import * as React from 'react';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';

import styles from './UnselectedItem.module.css';

const style = {
  cursor: 'move',
};
interface Props {
  text: string;
  toggle: () => void;
  pii: boolean;
}
export const UnselectedItem = ({ text, toggle, pii }: Props) => {
  return (
    <div
      className={clsx(baseStyles['base-form-checkbox'], pii && styles['pii'])}
      style={style}
    >
      <label>
        <input
          type="checkbox"
          name={text}
          checked={false}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggle();
          }}
        />
        <p></p>
        {text}
      </label>
    </div>
  );
};
