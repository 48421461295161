import moment, { Moment } from 'moment-timezone';
import { TFunction } from 'react-i18next';
import _ from 'lodash';
import { isSameDay } from 'react-dates';

import { matchesFormat } from 'shared/libraries/validate/validator';
import {
  Field,
  GuestType,
  UnitPricing,
  Reservation,
  ReservationLocationWithTime,
  ReservationLocationWithTimeInput,
} from 'shared/models/swagger';
import currencies from 'shared/libraries/validate/currencies.json';

export const printGuestType = (g: GuestType, t: TFunction) =>
  g.minimum_age && g.maximum_age
    ? t('{{guestTitle}} ({{guestMinAge}}-{{guestMaxAge}})', {
        guestTitle: g.title,
        guestMinAge: g.minimum_age,
        guestMaxAge: g.maximum_age,
      })
    : g.minimum_age
    ? t('{{guestTitle}} ({{guestMinAge}}+)', {
        guestTitle: g.title,
        guestMinAge: g.minimum_age,
      })
    : g.maximum_age
    ? t('{{guestTitle}} ({{guestMaxAge}} & under)', {
        guestTitle: g.title,
        guestMaxAge: g.maximum_age,
      })
    : g.title;

export const printUnitTitle = (unit: UnitPricing, t: TFunction) => {
  if (unit.method === 'PER_PARTICIPANT') {
    const guestType = unit.guest_type;

    if (unit.title) {
      if (guestType && unit.title === guestType.title) {
        return printGuestType(guestType, t);
      }

      return guestType
        ? `${printGuestType(guestType, t)} ${String(unit.title)}`
        : `${unit.title}`;
    }

    return guestType ? printGuestType(guestType, t) : '';
  } else if (unit.method === 'PER_GROUP') {
    const groupedGuestTypes = _.groupBy(unit.group_guest_types, (g) =>
      printGuestType(g, t)
    );

    const guestTypeBreakdown = Object.keys(groupedGuestTypes)
      .map(
        (guestTitle) =>
          `${groupedGuestTypes[guestTitle].length} x ${guestTitle}`
      )
      .join(', ');
    return unit.title ? `${unit.title} - ${guestTypeBreakdown}` : '';
  }

  return '';
};

export const getStartTime = (reservation: Reservation) =>
  moment.tz(reservation.start_date_time_utc, reservation.start_timezone ?? '');

export type LocationWithMoment = {
  locationID: string;
  googlePlaceID?: string;
  locationName: string;
  locationDescription: string;
  locationDateTime: Moment | null;
  imageUrls: string[];
};

const momentsAreSame = (m1: Moment, m2: Moment) => {
  if (m1 === m2) return true;
  if (m1 === null || m2 === null) return false;
  return m1.isSame(m2);
};

// isBeforeDay was copied from a react-dates unexported function
export const isBeforeDay = (a: Moment, b: Moment): boolean => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  const aYear = a.year();
  const aMonth = a.month();
  const bYear = b.year();
  const bMonth = b.month();
  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;
  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};
// isAfterDay was copied from a react-dates unexported function
export const isAfterDay = (a: Moment, b: Moment): boolean => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b) && !isSameDay(a as any, b as any);
};
export const formattedLocationName = (
  loc?: ReservationLocationWithTime | ReservationLocationWithTimeInput
) => {
  if (!loc || !loc.location_name) return '';
  if (loc.location_description)
    return `${loc.location_name} - ${loc.location_description}`;
  return loc.location_name;
};
export const defaultLocationWithMoment: LocationWithMoment = {
  locationID: '',
  locationName: '',
  locationDescription: '',
  googlePlaceID: '',
  locationDateTime: null,
  imageUrls: [],
};
// convertLocationWithMomentToReservationLocationWithTimeInput converts the LocationWithMoment
// utility type to an API type. If LocationWithMoment does not have 'locationName' or 'locationDateTime'
// set then return undefined so we don't pass a value to the API.
export const convertLocationWithMomentToReservationLocationWithTimeInput = (
  loc: LocationWithMoment
): ReservationLocationWithTimeInput | typeof undefined => {
  const {
    locationID,
    locationName,
    locationDescription,
    googlePlaceID,
    locationDateTime,
  } = loc;

  if (!locationName && !locationDateTime && !locationID) {
    return undefined;
  }

  return {
    location_name: locationName,
    location_description: locationDescription,
    google_place_id: googlePlaceID,
    id: locationID,
    date_time_utc:
      (locationDateTime && locationDateTime.utc().format()) || undefined,
    image_urls: loc.imageUrls,
  };
};
export const locationWithMomentsAreEqual = (
  loc1: LocationWithMoment,
  loc2: LocationWithMoment
): boolean => {
  return Boolean(
    loc1.locationID === loc2.locationID &&
      loc1.locationName === loc2.locationName &&
      loc1.locationDescription === loc2.locationDescription &&
      loc1.googlePlaceID === loc2.googlePlaceID &&
      ((!loc1.locationDateTime && !loc2.locationDateTime) ||
        (loc1.locationDateTime &&
          loc2.locationDateTime &&
          momentsAreSame(loc1.locationDateTime, loc2.locationDateTime))) &&
      _.isEqual(loc1.imageUrls, loc2.imageUrls)
  );
};
export const convertToLocationWithMoment = (
  loc:
    | ReservationLocationWithTime
    | ReservationLocationWithTimeInput
    | undefined,
  timezone: string,
  locale: string
): LocationWithMoment => {
  return {
    locationID: (loc && loc.id) || '',
    locationName: (loc && loc.location_name) || '',
    locationDescription: (loc && loc.location_description) || '',
    googlePlaceID: (loc && loc.google_place_id) || '',
    locationDateTime:
      (loc &&
        loc.date_time_utc &&
        timezone &&
        moment.tz(loc.date_time_utc, timezone).locale(locale)) ||
      null,
    imageUrls: loc?.image_urls ?? [],
  };
};
// i18n
export const translatedReactTableProps = (t: TFunction) => ({
  previousText: t('Previous'),
  nextText: t('Next'),
  loadingText: t('Loading...'),
  noDataText: t('No rows found'),
  pageText: t('Page'),
  ofText: t('of'),
  rowsText: t('rows'),
});
export const getFieldResponseErrors = (
  fieldResponses: {
    key?: string;
    response?: string;
    leaveBlank?: boolean;
  }[],
  formFields: Field[],
  t: TFunction,
  skipRequiredField?: boolean
): Record<string, any> => {
  const errorMap: Record<string, any> = {};
  formFields.forEach((f) => {
    const r = fieldResponses.find((r) => r.key === f.key);

    if (
      f.required === 'WHEN_BOOKING' &&
      !r?.leaveBlank &&
      (!r || !r.response) &&
      (!skipRequiredField ||
        (skipRequiredField &&
          [
            'given_name',
            'family_name',
            'kana_given_name',
            'kana_family_name',
          ].includes(f.key ?? '')))
    ) {
      errorMap[f.key || ''] = t('"{{fieldName}}" required', {
        fieldName: f.prompt,
      });
      return;
    }

    const response = r && r.response;

    if (response && f.format && !matchesFormat(response, f.format as any)) {
      errorMap[f.key || ''] = t(
        '"{{fieldName}}" does not match format "{{format}}"',
        {
          fieldName: f.prompt,
          format: f.format,
        }
      );
    }
  });
  return errorMap;
};

// Bundle of utility functions used for validating currency amount inputs in forms.
export class CurrencyAmountInputHelper {
  currencyCode: string;
  decimalPlaces: number;

  constructor(currencyCode: string) {
    this.currencyCode = currencyCode;
    this.decimalPlaces = currencies[currencyCode as keyof typeof currencies]
      ? currencies[currencyCode as keyof typeof currencies].decimal_digits
      : 2;
  }

  // Computes the difference between two numeric strings.
  diff = (a: string, b: string) => {
    const powerOfTen = Math.pow(10, this.decimalPlaces);

    const niceInput = (num: string) =>
      parseFloat((parseFloat(num) * powerOfTen).toFixed(this.decimalPlaces));

    return ((niceInput(a) - niceInput(b)) / powerOfTen).toString();
  };
  // Computes the sum of two numeric strings.
  add = (a: string, b: string) => {
    const powerOfTen = Math.pow(10, this.decimalPlaces);

    const niceInput = (num: string) =>
      parseFloat((parseFloat(num) * powerOfTen).toFixed(this.decimalPlaces));

    return ((niceInput(a) + niceInput(b)) / powerOfTen).toString();
  };
  // Checks to see if input should be recognized or ignored.
  inputAllowed = (num: string) => {
    // Counts the number of periods in a number string.
    let occurrences = 0;

    for (let idx = num.indexOf('.'); idx !== -1; idx = num.indexOf('.')) {
      num = num.substr(num.indexOf('.') + 1);
      occurrences++;
    }

    // Checks to see if at most 1 decimal points exist in numeric string.
    // Also checks to see whether any non-numeric characters are present in a numeric string.
    return occurrences <= 1 && !num.match(/[^0-9.]/);
  };
  // Formats an input number string for storage in state.
  moneyInput = (num: string) =>
    num.indexOf('.') !== -1 &&
    num.substr(num.indexOf('.')).length > this.decimalPlaces + 1
      ? num.substr(
          0,
          Math.min(
            num.indexOf('.') +
              (this.decimalPlaces ? this.decimalPlaces + 1 : 0),
            num.length
          )
        )
      : num;
  // Formats a state number string for serialization.
  moneyString = (num: string) =>
    `${this.currencyCode}${parseFloat(num)
      .toFixed(this.decimalPlaces)
      .toString()}`;
  // Checks to see one is allowed to submit the form given a numeric string.
  inputInvalid = (num: string) => isNaN(parseFloat(num)) || parseFloat(num) < 0;
}
export const currencyAmountInputHelper = (
  currencyCode: string
): CurrencyAmountInputHelper => new CurrencyAmountInputHelper(currencyCode);
export const currencyInputAllowed = (
  currencyCode: string,
  num: string
): boolean => {
  return currencyAmountInputHelper(currencyCode).inputAllowed(num);
};
// Converts a string to snake case
export const toSnakeCase = (str: string) =>
  str.replace(/^\s+/g, '').replace(/\s+/g, '_');
export const fromSnakeCase = (str: string) => str.replace(/_/g, ' ');
export type CommonFormField = {
  text: string;
  value: string;
  responseConstraint: string;
  defaultType: string;
  format?: string;
  choices?: string[];
  required?: string;
};
export const getCommonFormFields = (
  t: TFunction,
  sourceLanguageLowercaseIso?: string
): CommonFormField[] => {
  return [
    {
      text: t('Custom...'),
      value: 'custom',
      responseConstraint: 'NONE',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Booking Language', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'preferred_language_iso2',
      responseConstraint: 'CHOICES',
      choices: ['ja-JP', 'en-US'],
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Hotel Information', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'hotel_information',
      responseConstraint: 'NONE',
      format: '',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Representative Name', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'representative_name',
      responseConstraint: 'FORMAT',
      format: 'alpha-name',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Full Name', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'full_name',
      responseConstraint: 'FORMAT',
      format: 'alpha-name',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Full Name(Kanji)', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'kanji_full_name',
      responseConstraint: 'NO_RESTRICTION',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Title', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'title',
      responseConstraint: 'FORMAT',
      format: 'alpha-name',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Email Address', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'email',
      responseConstraint: 'FORMAT',
      format: 'email',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Phone Number', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'phone',
      responseConstraint: 'FORMAT',
      format: 'phone',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('International Phone Number', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'international_phone',
      responseConstraint: 'FORMAT',
      format: 'international_phone',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t("Emergency Contact's Name", {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'emergency_contact_name',
      responseConstraint: 'FORMAT',
      format: 'alpha-name',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t("Emergency Contact's Phone Number", {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'emergency_contact_phone',
      responseConstraint: 'FORMAT',
      format: 'phone',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Height (cm)', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'height',
      responseConstraint: 'FORMAT',
      format: 'float',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Weight (kg)', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'weight',
      responseConstraint: 'FORMAT',
      format: 'float',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Date of Birth', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'date_of_birth',
      responseConstraint: 'FORMAT',
      format: 'yyyy-mm-dd',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Age', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'age',
      responseConstraint: 'FORMAT',
      format: 'non-negative-integer',
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Gender', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'gender',
      responseConstraint: 'CHOICES',
      choices: [t('Male'), t('Female')],
      defaultType: 'PER_PARTICIPANT',
    },
    {
      text: t('Home Address', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'home_address',
      responseConstraint: 'NO_RESTRICTION',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Consent Form', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'consent_form',
      responseConstraint: 'NONE',
      defaultType: 'PER_BOOKING',
    },
    {
      text: t('Hotel TBD Form', {
        lng: sourceLanguageLowercaseIso,
      }),
      value: 'hotel_tbd_form',
      responseConstraint: 'NONE',
      defaultType: 'PER_BOOKING',
      required: 'OPTIONAL',
    },
  ];
};
export const getCommonFormFieldsByKey = (
  t: TFunction,
  sourceLanguageLowercaseIso?: string
): Record<string, CommonFormField> => {
  const commonFormFields = getCommonFormFields(t, sourceLanguageLowercaseIso);
  const commonFormFieldsByKey: Record<string, CommonFormField> = {};
  commonFormFields.forEach((field) => {
    commonFormFieldsByKey[field.value] = field;
  });
  commonFormFieldsByKey['kana_family_name'] = {
    value: 'kana_family_name',
    text: t('Family Name(Kana)', {
      lng: sourceLanguageLowercaseIso,
    }),
    responseConstraint: 'FORMAT',
    format: 'kana-name',
    defaultType: 'PER_BOOKING',
  };
  commonFormFieldsByKey['kana_given_name'] = {
    value: 'kana_given_name',
    text: t('Given Name(Kana)', {
      lng: sourceLanguageLowercaseIso,
    }),
    responseConstraint: 'FORMAT',
    format: 'kana-name',
    defaultType: 'PER_BOOKING',
  };
  return commonFormFieldsByKey;
};
export const getFieldOptions = (
  field: Field
): {
  key: string;
  text: string;
  value: string;
}[] => {
  if (field.options && field.options.length > 1) {
    return field.options.map((opt) => ({
      key: opt.key || '',
      text: opt.text || '',
      value: opt.key || '',
    }));
  } else if (field.choices && field.choices.length > 1) {
    return field.choices.map((choice) => ({
      key: choice,
      text: choice,
      value: choice,
    }));
  }

  return [];
};

const pathToSegments = (p: string): string[] => {
  if (!p) {
    return [];
  } else {
    const split = p.split('.');
    return split.length > 1 ? split.slice(1) : [];
  }
};

export const setBranchValue = (
  leaf: Record<string, any>,
  branch: string,
  value: any
) => {
  const segments = pathToSegments(branch);
  const idxPattern = /^[0-9]+$/g;
  let ptr = leaf;

  for (let i = 0; i < segments.length; i++) {
    const key = segments[i].match(idxPattern)
      ? parseInt(segments[i])
      : segments[i];

    if (i !== segments.length - 1) {
      if (ptr[key] === undefined) {
        ptr[key] = segments[i + 1].match(idxPattern) ? [] : {};
      } else if (typeof ptr[key] !== 'object') {
        console.log(
          `WARNING: Replacing an existing non-object segment with a new object. Branch: ${branch}, Segment: ${segments[i]}`
        );
        ptr[key] = segments[i + 1].match(idxPattern) ? [] : {};
      }

      ptr = ptr[key];
    } else {
      ptr[key] = value;
    }
  }
};
export const getBranchValue = (
  leaf: Record<string, any>,
  branch: string
): any => {
  const segments = pathToSegments(branch);
  let ptr = leaf;

  for (let i = 0; i < segments.length; i++) {
    if (i !== segments.length - 1) {
      if (
        ptr[segments[i]] === undefined ||
        typeof ptr[segments[i]] !== 'object'
      ) {
        throw new Error(
          `Branch doesn't exist for object: ${branch}. Current segment: ${segments[i]} (${i})`
        );
      }
    }

    ptr = ptr[segments[i]];
  }

  return ptr;
};
export const getOptionalBranchValue = (
  leaf: Record<string, any> | null | undefined,
  branch: string
): any => {
  if (!leaf || !branch) {
    return leaf;
  }

  const segments = pathToSegments(branch);
  let ptr = leaf;

  for (let i = 0; i < segments.length; i++) {
    if (i !== segments.length - 1) {
      if (
        ptr[segments[i]] === undefined ||
        typeof ptr[segments[i]] !== 'object'
      ) {
        return undefined;
      }
    }

    ptr = ptr[segments[i]];
  }

  return ptr;
};
export const replaceBranch = (
  dstRoot: Record<string, any>,
  srcRoot: Record<string, any>,
  branch: string
) => {
  const segments = pathToSegments(branch);
  let dstPtr = dstRoot;
  let srcPtr = srcRoot;

  for (let i = 0; i < segments.length; i++) {
    if (i !== segments.length - 1) {
      if (
        dstPtr[segments[i]] === undefined ||
        typeof dstPtr[segments[i]] !== 'object'
      ) {
        throw new Error(
          `Branch doesn't exist for destination object: ${branch}`
        );
      }

      if (
        srcPtr[segments[i]] === undefined ||
        typeof srcPtr[segments[i]] !== 'object'
      ) {
        throw new Error(`Branch doesn't exist for source object: ${branch}`);
      }

      dstPtr = dstPtr[segments[i]];
      srcPtr = srcPtr[segments[i]];
    } else {
      dstPtr[segments[i]] = srcPtr[segments[i]];
    }
  }
};
export function listAddFront<T>(value: T[], newElem: T): T[] {
  const newValue = [...(value || [])];
  newValue.splice(0, 0, newElem);
  return newValue;
}
export function listInsert<T>(value: T[], idx: number, newElem: T): T[] {
  const newValue = [...value];
  newValue.splice(idx + 1, 0, newElem);
  return newValue;
}
export function listRemove<T>(value: T[], idx: number): T[] {
  const newValue = [...value];
  newValue.splice(idx, 1);
  return newValue;
}
// function getDiffAttributes(
//   unitA: Object,
//   unitB: Object,
//   prefix = ''
// ): { A: string[], B: string[] } {
//   const keysA = Object.keys(unitA),
//     keysB = Object.keys(unitB);
//   let currA = [],
//     currB = [];
//   new Set([...keysA, ...keysB]).forEach(key => {
//     if (
//       !!unitB[key] &&
//       !!unitA[key] &&
//       typeof unitB[key] === 'object' &&
//       typeof unitA[key] === 'object' &&
//       !Array.isArray(unitA[key]) &&
//       !Array.isArray(unitB[key])
//     ) {
//       const { A, B } = getDiffAttributes(unitA[key], unitB[key], `.${key}`);
//       currA = currA.concat(A);
//       currB = currB.concat(B);
//     }
//   });
//   return {
//     A: currA.concat(
//       Object.keys(unitB)
//         .filter(key => unitA[key] === undefined)
//         .map(key => `${prefix}.${key}`)
//     ),
//     B: currB.concat(
//       Object.keys(unitA)
//         .filter(key => unitB[key] === undefined)
//         .map(key => `${prefix}.${key}`)
//     )
//   };
// }
export function listReorderFront<T>(value: T[], idx: number): T[] {
  if (idx > value.length - 1 || idx <= 0) {
    throw new Error(`Invalid index for reorderFront operation: ${idx}`);
  }

  const newValue = [...value];
  const prevUnit = newValue[idx - 1],
    currUnit = newValue[idx];
  newValue[idx] = prevUnit;
  newValue[idx - 1] = currUnit;
  return newValue;
}
export function listReorderBack<T>(value: T[], idx: number): T[] {
  if (idx >= value.length - 1 || idx < 0) {
    throw new Error(`Invalid index for reorderBack operation: ${idx}`);
  }

  const newValue = [...value];
  const currUnit = newValue[idx],
    nextUnit = newValue[idx + 1];
  newValue[idx] = nextUnit;
  newValue[idx + 1] = currUnit;
  return newValue;
}
export function listUpdate<T>(value: T[], idx: number, newElem: T): T[] {
  return [...value.slice(0, idx), newElem, ...value.slice(idx + 1)];
}
export function ListOps<T>(): Record<string, any> {
  return {
    addFront: (v: T[], e: T): T[] => listAddFront<T>(v, e),
    add: (v: T[], i: number, e: T): T[] => listInsert<T>(v, i, e),
    remove: (v: T[], i: number): T[] => listRemove<T>(v, i),
    reorderFront: (v: T[], i: number): T[] => listReorderFront<T>(v, i),
    reorderBack: (v: T[], i: number): T[] => listReorderBack<T>(v, i),
    update: (v: T[], i: number, e: T): T[] => listUpdate<T>(v, i, e),
  };
}
// Based on prior versions of a and b, determines whether the two arrays match.
export const arraysMatch = <T>(
  a: T[] | null | undefined,
  b: T[] | null | undefined,
  origA: T[],
  origB: T[]
): boolean => {
  const arrA = a || [],
    arrB = b || [];

  if (arrA.length !== origA.length || arrB.length !== origB.length) {
    return false;
  }

  for (let i = 0; i < Math.max(arrA.length, arrB.length); i++) {
    if (arrA[i] !== origA[i] || arrB[i] !== origB[i]) {
      return false;
    }
  }

  return true;
};
// Based on prior versions of a and b, moves elements in b to try to match a as closely as possible.
// NOTE: This assumes that only a been manipulated by ListOp functions
export const alignArrays = <T>(
  a: T[] | null | undefined,
  b: T[] | null | undefined,
  origA: T[],
  origB: T[],
  defVal: T
) => {
  const arrA = a || [],
    arrB = b || [];

  const eq = (itemA: T, itemB: T, i: number) =>
    itemA === origA[i] && itemB === origB[i];

  for (let i = 0; i < Math.max(arrA.length, arrB.length); i++) {
    if (!eq(arrA[i], arrB[i], i)) {
      // misalignment detected
      if (i > 0 && eq(arrA[i], arrB[i - 1], i - 1)) {
        if (eq(arrA[i - 1], arrB[i], i)) {
          // front reordering between indices i and i-1
          const curr = arrB[i],
            prev = arrB[i - 1];
          arrB[i] = prev;
          arrB[i - 1] = curr;
        } else if (arrA.length > arrB.length) {
          // insertion at index i
          arrB.splice(i - 1, 0, defVal);
        }
      } else if (eq(arrA[i], arrB[i + 1], i + 1)) {
        if (eq(arrA[i + 1], arrB[i], i)) {
          // back reordering between indices i and i+1
          const curr = arrB[i],
            next = arrB[i + 1];
          arrB[i] = next;
          arrB[i + 1] = curr;
        } else if (arrA.length < arrB.length) {
          // deletion at index i before end of array
          arrB.splice(i, 1);
        }
      } else if (
        i >= arrA.length &&
        i < arrB.length &&
        arrA.length < arrB.length
      ) {
        // deletion at end of array
        arrB.splice(i, arrB.length - arrA.length);
      } else if (
        i < arrA.length &&
        i >= arrB.length &&
        arrA.length > arrB.length
      ) {
        // insertion at end of array
        const deltaLength = arrA.length - arrB.length;

        for (let j = 0; j < deltaLength; j++) {
          arrB.splice(i, 0, defVal);
        }
      }
    }
  }
};

// Transaction types:
//   t('Charge')
//   t('Cancel refund')
// Reservation statuses:
//   t('PENDING')
//   t('CANCELLATION_PENDING')
//   t('AWAITING_RESERVATION_CONFIRMATION')
//   t('AWAITING_UPDATE_CONFIRMATION')
//   t('WAITLISTED')
//   t('REJECTED')
//   t('NO_SHOW')
//   t('CANCELLATION_PENDING')
//   t('REQUESTED')
//   t('CANCEL_REQUESTED_BY_AGENT')
//   t('WITHDRAWN_BY_AGENT')
//   t('CANCELED_BY_AGENT')
//   t('CANCELED_BY_GUEST')
//   t('CONFIRMED')
//   t('STANDBY')
//   t('PARTICIPATED')
//   t('DECLINED_BY_SUPPLIER')
//   t('CANCELED_BY_SUPPLIER')
//   t('CANCEL_CONFIRMED_BY_SUPPLIER')
//   t('CANCEL_DECLINED_BY_SUPPLIER')
// Availability channel categories
//   t('DIRECT_ONLINE')
//   t('DIRECT_OFFLINE')
//   t('DIRECT_ALL')
//   t('AGENT')
//   t('RESELLER')
// Allotment channel categories
//   t('COMMON')
// Payment types
//   t('DIRECT_WALK_IN')
//   t('DIRECT_TELEPHONE')
//   t('DIRECT_EMAIL')
//   t('DIRECT_WEB')
//   t('AGENT')
//   t('OTHER')
// Booking sources
//   t('INVOICE')
//   t('CREDIT_CARD')
//   t('CASH')
//   t('BANK')
//   t('OTHER')
//   t('PAID_IN_FULL')
//   t('PAY_ON_BOARD')
//   t('PAID_PARTIALLY')
// Field names
//   t('Please select a field type...')
//   t('Custom...')
//   t('Family Name')
//   t('Given Name')
//   t('Full Name')
//   t('Title')
//   t('Email Address')
//   t('Phone Number')
//   t('Emergency Contact's Name')
//   t('Emergency Contact's Phone Number')
//   t('Height')
//   t('Weight')
//   t('Date of Birth')
//   t('Age')
//   t('Gender')
// Common form field values
//   t('Male')
//   t('Female')
