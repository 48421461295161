import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  DOWNLOAD_GUIDE_CSV_FAILURE,
  DOWNLOAD_GUIDE_CSV_REQUEST,
  DOWNLOAD_GUIDE_CSV_SUCCESS,
  DOWNLOAD_GUIDE_PDF_FAILURE,
  DOWNLOAD_GUIDE_PDF_REQUEST,
  DOWNLOAD_GUIDE_PDF_SUCCESS,
  SEND_GUIDE_EMAIL_FAILURE,
  SEND_GUIDE_EMAIL_REQUEST,
  SEND_GUIDE_EMAIL_SUCCESS,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
} from 'client/constants/ActionTypes';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type {
  GuideSingleDaySchedule,
  GetGuideSingleDayScheduleResponse,
  ListGuideSingleDaySchedulesResponse,
} from 'shared/models/swagger';

export const guidesSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return (organization && organization.guides) || [];
  }
);

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_GUIDE_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_GUIDE_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_GUIDE_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const csvStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_GUIDE_CSV_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_GUIDE_CSV_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_GUIDE_CSV_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case SEND_GUIDE_EMAIL_REQUEST:
      return 'IN_FLIGHT';

    case SEND_GUIDE_EMAIL_SUCCESS:
      return 'SUCCESS';

    case SEND_GUIDE_EMAIL_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailError = (state = '', action: any) => {
  switch (action.type) {
    case SEND_GUIDE_EMAIL_REQUEST:
    case SEND_GUIDE_EMAIL_SUCCESS:
      return '';

    case SEND_GUIDE_EMAIL_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const singleDayScheduleLoading = (state = false, action: any) => {
  switch (action.type) {
    // These actions always trigger the loading screen
    case CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST:
    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST:
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST:
      return true;

    // These actions always remove the loading screen
    case CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS:
    case CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE:
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED:
      return false;

    default:
      return state;
  }
};

const singleDayScheduleError = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE:
    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE:
      return action.error;

    case CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS:
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS:
    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const singleDayScheduleByID = (
  state: Record<string, GuideSingleDaySchedule> = {},
  action: any
) => {
  switch (action.type) {
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS: {
      const responses =
        action.responses as any as GetGuideSingleDayScheduleResponse[];
      const singleDaySchedulesByID: Record<string, GuideSingleDaySchedule> = {};
      responses.forEach((r) => {
        singleDaySchedulesByID[r.id] = r;
      });
      return { ...state, ...singleDaySchedulesByID };
    }

    case UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS: {
      const result = { ...state };
      delete result[action.id];
      return result;
    }

    default:
      return state;
  }
};

const singleDaySchedules = (
  state: Array<GuideSingleDaySchedule> = [],
  action: any
): Array<GuideSingleDaySchedule> => {
  switch (action.type) {
    case FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS: {
      const resp =
        action.response as any as ListGuideSingleDaySchedulesResponse;
      return resp.guide_single_day_schedules || [];
    }
    case DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS:
      return state.filter((i: GuideSingleDaySchedule) => i.id !== action.id);

    default:
      return state;
  }
};

export const guides = combineReducers({
  csvStatus,
  emailError,
  emailStatus,
  pdfStatus,
  singleDayScheduleLoading,
  singleDayScheduleError,
  singleDayScheduleByID,
  singleDaySchedules,
});
