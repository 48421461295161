import { combineReducers } from 'redux';
import moment from 'moment-timezone';

import {
  SET_RESOURCE_AVAILABILITY_START_DATE,
  SET_RESOURCE_AVAILABILITY_RESOURCE_KEY,
  SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE,
  SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY,
} from 'client/constants/ActionTypes';
import type { ResourceType } from 'client/libraries/util/resourceType';

// Reducers

const startDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_RESOURCE_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const resourceKey = (state = '', action: any): string => {
  switch (action.type) {
    case SET_RESOURCE_AVAILABILITY_RESOURCE_KEY:
      return action.payload;
    default:
      return state;
  }
};

const resourceType = (
  state: ResourceType = 'other',
  action: any
): ResourceType => {
  switch (action.type) {
    case SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE:
      return action.payload;
    default:
      return state;
  }
};

const vehicleDepartureTimeKey = (state = '', action: any): string => {
  switch (action.type) {
    case SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY:
      return action.payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  startDate,
  resourceKey,
  resourceType,
  vehicleDepartureTimeKey,
});
export const resourceAvailabilityControls = reducer;
