import * as React from 'react';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWidgetProductGroupCalendarUrl } from 'client/libraries/util/getBookingWidgetProductGroupCalendarUrl';
import styles from 'client/pages/BookingWidget/EmbeddedMultiProductCalendar/EmbeddedMultiProductCalendar.module.css';

type Props = {
  apiKey: string | null;
  language?: string;
};

export const EmbeddedProductGroupCalendar = ({ apiKey, language }: Props) => {
  const organization = useSelector(activeUserOrganizationSelector);

  // need re-rendering iframe.
  const [key, setKey] = React.useState<number>(Math.random());

  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).buildMultiCalendarEmbed) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-multi-product/v1/index.js`;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const url = getBookingWidgetProductGroupCalendarUrl(apiKey, language);

  React.useEffect(() => {
    setKey(Math.random());
  }, [organization?.booking_widget_calendar_product_groups]);

  return (
    <div className="snippet-example">
      <div
        id="nutmeg-multi-calendar"
        className={styles['nutmeg-multi-calendar-enc-div']}
      >
        <iframe
          id="nutmeg-multi-calendar-embed-iframe"
          src={url}
          key={key}
          className={styles['nutmeg-multi-calendar']}
          title="embedded-calendar"
        />
      </div>
    </div>
  );
};
