import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { Form } from 'react-final-form';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { updateInquirySettings } from 'client/actions/inquirySettings';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import baseStyles from 'client/base.module.css';

import styles from './InquirySettings.module.css';
import { InquiryCategoryEditor } from './InquiryCategoriesEditor';
import { InquiryMessageTemplatesEditor } from './InquiryMessageTemplatesEditor';
import {
  convertToSwaggerInquirySettings,
  FormValues,
  getInitialValues,
} from './formValues';

export const InquirySettings = () => {
  const { t } = useTranslation();

  const scrollTo = React.useContext(ScrollToContext);
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const initialLanguage =
    supportedLanguages.length > 0 ? supportedLanguages[0] : 'JA_JP';

  const initialValues = React.useMemo(() => {
    return getInitialValues(organization, initialLanguage);
  }, [initialLanguage, organization]);

  const inquiryFormUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key || ''
  )}/inquiry`;

  const inquiryEmailAddress = `${
    organization?.booking_widget_subdomain ?? ''
  }@${getEmailDomain()}`;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Form
          initialValues={initialValues}
          onSubmit={async (values: FormValues) => {
            await dispatch(
              updateInquirySettings(
                convertToSwaggerInquirySettings(values, organization)
              )
            );

            scrollTo(0, 0);
          }}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitSucceeded, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              {submitError && (
                <Message
                  error
                  header={t('Update failed')}
                  content={submitError}
                />
              )}
              {submitSucceeded && (
                <Message success header={t('Update succeeded')} />
              )}
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Inquiry Form Link')}</th>
                      <td>
                        <a
                          className={styles['link']}
                          href={inquiryFormUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {inquiryFormUrl}
                        </a>
                        <Box mt={4} mb={2}>
                          <QRCode value={inquiryFormUrl} />
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Inquiry Email Address')}</th>
                      <td>
                        <a
                          className={styles['link']}
                          href={`mailto:${inquiryEmailAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {inquiryEmailAddress}
                        </a>
                        <Box mt={4} mb={2}>
                          <QRCode value={`mailto:${inquiryEmailAddress}`} />
                        </Box>
                      </td>
                    </tr>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'INQUIRY.SETTINGS'
                    ) && (
                      <tr>
                        <th>{t('Inquiry Categories')}</th>
                        <td>
                          <InquiryCategoryEditor />
                        </td>
                      </tr>
                    )}
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'INQUIRY.SETTINGS'
                    ) && (
                      <tr>
                        <th>{t('Inquiry Message Templates')}</th>
                        <td>
                          <InquiryMessageTemplatesEditor />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </FormTableBox>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'INQUIRY.SETTINGS'
              ) && (
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <Button style="gray" size="middle">
                    {t('Discard')}
                  </Button>
                  <Button
                    style="blue"
                    size="middle"
                    type="submit"
                    loading={submitting}
                  >
                    {t('Save')}
                  </Button>
                </div>
              )}
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const getEmailDomain = (): string => {
  return window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'book.');
};
