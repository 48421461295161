import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList.module.css';

interface Props {
  text: string;
}
export const FixedItem = ({ text }: Props) => {
  const { t } = useTranslation();

  return (
    <li
      className={clsx(styles['c-toggleableList__item'], styles['is-disabled'])}
    >
      <div className={styles['c-toggleableList__item__left']}>
        <p>{text}</p>
      </div>
      <div className={styles['c-toggleableList__item__right']}>
        {t('This item is fixed and cannot be removed or reordered.')}
      </div>
    </li>
  );
};
