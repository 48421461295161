// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import clsx from 'clsx';

import { updateReservation } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import type { Reservation } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';
import { Modal } from 'client/components/Modal/Modal';
import { TextArea, Button } from 'client/components/Form';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type OwnProps = {
  reservation: Reservation,
  onUpdateCompleted?: (void) => void,
  trigger: React.Element<'a'>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  guestHotel?: {
    location_name?: string,
    google_place_id?: string,
  },
  requestedPickup?: {
    location_name?: string,
    google_place_id?: string,
  },
  requestedDropoff?: {
    location_name?: string,
    google_place_id?: string,
  },
  agentNotes: string,
};

class RequestedPickupDropoffEditButtonComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    const { reservation } = props;

    this.state = {
      showModal: false,
      guestHotel: reservation.guest_hotel,
      requestedPickup: reservation.requested_pickup_location,
      requestedDropoff: reservation.requested_dropoff_location,
      agentNotes: reservation.agent_notes || '',
    };
  }

  fetchProduct = () => {
    const { fetchProductByID, reservation, product } = this.props;
    if (
      reservation &&
      reservation.product_id &&
      (!product || product.id !== reservation.product_id)
    ) {
      fetchProductByID(reservation.product_id);
    }
    this.setState({
      showModal: true,
    });
  };

  handleGuestHotelLocationSelect = ({
    title: location_name,
    key: google_place_id,
  }) => {
    const guestHotel = {
      location_name,
      google_place_id,
    };

    // Autofill requested pickup/dropoff from guest hotel.
    this.setState({
      guestHotel,
      requestedPickup: {
        ...guestHotel,
      },
      requestedDropoff: {
        ...guestHotel,
      },
    });
  };

  handleAgentNotesChange = (e) => {
    this.setState({ agentNotes: e.target.value });
  };

  render() {
    const { onUpdateCompleted, reservation, t, product, trigger } = this.props;
    const {
      guestHotel,
      requestedPickup,
      requestedDropoff,
      showModal,
      agentNotes,
    } = this.state;
    const pristine =
      guestHotel === reservation.guest_hotel &&
      requestedPickup === reservation.requested_pickup_location &&
      requestedDropoff === reservation.requested_dropoff_location &&
      agentNotes === reservation.agent_notes;

    return (
      <Modal
        title={t('Edit Desired Pickup/Dropoff')}
        trigger={trigger}
        open={showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={this.fetchProduct}
      >
        <div className={clsx(pageStyles['page-reservations__modal'])}>
          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <LocationSearchInput
              prompt={t('Customer Hotel')}
              location={(guestHotel && guestHotel.location_name) || ''}
              onSearchChange={(location_name) =>
                this.setState({
                  guestHotel: {
                    location_name,
                  },
                })
              }
              onLocationSelect={this.handleGuestHotelLocationSelect}
            />
          </div>

          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <LocationSearchInput
              prompt={t('Desired Pickup Location')}
              location={
                (requestedPickup && requestedPickup.location_name) || ''
              }
              candidateLocations={product.pickup || []}
              onSearchChange={(location_name) =>
                this.setState({
                  requestedPickup: {
                    location_name,
                  },
                })
              }
              onLocationSelect={({
                title: location_name,
                key: google_place_id,
              }) =>
                this.setState({
                  requestedPickup: {
                    location_name,
                    google_place_id,
                  },
                })
              }
            />
          </div>

          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <LocationSearchInput
              prompt={t('Desired Dropoff Location')}
              location={
                (requestedDropoff && requestedDropoff.location_name) || ''
              }
              candidateLocations={product.dropoff || []}
              onSearchChange={(location_name) =>
                this.setState({
                  requestedDropoff: {
                    location_name,
                  },
                })
              }
              onLocationSelect={({
                title: location_name,
                key: google_place_id,
              }) =>
                this.setState({
                  requestedDropoff: {
                    location_name,
                    google_place_id,
                  },
                })
              }
            />
          </div>

          <div className={clsx(baseStyles['base-text-divider'])} />
          <ReservationActorInputForm />
          <br />

          <TextArea
            label={t('Remarks')}
            value={agentNotes}
            onChange={this.handleAgentNotesChange}
          />
        </div>

        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <Button
            disabled={pristine}
            style="gray"
            size="middle"
            onClick={() =>
              this.setState({
                guestHotel: reservation.guest_hotel,
                requestedPickup: reservation.requested_pickup_location,
                requestedDropoff: reservation.requested_dropoff_location,
              })
            }
          >
            {t('Discard')}
          </Button>
          <Button
            style="green"
            size="middle"
            inverted
            disabled={pristine}
            onClick={() => {
              this.props
                .updateReservation(
                  reservation.id,
                  ({
                    guest_hotel: guestHotel,
                    requested_pickup_location: requestedPickup,
                    requested_dropoff_location: requestedDropoff,
                    agent_notes: agentNotes,
                  }: any)
                )
                .then(() => onUpdateCompleted && onUpdateCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps) => ({
  product:
    state.products.byID[
      ownProps.reservation && ownProps.reservation.product_id
    ],
});

const mapDispatchToProps = (dispatch) => ({
  updateReservation: (id, patch) => dispatch(updateReservation(id, patch)),
  fetchProductByID: (id) => dispatch(fetchProductByID(id)),
});

export const RequestedPickupDropoffEditButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(RequestedPickupDropoffEditButtonComponent);
