import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import type { DispatchResource } from 'shared/models/swagger';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { VehicleAvailabilityDisplayBox } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/VehicleAvailabilityDisplayBox';
import { VehicleAvailabilityModal } from 'client/pages/v3/Manifest/ManifestEditResource/Modal/VehicleAvailabilityModal';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { config } from 'client/config';
import { Button } from 'client/components/v3/Common/Button';
import { VehicleEditTable } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/VehicleEditTable';
import { updateDispatchVehicles } from 'client/actions/dispatchSettings';

type Props = {
  isActive?: boolean;
};

export const VehicleList = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const currentVehicles = useSelector(allDispatchVehiclesSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const [vehicles, setVehicles] = useState<DispatchResource[]>([]);
  useEffect(() => {
    setVehicles(_.sortBy(currentVehicles, (v) => v.key));
  }, [currentVehicles]);

  const [showVehicleAvailabilityModal, setShowVehicleAvailabilityModal] =
    useState<boolean>(false);
  const [existingVehicle, setExistingVehicle] =
    useState<DispatchResource | null>(null);

  const [editMode, setEditMode] = useState(false);
  // Close edit mode if active tab is changed
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  // Refetch data when edit mode is active
  useEffect(() => {
    if (editMode) {
      setVehicles(_.sortBy(currentVehicles, (v) => v.key));
    }
  }, [editMode]);

  const getColumns = () => {
    return [
      {
        Header: t('Name'),
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo: any) => <>{cellInfo.value}</>,
        width: '240px',
      },
      {
        Header: t('Capacity'),
        id: 'capacity',
        accessor: 'capacity',
        width: '200px',
      },
      {
        Header: t('Description'),
        id: 'description',
        accessor: 'description',
      },
    ];
  };

  const columns = getColumns();

  if (
    config.enableResourceManagementVehicle &&
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      id: 'availability',
      accessor: 'availability',
      Cell: (cellInfo: any) => (
        <div className={styles['p-manifests__vehicleResourceColumn']}>
          <VehicleAvailabilityDisplayBox resourceKey={cellInfo.original.key} />{' '}
          <Button
            text={t('Edit')}
            onClick={() => {
              setExistingVehicle(cellInfo.original);
              setShowVehicleAvailabilityModal(true);
            }}
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-edit-05"></i>}
          />
        </div>
      ),
      width: 'long',
    } as any);
  }

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Vehicles')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          {editMode && (
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
          )}
          <Button
            text={editMode ? t('Save') : t('Edit')}
            onClick={() => {
              setEditMode(!editMode);
              if (editMode) {
                dispatch(updateDispatchVehicles([...vehicles]));
              }
            }}
            color={editMode ? 'primary' : 'white'}
            iconBeforeText={
              editMode ? undefined : (
                <i className="c-icon-outline-general-edit-05"></i>
              )
            }
            // Disable button if vehicle key is empty in edit mode
            disabled={
              editMode && vehicles.filter((v) => v.key === undefined).length > 0
            }
          />
        </div>
      </div>
      {showVehicleAvailabilityModal && (
        <VehicleAvailabilityModal
          open={showVehicleAvailabilityModal}
          onClose={() => {
            setShowVehicleAvailabilityModal(false);
          }}
          resourceKey={existingVehicle?.key ?? ''}
        />
      )}

      {!editMode && (
        <ManifestCustomizeCustomTable
          itemName={t('Vehicle')}
          items={_.sortBy(currentVehicles, (v) => v.key)}
          columns={columns as any}
        />
      )}
      {editMode && (
        <VehicleEditTable
          initialVehicles={vehicles}
          setVehicles={setVehicles}
        />
      )}

      {editMode && (
        <div
          className={clsx(styles['p-manifests__fixed'], styles['is-active'])}
        >
          <div className={styles['p-manifests__fixed__main']}></div>
          <div className={styles['p-manifests__fixed__actions']}>
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
            <Button
              text={t('Save')}
              onClick={async () => {
                await setLoading(true);
                if (editMode) {
                  await dispatch(updateDispatchVehicles([...vehicles]));
                }
                await setLoading(false);
                await setEditMode(!editMode);
              }}
              color={'primary'}
              disabled={
                editMode &&
                vehicles.filter((v) => v.key === undefined).length > 0
              }
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};
