import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { Input } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { AgentReferencesEditor } from 'client/components/NewProductEditor/AgentReferencesEditor/AgentReferencesEditor';
import baseStyles from 'client/base.module.css';

import styles from './PerParticipantPriceInput.module.css';

type Props = {
  name: string;
  language: string;
  currency: string;
  showCustom?: boolean;
  showNet?: boolean;
  showPerGroup?: boolean;
};
export const PerParticipantPriceInput = ({
  currency,
  language,
  name,
  showCustom,
  showNet,
}: Props) => {
  const { t } = useTranslation();
  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );
  const builtInUnits = [
    t('Adult', {
      lng: language,
    }),
    t('Child', {
      lng: language,
    }),
    t('Adult/Child', {
      lng: language,
    }),
    t('Infant', {
      lng: language,
    }),
    t('Spectator', {
      lng: language,
    }),
  ];
  const unitOptions = [
    ...builtInUnits.map((unit) => ({
      value: unit,
      text: unit,
    })),
    ...(showCustom
      ? [
          {
            value: 'custom',
            text: t('Custom'),
          },
        ]
      : []),
  ];
  const translationTargetLanguageName =
    translationTargetLanguage &&
    getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  return (
    <div className={styles['page-productsRegist__priceMain']}>
      <p className={styles['page-productsRegist__priceMain__ttl']}>
        {t('Set Up Per-Participant Prices')}
      </p>
      <div className={styles['c-tableChild']}>
        <ul>
          <li className={baseStyles['base-t-160']}>{t('Unit')}</li>
          {showCustom && (
            <li className={baseStyles['base-t-160']}>{t('Displayed As')}</li>
          )}
          <li className={baseStyles['base-t-224']}>{t('Age (optional)')}</li>
          <li className={baseStyles['base-t-160']}>
            {t('Amount (tax included)')}
          </li>
          {showNet && (
            <li className={baseStyles['base-t-160']}>{t('Net Amount')}</li>
          )}
          {isNutmegAdmin && (
            <>
              <li className={baseStyles['base-t-400']}>
                {t('Agent References')}
              </li>
              <li className={baseStyles['base-t-160']}>
                {t('Third Party Reference')}
              </li>
            </>
          )}
          <li className={baseStyles['base-t-144']}></li>
          <li></li>
        </ul>
        <FieldArray name={name}>
          {({ fields }) =>
            fields.map((fieldName, idx) => (
              <ul key={fieldName}>
                <li data-title={t('Unit')}>
                  <label className={baseStyles['base-form-select']}>
                    <Field name={`${fieldName}.unit`}>
                      {({ input }) => (
                        <select
                          value={
                            builtInUnits.includes(input.value)
                              ? input.value
                              : 'custom'
                          }
                          onChange={(e) => {
                            if (e.target.value === 'custom') {
                              input.onChange('');
                            } else {
                              input.onChange(e.target.value);
                            }
                          }}
                        >
                          {unitOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </select>
                      )}
                    </Field>
                  </label>
                </li>
                {showCustom && (
                  <li data-title={t('Displayed As')}>
                    <TranslatedField name={`${fieldName}.unit`}>
                      {({ input, translationInput }) => (
                        <>
                          <div>
                            <input
                              {...input}
                              type="text"
                              className={baseStyles['base-form-text']}
                            />
                          </div>
                          {translationTargetLanguage != null &&
                            !builtInUnits.includes(input.value) && (
                              <div>
                                <input
                                  {...translationInput}
                                  placeholder={
                                    translationTargetLanguageName ?? ''
                                  }
                                  type="text"
                                  className={baseStyles['base-form-text']}
                                />
                              </div>
                            )}
                        </>
                      )}
                    </TranslatedField>
                  </li>
                )}
                <li data-title={t('Age (optional)')}>
                  <div
                    className={styles['page-productsRegist__priceMain__age']}
                  >
                    <label className={baseStyles['base-form-select']}>
                      <Field
                        parse={(value) => parseInt(value, 10)}
                        format={(value) => `${value}`}
                        name={`${fieldName}.ageBandMinimum`}
                      >
                        {({ input }) => (
                          <select {...input}>
                            {_.times(100).map((opt) => (
                              <option key={opt}>{opt}</option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </label>
                    <p>-</p>
                    <label className={baseStyles['base-form-select']}>
                      <Field
                        parse={(value) => parseInt(value, 10)}
                        format={(value) => `${value}`}
                        name={`${fieldName}.ageBandMaximum`}
                      >
                        {({ input }) => (
                          <select {...input}>
                            {_.times(100).map((opt) => (
                              <option key={opt} value={opt + 1}>
                                {opt + 1}
                              </option>
                            ))}
                            <option value={0}>{t('No Limit')}</option>
                          </select>
                        )}
                      </Field>
                    </label>
                  </div>
                </li>
                <li data-title={t('Amount (tax included)')}>
                  <div
                    className={styles['page-productsRegist__priceMain__price']}
                  >
                    <p>{currency}</p>
                    <Field name={`${fieldName}.price`}>
                      {({ input }) => (
                        <Input
                          value={input.value}
                          onChange={(e: any) => {
                            if (
                              !currencyInputAllowed(currency, e.target.value)
                            ) {
                              return;
                            }

                            input.onChange(e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </li>
                {showNet && (
                  <li data-title={t('Net Amount')}>
                    <div
                      className={
                        styles['page-productsRegist__priceMain__price']
                      }
                    >
                      <p>{currency}</p>
                      <Field name={`${fieldName}.netPrice`}>
                        {({ input }) => (
                          <Input
                            value={input.value}
                            onChange={(e: any) => {
                              if (
                                !currencyInputAllowed(currency, e.target.value)
                              ) {
                                return;
                              }

                              input.onChange(e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </li>
                )}
                {isNutmegAdmin && (
                  <>
                    <li data-title={t('Agent References')}>
                      <AgentReferencesEditor
                        name={`${fieldName}.referencesInAgentSystems`}
                      />
                    </li>
                    <li data-title={t('Third Party Reference')}>
                      <Field name={`${fieldName}.referenceInSupplierSystem`}>
                        {({ input }) => <Input {...input} />}
                      </Field>
                    </li>
                  </>
                )}
                <li className={baseStyles['base-flex']}>
                  <AddIcon
                    onClick={() => {
                      (fields as any).insertAt(idx + 1, {
                        method: 'PER_PARTICIPANT',
                        unit: t('Adult', {
                          lng: language,
                        }),
                        groupUnits: [],
                        ageBandMinimum: 13,
                        ageBandMaximum: 0,
                        price: '100',
                        packageComponentUnitMappings: [],
                      });
                    }}
                  />
                  {idx > 0 && (
                    <DeleteIcon
                      onClick={() => {
                        fields.remove(idx);
                      }}
                    />
                  )}
                </li>
              </ul>
            ))
          }
        </FieldArray>
      </div>
    </div>
  );
};
