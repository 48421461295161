export const getMonthlyDateRange = (now: moment.Moment) => {
  return {
    start: now.clone().startOf('month').format('YYYY-MM-DD'),
    end: now.clone().endOf('month').format('YYYY-MM-DD'),
  };
};

export const getWeeklyDateRange = (now: moment.Moment) => {
  return {
    start: now.clone().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
    end: now.clone().format('YYYY-MM-DD'),
  };
};

export interface DateRange {
  start: string;
  end: string;
}
