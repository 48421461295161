import * as React from 'react';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Loading } from 'client/components/v3/Common/Loading';

import { ChangeLabel } from '../JourneyAnalyticsDashboard/ChangeLabel';

import styles from './ProgressBarGadget.module.css';

interface Props {
  header: string;
  percent: number;
  percentChange?: number | null;
  tooltipText?: string;
  loading?: boolean;
}

export const ProgressBarGadget = ({
  header,
  percent,
  percentChange,
  tooltipText,
  loading,
}: Props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{header}</div>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles['body']}>
          {loading ? (
            <Loading size="md" />
          ) : (
            <>
              <div className={styles['text-section']}>
                <div className={styles['amount-wrapper']}>
                  <div className={styles['amount']}>
                    <div className={styles['amount-text']}>
                      {percent.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </div>
                    <div className={styles['amount-unit']}>%</div>
                  </div>
                  {percentChange != null && (
                    <div className={styles['previous']}>
                      <div className={styles['previous-text']}>
                        {(percent - percentChange).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div className={styles['previous-unit']}>%</div>
                    </div>
                  )}
                </div>
                {percentChange != null && (
                  <ChangeLabel amountChange={percentChange} unit="%" />
                )}
              </div>
              {percent != null && (
                <div className={styles['progress-bar']}>
                  {percent > 0 && (
                    <>
                      <div
                        className={styles['progress-bar-gray']}
                        style={{
                          width: '100%',
                        }}
                      />
                      <div
                        className={styles['progress-bar-filled']}
                        style={{
                          width: `${percent}%`,
                        }}
                      />
                    </>
                  )}
                  {percent <= 0 && (
                    <>
                      <div
                        className={styles['progress-bar-gray']}
                        style={{
                          width: '100%',
                        }}
                      />
                      <div
                        className={styles['progress-bar-filled']}
                        style={{
                          width: `${100 + percent}%`,
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
