import { currency } from 'shared/libraries/currency';
import type { Currency } from 'shared/libraries/currency';
import type { UnitPriceDelta } from 'shared/models/swagger';

export const applyPriceDeltaToGross = (
  gross: string,
  unitPriceDelta: UnitPriceDelta
): string => {
  const deltaPercent = unitPriceDelta.delta_percent;
  const deltaFixedGross = unitPriceDelta.delta_fixed_gross || '';
  let adjustmentAmountGross: Currency;

  if (deltaFixedGross) {
    adjustmentAmountGross = currency(deltaFixedGross);
  } else {
    adjustmentAmountGross = currency(gross).multiply((deltaPercent ?? 0) / 100);
  }

  let adjustedGross = '';

  if (unitPriceDelta.delta_type === 'CHARGE') {
    adjustedGross = currency(gross).add(adjustmentAmountGross).format();
  } else {
    adjustedGross = currency(gross).subtract(adjustmentAmountGross).format();
  }

  return adjustedGross;
};
export const applyPriceDeltaToNet = (
  net: string,
  unitPriceDelta: UnitPriceDelta
): string => {
  const deltaPercent = unitPriceDelta.delta_percent;
  const deltaFixedNet = unitPriceDelta.delta_fixed_net || '';
  let adjustmentAmountNet: Currency;

  if (deltaFixedNet) {
    adjustmentAmountNet = currency(deltaFixedNet);
  } else {
    adjustmentAmountNet = currency(net).multiply((deltaPercent ?? 0) / 100);
  }

  let adjustedNet = '';

  if (unitPriceDelta.delta_type === 'CHARGE') {
    adjustedNet = currency(net).add(adjustmentAmountNet).format();
  } else {
    adjustedNet = currency(net).subtract(adjustmentAmountNet).format();
  }

  return adjustedNet;
};
