import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import {
  Equipment,
  EquipmentInstance,
  EquipmentAssignment,
  EquipmentCellBlockMapping,
  EquipmentBlockInstanceProperty,
  Organization,
  EquipmentStartTimeMapping,
  ProductInstance,
  EquipmentAsset,
  EquipmentBlock,
} from 'shared/models/swagger';

import { SeatAssignmentModeType } from './SeatAssignmentContext';

export const NETWORK_PRINTER_IP_ADDRESS_KEY = 'network_printer_ip_address';

export const getEquipmentInstanceTotalCapacity = (
  equipmentInstance: EquipmentInstance | undefined,
  equipment: Equipment | undefined,
  activeUserOrganization: Organization | null
): number => {
  return getEquipmentBlockInstanceKeys(equipment).reduce((acc, cur) => {
    const property = getEquipmentBlockInstanceProperty(
      cur,
      equipment,
      equipmentInstance,
      activeUserOrganization
    );

    if (property?.is_closed) {
      return acc;
    }

    return acc + (property?.capacity ?? 0);
  }, 0);
};

export const getCurrentEquipmentBlockInstanceGuestCount = (
  equipmentBlockInstanceKey: string,
  equipmentAssignments: EquipmentAssignment[] | undefined
): number => {
  const equipmentAssignment = (equipmentAssignments || []).find(
    (assignment) => {
      return (
        assignment.equipment_block_instance_key === equipmentBlockInstanceKey
      );
    }
  );

  return (equipmentAssignment?.occupation?.guest_type_counts || []).reduce(
    (acc, cur) => {
      return acc + (cur.count ?? 0);
    },
    0
  );
};

export const getEquipmentInstanceGuestCount = (
  equipmentInstanceId: string,
  equipmentAssignments: EquipmentAssignment[] | undefined
): number => {
  return (equipmentAssignments || [])
    .filter((assignment) => {
      return assignment.equipment_instance_id === equipmentInstanceId;
    })
    .reduce((acc, cur) => {
      return (
        acc +
        (cur.occupation?.guest_type_counts?.reduce((acc, cur) => {
          return acc + (cur.count ?? 0);
        }, 0) ?? 0)
      );
    }, 0);
};

export const getCurrentReservationGuestCount = (
  reservationId: string,
  equipmentAssignments: EquipmentAssignment[]
): number => {
  return equipmentAssignments
    .filter((assignment) => {
      return assignment.reservation_id === reservationId;
    })
    .reduce((acc, cur) => {
      const assignedCount = cur.occupation?.guest_type_counts?.reduce(
        (acc, cur) => {
          return acc + (cur.count ?? 0);
        },
        0
      );

      return acc + (assignedCount ?? 0);
    }, 0);
};

export const findEquipmentAssignment = (
  equipmentBlockInstanceKey: string,
  equipmentAssignments: EquipmentAssignment[] | undefined
): EquipmentAssignment | null => {
  const equipmentAssignment = (equipmentAssignments || []).find(
    (assignment) => {
      return (
        assignment.equipment_block_instance_key === equipmentBlockInstanceKey
      );
    }
  );

  return equipmentAssignment ?? null;
};

export const getEquipmentCellBlockMapping = (
  row: number,
  column: number,
  equipment: Equipment
): EquipmentCellBlockMapping | null => {
  return (
    equipment?.equipment_cell_block_mappings?.find((mapping) => {
      return (mapping.row ?? 0) === row && (mapping.column ?? 0) === column;
    }) ?? null
  );
};

export const getEquipmentCellReservationIdMapping = (
  equipment: Equipment,
  equipmentAssignments: EquipmentAssignment[] | undefined
): string[][] => {
  const mapping: string[][] = [];

  for (let i = 0; i < (equipment?.row_count ?? 0) + 1; i++) {
    mapping.push([]);
    for (let j = 0; j < (equipment?.column_count ?? 0) + 1; j++) {
      mapping[i].push('');
    }
  }

  for (let i = 0; i < (equipment?.row_count ?? 0) + 1; i++) {
    for (let j = 0; j < (equipment?.column_count ?? 0) + 1; j++) {
      const cell = getEquipmentCellBlockMapping(i, j, equipment);
      if (!cell) {
        continue;
      }

      const equipmentAssignment = findEquipmentAssignment(
        cell.equipment_block_instance_key ?? '',
        equipmentAssignments
      );

      if (!equipmentAssignment) {
        continue;
      }

      mapping[i][j] = equipmentAssignment.reservation_id ?? '';
    }
  }

  return mapping;
};

export const getEquipmentBlockReference = (
  equipmentBlockInstanceKey: string,
  equipment: Equipment | undefined
): string => {
  for (let i = 0; i < (equipment?.row_count ?? 0) + 1; i++) {
    for (let j = 0; j < (equipment?.column_count ?? 0) + 1; j++) {
      const cellProps = getCellBlockMapping(i, j, equipment);

      if (
        cellProps?.equipment_block_instance_key === equipmentBlockInstanceKey
      ) {
        return `${convertToAlphabet(j + 1)}${i + 1}`;
      }
    }
  }
  return '';
};

export const getCellBlockMapping = (
  row: number,
  column: number,
  equipment: Equipment | undefined
) => {
  return equipment?.equipment_cell_block_mappings?.find((mapping) => {
    return (mapping.row ?? 0) === row && (mapping.column ?? 0) === column;
  });
};

export const getEquipmentAssignmentId = (
  equipmentInstanceId: string,
  equipmentBlockInstanceKey: string,
  equipmentAssignments: EquipmentAssignment[]
): string => {
  const equipmentAssignment = getEquipmentAssignment(
    equipmentInstanceId,
    equipmentBlockInstanceKey,
    equipmentAssignments
  );

  return equipmentAssignment?.id ?? '';
};

export const getEquipmentAssignment = (
  equipmentInstanceId: string,
  equipmentBlockInstanceKey: string,
  equipmentAssignments: EquipmentAssignment[]
): EquipmentAssignment | undefined => {
  const equipmentAssignment = equipmentAssignments.find((assignment) => {
    return (
      assignment.equipment_block_instance_key === equipmentBlockInstanceKey &&
      equipmentInstanceId === assignment.equipment_instance_id
    );
  });

  return equipmentAssignment;
};

export const getModeText = (
  editMode: SeatAssignmentModeType,
  t: TranslateFuncType
) => {
  switch (editMode) {
    case 'CREATE':
      return t('Create new reservation');
    case 'MOVE':
      return t('Move seats');
    case 'ASSIGN':
      return t('Assign seats');
    case 'EDIT':
      return t('Edit seat property');
    default:
      return t('No mode selected');
  }
};

export const findEquipmentBlockInstanceProperty = (
  equipmentBlockInstanceKey: string,
  equipmentInstanceId: string,
  equipments: Equipment[] | undefined,
  equipmentInstances: EquipmentInstance[] | undefined,
  activeUserOrganization: Organization | null
): EquipmentBlockInstanceProperty | null => {
  const equipmentInstance = equipmentInstances?.find((instance) => {
    return instance.id === equipmentInstanceId;
  });

  const equipment = equipments?.find((equipment) => {
    return equipment.id === equipmentInstance?.equipment_id;
  });

  return getEquipmentBlockInstanceProperty(
    equipmentBlockInstanceKey,
    equipment,
    equipmentInstance,
    activeUserOrganization
  );
};

export const getEquipmentBlockInstanceProperty = (
  equipmentBlockInstanceKey: string,
  equipment: Equipment | undefined,
  equipmentInstance: EquipmentInstance | undefined,
  activeUserOrganization: Organization | null
): EquipmentBlockInstanceProperty | null => {
  let wrappedProperty: EquipmentBlockInstanceProperty | null = {};

  if (equipment?.original_equipment_block_instance_properties) {
    const property =
      equipment?.original_equipment_block_instance_properties.find(
        (property) => {
          return (
            property.equipment_block_instance_key === equipmentBlockInstanceKey
          );
        }
      );
    if (property) {
      wrappedProperty = {
        ...wrappedProperty,
        ...property,
      };
    }
  }

  let equipmentColor = null;
  let equipmentAttribute = null;

  if (equipment?.equipment_block_instance_properties) {
    const property = equipment?.equipment_block_instance_properties.find(
      (property) => {
        return (
          property.equipment_block_instance_key === equipmentBlockInstanceKey
        );
      }
    );
    if (property) {
      if (property.override_color) {
        equipmentColor = property.color;
      }
      if (property.override_equipment_block_instance_attribute_key) {
        equipmentAttribute = (
          activeUserOrganization?.seat_management_settings
            ?.equipment_block_instance_attributes || []
        ).find((attribute) => {
          return (
            attribute.key === property?.equipment_block_instance_attribute_key
          );
        });
      }
      wrappedProperty = overrideEquipmentBlockInstanceProperty(
        wrappedProperty,
        property
      );
    }
  }

  let equipmentInstanceColor = null;
  let equipmentInstanceAttribute = null;

  if (equipmentInstance?.equipment_block_instance_properties) {
    const property =
      equipmentInstance?.equipment_block_instance_properties.find(
        (property) => {
          return (
            property.equipment_block_instance_key === equipmentBlockInstanceKey
          );
        }
      );
    if (property) {
      if (property.override_color) {
        equipmentInstanceColor = property.color;
      }
      if (property.override_equipment_block_instance_attribute_key) {
        equipmentInstanceAttribute = (
          activeUserOrganization?.seat_management_settings
            ?.equipment_block_instance_attributes || []
        ).find((attribute) => {
          return (
            attribute.key === property.equipment_block_instance_attribute_key
          );
        });
      }
      wrappedProperty = overrideEquipmentBlockInstanceProperty(
        wrappedProperty,
        property
      );
    }
  }

  wrappedProperty = {
    ...wrappedProperty,
    color:
      equipmentInstanceAttribute?.color ||
      equipmentInstanceColor ||
      equipmentAttribute?.color ||
      equipmentColor ||
      wrappedProperty?.color ||
      undefined,
  };

  return wrappedProperty;
};

export const overrideEquipmentBlockInstanceProperty = (
  property1: EquipmentBlockInstanceProperty | null,
  property2: EquipmentBlockInstanceProperty | null
): EquipmentBlockInstanceProperty | null => {
  if (!property1) {
    return property2;
  }

  if (!property2) {
    return property1;
  }

  const wrappedProperty = { ...property1 };

  if (property2.override_equipment_block_instance_attribute_key) {
    wrappedProperty.equipment_block_instance_attribute_key =
      property2.equipment_block_instance_attribute_key;
  }

  if (property2.override_color) {
    wrappedProperty.color = property2.color;
  }

  if (property2.override_capacity) {
    wrappedProperty.capacity = property2.capacity;
  }

  if (property2.override_is_closed) {
    wrappedProperty.is_closed = property2.is_closed;
  }

  if (property2.override_reference) {
    wrappedProperty.reference = property2.reference;
  }

  return wrappedProperty;
};

export const getEquipmentBlockInstanceKeys = (
  equipment: Equipment | undefined
): string[] => {
  const keys: string[] = [];

  equipment?.equipment_cell_block_mappings?.forEach((mapping) => {
    if (mapping.equipment_block_instance_key) {
      keys.push(mapping.equipment_block_instance_key);
    }
  });

  return [...new Set(keys)];
};

export const getEquipmentBlockInstancePropertyForEquipment = (
  equipmentBlockInstanceKey: string,
  equipment: Equipment | undefined
): EquipmentBlockInstanceProperty | null => {
  if (equipment?.equipment_block_instance_properties) {
    const property = equipment?.equipment_block_instance_properties.find(
      (property) => {
        return (
          property.equipment_block_instance_key === equipmentBlockInstanceKey
        );
      }
    );
    if (property) {
      return property;
    }
  }

  if (equipment?.original_equipment_block_instance_properties) {
    const property =
      equipment?.original_equipment_block_instance_properties.find(
        (property) => {
          return (
            property.equipment_block_instance_key === equipmentBlockInstanceKey
          );
        }
      );
    if (property) {
      return property;
    }
  }

  return null;
};

export const getEquipmentBlockInstancePriceKeyForEquipment = (
  equipmentBlockInstanceKey: string,
  equipment: Equipment | undefined
): string | null => {
  const mapping =
    equipment?.equipment_block_instance_price_settings?.equipment_block_instance_price_mappings?.find(
      (mapping) => {
        return (
          mapping.equipment_block_instance_key === equipmentBlockInstanceKey
        );
      }
    );
  if (mapping) {
    return mapping.equipment_block_instance_price_key ?? null;
  }

  return null;
};

export const getEquipmentBlockInstancePrice = (
  equipmentBlockInstanceKey: string,
  equipment: Equipment | undefined
): {
  equipment_block_instance_key?: string;
  equipment_block_instance_price_key?: string;
} | null => {
  if (equipment?.equipment_block_instance_price_settings) {
    const mapping =
      equipment?.equipment_block_instance_price_settings?.equipment_block_instance_price_mappings?.find(
        (mapping) => {
          return (
            mapping.equipment_block_instance_key === equipmentBlockInstanceKey
          );
        }
      );
    if (mapping) {
      return mapping;
    }
  }

  return null;
};

export const getEquipmentStartTimeMapping = (
  participationDate: string,
  startTimeKey: string,
  equipment: Equipment | undefined
): EquipmentStartTimeMapping | undefined => {
  const schedule = (equipment?.equipment_schedules ?? []).find((schedule) => {
    if (
      schedule.start_date_local &&
      schedule.start_date_local > participationDate
    ) {
      return false;
    }
    if (
      schedule.end_date_local &&
      schedule.end_date_local < participationDate
    ) {
      return false;
    }
    return true;
  });
  return (schedule?.start_time_mappings ?? []).find(
    (mapping) => mapping.key === startTimeKey
  );
};

export const filterProductInstancesByProductMapping = (
  productId: string,
  recurrenceKey: string,
  timeSlotKey: string,
  participationDate: string,
  timezone: string,
  productInstances: ProductInstance[]
): ProductInstance[] => {
  return productInstances.filter((productInstance) => {
    const startDate = moment
      .tz(productInstance.start_date_time_utc, timezone ?? 'UTC')
      .format('YYYY-MM-DD');
    return (
      productInstance.product_id === productId &&
      productInstance.recurrence_key === recurrenceKey &&
      productInstance.time_slot_key === timeSlotKey &&
      startDate === participationDate
    );
  });
};

export const getRowColumnSpanForEquipmentBlockInstance = (
  i: number,
  j: number,
  rowCount: number,
  columnCount: number,
  equipmentBlockInstanceKey: string,
  placedEquipmentBlockInstanceKeys: string[],
  equipmentCellBlockMappings: EquipmentCellBlockMapping[]
): { rowSpan: number; columnSpan: number; isSkipped: boolean } => {
  let columnSpan = 1;
  let rowSpan = 1;
  if (equipmentBlockInstanceKey) {
    if (placedEquipmentBlockInstanceKeys.includes(equipmentBlockInstanceKey)) {
      return { rowSpan: 0, columnSpan: 0, isSkipped: true };
    }
    placedEquipmentBlockInstanceKeys.push(equipmentBlockInstanceKey);

    for (; columnSpan + j < columnCount; columnSpan++) {
      const nextMapping = equipmentCellBlockMappings.find((mapping) => {
        return (
          (mapping.row ?? 0) === i && (mapping.column ?? 0) === j + columnSpan
        );
      });

      if (
        nextMapping &&
        nextMapping?.equipment_block_instance_key === equipmentBlockInstanceKey
      ) {
        continue;
      } else {
        break;
      }
    }

    for (; rowSpan + i < rowCount; rowSpan++) {
      const nextMapping = equipmentCellBlockMappings.find((mapping) => {
        return (
          (mapping.row ?? 0) === i + rowSpan && (mapping.column ?? 0) === j
        );
      });

      if (
        nextMapping &&
        nextMapping?.equipment_block_instance_key === equipmentBlockInstanceKey
      ) {
        continue;
      } else {
        break;
      }
    }
  }

  return { rowSpan: rowSpan, columnSpan: columnSpan, isSkipped: false };
};

export const getRowColumnSpanForEquipmentAssetInstance = (
  i: number,
  j: number,
  rowCount: number,
  columnCount: number,
  equipmentAssetInstanceKey: string,
  placedEquipmentAssetInstanceKeys: string[],
  equipmentCellBlockMappings: EquipmentCellBlockMapping[]
): { rowSpan: number; columnSpan: number; isSkipped: boolean } => {
  let columnSpan = 1;
  let rowSpan = 1;
  if (equipmentAssetInstanceKey) {
    if (placedEquipmentAssetInstanceKeys.includes(equipmentAssetInstanceKey)) {
      return { rowSpan: 0, columnSpan: 0, isSkipped: true };
    }
    placedEquipmentAssetInstanceKeys.push(equipmentAssetInstanceKey);

    for (; columnSpan + j < columnCount; columnSpan++) {
      const nextMapping = equipmentCellBlockMappings.find((mapping) => {
        return (
          (mapping.row ?? 0) === i && (mapping.column ?? 0) === j + columnSpan
        );
      });

      if (
        nextMapping &&
        nextMapping?.equipment_asset_instance_key === equipmentAssetInstanceKey
      ) {
        continue;
      } else {
        break;
      }
    }

    for (; rowSpan + i < rowCount; rowSpan++) {
      const nextMapping = equipmentCellBlockMappings.find((mapping) => {
        return (
          (mapping.row ?? 0) === i + rowSpan && (mapping.column ?? 0) === j
        );
      });

      if (
        nextMapping &&
        nextMapping?.equipment_asset_instance_key === equipmentAssetInstanceKey
      ) {
        continue;
      } else {
        break;
      }
    }
  }

  return { rowSpan: rowSpan, columnSpan: columnSpan, isSkipped: false };
};

export const getCellBorderProperty = (
  i: number,
  j: number,
  position: { row: number; column: number } | null,
  size: { rowCount: number; columnCount: number } | null
): {
  borderTop: boolean;
  borderBottom: boolean;
  borderLeft: boolean;
  borderRight: boolean;
} => {
  let borderTop = false;
  let borderLeft = false;
  let borderRight = false;
  let borderBottom = false;

  if (size && position) {
    if (
      position.column <= j &&
      j < position?.column + (size.columnCount ?? 0) &&
      position?.row <= i &&
      i < position?.row + (size.rowCount ?? 0)
    ) {
      if (position?.column === j) {
        borderLeft = true;
      }

      if (j === position?.column + (size.columnCount ?? 0) - 1) {
        borderRight = true;
      }

      if (position?.row === i) {
        borderTop = true;
      }

      if (i === position?.row + (size.rowCount ?? 0) - 1) {
        borderBottom = true;
      }
    }
  }
  return {
    borderTop: borderTop,
    borderBottom: borderBottom,
    borderLeft: borderLeft,
    borderRight: borderRight,
  };
};

export const getEquipmentRelatedProductIds = (
  equipment: Equipment | undefined,
  participationDate: string,
  startTimeKey: string
): string[] => {
  const mapping = getEquipmentStartTimeMapping(
    participationDate,
    startTimeKey,
    equipment
  );

  return (
    mapping?.product_start_times
      ?.filter((productStartTime) => {
        return Boolean(productStartTime.product_id);
      })
      .map((productStartTime) => {
        return productStartTime.product_id ?? '';
      }) ?? []
  );
};

export const getCorrectedPopupWindowPosition = (
  height: number,
  position?:
    | {
        left: number;
        top: number;
      }
    | null
    | undefined
): { left: number; top: number } => {
  let top = (position?.top ?? 0) - height - 12;
  let left = (position?.left ?? 0) - 64;

  if (top < 64) {
    top = 64;
    left = left - 100;
  }

  return {
    left: left,
    top: top,
  };
};

export const getAssignableEquipmentInstances = (
  selectedEquipmentInstanceId: string,
  equipmentInstances: EquipmentInstance[] | undefined
): EquipmentInstance[] => {
  const instances: EquipmentInstance[] = [];

  const selectedEquipmentInstance = equipmentInstances?.find((instance) => {
    return instance.id === selectedEquipmentInstanceId;
  });

  if (!selectedEquipmentInstance) {
    return instances;
  }

  instances.push(selectedEquipmentInstance);

  selectedEquipmentInstance.additional_equipment_settings?.additional_equipment_ids?.forEach(
    (additionalEquipmentId) => {
      const additionalEquipmentInstance = (equipmentInstances || []).find(
        (equipmentInstance) =>
          equipmentInstance.equipment_id === additionalEquipmentId &&
          equipmentInstance.original_equipment_instance_id ===
            selectedEquipmentInstance.id
      );

      if (!additionalEquipmentInstance) {
        return;
      }

      instances.push(additionalEquipmentInstance);
    }
  );

  return instances;
};

export const getAssignableEquipmentInstancesTotalCapacity = (
  selectedEquipmentInstanceId: string,
  equipmentInstances: EquipmentInstance[] | undefined
): number => {
  const assignableEquipmentInstances = getAssignableEquipmentInstances(
    selectedEquipmentInstanceId,
    equipmentInstances
  );

  return assignableEquipmentInstances.reduce((acc, equipmentInstance) => {
    let totalOfThisInstance = 0;
    if (
      !equipmentInstance?.additional_equipment_settings
        ?.close_original_equipment
    ) {
      totalOfThisInstance =
        equipmentInstance?.total_equipment_block_instance_count?.value || 0;
    }

    return acc + totalOfThisInstance;
  }, 0);
};

export const getAssignableEquipmentInstancesConsumedCapacity = (
  selectedEquipmentInstanceId: string,
  equipmentInstances: EquipmentInstance[] | undefined
): number => {
  const assignableEquipmentInstances = getAssignableEquipmentInstances(
    selectedEquipmentInstanceId,
    equipmentInstances
  );

  return assignableEquipmentInstances.reduce((acc, equipmentInstance) => {
    return (
      acc +
      (equipmentInstance?.consumed_equipment_block_instance_count?.value || 0)
    );
  }, 0);
};

export const isDraggingEquipmentBlockPlaceable = (
  i: number,
  j: number,
  rowCount: number,
  columnCount: number,
  equipmentAsset: EquipmentAsset | undefined,
  equipmentBlocks: EquipmentBlock[] | undefined
): boolean => {
  if (i + rowCount > (equipmentAsset?.row_count ?? 0)) {
    return false;
  }
  if (j + columnCount > (equipmentAsset?.column_count ?? 0)) {
    return false;
  }

  const overWrappedEquipmentBlockInstance =
    equipmentAsset?.equipment_block_instances?.find(
      (equipmentBlockInstance) => {
        const equipmentBlock = equipmentBlocks?.find((block) => {
          return block.id === equipmentBlockInstance.equipment_block_id;
        });

        if (!equipmentBlock) {
          return false;
        }

        if (
          j + columnCount <= (equipmentBlockInstance?.start_column ?? 0) ||
          (equipmentBlockInstance?.start_column ?? 0) +
            (equipmentBlock.column_count ?? 0) <=
            j
        ) {
          return false;
        }

        if (
          i + rowCount <= (equipmentBlockInstance?.start_row ?? 0) ||
          (equipmentBlockInstance?.start_row ?? 0) +
            (equipmentBlock?.row_count ?? 0) <=
            i
        ) {
          return false;
        }

        return true;
      }
    );

  return overWrappedEquipmentBlockInstance ? false : true;
};

export const isDraggingEquipmentAssetPlaceable = (
  i: number,
  j: number,
  rowCount: number,
  columnCount: number,
  equipment: Equipment | undefined,
  equipmentAssets: EquipmentAsset[] | undefined
): boolean => {
  if (i + rowCount > (equipment?.row_count ?? 0)) {
    return false;
  }
  if (j + columnCount > (equipment?.column_count ?? 0)) {
    return false;
  }

  const overWrappedEquipmentAssetInstance =
    equipment?.equipment_asset_instances?.find((equipmentAssetInstance) => {
      const equipmentAsset = equipmentAssets?.find((asset) => {
        return asset.id === equipmentAssetInstance.equipment_asset_id;
      });

      if (!equipmentAsset) {
        return false;
      }

      if (
        j + columnCount <= (equipmentAssetInstance?.start_column ?? 0) ||
        (equipmentAssetInstance?.start_column ?? 0) +
          (equipmentAsset.column_count ?? 0) <=
          j
      ) {
        return false;
      }

      if (
        i + rowCount <= (equipmentAssetInstance?.start_row ?? 0) ||
        (equipmentAssetInstance?.start_row ?? 0) +
          (equipmentAsset?.row_count ?? 0) <=
          i
      ) {
        return false;
      }

      return true;
    });

  return overWrappedEquipmentAssetInstance ? false : true;
};
