import * as React from 'react';

interface FileUpload {
  url: string;
}

interface Props {
  fileUrlString: string;
}

export const UploadedImageThumbnail = ({ fileUrlString }: Props) => {
  console.log('fileUrlString', fileUrlString);

  const uploadedFiles: FileUpload[] = React.useMemo(
    () => (fileUrlString ? JSON.parse(fileUrlString) : []),
    [fileUrlString]
  );

  console.log(uploadedFiles);
  return (
    <>
      {uploadedFiles.map((uploadedFile, index) => {
        return (
          <span key={index}>
            {index !== 0 && ', '}
            <img
              src={uploadedFile.url}
              style={{
                width: '100px',
              }}
            />
          </span>
        );
      })}
    </>
  );
};
