import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { localeSelector, ReduxState } from 'client/reducers';
import { fetchSiteControlWidget } from 'client/actions/siteControlWidgets';
import { Loading } from 'client/components/v3/Common/Loading';
import { currency } from 'shared/libraries/currency';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './SiteControlWidgetReport.module.css';

export const SiteControlWidgetReport = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);
  const org = useSelector(activeUserOrganizationSelector);

  const widget = useSelector((state: ReduxState) =>
    state.siteControlWidgets.all.find((w) => w.id === id)
  );
  const loading = useSelector(
    (state: ReduxState) => state.siteControlWidgets.loading
  );

  useEffect(() => {
    dispatch(fetchSiteControlWidget(id));
  }, [id]);

  const paths = [
    { text: t('Site Hacker List'), url: '/sitecontrolwidgets' },
    { text: t('Site Hacker Report'), url: '' },
  ];

  const targetedCount = widget?.targeted_count ?? 0;
  const impressionCount = widget?.impression_count ?? 0;
  const closedCount = widget?.closed_count ?? 0;
  const clickedCount = widget?.clicked_count ?? 0;
  const clickedRate =
    clickedCount && impressionCount
      ? Math.round((clickedCount / impressionCount) * 100 * 100) / 100
      : 0;
  const bookedCount = widget?.conversion_count ?? 0;
  const bookedRate =
    bookedCount && impressionCount
      ? Math.round((bookedCount / impressionCount) * 100 * 100) / 100
      : 0;
  // const totalSales = currencyjs(widget?.gross_sales ?? 0);
  const totalSales = currency(
    widget?.gross_sales ?? 0,
    org?.default_currency ?? ''
  ).format();

  return (
    <V3Page>
      <PageHeader
        title={t('Site Hacker Report')}
        hideNewUIToggle={true}
        breadcrumbPaths={paths}
      ></PageHeader>
      <PageContent>
        <div
          className={styles['p-siteControlWidget']}
          style={{ paddingBottom: '32px' }}
        >
          <section className={styles['p-section']}>
            <div className={styles['p-section__header']}>
              <p>{t('Summary')}</p>
            </div>
            <div className={styles['p-section__body']}>
              <div className={styles['p-summary']}>
                {loading && <Loading size="md" />}
                {!loading && (
                  <>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Last Updated Date')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>
                          {widget?.last_updated_date_time_utc
                            ? moment(widget.last_updated_date_time_utc)
                                .locale(locale)
                                .format('lll')
                            : ''}
                        </p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Title')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{widget?.name ?? ''}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Targets')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{targetedCount}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Impressions')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{impressionCount}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Closes')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{closedCount}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Clicks')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{clickedCount}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Click Rate')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{clickedRate}%</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Reservations')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{bookedCount}</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Reservation Rate')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>{bookedRate}%</p>
                      </div>
                    </div>
                    <div className={styles['p-summary__item']}>
                      <div className={styles['p-summary__item__header']}>
                        <p>{t('Sales')}</p>
                      </div>
                      <div className={styles['p-summary__item__body']}>
                        <p>
                          {locale === 'ja'
                            ? `${formattedCurrencyAmount(totalSales).slice(
                                3
                              )}円`
                            : formattedCurrencyAmount(totalSales)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </PageContent>
    </V3Page>
  );
};
