import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from 'client/actions';
import type { ReduxState } from 'client/reducers';

import langIcon from '../../images/ic_lang.svg';
import styles from '../../base.module.css';

export const LanguageSelect = () => {
  const languageOptions = useSelector(
    (state: ReduxState) => state.language.options
  );
  const selectedLanguage = useSelector(
    (state: ReduxState) => state.language.selected
  );
  const dispatch = useDispatch();
  return (
    <label className={styles['base-header__info__lang']}>
      <img src={langIcon} />
      <select
        name="lang"
        value={selectedLanguage.iso}
        onChange={(e) => {
          dispatch(setLanguage(e.target.value));
        }}
      >
        {languageOptions.map((l) => (
          <option key={l.iso} value={l.iso}>
            {l.text}
          </option>
        ))}
      </select>
    </label>
  );
};
