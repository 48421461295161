import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import clsx from 'clsx';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { fetchProducts } from 'client/actions/products';
import {
  createNewsletter,
  fetchNewsletter,
  updateNewsletter,
} from 'client/actions/newsletters';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';

import {
  convertFormValuesToSwagger,
  convertSwaggerToFormValues,
  FormValues,
} from './formValues';
import { ComposeMessage } from './ComposeMessage';
import { TemplateSelect } from './TemplateSelect';
import styles from './SendNewsletterWizard.module.css';
import { TargetSelect } from './TargetSelect';
import { ConfirmAndSend } from './ConfirmAndSend';
import { Complete } from './Complete';

type Step =
  | 'TEMPLATE_SELECT'
  | 'MESSAGE'
  | 'TARGET_SELECT'
  | 'CONFIRM_AND_SEND'
  | 'COMPLETE';

export const SendNewsletterWizard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);
  const [step, setStep] = React.useState<Step>(
    id ? 'MESSAGE' : 'TEMPLATE_SELECT'
  );
  const store = useStore();

  const existingNewsletter = useSelector((state: ReduxState) =>
    state.newsletters.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    if (id && !existingNewsletter) {
      dispatch(fetchNewsletter(id));
    }
  }, [id, existingNewsletter]);

  const initialValues = React.useMemo(() => {
    if (existingNewsletter) {
      return convertSwaggerToFormValues(existingNewsletter);
    }

    return {};
  }, [existingNewsletter]);

  if (id && !existingNewsletter) {
    return <Loading />;
  }

  if (
    step === 'TEMPLATE_SELECT' ||
    step === 'MESSAGE' ||
    step === 'TARGET_SELECT' ||
    step === 'CONFIRM_AND_SEND' ||
    step === 'COMPLETE'
  ) {
    return (
      <>
        {(step === 'TEMPLATE_SELECT' ||
          step === 'MESSAGE' ||
          step === 'TARGET_SELECT' ||
          step === 'CONFIRM_AND_SEND') && (
          <ul className={styles['steps-nav']}>
            <li
              className={clsx(
                step === 'TEMPLATE_SELECT' && styles['is-active']
              )}
            >
              STEP1<span> - {t('Select Template')}</span>
            </li>
            <li className={clsx(step === 'MESSAGE' && styles['is-active'])}>
              STEP2<span> - {t('Compose Message')}</span>
            </li>
            <li
              className={clsx(step === 'TARGET_SELECT' && styles['is-active'])}
            >
              STEP3<span> - {t('Select Targets')}</span>
            </li>
            <li
              className={clsx(
                step === 'CONFIRM_AND_SEND' && styles['is-active']
              )}
            >
              STEP4<span> - {t('Confirm & Send')}</span>
            </li>
          </ul>
        )}
        <Form
          onSubmit={async (values: FormValues) => {
            if (id) {
              await dispatch(
                updateNewsletter(id, {
                  ...convertFormValuesToSwagger(values),
                  status:
                    step === 'CONFIRM_AND_SEND' ? 'READY_TO_SEND' : 'DRAFT',
                })
              );
            } else {
              await dispatch(
                createNewsletter({
                  ...convertFormValuesToSwagger(values),
                  status: 'DRAFT',
                })
              );

              if (store.getState().newsletters.lastCreatedNewsletter) {
                history.push(
                  `/newsletters/${
                    store.getState().newsletters.lastCreatedNewsletter.id
                  }/edit`
                );
              }
            }
            switch (step) {
              case 'TEMPLATE_SELECT':
                setStep('MESSAGE');
                break;
              case 'MESSAGE':
                setStep('TARGET_SELECT');
                break;
              case 'TARGET_SELECT':
                setStep('CONFIRM_AND_SEND');
                break;
              case 'CONFIRM_AND_SEND':
                setStep('COMPLETE');
            }
          }}
          initialValues={initialValues}
        >
          {({ handleSubmit, values }) => {
            const onSave = async () => {
              if (id) {
                await dispatch(
                  updateNewsletter(id, {
                    ...convertFormValuesToSwagger(values),
                    status: 'DRAFT',
                  })
                );
              } else {
                await dispatch(
                  createNewsletter(convertFormValuesToSwagger(values))
                );
              }

              history.push('/newsletters');
            };

            return (
              <form onSubmit={handleSubmit}>
                {step === 'TEMPLATE_SELECT' && <TemplateSelect />}
                {step === 'MESSAGE' && (
                  <ComposeMessage
                    onSave={onSave}
                    onBackClick={() => setStep('TEMPLATE_SELECT')}
                  />
                )}
                {step === 'TARGET_SELECT' && (
                  <TargetSelect
                    onSave={onSave}
                    onBackClick={() => setStep('MESSAGE')}
                  />
                )}
                {step === 'CONFIRM_AND_SEND' && (
                  <ConfirmAndSend
                    onSave={onSave}
                    onBackClick={() => setStep('TARGET_SELECT')}
                  />
                )}
                {step === 'COMPLETE' && <Complete />}
              </form>
            );
          }}
        </Form>
      </>
    );
  }

  // We shouldn't reach here
  return null;
};
