import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateCustomer } from 'client/actions/customers';

import { CustomerContext } from './CustomerContext';

interface FormValues {
  tags: string[];
}

interface Props {
  onClose: () => void;
}

export const EditTagsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customer = React.useContext(CustomerContext);

  const initialValues = React.useMemo(
    () => ({
      tags: customer?.tags ?? [],
    }),
    [customer]
  );

  return (
    <Modal insertRoot title={t('Edit Tags')} open={true} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateCustomer(customer?.id ?? '', {
              ...customer,
              tags: values.tags.length > 0 ? values.tags : [''],
            })
          );
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <ListEditor name="tags" />
            </Modal.Content>
            <Modal.Actions>
              <Button style="gray" size="middle" onClick={() => form.reset}>
                {t('Discard')}
              </Button>
              <Button
                style="blue"
                size="middle"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
