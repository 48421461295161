import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';

import {
  clearProductInstances,
  fetchProductInstances,
} from 'client/actions/productInstances';
import { makeProductInstancesSelector } from 'client/reducers/productInstances';
import { activeUserSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { operationAllowed } from 'shared/models/access';
import { toProductInstanceShape } from 'client/libraries/util/productInstanceShape';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import type { ReduxState } from 'client/reducers';
import { getCustomCalendarEvents } from 'client/libraries/util/getCustomCalendarEvents';
import { CustomCalendar } from 'client/components/CustomCalendar/CustomCalendar';
import { ProductInstanceModal } from 'client/components/ProductInstanceModal/ProductInstanceModal';
import { ReservationListModal } from 'client/components/ReservationListModal/ReservationListModal';
import type { ProductInstance, Reservation } from 'shared/models/swagger';

type ReservationListContext = {
  productIds: string[];
  rootProductInstanceId: string;
  date: string;
};
type State = {
  startDate: Moment;
  endDate: Moment;
};
type Props = {
  defaultDate: Moment;
  loading: boolean;
  onChangeProduct?: (arg0: string) => void;
  onClose: (arg0: void) => void;
  onEditClick?: (arg0: ProductInstance) => void;
  productId: string;
  showProductSelector?: boolean;
  reservation?: Reservation;
  isChangeReservation?: boolean;
  onSelectProductInstanceError?: (arg0: string) => void;
};
export const ProductInstanceCalendar = ({
  defaultDate,
  loading,
  onChangeProduct,
  onClose,
  onEditClick,
  productId,
  showProductSelector,
  reservation,
  isChangeReservation,
  onSelectProductInstanceError,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>({
    startDate: moment(defaultDate).startOf('month').subtract(7, 'days'),
    endDate: moment(defaultDate).endOf('month').add(7, 'days'),
  });
  const [editingProductInstance, setEditingProductInstance] =
    React.useState<null | ProductInstance>(null);
  const [reservationListContext, setReservationListContext] =
    React.useState<null | ReservationListContext>(null);
  const activeUser = useSelector(activeUserSelector);
  const product = useSelector(
    (state: ReduxState) => state.products.byID[productId]
  );
  const productInstances = useSelector(makeProductInstancesSelector(productId));
  const products = useSelector(summariesSortedByBookmarkedSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  React.useEffect(() => {
    if (productId) {
      // Clear product instances before fetching. This is necessary to avoid showing old data.
      dispatch(clearProductInstances());
      dispatch(
        fetchProductInstances(productId, state.startDate, state.endDate)
      );
    }
  }, [productId, state.startDate, state.endDate]);
  React.useEffect(() => {
    if (productId) {
      dispatch(fetchProductByID(productId));
    }
  }, [productId]);
  React.useEffect(() => {
    if (showProductSelector) {
      dispatch(fetchProducts());
    }
  }, [showProductSelector, locale]);

  const onRangeChange = (range: any) => {
    let startDate: Moment;
    let endDate: Moment;

    if (range.start && range.end) {
      // 'month' view returns a {start: Date, end: Date} object
      startDate = moment(range.start).subtract(1, 'days');
      endDate = moment(range.end).add(1, 'days');
    } else {
      // 'day' and 'week' views return an array
      startDate = moment(range[0]).subtract(1, 'days');
      endDate = moment(range[range.length - 1]).add(1, 'days');
    }

    if (
      !operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      endDate.isSameOrBefore(moment())
    ) {
      // We won't show past product instances unless the user can confirm reservations so prevent fetch
      return;
    }

    setState({ ...state, startDate, endDate });
  };

  const onEventClick = (productInstance: ProductInstance | null) => {
    setEditingProductInstance(productInstance);
  };

  const onReservationListClick = (
    reservationListContext: ReservationListContext | null
  ) => {
    setReservationListContext(reservationListContext);
  };

  const timezone = (product && product.start_timezone) || 'UTC';
  return (
    <>
      <CustomCalendar
        onClose={onClose}
        events={
          loading
            ? null
            : getCustomCalendarEvents(
                activeUser,
                timezone,
                productInstances,
                locale,
                t,
                product?.request_booking_settings
                  ?.should_reject_bookings_beyond_capacity ?? false
              )
        }
        timezone={timezone}
        locale={locale}
        product={product}
        onRangeChange={onRangeChange}
        onEventClick={onEventClick}
        loading={loading}
        showProductSelector={showProductSelector}
        products={products}
        onChangeProduct={onChangeProduct}
        productSelectorIsSearchable={true}
      />

      {editingProductInstance && (
        <ProductInstanceModal
          insertRoot={true}
          productName={getDisplayProductName(product)}
          allotmentSettings={product?.allotment_settings ?? null}
          productInstance={editingProductInstance}
          timezone={timezone}
          onEditClick={onEditClick}
          instance={toProductInstanceShape(editingProductInstance)}
          open={editingProductInstance !== null}
          onClose={() => {
            setEditingProductInstance(null);
          }}
          onSameProductInstanceIsSelected={() => {
            setEditingProductInstance(null);
            onClose();
          }}
          onListClick={() => {
            onReservationListClick({
              productIds: [product.id],
              rootProductInstanceId: editingProductInstance?.id ?? '',
              date: moment(editingProductInstance?.start_date_time_utc)
                .tz(timezone)
                .format('YYYY-MM-DD'),
            });
          }}
          reservation={reservation}
          isChangeReservation={isChangeReservation}
          onSelectProductInstanceError={onSelectProductInstanceError}
          shouldRejectBookingsBeyondCapacity={
            product?.request_booking_settings
              ?.should_reject_bookings_beyond_capacity ?? false
          }
        />
      )}

      {reservationListContext && (
        <ReservationListModal
          insertRoot={true}
          productIds={reservationListContext?.productIds || []}
          rootProductInstanceId={reservationListContext?.rootProductInstanceId}
          date={reservationListContext?.date || ''}
          onClose={() => onReservationListClick(null)}
          open={reservationListContext !== null}
        />
      )}
    </>
  );
};
