type ColumnWidthString = 'short' | 'middle' | 'long' | 'longx2';
export type ColumnWidth = ColumnWidthString | string;

export type Column = {
  Header: string;
  accessor?: string | ((arg0: any) => string);
  width?: ColumnWidth;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  sub?: boolean;
};

export const calcWidthToPx = (width: ColumnWidth | undefined): number => {
  if (typeof width === 'undefined') {
    return 80;
  }
  if (width === 'short') return 110;
  if (width === 'middle') return 140;
  if (width === 'long') return 220;
  if (width === 'longx2') return 320;

  return parseInt(width);
};
