import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useFormState, useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Box } from 'client/components/Box/Box';
import { Button, Input, Radio, Select } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { matchesFormat } from 'shared/libraries/validate/validator';
import {
  isCheckinFully,
  noMoreAvailable,
  getStubText,
  getRemainingGuestsToCheckin,
} from 'client/libraries/checkin';
import baseStyles from 'client/base.module.css';
import { Reservation } from 'shared/models/swagger';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkUnfinishedIcon from 'client/images/ic_check_unfinished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';

import styles from './CheckinReservation.module.css';

const range = (start: number, stop: number): number[] => {
  return [...Array(stop - start + 1).keys()].map((item) => item + start);
};

const getPackageComponentProductDescription = (
  reservation: Reservation,
  componentReservationId: string
): string => {
  const component = (
    reservation?.package_component_reservation_summaries ?? []
  ).find(
    (componentSummary) =>
      componentSummary.reservation_id === componentReservationId
  );

  if (component == null) {
    return '';
  }

  let productCount = 0;
  for (const componentReservation of reservation?.package_component_reservation_summaries ??
    []) {
    if (component.product_id === componentReservation.product_id) {
      productCount++;
    }
  }

  const componentStartMoment = moment.tz(
    component.start_date_time_utc,
    reservation.start_timezone ?? 'UTC'
  );

  if (productCount > 1) {
    return `[${componentStartMoment.format('YYYY-MM-DD HH:mm')}] ${
      component.product_name ?? ''
    }`;
  }

  return component.product_name ?? '';
};

const todayIsParticipationDate = (reservation: Reservation): boolean => {
  return [
    reservation?.start_date_time_utc,
    ...(reservation.package_component_reservation_summaries ?? []).map(
      (reservationSummary) => reservationSummary.start_date_time_utc
    ),
  ].some((dateTime) =>
    isToday(dateTime ?? '', reservation.start_timezone ?? '')
  );
};

const isToday = (dateTimeUtc: string, timezone: string): boolean => {
  const today = moment().tz(timezone).format('YYYY-MM-DD');
  return moment.tz(dateTimeUtc, timezone).format('YYYY-MM-DD') === today;
};

export const CheckinReservationForm = () => {
  const { t } = useTranslation();

  const form = useForm();

  const { id } = useParams<{ id: string }>();

  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[id || '']
  );

  const validateEmailAddress = React.useCallback((email?: any) => {
    if (!email) return undefined;

    if (!matchesFormat(email, 'email')) return t('Invalid Email');

    return undefined;
  }, []);

  const shouldCountGuests =
    reservation?.checkin_info?.should_count_guests_for_checkin ?? false;
  const reservationEmail = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  const checkinRecords = reservation?.checkin_info?.checkin_records ?? [];
  const checkinStatus = reservation
    ? reservation?.checkin_info?.checkin_status
    : 'NOT_CHECKED_IN';

  const formState = useFormState();

  const selectedStubKey = formState.values?.stubKey;

  if (reservation != null && reservation.status !== 'CONFIRMED') {
    return (
      <div className={baseStyles['base-main__body__box']}>
        <Box mt={4} mb={4} ml={2} className={styles['err']}>
          {t('Checkin not available for the scanned QR code.')}
        </Box>
      </div>
    );
  }

  const packageComponentOptions = (
    reservation?.package_component_reservation_summaries ?? []
  ).map((reservationSummary) => ({
    value: reservationSummary.reservation_id,
    text: getPackageComponentProductDescription(
      reservation,
      reservationSummary.reservation_id ?? ''
    ),
  }));

  const stubs = reservation?.checkin_info?.stubs ?? [];

  const isExpired = reservation?.checkin_info?.expiration_date_time_utc
    ? moment().isAfter(
        moment.tz(
          reservation?.checkin_info?.expiration_date_time_utc,
          reservation?.start_timezone ?? 'UTC'
        )
      )
    : false;

  let checkinDisabledReason = '';
  if (checkinStatus === 'CHECKED_IN') {
    checkinDisabledReason = t('Checkin already completed');
  } else if (isExpired) {
    // if the checkin is started, need to check the expiration date
    checkinDisabledReason = t('The QR code is Expired.');
  } else if (
    // If the checkin is not started, need to check the participation date
    checkinStatus === 'NOT_CHECKED_IN' &&
    reservation &&
    !todayIsParticipationDate(reservation)
  ) {
    checkinDisabledReason = t('Checkin must be on the day of participation.');
  } else if (selectedStubKey) {
    // if the product is a stub, need to check the availability
    if (
      isCheckinFully(
        selectedStubKey ?? '',
        reservation?.checkin_info,
        (reservation?.guests ?? []).length
      )
    ) {
      checkinDisabledReason = t('The facility checkin already completed.');
    } else if (
      noMoreAvailable(selectedStubKey ?? '', reservation?.checkin_info)
    ) {
      checkinDisabledReason = t(
        'Since the maximum usage limit has been reached, checkin to different facilities is not allowed.'
      );
    }
  }

  const remainingGuestsToCheckin = getRemainingGuestsToCheckin(
    selectedStubKey,
    reservation?.checkin_info,
    (reservation?.guests ?? []).length
  );

  React.useEffect(() => {
    form.change('guestCount', String(remainingGuestsToCheckin));
  }, [remainingGuestsToCheckin]);

  return (
    <>
      <FormTableBox>
        <table>
          <tbody>
            <tr>
              <th>{t('Checkin Records')}</th>
              <td>
                {checkinStatus === 'NOT_CHECKED_IN' && (
                  <Box display="flex" alignItems="center">
                    <img src={checkUnfinishedIcon} />
                    <Box ml={2}>{t('Not yet checked in')}</Box>
                  </Box>
                )}
                {(checkinStatus === 'CHECKED_IN' ||
                  checkinStatus === 'IN_PROGRESS') && (
                  <Box display="flex" alignItems="center">
                    {checkinStatus === 'CHECKED_IN' && (
                      <img src={checkFinishedIcon} />
                    )}
                    {checkinStatus === 'IN_PROGRESS' && (
                      <img src={checkInProgressIcon} />
                    )}
                    {
                      <Box ml={2}>
                        {checkinRecords.map((record, idx) => (
                          <div key={idx}>
                            {packageComponentOptions.length > 0
                              ? `${moment(record.date_time_utc).format(
                                  'YYYY/MM/DD HH:mm'
                                )}   ${t(
                                  '{{count}} guest checked in ({{packageDescription}})',
                                  {
                                    count: record.guest_count,
                                    packageDescription:
                                      record.package_component_reservation_id
                                        ? getPackageComponentProductDescription(
                                            reservation,
                                            record.package_component_reservation_id
                                          )
                                        : t('checkin all at once'),
                                  }
                                )}`
                              : `${moment(record.date_time_utc).format(
                                  'YYYY/MM/DD HH:mm'
                                )} ${getStubText(
                                  record.stub_key ?? '',
                                  reservation?.checkin_info
                                )}  ${t('{{count}} guest checked in', {
                                  count: record.guest_count,
                                })}`}
                          </div>
                        ))}
                      </Box>
                    }
                  </Box>
                )}
              </td>
            </tr>

            {stubs.length > 0 && (
              <tr>
                <th>{t('Usage')}</th>
                <td>
                  <Field name="stubKey">
                    {({ input }) => (
                      <Select
                        width={200}
                        options={stubs.map((stub) => ({
                          value: stub.key ?? '',
                          text: stub.text ?? '',
                        }))}
                        value={input.value}
                        onChange={(e, { value }) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                </td>
              </tr>
            )}

            {checkinStatus !== 'CHECKED_IN' && (
              <tr>
                <th>{t('Checkin Guests')}</th>
                <td>
                  {shouldCountGuests ? (
                    <Field name="guestCount">
                      {({ input }) => (
                        <Select
                          width={200}
                          options={
                            remainingGuestsToCheckin > 0
                              ? range(1, remainingGuestsToCheckin).map((x) => ({
                                  value: String(x),
                                  text: `${x}`,
                                }))
                              : [
                                  {
                                    value: '0',
                                    text: '0',
                                  },
                                ]
                          }
                          value={input.value}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  ) : (
                    (reservation?.guests ?? []).length
                  )}
                </td>
              </tr>
            )}

            <tr>
              <th>{t('Email')}</th>
              <td>
                {reservationEmail ? (
                  reservationEmail
                ) : checkinStatus !== 'CHECKED_IN' ? (
                  <Field name="email" validate={validateEmailAddress}>
                    {({ input, meta: { touched, error } }) => (
                      <Input {...input} error={touched && error} />
                    )}
                  </Field>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            {packageComponentOptions.length > 0 && (
              <tr>
                <th>{t('Package')}</th>
                <td>
                  <Field name="packageComponentReservationId">
                    {({ input }) => (
                      <ul>
                        <li>
                          <Radio
                            label={t('Check-in to all component products')}
                            checked={input.value === ''}
                            onChange={() => {
                              input.onChange('');
                            }}
                          />
                        </li>
                        {packageComponentOptions.map((option, idx) => (
                          <li key={idx}>
                            <Radio
                              label={option.text}
                              checked={input.value === option.value}
                              onChange={() => {
                                input.onChange(option.value);
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </Field>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </FormTableBox>
      <Box mt={4} display="flex" alignItems="center">
        <Button
          disabled={Boolean(checkinDisabledReason)}
          size="middle"
          style="blue"
          type="submit"
        >
          {t('Checkin')}
        </Button>
        {checkinDisabledReason && (
          <Box ml={2} className={styles['err']}>
            {checkinDisabledReason}
          </Box>
        )}
      </Box>
    </>
  );
};
