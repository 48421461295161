import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { sendMediaDownloadOfferEmail } from 'client/actions/reservations';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { useMediaDownloadOrders } from 'client/hooks/useMediaDownloadOrders';
import { useMediaDownloadSalesPages } from 'client/hooks/useMediaDownloadSalesPages';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { SendMediaDownloadOfferEmailModal } from './SendMediaDownloadOfferEmailModal';
import { ReservationMediaDownloadOrderList } from './ReservationMediaDownloadOrderList';
import styles from './ReservationMediaDownloadsTab.module.css';

type Props = {
  reservation?: Reservation;
};
export const ReservationMediaDownloadsTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const [emailSendingStatus, setEmailSendingStatus] = React.useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const { orders } = useMediaDownloadOrders({
    reservationId: reservation?.id ?? '',
  });
  const { salesPages } = useMediaDownloadSalesPages({
    reservationId: reservation?.id ?? '',
  });
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;
  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Image/Video Downloads')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {orders.length === 0 && salesPages.length === 0 && (
          <Box mb={2}>
            {t(
              "No images have been published for this reservation's product and start time"
            )}
          </Box>
        )}
        {orders.length === 0 &&
          salesPages.length > 0 &&
          !isSubscriptionCancelled(
            activeOrganization,
            'feature-image-download'
          ) && (
            <>
              <Box mb={4}>
                {t(
                  'Images/videos downloads are available for this reservation but the customer has not made any purchases'
                )}
              </Box>
              <SendMediaDownloadOfferEmailModal
                initialToAddress={email}
                trigger={
                  <Button
                    style="green"
                    size="middle"
                    disabled={emailSendingStatus === 'SUCCEEDED'}
                    loading={emailSendingStatus === 'REQUESTED'}
                  >
                    {t('Send Photo Download Offer Email')}
                  </Button>
                }
                onSubmit={(email?: string) => {
                  try {
                    setEmailSendingStatus('REQUESTED');
                    dispatch(
                      sendMediaDownloadOfferEmail(reservation?.id ?? '', email)
                    );
                    setEmailSendingStatus('SUCCEEDED');
                  } catch (e) {
                    setEmailSendingStatus('FAILED');
                  }
                }}
              />
              {emailSendingStatus == 'SUCCEEDED' && (
                <Message success header={t('Send Successful')} />
              )}
              {emailSendingStatus == 'FAILED' && (
                <Message error header={t('Send Failed')} />
              )}
            </>
          )}
        {orders.length === 0 &&
          salesPages.length > 0 &&
          isSubscriptionCancelled(
            activeOrganization,
            'feature-image-download'
          ) && (
            <>
              {t(
                'Your subscription to the image download feature has been deactivated so sending image download offer emails is disabled. Resubscribe here: '
              )}
              <a
                className={styles['link']}
                href={`${window.origin}/systemfee/settings`}
                target="_blank"
                rel="noreferrer"
              >
                {t('Settings')}
              </a>
            </>
          )}
        {orders.length > 0 && (
          <ReservationMediaDownloadOrderList orders={orders} />
        )}
      </div>
    </div>
  );
};
