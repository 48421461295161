// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Input, Button, TextArea } from 'client/components/Form';
import { updateReservation } from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';

type Props = {
  reservation: Reservation,
  trigger: React.Element<'a'>,
};

export const ReservationMainInformationUpdateModal = ({
  reservation,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [patch, setPatch] = React.useState({
    supplier_reference: reservation.supplier_reference,
    supplier_notes: reservation.supplier_notes ?? '',
  });

  React.useEffect(() => {
    setPatch({
      ...patch,
      supplier_notes: reservation.supplier_notes ?? '',
    });
  }, [reservation.supplier_notes]);

  return (
    <Modal
      title={t('Update')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Modal.Box>
          <Input
            label={t('Confirmation Number')}
            value={patch['supplier_reference'] || ''}
            onChange={(e, { value }) => {
              setPatch({
                ...patch,
                supplier_reference: value,
              });
            }}
          />
        </Modal.Box>
        <Modal.Box>
          <TextArea
            label={t('Replies')}
            value={patch.supplier_notes}
            onChange={(e: any) => {
              setPatch({
                ...patch,
                supplier_notes: e.target.value,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel onClick={() => setShowModal(false)}>
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            dispatch(updateReservation(reservation.id, patch));
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
