import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  useScrollButton?: boolean;
  shouldOmitMobileFirstChildStyles?: boolean;
  hideSeeMoreButton?: boolean;
};
export type CustomTableColumn = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  accessor?: string | ((arg0: any) => string);
  width?: string;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  id?: string;
};

const isPixel = (width: string | null): boolean => {
  if (!width) {
    return false;
  }

  if (width.match(/\d+px/)) {
    return true;
  }

  return false;
};

export const CustomTable = ({
  items,
  columns,
  usePaging,
  useScrollButton,
  shouldOmitMobileFirstChildStyles,
  hideSeeMoreButton,
}: Props) => {
  const { t } = useTranslation();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    setRowCount(count);
    setCurrentPage(1);
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (items.length < rowCount * page) {
      page = Math.floor(items.length / rowCount);
    }

    setCurrentPage(page);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [items, rowCount]);
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [showColumns, setShowColumns] = React.useState(
    [...Array(items.length)].map(() => false)
  );
  return (
    <>
      <div
        className={clsx(
          componentStyles['c-table-main'],
          !shouldOmitMobileFirstChildStyles && componentStyles['mobile-header']
        )}
      >
        <div
          className={clsx(componentStyles['c-table-main__thead'])}
          ref={tableHeaderRef}
        >
          <table>
            <tbody>
              <tr>
                {columns.map((column, idx) => {
                  return (
                    <th
                      className={clsx(
                        isPixel(column.width || null)
                          ? ''
                          : baseStyles[
                              ('base-r-t-' +
                                `${
                                  column.width || 'short'
                                }`) as keyof typeof baseStyles
                            ]
                      )}
                      key={idx}
                      style={{
                        ...(isPixel(column.width || null)
                          ? { width: column.width }
                          : {}),
                      }}
                    >
                      {column.Header}
                      {column.HeaderElement}
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={clsx(componentStyles['c-table-main__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table>
            <tbody>
              {items
                .filter((item, index) => {
                  if (index < rowHead) {
                    return false;
                  }

                  if (rowHead + rowCount <= index) {
                    return false;
                  }

                  return true;
                })
                .map((item, ridx) => {
                  const rows = [];

                  const subRowCount =
                    _.max(
                      columns.map(
                        (col) =>
                          (col.CellArray && col.CellArray(item)?.length) || 1
                      )
                    ) ?? 1;

                  for (
                    let subRowIdx = 0;
                    subRowIdx < subRowCount;
                    subRowIdx++
                  ) {
                    const row = (
                      <tr
                        className={clsx(
                          subRowIdx > 0 && componentStyles['subrow'],
                          subRowIdx === subRowCount - 1 &&
                            componentStyles[
                              'last-subrow' as keyof typeof componentStyles
                            ]
                        )}
                        key={`${ridx}-${subRowIdx}`}
                      >
                        {columns.map((column, idx) => {
                          if (subRowIdx > 0 && !column.CellArray) {
                            return null;
                          }

                          const rowSpan = column.CellArray ? 1 : subRowCount;
                          let value: any;
                          let displayValue: any;

                          if (typeof column.accessor === 'string') {
                            value = item[column.accessor];
                          } else if (typeof column.accessor === 'function') {
                            value = column.accessor(item);
                          }

                          if (column.CellArray) {
                            const cellArray = column.CellArray(item);
                            displayValue =
                              subRowIdx < cellArray.length
                                ? cellArray[subRowIdx]
                                : '';
                          } else if (column.Cell) {
                            const cell = {
                              value: value,
                              original: item,
                              rowIndex: ridx,
                            };
                            displayValue = column.Cell(cell);
                          } else {
                            displayValue = value;
                          }

                          if (column.th) {
                            return (
                              <th
                                rowSpan={rowSpan}
                                className={clsx(
                                  isPixel(column.width || null)
                                    ? ''
                                    : baseStyles[
                                        ('base-r-t-' +
                                          `${
                                            column.width || 'short'
                                          }`) as keyof typeof baseStyles
                                      ]
                                )}
                                key={`${ridx}-${subRowIdx}-${idx}`}
                                style={{
                                  ...(isPixel(column.width || null)
                                    ? { width: column.width }
                                    : {}),
                                  ...(column.textAlign
                                    ? {
                                        textAlign: column.textAlign,
                                      }
                                    : {}),
                                }}
                              >
                                {displayValue}
                              </th>
                            );
                          } else {
                            return (
                              <td
                                rowSpan={rowSpan}
                                className={clsx(
                                  isPixel(column.width || null)
                                    ? ''
                                    : baseStyles[
                                        ('base-r-t-' +
                                          `${
                                            column.width || 'short'
                                          }`) as keyof typeof baseStyles
                                      ],
                                  6 < idx
                                    ? showColumns[ridx]
                                      ? ''
                                      : baseStyles['base-t-spHidden']
                                    : '',
                                  subRowIdx > 0 && componentStyles['subrow']
                                )}
                                style={{
                                  ...(isPixel(column.width || null)
                                    ? { width: column.width }
                                    : {}),
                                  ...(column.textAlign
                                    ? {
                                        textAlign: column.textAlign,
                                      }
                                    : {}),
                                }}
                                key={`${ridx}-${subRowIdx}-${idx}`}
                                data-text={column.Header}
                              >
                                {displayValue}
                              </td>
                            );
                          }
                        })}
                        {!hideSeeMoreButton && (
                          <td
                            className={baseStyles['base-t-spShow']}
                            key={columns.length + 1}
                          >
                            <a
                              className={clsx(
                                componentStyles['c-table-main__tbody__spMore'],
                                showColumns[ridx]
                                  ? componentStyles['arrowUp']
                                  : ''
                              )}
                              onClick={() => {
                                const newData = [...showColumns];
                                newData[ridx] = !newData[ridx];
                                setShowColumns(newData);
                              }}
                            >
                              {showColumns[ridx] ? t('Close') : t('See more')}
                            </a>
                          </td>
                        )}
                      </tr>
                    );
                    rows.push(row);
                  }

                  return rows;
                })}
            </tbody>
          </table>
        </div>

        {useScrollButton && (
          <>
            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['prev']
              )}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>

            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['next']
              )}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </>
        )}
      </div>

      {usePaging && (
        <>
          <div className={clsx(componentStyles['c-pagination'])}>
            <div className={clsx(componentStyles['c-pagination__size'])}>
              <p>{t('Number of lines')}:</p>
              <label>
                <select value={rowCount} onChange={rowCountChangeHandler}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="50">50</option>
                </select>
              </label>
            </div>
            <div className={clsx(componentStyles['c-pagination__jump'])}>
              <p>{t('Page')}:</p>
              <input
                type="number"
                value={currentPage}
                onChange={currentPageHandler}
              />
              <p>of {pageCount}</p>
            </div>
            <div className={clsx(componentStyles['c-pagination__btns'])}>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  1 < currentPage ? '' : componentStyles['disable']
                )}
                onClick={pageBackClickHandler}
              >
                {t('Previous')}
              </a>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  currentPage < pageCount ? '' : componentStyles['disable']
                )}
                onClick={pageForwardClickHandler}
              >
                {t('Next')}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};
