import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { Checkbox } from 'client/components/v3/Form/Checkbox';

type Props = {
  originalValue: boolean;
  onChange: (
    arg0: SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  checked: boolean;
};

export const PreventAutomaticAllotmentChangesCheckbox = ({
  originalValue,
  onChange,
  checked,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['p-reservationsCreateStock__note__item']}>
        <Checkbox
          label={t(
            'Prevent automatic allotment changes to this date and time.'
          )}
          subLabel={t(
            'Allotments are updated automatically and periodically with the allotment rules from the product settings. By leaving this box checked, any allotment edits you make will be preserved.'
          )}
          checked={checked}
          onChange={onChange}
          size="sm"
        />
      </div>
      <div className={styles['p-reservationsCreateStock__note__item']}>
        <span>
          {originalValue ? t('Current value: ON') : t('Current value: OFF')}
        </span>
      </div>
      <div className={styles['p-reservationsCreateStock__note__item']}>
        <span>{t('Example')}</span>
      </div>
      <div className={styles['p-reservationsCreateStock__note__item']}>
        <span>
          <ol>
            <li>
              {t(
                'Common allotments for all dates and times are 15 from product rules.'
              )}
            </li>
            <li> {t('For April 1, 9:00, edit allotment from 15 to 10.')}</li>
            <li>
              {t(
                'Depending on the checkbox, the allotment will be different after the automatic update:'
              )}
              <ul>
                <li>
                  {t('Checked: allotment for April 1, 9:00, will remain 10.')}
                </li>
                <li>
                  {t(
                    'Unchecked: allotment for April 1, 9:00, will be updated to 15 from the product setting.'
                  )}
                </li>
              </ul>
            </li>
          </ol>
        </span>
      </div>
    </>
  );
};
