import * as React from 'react';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import jaLocale from 'i18n-iso-countries/langs/ja.json';

export const useCountries = () => {
  const { i18n } = useTranslation();
  const [codeNameMap, setCodeNameMap] = React.useState<{
    [index: string]: string;
  }>({});

  React.useEffect(() => {
    countries.registerLocale(enLocale);
    countries.registerLocale(jaLocale);
  }, []);

  React.useEffect(() => {
    const lang = i18n.language.split('-')[0];

    const countryObj = countries.getNames(lang, { select: 'official' });

    setCodeNameMap(countryObj);
  }, [i18n.language]);
  return codeNameMap;
};
