import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { Loading } from 'client/pages/Loading';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { SearchableSelect } from 'client/pages/SignUpDefaults/SearchableSelect';
import { currencyOptions } from 'client/pages/SignUpDefaults/currencyOptions';
import { timezoneOptions } from 'client/libraries/util/timezoneOptions';
import { LogoHeader } from 'client/components/LogoHeader/LogoHeader';
import { updateOrganization } from 'client/actions/organizations';
import type { Organization } from 'shared/models/swagger';

import styles from './SignUpDefaults.module.css';

export const SignUpDefaults = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [language, setLanguage] = React.useState<'EN_US' | 'JA_JP'>('JA_JP');
  const [timezone, setTimezone] = React.useState<string>('Asia/Tokyo');
  const [currency, setCurrency] = React.useState<string>('JPY');
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  React.useEffect(() => {
    if (
      activeUser &&
      activeUser.locale &&
      activeUser.locale.toLowerCase().startsWith('en')
    ) {
      setLanguage('EN_US');
      setTimezone('America/Los_Angeles');
      setCurrency('USD');
    }
  }, [activeUser]);
  const languageOptions = [
    {
      value: 'EN_US',
      text: 'English',
    },
    {
      value: 'JA_JP',
      text: '日本語',
    },
  ];

  if (
    activeUserOrganization &&
    activeUserOrganization?.tutorial_stage !== 'DEFAULTS'
  ) {
    return <Redirect to="/home" />;
  }

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const tutorialStage: Organization['tutorial_stage'] = 'COPY_SITE_TEMPLATE';

  return (
    <div className={styles['p-sign-up-defaults']}>
      <LogoHeader />
      <main className={styles['l-main']}>
        <article>
          <section className={styles['p-contact__body']}>
            <div className={styles['c-inner--middle']}>
              <div className={styles['p-regist__flash']}>
                <p>
                  <span></span>
                  {t('Email verification complete')}
                </p>
              </div>

              <h2 className={styles['c-headline']}>
                {t('Confirm Basic Information - Sign Up')}
              </h2>

              <table className={styles['p-regist__table']}>
                <tbody>
                  <tr>
                    <th>{t('Email')}</th>
                    <td>
                      <span>{activeUser?.email}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Website Name (Company Name)')}</th>
                    <td>
                      <span>{activeUser?.organization_name}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Website URL')}</th>
                    <td>
                      <span>
                        {activeUserOrganization?.booking_widget_subdomain &&
                          `https://${activeUserOrganization?.booking_widget_subdomain}.book.ntmg.com`}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p className={styles['p-contact__body__form__ttl']}>
                {t('Basic Information')}
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (activeUserOrganization?.id) {
                    dispatch(
                      updateOrganization(
                        activeUserOrganization?.id,
                        'SUPPLIER',
                        {
                          supported_languages: [language],
                          source_language: language,
                          default_currency: currency,
                          default_timezone: timezone,
                          tutorial_stage: tutorialStage,
                        }
                      )
                    );
                  }
                }}
              >
                <div className={styles['c-form']}>
                  <div className={styles['c-form__box']}>
                    <div className={styles['c-form__box__header']}>
                      {t('Language')}
                    </div>
                    <div>
                      <label className={styles['c-select']}>
                        <select
                          value={language}
                          onChange={(e) =>
                            setLanguage(e.target.value as 'EN_US' | 'JA_JP')
                          }
                        >
                          {languageOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>

                  <SearchableSelect
                    label={t('Timezone')}
                    value={timezone}
                    options={timezoneOptions}
                    onChange={setTimezone}
                  />

                  <SearchableSelect
                    label={t('Currency')}
                    value={currency}
                    options={currencyOptions}
                    onChange={setCurrency}
                  />

                  <p className={styles['p-regist__note']}>
                    {t('* Basic information may also be modified later')}
                  </p>
                  <div className={styles['c-form__btns']}>
                    <button
                      className={clsx(
                        styles['c-btnsMiddle'],
                        styles['c-btns--blue'],
                        styles['c-btns--shadow']
                      )}
                    >
                      {t('Save Settings')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </article>
      </main>
    </div>
  );
};
