import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { config } from 'client/config';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import {
  Button,
  Input,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ListInputLabel } from 'client/components/ListInputLabel/ListInputLabel';
import { ListInputElement } from 'client/components/ListInputElement/ListInputElement';
import { updatePrivateMarketplace } from 'client/actions/privateMarketplace';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import { PrivateMarketplaceSelect } from 'client/pages/PrivateMarketplace/PrivateMarketplaceSelect/PrivateMarketplaceSelect';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import type { ReduxState } from 'client/reducers';
import type { PrivateMarketplaceSectionType } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './PrivateMarketplaceGeneralSettings.module.css';

export const PrivateMarketplaceGeneralSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollTo = React.useContext(ScrollToContext);
  const privateMarketplace = useSelector(privateMarketplaceSelector);
  const domain = privateMarketplace?.domain || '';
  const language = privateMarketplace?.language || '';
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  const [customDomain, setCustomDomain] = React.useState<string>(
    privateMarketplace?.custom_domain || ''
  );
  const [googleAnalyticsTag, setGoogleAnalyticsTag] = React.useState<string>(
    privateMarketplace?.google_analytics_tag || ''
  );
  const [ga4GoogleAnalyticsTag, setGa4GoogleAnalyticsTag] =
    React.useState<string>(privateMarketplace?.ga4_google_analytics_tag || '');
  const [uaGoogleAnalyticsTag, setUaGoogleAnalyticsTag] =
    React.useState<string>(privateMarketplace?.ua_google_analytics_tag || '');
  const [googleTagManagerTag, setGoogleTagManagerTag] = React.useState<string>(
    privateMarketplace?.google_tag_manager_id || ''
  );
  const [externalLinks, setExternalLinks] = React.useState<
    {
      text?: string;
      url?: string;
    }[]
  >(privateMarketplace?.external_links || []);
  const [headerDescription, setHeaderDescription] = React.useState<string>(
    privateMarketplace?.header_description_text || ''
  );
  const [bannerImageUrl, setBannerImageUrl] = React.useState<string>(
    privateMarketplace?.top_page?.banner_image_url || ''
  );
  const [bannerTitle, setBannerTitle] = React.useState<string>(
    privateMarketplace?.top_page?.banner_title || ''
  );
  const [bannerDescription, setBannerDescription] = React.useState<string>(
    privateMarketplace?.top_page?.banner_description || ''
  );
  const [sections, setSections] = React.useState<
    PrivateMarketplaceSectionType[]
  >(privateMarketplace?.top_page?.sections || []);
  const [shouldRedirectFromBookingWidget, setShouldRedirectFromBookingWidget] =
    React.useState<boolean>(
      !privateMarketplace?.should_not_redirect_to_pmp_from_booking_widget
    );
  const [
    showAddOnRecommendationsDuringCheckout,
    setShowAddOnRecommendationsDuringCheckout,
  ] = React.useState<boolean>(
    privateMarketplace?.show_add_on_recommendations_during_checkout || false
  );
  const [limitedAvailabilityThreshold, setLimitedAvailabilityThreshold] =
    React.useState<number>(
      privateMarketplace?.limited_availability_threshold || 0
    );
  const [showAvailabilitySearch, setShowAvailabilitySearch] =
    React.useState<boolean>(
      privateMarketplace?.show_availability_search || false
    );
  const [showTotalBooked, setShowTotalBooked] = React.useState<boolean>(
    privateMarketplace?.show_total_booked || false
  );
  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;

  const reset = () => {
    setCustomDomain(privateMarketplace?.custom_domain || '');
    setGoogleAnalyticsTag(privateMarketplace?.google_analytics_tag || '');
    setGa4GoogleAnalyticsTag(
      privateMarketplace?.ga4_google_analytics_tag || ''
    );
    setUaGoogleAnalyticsTag(privateMarketplace?.ua_google_analytics_tag || '');
    setGoogleTagManagerTag(privateMarketplace?.google_tag_manager_id || '');
    setExternalLinks(privateMarketplace?.external_links || []);
    setShowAddOnRecommendationsDuringCheckout(
      privateMarketplace?.show_add_on_recommendations_during_checkout || false
    );
    setShowTotalBooked(privateMarketplace?.show_total_booked || false);
    setShowAvailabilitySearch(
      privateMarketplace?.show_availability_search || false
    );
    setLimitedAvailabilityThreshold(
      privateMarketplace?.limited_availability_threshold || 0
    );
    setHeaderDescription(privateMarketplace?.header_description_text || '');
    setBannerImageUrl(privateMarketplace?.top_page?.banner_image_url || '');
    setBannerTitle(privateMarketplace?.top_page?.banner_title || '');
    setBannerDescription(
      privateMarketplace?.top_page?.banner_description || ''
    );
    setSections(privateMarketplace?.top_page?.sections || []);
    setShouldRedirectFromBookingWidget(
      !privateMarketplace?.should_not_redirect_to_pmp_from_booking_widget
    );
  };

  const sectionAsItem = (
    section: PrivateMarketplaceSectionType
  ): {
    key: string;
    text: string;
  } => {
    switch (section) {
      case 'POPULAR_PRODUCTS':
        return {
          key: 'POPULAR_PRODUCTS',
          text: t('Popular'),
        };

      case 'RECOMMENDED_PRODUCTS':
        return {
          key: 'RECOMMENDED_PRODUCTS',
          text: t('Recommended'),
        };

      case 'CATEGORY':
        return {
          key: 'CATEGORY',
          text: t('Categories'),
        };

      case 'FEATURE':
        return {
          key: 'FEATURE',
          text: t('Features'),
        };

      case 'PRODUCT_LIST':
      default:
        return {
          key: 'PRODUCT_LIST',
          text: t('Product List'),
        };
    }
  };

  const sectionCandidates = (
    [
      'POPULAR_PRODUCTS',
      'RECOMMENDED_PRODUCTS',
      'CATEGORY',
      'FEATURE',
      'PRODUCT_LIST',
    ] as PrivateMarketplaceSectionType[]
  ).map(sectionAsItem);
  const selectedSectionItems = sections.map(sectionAsItem);
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <PrivateMarketplaceSelect />
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tbody>
              <tr>
                <th>{t('Domain')}</th>
                <td>{domain}</td>
              </tr>
              <tr>
                <th>{t('Language')}</th>
                <td>
                  {' '}
                  {language &&
                    getLanguageName(uppercaseIsoToLowercaseIso[language], t)}
                </td>
              </tr>
              <tr>
                <th>{t('Redirection')}</th>
                <td>
                  <ToggleButton
                    label={t(
                      'Redirect to Private Marketplace from normal booking website address'
                    )}
                    onChange={() =>
                      setShouldRedirectFromBookingWidget(
                        !shouldRedirectFromBookingWidget
                      )
                    }
                    checked={shouldRedirectFromBookingWidget}
                  />
                </td>
              </tr>
              <tr>
                <th>{t('Custom Domain (optional)')}</th>
                <td>
                  <ul
                    style={{
                      listStyle: 'none',
                      paddingInlineStart: '0px',
                    }}
                  >
                    <li>
                      {t(
                        'To set up a custom domain for your private marketplace:'
                      )}
                    </li>
                    <br />
                    <li>
                      <b>{t('Step 1:')}</b>
                    </li>
                    <li>
                      {t(
                        'Set up an A record with your DNS provider. If using a root domain:'
                      )}
                    </li>
                    <br />
                    <li>{`@    A    ${config.bookingWidgetIpAddress}`}</li>
                    <br />
                    <li>{t('If using a subdomain:')}</li>
                    <br />
                    <li>{`<subdomain>    A    ${config.bookingWidgetIpAddress}`}</li>
                    <br />
                    <li>
                      <a
                        href="https://moz.com/learn/seo/domain"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('What are subdomains and root domains?')}
                      </a>
                    </li>
                    <br />
                    <li>
                      <b>{t('Step 2:')}</b>
                    </li>
                    <li>
                      {t(
                        'Enter your full custom domain name in the "Custom Domain" field and press "Save".'
                      )}
                    </li>
                    <br />
                    <li>
                      {t(
                        'Once steps 1 and 2 are completed, your booking widget will usually be live at your custom domain within 1-3 hours.'
                      )}
                    </li>
                  </ul>
                  <div className={styles['input-box']}>
                    <Input
                      label={t('Custom Domain')}
                      placeholder="example.com"
                      maxWidth={600}
                      onChange={(e, { value }) => setCustomDomain(value)}
                      value={customDomain}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Google Analytics')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <Input
                      maxWidth={600}
                      label={t('GA4 Google Analytics Tag (Recommended)')}
                      onChange={(e, { value }) =>
                        setGa4GoogleAnalyticsTag(value)
                      }
                      placeholder="G-xxxxxx"
                      value={ga4GoogleAnalyticsTag}
                    />
                    <Box mt={2}>
                      <Input
                        label={t('UA Google Analytics Tag')}
                        maxWidth={600}
                        onChange={(e, { value }) =>
                          setUaGoogleAnalyticsTag(value)
                        }
                        placeholder="UA-xxxxxx"
                        value={uaGoogleAnalyticsTag}
                      />
                    </Box>
                    <p>
                      {t('Setup instructions ')}
                      <a
                        href="/helps/settings/google-analytics"
                        target="_blank"
                      >
                        {t('here')}
                      </a>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Google Tag Manager Id')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <Input
                      maxWidth={600}
                      onChange={(e, { value }) => setGoogleTagManagerTag(value)}
                      value={googleTagManagerTag}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Website Title')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <TextArea
                      height={80}
                      maxWidth={600}
                      onChange={(e, { value }) => setHeaderDescription(value)}
                      value={headerDescription}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Website Description')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <TextArea
                      height={80}
                      maxWidth={600}
                      onChange={(e, { value }) => setBannerDescription(value)}
                      value={bannerDescription}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Catchphrase')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <Input
                      onChange={(e, { value }) => setBannerTitle(value)}
                      value={bannerTitle}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Key visual (jpg, png)')}</th>
                <td>
                  <SingleImageInput
                    acceptedFileTypes={['image/jpeg', 'image/png']}
                    onUploadFinished={(filename, url) => setBannerImageUrl(url)}
                    initialValue={bannerImageUrl}
                    getFileSavePath={(filename) =>
                      activeUserOrganization
                        ? `${activeUserOrganization?.id}/${filename}`
                        : ''
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>{t('Show add-on recommendations during checkout')}</th>
                <td>
                  <ToggleButton
                    label=""
                    onChange={() =>
                      setShowAddOnRecommendationsDuringCheckout(
                        !showAddOnRecommendationsDuringCheckout
                      )
                    }
                    checked={showAddOnRecommendationsDuringCheckout}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  {t(
                    'Show total number of booked participants for each product'
                  )}
                </th>
                <td>
                  <ToggleButton
                    label=""
                    onChange={() => setShowTotalBooked(!showTotalBooked)}
                    checked={showTotalBooked}
                  />
                </td>
              </tr>
              <tr>
                <th>{t('Limited availability marker')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <ToggleButton
                      label={t('Show limited availability dates on calendar')}
                      onChange={() => {
                        if (limitedAvailabilityThreshold) {
                          setLimitedAvailabilityThreshold(0);
                        } else {
                          setLimitedAvailabilityThreshold(5);
                        }
                      }}
                      checked={limitedAvailabilityThreshold > 0}
                    />
                  </div>
                  {limitedAvailabilityThreshold > 0 && (
                    <div className={styles['input-box']}>
                      <Select
                        label={t(
                          'Limited availability threshold (dates having this availaiblity or less will have a limited availability mark on the calendar)'
                        )}
                        options={_.times(10, (idx) => ({
                          key: idx + 1,
                          value: `${idx + 1}`,
                          text: `${idx + 1}`,
                        }))}
                        value={`${limitedAvailabilityThreshold}`}
                        onChange={(e, { value }) => {
                          setLimitedAvailabilityThreshold(parseInt(value));
                        }}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>{t('Availability Search')}</th>
                <td>
                  <ToggleButton
                    label={t(
                      'Show availability search in the product list filter'
                    )}
                    onChange={() =>
                      setShowAvailabilitySearch(!showAvailabilitySearch)
                    }
                    checked={showAvailabilitySearch}
                  />
                </td>
              </tr>
              <tr>
                <th>{t('Top Page Sections (drag-and-drop to reorder)')}</th>
                <td>
                  <ToggleableDndList
                    selectedItems={selectedSectionItems}
                    candidateItems={sectionCandidates}
                    setSelectedItems={(newSelectedItems) => {
                      setSections(newSelectedItems.map((item) => item.key));
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>{t('Links')}</th>
                <td>
                  <ListInputLabel
                    label={t(
                      'Informational Links (these will be shown under the "Info" menu item in the private marketplace header)'
                    )}
                    getDefaultElement={() => ({
                      text: '',
                      url: '',
                    })}
                    value={externalLinks}
                    onChange={(newExternalLinks) =>
                      setExternalLinks(newExternalLinks)
                    }
                  />
                  {externalLinks.map((externalLink, idx) => {
                    const setExternalLink = (newLink: {
                      text?: string;
                      url?: string;
                    }) => {
                      const newExternalLinks = [...externalLinks];
                      newExternalLinks[idx] = newLink;
                      setExternalLinks(newExternalLinks);
                    };

                    return (
                      <ListInputElement
                        minElements={0}
                        key={idx}
                        elemIndex={idx}
                        listValue={externalLinks}
                        onListChange={(newExternalLinks) =>
                          setExternalLinks(newExternalLinks)
                        }
                        getDefaultElement={() => ({
                          text: '',
                          url: '',
                        })}
                      >
                        <Input
                          label={t('Link Text')}
                          value={externalLink.text || ''}
                          onChange={(e, { value }) =>
                            setExternalLink({ ...externalLink, text: value })
                          }
                        />
                        <Input
                          label={t('Link URL')}
                          value={externalLink.url || ''}
                          onChange={(e, { value }) =>
                            setExternalLink({ ...externalLink, url: value })
                          }
                        />
                      </ListInputElement>
                    );
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button style="gray" size="middle" onClick={reset}>
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            onClick={async () => {
              await dispatch(
                updatePrivateMarketplace({
                  custom_domain: customDomain,
                  google_analytics_tag: googleAnalyticsTag,
                  ua_google_analytics_tag: uaGoogleAnalyticsTag,
                  ga4_google_analytics_tag: ga4GoogleAnalyticsTag,
                  google_tag_manager_id: googleTagManagerTag,
                  external_links: externalLinks,
                  show_add_on_recommendations_during_checkout:
                    showAddOnRecommendationsDuringCheckout,
                  header_description_text: headerDescription,
                  limited_availability_threshold: limitedAvailabilityThreshold,
                  show_total_booked: showTotalBooked,
                  show_availability_search: showAvailabilitySearch,
                  top_page: {
                    banner_image_url: bannerImageUrl,
                    banner_title: bannerTitle,
                    banner_description: bannerDescription,
                    sections,
                  },
                  should_not_redirect_to_pmp_from_booking_widget:
                    !shouldRedirectFromBookingWidget,
                })
              );
              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
