export type LanguageISO = 'en' | 'ja' | 'ko' | 'zh-CN' | 'zh-TW';
export type LanguageUppercaseISO =
  | 'EN_US'
  | 'JA_JP'
  | 'KO_KR'
  | 'ZH_CN'
  | 'ZH_TW';
export const lowercaseIsoToUppercaseIso: Record<
  LanguageISO,
  LanguageUppercaseISO
> = {
  en: 'EN_US',
  ja: 'JA_JP',
  ko: 'KO_KR',
  'zh-CN': 'ZH_CN',
  'zh-TW': 'ZH_TW',
};
export const uppercaseIsoToLowercaseIso: Record<
  LanguageUppercaseISO,
  LanguageISO
> = {
  EN_US: 'en',
  JA_JP: 'ja',
  KO_KR: 'ko',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
};
