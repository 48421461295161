import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Product } from 'shared/models/swagger';
import { operationAllowed } from 'shared/models/access';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { EditorButtonsRow } from 'client/pages/v3/Product/ProductEdit/ProductEditHeader/EditorButtonsRow/EditorButtonsRow';

type Props = {
  product: Product;
};

export const ProductEditHeader = ({ product }: Props) => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  const paths = [
    { text: t('Product List'), url: '/products-v3' },
    {
      text: product.internal_product_name ?? '',
      url: `/products-v3/${product.id}`,
    },
    { text: t('Product Edit'), url: '' },
  ];

  return (
    <PageHeader title={t('Product Edit')} breadcrumbPaths={paths}>
      <>
        {((operationAllowed(activeUser, 'write', 'productContents') &&
          hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST')) ||
          operationAllowed(activeUser, 'write', 'reverseMappings')) && (
          <EditorButtonsRow product={product} />
        )}
      </>
    </PageHeader>
  );
};
