import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useState } from 'react';

import styles from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/SelectPane.module.css';
import { TranslateFuncType } from 'client/components/Translate';
import {
  DashboardGadget,
  DashboardSettings,
} from 'client/reducers/dashboardSettings';
import { Button } from 'client/components/v3/Common/Button';
import { AddCustomGadgetModal } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddCustomGadgetModal';
import { Gadget } from 'client/pages/v3/FlexibleDashboard/Gadget';
import { AddButton } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddButton';
import { getReservationSummaryTableColumns } from 'client/libraries/util/getReservationSummaryTableColumns';
import { getReservationListInitialColumns } from 'client/pages/v3/FlexibleDashboard/util';

const getPresetGadgets = (
  gadgetType: 'reservation-summary' | 'reservation-list',
  t: TranslateFuncType
): DashboardGadget[] => {
  if (gadgetType === 'reservation-list') {
    return [
      {
        gadgetType: gadgetType,
        key: 'reservation-list-TODAY',
        title: t("Today's Reservations"),
        params: {
          searchPreset: 'CUSTOM',
          orderBy: 'last_updated_desc',
          statuses: ['CONFIRMED'],
          dateType: 'PARTICIPATION_DATE',
          targetDateType: 'CURRENT_DATE',
          defaultColumns: getReservationListInitialColumns(),
        },
      },
      {
        gadgetType: gadgetType,
        key: 'reservation-list-TOMORROW',
        title: t("Tomorrow's Reservations"),
        params: {
          searchPreset: 'CUSTOM',
          orderBy: 'last_updated_desc',
          statuses: ['CONFIRMED'],
          dateType: 'PARTICIPATION_DATE',
          targetDateType: 'NEXT_DATE',
          defaultColumns: getReservationListInitialColumns(),
        },
      },
      {
        gadgetType: gadgetType,
        key: 'reservation-list-REQUESTED-STANDBY',
        title: t('Requested/Standby Reservations'),
        params: {
          searchPreset: 'CUSTOM',
          orderBy: 'last_updated_desc',
          statuses: ['REQUESTED', 'STANDBY'],
          dateType: 'PARTICIPATION_DATE',
          targetDateType: 'RANGE',
          dateRangeType: 'NEXT',
          shouldSetAsIndefinite: true,
          defaultColumns: getReservationListInitialColumns(),
        },
      },
      {
        gadgetType: gadgetType,
        key: 'reservation-list-YESTERDAY-NEW',
        title: t("Yesterday's New Reservations"),
        params: {
          searchPreset: 'CUSTOM',
          orderBy: 'last_updated_desc',
          // Reservation status != (CANCELLED by supplier/agent/guest, Declined, Withdrawn)
          statuses: [
            'REQUESTED',
            'STANDBY',
            'AWAITING_UPDATE_CONFIRMATION',
            'CONFIRMED',
          ],
          dateType: 'BOOKED_DATE',
          targetDateType: 'PREVIOUS_DATE',
          defaultColumns: getReservationListInitialColumns(),
        },
      },
    ];
  }

  // Reservation summary
  const allColumns = getReservationSummaryTableColumns(t).map((c) => {
    return c.id.toUpperCase();
  });
  return [
    {
      gadgetType: gadgetType,
      key: 'reservation-summary-TODAY',
      title: t("Today's Reservation Summary"),
      params: {
        searchPreset: 'CUSTOM',
        orderBy: 'last_updated_desc',
        statuses: ['CONFIRMED'],
        dateType: 'PARTICIPATION_DATE',
        targetDateType: 'CURRENT_DATE',
        defaultColumns: allColumns,
      },
    },
    {
      gadgetType: gadgetType,
      key: 'reservation-summary-TOMORROW',
      title: t("Tomorrow's Reservation Summary"),
      params: {
        searchPreset: 'CUSTOM',
        orderBy: 'last_updated_desc',
        statuses: ['CONFIRMED'],
        dateType: 'PARTICIPATION_DATE',
        targetDateType: 'NEXT_DATE',
        defaultColumns: allColumns,
      },
    },
  ];
};

type Props = {
  onClose: () => void;
};

export const ReservationInfoSelectPane = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [gadgetType, setGadgetType] = useState<
    'reservation-list' | 'reservation-summary'
  >('reservation-summary');
  const form = useForm<DashboardSettings>();
  const values = form.getState().values;

  return (
    <>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Reservation Summary')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setGadgetType('reservation-summary');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getPresetGadgets('reservation-summary', t).map((gadget) => (
            <li
              key={gadget.key}
              className={clsx(
                styles['p-dashboardDataList__item'],
                styles['full']
              )}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Reservation List')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setGadgetType('reservation-list');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getPresetGadgets('reservation-list', t).map((gadget) => (
            <li
              key={gadget.key}
              className={clsx(
                styles['p-dashboardDataList__item'],
                styles['full']
              )}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      {showModal && (
        <AddCustomGadgetModal
          gadgetType={gadgetType}
          onClose={() => {
            setShowModal(false);
            // Also close add gadget modal
            onClose();
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
