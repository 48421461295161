import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import type { Organization } from 'shared/models/swagger';

export const getBookingWidgetPmpUrlBase = (
  organization: Organization | null
): string => {
  const pmp = organization?.private_marketplaces?.[0] || null;

  if (Boolean(pmp) && !isSubscriptionCancelled(organization, 'standard-plan')) {
    return `https://${pmp?.custom_domain || pmp?.domain || ''}/top`;
  }

  if (organization?.booking_widget_custom_domain) {
    return `https://${organization?.booking_widget_custom_domain}`;
  }

  if (organization?.booking_widget_subdomain) {
    return getBookingWidgetUrl(organization?.booking_widget_subdomain);
  }

  if (organization?.booking_widget_api_key) {
    return (
      getOldBookingWidgetUrl() +
      '/' +
      (organization.booking_widget_api_key || '')
    );
  }

  return '';
};

export const getOldBookingWidgetUrl = (): string => {
  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    return `https://book.dev.ntmg.com`;
  } else if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return `https://book.stg.ntmg.com`;
  }

  return `https://book.ntmg.com`;
};
