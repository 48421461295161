import querystring from 'query-string';
import moment from 'moment-timezone';
import _ from 'lodash';

import type { TranslateFuncType } from 'client/components/Translate';
import { ListWaiversRequest } from 'shared/models/swagger';
import { config } from 'client/config';

export type DateFilterPreset =
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'SUBMITTED_1_DAY'
  | 'SUBMITTED_3_DAYS'
  | 'SUBMITTED_7_DAYS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
      'SUBMITTED_1_DAY',
      'SUBMITTED_3_DAYS',
      'SUBMITTED_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'SUBMITTED_1_DAY':
      return t('Last updated, past {{count}} days', {
        count: 1,
      });

    case 'SUBMITTED_3_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 3,
      });

    case 'SUBMITTED_7_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
  }
};
export type SearchWaiversRequest = {
  agentReference: string;
  supplierReference: string;
  customerGivenName: string;
  customerFamilyName: string;
  productIds: string[];
  participationDateFrom: string;
  participationDateTo: string;
  submittedDateFrom: string;
  submittedDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
};
export const convertSearchWaiversRequestToQueryParams = (
  req: SearchWaiversRequest
): ListWaiversRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let submittedDateTimeUtcFrom = '';
  let submittedDateTimeUtcTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'SUBMITTED_1_DAY':
        submittedDateTimeUtcFrom = now.subtract(1, 'days').utc().format();
        break;

      case 'SUBMITTED_3_DAYS':
        submittedDateTimeUtcFrom = now.subtract(3, 'days').utc().format();
        break;

      case 'SUBMITTED_7_DAYS':
        submittedDateTimeUtcFrom = now.subtract(7, 'days').utc().format();
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    submittedDateTimeUtcFrom = req.submittedDateFrom
      ? moment(req.submittedDateFrom).utc().format()
      : '';
    submittedDateTimeUtcTo = req.submittedDateTo
      ? moment(req.submittedDateTo).add(1, 'day').utc().format()
      : '';
  }

  // TODO: feature flag config.enableWaiverListEnhancement
  // Delete the whole block for release
  if (!config.enableWaiverListEnhancement) {
    return {
      start_date_local_from: startDateLocalFrom,
      start_date_local_to: startDateLocalTo,
      submitted_date_time_utc_from: submittedDateTimeUtcFrom,
      submitted_date_time_utc_to: submittedDateTimeUtcTo,

      filter: querystring.stringify({
        reservation_agent_reference: req.agentReference
          ? [req.agentReference, '']
          : '',
        reservation_supplier_reference: req.supplierReference
          ? [req.supplierReference, '']
          : '',
        reservation_guest_given_name: req.customerGivenName
          ? [
              ...new Set([
                req.customerGivenName,
                req.customerGivenName.toLowerCase(),
                req.customerGivenName.toUpperCase(),
                _.startCase(req.customerGivenName.toLowerCase()),
              ]),
            ]
          : '',
        reservation_guest_family_name: req.customerFamilyName
          ? [
              ...new Set([
                req.customerFamilyName,
                req.customerFamilyName.toLowerCase(),
                req.customerFamilyName.toUpperCase(),
                _.startCase(req.customerFamilyName.toLowerCase()),
              ]),
            ]
          : '',
        product_id: req.productIds.length > 0 ? req.productIds : '',
      }),
    };
  }

  return {
    start_date_local_from: startDateLocalFrom,
    start_date_local_to: startDateLocalTo,
    submitted_date_time_utc_from: submittedDateTimeUtcFrom,
    submitted_date_time_utc_to: submittedDateTimeUtcTo,
    reservation_agent_reference: req.agentReference,
    reservation_supplier_reference: req.supplierReference,
    reservation_guest_given_name: req.customerGivenName,
    reservation_guest_family_name: req.customerFamilyName,
    product_ids: req.productIds.length > 0 ? req.productIds : [],
  };
};
