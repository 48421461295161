import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Section } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/Menu/menuType';

import styles from './PcSidebarMenu.module.css';

type Props = {
  sections: Section[];
  currentSectionId?: string;
};

const findSectionBySubsectionId = (
  sections: Section[],
  subsectionId: string | undefined
): Section | undefined => {
  if (!subsectionId) {
    return undefined;
  }
  for (const section of sections) {
    const foundSubsection = section.subsections?.find(
      (subsection) => subsection.id === subsectionId
    );
    if (foundSubsection) {
      return section;
    }
  }
  return undefined;
};

export const PcSidebarMenu = ({ sections, currentSectionId }: Props) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState<string | null>('basic');

  useEffect(() => {
    const currentSection = findSectionBySubsectionId(
      sections,
      currentSectionId
    );
    if (currentSection) {
      setActiveSection(currentSection.id);
    }
  }, [currentSectionId]);

  const handleSectionClick = (sectionId: string) => {
    const newActiveSection = activeSection === sectionId ? null : sectionId;
    setActiveSection(newActiveSection);
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'instant' });
    }
  };

  return (
    <nav className={styles['c-subnav']}>
      <p className={styles['c-subnav__ttl']}>{t('Table of Contents')}</p>
      <ul>
        {sections.map((section, index) => {
          if (!section.hasPermission) {
            return null;
          }

          const isSectionActive = activeSection === section.id;

          return (
            <li
              key={index}
              className={clsx(
                styles['c-subnav__list__item'],
                isSectionActive && styles['is-active']
              )}
            >
              <a onClick={() => handleSectionClick(section.id)}>
                {section.title}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
