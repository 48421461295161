import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { dispatchSettingsSelector } from 'client/reducers/dispatchSettings';
import type { ReduxState } from 'client/reducers';
import type {
  DispatchCrewMember,
  DispatchResource,
  DispatchSettings$Patch,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateDispatchSettings =
  (patch: DispatchSettings$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const currentDispatchSettings = dispatchSettingsSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        dispatch_settings: { ...currentDispatchSettings, ...patch },
      })
    );
  };
export const updateDispatchVehicles = (vehicles: DispatchResource[]) =>
  updateDispatchSettings({
    dispatch_vehicles: vehicles,
  });
export const updateDispatchCrewMembers = (crewMembers: DispatchCrewMember[]) =>
  updateDispatchSettings({
    dispatch_crew_members: crewMembers,
  });
export const updateDispatchMiscResources = (resources: DispatchResource[]) =>
  updateDispatchSettings({
    dispatch_misc_resources: resources,
  });
