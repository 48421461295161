import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { compareRelativeDeadlines } from 'client/libraries/util/productShape';
import type { RelativeDeadline } from 'client/libraries/util/productShape';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';

import { getDeadlineDescription } from '../util';

import styles from './Timeline.module.css';
import { TimelineItem } from './TimelineItem';

type Props = {
  fromRelativeDeadline?: RelativeDeadline | null;
  toRelativeDeadline?: RelativeDeadline | null;
};
export const Timeline = ({
  fromRelativeDeadline,
  toRelativeDeadline,
}: Props) => {
  const { t } = useTranslation();
  const timelineItems: {
    label: string;
    content: string;
    color?: 'blue' | 'yellow';
  }[] = [];

  if (!fromRelativeDeadline && !toRelativeDeadline) {
    return null;
  }

  if (
    toRelativeDeadline &&
    fromRelativeDeadline &&
    compareRelativeDeadlines(toRelativeDeadline, fromRelativeDeadline) < 0
  ) {
    return (
      <Box mb={2}>
        <p className={baseStyles['base-form-box__err']}>
          {t(
            'Invalid Active Period: "Apply From" must be earlier than "Apply To"'
          )}
        </p>
      </Box>
    );
  }

  if (fromRelativeDeadline) {
    timelineItems.push({
      label: t('Unactive'),
      content: getDeadlineDescription(fromRelativeDeadline, t),
    });
  }

  if (toRelativeDeadline) {
    if (
      fromRelativeDeadline &&
      compareRelativeDeadlines(toRelativeDeadline, fromRelativeDeadline) < 0
    ) {
      timelineItems.unshift({
        label: t('Active'),
        content: getDeadlineDescription(toRelativeDeadline, t),
        color: 'blue',
      });
    } else {
      timelineItems.push({
        label: t('Active'),
        content: getDeadlineDescription(toRelativeDeadline, t),
        color: 'blue',
      });
    }

    timelineItems.push({
      label: t('Unactive'),
      content: t('Until Participation'),
    });
  } else {
    timelineItems.push({
      label: t('Active'),
      content: t('Until Participation'),
      color: 'blue',
    });
  }

  return (
    <div className={styles['page-productsRegist__timeline']}>
      {timelineItems.map((item, idx) => (
        <TimelineItem
          key={idx}
          label={item.label}
          content={item.content}
          color={item.color}
        />
      ))}
      <p className={styles['page-productsRegist__timeline__last']}>
        {t('Participation Date')}
      </p>
    </div>
  );
};
