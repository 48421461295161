import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import clsx from 'clsx';
import _ from 'lodash';

import type { ReduxState } from 'client/reducers';
import type { SearchRedeemedRecordsRequest } from 'client/libraries/util/searchRedeemedRecords';
import {
  convertSearchRedeemedRecordsRequestToQueryParams,
  convertSearchRedeemedRecordsRequestToDownloadQueryParams,
} from 'client/libraries/util/searchRedeemedRecords';
import { Button } from 'client/components/Form';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import {
  fetchRedeemedRecords,
  downloadRedeemedRecordCSV,
} from 'client/actions/redeemedRecords';
import { fetchProducts } from 'client/actions/products';
import { setLastExecutedRedeemedRecordSearchCondition } from 'client/actions/redeemedRecordTableControls';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';
import fileDLIcon from 'client/images/ic_file_download.svg';

import { RedeemedRecordSearchFiltersDisplayBox } from './RedeemedRecordSearchFiltersDisplayBox/RedeemedRecordSearchFiltersDisplayBox';
import { useRedeemedRecordColumns } from './util';
import { RedeemedRecordSearchSettingsModal } from './RedeemedRecordSearchSettingsModal';

export const RedeemedRecordList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allColumns = useRedeemedRecordColumns();

  const loading = useSelector(
    (state: ReduxState) => state.redeemedRecords.loading
  );
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.redeemedRecordTableControls.lastExecutedSearchCondition
  );

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const redeemedRecords = useSelector(
    (state: ReduxState) => state.redeemedRecords.all
  );

  const downloading = useSelector(
    (state: ReduxState) => state.redeemedRecords.downloading
  );

  const filteredRedeemedRecords = React.useMemo(() => {
    return redeemedRecords
      .filter((redeemedRecord) => {
        if (!lastExecutedSearchCondition) {
          return true;
        }
        return (
          (redeemedRecord.title ?? '').indexOf(
            lastExecutedSearchCondition.titleText
          ) !== -1
        );
      })
      .filter((redeemedRecord) => {
        return (
          (redeemedRecord.page_title ?? '').indexOf(
            lastExecutedSearchCondition.pageTitleText
          ) !== -1
        );
      });
  }, [redeemedRecords, lastExecutedSearchCondition]);

  const [searchCondition, setSearchCondition] =
    React.useState<SearchRedeemedRecordsRequest>(lastExecutedSearchCondition);

  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);

  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  React.useEffect(() => {
    dispatch(
      fetchRedeemedRecords(
        convertSearchRedeemedRecordsRequestToQueryParams(searchCondition)
      )
    );
  }, [lastExecutedSearchCondition]);

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      supplierReference: '',
      customerGivenName: '',
      customerFamilyName: '',
      productIds: [],
      participationDateFrom: '',
      participationDateTo: '',
      redeemedDateFrom: '',
      redeemedDateTo: '',
      dateFilterPreset: 'PARTICIPATION_1_DAY',
      titleText: '',
      pageTitleText: '',
    });
  };

  const search = () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(setLastExecutedRedeemedRecordSearchCondition(searchCondition));
      }

      dispatch(
        fetchRedeemedRecords(
          convertSearchRedeemedRecordsRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const downloadCSV = () => {
    if (!isNutmegAdmin) {
      dispatch(
        downloadRedeemedRecordCSV(
          convertSearchRedeemedRecordsRequestToDownloadQueryParams(
            searchCondition
          )
        )
      );
    }
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <RedeemedRecordSearchSettingsModal
              onReset={reset}
              onSearch={search}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>

          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-main__body__header__right__another'],
                baseStyles['is-close']
              )}
            >
              <a
                className={clsx(baseStyles['base-btn'])}
                onClick={() => {
                  if (!downloading) {
                    downloadCSV();
                  }
                }}
              >
                <img src={fileDLIcon} />
              </a>

              <ul>
                <li>
                  <Button.Sub
                    content={<>{t('Download CSV')}</>}
                    onClick={() => {
                      if (!downloading) {
                        downloadCSV();
                      }
                    }}
                    loading={downloading}
                    disabled={downloading}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <Box mb={2}>
        <RedeemedRecordSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
          totalHits={filteredRedeemedRecords.length ?? 0}
        />
      </Box>
      <CustomTable
        items={filteredRedeemedRecords}
        columns={allColumns}
        usePaging={true}
      />
    </div>
  );
};
