import { Content } from '@nutmeglabs/nutmeg-sitecontrol-ui';

import {
  SiteControlWidget,
  SiteControlWidgetParams,
} from 'shared/models/swagger';

import { safeConvertToNumber } from './util';

export type Query = {
  parameter?: string;
  value?: string;
};

export type Target = {
  path?: string;
  queries?: Query[];
  hash?: string;
};

export type FormValues = {
  name: string;
  baseUrl: string;
  content: Content;
  status: 'DRAFT' | 'ACTIVE';
  includes: Target[];
  excludes: Target[];
  timing: {
    type: 'IMMEDIATELY' | 'SCROLL_DEPTH' | 'TIME_ON_PAGE' | 'TIME_ON_WEBSITE';
    scroll_percentage?: number;
    delay_time?: number;
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): SiteControlWidgetParams => {
  const scrollPercentage = values.timing.scroll_percentage
    ? safeConvertToNumber(values.timing.scroll_percentage)
    : undefined;
  const delayTime = values.timing.delay_time
    ? safeConvertToNumber(values.timing.delay_time)
    : undefined;

  return {
    status: values.status,
    name: values.name,
    base_url: values.baseUrl.endsWith('/')
      ? values.baseUrl.slice(0, -1)
      : values.baseUrl,
    content_json: JSON.stringify(values.content),
    includes: values.includes,
    excludes: values.excludes,
    timing: {
      ...values.timing,
      scroll_percentage: scrollPercentage,
      delay_time: delayTime,
    },
  };
};

export const convertSwaggerToFormValues = (
  siteControlWidget: SiteControlWidget
): FormValues => {
  return {
    status: siteControlWidget.status ?? 'DRAFT',
    name: siteControlWidget.name ?? '',
    baseUrl: siteControlWidget.base_url ?? '',
    content: siteControlWidget.content_json
      ? JSON.parse(siteControlWidget.content_json)
      : {},
    includes: siteControlWidget.includes ?? [],
    excludes: siteControlWidget.excludes ?? [],
    timing: {
      ...siteControlWidget.timing,
      type: siteControlWidget.timing?.type ?? 'IMMEDIATELY',
    },
  };
};
