import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { getArrayMutators } from 'client/libraries/util/form';
import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import {
  createMediaDownloadPrice,
  updateMediaDownloadPrice,
} from 'client/actions/mediaDownloadPrices';
import * as Swagger from 'shared/models/swagger';

import styles from './EditMediaDownloadPriceModal.module.css';
import type { FormValues } from './FormValues';

type Props = {
  existingPrice?: Swagger.MediaDownloadPrice;
  open: boolean;
  onClose: () => void;
};
export const EditMediaDownloadPriceModal = ({
  open,
  onClose,
  existingPrice,
}: Props) => {
  const { t } = useTranslation();
  const initialValues = React.useMemo(
    () =>
      existingPrice
        ? {
            id: existingPrice.id,
            label: existingPrice.label,
            type: existingPrice.type,
            price: existingPrice.price?.slice(3) ?? '',
          }
        : {
            id: '',
            label: '',
            type: 'INDIVIDUAL_MEDIA',
            price: '',
          },
    [existingPrice]
  );
  const typeOptions = [
    {
      key: 'INDIVIDUAL_MEDIA',
      value: 'INDIVIDUAL_MEDIA',
      label: t('Per Item'),
    },
    {
      key: 'MEDIA_SET',
      value: 'MEDIA_SET',
      label: t('Per Package of Photos/Videos'),
    },
  ];
  const currency = useSelector(defaultProductCurrencySelector);
  const dispatch = useDispatch();
  return (
    <Modal
      title={t('Edit Media Download Price')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          if (existingPrice) {
            await dispatch(
              updateMediaDownloadPrice(existingPrice.id ?? '', {
                label: values.label,
                price: `${currency}${values.price}`,
                type: values.type,
              })
            );
          } else {
            await dispatch(
              createMediaDownloadPrice({
                label: values.label,
                price: `${currency}${values.price}`,
                type: values.type,
              })
            );
          }

          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              <Box mb={2}>
                <Field name="label">
                  {({ input }) => (
                    <Input
                      placeholder={t('Professional Image')}
                      label={t('Price Title')}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('Price Applies To')}>
                  <EnumRadioButtonGroup name="type" options={typeOptions} />
                </FieldWrapper>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('Price')}>
                  <div className={styles['price']}>
                    <p>{currency}</p>
                    <Field name="price">
                      {({ input }) => (
                        <Input
                          value={input.value}
                          onChange={(e: any) => {
                            if (
                              !currencyInputAllowed(currency, e.target.value)
                            ) {
                              return;
                            }

                            input.onChange(e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </FieldWrapper>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              {/*
            <Button.Cancel
             onClick={() => {
               form.change(name, initialValues);
               onClose();
             }}
            >
             {t('Discard')}
            </Button.Cancel>*/}
              <Button size="middle" style="blue" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
