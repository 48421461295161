import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { config } from 'client/config';
import { logout } from 'client/actions/user';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { operationAllowed } from 'shared/models/access';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';

import styles from '../../base.module.css';

export const AccountDropdown = () => {
  const dropdownRef = React.useRef<HTMLUListElement | null>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const showPrivateMarketplaceSettings =
    (activeUserOrganization?.private_marketplaces?.length || 0) > 0 &&
    !isSubscriptionCancelled(activeUserOrganization, 'standard-plan') &&
    activeUser?.role !== 'GUIDE' &&
    hasCustomUserRoleWritePermissions(activeUser, 'BOOKING_SITE');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname]);
  React.useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (target instanceof Node && !dropdownRef?.current?.contains(target)) {
        setIsOpen(false);
      }
    };

    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);
    return () => {
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);
  return (
    <div
      className={clsx(
        styles['base-header__info__account'],
        isOpen ? styles['is-open'] : styles['is-close']
      )}
    >
      <p
        className={styles['base-header__info__account__ttl']}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{activeUser?.email}</span>
      </p>
      <ul
        ref={dropdownRef}
        className={styles['base-header__info__account__children']}
      >
        <li>
          <Link to="/settings">{t('Settings')}</Link>
        </li>
        {config.enableUIRevamp && (
          <li>
            <Link to="/settings-v3">{`${t('Settings')} V3`}</Link>
          </li>
        )}
        {showPrivateMarketplaceSettings && (
          <li>
            <Link to="/pmp/general">{t('Private Marketplace')}</Link>
          </li>
        )}
        {operationAllowed(activeUser, 'read', 'apiSpec') && (
          <li>
            <Link to="/swagger-ui">{t('API')}</Link>
          </li>
        )}
        <li>
          <a onClick={() => dispatch(logout())}>{t('Logout')}</a>
        </li>
      </ul>
    </div>
  );
};
