import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { mediaDownloadPricesSelector } from 'client/reducers/mediaDownloadPrices';
import { Select } from 'client/components/Form';
import { s3MediaDownloadStorage } from 'client/libraries/s3';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import deleteIcon from 'client/images/ic_delete.svg';

import type { MediaItem } from '../FormValues';

import styles from './MediaItemInput.module.css';

const ItemType = 'media';
type DragItem = {
  index: number;
  imageVideoUrl: string;
  type: string;
};
type Props = {
  mediaItem: MediaItem;
  index: number;
  onRemove: () => void;
  onMoveImage: (dragIndex: number, hoverIndex: number) => void;
  onChange: (newValue: MediaItem) => void;
  hidePriceInput?: boolean;
};
export const DraggablePreviewMediaItem = ({
  mediaItem,
  index,
  onRemove,
  onMoveImage,
  onChange,
  hidePriceInput,
}: Props) => {
  const videoElementRef = React.useRef<HTMLVideoElement>(null);

  const [previewUrl, setPreviewUrl] = React.useState<string>('');
  const { t } = useTranslation();
  const prices = useSelector(mediaDownloadPricesSelector);

  const isImage = mediaItem.mediaType !== 'VIDEO';

  React.useEffect(() => {
    if (videoElementRef.current) {
      const eventListener = () => {
        console.log('listener called');
        onChange({
          ...mediaItem,
          videoDurationSeconds: videoElementRef.current?.duration,
        });
      };

      videoElementRef.current.addEventListener('loadeddata', eventListener);

      return () => {
        videoElementRef.current?.removeEventListener(
          'loadeddata',
          eventListener
        );
      };
    }
  }, [isImage, previewUrl]);
  const priceOptions = [
    {
      key: '',
      value: '',
      text: t('Free'),
    },
    ...prices
      .filter((price) => price.type === 'INDIVIDUAL_MEDIA')
      .map((price) => ({
        key: price.id ?? '',
        value: price.id ?? '',
        text: `${price.label ?? ''} (${formattedCurrencyAmount(
          price.price ?? ''
        )})`,
      })),
  ];
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (mediaItem.url) {
      const url = new URL(mediaItem.url);

      s3MediaDownloadStorage
        .get(url.pathname.replace(/\//, ''))
        .then((url) => setPreviewUrl(url));
    }
  }, [mediaItem.url]);

  const [, drop] = useDrop({
    accept: ItemType,

    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      onMoveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: {
      type: ItemType,
      mediaItem,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;
  return (
    <>
      <div
        ref={ref}
        className={styles['picture__item__pic']}
        style={{
          opacity,
        }}
      >
        {isImage ? (
          <img src={previewUrl} />
        ) : (
          previewUrl && (
            <video ref={videoElementRef} width="160" height="160" controls>
              <source src={previewUrl} type="video/mp4" />
            </video>
          )
        )}
        <a className={styles['delete']} onClick={onRemove}>
          <img src={deleteIcon} />
        </a>
      </div>

      {!hidePriceInput && (
        <Select
          label={t('Price')}
          options={priceOptions}
          value={mediaItem.priceId}
          onChange={(e, { value }) =>
            onChange({ ...mediaItem, priceId: value })
          }
        />
      )}
    </>
  );
};
