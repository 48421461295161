import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';

import { fetchInquiries } from 'client/actions/inquiries';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ReduxState } from 'client/reducers';
import { Reservation } from 'shared/models/swagger';
import { SendSMSModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/SendSMSModal';
import { SendInquiryEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/SendInquiryEmailModal';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { InquiryAccordionItem } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/InquiryAccordionItem';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation?: Reservation;
};

export const Inquiry = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const inquiriesLastFetchedDateTimeUtc = useRef<string | null>(null);

  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  const dispatch = useDispatch();
  const inquiries = useSelector((state: ReduxState) => state.inquiries.all);
  const activeUser = useSelector(activeUserSelector);

  useEffect(() => {
    if (reservation) {
      inquiriesLastFetchedDateTimeUtc.current = moment().format();

      const reservationIds = [
        reservation?.id,
        ...(reservation?.rebooked_from_reservation_id_history ?? []),
      ];

      dispatch(
        fetchInquiries({
          reservation_ids: reservationIds,
          include_email_opened_at: true,
        })
      );
    }
  }, [reservation]);

  // Refresh inquiries data if the most recent inquiry was updated recently to make sure any async status changes for SMS
  // message operations are reflected.
  useEffect(() => {
    const lastUpdatedTime =
      inquiries && inquiries.length > 0
        ? inquiries?.[0]?.last_updated_date_time_utc
        : null;

    if (
      lastUpdatedTime &&
      inquiriesLastFetchedDateTimeUtc.current &&
      moment(lastUpdatedTime)
        .add(3, 'seconds')
        .isAfter(moment(inquiriesLastFetchedDateTimeUtc.current))
    ) {
      inquiriesLastFetchedDateTimeUtc.current = moment().format();
      const reservationIds: string[] = [];
      if (reservation?.id) {
        reservationIds.push(reservation?.id);
      }
      reservationIds.push(
        ...(reservation?.rebooked_from_reservation_id_history ?? [])
      );
      dispatch(
        fetchInquiries({
          reservation_ids: reservationIds,
          include_email_opened_at: true,
        })
      );
    }
  }, [reservation, inquiries]);

  return (
    <section
      id="inquiry"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Inquiries')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
              <Button
                text={t('Send SMS')}
                uiType="bg"
                size="md"
                color="white"
                onClick={() => setShowSMSModal(true)}
              />
            )}
            {showSMSModal && (
              <SendSMSModal
                reservation={reservation}
                open={showSMSModal}
                onClose={() => setShowSMSModal(false)}
              />
            )}
            {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
              <Button
                text={t('Send Email')}
                uiType="bg"
                size="md"
                color="white"
                onClick={() => setShowSendEmailModal(true)}
              />
            )}
            {showSendEmailModal && (
              <SendInquiryEmailModal
                reservation={reservation}
                open={showSendEmailModal}
                onClose={() => setShowSendEmailModal(false)}
              />
            )}
          </div>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <ul className={styles['p-contact']}>
            {inquiries.length === 0 && <>{t('No inquiries found')}</>}
            {inquiries.length > 0 &&
              inquiries.map((item, idx) => (
                <InquiryAccordionItem key={idx} inquiry={item} />
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
