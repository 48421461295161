import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import type { SearchMediaDownloadOrdersRequest } from 'client/libraries/util/searchMediaDownloadOrders';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import { fetchMediaDownloadOrders } from 'client/actions/mediaDownloadOrders';
import { Button } from 'client/components/Form';
import { convertSearchMediaDownloadOrdersRequestToQueryParams } from 'client/libraries/util/searchMediaDownloadOrders';
import { fetchProducts } from 'client/actions/products';
import { setLastExecutedMediaDownloadOrderSearchCondition } from 'client/actions/mediaDownloadOrderTableControls';
import { AccordionItem } from 'client/components/Accordion/Accordion';
import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';

import { useMediaDownloadOrderColumns } from './util';
import { MediaDownloadOrderSearchFiltersDisplayBox } from './MediaDownloadOrderSearchFiltersDisplayBox/MediaDownloadOrderSearchFiltersDisplayBox';
import { MediaDownloadOrderSearchSettingsModal } from './MediaDownloadOrderSearchSettingsModal/MediaDownloadOrderSearchSettingsModal';
import { MediaDownloadOrderSummary } from './MediaDownloadOrderSummary';

export const MediaDownloadOrderTable = () => {
  const [summaryIsOpen, setSummaryIsOpen] = React.useState(false);

  const columns: any = useMediaDownloadOrderColumns();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.mediaDownloadOrders.loading
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.mediaDownloadOrderTableControls.lastExecutedSearchCondition
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchMediaDownloadOrdersRequest>(
      lastExecutedSearchCondition
    );
  const mediaDownloadOrders = useSelector(
    (state: ReduxState) => state.mediaDownloadOrders.all
  );
  const sortedMediaDownloadOrders = React.useMemo(() => {
    return _.sortBy(
      mediaDownloadOrders,
      (order) => order.created_date_time_utc
    ).reverse();
  }, [mediaDownloadOrders]);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);
  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  const search = () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(
          setLastExecutedMediaDownloadOrderSearchCondition(searchCondition)
        );
      }

      dispatch(
        fetchMediaDownloadOrders(
          convertSearchMediaDownloadOrdersRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      supplierReference: '',
      customerGivenName: '',
      customerFamilyName: '',
      productIds: [],
      participationDateFrom: '',
      participationDateTo: '',
      createdDateFrom: '',
      createdDateTo: '',
      dateFilterPreset: 'PARTICIPATION_1_DAY',
    });
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <MediaDownloadOrderSearchSettingsModal
              onReset={reset}
              onSearch={search}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>
          {/*
        <div
         className={clsx(
           baseStyles['base-main__body__header__right'],
           baseStyles['spOrder-2']
         )}
        >
         <MediaDownloadOrderTableSettingsModal
           trigger={
             <a
               className={clsx(
                 baseStyles['base-btn'],
                 baseStyles['square'],
                 baseStyles['gray']
               )}
             >
               <img src={thIcon} />
             </a>
           }
         />
         </div>*/}
        </div>
      )}

      <Box mb={2}>
        <MediaDownloadOrderSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
        />
      </Box>

      <Box mb={2} maxWidth="700px">
        <AccordionItem
          header={t('Summary')}
          open={summaryIsOpen}
          onButtonClick={() => setSummaryIsOpen(!summaryIsOpen)}
        >
          {() => {
            return <MediaDownloadOrderSummary />;
          }}
        </AccordionItem>
      </Box>

      <CustomTable
        items={sortedMediaDownloadOrders}
        columns={columns}
        usePaging={true}
        shouldOmitMobileFirstChildStyles={true}
      />
    </div>
  );
};
