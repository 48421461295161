import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Modal } from 'client/components/Modal/Modal';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { ReduxState } from 'client/reducers/index';
import { Button, FieldWrapper } from 'client/components/Form';
import styles from 'client/pages/BookingForm/Book.module.css';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  reservation: Reservation;
  onClick?: () => void;
  onSubmit: () => void;
  open: boolean;
  onClose: () => void;
};

export const SubmitReservationButton = ({
  open,
  onClose,
  onClick,
  onSubmit,
  reservation,
}: Props) => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const startDateTime = moment
    .tz(reservation.start_date_time_utc, reservation.start_timezone ?? '')
    .locale(locale);
  return (
    <div>
      <Button
        style="blue"
        size="middle"
        type="button"
        onClick={() => {
          onClick?.();
        }}
      >
        {t('Book')}
      </Button>

      <Modal
        title={t('Confirm booking submission')}
        open={open}
        onClose={() => onClose()}
      >
        <div>
          <div className={clsx(styles['field'])}>
            <FieldWrapper label={t('Product')}>
              {reservation.product_name}
            </FieldWrapper>
          </div>
          <div className={clsx(styles['field'])}>
            <FieldWrapper label={t('Date')}>
              {startDateTime.format('ll')}
            </FieldWrapper>
          </div>
          <div className={clsx(styles['field'])}>
            <FieldWrapper label={t('Time')}>
              {startDateTime.format('LT')}
            </FieldWrapper>
          </div>
          <div className={clsx(styles['field'])}>
            <FieldWrapper label={t('Total participants')}>
              {(reservation.guests && reservation.guests.length) || 0}
            </FieldWrapper>
          </div>
          <div className={clsx(styles['field'])}>
            <ReservationActorInputForm />
          </div>
          <div className={clsx(styles['field'])}>
            <div
              className={clsx(
                baseStyles['base-main__box__body__bottomBtns'],
                baseStyles['center']
              )}
            >
              <Button
                style="gray"
                size="middle"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                {t('Cancel')}
              </Button>

              <Button
                style="blue"
                size="middle"
                type="button"
                onClick={() => {
                  onClose();
                  onSubmit();
                }}
              >
                {t('Book')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
