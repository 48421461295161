import { ThunkDispatch } from 'redux-thunk';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { TFunction } from 'react-i18next';
import React from 'react';

import { TextField } from 'client/components/v3/Form/TextField';
import { setActorName } from 'client/actions/user';
import { currentActorSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';

type Dispatch = ThunkDispatch<any, any, any>;

type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
/* eslint-enable no-use-before-define */

class ReservationActorInputFormComponent extends React.PureComponent<Props> {
  render() {
    const { actor, setActorName } = this.props;
    return (
      <TextField
        value={actor.name ?? ''}
        onChange={(value) => setActorName(value)}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  actor: currentActorSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setActorName: (name: string) => dispatch(setActorName(name)),
});

export const ReservationActorInputForm = compose<Props, Record<string, never>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ReservationActorInputFormComponent);
