import { getWeekdayFromDate } from 'client/libraries/util/weekdays';
import type { DispatchCrewMember, GuideSettings } from 'shared/models/swagger';

export type ResourceType = '' | 'vehicle' | 'crew' | 'other' | 'guide';
export const getResourceFilterOptions = <
  T extends {
    key?: string;
  }
>(
  resources: Array<T>
): Array<{
  key: string;
  value: string;
  text: string;
}> =>
  resources.map((r) => {
    const key = r.key || '';
    return {
      key,
      value: key,
      text: key,
    };
  });
export const crewMemberIsActiveForDate = (
  crewMember: DispatchCrewMember,
  participationDate: string
): boolean => {
  const participationWeekday = getWeekdayFromDate(participationDate);

  // No schedule == always active
  if (!crewMember.schedule || crewMember.schedule.length === 0) {
    return true;
  }

  for (const scheduleRule of crewMember.schedule) {
    // Check for [startDate, endDate] interval membership
    if (
      (scheduleRule.start_date_local &&
        scheduleRule.start_date_local > participationDate) ||
      (scheduleRule.end_date_local &&
        scheduleRule.end_date_local < participationDate)
    ) {
      // Participation date is outside date range -- keep looking
      continue;
    }

    if (
      scheduleRule.days_of_week &&
      scheduleRule.days_of_week.indexOf(participationWeekday) === -1
    ) {
      // Participation date falls on a weekday not contained in this schedule -- keep looking
      continue;
    }

    if (
      scheduleRule.excluded_dates &&
      scheduleRule.excluded_dates.indexOf(participationDate) !== -1
    ) {
      continue;
    }

    // This schedule matches date -- return true
    return true;
  }

  return false;
};
export const guideIsActiveForDate = (
  guide: GuideSettings,
  participationDate: string
): boolean => {
  const participationWeekday = getWeekdayFromDate(participationDate);

  if (
    (guide.operating_hours_rule?.days_of_week || []).includes(
      participationWeekday
    )
  ) {
    return true;
  }

  return false;
};
