import { Field } from 'react-final-form';
import React from 'react';

import {
  ProductCardFields,
  ProductList,
  truncated,
  withQueryParams,
} from '../../util';

const ProductListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ProductListEditButton')
);

type Props = {
  data: {
    editMode: boolean;
    productList: ProductList;
    language: string;
    groupedProducts: ProductCardFields[][];
    newsletterCampaignId: string;
  };
};

export const OtherRecommendedProductsSection = ({ data }: Props) => {
  return (
    <tr style={{ margin: 0, padding: 0 }}>
      <td>
        <div
          style={{
            margin: 0,
            padding: '1.5em',
            backgroundColor: '#ffffff',
          }}
        >
          <div style={{ margin: 0, padding: 0 }}>
            {data.editMode && (
              <Field name="templateValues.productList">
                {({ input }) => (
                  <ProductListEditButton
                    initialValue={data.productList}
                    onSave={(newValue) => input.onChange(newValue)}
                    language={data.language}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                fontSize: '1.5em',
                whiteSpace: 'pre-line',
                borderBottom: '1px solid #E4E4E7',
                paddingBottom: '1em',
              }}
            >
              {data.productList.title}
            </p>
            <table>
              <tbody>
                {data.groupedProducts.map((group, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td style={{ height: '16px' }}></td>
                      <td>
                        <div style={{ padding: '0 8px' }}></div>
                      </td>
                      <td style={{ height: '16px' }}></td>
                    </tr>
                    <tr>
                      {group.map((product, idx) => (
                        <React.Fragment key={idx}>
                          <td
                            style={{
                              width: '50%',
                              verticalAlign: 'top',
                            }}
                          >
                            <div
                              style={{
                                padding: '16px',
                                border: '1px solid #E4E4E7',
                                borderRadius: '16px',
                                height: '480px',
                              }}
                            >
                              <a
                                href={withQueryParams(product.productUrl, {
                                  utm_source: 'ntmg.newsletter',
                                  utm_medium: 'email',
                                  utm_campaign:
                                    data.newsletterCampaignId ?? 'todo',
                                  utm_content: 'productList',
                                  lng: data.language,
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: 'inherit',
                                  color: 'inherit',
                                  display: 'table',
                                  height: '100%',
                                }}
                              >
                                {/* Calculate width for image manually since position properties is not supported in gmail and other email clients */}
                                {/* 600 - 42 = 558, 558 - 16 = 542, 542 / 2 = 271, 271 - 32 = 239 (approx 240px) */}
                                <div
                                  style={{
                                    marginBottom: '16px',
                                  }}
                                >
                                  <img
                                    src={product.productThumbnailUrl}
                                    style={{
                                      width: '240px',
                                      height: '160px',
                                      objectFit: 'cover',
                                      borderRadius: '8px',
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: '#333333',
                                    verticalAlign: 'middle',
                                    marginBottom: '24px',
                                  }}
                                >
                                  {/* Since line clamp does not work in gmail, manually append ellipsis */}
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                      lineHeight: '1.5em',
                                    }}
                                  >
                                    {truncated(product.productName, 80)}
                                  </p>
                                  <p
                                    style={{
                                      margin: '.5em 0 0 0',
                                      padding: 0,
                                      fontSize: '12px',
                                      color: '#4A6C7A',
                                      lineHeight: '1.5em',
                                    }}
                                  >
                                    {truncated(product.productDescription, 120)}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'table-row',
                                    verticalAlign: 'bottom',
                                    height: '48px',
                                  }}
                                >
                                  <p
                                    style={{
                                      maxWidth: '240px',
                                      borderRadius: '9999px',
                                      textDecoration: 'none',
                                      display: 'block',
                                      margin: '0 auto',
                                    }}
                                  >
                                    <span
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        display: 'block',
                                        fontWeight: 'bold',
                                        width: '100%',
                                        height: '48px',
                                        lineHeight: '48px',
                                        fontSize: '16px',
                                        backgroundColor: '#4DA1EF',
                                        color: '#ffffff',
                                        textDecoration: 'none',
                                        textAlign: 'center',
                                        borderRadius: '40px',
                                        whiteSpace: 'pre-line',
                                      }}
                                    >
                                      {product.buttonText}
                                    </span>
                                  </p>
                                </div>
                              </a>
                            </div>
                          </td>
                          {idx % 2 === 0 ? (
                            <td>
                              <div style={{ padding: '0 8px' }}></div>
                            </td>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};
