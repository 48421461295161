import { useTranslation } from 'react-i18next';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import moment from 'moment';

import { BaseGadget } from 'client/components/v3/DashboardGadgets/BaseGadget';
import { CustomerReservationData } from 'client/pages/v3/AnnualPass/type';
import { getCustomerVisitsByDate } from 'client/pages/v3/AnnualPass/Gadgets/AnnualPassStageGadget';
import { Customer, NpsSurveyReportDataSet } from 'shared/models/swagger';

import { AverageConversion, Props, TooltipProps } from './type';
import styles from './Chart.module.css';

const CustomTooltip = ({ active, payload, label, t }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className={styles['c-tooltip']}>
          <div className={styles['c-tooltip__header']}>
            <p className={styles['c-tooltip__header__ttl']}>
              {label}
              {t('pt')}
            </p>
          </div>
          <div className={styles['c-tooltip__body']}>
            {payload.map((payload, index) => (
              <p className={styles['c-tooltip__body__item']} key={index}>{`${
                payload.name === 'Base Period'
                  ? t('Base Period', { context: 'nps' })
                  : t(payload.name)
              }: ${t('{{count}} time', { count: payload.value })}`}</p>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
};

type CorrelationProps = Props & {
  customerReservationData: CustomerReservationData[] | null;
};

type CustomerReservation = {
  customer: Customer;
  visitsByDate: {
    date: string;
    visitCount: number;
  }[];
};

export const PromotionVisitFrequencyCorrelationChart = ({
  baseDataSet,
  comparisonDataSet,
  loading = false,
  customerReservationData,
}: CorrelationProps) => {
  const { t } = useTranslation();

  const categorizedCustomerReservationData =
    customerReservationData &&
    customerReservationData.map(({ customer, reservations }) => {
      // Filter all CONFIRMED reservations
      const confirmedReservations = reservations.filter(
        (r) => r.status === 'CONFIRMED'
      );
      const visitsByDate = getCustomerVisitsByDate(confirmedReservations);

      return {
        customer,
        visitsByDate,
      };
    });

  const constructChartData = (
    baseDataSet: NpsSurveyReportDataSet,
    comparisonDataSet: NpsSurveyReportDataSet | null,
    filteredCustomerReservationData: CustomerReservation[] | null
  ): AverageConversion[] => {
    const chartData = Array.from({ length: 11 }, (_, i) => ({
      name: i.toString(),
      'Base Period': 0,
      'Comparison Period': 0,
    }));
    const baseScoreCounts = Array.from({ length: 11 }, () => 0);
    const comparisonScoreCounts = Array.from({ length: 11 }, () => 0);

    const processDataSet = (
      dataSet: NpsSurveyReportDataSet | null,
      scoreCount: number[],
      period: 'Base Period' | 'Comparison Period'
    ) => {
      if (dataSet && dataSet.items_by_date) {
        dataSet.items_by_date.forEach((item) => {
          item.results?.forEach((result) => {
            if (result.nps_score) {
              const npsScore = parseInt(result.nps_score, 10);
              const customerData = filteredCustomerReservationData?.find(
                (data) => data.customer.id === result.customer_id
              );
              let totalVisits = 0;
              if (customerData) {
                const { start, end } = dataSet.range ?? {};
                const uniqueVisitDates = new Set(
                  customerData?.visitsByDate
                    .filter(
                      (visit) =>
                        visit.date &&
                        start &&
                        end &&
                        moment(visit.date).isSameOrAfter(start) &&
                        moment(visit.date).isSameOrBefore(end)
                    )
                    .map((visit) => visit.date)
                );
                totalVisits = uniqueVisitDates.size;
                scoreCount[npsScore]++;
              }

              chartData[npsScore][period] += totalVisits;
            }
          });
        });
      }
    };

    processDataSet(baseDataSet, baseScoreCounts, 'Base Period');
    processDataSet(
      comparisonDataSet,
      comparisonScoreCounts,
      'Comparison Period'
    );

    chartData.forEach((data, index) => {
      if (baseScoreCounts[index] > 0) {
        data['Base Period'] /= baseScoreCounts[index];
      }
      if (comparisonScoreCounts[index] > 0) {
        data['Comparison Period'] /= comparisonScoreCounts[index];
      }
    });

    return chartData;
  };

  const chartData = constructChartData(
    baseDataSet,
    comparisonDataSet,
    categorizedCustomerReservationData
  );

  const renderLegendText = (value: string) => {
    return (
      <span style={{ color: '#71717A' }}>{t(value, { context: 'nps' })}</span>
    );
  };

  if (!customerReservationData) {
    return <></>;
  }

  return (
    <BaseGadget
      header={t('Promotion - Visit Frequency Correlation')}
      loading={loading}
    >
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={250}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis domain={[0, 10]} unit={t('pt')} tickLine={false} />
          <YAxis
            tickLine={false}
            tickFormatter={(value) => {
              return t('{{count}} time', { count: value });
            }}
            allowDecimals={false}
          />
          <Tooltip
            content={<CustomTooltip t={t} />}
            cursor={{ fill: 'transparent' }}
          />
          <Legend align="center" formatter={renderLegendText} />
          <Line
            type="linear"
            dataKey="Base Period"
            stroke="#3B82F6"
            activeDot={{
              r: 6,
            }}
            strokeWidth={2}
            dot={{ fill: '#3B82F6', r: 4 }}
          />
          {comparisonDataSet && (
            <Line
              type="linear"
              dataKey="Comparison Period"
              stroke="#BFDBFE"
              activeDot={{
                r: 6,
              }}
              strokeWidth={2}
              dot={{ fill: '#BFDBFE', r: 4 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </BaseGadget>
  );
};
