import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_CUSTOM_TOP_PAGE_REQUEST,
  FETCH_CUSTOM_TOP_PAGE_SUCCESS,
  FETCH_CUSTOM_TOP_PAGE_FAILURE,
  PUT_CUSTOM_TOP_PAGE_FAILURE,
  PUT_CUSTOM_TOP_PAGE_REQUEST,
  PUT_CUSTOM_TOP_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_CUSTOM_TOP_PAGE_REQUEST:
    case PUT_CUSTOM_TOP_PAGE_REQUEST:
      return '';

    case FETCH_CUSTOM_TOP_PAGE_FAILURE:
    case PUT_CUSTOM_TOP_PAGE_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_CUSTOM_TOP_PAGE_REQUEST:
      return true;

    case FETCH_CUSTOM_TOP_PAGE_FAILURE:
    case FETCH_CUSTOM_TOP_PAGE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const data = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_CUSTOM_TOP_PAGE_SUCCESS:
    case PUT_CUSTOM_TOP_PAGE_SUCCESS: {
      return action.response?.data ?? '';
    }

    case FETCH_CUSTOM_TOP_PAGE_FAILURE:
      return '';

    default:
      return state;
  }
};

export const customTopPage = combineReducers({
  error,
  loading,
  data,
});
