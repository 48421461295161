import * as React from 'react';
import clsx from 'clsx';

import baseStyles from '../../base.module.css';

const style = {
  backgroundColor: '#F9F9F9',
  cursor: 'move',
};
interface Props {
  text: string;
  toggle: () => void;
}
export const UnselectedItem = ({ text, toggle }: Props) => {
  return (
    <div className={clsx(baseStyles['base-form-checkbox'])} style={style}>
      <label className={clsx(baseStyles['dnd'])}>
        <input
          type="checkbox"
          name={text}
          checked={false}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggle();
          }}
        />
        <p></p>
        {text}
      </label>
    </div>
  );
};
