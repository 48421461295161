import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { Loading } from 'client/pages/Loading';
import { ReduxState } from 'client/reducers';
import { NewsletterTemplate } from 'shared/components/templates/newsletter/NewsletterTemplate';
import { fetchMarketingAutomationCampaign } from 'client/actions/marketingAutomationCampaigns';
import { fetchMarketingAutomationContent } from 'client/actions/marketingAutomationContents';

export const MarketingAutomationCampaignEmail = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationCampaign(id));
  }, [id]);

  const campaign = useSelector((state: ReduxState) =>
    state.marketingAutomationCampaigns.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    if (campaign?.content_id) {
      dispatch(fetchMarketingAutomationContent(campaign.content_id));
    }
  }, [campaign?.content_id]);

  const content = useSelector((state: ReduxState) =>
    state.marketingAutomationContents.all.find(
      (n) => n.id === campaign?.content_id
    )
  );

  if (!campaign || !content) {
    return <Loading />;
  }

  const selectedTemplate = content?.email_content?.email_template_name;
  const templateValues = JSON.parse(
    content?.email_content?.email_template_values_json ?? ''
  );

  return (
    <>
      <Link to="/marketingautomation/campaigns">
        <BackArrow />
      </Link>
      <React.Suspense fallback={<Loading />}>
        <div>
          <NewsletterTemplate
            selectedTemplate={selectedTemplate as any}
            templateValues={templateValues}
            customerName={t('John Nutmeg')}
          />
        </div>
      </React.Suspense>
    </>
  );
};
