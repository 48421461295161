import * as React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { getHTTPRequestHeaders } from 'client/actions';
import { ReduxState } from 'client/reducers';

interface FileInputProps {
  input: {
    onChange: (arg: { content: File | null; name: string }) => void;
    name: string;
  };
}

export const FileInputForm = ({
  input: { onChange, name },
}: FileInputProps) => {
  const headers = useSelector((state: ReduxState) =>
    getHTTPRequestHeaders(state)
  );

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    console.log('handleFileChange', file);

    if (!file) {
      return;
    }

    try {
      const customerListUploadReponse = await axios.get(
        '/api/customerlistuploadurls/new',
        {
          headers,
        }
      );

      await axios.put(customerListUploadReponse.data.upload_url, file, {
        headers: {
          'Content-Type': 'text/csv',
        },
      });

      onChange(customerListUploadReponse.data.s3_key);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} name={name} />
    </div>
  );
};
