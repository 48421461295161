import * as React from 'react';

interface Props {
  data: {
    title: string;
    dates: string;
    availableSlots: string;
    style: string;
  };
  language: string;
}

export const HighlightedDateSection = ({ data, language }: Props) => {
  const { title, dates, availableSlots, style } = data;

  if (style === 'BLUE') {
    return (
      <>
        <div
          style={{
            marginTop: '1em',
            padding: '1em .5em',
            background: '#E6F4FA',
            borderRadius: '4px',
            borderLeft: '4px solid #008EC9',
          }}
        >
          <div style={{ width: '100%', display: 'table' }}>
            <div
              style={{
                width: '40px',
                height: '40px',
                background: '#FFFFFF',
                borderRadius: '100%',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
            >
              <img
                src="https://assets.ntmg.com/newsletter/calendar1.png"
                style={{
                  width: '24px',
                  height: '24px',
                  margin: '8px',
                }}
              />
            </div>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#008EC9',
                paddingLeft: '8px',
                display: 'table-cell',
                verticalAlign: 'middle',
                whiteSpace: 'pre-line',
              }}
            >
              {title}
            </p>
          </div>
          <div
            style={{
              width: '100%',
              marginTop: '.5em',
              display: 'table',
            }}
          >
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                margin: '0 .5em 0 0',
                padding: 0,
                display: 'inline-block',
              }}
            >
              {dates}
            </p>
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '32px',
                color: '#FFFFFF',
                background: '#FF6464',
                margin: 0,
                padding: '0 .25em',
                display: 'inline-block',
              }}
            >
              {language.startsWith('ja')
                ? `残り${availableSlots}枠`
                : `${availableSlots} spots left`}
            </p>
          </div>
        </div>
      </>
    );
  } else if (style === 'YELLOW') {
    return (
      <>
        <div
          style={{
            marginTop: '2em',
            padding: '1em .5em',
            borderRadius: '4px',
            border: '2px solid #FFCC00',
            position: 'relative',
          }}
        >
          <p
            style={{
              width: '280px',
              height: '24px',
              fontSize: '22px',
              fontWeight: 'bold',
              textAlign: 'center',
              background: '#FFFFFF',
              margin: '0 auto',
              padding: '0 .5em',
              display: 'block',
              position: 'relative',
              top: '-24px',
              boxSizing: 'border-box',
              whiteSpace: 'pre-line',
            }}
          >
            {title}
          </p>
          <div
            style={{
              width: '100%',
              marginTop: '-0.5em',
              display: 'table',
            }}
          >
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
            >
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  background: '#FFCC00',
                  borderRadius: '100%',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <img
                  src="https://assets.ntmg.com/newsletter/calendar2.png"
                  style={{
                    width: '24px',
                    height: '24px',
                    margin: '8px',
                  }}
                />
              </div>
              <p
                style={{
                  margin: 0,
                  fontSize: '16px',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                {dates}
              </p>
            </div>

            {language.startsWith('ja') ? (
              <p
                style={{
                  fontSize: '14px',
                  color: '#4A6C7A',
                  fontWeight: 'bold',
                  margin: '0',
                  padding: '0',
                  textAlign: 'right',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                }}
              >
                残り
                <span
                  style={{
                    fontSize: '32px',
                    color: '#FF6464',
                    margin: '0 -4px',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  {availableSlots}
                </span>
                枠
              </p>
            ) : (
              <p
                style={{
                  fontSize: '14px',
                  color: '#4A6C7A',
                  fontWeight: 'bold',
                  margin: '0',
                  padding: '0',
                  textAlign: 'right',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                }}
              >
                <span
                  style={{
                    fontSize: '32px',
                    color: '#FF6464',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  {availableSlots}
                </span>
                spots left
              </p>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: '2em',
          padding: '1em .5em',
          borderRadius: '4px',
          border: '2px solid #FF6464',
          background: '#FFEBEB',
          position: 'relative',
        }}
      >
        <p
          style={{
            width: '280px',
            lineHeight: '36px',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '36px',
            color: '#FFFFFF',
            background: '#FF6464',
            margin: '0 auto',
            padding: '0 .5em',
            display: 'block',
            position: 'relative',
            top: '-36px',
            boxSizing: 'border-box',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </p>
        <div
          style={{
            width: '100%',
            marginTop: '-1em',
            display: 'table',
          }}
        >
          <p
            style={{
              margin: 0,
              padding: '.5em',
              background: '#FFFFFF',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {dates}
          </p>
          {language.startsWith('ja') ? (
            <p
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#4A6C7A',
                margin: '1em 0 0 0',
                padding: 0,
                textAlign: 'center',
              }}
            >
              残り
              <span
                style={{
                  fontSize: '32px',
                  color: '#FF6464',
                  margin: '0 -4px',
                  verticalAlign: 'text-bottom',
                }}
              >
                {availableSlots}
              </span>
              枠
            </p>
          ) : (
            <p
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#4A6C7A',
                margin: '1em 0 0 0',
                padding: 0,
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  fontSize: '32px',
                  color: '#FF6464',
                  verticalAlign: 'text-bottom',
                }}
              >
                {availableSlots}
              </span>
              spots left
            </p>
          )}
        </div>
      </div>
    </>
  );
};
