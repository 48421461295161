import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { ModalLoader } from 'client/components/ModalLoader';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
import type { Translation } from 'shared/models/swagger';

import styles from './EditTranslationsModal.module.css';

type Language = 'EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW';
type Props = {
  sourceLanguage: Language;
  sourceText: string;
  translationLanguages: Language[];
  trigger: React.ReactElement<any>;
  useTextArea?: boolean;
};
const emptyTranslation = {
  en_us: '',
};
export const EditTranslationsModal = ({
  sourceLanguage,
  sourceText,
  translationLanguages,
  trigger,
  useTextArea,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [translation, setTranslation] =
    React.useState<Translation>(emptyTranslation);
  const loading = useSelector(
    (state: ReduxState) => state.translations.loading
  );
  const originalTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const lastUpdateStatus = useSelector(
    (state: ReduxState) => state.translations.lastUpdateStatus
  );
  const translationError = useSelector(
    (state: ReduxState) => state.translations.error
  );
  React.useEffect(() => {
    if (showModal) {
      dispatch(batchGetTranslations([sourceText]));
    }
  }, [dispatch, sourceText, showModal]);
  const reset = React.useCallback(() => {
    if (originalTranslations.length > 0) {
      setTranslation(originalTranslations[0]);
    } else {
      setTranslation(emptyTranslation);
    }
  }, [originalTranslations]);
  React.useEffect(() => {
    reset();
  }, [originalTranslations, reset]);
  React.useEffect(() => {
    if (translationError) {
      setError(translationError);
    } else if (lastUpdateStatus === 'SUCCEEDED') {
      setError('');
    }
  }, [lastUpdateStatus, translationError]);
  const pristine =
    translation === emptyTranslation ||
    (originalTranslations.length > 0 &&
      translation === originalTranslations[0]);
  return (
    <Modal
      title={t('Edit Translations')}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
      trigger={trigger}
    >
      <Modal.Content>
        {loading && <ModalLoader />}
        <div className={styles['input-box']}>
          <FieldWrapper
            label={getLanguageName(
              uppercaseIsoToLowercaseIso[sourceLanguage],
              t
            )}
          >
            {sourceText}
          </FieldWrapper>
        </div>
        {translationLanguages.map((lang) => {
          return useTextArea ? (
            <div className={styles['input-box']}>
              <TextArea
                key={lang}
                label={getLanguageName(uppercaseIsoToLowercaseIso[lang], t)}
                value={
                  translation[lang.toLowerCase() as keyof Translation] || ''
                }
                onChange={(e, { value }) =>
                  setTranslation({
                    ...translation,
                    [lang.toLowerCase()]: value,
                  })
                }
              />
            </div>
          ) : (
            <div className={styles['input-box']}>
              <Input
                key={lang}
                label={getLanguageName(uppercaseIsoToLowercaseIso[lang], t)}
                value={
                  translation[lang.toLowerCase() as keyof Translation] || ''
                }
                onChange={(e, { value }) =>
                  setTranslation({
                    ...translation,
                    [lang.toLowerCase()]: value,
                  })
                }
              />
            </div>
          );
        })}
        {error && <Message error header={t('Update failed')} content={error} />}
        {submitted && lastUpdateStatus === 'SUCCEEDED' && (
          <Message success header={t('Update succeeded')} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="small" disabled={pristine} onClick={reset}>
          {t('Discard')}
        </Button>
        <Button
          disabled={pristine}
          style="blue"
          size="small"
          onClick={() => {
            dispatch(
              updateTranslations([
                {
                  ...translation,
                  [sourceLanguage.toLowerCase()]: sourceText,
                  source_language: sourceLanguage,
                },
              ])
            );
            setSubmitted(true);
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
