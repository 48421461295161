import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { s3MediaDownloadStorage } from 'client/libraries/s3';
import deleteIcon from 'client/images/ic_delete.svg';

import type { DownloadItem } from './formValues';
import styles from './DownloadItemInput.module.css';

const ItemType = 'media';
type DragItem = {
  index: number;
  imageVideoUrl: string;
  type: string;
};
type Props = {
  mediaItem: DownloadItem;
  index: number;
  onRemove: () => void;
  onMoveImage: (dragIndex: number, hoverIndex: number) => void;
  onChange: (newValue: DownloadItem) => void;
  hidePriceInput?: boolean;
};
export const DraggablePreviewDownloadItem = ({
  mediaItem,
  index,
  onRemove,
  onMoveImage,
  onChange,
}: Props) => {
  const videoElementRef = React.useRef<HTMLVideoElement>(null);

  const [previewUrl, setPreviewUrl] = React.useState<string>('');

  const isImage = mediaItem.mediaType !== 'VIDEO';

  React.useEffect(() => {
    if (videoElementRef.current) {
      const eventListener = () => {
        console.log('listener called');
        onChange({
          ...mediaItem,
          videoDurationSeconds: videoElementRef.current?.duration,
        });
      };

      videoElementRef.current.addEventListener('loadeddata', eventListener);

      return () => {
        videoElementRef.current?.removeEventListener(
          'loadeddata',
          eventListener
        );
      };
    }
  }, [isImage, previewUrl]);
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (mediaItem.url) {
      const url = new URL(mediaItem.url);

      s3MediaDownloadStorage
        .get(url.pathname.replace(/\//, ''))
        .then((url) => setPreviewUrl(url));
    }
  }, [mediaItem.url]);

  const [, drop] = useDrop({
    accept: ItemType,

    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      onMoveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: {
      type: ItemType,
      mediaItem,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;
  return (
    <>
      <div
        ref={ref}
        className={styles['picture__item__pic']}
        style={{
          opacity,
        }}
      >
        {isImage ? (
          <img src={previewUrl} />
        ) : (
          previewUrl && (
            <video ref={videoElementRef} width="160" height="160" controls>
              <source src={previewUrl} type="video/mp4" />
            </video>
          )
        )}
        <a className={styles['delete']} onClick={onRemove}>
          <img src={deleteIcon} />
        </a>
      </div>
    </>
  );
};
