import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { cancelSubscription } from 'client/actions/subscriptions';

interface Props {
  onClose: () => void;
  subscriptionId: string;
}

export const SubscriptionCancelModal = ({ onClose, subscriptionId }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      insertRoot
      onClose={onClose}
      open={true}
      title={t('Cancel Manual Subscription')}
    >
      <Modal.Content>
        {t('Are you sure you want to cancel manual subscription?')}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('No')}
        </Button.Cancel>
        <Button.Submit
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await dispatch(cancelSubscription(subscriptionId));
            setLoading(false);
            onClose();
          }}
        >
          {t('Yes')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
