import { useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from 'axios';
import moment from 'moment';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { buildSearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import {
  FlexibleDashboardTargetDateType,
  convertDateRange,
} from 'client/libraries/util/getPresetSearchReservationsRequest';

const fetcher = (url: string, paramsString: string, headerString: string) =>
  axios
    .get(url, {
      params: {
        ...JSON.parse(paramsString),
        page_size: 10000,
      },
      headers: JSON.parse(headerString),
    })
    .then((res) => res.data ?? []);

export const useReservationData = (
  gadget: DashboardGadget
): {
  data: Swagger.Reservation[] | null;
  total: number;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToSearchReservationRequest(gadget);

  const { data, error } = useSWR<Swagger.SearchReservationsResponse>(
    [
      `/api/reservations/search`,
      JSON.stringify(queryParams),
      JSON.stringify(headers),
    ],
    fetcher
  );
  return {
    data: data?.reservations ?? null,
    total: data?.total ?? 0,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const convertParamsToSearchReservationRequest = (
  gadget: DashboardGadget
): Swagger.SearchReservationsRequest | undefined => {
  const request = {
    agentReference: '',
    presetKey: '',
    supplierReference: '',
    id: '',
    customerGivenName: '',
    customerFamilyName: '',
    customerPhone: '',
    customerEmail: '',
    groupIds: [],
    supplierIds: [],
    bookedDateFrom: '',
    bookedDateTo: '',
    participationDateFrom: '',
    participationDateTo: '',
    lastUpdatedDateFrom: '',
    lastUpdatedDateTo: '',
    dateFilterPreset: null,
    supplierOrAgentReference: '',
    mostRecentEmailBounced: false,
    pickupCheckinLocationName: '',
    waiverCompletionStatuses: [],
    checkinStatuses: [],
    annualPassOnly: false,
    expirationPresetKey: '',
    expirationDateFrom: '',
    expirationDateTo: '',
    expirationDateFilterPreset: null,
    automaticContinuingStatus: null,
  };

  if (
    gadget.gadgetType === 'reservation-summary' ||
    gadget.gadgetType === 'reservation-list'
  ) {
    let targetDateTypeConvert: FlexibleDashboardTargetDateType;
    if (gadget.params.targetDateType === 'CURRENT_DATE') {
      targetDateTypeConvert = 'CURRENT_DATE';
    } else if (gadget.params.targetDateType === 'NEXT_DATE') {
      targetDateTypeConvert = 'NEXT_DATE';
    } else if (gadget.params.targetDateType === 'PREVIOUS_DATE') {
      targetDateTypeConvert = 'PREVIOUS_DATE';
    }
    // RANGE
    else {
      if (gadget.params.dateRangeType === 'NEXT') {
        targetDateTypeConvert = 'NEXT_DATE_RANGE';
      } else {
        targetDateTypeConvert = 'PREVIOUS_DATE_RANGE';
      }

      // If we only want to set either only 'date from' or 'date to'
      if (
        gadget.gadgetType === 'reservation-list' &&
        gadget.params.shouldSetAsIndefinite &&
        gadget.params.dateRangeType === 'PREV'
      ) {
        targetDateTypeConvert = 'TO_CURRENT_DATE';
      } else if (
        gadget.gadgetType === 'reservation-list' &&
        gadget.params.shouldSetAsIndefinite &&
        gadget.params.dateRangeType === 'NEXT'
      ) {
        targetDateTypeConvert = 'FROM_CURRENT_DATE';
      }
    }

    const dateRelatedFields = {
      date_type: gadget.params.dateType,
      target_date_type: targetDateTypeConvert,
      previous_date_range: gadget.params.dateRange ?? 0,
      next_date_range: gadget.params.dateRange ?? 0,
    };

    return buildSearchReservationsRequest({
      ...request,
      orderBy: gadget.params.orderBy,
      statuses: gadget.params.statuses ?? [],
      productIds: gadget.params.productIds ?? [],
      bookingSourceTypes: gadget.params.bookingSources ?? [],
      agentIds: gadget.params.agents ?? [],
      reservationLanguages: gadget.params.languages ?? [],
      ...convertDateRange(dateRelatedFields, moment()),
    });
  }
};
