// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'client/pages/Loading';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { Select } from 'client/components/Form';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import { fetchProductTranslations } from 'client/actions/translations';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { BasicEditor } from 'client/pages/ProductEditor/BasicEditor/BasicEditor';
import { DetailsEditor } from 'client/pages/ProductEditor/DetailsEditor/DetailsEditor';
import { ReservationParametersEditor } from 'client/pages/ProductEditor/ReservationParametersEditor/ReservationParametersEditor';
import { getProductEditorDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import type { SourceLanguage, Translation } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { config } from 'client/config';

import styles from './ProductEditor.module.css';

type TabCategory = 'basic' | 'reservation' | 'detail';

const getTranslationSourceLanguageText = (translation: Translation): string => {
  if (!translation.source_language) {
    return '';
  }

  return translation[translation.source_language?.toLowerCase() ?? ''] ?? '';
};

const isNullTranslation = (translation: Translation): boolean => {
  // Translation is null if it has no source language
  if (!translation.source_language) {
    return true;
  }

  // Translation is null if it has no source language text
  if (!translation[translation.source_language?.toLowerCase() ?? '']) {
    return true;
  }

  // Translation is null if it has no translated text for a language other than source
  // language
  for (const key of Object.keys(translation)) {
    if (
      key !== 'source_language' &&
      key !== translation.source_language?.toLowerCase()
    ) {
      return false;
    }
  }

  return true;
};

export const ProductEditor = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { id: editingProductId } = useParams();

  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage | null>(null);
  const [activeTab, setActiveTab] = React.useState<TabCategory>('basic');

  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  const languageOptions = contentLanguageOptions
    .map((option) => ({
      value: lowercaseIsoToUppercaseIso[option.iso],
      text: getLanguageName(option.iso, t),
    }))
    .filter((option) => option.value !== editingProduct?.source_language);

  React.useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId]);

  const defaultTranslationTargetLanguage = languageOptions[0].value;
  React.useEffect(() => {
    if (showTranslationMode) {
      setTranslationTargetLanguage(defaultTranslationTargetLanguage);
    } else {
      setTranslationTargetLanguage(null);
    }
  }, [defaultTranslationTargetLanguage, showTranslationMode]);

  const productTranslationsLoading = useSelector(
    (state: ReduxState) => state.translations.loading
  );
  const productTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  React.useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchProductTranslations(editingProductId));
    }
  }, [editingProductId]);

  React.useEffect(() => {
    // Default translation mode to "ON" if product name is translated.
    // We assume that if product name is translated, user has input translations for the product.
    if (
      editingProduct &&
      productTranslations.some(
        (translation) =>
          !isNullTranslation(translation) &&
          getTranslationSourceLanguageText(translation) ===
            editingProduct.product_name
      )
    ) {
      setShowTranslationMode(true);
    }
  }, [productTranslations, productTranslationsLoading, editingProduct]);

  if (!editingProduct) {
    return <Loading />;
  }

  return (
    <EditingProductContext.Provider value={editingProduct ?? null}>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="PRODUCT_EDIT" />
      )}
      <TranslationLanguageContext.Provider value={translationTargetLanguage}>
        <div className={baseStyles['base-main__body']}>
          <div
            className={clsx(
              baseStyles['base-flex'],
              styles['header-product-title']
            )}
          >
            <Link to={`/products/${editingProduct?.id}`}>
              <BackArrow />
            </Link>
            <div className={baseStyles['base-main__headline__ttl']}>
              <h1>{getProductEditorDisplayProductName(editingProduct)}</h1>
            </div>
          </div>
          <div className={baseStyles['base-main__body__header']}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spSpacebetween'],
                baseStyles['spOrder-1']
              )}
            >
              <div className={baseStyles['base-form-toggle']}>
                <label>
                  <input
                    type="checkbox"
                    checked={showTranslationMode}
                    onClick={() => setShowTranslationMode(!showTranslationMode)}
                  />
                  <p></p>
                </label>
                {t('Translation mode')}
              </div>
              {showTranslationMode && (
                <Select
                  width={176}
                  options={languageOptions}
                  onChange={(e, { value }) => {
                    setTranslationTargetLanguage(value);
                  }}
                  value={translationTargetLanguage}
                />
              )}
            </div>
          </div>
          <div className={styles['c-tab-box']}>
            <ul className={styles['c-tab-box__tab']}>
              <li
                className={clsx(activeTab === 'basic' && styles['is-active'])}
                onClick={() => setActiveTab('basic')}
              >
                <a>{t('Basic Information')}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'reservation' && styles['is-active']
                )}
                onClick={() => setActiveTab('reservation')}
              >
                <a>{t('Reservation Parameters')}</a>
              </li>
              <li
                className={clsx(activeTab === 'detail' && styles['is-active'])}
                onClick={() => setActiveTab('detail')}
              >
                <a>{t('Detail Information')}</a>
              </li>
            </ul>

            <div
              className={clsx(
                baseStyles['base-main__body__box'],
                styles['c-tab-box__box'],
                activeTab === 'basic' && styles['is-active']
              )}
            >
              <div className={baseStyles['base-main__body__box__header']}>
                <div
                  className={baseStyles['base-main__body__box__header__ttl']}
                >
                  {t('Basic Information')}
                </div>
              </div>
              <div className={baseStyles['base-main__body']}>
                <BasicEditor active={activeTab === 'basic'} />
              </div>
            </div>
            <div
              className={clsx(
                baseStyles['base-main__body__box'],
                styles['c-tab-box__box'],
                activeTab === 'reservation' && styles['is-active']
              )}
            >
              <div className={baseStyles['base-main__body__box__header']}>
                <div
                  className={baseStyles['base-main__body__box__header__ttl']}
                >
                  {t('Reservation Parameters')}
                </div>
              </div>
              <div className={baseStyles['base-main__body']}>
                <ReservationParametersEditor
                  active={activeTab === 'reservation'}
                />
              </div>
            </div>
            <div
              className={clsx(
                baseStyles['base-main__body__box'],
                styles['c-tab-box__box'],
                activeTab === 'detail' && styles['is-active']
              )}
            >
              <div className={baseStyles['base-main__body__box__header']}>
                <div
                  className={baseStyles['base-main__body__box__header__ttl']}
                >
                  {t('Detail Information')}
                </div>
              </div>
              <div className={baseStyles['base-main__body']}>
                <DetailsEditor />
              </div>
            </div>
          </div>
        </div>
      </TranslationLanguageContext.Provider>
    </EditingProductContext.Provider>
  );
};
