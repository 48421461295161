import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import { MediaDownloadOrder, SourceLanguage } from 'shared/models/swagger';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

export const useMediaDownloadOrderColumns =
  (): ColumnType<MediaDownloadOrder>[] => {
    const { t } = useTranslation();
    const locale = useSelector(
      (state: ReduxState) => state.language.selected.iso
    );
    const productsSummaries = useSelector(summariesSelector);

    // Need to change the order of given name and family name when the
    // reservation form requires kana name
    const getGuestName = (givenName: string, familyName: string): string => {
      if (isKanaWord(givenName) && isKanaWord(familyName)) {
        return `${familyName} ${givenName}`;
      }

      return `${givenName} ${familyName}`;
    };

    return [
      {
        Header: t('Purchase Date'),
        width: 'middle',
        accessor: (row) =>
          moment(row.created_date_time_utc).locale(locale).format('lll'),
        id: 'purchasedDate',
      },
      {
        Header: t('Language'),
        accessor: (row) =>
          getLanguageName(
            uppercaseIsoToLowercaseIso[row.language as SourceLanguage],
            t
          ),
        id: 'language',
      },
      {
        Header: t('Application Number'),
        accessor: 'reservation_agent_reference',
        Cell: (cellInfo) => (
          <Link
            to={`/reservations/${cellInfo.original.reservation_id}?t=mediaDownload#detail`}
            target="_blank"
          >
            {cellInfo.original.reservation_agent_reference}
          </Link>
        ),
        id: 'agentReference',
      },
      {
        Header: t('Confirmation Number'),
        accessor: 'reservation_supplier_reference',
        id: 'supplierReference',
      },
      {
        Header: t('Participation'),
        accessor: (row) =>
          moment
            .parseZone(row.reservation_date_from)
            .locale(locale)
            .format('lll'),
        id: 'participation',
      },
      {
        Header: t('Customer'),
        accessor: (row) =>
          row.reservation_guest_given_name && row.reservation_guest_family_name
            ? getGuestName(
                row.reservation_guest_given_name,
                row.reservation_guest_family_name
              )
            : '',
        id: 'customer',
      },
      {
        Header: t('Product'),
        Cell: (cellInfo: { original: MediaDownloadOrder }) => {
          return getDisplayProductName(
            productsSummaries.find((p) => p.id == cellInfo.original.product_id)
          );
        },
        id: 'productName',
      },
      {
        Header: t('Items'),
        id: 'items',
        Cell: (cellInfo: { original: MediaDownloadOrder }) => {
          const itemDescriptions: string[] = [];
          if ((cellInfo.original.media_items ?? []).length > 0) {
            itemDescriptions.push(
              t('Photo/Video x {{itemCount}}', {
                itemCount: (cellInfo.original.media_items ?? []).length,
              })
            );
          }
          if ((cellInfo.original.media_sets ?? []).length > 0) {
            itemDescriptions.push(
              t('Photo/Video Package x {{itemCount}}', {
                itemCount: (cellInfo.original.media_sets ?? []).length,
              })
            );
          }

          return (
            <ul>
              {itemDescriptions.map((description) => (
                <li key={description}>{description}</li>
              ))}
            </ul>
          );
        },
      },
      {
        Header: t('Gross'),
        accessor: (row) =>
          row?.billing_info?.amount_gross
            ? formattedCurrencyAmount(row.billing_info.amount_gross)
            : '',
        id: 'amountGross',
      },
    ];
  };
