import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE,
  FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST,
  FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { MediaDownloadOrder } from 'shared/models/swagger';

export const mediaDownloadOrdersSelector = (state: ReduxState) =>
  state.mediaDownloadOrders.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE:
      return action.error;

    case FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST:
      return true;

    case FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE:
    case FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: MediaDownloadOrder[] = [],
  action: any
): MediaDownloadOrder[] => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS: {
      return [...action.response.media_download_orders];
    }

    default:
      return state;
  }
};

export const mediaDownloadOrders = combineReducers({
  error,
  loading,
  all,
});
