import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_NEWSLETTERS_SUCCESS,
  FETCH_NEWSLETTERS_FAILURE,
  FETCH_NEWSLETTERS_REQUEST,
  FETCH_NEWSLETTER_SUCCESS,
  FETCH_NEWSLETTER_FAILURE,
  FETCH_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_FAILURE,
  DELETE_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_SUCCESS,
  CREATE_NEWSLETTER_REQUEST,
  UPDATE_NEWSLETTER_FAILURE,
  UPDATE_NEWSLETTER_SUCCESS,
  UPDATE_NEWSLETTER_REQUEST,
  CREATE_NEWSLETTER_FAILURE,
  CREATE_NEWSLETTER_SUCCESS,
  FETCH_NEWSLETTERS_CSV_REQUEST,
  FETCH_NEWSLETTERS_CSV_FAILURE,
  FETCH_NEWSLETTERS_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  DownloadNewslettersCSVRequest,
  NewsletterParams,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchNewslettersRequest = () => ({
  type: FETCH_NEWSLETTERS_REQUEST,
});

const fetchNewslettersSuccess = (response: any) => ({
  type: FETCH_NEWSLETTERS_SUCCESS,
  response,
});

const fetchNewslettersFailure = (error: any) => ({
  type: FETCH_NEWSLETTERS_FAILURE,
  error,
});

export const fetchNewsletters =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchNewslettersRequest());
    axios
      .get('/api/newsletters', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchNewslettersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchNewslettersFailure('canceled'));
        else dispatch(fetchNewslettersFailure(error));
      });
  };

const fetchNewsletterRequest = () => ({
  type: FETCH_NEWSLETTER_REQUEST,
});

const fetchNewsletterSuccess = (response: any) => ({
  type: FETCH_NEWSLETTER_SUCCESS,
  response,
});

const fetchNewsletterFailure = (error: any) => ({
  type: FETCH_NEWSLETTER_FAILURE,
  error,
});

export const fetchNewsletter =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchNewsletterRequest());
    axios
      .get(`/api/newsletters/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchNewsletterSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchNewsletterFailure('canceled'));
        else dispatch(fetchNewsletterFailure(error));
      });
  };

const deleteNewsletterRequest = (id: string) => ({
  type: DELETE_NEWSLETTER_REQUEST,
  id,
});

const deleteNewsletterSuccess = (response: any, id: string) => ({
  type: DELETE_NEWSLETTER_SUCCESS,
  response,
  id,
});

const deleteNewsletterFailure = (error: any) => ({
  type: DELETE_NEWSLETTER_FAILURE,
  error,
});

export const deleteNewsletter =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteNewsletterRequest(id));
    fetch(`/api/newsletters/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteNewsletterSuccess(result, id)))
      .catch((error) => dispatch(deleteNewsletterFailure(error)));
  };

const createNewsletterRequest = (newNewsletter: NewsletterParams) => ({
  type: CREATE_NEWSLETTER_REQUEST,
  newNewsletter,
});

const createNewsletterSuccess = (response: any) => ({
  type: CREATE_NEWSLETTER_SUCCESS,
  response,
});

const createNewsletterFailure = (error: any) => ({
  type: CREATE_NEWSLETTER_FAILURE,
  error,
});

export const createNewsletter =
  (newNewsletter: NewsletterParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createNewsletterRequest(newNewsletter));
    return axios
      .post('/api/newsletters', newNewsletter, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createNewsletterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createNewsletterFailure(err.message));
      });
  };

const updateNewsletterRequest = (id: string, patch: NewsletterParams) => ({
  type: UPDATE_NEWSLETTER_REQUEST,
  id,
  patch,
});

const updateNewsletterSuccess = (response: any) => ({
  type: UPDATE_NEWSLETTER_SUCCESS,
  response,
});

const updateNewsletterFailure = (error: any) => ({
  type: UPDATE_NEWSLETTER_FAILURE,
  error,
});

export const updateNewsletter =
  (id: string, patch: NewsletterParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateNewsletterRequest(id, patch));
    return axios
      .patch(`/api/newsletters/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateNewsletterSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateNewsletterFailure(err.message));
      });
  };

const fetchNewslettersCSVRequest = () => ({
  type: FETCH_NEWSLETTERS_CSV_REQUEST,
});

const fetchNewslettersCSVSuccess = () => ({
  type: FETCH_NEWSLETTERS_CSV_SUCCESS,
});

const fetchNewslettersCSVFailure = (error: string) => ({
  type: FETCH_NEWSLETTERS_CSV_FAILURE,
  error,
});

export const fetchNewslettersCSV =
  (req: DownloadNewslettersCSVRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchNewslettersCSVRequest());
    axios
      .get(`/api/newsletters/csv`, {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'newsletters.csv');
        dispatch(fetchNewslettersCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchNewslettersCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
