import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useRef, useState, useEffect } from 'react';

import { MemoModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/MemoModal';
import { fetchMemos } from 'client/actions/memos';
import { memosSelector } from 'client/reducers/memos';
import { getResourceManagerAssignedResources } from 'client/libraries/util/resourceManager';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { ManifestTableHeaderCell } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestTableHeaderCell';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import tableStyles from 'client/components/v3/Table/Table.module.css';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { Column, calcWidthToPx } from 'client/pages/v3/Manifest/util';

type Props = {
  items: Record<string, any>[];
  columns: Column[];
  startTime?: string;
  totalPax?: number;
  open?: boolean;
  capacity?: number;
  checkedInGuestCount: number | null;
  memoKey?: string | null;
  useCollapsible?: boolean;
};

export const ManifestCustomTable = ({
  items,
  columns,
  startTime,
  totalPax,
  open,
  capacity,
  checkedInGuestCount,
  memoKey,
  useCollapsible = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memos = useSelector(memosSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const memo = memos.find((memo) => memo.key === memoKey);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(open || false);
  const [showMemoModal, setShowMemoModal] = useState<boolean>(false);

  useEffect(() => {
    if (memoKey) {
      dispatch(fetchMemos(memoKey));
    }
  }, [memoKey]);

  const scrollButtonClickHandler = (direction: any) => {
    if (tableRef?.current?.scrollLeft != null) {
      if (direction === 'next') {
        tableRef.current.scrollLeft += 100;
      } else {
        tableRef.current.scrollLeft -= 100;
      }
    }
  };

  const assignedResources = getResourceManagerAssignedResources(
    activeUserOrganization,
    items as any
  );

  const [showColumns, setShowColumns] = useState(
    [...Array(items.length)].map(() => false)
  );

  return (
    <>
      {items.length === 0 && (
        <div
          className={styles['p-manifestDaily__noData']}
          style={{ marginTop: 0 }}
        >
          <div>
            {t(
              'There is no reservation that matches the conditions. Please change participation date or Product/Group.'
            )}
          </div>
        </div>
      )}
      {items.length !== 0 && (
        <div className={styles['p-manifests__normal']}>
          <div
            className={styles['p-manifests__normal__header']}
            style={{ padding: isOpen ? undefined : '24px' }}
          >
            <div>
              <div className={styles['p-manifests__normal__header__left']}>
                {startTime && (
                  <div>
                    <p>
                      {t('Start time: {{startTime}}', {
                        startTime: startTime,
                      })}
                    </p>
                  </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="c-icon-outline-users-users-02"></i>
                  {capacity
                    ? t('Total Pax: {{paxCount}} / {{capacity}}', {
                        paxCount: totalPax,
                        capacity: capacity,
                      })
                    : t('Total Pax: {{paxCount}}', {
                        paxCount: totalPax,
                      })}{' '}
                  {checkedInGuestCount != null &&
                    t('({{checkedInGuestCount}} / {{paxCount}} checked-in)', {
                      checkedInGuestCount,
                      paxCount: totalPax,
                    })}
                </div>
                {assignedResources !== null &&
                  (assignedResources ?? []).length !== 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="c-icon-outline-general-tool-02"></i>
                      {(assignedResources ?? [])
                        .map((pair) => {
                          return `${pair.resourceKey} x ${pair.quantity}`;
                        })
                        .join(', ')}
                    </div>
                  )}
              </div>
              <div className={styles['p-manifests__normal__header__right']}>
                {useCollapsible && (
                  <Button
                    text={isOpen ? t('Hide') : t('Show')}
                    size="md"
                    color="tertiarygray"
                    iconBeforeText={
                      isOpen ? (
                        <i className="c-icon-outline-general-minus"></i>
                      ) : (
                        <i className="c-icon-solid-general-plus"></i>
                      )
                    }
                    onClick={() => setIsOpen(!isOpen)}
                  />
                )}
              </div>
            </div>
            {memoKey && (
              <div>
                <div className={styles['p-manifests__normal__header__memo']}>
                  <div>{t('Memo')}:</div>
                  <div>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'MANIFEST.DAILY'
                    ) && (
                      <Button
                        text={t('Edit')}
                        size="sm"
                        color="white"
                        iconBeforeText={
                          <i className="c-icon-outline-general-edit-05"></i>
                        }
                        onClick={() => setShowMemoModal(true)}
                      />
                    )}
                  </div>
                  <div>{memo?.content}</div>
                </div>
              </div>
            )}
          </div>

          <div
            className={clsx(
              styles['p-manifestsTable2'],
              useCollapsible && !isOpen && styles['hidden']
            )}
          >
            <div className={tableStyles['c-tableFrame']}>
              <div
                className={clsx(
                  tableStyles['c-overflowRef'],
                  tableStyles['arrow']
                )}
                ref={tableRef}
              >
                <div className={tableStyles['c-table']}>
                  <div
                    className={clsx(
                      tableStyles['c-table'],
                      tableStyles['thead'],
                      tableStyles['sticky-top']
                    )}
                  >
                    <table className={tableStyles['c-table__body']}>
                      <thead>
                        <tr>
                          {columns.map((column, idx) => {
                            const width = calcWidthToPx(column.width);
                            return (
                              <th
                                className={clsx(
                                  idx === 0 && tableStyles['sticky-top'],
                                  idx === 0 && tableStyles['sticky-left']
                                )}
                                style={{ width: `${width}px` }}
                                key={idx}
                              >
                                <ManifestTableHeaderCell text={column.Header} />
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className={clsx(
                      tableStyles['c-table'],
                      tableStyles['tbody']
                    )}
                  >
                    <table className={tableStyles['c-table__body']}>
                      <tbody>
                        {_.flatten(
                          items.map((item, ridx) => {
                            const rows = [];

                            const subRowCount =
                              _.max(
                                columns.map(
                                  (col) =>
                                    (col.CellArray &&
                                      col.CellArray(item)?.length) ||
                                    1
                                )
                              ) ?? 1;

                            for (
                              let subRowIdx = 0;
                              subRowIdx < subRowCount;
                              subRowIdx++
                            ) {
                              const row = (
                                <tr key={`${ridx}-${subRowIdx}`}>
                                  {columns.map((column, idx) => {
                                    if (subRowIdx > 0 && !column.CellArray) {
                                      return null;
                                    }

                                    const rowSpan = column.CellArray
                                      ? 1
                                      : subRowCount;
                                    const width = calcWidthToPx(column.width);
                                    let value: any;
                                    let displayValue: any;

                                    if (typeof column.accessor === 'string') {
                                      value = item[column.accessor];
                                    } else if (
                                      typeof column.accessor === 'function'
                                    ) {
                                      value = column.accessor(item);
                                    }

                                    if (column.CellArray) {
                                      const cellArray = column.CellArray(item);
                                      displayValue =
                                        subRowIdx < cellArray.length
                                          ? cellArray[subRowIdx]
                                          : '';
                                    } else if (column.Cell) {
                                      const cell = {
                                        value: value,
                                        original: item,
                                      };
                                      displayValue = column.Cell(cell);
                                    } else {
                                      displayValue = value;
                                    }

                                    return (
                                      <td
                                        key={idx}
                                        className={clsx(
                                          idx === 0 &&
                                            tableStyles['c-table__spHeader'],
                                          idx === 0 &&
                                            tableStyles['sticky-left'],
                                          6 < idx
                                            ? showColumns[ridx]
                                              ? ''
                                              : tableStyles['hidden']
                                            : ''
                                        )}
                                        style={{
                                          width: `${width}px`,
                                        }}
                                        data-text={column.Header}
                                        rowSpan={rowSpan}
                                      >
                                        {displayValue}
                                      </td>
                                    );
                                  })}
                                  {subRowIdx === subRowCount - 1 && (
                                    <td
                                      className={clsx(
                                        tableStyles['c-table__detail'],
                                        tableStyles['c-table__sp']
                                      )}
                                    >
                                      <p className={baseStyles['u-pcHidden']}>
                                        {/* TODO: put product name here */}
                                      </p>
                                      <Button
                                        text={
                                          showColumns[ridx]
                                            ? t('Close')
                                            : t('See more')
                                        }
                                        onClick={() => {
                                          const newData = [...showColumns];
                                          newData[ridx] = !newData[ridx];
                                          setShowColumns(newData);
                                        }}
                                        color="tertiarycolor"
                                        iconAfterText={
                                          showColumns[ridx] ? (
                                            <i className="c-icon-solid-arrows-chevron-up"></i>
                                          ) : (
                                            <i className="c-icon-solid-arrows-chevron-down"></i>
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                              rows.push(row);
                            }

                            return rows;
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Do not show scroll button when there is no data */}
              {items.length > 0 && (
                <>
                  <div
                    className={clsx(
                      tableStyles['c-tableBtn'],
                      tableStyles['left']
                    )}
                    onClick={() => {
                      scrollButtonClickHandler('prev');
                    }}
                  >
                    <a>
                      <i className="c-icon-solid-arrows-chevron-left"></i>
                    </a>
                  </div>
                  <div
                    className={clsx(
                      tableStyles['c-tableBtn'],
                      tableStyles['right']
                    )}
                    onClick={() => {
                      scrollButtonClickHandler('next');
                    }}
                  >
                    <a>
                      <i className="c-icon-solid-arrows-chevron-left"></i>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* TODO: implement 'see more' functionality later */}
          {/* <div className={styles['p-manifests__normal__actions']}></div> */}
        </div>
      )}

      <MemoModal
        memoKey={memoKey || ''}
        open={showMemoModal}
        onClose={() => {
          setShowMemoModal(false);
        }}
      />
    </>
  );
};
