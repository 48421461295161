/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { getValidators } from 'shared/libraries/validate/validator';

import styles from './Details.module.css';

export const Details: React.FC = () => {
  const { t } = useTranslation();
  const { required } = getValidators(t);

  return (
    <section className={styles.details}>
      <h2 className={styles.sectionTitle}>Details</h2>
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name="name" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Name')}
                onChange={input.onChange}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name="description" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextArea
                label={t('Description')}
                onChange={input.onChange}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
    </section>
  );
};
