import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE,
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST,
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  MediaDownloadSalesPage,
  CreateMediaDownloadSalesPageResponse,
  ListMediaDownloadSalesPagesResponse,
  UpdateMediaDownloadSalesPageResponse,
} from 'shared/models/swagger';

export const mediaDownloadSalesPagesSelector = (state: ReduxState) =>
  state.mediaDownloadSalesPages.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE:
    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
      return action.error;

    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS:
    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST:
    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST:
    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST:
    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST:
      return true;

    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE:
    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE:
    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS:
    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: MediaDownloadSalesPage[] = [],
  action: any
): MediaDownloadSalesPage[] => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS: {
      const response = action.response as ListMediaDownloadSalesPagesResponse;
      const newIds = response.media_download_sales_pages?.map((m) => m.id);
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...response.media_download_sales_pages,
      ];
    }

    case CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS: {
      const response = action.response as CreateMediaDownloadSalesPageResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS: {
      const response = action.response as UpdateMediaDownloadSalesPageResponse;
      return state.map((i) => (i.id !== response.id ? i : response));
    }

    case DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const mediaDownloadSalesPages = combineReducers({
  error,
  loading,
  all,
});
