import type { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

export type FormValues = {
  confirm: MessageContent;
  checkin: MessageContent;
};

type MessageContent = {
  header: string;
  footer: string;
  items?: {
    title?: string;
    field?: Swagger.LineMessageItemField;
  }[];
};

export const getInitialValues = (
  product: Swagger.Product | null
): FormValues => {
  return {
    confirm: {
      header: product?.line_settings?.message_content?.confirm_header || '',
      footer: product?.line_settings?.message_content?.confirm_footer || '',
      items: product?.line_settings?.message_content?.confirm_items || [],
    },
    checkin: {
      header: product?.line_settings?.message_content?.checkin_header || '',
      footer: product?.line_settings?.message_content?.checkin_footer || '',
      items: product?.line_settings?.message_content?.checkin_items || [],
    },
  };
};

export const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    line_settings: {
      message_content: {
        confirm_header: values?.confirm?.header,
        confirm_footer: values?.confirm?.footer,
        checkin_header: values?.checkin?.header,
        checkin_footer: values?.checkin?.footer,
        confirm_items: values?.confirm?.items || [],
        checkin_items: values?.checkin?.items || [],
      },
    },
  };
};

export const getItemFieldOptions = (t: TranslateFuncType) => {
  return [
    {
      text: t('Confirmation Number'),
      value: 'LINE_MESSAGE_ITEM_FIELD_SUPPLIER_REFERENCE',
    },
    {
      text: t('Participation'),
      value: 'LINE_MESSAGE_ITEM_FIELD_PARTICIPATES_AT',
    },
    {
      text: t('Guest'),
      value: 'LINE_MESSAGE_ITEM_FIELD_GUEST_DESCRIPTION',
    },
    {
      text: t('E-ticket URL'),
      value: 'LINE_MESSAGE_ITEM_FIELD_E_TICKET_URL',
    },
    {
      text: t('Product Name'),
      value: 'LINE_MESSAGE_ITEM_FIELD_PRODUCT_NAME',
    },
    {
      text: t('Price'),
      value: 'LINE_MESSAGE_ITEM_FIELD_GROSS',
    },
    {
      text: t('Redemption Date'),
      value: 'LINE_MESSAGE_ITEM_FIELD_CHECKIN_DATE_TIME',
    },
  ];
};
