import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';

import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { NumberInput, Select, Button } from 'client/components/Form';
import { checkinReservation } from 'client/actions/reservations';
import { Message } from 'client/components/Message/Message';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type {
  Product,
  Reservation,
  ProductSummary,
  ReservationCheckinParams,
} from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';

interface Props {
  open: boolean;
  onClose: () => void;
  reservation: Reservation | ManifestReservationShape | undefined;
  product: Product | ProductSummary | undefined;
}

interface GuestTypeCount {
  guestTypeKey: string;
  guestTypeTitle: string;
  count: number;
}

interface FormValues {
  guestTypeCounts?: GuestTypeCount[];
  redemptionCount?: number;
  presetRedemptionCountKey?: string;
}

const getConsumedGuestCount = (
  stubKey: string,
  reservation: Reservation | ManifestReservationShape
): { [key: string]: number } => {
  const consumedGuestCount = (reservation.checkin_info?.checkin_records ?? [])
    .filter((record) => !stubKey || record.stub_key === stubKey)
    .reduce((acc, record) => {
      for (const guestTypeCount of record.guest_type_counts ?? []) {
        if (!guestTypeCount.guest_type_key) {
          continue;
        }
        acc[guestTypeCount.guest_type_key] =
          (acc[guestTypeCount.guest_type_key] ?? 0) +
          (guestTypeCount.count ?? 0);
      }
      return acc;
    }, {} as { [key: string]: number });

  return consumedGuestCount;
};

const getMaxGuestCount = (
  stubKey: string,
  reservation: Reservation | ManifestReservationShape
): { [key: string]: number } => {
  const maxGuestCount = reservation.guests.reduce((acc, guest) => {
    if (!guest.guest_type_key) {
      return acc;
    }
    acc[guest.guest_type_key] = (acc[guest.guest_type_key] ?? 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  const consumedGuestCount = getConsumedGuestCount(stubKey, reservation);

  for (const guestTypeKey of Object.keys(maxGuestCount)) {
    maxGuestCount[guestTypeKey] -= consumedGuestCount[guestTypeKey] ?? 0;
  }

  return maxGuestCount;
};

const getInitialValues = (
  stubKey: string,
  reservation: Reservation | ManifestReservationShape
): FormValues => {
  const maxGuestCount = getMaxGuestCount(stubKey, reservation);

  const guestTypeCounts: GuestTypeCount[] = [];
  for (const guest of reservation.guests) {
    if (
      !guestTypeCounts.find(
        (count) => count.guestTypeKey === guest.guest_type_key
      )
    ) {
      guestTypeCounts.push({
        guestTypeKey: guest.guest_type_key,
        guestTypeTitle: guest.guest_type_title || guest.guest_type_key,
        count: maxGuestCount[guest.guest_type_key] ?? 0,
      });
    }
  }

  return {
    guestTypeCounts,
    redemptionCount: 0,
  };
};

const convertFormValuesToSwagger = (
  stubKey: string,
  values: FormValues,
  product: Product | ProductSummary,
  reservation: Reservation | ManifestReservationShape
): ReservationCheckinParams => {
  const presetRedemptionCount =
    product?.qr_checkin_settings?.preset_redemption_counts?.find(
      (preset) => preset.key === values.presetRedemptionCountKey
    );

  return {
    stub_key: stubKey,
    ...(product?.qr_checkin_settings
      ?.should_count_guests_for_checkin_with_guest_type
      ? {
          guest_count: values.guestTypeCounts?.reduce(
            (acc, guestTypeCount) => acc + guestTypeCount.count,
            0
          ),
          guest_type_counts: values.guestTypeCounts?.map((guestTypeCount) => ({
            guest_type_key: guestTypeCount.guestTypeKey,
            count: guestTypeCount.count,
          })),
        }
      : null),
    ...(product?.qr_checkin_settings?.should_use_redemption_count
      ? {
          guest_count: reservation.guests.length,
          redemption_count: presetRedemptionCount
            ? presetRedemptionCount.redemption_count
            : values.redemptionCount,
          ...(presetRedemptionCount
            ? {
                preset_redemption_count_key: presetRedemptionCount.key,
              }
            : null),
        }
      : null),
    key: uuid(),
  };
};

const focusOnError: any = createDecorator();

export const RedeemETicketModal = ({
  open,
  onClose,
  reservation,
  product,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (!reservation || !product) {
    return null;
  }
  const [success, setSuccess] = React.useState<boolean>(false);
  const [stubKey, setStubKey] = React.useState<string>('');

  React.useEffect(() => {
    if (product?.qr_checkin_settings?.stubs?.length) {
      setStubKey(product.qr_checkin_settings.stubs[0].key || '');
    }
  }, [product]);

  const stubOptions = (product.qr_checkin_settings?.stubs || []).map(
    (stub) => ({
      text: stub.text || stub.key || '',
      value: stub.key || '',
    })
  );

  const presetRedemptionCountOptions = (
    product?.qr_checkin_settings?.preset_redemption_counts || []
  ).map((preset) => ({
    text: `${preset.text} (${preset.redemption_count})`,
    value: preset.key || '',
  }));

  const usePresetRedemptionCount = presetRedemptionCountOptions.length > 0;

  const maxGuestCount = getMaxGuestCount(stubKey, reservation);

  const availableRedemptionCount = React.useMemo(() => {
    const maxRedemptionCount = stubKey
      ? product?.qr_checkin_settings?.stubs?.find(
          (stub) => stub.key === stubKey
        )?.max_redemption_count ?? 0
      : product?.qr_checkin_settings?.max_redemption_count ?? 0;

    const existingRedemptionCount = (
      reservation.checkin_info?.checkin_records ?? []
    ).reduce((acc, record) => {
      if (!stubKey || record.stub_key === stubKey) {
        acc += record.redemption_count ?? 0;
      }
      return acc;
    }, 0);

    return maxRedemptionCount - existingRedemptionCount;
  }, [stubKey, reservation, product]);

  return (
    <Modal title={t('Redeem Ticket')} open={open} onClose={onClose} insertRoot>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => {
          try {
            await dispatch(
              checkinReservation(
                reservation.id,
                convertFormValuesToSwagger(
                  stubKey,
                  values,
                  product,
                  reservation
                )
              )
            );
            setSuccess(true);
          } catch (e) {
            console.error(e);
            setSuccess(false);
          }
        }}
        initialValues={getInitialValues(stubKey, reservation)}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, error, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {stubOptions.length > 1 && (
                <Select
                  value={stubKey}
                  onChange={(_, { value }) => setStubKey(value as string)}
                  options={stubOptions}
                />
              )}
              {/* checkin with guest type */}
              {product?.qr_checkin_settings
                ?.should_count_guests_for_checkin_with_guest_type && (
                <FieldArray name="guestTypeCounts">
                  {({ fields }) => (
                    <div>
                      {fields.map((name, idx) => (
                        <div key={name}>
                          <Field name={`${name}.guestTypeTitle`}>
                            {({ input }) => (
                              <div>
                                <label>{input.value}</label>
                              </div>
                            )}
                          </Field>
                          <Field name={`${name}.count`}>
                            {({ input, meta }) => (
                              <NumberInput
                                value={input.value}
                                onChange={(_, { value }) =>
                                  input.onChange(value)
                                }
                                error={meta.touched && meta.error}
                                min={0}
                                max={
                                  maxGuestCount[
                                    values.guestTypeCounts?.[idx]
                                      .guestTypeKey ?? ''
                                  ]
                                }
                              />
                            )}
                          </Field>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray>
              )}
              {/* checkin without guest type */}
              {product?.qr_checkin_settings?.should_use_redemption_count && (
                <>
                  <Box mb={2} mt={2}>
                    {t('Enter number of tickets to redeem')}
                  </Box>
                  {usePresetRedemptionCount ? (
                    <Field name="presetRedemptionCountKey">
                      {({ input }) => (
                        <Select
                          value={input.value}
                          onChange={(_, { value }) => input.onChange(value)}
                          options={presetRedemptionCountOptions}
                        />
                      )}
                    </Field>
                  ) : (
                    <Field name="redemptionCount">
                      {({ input, meta }) => (
                        <NumberInput
                          value={input.value}
                          onChange={(_, { value }) => {
                            if (value < 0) {
                              return;
                            }
                            if (value > availableRedemptionCount) {
                              return;
                            }
                            input.onChange(value);
                          }}
                          error={meta.touched && meta.error}
                          min={0}
                        />
                      )}
                    </Field>
                  )}
                </>
              )}
              {success && <Message success header={t('Save Successful')} />}
              {error && <Message error header={t('Save Failed')} />}
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  onClose();
                }}
              >
                {t('No')}
              </Button.Cancel>
              <Button
                loading={submitting}
                style="blue"
                size="middle"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
