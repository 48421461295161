import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { updateReservation } from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  onClose: () => void;
};

export const ReservationMainInformationUpdateModal = ({
  reservation,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [patch, setPatch] = useState<Partial<Reservation>>({
    supplier_reference: reservation.supplier_reference,
    supplier_notes: reservation.supplier_notes ?? '',
  });

  useEffect(() => {
    setPatch((prevPatch) => ({
      ...prevPatch,
      supplier_notes: reservation.supplier_notes ?? '',
    }));
  }, [reservation.supplier_notes]);

  return (
    <Modal
      title={t('Update')}
      open={true}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button color="white" text={t('Cancel')} onClick={onClose} />
          <Button
            text={t('Save')}
            onClick={() => {
              dispatch(updateReservation(reservation.id, patch));
              onClose();
            }}
          />
        </>
      }
    >
      <div className={styles['p-reservationNum']}>
        <div className={styles['p-reservationNum__item']}>
          <p className={styles['p-reservationNum__item__ttl']}>
            {t('Confirmation Number')}
          </p>
          <TextField
            value={patch.supplier_reference || ''}
            onChange={(value) => {
              setPatch((prevPatch) => ({
                ...prevPatch,
                supplier_reference: value,
              }));
            }}
          />
        </div>
        <div className={styles['p-reservationNum__item']}>
          <p className={styles['p-reservationNum__item__ttl']}>
            {t('Replies')}
          </p>
          <TextArea
            value={patch.supplier_notes || ''}
            onChange={(e: any) => {
              setPatch((prevPatch) => ({
                ...prevPatch,
                supplier_notes: e.target.value,
              }));
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
