import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  SUBDOMAIN_IS_AVAILABLE_REQUEST,
  SUBDOMAIN_IS_AVAILABLE_SUCCESS,
} from 'client/constants/ActionTypes';

const subdomainIsAvailableRequest = (subdomain: string) => ({
  type: SUBDOMAIN_IS_AVAILABLE_REQUEST,
  subdomain,
});

const subdomainIsAvailableSuccess = (isAvailable: boolean) => ({
  type: SUBDOMAIN_IS_AVAILABLE_SUCCESS,
  isAvailable,
});

type Dispatch = ThunkDispatch<any, any, any>;

export const subdomainIsAvailable =
  (subdomain: string) => (dispatch: Dispatch) => {
    dispatch(subdomainIsAvailableRequest(subdomain));
    return axios
      .get(`/noauthapi/subdomains/${subdomain}/isavailable`)
      .then(() => {
        dispatch(subdomainIsAvailableSuccess(true));
      })
      .catch(() => {
        dispatch(subdomainIsAvailableSuccess(false));
      });
  };
