import { ReservationDashBoardHeader } from 'client/pages/v3/Reservation/ReservationDashBoard/ReservationDashBoardHeader';
import { ReservationDashBoardBody } from 'client/pages/v3/Reservation/ReservationDashBoard/ReservationDashBoardBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ReservationDashBoard = () => {
  return (
    <V3Page>
      <ReservationDashBoardHeader />
      <ReservationDashBoardBody />
    </V3Page>
  );
};
