import * as React from 'react';

import { EquipmentInstance } from 'shared/models/swagger';
import { PortalTooltip } from 'client/components/PortalTooltip/PortalTooltip';
import { useBounds } from 'client/hooks/useBounds';

interface Props {
  equpmentInstance?: EquipmentInstance;
}

export const SeatMapHeaderInternalMemo = ({ equpmentInstance }: Props) => {
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showTooltip &&
        target instanceof Node &&
        !divRef?.current?.contains(target)
      ) {
        setShowTooltip(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showTooltip]);
  return (
    <>
      {equpmentInstance?.internal_memo?.value && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            ...((equpmentInstance?.internal_memo?.value || '').length > 15
              ? { cursor: 'pointer' }
              : {}),
            marginLeft: '5px',
            backgroundColor: '#f3f3f3',
            borderRadius: '5px',
            padding: '5px',
          }}
          onClick={() => {
            if ((equpmentInstance?.internal_memo?.value || '').length > 15) {
              showTooltip ? setShowTooltip(false) : setShowTooltip(true);
            }
          }}
          ref={divRef}
        >
          {showTooltip && (
            <PortalTooltip
              text={equpmentInstance.internal_memo.value}
              style={{
                left: divBounds.left + 40,
                top: divBounds.top - 10,
                width: '200px',
                padding: '5px',
                whiteSpace: 'pre-wrap',
              }}
            />
          )}
          {equpmentInstance.internal_memo.value.length > 15 ? (
            <p style={{ color: 'blue', marginLeft: '5px' }}>
              {equpmentInstance.internal_memo.value.slice(0, 15)}
              ...
            </p>
          ) : (
            <p style={{ color: 'blue', marginLeft: '5px' }}>
              {equpmentInstance.internal_memo.value}
            </p>
          )}
        </div>
      )}
    </>
  );
};
