import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { ReduxState } from 'client/reducers';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { currency } from 'shared/libraries/currency';
import { histogram } from 'client/libraries/util/util';
import { formattedAmount } from 'client/libraries/util/formattedAmount';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type {
  Guest,
  ManifestProductGroup,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { ReservationSummaryColumn } from 'client/libraries/util/getReservationSummaryTableColumns';

type Props = {
  reservations: ManifestReservationShape[];
  supplierCurrency: string;
  visibleColumns: ReservationSummaryColumn[];
};
type StartTimeLine = {
  reservationCount: number;
  pax: number;
  guests: Guest[];
  amountGross: number;
  amountPOB: number;
  amountPIF: number;
};
type ProductLine = {
  productId: string;
  productName: string;
  startTimeLines: Record<string, StartTimeLine>;
  reservationId: string;
};

export const ReservationSummaryTable = ({
  reservations,
  supplierCurrency,
  visibleColumns,
}: Props) => {
  const { t } = useTranslation();
  const [isActives, setIsActives] = useState<Record<string, boolean>>({});
  const productGroups = useSelector(manifestProductGroupsSelector);
  const summaries = useSelector(summariesSortedByBookmarkedSelector);
  const productGroupsForDisplay = productGroups.filter((productGroup) => {
    return productGroup.used_for_grouping;
  });
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const rootResourceProductIds = productGroupsForDisplay.find(
    (group: ManifestProductGroup) =>
      group.key == lastManifestDisplaySettings.rootResource.id
  )?.product_ids;

  const productLines: ProductLine[] = [];

  for (let i = 0; i < reservations.length; i++) {
    const amountGross = reservations[i].amount_gross;

    if (
      amountGross &&
      amountGross.length > 3 &&
      !amountGross.startsWith(supplierCurrency)
    ) {
      continue;
    }

    let productLine = productLines.find(
      (productLine) =>
        productLine.productId === reservations[i].product_id &&
        !reservations[i].is_free_format_reservation
    );

    if (!productLine) {
      productLine = {
        productId: reservations[i].product_id,
        productName:
          summaries.find((p) => p.id == reservations[i].product_id)
            ?.internal_product_name ?? reservations[i].product_name,
        startTimeLines: {},
        reservationId: reservations[i].id,
      };
      productLines.push(productLine);
    }

    const startTime = reservations[i].participates_at.format('HH:mm');
    let startTimeLine = productLine.startTimeLines[startTime];

    if (!startTimeLine) {
      startTimeLine = {
        reservationCount: 0,
        pax: 0,
        guests: [],
        amountGross: 0,
        amountPOB: 0,
        amountPIF: 0,
      };
      productLine.startTimeLines[startTime] = startTimeLine;
    }

    const grossValue = currency(reservations[i].amount_gross).value;
    const pobValue = currency(reservations[i].amount_pob).value;
    const pifValue = grossValue - pobValue;
    startTimeLine.reservationCount += 1;
    startTimeLine.pax += reservations[i].guests.length;
    startTimeLine.guests = [...startTimeLine.guests, ...reservations[i].guests];
    startTimeLine.amountGross += grossValue;
    startTimeLine.amountPOB += pobValue;
    startTimeLine.amountPIF += pifValue;
  }

  const getUnits = (guests: Guest[]): string => {
    const reservationGuestCounts = histogram(
      guests,
      (g) => g.guest_type_title || g.guest_type_key
    );
    return Object.keys(reservationGuestCounts)
      .map(
        (guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`
      )
      .join(', ');
  };

  const sortSummaries = rootResourceProductIds
    ? rootResourceProductIds.map((p) => summaries.find((elm) => elm.id == p))
    : summaries;
  const sortedProductLines = productLines.sort((a, b) => {
    const tmp_a = sortSummaries.find((p) => p?.id == a.productId);
    const tmp_b = sortSummaries.find((p) => p?.id == b.productId);

    if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) <
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return -1;
    } else if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) >
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return 1;
    }

    return 0;
  });
  let totalReservationCount = 0;
  let totalPax = 0;
  let totalGuests: Guest[] = [];
  let totalAmountGross = 0;
  let totalAmountPOB = 0;
  let totalAmountPIF = 0;

  return (
    <div>
      <div className={styles['p-manifestsTable']}>
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          style={{ tableLayout: 'fixed' }}
        >
          <tbody>
            <tr>
              {visibleColumns.map(
                (visibleColumn: ReservationSummaryColumn, index) => {
                  return (
                    <th
                      key={index}
                      style={{
                        width: index == 0 ? '200px' : '160px',
                        borderRight: 'none',
                      }}
                    >
                      {visibleColumn.Header}
                    </th>
                  );
                }
              )}
            </tr>
            {sortedProductLines.map((productLine, idx) => {
              let reservationCount = 0;
              let pax = 0;
              let guests: Guest[] = [];
              let amountGross = 0;
              let amountPOB = 0;
              let amountPIF = 0;

              const keys = _.sortBy(
                Object.keys(productLine.startTimeLines),
                (key) => key
              );

              for (let i = 0; i < keys.length; i++) {
                const startTimeLine = productLine.startTimeLines[keys[i]];
                reservationCount += startTimeLine.reservationCount;
                pax += startTimeLine.pax;
                guests = [...guests, ...startTimeLine.guests];
                amountGross += startTimeLine.amountGross;
                amountPOB += startTimeLine.amountPOB;
                amountPIF += startTimeLine.amountPIF;
              }

              totalReservationCount += reservationCount;
              totalPax += pax;
              totalGuests = [...totalGuests, ...guests];
              totalAmountGross += amountGross;
              totalAmountPOB += amountPOB;
              totalAmountPIF += amountPIF;

              const lineKey = productLine.productId
                ? productLine.productId
                : productLine.reservationId;
              return (
                <>
                  <tr
                    key={idx}
                    className={clsx(
                      isActives[lineKey] ? styles['is-active'] : ''
                    )}
                  >
                    {visibleColumns.map(
                      (visibleColumn: ReservationSummaryColumn, index) => {
                        if (index === 0) {
                          return (
                            <td
                              key={index}
                              className={styles['c-tableSmall__spTtl']}
                            >
                              <div className={styles['acBtn']}>
                                <div className={baseStyles['u-spHidden']}>
                                  <Button
                                    color="secondary"
                                    size="icon"
                                    iconAfterText={
                                      isActives[lineKey] ? (
                                        <i className="c-icon-outline-general-minus"></i>
                                      ) : (
                                        <i className="c-icon-outline-general-plus"></i>
                                      )
                                    }
                                    onClick={() => {
                                      const newIsActives = { ...isActives };
                                      newIsActives[lineKey] =
                                        !newIsActives[lineKey];
                                      setIsActives(newIsActives);
                                    }}
                                  />
                                </div>
                                {productLine.productName}
                              </div>
                            </td>
                          );
                        }
                        let cellValue;
                        if (visibleColumn.id === 'num_of_reservations') {
                          cellValue = reservationCount;
                        }
                        if (visibleColumn.id === 'num_of_participants') {
                          cellValue = pax;
                        }
                        if (visibleColumn.id === 'units') {
                          cellValue = getUnits(guests);
                        }
                        if (visibleColumn.id === 'gross') {
                          cellValue = formattedAmount(
                            amountGross,
                            supplierCurrency
                          );
                        }
                        if (visibleColumn.id === 'pif_amount') {
                          cellValue = formattedAmount(
                            amountPIF,
                            supplierCurrency
                          );
                        }
                        if (visibleColumn.id === 'pob_amount') {
                          cellValue = formattedAmount(
                            amountPOB,
                            supplierCurrency
                          );
                        }
                        return <td key={index}>{cellValue}</td>;
                      }
                    )}
                  </tr>
                  {isActives[lineKey] &&
                    keys.map((key, idx) => {
                      const startTimeLine = productLine.startTimeLines[key];
                      return (
                        <tr key={idx}>
                          {visibleColumns.map(
                            (
                              visibleColumn: ReservationSummaryColumn,
                              index
                            ) => {
                              if (index === 0) {
                                return (
                                  <td
                                    key={index}
                                    style={{ paddingLeft: '64px' }}
                                  >
                                    {key}
                                  </td>
                                );
                              }

                              let cellValue;
                              if (visibleColumn.id === 'num_of_reservations') {
                                cellValue = startTimeLine.reservationCount;
                              }
                              if (visibleColumn.id === 'num_of_participants') {
                                cellValue = startTimeLine.pax;
                              }
                              if (visibleColumn.id === 'units') {
                                cellValue = getUnits(startTimeLine.guests);
                              }
                              if (visibleColumn.id === 'gross') {
                                cellValue = formattedAmount(
                                  startTimeLine.amountGross,
                                  supplierCurrency
                                );
                              }
                              if (visibleColumn.id === 'pif_amount') {
                                cellValue = formattedAmount(
                                  startTimeLine.amountPIF,
                                  supplierCurrency
                                );
                              }
                              if (visibleColumn.id === 'pob_amount') {
                                cellValue = formattedAmount(
                                  startTimeLine.amountPOB,
                                  supplierCurrency
                                );
                              }
                              return <td key={index}>{cellValue}</td>;
                            }
                          )}
                        </tr>
                      );
                    })}
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {visibleColumns.map(
                (visibleColumn: ReservationSummaryColumn, index) => {
                  if (index === 0) {
                    return <td key={index}>{t('Total')}</td>;
                  }

                  let cellValue;
                  if (visibleColumn.id === 'num_of_reservations') {
                    cellValue = totalReservationCount;
                  }
                  if (visibleColumn.id === 'num_of_participants') {
                    cellValue = totalPax;
                  }
                  if (visibleColumn.id === 'units') {
                    cellValue = getUnits(totalGuests);
                  }
                  if (visibleColumn.id === 'gross') {
                    cellValue = formattedAmount(
                      totalAmountGross,
                      supplierCurrency
                    );
                  }
                  if (visibleColumn.id === 'pif_amount') {
                    cellValue = formattedAmount(
                      totalAmountPIF,
                      supplierCurrency
                    );
                  }
                  if (visibleColumn.id === 'pob_amount') {
                    cellValue = formattedAmount(
                      totalAmountPOB,
                      supplierCurrency
                    );
                  }
                  return <td key={index}>{cellValue}</td>;
                }
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
