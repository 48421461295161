import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductDetailsSection.module.css';
import { CancellationPolicyTimeline } from 'client/components/CancellationPolicyTimeline/CancellationPolicyTimeline';
import type { ProductShape } from 'client/libraries/util/productShape';
import { BookingDeadlineTimeline } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/TourInformation/BookingDeadlineTimeline/BookingDeadlineTimeline';

type Props = {
  product: ProductShape;
};
export const TourInformation = ({ product }: Props) => {
  const { t } = useTranslation();
  const itemLists: Array<{
    header: string;
    items: string[];
  }> = [
    {
      header: t("What's Included"),
      items: product.inclusions,
    },
    {
      header: t("What's Not Included"),
      items: product.exclusions,
    },
    {
      header: t('Requirements'),
      items: product.requirements,
    },
    {
      header: t('Restrictions'),
      items: product.restrictions,
    },
    {
      header: t('What to Bring'),
      items: product.whatToBring,
    },
    {
      header: t('Additional Info'),
      items: product.otherInfo,
    },
    ...(product.minimumParticipants
      ? [
          {
            header: t('Minimum Participants'),
            items: [`${product.minimumParticipants}`],
          },
        ]
      : []),
  ].filter((i) => i.items.length > 0);
  return (
    <ul className={styles['c-list']}>
      {itemLists.map((itemList, idx) => (
        <li key={idx} className={styles['c-list__item']}>
          <h3 className={styles['c-list__item__title']}>{itemList.header}</h3>
          <ul className={styles['c-list__item__body']}>
            {itemList.items.map((item, itemIdx) => (
              <li key={itemIdx}>{item}</li>
            ))}
          </ul>
        </li>
      ))}
      <li className={styles['c-list__item']}>
        <h3 className={styles['c-list__item__title']}>
          {t('Cancellation Policies')}
        </h3>
        <div className={styles['c-list__item__body']}>
          <CancellationPolicyTimeline
            cancellationPolicies={product.cancellationPolicies}
            currency={product.currency}
          />
        </div>
      </li>
      {product.agentGuestBookingAllowedDaysBefore && (
        <li className={styles['c-list__item']}>
          <h3 className={styles['c-list__item__title']}>
            {t('Booking Start Date')}
          </h3>
          <div className={styles['c-list__item__body']}>
            {t('Accept reservations from {{days}} days before participation', {
              days: product.agentGuestBookingAllowedDaysBefore,
            })}
          </div>
        </li>
      )}
      <li className={styles['c-list__item']}>
        <h3 className={styles['c-list__item__title']}>
          {t('Booking Deadlines')}
        </h3>
        <div className={styles['c-list__item__body']}>
          <BookingDeadlineTimeline
            instantBookingDeadline={product.instantBookingDeadline}
            requestBookingDeadline={product.requestBookingDeadline}
          />
        </div>
      </li>
    </ul>
  );
};
