// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { Dispatch as ReduxDispatch } from 'redux';
import clsx from 'clsx';
import _ from 'lodash';

import { fetchProducts } from 'client/actions/products';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { DeleteProductGroupModal } from 'client/pages/ManifestCustomize/ProductGroupList/DeleteProductGroupModal';
import { EditProductGroupModal } from 'client/pages/ManifestCustomize/ProductGroupList/EditProductGroupModal';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import 'react-table/react-table.css';
import type { ReduxState } from 'client/reducers/index';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import { Loading } from 'client/pages/Loading';
import type { ManifestProductGroup } from 'shared/models/swagger';

type OwnProps = {
  isActive?: boolean,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

const ProductGroupListComponent = ({
  fetchProducts,
  invalidated,
  loading,
  locale,
  products,
  productGroups,
  isActive,
}: Props) => {
  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts, invalidated, locale]);

  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const [editingProductGroup, setEditingProductGroup] =
    React.useState<ManifestProductGroup | null>(null);
  const [productGroupToDelete, setProductGroupToDelete] =
    React.useState<ManifestProductGroup | null>(null);

  const getColumns = () => {
    return [
      {
        Header: '',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
            onClick={() => {
              setEditingProductGroup(cellInfo.original);
              setShowEditModal(true);
            }}
          >
            <img src={editIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: '',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Delete')}
            onClick={() => {
              setProductGroupToDelete(cellInfo.original);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: t('Title'),
        accessor: 'key',
        width: 'long',
      },
      {
        Header: t('Applies To'),
        id: 'view_type_key',
        accessor: (row) => {
          return row.view_type_key || t('ALL_TYPES');
        },
        width: 'long',
      },
      {
        Header: t('Products'),
        accessor: 'product_ids',
        Cell: (cellInfo) => (
          <>
            {(cellInfo.value || [])
              .filter((productId) => {
                return products.find((p) => p.id === productId);
              })
              .map((productId) => {
                const product = products.find((p) => p.id === productId);
                return (
                  <>
                    {getDisplayProductName(product)}
                    <br />
                  </>
                );
              })}
          </>
        ),
      },
      {
        Header: t('Grouping'),
        id: 'used_for_grouping',
        accessor: (row) => {
          return row.used_for_grouping ? t('used') : t('not used');
        },
        width: 'middle',
      },
    ];
  };

  const columns = getColumns();

  return (
    <>
      {loading && <Loading />}
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Edit Manifest Groups')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['small'],
                baseStyles['flex'],
                baseStyles['green']
              )}
              onClick={() => {
                setEditingProductGroup(null);
                setShowEditModal(true);
              }}
            >
              {t('Create New Manifest Group')}
            </a>
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <EditProductGroupModal
            open={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            existingProductGroup={editingProductGroup}
          />

          <DeleteProductGroupModal
            open={showDeleteModal}
            onClose={() => {
              setShowDeleteModal(false);
            }}
            productGroupToDelete={productGroupToDelete}
          />

          <ManifestCustomizeCustomTable
            items={_.sortBy(productGroups, (p) => p.key)}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  loading: state.products.loading,
  locale: state.language.selected.iso,
  invalidated: state.userDataInvalidated,
  products: state.products.summaries,
  productGroups: manifestProductGroupsSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  fetchProducts: () => dispatch(fetchProducts()),
});

export const ProductGroupList = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(ProductGroupListComponent);
