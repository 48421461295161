import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Loader active>{t('Loading...')}</Loader>
    </div>
  );
};
