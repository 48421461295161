import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import type { BusinessLineItem, Transaction } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';

import styles from './SummaryTable.module.css';

type Props = {
  businessLineItem: BusinessLineItem;
  getFormattedTimeFromUTC: (dateTimeUTC: string) => string | null | undefined;
  getInvoiceFareAdjustmentDueByEntities: (
    transaction: Transaction
  ) => Record<string, any>;
};
export const TransactionTable = ({
  businessLineItem,
  getFormattedTimeFromUTC,
  getInvoiceFareAdjustmentDueByEntities,
}: Props) => {
  const { t } = useTranslation();

  const isInvoiceFareAdjustment = (lineItem: BusinessLineItem) => {
    return (
      lineItem && lineItem.business_line_item_type === 'INVOICE_ADJUSTMENT'
    );
  };

  const sortTransactions = (lineItem: BusinessLineItem) => {
    return (
      lineItem &&
      lineItem.transactions &&
      lineItem.transactions.sort((transaction1, transaction2) =>
        moment(transaction1.transaction_date_time_utc).diff(
          moment(transaction2.transaction_date_time_utc)
        )
      )
    );
  };

  if (!businessLineItem) {
    return null;
  }

  const isAdjustment = isInvoiceFareAdjustment(businessLineItem);
  const sortedTransactions = sortTransactions(businessLineItem);

  return (
    <div className={componentStyles['c-table-nowrap']}>
      <table>
        <tbody>
          {isAdjustment ? (
            <tr>
              <th className={styles['center']}>{t('Adjustment Date')}</th>
              <th className={styles['center']}>{t('Notes')}</th>
              <th className={styles['center']}>{t('Due by Agent')}</th>
              <th className={styles['center']}>{t('Due by Supplier')}</th>
            </tr>
          ) : (
            <tr>
              <th className={styles['center']}>{t('Transaction ID')}</th>
              <th className={styles['center']}>{t('Payer')}</th>
              <th className={styles['center']}>{t('Payee')}</th>
              <th className={styles['center']}>{t('Transaction Date')}</th>
              <th className={styles['center']}>{t('Item')}</th>
              <th className={styles['center']}>{t('Unit Pricing')}</th>
              <th className={styles['center']}>{t('Amount Payable')}</th>
              <th className={styles['center']}>{t('Notes')}</th>
            </tr>
          )}
          {sortedTransactions &&
            sortedTransactions.map((transaction, idx) => {
              const amountDueByEntities =
                getInvoiceFareAdjustmentDueByEntities(transaction);
              return (
                <tr key={idx}>
                  {!isAdjustment && (
                    <>
                      <td>{transaction.id}</td>
                      <td>{transaction.payer_name}</td>
                      <td>{transaction.payee_name}</td>
                    </>
                  )}
                  <td>
                    {getFormattedTimeFromUTC(
                      transaction.transaction_date_time_utc ?? ''
                    )}
                  </td>
                  {!isAdjustment && (
                    <>
                      <td>{transaction.item_title}</td>
                      <td>{transaction.unit_pricing_title}</td>
                      <td className={styles['right']}>
                        <strong>
                          {getFormattedAmount(transaction.amount)}
                        </strong>
                      </td>
                    </>
                  )}
                  <td>{transaction.notes}</td>
                  {isAdjustment && (
                    <>
                      <td className={styles['right']}>
                        <strong>
                          {getFormattedAmount(
                            amountDueByEntities?.amountDueByAgent
                          )}
                        </strong>
                      </td>
                      <td className={styles['right']}>
                        <strong>
                          {getFormattedAmount(
                            amountDueByEntities?.amountDueBySupplier
                          )}
                        </strong>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
