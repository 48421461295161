import { combineReducers } from 'redux';

import { SET_LAST_ACCESS_REPORT_SETTINGS } from 'client/constants/ActionTypes';
import { initialSettings } from 'client/libraries/util/accessReportSettings';

const lastAccessReportSettings = (state = initialSettings, action: any) => {
  switch (action.type) {
    case SET_LAST_ACCESS_REPORT_SETTINGS:
      return action.accessReportSettings;

    default:
      return state;
  }
};

export const accessReportSettings = combineReducers({
  lastAccessReportSettings,
});
