import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import { parseDuration } from 'client/libraries/util/util';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';

import styles from './ETicketDurationInput.module.css';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  required?: boolean;
  positive?: boolean;
  dateText?: string;
  timeText?: string;
  tailText?: string;
};

const getDuration = (days: string, hours: string, minutes: string): string => {
  const d = parseInt(days);
  const h = parseInt(hours);
  const m = parseInt(minutes);
  return `${d * 24 + h}:${m < 10 ? `0${m}` : m}`;
};

export const ETicketDurationInput = ({
  required,
  value,
  onChange,
  positive,
  dateText,
  timeText,
  tailText,
}: Props) => {
  const { t } = useTranslation();

  let { hours: rawHours, minutes: rawMinutes } = parseDuration(value);

  if (rawHours < 0) {
    rawHours = -rawHours;
  }

  if (rawMinutes < 0) {
    rawMinutes = -rawMinutes;
  }

  const days = value ? `${Math.floor(rawHours / 24)}` : '';
  const hours = value ? `${rawHours % 24}` : '';
  const minutes = value ? `${rawMinutes}` : '';
  return (
    <div>
      <Box>
        <Box mb={2}>
          <div>{dateText ?? t('Redemption date')}</div>
        </Box>
        <label
          className={clsx(baseStyles['base-form-select'], styles['day-select'])}
        >
          <select
            value={days}
            onChange={(e) => {
              const newDays = e.target.value;

              if (newDays === '') {
                onChange('');
              } else {
                onChange(
                  `${positive ? '' : '-'}${getDuration(
                    newDays,
                    hours || '0',
                    minutes || '0'
                  )}`
                );
              }
            }}
          >
            {!required && <option value="">-</option>}
            {_.times(11).map((d) => (
              <option key={d} value={d}>
                {d === 0
                  ? t('On the participation date')
                  : positive
                  ? t('{{count}} days after', { count: d })
                  : t('{{count}} days before', { count: d })}
              </option>
            ))}
          </select>
        </label>
      </Box>

      <Box mt={2}>
        <Box mb={2}>
          <div>{timeText ?? t('Redemption start time')}</div>
        </Box>
        <Box display="flex" alignItems="center">
          <label
            className={clsx(
              baseStyles['base-form-select'],
              styles['hour-select']
            )}
          >
            <select
              value={hours}
              onChange={(e) => {
                const newHours = e.target.value;

                if (newHours === '') {
                  onChange('');
                } else {
                  onChange(
                    `${positive ? '' : '-'}${getDuration(
                      days || '0',
                      newHours,
                      minutes || '0'
                    )}`
                  );
                }
              }}
            >
              {!required && <option value="">-</option>}
              {_.times(24).map((hours) => (
                <option key={hours} value={hours}>
                  {t('{{count}}h', {
                    count: hours,
                  })}
                </option>
              ))}
            </select>
          </label>
          <label
            className={clsx(
              baseStyles['base-form-select'],
              styles['minute-select']
            )}
          >
            <select
              value={minutes}
              onChange={(e) => {
                const newMinutes = e.target.value;

                if (newMinutes === '') {
                  onChange('');
                } else {
                  onChange(
                    `${positive ? '' : '-'}${getDuration(
                      days || '0',
                      hours || '0',
                      newMinutes
                    )}`
                  );
                }
              }}
            >
              {!required && <option value="">-</option>}
              {_.times(60).map((min) => (
                <option key={min} value={min}>
                  {t('{{count}}m', {
                    count: min,
                  })}
                </option>
              ))}
            </select>
          </label>
          <Box ml={2}>{tailText ?? t('before the start time')}</Box>
        </Box>
      </Box>
    </div>
  );
};
