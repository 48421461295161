import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { SearchFormValues } from 'client/libraries/util/review';
import { fetchPageOfReviews, fetchReviews } from 'client/actions/reviews';
import type { Product } from 'shared/models/swagger';

import styles from './ProductReviewsPane.module.css';
import { ReviewsSummary } from './ReviewsSummary';
import { ReviewList } from './ReviewList';
import { ReviewSearchForm } from './ReviewSearchForm';

const defaultPageSize = 30;

type Props = {
  product: Product | null;
  contentLanguage: string;
  onSearchFormValuesChange: (searchFormValues: SearchFormValues) => void;
  searchFormValues: SearchFormValues;
  onClose: () => void;
};
export const ProductReviewsModalInner = ({
  product,
  contentLanguage,
  searchFormValues,
  onSearchFormValuesChange,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    if (product?.id) {
      setOffset(0);
      const months = _.range(
        searchFormValues.monthStart,
        searchFormValues.monthEnd + 1
      );

      dispatch(
        fetchReviews({
          productIds: [product?.id],
          language: contentLanguage,
          statuses: ['PUBLISHED'],
          ratings: searchFormValues.ratings,
          languagesFilter: searchFormValues.language
            ? ([searchFormValues.language] as any)
            : undefined,
          attributions: searchFormValues.attributions,
          months,
          pageSize: defaultPageSize + 1,
        })
      );
    }
  }, [product?.id, contentLanguage, searchFormValues]);

  const fetchNextPage = React.useCallback(() => {
    const newOffset = offset + defaultPageSize;
    if (product?.id) {
      const months = _.range(
        searchFormValues.monthStart,
        searchFormValues.monthEnd + 1
      );

      (async () => {
        await dispatch(
          fetchPageOfReviews({
            productIds: [product?.id],
            language: contentLanguage,
            statuses: ['PUBLISHED'],
            ratings: searchFormValues.ratings,
            languagesFilter: searchFormValues.language
              ? ([searchFormValues.language] as any)
              : undefined,
            attributions: searchFormValues.attributions,
            months,
            offset: newOffset,
            pageSize: defaultPageSize + 1,
          })
        );

        setOffset(newOffset);
      })();
    }
  }, [product?.id, contentLanguage, searchFormValues, offset]);

  const reviews = useSelector((state: ReduxState) => state.reviews.all);
  const loading = useSelector(
    (state: ReduxState) => state.reviews.fetchReviewsLoading
  );
  const lastFetchedPage = useSelector(
    (state: ReduxState) => state.reviews.lastFetchedPage
  );

  const showSeeMoreButton =
    (reviews?.length ?? 0) > 0 &&
    (lastFetchedPage?.length ?? 0) > defaultPageSize;

  const modalRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const onWindowClick = (event: any) => {
      if (modalRef && !modalRef.current?.contains(event.target)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', onWindowClick);
    return () => {
      window.removeEventListener('mousedown', onWindowClick);
    };
  }, [onClose]);

  if (!product?.review_count) {
    return null;
  }

  const productIds = React.useMemo(
    () => (product?.id ? [product?.id] : undefined),
    [product?.id]
  );

  return (
    <div className={styles['c-review__modal']}>
      <div ref={modalRef} className={styles['c-review__modal__content']}>
        <div className={styles['c-review__modal__content__header']}>
          <p className={styles['c-review__modal__content__header__ttl']}>
            {t('All Customer Reviews')}
          </p>
          <a
            className={styles['c-review__modal__content__header__close']}
            onClick={onClose}
          ></a>
        </div>
        <div className={styles['c-review__modal__content__body']}>
          <div className={styles['c-review__summary']}>
            <ReviewsSummary productIds={productIds} reviewStatus="PUBLISHED" />
            <ReviewSearchForm
              onSearchFormValuesChange={onSearchFormValuesChange}
              searchFormValues={searchFormValues}
              initialIsExpanded={false}
            />
          </div>
          {reviews && (
            <ReviewList
              reviews={reviews.slice(0, offset + defaultPageSize)}
              label={t('Customer Reviews')}
              showEditButton={false}
              showStatus={false}
            />
          )}
          {showSeeMoreButton && (
            <a
              className={clsx(
                styles['c-review__list__btn'],
                loading && styles['loading']
              )}
              onClick={() => fetchNextPage()}
            >
              {t('Show More Reviews')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export const ProductReviewsModal = (props: Props) => {
  const rootEl: any = document.getElementById('root');
  return ReactDOM.createPortal(<ProductReviewsModalInner {...props} />, rootEl);
};
