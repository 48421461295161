import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { allProductTagsSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';
import { Button, MultiSelect, DateTimeInput } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  setCustomRangeAvailabilityStartDate,
  setCustomRangeAvailabilityEndDate,
  setAvailabilityEndDate,
  setAvailabilityStartDate,
  setProductCalendarListTagFilter,
  setProductCalendarListSalesStatusFilter,
} from 'client/actions/productCalendarListControls';
import { getSalesStatusOptions } from 'client/libraries/util/getProductSalesStatus';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';

import { tagFilterSetSelector, salesStatusFilterSetSelector } from './util';
import { AvailabilityTable } from './AvailabilityTable';
import styles from './CustomRangeAvailability.module.css';
import { CalendarEditButtonRow } from './CalendarEditButtonRow';
import { CalendarEditContext } from './CalendarEditContext';

export const CustomRangeAvailability = () => {
  const { t } = useTranslation();

  const [selectedProductInstanceIds, setSelectedProductInstanceIds] =
    React.useState<string[]>([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    React.useState(false);
  const [error, setError] = React.useState('');
  const activeUser = useSelector(activeUserSelector);

  const customRangeStartDate = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.customRangeAvailabilityStartDate
  );
  const customRangeEndDate = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.customRangeAvailabilityEndDate
  );

  const [startDate, setStartDate] = React.useState(customRangeStartDate);
  const [endDate, setEndDate] = React.useState(customRangeEndDate);

  const allTags = useSelector(allProductTagsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setAvailabilityStartDate(
        moment(customRangeStartDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      setAvailabilityEndDate(
        moment(customRangeEndDate).add(1, 'days').format('YYYY-MM-DD')
      )
    );
  }, [customRangeStartDate, customRangeEndDate]);

  const applyDates = () => {
    if (startDate > endDate) {
      setError(t('Invalid date range'));
      return;
    }
    if (moment(endDate).diff(moment(startDate), 'days') >= 90) {
      setError(
        t('Sorry, the duration must be {{maxDuration}} days or less.', {
          maxDuration: 90,
        })
      );
      return;
    }

    setError('');
    dispatch(setCustomRangeAvailabilityStartDate(startDate));
    dispatch(setCustomRangeAvailabilityEndDate(endDate));
  };

  const tagOptions = React.useMemo(
    () =>
      allTags.map((tag) => ({
        key: tag,
        value: tag,
        text: tag,
      })) || [],
    [allTags]
  );

  return (
    <CalendarEditContext.Provider
      value={{
        selectedProductInstanceIds,
        selectProductInstanceIds: (...ids) => {
          const idsToAdd = ids.filter(
            (id) => !selectedProductInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedProductInstanceIds([
              ...selectedProductInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectProductInstanceIds: (...ids) => {
          setSelectedProductInstanceIds(
            selectedProductInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      <div className={styles['custom-range-header']}>
        <div className={styles['daterange']}>
          <div className={styles['datepicker']}>
            <DateTimeInput
              value={moment(startDate)}
              locale={locale}
              onChange={(date) => {
                setStartDate(date.format('YYYY-MM-DD'));
              }}
            />
          </div>
          -
          <div className={styles['datepicker']}>
            <DateTimeInput
              value={moment(endDate)}
              locale={locale}
              onChange={(date) => {
                setEndDate(date.format('YYYY-MM-DD'));
              }}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
        <Box ml={2} mt={2}>
          <Button style="green" size="middle" onClick={applyDates}>
            {t('Apply')}
          </Button>
        </Box>
        <Box ml={2} mt={2}>
          <MultiSelect
            placeholder={t('Filter product status...')}
            options={getSalesStatusOptions(t)}
            selectedValues={salesStatusFilterSet as string[]}
            onChange={({ value }) => {
              dispatch(
                setProductCalendarListSalesStatusFilter(value as SalesStatus[])
              );
            }}
            withoutFrame={true}
            search
          />
        </Box>
        <Box ml={2} mt={2}>
          <MultiSelect
            placeholder={t('Filter tags...')}
            options={tagOptions}
            selectedValues={tagFilterSet}
            onChange={({ value }) => {
              dispatch(setProductCalendarListTagFilter(value));
            }}
            withoutFrame={true}
            search
          />
        </Box>

        <div className={styles['buttons']}>
          {hasCustomUserRoleWritePermissions(
            activeUser,
            'AVAILABILITY.CUSTOM'
          ) && <CalendarEditButtonRow />}
        </div>
      </div>

      {error && <div className={baseStyles['error']}>{error}</div>}

      <AvailabilityTable />
    </CalendarEditContext.Provider>
  );
};
