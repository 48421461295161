import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchRestaurantOrderReportData } from 'client/actions/restaurantOrderReportData';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';

import SalesOverview from './SalesOverview';
import SalesTrends from './SalesTrends';
import PopularCategories from './PopularCategories';
import OrderCount from './OrderCount';
import styles from './MobileOrderDashboard.module.css';
import { getMonthlyDateRange, getWeeklyDateRange, DateRange } from './util';

export const MobileOrderDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const now = moment();

  const monthlyDateRange = getMonthlyDateRange(now.clone());
  const priorMonthDateRange = getMonthlyDateRange(
    now.clone().subtract(1, 'month')
  );

  const weeklyDateRange = getWeeklyDateRange(now.clone());
  const priorWeekDateRange = getWeeklyDateRange(
    now.clone().subtract(1, 'week')
  );

  const [graphDateRange, setGraphDateRange] = React.useState<DateRange>(
    getDateRangeForPreset('PREV_7_DAYS')
  );
  const [graphComparisonDateRange, setGraphComparisonDateRange] =
    React.useState<DateRange | null>(null);

  React.useEffect(() => {
    const dateRanges = [
      monthlyDateRange,
      priorMonthDateRange,
      weeklyDateRange,
      priorWeekDateRange,
      graphDateRange,
    ];

    if (graphComparisonDateRange) {
      dateRanges.push(graphComparisonDateRange);
    }

    dispatch(fetchRestaurantOrderReportData(dateRanges));
  }, [
    dispatch,
    monthlyDateRange,
    priorMonthDateRange,
    weeklyDateRange,
    priorWeekDateRange,
    graphDateRange,
    graphComparisonDateRange,
  ]);

  return (
    <div className={styles.dashboard}>
      <main className={styles.mainContent}>
        <section className={styles.mainContentSection}>
          <header className={styles.contentHeader}>
            <h1 className={styles.title}>{t('Dashboard')}</h1>
            <p className={styles.subtitle}>
              {t("Stay up to date on your restaurant's targets.")}
            </p>
          </header>
          <div className={styles.contentGrid}>
            <div className={styles.leftColumn}>
              <SalesOverview />
              <SalesTrends
                dateRange={graphDateRange}
                onDateRangeChange={setGraphDateRange}
                comparisonDateRange={graphComparisonDateRange}
                onComparisonDateRangeChange={setGraphComparisonDateRange}
              />
            </div>
            <div className={styles.rightColumn}>
              <PopularCategories />
              <OrderCount />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
