import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import {
  convertSearchInquiriesRequestToQueryParams,
  SearchInquiriesRequest,
} from 'client/libraries/util/searchInquiries';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { setLastExecutedInquirySearchCondition } from 'client/actions/inquiryTableControls';
import { fetchInquiries } from 'client/actions/inquiries';
import { Tabs } from 'client/components/Tabs/Tabs';
import { AccordionItem } from 'client/components/Accordion/Accordion';
import searchIcon from 'client/images/ic_search.svg';
import baseStyles from 'client/base.module.css';

import { useInquiryColumns } from './util';
import { InquirySummary } from './InquirySummary';
import { InquirySearchSettingsModal } from './InquirySearchSettingsModal/InquirySearchSettingsModal';
import { InquirySearchFiltersDisplayBox } from './InquirySearchFiltersDisplayBox/InquirySearchFiltersDisplayBox';

export const InquiryTable = () => {
  const columns = useInquiryColumns();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.inquiries.loading);
  const inquiries = useSelector((state: ReduxState) => state.inquiries.all);
  const [summaryIsOpen, setSummaryIsOpen] = React.useState(false);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.inquiryTableControls.lastExecutedSearchCondition
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchInquiriesRequest>(lastExecutedSearchCondition);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);
  const search = () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(setLastExecutedInquirySearchCondition(searchCondition));
      }

      dispatch(
        fetchInquiries(
          convertSearchInquiriesRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const tabPanes = React.useMemo(() => {
    const receivedInquiries = inquiries.filter((p) => p.status === 'RECEIVED');
    const respondedInquiries = inquiries.filter(
      (p) =>
        p.status === 'RESPONDED' ||
        (p.status === 'SENT' &&
          p.initial_communication_type !== 'BULK_EMAIL_BY_SUPPLIER' &&
          p.initial_communication_type !== 'BULK_SMS_BY_SUPPLIER' &&
          !p.bulk_send_parent_inquiry_id)
    );
    const archivedInquiries = inquiries.filter((p) => p.status === 'ARCHIVED');
    const bulkInquiries = inquiries.filter(
      (p) =>
        p.status === 'SENT' &&
        (p.initial_communication_type === 'BULK_EMAIL_BY_SUPPLIER' ||
          p.initial_communication_type === 'BULK_SMS_BY_SUPPLIER')
    );
    const singleInquiryTableColumns = columns.filter((col) =>
      [
        'reservationDetails',
        'details',
        'updated',
        'leadTime',
        'participation',
        'subject',
        'communicationType',
        'tags',
        'contact',
        'customer',
        'category',
      ].includes(col.id)
    );
    const bulkInquiryTableColumns = columns.filter((col) =>
      [
        'details',
        'updated',
        'subject',
        'communicationType',
        'tags',
        'reservationCount',
      ].includes(col.id)
    );
    return [
      {
        header: t('Received'),
        content: () =>
          receivedInquiries.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Received Inquiries Found')}
            </div>
          ) : (
            <CustomTable
              items={receivedInquiries}
              columns={singleInquiryTableColumns}
              usePaging
            />
          ),
      },
      {
        header: t('Responded/Sent'),
        content: () =>
          respondedInquiries.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Responded Inquiries Found')}
            </div>
          ) : (
            <CustomTable
              items={respondedInquiries}
              columns={singleInquiryTableColumns}
              usePaging
            />
          ),
      },
      {
        header: t('Archived'),
        content: () =>
          archivedInquiries.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Archived Inquiries Found')}
            </div>
          ) : (
            <CustomTable
              items={archivedInquiries}
              columns={singleInquiryTableColumns}
              usePaging
            />
          ),
      },
      {
        header: t('Bulk Notifications'),
        content: () =>
          bulkInquiries.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Bulk Notifications Found')}
            </div>
          ) : (
            <CustomTable
              items={bulkInquiries}
              columns={bulkInquiryTableColumns}
              usePaging
            />
          ),
      },
    ];
  }, [t, inquiries]);

  const reset = () => {
    setSearchCondition({
      participationDateFrom: '',
      participationDateTo: '',
      updatedDateFrom: '',
      updatedDateTo: '',
      dateFilterPreset: 'UPDATED_1_DAY',
      customerGivenName: '',
      customerFamilyName: '',
      contact: '',
      categories: [],
      tag: '',
      communicationTypes: [],
    });
  };

  return (
    <div>
      {loading && (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      )}
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        >
          <InquirySearchSettingsModal
            onReset={reset}
            onSearch={search}
            searchCondition={searchCondition}
            setSearchCondition={(condition) => setSearchCondition(condition)}
            trigger={
              <Button.Transition
                content={
                  <>
                    <img src={searchIcon} />
                    {t('Search')}
                  </>
                }
              />
            }
          />
        </div>
      </div>
      <Box mb={2}>
        <InquirySearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
        />
      </Box>

      <Box mb={2} maxWidth="700px">
        <AccordionItem
          header={t('Summary')}
          open={summaryIsOpen}
          onButtonClick={() => setSummaryIsOpen(!summaryIsOpen)}
        >
          {() => {
            return <InquirySummary />;
          }}
        </AccordionItem>
      </Box>

      <Tabs panes={tabPanes} />
    </div>
  );
};
