import * as React from 'react';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  error?: string;
  label?: string;
  value: string;
  onSearchChange: (arg0: any, arg1: any) => void;
  onResultSelect: (arg0: any, arg1: any) => void;
  results: Record<string, any>[];
  maxWidth?: number;
  minWidth?: number;
  minLength?: string;
  maxLength?: string;
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  required?: boolean;
};
export const Search = ({
  error,
  label,
  value,
  onSearchChange,
  onResultSelect,
  results,
  maxWidth,
  minWidth,
  maxLength,
  minLength,
  ...inputProps
}: Props) => {
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const areaRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOptions &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOptions(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOptions]);
  const style = {
    maxWidth,
    minWidth,
  };
  return (
    <div className={baseStyles['base-form-box']}>
      {label && (
        <div className={baseStyles['base-form-box__header']}>{label}</div>
      )}
      <div className={baseStyles['base-form-box__body']}>
        <div className={clsx(componentStyles['c-form-single'])} ref={areaRef}>
          <label
            className={clsx(
              componentStyles['select'],
              showOptions
                ? results.length > 0
                  ? componentStyles['is-active']
                  : ''
                : ''
            )}
            style={style}
          >
            <input
              value={value}
              onChange={(e) => onSearchChange(e, e.target)}
              onClick={() => setShowOptions(true)}
              type="text"
              maxLength={maxLength as any}
              minLength={minLength as any}
              {...inputProps}
            />
          </label>
          <ul className={clsx(componentStyles['option'])}>
            {results.map((result, idx) => {
              return (
                <li
                  key={idx}
                  data-value={result.value}
                  data-idx={idx}
                  onClick={(e) => {
                    onResultSelect(e, {
                      result: results[e.currentTarget.dataset.idx as any],
                    });
                    setShowOptions(false);
                  }}
                >
                  {result.detail}
                </li>
              );
            })}
          </ul>
        </div>
        {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
