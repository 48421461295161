import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps } from 'react-final-form';
import ColorPicker from 'rc-color-picker';

import { FieldWrapper } from 'client/components/Form';

export const SectionThemeColorEditor = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field name="themePrimaryColor">
        {({ input }: FieldRenderProps<string>) => (
          <FieldWrapper label={t('Theme: Main color')}>
            <ColorPicker
              color={input.value}
              onChange={({ color }: any) => input.onChange(color)}
            />
          </FieldWrapper>
        )}
      </Field>
      <Field name="themeSecondaryColor">
        {({ input }: FieldRenderProps<string>) => (
          <FieldWrapper label={t('Theme: Secondary color')}>
            <ColorPicker
              color={input.value}
              onChange={({ color }: any) => input.onChange(color)}
            />
          </FieldWrapper>
        )}
      </Field>
      <Field name="themeContentBackgroundColor">
        {({ input }: FieldRenderProps<string>) => (
          <FieldWrapper label={t('Theme: Content background color')}>
            <ColorPicker
              color={input.value}
              onChange={({ color }: any) => input.onChange(color)}
            />
          </FieldWrapper>
        )}
      </Field>
    </>
  );
};
