import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import {
  FalconUIRenderingContext,
  GalleryImage,
  Section,
} from '@nutmeglabs/falcon-ui';

import { Box } from 'client/components/Box/Box';
import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { Add } from 'client/components/Icons/Add';

import { SectionTitleEditor } from '../SectionTitleEditor';
import { SectionBackgroundEditor } from '../SectionBackgroundEditor';
import { getDefaultGalleryImage } from '../../useTemplateOptions';

import { DraggableGalleryImage } from './DraggableGalleryImage';

interface Props {
  name: string;
}

export const EditImageGalleryButton = ({ name }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const form = useForm();

  const {
    imageGallery,
    title,
    titleStyle,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
  } = _.get(form.getState().values, name) as Section;

  const topPageCtx = React.useContext(FalconUIRenderingContext);

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Edit Image Gallery')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              imageGallery,
              title,
              titleStyle,
              useBackgroundImage,
              backgroundColor,
              backgroundImageUrl,
            }}
            onSubmit={(values: Section) => {
              form.change(name, {
                type: 'IMAGE_GALLERY',
                imageGallery: values.imageGallery,
                title: values.title,
                titleStyle: values.titleStyle,
                useBackgroundImage: values.useBackgroundImage,
                backgroundColor: values.backgroundColor,
                backgroundImageUrl: values.backgroundImageUrl,
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <SectionTitleEditor />
                  <SectionBackgroundEditor />
                  <Field name="imageGallery">
                    {({ input }: FieldRenderProps<GalleryImage[]>) => (
                      <>
                        {input.value?.length > 0 && (
                          <Box mb={2}>
                            <Add
                              onClick={() => {
                                input.onChange([
                                  getDefaultGalleryImage(topPageCtx.t, true),
                                  ...input.value,
                                ]);
                              }}
                            />
                          </Box>
                        )}
                        {input.value?.map((imageItle, idx) => (
                          <DraggableGalleryImage
                            key={imageItle.key}
                            items={input.value}
                            onItemsChange={input.onChange}
                            index={idx}
                          />
                        ))}
                        <Add
                          onClick={() => {
                            input.onChange([
                              ...input.value,
                              getDefaultGalleryImage(topPageCtx.t, true),
                            ]);
                          }}
                        />
                      </>
                    )}
                  </Field>
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
