import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment, { Moment } from 'moment-timezone';
import { enUS, ja, ko, zhCN, zhTW } from 'date-fns/locale';

import { isTouchBackend } from 'client/libraries/util/isTouchBackend';

import styles from './Calendar.module.css';

type Props = {
  onChange: (arg0: Moment) => void;
  value: Moment | null;
  locale?: 'ja' | 'en' | 'ko' | 'zh-CN' | 'zh-TW';
  showTimeInput?: boolean;
  popperPlacement?: string;
  disabled?: boolean;
  label?: string;
};
registerLocale('ja', ja);
registerLocale('en', enUS);
registerLocale('ko', ko);
registerLocale('zh-CN', zhCN);
registerLocale('zh-TW', zhTW);

const Input = (props: Record<string, any>) => {
  return (
    <input
      className="test"
      {...props}
      readOnly={isTouchBackend() ? true : false}
    />
  );
};

export const DateTimeTZInput = ({
  onChange,
  value,
  label,
  locale,
  showTimeInput,
  popperPlacement,
  disabled,
}: Props) => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const isMouseClick = event.type === 'mousedown';
      const isTouchStart = event.type === 'touchstart';

      const target = isMouseClick
        ? (event as MouseEvent).target
        : (event as TouchEvent).target;

      if (isMouseClick || isTouchStart) {
        // Check if the target element is outside of the wrapper element
        if (
          fieldSetRef.current &&
          !fieldSetRef.current.contains(target as Node)
        ) {
          setIsClicked(false);
        }
      }
    };

    // Add event listeners to document for click outside
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const format = showTimeInput ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd';
  const offset = useMemo(() => {
    return new Date().getTimezoneOffset();
  }, []);
  // NOTE(goro) react-datepicker cannot support time zone.
  // Then we need to calculate the offset of current time and local time

  const dateFormatCalendar = locale === 'ja' ? 'yyyy年MM月' : undefined;

  const selected =
    value && value.isValid()
      ? value
          .clone()
          .add(value.utcOffset() + offset, 'minutes')
          .toDate()
      : null;

  return (
    <div className={styles['c-calendarWrapper']}>
      {label && <div>{label}</div>}
      <fieldset
        className={clsx(
          styles['c-calendar'],
          disabled && styles['is-disabled'],
          isHovered && styles['is-hover'],
          isClicked && styles['is-active']
        )}
        ref={fieldSetRef}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsClicked(true)}
      >
        <div
          className={clsx(
            styles['c-calendar__body'],
            'react-datepicker-override',
            'react-datepicker-tzinput-override'
          )}
        >
          <DatePicker
            customInput={<Input />}
            selected={selected}
            dateFormat={format}
            onChange={(date: Date, e: React.SyntheticEvent) => {
              e.preventDefault();
              onChange(
                moment.tz(
                  moment(date).format('YYYY-MM-DD HH:mm'),
                  value?.tz() ?? ''
                )
              );
            }}
            locale={locale || 'en'}
            showTimeInput={showTimeInput}
            popperPlacement={popperPlacement as any}
            disabled={disabled}
            dateFormatCalendar={dateFormatCalendar}
            open={isClicked} // Use this to open calendar if clicked outside of input field
          />
        </div>
      </fieldset>
    </div>
  );
};
