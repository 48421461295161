import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { setETicketDailyUsesVisibleColumns } from 'client/actions/eTicketDailyUsesSettings';
import { Modal } from 'client/components/Modal/Modal';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';

type Props = {
  columnsToShow: CustomTableColumn[];
  allCandidateColumns: CustomTableColumn[];
};

export const ETicketDailyUsesTableSettingsModal = ({
  columnsToShow,
  allCandidateColumns,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <Modal
      title={t('Show columns')}
      trigger={
        <a
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['square'],
            baseStyles['gray']
          )}
        >
          <img src={thIcon} />
        </a>
      }
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <ToggleableDndList
          selectedItems={columnsToShow.map((col) => ({
            key: col.id,
            text: col.Header,
          }))}
          candidateItems={allCandidateColumns.map((col) => ({
            key: col.id,
            text: col.Header,
          }))}
          setSelectedItems={(items) =>
            dispatch(setETicketDailyUsesVisibleColumns(items.map((i) => i.key)))
          }
        />
      </Modal.Content>
    </Modal>
  );
};
