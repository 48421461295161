import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Message } from 'client/components/Message/Message';
import { ToggleButton, Radio, Button, Select } from 'client/components/Form';
import { updateGuideSettings } from 'client/actions/guideSettings';
import type { Account, GuideSettings } from 'shared/models/swagger';

import styles from './Settings.module.css';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
};
export const GuideNotificationMail = ({ activeUser, guideSettings }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getTimeLocalText = (guideSettings: GuideSettings | null) => {
    const base =
      guideSettings?.notification_settings?.relative_date?.time_local || '9:00';
    const hour = base.split(':')[0];
    return `${('00' + hour).slice(-2)}:00`;
  };

  const [needReservationEmail, setNeedReservationEmail] =
    React.useState<boolean>(
      guideSettings?.notification_settings?.send_notification_mail || false
    );
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [relativeCount, setRelativeCount] = React.useState<number>(
    guideSettings?.notification_settings?.relative_date?.count || 0
  );
  const [success, setSuccess] = React.useState<boolean>(false);
  const [timeLocal, setTimeLocal] = React.useState<string>(
    getTimeLocalText(guideSettings)
  );
  const reset = React.useCallback(() => {
    setNeedReservationEmail(
      guideSettings?.notification_settings?.send_notification_mail || false
    );
    setRelativeCount(
      guideSettings?.notification_settings?.relative_date?.count || 0
    );
    setTimeLocal(getTimeLocalText(guideSettings));
  }, [guideSettings]);
  React.useEffect(() => {
    reset();
  }, [reset]);

  const selectOptions = _.times(24, String).map((i) => {
    const hour = `${('00' + i).slice(-2)}:00`;
    return {
      value: hour,
      text: hour,
    };
  });

  return (
    <>
      <ToggleButton
        label={t('Automatically send email when reservations are assigned')}
        checked={needReservationEmail}
        onChange={() => setNeedReservationEmail(!needReservationEmail)}
      />

      {needReservationEmail && (
        <div className={clsx(styles['notification-time'])}>
          <div>{t('Notification Date/Time')}</div>
          <Radio
            label={t('On the day')}
            checked={relativeCount === 0}
            onChange={() => {
              setRelativeCount(0);
            }}
          />
          <Radio
            label={t('Previous day')}
            checked={relativeCount === 1}
            onChange={() => {
              setRelativeCount(1);
            }}
          />
          <Select
            options={selectOptions}
            value={timeLocal}
            onChange={(e, { value }) => setTimeLocal(value)}
          />
        </div>
      )}

      {error && <Message error header={t('Update failed')} content={error} />}
      {success && <Message success header={t('Update succeeded')} />}
      <div className={styles['buttons']}>
        <div className={styles['button-box']}>
          <Button style="gray" size="middle" onClick={reset}>
            {t('Discard')}
          </Button>
        </div>
        <div className={styles['button-box']}>
          <Button
            style="blue"
            size="middle"
            loading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await dispatch(
                  updateGuideSettings(activeUser?.id ?? '', {
                    notification_settings: {
                      send_notification_mail: needReservationEmail,
                      relative_date: {
                        type: 'DAY',
                        count: relativeCount,
                        time_local: timeLocal,
                      },
                    },
                  })
                );
                setSuccess(true);
                setError('');
              } catch (err) {
                setSuccess(false);
                setError(err as string);
              }

              setLoading(false);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </>
  );
};
