import type { TranslateFuncType } from 'client/components/Translate';

export const getRedeemedRecordTypeText = (
  recordType: string,
  t: TranslateFuncType
): string => {
  switch (recordType) {
    case 'COUPON':
      return t('Coupon');
    case 'STAMP_RALLY':
      return t('Stamp rally');
    case 'STAMP_RALLY_GIFT':
      return t('Stamp rally gift');
  }
  return '';
};
