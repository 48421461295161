import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { config } from 'client/config';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button, Input } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { matchesFormat } from 'shared/libraries/validate/validator';
import styles from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookingWidgetWebSiteIntegration.module.css';
import baseStyles from 'client/base.module.css';

interface FormValues {
  customDomain: string;
}

export const CustomDomainTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);

  const validateFqdn = React.useCallback(
    (fqdn?: any) => {
      if (!fqdn) return undefined;

      if (!matchesFormat(fqdn, 'fqdn')) {
        return t(
          'Invalid domain. Please check to make sure your domain does not contain "http://" or spaces.'
        );
      }

      return undefined;
    },
    [t]
  );

  return (
    <>
      <ul
        style={{
          listStyle: 'none',
          paddingInlineStart: '0px',
        }}
      >
        <li>{t('To set up a custom domain for your booking widget:')}</li>
        <br />
        <li>
          <b>{t('Step 1:')}</b>
        </li>
        <li>
          {t(
            'Set up an A record with your DNS provider. If using a root domain:'
          )}
        </li>
        <br />
        <li>{`@    A    ${config.bookingWidgetIpAddress}`}</li>
        <br />
        <li>{t('If using a subdomain:')}</li>
        <br />
        <li>{`<subdomain>    A    ${config.bookingWidgetIpAddress}`}</li>
        <br />
        <li>
          <a
            href="https://moz.com/learn/seo/domain"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('What are subdomains and root domains?')}
          </a>
        </li>
        <br />
        <li>
          <b>{t('Step 2:')}</b>
        </li>
        <li>
          {t(
            'Enter your full custom domain name in the "Custom Domain" field and press "Save".'
          )}
        </li>
        <br />
        <li>
          {t(
            'Once steps 1 and 2 are completed, your booking widget will usually be live at your custom domain within 1-3 hours.'
          )}
        </li>
      </ul>
      <Form
        initialValues={{
          customDomain: organization?.booking_widget_custom_domain ?? '',
        }}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateOrganization(organization?.id || '', 'SUPPLIER', {
              booking_widget_custom_domain: values.customDomain || '',
            })
          );
        }}
      >
        {({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          modifiedSinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles['custom-domain-input']}>
              <Field name="customDomain" validate={validateFqdn}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    label={t('Custom Domain')}
                    placeholder="example.com"
                    maxWidth={600}
                    value={input.value}
                    onChange={input.onChange}
                    error={touched && error}
                  />
                )}
              </Field>
            </div>
            {submitSucceeded && !modifiedSinceLastSubmit && (
              <Message success header={t('Save Successful')} />
            )}
            {submitError && !modifiedSinceLastSubmit && (
              <Message error header={t('Save Failed')} />
            )}

            <div className={baseStyles['base-main__box__body__bottomBtns']}>
              <Button
                type="submit"
                style="blue"
                size="small"
                loading={submitting}
              >
                {t('Save Settings')}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
