import { getWeekdayText } from 'client/libraries/util/weekdays';
import type { PriceSchedule } from 'client/libraries/util/productShape';
import type { TranslateFuncType } from 'client/components/Translate';

export const getScheduleText = (
  schedule: PriceSchedule,
  t: TranslateFuncType
) => {
  const weekdayText =
    schedule.weekdays.length >= 7
      ? t('Everyday')
      : schedule.weekdays
          .map((weekday) => getWeekdayText(weekday, t))
          .join(',');

  if (schedule.startDate && schedule.endDate) {
    return `${schedule.startDate} ~ ${schedule.endDate} - ${weekdayText}`;
  }

  if (schedule.startDate) {
    return `${schedule.startDate} ~ ${t('Unknown')} - ${weekdayText}`;
  }

  if (schedule.endDate) {
    return `${t('Unknown')} ~ ${schedule.endDate} - ${weekdayText}`;
  }

  return '~';
};
