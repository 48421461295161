import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { setInstantWinEventVisibleColumns } from 'client/actions/instantWinEventTableControls';
import {
  instantWinEventColumnCandidatesSelector,
  instantWinEventVisibleColumnsSelector,
} from 'client/reducers/instantWinEventTableControls';
import { Modal } from 'client/components/Modal/Modal';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import { useInstantWinEventColumns } from '../util';

type OwnProps = {
  trigger: React.ReactElement<any>;
};
export const InstantWinEventTableSettingsModal = ({ trigger }: OwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columnCandidates = useSelector(instantWinEventColumnCandidatesSelector);
  const visibleColumns = useSelector(instantWinEventVisibleColumnsSelector);
  const allColumns = useInstantWinEventColumns();

  const onVisibleColumnsChange = (visibleColumns: string[]) =>
    dispatch(setInstantWinEventVisibleColumns(visibleColumns));

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return (columnMask || []).map(
      (c) => allColumns.find((col) => col?.id === c) as any
    );
  };

  const columnsToShow = getColumns(visibleColumns);
  const allCandidateColumns = getColumns(columnCandidates);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Show columns')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={columnsToShow.map((col) => ({
                  key: col?.id,
                  text: col?.translatedColumnName || col?.Header,
                }))}
                candidateItems={allCandidateColumns.map((col) => ({
                  key: col?.id,
                  text: col?.translatedColumnName || col?.Header,
                }))}
                setSelectedItems={(items) =>
                  onVisibleColumnsChange(items.map((i) => i.key))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
