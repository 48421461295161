import { CustomerReservationData } from './type';

// Filter only reservations with is_annual_pass_mode = true
export const getAnnualPassData = (data: CustomerReservationData[]) => {
  return data?.map((customerReservationData) => {
    return {
      ...customerReservationData,
      reservations: customerReservationData.reservations.filter(
        (reservation) => reservation.is_annual_pass_mode
      ),
    };
  });
};
