import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  BATCH_UPDATE_CUSTOM_PAGE_REQUEST,
  BATCH_UPDATE_CUSTOM_PAGE_SUCCESS,
  BATCH_UPDATE_CUSTOM_PAGE_FAILURE,
  CREATE_CUSTOM_PAGE_FAILURE,
  CREATE_CUSTOM_PAGE_REQUEST,
  CREATE_CUSTOM_PAGE_SUCCESS,
  DELETE_CUSTOM_PAGE_FAILURE,
  DELETE_CUSTOM_PAGE_REQUEST,
  DELETE_CUSTOM_PAGE_SUCCESS,
  FETCH_CUSTOM_PAGES_FAILURE,
  FETCH_CUSTOM_PAGES_REQUEST,
  FETCH_CUSTOM_PAGES_SUCCESS,
  UPDATE_CUSTOM_PAGE_FAILURE,
  UPDATE_CUSTOM_PAGE_REQUEST,
  UPDATE_CUSTOM_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  CustomPage,
  CreateCustomPageResponse,
  ListCustomPagesResponse,
  UpdateCustomPageResponse,
} from 'shared/models/swagger';

export const customPagesSelector = (state: ReduxState) => state.customPages.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_CUSTOM_PAGES_FAILURE:
    case CREATE_CUSTOM_PAGE_FAILURE:
    case UPDATE_CUSTOM_PAGE_FAILURE:
    case DELETE_CUSTOM_PAGE_FAILURE:
    case BATCH_UPDATE_CUSTOM_PAGE_FAILURE:
      return action.error;

    case FETCH_CUSTOM_PAGES_SUCCESS:
    case CREATE_CUSTOM_PAGE_SUCCESS:
    case UPDATE_CUSTOM_PAGE_SUCCESS:
    case DELETE_CUSTOM_PAGE_SUCCESS:
    case BATCH_UPDATE_CUSTOM_PAGE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_CUSTOM_PAGES_REQUEST:
    case CREATE_CUSTOM_PAGE_REQUEST:
    case UPDATE_CUSTOM_PAGE_REQUEST:
    case DELETE_CUSTOM_PAGE_REQUEST:
    case BATCH_UPDATE_CUSTOM_PAGE_REQUEST:
      return true;

    case FETCH_CUSTOM_PAGES_FAILURE:
    case CREATE_CUSTOM_PAGE_FAILURE:
    case UPDATE_CUSTOM_PAGE_FAILURE:
    case DELETE_CUSTOM_PAGE_FAILURE:
    case BATCH_UPDATE_CUSTOM_PAGE_FAILURE:
    case FETCH_CUSTOM_PAGES_SUCCESS:
    case CREATE_CUSTOM_PAGE_SUCCESS:
    case UPDATE_CUSTOM_PAGE_SUCCESS:
    case DELETE_CUSTOM_PAGE_SUCCESS:
    case BATCH_UPDATE_CUSTOM_PAGE_SUCCESS:
      return false;

    default:
      return state;
  }
};

/*
const initialValues: CustomPage[] = [
  {
    id: '000001',
    content_language: 'JA_JP',
    status: 'ACTIVE',
    type: 'NORMAL',
    path: 'a_path',
    title: '日本語A',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "日本語 通常 A" } } ], "version" : "2.22.0" }',
    sort_order: 1
  },
  {
    id: '000002',
    content_language: 'JA_JP',
    status: 'ACTIVE',
    type: 'NORMAL',
    path: 'b_path',
    title: '日本語B',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "日本語 通常 B" } } ], "version" : "2.22.0" }',
    sort_order: 2
  },
  {
    id: '000003',
    content_language: 'EN_US',
    status: 'ACTIVE',
    type: 'NORMAL',
    path: 'a_path',
    title: 'a_title',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "English Normal A" } } ], "version" : "2.22.0" }',
    sort_order: 1
  },
  {
    id: '000004',
    content_language: 'EN_US',
    status: 'ACTIVE',
    type: 'NORMAL',
    path: 'b_path',
    title: 'b_title',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "English Normal B" } } ], "version" : "2.22.0" }',
    sort_order: 2
  },
  {
    id: '000005',
    content_language: 'JA_JP',
    status: 'ACTIVE',
    type: 'NEWS',
    path: 'a_path',
    title: '日本語ニュースA',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "日本語 ニュース A" } } ], "version" : "2.22.0" }',
    sort_order: 1
  },
  {
    id: '000006',
    content_language: 'JA_JP',
    status: 'ACTIVE',
    type: 'NEWS',
    path: 'b_path',
    title: '日本語ニュースB',
    body:
      '{ "time" : 1626049808012, "blocks" : [ { "id" : "9cq_VRqPen", "type" : "paragraph", "data" : { "text" : "日本語 ニュース B" } } ], "version" : "2.22.0" }',
    sort_order: 2
  }
];
*/
const all = (state: CustomPage[] = [], action: any): CustomPage[] => {
  switch (action.type) {
    case FETCH_CUSTOM_PAGES_SUCCESS: {
      const response = action.response as ListCustomPagesResponse;
      return response.custom_pages;
    }

    case CREATE_CUSTOM_PAGE_SUCCESS: {
      const response = action.response as CreateCustomPageResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_CUSTOM_PAGE_SUCCESS: {
      const response = action.response as UpdateCustomPageResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_CUSTOM_PAGE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    case BATCH_UPDATE_CUSTOM_PAGE_SUCCESS: {
      const responses = action.responses as UpdateCustomPageResponse[];
      return [
        ...state.filter((i) => !responses.find((r) => r.id === i.id)),
        ...responses,
      ];
    }

    default:
      return state;
  }
};

export const customPages = combineReducers({
  error,
  loading,
  all,
});
