import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { calculateReservationFares } from 'client/actions/fares';
import type {
  NewReservation,
  ReservationBillingInfoLineItem,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  reservation: NewReservation;
  onChange: (arg0: string) => void;
};
export const PromoCodeInput = ({ reservation, onChange }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const checkAvailablePromoCode = async () => {
    setErrorMessage('');

    try {
      const resp: any = await dispatch(calculateReservationFares(reservation));

      if (
        (resp.response?.line_items || []).every(
          (lineItem: ReservationBillingInfoLineItem) =>
            (lineItem.amount_gross || '').indexOf('-') === -1
        )
      ) {
        setErrorMessage(t('Unapplicable promo code'));
      }
    } catch (err) {
      setErrorMessage(t('Could not check this promo code'));
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '200px',
            marginRight: '8px',
          }}
        >
          <TextField
            type="text"
            value={reservation?.promo_code || ''}
            onChange={(promoCode) => {
              onChange(promoCode);
            }}
            // Set error state only without message since other component will show the error message
            error={errorMessage ? true : false}
          />
        </div>
        <Button
          text={t('Apply')}
          color="primary"
          type="button"
          disabled={
            (reservation?.guests || []).length === 0 ||
            (reservation?.promo_code || '').length === 0
          }
          onClick={() => {
            checkAvailablePromoCode();
          }}
        />
      </div>
      {errorMessage && (
        <p className={baseStyles['u-error-msg']} style={{ marginTop: '4px' }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
