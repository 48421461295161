import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  getDateFilterPresetOptions,
  SearchMediaDownloadOrdersRequest,
  DateFilterPreset,
} from 'client/libraries/util/searchMediaDownloadOrders';
import { Modal } from 'client/components/Modal/Modal';
import {
  Input,
  DateRangeInput,
  Button,
  MultiSelect,
  Select,
} from 'client/components/Form';
import { productOptionsSelector } from 'client/reducers/products';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  searchCondition: SearchMediaDownloadOrdersRequest;
  setSearchCondition: (arg0: SearchMediaDownloadOrdersRequest) => void;
  trigger: React.ReactElement<any>;
};
export const MediaDownloadOrderSearchSettingsModal = ({
  onReset,
  onSearch,
  searchCondition,
  setSearchCondition,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];

  const changeProductId = ({ value }: { value: string[] }) => {
    setSearchCondition({ ...searchCondition, productIds: value });
  };

  const participationDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const participationDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateTo: date,
      dateFilterPreset: null,
    });
  };

  const createdDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      createdDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const createdDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      createdDateTo: date ? moment(date).format('YYYY-MM-DD') : '',
      dateFilterPreset: null,
    });
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Search Orders')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Modal.Box>
          <MultiSelect
            search
            label={t('Product')}
            options={productOptions}
            selectedValues={searchCondition.productIds}
            onChange={changeProductId}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Application Number')}
            value={searchCondition.agentReference}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                agentReference: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Confirmation Number')}
            value={searchCondition.supplierReference}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                supplierReference: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Select
            label={t('Date Preset')}
            options={dateFilterPresetOptions}
            value={searchCondition.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setSearchCondition({
                ...searchCondition,
                dateFilterPreset:
                  value === 'NONE' ? null : (value as DateFilterPreset),
                createdDateFrom: '',
                createdDateTo: '',
                participationDateFrom: '',
                participationDateTo: '',
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <DateRangeInput
            label={t('Participation Date')}
            fromDate={searchCondition.participationDateFrom}
            onChangeFromDate={participationDateDateFromChangeHandler}
            toDate={searchCondition.participationDateTo}
            onChangeToDate={participationDateDateToChangeHandler}
          />
          <DateRangeInput
            label={t('Purchase Date')}
            fromDate={searchCondition.createdDateFrom}
            onChangeFromDate={createdDateFromChangeHandler}
            toDate={searchCondition.createdDateTo}
            onChangeToDate={createdDateToChangeHandler}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Given Name')}
            value={searchCondition.customerGivenName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerGivenName: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Family Name')}
            value={searchCondition.customerFamilyName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerFamilyName: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
          }}
        >
          {t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
