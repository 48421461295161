import type { Recurrence } from 'shared/models/swagger';

export type TimeSlot = {
  timeSlotKey: string;
  startTimes: {
    startTimeLocal: string;
    duration: string;
    startDate: string;
    endDate: string;
  }[];
};

const getEarliestStartDate = (recurrenceRule: Recurrence): string => {
  let earliestStartDate = recurrenceRule.start_date_local;

  for (const dateRange of recurrenceRule.additional_date_ranges ?? []) {
    if (
      dateRange.start_date_local &&
      dateRange.start_date_local < earliestStartDate
    ) {
      earliestStartDate = dateRange.start_date_local;
    }
  }

  return earliestStartDate;
};

const getLatestEndDate = (recurrenceRule: Recurrence): string => {
  let latestEndDate = recurrenceRule.end_date_local;

  for (const dateRange of recurrenceRule.additional_date_ranges ?? []) {
    if (dateRange.end_date_local && dateRange.end_date_local > latestEndDate) {
      latestEndDate = dateRange.end_date_local;
    }
  }

  return latestEndDate;
};

export const getTimeSlotsFromRecurrence = (
  recurrence: Recurrence[],
  earliestStartDate?: string,
  latestEndDate?: string
): TimeSlot[] => {
  const timeSlots: TimeSlot[] = [];

  for (const recurrenceRule of recurrence) {
    const recurrenceEarliestStartDate = getEarliestStartDate(recurrenceRule);
    const recurrenceLatestEndDate = getLatestEndDate(recurrenceRule);

    // If filters are applied, skip this recurrence rule if it doesn't match
    if (
      (latestEndDate && recurrenceEarliestStartDate > latestEndDate) ||
      (earliestStartDate && recurrenceLatestEndDate < earliestStartDate)
    ) {
      continue;
    }
    for (const startTime of recurrenceRule.start_times || []) {
      const existingTimeSlot = timeSlots.find(
        (slot) => slot.timeSlotKey === startTime.time_slot_key
      );

      if (existingTimeSlot) {
        existingTimeSlot.startTimes.push({
          startTimeLocal: startTime.start_time_local || '',
          duration: startTime.duration || '',
          startDate: getEarliestStartDate(recurrenceRule),
          endDate: getLatestEndDate(recurrenceRule),
        });
      } else {
        timeSlots.push({
          timeSlotKey: startTime.time_slot_key || '',
          startTimes: [
            {
              startTimeLocal: startTime.start_time_local || '',
              duration: startTime.duration || '',
              startDate: getEarliestStartDate(recurrenceRule),
              endDate: getLatestEndDate(recurrenceRule),
            },
          ],
        });
      }
    }
  }

  return timeSlots;
};
