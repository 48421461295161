import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { fetchSubscriptionInvoices } from 'client/actions/subscriptionInvoices';
import { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Box } from 'client/components/Box/Box';
import { getSubscriptionTitle } from 'client/libraries/util/getSubscriptionTitle';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { Loading } from 'client/pages/Loading';
import baseStyles from 'client/base.module.css';
import newWindowIcon from 'client/images/ic_newwindow_gray.svg';
import { SubscriptionInvoice, SubscriptionKey } from 'shared/models/swagger';

type Column = {
  Header: string;
  id: string;
  accessor?: string | ((row: SubscriptionInvoice) => string);
  Cell?: (cellInfo: { original: SubscriptionInvoice }) => React.ReactNode;
  th?: boolean;
  width?: 'minimal' | 'short' | 'middle' | 'long' | 'longx2';
};

const useColumns = (): Column[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('Date'),
      id: 'date',
      accessor: (row) => moment(row.issued_date_time_utc).format('lll'),
    },
    {
      Header: t('Name'),
      id: 'name',
      accessor: (row) =>
        getSubscriptionTitle(row.subscription_key as SubscriptionKey, t),
    },
    {
      Header: t('Billing Period'),
      id: 'billingPeriod',
      accessor: (row) =>
        `${moment(row.period_start_date_time_utc).format(
          'YYYY/MM/DD'
        )} ~ ${moment(row.period_end_date_time_utc).format('YYYY/MM/DD')}`,
    },
    {
      Header: t('Fee'),
      id: 'fee',
      accessor: (row) => {
        const formattedAmount = formattedCurrencyAmount(row.amount ?? '');

        const parts = formattedAmount.split(/\s+/);

        if (parts.length > 1) {
          return parts.slice(1).join('');
        }

        return formattedAmount;
      },
    },
    {
      Header: t('Receipt'),
      id: 'receipt',
      Cell: (cellInfo) => {
        return (
          <Box display="flex" alignItems="center">
            {cellInfo.original.stripe_hosted_invoice_url ? (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={cellInfo.original.stripe_hosted_invoice_url}
                >
                  {t('View Receipt')}
                </a>
                <Box ml={1} mb={1}>
                  <img src={newWindowIcon} />
                </Box>
              </>
            ) : (
              t('Processing')
            )}
          </Box>
        );
      },
    },
  ];
};
export const SystemFeeSubscriptions = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const invoices = useSelector(
    (state: ReduxState) => state.subscriptionInvoices.all
  );

  const loading = useSelector(
    (state: ReduxState) => state.subscriptionInvoices.loading
  );

  const columns = useColumns();

  React.useEffect(() => {
    dispatch(fetchSubscriptionInvoices());
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (invoices.length === 0) {
    return (
      <div className={baseStyles['base-form-box__err']}>
        {t('No Subscriptions Found')}
      </div>
    );
  }

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency: invoices[0].amount?.substring(0, 3),
          })}
        </Box>
      </Box>
      <CustomTable items={invoices} columns={columns} usePaging={true} />
    </>
  );
};
