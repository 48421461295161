import arrowIcon from 'client/images/ic_arrow.svg';

import styles from './BackArrow.module.css';

export const BackArrow = () => {
  return (
    <p className={styles['back-arrow-circle']}>
      <img src={arrowIcon} />
    </p>
  );
};
