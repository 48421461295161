import moment from 'moment-timezone';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { reservationIsCheckinCheckoutOnly } from 'client/libraries/util/util';
import * as Swagger from 'shared/models/swagger';

export type FormValues = {
  transportRouteItems: TransportRouteItem[];
};
export type TransportRouteItem = {
  locationFrom: TransportRouteItemLocation;
  locationTo: TransportRouteItemLocation;
  dispatchCrew: string[];
  dispatchVehicles: string[];
  dispatchMiscResources: string[];
  dispatchGuides: string[];
};
export type TransportRouteItemLocation = {
  googlePlaceId: string;
  locationName: string;
  hhMm: string;
};
export const getInitialValues = (
  reservation: Swagger.Reservation | null
): FormValues => {
  const pickupCheckin =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkin
      : reservation?.pickup;
  const dropoffCheckout =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkout
      : reservation?.dropoff;
  return {
    transportRouteItems:
      reservation?.transport_route?.map((routeItem, idx) => ({
        locationFrom:
          idx === 0
            ? {
                googlePlaceId: pickupCheckin?.google_place_id ?? '',
                locationName: pickupCheckin?.location_name ?? '',
                hhMm: pickupCheckin?.date_time_utc
                  ? moment
                      .tz(
                        pickupCheckin?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              }
            : {
                googlePlaceId: routeItem?.location_from?.google_place_id ?? '',
                locationName: routeItem?.location_from?.location_name ?? '',
                hhMm: routeItem?.location_from?.date_time_utc
                  ? moment
                      .tz(
                        routeItem?.location_from?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              },
        locationTo:
          idx === (reservation?.transport_route ?? []).length - 1
            ? {
                googlePlaceId: dropoffCheckout?.google_place_id ?? '',
                locationName: dropoffCheckout?.location_name ?? '',
                hhMm: dropoffCheckout?.date_time_utc
                  ? moment
                      .tz(
                        dropoffCheckout?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              }
            : {
                googlePlaceId: routeItem?.location_to?.google_place_id ?? '',
                locationName: routeItem?.location_to?.location_name ?? '',
                hhMm: routeItem?.location_to?.date_time_utc
                  ? moment
                      .tz(
                        routeItem?.location_to?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              },
        dispatchCrew: routeItem?.dispatch_crew ?? [],
        dispatchVehicles: routeItem?.dispatch_vehicles ?? [],
        dispatchMiscResources: routeItem?.dispatch_misc_resources ?? [],
        dispatchGuides: routeItem?.dispatch_guides ?? [],
      })) ?? [],
  };
};
export const getInitialValuesFromManifestReservation = (
  reservation: ManifestReservationShape
): FormValues => {
  return {
    transportRouteItems:
      reservation?.transport_route?.map((routeItem, idx) => ({
        locationFrom:
          idx === 0
            ? {
                googlePlaceId: '',
                locationName: reservation.pickup_checkin_location,
                hhMm: reservation.pickup_checkin_time
                  ? reservation.pickup_checkin_time.format('HH:mm')
                  : '',
              }
            : {
                googlePlaceId: routeItem?.location_from?.google_place_id ?? '',
                locationName: routeItem?.location_from?.location_name ?? '',
                hhMm: routeItem?.location_from?.date_time_utc
                  ? moment
                      .tz(
                        routeItem?.location_from?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              },
        locationTo:
          idx === (reservation?.transport_route ?? []).length - 1
            ? {
                googlePlaceId: '',
                locationName: reservation.dropoff_checkout_location,
                hhMm: reservation.dropoff_checkout_time
                  ? reservation.dropoff_checkout_time.format('HH:mm')
                  : '',
              }
            : {
                googlePlaceId: routeItem?.location_to?.google_place_id ?? '',
                locationName: routeItem?.location_to?.location_name ?? '',
                hhMm: routeItem?.location_to?.date_time_utc
                  ? moment
                      .tz(
                        routeItem?.location_to?.date_time_utc,
                        reservation?.start_timezone ?? ''
                      )
                      .format('HH:mm')
                  : '',
              },
        dispatchCrew: routeItem?.dispatch_crew ?? [],
        dispatchVehicles: routeItem?.dispatch_vehicles ?? [],
        dispatchMiscResources: routeItem?.dispatch_misc_resources ?? [],
        dispatchGuides: routeItem?.dispatch_guides ?? [],
      })) ?? [],
  };
};

const convertHhMmToDateTimeUtc = (
  startDateTimeUtc: string,
  reservationTimezone: string,
  hhMm: string
): string => {
  const startTime = moment.tz(
    startDateTimeUtc ?? '',
    reservationTimezone ?? ''
  );
  const hhMmMoment = moment(hhMm, 'HH:mm');
  return startTime
    .hours(hhMmMoment.hours())
    .minutes(hhMmMoment.minutes())
    .utc()
    .format();
};

export const convertFormValuesToReservationPatch = (
  values: FormValues,
  startDateTimeUtc: string,
  reservationTimezone: string
): Swagger.Reservation$Patch => {
  return {
    transport_route: values.transportRouteItems?.map((routeItem, idx) => {
      return {
        location_from:
          idx === 0
            ? undefined
            : {
                google_place_id: routeItem.locationFrom.googlePlaceId,
                location_name: routeItem.locationFrom.locationName,
                date_time_utc: convertHhMmToDateTimeUtc(
                  startDateTimeUtc,
                  reservationTimezone,
                  routeItem.locationFrom.hhMm
                ),
              },
        location_to:
          idx === (values.transportRouteItems ?? []).length - 1
            ? undefined
            : {
                google_place_id: routeItem.locationTo.googlePlaceId,
                location_name: routeItem.locationTo.locationName,
                date_time_utc: convertHhMmToDateTimeUtc(
                  startDateTimeUtc,
                  reservationTimezone,
                  routeItem.locationTo.hhMm
                ),
              },
        dispatch_crew: routeItem.dispatchCrew,
        dispatch_vehicles: routeItem.dispatchVehicles,
        dispatch_misc_resources: routeItem.dispatchMiscResources,
        dispatch_guides: routeItem.dispatchGuides,
      };
    }),
  };
};
