import * as React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { updateContract } from 'client/actions/contract';
import { updateOrganization } from 'client/actions/organizations';
import type { Contract, Organization } from 'shared/models/swagger';

type Props = {
  isEditingDefaultBillingSchedule?: boolean;
  currentOrganization?: Organization | null;
  contract?: Contract;
  trigger: React.ComponentType<unknown>;
};
export const EditBillingPeriodModal = ({
  isEditingDefaultBillingSchedule,
  contract,
  currentOrganization,
  trigger: Trigger,
}: Props) => {
  const initialDaysOfMonth = isEditingDefaultBillingSchedule
    ? currentOrganization?.default_billing_schedule?.days_of_month
    : contract?.billing_schedule_supplier?.days_of_month;
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [daysOfMonth, setDaysOfMonth] = React.useState(
    initialDaysOfMonth || [1]
  );
  const [numberOfPeriods, setNumberOfPeriods] = React.useState(
    initialDaysOfMonth ? initialDaysOfMonth.length : 1
  );
  const dispatch = useDispatch();

  const handleUpdateContract = () => {
    dispatch(
      updateContract(contract?.id ?? '', {
        ...(contract as any),
        billing_schedule_supplier: {
          days_of_month: daysOfMonth,
        },
      })
    );
    setShowModal(false);
  };

  const updateDefaultBillingSchedule = () => {
    dispatch(
      updateOrganization(
        currentOrganization?.id ?? '',
        currentOrganization?.type ?? 'SUPPLIER',
        {
          default_billing_schedule: {
            days_of_month: daysOfMonth,
          },
        }
      )
    );
  };

  const getBillingDaysOfMonthComponents = () =>
    daysOfMonth?.map((day, i) => (
      <Box mt={2} key={i}>
        <Select
          label={t('Invoicing date {{idx}} of the month:', {
            idx: i + 1,
          })}
          search
          options={[
            ..._.times(31, (i) => i + 1).map((v) => ({
              key: v,
              text: `${v}`,
              value: `${v}`,
            })),
          ]}
          value={`${daysOfMonth[i]}`}
          onChange={(e, { value }) => {
            const newValue = parseInt(value);

            const newDaysOfMonth = [...daysOfMonth];
            newDaysOfMonth[i] = newValue;
            normalizeDaysOfMonth(newDaysOfMonth);
            setDaysOfMonth(newDaysOfMonth);
          }}
        />
      </Box>
    ));

  // normalizeDaysOfMonth makes sure that:
  // 1) daysOfMonth is in strict ascending order
  // 2) daysOfMonth[0] >= 1
  // 3) daysOfMonth[daysOfMonth.length - 1] <= 31
  const normalizeDaysOfMonth = (daysOfMonth: any) => {
    if (!daysOfMonth) {
      return;
    }

    if (daysOfMonth[0] < 1) {
      daysOfMonth[0] = 1;
    }

    for (let i = 1; i < daysOfMonth.length; i++) {
      if (daysOfMonth[i] <= daysOfMonth[i - 1]) {
        daysOfMonth[i] = daysOfMonth[i - 1] + 1;
      }
    }

    if (daysOfMonth[daysOfMonth.length - 1] > 31) {
      daysOfMonth[daysOfMonth.length - 1] = 31;
    }

    for (let i = daysOfMonth.length - 1; i > 0; i--) {
      if (daysOfMonth[i] <= daysOfMonth[i - 1]) {
        daysOfMonth[i - 1] = daysOfMonth[i] - 1;
      }
    }
  };

  return (
    <>
      <span onClick={() => setShowModal(true)}>
        <Trigger />
      </span>
      <Modal
        title={
          isEditingDefaultBillingSchedule
            ? t('Edit Default Billing Period')
            : t('Edit Billing Period')
        }
        open={showModal}
        onClose={() => setShowModal(false)}
        insertRoot
      >
        <Modal.Content>
          <Select
            label={t('Invoices to create per month (max 4):')}
            value={`${numberOfPeriods}`}
            search
            options={[
              ..._.times(4, (i) => i + 1).map((v) => ({
                key: v,
                text: `${v}`,
                value: `${v}`,
              })),
            ]}
            onChange={(e, { value }) => {
              const newValue = parseInt(value);

              // update days of month array size accordingly
              let newDaysOfMonth = daysOfMonth;

              if (!newDaysOfMonth) {
                newDaysOfMonth = [];

                for (let i = 0; i < newValue; i++) {
                  newDaysOfMonth.push(i + 1);
                }
              } else if (newDaysOfMonth.length < newValue) {
                for (let i = newDaysOfMonth.length; i < newValue; i++) {
                  newDaysOfMonth.push(i);
                }
              } else if (newDaysOfMonth.length > newValue) {
                while (newDaysOfMonth.length > newValue) {
                  newDaysOfMonth.pop();
                }
              }

              // normalize days of month value
              normalizeDaysOfMonth(newDaysOfMonth);
              setNumberOfPeriods(newValue);
              setDaysOfMonth(newDaysOfMonth);
            }}
          />

          {getBillingDaysOfMonthComponents()}
        </Modal.Content>

        <Modal.Actions>
          <Button.Cancel
            onClick={() => {
              const daysOfMonth = isEditingDefaultBillingSchedule
                ? currentOrganization &&
                  currentOrganization.default_billing_schedule &&
                  currentOrganization.default_billing_schedule.days_of_month
                : contract &&
                  contract.billing_schedule_supplier &&
                  contract.billing_schedule_supplier.days_of_month;
              setShowModal(false);
              setDaysOfMonth(daysOfMonth || [1]);
              setNumberOfPeriods(daysOfMonth ? daysOfMonth.length : 1);
            }}
          >
            {t('Discard Changes')}
          </Button.Cancel>
          <Button.Submit
            onClick={() => {
              if (isEditingDefaultBillingSchedule) {
                updateDefaultBillingSchedule();
              } else {
                handleUpdateContract();
              }
            }}
          >
            {t('Save')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    </>
  );
};
