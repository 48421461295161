import { v4 as uuidv4 } from 'uuid';

import { config } from 'client/config';
import { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  customerRegistrationRequiredForBooking: boolean;
  termsAndConditions: string;
  privacyPolicy: string;
  formFieldSets: { [name: string]: FormCustomerFormFieldSet };
  contentLanguage: string;
  allowViewingReservationDetails: boolean;
  allowUpdatingReservationInfo: boolean;
  allowChangingReservationParameters: boolean;
  allowCancellingReservation: boolean;
  allowIssuingDownloadingReceiptPDF: boolean;

  isEnabled: boolean;

  translation?: {
    termsAndConditions?: { [language: string]: string };
    privacyPolicy?: { [language: string]: string };
  };
}

export interface FormCustomerFormFieldSet {
  customerNameFormat: 'ALPHABET' | 'KANA' | 'KANA_OR_ALPHABET';
  formFields: FormCustomerFormField[];
}

export interface FormCustomerFormField {
  key: string;
  type: 'DAY_OF_BIRTH' | 'GENDER' | 'CUSTOM' | 'ADDRESS';
  fieldName: string;
  responseType: 'TEXT' | 'FORMATTED_TEXT' | 'SELECT_ONE' | 'ADDRESS_FORM';
  required: 'WHEN_SIGN_UP' | 'OPTIONAL';
  options: string[];
  format?: string;
}

const convertFormatToField = (format: 'YYYY_MM_DD' | 'NUMERIC' | undefined) => {
  if (format) {
    return format === 'YYYY_MM_DD' ? 'yyyy-mm-dd' : 'numeric';
  }
  return undefined;
};

export const getInitialValues = (
  organization: Swagger.Organization,
  supportedLanguages: Swagger.SourceLanguage[]
): FormValues => {
  const customerFieldFormSets: { [index: string]: FormCustomerFormFieldSet } =
    {};
  for (const lang of supportedLanguages) {
    customerFieldFormSets[lang] = {
      formFields: [],
      customerNameFormat: 'ALPHABET',
    };
  }
  organization.customer_ledger_settings?.form_field_sets?.forEach((set) => {
    if (set.content_language) {
      customerFieldFormSets[set.content_language] = {
        customerNameFormat: set.customer_name_format ?? 'ALPHABET',
        formFields: (set.form_fields ?? []).map((formField) => ({
          key: formField.key ?? '',
          type: formField.type ?? 'CUSTOM',
          fieldName: formField.field_name ?? '',
          responseType: formField.response_type ?? 'TEXT',
          format: convertFormatToField(formField.format),
          options:
            formField.type === 'GENDER'
              ? getGenderSwaggerOptions(
                  set.content_language as Swagger.SourceLanguage
                ).map((o) => o.text)
              : (formField.options ?? []).map((option) => option.key ?? ''),
          required: formField.required ?? 'OPTIONAL',
        })),
      };
    }
  });

  return {
    customerRegistrationRequiredForBooking:
      organization.customer_ledger_settings
        ?.customer_registration_required_for_booking || false,
    termsAndConditions:
      organization.customer_ledger_settings?.terms_and_conditions || '',
    privacyPolicy: organization.customer_ledger_settings?.privacy_policy || '',
    formFieldSets: customerFieldFormSets,
    contentLanguage: '',
    allowViewingReservationDetails:
      organization.guest_my_page_settings?.allow_viewing_reservation_details ||
      false,
    allowUpdatingReservationInfo:
      organization.guest_my_page_settings?.allow_updating_reservation_info ||
      false,
    allowChangingReservationParameters:
      organization.guest_my_page_settings
        ?.allow_changing_reservation_parameters || false,
    allowCancellingReservation:
      organization.guest_my_page_settings?.allow_cancelling_reservation ||
      false,
    allowIssuingDownloadingReceiptPDF:
      organization.guest_my_page_settings
        ?.allow_issuing_downloading_receipt_pdf || false,
    isEnabled: organization.customer_ledger_settings?.is_enabled || false,
  };
};

const convertFormatToSwagger = (
  format: string | undefined
): 'YYYY_MM_DD' | 'NUMERIC' | undefined => {
  if (format) {
    return format === 'yyyy-mm-dd' ? 'YYYY_MM_DD' : 'NUMERIC';
  }
  return undefined;
};

export const convertFormValuesToSwagger = (
  formValues: FormValues,
  organization: Swagger.Organization
): Swagger.Organization => {
  const formFieldSets: Swagger.CustomerFormFieldSet[] = [];

  for (const [lang, value] of Object.entries(formValues.formFieldSets)) {
    formFieldSets.push({
      content_language: lang,
      customer_name_format: value.customerNameFormat ?? 'ALPHABET',
      form_fields: value.formFields.map((formField) => ({
        key: formField.key,
        type: formField.type,
        field_name: formField.fieldName,
        response_type: formField.responseType,
        format: convertFormatToSwagger(formField.format),
        options:
          formField.type === 'GENDER'
            ? getGenderSwaggerOptions(lang as Swagger.SourceLanguage)
            : formField.options.map((option) => ({
                text: option,
                key: option,
              })),
        required: formField.required,
      })),
    });
  }

  const guestMyPageSettings = {
    ...organization.guest_my_page_settings,
    allow_viewing_reservation_details:
      formValues.allowViewingReservationDetails,
    allow_updating_reservation_info: formValues.allowUpdatingReservationInfo,
    allow_changing_reservation_parameters:
      formValues.allowChangingReservationParameters,
    allow_cancelling_reservation: formValues.allowCancellingReservation,
    allow_issuing_downloading_receipt_pdf:
      formValues.allowIssuingDownloadingReceiptPDF,
  };

  return {
    ...organization,
    guest_my_page_settings: guestMyPageSettings,
    customer_ledger_settings: {
      ...organization.customer_ledger_settings,
      customer_registration_required_for_booking:
        formValues.customerRegistrationRequiredForBooking,
      terms_and_conditions: formValues.termsAndConditions,
      privacy_policy: formValues.privacyPolicy,
      form_field_sets: formFieldSets,
      is_enabled: formValues.isEnabled,
    },
  };
};

export const getDefaultFormField = (): FormCustomerFormField => {
  return {
    key: uuidv4(),
    type: 'CUSTOM',
    fieldName: '',
    responseType: 'TEXT',
    required: 'WHEN_SIGN_UP',
    options: [],
  };
};

interface PresetOption {
  text: string;
  key: string;
  type: 'DAY_OF_BIRTH' | 'GENDER' | 'CUSTOM' | 'ADDRESS';
  fieldName: string;
  responseType: 'TEXT' | 'FORMATTED_TEXT' | 'SELECT_ONE' | 'ADDRESS_FORM';
  required: 'WHEN_SIGN_UP' | 'OPTIONAL';
  options: string[];
  format?: string;
}

export const getFieldPresetOptions = (t: TranslateFuncType): PresetOption[] => {
  return [
    {
      text: t('Date of Birth'),
      key: 'day_of_birth', // use fixed key
      type: 'DAY_OF_BIRTH',
      fieldName: t('Date of Birth'),
      responseType: 'FORMATTED_TEXT',
      required: 'WHEN_SIGN_UP',
      options: [],
      format: 'yyyy-mm-dd',
    },
    {
      text: t('Gender'),
      key: 'gender', // use fixed key
      type: 'GENDER',
      fieldName: t('Gender'),
      responseType: 'SELECT_ONE',
      required: 'WHEN_SIGN_UP',
      options: [t('Male'), t('Female'), t('No Response')],
    },
    ...((config.enableCustomerLedgerAddressInputForm
      ? [
          {
            text: t('Address'),
            key: 'address', // use fixed key
            type: 'ADDRESS',
            fieldName: t('Address'),
            responseType: 'ADDRESS_FORM',
            required: 'WHEN_SIGN_UP',
            options: [],
          },
        ]
      : []) as PresetOption[]),
    {
      text: t('Custom'),
      key: uuidv4(),
      type: 'CUSTOM',
      fieldName: t('Custom'),
      responseType: 'TEXT',
      required: 'WHEN_SIGN_UP',
      options: [],
    },
  ];
};

const getGenderSwaggerOptions = (lang: Swagger.SourceLanguage) => {
  switch (lang) {
    case 'JA_JP':
      return [
        {
          key: 'NO_RESPONSE',
          text: '未回答',
        },
        {
          key: 'MALE',
          text: '男性',
        },
        {
          key: 'FEMALE',
          text: '女性',
        },
      ];
    default:
      return [
        {
          key: 'NO_RESPONSE',
          text: 'No Response',
        },
        {
          key: 'MALE',
          text: 'Male',
        },
        {
          key: 'FEMALE',
          text: 'Female',
        },
      ];
  }
};
