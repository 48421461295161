import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useEffect } from 'react';

import { calculateRebookFromReservationFares } from 'client/actions/fares';
import { separatedCurrency as currency } from 'shared/libraries/currency';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { ReduxState } from 'client/reducers';
import type { NewReservation, Reservation } from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  rebookFromReservation: Reservation;
};

export const AmountDifference = ({ rebookFromReservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );
  const rebookFromReservationBillingInfo = useSelector(
    (state: ReduxState) => state.fares.rebookFromReservationBillingInfo
  );

  useEffect(() => {
    if (!rebookFromReservation?.billing_info?.amount_gross) {
      dispatch(
        calculateRebookFromReservationFares({
          ...(rebookFromReservation as any as NewReservation),
          agent_reference: 'dummy-reference-amount-difference',
        })
      );
    }
  }, [rebookFromReservation]);

  if (
    !billingInfo ||
    !(
      rebookFromReservation.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo
    )
  ) {
    return null;
  }

  const totalAfter = currency(billingInfo?.amount_gross ?? '').format();
  const totalBefore = currency(
    rebookFromReservation?.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo?.amount_gross ||
      ''
  ).format();

  if (totalAfter == totalBefore) {
    return null;
  }

  const TableContent = () => {
    return (
      <div>
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        >
          <thead>
            <tr>
              <th style={{ width: 'calc(100% / 3)' }}>{t('Total before')}</th>
              <th style={{ width: 'calc(100% / 3)' }}>{t('Total after')}</th>
              <th style={{ width: 'calc(100% / 3)' }}>{t('Difference')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-text={t('Total before')}>{totalBefore}</td>
              <td data-text={t('Total after')}>{totalAfter}</td>
              <td data-text={t('Difference')}>
                {currency(totalAfter).subtract(totalBefore).format()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <FieldWrapper
      label={t('Total Gross Difference before & after the change')}
      wrapperClassName="p-application__item"
    >
      <TableContent />
    </FieldWrapper>
  );
};
