import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_WAIT_TIME_FAILURE,
  CREATE_WAIT_TIME_REQUEST,
  CREATE_WAIT_TIME_SUCCESS,
  DELETE_WAIT_TIME_FAILURE,
  DELETE_WAIT_TIME_REQUEST,
  DELETE_WAIT_TIME_SUCCESS,
  FETCH_WAIT_TIMES_FAILURE,
  FETCH_WAIT_TIMES_REQUEST,
  FETCH_WAIT_TIMES_SUCCESS,
  FETCH_WAIT_TIME_FAILURE,
  FETCH_WAIT_TIME_REQUEST,
  FETCH_WAIT_TIME_SUCCESS,
  UPDATE_WAIT_TIME_FAILURE,
  UPDATE_WAIT_TIME_REQUEST,
  UPDATE_WAIT_TIME_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  WaitTime,
  CreateWaitTimeResponse,
  ListWaitTimesResponse,
  UpdateWaitTimeResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_WAIT_TIMES_FAILURE:
    case FETCH_WAIT_TIME_FAILURE:
    case CREATE_WAIT_TIME_FAILURE:
    case UPDATE_WAIT_TIME_FAILURE:
    case DELETE_WAIT_TIME_FAILURE:
      return action.error;

    case FETCH_WAIT_TIMES_SUCCESS:
    case FETCH_WAIT_TIME_SUCCESS:
    case CREATE_WAIT_TIME_SUCCESS:
    case UPDATE_WAIT_TIME_SUCCESS:
    case DELETE_WAIT_TIME_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_WAIT_TIMES_REQUEST:
    case FETCH_WAIT_TIME_REQUEST:
    case CREATE_WAIT_TIME_REQUEST:
    case UPDATE_WAIT_TIME_REQUEST:
    case DELETE_WAIT_TIME_REQUEST:
      return true;

    case FETCH_WAIT_TIMES_FAILURE:
    case FETCH_WAIT_TIME_FAILURE:
    case CREATE_WAIT_TIME_FAILURE:
    case UPDATE_WAIT_TIME_FAILURE:
    case DELETE_WAIT_TIME_FAILURE:
    case FETCH_WAIT_TIMES_SUCCESS:
    case FETCH_WAIT_TIME_SUCCESS:
    case CREATE_WAIT_TIME_SUCCESS:
    case UPDATE_WAIT_TIME_SUCCESS:
    case DELETE_WAIT_TIME_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: WaitTime[] = [], action: any): WaitTime[] => {
  switch (action.type) {
    case FETCH_WAIT_TIMES_SUCCESS: {
      const response = action.response as ListWaitTimesResponse;
      return response.wait_times;
    }

    case FETCH_WAIT_TIME_SUCCESS: {
      const response = action.response as WaitTime;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_WAIT_TIME_SUCCESS: {
      const response = action.response as CreateWaitTimeResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_WAIT_TIME_SUCCESS: {
      const response = action.response as UpdateWaitTimeResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_WAIT_TIME_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const waitTimes = combineReducers({
  error,
  loading,
  all,
});
