import type { Organization, SubscriptionKey } from 'shared/models/swagger';

export const hasSubscription = (
  organization: Organization | null,
  key: SubscriptionKey
): boolean => {
  return (
    organization?.subscriptions?.some(
      (subscription) => subscription.key === key
    ) ?? false
  );
};
export const isSubscriptionCancelled = (
  organization: Organization | null,
  key: SubscriptionKey
): boolean => {
  return (
    organization?.subscriptions?.some(
      (subscription) =>
        subscription.key === key && subscription.status === 'CANCELED'
    ) ?? false
  );
};
