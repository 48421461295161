import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//import { v4 as uuidv4 } from 'uuid';
//import _ from 'lodash';

//import { getArrayMutators } from 'client/libraries/util/form';
//import { customPagesSelector } from 'client/reducers/customPages';
import { TextField } from 'client/components/v3/Form/TextField';
//import { getValidators } from 'shared/libraries/validate/validator';
import { Toggle } from 'client/components/v3/Form/Toggle';
//import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { getLanguageName } from 'client/libraries/i18n';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { getFooterLinkFormValues } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import type {
  CustomizeFormValues,
  //FooterLink,
} from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import { SourceLanguage } from 'shared/models/swagger';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

export const FooterEditor = () => {
  const { t } = useTranslation();
  //const [showEditFooterLinkModal, setShowEditFooterLinkModal] =
  //  React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    label: getLanguageName(lang, t),
  }));
  const parentFormState = useFormState<CustomizeFormValues>();
  const parentForm = useForm<CustomizeFormValues>();

  //const parentFormSaveFooterLink = (newFooterLink: FooterLink) => {
  //  const existingFooterLinks = parentFormState.values
  //    ?.footerLinks as FooterLink[];
  //  parentForm.change(
  //    'footerLinks',
  //    existingFooterLinks?.some(
  //      (footerLink) => footerLink.key === newFooterLink.key
  //    )
  //      ? existingFooterLinks?.map((existingFooterLink) =>
  //          existingFooterLink.key === newFooterLink.key
  //            ? newFooterLink
  //            : existingFooterLink
  //        )
  //      : [...(existingFooterLinks ?? []), newFooterLink]
  //  );
  //};

  const language = parentFormState.values?.footerLinkLanguage || 'JA_JP';
  const dispatch = useDispatch();
  console.log(language);
  console.log(dispatch);
  return (
    <>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <div>{t('Footer')}</div>
        <div>{t('Content Language')}</div>
        <EnumRadioButtonGroup
          name="footerLinkLanguage"
          options={languageOptions}
          onChange={(value) => {
            parentForm.change(
              'footerLinks',
              getFooterLinkFormValues(
                activeUserOrganization,
                value as SourceLanguage
              )
            );
          }}
        />
      </div>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <div className={styles['p-bookingWidgetCustomize-box']}>
          <div className={styles['p-bookingWidgetCustomize-box__list']}>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <ul className={styles['p-bookingWidgetCustomize-list-trash']}>
                <FieldArray name="footerLinks">
                  {({ fields }) => (
                    <>
                      {fields.map((name, idx) => (
                        <FooterItem key={idx} name={name} />
                      ))}
                    </>
                  )}
                </FieldArray>
              </ul>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <a className={styles['p-bookingWidgetCustomize-box__table__add']}>
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Footer')}
              </a>
            </div>
          </div>
        </div>
      </div>
      {parentForm.getState().values?.footerLinkLanguage === 'JA_JP' && (
        <div
          className={
            styles['p-bookingWidgetCustomize-products__section__body__item']
          }
        >
          <Field name="showFooterJapaneseTermsLink" type="checkbox">
            {({ input }) => {
              return (
                <Toggle
                  checked={input.checked ?? false}
                  onChange={() => input.onChange(!input.checked)}
                  label={
                    <div>
                      <div>
                        {t(
                          'Show the default "特定商取引法" link on Japanese website.'
                        )}
                      </div>
                      <div>
                        {t(
                          'Note: It is required to show 特定商取引法 page by Japanese law. If you want to hide the default link, please add a link to equivalent page of your organization or create a page using Homepage feature.'
                        )}
                      </div>
                    </div>
                  }
                />
              );
            }}
          </Field>
        </div>
      )}
    </>
  );
};

type FooterItemProps = {
  name: string;
  key: number;
};
const FooterItem = ({ name, key }: FooterItemProps) => {
  const { t } = useTranslation();
  //const dispatch = useDispatch();
  const form = useForm();
  const formState = useFormState();
  console.log('name', name);
  console.log('form', form);
  console.log('formState', formState);
  console.log(key);
  //const { required } = getValidators(t);
  //const customPages = useSelector(customPagesSelector);

  //const initialValuesFromForm = _.get(formState.values, name);

  //const initialValues = initialValuesFromForm
  //  ? initialValuesFromForm
  //  : {
  //      text: '',
  //      url: '',
  //      customPagePath: '/about',
  //      key: uuidv4(),
  //      linkType: 'CUSTOM_PAGE_PATH',
  //    };
  const linkTypeOptions = [
    {
      key: 'CUSTOM_PAGE_PATH',
      value: 'CUSTOM_PAGE_PATH',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'URL',
      value: 'URL',
      label: t('Enter URL directly'),
    },
  ];
  //const customPagePathOptions = [
  //  {
  //    text: t('About Us'),
  //    key: '/about',
  //    value: '/about',
  //  },
  //  {
  //    text: t('FAQ'),
  //    key: '/faq',
  //    value: '/faq',
  //  },
  //  {
  //    text: t('COVID-19 Guidelines'),
  //    key: '/covid19',
  //    value: '/covid19',
  //  },
  //  ...customPages.map((customPage) => ({
  //    text: customPage.title,
  //    key: `/article/${customPage.path}`,
  //    value: `/article/${customPage.path}`,
  //  })),
  //];

  return (
    <li className={styles['p-bookingWidgetCustomize-list-trash__item']}>
      <div className={styles['p-bookingWidgetCustomize-list-trash__box']}>
        <div className={styles['p-bookingWidgetCustomize-box-wh']}>
          <div className={styles['p-bookingWidgetCustomize-box-wh__header']}>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__header__ic']}
            >
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box-wh__body']}>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              <TextField
                label={t('Title')}
                placeholder={t('Input Title')}
                onChange={() => console.log('test')}
                value={'test'}
              />
            </div>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              <dl className={styles['p-bookingWidgetCustomize-box-wh__list']}>
                <dt
                  className={
                    styles['p-bookingWidgetCustomize-box-wh__list__term']
                  }
                >
                  <span>{t('Page to open')}</span>
                </dt>
                <EnumRadioButtonGroup
                  name="footerLinkLanguage"
                  options={linkTypeOptions}
                  onChange={(value) => {
                    if (value === 'CUSTOM_PAGE_PATH') {
                      form.change('customPagePath', '/about');
                      form.change('url', '');
                    } else {
                      form.change('customPagePath', '');
                      form.change('url', '');
                    }
                  }}
                />
              </dl>
            </div>
            <div
              className={styles['p-bookingWidgetCustomize-box-wh__body__item']}
            >
              {/*
                {values?.linkType === 'CUSTOM_PAGE_PATH' && (
                  <Field name="customPagePath">
                    {({ input, meta: { touched, error } }) => (
                      <SingleDropdown
                        options={customPagePathOptions}
                        error={touched && error}
                        selectedOption={input.value}
                        onChange={(value) => input.onChange(value)}
                      />
                    )}
                  </Field>
                )}
                {values?.linkType === 'URL' && (
                  <Field name="url">
                    {({ input, meta: { touched, error } }) => (
                      <TextField error={touched && error} {...input} />
                    )}
                  </Field>
                )}
              */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['p-bookingWidgetCustomize-list-trash__btn']}>
        <a className={styles['c-button-bg-sm-tertiarygray']}>
          <i className={styles['c-icon-outline-general-trash-03']}></i>
        </a>
      </div>
    </li>
  );
};
