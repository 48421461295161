import uuidv5 from 'uuid/v5';

import type { ResourceType } from 'client/libraries/util/resourceType';

const MANIFEST_START_TIME_BASE_UUID = '11111111-1111-1111-1111-111111111111';
export const getManifestStartTimeMemoKey = (
  resourceType: ResourceType,
  resourceKey: string,
  date: string,
  time: string
): string => {
  return uuidv5(
    `${resourceType}-${resourceKey}-${date}-${time}`,
    MANIFEST_START_TIME_BASE_UUID
  );
};
