import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import * as Swagger from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateTopPageAdditionalImage =
  (
    additionalImage: Swagger.TopPageAdditionalImage,
    language: Swagger.SourceLanguage
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForAdditionalImage(
          organization,
          additionalImage,
          language
        )
      )
    );
  };
export const updateTopPageSection =
  (
    newTopPageSection: Swagger.TopPageSection$Patch,
    language: Swagger.SourceLanguage
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForTopPageSection(
          organization,
          newTopPageSection,
          language
        )
      )
    );
  };
export const deleteAdditionalImage =
  (key: string, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    const existingAdditionalImages =
      organization?.booking_widget_design_params?.top_page_custom_layouts
        ?.find((param) => param.language === language)
        ?.sections?.find(
          (section) => section.section_type === 'ADDITIONAL_IMAGES_SECTION'
        )?.additional_images ?? [];
    const newAdditionalImages = existingAdditionalImages.filter(
      (image) => image.key !== key
    );
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForTopPageSection(
          organization,
          {
            key: 'ADDITIONAL_IMAGES_SECTION',
            section_type: 'ADDITIONAL_IMAGES_SECTION',
            additional_images: newAdditionalImages,
          },
          language
        )
      )
    );
  };
export const deleteTopPageSection =
  (key: string, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    const existingTopPageLayouts =
      organization?.booking_widget_design_params?.top_page_custom_layouts ?? [];
    const existingTopPageSections =
      organization?.booking_widget_design_params?.top_page_custom_layouts?.find(
        (param) => param.language === language
      )?.sections ?? [];
    return dispatch(
      updateActiveUserOrganization({
        booking_widget_design_params: {
          ...organization?.booking_widget_design_params,
          top_page_custom_layouts: existingTopPageLayouts.map(
            (existingLayout) =>
              existingLayout.language === language
                ? {
                    ...existingLayout,
                    sections: existingTopPageSections.filter(
                      (section) => section.key !== key
                    ),
                  }
                : existingLayout
          ),
        },
      })
    );
  };

const getOrganizationPatchForTopPageSection = (
  organization: Swagger.Organization | null,
  newTopPageSectionPatch: Swagger.TopPageSection$Patch,
  language: Swagger.SourceLanguage
): Swagger.Organization$Patch => {
  const existingTopPageCustomLayouts =
    organization?.booking_widget_design_params?.top_page_custom_layouts ?? [];
  const existingTopPageSections =
    existingTopPageCustomLayouts.find((param) => param.language === language)
      ?.sections ?? [];
  const patch: Swagger.BookingWidgetTopPageCustomLayout = {
    language,
    sections: existingTopPageSections.some(
      (existingSection) => existingSection.key === newTopPageSectionPatch.key
    )
      ? existingTopPageSections.map((existingSection) =>
          existingSection.key === newTopPageSectionPatch.key
            ? {
                // Merge existing attributes and overwrite new attributes from patch
                ...existingSection,
                ...newTopPageSectionPatch,
              }
            : existingSection
        )
      : [...existingTopPageSections, newTopPageSectionPatch],
  };
  const topPageCustomLayouts = [
    ...existingTopPageCustomLayouts.filter(
      (params) => params.language !== patch.language
    ),
    patch,
  ];
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      top_page_custom_layouts: topPageCustomLayouts,
    },
  };
};

const getOrganizationPatchForAdditionalImage = (
  organization: Swagger.Organization | null,
  additionalImage: Swagger.TopPageAdditionalImage,
  language: Swagger.SourceLanguage
): Swagger.Organization$Patch => {
  const existingAdditionalImages =
    organization?.booking_widget_design_params?.top_page_custom_layouts
      ?.find((param) => param.language === language)
      ?.sections?.find(
        (section) => section.section_type === 'ADDITIONAL_IMAGES_SECTION'
      )?.additional_images ?? [];
  const newAdditionalImages = existingAdditionalImages.some(
    (image) => image.key === additionalImage.key
  )
    ? existingAdditionalImages.map((existingImage) =>
        existingImage.key === additionalImage.key
          ? additionalImage
          : existingImage
      )
    : [...existingAdditionalImages, additionalImage];
  return getOrganizationPatchForTopPageSection(
    organization,
    {
      key: 'ADDITIONAL_IMAGES_SECTION',
      section_type: 'ADDITIONAL_IMAGES_SECTION',
      additional_images: newAdditionalImages,
    },
    language
  );
};
