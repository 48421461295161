// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Confirm } from 'semantic-ui-react';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { deleteAccount } from 'client/actions/accounts';
import type { Account } from 'shared/models/swagger';

type OwnProps = {
  account: Account,
  trigger: React.ComponentType<{}>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
};

class deleteAccountModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    const { trigger: Trigger, account, t } = this.props;

    return account ? (
      <Confirm
        header={t('Delete Account')}
        content={t(
          'Are you sure you want to delete the account for "{{accountName}}"?',
          {
            accountName: account.name,
          }
        )}
        trigger={
          <span
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          >
            <Trigger />
          </span>
        }
        open={this.state.showModal}
        onCancel={() =>
          this.setState({
            showModal: false,
          })
        }
        onConfirm={() => this.props.deleteAccount(account.id)}
        cancelButton={t('Cancel')}
        confirmButton={t('OK')}
      />
    ) : null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  deleteAccount: (id: string) => dispatch(deleteAccount(id)),
});

export const DeleteAccountModal = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(deleteAccountModal);
