import { useTranslation } from 'react-i18next';

import { TranslateFuncType } from 'client/components/Translate';
import { FieldWrapper } from 'client/components/Form';
import {
  SurveyTemplate,
  SurveyTemplatePage,
  SurveyQuestion,
} from 'shared/models/swagger';

const getFormattedAgeCondition = (
  presetConditions: string[],
  t: TranslateFuncType
) => {
  const agePresets = [
    {
      value: 'AGE_20s',
      text: t('20s'),
    },
    {
      value: 'AGE_30s',
      text: t('30s'),
    },
    {
      value: 'AGE_40s',
      text: t('40s'),
    },
    {
      value: 'AGE_50s',
      text: t('50s'),
    },
    {
      value: 'AGE_60_plus',
      text: t('60+'),
    },
  ];

  return agePresets
    .filter((presetOption) =>
      presetConditions.some((condition) => presetOption.value === condition)
    )
    .map((preset) => preset.text)
    .join(', ');
};

const getFormattedGenderCondition = (
  presetConditions: string[],
  t: TranslateFuncType
): string => {
  const genderPresets = [
    {
      value: 'GENDER_FEMALE',
      text: t('Female'),
    },
    {
      value: 'GENDER_MALE',
      text: t('Male'),
    },
    {
      value: 'GENDER_OTHER',
      text: t('Other'),
    },
  ];

  return genderPresets
    .filter((presetOption) =>
      presetConditions.some((condition) => presetOption.value === condition)
    )
    .map((preset) => preset.text)
    .join(', ');
};

const findQuestion = (
  template: SurveyTemplate,
  questionKey: string
): SurveyQuestion | null => {
  for (const page of template.pages ?? []) {
    for (const question of page.questions ?? []) {
      if (question.key === questionKey) {
        return question;
      }
    }
  }

  return null;
};

interface Props {
  template: SurveyTemplate;
  page: SurveyTemplatePage;
}

export const PageConditions = ({ template, page }: Props) => {
  const { t } = useTranslation();

  const ageCondition = getFormattedAgeCondition(
    page.preset_conditions ?? [],
    t
  );
  const genderCondition = getFormattedGenderCondition(
    page.preset_conditions ?? [],
    t
  );
  const customConditions = page.custom_conditions?.map((condition) => {
    const question = findQuestion(
      template,
      condition.custom_question_key ?? ''
    );

    return `${question?.question_text ?? ''}: ${(
      condition.custom_question_responses ?? []
    )
      .map(
        (response) =>
          question?.response_options?.find((option) => option.key === response)
            ?.text ?? ''
      )
      .join(', ')}`;
  });

  if (!ageCondition && !genderCondition && !customConditions?.length) {
    return null;
  }

  return (
    <FieldWrapper label={t('Conditions')}>
      <ul>
        {ageCondition && <li>{`${t('Age')}: ${ageCondition}`}</li>}
        {genderCondition && <li>{`${t('Gender')}: ${genderCondition}`}</li>}
        {customConditions?.map((condition, idx) => (
          <li key={idx}>{condition}</li>
        ))}
      </ul>
    </FieldWrapper>
  );
};
