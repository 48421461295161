import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import clsx from 'clsx';

import { PARTICIPANT_UNLIMITED } from 'client/constants/participantUnlimited';
import { Select, ToggleButton, FieldWrapper } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { getGuestTypeKeysUsedInProduct } from 'client/libraries/util/util';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

import { FormValues } from './BookingWidgetSettingsFormValues';

const range = (start: number, stop: number): number[] => {
  return [...Array(stop - start + 1).keys()].map((item) => item + start);
};

export const BookingWidgetSelectableParticipantRulesEditor = () => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const formState = useFormState<FormValues>();
  let units: string[] = [];
  const values = formState.values;

  if (product) {
    units = getGuestTypeKeysUsedInProduct(product);
  }

  const unitOptions = units.map((unit) => {
    return {
      value: unit,
      text: unit,
    };
  });

  const isFormDisabled = (values: FormValues, unit: string): boolean => {
    if (values?.isSelectableParticipantRuleActive) {
      const found = (values?.selectableParticipantRules || []).find((rule) => {
        return (rule?.restrictedUnits || []).find(
          (restrictedUnit) => restrictedUnit === unit
        );
      });

      if (found) {
        return true;
      }
    }

    return false;
  };

  const getDefaultUnit = (values: any): string => {
    if (
      values &&
      values.minimumMaximumParticipantRules &&
      values.minimumMaximumParticipantRules.length > 0
    ) {
      return values.minimumMaximumParticipantRules[0].unit;
    }

    return '';
  };

  return (
    <>
      <FieldWrapper label={t('Minimum and maximum participants per booking')} />
      <Field name="isPerBookingParticipantRuleActive">
        {({ input }) => (
          <>
            <div className={baseStyles['inline-block']}>
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                <ToggleButton
                  label=""
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              </div>
              <div>
                {t('Set minimum and maximum participants for one booking')}
              </div>
            </div>
            {input.value && (
              <div className={styles['c-tableChild']}>
                <ul>
                  <li className={baseStyles['base-t-240']}></li>
                  <li className={baseStyles['base-t-160']}>
                    {t('Minimum Selectable Participants')}
                  </li>
                  <li className={baseStyles['base-t-160']}>
                    {t('Maximum Selectable Participants')}
                  </li>
                </ul>
                <ul>
                  <li></li>
                  <li data-title={t('Minimum Selectable Participants')}>
                    <Field name="perBookingParticipantRule.minimumParticipants">
                      {({ input }) => (
                        <Select
                          options={range(1, 100).map((x) => ({
                            value: x as any,
                            text: `${x}`,
                          }))}
                          value={input.value}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </li>
                  <li data-title={t('Maximum Selectable Participants')}>
                    <Field name="perBookingParticipantRule.maximumParticipants">
                      {({ input }) => (
                        <Select
                          options={range(0, 100).map((x) => ({
                            value: x === 0 ? t('No limit') : (x as any),
                            text: x === 0 ? t('No limit') : `${x}`,
                          }))}
                          value={
                            input.value === 0 ? t('No limit') : input.value
                          }
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </Field>
      <FieldWrapper label={t('Number of selectable participants per unit')} />
      <div className={styles['c-tableChild']}>
        <ul>
          <li className={baseStyles['base-t-240']}>{t('Unit')}</li>
          <li className={baseStyles['base-t-160']}>
            {t('Minimum Selectable Participants')}
          </li>
          <li className={baseStyles['base-t-160']}>
            {t('Maximum Selectable Participants')}
          </li>
        </ul>
        <FieldArray name="minimumMaximumParticipantRules">
          {({ fields }) =>
            fields.map((name) => (
              <Field key={name} name={name}>
                {({ input: unitInput }) => (
                  <ul>
                    <li data-title={t('Unit')}>
                      {unitInput.value.unit === 'guest'
                        ? t('Guest')
                        : unitInput.value.unit}
                    </li>
                    <li data-title={t('Minimum Selectable Participants')}>
                      <Field name={`${name}.minimumParticipants`}>
                        {({ input }) => (
                          <Select
                            options={range(0, 100).map((x) => ({
                              value: x as any,
                              text: `${x}`,
                            }))}
                            value={input.value}
                            onChange={(e, { value }) => {
                              input.onChange(value);
                            }}
                            disabled={isFormDisabled(
                              values as any,
                              unitInput.value.unit
                            )}
                          />
                        )}
                      </Field>
                    </li>
                    <li data-title={t('Maximum Selectable Participants')}>
                      <Field name={`${name}.maximumParticipants`}>
                        {({ input }) => (
                          <Select
                            options={[
                              {
                                value: PARTICIPANT_UNLIMITED,
                                text: t('No limit'),
                              },
                              ...range(1, 100).map((x) => ({
                                value: x as any,
                                text: `${x}`,
                              })),
                            ]}
                            value={
                              input.value === PARTICIPANT_UNLIMITED
                                ? t('No limit')
                                : input.value
                            }
                            onChange={(e, { value }) => {
                              input.onChange(value);
                            }}
                            disabled={isFormDisabled(
                              values as any,
                              unitInput.value.unit
                            )}
                          />
                        )}
                      </Field>
                    </li>
                  </ul>
                )}
              </Field>
            ))
          }
        </FieldArray>
      </div>
      <FieldWrapper label={t('Participant Rule')} />
      <Field name={'isSelectableParticipantRuleActive'}>
        {({ input }) => (
          <>
            <div className={baseStyles['inline-block']}>
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                <ToggleButton
                  label=""
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              </div>
              <div>
                {t('Set maximum selectable participants based on other units')}
                <br />
                {t('Example: maximum of 1 infant per 1 adult')}
              </div>
            </div>
            {input.value && (
              <FieldArray name="selectableParticipantRules">
                {({ fields }) => {
                  return (
                    <>
                      <div className={clsx(componentStyles['c-table-nowrap'])}>
                        <table>
                          <tbody>
                            <tr>
                              <th className={clsx(baseStyles['base-t-192'])}>
                                {t('Required unit')}
                              </th>
                              <th>
                                {t('Restricted unit maximum participants')}{' '}
                              </th>
                              <th
                                className={clsx(baseStyles['base-t-96'])}
                              ></th>
                            </tr>
                            {fields.map((name, idx) => (
                              <tr key={idx}>
                                <td>
                                  <FieldArray name={`${name}.requiredUnits`}>
                                    {({ fields }) => {
                                      return (fields || ['']).map(
                                        (name, idx) => (
                                          <Field name={name} key={idx}>
                                            {({ input }) => (
                                              <div
                                                className={
                                                  baseStyles['base-flex']
                                                }
                                              >
                                                <Select
                                                  options={unitOptions}
                                                  value={input.value}
                                                  onChange={(e, { value }) =>
                                                    input.onChange(value)
                                                  }
                                                  width={96}
                                                />
                                                <AddIcon
                                                  onClick={() =>
                                                    (fields as any).insertAt(
                                                      idx + 1,
                                                      getDefaultUnit(
                                                        values as any
                                                      )
                                                    )
                                                  }
                                                />
                                                {idx !== 0 && (
                                                  <DeleteIcon
                                                    onClick={() =>
                                                      fields.remove(idx)
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        )
                                      );
                                    }}
                                  </FieldArray>
                                </td>
                                <td>
                                  <div className={baseStyles['base-flex']}>
                                    <div>
                                      <FieldArray
                                        name={`${name}.restrictedUnits`}
                                      >
                                        {({ fields }) => {
                                          return (fields || ['']).map(
                                            (name, idx) => (
                                              <Field name={name} key={idx}>
                                                {({ input }) => (
                                                  <div
                                                    className={
                                                      baseStyles['base-flex']
                                                    }
                                                  >
                                                    <Select
                                                      options={unitOptions}
                                                      value={input.value}
                                                      onChange={(
                                                        e,
                                                        { value }
                                                      ) => {
                                                        input.onChange(value);
                                                      }}
                                                      width={96}
                                                    />
                                                    <AddIcon
                                                      onClick={() =>
                                                        (
                                                          fields as any
                                                        ).insertAt(
                                                          idx + 1,
                                                          getDefaultUnit(
                                                            values as any
                                                          )
                                                        )
                                                      }
                                                    />
                                                    {idx !== 0 && (
                                                      <DeleteIcon
                                                        onClick={() =>
                                                          fields.remove(idx)
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            )
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: 'auto',
                                      }}
                                    >
                                      <Field
                                        name={`${name}.maximumParticipants`}
                                      >
                                        {({ input }) => (
                                          <Select
                                            options={range(0, 100).map((x) => ({
                                              value: x as any,
                                              text: `${x}`,
                                            }))}
                                            value={input.value}
                                            onChange={(e, { value }) => {
                                              input.onChange(value);
                                            }}
                                            width={64}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <AddIcon
                                    onClick={() =>
                                      (fields as any).insertAt(idx + 1, {
                                        requiredUnits: [
                                          getDefaultUnit(values as any),
                                        ],
                                        restrictedUnits: [
                                          getDefaultUnit(values as any),
                                        ],
                                        maximumParticipants: 1,
                                      })
                                    }
                                  />
                                  {idx !== 0 && (
                                    <DeleteIcon
                                      onClick={() => fields.remove(idx)}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <ul>
                          {fields.value.map((value, idx) => {
                            return (
                              <li key={idx}>
                                {t(
                                  'Rule {{idx}} Maximum of {{max}} {{restrictedUnits}} per 1 {{requiredUnits}}',
                                  {
                                    idx: idx + 1,
                                    max: value.maximumParticipants,
                                    restrictedUnits:
                                      value.restrictedUnits.join('/'),
                                    requiredUnits:
                                      value.requiredUnits.join('/'),
                                  }
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                }}
              </FieldArray>
            )}
          </>
        )}
      </Field>
    </>
  );
};
