import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { Box } from 'client/components/Box/Box';
import type { AgentSystemFeeInvoiceSummary } from 'shared/models/swagger';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import {
  SuppliersGrossCell,
  SuppliersCancellationCell,
  SuppliersCommissionCell,
} from 'client/components/PartnershipSystemFee/PartnershipSystemFee';

import styles from './PartnershipAgentSystemFeeInvoice.module.css';

interface Props {
  agentSystemFeeInvoice: AgentSystemFeeInvoiceSummary;
}

export const PartnershipAgentSystemFeeInvoice = ({
  agentSystemFeeInvoice,
}: Props) => {
  const { t } = useTranslation();
  const currency = useSelector(defaultProductCurrencySelector);

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>

      <div className={styles.summaryTable}>
        <table>
          <tbody>
            <tr>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross')}
                </Box>
              </th>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross by supplier')}
                </Box>
              </th>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee')}
                </Box>
              </th>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee by supplier')}
                </Box>
              </th>

              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Commission')}
                </Box>
              </th>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Commission by supplier')}
                </Box>
              </th>

              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Booking Fee')}
                </Box>
              </th>

              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Credit Card Fee')}
                </Box>
              </th>

              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Billing Amount')}
                </Box>
              </th>
            </tr>
            <tr>
              <td>
                {getFormattedAmount(agentSystemFeeInvoice.pif_gross_amount)}
              </td>
              <td>
                <SuppliersGrossCell invoice={agentSystemFeeInvoice} />
              </td>
              <td>
                {getFormattedAmount(
                  agentSystemFeeInvoice.pif_cancellation_fee_amount
                )}
              </td>
              <td>
                <SuppliersCancellationCell invoice={agentSystemFeeInvoice} />
              </td>
              <td>
                {getFormattedAmount(
                  agentSystemFeeInvoice.pif_commission_amount
                )}
              </td>
              <td>
                <SuppliersCommissionCell invoice={agentSystemFeeInvoice} />
              </td>
              <td>
                {getFormattedAmount(
                  agentSystemFeeInvoice.pif_booking_fee_amount
                )}
              </td>
              <td>
                {getFormattedAmount(
                  agentSystemFeeInvoice.pif_credit_card_fee_amount
                )}
              </td>

              <td>
                {getFormattedAmount(
                  agentSystemFeeInvoice.amount_due_by_nutmeg_final
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
