import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { config } from 'client/config';

import styles from './InstallEmbedCodeModal.module.css';

type Props = {
  onClose: () => void;
};

export const InstallEmbedCodeModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const organization = useSelector(activeUserOrganizationSelector);
  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';

  const handleCopy = () => {
    navigator.clipboard
      .writeText(scriptCode)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        //
      });
  };

  const scriptCode = `<link href="https://assets.ntmg.com/scripts/site-control/v1/index.css" rel="stylesheet">
<script type="text/javascript">
  /*<![CDATA[*/
  (function () {
      if (window.NutmegSiteControlWidget) {
        NutmegSiteControlWidgetInit();
      } else {
        loadScriptAsync();
      }
      function loadScriptAsync() {
        var script = document.createElement('script');
        script.src = "https://assets.ntmg.com/scripts/site-control/v1/index.js";
        script.async = true;
        (document.getElementsByTagName('head')[ 0 ] || document.getElementsByTagName('body')[ 0 ]).appendChild(script);
        script.onload = NutmegSiteControlWidgetInit;
      }
      function NutmegSiteControlWidgetInit() {
        window.NutmegSiteControlWidget.init("${bookingWidgetApiKey}", "${config.nutmegExternalApiBaseUrl}");
      }
    })();
  /*]]>*/
</script>
`;

  return (
    <>
      <Modal
        title={t('Install Embed Code')}
        open={true}
        onClose={onClose}
        rightActionChildren={<Button text={t('Close')} onClick={onClose} />}
        insertAtRoot={true}
        style={{ width: '600px' }}
      >
        <div className={styles['p-installModal']}>
          <div className={styles['p-installModal__item']}>
            <div className={styles['p-installModal__item__ttl']}>
              <p>{t('Embed Code')}</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {copied && (
                  <div style={{ marginRight: '8px', color: '#059669' }}>
                    {t('Copied!')}
                  </div>
                )}
                <Button text={t('Copy to clipboard')} onClick={handleCopy} />
              </div>
            </div>
            <div className={styles['p-installModal__item__body']}>
              <pre className={styles['p-installModal__codeBlock']}>
                <code>{scriptCode}</code>
              </pre>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
