import { FormValues } from 'client/libraries/util/resourceManager';
import type { GuideSettings, GuideSettings$Patch } from 'shared/models/swagger';

export const getInitialValues = (
  resource: GuideSettings | null
): FormValues => {
  return {
    availabilitySchedules: (resource?.availability?.schedules ?? []).map(
      (schedule) => ({
        startDateLocal: schedule.start_date_local ?? '',
        endDateLocal: schedule.end_date_local ?? '',
        productCapacities: (schedule.product_capacities ?? []).map(
          (product_capacities) => ({
            productId: product_capacities.product_id,
            capacity: product_capacities.capacity,
          })
        ),
      })
    ),
  };
};

export const convertFormValuesToDispatchResources = (
  values: FormValues,
  guideSettings: GuideSettings | null
): GuideSettings$Patch => {
  const availability = {
    schedules: values.availabilitySchedules?.map((schedule) => ({
      start_date_local: schedule.startDateLocal,
      end_date_local: schedule.endDateLocal,
      product_capacities: schedule.productCapacities?.map(
        (productCapacity) => ({
          product_id: productCapacity.productId,
          capacity: Number(productCapacity.capacity),
        })
      ),
    })),
  };

  guideSettings = {
    ...guideSettings,
    availability,
  };

  return guideSettings;
};
