import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { getColumns } from 'client/pages/v3/Manifest/ManifestDaily/Util/util';
import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import {
  getAssignedResources,
  buildPatch,
} from 'client/components/ResourceAssignmentModal/resources';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/v3/Table/CustomTable';
import { ManifestTableHeaderCell } from 'client/pages/Manifest/ManifestTableHeaderCell';
import type { CustomTableColumn } from 'client/components/v3/Table/CustomTable';
import { Button } from 'client/components/v3/Common/Button';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import {
  activeUserOrganizationSelector,
  accountsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import type {
  Product,
  ReservationColumn,
  ReservationPatch,
  ManifestCustomizedColumnName,
} from 'shared/models/swagger';
import { RouteAssignmentModal } from 'client/pages/v3/Manifest/ManifestResourceAssignment/Modal/RouteAssignmentModal';

const EditRouteButton = ({
  reservation,
  editingResourceType,
  editingResourceKey,
}: {
  editingResourceType: ResourceType;
  editingResourceKey: string;
  reservation: ManifestReservationShape;
}) => {
  const { t } = useTranslation();
  const [showRouteModal, setShowRouteModal] = useState(false);
  return (
    <>
      {showRouteModal && (
        <RouteAssignmentModal
          editingResourceType={editingResourceType}
          editingResourceKey={editingResourceKey}
          reservation={reservation}
          onClose={() => setShowRouteModal(false)}
        />
      )}
      <Button
        text={t('Edit Route Assignments')}
        color="primary"
        onClick={() => setShowRouteModal(true)}
      />
    </>
  );
};

type Props = {
  excludedFormFieldKeys: string[];
  updateRequests: {
    id: string;
    patch: ReservationPatch;
  }[];
  onUpdateRequestsChanged: (
    updateRequests: {
      id: string;
      patch: ReservationPatch;
    }[]
  ) => void;
  editingResourceType: ResourceType;
  editingResourceKey: string;
  products?: Product[];
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  readOnly?: boolean;
  hideResourceColumns?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
};

const defaultProducts: Product[] = [];

export const ResourceAssignmentReservationsTable = ({
  excludedFormFieldKeys,
  updateRequests,
  onUpdateRequestsChanged,
  editingResourceType,
  editingResourceKey,
  products = defaultProducts,
  reservations,
  visibleColumns,
  readOnly,
  hideResourceColumns,
  customizedColumnNames,
}: Props) => {
  const [columns, setColumns] = useState<CustomTableColumn[]>([]);
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.manifests.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const accounts = useSelector(accountsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );
  const rowIsChecked = useCallback(
    (reservation: ManifestReservationShape) => {
      const updateRequest = updateRequests.find(
        (req) => req.id === reservation.id
      );

      if (updateRequest) {
        const updateRequestResourceList = getAssignedResources(
          updateRequest.patch,
          editingResourceType
        );
        return updateRequestResourceList.indexOf(editingResourceKey) !== -1;
      }

      return (
        getAssignedResources(reservation, editingResourceType).indexOf(
          editingResourceKey
        ) !== -1
      );
    },
    [editingResourceKey, editingResourceType, updateRequests]
  );
  const handleRowCheckboxClicked = useCallback(
    (reservation: ManifestReservationShape) => {
      const updateRequest = updateRequests.find(
        (req) => req.id === reservation.id
      );

      if (updateRequest) {
        onUpdateRequestsChanged(
          updateRequests.filter((req) => req.id !== reservation.id)
        );
      } else {
        const currentAssignedResources = getAssignedResources(
          reservation,
          editingResourceType
        );
        let newUpdateRequest: {
          id: string;
          patch: ReservationPatch;
        };

        if (currentAssignedResources.indexOf(editingResourceKey) !== -1) {
          // Resource is already assigned and we want to remove it
          newUpdateRequest = {
            id: reservation.id,
            patch: buildPatch(
              editingResourceType,
              currentAssignedResources.filter(
                (resource) => resource !== editingResourceKey
              )
            ),
          };
        } else {
          // Resource has not been assigned yet and we want to add it
          newUpdateRequest = {
            id: reservation.id,
            patch: buildPatch(editingResourceType, [
              ...currentAssignedResources,
              editingResourceKey,
            ]),
          };
        }

        onUpdateRequestsChanged([...updateRequests, newUpdateRequest]);
      }
    },
    [
      editingResourceKey,
      editingResourceType,
      onUpdateRequestsChanged,
      updateRequests,
    ]
  );
  const getNewResourcesList = useCallback(
    (reservation: ManifestReservationShape): string[] => {
      const updateRequest = updateRequests.find(
        (req) => req.id === reservation.id
      );

      if (updateRequest) {
        return getAssignedResources(updateRequest.patch, editingResourceType);
      }

      return getAssignedResources(reservation, editingResourceType);
    },
    [editingResourceType, updateRequests]
  );
  useEffect(() => {
    let columns: CustomTableColumn[] = [];

    if (!readOnly) {
      columns = [
        {
          Header: '',
          width: 'short',
          Cell: (cellInfo: { original: ManifestReservationShape }) => (
            <Checkbox
              checked={rowIsChecked(cellInfo.original)}
              onChange={() => handleRowCheckboxClicked(cellInfo.original)}
            />
          ),
          th: true,
        },
      ];
    }

    if (!hideResourceColumns) {
      columns = [
        ...columns,
        ...[
          {
            Header: getColumnHeaderText('DISPATCH_VEHICLE', t),
            accessor: (row: ManifestReservationShape) => {
              if (editingResourceType === 'vehicle') {
                return getNewResourcesList(row).join(',');
              } else {
                return row.dispatch_vehicles && row.dispatch_vehicles.join(',');
              }
            },
          },
          {
            Header: getColumnHeaderText('DISPATCH_CREW', t),
            accessor: (row: ManifestReservationShape) => {
              if (editingResourceType === 'crew') {
                return getNewResourcesList(row).join(',');
              } else {
                return row.dispatch_crew && row.dispatch_crew.join(',');
              }
            },
          },
          {
            Header: getColumnHeaderText('DISPATCH_MISC_RESOURCE', t),
            accessor: (row: ManifestReservationShape) => {
              if (editingResourceType === 'other') {
                return getNewResourcesList(row).join(',');
              } else {
                return (
                  row.dispatch_misc_resources &&
                  row.dispatch_misc_resources.join(',')
                );
              }
            },
          },
        ],
      ];
    }

    columns = [
      ...columns,
      ...getColumns(
        locale,
        t,
        excludedFormFieldKeys,
        products,
        reservations,
        [],
        visibleColumns,
        customizedColumnNames || [],
        guideAccountShapes || [],
        editingResourceType,
        editingResourceKey,
        activeUser
      ).map((col) => ({
        ...col,
        Header: '',
        HeaderElement: <ManifestTableHeaderCell text={col.Header ?? ''} />,
      })),
    ];

    if (!readOnly) {
      columns.push({
        Header: '',
        width: 'long',
        Cell: (cellInfo: { original: ManifestReservationShape }) => {
          return (
            <EditRouteButton
              reservation={cellInfo.original}
              editingResourceKey={editingResourceKey}
              editingResourceType={editingResourceType}
            />
          );
        },
      });
    }

    setColumns(
      columns.map((c) => {
        return c as CustomTableColumn;
      })
    );
  }, [
    locale,
    t,
    editingResourceType,
    excludedFormFieldKeys,
    getNewResourcesList,
    rowIsChecked,
    handleRowCheckboxClicked,
    products,
    visibleColumns,
    readOnly,
    hideResourceColumns,
  ]);
  return (
    <>
      {loading ? (
        <>{t('Loading')}</>
      ) : (
        <CustomTable
          usePaging={false}
          useScrollButton={true}
          items={reservations}
          columns={columns}
        />
      )}
    </>
  );
};
