import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { batchUpdateGuidancePages } from 'client/actions/guidancePage';
import { DraggableItem } from 'client/components/DraggableItem/DraggableItem';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import {
  lowercaseIsoToUppercaseIso,
  uppercaseIsoToLowercaseIso,
} from 'shared/libraries/i18n';
import { contentLanguageOptions, getLanguageName } from 'client/libraries/i18n';
import baseStyles from 'client/base.module.css';
import { GuidancePage } from 'shared/models/swagger';

interface FormValues {
  guidancePages: GuidancePage[];
}

type Props = {
  open: boolean;
  onClose: () => void;
};

export const EditDigitalGuidancePagesDisplayOrderModal = ({
  open,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const guidancePages = useSelector(
    (state: ReduxState) => state.guidancePages.all
  );

  const firstGuidancePage = guidancePages.length > 0 ? guidancePages[0] : null;

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    firstGuidancePage
      ? uppercaseIsoToLowercaseIso[
          firstGuidancePage.language as keyof typeof uppercaseIsoToLowercaseIso
        ]
      : 'en'
  );

  const languageOptions = contentLanguageOptions
    .filter((option) =>
      guidancePages.some(
        (page) => page.language === lowercaseIsoToUppercaseIso[option.iso]
      )
    )
    .map((option) => ({
      text: getLanguageName(option.iso, t),
      value: option.iso,
    }));

  const initialValues = React.useMemo(() => {
    const sortedGuidancePages = _.orderBy(
      guidancePages,
      (p) => p.sort_order
    ).filter(
      (p) =>
        p.language ===
        lowercaseIsoToUppercaseIso[
          selectedLanguage as keyof typeof lowercaseIsoToUppercaseIso
        ]
    );
    return {
      guidancePages: sortedGuidancePages,
    };
  }, [guidancePages, selectedLanguage]);

  return (
    <Modal
      title={t('Edit Digital Guidance Page Display Order')}
      open={open}
      onClose={onClose}
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          const newGuidancePages = values.guidancePages.map((page, idx) => ({
            ...page,
            sort_order: idx + 1,
          }));

          await dispatch(batchUpdateGuidancePages(newGuidancePages));

          onClose();
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              {languageOptions.length > 0 && (
                <Box mb={4}>
                  <Select
                    label={t('Language')}
                    options={languageOptions}
                    value={selectedLanguage}
                    onChange={(e, { value }) => {
                      setSelectedLanguage(value);
                      form.change(
                        'guidancePages',
                        _.orderBy(
                          values.guidancePages as any,
                          (p) => p.sort_order
                        ).filter(
                          (p) =>
                            p.language ===
                            lowercaseIsoToUppercaseIso[
                              value as keyof typeof lowercaseIsoToUppercaseIso
                            ]
                        )
                      );
                    }}
                  />
                </Box>
              )}
              {values.guidancePages.length > 0 ? (
                <Field name="guidancePages">
                  {({ input }) =>
                    input.value.map((page: GuidancePage, idx: number) => (
                      <DraggableItem
                        id={page.id}
                        key={page.id}
                        text={page.title ?? ''}
                        index={idx}
                        moveItem={(dragIndex: number, hoverIndex: number) => {
                          const dragItem = input.value[dragIndex];

                          const newSelectedItems = [...input.value];
                          newSelectedItems.splice(dragIndex, 1);
                          newSelectedItems.splice(hoverIndex, 0, dragItem);
                          input.onChange(newSelectedItems);
                        }}
                      />
                    ))
                  }
                </Field>
              ) : (
                <div className={baseStyles['base-form-box__err']}>
                  {t('No Guidance pages available')}
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
                disabled={values.guidancePages.length === 0}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
