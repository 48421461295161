import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { ReduxState } from 'client/reducers';

import styles from './WebsitePopupEvent.module.css';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const PushNotificationClickEvent = ({
  event,
  durationInSeconds,
}: Props) => {
  const { t } = useTranslation();

  const campaigns = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.all
  );

  const campaign = campaigns.find(
    (c) => c.id === event?.marketing_automation_campaign_id
  );

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['color-label']} />
        <div className={styles['timeline']}>
          <div className={styles['icon']}>
            <i className="c-icon-solid-general-eye"></i>
          </div>

          <div className={styles['duration']}>
            {formattedDuration(durationInSeconds, t)}
          </div>
        </div>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>
              {t('Push Notification Click')}
            </div>
            <div className={styles['timestamp']}>
              {moment(event.event_date_time_utc).format('H:mm:ss')}
            </div>
          </div>
          {event.latitude && event.longitude && (
            <div>
              {t('Latitude/Longitude {{latitude}}/{{longitude}}', {
                latitude: event.latitude.toFixed(6),
                longitude: event.longitude.toFixed(6),
              })}
            </div>
          )}
          <div className={styles['body']}>
            <div className={styles['body-inner']}>
              <div className={styles['description']}>
                <div className={styles['page-title']}>
                  {campaign?.title ?? t('Unknown Campaign')}
                </div>
                <a
                  className={styles['link']}
                  href={event.page_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=""></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
