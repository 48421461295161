import * as React from 'react';
import clsx from 'clsx';

import styles from 'client/base.module.css';

type Props = {
  error?: string;
  label?: string;
  onChange: (
    arg0: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  checked: boolean;
  name?: string;
  value?: string;
  withoutFrame?: boolean;
  labelStyle?: any;
  disabled?: boolean;
  required?: boolean;
};

export const Checkbox = ({
  error,
  label,
  onChange,
  checked,
  name,
  value,
  withoutFrame,
  disabled,
  labelStyle,
}: Props) => {
  const Core = () => {
    return (
      <div className={styles['base-form-checkbox']}>
        <label
          style={labelStyle}
          className={clsx(disabled ? styles['disabled'] : null)}
        >
          <input
            onChange={(e) =>
              onChange(e, {
                value: e.target.dataset.value ?? '',
              })
            }
            type="checkbox"
            name={name || ''}
            value={value || ''}
            checked={checked}
            data-value={value || ''}
            disabled={disabled}
          />
          <p></p>
          {label}
        </label>
      </div>
    );
  };

  return (
    <>
      {withoutFrame ? (
        <Core />
      ) : (
        <div className={styles['base-form-box']}>
          <div className={styles['base-form-box__body']}>
            <Core />
            {error && <p className={styles['base-form-box__err']}>{error}</p>}
          </div>
        </div>
      )}
    </>
  );
};
