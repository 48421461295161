import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type {
  Reservation,
  Account,
  ReservationLocationWithTime,
} from 'shared/models/swagger';
import type { LanguageISO } from 'shared/libraries/i18n';
import { Box } from 'client/components/Box/Box';
import { formattedLocationName } from 'client/libraries/util/coreutil';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { RequestedPickupDropoffEditButton } from 'client/pages/ReservationDetails/RequestedPickupDropoffEditButton';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupHeader,
  TutorialPopupDescription,
} from 'client/components/TutorialPopup/TutorialPopup';
import { StatusStepsV2 } from 'client/pages/ReservationDetails/StatusStepsV2';
import { GuestHotelEditButton } from 'client/pages/ReservationDetails/GuestHotelEditButton';
import { PickupDropoffEditButton } from 'client/pages/ReservationDetails/PickupDropoffEditButton';
import { CheckinEditButton } from 'client/pages/ReservationDetails/CheckinEditButton';
import editIcon from 'client/images/ic_edit.svg';
import componentStyles from 'client/components/components.module.css';
import { TransportRoutePanel } from 'client/pages/ReservationDetails/TransportRoutePanel/TransportRoutePanel';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  reservation: Reservation;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly: boolean;
  active: boolean;
};

export const FreeFormatReservationDetailTab = ({
  reservation,
  locale,
  activeUser,
  readOnly,
  active,
}: Props) => {
  const { t } = useTranslation();

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughAgent =
    reservation.agent_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'AGENT';

  const userIsPassthroughSupplier =
    reservation.supplier_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'SUPPLIER';

  const userIsPassthroughOrg =
    userIsPassthroughAgent || userIsPassthroughSupplier;

  const userCanEditPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
  const userCanEditRequestedPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    !operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');

  const isCheckinCheckoutOnly = reservationIsCheckinCheckoutOnly(reservation);

  const LocationWithTime = ({
    label,
    locationWithTime,
  }: {
    label: string;
    locationWithTime: ReservationLocationWithTime;
  }) => {
    return (
      <div>
        <p className={clsx(baseStyles['base-subttl'])}>{label}</p>
        <table className={clsx(baseStyles['base-table'])}>
          <tbody>
            <tr>
              <th className={clsx(baseStyles['base-t-160'])}>{t('Time')}</th>
              <td>
                {locationWithTime && locationWithTime.date_time_utc
                  ? moment
                      .tz(
                        locationWithTime.date_time_utc,
                        reservation.start_timezone ?? 'UTC'
                      ) //TODO need supplier default timezone
                      .locale(locale)
                      .format('lll')
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={clsx(baseStyles['base-t-160'])}>
                {t('Location')}
              </th>
              <td>
                {locationWithTime && locationWithTime.location_name
                  ? formattedLocationName(locationWithTime)
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={clsx(baseStyles['base-t-160'])}>{t('Details')}</th>
              <td>
                {locationWithTime && locationWithTime.location_description
                  ? locationWithTime.location_description
                  : t('TBD')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const defaultLocationWithTime: ReservationLocationWithTime = { id: '' };

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          active ? componentStyles['is-active'] : ''
        )}
      >
        <TutorialPopup
          name="detail"
          content={
            <TutorialPopupContent>
              <TutorialPopupDescription
                text={t(
                  'Each tab shows detailed information about the reservation. You can find the necessary information by switching tabs, so please check if necessary.'
                )}
              />
              <TutorialPopupHeader text={t('Detail Information')} />
              <TutorialPopupDescription
                text={t(
                  'View details about your booking. In addition to reservation status, information like checkin or pickup information is also displayed.'
                )}
              />
              <TutorialPopupHeader text={t('Guest Information')} />
              <TutorialPopupDescription
                text={t(
                  'Reservation form information received for each participant is shown here.'
                )}
              />
              <TutorialPopupHeader text={t('Billing Info')} />
              <TutorialPopupDescription
                text={t(
                  'Shows a breakdown of the price amounts for the reservation. Please make adjustments like additional charges and refunds here.'
                )}
              />
            </TutorialPopupContent>
          }
        />
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Detail Information')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            {userCanEditPickupDropoff ? (
              isCheckinCheckoutOnly ? (
                <CheckinEditButton
                  reservationID={reservation.id}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                    >
                      <img src={editIcon} />
                    </a>
                  }
                />
              ) : (
                <PickupDropoffEditButton
                  reservationID={reservation.id}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                    >
                      <img src={editIcon} />
                    </a>
                  }
                />
              )
            ) : userCanEditRequestedPickupDropoff ? (
              isCheckinCheckoutOnly ? (
                <GuestHotelEditButton
                  reservation={reservation}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                    >
                      <img src={editIcon} />
                    </a>
                  }
                />
              ) : (
                <RequestedPickupDropoffEditButton
                  reservation={reservation}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                    >
                      <img src={editIcon} />
                    </a>
                  }
                />
              )
            ) : null}
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <Box mb={4}>
            <p className={clsx(baseStyles['base-subttl'])}>
              {t('Booking Status History')}
            </p>
            <ul className={clsx(pageStyles['page-reservations__list'])}>
              <StatusStepsV2
                readOnly={readOnly}
                reservation={reservation}
                product={null}
                productInstance={null}
              />
            </ul>
          </Box>

          <div className={clsx(pageStyles['page-reservations__detailFrame'])}>
            {isCheckinCheckoutOnly ? (
              <>
                <LocationWithTime
                  label={t('Checkin')}
                  locationWithTime={
                    reservation?.checkin || defaultLocationWithTime
                  }
                />
                <LocationWithTime
                  label={t('Checkout')}
                  locationWithTime={
                    reservation?.checkout || defaultLocationWithTime
                  }
                />
              </>
            ) : (
              <>
                <LocationWithTime
                  label={t('Pickup')}
                  locationWithTime={
                    reservation?.pickup || defaultLocationWithTime
                  }
                />
                <LocationWithTime
                  label={t('Dropoff')}
                  locationWithTime={
                    reservation?.dropoff || defaultLocationWithTime
                  }
                />
              </>
            )}
          </div>

          <div className={clsx(pageStyles['page-reservations__detailFrame2'])}>
            <p className={clsx(baseStyles['base-subttl'])}>
              {t('Customer Hotel')}
            </p>
            <table className={clsx(baseStyles['base-table'])}>
              <tbody>
                <tr>
                  <th className={clsx(baseStyles['base-t-240'])}>
                    {t('Customer Hotel')}
                  </th>
                  <td>
                    {(reservation?.guest_hotel?.location_name === 'TBD'
                      ? t('TBD')
                      : reservation?.guest_hotel?.location_name) || t('(none)')}
                  </td>
                </tr>
                <tr className={clsx(baseStyles['base-t-240'])}>
                  <th>{t('Desired Pickup Location')}</th>
                  <td>
                    {(reservation.requested_pickup_location?.location_name ===
                    'TBD'
                      ? t('TBD')
                      : reservation.requested_pickup_location?.location_name) ||
                      t('(none)')}
                    {userCanEditRequestedPickupDropoff && (
                      // RequestedPickupDropoffEditButton
                      <RequestedPickupDropoffEditButton
                        reservation={reservation}
                      />
                    )}
                  </td>
                </tr>
                <tr className={clsx(baseStyles['base-t-240'])}>
                  <th>{t('Desired Dropoff Location')}</th>
                  <td>
                    {(reservation.requested_dropoff_location?.location_name ===
                    'TBD'
                      ? t('TBD')
                      : reservation.requested_dropoff_location
                          ?.location_name) || t('(none)')}
                    {userCanEditRequestedPickupDropoff && (
                      // RequestedPickupDropoffEditButton
                      <RequestedPickupDropoffEditButton
                        reservation={reservation}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {(reservation?.transport_route ?? []).length > 1 && (
            <TransportRoutePanel />
          )}
        </div>
      </div>
    </>
  );
};
