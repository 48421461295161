import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import baseStyles from 'client/base.module.css';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Tabs } from 'client/components/Tabs/Tabs';
import { fetchGuidanceStampRallies } from 'client/actions/guidanceStampRally';
import { fetchGuidanceCoupons } from 'client/actions/guidanceCoupon';
import { fetchRestaurants } from 'client/actions/restaurants';

import { DigitalMapBasicsEditor } from './DigitalMapBasicsEditor';
import { DigitalMapPinCategoriesEditor } from './DigitalMapPinCategoriesEditor';
import { DigitalMapPinsEditor } from './DigitalMapPinsEditor';
import { DigitalMapStructuredInfoCategoriesEditor } from './DigitalMapStructuredInfoCategoriesEditor';

export const DigitalMapEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchGuidanceCoupons());
    dispatch(fetchGuidanceStampRallies());
    if (config.enableMobileOrder) {
      dispatch(fetchRestaurants());
    }
  }, [activeUserOrganization?.id]);

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2}>
          <Link to="/maps">
            <BackArrow />
          </Link>
        </Box>
        <Tabs
          panes={[
            {
              header: t('Basics'),
              content: () => <DigitalMapBasicsEditor />,
            },
            ...(id
              ? [
                  {
                    header: t('Pin Categories'),
                    content: () => <DigitalMapPinCategoriesEditor />,
                  },
                  {
                    header: t('Structured Info Categories'),
                    content: () => <DigitalMapStructuredInfoCategoriesEditor />,
                  },
                  {
                    header: t('Map Pins'),
                    content: () => <DigitalMapPinsEditor />,
                  },
                ]
              : []),
          ]}
        />
      </div>
    </div>
  );
};
