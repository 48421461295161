import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

import { activeUserSelector } from 'client/reducers/user';
import { s3MediaStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import { Loading } from 'client/components/v3/Common/Loading';
import baseStyles from 'client/v3-base.module.css';

import styles from './SingleImageInput.module.css';

type Props = {
  onUploadFinished?: (localFilename: string, objectUrl: string) => void;
  error?: string;
  enableSvgFormat?: boolean;
  fileTypes?: string[];
  initialValue?: string;
  saveFilePath?: string;
};
export const SingleImageInput = ({
  onUploadFinished,
  error,
  enableSvgFormat,
  fileTypes,
  initialValue,
  saveFilePath,
}: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>('');
  const imageFileInputRef = useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = fileTypes
    ? fileTypes
    : [
        'image/jpeg',
        'image/png',
        ...(enableSvgFormat ? ['image/svg+xml'] : []),
      ];
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles[0]);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (imageFileInputRef?.current) {
      imageFileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(e.target.files[0]);
      e.target.value = '';
    }
  };

  const uploadFiles = async (file: File) => {
    const organizationId = activeUser?.organization_id;
    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';
    try {
      const filename = getRandomFilenameForUpload(file);
      const objName = `${saveFilePath}/${filename}`;
      await s3MediaStorage.put(objName, file, {
        contentType: file.type,
      });
      const url = await s3MediaStorage.get(objName);
      const uploadedFileURL = url.split('?')[0];
      if (onUploadFinished) {
        onUploadFinished(filename, uploadedFileURL);
      }
    } catch (err) {
      console.error('err', err);
    }

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        {/* Note: if dimmer props:page is not set, dimmer will not cover the whole page, only the top portion of screen */}
        <Dimmer active={uploadInProgress} page={true} inverted>
          <Loading size="md" />
        </Dimmer>
      </div>
      <ul className={clsx(styles['c-imageVideoAudio__input'])}>
        {initialValue != '' && (
          <li key={`${initialValue}-idx`}>
            <div>
              <img src={initialValue} />
              <a onClick={() => onUploadFinished?.('', '')}>
                <i className="c-icon-outline-general-x-close"></i>
              </a>
            </div>
          </li>
        )}
        {initialValue == '' && (
          <li className={styles['add']} onClick={handleAddImageClick}>
            <a
              {...getRootProps()}
              className={clsx(isDragActive && styles['dragActive'])}
              style={{
                display: 'none',
              }}
            ></a>
            <i className="c-icon-outline-images-image-plus"></i>
            <p>{t('Add image')}</p>
            <input
              {...getInputProps()}
              ref={imageFileInputRef}
              id="image-file-input"
              type="file"
              name="name"
              accept={acceptedFileTypes.join(',')}
              style={{
                display: 'none',
              }}
              onChange={handleFileInputChange}
            />
          </li>
        )}
      </ul>
      {uploadError && (
        <p className={baseStyles['u-error-msg']}>{uploadError}</p>
      )}
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </>
  );
};
