import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_INSTANT_WIN_EVENT_FAILURE,
  CREATE_INSTANT_WIN_EVENT_REQUEST,
  CREATE_INSTANT_WIN_EVENT_SUCCESS,
  DELETE_INSTANT_WIN_EVENT_FAILURE,
  DELETE_INSTANT_WIN_EVENT_REQUEST,
  DELETE_INSTANT_WIN_EVENT_SUCCESS,
  FETCH_INSTANT_WIN_EVENTS_FAILURE,
  FETCH_INSTANT_WIN_EVENTS_REQUEST,
  FETCH_INSTANT_WIN_EVENTS_SUCCESS,
  FETCH_INSTANT_WIN_EVENT_FAILURE,
  FETCH_INSTANT_WIN_EVENT_REQUEST,
  FETCH_INSTANT_WIN_EVENT_SUCCESS,
  UPDATE_INSTANT_WIN_EVENT_FAILURE,
  UPDATE_INSTANT_WIN_EVENT_REQUEST,
  UPDATE_INSTANT_WIN_EVENT_SUCCESS,
  UPDATE_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN,
  RESET_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN,
} from 'client/constants/ActionTypes';
import type {
  InstantWinEvent,
  CreateInstantWinEventResponse,
  ListInstantWinEventsResponse,
  UpdateInstantWinEventResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENTS_FAILURE:
    case FETCH_INSTANT_WIN_EVENT_FAILURE:
    case CREATE_INSTANT_WIN_EVENT_FAILURE:
    case UPDATE_INSTANT_WIN_EVENT_FAILURE:
    case DELETE_INSTANT_WIN_EVENT_FAILURE:
      return action.error;

    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
    case FETCH_INSTANT_WIN_EVENT_SUCCESS:
    case CREATE_INSTANT_WIN_EVENT_SUCCESS:
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case DELETE_INSTANT_WIN_EVENT_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENTS_REQUEST:
    case FETCH_INSTANT_WIN_EVENT_REQUEST:
    case CREATE_INSTANT_WIN_EVENT_REQUEST:
    case UPDATE_INSTANT_WIN_EVENT_REQUEST:
    case DELETE_INSTANT_WIN_EVENT_REQUEST:
      return true;

    case FETCH_INSTANT_WIN_EVENTS_FAILURE:
    case FETCH_INSTANT_WIN_EVENT_FAILURE:
    case CREATE_INSTANT_WIN_EVENT_FAILURE:
    case UPDATE_INSTANT_WIN_EVENT_FAILURE:
    case DELETE_INSTANT_WIN_EVENT_FAILURE:
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
    case FETCH_INSTANT_WIN_EVENT_SUCCESS:
    case CREATE_INSTANT_WIN_EVENT_SUCCESS:
    case UPDATE_INSTANT_WIN_EVENT_SUCCESS:
    case DELETE_INSTANT_WIN_EVENT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: InstantWinEvent[] = [], action: any): InstantWinEvent[] => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS: {
      const response = action.response as ListInstantWinEventsResponse;
      return response.instant_win_events;
    }

    case FETCH_INSTANT_WIN_EVENT_SUCCESS: {
      const response = action.response as InstantWinEvent;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_INSTANT_WIN_EVENT_SUCCESS: {
      const response = action.response as CreateInstantWinEventResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_INSTANT_WIN_EVENT_SUCCESS: {
      const response = action.response as UpdateInstantWinEventResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_INSTANT_WIN_EVENT_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const totalSize = (state = 0, action: any) => {
  switch (action.type) {
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS: {
      const resp = action.response as ListInstantWinEventsResponse;
      return resp.total_size ?? 0;
    }
    default:
      return state;
  }
};

type PageTokens = {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
};

const pageTokens = (
  state: PageTokens = {
    allTokens: {
      '': {
        previous: null,
        next: null,
      },
    },
    currToken: '',
    indexToToken: [''],
  },
  action: any
): {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
} => {
  switch (action.type) {
    case RESET_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN:
      return {
        allTokens: {
          '': {
            previous: null,
            next: null,
          },
        },
        currToken: '',
        indexToToken: [''],
      };

    case UPDATE_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN:
    case FETCH_INSTANT_WIN_EVENTS_SUCCESS:
      if (action.pageTokens) {
        return action.pageTokens;
      } else {
        return {
          allTokens: {
            '': {
              previous: null,
              next: null,
            },
          },
          currToken: '',
          indexToToken: [''],
        };
      }

    default:
      return state;
  }
};

export const instantWinEvents = combineReducers({
  error,
  loading,
  totalSize,
  all,
  pageTokens,
});
