import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers/index';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Loading } from 'client/pages/Loading';
import {
  activeUserOrganizationSelector,
  accountsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  getGuideAccountShapes,
  isActiveAccount,
} from 'client/libraries/util/accountShape';
import { NewGuideModal } from 'client/components/NewGuideModal/NewGuideModal';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { GuideListTab } from './GuideListTab';

type TabType = 'ENABLED' | 'DISABLED';
export const GuideList = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<TabType>('ENABLED');
  const accounts = useSelector(accountsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const loading = useSelector((state: ReduxState) => state.accounts.loading);
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );
  const sortedGuideAccountShapes = React.useMemo(() => {
    return _.sortBy(
      guideAccountShapes,
      (guideAccountShape) => guideAccountShape.name
    );
  }, [guideAccountShapes]);
  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'SHIFT_MANAGEMENT.STAFF'
        ) && (
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spSpacebetween'],
              baseStyles['spOrder-1']
            )}
          >
            <NewGuideModal
              trigger={
                <button
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['middle'],
                    baseStyles['green']
                  )}
                >
                  {t('Create new account')}
                </button>
              }
            />
          </div>
        )}
      </div>

      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'ENABLED' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('ENABLED');
            }}
          >
            <a>{t('Enabled')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'DISABLED' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('DISABLED');
            }}
          >
            <a>{t('Disabled')}</a>
          </li>
        </ul>

        <GuideListTab
          guideAccountShapes={
            activeTab === 'ENABLED'
              ? sortedGuideAccountShapes.filter((guideAccountShape) =>
                  isActiveAccount(guideAccountShape)
                )
              : sortedGuideAccountShapes.filter(
                  (guideAccountShape) => !isActiveAccount(guideAccountShape)
                )
          }
        />
      </div>

      {loading && <Loading />}
    </>
  );
};
