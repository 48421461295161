import { combineReducers } from 'redux';

import { SET_LAST_DIGITAL_MAP_ACCESS_REPORT_SETTINGS } from 'client/constants/ActionTypes';
import { initialSettings } from 'client/libraries/util/digitalMapAccessReportSettings';

const lastDigitalMapAccessReportSettings = (
  state = initialSettings,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_DIGITAL_MAP_ACCESS_REPORT_SETTINGS:
      return action.accessReportSettings;

    default:
      return state;
  }
};

export const digitalMapAccessReportSettings = combineReducers({
  lastDigitalMapAccessReportSettings,
});
