import { AccessReportHeader } from 'client/pages/v3/BookingWidget/AccessReport/AccessReportHeader';
import { AccessReportSettingsBlock } from 'client/pages/v3/BookingWidget/AccessReport/AccessReportSettingsBlock';
import { AccessReportGraphBlock } from 'client/pages/v3/BookingWidget/AccessReport/AccessReportGraphBlock';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const AccessReport = () => {
  return (
    <V3Page>
      <AccessReportHeader />
      <AccessReportSettingsBlock />
      <AccessReportGraphBlock />
    </V3Page>
  );
};
