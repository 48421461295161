import { createSelector } from 'reselect';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getDefaultManifestViews } from 'client/reducers/manifestDefaults';
import type {
  ManifestProductGroup,
  ManifestViewType,
  ManifestCustomizedColumnName,
  ManifestAlternateNameSettings,
} from 'shared/models/swagger';

export const manifestSettingsSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return (organization && organization.manifest_settings) || {};
  }
);
export const manifestCustomViewsSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): ManifestViewType[] => {
    return manifestSettings.manifest_view_types || [];
  }
);
export const allManifestViewsSelector = createSelector(
  manifestCustomViewsSelector,
  (manifestCustomViews): ManifestViewType[] => {
    return [...manifestCustomViews, ...getDefaultManifestViews()];
  }
);

const alwaysExcludedFormFieldKeys = [
  'given_name',
  'family_name',
  'representative_name',
  'hotel_information_checkin_checkout',
  'hotel_information',
  'hotel_tbd_form',
];
export const manifestExcludedFormFieldsSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): string[] => {
    return (manifestSettings.excluded_form_field_keys || []).filter(
      (key) => !alwaysExcludedFormFieldKeys.includes(key)
    );
  }
);
export const manifestProductGroupsSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): ManifestProductGroup[] => {
    return manifestSettings.manifest_product_groups || [];
  }
);
export const manifestCustomizedColumnNamesSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): ManifestCustomizedColumnName[] => {
    return manifestSettings.customized_column_names || [];
  }
);
export const manifestAlternateNameSettingsSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): ManifestAlternateNameSettings => {
    return (
      manifestSettings.alternate_name_settings || {
        alternate_names: [],
      }
    );
  }
);
export const manifestFuseStartTimesSelector = createSelector(
  manifestSettingsSelector,
  (manifestSettings): boolean => {
    return (
      (manifestSettings.fuse_start_times &&
        manifestSettings.fuse_start_times.value) ||
      false
    );
  }
);
export const manifestShowReservationsOnAllOperatingDaysSelector =
  createSelector(manifestSettingsSelector, (manifestSettings): boolean => {
    return (
      (manifestSettings.show_reservations_on_all_operating_days &&
        manifestSettings.show_reservations_on_all_operating_days.value) ||
      false
    );
  });
