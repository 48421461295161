import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'client/config';
import { getIDToken } from 'client/libraries/cognito';
import { addNotification } from 'client/actions/notifications';
import type { ReduxState } from 'client/reducers';

interface Props {
  children: React.ReactNode;
}

export const WebSocketConnectionProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const idToken = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  React.useEffect(() => {
    if (!idToken) {
      return;
    }

    try {
      const webSocketEndpointUrl = config.webSocketEndpointUrl;

      if (!webSocketEndpointUrl) {
        throw new Error('web socket endpoint URL not present in configs');
      }

      const ws = new WebSocket(`${webSocketEndpointUrl}?authToken=${idToken}`);

      ws.onopen = () => {
        console.log('Websocket: opened');
      };

      ws.onclose = () => {
        console.log('Websocket: closed');
      };

      ws.onmessage = (event) => {
        if (typeof event.data === 'string') {
          const notification: any = JSON.parse(event.data);
          dispatch(addNotification(notification));
        }
      };

      return () => ws.close();
    } catch (err) {
      console.log('err', err);
    }
  }, [idToken]);

  return <>{children}</>;
};
