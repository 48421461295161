import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, TextArea } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { ReduxState } from 'client/reducers';

interface Props {
  initialValue: string[];
  onClose: () => void;
  onSave: (address: string[]) => void;
}

export const EditSupplierAddressModal = ({
  initialValue,
  onClose,
  onSave,
}: Props) => {
  const [address, setAddress] = React.useState(initialValue.join('\n'));

  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const { t } = useTranslation();

  return (
    <Modal insertRoot title={t('Edit address')} open={true} onClose={onClose}>
      <Modal.Content>
        <TextArea
          label={t('Address')}
          value={address}
          onChange={(e, { value }) => setAddress(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        <Button.Submit
          loading={loading}
          onClick={async () => {
            await onSave(address.split('\n'));
            onClose();
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
