import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getWeekdayText } from 'client/libraries/util/weekdays';
import type { Weekday } from 'client/libraries/util/weekdays';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';

const weekdays: Weekday[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
type Props = {
  value: Weekday[];
  onChange: (arg0: Weekday[]) => void;
};
export const WeekdaysInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['page-productsRegist__checkbox']}>
      {weekdays.map((weekday, idx) => (
        <div key={idx} className={baseStyles['base-form-checkbox']}>
          <label>
            <input
              type="checkbox"
              name="sample"
              checked={value?.includes(weekday as Weekday)}
              onChange={() => {
                if (value?.includes(weekday as Weekday)) {
                  onChange(value.filter((w) => w !== weekday));
                } else {
                  onChange(
                    weekdays.filter(
                      (w: Weekday) =>
                        value?.includes(w as Weekday) || w === weekday
                    )
                  );
                }
              }}
            />
            <p></p>
            {getWeekdayText(weekday as Weekday, t)}
          </label>
        </div>
      ))}
    </div>
  );
};
