import {
  SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION,
  SET_WAIVER_VISIBLE_COLUMNS,
  SET_WAIVER_ROW_COUNT,
  SET_WAIVER_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchWaiversRequest } from 'client/libraries/util/searchWaivers';

export const setWaiverVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_WAIVER_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setLastExecutedWaiverSearchCondition = (
  searchFilters: SearchWaiversRequest
) => ({
  type: SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION,
  searchFilters,
});
export const setWaiverRowCount = (rowCount: number) => ({
  type: SET_WAIVER_ROW_COUNT,
  rowCount,
});
export const setWaiverCurrentPage = (currentPage: number) => ({
  type: SET_WAIVER_CURRENT_PAGE,
  currentPage,
});
