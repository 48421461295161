import { Link } from 'react-router-dom';
import clsx from 'clsx';

import type { TranslateFuncType } from 'client/components/Translate';
import type { ActimReservation } from 'shared/models/swagger';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';

export type ColumnType = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: ((arg0: any) => string) | keyof ActimReservation;
  width?: 'short' | 'middle' | 'long';
  Cell?: (arg0: any) => any;
  th?: boolean;
};

const getActimReservationStatus = (
  t: TranslateFuncType,
  status: ActimReservation['status']
): string => {
  switch (status) {
    case 'ACTIM_RESERVE_STATUS_RESERVED':
      return t('reserved');
    case 'ACTIM_RESERVE_STATUS_REQUEST':
      return t('request');
    case 'ACTIM_RESERVE_STATUS_PROVISIONAL':
      return t('provisional');
    case 'ACTIM_RESERVE_STATUS_ATTENDED':
      return t('attended');
    case 'ACTIM_RESERVE_STATUS_CANCELED':
      return t('canceled');
    case 'ACTIM_RESERVE_STATUS_UNKNOWN':
      return t('unknown');
    default:
      return '';
  }
};

const getActimReservationSiteName = (
  t: TranslateFuncType,
  siteName: ActimReservation['site_name']
): string => {
  switch (siteName as any) {
    case 'ACTIM_RESERVE_SITENAME_ASOVIEW':
      return t('asoview');
    case 'ACTIM_RESERVE_SITENAME_URAKATA':
      return t('urakata');
    case 'ACTIM_RESERVE_SITENAME_JARAN':
      return t('jalan');
    case 'ACTIM_RESERVE_SITENAME_TABIRAI':
      return t('tabirai');
    case 'ACTIM_RESERVE_SITENAME_AJ':
      return t('AJ');
    default:
      return '';
  }
};

export const getActimReservationTableColumns = (
  t: TranslateFuncType
): ColumnType[] => {
  return [
    {
      Header: '',
      id: 'edit',
      width: 'short',
      th: true,
      Cell: (cellInfo: any) => (
        <Link
          to={`/products/${cellInfo.original.product_id}/instances/${cellInfo.original.product_instance_id}/book?new_reservation_modal=1&actim_reservation_id=${cellInfo.original.id}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </Link>
      ),
    },
    {
      Header: t('Actim Reservation ID'),
      id: 'id',
      accessor: 'id',
      width: 'short',
      //Cell: (row) => <Link to={`/reservations/${row.value}`}>{row.value}</Link>,
    },
    {
      Header: t('Status'),
      id: 'status',
      width: 'short',
      accessor: (row) => getActimReservationStatus(t, row.status),
    },
    {
      Header: t('Site Status'),
      id: 'site_status',
      accessor: 'site_status',
      width: 'short',
    },
    {
      Header: t('Site Name'),
      id: 'site_name',
      width: 'short',
      accessor: (row) => getActimReservationSiteName(t, row.site_name),
    },
    {
      Header: t('Created At'),
      id: 'rsv_created_at',
      width: 'short',
      //accessor: 'rsv_created_at',
      accessor: (row) => {
        const d = row.rsv_created_at.split(' ');
        if (d.length != 2) {
          return row.rsv_created_at;
        }
        const t = d[1].split(':');
        if (t.length == 3) {
          return `${d[0]} ${t[0]}:${t[1]}`;
        } else {
          return row.rsv_created_at;
        }
      },
    },
    {
      Header: t('Participation'),
      id: 'activity_started_at',
      width: 'short',
      accessor: (row) => {
        const d = row.activity_started_at.split(' ');
        if (d.length != 2) {
          return row.activity_started_at;
        }
        const t = d[1].split(':');
        if (t.length == 3) {
          return `${d[0]} ${t[0]}:${t[1]}`;
        } else {
          return row.activity_started_at;
        }
      },
    },
    {
      Header: t('Plan Name'),
      id: 'plan_name',
      width: 'middle',
      accessor: 'plan_name',
    },
    {
      Header: t('Course Name'),
      id: 'course_name',
      width: 'middle',
      accessor: 'course_name',
    },
    {
      Header: t('Customer'),
      id: 'customer',
      width: 'short',
      accessor: (row) => row.reserve_user.name,
    },
    {
      Header: t('Units'),
      id: 'units',
      width: 'long',
      Cell: (row: any) => {
        const u = row.original.payment_unit_list?.map(
          (r: Record<string, string | number>, idx: number) => {
            const classification = r.classification
              ? r.classification
              : t('Not set');
            const quantity = r.quantity ? r.quantity : t('Not set');
            const unit_price = r.unit_price ? r.unit_price : t('Not set');
            const sum = r.sum ? r.sum : t('Not set');
            return (
              <div key={idx}>
                {`${classification} ${unit_price} x ${quantity} ${t(
                  'Total'
                )} ${sum}`}
              </div>
            );
          }
        );
        return <div>{u}</div>;
      },
    },
  ];
};
