import moment from 'moment-timezone';

import type { StartTime } from 'client/libraries/util/productShape';

export const formatStartTime = (startTime: StartTime): string => {
  const today = moment().format('YYYY-MM-DD');
  const showStartDate = startTime.startDate && startTime.startDate > today;
  return `${startTime.hhMm}${
    startTime.description && ` ${startTime.description} `
  }${showStartDate ? ` (${startTime.startDate} ~ )` : ''}`;
};
