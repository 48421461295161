import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/libraries/util/ColumnType';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import inquiryDetailIcon from 'client/images/ic_inquiryDetail.svg';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';
import { Inquiry } from 'shared/models/swagger';

import { InquiryDetailsModal } from './InquiryDetailsModal';
import { AssignReservationModal } from './AssignReservationModal';

const getLeadTimeInHours = (inquiry: Inquiry): number | null => {
  if (!inquiry.reservation_start_date_time_local) {
    return null;
  }

  const today = moment();
  const participationDate = moment(inquiry.reservation_start_date_time_local);

  return participationDate.diff(today, 'hours');
};

const getLeadTimeInDays = (inquiry: Inquiry): number | null => {
  if (!inquiry.reservation_start_date_time_local) {
    return null;
  }

  const today = moment();
  const participationDate = moment(inquiry.reservation_start_date_time_local);

  return participationDate.diff(today, 'days');
};

const DetailsCell = ({ inquiry }: { inquiry: Inquiry }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <a onClick={() => setShowModal(true)} className={baseStyles['base-btn']}>
        <img src={inquiryDetailIcon} />
      </a>
      {showModal && (
        <InquiryDetailsModal
          inquiryId={inquiry?.id ?? ''}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

const AssignReservationButton = ({ inquiry }: { inquiry: Inquiry }) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <a
        data-text={t('Assign')}
        onClick={() => setShowModal(true)}
        className={baseStyles['base-btn']}
      >
        <img src={editIcon} />
      </a>
      {showModal && (
        <AssignReservationModal
          inquiry={inquiry}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

// Need to change the order of given name and family name when the
// reservation form requires kana name
const getGuestName = (givenName: string, familyName: string): string => {
  if (isKanaWord(givenName) && isKanaWord(familyName)) {
    return `${familyName} ${givenName}`;
  }
  return `${givenName} ${familyName}`;
};

export const useInquiryColumns = (): ColumnType<Inquiry>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);

  return [
    {
      Header: t('Reservation'),
      width: 'short',
      th: true,
      Cell: (cellInfo) =>
        cellInfo.original.reservation_id ? (
          <a
            href={`/reservations/${cellInfo.original.reservation_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Reservation')}
          >
            <img src={reservationDetailIcon} />
          </a>
        ) : (
          hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
            <AssignReservationButton inquiry={cellInfo.original} />
          )
        ),
      id: 'reservationDetails',
    },
    {
      Header: t('Messages'),
      width: 'short',
      Cell: (cellInfo) => <DetailsCell inquiry={cellInfo.original} />,
      id: 'details',
    },
    {
      Header: t('Last Updated'),
      width: 'middle',
      accessor: (row) =>
        moment(row.last_updated_date_time_utc).locale(locale).format('lll'),
      id: 'updated',
    },
    {
      Header: t('Lead Time'),
      id: 'leadTime',
      accessor: (row) => {
        const leadTimeInDays = getLeadTimeInDays(row);

        if (leadTimeInDays == null) {
          return '-';
        }

        if (leadTimeInDays < 0) {
          return t('Date Passed');
        }

        if (leadTimeInDays < 2) {
          return t('{{count}} hours', {
            count: getLeadTimeInHours(row),
          });
        }

        return t('{{count}} days', {
          count: leadTimeInDays,
        });
      },
    },
    {
      Header: t('Participation'),
      accessor: 'reservation_start_date_time_local',
      Cell: (cellInfo) =>
        cellInfo.original.reservation_start_date_time_local ? (
          <Link
            to={`/reservations/${cellInfo.original.reservation_id}?t=inquiry#detail`}
            target="_blank"
          >
            {moment(cellInfo.original.reservation_start_date_time_local)
              .locale(locale)
              .format('lll')}
          </Link>
        ) : (
          '-'
        ),
      id: 'participation',
    },
    {
      Header: t('Subject'),
      accessor: 'subject',
      id: 'subject',
      width: 'long',
    },
    {
      Header: t('Communication Type'),
      accessor: (row) => {
        switch (row.initial_communication_type) {
          case 'SMS_BY_SUPPLIER':
          case 'BULK_SMS_BY_SUPPLIER':
            return t('SMS');
          default:
            return t('Email');
        }
      },
      id: 'communicationType',
    },
    {
      Header: t('Tags'),
      accessor: (row) => row.tags?.join(',') ?? '',
      id: 'tags',
    },
    {
      Header: t('Email/Phone'),
      accessor: (row) =>
        row.customer_email ?? formattedPhone(row.customer_phone || '') ?? '-',
      id: 'contact',
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.customer_given_name || row.customer_family_name
          ? getGuestName(
              row.customer_given_name ?? '',
              row.customer_family_name ?? ''
            )
          : '-',
      id: 'customer',
    },
    {
      Header: t('Category'),
      accessor: (row) => row.inquiry_category ?? '',
      id: 'category',
    },
    {
      Header: t('Targeted Reservations'),
      accessor: (row) =>
        row.initial_communication_type === 'BULK_EMAIL_BY_SUPPLIER'
          ? String((row.bulk_send_info?.reservation_ids ?? []).length)
          : String((row.bulk_send_info?.sms_reservations ?? []).length),
      id: 'reservationCount',
    },
  ];
};
