import React from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getReservationTableColumns,
  ReservationListColumn,
} from 'client/libraries/util/getReservationTableColumns';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type { Account, Reservation } from 'shared/models/swagger';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { annualPassReservationVisibleColumnsSelector } from 'client/reducers/annualPassReservationTableControls';
import { CommonReservationSearchCustomTable } from 'client/components/CommonReservationSearch/CommonReservationSearchCustomTable/CommonReservationSearchCustomTable';

const fetcher = async (
  url: string,
  date: string,
  annualPassOnly: boolean,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) => {
  const params = new URLSearchParams({
    date,
  });
  if (annualPassOnly) {
    params.append('annual_pass_only', 'true');
  }

  return fetch(`${url}?${params}`, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());
};

interface QueryParams {
  id: string;
}

interface Props {
  annualPassOnly?: boolean;
}

export const ETicketFootprintReservations = ({ annualPassOnly }: Props) => {
  const { i18n, t } = useTranslation();
  const { id } = useParams<QueryParams>();
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const visibleColumns = useSelector(
    annualPassReservationVisibleColumnsSelector
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const getColumns = (columnMask: string[]): ReservationListColumn[] => {
    const columns = [
      'edit',
      ...(columnMask.filter((c) => c !== 'edit') as any),
    ].map((c) => allColumns.find((col) => col.id === c) as any);

    return columns;
  };
  const getAllColumns = (): ReservationListColumn[] => {
    return getReservationTableColumns(t, locale);
  };

  const [allColumns, setAllColumns] = React.useState(getAllColumns());
  const [showColumns, setShowColumns] = React.useState(
    getColumns(visibleColumns)
  );
  const [rowCount, setRowCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );

  React.useEffect(() => {
    if (invalidated) {
      setAllColumns(getAllColumns());
    }
  }, [invalidated, locale]);

  React.useEffect(() => {
    setShowColumns(getColumns(visibleColumns));
  }, [visibleColumns]);

  const { data } = useSWR(
    [
      `/api/eticketfootprints/reservations`,
      id,
      annualPassOnly || false,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );

  const reservationSummaryShapes = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return (
      data?.reservations?.map((r: Reservation) =>
        toReservationSummaryShape(r, locale, t)
      ) ?? []
    );
  }, [data]);

  const { setHeaderOverride } = React.useContext(PageHeaderOverrideContext);
  React.useEffect(() => {
    if (id) {
      setHeaderOverride(
        t('Daily E-ticket Usage - {{date}}', {
          date: id,
        })
      );
    }

    return () => setHeaderOverride('');
  }, [id, t]);

  const startIndex = (currentPage - 1) * rowCount;
  const endIndex = startIndex + rowCount;
  return (
    <div>
      <CommonReservationSearchCustomTable
        reservations={(reservationSummaryShapes ?? []).slice(
          startIndex,
          endIndex
        )}
        totalHits={(reservationSummaryShapes ?? []).length || 0}
        visibleColumns={showColumns}
        rowCount={rowCount}
        currentPage={currentPage}
        onRowCountChange={(rowCount) => {
          setRowCount(rowCount);
          setCurrentPage(1);
        }}
        onCurrentPageChange={(currentPage) => {
          setCurrentPage(currentPage);
        }}
      />
    </div>
  );
};
