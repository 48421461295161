import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { TransportRouteLocationEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/TransportRouteLocationEditor';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';

export const TransportRouteEditor = () => {
  const { t } = useTranslation();
  return (
    <FieldArray name="transportRoute">
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((name, idx) => {
              return (
                <div key={name} className={styles['p-frame']}>
                  <div className={styles['p-frame__header']}>
                    <p className={styles['p-frame__header__ttl']}>
                      {t('Route #{{routeIdx}}', {
                        routeIdx: idx + 1,
                      })}
                    </p>
                    <div className={styles['p-frame__header__actions']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                        onClick={() => fields.remove(idx)}
                      />
                    </div>
                  </div>
                  <div className={styles['p-frame__body']}>
                    <div>
                      <p className={styles['p-transportRoute__routeTitle']}>
                        {t('Place of Departure')}
                      </p>
                      {idx === 0 ? (
                        <div className={styles['bold']}>
                          {t('Pickup / Checkin')}
                        </div>
                      ) : (
                        <TransportRouteLocationEditor
                          key={`${name}.locationFrom`}
                          name={`${name}.locationFrom`}
                        />
                      )}
                    </div>
                    <div className={baseStyles['u-mt-12']}>
                      <p className={styles['p-transportRoute__routeTitle']}>
                        {t('Place of Arrival')}
                      </p>
                      {idx === fields.value?.length - 1 ? (
                        <div className={styles['bold']}>
                          {t('Dropoff / Checkout')}
                        </div>
                      ) : (
                        <TransportRouteLocationEditor
                          key={`${name}.locationTo`}
                          name={`${name}.locationTo`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          <div className={fields.length === 0 ? '' : baseStyles['u-mt-4']}>
            <a
              className={styles['p-box__table2__actions__add']}
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  const initialLocationFrom = fields.value[fields.length]
                    ?.locationTo
                    ? {
                        googlePlaceId:
                          fields.value[fields.length]?.locationTo.googlePlaceId,
                        locationName:
                          fields.value[fields.length]?.locationTo.locationName,
                        relativeTime:
                          fields.value[fields.length]?.locationTo.relativeTime,
                      }
                    : {
                        googlePlaceId: '',
                        locationName: '',
                        relativeTime: '',
                      };
                  (fields as any).insertAt(fields.length + 1, {
                    locationFrom: initialLocationFrom,
                    locationTo: {
                      googlePlaceId: '',
                      locationName: '',
                      relativeTime: '',
                    },
                  });
                }
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Route')}
            </a>
          </div>
        </>
      )}
    </FieldArray>
  );
};
