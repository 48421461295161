import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Account, Guest, Product, Reservation } from 'shared/models/swagger';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { Message } from 'client/components/Message/Message';
import { ReservationFieldResponseUpdateModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationFieldResponseUpdateModal';
import { FileDownloadList } from 'client/pages/ReservationDetails/FileDownloadList';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { Button } from 'client/components/v3/Common/Button';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  product: Product;
  activeUser: Account | null;
  readOnly: boolean;
};

const MAX_FIELD_RESPONSE = 100;
const MAX_PARTICIPANT = 100;

// Extend Guest to avoid the following error in getDisplayString
// Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Guest'.
// No index signature with a parameter of type 'string' was found on type 'Guest'
type ExtendedGuest = Guest & {
  [key: string]:
    | string
    | string[]
    | Array<{ key?: string; response?: string }>
    | undefined;
};

export const GuestInformation = forwardRef<any, Props>((props: Props) => {
  const { reservation, product, activeUser, readOnly } = props;
  const { partnershipMode } = useContext(PartnershipModeContext);

  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  if (!reservation || !product) {
    return null;
  }

  const [shouldRecalculateTableHeight, setShouldRecalculateTableHeight] =
    useState<boolean>(false);

  if (!reservation || !product) {
    return null;
  }

  const productReservationFormFields = (product.reservation_form_fields || [])
    .filter((field) => {
      return field.type === 'PER_PARTICIPANT';
    })
    .map((formField) => ({
      key: formField.key,
      title: formField.prompt,
      format: formField.format,
    }));

  const excludedFormFieldKeys = [
    'family_name',
    'given_name',
    'kana_family_name',
    'kana_given_name',
    'email',
    'preferred_language_iso2',
    'booking_language',
    'hotel_information',
    'representative_name',
    'hotel_tbd_form',
    'consent_form',
    'phone',
    'international_phone',
  ];

  const builtinFormFieldsByKey = getCommonFormFieldsByKey(t);
  const unmappedFormFieldKeys: string[] = reservation.guests.reduce(
    (acc: string[], guest) => {
      const keys =
        guest.field_responses?.map(
          (fieldResponse) => fieldResponse.key ?? ''
        ) || [];
      keys.forEach((key) => {
        if (
          !acc.includes(key) &&
          !productReservationFormFields.some(
            (formField) => formField.key === key
          )
        ) {
          acc.push(key);
        }
      });
      return acc;
    },
    []
  );
  const unregisteredBuiltinFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key)
    )
    .map((key) => ({
      key,
      title: builtinFormFieldsByKey[key ?? ''].text,
    }));
  const unregisteredUnknownFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        !builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key)
    )
    .map((key) => ({
      key,
      title: key,
    }));
  const items = [
    ...productReservationFormFields,
    ...unregisteredBuiltinFormFields,
    ...unregisteredUnknownFormFields,
  ];

  const getDisplayString = (guest: ExtendedGuest, key: string): string => {
    const value = guest[key];

    if (value) {
      if (typeof value === 'string') {
        return value;
      } else if (Array.isArray(value)) {
        return value.join('<br/>');
      }
    }

    if (guest.field_responses) {
      const field = guest.field_responses.find((field) => field.key === key);
      if (field && field.response) {
        return field.response;
      }
    }

    return '';
  };

  // for adjust the height of each lines
  // TODO(goro) need to keep the same React Hook call count
  const itemRefs: any[] = [];
  [...Array(MAX_FIELD_RESPONSE)].forEach(() => {
    const _itemRefs = [];
    _itemRefs.push(useRef(null));
    [...Array(MAX_PARTICIPANT)].forEach(() => {
      _itemRefs.push(useRef(null));
    });
    itemRefs.push(_itemRefs);
  });

  const getMax = useCallback((items) => {
    return items.reduce(
      (max: number, item: { current: { offsetHeight: any } }) => {
        const height = item?.current?.offsetHeight;
        if (!height) {
          return max;
        }
        return max < height ? height : max;
      },
      0
    );
  }, []);

  const setTableHeight = () => {
    itemRefs.forEach((items) => {
      const max = getMax(items);
      if (max) {
        items.forEach((item: any) => {
          if (item?.current?.style) {
            item.current.style.height = `${max}px`;
          }
        });
      }
    });
  };

  const handleResize = useCallback(() => {
    const clearHeights = () => {
      itemRefs.forEach((items) => {
        items.forEach((item: any) => {
          if (item?.current?.style?.height) {
            item.current.style.height = 'auto';
          }
        });
      });
    };

    clearHeights();
    setShouldRecalculateTableHeight(true);
  }, []);

  useEffect(() => {
    if (reservation) {
      setTableHeight();
    }
  }, [reservation]);

  useEffect(() => {
    if (shouldRecalculateTableHeight) {
      setTableHeight();
      setShouldRecalculateTableHeight(false);
    }
  }, [shouldRecalculateTableHeight]);

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughOrg =
    (reservation.agent_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'AGENT') ||
    (reservation.supplier_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'SUPPLIER');

  const userCanEditFormFieldResponses =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  return (
    <section
      id="guest"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Guest Information')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {userCanEditFormFieldResponses && items.length > 0 && (
              <Button
                text={t('Edit')}
                uiType="bg"
                size="sm"
                color="white"
                iconBeforeText={
                  <i className="c-icon-outline-general-edit-05"></i>
                }
                onClick={() => setShowModal(true)}
              />
            )}
          </div>
        </div>
        {showModal && (
          <ReservationFieldResponseUpdateModal
            reservationID={reservation.id}
            onClose={() => setShowModal(false)}
            openFrom="guest-info"
          />
        )}

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-relations']}>
            {(reservation?.guests?.length > MAX_PARTICIPANT ||
              items.length > MAX_FIELD_RESPONSE) && (
              <Message
                warning
                header={t('Too much participants or field responses')}
              />
            )}

            {items.length > 0 && (
              <table
                className={clsx(
                  tableStyles['c-tableSmall'],
                  tableStyles['row']
                )}
                style={{ tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    {items.map((item, idx) => {
                      return (
                        <th
                          key={idx}
                          ref={itemRefs[idx < MAX_FIELD_RESPONSE ? idx : 0][0]}
                          className={
                            idx === 0
                              ? baseStyles['u-width-224']
                              : baseStyles['u-width-144']
                          }
                        >
                          {item.title ?? ''}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {reservation.guests.map((guest, gidx) => {
                    return (
                      <tr key={gidx}>
                        {items.map((item, iidx) => {
                          return (
                            <td
                              key={iidx}
                              ref={
                                itemRefs[iidx < MAX_FIELD_RESPONSE ? iidx : 0][
                                  gidx + 1 < MAX_PARTICIPANT ? gidx + 1 : 0
                                ]
                              }
                              data-text={t(item.title)}
                            >
                              <div>
                                {'format' in item &&
                                item.format === 'file-upload' ? (
                                  <FileDownloadList
                                    key={`${gidx}-${iidx}`}
                                    fileUrlString={getDisplayString(
                                      guest,
                                      item.key ?? ''
                                    )}
                                  />
                                ) : (
                                  <TruncatedTextWithSeeMoreButton
                                    text={getDisplayString(
                                      guest,
                                      item.key ?? ''
                                    )}
                                    onResize={handleResize}
                                  />
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {/* TODO: table next/prev buttons, keep them here for now */}
            {/* <div
              className={clsx(
                componentStyles['c-table-slide__btn'],
                componentStyles['prev']
              )}
              onClick={() => {
                if (divRef?.current?.scrollLeft != null) {
                  divRef.current.scrollLeft -= 160;
                }
              }}
            ></div>
            <div
              className={clsx(
                componentStyles['c-table-slide__btn'],
                componentStyles['next']
              )}
              onClick={() => {
                if (divRef?.current?.scrollLeft != null) {
                  divRef.current.scrollLeft += 160;
                }
              }}
            ></div> */}
          </div>
        </div>
      </div>
    </section>
  );
});
