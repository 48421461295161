import * as React from 'react';
import { compose } from 'recompose';
import { withTranslation, TFunction } from 'react-i18next';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';

import { Modal } from 'client/components/Modal/Modal';
import { FormFieldV2 } from 'client/components/FormFieldV2';
import {
  convertToLocationWithMoment,
  defaultLocationWithMoment,
} from 'client/libraries/util/coreutil';
import { timePlusOffset } from 'client/libraries/util/util';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { DateTimeInput, FieldWrapper } from 'client/components/Form';
import { Select } from 'client/components/Form/Select';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import type { LocationWithTime as ProductLocationWithTime } from 'shared/models/swagger';

import { ImageVideoAudioInput } from './ImageVideoAudioInput/ImageVideoAudioInput';

type OwnProps = {
  locationNameLabel?: string;
  locationDateLabel?: string;
  locationDescriptionLabel?: string;
  locationTimeLabel?: string;
  locationDateTimeLable?: string;
  location: LocationWithMoment;
  productCandidateLocations: ProductLocationWithTime[];
  onLocationChange: (arg0: LocationWithMoment) => void;
  startTime: Moment;
  timeSlotKey: string;
};
type I18nProps = {
  t: TFunction;
};
type Props = OwnProps & I18nProps;
type State = {
  showModal: boolean;
  locationDateFocused: boolean;
  candidateLocations: ProductLocationWithTime[];
};

class LocationWithTimeEditFormFieldsComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      locationDateFocused: false,
      candidateLocations: this.getCandidateLocations(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.productCandidateLocations !==
        this.props.productCandidateLocations ||
      prevProps.location.locationName !== this.props.location.locationName
    ) {
      this.setState({
        candidateLocations: this.getCandidateLocations(),
      });
    }
  }

  getCandidateLocations = () => {
    const { location } = this.props;
    const candidateLocations: ProductLocationWithTime[] =
      this.props.productCandidateLocations || [];
    // If the reservation pickup location name is different than the name for the same pickup location in the
    // product, replace the name. If this is a custom pickup location, add it to the end of the list of locations.
    const { locationID: id, locationName: location_name } = location;

    if (id && candidateLocations.find((loc) => loc.id === id)) {
      return candidateLocations.map((loc) =>
        loc.id === id ? { ...loc, location_name } : loc
      );
    } else {
      return [
        ...candidateLocations,
        {
          id,
          location_name,
        },
      ];
    }
  };
  handleLocationChange = (
    e: React.SyntheticEvent<Record<string, any>>,
    {
      value,
    }: {
      value: string;
    }
  ) => {
    const { location, startTime, onLocationChange, timeSlotKey } = this.props;
    const { candidateLocations } = this.state;

    if (value === 'CHOOSE_LATER') {
      return onLocationChange({ ...defaultLocationWithMoment });
    }

    const locationMatch = candidateLocations.find(
      (p) => p.location_name === value
    );

    if (locationMatch) {
      const newLocation = convertToLocationWithMoment(
        locationMatch as any,
        '',
        ''
      );
      // Change default locationDateTime to start time
      let newLocationDateTime = startTime;
      // Note: this only checks for matching start time and not duration, even though
      // the dedicated start time info allows separate handling for two instances that
      // start at the same time but have different durations. This is highly uncommon.
      // Only fix if it becomes an issue.
      const dedicatedStartTimeItem = (
        locationMatch.dedicated_start_time_info || []
      ).find(
        (dedicatedStartTimeItem) =>
          dedicatedStartTimeItem.time_slot_key === timeSlotKey
      );

      if (dedicatedStartTimeItem && dedicatedStartTimeItem.time_relative) {
        newLocationDateTime = timePlusOffset(
          startTime,
          dedicatedStartTimeItem.time_relative
        );
      } else if (locationMatch.time_relative) {
        newLocationDateTime = timePlusOffset(
          startTime,
          locationMatch.time_relative
        );
      }

      return onLocationChange({
        ...newLocation,
        locationDateTime: newLocationDateTime,
      });
    } else {
      return onLocationChange({
        ...location,
        // This is a custom location not found in the pickup list. Clear any existing
        // Google place ID and location IDs.
        locationID: '',
        googlePlaceID: '',
        locationName: value,
      });
    }
  };
  handleAddNewLocation = (
    e: React.SyntheticEvent<Record<string, any>>,
    {
      value,
    }: {
      value: string;
    }
  ) => {
    const { onLocationChange, location } = this.props;
    this.setState((prevState) => ({
      candidateLocations: [
        ...prevState.candidateLocations,
        {
          id: '',
          location_name: value,
        },
      ],
    }));
    onLocationChange({
      ...defaultLocationWithMoment,
      locationName: value,
      locationDateTime: location.locationDateTime,
    });
  };
  handleLocationDateChange = (newDate: Moment) => {
    const { location, onLocationChange, startTime } = this.props;

    if (newDate) {
      if (location.locationDateTime) {
        const newLocationDateTime = moment(location.locationDateTime)
          .set('year', newDate.year())
          .set('month', newDate.month())
          .set('date', newDate.date());
        onLocationChange({
          ...location,
          locationDateTime: newLocationDateTime,
        });
      } else {
        const newLocationDateTime = moment(startTime)
          .set('year', newDate.year())
          .set('month', newDate.month())
          .set('date', newDate.date());
        onLocationChange({
          ...location,
          locationDateTime: newLocationDateTime,
        });
      }
    }
  };

  render() {
    const {
      locationDateLabel,
      locationTimeLabel,
      locationNameLabel,
      locationDescriptionLabel,
      location,
      onLocationChange,
      t,
    } = this.props;
    const { candidateLocations } = this.state;
    const { startTime } = this.props;
    const defaultLocationDateTime =
      startTime && moment(startTime).subtract(30, 'minutes');

    return (
      <>
        <Modal.Box>
          <Select
            name="location_name"
            label={locationNameLabel || t('Location')}
            search
            allowFreeInput={true}
            additionLabel={t('')}
            value={location.locationName || 'CHOOSE_LATER'}
            onChange={this.handleLocationChange}
            options={[
              {
                text: t('Choose later'),
                value: 'CHOOSE_LATER',
              },
              ...(candidateLocations || []).map((p) => ({
                text: p.location_name,
                value: p.location_name,
              })),
            ]}
          />
        </Modal.Box>

        <Modal.Box>
          <FormFieldV2
            prompt={locationDescriptionLabel || t('Description')}
            value={location.locationDescription}
            onChange={(newLocationDescription) =>
              onLocationChange({
                ...location,
                locationDescription: newLocationDescription,
              })
            }
            required={false}
            textArea
            height={100}
          />
        </Modal.Box>

        <Modal.Box>
          <>
            <FieldWrapper label={t('Images')} />
            <ImageVideoAudioInput
              fileUrls={location.imageUrls ?? []}
              onChange={(newValue) =>
                onLocationChange({
                  ...location,
                  imageUrls: newValue,
                })
              }
              disableYoutubeVideos
            />
          </>
        </Modal.Box>

        <Modal.Box column="two">
          <DateTimeInput
            label={locationDateLabel || t('Date')}
            value={location.locationDateTime}
            onChange={this.handleLocationDateChange}
          />

          <FieldWrapper label={locationTimeLabel || t('Time')}>
            <TimePicker
              allowEmpty={false}
              defaultOpenValue={defaultLocationDateTime}
              value={location.locationDateTime ?? undefined}
              onChange={(time) => {
                if (!time) {
                  return;
                }

                onLocationChange({
                  ...location,
                  locationDateTime: (
                    location.locationDateTime?.clone() ||
                    defaultLocationDateTime.clone()
                  )
                    .set('hour', time.hour())
                    .set('minute', time.minute()),
                });
              }}
            />
          </FieldWrapper>
        </Modal.Box>
      </>
    );
  }
}

export const LocationWithTimeEditFormFields = compose<Props, OwnProps>(
  withTranslation()
)(LocationWithTimeEditFormFieldsComponent);
