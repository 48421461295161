import type {
  AgentReference,
  PerGroupPrice,
  Price,
} from 'client/pages/ProductEditor/types';
import * as Swagger from 'shared/models/swagger';

export type Service = {
  title: string;
  description: string;
  priceType: 'PER_PARTICIPANT' | 'PER_BOOKING' | 'FREE';
  prices: Price[];
  // Per-participant schedules may also have per-group prices
  perGroupPrices: PerGroupPrice[];
  image: string;
  isPopular: boolean;
  isRecommended: boolean;
  referencesInAgentSystems: AgentReference[];
  referenceInSupplierSystem?: string;
};
export type ServiceFormValues = {
  services: Service[];
};
export const getInitialServiceFormValues = (
  initialServices: Swagger.Service[],
  showNet: boolean
): Service[] => {
  return initialServices.map((svc) => {
    let priceType: 'FREE' | 'PER_PARTICIPANT' | 'PER_BOOKING' = 'FREE';

    for (const unit of svc?.pricing || []) {
      if (unit.method === 'PER_PARTICIPANT' || unit.method === 'PER_BOOKING') {
        priceType = unit.method;
      }
    }

    return {
      priceType,
      title: svc.title,
      description: svc.description ?? '',
      prices:
        svc.pricing
          ?.filter(
            (unit) =>
              unit.method === 'PER_PARTICIPANT' || unit.method === 'PER_BOOKING'
          )
          .map((unit) => ({
            method:
              unit.method === 'PER_PARTICIPANT'
                ? 'PER_PARTICIPANT'
                : 'PER_BOOKING',
            unit: unit?.guest_type?.key ?? '',
            ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
            ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
            price: unit.gross?.substring(3),
            netPrice: showNet ? unit.net?.substring(3) : '100',
            perAgentNetPrices:
              unit.per_agent_net?.map((agentNet) => ({
                agentId: agentNet.agent_id ?? '',
                netFixed: agentNet.net_fixed,
              })) ?? [],
            weight: null,
            referenceInSupplierSystem: unit?.guest_type?.third_party_reference,
            referencesInAgentSystems: (
              unit?.guest_type?.agent_references ?? []
            ).map((ref) => ({
              agentId: ref.agent_id ?? '',
              reference: ref.agent_reference ?? '',
            })),
          })) ?? [],
      perGroupPrices:
        svc.pricing
          ?.filter((unit) => unit.method === 'PER_GROUP')
          .map((unit) => ({
            title: unit.title ?? '',
            groupUnits: (unit.group_guest_types ?? []).map(
              (guestType) => guestType?.key ?? ''
            ),
            price: unit.gross?.substring(3),
            netPrice: showNet ? unit.net?.substring(3) : '100',
            perAgentNetPrices:
              unit.per_agent_net?.map((agentNet) => ({
                agentId: agentNet.agent_id ?? '',
                netFixed: agentNet.net_fixed,
              })) ?? [],
          })) ?? [],
      image: svc.image_url ?? '',
      isPopular: svc.is_popular ?? false,
      isRecommended: svc.is_recommended ?? false,
      referenceInSupplierSystem: svc.supplier_reference,
      referencesInAgentSystems: (svc.agent_references ?? []).map((ref) => ({
        agentId: ref.agent_id ?? '',
        reference: ref.agent_reference ?? '',
      })),
    };
  });
};
export const convertFormValueServicesToProductServices = (
  services: Service[],
  defaultCurrency: string,
  showNet: boolean
): Swagger.Service$Patch[] => {
  return services.map((service) => {
    return {
      key: service.title,
      title: service.title,
      description: service.description,
      service_type:
        service.priceType === 'PER_PARTICIPANT' ||
        service.priceType === 'PER_BOOKING'
          ? 'PAID'
          : 'FREE',
      image_url: service.image,
      pricing: [
        ...(service.prices || []).map((price) => {
          let guestType;

          if (price.unit) {
            guestType = {
              title: price.unit,
              key: price.unit,
              minimum_age: price.ageBandMinimum,
              maximum_age: price.ageBandMaximum,
              third_party_reference: price.referenceInSupplierSystem,
              agent_references: (price.referencesInAgentSystems ?? []).map(
                (ref) => ({
                  agent_id: ref.agentId,
                  agent_reference: ref.reference,
                })
              ),
            };
          }

          return {
            method: price.method,
            guest_type: guestType,
            gross: `${defaultCurrency}${price.price}`,
            net: `${defaultCurrency}${
              showNet ? price.netPrice ?? price.price : price.price
            }`,
            per_agent_net: price.perAgentNetPrices?.map((agentNet) => ({
              agent_id: agentNet.agentId,
              net_fixed: agentNet.netFixed,
            })),
          };
        }),
        ...(service.perGroupPrices || []).map(
          (price) =>
            ({
              title: price.title,
              method: 'PER_GROUP',
              group_guest_types: (price.groupUnits ?? []).map((groupUnit) => ({
                title: groupUnit,
                key: groupUnit,
              })),
              gross: `${defaultCurrency}${price.price ?? 0}`,
              net: `${defaultCurrency}${
                showNet ? price.netPrice ?? 0 : price.price
              }`,
              per_agent_net: price.perAgentNetPrices?.map((agentNet) => ({
                agent_id: agentNet.agentId,
                net_fixed: agentNet.netFixed,
              })),
            } as Swagger.UnitPricing)
        ),
      ],
      is_popular: service.isPopular,
      is_recommended: service.isRecommended,
      supplier_reference: service.referenceInSupplierSystem,
      agent_references: (service.referencesInAgentSystems ?? []).map((ref) => ({
        agent_id: ref.agentId,
        agent_reference: ref.reference,
      })),
    };
  });
};
