import clsx from 'clsx';
import ColorPicker from 'rc-color-picker';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field, useForm } from 'react-final-form';

import { getArrayMutators } from 'client/libraries/util/form';
import { Box } from 'client/components/Box/Box';
import { Input, Button, FieldWrapper } from 'client/components/Form';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import { updateEquipmentBlock } from 'client/actions/equipmentBlocks';
import { EquipmentBlock } from 'shared/models/swagger';

import styles from './EquipmentEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  color: string;
  capacity: number;
}

const getInitialValues = (
  equipmentBlock: EquipmentBlock | undefined
): FormValues => {
  return {
    title: equipmentBlock?.title ?? '',
    description: equipmentBlock?.description ?? '',
    columnCount: equipmentBlock?.column_count ?? 1,
    rowCount: equipmentBlock?.row_count ?? 1,
    color: equipmentBlock?.color?.value ?? '#000000',
    capacity: equipmentBlock?.capacity?.value ?? 0,
  };
};

interface Props {
  equipmentBlock: EquipmentBlock | undefined;
}

export const EquipmentBlockPanel = ({ equipmentBlock }: Props) => {
  const dispatch = useDispatch();
  return (
    <Form
      keepDirtyOnReinitialize={true}
      onSubmit={(values: FormValues) => {
        dispatch(
          updateEquipmentBlock(equipmentBlock?.id ?? '', {
            title: values.title,
            color: {
              value: values.color,
            },
            capacity: {
              value: Number(values.capacity),
            },
          })
        );
      }}
      initialValues={
        getInitialValues(equipmentBlock) as Partial<EquipmentBlock>
      }
      mutators={getArrayMutators()}
      debug={console.log}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <EquipmentBlockPanelForm submitting={submitting} />
          </form>
        );
      }}
    </Form>
  );
};

interface FormProps {
  submitting: boolean;
}

const EquipmentBlockPanelForm = ({ submitting }: FormProps) => {
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const cellWidth = 80;
  const cellHeight = 50;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const renderLayout = () => {
    const cells = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        cells.push(
          <div
            key={`${i}-${j}`}
            className={clsx(
              styles['equipment-editor__panel-main__layout__cell']
            )}
            style={{
              width: cellWidth,
              height: cellHeight,
              backgroundColor: values.color,
            }}
          >
            {convertToAlphabet(j + 1)}
            {i + 1}
          </div>
        );
      }
    }
    return cells;
  };

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <Box mt={2} mr={2} ml={2}>
          <Field name="title">
            {({ input }) => (
              <Input
                label={t('Title')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
        <Box display="flex" mt={2} mr={2} ml={2}>
          <Box mr={2}>
            <Field name="columnCount">
              {({ input }) => (
                <Input
                  disabled={true}
                  type="number"
                  label={t('Column count')}
                  value={input.value}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                  {...{
                    min: 1,
                  }}
                />
              )}
            </Field>
          </Box>
          <Box ml={2}>
            <Field name="rowCount">
              {({ input }) => (
                <Input
                  disabled={true}
                  type="number"
                  label={t('Row count')}
                  value={input.value}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                  {...{
                    min: 1,
                  }}
                />
              )}
            </Field>
          </Box>
        </Box>
        <Box mt={2} mr={2} ml={2}>
          <FieldWrapper label={t('Colors')}>
            <Field name="color">
              {({ input }) => (
                <ColorPicker
                  color={input.value}
                  onChange={({ color }: any) => input.onChange(color)}
                />
              )}
            </Field>
          </FieldWrapper>
        </Box>
        <Box mt={2} mr={2} ml={2} mb={2}>
          <Field name="capacity">
            {({ input }) => (
              <Input
                label={t('seat.Capacity')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
      </div>
      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <div
          className={clsx(styles['equipment-editor__panel-main__layout'])}
          style={{
            gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
            gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
            width: layoutWidth,
            height: layoutHeight,
          }}
        >
          {renderLayout()}
        </div>
      </div>
      <Box
        mt={2}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          loading={submitting}
          size="middle"
          style="blue"
          type="submit"
          disabled={values.title ? false : true}
        >
          {t('Save')}
        </Button>
      </Box>
    </div>
  );
};
