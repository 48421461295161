import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { Input } from 'client/components/Form';

import styles from './WaiverTemplateList.module.css';

type Props = {
  name: string;
};
export const FileUploadEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  return (
    <div>
      <ul className={styles['scheds']}>
        <li className={styles['scheds__cell']}>
          <TranslatedField name={`${name}.label`}>
            {({ input, translationInput }) => (
              <>
                <div>
                  <p className={styles['scheds__body']}>
                    <Input
                      {...input}
                      label={t('Image/PDF Upload Instructions')}
                    />
                  </p>
                </div>
                {translationTargetLanguage && (
                  <div>
                    <p className={styles['scheds__body']}>
                      <Input
                        {...translationInput}
                        label={t(
                          'Image/PDF Upload Instructions ({{language}})',
                          {
                            language: translationTargetLanguageName,
                          }
                        )}
                      />
                    </p>
                  </div>
                )}
              </>
            )}
          </TranslatedField>
        </li>
      </ul>
    </div>
  );
};
