import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Pagination.module.css';

type PaginationProps = {
  totalItems: number;
  currentPage: number;
  pageCount: number;
  selectedNumberOfLinesToDisplay: number;
  onChangeNumberOfLinesToDisplay: (selectedOption: number) => void;
  onClickPrevButton: () => void;
  onClickNextButton: () => void;
  disableNumberOfLinesToDisplay?: boolean;
};

export const Pagination = ({
  totalItems,
  currentPage,
  pageCount,
  selectedNumberOfLinesToDisplay,
  onChangeNumberOfLinesToDisplay,
  onClickPrevButton,
  onClickNextButton,
  disableNumberOfLinesToDisplay = false,
}: PaginationProps) => {
  const { t } = useTranslation();
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (target instanceof Node && !fieldSetRef?.current?.contains(target)) {
        setIsOpen(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  const options = [1, 5, 10, 50];
  const startItem = (currentPage - 1) * selectedNumberOfLinesToDisplay + 1;
  const endItem = Math.min(
    currentPage * selectedNumberOfLinesToDisplay,
    totalItems
  );

  return (
    <div className={styles['c-pagination']}>
      {totalItems != 0 && (
        <p className={styles['c-pagination__cnt']}>
          {t('Showing {{start}}~{{end}} items of total {{total}} items', {
            start: startItem,
            end: endItem,
            total: totalItems,
          })}
        </p>
      )}
      <fieldset
        className={clsx(
          styles['c-pagination__select'],
          disableNumberOfLinesToDisplay && styles['is-disabled']
        )}
        ref={fieldSetRef}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <label>
          <p>
            {t('{{count}} items', {
              count: selectedNumberOfLinesToDisplay,
            })}
          </p>
        </label>
        <ul
          className={clsx(
            styles['c-pagination__select'],
            isOpen && styles['is-active']
          )}
        >
          {options.map((option, index) => {
            return (
              <li key={index}>
                <input
                  type="radio"
                  name="pagination"
                  value={option}
                  checked={selectedNumberOfLinesToDisplay == option}
                  onChange={() => {
                    onChangeNumberOfLinesToDisplay(option);
                    setIsOpen(false);
                  }}
                  disabled={disableNumberOfLinesToDisplay}
                />
                <i></i>
                <p>{option}</p>
              </li>
            );
          })}
        </ul>
      </fieldset>
      <a
        className={clsx(
          styles['c-pagination__btn'],
          1 < currentPage ? '' : styles['is-disabled']
        )}
        onClick={onClickPrevButton}
      >
        <i className="c-icon-solid-arrows-arrow-left"></i>
      </a>
      <a
        className={clsx(
          styles['c-pagination__btn'],
          currentPage < pageCount ? '' : styles['is-disabled']
        )}
        onClick={onClickNextButton}
      >
        <i className="c-icon-solid-arrows-arrow-right"></i>
      </a>
    </div>
  );
};
