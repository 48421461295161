import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { Radio } from 'client/components/v3/Form/Radio';
// import { CalendarEditContext } from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import {
  VisibilityStateByReservationSlot,
  VisibilityStateByResource,
  reservationSlotVisibilitySettingSelector,
  resourceVisibilitySettingSelector,
} from 'client/libraries/util/visibilitySettings';
import {
  setProductCalendarListReservationSlotVisibilitySetting,
  setProductCalendarListResourceVisibilitySetting,
} from 'client/actions/productCalendarListControls';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  onSave: () => void;
};

export const VisibilitySettingsModal = ({
  open,
  onClose,
  title,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const instanceVisibilityByReservationSlot = useSelector(
    reservationSlotVisibilitySettingSelector
  );
  const instanceVisibilityByResource = useSelector(
    resourceVisibilitySettingSelector
  );
  const [loading, setLoading] = useState<boolean | null>(null);

  // Use local state because we want to reflect the settings not immediately upon settings change
  // instead, only dispatch after save button is clicked
  const [
    localInstanceVisibilityByReservationSlot,
    setLocalInstanceVisibilityByReservationSlot,
  ] = useState<VisibilityStateByReservationSlot>(
    instanceVisibilityByReservationSlot
  );
  const [
    localInstanceVisibilityByResource,
    setLocalInstanceVisibilityByResource,
  ] = useState<VisibilityStateByResource[]>(instanceVisibilityByResource);

  // Initialize local state for checkboxes with default values from instanceVisibilityByResource
  useEffect(() => {
    if (open) {
      setLocalInstanceVisibilityByResource(instanceVisibilityByResource);
    }
  }, [open, instanceVisibilityByResource]);

  const handleCheckboxChange = (value: VisibilityStateByResource) => {
    if (
      localInstanceVisibilityByResource === null ||
      localInstanceVisibilityByResource.length === 0
    ) {
      setLocalInstanceVisibilityByResource([value]);
      return;
    }

    const tempArray: VisibilityStateByResource[] = [
      ...localInstanceVisibilityByResource,
    ];
    const index = tempArray.indexOf(value);

    if (index !== -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(value);
    }
    setLocalInstanceVisibilityByResource(tempArray);
  };

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={onClose}
          />
          <Button
            text={t('Save')}
            onClick={async () => {
              await setLoading(true);
              await onSave();
              // Artificial delay to introduce loading
              // We want user to feel as if the filtering is being applied while loading instead of instant filtering
              await setTimeout(async () => {
                await setLoading(false);
                if (localInstanceVisibilityByReservationSlot) {
                  await dispatch(
                    setProductCalendarListReservationSlotVisibilitySetting(
                      localInstanceVisibilityByReservationSlot
                    )
                  );
                }
                if (localInstanceVisibilityByResource) {
                  await dispatch(
                    setProductCalendarListResourceVisibilitySetting(
                      localInstanceVisibilityByResource
                    )
                  );
                }
                await onClose();
              }, 400);
            }}
            loading={Boolean(loading)}
          />
        </>
      }
      style={{ maxWidth: '560px' }}
    >
      <div className={commonStyles['p-availabilitySetting']}>
        <div className={commonStyles['p-availabilitySetting__box']}>
          <p className={commonStyles['p-availabilitySetting__box__ttl']}>
            {t('Reservation Slot')}
          </p>
          <div className={commonStyles['p-availabilitySetting__box__flex']}>
            <Radio
              label={t('Show all')}
              value="SHOW_ALL"
              checked={
                localInstanceVisibilityByReservationSlot === 'SHOW_ALL' ?? true
              }
              onChange={(event) => {
                setLocalInstanceVisibilityByReservationSlot(
                  event.target.value as VisibilityStateByReservationSlot
                );
              }}
              size="md"
            />
            <Radio
              label={t('Show items with one or more confirmed reservations')}
              value="SHOW_ITEMS_WITH_ONE_OR_MORE_RESERVATIONS"
              checked={
                localInstanceVisibilityByReservationSlot ===
                  'SHOW_ITEMS_WITH_ONE_OR_MORE_RESERVATIONS' ?? false
              }
              onChange={(event) => {
                setLocalInstanceVisibilityByReservationSlot(
                  event.target.value as VisibilityStateByReservationSlot
                );
              }}
              size="md"
            />
            <Radio
              label={t('Show items with zero reservations')}
              value="SHOW_ITEMS_WITH_ZERO_RESERVATIONS"
              checked={
                localInstanceVisibilityByReservationSlot ===
                  'SHOW_ITEMS_WITH_ZERO_RESERVATIONS' ?? false
              }
              onChange={(event) => {
                setLocalInstanceVisibilityByReservationSlot(
                  event.target.value as VisibilityStateByReservationSlot
                );
              }}
              size="md"
            />
          </div>
        </div>
        <div className={commonStyles['p-availabilitySetting__box']}>
          <p className={commonStyles['p-availabilitySetting__box__ttl']}>
            {t('Resource')}
          </p>
          <div className={commonStyles['p-availabilitySetting__box__flex']}>
            <Checkbox
              label={t('Crew')}
              value="CREW"
              checked={(localInstanceVisibilityByResource ?? []).includes(
                'CREW'
              )}
              onChange={(_, { value }) => {
                handleCheckboxChange(value as VisibilityStateByResource);
              }}
              size="md"
            />
            <Checkbox
              label={t('Vehicle')}
              value="VEHICLE"
              checked={(localInstanceVisibilityByResource ?? []).includes(
                'VEHICLE'
              )}
              onChange={(_, { value }) => {
                handleCheckboxChange(value as VisibilityStateByResource);
              }}
              size="md"
            />
            <Checkbox
              label={t('Other')}
              value="MISC"
              checked={(localInstanceVisibilityByResource ?? []).includes(
                'MISC'
              )}
              onChange={(_, { value }) => {
                handleCheckboxChange(value as VisibilityStateByResource);
              }}
              size="md"
            />
            <Checkbox
              label={t('Memo')}
              value="MEMO"
              checked={(localInstanceVisibilityByResource ?? []).includes(
                'MEMO'
              )}
              onChange={(_, { value }) => {
                handleCheckboxChange(value as VisibilityStateByResource);
              }}
              size="md"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
