import { v4 as uuidv4 } from 'uuid';

import { FormValues } from 'client/libraries/util/resourceManager';
import type { DispatchResource, ProductStartTime } from 'shared/models/swagger';

export const getInitialValues = (
  resource: DispatchResource | null
): FormValues => {
  return {
    availabilitySchedules: (resource?.availability?.schedules ?? []).map(
      (schedule) => ({
        startDateLocal: schedule.start_date_local ?? '',
        endDateLocal: schedule.end_date_local ?? '',
        departureTimeCapacities: (
          schedule?.departure_time_capacities ?? []
        )?.map((departureTimeCapacity: any) => ({
          key: departureTimeCapacity.key || uuidv4(),
          timeLocal: departureTimeCapacity.time_local ?? '00:00',
          capacity: departureTimeCapacity.capacity ?? 0,
          productStartTimes: (
            departureTimeCapacity.product_start_times ?? []
          ).map((productStartTime: ProductStartTime) => ({
            productId: productStartTime.product_id,
            startTimeLocal: productStartTime.start_time_local,
          })),
        })),
      })
    ),
  };
};

export const convertFormValuesToDispatchResources = (
  values: FormValues,
  resourceKey: string | null,
  resources: DispatchResource[]
): DispatchResource[] => {
  const availability = {
    schedules: values.availabilitySchedules?.map((schedule) => ({
      start_date_local: schedule.startDateLocal,
      end_date_local: schedule.endDateLocal,
      departure_time_capacities: schedule.departureTimeCapacities?.map(
        (departureTimeCapacity) => ({
          key: departureTimeCapacity.key,
          time_local: departureTimeCapacity.timeLocal,
          capacity: Number(departureTimeCapacity.capacity),
          product_start_times: (
            departureTimeCapacity.productStartTimes || []
          ).map((productStartTime) => ({
            product_id: productStartTime.productId,
            start_time_local: productStartTime.startTimeLocal,
          })),
        })
      ),
    })),
  };

  const resource = resources.find((resource) => resource.key === resourceKey);

  if (resource) {
    resource.availability = availability;
  }

  return resources;
};
