import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { TopPage } from '@nutmeglabs/falcon-ui';

import { fetchProducts } from 'client/actions/products';
import { FalconUIContextProvider } from 'client/components/FalconUIContextProvider/FalconUIContextProvider';
import { SourceLanguage } from 'shared/models/swagger';

import { TemplateContent } from './TemplateContent/TemplateContent';

interface Props {
  editMode?: boolean;
  language: SourceLanguage;
}

export const CustomTopPageTemplate = ({ editMode, language }: Props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const form = useForm();
  const pageData = form.getState().values;

  return (
    <FalconUIContextProvider language={language}>
      <TemplateContent editMode={editMode} pageData={pageData as TopPage} />
    </FalconUIContextProvider>
  );
};
