import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useContext } from 'react';

import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { getValidators } from 'shared/libraries/validate/validator';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Toggle } from 'client/components/v3/Form/Toggle';

type Props = {
  name: string;
  currency: string;
  showNet?: boolean;
};
export const PerGroupPriceInput = ({ currency, name, showNet }: Props) => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const { required } = getValidators(t);
  let units: string[] = [];

  for (const priceRule of editingProduct?.pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit?.guest_type?.key) {
        units.push(unit.guest_type.key);
      } else if (unit.method === 'PER_GROUP') {
        units.push(
          ...new Set(
            (unit.group_guest_types ?? []).map((guestType) => guestType.key)
          )
        );
      }
    }
  }

  units = [...new Set(units)];

  if (units.length === 0) {
    return (
      <p className={baseStyles['u-error-msg']}>
        {t(
          'Per-group prices require at least one separate per-participant price schedule'
        )}
      </p>
    );
  }

  return (
    <FieldArray name={name}>
      {({ fields: priceFields }) => (
        <div className={styles['p-box']}>
          <div className={styles['p-box__header']}>
            <Toggle
              label={t('Set Up Per-Group Prices')}
              checked={priceFields.length !== 0}
              onChange={() => {
                if (priceFields.length === 0) {
                  (priceFields as any).insertAt(0, {
                    title: '',
                    method: 'PER_GROUP',
                    groupUnits: [],
                    price: '100',
                    netPrice: '100',
                  });
                } else {
                  priceFields.remove(0);
                }
              }}
            />
          </div>
          {priceFields.length !== 0 && (
            <div className={styles['p-box__table']}>
              {priceFields.map((priceFieldName) => (
                <div key={priceFieldName} className={styles['p-box__list']}>
                  <div className={styles['p-box__list__item']}>
                    <div className={styles['p-box__list__item__ttl']}>
                      {t('Title')}
                    </div>
                    <div className={styles['p-box__list__item__body']}>
                      <TranslatedField
                        name={`${priceFieldName}.title`}
                        validate={required}
                      >
                        {({
                          input,
                          translationInput,
                          meta: { error, touched },
                        }) => (
                          <>
                            <TextField {...input} error={touched && error} />
                            {translationTargetLanguage != null && (
                              <TextField
                                {...translationInput}
                                placeholder={translationTargetLanguageName}
                              />
                            )}
                          </>
                        )}
                      </TranslatedField>
                    </div>
                  </div>
                  <div
                    className={styles['p-box__list__item']}
                    data-title={t('Amount (tax included {{currency}})', {
                      currency: currency,
                    })}
                  >
                    <div className={styles['p-box__list__item__ttl']}>
                      {t('Amount (tax included {{currency}})', {
                        currency: currency,
                      })}
                    </div>
                    <div className={styles['p-box__list__item__body']}>
                      <div className={styles['p-box__item__currency']}>
                        <Field name={`${priceFieldName}.price`}>
                          {({ input }) => (
                            <TextField
                              value={input.value}
                              onChange={(value) => {
                                if (!currencyInputAllowed(currency, value)) {
                                  return;
                                }

                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  {showNet && (
                    <div
                      className={styles['p-box__list__item']}
                      data-title={t('Net Amount ({{currency}})', {
                        currency: currency,
                      })}
                    >
                      <div className={styles['p-box__list__item__ttl']}>
                        {t('Net Amount ({{currency}})', {
                          currency: currency,
                        })}
                      </div>
                      <div className={styles['p-box__list__item__body']}>
                        <div className={styles['p-box__item__currency']}>
                          <Field name={`${priceFieldName}.netPrice`}>
                            {({ input }) => (
                              <TextField
                                value={input.value}
                                onChange={(value) => {
                                  if (!currencyInputAllowed(currency, value)) {
                                    return;
                                  }

                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={styles['p-box__list__item']}>
                    <div className={styles['p-box__list__item__ttl']}>
                      {t('Units')}
                    </div>
                    <div className={styles['p-box__list__item__body']}>
                      <FieldArray name={`${priceFieldName}.groupUnits`}>
                        {({ fields: unitFields }) => (
                          <>
                            {unitFields.map((unitFieldName, idx) => (
                              <div
                                key={unitFieldName}
                                className={clsx(
                                  styles['p-box__list__item__body__flex']
                                )}
                              >
                                <div
                                  className={clsx(
                                    styles[
                                      'p-box__list__item__body__flex__main'
                                    ]
                                  )}
                                >
                                  <Field name={unitFieldName}>
                                    {({ input }) => (
                                      <SingleDropdown
                                        options={units.map((unit) => ({
                                          value: unit,
                                          text: unit,
                                        }))}
                                        selectedOption={input.value}
                                        onChange={(value) => {
                                          input.onChange(value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div
                                  className={clsx(
                                    styles[
                                      'p-box__list__item__body__flex__actions'
                                    ],
                                    baseStyles['u-width-48']
                                  )}
                                >
                                  <Button
                                    size="icon"
                                    color="tertiarygray"
                                    onClick={() => unitFields.remove(idx)}
                                    iconBeforeText={
                                      <i className="c-icon-outline-general-trash-03"></i>
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                            <a
                              className={styles['p-box__table__add']}
                              onClick={() => {
                                if (
                                  unitFields.length &&
                                  unitFields.length > 0
                                ) {
                                  (unitFields as any).insertAt(
                                    unitFields.length + 1,
                                    units[0]
                                  );
                                } else {
                                  (unitFields as any).insertAt(0, units[0]);
                                }
                              }}
                            >
                              <i className="c-icon-outline-general-plus-circle"></i>
                              {t('Add Unit')}
                            </a>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
};
