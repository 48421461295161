import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import type { LanguageISO } from 'shared/libraries/i18n';
import {
  getOperationHourText,
  hasNote,
  isOutOfOffice,
  isOverwroteBySingleDayOperatingHour,
} from 'client/libraries/util/accountShape';
import type { GuideSingleDaySchedule } from 'shared/models/swagger';
import iMarkIcon from 'client/images/ic_imark.svg';
import baseStyles from 'client/base.module.css';
import humanIcon from 'client/images/ic_human.svg';

import styles from './GuideScheduleCustomTable.module.css';

export type Item = {
  account: GuideAccountShape;
  pax: Record<string, string>;
};
type Props = {
  items: Item[];
  columns: string[];
  usePaging?: boolean;
  useScrollButton?: boolean;
  locale: LanguageISO;
  dayMonthFormat: string;
  onPaxClick: (arg0: { accountId: string; date: string }) => void;
  onEventDayClick: (arg0: { accountId: string; date: string }) => void;
  guideSingleDaySchedules: GuideSingleDaySchedule[];
};
export const GuideScheduleCustomTable = ({
  items,
  columns,
  usePaging,
  useScrollButton,
  locale,
  dayMonthFormat,
  onPaxClick,
  onEventDayClick,
  guideSingleDaySchedules,
}: Props) => {
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    setRowCount(count);
    setCurrentPage(1);
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (items.length < rowCount * page) {
      page = Math.floor(items.length / rowCount);
    }

    setCurrentPage(page);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [rowCount]);
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getDateHeader = (column: string, locale: string) => {
    const dateMoment = moment(column).locale(locale);
    return (
      <span className={clsx(baseStyles['product-calendar-table-date-header'])}>
        {dateMoment.format(dayMonthFormat) + `(${dateMoment.format('ddd')})`}
      </span>
    );
  };

  const Pax = ({ item, column }: { item: Item; column: string }) => {
    const availability = item.pax[column];
    const outOfOffice = isOutOfOffice(
      column,
      item.account,
      guideSingleDaySchedules
    );
    return (
      <td
        className={clsx(styles['pax'], outOfOffice && styles['out-of-office'])}
        onClick={() => {
          onEventDayClick({
            accountId: item.account.id,
            date: column,
          });
        }}
      >
        <p
          className={clsx(
            styles['operating-hours'],
            outOfOffice ? styles['out-of-office'] : styles['in-of-office'],
            isOverwroteBySingleDayOperatingHour(
              column,
              item.account,
              guideSingleDaySchedules
            )
              ? styles['single-day']
              : styles['normal-day']
          )}
        >
          {getOperationHourText(column, item.account, guideSingleDaySchedules)}
          {hasNote(column, item.account, guideSingleDaySchedules) && (
            <img className={clsx(styles['information'])} src={iMarkIcon} />
          )}
        </p>
        <div className={clsx(baseStyles['base-flex'])}>
          {availability && (
            <a
              className={clsx(styles['base-btn'], styles['icon'])}
              onClick={(e) => {
                e.stopPropagation();
                onPaxClick({
                  accountId: item.account.id,
                  date: column,
                });
              }}
            >
              <p>
                <img className={clsx(styles['availability'])} src={humanIcon} />
              </p>
              <p> {availability} </p>
            </a>
          )}
        </div>
      </td>
    );
  };

  return (
    <>
      <div className={clsx(styles['c-table-availability'])}>
        <div onScroll={scrollHandler} ref={tableBodyRef}>
          <div className={clsx(styles['c-table-availability__tbody__box'])}>
            <div
              className={clsx(
                styles['c-table-availability__tbody__box__right']
              )}
            >
              <table>
                <tbody>
                  <tr>
                    <th
                      className={clsx(styles['left'], styles['spFixed'])}
                      key={0}
                    >
                      <p>{t('Name')}</p>
                    </th>
                    {columns.map((column, idx) => {
                      return (
                        <th key={idx + 1}>{getDateHeader(column, locale)}</th>
                      );
                    })}
                  </tr>

                  {items.map((item, idx) => (
                    <tr key={idx}>
                      <td
                        className={clsx(
                          baseStyles['base-t-80'],
                          styles['left'],
                          styles['spFixed']
                        )}
                      >
                        <p>{item.account.name}</p>
                        {process.env.NODE_ENV === 'development' && (
                          <p>{item.account.id}</p>
                        )}
                      </td>
                      {columns.map((column, idx) => {
                        return <Pax item={item} column={column} key={idx} />;
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {useScrollButton && (
          <>
            <div
              className={clsx(styles['c-table-main__btn'], styles['prev'])}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>

            <div
              className={clsx(styles['c-table-main__btn'], styles['next'])}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </>
        )}
      </div>

      {usePaging && (
        <>
          <div className={clsx(styles['c-pagination'])}>
            <div className={clsx(styles['c-pagination__size'])}>
              <p>{t('Number of lines')}:</p>
              <label>
                <select value={rowCount} onChange={rowCountChangeHandler}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="50">50</option>
                </select>
              </label>
            </div>
            <div className={clsx(styles['c-pagination__jump'])}>
              <p>{t('Page')}:</p>
              <input
                type="number"
                value={currentPage}
                onChange={currentPageHandler}
              />
              <p>of {pageCount}</p>
            </div>
            <div className={clsx(styles['c-pagination__btns'])}>
              <a
                className={clsx(
                  styles['c-pagination__btn'],
                  1 < currentPage ? '' : styles['disable']
                )}
                onClick={pageBackClickHandler}
              >
                {t('Previous')}
              </a>
              <a
                className={clsx(
                  styles['c-pagination__btn'],
                  currentPage < pageCount ? '' : styles['disable']
                )}
                onClick={pageForwardClickHandler}
              >
                {t('Next')}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};
