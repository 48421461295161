import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Button, FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { ColumnType } from 'client/libraries/util/ColumnType';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';

import styles from './Confirm.module.css';
import { FormValues } from './formValues';

interface Props {
  onBackClick: () => void;
  loading: boolean;
}

export const Confirm = ({ onBackClick, loading }: Props) => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const columns: ColumnType<ReservationSummaryShape>[] = [
    {
      Header: '',
      th: true,
      Cell: (cellInfo) => (
        <a
          href={`/reservations/${cellInfo.original.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Reservation')}
        >
          <img src={reservationDetailIcon} />
        </a>
      ),
      id: 'details',
    },
    {
      Header: t('Product Name'),
      accessor: (row) => row.internal_product_name ?? row.product_name,
      id: 'productName',
    },
    {
      Header: t('Participation'),
      accessor: (row) => row.participates_at?.locale(locale).format('lll'),
      id: 'participation',
    },
    {
      Header: t('Customer'),
      accessor: (row) => row.guest_display_name,
      id: 'customer',
    },
  ];

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="green" size="middle">
            {t('Send Bulk Email')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Confirm Email Details')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <FieldWrapper label={t('Subject')}>{values.subject}</FieldWrapper>
          <Box mt={2}>
            <FieldWrapper label={t('Description')}>
              <div className="newline">{values.description}</div>
            </FieldWrapper>
          </Box>
          {(values.attachments ?? []).length > 0 && (
            <Box mt={2}>
              <FieldWrapper label={t('Attachments')}>
                <div className="newline">
                  <ul>
                    {values.attachments.map((a, idx) => (
                      <li key={idx}>
                        <a
                          className={baseStyles['base-link']}
                          href={a.url}
                          download
                        >
                          {a.filename}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </FieldWrapper>
            </Box>
          )}
          <Box mt={2}>
            <FieldWrapper
              label={t('Reservations to Notify (Total: {{count}})', {
                count: values.reservations?.length ?? 0,
              })}
            >
              <CustomTable columns={columns} items={values.reservations} />
            </FieldWrapper>
          </Box>
        </div>
      </div>
    </div>
  );
};
