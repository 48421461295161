// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { currency } from 'shared/libraries/currency';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { printUnitTitle } from 'client/libraries/util/coreutil';
import { MoneyInput } from 'client/components/MoneyInput/MoneyInput';
import { PercentInput } from 'client/pages/ProductPriceList/PercentInput';
import type { ProductSummary, UnitPricing } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import './ProductPriceTable.css';
import productDetailIcon from 'client/images/ic_productsDetail.svg';
import { getScheduleText } from 'client/libraries/util/getScheduleText';
import { toProductShapePriceSchedule } from 'client/libraries/util/productShape';

type Props = {
  agentId: string,
  allowEditing: boolean,
  onProductsChange: (ProductSummary[]) => void,
  products: ProductSummary[],
  pricePeriodStartDate?: string,
  pricePeriodEndDate?: string,
};

export const ProductPriceTable = ({
  agentId,
  allowEditing,
  onProductsChange,
  products,
  pricePeriodStartDate,
  pricePeriodEndDate,
}: Props) => {
  const { t } = useTranslation();

  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const columnHeaders = [
    t('Product'),
    t('Price Period'),
    t('Unit'),
    t('Gross'),
    t('Net'),
    t('Commission Rate'),
    t('Type'),
  ];

  let rows = [];
  products.forEach((product) => {
    const priceSchedules = (product.pricing || []).filter((priceSchedule) => {
      if (
        pricePeriodEndDate &&
        (!priceSchedule.end_date_local ||
          priceSchedule.end_date_local > pricePeriodEndDate)
      ) {
        return false;
      }
      if (
        pricePeriodStartDate &&
        priceSchedule.end_date_local &&
        priceSchedule.end_date_local < pricePeriodStartDate
      ) {
        return false;
      }

      if (!priceSchedule.units || priceSchedule.units.length === 0) {
        return false;
      }

      return true;
    });

    const handleProductChange = (newProduct) =>
      onProductsChange(
        products.map((p) => (p.id === newProduct.id ? newProduct : p))
      );

    if (priceSchedules.length === 0) {
      return;
    }

    let row = {
      productId: product.id,
      productName: getDisplayProductName(product),
      unitPrices: [],
      periodUnitPrices: [],
    };

    priceSchedules.forEach((priceSchedule, priceScheduleIdx) => {
      let periodUnitPrice = {
        pricePeriod: getScheduleText(
          toProductShapePriceSchedule(priceSchedule),
          t
        ),
        unitPrices: [],
      };

      const handlePriceScheduleChange = (newPriceSchedule) =>
        handleProductChange({
          ...product,
          pricing: priceSchedules.map((priceSched, idx) =>
            priceScheduleIdx === idx ? newPriceSchedule : priceSched
          ),
        });

      const units = priceSchedule.units;

      units.forEach((unit: UnitPricing, unitIdx) => {
        const currencyCode = unit.gross.slice(0, 3);

        let net = unit.net;

        if (agentId) {
          const agentNetPrice = (unit.per_agent_net || []).find(
            (netPrice) => netPrice.agent_id === agentId
          );
          if (agentNetPrice) {
            net = agentNetPrice.net_fixed;
          }
        }

        const commissionPercent =
          currency(unit.gross).value === 0
            ? 0
            : Math.round(
                (currency(unit.gross).subtract(net).value /
                  currency(unit.gross).value) *
                  10000
              ) / 100;

        const handleUnitChange = (newUnit) =>
          handlePriceScheduleChange({
            ...priceSchedule,
            units: units.map((u, idx) => (idx === unitIdx ? newUnit : u)),
          });
        const handleNetChange = (newNet) => {
          if (agentId) {
            handleUnitChange({
              ...unit,
              per_agent_net: [
                { agent_id: agentId, net_fixed: newNet },
                ...(unit.per_agent_net || []).filter(
                  (netPrice) => netPrice.agent_id !== agentId
                ),
              ],
            });
          } else {
            handleUnitChange({
              ...unit,
              net: newNet,
            });
          }
        };
        const handleCommissionPercentChange = (newCommissionPercent) => {
          const newNet = currency(unit.gross)
            .subtract(currency(unit.gross).multiply(newCommissionPercent / 100))
            .format();
          handleNetChange(newNet);
        };

        periodUnitPrice.unitPrices.push({
          unit: (
            <>
              {unit.method === 'PER_BOOKING'
                ? t('Per-booking')
                : printUnitTitle(unit, t)}
            </>
          ),
          gross: <>{unit.gross}</>,
          net: (
            <>
              {allowEditing ? (
                <MoneyInput
                  currencyCode={currencyCode}
                  moneyAmount={net}
                  onChange={handleNetChange}
                />
              ) : (
                net
              )}
            </>
          ),
          commissionPercent: (
            <>
              {allowEditing ? (
                <>
                  <div className={clsx(baseStyles['inline-block'])}>
                    <PercentInput
                      value={commissionPercent}
                      onChange={handleCommissionPercentChange}
                    />
                    %
                  </div>
                </>
              ) : (
                `${commissionPercent.toFixed(2)}%`
              )}
            </>
          ),
          commissionDefaultOrCustom: (
            <>{unit.net === net ? t('Default') : t('Specific')}</>
          ),
        });
      });

      row.periodUnitPrices.push(periodUnitPrice);
    });

    rows.push(row);
  });

  const leftNum = 1;

  return (
    <div className={clsx('price-table')}>
      <div className={clsx('price-table__thead')} ref={tableHeaderRef}>
        <div className={clsx('price-table__thead__left')}>
          <div className={clsx(baseStyles['base-t-48'])}></div>
          {columnHeaders
            .filter((c, idx) => {
              return idx < leftNum;
            })
            .map((header) => (
              <div key={header}>{header}</div>
            ))}
        </div>
        <div className={clsx('price-table__thead__right')}>
          <table>
            <tbody>
              <tr>
                {columnHeaders
                  .filter((c, idx) => {
                    return idx >= leftNum;
                  })
                  .map((header) => (
                    <th key={header}>{header}</th>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        className={clsx('price-table__tbody')}
        onScroll={scrollHandler}
        ref={tableBodyRef}
      >
        {rows.map((row, idx) => (
          <div className={clsx('price-table__tbody__box')} key={idx}>
            <div className={clsx('price-table__tbody__box__left')}>
              <div className={clsx(baseStyles['base-t-48'])}>
                <Link
                  to={`/products/${row.productId}`}
                  className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  data-text={t('Detail')}
                >
                  <img src={productDetailIcon} />
                </Link>
              </div>
              <div>
                <Link to={`/products/${row.productId}`} data-text={t('Detail')}>
                  {row.productName}
                </Link>
              </div>
            </div>

            <div className={clsx('price-table__tbody__box__right')}>
              <table className={clsx('price-table')}>
                <tbody>
                  <tr className={clsx('price-table')}>
                    {columnHeaders
                      .filter((c, idx) => {
                        return idx >= leftNum;
                      })
                      .map((header) => (
                        <th className={clsx('price-table')}>{header}</th>
                      ))}
                  </tr>

                  {row.periodUnitPrices.map((periodUnitPrice, pidx) =>
                    periodUnitPrice.unitPrices.map((unit, uidx) => (
                      <tr
                        className={clsx('price-table')}
                        key={pidx * 100 + uidx}
                      >
                        {uidx === 0 && (
                          <td
                            rowspan={periodUnitPrice.unitPrices.length}
                            className={clsx('price-table')}
                          >
                            {' '}
                            {periodUnitPrice.pricePeriod}{' '}
                          </td>
                        )}
                        <td className={clsx('price-table')}>{unit.unit}</td>
                        <td className={clsx('price-table')}>{unit.gross}</td>
                        <td className={clsx('price-table')}>{unit.net}</td>
                        <td className={clsx('price-table')}>
                          {unit.commissionPercent}
                        </td>
                        <td className={clsx('price-table')}>
                          {unit.commissionDefaultOrCustom}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
