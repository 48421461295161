import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { TextField } from 'client/components/v3/Form/TextField';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { Button } from 'client/components/v3/Common/Button';
import type { ReduxState } from 'client/reducers';
import { changePassword } from 'client/actions/user';
import styles from 'client/pages/v3/Settings/Settings.module.css';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lastChangingPasswordStatus = useSelector(
    (state: ReduxState) => state.user.lastChangingPasswordStatus
  );
  const changeError = useSelector(
    (state: ReduxState) => state.user.changingPasswordError
  );

  const [newPassword, setNewPassword] = React.useState<string>('');
  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [newPasswordHidden, setNewPasswordHidden] =
    React.useState<boolean>(false);

  const onChangePassword = (oldPassword: string, newPassword: string) =>
    dispatch(changePassword(oldPassword, newPassword));

  React.useEffect(() => {
    if (lastChangingPasswordStatus === 'SUCCEEDED') {
      setSuccess(true);
    }
  }, [lastChangingPasswordStatus]);

  React.useEffect(() => {
    if (changeError != '') {
      setError(true);
    }
  }, [changeError]);

  return (
    <div>
      <div className={styles['input-box']}>
        <div
          style={{
            fontWeight: 'var(--text-semibold)',
            fontSize: 'var(--text-sm-size)',
            lineHeight: 'var(--text-sm-height)',
            marginBottom: '10px',
          }}
        >
          {t('Old password')}
        </div>
        <TextField
          type="password"
          name="oldPassword"
          value={oldPassword}
          onChange={(value) => setOldPassword(value)}
        />
      </div>
      <div className={styles['input-box']}>
        <div
          style={{
            fontWeight: 'var(--text-semibold)',
            fontSize: 'var(--text-sm-size)',
            lineHeight: 'var(--text-sm-height)',
            marginBottom: '10px',
          }}
        >
          {t('New password')}
        </div>
        <TextField
          type={newPasswordHidden ? 'password' : 'text'}
          name="newPassword"
          value={newPassword}
          onChange={(value) => setNewPassword(value)}
        />
        <div style={{ marginTop: '20px' }}>
          <Checkbox
            label={t('Show Password')}
            onChange={() => setNewPasswordHidden(!newPasswordHidden)}
            checked={!newPasswordHidden}
            size="md"
          />
        </div>
      </div>
      {error && (
        <Snackbar
          color="error"
          text={t('Password change failed')}
          shouldShow={error}
          onClose={() => setError(false)}
        />
      )}
      {success && (
        <Snackbar
          color="success"
          text={t('Password change succeeded')}
          shouldShow={success}
          onClose={() => setSuccess(false)}
        />
      )}
      <div className={styles['button-box']}>
        <Button
          color="primary"
          text={t('Change Password')}
          size="md"
          onClick={() => {
            onChangePassword(oldPassword, newPassword);
          }}
        />
      </div>
    </div>
  );
};
