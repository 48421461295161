import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'client/components/v3/Form/Toggle';

export const EmailSettingsInput = () => {
  const { t } = useTranslation();

  return (
    <Field type="checkbox" name="shouldSendReminderEmail">
      {({ input }) => {
        return (
          <Toggle
            {...input}
            label={t(
              'Send reminder email to guests one day prior to participation'
            )}
          />
        );
      }}
    </Field>
  );
};
