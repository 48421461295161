import { useTranslation } from 'react-i18next';

import { JourneyAnalyticsSettings } from 'shared/models/swagger';

import styles from './SearchPresetDisplay.module.css';
import { ConditionGroupDisplay } from './ConditionGroupDisplay';

type Preset = Exclude<JourneyAnalyticsSettings['presets'], undefined>[0];

interface Props {
  preset: Preset;
}

export const SearchPresetDisplay = ({ preset }: Props) => {
  const { t } = useTranslation();

  const conditionGroupCount = preset.condition_groups?.length ?? 0;

  return (
    <div className={styles['container']}>
      {preset.condition_groups?.map((conditionGroup, idx) => (
        <>
          <ConditionGroupDisplay conditionGroup={conditionGroup} />
          {idx < conditionGroupCount - 1 && (
            <hr
              className={styles['condition-group-divider']}
              data-content={t('OR')}
            />
          )}
        </>
      ))}
    </div>
  );
};
