import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { AccessReportGadgetParams } from 'client/reducers/dashboardSettings';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import { useAccessReportData } from 'client/pages/v3/FlexibleDashboard/useAccessReportData';
import { ModalLoader } from 'client/components/ModalLoader';
import { AccessReportBarChart } from 'client/pages/v3/FlexibleDashboard/AccessReportGadget/AccessReportBarChart';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';

interface DateRange {
  start: string;
  end: string;
}

interface Props {
  params: AccessReportGadgetParams;
  positionInRow?: 'left' | 'right' | 'middle';
}

export const AccessReportGadget = ({ params, positionInRow }: Props) => {
  const { t } = useTranslation();
  const [baseDateRange, setBaseDateRange] = React.useState<DateRange>(
    getDateRangeForPreset(params.dateRange)
  );
  const [comparisonDateRange, setComparisonDateRange] =
    React.useState<DateRange | null>(null);

  const { data, isLoading } = useAccessReportData(
    baseDateRange,
    params,
    comparisonDateRange
  );

  // Update when params changed
  React.useEffect(() => {
    setBaseDateRange(getDateRangeForPreset(params.dateRange));
  }, [params]);

  let dataToDisplay = null;
  if (isLoading) {
    dataToDisplay = <ModalLoader />;
  } else {
    if (data === null || (data && data[0].items?.length === 0)) {
      dataToDisplay = (
        <div>{t('No data to display for the selected data set')}</div>
      );
    } else {
      if (params.displayType === 'BAR_CHART' && data) {
        dataToDisplay = (
          <AccessReportBarChart reportDataSets={data} params={params} />
        );
      }
      // TODO: Add other display data type here ater data retrieval is implemented
      // Top 5 products
      // TOp 5 landing sources
    }
  }

  let dateInputMenuStyle;
  if (positionInRow === 'right') {
    dateInputMenuStyle = { right: 0, left: 'auto' };
  } else {
    dateInputMenuStyle = { right: 'auto', left: 0 };
  }

  return (
    <>
      <div className={styles['p-transition']}>
        <ComparisonDateInput
          baseDateRange={baseDateRange}
          setBaseDateRange={setBaseDateRange}
          comparisonDateRange={comparisonDateRange}
          setComparisonDateRange={setComparisonDateRange}
          menuStyle={dateInputMenuStyle}
        />
        <div className={styles['p-transition__body']}>{dataToDisplay}</div>
      </div>
    </>
  );
};
