import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_DASHBOARD_SETTINGS_REQUEST,
  FETCH_DASHBOARD_SETTINGS_SUCCESS,
  FETCH_DASHBOARD_SETTINGS_FAILURE,
  PUT_DASHBOARD_SETTINGS_REQUEST,
  PUT_DASHBOARD_SETTINGS_SUCCESS,
  PUT_DASHBOARD_SETTINGS_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchDashboardSettingsRequest = () => ({
  type: FETCH_DASHBOARD_SETTINGS_REQUEST,
});

const fetchDashboardSettingsSuccess = (response: any) => ({
  type: FETCH_DASHBOARD_SETTINGS_SUCCESS,
  response,
});

const fetchDashboardSettingsFailure = (error: any) => ({
  type: FETCH_DASHBOARD_SETTINGS_FAILURE,
  error,
});

let fetchDashboardSettingsCancel: () => void | typeof undefined;
export const fetchDashboardSettings =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchDashboardSettingsCancel && fetchDashboardSettingsCancel();
    dispatch(fetchDashboardSettingsRequest());
    axios
      .get('/api/accountsettings', {
        params: {
          key: 'dashboard',
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchDashboardSettingsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchDashboardSettingsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchDashboardSettingsFailure('canceled'));
        else dispatch(fetchDashboardSettingsFailure(error));
      });
  };

const putDashboardSettingsRequest = () => ({
  type: PUT_DASHBOARD_SETTINGS_REQUEST,
});

const putDashboardSettingsSuccess = (response: any) => ({
  type: PUT_DASHBOARD_SETTINGS_SUCCESS,
  response,
});

const putDashboardSettingsFailure = (error: any) => ({
  type: PUT_DASHBOARD_SETTINGS_FAILURE,
  error,
});

let putDashboardSettingsCancel: () => void | typeof undefined;
export const putDashboardSettings =
  (data: string) => async (dispatch: Dispatch, getState: () => ReduxState) => {
    putDashboardSettingsCancel && putDashboardSettingsCancel();
    dispatch(putDashboardSettingsRequest());
    return axios
      .put(
        '/api/accountsettings',
        {
          key: 'dashboard',
          data,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(function executor(c) {
            putDashboardSettingsCancel = c;
          }),
        }
      )
      .then((result) => {
        dispatch(putDashboardSettingsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(putDashboardSettingsFailure('canceled'));
        else dispatch(putDashboardSettingsFailure(error));
      });
  };
