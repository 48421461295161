import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { TextArea } from 'client/components/v3/Form/TextArea';
import { ReservationActorInputForm } from 'client/components/v3/ReservationActorInputForm';
import { MessageModal } from 'client/components/v3/MessageModal/MessageModal';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { replyToInquiry } from 'client/actions/inquiries';
import { Box } from 'client/components/Box/Box';
import {
  InquiryAttachmentsEditor,
  InquiryAttachment,
} from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/InquiryAttachmentsEditor';
import baseStyles from 'client/v3-base.module.css';
import { Inquiry } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';

import styles from './InquiryResponseSection.module.css';

interface FormValues {
  message: string;
  cc: string[];
  bcc: string[];
  attachments: InquiryAttachment[];
}

interface Props {
  inquiry: Inquiry;
  onClose: () => void;
}

const initialValues: FormValues = {
  message: '',
  cc: [''],
  bcc: [''],
  attachments: [],
};

export const InquiryResponseSection = ({ inquiry, onClose }: Props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<'EDIT' | 'CONFIRM'>('EDIT');
  const { required } = getValidators(t);
  const dispatch = useDispatch();
  const isSms = Boolean(inquiry.customer_phone);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        switch (step) {
          case 'EDIT':
            setStep('CONFIRM');
            break;
          case 'CONFIRM':
            await dispatch(
              replyToInquiry(
                inquiry.id ?? '',
                values.message,
                values.cc,
                values.bcc,
                values.attachments?.map((attachment) => ({
                  url: attachment.url,
                  filename: attachment.filename,
                }))
              )
            );

            onClose();
            break;
        }
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles['p-contactCard__body__reply__detail']}>
            <div className={styles['p-contactCard__body__reply__detail__item']}>
              <ReservationActorInputForm label={t('Sender')} />
              <p
                className={
                  styles['p-contactCard__body__reply__detail__item__note']
                }
              >
                {t(
                  'If you want to change the name that appears on outgoing emails, please change here.'
                )}
              </p>
            </div>
            {inquiry.customer_email && (
              <>
                <div
                  className={styles['p-contactCard__body__reply__detail__item']}
                >
                  <p
                    className={
                      styles['p-contactCard__body__reply__detail__item__ttl']
                    }
                  >
                    {t('CC')}
                  </p>
                  <ListEditor name="cc" addButtonText={t('add CC')} />
                </div>
                <div
                  className={styles['p-contactCard__body__reply__detail__item']}
                >
                  <p
                    className={
                      styles['p-contactCard__body__reply__detail__item__ttl']
                    }
                  >
                    {t('BCC')}
                  </p>
                  <ListEditor name="bcc" addButtonText={t('add BCC')} />
                </div>
              </>
            )}
            <div className={styles['p-contactCard__body__reply__detail__item']}>
              <Field name="message" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <TextArea
                    label={t('Message')}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                    error={touched && error}
                    showCharacterCount={isSms}
                    height={120}
                  />
                )}
              </Field>
            </div>
            {isSms && (
              <Box>
                <div>
                  {t(
                    '* 1 message cost will be for approximately 60 characters.'
                  )}
                </div>
                <div>
                  {t(
                    '* Long messages may be split into multiple messages and charged for multiple messages'
                  )}
                </div>
                <div>
                  {t(
                    '* When including URLs in messages, we recommend shortening the URLs with a service like '
                  )}
                  <a
                    className={baseStyles['base-link']}
                    href="https://bitly.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bitly.com
                  </a>
                </div>
              </Box>
            )}
            {!isSms && (
              <div
                className={styles['p-contactCard__body__reply__detail__item']}
              >
                <p
                  className={
                    styles['p-contactCard__body__reply__detail__item__ttl']
                  }
                >
                  {t('Attachments')}
                </p>
                <Field name="attachments">
                  {({ input }) => (
                    <InquiryAttachmentsEditor
                      attachments={input.value}
                      onChange={(newAttachments: InquiryAttachment[]) =>
                        input.onChange(newAttachments)
                      }
                    />
                  )}
                </Field>
              </div>
            )}
            <div
              className={styles['p-contactCard__body__reply__detail__actions']}
            >
              <Button
                text={t('Cancel')}
                color="white"
                onClick={() => {
                  onClose();
                }}
              />
              <Button
                text={t('Send')}
                onClick={() => {
                  form.submit();
                }}
              />
            </div>
          </div>
          {step === 'CONFIRM' && (
            <MessageModal
              onClose={() => setStep('EDIT')}
              title={t('Send Response')}
              message={t(
                'Are you sure you wish to send this inquiry response?'
              )}
              onSubmit={handleSubmit}
              insertRoot
              loading={submitting}
            />
          )}
        </form>
      )}
    </Form>
  );
};
