import React from 'react';
import clsx from 'clsx';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import type { TFunction } from 'react-i18next';

import { PARTICIPANT_UNLIMITED } from 'client/constants/participantUnlimited';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import { createReservation } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { checkAvailability } from 'client/actions/availability';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import {
  fetchActimReservationByID,
  updateActimReservation,
} from 'client/actions/actimReservations';
import {
  getFieldResponseErrors,
  printGuestType,
  convertLocationWithMomentToReservationLocationWithTimeInput,
  convertToLocationWithMoment,
} from 'client/libraries/util/coreutil';
import { findClosestPickupLocation } from 'client/libraries/util/findClosestPickupLocation';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import {
  getReservationLineItems,
  generateAgentReference,
  getGuestTypesUsedInProductInstance,
  getDisplayReservationFormFields,
  histogram,
} from 'client/libraries/util/util';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { Loading } from 'client/pages/Loading';
import { SubmitReservationButton } from 'client/pages/BookingForm/SubmitReservationButton';
import { FareSummaryV2 as FareSummary } from 'client/pages/BookingForm/FareSummaryV2';
import { AmountDifference } from 'client/pages/BookingForm/AmountDifference';
import { PaymentForChangeReservation } from 'client/pages/BookingForm/PaymentForChangeReservation';
import { needToOpenNoEmailWithPaymentLinkMessageModal } from 'client/pages/BookingForm/util';
import { FormFieldV2 as FormField } from 'client/components/FormFieldV2';
import {
  FieldWrapper,
  Checkbox,
  MultiSelect,
  MoneyInput,
  Button,
} from 'client/components/Form';
import { FieldsFormV2 as FieldsForm } from 'client/components/FieldsFormV2';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { separatedCurrency as currency } from 'shared/libraries/currency';
import type { ReduxState } from 'client/reducers';
import { ReservationCreateModalButton } from 'client/components/ReservationCreateModal/ReservationCreateModalButton';
import { ChangeProductAndParticipationDate } from 'client/pages/BookingForm/ChangeProductAndParticipationDate';
import { Message } from 'client/components/Message/Message';
import { disableRedirectAfterCreatingReservation } from 'client/debugging';
import { PromoCodeInput } from 'client/pages/BookingForm/PromoCodeInput';
import { ReservationFareAdjustmentModal } from 'client/pages/BookingForm/ReservationFareAdjustmentModal';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { AlertModal } from 'client/components/AlertModal/AlertModal';
import {
  AccordionItem,
  AccordionItemWithRef,
} from 'client/components/NewProductEditor/Accordion/Accordion';
import { Box } from 'client/components/Box/Box';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import {
  getNotEnoughAvailableResources,
  getInitialAddOnResources,
} from 'client/libraries/util/resourceManager';
import { fetchCustomerByID } from 'client/actions/customers';
import type {
  LocationWithTime,
  ProductInstance,
  NewReservation,
  Reservation,
  Service,
  BookingSourceType,
  Field,
  GuestType,
  Guest,
  Product,
  ActimReservation$Patch,
  Customer,
  ReservationBillingInfo,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/BookingForm/Book.module.css';
import editIcon from 'client/images/ic_edit.svg';
import oneIcon from 'client/images/ic_one.svg';
import twoIcon from 'client/images/ic_two.svg';
import threeIcon from 'client/images/ic_three.svg';
import fourIcon from 'client/images/ic_four.svg';
import fiveIcon from 'client/images/ic_five.svg';
import bookingSummaryIcon from 'client/images/ic_bookingsummary.svg';
import { NumberInputModal } from 'client/components/NumberInputModal/NumberInputModal';

import { TruncatedLabel } from './TruncatedLabel/TruncatedLabel';

const getPerBookingAddOns = (addOns: Service[]): Service[] => {
  return addOns.filter(
    (addOn) =>
      addOn.service_type === 'FREE' ||
      (addOn.pricing && addOn.pricing.find((p) => p.method === 'PER_BOOKING'))
  );
};
const getPerParticipantAddOns = (addOns: Service[]): Service[] => {
  return addOns.filter(
    (addOn) =>
      addOn.pricing && addOn.pricing.find((p) => p.method === 'PER_PARTICIPANT')
  );
};

const getGuestSummary = (guests: Guest[], guestTypes: GuestType[]): string => {
  const guestCounts = histogram(guests, (guest) => guest.guest_type_key);

  return (
    guestTypes
      .filter((guestType) => guestCounts[guestType.key])
      .map((guestType) => `${guestType.title} x ${guestCounts[guestType.key]}`)
      .join(', ') || '-'
  );
};

const AddOnsSummary = ({
  reservation,
  addOns,
  guestTypes,
}: {
  reservation?: NewReservation;
  addOns: Service[];
  guestTypes: GuestType[];
}) => {
  const addOnItems: string[] = [];

  const perBookingAddOns = getPerBookingAddOns(addOns ?? []);
  const perParticipantAddOns = getPerParticipantAddOns(addOns ?? []);

  for (const addOn of perBookingAddOns) {
    if (reservation?.add_ons?.includes(addOn.key ?? '')) {
      addOnItems.push(`${addOn.title} x 1`);
    }
  }

  for (const addOn of perParticipantAddOns) {
    if (
      reservation?.guests?.some((guest) =>
        guest.add_ons?.includes(addOn.key ?? '')
      )
    ) {
      for (const guestType of guestTypes) {
        const count = (
          reservation?.guests?.filter(
            (guest) =>
              guest.guest_type_key === guestType.key &&
              guest.add_ons?.includes(addOn.key ?? '')
          ) ?? []
        ).length;
        if (count) {
          addOnItems.push(`${addOn.title} ${guestType.title} x ${count}`);
        }
      }
    }
  }

  if (addOnItems.length === 0) {
    return <div>-</div>;
  }

  return <div>{addOnItems.join(', ')}</div>;
};

interface Location {
  state: {
    reservation?: Reservation;
    isResubmitReservation?: boolean;
    isChangeReservation?: boolean;
    isFromNewReservationModal?: boolean;
    customerId?: string;
  };
  search?: string;
}

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  isPreview?: boolean;
  reservationError?: string;
};

type RouteProps = {
  location: Location;
  match: {
    params: {
      productID: string;
      instanceID: string;
    };
  };
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  RouteProps &
  I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
type State = {
  reservationError: string;
  createdReservationID: string;
  defaultAgentReference: string;
  newReservation: NewReservation;
  productInstanceToRedirectTo: ProductInstance | null | undefined;
  agents: {
    id: string;
    name: string;
  }[];
  lastSubmissionValidationErrorMap: Record<string, string>;
  pickupDropoffUseSameLocation: boolean;
  isChangeReservation?: boolean;
  isResubmitReservation?: boolean;
  isFromNewReservationModal?: boolean;
  reservation?: Reservation;
  changeProductInstanceErrorMessage?: string;
  openCalendar?: boolean;
  sourceReservationProductId?: string | null;
  openNoEmailWithPaymentLinkMessageModal: boolean;
  openSubmitReservationMessageModal: boolean;
  openCreditCardChargeOrRefundForConfirmedReservationMessageModal: boolean;
  openCreditCardChargeForRequestedReservationMessageModal: boolean;
  showChangeReservationProductKeysHaveBeenResetAlert: boolean;
  showParticipantDeletionErrorAlert: boolean;
  showParticipantDeletionErrorAlertModal: boolean;
  showParticipantDeletionErrorGuestTypeKey: string;
  showBookingSummaryModal: boolean;
  actimReservationId: string;
  initialAvailablePerParticipantAddOns: { [key: string]: number };
  initialAvailablePerBookingAddOns: { [key: string]: number };
  availablePerParticipantAddOns: { [key: string]: number };
  notEnoughAvailableResources: string[];
  editAdjustingFare: boolean;
  openReservationCreateModal: boolean;
  customerId?: string;
  numberInputGuestType?: GuestType;
};
type PaymentType = 'PAID_IN_FULL' | 'PAY_ON_BOARD' | 'PAID_PARTIALLY';
type PaymentMethod = 'INVOICE' | 'CREDIT_CARD' | 'CASH' | 'OTHER';
export class BookingFormComponent extends React.Component<Props, State> {
  placesService: Record<string, any> | undefined;
  hotelInputRef: React.Ref<HTMLInputElement>;
  bookingSummaryPopupRef: React.Ref<HTMLDivElement>;
  pageRef: React.Ref<HTMLDivElement>;
  addOnRef: React.Ref<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    // Pre-populated reservation fields might be stashed in router state.
    const { location, product, productInstance, t } = props;
    const reservation =
      location && location.state && location.state.reservation;
    const isResubmitReservation =
      location && location.state && location.state.isResubmitReservation;
    const isChangeReservation =
      location && location.state && location.state.isChangeReservation;
    // When `isFromNewReservationModal` is true, `isChangeReservation` will be always false, but `reservation` might not be null.
    let isFromNewReservationModal: boolean;
    isFromNewReservationModal = Boolean(
      location &&
        location.state &&
        location.state.isFromNewReservationModal &&
        !isChangeReservation &&
        reservation
    );

    let actimReservationId = '';

    const stateSearch = location && location.search;
    const params = new URLSearchParams(stateSearch);

    // Check if the request is from new reservation modal from query string.
    // This is valid when no reservation, change reservation flag found at location.state.
    if (!isFromNewReservationModal && !isChangeReservation && !reservation) {
      const paramNewReservationModal = params.get('new_reservation_modal');
      const paramActimReservationId = params.get('actim_reservation_id');

      if (paramNewReservationModal == '1') {
        isFromNewReservationModal = true;
      }

      if (paramActimReservationId != null) {
        actimReservationId = paramActimReservationId;
        // If actim_reservation_id exists. fetch actim_reservation data.
        this.props.fetchActimReservationByID(actimReservationId);
      }
    }

    const customerId =
      (location && location.state && location.state.customerId) ||
      params.get('customer_id') ||
      undefined;

    let baseNewReservation: NewReservation = {
      agent_reference: '',
      agent_notes: '',
      supplier_notes: '',
      product_instance_id: this.props.match.params.instanceID,
      guests: [],
      field_responses: [
        {
          key: 'preferred_language_iso2',
          response: props.locale,
        },
        ...(customerId ? [{ key: 'customer_id', response: customerId }] : []),
      ],
      payment_type: 'PAY_ON_BOARD',
      amount_pay_on_board: '',
      payment_deferred: false,
      email_payment_to_address: '',
      ...(!isFromNewReservationModal && reservation ? reservation : {}),
    };

    if (isResubmitReservation) {
      // When resubmitting a canceled booking:
      // For direct web bookings, use default booking source (phone) and default payment method (invoice).
      // For all other bookings, use the booking source and payment method from the canceled reservation.
      const bookingSource =
        reservation?.booking_source?.source_type !== 'DIRECT_WEB'
          ? { ...reservation?.booking_source }
          : {
              source_type: 'DIRECT_TELEPHONE',
              agent_name: '',
            };
      const paymentMethod =
        reservation?.booking_source?.source_type !== 'DIRECT_WEB'
          ? reservation?.payment_method
          : 'INVOICE';
      baseNewReservation = {
        ...baseNewReservation,
        booking_source: bookingSource as any,
        payment_method: paymentMethod,
        rebooked_from_reservation_id: '',
        amount_pay_on_board: '',
        payment_deferred: false,
        email_payment_to_address: '',
      };
    } else if (!isChangeReservation) {
      baseNewReservation = {
        ...baseNewReservation,
        booking_source: {
          source_type: 'DIRECT_TELEPHONE',
          agent_name: '',
        },
        payment_method: 'CASH',
        rebooked_from_reservation_id: '',
        amount_pay_on_board: '',
        payment_deferred: false,
        email_payment_to_address: '',
      };
    }

    // Open dropoff input form when
    // - Use change reservation
    const pickupDropoffUseSameLocation = isChangeReservation ? false : true;
    const newReservation = this.getNewReservation(
      baseNewReservation,
      reservation,
      product,
      productInstance,
      Boolean(isChangeReservation),
      t,
      isFromNewReservationModal
    );
    const showChangeReservationProductKeysHaveBeenResetAlert = Boolean(
      isChangeReservation &&
        ((newReservation.transportation ?? '') !==
          (reservation?.transportation ?? '') ||
          (newReservation.add_ons ?? []).length !==
            (reservation?.add_ons ?? []).length ||
          (newReservation.guests ?? []).length !==
            (reservation?.guests ?? []).length ||
          newReservation.guests?.some(
            (guest, idx) =>
              (newReservation.guests[idx].add_ons ?? []).length !==
              (reservation?.guests[idx]?.add_ons ?? []).length
          ))
    );
    this.state = {
      reservationError: '',
      defaultAgentReference: generateAgentReference(),
      newReservation,
      agents: product && (product.agents as any),
      createdReservationID: '',
      productInstanceToRedirectTo: null,
      lastSubmissionValidationErrorMap: {},
      pickupDropoffUseSameLocation,
      isChangeReservation: isChangeReservation || false,
      isResubmitReservation: isResubmitReservation || false,
      isFromNewReservationModal: isFromNewReservationModal || false,
      reservation: reservation,
      changeProductInstanceErrorMessage: '',
      openCalendar: isChangeReservation ? true : false,
      sourceReservationProductId: reservation ? reservation.product_id : null,
      openNoEmailWithPaymentLinkMessageModal: false,
      openSubmitReservationMessageModal: false,
      openCreditCardChargeOrRefundForConfirmedReservationMessageModal: false,
      openCreditCardChargeForRequestedReservationMessageModal: false,
      showChangeReservationProductKeysHaveBeenResetAlert,
      showParticipantDeletionErrorAlert: false,
      showParticipantDeletionErrorAlertModal: false,
      showParticipantDeletionErrorGuestTypeKey: '',
      showBookingSummaryModal: false,
      actimReservationId: actimReservationId,
      initialAvailablePerParticipantAddOns: {},
      initialAvailablePerBookingAddOns: {},
      availablePerParticipantAddOns: {},
      notEnoughAvailableResources: [],
      editAdjustingFare: false,
      openReservationCreateModal: false,
      customerId: customerId,
    };
    this.hotelInputRef = React.createRef();
    this.bookingSummaryPopupRef = React.createRef();
    this.pageRef = React.createRef();
    this.addOnRef = React.createRef();
  }

  handleClickOutside = (event: any) => {
    if (
      this.bookingSummaryPopupRef &&
      !(this.bookingSummaryPopupRef as any)?.current?.contains(event.target) &&
      this.state.showBookingSummaryModal
    ) {
      this.setState({
        showBookingSummaryModal: false,
      });
    }
  };

  componentDidMount() {
    // Fetch product info.
    this.props.fetchProductByID(this.props.match.params.productID);
    // Fetch product instance.
    this.props.fetchProductInstanceByID(this.props.match.params.instanceID);

    if (this.state.customerId) {
      this.props.fetchCustomerByID(this.state.customerId);
    }

    // If actim_reservation_id exists. fetch actim_reservation data.
    //this.props.fetchActimReservationByID(this.state.actimReservationId);

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const newFamilyNameField =
      this.state.newReservation.field_responses &&
      this.state.newReservation.field_responses.find(
        (f) => f.key === 'family_name'
      );
    const newGivenNameField =
      this.state.newReservation.field_responses &&
      this.state.newReservation.field_responses.find(
        (f) => f.key === 'given_name'
      );
    const newFamilyName = newFamilyNameField ? newFamilyNameField.response : '';
    const newGivenName = newGivenNameField ? newGivenNameField.response : '';
    const prevFamilyNameField =
      prevState.newReservation.field_responses &&
      prevState.newReservation.field_responses.find(
        (f) => f.key === 'family_name'
      );
    const prevGivenNameField =
      prevState.newReservation.field_responses &&
      prevState.newReservation.field_responses.find(
        (f) => f.key === 'given_name'
      );
    const prevFamilyName = prevFamilyNameField
      ? prevFamilyNameField.response
      : '';
    const prevGivenName = prevGivenNameField ? prevGivenNameField.response : '';

    if (
      (newGivenName !== prevGivenName || newFamilyName !== prevFamilyName) &&
      newGivenName !== '' &&
      newFamilyName !== ''
    ) {
      this.setState({
        newReservation: {
          ...this.state.newReservation,
          guests: this.state.newReservation.guests.map((guest, idx) =>
            idx === 0
              ? {
                  ...guest,
                  field_responses: [
                    ...(guest.field_responses || []).filter(
                      (r) => r.key !== 'full_name'
                    ),
                    {
                      key: 'full_name',
                      response:
                        newGivenName && newFamilyName
                          ? newGivenName + ' ' + newFamilyName
                          : '',
                    },
                  ],
                }
              : guest
          ),
        },
      });
    }

    if (
      prevProps.actimReservation[this.state.actimReservationId] !=
        this.props.actimReservation[this.state.actimReservationId] &&
      this.props.actimReservation[this.state.actimReservationId] != null
    ) {
      const actimReservation =
        this.props.actimReservation[this.state.actimReservationId];
      this.setState({
        newReservation: {
          ...this.state.newReservation,
          agent_notes: actimReservation?.note,
          supplier_internal_notes: actimReservation?.memo,
          agent_reference: actimReservation?.id,
          field_responses: [
            {
              key: 'preferred_language_iso2',
              response: this.props.locale,
            },
            ...(actimReservation?.reserve_user?.name
              ? [
                  {
                    key: 'family_name',
                    response: actimReservation.reserve_user.name,
                  },
                ]
              : []),
            ...(actimReservation?.reserve_user?.name
              ? [
                  {
                    key: 'full_name',
                    response: actimReservation.reserve_user.name,
                  },
                ]
              : []),
            ...(actimReservation?.reserve_user?.tel
              ? [
                  {
                    key: 'phone',
                    response: actimReservation.reserve_user.tel,
                  },
                ]
              : []),
            ...(actimReservation?.reserve_user?.age
              ? [
                  {
                    key: 'age',
                    response: `${actimReservation.reserve_user.age}`,
                  },
                ]
              : []),
            ...(actimReservation?.reserve_user?.gender
              ? [
                  {
                    key: 'gender',
                    response: `${actimReservation.reserve_user.gender}`,
                  },
                ]
              : []),
            ...(actimReservation?.reserve_user?.mail
              ? [
                  {
                    key: 'email',
                    response: actimReservation.reserve_user.mail,
                  },
                ]
              : []),
          ],
        },
      });
    }
    if (
      this.state.agents != null &&
      this.state.newReservation?.booking_source?.agent_id == null &&
      this.props.actimReservation[this.state.actimReservationId]?.agent_id !=
        null
    ) {
      const actimReservation =
        this.props.actimReservation[this.state.actimReservationId];
      if (actimReservation.agent_id != '') {
        const a = this.state.agents.filter(
          (agent) => agent.id == actimReservation.agent_id
        );
        this.setState({
          newReservation: {
            ...this.state.newReservation,
            booking_source: {
              source_type: 'AGENT',
              agent_id: a[0].id,
              agent_name: a[0].name,
            },
          },
        });
      }
    }

    const prevParams = prevProps.match.params;
    const newParams = this.props.match.params;

    // Fetch product info again
    if (
      this.props.invalidated ||
      this.props.locale !== prevProps.locale ||
      prevParams.productID !== newParams.productID
    ) {
      this.props.fetchProductByID(newParams.productID);
    }

    // Fetch product instance again
    if (
      this.props.invalidated ||
      this.props.locale !== prevProps.locale ||
      prevParams.instanceID !== newParams.instanceID
    ) {
      this.props.fetchProductInstanceByID(newParams.instanceID);
    }

    if (prevProps.reservationError !== this.props.reservationError) {
      this.setState({
        reservationError: this.props.reservationError ?? '',
      });
    }

    if (
      prevProps.lastCreatedReservationID !== this.props.lastCreatedReservationID
    ) {
      this.setState({
        createdReservationID: this.props.lastCreatedReservationID,
      });
    }

    // Update product info, product instance states.
    if (
      prevProps.product !== this.props.product ||
      prevProps.productInstance !== this.props.productInstance
    ) {
      const { product, productInstance, t } = this.props;
      let pickup = undefined;
      let dropoff = undefined;
      let checkin = undefined;
      let checkout = undefined;
      let transportationKey = '';

      if (product && productInstance) {
        if (prevState.sourceReservationProductId === product.id) {
          const productStartTimeUTC = moment(
            productInstance.start_date_time_utc
          );
          const sourceReservationStartTimeUTC = moment(
            prevState.reservation?.start_date_time_utc
          );
          pickup = prevState.reservation?.pickup;
          dropoff = prevState.reservation?.dropoff;
          checkin = prevState.reservation?.checkin;
          checkout = prevState.reservation?.checkout;
          transportationKey = prevState.reservation?.transportation ?? '';

          if (
            productStartTimeUTC.format('HH:mm') ===
            sourceReservationStartTimeUTC.format('HH:mm')
          ) {
            if (pickup && pickup.date_time_utc) {
              pickup.date_time_utc = this.getParticipationDateTime(
                pickup?.date_time_utc,
                productStartTimeUTC,
                sourceReservationStartTimeUTC
              );
            }

            if (dropoff && dropoff.date_time_utc) {
              dropoff.date_time_utc = this.getParticipationDateTime(
                dropoff?.date_time_utc,
                productStartTimeUTC,
                sourceReservationStartTimeUTC
              );
            }

            if (checkin && checkin.date_time_utc) {
              checkin.date_time_utc = this.getParticipationDateTime(
                checkin?.date_time_utc,
                productStartTimeUTC,
                sourceReservationStartTimeUTC
              );
            }

            if (checkout && checkout.date_time_utc) {
              checkout.date_time_utc = this.getParticipationDateTime(
                checkout?.date_time_utc,
                productStartTimeUTC,
                sourceReservationStartTimeUTC
              );
            }
          } else {
            if (pickup) {
              pickup.date_time_utc = this.calcParticipationDateTime(
                pickup?.location_name || '',
                productInstance.start_date_time_utc,
                productInstance.time_slot_key || '',
                product?.pickup || []
              );
            }

            if (dropoff) {
              dropoff.date_time_utc = this.calcParticipationDateTime(
                dropoff?.location_name || '',
                productInstance.end_date_time_utc ?? '',
                productInstance.time_slot_key || '',
                product?.dropoff || []
              );
            }

            if (checkin) {
              checkin.date_time_utc = this.calcParticipationDateTime(
                checkin?.location_name || '',
                productInstance.start_date_time_utc,
                productInstance.time_slot_key || '',
                product?.checkin || []
              );
            }

            if (checkout) {
              checkout.date_time_utc = this.calcParticipationDateTime(
                checkout?.location_name || '',
                productInstance.end_date_time_utc ?? '',
                productInstance.time_slot_key || '',
                product?.checkout || []
              );
            }
          }
        }
      }

      const baseNewReservation: NewReservation = {
        ...(prevState.newReservation as any),
        pickup,
        dropoff,
        checkin,
        checkout,
        transportation: transportationKey,
        product_instance_id: (productInstance && productInstance.id) || '',
      };

      // If 'datetime-local' reservation form fields have not been initialized, initialize them
      // to the product instance local start time.
      const dateTimeFormFields = product?.reservation_form_fields?.filter(
        (formField) =>
          formField.type === 'PER_BOOKING' &&
          formField.format === 'datetime-local'
      );

      if (dateTimeFormFields?.length) {
        for (const formField of dateTimeFormFields) {
          if (
            !baseNewReservation.field_responses?.find(
              (response) => response.key === formField.key
            )
          ) {
            baseNewReservation.field_responses = [
              ...(baseNewReservation.field_responses ?? []),
              {
                key: formField.key,
                response: (productInstance?.start_date_time_local || '')?.slice(
                  0,
                  16
                ),
              },
            ];
          }
        }
      }

      if (
        (prevState.isChangeReservation ||
          prevState.isFromNewReservationModal) &&
        prevState.reservation &&
        product &&
        productInstance
      ) {
        const newReservation = this.getNewReservation(
          baseNewReservation,
          prevState.reservation,
          product,
          productInstance,
          prevState.isChangeReservation || false,
          t,
          prevState.isFromNewReservationModal || false
        );
        const showChangeReservationProductKeysHaveBeenResetAlert = Boolean(
          prevState.isChangeReservation &&
            ((newReservation.transportation ?? '') !==
              (prevState.reservation?.transportation ?? '') ||
              (newReservation.add_ons ?? []).length !==
                (prevState.reservation?.add_ons ?? []).length ||
              (newReservation.guests ?? []).length !==
                (prevState.reservation?.guests ?? []).length ||
              newReservation.guests?.some(
                (guest, idx) =>
                  (newReservation.guests[idx].add_ons ?? []).length !==
                  (prevState.reservation?.guests[idx]?.add_ons ?? []).length
              ))
        );
        this.setState({
          agents: product && (product.agents as any),
          newReservation: newReservation,
          showChangeReservationProductKeysHaveBeenResetAlert:
            showChangeReservationProductKeysHaveBeenResetAlert,
        });
      } else {
        this.setState({
          agents: product && (product.agents as any),
          newReservation: { ...baseNewReservation },
        });
      }
    }

    const prevGuestHotelPlaceId =
      prevState.newReservation.guest_hotel?.google_place_id;
    const newGuestHotelPlaceId =
      this.state.newReservation.guest_hotel?.google_place_id;
    const productHasPickupLocations =
      (this.props.product?.pickup ?? []).length > 0;
    const hasSelectedTransportation =
      !!this.state.newReservation.transportation;

    if (
      prevGuestHotelPlaceId !== newGuestHotelPlaceId &&
      newGuestHotelPlaceId &&
      productHasPickupLocations &&
      hasSelectedTransportation
    ) {
      const exactMatchPickup = this.props.product?.pickup?.find(
        (loc) => loc.google_place_id === newGuestHotelPlaceId
      );
      const premappedPickup =
        this.getPremappedPickupLocation(newGuestHotelPlaceId);

      if (exactMatchPickup) {
        const pickupTime = this.getPickupTime(exactMatchPickup);
        this.handlePickupLocationChanged({
          locationID: exactMatchPickup.id ?? '',
          locationName: exactMatchPickup.location_name ?? '',
          locationDescription: exactMatchPickup.location_description ?? '',
          googlePlaceID: exactMatchPickup.google_place_id ?? '',
          locationDateTime: pickupTime,
          imageUrls: exactMatchPickup.image_urls ?? [],
        });
      } else if (premappedPickup) {
        const pickupTime = this.getPickupTime(premappedPickup);
        this.handlePickupLocationChanged({
          locationID: premappedPickup.id ?? '',
          locationName: premappedPickup.location_name ?? '',
          locationDescription: premappedPickup.location_description ?? '',
          googlePlaceID: premappedPickup.google_place_id ?? '',
          locationDateTime: pickupTime,
          imageUrls: premappedPickup.image_urls ?? [],
        });
      } else {
        if (!this.placesService) {
          this.placesService = new window.google.maps.places.PlacesService(
            (this.hotelInputRef as any)?.current
          );
        }

        this.placesService?.getDetails(
          {
            placeId: newGuestHotelPlaceId,
            fields: ['geometry'],
          },
          (place: any, status: any) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              const closestPickup = findClosestPickupLocation(
                lat,
                lng,
                this.props.product?.pickup ?? []
              );

              if (closestPickup) {
                const pickupTime = this.getPickupTime(closestPickup);
                this.handlePickupLocationChanged({
                  locationID: closestPickup.id ?? '',
                  locationName: closestPickup.location_name ?? '',
                  locationDescription: closestPickup.location_description ?? '',
                  googlePlaceID: closestPickup.google_place_id ?? '',
                  locationDateTime: pickupTime,
                  imageUrls: closestPickup.image_urls ?? [],
                });
              }
            } else {
              throw new Error('getDetails() returned status ' + status);
            }
          }
        );
      }
    }

    // WHen transportation is 'Checkin/Checkout Only', initialize checkin to the product checkin location
    if (
      this.state.newReservation.transportation === '' &&
      !this.state.newReservation.checkin &&
      this.props.productInstance &&
      (prevProps.product !== this.props.product ||
        prevState.newReservation.transportation !==
          this.state.newReservation.transportation)
    ) {
      const productCheckinLocations = this.props.product?.checkin ?? [];
      if (this.props.product.checkin?.length === 1) {
        let checkin: NewReservation['checkin'] | typeof undefined = undefined;

        const checkinTime = this.getPickupTime(productCheckinLocations[0]);

        checkin = convertLocationWithMomentToReservationLocationWithTimeInput({
          locationID: productCheckinLocations[0].id ?? '',
          locationName: productCheckinLocations[0].location_name ?? '',
          locationDescription:
            productCheckinLocations[0].location_description ?? '',
          googlePlaceID: productCheckinLocations[0].google_place_id ?? '',
          locationDateTime: checkinTime,
          imageUrls: productCheckinLocations[0].image_urls ?? [],
        });

        this.setState({
          newReservation: {
            ...this.state.newReservation,
            checkin,
          },
        });
      }
    }

    if (
      this.state.customerId &&
      this.props.customersById &&
      this.props.customersById[this.state.customerId ?? '']
    ) {
      const customer = this.props.customersById[this.state.customerId ?? ''];

      const newFieldResponses = this.getUpdateFieldResponseByCustomerInfo(
        this.state.newReservation.field_responses ?? [],
        customer
      );

      if (
        !_.isEqual(newFieldResponses, this.state.newReservation.field_responses)
      ) {
        this.setState({
          newReservation: {
            ...this.state.newReservation,
            field_responses: newFieldResponses,
          },
        });
      }
    }

    const isPassthrough = Boolean(
      this.props.product?.shared_allotment_references
        ?.passthrough_base_product_id
    );
    if (
      !operationAllowed(
        this.props.activeUser,
        'write',
        'reservationBookingSource'
      ) &&
      this.props.productInstance &&
      (this.props.invalidated ||
        prevState.newReservation.guests !== this.state.newReservation.guests)
    ) {
      this.props.checkAvailability(
        this.props.productInstance.id,
        this.state.newReservation.guests.map((guest) => guest.guest_type_key)
      );
    } else if (
      isPassthrough &&
      this.props.productInstance &&
      (this.props.error !== prevProps.error ||
        this.props.product !== prevProps.product ||
        this.props.productInstance !== prevProps.productInstance ||
        this.props.invalidated ||
        prevState.newReservation.guests !== this.state.newReservation.guests)
    ) {
      this.props.checkAvailability(
        this.props.productInstance.id,
        this.state.newReservation.guests.map((guest) => guest.guest_type_key)
      );
    }

    if (
      this.props.productInstance &&
      (this.props.invalidated ||
        prevProps.productInstance !== this.props.productInstance)
    ) {
      const [
        initialAvailablePerBookingAddOns,
        initialAvailablePerParticipantAddOns,
      ] = getInitialAddOnResources(this.props.productInstance);
      this.setState({
        initialAvailablePerParticipantAddOns,
        initialAvailablePerBookingAddOns,
        availablePerParticipantAddOns: initialAvailablePerParticipantAddOns,
      });
    }
    if (prevState.newReservation !== this.state.newReservation) {
      const newAvailablePerParticipantAddOns: { [key: string]: number } = {
        ...this.state.initialAvailablePerParticipantAddOns,
      };
      this.state.newReservation.guests.forEach((guest) => {
        (guest.add_ons ?? []).forEach((addOn) => {
          if (newAvailablePerParticipantAddOns[addOn]) {
            newAvailablePerParticipantAddOns[addOn] -= 1;
          }
        });
      });
      this.setState({
        availablePerParticipantAddOns: newAvailablePerParticipantAddOns,
      });

      const notEnoughAvailableResources = getNotEnoughAvailableResources(
        this.state.newReservation.guests,
        this.props.product,
        this.props.productInstance
      );
      this.setState({
        notEnoughAvailableResources,
      });
    }
  }

  // End of componentDidUpdate
  getNewReservation = (
    baseNewReservation: NewReservation,
    reservation: Reservation | undefined,
    product: Product,
    productInstance: ProductInstance | null,
    isChangeReservation: boolean,
    t: TFunction,
    isFromNewReservationModal: boolean
  ): NewReservation => {
    if (isChangeReservation) {
      let amountPayOnBoard =
        reservation?.billing_info?.amount_pay_on_board?.substring(3) || '0';

      if (reservation?.payment_type !== 'PAID_PARTIALLY') {
        amountPayOnBoard = '';
      }

      return {
        ...baseNewReservation,
        agent_reference: this.getIncrementedApplicationNumber(
          reservation?.agent_reference || ''
        ),
        transportation: this.getMappedTransportationKeyFromTakeOverValue(
          product?.transportations || [],
          reservation?.transportation || ''
        ),
        add_ons: this.getMappedAddOnsKeysFromTakeOverValue(
          getPerBookingAddOns(product?.add_ons || []),
          reservation?.add_ons || []
        ),
        field_responses: this.getMappedFieldResponsesFromTakeOverValue(
          product?.reservation_form_fields?.filter(
            (field) => field.type === 'PER_BOOKING'
          ) || [],
          reservation?.field_responses || []
        ),
        guests: this.getMappedGuestsFromTakeOverValue(
          getGuestTypesUsedInProductInstance(productInstance, product, t),
          reservation?.guests || []
        ).map((guest) => {
          return {
            ...guest,
            add_ons: this.getMappedAddOnsKeysFromTakeOverValue(
              getPerParticipantAddOns(product?.add_ons || []),
              guest.add_ons || []
            ),
            field_responses: this.getMappedFieldResponsesFromTakeOverValue(
              product?.reservation_form_fields?.filter(
                (field) => field.type === 'PER_PARTICIPANT'
              ) || [],
              guest.field_responses || []
            ),
          };
        }),
        product_instance_id: (productInstance && productInstance.id) || '',
        rebooked_from_reservation_id: reservation?.id || '',
        amount_pay_on_board: amountPayOnBoard,
      };
    } else if (
      isFromNewReservationModal &&
      reservation &&
      product &&
      productInstance
    ) {
      const obj = {
        ...baseNewReservation,
        add_ons: [],
        field_responses: this.getMappedFieldResponsesFromTakeOverValue(
          product?.reservation_form_fields?.filter(
            (field) => field.type === 'PER_BOOKING'
          ) || [],
          reservation?.field_responses || []
        ),
        guests: this.getMappedGuestsFromTakeOverValue(
          getGuestTypesUsedInProductInstance(productInstance, product, t),
          reservation?.guests || []
        ).map((guest) => {
          return {
            ...guest,
            add_ons: [],
            field_responses: this.getMappedFieldResponsesFromTakeOverValue(
              product?.reservation_form_fields?.filter(
                (field) => field.type === 'PER_PARTICIPANT'
              ) || [],
              guest.field_responses || []
            ),
          };
        }),
      };
      return obj;
    }

    return {
      ...baseNewReservation,
      product_instance_id: (productInstance && productInstance.id) || '',
      transportation:
        (product &&
          product.transportations &&
          product.transportations.length > 0 &&
          product.transportations[0].key) ||
        '',
    };
  };
  getMappedFieldResponsesFromTakeOverValue = (
    newFieldResponses: Field[],
    fieldResponses: {
      key?: string;
      response?: string;
    }[]
  ): {
    key?: string;
    response?: string;
  }[] => {
    const keys = newFieldResponses.map((newFieldResponse) => {
      return newFieldResponse?.key || '';
    });
    const list = fieldResponses.filter(
      (fieldResponse: { key?: string; response?: string }) => {
        return [
          ...keys,
          'preferred_language_iso2',
          'Line User ID',
          'customer_id',
        ].includes(fieldResponse?.key ?? '');
      }
    );
    // Additional filter
    const final = list.filter(
      (fieldResponse: { key?: string; response?: string }) => {
        // Field response data from Product
        let targetFieldResponse = null;
        // Target field response key need to be validate
        const targetKey = fieldResponse?.key || '';
        // Target field response value need to be validate
        const targetResponse = fieldResponse?.response || null;

        for (let i = 0; i < newFieldResponses.length; i++) {
          if (
            newFieldResponses[i]?.key &&
            newFieldResponses[i].key == targetKey
          ) {
            targetFieldResponse = newFieldResponses[i];
            break;
          }
        }

        // If Response Constraint = Choices, include it if the selection value is valid.
        if (
          targetFieldResponse &&
          targetFieldResponse?.choices &&
          targetFieldResponse.choices.length > 0
        ) {
          return targetFieldResponse.choices.includes(targetResponse ?? '');
        }

        return fieldResponse?.key;
      }
    );
    return final;
  };
  getMappedTransportationKeyFromTakeOverValue = (
    newTransportations: Service[],
    transportationKey: string
  ): string => {
    const newTransportation = newTransportations.find(
      (newTransportation) => newTransportation.key === transportationKey
    );
    return newTransportation?.key || '';
  };
  getMappedAddOnsKeysFromTakeOverValue = (
    newAddOns: Service[],
    addOnKeys: string[]
  ): string[] => {
    return newAddOns
      .filter((newAddOn: Service) => {
        return addOnKeys.includes(newAddOn?.key || '');
      })
      .map((addOn: Service) => {
        return addOn?.key || '';
      });
  };
  getMappedGuestsFromTakeOverValue = (
    newGuestTypes: GuestType[],
    guests: Guest[]
  ): {
    guest_type_key: string;
    add_ons?: string[];
    field_responses?: {
      key?: string;
      response?: string;
    }[];
  }[] => {
    const keys = newGuestTypes.map((newGuestType) => {
      return newGuestType.key || '';
    });
    return guests
      .filter((guest: Guest) => {
        return keys.includes(guest?.guest_type_key);
      })
      .map((guest: Guest) => {
        return {
          guest_type_key: guest.guest_type_key,
          add_ons: guest.add_ons,
          field_responses: guest.field_responses,
        };
      });
  };
  getPremappedPickupLocation = (
    googlePlaceId: string
  ): LocationWithTime | null => {
    return (
      this.props.product?.pickup?.find((loc) =>
        loc.premapped_locations?.some(
          (premappedLocation) =>
            premappedLocation.google_place_id === googlePlaceId
        )
      ) ?? null
    );
  };
  getPickupTime = (pickupLocation: LocationWithTime): Moment => {
    // Apply pickup time to start time
    let timeRelative = pickupLocation.time_relative;

    if ((pickupLocation.dedicated_start_time_info ?? []).length > 0) {
      const dedicatedStartTimeItem =
        pickupLocation.dedicated_start_time_info?.find(
          (item) =>
            item.time_slot_key === this.props.productInstance?.time_slot_key
        );

      if (dedicatedStartTimeItem?.time_relative) {
        timeRelative = dedicatedStartTimeItem?.time_relative;
      }
    }

    const startDate = moment.tz(
      this.props.productInstance?.start_date_time_utc,
      this.props.product?.start_timezone ?? ''
    );
    return moment(startDate).add(moment.duration(timeRelative));
  };
  handleTransportationChange = (transportation: string) => {
    // Reset checkin/checkout/pickup/dropoff when the transportation changed
    this.setState((prevState) => ({
      newReservation: {
        ...prevState.newReservation,
        checkin: undefined,
        checkout: undefined,
        pickup: undefined,
        dropoff: undefined,
        requested_pickup_location: undefined,
        requested_dropoff_location: undefined,
        transportation: transportation === 'none' ? '' : transportation,
      },
    }));
  };
  handleCustomerLanguageChanged = (language: string) => {
    let fieldResponses = this.state.newReservation.field_responses
      ? [...this.state.newReservation.field_responses]
      : [];
    const languageFieldIdx = fieldResponses.findIndex(
      (r) => r.key === 'preferred_language_iso2'
    );

    if (languageFieldIdx !== -1) {
      fieldResponses[languageFieldIdx] = {
        key: 'preferred_language_iso2',
        response: language,
      };
    } else {
      fieldResponses = [
        ...fieldResponses,
        {
          key: 'preferred_language_iso2',
          response: language,
        },
      ];
    }

    this.setState((prevState) => ({
      newReservation: {
        ...prevState.newReservation,
        field_responses: fieldResponses,
      },
    }));
  };
  handleHotelLocationNameChanged = (location_name: string) => {
    this.setState((prevState) => ({
      newReservation: {
        ...prevState.newReservation,
        guest_hotel: {
          location_name,
        },
      },
    }));
  };
  handleHotelLocationSelected = ({
    title: location_name,
    key: google_place_id,
  }: {
    title: string;
    key: string;
  }) => {
    this.setState((prevState) => {
      const guest_hotel = {
        location_name,
        google_place_id,
      };
      return {
        newReservation: { ...prevState.newReservation, guest_hotel },
      };
    }); // End of set state
  };
  handlePickupLocationChanged = (loc: LocationWithMoment) => {
    const pickup =
      convertLocationWithMomentToReservationLocationWithTimeInput(loc);

    if (this.state.pickupDropoffUseSameLocation && pickup) {
      const dropoff = {
        location_name: pickup.location_name,
        location_description: pickup.location_description,
        google_place_id: pickup.google_place_id,
      };
      this.setState((prevState) => ({
        newReservation: { ...prevState.newReservation, dropoff, pickup },
      }));
    } else {
      this.setState((prevState) => ({
        newReservation: { ...prevState.newReservation, pickup },
      }));
    }
  };
  handleDropoffLocationChanged = (loc: LocationWithMoment) => {
    this.setState((prevState) => ({
      newReservation: {
        ...prevState.newReservation,
        dropoff:
          convertLocationWithMomentToReservationLocationWithTimeInput(loc),
      },
    }));
  };
  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState: State): any => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.newReservation.pickup;
        const dropoff = {
          location_name: pickup && pickup.location_name,
          location_description: pickup && pickup.location_description,
          google_place_id: pickup && pickup.google_place_id,
        };
        return {
          pickupDropoffUseSameLocation: true,
          newReservation: { ...prevState.newReservation, dropoff },
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };
  handleTogglePaymentDeferred = () => {
    this.setState((prevState) => ({
      newReservation: {
        ...prevState.newReservation,
        payment_deferred: !prevState.newReservation.payment_deferred,
      },
    }));
  };
  reservationFromState = () => ({
    // Spread 'newReservation' before product/instance fields to prevent productInstance.start_date_time_utc from being
    // clobbered by newReservation.start_date_time_utc when we are resubmitting a previous booking.
    ...this.state.newReservation,
    product_name: this.props.product && this.props.product.product_name,
    start_date_time_utc:
      this.props.productInstance &&
      this.props.productInstance.start_date_time_utc,
    start_timezone: this.props.product && this.props.product.start_timezone,
  });
  getFullName = () => {
    const familyNameField =
      this.state.newReservation.field_responses &&
      this.state.newReservation.field_responses.find(
        (f) => f.key === 'family_name'
      );
    const givenNameField =
      this.state.newReservation.field_responses &&
      this.state.newReservation.field_responses.find(
        (f) => f.key === 'given_name'
      );
    const familyName = familyNameField ? familyNameField.response : '';
    const givenName = givenNameField ? givenNameField.response : '';
    return givenName && familyName ? givenName + ' ' + familyName : '';
  };
  updateGuest = (guestTypeKey: string, quantity: number) => {
    const firstTime = this.state.newReservation.guests.length === 0 && quantity;

    // Special case of copying over full & given names when adding the first guest
    // Add guest object with `field_responses`.
    if (firstTime) {
      const guests = [...Array(quantity)].map((_, i) => {
        const defaultGuest = {
          guest_type_key: guestTypeKey,
        };
        let guest = {};

        if (i === 0) {
          guest = {
            ...defaultGuest,
            field_responses: [
              {
                key: 'full_name',
                response: this.getFullName(),
              },
            ],
          };
        } else {
          guest = { ...defaultGuest };
        }

        return guest;
      });
      this.setState((prevState: State) => ({
        newReservation: {
          ...prevState.newReservation,
          guests: guests as Guest[],
        },
      }));
    } else {
      // Check if need to add / remove guest
      const prevQuantity =
        this.state.newReservation.guests.filter(
          (g) => g.guest_type_key === guestTypeKey
        ).length || 0;

      if (quantity > prevQuantity) {
        const addQuantity = quantity - prevQuantity;
        const newGuests = [];

        for (let i = 0; i < addQuantity; i++) {
          newGuests.push({
            guest_type_key: guestTypeKey,
          });
        }

        const guests = [
          ...(this.state.newReservation.guests || []),
          ...newGuests,
        ];
        this.setState((prevState) => ({
          showParticipantDeletionErrorAlert: false,
          showParticipantDeletionErrorGuestTypeKey: '',
          newReservation: { ...prevState.newReservation, guests: guests },
        }));
      } else {
        const perParticipantFields: Field[] = (
          this.props.product?.reservation_form_fields || []
        )
          .filter((f) => f.type === 'PER_PARTICIPANT')
          .map((field) => ({
            ...field,
            required:
              field.required === 'WHEN_BOOKING' ? 'WHEN_BOOKING' : 'OPTIONAL',
          }));
        const perParticipantAddOns = getPerParticipantAddOns(
          this.props.product?.add_ons ?? []
        );

        if (quantity === 0) {
          const guests = this.state.newReservation.guests.filter(
            (g) => g.guest_type_key !== guestTypeKey
          );
          this.setState((prevState) => ({
            newReservation: { ...prevState.newReservation, guests: guests },
          }));
          return;
        }
        if (
          perParticipantFields.length > 0 ||
          perParticipantAddOns.length > 0
        ) {
          let removeQuantity = prevQuantity - quantity;
          const removedGuest = this.state.newReservation.guests
            .filter((g) => g.guest_type_key === guestTypeKey)
            .filter((g) => {
              if (removeQuantity === 0) {
                return true;
              }
              let addOnCheck = false;
              let participantFieldCheck = false;
              if (perParticipantFields.length > 0) {
                participantFieldCheck = perParticipantFields.some((pField) => {
                  const r = (g.field_responses || []).find(
                    (field) => pField.key === field.key
                  );
                  if (!r || r?.response === '') {
                    return false;
                  }
                  return true;
                });
              }
              if (perParticipantAddOns.length > 0) {
                addOnCheck = (g.add_ons || []).length !== 0 ? true : false;
              }

              if (participantFieldCheck || addOnCheck) {
                return true;
              } else {
                removeQuantity--;
                return false;
              }
            });

          if (removeQuantity === 0) {
            const guests = [
              ...(this.state.newReservation.guests.filter(
                (g) => g.guest_type_key !== guestTypeKey
              ) || []),
              ...removedGuest,
            ];
            this.setState((prevState) => ({
              showParticipantDeletionErrorAlert: false,
              showParticipantDeletionErrorGuestTypeKey: '',
              newReservation: { ...prevState.newReservation, guests: guests },
            }));
            return;
          } else {
            this.setState({
              showParticipantDeletionErrorAlert: true,
              showParticipantDeletionErrorAlertModal: true,
              showParticipantDeletionErrorGuestTypeKey: guestTypeKey,
            });
            return;
          }
        }

        // remove guest
        let find = true;
        const idxToRemove: number[] = [];
        let fromIndex = this.state.newReservation.guests.length;
        const removeQuantity = prevQuantity - quantity;

        while (find) {
          const idx = _.findLastIndex(
            this.state.newReservation.guests,
            (g) => g.guest_type_key === guestTypeKey,
            fromIndex
          );

          if (idx === -1) {
            find = false;
          } else {
            idxToRemove.push(idx);
            fromIndex = idx - 1;
          }

          if (fromIndex < 0 || idxToRemove.length == removeQuantity) {
            find = false;
          }
        }

        if (idxToRemove.length != removeQuantity) {
          throw new Error('updateGuest() unable to remove guest');
        }

        const guests = this.state.newReservation.guests.filter(
          (g, idx) => idxToRemove.includes(idx) === false
        );
        this.setState((prevState) => ({
          newReservation: { ...prevState.newReservation, guests: guests },
        }));
      }
    }
  };
  getFormInputErrors = (): Record<string, any> => {
    const { product, t, activeUser } = this.props;
    const { newReservation } = this.state;
    let errorMap: Record<string, string> = {};

    if (!newReservation.guests || newReservation.guests.length === 0) {
      errorMap['guests'] = t('At least one guest required');
    }

    if (newReservation.payment_deferred) {
      if (!newReservation.email_payment_to_address) {
        errorMap['email_payment_to_address'] = t(
          'Email address required when sending email with payment link'
        );
      } else if (
        !matchesFormat(newReservation.email_payment_to_address, 'email')
      ) {
        errorMap['email_payment_to_address'] = t(
          '"{{fieldName}}" does not match format "{{format}}"',
          {
            fieldName: 'Payment link email address',
            format: 'email',
          }
        );
      }
    }

    // End of email_payment_to_address validation
    const perBookingFields = getDisplayReservationFormFields(
      product.reservation_form_fields ?? [],
      t
    )
      .filter((f) => f.type === 'PER_BOOKING')
      .map((f) => {
        // remove format validation when the organization type is supplier
        if (activeUser?.organization_type === 'SUPPLIER') {
          return { ...f, format: undefined };
        }

        return f;
      });
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newReservation.field_responses || [],
        perBookingFields,
        t,
        activeUser?.organization_type === 'SUPPLIER'
      ),
    };
    const perParticipantFields = (product.reservation_form_fields || [])
      .filter((f) => f.type === 'PER_PARTICIPANT')
      .map((f) => {
        // remove format validation when the organization type is supplier
        if (activeUser?.organization_type === 'SUPPLIER') {
          return { ...f, format: undefined };
        }

        return f;
      });
    (newReservation.guests || []).forEach((g) => {
      errorMap = {
        ...errorMap,
        ...getFieldResponseErrors(
          g.field_responses || [],
          perParticipantFields,
          t,
          activeUser?.organization_type === 'SUPPLIER'
        ),
      };
    });

    if (
      newReservation.payment_type === 'PAID_PARTIALLY' &&
      newReservation.amount_pay_on_board === ''
    ) {
      errorMap['amount_pay_on_board'] = t('"{{fieldName}}" required', {
        fieldName: t('Amount Paid Onboard'),
      });
    }

    return errorMap;
  };
  // This is duplicated behind the API...
  receipt = () => {
    const { product, productInstance, t } = this.props;
    if (!productInstance || !product)
      return {
        line_items: [],
        total: '',
      };
    const reservation = this.reservationFromState();
    return getReservationLineItems(
      reservation as any,
      product,
      productInstance,
      t
    );
  };
  handleAddNewAgent = (value: string) => {
    this.setState((prevState) => ({
      agents: [
        ...prevState.agents,
        {
          id: '',
          name: value,
        },
      ],
    }));
  };
  getIncrementedApplicationNumber = (applicationNumber: string) => {
    const match = /(.*-[0]*)(\d*)$/.exec(applicationNumber);

    if (match) {
      return match[1] + (Number(match[2]) + 1);
    }

    return applicationNumber + '-1';
  };
  getParticipationDateTime = (
    dateTimeUTCString: string,
    startDateTimeUTCMoment: Moment,
    sourceReservationStartTimeUTC: Moment
  ): string => {
    return moment(dateTimeUTCString)
      .add(
        moment.duration(
          startDateTimeUTCMoment.diff(sourceReservationStartTimeUTC)
        )
      )
      .format();
  };
  calcParticipationDateTime = (
    sourceLocation: string,
    dateTimeUTCString: string,
    timeSlotKey: string,
    candidates: LocationWithTime[]
  ): string => {
    if (!sourceLocation) {
      return dateTimeUTCString;
    }

    const placeInfo = (candidates || []).find(
      (candidate) => candidate.location_name === sourceLocation
    );

    if (!placeInfo) {
      return dateTimeUTCString;
    }

    let timeRelative = placeInfo.time_relative || '0:00';

    if (timeSlotKey) {
      const dedicatedStartTimeItem = (
        placeInfo.dedicated_start_time_info || []
      ).find((info) => info.time_slot_key === timeSlotKey);

      if (dedicatedStartTimeItem) {
        timeRelative = dedicatedStartTimeItem.time_relative || timeRelative;
      }
    }

    return moment(dateTimeUTCString)
      .add(moment.duration(timeRelative))
      .format('');
  };

  isEmailPayment = (reservation: Reservation | undefined) => {
    if (!reservation) {
      return false;
    }

    if (reservation.payment_deferred && reservation.email_payment_to_address) {
      return true;
    }

    return false;
  };

  // Show the modal if confirmed reservation change or product change results in credit card transaction
  needToOpenCreditCardChargeOrRefundForConfirmedReservationMessageModal = (
    reservation: Reservation | undefined,
    isChangeReservation: boolean,
    amountDifference: string
  ) => {
    if (!reservation) {
      return false;
    }

    if (!isChangeReservation) {
      return false;
    }

    if (
      reservation.booking_source?.source_type === 'DIRECT_WEB' &&
      reservation.payment_type === 'PAID_IN_FULL' &&
      reservation.payment_method === 'CREDIT_CARD' &&
      reservation.status === 'CONFIRMED' &&
      amountDifference
    ) {
      return true;
    }

    if (
      reservation.payment_type === 'PAID_IN_FULL' &&
      reservation.payment_method === 'CREDIT_CARD' &&
      reservation.status === 'CONFIRMED' &&
      amountDifference &&
      this.isEmailPayment(reservation)
    ) {
      return true;
    }

    return false;
  };

  // Show the modal for requested/standby reservation change
  needToOpenCreditCardChargeForRequestedReservationMessageModal = (
    reservation: Reservation | undefined,
    isChangeReservation: boolean
  ) => {
    if (!reservation) {
      return false;
    }

    if (!isChangeReservation) {
      return false;
    }

    if (
      reservation.booking_source?.source_type === 'DIRECT_WEB' &&
      reservation.payment_type === 'PAID_IN_FULL' &&
      reservation.payment_method === 'CREDIT_CARD' &&
      (reservation.status === 'REQUESTED' || reservation.status === 'STANDBY')
    ) {
      return true;
    }

    return false;
  };

  getAmountDifference = (
    reservation: Reservation | undefined,
    billingInfo: ReservationBillingInfo | null,
    rebookFromReservationBillingInfo: ReservationBillingInfo | null
  ) => {
    if (!reservation) {
      return '';
    }

    if (
      !billingInfo ||
      !(
        reservation.billing_info?.amount_gross ||
        rebookFromReservationBillingInfo
      )
    ) {
      return '';
    }

    const totalAfter = currency(billingInfo?.amount_gross ?? '').format();
    const totalBefore = currency(
      reservation?.billing_info?.amount_gross ||
        rebookFromReservationBillingInfo?.amount_gross ||
        ''
    ).format();

    if (totalAfter == totalBefore) {
      return '';
    }

    return currency(totalAfter).subtract(totalBefore).format();
  };

  getUpdateFieldResponseByCustomerInfo = (
    fieldResponses: { key?: string; response?: string }[],
    customer: Customer
  ): { key?: string; response?: string }[] => {
    const fields = [
      'email',
      'family_name',
      'given_name',
      'kana_given_name',
      'kana_family_name',
    ];

    for (const field of fields) {
      const response = (fieldResponses || []).find(
        (response) => response.key === field
      );

      if (response) {
        continue;
      }

      let value = '';
      switch (field) {
        case 'email':
          value = customer.email ?? '';
          break;
        case 'family_name':
          value = customer.family_name ?? '';
          break;
        case 'given_name':
          value = customer.given_name ?? '';
          break;
        case 'kana_given_name':
          value = customer.kana_given_name ?? '';
          break;
        case 'kana_family_name':
          value = customer.kana_family_name ?? '';
          break;
      }

      if (value) {
        fieldResponses.push({
          key: field,
          response: value,
        });
      }
    }

    return fieldResponses;
  };

  render() {
    const {
      activeUser,
      actimReservation,
      billingInfo,
      rebookFromReservationBillingInfo,
      isPreview,
      loading,
      locale,
      product,
      productInstance,
      t,
      activeUserOrganization,
    } = this.props;
    const {
      createdReservationID,
      reservationError,
      lastSubmissionValidationErrorMap,
      pickupDropoffUseSameLocation,
      isChangeReservation,
      isResubmitReservation,
      isFromNewReservationModal,
      openCalendar,
      reservation,
      actimReservationId,
      notEnoughAvailableResources,
      openReservationCreateModal,
      customerId,
    } = this.state;

    if (!product || !productInstance) {
      return <h1>{t('Product not found!!')}</h1>;
    }

    const {
      productInstanceToRedirectTo: instance,
      newReservation,
      agents,
    } = this.state;

    if (
      instance &&
      instance.id !== productInstance.id &&
      !disableRedirectAfterCreatingReservation
    ) {
      const productInstanceBookingPath = `/products/${instance.product_id}/instances/${instance.id}/book`;
      return <Redirect to={productInstanceBookingPath} />;
    }

    if (createdReservationID) {
      return <Redirect to={`/reservations/${createdReservationID}`} />;
    }

    const timeSlotKey = productInstance?.time_slot_key;
    const startDate = moment
      .tz(productInstance.start_date_time_utc, product.start_timezone ?? '')
      .locale(locale);
    const participationReservationFormFields = getDisplayReservationFormFields(
      product.reservation_form_fields ?? [],
      t
    )
      .filter((f) =>
        [
          'given_name',
          'family_name',
          'kana_given_name',
          'kana_family_name',
          'email',
        ].includes(f.key ?? '')
      )
      .sort((a, b) => {
        // Order email at the end
        if (b.key === 'email') {
          return -1;
        }
        return 0;
      });

    if (
      !participationReservationFormFields.find((field) => field.key === 'email')
    ) {
      participationReservationFormFields.push({
        type: 'PER_BOOKING',
        key: 'email',
        prompt: t('Email'),
      });
    }
    const requiredWhenBookingPerBookingFields = getDisplayReservationFormFields(
      product.reservation_form_fields ?? [],
      t
    ).filter(
      (f) =>
        f.required === 'WHEN_BOOKING' &&
        f.type === 'PER_BOOKING' &&
        ![
          'given_name',
          'family_name',
          'kana_given_name',
          'kana_family_name',
          'email',
        ].includes(f.key ?? '')
    );
    const optionalPerBookingFields: Field[] = getDisplayReservationFormFields(
      product.reservation_form_fields ?? [],
      t
    )
      .filter(
        (f) =>
          (f.required === 'BEFORE_PARTICIPATING' ||
            f.required === 'OPTIONAL') &&
          f.type === 'PER_BOOKING' &&
          ![
            'given_name',
            'family_name',
            'kana_given_name',
            'kana_family_name',
            'email',
          ].includes(f.key ?? '')
      )
      .map((field) => ({
        ...field,
        required: 'OPTIONAL',
      }));
    const perParticipantFields: Field[] = (
      product.reservation_form_fields || []
    )
      .filter((f) => f.type === 'PER_PARTICIPANT')
      .map((field) => ({
        ...field,
        required:
          field.required === 'WHEN_BOOKING' ? 'WHEN_BOOKING' : 'OPTIONAL',
      }));
    const transportations = product.transportations || [];
    const addOns = product.add_ons || [];
    const perBookingAddOns = getPerBookingAddOns(addOns);
    const perParticipantAddOns = getPerParticipantAddOns(addOns);
    const { line_items } = this.receipt();
    const hasGroupPricing = !!line_items.find(
      (i) => i.unit && i.unit.method === 'PER_GROUP'
    );
    const groupPricingActive = !!line_items.find(
      (i) => i.count > 0 && i.unit && i.unit.method === 'PER_GROUP'
    );
    const groupPricingNote = line_items
      .filter((i) => i.count > 0 && i.unit && i.unit.method === 'PER_GROUP')
      .map((i) => `${i.name || ''} x ${i.count}`)
      .join('\n');
    const agent_notes = [
      this.state.newReservation.agent_notes,
      groupPricingNote,
    ].join('\n');
    const supplier_notes = [
      this.state.newReservation.supplier_notes,
      groupPricingNote,
    ].join('\n');
    const agent_reference =
      this.state.newReservation.agent_reference ||
      this.state.defaultAgentReference;

    let paymentGateway = '';
    if (
      this.state.newReservation.payment_deferred &&
      activeUserOrganization?.reservation_payment_gateway_settings
        ?.payment_gateway === 'GMO'
    ) {
      paymentGateway = 'GMO';
    }

    const reservationToSubmit = {
      ...this.state.newReservation,
      agent_notes,
      supplier_notes,
      agent_reference,
      ...(paymentGateway ? { payment_gateway: paymentGateway } : {}),
    };
    const productCurrency = productInstance.units[0].gross.slice(0, 3);
    const currentValidationErrorMap = this.getFormInputErrors();
    const errorMap: Record<string, string> = {};
    // Set keys for all fields that were invalid in the last submission.
    Object.keys(lastSubmissionValidationErrorMap).forEach((errKey) => {
      errorMap[errKey] = currentValidationErrorMap[errKey];
    });
    const errors = reservationError
      ? [reservationError]
      : Object.values(lastSubmissionValidationErrorMap);
    const timezone = product.start_timezone || '';
    const pickup = convertToLocationWithMoment(
      newReservation.pickup,
      timezone,
      locale
    );
    const dropoff = convertToLocationWithMoment(
      newReservation.dropoff,
      timezone,
      locale
    );
    const checkin = convertToLocationWithMoment(
      newReservation.checkin,
      timezone,
      locale
    );
    const paymentMethodOptions = ['INVOICE', 'CREDIT_CARD', 'CASH', 'OTHER'];
    const otherPaymentMethodOptions = ['Bank Transfer', 'E-Money'];
    // The following comments are required to create translation item
    // t('Bank Transfer')
    // t('E-Money')
    const paymentTypeOptions = [
      'PAID_IN_FULL',
      'PAY_ON_BOARD',
      'PAID_PARTIALLY',
    ];
    const guestTypes = getGuestTypesUsedInProductInstance(
      productInstance,
      product,
      t
    );
    const startTimeDescription = productInstance.start_time_description || '';
    const bookingSourceIsAgent =
      newReservation.booking_source &&
      newReservation.booking_source.source_type === 'AGENT';
    const activatePaymentDeferred =
      newReservation.payment_method === 'CREDIT_CARD' &&
      newReservation.payment_type === 'PAID_IN_FULL';
    const customerLanguage: string =
      (newReservation.field_responses || []).find(
        (r) => r.key === 'preferred_language_iso2'
      )?.response || 'ja-JP';
    const customerLanguageOptions = contentLanguageOptions.map((option) => ({
      value: option.iso,
      text: getLanguageName(option.iso, t),
    }));

    const setChangeProductInstanceErrorMessage = (text: string) => {
      this.setState({
        changeProductInstanceErrorMessage: text,
      });
    };

    const scrollToTop = () => {
      (this.pageRef as any)?.current?.scrollIntoView();
    };

    const scrollToAddOn = () => {
      (this.addOnRef as any)?.current?.scrollIntoView();
    };

    const amountDifference = this.getAmountDifference(
      reservation,
      billingInfo,
      rebookFromReservationBillingInfo
    );

    const OrderSummary = () => {
      return (
        <>
          <a
            className={styles['order-summary-close']}
            onClick={() => {
              this.setState({
                showBookingSummaryModal: false,
              });
            }}
          />
          <FieldWrapper label={t('Order Summary')}>
            <div>
              <div>{product.product_name}</div>
              <div>{`${startDate.format('ll')} ${
                startDate.format('LT') + ' ' + startTimeDescription
              }`}</div>
            </div>
          </FieldWrapper>
          <Box mt={1}>
            <FieldWrapper label={t('Participants')}>
              {getGuestSummary(newReservation?.guests ?? [], guestTypes)}
            </FieldWrapper>
          </Box>
          <Box mt={1}>
            <FieldWrapper label={t('Transportation')}>
              {transportations?.find(
                (transportationItem) =>
                  transportationItem.key === newReservation?.transportation
              )?.title ?? t('Checkin/Checkout Only')}
            </FieldWrapper>
          </Box>
          <Box mt={1}>
            <FieldWrapper label={t('Add-ons')}>
              <AddOnsSummary
                reservation={newReservation}
                addOns={product?.add_ons ?? []}
                guestTypes={guestTypes}
              />
            </FieldWrapper>
          </Box>
          <Box mt={1}>
            <FieldWrapper label={t('Total')}>
              {billingInfo ? (
                <div>
                  {billingInfo.amount_gross && (
                    <div>{`${t('Gross')} ${formattedCurrencyAmount(
                      billingInfo.amount_gross
                    )}`}</div>
                  )}
                  {billingInfo.amount_net && (
                    <div>{`${t('Net')} ${formattedCurrencyAmount(
                      billingInfo.amount_net
                    )}`}</div>
                  )}
                </div>
              ) : (
                '-'
              )}
            </FieldWrapper>
          </Box>
          {(notEnoughAvailableResources ?? []).length > 0 && (
            <Box mt={1}>
              <span className={clsx(baseStyles['error'])}>
                {t('Not enough available resource {{resources}}.', {
                  resources: notEnoughAvailableResources
                    .map((key) => `"${key}"`)
                    .join(', '),
                })}
              </span>
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            <SubmitReservationButton
              reservation={this.reservationFromState() as Reservation}
              open={this.state.openSubmitReservationMessageModal}
              onClick={() => {
                this.setState({
                  showBookingSummaryModal: false,
                });
                if (
                  this.needToOpenCreditCardChargeOrRefundForConfirmedReservationMessageModal(
                    reservation,
                    isChangeReservation ?? false,
                    amountDifference
                  )
                ) {
                  this.setState({
                    openCreditCardChargeOrRefundForConfirmedReservationMessageModal:
                      true,
                  });
                } else if (
                  this.needToOpenCreditCardChargeForRequestedReservationMessageModal(
                    reservation,
                    isChangeReservation ?? false
                  )
                ) {
                  this.setState({
                    openCreditCardChargeForRequestedReservationMessageModal:
                      true,
                  });
                } else if (
                  needToOpenNoEmailWithPaymentLinkMessageModal(
                    this.state.newReservation as any,
                    isChangeReservation ?? false
                  )
                ) {
                  this.setState({
                    openNoEmailWithPaymentLinkMessageModal: true,
                  });
                } else {
                  const openSubmitReservationMessageModal =
                    Object.keys(currentValidationErrorMap).length === 0;
                  this.setState({
                    openSubmitReservationMessageModal,
                    lastSubmissionValidationErrorMap: currentValidationErrorMap,
                  });
                  scrollToTop();
                }
              }}
              onSubmit={() => {
                this.props.onSubmit(reservationToSubmit);
                if (actimReservationId != '') {
                  this.props.updateActimReservation(actimReservationId, {
                    is_already_taken: true,
                  });
                }
              }}
              onClose={() => {
                this.setState({
                  openSubmitReservationMessageModal: false,
                });
              }}
            />
          </Box>
        </>
      );
    };

    const actimUnitMemo = (): JSX.Element => {
      const u = actimReservation[actimReservationId]?.payment_unit_list?.map(
        (r: Record<string, string | number>, idx) => {
          const classification = r.classification
            ? r.classification
            : t('Not set');
          const quantity = r.quantity ? r.quantity : t('Not set');
          const unit_price = r.unit_price ? r.unit_price : t('Not set');
          const sum = r.sum ? r.sum : t('Not set');
          return (
            <div key={idx}>
              {`${classification} ${unit_price} x ${quantity} ${t(
                'Total'
              )} ${sum}`}
            </div>
          );
        }
      );
      return <div>{u}</div>;
    };

    const actimPaymentMethod = (): JSX.Element => {
      const m = actimReservation[actimReservationId]?.payment_method;
      const method = m ? m : t('Not set');
      return <div>{method}</div>;
    };

    const isUnlimitedUnit = (guestTypeKey: string) => {
      return (
        (
          this.props.product?.booking_widget_settings
            ?.maximum_participant_rules || []
        )?.find((rule) => rule.unit === guestTypeKey)?.maximum_participants ===
          PARTICIPANT_UNLIMITED ?? false
      );
    };

    return (
      <>
        {loading && (
          <div className={styles['loading-container']}>
            <Loading />
          </div>
        )}
        <ChangeProductAndParticipationDate
          reservation={reservation}
          isChangeReservation={isChangeReservation || false}
          productId={product.id}
          onSelectProductInstanceError={(message) => {
            setChangeProductInstanceErrorMessage(message);
          }}
          open={openCalendar ?? false}
          onClose={() => {
            this.setState({
              openCalendar: false,
            });
          }}
        />
        <ReservationCreateModalButton
          reservation={reservation}
          productId={this.props.match.params.productID}
          defaultDate={moment(productInstance.start_date_time_utc)}
          open={openReservationCreateModal}
          onClose={() => {
            this.setState({
              openReservationCreateModal: false,
            });
          }}
          customerId={customerId}
        />
        {!loading && (
          <div ref={this.pageRef} className={styles['page-container']}>
            <div className={clsx(styles['page-outline'])}>
              <AlertModal
                header={t('Different unit, transportation, or add-on titles')}
                content={t(
                  'Some units, transportation, and/or add-ons have been re-initialized because product settings on the selected date are different from the original reservation. Please reselect the correct items.'
                )}
                open={
                  this.state.showChangeReservationProductKeysHaveBeenResetAlert
                }
                onClose={() => {
                  this.setState({
                    showChangeReservationProductKeysHaveBeenResetAlert: false,
                  });
                }}
              />
              <AlertModal
                header={t('Remove Guest')}
                content={t(
                  'Number of guest is reduced. Please click "X" button in "4. Add-on/Guest Info" section and remove guest.'
                )}
                open={this.state.showParticipantDeletionErrorAlertModal}
                onClose={() => {
                  this.setState({
                    showParticipantDeletionErrorAlertModal: false,
                  });
                  scrollToAddOn();
                }}
              />
              <div
                className={clsx(
                  baseStyles['base-flex'],
                  baseStyles['align_top']
                )}
              >
                <h2>{getDisplayProductName(product)}</h2>
                {!isPreview && !isFromNewReservationModal && (
                  <a
                    className={clsx(
                      baseStyles['icon'],
                      baseStyles['inline-icon']
                    )}
                    onClick={() => {
                      this.setState({
                        openCalendar: true,
                      });
                    }}
                  >
                    <img src={editIcon} />
                  </a>
                )}

                {isFromNewReservationModal && (
                  <button
                    className={clsx(
                      baseStyles['icon'],
                      baseStyles['inline-icon']
                    )}
                    onClick={() => {
                      this.setState({
                        openReservationCreateModal: true,
                      });
                    }}
                  >
                    <img src={editIcon} />
                  </button>
                )}
              </div>
              {this.state.changeProductInstanceErrorMessage && (
                <Message
                  error
                  content={this.state.changeProductInstanceErrorMessage}
                />
              )}
              {/* <Form error={errors.length > 0} onSubmit={e => e.preventDefault()}> */}
              <form onSubmit={(e) => e.preventDefault()}>
                {errors.length > 0 && (
                  <div className={clsx(styles['error-box'])}>
                    <div className={clsx(styles['error-header'])}>
                      {t('Error')}
                    </div>
                    <ul className={clsx(styles['error-list'])}>
                      {errors.map((err, index) => (
                        <li
                          key={index}
                          className={clsx(styles['error-list-item'])}
                        >
                          {err as any as string}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <AccordionItem
                  id="participation"
                  header={t('1. Participation')}
                  initialOpen={true}
                >
                  {() => (
                    <>
                      <div className={clsx(styles['field'])}>
                        <FieldWrapper label={t('Participation')}>
                          <div>{`${startDate.format('ll')} ${
                            startDate.format('LT') + ' ' + startTimeDescription
                          }`}</div>
                        </FieldWrapper>
                      </div>
                      <div className={clsx(styles['field'])}>
                        <FieldsForm
                          fields={participationReservationFormFields}
                          getFieldValue={(key) => {
                            const r = (
                              newReservation.field_responses || []
                            ).find((r) => r.key === key);

                            if (!r) {
                              return '';
                            }

                            return r.response || '';
                          }}
                          errorMap={errorMap}
                          onFieldChange={(key, value) =>
                            this.setState((prevState) => ({
                              newReservation: {
                                ...prevState.newReservation,
                                field_responses: [
                                  ...(
                                    prevState.newReservation.field_responses ||
                                    []
                                  ).filter((r) => r.key !== key),
                                  {
                                    key,
                                    response: value,
                                  },
                                ],
                              },
                            }))
                          }
                          mode="INPUT"
                        />
                        <FieldsForm
                          fields={requiredWhenBookingPerBookingFields}
                          getFieldValue={(key) => {
                            const r = (
                              newReservation.field_responses || []
                            ).find((r) => r.key === key);

                            if (!r) {
                              return '';
                            }

                            return r.response || '';
                          }}
                          errorMap={errorMap}
                          onFieldChange={(key, value, leaveBlank) =>
                            this.setState((prevState) => ({
                              newReservation: {
                                ...prevState.newReservation,
                                field_responses: [
                                  ...(
                                    prevState.newReservation.field_responses ||
                                    []
                                  ).filter((r) => r.key !== key),
                                  {
                                    key,
                                    response: value,
                                    leaveBlank,
                                  },
                                ],
                              },
                            }))
                          }
                          showLeaveBlankBox={false}
                          requiredFieldOptionalEntry={
                            activeUser?.organization_type === 'SUPPLIER'
                          }
                          getFieldLeaveBlankValue={(key) => {
                            const r = (
                              newReservation.field_responses || []
                            ).find((r) => r.key === key);

                            if (!r) {
                              return false;
                            }

                            return (r as any).leaveBlank || false;
                          }}
                          mode="INPUT"
                        />
                      </div>
                      {guestTypes.length > 0 && (
                        <div
                          className={
                            errorMap['guests']
                              ? clsx(styles['segment'], styles['error'])
                              : clsx(styles['segment'])
                          }
                        >
                          <FieldWrapper required label={t('Guests')}>
                            {guestTypes.map((guestType: any, idx: number) => {
                              const cnt =
                                (newReservation.guests &&
                                  newReservation.guests.filter(
                                    (g) => g.guest_type_key === guestType.key
                                  ).length) ||
                                0;
                              return (
                                <div key={idx} className={styles['unit']}>
                                  <div className={baseStyles['base-form-box']}>
                                    <TruncatedLabel
                                      text={`${
                                        printGuestType(guestType, t) || ''
                                      }`}
                                    />
                                    <div
                                      className={
                                        baseStyles['base-form-box__body']
                                      }
                                    >
                                      <label
                                        className={
                                          baseStyles['base-form-select']
                                        }
                                      >
                                        <select
                                          value={cnt}
                                          onChange={(e) => {
                                            if (e.target.value === 'more') {
                                              this.setState({
                                                numberInputGuestType: guestType,
                                              });
                                              return;
                                            }
                                            this.updateGuest(
                                              guestType.key,
                                              parseInt(e.target.value, 0)
                                            );
                                          }}
                                        >
                                          {[...Array(101)].map(
                                            (_, idx: number) => {
                                              return (
                                                <option key={idx} value={idx}>
                                                  {idx}
                                                </option>
                                              );
                                            }
                                          )}
                                          {cnt > 100 && (
                                            <option value={cnt}>{cnt}</option>
                                          )}
                                          {isUnlimitedUnit(guestType.key) && (
                                            <option value={'more'}>
                                              {t(
                                                'more than or equal to {{count}}',
                                                {
                                                  count: 101,
                                                }
                                              )}
                                            </option>
                                          )}
                                        </select>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </FieldWrapper>

                          {groupPricingActive ? (
                            <div className={clsx(styles['info-message'])}>
                              {t('Group discount applied!')}
                            </div>
                          ) : hasGroupPricing ? (
                            <div className={clsx(styles['message'])}>
                              {t(
                                'Note: this product has group discounts which will be applied automatically'
                              )}
                            </div>
                          ) : null}
                          {actimReservation[actimReservationId] && (
                            <div className={clsx(styles['field'])}>
                              <FieldWrapper label={t('Actim Unit Memo')}>
                                {actimUnitMemo()}
                              </FieldWrapper>
                              <FieldWrapper label={t('Actim Payment Method')}>
                                {actimPaymentMethod()}
                              </FieldWrapper>
                            </div>
                          )}
                          {this.props.confirmationType === 'REJECT' && (
                            <Message
                              error
                              content={t(
                                'Sorry, we do not have enough availability for the selected date and time. Please try on different date'
                              )}
                            />
                          )}
                          {this.props.confirmationType === 'REQUEST' &&
                            isChangeReservation &&
                            this.props.product?.shared_allotment_references
                              ?.passthrough_base_product_id && (
                              <Message
                                error
                                content={t(
                                  'Sorry, we cannot make instant reservation changes for the selected date, time, and participants.'
                                )}
                              />
                            )}
                          {this.props.confirmationType === 'REQUEST' &&
                            !isChangeReservation &&
                            this.props.product?.shared_allotment_references
                              ?.passthrough_base_product_id && (
                              <Message
                                error
                                content={t(
                                  'Reservation will be on request for the selected date and time. Manual confirmation by supplier will be required.'
                                )}
                              />
                            )}
                        </div>
                      )}
                    </>
                  )}
                </AccordionItem>
                <AccordionItem
                  id="basic-info"
                  header={t('2. Basic Information')}
                  initialOpen={true}
                >
                  {() => (
                    <>
                      <div className={clsx(styles['field'])}>
                        {isChangeReservation ? (
                          <FieldWrapper label={t('Application Number')}>
                            {t(newReservation?.agent_reference as any)}
                          </FieldWrapper>
                        ) : (
                          <FormField
                            prompt={t('Application Number')}
                            value={newReservation.agent_reference ?? ''}
                            required={false}
                            onChange={(agent_reference) => {
                              this.setState((prevState) => ({
                                newReservation: {
                                  ...prevState.newReservation,
                                  agent_reference,
                                },
                              }));
                            }}
                          />
                        )}
                      </div>
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationBookingSource'
                      ) && (
                        <div className={clsx(styles['field'])}>
                          {isChangeReservation ? (
                            <FieldWrapper label={t('Booking Source')}>
                              {t(
                                newReservation?.booking_source
                                  ?.source_type as any
                              )}
                            </FieldWrapper>
                          ) : (
                            <FormField
                              prompt={t('Booking Source')}
                              value={
                                newReservation.booking_source?.source_type ?? ''
                              }
                              options={[
                                'DIRECT_WALK_IN',
                                'DIRECT_TELEPHONE',
                                'DIRECT_EMAIL',
                                'AGENT',
                                'OTHER',
                              ].map((a, index) => ({
                                text: t(a),
                                value: a,
                                key: index.toString(),
                              }))}
                              onChange={(source_type: string) => {
                                let newPaymentMethod =
                                  newReservation.payment_method;
                                let newAgentName =
                                  newReservation.booking_source?.agent_name ||
                                  '';
                                let newAgentId =
                                  newReservation.booking_source?.agent_id || '';

                                if (source_type === 'AGENT') {
                                  newPaymentMethod = 'INVOICE';
                                } else {
                                  newAgentName = '';
                                  newAgentId = '';
                                }

                                this.setState({
                                  newReservation: {
                                    ...newReservation,
                                    booking_source: {
                                      source_type:
                                        source_type as any as BookingSourceType,
                                      agent_name: newAgentName,
                                      agent_id: newAgentId,
                                    },
                                    payment_method: newPaymentMethod,
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      )}
                      {bookingSourceIsAgent && (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            search={true}
                            placeholder={t(
                              'type an agent name or select from list'
                            )}
                            prompt={t('Agent')}
                            allowFreeInput={true}
                            value={
                              newReservation.booking_source?.agent_name ?? ''
                            }
                            options={(agents || []).map((a, index) => ({
                              text: a.name,
                              value: a.name,
                              key: index.toString(),
                            }))}
                            onChange={(agent_name: string) => {
                              const agent = agents.find(
                                (a) => a.name === agent_name
                              );
                              this.setState({
                                newReservation: {
                                  ...newReservation,
                                  booking_source: {
                                    source_type: 'AGENT',
                                    agent_id: (agent && agent.id) || '',
                                    agent_name: agent_name,
                                  },
                                },
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className={clsx(styles['field'])}>
                        {isChangeReservation ? (
                          <FieldWrapper label={t('Payment Type')}>
                            {t(newReservation?.payment_type as any)}
                          </FieldWrapper>
                        ) : (
                          <FormField
                            prompt={t('Payment Type')}
                            value={newReservation.payment_type ?? ''}
                            options={paymentTypeOptions.map((ty, index) => ({
                              text: t(ty),
                              value: ty,
                              key: index.toString(),
                            }))}
                            onChange={(payment_type: string) => {
                              let payment_deferred =
                                newReservation.payment_deferred;
                              let email_payment_to_address =
                                newReservation.email_payment_to_address;

                              if (payment_type !== 'PAID_IN_FULL') {
                                payment_deferred = false;
                                email_payment_to_address = '';
                              }

                              // clear amount pob every time payment_type changes
                              const amount_pay_on_board = '';
                              this.setState({
                                newReservation: {
                                  ...newReservation,
                                  payment_type:
                                    payment_type as any as PaymentType,
                                  amount_pay_on_board,
                                  payment_deferred,
                                  email_payment_to_address,
                                },
                              });
                            }}
                          />
                        )}
                      </div>
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationPaymentMethod'
                      ) && // 'INVOICE' is the only payment method supported for agents
                        !bookingSourceIsAgent && (
                          <div className={clsx(styles['field'])}>
                            {isChangeReservation ? (
                              <FieldWrapper label={t('Payment Method')}>
                                {t(newReservation?.payment_method as any) ||
                                  t('Unspecified')}
                              </FieldWrapper>
                            ) : (
                              <FormField
                                prompt={t('Payment Method')}
                                value={newReservation.payment_method ?? ''}
                                options={paymentMethodOptions.map(
                                  (ty, index) => ({
                                    text: t(ty),
                                    value: ty,
                                    key: index.toString(),
                                  })
                                )}
                                onChange={(payment_method: string) => {
                                  let payment_deferred =
                                    newReservation.payment_deferred;
                                  let email_payment_to_address =
                                    newReservation.email_payment_to_address;

                                  if (payment_method !== 'CREDIT_CARD') {
                                    payment_deferred = false;
                                    email_payment_to_address = '';
                                  }

                                  this.setState({
                                    newReservation: {
                                      ...newReservation,
                                      payment_method:
                                        payment_method as any as PaymentMethod,
                                      payment_deferred,
                                      email_payment_to_address,
                                    },
                                  });
                                }}
                              />
                            )}
                          </div>
                        )}
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationPaymentMethod'
                      ) &&
                        !bookingSourceIsAgent &&
                        newReservation.payment_method === 'OTHER' && (
                          <div className={clsx(styles['field'])}>
                            {isChangeReservation ? (
                              <FieldWrapper label={t('Other Payment Method')}>
                                {t(newReservation?.payment_method) ||
                                  t('Unspecified')}
                              </FieldWrapper>
                            ) : (
                              <FormField
                                search={true}
                                allowFreeInput={true}
                                prompt={t('Other Payment Method')}
                                value={
                                  newReservation.other_payment_method || ''
                                }
                                options={otherPaymentMethodOptions.map(
                                  (ty, index) => ({
                                    text: t(ty),
                                    value: ty,
                                    key: index.toString(),
                                  })
                                )}
                                onChange={(other_payment_method: string) => {
                                  this.setState({
                                    newReservation: {
                                      ...newReservation,
                                      other_payment_method:
                                        t(other_payment_method),
                                    },
                                  });
                                }}
                                placeholder={t(
                                  'type an payment method of select from list'
                                )}
                              />
                            )}
                          </div>
                        )}
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationPaymentMethod'
                      ) &&
                        !isChangeReservation && (
                          <div className={clsx(styles['field'])}>
                            <Checkbox
                              label={t('Send email with payment link')}
                              checked={Boolean(newReservation.payment_deferred)}
                              onChange={this.handleTogglePaymentDeferred}
                              disabled={!activatePaymentDeferred}
                            />
                          </div>
                        )}
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationPaymentMethod'
                      ) &&
                        newReservation.payment_deferred &&
                        !isChangeReservation && (
                          <div className={clsx(styles['field'])}>
                            <FormField
                              required
                              prompt={t('Payment link email address')}
                              value={
                                newReservation.email_payment_to_address ?? ''
                              }
                              disabled={!activatePaymentDeferred}
                              onChange={(email_payment_to_address: string) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    email_payment_to_address,
                                  },
                                }));
                              }}
                              error={errorMap['email_payment_to_address']}
                              autoComplete="off"
                            />
                          </div>
                        )}
                      <div className={clsx(styles['field'])}>
                        {isChangeReservation ? (
                          <FieldWrapper label={t('Customer Language')}>
                            {customerLanguage}
                          </FieldWrapper>
                        ) : (
                          <FormField
                            prompt={t('Customer Language')}
                            options={customerLanguageOptions.map(
                              (ty, index) => ({
                                text: t(ty.text),
                                value: ty.value,
                                key: index.toString(),
                              })
                            )}
                            onChange={(value: string) => {
                              this.handleCustomerLanguageChanged(value);
                            }}
                            value={customerLanguage}
                          />
                        )}
                      </div>

                      {(isChangeReservation || isResubmitReservation) &&
                        operationAllowed(
                          activeUser,
                          'write',
                          'reservationSupplierNotes'
                        ) && (
                          <>
                            <div className={clsx(styles['field'])}>
                              <FormField
                                prompt={t('Remarks')}
                                value={newReservation.agent_notes ?? ''}
                                onChange={(agent_notes) =>
                                  this.setState((prevState) => ({
                                    newReservation: {
                                      ...prevState.newReservation,
                                      agent_notes,
                                    },
                                  }))
                                }
                                required={false}
                              />
                            </div>
                            <div className={clsx(styles['field'])}>
                              <FormField
                                prompt={t('Replies')}
                                value={newReservation.supplier_notes ?? ''}
                                onChange={(supplier_notes) =>
                                  this.setState((prevState) => ({
                                    newReservation: {
                                      ...prevState.newReservation,
                                      supplier_notes,
                                    },
                                  }))
                                }
                                required={false}
                              />
                            </div>
                          </>
                        )}
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationAgentNotes'
                      ) ? (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            prompt={t('Remarks')}
                            value={newReservation.agent_notes ?? ''}
                            onChange={(agent_notes) =>
                              this.setState((prevState) => ({
                                newReservation: {
                                  ...prevState.newReservation,
                                  agent_notes,
                                },
                              }))
                            }
                            required={false}
                          />
                        </div>
                      ) : operationAllowed(
                          activeUser,
                          'write',
                          'reservationSupplierNotes'
                        ) ? (
                        <>
                          <div className={clsx(styles['field'])}>
                            <FormField
                              prompt={t('Memo')}
                              value={
                                newReservation.supplier_internal_notes ?? ''
                              }
                              onChange={(supplier_internal_notes) =>
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    supplier_internal_notes,
                                  },
                                }))
                              }
                              required={false}
                            />
                          </div>
                          <div className={clsx(styles['field'])}>
                            <FormField
                              prompt={t('Internal Note')}
                              value={
                                newReservation.supplier_internal_notes_for_dispatch ??
                                ''
                              }
                              onChange={(
                                supplier_internal_notes_for_dispatch
                              ) =>
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    supplier_internal_notes_for_dispatch,
                                  },
                                }))
                              }
                              required={false}
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </AccordionItem>
                <AccordionItem
                  id="transportation"
                  header={t('3. Transportation/Checkin')}
                  initialOpen={true}
                >
                  {() => (
                    <>
                      {transportations.length > 0 && (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            prompt={t('Transportation')}
                            value={newReservation.transportation || 'none'}
                            options={[
                              {
                                value: 'none',
                                text: t('Checkin/Checkout Only'),
                                key: '00',
                              },
                              ...transportations.map((trans, index) => ({
                                value: trans.key ?? '',
                                text:
                                  trans.service_type === 'FREE'
                                    ? `${trans.title} (${t('Free')})`
                                    : trans.title,
                                key: index.toString(),
                              })),
                            ]}
                            onChange={this.handleTransportationChange}
                            placeholder={t('Select transportation...')}
                          />
                        </div>
                      )}
                      <div className={clsx(styles['field'])}>
                        <input
                          style={{
                            display: 'none',
                          }}
                          ref={this.hotelInputRef}
                        />
                        <LocationSearchInput
                          prompt={t('Customer Hotel')}
                          location={
                            (newReservation.guest_hotel &&
                              newReservation.guest_hotel.location_name) ||
                            ''
                          }
                          onSearchChange={this.handleHotelLocationNameChanged}
                          onLocationSelect={this.handleHotelLocationSelected}
                        />
                      </div>
                      {operationAllowed(
                        activeUser,
                        'write',
                        'reservationPickupDropoff'
                      ) ? (
                        !newReservation.transportation ? (
                          <div className={clsx(styles['field'])}>
                            <LocationWithTimeEditFormFields
                              locationNameLabel={t('Checkin Location Name')}
                              locationDescriptionLabel={t(
                                'Checkin Location Description (ex: "Main lobby", "Car park", "Main wing")'
                              )}
                              locationTimeLabel={t('Checkin Time')}
                              locationDateLabel={t('Checkin Date')}
                              location={checkin}
                              productCandidateLocations={product.checkin || []}
                              startTime={startDate}
                              timeSlotKey={timeSlotKey ?? ''}
                              onLocationChange={(loc: LocationWithMoment) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    checkin:
                                      convertLocationWithMomentToReservationLocationWithTimeInput(
                                        loc
                                      ),
                                  },
                                }));
                              }}
                            />
                          </div>
                        ) : (
                          <div className={clsx(styles['field'])}>
                            <div className={clsx(styles['field'])}>
                              <LocationWithTimeEditFormFields
                                locationNameLabel={t('Pickup Location Name')}
                                locationDescriptionLabel={t(
                                  'Pickup Location Description (ex: "Main lobby", "Car park", "Main wing")'
                                )}
                                locationTimeLabel={t('Pickup Time')}
                                locationDateLabel={t('Pickup Date')}
                                location={pickup}
                                productCandidateLocations={product.pickup || []}
                                startTime={startDate}
                                timeSlotKey={timeSlotKey ?? ''}
                                onLocationChange={
                                  this.handlePickupLocationChanged
                                }
                              />
                            </div>

                            <div className={clsx(styles['field'])}>
                              <Checkbox
                                label={t(
                                  'Use same location for pickup and dropoff'
                                )}
                                checked={pickupDropoffUseSameLocation}
                                onChange={
                                  this.handleTogglePickupDropoffUseSameLocation
                                }
                              />
                            </div>

                            {!pickupDropoffUseSameLocation && (
                              <div className={clsx(styles['field'])}>
                                <LocationWithTimeEditFormFields
                                  locationNameLabel={t('Dropoff Location Name')}
                                  locationDescriptionLabel={t(
                                    'Dropoff Location Description (ex: "Main lobby", "Car park", "Main wing")'
                                  )}
                                  locationTimeLabel={t('Dropoff Time')}
                                  locationDateLabel={t('Dropoff Date')}
                                  location={dropoff}
                                  productCandidateLocations={
                                    product.dropoff || []
                                  }
                                  startTime={startDate}
                                  timeSlotKey={timeSlotKey ?? ''}
                                  onLocationChange={
                                    this.handleDropoffLocationChanged
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        newReservation.transportation && (
                          <div className={clsx(styles['field'])}>
                            <LocationSearchInput
                              prompt={t('Desired Pickup Location')}
                              location={
                                (newReservation.requested_pickup_location &&
                                  newReservation.requested_pickup_location
                                    .location_name) ||
                                ''
                              }
                              candidateLocations={product.pickup || []}
                              onSearchChange={(location_name: string) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    requested_pickup_location: {
                                      location_name,
                                    },
                                  },
                                }));
                              }}
                              onLocationSelect={({
                                title: location_name,
                                key: google_place_id,
                              }: {
                                title: string;
                                key: string;
                              }) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    requested_pickup_location: {
                                      location_name,
                                      google_place_id,
                                    },
                                  },
                                }));
                              }}
                            />

                            <LocationSearchInput
                              prompt={t('Desired Dropoff Location')}
                              location={
                                (newReservation.requested_dropoff_location &&
                                  newReservation.requested_dropoff_location
                                    .location_name) ||
                                ''
                              }
                              candidateLocations={product.dropoff || []}
                              onSearchChange={(location_name: string) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    requested_dropoff_location: {
                                      location_name,
                                    },
                                  },
                                }));
                              }}
                              onLocationSelect={({
                                title: location_name,
                                key: google_place_id,
                              }: {
                                title: string;
                                key: string;
                              }) => {
                                this.setState((prevState) => ({
                                  newReservation: {
                                    ...prevState.newReservation,
                                    requested_dropoff_location: {
                                      location_name,
                                      google_place_id,
                                    },
                                  },
                                }));
                              }}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                </AccordionItem>
                <AccordionItemWithRef
                  id="add-ons"
                  header={t('4. Add-on/Guest Info')}
                  initialOpen={true}
                  ref={this.addOnRef}
                >
                  {() =>
                    perBookingAddOns.length > 0 ||
                    optionalPerBookingFields.length > 0 ||
                    ((perParticipantFields.length > 0 ||
                      perParticipantAddOns.length > 0) &&
                      newReservation.guests.length > 0) ? (
                      <FormTableBox>
                        {this.state.showParticipantDeletionErrorAlert && (
                          <div className={clsx(styles['error-box'])}>
                            <ul className={clsx(styles['error-list'])}>
                              <li
                                key="add-on-error"
                                className={clsx(styles['error-list-item'])}
                              >
                                {t('Please click "X" button to delete guest')}
                              </li>
                            </ul>
                          </div>
                        )}
                        <table>
                          <colgroup>
                            <col />
                            <col />
                            {(perParticipantFields.length > 0 ||
                              perParticipantAddOns.length > 0) &&
                              newReservation.guests.length > 0 && (
                                <col className={styles['delete-cell']} />
                              )}
                          </colgroup>
                          <tbody>
                            {(perBookingAddOns.length > 0 ||
                              optionalPerBookingFields.length > 0) && (
                              <tr>
                                <th>{t('Per-Booking')}</th>
                                <td
                                  colSpan={
                                    (perParticipantFields.length > 0 ||
                                      perParticipantAddOns.length > 0) &&
                                    newReservation.guests.length > 0
                                      ? 2
                                      : 1
                                  }
                                >
                                  {perBookingAddOns.length > 0 && (
                                    <div className={clsx(styles['field'])}>
                                      <MultiSelect
                                        label={t('Add-ons')}
                                        placeholder={t('Select add-ons...')}
                                        options={perBookingAddOns.map(
                                          (addOn) => {
                                            const p =
                                              addOn.pricing &&
                                              addOn.pricing.find(
                                                (pr) =>
                                                  pr.method === 'PER_BOOKING'
                                              );
                                            const gross = (p && p.gross) || '';

                                            const remaining =
                                              this.state
                                                .initialAvailablePerBookingAddOns[
                                                addOn.key ?? ''
                                              ];

                                            const optionText =
                                              remaining !== undefined
                                                ? t('[{{remaining}} left]', {
                                                    remaining,
                                                  })
                                                : '';

                                            return {
                                              value: addOn.key ?? '',
                                              text:
                                                addOn.service_type === 'FREE'
                                                  ? `${addOn.title} (${t(
                                                      'Free'
                                                    )})`
                                                  : `${addOn.title} (${gross})`,
                                              addition: optionText,
                                            };
                                          }
                                        )}
                                        selectedValues={
                                          newReservation.add_ons || []
                                        }
                                        onChange={({
                                          value: add_ons,
                                        }: {
                                          value: string[];
                                        }) => {
                                          this.setState((prevState) => ({
                                            newReservation: {
                                              ...prevState.newReservation,
                                              add_ons,
                                            },
                                          }));
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div className={clsx(styles['field'])}>
                                    <FieldsForm
                                      fields={optionalPerBookingFields}
                                      getFieldValue={(key) => {
                                        const r = (
                                          newReservation.field_responses || []
                                        ).find((r) => r.key === key);

                                        if (!r) {
                                          return '';
                                        }

                                        return r.response || '';
                                      }}
                                      errorMap={errorMap}
                                      onFieldChange={(key, value) =>
                                        this.setState((prevState) => ({
                                          newReservation: {
                                            ...prevState.newReservation,
                                            field_responses: [
                                              ...(
                                                prevState.newReservation
                                                  .field_responses || []
                                              ).filter((r) => r.key !== key),
                                              {
                                                key,
                                                response: value,
                                              },
                                            ],
                                          },
                                        }))
                                      }
                                      mode="INPUT"
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}

                            {(perParticipantFields.length > 0 ||
                              perParticipantAddOns.length > 0) &&
                              newReservation.guests.length > 0 &&
                              newReservation.guests.map((g, idx) => {
                                const addOnOptions = perParticipantAddOns
                                  .filter(
                                    (addOn) =>
                                      addOn.pricing &&
                                      addOn.pricing.find(
                                        (p) =>
                                          p.guest_type &&
                                          p.guest_type.key === g.guest_type_key
                                      )
                                  )
                                  .map((addOn) => {
                                    const p =
                                      addOn.pricing &&
                                      addOn.pricing.find(
                                        (p) =>
                                          p.guest_type &&
                                          p.guest_type.key === g.guest_type_key
                                      );
                                    const gross = (p && p.gross) || '';

                                    const remaining =
                                      this.state.availablePerParticipantAddOns[
                                        addOn.key ?? ''
                                      ];

                                    const optionText =
                                      remaining !== undefined
                                        ? t('[{{remaining}} left]', {
                                            remaining,
                                          })
                                        : '';

                                    return {
                                      text: `${addOn.title} (${gross})`,
                                      value: addOn.key ?? '',
                                      addition: optionText,
                                    };
                                  });
                                // Initialize guest error map if entire form is showing 1 or more errors.
                                const guestErrorMap =
                                  Object.keys(errorMap).length > 0
                                    ? getFieldResponseErrors(
                                        g.field_responses || [],
                                        perParticipantFields,
                                        t,

                                        activeUser?.organization_type ===
                                          'SUPPLIER'
                                      )
                                    : {};
                                return (
                                  <>
                                    <tr>
                                      <th>
                                        {
                                          (
                                            guestTypes.find(
                                              (guestType) =>
                                                guestType &&
                                                guestType.key ===
                                                  g.guest_type_key
                                            ) as any
                                          )?.title
                                        }
                                      </th>
                                      <td
                                        className={clsx(
                                          this.state
                                            .showParticipantDeletionErrorAlert &&
                                            this.state
                                              .showParticipantDeletionErrorGuestTypeKey ===
                                              g.guest_type_key &&
                                            styles['table-list-td__error']
                                        )}
                                      >
                                        <div
                                          className={styles['table-list-td']}
                                        >
                                          {addOnOptions.length > 0 && (
                                            <div
                                              className={clsx(styles['field'])}
                                            >
                                              <MultiSelect
                                                label={t('Add-ons')}
                                                placeholder={t(
                                                  'Select add-ons...'
                                                )}
                                                options={addOnOptions}
                                                selectedValues={g.add_ons || []}
                                                onChange={({
                                                  value: add_ons,
                                                }: {
                                                  value: string[];
                                                }) => {
                                                  this.setState(
                                                    (prevState) => ({
                                                      newReservation: {
                                                        ...prevState.newReservation,
                                                        guests:
                                                          prevState.newReservation.guests.map(
                                                            (g2, idx2) =>
                                                              idx === idx2
                                                                ? {
                                                                    ...g2,
                                                                    add_ons,
                                                                  }
                                                                : g2
                                                          ),
                                                      },
                                                    })
                                                  );
                                                }}
                                              />
                                            </div>
                                          )}

                                          <FieldsForm
                                            fields={perParticipantFields}
                                            errorMap={guestErrorMap}
                                            getFieldValue={(key) => {
                                              const r = (
                                                g.field_responses || []
                                              ).find((r) => r.key === key);

                                              if (!r) {
                                                return '';
                                              }

                                              return r.response || '';
                                            }}
                                            onFieldChange={(
                                              key,
                                              value,
                                              leaveBlank
                                            ) =>
                                              this.setState((prevState) => ({
                                                newReservation: {
                                                  ...prevState.newReservation,
                                                  guests:
                                                    prevState.newReservation.guests.map(
                                                      (g2, idx2) =>
                                                        idx === idx2
                                                          ? {
                                                              ...g2,
                                                              field_responses: [
                                                                ...(
                                                                  g2.field_responses ||
                                                                  []
                                                                ).filter(
                                                                  (r) =>
                                                                    r.key !==
                                                                    key
                                                                ),
                                                                {
                                                                  key,
                                                                  response:
                                                                    value,
                                                                  leaveBlank,
                                                                },
                                                              ],
                                                            }
                                                          : g2
                                                    ),
                                                },
                                              }))
                                            }
                                            getFieldLeaveBlankValue={(key) => {
                                              const r = (
                                                g.field_responses || []
                                              ).find((r) => r.key === key);

                                              if (!r) {
                                                return '';
                                              }

                                              return (
                                                (r as any).leaveBlank || false
                                              );
                                            }}
                                            showLeaveBlankBox={false}
                                            requiredFieldOptionalEntry={
                                              activeUser?.organization_type ===
                                              'SUPPLIER'
                                            }
                                            mode="INPUT"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        className={clsx(
                                          this.state
                                            .showParticipantDeletionErrorAlert &&
                                            this.state
                                              .showParticipantDeletionErrorGuestTypeKey ===
                                              g.guest_type_key &&
                                            styles['table-list-td__error']
                                        )}
                                      >
                                        <div>
                                          <DeleteIcon
                                            onClick={() => {
                                              const guests =
                                                this.state.newReservation.guests.filter(
                                                  (g, guestIdx) =>
                                                    idx !== guestIdx
                                                );
                                              this.setState((prevState) => ({
                                                showParticipantDeletionErrorAlert:
                                                  false,
                                                showParticipantDeletionErrorGuestTypeKey:
                                                  '',
                                                newReservation: {
                                                  ...prevState.newReservation,
                                                  guests,
                                                },
                                              }));
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </FormTableBox>
                    ) : (
                      <div>
                        {t(
                          'No add-ons and no guest information inputs for the product'
                        )}
                      </div>
                    )
                  }
                </AccordionItemWithRef>
                <AccordionItem
                  id="summary"
                  header={t('5. Summary')}
                  initialOpen={true}
                >
                  {() => (
                    <>
                      {product?.has_promo_code && (
                        <FieldWrapper label={t('Promo code')}>
                          <PromoCodeInput
                            reservation={
                              newReservation as any as NewReservation
                            }
                            onChange={(promo_code) => {
                              this.setState((prevState) => ({
                                newReservation: {
                                  ...prevState.newReservation,
                                  promo_code,
                                },
                              }));
                            }}
                          />
                        </FieldWrapper>
                      )}

                      {this.state.newReservation.product_instance_id && (
                        <div className={clsx(styles['field'])}>
                          <div className={clsx(styles['field-label'])}>
                            <label>{t('Fare summary')}</label>
                          </div>
                          <div>
                            {(this.state.newReservation.guests || []).length >
                              0 && (
                              <FareSummary
                                reservationParams={this.state.newReservation}
                                isChangeReservation={!!isChangeReservation}
                              />
                            )}
                          </div>
                          {billingInfo &&
                            operationAllowed(
                              activeUser,
                              'write',
                              'fareAdjustmentChangeDirection'
                            ) && (
                              <div
                                style={{
                                  marginTop: '15px',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Button
                                  style="yellow"
                                  size="middle"
                                  type="button"
                                  onClick={() => {
                                    this.setState({
                                      editAdjustingFare: true,
                                    });
                                  }}
                                >
                                  {t('Fare Adjustment')}
                                </Button>
                              </div>
                            )}
                          {this.state.editAdjustingFare && (
                            <ReservationFareAdjustmentModal
                              header={t('Fare Adjustment')}
                              currency={productCurrency}
                              onClose={() => {
                                this.setState({
                                  editAdjustingFare: false,
                                });
                              }}
                              reservationParams={this.state.newReservation}
                              isChangeReservation={!!isChangeReservation}
                              adjustmentAmount={
                                this.state.newReservation.adjustment_amount
                              }
                              saveAdjustingFareParamsNewReservation={(
                                adjustmentAmount: NewReservation['adjustment_amount']
                              ) =>
                                this.setState({
                                  newReservation: {
                                    ...newReservation,
                                    adjustment_amount: adjustmentAmount,
                                  },
                                })
                              }
                            />
                          )}
                        </div>
                      )}

                      {this.state.newReservation &&
                        this.state.newReservation.payment_type ===
                          'PAID_PARTIALLY' && (
                          <div className={clsx(styles['field'])}>
                            <FieldWrapper
                              label={
                                t('Amount to pay on board') +
                                ` (${productCurrency})`
                              }
                              error={errorMap['amount_pay_on_board']}
                            >
                              <MoneyInput
                                currencyCode={productCurrency}
                                moneyAmount={
                                  this.state.newReservation
                                    .amount_pay_on_board || ''
                                }
                                onChange={(amount_pay_on_board: string) => {
                                  this.setState({
                                    newReservation: {
                                      ...newReservation,
                                      amount_pay_on_board,
                                    },
                                  });
                                }}
                              />
                            </FieldWrapper>
                          </div>
                        )}
                      {isChangeReservation &&
                        reservation &&
                        reservation.status !== 'REQUESTED' && (
                          <AmountDifference
                            rebookFromReservation={reservation}
                          />
                        )}
                      {isChangeReservation &&
                        this.state.newReservation.payment_type ===
                          'PAID_PARTIALLY' &&
                        reservation && (
                          <FieldWrapper label={t('Payment')}>
                            <PaymentForChangeReservation
                              rebookFromReservation={reservation}
                              amountToPayOnBoard={
                                this.state.newReservation.amount_pay_on_board ||
                                ''
                              }
                              onChangeAmountToPayOnBoard={(
                                amount_pay_on_board
                              ) => {
                                this.setState({
                                  newReservation: {
                                    ...newReservation,
                                    amount_pay_on_board,
                                  },
                                });
                              }}
                            />
                          </FieldWrapper>
                        )}
                    </>
                  )}
                </AccordionItem>
                <MessageModal
                  title={t(
                    'Reservation with PIF (paid in full) by Credit Card'
                  )}
                  message={t(
                    'Creating a reservation with "PIF (paid in full)" and "Credit card", but no email to request online payment. Are you sure to create a new reservation without online payment by credit card?'
                  )}
                  open={this.state.openNoEmailWithPaymentLinkMessageModal}
                  onClose={() => {
                    this.setState({
                      openNoEmailWithPaymentLinkMessageModal: false,
                    });
                  }}
                  onSubmit={() => {
                    const openSubmitReservationMessageModal =
                      Object.keys(currentValidationErrorMap).length === 0;
                    this.setState({
                      openNoEmailWithPaymentLinkMessageModal: false,
                      openSubmitReservationMessageModal,
                      lastSubmissionValidationErrorMap:
                        currentValidationErrorMap,
                    });
                    scrollToTop();
                  }}
                />
                <MessageModal
                  title={t('Payment by Credit Card')}
                  message={
                    amountDifference[0] === '-'
                      ? t(
                          'By changing reservation, {{amount}} will be refunded to the credit card. Are you sure to change reservation paid by credit card?',
                          {
                            amount: amountDifference.slice(1),
                          }
                        )
                      : t(
                          'By changing reservation, {{amount}} will be charged to the credit card. Are you sure to change reservation paid by credit card?',
                          {
                            amount: amountDifference,
                          }
                        )
                  }
                  open={
                    this.state
                      .openCreditCardChargeOrRefundForConfirmedReservationMessageModal
                  }
                  onClose={() => {
                    this.setState({
                      openCreditCardChargeOrRefundForConfirmedReservationMessageModal:
                        false,
                    });
                  }}
                  onSubmit={() => {
                    const openSubmitReservationMessageModal =
                      Object.keys(currentValidationErrorMap).length === 0;
                    this.setState({
                      openCreditCardChargeOrRefundForConfirmedReservationMessageModal:
                        false,
                      openSubmitReservationMessageModal,
                      lastSubmissionValidationErrorMap:
                        currentValidationErrorMap,
                    });
                    scrollToTop();
                  }}
                />
                <MessageModal
                  title={t('Payment by Credit Card')}
                  message={t(
                    'By changing reservation, {{amount}} will be charged to the credit card. Are you sure to change reservation paid by credit card?',
                    {
                      amount: currency(
                        billingInfo?.amount_gross ?? ''
                      ).format(),
                    }
                  )}
                  open={
                    this.state
                      .openCreditCardChargeForRequestedReservationMessageModal
                  }
                  onClose={() => {
                    this.setState({
                      openCreditCardChargeForRequestedReservationMessageModal:
                        false,
                    });
                  }}
                  onSubmit={() => {
                    const openSubmitReservationMessageModal =
                      Object.keys(currentValidationErrorMap).length === 0;
                    this.setState({
                      openCreditCardChargeForRequestedReservationMessageModal:
                        false,
                      openSubmitReservationMessageModal,
                      lastSubmissionValidationErrorMap:
                        currentValidationErrorMap,
                    });
                    scrollToTop();
                  }}
                />
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <SubmitReservationButton
                    reservation={this.reservationFromState() as Reservation}
                    open={this.state.openSubmitReservationMessageModal}
                    onClick={() => {
                      if (
                        this.needToOpenCreditCardChargeOrRefundForConfirmedReservationMessageModal(
                          reservation,
                          isChangeReservation ?? false,
                          amountDifference
                        )
                      ) {
                        this.setState({
                          openCreditCardChargeOrRefundForConfirmedReservationMessageModal:
                            true,
                        });
                      } else if (
                        this.needToOpenCreditCardChargeForRequestedReservationMessageModal(
                          reservation,
                          isChangeReservation ?? false
                        )
                      ) {
                        this.setState({
                          openCreditCardChargeForRequestedReservationMessageModal:
                            true,
                        });
                      } else if (
                        needToOpenNoEmailWithPaymentLinkMessageModal(
                          this.state.newReservation as any,
                          isChangeReservation ?? false
                        )
                      ) {
                        this.setState({
                          openNoEmailWithPaymentLinkMessageModal: true,
                        });
                      } else {
                        const openSubmitReservationMessageModal =
                          Object.keys(currentValidationErrorMap).length === 0;
                        this.setState({
                          openSubmitReservationMessageModal,
                          lastSubmissionValidationErrorMap:
                            currentValidationErrorMap,
                        });
                        scrollToTop();
                      }
                    }}
                    onSubmit={() => {
                      this.props.onSubmit(reservationToSubmit);
                      if (actimReservationId != '') {
                        this.props.updateActimReservation(actimReservationId, {
                          is_already_taken: true,
                        });
                      }
                    }}
                    onClose={() => {
                      this.setState({
                        openSubmitReservationMessageModal: false,
                      });
                    }}
                  />
                </div>
              </form>
            </div>
            <div className={styles['desktop-only']}>
              <div className={styles['page-sidebar']}>
                <div className={styles['page-sidebar-window']}>
                  <div className={styles['page-sidebar-header']}>
                    {t('New Reservation Info')}
                  </div>
                  <div className={styles['link']}>
                    <a href="#participation">{t('1. Participation')}</a>
                  </div>
                  <div className={styles['link']}>
                    <a href="#basic-info">{t('2. Basic Information')}</a>
                  </div>
                  <div className={styles['link']}>
                    <a href="#transportation">
                      {t('3. Transportation/Checkin')}
                    </a>
                  </div>
                  <div className={styles['link']}>
                    <a href="#add-ons">{t('4. Add-on/Guest Info')}</a>
                  </div>
                  <div className={styles['link']}>
                    <a href="#summary">{t('5. Summary')}</a>
                  </div>
                </div>
                <div className={styles['page-sidebar-window']}>
                  <OrderSummary />
                </div>
              </div>
            </div>
            <nav className={baseStyles['base-spNav']}>
              <ul>
                <li>
                  <a href="#participation">
                    <img src={oneIcon} />
                  </a>
                </li>
                <li>
                  <a href="#basic-info">
                    <img src={twoIcon} />
                  </a>
                </li>
                <li>
                  <a href="#transportation">
                    <img src={threeIcon} />
                  </a>
                </li>
                <li>
                  <a href="#add-ons">
                    <img src={fourIcon} />
                  </a>
                </li>
                <li>
                  <a href="#summary">
                    <img src={fiveIcon} />
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      this.setState({
                        showBookingSummaryModal:
                          !this.state.showBookingSummaryModal,
                      })
                    }
                  >
                    <img src={bookingSummaryIcon} />
                  </a>
                </li>
              </ul>
            </nav>
            {this.state.showBookingSummaryModal && (
              <div
                ref={this.bookingSummaryPopupRef}
                className={styles['booking-summary-popup']}
              >
                <OrderSummary />
              </div>
            )}
            {this.state.numberInputGuestType && (
              <NumberInputModal
                title={t('Set number of participants : {{guestType}}', {
                  guestType: this.state.numberInputGuestType?.title || '',
                })}
                initialValue={
                  (newReservation?.guests &&
                    newReservation?.guests.filter(
                      (g) =>
                        g.guest_type_key ===
                        this.state.numberInputGuestType?.key
                    ).length) ||
                  101
                }
                open={true}
                onClose={() => {
                  this.setState({
                    numberInputGuestType: undefined,
                  });
                }}
                onSubmit={(value: number) => {
                  this.updateGuest(
                    this.state.numberInputGuestType?.key ?? '',
                    value
                  );
                  this.setState({
                    numberInputGuestType: undefined,
                  });
                }}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: OwnProps & RouteProps
) => ({
  activeUser: activeUserSelector(state),
  confirmationType: state.availability.confirmationType,
  loading:
    state.reservations.loading ||
    state.products.loading ||
    state.productInstances.currentLoading,
  error: state.reservations.error,
  lastCreatedReservationID: state.reservations.lastCreatedReservationID,
  productInstance:
    state.productInstances.current &&
    state.productInstances.current.id === ownProps.match.params.instanceID
      ? state.productInstances.current
      : null,
  product: state.products.byID[ownProps.match.params.productID],
  locale: state.language.selected.iso,
  monthYearFormat: state.language.selected.monthYearFormat,
  invalidated: state.userDataInvalidated,
  billingInfo: state.fares.billingInfo,
  rebookFromReservationBillingInfo:
    state.fares.rebookFromReservationBillingInfo,
  actimReservation: state.actimReservations.byID,
  activeUserOrganization: activeUserOrganizationSelector(state),
  customersById: state.customers.byID,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (reservation: any) => {
    dispatch(createReservation(reservation));
  },
  fetchProductByID: (id: string) => {
    dispatch(fetchProductByID(id));
  },
  fetchProductInstanceByID: (id: string) => {
    dispatch(fetchProductInstanceByID(id));
  },
  fetchActimReservationByID: (id: string) => {
    dispatch(fetchActimReservationByID(id));
  },
  updateActimReservation: (id: string, patch: ActimReservation$Patch) => {
    dispatch(updateActimReservation(id, patch));
  },
  checkAvailability: (productInstanceId: string, guestTypeKeys: string[]) => {
    dispatch(checkAvailability(productInstanceId, guestTypeKeys));
  },
  fetchCustomerByID: (id: string) => {
    dispatch(fetchCustomerByID(id));
  },
});

export const BookingForm = compose<Props, OwnProps>(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(BookingFormComponent);
