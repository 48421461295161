import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { fetchActimReservations } from 'client/actions/actimReservations';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { actimReservationVisibleColumnsSelector } from 'client/reducers/actimReservationTableControls';
import {
  //activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { getActimReservationTableColumns } from 'client/libraries/util/getActimReservationTableColumns';
import { operationAllowed } from 'shared/models/access';
import type { ActimReservation } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getActimReservationTableColumns';
import 'react-table/react-table.css';
import componentStyles from 'client/components/components.module.css';

import baseStyles from '../../base.module.css';

import { ActimReservationCustomTable } from './ActimReservationCustomTable';

export const ActimReservationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const actimReservations = useSelector(
    (state: ReduxState) => state.actimReservations.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.actimReservations.loading
  );
  const visibleColumns = useSelector(actimReservationVisibleColumnsSelector);
  //const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );

  const getAllColumns = (): ColumnType[] => {
    return getActimReservationTableColumns(t);
  };

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return ['edit', ...(columnMask.filter((c) => c !== 'edit') as any)].map(
      (c) => allColumns.find((col) => col.id === c) as any
    );
  };

  const [allColumns, setAllColumns] = React.useState(getAllColumns());
  const [showColumns, setShowColumns] = React.useState(
    getColumns(visibleColumns as string[])
  );
  const [listActim, setListActim] = React.useState<ActimReservation[]>(
    actimReservations.filter(
      (reservation) => typeof reservation?.is_already_taken === 'undefined'
    )
  );
  React.useEffect(() => {
    if (invalidated) {
      setAllColumns(getAllColumns());
    }
  }, [invalidated, locale]);
  React.useEffect(() => {
    setShowColumns(getColumns(visibleColumns as string[]));
  }, [visibleColumns]);
  React.useEffect(() => {
    dispatch(fetchActimReservations(activeUser?.organization_id));
  }, [t, activeUser]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);
  React.useEffect(() => {
    setListActim(
      actimReservations.filter(
        (reservation) => typeof reservation?.is_already_taken === 'undefined'
      )
    );
  }, [actimReservations]);
  return (
    <>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <>
          <div className={clsx(baseStyles['base-main__body__header'])}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__right'],
                baseStyles['spOrder-2']
              )}
            >
              <div
                className={clsx(
                  baseStyles['base-main__body__header__right__another'],
                  baseStyles['is-close']
                )}
              ></div>
            </div>
          </div>
          <div
            className={clsx(
              componentStyles['c-tab-box'],
              baseStyles['scroll-target-pane']
            )}
          >
            <ul className={clsx(componentStyles['c-tab-box__tab'])}>
              <li className={clsx(componentStyles['is-active'])}>
                <a>{t('Not Captured')}</a>
              </li>
            </ul>
            <ActimReservationCustomTable
              actimReservations={listActim}
              visibleColumns={showColumns}
            />
          </div>
        </>
      )}
    </>
  );
};
