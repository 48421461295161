// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { getLanguageName } from 'client/libraries/i18n';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateOrganization } from 'client/actions/organizations';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, Input } from 'client/components/Form';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
import type { EmailCustomizationEmailType } from 'shared/models/swagger';

import { getDefaultEmailSubject } from './FormValues';

type FormValues = {
  subject: string,
  translation: { [lang: string]: string },
};

type Props = {
  emailType: EmailCustomizationEmailType,
  open: boolean,
  onClose?: () => void,
  onSaveSuccessful?: () => void,
};

export const PerEmailSubjectEditorModal = ({
  emailType,
  open,
  onClose,
  onSaveSuccessful,
}: Props) => {
  const { t } = useTranslation();

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();

  const shouldUseDefaultSubject = Boolean(
    activeUserOrganization &&
      !activeUserOrganization?.email_customization_settings?.custom_email_subjects?.find(
        (msg) => msg.email_type === emailType
      )
  );

  const translationLanguages = React.useMemo(
    () => supportedLanguages.filter((lang) => lang !== defaultLanguage),
    [supportedLanguages, defaultLanguage]
  );
  const subject = shouldUseDefaultSubject
    ? getDefaultEmailSubject(emailType, t, defaultLanguage)
    : activeUserOrganization?.email_customization_settings?.custom_email_subjects?.find(
        (msg) => msg.email_type === emailType
      )?.subject ?? '';

  React.useEffect(() => {
    if (!shouldUseDefaultSubject && subject) {
      dispatch(batchGetTranslations([subject]));
    }
  }, [subject, shouldUseDefaultSubject]);

  const translation = React.useMemo(() => {
    const trans: { [lang: string]: string } = {};
    if (shouldUseDefaultSubject) {
      for (const lang of translationLanguages) {
        trans[lang.toLowerCase()] = getDefaultEmailSubject(emailType, t, lang);
      }
    } else {
      const apiTrans = apiTranslations.find(
        (trans) => trans[defaultLanguage.toLowerCase()] === subject
      );
      if (apiTrans) {
        for (const lang of translationLanguages) {
          trans[lang.toLowerCase()] = apiTrans[lang.toLowerCase()] ?? '';
        }
      }
    }

    return trans;
  }, [
    shouldUseDefaultSubject,
    defaultLanguage,
    translationLanguages,
    activeUserOrganization,
    t,
    subject,
    apiTranslations,
  ]);

  const initialValues = React.useMemo(() => {
    return {
      subject,
      translation,
    };
  }, [subject, translation]);

  const { required } = getValidators(t);

  const sourceLanguageName = getLanguageName(defaultLanguage, t);

  return (
    <Modal open={open} title={t('Edit Subject')} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          const organizationPatch = {
            email_customization_settings: {
              ...activeUserOrganization?.email_customization_settings,
              custom_email_subjects: [
                ...(
                  activeUserOrganization?.email_customization_settings
                    ?.custom_email_subjects ?? []
                ).filter((msg) => msg.email_type !== emailType),
                {
                  email_type: emailType,
                  subject: values?.subject ?? '',
                },
              ],
            },
          };

          if (
            Object.values(values.translation).some((translatedText) =>
              Boolean(translatedText)
            )
          ) {
            const translation = {
              source_language: defaultLanguage,
              [defaultLanguage.toLowerCase()]: values.subject,
            };

            Object.keys(values.translation).forEach((lang) => {
              translation[lang.toLowerCase()] = values.translation[lang];
            });

            await Promise.all([
              dispatch(
                updateOrganization(
                  activeUserOrganization?.id ?? '',
                  'SUPPLIER',
                  organizationPatch
                )
              ),
              dispatch(updateTranslations([translation])),
            ]);
          } else {
            await dispatch(
              updateOrganization(
                activeUserOrganization?.id ?? '',
                'SUPPLIER',
                organizationPatch
              )
            );
          }

          onSaveSuccessful?.();
          onClose?.();
        }}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper label={sourceLanguageName}>
              <Field name="subject" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <Input {...input} error={touched && error} />
                )}
              </Field>
            </FieldWrapper>
            {translationLanguages.map((lang) => (
              <Box mt={1}>
                <FieldWrapper key={lang} label={getLanguageName(lang, t)}>
                  <Field name={`translation.${lang.toLowerCase()}`}>
                    {({ input }) => <Input {...input} />}
                  </Field>
                </FieldWrapper>
              </Box>
            ))}
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
