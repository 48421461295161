import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { s3MediaDownloadStorage } from 'client/libraries/s3';
import { Box } from 'client/components/Box/Box';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { MediaDownloadItem } from 'shared/models/swagger';

import styles from './PublishedSalesPageForm.module.css';

interface Props {
  mediaItem: MediaDownloadItem;
  shouldShowPrices: boolean;
}

export const MediaItemView = ({ mediaItem, shouldShowPrices }: Props) => {
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = React.useState('');

  React.useEffect(() => {
    if (mediaItem.content?.url) {
      const url = new URL(mediaItem.content?.url);

      s3MediaDownloadStorage
        .get(url.pathname.replace(/\//, ''))
        .then((responseUrl) => setPreviewUrl(responseUrl));
    }
  }, [mediaItem.content?.url]);

  const isImage = mediaItem?.content?.type !== 'VIDEO';

  return (
    <>
      <div className={styles['picture__item__pic']}>
        {isImage ? (
          <img src={previewUrl} />
        ) : (
          previewUrl && (
            <video width="160" height="160" controls>
              <source src={previewUrl} type="video/mp4" />
            </video>
          )
        )}
      </div>

      {shouldShowPrices && (
        <Box mt={2} mb={2} className={styles['price']}>
          {mediaItem.price
            ? `${mediaItem.price?.label} (${formattedCurrencyAmount(
                mediaItem.price?.price ?? ''
              )})`
            : t('FREE')}
        </Box>
      )}
    </>
  );
};
