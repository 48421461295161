import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Modal } from 'client/components/Modal/Modal';
import { Input, Button } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateProduct } from 'client/actions/products';
import type { Product$Patch } from 'shared/models/swagger';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

type Props = {
  onClose: () => void;
};

type FormValues = {
  recurrence: Recurrence[];
};

type Recurrence = {
  startDateLocal: string;
  endDateLocal: string;
  startTimeLocal: string;
  duration: string;
  startTimeId?: string;
  actimPlanTimeZoneId?: string[];
};

export const ActimPlanTimeZoneIdEditor = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const product = React.useContext(EditingProductContext);

  const initialValues = React.useMemo(() => {
    const value: Recurrence[] = [];
    (product?.recurrence || []).forEach((recurrence) => {
      (recurrence.start_times || []).forEach((start_time) => {
        value.push({
          startDateLocal: recurrence?.start_date_local,
          endDateLocal: recurrence?.end_date_local,
          startTimeLocal: start_time?.start_time_local,
          duration: start_time?.duration,
          startTimeId: start_time?.start_time_id,
          actimPlanTimeZoneId: start_time?.third_party_reference?.split(','),
        });
      });
    });
    return { recurrence: value };
  }, [activeUserOrganization, product]);

  const convertFormValuesToProductPatch = (
    values: FormValues
  ): Product$Patch => {
    const recurrence = product?.recurrence;
    (recurrence || []).forEach((recurrence) => {
      (recurrence.start_times || []).forEach((start_time) => {
        values.recurrence.forEach((element) => {
          if (
            element.startTimeId == start_time.start_time_id &&
            element.startDateLocal == recurrence.start_date_local &&
            element.endDateLocal == recurrence.end_date_local
          ) {
            start_time.third_party_reference =
              element.actimPlanTimeZoneId?.join(',');
          }
        });
      });
    });
    return {
      recurrence: recurrence,
    };
  };

  return (
    <>
      <Modal
        insertRoot
        title={t('Edit Actim Plan ID')}
        open={true}
        onClose={onClose}
      >
        <Form
          initialValues={initialValues}
          onSubmit={async (values: FormValues) => {
            try {
              await dispatch(
                updateProduct(
                  product?.id || '',
                  convertFormValuesToProductPatch(values)
                )
              );
            } catch (err) {
              console.log(err);
              return { [FORM_ERROR]: t('Save Failed') };
            }
          }}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div
                className={styles['c-table-list']}
                style={{ textAlign: 'center' }}
              >
                <table>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t(
                        'Participation Rule Availability Date Range (Start Time/Duration)'
                      )}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {t('Actim Plan Time Zone ID')}
                    </th>
                  </tr>
                  <FieldArray name="recurrence">
                    {({ fields: recurrenceFields }) =>
                      recurrenceFields.map((name) => (
                        <Field key={name} name={name}>
                          {({ input }) => (
                            <>
                              <tr>
                                <td>
                                  {`${input.value.startDateLocal}~${input.value.endDateLocal} (${input.value.startTimeLocal}_${input.value.duration})`}
                                </td>
                                <FieldArray
                                  name={`${name}.actimPlanTimeZoneId`}
                                >
                                  {({ fields: planTimeZoneIdFields }) => (
                                    <>
                                      <td>
                                        {planTimeZoneIdFields.length === 0 && (
                                          <AddIcon
                                            onClick={() =>
                                              (
                                                planTimeZoneIdFields as any
                                              ).insertAt(0, '')
                                            }
                                          />
                                        )}
                                        {planTimeZoneIdFields.map(
                                          (idName, idx2) => (
                                            <Field key={idName} name={idName}>
                                              {({ input }) => (
                                                <>
                                                  <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    mt={2}
                                                    mr={3}
                                                    mb={2}
                                                  >
                                                    <Input {...input} />
                                                    <Box ml={4}>
                                                      <AddIcon
                                                        onClick={() =>
                                                          (
                                                            planTimeZoneIdFields as any
                                                          ).insertAt(0, '')
                                                        }
                                                      />
                                                    </Box>
                                                    <Box ml={2}>
                                                      <DeleteIcon
                                                        onClick={() => {
                                                          planTimeZoneIdFields.remove(
                                                            idx2
                                                          );
                                                        }}
                                                      />
                                                    </Box>
                                                  </Box>
                                                </>
                                              )}
                                            </Field>
                                          )
                                        )}
                                      </td>
                                    </>
                                  )}
                                </FieldArray>
                              </tr>
                            </>
                          )}
                        </Field>
                      ))
                    }
                  </FieldArray>
                </table>
              </div>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  style="green"
                  size="middle"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          )}
        </Form>
      </Modal>
    </>
  );
};
