import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { Modal } from 'client/components/Modal/Modal';
import { PortalTooltip } from 'client/components/PortalTooltip/PortalTooltip';
import { useBounds } from 'client/hooks/useBounds';
import {
  Button,
  FieldWrapper,
  Input,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import { EditProductCollectionHighlightModal } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/EditProductCollectionHighlightModal';
import { DraggableProductCollectionHighlight } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/DraggableProductCollectionHighlight';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { selectedPrivateMarketplaceIndexSelector } from 'client/reducers/privateMarketplace';
import type {
  ProductCollectionHighlight,
  ProductCollectionPage,
} from 'shared/models/swagger';
import copyIcon from 'client/images/ic_copy.svg';
import baseStyles from 'client/base.module.css';

import styles from './PrivateMarketplaceProductCollectionPages.module.css';

type Props = {
  existingProductCollectionPage?: ProductCollectionPage;
  onSave: (arg0: ProductCollectionPage) => void;
  collectionType: 'CATEGORY' | 'FEATURE';
  open: boolean;
  onClose: () => void;
  index?: number;
};
export const EditProductCollectionPageModal = ({
  collectionType,
  existingProductCollectionPage,
  onSave,
  open,
  onClose,
  index,
}: Props) => {
  const { t } = useTranslation();
  const [showCreateHighlightModal, setShowCreateHighlightModal] =
    React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(
    existingProductCollectionPage?.name || ''
  );
  const [displayName, setDisplayName] = React.useState<string>(
    existingProductCollectionPage?.display_name ||
      existingProductCollectionPage?.name ||
      ''
  );
  const [description, setDescription] = React.useState<string>(
    existingProductCollectionPage?.description || ''
  );
  const [bannerUrl, setBannerUrl] = React.useState<string>(
    existingProductCollectionPage?.banner_image_url || ''
  );
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string>(
    existingProductCollectionPage?.thumbnail_image_url || ''
  );
  const [productIds, setProductIds] = React.useState<string[]>(
    existingProductCollectionPage?.product_ids || []
  );
  const [highlights, setHighlights] = React.useState<
    ProductCollectionHighlight[]
  >(existingProductCollectionPage?.highlights || []);
  const [isHidden, setIsHidden] = React.useState<boolean>(
    !config.enablePMPCategoryHideSettings
      ? false
      : existingProductCollectionPage
      ? existingProductCollectionPage?.is_hidden || false
      : true
  );
  const [isHiddenFromPmpAndSearch, setIsHiddenFromPmpAndSearch] =
    React.useState<boolean>(
      existingProductCollectionPage?.is_hidden_from_pmp_and_search || false
    );
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const products = useSelector(bookingWidgetProductSummariesSelector);
  let headerText = '';

  switch (collectionType) {
    case 'CATEGORY':
      headerText = existingProductCollectionPage
        ? t('Edit Category Page')
        : t('Create Category Page');
      break;

    case 'FEATURE':
    default:
      headerText = existingProductCollectionPage
        ? t('Edit Feature Page')
        : t('Create Feature Page');
      break;
  }

  const reset = () => {
    setName(existingProductCollectionPage?.name || '');
    setDisplayName(
      existingProductCollectionPage?.display_name ||
        existingProductCollectionPage?.name ||
        ''
    );
    setDescription(existingProductCollectionPage?.description || '');
    setBannerUrl(existingProductCollectionPage?.banner_image_url || '');
    setThumbnailUrl(existingProductCollectionPage?.thumbnail_image_url || '');
    setProductIds(existingProductCollectionPage?.product_ids || []);
    setHighlights(existingProductCollectionPage?.highlights || []);
    setIsHidden(
      existingProductCollectionPage
        ? existingProductCollectionPage?.is_hidden || false
        : true
    );
    setIsHiddenFromPmpAndSearch(
      existingProductCollectionPage?.is_hidden_from_pmp_and_search || false
    );
  };

  const productOptions = products.map((product) => ({
    key: product.id || '',
    value: product.id || '',
    text: product.product_name || '',
  }));

  const pmpIdx = useSelector(selectedPrivateMarketplaceIndexSelector);
  const selectedPrivateMarketplace =
    activeUserOrganization?.private_marketplaces?.[pmpIdx];
  const privateMarketplaceDomain =
    selectedPrivateMarketplace?.custom_domain ||
    selectedPrivateMarketplace?.domain ||
    '';

  const error = (() => {
    if (name === '') {
      return t('Required');
    }
    if (
      existingProductCollectionPage &&
      (selectedPrivateMarketplace?.category_pages?.some(
        (category, idx) =>
          category.name === name &&
          (collectionType !== 'CATEGORY' || idx !== index)
      ) ||
        selectedPrivateMarketplace?.feature_pages?.some(
          (feature, idx) =>
            feature.name === name &&
            (collectionType !== 'FEATURE' || idx !== index)
        ))
    ) {
      return t('Path Not Available');
    }
    return '';
  })();

  return (
    <Modal title={headerText} open={open} onClose={onClose}>
      <Modal.Content>
        {copySuccess && <Message success header={t('Copied')} />}
        <FieldWrapper label={t('URL')}>
          <div className={baseStyles['inline-block']}>
            <p
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {`https://${privateMarketplaceDomain}/top/`}
            </p>
            <input
              className={baseStyles['base-form-text']}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
        </FieldWrapper>
        {config.enablePMPCategoryHideSettings && (
          <FieldWrapper label={t('Visibility')}>
            <div
              className={baseStyles['inline-block']}
              style={{ whiteSpace: 'nowrap' }}
            >
              <ToggleButton
                label={t('Show on booking website')}
                onChange={() => {
                  if (!isHidden) {
                    setIsHidden(!isHidden);
                    setIsHiddenFromPmpAndSearch(false);
                  } else {
                    setIsHidden(!isHidden);
                  }
                }}
                checked={!isHidden}
              />
              <div style={{ marginLeft: '10px', width: '100%' }}>
                <a
                  style={{
                    color: '#008ec9',
                    cursor: 'pointer',
                  }}
                  href={`https://${privateMarketplaceDomain}/top/${name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{t('Open booking site')}</p>
                </a>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://${privateMarketplaceDomain}/top/${name}`
                  );
                  setCopySuccess(true);
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                ref={divRef}
              >
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['icon'],
                    baseStyles['narrow']
                  )}
                  data-text={t('Copy')}
                >
                  {showTooltip && (
                    <PortalTooltip
                      text={
                        collectionType === 'CATEGORY'
                          ? t('Copy category page URL to clipboard')
                          : t('Copy featured page URL to clipboard')
                      }
                      style={{
                        left: divBounds.left + 40,
                        top: divBounds.top - 10,
                      }}
                    />
                  )}
                  <img src={copyIcon} />
                </a>
              </div>
            </div>
            {!isHidden && (
              <div style={{ marginTop: '10px' }}>
                <ToggleButton
                  label={
                    <div>
                      <div>
                        {t(
                          'Hide this page from private market place top page and from search results in Google and other search engines.'
                        )}
                      </div>
                      <div>
                        {t(
                          'Page will be accessible when the URL is accessed directly'
                        )}
                      </div>
                    </div>
                  }
                  onChange={() => {
                    setIsHiddenFromPmpAndSearch(!isHiddenFromPmpAndSearch);
                  }}
                  checked={isHiddenFromPmpAndSearch}
                />
              </div>
            )}
          </FieldWrapper>
        )}
        <div className={styles['input-box']}>
          <Input
            label={t('Name')}
            value={displayName}
            onChange={(event, { value }) => setDisplayName(value)}
          />
        </div>
        <div className={styles['input-box']}>
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(event, { value }) => setDescription(value)}
          />
        </div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Key visual (jpg, png)')}>
            <div>
              <SingleImageInput
                acceptedFileTypes={['image/jpeg', 'image/png']}
                onUploadFinished={(filename, url) => setBannerUrl(url)}
                initialValue={bannerUrl}
                getFileSavePath={(filename) =>
                  activeUserOrganization
                    ? `${activeUserOrganization?.id}/${filename}`
                    : ''
                }
              />
            </div>
          </FieldWrapper>
        </div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
            <div>
              <SingleImageInput
                acceptedFileTypes={['image/jpeg', 'image/png']}
                onUploadFinished={(filename, url) => setThumbnailUrl(url)}
                initialValue={thumbnailUrl}
                getFileSavePath={(filename) =>
                  activeUserOrganization
                    ? `${activeUserOrganization?.id}/${filename}`
                    : ''
                }
              />
            </div>
          </FieldWrapper>
        </div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Highlights (drag-and-drop to reorder)')}>
            <div>
              <div className={styles['input-box']}>
                {highlights.length < 3 && (
                  <>
                    <Button
                      style="green"
                      size="middle"
                      onClick={() => setShowCreateHighlightModal(true)}
                    >
                      {t('Add New Highlight')}
                    </Button>

                    <EditProductCollectionHighlightModal
                      onSave={(newHighlight) =>
                        setHighlights([newHighlight, ...highlights])
                      }
                      open={showCreateHighlightModal}
                      onClose={() => setShowCreateHighlightModal(false)}
                    />
                  </>
                )}
              </div>
              <div className={styles['input-box']}>
                {highlights.map((highlight, idx) => (
                  <DraggableProductCollectionHighlight
                    key={highlight.title || idx}
                    value={highlight}
                    index={idx}
                    onValueChange={(newValue) => {
                      const newHighlights = [...highlights];
                      newHighlights[idx] = newValue;
                      setHighlights(newHighlights);
                    }}
                    moveItem={(dragIndex: number, hoverIndex: number) => {
                      const draggingHighlight = highlights[dragIndex];
                      const newHighlights = [...highlights];
                      newHighlights.splice(dragIndex, 1);
                      newHighlights.splice(hoverIndex, 0, draggingHighlight);
                      setHighlights(newHighlights);
                    }}
                    deleteItem={() => {
                      const newHighlights = [...highlights];
                      newHighlights.splice(idx, 1);
                      setHighlights(newHighlights);
                    }}
                  />
                ))}
              </div>
            </div>
          </FieldWrapper>
        </div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Products (drag-and-drop to reorder)')}>
            <div className={styles['products-input-box']}>
              <div className={styles['input-box']}>
                <Button
                  style="green"
                  size="middle"
                  onClick={() => setProductIds(['', ...productIds])}
                >
                  {t('Add New Product')}
                </Button>
              </div>
              <div className={styles['input-box']}>
                {productIds.map((productId, idx) => {
                  return (
                    <DraggableSelect
                      key={productId || idx}
                      value={productId}
                      options={
                        // Options are any product IDs not yet present
                        productOptions.filter(
                          (p) =>
                            p.key === productId || !productIds.includes(p.key)
                        )
                      }
                      index={idx}
                      onValueChange={(newValue) => {
                        const newProductIds = [...productIds];
                        newProductIds[idx] = newValue;
                        setProductIds(newProductIds);
                      }}
                      moveItem={(dragIndex: number, hoverIndex: number) => {
                        const draggingProductId = productIds[dragIndex];
                        const newProductIds = [...productIds];
                        newProductIds.splice(dragIndex, 1);
                        newProductIds.splice(hoverIndex, 0, draggingProductId);
                        setProductIds(newProductIds);
                      }}
                      deleteItem={() => {
                        const newProductIds = [...productIds];
                        newProductIds.splice(idx, 1);
                        setProductIds(newProductIds);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </FieldWrapper>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={reset}>
          {t('Discard')}
        </Button>
        <Button
          style="blue"
          size="middle"
          disabled={Boolean(error)}
          onClick={() => {
            onSave({
              name,
              display_name: displayName,
              description,
              banner_image_url: bannerUrl,
              thumbnail_image_url: thumbnailUrl,
              product_ids: productIds,
              highlights,
              is_hidden: isHidden,
              is_hidden_from_pmp_and_search: isHiddenFromPmpAndSearch,
            });
            onClose();
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
