import * as React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { ToggleButton } from 'client/components/Form';

import styles from './ProductGuestMyPageSettingsForm.module.css';

export const ProductGuestMyPageSettingsForm = () => {
  const { t } = useTranslation();
  const formState = useFormState();
  return (
    <div>
      <Field name="shouldUseCustomSettings">
        {({ input }) => (
          <ToggleButton
            label={t(
              'Use different settings for this product on My Page for Guest'
            )}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>

      {formState.values?.shouldUseCustomSettings && (
        <Box mt={2}>
          <div className={styles['header']}>{t('Allowed operations')}</div>

          <Box mt={2}>
            <Field name="allowUpdatingReservationInfo">
              {({ input }) => (
                <ToggleButton
                  label={t('Update hotel, form answers')}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="allowChangingReservationParameters">
              {({ input }) => (
                <ToggleButton
                  label={t(
                    'Change reservations (Participation date, time, participants, add-on, transportation)'
                  )}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="allowCancellingReservation">
              {({ input }) => (
                <ToggleButton
                  label={t('Cancel')}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              )}
            </Field>
          </Box>
        </Box>
      )}
    </div>
  );
};
