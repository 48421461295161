import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Select } from 'client/components/Form';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const ReservationSearchConditionPresetSelector = ({
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const presetOptions = React.useMemo(() => {
    if (!activeUserOrganization) {
      return [];
    }
    return (
      activeUserOrganization?.account_reservation_search_settings?.presets?.map(
        (preset) => ({
          text: preset.title ?? '',
          value: preset.key ?? '',
        })
      ) ?? []
    );
  }, [activeUserOrganization]);

  return (
    <Select
      placeholder={t('Select your preset search condition')}
      value={value}
      onChange={(_, { value }) => {
        onChange(value);
      }}
      options={presetOptions}
    />
  );
};
