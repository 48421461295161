import { Field, useField } from 'react-final-form';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import baseStyles from 'client/base.module.css';

import styles from './ExpireLimitInput.module.css';

type Props = {
  name: string;
  activateImmediateExpiration?: boolean;
};

export const ExpireLimitInput = ({
  name,
  activateImmediateExpiration,
}: Props) => {
  const field = useField(name);
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles['input-container']}>
        {field.input.value.deadlineType === 'HOUR' && (
          <label
            className={baseStyles['base-form-select']}
            style={{
              width: '80px',
            }}
          >
            <Field
              name={`${name}.hoursLater`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <select {...input}>
                  {_.times(101).map((daysLater) => (
                    <option key={daysLater} value={daysLater}>
                      {daysLater}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </label>
        )}
        {field.input.value.deadlineType === 'DAY' && (
          <label
            className={baseStyles['base-form-select']}
            style={{
              width: '80px',
            }}
          >
            <Field
              name={`${name}.daysLater`}
              parse={(value) =>
                typeof value === 'string' ? parseInt(value) : value
              }
            >
              {({ input }) => (
                <select {...input}>
                  {_.times(366).map((daysLater) => (
                    <option key={daysLater} value={daysLater}>
                      {daysLater}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </label>
        )}
        <label className={baseStyles['base-form-select']}>
          <Field name={`${name}.deadlineType`}>
            {({ input }) => (
              <select
                value={input.value}
                onChange={(e) => {
                  if (e.target.value === 'HOUR') {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'HOUR',
                      hoursLater: field.input.value.hoursLater ?? 24,
                    });
                  } else if (e.target.value === 'DAY') {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: 'DAY',
                      daysLater: field.input.value.daysLater ?? 1,
                      timeOfDay: field.input.value.timeOfDay ?? '17:00',
                    });
                  } else {
                    field.input.onChange({
                      ...field.input.value,
                      deadlineType: e.target.value,
                    });
                  }
                }}
              >
                <option value="ETERNAL">{t('No expiration')}</option>
                <option value="HOUR">{t('hours')}</option>
                <option value="DAY">{t('days after')}</option>
                {activateImmediateExpiration && (
                  <option value="INSTANT_EXPIRATION">{t('Immediately')}</option>
                )}
              </select>
            )}
          </Field>
        </label>
        {field.input.value.deadlineType === 'DAY' && (
          <Field name={`${name}.timeOfDay`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        )}
      </div>
    </div>
  );
};
