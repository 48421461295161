import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { fetchReservationStatsByDate } from 'client/actions/reservations';
import { ProductInstanceEditModal } from 'client/components/ProductInstanceEditModal/ProductInstanceEditModal';
import { ReservationListModal } from 'client/components/ReservationListModal/ReservationListModal';
import type { ReduxState } from 'client/reducers';
import {
  setProductCalendarListCurrentPage,
  setProductCalendarListPageSize,
} from 'client/actions/productCalendarListControls';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { fetchProductAvailability } from 'client/actions/productAvailability';
import type { ProductInstance } from 'shared/models/swagger';

import { AvailabilityCustomTable } from './AvailabilityCustomTable';
import {
  productsWithOccupancySelector,
  tagFilterSetSelector,
  salesStatusFilterSetSelector,
  visibleParentProductIdsSelector,
} from './util';

type CustomTableColumn = {
  accessor: string;
};
type Column = CustomTableColumn;

export const AvailabilityTable = () => {
  const { t } = useTranslation();

  const [editingProductInstance, setEditingProductInstance] =
    React.useState<ProductInstance | null>(null);
  const [reservationListContext, setReservationListContext] = React.useState<{
    productIds: string[];
    rootProductInstanceId: string;
    date: string;
  } | null>(null);
  const [shouldRefreshAvailabilityData, setShouldRefreshAvailabilityData] =
    React.useState(false);
  const [columns, setColumns] = React.useState<Column[]>([]);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const availabilityInvalidated = useSelector(
    (state: ReduxState) => state.productAvailability.invalidated
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const totalPaxByDate = useSelector(
    (state: ReduxState) => state.reservations.totalConfirmedPaxByDate
  );
  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);
  const visibleParentProductIds = useSelector(visibleParentProductIdsSelector);
  const productsWithOccupancy = useSelector(productsWithOccupancySelector);
  const dates = useSelector(productCalendarListDatesSelector);
  const startDate = useSelector(
    (state: ReduxState) => state.productCalendarListControls.startDate
  );
  const endDate = useSelector(
    (state: ReduxState) => state.productCalendarListControls.endDate
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isNutmegAdmin && visibleParentProductIds.length === 0) {
      dispatch(fetchProducts());
    }
  }, [invalidated, isNutmegAdmin, t, visibleParentProductIds.length]);
  React.useEffect(() => {
    if (visibleParentProductIds.length > 0) {
      dispatch(
        fetchProductAvailability(visibleParentProductIds, startDate, endDate)
      );
    }
  }, [startDate, endDate, visibleParentProductIds]);
  React.useEffect(() => {
    // Reset current page if filters change.
    dispatch(setProductCalendarListCurrentPage(0));
  }, [tagFilterSet, salesStatusFilterSet]);
  React.useEffect(() => {
    dispatch(fetchReservationStatsByDate(startDate, endDate));
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (availabilityInvalidated) {
      setShouldRefreshAvailabilityData(true);
    }
  }, [availabilityInvalidated]);

  // Reload availability if 'shouldRefreshAvailabilityData' flag becomes true;
  React.useEffect(() => {
    if (shouldRefreshAvailabilityData && visibleParentProductIds.length > 0) {
      dispatch(
        fetchProductAvailability(visibleParentProductIds, startDate, endDate)
      );
    }

    setShouldRefreshAvailabilityData(false);
  }, [
    startDate,
    endDate,
    visibleParentProductIds,
    shouldRefreshAvailabilityData,
  ]);

  React.useEffect(() => {
    setColumns([
      ...dates.map((date) => {
        return {
          id: date,
          accessor: date,
        };
      }),
    ]);
  }, [dates, dayMonthFormat, locale, setColumns, t, totalPaxByDate]);

  return (
    <>
      {editingProductInstance && (
        <ProductInstanceEditModal
          oldProductInstance={editingProductInstance}
          onClose={() => setEditingProductInstance(null)}
          open={editingProductInstance !== null}
        />
      )}

      <ReservationListModal
        productIds={reservationListContext?.productIds || []}
        rootProductInstanceId={
          reservationListContext?.rootProductInstanceId || ''
        }
        date={reservationListContext?.date || ''}
        onClose={() => setReservationListContext(null)}
        open={reservationListContext !== null}
      />

      <AvailabilityCustomTable
        items={productsWithOccupancy}
        columns={columns}
        usePaging={true}
        setEditingProductInstance={setEditingProductInstance}
        setReservationListContext={setReservationListContext}
        onCurrentPageChange={(page: number) =>
          dispatch(setProductCalendarListCurrentPage(page))
        }
        onPageSizeChange={(size: number) =>
          dispatch(setProductCalendarListPageSize(size))
        }
        totalPaxByDate={totalPaxByDate}
      />
    </>
  );
};
