import { WebSiteIntegrationHeader } from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegrationHeader';
import { WebSiteIntegrationBody } from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegrationBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const WebSiteIntegration = () => {
  return (
    <V3Page>
      <WebSiteIntegrationHeader />
      <WebSiteIntegrationBody />
    </V3Page>
  );
};
