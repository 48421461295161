import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';

import { updateManifestSettings } from 'client/actions/manifestSettings';
import {
  manifestFuseStartTimesSelector,
  manifestShowReservationsOnAllOperatingDaysSelector,
} from 'client/reducers/manifestSettings';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Checkbox, Button } from 'client/components/Form';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  trigger: React.ReactElement<'a'>;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
const ManifestSettingsModalComponent = ({
  fuseStartTimes,
  showReservationsOnAllOperatingDays,
  trigger,
  updateSettings,
}: Props) => {
  const [splitStartTimes, setSplitStartTimes] = React.useState(!fuseStartTimes);
  const [showMultidayReservations, setShowMultidayReservations] =
    React.useState(showReservationsOnAllOperatingDays);
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      title={t('Settings')}
      trigger={trigger}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
    >
      <Modal.Content>
        <Modal.Box>
          <Checkbox
            checked={splitStartTimes}
            onChange={() => setSplitStartTimes(!splitStartTimes)}
            label={t('Split start times')}
          />
        </Modal.Box>
        <Modal.Box>
          <Checkbox
            checked={showMultidayReservations}
            onChange={() =>
              setShowMultidayReservations(!showMultidayReservations)
            }
            label={t(
              'Show multiday reservations on manifests for all operating days'
            )}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button
          style="green"
          size="middle"
          onClick={() => {
            updateSettings(!splitStartTimes, showMultidayReservations);
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  fuseStartTimes: manifestFuseStartTimesSelector(state),
  showReservationsOnAllOperatingDays:
    manifestShowReservationsOnAllOperatingDaysSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSettings: (
    fuseStartTimes: boolean,
    showReservationsOnAllOperatingDays: boolean
  ) =>
    dispatch(
      updateManifestSettings({
        fuse_start_times: {
          value: fuseStartTimes,
        },
        show_reservations_on_all_operating_days: {
          value: showReservationsOnAllOperatingDays,
        },
      })
    ),
});

export const ManifestSettingsModal = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ManifestSettingsModalComponent);
