// @flow

import * as React from 'react';

import { FloatInput } from 'client/components/FloatInput';

type Props = {
  value: number,
  onChange: (number) => void,
};

export const PercentInput = ({ value, onChange }: Props) => {
  return <FloatInput labelPosition="right" value={value} onChange={onChange} />;
};
