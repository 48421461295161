import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { updateDispatchMiscResources } from 'client/actions/dispatchSettings';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Input, TextArea } from 'client/components/Form';
import type { DispatchResource } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  existingResource?: DispatchResource | null;
  open: boolean;
  onClose: () => void;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
const EditResourceModalComponent = ({
  existingResource,
  allResources,
  updateResources,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [resourceKey, setResourceKey] = React.useState<string>('');
  const [resourceCapacity, setResourceCapacity] = React.useState<
    number | typeof undefined
  >(undefined);
  const [resourceDescription, setResourceDescription] =
    React.useState<string>('');
  React.useEffect(() => {
    setResourceKey(existingResource?.key || '');
    setResourceCapacity(existingResource?.capacity || 0);
    setResourceDescription(existingResource?.description || '');
  }, [existingResource]);
  const headerText = existingResource
    ? t('Edit Resource')
    : t('Add New Resource');
  return (
    <Modal title={headerText} open={open} onClose={onClose}>
      <Modal.Content>
        <Modal.Box>
          <Input
            disabled={Boolean(existingResource)}
            label={t('Name')}
            value={resourceKey}
            onChange={(e, { value }) => setResourceKey(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <TextArea
            label={t('Description')}
            value={resourceDescription}
            onChange={(e, { value }) => setResourceDescription(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <div className={baseStyles['base-form-box']}>
            <div className={baseStyles['base-form-box__header']}>
              {t('Capacity')}
            </div>
            <div className={baseStyles['base-form-box__body']}>
              <label className={baseStyles['base-form-select']}>
                <select
                  value={resourceCapacity}
                  onChange={(e) =>
                    setResourceCapacity(parseInt(e.target.value, 10))
                  }
                >
                  {[...Array(100)].map((_, idx) => {
                    return (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            const newResource = {
              ...existingResource,
              key: resourceKey,
              capacity: resourceCapacity,
              description: resourceDescription,
            };
            updateResources([
              ...allResources.filter(
                (resource) => resourceKey !== resource.key
              ),
              newResource,
            ]);
            onClose();
          }}
          disabled={!resourceKey}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  allResources: allDispatchMiscResourcesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateResources: (resources: DispatchResource[]) =>
    dispatch(updateDispatchMiscResources(resources)),
});

export const EditResourceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditResourceModalComponent);
