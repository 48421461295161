import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS,
  SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE,
  SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE,
  SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME,
  SET_IMPERSONATED_USER_ID,
  SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB,
} from 'client/constants/ActionTypes';

// Reducers
const defaultVisibleColumns = [
  'name',
  'customerCategory',
  'tags',
  'sessionCount',
  'pageViewCount',
  'totalTimeSeconds',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};
const selectedPresetName = (state = 'NONE', action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME:
      return action.selectedPreset;

    default:
      return state;
  }
};
const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const pageSize = (state = 10, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const activeTab = (state = 'ALL', action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB:
      return action.activeTab;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  selectedPresetName: string;
  currentPage: number;
  pageSize: number;
  activeTab: 'ALL' | 'BOOKED' | 'NOT_BOOKED';
};
const reducer = combineReducers<State>({
  visibleColumns,
  selectedPresetName,
  currentPage,
  pageSize,
  activeTab,
});
export const webJourneyCustomerListControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
