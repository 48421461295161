import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import * as Swagger from 'shared/models/swagger';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchRestaurants, updateRestaurant } from 'client/actions/restaurants';

import { ActionsFooter } from '../ActionsFooter/ActionsFooter';

import styles from './MenuEditor.module.css';
import {
  convertFormValuesToSwaggerRestaurantPatch,
  getInitialValues,
  FormValues,
} from './formValues';
import { MenuItemEditor } from './MenuItemEditor/MenuItemEditor';

type MenuItem = Exclude<Swagger.RestaurantMenu['menu_items'], undefined>[0];

export const Edit = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={styles['p-availability__select__menu__item']}
    >
      <i className="c-icon-outline-general-edit-05"></i>
      <p>{t('Edit')}</p>
    </div>
  );
};

export const Delete = ({
  item,
  onDelete,
}: {
  item: MenuItem;
  onDelete: () => void;
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const { t } = useTranslation();

  return (
    <div>
      <div
        className={clsx(
          styles['p-availability__select__menu__item'],
          styles['red']
        )}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="c-icon-outline-general-trash-03"></i>
        <p>{t('Delete')}</p>
      </div>
      {showDeleteModal && (
        <Modal
          onClose={() => setShowDeleteModal(false)}
          insertAtRoot
          title={t('Delete Restaurant')}
          open={true}
          rightActionChildren={
            <>
              <Button
                text={t('Cancel')}
                size="md"
                color="white"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              />
              <Button
                text={t('Delete')}
                onClick={async () => {
                  await onDelete();
                  setShowDeleteModal(false);
                }}
              />
            </>
          }
        >
          <div>
            {t('Are you sure you want to delete "{{name}}"?', {
              name: item.name,
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

interface UseColumnsParams {
  onEditClick: (item: MenuItem) => void;
  onDelete: (item: MenuItem) => void;
}

const useColumns = ({
  onEditClick,
  onDelete,
}: UseColumnsParams): ColumnType<MenuItem>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (item) => item.name ?? '',
    },
    {
      Header: '',
      id: 'actions',
      accessor: (item) => (
        <div className={styles['actions']}>
          <Edit onClick={() => onEditClick(item)} />
          <Delete item={item} onDelete={() => onDelete(item)} />
        </div>
      ),
    },
  ];
};

export const MenuEditor: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { restaurantId } = useParams<{
    restaurantId: string;
  }>();
  const [editingMenuItemIdx, setEditingMenuItemIdx] = React.useState<
    number | null
  >(null);
  const org = useSelector(activeUserOrganizationSelector);
  const restaurants = useSelector((state: ReduxState) => state.restaurants.all);
  const currencyCode = org?.default_currency ?? '';

  const existingRestaurant = restaurants.find((r) => r.id === restaurantId);
  const existingMenu = existingRestaurant?.menu;

  const initialValues = React.useMemo(() => {
    return getInitialValues(existingRestaurant || null, restaurantId);
  }, [existingRestaurant, restaurantId]);

  React.useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  const columns = useColumns({
    onEditClick: (item: MenuItem) => {
      setEditingMenuItemIdx(
        existingMenu?.menu_items?.findIndex((i) => i === item) ?? null
      );
    },
    onDelete: (item: MenuItem) => {
      dispatch(
        updateRestaurant(restaurantId, {
          menu: {
            ...existingMenu,
            menu_items: existingMenu?.menu_items?.filter((i) => i !== item),
          },
        })
      );
    },
  });

  return (
    <Form<FormValues>
      initialValues={initialValues}
      onSubmit={async (values) => {
        await dispatch(
          updateRestaurant(
            restaurantId,
            convertFormValuesToSwaggerRestaurantPatch(values, currencyCode)
          )
        );

        if (editingMenuItemIdx != null) {
          setEditingMenuItemIdx(null);
        }
      }}
      mutators={getArrayMutators()}
      debug={console.log}
    >
      {({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          {editingMenuItemIdx != null ? (
            <V3Page>
              <PageHeader
                title={t('Edit Menu Item')}
                description={t('Edit your menu item below')}
              />
              <PageContent>
                <div className={styles.actions}>
                  <Button
                    onClick={() => setEditingMenuItemIdx(null)}
                    text={t('Back to Menu')}
                    iconBeforeText={
                      <i className="c-icon-outline-general-back" />
                    }
                  />
                </div>

                <MenuItemEditor name={`menuItems.${editingMenuItemIdx}`} />
              </PageContent>
              <ActionsFooter loading={submitting} />
            </V3Page>
          ) : (
            <V3Page>
              <PageHeader
                title={t('Edit Menu')}
                description={t('Edit your menu below')}
                breadcrumbPaths={[
                  {
                    text: existingRestaurant?.name ?? '',
                    url: `/mobileorders/restaurants/${restaurantId}`,
                  },
                  { text: t('Edit Menu'), url: '' },
                ]}
              />
              <PageContent>
                <PagedGenericTable
                  hideScrollButtons
                  columns={columns}
                  allItems={existingMenu?.menu_items ?? []}
                  headerBar={
                    <div className={styles.actions}>
                      <div className={styles.restaurantSelector}>
                        <Button
                          onClick={() => {
                            setEditingMenuItemIdx(values.menuItems.length);
                          }}
                          text={t('Add Menu Item')}
                          iconBeforeText={
                            <i className="c-icon-outline-general-plus" />
                          }
                        />
                      </div>
                    </div>
                  }
                />
              </PageContent>

              <ActionsFooter loading={submitting} />
            </V3Page>
          )}
        </form>
      )}
    </Form>
  );
};
