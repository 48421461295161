import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { FieldWrapper, Button } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { fetchProducts } from 'client/actions/products';
import { updateEquipment } from 'client/actions/equipments';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { equipmentsSelector } from 'client/reducers/equipments';
import { getFromStartDateToEndDateText } from 'client/libraries/util/getFromStartDateToEndDateText';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

import {
  getInitialValues,
  convertFormValuesToEquipment,
  FormValues,
  getDefaultStartTimeMapping,
} from './formValues';
import styles from './EquipmentScheduleModal.module.css';
import { StartTimeForm } from './StartTimeForm';

type Props = {
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  equipmentId: string | null;
};

export const EquipmentScheduleModal = ({
  open,
  onOpen,
  onClose,
  equipmentId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const equipments = useSelector(equipmentsSelector);

  const selectedEquipment = equipments.find((e) => e.id === equipmentId);

  const title = t('Edit schedule for {{equipmentTitle}}', {
    equipmentTitle: selectedEquipment?.title,
  });

  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      onOpen={() => {
        onOpen && onOpen();
      }}
      width="wide"
    >
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            await dispatch(
              updateEquipment(
                equipmentId ?? '',
                convertFormValuesToEquipment(values)
              )
            );
            onClose && onClose();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={getInitialValues(selectedEquipment ?? null)}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <FieldArray name="schedules">
                    {({ fields }) => (
                      <FieldWrapper label={t('Resource Availability')}>
                        <div className={styles['page-productsEdit__select']}>
                          <div
                            className={styles['page-productsEdit__select__box']}
                          >
                            <label
                              className={clsx(baseStyles['base-form-select'])}
                            >
                              <select
                                value={activeScheduleIndex}
                                onChange={(e) => {
                                  setActiveScheduleIndex(
                                    parseInt(e.target.value)
                                  );
                                }}
                              >
                                {fields.value.map((schedule, idx) => (
                                  <option value={idx} key={idx}>
                                    {getFromStartDateToEndDateText(
                                      schedule.startDateLocal,
                                      schedule.endDateLocal,
                                      t
                                    )}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <a
                              className={clsx(
                                baseStyles['base-btn'],
                                baseStyles['small'],
                                baseStyles['green']
                              )}
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            >
                              {t('Add Resource Schedule')}
                            </a>
                          </div>
                        </div>

                        <div
                          key={activeScheduleIndex}
                          className={baseStyles['base-modalSelectFrame']}
                        >
                          <div
                            className={baseStyles['base-selectFrame__header']}
                          >
                            <p
                              className={
                                baseStyles['base-selectFrame__header__ttl']
                              }
                            >
                              {t('Selected Resource Availability')}
                            </p>
                            {fields.value.length > 1 && (
                              <a
                                className={
                                  baseStyles['base-selectFrame__header__delete']
                                }
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                              >
                                {t('Remove this resource availability')}
                              </a>
                            )}
                          </div>
                          <div className={baseStyles['base-selectFrame__body']}>
                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Date Range')}
                              </p>
                              <div
                                className={
                                  styles['page-productsRegist__date__range']
                                }
                              >
                                <div className={baseStyles['base-form-range']}>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                  <p>-</p>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <FieldArray
                                name={`schedules.${activeScheduleIndex}.startTimeMappings`}
                              >
                                {({ fields }) => {
                                  if (fields.length === 0) {
                                    return (
                                      <AddIcon
                                        onClick={() =>
                                          (fields as any).insertAt(
                                            0,
                                            getDefaultStartTimeMapping()
                                          )
                                        }
                                      />
                                    );
                                  }
                                  return (
                                    <div
                                      className={clsx(styles['c-table-main'])}
                                    >
                                      <table>
                                        <tbody>
                                          <>
                                            {fields.map((name, index) => (
                                              <tr key={index}>
                                                <td style={{ width: '80%' }}>
                                                  <StartTimeForm
                                                    name={name}
                                                    key={index}
                                                  />
                                                </td>
                                                <td>
                                                  <div
                                                    className={
                                                      baseStyles['base-flex']
                                                    }
                                                  >
                                                    <AddIcon
                                                      onClick={() =>
                                                        (
                                                          fields as any
                                                        ).insertAt(
                                                          index + 1,
                                                          getDefaultStartTimeMapping()
                                                        )
                                                      }
                                                    />
                                                    <DeleteIcon
                                                      onClick={() =>
                                                        fields.remove(index)
                                                      }
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        </tbody>
                                      </table>
                                    </div>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                      </FieldWrapper>
                    )}
                  </FieldArray>
                </Modal.Box>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
              </Modal.Content>

              <Modal.Actions>
                <Button.Cancel
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  {t('Discard')}
                </Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
