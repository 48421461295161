import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { removeEquipmentNotification } from 'client/actions/equipmentNotifications';
import type { EquipmentNotification } from 'client/reducers/equipmentNotifications';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';

import styles from './SeatAssignmentNotificationList.module.css';

type Props = {
  notification: EquipmentNotification;
};
export const SeatAssignmentNotificationListElement = ({
  notification,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Auto-clear notification after 11 seconds
  React.useEffect(() => {
    const deleteNotificationTimer = setTimeout(() => {
      dispatch(removeEquipmentNotification(notification.id));
    }, 10 * 1000);
    return () => {
      clearTimeout(deleteNotificationTimer);
    };
  }, [notification.id]);
  return (
    <div className={clsx(styles['snackbar'], styles['fade-out'])}>
      <Box
        display="flex"
        className={styles['snackbar-header']}
        alignItems="center"
      >
        <img width={18} height={18} src={checkFinishedIcon} />
        <Box ml={1}>{t('Seat assignment is updated')}</Box>
        <a
          className={clsx(styles['snackbar-close'])}
          onClick={() => {
            dispatch(removeEquipmentNotification(notification.id));
          }}
        />
      </Box>
      <Box mt={1}>
        <div className={styles['snackbar-ellipsis']}>
          {notification.payload?.['message']}
        </div>
      </Box>
    </div>
  );
};
