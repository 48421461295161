import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import styles from 'client/base.module.css';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  value: string;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  minLength?: number;
  maxLength?: number;
  type?: string;
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  showCharacterCount?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  tooltipText?: string;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      error,
      label,
      width,
      height,
      maxWidth,
      maxHeight,
      showCharacterCount,
      onChange,
      value,
      required,
      placeholder,
      tooltipText,
      ...inputProps
    }: Props,
    ref
  ) => {
    const { t } = useTranslation();
    const style = {
      width,
      height,
      maxWidth,
      maxHeight,
    };
    return (
      <div className={styles['base-form-box']}>
        {label && (
          <div
            className={clsx(
              styles['base-form-box__header'],
              required ? styles['required'] : '',
              error ? styles['error'] : ''
            )}
          >
            {label}

            {tooltipText && <Tooltip text={tooltipText} />}
          </div>
        )}
        <div className={styles['base-form-box__body']}>
          <textarea
            ref={ref}
            className={styles['base-form-textarea']}
            value={value}
            onChange={(e) =>
              onChange(e, {
                value: e.target.value,
              })
            }
            style={style}
            placeholder={placeholder}
            {...inputProps}
          />
          {showCharacterCount && (
            <span className={styles['base-txtCnt']}>
              {t('{{count}} characters', {
                count: value?.length || 0,
              })}
            </span>
          )}{' '}
          {error && <p className={styles['base-form-box__err']}>{error}</p>}
        </div>
      </div>
    );
  }
);
