import { forwardRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Linkify from 'react-linkify';
import clsx from 'clsx';

import { Account, Product, Reservation } from 'shared/models/swagger';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { ReservationFieldResponseUpdateModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationFieldResponseUpdateModal';
import { FileDownloadList } from 'client/pages/ReservationDetails/FileDownloadList';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { ReduxState } from 'client/reducers';
import { sendPaymentEmail } from 'client/actions/reservations';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  product: Product;
  activeUser: Account | null;
  readOnly?: boolean;
};

export const RelatedInformation = forwardRef<any, Props>((props: Props) => {
  const { reservation, product, activeUser, readOnly } = props;
  const { partnershipMode } = useContext(PartnershipModeContext);

  const [showModal, setShowModal] = useState(false);

  if (!reservation || !product) {
    return null;
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const keyTitles: { [key: string]: string } = {};
  product.reservation_form_fields?.forEach((field) => {
    if (field.key) {
      keyTitles[field.key] = field.prompt;
    }
  });

  const excludedFormFieldKeys = [
    'family_name',
    'given_name',
    'kana_family_name',
    'kana_given_name',
    'full_name',
    'email',
    'preferred_language_iso2',
    'booking_language',
    'hotel_information',
    'representative_name',
    'hotel_tbd_form',
    'consent_form',
    'customer_id',
  ];

  const builtinFormFieldsByKey = getCommonFormFieldsByKey(t);
  const productReservationFormFields = [
    ...(product.reservation_form_fields || [])
      .filter((field) => {
        return (
          !excludedFormFieldKeys.includes(field.key ?? '') &&
          field.type === 'PER_BOOKING'
        );
      })
      .map((field) => ({
        key: field.key,
        prompt: field.prompt,
        format: field.format,
      })),
  ];
  const unmappedFormFieldKeys = [
    ...new Set(
      reservation?.field_responses
        ?.map((fieldResponse) => fieldResponse.key)
        .filter(
          (key) =>
            !productReservationFormFields.some(
              (formField) => formField.key === key
            )
        )
    ),
  ];
  const unregisteredBuiltinFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key ?? '')
    )
    .map((key) => ({
      key,
      prompt: builtinFormFieldsByKey[key ?? ''].text,
    }));
  const unregisteredUnknownFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        !builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key ?? '')
    )
    .map((key) => ({
      key,
      prompt: key,
    }));
  const formFields = [
    ...productReservationFormFields,
    ...unregisteredBuiltinFormFields,
    ...unregisteredUnknownFormFields,
  ];

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughOrg =
    (reservation.agent_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'AGENT') ||
    (reservation.supplier_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'SUPPLIER');

  const userCanEditFormFieldResponses =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  const numOfFieldResponse = formFields.length;

  const paymentDeferredAvailable =
    reservation.payment_deferred &&
    reservation.billing_info &&
    (reservation.billing_info.payment_deferred_available ||
      reservation.billing_info.invoice_status === 'INVOICE_IN_PROGRESS' ||
      reservation.billing_info.invoice_status === 'INVOICE_CHARGE_PENDING');

  const userEmail =
    reservation?.field_responses?.find((response) => response.key === 'email')
      ?.response || '';
  const userLanguage =
    reservation?.field_responses?.find(
      (response) => response.key === 'preferred_language_iso2'
    )?.response || '';
  const consentForm =
    reservation?.field_responses?.find(
      (response) => response.key === 'consent_form'
    )?.response || '';
  const consentFormPrompt =
    product.reservation_form_fields?.find(
      (field) => field.key === 'consent_form'
    )?.prompt || '';

  const sending = useSelector(
    (state: ReduxState) => state.reservations.paymentEmailSending
  );

  const [sendingStatus, setSendingStatus] = useState<
    'REQUIRED' | 'SUCCEEDED' | 'FAILED'
  >('REQUIRED');

  const componentDecorator = (href: string, text: string, key: number) => (
    <a
      key={key}
      href={href}
      style={{ color: 'blue' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  return (
    <section
      id="related"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <TutorialPopup
        name="related"
        content={
          <TutorialPopupContent>
            <TutorialPopupHeader text={t('Related Information')} />
            <TutorialPopupDescription
              text={t(
                'The information set in "Per-Booking" on the applicant\'s reservation form will be displayed. Please check here for contact information and stay information.'
              )}
            />
          </TutorialPopupContent>
        }
      />

      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Related Information')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {userCanEditFormFieldResponses && (
              <Button
                text={t('Edit')}
                uiType="bg"
                size="sm"
                color="white"
                iconBeforeText={
                  <i className="c-icon-outline-general-edit-05"></i>
                }
                onClick={() => setShowModal(true)}
              />
            )}
          </div>
        </div>
        {showModal && (
          <ReservationFieldResponseUpdateModal
            reservationID={reservation.id}
            onClose={() => setShowModal(false)}
            openFrom="related-info"
          />
        )}

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-relations']}>
            <table
              className={tableStyles['c-tableSmall']}
              style={{ overflow: 'hidden' }}
            >
              <tbody>
                {formFields.map((field, idx) => {
                  const response = reservation.field_responses?.find(
                    (response) => response.key === field.key
                  );
                  return (
                    <tr key={idx}>
                      <th className={baseStyles['u-width-176']}>
                        {field.prompt}
                      </th>
                      <td>
                        {field.key === 'phone' ||
                        field.key === 'international_phone' ? (
                          formattedPhone(response?.response ?? '')
                        ) : 'format' in field &&
                          field.format === 'file-upload' ? (
                          <FileDownloadList
                            fileUrlString={response?.response ?? ''}
                          />
                        ) : (
                          <TruncatedTextWithSeeMoreButton
                            text={response?.response ?? ''}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
                {userEmail && (
                  <tr>
                    <th className={baseStyles['u-width-176']}>{t('Email')}</th>
                    <td>{userEmail}</td>
                  </tr>
                )}
                {userLanguage && (
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Language')}
                    </th>
                    <td>{userLanguage}</td>
                  </tr>
                )}
                {reservation.promo_code && (
                  <tr key={numOfFieldResponse}>
                    <th className={baseStyles['u-width-176']}>
                      {t('Promo Code')}
                    </th>
                    <td>{reservation.promo_code}</td>
                  </tr>
                )}
                {consentForm && (
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      <Linkify componentDecorator={componentDecorator}>
                        {consentFormPrompt}
                      </Linkify>
                    </th>
                    <td>{t(consentForm)}</td>
                  </tr>
                )}
                {operationAllowed(
                  activeUser,
                  'write',
                  'reservationPaymentMethod'
                ) &&
                  reservation.payment_deferred && (
                    <tr key={numOfFieldResponse + 1}>
                      <th className={baseStyles['u-width-176']}>
                        <a
                          className={styles['p-relations__cardlink']}
                          href={reservation.payment_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            text={t('Payment link')}
                            uiType="bg"
                            size="md"
                            color="white"
                            iconAfterText={
                              <i className="c-icon-outline-general-link-external-02"></i>
                            }
                          />
                        </a>
                      </th>
                      <td>
                        <div
                          className={styles['reservation-detail-email-payment']}
                        >
                          {reservation.email_payment_to_address}
                          <div>
                            <Button
                              text={t('Resend payment link again via Email')}
                              color="primary"
                              size="md"
                              disabled={
                                !(
                                  reservation.status === 'CONFIRMED' &&
                                  reservation.email_payment_to_address &&
                                  paymentDeferredAvailable
                                )
                              }
                              onClick={async () => {
                                if (
                                  reservation.id &&
                                  reservation.email_payment_to_address
                                ) {
                                  try {
                                    setSendingStatus('REQUIRED');
                                    await dispatch(
                                      sendPaymentEmail(
                                        reservation.id,
                                        reservation.email_payment_to_address ||
                                          ''
                                      )
                                    );
                                    setSendingStatus('SUCCEEDED');
                                  } catch (e) {
                                    setSendingStatus('FAILED');
                                  }
                                }
                              }}
                              loading={sending}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>

            <div>
              {sendingStatus == 'SUCCEEDED' && (
                <Snackbar
                  text={t('Send Successful')}
                  color="success"
                  shouldShow={sendingStatus == 'SUCCEEDED'}
                />
              )}
              {sendingStatus == 'FAILED' && (
                <Snackbar
                  text={t('Send Failed')}
                  color="error"
                  shouldShow={sendingStatus == 'FAILED'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
