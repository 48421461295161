import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { useTicketNetworkPrinterIpAddress } from 'client/hooks/useTicketNetworkPrinterIpAddress';
import { Message } from 'client/components/Message/Message';

export const SeatManagementSettings = () => {
  const { t } = useTranslation();
  const [testResult, setTestResult] = React.useState<
    'SUCCESS' | 'FAILURE' | ''
  >('');
  const [response, setResponse] = React.useState<string | null>(null);

  const {
    networkPrinterIpAddress,
    setNetworkPrinterIpAddress: handleChange,
    saveNetworkPrinterIpAddress: handleSubmit,
  } = useTicketNetworkPrinterIpAddress();

  const fetchWithTimeout = async (url: string, options: any) => {
    const { timeout = 8000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  };

  const handleTest = async () => {
    const xml = `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>
<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
<text>Printer test succeeded&#10;</text>
<cut type="feed"/>
</epos-print>
      </s:Body></s:Envelope>`;

    try {
      const response = await fetchWithTimeout(
        `https://${networkPrinterIpAddress}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=60000`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/xml', // リクエストのコンテンツタイプをXMLに設定
          },
          body: xml, // XMLデータをリクエストのボディに設定
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.text(); // レスポンスをテキストとして取得
      setTestResult('SUCCESS');
      setResponse(data);
    } catch (error: any) {
      setTestResult('FAILURE');
      setResponse(error.message);
    }
  };

  return (
    <Box>
      <Box width="300px">
        <Input
          label={t('Network Printer IP Address')}
          placeholder="10.0.0.1"
          value={networkPrinterIpAddress}
          onChange={(_, { value }) => {
            handleChange(value);
          }}
        />
      </Box>
      <Box mt={3} display="flex">
        <Box mr={2}>
          <Button
            size="middle"
            style="blue"
            type="button"
            onClick={handleSubmit}
          >
            {t('Save')}
          </Button>
        </Box>
        <Button size="middle" style="green" type="button" onClick={handleTest}>
          {t('Test')}
        </Button>
      </Box>
      {testResult === 'SUCCESS' && <Message success header={t('Success')} />}
      {testResult === 'FAILURE' && (
        <>
          {' '}
          <Message error header={t('Failure')} />
          <pre>{response}</pre>
        </>
      )}
    </Box>
  );
};
