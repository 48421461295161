import { v4 as uuidv4 } from 'uuid';

import type { Equipment } from 'shared/models/swagger';

export interface FormValues {
  schedules: Schedule[];
}

interface Schedule {
  key?: string;
  startDateLocal: string;
  endDateLocal: string;
  startTimeMappings: StartTimeMapping[];
}

interface StartTimeMapping {
  key?: string;
  startTimeLocal: string;
  productStartTimes: ProductStartTime[];
}

interface ProductStartTime {
  productId: string;
  recurrenceKey: string;
  timeSlotKey: string;
}

export const getDefaultStartTimeMapping = (): StartTimeMapping => ({
  key: uuidv4(),
  startTimeLocal: '00:00',
  productStartTimes: [],
});

export const getInitialValues = (equipment: Equipment | null): FormValues => {
  return {
    schedules: (equipment?.equipment_schedules ?? []).map((schedule) => ({
      startDateLocal: schedule.start_date_local ?? '',
      endDateLocal: schedule.end_date_local ?? '',
      startTimeMappings: (schedule?.start_time_mappings ?? []).map(
        (startTimeMapping) => ({
          key: startTimeMapping.key,
          startTimeLocal: startTimeMapping.start_time_local ?? '',
          productStartTimes: (startTimeMapping?.product_start_times ?? []).map(
            (productStartTime) => ({
              productId: productStartTime.product_id ?? '',
              recurrenceKey: productStartTime.recurrence_key ?? '',
              timeSlotKey: productStartTime.time_slot_key ?? '',
            })
          ),
        })
      ),
    })),
  };
};

export const convertFormValuesToEquipment = (values: FormValues): Equipment => {
  return {
    equipment_schedules: values.schedules.map((schedule) => ({
      start_date_local: schedule.startDateLocal,
      end_date_local: schedule.endDateLocal,
      start_time_mappings: schedule.startTimeMappings.map(
        (startTimeMapping) => ({
          key: startTimeMapping.key ?? uuidv4(),
          start_time_local: startTimeMapping.startTimeLocal,
          product_start_times: startTimeMapping.productStartTimes.map(
            (productStartTime) => ({
              product_id: productStartTime.productId,
              recurrence_key: productStartTime.recurrenceKey,
              time_slot_key: productStartTime.timeSlotKey,
            })
          ),
        })
      ),
    })),
  };
};
