// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import type { TranslatorProps } from 'react-i18next';
import clsx from 'clsx';

import {
  countFilteredFields,
  getGuestTypesUsedInProductInstance,
  getDisplayReservationFormFields,
} from 'client/libraries/util/util';
import { activeUserSelector } from 'client/reducers/user';
import { updateReservation } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { FieldsFormV2 as FieldsForm } from 'client/components/FieldsFormV2';
import { getFieldResponseErrors } from 'client/libraries/util/coreutil';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Modal } from 'client/components/Modal/Modal';
import { TextArea, Button } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import type {
  Guest,
  Reservation,
  ReservationPatch,
} from 'shared/models/swagger';

import baseStyles from '../../base.module.css';

type OwnProps = {
  onUpdate?: (reservation: ReservationPatch) => void,
  reservationID: string,
  size?: string,
  trigger?: React.Element<'a'>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  newPerBookingResponses: {| key?: string, response?: string |}[],
  newGuests: Guest[],
  lastSubmissionValidationErrorMap: { [key: string]: string },
  agentNotes: string,
  supplierNotes: string,
};

class EditFieldResponseButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      newPerBookingResponses: [...(props.reservation.field_responses || [])],
      newGuests: [...props.reservation.guests],
      lastSubmissionValidationErrorMap: {},
      agentNotes: this.props.reservation.agent_notes || '',
      supplierNotes: this.props.reservation.supplier_notes || '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.reservation !== this.props.reservation) {
      this.setState({
        newPerBookingResponses: [
          ...(this.props.reservation.field_responses || []),
        ],
        newGuests: [...this.props.reservation.guests],
        supplierNotes: this.props.reservation.supplier_notes || '',
      });
    }
  }

  reservationFromState = (): Reservation => {
    return ({
      product: this.props.product,
      guests: this.state.newGuests,
      field_responses: this.state.newPerBookingResponses,
    }: any);
  };

  fetchProductAndProductInstance = () => {
    const { reservation, product, productInstance } = this.props;
    const productID = reservation && reservation.product_id;
    const productInstanceID = reservation && reservation.product_instance_id;

    if (productID && (!product || product.id !== productID)) {
      this.props.fetchProductByID(productID);
    }
    if (
      productInstanceID &&
      (!productInstance || productInstance.id !== productInstanceID)
    ) {
      this.props.fetchProductInstanceByID(productInstanceID);
    }

    this.setState({
      showModal: true,
    });
  };

  getFormInputErrors = () => {
    const { activeUser, product, t } = this.props;
    const { newPerBookingResponses, newGuests } = this.state;

    // Skip field validation for suppliers
    if (activeUser?.organization_type === 'SUPPLIER') {
      return {};
    }

    let errorMap = {};

    const perBookingFields = getDisplayReservationFormFields(
      product?.reservation_form_fields,
      t
    ).filter((f) => f.type === 'PER_BOOKING');
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newPerBookingResponses || [],
        perBookingFields,
        t
      ),
    };

    const perParticipantFields = getDisplayReservationFormFields(
      product?.reservation_form_fields,
      t
    ).filter((f) => f.type === 'PER_PARTICIPANT');
    (newGuests || []).forEach((g) => {
      errorMap = {
        ...errorMap,
        ...getFieldResponseErrors(
          g.field_responses || [],
          perParticipantFields,
          t
        ),
      };
    });

    return errorMap;
  };

  getNotesInput = () => {
    const { activeUser, t } = this.props;
    if (activeUser.organization_type === 'AGENT') {
      return (
        <TextArea
          label={t('Remarks')}
          value={this.state.agentNotes}
          onChange={(e) => {
            this.setState({ agentNotes: e.target.value });
          }}
        />
      );
    } else if (activeUser.organization_type === 'SUPPLIER') {
      return (
        <TextArea
          label={t('Replies')}
          value={this.state.supplierNotes}
          onChange={(e) => {
            this.setState({ supplierNotes: e.target.value });
          }}
        />
      );
    }
  };

  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  render() {
    const {
      loading,
      onUpdate,
      product,
      productInstance,
      reservation,
      t,
      trigger,
    } = this.props;
    const {
      newGuests,
      newPerBookingResponses,
      lastSubmissionValidationErrorMap,
      agentNotes,
      supplierNotes,
    } = this.state;

    const currentValidationErrorMap = this.getFormInputErrors();
    const errorMap = {};

    // Set keys for all fields that were invalid in the last submission.
    Object.keys(lastSubmissionValidationErrorMap).forEach((errKey) => {
      errorMap[errKey] = currentValidationErrorMap[errKey];
    });

    const errors = Object.values(lastSubmissionValidationErrorMap);

    const perParticipantFields = getDisplayReservationFormFields(
      product?.reservation_form_fields,
      t
    ).filter((f) => f.type === 'PER_PARTICIPANT');

    const perBookingFields = getDisplayReservationFormFields(
      product?.reservation_form_fields,
      t
    ).filter((f) => f.type === 'PER_BOOKING');

    const allGuestTypes = getGuestTypesUsedInProductInstance(
      productInstance,
      product,
      t
    );

    return (
      <Modal
        title={t('Update Field Response')}
        trigger={trigger}
        open={this.state.showModal}
        onClose={() => {
          this.setState({
            showModal: false,
          });
        }}
        onOpen={this.fetchProductAndProductInstance}
      >
        <Modal.Content>
          {loading || !product || !productInstance ? (
            <ModalLoader />
          ) : (
            <>
              {countFilteredFields(
                this.reservationFromState(),
                product,
                (f) => f.type === 'PER_BOOKING'
              ) > 0 && (
                <FieldsForm
                  fields={perBookingFields}
                  errorMap={errorMap}
                  getFieldValue={(key) => {
                    const r = newPerBookingResponses.find((r) => r.key === key);

                    return (r && r.response) || '';
                  }}
                  onFieldChange={(key, value) =>
                    this.setState((prevState) => ({
                      newPerBookingResponses: [
                        ...prevState.newPerBookingResponses.filter(
                          (r) => r.key !== key
                        ),
                        {
                          key,
                          response: value,
                        },
                      ],
                    }))
                  }
                  mode="INPUT"
                  type="EDIT"
                />
              )}
              {countFilteredFields(
                this.reservationFromState(),
                product,
                (f) => f.type === 'PER_PARTICIPANT'
              ) > 0 &&
                newGuests.map((g, idx) => {
                  // Initialize guest error map if entire form is showing 1 or more errors.
                  const guestErrorMap =
                    errors.length > 0
                      ? getFieldResponseErrors(
                          g.field_responses || [],
                          perParticipantFields,
                          t
                        )
                      : {};

                  let guestTypeTitle = g.guest_type_title;
                  if (!guestTypeTitle) {
                    const matchingGuestType = allGuestTypes.find(
                      (guestType) => guestType.key === g.guest_type_key
                    );
                    if (matchingGuestType) {
                      guestTypeTitle = matchingGuestType.title;
                    } else {
                      guestTypeTitle = g.guest_type_key;
                    }
                  }

                  return (
                    <div className={clsx(baseStyles['segment'])} key={idx}>
                      <h3>{guestTypeTitle}</h3>
                      <FieldsForm
                        errorMap={guestErrorMap}
                        fields={perParticipantFields}
                        getFieldValue={(key) => {
                          const r = (g.field_responses || []).find(
                            (r) => r.key === key
                          );
                          if (!r) {
                            return '';
                          }

                          return r.response || '';
                        }}
                        onFieldChange={(key, value) =>
                          this.setState((prevState) => ({
                            newGuests: prevState.newGuests.map((g2, idx2) =>
                              idx === idx2
                                ? {
                                    ...g2,
                                    field_responses: [
                                      ...(g2.field_responses || []).filter(
                                        (r) => r.key !== key
                                      ),
                                      {
                                        key,
                                        response: value,
                                      },
                                    ],
                                  }
                                : g2
                            ),
                          }))
                        }
                        mode="INPUT"
                      />
                    </div>
                  );
                })}
            </>
          )}

          <hr />

          <div className={clsx(baseStyles['base-text-divider'])} />

          <ReservationActorInputForm />
          <br />

          {this.getNotesInput()}
        </Modal.Content>

        <Modal.Actions>
          <Button.Cancel
            onClick={() => {
              this.setState({
                newPerBookingResponses: [
                  ...(reservation.field_responses || []),
                ],
                newGuests: [...reservation.guests],
              });
            }}
          >
            {t('Clear Changes')}
          </Button.Cancel>
          <Button.Submit
            primary
            onClick={() => {
              if (Object.keys(currentValidationErrorMap).length > 0) {
                this.setState({
                  lastSubmissionValidationErrorMap: currentValidationErrorMap,
                });
              } else {
                this.setState(() => {
                  // Extranet creates full name from given name and family name automatically
                  // We need to update the created full name when the given name or family name is updated
                  if (
                    !perParticipantFields
                      .map((f) => f.key)
                      .includes('full_name')
                  ) {
                    if (
                      newGuests.length > 0 &&
                      newGuests[0].field_responses
                        ?.map((f) => f.key)
                        .includes('full_name')
                    ) {
                      const givenName =
                        newPerBookingResponses.find(
                          (f) => f.key === 'given_name'
                        )?.response || '';
                      const familyName =
                        newPerBookingResponses.find(
                          (f) => f.key === 'family_name'
                        )?.response || '';
                      const fullName = [givenName, familyName].join(' ');
                      newGuests[0].field_responses = [
                        ...(newGuests[0].field_responses || []).filter(
                          (f) => f.key !== 'full_name'
                        ),
                        {
                          key: 'full_name',
                          response: fullName,
                        },
                      ];
                    }
                  }

                  // Why do we need to put this inside the callback?
                  // Calling setState() and updateReservation() consecutively
                  // prevented the setState() from happening.
                  this.props
                    .updateReservation(reservation.id, {
                      field_responses: [...newPerBookingResponses],
                      guests: [...(newGuests: any)],
                      agent_notes: agentNotes,
                      supplier_notes: supplierNotes,
                    })
                    .then((res) => onUpdate && onUpdate(res));

                  return {
                    showModal: false,
                  };
                });
              }
            }}
          >
            {t('Save')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];

  return {
    loading: state.products.loading || state.productInstances.loading,
    reservation,
    product: state.products.byID[reservation?.product_id || ''],
    productInstance:
      state.productInstances.byID[
        reservation && reservation.product_instance_id
      ],
    activeUser: activeUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateReservation: (id, r) => dispatch(updateReservation(id, r)),
  fetchProductByID: (id) => dispatch(fetchProductByID(id)),
  fetchProductInstanceByID: (id) => dispatch(fetchProductInstanceByID(id)),
});

export const ReservationFieldResponseUpdateModal = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(EditFieldResponseButtonComponent);
