import type { TranslateFuncType } from 'client/components/Translate';

export const getFromStartDateToEndDateText = (
  startDateLocal: string,
  endDateLocal: string,
  t: TranslateFuncType
) => {
  if (startDateLocal && endDateLocal) {
    return `${startDateLocal} ~ ${endDateLocal}`;
  }
  if (startDateLocal) {
    return `${startDateLocal} ~ ${t('Unknown')}`;
  }
  if (endDateLocal) {
    return `${t('Unknown')} ~ ${endDateLocal}`;
  }
  return '~';
};
