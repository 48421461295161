import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { InquirySettings$Patch } from 'shared/models/swagger';

export const updateInquirySettings =
  (patch: InquirySettings$Patch) =>
  (dispatch: ThunkDispatch<any, any, any>) => {
    return dispatch(
      updateActiveUserOrganization({
        inquiry_settings: patch,
      })
    );
  };
