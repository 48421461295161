/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { LanguageSelect } from 'client/components/LanguageSelect/LanguageSelect';
import { ReduxState } from 'client/reducers';

import { USATerms } from '../USATerms/USATerms';
import { USAPrivacyPolicy } from '../USAPrivacyPolicy/USAPrivacyPolicy';

import styles from './SignUp.module.css';

async function emailInUse(email: string): Promise<boolean> {
  try {
    await axios.get(
      '/noauthapi/accounts/' + encodeURIComponent(email) + '/isavailable',
      {
        headers: {
          'Accept-Language': 'en-US',
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    return true;
  }

  return false;
}

async function subdomainInUse(subdomain: string): Promise<boolean> {
  try {
    await axios.get(
      '/noauthapi/subdomains/' + encodeURIComponent(subdomain) + '/isavailable',
      {
        headers: {
          'Accept-Language': 'en-US',
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (e) {
    return true;
  }

  return false;
}

interface FormValues {
  email: string;
  subdomain: string;
  companyName: string;
  agreedToTerms: boolean;
}

export const SignUp = () => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [resentVerificationEmail, setResentVerificationEmail] =
    React.useState(false);
  const [step, setStep] = React.useState<'FILL' | 'CONFIRM' | 'COMPLETE'>(
    'FILL'
  );

  const validateEmailAddress = React.useCallback(async (email?: any) => {
    if (!email) return t('Required');

    if (!matchesFormat(email, 'email')) return t('Invalid Email');

    return undefined;
  }, []);

  return (
    <Form
      initialValues={{
        email: '',
        subdomain: '',
        companyName: '',
        agreedToTerms: false,
      }}
      onSubmit={async (values: FormValues) => {
        if (step === 'FILL') {
          const responses = await Promise.all([
            emailInUse(values.email),
            subdomainInUse(values.subdomain),
          ]);

          if (responses[0] || responses[1]) {
            return {
              email: responses[0] ? t('Email already in use') : undefined,
              subdomain: responses[1]
                ? t('Subdomain already in use')
                : undefined,
            };
          }

          setStep('CONFIRM');
          console.log(values);
        }
        if (step === 'CONFIRM') {
          try {
            await axios.post(
              '/noauthapi/signup',
              {
                email: values.email,
                subdomain: values.subdomain,
                company_name: values.companyName,
              },
              {
                headers: {
                  'Accept-Language': locale,
                  'Content-Type': 'application/json',
                  origin: 'ntmg.com',
                },
              }
            );
          } catch (e) {
            console.error(e);
          }

          setStep('COMPLETE');
        }
      }}
    >
      {({ handleSubmit, values, submitting }) => (
        <form className={styles['form-container']} onSubmit={handleSubmit}>
          <div className={styles['form-content']}>
            <LanguageSelect />

            {step === 'FILL' && (
              <>
                <h1>{t('Sign up')}</h1>
                <Field name="email" validate={validateEmailAddress}>
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      value={input.value}
                      onChange={input.onChange}
                      label={t('Email')}
                      error={
                        touched && (error || submitError)
                          ? error || submitError
                          : undefined
                      }
                    />
                  )}
                </Field>
                <Field name="companyName" validate={required}>
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      value={input.value}
                      onChange={input.onChange}
                      label={t('Website Name (Company Name)')}
                      error={
                        touched && (error || submitError)
                          ? error || submitError
                          : undefined
                      }
                    />
                  )}
                </Field>
                <div className={styles['subdomain-input']}>
                  <Field name="subdomain" validate={required}>
                    {({ input, meta: { touched, error, submitError } }) => (
                      <TextField
                        value={input.value}
                        onChange={input.onChange}
                        label={t('Website URL')}
                        error={
                          touched && (error || submitError)
                            ? error || submitError
                            : undefined
                        }
                      />
                    )}
                  </Field>
                  <span>.book.ntmg.com</span>
                </div>
                <div className={styles['actions']}>
                  <div>
                    After agreeing to our{' '}
                    <a
                      className={styles['link']}
                      href="https://ntmg.com/terms.html"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      className={styles['link']}
                      href="https://ntmg.com/privacy.html"
                    >
                      Privacy Policy
                    </a>
                  </div>
                  <Button
                    loading={submitting}
                    type="submit"
                    text={t('Launch Booking Website')}
                  />
                </div>
              </>
            )}
            {step === 'CONFIRM' && (
              <>
                <h1>{t('Confirm Registration')}</h1>
                <div className={styles['confirm']}>
                  <div className={styles['confirm-item-title']}>
                    {t('Email')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {values.email}
                  </div>
                  <hr />
                  <div className={styles['confirm-item-title']}>
                    {t('Website Name (Company Name)')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {values.companyName}
                  </div>
                  <hr />
                  <div className={styles['confirm-item-title']}>
                    {t('Website URL')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {`${values.subdomain}.book.ntmg.com`}
                  </div>
                </div>

                <USATerms />

                <USAPrivacyPolicy />

                <Field name="agreedToTerms" type="checkbox">
                  {({ input }) => (
                    <Checkbox
                      label={t(
                        'I agree to the Terms of Use and Privacy Policy'
                      )}
                      checked={input.checked}
                      onChange={() => input.onChange(!input.value)}
                    />
                  )}
                </Field>

                <div className={styles['actions']}>
                  <div className={styles['buttons']}>
                    <Button
                      color="white"
                      onClick={() => setStep('FILL')}
                      text={t('Edit Info')}
                    />
                    <Button
                      loading={submitting}
                      disabled={!values.agreedToTerms}
                      type="submit"
                      text={t('Launch Booking Website')}
                    />
                  </div>
                </div>
              </>
            )}
            {step === 'COMPLETE' && (
              <>
                <h1>{t('Sign Up Complete')}</h1>
                <div className={styles['confirm']}>
                  <div className={styles['confirm-item-title']}>
                    {t('Email')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {values.email}
                  </div>
                  <hr />
                  <div className={styles['confirm-item-title']}>
                    {t('Website Name (Company Name)')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {values.companyName}
                  </div>
                  <hr />
                  <div className={styles['confirm-item-title']}>
                    {t('Website URL')}
                  </div>
                  <div className={styles['confirm-item-value']}>
                    {`${values.subdomain}.book.ntmg.com`}
                  </div>

                  <hr />

                  <div className={styles['p-regist__flow']}>
                    <div className={styles['p-contact__body__form__ttl']}>
                      How to verify your email
                    </div>
                    <ul>
                      <li>
                        We sent an email to you:
                        <br />
                        <br />
                        Subject: "Nutmeg: Email verification to finish creating
                        your account"
                        <br />
                        From: "security@nutmeglabs.com"
                        <br />
                      </li>
                      <li>
                        Please click the verification link in the email and set
                        a new password.
                      </li>
                    </ul>
                    <p>
                      * Check that the verification email did not land in your
                      spam folder.
                    </p>
                  </div>
                  <div className={styles['p-regist__send']}>
                    <p className={styles['p-regist__note']}>
                      If the message is not in the spam folder and you have not
                      received it, please resend the verification email by
                      clicking the following link:
                    </p>

                    <a
                      onClick={() => {
                        axios.post(
                          '/noauthapi/signup/resendemail',
                          {
                            email: values.email,
                          },
                          {
                            headers: {
                              'Accept-Language': locale,
                              'Content-Type': 'application/json',
                              origin: 'ntmg.com',
                            },
                          }
                        );

                        setResentVerificationEmail(true);
                      }}
                    >
                      Resend verification email
                    </a>

                    {resentVerificationEmail && (
                      <div className={styles['p-regist__flash']}>
                        <p>
                          <span></span>Verification email sent
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      )}
    </Form>
  );
};
