import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  SET_IMPERSONATED_USER_ID,
  SET_PRODUCT_FILTERED,
  SET_PRODUCT_PAGE,
  SET_PRODUCT_PAGE_SIZE,
  SET_PRODUCT_SORTED,
  SET_PRODUCT_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import {
  activeUserSelector,
  activeUserAllOrganizationsSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';

// Selectors
export const productColumnCandidatesSelector = createSelector(
  activeUserSelector,
  activeUserAllOrganizationsSelector,
  (activeUser, organizations) => {
    const hasSubBranches =
      activeUser?.organization_type === 'AGENT' && organizations.length > 1;
    return [
      ...(hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST')
        ? ['delete/copy']
        : []),
      'product_name',
      'internal_product_name',
      'availability',
      'area_name',
      'supplier_reference',
      ...(activeUser?.organization_type === 'AGENT' ? ['supplier_name'] : []),
      'id',
      'product_tags',
      ...(activeUser?.organization_type === 'SUPPLIER'
        ? ['internal_product_tags']
        : []),
      ...(hasSubBranches ? ['responsible_agent'] : []),
    ];
  }
);

const visibleColumnsSelector = (state: ReduxState) =>
  state.productTableControls.visibleColumns;

export const productVisibleColumnsSelector = createSelector(
  visibleColumnsSelector,
  productColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);

// Reducers
const summariesFiltered = (state = [], action: any) => {
  switch (action.type) {
    case SET_PRODUCT_FILTERED:
      return action.filtered;

    default:
      return state;
  }
};

const summariesSorted = (
  state = [
    {
      id: 'bookmarked',
      desc: false,
    },
  ],
  action: any
) => {
  switch (action.type) {
    case SET_PRODUCT_SORTED:
      return action.sorted;

    default:
      return state;
  }
};

const defaultVisibleColumns = [
  'delete/copy',
  'product_name',
  'internal_product_name',
  'availability',
  'area_name',
  'supplier_reference',
  'supplier_name',
  'id',
  'product_tags',
  'internal_product_tags',
  'responsible_agent',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_PRODUCT_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const page = (state = 0, action: any) => {
  switch (action.type) {
    case SET_PRODUCT_PAGE:
      return action.page;

    case SET_IMPERSONATED_USER_ID:
    case SET_PRODUCT_FILTERED:
    case SET_PRODUCT_SORTED:
    case SET_PRODUCT_PAGE_SIZE:
      return 0;

    default:
      return state;
  }
};

const pageSize = (state = 15, action: any) => {
  switch (action.type) {
    case SET_PRODUCT_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

export const productTableControls = combineReducers({
  page,
  pageSize,
  summariesFiltered,
  summariesSorted,
  visibleColumns,
});
