import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';

import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { findOverlappingDateRange } from 'client/pages/ProductEditor/ReservationParametersEditor/util';
import { getAvailabilityRuleScheduleText } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { StartTimesInput } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/StartTimesInput';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';

export const AvailabilityAllotmentEditor = () => {
  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const { t } = useTranslation();

  const validateNoOverlaps = useCallback(
    (schedules: AvailabilityAllotmentSchedule[]): any | null | undefined => {
      for (const [idx, schedule] of schedules.entries()) {
        const overlap = findOverlappingDateRange(
          schedule,
          schedules.slice(idx + 1)
        );

        if (overlap) {
          return t(
            'Duplicate participation rules for {{startDate}} ~ {{endDate}}. Please edit date range or add closed dates.',
            {
              startDate: overlap.startDate,
              endDate: overlap.endDate,
            }
          );
        }
      }

      return undefined;
    },
    [t]
  );

  return (
    <FieldArray
      name="availabilityAllotmentSchedules"
      validate={validateNoOverlaps as any}
    >
      {({ fields }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <SingleDropdown
                options={fields.value.map(
                  (availabilityAllotmentSchedule, idx) => ({
                    text: getAvailabilityRuleScheduleText(
                      availabilityAllotmentSchedule,
                      t
                    ),
                    value: idx.toString(),
                  })
                )}
                selectedOption={activeScheduleIndex.toString()}
                onChange={(e) => {
                  setActiveScheduleIndex(parseInt(e));
                }}
              />
            </div>
          </div>

          <CollapsibleBox key={activeScheduleIndex} title={t('Selected Rule')}>
            <StartTimesInput
              name={`availabilityAllotmentSchedules.${activeScheduleIndex}`}
            />
          </CollapsibleBox>
        </>
      )}
    </FieldArray>
  );
};
