import type { Review } from 'shared/models/swagger';
import { ReviewCard } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review/ReviewCard';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

type Props = {
  reviews: Review[];
  label?: string;
  showEditButton: boolean;
  showStatus: boolean;
};
export const ReviewList = ({
  reviews,
  label,
  showEditButton,
  showStatus,
}: Props) => {
  return (
    <>
      {label && <p className={styles['p-review__ttl']}>{label}</p>}
      <ul className={styles['p-review']}>
        {reviews.map((review, idx) => (
          <ReviewCard
            key={idx}
            review={review}
            showEditButton={showEditButton}
            showStatus={showStatus}
          />
        ))}
      </ul>
    </>
  );
};
