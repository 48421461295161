import * as Swagger from 'shared/models/swagger';

export type DetailInfoFormValues = {
  inclusions: string[];
  exclusions: string[];
  requirements: string[];
  restrictions: string[];
  whatToBring: string[];
  otherNotes: string[];
};
export const getInitialDetailInfoFormValues = (
  product: Swagger.Product | null
): DetailInfoFormValues => {
  return {
    inclusions: product?.inclusions ?? [],
    exclusions: product?.exclusions ?? [],
    requirements: product?.requirements ?? [],
    restrictions: product?.restrictions ?? [],
    whatToBring: product?.what_to_bring ?? [],
    otherNotes: product?.other_notes ?? [],
  };
};
export const convertDetailInfoFormValuesToProductPatch = (
  values: DetailInfoFormValues
): Swagger.Product$Patch => ({
  inclusions: values.inclusions.filter((i) => Boolean(i)),
  exclusions: values.exclusions.filter((i) => Boolean(i)),
  requirements: values.requirements.filter((i) => Boolean(i)),
  restrictions: values.restrictions.filter((i) => Boolean(i)),
  what_to_bring: values.whatToBring.filter((i) => Boolean(i)),
  other_notes: values.otherNotes.filter((i) => Boolean(i)),
});
