import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { ManifestActiveResourceContext } from 'client/contexts/ManifestActiveResourceContext';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';

import { Info } from './Info';
import styles from './EditCell.module.css';

interface Props {
  reservation: ManifestReservationShape;
}

export const EditCell = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const activeResource = React.useContext(ManifestActiveResourceContext);

  let reservationHasTransportRouteAssignments = false;
  let reservationHasReservationAssignments = false;
  if (activeResource) {
    switch (activeResource.activeResourceType) {
      case 'guide':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_guides?.some(
            (guide) => guide === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_guides?.some(
              (guide) => guide === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'crew':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_crew?.some(
            (crew) => crew === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_crew?.some(
              (crew) => crew === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'vehicle':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_vehicles?.some(
            (vehicle) => vehicle === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_vehicles?.some(
              (vehicle) => vehicle === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'other':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_misc_resources?.some(
            (otherResource) =>
              otherResource === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_misc_resources?.some(
              (otherResource) =>
                otherResource === activeResource.activeResourceKey
            )
          )
        );
        break;
    }
  }

  return (
    <Box display="flex">
      <Link
        to={`/reservations/${reservation.id}`}
        className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
        data-text={t('Detail')}
      >
        <img src={reservationDetailIcon} />
      </Link>
      <Box className={styles['info-container']} zIndex={1000}>
        {reservationHasTransportRouteAssignments &&
          !activeResource?.shouldShowTransportRouteAssignmentFormat && (
            <Info text={t('Transport routes are assigned')} />
          )}
        {reservationHasReservationAssignments &&
          activeResource?.shouldShowTransportRouteAssignmentFormat && (
            <Info text={t('Assigned to reservation')} />
          )}
      </Box>
    </Box>
  );
};
