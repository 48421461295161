import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useContext } from 'react';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { EditingProductContext } from 'client/contexts/EditingProductContext';

type Props = {
  name: string;
  language: string;
  currency: string;
  showCustom?: boolean;
  showNet?: boolean;
  showPerGroup?: boolean;
};
export const PerParticipantPriceInput = ({
  language,
  name,
  showCustom,
}: Props) => {
  const { t } = useTranslation();
  const translationTargetLanguage = useContext(TranslationLanguageContext);
  const builtInUnits = [
    t('Adult', {
      lng: language,
    }),
    t('Child', {
      lng: language,
    }),
    t('Adult/Child', {
      lng: language,
    }),
    t('Infant', {
      lng: language,
    }),
    t('Spectator', {
      lng: language,
    }),
  ];
  const unitOptions = [
    ...builtInUnits.map((unit) => ({
      value: unit,
      text: unit,
    })),
    ...(showCustom
      ? [
          {
            value: 'custom',
            text: t('Custom'),
          },
        ]
      : []),
  ];

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <div style={{ width: '100%' }}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((fieldName) => (
              <>
                {showCustom && (
                  <CollapsibleBox
                    key={fieldName}
                    title={`${t('Unit')} - ${t('Displayed As')}`}
                  >
                    <TranslatedField name={`${fieldName}.unit`}>
                      {({ input, translationInput }) => (
                        <ul className={styles['p-list']}>
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__body']}>
                              <Field name={`${fieldName}.unit`}>
                                {({ input }) => (
                                  <SingleDropdown
                                    options={unitOptions.map((option) => ({
                                      value: option.value,
                                      text: option.text,
                                    }))}
                                    selectedOption={
                                      builtInUnits.includes(input.value)
                                        ? input.value
                                        : 'custom'
                                    }
                                    onChange={(value) => {
                                      if (value === 'custom') {
                                        input.onChange('');
                                      } else {
                                        input.onChange(value);
                                      }
                                    }}
                                    disabled={true}
                                  />
                                )}
                              </Field>
                            </div>
                          </li>
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...input} />
                            </div>
                          </li>
                          {translationTargetLanguage && (
                            <li className={clsx(styles['p-list__item'])}>
                              <div className={styles['p-list__item__ttl']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                              <div className={styles['p-list__item__body']}>
                                {!builtInUnits.includes(input.value) && (
                                  <TextField {...translationInput} />
                                )}
                              </div>
                            </li>
                          )}
                        </ul>
                      )}
                    </TranslatedField>
                  </CollapsibleBox>
                )}
              </>
            ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};
