import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Loading } from 'client/components/v3/Common/Loading';
import { ReduxState } from 'client/reducers';
import { currency } from 'shared/libraries/currency';

import styles from './MediaSalesTableGadget.module.css';

export const MediaSalesTableGadget = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.onSiteReportData
  );

  const getMediaDisplayName = (media: string) => {
    switch (media) {
      case 'email':
        return t('Email');
      case 'website_popup':
        return t('Website Popup');
      case 'web_push':
        return t('Web Push');
      case 'sns':
        return t('SNS');
      case 'other':
        return t('Other');
      default:
        return t('Unknown');
    }
  };

  const items: {
    value: string;
    title: string;
    amount: number;
    comparisonAmount?: number;
  }[] = (
    reportDataSets[0]
      ?.gross_sales_by_landing_marketing_automation_content_type || []
  ).map((sales) => ({
    title: getMediaDisplayName(
      sales.landing_marketing_automation_content_type ?? ''
    ),
    value: sales.landing_marketing_automation_content_type ?? '',
    amount: currency(sales?.gross_sales ?? '').value,
  }));

  if (reportDataSets.length > 1) {
    items.forEach((item) => {
      const comparisonItem =
        reportDataSets[1]?.gross_sales_by_landing_marketing_automation_content_type?.find(
          (sales) =>
            sales.landing_marketing_automation_content_type === item.value
        );
      if (comparisonItem) {
        item.comparisonAmount = currency(
          comparisonItem?.gross_sales ?? ''
        ).value;
      }
    });
  }

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-inner']}>
          <div className={styles['header-text']}>{t('Sales per Media')}</div>
        </div>
      </div>
      <div className={styles['graph-container']}>
        {loading ? (
          <Loading size="md" />
        ) : (
          <table
            className={clsx(
              tableSmallStyles['c-tableSmall'],
              tableSmallStyles['row']
            )}
            style={{ tableLayout: 'fixed' }}
          >
            <thead>
              <tr>
                <th style={{ width: '200px' }}>{t('Media')}</th>
                <th style={{ width: '200px' }}>{t('Amount')}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr className={styles['p-accessReport__row']} key={item.value}>
                  <td data-text={t('visitCount')}>
                    <div className={styles['p-accessReport__cell']}>
                      <p>{item.title}</p>
                    </div>
                  </td>
                  <td data-text={t('userCount')}>
                    <div className={styles['p-accessReport__cell']}>
                      <p>{item.amount.toLocaleString()}</p>
                    </div>
                    {item.comparisonAmount && (
                      <div className={styles['p-accessReport__cell']}>
                        <p>{item.comparisonAmount.toLocaleString()}</p>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
