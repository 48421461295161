import type { TranslateFuncType } from 'client/components/Translate';
import type { DayOfWeek } from 'shared/models/swagger';

const allDaysOfweek: DayOfWeek[] = [
  'SAT',
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
];

interface DayOfWeekRange {
  start: DayOfWeek;
  end?: DayOfWeek;
}

export const getDaysOfWeekText = (
  daysOfWeek: DayOfWeek[],
  t: TranslateFuncType
): string => {
  const sortedDaysOfWeek = allDaysOfweek.filter((dayOfWeek) =>
    daysOfWeek.includes(dayOfWeek)
  );

  // No text needed to describe all days of week, no days of week
  if (sortedDaysOfWeek.length === 7 || sortedDaysOfWeek.length === 0) {
    return '';
  }

  const dayOfWeekRanges = getDayOfWeekRanges(sortedDaysOfWeek);

  return dayOfWeekRanges
    .map((dayOfWeekRange) => {
      const firstDay = getShortWeekdayText(dayOfWeekRange.start, t);
      if (!dayOfWeekRange.end) {
        return firstDay;
      }

      return `${firstDay}-${getShortWeekdayText(dayOfWeekRange.end, t)}`;
    })
    .join(',');
};

const getDayOfWeekRanges = (daysOfWeek: DayOfWeek[]): DayOfWeekRange[] => {
  const dayOfWeekRanges: DayOfWeekRange[] = [];
  let currentRange: DayOfWeekRange | null = null;

  for (const dayOfWeek of allDaysOfweek) {
    if (daysOfWeek.includes(dayOfWeek)) {
      if (!currentRange) {
        currentRange = {
          start: dayOfWeek,
        };
      } else {
        currentRange.end = dayOfWeek;
      }
    } else {
      if (currentRange) {
        dayOfWeekRanges.push(currentRange);
        currentRange = null;
      }
    }
  }

  if (currentRange) {
    if (
      dayOfWeekRanges.length &&
      dayOfWeekRanges[0].start === allDaysOfweek[0]
    ) {
      dayOfWeekRanges[0].start = currentRange.start;
    } else {
      dayOfWeekRanges.push(currentRange);
    }
  }
  return dayOfWeekRanges;
};

const getShortWeekdayText = (dayOfWeek: DayOfWeek, t: TranslateFuncType) => {
  switch (dayOfWeek) {
    case 'MON':
      return t('M');
    case 'TUE':
      return t('Tu');
    case 'WED':
      return t('W');
    case 'THU':
      return t('Th');
    case 'FRI':
      return t('F');
    case 'SAT':
      return t('Sa');
    case 'SUN':
      return t('Su');
    default:
      return '';
  }
};
