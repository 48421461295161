import querystring from 'query-string';
import moment from 'moment-timezone';
import _ from 'lodash';

import type { TranslateFuncType } from 'client/components/Translate';
import { fetchMediaDownloadOrders } from 'client/actions/mediaDownloadOrders';
import { ListMediaDownloadOrdersRequest } from 'shared/models/swagger';

export type DateFilterPreset =
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'CREATED_1_DAY'
  | 'CREATED_3_DAYS'
  | 'CREATED_7_DAYS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
      'CREATED_1_DAY',
      'CREATED_3_DAYS',
      'CREATED_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'CREATED_1_DAY':
      return t('Purchased, past {{count}} days', {
        count: 1,
      });

    case 'CREATED_3_DAYS':
      return t('Purchased, past {{count}} days', {
        count: 3,
      });

    case 'CREATED_7_DAYS':
      return t('Purchased, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, past 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, past {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, past {{count}} days', {
        count: 7,
      });
  }

  return '';
};
export type SearchMediaDownloadOrdersRequest = {
  agentReference: string;
  supplierReference: string;
  customerGivenName: string;
  customerFamilyName: string;
  productIds: string[];
  participationDateFrom: string;
  participationDateTo: string;
  createdDateFrom: string;
  createdDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
};
export const convertSearchMediaDownloadOrdersRequestToQueryParams = (
  req: SearchMediaDownloadOrdersRequest
): ListMediaDownloadOrdersRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let createdDateTimeUtcFrom = '';
  let createdDateTimeUtcTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'CREATED_1_DAY':
        createdDateTimeUtcFrom = now.subtract(1, 'days').utc().format();
        break;

      case 'CREATED_3_DAYS':
        createdDateTimeUtcFrom = now.subtract(3, 'days').utc().format();
        break;

      case 'CREATED_7_DAYS':
        createdDateTimeUtcFrom = now.subtract(7, 'days').utc().format();
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = moment(now)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = moment(now)
          .subtract(3, 'days')
          .format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = moment(now)
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(1, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    createdDateTimeUtcFrom = req.createdDateFrom
      ? moment(req.createdDateFrom).utc().format()
      : '';
    createdDateTimeUtcTo = req.createdDateTo
      ? moment(req.createdDateTo).add(1, 'day').utc().format()
      : '';
  }

  return {
    start_date_local_from: startDateLocalFrom,
    start_date_local_to: startDateLocalTo,
    created_date_time_utc_from: createdDateTimeUtcFrom,
    created_date_time_utc_to: createdDateTimeUtcTo,
    filter: querystring.stringify({
      reservation_agent_reference: req.agentReference
        ? [req.agentReference, '']
        : '',
      reservation_supplier_reference: req.supplierReference
        ? [req.supplierReference, '']
        : '',
      reservation_guest_given_name: req.customerGivenName
        ? [
            ...new Set([
              req.customerGivenName,
              req.customerGivenName.toLowerCase(),
              req.customerGivenName.toUpperCase(),
              _.startCase(req.customerGivenName.toLowerCase()),
            ]),
          ]
        : '',
      reservation_guest_family_name: req.customerFamilyName
        ? [
            ...new Set([
              req.customerFamilyName,
              req.customerFamilyName.toLowerCase(),
              req.customerFamilyName.toUpperCase(),
              _.startCase(req.customerFamilyName.toLowerCase()),
            ]),
          ]
        : '',
      product_id: req.productIds.length > 0 ? req.productIds : '',
    }),
  };
};
export const searchMediaDownloadOrders = (
  req: SearchMediaDownloadOrdersRequest
) =>
  fetchMediaDownloadOrders(
    convertSearchMediaDownloadOrdersRequestToQueryParams(req)
  );
