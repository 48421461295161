import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

import 'react-table/react-table.css';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import { manifestAlternateNameSettingsSelector } from 'client/reducers/manifestSettings';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { EditOutputAbbreviationTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/EditOutputAbbreviationTable';

type Props = {
  isActive: boolean;
};

export const OutputAbbreviation = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const alternateNameSettings = useSelector(
    manifestAlternateNameSettingsSelector
  );
  const products = useSelector(summariesWithBookmarksSelector);

  const agents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  useEffect(() => {
    setEditMode(submitting);
  }, [submitting]);

  // Close edit mode if active tab is changed
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  const columns = [
    {
      Header: t('Default name'),
      accessor: 'default',
      width: 'long',
    },
    {
      Header: t('Abbreviation'),
      accessor: 'alternate',
      width: 'long',
    },
  ];

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
      ref={ref}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Output Abbreviations')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          {editMode && (
            <>
              <Button
                text={t('Cancel')}
                onClick={() => {
                  setEditMode(false);
                }}
                color={'white'}
              />
              <Button
                text={t('Save')}
                type="submit"
                color={'primary'}
                form="outputAbbreviationForm"
                loading={submitting}
              />
            </>
          )}
          {!editMode && (
            <Button
              text={t('Edit')}
              onClick={() => {
                setEditMode(!editMode);
              }}
              color="white"
              iconBeforeText={
                <i className="c-icon-outline-general-edit-05"></i>
              }
            />
          )}
        </div>
      </div>

      {!editMode && (
        <>
          <div className={styles['p-manifests__customize__body__box']}>
            <p className={styles['p-manifests__customize__body__box__ttl']}>
              {t('Product name')}
            </p>
            <ManifestCustomizeCustomTable
              itemName={t('Abbreviation')}
              items={(alternateNameSettings.id_alternate_names || []).map(
                (a) => {
                  return {
                    default:
                      products.find((p) => p.id === a.id)?.product_name || '',
                    alternate: a.name || '',
                  };
                }
              )}
              columns={columns as any}
            />
          </div>
          <div className={styles['p-manifests__customize__body__box']}>
            <p className={styles['p-manifests__customize__body__box__ttl']}>
              {t('Location name')}
            </p>
            <ManifestCustomizeCustomTable
              itemName={t('Abbreviation')}
              items={(alternateNameSettings.alternate_names || []).map((a) => {
                return {
                  default: a.key || '',
                  alternate: a.name || '',
                };
              })}
              columns={columns as any}
            />
          </div>
          <div className={styles['p-manifests__customize__body__box']}>
            <p className={styles['p-manifests__customize__body__box__ttl']}>
              {t('Agent Name')}
            </p>
            <ManifestCustomizeCustomTable
              itemName={t('Abbreviation')}
              items={(alternateNameSettings.agent_id_alternate_names || []).map(
                (a) => {
                  return {
                    default: agents.find((p) => p.id === a.id)?.name || '',
                    alternate: a.name || '',
                  };
                }
              )}
              columns={columns as any}
            />
          </div>
        </>
      )}

      {editMode && (
        <EditOutputAbbreviationTable
          onSubmit={(value) => {
            setSubmitting(value);
          }}
        />
      )}

      {/* TODO: figure out how to implement fixed footer later */}
      {editMode && (
        <div
          className={clsx(styles['p-manifests__fixed'], styles['is-active'])}
        >
          <div className={styles['p-manifests__fixed__main']}></div>
          <div className={styles['p-manifests__fixed__actions']}>
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
            <Button
              text={t('Save')}
              type="submit"
              color={'primary'}
              form="outputAbbreviationForm"
              loading={submitting}
            />
          </div>
        </div>
      )}
    </div>
  );
};
