// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { manifestCustomizedColumnNamesSelector } from 'client/reducers/manifestSettings';
import { updateManifestCustomizedColumnNames } from 'client/actions/manifestSettings';
import { Modal } from 'client/components/Modal/Modal';
import { Input, Button } from 'client/components/Form';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import type { ManifestCustomizedColumnName } from 'shared/models/swagger';
import styles from 'client/pages/ManifestCustomize/CustomizedColumnName/CustomizedColumnName.module.css';

type Props = {
  trigger: React.Element<'a' | 'button'>,
};

export const EditCustomizedColumnNameModal = ({ trigger }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [customizedColumnNames, setCustomizedColumnNames] = React.useState<
    ManifestCustomizedColumnName[]
  >([]);

  const currentCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );

  const allManifestColumns = useAllManifestColumns();

  React.useEffect(() => {
    setCustomizedColumnNames(currentCustomizedColumnNames);
  }, [currentCustomizedColumnNames]);

  const headerText = t('Customize column names');

  const targetManifestColumnNames = React.useMemo(() => {
    const columns = [];

    for (const col of allManifestColumns.filter(
      (c) => c !== 'RESERVATION_FORM_FIELDS' && c !== 'RESERVATION_FORM_CUSTOM'
    )) {
      if (col === 'TRANSPORT_ROUTE') {
        columns.push(
          'TRANSPORT_DEPARTURE_TIME',
          'TRANSPORT_DEPARTURE_LOCATION',
          'TRANSPORT_ARRIVAL_TIME',
          'TRANSPORT_ARRIVAL_LOCATION'
        );
      } else {
        columns.push(col);
      }
    }

    return columns;
  }, [allManifestColumns]);

  const updated = () => {
    return targetManifestColumnNames.some(
      (column) =>
        (customizedColumnNames.find((c) => c.column === column)?.name || '') !==
        (currentCustomizedColumnNames.find((c) => c.column === column)?.name ||
          '')
    );
  };

  return (
    <Modal
      title={headerText}
      trigger={trigger}
      open={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
    >
      <Modal.Content>
        <table className={clsx(styles['modal__table'])}>
          <tbody>
            {targetManifestColumnNames.map((column) => (
              <tr key={column}>
                <th>{getColumnHeaderText(column, t)}</th>
                <td>
                  <Input
                    value={
                      customizedColumnNames.find((c) => c.column === column)
                        ?.name || ''
                    }
                    placeholder={getColumnHeaderText(column, t)}
                    onChange={(e, { value }) => {
                      setCustomizedColumnNames([
                        ...customizedColumnNames.filter(
                          (c) => c.column !== column
                        ),
                        {
                          column: column,
                          name: value,
                        },
                      ]);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          positive
          disabled={!updated()}
          onClick={() => {
            dispatch(
              updateManifestCustomizedColumnNames(
                customizedColumnNames.filter((c) => c.name !== '')
              )
            );
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
