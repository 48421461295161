import * as React from 'react';

import baseStyles from 'client/base.module.css';

type Props = {
  text: string;
  // ** HACK **
  // Mobile style allows customizing style for mobile to allow fine-grained control of individual
  // tooltip positioning.
  mobileStyle?: Record<string, any>;
  style?: Record<string, any>;
  icon?: React.ReactNode;
};

export const Tooltip = ({ icon, mobileStyle, style, text }: Props) => {
  const mql = window.matchMedia('(max-width: 768px)');
  const customStyle = { ...style, ...(mql.matches ? mobileStyle : null) };
  return (
    <div className={baseStyles['base-info']}>
      {icon ? icon : <i className={baseStyles['base-info__ic']}>?</i>}
      <div className={baseStyles['base-info__body']} style={customStyle}>
        {text}
      </div>
    </div>
  );
};
