import { combineReducers } from 'redux';
import type { Action } from 'redux';
import { createSelector } from 'reselect';

import {
  FETCH_SUPPLIER_SETTINGS_REQUEST,
  FETCH_SUPPLIER_SETTINGS_SUCCESS,
  FETCH_SUPPLIER_SETTINGS_FAILURE,
  PUT_SUPPLIER_SETTINGS_FAILURE,
  PUT_SUPPLIER_SETTINGS_REQUEST,
  PUT_SUPPLIER_SETTINGS_SUCCESS,
} from 'client/constants/ActionTypes';
import * as Swagger from 'shared/models/swagger';

import { ReduxState } from '.';

export const journeyAnalyticsSearchPresetsSelector = createSelector(
  (state: ReduxState) => state.supplierSettings.journeyAnalytics?.presets,
  (presets) => presets ?? []
);

export const journeyAnalyticsAutoTagSelector = createSelector(
  (state: ReduxState) => state.supplierSettings.journeyAnalytics?.autotags,
  (autotags) => autotags ?? []
);

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_SUPPLIER_SETTINGS_REQUEST:
    case PUT_SUPPLIER_SETTINGS_REQUEST:
      return '';

    case FETCH_SUPPLIER_SETTINGS_FAILURE:
    case PUT_SUPPLIER_SETTINGS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case PUT_SUPPLIER_SETTINGS_REQUEST:
    case FETCH_SUPPLIER_SETTINGS_REQUEST:
      return true;

    case PUT_SUPPLIER_SETTINGS_FAILURE:
    case PUT_SUPPLIER_SETTINGS_SUCCESS:
    case FETCH_SUPPLIER_SETTINGS_FAILURE:
    case FETCH_SUPPLIER_SETTINGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const journeyAnalytics = (
  state: Swagger.JourneyAnalyticsSettings | null = null,
  action: any
): Swagger.JourneyAnalyticsSettings | null => {
  switch (action.type) {
    case FETCH_SUPPLIER_SETTINGS_SUCCESS:
    case PUT_SUPPLIER_SETTINGS_SUCCESS: {
      if (action.response.key === 'JOURNEY_ANALYTICS') {
        return action.response?.data?.journey_analytics_settings ?? null;
      }
      return state;
    }

    case FETCH_SUPPLIER_SETTINGS_FAILURE:
      return null;

    default:
      return state;
  }
};

export const supplierSettings = combineReducers({
  error,
  loading,
  journeyAnalytics,
});
