import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState } from 'react';

import { EditTransportRouteModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DetailInformation/EditTransportRouteModal';
import { reservationIsCheckinCheckoutOnly } from 'client/libraries/util/util';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { Reservation } from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

const formattedTransportLocation = (
  locationName: string,
  dateTimeUtc: string,
  timezone: string,
  t: (s: string) => string
): string => {
  if (!locationName) {
    return t('TBD');
  }

  return `${
    dateTimeUtc && moment.tz(dateTimeUtc, timezone).format('HH:mm')
  } ${locationName}`;
};

type Props = {
  reservation: Reservation;
};

export const TransportRoutePanel = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);

  if ((reservation?.transport_route ?? []).length === 0) {
    return null;
  }

  const pickupCheckin =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkin
      : reservation?.pickup;
  const dropoffCheckout =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkout
      : reservation?.dropoff;

  return (
    <>
      {showEditModal && (
        <EditTransportRouteModal onClose={() => setShowEditModal(false)} />
      )}
      <div className={clsx(styles[''], styles['max'])}>
        <div className={styles['p-secondary__item__header']}>
          <p
            className={styles['p-secondary__item__ttl']}
            style={{ marginBottom: 0 }}
          >
            {t('Transport Routes')}
          </p>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              text={t('Edit')}
              uiType="bg"
              size="sm"
              color="white"
              iconBeforeText={
                <i className="c-icon-outline-general-edit-05"></i>
              }
              onClick={() => setShowEditModal(true)}
            />
          </div>
        </div>
        <table
          className={tableStyles['c-tableSmall']}
          style={{ overflow: 'hidden' }}
        >
          <tbody>
            {reservation?.transport_route?.map((transportRouteItem, idx) => {
              const locationFrom = transportRouteItem?.location_from;
              const locationTo = transportRouteItem?.location_to;
              return (
                <tr key={idx}>
                  <th className={baseStyles['u-width-176']}>
                    {t('Route #{{routeIdx}}', {
                      routeIdx: idx + 1,
                    })}
                  </th>
                  <td>
                    {locationFrom
                      ? formattedTransportLocation(
                          locationFrom?.location_name ?? '',
                          locationFrom?.date_time_utc ?? '',
                          reservation?.start_timezone ?? '',
                          t
                        )
                      : formattedTransportLocation(
                          pickupCheckin?.location_name ?? '',
                          pickupCheckin?.date_time_utc ?? '',
                          reservation?.start_timezone ?? '',
                          t
                        )}{' '}
                    -{' '}
                    {locationTo
                      ? formattedTransportLocation(
                          locationTo?.location_name ?? '',
                          locationTo?.date_time_utc ?? '',
                          reservation?.start_timezone ?? '',
                          t
                        )
                      : formattedTransportLocation(
                          dropoffCheckout?.location_name ?? '',
                          dropoffCheckout?.date_time_utc ?? '',
                          reservation?.start_timezone ?? '',
                          t
                        )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
