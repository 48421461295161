import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getValidators } from 'shared/libraries/validate/validator';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { PerParticipantPriceInput } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/PerParticipantPriceInput';
import { PerGroupPriceInput } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/PerGroupPriceInput';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import translationStyles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { getScheduleText } from 'client/pages/ProductEditor/util';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { getLanguageName } from 'client/libraries/i18n';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { Badge } from 'client/components/v3/Common/Badge';

export const PricesEditor = () => {
  const [activePriceIndex, setActivePriceIndex] = useState<number>(0);

  const { t } = useTranslation();

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const { required } = getValidators(t);

  return (
    <FieldArray name="priceSchedules">
      {({ fields }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <SingleDropdown
                options={fields.value.map((priceRule, idx) => ({
                  text: getScheduleText(priceRule, t),
                  value: idx.toString(),
                }))}
                selectedOption={activePriceIndex.toString()}
                onChange={(e) => {
                  setActivePriceIndex(parseInt(e));
                }}
              />
            </div>
          </div>

          <div key={activePriceIndex} className={styles['p-frame']}>
            <div className={styles['p-frame__header']}>
              <p className={styles['p-frame__header__ttl']}>
                {t('Selected Price Schedule')}
              </p>
            </div>
            <div className={styles['p-frame__body']}>
              <ul className={styles['p-list']}>
                <li className={styles['p-list__item']}>
                  <Field name={`${fields.name}.${activePriceIndex}.method`}>
                    {({ input: { value } }) => (
                      <>
                        {value === 'PER_PARTICIPANT' && (
                          <>
                            <PerParticipantPriceInput
                              showCustom
                              name={`${fields.name}.${activePriceIndex}.prices`}
                              language={sourceLanguage}
                              currency={defaultCurrency}
                              showNet={true}
                            />
                            <PerGroupPriceInput
                              name={`${fields.name}.${activePriceIndex}.perGroupPrices`}
                              currency={defaultCurrency}
                              showNet={true}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Field>
                </li>
              </ul>
              <>
                <Field
                  name={`${fields.name}.${activePriceIndex}.applySurcharge`}
                >
                  {({ input: applySurchargeToggleInput }) => (
                    <div style={{ width: '100%', marginTop: '20px' }}>
                      <Toggle
                        label={t('Apply surcharge for these dates')}
                        checked={applySurchargeToggleInput.value}
                        onChange={() =>
                          applySurchargeToggleInput.onChange(
                            !applySurchargeToggleInput.value
                          )
                        }
                      />

                      {applySurchargeToggleInput.value && (
                        <CollapsibleBox title={t('Surcharge Title')}>
                          <TranslatedField
                            name={`${fields.name}.${activePriceIndex}.surchargeTitle`}
                            validate={required}
                          >
                            {({
                              input,
                              meta: { touched, error },
                              translationInput,
                            }) => (
                              <ul className={translationStyles['p-list']}>
                                <li
                                  className={translationStyles['p-list__item']}
                                >
                                  <div
                                    className={
                                      translationStyles['p-list__item__ttl']
                                    }
                                  >
                                    <div
                                      className={
                                        translationStyles[
                                          'p-list__item__ttl__txt'
                                        ]
                                      }
                                    >
                                      <p>
                                        {getLanguageName(
                                          lowerCaseIsoSourceLanguage,
                                          t
                                        )}
                                      </p>
                                      <Badge
                                        size="sm"
                                        label={t('Required')}
                                        color="warning-contained"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      translationStyles['p-list__item__body']
                                    }
                                  >
                                    <TextField
                                      {...input}
                                      type="text"
                                      error={Boolean(touched && error)}
                                      helperText={error}
                                    />
                                  </div>
                                </li>
                                {translationTargetLanguage && (
                                  <li
                                    className={
                                      translationStyles['p-list__item']
                                    }
                                  >
                                    <div
                                      className={
                                        translationStyles['p-list__item__ttl']
                                      }
                                    >
                                      <p>
                                        {getLanguageName(
                                          uppercaseIsoToLowercaseIso[
                                            translationTargetLanguage
                                          ],
                                          t
                                        )}
                                      </p>
                                    </div>
                                    <div
                                      className={
                                        translationStyles['p-list__item__body']
                                      }
                                    >
                                      <TextField
                                        {...translationInput}
                                        type="text"
                                      />
                                    </div>
                                  </li>
                                )}
                              </ul>
                            )}
                          </TranslatedField>
                        </CollapsibleBox>
                      )}
                    </div>
                  )}
                </Field>
              </>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
