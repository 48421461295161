import {
  SET_RESOURCE_AVAILABILITY_START_DATE,
  SET_RESOURCE_AVAILABILITY_RESOURCE_KEY,
  SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE,
  SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY,
} from 'client/constants/ActionTypes';
import type { ResourceType } from 'client/libraries/util/resourceType';

export const setResourceAvailabilityStartDate = (payload: string) => ({
  type: SET_RESOURCE_AVAILABILITY_START_DATE,
  payload,
});
export const setResourceAvailabilityResourceKey = (payload: string) => ({
  type: SET_RESOURCE_AVAILABILITY_RESOURCE_KEY,
  payload,
});

export const setResourceAvailabilityResourceType = (payload: ResourceType) => ({
  type: SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE,
  payload,
});

export const setResourceAvailabilityVehicleDepartureTimeKey = (
  payload: string
) => ({
  type: SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY,
  payload,
});
