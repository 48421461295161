import * as React from 'react';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchProductByID } from 'client/actions/products';

import { getReactTableColumns } from './utils';

type Props = {
  reservations: ManifestReservationShape[];
  dateTime: Moment | null;
  resourceKey: string;
  productId: string;
  quantity: number;
  occupiedQuantity: number;
  onClose: () => void;
  open: boolean;
  insertRoot?: boolean;
};
export const ResourceAvailabilityReservationListModal = ({
  reservations,
  dateTime,
  resourceKey,
  productId,
  quantity,
  occupiedQuantity,
  onClose,
  open,
  insertRoot,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const product = useSelector(
    (state: ReduxState) => state.products.byID[productId]
  );

  const [columns, setColumns] = React.useState<CustomTableColumn[]>(
    getReactTableColumns(t, locale, true)
  );

  React.useEffect(() => {
    setColumns(getReactTableColumns(t, locale, true));
  }, [t, locale]);

  React.useEffect(() => {
    if (productId) {
      dispatch(fetchProductByID(productId));
    }
  }, [productId]);

  let addOnTitle = '';
  if (dateTime) {
    const dispatchMiscResources =
      (
        activeOrganization?.dispatch_settings?.dispatch_misc_resources || []
      ).find((resource) => resource.key === resourceKey) || null;

    const schedule =
      (dispatchMiscResources?.availability?.schedules || []).find(
        (schedule) => {
          const startDate = moment.tz(
            schedule.start_date_local,
            activeOrganization?.default_timezone || 'UTC'
          );
          const endDate = moment
            .tz(
              schedule.end_date_local,
              activeOrganization?.default_timezone || 'UTC'
            )
            .add(1, 'day');
          return (
            startDate.isSameOrBefore(dateTime) && endDate.isAfter(dateTime)
          );
        }
      ) || null;

    const addOnKey = schedule?.add_ons?.find(
      (addOn) => addOn.product_id === productId
    )?.add_on_key;

    addOnTitle =
      product?.add_ons?.find((addOn) => addOn.key === addOnKey)?.title || '';
  }

  return (
    <Modal
      title={dateTime?.format('lll') ?? ''}
      open={open}
      onClose={onClose}
      width="wide"
      insertRoot={insertRoot}
    >
      <Modal.Content>
        <Modal.Box>
          <ul>
            <li>
              {t('Reserved Resource')} : {occupiedQuantity} / {quantity ?? 0}
            </li>
            <li>
              {t('Product')} : {product?.product_name}
            </li>
            {addOnTitle && (
              <div>
                {t('Reservations with the add-on {{addOnTitle}}', {
                  addOnTitle: addOnTitle,
                })}
              </div>
            )}
          </ul>
        </Modal.Box>
        <CustomTable
          items={reservations}
          columns={columns}
          usePaging={false}
          useScrollButton={true}
        />
      </Modal.Content>
    </Modal>
  );
};
