import { useTranslation, TFunction } from 'react-i18next';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';

import type { CustomerReportDataKeyCount } from 'shared/models/swagger';
import { localeSelector, ReduxState } from 'client/reducers';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

import styles from './AgesGadget.module.css';

const coloers = ['#018ec9', '#0db0eb', '#7cd9fd', '#b9e9fe'];

const getTitle = (key: string, unit: string, t: TFunction) => {
  switch (key) {
    case '0':
      return t("Under 10's");
    case 'UNKNOWN':
      return t('Unknown');
    default:
      return `${key ?? ''}${unit}`;
  }
};

const convertToPercentage = (
  counts: CustomerReportDataKeyCount[],
  unit: string,
  t: TFunction
): { name: string; value: number; color: string }[] => {
  return counts
    .sort((a, b) => (b.count ?? 0) - (a.count ?? 0))
    .map((count, index) => {
      return {
        name: getTitle(count.key ?? '', unit, t),
        value: Number(count.count ?? 0),
        color: coloers[(index + 1) % coloers.length] ?? '#000',
      };
    });
};

export const AgesGadget = () => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);

  const unit = locale === 'ja' ? '代' : "'s";
  const unitForAverage = locale === 'ja' ? '歳' : "'s";

  const reportData = useSelector(
    (state: ReduxState) => state.customers.reportData
  );

  const customerCounts = reportData?.[0]?.totals?.customer_count ?? 0;
  const decadeCounts = reportData?.[0]?.totals?.decade_counts ?? [];

  const showComparison = (reportData || []).length > 1;

  const pieData = customerCounts
    ? convertToPercentage(decadeCounts, unit, t)
    : [];

  const average = reportData?.[0]?.totals?.average_age ?? 0;
  const comparationAvarege = reportData?.[1]?.totals?.average_age ?? 0;

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Age Percentage')}</div>
            <Tooltip
              text={t(
                'Display the percentage of members by age group as of the end date of the reporting period.'
              )}
            >
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          <div className={styles['chart']}>
            <div className={styles['amount']}>
              <div className={styles['amount-inner']}>
                <div className={styles['amount-text']}>
                  {average.toFixed(1)}
                </div>
                <div className={styles['amount-unit']}>{unitForAverage}</div>
              </div>
              {showComparison && (
                <>
                  {comparationAvarege.toFixed(1)}
                  <div className={styles['previous-text']}>
                    {unitForAverage}
                  </div>
                </>
              )}
            </div>
            <ResponsiveContainer width="100%" height="100%" debounce={300}>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius="90%"
                  innerRadius="70%"
                  paddingAngle={0}
                  startAngle={270}
                  endAngle={-90}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} />
                  ))}
                </Pie>
                <RechartsTooltip
                  formatter={(value: any, name: any) => {
                    const percentage = (value / customerCounts) * 100;
                    const percentageString = Number.isInteger(percentage)
                      ? percentage.toFixed(0)
                      : percentage.toFixed(2);

                    return [
                      `${t('{{count}} person', {
                        count: value,
                      })} (${percentageString}%)`,
                      name,
                    ];
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={styles['text-section']}>
            {pieData.map((entry, index) => (
              <div key={index} className={styles['legend-item']}>
                <div
                  className={styles['legend-dot']}
                  style={{ backgroundColor: entry.color }}
                />
                <div>{entry.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
