import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Button } from 'client/components/v3/Common/Button';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from './ProvinceCityNameEditor.module.css';

type Props = {
  name: string;
  onDeleteButtonClick: () => void;
};

export const ProvinceCityNameEditor = ({
  name,
  onDeleteButtonClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <li
      className={styles['p-areaGroupSettingsModal__provinceCity__list__item']}
    >
      <div className={styles['p-areaGroupSettingsModal__box']}>
        <div>
          <p>{t('State / Province / Region')}</p>
          <div>
            <Field name={`${name}.province`}>
              {({ input }) => {
                return (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                );
              }}
            </Field>
            <div className={styles['p-areaGroupSettingsModal__box']}>
              <p>{t('City / Ward / Town')}</p>
              <div>
                <ListEditor name={`${name}.cities`} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            size="icon"
            color="tertiarygray"
            onClick={onDeleteButtonClick}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </div>
      </div>
    </li>
  );
};
