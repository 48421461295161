import axios from 'axios';
import { Dispatch } from 'redux';

import { ReduxState } from 'client/reducers';
import {
  FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';

import { getHTTPRequestHeaders } from '.';

const fetchCustomerEventReportDataRequest = () => ({
  type: FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST,
});

const fetchCustomerEventReportDataSuccess = (response: any) => ({
  type: FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS,
  response,
});

const fetchCustomerEventReportDataFailure = (error: any) => ({
  type: FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE,
  error,
});

let fetchCustomerEventReportDataCancel: () => void | typeof undefined;
export const fetchCustomerEventReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomerEventReportDataCancel && fetchCustomerEventReportDataCancel();
    dispatch(fetchCustomerEventReportDataRequest());

    axios
      .get('/api/customerevents/reportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomerEventReportDataCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchCustomerEventReportDataSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchCustomerEventReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchCustomerEventReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };

const fetchCustomerEventOnSiteReportDataRequest = () => ({
  type: FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST,
});

const fetchCustomerEventOnSiteReportDataSuccess = (response: any) => ({
  type: FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS,
  response,
});

const fetchCustomerEventOnSiteReportDataFailure = (error: any) => ({
  type: FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE,
  error,
});

let fetchCustomerEventOnSiteReportDataCancel: () => void | typeof undefined;
export const fetchCustomerEventOnSiteReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomerEventOnSiteReportDataCancel &&
      fetchCustomerEventOnSiteReportDataCancel();
    dispatch(fetchCustomerEventOnSiteReportDataRequest());

    axios
      .get('/api/customerevents/onsitereportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomerEventOnSiteReportDataCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchCustomerEventOnSiteReportDataSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchCustomerEventOnSiteReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchCustomerEventOnSiteReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
