import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { Button } from 'client/components/v3/Common/Button';
import { undoCheckinReservation } from 'client/actions/reservations';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { RedeemETicketModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/Checkin/RedeemETicketModal';
import { Box } from 'client/components/Box/Box';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type {
  Product,
  Reservation,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

interface Props {
  reservation: ManifestReservationShape | Reservation | undefined;
  product: Product | ProductSummary | undefined;
}

export const ReservationGuestTypeRedemptionTableBody = ({
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showRedeemETicketModal, setShowRedeemETicketModal] =
    React.useState<boolean>(false);

  const activeUser = useSelector(activeUserSelector);

  const [undoCheckinLoadingKey, setUndoCheckinLoadingKey] = React.useState<
    string | null
  >(null);

  const undoCheckin = async (key: string) => {
    try {
      setUndoCheckinLoadingKey(key);
      await dispatch(
        undoCheckinReservation(reservation?.id ?? '', {
          key: key,
        })
      );
    } finally {
      setUndoCheckinLoadingKey(null);
    }
  };

  const records = (reservation?.checkin_info?.checkin_records ?? []).map(
    (record) => {
      const guests = (record?.guest_type_counts ?? [])
        .map((guestTypeCount) => {
          const guestTypeTitle =
            reservation?.guests?.find((guest) => {
              return guest?.guest_type_key === guestTypeCount?.guest_type_key;
            })?.guest_type_title ?? '';
          return `${guestTypeTitle}(${guestTypeCount?.count ?? 0})`;
        })
        .join(', ');

      const stubText =
        (product?.qr_checkin_settings?.stubs ?? []).find((stub) => {
          return stub?.key === record?.stub_key;
        })?.text ?? '';

      const dateTime = moment.tz(
        record.date_time_utc,
        reservation?.start_timezone || 'UTC'
      );

      return {
        key: record.key ?? '',
        stubText: stubText,
        dateTime: dateTime,
        guests: guests,
      };
    }
  );

  return (
    <>
      <Box mt={2} mb={2}>
        <Button
          text={t('Redeem Ticket')}
          onClick={() => {
            setShowRedeemETicketModal(true);
          }}
        />
      </Box>
      {records.length !== 0 && (
        <div className={clsx(styles['c-table-nowrap'])}>
          <table
            className={clsx(tableStyles['c-tableSmall'])}
            style={{ overflow: 'hidden' }}
          >
            <thead>
              <tr>
                {hasCustomUserRoleWritePermissions(
                  activeUser,
                  'RESERVATION.LIST'
                ) && <th className={clsx(baseStyles['base-t-160'])}></th>}
                <th
                  className={clsx(
                    baseStyles['base-t-192'],
                    styles['center-align']
                  )}
                >
                  {t('Date/Time')}
                </th>
                <th>{t('Ticket Usage')}</th>
                <th className={clsx(baseStyles['base-t-192'])}>
                  {t('Redemptions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, idx) => {
                return (
                  <tr key={idx}>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'RESERVATION.LIST'
                    ) && (
                      <td className={clsx(styles['center-align'])}>
                        <Button
                          text={t('Delete record')}
                          color="white"
                          disabled={!record.dateTime}
                          onClick={() => {
                            undoCheckin(record.key ?? '');
                          }}
                          loading={undoCheckinLoadingKey === record.key}
                        />
                      </td>
                    )}
                    <td className={clsx(styles['center-align'])}>
                      {record.dateTime?.format('lll') ?? ''}
                    </td>
                    <td>{record.stubText}</td>
                    <td>{record.guests}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {showRedeemETicketModal && (
        <RedeemETicketModal
          open={showRedeemETicketModal}
          reservation={reservation}
          product={product}
          onClose={() => {
            setShowRedeemETicketModal(false);
          }}
        />
      )}
    </>
  );
};
