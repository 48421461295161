import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions';
import {
  FETCH_RESERVATION_VOUCHER_REQUEST,
  FETCH_RESERVATION_VOUCHER_SUCCESS,
  FETCH_RESERVATION_VOUCHER_FAILURE,
} from 'client/constants/ActionTypes';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchReservationVoucherRequest = () => ({
  type: FETCH_RESERVATION_VOUCHER_REQUEST,
});

const fetchReservationVoucherSuccess = (html: string) => ({
  type: FETCH_RESERVATION_VOUCHER_SUCCESS,
  html,
});

const fetchReservationVoucherFailure = (error: any) => ({
  type: FETCH_RESERVATION_VOUCHER_FAILURE,
  error,
});

export const fetchReservationVoucher =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationVoucherRequest());
    axios
      .get(`/api/reservations/${id}/voucher`, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'text',
      })
      .then((response) => {
        dispatch(fetchReservationVoucherSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          fetchReservationVoucherFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };

const fetchReservationVoucherPreviewRequest = (
  primaryColor: string,
  secondaryColor: string,
  backgroundColor: string
) => ({
  type: FETCH_RESERVATION_VOUCHER_REQUEST,
  primaryColor,
  secondaryColor,
  backgroundColor,
});

const fetchReservationVoucherPreviewSuccess = (html: string) => ({
  type: FETCH_RESERVATION_VOUCHER_SUCCESS,
  html,
});

const fetchReservationVoucherPreviewFailure = (error: string) => ({
  type: FETCH_RESERVATION_VOUCHER_FAILURE,
  error,
});

export const fetchReservationVoucherPreview =
  (primaryColor: string, secondaryColor: string, backgroundColor: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      fetchReservationVoucherPreviewRequest(
        primaryColor,
        secondaryColor,
        backgroundColor
      )
    );
    axios
      .get(`/api/reservations/voucherpreview`, {
        params: {
          primary_color: primaryColor,
          secondary_color: secondaryColor,
          background_color: backgroundColor,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'text',
      })
      .then((response) => {
        dispatch(fetchReservationVoucherPreviewSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          fetchReservationVoucherPreviewFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
