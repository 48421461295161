import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLanguageName } from 'client/libraries/i18n';
import {
  LanguageUppercaseISO,
  uppercaseIsoToLowercaseIso,
} from 'shared/libraries/i18n';
import { fetchWaiverPDF } from 'client/actions/waivers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import type { Waiver } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import type { ColumnType as CustomColumnType } from 'client/libraries/util/ColumnType';

export const useWaiverColumns = (): ColumnType<Waiver>[] => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const waiverPDFStatus = useSelector(
    (state: ReduxState) => state.waivers.pdfStatus
  );
  const productsSummaries = useSelector(summariesSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const dispatch = useDispatch();

  // Need to change the order of given name and family name when the
  // reservation form requires kana name
  const getGuestName = (givenName: string, familyName: string): string => {
    if (isKanaWord(givenName) && isKanaWord(familyName)) {
      return `${familyName} ${givenName}`;
    }

    return `${givenName} ${familyName}`;
  };

  return [
    {
      Header: '',
      width: 220,
      id: 'view',
      accessor: (row) => (
        <Box display="flex" width="100%">
          <a
            style={{
              flexGrow: 1,
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${bookingWebsiteUrl}/waivers/${row.id}`}
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('View')}
          >
            {t('View')}
          </a>
          <Box ml="auto">
            <Button
              size="small"
              style="gray"
              onClick={() => {
                dispatch(fetchWaiverPDF(row.id ?? ''));
              }}
              loading={waiverPDFStatus === 'IN_FLIGHT'}
            >
              {t('Download as PDF')}
            </Button>
          </Box>
        </Box>
      ),
    },
    {
      Header: t('Submission Date'),
      width: 160, // 'middle',
      accessor: (row) =>
        moment(row.submitted_date_time_utc).locale(locale).format('lll'),
      id: 'submissionDate',
    },
    {
      Header: t('Waiver Title'),
      width: 200,
      accessor: (row) => row.title ?? '',
      id: 'title',
    },
    {
      Header: t('Language'),
      accessor: (row) =>
        getLanguageName(
          uppercaseIsoToLowercaseIso[row.language as LanguageUppercaseISO],
          t
        ),
      id: 'language',
      width: 120,
    },
    {
      Header: t('Application Number'),
      accessor: (row) => (
        <Link to={`/reservations/${row.reservation_id}`} target="_blank">
          {row.reservation_agent_reference}
        </Link>
      ),
      id: 'agentReference',
      width: 120,
    },
    {
      Header: t('Confirmation Number'),
      accessor: (row) => row.reservation_supplier_reference ?? '',
      id: 'supplierReference',
      width: 120,
    },
    {
      Header: t('Participation'),
      accessor: (row) =>
        moment
          .parseZone(row.reservation_date_from)
          .locale(locale)
          .format('lll'),
      id: 'participation',
      width: 160,
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.reservation_guest_given_name && row.reservation_guest_family_name
          ? getGuestName(
              row.reservation_guest_given_name,
              row.reservation_guest_family_name
            )
          : '',
      id: 'customer',
      width: 200,
    },
    {
      Header: t('Product'),
      accessor: (row) =>
        getDisplayProductName(
          productsSummaries.find((p) => p.id == row.product_id)
        ),
      id: 'productName',
      width: 200,
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {getWaiverStatus(row) === 'COMPLETE' && (
              <img src={checkFinishedIcon} />
            )}
            {getWaiverStatus(row) === 'IN_PROGRESS' && (
              <img src={checkInProgressIcon} />
            )}
          </Box>
          {getWaiverStatusText(row, t)}
        </Box>
      ),
      width: 160,
    },
  ];
};

// TODO: useCustomWaiverColumns is only used for feature flag config.enableWaiverListEnhancement
// Delete for release
export const useCustomWaiverColumns = (): CustomColumnType<Waiver>[] => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const waiverPDFStatus = useSelector(
    (state: ReduxState) => state.waivers.pdfStatus
  );
  const productsSummaries = useSelector(summariesSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const dispatch = useDispatch();

  // Need to change the order of given name and family name when the
  // reservation form requires kana name
  const getGuestName = (givenName: string, familyName: string): string => {
    if (isKanaWord(givenName) && isKanaWord(familyName)) {
      return `${familyName} ${givenName}`;
    }

    return `${givenName} ${familyName}`;
  };

  return [
    {
      Header: '',
      width: 'long',
      Cell: (cellInfo) => (
        <Box display="flex" width="100%">
          <a
            style={{
              flexGrow: 1,
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${bookingWebsiteUrl}/waivers/${cellInfo.original.id}`}
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('View')}
          >
            {t('View')}
          </a>
          <Box ml="auto">
            <Button
              size="small"
              style="gray"
              onClick={() => {
                dispatch(fetchWaiverPDF(cellInfo.original.id ?? ''));
              }}
              loading={waiverPDFStatus === 'IN_FLIGHT'}
            >
              {t('Download as PDF')}
            </Button>
          </Box>
        </Box>
      ),
      id: 'view',
    },
    {
      Header: t('Submission Date'),
      width: 'middle',
      accessor: (row) =>
        moment(row.submitted_date_time_utc).locale(locale).format('lll'),
      id: 'submissionDate',
    },
    {
      Header: t('Waiver Title'),
      width: 'middle',
      accessor: 'title',
      id: 'title',
    },
    {
      Header: t('Language'),
      accessor: (row) =>
        getLanguageName(
          uppercaseIsoToLowercaseIso[row.language as LanguageUppercaseISO],
          t
        ),
      id: 'language',
    },
    {
      Header: t('Application Number'),
      accessor: 'reservation_agent_reference',
      Cell: (cellInfo) => (
        <Link
          to={`/reservations/${cellInfo.original.reservation_id}`}
          target="_blank"
        >
          {cellInfo.original.reservation_agent_reference}
        </Link>
      ),
      id: 'agentReference',
    },
    {
      Header: t('Confirmation Number'),
      accessor: 'reservation_supplier_reference',
      id: 'supplierReference',
    },
    {
      Header: t('Participation'),
      accessor: (row) =>
        moment
          .parseZone(row.reservation_date_from)
          .locale(locale)
          .format('lll'),
      id: 'participation',
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.reservation_guest_given_name && row.reservation_guest_family_name
          ? getGuestName(
              row.reservation_guest_given_name,
              row.reservation_guest_family_name
            )
          : '',
      id: 'customer',
    },
    {
      Header: t('Product'),
      Cell: (cellInfo) =>
        getDisplayProductName(
          productsSummaries.find((p) => p.id == cellInfo.original.product_id)
        ),
      accessor: 'product_name',
      id: 'productName',
    },
    {
      Header: t('Status'),
      Cell: (cellInfo) => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              {getWaiverStatus(cellInfo.original) === 'COMPLETE' && (
                <img src={checkFinishedIcon} />
              )}
              {getWaiverStatus(cellInfo.original) === 'IN_PROGRESS' && (
                <img src={checkInProgressIcon} />
              )}
            </Box>
            {getWaiverStatusText(cellInfo.original, t)}
          </Box>
        );
      },
      id: 'status',
    },
  ];
};

const getWaiverStatus = (waiver: Waiver): 'COMPLETE' | 'IN_PROGRESS' => {
  if ((waiver.per_participant_waivers ?? []).length > 0) {
    if (
      (waiver.per_participant_waivers ?? []).length <
      (waiver.reservation_guest_count ?? 0)
    ) {
      return 'IN_PROGRESS';
    } else {
      return 'COMPLETE';
    }
  }

  return 'COMPLETE';
};

const getWaiverStatusText = (waiver: Waiver, t: TranslateFuncType): string => {
  if ((waiver.per_participant_waivers ?? []).length === 0) {
    return t('Completed');
  }

  if (
    (waiver.per_participant_waivers ?? []).length ===
    waiver.reservation_guest_count
  ) {
    return t('Completed');
  }

  return t('In progress ({{completions}}/{{totalGuestCount}})', {
    completions: (waiver.per_participant_waivers ?? []).length,
    totalGuestCount: waiver.reservation_guest_count,
  });
};
