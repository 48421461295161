import {
  SET_LAST_EXECUTED_SEARCH_CONDITION,
  SET_RESERVATION_VISIBLE_COLUMNS,
  SET_RESERVATION_ROW_COUNT,
  SET_RESERVATION_CURRENT_PAGE,
  SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { SearchReservationsRequest } from 'client/libraries/util/searchReservations';

export const setReservationVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_RESERVATION_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setLastExecutedSearchCondition = (
  searchFilters: SearchReservationsRequest
) => ({
  type: SET_LAST_EXECUTED_SEARCH_CONDITION,
  searchFilters,
});
export const setReservationRowCount = (rowCount: number) => ({
  type: SET_RESERVATION_ROW_COUNT,
  rowCount,
});
export const setReservationCurrentPage = (currentPage: number) => ({
  type: SET_RESERVATION_CURRENT_PAGE,
  currentPage,
});
export const setReservationDefaultVisibleColumns = (
  defaultVisibleColumns: string[]
) => ({
  type: SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS,
  defaultVisibleColumns,
});
