import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ModalLoader } from 'client/components/ModalLoader';
import { ProductInstanceModal } from 'client/pages/v3/Availability/Shared/Modal/ProductInstanceModal';
import { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import {
  fetchProductInstanceByID,
  fetchProductInstancesForProducts,
} from 'client/actions/productInstances';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import {
  ProductInstanceShape,
  toProductInstanceShape,
} from 'client/libraries/util/productInstanceShape';
import {
  ProductInstance,
  ProductDateAvailability,
  StartTimeAvailability,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import stopIcon from 'client/images/ic_stop.svg';
import rqIcon from 'client/images/ic_rq.svg';
import {
  getAvailabilityIconType,
  ProductWithOccupancy,
} from 'client/pages/Availability/util';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import {
  CalendarEditContext,
  shouldShowBasedOnVisibilityByReservationSlot,
  shouldShowBasedOnVisibilityByResource,
} from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import componentStyles from 'client/pages/Availability/components.module.css';
import calendarStyles from 'client/pages/v3/Availability/AvailabilityCalendar.module.css';
import { ViewContext } from 'client/pages/v3/Availability/AvailabilityWeekly/AvailabilityWeeklyBody/ViewContext';
import {
  reservationSlotVisibilitySettingSelector,
  resourceVisibilitySettingSelector,
} from 'client/libraries/util/visibilitySettings';
import {
  ProductWithOccupancyPlusColor,
  assignColorsToProducts,
} from 'client/pages/v3/Availability/util';
import { Pagination } from 'client/components/v3/Pagination/Pagination';

const defaultColumnWidth = 'calc(100% / 7)';
const adjustedColumnWidth = 'calc((100% - 320px) / 6)';
const defaultColumnWidthMobile = '160px';
const wideColumnWidth = '320px';

type ProductWithOccupancyByStartTime = {
  [startTime: string]: ProductWithOccupancyPlusColor[];
};

const getDateHeader = (
  column: CustomTableColumn,
  locale: string,
  dayMonthFormat: string
) => {
  const dateMoment = moment(column.accessor).locale(locale);
  return (
    <span className={clsx(baseStyles['product-calendar-table-date-header'])}>
      <div>{`${dateMoment.format(dayMonthFormat)} (${dateMoment.format(
        'ddd'
      )})`}</div>
    </span>
  );
};

const groupByActualStartTime = (products: ProductWithOccupancy[]) => {
  return products.reduce(
    (groups: { [key: string]: ProductWithOccupancy[] }, product) => {
      const startTime =
        product.startTimeAvailabilities[0]?.start_time_local || '';
      groups[startTime] = groups[startTime] || [];
      groups[startTime].push(product);
      return groups;
    },
    {}
  );
};

const StartTimeAvailabilityDataCell = ({
  items,
  column,
  onEditProductInstanceClick,
  columnIndex,
  groupedVisibleItems,
}: {
  items: ProductWithOccupancyPlusColor[];
  column: CustomTableColumn;
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
  columnIndex: number;
  groupedVisibleItems: ProductWithOccupancyByStartTime;
}) => {
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);

  // Group items in array by actual start time eg. inside 10:00 frame, groip into 10:00, 10:15, 10:30 and so on if any
  // Then iterate each start time to create availability cards
  // Each row should show up to only 4 cards initially, card no 5 and beyond will be hidden
  return (
    <>
      {Object.entries(groupByActualStartTime(items)).map(([, groupedItems]) => {
        let totalItemsPerStartTime = 0;
        for (const item of groupedItems) {
          if (item.startTimeAvailabilities[0].date_availabilities) {
            for (const dateAvailability of item.startTimeAvailabilities[0]
              .date_availabilities) {
              if (dateAvailability.date === column.accessor) {
                totalItemsPerStartTime++;
              }
            }
          }
        }
        return groupedItems.map((item, index) => {
          // Access first element [0] because each item only has one startTimeAvailability after sortAndGroupProductsBasedOnStartTimes
          const startTimeAvailability = item.startTimeAvailabilities[0];
          const availability = startTimeAvailability.date_availabilities?.find(
            (availability) => availability.date === column.accessor
          );

          // No allotment
          if (!availability) {
            return <></>;
          }

          // Shift the index forward if the previous item has no availability
          // This is to ensure that there will be no gaps between items (all items rendered in order)
          let indexToBe = index;
          if (index > 0) {
            const previousItem = groupedItems[index - 1];
            const previousAvailability = previousItem
              ? previousItem.startTimeAvailabilities[0].date_availabilities?.find(
                  (availability) => availability.date === column.accessor
                )
              : null;
            if (!previousAvailability) {
              indexToBe--;
            }
          }

          return (
            <AvailabilityCard
              key={index}
              item={item}
              availability={availability}
              onClick={() => {
                if (calendarEditModeIsActive) {
                  if (
                    selectedProductInstanceIds.includes(
                      availability?.product_instance_id ?? ''
                    )
                  ) {
                    deselectProductInstanceIds(
                      availability?.product_instance_id ?? ''
                    );
                  } else {
                    selectProductInstanceIds(
                      availability?.product_instance_id ?? ''
                    );
                  }
                } else {
                  onEditProductInstanceClick({
                    product: item,
                    startTime: startTimeAvailability,
                    column: column,
                    availability: availability,
                  });
                }
              }}
              columnIndex={columnIndex}
              itemIndex={indexToBe}
              totalItems={groupedItems.length}
              totalItemsPerStartTime={totalItemsPerStartTime}
              groupedVisibleItems={groupedVisibleItems}
            />
          );
        });
      })}
    </>
  );
};

// Calculate top position for the target card
const calculateStyleTop = (
  groupedVisibleItems: ProductWithOccupancyByStartTime,
  target: ProductWithOccupancyPlusColor
) => {
  if (Object.keys(groupedVisibleItems).length === 0) {
    return '0';
  }
  const startTimes = Object.keys(groupedVisibleItems);
  const firstStartTime = startTimes[0];
  const lastStartTime = startTimes[startTimes.length - 1];
  const firstHour = parseInt(firstStartTime.split(':')[0]);
  const lastHour = parseInt(lastStartTime.split(':')[0]);
  // +1 to accommodate the last hour, eg. span between 8:00-17:00 there are actually 10 hours (8:00-17:59)
  const totalMinutes = (lastHour - firstHour + 1) * 60;

  const itemHour = parseInt(
    (target.startTimeAvailabilities[0].start_time_local ?? '').split(':')[0]
  );
  const itemMinute = parseInt(
    (target.startTimeAvailabilities[0].start_time_local ?? '').split(':')[1]
  );
  // Calculate the relative position of the item within the total time span
  const relativePosition = (itemHour - firstHour) * 60 + itemMinute;
  // Calculate the total height of the column based on the number of generated rows by start time
  const totalHeight =
    generateHoursOfStartTimes(groupedVisibleItems).length * 160; // Assuming each row has a height of 160px
  const top = (relativePosition / totalMinutes) * totalHeight;
  return `${top}px`;
};

const AvailabilityCard = ({
  item,
  availability,
  onClick,
  columnIndex,
  itemIndex,
  totalItems,
  totalItemsPerStartTime,
  groupedVisibleItems,
}: {
  item: ProductWithOccupancyPlusColor;
  availability: ProductDateAvailability;
  onClick: () => void;
  columnIndex: number;
  itemIndex: number;
  totalItems: number;
  totalItemsPerStartTime: number;
  groupedVisibleItems: ProductWithOccupancyByStartTime;
}) => {
  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const allProducts = useSelector(summariesSelector);
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const { columnWidths } = useContext(ViewContext);

  const instanceVisibilityByReservationSlot = useSelector(
    reservationSlotVisibilitySettingSelector
  );
  const instanceVisibilityByResource = useSelector(
    resourceVisibilitySettingSelector
  );

  const formattedOccupancy = `${
    availability.occupied_slots ?? 0
  } / ${formattedTotalAllotmentSlots(availability.total_slots ?? 0)}`;

  const dispatchCrews = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_crew;
  const dispatchVehicles = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_vehicles;
  const dispatchMiscResources = allReservations.find(
    (reservation) =>
      reservation.product_instance_id === availability.product_instance_id
  )?.dispatch_misc_resources;

  const Icon = () => {
    const iconType = getAvailabilityIconType(
      availability.occupied_slots ?? 0,
      availability.total_slots ?? 0,
      availability.all_channels_are_closed ?? false,
      item.shouldRejectRequestBookingsBeyondCapacity ||
        availability.rejects_request_booking_beyond_capacity,
      availability?.booking_deadlines ?? []
    );

    if (iconType === 'STOP') {
      return (
        <img
          className={componentStyles['availability__table']}
          src={stopIcon}
        />
      );
    }

    if (iconType === 'REQUEST') {
      return (
        <img className={componentStyles['availability__table']} src={rqIcon} />
      );
    }

    return null;
  };

  const calculateStyleLeft = () => {
    // If current column is wide (show all is clicked)
    if (columnWidths[columnIndex + 1] === wideColumnWidth) {
      return `${50 * itemIndex}px`;
    }
    return `${30 * itemIndex}px`;
  };

  const calculateStyleWidth = () => {
    let width = '100%';

    // If current column is wide (show all is clicked)
    if (columnWidths[columnIndex + 1] === wideColumnWidth) {
      // Modify width of last item in row using actual number of items in column
      if (itemIndex === totalItemsPerStartTime - 1) {
        return `calc(100% - ${calculateStyleLeft()})`;
      }

      return `calc((100% / ${totalItems}) + 16px)`;
    } else {
      if (totalItems === 2) {
        width = 'calc((100% / 2) + 16px)';
      }
      if (totalItems === 3) {
        width = 'calc((100% / 3) + 16px)';
      }
      if (totalItems > 3) {
        width = `calc((100% / ${totalItems}) + 16px)`;
      }

      // Modify width of last item in row (the 4th item)
      if (itemIndex === 3) {
        width = `calc(100% - ${calculateStyleLeft()})`;
      }
    }

    return width;
  };

  const shouldShowOnlyFirstFourItems = itemIndex <= 3;
  const shouldShowAllItemsInCell =
    columnWidths[columnIndex + 1] === wideColumnWidth;
  const shouldShowItem =
    shouldShowOnlyFirstFourItems || shouldShowAllItemsInCell;

  return (
    <>
      {shouldShowBasedOnVisibilityByReservationSlot(
        instanceVisibilityByReservationSlot,
        availability.occupied_slots ?? 0
      ) &&
        shouldShowBasedOnVisibilityByResource(
          instanceVisibilityByResource,
          availability.product_instance_id ?? '',
          productInstancesById,
          dispatchCrews ?? [],
          dispatchVehicles ?? [],
          dispatchMiscResources ?? []
        ) && (
          <a
            className={calendarStyles['p-availabilityCard']}
            style={{
              display: shouldShowItem ? undefined : 'none',
              width: calculateStyleWidth(),
              left: calculateStyleLeft(),
              top: calculateStyleTop(groupedVisibleItems, item),
              color: item.cardColor?.color,
              backgroundColor: item.cardColor?.backgroundColor,
              borderColor: item.cardColor?.borderColor,
            }}
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            <div
              className={calendarStyles['p-calendarWeekly__check']}
              style={{ display: calendarEditModeIsActive ? 'block' : 'none' }}
            >
              <Checkbox
                checked={selectedProductInstanceIds.includes(
                  availability.product_instance_id ?? ''
                )}
                onChange={() => {
                  if (
                    selectedProductInstanceIds.includes(
                      availability.product_instance_id ?? ''
                    )
                  ) {
                    deselectProductInstanceIds(
                      availability.product_instance_id ?? ''
                    );
                  } else {
                    selectProductInstanceIds(
                      availability.product_instance_id ?? ''
                    );
                  }
                }}
                size="sm"
              />
            </div>
            <div className={calendarStyles['p-availabilityCard__ttl']}>
              <p>
                {getDisplayProductName(
                  allProducts.find((p) => p.id == item.id)
                )}
              </p>
              {availability.product_instance_id &&
              productInstancesById[availability.product_instance_id]?.memo ? (
                <i className="c-icon-outline-communication-message-text-square-02"></i>
              ) : null}
            </div>
            <div className={calendarStyles['p-availabilityCard__num']}>
              <Icon />
              <p> {formattedOccupancy}</p>
            </div>
            {((item.childProducts && item.childProducts.length > 0) ||
              (dispatchCrews && dispatchCrews.length > 0) ||
              (dispatchVehicles && dispatchVehicles.length > 0) ||
              (dispatchMiscResources && dispatchMiscResources.length > 0)) && (
              <div className={calendarStyles['p-availabilityCard__info']}>
                {item.childProducts.length > 0 && (
                  <div className={calendarStyles['p-availabilityCard__item']}>
                    <i className="c-icon-outline-general-link-03"></i>
                    <div>
                      {item.childProducts.map((childProduct) => (
                        <Link
                          key={childProduct.id}
                          to={`/products/${childProduct.id}`}
                        >
                          <p>{childProduct.name}</p>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                {dispatchCrews && dispatchCrews.length > 0 && (
                  <div className={calendarStyles['p-availabilityCard__item']}>
                    <i className="c-icon-solid-users-user-circle"></i>
                    <div>
                      {dispatchCrews &&
                        dispatchCrews.map((crew, index) => (
                          <p key={index}>{crew}</p>
                        ))}
                    </div>
                  </div>
                )}
                {dispatchVehicles && dispatchVehicles.length > 0 && (
                  <div className={calendarStyles['p-availabilityCard__item']}>
                    <i className="c-icon-outline-maps-bus"></i>

                    <div>
                      {dispatchVehicles &&
                        dispatchVehicles.map((vehicle, index) => (
                          <p key={index}>{vehicle}</p>
                        ))}
                    </div>
                  </div>
                )}
                {dispatchMiscResources && dispatchMiscResources.length > 0 && (
                  <div className={calendarStyles['p-availabilityCard__item']}>
                    <i className="c-icon-outline-general-info-circle"></i>
                    <div>
                      {dispatchMiscResources &&
                        dispatchMiscResources.map((resource, index) => (
                          <p key={index}>{resource}</p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </a>
        )}
    </>
  );
};

type Props = {
  items: ProductWithOccupancy[];
  columns: CustomTableColumn[];
  setEditingProductInstance: (arg0: any) => any;
  setReservationListContext: (arg0: any) => any;
  totalPaxByDate: Record<string, number>;
  onPageSizeChange: (arg0: number) => any;
  onCurrentPageChange: (arg0: number) => any;
};

export type CustomTableColumn = {
  accessor: string;
};

type ProductInstanceModalParams = {
  product: ProductWithOccupancyPlusColor;
  startTime: StartTimeAvailability;
  column: CustomTableColumn;
  availability: ProductDateAvailability;
};

const sortAndGroupProductsBasedOnStartTimes = (
  data: ProductWithOccupancyPlusColor[]
): ProductWithOccupancyByStartTime => {
  // Exclude elements with no startTimeAvailabilities.start_time_local
  const filteredData = data.filter(
    (item) => item.startTimeAvailabilities[0]?.start_time_local
  );

  // Flatten the array to handle multiple start_time_local values
  const flattenedData = filteredData.reduce((acc, item) => {
    return acc.concat(
      item.startTimeAvailabilities.map((availability) => ({
        ...item,
        startTimeAvailabilities: [availability],
      }))
    );
  }, [] as ProductWithOccupancyPlusColor[]);

  // Sort based on start times
  const sortedData = flattenedData.sort(
    (a, b) =>
      convertToMinutes(a.startTimeAvailabilities[0]?.start_time_local) -
      convertToMinutes(b.startTimeAvailabilities[0]?.start_time_local)
  );

  // Group based on start times rounded to the hour
  const groupedData = sortedData.reduce((result, item) => {
    const startTime = item.startTimeAvailabilities[0]?.start_time_local || '';
    const hour = startTime ? startTime.split(':')[0] + ':00' : ''; // Extract hour and round to nearest hour
    result[hour] = result[hour] || [];
    result[hour].push(item);
    return result;
  }, {} as ProductWithOccupancyByStartTime);

  return groupedData;
};

const convertToMinutes = (time: string | undefined): number => {
  if (!time) return 0;
  const [hour, minute] = time.split(':').map(Number);
  return hour * 60 + minute;
};

// Generate an array of hours between the first and last start times
const generateHoursOfStartTimes = (
  groupedVisibleItems: ProductWithOccupancyByStartTime
) => {
  if (Object.keys(groupedVisibleItems).length === 0) {
    return [];
  }
  const startTimes = Object.keys(groupedVisibleItems);
  const firstStartTime = startTimes[0];
  const lastStartTime = startTimes[startTimes.length - 1];
  const firstHour = parseInt(firstStartTime.split(':')[0]);
  const lastHour = parseInt(lastStartTime.split(':')[0]);
  return Array.from(
    { length: lastHour - firstHour + 1 },
    (_, index) => firstHour + index
  );
};

const wideColumnWidthIsUsed = (columnWidths: {
  [key: string]: string;
}): boolean => {
  for (const key in columnWidths) {
    if (columnWidths[key] !== defaultColumnWidth) {
      return true;
    }
  }
  return false;
};

export const AvailabilityCustomCalendar = ({
  items,
  columns,
  setEditingProductInstance,
  setReservationListContext,
  totalPaxByDate,
  onPageSizeChange,
  onCurrentPageChange,
}: Props) => {
  const defaultColumnWidths = {
    1: defaultColumnWidth,
    2: defaultColumnWidth,
    3: defaultColumnWidth,
    4: defaultColumnWidth,
    5: defaultColumnWidth,
    6: defaultColumnWidth,
    7: defaultColumnWidth,
  };
  const defaultColumnWidthsMobile = {
    1: defaultColumnWidthMobile,
    2: defaultColumnWidthMobile,
    3: defaultColumnWidthMobile,
    4: defaultColumnWidthMobile,
    5: defaultColumnWidthMobile,
    6: defaultColumnWidthMobile,
    7: defaultColumnWidthMobile,
  };
  const [columnWidths, setColumnWidths] = useState<{
    [key: string]: string;
  }>(defaultColumnWidths);

  // Attach listener for screen size change events to adjust the column width
  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia('(max-width: 1024px)');
      const newColumnWidth = mql.matches
        ? defaultColumnWidthMobile
        : defaultColumnWidth;

      setColumnWidths({
        1: newColumnWidth,
        2: newColumnWidth,
        3: newColumnWidth,
        4: newColumnWidth,
        5: newColumnWidth,
        6: newColumnWidth,
        7: newColumnWidth,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);

  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    onPageSizeChange(rowCount);
  }, [rowCount]);
  useEffect(() => {
    onCurrentPageChange(currentPage - 1);
  }, [currentPage]);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [productInstanceModalParams, setProductInstanceModalParams] =
    useState<ProductInstanceModalParams | null>(null);
  const [openProductInstanceModal, setOpenProductInstanceModal] =
    useState<boolean>(false);
  const [noData, setNoData] = useState<boolean>(false);

  const allProducts = useSelector(summariesSelector);
  const dispatch = useDispatch();
  const location = useLocation();

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );
  const instanceVisibilityByReservationSlot = useSelector(
    reservationSlotVisibilitySettingSelector
  );
  const instanceVisibilityByResource = useSelector(
    resourceVisibilitySettingSelector
  );

  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const clickedProductInstance: ProductInstance | null = useMemo(
    () =>
      productInstancesById[
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      ] ?? null,
    [
      productInstancesById,
      productInstanceModalParams?.availability?.product_instance_id,
    ]
  );
  const clickedProductInstanceShape: ProductInstanceShape | null = useMemo(
    () =>
      clickedProductInstance
        ? toProductInstanceShape(clickedProductInstance)
        : null,
    [clickedProductInstance]
  );

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  // Fetch product instance by ID whenever params change because we click on a card
  useEffect(() => {
    if (productInstanceModalParams?.availability?.product_instance_id) {
      dispatch(
        fetchProductInstanceByID(
          productInstanceModalParams?.availability?.product_instance_id
        )
      );
    }
  }, [productInstanceModalParams?.availability?.product_instance_id]);

  // ProductInstanceModal relies on child product instances existing in redux state in order to show these
  // reservations in the reservation list modal.
  useEffect(() => {
    if (clickedProductInstance) {
      const product = productSummaries.find(
        (product) => product.id === clickedProductInstance.product_id
      );

      const childProductIds =
        product?.shared_allotment_references?.child_product_ids ?? [];
      if (childProductIds.length > 0) {
        const startDate = moment.parseZone(
          clickedProductInstance.start_date_time_local
        );
        const endDate = moment
          .parseZone(clickedProductInstance.start_date_time_local)
          .add(1, 'days');
        dispatch(
          fetchProductInstancesForProducts(childProductIds, startDate, endDate)
        );
      }
    }
  }, [clickedProductInstance, productSummaries]);

  // Check DOM for availability card after visibility filtering
  useEffect(() => {
    const availabilityCard = document.querySelector(
      '[class*="p-availabilityCard"]'
    );
    if (!availabilityCard) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [
    instanceVisibilityByReservationSlot,
    instanceVisibilityByResource,
    items,
    location,
  ]);

  const getTotalPaxHeader = (column: CustomTableColumn) => {
    return (
      <>
        {typeof totalPaxByDate[column.accessor] === 'number' ? (
          <p>
            <span>{t('Total: ')}</span>
            {t('{{count}} pax', {
              count: totalPaxByDate[column.accessor],
            })}
          </p>
        ) : (
          <div style={{ marginTop: '8px' }}>
            <ModalLoader />
          </div>
        )}
      </>
    );
  };

  const toggleColumnWidth = (columnId: string) => {
    const mql = window.matchMedia('(max-width: 1024px)');

    setColumnWidths((prevWidths) => {
      const currentWidth = prevWidths[columnId];
      const columnWidth = mql.matches
        ? defaultColumnWidthMobile
        : adjustedColumnWidth;
      const newWidth =
        currentWidth === wideColumnWidth ? columnWidth : wideColumnWidth;

      // Recalculate the width of all columns except the clicked one
      const updatedWidths: { [key: string]: string } = {};
      Object.keys(prevWidths).forEach((key) => {
        updatedWidths[key] = key === columnId ? newWidth : columnWidth;
      });

      // If there is no wide column, reset the width of all columns
      if (
        updatedWidths[columnId] !== wideColumnWidth &&
        updatedWidths[columnId] !== defaultColumnWidth &&
        wideColumnWidthIsUsed(columnWidths)
      ) {
        return mql.matches ? defaultColumnWidthsMobile : defaultColumnWidths;
      }

      return updatedWidths;
    });
  };

  const itemsWithColor = assignColorsToProducts(items);
  const groupedVisibleItems =
    sortAndGroupProductsBasedOnStartTimes(itemsWithColor);

  // Iterate over each startdate and check if they have more than 4 items
  let shouldRenderShowAllButton = false;
  Object.entries(groupedVisibleItems).map(([, groupedItems]) => {
    if (groupedItems.length > 4) {
      shouldRenderShowAllButton = true;
    }
  });

  return (
    <ViewContext.Provider value={{ columnWidths }}>
      <div className={calendarStyles['p-calendarWeekly']}>
        {noData && (
          <div className={calendarStyles['p-calendar__noData']}>
            {t(
              'There is no reservation slots to show. Please adjust the filters and visibility settings.'
            )}
          </div>
        )}
        <>
          <div
            className={calendarStyles['p-calendarWeekly__sub']}
            style={{ display: noData ? 'none' : undefined }}
          >
            <ul className={calendarStyles['p-calendarWeekly__time']}>
              <li
                className={calendarStyles['p-calendarWeekly__time__header']}
                style={{
                  height: shouldRenderShowAllButton ? undefined : '64px',
                }}
              ></li>
              {Object.keys(groupedVisibleItems).length === 0 ? (
                <li
                  className={calendarStyles['p-calendarWeekly__time__item']}
                ></li>
              ) : (
                generateHoursOfStartTimes(groupedVisibleItems).map((key) => (
                  <li
                    key={key}
                    className={calendarStyles['p-calendarWeekly__time__item']}
                  >
                    <p>{`${key}:00`}</p>
                  </li>
                ))
              )}
            </ul>
          </div>
          <div
            className={calendarStyles['p-calendarWeekly__main']}
            style={{ display: noData ? 'none' : undefined }}
          >
            {columns.map((column, columnIndex) => {
              const columnProductInstanceIds: string[] = [];

              for (const item of items) {
                for (const availability of item.startTimeAvailabilities) {
                  const productInstanceId =
                    availability.date_availabilities?.find(
                      (dateAvailability) =>
                        dateAvailability.date === column.accessor
                    )?.product_instance_id;

                  if (productInstanceId) {
                    columnProductInstanceIds.push(productInstanceId);
                  }
                }
              }

              const columnIsChecked = columnProductInstanceIds.every((id) =>
                selectedProductInstanceIds.includes(id)
              );

              return (
                <div
                  key={columnIndex}
                  className={calendarStyles['p-calendarWeekly__item']}
                  style={{
                    width: columnWidths[columnIndex + 1],
                  }}
                >
                  <div
                    className={calendarStyles['p-calendarWeekly__item__header']}
                  >
                    <div
                      className={
                        calendarStyles['p-calendarWeekly__item__header__main']
                      }
                    >
                      <p
                        className={
                          calendarStyles[
                            'p-calendarWeekly__item__header__main__day'
                          ]
                        }
                      >
                        {getDateHeader(column, locale, dayMonthFormat)}
                      </p>
                      <p
                        className={
                          calendarStyles[
                            'p-calendarWeekly__item__header__main__total'
                          ]
                        }
                      >
                        {getTotalPaxHeader(column)}
                      </p>
                      {/* Checkbox to select the whole column */}
                      {calendarEditModeIsActive &&
                        typeof totalPaxByDate[column.accessor] === 'number' && (
                          <div
                            className={
                              calendarStyles['p-calendarWeekly__check']
                            }
                          >
                            <Checkbox
                              checked={columnIsChecked}
                              onChange={() => {
                                if (columnIsChecked) {
                                  deselectProductInstanceIds(
                                    ...columnProductInstanceIds
                                  );
                                } else {
                                  selectProductInstanceIds(
                                    ...columnProductInstanceIds
                                  );
                                }
                              }}
                              size="sm"
                            />
                          </div>
                        )}
                    </div>
                    {shouldRenderShowAllButton && (
                      <div
                        className={
                          calendarStyles[
                            'p-calendarWeekly__item__header_actions'
                          ]
                        }
                      >
                        <a
                          onClick={() =>
                            toggleColumnWidth(`${columnIndex + 1}`)
                          }
                        >
                          {columnWidths[columnIndex + 1] === wideColumnWidth
                            ? t('Show less')
                            : t('Show all')}
                        </a>
                      </div>
                    )}
                  </div>
                  <div
                    className={calendarStyles['p-calendarWeekly__item__body']}
                  >
                    <ul
                      className={
                        calendarStyles['p-calendarWeekly__item__body__cells']
                      }
                    >
                      {Object.keys(groupedVisibleItems).length === 0 ? (
                        <li
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '64px',
                          }}
                        >
                          {<ModalLoader />}
                        </li>
                      ) : availabilityLoading ? (
                        <>
                          {generateHoursOfStartTimes(groupedVisibleItems).map(
                            (key) => (
                              <li
                                key={key}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: '64px',
                                }}
                              >
                                {<ModalLoader />}
                              </li>
                            )
                          )}
                        </>
                      ) : (
                        generateHoursOfStartTimes(groupedVisibleItems).map(
                          (key) => <li key={key}></li>
                        )
                      )}
                    </ul>
                    <div
                      className={
                        calendarStyles['p-calendarWeekly__item__body__scheds']
                      }
                    >
                      {Object.keys(groupedVisibleItems).length === 0 ? (
                        <>{/* Do not show anything if items are empty */}</>
                      ) : availabilityLoading ? (
                        Object.entries(groupedVisibleItems).map(
                          ([startTime]) => {
                            return (
                              <div
                                key={startTime}
                                className={calendarStyles['p-availabilityCard']}
                                style={{ display: 'none' }}
                              ></div>
                            );
                          }
                        )
                      ) : (
                        Object.entries(groupedVisibleItems).map(
                          ([startTime, groupedItems]) => {
                            return (
                              <StartTimeAvailabilityDataCell
                                key={startTime}
                                items={groupedItems}
                                column={column}
                                onEditProductInstanceClick={(
                                  params: ProductInstanceModalParams
                                ) => {
                                  setProductInstanceModalParams(params);
                                  setOpenProductInstanceModal(true);
                                }}
                                columnIndex={columnIndex}
                                groupedVisibleItems={groupedVisibleItems}
                              />
                            );
                          }
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </div>
      <div className={calendarStyles['p-availabilityPagination']}>
        <Pagination
          totalItems={items.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
          disableNumberOfLinesToDisplay={true}
        />
      </div>

      {productInstanceModalParams &&
        clickedProductInstance &&
        clickedProductInstanceShape &&
        openProductInstanceModal && (
          <ProductInstanceModal
            productName={getDisplayProductName(
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )
            )}
            allotmentSettings={
              productInstanceModalParams.product.allotmentSettings
            }
            timezone={productInstanceModalParams.product.timezone}
            onEditClick={setEditingProductInstance}
            onListClick={() =>
              setReservationListContext({
                productIds: [
                  productInstanceModalParams.product.id,
                  ...productInstanceModalParams.product.childProducts.map(
                    (p) => p.id
                  ),
                ],
                rootProductInstanceId:
                  productInstanceModalParams.availability.product_instance_id ??
                  0,
                date: productInstanceModalParams.column.accessor,
              })
            }
            productInstance={clickedProductInstance}
            instance={clickedProductInstanceShape}
            onClose={() => {
              setOpenProductInstanceModal(false);
            }}
            shouldRejectBookingsBeyondCapacity={
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )?.request_booking_settings
                ?.should_reject_bookings_beyond_capacity ?? false
            }
          />
        )}
    </ViewContext.Provider>
  );
};
