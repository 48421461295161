import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { isYouTubeUrl } from 'client/components/NewProductEditor/util';
import baseStyles from 'client/base.module.css';

import styles from './YouTubeVideoEditorModal.module.css';

type Props = {
  onAddUrl: (url: string) => void;
  onClose: () => void;
};
export const YouTubeVideoEditorModal = ({ onAddUrl, onClose }: Props) => {
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = React.useState<string>('');
  React.useEffect(() => {
    setVideoUrl('');
  }, [open]);
  const isValidYouTubeUrl = videoUrl && isYouTubeUrl(videoUrl);
  return (
    <Modal
      insertRoot
      onClose={onClose}
      open={true}
      title={t('Add YouTube Video')}
    >
      <Modal.Content>
        <div
          className={clsx(
            styles['info-text'],
            baseStyles['base-margin-bottom-16']
          )}
        >
          {t(
            "For YouTube videos, please use the 'embed' link available from YouTube's 'Share' menu. Example: When URL to share YouTube video is \"https://youtu.be/-uUmxM1yKYg\" please enter \"https://www.youtube.com/embed/-uUmxM1yKYg\""
          )}
        </div>
        <div className={styles['edit-container']}>
          <div className={styles['edit-row']}>
            <div className={styles['input-cell']}>
              <div>
                <Input
                  label={'URL'}
                  value={videoUrl}
                  onChange={(e, { value }) => setVideoUrl(value)}
                />
                {isValidYouTubeUrl && (
                  <FieldWrapper label={t('Preview')}>
                    <iframe
                      width="500"
                      height="315"
                      src={videoUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </FieldWrapper>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={!isValidYouTubeUrl}
          style="green"
          size="middle"
          onClick={() => {
            onAddUrl(videoUrl);
          }}
        >
          {t('Add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
