import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { updateOrganization } from 'client/actions/organizations';
import { Button, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';

import styles from './Settings.module.css';

export const OrganizationUpdateNotificationSettings = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const [needReservationNotifications, setNeedReservationNotifications] =
    React.useState(
      !organization?.extranet_notification_settings
        ?.disable_reservation_notifications
    );
  const [needQrCheckinNotifications, setNeedQrCheckinNotifications] =
    React.useState(
      !organization?.extranet_notification_settings
        ?.disable_qr_checkin_notifications
    );
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const reset = React.useCallback(() => {
    setNeedReservationNotifications(
      !organization?.extranet_notification_settings
        ?.disable_reservation_notifications
    );
    setNeedQrCheckinNotifications(
      !organization?.extranet_notification_settings
        ?.disable_qr_checkin_notifications
    );
  }, [organization]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    reset();
  }, [reset]);

  if (!organization) {
    return null;
  }

  return (
    <div>
      <div className={styles['input-box']}>
        <ToggleButton
          label={t('Show notifications for new & updated reservations')}
          checked={needReservationNotifications}
          onChange={() =>
            setNeedReservationNotifications(!needReservationNotifications)
          }
        />
      </div>
      {hasSubscription(organization, 'feature-qr-checkin') &&
        !isSubscriptionCancelled(organization, 'feature-qr-checkin') && (
          <div className={styles['input-box']}>
            <ToggleButton
              label={t('Show notifications when guests check in with QR code')}
              checked={needQrCheckinNotifications}
              onChange={() =>
                setNeedQrCheckinNotifications(!needQrCheckinNotifications)
              }
            />
          </div>
        )}
      {error && <Message error header={t('Update failed')} content={error} />}
      {success && <Message success header={t('Update succeeded')} />}
      <div className={styles['buttons']}>
        <div className={styles['button-box']}>
          <Button style="gray" size="middle" onClick={reset}>
            {t('Discard')}
          </Button>
        </div>
        <div className={styles['button-box']}>
          <Button
            style="blue"
            size="middle"
            loading={loading}
            onClick={async () => {
              try {
                await dispatch(
                  updateOrganization(organization.id, organization.type, {
                    extranet_notification_settings: {
                      disable_reservation_notifications:
                        !needReservationNotifications,
                      disable_qr_checkin_notifications:
                        !needQrCheckinNotifications,
                    },
                  })
                );
                setSuccess(true);
                setError('');
              } catch (err) {
                setSuccess(false);
                setError(err as string);
              }
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
