// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import {
  confirmReservation,
  fetchReservationByID,
} from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import {
  convertToLocationWithMoment,
  getStartTime,
  convertLocationWithMomentToReservationLocationWithTimeInput,
} from 'client/libraries/util/coreutil';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import {
  getInstanceDedicatedAllotments,
  getReservationWeightedParticipantCount,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import type { ReduxState } from 'client/reducers';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import type { ConfirmReservationPatch } from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import {
  FieldWrapper,
  Button,
  TextArea,
  Checkbox,
  Input,
} from 'client/components/Form';
import { Divider } from 'client/components/Divider/Divider';
import { ModalLoader } from 'client/components/ModalLoader';

type OwnProps = {
  trigger: React.Element<'a' | 'button'>,
  reservationID: string,
  onConfirmCompleted?: (void) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  pickup: LocationWithMoment,
  dropoff: LocationWithMoment,
  checkin: LocationWithMoment,
  pickupDropoffUseSameLocation: boolean,
  supplier_notes: string,
  supplier_reference: string,
};

class ConfirmReservationButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { locale, reservation } = props;

    const timezone = (reservation && reservation.start_timezone) || '';
    let pickup = convertToLocationWithMoment(
      reservation.pickup,
      reservation.start_timezone || '',
      locale
    );
    if (!pickup?.locationDateTime) {
      pickup = {
        ...pickup,
        locationDateTime:
          reservation.start_date_time_utc &&
          reservation.start_timezone &&
          moment.tz(
            reservation.start_date_time_utc,
            reservation.start_timezone
          ),
      };
    }
    const dropoff = convertToLocationWithMoment(
      reservation && reservation.dropoff,
      timezone,
      locale
    );
    const checkin = convertToLocationWithMoment(
      reservation && reservation.checkin,
      timezone,
      locale
    );

    const pickupDropoffUseSameLocation =
      pickup.locationName === dropoff.locationName &&
      pickup.locationDescription === dropoff.locationDescription &&
      pickup.googlePlaceID === dropoff.googlePlaceID;

    this.state = {
      showModal: false,
      pickup,
      dropoff,
      checkin,
      pickupDropoffUseSameLocation,
      supplier_notes: '',
      supplier_reference: reservation && reservation.supplier_reference,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reservation &&
      prevProps.reservation !== this.props.reservation
    ) {
      const { locale, reservation } = this.props;

      const timezone = (reservation && reservation.start_timezone) || '';
      const pickup = convertToLocationWithMoment(
        reservation && reservation.pickup,
        timezone,
        locale
      );
      const dropoff = convertToLocationWithMoment(
        reservation && reservation.dropoff,
        timezone,
        locale
      );
      const checkin = convertToLocationWithMoment(
        reservation && reservation.checkin,
        timezone,
        locale
      );

      const pickupDropoffUseSameLocation =
        pickup.locationName === dropoff.locationName &&
        pickup.locationDescription === dropoff.locationDescription &&
        pickup.googlePlaceID === dropoff.googlePlaceID;

      this.setState({
        pickup,
        dropoff,
        checkin,
        pickupDropoffUseSameLocation,
      });

      this.fetchProduct();
      this.fetchProductInstance();
    }
  }

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }

    this.setState({
      showModal: true,
    });
  };

  fetchProduct = () => {
    const { reservation, product } = this.props;
    if (
      reservation &&
      reservation.product_id &&
      (!product || product.id !== reservation.product_id)
    ) {
      this.props.fetchProductByID(reservation.product_id);
    }
  };

  fetchProductInstance = () => {
    const { reservation, productInstance } = this.props;
    if (
      reservation &&
      reservation.product_instance_id &&
      (!productInstance ||
        productInstance.id !== reservation.product_instance_id)
    ) {
      this.props.fetchProductInstanceByID(reservation.product_instance_id);
    }
  };

  buildConfirmReservationRequest = (): ConfirmReservationPatch => {
    const { checkin, pickup, dropoff } = this.state;

    return {
      pickup:
        pickup &&
        convertLocationWithMomentToReservationLocationWithTimeInput(pickup),
      dropoff:
        dropoff &&
        convertLocationWithMomentToReservationLocationWithTimeInput(dropoff),
      checkin:
        checkin &&
        convertLocationWithMomentToReservationLocationWithTimeInput(checkin),
    };
  };

  handleAutofillPickupDropoff = () => {
    const { reservation } = this.props;
    const requestedPickup = reservation.requested_pickup_location;
    const requestedPickupLocationName =
      (requestedPickup && requestedPickup.location_name) || '';
    const requestedDropoff = reservation.requested_dropoff_location;
    const requestedDropoffLocationName =
      (requestedDropoff && requestedDropoff.location_name) || '';

    if (requestedPickupLocationName || requestedDropoffLocationName) {
      // If either requested pickup or requested dropoff are populated, autofill from those.
      const requestedPickupGooglePlaceId =
        (requestedPickup && requestedPickup.google_place_id) || '';
      const requestedDropoffGooglePlaceId =
        (requestedDropoff && requestedDropoff.google_place_id) || '';
      this.setState((prevState) => ({
        pickup: {
          ...prevState.pickup,
          locationName: requestedPickupLocationName,
          googlePlaceID: requestedPickupGooglePlaceId,
        },
        dropoff: {
          ...prevState.dropoff,
          locationName: requestedDropoffLocationName,
          googlePlaceID: requestedDropoffGooglePlaceId,
        },
      }));
    } else {
      // Otherwise, try to fill from customer hotel.
      const { reservation } = this.props;
      const guestHotel = reservation && reservation.guest_hotel;
      const locationName = (guestHotel && guestHotel.location_name) || '';
      const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

      if (locationName) {
        this.setState((prevState) => ({
          pickup: {
            ...prevState.pickup,
            locationName,
            googlePlaceID,
          },
          dropoff: {
            ...prevState.dropoff,
            locationName,
            googlePlaceID,
          },
        }));
      }
    }
  };

  handleAutofillCheckin = () => {
    const { reservation } = this.props;
    const guestHotel = reservation && reservation.guest_hotel;
    const locationName = (guestHotel && guestHotel.location_name) || '';
    const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

    if (locationName) {
      this.setState((prevState) => ({
        checkin: {
          ...prevState.checkin,
          locationName,
          googlePlaceID,
        },
      }));
    }
  };

  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState) => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.pickup;
        const dropoff = {
          locationID: pickup && pickup.locationID,
          locationName: pickup && pickup.locationName,
          locationDescription: pickup && pickup.locationDescription,
          googlePlaceID: pickup && pickup.googlePlaceID,
          locationDateTime: null,
        };

        return {
          pickupDropoffUseSameLocation: true,
          dropoff,
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };

  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState) => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.pickup;
        const dropoff = {
          locationID: pickup && pickup.locationID,
          locationName: pickup && pickup.locationName,
          locationDescription: pickup && pickup.locationDescription,
          googlePlaceID: pickup && pickup.googlePlaceID,
          locationDateTime: null,
        };

        return {
          pickupDropoffUseSameLocation: true,
          dropoff,
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  handleSupplierReferenceChange = (e) => {
    this.setState({ supplier_reference: e.target.value });
  };

  render() {
    const {
      confirmReservation,
      onConfirmCompleted,
      product,
      productInstance,
      reservation,
      loading,
      locale,
      t,
      trigger,
    } = this.props;
    const {
      checkin,
      dropoff,
      pickup,
      pickupDropoffUseSameLocation,
      supplier_notes,
      supplier_reference,
    } = this.state;
    const startTime = reservation && getStartTime(reservation).locale(locale);
    const timeSlotKey = productInstance?.time_slot_key ?? '';

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];
    const reservationID = (reservation && reservation.id) || '';
    const guestHotel = reservation && reservation.guest_hotel;
    const guestHotelName =
      (guestHotel && guestHotel.location_name) || t('(none)');
    const requestedPickup =
      reservation && reservation.requested_pickup_location;
    const requestedPickupLocationName =
      (requestedPickup && requestedPickup.location_name) || t('(none)');
    const requestedDropoff =
      reservation && reservation.requested_dropoff_location;
    const requestedDropoffLocationName =
      (requestedDropoff && requestedDropoff.location_name) || t('(none)');

    let confirmWarning = '';
    let bookedSlots = 0;
    let totalSlots = 0;
    if (reservation && product && productInstance) {
      const currentParticipantCount =
        productInstance.weighted_participant_count || 0;
      const resParticipantCount = getReservationWeightedParticipantCount(
        reservation,
        productInstance
      );
      const participantCountAfterCancel =
        currentParticipantCount + resParticipantCount;

      const minParticipantCount =
        (product.minimum_participant_count &&
          product.minimum_participant_count.value) ||
        0;
      if (
        minParticipantCount > 0 &&
        participantCountAfterCancel < minParticipantCount
      ) {
        confirmWarning = t(
          'This date and time has not yet met minimum participant requirements.'
        );
      }

      bookedSlots =
        (productInstance.occupied_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(productInstance),
          (a) => a.occupied_slots || 0
        );
      totalSlots =
        (productInstance.total_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(productInstance),
          (a) => a.total_slots || 0
        );
    }

    return (
      <Modal
        title={t('Confirm reservation')}
        trigger={trigger}
        open={this.state.showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={this.fetchReservation}
      >
        <Modal.Content>
          {loading ? (
            <ModalLoader />
          ) : (
            <>
              <Modal.Box>
                {confirmWarning && <Message warning content={confirmWarning} />}
                <p>
                  {t(
                    'Current occupied allotment: {{bookedSlots}} / {{totalSlots}}',
                    {
                      bookedSlots,
                      totalSlots: formattedTotalAllotmentSlots(totalSlots),
                    }
                  )}
                </p>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Product')}>{productName}</FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Start time')}>
                  {moment(startTime).format('lll')}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Total participants')}>
                  {guests.length}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Customer Hotel')}>
                  {guestHotelName}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Requested Pickup Location')}>
                  {requestedPickupLocationName}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Requested Dropoff Location')}>
                  {requestedDropoffLocationName}
                </FieldWrapper>
              </Modal.Box>

              {reservation &&
                (!reservationIsCheckinCheckoutOnly(reservation) ? (
                  <>
                    <Modal.Box>
                      <Button
                        size={'middle'}
                        style={'gray'}
                        onClick={this.handleAutofillPickupDropoff}
                      >
                        {t('Autofill pickup/dropoff')}
                      </Button>
                    </Modal.Box>

                    <LocationWithTimeEditFormFields
                      locationNameLabel={t('Pickup Location Name')}
                      locationDescriptionLabel={t(
                        'Pickup Location Description (ex: "Main lobby", "Car park", "Main wing")'
                      )}
                      locationTimeLabel={t('Pickup Time')}
                      locationDateLabel={t('Pickup Date')}
                      location={pickup}
                      productCandidateLocations={
                        (product && product.pickup) || []
                      }
                      startTime={startTime}
                      onLocationChange={(pickup) => {
                        this.setState({
                          pickup,
                        });
                      }}
                      timeSlotKey={timeSlotKey}
                    />
                    <Checkbox
                      label={t('Use same location for pickup and dropoff')}
                      checked={pickupDropoffUseSameLocation}
                      onChange={this.handleTogglePickupDropoffUseSameLocation}
                    />
                    {!pickupDropoffUseSameLocation && (
                      <LocationWithTimeEditFormFields
                        locationNameLabel={t('Dropoff Location Name')}
                        locationDescriptionLabel={t(
                          'Dropoff Location Description (ex: "Main lobby", "Car park", "Main wing")'
                        )}
                        locationTimeLabel={t('Dropoff Time')}
                        locationDateLabel={t('Dropoff Date')}
                        location={dropoff}
                        productCandidateLocations={
                          (product && product.dropoff) || []
                        }
                        startTime={startTime}
                        timeSlotKey={timeSlotKey}
                        onLocationChange={(dropoff) => {
                          this.setState({
                            dropoff,
                          });
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Modal.Box>
                      <Button
                        size={'middle'}
                        style={'gray'}
                        onClick={this.handleAutofillCheckin}
                      >
                        {t('Autofill checkin')}
                      </Button>
                    </Modal.Box>

                    <LocationWithTimeEditFormFields
                      locationNameLabel={t('Checkin Location Name')}
                      locationDescriptionLabel={t(
                        'Checkin Location Description (ex: "Main lobby", "Car park", "Main wing")'
                      )}
                      locationTimeLabel={t('Checkin Time')}
                      locationDateLabel={t('Checkin Date')}
                      location={checkin}
                      productCandidateLocations={
                        (product && product.checkin) || []
                      }
                      startTime={startTime}
                      timeSlotKey={timeSlotKey}
                      onLocationChange={(checkin) => {
                        this.setState({
                          checkin,
                        });
                      }}
                    />
                  </>
                ))}
            </>
          )}

          <Divider />

          <ReservationActorInputForm />
          <TextArea
            label={t('Replies')}
            value={supplier_notes}
            onChange={this.handleSupplierNotesChange}
          />
          <Input
            label={t('Confirmation Number')}
            value={this.state.supplier_reference}
            onChange={this.handleSupplierReferenceChange}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            style="green"
            size="middle"
            inverted
            onClick={() => {
              confirmReservation(
                reservationID,
                this.buildConfirmReservationRequest(),
                supplier_notes,
                supplier_reference
              ).then(() => onConfirmCompleted && onConfirmCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];

  return {
    locale: state.language.selected.iso,
    monthYearFormat: state.language.selected.monthYearFormat,
    reservation,
    product: reservation && state.products.byID[reservation.product_id || ''],
    productInstance:
      reservation &&
      state.productInstances.byID[reservation.product_instance_id],
    loading:
      state.products.loading ||
      state.productInstances.loading ||
      state.reservations.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
  fetchProductInstanceByID: (id: string) =>
    dispatch(fetchProductInstanceByID(id)),
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
  confirmReservation: (
    id: string,
    confirmReservationRequest: any,
    supplier_notes: string,
    supplier_reference: string
  ) =>
    dispatch(
      confirmReservation(
        id,
        confirmReservationRequest,
        supplier_notes,
        supplier_reference
      )
    ),
});

export const ConfirmReservationButtonV2 = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ConfirmReservationButtonComponent);
