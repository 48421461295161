import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './BookingsGadget.module.css';
import { ChangeLabel } from './ChangeLabel';

const colors = ['#3B82F6', '#60A5FA', '#93C5FD', '#BFDBFE', '#DBEAFE'];

export const OnSitePageViewGadget = () => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const existingDigitalMaps = useSelector(
    (state: ReduxState) => state.digitalMaps.all
  );

  const pins = React.useMemo(() => {
    if (!existingDigitalMaps) {
      return [];
    }

    return existingDigitalMaps.reduce((acc, cur) => {
      return [
        ...acc,
        ...((cur.pins ?? []).map((pin) => ({
          key: pin.key,
          title: pin.title,
        })) as { key: string; title: string }[]),
      ];
    }, [] as { key: string; title: string }[]);
  }, [existingDigitalMaps]);

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.onSiteReportData
  );
  const eventDataLoading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const loading = eventDataLoading || productsLoading;

  const baseDataSet = reportDataSets[0];
  const baseTotals = baseDataSet?.totals;

  const basePageViewTotal = (baseTotals?.view_count_by_pin ?? []).reduce(
    (acc, cur) => acc + (cur.view_count ?? 0),
    0
  );

  const baseViewCountByPin = baseTotals?.view_count_by_pin ?? [];

  const sortedViewCountByPin = _.orderBy(
    baseViewCountByPin,
    'view_count',
    'desc'
  );

  const pieData = [
    ...sortedViewCountByPin.slice(0, 4).map((viewCount, idx) => ({
      name: pins?.find((p) => p.key === viewCount.pin_key)?.title ?? '',
      value: viewCount.view_count,
      color: colors[idx],
    })),
  ];

  if (sortedViewCountByPin.length > 4) {
    const otherReservations = sortedViewCountByPin
      .slice(4)
      .reduce((acc, cur) => acc + (cur.view_count ?? 0), 0);

    pieData.push({
      name: t('Other'),
      value: otherReservations,
      color: colors[4],
    });
  }

  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;
  const comparisonTotals = comparisonDataSet?.totals;

  const comparisonPageViewTotal = (
    comparisonTotals?.view_count_by_pin ?? []
  ).reduce((acc, cur) => acc + (cur.view_count ?? 0), 0);

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Page View')}</div>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          {loading ? (
            <Loading size="md" />
          ) : (
            <>
              <div className={styles['chart']}>
                <div className={styles['amount']}>
                  <div className={styles['amount-inner']}>
                    <div className={styles['amount-text']}>
                      {basePageViewTotal.toLocaleString()}
                    </div>
                  </div>
                  {comparisonDataSet != null && (
                    <div className={styles['amount-inner']}>
                      <div className={styles['amount-unit']}>
                        {comparisonPageViewTotal.toLocaleString()}
                      </div>
                    </div>
                  )}
                </div>
                <ResponsiveContainer width="100%" height="100%" debounce={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      outerRadius="90%"
                      innerRadius="70%"
                      paddingAngle={0}
                      startAngle={225}
                      endAngle={-45}
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={index} fill={entry.color} />
                      ))}
                    </Pie>
                    <RechartsTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className={styles['text-section']}>
                {comparisonDataSet != null && (
                  <ChangeLabel
                    amountChange={basePageViewTotal - comparisonPageViewTotal}
                    unit={locale === 'ja' ? '件' : ''}
                  />
                )}
                {pieData.map((entry, index) => (
                  <div key={index} className={styles['legend-item']}>
                    <div
                      className={styles['legend-dot']}
                      style={{ backgroundColor: entry.color }}
                    />
                    <div>{entry.name}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
