import * as React from 'react';
import { Field } from 'react-final-form';

import { BaseTemplate } from './BaseTemplate';
import { HighlightedDateSection } from './HighlightedDateSection';
import {
  truncated,
  withQueryParams,
  ProductCardFields,
  ProductList,
} from './util';

const ImageEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ImageEditButton')
);
const HighlightedDateSectionEditButton = React.lazy(
  () =>
    import(
      'client/components/TemplateEditButtons/HighlightedDateSectionEditButton'
    )
);
const MainProductEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/MainProductEditButton')
);
const ProductListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ProductListEditButton')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  highlightedDateSection: {
    title: string;
    dates: string;
    availableSlots: string;
    style: string;
  };
  mainProduct: ProductCardFields;
  productList: ProductList;
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const SellOut = ({
  editMode,
  language,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  keyVisualUrl,
  headline,
  headlineDescription,
  highlightedDateSection,
  mainProduct,
  productList,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* お客様向けメッセージ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1em .5em',
              backgroundColor: '#fff',
            }}
          >
            <p style={{ marginTop: '.5em' }}>
              {customerName
                ? customerIntroduction.replace('{customerName}', customerName)
                : customerIntroduction}
              <br />
              <br />

              {editMode && (
                <Field name="templateValues.introParagraph">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <span style={{ whiteSpace: 'pre-line' }}>{introParagraph}</span>
            </p>
          </div>
        </td>
      </tr>
      {/* 当店一番人気のプランは残席わずか！ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '0em .5em 1em .5em',
              backgroundColor: '#ffffff',
            }}
          >
            {editMode && (
              <Field name="templateValues.keyVisualUrl">
                {({ input }) => (
                  <ImageEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <div
              style={{
                width: '100%',
                marginBottom: '1em',
                background: '#ddd',
                overflow: 'hidden',
              }}
            >
              <img src={keyVisualUrl} style={{ width: '100%' }} />
            </div>
            {editMode && (
              <Field name="templateValues.headline">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                padding: '.5em',
                fontWeight: 'bold',
                borderRadius: '4px',
                color: '#ffffff',
                backgroundColor: '#4A6C7A',
                whiteSpace: 'pre-line',
              }}
            >
              {headline}
            </p>
            {editMode && (
              <Field name="templateValues.headlineDescription">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: '1em 0 0 0',
                padding: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {headlineDescription}
            </p>
            {/* もうすぐ満席になる日程 1 */}
            {editMode && (
              <Field name="templateValues.highlightedDateSection">
                {({ input }) => (
                  <HighlightedDateSectionEditButton
                    onSave={(newValue) => input.onChange(newValue)}
                    initialValue={input.value}
                  />
                )}
              </Field>
            )}
            {highlightedDateSection && (
              <HighlightedDateSection
                data={highlightedDateSection}
                language={language}
              />
            )}
            {editMode && (
              <Field name="templateValues.mainProduct">
                {({ input }) => (
                  <MainProductEditButton
                    onSave={(newValue) => input.onChange(newValue)}
                    initialValue={input.value}
                    language={language}
                  />
                )}
              </Field>
            )}
            <a
              href={withQueryParams(mainProduct.productUrl, {
                utm_source: 'ntmg.newsletter',
                utm_medium: 'email',
                utm_campaign: newsletterCampaignId ?? 'todo',
                utm_content: 'mainProduct',
                lng: language,
              })}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '100%',
                margin: '1em 0 0 0',
                padding: 0,
                background: '#F9F9F9',
                textDecoration: 'none',
              }}
            >
              <div
                style={{
                  margin: '2em 0.5em 1em',
                  position: 'relative',
                  verticalAlign: 'middle',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={mainProduct.productThumbnailUrl}
                  style={{
                    width: '100%',
                  }}
                />
              </div>
              <div
                style={{
                  padding: '.5em',
                  color: '#333333',
                  verticalAlign: 'middle',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '1.5em',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {mainProduct.productName}
                </p>
                <p
                  style={{
                    margin: '.5em 0 0 0',
                    padding: 0,
                    fontSize: '12px',
                    color: '#4A6C7A',
                    lineHeight: '1.5em',
                  }}
                >
                  {truncated(mainProduct.productDescription, 200)}
                </p>
              </div>
            </a>
            <div style={{ margin: '1em auto 0 auto' }}>
              <a
                href={withQueryParams(mainProduct.productUrl, {
                  utm_source: 'ntmg.newsletter',
                  utm_medium: 'email',
                  utm_campaign: newsletterCampaignId ?? 'todo',
                  utm_content: 'mainProduct',
                  lng: language,
                })}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: '320px',
                  border: '1px solid #008EC9',
                  borderRadius: '48px',
                  textDecoration: 'none',
                  padding: '2px',
                  display: 'block',
                  margin: '0 auto',
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    display: 'block',
                    fontWeight: 'bold',
                    width: '100%',
                    height: '48px',
                    lineHeight: '48px',
                    fontSize: '20px',
                    backgroundColor: '#008EC9',
                    color: '#ffffff',
                    textDecoration: 'none',
                    textAlign: 'center',
                    borderRadius: '40px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {mainProduct.buttonText}
                </span>
              </a>
            </div>
          </div>
        </td>
      </tr>
      {/* スペース */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {editMode && (
        <Field name="templateValues.productList">
          {({ input }) => (
            <ProductListEditButton
              initialValue={productList}
              onSave={(newValue) => input.onChange(newValue)}
              language={language}
            />
          )}
        </Field>
      )}
      {/* その他のおすすめプラン */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1em .5em',
              backgroundColor: '#ffffff',
            }}
          >
            <div style={{ margin: 0, padding: 0 }}>
              <p
                style={{
                  margin: 0,
                  padding: '.5em',
                  fontWeight: 'bold',
                  color: '#ffffff',
                  backgroundColor: '#FFCC00',
                  borderRadius: '4px',
                  whiteSpace: 'pre-line',
                }}
              >
                {productList.title}
              </p>
              {productList.products.map((product, idx) => (
                <div key={idx}>
                  <a
                    href={withQueryParams(product.productUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: newsletterCampaignId ?? 'todo',
                      utm_content: 'productList',
                      lng: language,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                      display: 'block',
                    }}
                  >
                    <div
                      style={{
                        margin: '2em 0.5em 1em',
                        position: 'relative',
                        verticalAlign: 'middle',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={product.productThumbnailUrl}
                        style={{
                          width: '100%',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '.5em',
                        color: '#333333',
                        verticalAlign: 'middle',
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '16px',
                          fontWeight: 'bold',
                          lineHeight: '1.5em',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {product.productName}
                      </p>
                      <p
                        style={{
                          margin: '.5em 0 0 0',
                          padding: 0,
                          fontSize: '12px',
                          color: '#4A6C7A',
                          lineHeight: '1.5em',
                        }}
                      >
                        {truncated(product.productDescription, 200)}
                      </p>

                      <p
                        style={{
                          margin: '1em auto 0 auto',
                          padding: 0,
                          display: 'block',
                          fontWeight: 'bold',
                          width: '100%',
                          height: '32px',
                          lineHeight: '32px',
                          backgroundColor: '#008EC9',
                          color: '#ffffff',
                          textDecoration: 'none',
                          textAlign: 'center',
                          borderRadius: '24px',
                          whiteSpace: 'pre-line',
                          maxWidth: '320px',
                        }}
                      >
                        {product.buttonText}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </td>
      </tr>
    </BaseTemplate>
  );
};
