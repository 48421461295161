import * as React from 'react';
import _ from 'lodash';
import { FalconUIRenderingContext } from '@nutmeglabs/falcon-ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { Message } from 'client/components/Message/Message';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { FieldWrapper, Input, Select } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { customPagesSelector } from 'client/reducers/customPages';
import { getBookingWidgetProductSummaries } from 'client/libraries/util/getBookingWidgetProductSummaries';

const encodeCategoryNameForURI = (categoryName: string): string => {
  // We need to replace '%' with '%25' since next.js does not escape '%'
  return encodeURIComponent(categoryName.replace('%', '%25'));
};

interface Props {
  name: string;
}

export const LinkEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const customPages = useSelector(customPagesSelector);

  const { language } = React.useContext(FalconUIRenderingContext);
  const organization = useSelector(activeUserOrganizationSelector);

  const typeOptions = [
    {
      value: 'PRODUCT',
      label: t('Product'),
    },
    {
      value: 'CUSTOM_PAGE',
      label: t('Custom Page'),
    },
    {
      value: 'FULL_URL',
      label: t('Full URL'),
    },
  ];

  const customPageOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];

  const pmp = organization?.private_marketplaces?.find(
    (pmp) => pmp.language === language
  );

  const pmpOptions = [
    {
      key: '/top/popular',
      value: '/top/popular',
      text: t('Ranking'),
    },
    {
      key: '/top/recommended',
      value: '/top/recommended',
      text: t('Recommended'),
    },
  ];
  pmp?.category_pages?.forEach((categoryPage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      text: categoryPage.display_name || categoryPage.name || '',
    })
  );
  pmp?.feature_pages?.forEach((featurePage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      text: featurePage.display_name || featurePage.name || '',
    })
  );

  const products = useSelector((state: ReduxState) => state.products.summaries);

  const productOptions = getBookingWidgetProductSummaries(
    organization,
    products,
    language
  ).map((p) => ({
    value: p.id ?? '',
    text: p.product_name ?? '',
  }));

  if (pmp) {
    typeOptions.push({
      value: 'PRIVATE_MARKETPLACE',
      label: t('Select a Private Marketplace page'),
    });
  }

  if (hasSubscription(organization, 'feature-customer-ledger')) {
    typeOptions.push({
      value: 'LOGIN_PAGE',
      label: t('Login / My Page'),
    });
  }

  const form = useForm();
  const { values } = form.getState();

  const linkType = _.get(values, `${name}.type`);

  return (
    <>
      <FieldWrapper label={t('Link To')}>
        <EnumRadioButtonGroup
          name={`${name}.type`}
          options={typeOptions}
          onChange={(newValue) => {
            form.change(`${name}.productId`, '');
            form.change(`${name}.customPagePath`, '');
            form.change(`${name}.fullUrl`, '');
            form.change(`${name}.privateMarketplacePath`, '');
            if (newValue === 'LOGIN_PAGE') {
              form.change('title', t('Login / My Page'));
            } else {
              form.change('title', t('[TITLE]'));
            }
          }}
        />
      </FieldWrapper>
      {linkType === 'PRODUCT' && (
        <Field name={`${name}.productId`}>
          {({ input }) => (
            <Select
              search
              options={productOptions}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      )}
      {linkType === 'CUSTOM_PAGE' && (
        <Field name={`${name}.customPagePath`}>
          {({ input }) => (
            <Select
              search
              options={customPageOptions}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      )}
      {linkType === 'FULL_URL' && (
        <Field name={`${name}.fullUrl`}>
          {({ input }) => (
            <Input value={input.value} onChange={input.onChange} />
          )}
        </Field>
      )}
      {linkType === 'PRIVATE_MARKETPLACE' && (
        <Field name={`${name}.privateMarketplacePath`}>
          {({ input }) => (
            <Select
              search
              options={pmpOptions}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      )}
      {linkType === 'LOGIN_PAGE' && (
        <Message warning>
          {t(
            `"Login" or "My Page" menu will be displayed depending on the customer's login status. You cannot change the text of the  Login/My Page menu.`
          )}
        </Message>
      )}
    </>
  );
};
