// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button, Form, Image, Message, Segment } from 'semantic-ui-react';
import type { TranslatorProps } from 'react-i18next';

import { s3MediaStorage } from 'client/libraries/s3';
import { activeUserSelector } from 'client/reducers/user';
import { config } from 'client/config';

type OwnProps = {
  filename: string,
  organizationID: string,
  organizationType: 'agent' | 'supplier',
  onChange: (newFilename: string) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  file: ?File,
  error: ?Error,
  uploadTimestamp: number,
};

const iconFilename = 'favicon.ico';

class IconInputComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      error: null,
      uploadTimestamp: 0,
    };
  }

  getUploadedFileURL = () => {
    const { organizationID, organizationType } = this.props;

    return `${config.s3Config.mediaPathRoot}/logos/${organizationType}/${organizationID}/${iconFilename}`;
  };

  getFileType = () => {
    const { file } = this.state;

    if (!file) return '';

    return file.type;
  };

  uploadFile = () => {
    const { organizationID, organizationType } = this.props;
    const { file } = this.state;

    if (!file) return;

    const objName = `logos/${organizationType}/${organizationID}/${iconFilename}`;
    s3MediaStorage
      .put(objName, file)
      .then(() =>
        s3MediaStorage
          .get(objName)
          .then(() => {
            // Remove pre-signed suffix
            this.setState({
              error: null,
              file: null,
              uploadTimestamp: new Date().getTime(),
            });
          })
          .catch((err) => {
            throw err;
          })
      )
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  render() {
    const { t } = this.props;

    const { error, uploadTimestamp } = this.state;

    const uploadedFileURL = this.getUploadedFileURL();

    return (
      <Segment>
        <label>
          {t('Select icon file (ico)')}
          <Form.Group inline>
            <Form.Input
              type="file"
              accept=".ico"
              onChange={(e) =>
                this.setState({
                  file: e.target.files[0],
                })
              }
            />
            <Button onClick={this.uploadFile}>{t('Upload')}</Button>
          </Form.Group>
          {error && (
            <Message
              negative
              header={t('Upload failed')}
              content={error.message}
            />
          )}
        </label>
        {uploadedFileURL && (
          <Form.Field>
            <label>{t('Preview')}</label>

            {/* trigger rerender via new key if a new file is uploaded*/}
            <Image key={uploadTimestamp} src={uploadedFileURL} size="small" />
          </Form.Field>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeUser: activeUserSelector(state),
});

export const IconInput = compose(
  withTranslation(),
  connect(mapStateToProps, {})
)(IconInputComponent);
