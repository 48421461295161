import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'client/pages/Loading';
import { fetchReservationVoucher } from 'client/actions/reservationVoucher';
import { ReduxState } from 'client/reducers';

export const ReservationVoucher = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.reservationVoucher.loading
  );
  const html = useSelector(
    (state: ReduxState) => state.reservationVoucher.html
  );
  React.useEffect(() => {
    if (id) {
      dispatch(fetchReservationVoucher(id));
    }
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
