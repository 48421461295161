import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import styles from 'client/components/TranslationTableHeader/TranslationTableHeader.module.css';

type Props = {
  hideThTag?: boolean;
};

export const TranslationTableHeader = ({ hideThTag }: Props) => {
  const { t } = useTranslation();

  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );

  const editingProduct = React.useContext(EditingProductContext);

  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'en';

  return translationTargetLanguage ? (
    <tr
      className={clsx(
        styles['page-productsTable__header'],
        styles['base-translate-row']
      )}
    >
      {hideThTag ? null : <th />}
      <td>
        {sourceLanguage && getLanguageName(lowerCaseIsoSourceLanguage, t)}
      </td>
      <td>
        {translationTargetLanguage &&
          getLanguageName(
            uppercaseIsoToLowercaseIso[translationTargetLanguage],
            t
          )}
      </td>
    </tr>
  ) : null;
};
