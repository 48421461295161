import { combineReducers } from 'redux';

import {
  SET_RESERVATION_DATA_CSV_EXPORT_ORDER_VISIBLE_COLUMNS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';

const defaultVisibleColumns: string[] = [];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_RESERVATION_DATA_CSV_EXPORT_ORDER_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

type State = { visibleColumns: string[] };
const reducer = combineReducers<State>({
  visibleColumns,
});
export const reservationDataCsvExportOrderTableControls = (
  state: State,
  action: any
) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
