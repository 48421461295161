import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getValidators } from 'shared/libraries/validate/validator';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';
import { PerParticipantPriceInput } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/PerParticipantPriceInput';
import { PerGroupPriceInput } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/PerGroupPriceInput';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { Badge } from 'client/components/v3/Common/Badge';

type ServiceInputProps = {
  name: string;
};

const ServiceInput = ({ name }: ServiceInputProps) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);

  const { translationTargetLanguage } = useTranslationTargetLanguage(t);
  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  const language =
    uppercaseIsoToLowercaseIso[editingProduct?.source_language || 'JA_JP'];

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  return (
    <>
      <CollapsibleBox title={t('Title')}>
        <TranslatedField name={`${name}.title`} validate={required}>
          {({ input, meta: { error, touched }, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <div className={styles['p-list__item__ttl__txt']}>
                    <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                    <Badge
                      size="sm"
                      label={t('Required')}
                      color="warning-contained"
                    />
                  </div>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextField
                    {...input}
                    error={Boolean(touched && error)}
                    helperText={error}
                  />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextField {...translationInput} />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>
      <CollapsibleBox title={t('Description')}>
        <TranslatedField name={`${name}.description`}>
          {({ input, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextArea {...(input as any)} height={80} />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextArea {...(translationInput as any)} height={80} />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>
      <Field name={`${name}.priceType`}>
        {({ input: { value } }) => (
          <>
            {/* Only show translation for pricing when type is 'PER_PARTICIPANT' as other types don't have translations */}
            {value === 'PER_PARTICIPANT' && (
              <CollapsibleBox title={t('Pricing')}>
                <li className={styles['p-list__item']}>
                  {value === 'PER_PARTICIPANT' && (
                    <>
                      <PerParticipantPriceInput
                        name={`${name}.prices`}
                        language={language}
                        currency={defaultCurrency}
                        showCustom={true}
                        showPerGroup={true}
                      />
                      <PerGroupPriceInput
                        name={`${name}.perGroupPrices`}
                        currency={defaultCurrency}
                      />
                    </>
                  )}
                </li>
              </CollapsibleBox>
            )}
          </>
        )}
      </Field>
    </>
  );
};

type ServicesEditorProps = {
  name: 'addOns' | 'transportations';
  getItemLabel: (idx: number) => string;
};
export const ServicesEditor = ({ name, getItemLabel }: ServicesEditorProps) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((name, idx) => (
              <CollapsibleBox key={name} title={getItemLabel(idx + 1)}>
                <ServiceInput name={name} />
              </CollapsibleBox>
            ))}
        </>
      )}
    </FieldArray>
  );
};
