import { Field, useField, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useState, useContext, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { getStartTimesFromRecurrence } from 'client/libraries/util/getStartTimesFromRecurrence';
import { timePlusOffset } from 'client/libraries/util/util';
import { DurationInput } from 'client/components/v3/DurationInput/DurationInput';
import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { LocationListEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/LocationListEditor';
import {
  isDropoffType,
  isPickupType,
  PerStartTimeOffset,
} from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/FormValues';
import type {
  LocationType,
  LocationWithTime,
} from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/FormValues';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { convertFormValuesToRecurrence } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { Recurrence } from 'shared/models/swagger';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { config } from 'client/config';

const isPickupCheckinType = (type: LocationType): boolean => {
  return (
    type === 'PICKUP_DROPOFF' ||
    type === 'PICKUP_ONLY' ||
    type === 'CHECKIN_CHECKOUT' ||
    type === 'CHECKIN_ONLY'
  );
};

const isDropoffCheckoutType = (type: LocationType): boolean => {
  return (
    type === 'PICKUP_DROPOFF' ||
    type === 'DROPOFF_ONLY' ||
    type === 'CHECKIN_CHECKOUT' ||
    type === 'CHECKOUT_ONLY'
  );
};

const getDefaultPickupLocation = (): LocationWithTime => {
  return {
    type: 'PICKUP_DROPOFF',
    timeOffsetCheckinPickup: '0:00',
    timeOffsetCheckoutDropoff: '0:00',
    location: {
      locationName: '',
      googlePlaceId: '',
    },
    description: '',
    imageUrls: [],
    perStartTimeOffsets: [],
    premappedLocations: [],
  };
};

type PerStartTimeOffsetsEditorProps = {
  name: string;
  type: LocationType;
};

const PerStartTimeOffsetsEditor = ({
  name,
  type,
}: PerStartTimeOffsetsEditorProps) => {
  const { t } = useTranslation();
  const [selectedTimeSlotKey, setSelectedTimeSlotKey] = useState<string | null>(
    null
  );
  const editingProduct = useContext(EditingProductContext);

  const { reservationParamsFormValues } = useContext(ProductHelperContext);
  const recurrenceFromForm = convertFormValuesToRecurrence([
    {
      dateRanges: reservationParamsFormValues?.dateRanges ?? [],
      weekdays: reservationParamsFormValues?.weekdays ?? [],
      closedDates: reservationParamsFormValues?.closedDates ?? [],
      startTimes: reservationParamsFormValues?.startTimes ?? [],
      key: uuidv4(),
    },
  ]) as Recurrence[];

  const startTimes = useMemo(
    () =>
      getStartTimesFromRecurrence(
        editingProduct?.recurrence || recurrenceFromForm
      ),
    [editingProduct, recurrenceFromForm]
  );
  const { input: perStartTimeOffsets } = useField<PerStartTimeOffset[]>(name);
  const unusedStartTimes = useMemo(() => {
    return startTimes.filter(
      (startTime) =>
        !perStartTimeOffsets.value?.some(
          (perStartTimeOffset) =>
            perStartTimeOffset.timeSlotKey === startTime.timeSlotKey
        )
    );
  }, [perStartTimeOffsets.value, startTimes]);
  useEffect(() => {
    setSelectedTimeSlotKey(
      unusedStartTimes.length > 0 ? unusedStartTimes[0].timeSlotKey : null
    );
  }, [unusedStartTimes]);

  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return (
          <>
            <div className={styles['p-list__item__body']}>
              <div className={styles['p-list__item__body__flex']}>
                {unusedStartTimes.length > 0 && (
                  <>
                    <SingleDropdown
                      options={unusedStartTimes.map((startTime) => ({
                        text: startTime.hhMm,
                        value: startTime.timeSlotKey,
                      }))}
                      selectedOption={selectedTimeSlotKey ?? ''}
                      onChange={(value) => {
                        setSelectedTimeSlotKey(value);
                      }}
                    />
                    <Button
                      text={t('Add rule for start time')}
                      size="md"
                      color="secondary"
                      iconBeforeText={
                        <i className="c-icon-outline-general-plus-circle"></i>
                      }
                      onClick={() => {
                        if (selectedTimeSlotKey != null) {
                          fields.push({
                            timeSlotKey: selectedTimeSlotKey,
                            timeOffsetCheckinPickup: '0:00',
                            timeOffsetCheckoutDropoff: '0:00',
                          });
                        }
                      }}
                      disabled={selectedTimeSlotKey == null}
                    />
                  </>
                )}
              </div>
            </div>
            {fields.map((name, idx) => (
              <Field key={name} name={name}>
                {({ input }) => {
                  const startTime = startTimes.find(
                    (startTime) =>
                      startTime.timeSlotKey === input.value.timeSlotKey
                  );

                  return (
                    <div className={styles['p-frame']}>
                      <div className={styles['p-frame__header']}>
                        <p className={styles['p-frame__header__ttl']}>
                          {startTime?.hhMm ?? ''}
                        </p>
                        <div className={styles['p-frame__header__actions']}>
                          <Button
                            size="icon"
                            color="tertiarygray"
                            onClick={() => fields.remove(idx)}
                            iconBeforeText={
                              <i className="c-icon-outline-general-trash-03"></i>
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={clsx(
                          styles['p-frame__body'],
                          styles['gray']
                        )}
                      >
                        <ul className={styles['p-list']}>
                          {isPickupCheckinType(type) && (
                            <li className={styles['p-list__item']}>
                              <Field name={`${name}.timeOffsetCheckinPickup`}>
                                {({ input }) => (
                                  <>
                                    <div
                                      className={styles['p-list__item__ttl']}
                                    >
                                      <div
                                        className={
                                          styles['p-list__item__ttl__txt']
                                        }
                                      >
                                        {isPickupType(type)
                                          ? t('Pickup Time Offset: ')
                                          : t('Checkin Time Offset: ')}
                                      </div>
                                    </div>
                                    <div
                                      className={clsx(
                                        styles['p-list__item__body'],
                                        styles['flex']
                                      )}
                                      style={{ width: '100%' }}
                                    >
                                      <DurationInput
                                        showSignSelect
                                        required
                                        {...input}
                                      />
                                      <div className={baseStyles['u-mt-2']}>
                                        <p style={{ marginRight: '4px' }}>
                                          {t('Displayed Time: ')}
                                        </p>
                                        <span
                                          className={
                                            styles[
                                              'p-checkinPickup__displayTime'
                                            ]
                                          }
                                        >
                                          {timePlusOffset(
                                            moment(
                                              startTime?.hhMm || '',
                                              'H:mm'
                                            ),
                                            input.value
                                          ).format('H:mm')}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Field>
                            </li>
                          )}
                          {isDropoffCheckoutType(type) && (
                            <li className={styles['p-list__item']}>
                              <Field name={`${name}.timeOffsetCheckoutDropoff`}>
                                {({ input }) => (
                                  <>
                                    <div
                                      className={styles['p-list__item__ttl']}
                                    >
                                      <div
                                        className={
                                          styles['p-list__item__ttl__txt']
                                        }
                                      >
                                        {isDropoffType(type)
                                          ? t('Dropoff Time Offset: ')
                                          : t('Checkout Time Offset: ')}
                                      </div>
                                    </div>
                                    <div
                                      className={clsx(
                                        styles['p-list__item__body'],
                                        styles['flex']
                                      )}
                                      style={{ width: '100%' }}
                                    >
                                      <DurationInput
                                        showSignSelect
                                        required
                                        {...input}
                                      />
                                      <div className={baseStyles['u-mt-2']}>
                                        <p style={{ marginRight: '4px' }}>
                                          {t('Displayed Time: ')}
                                        </p>
                                        <span
                                          className={
                                            styles[
                                              'p-checkinPickup__displayTime'
                                            ]
                                          }
                                        >
                                          {timePlusOffset(
                                            moment(
                                              startTime?.hhMm || '',
                                              'H:mm'
                                            ),
                                            input.value
                                          ).format('H:mm')}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Field>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                }}
              </Field>
            ))}
          </>
        );
      }}
    </FieldArray>
  );
};

type CheckinPickupLocationInputProps = {
  name: string;
  startTimes: string[];
  type: LocationType;
};

const CheckinPickupLocationInput = ({
  name,
  startTimes,
  type,
}: CheckinPickupLocationInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName } = useTranslationTargetLanguage(t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>{t('Type:')}</div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name={`${name}.type`}>
            {({ input }) => (
              <SingleDropdown
                options={[
                  {
                    text: t('Pickup/Dropoff'),
                    value: 'PICKUP_DROPOFF',
                  },
                  {
                    text: t('Pickup Only'),
                    value: 'PICKUP_ONLY',
                  },
                  {
                    text: t('Dropoff Only'),
                    value: 'DROPOFF_ONLY',
                  },
                  {
                    text: t('Checkin/Checkout'),
                    value: 'CHECKIN_CHECKOUT',
                  },
                  {
                    text: t('Checkin Only'),
                    value: 'CHECKIN_ONLY',
                  },
                  {
                    text: t('Checkout Only'),
                    value: 'CHECKOUT_ONLY',
                  },
                ]}
                selectedOption={input.value}
                onChange={(value) => input.onChange(value)}
              />
            )}
          </Field>
        </div>
      </li>
      {isPickupCheckinType(type) && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {isPickupType(type)
                ? t('Pickup Time Offset: ')
                : t('Checkin Time Offset: ')}
            </div>
          </div>
          <div className={clsx(styles['p-list__item__body'], styles['row'])}>
            <Field name={`${name}.timeOffsetCheckinPickup`}>
              {({ input }) => (
                <>
                  <Toggle
                    label={
                      isPickupType(type)
                        ? t('Leave Pickup Time Offset Blank')
                        : t('Leave Checkin Time Offset Blank')
                    }
                    onChange={() => {
                      input.onChange(input.value ? '' : '0:00');
                    }}
                    checked={!input.value}
                    size="sm"
                  />
                  {input.value && (
                    <div
                      className={clsx(
                        styles['p-list__item__body'],
                        styles['flex']
                      )}
                      style={{ width: '100%' }}
                    >
                      <DurationInput required showSignSelect {...input} />
                      <div className={baseStyles['u-mt-2']}>
                        <p style={{ marginRight: '4px' }}>
                          {t('Displayed Time: ')}
                        </p>
                        <span
                          className={styles['p-checkinPickup__displayTime']}
                        >
                          {startTimes
                            .map((startTime) => {
                              const st = moment(startTime, 'H:mm');
                              return timePlusOffset(st, input.value).format(
                                'H:mm'
                              );
                            })
                            .join(',')}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
        </li>
      )}
      {isDropoffCheckoutType(type) && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {isDropoffType(type)
                ? t('Dropoff Time Offset: ')
                : t('Checkout Time Offset: ')}
            </div>
          </div>
          <div className={clsx(styles['p-list__item__body'], styles['row'])}>
            <Field name={`${name}.timeOffsetCheckoutDropoff`}>
              {({ input }) => (
                <>
                  <Toggle
                    label={
                      isDropoffType(type)
                        ? t('Leave Dropoff Time Offset Blank')
                        : t('Leave Checkout Time Offset Blank')
                    }
                    onChange={() => {
                      input.onChange(input.value ? '' : '0:00');
                    }}
                    checked={!input.value}
                    size="sm"
                  />
                  {input.value && (
                    <div
                      className={clsx(
                        styles['p-list__item__body'],
                        styles['flex']
                      )}
                      style={{ width: '100%' }}
                    >
                      <DurationInput showSignSelect {...input} />
                      <div className={baseStyles['u-mt-2']}>
                        <p style={{ marginRight: '4px' }}>
                          {t('Displayed Time: ')}
                        </p>
                        <span
                          className={styles['p-checkinPickup__displayTime']}
                        >
                          {startTimes
                            .map((startTime) => {
                              const st = moment(startTime, 'H:mm');
                              return timePlusOffset(st, input.value).format(
                                'H:mm'
                              );
                            })
                            .join(',')}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
        </li>
      )}
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Location')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field
            name={`${name}.location`}
            validate={(value: any | null | undefined): string => {
              if (!value?.locationName) return t('Required');
              return '';
            }}
          >
            {({ input, meta: { touched, error } }) => (
              <LocationSearchInput
                location={input.value.locationName}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSearchChange={() => {}}
                onLocationSelect={({ title, key }) =>
                  input.onChange({
                    locationName: title,
                    googlePlaceId: key,
                  })
                }
                error={touched && error}
                maxWidth={800}
              />
            )}
          </Field>
          {/* TODO: not sure why this translation input is different from the rest */}
          {translationTargetLanguageName && (
            <div>
              <div>{`${t('Location')} (${translationTargetLanguageName})`}</div>
              <div>
                <TranslatedField name={`${name}.location.locationName`}>
                  {({ translationInput }) => (
                    <TextField {...translationInput} />
                  )}
                </TranslatedField>
              </div>
            </div>
          )}
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Description')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name={`${name}.description`}>
            {({ input, translationInput }) => (
              <>
                <TextArea
                  value={input.value}
                  onChange={input.onChange}
                  height={80}
                  fullContainerWidth={true}
                />
                {translationTargetLanguageName && (
                  <TextArea
                    value={translationInput.value}
                    onChange={translationInput.onChange}
                    height={80}
                  />
                )}
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Images')}
            <Tooltip text={t('Images will be shown only on guest emails')}>
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name={`${name}.imageUrls`}>
            {({ input }) => (
              <div>
                <ImageVideoAudioInput
                  fileUrls={input.value ?? []}
                  onChange={(newValue) => input.onChange(newValue)}
                  disableYoutubeVideos
                />
              </div>
            )}
          </Field>
        </div>
      </li>
      {isPickupType(type) && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {t('Pre-mapped Hotels')}
              <Tooltip
                text={t(
                  "By default, Nutmeg will assign the closest pickup location for a customer's hotel. To override this default behavior, a hotel may be added to the pre-mapped hotel list so that it is always mapped to a certain pickup location rather than the closest."
                )}
              >
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <LocationListEditor name={`${name}.premappedLocations`} />
          </div>
        </li>
      )}
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Register different settings per start time (optional)')}
          </div>
        </div>
        <PerStartTimeOffsetsEditor
          name={`${name}.perStartTimeOffsets`}
          type={type}
        />
      </li>
      {isNutmegAdmin && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {t('Third Party Reference')}
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <Field name={`${name}.referenceInSupplierSystem`}>
              {({ input }) => <TextField {...input} />}
            </Field>
          </div>
        </li>
      )}
    </ul>
  );
};

export const CheckinPickupLocationsEditor = () => {
  const { t } = useTranslation();
  const { translationTargetLanguageName } = useTranslationTargetLanguage(t);
  const formState = useFormState();
  const { reservationParamsFormValues } = useContext(ProductHelperContext);

  const recurrence = convertFormValuesToRecurrence(
    formState.values.availabilityAllotmentSchedules
  );

  let startTimes = getStartTimesFromRecurrence(recurrence as Recurrence[]).map(
    (startTime) => startTime.hhMm || ''
  );
  if (startTimes.length === 0) {
    startTimes =
      reservationParamsFormValues?.startTimes.map(
        (startTime) => startTime.time || ''
      ) ?? [];
  }

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Guest Email Pickup Instructions')}
            <Tooltip
              text={t(
                'Instructions will be shown only on guest emails. The same instructions will be used for all pickup/checkin locations.'
              )}
            >
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name="guestEmailPickupInstructions">
            {({ input, translationInput }) => (
              <>
                <TextArea
                  value={input.value}
                  onChange={input.onChange}
                  height={80}
                  fullContainerWidth={true}
                />
                {translationTargetLanguageName && (
                  <TextArea
                    value={translationInput.value}
                    onChange={translationInput.onChange}
                    height={80}
                  />
                )}
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Guest Email Checkin Instructions')}
            <Tooltip
              text={t(
                'Instructions will be shown only on guest emails. The same instructions will be used for all pickup/checkin locations.'
              )}
            >
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name="guestEmailCheckinInstructions">
            {({ input, translationInput }) => (
              <>
                <TextArea
                  value={input.value}
                  onChange={input.onChange}
                  height={80}
                  fullContainerWidth={true}
                />
                {translationTargetLanguageName && (
                  <TextArea
                    value={translationInput.value}
                    onChange={translationInput.onChange}
                    height={80}
                  />
                )}
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      {config.enablePickupLocation && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {t('Selecting Pickup Location')}
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <Field name={`shouldShowPickupLocationSelector`}>
              {({ input }) => (
                <>
                  <Toggle
                    label={t(
                      'Allow customers to select from the registered pickup locations.'
                    )}
                    onChange={() => {
                      input.onChange(!input.value);
                    }}
                    checked={input.value}
                    size="sm"
                  />
                </>
              )}
            </Field>
          </div>
        </li>
      )}

      <FieldArray name="checkinPickupLocations">
        {({ fields }) => (
          <>
            {fields.length !== 0 &&
              fields.map((name, idx) => (
                <Field key={name} name={name}>
                  {({ input }) => (
                    <CollapsibleBox
                      key={name}
                      title={
                        input.value.type === 'PICKUP_DROPOFF'
                          ? t('Pickup/Dropoff')
                          : input.value.type === 'PICKUP_ONLY'
                          ? t('Pickup')
                          : input.value.type === 'DROPOFF_ONLY'
                          ? t('Dropoff')
                          : input.value.type === 'CHECKIN_CHECKOUT'
                          ? t('Checkin/Checkout')
                          : input.value.type === 'CHECKIN_ONLY'
                          ? t('Checkin')
                          : t('Checkout')
                      }
                      onDelete={() => fields.remove(idx)}
                    >
                      <CheckinPickupLocationInput
                        name={name}
                        type={input.value.type}
                        startTimes={startTimes}
                      />
                    </CollapsibleBox>
                  )}
                </Field>
              ))}
            <div className={fields.length === 0 ? '' : baseStyles['u-mt-4']}>
              <a
                className={styles['p-box__table2__actions__add']}
                onClick={() => {
                  if (fields.length && fields.length > 0) {
                    (fields as any).insertAt(
                      fields.length + 1,
                      getDefaultPickupLocation()
                    );
                  } else {
                    (fields as any).insertAt(0, getDefaultPickupLocation());
                  }
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Checkin/Pickup Location')}
              </a>
            </div>
          </>
        )}
      </FieldArray>
    </ul>
  );
};
