import {
  RealtimeNotificationSettings,
  Organization,
} from 'shared/models/swagger';

export interface FormValues {
  enableWebPush: boolean;
}

export const convertToSwaggerNotificationSettings = (
  values: FormValues
): RealtimeNotificationSettings => {
  return {
    enable_web_push_notifications: values.enableWebPush,
  };
};

export const getInitialValues = (org: Organization | null): FormValues => {
  return {
    enableWebPush:
      org?.realtime_notification_settings?.enable_web_push_notifications ??
      false,
  };
};
