import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { updateManifestCustomViews } from 'client/actions/manifestSettings';
import { manifestCustomViewsSelector } from 'client/reducers/manifestSettings';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import type { ReservationColumn } from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type Props = {
  existingView?: {
    key?: string;
    order_by?: ReservationColumn[];
    columns?: ReservationColumn[];
  } | null;
  open: boolean;
  onClose: () => void;
};
const sortOptions: {
  value: ReservationColumn;
  text: string;
}[] = [
  {
    value: 'SUPPLIER_REFERENCE',
    text: 'Confirmation Number',
  },
  {
    value: 'AGENT_REFERENCE',
    text: 'Application Number',
  },
  {
    value: 'PICKUP_CHECKIN_TIME',
    text: 'Pickup/Checkin time',
  },
  {
    value: 'SUPPLIER_PRODUCT_REFERENCE',
    text: 'Product Code',
  },
  {
    value: 'GUEST_DISPLAY_NAME',
    text: 'Customer',
  },
  {
    value: 'GIVEN_NAME',
    text: 'Given Name',
  },
  {
    value: 'FAMILY_NAME',
    text: 'Family Name',
  },
];
export const EditCustomViewModal = ({ existingView, open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [key, setKey] = useState<string>('');
  const [columns, setColumns] = useState<ReservationColumn[]>([]);
  const [orderBy, setOrderBy] = useState<ReservationColumn[]>([]);
  const existingCustomViews = useSelector(manifestCustomViewsSelector);
  const allManifestColumns = useAllManifestColumns();

  useEffect(() => {
    if (existingView) {
      setKey(existingView?.key || '');
      setOrderBy(existingView?.order_by || []);
      setColumns(existingView?.columns || []);
    } else {
      setKey('');
      setColumns([]);
    }
  }, [existingView]);

  const headerText = existingView
    ? t('Edit Manifest View')
    : t('Create New Manifest View');

  return (
    <Modal
      title={headerText}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button text={t('Cancel')} color="white" onClick={onClose} />
          <Button
            text={t('Save')}
            disabled={!key}
            onClick={() => {
              const newCustomViews = [
                ...existingCustomViews.filter((view) => view.key !== key),
                {
                  key,
                  columns,
                  order_by: orderBy,
                },
              ];
              dispatch(updateManifestCustomViews(newCustomViews));
              onClose();
            }}
          />
        </>
      }
      style={{ width: '600px', height: '800px' }}
    >
      <div className={styles['p-manifestsModalCustomize']}>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Name')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <TextField
              disabled={Boolean(existingView)}
              value={key}
              onChange={(value) => setKey(value)}
            />
          </div>
        </div>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Sort')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <SingleDropdown
              options={sortOptions.map((option) => {
                return { ...option, text: t(option.text) };
              })}
              selectedOption={orderBy.length > 0 ? orderBy[0] : ''}
              onChange={(value) => {
                if (value) {
                  setOrderBy([value as ReservationColumn]);
                }
              }}
            />
          </div>
        </div>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Columns')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <ToggleableDragAndDropList
              selectedItems={columns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
              setSelectedItems={(selectedItems) =>
                setColumns(selectedItems.map((i) => i.key))
              }
              candidateItems={allManifestColumns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
