import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { activeUserSelector } from 'client/reducers/user';
import { ExportToCSVButton } from 'client/pages/InvoiceRevamped/ExportToCSVButton';
import { DownloadInvoiceButton } from 'client/pages/InvoiceRevamped/DownloadInvoiceButton';
import { InvoiceFareAdjustmentModal } from 'client/pages/InvoiceRevamped/InvoiceFareAdjustmentModal';
import { BillingInfoFormModal } from 'client/pages/InvoiceRevamped/BillingInfoFormModal';
import { LineItemDropdown } from 'client/pages/InvoiceDetailsRevamped/LineItemDropdown';
import { TransactionTable } from 'client/pages/InvoiceDetailsRevamped/TransactionTable';
import { LineItemTable } from 'client/pages/InvoiceDetailsRevamped/LineItemTable';
import { SummaryTable } from 'client/pages/InvoiceDetailsRevamped/SummaryTable';
import {
  fetchInvoiceByID,
  confirmInvoice,
  markInvoiceAsPaid,
} from 'client/actions/invoices';
import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { ReduxState } from 'client/reducers/index';
import { operationAllowed } from 'shared/models/access';
import { Loading } from 'client/pages/Loading';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import type {
  BusinessLineItem,
  Invoice,
  Transaction,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './InvoiceDetails.module.css';
import { InvoiceTableSettingsModal } from './InvoiceTableSettingsModal';
import { EditSupplierAddressModal } from './EditSupplierAddressModal';
import { EditAgentAddressModal } from './EditAgentAddressModal';

interface AmountDueByEntities {
  amountDueByAgent?: string;
  amountDueBySupplier?: string;
}

export const InvoiceDetails = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const [showEditSupplierAddressModal, setShowEditSupplierAddressModal] =
    React.useState(false);
  const [showEditAgentAddressModal, setShowEditAgentAddressModal] =
    React.useState(false);
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const dispatch = useDispatch();
  const invoice = useSelector(
    (state: ReduxState) => state.invoices.byID[params.id]
  );
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const loading = useSelector((state: ReduxState) => state.invoices.loading);
  React.useEffect(() => {
    dispatch(fetchInvoiceByID(params.id));
  }, [params.id, invalidated]);

  const parseField = (field: string, start: number, end: number) => {
    return field.slice(start, end);
  };

  const getFormattedTimeFromUTC = (dateTimeUTC?: string) => {
    return (
      dateTimeUTC &&
      moment(dateTimeUTC, 'YYYY-MM-DD').locale(locale).format('YYYY-MM-DD')
    );
  };

  const getInvoiceFareAdjustmentDueByEntities = (
    transaction: Transaction
  ): AmountDueByEntities => {
    const amountDueByEntities: AmountDueByEntities = {};

    switch (transaction && transaction.payer_type) {
      case 'AGENT_ENTITY_TYPE':
        amountDueByEntities.amountDueByAgent = transaction.amount;
        amountDueByEntities.amountDueBySupplier = '';
        break;

      case 'SUPPLIER_ENTITY_TYPE':
        amountDueByEntities.amountDueByAgent = '';
        amountDueByEntities.amountDueBySupplier = transaction.amount;
        break;

      default:
        amountDueByEntities.amountDueByAgent = '';
        amountDueByEntities.amountDueBySupplier = '';
        break;
    }

    return amountDueByEntities;
  };

  const getBusinessLineItemsOfType = (
    invoiceBusinessLineItems: BusinessLineItem[],
    lineItemsType:
      | 'INVOICE_ADJUSTMENT'
      | 'PAID_IN_FULL'
      | 'PAY_ON_BOARD'
      | 'PAID_PARTIALLY'
      | 'SUMMARY'
  ) => {
    let typeAttribute: 'business_line_item_type' | 'payment_type' =
      'business_line_item_type';

    switch (lineItemsType) {
      case 'INVOICE_ADJUSTMENT':
        typeAttribute = 'business_line_item_type';
        break;

      case 'PAID_IN_FULL':
      case 'PAY_ON_BOARD':
      case 'PAID_PARTIALLY':
        typeAttribute = 'payment_type';
        break;

      default:
        return [];
    }

    return (
      invoiceBusinessLineItems &&
      typeAttribute &&
      invoiceBusinessLineItems.filter(
        (businessLineItem) => businessLineItem[typeAttribute] === lineItemsType
      )
    );
  };

  const getRenderedSummaryTable = (
    invoiceBusinessLineItems: BusinessLineItem[]
  ) => {
    const fareAdjustmentBusinessLineItems = getBusinessLineItemsOfType(
      invoiceBusinessLineItems,
      'INVOICE_ADJUSTMENT'
    );
    const businessLineItemsPIF = getBusinessLineItemsOfType(
      invoiceBusinessLineItems,
      'PAID_IN_FULL'
    );
    const businessLineItemsPOB = getBusinessLineItemsOfType(
      invoiceBusinessLineItems,
      'PAY_ON_BOARD'
    );
    const businessLineItemsSP = getBusinessLineItemsOfType(
      invoiceBusinessLineItems,
      'PAID_PARTIALLY'
    );
    return (
      <SummaryTable
        invoiceBusinessLineItems={invoiceBusinessLineItems}
        fareAdjustmentBusinessLineItems={fareAdjustmentBusinessLineItems}
        businessLineItemsPIF={businessLineItemsPIF}
        businessLineItemsPOB={businessLineItemsPOB}
        businessLineItemsSP={businessLineItemsSP}
        getInvoiceFareAdjustmentDueByEntities={
          getInvoiceFareAdjustmentDueByEntities
        }
      />
    );
  };

  const getRenderedTransactionTable = (businessLineItem: BusinessLineItem) => {
    return (
      <TransactionTable
        businessLineItem={businessLineItem}
        getFormattedTimeFromUTC={getFormattedTimeFromUTC}
        getInvoiceFareAdjustmentDueByEntities={
          getInvoiceFareAdjustmentDueByEntities
        }
      />
    );
  };

  const getRenderedLineItemTable = (
    businessLineItemsOfPaymentType: BusinessLineItem[]
  ) => {
    return (
      <LineItemTable
        businessLineItemsOfPaymentType={businessLineItemsOfPaymentType}
        getRenderedTransactionTable={getRenderedTransactionTable}
        getRenderedTotalAmount={getRenderedTotalAmount}
        parseField={parseField}
      />
    );
  };

  const getRenderedInvoiceLineItemsTypeTable = (
    invoiceBusinessLineItems: BusinessLineItem[],
    lineItemsType:
      | 'PAID_IN_FULL'
      | 'PAY_ON_BOARD'
      | 'INVOICE_ADJUSTMENT'
      | 'PAID_PARTIALLY'
      | 'SUMMARY'
  ) => {
    const businessLineItemsOfPaymentType = getBusinessLineItemsOfType(
      invoiceBusinessLineItems,
      lineItemsType
    );

    switch (lineItemsType) {
      case 'PAID_IN_FULL':
      case 'PAY_ON_BOARD':
      case 'PAID_PARTIALLY':
        return (
          businessLineItemsOfPaymentType &&
          businessLineItemsOfPaymentType[0] &&
          getRenderedLineItemTable(businessLineItemsOfPaymentType)
        );

      case 'INVOICE_ADJUSTMENT':
        return (
          businessLineItemsOfPaymentType &&
          businessLineItemsOfPaymentType[0] &&
          getRenderedTransactionTable(businessLineItemsOfPaymentType[0])
        );

      case 'SUMMARY':
        return (
          invoiceBusinessLineItems &&
          invoiceBusinessLineItems[0] &&
          getRenderedSummaryTable(invoiceBusinessLineItems)
        );

      default:
        return null;
    }
  };

  const getInvoiceStatusText = (status: string) => {
    switch (status) {
      case 'INVOICE_PENDING':
        return t('Pending');

      case 'INVOICE_ISSUED':
        return t('Issued');

      case 'INVOICE_LOCKED':
        return t('Locked');

      case 'INVOICE_IN_PROGRESS':
        return t('Payment in progress');

      case 'INVOICE_PAID':
        return t('Invoice Paid');

      case 'INVOICE_PAYMENT_FAILED':
        return t('Payment failed');

      case 'INVOICE_VOIDED':
        return t('Voided');

      default:
        return status;
    }
  };

  const getPaymentMethodText = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'CREDIT_CARD':
        return t('Credit Card');

      case 'DEBIT_CARD':
        return t('Debit Card');

      case 'CASH':
        return t('Cash');

      case 'BANK':
        return t('Bank');

      case 'CHECK':
        return t('Check');

      case 'OTHER':
        return t('Other');

      default:
        return paymentMethod;
    }
  };

  const getRenderedInvoiceLineItemTypeDropdowns = (
    invoiceBusinessLineItems: BusinessLineItem[],
    lineItemsTypes: (
      | 'SUMMARY'
      | 'PAID_IN_FULL'
      | 'PAY_ON_BOARD'
      | 'PAID_PARTIALLY'
      | 'INVOICE_ADJUSTMENT'
    )[]
  ) => {
    return lineItemsTypes.map((lineItemsType) => {
      const renderedTable = getRenderedInvoiceLineItemsTypeTable(
        invoiceBusinessLineItems,
        lineItemsType
      );
      let openTableName = '';
      let closedTableName = '';

      switch (lineItemsType) {
        case 'SUMMARY':
          openTableName = t('Invoice Summary');
          closedTableName = t('View Invoice Summary');
          break;

        case 'PAID_IN_FULL':
          openTableName = t('PIF Details');
          closedTableName = t('View PIF Details');
          break;

        case 'PAY_ON_BOARD':
          openTableName = t('POB Details');
          closedTableName = t('View POB Details');
          break;

        case 'PAID_PARTIALLY':
          openTableName = t('Split Payment Details');
          closedTableName = t('View Split Payment Details');
          break;

        case 'INVOICE_ADJUSTMENT':
          openTableName = t('Invoice Adjustments');
          closedTableName = t('View Invoice Adjustments');
          break;

        default:
          return null;
      }

      if (!renderedTable) {
        return null;
      }

      return (
        <LineItemDropdown
          key={lineItemsType}
          openTableName={openTableName}
          closedTableName={closedTableName}
          content={() => renderedTable}
          defaultOpen={lineItemsType === 'SUMMARY'}
        />
      );
    });
  };

  const getRenderedTotalAmount = (totalAmount: string, moneyType: string) => {
    return (
      <Box display="flex">
        <div className={styles['total-amount']}>
          {moneyType} {t('Total')}: {getFormattedAmount(totalAmount)}
        </div>
      </Box>
    );
  };

  const getInvoiceStatusButton = (invoice: Invoice) => {
    switch (invoice && invoice.status) {
      case 'INVOICE_PENDING':
        return (
          <BillingInfoFormModal
            invoiceId={invoice && invoice.id}
            includeDetails={true}
          />
        );

      case 'INVOICE_ISSUED':
        return (
          <Button.Submit
            onClick={() => {
              dispatch(confirmInvoice(invoice?.id ?? '', true));
            }}
          >
            {t('Confirm')}
          </Button.Submit>
        );

      case 'INVOICE_LOCKED':
        return (
          <Button.Submit
            onClick={() => {
              dispatch(markInvoiceAsPaid(invoice?.id ?? '', true));
            }}
          >
            {t('Mark as paid')}
          </Button.Submit>
        );

      default:
        return null;
    }
  };

  const getInvoiceAdjustmentButton = (invoice: Invoice) => {
    switch (invoice && invoice.status) {
      case 'INVOICE_PENDING':
        return (
          <InvoiceFareAdjustmentModal
            header={t('Fare Adjustment')}
            trigger={(props) => (
              <Button style="yellow" size="middle" {...props}>
                {t('Adjust')}
              </Button>
            )}
            invoice={{
              id: invoice && invoice.id,
              payer_id: invoice?.payer_id ?? '',
              payer_name: invoice?.payer_name ?? '',
              payer_type: invoice?.payer_type ?? 'AGENT_ENTITY_TYPE',
              payee_id: invoice?.payee_id ?? '',
              payee_name: invoice?.payee_name ?? '',
              payee_type: invoice?.payee_type ?? 'SUPPLIER_ENTITY_TYPE',
              amount: parseFloat(
                invoice?.total_amount?.slice(
                  3,
                  invoice?.total_amount?.length
                ) ?? '0'
              ),
              currency: invoice?.total_amount?.slice(0, 3) ?? '',
            }}
            includeDetails={true}
          />
        );

      case 'INVOICE_ISSUED':
        return (
          <>
            {activeUser &&
              operationAllowed(
                activeUser,
                'write',
                'postIssuanceInvoiceFareAdjustment'
              ) && (
                <InvoiceFareAdjustmentModal
                  header={t('Fare Adjustment')}
                  trigger={(props) => (
                    <Button style="yellow" size="middle" {...props}>
                      {t('Adjust')}
                    </Button>
                  )}
                  invoice={{
                    id: invoice && invoice.id,
                    payer_id: invoice?.payer_id ?? '',
                    payer_name: invoice?.payer_name ?? '',
                    payer_type: invoice?.payer_type ?? 'AGENT_ENTITY_TYPE',
                    payee_id: invoice?.payee_id ?? '',
                    payee_name: invoice?.payee_name ?? '',
                    payee_type: invoice?.payee_type ?? 'SUPPLIER_ENTITY_TYPE',
                    amount: parseFloat(
                      invoice?.total_amount?.slice(
                        3,
                        invoice?.total_amount?.length
                      ) ?? '0'
                    ),
                    currency: invoice?.total_amount?.slice(0, 3) ?? '',
                  }}
                  includeDetails={true}
                />
              )}
          </>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!invoice) {
    return <div>{t('Invoice not found')}</div>;
  }

  const createdAtDateTime = getFormattedTimeFromUTC(
    invoice.creation_date_time_utc
  );
  const lastUpdatedAtDateTime = getFormattedTimeFromUTC(
    invoice.last_updated_date_time_utc
  );
  const billingStartDateTime = getFormattedTimeFromUTC(
    invoice.billing_period_start_date_time_utc
  );
  const billingEndDateTime = getFormattedTimeFromUTC(
    invoice.billing_period_end_date_time_utc
  );
  const dueDateTime = getFormattedTimeFromUTC(invoice.due_date_time_utc);
  const renderedInvoiceStatusButton = getInvoiceStatusButton(invoice);
  const renderedInvoiceAdjustmentButton = getInvoiceAdjustmentButton(invoice);
  const invoiceBusinessLineItems = invoice.business_line_items ?? [];
  const renderedInvoiceDropdowns = getRenderedInvoiceLineItemTypeDropdowns(
    invoiceBusinessLineItems,
    [
      'SUMMARY',
      'PAID_IN_FULL',
      'PAY_ON_BOARD',
      'PAID_PARTIALLY',
      'INVOICE_ADJUSTMENT',
    ]
  );
  const renderedInvoiceTotalAmount = getRenderedTotalAmount(
    invoice.total_amount ?? '',
    t('Invoice')
  );
  return (
    <div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {invoice.payee_reference
              ? t('Invoice #{{id}} ({{payee_ref}})', {
                  id: invoice.id,
                  payee_ref: invoice.payee_reference,
                })
              : t('Invoice #{{id}}', {
                  id: invoice.id,
                })}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Box display="flex" ml={-2} mt={-2}>
            <div className={styles['header-buttons-row']}>
              <Box ml={2} mt={2}>
                <DownloadInvoiceButton invoiceIds={[invoice.id]} />
              </Box>
              <Box ml={2} mt={2}>
                <ExportToCSVButton invoiceIds={[invoice.id]} />
              </Box>
              <Box ml={2} mt={2}>
                <InvoiceTableSettingsModal />
              </Box>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'BILLING.INVOICES'
              ) && (
                <>
                  <Box ml={2} mt={2}>
                    {renderedInvoiceStatusButton}
                  </Box>
                  <Box ml={2} mt={2}>
                    {renderedInvoiceAdjustmentButton}
                  </Box>
                </>
              )}
            </div>
          </Box>
          <Box mt={4} maxWidth="400px">
            <table className={clsx(baseStyles['base-table'])}>
              <tbody>
                {createdAtDateTime && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Created At')}>
                      {createdAtDateTime}
                    </FieldWrapper>
                  </Box>
                )}
                {lastUpdatedAtDateTime && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Last Updated At')}>
                      {lastUpdatedAtDateTime}
                    </FieldWrapper>
                  </Box>
                )}
                {billingStartDateTime && billingEndDateTime && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Billing Period')}>
                      {`${billingStartDateTime} - ${billingEndDateTime}`}
                    </FieldWrapper>
                  </Box>
                )}
                {dueDateTime && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Due at')}>
                      {dueDateTime}
                    </FieldWrapper>
                  </Box>
                )}
                {invoice.payment_method && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Payment Method')}>
                      {getPaymentMethodText(invoice.payment_method ?? '')}
                    </FieldWrapper>
                  </Box>
                )}
                {invoice.status && (
                  <Box mb={1}>
                    <FieldWrapper label={t('Status')}>
                      {getInvoiceStatusText(invoice.status ?? '')}
                    </FieldWrapper>
                  </Box>
                )}
                {invoice.remarks && (
                  <Box>
                    <FieldWrapper label={t('Additional Notes')}>
                      {invoice.remarks}
                    </FieldWrapper>
                  </Box>
                )}
              </tbody>
            </table>
          </Box>
          <Box mt={4} mb={4} display="flex">
            <div>
              {invoice.payee_id && invoice.payee_type && (
                <>
                  <Box mb={1}>
                    <div
                      className={baseStyles['base-selectFrame__header__ttl']}
                    >
                      {t('Issued by')}
                    </div>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div>
                      {invoice.payee_name && (
                        <div>
                          {t('{{payee_name}}', {
                            payee_name: invoice.payee_name,
                          })}
                        </div>
                      )}
                      {invoice.payee_address?.map((line, idx) => (
                        <div key={idx}>{line}</div>
                      ))}
                    </div>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'BILLING.INVOICES'
                    ) && (
                      <Box ml={2}>
                        <EditIcon
                          onClick={() => setShowEditSupplierAddressModal(true)}
                        />
                      </Box>
                    )}
                    {showEditSupplierAddressModal && (
                      <EditSupplierAddressModal
                        initialValue={invoice.payee_address ?? []}
                        onClose={() => setShowEditSupplierAddressModal(false)}
                        onSave={async (address: string[]) => {
                          await dispatch(
                            updateActiveUserOrganization({
                              invoice_formatted_mailing_address: address,
                            })
                          );
                          await dispatch(fetchInvoiceByID(params.id));
                        }}
                      />
                    )}
                  </Box>
                </>
              )}
            </div>
            <Box ml="auto">
              {invoice.payer_id && invoice.payer_type ? (
                <>
                  <Box mb={1}>
                    <div
                      className={baseStyles['base-selectFrame__header__ttl']}
                    >
                      {t('Bill to')}
                    </div>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div>
                      {invoice.payer_name && (
                        <div>
                          {t('{{payer_name}}', {
                            payer_name: invoice.payer_name,
                          })}
                        </div>
                      )}
                      {invoice.payer_address?.map((line, idx) => (
                        <div key={idx}>{line}</div>
                      ))}
                    </div>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'BILLING.INVOICES'
                    ) && (
                      <Box ml={2}>
                        <EditIcon
                          onClick={() => setShowEditAgentAddressModal(true)}
                        />
                      </Box>
                    )}
                    {showEditAgentAddressModal && (
                      <EditAgentAddressModal
                        agentId={invoice.payer_id}
                        initialValue={invoice.payer_address ?? []}
                        onClose={() => setShowEditAgentAddressModal(false)}
                        onSave={async () => {
                          await dispatch(fetchInvoiceByID(params.id));
                        }}
                      />
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
          {renderedInvoiceDropdowns}
          {renderedInvoiceTotalAmount}
        </div>
      </div>
    </div>
  );
};
