import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { InstantWin } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { Tabs } from 'client/components/Tabs/Tabs';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { deleteInstantWin, fetchInstantWins } from 'client/actions/instantWins';
import { fetchProducts } from 'client/actions/products';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';

import { TotalStats } from './TotalStats';
import { TiersStats } from './TiersStats';
import { EditInstantWinModal } from './EditInstantWinModal';

export const InstantWinList = () => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const columns = useColumns();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchInstantWins());
  }, []);

  const instantWins = useSelector((state: ReduxState) => state.instantWins.all);
  const { t } = useTranslation();
  const tabPanes = React.useMemo(() => {
    const publishedPages = _.orderBy(
      _.orderBy(
        instantWins.filter((p) => p.status === 'INSTANT_WIN_ACTIVE'),
        (g) => g?.page_design_contents?.title
      ),
      (g) => g.language
    );
    const pendingPages = _.orderBy(
      _.orderBy(
        instantWins.filter((p) => p.status !== 'INSTANT_WIN_ACTIVE'),
        (g) => g?.page_design_contents?.title
      ),
      (g) => g.language
    );
    return [
      {
        header: t('Pending'),
        content: () =>
          pendingPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Pending Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable items={pendingPages} columns={columns} />
          ),
      },
      {
        header: t('Published'),
        content: () =>
          publishedPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Published Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable items={publishedPages} columns={columns} />
          ),
      },
    ];
  }, [t, instantWins, columns]);
  return (
    <div>
      <Box display="flex">
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Instant Win')}
        </Button>
        {showEditModal && (
          <EditInstantWinModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          />
        )}
      </Box>

      <Box mt={2}>
        <Tabs panes={tabPanes} />
      </Box>
    </div>
  );
};

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long' | string;
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const useColumns = (): ColumnType<InstantWin>[] => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);

  const columns: ColumnType<InstantWin>[] = React.useMemo(
    () => [
      {
        Header: '',
        th: true,
        width: 'minimal',
        Cell: (cellInfo) => (
          <EditDeleteCell existingInstantWin={cellInfo.original} />
        ),
      },
      {
        Header: t('Title'),
        width: 'minimal',
        accessor: (row) => row.page_design_contents?.title ?? '',
      },
      {
        Header: t('Language'),
        width: 'minimal',
        accessor: (row) =>
          getLanguageName(
            uppercaseIsoToLowercaseIso[
              row.language as keyof typeof uppercaseIsoToLowercaseIso
            ],
            t
          ) ?? '',
      },
      {
        Header: t('Description'),
        accessor: (row) => row.page_design_contents?.description ?? '',
      },
      {
        Header: t('Usage Rate'),
        Cell: (cellInfo) => (
          <TotalStats instantWinId={cellInfo.original.id ?? ''} />
        ),
      },
      {
        Header: t('Tier Usage Rate'),
        Cell: (cellInfo) => <TiersStats instantWin={cellInfo.original} />,
      },
    ],
    [t, activeUser, bookingWebsiteUrl]
  );

  return columns;
};

const EditDeleteCell = ({
  existingInstantWin,
}: {
  existingInstantWin: InstantWin;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditInstantWinModal
          existingInstantWin={existingInstantWin}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete guidance page')}
          content={t('Are you sure you want to delete guidance page?')}
          onConfirm={async () => {
            await dispatch(deleteInstantWin(existingInstantWin?.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};
