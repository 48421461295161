import { Icon } from 'client/components/Icons/Icon';
import qrCodeIcon from 'client/images/ic_qr_code.svg';

type Props = {
  onClick?: () => void;
  className?: string;
};
export const QRCode = (props: Props) => {
  return <Icon {...props} src={qrCodeIcon} />;
};
