import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { SystemFeeInvoiceDetails } from 'client/pages/SystemFee/SystemFeeInvoiceDetails/SystemFeeInvoiceDetails';

export const PartnershipSystemFeeInvoiceDetails = () => {
  return (
    <PartnershipModeContext.Provider value={{ partnershipMode: true }}>
      <SystemFeeInvoiceDetails />
    </PartnershipModeContext.Provider>
  );
};
