import * as React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { parseMoney } from 'client/libraries/util/util';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { getCommonFormFields } from 'client/libraries/util/coreutil';
import type { TranslateFuncType } from 'client/components/Translate';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import { operationAllowed } from 'shared/models/access';
import {
  getWaiverStatusText,
  getWaiverStatus,
} from 'client/libraries/util/getWaiverStatusText';
import { EditSupplierInternalNotesModal } from 'client/pages/Manifest/EditSupplierInternalNotesModal';
import { EditSupplierInternalNotesModalForDispatchModal } from 'client/pages/Manifest/EditSupplierInternalNotesForDispatchModal';
import { CheckinReservationModal } from 'client/pages/CheckinReservation/CheckinReservationModal';
import { MultipleCheckinReservationModal } from 'client/pages/CheckinReservation/MultipleCheckinReservationModal';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ReservationGuestTypeRedemptionModal } from 'client/components/ReservationGuestTypeRedemptionModal/ReservationGuestTypeRedemptionModal';
import { ReservationRedemptionCountModal } from 'client/components/ReservationRedemptionCountModal/ReservationRedemptionCountModal';
import { UploadedImageThumbnail } from 'client/components/UploadedImageThumbnail/UploadedImageThumbnail';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
  Account,
} from 'shared/models/swagger';
import editIcon from 'client/images/ic_edit.svg';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkUnfinishedIcon from 'client/images/ic_check_unfinished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';
import checkMarkIcon from 'client/images/ic_check.svg';
import baseStyles from 'client/base.module.css';

import { EditCell } from './EditCell';
import { ReservationFormCell } from './ReservationFormCell';

interface FileUpload {
  url: string;
  name: string;
}

const isJSONArray = (s: string): boolean => {
  try {
    const parsed = JSON.parse(s);

    if (!Array.isArray(parsed)) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

export type ManifestReservationsTableColumn = ReservationColumn | string;
export type Column = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  id: ManifestReservationsTableColumn;
  accessor?: string | ((arg0: any) => string);
  columnGroupKey?: ManifestReservationsTableColumn;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  width?: string;
};

const formatPOBamount = (currencyString: string): string => {
  if (!currencyString) {
    return '';
  }

  const amountString = parseMoney(currencyString).value;
  const regex = /^0+\.?0*$/gm;

  if (regex.test(amountString)) {
    return '-';
  }

  return currencyString;
};

const tooltipStyle = {
  top: '-20px',
  left: '20px',
};

const getCheckedInGuestCountForComponentReservationId = (
  reservation: ManifestReservationShape,
  componentReservationId: string
) => {
  let checkedInGuestCount = 0;

  for (const checkinRecord of reservation?.checkin_info?.checkin_records ??
    []) {
    if (
      !checkinRecord.package_component_reservation_id ||
      checkinRecord.package_component_reservation_id === componentReservationId
    ) {
      checkedInGuestCount += checkinRecord.guest_count ?? 0;
    }
  }

  return Math.min(checkedInGuestCount, (reservation?.guests ?? []).length);
};

const isMultipleCheckin = (product: ProductSummary): boolean => {
  if (product.qr_checkin_settings?.should_use_e_ticket) {
    return true;
  }
  return false;
};

const isSplitETicketCheckin = (product: ProductSummary): boolean => {
  if (
    product.qr_checkin_settings?.should_use_e_ticket &&
    product?.qr_checkin_settings
      ?.should_count_guests_for_checkin_with_guest_type
  ) {
    return true;
  }
  return false;
};

const getSplitETicketCheckedInGuestCount = (
  reservation: ManifestReservationShape
) => {
  return reservation?.checkin_info?.checkin_records?.reduce(
    (acc, checkinRecord) => {
      return acc + (checkinRecord.guest_count ?? 0);
    },
    0
  );
};

const getSplitETicketFullGuestCount = (
  reservation: ManifestReservationShape,
  product: ProductSummary
) => {
  return (
    (reservation?.guests?.length ?? 1) *
    (product?.qr_checkin_settings?.stubs?.length ?? 1)
  );
};

const getInProgressCheckinStatusBox = (
  manifestReservationShape: ManifestReservationShape,
  products: ProductSummary[]
): any => {
  const product = products.find(
    (product) => product.id === manifestReservationShape.product_id
  );

  if (product && isSplitETicketCheckin(product)) {
    return (
      <Box>
        {`${getSplitETicketCheckedInGuestCount(
          manifestReservationShape
        )}/${getSplitETicketFullGuestCount(manifestReservationShape, product)}`}
      </Box>
    );
  }

  if (product && isMultipleCheckin(product)) {
    return (
      <Box>
        {`${
          (manifestReservationShape?.checkin_info?.checkin_records || []).length
        }/${(product.qr_checkin_settings?.stubs || []).length || 1}`}
      </Box>
    );
  }

  if (
    (manifestReservationShape?.checkin_info?.stubs || []).length > 0 &&
    manifestReservationShape?.package_component_reservations.length === 0
  ) {
    return (manifestReservationShape?.checkin_info?.stubs || []).map(
      (stub, idx) => {
        let checkinGuestCount = 0;
        for (const checkinRecord of manifestReservationShape?.checkin_info
          ?.checkin_records ?? []) {
          if (stub.key == checkinRecord.stub_key ?? '') {
            checkinGuestCount += checkinRecord.guest_count ?? 0;
          }
        }

        return (
          <Box key={idx}>
            {`[${stub.text}] ${checkinGuestCount}/${manifestReservationShape?.guests?.length}`}
          </Box>
        );
      }
    );
  }

  return (
    <>
      {manifestReservationShape?.checkin_info?.checkin_records?.some(
        (record) => record.package_component_reservation_id
      ) ? (
        manifestReservationShape?.package_component_reservations?.map(
          (packageComponentReservation) => {
            return (
              <Box key={packageComponentReservation.reservation_id}>
                {`[${
                  packageComponentReservation.formatted_start_time
                }] ${getCheckedInGuestCountForComponentReservationId(
                  manifestReservationShape,
                  packageComponentReservation.reservation_id
                )}/${manifestReservationShape?.guests?.length}`}
              </Box>
            );
          }
        )
      ) : (
        <Box>
          {`${getCheckedInGuestCountForComponentReservationId(
            manifestReservationShape,
            ''
          )}/${manifestReservationShape?.guests?.length}`}
        </Box>
      )}
    </>
  );
};

const getInProgressCheckinTipsText = (
  manifestReservationShape: ManifestReservationShape,
  products: ProductSummary[],
  t: TranslateFuncType
): string => {
  const product = products.find(
    (product) => product.id === manifestReservationShape.product_id
  );

  if (product && isMultipleCheckin(product)) {
    return t('Some tickets are redeemed');
  }

  return t('Some guests have checked in');
};

const getCheckinStatusText = (
  manifestReservationShape: ManifestReservationShape,
  products: ProductSummary[]
): any => {
  const product = products.find(
    (product) => product.id === manifestReservationShape.product_id
  );

  if (product && isSplitETicketCheckin(product)) {
    return (
      <Box>
        {`${getSplitETicketCheckedInGuestCount(
          manifestReservationShape
        )}/${getSplitETicketFullGuestCount(manifestReservationShape, product)}`}
      </Box>
    );
  }

  if (product && isMultipleCheckin(product)) {
    return `${
      (manifestReservationShape?.checkin_info?.checkin_records || []).length
    }/${(product.qr_checkin_settings?.stubs || []).length || 1}`;
  }

  if (
    (manifestReservationShape?.checkin_info?.stubs || []).length > 0 &&
    manifestReservationShape?.package_component_reservations.length === 0
  ) {
    return (manifestReservationShape?.checkin_info?.stubs || []).map(
      (stub, idx) => {
        let checkinGuestCount = 0;
        for (const checkinRecord of manifestReservationShape?.checkin_info
          ?.checkin_records ?? []) {
          if (stub.key == checkinRecord.stub_key ?? '') {
            checkinGuestCount += checkinRecord.guest_count ?? 0;
          }
        }

        return (
          <Box key={idx}>
            {`[${stub.text}] ${checkinGuestCount}/${manifestReservationShape?.guests?.length}`}
          </Box>
        );
      }
    );
  }

  if (manifestReservationShape?.checkin_info?.checkin_status === 'CHECKED_IN') {
    return `${manifestReservationShape?.guests?.length}/${manifestReservationShape?.guests?.length}`;
  }

  return `0/${manifestReservationShape?.guests?.length}`;
};

const getCheckinTipsText = (
  manifestReservationShape: ManifestReservationShape,
  products: ProductSummary[],
  t: TranslateFuncType
): string => {
  const product = products.find(
    (product) => product.id === manifestReservationShape.product_id
  );

  if (product && isMultipleCheckin(product)) {
    if (
      (manifestReservationShape?.checkin_info?.checkin_records || []).length ===
      ((product.qr_checkin_settings?.stubs || []).length || 1)
    ) {
      return t('All tickets are redeemed');
    }
    return t('Ticket is not redeemed, yet');
  }

  if (manifestReservationShape?.checkin_info?.checkin_status === 'CHECKED_IN') {
    return t('All guests have checked in');
  }

  return t('Not checked in yet');
};

const getCheckinIcon = (
  manifestReservationShape: ManifestReservationShape
): any => {
  switch (manifestReservationShape?.checkin_info?.checkin_status) {
    case 'CHECKED_IN':
      return checkFinishedIcon;
    case 'IN_PROGRESS':
      return checkInProgressIcon;
    default:
      return checkUnfinishedIcon;
  }
};

export const getColumns = (
  locale: string,
  t: TranslateFuncType,
  excludedFormFieldKeys: string[],
  products: ProductSummary[],
  reservations: ManifestReservationShape[],
  visibleColumnsForEdit: string[],
  visibleColumns: ReservationColumn[],
  customizedColumnNames: ManifestCustomizedColumnName[],
  guideAccountShapes: GuideAccountShape[],
  editingResourceType: ResourceType | null,
  editingResourceKey: string | null,
  activeUser: Account | null,
  onEditResourceButtonClick?: (arg0: string) => void,
  onResourceTextClick?: (arg0: string, arg1: ResourceType, arg2: string) => void
): Column[] => {
  const bookedProducts = products.filter((p) =>
    reservations.some((r) => r.product_id === p.id)
  );

  const allColumns: Column[] = [
    ...(activeUser == null ||
    operationAllowed(activeUser, 'read', 'reservations')
      ? [
          {
            Header: '',
            id: 'edit',
            width: 'short',
            Cell: (cellInfo: { original: ManifestReservationShape }) => (
              <EditCell reservation={cellInfo.original} />
            ),
            th: true,
          },
        ]
      : []),
    {
      Header: getColumnHeaderText('CHECKIN_STATUS', t, customizedColumnNames),
      id: 'CHECKIN_STATUS',
      width: '150',
      Cell: (cellInfo: { original: ManifestReservationShape }) => {
        const product = products.find(
          (product) => product.id === cellInfo.original?.product_id
        );

        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {['CHECKED_IN', 'NOT_CHECKED_IN'].includes(
              cellInfo.original?.checkin_info?.checkin_status || ''
            ) && (
              <>
                <Tooltip
                  style={tooltipStyle}
                  icon={getCheckinModal(product, cellInfo.original)}
                  text={getCheckinTipsText(cellInfo.original, products, t)}
                />
                <Box ml={1}>
                  {getCheckinStatusText(cellInfo.original, products)}
                </Box>
              </>
            )}
            {cellInfo.original?.checkin_info?.checkin_status ===
              'IN_PROGRESS' && (
              <>
                <Tooltip
                  style={tooltipStyle}
                  icon={getCheckinModal(product, cellInfo.original)}
                  text={getInProgressCheckinTipsText(
                    cellInfo.original,
                    products,
                    t
                  )}
                />
                <Box ml={1}>
                  {getInProgressCheckinStatusBox(cellInfo.original, products)}
                </Box>
              </>
            )}
          </Box>
        );
      },
    },
    {
      Header: t('Dispatch resources'),
      id: 'dispatch',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <div className={clsx(baseStyles['base-flex'])}>
          <div>
            {cellInfo.original.dispatch_vehicles.map((s, idx) => (
              <div key={idx}>
                <a
                  onClick={() => {
                    onResourceTextClick && onResourceTextClick(s, 'vehicle', s);
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '2px',
                    }}
                    key={idx}
                  >
                    {s}
                  </span>
                </a>
              </div>
            ))}
            {cellInfo.original.dispatch_crew.map((s, idx) => (
              <div key={idx}>
                <a
                  onClick={() => {
                    onResourceTextClick && onResourceTextClick(s, 'crew', s);
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '2px',
                    }}
                    key={idx}
                  >
                    {s}
                  </span>
                </a>
              </div>
            ))}
            {cellInfo.original.dispatch_misc_resources.map((s, idx) => (
              <div key={idx}>
                <a
                  onClick={() => {
                    onResourceTextClick && onResourceTextClick(s, 'other', s);
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '2px',
                    }}
                    key={idx}
                  >
                    {s}
                  </span>
                </a>
              </div>
            ))}
            {cellInfo.original.dispatch_guides.map((s, idx) => (
              <div key={idx}>
                <a
                  onClick={() => {
                    onResourceTextClick &&
                      onResourceTextClick(
                        s,
                        'guide',
                        guideAccountShapes.find((guide) => guide.id === s)
                          ?.name || 'NO NAME'
                      );
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '2px',
                    }}
                    key={idx}
                  >
                    {guideAccountShapes.find((guide) => guide.id === s)?.name ||
                      'NO NAME'}
                  </span>
                </a>
              </div>
            ))}
          </div>
          {hasCustomUserRoleWritePermissions(activeUser, 'MANIFEST.DAILY') && (
            <a
              className={clsx(
                baseStyles['base-btn icon' as keyof typeof baseStyles],
                baseStyles['icon']
              )}
              onClick={() => {
                onEditResourceButtonClick &&
                  onEditResourceButtonClick(cellInfo.original.id);
              }}
            >
              <img src={editIcon} />
            </a>
          )}
        </div>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_REFERENCE',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_REFERENCE',
      accessor: (row: ManifestReservationShape) => row.supplier_reference || '',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) =>
        activeUser === null ||
        operationAllowed(activeUser, 'read', 'reservations') ? (
          <Link to={`/reservations/${cellInfo.original.id}`}>
            {cellInfo.original.supplier_reference}
          </Link>
        ) : (
          <>{cellInfo.original.supplier_reference}</>
        ),
    },
    {
      Header: getColumnHeaderText(
        'GUEST_DISPLAY_NAME',
        t,
        customizedColumnNames
      ),
      id: 'GUEST_DISPLAY_NAME',
      width: 'middle',
      accessor: 'guest_display_name',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <div>
          {cellInfo.original.guest_display_name.split(' ').map((s, idx) => (
            <span
              style={{
                display: 'inline-block',
                marginRight: '2px',
              }}
              key={idx}
            >
              {s}{' '}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: getColumnHeaderText('GIVEN_NAME', t, customizedColumnNames),
      id: 'GIVEN_NAME',
      width: 'middle',
      accessor: 'given_name',
    },
    {
      Header: getColumnHeaderText('FAMILY_NAME', t, customizedColumnNames),
      id: 'FAMILY_NAME',
      width: 'middle',
      accessor: 'family_name',
    },
    {
      Header: getColumnHeaderText('PRODUCT_NAME', t, customizedColumnNames),
      id: 'PRODUCT_NAME',
      width: 'long',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <>
          <div className={clsx(baseStyles['base-flex'])}>
            <ul>
              <li>{cellInfo.original.product_name}</li>
              {cellInfo.original.package_component_reservations.map(
                (componentReservation, idx) => (
                  <li key={idx}>
                    {`[${componentReservation.formatted_start_time}] ${componentReservation.product_name}`}
                  </li>
                )
              )}
            </ul>
            {editingResourceType === 'guide' &&
              (
                guideAccountShapes.find(
                  (guide) => guide.id === editingResourceKey
                )?.product_ids || []
              ).includes(cellInfo.original.product_id) && (
                <img src={checkMarkIcon} />
              )}
          </div>
        </>
      ),
    },
    {
      Header: getColumnHeaderText(
        'INTERNAL_PRODUCT_NAME',
        t,
        customizedColumnNames
      ),
      id: 'INTERNAL_PRODUCT_NAME',
      width: 'long',
      accessor: (row) => {
        return (
          getDisplayProductName(products.find((p) => p.id == row.product_id)) ||
          ''
        );
      },
    },
    {
      Header: getColumnHeaderText(
        'GUEST_DESCRIPTION',
        t,
        customizedColumnNames
      ),
      id: 'GUEST_DESCRIPTION',
      width: 'long',
      accessor: 'guest_description',
    },
    {
      Header: getColumnHeaderText('GUEST_COUNT', t, customizedColumnNames),
      id: 'GUEST_COUNT',
      width: 'short',
      accessor: 'guest_count',
    },
    {
      Header: getColumnHeaderText('GROSS', t, customizedColumnNames),
      id: 'GROSS',
      width: 'short',
      accessor: 'amount_gross',
    },
    {
      Header: getColumnHeaderText('POB_AMOUNT', t, customizedColumnNames),
      id: 'POB_AMOUNT',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        formatPOBamount(row.amount_pob) || '',
    },
    {
      Header: getColumnHeaderText(
        'PICKUP_CHECKIN_TIME',
        t,
        customizedColumnNames
      ),
      id: 'PICKUP_CHECKIN_TIME',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        (row.pickup_checkin_time &&
          row.pickup_checkin_time.locale(locale).format('LT')) ||
        '',
    },
    {
      Header: getColumnHeaderText(
        'PICKUP_CHECKIN_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'PICKUP_CHECKIN_LOCATION',
      width: 'long',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: getColumnHeaderText('DROPOFF_LOCATION', t, customizedColumnNames),
      id: 'DROPOFF_LOCATION',
      width: 'long',
      accessor: 'dropoff_location',
    },
    {
      Header: getColumnHeaderText('PAYMENT_TYPE', t, customizedColumnNames),
      id: 'PAYMENT_TYPE',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        (row.payment_type && t(row.payment_type)) || '',
    },
    {
      Header: getColumnHeaderText('PAYMENT_METHOD', t, customizedColumnNames),
      id: 'PAYMENT_METHOD',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        (row.payment_method && t(row.payment_method)) || '',
    },
    {
      Header: getColumnHeaderText('TRANSPORTATION', t, customizedColumnNames),
      id: 'TRANSPORTATION',
      width: 'middle',
      accessor: 'transportation',
    },
    {
      Header: getColumnHeaderText('ADD_ONS', t, customizedColumnNames),
      id: 'ADD_ONS',
      width: 'middle',
      accessor: 'add_ons',
    },
    {
      Header: getColumnHeaderText('BOOKING_SOURCE', t, customizedColumnNames),
      id: 'BOOKING_SOURCE',
      width: 'middle',
      accessor: 'booking_source',
    },
    {
      Header: getColumnHeaderText('SUPPLIER_NOTES', t, customizedColumnNames),
      id: 'SUPPLIER_NOTES',
      width: 'long',
      accessor: 'supplier_notes',
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_INTERNAL_NOTES',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_INTERNAL_NOTES',
      accessor: 'supplier_internal_notes',
      width: 'long',
      Cell: (cellInfo: {
        original: ManifestReservationShape;
        value: string;
      }) => (
        <>
          {hasCustomUserRoleWritePermissions(activeUser, 'MANIFEST.DAILY') && (
            <EditSupplierInternalNotesModal
              key={cellInfo.original?.id}
              header={t('Edit supplier memo')}
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn icon' as keyof typeof baseStyles],
                    baseStyles['icon']
                  )}
                >
                  <img src={editIcon} />
                </a>
              }
              reservation={cellInfo.original}
            />
          )}
          {cellInfo.value}
        </>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
      accessor: 'supplier_internal_notes_for_dispatch',
      width: 'long',
      Cell: (cellInfo: {
        original: ManifestReservationShape;
        value: string;
      }) => (
        <>
          <EditSupplierInternalNotesModalForDispatchModal
            key={cellInfo.original?.id}
            header={t('Edit internal note')}
            trigger={
              <a className={clsx(baseStyles['icon'])}>
                <img src={editIcon} />
              </a>
            }
            reservation={cellInfo.original}
          />
          {cellInfo.value}
        </>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_PRODUCT_REFERENCE',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_PRODUCT_REFERENCE',
      width: 'middle',
      accessor: 'supplier_product_reference',
    },
    {
      Header: getColumnHeaderText('AGENT_NOTES', t, customizedColumnNames),
      id: 'AGENT_NOTES',
      width: 'long',
      accessor: 'agent_notes',
    },
    {
      Header: getColumnHeaderText('AGENT_REFERENCE', t, customizedColumnNames),
      id: 'AGENT_REFERENCE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) => row.agent_reference || '',
      Cell: (cellInfo: { original: ManifestReservationShape }) =>
        activeUser === null ||
        operationAllowed(activeUser, 'read', 'reservations') ? (
          <Link to={`/reservations/${cellInfo.original.id}`}>
            {cellInfo.original.agent_reference}
          </Link>
        ) : (
          <>{cellInfo.original.agent_reference}</>
        ),
    },
    {
      Header: getColumnHeaderText('HOTEL', t, customizedColumnNames),
      id: 'HOTEL',
      width: 'long',
      accessor: 'hotel',
    },
    {
      Header: getColumnHeaderText('PROMO_CODE', t, customizedColumnNames),
      id: 'PROMO_CODE',
      width: 'middle',
      accessor: 'promo_code',
    },
    {
      Header: getColumnHeaderText('DISPATCH_VEHICLE', t, customizedColumnNames),
      id: 'DISPATCH_VEHICLE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        row.dispatch_vehicles && row.dispatch_vehicles.join(','),
    },
    {
      Header: getColumnHeaderText('DISPATCH_CREW', t, customizedColumnNames),
      id: 'DISPATCH_CREW',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        [
          ...(row.dispatch_crew || []),
          ...(row.dispatch_guides || []).map((id) => {
            return (
              guideAccountShapes.find((guide) => guide.id === id)?.name ||
              'no name'
            );
          }),
        ].join(',') || '',
    },
    {
      Header: getColumnHeaderText(
        'DISPATCH_MISC_RESOURCE',
        t,
        customizedColumnNames
      ),
      id: 'DISPATCH_MISC_RESOURCE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        (row.dispatch_misc_resources &&
          row.dispatch_misc_resources.join(',')) ||
        '',
    },
    {
      Header: getColumnHeaderText(
        'INTERNAL_PRODUCT_TAGS',
        t,
        customizedColumnNames
      ),
      id: 'INTERNAL_PRODUCT_TAGS',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        (row.internal_product_tags && row.internal_product_tags.join(',')) ||
        '',
    },
    {
      Header: getColumnHeaderText('WAIVER_STATUS', t, customizedColumnNames),
      id: 'WAIVER_STATUS',
      width: 'middle',
      accessor: 'waiver_status_text',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {getWaiverStatus(cellInfo.original) === 'COMPLETE' && (
              <img src={checkFinishedIcon} />
            )}
            {getWaiverStatus(cellInfo.original) === 'UNSIGNED' && (
              <img src={checkUnfinishedIcon} />
            )}
            {getWaiverStatus(cellInfo.original) === 'IN_PROGRESS' && (
              <img src={checkInProgressIcon} />
            )}
          </Box>
          <Link to={`/reservations/${cellInfo.original.id}?t=waivers#detail`}>
            {getWaiverStatusText(
              cellInfo.original.waiver_info,
              cellInfo.original.guest_count,
              t
            )}
          </Link>
        </Box>
      ),
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_DEPARTURE_TIME',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_DEPARTURE_TIME',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) => {
          const dateTimeUtc = routeItem.location_from?.date_time_utc;

          if (!dateTimeUtc) {
            return '';
          }

          return moment
            .tz(dateTimeUtc, item.start_timezone ?? '')
            .format('HH:mm');
        }) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_DEPARTURE_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_DEPARTURE_LOCATION',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map(
          (routeItem) => routeItem.location_from?.location_name || ''
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_ARRIVAL_TIME',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_ARRIVAL_TIME',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) => {
          const dateTimeUtc = routeItem.location_to?.date_time_utc;

          if (!dateTimeUtc) {
            return '';
          }

          return moment
            .tz(dateTimeUtc, item.start_timezone ?? '')
            .format('HH:mm');
        }) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_ARRIVAL_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_ARRIVAL_LOCATION',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map(
          (routeItem) => routeItem.location_to?.location_name || ''
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_VEHICLE',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_VEHICLE',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          (routeItem.dispatch_vehicles ?? []).join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText('TRANSPORT_CREW', t, customizedColumnNames),
      id: 'TRANSPORT_CREW',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          [
            ...(routeItem.dispatch_crew || []),
            ...(routeItem.dispatch_guides || []).map((id) => {
              return (
                guideAccountShapes.find((guide) => guide.id === id)?.name ||
                'no name'
              );
            }),
          ].join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText('TRANSPORT_OTHER', t, customizedColumnNames),
      id: 'TRANSPORT_OTHER',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          (routeItem.dispatch_misc_resources ?? []).join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMAIL',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMAIL',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="email" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FULL_NAME',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FULL_NAME',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="full_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FULL_NAME_KANJI',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FULL_NAME_KANJI',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kanji_full_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_TITLE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_TITLE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="title" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="phone" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_INTERNATIONAL_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_INTERNATIONAL_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="international_phone"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="emergency_contact_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="emergency_contact_phone"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_HEIGHT',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_HEIGHT',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="height"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_WEIGHT',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_WEIGHT',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="weight"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_DATE_OF_BIRTH',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_DATE_OF_BIRTH',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="date_of_birth"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_AGE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_AGE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="age" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_GENDER',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_GENDER',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="gender"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_HOME_ADDRESS',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_HOME_ADDRESS',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="home_address"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_CONSENT_FORM',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_CONSENT_FORM',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="consent_form"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FAMILY_NAME_KANA',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FAMILY_NAME_KANA',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kana_family_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_GIVEN_NAME_KANA',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_GIVEN_NAME_KANA',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kana_given_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'REQUIRED_RESOURCES',
        t,
        customizedColumnNames
      ),
      id: 'REQUIRED_RESOURCES',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => {
        return (cellInfo.original?.resource_assignments || []).map(
          (resourceAssignment, idx) => {
            return (
              <p key={idx} style={{ margin: '1px' }}>
                {`${resourceAssignment.resource_key ?? ''} x ${
                  resourceAssignment.quantity ?? ''
                }`}
              </p>
            );
          }
        );
      },
    },
    ...formFieldColumns(excludedFormFieldKeys, bookedProducts),
    ...reservationFormCustomFieldColumns(excludedFormFieldKeys, bookedProducts),
  ];
  const columns: Column[] = [];
  [
    ...visibleColumnsForEdit,
    ...filterExcludedFormFieldColumns(visibleColumns, excludedFormFieldKeys),
  ].forEach((vc) => {
    columns.push(
      ...allColumns.filter((c) => {
        if (c.id === vc) {
          return true;
        }

        if (c.columnGroupKey === vc) {
          return true;
        }

        return false;
      })
    );
  });
  return columns;
};

const getFormFieldKeyForColumn = (column: ReservationColumn): string => {
  switch (column) {
    case 'RESERVATION_FORM_EMAIL':
      return 'email';

    case 'RESERVATION_FORM_FULL_NAME':
      return 'full_name';

    case 'RESERVATION_FORM_FULL_NAME_KANJI':
      return 'kanji_full_name';

    case 'RESERVATION_FORM_TITLE':
      return 'title';

    case 'RESERVATION_FORM_PHONE':
      return 'phone';

    case 'RESERVATION_FORM_INTERNATIONAL_PHONE':
      return 'international_phone';

    case 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME':
      return 'emergency_contact_name';

    case 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE':
      return 'emergency_contact_phone';

    case 'RESERVATION_FORM_HEIGHT':
      return 'height';

    case 'RESERVATION_FORM_WEIGHT':
      return 'weight';

    case 'RESERVATION_FORM_DATE_OF_BIRTH':
      return 'date_of_birth';

    case 'RESERVATION_FORM_AGE':
      return 'age';

    case 'RESERVATION_FORM_GENDER':
      return 'gender';

    case 'RESERVATION_FORM_HOME_ADDRESS':
      return 'home_address';

    case 'RESERVATION_FORM_CONSENT_FORM':
      return 'consent_form';

    case 'RESERVATION_FORM_FAMILY_NAME_KANA':
      return 'kana_family_name';

    case 'RESERVATION_FORM_GIVEN_NAME_KANA':
      return 'kana_given_name';

    default:
      return '';
  }
};

const filterExcludedFormFieldColumns = (
  columns: ReservationColumn[],
  excludedFormFields: string[]
): ReservationColumn[] => {
  return columns.filter((col) => {
    const columnFormFieldKey = getFormFieldKeyForColumn(col);

    if (columnFormFieldKey && excludedFormFields.includes(columnFormFieldKey)) {
      return false;
    }

    return true;
  });
};

const formFieldColumns = (
  excludedFormFieldKeys: string[],
  products: ProductSummary[]
): Column[] => {
  if (products.length === 0) return [];
  const alwaysExcludedFormFields = [
    'given_name',
    'family_name',
    'hotel_information',
    'hotel_information_checkin_checkout',
    'representative_name',
  ];
  const allProductFormFields = products
    .map((p) => p.reservation_form_fields)
    .reduce((prev, current) => prev?.concat(current || []), []);

  const reservationFormFields = _.uniqBy(
    allProductFormFields,
    (f) => f.key
  ).filter((f) => excludedFormFieldKeys.indexOf(f.key ?? '') === -1);

  return [
    // Per booking responses
    ...reservationFormFields
      .filter(
        (f) =>
          !alwaysExcludedFormFields.includes(f.key ?? '') &&
          f.type === 'PER_BOOKING'
      )
      .map((f) => ({
        Header: f.prompt,
        width: 'middle',
        id: f.key as any,
        Cell: (cellInfo: { original: ManifestReservationShape }) => {
          const reservation = cellInfo.original;
          const response = reservation.field_responses.find(
            (r) => r.key === f.key
          );
          return f.format === 'file-upload' &&
            response &&
            response.response &&
            isJSONArray(response.response) ? (
            <div className="newline">
              {JSON.parse(response.response).map((fileUpload: FileUpload) => (
                <div key={fileUpload.url}>
                  <a
                    href={fileUpload.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fileUpload.name}
                  </a>
                </div>
              ))}
            </div>
          ) : f.format === 'image-upload' &&
            response &&
            response.response &&
            isJSONArray(response.response) ? (
            <UploadedImageThumbnail fileUrlString={response.response} />
          ) : (
            <div className="newline">
              <TruncatedTextWithSeeMoreButton
                text={(response && response.response) || ''}
              />
            </div>
          );
        },
        columnGroupKey: 'RESERVATION_FORM_FIELDS',
      })), // Per participant responses
    ...reservationFormFields
      .filter((f) => f.type === 'PER_PARTICIPANT')
      .map((f) => ({
        Header: f.prompt,
        width: 'middle',
        id: f.key as any,
        Cell: (cellInfo: { original: ManifestReservationShape }) => {
          const reservation = cellInfo.original;
          return (
            <ul>
              {reservation.guests.map((g, idx) => {
                const response = g.field_responses?.find(
                  (r) => r.key === f.key
                );
                return f.format === 'file-upload' &&
                  response &&
                  response.response &&
                  isJSONArray(response.response) ? (
                  <div className="newline">
                    {JSON.parse(response.response).map(
                      (fileUpload: FileUpload) => (
                        <div key={fileUpload.url}>
                          <a
                            href={fileUpload.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {fileUpload.name}
                          </a>
                        </div>
                      )
                    )}
                  </div>
                ) : f.format === 'image-upload' &&
                  response &&
                  response.response &&
                  isJSONArray(response.response) ? (
                  <UploadedImageThumbnail fileUrlString={response.response} />
                ) : (
                  <li className="newline" key={idx}>
                    <TruncatedTextWithSeeMoreButton
                      text={(response && response.response) || ''}
                    />
                  </li>
                );
              })}
            </ul>
          );
        },
        columnGroupKey: 'RESERVATION_FORM_FIELDS',
      })),
  ];
};

const reservationFormCustomFieldColumns = (
  excludedFormFieldKeys: string[],
  products: ProductSummary[]
): Column[] => {
  if (products.length === 0) return [];
  const builtinFormFields = [
    ...getCommonFormFields((s: string) => s).map((field) => field.value),
    'hotel_information_checkin_checkout',
    'kana_given_name',
    'kana_family_name',
  ];
  const allProductFormFields = products
    .map((p) => p.reservation_form_fields)
    .reduce((prev, current) => prev?.concat(current || []), []);

  const reservationFormFields = _.uniqBy(
    allProductFormFields,
    (f) => f.key
  ).filter(
    (f) =>
      !excludedFormFieldKeys.includes(f.key ?? '') &&
      !builtinFormFields.includes(f.key ?? '')
  );

  return [
    // Per booking responses
    ...reservationFormFields
      .filter(
        (
          f // TODO: add more filters
        ) =>
          f.key !== 'given_name' &&
          f.key !== 'family_name' &&
          f.type === 'PER_BOOKING'
      )
      .map((f) => ({
        Header: f.prompt,
        width: 'middle',
        id: f.key as any,
        Cell: (cellInfo: { original: ManifestReservationShape }) => {
          const reservation = cellInfo.original;
          const response = reservation.field_responses.find(
            (r) => r.key === f.key
          );
          return f.format === 'file-upload' &&
            response &&
            response.response &&
            isJSONArray(response.response) ? (
            <div className="newline">
              {JSON.parse(response.response).map((fileUpload: FileUpload) => (
                <div key={fileUpload.url}>
                  <a
                    href={fileUpload.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fileUpload.name}
                  </a>
                </div>
              ))}
            </div>
          ) : f.format === 'image-upload' &&
            response &&
            response.response &&
            isJSONArray(response.response) ? (
            <UploadedImageThumbnail fileUrlString={response.response} />
          ) : (
            <div className="newline">
              <TruncatedTextWithSeeMoreButton
                text={(response && response.response) || ''}
              />
            </div>
          );
        },
        columnGroupKey: 'RESERVATION_FORM_CUSTOM',
      })), // Per participant responses
    ...reservationFormFields
      .filter((f) => f.type === 'PER_PARTICIPANT')
      .map((f) => ({
        Header: f.prompt,
        width: 'middle',
        id: f.key as any,
        Cell: (cellInfo: { original: ManifestReservationShape }) => {
          const reservation = cellInfo.original;
          return (
            <ul>
              {reservation.guests.map((g, idx) => {
                const response = g.field_responses?.find(
                  (r) => r.key === f.key
                );
                return f.format === 'file-upload' &&
                  response &&
                  response.response &&
                  isJSONArray(response.response) ? (
                  <div className="newline">
                    {JSON.parse(response.response).map(
                      (fileUpload: FileUpload) => (
                        <div key={fileUpload.url}>
                          <a
                            href={fileUpload.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {fileUpload.name}
                          </a>
                        </div>
                      )
                    )}
                  </div>
                ) : f.format === 'image-upload' &&
                  response &&
                  response.response &&
                  isJSONArray(response.response) ? (
                  <UploadedImageThumbnail fileUrlString={response.response} />
                ) : (
                  <li className="newline" key={idx}>
                    <TruncatedTextWithSeeMoreButton
                      text={(response && response.response) || ''}
                    />
                  </li>
                );
              })}
            </ul>
          );
        },
        columnGroupKey: 'RESERVATION_FORM_CUSTOM',
      })),
  ];
};

const getCheckinModal = (
  product: ProductSummary | undefined,
  reservation: ManifestReservationShape
) => {
  if (product && isSplitETicketCheckin(product)) {
    return (
      <ReservationGuestTypeRedemptionModal
        reservation={reservation}
        product={product}
        trigger={
          <a>
            <img src={getCheckinIcon(reservation)} />
          </a>
        }
      />
    );
  }

  if (
    product &&
    product?.qr_checkin_settings?.should_use_e_ticket &&
    product?.qr_checkin_settings?.should_use_redemption_count
  ) {
    return (
      <ReservationRedemptionCountModal
        reservation={reservation}
        product={product}
        trigger={
          <a>
            <img src={getCheckinIcon(reservation)} />
          </a>
        }
      />
    );
  }

  if (product && isMultipleCheckin(product)) {
    return (
      <MultipleCheckinReservationModal
        reservation={reservation}
        product={product}
        trigger={
          <a>
            <img src={getCheckinIcon(reservation)} />
          </a>
        }
      />
    );
  }

  return (
    <CheckinReservationModal
      reservation={reservation}
      trigger={
        <a>
          <img src={getCheckinIcon(reservation)} />
        </a>
      }
    />
  );
};
