import moment from 'moment';
import _ from 'lodash';

import {
  AggregationType,
  TargetType,
} from 'client/libraries/util/reservationReport';
import {
  ReservationReportDataItem,
  ReservationReportDataSet,
} from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';

export type GraphDataItem = Record<string, string | number>;

const getFieldNames = (
  dataSet: ReservationReportDataSet,
  aggregationType: AggregationType
): string[] => {
  return Array.from(
    new Set(
      (dataSet.items || []).map((item) => getFieldName(item, aggregationType))
    )
  );
};

const getFieldValue = (
  item: ReservationReportDataItem,
  targetType: TargetType
): number => {
  switch (targetType) {
    case 'AMOUNT_GROSS':
      return item.amount_gross || 0;

    case 'AMOUNT_NET':
      return item.amount_net || 0;

    case 'NUM_OF_RESERVATIONS':
      return 1;

    case 'PAX':
      return item.pax || 0;

    default:
      return 0;
  }
};

const getFieldName = (
  item: ReservationReportDataItem,
  aggregationType: AggregationType
): keyof GraphDataItem => {
  switch (aggregationType) {
    case 'PRODUCT':
      return item.product_id || 'NO PRODUCT';

    case 'BOOKING_SOURCE':
      return (
        item.booking_source?.agent_id ||
        item.booking_source?.source_type ||
        'NO BOOKING SOURCE'
      );

    case 'TOTAL':
    default:
      return 'total';
  }
};

export const convertReservationReportDataSetsToGraphData = (
  dataSets: ReservationReportDataSet[],
  aggregationType: AggregationType,
  targetType: TargetType,
  dateAggregation: 'DAILY' | 'WEEKLY' | 'MONTHLY' = 'DAILY'
): GraphDataItem[][] => {
  const allGraphData: GraphDataItem[][] = [];

  if (dataSets.length < 1) {
    return [];
  }

  dataSets.forEach((dataSet) => {
    const graphData: GraphDataItem[] = [];
    let start = dataSet.range?.start ? moment(dataSet.range.start) : moment();
    const end = dataSet.range?.end ? moment(dataSet.range.end) : moment();
    const fields = getFieldNames(dataSet, aggregationType);

    while (start.isSameOrBefore(end)) {
      graphData.push({
        name:
          dateAggregation === 'MONTHLY'
            ? start.format('YYYY-MM')
            : start.format('YYYY-MM-DD'),
        amountGross: 0,
        amountNet: 0,
        numOfReservations: 0,
        pax: 0,
        ...fields.reduce((obj, field) => {
          (obj as any)[field] = 0;
          return obj;
        }, {}),
      });
      if (dateAggregation === 'DAILY') {
        start = start.add(1, 'days');
      } else if (dateAggregation === 'WEEKLY') {
        start = start.add(1, 'weeks');
      } else if (dateAggregation === 'MONTHLY') {
        start = start.add(1, 'months');
      }
    }

    (dataSet.items || []).forEach((item) => {
      const graphDataItem = graphData.find((e) => {
        switch (dateAggregation) {
          case 'DAILY':
            return e.name === item.date;
          case 'WEEKLY':
            return (
              item.date &&
              e.name <= item.date &&
              moment(e.name).add(1, 'weeks').format('YYYY-MM-DD') > item.date
            );
          case 'MONTHLY':
            return (
              item.date &&
              e.name <= item.date &&
              moment(e.name).add(1, 'months').format('YYYY-MM-DD') > item.date
            );
        }
      });

      if (!graphDataItem) {
        return;
      }

      const fieldName = getFieldName(item, aggregationType);
      const fieldValue = getFieldValue(item, targetType);

      if (graphDataItem[fieldName]) {
        graphDataItem[fieldName] += fieldValue as any;
      } else {
        graphDataItem[fieldName] = fieldValue;
      }
    });

    allGraphData.push(
      _.sortBy(graphData, (graphDataItem) => graphDataItem.name)
    );
  });

  return allGraphData;
};

export const getGraphColor = (
  params: ReservationReportGadgetParams
): string => {
  switch (params.dataType) {
    case 'RESERVATION_COUNT':
      return '#3B82F6';
    case 'PARTICIPANT_COUNT':
      return '#EAB308';
    case 'SALES':
      return '#10B981';
  }
};
