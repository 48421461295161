import {
  GroupBookingTemplateParams,
  GroupBookingTemplate,
} from 'shared/models/swagger';

interface GroupTemplateProduct {
  productId: string;
  limit: number;
}

export interface FormValues {
  name: string;

  status: 'ON' | 'OFF';

  products: GroupTemplateProduct[];

  startDateLocalFrom: string;
  startDateLocalTo: string;

  groupName: string;
  logoUrl: string;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): GroupBookingTemplateParams => {
  return {
    name: values.name,
    status: values.status,
    products: values.products.map((product) => ({
      product_id: product.productId,
      limit: product.limit,
    })),
    start_date_local_from: values.startDateLocalFrom,
    start_date_local_to: values.startDateLocalTo,
    logo_url: values.logoUrl,
    group_name: values.groupName,
  };
};

export const convertSwaggerToFormValues = (
  groupBookingTemplate: GroupBookingTemplate
): FormValues => {
  return {
    name: groupBookingTemplate.name ?? '',
    products:
      groupBookingTemplate.products?.map((product) => ({
        productId: product.product_id ?? '',
        limit: product.limit ?? 0,
      })) ?? [],
    startDateLocalFrom: groupBookingTemplate.start_date_local_from ?? '',
    startDateLocalTo: groupBookingTemplate.start_date_local_to ?? '',
    status: groupBookingTemplate.status ?? 'OFF',
    logoUrl: groupBookingTemplate.logo_url ?? '',
    groupName: groupBookingTemplate.group_name ?? '',
  };
};
