import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/Form';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';

export const EditInstantWinTierGiftForm = () => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Field name="giftThumbnailUrl">
        {({ input, meta: { touched, error } }) => (
          <FieldWrapper label={t('Gift Thumbnail Image (jpg, png)')}>
            <ImageVideoAudioInput
              fileUrls={input.value ? [input.value] : []}
              onChange={(newValue) =>
                newValue.length > 0
                  ? input.onChange(newValue[0])
                  : input.onChange('')
              }
              maxFileCount={1}
              disableYoutubeVideos
              error={touched && error}
            />
          </FieldWrapper>
        )}
      </Field>
    </Box>
  );
};
