import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import { KeyVisualSettings } from '@nutmeglabs/falcon-ui';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { VideoInput } from 'client/components/VideoInput/VideoInput';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';

import { LinkEditor } from './LinkEditor';
import { TextStyleEditor } from './TextStyleEditor/TextStyleEditor';

export const EditKeyVisualSection = () => {
  const [showModal, setShowModal] = React.useState(false);

  const form = useForm();
  const values = form.getState().values;

  const {
    catchPhrase,
    catchPhraseStyle,
    catchPhraseDescription,
    catchPhraseDescriptionStyle,
    keyVisualImageUrls,
    keyVisualTextColor,
    keyVisualStyle,
    keyVisualButtonText,
    keyVisualButtonColor,
    useVideoKeyVisual,
    keyVisualVideoUrl,
    keyVisualButtonLink,
    keyVisualHideButton,
    keyVisualButtonUseExternalLink,
  } = values;

  const { t } = useTranslation();

  const styleOptions = [
    { value: 'WAVES', text: t('Waves') },
    { value: 'ROUND', text: t('Round') },
    { value: 'STRAIGHT', text: t('Straight') },
  ];

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          width="wide"
          title={t('Edit Key Visual')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              catchPhrase,
              catchPhraseStyle,
              catchPhraseDescription,
              catchPhraseDescriptionStyle,
              keyVisualImageUrls,
              keyVisualTextColor,
              keyVisualStyle,
              keyVisualButtonText,
              keyVisualButtonColor,
              useVideoKeyVisual,
              keyVisualVideoUrl,
              keyVisualButtonLink,
              keyVisualHideButton,
              keyVisualButtonUseExternalLink,
            }}
            onSubmit={(values: KeyVisualSettings) => {
              form.batch(() => {
                Object.entries(values).forEach(([key, value]) => {
                  form.change(key, value);
                });
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <Field name="keyVisualStyle">
                    {({ input }: FieldRenderProps<string>) => (
                      <Select
                        label={t('Style')}
                        options={styleOptions}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                  <Field name="catchPhrase">
                    {({ input }: FieldRenderProps<string>) => (
                      <Input label={t('Catchphrase')} {...input} />
                    )}
                  </Field>
                  <TextStyleEditor
                    expanderLabel={t('Customize Catchphrase Style')}
                    name="catchPhraseStyle"
                  />
                  <Field name="catchPhraseDescription">
                    {({ input }: FieldRenderProps<string>) => (
                      <TextArea
                        height={80}
                        maxWidth={600}
                        label={t('Website Description')}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                  <TextStyleEditor
                    expanderLabel={t('Customize Website Description Style')}
                    name="catchPhraseDescriptionStyle"
                  />
                  <FieldWrapper label={t('Key visual')}>
                    <Field name="useVideoKeyVisual">
                      {({ input }: FieldRenderProps<boolean>) => (
                        <ToggleButton
                          label={t('Use video key visual')}
                          checked={input.value}
                          onChange={() => input.onChange(!input.value)}
                        />
                      )}
                    </Field>
                    {!values.useVideoKeyVisual && (
                      <Field name="keyVisualImageUrls">
                        {({ input }: FieldRenderProps<string[]>) => (
                          <ImageVideoAudioInput
                            fileUrls={input.value}
                            onChange={(newValue) => input.onChange(newValue)}
                            disableYoutubeVideos
                          />
                        )}
                      </Field>
                    )}
                    {values.useVideoKeyVisual && (
                      <>
                        <Field name="keyVisualVideoUrl">
                          {({ input }: FieldRenderProps<string>) => (
                            <VideoInput
                              videoUrl={input.value}
                              onChange={(newValue) => input.onChange(newValue)}
                            />
                          )}
                        </Field>
                        <FieldWrapper label={t('OGP Image')} />
                        <Field name="keyVisualImageUrls">
                          {({ input }: FieldRenderProps<string[]>) => (
                            <ImageVideoAudioInput
                              fileUrls={input.value?.slice(0, 1) || []}
                              disableYoutubeVideos
                              onChange={(newValue) => input.onChange(newValue)}
                              maxFileCount={1}
                            />
                          )}
                        </Field>
                      </>
                    )}
                  </FieldWrapper>

                  <div className={baseStyles['base-text-divider']} />

                  <Box mb={2}>
                    <FieldWrapper label={t('Book Now Button')} />
                    <Box display="flex">
                      <Field name="keyVisualHideButton">
                        {({ input }: FieldRenderProps<boolean>) => (
                          <ToggleButton
                            label={
                              <Box display="flex">
                                {t('Show "Book Now" Button')}
                                <Tooltip
                                  text={t(
                                    'Please add a "Product List" section somewhere in your custom top page. Clicking the "Book Now" button will scroll to your product list section.'
                                  )}
                                />
                              </Box>
                            }
                            checked={!input.value}
                            onChange={() => input.onChange(!input.value)}
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>
                  {!values.keyVisualHideButton && (
                    <>
                      <Box display="flex" width="100%">
                        <Box width="300px">
                          <Field name="keyVisualButtonText">
                            {({ input }: FieldRenderProps<string>) => (
                              <Input
                                label={t('Text')}
                                value={input.value}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                              />
                            )}
                          </Field>
                        </Box>
                        <Box ml={2}>
                          <FieldWrapper label={t('Color')}>
                            <Field name="keyVisualButtonColor">
                              {({ input }: FieldRenderProps<string>) => (
                                <ColorPicker
                                  color={input.value}
                                  onChange={({ color }: any) =>
                                    input.onChange(color)
                                  }
                                />
                              )}
                            </Field>
                          </FieldWrapper>
                        </Box>
                      </Box>
                      {/*
                      <Box mt={2}>
                        <Field name="keyVisualButtonUseExternalLink">
                          {({ input }: FieldRenderProps<boolean>) => (
                            <FieldWrapper label={t('Book Now Click Behavior')}>
                              <Radio
                                label={t(
                                  'Scroll to product list on Top Page (Note: Please add "Product List" section in the custom top page.)'
                                )}
                                checked={!input.value}
                                onChange={() => {
                                  input.onChange(false);
                                }}
                              />
                              <Radio
                                label={t('Go to external link')}
                                checked={input.value}
                                onChange={() => {
                                  input.onChange(true);
                                }}
                              />
                            </FieldWrapper>
                          )}
                        </Field>
                      </Box>
                      */}
                      {values.keyVisualButtonUseExternalLink && (
                        <Box mt={2}>
                          <LinkEditor name="keyVisualButtonLink" />
                        </Box>
                      )}
                    </>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
