import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import type { Organization, SourceLanguage } from 'shared/models/swagger';

export interface LanguageOption {
  value: SourceLanguage;
  text: string;
}

export const getBookingWidgetPmpSupportedLanguageOptions = (
  organization: Organization | null
): LanguageOption[] => {
  const supportedLanguages = getBookingWidgetPmpSupportLanguages(organization);

  return (
    [
      {
        value: 'EN_US',
        text: 'English',
      },
      {
        value: 'JA_JP',
        text: '日本語',
      },
      {
        value: 'KO_KR',
        text: '한국어',
      },
      {
        value: 'ZH_CN',
        text: '简体中文',
      },
      {
        value: 'ZH_TW',
        text: '繁體中文',
      },
    ] as { value: SourceLanguage; text: string }[]
  ).filter((lang) => supportedLanguages.includes(lang.value as SourceLanguage));
};

export const getBookingWidgetPmpSupportLanguages = (
  organization: Organization | null
): SourceLanguage[] => {
  let langs: SourceLanguage[] = [];

  if (
    (organization?.private_marketplaces ?? []).length > 0 &&
    !isSubscriptionCancelled(organization, 'standard-plan')
  ) {
    organization?.private_marketplaces?.forEach((pmp) => {
      if (pmp.language) {
        langs.push(pmp.language);
      }
    });
  }

  if (organization?.booking_widget_api_key) {
    if (organization?.supported_languages) {
      langs = [...langs, ...(organization?.supported_languages ?? [])];
    }
  }

  return [...new Set(langs)];
};
