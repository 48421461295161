import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import clsx from 'clsx';
import { useState } from 'react';

import styles from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/SelectPane.module.css';
import { AddButton } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddButton';
import {
  AccessReportGadgetDataType,
  AccessReportGadgetDisplayType,
  AccessReportGadgetParams,
  DashboardGadget,
  DashboardSettings,
} from 'client/reducers/dashboardSettings';
import { TranslateFuncType } from 'client/components/Translate';
import { Gadget } from 'client/pages/v3/FlexibleDashboard/Gadget';
import { Button } from 'client/components/v3/Common/Button';
import { AddCustomGadgetModal } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddCustomGadgetModal';
import { getAccessReportGadgetInitialParams } from 'client/pages/v3/FlexibleDashboard/util';

const getGadgetTitle = (
  dataType: AccessReportGadgetDataType,
  displayType: AccessReportGadgetDisplayType,
  t: TranslateFuncType
): string => {
  switch (displayType) {
    case 'BAR_CHART':
      switch (dataType) {
        case 'PAGE_VIEWS':
          return t('Page Views');
        case 'USERS':
          return t('Users');
        case 'RESERVATIONS':
          return t('Reservations');
      }
    // fallsthrough
    case 'PRODUCTS_TABLE':
      switch (dataType) {
        case 'PAGE_VIEWS':
          return t('Page Views by Products');
        case 'USERS':
          return t('Page Views by Landing Sources');
        case 'RESERVATIONS':
          return t('Reservations by Products');
      }
    // fallsthrough
    case 'LANDING_SOURCES_TABLE':
      switch (dataType) {
        case 'PAGE_VIEWS':
          return t('Page Views by Landing Sources');
        case 'USERS':
          return t('Users by Landing Sources');
        case 'RESERVATIONS':
          return t('Reservations by Landing Sources');
      }
  }
};

const getSummaryPresetGadgets = (t: TranslateFuncType): DashboardGadget[] => {
  return [
    {
      gadgetType: 'access-summary',
      key: 'access-summary',
      title: t('Booking Site Access Summary'),
      params: { dateRange: 'PREV_7_DAYS' },
    },
  ];
};

const getReportPresetGadgets = (
  dataType: AccessReportGadgetDataType,
  t: TranslateFuncType
): DashboardGadget[] => {
  const displayTypes: AccessReportGadgetParams['displayType'][] = [
    'BAR_CHART',
    // TODO: Hide by products and by landing sources for now, to be implemented later
    // 'PRODUCTS_TABLE',
    // 'LANDING_SOURCES_TABLE',
  ];

  return displayTypes.map(
    (displayType: AccessReportGadgetParams['displayType']) => {
      return {
        gadgetType: 'access-report',
        key: dataType.toLowerCase() + '-report-' + displayType,
        title: getGadgetTitle(dataType, displayType, t),
        params: getAccessReportGadgetInitialParams(dataType, displayType),
      };
    }
  );
};

type Props = {
  onClose: () => void;
};

export const AccessReportSelectPane = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [gadgetType, setGadgetType] = useState<
    'access-report' | 'access-summary'
  >('access-report');
  const [dataType, setDataType] = useState<
    AccessReportGadgetDataType | undefined
  >(undefined);

  const form = useForm<DashboardSettings>();
  const values = form.getState().values;

  return (
    <>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Access Summary')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setGadgetType('access-summary');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getSummaryPresetGadgets(t).map((gadget) => (
            <li
              key={gadget.key}
              className={clsx(
                styles['p-dashboardDataList__item'],
                styles['full']
              )}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Page Views')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('PAGE_VIEWS');
                setGadgetType('access-report');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getReportPresetGadgets('PAGE_VIEWS', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Users')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('USERS');
                setGadgetType('access-report');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getReportPresetGadgets('USERS', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Reservations')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('RESERVATIONS');
                setGadgetType('access-report');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getReportPresetGadgets('RESERVATIONS', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      {showModal && (
        <AddCustomGadgetModal
          gadgetType={gadgetType}
          gadgetDataType={dataType}
          onClose={() => {
            setShowModal(false);
            // Also close add gadget modal
            onClose();
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
