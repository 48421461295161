import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import type { ReduxState } from 'client/reducers';
import { getFormattedTime } from 'client/libraries/util/util';
import type { ReservationColumn, ProductSummary } from 'shared/models/swagger';
import { ResourceBulkUpdateReservationsTable } from 'client/pages/v3/Manifest/ManifestDaily/Modal/ResourceBulkUpdateReservationTable';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

type Props = {
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  excludedFormFieldKeys: string[];
  products: ProductSummary[];
  open: boolean;
  onClose: () => void;
  onResourceAssignmentButtonChange: (arg0: string[]) => void;
};
export const ResourceBulkUpdateModal = ({
  reservations,
  visibleColumns,
  excludedFormFieldKeys,
  products,
  open,
  onClose,
  onResourceAssignmentButtonChange,
}: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationsByStartTime = groupReservationsByStartTime(
    reservations,
    locale
  );
  const [selectedReservationIds, setSelectedReservationIds] = React.useState<
    string[]
  >([]);
  return (
    <Modal
      title={t('Bulk assign resources')}
      subtitle={t('Please select a reservation.')}
      open={open}
      onClose={onClose}
      style={{ width: '720px', height: '600px' }}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
            color="white"
          />

          <Button
            text={t('Select resources')}
            disabled={selectedReservationIds.length === 0}
            onClick={() => {
              onResourceAssignmentButtonChange(selectedReservationIds);
            }}
          />
        </>
      }
      insertAtRoot={true}
    >
      <div className={styles['p-manifestsModalAssignments']}>
        {reservationsByStartTime.map(({ startTime, reservations }, idx) => {
          return (
            <div key={idx} className={styles['p-manifests__normal']}>
              <div className={styles['p-manifests__normal__header']}>
                <div>
                  <p>
                    {t('Start time: {{startTime}}', {
                      startTime: getFormattedTime(startTime, locale),
                    })}
                  </p>
                </div>
              </div>
              <div className={styles['p-manifestsTable2']}>
                <ResourceBulkUpdateReservationsTable
                  excludedFormFieldKeys={excludedFormFieldKeys || []}
                  reservations={reservations}
                  visibleColumns={visibleColumns}
                  selectedReservationIds={selectedReservationIds.filter((id) =>
                    reservations.map((r) => r.id).includes(id)
                  )}
                  onSelectedReservationChange={(ids: string[]) => {
                    setSelectedReservationIds([
                      // unique array
                      ...new Set([
                        // remove unselected id
                        ...selectedReservationIds.filter((id) => {
                          if (
                            reservations.map((r) => r.id).includes(id) &&
                            !ids.includes(id)
                          ) {
                            return false;
                          }

                          return true;
                        }), // add selected id
                        ...ids,
                      ]),
                    ]);
                  }}
                  products={products}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
