import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Divider } from 'client/components/Divider/Divider';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { composeValidators } from 'client/libraries/util/composeValidators';
import { journeyAnalyticsSearchPresetsSelector } from 'client/reducers/supplierSettings';
import { putSupplierSettings } from 'client/actions/supplierSettings';
import { ReduxState } from 'client/reducers';

import { ConditionGroupEditor } from './ConditionGroupEditor';
import styles from './EditPresetModal.module.css';
import {
  AnalyticsCustomerSearchPreset,
  convertSwaggerPresetToFormValues,
  convertFormValuesToSwaggerAnalyticsPreset,
} from './formValues';

type FormValues = AnalyticsCustomerSearchPreset;

interface Props {
  onClose: () => void;
  onSave: (presetName: string) => void;
  presetIdx: number;
}

const focusOnError: any = createDecorator<FormValues, Partial<FormValues>>();

export const EditPresetModal = ({ onClose, presetIdx, onSave }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  const dispatch = useDispatch();

  const existingJourneyAnalyticsSettings = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.journeyAnalytics
  );
  const presets = useSelector(journeyAnalyticsSearchPresetsSelector);

  const presetNameUnique = React.useCallback(
    (presetName: string) => {
      if (
        presets.some(
          (preset, idx) =>
            preset.preset_name === presetName && idx !== presetIdx
        )
      ) {
        return t('Preset name must be unique');
      }

      return undefined;
    },
    [presets]
  );

  const initialValues = React.useMemo((): AnalyticsCustomerSearchPreset => {
    if (presetIdx >= presets.length) {
      return {
        presetName: '',
        conditionGroups: [
          {
            referralPaths: [],
            eventTypes: [],
            url: '',
            tags: [],
            dateRangePreset: 'LAST_24_HOURS',
            dimensionType: 'NONE',
          },
        ],
      };
    } else {
      return {
        ...convertSwaggerPresetToFormValues(presets[presetIdx]),
      };
    }
  }, [presets, presetIdx]);

  return (
    <Form<FormValues>
      initialValues={initialValues}
      decorators={[focusOnError]}
      mutators={getArrayMutators()}
      debug={console.log}
      onSubmit={async (values: FormValues) => {
        const newPresets = [...presets];
        if (presetIdx < presets.length) {
          newPresets[presetIdx] =
            convertFormValuesToSwaggerAnalyticsPreset(values);
        } else {
          newPresets.push(convertFormValuesToSwaggerAnalyticsPreset(values));
        }
        await dispatch(
          putSupplierSettings('JOURNEY_ANALYTICS', {
            journey_analytics_settings: {
              ...existingJourneyAnalyticsSettings,
              presets: newPresets,
            },
          })
        );

        onSave(values.presetName);
        onClose();
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            open={true}
            title={t('Edit Preset')}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  size="md"
                  color="white"
                  onClick={onClose}
                />
                <Button text={t('Save')} type="submit" />
              </>
            }
          >
            <Field
              name="presetName"
              validate={composeValidators(required, presetNameUnique)}
            >
              {({ input, meta: { error, touched } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  label={t('Preset Name')}
                  required
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <Divider />
            <FieldArray name="conditionGroups">
              {({ fields }) =>
                fields.map((f, idx) => (
                  <>
                    <ConditionGroupEditor key={f} name={f} />
                    <hr
                      className={styles['condition-group-divider']}
                      data-content={t('OR')}
                    />
                    {idx === (fields.length ?? 0) - 1 && (
                      <div
                        className={styles['add-new']}
                        onClick={() =>
                          (fields as any).insertAt(idx + 1, {
                            referralPaths: [],
                            eventTypes: [],
                            url: '',
                            tags: [],
                            dateRangePreset: 'LAST_24_HOURS',
                            dimensionType: '',
                          })
                        }
                      >
                        <i className="c-icon-outline-general-plus-circle"></i>
                        <p>{t('Add Condition')}</p>
                      </div>
                    )}
                  </>
                ))
              }
            </FieldArray>
          </Modal>
        </form>
      )}
    </Form>
  );
};
