import * as React from 'react';
import moment from 'moment-timezone';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { config } from 'client/config';
import {
  searchReservations,
  setLastReservationSearchQuery,
} from 'client/actions/reservationSearch';
import { fetchProducts } from 'client/actions/products';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { reservationVisibleColumnsSelector } from 'client/reducers/reservationTableControls';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ReservationListColumn } from 'client/libraries/util/getReservationTableColumns';
import { Button, Checkbox } from 'client/components/Form';
import { operationAllowed } from 'shared/models/access';
import { ReservationSearchQueryModal } from 'client/pages/ReservationSearch/ReservationSearchQueryModal/ReservationSearchQueryModal';
import {
  ReservationBulkCancelModal,
  BULK_OPERATION,
} from 'client/pages/ReservationSearch/ReservationBulkCancelModal/ReservationBulkCancelModal';
import { ReservationBulkCancelMaxThresholdModal } from 'client/pages/ReservationSearch/ReservationBulkCancelModal/ReservationBulkCancelMaxThresholdModal';
import { ReservationBulkCancelTimeOutModal } from 'client/pages/ReservationSearch/ReservationBulkCancelModal/ReservationBulkCancelTimeOutModal';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import { ReservationSearchTableSettingsModal } from 'client/pages/ReservationSearch/ReservationSearchTableSettingsModal/ReservationSearchTableSettingsModal';
import { ReservationSearchDownloadCSVModal } from 'client/pages/ReservationSearch/ReservationSearchDownloadCSVModal/ReservationSearchDownloadCSVModal';
import {
  csvColumnCandidatesSelector,
  csvInitialSelectedColumnsSelector,
} from 'client/pages/ReservationSearch/ReservationSearchDownloadCSVModal/util';
import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from 'client/pages/ReservationSearch/util';
import {
  setReservationCurrentPage,
  setReservationDefaultVisibleColumns,
} from 'client/actions/reservationTableControls';
import { getPresetSearchReservationsRequest } from 'client/libraries/util/getPresetSearchReservationsRequest';
import { ReservationSuggestForm } from 'client/components/ReservationSuggestForm/ReservationSuggestForm';
import 'react-table/react-table.css';
import searchIcon from 'client/images/ic_search.svg';
import anotherIcon from 'client/images/ic_another.svg';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';
import { fetchGroupBookingTemplates } from 'client/actions/groupBookingTemplates';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import styles from './ReservationSearchTable.module.css';
import { ReservationSearchCustomTable } from './ReservationSearchCustomTable';

const MAX_CANCEL_THRESHOLD = 150;

export const ReservationSearchTable = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const csvLoading = useSelector(
    (state: ReduxState) => state.reservationSearch.csvLoading
  );
  const bulkCancelLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const bulkCancelError = useSelector(
    (state: ReduxState) => state.reservations.bulkCancelError
  );
  const defaultVisibleColumnsLoaded = useSelector(
    (state: ReduxState) =>
      state.reservationTableControls.defaultVisibleColumnsLoaded
  );

  const dispatch = useDispatch();
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalSearchHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );
  const productNameOptions = useSelector((state: ReduxState) => [
    ...new Set(state.reservations.summaries.map((r) => r.product_name || '')),
  ]);
  const [runSearch, setRunSearch] = React.useState<boolean>(false);

  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const bookingSourceOptions = [
    ...new Set(
      allReservations.map(
        (r) =>
          (r.booking_source &&
            (r.booking_source.agent_name ||
              t(r.booking_source.source_type as any))) ||
          ''
      )
    ),
  ];
  const visibleColumns = useSelector(reservationVisibleColumnsSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const initialSelectedColumns = useSelector(csvInitialSelectedColumnsSelector);
  const columnCandidates = useSelector(csvColumnCandidatesSelector);
  const reservationSummaries = React.useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (!defaultVisibleColumnsLoaded && activeUserOrganization) {
      const defaultColumns =
        activeUserOrganization?.account_reservation_search_settings
          ?.default_columns ?? [];
      if (defaultColumns.length > 0) {
        dispatch(
          setReservationDefaultVisibleColumns(
            defaultColumns.map((c) => c.toLowerCase())
          )
        );
      }
    }
  }, [dispatch, defaultVisibleColumnsLoaded, activeUserOrganization]);

  const [bulkCancelMode, setBulkCancelMode] = React.useState<boolean>(false);
  const [showThresholdModal, setShowThresholdModal] =
    React.useState<boolean>(false);
  const [showBulkCancelModal, setShowBulkCancelModal] =
    React.useState<boolean>(false);
  const [bulkCancelTimeOutModal, setBulkCancelTimeOutModal] =
    React.useState<boolean>(false);
  const [allCheck, setAllCheck] = React.useState<boolean>(false);
  const [cancelDeclineList, setCancelDeclineList] = React.useState<
    Record<string, BULK_OPERATION>
  >({});

  let cancelDeclineListCount = 0;
  Object.keys(cancelDeclineList).forEach((key) => {
    if (cancelDeclineList[key]) {
      cancelDeclineListCount++;
    }
  });

  const bulkCancelColumn: ReservationListColumn = {
    HeaderElement: (items: any) => {
      const confirmReservations = allReservations.filter(
        (r) => r.status === 'CONFIRMED'
      );
      const requestStandbyReservations = allReservations.filter(
        (r) => r.status === 'REQUESTED' || r.status === 'STANDBY'
      );
      const stillNotCheckedReservations = confirmReservations.filter(
        (r) => !cancelDeclineList[r.id]
      );
      const overThreshold =
        stillNotCheckedReservations.length +
          requestStandbyReservations.length +
          cancelDeclineListCount >
        MAX_CANCEL_THRESHOLD;
      return (
        <Checkbox
          checked={allCheck}
          onChange={() => {
            if (overThreshold) {
              setShowThresholdModal(true);
            } else {
              const newCancelList = {
                ...(items as Record<string, BULK_OPERATION>),
              };
              confirmReservations.forEach((r) => {
                newCancelList[r.id] = allCheck ? undefined : 'CANCEL';
              });
              requestStandbyReservations.forEach((r) => {
                newCancelList[r.id] = allCheck ? undefined : 'DECLINE';
              });
              setCancelDeclineList(newCancelList);
              setAllCheck(!allCheck);
            }
          }}
        />
      );
    },
    Header: '',
    id: 'bulkCancel',
    width: 'short',
    th: true,
    Cell: (cellInfo: any) => {
      if (
        ['CONFIRMED', 'REQUESTED', 'STANDBY'].includes(cellInfo.original.status)
      ) {
        const disabled =
          cancelDeclineListCount >= MAX_CANCEL_THRESHOLD &&
          Boolean(!cancelDeclineList[cellInfo.original.id]);
        return (
          <Checkbox
            checked={cancelDeclineList[cellInfo.original.id] !== undefined}
            onChange={() => {
              if (disabled) {
                setShowThresholdModal(true);
              } else {
                const newCancelList = { ...cancelDeclineList };
                if (newCancelList[cellInfo.original.id]) {
                  newCancelList[cellInfo.original.id] = undefined;
                } else {
                  newCancelList[cellInfo.original.id] =
                    cellInfo.original.status === 'CONFIRMED'
                      ? 'CANCEL'
                      : 'DECLINE';
                }
                setCancelDeclineList(newCancelList);
              }
            }}
          />
        );
      } else {
        return null;
      }
    },
  };

  const getAllColumns = (): ReservationListColumn[] => {
    return getReservationTableColumns(t, locale, false, partnershipMode);
  };

  const getColumns = (columnMask: string[]): ReservationListColumn[] => {
    const columns = [
      'edit',
      ...(columnMask.filter((c) => c !== 'edit') as any),
    ].map((c) => allColumns.find((col) => col.id === c) as any);

    const bulkCancelModeColumn: ReservationListColumn[] = [];
    if (bulkCancelMode) {
      bulkCancelModeColumn.push(bulkCancelColumn);
      columns.forEach((c) => {
        if (c.id !== 'edit') {
          bulkCancelModeColumn.push(c);
        }
      });

      return bulkCancelModeColumn;
    }

    return columns;
  };

  const [allColumns, setAllColumns] = React.useState(getAllColumns());
  const [showColumns, setShowColumns] = React.useState(
    getColumns(visibleColumns)
  );

  const rowCount = useSelector(
    (state: ReduxState) => state.reservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.reservationTableControls.currentPage
  );

  React.useEffect(() => {
    if (bulkCancelError !== '') {
      setBulkCancelTimeOutModal(true);
    }
  }, [bulkCancelError]);
  React.useEffect(() => {
    if (invalidated) {
      setAllColumns(getAllColumns());
    }
  }, [invalidated, locale, productNameOptions, bookingSourceOptions]);
  React.useEffect(() => {
    setShowColumns(getColumns(visibleColumns));
  }, [
    visibleColumns,
    bulkCancelMode,
    cancelDeclineList,
    allReservations,
    allCheck,
  ]);
  const lastQuery = useSelector(
    (state: ReduxState) => state.reservationSearch.lastQuery
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchReservationsRequest>(lastQuery);
  React.useEffect(() => {
    setAllCheck(false);
    search();
  }, [activeUser, rowCount, currentPage]);

  React.useEffect(() => {
    // Fetch products for search modal and search display
    dispatch(fetchProducts());

    if (config.enableGroupBooking) {
      // Fetch group booking templates for search modal and search display
      dispatch(fetchGroupBookingTemplates());
    }
  }, [t, activeUser]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  React.useEffect(() => {
    if (runSearch) {
      search();
      setRunSearch(false);
    }
  }, [runSearch]);

  const search = () => {
    if (!isNutmegAdmin) {
      let pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastQuery)) {
        dispatch(setLastReservationSearchQuery(searchCondition as any));
        pageToFetch = 1;
        dispatch(setReservationCurrentPage(pageToFetch));
      }

      dispatch(
        searchReservations(
          buildSearchReservationsRequest({
            ...searchCondition,
          }),
          rowCount,
          rowCount * (pageToFetch - 1),
          partnershipMode
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      agentIds: [],
      presetKey: '',
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
    });
  };

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) &&
        !partnershipMode && <ToggleNewUI origin="RESERVATION_LIST" />}
      <>
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
        <div
          className={clsx(
            baseStyles['base-main__body__header'],
            styles['mobile__header']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <ReservationSearchQueryModal
              onReset={reset}
              onSearch={() => {
                dispatch(setReservationCurrentPage(1));
                search();
              }}
              onClick={() => {
                setCancelDeclineList({});
                setAllCheck(false);
                setBulkCancelMode(false);
              }}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>
          {config.enableFreeTextSearch && (
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spOrder-2'],
                styles['reservation__suggest__block']
              )}
            >
              <ReservationSuggestForm />
            </div>
          )}
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-3'],
              styles['mobile__right__header'],
              bulkCancelMode &&
                styles['mobile__right__header__bulk_cancel_mode']
            )}
          >
            <div
              className={clsx(
                styles['bulk_cancel_button'],
                bulkCancelMode
                  ? styles['bulk_cancel_button_mobile_bulk_cancel_mode']
                  : styles['bulk_cancel_button_mobile']
              )}
            >
              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) && (
                <>
                  {bulkCancelMode && (
                    <Button
                      type="button"
                      style="red"
                      size="middle"
                      onClick={() => {
                        setCancelDeclineList({});
                        setAllCheck(false);
                        setBulkCancelMode(false);
                      }}
                    >
                      {t('Discard')}
                    </Button>
                  )}
                  <Button
                    type="button"
                    style="yellow"
                    size="middle"
                    loading={bulkCancelLoading}
                    disabled={(() => {
                      const notChecked = Object.keys(cancelDeclineList).every(
                        (key) => Boolean(cancelDeclineList[key]) === false
                      );
                      if (!bulkCancelMode) {
                        return false;
                      } else {
                        if (notChecked) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    })()}
                    onClick={() => {
                      if (bulkCancelMode) {
                        setShowBulkCancelModal(true);
                      } else {
                        setBulkCancelMode(!bulkCancelMode);
                      }
                    }}
                  >
                    {t('Cancel/Decline Reservations')}
                  </Button>
                  {showBulkCancelModal && (
                    <ReservationBulkCancelModal
                      setShowModal={setShowBulkCancelModal}
                      cancelDeclineList={cancelDeclineList}
                      onCancel={() => {
                        setCancelDeclineList({});
                        setAllCheck(false);
                        setBulkCancelMode(false);
                      }}
                    />
                  )}
                  {showThresholdModal && (
                    <ReservationBulkCancelMaxThresholdModal
                      setShowModal={setShowThresholdModal}
                      maxThreshold={MAX_CANCEL_THRESHOLD}
                    />
                  )}
                  {bulkCancelTimeOutModal && (
                    <ReservationBulkCancelTimeOutModal
                      setShowModal={setBulkCancelTimeOutModal}
                    />
                  )}
                </>
              )}
            </div>
            <div
              className={clsx(
                styles['header_right_other_block'],
                bulkCancelMode && styles['header_right_other_block_mobile']
              )}
            >
              <div
                className={clsx(
                  baseStyles['base-main__body__header__right__another'],
                  baseStyles['is-close']
                )}
              >
                <ReservationSearchDownloadCSVModal
                  searchRequest={searchCondition}
                  initialSelectedColumns={initialSelectedColumns}
                  columnCandidates={columnCandidates}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        csvLoading && baseStyles['loading']
                      )}
                    >
                      <img src={anotherIcon} />
                    </a>
                  }
                />

                <ul>
                  <li>
                    <ReservationSearchDownloadCSVModal
                      searchRequest={searchCondition}
                      initialSelectedColumns={initialSelectedColumns}
                      columnCandidates={columnCandidates}
                      trigger={
                        <Button.Sub
                          loading={csvLoading}
                          content={<>{t('Download CSV')}</>}
                        />
                      }
                    />
                  </li>
                </ul>
              </div>
              <ReservationSearchTableSettingsModal
                trigger={
                  <a
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['square'],
                      baseStyles['gray']
                    )}
                  >
                    <img src={thIcon} />
                  </a>
                }
              />
            </div>
          </div>
        </div>
        <ReservationSearchQueryDisplayBox
          searchFilters={lastQuery}
          onChangePreset={(presetKey) => {
            const presetSearchCondition = getPresetSearchReservationsRequest(
              activeUserOrganization,
              presetKey,
              moment()
            );
            if (presetSearchCondition) {
              setSearchCondition(presetSearchCondition);
            }
            setRunSearch(true);
          }}
        />

        <ReservationSearchCustomTable
          reservations={reservationSummaries}
          totalHits={totalSearchHits}
          visibleColumns={showColumns}
          headerElementItems={Object.keys(cancelDeclineList).reduce(
            (acc, cur) => {
              if (cancelDeclineList[cur]) {
                acc[cur] = true;
              }
              return acc;
            },
            {} as Record<string, boolean>
          )}
        />
      </>
    </>
  );
};
