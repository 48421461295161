import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import clsx from 'clsx';
import { useEffect } from 'react';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { operationAllowed } from 'shared/models/access';
import { activeUserSelector } from 'client/reducers/user';
import {
  getInstanceDedicatedAllotments,
  productInstanceAllChannelsAreClosed,
  getInstanceError,
} from 'client/libraries/util/util';
import { fetchReservations } from 'client/actions/reservations';
import { getPromotionTypeText } from 'client/libraries/util/promotionTextHelpers';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers/index';
import type { ProductInstanceShape } from 'client/libraries/util/productInstanceShape';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { TextArea } from 'client/components/v3/Form/TextArea';
import type {
  ProductInstance,
  Reservation,
  AllotmentSettings,
} from 'shared/models/swagger';
import rqIcon from 'client/images/ic_rq.svg';
import { getAvailabilityIconType } from 'client/pages/Availability/util';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { config } from 'client/config';

const getUncountedUnitsText = (
  allotmentSettings: AllotmentSettings | null,
  reservations: Reservation[]
): string => {
  const confirmedReservations = reservations.filter(
    (reservation) => reservation.status === 'CONFIRMED'
  );
  const uncountedUnitCounts: Record<string, number> = {};

  for (const reservation of confirmedReservations) {
    for (const guest of reservation.guests) {
      if (
        allotmentSettings?.inventory_consumption_rules?.find(
          (rule) => rule.unit === guest.guest_type_key
        )?.should_not_count_inventory
      ) {
        uncountedUnitCounts[guest.guest_type_title ?? ''] =
          (uncountedUnitCounts[guest.guest_type_title ?? ''] ?? 0) + 1;
      }
    }
  }

  const unitCounts = Object.entries(uncountedUnitCounts).map(
    ([unit, count]) => {
      const unitCount: any = count;
      return `${unit} x ${unitCount}`;
    }
  );

  if (unitCounts.length > 0) {
    return `(+ ${unitCounts.join(', ')})`;
  }

  return '';
};

type Props = {
  timezone: string;
  productInstance: ProductInstance;
  onEditClick?: (instance: ProductInstance) => void;
  onListClick?: (instance: ProductInstance) => void;
  productName: string;
  allotmentSettings: AllotmentSettings | null;
  instance: ProductInstanceShape;
  open: boolean;
  onClose: () => void;
  onSameProductInstanceIsSelected?: () => void;
  reservation?: Reservation;
  isChangeReservation?: boolean;
  isFromNewReservationModal?: boolean;
  isReservationWithSameGuests?: boolean;
  onSelectProductInstanceError?: (arg0: string) => void;
  customerId?: string;
  shouldRejectBookingsBeyondCapacity?: boolean;
};

export const ProductInstancePanel = ({
  timezone,
  productInstance,
  onEditClick,
  onListClick,
  productName,
  allotmentSettings,
  instance,
  open,
  onClose,
  onSameProductInstanceIsSelected,
  reservation,
  isChangeReservation,
  isFromNewReservationModal,
  isReservationWithSameGuests,
  onSelectProductInstanceError,
  customerId,
  shouldRejectBookingsBeyondCapacity,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const reservationsLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const startMoment = moment
    .tz(productInstance.start_date_time_utc, timezone)
    .locale(locale);
  const start =
    startMoment.format('lll') +
    ' ' +
    (productInstance.start_time_description ?? '');
  const instantDeadline = (productInstance.booking_deadlines || []).find(
    (d) => d.confirmation_type === 'INSTANT'
  );
  const instantDeadlineMoment =
    instantDeadline && moment.tz(instantDeadline.date_time_utc, timezone);
  const instantDeadlinePassed =
    instantDeadline && moment().isAfter(instantDeadlineMoment);
  const formattedInstantBookingDeadline =
    instantDeadlineMoment && instantDeadlineMoment.locale(locale).format('lll');
  const dedicatedAllotments = getInstanceDedicatedAllotments(productInstance);

  const allChannelsClosed =
    productInstanceAllChannelsAreClosed(productInstance);
  const instanceIsClosed = productInstance.is_closed || allChannelsClosed;
  const instanceError = getInstanceError(productInstance, t);

  const userIsPassthroughSupplier =
    activeUser?.organization_type === 'SUPPLIER' &&
    productInstance.shared_allotment_references &&
    productInstance.shared_allotment_references
      .passthrough_base_product_instance_id;
  const componentInstances =
    productInstance.package_component_product_instances || [];

  // Load reservations
  useEffect(() => {
    dispatch(
      fetchReservations({
        filter: queryString.stringify({
          product_instance_id: productInstance.id,
        }),
        start_date_local_from: startMoment.format('YYYY-MM-DD'),
        start_date_local_to: startMoment.add(1, 'days').format('YYYY-MM-DD'),
      })
    );
  }, [productInstance.id]);

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  // Disable scrolling when modal is opened
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
  }, [open]);

  const onMoveRebookForm = async () => {
    try {
      const now = moment().tz(reservation?.start_timezone ?? '');
      const changeFromStartTime = moment.tz(
        reservation?.start_date_time_utc,
        reservation?.start_timezone ?? ''
      );
      const changeToStartTime = moment.tz(
        productInstance?.start_date_time_utc,
        reservation?.start_timezone ?? ''
      );

      if (
        !isFromNewReservationModal &&
        changeFromStartTime.isBefore(now) &&
        !changeToStartTime.isSame(changeFromStartTime, 'month')
      ) {
        if (onSelectProductInstanceError) {
          onSelectProductInstanceError(
            t(
              'If the participation date is already passed, it cannot be changed to next month or later. We are sorry for the inconveniences but please cancel and rebook with a new date.'
            )
          );
        }

        return;
      }

      if (
        !isFromNewReservationModal &&
        moment
          .tz(
            changeToStartTime.format('YYYY-MM-DD'),
            reservation?.start_timezone ?? 'UTC'
          )
          .isBefore(
            moment.tz(
              now.format('YYYY-MM-DD'),
              reservation?.start_timezone ?? 'UTC'
            )
          )
      ) {
        if (onSelectProductInstanceError) {
          onSelectProductInstanceError(
            t('Cannot change the reservation to past date.')
          );
        }

        return;
      }

      if (
        reservation &&
        typeof onSameProductInstanceIsSelected !== 'undefined' &&
        productInstance.product_id === reservation.product_id &&
        productInstance.id === reservation.product_instance_id
      ) {
        onSameProductInstanceIsSelected();
        return;
      }

      onSelectProductInstanceError?.('');
      history.push({
        // TODO: remove v3 from url for release
        pathname: `/products/${productInstance.product_id}/instances/${productInstance.id}/book-v3`,
        state: {
          reservation,
          // Always set isChangeReservation = false, if this trigger point is from ReservationCreateModal.
          isChangeReservation: isFromNewReservationModal
            ? false
            : isChangeReservation,
          isFromNewReservationModal: isFromNewReservationModal,
          isReservationWithSameGuests: isReservationWithSameGuests,
          customerId,
        },
      });
    } catch (err) {
      if (onSelectProductInstanceError) {
        onSelectProductInstanceError(t('System error'));
      }

      return;
    }
  };

  const requestedReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'REQUESTED'
  ).length;
  const standbyReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'STANDBY'
  ).length;
  const declinedReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'DECLINED_BY_SUPPLIER'
  ).length;
  const uncountedUnitsText = !reservationsLoading
    ? getUncountedUnitsText(allotmentSettings, reservations)
    : '';

  const getSearchString = () => {
    const params: string[] = [];
    if (isFromNewReservationModal) {
      params.push('new_reservation_modal=1');
    }

    // Try to get customer id from query parameters if any
    // Do not include if undefined after trying
    const stateSearch = location && location.search;
    const currentParams = new URLSearchParams(stateSearch);
    const tempCustomerId = customerId || currentParams.get('customer_id');

    if (tempCustomerId) {
      params.push(
        `customer_id=${customerId || currentParams.get('customer_id')}`
      );
    }

    if (params.length === 0) {
      return '';
    }
    return '?' + params.join('&');
  };

  return (
    <div
      className={clsx(
        styles['p-reservationsCreate__info'],
        open && styles['is-active']
      )}
    >
      <div
        className={clsx(
          styles['p-reservationsCreate__info__header'],
          baseStyles['u-pcHidden']
        )}
      >
        <p>{t('Confirm Contents')}</p>
        <Button
          size="icon"
          color="white"
          iconAfterText={<i className="c-icon-outline-general-x-close"></i>}
          onClick={handleClose}
        />
      </div>
      <div className={styles['p-reservationsCreate__info__body']}>
        <div className={styles['p-reservationsCreate__info__item']}>
          <p className={styles['p-reservationsCreate__info__item__ttl']}>
            {t('Product')}
          </p>
          <div className={styles['p-reservationsCreate__info__item__body']}>
            {productName ? <p>{productName}</p> : <ModalLoader />}
          </div>
        </div>
        {(productInstance.promotions || []).length > 0 && (
          <div className={styles['p-reservationsCreate__info__item']}>
            <p className={styles['p-reservationsCreate__info__item__ttl']}>
              {t('Discounts')}
            </p>
            <div className={styles['p-reservationsCreate__info__item__body']}>
              <p>
                {[
                  ...new Set(
                    (productInstance.promotions || []).map(
                      (promo) => promo.type
                    )
                  ),
                ]
                  .map((promoType) =>
                    promoType ? getPromotionTypeText(promoType, t) : ''
                  )
                  .join(', ')}
              </p>
            </div>
          </div>
        )}
        {formattedInstantBookingDeadline && (
          <div className={styles['p-reservationsCreate__info__item']}>
            <p className={styles['p-reservationsCreate__info__item__ttl']}>
              {t('Date & Start time')}
            </p>
            <div className={styles['p-reservationsCreate__info__item__body']}>
              {start ? <p>{start}</p> : <ModalLoader />}
            </div>
          </div>
        )}
        <div className={styles['p-reservationsCreate__info__item']}>
          <p className={styles['p-reservationsCreate__info__item__ttl']}>
            {t('Instant confirmation deadline')}
          </p>
          <div className={styles['p-reservationsCreate__info__item__body']}>
            {instantDeadlinePassed ? (
              <span className={styles['red']}>
                {t('Instant booking deadline passed')}
              </span>
            ) : (
              <p>{formattedInstantBookingDeadline}</p>
            )}
          </div>
        </div>
        {process.env.NODE_ENV === 'development' && (
          <div className={styles['p-reservationsCreate__info__item']}>
            <div className={styles['page-availability__modal__ttl']}>
              {productInstance.id}
            </div>
          </div>
        )}
        <>
          {operationAllowed(activeUser, 'write', 'reservationConfirmation') ? (
            <>
              {uncountedUnitsText && (
                <div className={styles['p-reservationsCreate__info__item']}>
                  <div
                    className={clsx(
                      styles['p-reservationsCreate__info__item__ttl'],
                      styles['flex']
                    )}
                  >
                    <div
                      className={
                        styles[
                          'page-availability__modal__num__header__uncounted__units'
                        ]
                      }
                    >
                      {uncountedUnitsText}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : getAvailabilityIconType(
              instance.bookedSlots,
              instance.totalSlots,
              instance.allChannelsClosed,
              shouldRejectBookingsBeyondCapacity,
              productInstance.booking_deadlines
            ) === 'REQUEST' ? (
            <>
              <div className={styles['p-reservationsCreate__info__item']}>
                <div
                  className={clsx(
                    styles['p-reservationsCreate__info__item__ttl'],
                    styles['flex']
                  )}
                >
                  <img src={rqIcon} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles['p-reservationsCreate__info__item']}>
                <div
                  className={clsx(
                    styles['p-reservationsCreate__info__item__ttl'],
                    styles['flex']
                  )}
                >
                  <p>
                    {t('{{count}} spot', {
                      count: formattedTotalAllotmentSlots(
                        productInstance.available_slots || 0
                      ),
                    })}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
        {instance.bookedSlots === instance.totalSlots &&
          instance.totalSlots !== 0 && (
            <div className={styles['p-reservationsCreate__info__item']}>
              <div
                className={clsx(
                  styles['page-availability__modal__num__header__label']
                )}
              >
                <span className={styles['red']}>{t('Sold out')}</span>
              </div>
            </div>
          )}
        {reservationsLoading ? (
          <div className={styles['p-reservationsCreate__info__item']}>
            <ModalLoader />
          </div>
        ) : (
          <>
            {operationAllowed(
              activeUser,
              'write',
              'reservationConfirmation'
            ) && (
              <>
                {requestedReservationsCount +
                  standbyReservationsCount +
                  declinedReservationsCount >
                  0 && (
                  <div className={styles['p-reservationsCreate__info__item']}>
                    <div className={styles['nonConfirmedReservations']}>
                      {requestedReservationsCount > 0 && (
                        <p>
                          {`${t(
                            'Requested reservations: '
                          )}${requestedReservationsCount}`}
                        </p>
                      )}
                      {standbyReservationsCount > 0 && (
                        <p>
                          {`${t(
                            'Standby reservations: '
                          )}${standbyReservationsCount}`}
                        </p>
                      )}
                      {declinedReservationsCount > 0 && (
                        <p>
                          {`${t(
                            'Declined reservations: '
                          )}${declinedReservationsCount}`}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className={styles['p-reservationsCreate__info__item']}>
          {operationAllowed(activeUser, 'write', 'reservationConfirmation') && (
            <ul className={styles['slots']} style={{ width: '100%' }}>
              <li key="COMMON" style={{ borderBottom: 'none' }}>
                <p>{t('COMMON')}</p>
                <p>{`${
                  productInstance.occupied_slots || 0
                }/${formattedTotalAllotmentSlots(
                  productInstance.total_slots || 0
                )}`}</p>
              </li>
              {dedicatedAllotments.map((a) => {
                if (a.agent_id) {
                  return (
                    <li key={a.agent_id} style={{ borderBottom: 'none' }}>
                      <p>{a.agent_name || ''}</p>
                      <p>{`${a.occupied_slots || 0}/${a.total_slots || 0}`}</p>
                    </li>
                  );
                }

                return (
                  <li key={a.channel_category} style={{ borderBottom: 'none' }}>
                    <p>{t(a.channel_category as any)}</p>
                    <p>{`${a.occupied_slots || 0}/${a.total_slots || 0}`}</p>
                  </li>
                );
              })}
              <li
                key="TOTAL"
                className={styles['total']}
                style={{ borderBottom: 'none' }}
              >
                <p>{t('Total')}</p>
                <p>{`${instance.bookedSlots}/${formattedTotalAllotmentSlots(
                  instance.totalSlots
                )}`}</p>
              </li>
            </ul>
          )}
          <div
            className={styles['p-reservationsCreate__info__item__body__flex']}
            style={{ marginTop: '12px' }}
          >
            {!isChangeReservation &&
              onEditClick &&
              !userIsPassthroughSupplier &&
              hasCustomUserRoleWritePermissions(activeUser, 'AVAILABILITY') &&
              componentInstances.length === 0 && (
                <Button
                  text={t('Edit allotment')}
                  size="md"
                  color="white"
                  onClick={() => {
                    onEditClick && onEditClick(productInstance);
                  }}
                />
              )}
            {onListClick &&
              operationAllowed(activeUser, 'read', 'manifests') && (
                <Button
                  text={t('See reservations')}
                  size="md"
                  color="white"
                  onClick={() => {
                    onListClick(productInstance);
                  }}
                />
              )}
          </div>
        </div>
        {productInstance?.memo?.value && (
          <div className={clsx(styles['p-reservationsCreate__info__item'])}>
            <p className={styles['p-reservationsCreate__info__item__ttl']}>
              {t('Memo')}
            </p>
            <div
              className={clsx(
                styles['p-reservationsCreate__info__item__body'],
                styles['p-reservationsCreate__info__item__body__note']
              )}
            >
              <TextArea
                value={productInstance?.memo?.value ?? ''}
                height={100}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                disabled={true}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles['p-reservationsCreate__info__actions']}>
        {((instanceIsClosed &&
          operationAllowed(activeUser, 'write', 'productInstances')) ||
          !instanceIsClosed) &&
        !instanceError &&
        hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') ? (
          <>
            {reservation ? (
              <>
                <Button
                  text={
                    isChangeReservation
                      ? t('Change to this date & time')
                      : t('Create new reservation')
                  }
                  size="md"
                  onClick={onMoveRebookForm}
                  useFullWidth={true}
                />
              </>
            ) : config.enableCreateReservationButtonForPassthroughSupplier ||
              !userIsPassthroughSupplier ? (
              <Link
                to={{
                  // TODO: remove v3 from url for release
                  pathname: `/products/${productInstance.product_id}/instances/${productInstance.id}/book-v3`,
                  search: getSearchString(),
                }}
              >
                <Button
                  text={t('Create new reservation')}
                  size="md"
                  useFullWidth={true}
                />
              </Link>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
