import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { fetchProductTranslations } from 'client/actions/translations';
import { isNullTranslation } from 'client/libraries/isNullTranslation';
import { getTranslationSourceLanguageText } from 'client/libraries/getTranslationSourceLanguageText';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import type { SourceLanguage } from 'shared/models/swagger';

import {
  getETicketConvertFormValuesToProductPatch,
  getInitialValues,
} from '../QRCheckinSettingsModal/QRCheckinSettingsFormValues';

import { ETicketSettingsForm } from './ETicketSettingsForm';

type Props = {
  onClose: () => void;
};
export const ETicketSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage | null>(null);
  const productTranslationsLoading = useSelector(
    (state: ReduxState) => state.translations.loading
  );
  const productTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const product = React.useContext(EditingProductContext);
  const languageOptions = contentLanguageOptions
    .map((option) => ({
      value: lowercaseIsoToUppercaseIso[option.iso],
      text: getLanguageName(option.iso, t),
    }))
    .filter((option) => option.value !== product?.source_language);
  const defaultTranslationTargetLanguage = languageOptions[0].value;
  React.useEffect(() => {
    if (showTranslationMode) {
      setTranslationTargetLanguage(defaultTranslationTargetLanguage as any);
    } else {
      setTranslationTargetLanguage(null);
    }
  }, [defaultTranslationTargetLanguage, showTranslationMode]);
  React.useEffect(() => {
    if (product != null) {
      dispatch(fetchProductTranslations(product.id));
    }
  }, [product]);
  React.useEffect(() => {
    // Default translation mode to "ON" if product name is translated.
    // We assume that if product name is translated, user has input translations for the product.
    if (
      product &&
      productTranslations.some(
        (translation) =>
          !isNullTranslation(translation) &&
          getTranslationSourceLanguageText(translation) === product.product_name
      )
    ) {
      setShowTranslationMode(true);
    }
  }, [productTranslations, productTranslationsLoading, product]);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <TranslationLanguageContext.Provider value={translationTargetLanguage}>
      <ProductEditorModal
        open={true}
        onClose={onClose}
        title={t('E-ticket')}
        initialValues={initialValues}
        convertFormValuesToProductPatch={getETicketConvertFormValuesToProductPatch(
          product
        )}
      >
        <ETicketSettingsForm
          productId={product?.id ?? ''}
          languageOptions={languageOptions}
          showTranslationMode={showTranslationMode}
          translationTargetLanguage={translationTargetLanguage}
          onTranslationModeChange={setShowTranslationMode}
          onTranslationTargetLanguageChange={setTranslationTargetLanguage}
        />
      </ProductEditorModal>
    </TranslationLanguageContext.Provider>
  );
};
