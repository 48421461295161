// @flow

import * as React from 'react';
import { Button, Divider, Form, Header, Icon, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { updateReservation } from 'client/actions/reservations';
import { ButtonLink } from 'client/components/ButtonLink';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { Reservation } from 'shared/models/swagger';

type OwnProps = {
  reservation: Reservation,
  fieldName: 'agent_notes' | 'supplier_notes',
  onUpdateCompleted?: (void) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  newNotes: string,
};

class NotesEditButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      newNotes: this.currentNotes(),
    };
  }

  currentNotes = () => this.props.reservation[this.props.fieldName] || '';

  render() {
    const { fieldName, onUpdateCompleted, reservation, t } = this.props;
    const { newNotes, showModal } = this.state;

    return (
      <Modal
        trigger={
          <ButtonLink onClick={() => this.setState({ showModal: true })}>
            {t('edit')}
          </ButtonLink>
        }
        open={showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        className="fix-semantic-modal"
      >
        <Header
          icon="edit"
          content={this.props.headerName || t('Edit notes')}
        />
        <Modal.Content>
          <Form>
            <Form.TextArea
              value={newNotes}
              onChange={(e, { value: newNotes }) => this.setState({ newNotes })}
              placeholder={t('type notes here')}
            />
          </Form>
          <Divider section />
          <ReservationActorInputForm />
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={newNotes === this.currentNotes()}
            onClick={() =>
              this.setState({
                newNotes: this.currentNotes(),
              })
            }
          >
            {t('Discard')}
          </Button>
          <Button
            color="green"
            inverted
            disabled={newNotes === this.currentNotes()}
            onClick={() => {
              this.props
                .updateReservation(reservation.id, {
                  [fieldName]: newNotes,
                })
                .then(() => onUpdateCompleted && onUpdateCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            <Icon name="checkmark" /> {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateReservation: (id, patch) => dispatch(updateReservation(id, patch)),
});

export const NotesEditButton = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(NotesEditButtonComponent);
