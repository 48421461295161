// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { Button, Select } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { updateSurveySettings } from 'client/actions/surveySettings';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import { fetchSurveyTemplates } from 'client/actions/surveyTemplates';
import { SurveyTemplate } from 'shared/models/swagger';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { config } from 'client/config';

export const SurveySettings = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const language = convertToBookingWidgetUrlLangCode(i18n.language);

  const initialAvailableHours =
    organization?.survey_settings?.available_hours ?? 0;

  const [availableHours, setAvailableHours] = React.useState<number>(
    initialAvailableHours
  );

  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const npsSurveys = useSelector((state: ReduxState) =>
    state.surveyTemplates.all.filter(
      (template: SurveyTemplate): boolean =>
        template.is_nps_survey?.value === true &&
        template.status === 'SURVEY_TEMPLATE_PUBLISHED'
    )
  );

  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );

  const [selectedBwUrl, setSelectedBwUrl] = React.useState<string>('');

  const kioskUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key ?? ''
  )}/survey/verify`;
  const baseNpsSurveyUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key ?? ''
  )}/survey?lng=${language}&s=`;

  const [selectedNpsSurvey, setSelectedNpsSurvey] =
    React.useState<SurveyTemplate | null>(
      npsSurveys.length > 0 ? npsSurveys[0] : null
    );
  const [selectedNpsSurveyUrl, setSelectedNpsSurveyUrl] =
    React.useState<string>('');

  const bwUrlOptions = React.useMemo(() => {
    const options = [
      {
        text: t('Normal Booking Widget'),
        value: kioskUrl,
      },
    ];

    if (organization?.liff_app_domain_name) {
      options.push({
        text: t('LIFF App'),
        value: `https://${organization?.liff_app_domain_name ?? ''}/surveyauth`,
      });
    }

    if ((organization?.line_mini_app_domain_names || []).length > 0) {
      (organization?.line_mini_app_domain_names ?? []).reduce(
        (acc, domainName) => {
          if (domainName?.domain_name) {
            acc.push({
              text: t('LINE Mini App') + ` (${domainName?.domain_name ?? ''})`,
              value: `https://${domainName?.domain_name ?? ''}/surveyauth`,
            });
          }
          return acc;
        },
        options
      );
    }

    return options;
  }, [kioskUrl, t, organization]);

  React.useEffect(() => {
    if (selectedBwUrl === '') {
      setSelectedBwUrl(kioskUrl);
    }
  }, [kioskUrl]);

  React.useEffect(() => {
    if (selectedNpsSurvey) {
      setSelectedNpsSurveyUrl(baseNpsSurveyUrl + selectedNpsSurvey.id);
    } else if (npsSurveys && npsSurveys.length > 0) {
      setSelectedNpsSurveyUrl(baseNpsSurveyUrl + npsSurveys[0].id);
    } else {
      setSelectedNpsSurveyUrl('');
    }
  }, [selectedNpsSurvey, npsSurveys]);

  const reset = React.useCallback(() => {
    setAvailableHours(initialAvailableHours);
  }, [initialAvailableHours]);

  React.useEffect(() => {
    reset();
  }, [reset]);

  React.useEffect(() => {
    dispatch(fetchSurveyTemplates());
  }, []);

  const pristine = initialAvailableHours === availableHours;

  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            {bwUrlOptions.length > 1 ? (
              <tr>
                <th>
                  {t('Survey Link')}({t('With Reservation Verification')})
                </th>
                <td>
                  <Box mb={2}>
                    <Select
                      search
                      options={bwUrlOptions}
                      value={selectedBwUrl}
                      onChange={(e, { value }) => {
                        setSelectedBwUrl(value);
                      }}
                    />
                  </Box>
                  <Box mb={2}>
                    <a
                      className={baseStyles['base-link']}
                      href={selectedBwUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedBwUrl}
                    </a>
                  </Box>
                  <Box mt={2} mb={2}>
                    <QRCode value={selectedBwUrl} />
                  </Box>
                </td>
              </tr>
            ) : (
              <tr>
                <th>
                  {t('Survey Link')}({t('With Reservation Verification')})
                </th>
                <td>
                  <a
                    className={baseStyles['base-link']}
                    href={kioskUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {kioskUrl}
                  </a>
                  <Box mt={2} mb={2}>
                    <QRCode value={kioskUrl} />
                  </Box>
                </td>
              </tr>
            )}
            {hasCustomUserRoleWritePermissions(
              activeUser,
              'SURVEY.SETTINGS'
            ) && (
              <tr>
                <th>{t('Survey Availability')}</th>
                <td>
                  <Select
                    label={t('Survey available from')}
                    search
                    value={`${availableHours}`}
                    onChange={(e, { value }) => {
                      setAvailableHours(parseInt(value));
                    }}
                    options={[
                      {
                        text: t('participation start time'),
                        value: '0',
                      },
                      ..._.times(45, (i) => i + 1).map((day) => ({
                        key: day,
                        text: t(
                          '{{count}} hours after participation start time',
                          {
                            count: day,
                          }
                        ),
                        value: `${day}`,
                      })),
                    ]}
                  />
                </td>
              </tr>
            )}
            {config.enableNpsSurvey && (
              <tr>
                <th>
                  {t('Survey Link')}({t('Without Reservation Verification')})
                </th>
                <td>
                  <Select
                    search
                    label={`NPS ${t('Survey')}`}
                    value={
                      npsSurveys.find(
                        (survey) => survey.id === selectedNpsSurvey?.id
                      )?.id ?? ''
                    }
                    onChange={(_, { value }) => {
                      const survey = npsSurveys.find(
                        (survey) => survey.id === value
                      );
                      setSelectedNpsSurvey(survey ?? null);
                    }}
                    options={npsSurveys.map((survey) => {
                      return {
                        text: survey.template_name ?? '',
                        value: survey.id ?? '',
                      };
                    })}
                  />
                  {selectedNpsSurveyUrl && (
                    <>
                      <div style={{ marginTop: '16px' }}>
                        <a
                          className={baseStyles['base-link']}
                          href={selectedNpsSurveyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedNpsSurveyUrl}
                        </a>
                      </div>
                      <Box mt={2} mb={2}>
                        <QRCode value={selectedNpsSurveyUrl} />
                      </Box>
                    </>
                  )}
                </td>
              </tr>
            )}
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button
            style="gray"
            size="middle"
            disabled={pristine}
            onClick={reset}
          >
            {t('Discard')}
          </Button>
          <Button
            loading={loading}
            style="blue"
            size="middle"
            disabled={pristine}
            onClick={async () => {
              await dispatch(
                updateSurveySettings({
                  available_hours: availableHours,
                })
              );

              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
