import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { useWindowDimensions } from 'client/hooks/useWindowDimensions';

import styles from './SeatAssignmentReservations.module.css';

export interface ColumnType<T> {
  Header: string;
  id: string;
  accessor: (arg: T) => any;
  th?: boolean;
  sub?: boolean;
  width?: number;
  icon?: string;
}

interface Props<T> {
  columns: ColumnType<T>[];
  items: T[];
  draggable?: boolean;
  onDragStart?: (item: T) => void;
  onDragEnd?: (item: T) => void;
  onTrMouseEnter?: (item: T) => void;
  onTrMouseLeave?: (item: T) => void;
  highlightRow?: (item: T) => boolean;
  onTableMouseLeave?: () => void;
}

export const SeatAssignmentReservationTable = <T,>({
  columns,
  items,
  draggable = false,
  onDragStart,
  onDragEnd,
  onTrMouseEnter,
  onTrMouseLeave,
  highlightRow,
  onTableMouseLeave,
}: Props<T>) => {
  const { width } = useWindowDimensions();
  const isMobileView = width < 786;
  return (
    <div className={clsx(styles['stickyTable'])}>
      <table
        className={clsx(styles['table'])}
        onMouseLeave={() => {
          onTableMouseLeave?.();
        }}
      >
        <thead>
          {columns.map((column, idx) => {
            const width = column.width ? `${column.width}px` : 'auto';
            return (
              <th
                className={clsx(
                  styles['sticky-top'],
                  idx === 0 ? styles['sticky-left'] : ''
                )}
                style={{ width: width }}
                key={idx}
              >
                {column.icon && (
                  <Box display="flex" alignItems="center">
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      src={column.icon}
                      alt={column.Header}
                    />
                  </Box>
                )}
                {!column.icon && column.Header}
              </th>
            );
          })}
        </thead>

        <tbody>
          {items.map((item, ridx) => {
            return (
              <tr
                draggable={draggable}
                key={ridx}
                onDragStart={() => {
                  onDragStart?.(item);
                }}
                onDragEnd={() => {
                  onDragEnd?.(item);
                }}
                onMouseEnter={() => {
                  onTrMouseEnter?.(item);
                }}
                onMouseLeave={() => {
                  onTrMouseLeave?.(item);
                }}
                className={clsx(highlightRow?.(item) ? '' : styles['disable'])}
              >
                {columns.map((column, idx) => {
                  const width = column.width || '';

                  const displayValue = column.accessor(item);

                  if (column.th) {
                    return (
                      <th
                        key={idx}
                        style={isMobileView ? {} : { width: `${width}px` }}
                      >
                        {displayValue}
                      </th>
                    );
                  } else {
                    return (
                      <td
                        key={idx}
                        style={isMobileView ? {} : { width: `${width}px` }}
                        className={clsx(
                          column.sub ? styles['c-table-sub__btn'] : '',
                          idx === 0 ? styles['sticky-left'] : ''
                        )}
                        data-text={column.Header}
                      >
                        {displayValue}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
