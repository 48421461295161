import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'client/components/Form';
import { ListInput } from 'client/components/ListInput/ListInput';
import { Message } from 'client/components/Message/Message';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';

type FormValues = {
  subject: string;
  toAddresses: string[];
};
type Props = {
  title: string;
  open: boolean;
  value: FormValues;
  onOpen?: (arg0: void) => void;
  onClose: (arg0: void) => void;
  onSendClick: (arg0: FormValues) => void;
  emailStatus: string;
};
export const SendEmailModal = ({
  title,
  value,
  open,
  onOpen,
  onClose,
  onSendClick,
  emailStatus,
}: Props) => {
  const { t } = useTranslation();

  const createInitialValues = () => {
    return value;
  };

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      onOpen={() => {
        if (onOpen) {
          onOpen();
        }
      }}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            onSendClick(values);
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={createInitialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field name={'subject'}>
                    {({ input }) => (
                      <Input {...input} label={t('Subject')} required={true} />
                    )}
                  </Field>
                </Modal.Box>
                <Modal.Box>
                  <Field name={'toAddresses'}>
                    {({ input }) => (
                      <ListInput
                        label={t('Email addresses')}
                        value={input.value}
                        onChange={(toAddresses) => {
                          input.onChange(toAddresses);
                        }}
                      />
                    )}
                  </Field>
                </Modal.Box>
                <div>
                  {emailStatus === 'SUCCESS' && (
                    <Message success header={t('Email sent successfully')} />
                  )}
                  {submitError ||
                    (emailStatus === 'FAILURE' && (
                      <Message error header={t('Email failed to send')} />
                    ))}
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button.Cancel
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                  }}
                >
                  {t('Close')}
                </Button.Cancel>
                <Button
                  loading={submitting || emailStatus === 'IN_FLIGHT'}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Send')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
