import * as React from 'react';

import { useBounds } from 'client/hooks/useBounds';

import styles from './ProductReviewsPane.module.css';

type Props = {
  imageUrls: string[];
};
export const ImageSlider = ({ imageUrls }: Props) => {
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const [showModalImageUrl, setShowModalImageUrl] = React.useState<
    string | null
  >(null);
  React.useEffect(() => {
    const onWindowClick = (event: any) => {
      if (modalRef && !modalRef.current?.contains(event.target)) {
        setShowModalImageUrl(null);
      }
    };

    window.addEventListener('mousedown', onWindowClick);
    window.addEventListener('touchstart', onWindowClick);
    return () => {
      window.removeEventListener('mousedown', onWindowClick);
      window.addEventListener('touchstart', onWindowClick);
    };
  }, []);
  const [sliderOffset, setSliderOffset] = React.useState(0);
  const [firstItemRef, firstItemBounds] = useBounds<HTMLLIElement>();
  const [sliderListRef, sliderListBounds] = useBounds<HTMLUListElement>();
  const itemWidth = firstItemBounds.width + 16;
  const sliderTotalWidth = imageUrls.length * itemWidth;
  const sliderTotalRight =
    -sliderOffset + sliderListBounds.left + sliderTotalWidth + 16;
  const sliderVisibleRegionRight = sliderListBounds.right - 16;
  const sliderVisibleRegionWidth =
    sliderListBounds.right - sliderListBounds.left - 32;
  const remainingStripWidth = sliderTotalRight - sliderVisibleRegionRight;

  const handlePrev = () => {
    // If we are already at the left bounds, do nothing
    if (sliderOffset === 0) {
      return;
    }

    // Reduce offset by the minimum between the current offset or the slider list width.
    const delta = Math.min(sliderOffset, sliderVisibleRegionWidth);
    // Apply delta and round down to a multiple of item width
    const newOffset =
      Math.floor((sliderOffset - delta) / itemWidth) * itemWidth;
    setSliderOffset(newOffset);
  };

  const handleNext = () => {
    // If we are already at the right bounds or the entire group of items fits onscreen, do nothing.
    if (sliderTotalRight <= sliderVisibleRegionRight) {
      return;
    }

    // Increase offset by the minimum of the remaining strip width or the space between the arrows.
    const delta = Math.min(remainingStripWidth, sliderVisibleRegionWidth);
    const newOffset =
      delta === remainingStripWidth
        ? sliderOffset + delta
        : Math.floor((sliderOffset + delta) / itemWidth) * itemWidth;
    setSliderOffset(newOffset);
  };

  const itemStyle = {
    transform: `translateX(-${sliderOffset}px)`,
  };
  const showNextButton = sliderTotalRight > sliderVisibleRegionRight + 16;
  const showPrevButton = !!sliderOffset;
  return (
    <>
      {showModalImageUrl && (
        <div className={styles['c-reviewPicture__modal']}>
          <div
            ref={modalRef}
            className={styles['c-reviewPicture__modal__modal__content']}
          >
            <div
              className={styles['c-reviewPicture__modal__modal__content__pic']}
            >
              <img src={showModalImageUrl} />
            </div>
            <a
              className={
                styles['c-reviewPicture__modal__modal__content__close']
              }
              onClick={() => setShowModalImageUrl(null)}
            ></a>
          </div>
        </div>
      )}
      <div className={styles['c-review__list__comment__item__pic']}>
        <ul
          className={styles['c-review__list__comment__item__pic__list']}
          ref={sliderListRef}
        >
          {imageUrls.map((url, idx) => (
            <li
              style={itemStyle}
              key={idx}
              ref={idx === 0 ? firstItemRef : null}
              onClick={() => setShowModalImageUrl(url)}
            >
              <img src={url} />
            </li>
          ))}
        </ul>
        {showPrevButton && (
          <a
            className={styles['c-review__list__comment__item__pic__prev']}
            onClick={handlePrev}
          ></a>
        )}
        {showNextButton && (
          <a
            className={styles['c-review__list__comment__item__pic__next']}
            onClick={handleNext}
          ></a>
        )}
      </div>
    </>
  );
};
