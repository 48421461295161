import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  maxThreshold: number;
};

export const ReservationBulkCancelMaxThresholdModal = ({
  setShowModal,
  maxThreshold,
}: Props) => {
  const { t } = useTranslation();

  const text = t(
    'Bulk cancellation/decline is limited to maximum of {{n}} reservations.',
    {
      n: maxThreshold,
    }
  );

  return (
    <Modal
      title={t('Cancel/Decline Reservations')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <div>{text}</div>
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="submit"
          text={t('OK')}
          uiType="bg"
          size="md"
          color="primary"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};
