import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { useFormState, Field } from 'react-final-form';

import { Input, Button, ToggleButton } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import type { SourceLanguage } from 'shared/models/swagger';

import localStyles from './ETicketStubModal.module.css';
import { DraggableStubOptionInputForm } from './DraggableStubOptionInputForm';

type Props = {
  name: string;
  stubKey: string;
  translationTargetLanguage: SourceLanguage | null;
  open: boolean;
  onClose: () => void;
  hideStubOptions?: boolean;
};
export const ETicketStubModal = ({
  name,
  stubKey,
  translationTargetLanguage,
  open,
  onClose,
  hideStubOptions,
}: Props) => {
  const { t } = useTranslation();
  const [showStubOptions, setShowStubOptions] = React.useState(false);
  const values = useFormState().values;

  hideStubOptions =
    hideStubOptions ||
    values.shouldUseCountGuestsWithGuestType ||
    values.shouldUseRedemptionCount;

  React.useEffect(() => {
    if (values) {
      const stub = values?.stubs?.find((stub: any) => stub.key === stubKey);

      if (stub?.options?.length > 0) {
        setShowStubOptions(true);
      }
    }
  }, [values]);
  return (
    <Modal
      title={
        stubKey
          ? t('Edit Ticket Usage {{stubKey}}', {
              stubKey: stubKey,
            })
          : t('Add New Ticket Usage')
      }
      onClose={onClose}
      open={open}
      insertRoot
    >
      <Modal.Content>
        <Modal.Box>
          <div className={localStyles['c-table-list']}>
            <table>
              <tbody
                style={{
                  width: '100%',
                }}
              >
                {translationTargetLanguage && <TranslationTableHeader />}
                <TranslatedField name={`${name}.text`}>
                  {({ input, translationInput }) => (
                    <tr>
                      <th>{t('Title')}</th>
                      <td>
                        <div className={baseStyles['base-flex']}>
                          <Input {...input} />
                        </div>
                      </td>
                      {translationTargetLanguage && (
                        <td>
                          <Input {...translationInput} />
                        </td>
                      )}
                    </tr>
                  )}
                </TranslatedField>
              </tbody>
            </table>
          </div>
        </Modal.Box>
        {!hideStubOptions && (
          <>
            <Modal.Box>
              <Box mb={2}>
                <ToggleButton
                  label={t('Use Ticket Usage Options')}
                  checked={showStubOptions}
                  onChange={() => {
                    setShowStubOptions(!showStubOptions);
                  }}
                />
              </Box>
            </Modal.Box>

            {showStubOptions && (
              <Modal.Box>
                <FieldArray name={`${name}.options`}>
                  {({ fields }) => (
                    <>
                      <div className={localStyles['c-table-list']}>
                        <Box mb={2}>
                          <Button
                            type="button"
                            style="green"
                            size="middle"
                            onClick={() => {
                              fields.push('');
                            }}
                          >
                            {t('Add Ticket Usage Option')}
                          </Button>
                        </Box>
                        <table>
                          <tbody
                            style={{
                              width: '100%',
                            }}
                          >
                            {translationTargetLanguage && (
                              <TranslationTableHeader />
                            )}
                            {fields.map((name, idx) => (
                              <DraggableStubOptionInputForm
                                key={idx}
                                idx={idx}
                                name={name}
                                translationTargetLanguage={
                                  translationTargetLanguage
                                }
                                onRemoveClick={() => {
                                  fields.remove(idx);
                                }}
                                onMoveItem={(
                                  dragIndex: number,
                                  hoverIndex: number
                                ) => {
                                  const dragItem = fields.value[dragIndex];
                                  const newItems = [...fields.value];
                                  newItems.splice(dragIndex, 1);
                                  newItems.splice(hoverIndex, 0, dragItem);
                                  newItems.forEach((item, index) => {
                                    fields.update(index, item);
                                  });
                                }}
                              />
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <span className={localStyles['c-table-list__comment']}>
                        {t('Drag-and-drop to reorder')}
                      </span>
                    </>
                  )}
                </FieldArray>
              </Modal.Box>
            )}
          </>
        )}
        {values.shouldUseRedemptionCount && (
          <>
            <Field name={`${name}.maxRedemptionCount`}>
              {({ input }) => {
                return (
                  <Box display="flex" alignItems="center" mt={2}>
                    <Input
                      label={t('Number of uses')}
                      type="number"
                      value={`${input.value}`}
                      onChange={(_, { value }) => {
                        if (value.startsWith('-')) {
                          return;
                        }

                        let newValue = undefined;

                        if (value.length > 0) {
                          newValue = parseInt(value, 10);

                          if (isNaN(newValue)) {
                            newValue = undefined;
                          } else {
                            if (newValue < 1) {
                              newValue = 1;
                            }
                          }
                        }

                        input.onChange(newValue);
                      }}
                    />
                  </Box>
                );
              }}
            </Field>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="green"
          size="middle"
          type="submit"
          onClick={() => onClose()}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
