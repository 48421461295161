// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { LabelWithHelpText } from 'client/components/LabelWithHelpText';
import type { TranslateFuncType } from 'client/components/Translate';

const getFormFieldHelpText = (
  format?: string,
  t: TranslateFuncType
): string => {
  if (format === 'alpha-name') {
    return t('Alphabet characters or spaces');
  } else if (format === 'non-negative-integer') {
    return t('Positive numeric');
  } else if (format === 'float') {
    return t('Numeric');
  } else if (format === 'yyyy-mm-dd') {
    return 'YYYY-MM-DD';
  }

  return '';
};

export const FormField = (props: {
  prompt: string,
  onChange: (string) => void,
  options?: { text: string, value: string, key: string }[],
  error?: boolean,
  format?: string,
  required?: boolean,
  disabled?: boolean,
}) => {
  const {
    prompt,
    onChange,
    options,
    error,
    format,
    required,
    disabled,
    ...rest
  } = props;

  const { t } = useTranslation();

  const classes = [];
  if (error) {
    classes.push('error');
  }

  if (required) {
    classes.push('required');
  }

  return options && options.length > 0 ? (
    /* TODO: use radio buttons for select from < (N = 4?) options */
    <Form.Select
      {...rest}
      className={classes.join(' ')}
      name={prompt}
      label={prompt}
      onChange={(e, { value }) => onChange(value)}
      options={options}
      placeholder={props.prompt}
      disabled={!!disabled}
    />
  ) : (
    <Form.Input
      {...rest}
      className={classes.join(' ')}
      name={prompt}
      fluid
      label={
        <LabelWithHelpText
          text={prompt}
          helpText={getFormFieldHelpText(format, t)}
        />
      }
      onChange={(e, { value }) => onChange(value)}
      placeholder={prompt}
      disabled={!!disabled}
    />
  );
};
