import { config } from 'client/config';

declare global {
  interface Window {
    dataLayer: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(...args: any[]): void {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const configureGa = () => {
  window.dataLayer = window.dataLayer || [];

  if (config.googleAnalyticsMeasurementId) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsMeasurementId}`;
    document.getElementsByTagName('script')[0].after(script);
  }

  gtag('js', new Date());
};
export const trackPageView = (pathname: string) => {
  gtag('config', config.googleAnalyticsMeasurementId, {
    page_path: pathname,
    page_title: pathname,
  });
};
