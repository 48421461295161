import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  TopPage,
  Section,
  Highlight,
  MenuTile,
  CheckerboardTile,
  IconTile,
  ImageTile,
  GalleryImage,
} from '@nutmeglabs/falcon-ui';

import { getOrderedProductIds } from 'client/libraries/util/getOrderedProductIds';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import * as Swagger from 'shared/models/swagger';

type TFunc = (key: string, args?: Record<string, string>) => string;

export const getDefaultHeaderMenu = (t: TFunc): Menu => ({
  title: t('[TITLE]'),
  key: Math.random().toString(),
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
  style: 'DIRECT_LINK',
});

export const getDefaultHeaderSubmenu = (t: TFunc): Menu => ({
  title: t('[TITLE]'),
  key: Math.random().toString(),
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
  style: 'DIRECT_LINK',
});

export const getDefaultHighlight = (t: TFunc): Highlight => ({
  title: t('Title Goes Here'),
  key: Math.random().toString(),
  description: _.times(10, () => t('Description Text Goes Here. ')).join(''),
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/e95717ff-913d-5e05-8b95-bddfb11a3673/sea-turtle-547162_1920.jpg',
});

export const getDefaultHighlightsSection = (t: TFunc): Section => ({
  type: 'HIGHLIGHTS',
  backgroundColor: '#ffffff',
  title: t('Title Goes Here'),
  highlightsStyle: 'CIRCULAR_ROW',
  highlights: _.times(3, () => getDefaultHighlight(t)),
});

export const getDefaultMenuTile = (t: TFunc): MenuTile => ({
  title: t('Menu Name Goes Here'),
  key: Math.random().toString(),
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/0ae88db0-eb07-5655-9965-0047ad5afff9/tut/mountCook.jpg',
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
});

export const getDefaultMenuTilesSection = (t: TFunc): Section => ({
  type: 'MENU_TILES',
  backgroundColor: '#ffffff',
  title: t('Title Goes Here'),
  menuTiles: _.times(4, () => getDefaultMenuTile(t)),
});

export const getDefaultCheckerboardTile = (t: TFunc): CheckerboardTile => ({
  title: _.times(3, () => t('Title Goes Here. ')).join(''),
  key: Math.random().toString(),
  description: _.times(18, () => t('Description Text Goes Here. ')).join(''),
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/0ae88db0-eb07-5655-9965-0047ad5afff9/tut/mountCook.jpg',
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
  buttonText: t('VIEW MORE'),
  buttonTextColor: '#fff',
  buttonColor: '#0094CC',
});

export const getDefaultCheckerboardTilesSection = (t: TFunc): Section => ({
  type: 'CHECKERBOARD_TILES',
  backgroundColor: '#ffffff',
  title: t('Title Goes Here'),

  checkerboardTiles: _.times(3, () => getDefaultCheckerboardTile(t)),
});

export const getDefaultIconTile = (t: TFunc, iconUrl: string): IconTile => ({
  imageUrl: iconUrl,
  key: Math.random().toString(),
  description: _.times(5, () => t('Description Text Goes Here. ')).join(''),
  color: '#0094CC',
  linkText: t('VIEW MORE'),
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
});

const getDefaultIconTilesSection = (t: TFunc): Section => ({
  type: 'ICON_TILES',
  backgroundColor: '#ffffff',
  themeContentBackgroundColor: '#ffffff',
  title: t('Title Goes Here'),

  iconTiles: [
    getDefaultIconTile(
      t,
      'https://assets.ntmg.com/customtoppage/ic_sample01.png'
    ),
    getDefaultIconTile(
      t,
      'https://assets.ntmg.com/customtoppage/ic_sample02.png'
    ),
    getDefaultIconTile(
      t,
      'https://assets.ntmg.com/customtoppage/ic_sample03.png'
    ),
  ],
});

export const getDefaultImageTile = (t: TFunc): ImageTile => ({
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/0ae88db0-eb07-5655-9965-0047ad5afff9/tut/mountCook.jpg',
  key: Math.random().toString(),
  title: _.times(2, () => t('Title Goes Here. ')).join(''),
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
  linkText: t('VIEW MORE'),
  textColor: '#fff',
});

const getDefaultImageTilesSections = (t: TFunc): Section => ({
  type: 'IMAGE_TILES',
  backgroundColor: '#ffffff',
  title: t('Title Goes Here'),
  imageTiles: _.times(4, () => getDefaultImageTile(t)),
});

export const getDefaultGalleryImage = (
  t: TFunc,
  isVideo: boolean
): GalleryImage => ({
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/0ae88db0-eb07-5655-9965-0047ad5afff9/tut/mountCook.jpg',
  key: Math.random().toString(),
  isVideo,
  link: {
    type: 'FULL_URL',
    fullUrl: '',
  },
});

const getDefaultImageGallerySection = (t: TFunc): Section => ({
  type: 'IMAGE_GALLERY',
  backgroundColor: '#ffffff',
  title: t('Title Goes Here'),
  imageGallery: _.times(8, (idx) => getDefaultGalleryImage(t, idx % 2 === 0)),
});

export const getDefaultInfoSection = (
  sectionType:
    | 'HIGHLIGHTS'
    | 'MENU_TILES'
    | 'CHECKERBOARD_TILES'
    | 'ICON_TILES'
    | 'IMAGE_TILES'
    | 'IMAGE_GALLERY'
    | 'EDITORJS',
  t: TFunc
): Section => {
  switch (sectionType) {
    case 'HIGHLIGHTS':
      return getDefaultHighlightsSection(t);
    case 'MENU_TILES':
      return getDefaultMenuTilesSection(t);
    case 'CHECKERBOARD_TILES':
      return getDefaultCheckerboardTilesSection(t);
    case 'ICON_TILES':
      return getDefaultIconTilesSection(t);
    case 'IMAGE_TILES':
      return getDefaultImageTilesSections(t);
    case 'IMAGE_GALLERY':
      return getDefaultImageGallerySection(t);
    case 'EDITORJS':
      return getDefaultEditorJsSection(t);
  }
};

export const getDefaultEditorJsSection = (t: TFunc): Section => ({
  type: 'EDITORJS',
  title: t('Title Goes Here'),
  editorJsContent: '{}',
  backgroundColor: '#f9f9f9',
  themeContentBackgroundColor: '#ffffff',
});

export const getDefaultProductListSection = (
  organization: Swagger.Organization | null,
  products: Swagger.ProductSummary[],
  language: Swagger.SourceLanguage,
  t: TFunc
): Section => {
  const productIds = getOrderedProductIds(organization, products, language);

  return {
    type: 'PRODUCT_LIST',
    backgroundColor: '#ffffff',
    products: productIds.slice(0, 4).map((id) => ({
      productId: id,
    })),
    productListStyle: 'ROW_THREE_MEDIUM',
    title: t('Title Goes Here'),
    productCardButtonText: t('Learn More'),
    themeContentBackgroundColor: '#ffffff',
    themePrimaryColor: '#0094CC',
    themeSecondaryColor: '#ffcc00',
  };
};

export const usePageDefaults = (
  selectedLanguage: Swagger.SourceLanguage
): TopPage => {
  const transProps = useTranslation();

  const t = (key: string, args?: Record<string, string>) => {
    const argsWithLng = args ?? {};
    argsWithLng.lng = uppercaseIsoToLowercaseIso[selectedLanguage];

    return transProps.t(key, argsWithLng);
  };

  return {
    shouldUseCustomTopPage: false,
    headerMenus: [],
    catchPhrase: t('Thrilling marine adventures for the entire family'),
    catchPhraseStyle: {
      color: '#fff',
    },
    catchPhraseDescription: t(
      "Embark on a tour that will rekindle the excitement and joy of your childhood adventures. Experience Okinawa's breathtaking nature like never before, leaving you with unforgettable memories and endless smiles."
    ),
    catchPhraseDescriptionStyle: {
      color: '#fff',
    },
    keyVisualImageUrls: [
      'https://assets.ntmg.com/customtoppage/pic_keyvisual.jpg',
      'https://ntmg-media.s3.us-west-1.amazonaws.com/e95717ff-913d-5e05-8b95-bddfb11a3673/sea-turtle-547162_1920.jpg',
    ],
    useVideoKeyVisual: false,
    keyVisualVideoUrl: '',
    headerTextColor: '#fff',
    headerMobileMenuTextColor: '#0094CC',
    languageButtonColor: '#629cf3',
    keyVisualStyle: 'WAVES',
    keyVisualButtonText: t('Book Now'),
    keyVisualButtonColor: '#629cf3',
    keyVisualButtonLink: {
      type: 'FULL_URL',
      fullUrl: '',
    },
    pageDescription: t(
      'Local Experience-Oriented Reservation Site Setup Service'
    ),
    headerStyle: 'TRANSPARENT',
    headerBackgroundColor: '#fff',
    footerBackgroundColor: '',
    footerTextColor: '#000',
    sections: [getDefaultHighlightsSection(t)],
  };
};
