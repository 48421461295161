import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { toProductShape } from 'client/libraries/util/productShape';
import { MediaCarousel } from 'client/pages/ProductDetails/ProductContentsPane/MediaCarousel/MediaCarousel';
import { OperationInfo } from 'client/pages/ProductDetails/ProductContentsPane/OperationInfo/OperationInfo';
import { Highlights } from 'client/pages/ProductDetails/ProductContentsPane/Highlights/Highlights';
import { ProductOutline } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/ProductOutline';
import { ServiceList } from 'client/pages/ProductDetails/ProductContentsPane/ServiceList/ServiceList';
import { TourInformation } from 'client/pages/ProductDetails/ProductContentsPane/TourInformation/TourInformation';
import { AccordionList } from 'client/pages/ProductDetails/ProductContentsPane/AccordionList/AccordionList';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ShareButtons } from 'client/components/Sns/ShareButtons';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getBookingWidgetPmpUrlBase } from 'client/libraries/util/getBookingWidgetPmpUrlBase';
import type { AccordionItemContent } from 'client/pages/ProductDetails/ProductContentsPane/AccordionList/AccordionItem';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ProductContentsPane.module.css';

type OwnProps = {
  product: Product;
  contentLanguage?: string;
};
type Props = OwnProps;
export const ProductContentsPane = ({
  product: apiProduct,
  contentLanguage,
}: Props) => {
  const product = toProductShape(apiProduct);
  const { t, i18n } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const hasBookingWidgetPmp = Boolean(
    activeUserOrganization?.booking_widget_api_key
  );
  const urlBase = getBookingWidgetPmpUrlBase(activeUserOrganization);
  const showMediaCarousel = product.mediaURLs.length > 0;
  const showHighlights = product.highlights.length > 0;
  const showOutline =
    product.schedule.length > 0 ||
    product.pickupLocations.length > 0 ||
    product.checkinLocations.length > 0 ||
    product.dropoffLocations.length > 0 ||
    product.checkoutLocations.length > 0;
  const showAddonsAndTransportation =
    product.addOns.length > 0 || product.transportations.length > 0;
  const addOnsAccordionListItems: AccordionItemContent[] = [];

  if (product.addOns.length > 0) {
    addOnsAccordionListItems.push({
      header: t('Add-ons'),
      content: () => <ServiceList services={product.addOns} />,
      isActiveDefault: true,
    });
  }

  if (product.transportations.length > 0) {
    addOnsAccordionListItems.push({
      header: t('Transportation'),
      content: () => <ServiceList services={product.transportations} />,
      isActiveDefault: true,
    });
  }

  return (
    <div className={styles['base-single__card']}>
      {showMediaCarousel && (
        <section>
          <MediaCarousel urls={product.mediaURLs} />
        </section>
      )}
      <OperationInfo
        product={product}
        contentLanguage={contentLanguage || i18n.language}
      />
      {showHighlights && (
        <section className={baseStyles['base-single__section']}>
          <h2
            className={clsx(
              baseStyles['base-single__section__title'],
              styles['base-single-flex']
            )}
          >
            {t('Highlights')}
            {hasBookingWidgetPmp && (
              <ShareButtons
                url={`${urlBase}/products/${
                  product.id
                }?highlight=1&lng=${convertToBookingWidgetUrlLangCode(
                  contentLanguage || i18n.language
                )}`}
                locale={i18n.language}
                productName={product.name || ''}
              />
            )}
          </h2>
          <div className={baseStyles['base-padding-16']}>
            <Highlights highlights={product.highlights} />
          </div>
        </section>
      )}
      {showOutline && (
        <section className={baseStyles['base-single__section']}>
          <h2 className={baseStyles['base-single__section__title']}>
            {t('Outline')}
          </h2>
          <div className={baseStyles['base-padding-16']}>
            <ProductOutline product={product} />
          </div>
        </section>
      )}
      {showAddonsAndTransportation && (
        <section className={baseStyles['base-single__section']}>
          <h2 className={baseStyles['base-single__section__title']}>
            {t('Add-ons / Transportation')}
          </h2>
          <div className={baseStyles['base-padding-16']}>
            <AccordionList items={addOnsAccordionListItems} />
          </div>
        </section>
      )}
      <section className={baseStyles['base-single__section']}>
        <h2 className={baseStyles['base-single__section__title']}>
          {t('Plan Information')}
        </h2>
        <div className={baseStyles['base-padding-16']}>
          <TourInformation product={product} />
        </div>
      </section>
    </div>
  );
};
