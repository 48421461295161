import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';

import { Modal } from 'client/components/Modal/Modal';
import { Button, MultiSelect } from 'client/components/Form';
import { fetchProducts } from 'client/actions/products';
import { productOptionsSelector } from 'client/reducers/products';
import { ReduxState } from 'client/reducers';
import { ProductSummary } from 'shared/models/swagger';

interface Props {
  onImport: (products: ProductSummary[]) => void;
  onClose: () => void;
}

export const ImportProductsModal = ({ onClose, onImport }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [productIds, setProductIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const productOptions = useSelector(productOptionsSelector);
  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  return (
    <Modal title={t('Import Products')} open={true} onClose={onClose}>
      <Modal.Content>
        <MultiSelect
          label={t('Products')}
          selectedValues={productIds}
          onChange={({ value }) => setProductIds(value)}
          options={productOptions}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onImport(
              productSummaries.filter((product) =>
                productIds.includes(product.id)
              )
            );
            onClose();
          }}
          size="middle"
          style="blue"
        >
          {t('Import')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
