import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  activeUserOrganizationSelector,
  activeUserSelector,
  activeUserIsNutmegAdminSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import { fetchProducts } from 'client/actions/products';
import { AccountSettings } from 'client/pages/v3/Settings/component/AccountSettings';
import { ChangePassword } from 'client/pages/v3/Settings/component/ChangePassword';
import { MultiFactorAuthSettings } from 'client/pages/v3/Settings/component/MultiFactorAuthSettings';
import { ReservationSearchSettings } from 'client/pages/v3/Settings/component/ReservationSearchSettings';
import { CollapsibleSection } from 'client/pages/v3/Settings/component/CollapsibleSection/CollapsibleSection';
import { Loading } from 'client/components/v3/Common/Loading';
import { presetSearchConditionsEnabledForAccount } from 'shared/models/access';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Settings/Settings.module.css';
import { GuideReservationInfo } from 'client/pages/v3/Settings/component/GuideReservationInfo';
import { GuideNotificationMail } from 'client/pages/v3/Settings/component/GuideNotificationMail';

export const SettingsBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);
  const guideSettings = (organization?.guides || []).find(
    (guide) => guide.id === activeUser?.id
  );
  if (!isNutmegAdmin && !organization) {
    return <Loading size="md" />;
  }

  return (
    <div className={baseStyles['l-main__body']}>
      <section className={styles['g-section']}>
        <CollapsibleSection id="info" title={t('Account Info')}>
          <ul className={styles['p-general-list']}>
            <li className={styles['p-general-list__item']}>
              <div className={styles['p-general-list__item__ttl']}>
                <div className={styles['p-general-list__item__ttl__txt']}>
                  <div>{t('Account Info')}</div>
                </div>
              </div>
              <div className={styles['p-general-list__item__body']}>
                <div
                  className={styles['p-general-products__section__body__item']}
                >
                  <AccountSettings />
                </div>
              </div>
            </li>

            <li className={styles['p-general-list__item']}>
              <div className={styles['p-general-list__item__ttl']}>
                <div className={styles['p-general-list__item__ttl__txt']}>
                  <div>{t('Change Password')}</div>
                </div>
              </div>
              <div className={styles['p-general-list__item__body']}>
                <div
                  className={styles['p-general-products__section__body__item']}
                >
                  <ChangePassword />
                </div>
              </div>
            </li>

            <li className={styles['p-general-list__item']}>
              <div className={styles['p-general-list__item__ttl']}>
                <div className={styles['p-general-list__item__ttl__txt']}>
                  <div>{t('Two Factor Authentication')}</div>
                </div>
              </div>
              <div className={styles['p-general-list__item__body']}>
                <div
                  className={styles['p-general-products__section__body__item']}
                >
                  <MultiFactorAuthSettings />
                </div>
              </div>
            </li>

            {presetSearchConditionsEnabledForAccount(activeUser) && (
              <li className={styles['p-general-list__item']}>
                <div className={styles['p-general-list__item__ttl']}>
                  <div className={styles['p-general-list__item__ttl__txt']}>
                    <div>{t('Reservation List')}</div>
                  </div>
                </div>
                <div className={styles['p-general-list__item__body']}>
                  <div
                    className={
                      styles['p-general-products__section__body__item']
                    }
                  >
                    <ReservationSearchSettings />
                  </div>
                </div>
              </li>
            )}
          </ul>
        </CollapsibleSection>

        {organization && (
          <>
            {organization?.type === 'SUPPLIER' &&
              activeUser?.role === 'GUIDE' && (
                <CollapsibleSection id="others" title={t('Others')}>
                  <ul className={styles['p-general-list']}>
                    <li className={styles['p-general-list__item']}>
                      <div className={styles['p-general-list__item__ttl']}>
                        <div
                          className={styles['p-general-list__item__ttl__txt']}
                        >
                          <div>{t('Reservation info')}</div>
                        </div>
                      </div>
                      <div className={styles['p-general-list__item__body']}>
                        <div
                          className={
                            styles['p-general-products__section__body__item']
                          }
                        >
                          <GuideReservationInfo
                            activeUser={activeUser}
                            guideSettings={guideSettings || null}
                          />
                        </div>
                      </div>
                    </li>
                    <li className={styles['p-general-list__item']}>
                      <div className={styles['p-general-list__item__ttl']}>
                        <div
                          className={styles['p-general-list__item__ttl__txt']}
                        >
                          <div>{t('Automatic notifications')}</div>
                        </div>
                      </div>
                      <div className={styles['p-general-list__item__body']}>
                        <div
                          className={
                            styles['p-general-products__section__body__item']
                          }
                        >
                          <GuideNotificationMail
                            activeUser={activeUser}
                            guideSettings={guideSettings || null}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </CollapsibleSection>
              )}
          </>
        )}
      </section>
    </div>
  );
};
