import * as React from 'react';
import clsx from 'clsx';

import styles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

type Props = {
  error?: string;
  label?: string;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  value: string;
  width?: number;
  maxWidth?: number;
  disabled?: boolean;
  required?: boolean;
};

export const DateInput = ({ error, label, onChange, value }: Props) => {
  return (
    <div className={clsx(styles['base-form-box'])}>
      {label && <div className={styles['base-form-box__header']}>{label}</div>}
      <div className={styles['base-form-box__body']}>
        <label className={clsx(styles['base-form-calendar'])}>
          <img src={calendarIcon} />
          <input
            type="date"
            value={value}
            onChange={(e) => onChange(e, e.target)}
          />
          {error && <p className={styles['base-form-box__err']}>{error}</p>}
        </label>
      </div>
    </div>
  );
};
