import * as React from 'react';

import { useBounds } from 'client/hooks/useBounds';

import { PortalTooltip } from './PortalTooltip';
import styles from './TruncatedLabel.module.css';

interface Props {
  text: string;
}

export const TruncatedLabel = ({ text }: Props) => {
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowTooltip(false);
    };
    window.document.addEventListener('scroll', handleScroll, { capture: true });
    return () => {
      window.document.removeEventListener('scroll', handleScroll, {
        capture: true,
      });
    };
  }, []);

  return (
    <div
      onMouseEnter={() => {
        const scrollHeight = divRef.current?.scrollHeight ?? 0;
        const clientHeight = divRef.current?.clientHeight ?? 0;
        if (scrollHeight > clientHeight) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => setShowTooltip(false)}
      ref={divRef}
      className={styles['label']}
    >
      {text}
      {showTooltip && (
        <PortalTooltip
          text={text}
          style={{
            left: divBounds.left,
            top: divBounds.bottom,
          }}
        />
      )}
    </div>
  );
};
