import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Modal } from 'client/components/Modal/Modal';
import { Input } from 'client/components/Form';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { Button } from 'client/components/v3/Common/Button';

interface FormValues {
  toAddress: string;
}

interface Props {
  loading: boolean;
  showGuestEmailEditModal: boolean;
  onResendEmail: (toAddress?: string) => Promise<void>;
}

export const ResendEmailButton = ({
  loading,
  showGuestEmailEditModal,
  onResendEmail,
}: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const validateEmailAddress = React.useCallback(
    (email?: any) => {
      if (!email) return t('Required');
      if (!matchesFormat(email, 'email')) return t('Invalid Email');
      return undefined;
    },
    [t]
  );

  return (
    <>
      {showModal && (
        <Modal
          title={t('Resend Email')}
          insertRoot
          open={true}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={{}}
            onSubmit={(values: FormValues) => {
              onResendEmail(values.toAddress);
              setShowModal(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <Field name="toAddress" validate={validateEmailAddress}>
                    {({ input }) => (
                      <Input label={t('Email Address')} {...input} />
                    )}
                  </Field>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    type="submit"
                    text={t('Resend')}
                    uiType="bg"
                    size="md"
                    color="primary"
                  />
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
      <Button
        text={t('Resend')}
        uiType="bg"
        size="md"
        color="primary"
        loading={loading}
        onClick={() => {
          if (showGuestEmailEditModal) {
            setShowModal(true);
          } else {
            onResendEmail();
          }
        }}
      />
    </>
  );
};
