import clsx from 'clsx';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import baseStyles from 'client/v3-base.module.css';

import styles from './Dropdown.module.css';

type Option = {
  text: string;
  value: string;
};

type SingleDropdownProps = {
  label?: string;
  options: Option[];
  onChange: (selectedOption: string) => void;
  selectedOption: string;
  width?: number;
  disabled?: boolean;
  optionOpensAt?: 'bottom' | 'top';
  searchable?: boolean;
  placeholder?: string;
  menuStyle?: CSSProperties; // Use this to adjust placement of menu, eg. in a modal with overflow: hidden
  error?: string; // Pass error string to render error state with error message, pass empty string to render error state only without error message
  resetSearchText?: boolean; // Use this to reset search text due to external cause (eg. options changed in parent component)
  inputStyle?: CSSProperties; // Use this to style input

  // When renderOption is provided, it will be used to render the option instead of the default text
  renderOption?: (option: Option) => React.ReactNode;
  onClick?: (click: boolean) => void;
};

export const SingleDropdown = ({
  label,
  options,
  onChange,
  selectedOption,
  width,
  disabled,
  optionOpensAt = 'bottom',
  searchable = false,
  placeholder,
  menuStyle,
  error,
  resetSearchText = false,
  renderOption,
  inputStyle,
  onClick,
}: SingleDropdownProps) => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [placeHolder, setPlaceHolder] = useState<string>(placeholder || '');
  const [searchMode, setSearchMode] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showOptions &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowOptions(false);
        setIsClicked(false);
        onClick?.(false);
        if (searchable) {
          setSearchMode(false);
        }
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);
    window.document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [showOptions]);

  // If placeholder exists, prevent it from clashing with label
  useEffect(() => {
    if (placeHolder) {
      setIsClicked(true);
    }
  }, [placeHolder]);

  // Notify the calling component of selected option change
  const handleOptionClick = (value: string) => {
    onChange(value);
    setShowOptions(false);
    setIsClicked(false);
    onClick?.(false);
  };

  // Filter options
  const filteredOptions = options.filter((o) => {
    if (o.text === '') {
      return false;
    }

    if (searchable && searchText) {
      return replaceFullWidthCharactersWithHalfWidth(
        o.text.toLowerCase()
      ).includes(searchText.toLowerCase());
    }

    return true;
  });

  return (
    <div className={styles['c-dropdownWrapper']}>
      <fieldset
        ref={fieldSetRef}
        className={clsx(
          styles['c-dropdown'],
          isHovered && styles['is-hover'],
          isClicked && styles['is-active'],
          (isClicked || selectedOption) && styles['is-input'],
          disabled && styles['is-disabled'],
          error && styles['is-error']
        )}
        style={{ width: width ? `${width}px` : '' }}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          setShowOptions(true);
          setIsClicked(true);
          onClick?.(true);
          if (searchable) {
            setSearchMode(true);
            setPlaceHolder(
              (searchText ||
                options.find((option) => option.value === selectedOption)
                  ?.text) ??
                ''
            );
            setSearchText('');
          }
        }}
      >
        <div className={styles['c-dropdown__body']}>
          <label>
            {label && (
              <legend className={styles['c-dropdown__body__title']}>
                {label}
              </legend>
            )}
            <div className={styles['c-dropdown__body__selected']}>
              {searchable ? (
                <input
                  ref={inputRef}
                  className={
                    styles['c-dropdown__body__selected__input__canSearch']
                  }
                  type="text"
                  value={
                    resetSearchText
                      ? ''
                      : searchMode
                      ? searchText
                      : (searchText ||
                          options.find(
                            (option) => option.value === selectedOption
                          )?.text) ??
                        ''
                  }
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    onChange(event.target.value);
                  }}
                  placeholder={placeHolder}
                  style={inputStyle}
                />
              ) : (
                <div style={inputStyle}>
                  {options.find((option) => option.value === selectedOption)
                    ?.text ??
                    // If options are changed in parent element and selected option is not found, try to get value from selected option directly
                    selectedOption ??
                    ''}
                </div>
              )}
            </div>
          </label>
        </div>
        {/* Do not show options if it's empty */}
        {filteredOptions.length > 0 && (
          <ul
            className={clsx(
              styles['c-dropdown__menu'],
              showOptions && styles['is-active'],
              styles[optionOpensAt]
            )}
            style={menuStyle}
          >
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className={styles['c-dropdown__menu__item']}
                onClick={(event) => {
                  event.stopPropagation();
                  handleOptionClick(option.value);
                  // Reset search text after selecting an option
                  setSearchText('');
                  setSearchMode(false);
                }}
              >
                {renderOption ? renderOption(option) : option.text}
              </li>
            ))}
          </ul>
        )}
      </fieldset>
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </div>
  );
};
