import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import baseStyles from 'client/base.module.css';
import iconTutorialComplete from 'client/images/ic_tutorial_comp.svg';

import styles from '../Tutorial.module.css';

type Props = {
  popUpDisable: () => void;
};

export const SiteTemplateTutorialCompletePopUp = ({ popUpDisable }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const bwUrl = getBookingWidgetUrl(
    activeUserOrganization?.booking_widget_subdomain ?? ''
  );
  return (
    <>
      <div className={styles['page-tutorial__popupFrame']}>
        <div className={styles['page-tutorial__popupFinish']}>
          <p className={styles['page-tutorial__popupFinish__header']}>
            {t('STEP1 - Create Website Completed')}
          </p>
          <div className={styles['page-tutorial__popupFinish__body']}>
            <div className={styles['page-tutorial__popupFinish__body__pic']}>
              <img src={iconTutorialComplete} />
            </div>
            <p className={styles['page-tutorial__popupFinish__body__desc']}>
              {t('Your booking website has been created.')}
            </p>

            <div
              className={clsx(
                styles['page-tutorial__box'],
                styles['page-new__tutorial__box--step1'],
                styles['is-active'],
                styles['new-tutorial']
              )}
              style={{ background: '#fff' }}
              data-num="2"
            >
              <div className={styles['page-tutorial__box__left']}>
                <p
                  style={{ marginBottom: '15px' }}
                  className={styles['page-tutorial__box__left__next']}
                >
                  NEXT STEP
                </p>
                <p
                  style={{
                    fontSize: '16px',
                    marginBottom: '0',
                    marginRight: 'auto',
                    textAlign: 'start',
                  }}
                >
                  {t(
                    'Booking website is now ready for you to see and make a new booking.'
                  )}
                </p>
                <p style={{ fontSize: '16px', textAlign: 'start' }}>
                  {t(
                    'Texts, photos and product details are all based on a sample data. You will be able to edit them later.'
                  )}
                </p>
                <a
                  className={clsx(baseStyles['base-btn'])}
                  style={{
                    width: '128px',
                    height: '32px',
                    margin: 'auto',
                    color: '#fff',
                    background: '#008ec9',
                  }}
                  href={bwUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => popUpDisable?.()}
                >
                  {t('Open website')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
