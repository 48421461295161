import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Checkbox, Input } from 'client/components/Form';
import { updateInquiry } from 'client/actions/inquiries';
import { Box } from 'client/components/Box/Box';
import { ModalLoader } from 'client/components/ModalLoader';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { ReduxState } from 'client/reducers';
import { searchReservations } from 'client/actions/reservationSearch';
import { ColumnType } from 'client/libraries/util/ColumnType';
import {
  toReservationSummaryShape,
  ReservationSummaryShape,
} from 'client/libraries/util/reservationSummaryShape';
import { Inquiry } from 'shared/models/swagger';

interface Props {
  inquiry: Inquiry;
  onClose: () => void;
}

export const AssignReservationModal = ({ inquiry, onClose }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const inquiryLoading = useSelector(
    (state: ReduxState) => state.inquiries.loading
  );
  const [agentReference, setAgentReference] = React.useState('');
  const [lastSearchedAgentReference, setLastSearchedAgentReference] =
    React.useState('');
  const [selectedReservationId, setSelectedReservationId] = React.useState('');
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const apiReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const reservationSummaries = apiReservations.map((r) =>
    toReservationSummaryShape(r, i18n.language, t)
  );

  const columns = React.useMemo((): ColumnType<ReservationSummaryShape>[] => {
    return [
      {
        Header: '',
        width: 'short',
        Cell: (cellInfo) => (
          <Checkbox
            checked={selectedReservationId === cellInfo.original.id}
            onChange={() =>
              selectedReservationId === cellInfo.original.id
                ? setSelectedReservationId('')
                : setSelectedReservationId(cellInfo.original.id)
            }
          />
        ),
        id: 'select',
      },
      {
        Header: t('Application Number'),
        id: 'agent_reference',
        accessor: (row) => row.agent_reference,
        Cell: (cellInfo) => (
          <Link
            to={`/reservations/${cellInfo.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cellInfo.original.agent_reference}
          </Link>
        ),
        width: 'middle',
        th: true,
      },
      {
        Header: t('Booked Date'),
        id: 'booked_at',
        accessor: 'booked_at',
        width: 'middle',
        Cell: ({ original }) => original.booked_at.locale(locale).format('lll'),
      },
      {
        Header: t('Participation'),
        id: 'participates_at',
        accessor: 'participates_at',
        width: 'middle',
        Cell: ({ original }) =>
          original.participates_at.locale(locale).format('lll'),
      },
      {
        Header: t('Status'),
        id: 'status',
        width: 'middle',
        accessor: (row) => t(row.status),
      },
      {
        Header: t('Customer'),
        id: 'guest_display_name',
        width: 'middle',
        accessor: 'guest_display_name',
      },
      {
        Header: t('Product'),
        id: 'product_name',
        accessor: 'product_name',
        width: 'long',
        Cell: ({ original }) => (
          <Link
            to={`/products/${original.product_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original.product_name}
          </Link>
        ),
      },
      {
        Header: t('Units'),
        id: 'guest_description',
        width: 'middle',
        accessor: 'guest_description',
      },
      {
        Header: t('Total Pax'),
        id: 'guest_count',
        width: 'short',
        accessor: 'guest_count',
      },
    ];
  }, [selectedReservationId, locale]);

  return (
    <Modal
      insertRoot
      title={t('Assign Reservation')}
      open={true}
      onClose={onClose}
      width="wide"
    >
      <Modal.Content>
        <Box display="flex" alignItems="flex-end">
          <Box maxWidth="200px">
            <Input
              label={t('Application Number')}
              value={agentReference}
              onChange={(e, { value }) => setAgentReference(value)}
            />
          </Box>
          <Box ml={2}>
            <Button
              style="blue"
              size="middle"
              disabled={!agentReference}
              loading={loading}
              onClick={() => {
                setLastSearchedAgentReference(agentReference);
                dispatch(
                  searchReservations({ agent_reference: agentReference }, 20, 0)
                );
              }}
            >
              {t('Search')}
            </Button>
          </Box>
        </Box>

        <Box mt={2}>
          {loading ? (
            <ModalLoader />
          ) : (
            <CustomTable
              columns={columns}
              items={lastSearchedAgentReference ? reservationSummaries : []}
              useScrollButton={true}
              usePaging={false}
            />
          )}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={!selectedReservationId}
          style="blue"
          size="middle"
          type="submit"
          loading={inquiryLoading}
          onClick={async () => {
            await dispatch(
              updateInquiry(inquiry.id ?? '', {
                reservation_id: selectedReservationId,
              })
            );
            onClose();
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
