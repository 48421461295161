import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateManifestProductGroups } from 'client/actions/manifestSettings';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import type { ManifestProductGroup } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  productGroupToDelete: ManifestProductGroup | null;
  open: boolean;
  onClose: () => void;
};

export const DeleteProductGroupModal = ({
  productGroupToDelete = null,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allProductGroups = useSelector((state: ReduxState) =>
    manifestProductGroupsSelector(state)
  );

  return productGroupToDelete ? (
    <Modal
      title={t('Delete Manifest Group')}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button text={t('Cancel')} color="white" onClick={onClose} />
          <Button
            color="red"
            text={t('Delete')}
            onClick={() => {
              const newProductGroups = allProductGroups.filter(
                (group) => group.key !== productGroupToDelete.key
              );
              dispatch(updateManifestProductGroups(newProductGroups));
              onClose();
            }}
          />
        </>
      }
    >
      {t('Are you sure you want to delete "{{name}}"?', {
        name: productGroupToDelete.key,
      })}
    </Modal>
  ) : null;
};
