import clsx from 'clsx';
import { useState } from 'react';

import { formatStartTime } from 'client/pages/ProductDetails/formatStartTime';
import { applyRelativeTimeOffset } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/util';
import type { ProductShape } from 'client/libraries/util/productShape';
import styles from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductOutline/ProductOutline.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

interface Props {
  product: ProductShape;
}
export const ScheduleTab = ({ product }: Props) => {
  const [selectedStartTime, setSelectedStartTime] = useState<string>(
    product.startTimes.length > 0 ? product.startTimes[0].hhMm : ''
  );

  const handleStartTimeChange = (val: any) => {
    setSelectedStartTime(val);
  };

  const options = product.startTimes.map((startTime) => ({
    text: formatStartTime(startTime),
    value: startTime.hhMm,
  }));

  return (
    <div className={styles['c-schedule']}>
      <div className={styles['singleDropdown__container']}>
        <SingleDropdown
          options={options}
          selectedOption={selectedStartTime}
          onChange={handleStartTimeChange}
        />
      </div>
      <ul className={styles['c-schedule__list']}>
        {product.schedule.map((event) => {
          const eventStartTime = event.startTimeRelative
            ? applyRelativeTimeOffset(
                selectedStartTime,
                event.startTimeRelative
              )
            : '';
          const eventEndTime = event.endTimeRelative
            ? applyRelativeTimeOffset(selectedStartTime, event.endTimeRelative)
            : '';
          const timeDescription = eventEndTime
            ? `${eventStartTime} - ${eventEndTime}`
            : eventStartTime;
          return (
            <li key={event.name} className={styles['c-schedule__list__item']}>
              <p className={styles['c-schedule__list__item__time']}>
                {timeDescription}
              </p>
              <div className={styles['c-schedule__list__item__body']}>
                <p className={styles['c-schedule__list__item__body__title']}>
                  {event.name}
                </p>
                <p
                  className={clsx(
                    styles['c-schedule__list__item__body__text'],
                    'newline'
                  )}
                >
                  {event.description}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
