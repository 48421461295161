import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { TranslateFuncType } from 'client/components/Translate';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import {
  BusinessLineItem,
  InvoiceReservationColumn,
  InvoiceSummaryColumn,
  InvoiceSummaryRow,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './SummaryTable.module.css';

export const getSummaryColumnHeader = (
  column: InvoiceSummaryColumn,
  t: TranslateFuncType
): string => {
  switch (column) {
    case 'GROSS':
      return t('Gross Final');
    case 'NET':
      return t('Net Final');
    case 'COMMISSION':
      return t('Commission Final');
    case 'AMOUNT_DUE_BY_AGENT':
      return t('Due by Agent');
    case 'AMOUNT_DUE_BY_SUPPLIER':
      return t('Due by Supplier');
  }

  return '';
};

export const getSummaryRowHeader = (
  column: InvoiceSummaryRow,
  t: TranslateFuncType
): string => {
  switch (column) {
    case 'PIF':
      return t('PIF');
    case 'POB':
      return t('POB');
    case 'SPLIT_PAYMENT':
      return t('Split Payment');
    case 'SUBTOTAL':
      return t('Subtotal');
    case 'INVOICE_ADJUSTMENT':
      return t('Invoice Adjustment');
  }

  return '';
};

export interface InvoiceReservationColumnType {
  Header: string;
  id: string;
  renderCell: (row: BusinessLineItem) => React.ReactNode;
}

export const getReservationColumnHeader = (
  column: InvoiceReservationColumn,
  t: TranslateFuncType
): string => {
  const columns = getInvoiceReservationColumns(t);

  return columns.find((c) => c.id === column)?.Header || '';
};

export const useInvoiceReservationColumns =
  (): InvoiceReservationColumnType[] => {
    const { t } = useTranslation();

    return getInvoiceReservationColumns(t);
  };

const getInvoiceReservationColumns = (
  t: TranslateFuncType
): InvoiceReservationColumnType[] => {
  return [
    {
      Header: t('Application Number'),
      id: 'AGENT_REFERENCE',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.payer_reference}</td>
      ),
    },
    {
      Header: t('Confirmation Number'),
      id: 'SUPPLIER_REFERENCE',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.payee_reference}</td>
      ),
    },
    {
      Header: t('Reservation Short ID'),
      id: 'SHORT_ID',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>
          {row.id && (
            <Link
              to={typeof row.id === 'string' ? `/reservations/${row.id}` : ''}
            >
              {row.id?.slice(0, row.id.indexOf('-'))}
            </Link>
          )}
        </td>
      ),
    },
    {
      Header: t('Reservation Status'),
      id: 'RESERVATION_STATUS',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>
          {t(row.business_line_item_status as any)}
        </td>
      ),
    },
    {
      Header: t('Product Name'),
      id: 'PRODUCT_NAME',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>
          {row.business_line_item_name}
        </td>
      ),
    },
    {
      Header: t('Internal Product Name'),
      id: 'INTERNAL_PRODUCT_NAME',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>
          {row.business_line_item_internal_product_name}
        </td>
      ),
    },
    {
      Header: t('Participation Date'),
      id: 'PARTICIPATES_AT',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.billing_date_time}</td>
      ),
    },
    {
      Header: t('Gross Final'),
      id: 'GROSS',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          {getFormattedAmount(row.amount_gross)}
        </td>
      ),
    },
    {
      Header: t('Net Final'),
      id: 'NET',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          {getFormattedAmount(row.amount_net)}
        </td>
      ),
    },
    {
      Header: t('Commission Final'),
      id: 'COMMISSION',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          {getFormattedAmount(row.amount_commission)}
        </td>
      ),
    },
    {
      Header: t('Amount Paid Onboard'),
      id: 'POB_AMOUNT',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          {getFormattedAmount(row.amount_pay_onboard)}
        </td>
      ),
    },
    {
      Header: t('Due by Agent'),
      id: 'AMOUNT_DUE_BY_AGENT',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          <strong>{getFormattedAmount(row.amount_due_by_agent)}</strong>
        </td>
      ),
    },
    {
      Header: t('Due by Supplier'),
      id: 'AMOUNT_DUE_BY_SUPPLIER',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          <strong>{getFormattedAmount(row.amount_due_by_supplier)}</strong>
        </td>
      ),
    },
    {
      Header: t('Customer'),
      id: 'GUEST_DISPLAY_NAME',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>
          {row.customer_display_name}
        </td>
      ),
    },
    {
      Header: t('Given Name'),
      id: 'GIVEN_NAME',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.customer_given_name}</td>
      ),
    },
    {
      Header: t('Family Name'),
      id: 'FAMILY_NAME',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.customer_family_name}</td>
      ),
    },
    {
      Header: t('Units'),
      id: 'GUEST_DESCRIPTION',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.pax_info}</td>
      ),
    },
    {
      Header: t('Total Pax'),
      id: 'GUEST_COUNT',
      renderCell: (row: BusinessLineItem) => (
        <td className={clsx(styles['right'], baseStyles['base-t-112'])}>
          {row.reservation_total_pax}
        </td>
      ),
    },
    {
      Header: t('Transportation'),
      id: 'TRANSPORTATION',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.transportation}</td>
      ),
    },
    {
      Header: t('Add-ons'),
      id: 'ADD_ONS',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.add_ons_description}</td>
      ),
    },
    {
      Header: t('Remarks'),
      id: 'AGENT_NOTES',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.agent_notes}</td>
      ),
    },
    {
      Header: t('Replies'),
      id: 'SUPPLIER_NOTES',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.supplier_notes}</td>
      ),
    },
    {
      Header: t('#'),
      id: 'ID',
      renderCell: (row: BusinessLineItem) => (
        <td className={baseStyles['base-t-112']}>{row.id}</td>
      ),
    },
  ];
};
