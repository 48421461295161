import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { Button, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { SourceLanguage } from 'shared/models/swagger';

import { CustomTopPageTemplate } from './CustomTopPageTemplate';

interface Props {
  language: SourceLanguage;
}

export const PageEditor = ({ language }: Props) => {
  const { t } = useTranslation();

  const form = useForm();
  const { submitting, submitSucceeded } = form.getState();

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        mb={4}
      >
        <Field name="shouldUseCustomTopPage">
          {({ input }) => (
            <ToggleButton
              label={t('Use custom top page')}
              checked={input.value}
              onChange={() => input.onChange(!input.value)}
            />
          )}
        </Field>
        <Box ml="auto">
          <Button
            type="submit"
            style="green"
            size="middle"
            loading={submitting}
          >
            {t('Save')}
          </Button>
        </Box>
      </Box>
      {submitSucceeded && <Message success>{t('Save Successful')}</Message>}
      <div style={{ background: 'white' }}>
        <CustomTopPageTemplate editMode language={language} />
      </div>
    </>
  );
};
