import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { Button, Select, FieldWrapper } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import styles from 'client/pages/ManifestCustomize/AlternateName/AlternateName.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  name: string;
  options: {
    key: string;
    value: string;
    text: string;
  }[];
  label: string;
  values: string[];
};
export const ResourceList = ({ name, options, label, values }: Props) => {
  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return (
          <FieldWrapper label={label}>
            <table className={clsx(styles['scheds__body__content'])}>
              <tbody>
                {fields.map((name, idx) => {
                  return (
                    <tr key={`${name}-${idx}`}>
                      <td>
                        <Field name={`${name}`}>
                          {({ input }) => (
                            <Select
                              options={options.filter((option) => {
                                // Remove existing product name
                                return !values.includes(option.value);
                              })}
                              value={
                                options.find((p) => p.value === input.value)
                                  ?.text || ''
                              }
                              onChange={(e, { value }) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </td>
                      <td>
                        <DeleteIcon onClick={() => fields.remove(idx)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  fields.push('');
                }}
              >
                {t('Add')}
              </Button>
            </div>
          </FieldWrapper>
        );
      }}
    </FieldArray>
  );
};
