import * as React from 'react';
import { Button, Form, Header, List, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  createProductAgentReference,
  updateProductAgentReference,
} from 'client/actions/productAgentReferences';
import { activeUserSelector } from 'client/reducers/user';
import { ListSelect } from 'client/components/ListSelect';
import { formattedTimeSlot } from 'client/libraries/util/formattedTimeSlot';
import { getTimeSlotsFromRecurrence } from 'client/libraries/util/getTimeSlots';
import {
  getProductAddOnKeys,
  getProductGuestTypeKeys,
  getProductTransportationKeys,
} from 'client/pages/EditProductContent/EditProductAgentReferenceModal/util';
import type { ProductAgentReference, Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  currentProductAgentReference?: ProductAgentReference;
  trigger: React.ReactNode;
  product: Product;
};

export const EditProductAgentReferenceModal = ({
  currentProductAgentReference,
  product,
  trigger,
}: Props) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const initialAgentId =
    (currentProductAgentReference && currentProductAgentReference.agent_id) ||
    '';
  const [agentId, setAgentId] = React.useState<string>(initialAgentId);
  const initialAgentReference =
    (currentProductAgentReference &&
      currentProductAgentReference.agent_reference) ||
    '';
  const [agentProductReference, setAgentProductReference] =
    React.useState<string>(initialAgentReference);
  const initialProductName =
    (currentProductAgentReference &&
      currentProductAgentReference.product_agent_reference_name) ||
    '';
  const [agentProductName, setAgentProductName] =
    React.useState<string>(initialProductName);
  const initialTimeSlotKeys =
    (currentProductAgentReference &&
      currentProductAgentReference.time_slot_keys) ||
    [];
  const [timeSlotKeys, setTimeSlotKeys] =
    React.useState<string[]>(initialTimeSlotKeys);
  const initialAddOnKeys =
    (currentProductAgentReference &&
      currentProductAgentReference.add_on_keys) ||
    [];
  const [addOnKeys, setAddOnKeys] = React.useState<string[]>(initialAddOnKeys);
  const initialTransportationKey =
    (currentProductAgentReference &&
      currentProductAgentReference.transportation_key) ||
    '';
  const [transportationKey, setTransportationKey] = React.useState<string>(
    initialTransportationKey
  );
  const initialGuestTypeKeys =
    (currentProductAgentReference &&
      currentProductAgentReference.guest_type_keys) ||
    [];
  const [guestTypeKeys, setGuestTypeKeys] =
    React.useState<string[]>(initialGuestTypeKeys);
  const dispatch = useDispatch();
  const store = useStore();

  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);
  const agentOrgOptions = (product.agents || []).map((agent) => ({
    key: agent.id,
    value: agent.id,
    text: agent.name,
  }));
  const timeSlotOptions = getTimeSlotsFromRecurrence(
    product.recurrence || []
  ).map((timeSlot) => {
    return {
      key: timeSlot.timeSlotKey,
      text: formattedTimeSlot(timeSlot),
      value: timeSlot.timeSlotKey,
    };
  });
  const addOnKeyOptions = getProductAddOnKeys(product).map((addOnKey) => ({
    key: addOnKey,
    value: addOnKey,
    text: addOnKey,
  }));
  const transportationKeyOptions = getProductTransportationKeys(product).map(
    (transportationKey) => ({
      key: transportationKey,
      value: transportationKey,
      text: transportationKey,
    })
  );
  const guestTypeKeyOptions = getProductGuestTypeKeys(product).map(
    (guestTypeKey) => ({
      key: guestTypeKey,
      value: guestTypeKey,
      text: guestTypeKey,
    })
  );
  const headerText = currentProductAgentReference
    ? t('Edit product-level reverse mapping')
    : t('Create product-level reverse mapping');
  return (
    <Modal
      trigger={trigger}
      className="fix-semantic-modal"
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Header icon="edit" content={headerText} />
      <Modal.Content>
        <Modal.Description>
          <List />
          <Form>
            {activeUser?.role === 'nutmeg_admin' && (
              <Form.Select
                fluid
                search
                required
                disabled={Boolean(currentProductAgentReference)}
                label={t('Agent')}
                options={agentOrgOptions}
                value={agentId}
                onChange={(e, { value }) => setAgentId(value as string)}
                placeholder="Agent Name"
              />
            )}
            <Form.Input
              required
              disabled={Boolean(currentProductAgentReference)}
              label={t('Agent Product Id')}
              value={agentProductReference}
              onChange={(e, { value }) => setAgentProductReference(value)}
            />
            <Form.Input
              required
              label={t('Agent Product Name')}
              value={agentProductName}
              onChange={(e, { value }) => setAgentProductName(value)}
            />
            <Form.Select
              label={t('Start Times')}
              options={timeSlotOptions}
              multiple
              value={timeSlotKeys}
              onChange={(e, { value }) => setTimeSlotKeys(value as string[])}
            />
            <ListSelect
              label={t('Included Guest Types')}
              options={guestTypeKeyOptions}
              value={guestTypeKeys}
              onChange={(newGuestTypeKeys) =>
                setGuestTypeKeys(newGuestTypeKeys)
              }
            />
            <ListSelect
              label={t('Included Add-ons')}
              options={addOnKeyOptions}
              value={addOnKeys}
              onChange={(newAddOnKeys) => setAddOnKeys(newAddOnKeys)}
            />
            <Form.Select
              label={t('Included Transportation')}
              options={[
                {
                  value: 'none',
                  text: t('None'),
                  key: 'none',
                },
                ...transportationKeyOptions,
              ]}
              value={transportationKey || 'none'}
              onChange={(e, { value }) =>
                value === 'none'
                  ? setTransportationKey('')
                  : setTransportationKey(value as string)
              }
            />
          </Form>
          {error && <p className={baseStyles['error']}>{error}</p>}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}>{t('Close')}</Button>
        <Button
          loading={loading}
          disabled={
            (loading && !agentId && activeUser?.role === 'nutmeg_admin') ||
            !agentProductReference
          }
          primary
          onClick={async () => {
            setLoading(true);

            if (currentProductAgentReference) {
              await dispatch(
                updateProductAgentReference(currentProductAgentReference.id, {
                  product_agent_reference_name: agentProductName,
                  time_slot_keys: timeSlotKeys,
                  add_on_keys: addOnKeys,
                  transportation_key: transportationKey,
                  guest_type_keys: guestTypeKeys,
                })
              );
            } else {
              await dispatch(
                createProductAgentReference({
                  agent_id: agentId,
                  product_id: product.id,
                  supplier_id: product.supplier_id,
                  agent_reference: agentProductReference,
                  product_agent_reference_name: agentProductName,
                  time_slot_keys: timeSlotKeys,
                  add_on_keys: addOnKeys,
                  transportation_key: transportationKey,
                  guest_type_keys: guestTypeKeys,
                })
              );
            }

            setLoading(false);

            if (store.getState().productAgentReferences.error) {
              setError(store.getState().productAgentReferences.error);
            } else {
              setError('');
              setShowModal(false);
            }
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
