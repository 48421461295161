import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';

import styles from './ScheduleTable.module.css';

export type ScheduleItem = {
  title: string;
  description: string;
  description2: string;
  dateFromUtc: string;
  dateToUtc: string;
  onClick: () => void;
};

export type DateEvent = {
  title: string | React.ReactNode;
  date: string;
  onClick?: () => void;
};

type Props = {
  startDateLocal: string;
  scheduleItems: ScheduleItem[];
  dateEvents?: DateEvent[];
  timezone: string;
};

export const ScheduleTable = ({
  startDateLocal,
  scheduleItems,
  dateEvents,
  timezone,
}: Props) => {
  const dateEventsMap = (dateEvents ?? []).reduce((acc, dateEvent) => {
    acc[dateEvent.date] = dateEvent;
    return acc;
  }, {} as Record<string, DateEvent>);

  const startDate = moment.tz(startDateLocal, timezone);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const sortedScheduleItems = scheduleItems.sort((a, b) => {
    const aStart = moment.tz(a.dateFromUtc, timezone);
    const bStart = moment.tz(b.dateFromUtc, timezone);
    return aStart.isBefore(bStart) ? -1 : 1;
  });

  const bodyMainRef = React.useRef<HTMLDivElement | null>(null);
  const headerMainRef = React.useRef<HTMLDivElement | null>(null);
  const bodyTimelineRef = React.useRef<HTMLDivElement | null>(null);
  const bodyDaysRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (bodyDaysRef.current) {
      bodyDaysRef.current.scrollTop = 60 * 8;
    }
  }, [bodyDaysRef]);

  React.useEffect(() => {
    if (bodyTimelineRef.current) {
      bodyTimelineRef.current.scrollTop = 60 * 8;
    }
  }, [bodyTimelineRef]);

  const xAxisScrollHandler = () => {
    if (
      bodyMainRef?.current?.scrollLeft != null &&
      headerMainRef?.current?.scrollLeft != null
    ) {
      headerMainRef.current.scrollLeft = bodyMainRef.current.scrollLeft;
    }
  };

  const yAxisScrollHandler = () => {
    if (
      bodyDaysRef?.current?.scrollTop != null &&
      bodyTimelineRef?.current?.scrollTop != null
    ) {
      bodyTimelineRef.current.scrollTop = bodyDaysRef.current.scrollTop;
    }
  };

  return (
    <div className={clsx(styles['schedule-table'])}>
      <div className={clsx(styles['header'])}>
        <div className={clsx(styles['header__space'])}></div>
        <div className={clsx(styles['header__main'])} ref={headerMainRef}>
          <div className={clsx(styles['header__days'])}>
            {[...Array(7)].map((_, i) => {
              const date = startDate.clone().locale(locale).add(i, 'days');
              return (
                <div className={clsx(styles['header__day'])} key={i}>
                  <p className={clsx(styles['header__date'])}>
                    {`${date.format(dayMonthFormat)} (${date.format('ddd')})`}
                  </p>
                  {dateEventsMap[date.format('YYYY-MM-DD')] && (
                    <p className={clsx(styles['header__date-event'])}>
                      <a
                        className={styles['link']}
                        onClick={() => {
                          dateEventsMap[date.format('YYYY-MM-DD')].onClick?.();
                        }}
                      >
                        {dateEventsMap[date.format('YYYY-MM-DD')].title}
                      </a>
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={clsx(styles['body'])}>
        <div className={clsx(styles['body__timeline'])} ref={bodyTimelineRef}>
          {[...Array(24 * 6 * 2)].map((_, i) => (
            <div
              className={clsx(styles['time-marker'])}
              key={`time-markder-${i}`}
            >
              {i % (6 * 2) === 0 && <>{i / (6 * 2)}:00</>}
            </div>
          ))}
        </div>
        <div
          className={clsx(styles['body__main'])}
          onScroll={xAxisScrollHandler}
          ref={bodyMainRef}
        >
          <div
            className={clsx(styles['body__days'])}
            onScroll={yAxisScrollHandler}
            ref={bodyDaysRef}
          >
            {[...Array(7)].map((_, i) => {
              const date = startDate.clone().add(i, 'days');
              const nextDate = startDate.clone().add(i + 1, 'days');
              return (
                <div className={clsx(styles['body__day'])} key={`day-${i}`}>
                  <div className={clsx(styles['events'])}>
                    {sortedScheduleItems
                      .filter((scheduleItem) => {
                        const dateFrom = moment.tz(
                          scheduleItem.dateFromUtc,
                          timezone
                        );
                        if (dateFrom.isBefore(date)) {
                          return false;
                        }
                        if (dateFrom.isSameOrAfter(nextDate)) {
                          return false;
                        }
                        return true;
                      })
                      .map((scheduleItem, j) => (
                        <ScheduleTableEvent
                          scheduleItem={scheduleItem}
                          timezone={timezone}
                          key={`schedule-item-${j}`}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ScheduleTableEvent = ({
  scheduleItem,
  timezone,
}: {
  scheduleItem: ScheduleItem;
  timezone: string;
}) => {
  const startMoment = moment(scheduleItem.dateFromUtc).tz(timezone);
  const start = startMoment.hour() * 12 + startMoment.minute() / 5;

  const endMoment = moment(scheduleItem.dateToUtc).tz(timezone);
  const end = endMoment.hour() * 12 + endMoment.minute() / 5;

  return (
    <div
      className={clsx(styles['event'], styles['corp-fi'])}
      style={{
        gridRowStart: start,
        gridRowEnd: end,
      }}
      onClick={() => {
        scheduleItem.onClick?.();
      }}
    >
      <p className={clsx(styles['title'])}>{scheduleItem.title}</p>
      <p className={clsx(styles['time'])}>{scheduleItem.description}</p>
      {scheduleItem.description2 && (
        <p className={clsx(styles['time'])}>{scheduleItem.description2}</p>
      )}
    </div>
  );
};
