import { combineReducers } from 'redux';

import {
  SUBDOMAIN_IS_AVAILABLE_REQUEST,
  SUBDOMAIN_IS_AVAILABLE_SUCCESS,
} from 'client/constants/ActionTypes';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case SUBDOMAIN_IS_AVAILABLE_REQUEST:
      return true;

    case SUBDOMAIN_IS_AVAILABLE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const isAvailable = (state = false, action: any): boolean => {
  switch (action.type) {
    case SUBDOMAIN_IS_AVAILABLE_REQUEST:
      return false;

    case SUBDOMAIN_IS_AVAILABLE_SUCCESS:
      return action.isAvailable;

    default:
      return state;
  }
};

export const subdomains = combineReducers({
  loading,
  isAvailable,
});
