import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { DateTimeInput } from 'client/components/Form';
import { LanguageISO } from 'shared/libraries/i18n';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  locale: string;
};
export const ParticipationDate = ({ value, onChange, locale }: Props) => {
  return (
    <div className={clsx(pageStyles['page-availability__datepick'])}>
      <a
        className={clsx(
          pageStyles['page-availability__datepick__btn'],
          pageStyles['prev']
        )}
        onClick={() => {
          onChange(moment(value).add(-1, 'days').format('YYYY-MM-DD'));
        }}
      />
      <div className={clsx(pageStyles['page-availability__datepick__main'])}>
        <DateTimeInput
          value={moment(value)}
          locale={locale as LanguageISO}
          onChange={(date) => {
            onChange(date.format('YYYY-MM-DD'));
          }}
        />
      </div>
      <a
        className={clsx(
          pageStyles['page-availability__datepick__btn'],
          pageStyles['next']
        )}
        onClick={() => {
          onChange(moment(value).add(1, 'days').format('YYYY-MM-DD'));
        }}
      />
    </div>
  );
};
