import * as React from 'react';

import type { Review } from 'shared/models/swagger';

import { ReviewCard } from './ReviewCard';
import styles from './ProductReviewsPane.module.css';

type Props = {
  reviews: Review[];
  label?: string;
  showEditButton: boolean;
  showStatus: boolean;
};
export const ReviewList = ({
  reviews,
  label,
  showEditButton,
  showStatus,
}: Props) => {
  return (
    <div className={styles['c-review__list']}>
      {label && <p className={styles['c-review__ttl']}>{label}</p>}
      <ul className={styles['c-review__list__comment']}>
        {reviews.map((review, idx) => (
          <ReviewCard
            key={idx}
            review={review}
            showEditButton={showEditButton}
            showStatus={showStatus}
          />
        ))}
      </ul>
    </div>
  );
};
