import * as React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper, Input } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Divider } from 'client/components/Divider/Divider';

import styles from './BookNowButtonEditor.module.css';

type Props = {
  calendarUrl: string;
  calendarType: 'MULTI' | 'SINGLE';
};

export const BookNowButtonEditor = ({ calendarUrl, calendarType }: Props) => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const defaultColor =
    activeUserOrganization?.booking_widget_design_params?.primary_color ??
    '#0094cc';

  const [buttonText, setButtonText] = React.useState<string>(t('Book Now'));
  const [buttonColor, setButtonColor] = React.useState<string>(defaultColor);

  React.useEffect(() => {
    if (!(window as any).openCalendarModal) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-book-now/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);

  const buttonStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '24px',
    height: '48px',
    background: buttonColor,
    color: '#fff',
    cursor: 'pointer',
    border: 'none',
    paddingLeft: '32px',
    paddingRight: '32px',
  };

  const cssStyleString = [
    `font-size: ${buttonStyle.fontSize}`,
    `font-weight: ${buttonStyle.fontWeight}`,
    `border-radius: ${buttonStyle.borderRadius}`,
    `height: ${buttonStyle.height}`,
    `font-weight: ${buttonStyle.fontWeight}`,
    `background: ${buttonStyle.background}`,
    `color: ${buttonStyle.color}`,
    `cursor: ${buttonStyle.cursor}`,
    `border: ${buttonStyle.border}`,
    `padding-left: ${buttonStyle.paddingLeft}`,
    `padding-right: ${buttonStyle.paddingRight}`,
  ].join('; ');

  return (
    <>
      <h3>{t('"Book Now" Button')}</h3>
      <Divider />
      <List relaxed>
        <List.Item>
          <Box mb={3}>
            <Input
              maxWidth={400}
              label={t('Button Text')}
              value={buttonText}
              onChange={(e: any) => setButtonText(e.target.value)}
            />
            <Box mt={2}>
              <FieldWrapper label={t('Button Color')}>
                <ColorPicker
                  placement="bottomRight"
                  color={buttonColor}
                  onChange={({ color }: any) => setButtonColor(color)}
                />
              </FieldWrapper>
            </Box>
          </Box>
        </List.Item>
        <List.Item>
          <List.Content>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                style={buttonStyle}
                type="button"
                onClick={() =>
                  (window as any).openCalendarModal(calendarUrl, calendarType)
                }
              >
                {buttonText}
              </button>
            </div>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>{t('Code')}</List.Header>
          <List.Content>
            <pre
              className={styles['code-block']}
            >{`<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        if (!window.openCalendarModal) {
          loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed-book-now/v1/index.js');
        }
        if (!window.NutmegCalendar) {
          loadScriptAsync('https://assets.ntmg.com/scripts/falcon-embed/v1/index.js');
        }
        function loadScriptAsync(url) {
            var script = document.createElement('script');
            script.src = url;
            script.async = true;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        }
    })();
    /*]]>*/
</script>   
<div style="width: 100%; display: flex; justify-content: center"><button type="button" style="${cssStyleString}" onclick="openCalendarModal('${calendarUrl}', '${calendarType}')">${buttonText}</button></div>`}</pre>
          </List.Content>
        </List.Item>
      </List>
    </>
  );
};
