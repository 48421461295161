import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'client/pages/Loading';
import { fetchReservationVoucherPreview } from 'client/actions/reservationVoucher';
import { ReduxState } from 'client/reducers';

export const ReservationVoucherPreview = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.reservationVoucher.loading
  );
  const html = useSelector(
    (state: ReduxState) => state.reservationVoucher.html
  );
  console.log('search', search);
  const query = new URLSearchParams(search);
  console.log('query', query);
  const primaryColor = query.get('p') ?? '';
  const secondaryColor = query.get('s') ?? '';
  const backgroundColor = query.get('bg') ?? '';
  React.useEffect(() => {
    dispatch(
      fetchReservationVoucherPreview(
        primaryColor,
        secondaryColor,
        backgroundColor
      )
    );
  }, [primaryColor, secondaryColor, backgroundColor]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
