import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ResourceType } from 'client/libraries/util/resourceType';
import { ManifestReservationsTable } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestReservationsTable';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { StartTimeManifestReservationsTableList } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/StartTimeManifestReservationsTableList';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { CollapsibleSection } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/CollapsibleSection';

type Props = {
  excludedFormFieldKeys: string[];
  loading?: boolean;
  product?: ProductSummary;
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  open?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  onEditResourceButtonClick?: (arg0: string) => void;
  onResourceTextClick?: (
    arg0: string,
    arg1: ResourceType,
    arg2: string
  ) => void;
  showDispatchColumn?: boolean;
  capacity?: number;
  splitStartTimes?: boolean;
  manifestDate?: string;
};
export const ManifestProductTable = ({
  excludedFormFieldKeys,
  loading,
  product,
  reservations,
  visibleColumns,
  open,
  customizedColumnNames,
  onEditResourceButtonClick,
  onResourceTextClick,
  showDispatchColumn,
  capacity,
  splitStartTimes,
  manifestDate,
}: Props) => {
  const totalPax = reservations.reduce((total, reservation) => {
    return total + reservation.guests.length;
  }, 0);
  const displayProductName = getDisplayProductName(product);
  return (
    <CollapsibleSection
      title={
        displayProductName
          ? displayProductName
          : reservations?.[0]?.product_name
      }
      open={true}
    >
      <div className={styles['p-manifests__section__body__box']}>
        {splitStartTimes ? (
          <StartTimeManifestReservationsTableList
            manifestDate={manifestDate}
            excludedFormFieldKeys={excludedFormFieldKeys}
            products={product ? [product] : []}
            reservations={reservations}
            visibleColumns={visibleColumns as any}
            customizedColumnNames={customizedColumnNames}
            onEditResourceButtonClick={onEditResourceButtonClick}
            onResourceTextClick={onResourceTextClick}
            showDispatchColumn={showDispatchColumn}
            insideGroup={true}
          />
        ) : (
          <ManifestReservationsTable
            excludedFormFieldKeys={excludedFormFieldKeys}
            loading={loading}
            products={product ? [product] : []}
            reservations={reservations}
            visibleColumns={visibleColumns as any}
            totalPax={totalPax}
            open={open}
            customizedColumnNames={customizedColumnNames}
            onEditResourceButtonClick={onEditResourceButtonClick}
            onResourceTextClick={onResourceTextClick}
            showDispatchColumn={showDispatchColumn}
            capacity={capacity}
          />
        )}
      </div>
    </CollapsibleSection>
  );
};
