import * as React from 'react';

interface GadgetEditProps {
  editModeIsActive: boolean;
  onDeleteGadget: (gadgetKey: string) => void;
  onEditGadgetClick: (gadgetKey: string) => void;
}

export const GadgetEditContext = React.createContext<GadgetEditProps>({
  editModeIsActive: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDeleteGadget: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onEditGadgetClick: () => {},
});
