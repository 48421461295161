import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_RESOURCE_ASSIGNMENTS_SUCCESS,
  FETCH_RESOURCE_ASSIGNMENTS_FAILURE,
  FETCH_RESOURCE_ASSIGNMENTS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchResourceAssignmentsRequest = () => ({
  type: FETCH_RESOURCE_ASSIGNMENTS_REQUEST,
});

const fetchResourceAssignmentsSuccess = (response: any) => ({
  type: FETCH_RESOURCE_ASSIGNMENTS_SUCCESS,
  response,
});

const fetchResourceAssignmentsFailure = (error: any) => ({
  type: FETCH_RESOURCE_ASSIGNMENTS_FAILURE,
  error,
});

export const fetchResourceAssignments =
  (
    resource_type: Swagger.ResourceType,
    resource_key: string,
    date_from_start: string,
    date_from_end: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchResourceAssignmentsRequest());
    axios
      .get('/api/resourceassignments', {
        params: {
          resource_type,
          resource_key,
          date_from_start,
          date_from_end,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchResourceAssignmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchResourceAssignmentsFailure('canceled'));
        else dispatch(fetchResourceAssignmentsFailure(error));
      });
  };
