import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';

import styles from '../Tutorial.module.css';

type Props = {
  popUpDisable: () => void;
};

export const ProductEditReservationPopUp = ({ popUpDisable }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['page-tutorial__popupFrame']}>
      <div className={styles['page-tutorial__popupFinish']}>
        <p className={styles['page-tutorial__popupFinish__header']}>
          {t('Create Product - Reservation Parameters')}
        </p>
        <div className={styles['page-new-tutorial__popupFinish__body']}>
          <p>{t('Next step is "Reservation Parameters"')}</p>
          <p>{t('It includes settings for:')}</p>
          <p>{t(' - Product start time, operating dates & days')}</p>
          <p>{t(' - Cut-off time for bookings from website')}</p>
          <p>{t(' - Cancellation policies')}</p>
          <p>
            {t(
              'You can edit details at any time and the changes willl be immediately reflected on the booking website.'
            )}
          </p>
          <button
            className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
            style={{ width: '128px', height: '32px', margin: 'auto' }}
            onClick={() => popUpDisable?.()}
          >
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};
