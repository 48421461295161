import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { DispatchCrewMember } from 'shared/models/swagger';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import { DateListInput } from 'client/components/v3/DateListInput/DateListInput';

type Props = {
  crewMember: DispatchCrewMember;
  index: number;
  onScheduleChange: (
    startDate: string,
    endDate: string,
    daysOfWeek: string[],
    excludedDates: string[],
    index: number
  ) => void;
};

type Day = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
const allDays: Day[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const CrewScheduleInput = ({
  crewMember,
  index,
  onScheduleChange,
}: Props) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [daysOfWeek, setDaysOfWeek] = useState<Day[]>(allDays);
  const [excludedDates, setExcludedDates] = useState<string[]>([]);

  useEffect(() => {
    const schedule = crewMember && crewMember.schedule;
    const firstSchedule = schedule && schedule.length > 0 && schedule[0];
    setStartDate((firstSchedule && firstSchedule.start_date_local) || '');
    setEndDate((firstSchedule && firstSchedule.end_date_local) || '');
    setDaysOfWeek(
      firstSchedule && firstSchedule.days_of_week
        ? firstSchedule.days_of_week
        : allDays
    );
    setExcludedDates(
      firstSchedule && firstSchedule.excluded_dates
        ? firstSchedule.excluded_dates
        : []
    );
  }, [crewMember]);

  const handleScheduleChange = (
    newStartDate: string,
    newEndDate: string,
    newDaysOfWeek: Day[],
    newExcludedDates: string[]
  ) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setDaysOfWeek(newDaysOfWeek);
    setExcludedDates(newExcludedDates);
    onScheduleChange(
      newStartDate,
      newEndDate,
      newDaysOfWeek,
      newExcludedDates,
      index
    );
  };

  return (
    <>
      <div className={styles['p-manifests__customize__form']}>
        <p className={styles['p-manifests__customize__formTtl']}>
          {t('Date Range')}
        </p>
        <DateRangeInput
          dateFrom={startDate}
          dateTo={endDate}
          onChange={(value) => {
            handleScheduleChange(
              value[0] ?? '',
              value[1] ?? '',
              daysOfWeek,
              excludedDates
            );
          }}
        />
      </div>
      <div className={styles['p-manifests__customize__form']}>
        <p className={styles['p-manifests__customize__formTtl']}>
          {t('Working days')}
        </p>
        <WeekdaysInput
          value={daysOfWeek}
          onChange={(newDaysOfWeek) =>
            handleScheduleChange(
              startDate,
              endDate,
              newDaysOfWeek,
              excludedDates
            )
          }
        />
      </div>
      <div className={styles['p-manifests__customize__form']}>
        <p className={styles['p-manifests__customize__formTtl']}>
          {t('Off days')}
        </p>
        <DateListInput
          addButtonLabel={t('Add Schedule Excluded Date')}
          dates={excludedDates}
          setDates={(newExcludedDates) =>
            handleScheduleChange(
              startDate,
              endDate,
              daysOfWeek,
              newExcludedDates
            )
          }
        />
      </div>
    </>
  );
};
