import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type Props = {
  header: string;
  content: React.ReactNode;
  onClose?: () => any;
  open?: boolean;
  insertRoot?: boolean;
};
export const AlertModal = ({
  header,
  content,
  onClose: onCloseFromProps,
  open: openFromProps,
  insertRoot,
}: Props) => {
  const { t } = useTranslation();
  const [myOpen, setMyOpen] = React.useState<boolean>(false);

  const onOpen = () => setMyOpen(true);

  const onClose = onCloseFromProps ?? (() => setMyOpen(false));

  const open = openFromProps ?? myOpen;
  return (
    <Modal
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title={header}
      insertRoot={insertRoot}
    >
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            onClose();
          }}
        >
          {t('OK')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
