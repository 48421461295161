import {
  SET_LAST_EXECUTED_CHECKIN_RECORD_SEARCH_CONDITION,
  SET_CHECKIN_RECORD_ROW_COUNT,
  SET_CHECKIN_RECORD_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchCheckinRecordsRequest } from 'client/libraries/util/searchCheckinRecords';

export const setLastExecutedCheckinRecordSearchCondition = (
  searchFilters: SearchCheckinRecordsRequest
) => ({
  type: SET_LAST_EXECUTED_CHECKIN_RECORD_SEARCH_CONDITION,
  searchFilters,
});

export const setCheckinRecordRowCount = (rowCount: number) => ({
  type: SET_CHECKIN_RECORD_ROW_COUNT,
  rowCount,
});

export const setCheckinRecordCurrentPage = (currentPage: number) => ({
  type: SET_CHECKIN_RECORD_CURRENT_PAGE,
  currentPage,
});
