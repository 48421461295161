import { Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { getAllChannels } from 'client/pages/EditProductAvailabilityAllotment/ProductInstanceEditCalendar/util';
import {
  getInstanceDedicatedAllotments,
  getInstanceClosedChannels,
  getInstancePerChannelInfo,
  productInstanceChannelsAreClosed,
  productInstanceAllChannelsAreClosed,
  productInstanceAllChannelSlotsAreFull,
  productInstanceCloseAllAgentChannels,
  productInstanceOpenAllAgentChannels,
  productInstanceFillAllChannelSlots,
} from 'client/libraries/util/util';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import type { ProductInstance, Product } from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { PreventAutomaticAllotmentChangesCheckbox } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/PreventAutomaticAllotmentChangesCheckbox';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import baseStyles from 'client/v3-base.module.css';

type Props = {
  product: Product | null;
  oldProductInstance: ProductInstance;
  newProductInstance: ProductInstance;
  handleProductInstanceChange: (arg0: ProductInstance) => void;
};

const printDifference = (x1?: number, x2?: number) => {
  if (!x2 || !x1) {
    return '';
  }

  if (x1 > x2) {
    return `(-${x1 - x2})`;
  } else if (x2 > x1) {
    return `(+${x2 - x1})`;
  }

  return '';
};

export const ProductInstanceEditInput = ({
  product,
  oldProductInstance,
  newProductInstance,
  handleProductInstanceChange,
}: Props) => {
  const { t } = useTranslation();

  const { prevent_allotment_changes_when_reprinting } = newProductInstance;
  const oldDedicatedAllotments =
    getInstanceDedicatedAllotments(oldProductInstance);
  const newDedicatedAllotments =
    getInstanceDedicatedAllotments(newProductInstance);
  const bookedSum =
    _.sumBy(oldDedicatedAllotments, (p) => p.occupied_slots || 0) +
    (oldProductInstance.occupied_slots || 0);
  const oldAllotmentSum =
    _.sumBy(oldDedicatedAllotments, (p) => p.total_slots || 0) +
    (oldProductInstance.total_slots || 0);
  const newAllotmentSum =
    _.sumBy(newDedicatedAllotments, (p) => p.total_slots || 0) +
    (newProductInstance.total_slots || 0);
  const allChannels = getAllChannels(product).filter((ch) => {
    if (ch.channel_category === 'DIRECT_ALL') {
      const oldAllotment = oldDedicatedAllotments.find((a) =>
        ch.channel_category === 'AGENT'
          ? a.agent_id === ch.agent_id
          : a.channel_category === ch.channel_category
      );
      const newAllotment = newDedicatedAllotments.find((a) =>
        ch.channel_category === 'AGENT'
          ? a.agent_id === ch.agent_id
          : a.channel_category === ch.channel_category
      );
      const newTotal = newAllotment && (newAllotment.total_slots || 0);
      const oldTotal = oldAllotment && (oldAllotment.total_slots || 0);
      const occupied = oldAllotment && (oldAllotment.occupied_slots || 0);

      if (
        newTotal === undefined &&
        oldTotal === undefined &&
        occupied === undefined
      ) {
        return false;
      }
    }

    return true;
  });
  const channels: {
    category?: string;
    agentID?: string;
    agentName?: string;
    newTotal?: number;
    oldTotal?: number;
    occupied?: number;
  }[] = allChannels.map((ch) => {
    if (ch.channel_category === 'COMMON') {
      return {
        category: 'COMMON',
        newTotal: newProductInstance.total_slots || 0,
        oldTotal: oldProductInstance.total_slots || 0,
        occupied: oldProductInstance.occupied_slots || 0,
      };
    }

    const oldAllotment = oldDedicatedAllotments.find((a) =>
      ch.channel_category === 'AGENT'
        ? a.agent_id === ch.agent_id
        : a.channel_category === ch.channel_category
    );
    const newAllotment = newDedicatedAllotments.find((a) =>
      ch.channel_category === 'AGENT'
        ? a.agent_id === ch.agent_id
        : a.channel_category === ch.channel_category
    );
    const newTotal = newAllotment && (newAllotment.total_slots || 0);
    const oldTotal = oldAllotment && (oldAllotment.total_slots || 0);
    const occupied = oldAllotment && (oldAllotment.occupied_slots || 0);
    return {
      category: ch.channel_category,
      agentID: ch.agent_id,
      agentName: ch.agent_name,
      newTotal,
      oldTotal,
      occupied,
    };
  });
  const agentChannels = allChannels.filter(
    (ch) =>
      ch.channel_category === 'AGENT' || ch.channel_category === 'RESELLER'
  );
  const allChannelsAreClosed =
    productInstanceAllChannelsAreClosed(newProductInstance);
  const allAgentsAreClosed = productInstanceChannelsAreClosed(
    newProductInstance,
    agentChannels
  );
  const allChannelSlotsAreFull =
    productInstanceAllChannelSlotsAreFull(newProductInstance);
  const mql = window.matchMedia('(max-width: 768px)');

  return (
    <>
      <div className={styles['p-reservationsCreateStock']}>
        <div className={styles['p-reservationsCreateStock__check']}>
          <ul>
            <li>
              <Checkbox
                label={t('Close all channels')}
                checked={allChannelsAreClosed}
                onChange={() => {
                  handleProductInstanceChange({
                    ...newProductInstance,
                    per_channel_info: {
                      ...getInstancePerChannelInfo(newProductInstance),
                      all_channels_are_closed: !allChannelsAreClosed,
                      closed_channels: [],
                    },
                  });
                }}
                size="sm"
              />
            </li>
            <li>
              <Checkbox
                label={t('Close all agents')}
                disabled={allChannelsAreClosed}
                checked={allAgentsAreClosed}
                onChange={() => {
                  handleProductInstanceChange(
                    allAgentsAreClosed
                      ? productInstanceOpenAllAgentChannels(newProductInstance)
                      : productInstanceCloseAllAgentChannels(
                          newProductInstance,
                          agentChannels
                        )
                  );
                }}
                size="sm"
              />
            </li>
            <li>
              <Checkbox
                label={t('Close instant booking for all channels')}
                checked={allChannelSlotsAreFull}
                disabled={allChannelSlotsAreFull || allChannelsAreClosed}
                onChange={() => {
                  handleProductInstanceChange(
                    productInstanceFillAllChannelSlots(newProductInstance)
                  );
                }}
                size="sm"
              />
            </li>
          </ul>
        </div>

        <div className={styles['p-reservationsCreateStock__table']}>
          <table
            className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          >
            <thead>
              <tr>
                <th>
                  <Popup trigger={<span>{t('Closed')}</span>}>
                    {t(
                      'No bookings will be allowed through a "closed" channel regardless of remaining allotments.'
                    )}
                  </Popup>
                </th>
                <th> {t('Channel Name')} </th>
                <th>
                  <Popup trigger={<span>{t('Current')}</span>}>
                    {t('(# booked) / allotment')}
                  </Popup>
                </th>
                <th> {t('New allotment')} </th>
                <th>
                  <Popup trigger={<span>{t('Result')}</span>}>
                    {t('(# booked) / allotment')}
                  </Popup>
                </th>
              </tr>
            </thead>
            <tbody>
              {channels.map((channel, idx) => {
                const channelIsClosed =
                  allChannelsAreClosed ||
                  !!getInstanceClosedChannels(newProductInstance).find(
                    (c) =>
                      (channel.agentID || channel.category) ===
                      (c.agent_id || c.channel_category)
                  );
                return (
                  <tr key={idx}>
                    <td
                      className={clsx(
                        tableStyles['c-tableSmall__spTtl'],
                        baseStyles['u-pcHidden']
                      )}
                    >
                      {channel.category === 'AGENT'
                        ? channel.agentName
                        : channel.category}
                    </td>
                    <td data-text={t('Closed')}>
                      <Checkbox
                        size="sm"
                        disabled={allChannelsAreClosed}
                        checked={channelIsClosed}
                        onChange={() => {
                          const s = new Set(
                            getInstanceClosedChannels(newProductInstance).map(
                              (c) => c.agent_id || c.channel_category
                            )
                          );
                          const value = channel.agentID || channel.category;

                          if (s.has(value)) {
                            s.delete(value);
                          } else {
                            s.add(value);
                          }

                          const closed_channels = allChannels
                            .filter((c) =>
                              s.has(c.agent_id || c.channel_category)
                            )
                            .map((c) => ({ ...c }));
                          handleProductInstanceChange({
                            ...newProductInstance,
                            per_channel_info: {
                              ...getInstancePerChannelInfo(newProductInstance),
                              all_channels_are_closed:
                                allChannels.length === closed_channels.length,
                              closed_channels,
                            },
                          });
                        }}
                      />
                    </td>
                    <td data-text={t('Channel Name')}>
                      {channel.category === 'AGENT'
                        ? channel.agentName
                        : t(channel.category ?? '')}
                    </td>
                    {channel.occupied !== undefined &&
                    channel.oldTotal !== undefined &&
                    channel.newTotal !== undefined ? (
                      <>
                        <td data-text={t('Current')}>
                          {`${
                            channel.occupied || 0
                          } / ${formattedTotalAllotmentSlots(
                            channel.oldTotal || 0
                          )}`}
                        </td>
                        <td data-text={t('New allotment')}>
                          <SingleDropdown
                            options={[...Array(2001)].map((_, idx) => ({
                              text: idx.toString(),
                              value: idx.toString(),
                            }))}
                            menuStyle={{ top: mql.matches ? '-100px' : '' }}
                            selectedOption={channel.newTotal.toString()}
                            onChange={(value) => {
                              let total_slots = 0;

                              if (value.length > 0) {
                                total_slots = parseInt(value, 10);
                              }

                              if (isNaN(total_slots)) {
                                total_slots = 0;
                              }

                              if (channel.category === 'COMMON') {
                                handleProductInstanceChange({
                                  ...newProductInstance,
                                  total_slots,
                                });
                              } else {
                                handleProductInstanceChange({
                                  ...newProductInstance,
                                  per_channel_info: {
                                    ...getInstancePerChannelInfo(
                                      newProductInstance
                                    ),
                                    dedicated_allotments: (
                                      newDedicatedAllotments || []
                                    ).map((a) => {
                                      if (channel.category === 'AGENT') {
                                        return channel.agentID === a.agent_id
                                          ? { ...a, total_slots }
                                          : a;
                                      }

                                      return channel.category ===
                                        a.channel_category
                                        ? { ...a, total_slots }
                                        : a;
                                    }),
                                  },
                                });
                              }
                            }}
                            disabled={channelIsClosed}
                          />
                        </td>
                        <td
                          data-text={t('Result')}
                          style={{
                            color:
                              channel.newTotal !== channel.oldTotal
                                ? 'red'
                                : undefined,
                          }}
                        >
                          {`${
                            channel.occupied || 0
                          } / ${formattedTotalAllotmentSlots(
                            channel.newTotal || 0
                          )} ${printDifference(
                            channel.oldTotal,
                            channel.newTotal
                          )}`}
                        </td>
                      </>
                    ) : (
                      <>
                        <td data-text={t('Current')}>--</td>
                        <td data-text={t('New allotment')}>--</td>
                        <td data-text={t('Result')}>--</td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className={baseStyles['u-spHidden']} data-text={'-'} />
                <td data-text={t('Channel Name')}>{t('Total')}</td>
                <td
                  data-text={t('Current')}
                >{`${bookedSum} / ${formattedTotalAllotmentSlots(
                  oldAllotmentSum
                )}`}</td>
                <td data-text={t('New allotment')}>{newAllotmentSum}</td>
                <td
                  data-text={t('Result')}
                >{`${bookedSum} / ${formattedTotalAllotmentSlots(
                  newAllotmentSum
                )} ${printDifference(oldAllotmentSum, newAllotmentSum)}`}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className={styles['p-reservationsCreateStock__memo']}>
        <p className={styles['p-reservationsCreateStock__memo__ttl']}>
          {t('Memo')}
        </p>
        <div className={styles['p-reservationsCreateStock__memo__body']}>
          <TextArea
            value={newProductInstance?.memo?.value ?? ''}
            height={100}
            onChange={(e, { value }) => {
              handleProductInstanceChange({
                ...newProductInstance,
                memo: { value: value },
              });
            }}
          />
        </div>
      </div>
      <div className={styles['p-reservationsCreateStock__note']}>
        <PreventAutomaticAllotmentChangesCheckbox
          originalValue={Boolean(
            oldProductInstance.prevent_allotment_changes_when_reprinting
          )}
          checked={Boolean(prevent_allotment_changes_when_reprinting)}
          onChange={() => {
            handleProductInstanceChange({
              ...newProductInstance,
              prevent_allotment_changes_when_reprinting:
                !newProductInstance.prevent_allotment_changes_when_reprinting,
            });
          }}
        />
      </div>
    </>
  );
};
