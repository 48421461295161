import * as React from 'react';
import clsx from 'clsx';

import styles from './SignUpDefaults.module.css';

type Props = {
  options: {
    value: string;
    text: string;
  }[];
  value: string;
  onChange: (arg0: string) => void;
  label: string;
};
export const SearchableSelect = ({
  label,
  onChange,
  options,
  value,
}: Props) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const formBoxRef = React.useRef<HTMLDivElement>(null);
  const inputElement: {
    current: null | HTMLInputElement;
  } = React.useRef(null);
  const valueText =
    options.find((option) => option.value === value)?.text || '';
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        isActive &&
        target instanceof Node &&
        !formBoxRef?.current?.contains(target)
      ) {
        setIsActive(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [isActive]);
  React.useEffect(() => {
    if (isActive && inputElement?.current) {
      inputElement.current?.focus();
      inputElement.current?.select();
    }
  }, [isActive]);
  const filteredOptions = options.filter(
    (option) =>
      !searchQuery ||
      option.text.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className={styles['c-form__box']} ref={formBoxRef}>
      <div className={styles['c-form__box__header']}>{label}</div>
      <div>
        <div className={styles['p-regist__search']}>
          <div
            className={styles['c-select']}
            id="currency"
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            {valueText}
          </div>
          <div
            className={clsx(
              styles['p-regist__search__main'],
              isActive && styles['is-active']
            )}
          >
            <div className={styles['p-regist__search__main__header']}>
              <input
                ref={inputElement}
                onKeyDown={(e) => {
                  const ENTER = 13;

                  if (e.keyCode === ENTER) {
                    e.preventDefault();

                    if (filteredOptions.length === 1) {
                      onChange(filteredOptions[0].value);
                      setIsActive(false);
                    }
                  }
                }}
                type="text"
                className={styles['c-inputtext']}
                placeholder="テキストで絞り込む"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <ul className={styles['p-regist__search__main__body']}>
              {filteredOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => {
                    setIsActive(false);
                    onChange(option.value);
                  }}
                >
                  {option.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <p className={styles['c-error']}>エラーテキスト</p>
    </div>
  );
};
