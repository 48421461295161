import * as React from 'react';
import moment from 'moment-timezone';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FieldWrapper,
  Checkbox,
  TextArea,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { activeUserSelector } from 'client/reducers/user';
import {
  createGuideSingleDaySchedule,
  updateGuideSingleDaySchedule,
  deleteGuideSingleDaySchedule,
} from 'client/actions/guides';
import { getArrayMutators } from 'client/libraries/util/form';
import { getWeekdayFromDate } from 'client/libraries/util/weekdays';
import type {
  OperatingHoursRule,
  GuideSingleDaySchedule,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  date: string;
  accountId: string;
  operatingHoursRule: OperatingHoursRule | null;
  guideSingleDaySchedule?: GuideSingleDaySchedule | null;
  onSubmit?: (arg0: void) => void;
};
type FormValues = {
  startTimeLocal: string;
  endTimeLocal: string;
  outOfOffice: boolean;
  supplierNote: string;
  guideNote: string;
};
export const EditSingleDayOperatingHourModal = ({
  open,
  onClose,
  date,
  accountId,
  operatingHoursRule,
  guideSingleDaySchedule,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);

  const createInitialValues = (): FormValues => {
    const dayOfWeek = getWeekdayFromDate(date);
    const defaultStartTimeLocal =
      operatingHoursRule?.start_time_local || '09:00';
    const defaultEndTimeLocal = operatingHoursRule?.end_time_local || '17:00';
    const defaultOutOfOffice = !(
      operatingHoursRule?.days_of_week || []
    ).includes(dayOfWeek);
    return {
      startTimeLocal:
        guideSingleDaySchedule?.start_time_local || defaultStartTimeLocal,
      endTimeLocal:
        guideSingleDaySchedule?.end_time_local || defaultEndTimeLocal,
      outOfOffice:
        guideSingleDaySchedule?.out_of_office?.value || defaultOutOfOffice,
      supplierNote: guideSingleDaySchedule?.supplier_note || '',
      guideNote: guideSingleDaySchedule?.guide_note || '',
    };
  };

  return (
    <Modal
      title={t('Edit schedule')}
      open={open}
      onClose={onClose}
      insertRoot={true}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          const participationWeekday = getWeekdayFromDate(date);
          const isWorkday = (operatingHoursRule?.days_of_week || []).includes(
            participationWeekday
          );
          let noNeed = false;

          if (
            ((values.outOfOffice && !isWorkday) ||
              (!values.outOfOffice &&
                isWorkday &&
                values.startTimeLocal ===
                  operatingHoursRule?.start_time_local &&
                values.endTimeLocal === operatingHoursRule?.end_time_local)) &&
            !values.supplierNote &&
            !values.guideNote
          ) {
            noNeed = true;
          }

          try {
            if (guideSingleDaySchedule) {
              if (noNeed) {
                await dispatch(
                  deleteGuideSingleDaySchedule(guideSingleDaySchedule.id)
                );
              } else {
                await dispatch(
                  updateGuideSingleDaySchedule(guideSingleDaySchedule.id, {
                    date_local: date,
                    guide_account_id: accountId,
                    start_time_local: values.startTimeLocal,
                    end_time_local: values.endTimeLocal,
                    out_of_office: {
                      value: values.outOfOffice,
                    },
                    supplier_note: values.supplierNote,
                    guide_note: values.guideNote,
                  })
                );
              }
            } else {
              await dispatch(
                createGuideSingleDaySchedule({
                  date_local: date,
                  guide_account_id: accountId,
                  start_time_local: values.startTimeLocal,
                  end_time_local: values.endTimeLocal,
                  out_of_office: {
                    value: values.outOfOffice,
                  },
                  supplier_note: values.supplierNote,
                  guide_note: values.guideNote,
                })
              );
            }

            if (onSubmit) {
              onSubmit();
            }

            if (onClose) {
              onClose();
            }
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={createInitialValues()}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <FieldWrapper label={t('Date')}>{date}</FieldWrapper>
                </Modal.Box>
                <Modal.Box>
                  <FieldWrapper label={t('Operating hour')}>
                    <Field name="startTimeLocal">
                      {({ input }) => (
                        <TimePicker
                          allowEmpty={false}
                          value={moment(input.value, 'HH:mm')}
                          onChange={(newMoment) => {
                            if (!newMoment) {
                              return;
                            }

                            input.onChange(newMoment.format('HH:mm'));
                          }}
                        />
                      )}
                    </Field>
                    <p>-</p>
                    <Field name="endTimeLocal">
                      {({ input }) => (
                        <TimePicker
                          allowEmpty={false}
                          value={moment(input.value, 'HH:mm')}
                          onChange={(newMoment) => {
                            if (!newMoment) {
                              return;
                            }

                            input.onChange(newMoment.format('HH:mm'));
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Modal.Box>

                <Modal.Box>
                  <FieldWrapper label={t('Day off')}>
                    <Field name="outOfOffice" type="checkbox">
                      {({ input }) => (
                        <Checkbox
                          label={t('Make this day off')}
                          {...(input as any)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Modal.Box>

                <Modal.Box>
                  <Field name="supplierNote" type="textarea">
                    {({ input }) => (
                      <TextArea
                        disabled={activeUser?.role === 'GUIDE'}
                        label={t('Note from the office')}
                        height={80}
                        {...(input as any)}
                      />
                    )}
                  </Field>
                </Modal.Box>

                <Modal.Box>
                  <Field name="guideNote" type="textarea">
                    {({ input }) => (
                      <TextArea
                        disabled={activeUser?.role !== 'GUIDE'}
                        label={t('Note from staff')}
                        height={80}
                        {...(input as any)}
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button.Cancel onClick={onClose}>{t('Discard')}</Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
