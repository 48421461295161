import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';
import baseStyles from 'client/base.module.css';

import styles from './AccessReport.module.css';

type Props = {
  data: GraphDataItem[];
};
export const DetailTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Date')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('visitCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('userCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('reservationCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Conversion Rate')}
            </th>
          </tr>
          {data.map((dataItem, idx) => {
            return (
              <tr key={idx}>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['center-align']
                  )}
                >
                  {dataItem.name}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {dataItem.visitCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {dataItem.userCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {dataItem.reservationCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {(dataItem.userCount
                    ? (dataItem.reservationCount / dataItem.userCount) * 100
                    : 0
                  ).toFixed(1)}{' '}
                  %
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
