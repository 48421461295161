// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  List,
  Message,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import {
  confirmReservation,
  fetchReservationByID,
} from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import {
  convertToLocationWithMoment,
  getStartTime,
  convertLocationWithMomentToReservationLocationWithTimeInput,
} from 'client/libraries/util/coreutil';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import {
  getInstanceDedicatedAllotments,
  getReservationWeightedParticipantCount,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import type { ReduxState } from 'client/reducers';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import type { ConfirmReservationPatch } from 'shared/models/swagger';

type OwnProps = {
  reservationID: string,
  onConfirmCompleted?: (void) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  pickup: LocationWithMoment,
  dropoff: LocationWithMoment,
  checkin: LocationWithMoment,
  pickupDropoffUseSameLocation: boolean,
  supplier_notes: string,
  supplier_reference: string,
};

class ConfirmReservationButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { locale, reservation } = props;

    const timezone = (reservation && reservation.start_timezone) || '';
    const pickup = convertToLocationWithMoment(
      reservation && reservation.pickup,
      timezone,
      locale
    );
    const dropoff = convertToLocationWithMoment(
      reservation && reservation.dropoff,
      timezone,
      locale
    );
    const checkin = convertToLocationWithMoment(
      reservation && reservation.checkin,
      timezone,
      locale
    );

    const pickupDropoffUseSameLocation =
      pickup.locationName === dropoff.locationName &&
      pickup.locationDescription === dropoff.locationDescription &&
      pickup.googlePlaceID === dropoff.googlePlaceID;

    this.state = {
      showModal: false,
      pickup,
      dropoff,
      checkin,
      pickupDropoffUseSameLocation,
      supplier_notes: '',
      supplier_reference: reservation && reservation.supplier_reference,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reservation &&
      prevProps.reservation !== this.props.reservation
    ) {
      const { locale, reservation } = this.props;

      const timezone = (reservation && reservation.start_timezone) || '';
      const pickup = convertToLocationWithMoment(
        reservation && reservation.pickup,
        timezone,
        locale
      );
      const dropoff = convertToLocationWithMoment(
        reservation && reservation.dropoff,
        timezone,
        locale
      );
      const checkin = convertToLocationWithMoment(
        reservation && reservation.checkin,
        timezone,
        locale
      );

      const pickupDropoffUseSameLocation =
        pickup.locationName === dropoff.locationName &&
        pickup.locationDescription === dropoff.locationDescription &&
        pickup.googlePlaceID === dropoff.googlePlaceID;

      this.setState({
        pickup,
        dropoff,
        checkin,
        pickupDropoffUseSameLocation,
      });

      this.fetchProduct();
      this.fetchProductInstance();
    }
  }

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }
  };

  fetchProduct = () => {
    const { reservation, product } = this.props;
    if (
      reservation &&
      reservation.product_id &&
      (!product || product.id !== reservation.product_id)
    ) {
      this.props.fetchProductByID(reservation.product_id);
    }
  };

  fetchProductInstance = () => {
    const { reservation, productInstance } = this.props;
    if (
      reservation &&
      reservation.product_instance_id &&
      (!productInstance ||
        productInstance.id !== reservation.product_instance_id)
    ) {
      this.props.fetchProductInstanceByID(reservation.product_instance_id);
    }
  };

  buildConfirmReservationRequest = (): ConfirmReservationPatch => {
    const { checkin, pickup, dropoff } = this.state;

    return {
      pickup:
        pickup &&
        convertLocationWithMomentToReservationLocationWithTimeInput(pickup),
      dropoff:
        dropoff &&
        convertLocationWithMomentToReservationLocationWithTimeInput(dropoff),
      checkin:
        checkin &&
        convertLocationWithMomentToReservationLocationWithTimeInput(checkin),
    };
  };

  handleAutofillPickupDropoff = () => {
    const { reservation } = this.props;
    const requestedPickup = reservation.requested_pickup_location;
    const requestedPickupLocationName =
      (requestedPickup && requestedPickup.location_name) || '';
    const requestedDropoff = reservation.requested_dropoff_location;
    const requestedDropoffLocationName =
      (requestedDropoff && requestedDropoff.location_name) || '';

    if (requestedPickupLocationName || requestedDropoffLocationName) {
      // If either requested pickup or requested dropoff are populated, autofill from those.
      const requestedPickupGooglePlaceId =
        (requestedPickup && requestedPickup.google_place_id) || '';
      const requestedDropoffGooglePlaceId =
        (requestedDropoff && requestedDropoff.google_place_id) || '';
      this.setState((prevState) => ({
        pickup: {
          ...prevState.pickup,
          locationName: requestedPickupLocationName,
          googlePlaceID: requestedPickupGooglePlaceId,
        },
        dropoff: {
          ...prevState.dropoff,
          locationName: requestedDropoffLocationName,
          googlePlaceID: requestedDropoffGooglePlaceId,
        },
      }));
    } else {
      // Otherwise, try to fill from customer hotel.
      const { reservation } = this.props;
      const guestHotel = reservation && reservation.guest_hotel;
      const locationName = (guestHotel && guestHotel.location_name) || '';
      const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

      if (locationName) {
        this.setState((prevState) => ({
          pickup: {
            ...prevState.pickup,
            locationName,
            googlePlaceID,
          },
          dropoff: {
            ...prevState.dropoff,
            locationName,
            googlePlaceID,
          },
        }));
      }
    }
  };

  handleAutofillCheckin = () => {
    const { reservation } = this.props;
    const guestHotel = reservation && reservation.guest_hotel;
    const locationName = (guestHotel && guestHotel.location_name) || '';
    const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

    if (locationName) {
      this.setState((prevState) => ({
        checkin: {
          ...prevState.checkin,
          locationName,
          googlePlaceID,
        },
      }));
    }
  };

  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState) => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.pickup;
        const dropoff = {
          locationID: pickup && pickup.locationID,
          locationName: pickup && pickup.locationName,
          locationDescription: pickup && pickup.locationDescription,
          googlePlaceID: pickup && pickup.googlePlaceID,
          locationDateTime: null,
        };

        return {
          pickupDropoffUseSameLocation: true,
          dropoff,
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };

  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState) => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.pickup;
        const dropoff = {
          locationID: pickup && pickup.locationID,
          locationName: pickup && pickup.locationName,
          locationDescription: pickup && pickup.locationDescription,
          googlePlaceID: pickup && pickup.googlePlaceID,
          locationDateTime: null,
        };

        return {
          pickupDropoffUseSameLocation: true,
          dropoff,
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  handleSupplierReferenceChange = (e) => {
    this.setState({ supplier_reference: e.target.value });
  };

  render() {
    const {
      confirmReservation,
      onConfirmCompleted,
      product,
      productInstance,
      reservation,
      loading,
      locale,
      t,
    } = this.props;
    const {
      checkin,
      dropoff,
      pickup,
      pickupDropoffUseSameLocation,
      supplier_notes,
      supplier_reference,
    } = this.state;
    const startTime = reservation && getStartTime(reservation).locale(locale);
    const timeSlotKey = productInstance?.time_slot_key ?? '';

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];
    const reservationID = (reservation && reservation.id) || '';
    const guestHotel = reservation && reservation.guest_hotel;
    const guestHotelName =
      (guestHotel && guestHotel.location_name) || t('(none)');
    const requestedPickup =
      reservation && reservation.requested_pickup_location;
    const requestedPickupLocationName =
      (requestedPickup && requestedPickup.location_name) || t('(none)');
    const requestedDropoff =
      reservation && reservation.requested_dropoff_location;
    const requestedDropoffLocationName =
      (requestedDropoff && requestedDropoff.location_name) || t('(none)');

    let confirmWarning = '';
    let bookedSlots = 0;
    let totalSlots = 0;
    if (reservation && product && productInstance) {
      const currentParticipantCount =
        productInstance.weighted_participant_count || 0;
      const resParticipantCount = getReservationWeightedParticipantCount(
        reservation,
        productInstance
      );
      const participantCountAfterCancel =
        currentParticipantCount + resParticipantCount;

      const minParticipantCount =
        (product.minimum_participant_count &&
          product.minimum_participant_count.value) ||
        0;
      if (
        minParticipantCount > 0 &&
        participantCountAfterCancel < minParticipantCount
      ) {
        confirmWarning = t(
          'This date and time has not yet met minimum participant requirements.'
        );
      }

      bookedSlots =
        (productInstance.occupied_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(productInstance),
          (a) => a.occupied_slots || 0
        );
      totalSlots =
        (productInstance.total_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(productInstance),
          (a) => a.total_slots || 0
        );
    }

    return (
      <Modal
        trigger={
          <Button icon basic onClick={() => this.setState({ showModal: true })}>
            <Icon name="checkmark" color="green" />
            {t('Confirm')}
          </Button>
        }
        style={{
          marginTop: 0,
        }}
        open={this.state.showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={this.fetchReservation}
      >
        <Header icon="checkmark" content={t('Confirm reservation')} />
        <Modal.Content>
          <Form warning={Boolean(confirmWarning)} loading={loading}>
            <Message warning content={confirmWarning} />
            <p>
              {t(
                'Current occupied allotment: {{bookedSlots}} / {{totalSlots}}',
                {
                  bookedSlots,
                  totalSlots: formattedTotalAllotmentSlots(totalSlots),
                }
              )}
            </p>
            <List>
              <List.Item>
                <List.Header>{t('Product')}</List.Header>
                <List.Content>{productName}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>{t('Start time')}</List.Header>
                <List.Content>{moment(startTime).format('lll')}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>{t('Total participants')}</List.Header>
                <List.Content>{guests.length}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>{t('Customer Hotel')}</List.Header>
                <List.Content>{guestHotelName}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>{t('Requested Pickup Location')}</List.Header>
                <List.Content>{requestedPickupLocationName}</List.Content>
              </List.Item>
              <List.Item>
                <List.Header>{t('Requested Dropoff Location')}</List.Header>
                <List.Content>{requestedDropoffLocationName}</List.Content>
              </List.Item>
            </List>
            {reservation &&
              (!reservationIsCheckinCheckoutOnly(reservation) ? (
                <>
                  <Form.Button
                    content={t('Autofill pickup/dropoff')}
                    onClick={this.handleAutofillPickupDropoff}
                  />
                  <LocationWithTimeEditFormFields
                    locationNameLabel={t('Pickup Location Name')}
                    locationDescriptionLabel={t(
                      'Pickup Location Description (ex: "Main lobby", "Car park", "Main wing")'
                    )}
                    locationTimeLabel={t('Pickup Time')}
                    locationDateLabel={t('Pickup Date')}
                    location={pickup}
                    productCandidateLocations={
                      (product && product.pickup) || []
                    }
                    startTime={startTime}
                    timeSlotKey={timeSlotKey}
                    onLocationChange={(pickup) => {
                      this.setState({
                        pickup,
                      });
                    }}
                  />
                  <Form.Checkbox
                    label={t('Use same location for pickup and dropoff')}
                    checked={pickupDropoffUseSameLocation}
                    onChange={this.handleTogglePickupDropoffUseSameLocation}
                  />
                  {!pickupDropoffUseSameLocation && (
                    <LocationWithTimeEditFormFields
                      locationNameLabel={t('Dropoff Location Name')}
                      locationDescriptionLabel={t(
                        'Dropoff Location Description (ex: "Main lobby", "Car park", "Main wing")'
                      )}
                      locationTimeLabel={t('Dropoff Time')}
                      locationDateLabel={t('Dropoff Date')}
                      location={dropoff}
                      productCandidateLocations={
                        (product && product.dropoff) || []
                      }
                      startTime={startTime}
                      timeSlotKey={timeSlotKey}
                      onLocationChange={(dropoff) => {
                        this.setState({
                          dropoff,
                        });
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Form.Button
                    content={t('Autofill checkin')}
                    onClick={this.handleAutofillCheckin}
                  />
                  <LocationWithTimeEditFormFields
                    locationNameLabel={t('Checkin Location Name')}
                    locationDescriptionLabel={t(
                      'Checkin Location Description (ex: "Main lobby", "Car park", "Main wing")'
                    )}
                    locationTimeLabel={t('Checkin Time')}
                    locationDateLabel={t('Checkin Date')}
                    location={checkin}
                    productCandidateLocations={
                      (product && product.checkin) || []
                    }
                    startTime={startTime}
                    timeSlotKey={timeSlotKey}
                    onLocationChange={(checkin) => {
                      this.setState({
                        checkin,
                      });
                    }}
                  />
                </>
              ))}
          </Form>
          <Divider section />
          <ReservationActorInputForm />
          <Form>
            <Form.Input
              control={TextArea}
              label={t('Replies')}
              value={supplier_notes}
              onChange={this.handleSupplierNotesChange}
            />
            <Form.Input
              label={t('Confirmation Number')}
              value={this.state.supplier_reference}
              onChange={this.handleSupplierReferenceChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            inverted
            onClick={() => {
              confirmReservation(
                reservationID,
                this.buildConfirmReservationRequest(),
                supplier_notes,
                supplier_reference
              ).then(() => onConfirmCompleted && onConfirmCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            <Icon name="checkmark" /> {t('Confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];

  return {
    locale: state.language.selected.iso,
    monthYearFormat: state.language.selected.monthYearFormat,
    reservation,
    product: reservation && state.products.byID[reservation.product_id || ''],
    productInstance:
      reservation &&
      state.productInstances.byID[reservation.product_instance_id],
    loading:
      state.products.loading ||
      state.productInstances.loading ||
      state.reservations.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
  fetchProductInstanceByID: (id: string) =>
    dispatch(fetchProductInstanceByID(id)),
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
  confirmReservation: (
    id: string,
    confirmReservationRequest: any,
    supplier_notes: string,
    supplier_reference: string
  ) =>
    dispatch(
      confirmReservation(
        id,
        confirmReservationRequest,
        supplier_notes,
        supplier_reference
      )
    ),
});

export const ConfirmReservationButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ConfirmReservationButtonComponent);
