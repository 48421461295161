import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import {
  loggedInAccountSelector,
  activeUserIsNutmegAdminSelector,
} from 'client/reducers/user';
import { buildSearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import { createReservationDataCsvExportOrder } from 'client/actions/reservationDataCsvExportOrders';
import { setReservationDataCsvExportOrderVisibleColumns } from 'client/actions/reservationDataCsvExportOrderTableControls';
import { InputActionOwnerNameModal } from 'client/components/InputActionOwnerNameModal/InputActionOwnerNameModal';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import type { ReservationColumn } from 'shared/models/swagger';

import { piiItems } from '../components/DownloadReservationsCSVModal/util';

import { Conditions } from './Conditions';
import { DownloadItems } from './DownloadItems';
import { ConsentForm } from './ConsentForm';
import { SuccessMessage } from './SuccessMessage';
import { CreateCSVFileButton } from './CreateCSVFileButton';

export const ConditionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appliedSearchCondition = useSelector(
    (state: ReduxState) => (state as any).reservationSearch.lastQuery
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const lastDownloadItems = useSelector(
    (state: ReduxState) =>
      (state as any).reservationDataCsvExportOrderTableControls.visibleColumns
  );
  const loggedInAccount = useSelector(loggedInAccountSelector);
  const lastCreatedReservationDataCsvExportOrder = useSelector(
    (statue: ReduxState) =>
      (statue as any).reservationDataCsvExportOrders.lastCreated
  );

  const [appliedDownloadItems, setAppliedDownloadItems] = React.useState<
    string[] | null
  >(lastDownloadItems);

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  type ConsentFormState = {
    visible: boolean;
    checked: boolean;
  };

  const [openInputActionOwnerNameModal, setOpenInputActionOwnerNameModal] =
    React.useState<boolean>(false);

  const includesPii = (items: string[]) => {
    return items.some((item) => piiItems.includes(item));
  };

  const consentFormVisible = includesPii(appliedDownloadItems ?? []);
  const [consentFormState, setConsentFormState] =
    React.useState<ConsentFormState>({
      visible: consentFormVisible,
      checked: false,
    });

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        setReservationDataCsvExportOrderVisibleColumns(
          appliedDownloadItems ?? []
        )
      );
    }
  }, [appliedDownloadItems, isNutmegAdmin]);

  const donwloadable = (): boolean => {
    if ((appliedDownloadItems ?? []).length === 0) {
      return false;
    }

    if (!includesPii(appliedDownloadItems ?? [])) {
      return true;
    }

    if (consentFormState.checked) {
      return true;
    }

    return false;
  };

  if (!loggedInAccount) throw new Error('loggedInAccount is null.');
  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        componentStyles['is-active']
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <Conditions appliedSearchCondition={appliedSearchCondition} />
        <DownloadItems
          appliedDownloadItems={appliedDownloadItems ?? []}
          onApply={(items: string[]) => {
            setAppliedDownloadItems(items);
            const visible = includesPii(items);
            setConsentFormState({
              visible: visible,
              checked: false,
            });
          }}
        />
        <ConsentForm
          visible={consentFormState.visible}
          checked={consentFormState.checked}
          onChange={(e) => {
            if (e.target instanceof HTMLInputElement) {
              const checked = e.target.checked;
              setConsentFormState({ ...consentFormState, checked: checked });
            }
          }}
        />
        <SuccessMessage
          visible={showSuccessMessage}
          dataExportStatus={
            lastCreatedReservationDataCsvExportOrder?.data_export_status ?? null
          }
        />
        <CreateCSVFileButton
          loading={loading}
          disabled={!donwloadable()}
          onClick={() => {
            setOpenInputActionOwnerNameModal(true);
          }}
        />
        <InputActionOwnerNameModal
          initialName={loggedInAccount.name}
          open={openInputActionOwnerNameModal}
          onClose={() => setOpenInputActionOwnerNameModal(false)}
          onActionStart={(name) => {
            const fn = async () => {
              const columns = (appliedDownloadItems ?? []).map(
                (col) => col.toUpperCase() as any as ReservationColumn
              );
              const request = buildSearchReservationsRequest(
                appliedSearchCondition
              );

              const { most_recent_email_bounced, ...rest } = request;

              try {
                setShowSuccessMessage(false);
                setLoading(true);
                await dispatch(
                  createReservationDataCsvExportOrder({
                    ...rest,
                    most_recent_email_bounced: most_recent_email_bounced
                      ? {
                          value: most_recent_email_bounced,
                        }
                      : undefined,
                    columns,
                    name,
                  })
                );
                setShowSuccessMessage(true);
              } catch (e) {
                console.error(e);
              } finally {
                setLoading(false);
              }
            };
            fn();
          }}
          title={t('Download Reservation Data')}
          buttonText={t('Create CSV file')}
        />
      </div>
    </div>
  );
};
