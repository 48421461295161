import * as React from 'react';
import clsx from 'clsx';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

export type TabPane = {
  header: string;
  content: React.ComponentType<any>;
};
interface Props {
  panes: TabPane[];
  // Pass both properties below to use Tabs as controlled component
  activeIndex?: number | null;
  onActiveIndexChange?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const Tabs = ({ panes, activeIndex: index = null }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (index != null) {
      setActiveIndex(index);
    }
  }, [index]);

  return (
    <div
      className={clsx(
        componentStyles['c-tab-box'],
        baseStyles['scroll-target-pane']
      )}
    >
      {panes.map((pane, idx) => {
        const Content = pane.content;
        return idx === activeIndex ? <Content key={idx} /> : null;
      })}
    </div>
  );
};
