import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { nonNull } from 'client/libraries/util/nonNull';
import { InquiryMessageTemplate } from 'shared/models/swagger';

interface Props {
  onInsert: (messageTemplate: InquiryMessageTemplate) => void;
  onClose: () => void;
}

export const InsertMessageTemplateModal = ({ onInsert, onClose }: Props) => {
  const [selectedMessageTemplateName, setSelectedMessageTemplateName] =
    React.useState('');
  const { t } = useTranslation();
  const org = useSelector(activeUserOrganizationSelector);
  const messageTemplateOptions = [
    {
      value: 'none',
      text: '',
    },
    ...(org?.inquiry_settings?.inquiry_message_templates ?? []).map(
      (template) => ({
        value: template.name ?? '',
        text: template.name ?? '',
      })
    ),
  ];

  const selectedMessageTemplate =
    org?.inquiry_settings?.inquiry_message_templates?.find(
      (template) => template.name === selectedMessageTemplateName
    );

  return (
    <Modal
      insertRoot={true}
      open={true}
      onClose={onClose}
      title={t('Insert Message Template')}
    >
      <Select
        label={t('Select Message Template')}
        options={messageTemplateOptions}
        value={selectedMessageTemplateName}
        onChange={(e, { value }) => setSelectedMessageTemplateName(value)}
      />
      <Modal.Actions>
        <Button.Submit
          disabled={!selectedMessageTemplate}
          onClick={() => {
            onInsert(nonNull(selectedMessageTemplate));
            onClose();
          }}
        >
          {t('Insert')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
