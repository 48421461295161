import * as React from 'react';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';
import { FieldArray } from 'react-final-form-arrays';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateAboutUsSettings } from 'client/actions/essentialPages';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { getArrayMutators } from 'client/libraries/util/form';
//import {
//  getLanguageName,
//  uppercaseIsoToLowercaseIso,
//} from 'client/libraries/i18n';
//import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import { Editor } from 'client/components/Editor/Editor';
import type { SourceLanguage, AboutUsSettings } from 'shared/models/swagger';
import * as Swagger from 'shared/models/swagger';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
//import baseStyles from 'client/v3-base.module.css';

const focusOnError: any = createDecorator();
export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};
type MessageTranslationsFormValues = {
  message_body: string;
  trans_message_body: string;
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

export const AboutUsTab = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  console.log(setShowTranslationMode);

  // supplier not always support EN_US
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>(
      getBookingWidgetPmpSupportLanguages(activeUserOrganization).find(
        (supportedLanguage) => supportedLanguage !== defaultLanguage
      ) || 'EN_US'
    );
  console.log(setTranslationTargetLanguage);

  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);
  //const [transEditorJsLoading, setTransEditorJsLoading] =
  //  React.useState<boolean>(false);
  React.useEffect(() => {
    if (showTranslationMode) {
      const aboutUsSettings =
        activeUserOrganization?.essential_pages?.about_us_settings;

      if (aboutUsSettings) {
        const texts = [];

        if (aboutUsSettings.title) {
          texts.push(aboutUsSettings.title);
        }

        (aboutUsSettings.other_company_profiles || []).forEach((profile) => {
          if (profile.key) {
            texts.push(profile.key);
          }

          if (profile.value) {
            texts.push(profile.value);
          }
        });
        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);
  const initialValuesWithTranslations = React.useMemo(() => {
    const messages =
      activeUserOrganization?.essential_pages?.about_us_settings?.messages ||
      [];
    const defaultLanguageMessage = messages.find(
      (message) => message.content_language === defaultLanguage
    );
    const translationTargetLanguageMessage = messages.find(
      (message) => message.content_language === translationTargetLanguage
    );
    return {
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      ...activeUserOrganization?.essential_pages?.about_us_settings,
      message_body: JSON.parse(defaultLanguageMessage?.body || '{}'),
      trans_message_body: JSON.parse(
        translationTargetLanguageMessage?.body || '{}'
      ),
    };
  }, [
    apiTranslations,
    translationTargetLanguage,
    activeUserOrganization,
    defaultLanguage,
  ]);

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  //const changeShowTranslationMode = (show: boolean) => {
  //  setShowTranslationMode(show);
  //};

  //const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
  //  setTranslationTargetLanguage(lang);
  //};

  return (
    <>
      <Form
        onSubmit={async (
          values: AboutUsSettings &
            TranslationsFormValues &
            MessageTranslationsFormValues
        ) => {
          const previousMessages =
            activeUserOrganization?.essential_pages?.about_us_settings
              ?.messages;
          let messages = previousMessages ? [...previousMessages] : [];
          messages = [
            ...messages.filter(
              (message) => message.content_language !== defaultLanguage
            ),
            {
              content_language: defaultLanguage,
              body: JSON.stringify(values.message_body),
            },
          ];

          if (showTranslationMode) {
            messages = [
              ...messages.filter(
                (message) =>
                  message.content_language !== translationTargetLanguage
              ),
              {
                content_language: translationTargetLanguage,
                body: JSON.stringify(values.trans_message_body),
              },
            ];
          }

          values.messages = messages;
          const promises = [dispatch(updateAboutUsSettings(values))];

          if (showTranslationMode) {
            promises.push(
              dispatch(
                updateTranslations(
                  values.translations.map((translation: any) => ({
                    source_language: defaultLanguage,
                    [defaultLanguage.toLowerCase()]: translation.source,
                    [translationTargetLanguage.toLowerCase()]:
                      translation.target,
                  }))
                )
              ) as any
            );
          }

          try {
            await Promise.all(promises);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
        initialValues={initialValuesWithTranslations}
        keepDirtyOnReinitialize={true}
      >
        {({
          handleSubmit,
          submitting,
          submitSucceeded,
          submitError,
          modifiedSinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div
              className={clsx(
                styles['p-inquiry-tabs__tab-panel'],
                styles['components_is-active__B15DT']
              )}
              style={{ display: 'block' }}
            >
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'success'}
                  text={t('Save Successful')}
                  shouldShow={submitSucceeded}
                />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Snackbar
                  color={'error'}
                  text={t('Save Failed')}
                  shouldShow={submitError}
                />
              )}
              <div className={styles['p-inquiry-tabs__content']}>
                <ul className={styles['p-inquiry-list']}>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Visibility')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="visibility" type="checkbox">
                          {({ input }) => (
                            <div>
                              <Toggle
                                label={t(
                                  'Show About Us page on booking website'
                                )}
                                {...input}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Title')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="title">
                          {({ input, meta: { touched, error } }) => (
                            <TextField
                              label={t('Title')}
                              value={input.value}
                              onChange={input.onChange}
                              error={touched && error}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Key visual')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="key_visual">
                          {({ input }) => (
                            <SingleImageInput
                              fileTypes={['image/jpeg', 'image/png']}
                              onUploadFinished={(_, objectUrl) =>
                                input.onChange(objectUrl)
                              }
                              initialValue={input.value}
                              saveFilePath={`essential/supplier/${activeUserOrganization?.id}`}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Message')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                        style={{ display: 'block' }}
                      >
                        <Field name="message_body">
                          {({ input }) => (
                            <Editor
                              imageS3Prefix={`editor/supplier/${
                                activeUserOrganization?.id || ''
                              }`}
                              data={input.value}
                              onChange={(data: any, loading) => {
                                setEditorJsLoading(loading);
                                input.onChange(data);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Other Company Profile')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <FieldArray name={`other_company_profiles`}>
                          {({ fields }) => {
                            const count = fields.length ?? 0;
                            return (
                              <div>
                                {fields.map((name, formIdx) => (
                                  <div
                                    className={styles['company__item']}
                                    style={{ display: 'flex' }}
                                    key={formIdx}
                                  >
                                    <div
                                      className={styles['company__item__box']}
                                    >
                                      <div
                                        style={{
                                          display: 'block',
                                        }}
                                      >
                                        <Field name={`${name}.key`}>
                                          {({
                                            input,
                                            meta: { error, touched },
                                          }) => (
                                            <>
                                              <div
                                                style={{
                                                  margin: '10px 10px 10px',
                                                }}
                                              >
                                                <p>
                                                  {' '}
                                                  <TextField
                                                    {...input}
                                                    label={t('Title')}
                                                    error={touched && error}
                                                  />{' '}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </Field>

                                        <Field name={`${name}.value`}>
                                          {({
                                            input,
                                            meta: { error, touched },
                                          }) => (
                                            <>
                                              <div
                                                style={{
                                                  margin: '10px 10px 10px',
                                                }}
                                              >
                                                <p>
                                                  {' '}
                                                  <TextField
                                                    {...input}
                                                    label={t('Description')}
                                                    error={touched && error}
                                                  />{' '}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                    <Button
                                      size="icon"
                                      color="tertiarygray"
                                      iconBeforeText={
                                        <i className="c-icon-outline-general-trash-03"></i>
                                      }
                                      onClick={() => fields.remove(formIdx)}
                                    />
                                  </div>
                                ))}
                                <a
                                  className={styles['add__button']}
                                  onClick={() => {
                                    if (count === 0) {
                                      (fields as any).insertAt(0, '');
                                    } else {
                                      (fields as any).insertAt(count + 1, '');
                                    }
                                  }}
                                >
                                  <i className="c-icon-outline-general-plus-circle"></i>
                                  {t('Add')}
                                </a>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                  </li>

                  <li className={styles['p-inquiry-list__item']}>
                    <div className={styles['p-inquiry-list__item__ttl']}>
                      <div className={styles['p-inquiry-list__item__ttl__txt']}>
                        <div>{t('Google Map Embed Tag')}</div>
                      </div>
                    </div>
                    <div className={styles['p-inquiry-list__item__body']}>
                      <div
                        className={styles['p-inquiry-list__item__body__item']}
                      >
                        <Field name="google_map_tag">
                          {({ input }) => (
                            <TextField
                              maxWidth={800}
                              height={'150px'}
                              placeholder={
                                "<iframe src='https://www.google.com/maps/embed..."
                              }
                              {...(input as any)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={clsx(styles['p-products__fixed'], styles['is-active'])}
            >
              <div className={styles['p-products__fixed__actions']}>
                <Button
                  disabled={submitting || editorJsLoading}
                  loading={submitting || editorJsLoading}
                  text={t('Save')}
                  type="submit"
                  color="primary"
                  size="md"
                  form="submit"
                  style={{
                    width: '60px',
                    fontWeight: 'var(--text-semibold)',
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
