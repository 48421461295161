import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';

import { isYouTubeUrl } from 'client/components/NewProductEditor/util';
import { getYouTubePreviewUrl } from 'client/libraries/util/getYouTubePreviewUrl';

const ImageItemType = 'image';
type DragItem = {
  index: number;
  imageVideoUrl: string;
  type: string;
};
type Props = {
  imageVideoUrl: string;
  index: number;
  onRemove: () => void;
  onMoveImage: (dragIndex: number, hoverIndex: number) => void;
};
export const DraggablePreviewImage = ({
  imageVideoUrl,
  index,
  onRemove,
  onMoveImage,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop({
    accept: ImageItemType,

    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      onMoveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ImageItemType,
    item: {
      type: ImageItemType,
      imageVideoUrl,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;
  const previewUrl = isYouTubeUrl(imageVideoUrl)
    ? getYouTubePreviewUrl(imageVideoUrl)
    : imageVideoUrl;

  return (
    <div
      ref={ref}
      style={{
        opacity,
      }}
    >
      <img src={previewUrl} />
      <a onClick={onRemove}>
        <i className="c-icon-outline-general-x-close"></i>
      </a>
    </div>
  );
};
