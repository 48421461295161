import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { ToggleButton } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/Modal/Modal';

import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from './BookingWidgetSettingsFormValues';

type Props = {
  onClose: () => void;
};
export const FreeStartDateTimeModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product, activeUserOrganization),
    [activeUserOrganization, product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Free pass settings')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="isFreeStartDateTime">
        {({ input }) => {
          return (
            <Modal.Box>
              <ToggleButton {...input} label={t('Free Pass')} />
            </Modal.Box>
          );
        }}
      </Field>
    </ProductEditorModal>
  );
};
