import * as React from 'react';
import moment, { Moment } from 'moment-timezone';
import { useSelector } from 'react-redux';

import { ReservationCreateModal } from 'client/components/ReservationCreateModal/ReservationCreateModal';
import { ProductInstanceEditModal } from 'client/components/ProductInstanceEditModal/ProductInstanceEditModal';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import type { Reservation, ProductInstance } from 'shared/models/swagger';

type Props = {
  trigger?: React.ReactElement<'span' | 'a' | 'button'>;
  reservation?: Reservation;
  productId?: string;
  defaultDate?: Moment;
  open?: boolean;
  onClose?: () => void;
  customerId?: string;
};
export const ReservationCreateModalButton = ({
  trigger,
  reservation,
  productId,
  defaultDate,
  open,
  onClose,
  customerId,
}: Props) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [editingProductInstance, setEditingProductInstance] =
    React.useState<null | ProductInstance>(null);
  const activeUser = useSelector(activeUserSelector);

  const getDefaultDateMoment = () => {
    return defaultDate ? defaultDate : moment();
  };

  // Show Edit Product Instance modal when the "Edit" button is clicked at Product Instance modal.
  const handleEditClick = (editingProductInstance: ProductInstance) => {
    setEditingProductInstance(editingProductInstance);
  };

  return (
    <>
      {trigger && (
        <>
          {React.cloneElement(trigger as any, {
            onClick: () => {
              setShowModal(true);
            },
          })}
        </>
      )}

      {(showModal || open) && (
        <ReservationCreateModal
          defaultDate={getDefaultDateMoment()}
          onClose={() => {
            setShowModal(false);
            onClose && onClose();
          }}
          reservation={reservation}
          productId={productId}
          onEditClick={
            operationAllowed(activeUser, 'write', 'productInstances')
              ? handleEditClick
              : undefined
          }
          customerId={customerId}
        />
      )}

      {editingProductInstance && (
        <ProductInstanceEditModal
          insertRoot={true}
          oldProductInstance={editingProductInstance}
          onClose={() => {
            setEditingProductInstance(null);
          }}
          open={editingProductInstance !== null}
        />
      )}
    </>
  );
};
