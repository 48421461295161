// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'client/components/Tabs/Tabs';

import { BasicTab } from './BasicTab/BasicTab';
import { PerEmailMessagesTab } from './PerEmailMessagesTab/PerEmailMessagesTab';
import { CommonMessagesTab } from './CommonMessagesTab/CommonMessagesTab';
import { PerProductMessagesTab } from './PerProductMessagesTab/PerProductMessagesTab';

export const EmailCustomization = () => {
  const { t } = useTranslation();

  const tabPanes = React.useMemo(
    () => [
      {
        header: t('General'),
        content: () => <BasicTab />,
      },
      {
        header: t('Per-Email Messages'),
        content: () => <PerEmailMessagesTab />,
      },
      {
        header: t('Common Messages'),
        content: () => <CommonMessagesTab />,
      },
      {
        header: t('Per-Product Messages'),
        content: () => <PerProductMessagesTab />,
      },
    ],
    [t]
  );

  return <Tabs panes={tabPanes} />;
};
