import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import {
  getGuestCounts,
  compareFunc,
} from 'client/pages/ETicket/ETicketDailyUses/util';
import componentStyles from 'client/components/components.module.css';

import styles from './ETicketDailyUsesProductTable.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
  productName: string;
  productId: string;
};
export const ETicketDailyUsesProductTable = ({
  items,
  columns,
  productName,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const productsById = useSelector((state: ReduxState) => state.products.byID);

  const desiredStubKeySort = productsById[
    productId
  ]?.qr_checkin_settings?.stubs?.map((s) => s.key);

  const desiredStubOptionSort: Record<string, string[]> = {};
  productsById[productId]?.qr_checkin_settings?.stubs?.forEach((s) => {
    desiredStubOptionSort[s.key ?? ''] =
      s?.options?.map((o) => o.key ?? '') ?? [];
  });
  items.sort(compareFunc(desiredStubKeySort, desiredStubOptionSort));
  const groupByStubKeyItems = _.groupBy(items, (item) =>
    item.stubOptionText
      ? `${item.stubKey} ${item.stubOptionText}`
      : item.stubKey
  );
  Object.keys(groupByStubKeyItems).forEach((key) => {
    groupByStubKeyItems[key].sort((a: any, b: any) => {
      const moment1 = moment(a.dateTimeUtc);
      const moment2 = moment(b.dateTimeUtc);

      if (moment1.diff(moment2) > 0) {
        return 1;
      }
      if (moment1.diff(moment2) < 0) {
        return -1;
      }
      return 0;
    });
  });

  const totalGuestsByStubKey: Record<string, string> = {};
  const totalCountByStubKey: Record<string, number> = {};
  Object.keys(groupByStubKeyItems).forEach((key) => {
    const totalGuests: Record<string, number> = {};
    let totalCount = 0;
    groupByStubKeyItems[key].forEach((item: any) => {
      const guestSet = getGuestCounts(item.guests ?? []);
      Object.keys(guestSet).forEach((guest) => {
        if (!totalGuests[guest]) {
          totalGuests[guest] = 0;
        }
        totalGuests[guest] += item[guest];
        totalCount += item[guest];
      });
    });
    totalGuestsByStubKey[key] = Object.keys(totalGuests)
      .map((guest) => {
        return `${guest}:${totalGuests[guest]}`;
      })
      .join(', ');
    totalCountByStubKey[key] = totalCount;
  });

  return (
    <div className={clsx(styles['table-pane'])}>
      <div className={clsx(styles['title'])}>{productName}</div>
      {Object.keys(groupByStubKeyItems).map((item) => {
        return (
          <>
            <div className={clsx(componentStyles['c-table-manifests'])}>
              <div
                className={clsx(componentStyles['c-table-manifests__tbody'])}
              >
                <div
                  className={clsx(
                    componentStyles['c-table-manifests__tbody__ttl'],
                    componentStyles['is-open']
                  )}
                >
                  {item && (
                    <p
                      className={clsx(
                        componentStyles['c-table-manifests__tbody__ttl__time']
                      )}
                    >
                      {item}
                    </p>
                  )}
                  <div
                    className={clsx(
                      componentStyles['c-table-manifests__tbody__ttl__num']
                    )}
                  >
                    {`${t('total')}: ${totalCountByStubKey[item]} (${
                      totalGuestsByStubKey[item]
                    })`}
                  </div>
                </div>
              </div>
            </div>
            <CustomTable
              items={groupByStubKeyItems[item] as any}
              columns={columns}
            />
          </>
        );
      })}
    </div>
  );
};
