import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { currency } from 'shared/libraries/currency';
import { Box } from 'client/components/Box/Box';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import componentStyles from 'client/components/components.module.css';
import type {
  BusinessLineItem,
  InvoiceSummaryRow,
  Transaction,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './SummaryTable.module.css';
import { getSummaryColumnHeader } from './util';

interface BreakdownRow {
  businessLineItems: BusinessLineItem[];
  description: string;
  paymentAmounts: {
    gross?: string;
    net?: string;
    commission?: string;
    dueByAgent?: string;
    dueBySupplier?: string;
  };
}

interface BreakdownRows {
  PIF: BreakdownRow;
  POB: BreakdownRow;
  SPLIT_PAYMENT: BreakdownRow;
  SUBTOTAL: BreakdownRow;
  INVOICE_ADJUSTMENT: BreakdownRow;
  TOTAL: BreakdownRow;
}

type Props = {
  invoiceBusinessLineItems: BusinessLineItem[];
  fareAdjustmentBusinessLineItems: BusinessLineItem[];
  businessLineItemsPIF: BusinessLineItem[];
  businessLineItemsPOB: BusinessLineItem[];
  businessLineItemsSP: BusinessLineItem[];
  getInvoiceFareAdjustmentDueByEntities: (
    transaction: Transaction
  ) => Record<string, any>;
};

export const SummaryTable = ({
  invoiceBusinessLineItems,
  fareAdjustmentBusinessLineItems,
  getInvoiceFareAdjustmentDueByEntities,
  businessLineItemsPIF,
  businessLineItemsPOB,
  businessLineItemsSP,
}: Props) => {
  const { t } = useTranslation();

  const getCurrencyCode = (lineItems: BusinessLineItem[]): string => {
    const amountDue =
      lineItems?.length && lineItems[0] && lineItems[0].amount_due;
    return amountDue ? amountDue.slice(0, 3) : '';
  };

  const visibleSummaryColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryColumns
  );
  const visibleSummaryRows = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryRows
  );

  const sumLineItemsByAttribute = (
    lineItems: BusinessLineItem[],
    attribute:
      | 'amount_gross'
      | 'amount_net'
      | 'amount_commission'
      | 'amount_due_by_agent'
      | 'amount_due_by_supplier'
  ) => {
    const currencyCode = getCurrencyCode(lineItems);

    if (!currencyCode) {
      return '';
    }

    let sum = currency(0, currencyCode);
    lineItems.forEach((lineItem) => {
      if (lineItem[attribute]) {
        sum = sum.add(currency(lineItem[attribute] ?? 0));
      }
    });
    return sum.format();
  };

  const getInvoiceSummaryColumnSum = (
    breakdownRowsInfo: BreakdownRows,
    breakdownRowLabels: (keyof BreakdownRows)[],
    columnAttribute: keyof BreakdownRow['paymentAmounts'],
    currencyCode: string
  ) => {
    if (!currencyCode) {
      return '';
    }

    let sum = currency(0, currencyCode);
    breakdownRowLabels &&
      breakdownRowLabels.forEach((breakdownRowLabel) => {
        sum = sum.add(
          currency(
            breakdownRowsInfo[breakdownRowLabel].paymentAmounts[
              columnAttribute
            ] ?? 0
          )
        );
      });
    return sum.format();
  };

  const getInvoiceAgentSupplierAdjustments = (currencyCode: string) => {
    const agentSupplierAdjustments = {
      totalDueByAgentAdjustments: currency(0, currencyCode),
      totalDueBySupplierAdjustments: currency(0, currencyCode),
    };
    fareAdjustmentBusinessLineItems &&
      fareAdjustmentBusinessLineItems[0] &&
      fareAdjustmentBusinessLineItems[0].transactions &&
      fareAdjustmentBusinessLineItems[0].transactions.forEach((transaction) => {
        const fareAdjustmentDueByEntities =
          getInvoiceFareAdjustmentDueByEntities(transaction);
        const dueByAgentAdjustment =
          fareAdjustmentDueByEntities &&
          fareAdjustmentDueByEntities.amountDueByAgent;
        const dueBySupplierAdjustment =
          fareAdjustmentDueByEntities &&
          fareAdjustmentDueByEntities.amountDueBySupplier;
        agentSupplierAdjustments.totalDueByAgentAdjustments =
          agentSupplierAdjustments.totalDueByAgentAdjustments.add(
            currency(dueByAgentAdjustment)
          );
        agentSupplierAdjustments.totalDueBySupplierAdjustments =
          agentSupplierAdjustments.totalDueBySupplierAdjustments.add(
            currency(dueBySupplierAdjustment)
          );
      });
    return (
      fareAdjustmentBusinessLineItems &&
      fareAdjustmentBusinessLineItems[0] &&
      agentSupplierAdjustments
    );
  };

  const getInvoiceSummaryColumnAdjustments = (
    breakdownRows: BreakdownRows,
    currencyCode: string
  ) => {
    const agentSupplierAdjustments =
      getInvoiceAgentSupplierAdjustments(currencyCode);
    const dueByAgentInvoiceAdjustments =
      agentSupplierAdjustments &&
      agentSupplierAdjustments.totalDueByAgentAdjustments;
    const dueBySupplierInvoiceAdjustments =
      agentSupplierAdjustments &&
      agentSupplierAdjustments.totalDueBySupplierAdjustments;
    const summaryColumnAdjustments = {
      amountGrossAdjusted: '',
      amountNetAdjusted:
        dueByAgentInvoiceAdjustments &&
        dueByAgentInvoiceAdjustments
          .subtract(dueBySupplierInvoiceAdjustments)
          .format(),
      amountCommissionAdjusted:
        dueBySupplierInvoiceAdjustments &&
        dueBySupplierInvoiceAdjustments
          .subtract(dueByAgentInvoiceAdjustments)
          .format(),
      amountDueByAgentAdjusted:
        dueByAgentInvoiceAdjustments && dueByAgentInvoiceAdjustments.format(),
      amountDueBySupplierAdjusted:
        dueBySupplierInvoiceAdjustments &&
        dueBySupplierInvoiceAdjustments.format(),
    };
    return summaryColumnAdjustments;
  };

  const getSummaryBreakdownRows = () => {
    const currencyCode = getCurrencyCode(invoiceBusinessLineItems);
    const breakdownRows: BreakdownRows = {
      PIF: {
        description: t('PIF'),
        businessLineItems: businessLineItemsPIF,
        paymentAmounts: {},
      },
      POB: {
        description: t('POB'),
        businessLineItems: businessLineItemsPOB,
        paymentAmounts: {},
      },
      SPLIT_PAYMENT: {
        description: t('Split Payment'),
        businessLineItems: businessLineItemsSP,
        paymentAmounts: {},
      },
      SUBTOTAL: {
        description: t('Subtotal'),
        businessLineItems: [],
        paymentAmounts: {},
      },
      INVOICE_ADJUSTMENT: {
        description: t('Invoice Adjustments'),
        businessLineItems: [],
        paymentAmounts: {},
      },
      TOTAL: {
        description: t('Total'),
        businessLineItems: [],
        paymentAmounts: {},
      },
    };
    let amountGross,
      amountNet,
      amountCommission,
      amountDueByAgent,
      amountDueBySupplier;

    for (const [breakdownRow, summaryRow] of Object.entries(breakdownRows)) {
      switch (breakdownRow) {
        case 'PIF':
        case 'POB':
        case 'SPLIT_PAYMENT':
          amountGross = sumLineItemsByAttribute(
            summaryRow.businessLineItems,
            'amount_gross'
          );
          amountNet = sumLineItemsByAttribute(
            summaryRow.businessLineItems,
            'amount_net'
          );
          amountCommission = sumLineItemsByAttribute(
            summaryRow.businessLineItems,
            'amount_commission'
          );
          amountDueByAgent = sumLineItemsByAttribute(
            summaryRow.businessLineItems,
            'amount_due_by_agent'
          );
          amountDueBySupplier = sumLineItemsByAttribute(
            summaryRow.businessLineItems,
            'amount_due_by_supplier'
          );
          break;

        case 'SUBTOTAL':
          amountGross = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['PIF', 'POB', 'SPLIT_PAYMENT'],
            'gross',
            currencyCode
          );
          amountNet = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['PIF', 'POB', 'SPLIT_PAYMENT'],
            'net',
            currencyCode
          );
          amountCommission = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['PIF', 'POB', 'SPLIT_PAYMENT'],
            'commission',
            currencyCode
          );
          amountDueByAgent = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['PIF', 'POB', 'SPLIT_PAYMENT'],
            'dueByAgent',
            currencyCode
          );
          amountDueBySupplier = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['PIF', 'POB', 'SPLIT_PAYMENT'],
            'dueBySupplier',
            currencyCode
          );
          break;

        case 'INVOICE_ADJUSTMENT': {
          const summaryColumnAdjustments = getInvoiceSummaryColumnAdjustments(
            breakdownRows,
            currencyCode
          );
          amountGross =
            summaryColumnAdjustments &&
            summaryColumnAdjustments.amountGrossAdjusted;
          amountNet =
            summaryColumnAdjustments &&
            summaryColumnAdjustments.amountNetAdjusted;
          amountCommission =
            summaryColumnAdjustments &&
            summaryColumnAdjustments.amountCommissionAdjusted;
          amountDueByAgent =
            summaryColumnAdjustments &&
            summaryColumnAdjustments.amountDueByAgentAdjusted;
          amountDueBySupplier =
            summaryColumnAdjustments &&
            summaryColumnAdjustments.amountDueBySupplierAdjusted;
          break;
        }

        case 'TOTAL':
          amountGross = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['SUBTOTAL', 'INVOICE_ADJUSTMENT'],
            'gross',
            currencyCode
          );
          amountNet = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['SUBTOTAL', 'INVOICE_ADJUSTMENT'],
            'net',
            currencyCode
          );
          amountCommission = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['SUBTOTAL', 'INVOICE_ADJUSTMENT'],
            'commission',
            currencyCode
          );
          amountDueByAgent = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['SUBTOTAL', 'INVOICE_ADJUSTMENT'],
            'dueByAgent',
            currencyCode
          );
          amountDueBySupplier = getInvoiceSummaryColumnSum(
            breakdownRows,
            ['SUBTOTAL', 'INVOICE_ADJUSTMENT'],
            'dueBySupplier',
            currencyCode
          );
          break;

        default:
          break;
      }

      summaryRow.paymentAmounts = {
        gross: amountGross,
        net: amountNet,
        commission: amountCommission,
        dueByAgent: amountDueByAgent,
        dueBySupplier: amountDueBySupplier,
      };
    }

    return breakdownRows;
  };

  if (!invoiceBusinessLineItems || invoiceBusinessLineItems.length === 0) {
    return null;
  }

  const breakdownRows = getSummaryBreakdownRows();
  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency: getCurrencyCode(invoiceBusinessLineItems),
          })}
        </Box>
      </Box>
      <div className={componentStyles['c-table-nowrap']}>
        <table>
          <tbody>
            <tr>
              <th className={clsx(baseStyles['base-t-112'], styles['center'])}>
                {t('Payment Type')}
              </th>
              {visibleSummaryColumns.map((column) => (
                <th
                  key={column}
                  className={clsx(baseStyles['base-t-112'], styles['center'])}
                >
                  {getSummaryColumnHeader(column, t)}
                </th>
              ))}
            </tr>
            {[...visibleSummaryRows, 'TOTAL'].map((breakdownRow) => {
              const summaryRow =
                breakdownRows[breakdownRow as keyof BreakdownRows];
              if (
                breakdownRow !== 'TOTAL' &&
                !visibleSummaryRows.includes(breakdownRow as InvoiceSummaryRow)
              ) {
                return null;
              }

              return (
                <tr key={breakdownRow}>
                  <td
                    className={clsx(
                      baseStyles['base-t-112'],
                      breakdownRow === 'TOTAL' && styles['gray-background'],
                      (breakdownRow === 'SUBTOTAL' ||
                        breakdownRow === 'TOTAL') &&
                        styles['bold']
                    )}
                  >
                    {summaryRow.description}
                  </td>
                  {visibleSummaryColumns.map((column) => {
                    switch (column) {
                      case 'GROSS':
                        return (
                          <td
                            className={clsx(
                              styles['right'],
                              baseStyles['base-t-112'],
                              breakdownRow === 'TOTAL' &&
                                styles['gray-background'],
                              (breakdownRow === 'SUBTOTAL' ||
                                breakdownRow === 'TOTAL') &&
                                styles['bold']
                            )}
                          >
                            {getFormattedAmount(
                              summaryRow.paymentAmounts.gross
                            )}
                          </td>
                        );
                      case 'NET':
                        return (
                          <td
                            className={clsx(
                              styles['right'],
                              baseStyles['base-t-112'],
                              breakdownRow === 'TOTAL' &&
                                styles['gray-background'],
                              (breakdownRow === 'SUBTOTAL' ||
                                breakdownRow === 'TOTAL') &&
                                styles['bold']
                            )}
                          >
                            {getFormattedAmount(summaryRow.paymentAmounts.net)}
                          </td>
                        );
                      case 'COMMISSION':
                        return (
                          <td
                            className={clsx(
                              styles['right'],
                              baseStyles['base-t-112'],
                              breakdownRow === 'TOTAL' &&
                                styles['gray-background'],
                              (breakdownRow === 'SUBTOTAL' ||
                                breakdownRow === 'TOTAL') &&
                                styles['bold']
                            )}
                          >
                            {getFormattedAmount(
                              summaryRow.paymentAmounts.commission
                            )}
                          </td>
                        );
                      case 'AMOUNT_DUE_BY_AGENT':
                        return (
                          <td
                            className={clsx(
                              styles['right'],
                              baseStyles['base-t-112'],
                              breakdownRow === 'TOTAL' &&
                                styles['gray-background'],
                              breakdownRow === 'TOTAL' &&
                                styles['outlined-cell'],
                              (breakdownRow === 'SUBTOTAL' ||
                                breakdownRow === 'TOTAL') &&
                                styles['bold']
                            )}
                          >
                            {getFormattedAmount(
                              summaryRow.paymentAmounts.dueByAgent
                            )}
                          </td>
                        );
                      case 'AMOUNT_DUE_BY_SUPPLIER':
                        return (
                          <td
                            className={clsx(
                              styles['right'],
                              baseStyles['base-t-112'],
                              breakdownRow === 'TOTAL' &&
                                styles['gray-background'],
                              breakdownRow === 'TOTAL' &&
                                styles['outlined-cell'],
                              (breakdownRow === 'SUBTOTAL' ||
                                breakdownRow === 'TOTAL') &&
                                styles['bold']
                            )}
                          >
                            {getFormattedAmount(
                              summaryRow.paymentAmounts.dueBySupplier
                            )}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
