import clsx from 'clsx';
import { CSSProperties } from 'react';

import styles from './Radio.module.css';

type RadioProps = {
  name?: string;
  label: string;
  value?: string;
  checked?: boolean;
  onChange: (
    arg0: React.ChangeEvent<HTMLInputElement>,
    arg1: { value: string }
  ) => any;
  size?: 'sm' | 'md';
  disabled?: boolean;
  style?: CSSProperties;
};

export const Radio = ({
  name,
  label,
  value,
  checked = false,
  onChange,
  size = 'md',
  disabled,
  style,
}: RadioProps) => {
  return (
    <label className={clsx(styles['c-radio'], styles[size])}>
      <input
        type="radio"
        name={name}
        value={value || ''}
        checked={checked}
        onChange={(e) => {
          onChange(e, {
            value: e.target.dataset.value as string,
          });
        }}
        disabled={disabled}
        style={style}
      />
      <i></i>
      <p className={disabled ? styles['is-disabled'] : ''}>{label}</p>
    </label>
  );
};
