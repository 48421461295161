import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';

import deleteIcon from 'client/images/ic_delete.svg';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

import { InquiryAttachment } from './InquiryAttachmentsEditor';

const AttachmentItemType = 'attachment';
type DragItem = {
  index: number;
  attachmentUrl: string;
  attachmentFilename: string;
  type: string;
};
type Props = {
  attachment: InquiryAttachment;
  index: number;
  onRemove: () => void;
  onMoveImage: (dragIndex: number, hoverIndex: number) => void;
};
export const DraggableInquiryAttachment = ({
  attachment,
  index,
  onRemove,
  onMoveImage,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop({
    accept: AttachmentItemType,

    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      onMoveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: AttachmentItemType,
    item: {
      type: AttachmentItemType,
      attachmentUrl: attachment.url,
      attachmentFilename: attachment.filename,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;
  const previewUrl = attachment.url;

  const isPDF = attachment.url.toLowerCase().endsWith('.pdf');

  return (
    <div
      ref={ref}
      className={styles['page-productsRegist__picture__item__pic']}
      style={{
        opacity,
      }}
    >
      {isPDF ? (
        <div className={styles['attachment-filename']}>
          {attachment.filename}
        </div>
      ) : (
        <img src={previewUrl} />
      )}
      <a className={styles['delete']} onClick={onRemove}>
        <img src={deleteIcon} alt={attachment.filename} />
      </a>
    </div>
  );
};
