import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchInvoicePdf } from 'client/actions/invoices';
import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper } from 'client/components/Form';
import { Icon } from 'client/components/Icons/Icon';
import { ReduxState } from 'client/reducers';
import downloadIcon from 'client/images/ic_file_download.svg';

type Props = {
  invoiceIds: string[];
};

export const DownloadInvoiceButton = ({ invoiceIds }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pdfLoading = useSelector(
    (state: ReduxState) => state.invoices.pdfLoading
  );
  const summaryRows = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryRows
  );
  const summaryColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryColumns
  );
  const reservationColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleReservationColumns
  );

  const download = () => {
    invoiceIds.forEach((invoiceId) => {
      dispatch(
        fetchInvoicePdf(
          invoiceId,
          summaryColumns,
          summaryRows,
          reservationColumns
        )
      );
    });
  };

  return (
    <>
      <Button
        loading={pdfLoading}
        style="gray"
        size="middle"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {t('Download PDF')}
      </Button>
      <Modal
        insertRoot
        title={t('Download Invoices')}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Content>
          <FieldWrapper label={t('Files:')}>
            <ul>
              {invoiceIds.map((invoiceId) => (
                <li key={invoiceId}>
                  <Icon src={downloadIcon} />
                  {invoiceId}
                </li>
              ))}
            </ul>
          </FieldWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Button.Submit
            onClick={() => {
              download();
              setShowModal(false);
            }}
          >
            {t('Download all files')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    </>
  );
};
