// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import type Moment from 'moment-timezone';

import {
  fetchReservationByID,
  standbyReservation,
} from 'client/actions/reservations';
import { getStartTime } from 'client/libraries/util/coreutil';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { Reservation } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';
import type { TranslateFuncType } from 'client/components/Translate';

type Props = {
  t: TranslateFuncType,
  reservationID: string,
  reservation?: Reservation,
  onStandbyCompleted?: (Reservation) => void,
  loading: boolean,
  locale: string,
  loading: boolean,
  monthYearFormat: string,
  standbyReservation: (string, string, string, string) => Promise<Object>,
  fetchReservationByID: (string) => Promise<Object>,
};

type State = {
  standbyTime: Moment,
  standbyDate: Moment,
  standbyDateFocused: boolean,
  showModal: boolean,
  supplier_notes: string,
  supplier_reference: string,
};

class standbyReservationButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const after24Hours = moment().add(24, 'hours');
    this.state = {
      standbyTime: moment(after24Hours),
      standbyDate: moment(after24Hours),
      standbyDateFocused: false,
      showModal: false,
      supplier_notes: '',
      supplier_reference:
        (this.props.reservation && this.props.reservation.supplier_reference) ||
        '',
    };
  }

  getStandbyDateTime = (): Moment => {
    const m = moment(this.state.standbyDate);
    m.set('hours', this.state.standbyTime.hours());
    m.set('minutes', this.state.standbyTime.minutes());

    return m;
  };

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }
  };

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  handleSupplierReferenceChange = (e) => {
    this.setState({ supplier_reference: e.target.value });
  };

  render() {
    const { reservation, reservationID, loading, locale, t } = this.props;
    const startTime = reservation && getStartTime(reservation).locale(locale);

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];

    return (
      <Modal
        trigger={
          <Button
            icon
            basic
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          >
            <Icon name="hourglass half" color="orange" />
            {t('Standby')}
          </Button>
        }
        style={{
          marginTop: 0,
        }}
        open={this.state.showModal}
        onOpen={this.fetchReservation}
        onClose={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <Header
          icon="hourglass half"
          content={t('Put reservation on standby')}
        />
        <Modal.Content>
          {loading ? (
            <ModalLoader />
          ) : (
            <Form>
              <div className="field">
                <label>{t('Product')}</label>
                {productName}
              </div>
              <div className="field">
                <label>{t('Start time')}</label>
                {moment(startTime).format('lll')}
              </div>
              <div className="field">
                <label>{t('Total participants')}</label>
                {guests.length}
              </div>
              <div className="field">
                <label>{t('Standby until:')}</label>
              </div>
              <Form.Field>
                <SingleDatePicker
                  id="somethingunique"
                  noBorder
                  date={this.state.standbyDate.locale(locale)}
                  displayFormat="ll"
                  onDateChange={(standbyDate) =>
                    this.setState({
                      standbyDate,
                    })
                  }
                  numberOfMonths={1}
                  openDirection={OPEN_UP}
                  renderMonthText={(month) =>
                    moment(month).format(this.props.monthYearFormat)
                  }
                  focused={this.state.standbyDateFocused}
                  onFocusChange={({ focused: standbyDateFocused }) => {
                    this.setState({ standbyDateFocused });
                  }}
                />
                {'  @  '}
                <TimePicker
                  defaultOpenValue={undefined}
                  value={this.state.standbyTime.locale(locale)}
                  onChange={(standbyTime) => this.setState({ standbyTime })}
                />
              </Form.Field>
            </Form>
          )}
          <Divider section />
          <ReservationActorInputForm />
          <Form>
            <Form.Input
              control={TextArea}
              label={t('Replies')}
              value={this.state.supplier_notes}
              onChange={this.handleSupplierNotesChange}
            />
            <Form.Input
              label={t('Confirmation Number')}
              value={this.state.supplier_reference}
              onChange={this.handleSupplierReferenceChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="orange"
            inverted
            onClick={() => {
              this.props
                .standbyReservation(
                  reservationID,
                  this.getStandbyDateTime().format(),
                  this.state.supplier_notes,
                  this.state.supplier_reference
                )
                .then(
                  (res) =>
                    this.props.onStandbyCompleted &&
                    this.props.onStandbyCompleted(res)
                );
              this.setState({
                showModal: false,
              });
            }}
          >
            <Icon name="hourglass half" />
            {t('Standby')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps) => ({
  locale: state.language.selected.iso,
  loading: state.reservations.loading,
  monthYearFormat: state.language.selected.monthYearFormat,
  reservation: state.reservations.byID[ownProps.reservationID],
});

const mapDispatchToProps = (dispatch) => ({
  standbyReservation: (id, untilTime, supplier_notes, supplier_reference) =>
    dispatch(
      standbyReservation(id, untilTime, supplier_notes, supplier_reference)
    ),
  fetchReservationByID: (id) => dispatch(fetchReservationByID(id)),
});

export const StandbyReservationButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(standbyReservationButton);
