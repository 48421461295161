import * as React from 'react';

import baseStyles from 'client/base.module.css';

interface FileUpload {
  url: string;
  name: string;
}

interface Props {
  fileUrlString: string;
}

export const FileDownloadList = ({ fileUrlString }: Props) => {
  const uploadedFiles: FileUpload[] = React.useMemo(
    () => (fileUrlString ? JSON.parse(fileUrlString) : []),
    [fileUrlString]
  );

  return (
    <>
      {uploadedFiles.map((uploadedFile, index) => {
        return (
          <span key={index}>
            {index !== 0 && ', '}
            <a
              className={baseStyles['link']}
              href={uploadedFile.url}
              download
              target="_blank"
              rel="noreferrer"
            >
              {uploadedFile.name}
            </a>
          </span>
        );
      })}
    </>
  );
};
