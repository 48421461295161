import * as React from 'react';
import clsx from 'clsx';

import styles from './Tabs.module.css';

export type TabPane = {
  header: string;
  content: React.ComponentType<any>;
};
interface Props {
  panes: TabPane[];
}
export const Tabs = ({ panes }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <div className={styles['c-tab']}>
      <ul className={styles['c-tab__header']}>
        {panes.map((pane, idx) => {
          return (
            <li
              key={pane.header}
              className={clsx(
                styles['c-tab__header__item'],
                idx === activeIndex && styles['is-active']
              )}
              onClick={() => setActiveIndex(idx)}
            >
              <h3 className={styles['c-tab__header__item__text']}>
                {pane.header}
              </h3>
            </li>
          );
        })}
      </ul>
      {panes.map((pane, idx) => {
        const Content = pane.content;
        return (
          <ul key={pane.header} className={styles['c-tab__body']}>
            <li
              className={clsx(
                styles['c-tab__body__item'],
                idx === activeIndex && styles['is-active']
              )}
            >
              <Content />
            </li>
          </ul>
        );
      })}
    </div>
  );
};
