import * as React from 'react';
import clsx from 'clsx';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type CoreProps = {
  options: {
    text: string;
    value: string;
    addition?: string;
  }[];
  selectedValues?: string[];
  onChange: (arg0: { value: string[] }) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  search?: boolean;
};

const Core = ({
  options,
  selectedValues,
  onChange,
  placeholder,
  search,
}: CoreProps) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const ref = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOption &&
        target instanceof Node &&
        !ref?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOption]);
  const filteredOptions = options.filter((o) => {
    if (o.text === '') {
      return false;
    }

    if (search && searchText) {
      return replaceFullWidthCharactersWithHalfWidth(
        o.text.toLowerCase()
      ).includes(searchText.toLowerCase());
    }

    return true;
  });
  return (
    <>
      <div className={clsx(componentStyles['c-form-multiple'])} ref={ref}>
        <label
          className={clsx(
            componentStyles['select'],
            showOption
              ? filteredOptions.length > 0
                ? componentStyles['is-active']
                : ''
              : ''
          )}
          onClick={() => {
            setShowOption(true);
          }}
        >
          <ul className={clsx(componentStyles['selected'])}>
            {selectedValues?.map((value, idx) => {
              const option = options.find((option) => {
                return option.value === value;
              });
              return (
                <li
                  key={idx}
                  value={value}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <a
                    data-value={value}
                    className={clsx(componentStyles['delete'])}
                    onClick={(e) => {
                      onChange({
                        value: selectedValues.filter(
                          (value) => value !== e.currentTarget.dataset.value
                        ),
                      });
                    }}
                  ></a>
                  <p>{option?.text}</p>
                </li>
              );
            })}
          </ul>
          {(((selectedValues ?? []).length === 0 && placeholder) || search) && (
            <input
              ref={inputRef}
              type="text"
              placeholder={placeholder}
              value={searchText}
              onChange={(e) => {
                if (search) {
                  setSearchText(e.target.value);
                }
              }}
            />
          )}
        </label>
        <ul className={clsx(componentStyles['option'])}>
          {filteredOptions
            .filter((o) => {
              const value = selectedValues?.find((value) => {
                return o.value === value;
              });
              return value === undefined;
            })
            .map((option, idx) => {
              return (
                <li
                  key={idx}
                  data-value={option.value}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (inputRef.current) {
                      inputRef.current.focus();
                    }

                    setSearchText('');
                    onChange({
                      value: [
                        ...(selectedValues ?? []),
                        e.currentTarget.dataset.value ?? '',
                      ],
                    });
                  }}
                >
                  {option.text} {option.addition ? `${option.addition}` : ''}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

type Props = {
  error?: string;
  label?: string;
  options: {
    text: string;
    value: string;
    addition?: string;
  }[];
  selectedValues?: string[];
  onChange: (arg0: { value: string[] }) => void;
  placeholder?: string;
  withoutFrame?: boolean;
  disabled?: boolean;
  required?: boolean;
  search?: boolean;
  style?: Record<string, any>;
};
export const MultiSelect = ({
  error,
  label,
  options,
  selectedValues,
  onChange,
  placeholder,
  withoutFrame,
  search,
  style,
}: Props) => {
  return (
    <>
      {withoutFrame ? (
        <Core
          options={options}
          selectedValues={selectedValues}
          onChange={onChange}
          placeholder={placeholder}
          search={search}
        />
      ) : (
        <div className={baseStyles['base-form-box']} style={style}>
          {label && (
            <div className={baseStyles['base-form-box__header']}>{label}</div>
          )}
          <div className={baseStyles['base-form-box__body']}>
            <Core
              options={options}
              selectedValues={selectedValues}
              onChange={onChange}
              placeholder={placeholder}
              search={search}
            />
            {error && (
              <p className={baseStyles['base-form-box__err']}>{error}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
