import { combineReducers } from 'redux';

import {
  FETCH_SURVEY_SUBMISSIONS_REQUEST,
  FETCH_SURVEY_SUBMISSIONS_SUCCESS,
  FETCH_SURVEY_SUBMISSIONS_FAILURE,
  FETCH_SURVEY_SUBMISSION_REQUEST,
  FETCH_SURVEY_SUBMISSION_SUCCESS,
  FETCH_SURVEY_SUBMISSION_FAILURE,
  FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { SurveySubmission } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSIONS_REQUEST:
    case FETCH_SURVEY_SUBMISSION_REQUEST:
      return true;

    case FETCH_SURVEY_SUBMISSIONS_SUCCESS:
    case FETCH_SURVEY_SUBMISSIONS_FAILURE:
    case FETCH_SURVEY_SUBMISSION_SUCCESS:
    case FETCH_SURVEY_SUBMISSION_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSIONS_FAILURE:
    case FETCH_SURVEY_SUBMISSION_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const summaries = (
  state: SurveySubmission[] = [],
  action: any
): SurveySubmission[] => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSIONS_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

const survey = (state: SurveySubmission | null = null, action: any) => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSION_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST:
      return true;

    case FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS:
    case FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

export const surveySubmissions = combineReducers({
  loading,
  error,
  summaries,
  survey,
  csvLoading,
});
