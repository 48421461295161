import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

interface Props {
  onClose: () => void;
  title: string;
  message: string;
  onSave: (memo: string) => void;
}

export const CalendarEditModal = ({
  onClose,
  title,
  message,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const [memo, setMemo] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  return (
    <Modal title={title} onClose={onClose} insertRoot open={true}>
      <Modal.Content>
        <div>{message}</div>
        <Box mt={4}>
          <TextArea
            height={100}
            label={t('Memo')}
            value={memo}
            onChange={(e, { value }) => setMemo(value)}
          />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          onClick={async () => {
            setLoading(true);
            await onSave(memo);
            setLoading(false);

            onClose();
          }}
          loading={loading}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
