import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import * as Swagger from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  Select,
  Button,
  Input,
  TextArea,
  FieldWrapper,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';

import { InstantWinTierFormValue } from './formValues';
import { getTierTypeOptions } from './getTierTypeOptions';
import { EditInstantWinTierETicketForm } from './EditInstantWinTierETicketForm';
import { EditInstantWinTierPromoCodeForm } from './EditInstantWinTierPromoCodeForm';
import { EditInstantWinTierGiftForm } from './EditInstantWinTierGiftForm';
import { EditInstantWinTierDownloadForm } from './EditInstantWinTierDownloadForm';

interface Props {
  existingInstantWinTier?: InstantWinTierFormValue;
  open: boolean;
  onClose: () => void;
  onSave: (args: InstantWinTierFormValue) => void;
  distributionType: Swagger.InstantWinDistributionType;
}

export const EditInstantWinTierModal = ({
  existingInstantWinTier,
  open,
  onClose,
  onSave,
  distributionType,
}: Props) => {
  const { t } = useTranslation();

  const instantWinTierTypes: {
    value: InstantWinTierFormValue['type'];
    component?: React.ReactNode;
  }[] = [
    {
      value: 'INSTANT_WIN_TIER_TYPE_E_TICKET',
      component: <EditInstantWinTierETicketForm />,
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_DOWNLOAD',
      component: <EditInstantWinTierDownloadForm />,
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_PROMO_CODE',
      component: <EditInstantWinTierPromoCodeForm />,
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_GIFT',
      component: <EditInstantWinTierGiftForm />,
    },
  ];

  const getTierTypeComponent = (
    triggerType: InstantWinTierFormValue['type']
  ) => {
    return instantWinTierTypes.find((trigger) => trigger.value === triggerType)
      ?.component;
  };

  return (
    <Modal
      title={t('Edit Instant Win Tier')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form<InstantWinTierFormValue>
        onSubmit={(values: InstantWinTierFormValue) => {
          onSave(values);
          onClose();
        }}
        initialValues={
          existingInstantWinTier ?? {
            key: uuidv4(),
            title: '',
            description: '',
            body: '',
            thumbnailUrl: '',
            type: 'INSTANT_WIN_TIER_TYPE_E_TICKET',
            stock: 0,
            probability: 0,
            productIdForETicket: '',
          }
        }
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Field name="title">
                {({ input }) => (
                  <Input
                    label={t('Title')}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                  />
                )}
              </Field>

              <Box mt={2}>
                <Field name="description">
                  {({ input }) => (
                    <TextArea
                      label={t('Description')}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>
              <Box mt={2}>
                <Field name="thumbnailUrl">
                  {({ input, meta: { touched, error } }) => (
                    <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue.length > 0
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                        error={touched && error}
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Box>

              {distributionType === 'INSTANT_WIN_DISTRIBUTION_TYPE_QUANTITY' ? (
                <Box mt={2}>
                  <Field name="stock">
                    {({ input }) => (
                      <Input
                        label={t('Stock')}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Box>
              ) : (
                <Box mt={2}>
                  <Field name="probability">
                    {({ input }) => (
                      <Input
                        label={t('Probability')}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Box>
              )}

              <Box mt={2}>
                <Field name="type">
                  {({ input }) => (
                    <Select
                      label={t('Prize Type')}
                      options={getTierTypeOptions(t)}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>

              {getTierTypeComponent(values.type)}
            </Modal.Content>
            <Modal.Actions>
              <Button size="middle" style="blue" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
