import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import { getArrayMutators } from 'client/libraries/util/form';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Loading } from 'client/pages/Loading';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import type { LanguageISO } from 'client/libraries/i18n';
import {
  Button,
  Select,
  Input,
  Radio,
  FieldWrapper,
} from 'client/components/Form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import { fetchProducts } from 'client/actions/products';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';
import copyIcon from 'client/images/ic_copy.svg';

import { getFloatingButtonEmbedCode } from './util';
import type { ButtonStyle, DisplayPosition } from './util';
import styles from './BookingWidgetWebSiteIntegration.module.css';
import {
  convertSettingsFormValuesToOrganizationPatch,
  getSettingsInitialValues,
} from './FormValues';
import type { FloatingButtonSettingsFormValues } from './FormValues';
import 'rc-color-picker/assets/index.css';

const focusOnError: any = createDecorator();

export const FloatingButtonTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollTo = React.useContext(ScrollToContext);
  const productOptions = useSelector(productOptionsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const bookingWidgetApiKey =
    activeUserOrganization?.booking_widget_api_key ?? '';
  const baseUrl = getBookingWebsiteUrl(activeUserOrganization);

  const existFloatingButtonSettings = Boolean(
    activeUserOrganization?.floating_button_settings
  );

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  const [displayPosition, setDisplayPosition] =
    React.useState<DisplayPosition>('LOWER_LEFT');
  const [buttonText, setButtonText] = React.useState<string>(t('Book Now'));
  const [buttonTextColor, setButtonTextColor] =
    React.useState<string>('#ffffff');
  const [buttonColor, setButtonColor] = React.useState<string>('#008ec9');
  const [buttonStyle, setButtonStyle] = React.useState<ButtonStyle>(
    'ROUNDED_CORNERS_LARGE'
  );
  const [buttonWidth, setButtonWidth] = React.useState<string>('160');
  const [buttonHeight, setButtonHeight] = React.useState<string>('40');
  const [language, setLanguage] = React.useState<LanguageISO>('ja');

  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  const languageOptions = (
    (activeUserOrganization && activeUserOrganization.supported_languages) ||
    []
  ).map((language) => ({
    text: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
    key: uppercaseIsoToLowercaseIso[language],
  }));

  const getBorderRadiusValue = React.useMemo(() => {
    if (buttonStyle === 'ROUND') {
      return '50%';
    } else if (buttonStyle === 'ROUNDED_CORNERS_LARGE') {
      return '24px';
    } else if (buttonStyle === 'ROUNDED_CORNERS_SMALL') {
      return '12px';
    }
    return '';
  }, [buttonStyle]);

  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
    }
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);

  React.useEffect(() => {
    if (!(window as any).openCalendarByModal) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-floating-button/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).bookingWidgetApiKey) {
      (window as any).bookingWidgetApiKey = bookingWidgetApiKey;
    }
  }, [bookingWidgetApiKey]);
  React.useEffect(() => {
    if (!(window as any).lang) {
      (window as any).lang = language;
    }
  }, [language]);

  const embedCode = getFloatingButtonEmbedCode(
    bookingWidgetApiKey,
    buttonText,
    buttonColor,
    displayPosition,
    buttonTextColor,
    buttonStyle,
    buttonWidth,
    buttonHeight,
    language
  );

  const initialValues = getSettingsInitialValues(activeUserOrganization);

  return (
    <>
      <p style={{ fontWeight: 'bold' }}>{t('What is floating button?')}</p>
      <p>
        {t(
          'You can add a button to your website that is displayed at the bottom right/left corner even when you scroll the page.'
        )}
      </p>
      <a
        style={{
          color: '#008ec9',
          cursor: 'pointer',
        }}
        href="/helps/website/floating-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('More about floating button')}
      </a>
      <Form
        onSubmit={async (values: FloatingButtonSettingsFormValues) => {
          const orgId = activeUserOrganization?.id || '';

          try {
            await dispatch(
              updateOrganization(
                orgId,
                'SUPPLIER',
                convertSettingsFormValuesToOrganizationPatch(values)
              )
            );
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values }) => {
          const navigateToPageUrl = React.useMemo(() => {
            if (!baseUrl) {
              return '';
            }
            const url = new URL(baseUrl ?? '');
            if (values.onClickBehavior === 'NAVIGATE_TO_PAGE') {
              if (values.onClickTarget === 'TOP_PAGE') {
                return url.origin;
              }
              return `${url.origin}/products/${
                values.floatingButtonUrlMap?.[0]?.targetProduct || ''
              }/?lng=${language}`;
            } else {
              if (values.onClickTarget === 'TOP_PAGE') {
                return `${url.origin}/embed/${bookingWidgetApiKey}/multiProductCalendar?lng=${language}&isModal=true`;
              }
              return `${url.origin}/embed/${bookingWidgetApiKey}/products/${
                values.floatingButtonUrlMap?.[0]?.targetProduct || ''
              }/sideBarCalendarWithPrices?lng=${language}&isModal=true`;
            }
          }, [
            values.onClickTarget,
            values.onClickBehavior,
            values.floatingButtonUrlMap,
            language,
            baseUrl,
          ]);
          const buttonDisable = React.useMemo(() => {
            return (
              values.onClickTarget === 'PRODUCT_PAGE' &&
              values.floatingButtonUrlMap?.length === 0
            );
          }, [values.onClickTarget, values.floatingButtonUrlMap]);
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={baseStyles['base-main__body__box']}
                style={{ border: 'none' }}
              >
                <div
                  className={baseStyles['base-main__body__box__body']}
                  style={{ padding: '0' }}
                >
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tbody>
                          <tr>
                            <th>{t('Behaviour')}</th>
                            <td>
                              <div className={styles['form-title']}>
                                {t('Click Action')}
                              </div>
                              <Field name="onClickBehavior">
                                {({ input }) => (
                                  <>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mb={3}
                                    >
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t('Go to booking website')}
                                          checked={
                                            input.value === 'NAVIGATE_TO_PAGE'
                                          }
                                          onChange={() =>
                                            input.onChange('NAVIGATE_TO_PAGE')
                                          }
                                        />
                                      </div>
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t(
                                            'Open product availability calendar'
                                          )}
                                          checked={
                                            input.value === 'SHOW_CALENDAR'
                                          }
                                          onChange={() =>
                                            input.onChange('SHOW_CALENDAR')
                                          }
                                        />
                                      </div>
                                      <Tooltip
                                        text={t(
                                          'Similar to "Book Now" button for embed calendar, guest can see the availability calendar without redirected to booking website.'
                                        )}
                                      />
                                    </Box>
                                  </>
                                )}
                              </Field>
                              <Box mt={2}>
                                <div className={styles['form-title']}>
                                  {t('Targets')}
                                </div>
                                <p>
                                  {t(
                                    'Select a page to go to or type of calendar when the button is clicked.'
                                  )}
                                </p>
                                <Field name="onClickTarget">
                                  {({ input }) => {
                                    const formState = useFormState();
                                    const isNavigateToPage = Boolean(
                                      formState.values?.onClickBehavior ===
                                        'NAVIGATE_TO_PAGE'
                                    );
                                    return (
                                      <>
                                        <Box display="flex" width="fit-content">
                                          <div className={styles['form-body']}>
                                            <Radio
                                              label={
                                                isNavigateToPage
                                                  ? t('Top Page')
                                                  : t('All Products Calendar')
                                              }
                                              checked={
                                                input.value === 'TOP_PAGE'
                                              }
                                              onChange={() =>
                                                input.onChange('TOP_PAGE')
                                              }
                                            />
                                          </div>
                                          <div className={styles['form-body']}>
                                            <Radio
                                              label={
                                                isNavigateToPage
                                                  ? t('Product Page')
                                                  : t('Single Product Calendar')
                                              }
                                              checked={
                                                input.value === 'PRODUCT_PAGE'
                                              }
                                              onChange={() =>
                                                input.onChange('PRODUCT_PAGE')
                                              }
                                            />
                                          </div>
                                        </Box>
                                      </>
                                    );
                                  }}
                                </Field>
                              </Box>
                              <Box mt={3}>
                                <FieldArray name="floatingButtonUrlMap">
                                  {({ fields: urlMap }) => {
                                    const formState = useFormState();
                                    if (
                                      formState.values?.onClickTarget !==
                                      'PRODUCT_PAGE'
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <>
                                        <FieldWrapper label={t('URL Mapping')}>
                                          <Box mb={2} display="block">
                                            <p>
                                              {t(
                                                "Please enter URL to show the floating button and select a product for the URL. When the button is clicked on the registered URL, guest will be redirected to the target product's page or product's availability calendar will be displayed. Floating button will not be displayed on non-registered URL."
                                              )}
                                            </p>
                                            <Box mt={2}>
                                              <div
                                                className={
                                                  styles['c-table-list']
                                                }
                                              >
                                                <table
                                                  style={{
                                                    tableLayout: 'auto',
                                                  }}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <th
                                                        style={{
                                                          width: 'auto',
                                                        }}
                                                      >
                                                        {t('Source URL')}
                                                      </th>
                                                      <th
                                                        style={{
                                                          width: 'auto',
                                                        }}
                                                      >
                                                        {t('Target Product')}
                                                      </th>
                                                      <th
                                                        style={{
                                                          width: 'auto',
                                                        }}
                                                      ></th>
                                                    </tr>
                                                    {urlMap.map((url, idx) => (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            <Field
                                                              key={`origin.${idx}`}
                                                              name={`${url}.origin`}
                                                            >
                                                              {({ input }) => (
                                                                <Box
                                                                  display="flex"
                                                                  alignItems="center"
                                                                  mt={2}
                                                                  mr={3}
                                                                  mb={2}
                                                                >
                                                                  <Input
                                                                    {...input}
                                                                  />
                                                                </Box>
                                                              )}
                                                            </Field>
                                                          </td>
                                                          <td>
                                                            <Field
                                                              key={`targetProduct.${idx}`}
                                                              name={`${url}.targetProduct`}
                                                            >
                                                              {({ input }) => (
                                                                <Box
                                                                  display="flex"
                                                                  alignItems="center"
                                                                  mt={2}
                                                                  mr={3}
                                                                  mb={2}
                                                                >
                                                                  <Select
                                                                    search
                                                                    maxWidth={
                                                                      400
                                                                    }
                                                                    value={
                                                                      input.value
                                                                    }
                                                                    options={
                                                                      productOptions
                                                                    }
                                                                    onChange={(
                                                                      e,
                                                                      { value }
                                                                    ) => {
                                                                      input.onChange(
                                                                        value
                                                                      );
                                                                    }}
                                                                  />
                                                                </Box>
                                                              )}
                                                            </Field>
                                                          </td>
                                                          <td>
                                                            <Box ml={2}>
                                                              <DeleteIcon
                                                                onClick={() =>
                                                                  urlMap.remove(
                                                                    idx
                                                                  )
                                                                }
                                                              />
                                                            </Box>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    ))}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </Box>
                                            <Box mt={2}>
                                              <Button
                                                type="button"
                                                style="green"
                                                size="small"
                                                onClick={() =>
                                                  (urlMap as any).insertAt(
                                                    0,
                                                    ''
                                                  )
                                                }
                                              >
                                                {t('Add')}
                                              </Button>
                                            </Box>
                                          </Box>
                                        </FieldWrapper>
                                      </>
                                    );
                                  }}
                                </FieldArray>
                              </Box>
                              <FieldArray name="excludeUrl">
                                {({ fields: urls }) => {
                                  const formState = useFormState();
                                  if (
                                    formState.values?.onClickTarget !==
                                    'TOP_PAGE'
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <>
                                      <Box mt={2}>
                                        <div className={styles['form-title']}>
                                          {t('Excluded URL')}
                                        </div>
                                        <p>
                                          {t(
                                            'Please enter URL that should not show the button.'
                                          )}
                                        </p>
                                        {urls.length === 0 && (
                                          <AddIcon
                                            onClick={() =>
                                              (urls as any).insertAt(0, '')
                                            }
                                          />
                                        )}
                                        {urls.map((url, idx) => (
                                          <Field key={idx} name={url}>
                                            {({ input }) => (
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                mt={2}
                                                mr={3}
                                                mb={2}
                                              >
                                                <Input {...input} />
                                                <Box ml={4}>
                                                  <AddIcon
                                                    onClick={() =>
                                                      (urls as any).insertAt(
                                                        0,
                                                        ''
                                                      )
                                                    }
                                                  />
                                                </Box>
                                                <Box ml={2}>
                                                  <DeleteIcon
                                                    onClick={() =>
                                                      urls.remove(idx)
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            )}
                                          </Field>
                                        ))}
                                      </Box>
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </td>
                          </tr>
                          <tr>
                            <th>{t('Display')}</th>
                            <td>
                              <div className={styles['form-title']}>
                                {t('Position')}
                              </div>
                              <Box display="flex">
                                <div className={styles['form-body']}>
                                  <Radio
                                    label={t('Left Bottom')}
                                    checked={displayPosition === 'LOWER_LEFT'}
                                    onChange={() =>
                                      setDisplayPosition('LOWER_LEFT')
                                    }
                                  />
                                </div>
                                <div className={styles['form-body']}>
                                  <Radio
                                    label={t('Right Bottom')}
                                    checked={displayPosition === 'LOWER_RIGHT'}
                                    onChange={() =>
                                      setDisplayPosition('LOWER_RIGHT')
                                    }
                                  />
                                </div>
                              </Box>
                              <Box mt={2}>
                                <FieldWrapper label={t('Button Text')}>
                                  <Box display="block">
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                      mr={3}
                                      mb={2}
                                    >
                                      <Input
                                        value={buttonText}
                                        onChange={(e, { value }) =>
                                          setButtonText(value)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </FieldWrapper>
                              </Box>
                              <Box mt={2}>
                                <FieldWrapper label={t('Text Color')}>
                                  <ColorPicker
                                    placement="bottomRight"
                                    color={buttonTextColor}
                                    onChange={({ color }: any) =>
                                      setButtonTextColor(color)
                                    }
                                  />
                                </FieldWrapper>
                              </Box>
                              <Box mt={2}>
                                <FieldWrapper label={t('Button Color')}>
                                  <ColorPicker
                                    placement="bottomRight"
                                    color={buttonColor}
                                    onChange={({ color }: any) =>
                                      setButtonColor(color)
                                    }
                                  />
                                </FieldWrapper>
                              </Box>
                              <Box mt={2}>
                                <div className={styles['form-title']}>
                                  {t('Button Style')}
                                </div>
                                <Box display="flex">
                                  <div className={styles['form-body']}>
                                    <Radio
                                      label={t('Square Corners')}
                                      checked={buttonStyle === 'SQUARE_CORNERS'}
                                      onChange={() => {
                                        setButtonStyle('SQUARE_CORNERS');
                                        setButtonWidth('160');
                                        setButtonHeight('40');
                                      }}
                                    />
                                  </div>
                                  <div className={styles['form-body']}>
                                    <Radio
                                      label={t('Rounded Corners (small)')}
                                      checked={
                                        buttonStyle === 'ROUNDED_CORNERS_SMALL'
                                      }
                                      onChange={() => {
                                        setButtonStyle('ROUNDED_CORNERS_SMALL');
                                        setButtonWidth('160');
                                        setButtonHeight('40');
                                      }}
                                    />
                                  </div>
                                  <div className={styles['form-body']}>
                                    <Radio
                                      label={t('Rounded Corners (large)')}
                                      checked={
                                        buttonStyle === 'ROUNDED_CORNERS_LARGE'
                                      }
                                      onChange={() => {
                                        setButtonStyle('ROUNDED_CORNERS_LARGE');
                                        setButtonWidth('160');
                                        setButtonHeight('40');
                                      }}
                                    />
                                  </div>
                                  <div className={styles['form-body']}>
                                    <Radio
                                      label={t('Round')}
                                      checked={buttonStyle === 'ROUND'}
                                      onChange={() => {
                                        setButtonStyle('ROUND');
                                        setButtonWidth('50');
                                        setButtonHeight('50');
                                      }}
                                    />
                                  </div>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <FieldWrapper label={t('Button Size')}>
                                  <Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                      mb={2}
                                    >
                                      <span style={{ width: '50px' }}>
                                        {t('width')}
                                      </span>
                                      <Input
                                        value={buttonWidth}
                                        onChange={(e, { value }) => {
                                          setButtonWidth(value);
                                          if (buttonStyle === 'ROUND') {
                                            setButtonHeight(value);
                                          }
                                        }}
                                      />
                                      <span style={{ marginLeft: '5px' }}>
                                        {t('px')}
                                      </span>
                                    </Box>
                                    {buttonStyle !== 'ROUND' && (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        mt={2}
                                        mb={2}
                                      >
                                        <span style={{ width: '50px' }}>
                                          {t('height')}
                                        </span>
                                        <Input
                                          value={buttonHeight}
                                          onChange={(e, { value }) =>
                                            setButtonHeight(value)
                                          }
                                        />
                                        <span style={{ marginLeft: '5px' }}>
                                          {t('px')}
                                        </span>
                                      </Box>
                                    )}
                                  </Box>
                                </FieldWrapper>
                              </Box>
                              <Box mt={2}>
                                <Select
                                  label={t('Select language')}
                                  maxWidth={400}
                                  value={language}
                                  options={languageOptions}
                                  onChange={(e, { value }) => {
                                    setLanguage(value as LanguageISO);
                                  }}
                                />
                              </Box>
                            </td>
                          </tr>
                          <tr>
                            <th>{t('Sample, Embed code')}</th>
                            <td>
                              <div className={styles['form-title']}>
                                {t('Button Display')}
                              </div>
                              <p style={{ marginBottom: '4px' }}>
                                {t(
                                  'Button can be added to your website by copy and pasting the HTML code right before </body> tag on each page.'
                                )}
                              </p>
                              {!existFloatingButtonSettings && (
                                <p style={{ color: '#dc3e15' }}>
                                  {t(
                                    'Please confirm behavior and display settings and click "Save Settings".'
                                  )}
                                </p>
                              )}
                              {copySuccess && (
                                <Message success header={t('Copied')} />
                              )}
                              <Box display="flex" alignItems="center">
                                <button
                                  type="button"
                                  disabled={buttonDisable}
                                  style={{
                                    height: `${buttonHeight}px`,
                                    width: `${buttonWidth}px`,
                                    fontWeight: 'bold',
                                    color: `${buttonTextColor}`,
                                    fontSize: '18px',
                                    backgroundColor: `${buttonColor}`,
                                    borderRadius: `${getBorderRadiusValue}`,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (baseUrl) {
                                      if (
                                        values.onClickBehavior ===
                                        'NAVIGATE_TO_PAGE'
                                      ) {
                                        window.open(
                                          `${navigateToPageUrl}`,
                                          '_blank'
                                        );
                                      } else {
                                        if (
                                          values.onClickTarget === 'TOP_PAGE'
                                        ) {
                                          (window as any).openCalendarByModal(
                                            bookingWidgetBaseUrl,
                                            '',
                                            'MULTI'
                                          );
                                        } else {
                                          (window as any).openCalendarByModal(
                                            bookingWidgetBaseUrl,
                                            values.floatingButtonUrlMap?.[0]
                                              ?.targetProduct,
                                            'SINGLE'
                                          );
                                        }
                                      }
                                    }
                                  }}
                                >
                                  {buttonText}
                                </button>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(embedCode);
                                    setCopySuccess(true);
                                  }}
                                >
                                  <a
                                    className={clsx(
                                      baseStyles['base-btn'],
                                      baseStyles['icon'],
                                      baseStyles['narrow']
                                    )}
                                    data-text={t('Copy')}
                                  >
                                    <img src={copyIcon} />
                                  </a>
                                  <p style={{ margin: 0 }}>
                                    {t('Copy HTML code')}
                                  </p>
                                </div>
                                <Tooltip
                                  text={t(
                                    'Copy HTML code for the button and paste it to each page of your website.'
                                  )}
                                />
                              </Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    <Button
                      type="submit"
                      style="blue"
                      size="small"
                      loading={submitting}
                    >
                      {t('Save Settings')}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
      {loading && <Loading />}
    </>
  );
};
