import { useTranslation } from 'react-i18next';

import styles from './FilterDisplayBox.module.css';

interface Props {
  resultsCount: number;
}

export const FilterDisplayBox = ({ resultsCount }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['search-filter-display-box']}>
      <li>
        <b>{t('Total: ')}</b>
        {t('{{count}} records', {
          count: resultsCount,
        })}
      </li>
    </div>
  );
};
