import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useContext, useState, useMemo, useCallback } from 'react';

import {
  defaultProductCurrencySelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import {
  ProductFormValues,
  convertFormValuesToProductPatch,
  getInitialValues,
} from 'client/pages/v3/Product/ProductEdit/ProductEditContents/FormValues';
import { NtaReservationFormMessage } from 'client/components/ESaas/Nta/NtaReservationFormMessage/NtaReservationFormMessage';
import { isNta } from 'client/components/ESaas/Nta/utils';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/v3-base.module.css';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { AvailabilityAllotmentEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/AvailabilityAllotmentEditor';
import { HighlightsEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/HighlightsEditor';
import { TagsEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/TagsEditor';
import { InternalTagsEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/InternalTagsEditor';
import { DetailInfoEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/DetailInfoEditor';
import { ItineraryEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ItineraryEditor';
import { ServicesEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ServicesEditor';
import { ReservationFormEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ReservationFormEditor';
import { CheckinPickupLocationsEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/CheckinPickupLocationsEditor';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { ProductEditorForm } from 'client/pages/v3/Product/ProductEdit/ProductEditorForm';
import { PricesEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/PricesEditor';
import { getValidators } from 'shared/libraries/validate/validator';
import { Badge } from 'client/components/v3/Common/Badge';

export const ProductTranslationEditor = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const { required } = getValidators(t);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;

  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const defaultTimezone = useSelector(defaultProductTimezoneSelector);

  const initialValues = useMemo(
    () =>
      getInitialValues(
        editingProduct,
        defaultCurrency,
        defaultTimezone,
        t,
        true
      ),
    [editingProduct, defaultCurrency, defaultTimezone, t]
  );

  const convertToProductPatch = useCallback(
    (values: ProductFormValues) =>
      convertFormValuesToProductPatch(
        values,
        defaultCurrency,
        isPackage,
        editingProduct,
        t,
        true
      ),
    [defaultCurrency, isPackage, editingProduct, t, false]
  );

  const hasHighlights = (editingProduct?.highlights || []).length > 0;
  const hasDetailInfo =
    (editingProduct?.inclusions ?? []).length > 0 ||
    (editingProduct?.exclusions ?? []).length > 0 ||
    (editingProduct?.requirements ?? []).length > 0 ||
    (editingProduct?.restrictions ?? []).length > 0 ||
    (editingProduct?.what_to_bring ?? []).length > 0 ||
    (editingProduct?.other_notes ?? []).length > 0;
  const hasItinerary = (editingProduct?.itinerary ?? []).length > 0;
  const hasAddOns = (editingProduct?.add_ons ?? []).length > 0;
  const hasTransportation = (editingProduct?.transportations ?? []).length > 0;
  const hasCheckinPickup =
    (editingProduct?.checkin ?? []).length > 0 ||
    (editingProduct?.checkout ?? []).length > 0 ||
    (editingProduct?.pickup ?? []).length > 0 ||
    (editingProduct?.dropoff ?? []).length > 0;
  const hasForm =
    (
      editingProduct?.reservation_form_fields?.filter(
        (formField) =>
          formField.key !== 'given_name' && formField.key !== 'family_name'
      ) ?? []
    ).length > 0;
  const hasProductTags = (editingProduct?.product_tags ?? []).length > 0;
  const hasInternalProductTags =
    (editingProduct?.internal_product_tags ?? []).length > 0;

  const isNtaHotel =
    isNta(activeUserOrganization) &&
    (editingProduct?.html_texts || []).find((htmlText) => {
      return htmlText.key === 'product-type' && htmlText.text === 'hotel';
    });

  // Guard to ensure product is not null
  if (!editingProduct) {
    return <></>;
  }

  return (
    <ProductEditorForm
      id="translationProductForm"
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertToProductPatch}
      subscription={{
        submitError: true,
        submitting: true,
      }}
    >
      {({ submitError }) => (
        <>
          <Snackbar
            text={t('Save Successful')}
            color="success"
            shouldShow={success}
          />
          <Snackbar
            text={t('Save Failed')}
            color="error"
            shouldShow={submitError}
          />

          {/* Basic editor */}
          <section id="basic" className={styles['g-section']}>
            <p className={styles['p-products__ttl']}>
              {t('Basic Information')}
            </p>
            <CollapsibleSection title={t('Products')}>
              <CollapsibleBox title={t('Internal Product Name')}>
                <TranslatedField name="internalProductName" validate={required}>
                  {({ input, meta: { touched, error }, translationInput }) => {
                    return (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                              <Badge
                                size="sm"
                                label={t('Required')}
                                color="warning-contained"
                              />
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              error={Boolean(touched && error)}
                              helperText={error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={clsx(styles['p-list__item'])}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} />
                            </div>
                          </li>
                        )}
                      </ul>
                    );
                  }}
                </TranslatedField>
              </CollapsibleBox>
              <CollapsibleBox title={t('Product Name')}>
                <TranslatedField name="productName" validate={required}>
                  {({ input, meta: { touched, error }, translationInput }) => (
                    <ul className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <p>
                              {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                            </p>
                            <Badge
                              size="sm"
                              label={t('Required')}
                              color="warning-contained"
                            />
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <TextField
                            {...input}
                            error={Boolean(touched && error)}
                            helperText={error}
                          />
                        </div>
                      </li>
                      {translationTargetLanguage && (
                        <li className={clsx(styles['p-list__item'])}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(
                                  uppercaseIsoToLowercaseIso[
                                    translationTargetLanguage
                                  ],
                                  t
                                )}
                              </p>
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField {...translationInput} />
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </TranslatedField>
              </CollapsibleBox>
              <CollapsibleBox title={t('Product Description')}>
                <TranslatedField name="productDescription">
                  {({ input, translationInput }) => (
                    <ul className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <p>
                            {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                          </p>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <TextArea {...input} height={80} showCharacterCount />
                        </div>
                      </li>
                      {translationTargetLanguage && (
                        <li className={clsx(styles['p-list__item'])}>
                          <div className={styles['p-list__item__ttl']}>
                            <p>
                              {getLanguageName(
                                uppercaseIsoToLowercaseIso[
                                  translationTargetLanguage
                                ],
                                t
                              )}
                            </p>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextArea
                              {...translationInput}
                              height={80}
                              showCharacterCount
                            />
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </TranslatedField>
              </CollapsibleBox>
            </CollapsibleSection>

            <CollapsibleSection id="prices" title={t('Prices')}>
              <PricesEditor />
            </CollapsibleSection>
          </section>

          {/* Reservation parameters editor */}
          <section
            id="reservationParameters"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Reservation Parameters')}
            </p>
            <CollapsibleSection title={t('Participation Rules')}>
              <AvailabilityAllotmentEditor />
            </CollapsibleSection>
          </section>

          {/* Details editor */}
          <section
            id="detail"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Detail Information')}
            </p>
            <CollapsibleSection
              title={t('Highlights')}
              subtitle={t('Enter the highlights and summary of the experience')}
              initialOpen={hasHighlights}
            >
              <HighlightsEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="detailedInfoVoucher"
              title={t('Detailed Info (shown on voucher)')}
              subtitle={t(
                'Enter detailed info for the experience (info is shown automatically on reservation confirmation email)'
              )}
              initialOpen={hasDetailInfo}
            >
              <DetailInfoEditor />
            </CollapsibleSection>
            <CollapsibleSection
              id="experienceItinerary"
              title={t('Experience Itinerary')}
              subtitle={t('Explain the flow of the experience')}
              initialOpen={hasItinerary}
            >
              <ItineraryEditor />
            </CollapsibleSection>
            {hasAddOns && (
              <CollapsibleSection
                id="addOns"
                title={t('Add-Ons')}
                subtitle={t(
                  'Describe additional options that can be selected on the booking form'
                )}
                initialOpen={hasAddOns}
              >
                <ServicesEditor
                  name="addOns"
                  getItemLabel={(idx: number) =>
                    t('Add-On #{{itemNumber}}', {
                      itemNumber: idx,
                    })
                  }
                />
              </CollapsibleSection>
            )}
            {hasTransportation && (
              <CollapsibleSection
                id="transportation"
                title={t('Transportation')}
                subtitle={t(
                  'Register if there is pickup (not required for checkin-only)'
                )}
                initialOpen={hasTransportation}
              >
                <ServicesEditor
                  name="transportations"
                  getItemLabel={(idx: number) =>
                    t('Transportation #{{itemNumber}}', {
                      itemNumber: idx,
                    })
                  }
                />
              </CollapsibleSection>
            )}
            <CollapsibleSection
              id="checkinPickupLocations"
              title={t('Checkin/Pickup Locations')}
              subtitle={t(
                'Enter location and time details in advance to make it easy for customers to understand'
              )}
              initialOpen={hasCheckinPickup}
            >
              <CheckinPickupLocationsEditor />
            </CollapsibleSection>
            {!isNtaHotel && (
              <CollapsibleSection
                id="reservationForm"
                title={t('Reservation Form')}
                subtitle={t(
                  'Customize information required from customers at the time of booking'
                )}
                initialOpen={hasForm}
              >
                <ReservationFormEditor />
              </CollapsibleSection>
            )}
            {isNtaHotel && (
              <CollapsibleSection
                id="reservationForm"
                title={t('Reservation Form')}
                initialOpen={hasForm}
              >
                <NtaReservationFormMessage />
              </CollapsibleSection>
            )}
            <CollapsibleSection
              id="productTags"
              title={t('Product Tags')}
              subtitle={t(
                'Use tags for displaying detailed conditions of the experience and help customers narrow down their selection'
              )}
              initialOpen={hasProductTags}
            >
              <TagsEditor />
            </CollapsibleSection>
          </section>

          {/* Internal management editor */}
          <section
            id="internalManagement"
            className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
          >
            <p className={styles['p-products__ttl']}>
              {t('Internal Management')}
            </p>
            <CollapsibleSection
              title={t('Internal Use Product Tags')}
              subtitle={t('Set tags for internal use on product list page')}
              initialOpen={hasInternalProductTags}
            >
              <InternalTagsEditor />
            </CollapsibleSection>
          </section>
        </>
      )}
    </ProductEditorForm>
  );
};
