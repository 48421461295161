import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import { Loading } from 'client/pages/Loading';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import { fetchProductTranslations } from 'client/actions/translations';
import { getProductEditorDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ReduxState } from 'client/reducers';
import { ProductEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/ProductEditor';
import { ProductEditHeader } from 'client/pages/v3/Product/ProductEdit/ProductEditHeader/ProductEditHeader';
import { activeUserSelector } from 'client/reducers/user';
import v3BaseStyles from 'client/v3-base.module.css';
import { PcSidebarMenu } from 'client/pages/v3/Product/ProductEdit/Menu/PcSidebarMenu';
import { Badge } from 'client/components/v3/Common/Badge';
import { getBadgeColorForProductStatus } from 'client/libraries/util/getBadgeColorForProductStatus';
import {
  getProductSalesStatus,
  getSalesStatusText,
} from 'client/libraries/util/getProductSalesStatus';
import { Button } from 'client/components/v3/Common/Button';
import { SpMenu } from 'client/pages/v3/Product/ProductEdit/Menu/SpMenu';
import { fetchProductTags } from 'client/actions/productTags';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { operationAllowed } from 'shared/models/access';
import { Section } from 'client/pages/v3/Product/ProductEdit/Menu/menuType';

import styles from './ProductEdit.module.css';

type Params = {
  id: string;
};

export const ProductEdit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  const { id: editingProductId = '' } = useParams<Params>();

  const loading = useSelector((state: ReduxState) => state.products.loading);

  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId]);

  useEffect(() => {
    dispatch(fetchProductTags());
  }, []);

  useEffect(() => {
    if (editingProductId != null) {
      dispatch(fetchProductTranslations(editingProductId));
    }
  }, [editingProductId]);

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;

  const hasPerParticipantPricing =
    editingProduct?.pricing == null ||
    editingProduct?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });

  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );
  const isPassthrough = Boolean(
    editingProduct?.shared_allotment_references?.passthrough_base_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;

  const productSalesStatus = getProductSalesStatus(editingProduct);

  // Sections & permission to show for sidebar
  // TODO: add more sections & subsections later and define the permissions!
  const sections: Section[] = [
    {
      title: t('Basic Information'),
      id: 'basic',
      hasPermission: true,
      subsections: [
        {
          title: t('Products'),
          id: 'products',
          hasPermission: true,
        },
        {
          title: t('Prices'),
          id: 'prices',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Reservation Parameters'),
      id: 'reservationParameters',
      hasPermission: true,
      subsections: [
        {
          title: t('Participation Rules'),
          id: 'participationRules',
          hasPermission: true,
        },
        {
          title: t('Minimum Participants & Per-Unit Settings'),
          id: 'mininumParticipantsAndPerUnitSettings',
          hasPermission: showMinimumParticipants,
        },
        {
          title: t('Booking Website Selectable Participant Rules'),
          id: 'selectableParticipantRules',
          hasPermission: operationAllowed(
            activeUser,
            'write',
            'productContents'
          ),
        },
        {
          title: t('Booking Period'),
          id: 'bookingPeriod',
          hasPermission: true,
        },
        {
          title: t('Booking Website Payment Types'),
          id: 'paymentType',
          hasPermission: operationAllowed(
            activeUser,
            'write',
            'productContents'
          ),
        },
        {
          title: t('Cancellation Policies'),
          id: 'cancellationPolicies',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Detail Information'),
      id: 'detail',
      hasPermission: true,
      subsections: [
        {
          title: t('Highlights'),
          id: 'highlights',
          hasPermission: true,
        },
        {
          title: t('Detailed Info (shown on voucher)'),
          id: 'detailedInfoVoucher',
          hasPermission: true,
        },
        {
          title: t('Experience Itinerary'),
          id: 'experienceItinerary',
          hasPermission: true,
        },
        {
          title: t('Add-Ons'),
          id: 'addOns',
          hasPermission: true,
        },
        {
          title: t('Transportation'),
          id: 'transportation',
          hasPermission: true,
        },
        {
          title: t('Checkin/Pickup Locations'),
          id: 'checkinPickupLocations',
          hasPermission: true,
        },
        {
          title: t('Reservation Form'),
          id: 'reservationForm',
          hasPermission: true,
        },
        {
          title: t('Product Tags'),
          id: 'productTags',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Internal Management'),
      id: 'internalManagement',
      hasPermission: true,
      subsections: [
        {
          title: t('Transport Routes'),
          id: 'transportRoutes',
          hasPermission: true,
        },
        {
          title: t('Internal Use Product Tags'),
          id: 'internalProductTags',
          hasPermission: true,
        },
        {
          title: t('Email Settings'),
          id: 'emailSettings',
          hasPermission: true,
        },
      ],
    },
  ];

  const [currentSectionId, setCurrentSectionId] = useState('products');

  if (!editingProduct) {
    return <Loading />;
  }

  return (
    <V3Page>
      <Dimmer active={!editingProduct} page={true} inverted>
        <Loader>{t('Loading')}</Loader>
      </Dimmer>

      <EditingProductContext.Provider value={editingProduct ?? null}>
        <ProductEditHeader product={editingProduct} />
        <div
          className={clsx(
            v3BaseStyles['l-main__body'],
            styles['p-productEditor']
          )}
        >
          <div className={v3BaseStyles['l-main__body__flex']}>
            <div className={v3BaseStyles['l-main__body__flex__left']}>
              <SpMenu sections={sections} currentSectionId={currentSectionId} />
              <ProductEditor
                showMinimumParticipants={showMinimumParticipants}
                setCurrentSectionId={setCurrentSectionId}
              />
              {/* TODO: make fixed footer show up upon scroll */}
              <div
                className={clsx(
                  styles['p-products__fixed'],
                  styles['is-active']
                )}
              >
                <div className={styles['p-products__fixed__main']}>
                  <Badge
                    label={getSalesStatusText(productSalesStatus, t)}
                    color={getBadgeColorForProductStatus(productSalesStatus)}
                    size="lg"
                  />
                  <p className={styles['p-products__fixed__main__ttl']}>
                    {getProductEditorDisplayProductName(editingProduct)}
                  </p>
                </div>
                <div className={styles['p-products__fixed__actions']}>
                  <Button
                    text={t('Save')}
                    type="submit"
                    color="primary"
                    size="md"
                    // Associate button with form id
                    form="editProductForm"
                    loading={loading}
                    iconBeforeText={
                      <i className="c-icon-outline-general-save-01"></i>
                    }
                    style={{ minWidth: '100px' }}
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                v3BaseStyles['l-main__body__flex__right'],
                v3BaseStyles['sticky']
              )}
            >
              <PcSidebarMenu
                sections={sections}
                currentSectionId={currentSectionId}
              />
            </div>
          </div>
        </div>
      </EditingProductContext.Provider>
    </V3Page>
  );
};
