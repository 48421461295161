import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_INQUIRY_SMS_STATS_FAILURE,
  FETCH_INQUIRY_SMS_STATS_REQUEST,
  FETCH_INQUIRY_SMS_STATS_SUCCESS,
  FETCH_INQUIRIES_SUCCESS,
  FETCH_INQUIRIES_FAILURE,
  FETCH_INQUIRIES_REQUEST,
  FETCH_INQUIRY_SUCCESS,
  FETCH_INQUIRY_FAILURE,
  FETCH_INQUIRY_REQUEST,
  REPLY_TO_INQUIRY_SUCCESS,
  REPLY_TO_INQUIRY_FAILURE,
  REPLY_TO_INQUIRY_REQUEST,
  UPDATE_INQUIRY_SUCCESS,
  UPDATE_INQUIRY_FAILURE,
  UPDATE_INQUIRY_REQUEST,
  SEND_INQUIRY_BULK_EMAIL_SUCCESS,
  SEND_INQUIRY_BULK_EMAIL_FAILURE,
  SEND_INQUIRY_BULK_EMAIL_REQUEST,
  SEND_INQUIRY_BULK_SMS_SUCCESS,
  SEND_INQUIRY_BULK_SMS_FAILURE,
  SEND_INQUIRY_BULK_SMS_REQUEST,
  SEND_INQUIRY_SMS_SUCCESS,
  SEND_INQUIRY_SMS_FAILURE,
  SEND_INQUIRY_SMS_REQUEST,
  SEND_INQUIRY_EMAIL_SUCCESS,
  SEND_INQUIRY_EMAIL_FAILURE,
  SEND_INQUIRY_EMAIL_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  InquiryBulkEmailRequest,
  InquiryBulkSMSRequest,
  InquirySmsRequest,
  InquiryAttachment,
  InquiryPatch,
  ListInquiriesRequest,
  NewInquiry,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchInquiriesRequest = () => ({
  type: FETCH_INQUIRIES_REQUEST,
});

const fetchInquiriesSuccess = (response: any) => ({
  type: FETCH_INQUIRIES_SUCCESS,
  response,
});

const fetchInquiriesFailure = (error: any) => ({
  type: FETCH_INQUIRIES_FAILURE,
  error,
});

export const fetchInquiries =
  (req: ListInquiriesRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInquiriesRequest());
    axios
      .get('/api/inquiries', {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchInquiriesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchInquiriesFailure('canceled'));
        else dispatch(fetchInquiriesFailure(error));
      });
  };

const fetchInquiryRequest = () => ({
  type: FETCH_INQUIRY_REQUEST,
});

const fetchInquirySuccess = (response: any) => ({
  type: FETCH_INQUIRY_SUCCESS,
  response,
});

const fetchInquiryFailure = (error: any) => ({
  type: FETCH_INQUIRY_FAILURE,
  error,
});

export const fetchInquiry =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInquiryRequest());
    axios
      .get(`/api/inquiries/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchInquirySuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchInquiryFailure('canceled'));
        else dispatch(fetchInquiryFailure(error));
      });
  };

const replyToInquiryRequest = () => ({
  type: REPLY_TO_INQUIRY_REQUEST,
});

const replyToInquirySuccess = (response: any) => ({
  type: REPLY_TO_INQUIRY_SUCCESS,
  response,
});

const replyToInquiryFailure = (error: any) => ({
  type: REPLY_TO_INQUIRY_FAILURE,
  error,
});

export const replyToInquiry =
  (
    id: string,
    body: string,
    cc: string[],
    bcc: string[],
    attachments: InquiryAttachment[]
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(replyToInquiryRequest());
    axios
      .post(
        `/api/inquiries/${id}/reply`,
        {
          body,
          cc,
          bcc,
          attachments,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => {
        dispatch(replyToInquirySuccess(result.data));
      })
      .catch((error) => {
        dispatch(replyToInquiryFailure(error));
      });
  };

const updateInquiryRequest = () => ({
  type: UPDATE_INQUIRY_REQUEST,
});

const updateInquirySuccess = (response: any) => ({
  type: UPDATE_INQUIRY_SUCCESS,
  response,
});

const updateInquiryFailure = (error: any) => ({
  type: UPDATE_INQUIRY_FAILURE,
  error,
});

export const updateInquiry =
  (id: string, patch: InquiryPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateInquiryRequest());
    return axios
      .patch(`/api/inquiries/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(updateInquirySuccess(result.data));
      })
      .catch((error) => {
        dispatch(updateInquiryFailure(error));
      });
  };

const sendInquiryBulkEmailRequest = () => ({
  type: SEND_INQUIRY_BULK_EMAIL_REQUEST,
});

const sendInquiryBulkEmailSuccess = (response: any) => ({
  type: SEND_INQUIRY_BULK_EMAIL_SUCCESS,
  response,
});

const sendInquiryBulkEmailFailure = (error: any) => ({
  type: SEND_INQUIRY_BULK_EMAIL_FAILURE,
  error,
});

export const sendInquiryBulkEmail =
  (params: InquiryBulkEmailRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendInquiryBulkEmailRequest());
    return axios
      .post(`/api/inquiries/send-bulk-email`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(sendInquiryBulkEmailSuccess(result.data));
      })
      .catch((error) => {
        dispatch(sendInquiryBulkEmailFailure(error));
      });
  };

const sendInquiryBulkSMSRequest = () => ({
  type: SEND_INQUIRY_BULK_SMS_REQUEST,
});

const sendInquiryBulkSMSSuccess = (response: any) => ({
  type: SEND_INQUIRY_BULK_SMS_SUCCESS,
  response,
});

const sendInquiryBulkSMSFailure = (error: any) => ({
  type: SEND_INQUIRY_BULK_SMS_FAILURE,
  error,
});

export const sendInquiryBulkSMS =
  (params: InquiryBulkSMSRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendInquiryBulkSMSRequest());
    return axios
      .post(`/api/inquiries/send-bulk-sms`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(sendInquiryBulkSMSSuccess(result.data));
      })
      .catch((error) => {
        dispatch(sendInquiryBulkSMSFailure(error));
      });
  };

const sendInquirySMSRequest = () => ({
  type: SEND_INQUIRY_SMS_REQUEST,
});

const sendInquirySMSSuccess = (response: any) => ({
  type: SEND_INQUIRY_SMS_SUCCESS,
  response,
});

const sendInquirySMSFailure = (error: any) => ({
  type: SEND_INQUIRY_SMS_FAILURE,
  error,
});

export const sendInquirySMS =
  (params: InquirySmsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendInquirySMSRequest());
    return axios
      .post(`/api/inquiries/send-sms`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(sendInquirySMSSuccess(result.data));
      })
      .catch((error) => {
        dispatch(sendInquirySMSFailure(error));
      });
  };

const sendInquiryEmailRequest = () => ({
  type: SEND_INQUIRY_EMAIL_REQUEST,
});

const sendInquiryEmailSuccess = (response: any) => ({
  type: SEND_INQUIRY_EMAIL_SUCCESS,
  response,
});

const sendInquiryEmailFailure = (error: any) => ({
  type: SEND_INQUIRY_EMAIL_FAILURE,
  error,
});

export const sendInquiryEmail =
  (params: NewInquiry) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendInquiryEmailRequest());
    return axios
      .post(`/api/inquiries`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(sendInquiryEmailSuccess(result.data));
      })
      .catch((error) => {
        dispatch(sendInquiryEmailFailure(error));
      });
  };

const fetchInquirySmsStatsRequest = () => ({
  type: FETCH_INQUIRY_SMS_STATS_REQUEST,
});

const fetchInquirySmsStatsSuccess = (response: any) => ({
  type: FETCH_INQUIRY_SMS_STATS_SUCCESS,
  response,
});

const fetchInquirySmsStatsFailure = (error: any) => ({
  type: FETCH_INQUIRY_SMS_STATS_FAILURE,
  error,
});

export const fetchInquirySmsStats =
  (startDateTimeUtc: string, endDateTimeUtc: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInquirySmsStatsRequest());
    return axios
      .get(`/api/inquiries/smsstats`, {
        params: {
          start_date_time_utc: startDateTimeUtc,
          end_date_time_utc: endDateTimeUtc,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchInquirySmsStatsSuccess(result.data));
      })
      .catch((error) => {
        dispatch(fetchInquirySmsStatsFailure(error));
      });
  };
