import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_SELECTED_PRIVATE_MARKETPLACE_INDEX,
} from 'client/constants/ActionTypes';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';

export const selectedPrivateMarketplaceIndexSelector = (state: ReduxState) =>
  state.privateMarketplace.selectedIndex;
export const privateMarketplaceSelector = createSelector(
  activeUserOrganizationSelector,
  selectedPrivateMarketplaceIndexSelector,
  (organization, selectedIndex) => {
    return selectedIndex < (organization?.private_marketplaces?.length ?? 0)
      ? organization?.private_marketplaces?.[selectedIndex]
      : null;
  }
);

// Reducers
const selectedIndex = (state = 0, action: any): number => {
  switch (action.type) {
    case SET_SELECTED_PRIVATE_MARKETPLACE_INDEX:
      return action.newIndex;

    default:
      return state;
  }
};

export const reducer = combineReducers({
  selectedIndex,
});
export const privateMarketplace = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  // Reset data to initial values when impersonating or logging out
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
