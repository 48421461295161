import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { Review } from 'shared/models/swagger';

import styles from './ProductReviewsPane.module.css';

interface Props {
  review: Review;
}
export const ProductReviewBody = ({ review }: Props) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] =
    React.useState<boolean>(false);
  const bodyElemRef = React.useRef<HTMLParagraphElement | null>(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    // Set this state inside useEffect() because refs are not guaranteed to be set on first render but they
    // will be set by the time this useEffect() callback runs.
    setShouldShowSeeMoreButton(
      bodyElemRef.current != null
        ? bodyElemRef.current.scrollHeight > 70
        : false
    );
  });
  return (
    <>
      <p
        className={clsx(
          styles['c-review__list__comment__item__message'],
          !showMore && styles['is-close'],
          'newline'
        )}
        ref={bodyElemRef}
      >
        {review.body}
      </p>
      {shouldShowSeeMoreButton && (
        <div className={styles['c-review__list__comment__item__more']}>
          <a onClick={() => setShowMore(!showMore)}>
            {showMore ? t('Show Less') : t('See More')}
          </a>
        </div>
      )}
    </>
  );
};
