import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_CONTRACTED_ORGANIZATIONS_FAILURE,
  FETCH_CONTRACTED_ORGANIZATIONS_REQUEST,
  FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_BY_ID_FAILURE,
  FETCH_ORGANIZATION_BY_ID_REQUEST,
  FETCH_ORGANIZATION_BY_ID_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  SAVE_PAYMENT_METHOD_SUCCESS,
  ADD_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  REFRESH_ORGANIZATIONS_FAILURE,
  REFRESH_ORGANIZATIONS_SUCCESS,
} from 'client/constants/ActionTypes';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n/i18n';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import type {
  CreateOrganizationResponse,
  ListContractedOrganizationsResponse,
  ListOrganizationsResponse,
  Organization,
  OrganizationSummary,
  SubscriptionKey,
  UpdateOrganizationResponse,
} from 'shared/models/swagger';

export const allOrganizationsSelector = (state: ReduxState) =>
  state.organizations.all;

const agentIdToNameSelector = createSelector(
  allOrganizationsSelector,
  (organizations) =>
    [['AGENT', '']].concat(
      organizations
        .filter((org: Organization) => org.type === 'AGENT')
        .map((org: Organization) => [org.id ?? '', org.name ?? ''])
    )
);
const supplierIdToNameSelector = createSelector(
  allOrganizationsSelector,
  (organizations) =>
    [['SUPPLIER', '']].concat(
      organizations
        .filter((org: Organization) => org.type === 'SUPPLIER')
        .map((org: Organization) => [org.id ?? '', org.name ?? ''])
    )
);
export const idToNameSelector = createSelector(
  agentIdToNameSelector,
  supplierIdToNameSelector,
  (agents: string[][], suppliers: string[][]) => {
    return {
      AGENT: agents.reduce((prev, curr) => {
        (prev as any)[curr[0]] = curr[1];
        return prev;
      }, {}),
      SUPPLIER: suppliers.reduce((prev, curr) => {
        (prev as any)[curr[0]] = curr[1];
        return prev;
      }, {}),
    };
  }
);
export const supplierMapSelector = createSelector(
  allOrganizationsSelector,
  (organizations) => {
    const supplierMap: Record<string, any> = {};
    organizations.forEach((organization: Organization) => {
      if (organization.type === 'SUPPLIER') {
        supplierMap[organization.id ?? ''] = organization.name;
      }
    });
    return supplierMap;
  }
);
export const agentMapSelector = createSelector(
  allOrganizationsSelector,
  (organizations) => {
    const agentMap: Record<string, any> = {};
    organizations.forEach((organization: Organization) => {
      if (organization.type === 'AGENT') {
        agentMap[organization.id ?? ''] = organization.name;
      }
    });
    return agentMap;
  }
);
export const defaultProductLanguageSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return organization?.source_language || 'JA_JP';
  }
);
export const defaultProductLanguageIsoSelector = createSelector(
  activeUserOrganizationSelector,
  (organization: Organization | null) => {
    if (organization?.source_language) {
      return uppercaseIsoToLowercaseIso[organization?.source_language];
    }

    return 'ja';
  }
);
export const defaultProductCurrencySelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return organization?.default_currency || 'JPY';
  }
);
export const defaultProductTimezoneSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return organization?.default_timezone || 'America/Los_Angeles';
  }
);

const error = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
    case FETCH_ORGANIZATIONS_FAILURE:
    case REFRESH_ORGANIZATIONS_FAILURE:
    case FETCH_ORGANIZATION_BY_ID_FAILURE:
    case DELETE_ORGANIZATION_FAILURE:
      return action.error;

    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_SUCCESS:
    case FETCH_ORGANIZATIONS_SUCCESS:
    case FETCH_ORGANIZATION_BY_ID_SUCCESS:
    case REFRESH_ORGANIZATIONS_SUCCESS:
    case DELETE_ORGANIZATION_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_REQUEST:
    case UPDATE_ORGANIZATION_REQUEST:
    case FETCH_ORGANIZATIONS_REQUEST:
    case FETCH_ORGANIZATION_BY_ID_REQUEST:
    case DELETE_ORGANIZATION_REQUEST:
      return true;

    case CREATE_ORGANIZATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
    case FETCH_ORGANIZATIONS_FAILURE:
    case FETCH_ORGANIZATION_BY_ID_FAILURE:
    case DELETE_ORGANIZATION_FAILURE:
    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_SUCCESS:
    case FETCH_ORGANIZATIONS_SUCCESS:
    case FETCH_ORGANIZATION_BY_ID_SUCCESS:
    case DELETE_ORGANIZATION_SUCCESS:
      return false;

    default:
      return state;
  }
};

const lastUpdated = (state = null, action: any) => {
  switch (action.type) {
    case UPDATE_ORGANIZATION_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

const all = (
  state: Organization[] = [],
  action: Record<string, any>
): Organization[] => {
  switch (action.type) {
    case CREATE_ORGANIZATION_SUCCESS: {
      const response = action.response as any as CreateOrganizationResponse;
      return [
        ...state.filter(
          (i) => i.id !== response.id || i.type !== response.type
        ),
        response,
      ];
    }

    case UPDATE_ORGANIZATION_SUCCESS: {
      const response = action.response as any as UpdateOrganizationResponse;
      return [
        ...state.filter(
          (i) => i.id !== response.id || i.type !== response.type
        ),
        response,
      ];
    }

    case FETCH_ORGANIZATIONS_SUCCESS: {
      const response = action.response as any as ListOrganizationsResponse;
      return response.organizations ?? [];
    }
    case REFRESH_ORGANIZATIONS_SUCCESS: {
      const response = action.response as any as ListOrganizationsResponse;
      // Only reinitialize organizations that have changed subscription settings
      // to avoid clearing fields that are being edited.
      return response.organizations.map((org) => {
        const existingOrg = state.find(
          (existingOrg) => existingOrg.id === org.id
        );

        if (!existingOrg) {
          return org;
        }

        const keys = [
          ...new Set([
            ...(existingOrg?.subscriptions?.map(
              (subscription) => subscription.key as SubscriptionKey
            ) ?? []),
            ...(org?.subscriptions?.map(
              (subscription) => subscription.key as SubscriptionKey
            ) ?? []),
          ]),
        ];

        if (
          !keys.some(
            (key) =>
              hasSubscription(existingOrg, key) !== hasSubscription(org, key) ||
              isSubscriptionCancelled(existingOrg, key) !==
                isSubscriptionCancelled(org, key)
          )
        ) {
          return existingOrg;
        }

        return org;
      });
    }

    case DELETE_ORGANIZATION_SUCCESS:
      return state.filter(
        (i) => i.id !== action.id || i.type !== action.orgType
      );

    case SAVE_PAYMENT_METHOD_SUCCESS:
    case ADD_SUBSCRIPTION_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const org = action.organization;
      return [
        ...state.filter((i) => i.id !== org.id || i.type !== org.type),
        org,
      ];
    }
    default:
      return state;
  }
};

const contracted = (
  state: OrganizationSummary[] = [],
  action: any
): OrganizationSummary[] => {
  switch (action.type) {
    case FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS: {
      const response =
        action.response as any as ListContractedOrganizationsResponse;
      return [...(response.organizations || [])];
    }

    default:
      return state;
  }
};

const contractedLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_CONTRACTED_ORGANIZATIONS_REQUEST:
      return true;

    case FETCH_CONTRACTED_ORGANIZATIONS_FAILURE:
    case FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const byID = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_BY_ID_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    default:
      return state;
  }
};

export const organizations = combineReducers({
  all,
  byID,
  contracted,
  contractedLoading,
  error,
  lastUpdated,
  loading,
});
