import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { getAvailabilityRuleScheduleText } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { formattedTimeSlot } from 'client/libraries/util/formattedTimeSlot';
import { getTimeSlotsFromRecurrence } from 'client/libraries/util/getTimeSlots';
import { FieldWrapper } from 'client/components/Form';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

const getEarliestStartDate = (
  schedule: AvailabilityAllotmentSchedule
): string => {
  return _.minBy(schedule.dateRanges, 'startDate')?.startDate ?? '';
};

const getLatestEndDate = (schedule: AvailabilityAllotmentSchedule): string => {
  return _.maxBy(schedule.dateRanges, 'endDate')?.endDate ?? '';
};

type Props = {
  parentProductId: string;
};
export const ParentProductStartTimeMappingEditor = ({
  parentProductId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  React.useEffect(() => {
    dispatch(fetchSourceLanguageProductById(parentProductId));
  }, [parentProductId]);

  const form = useForm();

  const parentProduct = useSelector(
    (state: ReduxState) => state.products.byID[parentProductId]
  );
  const parentProductTimeSlots = React.useMemo(() => {
    const schedule: AvailabilityAllotmentSchedule = _.get(
      form.getState().values,
      `availabilitySchedules.${activeScheduleIndex}`
    );
    const scheduleEarliestStartDate = getEarliestStartDate(schedule);
    const scheduleLatestEndDate = getLatestEndDate(schedule);
    return getTimeSlotsFromRecurrence(
      parentProduct?.recurrence ?? [],
      scheduleEarliestStartDate,
      scheduleLatestEndDate
    );
  }, [parentProduct, activeScheduleIndex]);
  const required = React.useCallback(
    (value: any | null | undefined): string => {
      if (
        !parentProductTimeSlots.some(
          (timeSlot) => value === timeSlot.timeSlotKey
        )
      )
        return t('Required');
      return '';
    },
    [parentProductTimeSlots]
  );

  return (
    <FieldArray name="availabilitySchedules">
      {({ fields }) => (
        <div className={baseStyles['base-margin-top-8']}>
          <FieldWrapper label={t('Start Time Settings')}>
            <div className={styles['page-productsEdit__select']}>
              <p className={styles['page-productsEdit__select__ttl']}>
                {t('Select')}
              </p>
              <div className={styles['page-productsEdit__select__box']}>
                <label
                  className={clsx(
                    baseStyles['base-form-select'],
                    baseStyles['base-maxwidth-800']
                  )}
                >
                  <select
                    value={activeScheduleIndex}
                    onChange={(e) => {
                      setActiveScheduleIndex(parseInt(e.target.value));
                    }}
                  >
                    {fields.value.map((availabilityRule, idx) => (
                      <option key={idx} value={idx}>
                        {getAvailabilityRuleScheduleText(availabilityRule, t)}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div
              key={activeScheduleIndex}
              className={baseStyles['base-selectFrame']}
            >
              <div className={baseStyles['base-selectFrame__header']}>
                <p className={baseStyles['base-selectFrame__header__ttl']}>
                  {t('Selected Rule')}
                </p>
              </div>
              <div
                className={clsx(
                  baseStyles['base-selectFrame__body'],
                  baseStyles['base-margin-bottom-16']
                )}
              >
                <FieldArray
                  name={`availabilitySchedules.${activeScheduleIndex}.startTimes`}
                >
                  {({ fields: startTimeFields }) =>
                    startTimeFields.map((startTimeName) => (
                      <div
                        key={startTimeName}
                        className={styles['package-start-time-section']}
                      >
                        <Field name={startTimeName}>
                          {({ input }) => (
                            <div
                              className={styles['package-start-time-label']}
                            >{`${t('Start Time: ')}${input.value.time}`}</div>
                          )}
                        </Field>
                        <Field
                          name={`${startTimeName}.parentProductTimeSlotKey`}
                          validate={required}
                        >
                          {({ input, meta: { error } }) => (
                            <FieldWrapper
                              label={t('Link to Parent Product Start Time')}
                            >
                              <label
                                className={clsx(
                                  baseStyles['base-form-select'],
                                  baseStyles['base-maxwidth-800']
                                )}
                              >
                                <select
                                  value={input.value}
                                  onChange={(e) =>
                                    input.onChange(e.target.value)
                                  }
                                >
                                  <option key="" value="" />
                                  {parentProductTimeSlots.map((candidate) => (
                                    <option
                                      key={candidate.timeSlotKey}
                                      value={candidate.timeSlotKey}
                                    >
                                      {formattedTimeSlot(candidate)}
                                    </option>
                                  ))}
                                </select>
                                {error && (
                                  <p
                                    className={baseStyles['base-form-box__err']}
                                  >
                                    {error}
                                  </p>
                                )}
                              </label>
                            </FieldWrapper>
                          )}
                        </Field>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </div>
          </FieldWrapper>
        </div>
      )}
    </FieldArray>
  );
};
