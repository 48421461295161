// @flow

import * as React from 'react';
import { Button, Icon, List, Step } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TranslatorProps } from 'react-i18next';

import { ConfirmReservationButton } from 'client/components/ConfirmReservationButton';
import { StandbyReservationButton } from 'client/components/StandbyReservationButton';
import { DeclineReservationButton } from 'client/components/DeclineReservationButton';
import { WithdrawReservationButton } from 'client/components/WithdrawReservationButton';
import { CancelWithFeeModal } from 'client/pages/ReservationDetails/CancelWithFeeModal';
import { CancelModal } from 'client/pages/ReservationDetails/CancelModal';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import {
  filteredStatuses,
  getCancellationDeadline,
  getCancellationText,
  getNextStatus,
  getReservationWeightedParticipantCount,
} from 'client/libraries/util/util';
import type {
  Reservation,
  Product,
  ProductInstance,
} from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';

type OwnProps = {
  readOnly?: boolean,
  reservation: Reservation,
  productInstance: ProductInstance,
  product: Product,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  cancelConfirmOpen: boolean,
};

const statusIconName = (status) => {
  switch (status) {
    case 'NO_SHOW':
      return 'frown';
    case 'PENDING':
    case 'CANCELLATION_PENDING':
    case 'AWAITING_RESERVATION_CONFIRMATION':
    case 'AWAITING_UPDATE_CONFIRMATION':
    case 'REQUESTED':
    case 'CANCEL_REQUESTED_BY_AGENT':
      return 'send';
    case 'STANDBY':
    case 'WAITLISTED':
      return 'wait';
    case 'CONFIRMED':
      return 'checkmark';
    case 'PARTICIPATED':
      return 'ticket';
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
      return 'cancel';
    default:
      return 'empty heart';
  }
};

const isResubmittableStatus = (status) => {
  switch (status) {
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
      return true;
    default:
      return false;
  }
};

export class StatusStepsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cancelConfirmOpen: false,
    };
  }

  handleCancelCancel = () =>
    this.setState({
      cancelConfirmOpen: false,
    });

  render() {
    const {
      activeUser,
      locale,
      readOnly,
      reservation,
      product,
      productInstance,
      t,
    } = this.props;

    const cancelDeadline = getCancellationDeadline(reservation, product);

    const statuses = filteredStatuses(reservation.status_history || []);

    const nextStatus =
      statuses.length > 0
        ? getNextStatus(statuses[statuses.length - 1].status)
        : '';

    const currentParticipantCount =
      productInstance.weighted_participant_count || 0;
    const resParticipantCount = getReservationWeightedParticipantCount(
      reservation,
      productInstance
    );
    const participantCountAfterCancel =
      currentParticipantCount - resParticipantCount;

    let cancelWarning = '';
    const minParticipantCount =
      (product.minimum_participant_count &&
        product.minimum_participant_count.value) ||
      0;
    if (
      minParticipantCount > 0 &&
      participantCountAfterCancel < minParticipantCount
    ) {
      cancelWarning = t(
        'After cancelling this booking, the participant count for the date and time will be below minimum participant requirements.'
      );
    }

    const userIsPassthroughAgent =
      reservation.agent_side_passthrough_reservation_id &&
      activeUser.organization_type === 'AGENT';
    const userIsPassthroughSupplier =
      reservation.supplier_side_passthrough_reservation_id &&
      activeUser.organization_type === 'SUPPLIER';
    const userIsPassthroughOrg =
      userIsPassthroughAgent || userIsPassthroughSupplier;

    const userCanConfirmReservation =
      !readOnly &&
      !userIsPassthroughOrg &&
      operationAllowed(activeUser, 'write', 'reservationConfirmation');
    const userCanSetCancellationFee =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationCancellationFee');
    const userCanCancelReservation = !readOnly && !userIsPassthroughAgent;
    const userCanWithdrawReservation =
      !readOnly &&
      !userIsPassthroughAgent &&
      !operationAllowed(activeUser, 'write', 'reservationConfirmation');
    const userCanResubmitReservation = !readOnly && !userIsPassthroughOrg;

    let packagePendingThirdPartyConfirmation: boolean = false;
    const packageComponentReservations =
      reservation.package_component_reservation_summaries || [];
    if (
      packageComponentReservations.length > 0 &&
      reservation.status === 'REQUESTED'
    ) {
      packagePendingThirdPartyConfirmation = true;
      for (let packageComponentReservation of packageComponentReservations) {
        if (
          !packageComponentReservation.supplier_side_passthrough_reservation_id &&
          packageComponentReservation.status !== 'CONFIRMED'
        ) {
          packagePendingThirdPartyConfirmation = false;
        }
      }
    }

    return (
      <Step.Group>
        {statuses.map((s, idx) =>
          (() => {
            const isActive = statuses.length - 1 === idx;
            const statusChangedAt = t('{{date}} ({{fromNow}})', {
              date: moment(s.status_date_time_utc).locale(locale).format('ll'),
              fromNow: moment(s.status_date_time_utc).locale(locale).fromNow(),
            });

            return (
              <Step key={s.status} active={isActive}>
                <Icon name={statusIconName(s.status)} />
                <Step.Content>
                  <Step.Title>{t(s.status)}</Step.Title>
                  {!isActive ? (
                    <Step.Description>{statusChangedAt}</Step.Description>
                  ) : s.status === 'REQUESTED' ? (
                    userCanConfirmReservation ? (
                      <Step.Description>
                        <List>
                          <List.Item>{statusChangedAt}</List.Item>
                          {packagePendingThirdPartyConfirmation ? (
                            <List.Item>
                              {t('** Pending third-party confirmation **')}
                            </List.Item>
                          ) : (
                            <List.Item>
                              <Button.Group size="tiny" basic>
                                <ConfirmReservationButton
                                  reservationID={reservation.id}
                                />
                                <StandbyReservationButton
                                  reservationID={reservation.id}
                                />
                                <DeclineReservationButton
                                  reservationID={reservation.id}
                                />
                              </Button.Group>
                            </List.Item>
                          )}
                        </List>
                      </Step.Description>
                    ) : userCanWithdrawReservation ? (
                      <Step.Description>
                        <List>
                          <List.Item>{statusChangedAt}</List.Item>
                          <List.Item>
                            <Button.Group size="tiny" basic>
                              <WithdrawReservationButton
                                reservationID={reservation.id}
                              />
                            </Button.Group>
                          </List.Item>
                        </List>
                      </Step.Description>
                    ) : null
                  ) : userCanConfirmReservation && s.status === 'STANDBY' ? (
                    <Step.Description>
                      <List>
                        <List.Item>{statusChangedAt}</List.Item>
                        <List.Item>
                          {s.until_date_time_utc &&
                            t('Until: {{untilDate}}', {
                              untilDate: moment(s.until_date_time_utc)
                                .locale(locale)
                                .format('lll'),
                            })}
                        </List.Item>
                        <List.Item>
                          <Button.Group size="tiny" basic>
                            <ConfirmReservationButton
                              reservationID={reservation.id}
                            />
                            <DeclineReservationButton
                              reservationID={reservation.id}
                            />
                          </Button.Group>
                        </List.Item>
                      </List>
                    </Step.Description>
                  ) : s.status === 'CONFIRMED' ||
                    s.status === 'CANCEL_DECLINED_BY_SUPPLIER' ? (
                    userCanSetCancellationFee ? (
                      <Step.Description>
                        <List>
                          <List.Item>{statusChangedAt}</List.Item>
                          <List.Item>
                            <Button
                              size="tiny"
                              negative
                              onClick={() =>
                                this.setState({ cancelConfirmOpen: true })
                              }
                            >
                              {t('Cancel')}
                            </Button>
                            <CancelWithFeeModal
                              open={this.state.cancelConfirmOpen}
                              reservation={reservation}
                              onClose={this.handleCancelCancel}
                              warning={cancelWarning}
                            />
                          </List.Item>
                        </List>
                      </Step.Description>
                    ) : (
                      <Step.Description>
                        <List>
                          <List.Item>{statusChangedAt}</List.Item>
                          {cancelDeadline &&
                          moment(cancelDeadline).isBefore(moment()) ? (
                            <List.Item>
                              {t('Cancel deadline expired: {{date}}', {
                                date: moment(cancelDeadline)
                                  .locale(locale)
                                  .fromNow(),
                              })}
                            </List.Item>
                          ) : userCanCancelReservation ? (
                            <List.Item>
                              <Button
                                size="tiny"
                                negative
                                onClick={() =>
                                  this.setState({ cancelConfirmOpen: true })
                                }
                              >
                                {t('Cancel')}
                              </Button>
                              <span
                                style={{
                                  color: '#ff495a',
                                }}
                              >
                                <i>
                                  {' ' +
                                    t('Deadline: {{date}} ({{fromNow}})', {
                                      date: moment(cancelDeadline)
                                        .locale(locale)
                                        .format('ll'),
                                      fromNow: moment(cancelDeadline)
                                        .locale(locale)
                                        .fromNow(),
                                    })}
                                </i>
                              </span>
                              <CancelModal
                                open={this.state.cancelConfirmOpen}
                                onClose={this.handleCancelCancel}
                                text={t(
                                  '{{cancelFeeDescription}} Are you sure you wish to cancel?',
                                  {
                                    cancelFeeDescription: getCancellationText(
                                      reservation,
                                      product,
                                      productInstance,
                                      t,
                                      locale
                                    ),
                                  }
                                )}
                                reservationID={reservation.id}
                              />
                            </List.Item>
                          ) : null}
                        </List>
                      </Step.Description>
                    )
                  ) : (
                    <Step.Description>
                      <List>
                        <List.Item>{statusChangedAt}</List.Item>
                        {isResubmittableStatus(s.status) &&
                          userCanResubmitReservation && (
                            <List.Item>
                              <Button
                                size="tiny"
                                positive
                                as={Link}
                                to={{
                                  pathname: `/products/${product.id}/instances/${productInstance.id}/book`,
                                  state: {
                                    reservation,
                                  },
                                }}
                              >
                                {t('Resubmit booking')}
                              </Button>
                            </List.Item>
                          )}
                      </List>
                    </Step.Description>
                  )}
                </Step.Content>
              </Step>
            );
          })()
        )}
        {nextStatus && (
          <Step disabled>
            <Icon name={statusIconName(nextStatus)} />
            <Step.Content>
              <Step.Title>{t(nextStatus)}</Step.Title>
              <Step.Description>{t('TBD')}</Step.Description>
            </Step.Content>
          </Step>
        )}
      </Step.Group>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    locale: state.language.selected.iso,
    activeUser: activeUserSelector(state),
  };
};

export const StatusSteps = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, null),
  withTranslation()
)(StatusStepsComponent);
