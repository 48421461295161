import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateReservation } from 'client/actions/reservations';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { TextArea } from 'client/components/v3/Form/TextArea';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

type Props = {
  reservation: ManifestReservationShape;
  header?: string;
  onClose: () => void;
};

export const EditSupplierInternalNotesModalForDispatchModal = ({
  reservation,
  header,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    supplierInternalNotesForDispatch,
    setSupplierInternalNotesForDispatch,
  ] = React.useState(reservation?.supplier_internal_notes_for_dispatch ?? '');

  return (
    <Modal
      insertAtRoot={true}
      title={header ?? ''}
      open={true}
      onClose={onClose}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Discard Changes')}
            color="white"
            onClick={() => {
              setSupplierInternalNotesForDispatch(
                reservation?.supplier_internal_notes_for_dispatch ?? ''
              );
              onClose();
            }}
            disabled={
              supplierInternalNotesForDispatch ===
              reservation?.supplier_internal_notes_for_dispatch
            }
          />
          <Button
            text={t('Save')}
            disabled={
              supplierInternalNotesForDispatch ===
              reservation?.supplier_internal_notes_for_dispatch
            }
            onClick={() => {
              dispatch(
                updateReservation(reservation?.id, {
                  supplier_internal_notes_for_dispatch:
                    supplierInternalNotesForDispatch,
                })
              );
              onClose();
            }}
          />
        </>
      }
    >
      <div className={styles['p-manifestsModalMemo']}>
        <div className={styles['p-manifestsModalMemo__item']}>
          <p className={styles['p-manifestsModalMemo__item__ttl']}>
            {t('Internal Note')}
          </p>
          <div className={styles['p-manifestsModalMemo__item__body']}>
            <TextArea
              required
              value={supplierInternalNotesForDispatch || ''}
              onChange={(_, { value }) =>
                setSupplierInternalNotesForDispatch(value)
              }
              height={100}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
