import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  EquipmentCellBlockMapping,
  EquipmentBlockInstance,
  EquipmentAsset,
} from 'shared/models/swagger';
import { equipmentBlocksSelector } from 'client/reducers/equipmentBlocks';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import {
  getRowColumnSpanForEquipmentBlockInstance,
  getCellBorderProperty,
  isDraggingEquipmentBlockPlaceable,
} from 'client/components/Seat/utils';

import styles from './SeatEditor.module.css';

interface BlockInstanceProps {
  i: number;
  j: number;
  rowCount: number;
  columnCount: number;
  equipmentCellBlockMappings: EquipmentCellBlockMapping[];
  selectedEquipmentBlockInstanceKeys: string[];
  placedEquipmentBlockInstanceKeys: string[];
  equipmentBlockInstances: EquipmentBlockInstance[];
  draggingEquipmentBlockId?: string | null;
  draggingOverPosition?: { row: number; column: number } | null;
  onSelectedEquipmentBlockInstanceKeysChange: (
    selectedEquipmentBlockInstanceKeys: string[]
  ) => void;
  onDraggingOverPositionChange: (
    position: {
      row: number;
      column: number;
    } | null
  ) => void;
  onEquipmentBlockInstancesChange: () => void;
  onPopupPositionChange: (arg: { left: number; top: number } | null) => void;
  equipmentAsset: EquipmentAsset | undefined;
}

export const BlockInstance = ({
  i,
  j,
  rowCount,
  columnCount,
  equipmentCellBlockMappings,
  selectedEquipmentBlockInstanceKeys,
  placedEquipmentBlockInstanceKeys,
  equipmentBlockInstances,
  draggingEquipmentBlockId,
  draggingOverPosition,
  onSelectedEquipmentBlockInstanceKeysChange,
  onDraggingOverPositionChange,
  onEquipmentBlockInstancesChange,
  onPopupPositionChange,
  equipmentAsset,
}: BlockInstanceProps) => {
  const equipmentBlocks = useSelector(equipmentBlocksSelector);

  const ref = React.useRef<HTMLLIElement>(null);
  const draggingEquipmentBlock = equipmentBlocks.find((equipmentBlock) => {
    return equipmentBlock.id === draggingEquipmentBlockId;
  });
  const mapping = equipmentCellBlockMappings.find((mapping) => {
    return (mapping.row ?? 0) === i && (mapping.column ?? 0) === j;
  });

  const isSelected =
    mapping && mapping?.equipment_block_instance_key
      ? selectedEquipmentBlockInstanceKeys.includes(
          mapping?.equipment_block_instance_key ?? ''
        )
      : false;

  const rowColumnSpan = getRowColumnSpanForEquipmentBlockInstance(
    i,
    j,
    rowCount,
    columnCount,
    mapping?.equipment_block_instance_key ?? '',
    placedEquipmentBlockInstanceKeys,
    equipmentCellBlockMappings ?? []
  );

  if (rowColumnSpan.isSkipped) {
    return null;
  }

  const equipmentBlockInstance = equipmentBlockInstances?.find(
    (equipmentBlockInstance) => {
      return (
        equipmentBlockInstance.key === mapping?.equipment_block_instance_key
      );
    }
  );

  const equipmentBlock = equipmentBlocks.find((equipmentBlock) => {
    return equipmentBlock.id === equipmentBlockInstance?.equipment_block_id;
  });

  const cellBoarderProperty = getCellBorderProperty(
    i,
    j,
    draggingOverPosition ?? null,
    draggingEquipmentBlock
      ? {
          rowCount: draggingEquipmentBlock.row_count ?? 0,
          columnCount: draggingEquipmentBlock.column_count ?? 0,
        }
      : null
  );

  const pos = ref?.current?.getBoundingClientRect();

  return (
    <li
      ref={ref}
      key={`${i}-${j}`}
      className={clsx(
        isSelected ? styles['select'] : '',
        cellBoarderProperty.borderTop ? styles['border__top'] : '',
        cellBoarderProperty.borderLeft ? styles['border__left'] : '',
        cellBoarderProperty.borderRight ? styles['border__right'] : '',
        cellBoarderProperty.borderBottom ? styles['border__bottom'] : ''
      )}
      style={{
        width: '100%',
        height: '100%',
        gridColumn: `${j + 1} / span ${rowColumnSpan.columnSpan ?? 1}`,
        gridRow: `${i + 1} / span ${rowColumnSpan.rowSpan ?? 1}`,
        backgroundColor: equipmentBlock?.color?.value ?? 'white',
      }}
      onClick={() => {
        if (mapping && mapping.equipment_block_instance_key) {
          if (
            !selectedEquipmentBlockInstanceKeys.includes(
              mapping.equipment_block_instance_key
            )
          ) {
            onSelectedEquipmentBlockInstanceKeysChange([
              ...selectedEquipmentBlockInstanceKeys,
              mapping.equipment_block_instance_key,
            ]);
          } else {
            onSelectedEquipmentBlockInstanceKeysChange([
              ...selectedEquipmentBlockInstanceKeys.filter((key) => {
                return key !== mapping?.equipment_block_instance_key ?? '';
              }),
            ]);
          }
          onPopupPositionChange({
            left: pos?.left ?? 0,
            top: pos?.top ?? 0,
          });
        }
      }}
      onDragOver={(e) => {
        if (draggingEquipmentBlockId) {
          onDraggingOverPositionChange({ row: i, column: j });
        }
        e.preventDefault();
      }}
      onDrop={(e) => {
        if (draggingEquipmentBlockId) {
          const isPlaceable = isDraggingEquipmentBlockPlaceable(
            i,
            j,
            draggingEquipmentBlock?.row_count ?? 0,
            draggingEquipmentBlock?.column_count ?? 0,
            equipmentAsset ?? undefined,
            equipmentBlocks ?? undefined
          );
          if (isPlaceable) {
            onEquipmentBlockInstancesChange();
          } else {
            alert('配置できません');
          }
        }
        e.preventDefault();
      }}
    >
      {equipmentBlock ? (
        <div>{equipmentBlock.title}</div>
      ) : (
        <>
          {convertToAlphabet(j + 1)}
          {i + 1}
        </>
      )}
    </li>
  );
};
