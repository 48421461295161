import React from 'react';
import clsx from 'clsx';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { currency } from 'shared/libraries/currency';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import {
  createReservation,
  clearLastCreatedReservationId,
} from 'client/actions/reservations';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  getFieldResponseErrors,
  printGuestType,
  convertLocationWithMomentToReservationLocationWithTimeInput,
  convertToLocationWithMoment,
  currencyAmountInputHelper,
} from 'client/libraries/util/coreutil';
import { findClosestPickupLocation } from 'client/libraries/util/findClosestPickupLocation';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
  agentOptionsSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import { generateAgentReference, histogram } from 'client/libraries/util/util';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { SubmitReservationButton } from 'client/pages/BookingForm/SubmitReservationButton';
import { FormFieldV2 as FormField } from 'client/components/FormFieldV2';
import { FieldWrapper, Checkbox, MoneyInput } from 'client/components/Form';
import { FieldsFormV2 as FieldsForm } from 'client/components/FieldsFormV2';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import { matchesFormat } from 'shared/libraries/validate/validator';
import type { ReduxState } from 'client/reducers';
import { ReservationCreateModalButton } from 'client/components/ReservationCreateModal/ReservationCreateModalButton';
import { needToOpenNoEmailWithPaymentLinkMessageModal } from 'client/pages/BookingForm/util';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { AccordionItem } from 'client/components/NewProductEditor/Accordion/Accordion';
import { Box } from 'client/components/Box/Box';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type {
  LocationWithTime,
  NewReservation,
  Reservation,
  BookingSourceType,
  GuestType,
  Field,
  Guest,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/BookingForm/Book.module.css';
import editIcon from 'client/images/ic_edit.svg';
import oneIcon from 'client/images/ic_one.svg';
import twoIcon from 'client/images/ic_two.svg';
import threeIcon from 'client/images/ic_three.svg';
import fourIcon from 'client/images/ic_four.svg';
import bookingSummaryIcon from 'client/images/ic_bookingsummary.svg';

import { TruncatedLabel } from './TruncatedLabel/TruncatedLabel';

const getGuestSummary = (guests: Guest[], guestTypes: GuestType[]): string => {
  const guestCounts = histogram(guests, (guest) => guest.guest_type_key);

  return (
    guestTypes
      .filter((guestType) => guestCounts[guestType.key])
      .map((guestType) => `${guestType.title} x ${guestCounts[guestType.key]}`)
      .join(', ') || '-'
  );
};

type LocationState = {
  productName: string;
  participationDate: string;
  startTime: string;
};

/* eslint-enable no-use-before-define */
type PaymentType = 'PAID_IN_FULL' | 'PAY_ON_BOARD' | 'PAID_PARTIALLY';
type PaymentMethod = 'INVOICE' | 'CREDIT_CARD' | 'CASH' | 'OTHER';

type FieldResponse = {
  response?: string;
  key?: string;
};

export const FreeFormatBookingForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const contractAgents = useSelector(agentOptionsSelector);
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const lastCreatedReservationId = useSelector(
    (state: ReduxState) => state.reservations.lastCreatedReservationID
  );

  const hotelInputRef = React.useRef<HTMLInputElement | null>(null);
  const bookingSummaryPopupRef = React.useRef<HTMLDivElement | null>(null);
  const pageRef = React.useRef<HTMLDivElement | null>(null);
  let placesService: Record<string, any> | undefined = undefined;

  const propsLocation = location && (location.state as LocationState);
  const [productName, setProductName] = React.useState<string>(
    propsLocation?.productName ?? ''
  );
  const [participationDate, setParticipationDate] = React.useState<string>(
    propsLocation?.participationDate ?? ''
  );
  const [startTime, setStartTime] = React.useState<string>(
    propsLocation?.startTime ?? ''
  );
  //TODO
  //const bookingType = propsLocation.bookingType ?? 'PER_PARTICIPANT';

  const baseNewReservation: NewReservation = {
    agent_reference: generateAgentReference(),
    agent_notes: '',
    supplier_notes: '',
    product_instance_id: '',
    transportation: '',
    guests: [],
    field_responses: [
      {
        key: 'preferred_language_iso2',
        response: locale,
      },
    ],
    booking_source: {
      source_type: 'DIRECT_TELEPHONE',
      agent_name: '',
    },
    payment_method: 'CASH',
    rebooked_from_reservation_id: '',
    payment_type: 'PAY_ON_BOARD',
    amount_pay_on_board: '',
    payment_deferred: false,
    email_payment_to_address: '',
    is_free_format_reservation: true,
  };

  const helper = currencyAmountInputHelper(
    activeUserOrganization?.default_currency ?? 'USD'
  );
  const timezone = activeUserOrganization?.default_timezone ?? '';
  //TODO?
  const timeSlotKey = '';
  const startDate = moment
    .tz(`${participationDate} ${startTime}`, timezone)
    .locale(locale);

  const [createdReservationId, setCreatedReservationId] =
    React.useState<string>('');
  const [newReservation, setNewReservation] =
    React.useState<NewReservation>(baseNewReservation);
  const [showBookingSummaryModal, setShowBookingSummaryModal] =
    React.useState<boolean>(false);
  const [pickupDropoffUseSameLocation, setPickupDropoffUseSameLocation] =
    React.useState<boolean>(true);
  const [openReservationCreateModal, setOpenReservationCreateModal] =
    React.useState<boolean>(false);
  const [
    openSubmitReservationMessageModal,
    setOpenSubmitReservationMessageModal,
  ] = React.useState<boolean>(false);
  const [
    openNoEmailWithPaymentLinkMessageModal,
    setOpenNoEmailWithPaymentLinkMessageModal,
  ] = React.useState<boolean>(false);
  const [
    lastSubmissionValidationErrorMap,
    setLastSubmissionValidationErrorMap,
  ] = React.useState<Record<string, string>>({});
  const [guestSummary, setGuestSummary] = React.useState<
    {
      guest_type_key: string;
      count: number;
    }[]
  >([]);
  const [amountGrossError, setAmountGrossError] =
    React.useState<boolean>(false);

  const [guestGross, setGuestGross] = React.useState<Record<string, string>>(
    {}
  );
  const [guestNet, setGuestNet] = React.useState<Record<string, string>>({});
  const totalGross = React.useMemo(() => {
    return Object.keys(guestGross).reduce((a: string, b: string) => {
      const gross = currency(
        guestGross[b] || '0',
        activeUserOrganization?.default_currency ?? 'USD'
      ).multiply(
        guestSummary.find((g) => g.guest_type_key === b)?.count || 0
      ).value;
      const result = helper.add(a, String(gross) || '0');
      return result;
    }, '0');
  }, [guestGross, guestSummary]);
  const totalNet = React.useMemo(() => {
    return Object.keys(guestNet).reduce((a, b) => {
      const net = currency(
        guestNet[b] || '0',
        activeUserOrganization?.default_currency ?? 'USD'
      ).multiply(
        guestSummary.find((g) => g.guest_type_key === b)?.count || 0
      ).value;
      const result = helper.add(a, String(net) || '0');
      return result;
    }, '0');
  }, [guestNet, guestSummary]);

  React.useEffect(() => {
    const propsLocation = location && (location.state as LocationState);
    const productName = propsLocation?.productName ?? '';
    const participationDate = propsLocation?.participationDate ?? '';
    const startTime = propsLocation?.startTime ?? '';

    if (productName != '' && participationDate != '' && startTime != '') {
      setProductName(productName);
      setParticipationDate(participationDate);
      setStartTime(startTime);
      setNewReservation(baseNewReservation);
    }
  }, [location]);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, [activeUser]);

  React.useEffect(() => {
    setCreatedReservationId(lastCreatedReservationId);
  }, [lastCreatedReservationId]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [lastCreatedReservationId]);

  //Other function paragraph
  const onSubmit = (reservation: any) => {
    dispatch(createReservation(reservation));
  };

  const scrollToTop = () => {
    (pageRef as any)?.current?.scrollIntoView();
  };

  const handleClickOutside = (event: any) => {
    if (
      bookingSummaryPopupRef &&
      !(bookingSummaryPopupRef as any)?.current?.contains(event.target) &&
      showBookingSummaryModal
    ) {
      setShowBookingSummaryModal(false);
    }
  };

  const handlePickupLocationChanged = (loc: LocationWithMoment) => {
    const pickup =
      convertLocationWithMomentToReservationLocationWithTimeInput(loc);

    if (pickupDropoffUseSameLocation && pickup) {
      const dropoff = {
        location_name: pickup.location_name,
        location_description: pickup.location_description,
        google_place_id: pickup.google_place_id,
      };
      setNewReservation({
        ...newReservation,
        dropoff,
        pickup,
      });
    } else {
      setNewReservation({
        ...newReservation,
        pickup,
      });
    }
  };

  const handleTogglePickupDropoffUseSameLocation = () => {
    if (!pickupDropoffUseSameLocation) {
      const pickup = newReservation.pickup;
      const dropoff = {
        location_name: pickup && pickup.location_name,
        location_description: pickup && pickup.location_description,
        google_place_id: pickup && pickup.google_place_id,
      };
      setPickupDropoffUseSameLocation(true);
      setNewReservation({
        ...newReservation,
        dropoff,
      });
    } else {
      setPickupDropoffUseSameLocation(false);
    }
  };

  const getPickupTime = (pickupLocation: LocationWithTime): Moment => {
    // Apply pickup time to start time
    const timeRelative = pickupLocation.time_relative;

    //TODO?
    //let timeRelative = pickupLocation.time_relative;
    //if ((pickupLocation.dedicated_start_time_info ?? []).length > 0) {
    //  const dedicatedStartTimeItem =
    //    pickupLocation.dedicated_start_time_info?.find(
    //      (item) =>
    //        item.time_slot_key === this.props.productInstance?.time_slot_key
    //    );

    //  if (dedicatedStartTimeItem?.time_relative) {
    //    timeRelative = dedicatedStartTimeItem?.time_relative;
    //  }
    //}
    const startDate = moment.tz(`${participationDate} ${startTime}`, timezone);
    return moment(startDate).add(moment.duration(timeRelative));
  };

  const handleTransportationChange = (transportation: string) => {
    setNewReservation({
      ...newReservation,
      checkin: undefined,
      checkout: undefined,
      pickup: undefined,
      dropoff: undefined,
      requested_pickup_location: undefined,
      requested_dropoff_location: undefined,
      transportation: transportation === 'none' ? '' : transportation,
    });
  };

  const handleCustomerLanguageChanged = (language: string) => {
    let fieldResponses = newReservation.field_responses
      ? [...newReservation.field_responses]
      : [];
    const languageFieldIdx = fieldResponses.findIndex(
      (r) => r.key === 'preferred_language_iso2'
    );

    if (languageFieldIdx !== -1) {
      fieldResponses[languageFieldIdx] = {
        key: 'preferred_language_iso2',
        response: language,
      };
    } else {
      fieldResponses = [
        ...fieldResponses,
        {
          key: 'preferred_language_iso2',
          response: language,
        },
      ];
    }

    setNewReservation({
      ...newReservation,
      field_responses: fieldResponses,
    });
  };

  const handleHotelLocationNameChanged = (location_name: string) => {
    setNewReservation({
      ...newReservation,
      guest_hotel: {
        location_name,
      },
    });
  };

  const handleHotelLocationSelected = ({
    title: location_name,
    key: google_place_id,
  }: {
    title: string;
    key: string;
  }) => {
    const guest_hotel = {
      location_name,
      google_place_id,
    };
    setNewReservation({
      ...newReservation,
      guest_hotel,
    });
  };

  const handleDropoffLocationChanged = (loc: LocationWithMoment) => {
    setNewReservation({
      ...newReservation,
      dropoff: convertLocationWithMomentToReservationLocationWithTimeInput(loc),
    });
  };

  const handleTogglePaymentDeferred = () => {
    setNewReservation({
      ...newReservation,
      payment_deferred: !newReservation.payment_deferred,
    });
  };

  const reservationFromState = () => ({
    // Spread 'newReservation' before product/instance fields to prevent productInstance.start_date_time_utc from being
    // clobbered by newReservation.start_date_time_utc when we are resubmitting a previous booking.
    ...newReservation,
    product_name: productName,
    start_date_time_utc: moment
      .tz(`${participationDate} ${startTime}`, timezone)
      .utc()
      .format(),
    start_timezone: timezone,
  });

  const getFullName = () => {
    const familyNameField =
      newReservation.field_responses &&
      newReservation.field_responses.find(
        (f: FieldResponse) => f.key === 'family_name'
      );
    const givenNameField =
      newReservation.field_responses &&
      newReservation.field_responses.find(
        (f: FieldResponse) => f.key === 'given_name'
      );
    const familyName = familyNameField ? familyNameField.response : '';
    const givenName = givenNameField ? givenNameField.response : '';
    return givenName && familyName ? givenName + ' ' + familyName : '';
  };

  const summarizeGuest = (guests: Guest[]) => {
    const summary: Record<string, number> = {};
    guests.forEach((g) => {
      if (!summary[g.guest_type_key]) {
        summary[g.guest_type_key] = 0;
      }
      summary[g.guest_type_key]++;
    });
    const result = Object.keys(summary).map((g) => {
      return {
        guest_type_key: g,
        count: summary[g],
      };
    });
    setGuestSummary(result);
  };

  const updateGuest = (guestTypeKey: string, quantity: number) => {
    const firstTime = newReservation.guests.length === 0 && quantity;

    // Special case of copying over full & given names when adding the first guest
    // Add guest object with `field_responses`.
    if (firstTime) {
      const guests = [...Array(quantity)].map((_, i) => {
        const defaultGuest = {
          guest_type_key: guestTypeKey,
        };
        let guest = {};

        if (i === 0) {
          guest = {
            ...defaultGuest,
            field_responses: [
              {
                key: 'full_name',
                response: getFullName(),
              },
            ],
          };
        } else {
          guest = { ...defaultGuest };
        }

        return guest;
      });
      setNewReservation({
        ...newReservation,
        guests: guests as Guest[],
      });
      summarizeGuest(guests as Guest[]);
    } else {
      // Check if need to add / remove guest
      const prevQuantity =
        newReservation.guests.filter((g) => g.guest_type_key === guestTypeKey)
          .length || 0;

      if (quantity > prevQuantity) {
        const addQuantity = quantity - prevQuantity;
        const newGuests: Guest[] = [];

        for (let i = 0; i < addQuantity; i++) {
          newGuests.push({
            guest_type_key: guestTypeKey,
          });
        }

        const guests = [...(newReservation.guests || []), ...newGuests];
        setNewReservation({
          ...newReservation,
          guests: guests,
        });
        summarizeGuest(guests as Guest[]);
      } else {
        if (quantity === 0) {
          const guests = newReservation.guests.filter(
            (g) => g.guest_type_key !== guestTypeKey
          );
          setNewReservation({
            ...newReservation,
            guests: guests,
          });
          summarizeGuest(guests as Guest[]);
          return;
        }

        // remove guest
        let find = true;
        const idxToRemove: number[] = [];
        let fromIndex = newReservation.guests.length;
        const removeQuantity = prevQuantity - quantity;

        while (find) {
          const idx = _.findLastIndex(
            newReservation.guests,
            (g: Guest) => g.guest_type_key === guestTypeKey,
            fromIndex
          );

          if (idx === -1) {
            find = false;
          } else {
            idxToRemove.push(idx);
            fromIndex = idx - 1;
          }

          if (fromIndex < 0 || idxToRemove.length == removeQuantity) {
            find = false;
          }
        }

        if (idxToRemove.length != removeQuantity) {
          throw new Error('updateGuest() unable to remove guest');
        }

        const guests = newReservation.guests.filter(
          (g, idx) => idxToRemove.includes(idx) === false
        );
        setNewReservation({
          ...newReservation,
          guests: guests,
        });
        summarizeGuest(guests as Guest[]);
      }
    }
  };

  const getFormInputErrors = (): Record<string, any> => {
    let errorMap: Record<string, string> = {};

    if (!newReservation.guests || newReservation.guests.length === 0) {
      errorMap['guests'] = t('At least one guest required');
      errorMap['fare_adjustment'] = t(
        'Enter the gross and net price per unit.'
      );
    }

    if (newReservation.payment_deferred) {
      if (!newReservation.email_payment_to_address) {
        errorMap['email_payment_to_address'] = t(
          'Email address required when sending email with payment link'
        );
      } else if (
        !matchesFormat(newReservation.email_payment_to_address, 'email')
      ) {
        errorMap['email_payment_to_address'] = t(
          '"{{fieldName}}" does not match format "{{format}}"',
          {
            fieldName: 'Payment link email address',
            format: 'email',
          }
        );
      }
    }

    // End of email_payment_to_address validation
    const perBookingFields = participationReservationFormFields.map((f) => {
      // remove format validation when the organization type is supplier
      if (activeUser?.organization_type === 'SUPPLIER') {
        return { ...f, format: undefined };
      }
      return f;
    });
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newReservation.field_responses || [],
        perBookingFields,
        t,
        activeUser?.organization_type === 'SUPPLIER'
      ),
    };

    if (
      newReservation.payment_type === 'PAID_PARTIALLY' &&
      newReservation.amount_pay_on_board === ''
    ) {
      errorMap['amount_pay_on_board'] = t('"{{fieldName}}" required', {
        fieldName: t('Amount Paid Onboard'),
      });
    }

    if (
      guestSummary.some(
        (g) => !guestGross[g.guest_type_key] || !guestNet[g.guest_type_key]
      )
    ) {
      errorMap['fare_adjustment'] = t(
        'Enter the gross and net price per unit.'
      );
    }

    return errorMap;
  };

  const reservationToSubmit = () => {
    const prices: Record<string, any>[] = [];
    guestSummary.forEach((g) => {
      for (let i = 0; i < g.count; i++) {
        prices.push({
          gross: helper.moneyString(guestGross[g.guest_type_key]),
          net: helper.moneyString(guestNet[g.guest_type_key]),
          title: g.guest_type_key,
        });
      }
    });

    let paymentGateway = '';
    if (
      newReservation.payment_deferred &&
      activeUserOrganization?.reservation_payment_gateway_settings
        ?.payment_gateway === 'GMO'
    ) {
      paymentGateway = 'GMO';
    }

    const newRes = {
      ...newReservation,
      ...(paymentGateway ? { payment_gateway: paymentGateway } : {}),
      free_format_reservation_params: {
        product_name: productName,
        date_from: moment
          .tz(`${participationDate} ${startTime}`, timezone)
          .utc()
          .format(),
        free_format_adjustment_prices: prices,
      },
    } as NewReservation;
    return newRes;
  };

  //Other fields
  //TODO supplier langage check handling filed response.
  const participationReservationFormFields: Field[] = [
    {
      key: 'family_name',
      prompt: t('Family Name'),
      format: 'alpha-name',
      required: 'WHEN_BOOKING',
      type: 'PER_BOOKING',
    },
    {
      key: 'given_name',
      prompt: t('Given Name'),
      format: 'alpha-name',
      required: 'WHEN_BOOKING',
      type: 'PER_BOOKING',
    },
    {
      key: 'email',
      prompt: t('Email'),
      format: 'email',
      required: 'OPTIONAL',
      type: 'PER_BOOKING',
    },
  ];
  const paymentMethodOptions = ['INVOICE', 'CREDIT_CARD', 'CASH', 'OTHER'];
  const otherPaymentMethodOptions = ['Bank Transfer', 'E-Money'];
  const paymentTypeOptions = ['PAID_IN_FULL', 'PAY_ON_BOARD', 'PAID_PARTIALLY'];
  const guestTypes: GuestType[] = [
    {
      key: 'Adult',
      title: t('Adult'),
    },
    {
      key: 'Child',
      title: t('Child'),
    },
    {
      key: 'Infant',
      title: t('Infant'),
    },
    {
      key: 'Adult/Child',
      title: t('Adult/Child'),
    },
    {
      key: 'Spectator',
      title: t('Spectator'),
    },
  ];
  const bookingSourceIsAgent =
    newReservation.booking_source &&
    newReservation.booking_source.source_type === 'AGENT';
  const activatePaymentDeferred =
    newReservation.payment_method === 'CREDIT_CARD' &&
    newReservation.payment_type === 'PAID_IN_FULL';
  const customerLanguage: string =
    (newReservation.field_responses || []).find(
      (r) => r.key === 'preferred_language_iso2'
    )?.response || 'ja-JP';
  const customerLanguageOptions = contentLanguageOptions.map((option) => ({
    value: option.iso,
    text: getLanguageName(option.iso, t),
  }));

  const transportations = [
    {
      key: t('Included Transportation'),
      title: t('Included Transportation'),
      service_type: 'FREE',
    },
  ];
  const productCurrency = activeUserOrganization?.default_currency ?? '';
  const currentValidationErrorMap = getFormInputErrors();
  const errorMap: Record<string, string> = {};
  // Set keys for all fields that were invalid in the last submission.
  Object.keys(lastSubmissionValidationErrorMap).forEach((errKey) => {
    errorMap[errKey] = currentValidationErrorMap[errKey];
  });
  const errors = Object.values(lastSubmissionValidationErrorMap);
  const pickup = convertToLocationWithMoment(
    newReservation.pickup,
    timezone,
    locale
  );
  const dropoff = convertToLocationWithMoment(
    newReservation.dropoff,
    timezone,
    locale
  );
  const checkin = convertToLocationWithMoment(
    newReservation.checkin,
    timezone,
    locale
  );

  React.useEffect(() => {
    //const productHasPickupLocations =
    //  (this.props.product?.pickup ?? []).length > 0;
    const productHasPickupLocations = true;
    const hasSelectedTransportation = !!newReservation.transportation;
    const hotelPlaceId = newReservation.guest_hotel?.google_place_id ?? '';

    //TODO
    if (
      hotelPlaceId !== '' &&
      productHasPickupLocations &&
      hasSelectedTransportation
    ) {
      if (!placesService) {
        placesService = new window.google.maps.places.PlacesService(
          (hotelInputRef as any)?.current
        );
      }

      placesService?.getDetails(
        {
          placeId: hotelPlaceId,
          fields: ['geometry'],
        },
        (place: any, status: any) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            //TODO
            //const closestPickup = findClosestPickupLocation(
            //  lat,
            //  lng,
            //  this.props.product?.pickup ?? []
            //);
            const closestPickup = findClosestPickupLocation(lat, lng, []);

            if (closestPickup) {
              const pickupTime = getPickupTime(closestPickup);
              handlePickupLocationChanged({
                locationID: closestPickup.id ?? '',
                locationName: closestPickup.location_name ?? '',
                locationDescription: closestPickup.location_description ?? '',
                googlePlaceID: closestPickup.google_place_id ?? '',
                locationDateTime: pickupTime,
                imageUrls: closestPickup.image_urls ?? [],
              });
            }
          } else {
            throw new Error('getDetails() returned status ' + status);
          }
        }
      );
    }
  }, [newReservation.guest_hotel?.google_place_id]);

  React.useEffect(() => {
    const familyNameField =
      newReservation.field_responses &&
      newReservation.field_responses.find(
        (f: FieldResponse) => f.key === 'family_name'
      );
    const givenNameField =
      newReservation.field_responses &&
      newReservation.field_responses.find(
        (f: FieldResponse) => f.key === 'given_name'
      );
    const familyName = familyNameField ? familyNameField.response : '';
    const givenName = givenNameField ? givenNameField.response : '';

    if (familyName != '' && givenName != '') {
      const fullName =
        givenName && familyName ? givenName + ' ' + familyName : '';
      setNewReservation({
        ...newReservation,
        guests: newReservation.guests.map((guest, idx) =>
          idx === 0
            ? {
                ...guest,
                field_responses: [
                  ...(guest.field_responses || []).filter(
                    (r) => r.key !== 'full_name'
                  ),
                  {
                    key: 'full_name',
                    response: fullName,
                  },
                ],
              }
            : guest
        ),
      });
    }
  }, [newReservation.field_responses]);

  if (createdReservationId) {
    dispatch(clearLastCreatedReservationId());
    return <Redirect to={`/reservations/${createdReservationId}`} />;
  }

  const OrderSummary = () => {
    return (
      <>
        <a
          className={styles['order-summary-close']}
          onClick={() => {
            setShowBookingSummaryModal(false);
          }}
        />
        <FieldWrapper label={t('Order Summary')}>
          <div>
            <div>{productName}</div>
            <div>{`${startDate.format('ll')} ${startDate.format('LT')}`}</div>
          </div>
        </FieldWrapper>
        <Box mt={1}>
          <FieldWrapper label={t('Participants')}>
            {getGuestSummary(newReservation?.guests ?? [], guestTypes)}
          </FieldWrapper>
        </Box>
        <Box mt={1}>
          <FieldWrapper label={t('Transportation')}>
            {transportations?.find(
              (transportationItem) =>
                transportationItem.key === newReservation?.transportation
            )?.title ?? t('Checkin/Checkout Only')}
          </FieldWrapper>
        </Box>
        <Box mt={1}>
          <FieldWrapper label={t('Total')}>
            {!amountGrossError && totalGross && totalNet ? (
              <div>
                {totalGross && (
                  <div>{`${t('Gross')} ${formattedCurrencyAmount(
                    helper.moneyString(totalGross || '0')
                  )}`}</div>
                )}
                {totalNet && (
                  <div>{`${t('Net')} ${formattedCurrencyAmount(
                    helper.moneyString(totalNet || '0')
                  )}`}</div>
                )}
              </div>
            ) : (
              '-'
            )}
          </FieldWrapper>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <SubmitReservationButton
            reservation={reservationFromState() as Reservation}
            open={openSubmitReservationMessageModal}
            onClick={() => {
              setShowBookingSummaryModal(false);
              if (
                needToOpenNoEmailWithPaymentLinkMessageModal(
                  newReservation as any,
                  false
                )
              ) {
                setOpenNoEmailWithPaymentLinkMessageModal(true);
              } else {
                const openMessageModal =
                  Object.keys(currentValidationErrorMap).length === 0;
                setOpenSubmitReservationMessageModal(openMessageModal);
                setLastSubmissionValidationErrorMap(currentValidationErrorMap);
                scrollToTop();
              }
            }}
            onSubmit={() => {
              onSubmit(reservationToSubmit());
            }}
            onClose={() => {
              setOpenSubmitReservationMessageModal(false);
            }}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <ReservationCreateModalButton
        productId={''}
        defaultDate={moment
          .tz(`${participationDate} ${startTime}`, timezone)
          .utc()}
        open={openReservationCreateModal}
        onClose={() => {
          setOpenReservationCreateModal(false);
        }}
      />
      {!loading && (
        <div ref={pageRef} className={styles['page-container']}>
          <div className={clsx(styles['page-outline'])}>
            <div
              className={clsx(baseStyles['base-flex'], baseStyles['align_top'])}
            >
              <h2>{productName}</h2>
              <button
                className={clsx(baseStyles['icon'], baseStyles['inline-icon'])}
                onClick={() => {
                  setOpenReservationCreateModal(true);
                }}
              >
                <img src={editIcon} />
              </button>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              {errors.length > 0 && (
                <div className={clsx(styles['error-box'])}>
                  <div className={clsx(styles['error-header'])}>
                    {t('Error')}
                  </div>
                  <ul className={clsx(styles['error-list'])}>
                    {errors.map((err, index) => (
                      <li
                        key={index}
                        className={clsx(styles['error-list-item'])}
                      >
                        {err as any as string}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <AccordionItem
                id="participation"
                header={t('1. Participation')}
                initialOpen={true}
              >
                {() => (
                  <>
                    <div className={clsx(styles['field'])}>
                      <FieldWrapper label={t('Participation')}>
                        <div>{`${startDate.format('ll')} ${startDate.format(
                          'LT'
                        )}`}</div>
                      </FieldWrapper>
                    </div>
                    <div className={clsx(styles['field'])}>
                      <FieldsForm
                        fields={participationReservationFormFields}
                        getFieldValue={(key) => {
                          const r = (newReservation.field_responses || []).find(
                            (r) => r.key === key
                          );

                          if (!r) {
                            return '';
                          }

                          return r.response || '';
                        }}
                        errorMap={errorMap}
                        onFieldChange={(key, value) =>
                          setNewReservation({
                            ...newReservation,
                            field_responses: [
                              ...(newReservation.field_responses || []).filter(
                                (r) => r.key !== key
                              ),
                              {
                                key,
                                response: value,
                              },
                            ],
                          })
                        }
                        mode="INPUT"
                      />
                    </div>
                    {guestTypes.length > 0 && (
                      <div
                        className={
                          errorMap['guests']
                            ? clsx(styles['segment'], styles['error'])
                            : clsx(styles['segment'])
                        }
                      >
                        <FieldWrapper required label={t('Guests')}>
                          {guestTypes.map((guestType: any, idx: number) => {
                            const cnt =
                              (newReservation.guests &&
                                newReservation.guests.filter(
                                  (g) => g.guest_type_key === guestType.key
                                ).length) ||
                              0;
                            return (
                              <div key={idx} className={styles['unit']}>
                                <div className={baseStyles['base-form-box']}>
                                  <TruncatedLabel
                                    text={`${
                                      printGuestType(guestType, t) || ''
                                    }`}
                                  />
                                  <div
                                    className={
                                      baseStyles['base-form-box__body']
                                    }
                                  >
                                    <label
                                      className={baseStyles['base-form-select']}
                                    >
                                      <select
                                        value={cnt}
                                        onChange={(e) => {
                                          updateGuest(
                                            guestType.key,
                                            parseInt(e.target.value, 0)
                                          );
                                        }}
                                      >
                                        {[...Array(101)].map(
                                          (_, idx: number) => {
                                            return (
                                              <option key={idx} value={idx}>
                                                {idx}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </FieldWrapper>
                      </div>
                    )}
                  </>
                )}
              </AccordionItem>

              <AccordionItem
                id="basic-info"
                header={t('2. Basic Information')}
                initialOpen={true}
              >
                {() => (
                  <>
                    <div className={clsx(styles['field'])}>
                      <FormField
                        prompt={t('Application Number')}
                        value={newReservation.agent_reference ?? ''}
                        required={false}
                        onChange={(agent_reference: string) => {
                          setNewReservation({
                            ...newReservation,
                            agent_reference,
                          });
                        }}
                      />
                    </div>
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationBookingSource'
                    ) && (
                      <div className={clsx(styles['field'])}>
                        <FormField
                          prompt={t('Booking Source')}
                          value={
                            newReservation.booking_source?.source_type ?? ''
                          }
                          options={[
                            'DIRECT_WALK_IN',
                            'DIRECT_TELEPHONE',
                            'DIRECT_EMAIL',
                            'AGENT',
                            'OTHER',
                          ].map((a, index) => ({
                            text: t(a),
                            value: a,
                            key: index.toString(),
                          }))}
                          onChange={(source_type: string) => {
                            let newPaymentMethod =
                              newReservation.payment_method;
                            let newAgentName =
                              newReservation.booking_source?.agent_name || '';
                            let newAgentId =
                              newReservation.booking_source?.agent_id || '';

                            if (source_type === 'AGENT') {
                              newPaymentMethod = 'INVOICE';
                            } else {
                              newAgentName = '';
                              newAgentId = '';
                            }

                            setNewReservation({
                              ...newReservation,
                              booking_source: {
                                source_type:
                                  source_type as any as BookingSourceType,
                                agent_name: newAgentName,
                                agent_id: newAgentId,
                              },
                              payment_method: newPaymentMethod,
                            });
                          }}
                        />
                      </div>
                    )}
                    {bookingSourceIsAgent && (
                      <div className={clsx(styles['field'])}>
                        <FormField
                          search={true}
                          placeholder={t(
                            'type an agent name or select from list'
                          )}
                          prompt={t('Agent')}
                          allowFreeInput={true}
                          value={newReservation.booking_source?.agent_id ?? ''}
                          options={contractAgents || []}
                          onChange={(agent_id: string) => {
                            const agent = (contractAgents || []).find(
                              (a) => a.value === agent_id
                            );
                            setNewReservation({
                              ...newReservation,
                              booking_source: {
                                source_type: 'AGENT',
                                agent_id: (agent && agent.value) || '',
                                agent_name: (agent && agent.text) || '',
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                    <div className={clsx(styles['field'])}>
                      <FormField
                        prompt={t('Payment Type')}
                        value={newReservation.payment_type ?? ''}
                        options={paymentTypeOptions.map((ty, index) => ({
                          text: t(ty),
                          value: ty,
                          key: index.toString(),
                        }))}
                        onChange={(payment_type: string) => {
                          let payment_deferred =
                            newReservation.payment_deferred;
                          let email_payment_to_address =
                            newReservation.email_payment_to_address;

                          if (payment_type !== 'PAID_IN_FULL') {
                            payment_deferred = false;
                            email_payment_to_address = '';
                          }

                          // clear amount pob every time payment_type changes
                          const amount_pay_on_board = '';
                          setNewReservation({
                            ...newReservation,
                            payment_type: payment_type as any as PaymentType,
                            amount_pay_on_board,
                            payment_deferred,
                            email_payment_to_address,
                          });
                        }}
                      />
                    </div>
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationPaymentMethod'
                    ) && // 'INVOICE' is the only payment method supported for agents
                      !bookingSourceIsAgent && (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            prompt={t('Payment Method')}
                            value={newReservation.payment_method ?? ''}
                            options={paymentMethodOptions.map((ty, index) => ({
                              text: t(ty),
                              value: ty,
                              key: index.toString(),
                            }))}
                            onChange={(payment_method: string) => {
                              let payment_deferred =
                                newReservation.payment_deferred;
                              let email_payment_to_address =
                                newReservation.email_payment_to_address;

                              if (payment_method !== 'CREDIT_CARD') {
                                payment_deferred = false;
                                email_payment_to_address = '';
                              }

                              setNewReservation({
                                ...newReservation,
                                payment_method:
                                  payment_method as any as PaymentMethod,
                                payment_deferred,
                                email_payment_to_address,
                              });
                            }}
                          />
                        </div>
                      )}
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationPaymentMethod'
                    ) &&
                      !bookingSourceIsAgent &&
                      newReservation.payment_method === 'OTHER' && (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            search={true}
                            allowFreeInput={true}
                            prompt={t('Other Payment Method')}
                            value={newReservation.other_payment_method || ''}
                            options={otherPaymentMethodOptions.map(
                              (ty, index) => ({
                                text: t(ty),
                                value: ty,
                                key: index.toString(),
                              })
                            )}
                            onChange={(other_payment_method: string) => {
                              setNewReservation({
                                ...newReservation,
                                other_payment_method: t(other_payment_method),
                              });
                            }}
                            placeholder={t(
                              'type an payment method of select from list'
                            )}
                          />
                        </div>
                      )}
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationPaymentMethod'
                    ) && (
                      <div className={clsx(styles['field'])}>
                        <Checkbox
                          label={t('Send email with payment link')}
                          checked={Boolean(newReservation.payment_deferred)}
                          onChange={handleTogglePaymentDeferred}
                          disabled={!activatePaymentDeferred}
                        />
                      </div>
                    )}
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationPaymentMethod'
                    ) &&
                      newReservation.payment_deferred && (
                        <div className={clsx(styles['field'])}>
                          <FormField
                            required
                            prompt={t('Payment link email address')}
                            value={
                              newReservation.email_payment_to_address ?? ''
                            }
                            disabled={!activatePaymentDeferred}
                            onChange={(email_payment_to_address: string) => {
                              setNewReservation({
                                ...newReservation,
                                email_payment_to_address,
                              });
                            }}
                            error={errorMap['email_payment_to_address']}
                            autoComplete="off"
                          />
                        </div>
                      )}
                    <div className={clsx(styles['field'])}>
                      <FormField
                        prompt={t('Customer Language')}
                        options={customerLanguageOptions.map((ty, index) => ({
                          text: t(ty.text),
                          value: ty.value,
                          key: index.toString(),
                        }))}
                        onChange={(value: string) => {
                          handleCustomerLanguageChanged(value);
                        }}
                        value={customerLanguage}
                      />
                    </div>

                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationAgentNotes'
                    ) ? (
                      <div className={clsx(styles['field'])}>
                        <FormField
                          prompt={t('Remarks')}
                          value={newReservation.agent_notes ?? ''}
                          onChange={(agent_notes) =>
                            setNewReservation({
                              ...newReservation,
                              agent_notes,
                            })
                          }
                          required={false}
                        />
                      </div>
                    ) : operationAllowed(
                        activeUser,
                        'write',
                        'reservationSupplierNotes'
                      ) ? (
                      <>
                        <div className={clsx(styles['field'])}>
                          <FormField
                            prompt={t('Memo')}
                            value={newReservation.supplier_internal_notes ?? ''}
                            onChange={(supplier_internal_notes: string) =>
                              setNewReservation({
                                ...newReservation,
                                supplier_internal_notes,
                              })
                            }
                            required={false}
                          />
                        </div>
                        <div className={clsx(styles['field'])}>
                          <FormField
                            prompt={t('Internal Note')}
                            value={
                              newReservation.supplier_internal_notes_for_dispatch ??
                              ''
                            }
                            onChange={(
                              supplier_internal_notes_for_dispatch: string
                            ) =>
                              setNewReservation({
                                ...newReservation,
                                supplier_internal_notes_for_dispatch,
                              })
                            }
                            required={false}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </AccordionItem>

              <AccordionItem
                id="transportation"
                header={t('3. Transportation/Checkin')}
                initialOpen={true}
              >
                {() => (
                  <>
                    {transportations.length > 0 && (
                      <div className={clsx(styles['field'])}>
                        <FormField
                          prompt={t('Transportation')}
                          value={newReservation.transportation || 'none'}
                          options={[
                            {
                              value: 'none',
                              text: t('Checkin/Checkout Only'),
                              key: '00',
                            },
                            ...transportations.map((trans, index) => ({
                              value: trans.key ?? '',
                              text:
                                trans.service_type === 'FREE'
                                  ? `${trans.title} (${t('Free')})`
                                  : trans.title,
                              key: index.toString(),
                            })),
                          ]}
                          onChange={handleTransportationChange}
                          placeholder={t('Select transportation...')}
                        />
                      </div>
                    )}
                    <div className={clsx(styles['field'])}>
                      <input
                        style={{
                          display: 'none',
                        }}
                        ref={hotelInputRef}
                      />
                      <LocationSearchInput
                        prompt={t('Customer Hotel')}
                        location={
                          (newReservation.guest_hotel &&
                            newReservation.guest_hotel.location_name) ||
                          ''
                        }
                        onSearchChange={handleHotelLocationNameChanged}
                        onLocationSelect={handleHotelLocationSelected}
                      />
                    </div>
                    {operationAllowed(
                      activeUser,
                      'write',
                      'reservationPickupDropoff'
                    ) ? (
                      !newReservation.transportation ? (
                        <div className={clsx(styles['field'])}>
                          <LocationWithTimeEditFormFields
                            locationNameLabel={t('Checkin Location Name')}
                            locationDescriptionLabel={t(
                              'Checkin Location Description (ex: "Main lobby", "Car park", "Main wing")'
                            )}
                            locationTimeLabel={t('Checkin Time')}
                            locationDateLabel={t('Checkin Date')}
                            location={checkin}
                            productCandidateLocations={[]}
                            startTime={startDate}
                            timeSlotKey={timeSlotKey ?? ''}
                            onLocationChange={(loc: LocationWithMoment) => {
                              setNewReservation({
                                ...newReservation,
                                checkin:
                                  convertLocationWithMomentToReservationLocationWithTimeInput(
                                    loc
                                  ),
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <div className={clsx(styles['field'])}>
                          <div className={clsx(styles['field'])}>
                            <LocationWithTimeEditFormFields
                              locationNameLabel={t('Pickup Location Name')}
                              locationDescriptionLabel={t(
                                'Pickup Location Description (ex: "Main lobby", "Car park", "Main wing")'
                              )}
                              locationTimeLabel={t('Pickup Time')}
                              locationDateLabel={t('Pickup Date')}
                              location={pickup}
                              productCandidateLocations={[]}
                              startTime={startDate}
                              timeSlotKey={timeSlotKey ?? ''}
                              onLocationChange={handlePickupLocationChanged}
                            />
                          </div>

                          <div className={clsx(styles['field'])}>
                            <Checkbox
                              label={t(
                                'Use same location for pickup and dropoff'
                              )}
                              checked={pickupDropoffUseSameLocation}
                              onChange={
                                handleTogglePickupDropoffUseSameLocation
                              }
                            />
                          </div>

                          {!pickupDropoffUseSameLocation && (
                            <div className={clsx(styles['field'])}>
                              <LocationWithTimeEditFormFields
                                locationNameLabel={t('Dropoff Location Name')}
                                locationDescriptionLabel={t(
                                  'Dropoff Location Description (ex: "Main lobby", "Car park", "Main wing")'
                                )}
                                locationTimeLabel={t('Dropoff Time')}
                                locationDateLabel={t('Dropoff Date')}
                                location={dropoff}
                                productCandidateLocations={[]}
                                startTime={startDate}
                                timeSlotKey={timeSlotKey ?? ''}
                                onLocationChange={handleDropoffLocationChanged}
                              />
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      newReservation.transportation && (
                        <div className={clsx(styles['field'])}>
                          <LocationSearchInput
                            prompt={t('Desired Pickup Location')}
                            location={
                              (newReservation.requested_pickup_location &&
                                newReservation.requested_pickup_location
                                  .location_name) ||
                              ''
                            }
                            candidateLocations={[]}
                            onSearchChange={(location_name: string) => {
                              setNewReservation({
                                ...newReservation,
                                requested_pickup_location: {
                                  location_name,
                                },
                              });
                            }}
                            onLocationSelect={({
                              title: location_name,
                              key: google_place_id,
                            }: {
                              title: string;
                              key: string;
                            }) => {
                              setNewReservation({
                                ...newReservation,
                                requested_pickup_location: {
                                  location_name,
                                  google_place_id,
                                },
                              });
                            }}
                          />

                          <LocationSearchInput
                            prompt={t('Desired Dropoff Location')}
                            location={
                              (newReservation.requested_dropoff_location &&
                                newReservation.requested_dropoff_location
                                  .location_name) ||
                              ''
                            }
                            candidateLocations={[]}
                            onSearchChange={(location_name: string) => {
                              setNewReservation({
                                ...newReservation,
                                requested_dropoff_location: {
                                  location_name,
                                },
                              });
                            }}
                            onLocationSelect={({
                              title: location_name,
                              key: google_place_id,
                            }: {
                              title: string;
                              key: string;
                            }) => {
                              setNewReservation({
                                ...newReservation,
                                requested_dropoff_location: {
                                  location_name,
                                  google_place_id,
                                },
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                  </>
                )}
              </AccordionItem>

              <AccordionItem
                id="summary"
                header={t('4. Summary')}
                initialOpen={true}
              >
                {() => (
                  <>
                    {newReservation.guests && (
                      <div className={clsx(styles['fare-summary-table'])}>
                        <table>
                          <tbody>
                            <tr key="1">
                              <th style={{ width: '30%' }}>{t('Title')}</th>
                              <th style={{ width: '35%' }}>{t('Gross')}</th>
                              <th style={{ width: '35%' }}>{t('Net')}</th>
                            </tr>
                            {guestSummary.map((i, idx) => {
                              const title = i.guest_type_key;
                              return (
                                <tr key={`${i.guest_type_key}${idx}`}>
                                  <td>{t(title)}</td>
                                  <td>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                      }}
                                    >
                                      <div>{productCurrency ?? 'USD'}</div>
                                      <div
                                        className={
                                          baseStyles['base-form-box__body']
                                        }
                                      >
                                        <input
                                          value={guestGross[title] || ''}
                                          onChange={(e: any) => {
                                            const v = e.target.value;
                                            if (!helper.inputAllowed(v)) {
                                              return;
                                            }
                                            const moneyInput =
                                              helper.moneyInput(v);
                                            if (
                                              helper.inputInvalid(moneyInput)
                                            ) {
                                              setAmountGrossError(true);
                                            } else {
                                              setAmountGrossError(false);
                                            }
                                            const gross = {
                                              ...guestGross,
                                            };
                                            gross[title] = moneyInput;
                                            setGuestGross({
                                              ...gross,
                                            });
                                            if (
                                              newReservation?.booking_source
                                                ?.source_type !== 'AGENT'
                                            ) {
                                              setGuestNet({
                                                ...guestNet,
                                                ...gross,
                                              });
                                            }
                                          }}
                                          type={'text'}
                                          className={
                                            baseStyles['base-form-text']
                                          }
                                        />
                                        {helper.inputInvalid(
                                          guestGross[title]
                                        ) && (
                                          <p
                                            className={
                                              baseStyles['base-form-box__err']
                                            }
                                          >
                                            {t('Invalid')}
                                          </p>
                                        )}
                                      </div>
                                      <div style={{ paddingRight: '10px' }}>
                                        x
                                        {guestSummary.find(
                                          (g) => g.guest_type_key === title
                                        )?.count || 0}
                                      </div>
                                      <div>
                                        {helper.moneyString(
                                          currency(
                                            guestGross[title] || '0',
                                            activeUserOrganization?.default_currency ??
                                              'USD'
                                          )
                                            .multiply(
                                              guestSummary.find(
                                                (g) =>
                                                  g.guest_type_key === title
                                              )?.count || 0
                                            )
                                            .toString()
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                      }}
                                    >
                                      <div>{productCurrency}</div>
                                      <div
                                        className={
                                          baseStyles['base-form-box__body']
                                        }
                                      >
                                        <input
                                          value={guestNet[title] || ''}
                                          onChange={(e: any) => {
                                            const v = e.target.value;
                                            if (!helper.inputAllowed(v)) {
                                              return;
                                            }
                                            const moneyInput =
                                              helper.moneyInput(v);
                                            const net = {
                                              ...guestNet,
                                            };
                                            net[title] = moneyInput;
                                            setGuestNet({
                                              ...net,
                                            });
                                          }}
                                          type={'text'}
                                          className={
                                            baseStyles['base-form-text']
                                          }
                                          disabled={
                                            newReservation?.booking_source
                                              ?.source_type !== 'AGENT'
                                          }
                                        />
                                        {helper.inputInvalid(
                                          guestNet[title]
                                        ) && (
                                          <p
                                            className={
                                              baseStyles['base-form-box__err']
                                            }
                                          >
                                            {t('Invalid')}
                                          </p>
                                        )}
                                      </div>
                                      <div style={{ paddingRight: '10px' }}>
                                        x
                                        {guestSummary.find(
                                          (g) => g.guest_type_key === title
                                        )?.count || 0}
                                      </div>
                                      <div>
                                        {helper.moneyString(
                                          currency(
                                            guestNet[title] || '0',
                                            activeUserOrganization?.default_currency ??
                                              'USD'
                                          )
                                            .multiply(
                                              guestSummary.find(
                                                (g) =>
                                                  g.guest_type_key === title
                                              )?.count || 0
                                            )
                                            .toString()
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr key={2}>
                              <td>{t('Total')}</td>
                              <td>
                                <p
                                  className={clsx(
                                    styles['fare-summary-table__bold']
                                  )}
                                >
                                  {helper.moneyString(totalGross)}
                                </p>
                              </td>
                              <td>
                                <p
                                  className={clsx(
                                    styles['fare-summary-table__bold']
                                  )}
                                >
                                  {helper.moneyString(totalNet)}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    {newReservation &&
                      newReservation.payment_type === 'PAID_PARTIALLY' && (
                        <div className={clsx(styles['field'])}>
                          <FieldWrapper
                            label={
                              t('Amount to pay on board') +
                              ` (${productCurrency})`
                            }
                            error={errorMap['amount_pay_on_board']}
                          >
                            <MoneyInput
                              currencyCode={productCurrency}
                              moneyAmount={
                                newReservation.amount_pay_on_board || ''
                              }
                              onChange={(amount_pay_on_board: string) => {
                                setNewReservation({
                                  ...newReservation,
                                  amount_pay_on_board,
                                });
                              }}
                            />
                          </FieldWrapper>
                        </div>
                      )}
                  </>
                )}
              </AccordionItem>

              <MessageModal
                title={t('Reservation with PIF (paid in full) by Credit Card')}
                message={t(
                  'Creating a reservation with "PIF (paid in full)" and "Credit card", but no email to request online payment. Are you sure to create a new reservation without online payment by credit card?'
                )}
                open={openNoEmailWithPaymentLinkMessageModal}
                onClose={() => {
                  setOpenNoEmailWithPaymentLinkMessageModal(false);
                }}
                onSubmit={() => {
                  const openMessageModal =
                    Object.keys(currentValidationErrorMap).length === 0;
                  setOpenNoEmailWithPaymentLinkMessageModal(false);
                  setOpenSubmitReservationMessageModal(openMessageModal);
                  setLastSubmissionValidationErrorMap(
                    currentValidationErrorMap
                  );
                  scrollToTop();
                }}
              />
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <SubmitReservationButton
                  reservation={reservationFromState() as Reservation}
                  open={openSubmitReservationMessageModal}
                  onClick={() => {
                    if (
                      needToOpenNoEmailWithPaymentLinkMessageModal(
                        newReservation as any,
                        false
                      )
                    ) {
                      setOpenNoEmailWithPaymentLinkMessageModal(true);
                    } else {
                      const openMessageModal =
                        Object.keys(currentValidationErrorMap).length === 0;
                      setOpenSubmitReservationMessageModal(openMessageModal);
                      setLastSubmissionValidationErrorMap(
                        currentValidationErrorMap
                      );
                      scrollToTop();
                    }
                  }}
                  onSubmit={() => {
                    onSubmit(reservationToSubmit());
                  }}
                  onClose={() => {
                    setOpenSubmitReservationMessageModal(false);
                  }}
                />
              </div>
            </form>
          </div>
          <div className={styles['desktop-only']}>
            <div className={styles['page-sidebar']}>
              <div className={styles['page-sidebar-window']}>
                <div className={styles['page-sidebar-header']}>
                  {t('New Reservation Info')}
                </div>
                <div className={styles['link']}>
                  <a href="#participation">{t('1. Participation')}</a>
                </div>
                <div className={styles['link']}>
                  <a href="#basic-info">{t('2. Basic Information')}</a>
                </div>
                <div className={styles['link']}>
                  <a href="#transportation">{t('3. Transportation/Checkin')}</a>
                </div>
                <div className={styles['link']}>
                  <a href="#summary">{t('4. Summary')}</a>
                </div>
              </div>
              <div className={styles['page-sidebar-window']}>
                <OrderSummary />
              </div>
            </div>
          </div>
          <nav className={baseStyles['base-spNav']}>
            <ul>
              <li>
                <a href="#participation">
                  <img src={oneIcon} />
                </a>
              </li>
              <li>
                <a href="#basic-info">
                  <img src={twoIcon} />
                </a>
              </li>
              <li>
                <a href="#transportation">
                  <img src={threeIcon} />
                </a>
              </li>
              <li>
                <a href="#summary">
                  <img src={fourIcon} />
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setShowBookingSummaryModal(!showBookingSummaryModal);
                  }}
                >
                  <img src={bookingSummaryIcon} />
                </a>
              </li>
            </ul>
          </nav>
          {showBookingSummaryModal && (
            <div
              ref={bookingSummaryPopupRef}
              className={styles['booking-summary-popup']}
            >
              <OrderSummary />
            </div>
          )}
        </div>
      )}
    </>
  );
};
