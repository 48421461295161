export const getBookingWidgetEmbeddedCalendarUrl = (
  apiKey: string | null,
  productId: string,
  calendarDesignType: 'BASIC' | 'BASIC_WITH_PRICES',
  language?: string,
  primaryColor?: string,
  secondaryColor?: string
): string => {
  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  if (bookingWidgetBaseUrl && apiKey) {
    if (productId) {
      const params = new URLSearchParams();

      if (primaryColor) {
        params.set('primary', primaryColor);
      }

      if (secondaryColor) {
        params.set('secondary', secondaryColor);
      }

      if (language) {
        params.set('lng', language);
      }

      let calendarTypePath = '';

      switch (calendarDesignType) {
        case 'BASIC_WITH_PRICES':
          calendarTypePath = 'sideBarCalendarWithPrices';
          break;

        case 'BASIC':
        default:
          calendarTypePath = 'sideBarCalendar';
          break;
      }

      return `${bookingWidgetBaseUrl}/embed/${apiKey}/products/${productId}/${calendarTypePath}?${params.toString()}`;
    }
  }

  return '';
};
