import axios from 'axios';
import moment from 'moment-timezone';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMER_BY_ID_SUCCESS,
  FETCH_CUSTOMER_BY_ID_FAILURE,
  FETCH_CUSTOMER_BY_ID_REQUEST,
  DELETE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_LIST_PAGE_TOKEN,
  UPDATE_CUSTOMER_SEARCH_SETTINGS,
  FETCH_CUSTOMER_TAGS_SUCCESS,
  FETCH_CUSTOMER_TAGS_REQUEST,
  FETCH_CUSTOMER_TAGS_FAILURE,
  FETCH_CUSTOMER_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { CustomerSearchSettings } from 'client/reducers/customers';
import type { NewCustomer, CustomerPatch } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchCustomersRequest = () => ({
  type: FETCH_CUSTOMERS_REQUEST,
});

const fetchCustomersSuccess = (response: any, pageTokens: any) => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  response,
  pageTokens,
});

const fetchCustomersFailure = (error: any) => ({
  type: FETCH_CUSTOMERS_FAILURE,
  error,
});

export const fetchCustomers =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchCustomersRequest());
    const params: Record<string, any> = {
      page_token: getState().customers.pageTokens.currToken,
      page_size: getState().customerListControls.rowCount.toString(),
      order_by: getState().customers.searchSettings.orderBy,
      is_ascending: getState().customers.searchSettings.isAscending,
      registration_date_from:
        getState().customers.searchSettings.registrationDateFrom,
      last_participation_date_from:
        getState().customers.searchSettings.lastParticipationDateFrom,
      last_participation_date_to:
        getState().customers.searchSettings.lastParticipationDateTo,
      email: getState().customers.searchSettings.email,
      product_ids: getState().customers.searchSettings.productIds ?? [],
      tags: getState().customers.searchSettings.tags ?? [],
      only_should_receive_special_email_offers:
        getState().customers.searchSettings.onlyShouldReceiveSpecialEmailOffers,
      only_alerted_customer:
        getState().customers.searchSettings.onlyAlertedCustomer,
      family_name: getState().customers.searchSettings.familyName,
      given_name: getState().customers.searchSettings.givenName,
    };

    const registrationDateTo =
      getState().customers.searchSettings.registrationDateTo;
    if (registrationDateTo) {
      params.registration_date_to = moment(registrationDateTo)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    }

    const lastParticipationDateTo =
      getState().customers.searchSettings.lastParticipationDateTo;
    if (lastParticipationDateTo) {
      params.last_participation_date_to = moment(lastParticipationDateTo)
        .add(1, 'day')
        .format('YYYY-MM-DD');
    }

    const operator =
      getState().customers.searchSettings.reservationCountOperator;

    if (operator) {
      params.reservation_count_operator = operator;
      params.reservation_count =
        getState().customers.searchSettings.reservationCount;
    }

    axios
      .get('/api/customers', {
        params,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        const data = result.data;
        const pageTokens = getState().customers.pageTokens;

        if (data.next_page_token) {
          if (pageTokens.allTokens[pageTokens.currToken].next === null) {
            pageTokens.indexToToken.push(data.next_page_token);
          }

          pageTokens.allTokens[pageTokens.currToken].next =
            data.next_page_token;
        }

        dispatch(fetchCustomersSuccess(data, pageTokens));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchCustomersFailure('canceled'));
        else dispatch(fetchCustomersFailure(error));
      });
  };

const deleteCustomerRequest = (id: string) => ({
  type: DELETE_CUSTOMER_REQUEST,
  id,
});

const deleteCustomerSuccess = (response: any, id: string) => ({
  type: DELETE_CUSTOMER_SUCCESS,
  response,
  id,
});

const deleteCustomerFailure = (error: any) => ({
  type: DELETE_CUSTOMER_FAILURE,
  error,
});

export const deleteCustomer =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteCustomerRequest(id));
    fetch(`/api/customers/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteCustomerSuccess(result, id)))
      .catch((error) => dispatch(deleteCustomerFailure(error)));
  };

const createCustomerRequest = (newCustomer: NewCustomer) => ({
  type: CREATE_CUSTOMER_REQUEST,
  newCustomer,
});

const createCustomerSuccess = (response: any) => ({
  type: CREATE_CUSTOMER_SUCCESS,
  response,
});

const createCustomerFailure = (error: any) => ({
  type: CREATE_CUSTOMER_FAILURE,
  error,
});

export const createCustomer =
  (newCustomer: NewCustomer) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createCustomerRequest(newCustomer));
    return axios
      .post('/api/customers', newCustomer, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createCustomerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createCustomerFailure(err.message));
      });
  };

const updateCustomerRequest = (id: string, patch: CustomerPatch) => ({
  type: UPDATE_CUSTOMER_REQUEST,
  id,
  patch,
});

const updateCustomerSuccess = (response: any) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  response,
});

const updateCustomerFailure = (error: any) => ({
  type: UPDATE_CUSTOMER_FAILURE,
  error,
});

export const updateCustomer =
  (id: string, patch: CustomerPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateCustomerRequest(id, patch));
    return axios
      .patch(`/api/customers/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateCustomerSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateCustomerFailure(err.message));
      });
  };

const fetchCustomerByIDRequest = (id: string) => ({
  type: FETCH_CUSTOMER_BY_ID_REQUEST,
  id,
});

const fetchCustomerByIDSuccess = (response: any) => ({
  type: FETCH_CUSTOMER_BY_ID_SUCCESS,
  response,
});

const fetchCustomerByIDFailure = (error: any) => ({
  type: FETCH_CUSTOMER_BY_ID_FAILURE,
  error,
});

let fetchCustomerByIDCancel: () => void | typeof undefined;
export const fetchCustomerByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomerByIDCancel && fetchCustomerByIDCancel();
    dispatch(fetchCustomerByIDRequest(id));
    axios
      .get(`/api/customers/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomerByIDCancel = c;
        }),
      })
      .then((result) => dispatch(fetchCustomerByIDSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchCustomerByIDFailure('canceled'));
        else dispatch(fetchCustomerByIDFailure(error));
      });
  };

export const updateCustomerListPageTokens = (
  pageTokens: Record<string, any>
) => ({
  type: UPDATE_CUSTOMER_LIST_PAGE_TOKEN,
  pageTokens,
});

export const updateCustomerSearchSettings = (
  searchSettings: CustomerSearchSettings
) => ({
  type: UPDATE_CUSTOMER_SEARCH_SETTINGS,
  searchSettings,
});

const fetchCustomerTagsRequest = () => ({
  type: FETCH_CUSTOMER_TAGS_REQUEST,
});

const fetchCustomerTagsSuccess = (response: any) => ({
  type: FETCH_CUSTOMER_TAGS_SUCCESS,
  response,
});

const fetchCustomerTagsFailure = (error: any) => ({
  type: FETCH_CUSTOMER_TAGS_FAILURE,
  error,
});

export const fetchCustomerTags =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchCustomerTagsRequest());

    axios
      .get('/api/customers/tags', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        const data = result.data;

        dispatch(fetchCustomerTagsSuccess(data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchCustomerTagsFailure('canceled'));
        else dispatch(fetchCustomerTagsFailure(error));
      });
  };

const fetchCustomerReportDataRequest = () => ({
  type: FETCH_CUSTOMER_REPORT_DATA_REQUEST,
});

const fetchCustomerReportDataSuccess = (response: any) => ({
  type: FETCH_CUSTOMER_REPORT_DATA_SUCCESS,
  response,
});

const fetchCustomerReportDataFailure = (error: any) => ({
  type: FETCH_CUSTOMER_REPORT_DATA_FAILURE,
  error,
});

let fetchCustomerReportDataCancel: () => void | typeof undefined;
export const fetchCustomerReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomerReportDataCancel && fetchCustomerReportDataCancel();
    dispatch(fetchCustomerReportDataRequest());

    axios
      .get('/api/customers/reportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomerReportDataCancel = c;
        }),
      })
      .then((result) => dispatch(fetchCustomerReportDataSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchCustomerReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchCustomerReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
