import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { fetchMarketingAutomationCampaign } from 'client/actions/marketingAutomationCampaigns';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Input, MultiSelect } from 'client/components/Form';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { TranslateFuncType } from 'client/components/Translate';
import { ReduxState } from 'client/reducers';
import { formattedPercent } from 'client/libraries/util/formattedPercent';
import { MarketingAutomationCampaignCustomerRecord } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { Loading } from 'client/components/v3/Common/Loading';

const getActionText = (
  action: MarketingAutomationCampaignCustomerRecord['campaign_status'],
  t: TranslateFuncType
) => {
  switch (action) {
    case 'DELIVERED':
      return t('Delivered');
    case 'BOUNCED':
      return t('Bounced');
    case 'OPENED':
      return t('Opened');
    case 'CLICKED':
      return t('Clicked');
    case 'BOOKED':
      return t('Booked');
  }

  return '-';
};

export const MarketingAutomationCampaignReport = () => {
  const { id } = useParams<{ id: string }>();
  const [actionFilters, setActionFilters] = React.useState<string[]>([]);
  const [nameEmailFilter, setNameEmailFilter] = React.useState<string>('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.loading
  );

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationCampaign(id));
  }, [id]);

  const campaign = useSelector((state: ReduxState) =>
    state.marketingAutomationCampaigns.all.find((n) => n.id === id)
  );
  const customerRecords = campaign?.customer_records ?? [];

  const actionFilterOptions = [
    'DELIVERED',
    'BOUNCED',
    'OPENED',
    'CLICKED',
    'BOOKED',
  ].map((action) => ({
    value: action,
    text: getActionText(
      action as MarketingAutomationCampaignCustomerRecord['campaign_status'],
      t
    ),
  }));
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const columns: ColumnType<MarketingAutomationCampaignCustomerRecord>[] =
    React.useMemo(
      () => [
        {
          Header: t('Name'),
          accessor: (customer) => customer.customer_name,
          id: 'name',
        },
        {
          Header: t('Email'),
          accessor: (customer) => customer.customer_email,
          id: 'email',
        },
        {
          Header: t('Action'),
          accessor: (customer) => getActionText(customer.campaign_status, t),
          id: 'campaign_status',
        },
      ],
      [t]
    );

  const filteredCustomers = customerRecords.filter((customer) => {
    return (
      (actionFilters.length === 0 ||
        actionFilters.includes(customer.campaign_status ?? '')) &&
      (!nameEmailFilter ||
        customer.customer_name
          ?.toLowerCase()
          ?.includes(nameEmailFilter.toLowerCase()) ||
        customer.customer_email
          ?.toLowerCase()
          ?.includes(nameEmailFilter.toLowerCase()))
    );
  });

  const targetCount = campaign?.targeted_count ?? 0;
  const deliveredCount = campaign?.delivered_count ?? 0;
  const openedCount = campaign?.opened_count ?? 0;
  const clickedCount = campaign?.clicked_count ?? 0;
  const bookedCount = campaign?.booked_count ?? 0;
  const bouncedCount = campaign?.bounced_count ?? 0;

  return (
    <>
      <Link to="/marketingautomation/campaigns">
        <BackArrow />
      </Link>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <FormTableBox header={t('Summary')}>
            <table>
              <tbody>
                <tr>
                  <th>{t('Last Updated Date')}</th>
                  <td>
                    {campaign?.last_updated_date_time_utc
                      ? moment(campaign.last_updated_date_time_utc)
                          .locale(locale)
                          .format('lll')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <th>{t('Title')}</th>
                  <td>{campaign?.title}</td>
                </tr>
                <tr>
                  <th>{t('Targets')}</th>
                  <td>{targetCount ?? '-'}</td>
                </tr>
                <tr>
                  <th>{t('Deliveries')}</th>
                  <td>{`${deliveredCount} (${formattedPercent(
                    deliveredCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Opens')}</th>
                  <td>{`${openedCount} (${formattedPercent(
                    openedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Clicks')}</th>
                  <td>{`${clickedCount} (${formattedPercent(
                    clickedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Bookings')}</th>
                  <td>{`${bookedCount} (${formattedPercent(
                    bookedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Bounces')}</th>
                  <td>{`${bouncedCount} (${formattedPercent(
                    bouncedCount,
                    targetCount
                  )})`}</td>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
        </div>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <div className={baseStyles['base-subttl']}>{t('Targets')}</div>
          <Box ml={-1} mb={2} display="flex" flexWrap="wrap">
            <Box ml={2} minWidth="300px">
              <MultiSelect
                label={t('Filter by actions')}
                options={actionFilterOptions}
                selectedValues={actionFilters}
                onChange={({ value }) => setActionFilters(value)}
              />
            </Box>
            <Box ml={2} minWidth="300px">
              <Input
                label={t('Filter by name/email')}
                value={nameEmailFilter}
                onChange={(e, { value }) => setNameEmailFilter(value)}
              />
            </Box>
          </Box>
          {loading ? (
            <Loading size="md" />
          ) : (
            <PagedGenericTable allItems={filteredCustomers} columns={columns} />
          )}
        </div>
      </div>
    </>
  );
};
