import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState, localeSelector } from 'client/reducers';
import { currency } from 'shared/libraries/currency';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { QuantityGadget } from '../../components/v3/DashboardGadgets/QuantityGadget';
import { ProgressBarGadget } from '../../components/v3/DashboardGadgets/ProgressBarGadget';

import styles from './Gadgets.module.css';
import { LeadTimeGadget } from './LeadTimeGadget';
import { BookingsGadget } from './BookingsGadget';
import { GraphGadget } from './GraphGadget';

export const Gadgets = () => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);
  const org = useSelector(activeUserOrganizationSelector);

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.reportData
  );

  const eventDataLoading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );

  const baseDataSet = reportDataSets[0];
  const baseTotals = baseDataSet?.totals;

  const baseGrossSales = baseTotals?.gross_sales ?? '';
  const baseConversions = baseTotals?.conversion_count ?? 0;
  const basePageViews = baseTotals?.page_view_count ?? 0;
  const baseSessions = baseTotals?.session_count ?? 0;
  const baseUniqueUsers = baseTotals?.unique_user_count ?? 0;
  const baseConversionRate =
    baseSessions > 0 ? (100 * baseConversions) / baseSessions : 0;
  const baseSessionsPerUser =
    baseUniqueUsers > 0 ? baseSessions / baseUniqueUsers : 0;
  const baseAddToCartCount = baseTotals?.add_to_cart_count ?? 0;
  const baseAddToCartRate =
    baseSessions > 0 ? (100 * baseAddToCartCount) / baseSessions : 0;
  const baseLeadTimeInMinutes = baseTotals?.total_lead_time_in_minutes ?? 0;
  const baseLeadTimeInDays = baseTotals?.conversion_count
    ? baseLeadTimeInMinutes / baseTotals?.conversion_count / (60 * 24)
    : 0;

  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;
  const comparisonTotals = comparisonDataSet?.totals;

  const comparisonGrossSales = comparisonTotals?.gross_sales ?? '';
  const comparisonConversions = comparisonTotals?.conversion_count ?? 0;
  const comparisonPageViews = comparisonTotals?.page_view_count ?? 0;
  const comparisonSessions = comparisonTotals?.session_count ?? 0;
  const comparisonUniqueUsers = comparisonTotals?.unique_user_count ?? 0;
  const comparisonConversionRate =
    comparisonSessions > 0
      ? (100 * comparisonConversions) / comparisonSessions
      : 0;
  const comparisonSessionsPerUser =
    comparisonUniqueUsers > 0 ? comparisonSessions / comparisonUniqueUsers : 0;
  const comparisonAddToCartCount = comparisonTotals?.add_to_cart_count ?? 0;
  const comparisonAddToCartRate =
    comparisonSessions > 0
      ? (100 * comparisonAddToCartCount) / comparisonSessions
      : 0;
  const comparisonLeadTimeInMinutes =
    comparisonTotals?.total_lead_time_in_minutes ?? 0;
  const comparisonLeadTimeInDays = comparisonTotals?.conversion_count
    ? comparisonLeadTimeInMinutes /
      comparisonTotals?.conversion_count /
      (60 * 24)
    : 0;

  const formattedCurrency =
    org?.default_currency === 'JPY' && locale === 'ja'
      ? '円'
      : org?.default_currency || '';

  return (
    <div className={styles['container']}>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Sales')}
          unit={formattedCurrency}
          amount={currency(baseGrossSales ?? '').value}
          changeAmount={
            comparisonDataSet
              ? currency(baseGrossSales ?? '').subtract(
                  currency(comparisonGrossSales ?? '')
                ).value
              : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Conversions')}
          unit={locale === 'ja' ? '件' : ''}
          amount={baseConversions}
          changeAmount={
            comparisonDataSet ? baseConversions - comparisonConversions : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Page Views')}
          unit={locale === 'ja' ? '件' : ''}
          amount={basePageViews}
          changeAmount={
            comparisonDataSet ? basePageViews - comparisonPageViews : null
          }
          loading={eventDataLoading}
        />
      </div>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Sessions')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseSessions}
          changeAmount={
            comparisonDataSet ? baseSessions - comparisonSessions : null
          }
          loading={eventDataLoading}
        />
        <ProgressBarGadget
          header={t('Conversion Rate')}
          percent={baseConversionRate}
          percentChange={
            comparisonDataSet
              ? baseConversionRate - comparisonConversionRate
              : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Average Session Count')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseSessionsPerUser}
          changeAmount={
            comparisonDataSet
              ? baseSessionsPerUser - comparisonSessionsPerUser
              : null
          }
          loading={eventDataLoading}
        />
      </div>
      <div className={styles['container-row']}>
        <ProgressBarGadget
          header={t('Add-to-Cart Rate')}
          percent={baseAddToCartRate}
          percentChange={
            comparisonDataSet
              ? baseAddToCartRate - comparisonAddToCartRate
              : null
          }
          loading={eventDataLoading}
        />
        <LeadTimeGadget
          baseLeadTimeInDays={baseLeadTimeInDays}
          comparisonLeadTimeInDays={
            comparisonDataSet ? comparisonLeadTimeInDays : null
          }
          loading={eventDataLoading}
        />
        <BookingsGadget />
      </div>
      <div className={styles['container-row']}>
        <GraphGadget />
      </div>
    </div>
  );
};
