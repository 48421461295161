import * as React from 'react';
import { Form } from 'semantic-ui-react';

type Props<T extends string> = {
  label?: string;
  options: {
    value: T;
    text: string;
  }[];
  value: T[];
  onChange: (arg0: T[]) => void;
};
export const MultiselectListInput = <T extends string>({
  label,
  value,
  onChange,
  options,
}: Props<T>) => {
  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Form.Group>
        {options.map((opt) => (
          <Form.Checkbox
            key={opt.value}
            label={opt.text}
            checked={value.indexOf(opt.value) !== -1}
            onChange={() => {
              if (value.indexOf(opt.value) === -1) {
                onChange([...value, opt.value]);
              } else {
                onChange(value.filter((elem) => elem !== opt.value));
              }
            }}
          />
        ))}
      </Form.Group>
    </Form.Field>
  );
};
