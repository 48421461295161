import { useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from 'axios';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';

import { CustomerDataParams } from './type';

const fetcher = (url: string, paramsString: string, headerString: string) =>
  axios
    .get(url, {
      params: {
        ...JSON.parse(paramsString),
        page_size: 10000,
      },
      headers: JSON.parse(headerString),
    })
    .then((res) => res.data ?? []);

export const useCustomerData = (
  params: CustomerDataParams
): {
  data: Swagger.Customer[] | null;
  total: number;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToRequest(params);

  const { data, error } = useSWR<Swagger.Customer[]>(
    [`/api/customers`, JSON.stringify(queryParams), JSON.stringify(headers)],
    fetcher
  );
  return {
    data: (data as any)?.customers ?? null,
    total: (data as any)?.total_size ?? 0,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const convertParamsToRequest = (params: CustomerDataParams) => {
  return {
    //We don't need to filter customers data based on registration date because cumulative customers data is what we are looking for instead
    supplier_id: params.supplierId,
  };
};
