import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { fetchSurveySubmission } from 'client/actions/surveySubmissions';
import { ReduxState } from 'client/reducers';
import { ModalLoader } from 'client/components/ModalLoader';

import { SubmittedSurveyAnswers } from './SubmittedSurveyAnswers';

interface Props {
  surveySubmissionId: string;
  onClose: () => void;
}

export const SubmittedSurveyAnswersModal = ({
  surveySubmissionId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const surveySubmission = useSelector(
    (state: ReduxState) => state.surveySubmissions.survey
  );
  const loading = useSelector(
    (state: ReduxState) => state.surveySubmissions.loading
  );

  React.useEffect(() => {
    dispatch(fetchSurveySubmission(surveySubmissionId));
  }, [surveySubmissionId]);

  return (
    <Modal onClose={onClose} open={true} title={t('Responses')} insertRoot>
      <Modal.Content>
        {loading && <ModalLoader />}
        {!loading && surveySubmission && (
          <SubmittedSurveyAnswers surveySubmission={surveySubmission} />
        )}
      </Modal.Content>
    </Modal>
  );
};
