import * as React from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';

import { FieldWrapper, Button, Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDispatchCrewMembers } from 'client/actions/dispatchSettings';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import { fetchProducts } from 'client/actions/products';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import {
  FormValues,
  validateRange,
  getScheduleText,
} from 'client/libraries/util/resourceManager';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

import {
  getInitialValues,
  convertFormValuesToDispatchResources,
} from './formValues';
import styles from './CrewAvailabilityModal.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  resourceKey: string;
}

export const CrewAvailabilityModal = ({
  open,
  onClose,
  onOpen,
  resourceKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('Edit availability for {{resourceName}}', {
    resourceName: resourceKey,
  });
  const resources = useSelector(allDispatchCrewMembersSelector);

  const resource = resources.find((r) => r.key === resourceKey);

  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      onOpen={() => {
        onOpen && onOpen();
      }}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          const err = validateRange(values, t);
          if (err) {
            return err;
          }

          try {
            await dispatch(
              updateDispatchCrewMembers(
                convertFormValuesToDispatchResources(
                  values,
                  resourceKey,
                  resources
                )
              )
            );
            onClose && onClose();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={getInitialValues(resource ?? null)}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <FieldArray name="availabilitySchedules">
                    {({ fields }) => (
                      <FieldWrapper label={t('Resource Availability')}>
                        <div className={styles['page-productsEdit__select']}>
                          <div
                            className={styles['page-productsEdit__select__box']}
                          >
                            <label
                              className={clsx(baseStyles['base-form-select'])}
                            >
                              <select
                                value={activeScheduleIndex}
                                onChange={(e) => {
                                  setActiveScheduleIndex(
                                    parseInt(e.target.value)
                                  );
                                }}
                              >
                                {fields.value.map((priceRule, idx) => (
                                  <option value={idx} key={idx}>
                                    {getScheduleText(priceRule, t)}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <a
                              className={clsx(
                                baseStyles['base-btn'],
                                baseStyles['small'],
                                baseStyles['green']
                              )}
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            >
                              {t('Add resource availability')}
                            </a>
                          </div>
                        </div>

                        <div
                          key={activeScheduleIndex}
                          className={baseStyles['base-modalSelectFrame']}
                        >
                          <div
                            className={baseStyles['base-selectFrame__header']}
                          >
                            <p
                              className={
                                baseStyles['base-selectFrame__header__ttl']
                              }
                            >
                              {t('Selected Resource Availability')}
                            </p>
                            {fields.value.length > 1 && (
                              <a
                                className={
                                  baseStyles['base-selectFrame__header__delete']
                                }
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                              >
                                {t('Remove this resource availability')}
                              </a>
                            )}
                          </div>
                          <div className={baseStyles['base-selectFrame__body']}>
                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Date Range')}
                              </p>
                              <div
                                className={
                                  styles['page-productsRegist__date__range']
                                }
                              >
                                <div className={baseStyles['base-form-range']}>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                  <p>-</p>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Product')}
                              </p>
                              <ProductCapacities
                                name={`${fields.name}.${activeScheduleIndex}.productCapacities`}
                                scheduleIndex={activeScheduleIndex}
                              />
                            </div>
                          </div>
                        </div>
                      </FieldWrapper>
                    )}
                  </FieldArray>
                </Modal.Box>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
              </Modal.Content>

              <Modal.Actions>
                <Button.Cancel
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  {t('Discard')}
                </Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

const ProductCapacities = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const formState = useFormState();

  const excludeProductIds = React.useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.addOns?.map(
      (addOn: any) => addOn.productId
    );
  }, [scheduleIndex, formState.values]);

  const candidateProducts = React.useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });

  return (
    <div className={baseStyles['base-form-box']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div key={name} className={styles['addon__item']}>
                <Field name={`${name}.productId`} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <div className={styles['addon__item__selector']}>
                      <Select
                        search={true}
                        options={(candidateProducts ?? [])
                          .filter(
                            (p) =>
                              !fields.value
                                .map((v) => v.productId)
                                .includes(p.id) || p.id === inputProductId.value
                          )
                          .map((p) => ({
                            value: p.id,
                            text: getVerboseDisplayProductName(p),
                          }))}
                        value={inputProductId.value ?? ''}
                        onChange={(_, { value }) => {
                          inputProductId.onChange(value);
                        }}
                      />
                    </div>
                  )}
                </Field>
                <Field name={`${name}.capacity`} key={`${idx}-capacity`}>
                  {({ input: inputCapacity }) => (
                    <Select
                      options={[...nums]}
                      value={inputCapacity.value}
                      onChange={(_, { value }) => inputCapacity.onChange(value)}
                    />
                  )}
                </Field>
                <DeleteIcon onClick={() => fields.remove(idx)} />
              </div>
            ))}

            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  fields.push('');
                }}
              >
                {t('Add')}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};
