// @flow

import * as React from 'react';
import { Button, Form, Icon, Modal, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import {
  cancelReservation,
  fetchReservationByID,
} from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import { ModalLoader } from 'client/components/ModalLoader';
import { getStartTime } from 'client/libraries/util/coreutil';

type OwnProps = {
  t: TranslateFuncType,
  locale: string,
  onDeclineCompleted?: () => void,
  fetchReservationByID: (string) => void,
  reservationID: string,
  reservation?: Reservation,
  loading: boolean,
  withdrawReservation: (string) => Promise<Object>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
};

class WithdrawReservationButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }
  };

  render() {
    const {
      loading,
      onDeclineCompleted,
      reservation,
      reservationID,
      locale,
      t,
    } = this.props;

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];

    const startTime = reservation && getStartTime(reservation).locale(locale);

    return (
      <Modal
        trigger={
          <Button
            icon
            basic
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          >
            <Icon name="cancel" color="red" />
            {t('Withdraw')}
          </Button>
        }
        open={this.state.showModal}
        onOpen={this.fetchReservation}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
      >
        <Header icon="cancel" content={t('Withdraw reservation')} />
        <Modal.Content>
          {loading ? (
            <ModalLoader />
          ) : (
            <Form>
              <div className="field">
                <label>{t('Product')}</label>
                {productName}
              </div>
              <div className="field">
                <label>{t('Start time')}</label>
                {moment(startTime).format('lll')}
              </div>
              <div className="field">
                <label>{t('Total participants')}</label>
                {guests.length}
              </div>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            inverted
            onClick={() => {
              this.props
                .withdrawReservation(reservationID)
                .then(() => onDeclineCompleted && onDeclineCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            <Icon name="cancel" /> {t('Withdraw')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  loading: state.reservations.loading,
  reservation: state.reservations.byID[ownProps.reservationID],
  locale: state.language.selected.iso,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  withdrawReservation: (id: string) => dispatch(cancelReservation(id)),
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
});

export const WithdrawReservationButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(WithdrawReservationButtonComponent);
