import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_LAST_MANIFEST_DISPLAY_SETTINGS,
} from 'client/constants/ActionTypes';
import {
  initialSettings,
  ManifestDisplaySettings,
} from 'client/libraries/util/manifestDisplaySettings';

const lastManifestDisplaySettings = (
  state: ManifestDisplaySettings = initialSettings,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_MANIFEST_DISPLAY_SETTINGS:
      return action.manifestDisplaySettings;

    default:
      return state;
  }
};

type State = {
  lastManifestDisplaySettings: ManifestDisplaySettings;
};

const reducer = combineReducers<State>({
  lastManifestDisplaySettings,
});
export const manifestDisplaySettings = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
