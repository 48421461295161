import type { Product } from 'shared/models/swagger';
// getProductCurrencyCode returns the currency code from the first gross price we find.
export const getProductCurrencyCode = (product: Product): string => {
  for (const priceSched of product.pricing || []) {
    for (const unit of priceSched.units) {
      if (unit.gross && unit.gross.length > 3) {
        return unit.gross.substring(0, 3);
      }
    }
  }

  return '';
};
