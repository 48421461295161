import * as React from 'react';
import { useSelector } from 'react-redux';

import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { getProductSalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { activeUserSelector } from 'client/reducers/user';
import {
  summariesWithBookmarksSelector,
  ProductSummaryWithBookmark,
} from 'client/reducers/products';
import {
  CustomTableColumn,
  CustomTable,
} from 'client/pages/v3/Product/ProductList/ProductListTable/CustomTable';

type FilterColumn =
  | 'product_name'
  | 'internal_product_name'
  | 'supplier_name'
  | 'supplier_reference'
  | 'product_tags'
  | 'internal_product_tags';

const filterProducts = (
  inputText: string,
  columns: FilterColumn[],
  productSummaries: ProductSummaryWithBookmark[]
): ProductSummaryWithBookmark[] => {
  if (!inputText) {
    return productSummaries;
  }
  return productSummaries.filter((productSummary) => {
    return inputText.split(/\s+/).every((input) => {
      return !!columns.find((column) => {
        if (!productSummary[column]) {
          return false;
        }
        if (typeof productSummary[column] === 'string') {
          return ((productSummary?.[column] ?? '') as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        if (typeof productSummary[column] === 'object') {
          return !!((productSummary?.[column] ?? []) as string[]).find(
            (element) => {
              return element.toLowerCase().includes(input.toLowerCase());
            }
          );
        }
        return false;
      });
    });
  });
};

interface Props {
  filterText: string;
  columns: CustomTableColumn[];
  activeTab?: SalesStatus;
  tableHeaderRef: React.RefObject<HTMLDivElement>;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

export const ProductListTable = ({
  filterText,
  columns,
  activeTab,
  tableHeaderRef,
  tableBodyRef,
}: Props) => {
  const allProducts: ProductSummaryWithBookmark[] = useSelector(
    summariesWithBookmarksSelector
  );
  const activeUser = useSelector(activeUserSelector);

  const items = React.useMemo(() => {
    let salesStatusProducts;
    if (activeTab != null) {
      salesStatusProducts = allProducts.filter((product) => {
        return getProductSalesStatus(product) === activeTab;
      });
    } else {
      salesStatusProducts = allProducts;
    }

    const filterTarget: FilterColumn[] =
      activeUser?.organization_type === 'AGENT'
        ? ['product_name', 'supplier_name']
        : [
            'product_name',
            'internal_product_name',
            'supplier_reference',
            'product_tags',
            'internal_product_tags',
          ];

    return filterProducts(filterText, filterTarget, salesStatusProducts);
  }, [filterText, activeUser, allProducts, activeTab]);

  return (
    <CustomTable
      columns={columns}
      items={items}
      tableHeaderRef={tableHeaderRef}
      tableBodyRef={tableBodyRef}
    />
  );
};
