import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { TranslateFuncType } from 'client/components/Translate';
import { Inquiry, InquiryStatus } from 'shared/models/swagger';
import { InquiryItem } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/InquiryItem';
import newStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Badge } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';

const getSmsInquiryStatus = (
  inquiry: Inquiry,
  t: TranslateFuncType
): string => {
  if (inquiry.status === 'ARCHIVED') {
    return t('Archived');
  }

  return (
    inquiry.messages?.[inquiry.messages?.length - 1]?.sms_info?.sms_status ??
    'unknown'
  );
};

type Props = {
  inquiry: Inquiry;
};
export const InquiryAccordionItem = ({ inquiry }: Props) => {
  const { t } = useTranslation();

  const initialOpen = inquiry.status !== 'ARCHIVED';
  const [open, setOpen] = useState<boolean>(initialOpen ?? false);

  const getStatusText = (status?: InquiryStatus): string => {
    switch (status) {
      case 'RECEIVED':
        return t('Received');
      case 'RESPONDED':
        return t('Responded');
      case 'ARCHIVED':
        return t('Archived');
      case 'SENT':
        return t('Sent');
    }

    return t('Completed');
  };

  const header =
    inquiry.initial_communication_type === 'SMS_BY_SUPPLIER'
      ? `SMS ${formattedPhone(inquiry.customer_phone || '')}`
      : `${inquiry.subject}`;

  const inquiryStatus =
    inquiry.initial_communication_type === 'SMS_BY_SUPPLIER'
      ? getSmsInquiryStatus(inquiry, t)
      : getStatusText(inquiry.status);

  return (
    <li>
      <div className={newStyles['p-contactCard']}>
        <div className={newStyles['p-contactCard__header']}>
          <div className={newStyles['p-contactCard__header__info']}>
            <p className={newStyles['p-contactCard__header__info__ttl']}>
              {header}
            </p>
            <ul className={newStyles['p-contactCard__header__info__tab']}>
              <Badge
                size="lg"
                label={inquiryStatus}
                color={inquiry.status != 'ARCHIVED' ? 'primary' : 'gray'}
              />
            </ul>
          </div>
          <div className={newStyles['p-contactCard__header__toggle']}>
            <Button
              iconBeforeText={
                open ? (
                  <i className="c-icon-outline-general-minus"></i>
                ) : (
                  <i className="c-icon-outline-general-plus"></i>
                )
              }
              onClick={() => setOpen(!open)}
              text={open ? t('Hide') : t('Show')}
              color="tertiarygray"
            />
          </div>
        </div>

        <div
          className={clsx(
            newStyles['p-contactCard__body'],
            open && newStyles['is-active']
          )}
        >
          <InquiryItem item={inquiry} />
        </div>
      </div>
    </li>
  );
};
