import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  EquipmentAssignment,
  EquipmentAssignmentOperation,
} from 'shared/models/swagger';
import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { Button } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import { bulkOperateEquipmentAssignments } from 'client/actions/equipmentAssignments';
import { addEquipmentNotification } from 'client/actions/equipmentNotifications';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { SeatAssignmentContext } from './SeatAssignmentContext';
import { SeatMapHeaderInternalMemo } from './SeatMapHeaderInternalMemo';
import {
  getEquipmentBlockReference,
  getEquipmentBlockInstanceProperty,
} from './utils';
import styles from './SeatAssignment.module.css';

interface Props {
  selectedEquipmentInstanceId: string;
  selectedAssignableEquipmentInstanceId: string;
  filteredEquipmentAssignments?: EquipmentAssignment[];
  selectedReservationId?: string;
  draggingReservationId?: string;
  selectedEquipmentBlockInstanceKeys: {
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  }[];

  onEditEquipmentInstanceButtonClick: () => void;
  onAssignEquipmentInstanceIdChange: (id: string) => void;
  onNewReservationClick?: () => void;
  onReset?: () => void;

  equipmentLoading?: boolean;
  equipmentInstanceLoading?: boolean;

  showOnlySelectedEquipmentInstanceInSelector?: boolean;

  setShowEditEquipmentInstanceChannelModal?: (show: boolean) => void;

  keepShowingEquipmentInstanceSelector?: boolean;
  hideEquipmentInstanceCloseButton?: boolean;
  hideEquipmentInstanceEditButton?: boolean;
}

interface Option {
  value: string;
  text: string;
}

export const SeatMapHeader = ({
  selectedEquipmentInstanceId,
  selectedAssignableEquipmentInstanceId,
  selectedReservationId,
  draggingReservationId,
  selectedEquipmentBlockInstanceKeys,

  onEditEquipmentInstanceButtonClick,
  onAssignEquipmentInstanceIdChange,
  onNewReservationClick,
  onReset,

  showOnlySelectedEquipmentInstanceInSelector = false,
  setShowEditEquipmentInstanceChannelModal,
  keepShowingEquipmentInstanceSelector,
  hideEquipmentInstanceCloseButton,
  hideEquipmentInstanceEditButton,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const { editMode } = React.useContext(SeatAssignmentContext);
  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const selectedReservation = React.useMemo(() => {
    return allReservations.find(
      (reservation) => reservation.id === selectedReservationId
    );
  }, [allReservations, selectedReservationId]);

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (equipmentInstance) =>
        equipmentInstance.id === selectedEquipmentInstanceId
    );
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const equipmentInstanceOptions = React.useMemo(() => {
    const options: Option[] = [];

    if (!selectedEquipmentInstance) {
      return options;
    }

    const selectedEquipment = equipments.find(
      (equipment) => equipment.id === selectedEquipmentInstance.equipment_id
    );

    options.push({
      value: selectedEquipmentInstance.id || '',
      text: selectedEquipment?.title || '',
    });

    if (!showOnlySelectedEquipmentInstanceInSelector) {
      selectedEquipmentInstance.additional_equipment_settings?.additional_equipment_ids?.forEach(
        (additionalEquipmentId) => {
          const additionalEquipmentInstance = equipmentInstances.find(
            (equipmentInstance) =>
              equipmentInstance.equipment_id === additionalEquipmentId &&
              equipmentInstance.original_equipment_instance_id ===
                selectedEquipmentInstance.id
          );

          const additionalEquipment = equipments.find(
            (equipment) => equipment.id === additionalEquipmentId
          );
          if (additionalEquipmentInstance) {
            options.push({
              value: additionalEquipmentInstance?.id || '',
              text: additionalEquipment?.title || '',
            });
          }
        }
      );
    }
    return options;
  }, [selectedEquipmentInstance, equipmentInstances]);

  React.useEffect(() => {
    if (equipmentInstanceOptions.length > 0) {
      if (
        equipmentInstanceOptions?.every(
          (e) => e.value !== selectedAssignableEquipmentInstanceId
        )
      ) {
        onAssignEquipmentInstanceIdChange(equipmentInstanceOptions[0].value);
      }
    } else {
      onAssignEquipmentInstanceIdChange('');
    }
  }, [equipmentInstanceOptions]);

  const bulkOperateHander = async () => {
    const operations: EquipmentAssignmentOperation[] =
      selectedEquipmentBlockInstanceKeys.map((key, idx) => {
        const equipmentInstance = equipmentInstances.find(
          (equipmentInstance) =>
            equipmentInstance.id === key.equipmentInstanceId
        );

        const equipment = equipments.find(
          (equipment) => equipment.id === equipmentInstance?.equipment_id
        );

        const property = getEquipmentBlockInstanceProperty(
          key.equipmentBlockInstanceKey,
          equipment,
          equipmentInstance,
          activeUserOrganization
        );

        return {
          operation_type: 'ASSIGN',
          equipment_id: equipment?.id || '',
          date: equipmentInstance?.date || '',
          start_time_key: equipmentInstance?.start_time_key || '',
          equipment_block_instance_key: key.equipmentBlockInstanceKey,
          equipment_block_reference:
            property?.reference ||
            getEquipmentBlockReference(
              key.equipmentBlockInstanceKey,
              equipment
            ),
          occupation: {
            guest_type_counts: [
              {
                guest_type_key: selectedReservation?.guests[idx].guest_type_key,
                count: 1,
              },
            ],
            count: 1,
          },
          reservation_id: selectedReservation?.id || '',
          product_instance_id: selectedReservation?.product_instance_id || '',
          equipment_instance_id: equipmentInstance?.id || '',
          original_equipment_instance_id:
            equipmentInstance?.original_equipment_instance_id || '',
        };
      });

    await dispatch(bulkOperateEquipmentAssignments(operations));
    dispatch(
      addEquipmentNotification({
        id: '',
        payload: {
          message: t('Seat assignment has been updated.'),
        },
      })
    );
  };

  return (
    <div className={styles['seatsHeader']}>
      <div className={styles['seatsHeader__left']}>
        <p>{t('Available Resources')}:</p>
        <ul className={styles['tag']}>
          {equipmentInstanceOptions.map((option) => (
            <li key={option.value}>
              <a
                className={clsx(
                  option.value === selectedAssignableEquipmentInstanceId
                    ? styles['is-selected']
                    : styles['is-unselected'],
                  styles['is-onsale']
                )}
                onClick={() => {
                  onAssignEquipmentInstanceIdChange(option.value);
                }}
              >
                {option.text}
              </a>
            </li>
          ))}
          {!hideEquipmentInstanceEditButton && (
            <li>
              <p
                className={styles['add']}
                onClick={() => {
                  onEditEquipmentInstanceButtonClick();
                }}
              ></p>
            </li>
          )}
        </ul>
      </div>
      <div className={styles['seatsHeader__right']}>
        <div className={clsx(styles['seatsHeader__right__info'], styles['pc'])}>
          {selectedEquipmentInstance?.per_channel_info
            ?.all_channels_are_closed && (
            <>
              <p
                style={{
                  color: 'red',
                  whiteSpace: 'nowrap',
                  fontSize: '1.2rem',
                }}
              >
                売り止め中
              </p>
              <SeatMapHeaderInternalMemo
                equpmentInstance={selectedEquipmentInstance}
              />
            </>
          )}
        </div>
        {!hideEquipmentInstanceCloseButton && (
          <Box>
            <Button
              size="middle"
              type="button"
              style="yellow"
              onClick={() => {
                setShowEditEquipmentInstanceChannelModal?.(true);
              }}
            >
              <p>売り止めの設定</p>
            </Button>
          </Box>
        )}
        <div className={clsx(styles['seatsHeader__right__info'], styles['sp'])}>
          {selectedEquipmentInstance?.per_channel_info
            ?.all_channels_are_closed && (
            <p
              style={{
                color: 'red',
                whiteSpace: 'nowrap',
                fontSize: '1.2rem',
              }}
            >
              売り止め中
            </p>
          )}
        </div>
      </div>

      {!keepShowingEquipmentInstanceSelector && (
        <>
          <div
            className={clsx(
              styles['seatsHeader__action'],
              editMode === 'ASSIGN' && selectedReservationId
                ? styles['is-active']
                : null
            )}
          >
            <p className={clsx(styles['seatsHeader__action__txt'])}>
              選択した座席へ配置する
            </p>
            <div className={clsx(styles['seatsHeader__action__info'])}>
              <p className={clsx(styles['seatsHeader__action__info__txt'])}>
                移動先の座席: {selectedEquipmentBlockInstanceKeys.length ?? 0}/
                {selectedReservation?.guests.length ?? 0}席
              </p>
              <Box ml={2}>
                <Button
                  size="middle"
                  type="button"
                  style="blue"
                  disabled={
                    selectedEquipmentBlockInstanceKeys.length !==
                    selectedReservation?.guests.length
                  }
                  onClick={() => {
                    bulkOperateHander();
                  }}
                >
                  <p>確定</p>
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  size="middle"
                  type="button"
                  style="yellow"
                  disabled={selectedEquipmentBlockInstanceKeys.length === 0}
                  onClick={() => {
                    onReset?.();
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </div>
          </div>

          <div
            className={clsx(
              styles['seatsHeader__action'],
              editMode === 'CREATE' ? styles['is-active'] : null
            )}
          >
            <p className={clsx(styles['seatsHeader__action__txt'])}>
              {t('Seats selected')}
            </p>
            <div className={clsx(styles['seatsHeader__action__info'])}>
              <p className={clsx(styles['seatsHeader__action__info__txt'])}>
                {t('Total seats selected')}:{' '}
                {selectedEquipmentBlockInstanceKeys.length ?? 0}
              </p>
              <Box ml={2}>
                <Button
                  size="middle"
                  type="button"
                  style="blue"
                  onClick={() => {
                    onNewReservationClick?.();
                  }}
                >
                  <p>{t('Create Reservation')}</p>
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  size="middle"
                  type="button"
                  style="yellow"
                  disabled={selectedEquipmentBlockInstanceKeys.length === 0}
                  onClick={() => {
                    onReset?.();
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </div>
          </div>

          <div
            className={clsx(
              styles['seatsHeader__action'],
              editMode === 'ASSIGN' && draggingReservationId
                ? styles['is-active']
                : null
            )}
          >
            <p className={clsx(styles['seatsHeader__action__txt'])}>
              drag & dropで座席に配置する
            </p>
          </div>
        </>
      )}
    </div>
  );
};
