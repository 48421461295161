// @flow

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import * as swagger from 'shared/models/swagger';
import { MultiselectListInput } from 'client/components/MultiselectListInput';
import { FloatInput } from 'client/components/FloatInput';

type Props = {
  onChange: (swagger.SupplierGuestPaymentSettings) => void,
  value: swagger.SupplierGuestPaymentSettings,
  hideOnlineMarkup?: boolean,
};

export const SupplierGuestPaymentSettingsInput = ({
  hideOnlineMarkup,
  onChange,
  value,
}: Props) => {
  const { t } = useTranslation();

  const acceptsPOB =
    (value.accepted_guest_payment_types || []).indexOf('PAY_ON_BOARD') !== -1;

  const accepted_guest_pob_payment_methods =
    value.accepted_guest_pob_payment_methods || [];
  const cashPOBPaymentMethod = accepted_guest_pob_payment_methods.find(
    (m) => m.payment_method === 'CASH'
  );
  const bankPOBPaymentMethod = accepted_guest_pob_payment_methods.find(
    (m) => m.payment_method === 'BANK'
  );
  const creditPOBPaymentMethod = accepted_guest_pob_payment_methods.find(
    (m) => m.payment_method === 'CREDIT_CARD'
  );

  const creditCardFixedFee =
    (creditPOBPaymentMethod && creditPOBPaymentMethod.fee_fixed) || '';
  const creditCardPercentFee =
    (creditPOBPaymentMethod && creditPOBPaymentMethod.fee_percent) || 0;

  const onlinePaymentAdditionalFeePercent =
    value.online_payment_markup_percent || 0;

  return (
    <>
      {!hideOnlineMarkup && (
        <Form.Field>
          <label>{t('Online Payment Additional Fee Percent (1-100)')}</label>
          <FloatInput
            value={onlinePaymentAdditionalFeePercent}
            onChange={(amount) =>
              onChange({
                ...value,
                online_payment_markup_percent: amount,
              })
            }
          />
        </Form.Field>
      )}
      <MultiselectListInput
        label={t('Accepted payment types')}
        options={[
          { value: 'PAID_IN_FULL', text: t('Paid in full') },
          { value: 'PAY_ON_BOARD', text: t('Pay on board') },
        ]}
        onChange={(accepted_guest_payment_types: any) =>
          onChange({
            ...value,
            accepted_guest_payment_types,
          })
        }
        value={(value.accepted_guest_payment_types: any) || []}
      />
      {acceptsPOB && (
        <Form.Field>
          <label>{t('Accepted POB Payment Methods')}</label>
          <Form.Group>
            <Form.Checkbox
              key="CASH"
              label={t('Cash')}
              checked={Boolean(cashPOBPaymentMethod)}
              onChange={() => {
                if (cashPOBPaymentMethod) {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods:
                      accepted_guest_pob_payment_methods.filter(
                        (m) => m.payment_method !== 'CASH'
                      ),
                  });
                } else {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods: [
                      ...accepted_guest_pob_payment_methods,
                      {
                        payment_method: 'CASH',
                      },
                    ],
                  });
                }
              }}
            />
            <Form.Checkbox
              key="CREDIT_CARD"
              label={t('Credit Card')}
              checked={Boolean(creditPOBPaymentMethod)}
              onChange={() => {
                if (creditPOBPaymentMethod) {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods:
                      accepted_guest_pob_payment_methods.filter(
                        (m) => m.payment_method !== 'CREDIT_CARD'
                      ),
                  });
                } else {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods: [
                      ...accepted_guest_pob_payment_methods,
                      {
                        payment_method: 'CREDIT_CARD',
                      },
                    ],
                  });
                }
              }}
            />
            <Form.Checkbox
              key="BANK"
              label={t('Bank Transfer')}
              checked={Boolean(bankPOBPaymentMethod)}
              onChange={() => {
                if (bankPOBPaymentMethod) {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods:
                      accepted_guest_pob_payment_methods.filter(
                        (m) => m.payment_method !== 'BANK'
                      ),
                  });
                } else {
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods: [
                      ...accepted_guest_pob_payment_methods,
                      {
                        payment_method: 'BANK',
                      },
                    ],
                  });
                }
              }}
            />
          </Form.Group>
          {creditPOBPaymentMethod && (
            <>
              <Form.Input
                label={t(
                  'Credit card fee amount (ex. USD125.00). Leave blank if none.'
                )}
                disabled={Boolean(creditCardPercentFee)}
                value={creditCardFixedFee}
                onChange={(e, { value: amount }) =>
                  onChange({
                    ...value,
                    accepted_guest_pob_payment_methods:
                      accepted_guest_pob_payment_methods.map((m) =>
                        m.payment_method === 'CREDIT_CARD'
                          ? {
                              payment_method: 'CREDIT_CARD',
                              fee_fixed: amount,
                            }
                          : m
                      ),
                  })
                }
              />
              <Form.Field disabled={Boolean(creditCardFixedFee)}>
                <label>{t('Credit card fee percent (1-100)')}</label>
                <FloatInput
                  value={creditCardPercentFee}
                  onChange={(amount) =>
                    onChange({
                      ...value,
                      accepted_guest_pob_payment_methods:
                        accepted_guest_pob_payment_methods.map((m) =>
                          m.payment_method === 'CREDIT_CARD'
                            ? {
                                payment_method: 'CREDIT_CARD',
                                fee_percent: amount,
                              }
                            : m
                        ),
                    })
                  }
                />
              </Form.Field>
            </>
          )}
        </Form.Field>
      )}
    </>
  );
};
