import { useTranslation } from 'react-i18next';

import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import {
  printPriceTitle,
  ServiceShape,
} from 'client/libraries/util/productShape';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

import styles from './ServiceList.module.css';

interface Props {
  services: ServiceShape[];
}
export const ServiceList = ({ services }: Props) => {
  const { t } = useTranslation();

  return (
    <table className={tableStyles['c-tableSmall']}>
      <thead>
        <tr>
          <th className={baseStyles['u-width-256']}>{t('Item')}</th>
          <th className={baseStyles['u-width-320']}>{t('Item Description')}</th>
          <th>{t('Item Price')}</th>
        </tr>
      </thead>
      <tbody>
        {services.map((service) => {
          const showDescription = !!service.description;
          return (
            <tr
              key={service.name}
              className={styles['c-schedule2__list__item__body__tr']}
            >
              <td>{service.name}</td>
              <td>{showDescription ? service.description : '-'}</td>
              <td>
                {service.type === 'FREE' ? (
                  <div
                    className={
                      styles['c-schedule2__list__item__body__price__td']
                    }
                  >
                    {t('Free')}
                  </div>
                ) : (
                  <div
                    className={
                      styles['c-schedule2__list__item__body__price__td']
                    }
                  >
                    {service.pricesSummary.map((price) => (
                      <div key={price.unitTitle}>
                        <p>{`${printPriceTitle(
                          price,
                          t
                        )} ${formattedCurrencyAmount(price.amount)}`}</p>
                      </div>
                    ))}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
