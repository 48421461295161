import * as React from 'react';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  Select,
  FieldWrapper,
  ToggleButton,
  Radio,
  Input,
} from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { EquipmentBlockInstanceProperty } from 'shared/models/swagger';
import { ColorSelectCheckbox } from 'client/components/ColorSelectCheckbox/ColorSelectCheckbox';

interface FormValue {
  isClosed: boolean;
  attributeKey: string;
  color: string;
  reference: string;
  capacity: string;
  changeIsClosed: boolean;
  changeAttributeKey: boolean;
  changeColor: boolean;
  changeReference: boolean;
  changeCapacity: boolean;
  overrideIsClosed: boolean;
  overrideAttributeKey: boolean;
  overrideColor: boolean;
  overrideReference: boolean;
  overrideCapacity: boolean;
}

interface Props {
  equipmentBlockInstanceProperty: EquipmentBlockInstanceProperty | null;
  onClose: () => void;
  open: boolean;
  onSave: (value: EquipmentBlockInstanceProperty) => Promise<any>;
  saveButtonText?: string;
  cancelButtonText?: string;
}

const getInitialValues = (
  property: EquipmentBlockInstanceProperty | null
): FormValue => {
  return {
    isClosed: property?.is_closed ?? false,
    attributeKey: property?.equipment_block_instance_attribute_key ?? '',
    color: property?.color ?? '#E6F8F',
    reference: property?.reference ?? '',
    capacity: property?.capacity ? String(property?.capacity) : '',
    changeIsClosed: property?.override_is_closed ? true : false,
    changeAttributeKey: Boolean(
      property?.override_equipment_block_instance_attribute_key
    ),
    changeColor: property?.override_color ? true : false,
    changeReference: property?.override_reference ? true : false,
    changeCapacity: property?.override_capacity ? true : false,
    overrideIsClosed: property?.override_is_closed ? true : false,
    overrideAttributeKey: Boolean(
      property?.override_equipment_block_instance_attribute_key
    ),
    overrideColor: property?.override_color ? true : false,
    overrideReference: property?.override_reference ? true : false,
    overrideCapacity: property?.override_capacity ? true : false,
  };
};

const convertToEquipmentBlockInstanceProperty = (
  value: FormValue
): EquipmentBlockInstanceProperty => {
  return {
    ...(value.changeAttributeKey && {
      equipment_block_instance_attribute_key: value.attributeKey,
      override_equipment_block_instance_attribute_key:
        value.overrideAttributeKey,
    }),
    ...(value.changeColor && {
      color: value.color,
      override_color: value.overrideColor,
    }),
    ...(value.changeCapacity && {
      capacity: Number(value.capacity),
      override_capacity: value.overrideCapacity,
    }),
    ...(value.changeIsClosed && {
      is_closed: value.isClosed,
      override_is_closed: value.overrideIsClosed,
    }),
    ...(value.changeReference && {
      reference: value.reference,
      override_reference: value.overrideReference,
    }),
  };
};

export const EditEquipmentBlockInstancePropertyModal = ({
  equipmentBlockInstanceProperty,
  onClose,
  open,
  onSave,
  saveButtonText,
  cancelButtonText,
}: Props) => {
  const { t } = useTranslation();

  const organization = useSelector(activeUserOrganizationSelector);

  const equipmentBlockInstanceAttributes = React.useMemo(() => {
    return (
      organization?.seat_management_settings
        ?.equipment_block_instance_attributes || []
    );
  }, [organization]);

  const [success, setSuccess] = React.useState<boolean>(false);

  const options = equipmentBlockInstanceAttributes.map((attribute) => ({
    value: attribute.key ?? '',
    text: attribute.title ?? '',
  }));

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t('Edit Seat Settings')}
      transparent={true}
    >
      <Form
        onSubmit={async (value: FormValue) => {
          setSuccess(false);
          await onSave({
            ...convertToEquipmentBlockInstanceProperty(value),
          });
          onClose();
        }}
        initialValues={getInitialValues(equipmentBlockInstanceProperty)}
      >
        {({ handleSubmit, submitting, error }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <ChangeOverrideForm
                  changeName="changeIsClosed"
                  overrideName="overrideIsClosed"
                  propertyName={t('Seat Status')}
                >
                  <Field name="isClosed">
                    {({ input }) => (
                      <>
                        <Radio
                          label={t('Open')}
                          checked={!input.value}
                          onChange={() => {
                            input.onChange(false);
                          }}
                        />
                        <Radio
                          label={t('Closed')}
                          checked={input.value}
                          onChange={() => {
                            input.onChange(true);
                          }}
                        />
                      </>
                    )}
                  </Field>
                </ChangeOverrideForm>
              </Modal.Box>
              <Modal.Box>
                <ChangeOverrideForm
                  changeName="changeAttributeKey"
                  overrideName="overrideAttributeKey"
                  propertyName={t('Booking Source Group')}
                >
                  <Field name="attributeKey">
                    {({ input }) => (
                      <Select
                        label={t('Booking Source Group')}
                        options={options}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </ChangeOverrideForm>
              </Modal.Box>
              <Modal.Box>
                <ChangeOverrideForm
                  changeName="changeColor"
                  overrideName="overrideColor"
                  propertyName={t('Color')}
                >
                  <FieldWrapper label={t('Colors')}>
                    <Field name="color">
                      {({ input }) => (
                        <ColorSelectCheckbox
                          value={input.value}
                          onChange={(color) => input.onChange(color)}
                          defaultColors={[
                            '#E6F8FF',
                            '#FEFFE6',
                            '#FFECE6',
                            '#E7FFE6',
                          ]}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </ChangeOverrideForm>
              </Modal.Box>
              <Modal.Box>
                <ChangeOverrideForm
                  changeName="changeReference"
                  overrideName="overrideReference"
                  propertyName={t('Seat Reference')}
                >
                  <Field name="reference">
                    {({ input }) => (
                      <Input
                        type="text"
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </ChangeOverrideForm>
              </Modal.Box>
              <div>
                {success && <Message success header={t('Save Successful')} />}
                {error && <Message error header={t('Save Failed')} />}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {cancelButtonText || t('Discard')}
              </Button.Cancel>
              <Button
                loading={submitting}
                style="green"
                size="middle"
                type="submit"
              >
                {saveButtonText || t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};

const ChangeOverrideForm = ({
  changeName,
  overrideName,
  propertyName,
  children,
}: {
  changeName: string;
  overrideName: string;
  propertyName: string;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <Field name={changeName} type="checkbox">
      {({ input: changeInput }) => (
        <Box>
          <Box mb={2}>
            <ToggleButton
              checked={changeInput.checked ?? false}
              onChange={() => changeInput.onChange(!changeInput.checked)}
              label={t('Change {{propertyName}}', { propertyName })}
            />
          </Box>
          <Box>
            {changeInput.checked && (
              <Field name={overrideName} type="checkbox">
                {({ input: overrideInput }) => (
                  <Box ml={4} mb={2}>
                    <Box mb={2}>
                      <ToggleButton
                        checked={overrideInput.checked ?? false}
                        onChange={() =>
                          overrideInput.onChange(!overrideInput.checked)
                        }
                        label={t('Override {{propertyName}}', {
                          propertyName,
                        })}
                      />
                    </Box>
                    <Box>{overrideInput.checked && children}</Box>
                  </Box>
                )}
              </Field>
            )}
          </Box>
        </Box>
      )}
    </Field>
  );
};
