import { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';

import styles from './DraggableSelect.module.css';

const itemType = 'selectable-item';
export interface Props {
  value: string;
  options: {
    key: string;
    value: string;
    text: string;
  }[];
  index: number;
  onValueChange: (arg0: string) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem?: () => void;
  error?: string;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const DraggableSelect = ({
  deleteItem,
  index,
  moveItem,
  onValueChange,
  options,
  value,
  error,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop<DragItem>({
    accept: itemType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: value,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={styles['c-draggable__box']}
      style={{
        opacity,
      }}
    >
      <div className={styles['c-draggable__flex']}>
        <div className={styles['c-draggable__icon']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div>{`#${index + 1}`}</div>
        <SingleDropdown
          options={options}
          selectedOption={value}
          onChange={(value) => onValueChange(value)}
          searchable={true}
          error={error}
        />
        {deleteItem && (
          <Button
            size="icon"
            color="tertiarygray"
            onClick={deleteItem}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        )}
      </div>
    </div>
  );
};
