import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_GUIDANCE_STAMP_RALLY_FAILURE,
  CREATE_GUIDANCE_STAMP_RALLY_REQUEST,
  CREATE_GUIDANCE_STAMP_RALLY_SUCCESS,
  DELETE_GUIDANCE_STAMP_RALLY_FAILURE,
  DELETE_GUIDANCE_STAMP_RALLY_REQUEST,
  DELETE_GUIDANCE_STAMP_RALLY_SUCCESS,
  FETCH_GUIDANCE_STAMP_RALLIES_FAILURE,
  FETCH_GUIDANCE_STAMP_RALLIES_REQUEST,
  FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS,
  UPDATE_GUIDANCE_STAMP_RALLY_FAILURE,
  UPDATE_GUIDANCE_STAMP_RALLY_REQUEST,
  UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GuidanceStampRally,
  CreateGuidanceStampRallyResponse,
  ListGuidanceStampRalliesResponse,
  UpdateGuidanceStampRallyResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_GUIDANCE_STAMP_RALLIES_FAILURE:
    case CREATE_GUIDANCE_STAMP_RALLY_FAILURE:
    case UPDATE_GUIDANCE_STAMP_RALLY_FAILURE:
    case DELETE_GUIDANCE_STAMP_RALLY_FAILURE:
      return action.error;

    case FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS:
    case CREATE_GUIDANCE_STAMP_RALLY_SUCCESS:
    case UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS:
    case DELETE_GUIDANCE_STAMP_RALLY_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_GUIDANCE_STAMP_RALLIES_REQUEST:
    case CREATE_GUIDANCE_STAMP_RALLY_REQUEST:
    case UPDATE_GUIDANCE_STAMP_RALLY_REQUEST:
    case DELETE_GUIDANCE_STAMP_RALLY_REQUEST:
      return true;

    case FETCH_GUIDANCE_STAMP_RALLIES_FAILURE:
    case CREATE_GUIDANCE_STAMP_RALLY_FAILURE:
    case UPDATE_GUIDANCE_STAMP_RALLY_FAILURE:
    case DELETE_GUIDANCE_STAMP_RALLY_FAILURE:
    case FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS:
    case CREATE_GUIDANCE_STAMP_RALLY_SUCCESS:
    case UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS:
    case DELETE_GUIDANCE_STAMP_RALLY_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: GuidanceStampRally[] = [],
  action: any
): GuidanceStampRally[] => {
  switch (action.type) {
    case FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS: {
      const response = action.response as ListGuidanceStampRalliesResponse;
      return response.guidance_stamp_rallies ?? [];
    }

    case CREATE_GUIDANCE_STAMP_RALLY_SUCCESS: {
      const response = action.response as CreateGuidanceStampRallyResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS: {
      const response = action.response as UpdateGuidanceStampRallyResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_GUIDANCE_STAMP_RALLY_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const guidanceStampRallies = combineReducers({
  error,
  loading,
  all,
});
