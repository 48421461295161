import * as React from 'react';
import {
  useSelector,
  /*, useDispatch*/
} from 'react-redux';
import moment from 'moment-timezone';

import { activeUserSelector } from 'client/reducers/user';
import { ProductInstanceEditModal } from 'client/components/ProductInstanceEditModal/ProductInstanceEditModal';
import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers';
import { ProductInstanceCalendar } from 'client/components/ProductInstanceCalendar';
import type { Reservation, ProductInstance } from 'shared/models/swagger';

type Props = {
  isChangeReservation: boolean;
  reservation?: Reservation;
  productId: string;
  defaultDate?: string;
  onSelectProductInstanceError?: (arg0: string) => void;
  open: boolean;
  onClose: () => void;
};
export const ChangeProductAndParticipationDate = ({
  isChangeReservation,
  reservation,
  productId,
  defaultDate,
  onSelectProductInstanceError,
  open,
  onClose,
}: Props) => {
  const [selectedProductId, setSelectedProductId] =
    React.useState<string>(productId);
  const [editingProductInstance, setEditingProductInstance] =
    React.useState<ProductInstance | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const isProductLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const isProductInstancesLoading = useSelector(
    (state: ReduxState) => state.productInstances.loading
  );
  const loading = isProductLoading || isProductInstancesLoading;

  const getDefaultDateMoment = () => {
    if (defaultDate) {
      return moment(defaultDate);
    }

    return moment();
  };

  return (
    <>
      {open && (
        <div>
          {editingProductInstance && (
            <ProductInstanceEditModal
              insertRoot={true}
              oldProductInstance={editingProductInstance}
              onClose={() => {
                setEditingProductInstance(null);
              }}
              open={editingProductInstance !== null}
            />
          )}
          <ProductInstanceCalendar
            defaultDate={getDefaultDateMoment()}
            loading={loading}
            onChangeProduct={setSelectedProductId}
            onClose={() => onClose()}
            onEditClick={
              operationAllowed(activeUser, 'write', 'productInstances')
                ? setEditingProductInstance
                : undefined
            }
            productId={selectedProductId}
            showProductSelector={true}
            reservation={reservation}
            isChangeReservation={isChangeReservation}
            onSelectProductInstanceError={(message) => {
              onClose();

              if (onSelectProductInstanceError) {
                onSelectProductInstanceError(message);
              }
            }}
          />
        </div>
      )}
    </>
  );
};
