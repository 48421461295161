import * as Swagger from 'shared/models/swagger';

export type FloatingButtonSettingsFormValues = {
  onClickBehavior?: 'NAVIGATE_TO_PAGE' | 'SHOW_CALENDAR';
  onClickTarget?: 'TOP_PAGE' | 'PRODUCT_PAGE';
  floatingButtonUrlMap?: {
    origin?: string;
    targetProduct?: string;
  }[];
  excludeUrl?: string[];
};

export const getSettingsInitialValues = (
  organization: Swagger.Organization | null
): FloatingButtonSettingsFormValues => {
  const urlMap =
    organization?.floating_button_settings?.floating_button_url_map?.map(
      (m) => {
        return {
          origin: m?.origin,
          targetProduct: m?.target_product,
        };
      }
    );
  return {
    onClickBehavior:
      organization?.floating_button_settings?.on_click_behavior ??
      'NAVIGATE_TO_PAGE',
    onClickTarget:
      organization?.floating_button_settings?.on_click_target ?? 'TOP_PAGE',
    floatingButtonUrlMap: urlMap ?? [],
    excludeUrl: organization?.floating_button_settings?.exclude_url ?? [],
  };
};

export const convertSettingsFormValuesToOrganizationPatch = (
  values: FloatingButtonSettingsFormValues
): Swagger.Organization$Patch => {
  const urlMap = values.floatingButtonUrlMap?.map((m) => {
    return {
      origin: m?.origin,
      target_product: m?.targetProduct,
    };
  });
  const excludeUrl =
    values.onClickTarget !== 'TOP_PAGE' ? [] : values.excludeUrl;
  return {
    floating_button_settings: {
      on_click_behavior: values.onClickBehavior,
      on_click_target: values.onClickTarget,
      floating_button_url_map: urlMap ?? [],
      exclude_url: excludeUrl,
    },
  };
};
