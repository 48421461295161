import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';
import { DurationInput } from 'client/components/v3/DurationInput/DurationInput';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';

type Props = {
  name: string;
};
export const TransportRouteLocationEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div
          className={clsx(
            styles['p-list__item__ttl'],
            styles['p-transportRoute__item__ttl']
          )}
        >
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Time Offset:')}
          </div>
        </div>
        <div
          className={clsx(
            styles['p-list__item__body'],
            styles['p-transportRoute__item__body']
          )}
        >
          <Field name={`${name}.relativeTime`}>
            {({ input }) => (
              <DurationInput showSignSelect required {...input} />
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div
          className={clsx(
            styles['p-list__item__ttl'],
            styles['p-transportRoute__item__ttl']
          )}
        >
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Location:')}
          </div>
        </div>
        <div
          className={clsx(
            styles['p-list__item__body'],
            styles['p-transportRoute__item__body']
          )}
        >
          <Field name={`${name}.locationName`}>
            {({ input }) => (
              <LocationSearchInput
                location={input.value}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSearchChange={() => {}}
                onLocationSelect={({ title, key }) => {
                  input.onChange(title);
                  form.change(`${name}.googlePlaceId`, key);
                }}
                maxWidth={800}
              />
            )}
          </Field>
        </div>
      </li>
    </ul>
  );
};
