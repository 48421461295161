import {
  SET_PRODUCT_PAGE,
  SET_PRODUCT_PAGE_SIZE,
  SET_PRODUCT_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';

export const setProductVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_PRODUCT_VISIBLE_COLUMNS,
  visibleColumns,
});

export const setProductPage = (page: number) => ({
  type: SET_PRODUCT_PAGE,
  page,
});

export const setProductPageSize = (pageSize: number) => ({
  type: SET_PRODUCT_PAGE_SIZE,
  pageSize,
});
