import moment from 'moment';

import { TranslateFuncType } from 'client/components/Translate';
import { ProductSummary } from 'shared/models/swagger';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';

import { CustomerAutoTag } from './FormValues';

const getTimePeriodText = (preset: string, t: TranslateFuncType) => {
  switch (preset) {
    case 'LAST_30_DAYS':
      return t('{{count}} days ago', { count: 30 });
    case 'LAST_90_DAYS':
      return t('{{count}} days ago', { count: 90 });
    case 'LAST_180_DAYS':
      return t('{{count}} days ago', { count: 180 });
  }

  return '';
};

export const getConditionDescription = (
  tag: CustomerAutoTag,
  t: TranslateFuncType,
  allProducts: ProductSummary[]
) => {
  if (!tag.conditions) {
    return '';
  }

  const conditions = [];
  for (const condition of tag.conditions) {
    const tempCondition = [];
    if (condition.registrationDateRangePreset) {
      if (!condition.registrationDateRangePreset.includes('CUSTOM')) {
        tempCondition.push(
          getTimePeriodText(condition.registrationDateRangePreset, t)
        );
      }
    }
    if (condition.registrationDateRange) {
      if (condition.registrationDateRangePreset.includes('CUSTOM')) {
        tempCondition.push(
          `${t('Registration Date')} (${moment(
            condition.registrationDateRange.startDate
          ).format('YYYY/MM/DD')} ~ ${moment(
            condition.registrationDateRange.endDate
          ).format('YYYY/MM/DD')})`
        );
      }
    }
    if (condition.lastParticipationDateRangePreset) {
      if (!condition.lastParticipationDateRangePreset.includes('CUSTOM')) {
        tempCondition.push(
          `${t('Last Participation Date')} (${getTimePeriodText(
            condition.lastParticipationDateRangePreset,
            t
          )})`
        );
      }
    }
    if (condition.lastParticipationDateRange) {
      if (condition.lastParticipationDateRangePreset.includes('CUSTOM')) {
        tempCondition.push(
          `${t('Last Participation Date')} (${moment(
            condition.lastParticipationDateRange.startDate
          ).format('YYYY/MM/DD')} ~ ${moment(
            condition.lastParticipationDateRange.endDate
          ).format('YYYY/MM/DD')})`
        );
      }
    }
    if (condition.productIds && condition.productIds.length > 0) {
      const products = [];
      for (const productId of condition.productIds) {
        const product = allProducts.find((product) => product.id === productId);
        products.push(getVerboseDisplayProductName(product));
      }
      tempCondition.push(`${t('Products')} (${products.join(',')})`);
    }
    if (condition.manualTagIds && condition.manualTagIds.length > 0) {
      const manualTagIds = [];
      for (const manualTagId of condition.manualTagIds) {
        manualTagIds.push(manualTagId);
      }
      tempCondition.push(`${t('Tags')} (${manualTagIds.join(',')})`);
    }
    if (condition.gender) {
      if (condition.gender !== 'ANY') {
        switch (condition.gender) {
          case 'MALE':
            tempCondition.push(`${t('Gender')}: ${t('Male')}`);
            break;
          case 'FEMALE':
            tempCondition.push(`${t('Gender')}: ${t('Female')}`);
            break;
          case 'UNSPECIFIED':
            tempCondition.push(`${t('Gender')}: ${t('Unspecified')}`);
            break;
        }
      }
    }
    if (condition.ageRange) {
      tempCondition.push(
        `${t('Age')} (${condition.ageRange.lowerBound} ~ ${
          condition.ageRange.upperBound
        })`
      );
    }
    if (condition.shouldReceiveSpecialEmailOffers) {
      tempCondition.push(`${t('Receive Emails')}`);
    }
    if (condition.alertedCustomer) {
      tempCondition.push(`${t('Flag customer as alerted')}`);
    }
    if (condition.reservationCountRange) {
      tempCondition.push(
        `${t('Total Reservations')} (${
          condition.reservationCountRange.lowerBound
        } ~ ${condition.reservationCountRange.upperBound})`
      );
    }
    if (condition.averageReservationAmountGrossRange) {
      tempCondition.push(
        `${t('Average booking price')} (${
          condition.averageReservationAmountGrossRange.lowerBound
        } ~ ${condition.averageReservationAmountGrossRange.upperBound})`
      );
    }
    if (condition.customerLtvRange) {
      tempCondition.push(
        `${t('Member LTV')} (${condition.customerLtvRange.lowerBound} ~ ${
          condition.customerLtvRange.upperBound
        })`
      );
    }
    const joinedCondition = tempCondition.join(` --${t('AND')}-- `);
    conditions.push(joinedCondition);
  }
  return conditions.join(`<br> --${t('OR')}-- <br>`);
};
