import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Select } from 'client/components/Form';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { getLanguageName } from 'client/libraries/i18n';
import { Box } from 'client/components/Box/Box';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { SourceLanguage } from 'shared/models/swagger';

import styles from './PopupMessagesEditor.module.css';
import { EditPopupMessageModal } from './EditPopupMessageModal';
import { getPopupMessageFormValues } from './FormValues';
import { deletePopupMessage } from './util';
import type { PopupMessage } from './FormValues';

export const PopupMessagesEditor = () => {
  const { t } = useTranslation();
  const [showEditPopupMessageModal, setShowEditPopupMessageModal] =
    React.useState(false);
  const [showDeletePopupMessageModal, setShowDeletePopupMessageModal] =
    React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));
  const dispatch = useDispatch();
  const form = useForm();
  const popupMessage = form.getState().values?.popupMessage;

  const parentFormSavePopupMessage = (newPopupMessage: PopupMessage) => {
    form.change('popupMessage', newPopupMessage);
  };

  return (
    <>
      <Box
        ml={-1}
        mb={2}
        flexWrap="wrap"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box ml={1} fontWeight="bold">
          {t('Content Language')}
        </Box>
        <Box ml={1}>
          <Field name="popupMessageLanguage">
            {({ input }) => (
              <Select
                maxWidth={200}
                options={languageOptions}
                value={input.value}
                onChange={(e, { value }) => {
                  form.change(
                    'popupMessage',
                    getPopupMessageFormValues(
                      activeUserOrganization,
                      value as SourceLanguage
                    )
                  );
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
      </Box>
      <Box mb={2}>
        <Button
          style="green"
          size="middle"
          onClick={() => setShowEditPopupMessageModal(true)}
          disabled={Boolean(popupMessage)}
        >
          {t('Show pop-up message')}
        </Button>
      </Box>
      {showEditPopupMessageModal && (
        <EditPopupMessageModal
          name="popupMessage"
          onSave={(value: PopupMessage) => {
            parentFormSavePopupMessage(value);
          }}
          open={showEditPopupMessageModal}
          onClose={() => setShowEditPopupMessageModal(false)}
        />
      )}
      {showDeletePopupMessageModal && (
        <DeleteConfirmModal
          header={t('Delete Pop-up Message')}
          content={t('Are you sure you want to delete pop-up message?')}
          onConfirm={async () => {
            await dispatch(deletePopupMessage(popupMessage?.key ?? ''));
          }}
          onClose={() => setShowDeletePopupMessageModal(false)}
          open={showDeletePopupMessageModal}
        />
      )}
      {popupMessage ? (
        <Box mb={2}>
          <div className={styles['container']}>
            <EditIcon
              className={styles['icon-box']}
              onClick={() => setShowEditPopupMessageModal(true)}
            />
            <DeleteIcon
              className={styles['icon-box']}
              onClick={() => setShowDeletePopupMessageModal(true)}
            />
            <div className={styles['title']}>{popupMessage.title}</div>
          </div>
        </Box>
      ) : null}
    </>
  );
};
