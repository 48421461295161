import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { EquipmentBlockInstanceAttribute } from 'shared/models/swagger';

interface Props {
  equipmentBlockInstanceAttribute: EquipmentBlockInstanceAttribute | null;
}

export const BookingSourcesDisplayBox = ({
  equipmentBlockInstanceAttribute,
}: Props) => {
  const { t } = useTranslation();
  const contractedAgents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <div>
      <ul>
        {(equipmentBlockInstanceAttribute?.booking_sources ?? []).map(
          (bookingSource, idx) => {
            if (bookingSource.source_type === 'AGENT') {
              const agent = contractedAgents.find(
                (a) => a.id === bookingSource.agent_id
              );
              return (
                <li key={idx}>
                  {t(bookingSource.source_type)}:{agent?.name}
                </li>
              );
            } else {
              return <li key={idx}>{t(bookingSource.source_type as any)}</li>;
            }
          }
        )}
      </ul>
    </div>
  );
};
