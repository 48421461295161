import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { OptionalIntegerInput, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

interface Props {
  name: string;
}

export const CountFilterEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const required = (value: any | null | undefined): string => {
    if (!value && value !== 0) return t('Required');
    return '';
  };

  return (
    <Box display="flex" mt={2}>
      <Field name={`${name}.filterType`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <div>
            <div>{t('Condition')}</div>
            <Select
              width={364}
              options={[
                {
                  text: t('Less than or equal to'),
                  value: 'LTE',
                },
                {
                  text: t('Greater than or equal to'),
                  value: 'GTE',
                },
                {
                  text: t('Equal to'),
                  value: 'EQ',
                },
              ]}
              value={input.value}
              onChange={(e, { value }) => {
                input.onChange(value);
              }}
              error={touched && error}
            />
          </div>
        )}
      </Field>
      <Field name={`${name}.value`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box ml={1}>
            <div>{t('Value')}</div>
            <OptionalIntegerInput
              label=""
              value={input.value}
              onChange={input.onChange}
              error={touched && error}
              disallowNegative
            />
          </Box>
        )}
      </Field>
    </Box>
  );
};
