import { Account } from 'shared/models/swagger';

export interface FormValues {
  manageableOrganizationIds?: string[];
}

export const getInitialValues = (account?: Partial<Account>): FormValues => {
  let manageableOrganizationIds: string[] = [];
  if (account?.manageable_organization_ids) {
    manageableOrganizationIds = account.manageable_organization_ids.split(',');
  }

  return {
    manageableOrganizationIds: manageableOrganizationIds,
  };
};

export const convertFormValuesToAccount = (
  values: FormValues,
  existingAccount?: Partial<Account>
): Partial<Account> => {
  const account: Partial<Account> = {
    ...existingAccount,
    manageable_organization_ids:
      (values.manageableOrganizationIds || []).length > 0
        ? (values.manageableOrganizationIds || []).join(',')
        : undefined,
  };

  return account;
};
