import {
  MarketingAutomationTriggerCondition,
  MarketingAutomationTriggerConditionParams,
} from 'shared/models/swagger';

export interface FormValues {
  title: string;

  /* TRIGGER & CONDITIONS */

  displayTriggerType: Exclude<
    MarketingAutomationTriggerCondition['trigger'],
    undefined
  >['display_trigger_type'];

  triggerTiming: 'IMMEDIATE' | 'DELAYED' | 'BEFORE_EVENT';
  triggerDelay: {
    delayType: 'MINUTE' | 'HOUR' | 'DAY' | 'ETERNAL';
    count: number;
    timeOfDay: string;
  };

  // Only valid for 'START_TIME' trigger type
  triggerAdvance: {
    advanceType: 'MINUTE' | 'HOUR' | 'DAY' | 'ETERNAL';
    count: number;
    timeOfDay: string;
  };

  // if displayTriggerType is ONLINE_GUIDE_AFTER_COUPON_REDEMPTION the couponId of the coupon that triggers the offer
  triggerCouponIds: string[];

  // if displayTriggerType is ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION
  locationTriggerSettings?: LocationTriggerSettings;

  // if displayTriggerType is ONLINE_GUIDE_AFTER_STAMP_RALLY_COMPLETION the id of the stamp rally that triggers the condition
  triggerStampRallyIds: string[];

  /* CONDITIONS */

  conditionRules: ConditionRule[];
}

export interface ConditionRule {
  participationStartDate: string;
  participationEndDate: string;

  shouldFilterByBookedProductIds: boolean;
  bookedProductIds: string[];

  shouldFilterByTimeOfDay: boolean;
  // hh:mm
  timeOfDayStart: string;
  // hh:mm
  timeOfDayEnd: string;

  /* OFFER LIMITS */

  limitType: 'NO_LIMIT' | 'EXPIRATION_TIME_OF_DAY' | 'LIMITED_CLAIMS_PER_DAY';

  // if expirationType is EXPIRATION_TIME_OF_DAY
  // hh:mm
  expirationTimeOfDay: string;

  // if expirationType is EXPIRES_AFTER_X_USES
  dailyClaimLimit?: number;
}

interface LocationTriggerSettings {
  locationName: string;
  latitude: number;
  longitude: number;
  activationRange: number;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): MarketingAutomationTriggerConditionParams => {
  return {
    title: values.title,
    trigger: {
      display_trigger_type: values.displayTriggerType,
      trigger_coupon_ids: values.triggerCouponIds,
      trigger_stamp_rally_ids: values.triggerStampRallyIds,
      trigger_delay:
        values.triggerTiming === 'DELAYED'
          ? {
              type: values.triggerDelay.delayType,
              count: values.triggerDelay.count,
              time_local: values.triggerDelay.timeOfDay,
            }
          : undefined,
      trigger_advance:
        values.triggerTiming === 'BEFORE_EVENT'
          ? {
              type: values.triggerAdvance.advanceType,
              count: values.triggerAdvance.count,
              time_local: values.triggerAdvance.timeOfDay,
            }
          : undefined,
      location_trigger_settings: {
        location_name: values.locationTriggerSettings?.locationName,
        latitude: values.locationTriggerSettings?.latitude,
        longitude: values.locationTriggerSettings?.longitude,
        activation_range: values.locationTriggerSettings?.activationRange,
      },
    },
    condition_rules:
      values?.conditionRules.map((rule) => ({
        participation_start_date: rule.participationStartDate,
        participation_end_date: rule.participationEndDate,
        booked_product_ids: rule.shouldFilterByBookedProductIds
          ? rule.bookedProductIds
          : [],
        time_of_day_start: rule.shouldFilterByTimeOfDay
          ? rule.timeOfDayStart
          : '',
        time_of_day_end: rule.shouldFilterByTimeOfDay ? rule.timeOfDayEnd : '',
        limit_type: rule.limitType,
        expiration_time_of_day: rule.expirationTimeOfDay,
        max_claims_per_day: rule.dailyClaimLimit,
      })) ?? [],
  };
};

export const convertSwaggerToFormValues = (
  trigger: MarketingAutomationTriggerCondition
): FormValues => {
  return {
    title: trigger.title ?? '',
    displayTriggerType:
      trigger.trigger?.display_trigger_type ?? 'ETICKET_BEFORE_REDEMPTION',
    locationTriggerSettings:
      trigger.trigger?.display_trigger_type ===
      'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION'
        ? {
            locationName:
              trigger.trigger?.location_trigger_settings?.location_name ?? '',
            latitude: trigger.trigger?.location_trigger_settings?.latitude ?? 0,
            longitude:
              trigger.trigger?.location_trigger_settings?.longitude ?? 0,
            activationRange:
              trigger.trigger?.location_trigger_settings?.activation_range ?? 0,
          }
        : undefined,
    triggerTiming: trigger.trigger?.trigger_delay
      ? 'DELAYED'
      : trigger.trigger?.trigger_advance
      ? 'BEFORE_EVENT'
      : 'IMMEDIATE',
    triggerDelay: trigger.trigger?.trigger_delay
      ? {
          delayType: trigger.trigger?.trigger_delay?.type ?? 'MINUTE',
          count: trigger.trigger?.trigger_delay?.count ?? 0,
          timeOfDay: trigger.trigger?.trigger_delay?.time_local ?? '0:00',
        }
      : {
          delayType: 'MINUTE',
          count: 0,
          timeOfDay: '0:00',
        },
    triggerAdvance: trigger.trigger?.trigger_advance
      ? {
          advanceType: trigger.trigger?.trigger_advance?.type ?? 'MINUTE',
          count: trigger.trigger?.trigger_advance?.count ?? 0,
          timeOfDay: trigger.trigger?.trigger_advance?.time_local ?? '0:00',
        }
      : {
          advanceType: 'MINUTE',
          count: 0,
          timeOfDay: '0:00',
        },
    triggerCouponIds:
      trigger.trigger?.display_trigger_type ===
      'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION'
        ? trigger.trigger?.trigger_coupon_ids ?? []
        : [],
    triggerStampRallyIds:
      trigger.trigger?.display_trigger_type ===
      'ONLINE_GUIDE_AFTER_STAMP_RALLY_COMPLETION'
        ? trigger.trigger?.trigger_stamp_rally_ids ?? []
        : [],
    conditionRules:
      trigger.condition_rules?.map((rule) => ({
        participationStartDate: rule?.participation_start_date ?? '',
        participationEndDate: rule?.participation_end_date ?? '',
        shouldFilterByBookedProductIds: Boolean(
          rule?.booked_product_ids?.length
        ),
        bookedProductIds: rule?.booked_product_ids ?? [],
        shouldFilterByTimeOfDay: Boolean(rule?.time_of_day_start?.length),
        timeOfDayStart: rule?.time_of_day_start ?? '',
        timeOfDayEnd: rule?.time_of_day_end ?? '',
        limitType: rule?.limit_type ?? 'NO_LIMIT',
        expirationTimeOfDay: rule?.expiration_time_of_day ?? '',
        dailyClaimLimit: rule?.max_claims_per_day ?? 0,
      })) ?? [],
  };
};

export const getDefaultConditionRule = (): ConditionRule => ({
  shouldFilterByBookedProductIds: false,
  bookedProductIds: [],
  participationStartDate: '',
  participationEndDate: '',
  shouldFilterByTimeOfDay: false,
  timeOfDayStart: '00:00',
  timeOfDayEnd: '23:59',

  // Limits
  limitType: 'NO_LIMIT',
  expirationTimeOfDay: '23:59',
});
