import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { FieldWrapper, Select } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import styles from './AgentBlacklistWhitelistEditorModal.module.css';

export const AgentBlacklistWhitelistEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, [dispatch]);
  const contractedAgents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );
  const agentOptions = contractedAgents.map((agent) => ({
    key: agent.id,
    value: agent.id,
    text: agent.name,
  }));
  const form = useForm();
  return (
    <Field name="type">
      {({ input: typeInput }) => (
        <>
          <div className={baseStyles['base-margin-bottom-8']}>
            <Select
              label={t('Agent list type')}
              value={typeInput.value}
              onChange={(e, { value }) => {
                typeInput.onChange(value);
                form.change('whitelistedAgentIds', []);
                form.change('blacklistedAgentIds', []);
              }}
              options={[
                {
                  text: t('Whitelist'),
                  value: 'WHITELIST',
                },
                {
                  text: t('Blacklist'),
                  value: 'BLACKLIST',
                },
              ]}
            />
          </div>
          <FieldArray
            key={typeInput.value}
            name={
              typeInput.value === 'WHITELIST'
                ? 'whitelistedAgentIds'
                : 'blacklistedAgentIds'
            }
          >
            {({ fields }) => (
              <>
                {fields.length === 0 ? (
                  <AddIcon onClick={() => (fields as any).insertAt(0, '')} />
                ) : (
                  <div className={styles['edit-container']}>
                    <FieldWrapper label={t('Agents')}>
                      {fields.map((name, idx) => (
                        <div key={name} className={styles['edit-row']}>
                          <div className={styles['input-cell']}>
                            <Field name={name}>
                              {({ input, meta: { touched, error } }) => (
                                <Select
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                  error={touched && error}
                                  options={agentOptions.filter(
                                    (option) =>
                                      !fields.value.includes(option.value) ||
                                      option.value === input.value
                                  )}
                                />
                              )}
                            </Field>
                          </div>
                          <div className={styles['button-cell']}>
                            <AddIcon
                              onClick={() =>
                                (fields as any).insertAt(idx + 1, '')
                              }
                            />
                            <DeleteIcon onClick={() => fields.remove(idx)} />
                          </div>
                        </div>
                      ))}
                    </FieldWrapper>
                  </div>
                )}
              </>
            )}
          </FieldArray>
        </>
      )}
    </Field>
  );
};
