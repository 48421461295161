import * as React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { formattedDuration } from 'client/libraries/util/formattedDuration';

import styles from './SessionSummary.module.css';
import { SessionSelectionContext } from './SessionSelectionContext';
import { formattedDate, getFilteredEvents } from './util';
import { useSessions } from './useSessions';

export const SessionSummary = () => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const sessions = useSessions();

  const { selectedSessionTimestamp } = React.useContext(
    SessionSelectionContext
  );

  const selectedSession = sessions?.find(
    (session) => session.start_date_time_utc === selectedSessionTimestamp
  );
  const filteredEvents = selectedSession
    ? getFilteredEvents(selectedSession)
    : [];

  const startDateTime = moment(selectedSession?.start_date_time_utc);
  const endDateTime = moment(selectedSession?.end_date_time_utc);
  const duration = formattedDuration(endDateTime.diff(startDateTime) / 1000, t);

  const durationInMinutes = (endDateTime.diff(startDateTime) / 60000).toFixed(
    0
  );

  return (
    <div className={styles['container']}>
      <div className={styles['date-header']}>
        {`${formattedDate(
          selectedSession?.start_date_time_utc ?? '',
          locale
        )} ${startDateTime.format('H:mm')}~${endDateTime.format(
          'H:mm'
        )} (${duration})`}
      </div>
      <div className={styles['stats']}>
        <div className={styles['stats-marker']}>
          <div className={styles['stats-text']}>
            {t('Duration', {
              context: 'session',
            })}
          </div>
          <div className={styles['count']}>{durationInMinutes}</div>
          <div className={styles['stats-text']}>{t('min')}</div>
        </div>
        <div className={styles['stats-marker']}>
          <div className={styles['stats-text']}>{t('PV')}</div>
          <div className={styles['count']}>
            {filteredEvents.filter((e) => e.event_type === 'page-view').length}
          </div>
          {locale === 'ja' && <div className={styles['stats-text']}>回</div>}
        </div>
        {/*
        <div className={styles['stats-marker']}>
          <div className={styles['stats-text']}>{t('Progress')}</div>
          <div className={styles['count']}>10</div>
          <div className={styles['stats-text']}>%</div>
        </div>
        <div className={styles['stats-marker']}>
          <div className={styles['stats-text']}>{t('Health Score')}</div>
          <div className={styles['count']}>10</div>
          <div className={styles['stats-text']}>%</div>
        </div>
      */}
      </div>
    </div>
  );
};
