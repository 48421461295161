import { RefObject, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from 'client/components/v3/Common/Button';
import { ProductSummaryWithBookmark } from 'client/reducers/products';
import { impersonatedAccountIDSelector } from 'client/reducers/user';
import { addBookmark, removeBookmark } from 'client/actions/user';
import { DeleteProductModal } from 'client/pages/v3/Product/ProductList/DeleteProductModal';

import styles from './OperateButton.module.css';

interface Props {
  product: ProductSummaryWithBookmark;
  tableRef: RefObject<HTMLDivElement>;
}

export const OperateButton = ({ product, tableRef }: Props) => {
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLUListElement>(null);
  const [isMenuBottomPartlyHidden, setIsMenuBottomPartlyHidden] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const impersonating = useSelector(impersonatedAccountIDSelector);
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);

  // Check if menu is hidden by table overflow or not
  useEffect(() => {
    if (menuRef.current && tableRef.current) {
      const dropdownRect = menuRef.current.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();

      // Check if the dropdown's bottom edge is below the table's bottom edge
      if (dropdownRect.bottom > tableRect.bottom) {
        setIsMenuBottomPartlyHidden(true);
      } else {
        setIsMenuBottomPartlyHidden(false);
      }
    }
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  const addBookmarkHandler = (id: string) => {
    dispatch(addBookmark(id));
  };
  const removeBookmarkHandler = (id: string) => {
    dispatch(removeBookmark(id));
  };
  return (
    <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
      <div>
        <Button
          uiType="bg"
          size="icon"
          color="white"
          iconBeforeText={
            <i className="c-icon-solid-general-dots-vertical"></i>
          }
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      <ul
        className={clsx(
          styles['p-primary__select__menu'],
          showMenu && styles['is-active'],
          showMenu && isMenuBottomPartlyHidden && styles['open-above']
        )}
        ref={menuRef}
      >
        {!impersonating && (
          <>
            {product?.bookmarked ? (
              <li
                className={styles['p-primary__select__menu__item']}
                onClick={() => {
                  removeBookmarkHandler(product.id);
                  setShowMenu(false);
                }}
              >
                <div>
                  <i className="c-icon-outline-general-pin-02"></i>
                  <div>{t('Remove from top of list')}</div>
                </div>
              </li>
            ) : (
              <li
                className={styles['p-primary__select__menu__item']}
                onClick={() => {
                  addBookmarkHandler(product.id);
                  setShowMenu(false);
                }}
              >
                <div>
                  <i className="c-icon-outline-general-pin-02"></i>
                  <div>{t('Pin to top of list')}</div>
                </div>
              </li>
            )}
          </>
        )}
        <li className={styles['p-primary__select__menu__item']}>
          {/* TODO: remove v3 from url later */}
          <Link
            to={{
              pathname: `/products/${product.id}/edit-v3`,
            }}
          >
            {/* <Link
            to={{
              pathname: `/products/${product.id}/edit`,
            }}
          > */}
            <i className="c-icon-outline-general-edit-05"></i>
            <div>{t('Edit Product')}</div>
          </Link>
        </li>
        <li className={styles['p-primary__select__menu__item']}>
          {/* TODO: remove v3 from url later */}
          <Link
            to={{
              pathname: `/products/${product.id}/copy-v3`,
            }}
          >
            {/* <Link
            to={{
              pathname: `/products/${product.id}/copy`,
            }}
          > */}
            <i className="c-icon-outline-general-copy-01"></i>
            <div>{t('Copy Product')}</div>
          </Link>
        </li>
        <li
          className={clsx(
            styles['p-primary__select__menu__item'],
            styles['red']
          )}
          onClick={() => setShowModal(true)}
        >
          <div>
            <i className="c-icon-outline-general-trash-03"></i>
            <div>{t('Delete Product')}</div>
          </div>
        </li>
      </ul>
      {showModal && (
        <DeleteProductModal
          onClose={() => {
            setShowModal(false);
            setShowMenu(false);
          }}
          product={product}
        />
      )}
    </fieldset>
  );
};
