import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import * as Swagger from 'shared/models/swagger';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';

import styles from './PageTitle.module.css';
import { formatLandingSource } from './util';

interface Props {
  customer: Swagger.AnalyticsCustomer | undefined;
}

export const PageTitle = ({ customer }: Props) => {
  const { t } = useTranslation();

  const settings = useSelector(
    (state: ReduxState) => state.supplierSettings.journeyAnalytics
  );

  return (
    <div className={styles['title-container']}>
      {customer?.classification === 'BOOKED' ? (
        <Badge label={t('Booked')} color="success" />
      ) : (
        <Badge label={t('Not Booked')} color="orange" />
      )}
      <div className={styles['name']}>
        {customer?.customer_display_name ?? customer?.id?.substring(0, 8) ?? ''}
      </div>
      {customer?.landing_source && (
        <Badge
          label={formatLandingSource(customer?.landing_source)}
          color="gray"
        />
      )}

      {config.enableJourneyRegion && customer?.region && (
        <Badge label={customer?.region} color="gray" />
      )}
      {customer?.tags?.map((tag) => {
        const color = settings?.autotags?.find(
          (t) => t.tag_name === tag
        )?.tag_color;

        if (!color) {
          return null;
        }

        return <Badge key={tag} label={tag} color={color as BadgeColor} />;
      })}
      {customer?.reservation_id && (
        <Link to={`/reservations/${customer?.reservation_id}`}>
          <Button color="white" text={t('See Reservation')} />
        </Link>
      )}
    </div>
  );
};
