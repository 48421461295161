export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException'; // thrown from aws.cognito when password recovery code is wrong.

export const EMPTY_EMAIL_EXCEPTION = 'EmptyEmailException';
export const EMPTY_NEW_PASSWORD_EXCEPTION = 'EmptyNewPasswordException';
export const EMPTY_PASSWORD_EXCEPTION = 'EmptyPasswordException';
export const EMPTY_RECOVERY_CODE_EXCEPTION = 'EmptyRecoveryCodeException';
export const EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException'; // thrown from aws.cognito when password recovery code is expired.

export const INVALID_EMAIL_FORMAT_EXCEPTION = 'InvalidEmailFormatException';
export const INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException'; // thrown from aws.cognito when new password.length < 6

export const INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException'; // thrown from aws.cognito when new password doesn't conform to customized policy

export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException'; // thrown from aws.cognito when username || password is wrong

export const LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException'; // thrown from aws.cognito for excessive login / forget password attempts

export const PASSWORD_RESET_REQUIRED_EXCEPTION =
  'PasswordResetRequiredException';
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException'; // thrown from aws.cognito when username is not found

export const EMPTY_ONETIME_CODE_EXCEPTION = 'EmptyOnetimeCodeException';

export const INVALID_ONETIME_CODE_EXCEPTION = 'InvalidOnetimeCodeException';
