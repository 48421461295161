import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import {
  getGuestCounts,
  getAllUnits,
  sortETicketDailyUsesByProductSummaries,
  summarizeSameProductETicketDailyUse,
  getGuestCountByGuestTypeCounts,
} from 'client/pages/ETicket/ETicketDailyUses/util';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { separatedCurrency as currency } from 'shared/libraries/currency';
import { formattedAmount } from 'client/libraries/util/formattedAmount';
import type { ETicketDailyUse } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ETicketDailyUsesSummary.module.css';

type Props = {
  eTicketDailyUsesList: ETicketDailyUse[];
};

export const ETicketDailyUsesSummary = ({ eTicketDailyUsesList }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const summaries = useSelector(summariesSortedByBookmarkedSelector);
  const productsById = useSelector((state: ReduxState) => state.products.byID);

  const [isActives, setIsActives] = React.useState<Record<string, boolean>>({});

  sortETicketDailyUsesByProductSummaries(eTicketDailyUsesList, summaries);

  const allUnits = getAllUnits(t, eTicketDailyUsesList);

  const products: { [key: string]: ETicketDailyUse[] } =
    summarizeSameProductETicketDailyUse(eTicketDailyUsesList);

  const currencyCode = activeUserOrganization?.default_currency ?? '';

  let totalReservationCount = 0;
  let totalAmount = currency(0, currencyCode);
  let totalNet = currency(0, currencyCode);

  return (
    <div className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}>
      <table>
        <tbody>
          <tr>
            <th className={clsx(styles['table-width-230'])}>{t('Product')}</th>
            <th className={clsx(styles['table-width-112-mobile-80'])}>
              {t('Reservations')}
            </th>
            {Object.keys(allUnits).map((unit, idx) => (
              <th
                key={idx}
                className={clsx(styles['table-width-112-mobile-80'])}
              >
                {unit}
              </th>
            ))}
            <th className={clsx(styles['table-width-112-mobile-80'])}>
              {t('Gross')}
            </th>
            <th className={clsx(styles['table-width-112-mobile-80'])}>
              {t('Net')}
            </th>
          </tr>
          {Object.keys(products).map((productId, idx) => {
            const sameProductETicketDailyUsesList = products[productId];

            const reservationCount =
              sameProductETicketDailyUsesList?.length ?? 0;

            const sameProductTotalAmount =
              sameProductETicketDailyUsesList.reduce((a, b) => {
                const amount =
                  b?.reservation_billing_info?.amount_gross?.substring(3) ??
                  '0';
                return a.add(currency(amount));
              }, currency(0, currencyCode));
            const sameProductTotalNet = sameProductETicketDailyUsesList.reduce(
              (a, b) => {
                const net =
                  b?.reservation_billing_info?.amount_net?.substring(3) ?? '0';
                return a.add(currency(net));
              },
              currency(0, currencyCode)
            );

            const productName =
              sameProductETicketDailyUsesList[0].internal_product_name ??
              sameProductETicketDailyUsesList[0].product_name ??
              '';
            const hasOptions = Boolean(
              sameProductETicketDailyUsesList[0].checkin_info?.stubs?.length
            );

            const guestCounts: Record<string, number> = {};
            sameProductETicketDailyUsesList
              .map((p) => {
                return getGuestCounts(p.guests ?? []);
              })
              .forEach((g) => {
                Object.keys(g).forEach((key) => {
                  if (!guestCounts[key]) {
                    guestCounts[key] = 0;
                  }
                  guestCounts[key] += g[key];
                });
              });

            const stubKeyReservationCount: Record<string, number> = {};
            const checkinRecords: Record<string, Record<string, number>> = {};
            const checkinData: {
              stubKey: string;
              stubOptionKey: string;
              guest: any;
            }[] = [];
            sameProductETicketDailyUsesList.forEach((p) => {
              const isStubKeyReservationCounted: Record<string, boolean> = {};
              p?.checkin_info?.checkin_records?.forEach((c) => {
                const stubKey = `${c?.stub_key ?? ''} ${
                  c?.stub_option_key ?? ''
                }`;
                if (!isStubKeyReservationCounted[stubKey]) {
                  if (!stubKeyReservationCount[stubKey]) {
                    stubKeyReservationCount[stubKey] = 0;
                  }
                  stubKeyReservationCount[stubKey] += 1;
                  isStubKeyReservationCounted[stubKey] = true;
                }

                const guest =
                  (c.guest_type_counts || []).length > 0
                    ? getGuestCountByGuestTypeCounts(c.guest_type_counts)
                    : getGuestCounts(p.guests ?? []);
                checkinData.push({
                  stubKey: c.stub_key ?? '',
                  stubOptionKey: c?.stub_option_key ?? '',
                  guest: guest,
                });
              });
            });
            const desiredStubKeySort = productsById[
              productId
            ]?.qr_checkin_settings?.stubs?.map((s) => s.key);
            const desiredStubOptionSort: Record<string, string[]> = {};
            productsById[productId]?.qr_checkin_settings?.stubs?.forEach(
              (s) => {
                desiredStubOptionSort[s.key ?? ''] =
                  s?.options?.map((o) => o.key ?? '') ?? [];
              }
            );
            checkinData.sort((a, b) => {
              if (a.stubKey && b.stubKey && desiredStubKeySort) {
                if (
                  desiredStubKeySort?.indexOf(a?.stubKey) <
                  desiredStubKeySort?.indexOf(b?.stubKey)
                ) {
                  return -1;
                }
                if (
                  desiredStubKeySort?.indexOf(b?.stubKey) <
                  desiredStubKeySort?.indexOf(a?.stubKey)
                ) {
                  return 1;
                }
              }
              if (desiredStubOptionSort) {
                if (a.stubKey == b.stubKey) {
                  const key = a.stubKey;
                  if (
                    desiredStubOptionSort[key]?.indexOf(a?.stubOptionKey) <
                    desiredStubOptionSort[key]?.indexOf(b?.stubOptionKey)
                  ) {
                    return -1;
                  }
                  if (
                    desiredStubOptionSort[key]?.indexOf(b?.stubOptionKey) <
                    desiredStubOptionSort[key]?.indexOf(a?.stubOptionKey)
                  ) {
                    return 1;
                  }
                }
              }

              return 0;
            });
            checkinData.forEach((c) => {
              const guest = c.guest ?? [];
              const stubKey = `${c.stubKey} ${c.stubOptionKey ?? ''}`;

              if (!checkinRecords[stubKey]) {
                checkinRecords[stubKey] = {};
              }
              Object.keys(allUnits).forEach((key) => {
                if (!checkinRecords[stubKey][key]) {
                  checkinRecords[stubKey][key] = 0;
                }
              });
              Object.keys(allUnits).forEach((key) => {
                checkinRecords[stubKey][key] += guest[key] ?? 0;
              });
            });

            totalReservationCount = totalReservationCount + reservationCount;
            totalAmount = totalAmount.add(sameProductTotalAmount);
            totalNet = totalNet.add(sameProductTotalNet);

            return (
              <>
                <tr
                  key={idx}
                  className={clsx(
                    isActives[productId] ? styles['is-active'] : ''
                  )}
                >
                  <td className={styles['parent-line']}>
                    <div className={baseStyles['inline-block']}>
                      {hasOptions && (
                        <div
                          className={styles['ic']}
                          onClick={() => {
                            const newIsActives = { ...isActives };
                            newIsActives[productId] = !newIsActives[productId];
                            setIsActives(newIsActives);
                          }}
                        >
                          <span></span>
                        </div>
                      )}
                      {hasOptions ? (
                        productName
                      ) : (
                        <span style={{ paddingLeft: '28px' }}>
                          {productName}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className={clsx(styles['right-align'])}>
                    {reservationCount}
                  </td>
                  {Object.keys(allUnits).map((unit, idx) => (
                    <td key={idx} className={clsx(styles['right-align'])}>
                      {guestCounts[unit] ?? 0}
                    </td>
                  ))}
                  <td key="amount" className={clsx(styles['right-align'])}>
                    {formattedAmount(
                      sameProductTotalAmount.value,
                      currencyCode
                    )}
                  </td>
                  <td key="net" className={clsx(styles['right-align'])}>
                    {formattedAmount(sameProductTotalNet.value, currencyCode)}
                  </td>
                </tr>
                {isActives[productId] &&
                  Object.keys(checkinRecords).map((stubKey, idx) => {
                    return (
                      <tr key={idx} className={clsx(styles['child-line'])}>
                        <td
                          className={clsx(
                            styles['left-align'],
                            styles['left-padding-50']
                          )}
                        >
                          {stubKey}
                        </td>
                        <td key={idx} className={clsx(styles['right-align'])}>
                          {stubKeyReservationCount[stubKey]}
                        </td>
                        {Object.keys(checkinRecords[stubKey]).map(
                          (key, nestIdx) => {
                            return (
                              <td
                                key={nestIdx}
                                className={clsx(styles['right-align'])}
                              >
                                {checkinRecords[stubKey][key] ?? 0}
                              </td>
                            );
                          }
                        )}
                        <td
                          key="amount"
                          className={clsx(styles['right-align'])}
                        >
                          -
                        </td>
                        <td key="net" className={clsx(styles['right-align'])}>
                          -
                        </td>
                      </tr>
                    );
                  })}
              </>
            );
          })}
          <tr key={Object.keys(products).length + 1}>
            <td className={clsx(styles['right-align'])}>{t('Total')}</td>
            <td className={clsx(styles['right-align'])}>
              {totalReservationCount}
            </td>
            {Object.keys(allUnits).map((unit, idx) => (
              <td key={idx} className={clsx(styles['right-align'])}>
                {allUnits[unit] ?? 0}
              </td>
            ))}
            <td className={clsx(styles['right-align'])}>
              {formattedAmount(totalAmount.value, currencyCode)}
            </td>
            <td className={clsx(styles['right-align'])}>
              {formattedAmount(totalNet.value, currencyCode)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={clsx(styles['footer-note'])}>
        {t('Currency Code') + ' : ' + currencyCode}
      </div>
    </div>
  );
};
