import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateInquiry } from 'client/actions/inquiries';
import { Inquiry } from 'shared/models/swagger';

interface FormValues {
  tags: string[];
}

interface Props {
  inquiry: Inquiry;
  onClose: () => void;
}

export const EditTagsModal = ({ inquiry, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = React.useMemo(
    () => ({
      tags: inquiry.tags ?? [],
    }),
    [inquiry]
  );

  return (
    <Modal insertRoot title={t('Edit Tags')} open={true} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateInquiry(inquiry.id ?? '', {
              // If setting tags, pass tags
              // If clearing tags, pass sentinel value
              tags: values.tags.length > 0 ? values.tags : [''],
            })
          );
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <ListEditor name="tags" />
            </Modal.Content>
            <Modal.Actions>
              <Button style="gray" size="middle" onClick={() => form.reset()}>
                {t('Discard')}
              </Button>
              <Button
                style="blue"
                size="middle"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
