import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { TextArea, Button } from 'client/components/Form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Box } from 'client/components/Box/Box';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import baseStyles from 'client/base.module.css';
import type { SourceLanguage } from 'shared/models/swagger';

import { DraggableItemInputForm } from './DraggableItemInputForm';
import localStyles from './LineMessageContentModal.module.css';

type Props = {
  name: string;
  translationTargetLanguage: SourceLanguage | null;
};

export const LineMessageContentFormTab = ({
  name,
  translationTargetLanguage,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={localStyles['c-table-list']}>
      <table>
        <tbody style={{ width: '100%' }}>
          {translationTargetLanguage && <TranslationTableHeader />}
          <TranslatedField name={`${name}.header`}>
            {({ input, translationInput }) => (
              <tr>
                <th>{t('Header')}</th>
                <td>
                  <div className={baseStyles['base-flex']}>
                    <TextArea
                      value={input.value}
                      onChange={(_, { value }) => {
                        input.onChange(value);
                      }}
                    />
                  </div>
                </td>
                {translationTargetLanguage && (
                  <td>
                    <TextArea
                      value={translationInput.value}
                      onChange={(_, { value }) => {
                        translationInput.onChange(value);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </TranslatedField>
          <tr>
            <th>{t('Items')}</th>
            <td colSpan={translationTargetLanguage ? 2 : 1}>
              <FieldArray name={`${name}.items`}>
                {({ fields }) => (
                  <>
                    <div className={localStyles['c-table-list']}>
                      <Box mb={2}>
                        <Button
                          type="button"
                          style="green"
                          size="middle"
                          onClick={() => {
                            fields.push({});
                          }}
                        >
                          {t('Add New Item')}
                        </Button>
                      </Box>
                      <table>
                        <tbody style={{ width: '100%' }}>
                          {translationTargetLanguage && (
                            <TranslationTableHeader hideThTag={true} />
                          )}
                          {fields.map((name, idx) => (
                            <DraggableItemInputForm
                              key={idx}
                              idx={idx}
                              name={name}
                              translationTargetLanguage={
                                translationTargetLanguage
                              }
                              onRemoveClick={() => {
                                fields.remove(idx);
                              }}
                              onMoveItem={(
                                dragIndex: number,
                                hoverIndex: number
                              ) => {
                                const dragItem = fields.value[dragIndex];
                                const newItems = [...fields.value];
                                newItems.splice(dragIndex, 1);
                                newItems.splice(hoverIndex, 0, dragItem);
                                newItems.forEach((item, index) => {
                                  fields.update(index, item);
                                });
                              }}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <span className={localStyles['c-table-list__comment']}>
                      {t('Drag-and-drop to reorder')}
                    </span>
                  </>
                )}
              </FieldArray>
            </td>
          </tr>
          <TranslatedField name={`${name}.footer`}>
            {({ input, translationInput }) => (
              <tr>
                <th>{t('Footer')}</th>
                <td>
                  <div className={baseStyles['base-flex']}>
                    <TextArea
                      value={input.value}
                      onChange={(_, { value }) => {
                        input.onChange(value);
                      }}
                    />
                  </div>
                </td>
                {translationTargetLanguage && (
                  <td>
                    <TextArea
                      value={translationInput.value}
                      onChange={(_, { value }) => {
                        translationInput.onChange(value);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </TranslatedField>
        </tbody>
      </table>
    </div>
  );
};
