import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Select } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Box } from 'client/components/Box/Box';
import { fetchSurveyTemplateTranslations } from 'client/actions/translations';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { ReduxState } from 'client/reducers';
import { getLanguageName } from 'client/libraries/i18n';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import baseStyles from 'client/base.module.css';

import styles from './SurveyTemplateEditor.module.css';
import { FormValues, SurveyPage } from './formValues';
import { TranslatedInputContainer } from './TranslatedInputContainer';
import { EditSurveyPageModal } from './EditSurveyPageModal';
import { DraggableSurveyPage } from './DraggableSurveyPage';

interface Props {
  surveyTemplateId: string;
}

export const SurveyContentEditor = ({ surveyTemplateId }: Props) => {
  const { t } = useTranslation();
  const form = useForm();

  const { values } = useFormState<FormValues>();

  const dispatch = useDispatch();

  const showTranslationMode = values?.showTranslationMode ?? '';

  const translationTargetLanguage = values?.showTranslationMode
    ? values?.translationTargetLanguage ?? null
    : null;

  const translationTargetLanguageName = translationTargetLanguage
    ? getLanguageName(translationTargetLanguage, t)
    : '';

  React.useEffect(() => {
    if (surveyTemplateId != null) {
      dispatch(fetchSurveyTemplateTranslations(surveyTemplateId));
    }
  }, [surveyTemplateId]);

  const surveyTemplateTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  React.useEffect(() => {
    let translations: FormValues['translations'] = [];
    if (
      values?.sourceLanguage &&
      translationTargetLanguage &&
      surveyTemplateId
    ) {
      const sourceFieldName = values?.sourceLanguage.toLowerCase();
      const translationFieldName = translationTargetLanguage.toLowerCase();
      translations = surveyTemplateTranslations.map((trans) => ({
        source: trans[sourceFieldName as keyof typeof trans] ?? '',
        target: trans[translationFieldName as keyof typeof trans] ?? '',
      }));
    }

    form.change('translations', translations);
  }, [
    form,
    values?.sourceLanguage,
    surveyTemplateTranslations,
    translationTargetLanguage,
    surveyTemplateId,
  ]);

  const [showEditPageModal, setShowEditPageModal] = React.useState(false);

  return (
    <TranslationLanguageContext.Provider value={translationTargetLanguage}>
      <Box mt={4}>
        {(values?.translatedLanguages ?? []).length > 0 && (
          <div className={baseStyles['base-main__body__header']}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spSpacebetween'],
                baseStyles['spOrder-1']
              )}
            >
              <div className={baseStyles['base-form-toggle']}>
                <label>
                  <Field name="showTranslationMode" type="checkbox">
                    {({ input }) => <input type="checkbox" {...input} />}
                  </Field>
                  <p></p>
                </label>
                {t('Translation mode')}
              </div>
              {showTranslationMode && (
                <Field name="translationTargetLanguage">
                  {({ input }) => (
                    <Select
                      width={176}
                      options={(values?.translatedLanguages ?? []).map(
                        (option: any) => ({
                          value: option,
                          text: getLanguageName(option, t),
                        })
                      )}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </div>
          </div>
        )}
      </Box>
      <p className={styles['table__section__header']}>{t('Survey Content')}</p>
      <FormTableBox>
        <table>
          <tbody>
            <tr>
              <th>{t('Title')}</th>
              <td>
                <TranslatedField name="title">
                  {({ input, translationInput }) => (
                    <TranslatedInputContainer>
                      <Input
                        {...input}
                        label={
                          translationTargetLanguage
                            ? getLanguageName(values?.sourceLanguage, t)
                            : ''
                        }
                      />
                      {translationTargetLanguage && (
                        <Input
                          {...translationInput}
                          label={translationTargetLanguageName}
                        />
                      )}
                    </TranslatedInputContainer>
                  )}
                </TranslatedField>
              </td>
            </tr>
            <tr>
              <th>{t('Pages')}</th>
              <td>
                <FieldArray name="pages">
                  {({ fields }) => (
                    <>
                      {/* Do not show add button if survey type = 'NPS' */}
                      {values.surveyType === 'NORMAL' && (
                        <Box mb={2}>
                          {t('* drag-and-drop to reorder')}
                          <Button
                            style="green"
                            size="middle"
                            onClick={() => setShowEditPageModal(true)}
                          >
                            {t('Add page')}
                          </Button>
                        </Box>
                      )}
                      {showEditPageModal && (
                        <EditSurveyPageModal
                          initialName={`${fields.name}[${fields.length}]`}
                          onClose={() => setShowEditPageModal(false)}
                        />
                      )}
                      {fields.map((name, idx) => (
                        <DraggableSurveyPage
                          key={fields.value?.[idx]?.key}
                          name={name}
                          index={idx}
                          moveItem={(dragIndex: number, hoverIndex: number) => {
                            fields.move(dragIndex, hoverIndex);
                          }}
                          deleteItem={async () => {
                            fields.remove(idx);
                          }}
                          onSave={(value: SurveyPage) =>
                            form.change(name, value)
                          }
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </td>
            </tr>
          </tbody>
        </table>
      </FormTableBox>
    </TranslationLanguageContext.Provider>
  );
};
