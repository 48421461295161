import * as React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Loading } from 'client/pages/Loading';

import styles from './GoogleMyBusiness.module.css';
import { LocationEditModal } from './LocationEditModal';
import { ServicesEditModal } from './ServicesEditModal';
import { MediaEditModal } from './MediaEditModal';

declare global {
  interface Window {
    onGoogleScriptLoad: any;
    gapi: any;
  }
}

const googleClientId =
  '22510836789-j2358sq8b6c9571o9gvlkvvf3ioo9qob.apps.googleusercontent.com';

const loadGoogleScript = () => {
  //loads the Google JavaScript Library
  const id = 'google-js';
  const src = 'https://apis.google.com/js/platform.js';
  //we have at least one script (React)
  const firstJs = document.getElementsByTagName('script')[0];

  //prevent script from loading twice
  if (document.getElementById(id)) {
    return;
  }

  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.onload = window.onGoogleScriptLoad;
  firstJs.parentNode?.insertBefore(js, firstJs);
};

export const GoogleMyBusiness = () => {
  const [googleAuth, setGoogleAuth] = React.useState<any>(null);
  const [accountName, setAccountName] = React.useState('');
  const [locations, setLocations] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [needsRefresh, setNeedsRefresh] = React.useState(true);
  const [
    selectedLocationNameForManageModal,
    setSelectedLocationNameForManageModal,
  ] = React.useState('');
  const [
    selectedLocationNameForServicesModal,
    setSelectedLocationNameForServicesModal,
  ] = React.useState('');
  const [
    selectedLocationNameForPhotosModal,
    setSelectedLocationNameForPhotosModal,
  ] = React.useState('');
  const [loginSucceeded, setLoginSucceeded] = React.useState(false);

  const { t } = useTranslation();

  const logOut = () => {
    (async () => {
      await googleAuth?.signOut();
      setLoginSucceeded(false);
      renderSigninButton(window.gapi);
    })();
  };

  const renderSigninButton = (_gapi: any) => {
    _gapi.signin2.render('google-signin', {
      scope:
        'profile email https://www.googleapis.com/auth/plus.business.manage',
      width: 240,
      height: 50,
      longtitle: true,
      theme: 'dark',
      onsuccess: () => setLoginSucceeded(true),
      /*
      onfailure: onFailure,
      */
    });
  };

  const getIdToken = React.useCallback(() => {
    const user = window.gapi?.auth2.getAuthInstance().currentUser.get();
    return user?.getAuthResponse(true)?.access_token ?? '';
  }, [loginSucceeded]);

  const isLoggedIn = React.useMemo(() => !!getIdToken(), [getIdToken]);

  React.useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const accountsResp = await axios.get(
          'https://mybusinessaccountmanagement.googleapis.com/v1/accounts',
          {
            headers: {
              authorization: `Bearer ${getIdToken()}`,
            },
          }
        );

        const accounts = accountsResp.data.accounts;
        if (accounts && accounts.length > 0) {
          setAccountName(accounts[0].name);
        }
      }
    })();
  }, [isLoggedIn]);

  React.useEffect(() => {
    (async () => {
      if (isLoggedIn && accountName && needsRefresh && !loading) {
        setLoading(true);

        const locationsResp = await axios.get(
          `https://mybusinessaccountmanagement.googleapis.com/v1/${accountName}/locations`,
          {
            params: {
              read_mask:
                'name,title,categories,phoneNumbers,profile,websiteUri,metadata,serviceItems,storefrontAddress,regularHours',
            },
            headers: {
              authorization: `Bearer ${getIdToken()}`,
            },
          }
        );

        setLoading(false);
        setLocations(locationsResp.data.locations);

        setNeedsRefresh(false);
      }
    })();
  }, [isLoggedIn, accountName, loading, needsRefresh]);

  React.useEffect(() => {
    //window.gapi is available at this point
    window.onGoogleScriptLoad = () => {
      const _gapi = window.gapi;

      _gapi.load('auth2', () => {
        (async () => {
          const _googleAuth = await _gapi.auth2.init({
            client_id: googleClientId,
          });

          setGoogleAuth(_googleAuth);
          renderSigninButton(_gapi);
        })();
      });
    };

    //ensure everything is set before loading the script
    loadGoogleScript();
  }, []);

  console.log(locations);

  return (
    <div>
      {!isLoggedIn && (
        <div className={styles['login-container']} id="google-signin"></div>
      )}
      {loading && <Loading />}

      {isLoggedIn && (
        <div>
          <Button style="green" size="middle" onClick={logOut}>
            {t('Log Out')}
          </Button>
          <h3>{t('Locations')}</h3>
          {locations?.map((location: any) => (
            <div className={styles['location-card']} key={location.name}>
              <h4>{location.title}</h4>
              <Box mb={2}>
                {location.categories?.primaryCategory &&
                  t('Category: {{categoryName}}', {
                    categoryName:
                      location.categories?.primaryCategory?.displayName,
                  })}
              </Box>
              <Box mb={2}>
                <div>{t('Address:')}</div>
                {location.storefrontAddress?.addressLines.map(
                  (line: string, idx: number) => (
                    <div key={idx}>{line}</div>
                  )
                )}
                {location.storefrontAddress?.locality &&
                  `${location.storefrontAddress?.locality}, `}
                {location.storefrontAddress?.administrativeArea &&
                  `${location.storefrontAddress?.administrativeArea} `}
                {location.storefrontAddress?.postalCode}
              </Box>

              <Box mb={2}>
                <a
                  className={styles['link']}
                  href={location.metadata.mapsUri}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('View In Google')}
                </a>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  style="green"
                  size="small"
                  onClick={() =>
                    setSelectedLocationNameForManageModal(location.name)
                  }
                >
                  {t('Basic Info')}
                </Button>
                {location.categories && (
                  <Box ml={2}>
                    <Button
                      style="green"
                      size="small"
                      onClick={() =>
                        setSelectedLocationNameForServicesModal(location.name)
                      }
                    >
                      {t('Products')}
                    </Button>
                  </Box>
                )}
                <Box ml={2}>
                  <Button
                    style="green"
                    size="small"
                    onClick={() =>
                      setSelectedLocationNameForPhotosModal(location.name)
                    }
                  >
                    {t('Photos')}
                  </Button>
                </Box>
              </Box>
              {selectedLocationNameForManageModal && (
                <LocationEditModal
                  onClose={() => setSelectedLocationNameForManageModal('')}
                  location={location}
                  onSave={async (location: any) => {
                    await axios.patch(
                      `https://mybusinessaccountmanagement.googleapis.com/v1/${selectedLocationNameForManageModal}`,
                      {
                        ...location,
                      },
                      {
                        params: {
                          updateMask:
                            'title,profile,phoneNumbers,websiteUri,regularHours',
                        },
                        headers: {
                          authorization: `Bearer ${getIdToken()}`,
                        },
                      }
                    );

                    setNeedsRefresh(true);
                  }}
                />
              )}
              {selectedLocationNameForServicesModal && (
                <ServicesEditModal
                  onClose={() => setSelectedLocationNameForServicesModal('')}
                  location={location}
                  onSave={async (locationPatch: any) => {
                    await axios.patch(
                      `https://mybusinessaccountmanagement.googleapis.com/v1/${selectedLocationNameForServicesModal}`,
                      {
                        ...locationPatch,
                      },
                      {
                        params: {
                          updateMask: 'serviceItems',
                        },
                        headers: {
                          authorization: `Bearer ${getIdToken()}`,
                        },
                      }
                    );

                    setNeedsRefresh(true);
                  }}
                />
              )}
              {selectedLocationNameForPhotosModal && (
                <MediaEditModal
                  onClose={() => setSelectedLocationNameForPhotosModal('')}
                  location={location}
                  accountName={accountName}
                  onSave={async (locationPatch: any) => {
                    await axios.patch(
                      `https://mybusinessaccountmanagement.googleapis.com/v1/${selectedLocationNameForPhotosModal}`,
                      {
                        ...locationPatch,
                      },
                      {
                        params: {
                          updateMask: 'serviceItems',
                        },
                        headers: {
                          authorization: `Bearer ${getIdToken()}`,
                        },
                      }
                    );

                    setNeedsRefresh(true);
                  }}
                  idToken={getIdToken()}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
