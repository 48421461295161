import type { Pricing } from 'shared/models/swagger';

export const getPriceSchedulesActiveInDateRange = (
  priceSchedules: Pricing[],
  startDate: string,
  endDate: string
): Pricing[] => {
  return priceSchedules
    .filter(
      (priceSched) =>
        !priceSched.start_date_local ||
        !endDate ||
        priceSched.start_date_local <= endDate
    )
    .filter(
      (priceSched) =>
        !priceSched.end_date_local ||
        !startDate ||
        priceSched.end_date_local >= startDate
    );
};
