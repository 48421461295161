import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Modal } from 'client/components/v3/Form/Modal';
import { memosSelector } from 'client/reducers/memos';
import {
  fetchMemos,
  createMemo,
  updateMemo,
  deleteMemo,
} from 'client/actions/memos';
import { getArrayMutators } from 'client/libraries/util/form';
import baseStyles from 'client/v3-base.module.css';

type FormValues = {
  content: string;
};

type Props = {
  memoKey: string;
  open: boolean;
  onClose?: () => void;
};
export const MemoModal = ({ memoKey, open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memos = useSelector(memosSelector);

  useEffect(() => {
    if (memoKey) {
      dispatch(fetchMemos(memoKey));
    }
  }, [memoKey]);

  const memo = memos.find((memo) => memo.key === memoKey);
  const title = t('Edit memo');
  const initialValue = memo
    ? {
        content: memo.content,
      }
    : {
        content: '',
      };

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        try {
          if (memo) {
            if (values.content) {
              await dispatch(
                updateMemo(memo.id, {
                  content: values.content,
                })
              );
            } else {
              dispatch(deleteMemo(memo.id));
            }
          } else {
            if (values.content) {
              await dispatch(
                createMemo({
                  key: memoKey,
                  content: values.content,
                })
              );
            }
          }

          onClose && (await onClose());
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={initialValue}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              title={title}
              open={open}
              onClose={() => {
                onClose && onClose();
              }}
              rightActionChildren={
                <>
                  <Button
                    text={t('Discard')}
                    onClick={() => {
                      onClose && onClose();
                    }}
                    color="white"
                  />
                  <Button
                    text={t('Save')}
                    loading={submitting}
                    color="primary"
                    type="submit"
                  />
                </>
              }
            >
              <>
                <p style={{ marginBottom: '6px' }}>{t('Memo')}</p>
                <Field name={'content'}>
                  {({ input, meta: { touched, error } }) => (
                    <TextField {...input} error={touched && error} />
                  )}
                </Field>
                {submitError && (
                  <p className={baseStyles['u-error-msg']}>{submitError}</p>
                )}
              </>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};
