import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getValidators } from 'shared/libraries/validate/validator';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Radio } from 'client/components/v3/Form/Radio';

import styles from './EnumRadioButtonGroup.module.css';

type EnumRadioButtonsProps = {
  disabled?: boolean;
  name: string;
  options: {
    label: string;
    value: string;
    tooltipText?: string;
    disabled?: boolean;
  }[];
  onChange?: (newValue: string) => void;
  style?: Record<string, any>;
  liStyle?: Record<string, any>;
  isRequired?: boolean;
  defaultChecked?: string;
};

export const EnumRadioButtonGroup = ({
  disabled,
  name,
  options,
  onChange,
  style,
  liStyle,
  isRequired,
  defaultChecked,
}: EnumRadioButtonsProps) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  return (
    <Field
      name={name}
      validate={isRequired ? required : undefined}
      initialValue={defaultChecked}
    >
      {({ input, meta: { touched, error } }) => (
        <>
          <ul className={styles['c-enumRadioButtonGroup']}>
            {options.map((option) => (
              <li key={option.value} style={liStyle}>
                <div
                  className={
                    disabled || option.disabled ? styles['disabled'] : undefined
                  }
                >
                  <Radio
                    label={option.label}
                    disabled={disabled || option.disabled}
                    checked={option.value === input.value}
                    onChange={() => {
                      onChange?.(option.value);
                      input.onChange(option.value);
                    }}
                    size="sm"
                    style={style}
                  />
                </div>
                {option.tooltipText && (
                  <Tooltip text={option.tooltipText}>
                    <i className="c-icon-outline-general-info-circle"></i>
                  </Tooltip>
                )}
              </li>
            ))}
          </ul>
          {touched && error && (
            <p className={baseStyles['u-error-msg']}>{error}</p>
          )}
        </>
      )}
    </Field>
  );
};
