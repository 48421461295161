import * as React from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { activeUserSelector } from 'client/reducers/user';
import { s3MediaDownloadStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import baseStyles from 'client/base.module.css';

import type { DownloadItem } from './formValues';
import { DraggablePreviewDownloadItem } from './DraggablePreviewDownloadItem';
import styles from './DownloadItemInput.module.css';

type Props = {
  mediaItems: DownloadItem[];
  onChange: (newValue: DownloadItem[]) => void;
  error?: string;
  hidePriceInput?: boolean;
};
export const DownloadItemInput = ({
  mediaItems,
  onChange,
  error,
  hidePriceInput,
}: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const imageFileInputRef = React.useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = ['image/jpeg', 'image/png', 'video/mp4'];
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (imageFileInputRef?.current) {
      imageFileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const handleMoveImage = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = mediaItems[dragIndex];
      const newmediaItems = [...mediaItems];
      newmediaItems.splice(dragIndex, 1);
      newmediaItems.splice(hoverIndex, 0, dragImage);
      onChange(newmediaItems);
    },
    [mediaItems]
  );

  const uploadFiles = async (files: File[]) => {
    const newDownloadItems: DownloadItem[] = [];
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadInProgress(true);

    for (const file of files) {
      try {
        const filename = getRandomFilenameForUpload(file);
        const objName = `${organizationId}/${filename}`;
        await s3MediaDownloadStorage.put(objName, file);
        const url = await s3MediaDownloadStorage.get(objName);
        // Remove pre-signed suffix
        const uploadedFileURL = url.split('?')[0];
        newDownloadItems.push({
          key: '',
          url: uploadedFileURL,
          mediaType: uploadedFileURL.endsWith('.mp4') ? 'VIDEO' : 'IMAGE',
        });
        onChange([...mediaItems, ...newDownloadItems]);
      } catch (err) {
        console.log(err);
        /* TODO */
      }
    }

    setUploadInProgress(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <ul className={styles['picture']}>
        {mediaItems.map((mediaItem, idx) => (
          <li key={mediaItem.url} className={styles['picture__item']}>
            <DraggablePreviewDownloadItem
              mediaItem={mediaItem}
              index={idx}
              onRemove={() =>
                onChange(mediaItems.filter((i) => i.url !== mediaItem.url))
              }
              onMoveImage={handleMoveImage}
              onChange={(newValue) =>
                onChange(
                  mediaItems.map((existingItem) =>
                    existingItem.url === newValue.url ? newValue : existingItem
                  )
                )
              }
              hidePriceInput={hidePriceInput}
            />
          </li>
        ))}

        <li className={styles['picture__item']}>
          <a
            {...getRootProps()}
            className={clsx(
              styles['picture__item__add'],
              isDragActive && styles['dragActive']
            )}
            onClick={handleAddImageClick}
          ></a>
          <input
            {...getInputProps()}
            multiple
            ref={imageFileInputRef}
            id="image-file-input"
            type="file"
            name="name"
            accept={acceptedFileTypes.join(',')}
            style={{
              display: 'none',
            }}
            onChange={handleFileInputChange}
          />
        </li>
      </ul>
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}

      <Box mb={2}>
        <div>{t('* jpg/png/mp4 format')}</div>
        <div>{t('* drag-and-drop to reorder')}</div>
      </Box>
    </>
  );
};
