import _ from 'lodash';

import { allConfig } from 'shared/config';

export const config = _.merge(
  allConfig.default,
  allConfig[
    (process.env.NUTMEG_ENV || 'development') as
      | 'development'
      | 'staging'
      | 'production'
  ]
);
