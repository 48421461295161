import moment from 'moment-timezone';

import { HubspotChatWidget } from './HubspotChatWidget';
import { ChannelIO } from './ChannelIO';

export const ChatWidget = () => {
  const timezone = moment.tz.guess();

  if (timezone === 'Asia/Tokyo') {
    return <ChannelIO />;
  }

  return <HubspotChatWidget />;
};
