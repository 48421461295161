// @flow

import useSWR from 'swr';
import { useSelector } from 'react-redux';

import type { HelpArticleData } from 'client/pages/Help/HelpArticle/types';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers/index';

const fetcher = (url: string, token: string, lang: string) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
    },
  }).then((res) => res.json());

export const useData = (
  language: string,
  id: string
): { data: HelpArticleData, isLoading: boolean, error: string } => {
  const lang = language === 'en' || language === 'ja' ? language : 'ja';

  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const { data, error } = useSWR(
    [`/api/helps/${encodeURIComponent(id)}`, token, lang],
    fetcher
  );

  return {
    data,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
