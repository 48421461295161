import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import { ModalLoader } from 'client/components/ModalLoader';
import * as Swagger from 'shared/models/swagger';

type Props<FormValues> = {
  disabled?: boolean;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  title: string;
  initialValues: FormValues;
  convertFormValuesToProductPatch: (
    formValues: FormValues
  ) => Swagger.Product$Patch;
  children: React.ReactNode;
  width?: 'narrow' | 'wide';
  debug?: (params: any) => void;
};
export function ProductEditorModal<FormValues>({
  children,
  debug,
  disabled,
  loading,
  open,
  onClose,
  title,
  initialValues,
  width,
  convertFormValuesToProductPatch,
}: Props<FormValues>) {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState<boolean>(false);
  return (
    <Modal title={title} open={open} onClose={onClose} width={width}>
      <ProductEditorForm
        onSubmitStart={() => setSuccess(false)}
        onSubmitSuccess={() => setSuccess(true)}
        initialValues={initialValues}
        convertFormValuesToProductPatch={convertFormValuesToProductPatch}
        debug={debug}
      >
        {({ submitError, submitting }) => (
          <>
            <Modal.Content>
              {loading && !submitting ? <ModalLoader /> : children}
              <div>
                {success && <Message success header={t('Save Successful')} />}
                {submitError && <Message error header={t('Save Failed')} />}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={disabled || loading}
                loading={submitting}
                style="green"
                size="middle"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </>
        )}
      </ProductEditorForm>
    </Modal>
  );
}
