import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment-timezone';
import { useContext, useMemo } from 'react';

import { updateReservation } from 'client/actions/reservations';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  convertFormValuesToReservationPatch,
  getInitialValues,
} from 'client/formValues/ReservationTransportRouteFormValues';
import type { FormValues } from 'client/formValues/ReservationTransportRouteFormValues';
import { ReservationContext } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationContext';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import baseStyles from 'client/v3-base.module.css';
import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';

type Props = {
  onClose: () => void;
};
export const EditTransportRouteModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservation = useContext(ReservationContext);
  const initialValues = useMemo(
    () => getInitialValues(reservation),
    [reservation]
  );
  return (
    <Form
      onSubmit={async (values: FormValues) => {
        const patch: any = convertFormValuesToReservationPatch(
          values,
          reservation?.start_date_time_utc ?? '',
          reservation?.start_timezone ?? ''
        );
        await dispatch(updateReservation(reservation?.id ?? '', patch));
        onClose?.();
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            open={true}
            onClose={onClose}
            title={t('Edit Transport Routes')}
            rightActionChildren={
              <>
                <Button text={t('Close')} color="white" onClick={onClose} />
                <Button text={t('Save')} loading={submitting} type="submit" />
              </>
            }
            style={{ width: '800px' }}
          >
            <>
              <table
                className={tableStyles['c-tableSmall']}
                style={{ overflow: 'hidden' }}
              >
                <tbody>
                  <FieldArray name="transportRouteItems">
                    {({ fields }) =>
                      fields.map((name, idx) => (
                        <>
                          <tr>
                            <th
                              rowSpan={2}
                              className={baseStyles['u-width-48']}
                            >{`#${idx + 1}`}</th>
                            <td className={baseStyles['u-width-128']}>
                              {t('Departure')}
                            </td>
                            <td className={baseStyles['u-width-128']}>
                              <Field name={`${name}.locationFrom.hhMm`}>
                                {({ input }) =>
                                  idx === 0 ? (
                                    <div>{input.value}</div>
                                  ) : (
                                    <TimePicker
                                      allowEmpty={false}
                                      value={moment(input.value, 'HH:mm')}
                                      onChange={(newValue) => {
                                        if (!newValue) {
                                          return;
                                        }

                                        input.onChange(
                                          newValue.format('HH:mm')
                                        );
                                      }}
                                    />
                                  )
                                }
                              </Field>
                            </td>
                            <td>
                              <Field name={`${name}.locationFrom.locationName`}>
                                {({ input }) =>
                                  idx === 0 ? (
                                    <div>{input.value}</div>
                                  ) : (
                                    <LocationSearchInput
                                      location={input.value}
                                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                                      onSearchChange={() => {}}
                                      onLocationSelect={({
                                        title,
                                        key,
                                      }: {
                                        title: string;
                                        key: string;
                                      }) => {
                                        input.onChange(title);
                                        form.change(
                                          `${name}.locationFrom.googlePlaceId` as any,
                                          key
                                        );
                                      }}
                                    />
                                  )
                                }
                              </Field>
                            </td>
                          </tr>
                          <tr>
                            <td>{t('Arrival')}</td>
                            <td>
                              <Field name={`${name}.locationTo.hhMm`}>
                                {({ input }) =>
                                  idx === (fields?.value ?? []).length - 1 ? (
                                    <div>{input.value}</div>
                                  ) : (
                                    <TimePicker
                                      allowEmpty={false}
                                      value={moment(input.value, 'HH:mm')}
                                      onChange={(newValue) => {
                                        if (!newValue) {
                                          return;
                                        }

                                        input.onChange(
                                          newValue.format('HH:mm')
                                        );
                                      }}
                                    />
                                  )
                                }
                              </Field>
                            </td>
                            <td>
                              <Field name={`${name}.locationTo.locationName`}>
                                {({ input }) =>
                                  idx === (fields?.value ?? []).length - 1 ? (
                                    <div>{input.value}</div>
                                  ) : (
                                    <LocationSearchInput
                                      location={input.value}
                                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                                      onSearchChange={() => {}}
                                      onLocationSelect={({
                                        title,
                                        key,
                                      }: {
                                        title: string;
                                        key: string;
                                      }) => {
                                        input.onChange(title);
                                        form.change(
                                          `${name}.locationFrom.googlePlaceId` as any,
                                          key
                                        );
                                      }}
                                    />
                                  )
                                }
                              </Field>
                            </td>
                          </tr>
                        </>
                      ))
                    }
                  </FieldArray>
                </tbody>
              </table>
            </>
          </Modal>
        </form>
      )}
    </Form>
  );
};
