import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
  CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_REQUEST,
  CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS,
  CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_FAILURE,
  DELETE_EQUIPMENT_ASSIGNMENT_FAILURE,
  DELETE_EQUIPMENT_ASSIGNMENT_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST,
  BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BULK_MOVE_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BULK_MOVE_EQUIPMENT_ASSIGNMENTS_REQUEST,
  BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST,
  BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE,
  BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST,
  DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  EquipmentAssignment,
  ListEquipmentAssignmentsResponse,
} from 'shared/models/swagger';

export const equipmentAssignmentsSelector = (state: ReduxState) =>
  state.equipmentAssignments.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_FAILURE:
    case DELETE_EQUIPMENT_ASSIGNMENT_FAILURE:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE:
      return action.error;

    case FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS:
    case DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_REQUEST:
    case DELETE_EQUIPMENT_ASSIGNMENT_REQUEST:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST:
      return true;

    case FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_FAILURE:
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS:
    case DELETE_EQUIPMENT_ASSIGNMENT_FAILURE:
    case DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE:
    case DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const uploadLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST:
      return true;

    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: EquipmentAssignment[] = [],
  action: any
): EquipmentAssignment[] => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS: {
      const response = action.response as ListEquipmentAssignmentsResponse;
      return [...(response.equipment_assignments ?? [])];
    }
    case BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS: {
      const responses = action.responses as ListEquipmentAssignmentsResponse[];
      return [
        ...responses.reduce(
          (acc, response) => [
            ...acc,
            ...(response.equipment_assignments ?? []),
          ],
          [] as EquipmentAssignment[]
        ),
      ];
    }
    case CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS: {
      const response = action.response as EquipmentAssignment;
      return [...state.filter((i) => i.id !== response.id), response];
    }
    case DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS:
    case DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS: {
      const id = action.id as string;
      return [...state.filter((i) => i.id !== id)];
    }
    case BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS:
    case BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS: {
      return [
        ...state.filter(
          (i) =>
            !action.response.unassigned_equipment_assignment_ids?.includes(i.id)
        ),
        ...(action.response.equipment_assignments ?? []),
      ];
    }

    default:
      return state;
  }
};

export const equipmentAssignments = combineReducers({
  error,
  loading,
  all,
  uploadLoading,
});
