// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TranslatorProps } from 'react-i18next';
import clsx from 'clsx';

import { ConfirmReservationButtonV2 as ConfirmReservationButton } from 'client/components/ConfirmReservationButton';
import { StandbyReservationButtonV2 as StandbyReservationButton } from 'client/components/StandbyReservationButton';
import { DeclineReservationButtonV2 as DeclineReservationButton } from 'client/components/DeclineReservationButton';
import { WithdrawReservationButtonV2 as WithdrawReservationButton } from 'client/components/WithdrawReservationButton';
import { CancelWithFeeModalV2 as CancelWithFeeModal } from 'client/pages/ReservationDetails/CancelWithFeeModalV2';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { CancelModalV2 as CancelModal } from 'client/pages/ReservationDetails/CancelModalV2';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import {
  filteredStatuses,
  getCancellationDeadline,
  getCancellationText,
  getNextStatus,
  getReservationWeightedParticipantCount,
} from 'client/libraries/util/util';
import type {
  Reservation,
  Product,
  ProductInstance,
} from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';
import { isPartnershipComponentReservation } from 'client/libraries/util/partnership';

import statusRqOffIcon from '../../images/ic_status_rq_off.svg';
import statusRqOnIcon from '../../images/ic_status_rq_on.svg';
import statusStandbyOffIcon from '../../images/ic_status_standby_off.svg';
import statusStandbyOnIcon from '../../images/ic_status_standby_on.svg';
import statusCheckOffIcon from '../../images/ic_status_check_off.svg';
import statusCheckOnIcon from '../../images/ic_status_check_on.svg';
import statusCancelOffIcon from '../../images/ic_status_cancel_off.svg';
import statusCancelOnIcon from '../../images/ic_status_cancel_on.svg';
import baseStyles from '../../base.module.css';
import pageStyles from '../pages.module.css';

type OwnProps = {
  readOnly?: boolean,
  reservation: Reservation,
  productInstance?: ProductInstance,
  product?: Product,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  cancelConfirmOpen: boolean,
};

const statusIconName = (status) => {
  switch (status) {
    case 'NO_SHOW':
      return null;
    case 'PENDING':
    case 'CANCELLATION_PENDING':
    case 'AWAITING_RESERVATION_CONFIRMATION':
    case 'AWAITING_UPDATE_CONFIRMATION':
    case 'REQUESTED':
    case 'CANCEL_REQUESTED_BY_AGENT':
      return {
        on: statusRqOnIcon,
        off: statusRqOffIcon,
      };
    case 'STANDBY':
    case 'WAITLISTED':
      return {
        on: statusStandbyOnIcon,
        off: statusStandbyOffIcon,
      };
    case 'CONFIRMED':
      return {
        on: statusCheckOnIcon,
        off: statusCheckOffIcon,
      };
    case 'PARTICIPATED': // TODO(goro) no icon
      return {
        on: statusStandbyOnIcon,
        off: statusStandbyOffIcon,
      };
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
      return {
        on: statusCancelOnIcon,
        off: statusCancelOffIcon,
      };
    default:
      return null;
  }
};

const isResubmittableStatus = (status) => {
  switch (status) {
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
      return true;
    default:
      return false;
  }
};

export class StatusStepsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cancelConfirmOpen: false,
    };
  }

  handleCancelCancel = () =>
    this.setState({
      cancelConfirmOpen: false,
    });

  render() {
    const {
      activeUser,
      locale,
      readOnly,
      reservation,
      product,
      productInstance,
      t,
    } = this.props;

    const cancelDeadline = getCancellationDeadline(reservation, product);

    const statuses = filteredStatuses(reservation.status_history || []);

    const nextStatus =
      statuses.length > 0
        ? getNextStatus(statuses[statuses.length - 1].status)
        : '';

    const nextStatusIcon = statusIconName(nextStatus);

    const currentParticipantCount =
      productInstance?.weighted_participant_count || 0;
    const resParticipantCount = getReservationWeightedParticipantCount(
      reservation,
      productInstance
    );
    const participantCountAfterCancel =
      currentParticipantCount - resParticipantCount;

    let cancelWarning = '';
    const minParticipantCount =
      (product?.minimum_participant_count &&
        product?.minimum_participant_count.value) ||
      0;
    if (
      minParticipantCount > 0 &&
      participantCountAfterCancel < minParticipantCount
    ) {
      cancelWarning = t(
        'After cancelling this booking, the participant count for the date and time will be below minimum participant requirements.'
      );
    }

    const userIsPassthroughAgent =
      reservation.agent_side_passthrough_reservation_id &&
      activeUser.organization_type === 'AGENT';
    const userIsPassthroughSupplier =
      reservation.supplier_side_passthrough_reservation_id &&
      activeUser.organization_type === 'SUPPLIER';
    const userIsPassthroughOrg =
      userIsPassthroughAgent || userIsPassthroughSupplier;

    const userCanConfirmReservation =
      !readOnly &&
      !userIsPassthroughOrg &&
      operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
    const userCanSetCancellationFee =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationCancellationFee') &&
      !isPartnershipComponentReservation(reservation) &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
    const userCanCancelReservation =
      !readOnly &&
      !userIsPassthroughAgent &&
      !isPartnershipComponentReservation(reservation) &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
    const userCanWithdrawReservation =
      !readOnly &&
      !userIsPassthroughAgent &&
      !operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
    const userCanResubmitReservation =
      !readOnly &&
      !userIsPassthroughOrg &&
      !isPartnershipComponentReservation(reservation) &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');

    let packagePendingThirdPartyConfirmation: boolean = false;
    const packageComponentReservations =
      reservation.package_component_reservation_summaries || [];
    if (
      packageComponentReservations.length > 0 &&
      reservation.status === 'REQUESTED'
    ) {
      packagePendingThirdPartyConfirmation = true;
      for (let packageComponentReservation of packageComponentReservations) {
        if (
          !packageComponentReservation.supplier_side_passthrough_reservation_id &&
          packageComponentReservation.status !== 'CONFIRMED'
        ) {
          packagePendingThirdPartyConfirmation = false;
        }
      }
    }

    return (
      <>
        {statuses.map((s, idx) =>
          (() => {
            const isActive = statuses.length - 1 === idx;
            const statusChangedAt = t('{{date}} ({{fromNow}})', {
              date: moment(s.status_date_time_utc).locale(locale).format('ll'),
              fromNow: moment(s.status_date_time_utc).locale(locale).fromNow(),
            });

            const iconPair = statusIconName(s.status);

            return (
              <li key={idx}>
                <div
                  className={clsx(
                    pageStyles['page-reservations__status'],
                    isActive ? pageStyles['is-active'] : ''
                  )}
                >
                  {iconPair !== null && (
                    <img
                      className={clsx(
                        pageStyles['page-reservations__status__ic']
                      )}
                      src={isActive ? iconPair.on : iconPair.off}
                    />
                  )}

                  <div
                    className={clsx(
                      pageStyles['page-reservations__status__info']
                    )}
                  >
                    <p
                      className={clsx(
                        pageStyles['page-reservations__status__info__ttl']
                      )}
                    >
                      {t(s.status)}
                    </p>

                    {!isActive ? (
                      <p
                        className={clsx(
                          pageStyles['page-reservations__status__info__date']
                        )}
                      >
                        {statusChangedAt}
                      </p>
                    ) : (
                      <>
                        {s.status === 'REQUESTED' ? (
                          userCanConfirmReservation ? (
                            <div>
                              {packagePendingThirdPartyConfirmation ? (
                                <div>
                                  {t('** Pending third-party confirmation **')}
                                </div>
                              ) : (
                                <div
                                  className={
                                    baseStyles['status-step-inline-block']
                                  }
                                >
                                  <div
                                    className={baseStyles['status-step-btn']}
                                  >
                                    <ConfirmReservationButton
                                      reservationID={reservation.id}
                                      trigger={
                                        <button
                                          type={'button'}
                                          className={clsx(
                                            baseStyles['base-btn'],
                                            baseStyles['small'],
                                            baseStyles['green']
                                          )}
                                        >
                                          {t('Confirm')}
                                        </button>
                                      }
                                    />
                                  </div>
                                  <div
                                    className={baseStyles['status-step-btn']}
                                  >
                                    <StandbyReservationButton
                                      reservationID={reservation.id}
                                      trigger={
                                        <button
                                          type={'button'}
                                          className={clsx(
                                            baseStyles['base-btn'],
                                            baseStyles['small'],
                                            baseStyles['yellow']
                                          )}
                                        >
                                          {t('Standby')}
                                        </button>
                                      }
                                    />
                                  </div>
                                  <div
                                    className={baseStyles['status-step-btn']}
                                  >
                                    <DeclineReservationButton
                                      reservationID={reservation.id}
                                      trigger={
                                        <button
                                          type={'button'}
                                          className={clsx(
                                            baseStyles['base-btn'],
                                            baseStyles['small'],
                                            baseStyles['red']
                                          )}
                                        >
                                          {t('Decline')}
                                        </button>
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : userCanWithdrawReservation ? (
                            <div
                              className={baseStyles['status-step-inline-block']}
                            >
                              <div className={baseStyles['status-step-btn']}>
                                <WithdrawReservationButton
                                  reservationID={reservation.id}
                                  trigger={
                                    <button
                                      type={'button'}
                                      className={clsx(
                                        baseStyles['base-btn'],
                                        baseStyles['small'],
                                        baseStyles['red']
                                      )}
                                    >
                                      {t('Withdraw')}
                                    </button>
                                  }
                                />
                              </div>
                            </div>
                          ) : null
                        ) : userCanConfirmReservation &&
                          s.status === 'STANDBY' ? (
                          <div>
                            <div>
                              {s.until_date_time_utc &&
                                t('Until: {{untilDate}}', {
                                  untilDate: moment(s.until_date_time_utc)
                                    .locale(locale)
                                    .format('lll'),
                                })}
                            </div>
                            <div>
                              <div
                                className={
                                  baseStyles['status-step-inline-block']
                                }
                              >
                                <div className={baseStyles['status-step-btn']}>
                                  <ConfirmReservationButton
                                    reservationID={reservation.id}
                                    trigger={
                                      <button
                                        type={'button'}
                                        className={clsx(
                                          baseStyles['base-btn'],
                                          baseStyles['small'],
                                          baseStyles['green']
                                        )}
                                      >
                                        {t('Confirm')}
                                      </button>
                                    }
                                  />
                                </div>
                                <div className={baseStyles['status-step-btn']}>
                                  <DeclineReservationButton
                                    reservationID={reservation.id}
                                    trigger={
                                      <button
                                        type={'button'}
                                        className={clsx(
                                          baseStyles['base-btn'],
                                          baseStyles['small'],
                                          baseStyles['red']
                                        )}
                                      >
                                        {t('Decline')}
                                      </button>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : s.status === 'CONFIRMED' ||
                          s.status === 'CANCEL_DECLINED_BY_SUPPLIER' ? (
                          userCanSetCancellationFee ? (
                            <div className={baseStyles['status-step-btn']}>
                              <CancelWithFeeModal
                                reservation={reservation}
                                warning={cancelWarning}
                                trigger={
                                  <button
                                    type={'button'}
                                    className={clsx(
                                      baseStyles['base-btn'],
                                      baseStyles['small'],
                                      baseStyles['red']
                                    )}
                                  >
                                    {t('Cancel')}
                                  </button>
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              {moment(cancelDeadline).isBefore(moment()) ? (
                                <div>
                                  {t('Cancel deadline expired: {{date}}', {
                                    date: moment(cancelDeadline)
                                      .locale(locale)
                                      .fromNow(),
                                  })}
                                </div>
                              ) : userCanCancelReservation ? (
                                <div className={baseStyles['status-step-btn']}>
                                  <CancelModal
                                    reservationID={reservation.id}
                                    text={t(
                                      '{{cancelFeeDescription}} Are you sure you wish to cancel?',
                                      {
                                        cancelFeeDescription:
                                          getCancellationText(
                                            reservation,
                                            product,
                                            productInstance,
                                            t,
                                            locale
                                          ),
                                      }
                                    )}
                                    trigger={
                                      <button
                                        type={'button'}
                                        className={clsx(
                                          baseStyles['base-btn'],
                                          baseStyles['small'],
                                          baseStyles['red']
                                        )}
                                      >
                                        {t('Cancel')}
                                      </button>
                                    }
                                  />
                                  {cancelDeadline && (
                                    <span
                                      style={{
                                        color: '#ff495a',
                                      }}
                                    >
                                      <i>
                                        {t('Deadline: {{date}} ({{fromNow}})', {
                                          date: moment(cancelDeadline)
                                            .locale(locale)
                                            .format('ll'),
                                          fromNow: moment(cancelDeadline)
                                            .locale(locale)
                                            .fromNow(),
                                        })}
                                      </i>
                                    </span>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          )
                        ) : (
                          <div className={baseStyles['status-step-btn']}>
                            {isResubmittableStatus(s.status) &&
                              userCanResubmitReservation && (
                                <Link
                                  to={{
                                    pathname: `/products/${
                                      product?.id || ''
                                    }/instances/${
                                      productInstance?.id || ''
                                    }/book`,
                                    state: {
                                      reservation,
                                      isResubmitReservation: true,
                                    },
                                  }}
                                >
                                  <button
                                    type={'button'}
                                    className={clsx(
                                      baseStyles['base-btn'],
                                      baseStyles['small'],
                                      baseStyles['green']
                                    )}
                                  >
                                    {t('Resubmit booking')}
                                  </button>
                                </Link>
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </li>
            );
          })()
        )}

        {nextStatus && (
          <li>
            <div className={clsx(pageStyles['page-reservations__status'])}>
              {nextStatusIcon !== null && (
                <img
                  className={clsx(pageStyles['page-reservations__status__ic'])}
                  src={nextStatusIcon.off}
                />
              )}
              <div
                className={clsx(pageStyles['page-reservations__status__info'])}
              >
                <p
                  className={clsx(
                    pageStyles['page-reservations__status__info__ttl']
                  )}
                >
                  {t(nextStatus)}
                </p>
                <p
                  className={clsx(
                    pageStyles['page-reservations__status__info__date']
                  )}
                >
                  {t('TBD')}
                </p>
              </div>
            </div>
          </li>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    locale: state.language.selected.iso,
    activeUser: activeUserSelector(state),
  };
};

export const StatusStepsV2 = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, null),
  withTranslation()
)(StatusStepsComponent);
