import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { convertAccessReportSettingsToQueryParams } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/utils';
import { fetchDigitalMapAccessReportData } from 'client/actions/digitalMapAccessReportData';
import styles from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReport.module.css';
import { CompareTablePane } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/component/CompareTablePane';
import { TotalTablePane } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/component/TotalTablePane';
import { DetailTablePane } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/component/DetailTablePane';
import type { ReduxState } from 'client/reducers';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { setLastDigitalMapAccessReportSettings } from 'client/actions/digitalMapAccessReportSettings';
import {
  convertAccessReportDataSetsToGraphData,
  convertAccessReportDataSetsToTableData,
} from 'client/libraries/util/digitalMapAccessReportSettings';
import type { DigitalMapAccessReportSettings } from 'client/libraries/util/digitalMapAccessReportSettings';
import { Radio } from 'client/components/v3/Form/Radio';
import baseStyles from 'client/v3-base.module.css';
import { ChartPane } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/component/ChartPane';

export const DigitalMapAccessReportGraphBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const reportData = useSelector(
    (state: ReduxState) => state.digitalMapAccessReportData.reportData
  );
  const lastDigitalMapAccessReportSettings = useSelector(
    (state: ReduxState) =>
      state.digitalMapAccessReportSettings.lastDigitalMapAccessReportSettings
  );
  const [digitalMapAccessReportSettings, setDigitalMapAccessReportSettings] =
    React.useState<DigitalMapAccessReportSettings>(
      lastDigitalMapAccessReportSettings
    );

  const graphPaneHeight = 600;
  const getBarDateKeys = () => {
    if (digitalMapAccessReportSettings.chartDisplayType === 'PV') {
      return reportData.length === 1
        ? ['visitCount']
        : ['visitCount', 'compareVisitCount'];
    }

    return reportData.length === 1
      ? ['userCount']
      : ['userCount', 'compareUserCount'];
  };

  const names = {
    visitCount: t('visitCount'),
    compareVisitCount: t('compareVisitCount'),
    userCount: t('userCount'),
    compareUserCount: t('compareUserCount'),
  };
  const graphData = React.useMemo(() => {
    return convertAccessReportDataSetsToGraphData(reportData);
  }, [reportData]);
  const tableData = React.useMemo(() => {
    return convertAccessReportDataSetsToTableData(reportData);
  }, [reportData]);

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchDigitalMapAccessReportData(
          convertAccessReportSettingsToQueryParams(
            digitalMapAccessReportSettings
          )
        )
      );
      dispatch(
        setLastDigitalMapAccessReportSettings(digitalMapAccessReportSettings)
      );
    }
  }, [digitalMapAccessReportSettings.unit]);

  return (
    <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
      <div className={styles['p-reservations']}>
        <div className={styles['p-reservations__header']}>
          <div
            className={styles['p-reports__body__item__flex']}
            style={{ alignItems: 'center' }}
          >
            <p className={styles['p-reports__body__item__radiotitle']}>
              {t('Display')}
            </p>
            <Radio
              label={t('visitCount')}
              checked={digitalMapAccessReportSettings.chartDisplayType === 'PV'}
              onChange={() => {
                setDigitalMapAccessReportSettings({
                  ...digitalMapAccessReportSettings,
                  chartDisplayType: 'PV',
                });
              }}
              size="sm"
            />
            <Radio
              label={t('userCount')}
              checked={
                digitalMapAccessReportSettings.chartDisplayType === 'USER'
              }
              onChange={() => {
                setDigitalMapAccessReportSettings({
                  ...digitalMapAccessReportSettings,
                  chartDisplayType: 'USER',
                });
              }}
              size="sm"
            />
          </div>
          <div className={styles['p-reservations__dropdown']}>
            <span className={styles['p-reservations__dropdown-txt']}>
              {t('Aggregation')}
            </span>
            <ul className={styles['p-reservations__tab']}>
              <li
                className={clsx(
                  digitalMapAccessReportSettings.unit === 'DAY'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  setDigitalMapAccessReportSettings({
                    ...digitalMapAccessReportSettings,
                    unit: 'DAY',
                  });
                }}
              >
                {t('day')}
              </li>
              <li
                className={clsx(
                  digitalMapAccessReportSettings.unit === 'WEEK'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  setDigitalMapAccessReportSettings({
                    ...digitalMapAccessReportSettings,
                    unit: 'WEEK',
                  });
                }}
              >
                {t('week')}
              </li>
              <li
                className={clsx(
                  digitalMapAccessReportSettings.unit === 'MONTH'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  setDigitalMapAccessReportSettings({
                    ...digitalMapAccessReportSettings,
                    unit: 'MONTH',
                  });
                }}
              >
                {t('month')}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles['p-reservations__graf']}>
          <div
            className={styles['graph-pane-wrapper']}
            style={{
              height: graphPaneHeight,
            }}
          >
            <ChartPane
              data={graphData}
              barDataKeys={getBarDateKeys()}
              names={names}
            />
          </div>
        </div>
        <div style={{ width: '100%', padding: '20px 24px' }}>
          {reportData?.length === 1 && (
            <div>
              <p>{t('Total')}</p>
              <TotalTablePane data={tableData} />
            </div>
          )}
          {reportData?.length === 2 && (
            <div style={{ width: '100%', padding: '20px 0' }}>
              <p>{t('Compare')}</p>
              <CompareTablePane dataSets={reportData} />
            </div>
          )}

          {reportData?.length === 1 && (
            <div style={{ width: '100%', padding: '20px 0' }}>
              <p>{t('Details')}</p>
              <DetailTablePane data={tableData} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
