import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { compose } from 'recompose';
import { withTranslation, TFunction } from 'react-i18next';

import { Message } from 'client/components/Message/Message';
import { Button, Input } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import { changePassword } from 'client/actions/user';
import baseStyles from 'client/base.module.css';

import styles from './Settings.module.css';

type Dispatch = ThunkDispatch<any, any, any>;
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  I18nProps;
/* eslint-enable no-use-before-define */

type State = {
  oldPassword: string;
  newPassword: string;
  newPasswordHidden: boolean;
  error: string;
  success: boolean;
};

class ChangePasswordComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordHidden: true,
      error: '',
      success: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.lastChangingPasswordStatus !==
        this.props.lastChangingPasswordStatus &&
      this.props.lastChangingPasswordStatus === 'SUCCEEDED'
    ) {
      this.setState({
        success: true,
        error: '',
        oldPassword: '',
        newPassword: '',
      });
    }

    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error,
        success: false,
        oldPassword: '',
        newPassword: '',
      });
    }
  }

  toggleNewPasswordHidden = () => {
    this.setState((prevState) => ({
      newPasswordHidden: !prevState.newPasswordHidden,
    }));
  };
  onInputChanged = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    } as any);
  };

  render() {
    const { t } = this.props;
    const { oldPassword, newPassword, error, success } = this.state;
    return (
      <div>
        <div className={styles['input-box']}>
          <Input
            type="password"
            label={t('Old password')}
            name="oldPassword"
            value={oldPassword}
            onChange={this.onInputChanged}
          />
        </div>
        <div className={styles['input-box']}>
          <Input
            type={this.state.newPasswordHidden ? 'password' : 'text'}
            label={t('New password')}
            name="newPassword"
            value={newPassword}
            onChange={this.onInputChanged}
          />
          <div className={baseStyles['base-form-checkbox']}>
            <label
              style={{
                background: '#fff',
              }}
            >
              <input
                onChange={() =>
                  this.setState({
                    newPasswordHidden: !this.state.newPasswordHidden,
                  })
                }
                type="checkbox"
                checked={!this.state.newPasswordHidden}
              />
              <p></p>
              {t('Show Password')}
            </label>
          </div>
        </div>
        {error && (
          <Message error header={t('Password change failed')} content={error} />
        )}
        {success && <Message success header={t('Password change succeeded')} />}
        <div className={styles['button-box']}>
          <Button
            style="red"
            size="middle"
            onClick={(e) => {
              e.preventDefault();
              this.props.onChangePassword(
                this.state.oldPassword,
                this.state.newPassword
              );
            }}
          >
            {t('Change Password')}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    error: state.user.changingPasswordError,
    lastChangingPasswordStatus: state.user.lastChangingPasswordStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangePassword: (oldPassword: string, newPassword: string) =>
    dispatch(changePassword(oldPassword, newPassword)),
});

export const ChangePassword = compose<Props, Record<string, never>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ChangePasswordComponent);
