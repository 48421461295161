import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import { fetchSiteControlWidgetReportData } from 'client/actions/siteControlWidgets';

import styles from './SiteControlWidgetDashboard.module.css';
import { Gadgets } from './Gadgets';

type DateRange = {
  start: string;
  end: string;
};

export const SiteControlWidgetDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<DateRange>(
    getDateRangeForPreset('PREV_7_DAYS')
  );
  const [comparisonDateRange, setComparisonDateRange] =
    useState<DateRange | null>(null);

  useEffect(() => {
    const dateRanges = [{ start: dateRange.start, end: dateRange.end }];
    if (comparisonDateRange?.start && comparisonDateRange?.end) {
      dateRanges.push({
        start: comparisonDateRange.start,
        end: comparisonDateRange.end,
      });
    }

    dispatch(fetchSiteControlWidgetReportData(dateRanges));
  }, [
    dateRange.start,
    dateRange.end,
    comparisonDateRange?.start,
    comparisonDateRange?.end,
  ]);

  return (
    <V3Page>
      <PageHeader title={t('Site Hacker Dashboard')} hideNewUIToggle={true}>
        <div className={styles['p-siteControlWidget__header']}>
          <ComparisonDateInput
            baseDateRange={dateRange}
            setBaseDateRange={setDateRange}
            comparisonDateRange={comparisonDateRange}
            setComparisonDateRange={setComparisonDateRange}
            menuStyle={{ right: 0, left: 'auto' }}
          />
        </div>
      </PageHeader>
      <PageContent>
        <Gadgets />
      </PageContent>
    </V3Page>
  );
};
