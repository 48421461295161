import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type { Product } from 'shared/models/swagger';

const fetcher = (url: string, token: string) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());

export const useSourceLanguageProduct = (
  id: string
): {
  data: Product;
  isLoading: boolean;
  error: string;
} => {
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const { data, error } = useSWR(
    [
      `/api/products/${encodeURIComponent(id)}?ignore_request_language=true`,
      token,
    ],
    fetcher
  );
  return {
    data,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
