import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { equipmentsSelector } from 'client/reducers/equipments';
import { fetchEquipments } from 'client/actions/equipments';
import { fetchProducts } from 'client/actions/products';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import {
  EquipmentShape,
  toEquipmentShape,
} from 'client/libraries/util/equipmentShape';
import type { TranslateFuncType } from 'client/components/Translate';
import { Box } from 'client/components/Box/Box';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';

import { EquipmentScheduleModal } from './EquipmentScheduleModal';
import { EquipmentScheduleDisplayBox } from './EquipmentScheduleDisplayBox';

export const EquipmentList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);
  const [editEquipmentId, setEditEquipmentId] = React.useState('');
  const [showEquipmentScheduleModal, setShowEquipmentScheduleModal] =
    React.useState(false);

  const equipments = useSelector(equipmentsSelector);

  const equipmentShapes = equipments.map((equipment) => {
    return toEquipmentShape(equipment);
  });

  React.useEffect(() => {
    dispatch(fetchEquipments());
    dispatch(fetchProducts());
  }, []);

  const getColumns = (t: TranslateFuncType): ColumnType<EquipmentShape>[] => {
    return [
      {
        Header: t('Title'),
        id: 'title',
        accessor: (item: EquipmentShape) => {
          return item.title;
        },
        width: 200,
      },
      {
        Header: t('Schedules'),
        id: 'schedules',
        accessor: (item: EquipmentShape) => {
          return (
            <Box display="flex" alignItems="center">
              <div>
                <EquipmentScheduleDisplayBox
                  equipmentId={item.id ?? ''}
                  key={item.id}
                />
              </div>
              <a
                className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                style={{ marginRight: '10px' }}
                data-text={t('Edit')}
                onClick={() => {
                  setEditEquipmentId(item.id ?? '');
                  setShowEquipmentScheduleModal(true);
                }}
              >
                <img src={editIcon} />
              </a>
            </Box>
          );
        },
      },
    ];
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box__body'])}>
      <GenericTable<EquipmentShape>
        items={(equipmentShapes ?? []).filter((_, index) => {
          const start = (currentPage - 1) * rowCount;
          const end = start + rowCount;
          return index >= start && index < end;
        })}
        totalCount={equipmentShapes?.length ?? 0}
        columns={getColumns(t)}
        rowCount={rowCount}
        currentPage={currentPage}
        onRowCountChange={(rowCount: number) => {
          setRowCount(rowCount);
        }}
        onCurrentPageChange={(newCurrentPage: number) => {
          setCurrentPage(newCurrentPage);
        }}
        hideScrollButtons={true}
      />

      {showEquipmentScheduleModal && (
        <EquipmentScheduleModal
          equipmentId={editEquipmentId}
          onClose={() => {
            setShowEquipmentScheduleModal(false);
          }}
          open={showEquipmentScheduleModal}
        />
      )}
    </div>
  );
};
