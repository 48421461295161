import * as React from 'react';

import { getBookingWidgetEmbeddedCalendarUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCalendarUrl';

import { BookNowButtonEditor } from './BookNowButtonEditor';

type Props = {
  bookingWidgetApiKey: string;
  selectedProductId: string;
  widgetLanguage: string;
};

export const BookingWidgetSingleProductBookNowButton = ({
  widgetLanguage,
  selectedProductId,
  bookingWidgetApiKey,
}: Props) => {
  const calendarUrl = `${getBookingWidgetEmbeddedCalendarUrl(
    bookingWidgetApiKey,
    selectedProductId,
    'BASIC_WITH_PRICES',
    widgetLanguage
  )}&isModal=true`;

  return (
    <BookNowButtonEditor calendarUrl={calendarUrl} calendarType="SINGLE" />
  );
};
