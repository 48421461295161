import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import {
  SET_EQUIPMENT_AVAILABILITY_START_DATE,
  SET_EQUIPMENT_AVAILABILITY_END_DATE,
  SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE,
  SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE,
  SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE,
  SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';

// Selectors

export const equipmentCalendarListDatesSelector = createSelector(
  (state: ReduxState): string => state.equipmentCalendarListControls.startDate,
  (state: ReduxState): string => state.equipmentCalendarListControls.endDate,
  (startDate, endDate) => {
    if (endDate < startDate) {
      return [];
    }

    const end = moment(endDate);
    const dates: string[] = [];
    for (
      let m = moment(startDate);
      m.isBefore(end);
      m = moment(m).add(1, 'days')
    ) {
      dates.push(m.format('YYYY-MM-DD'));
    }

    return dates;
  }
);

// Reducers

const startDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_EQUIPMENT_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const endDate = (
  state = moment().add(7, 'days').format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_EQUIPMENT_AVAILABILITY_END_DATE:
      return action.payload;
    default:
      return state;
  }
};

const weeklyAvailabilityStartDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const pageSize = (state = 10, action: any) => {
  switch (action.type) {
    case SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const currentPage = (state = 0, action: any) => {
  switch (action.type) {
    case SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE:
      return action.page;

    default:
      return state;
  }
};

const monthlyEquipmentAvailabilitySelectedMonth = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH:
      return action.payload;
    default:
      return state;
  }
};
const reducer = combineReducers({
  startDate,
  endDate,
  weeklyAvailabilityStartDate,
  pageSize,
  currentPage,
  monthlyEquipmentAvailabilitySelectedMonth,
});
export const equipmentCalendarListControls = reducer;
