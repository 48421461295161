import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import moment from 'moment-timezone';

import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  ToggleButton,
  TextArea,
  DateTimeInput,
  FieldWrapper,
  Button,
} from 'client/components/Form';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import type { ReduxState } from 'client/reducers/index';
import { updateReservation } from 'client/actions/reservations';
import baseStyles from 'client/base.module.css';
import * as Swagger from 'shared/models/swagger';

import type { FormValues } from './formValues';
import { convertFormValuesToReservation } from './formValues';

type Props = {
  onClose: () => void;
  open: boolean;
  reservation: Swagger.Reservation;
};

export const ReservationPinModal = ({ onClose, open, reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <Modal
      title={t('Pin reservation')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        onSubmit={async (values: FormValues) => {
          const newReservation = convertFormValuesToReservation(
            values,
            reservation
          );
          await dispatch(updateReservation(reservation.id, newReservation));
          onClose();
        }}
        initialValues={{
          isPinned: reservation?.pin_info?.is_pinned,
          dueDateTimeUtc: moment
            .tz(
              reservation?.pin_info?.due_date_time_utc,
              reservation?.start_timezone ?? ''
            )
            .locale(locale),
          memo: reservation?.pin_info?.memo,
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="isPinned" type="checkbox">
                  {({ input }) => {
                    return (
                      <ToggleButton
                        checked={input.checked ?? false}
                        onChange={() => input.onChange(!input.checked)}
                        label={t('Pin reservation')}
                      />
                    );
                  }}
                </Field>
              </Modal.Box>

              <Modal.Box column="two">
                <Field name="dueDateTimeUtc">
                  {({ input }) => (
                    <DateTimeInput
                      label={t('Pinned until')}
                      value={input.value}
                      onChange={(date) => {
                        const newDate = input.value
                          .clone()
                          .set('year', date.year())
                          .set('month', date.month())
                          .set('date', date.date());
                        input.onChange(newDate);
                      }}
                    />
                  )}
                </Field>

                <FieldWrapper label={t('Time')}>
                  <Field name="dueDateTimeUtc">
                    {({ input }) => (
                      <TimePicker
                        allowEmpty={false}
                        value={input.value}
                        onChange={(time) => {
                          if (!time) {
                            return;
                          }

                          const newDate = input.value
                            .clone()
                            .set('hour', time.hour())
                            .set('minute', time.minute());
                          input.onChange(newDate);
                        }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <Field name="memo">
                  {({ input, meta: { touched, error } }) => (
                    <TextArea
                      label={t('Memo')}
                      value={input.value}
                      onChange={(_, { value }) => {
                        input.onChange(value);
                      }}
                      height={80}
                      maxWidth={800}
                      showCharacterCount
                      error={touched && error}
                    ></TextArea>
                  )}
                </Field>
              </Modal.Box>
            </Modal.Content>

            <Modal.Actions>
              {submitError && (
                <p className={baseStyles['base-form-box__err']}>
                  {submitError}
                </p>
              )}
              <Button.Cancel
                onClick={() => {
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
