import { v4 as uuidv4 } from 'uuid';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useState, useContext, useMemo, useCallback } from 'react';

import { FocusableInput } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { timezoneOptions } from 'client/libraries/util/timezoneOptions';
import { getResourceTypeText } from 'client/libraries/util/resourceManager';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { findOverlappingDateRange } from 'client/pages/ProductEditor/ReservationParametersEditor/util';
import {
  getAvailabilityRuleScheduleText,
  schedulesHaveRequestOnly,
  schedulesHaveFreesaleOnly,
} from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { AvailabilityDatesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AvailabilityDatesInput';
import { StartTimesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/StartTimesInput';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import baseStyles from 'client/v3-base.module.css';

const getDefaultAvailabilityAllotmentSchedule =
  (): AvailabilityAllotmentSchedule => {
    const defaultStartDate = moment().format('YYYY-MM-DD');
    const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');
    return {
      dateRanges: [
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
        },
      ],
      weekdays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      closedDates: [],
      startTimes: [
        {
          time: '9:00',
          duration: '3:00',
          allotmentSlots: 10,
          description: '',
          perChannelAllotments: [],
          packageComponentTimeSlots: [],
        },
      ],
      key: uuidv4(),
    };
  };

export const AvailabilityAllotmentEditor = () => {
  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );

  const recurrence = useMemo(() => {
    if (
      editingProduct?.recurrence &&
      editingProduct?.recurrence?.length > activeScheduleIndex
    ) {
      return editingProduct?.recurrence[activeScheduleIndex];
    }
    return undefined;
  }, [editingProduct?.recurrence, activeScheduleIndex]);

  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');
  const validateNoOverlaps = useCallback(
    (schedules: AvailabilityAllotmentSchedule[]): any | null | undefined => {
      for (const [idx, schedule] of schedules.entries()) {
        const overlap = findOverlappingDateRange(
          schedule,
          schedules.slice(idx + 1)
        );

        if (overlap) {
          return t(
            'Duplicate participation rules for {{startDate}} ~ {{endDate}}. Please edit date range or add closed dates.',
            {
              startDate: overlap.startDate,
              endDate: overlap.endDate,
            }
          );
        }
      }

      return undefined;
    },
    [t]
  );
  const formState = useFormState();
  const isRequestOnlyProduct = schedulesHaveRequestOnly(
    formState?.values?.availabilityAllotmentSchedules
  );
  const isFreesaleOnlyProduct = schedulesHaveFreesaleOnly(
    formState?.values?.availabilityAllotmentSchedules
  );

  return (
    <FieldArray
      name="availabilityAllotmentSchedules"
      validate={validateNoOverlaps as any}
    >
      {({ fields, meta: { error, touched } }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <SingleDropdown
                options={fields.value.map(
                  (availabilityAllotmentSchedule, idx) => ({
                    text: getAvailabilityRuleScheduleText(
                      availabilityAllotmentSchedule,
                      t
                    ),
                    value: idx.toString(),
                  })
                )}
                selectedOption={activeScheduleIndex.toString()}
                onChange={(e) => {
                  setActiveScheduleIndex(parseInt(e));
                }}
              />
              <a
                className={styles['p-products__section__body__add']}
                onClick={() => {
                  fields.push(getDefaultAvailabilityAllotmentSchedule());
                  setActiveScheduleIndex(fields.length ?? 0);
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Participation Rule')}
              </a>
            </div>
            {/* TODO: need to style these error related items later */}
            {error && typeof error === 'string' && (
              <FocusableInput name="availabilityAllotmentSchedules" />
            )}
            {touched && error && typeof error === 'string' && (
              <p className={baseStyles['u-error-msg']}>{error}</p>
            )}
          </div>

          <div key={activeScheduleIndex} className={styles['p-frame']}>
            <div className={styles['p-frame__header']}>
              <p className={styles['p-frame__header__ttl']}>
                {t('Selected Rule')}
              </p>
              <div className={styles['p-frame__header__actions']}>
                {fields.value.length > 1 && (
                  <Button
                    text={t('Remove this Rule')}
                    size="sm"
                    color="tertiarygray"
                    onClick={() => {
                      fields.remove(activeScheduleIndex);
                      setActiveScheduleIndex(0);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                    style={{ color: 'var(--error600)' }}
                  />
                )}
              </div>
            </div>
            <div className={styles['p-frame__body']}>
              <ul className={styles['p-list']}>
                <AvailabilityDatesInput
                  name={`availabilityAllotmentSchedules.${activeScheduleIndex}`}
                />
                <li className={styles['p-list__item']}>
                  {isProductEditOrProductCopy &&
                    !isPackage &&
                    !isSharedAllotment &&
                    !isRequestOnlyProduct &&
                    !isFreesaleOnlyProduct && (
                      <>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            {t('Times and Inventory')}
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name="allowRequestBookingBeyondCapacity">
                            {({ input }) => (
                              <Toggle
                                label={t(
                                  'Accept request bookings when there is not enough availability'
                                )}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                  <StartTimesInput
                    name={`availabilityAllotmentSchedules.${activeScheduleIndex}`}
                    showAllotment={!isPackage && !isSharedAllotment}
                  />
                </li>
                {(recurrence?.related_resources ?? []).length > 0 && (
                  <li className={styles['p-list__item']}>
                    <div className={styles['p-list__item__ttl']}>
                      <div className={styles['p-list__item__ttl__txt']}></div>
                    </div>
                    <div className={styles['p-list__item__body']}>
                      <div>
                        {t(
                          'Note: Product is linked with resources and availability of the resources will be the effective inventory count'
                        )}
                      </div>
                      <div style={{ marginTop: '12px' }}>
                        {t('Linked Resource')}:
                      </div>
                      <ul>
                        {recurrence?.related_resources?.map((resource, idx) => (
                          <li key={idx}>
                            {getResourceTypeText(resource.type ?? '', t)} :{' '}
                            {resource.key}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      {t('Timezone')}
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="timezone">
                      {({ input }) => (
                        <SingleDropdown
                          options={timezoneOptions.map((option) => ({
                            value: option.value,
                            text: option.text,
                          }))}
                          selectedOption={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
