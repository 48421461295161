export const getStubRedemptionButtonFontSize = (text: string) => {
  const lines = text
    .replace(/\u3000/g, ' ')
    .replace(/\s+/g, ' ')
    .split('\\n');

  if (lines.length === 1) {
    return 16;
  }

  if (lines.every((line) => countHalfAndFullWidthChars(line) <= 12)) {
    return 16;
  }

  return 10;
};

const countHalfAndFullWidthChars = (str: string) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    count += str.charCodeAt(i) > 255 ? 2 : 1;
  }
  return count;
};
