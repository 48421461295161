import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Button } from 'client/components/v3/Common/Button';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import type { ProductSummaryWithBookmark } from 'client/reducers/products';
import baseStyles from 'client/v3-base.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';

interface ProductStartTimeProps {
  productId?: string;
  startTimeLocal?: string;
}

type Props = {
  productStartTimes: ProductStartTimeProps[];
  onChange: (arg0: ProductStartTimeProps[]) => void;
  error?: string | typeof undefined;
  filter?: (arg0: ProductSummaryWithBookmark) => boolean;
};

export const ProductIdStartTimeListInput = ({
  productStartTimes,
  onChange,
  error,
  filter,
}: Props) => {
  const { t } = useTranslation();
  const productsToChoose = useSelector(summariesWithBookmarksSelector);

  const filteredProductsToChoose = productsToChoose.filter((p) => {
    if (filter) {
      return filter(p);
    }
    return true;
  });

  const getStartTimeLocals = (productId: string) => {
    const product = filteredProductsToChoose.find((p) => p.id === productId);
    if (!product) {
      return [];
    }

    const recurrence = product.recurrence;
    if (!recurrence || !recurrence.length) {
      return [];
    }

    const firstRecurrence = recurrence.find((r) => {
      if (
        moment
          .tz(r.end_date_local, product.start_timezone ?? 'UTC')
          .isAfter(moment())
      ) {
        return true;
      }
      return false;
    });

    if (!firstRecurrence) {
      return [];
    }

    return firstRecurrence.start_times.map((startTime) => ({
      value: startTime.start_time_local,
      text: startTime.start_time_local,
    }));
  };

  return (
    <div className={styles['p-manifests__productListInput']}>
      {(productStartTimes || []).map((currentValue, currentValueIdx) => (
        <div
          key={currentValueIdx}
          className={styles['p-manifestsModalCustomize__item__body__flex']}
        >
          <SingleDropdown
            searchable={true}
            options={filteredProductsToChoose
              .filter(
                // Does not allow choosing duplicate
                (p) =>
                  p.id === currentValue.productId ||
                  !productStartTimes
                    .map((productStartTime) => productStartTime.productId)
                    .includes(p.id)
              )
              .map((p) => ({
                value: p.id ?? '',
                text: getVerboseDisplayProductName(p),
              }))}
            selectedOption={currentValue.productId ?? ''}
            onChange={(newProductId) => {
              onChange(
                productStartTimes.map((productStartTime, idx) =>
                  idx === currentValueIdx
                    ? { productId: newProductId }
                    : productStartTime
                )
              );
            }}
          />

          <SingleDropdown
            options={getStartTimeLocals(currentValue.productId ?? '')}
            selectedOption={currentValue.startTimeLocal ?? ''}
            onChange={(newStartTimeLocal) => {
              const newProductStartTimes = [...productStartTimes];
              newProductStartTimes[currentValueIdx] = {
                ...newProductStartTimes[currentValueIdx],
                startTimeLocal: newStartTimeLocal,
              };
              onChange(newProductStartTimes);
            }}
          />

          <Button
            size="icon"
            color="tertiarygray"
            onClick={() => {
              const newProductStartTimes = [...productStartTimes];
              newProductStartTimes.splice(currentValueIdx, 1);
              onChange(newProductStartTimes);
            }}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </div>
      ))}

      <Button
        text={t('Add')}
        color="primary"
        onClick={() => {
          const newProductStartTimes = [...productStartTimes];
          newProductStartTimes.splice(productStartTimes.length + 1, 0, {});
          onChange(newProductStartTimes);
        }}
      />
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </div>
  );
};
