import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useContext } from 'react';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { DurationInput } from 'client/components/v3/DurationInput/DurationInput';
import { getValidators } from 'shared/libraries/validate/validator';
import { timePlusOffset } from 'client/libraries/util/util';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getStartTimesFromRecurrence } from 'client/libraries/util/getStartTimesFromRecurrence';
import { getDefaultItineraryItem } from 'client/components/NewProductEditor/DetailsStep/ItineraryEditor/FormValues';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { convertFormValuesToRecurrence } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { Recurrence } from 'shared/models/swagger';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';

type ItineraryItemInputProps = {
  name: string;
  startTimes: string[];
};

const ItineraryItemInput = ({ name, startTimes }: ItineraryItemInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);
  const { required } = getValidators(t);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Start Time')}
          </div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <Field name={`${name}.timeOffsetStart`}>
            {({ input }) => (
              <div
                className={clsx(
                  styles['p-list__item__body'],
                  styles['flex'],
                  styles['fullWidth']
                )}
              >
                <DurationInput showSignSelect {...input} />
                <p style={{ marginLeft: 0 }}>
                  {t('Displayed Time: ')}
                  {startTimes
                    .map((startTime) => {
                      if (!input.value) {
                        return '-';
                      }

                      const st = moment(startTime, 'H:mm');
                      return timePlusOffset(st, input.value).format('H:mm');
                    })
                    .join(',')}
                </p>
              </div>
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('End Time')}
          </div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <Field name={`${name}.timeOffsetEnd`}>
            {({ input }) => (
              <div
                className={clsx(
                  styles['p-list__item__body'],
                  styles['flex'],
                  styles['fullWidth']
                )}
              >
                <DurationInput showSignSelect {...input} />
                <p style={{ marginLeft: 0 }}>
                  {t('Displayed Time: ')}
                  {startTimes
                    .map((startTime) => {
                      if (!input.value) {
                        return '-';
                      }

                      const st = moment(startTime, 'H:mm');
                      return timePlusOffset(st, input.value).format('H:mm');
                    })
                    .join(',')}
                </p>
              </div>
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>{t('Title')}</div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name={`${name}.title`} validate={required}>
            {({ input, translationInput, meta: { error, touched } }) => (
              <>
                <TextField {...input} error={touched && error} />
                {translationTargetLanguage && (
                  <TextField {...translationInput} error={touched && error} />
                )}
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Description')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name={`${name}.description`}>
            {({ input, translationInput }) => (
              <>
                <TextArea
                  {...(input as any)}
                  height={80}
                  fullContainerWidth={true}
                />
                {translationTargetLanguage && (
                  <TextArea {...(translationInput as any)} height={80} />
                )}
              </>
            )}
          </TranslatedField>
        </div>
      </li>
    </ul>
  );
};

export const ItineraryEditor = () => {
  const { t } = useTranslation();
  const { reservationParamsFormValues } = useContext(ProductHelperContext);

  const formState = useFormState();
  const itinerary = formState.values.itinerary;

  // Get start times from recurrence via form values, or from context if not available
  const recurrence = convertFormValuesToRecurrence(
    formState.values.availabilityAllotmentSchedules
  );
  let startTimes = getStartTimesFromRecurrence(recurrence as Recurrence[]).map(
    (startTime) => startTime.hhMm || ''
  );
  if (startTimes.length === 0) {
    startTimes =
      reservationParamsFormValues?.startTimes.map(
        (startTime) => startTime.time || ''
      ) ?? [];
  }

  return (
    <FieldArray name="itinerary">
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((name, idx) => {
              return (
                <CollapsibleBox
                  key={name}
                  title={`#${idx + 1} ${itinerary[idx].title ?? ''}`}
                  onDelete={() => fields.remove(idx)}
                >
                  <ItineraryItemInput name={name} startTimes={startTimes} />
                </CollapsibleBox>
              );
            })}
          <div className={fields.length === 0 ? '' : baseStyles['u-mt-4']}>
            <a
              className={styles['p-box__table2__actions__add']}
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  (fields as any).insertAt(fields.length + 1, '');
                } else {
                  (fields as any).insertAt(0, getDefaultItineraryItem());
                }
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Itinerary')}
            </a>
          </div>
        </>
      )}
    </FieldArray>
  );
};
