import * as React from 'react';
import clsx from 'clsx';

import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import { Button } from 'client/components/Form';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  label: string;
  children: React.ReactNode;
};
export const ButtonMenu = ({ children, label }: Props) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const areaRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const handleOutsideClick: any = ({
      target,
    }: React.SyntheticEvent<HTMLElement>) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOption]);
  return (
    <div
      className={baseStyles['base-form-box']}
      {...getTouchEventBoundaryProps()}
    >
      <div className={baseStyles['base-form-box__body']}>
        <div className={clsx(componentStyles['c-form-single'])} ref={areaRef}>
          <Button
            onClick={() => setShowOption(!showOption)}
            style="gray"
            size="middle"
          >
            {label}
          </Button>
          {showOption && (
            <ul
              onClick={() => {
                setShowOption(false);
              }}
              className={clsx(componentStyles['option'])}
              style={{
                display: 'block',
                width: 300,
              }}
            >
              {children}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export const ButtonMenuItem = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return <li onClick={onClick}>{text}</li>;
};
