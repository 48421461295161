import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';

import type { ReduxState } from 'client/reducers';
import type { Review } from 'shared/models/swagger';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

interface Props {
  review: Review;
}
export const ProductReviewSupplierComments = ({ review }: Props) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] =
    useState<boolean>(false);
  const commentsElemRef = useRef<HTMLParagraphElement | null>(null);
  const { t } = useTranslation();
  const supplierName =
    useSelector((state: ReduxState) => state.organizations.all).find(
      (org) => org.id === review.supplier_id
    )?.name ?? '';

  useEffect(() => {
    // Set this state inside useEffect() because refs are not guaranteed to be set on first render but they
    // will be set by the time this useEffect() callback runs.
    setShouldShowSeeMoreButton(
      commentsElemRef.current != null
        ? commentsElemRef.current.scrollHeight > 70
        : false
    );
  });

  if (!review.supplier_comments) {
    return null;
  }

  return (
    <div className={styles['p-reviewCard__body__reply']}>
      <div>
        <p className={styles['p-reviewCard__body__reply__ttl']}>
          {t('Reply from {{supplierName}}', {
            supplierName,
          })}
        </p>
        <p
          className={clsx(
            styles['p-reviewCard__body__reply__desc'],
            'newline',
            !showMore && styles['is-close']
          )}
          ref={commentsElemRef}
        >
          {review.supplier_comments}
        </p>
        {shouldShowSeeMoreButton && (
          <p className={styles['p-reviewCard__body__reply__more']}>
            <a onClick={() => setShowMore(!showMore)}>
              {showMore ? t('Show Less') : t('See More')}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};
