import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from 'client/components/Form';
import { fetchGuidanceLocations } from 'client/actions/guidanceLocation';

import { GuidanceLocationMap } from './GuidanceLocationMap';
import { EditGuidanceLocationModal } from './EditGuidanceLocationModal';

export const DigitalGuidanceLocationList = () => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchGuidanceLocations());
  }, []);

  return (
    <div>
      <Button
        size="middle"
        style="green"
        onClick={() => setShowEditModal(true)}
      >
        {t('Create New Guidance Location')}
      </Button>
      {showEditModal && (
        <EditGuidanceLocationModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <GuidanceLocationMap />
    </div>
  );
};
