import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_ACCESS_REPORT_DATA_FAILURE,
  FETCH_ACCESS_REPORT_DATA_REQUEST,
  FETCH_ACCESS_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchAccessReportDataRequest = () => ({
  type: FETCH_ACCESS_REPORT_DATA_REQUEST,
});

const fetchAccessReportDataSuccess = (response: any) => ({
  type: FETCH_ACCESS_REPORT_DATA_SUCCESS,
  response,
});

const fetchAccessReportDataFailure = (error: any) => ({
  type: FETCH_ACCESS_REPORT_DATA_FAILURE,
  error,
});

let fetchAccessReportDataCancel: (() => void) | null = null;
export const fetchAccessReportData =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchAccessReportDataCancel && fetchAccessReportDataCancel();
    dispatch(fetchAccessReportDataRequest());
    axios
      .get('/api/accessreportdata', {
        params: {
          ...queryParams,
          // Limit total items to 1000 to prevent costly calls
          page_size: 1000,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchAccessReportDataCancel = c;
        }),
      })
      .then((result) => dispatch(fetchAccessReportDataSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchAccessReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchAccessReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
