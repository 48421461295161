import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { DeleteResourceModal } from 'client/pages/DispatchCustomize/ResourceList/DeleteResourceModal';
import { EditResourceModal } from 'client/pages/DispatchCustomize/ResourceList/EditResourceModal';
import { MiscResourceAvailabilityModal } from 'client/pages/DispatchCustomize/ResourceList/MiscResourceAvailabilityModal/MiscResourceAvailabilityModal';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import 'react-table/react-table.css';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import type { Column } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';
import editIcon from 'client/images/ic_edit.svg';
import type { DispatchResource } from 'shared/models/swagger';

import { MiscResourceAvailabilityDisplayBox } from './MiscResourceAvailabilityDisplayBox';
import styles from './ResourceList.module.css';

type Props = {
  isActive?: boolean;
};

export const ResourceList = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const resources = useSelector(allDispatchMiscResourcesSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showResourceAvailabilityModal, setShowResourceAvailabilityModal] =
    React.useState<boolean>(false);
  const [existingResource, setExistingResource] =
    React.useState<DispatchResource | null>(null);
  const [resourceToDelete, setResourceToDelete] =
    React.useState<DispatchResource | null>(null);

  const getColumns = (): Column[] => {
    return [
      {
        Header: '',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
            onClick={() => {
              setExistingResource(cellInfo.original);
              setShowEditModal(true);
            }}
          >
            <img src={editIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: '',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Delete')}
            onClick={() => {
              setResourceToDelete(cellInfo.original);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: t('Name'),
        accessor: 'key',
        Cell: (cellInfo) => <>{cellInfo.value}</>,
        width: 'long',
      },
      {
        Header: t('Capacity'),
        accessor: 'capacity',
        width: 'long',
      },
      {
        Header: t('Description'),
        accessor: 'description',
        width:
          hasSubscription(activeOrganization, 'feature-resource-management') ||
          hasSubscription(
            activeOrganization,
            'feature-vehicle-crew-resource-management'
          )
            ? 'long'
            : undefined,
      },
    ];
  };

  const columns = getColumns();

  if (
    hasSubscription(activeOrganization, 'feature-resource-management') ||
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      accessor: 'availability',
      Cell: (cellInfo) => (
        <div className={styles['resource_list__display_box__column']}>
          <div className={styles['resource_list__display_box__wrapper']}>
            <MiscResourceAvailabilityDisplayBox
              resourceKey={cellInfo.original.key}
            />
          </div>
          <div className={clsx(styles['resource_list__display_box__button'])}>
            <a
              className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
              data-text={t('Edit')}
              onClick={() => {
                setExistingResource(cellInfo.original);
                setShowResourceAvailabilityModal(true);
              }}
            >
              <img src={editIcon} />
            </a>
          </div>
        </div>
      ),
    });
  }

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Resources')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['flex'],
              baseStyles['green']
            )}
            onClick={() => {
              setExistingResource(null);
              setShowEditModal(true);
            }}
          >
            {t('Add New Resource')}
          </a>
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <EditResourceModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          existingResource={existingResource}
        />

        <DeleteResourceModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          resourceToDelete={resourceToDelete}
        />

        {showResourceAvailabilityModal && (
          <MiscResourceAvailabilityModal
            open={showResourceAvailabilityModal}
            onClose={() => {
              setShowResourceAvailabilityModal(false);
            }}
            resourceKey={existingResource?.key ?? ''}
          />
        )}

        <ManifestCustomizeCustomTable
          items={_.sortBy(resources, (r) => r.key)}
          columns={columns}
        />
      </div>
    </div>
  );
};
