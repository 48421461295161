import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { getCalendarWeeks } from 'client/libraries/util/getCalendarWeeks';

import styles from './CalendarDates.module.css';
import { SessionSelectionContext } from './SessionSelectionContext';
import { useSessions } from './useSessions';

const getSessionDates = (
  sessions: Swagger.AnalyticsCustomerSession[] | null
): string[] => {
  const dates = new Set<string>();
  for (const session of sessions ?? []) {
    for (const event of session.events ?? []) {
      dates.add(moment(event.event_date_time_utc).format('YYYY-MM-DD'));
    }
  }
  return Array.from(dates).sort();
};

const getConversionDates = (
  sessions: Swagger.AnalyticsCustomerSession[] | null
): string[] => {
  const dates = new Set<string>();
  for (const session of sessions ?? []) {
    for (const event of session.events ?? []) {
      if (event.event_type === 'reservation-thank-you') {
        dates.add(moment(event.event_date_time_utc).format('YYYY-MM-DD'));
      }
    }
  }
  return Array.from(dates).sort();
};

interface Props {
  month: string;
}

export const CalendarDates = ({ month }: Props) => {
  const { t } = useTranslation();
  const { setSelectedDate, setSelectedSessionTimestamp } = React.useContext(
    SessionSelectionContext
  );

  const sessions = useSessions();
  const sessionDates = sessions ? getSessionDates(sessions) : [];
  const conversionDates = sessions ? getConversionDates(sessions) : [];

  const firstDayOfMonth = moment(`${month}-01`);
  let initialSunday = moment(firstDayOfMonth);

  while (initialSunday.day() !== 0) {
    initialSunday = initialSunday.subtract(1, 'day');
  }

  let endingSaturday = moment(firstDayOfMonth).endOf('month');
  while (endingSaturday.day() !== 6) {
    endingSaturday = endingSaturday.add(1, 'day');
  }

  const weeks = getCalendarWeeks(month);

  return (
    <div className={styles['weeks']}>
      <div className={styles['week-title']}>
        <div className={styles['day-title']}>{t('Sun')}</div>
        <div className={styles['day-title']}>{t('Mon')}</div>
        <div className={styles['day-title']}>{t('Tue')}</div>
        <div className={styles['day-title']}>{t('Wed')}</div>
        <div className={styles['day-title']}>{t('Thu')}</div>
        <div className={styles['day-title']}>{t('Fri')}</div>
        <div className={styles['day-title']}>{t('Sat')}</div>
      </div>
      {weeks.map((week, idx) => (
        <div key={idx} className={styles['week']}>
          {week.map((day) => (
            <div
              key={day.date()}
              className={clsx(
                styles['day'],
                day.month() !== firstDayOfMonth.month() && styles['disabled'],
                sessionDates.includes(day.format('YYYY-MM-DD')) &&
                  styles['session-marker']
              )}
              onClick={() => {
                if (sessionDates.includes(day.format('YYYY-MM-DD'))) {
                  setSelectedDate(day.format('YYYY-MM-DD'));
                  setSelectedSessionTimestamp(
                    sessions?.find(
                      (s) =>
                        moment(s.start_date_time_utc).format('YYYY-MM-DD') ===
                        day.format('YYYY-MM-DD')
                    )?.start_date_time_utc ?? ''
                  );
                }
              }}
            >
              {conversionDates.includes(day.format('YYYY-MM-DD')) && (
                <div className={styles['conversion-marker']}></div>
              )}
              {day.date()}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
