import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import { ReservationReportBarChartPane } from 'client/pages/v3/FlexibleDashboard/Chart/ReservationReportBarChartPane';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  getDateRange,
  getReservationReportDataSum,
} from 'client/pages/v3/FlexibleDashboard/util';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { getGraphColor } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/util';
import { COMPARISON_COLOR } from 'client/pages/v3/FlexibleDashboard/Chart/util';

interface Props {
  params: ReservationReportGadgetParams;
  reportDataSets: Swagger.ReservationReportDataSet[];
}

export const ReservationReportSourcesBarChart = ({
  reportDataSets,
  params,
}: Props) => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const currencyCode = activeUserOrganization?.default_currency || null;

  const [baseRange, compareRange] = getDateRange(reportDataSets);

  const grouped = _.groupBy(reportDataSets[0].items ?? [], (item) => {
    return (
      item.booking_source?.agent_name ||
      t(item.booking_source?.source_type || 'NO BOOKING SOURCE')
    );
  });

  const bookingSourceCounts: { source: string; count: number }[] = [];
  Object.keys(grouped).forEach((key) => {
    bookingSourceCounts.push({
      source: key,
      count: getReservationReportDataSum(grouped[key], params.dataType),
    });
  });

  const sorted = _.sortBy(bookingSourceCounts, (item) => -item.count);
  const data = sorted.map((item) => ({
    [item.source]: item.count,
  }));

  // Create comparison data
  let dataComparison: {
    [x: string]: number;
  }[] = [];
  if (reportDataSets.length > 1) {
    const grouped = _.groupBy(reportDataSets[1].items ?? [], (item) => {
      return (
        item.booking_source?.agent_name ||
        t(item.booking_source?.source_type || 'NO BOOKING SOURCE')
      );
    });

    const bookingSourceCounts: { source: string; count: number }[] = [];
    Object.keys(grouped).forEach((key) => {
      bookingSourceCounts.push({
        source: key,
        count: getReservationReportDataSum(grouped[key], params.dataType),
      });
    });

    const sorted = _.sortBy(bookingSourceCounts, (item) => -item.count);
    dataComparison = sorted.map((item) => ({
      [t('Compare') + ' ' + item.source]: item.count,
    }));
  }

  const combineData = (arr1: any[], arr2: any[]) => {
    const combinedArray: any[] = [];

    for (const obj of arr1) {
      const key = Object.keys(obj)[0];
      const matchingObj = arr2.find((obj2) => `${t('Compare')} ${key}` in obj2);

      const newObj = {
        name: key,
        [baseRange]: obj[key],
      };

      if (arr2.length > 0) {
        newObj[compareRange] = matchingObj
          ? matchingObj[`${t('Compare')} ${key}`]
          : 0;
      }

      combinedArray.push(newObj);
    }

    return combinedArray;
  };

  let names = {
    [baseRange]: t('Base Range'),
  };
  let colors = [getGraphColor(params)];
  if (reportDataSets.length > 1) {
    names = {
      [baseRange]: t('Base Range'),
      [compareRange]: t('Compare Range'),
    };
    colors = [getGraphColor(params), COMPARISON_COLOR];
  }

  return (
    <div
      className={styles['p-transition__body__graph']}
      style={{ height: '400px' }}
    >
      <ReservationReportBarChartPane
        data={combineData(data, dataComparison)}
        names={names}
        currencyCode={currencyCode}
        colors={colors}
      />
    </div>
  );
};
