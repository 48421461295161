import { ReservationListTable } from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/ReservationListTable';
import { ReservationListHeader } from 'client/pages/v3/Reservation/ReservationList/ReservationListHeader/ReservationListHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ReservationList = () => {
  return (
    <V3Page>
      <ReservationListHeader />
      <ReservationListTable annualPassOnly={false} />
    </V3Page>
  );
};
