import { SystemFeeSubscriptionsHeader } from 'client/pages/v3/SystemFee/SystemFeeSubscriptions/SystemFeeSubscriptionsHeader';
import { SystemFeeSubscriptionsBody } from 'client/pages/v3/SystemFee/SystemFeeSubscriptions/SystemFeeSubscriptionsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const SystemFeeSubscriptions = () => {
  return (
    <V3Page>
      <SystemFeeSubscriptionsHeader />
      <SystemFeeSubscriptionsBody />
    </V3Page>
  );
};
