import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Input,
  TextArea,
  OptionalIntegerInput,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Editor } from 'client/components/Editor/Editor';

import styles from './GuidancePageLocationsEditor.module.css';
import { GuidancePageLocationEditor } from './GuidancePageLocationEditor';
import { CouponEditor } from './CouponEditor';
import { StampRallyEditor } from './StampRallyEditor';
import { AutoplayMediaInput } from './AutoplayMediaInput/AutoplayMediaInput';

export const DigitalGuidanceItemNormalTypeForm = () => {
  const { t } = useTranslation();
  const form = useForm();

  const [showLocation, setShowLocation] = React.useState(
    Boolean(form.getState().values?.location)
  );

  return (
    <Modal.Content>
      <Field name="title">
        {({ input }) => (
          <Input
            label={t('Title')}
            value={input.value}
            onChange={(_, { value }) => input.onChange(value)}
          />
        )}
      </Field>
      <Box mt={2}>
        <Field name="description">
          {({ input }) => (
            <TextArea
              label={t('Description')}
              tooltipText={t(
                'Enter short text to briefly describe the content of the guidance item. This will be shown on the guidance list page.'
              )}
              height={80}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </Box>
      <Box mt={2}>
        <Field name="thumbnailUrl">
          {({ input, meta: { touched, error } }) => (
            <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
              <ImageVideoAudioInput
                fileUrls={input.value ? [input.value] : []}
                onChange={(newValue) =>
                  newValue.length > 0
                    ? input.onChange(newValue[0])
                    : input.onChange('')
                }
                maxFileCount={1}
                disableYoutubeVideos
                error={touched && error}
              />
            </FieldWrapper>
          )}
        </Field>
      </Box>
      <Box mt={2}>
        <FieldWrapper
          label={t('Content')}
          tooltipText={t(
            'Compose main content of the guidance page using images, video files, or YouTube videos.'
          )}
        >
          <Field name="body">
            {({ input }) => (
              <div className={styles['body-editor']}>
                <Editor
                  style={{ width: '100%' }}
                  imageS3Prefix=""
                  data={input.value}
                  onChange={(data: any, loading) => {
                    form.change('editorJsIsLoading', loading);
                    input.onChange(data);
                  }}
                  allowVideoUploads={true}
                  allowAudioUploads={true}
                />
              </div>
            )}
          </Field>
        </FieldWrapper>
      </Box>
      <Box mt={4}>
        <ToggleButton
          label={t('Link a location with this guidance page')}
          checked={showLocation}
          onChange={() => {
            if (showLocation) {
              form.change('location', null);
              form.change('couponIds', []);
            }
            setShowLocation(!showLocation);
          }}
        />
        {showLocation && (
          <>
            <GuidancePageLocationEditor />
            <CouponEditor />
            <StampRallyEditor />
            <Box mt={2}>
              <Field name="location.activationDistance">
                {({ input }) => (
                  <OptionalIntegerInput
                    label={t('Coupon/Stamp activation distance (m)')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <div className={styles['help-text']}>
                {t(
                  'We recommend setting a longer distance (20 meters or more) since GPS locations from mobile devices are often imprecise.'
                )}
              </div>
            </Box>
          </>
        )}
      </Box>
      {showLocation && (
        <Box mt={4}>
          <Field name="shouldUseAutoplayMediaItem">
            {({ input }) => (
              <ToggleButton
                label={t('Use autoplay audio/video')}
                checked={input.value}
                onChange={() => input.onChange(!input.value)}
              />
            )}
          </Field>
          {form.getState().values?.shouldUseAutoplayMediaItem && (
            <Box mt={2}>
              <Field name="autoplayMedia">
                {({ input }) => (
                  <>
                    <FieldWrapper
                      label={t('Audio/Video file (mp3, mp4, YouTube)')}
                    />
                    <AutoplayMediaInput
                      value={input.value}
                      onChange={(newValue) => input.onChange(newValue)}
                    />
                  </>
                )}
              </Field>
              <Box mt={2}>
                <Field name="autoplayMediaItemActivationDistance">
                  {({ input }) => (
                    <OptionalIntegerInput
                      label={t('Autoplay audio/video activation distance (m)')}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <div className={styles['help-text']}>
                  {t(
                    'We recommend setting a longer distance (20 meters or more) since GPS locations from mobile devices are often imprecise.'
                  )}
                </div>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Modal.Content>
  );
};
