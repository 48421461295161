import type { Organization } from 'shared/models/swagger';

const suppliersForEnv = {
  production: [
    // 京阪ホールディングス株式会社
    'e58d7a5b-b01a-593a-858b-0649ad89012b', // 京阪電気鉄道株式会社
    'ab592eb9-32da-5ca8-871d-93e32c9f4812',
    'a56d5ad6-cb18-5cbb-b8f7-0fcd54ba15f5', // KUZUHA MALL
  ],
  staging: [
    '65c3f10b-4071-5fc2-999d-db1200d436e8', // 京阪ホールディングス株式会社
    '6290a84d-3c4d-57cd-bbdf-833f0472fac2', // 京阪電車（寝屋川車庫イベント）
    '44f08e55-c67f-5082-a586-d24115789dff', // レールフェア
    'bc8fa46f-d018-59be-874b-ed1767743270', // 京阪ミニアプリデモ
    '85328eee-5971-5e1d-b3cb-a8c6c6725d34', // 京都タワーデモ
    'ebe0e54f-17b5-55f3-a002-60d0d82a90d0', // 坂本ケーブルデモ
    'fa438293-e18e-59d8-87b8-80a2384b67ac', // くずはモールデモ
    '7eb1de12-33cc-5981-89c4-8f49a71e627a', // 大阪水上バスデモ
    'b39db66f-d41f-5b49-976c-cb11e4967034', // 琵琶湖ホテルDEMO
    '0f8807b5-8b1b-5c38-9d2f-38227763c9d4', // ひらかたパークデモ
  ],
  development: [
    'bb33ed73-5f5d-5863-9566-ece758d908a5', // DokiDoki
    'b1a67d33-39d4-517b-ae25-dc8ad0ce3c7e', // Goro Supplier
  ],
};
export const isKeihan = (organization: Organization | null) => {
  if (!organization) {
    return false;
  }

  if (/app\.ntmg\.com/.test(window.location.origin)) {
    return suppliersForEnv['production'].includes(organization?.id);
  }

  if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return suppliersForEnv['staging'].includes(organization?.id);
  }

  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost/.test(window.location.origin)
  ) {
    return suppliersForEnv['development'].includes(organization?.id);
  }

  return false;
};

export const hideEmbedCalendar = (organization: Organization | null) => {
  if (!organization) {
    return false;
  }

  if (/app\.ntmg\.com/.test(window.location.origin)) {
    return suppliersForEnv['production'].includes(organization?.id);
  }

  if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return suppliersForEnv['staging'].includes(organization?.id);
  }

  return false;
};
