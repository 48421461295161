import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';

import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper } from 'client/components/Form';
import { activeUserSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { matchesFormat } from 'shared/libraries/validate/validator';

import 'react-phone-input-2/lib/style.css';

interface Props {
  onClose: () => void;
  onChange: (newPhone: string) => void;
  initialPhone: string;
}

export const ReservationPhoneEditModal = ({
  onClose,
  onChange,
  initialPhone,
}: Props) => {
  const { t } = useTranslation();
  const [phone, setPhone] = React.useState(
    initialPhone && !initialPhone.startsWith('+')
      ? `+${initialPhone}`
      : initialPhone
  );
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const countryCodeOptions =
    activeUser?.locale === 'ja' ? ['jp', 'us', 'au'] : ['us', 'jp', 'au'];

  const validatePhone = (phone?: any) => {
    if (!phone) return t('Required');

    if (!matchesFormat(phone, 'friendly_phone')) return t('Invalid Phone');

    if (
      ['+81', '+1', '+61'].every(
        (countryCode) => !phone.startsWith(countryCode)
      )
    ) {
      return t('Invalid Phone');
    }

    return undefined;
  };

  const error = validatePhone(phone);

  return (
    <Modal
      title={t('Edit Phone Number for SMS')}
      open={true}
      onClose={onClose}
      insertRoot
    >
      <Modal.Content>
        <Box display="flex" alignItems="flex-end">
          <FieldWrapper label={t('Phone')} required={true} error={error}>
            <PhoneInput
              placeholder={locale === 'ja' ? '81 9012345678' : '1 800 123 4567'}
              masks={{ jp: '...........' }}
              onlyCountries={countryCodeOptions}
              localization={locale === 'ja' ? jpPhoneLocalization : undefined}
              value={phone?.replace('+', '')}
              onChange={(value: string, data: { dialCode: string }) => {
                if (data && !value?.startsWith(data.dialCode)) {
                  setPhone(`+${data.dialCode}${value}`);
                } else if (value && !value?.startsWith('+')) {
                  setPhone(`+${value}`);
                } else {
                  setPhone(value);
                }
              }}
              searchPlaceholder={t('Search')}
              searchNotFound={t('No Results')}
              isValid={() => {
                return !error;
              }}
              enableSearch
            />
          </FieldWrapper>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            onChange(phone);
            onClose();
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
