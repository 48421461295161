import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { config } from 'client/config';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { createPromotion, updatePromotion } from 'client/actions/promotions';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  getPromotionTypeText,
  getPromotionStatusText,
} from 'client/libraries/util/promotionTextHelpers';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { getProductCurrencyCode } from 'client/libraries/util/getProductCurrencyCode';
import { UnitPriceDeltaListInput } from 'client/pages/PromotionList/UnitPriceDeltaListInput';
import { FamilyDiscountInput } from 'client/pages/PromotionList/FamilyDiscountInput';
import { GroupDiscountInput } from 'client/pages/PromotionList/GroupDiscountInput';
import { ChannelListInput } from 'client/pages/PromotionList/ChannelListInput';
import {
  applyPriceDeltaToGross,
  applyPriceDeltaToNet,
} from 'client/libraries/util/applyPriceDelta';
import {
  PromotionShape,
  DateRange,
  getPriceSchedulesActiveInDateRanges,
  dateRangesOverlap,
} from 'client/libraries/util/promotionShape';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';
import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Radio,
  Select,
  Input,
  DateRangeInput,
  ToggleButton,
  Button,
} from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { PromotionDetails } from 'client/pages/ProductDetails/ProductContentsPane/PromotionDetails/PromotionDetails';
import { toProductShape } from 'client/libraries/util/productShape';
import type {
  Channel,
  DayOfWeek,
  FamilyDiscount,
  GroupDiscount,
  NewPromotion,
  Promotion,
  PromotionPatch,
  PromotionType,
  PromotionStatus,
  RelativeDateTime,
  UnitPriceDelta,
  Pricing,
} from 'shared/models/swagger';
import { Add } from 'client/components/Icons/Add';
import { Box } from 'client/components/Box/Box';
import { Delete } from 'client/components/Icons/Delete';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';

const promotionActiveForPriceSchedule = (
  promotion: Promotion,
  priceSchedule: Pricing
): boolean => {
  if (
    !promotion.participation_days_of_week?.some((day) =>
      priceSchedule.days_of_week?.includes(day)
    )
  ) {
    return false;
  }

  if (!promotion.participation_date_ranges?.length) {
    return true;
  }

  const priceDateRange = {
    startDate: priceSchedule.start_date_local ?? '',
    endDate: priceSchedule.end_date_local ?? '',
  };

  return promotion.participation_date_ranges.some((dateRange) =>
    dateRangesOverlap(
      {
        startDate: dateRange.start_date_local || '',
        endDate: dateRange.end_date_local || '',
      },
      priceDateRange
    )
  );
};

const allDaysOfWeek = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
] as DayOfWeek[];

type Props = {
  open: boolean;
  onClose: () => void;
  // Two uses for inputPromotion:
  // 1) For editing an existing promotion. inputPromotion.id will not be nil.
  // 2) For cloning and creating a promotion based on another promotion. inputPromotion.id will be nil.
  inputPromotion?: PromotionShape | null;
};
export const EditPromotionModal = ({
  inputPromotion,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  // State
  const [promotionAppliesToAllProducts, setPromotionAppliesToAllProducts] =
    React.useState<boolean>(false);
  const [productId, setProductId] = React.useState<string>('');
  const [promotionType, setPromotionType] =
    React.useState<PromotionType>('SIMPLE_DISCOUNT');
  const [promotionStatus, setPromotionStatus] =
    React.useState<PromotionStatus>('ON');
  const [participationDateRanges, setParticipationDateRanges] = React.useState<
    DateRange[]
  >([]);
  const [participationDaysOfWeek, setParticipationDaysOfWeek] = React.useState<
    DayOfWeek[]
  >([]);
  const [bookedDateLocalFrom, setBookedDateLocalFrom] =
    React.useState<string>('');
  const [bookedDateLocalTo, setBookedDateLocalTo] = React.useState<string>('');
  const [unitPriceDeltas, setUnitPriceDeltas] = React.useState<
    UnitPriceDelta[]
  >([]);
  const [earlyBirdDeadline, setEarlyBirdDeadline] =
    React.useState<RelativeDateTime>({
      type: 'DAY',
      count: 30,
      time_local: '12:00',
    });
  const [lastMinuteStart, setLastMinuteStart] =
    React.useState<RelativeDateTime>({
      type: 'HOUR',
      count: 24,
    });
  const [familyDiscount, setFamilyDiscount] = React.useState<FamilyDiscount>({
    qualifying_base_guest_count: 1,
  });
  const [groupDiscount, setGroupDiscount] = React.useState<GroupDiscount>({
    min_guest_count: 1,
  });
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [appliesToSpecificChannels, setAppliesToSpecificChannels] =
    React.useState<boolean>(false);

  // |                     | N/A  | promoCode | oneTime | auto  |
  // |---------------------|------|-----------|---------|-------|
  // | usePromoCode        | true | true      | false   | false |
  // | useOneTimePromoCode | true | false     | true    | false |

  const [usePromoCode, setUsePromoCode] = React.useState<boolean>(false);
  const [useOneTimePromoCode, setUseOneTimePromoCode] =
    React.useState<boolean>(false);

  const [referenceLabel, setReferenceLabel] = React.useState<string>('');
  const [promoCode, setPromoCode] = React.useState<string>('');
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  React.useEffect(() => {
    setPromotionAppliesToAllProducts(
      (inputPromotion && inputPromotion.appliesToAllProducts) || false
    );
    setProductId((inputPromotion && inputPromotion.productId) || '');
    setPromotionType(
      (inputPromotion && inputPromotion.type) || 'SIMPLE_DISCOUNT'
    );
    setPromotionStatus((inputPromotion && inputPromotion.status) || 'ON');
    setParticipationDateRanges(
      (inputPromotion && inputPromotion.participationDateRanges) || [
        { startDate: '', endDate: '' },
      ]
    );
    setParticipationDaysOfWeek(
      (inputPromotion && inputPromotion.participationDaysOfWeek) || [
        ...allDaysOfWeek,
      ]
    );
    setBookedDateLocalFrom(
      (inputPromotion && inputPromotion.bookedDateLocalFrom) || ''
    );
    setBookedDateLocalTo(
      (inputPromotion && inputPromotion.bookedDateLocalTo) || ''
    );
    setUnitPriceDeltas(
      (inputPromotion && inputPromotion.unitPriceDeltas) || []
    );
    setEarlyBirdDeadline(
      (inputPromotion && inputPromotion.earlyBirdDeadline) || {
        type: 'DAY',
        count: 30,
        time_local: '12:00',
      }
    );
    setLastMinuteStart(
      (inputPromotion && inputPromotion.lastMinuteStart) || {
        type: 'HOUR',
        count: 24,
      }
    );
    setFamilyDiscount(
      (inputPromotion && inputPromotion.familyDiscount) || {
        qualifying_base_guest_count: 1,
      }
    );
    setGroupDiscount(
      (inputPromotion && inputPromotion.groupDiscount) || {
        min_guest_count: 1,
      }
    );
    setChannels((inputPromotion && inputPromotion.channels) || []);
    setAppliesToSpecificChannels(
      (inputPromotion &&
        inputPromotion.channels &&
        inputPromotion.channels.length > 0) ||
        false
    );
    setUsePromoCode(
      Boolean(inputPromotion && inputPromotion.promoCode) || false
    );
    setPromoCode((inputPromotion && inputPromotion.promoCode) || '');

    setUseOneTimePromoCode(
      Boolean(inputPromotion && inputPromotion.useOneTimePromoCode) || false
    );
    setReferenceLabel((inputPromotion && inputPromotion.referenceLabel) || '');

    setShowErrors(false);
  }, [inputPromotion]);
  // Redux
  const dispatch = useDispatch();

  const selectedProduct = useSelector(
    (state: ReduxState) => (productId && state.products.byID[productId]) || null
  );
  const contractedAgents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );
  const inputPromotionId = (inputPromotion && inputPromotion.id) || '';
  const mode: 'UPDATE' | 'CREATE' = inputPromotionId ? 'UPDATE' : 'CREATE';
  // Effects
  React.useEffect(() => {
    if (open) {
      dispatch(fetchContractedOrganizations());
    }
  }, [dispatch, open]);
  React.useEffect(() => {
    if (open && !inputPromotionId) {
      dispatch(fetchProducts());
    }
  }, [dispatch, inputPromotionId, open]);
  React.useEffect(() => {
    if (productId && open) {
      dispatch(fetchProductByID(productId));
    }
  }, [dispatch, productId, open]);
  const productOptions = useSelector(productOptionsSelector);
  const promotionStatusOptions = ['ON', 'OFF', 'DISCONTINUED'].map(
    (status) => ({
      text: getPromotionStatusText(status as PromotionStatus, t),
      value: status,
      key: status,
    })
  );
  const promotionTypeOptions = [
    'SIMPLE_DISCOUNT',
    'EARLY_BIRD_DISCOUNT',
    'LAST_MINUTE_DISCOUNT',
    'ADDITIONAL_CHARGE',
    'GROUP_DISCOUNT',
    'FAMILY_DISCOUNT',
  ].map((type) => ({
    text: getPromotionTypeText(type as PromotionType, t),
    value: type,
    key: type,
  }));
  const headerText =
    mode === 'UPDATE' ? t('Edit Promotion') : t('Create New Promotion');
  let unitPriceMethod: 'PER_PARTICIPANT' | 'PER_BOOKING' | 'ANY' =
    'PER_PARTICIPANT';
  let allGuestTypeKeys: string[] = [];

  const nonEmptyParticipationDateRanges = participationDateRanges.filter(
    (dateRange) => dateRange.startDate || dateRange.endDate
  );

  const startDateLocalFrom = nonEmptyParticipationDateRanges?.length
    ? nonEmptyParticipationDateRanges[0].startDate
    : '';
  const startDateLocalTo = nonEmptyParticipationDateRanges?.length
    ? nonEmptyParticipationDateRanges[0].endDate
    : '';

  if (promotionAppliesToAllProducts) {
    unitPriceMethod = 'ANY';
  } else if (selectedProduct) {
    const priceSchedules = getPriceSchedulesActiveInDateRanges(
      selectedProduct.pricing || [],
      nonEmptyParticipationDateRanges
    );

    if (priceSchedules.length > 0) {
      if (
        priceSchedules[0].units.length > 0 &&
        priceSchedules[0].units[0].method === 'PER_BOOKING'
      ) {
        unitPriceMethod = 'PER_BOOKING';
      }

      if (unitPriceMethod === 'PER_PARTICIPANT') {
        allGuestTypeKeys = [
          ...new Set(
            _.flatten(
              priceSchedules.map((priceSched) =>
                priceSched.units
                  .map((unit) => (unit.guest_type ? unit.guest_type.key : ''))
                  .filter((key) => !!key)
              )
            )
          ),
        ];
      }
    }
  }

  let unitPriceDeltaGuestTypeKeys = allGuestTypeKeys;

  if (promotionType === 'FAMILY_DISCOUNT') {
    unitPriceDeltaGuestTypeKeys =
      familyDiscount && familyDiscount.discount_guest_type_key
        ? [familyDiscount.discount_guest_type_key]
        : [];
  }

  if (promotionType === 'GROUP_DISCOUNT') {
    unitPriceDeltaGuestTypeKeys = (
      groupDiscount?.eligible_guest_type_keys || []
    ).filter((key) => allGuestTypeKeys.includes(key));
  }

  const nonZeroDeltas: UnitPriceDelta[] = unitPriceDeltas
    .filter(
      (delta: UnitPriceDelta) =>
        (unitPriceMethod !== 'PER_PARTICIPANT' ||
          (unitPriceDeltaGuestTypeKeys.includes(
            delta.guest_type_key as string
          ) &&
            allGuestTypeKeys.includes(delta.guest_type_key as string))) &&
        (delta.delta_fixed_gross ||
          delta.delta_fixed_net ||
          delta.delta_percent)
    )
    .map((delta: UnitPriceDelta) => {
      // Ensure 'DISCOUNT'/'CHARGE' corresponds with the promotion type. Cloning a simple discount from
      // an additional charge (or vice versa) without changing amounts would not change delta type so
      // we need to do it here.
      return {
        ...delta,
        delta_type:
          promotionType === 'ADDITIONAL_CHARGE' ? 'CHARGE' : 'DISCOUNT',
      };
    });

  const buildPromotionObject = (): Promotion => {
    return {
      id: inputPromotionId || 'dummyId',
      product_id: productId,
      type: promotionType,
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const buildNewPromotionObject = (): NewPromotion => {
    return {
      product_id: productId,
      type: promotionType,
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const buildPromotionPatch = (): PromotionPatch => {
    return {
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const previewPromotion: Promotion = buildPromotionObject();
  const previewPricing = (
    (selectedProduct && selectedProduct.pricing) ||
    []
  ).map((priceSchedule) => {
    const previewPromotionIsActive =
      previewPromotion.status === 'ON' &&
      promotionActiveForPriceSchedule(previewPromotion, priceSchedule);

    return {
      ...priceSchedule,
      promotions: !previewPromotionIsActive
        ? priceSchedule.promotions
        : [
            ...((priceSchedule.promotions || [])?.filter(
              (promo) =>
                !previewPromotion.id || promo.id !== previewPromotion.id
            ) ?? []),
            {
              type: promotionType,
              participation_date_ranges: nonEmptyParticipationDateRanges.map(
                (dateRange) => ({
                  start_date_local: dateRange.startDate,
                  end_date_local: dateRange.endDate,
                })
              ),
              participation_days_of_week: participationDaysOfWeek,
              booked_date_local_from: bookedDateLocalFrom,
              booked_date_local_to: bookedDateLocalTo,
              early_bird_deadline:
                promotionType === 'EARLY_BIRD_DISCOUNT'
                  ? earlyBirdDeadline
                  : undefined,
              last_minute_start:
                promotionType === 'LAST_MINUTE_DISCOUNT'
                  ? lastMinuteStart
                  : undefined,
              family_discount:
                promotionType === 'FAMILY_DISCOUNT'
                  ? familyDiscount
                  : undefined,
              group_discount:
                promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
              channels,
              promo_code: promoCode,
              adjusted_unit_prices: (
                previewPromotion.unit_price_deltas || []
              ).map((unitPriceDelta) => {
                const unit = priceSchedule.units.find((unit) => {
                  if (unitPriceDelta.unit_price_method === 'PER_PARTICIPANT') {
                    return (
                      unitPriceDelta.guest_type_key ===
                      (unit.guest_type && unit.guest_type.key)
                    );
                  }

                  return unitPriceDelta.unit_price_method === unit.method;
                });
                let adjustedGross = '';
                let adjustedNet = '';

                if (unit) {
                  adjustedGross = applyPriceDeltaToGross(
                    unit.gross,
                    unitPriceDelta
                  );
                  adjustedNet = applyPriceDeltaToNet(unit.net, unitPriceDelta);
                }

                return {
                  ...unitPriceDelta,
                  unit_price_method: unit?.method,
                  adjusted_gross: adjustedGross,
                  adjusted_net: adjustedNet,
                };
              }),
            },
          ],
    };
  });

  const showUnitPriceDeltaListInput =
    promotionType !== 'FAMILY_DISCOUNT' ||
    (promotionType === 'FAMILY_DISCOUNT' &&
      familyDiscount &&
      familyDiscount.discount_guest_type_key);
  const currencyCode = selectedProduct
    ? getProductCurrencyCode(selectedProduct)
    : '';

  const validate = (): string[] => {
    const validationErrors: string[] = [];

    if (usePromoCode && !promoCode) {
      validationErrors.push(t('Promo code required'));
    }

    if (useOneTimePromoCode && !referenceLabel) {
      validationErrors.push(t('Reference label required'));
    }

    if (promotionAppliesToAllProducts || productId) {
      if (!startDateLocalFrom && startDateLocalTo) {
        validationErrors.push(t('Eligible participation start date required'));
      }

      if (startDateLocalFrom && !startDateLocalTo) {
        validationErrors.push(t('Eligible participation end date required'));
      }

      if (!bookedDateLocalFrom && bookedDateLocalTo) {
        validationErrors.push(t('Eligible booking start date required'));
      }

      if (bookedDateLocalFrom && !bookedDateLocalTo) {
        validationErrors.push(t('Eligible booking end date required'));
      }

      if (
        !startDateLocalFrom &&
        !startDateLocalTo &&
        !bookedDateLocalFrom &&
        !bookedDateLocalTo
      ) {
        validationErrors.push(
          t('Eligible participation or booking  date required')
        );
      }
    }

    switch (promotionType) {
      case 'FAMILY_DISCOUNT':
        if (!familyDiscount.base_guest_type_key) {
          validationErrors.push(t('Family discount: base unit required'));
        }

        if (!familyDiscount.discount_guest_type_key) {
          validationErrors.push(t('Family discount: discount unit required'));
        }

        break;

      case 'EARLY_BIRD_DISCOUNT':
        if (
          earlyBirdDeadline.type === 'DAY' &&
          !matchesFormat(earlyBirdDeadline.time_local || '', '24-hour-time')
        ) {
          validationErrors.push(
            t("Early bird discount: 'hh:mm' required for promotion deadline")
          );
        }

        break;

      case 'LAST_MINUTE_DISCOUNT':
        if (
          lastMinuteStart.type === 'DAY' &&
          !matchesFormat(lastMinuteStart.time_local || '', '24-hour-time')
        ) {
          validationErrors.push(
            t("Last minute discount: 'hh:mm' required for promotion start")
          );
        }

        break;

      case 'GROUP_DISCOUNT':
        if (
          !groupDiscount?.eligible_guest_type_keys?.some((key) =>
            allGuestTypeKeys.includes(key)
          )
        ) {
          validationErrors.push(
            t('Group discount: At least one unit discount setting is required')
          );
        }

        break;

      default:
    }

    return validationErrors;
  };

  const validationErrors = validate();
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal title={headerText} open={open} onClose={onClose} onOpen={() => {}}>
      <Modal.Content>
        <Modal.Description>
          <div>
            <Modal.Box>
              {mode === 'UPDATE' ? (
                promotionAppliesToAllProducts && (
                  <FieldWrapper label={t('Product')}>
                    {t('All Products')}
                  </FieldWrapper>
                )
              ) : (
                <FieldWrapper label="">
                  <Radio
                    checked={!promotionAppliesToAllProducts}
                    label={t('Promotion applies to a single product')}
                    onChange={() => {
                      setPromotionAppliesToAllProducts(false);
                    }}
                  />
                  <Radio
                    checked={promotionAppliesToAllProducts}
                    label={t('Promotion applies to all products')}
                    onChange={() => {
                      setProductId('');
                      setPromotionType('SIMPLE_DISCOUNT');
                      setUnitPriceDeltas([
                        {
                          delta_type: 'DISCOUNT',
                          unit_price_method: 'ANY',
                          delta_percent: 0,
                        },
                      ]);
                      setPromotionAppliesToAllProducts(true);
                    }}
                  />
                </FieldWrapper>
              )}
            </Modal.Box>

            <Modal.Box>
              {!promotionAppliesToAllProducts &&
                (mode === 'UPDATE' ? (
                  <FieldWrapper label={t('Product')}>
                    {selectedProduct &&
                      (selectedProduct.internal_product_name ??
                        selectedProduct.product_name)}
                  </FieldWrapper>
                ) : (
                  <Select
                    required
                    label={t('Product')}
                    search
                    value={productId}
                    options={productOptions}
                    onChange={(e, { value }) => {
                      setProductId(value);
                    }}
                  />
                ))}
            </Modal.Box>

            {(productId || promotionAppliesToAllProducts) && (
              <>
                {mode === 'UPDATE' || promotionAppliesToAllProducts ? (
                  <Modal.Box>
                    <FieldWrapper label={t('Promotion Type')}>
                      {getPromotionTypeText(promotionType, t)}
                    </FieldWrapper>
                  </Modal.Box>
                ) : (
                  <Modal.Box>
                    <Select
                      required
                      label={t('Promotion Type')}
                      value={promotionType}
                      options={promotionTypeOptions}
                      onChange={(e, { value }) => {
                        setPromotionType(value as PromotionType);
                      }}
                    />
                  </Modal.Box>
                )}
                <Modal.Box>
                  <FieldWrapper label="">
                    <Radio
                      checked={!usePromoCode && !useOneTimePromoCode}
                      label={t('Apply this promotion automatically')}
                      onChange={() => {
                        setPromoCode('');
                        setReferenceLabel('');
                        setUsePromoCode(false);
                        setUseOneTimePromoCode(false);
                      }}
                    />
                    <Radio
                      checked={usePromoCode && !useOneTimePromoCode}
                      label={t('Require promo code')}
                      onChange={() => {
                        setReferenceLabel('');
                        setUsePromoCode(true);
                        setUseOneTimePromoCode(false);
                      }}
                    />
                    {config.enableInstantWin && (
                      <Radio
                        checked={!usePromoCode && useOneTimePromoCode}
                        label={t('Require one time promo code')}
                        onChange={() => {
                          setPromoCode('');
                          setUsePromoCode(false);
                          setUseOneTimePromoCode(true);
                        }}
                      />
                    )}
                  </FieldWrapper>
                </Modal.Box>
                {usePromoCode && (
                  <Modal.Box>
                    <Input
                      label={t('Promo Code')}
                      error={(showErrors && !promoCode) as any}
                      required
                      value={promoCode}
                      onChange={(_, { value }) => {
                        setPromoCode(value);
                      }}
                    />
                  </Modal.Box>
                )}
                {useOneTimePromoCode && (
                  <Modal.Box>
                    <Input
                      label={t('Reference Label')}
                      error={(showErrors && !referenceLabel) as any}
                      required
                      value={referenceLabel}
                      onChange={(_, { value }) => {
                        setReferenceLabel(value);
                      }}
                    />
                  </Modal.Box>
                )}

                <Modal.Box>
                  <FieldWrapper
                    label={t('Eligible Participation Date Ranges')}
                  />
                  <Box mb={2}>
                    <Add
                      onClick={() => {
                        setParticipationDateRanges([
                          {
                            startDate: '',
                            endDate: '',
                          },
                          ...participationDateRanges,
                        ]);
                      }}
                    />
                    {participationDateRanges?.map((dateRange, index) => (
                      <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                        key={index}
                      >
                        <DateRangeInput
                          fromDate={dateRange.startDate}
                          toDate={dateRange.endDate}
                          onChangeFromDate={(startDate: string) => {
                            const newParticipationDateRanges = [
                              ...participationDateRanges,
                            ];
                            newParticipationDateRanges[index] = {
                              startDate,
                              endDate: dateRange.endDate,
                            };
                            setParticipationDateRanges(
                              newParticipationDateRanges
                            );
                          }}
                          onChangeToDate={(endDate) => {
                            const newParticipationDateRanges = [
                              ...participationDateRanges,
                            ];
                            newParticipationDateRanges[index] = {
                              startDate: dateRange.startDate,
                              endDate,
                            };
                            setParticipationDateRanges(
                              newParticipationDateRanges
                            );
                          }}
                        />
                        <Box ml={1}>
                          <Add
                            onClick={() => {
                              const newParticipationDateRanges = [
                                ...participationDateRanges,
                              ];
                              newParticipationDateRanges.splice(index + 1, 0, {
                                startDate: '',
                                endDate: '',
                              });
                              setParticipationDateRanges(
                                newParticipationDateRanges
                              );
                            }}
                          />
                        </Box>
                        <Box ml={1}>
                          <Delete
                            onClick={() => {
                              const newParticipationDateRanges = [
                                ...participationDateRanges,
                              ];
                              newParticipationDateRanges.splice(index, 1);
                              setParticipationDateRanges(
                                newParticipationDateRanges
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Modal.Box>
                <FieldWrapper
                  label={t('Eligible Participation Days of Week')}
                />
                <WeekdaysInput
                  value={participationDaysOfWeek}
                  onChange={setParticipationDaysOfWeek}
                />

                <Modal.Box>
                  <DateRangeInput
                    label={t('Eligible Booking Date Range')}
                    fromDate={bookedDateLocalFrom}
                    toDate={bookedDateLocalTo}
                    onChangeFromDate={(startDate) => {
                      setBookedDateLocalFrom(startDate);
                    }}
                    onChangeToDate={(endDate) => {
                      setBookedDateLocalTo(endDate);
                    }}
                  />
                </Modal.Box>

                <Modal.Box>
                  <Select
                    required
                    label={t('Status')}
                    value={promotionStatus}
                    options={promotionStatusOptions}
                    onChange={(e, { value }) => {
                      setPromotionStatus(value as PromotionStatus);
                    }}
                  />
                </Modal.Box>

                {promotionType === 'EARLY_BIRD_DISCOUNT' && (
                  <Modal.Box column="three">
                    <Input
                      label={t('Discount applies until ')}
                      value={earlyBirdDeadline.count || (0 as any)}
                      onChange={(e, { value }) => {
                        let count = parseInt(value, 10);

                        if (isNaN(count)) {
                          count = 0;
                        }

                        setEarlyBirdDeadline({ ...earlyBirdDeadline, count });
                      }}
                    />

                    <Select
                      label={t('Days/Hours')}
                      value={earlyBirdDeadline && earlyBirdDeadline.type}
                      options={[
                        {
                          text: t('days before participation'),
                          value: 'DAY',
                        },
                        {
                          text: t('hours before participation'),
                          value: 'HOUR',
                        },
                      ]}
                      onChange={(e, { value }: any) =>
                        setEarlyBirdDeadline({
                          ...earlyBirdDeadline,
                          type: value,
                        })
                      }
                    />

                    {earlyBirdDeadline.type === 'DAY' && (
                      <Input
                        label={t('at')}
                        required
                        error={
                          showErrors &&
                          (!matchesFormat(
                            earlyBirdDeadline.time_local || '',
                            '24-hour-time'
                          ) as any)
                        }
                        value={earlyBirdDeadline.time_local || ''}
                        placeholder={'hh:mm (24-hour time)'}
                        onChange={(e, { value }) =>
                          setEarlyBirdDeadline({
                            ...earlyBirdDeadline,
                            time_local: value,
                          })
                        }
                      />
                    )}
                  </Modal.Box>
                )}
                {promotionType === 'LAST_MINUTE_DISCOUNT' && (
                  <Modal.Box column="three">
                    <Input
                      label={t('Discount starts ')}
                      value={lastMinuteStart.count || (0 as any)}
                      onChange={(e, { value }) => {
                        let count = parseInt(value, 10);

                        if (isNaN(count)) {
                          count = 0;
                        }

                        setLastMinuteStart({ ...lastMinuteStart, count });
                      }}
                    />

                    <Select
                      label={t('Days/Hours')}
                      value={lastMinuteStart && lastMinuteStart.type}
                      options={[
                        {
                          text: t('days before participation'),
                          value: 'DAY',
                        },
                        {
                          text: t('hours before participation'),
                          value: 'HOUR',
                        },
                      ]}
                      onChange={(e, { value }: any) =>
                        setLastMinuteStart({ ...lastMinuteStart, type: value })
                      }
                    />

                    {lastMinuteStart.type === 'DAY' && (
                      <Input
                        label={t('at')}
                        required
                        error={
                          showErrors &&
                          (!matchesFormat(
                            lastMinuteStart.time_local || '',
                            '24-hour-time'
                          ) as any)
                        }
                        value={lastMinuteStart.time_local || ''}
                        placeholder={'hh:mm (24-hour time)'}
                        onChange={(e, { value }) =>
                          setLastMinuteStart({
                            ...lastMinuteStart,
                            time_local: value,
                          })
                        }
                      />
                    )}
                  </Modal.Box>
                )}
                {promotionType === 'FAMILY_DISCOUNT' && (
                  <FamilyDiscountInput
                    showErrors={showErrors}
                    familyDiscount={familyDiscount}
                    onFamilyDiscountChange={setFamilyDiscount}
                    guestTypeKeys={allGuestTypeKeys}
                  />
                )}
                {promotionType === 'GROUP_DISCOUNT' && (
                  <GroupDiscountInput
                    groupDiscount={groupDiscount}
                    onGroupDiscountChange={setGroupDiscount}
                    guestTypeKeys={allGuestTypeKeys}
                  />
                )}
                {showUnitPriceDeltaListInput && (
                  <UnitPriceDeltaListInput
                    type={
                      promotionType === 'ADDITIONAL_CHARGE'
                        ? 'CHARGE'
                        : 'DISCOUNT'
                    }
                    label={
                      promotionType === 'ADDITIONAL_CHARGE'
                        ? t('Charge Amounts')
                        : t('Discount Amounts')
                    }
                    currencyCode={currencyCode}
                    unitPriceMethod={unitPriceMethod}
                    guestTypeKeyCandidates={unitPriceDeltaGuestTypeKeys}
                    value={unitPriceDeltas}
                    onChange={(newUnitPriceDeltas) => {
                      setUnitPriceDeltas(newUnitPriceDeltas);
                    }}
                  />
                )}
                {!promotionAppliesToAllProducts && selectedProduct && (
                  <PromotionDetails
                    product={toProductShape({
                      ...selectedProduct,
                      pricing: previewPricing,
                    })}
                  />
                )}
                <FieldWrapper
                  label={t('Promotion applies to specific channels')}
                >
                  <ToggleButton
                    label=""
                    checked={appliesToSpecificChannels}
                    onChange={() => {
                      if (appliesToSpecificChannels) {
                        setChannels([]);
                      }

                      setAppliesToSpecificChannels(!appliesToSpecificChannels);
                    }}
                  />
                </FieldWrapper>
                {appliesToSpecificChannels && (
                  <ChannelListInput
                    channels={channels}
                    onChange={setChannels}
                    allAgents={
                      (selectedProduct && selectedProduct.agents) ||
                      contractedAgents
                    }
                  />
                )}
              </>
            )}
            {validationErrors.length > 0 && (
              <Message error>
                <ol>
                  {validationErrors.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                </ol>
              </Message>
            )}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          disabled={
            (!productId && !promotionAppliesToAllProducts) ||
            !promotionStatus ||
            !promotionType
          }
          onClick={() => {
            if (validationErrors.length > 0) {
              setShowErrors(true);
              return;
            }

            if (inputPromotionId) {
              dispatch(
                updatePromotion(inputPromotionId, buildPromotionPatch())
              );
            } else {
              dispatch(createPromotion(buildNewPromotionObject()));
            }

            onClose();
            setShowErrors(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
