import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ProductContents } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductContents';
import { activeUserSelector } from 'client/reducers/user';
import { fetchProductByID } from 'client/actions/products';
import { ProductReviewsPane } from 'client/pages/ProductDetails/ProductReviewsPane/ProductReviewsPane';
import type { ReduxState } from 'client/reducers';
import { ProductDetailsHeader } from 'client/pages/v3/Product/ProductDetails/ProductDetailsHeader/ProductDetailsHeader';
import { BookingWidgetPreview } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/BookingWidgetPreview';
import baseStyles from 'client/v3-base.module.css';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ProductDetails = () => {
  const { t, i18n } = useTranslation();
  const [contentLanguage, setContentLanguage] = useState<string>(i18n.language);

  const activeUser = useSelector(activeUserSelector);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(fetchProductByID(id ?? '', contentLanguage));
  }, [id, contentLanguage, activeUser]);
  const product = useSelector(
    (state: ReduxState) => state.products.byID[id ?? '']
  );

  if (!product) {
    return <div>{t('Product not found')}</div>;
  }

  return (
    <V3Page>
      <ProductDetailsHeader
        product={product}
        contentLanguage={contentLanguage}
        onContentLanguageChange={setContentLanguage}
      />
      <div className={baseStyles['l-main__body']}>
        <ProductContents product={product} contentLanguage={contentLanguage} />

        <BookingWidgetPreview
          product={product}
          contentLanguage={contentLanguage}
        />

        <section className={clsx('g-section', baseStyles['u-mt-6'])}>
          <ProductReviewsPane
            product={product}
            contentLanguage={contentLanguage}
          />
        </section>
      </div>
    </V3Page>
  );
};
