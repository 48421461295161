import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';

export const ProductCreateHeader = () => {
  const { t } = useTranslation();

  const paths = [
    { text: t('Product List'), url: '/products-v3' },
    { text: t('New Product'), url: '' },
  ];

  return (
    <PageHeader title={t('New Product')} breadcrumbPaths={paths}></PageHeader>
  );
};
