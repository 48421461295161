import { combineReducers } from 'redux';

import {
  FETCH_E_TICKET_FOOTPRINTS_REQUEST,
  FETCH_E_TICKET_FOOTPRINTS_SUCCESS,
  FETCH_E_TICKET_FOOTPRINTS_FAILURE,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ETicketFootprint } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_E_TICKET_FOOTPRINTS_REQUEST:
      return true;

    case FETCH_E_TICKET_FOOTPRINTS_SUCCESS:
    case FETCH_E_TICKET_FOOTPRINTS_FAILURE:
      return false;

    default:
      return state;
  }
};

const downloading = (state = false, action: any): boolean => {
  switch (action.type) {
    case DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST:
      return true;

    case DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS:
    case DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_E_TICKET_FOOTPRINTS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (
  state: ETicketFootprint[] = [],
  action: any
): ETicketFootprint[] => {
  switch (action.type) {
    case FETCH_E_TICKET_FOOTPRINTS_SUCCESS:
      return action.response.e_ticket_footprints;

    default:
      return state;
  }
};

interface State {
  all: ETicketFootprint[];
  loading: boolean;
  error: string;
  downloading: boolean;
}

export const eTicketFootprints = combineReducers<State>({
  all,
  loading,
  error,
  downloading,
});
