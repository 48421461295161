import * as React from 'react';
import { Input } from 'semantic-ui-react';

import { currency } from 'shared/libraries/currency';
import { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';

type Props = {
  currencyCode: string;
  moneyAmount: string;
  onChange: (arg0: string) => void;
};
export const MoneyInput = ({ currencyCode, moneyAmount, onChange }: Props) => {
  const currencyInputHelper = new CurrencyAmountInputHelper(currencyCode);
  const amount = moneyAmount.length > 3 ? moneyAmount.substring(3) : '';
  return (
    <Input
      label={currencyCode}
      value={amount}
      onChange={(e, { value }) => {
        if (!currencyInputHelper.inputAllowed(value)) {
          return;
        }

        const newMoneyAmount = `${currencyCode}${value}`;

        // Reject input if it has too many decimal places.
        if (
          value &&
          currency(newMoneyAmount).format().length <
            value.length + currencyCode.length
        ) {
          return;
        }

        if (value) {
          onChange(newMoneyAmount);
        } else {
          onChange('');
        }
      }}
    />
  );
};
