import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { SourceLanguage } from 'shared/models/swagger';

import { SingleDropdown } from '../Form/Dropdown/SingleDropdown';

export interface Props {
  contentLanguage: SourceLanguage | null;
  onChange: (contentLanguage: SourceLanguage) => void;
}

export const ContentLanguageSelector = ({
  contentLanguage,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));

  const [supportLanguages, setSupportLanguages] = React.useState<
    SourceLanguage[]
  >([]);

  const selectableContentLanguageOptions = languageOptions.filter((option) => {
    return supportLanguages.includes(option.value);
  });

  const activeOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    setSupportLanguages(
      getBookingWidgetPmpSupportLanguages(activeOrganization)
    );
  }, [activeOrganization]);

  React.useEffect(() => {
    if (selectableContentLanguageOptions.length !== 0) {
      const defaultContentLanguage = selectableContentLanguageOptions[0].value;
      if (contentLanguage === null) {
        onChange(defaultContentLanguage);
      }
    }
  }, [selectableContentLanguageOptions]);

  return (
    <>
      <p style={{ marginBottom: '4px' }}>{t('Content Language')}</p>
      <SingleDropdown
        options={selectableContentLanguageOptions}
        onChange={(value) => {
          onChange(value as SourceLanguage);
        }}
        selectedOption={contentLanguage as string}
      />
    </>
  );
};
