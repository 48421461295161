import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import {
  activeUserOrganizationSelector,
  accountsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import type { ReduxState } from 'client/reducers';
import type {
  ProductSummary,
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { CustomTable } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/CustomTable';
import { FlexibleDashboardReservationListShape } from 'client/libraries/util/flexibleDashboardReservationListShape';
import {
  Column,
  getColumns,
} from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/util';
import { getReservationListInitialColumnsAsString } from 'client/pages/v3/FlexibleDashboard/util';

type Props = {
  excludedFormFieldKeys: string[]; // TODO: not sure if this is needed
  products: ProductSummary[];
  reservations: FlexibleDashboardReservationListShape[];
  visibleColumns: ReservationColumn[];
  startTime?: string;
  totalPax?: number;
  open?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[]; // TODO: not sure if this is needed
  capacity?: number;
  memoKey?: string | null;
  gadgetKey: string;
};

const defaultProducts: ProductSummary[] = [];

export const ReservationListTable = ({
  excludedFormFieldKeys,
  products = defaultProducts,
  reservations,
  visibleColumns,
  startTime,
  totalPax,
  customizedColumnNames,
  capacity,
  memoKey,
  gadgetKey,
}: Props) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const { t } = useTranslation();
  const accounts = useSelector(accountsSelector);
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dashboardSettings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );
  const shouldShowCheckinInfo = hasSubscription(
    activeUserOrganization,
    'feature-qr-checkin'
  );
  const checkedInGuestCount =
    _.sum(
      reservations.map((reservation) => {
        if ((reservation.package_component_reservations ?? []).length > 0) {
          const checkedInGuestsByPackageComponent: Record<string, number> = {};

          for (const packageComponentReservation of reservation.package_component_reservations) {
            checkedInGuestsByPackageComponent[
              packageComponentReservation.reservation_id
            ] = 0;
          }

          for (const checkinRecord of reservation?.checkin_info
            ?.checkin_records ?? []) {
            if (checkinRecord.package_component_reservation_id) {
              checkedInGuestsByPackageComponent[
                checkinRecord.package_component_reservation_id
              ] += checkinRecord.guest_count ?? 0;
            } else {
              for (const reservationId of Object.keys(
                checkedInGuestsByPackageComponent
              )) {
                checkedInGuestsByPackageComponent[reservationId] +=
                  checkinRecord.guest_count ?? 0;
              }
            }
          }

          let minCheckedInGuestCount = (reservation?.guests ?? []).length;

          for (const productId of Object.keys(
            checkedInGuestsByPackageComponent
          )) {
            if (
              checkedInGuestsByPackageComponent[productId] <
              minCheckedInGuestCount
            ) {
              minCheckedInGuestCount =
                checkedInGuestsByPackageComponent[productId];
            }
          }

          return minCheckedInGuestCount;
        } else {
          return Math.min(
            _.sum(
              (reservation?.checkin_info?.checkin_records ?? []).map(
                (record) => record.guest_count ?? 0
              )
            ),
            (reservation?.guests ?? []).length
          );
        }
      })
    ) ?? 0;

  useEffect(() => {
    const columns = getColumns(
      locale,
      t,
      excludedFormFieldKeys,
      products,
      reservations,
      visibleColumns,
      customizedColumnNames || [],
      guideAccountShapes || [],
      null,
      null,
      activeUser
    ).map((c) => {
      return c as Column;
    });

    // Filter and sort columns visible columns based on dashboard settings if any
    const gadget = dashboardSettings?.gadgets?.find(
      (g) => g.gadgetType === 'reservation-list' && g.key === gadgetKey
    );

    if (gadget && gadget.gadgetType === 'reservation-list') {
      const defaultColumns = gadget.params.defaultColumns ?? [];

      if (defaultColumns.length > 0) {
        const newVisibleColumns = columns
          .filter((column) =>
            defaultColumns.includes(
              // TODO: casting is unavoidable for now, find a better solution
              column.id.toUpperCase() as ReservationColumn
            )
          )
          .sort((a, b) => {
            const indexA = defaultColumns.indexOf(
              a.id.toUpperCase() as ReservationColumn
            );
            const indexB = defaultColumns.indexOf(
              b.id.toUpperCase() as ReservationColumn
            );

            return indexA - indexB;
          });
        setColumns(newVisibleColumns);
      } else {
        setColumns([]);
      }
    }

    // If dashboard settings not found get from current visible columns
    else {
      const newVisibleColumns = columns
        .filter((column) =>
          visibleColumns.includes(column.id.toUpperCase() as ReservationColumn)
        )
        .sort((a, b) => {
          return (
            getReservationListInitialColumnsAsString.indexOf(
              a.id.toUpperCase()
            ) -
            getReservationListInitialColumnsAsString.indexOf(b.id.toUpperCase())
          );
        });
      setColumns(newVisibleColumns);
    }
  }, [
    locale,
    t,
    excludedFormFieldKeys,
    products,
    visibleColumns,
    reservations,
    dashboardSettings,
  ]);

  return (
    <div className={styles['p-dashboard__body__plane']}>
      {columns.length === 0 && <div>{t('No column to display')}</div>}
      {columns.length > 0 && (
        <CustomTable
          items={reservations}
          columns={columns}
          startTime={startTime}
          totalPax={totalPax}
          capacity={capacity}
          checkedInGuestCount={
            shouldShowCheckinInfo ? checkedInGuestCount : null
          }
          memoKey={memoKey}
        />
      )}
    </div>
  );
};
