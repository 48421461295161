import * as React from 'react';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

export interface Props {
  value: string;
  onChange: (newValue: string) => void;
  label?: string;
}

export const FontPicker = ({ value, onChange, label }: Props) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);

  const options = [
    {
      value: 'helvetica',
      text: 'Helvetica',
    },
    {
      value: 'arial',
      text: 'Arial',
    },
    {
      value: 'arial black',
      text: 'Arial Black',
    },
    {
      value: 'verdana',
      text: 'Verdana',
    },
    {
      value: 'tahoma',
      text: 'Tahoma',
    },
    {
      value: 'trebuchet ms',
      text: 'Trebuchet MS',
    },
    {
      value: 'impact',
      text: 'Impact',
    },
    {
      value: 'gill sans',
      text: 'Gill Sans',
    },
    {
      value: 'times new roman',
      text: 'Times New Roman',
    },
    {
      value: 'georgia',
      text: 'Georgia',
    },
    {
      value: 'palatino',
      text: 'Palatino',
    },
    {
      value: 'baskerville',
      text: 'Baskerville',
    },
    {
      value: 'andale mono',
      text: 'Andale Mono',
    },
    {
      value: 'courier',
      text: 'Courier',
    },
    {
      value: 'lucida',
      text: 'Lucida',
    },
    {
      value: 'monaco',
      text: 'Monaco',
    },
    {
      value: 'bradley hand',
      text: 'Bradley Hand',
    },
    {
      value: 'brush script mt',
      text: 'Brush Script MT',
    },
    {
      value: 'luminari',
      text: 'Luminari',
    },
    {
      value: 'comic sans ms',
      text: 'Comic Sans MS',
    },
    {
      value: "'BIZ UDPMincho', serif",
      text: 'BIZ UDPMincho',
    },
    { value: "'Klee One', cursive", text: 'Klee One' },
    { value: "'Kosugi Maru', sans-serif", text: 'Kosugi Maru' },
    { value: "'M PLUS 1p', sans-serif", text: 'M PLUS 1p' },
    { value: "'Noto Sans JP', sans-serif", text: 'Noto Sans JP' },
    { value: "'Noto Serif JP', serif", text: 'Noto Serif JP' },
    { value: "'Shippori Mincho', serif", text: 'Shippori Mincho' },
    { value: "'Zen Kaku Gothic New', sans-serif", text: 'Zen Kaku Gothic New' },
  ];

  return (
    <div className={baseStyles['base-form-box']}>
      {label && (
        <div className={baseStyles['base-form-box__header']}>{label}</div>
      )}
      <div className={baseStyles['base-form-box__body']}>
        <div className={componentStyles['c-form-single']}>
          <label
            className={clsx(
              componentStyles['select'],
              showOption
                ? options.length > 0
                  ? componentStyles['is-active']
                  : ''
                : ''
            )}
          >
            <input
              style={{ fontFamily: value }}
              value={options.find((o) => o.value === value)?.text ?? ''}
              type="text"
              readOnly={true}
              onClick={() => setShowOption(true)}
            />
          </label>
          {options.length > 0 && (
            <ul className={componentStyles['option']}>
              {options.map((option, idx) => {
                return (
                  <li
                    key={idx}
                    data-value={option.value}
                    data-idx={idx}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      onChange(option.value);
                      setShowOption(false);
                    }}
                    style={{ fontFamily: option.value }}
                  >
                    {option.text || 'NO WORD'}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
