import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type { LanguageISO } from 'shared/libraries/i18n';
import { getActionSourceEntityName } from 'client/libraries/util/util';
import { TranslateFuncType } from 'client/components/Translate';
import type {
  Reservation,
  ActionSource,
  ReservationUpdateCategory,
} from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Badge } from 'client/components/v3/Common/Badge';
import baseStyles from 'client/v3-base.module.css';
import { getBadgeColorForReservationStatus } from 'client/libraries/util/getBadgeColorForReservationStatus';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  locale: LanguageISO;
};
type Action = {
  actionDescription: string;
  actionSource?: ActionSource;
  time: string;
  updateCategories?: ReservationUpdateCategory[];
  status: string;
};

const getUpdateCategoryText = (
  category: ReservationUpdateCategory,
  t: TranslateFuncType
) => {
  switch (category) {
    case 'SUPPLIER_REFERENCE':
      return t('Edit Confirmation Number');
    case 'PICKUP_DROPOFF':
      return t('Edit Pickup/Dropoff');
    case 'CHECKIN':
      return t('Edit Checkin');
    case 'GUEST_HOTEL':
      return t('Edit Customer Hotel');
    case 'FIELD_RESPONSES':
      return t('Update Field Response');
    case 'SUPPLIER_NOTES':
      return t('Edit Replies, Memo, Internal Note');
    case 'AGENT_NOTES':
      return t('Update: Remarks');
    case 'FARE_ADJUSTMENT_CHARGE':
      return t('Fare Adjustment: Charge');
    case 'FARE_ADJUSTMENT_REFUND':
      return t('Fare Adjustment: Refund');
  }
};

export const ActivityLog = ({ reservation, locale }: Props) => {
  const { t } = useTranslation();

  const getActions = (): Action[] => {
    const activities: Action[] = [];
    const statusHistory = reservation.status_history.slice(1);

    for (let i = 0; i < statusHistory.length; i++) {
      const s = statusHistory[i];

      switch (s.status) {
        case 'REQUESTED':
        case 'CONFIRMED':
        case 'DECLINED_BY_SUPPLIER':
        case 'WITHDRAWN_BY_AGENT':
        case 'STANDBY':
        case 'CANCELED_BY_AGENT':
        case 'CANCELED_BY_SUPPLIER':
        case 'CANCELED_BY_GUEST':
          activities.push({
            actionDescription: t(s.status),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
            updateCategories: s.update_categories,
            status: s.status,
          });
          break;

        default:
          continue;
      }
    }

    return activities;
  };

  const printAction = (action: Action): string => {
    const { actionDescription, actionSource } = action;

    if (actionSource) {
      const entityName = getActionSourceEntityName(actionSource, t);
      const entityDescription = actionSource.entity_description;
      if (entityName && entityDescription)
        return t('{{entityName}} ({{personName}})', {
          personName: entityDescription,
          entityName,
          actionDescription,
        });
      else if (entityName)
        return t('{{entityName}}', {
          entityName,
          actionDescription,
        });
      else if (entityDescription)
        return t('{{personName}}', {
          personName: entityDescription,
          actionDescription,
        });
    }

    return '';
  };

  const actions = getActions();

  return (
    <section
      id="activityLog"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Activity Log')}
          </p>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className="p-histories">
            <table
              className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
            >
              <thead>
                <tr>
                  <th className={baseStyles['u-width-208']}>{t('Date')}</th>
                  <th className={baseStyles['u-width-176']}>
                    {t('Last Updated By')}
                  </th>
                  <th className={baseStyles['u-width-208']}>{t('Status')}</th>
                  <th>{t('Update Content')}</th>
                </tr>
              </thead>
              <tbody>
                {actions.map((action, idx) => {
                  const actionText = printAction(action);
                  return (
                    actionText && (
                      <tr key={idx}>
                        <td className={baseStyles['u-pcHidden']}>
                          <Badge
                            label={action.actionDescription}
                            color={getBadgeColorForReservationStatus(
                              action.status
                            )}
                          />
                        </td>
                        <td className={tableStyles['c-tableSmall__spNoTh']}>
                          {moment(action.time).locale(locale).format('lll')}
                        </td>
                        <td className={tableStyles['c-tableSmall__spNoTh']}>
                          {actionText}
                        </td>
                        <td className={baseStyles['u-spHidden']}>
                          <Badge
                            label={action.actionDescription}
                            color={getBadgeColorForReservationStatus(
                              action.status
                            )}
                          />
                        </td>
                        <td
                          className={clsx(
                            tableStyles['c-tableSmall__spNoTh'],
                            tableStyles['c-tableSmall__spTopBorder']
                          )}
                        >
                          {action.updateCategories?.length
                            ? getUpdateCategoryText(
                                action.updateCategories[0],
                                t
                              )
                            : '-'}
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
