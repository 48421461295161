import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type ListEditorProps = {
  title?: string;
  tooltipText?: string;
  name: string;
  style?: Record<string, any>;
  fieldDisabled?: boolean;
  buttonDisabled?: boolean;
  suggestions?: string[];
  addButtonText?: string;
};

export const ListEditor = ({
  title,
  tooltipText,
  name,
  style,
  fieldDisabled,
  buttonDisabled,
  suggestions,
  addButtonText,
}: ListEditorProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);
  const combinedStyle = {
    maxWidth: 800,
    ...style,
  };

  return (
    <li className={clsx(styles['p-list__item'], styles['row'])}>
      {title && (
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{title}</div>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={clsx(styles['p-list__item__body'], styles['row'])}>
            <div className={styles['p-list__item__body__list']}>
              {fields.length !== 0 &&
                fields.map((name, idx) => (
                  <div
                    key={name}
                    className={clsx(styles['p-list__item__body__flex'])}
                  >
                    <TranslatedField name={name}>
                      {({ input, translationInput }) => (
                        <>
                          <div
                            className={styles['p-list__item__body__flex__main']}
                          >
                            {suggestions && suggestions.length > 0 ? (
                              <SingleDropdown
                                options={suggestions
                                  .filter(
                                    (suggestion) =>
                                      !(fields.value ?? []).includes(
                                        suggestion
                                      ) || input.value === suggestion
                                  )
                                  .map((suggestion) => ({
                                    value: suggestion,
                                    text: suggestion,
                                  }))}
                                searchable={true}
                                selectedOption={input.value}
                                onChange={(value) => input.onChange(value)}
                              />
                            ) : (
                              <TextField
                                style={combinedStyle}
                                disabled={fieldDisabled}
                                {...input}
                              />
                            )}
                          </div>
                          <div
                            className={
                              styles['p-list__item__body__flex__actions']
                            }
                          >
                            {!buttonDisabled && (
                              <Button
                                size="icon"
                                color="tertiarygray"
                                onClick={() => fields.remove(idx)}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                              />
                            )}
                          </div>
                          {translationTargetLanguage && (
                            <div
                              className={
                                styles['p-list__item__body__flex__translation']
                              }
                            >
                              <TextField
                                placeholder={translationTargetLanguageName}
                                {...translationInput}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </TranslatedField>
                  </div>
                ))}
              {!buttonDisabled && (
                <div className={baseStyles['u-mt-2']}>
                  <a
                    className={styles['p-box__table2__actions__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, '');
                      } else {
                        (fields as any).insertAt(0, '');
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {addButtonText}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </FieldArray>
    </li>
  );
};
