import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { AvailabilityDatesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AvailabilityDatesInput';
import { BookingDeadlinesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/BookingDeadlinesInput';
import { CancellationPoliciesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/CancellationPoliciesInput';
import { StartTimesInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/StartTimesInput';
import { updateProduct } from 'client/actions/products';
import {
  convertReservationParamsFormValuesToProductPatch,
  getMinStartDate,
  getMaxEndDate,
} from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { getArrayMutators } from 'client/libraries/util/form';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { ProductEditReservationPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditReservationPopUp';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import {
  convertToBookingDeadline,
  convertToCancellationPolicy,
  isDefaultCancellationPolicy,
} from 'client/libraries/util/productShape';
import type { BookingDeadline } from 'client/libraries/util/productShape';
import type { ReservationParamsFormValues } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import { MinimumParticipantsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/MinimumParticipantsEditor';
import { MinimumParticipantFormValues } from 'client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/FormValues';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { BasicFormValues } from 'client/pages/v3/Product/ProductEdit/types';
import { convertMinimumParticipantFormValuesToProductPatch } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/FormValues';
import { getInitialMinimumParticipantFormValues } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/util';

const focusOnError = createDecorator();

const getInitialValues = (
  product: Product | null,
  basicFormValues: BasicFormValues | null,
  defaultCurrency: string
): ReservationParamsFormValues & MinimumParticipantFormValues => {
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  const recurrenceRule =
    product?.recurrence && product.recurrence.length > 0
      ? product.recurrence.slice(-1)[0]
      : null;
  const allotmentRule =
    product?.allotment_rules && product.allotment_rules.length > 0
      ? product.allotment_rules.slice(-1)[0]
      : null;
  const startDate = recurrenceRule?.start_date_local ?? defaultStartDate;
  const endDate = recurrenceRule?.end_date_local ?? defaultEndDate;
  const weekdays = recurrenceRule?.days_of_week ?? [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ];
  const closedDates = _.sortBy(
    [
      ...(recurrenceRule?.closed_dates ?? []).map((closedDate) => ({
        date: closedDate,
        repeatsAnnually: false,
      })),
      ...(recurrenceRule?.annually_repeating_closed_dates ?? []).map(
        (closedDate) => ({
          date: closedDate,
          repeatsAnnually: true,
        })
      ),
    ],
    (closedDate) => closedDate.date
  );
  const freesaleSlotLimit = 10000000;

  const startTimes = recurrenceRule?.start_times
    ? recurrenceRule.start_times.map((startTime) => ({
        time: startTime.start_time_local,
        duration: startTime.duration,
        perChannelAllotments: [],
        allotmentSlots: allotmentRule?.start_time_allotments
          ? allotmentRule.start_time_allotments.find(
              (rule) => rule.time_slot_key === startTime.time_slot_key
            )?.common_allotment_slots ?? 0
          : 10,
        isFreesale:
          (allotmentRule?.start_time_allotments.find(
            (rule) => rule.time_slot_key === startTime.time_slot_key
          )?.common_allotment_slots ?? 0) >= freesaleSlotLimit,
        description: startTime.description ?? '',
        packageComponentTimeSlots: [],
      }))
    : [
        {
          time: '9:00',
          duration: '3:00',
          perChannelAllotments: [],
          allotmentSlots: 10,
          description: '',
          packageComponentTimeSlots: [],
        },
      ];

  // Define default value to avoid ts constraints
  let instantBookingDeadline: BookingDeadline = {
    deadlineType: 'HOUR',
    hoursBefore: 0,
  };
  let requestBookingDeadline: BookingDeadline = {
    deadlineType: 'HOUR',
    hoursBefore: 0,
  };

  const productHasBookingDeadlines =
    product?.booking_deadlines != null && product.booking_deadlines.length > 0;
  const productInstantBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'INSTANT'
  );
  const productRequestBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'REQUEST'
  );
  if (productHasBookingDeadlines) {
    if (productInstantBookingDeadline) {
      instantBookingDeadline = convertToBookingDeadline(
        productInstantBookingDeadline
      );
    }
    if (productRequestBookingDeadline) {
      requestBookingDeadline = convertToBookingDeadline(
        productRequestBookingDeadline
      );
    }
  } else {
    instantBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 2,
      timeOfDay: '17:00',
    };
    requestBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 1,
      timeOfDay: '17:00',
    };
  }

  const cancellationPolicies = (
    product?.cancellation_policies != null &&
    product.cancellation_policies.length > 0
      ? product.cancellation_policies
          .filter(
            (cancellationPolicy) =>
              !isDefaultCancellationPolicy(cancellationPolicy)
          )
          .map((cancellationPolicy) =>
            convertToCancellationPolicy(cancellationPolicy)
          )
      : [
          {
            deadlineType: 'DAY',
            daysBefore: 2,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '0',
          },
          {
            deadlineType: 'DAY',
            daysBefore: 1,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '50',
          },
        ]
  ) as any;

  return {
    dateRanges: [
      {
        startDate,
        endDate,
      },
    ],
    weekdays,
    startTimes,
    closedDates,
    instantBookingDeadline,
    requestBookingDeadline,
    cancellationPolicies,
    agentGuestBookingAllowedDaysBefore:
      product?.agent_guest_booking_period_settings
        ?.booking_allowed_days_before_participation ?? null,
    ...getInitialMinimumParticipantFormValues(basicFormValues, defaultCurrency),
    shouldSendReminderEmail: true,
  };
};

type Props = {
  onSubmitSuccess: () => void;
  onSubmitFailed: () => void;
  setCurrentSectionId: Dispatch<SetStateAction<string>>;
};

export const ReservationParametersEditor = ({
  onSubmitSuccess,
  onSubmitFailed,
  setCurrentSectionId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  const editingProduct = useContext(EditingProductContext);
  const hasPerParticipantPricing =
    editingProduct?.pricing == null ||
    editingProduct?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });
  const isPassthrough = Boolean(
    editingProduct?.shared_allotment_references?.passthrough_base_product_id
  );

  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = useState<boolean>(false);
  const { basicFormValues } = useContext(ProductHelperContext);

  useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0 || basicFormValues?.productType === 'PACKAGE';
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const initialValues = useMemo(
    () => getInitialValues(editingProduct, basicFormValues, defaultCurrency),
    []
  );

  return (
    <>
      <Form
        onSubmit={async (
          values: ReservationParamsFormValues & MinimumParticipantFormValues
        ) => {
          try {
            const pricing = (editingProduct?.pricing ?? []).map(
              (priceRule) => ({
                ...priceRule,
                start_date_local: getMinStartDate(values.dateRanges),
                end_date_local: getMaxEndDate(values.dateRanges),
              })
            );
            await dispatch(
              updateProduct(editingProduct?.id || '', {
                ...convertReservationParamsFormValuesToProductPatch(
                  values,
                  isPackage,
                  defaultCurrency
                ),
                ...pricing,
                // Note: as a side effect this fills in minimum participant info in product.pricing
                // Since form values are combined together now, use the currently edited pricing values
                ...convertMinimumParticipantFormValuesToProductPatch(
                  values,
                  pricing
                ),
              })
            );

            await onSubmitSuccess();
          } catch (err) {
            await onSubmitFailed();
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        // Need to disable this in tsx
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitError }) => (
          <>
            <Snackbar
              text={t('Save Failed')}
              color="error"
              shouldShow={submitError}
            />
            <form
              id="createProductReservationParametersForm"
              onSubmit={handleSubmit}
            >
              <section
                id="reservationParameters"
                className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
              >
                <p className={styles['p-products__ttl']}>
                  {t('Reservation Parameters')}
                </p>
                <p className={baseStyles['u-mt-2']}>
                  {t('Register information required to accept reservations')}
                </p>
                <CollapsibleSection
                  id="availabilitySettings"
                  title={t('Availability Settings')}
                  setCurrentSectionId={setCurrentSectionId}
                >
                  <AvailabilityDatesInput />
                </CollapsibleSection>
                <CollapsibleSection
                  id="timesAndInventory"
                  title={t('Times and Inventory')}
                  setCurrentSectionId={setCurrentSectionId}
                >
                  <StartTimesInput
                    showAllotment={!isPackage && !isSharedAllotment}
                  />
                </CollapsibleSection>
                {showMinimumParticipants &&
                  basicFormValues?.productType === 'NORMAL' && (
                    <CollapsibleSection
                      id="mininumParticipantsAndPerUnitSettings"
                      title={t('Minimum Participants & Per-Unit Settings')}
                      subtitle={t(
                        'If minimum participants are set, reservations will be received as request-only until this number of people is reached'
                      )}
                      initialOpen={Boolean(
                        !!editingProduct?.minimum_participant_count?.value ||
                          editingProduct?.pricing?.some((priceRule) => {
                            priceRule.units?.some((unit) => {
                              return !!unit?.guest_type
                                ?.minimum_participant_parameters?.weight;
                            });
                          }) ||
                          editingProduct?.allotment_settings?.inventory_consumption_rules?.some(
                            (rule) => rule.should_not_count_inventory
                          )
                      )}
                      setCurrentSectionId={setCurrentSectionId}
                    >
                      <MinimumParticipantsEditor />
                    </CollapsibleSection>
                  )}
                <CollapsibleSection
                  id="bookingPeriod"
                  title={t('Booking Deadlines')}
                  setCurrentSectionId={setCurrentSectionId}
                >
                  <BookingDeadlinesInput />
                </CollapsibleSection>
                <CollapsibleSection
                  id="cancellationPolicies"
                  title={t('Cancellation Policies')}
                  setCurrentSectionId={setCurrentSectionId}
                >
                  <CancellationPoliciesInput />
                </CollapsibleSection>

                {isTutorialActive && showHelpPopUp && (
                  <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
                )}
              </section>
            </form>
          </>
        )}
      </Form>
      {isTutorialActive && showPopUp && (
        <ProductEditReservationPopUp popUpDisable={() => setShowPopUp(false)} />
      )}
    </>
  );
};
