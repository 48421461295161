import { combineReducers } from 'redux';
import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  CHANGE_USER_ATTRIBUTES_FAILURE,
  CHANGE_USER_ATTRIBUTES_REQUEST,
  CHANGE_USER_ATTRIBUTES_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_REQUEST_CANCEL,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SET_ACTOR_NAME,
  SET_IMPERSONATED_USER_ID,
  SET_ALTERNATE_ORGANIZATION_IS_ACTIVE,
  SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET,
  SEND_ONETIME_CODE_REQUEST,
  SEND_ONETIME_CODE_FAILURE,
  SEND_ONETIME_CODE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import type { Account, Organization } from 'shared/models/swagger';

// Selectors
export const alternateOrganizationIsActiveSelector = (state: ReduxState) =>
  state.user.alternateOrganizationIsActive;
export const alternateOrganizationIsActiveSelectorInSecret = (
  state: ReduxState
) => state.user.alternateOrganizationIsActiveInSecret;
export const impersonatedAccountIDSelector = (state: ReduxState) =>
  state.user.impersonatedAccountID;
export const accountsSelector = (state: ReduxState) => state.accounts.all;

const cognitoSelector = (state: ReduxState) => state.user.cognito;

const attrMapSelector = (state: ReduxState) => state.user.attrMap;

const actorSelector = (state: ReduxState) => state.user.actor;

// LoggedInAccount: account that logged-in with a username or password
// ActiveAccount:
//  If nutmeg admin, the account we are impersonating.
//  Otherwise, the active account
// ActiveUser:
//  If alternate organization is active, the ImpersonatedAccount with the alternate organization information
//    substituted in for the primary organization fields
//  Otherwise, the impersonated account.
export const loggedInAccountSelector = createSelector(
  cognitoSelector,
  attrMapSelector,
  (cognito, attrMap) => {
    if (!cognito || !attrMap) {
      return null;
    }

    return {
      id: cognito.username,
      email: attrMap['email'],
      name: attrMap['name'],
      role: attrMap['custom:user_role'] as any,
      locale: attrMap['locale'],
      organization_id: attrMap['custom:organization_id'],
      organization_type: attrMap['custom:organization_type'],
      organization_name: attrMap['custom:organization_name'],
      organization_parent_id: attrMap['custom:org_parent_id'],
      alternate_organization_id: attrMap['custom:alt_org_id'],
      alternate_organization_type: attrMap['custom:alt_org_type'],
      alternate_organization_name: attrMap['custom:alt_org_name'],
      permissions: attrMap['custom:permissions'],
      bookmarks: attrMap['custom:bookmarks'],
      manageable_organization_ids: attrMap['custom:manageable_org_ids'],
    } as any as Account;
  }
);
export const loggedInAccountIsNutmegAdminSelector = createSelector(
  loggedInAccountSelector,
  (loggedInAccount) => loggedInAccount?.role === 'nutmeg_admin'
);
export const impersonatedAccountSelector = createSelector(
  impersonatedAccountIDSelector,
  accountsSelector,
  (id, accounts) => accounts.find((a: Account) => a.id === id)
);
export const activeAccountSelector = createSelector(
  impersonatedAccountSelector,
  loggedInAccountSelector,
  (impUser, loggedInUser) => impUser || loggedInUser
);
export const activeUserSelector = createSelector(
  activeAccountSelector,
  alternateOrganizationIsActiveSelector,
  (activeAccount, altOrgActive) => {
    if (altOrgActive && activeAccount?.alternate_organization_id) {
      // If primary org is an agent and alternate org is a supplier, switch role to 'PRODUCT_EDITOR' for convenience.
      const newRole =
        activeAccount.organization_type === 'AGENT' &&
        activeAccount.alternate_organization_type === 'SUPPLIER'
          ? 'PRODUCT_EDITOR'
          : activeAccount.role;
      return {
        ...activeAccount,
        organization_id: activeAccount.alternate_organization_id,
        organization_type: activeAccount.alternate_organization_type,
        organization_name: activeAccount.alternate_organization_name,
        alternate_organization_id: activeAccount.organization_id,
        alternate_organization_type: activeAccount.organization_type,
        alternate_organization_name: activeAccount.organization_name,
        role: newRole,
      };
    }

    return activeAccount;
  }
);
export const activeUserIsNutmegAdminSelector = createSelector(
  activeUserSelector,
  (activeUser) => (activeUser ? activeUser.role === 'nutmeg_admin' : false)
);
export const organizationsSelector = (state: ReduxState) =>
  state.organizations.all;
export const activeUserAllOrganizationsSelector = createSelector(
  activeUserSelector,
  organizationsSelector,
  (user, organizations) =>
    user
      ? organizations.filter(
          (org: Organization) =>
            org.type === user.organization_type &&
            (org.id === user.organization_id ||
              org.parent_id === user.organization_id)
        )
      : organizations
);
export const activeUserOrganizationSelector = createSelector(
  activeUserSelector,
  organizationsSelector,
  (activeUser, organizations): Organization | null => {
    if (activeUser?.organization_id && activeUser.organization_type) {
      return (
        organizations.find(
          (org: Organization) =>
            org.id === activeUser.organization_id &&
            org.type === activeUser.organization_type
        ) ?? null
      );
    }

    return null;
  }
);
export const guideAccountShapesSelector = createSelector(
  accountsSelector,
  activeUserOrganizationSelector,
  (accounts, organization) =>
    getGuideAccountShapes(accounts || [], organization)
);

export const bookingWidgetPMPSupportedLanguagesSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => getBookingWidgetPmpSupportLanguages(organization)
);
// 'Actor' defaults to information from the active user but may be overridden when different
// people share a single account.
export const currentActorSelector = createSelector(
  activeUserSelector,
  actorSelector,
  (user, actor) => {
    const currentActor: {
      name?: string;
    } = {
      name: user?.name,
    };

    if (actor && Object.prototype.hasOwnProperty.call(actor, 'name')) {
      currentActor.name = actor.name;
    }

    return currentActor;
  }
);

export const agentOptionsSelector = createSelector(
  (state: ReduxState) => state.organizations.contracted,
  activeUserAllOrganizationsSelector,
  (contractedOrganizations, allOrganizations) => {
    return _.orderBy(
      _.uniqBy(
        [
          ...contractedOrganizations,
          ...allOrganizations
            .filter((org) => org.type === 'AGENT')
            .map((org) => ({
              id: org.id,
              name: org.name,
            })),
        ],
        (agent) => agent.id
      ),
      [(agent) => agent.name?.toLowerCase()]
    ).map((agent) => ({
      key: agent.id ?? '',
      text: agent.name ?? '',
      value: agent.id ?? '',
    }));
  }
);

// Reducers
const changingAttr = (state = false, action: any) => {
  switch (action.type) {
    case CHANGE_USER_ATTRIBUTES_REQUEST:
      return true;

    case CHANGE_USER_ATTRIBUTES_SUCCESS:
    case CHANGE_USER_ATTRIBUTES_FAILURE:
      return false;

    default:
      return state;
  }
};

const changingAttrError = (state = '', action: any) => {
  switch (action.type) {
    case CHANGE_USER_ATTRIBUTES_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const changingAttrSuccess = (state = false, action: any) => {
  switch (action.type) {
    case CHANGE_USER_ATTRIBUTES_SUCCESS:
      return true;

    case CHANGE_USER_ATTRIBUTES_REQUEST:
    case CHANGE_USER_ATTRIBUTES_FAILURE:
      return false;

    default:
      return state;
  }
};

const changingPassword = (state = false, action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return true;

    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE:
      return false;

    default:
      return state;
  }
};

const changingPasswordError = (state = '', action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const lastChangingPasswordStatus = (state = '', action: any) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return 'REQUESTED';

    case CHANGE_PASSWORD_SUCCESS:
      return 'SUCCEEDED';

    case CHANGE_PASSWORD_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

const forgotPassword = (state = false, action: any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
      return true;

    case FORGOT_PASSWORD_REQUEST_CANCEL:
    case FORGOT_PASSWORD_REQUEST_FAILURE:
    case LOGIN_SUCCESS:
    case SEND_ONETIME_CODE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const cognito = (state = null, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case SEND_ONETIME_CODE_SUCCESS:
      return action.user;

    case LOGOUT_SUCCESS:
    case LOGIN_FAILURE:
    case SEND_ONETIME_CODE_FAILURE:
      return null;

    default:
      return state;
  }
};

const attrMap = (state = {}, action: any): Record<string, string> => {
  switch (action.type) {
    case CHANGE_USER_ATTRIBUTES_SUCCESS:
      return { ...state, ...action.newAttr };

    case LOGIN_SUCCESS:
    case SEND_ONETIME_CODE_SUCCESS:
      return action.attributes;

    case LOGIN_FAILURE:
      return {};

    default:
      return state;
  }
};

const impersonatedAccountID = (state = '', action: any): string => {
  switch (action.type) {
    case SET_IMPERSONATED_USER_ID:
      return action.id;

    default:
      return state;
  }
};

// 'Actor' is a layer on top of Cognito user account. Multiple actors can use the
// same Cognito account. When each actor creates or modifies a reservation, their
// name will be registered with the action source for the edit.
const actor = (
  state: Actor | null = null,
  action: any
): {
  name?: string;
} | null => {
  switch (action.type) {
    case SET_ACTOR_NAME:
      return { ...(state ?? {}), name: action.payload };

    default:
      return state;
  }
};

const alternateOrganizationIsActive = (state = false, action: any): boolean => {
  switch (action.type) {
    case SET_ALTERNATE_ORGANIZATION_IS_ACTIVE:
      return action.payload;

    case SET_IMPERSONATED_USER_ID:
      return false;

    default:
      return state;
  }
};

const alternateOrganizationIsActiveInSecret = (
  state = false,
  action: any
): boolean => {
  switch (action.type) {
    case SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET:
      return action.payload;

    default:
      return state;
  }
};

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUESTED:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case SEND_ONETIME_CODE_REQUEST:
      return true;

    case FORGOT_PASSWORD_REQUEST_FAILURE:
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
    case LOGIN_FAILURE:
    case LOGIN_SUCCESS:
    case LOGOUT_FAILURE:
    case LOGOUT_SUCCESS:
    case SEND_ONETIME_CODE_FAILURE:
    case SEND_ONETIME_CODE_SUCCESS:
      return false;

    default:
      return state;
  }
};

type Actor = { name?: string };

type State = {
  actor: Actor | null;
  attrMap: any;
  changingAttr: boolean;
  changingAttrError: string;
  changingAttrSuccess: boolean;
  lastChangingPasswordStatus: string;
  loading: boolean;
  changingPassword: boolean;
  changingPasswordError: string;
  cognito: any;
  forgotPassword: boolean;
  impersonatedAccountID: string;
  alternateOrganizationIsActive: boolean;
  alternateOrganizationIsActiveInSecret: boolean;
};

const userReducers = {
  actor,
  attrMap,
  changingAttr,
  changingAttrError,
  changingAttrSuccess,
  lastChangingPasswordStatus,
  loading,
  changingPassword,
  changingPasswordError,
  cognito,
  forgotPassword,
  impersonatedAccountID,
  alternateOrganizationIsActive,
  alternateOrganizationIsActiveInSecret,
};
export const user = combineReducers<State>(userReducers);
