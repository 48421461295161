// Minimal version of material-ui Box component
import * as React from 'react';

type Props = {
  children: React.ReactNode;
  display?: 'flex' | 'inline' | 'block' | 'inline-block' | 'inline-flex';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit';
  gap?: number;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit';
  flexShrink?: number;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexGrow?: number;
  pl?: number | 'auto';
  pr?: number | 'auto';
  pt?: number | 'auto';
  pb?: number | 'auto';
  ml?: number | 'auto';
  mr?: number | 'auto';
  mt?: number | 'auto';
  mb?: number | 'auto';
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  width?: string;
  height?: string;
  className?: string;
  fontWeight?: string;
  zIndex?: number;
  position?: 'absolute' | 'relative' | 'fixed' | 'static' | 'sticky';
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

const getPx = (units?: number | 'auto'): string | typeof undefined => {
  if (units == null) {
    return undefined;
  }

  if (units === 'auto') {
    return 'auto';
  }

  const pxPerUnit = 4;
  return `${units * pxPerUnit}px`;
};

export const Box = ({
  children,
  display,
  fontWeight,
  alignItems,
  justifyContent,
  gap,
  flexWrap,
  flexDirection,
  pl,
  pr,
  pt,
  pb,
  ml,
  mr,
  mt,
  mb,
  maxWidth,
  minWidth,
  minHeight,
  width,
  height,
  flexGrow,
  flexShrink,
  className,
  zIndex,
  position,
  textAlign,
  top,
  left,
  right,
  bottom,
}: Props) => {
  return (
    <div
      style={{
        display,
        fontWeight,
        alignItems,
        justifyContent,
        gap: getPx(gap),
        flexWrap,
        flexDirection,
        paddingTop: getPx(pt),
        paddingBottom: getPx(pb),
        paddingRight: getPx(pr),
        paddingLeft: getPx(pl),
        marginTop: getPx(mt),
        marginBottom: getPx(mb),
        marginLeft: getPx(ml),
        marginRight: getPx(mr),
        maxWidth,
        minWidth,
        minHeight,
        width,
        height,
        flexGrow,
        flexShrink,
        zIndex,
        position,
        textAlign,
        top,
        left,
        right,
        bottom,
      }}
      className={className}
    >
      {children}
    </div>
  );
};
