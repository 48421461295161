import * as React from 'react';
import { Form, Icon } from 'semantic-ui-react';

import { ListOps } from 'client/libraries/util/coreutil';

const { addFront, add, remove } = ListOps<string>();

const ListSelectComponent = ({
  disabled,
  label,
  value,
  placeholder,
  options,
  isTranslation,
  onChange,
}: {
  disabled?: boolean;
  label: string;
  value: string[] | null | undefined;
  isTranslation?: boolean;
  placeholder?: string;
  options?: any;
  onChange: (newValue: string[]) => void;
}) => (
  <div className="field" key={label}>
    <label
      style={{
        paddingBottom: 5,
      }}
    >
      {label}
      {!isTranslation && !disabled && (
        <Icon
          link
          name="plus circle"
          color="blue"
          size="large"
          onClick={() => onChange(addFront(value, ''))}
        />
      )}
    </label>
    {value &&
      value.map((h, idx) => (
        <Form.Group key={idx} inline>
          <Form.Select
            disabled={disabled}
            width={15}
            placeholder={placeholder}
            options={options}
            value={h || ''}
            onChange={(e, { value: newItem }) =>
              onChange(
                (value as string[])?.map((h, idx2) =>
                  idx2 === idx ? (newItem as string) : h
                ) ?? []
              )
            }
          />
          {!isTranslation && !disabled && (
            <React.Fragment>
              <Icon
                link
                name="minus circle"
                color="red"
                size="large"
                onClick={() => onChange(remove(value, idx))}
              />
              <Icon
                link
                name="plus circle"
                color="blue"
                size="large"
                onClick={() => onChange(add(value, idx, ''))}
              />
            </React.Fragment>
          )}
        </Form.Group>
      ))}
  </div>
);

export const ListSelect = ListSelectComponent;
