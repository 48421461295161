import { combineReducers } from 'redux';

import {
  FETCH_WAIVER_TEMPLATES_REQUEST,
  FETCH_WAIVER_TEMPLATES_SUCCESS,
  FETCH_WAIVER_TEMPLATES_FAILURE,
  FETCH_WAIVER_TEMPLATE_REQUEST,
  FETCH_WAIVER_TEMPLATE_SUCCESS,
  FETCH_WAIVER_TEMPLATE_FAILURE,
  CREATE_WAIVER_TEMPLATE_REQUEST,
  CREATE_WAIVER_TEMPLATE_SUCCESS,
  CREATE_WAIVER_TEMPLATE_FAILURE,
  UPDATE_WAIVER_TEMPLATE_REQUEST,
  UPDATE_WAIVER_TEMPLATE_SUCCESS,
  UPDATE_WAIVER_TEMPLATE_FAILURE,
  DELETE_WAIVER_TEMPLATE_REQUEST,
  DELETE_WAIVER_TEMPLATE_SUCCESS,
  DELETE_WAIVER_TEMPLATE_FAILURE,
} from 'client/constants/ActionTypes';
import type { WaiverTemplate } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_WAIVER_TEMPLATES_REQUEST:
    case FETCH_WAIVER_TEMPLATE_REQUEST:
    case CREATE_WAIVER_TEMPLATE_REQUEST:
    case UPDATE_WAIVER_TEMPLATE_REQUEST:
    case DELETE_WAIVER_TEMPLATE_REQUEST:
      return true;

    case FETCH_WAIVER_TEMPLATES_SUCCESS:
    case FETCH_WAIVER_TEMPLATES_FAILURE:
    case FETCH_WAIVER_TEMPLATE_SUCCESS:
    case FETCH_WAIVER_TEMPLATE_FAILURE:
    case CREATE_WAIVER_TEMPLATE_SUCCESS:
    case CREATE_WAIVER_TEMPLATE_FAILURE:
    case UPDATE_WAIVER_TEMPLATE_SUCCESS:
    case UPDATE_WAIVER_TEMPLATE_FAILURE:
    case DELETE_WAIVER_TEMPLATE_SUCCESS:
    case DELETE_WAIVER_TEMPLATE_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_WAIVER_TEMPLATES_FAILURE:
    case FETCH_WAIVER_TEMPLATE_FAILURE:
    case CREATE_WAIVER_TEMPLATE_FAILURE:
    case UPDATE_WAIVER_TEMPLATE_FAILURE:
    case DELETE_WAIVER_TEMPLATE_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (state: WaiverTemplate[] = [], action: any): WaiverTemplate[] => {
  switch (action.type) {
    case FETCH_WAIVER_TEMPLATES_SUCCESS:
      return action.response.waiver_templates;

    case CREATE_WAIVER_TEMPLATE_SUCCESS:
      return [...state, action.response];

    case UPDATE_WAIVER_TEMPLATE_SUCCESS:
      return state?.map((item: WaiverTemplate) =>
        item.id === action.response.id ? action.response : item
      );

    case FETCH_WAIVER_TEMPLATE_SUCCESS:
      if (state?.find((w: WaiverTemplate) => w?.id === action.response.id)) {
        return state?.map((item: WaiverTemplate) =>
          item.id === action.response.id ? action.response : item
        );
      } else {
        return [...state, action.response];
      }

    case DELETE_WAIVER_TEMPLATE_SUCCESS:
      return state?.filter((item: WaiverTemplate) => item.id !== action.id);

    default:
      return state;
  }
};

const lastCreated = (state = null, action: any): WaiverTemplate | null => {
  switch (action.type) {
    case CREATE_WAIVER_TEMPLATE_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

export const waiverTemplates = combineReducers({
  all,
  loading,
  error,
  lastCreated,
});
