import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { ReservationGuestTypeRedemptionTable } from 'client/components/v3/ReservationGuestTypeRedemptionTable/ReservationGuestTypeRedemptionTable';
import type {
  Reservation,
  Product,
  ProductSummary,
} from 'shared/models/swagger';

type Props = {
  reservation?: Reservation | ManifestReservationShape;
  product?: Product | ProductSummary;
};

export const GuestTypeCountCheckin = ({ reservation, product }: Props) => {
  return (
    <ReservationGuestTypeRedemptionTable
      reservation={reservation}
      product={product}
    />
  );
};
