import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';

import styles from '../Tutorial.module.css';

type Props = {
  popUpDisable: () => void;
};

export const TutorialInformationPopUp = ({ popUpDisable }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['page-tutorial__popupFrame']}>
      <div className={styles['page-tutorial__popupFinish']}>
        <p className={styles['page-tutorial__popupFinish__header']}>
          {t('3 steps to launch booking website')}
        </p>
        <div className={styles['page-new-tutorial__popupFinish__body']}>
          <p>{t('Thank you for signing up for Nutmeg.')}</p>
          <p style={{ marginBottom: '15px' }}>
            {t('In three simple steps, you can launch booking website.')}
          </p>
          <p style={{ marginBottom: '0' }}>
            {t(
              '1. Create website: From 4 sample websites, select one for your website to be based on.'
            )}
          </p>
          <p style={{ marginBottom: '0' }}>
            {t(
              '2. Test booking: Visit your booking website and make a test booking.'
            )}
          </p>
          <p style={{ marginBottom: '15px' }}>
            {t(
              '3. Product & site settings: Go through product and site settings to launch your website.'
            )}
          </p>
          <p>
            {t(
              'Complete these 3 steps to open your website. You can always come back and edit details later.'
            )}
          </p>
          <button
            className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
            style={{ width: '128px', height: '32px', margin: 'auto' }}
            onClick={() => popUpDisable?.()}
          >
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};
