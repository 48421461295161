import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

export const getFormattedAmount = (moneyString?: string): string => {
  if (!moneyString) {
    return '-';
  }

  const formattedAmount = formattedCurrencyAmount(moneyString);

  const parts = formattedAmount.split(/\s+/);

  if (parts.length > 1) {
    return `${moneyString.includes('-') ? '-' : ''}${parts.slice(1).join('')}`;
  }

  return formattedAmount;
};
