import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/components/v3/Common/Loading';
import { NewTutorial } from 'client/pages/Home/Tutorial/NewTutorial';

import { FlexibleDashboard } from '../FlexibleDashboard/FlexibleDashboard';

export const Home = () => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const showTutorial =
    activeUserOrganization &&
    activeUserOrganization?.tutorial_stage &&
    activeUserOrganization?.tutorial_stage !== 'COMPLETED';

  if (activeUserOrganization?.tutorial_stage === 'DEFAULTS') {
    return <Redirect to="/signup/defaults" />;
  }

  if (!activeUserOrganization) {
    return <Loading size="md" />;
  }

  return showTutorial ? <NewTutorial /> : <FlexibleDashboard />;
};
