import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_PRODUCT_AVAILABILITY_FAILURE,
  FETCH_PRODUCT_AVAILABILITY_REQUEST,
  FETCH_PRODUCT_AVAILABILITY_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchProductAvailabilityRequest = () => ({
  type: FETCH_PRODUCT_AVAILABILITY_REQUEST,
});

const fetchProductAvailabilitySuccess = (response: any) => ({
  type: FETCH_PRODUCT_AVAILABILITY_SUCCESS,
  response,
});

const fetchProductAvailabilityFailure = (error: any) => ({
  type: FETCH_PRODUCT_AVAILABILITY_FAILURE,
  error,
});

let fetchProductAvailabilityCancel: () => void | typeof undefined;
export const fetchProductAvailability =
  (productIds: string[], dateFromStart: string, dateFromEnd: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchProductAvailabilityCancel && fetchProductAvailabilityCancel();
    dispatch(fetchProductAvailabilityRequest());
    const params = {
      product_ids: productIds,
      date_from_start: dateFromStart,
      date_from_end: dateFromEnd,
    };
    axios
      .get('/api/products/availability', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchProductAvailabilityCancel = c;
        }),
      })
      .then((result) => dispatch(fetchProductAvailabilitySuccess(result.data)))
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchProductAvailabilityFailure(error));
        }
      });
  };
