import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SettingsTab } from 'client/pages/JaranImport/SettingsTab';
import { ImportTab } from 'client/pages/JaranImport/ImportTab';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type TabType = 'SETTINGS' | 'IMPORT';

export const JaranImport = () => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  const [activeTab, setActiveTab] = React.useState<TabType>('IMPORT');

  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              baseStyles['is-close']
            )}
          ></div>
        </div>
      </div>
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'IMPORT' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('IMPORT');
            }}
          >
            <a>{t('Import')}</a>
          </li>
          {hasCustomUserRoleWritePermissions(
            activeUser,
            'CHANNEL_MANAGER.JARAN_IMPORT'
          ) && (
            <li
              className={clsx(
                activeTab === 'SETTINGS' ? componentStyles['is-active'] : ''
              )}
              onClick={() => {
                setActiveTab('SETTINGS');
              }}
            >
              <a>{t('Settings')}</a>
            </li>
          )}
        </ul>
      </div>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          componentStyles['no-margin-top'],
          componentStyles['is-active']
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <div className={baseStyles['acBody' as keyof typeof baseStyles]}>
            {activeTab === 'IMPORT' && <ImportTab />}
            {activeTab === 'SETTINGS' && <SettingsTab />}
          </div>
        </div>
      </div>
    </>
  );
};
