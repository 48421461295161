import React from 'react';
import { Field } from 'react-final-form';

const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);
type Props = {
  data: {
    editMode: boolean;
    customerName: string;
    customerIntroduction: string;
    introParagraph: string;
  };
};

export const CustomerGreetingsSection = ({ data }: Props) => {
  return (
    <tr style={{ margin: 0, padding: 0 }}>
      <td>
        <div
          style={{
            margin: 0,
            padding: '1.5em',
            backgroundColor: '#fff',
          }}
        >
          <p style={{ marginTop: '0.5em' }}>
            {data.customerName
              ? data.customerIntroduction.replace(
                  '{customerName}',
                  data.customerName
                )
              : data.customerIntroduction}
            <br />
            <br />

            {data.editMode && (
              <Field name="templateValues.introParagraph">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <span style={{ whiteSpace: 'pre-line' }}>
              {data.introParagraph}
            </span>
          </p>
        </div>
      </td>
    </tr>
  );
};
