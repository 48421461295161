import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SALES_OFFERS_SUCCESS,
  FETCH_SALES_OFFERS_FAILURE,
  FETCH_SALES_OFFERS_REQUEST,
  FETCH_SALES_OFFER_SUCCESS,
  FETCH_SALES_OFFER_FAILURE,
  FETCH_SALES_OFFER_REQUEST,
  DELETE_SALES_OFFER_FAILURE,
  DELETE_SALES_OFFER_REQUEST,
  DELETE_SALES_OFFER_SUCCESS,
  CREATE_SALES_OFFER_REQUEST,
  UPDATE_SALES_OFFER_FAILURE,
  UPDATE_SALES_OFFER_SUCCESS,
  UPDATE_SALES_OFFER_REQUEST,
  CREATE_SALES_OFFER_FAILURE,
  CREATE_SALES_OFFER_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { SalesOfferParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSalesOffersRequest = () => ({
  type: FETCH_SALES_OFFERS_REQUEST,
});

const fetchSalesOffersSuccess = (response: any) => ({
  type: FETCH_SALES_OFFERS_SUCCESS,
  response,
});

const fetchSalesOffersFailure = (error: any) => ({
  type: FETCH_SALES_OFFERS_FAILURE,
  error,
});

let fetchSalesOffersCancel: () => void | undefined;
export const fetchSalesOffers =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchSalesOffersCancel?.();
    dispatch(fetchSalesOffersRequest());
    axios
      .get('/api/salesoffers', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchSalesOffersCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchSalesOffersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSalesOffersFailure('canceled'));
        dispatch(fetchSalesOffersFailure(error));
      });
  };

const fetchSalesOfferRequest = () => ({
  type: FETCH_SALES_OFFER_REQUEST,
});

const fetchSalesOfferSuccess = (response: any) => ({
  type: FETCH_SALES_OFFER_SUCCESS,
  response,
});

const fetchSalesOfferFailure = (error: any) => ({
  type: FETCH_SALES_OFFER_FAILURE,
  error,
});

export const fetchSalesOffer =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSalesOfferRequest());
    axios
      .get(`/api/salesoffers/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchSalesOfferSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchSalesOfferFailure('canceled'));
        else dispatch(fetchSalesOfferFailure(error));
      });
  };

const deleteSalesOfferRequest = (id: string) => ({
  type: DELETE_SALES_OFFER_REQUEST,
  id,
});

const deleteSalesOfferSuccess = (response: any, id: string) => ({
  type: DELETE_SALES_OFFER_SUCCESS,
  response,
  id,
});

const deleteSalesOfferFailure = (error: any) => ({
  type: DELETE_SALES_OFFER_FAILURE,
  error,
});

export const deleteSalesOffer =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteSalesOfferRequest(id));
    axios
      .delete(`/api/salesoffers/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteSalesOfferSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteSalesOfferFailure(error)));
  };

const createSalesOfferRequest = (newSalesOffer: SalesOfferParams) => ({
  type: CREATE_SALES_OFFER_REQUEST,
  newSalesOffer,
});

const createSalesOfferSuccess = (response: any) => ({
  type: CREATE_SALES_OFFER_SUCCESS,
  response,
});

const createSalesOfferFailure = (error: any) => ({
  type: CREATE_SALES_OFFER_FAILURE,
  error,
});

export const createSalesOffer =
  (newSalesOffer: SalesOfferParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createSalesOfferRequest(newSalesOffer));
    return axios
      .post('/api/salesoffers', newSalesOffer, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createSalesOfferSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createSalesOfferFailure(err.message));
      });
  };

const updateSalesOfferRequest = (id: string, patch: SalesOfferParams) => ({
  type: UPDATE_SALES_OFFER_REQUEST,
  id,
  patch,
});

const updateSalesOfferSuccess = (response: any) => ({
  type: UPDATE_SALES_OFFER_SUCCESS,
  response,
});

const updateSalesOfferFailure = (error: any) => ({
  type: UPDATE_SALES_OFFER_FAILURE,
  error,
});

export const updateSalesOffer =
  (id: string, patch: SalesOfferParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateSalesOfferRequest(id, patch));
    return axios
      .patch(`/api/salesoffers/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateSalesOfferSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateSalesOfferFailure(err.message));
      });
  };
