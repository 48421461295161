import { Newsletter, NewsletterParams } from 'shared/models/swagger';

export interface FormValues {
  subject: string;
  selectedTemplate: string;
  templateValues: Record<string, any>;
  segments: string[];
}

export const convertFormValuesToSwagger = (
  values: FormValues
): NewsletterParams => {
  return {
    segment_ids: values.segments,
    email_template_name: values.selectedTemplate,
    email_template_values_json: JSON.stringify(values.templateValues),
    email_subject: values.subject,
  };
};

export const convertSwaggerToFormValues = (
  newsletter: Newsletter
): FormValues => {
  return {
    segments: newsletter.segment_ids ?? [],
    selectedTemplate: newsletter.email_template_name ?? '',
    templateValues: newsletter.email_template_values_json
      ? JSON.parse(newsletter.email_template_values_json)
      : {},
    subject: newsletter.email_subject ?? '',
  };
};
