import moment from 'moment-timezone';

import { currency } from 'shared/libraries/currency';
import type { Product, UnitPricing } from 'shared/models/swagger';

export const demoReservationId = '427eb423-0c92-53f6-a354-c06c74a1e116';
export const demoProductInstanceId = '566ecda2-c739-5ca2-8ab5-c4d6e2f08cb7';

export const getDemoProductInstance = (demoProduct: Product) => {
  const units = getDemoProductInstanceUnits(demoProduct);
  const startTime = getStartTime(demoProduct);
  const after30Days = moment().add(30, 'days');
  const bookingDate = moment.tz(
    `${after30Days.format('YYYY-MM-DD')} ${startTime}`,
    demoProduct.start_timezone ?? ''
  );
  return {
    booking_deadlines: [
      {
        confirmation_type: 'REQUEST',
        date_time_utc: '2020-12-31T07:00:00Z',
      },
    ],
    units,
    id: demoProductInstanceId,
    product_id: demoProduct.id,
    start_date_time_utc: bookingDate.clone().tz('UTC').format(),
    end_date_time_utc: bookingDate.clone().tz('UTC').format(),
    start_date_time_local: bookingDate.format(),
    end_date_time_local: bookingDate.format(),
    start_time_local: startTime,
    duration: '3:00',
    start_time_id: '48194755-75b7-50ce-a03f-b80566569a28',
    time_slot_key: '4:15-14:00',
    occupied_slots: 1,
    available_slots: -1,
    per_channel_info: {
      closed_channels: [],
      dedicated_allotments: [],
    },
    last_modified_date_time_utc: '2020-12-20T18:38:46-08:00',
    weighted_participant_count: 1,
  };
};
export const getDemoReservation = (demoProduct: Product) => {
  const productInstance = getDemoProductInstance(demoProduct);
  const unit = productInstance.units[0];
  const gross = currency(unit.gross).multiply(2).format();
  const net = currency(unit.net).multiply(2).format();
  const guestTypeKey = unit?.guest_type?.key || 'Guest';
  const guestTypeTitle = unit?.guest_type?.title || 'Guest';
  return {
    status_history: [
      {
        status: 'PENDING',
        status_date_time_utc: moment().format(),
        until_date_time_utc: moment().format(),
        action_source: {
          entity_type: 'GUEST_ENTITY_TYPE',
          entity_description: `${
            demoProduct.supplier_name || ''
          } Booking Widget`,
        },
      },
      {
        status: 'CONFIRMED',
        status_date_time_utc: moment().format(),
        action_source: {
          entity_type: 'SUPPLIER_ENTITY_TYPE',
          entity_description: `${demoProduct.supplier_name || ''}`,
        },
      },
    ],
    add_ons: [],
    guests: [
      {
        add_ons: [],
        field_responses: [
          {
            key: 'family_name',
            response: 'Cooper',
          },
          {
            key: 'given_name',
            response: 'Bradley',
          },
          {
            key: 'email',
            response: 'bradleycooper@famous.com',
          },
          {
            key: 'preferred_language_iso2',
            response: 'ja-JP',
          },
        ],
        add_on_titles: [],
        guest_type_key: guestTypeKey,
        guest_type_title: guestTypeTitle,
      },
      {
        add_ons: [],
        field_responses: [
          {
            key: 'family_name',
            response: 'Gaga',
          },
          {
            key: 'given_name',
            response: 'Lady',
          },
        ],
        add_on_titles: [],
        guest_type_key: guestTypeKey,
        guest_type_title: guestTypeTitle,
      },
    ],
    field_responses: [
      {
        key: 'Test Question',
        response: 'Test Answer',
      },
      {
        key: 'email',
        response: 'andrewpspencer@gmail.com',
      },
      {
        key: 'preferred_language_iso2',
        response: 'ja-JP',
      },
      {
        key: 'given_name',
        response: 'Andrew',
      },
      {
        key: 'family_name',
        response: 'Spencer',
      },
    ],
    id: demoReservationId,
    product_id: demoProduct.id,
    product_name: demoProduct.product_name,
    supplier_product_reference: demoProduct.supplier_reference,
    product_instance_id: '566ecda2-c739-5ca2-8ab5-c4d6e2f08cb7',
    supplier_id: 'b7b478ac-2a28-5c2b-be5a-0347edb073c7',
    supplier_name: demoProduct.supplier_name,
    supplier_reference: '5C15VCI5',
    agent_reference: 'QRHV31AZ',
    status: 'CONFIRMED',
    booking_source: {
      source_type: 'DIRECT_WEB',
    },
    payment_type: 'PAID_IN_FULL',
    payment_method: 'CREDIT_CARD',
    start_timezone: demoProduct.start_timezone,
    start_date_time_utc: productInstance.start_date_time_utc,
    end_date_time_utc: productInstance.end_date_time_utc,
    last_updated_date_time_utc: '2020-12-21T02:38:46Z',
    billing_info: {
      line_items: [
        {
          title: `${demoProduct.product_name || ''} ${guestTypeKey}`,
          amount_gross: gross,
          amount_net: net,
          count: 2,
          invoice_id: 'beed4479-3640-546d-8dba-b7091396216b',
        },
      ],
      amount_gross: gross,
      amount_net: net,
      amount_pay_in_advance: gross,
      amount_pay_on_board: gross,
      settlement_currency_amount_gross: 'USD221.41',
      settlement_currency_code: 'USD',
      variable_markup_amount: gross,
    },
  };
};

const getDemoProductInstanceUnits = (demoProduct: Product): UnitPricing[] => {
  if (
    demoProduct?.pricing &&
    demoProduct.pricing.length > 0 &&
    demoProduct.pricing[0].units
  ) {
    return demoProduct.pricing[0].units;
  }

  return [];
};

const getStartTime = (demoProduct: Product): string => {
  if (
    demoProduct?.recurrence &&
    demoProduct.recurrence.length > 0 &&
    demoProduct.recurrence[0].start_times &&
    demoProduct.recurrence[0].start_times.length > 0 &&
    demoProduct.recurrence[0].start_times[0].start_time_local
  ) {
    return demoProduct.recurrence[0].start_times[0].start_time_local;
  }

  return '9:00';
};
