import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateActiveUserOrganization } from 'client/actions/organizations';
import { Button, Select, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';

import styles from './MediaDownloadSettings.module.css';

interface FormValues {
  validDaysAfterParticipation: number;
  shouldSendAutomaticSalesOffers: boolean;
}

export const MediaDownloadSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  // Expiration days is the number of days after participation date that a media download offer is available
  const initialExpirationDays =
    activeUserOrganization?.media_download_settings
      ?.offer_valid_days_after_participation ?? 30;
  const initialShouldSendAutomaticSalesOffers =
    !activeUserOrganization?.media_download_settings
      ?.disable_automatic_sales_offer_emails;

  return (
    <div className={styles['container-panel']}>
      <Form
        initialValues={{
          validDaysAfterParticipation: initialExpirationDays,
          shouldSendAutomaticSalesOffers: initialShouldSendAutomaticSalesOffers,
        }}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateActiveUserOrganization({
              media_download_settings: {
                offer_valid_days_after_participation:
                  values.validDaysAfterParticipation,
                disable_automatic_sales_offer_emails:
                  !values.shouldSendAutomaticSalesOffers,
              },
            })
          );
        }}
        debug={console.log}
      >
        {({ handleSubmit, submitting, submitSucceeded }) => (
          <form onSubmit={handleSubmit}>
            {submitSucceeded && (
              <Message success header={t('Settings saved successfully')} />
            )}
            <FormTableBox>
              <table>
                <tbody>
                  <tr>
                    <th>{t('Expiration')}</th>
                    <td>
                      <Field name="validDaysAfterParticipation">
                        {({ input }) => (
                          <Select
                            label={t('Photo/video download offers valid until')}
                            search
                            value={`${input.value}`}
                            onChange={(e, { value }) => {
                              input.onChange(parseInt(value));
                            }}
                            options={_.times(45, (i) => i + 1).map((day) => ({
                              key: `${day}`,
                              text: t('{{count}} days after participation', {
                                count: day,
                              }),
                              value: `${day}`,
                            }))}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Guest Sales Offers')}</th>
                    <td>
                      <Field name="shouldSendAutomaticSalesOffers">
                        {({ input }) => (
                          <ToggleButton
                            label={t(
                              'Automatically send sales offer emails when a sales page is published'
                            )}
                            checked={Boolean(input.value)}
                            onChange={() => {
                              input.onChange(!input.value);
                            }}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  style="blue"
                  size="middle"
                  loading={submitting}
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </div>
            </FormTableBox>
          </form>
        )}
      </Form>
    </div>
  );
};
