import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { buildImpersonateToken } from 'shared/libraries/cognito';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type { Account, Customer } from 'shared/models/swagger';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());

export const useCustomers = ({
  customerIds,
}: {
  customerIds: string[];
}): {
  customers: Customer[] | null;
  isLoading: boolean;
  error: string;
} => {
  const { i18n } = useTranslation();
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const impersonatedAccount = useSelector(impersonatedAccountSelector);

  const params = new URLSearchParams();
  for (const customerId of customerIds) {
    params.append('ids', customerId);
  }
  const { data, error } = useSWR(
    [
      `/api/customers/batchget?${params.toString()}`,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );
  return {
    customers: data?.customers || null,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
