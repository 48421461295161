import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';

import styles from './CalendarMonthSelect.module.css';

interface Props {
  onChangeMonth: (m: string) => void;
  month: string;
}

export const CalendarMonthSelect = ({ onChangeMonth, month }: Props) => {
  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div className={styles['container']}>
      <div
        className={styles['button']}
        onClick={() => {
          onChangeMonth(moment(month).subtract(1, 'month').format('YYYY-MM'));
        }}
      >
        <i className="c-icon-solid-arrows-chevron-left"></i>
      </div>
      <div className={styles['title']}>
        {moment(month).locale(locale).format(monthYearFormat)}
      </div>
      <div
        className={styles['button']}
        onClick={() => {
          onChangeMonth(moment(month).add(1, 'month').format('YYYY-MM'));
        }}
      >
        <i className="c-icon-solid-arrows-chevron-right"></i>
      </div>
    </div>
  );
};
