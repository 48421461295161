import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAILURE,
  FETCH_CONTRACTS_REQUEST,
  DELETE_CONTRACT_FAILURE,
  DELETE_CONTRACT_REQUEST,
  DELETE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_REQUEST,
  UPDATE_CONTRACT_FAILURE,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_FAILURE,
  CREATE_CONTRACT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { Contract$Patch, Contract$Input } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchContractsRequest = () => ({
  type: FETCH_CONTRACTS_REQUEST,
});

const fetchContractsSuccess = (response: any) => ({
  type: FETCH_CONTRACTS_SUCCESS,
  response,
});

const fetchContractsFailure = (error: string) => ({
  type: FETCH_CONTRACTS_FAILURE,
  error,
});

let fetchContractsCancel: (() => void) | null = null;
export const fetchContracts =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchContractsCancel?.();
    dispatch(fetchContractsRequest());
    axios
      .get('/api/contracts', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchContractsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchContractsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchContractsFailure('canceled'));
        else dispatch(fetchContractsFailure(error));
      });
  };

const deleteContractRequest = (id: string) => ({
  type: DELETE_CONTRACT_REQUEST,
  id,
});

const deleteContractSuccess = (response: any, id: string) => ({
  type: DELETE_CONTRACT_SUCCESS,
  response,
  id,
});

const deleteContractFailure = (error: string) => ({
  type: DELETE_CONTRACT_FAILURE,
  error,
});

export const deleteContract =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteContractRequest(id));
    fetch(`/api/contracts/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteContractSuccess(result, id)))
      .catch((error) => dispatch(deleteContractFailure(error)));
  };

const createContractRequest = (newContract: Contract$Input) => ({
  type: CREATE_CONTRACT_REQUEST,
  newContract,
});

const createContractSuccess = (response: any) => ({
  type: CREATE_CONTRACT_SUCCESS,
  response,
});

const createContractFailure = (error: string) => ({
  type: CREATE_CONTRACT_FAILURE,
  error,
});

export const createContract =
  (newContract: Contract$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createContractRequest(newContract));
    return fetch('/api/contracts', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newContract),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(createContractSuccess(result)))
      .catch((error) => dispatch(createContractFailure(error)));
  };

const updateContractRequest = (id: string, patch: Contract$Patch) => ({
  type: UPDATE_CONTRACT_REQUEST,
  id,
  patch,
});

const updateContractSuccess = (response: any) => ({
  type: UPDATE_CONTRACT_SUCCESS,
  response,
});

const updateContractFailure = (error: string) => ({
  type: UPDATE_CONTRACT_FAILURE,
  error,
});

export const updateContract =
  (id: string, patch: Contract$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateContractRequest(id, patch));
    fetch(`/api/contracts/${id}`, {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(patch),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(updateContractSuccess(result)))
      .catch((error) => dispatch(updateContractFailure(error)));
  };
