import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import baseStyles from 'client/v3-base.module.css';
import useScroll from 'client/hooks/useScroll';

import styles from './SpMenu.module.css';

type MenuProps = {
  sections: {
    title: string;
    id: string;
  }[];
};

export const SpMenu = ({ sections }: MenuProps) => {
  const ulListRef = useRef<HTMLUListElement | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(
    'product-info'
  );
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isScrolled = useScroll();

  const handleMenuItemClick = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
    setShowMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !ulListRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div
      className={clsx(
        styles['p-reservationsCreateDetail__spMenu'],
        baseStyles['u-pcHidden'],
        isScrolled ? styles['is-scrolled'] : ''
      )}
    >
      <div className={styles['p-reservationsCreateDetail__spMenu__inner']}>
        <fieldset
          className={clsx(styles['c-select'], showMenu && styles['is-active'])}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className={styles['c-select__body']}>
            <label>
              <div className={styles['c-select__body__selected']}>
                <p>
                  {
                    sections.find((section) => section.id === activeSection)
                      ?.title
                  }
                </p>
              </div>
            </label>
          </div>
        </fieldset>
        <ul
          ref={ulListRef}
          className={clsx(
            styles['c-select__menu'],
            showMenu ? styles['is-active'] : ''
          )}
        >
          {sections.map((section, index) => {
            return (
              <li
                key={index}
                className={clsx(
                  activeSection === section.id ? styles['is-active'] : '',
                  styles['c-select__menu__item']
                )}
                onClick={() => {
                  handleMenuItemClick(section.id);
                }}
              >
                <input
                  type="radio"
                  name="select"
                  checked={activeSection === section.id}
                  onChange={() => {
                    handleMenuItemClick(section.id);
                  }}
                />
                <i></i>
                <p>{section.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
