import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button } from 'client/components/v3/Common/Button';
import { Loading } from 'client/components/v3/Common/Loading';
import { Customer, Reservation } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { CustomerReservationData, DateRange } from '../type';

import { Gadget } from './Gadget';
import styles from './AnnualPassStageGadget.module.css';

enum Status {
  NOT_PURCHASED_ANNUAL_PASS = 'NOT_PURCHASED_ANNUAL_PASS',
  FIRST_YEAR_ANNUAL_PASS = 'FIRST_YEAR_ANNUAL_PASS',
  SECOND_YEAR_ABOVE_ANNUAL_PASS = 'SECOND_YEAR_ABOVE_ANNUAL_PASS',
  CANCELLED_ANNUAL_PASS = 'CANCELLED_ANNUAL_PASS',
}

type TableData = {
  customerId: string;
  customer: Customer;
  reservations: Reservation[];
  status: Status;
  visitsByDate: { date: string; visitCount: number }[];
  totalVisitsWithinDateRange: number;
};

type RowData = {
  numberOfCustomers: number;
  percentage: string;
};

const getTotalNumberOfCustomers = (
  data: TableData[],
  status: Status
): number => {
  return data.filter((entry) => entry.status === status).length;
};

const getNumberOfCustomersByVisitFrequency = (
  data: TableData[],
  numberOfVisits: number,
  status: Status
): number => {
  return data.filter((entry) => {
    return (
      entry.totalVisitsWithinDateRange === numberOfVisits &&
      entry.status === status
    );
  }).length;
};

const calculatePercentage = (count: number, total: number): string => {
  if (count === 0 && total === 0) {
    return `0%`;
  }
  const percentage = (count / total) * 100;
  const formattedPercentage = percentage.toFixed(2).replace(/\.?0+$/, '');
  return `${formattedPercentage}%`;
};

type CellColor = {
  bgColor: string;
  textColor: string;
  comparisonTextColor: string;
};

const getCellColor = (numCustomers: number): CellColor => {
  if (numCustomers >= 0 && numCustomers <= 20) {
    return {
      bgColor: '#F4F4F5',
      textColor: '#18181B',
      comparisonTextColor: '#A1A1AA',
    };
  } else if (numCustomers >= 21 && numCustomers <= 40) {
    return {
      bgColor: '#EFF6FF',
      textColor: '#18181B',
      comparisonTextColor: '#A1A1AA',
    };
  } else if (numCustomers >= 41 && numCustomers <= 60) {
    return {
      bgColor: '#DBEAFE',
      textColor: '#18181B',
      comparisonTextColor: '#A1A1AA',
    };
  } else if (numCustomers >= 61 && numCustomers <= 80) {
    return {
      bgColor: '#93C5FD',
      textColor: '#18181B',
      comparisonTextColor: '#A1A1AA',
    };
  } else {
    return {
      bgColor: '#3B82F6',
      textColor: '#18181B',
      comparisonTextColor: '#A1A1AA',
    };
  }
};

type Props = {
  data: CustomerReservationData[] | null;
  dateRange: DateRange;
  comparisonDateRange: DateRange | null;
};

// Iterate through reservations to find footprint info to calculate total visit per date for a customer
export const getCustomerVisitsByDate = (
  reservations: Reservation[]
): { date: string; visitCount: number }[] => {
  const visitsAccumulator: { [key: string]: number } = reservations.reduce(
    (acc, reservation) => {
      if (reservation.footprint_info?.visits_per_date) {
        reservation.footprint_info.visits_per_date.forEach((visit) => {
          if (visit.date) {
            // Count the visit for the date as 1 regardless of number of visits
            if (!acc[visit.date]) {
              acc[visit.date] = 1;
            }

            // Even if footprint record is there, we need to check checkin info for data integrity (for example E-ticket records are deleted manually)
            // Remove the count if applicable
            if (
              reservation.checkin_info?.checkin_status !== 'CHECKED_IN' &&
              acc[visit.date] > 0
            ) {
              acc[visit.date] = 0; // Reset to 0 if not checked in
            }
          }
        });
      }
      return acc;
    },
    {} as { [key: string]: number }
  );

  const visitsByDateArray: { date: string; visitCount: number }[] = Object.keys(
    visitsAccumulator
  ).map((date) => ({
    date,
    visitCount: visitsAccumulator[date],
  }));

  return visitsByDateArray;
};

const categorizeDataByNumberOfAnnualPassPurchases = (
  data: CustomerReservationData[] | null,
  timezone: string
): TableData[] | null => {
  if (!data) {
    return null;
  }

  return data
    .map(({ customer, reservations }) => {
      // Customer without any reservation
      const noReservation = reservations.length === 0;

      // Filter all CONFIRMED reservations
      const confirmedReservations = reservations.filter(
        (r) => r.status === 'CONFIRMED'
      );

      // Filter only CONFIRMED annual passes
      const confirmedAnnualPasses = confirmedReservations.filter(
        (r) => r.is_annual_pass_mode
      );
      const noAnnualPass = confirmedAnnualPasses.length === 0;

      // Check if the customer has annual passes but no auto renewal to indicate that they are in 1st year of owning annual pass
      const firstYearAnnualPass = confirmedAnnualPasses.some(
        (r) => !r.annual_pass_mode_info
      );
      // If there are at least one annual pass automatic continuing reservation, we can consider it as second year and onwards
      const secondYearAboveAnnualPass =
        confirmedAnnualPasses.filter(
          (r) => r.annual_pass_mode_info?.continuing_reservation_is_created
        ).length >= 1;
      // Canceled
      // All annual passes which have expiration date and no auto renewal
      const canceledAnnualPass = confirmedAnnualPasses.every((r) => {
        // If checkin info has no expiration date, annual pass is not used yet, treat as valid annual pass
        if (!r.checkin_info?.expiration_date_time_utc) {
          return false;
        }

        // If e-ticket expiration date time is after current time, treat as valid annual pass
        const expirationDateTimeLocal = moment
          .utc(r.checkin_info?.expiration_date_time_utc)
          .tz(timezone);
        const currentDateTimeLocal = moment().tz(timezone);
        if (expirationDateTimeLocal.isAfter(currentDateTimeLocal)) {
          return false;
        }

        return !r.should_auto_renew_annual_pass;
      });

      let status: Status = Status.NOT_PURCHASED_ANNUAL_PASS;

      // Get visits by date for all CONFIRMED reservations of a customer
      const visitsByDate = getCustomerVisitsByDate(confirmedReservations);

      // Assign status to data
      // Customers with 0 reservations
      if (noReservation) {
        status = Status.NOT_PURCHASED_ANNUAL_PASS;
      } else {
        // Customers with reservations but no annual passes
        if (noAnnualPass) {
          status = Status.NOT_PURCHASED_ANNUAL_PASS;
        } else {
          // Customers with no annual passes
          if (canceledAnnualPass) {
            status = Status.CANCELLED_ANNUAL_PASS;
          } else if (secondYearAboveAnnualPass) {
            status = Status.SECOND_YEAR_ABOVE_ANNUAL_PASS;
          } else if (firstYearAnnualPass) {
            status = Status.FIRST_YEAR_ANNUAL_PASS;
          }
        }
      }

      return {
        customerId: customer.id,
        customer,
        reservations: confirmedReservations,
        status,
        visitsByDate,
        totalVisitsWithinDateRange: 0, // Initialize as 0
      };
    })
    .filter((data): data is TableData => data !== null);
};

// Count visit frequencies for all customers
const countVisitFrequencies = (
  data: TableData[]
): { [key: number]: number } => {
  const visitFrequencies: { [key: number]: number } = {};
  data.forEach((entry) => {
    const totalVisits = entry.totalVisitsWithinDateRange;
    if (!visitFrequencies[totalVisits]) {
      visitFrequencies[totalVisits] = 0;
    }
    visitFrequencies[totalVisits]++;
  });

  return visitFrequencies;
};

const getBreakdownRows = (data: TableData[], grandTotal: number) => {
  const keys = getKeysForTwoOrMoreVisitFrequencies(data);

  return keys.flatMap((key) => [
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        key,
        Status.NOT_PURCHASED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          key,
          Status.NOT_PURCHASED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        key,
        Status.FIRST_YEAR_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          key,
          Status.FIRST_YEAR_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        key,
        Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          key,
          Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        key,
        Status.CANCELLED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          key,
          Status.CANCELLED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
  ]);
};

const getKeysForTwoOrMoreVisitFrequencies = (data: TableData[]): number[] => {
  const visitCounts = new Set<number>();
  data.forEach((item) => {
    if (item.totalVisitsWithinDateRange >= 2) {
      visitCounts.add(item.totalVisitsWithinDateRange);
    }
  });
  return Array.from(visitCounts).sort((a, b) => a - b);
};

// Categorize data by calculating the total number of visits for each customer within the date range
const categorizeDataByVisitFrequency = (
  data: TableData[],
  dateRange: DateRange,
  timezone: string
) => {
  const { start, end } = dateRange;
  const startDate = moment(start, 'YYYY-MM-DD').tz(timezone);
  const endDate = moment(end, 'YYYY-MM-DD').tz(timezone);

  const dataWithTotalVisits = data.map((item) => {
    const filteredVisitsByDate = item.visitsByDate.filter((visit) => {
      const visitDate = moment(visit.date, 'YYYY-MM-DD').tz(timezone);
      return (
        visitDate.isSameOrAfter(startDate) && visitDate.isSameOrBefore(endDate)
      );
    });

    const totalVisitsWithinDateRange = filteredVisitsByDate.reduce(
      (sum, visit) => sum + visit.visitCount,
      0
    );

    return {
      ...item,
      totalVisitsWithinDateRange,
    };
  });

  return dataWithTotalVisits;
};

const getDataAsRows = (data: TableData[] | null) => {
  if (!data) {
    return {
      rows: [],
      breakdownRows: [],
      totals: [],
    };
  }
  // Calculate cumulative number of customers with visit frequencies 2 and above
  const cumulativeCustomersForTwoVisitsAndAbove: { [key in Status]: number } = {
    [Status.NOT_PURCHASED_ANNUAL_PASS]: 0,
    [Status.FIRST_YEAR_ANNUAL_PASS]: 0,
    [Status.SECOND_YEAR_ABOVE_ANNUAL_PASS]: 0,
    [Status.CANCELLED_ANNUAL_PASS]: 0,
  };
  data.forEach((item) => {
    if (item.totalVisitsWithinDateRange >= 2) {
      cumulativeCustomersForTwoVisitsAndAbove[item.status]++;
    }
  });

  // Calculate total number of customers for each status
  const totalNotPurchased = getTotalNumberOfCustomers(
    data,
    Status.NOT_PURCHASED_ANNUAL_PASS
  );
  const totalFirstYear = getTotalNumberOfCustomers(
    data,
    Status.FIRST_YEAR_ANNUAL_PASS
  );
  const totalSecondYearAbove = getTotalNumberOfCustomers(
    data,
    Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
  );
  const totalCancelled = getTotalNumberOfCustomers(
    data,
    Status.CANCELLED_ANNUAL_PASS
  );

  const grandTotal =
    totalNotPurchased + totalFirstYear + totalSecondYearAbove + totalCancelled;

  const rows: RowData[] = [
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        0,
        Status.NOT_PURCHASED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          0,
          Status.NOT_PURCHASED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        0,
        Status.FIRST_YEAR_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          0,
          Status.FIRST_YEAR_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        0,
        Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          0,
          Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        0,
        Status.CANCELLED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          0,
          Status.CANCELLED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        1,
        Status.NOT_PURCHASED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          1,
          Status.NOT_PURCHASED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        1,
        Status.FIRST_YEAR_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          1,
          Status.FIRST_YEAR_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        1,
        Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          1,
          Status.SECOND_YEAR_ABOVE_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers: getNumberOfCustomersByVisitFrequency(
        data,
        1,
        Status.CANCELLED_ANNUAL_PASS
      ),
      percentage: calculatePercentage(
        getNumberOfCustomersByVisitFrequency(
          data,
          1,
          Status.CANCELLED_ANNUAL_PASS
        ),
        grandTotal
      ),
    },
    {
      numberOfCustomers:
        cumulativeCustomersForTwoVisitsAndAbove.NOT_PURCHASED_ANNUAL_PASS,
      percentage: calculatePercentage(
        cumulativeCustomersForTwoVisitsAndAbove.NOT_PURCHASED_ANNUAL_PASS,
        grandTotal
      ),
    },
    {
      numberOfCustomers:
        cumulativeCustomersForTwoVisitsAndAbove.FIRST_YEAR_ANNUAL_PASS,
      percentage: calculatePercentage(
        cumulativeCustomersForTwoVisitsAndAbove.FIRST_YEAR_ANNUAL_PASS,
        grandTotal
      ),
    },
    {
      numberOfCustomers:
        cumulativeCustomersForTwoVisitsAndAbove.SECOND_YEAR_ABOVE_ANNUAL_PASS,
      percentage: calculatePercentage(
        cumulativeCustomersForTwoVisitsAndAbove.SECOND_YEAR_ABOVE_ANNUAL_PASS,
        grandTotal
      ),
    },
    {
      numberOfCustomers:
        cumulativeCustomersForTwoVisitsAndAbove.CANCELLED_ANNUAL_PASS,
      percentage: calculatePercentage(
        cumulativeCustomersForTwoVisitsAndAbove.CANCELLED_ANNUAL_PASS,
        grandTotal
      ),
    },
  ];

  const breakdownRows = getBreakdownRows(data, grandTotal);

  const totals: RowData[] = [
    {
      numberOfCustomers: totalNotPurchased,
      percentage: calculatePercentage(totalNotPurchased, grandTotal),
    },
    {
      numberOfCustomers: totalFirstYear,
      percentage: calculatePercentage(totalFirstYear, grandTotal),
    },
    {
      numberOfCustomers: totalSecondYearAbove,
      percentage: calculatePercentage(totalSecondYearAbove, grandTotal),
    },
    {
      numberOfCustomers: totalCancelled,
      percentage: calculatePercentage(totalCancelled, grandTotal),
    },
  ];

  return { rows, breakdownRows, totals };
};

export const AnnualPassStageGadget = ({
  data,
  dateRange,
  comparisonDateRange,
}: Props) => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector(
    (state: ReduxState) => state.language.selected
  );
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const defaultTimezone = activeOrganization?.default_timezone;
  const [showBreakdown, setShowBreakdown] = useState<boolean>(false);

  const toggleBreakdown = () => {
    setShowBreakdown(!showBreakdown);
  };

  const dataByNumberOfAnnualPassPurchases =
    categorizeDataByNumberOfAnnualPassPurchases(data, defaultTimezone ?? '');
  const comparisonDataByNumberOfAnnualPassPurchases = comparisonDateRange
    ? categorizeDataByNumberOfAnnualPassPurchases(data, defaultTimezone ?? '')
    : null;

  if (!dataByNumberOfAnnualPassPurchases) {
    return (
      <Gadget
        title={t('Annual Pass Purchase Stage')}
        tooltipText={t(
          'Display the distribution of people by number of purchases and visit frequency'
        )}
        positionTooltipToTheLeft={true}
      >
        <Loading size="md" />
      </Gadget>
    );
  }

  const dataByVisitFrequency = categorizeDataByVisitFrequency(
    dataByNumberOfAnnualPassPurchases,
    dateRange,
    defaultTimezone ?? ''
  );
  const comparisonDataByVisitFrequency =
    comparisonDataByNumberOfAnnualPassPurchases && comparisonDateRange
      ? categorizeDataByVisitFrequency(
          comparisonDataByNumberOfAnnualPassPurchases,
          comparisonDateRange,
          defaultTimezone ?? ''
        )
      : null;

  const numberOfVisitFrequencies = Object.keys(
    countVisitFrequencies(dataByVisitFrequency)
  ).map(Number);
  const numberOfVisitFrequenciesTwoAndAbove = numberOfVisitFrequencies.filter(
    (number) => number !== 0 && number !== 1
  ).length;

  const dataAsRows = getDataAsRows(dataByVisitFrequency);
  const comparisonDataAsRows = getDataAsRows(comparisonDataByVisitFrequency);

  const initialRows = {
    data: dataAsRows.rows,
    comparisonData:
      comparisonDataAsRows.rows.length > 0 ? comparisonDataAsRows.rows : null,
  };
  // Set as null when there are no data to display
  const breakdownRows = {
    data: dataAsRows.breakdownRows.length > 0 ? dataAsRows.breakdownRows : null,
    comparisonData:
      comparisonDataAsRows && comparisonDataAsRows.breakdownRows.length > 0
        ? comparisonDataAsRows.breakdownRows
        : // If comparisonData's breakdownRows is empty but data is not, create empty array to match
        dataAsRows.breakdownRows.length > 0
        ? dataAsRows.breakdownRows.map(() => {
            return {
              numberOfCustomers: 0,
              percentage: '0%',
            };
          })
        : null,
  };
  const totals = {
    data: dataAsRows.totals,
  };

  const NUMBER_OF_TOTAL_ROWS = 5;
  const NUMBER_OF_LEFT_SUBHEADER_ROWS = 3;

  return (
    <Gadget
      title={t('Annual Pass Purchase Stage')}
      tooltipText={t(
        'Display the distribution of people by number of purchases and visit frequency'
      )}
      positionTooltipToTheLeft={true}
    >
      <div className={styles['c-annualPassStage']}>
        <div className={styles['c-annualPassStage__content__container']}>
          <div
            className={styles['c-annualPassStage__content']}
            style={{
              gridTemplateRows: `repeat(${
                showBreakdown
                  ? numberOfVisitFrequenciesTwoAndAbove
                  : NUMBER_OF_TOTAL_ROWS
              }, auto)`,
            }}
          >
            <div></div>
            <div
              className={clsx(
                styles['c-annualPassStage__content__header'],
                styles['topHeaderColSpan'],
                styles['top']
              )}
            >
              <div className={styles['topHeader']}>
                {t('Number of purchases')}
              </div>
              <div className={styles['topSubheader']}>
                <div>{t('Not purchased')}</div>
                <div>{t('First time')}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('More than 2 years<br>(Automatic renewal)'),
                  }}
                />
                <div>{t('Pass canceled')}</div>
              </div>
            </div>
            <div
              className={clsx(
                styles['c-annualPassStage__content__header'],
                styles['left']
              )}
              style={{
                gridRow: `span ${
                  // We always want to show 3 rows (0 times, 1 time, 2 times and above + its breakdowns)
                  showBreakdown && numberOfVisitFrequenciesTwoAndAbove > 0
                    ? NUMBER_OF_LEFT_SUBHEADER_ROWS +
                      numberOfVisitFrequenciesTwoAndAbove
                    : NUMBER_OF_LEFT_SUBHEADER_ROWS
                }`,
              }}
            >
              <div
                className={clsx(styles['leftHeader'], styles['verticalText'])}
                style={{
                  textOrientation:
                    selectedLanguage.iso === 'en' ? 'mixed' : undefined,
                }}
              >
                {t('Visit frequency')}
              </div>
              <div
                className={styles['leftSubheader']}
                style={{
                  gridTemplateRows: `repeat(${
                    showBreakdown
                      ? numberOfVisitFrequenciesTwoAndAbove +
                        NUMBER_OF_LEFT_SUBHEADER_ROWS
                      : NUMBER_OF_LEFT_SUBHEADER_ROWS
                  }, 1fr)`,
                }}
              >
                <div>
                  <p style={{ color: '#71717A' }}>
                    {t('{{count}} time', { count: 0 })}
                  </p>
                </div>
                <div>
                  <p style={{ color: '#71717A' }}>
                    {t('{{count}} time', { count: 1 })}
                  </p>
                </div>
                <div>
                  <p style={{ color: '#71717A' }}>
                    {t('{{count}} time_and_above (total)', { count: 2 })}
                  </p>
                  {/* If number of visit frequencies is either 0 or 1, do not show button */}
                  {numberOfVisitFrequenciesTwoAndAbove > 0 && (
                    <Button
                      text={t('Breakdown')}
                      color="white"
                      iconBeforeText={
                        showBreakdown ? (
                          <i className="c-icon-outline-general-minus"></i>
                        ) : (
                          <i className="c-icon-outline-general-plus" />
                        )
                      }
                      onClick={() => {
                        toggleBreakdown();
                      }}
                      style={{ marginTop: '4px' }}
                    />
                  )}
                </div>
                {showBreakdown &&
                  getKeysForTwoOrMoreVisitFrequencies(dataByVisitFrequency).map(
                    (count, index) => (
                      <div
                        key={index}
                        className={styles['leftSubheaderBreakdown']}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '8px',
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.66667 2C3.03486 2 3.33333 2.29848 3.33333 2.66667V3.6C3.33333 4.73111 3.33385 5.53934 3.38562 6.1729C3.43674 6.79869 3.53453 7.19512 3.69665 7.5133C4.01622 8.14051 4.52616 8.65044 5.15337 8.97002C5.47154 9.13214 5.86798 9.22992 6.49376 9.28105C7.12733 9.33282 7.93556 9.33333 9.06667 9.33333H11.7239L9.5286 7.13807C9.26825 6.87772 9.26825 6.45561 9.5286 6.19526C9.78895 5.93491 10.2111 5.93491 10.4714 6.19526L13.8047 9.5286C14.0651 9.78895 14.0651 10.2111 13.8047 10.4714L10.4714 13.8047C10.2111 14.0651 9.78895 14.0651 9.5286 13.8047C9.26825 13.5444 9.26825 13.1223 9.5286 12.8619L11.7239 10.6667H9.03708C7.94204 10.6667 7.0794 10.6667 6.38519 10.61C5.67695 10.5521 5.08551 10.4319 4.54804 10.158C3.66995 9.71062 2.95605 8.99671 2.50864 8.11862C2.23478 7.58115 2.11458 6.98972 2.05671 6.28148C1.99999 5.58727 2 4.72464 2 3.62961L2 2.66667C2 2.29848 2.29848 2 2.66667 2Z"
                            fill="#A1A1AA"
                          />
                        </svg>

                        <p style={{ color: '#71717A' }}>
                          {t('{{count}} time', {
                            count: count,
                          })}
                        </p>
                      </div>
                    )
                  )}
              </div>
            </div>
            {initialRows.data.map((row, index) => (
              <>
                <div
                  key={index}
                  className={clsx(
                    styles['c-annualPassStage__content__item'],
                    styles['content']
                  )}
                  style={{
                    backgroundColor: getCellColor(row.numberOfCustomers)
                      .bgColor,
                    color: getCellColor(row.numberOfCustomers).textColor,
                  }}
                >
                  <p>
                    {t('{{count}} person', { count: row.numberOfCustomers })} (
                    {row.percentage})
                  </p>
                  {initialRows.comparisonData && (
                    <p
                      className={styles['comparisonData']}
                      style={{
                        color: getCellColor(row.numberOfCustomers)
                          .comparisonTextColor,
                      }}
                    >
                      {t('{{count}} person', {
                        count:
                          initialRows.comparisonData[index].numberOfCustomers,
                      })}{' '}
                      ({initialRows.comparisonData[index].percentage})
                    </p>
                  )}
                </div>
              </>
            ))}
            {showBreakdown &&
              breakdownRows.data &&
              breakdownRows.data.map((row, index) => (
                <div
                  key={index}
                  className={clsx(
                    styles['c-annualPassStage__content__item'],
                    styles['content']
                  )}
                  style={{
                    backgroundColor: getCellColor(row.numberOfCustomers)
                      .bgColor,
                    color: getCellColor(row.numberOfCustomers).textColor,
                  }}
                >
                  <p>
                    {t('{{count}} person', { count: row.numberOfCustomers })} (
                    {row.percentage})
                  </p>
                  {/* Show breakdown rows for comparison data if comparison date range is set */}
                  {comparisonDateRange && breakdownRows.comparisonData && (
                    <p
                      className={styles['comparisonData']}
                      style={{
                        color: getCellColor(row.numberOfCustomers)
                          .comparisonTextColor,
                      }}
                    >
                      {t('{{count}} person', {
                        count:
                          breakdownRows.comparisonData[index].numberOfCustomers,
                      })}{' '}
                      ({breakdownRows.comparisonData[index].percentage})
                    </p>
                  )}
                </div>
              ))}
            <div
              className={clsx(
                styles['c-annualPassStage__content__item'],
                styles['total'],
                styles['content'],
                styles['totalHeader']
              )}
            >
              {t('Total')}
            </div>
            {totals.data.map((row, index) => (
              <div
                key={index}
                className={clsx(
                  styles['c-annualPassStage__content__item'],
                  styles['total'],
                  styles['content']
                )}
                style={{
                  backgroundColor: getCellColor(row.numberOfCustomers).bgColor,
                  color: getCellColor(row.numberOfCustomers).textColor,
                }}
              >
                <p>
                  {t('{{count}} person', { count: row.numberOfCustomers })} (
                  {row.percentage})
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['c-annualPassStage__legend']}>
          <div>
            <i
              className={styles['p-tagSettingsModal__tagColor__icon']}
              style={{
                backgroundColor: getCellColor(0).bgColor,
              }}
            ></i>
            <p>
              {t('{{startCount}}~{{endCount}} persons', {
                startCount: 0,
                endCount: 20,
              })}
            </p>
          </div>
          <div>
            <i
              className={styles['p-tagSettingsModal__tagColor__icon']}
              style={{
                backgroundColor: getCellColor(21).bgColor,
              }}
            ></i>
            <p>
              {t('{{startCount}}~{{endCount}} persons', {
                startCount: 21,
                endCount: 40,
              })}
            </p>
          </div>
          <div>
            <i
              className={styles['p-tagSettingsModal__tagColor__icon']}
              style={{
                backgroundColor: getCellColor(41).bgColor,
              }}
            ></i>
            <p>
              {t('{{startCount}}~{{endCount}} persons', {
                startCount: 41,
                endCount: 60,
              })}
            </p>
          </div>
          <div>
            <i
              className={styles['p-tagSettingsModal__tagColor__icon']}
              style={{
                backgroundColor: getCellColor(61).bgColor,
              }}
            ></i>
            <p>
              {t('{{startCount}}~{{endCount}} persons', {
                startCount: 61,
                endCount: 80,
              })}
            </p>
          </div>
          <div>
            <i
              className={styles['p-tagSettingsModal__tagColor__icon']}
              style={{
                backgroundColor: getCellColor(81).bgColor,
              }}
            ></i>
            <p>
              {t('{{count}} persons ~', {
                count: 81,
              })}
            </p>
          </div>
        </div>
      </div>
    </Gadget>
  );
};
