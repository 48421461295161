import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';

interface Props {
  children: React.ReactNode;
}

export const WithGoogleMapsScript = ({ children }: Props) => {
  const { isLoaded } = useGoogleMapsApi();

  return <>{isLoaded ? children : <div style={{ height: '100%' }} />}</>;
};
