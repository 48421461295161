import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS,
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE,
  FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  NewMediaDownloadSalesPage,
  MediaDownloadSalesPage,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMediaDownloadSalesPagesRequest = () => ({
  type: FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST,
});

const fetchMediaDownloadSalesPagesSuccess = (response: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS,
  response,
});

const fetchMediaDownloadSalesPagesFailure = (error: any) => ({
  type: FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE,
  error,
});

export const fetchMediaDownloadSalesPages =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMediaDownloadSalesPagesRequest());
    axios
      .get('/api/mediadownloadsalespages', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMediaDownloadSalesPagesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMediaDownloadSalesPagesFailure('canceled'));
        else dispatch(fetchMediaDownloadSalesPagesFailure(error));
      });
  };

const deleteMediaDownloadSalesPageRequest = (id: string) => ({
  type: DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  id,
});

const deleteMediaDownloadSalesPageSuccess = (response: any, id: string) => ({
  type: DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  response,
  id,
});

const deleteMediaDownloadSalesPageFailure = (error: any) => ({
  type: DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  error,
});

export const deleteMediaDownloadSalesPage =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMediaDownloadSalesPageRequest(id));
    axios
      .delete(`/api/mediadownloadsalespages/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(deleteMediaDownloadSalesPageSuccess(result.data, id))
      )
      .catch((error) => dispatch(deleteMediaDownloadSalesPageFailure(error)));
  };

const createMediaDownloadSalesPageRequest = (
  newMediaDownloadSalesPage: NewMediaDownloadSalesPage
) => ({
  type: CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  newMediaDownloadSalesPage,
});

const createMediaDownloadSalesPageSuccess = (response: any) => ({
  type: CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  response,
});

const createMediaDownloadSalesPageFailure = (error: any) => ({
  type: CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  error,
});

export const createMediaDownloadSalesPage =
  (newMediaDownloadSalesPage: NewMediaDownloadSalesPage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createMediaDownloadSalesPageRequest(newMediaDownloadSalesPage));
    return axios
      .post('/api/mediadownloadsalespages', newMediaDownloadSalesPage, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createMediaDownloadSalesPageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createMediaDownloadSalesPageFailure(err.message));
      });
  };

const updateMediaDownloadSalesPageRequest = (
  id: string,
  patch: MediaDownloadSalesPage
) => ({
  type: UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST,
  id,
  patch,
});

const updateMediaDownloadSalesPageSuccess = (response: any) => ({
  type: UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS,
  response,
});

const updateMediaDownloadSalesPageFailure = (error: any) => ({
  type: UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE,
  error,
});

export const updateMediaDownloadSalesPage =
  (id: string, patch: MediaDownloadSalesPage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMediaDownloadSalesPageRequest(id, patch));
    return axios
      .patch(`/api/mediadownloadsalespages/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateMediaDownloadSalesPageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateMediaDownloadSalesPageFailure(err.message));
      });
  };
