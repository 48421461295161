import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { formattedPhone } from 'client/libraries/util/formattedPhone';

interface Props {
  reservation: ManifestReservationShape;
  fieldKey: string;
}

export const ReservationFormCell = ({ reservation, fieldKey }: Props) => {
  if (
    reservation?.field_responses?.some(
      (fieldResponse) => fieldResponse.key === fieldKey
    )
  ) {
    if (fieldKey === 'phone' || fieldKey === 'international_phone') {
      return (
        <>
          {formattedPhone(
            reservation?.field_responses?.find(
              (fieldResponse) => fieldResponse.key === fieldKey
            )?.response || ''
          )}
        </>
      );
    }

    return (
      <>
        {reservation?.field_responses?.find(
          (fieldResponse) => fieldResponse.key === fieldKey
        )?.response || ''}
      </>
    );
  }

  if (
    reservation.guests?.some((g) =>
      g?.field_responses?.some((r) => r.key === fieldKey)
    )
  ) {
    return (
      <ul>
        {reservation.guests.map((g, idx) => {
          const response = g?.field_responses?.find((r) => r.key === fieldKey);
          return <li key={idx}>{(response && response.response) || ''}</li>;
        })}
      </ul>
    );
  }

  return null;
};
