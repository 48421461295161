import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button, FieldWrapper } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  clearParticipantWaiver,
  deleteWaiver,
  fetchWaivers,
  fetchWaiverPDF,
} from 'client/actions/waivers';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchReservationByID } from 'client/actions/reservations';
import {
  getWaiverStatusText,
  getWaiverStatus,
} from 'client/libraries/util/getWaiverStatusText';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import type { ReduxState } from 'client/reducers';
import checkFinished from 'client/images/ic_check_finished.svg';
import checkUnfinished from 'client/images/ic_check_unfinished.svg';
import type { Reservation, Waiver, WaiverItem } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './WaiverDisplay.module.css';

const getConditionalItemSignatures = (item: WaiverItem): string[] => {
  const isYes = Boolean(
    (item.user_responses ?? []).length > 0 &&
      item.user_responses?.[0] === 'true'
  );

  const signatures: string[] = [];

  (isYes
    ? item.template_item?.conditional?.yes_items
    : item.template_item?.conditional?.no_items
  )?.forEach((yesNoItem, yesNoItemIdx) => {
    if (yesNoItem?.type === 'SIGNATURE') {
      if (
        (item.conditional_item_responses ?? []).length > yesNoItemIdx &&
        (item.conditional_item_responses?.[yesNoItemIdx]?.user_responses ?? [])
          .length > 0
      ) {
        signatures.push(
          item.conditional_item_responses?.[yesNoItemIdx]
            ?.user_responses?.[0] ?? ''
        );
      }
    }
  });

  return signatures;
};

type Fingerprint = {
  ipAddress: string;
  requestorId: string;
  signatures: string[];
  submissionDateTimeUtc: string;
  participantFullName?: string;
};

const getFingerprints = (waiver: Waiver): Fingerprint[] => {
  if ((waiver.per_participant_waivers ?? []).length > 0) {
    return (waiver.per_participant_waivers ?? []).map((perPaxWaiver) => {
      const fingerprint = perPaxWaiver.submission_fingerprint;

      const signatures = _.flatten(
        perPaxWaiver?.sections?.map((section) => [
          ...(section?.items
            ?.filter((item) => item?.template_item?.type === 'SIGNATURE')
            .map((item) => item.user_responses?.[0] ?? '') ?? []),
          ..._.flatten(
            section?.items
              ?.filter((item) => item?.template_item?.type === 'CONDITIONAL')
              .map((item) => getConditionalItemSignatures(item))
          ),
        ]) ?? []
      );

      return {
        ipAddress: fingerprint?.ip_address ?? '',
        requestorId: fingerprint?.requestor_id ?? '',
        signatures,
        submissionDateTimeUtc: perPaxWaiver.submitted_date_time_utc ?? '',
        participantFullName: perPaxWaiver.participant_full_name ?? '',
      };
    });
  }

  const fingerprint = waiver.submission_fingerprints?.[0];

  const signatures = _.flatten(
    waiver?.sections?.map(
      (section) =>
        section?.items
          ?.filter((item) => item?.template_item?.type === 'SIGNATURE')
          .map((item) => item.user_responses?.[0] ?? '') ?? []
    ) ?? []
  );

  return [
    {
      ipAddress: fingerprint?.ip_address ?? '',
      requestorId: fingerprint?.requestor_id ?? '',
      signatures,
      submissionDateTimeUtc: waiver.submitted_date_time_utc ?? '',
    },
  ];
};

type Props = {
  reservation: Reservation;
};
export const WaiverDisplay = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const [participantNameToClear, setParticpantNameToClear] = React.useState('');
  const [showClearWaiversModal, setShowClearWaiversModal] =
    React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const waiverPDFStatus = useSelector(
    (state: ReduxState) => state.waivers.pdfStatus
  );
  const waivers = useSelector((state: ReduxState) => state.waivers.all);
  const [showDigitalFingerprint, setShowDigitalFingerprint] =
    React.useState<boolean>(false);
  const [waiversHaveLoaded, setWaiversHaveLoaded] =
    React.useState<boolean>(false);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const waiverLoading = useSelector(
    (state: ReduxState) => state.waivers.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  React.useEffect(() => {
    if (showDigitalFingerprint && !waiversHaveLoaded) {
      setWaiversHaveLoaded(true);
      dispatch(
        fetchWaivers({
          reservation_id: reservation.id,
        })
      );
    }
  }, [showDigitalFingerprint, waiversHaveLoaded]);
  const dispatch = useDispatch();
  const waiverStatus = getWaiverStatus(reservation);
  const complete = waiverStatus === 'COMPLETE';
  const fingerprints =
    (waivers ?? []).length > 0 ? getFingerprints(waivers[0]) : [];

  return (
    <div>
      {showClearWaiversModal && (
        <DeleteConfirmModal
          header={t('Clear Waivers')}
          content={t(
            'Are you sure you want to clear waivers for this reservation? Warning: any waiver progress will be lost and this action can not be undone.'
          )}
          onConfirm={async () => {
            await dispatch(
              deleteWaiver(
                reservation?.id ?? '',
                reservation.waiver_info?.waiver_id ?? ''
              )
            );
          }}
          onClose={() => setShowClearWaiversModal(false)}
          open={showClearWaiversModal}
        />
      )}
      {participantNameToClear && (
        <DeleteConfirmModal
          header={t('Clear Participant Waiver')}
          content={t(
            'Are you sure you want to clear the waiver for "{{participantName}}"? Warning: this action can not be undone.',
            {
              participantName: participantNameToClear,
            }
          )}
          onConfirm={async () => {
            await dispatch(
              clearParticipantWaiver(
                reservation?.id ?? '',
                reservation.waiver_info?.waiver_id ?? '',
                participantNameToClear
              )
            );
            await dispatch(fetchReservationByID(reservation.id));
          }}
          loading={waiverLoading}
          onClose={() => setParticpantNameToClear('')}
          open={Boolean(participantNameToClear)}
        />
      )}
      {(waiverStatus === 'COMPLETE' || waiverStatus === 'IN_PROGRESS') &&
        hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') && (
          <Box mb={2}>
            <Button
              style="red"
              size="middle"
              onClick={() => setShowClearWaiversModal(true)}
            >
              {t('Clear Waivers')}
            </Button>
          </Box>
        )}
      <table className={clsx(baseStyles['base-table'])}>
        <tbody>
          <tr>
            <th className={clsx(baseStyles['base-t-160'])}>
              {t('Waiver Status')}
            </th>
            <td>
              {getWaiverStatusText(
                reservation.waiver_info,
                reservation.guests.length,
                t
              )}
              {(reservation.waiver_info?.participant_statuses ?? []).map(
                (participantStatus, idx) => (
                  <Box display="flex" alignItems="center" key={idx}>
                    <Box display="flex" alignItems="center">
                      {participantStatus.status === 'UNSIGNED' ? (
                        <img src={checkUnfinished} />
                      ) : (
                        <img src={checkFinished} />
                      )}
                    </Box>
                    <Box ml={1} width="30px">
                      {participantStatus.status !== 'UNSIGNED' &&
                        hasCustomUserRoleWritePermissions(
                          activeUser,
                          'RESERVATION'
                        ) && (
                          <DeleteIcon
                            onClick={() =>
                              setParticpantNameToClear(
                                participantStatus.participant_full_name ?? ''
                              )
                            }
                          />
                        )}
                    </Box>
                    <Box ml={4} width="200px">
                      {participantStatus.participant_full_name}
                    </Box>
                  </Box>
                )
              )}
            </td>
          </tr>
          {complete && (
            <tr>
              <th className={clsx(baseStyles['base-t-160'])}>
                {t('Submission Date')}
              </th>
              <td>
                {moment(reservation.waiver_info?.submitted_date_time_utc)
                  .locale(locale)
                  .format('lll')}
              </td>
            </tr>
          )}
          {complete && (
            <tr>
              <th className={clsx(baseStyles['base-t-160'])} />
              <td>
                <Box display="flex">
                  <Box mr={2}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${bookingWebsiteUrl}/waivers/${
                        reservation.waiver_info?.waiver_id ?? ''
                      }`}
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                      data-text={t('View')}
                    >
                      {t('View')}
                    </a>
                  </Box>
                  <Button
                    size="middle"
                    style="gray"
                    onClick={() => {
                      dispatch(
                        fetchWaiverPDF(reservation.waiver_info?.waiver_id ?? '')
                      );
                    }}
                    loading={waiverPDFStatus === 'IN_FLIGHT'}
                  >
                    {t('Download as PDF')}
                  </Button>
                </Box>
              </td>
            </tr>
          )}
          {complete && (
            <tr>
              <th className={clsx(baseStyles['base-t-160'])}>
                {t('Digital Fingerprints')}
              </th>
              <td style={{ height: 'auto' }}>
                <div
                  onClick={() =>
                    setShowDigitalFingerprint(!showDigitalFingerprint)
                  }
                  className={styles['link']}
                >
                  {showDigitalFingerprint ? t('Hide') : t('Show')}
                </div>
                {}
                {showDigitalFingerprint &&
                  fingerprints.map((fingerprint, idx) => (
                    <div key={idx} className={baseStyles['base-selectFrame']}>
                      <div className={baseStyles['base-selectFrame__header']}>
                        <p
                          className={
                            baseStyles['base-selectFrame__header__ttl']
                          }
                        >
                          {`#${idx + 1} - ${
                            fingerprint.submissionDateTimeUtc
                              ? moment(
                                  fingerprint.submissionDateTimeUtc
                                ).format('lll')
                              : '-'
                          }`}
                        </p>
                      </div>
                      <div className={baseStyles['base-selectFrame__body']}>
                        {fingerprint.ipAddress && (
                          <FieldWrapper label={t('IP Address: ')}>
                            {fingerprint.ipAddress}
                          </FieldWrapper>
                        )}
                        {fingerprint.requestorId && (
                          <FieldWrapper label={t('ID: ')}>
                            {fingerprint.requestorId}
                          </FieldWrapper>
                        )}
                        {fingerprint.participantFullName && (
                          <FieldWrapper label={t('Participant Name: ')}>
                            {fingerprint.participantFullName}
                          </FieldWrapper>
                        )}
                        {fingerprint.signatures &&
                          fingerprint.signatures.length > 0 && (
                            <FieldWrapper label={t('Signatures: ')}>
                              <div>
                                {fingerprint.signatures?.map(
                                  (signature, idx) => (
                                    <div
                                      className={styles['signature-box']}
                                      key={idx}
                                    >
                                      <img src={signature} />
                                    </div>
                                  )
                                )}
                              </div>
                            </FieldWrapper>
                          )}
                      </div>
                    </div>
                  ))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
