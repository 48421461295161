import type { TranslateFuncType } from 'client/components/Translate';
import type { EmailType } from 'shared/models/swagger';

export const getEmailTypeText = (
  emailType: EmailType,
  t: TranslateFuncType
): string => {
  switch (emailType) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      return t('Requested');
    case 'RESERVATION_CONFIRMED':
      return t('Confirmed');
    case 'RESERVATION_PUT_ON_STANDBY':
      return t('Standby');
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      return t('Updated');
    case 'RESERVATION_UPDATED_BY_AGENT':
      return t('Updated (Agent)');
    case 'RESERVATION_UPDATED_BY_GUEST':
      return t('Updated (Guest)');
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      return t('Canceled');
    case 'RESERVATION_CANCELED_BY_AGENT':
      return t('Canceled by agent');
    case 'RESERVATION_CANCELED_BY_GUEST':
      return t('Canceled by guest');
    case 'RESERVATION_DECLINED':
      return t('Declined');
    case 'RESERVATION_CROSSSELL_UPSELL':
      return t('Recommendations');
    case 'RESERVATION_REMINDER':
      return t('Reminder');
    case 'RESERVATION_REVIEW_REQUEST':
      return t('Review Request');
    case 'RESERVATION_PAYMENT_COMPLETE':
      return t('Payment Complete');
    case 'RESERVATION_UPDATE_BY_AGENT_FAILED':
      return t('Update Failed (Agent)');
    case 'RESERVATION_PAYMENT_REQUIRED':
      return t('Payment Required');
    case 'RESERVATION_UPDATE_BY_SUPPLIER_FAILED':
      return t('Update Failed');
    case 'RESERVATION_CANCELLATION_FAILED':
      return t('Cancellation Failed');
    case 'RESERVATION_REVIEW_SUBMITTED':
      return t('Review Submitted');
    case 'RESERVATION_WAIVER_SUBMITTED':
      return t('Waiver Submitted');
    case 'RESERVATION_WAIVER_REQUEST':
      return t('Waiver Request');
    case 'RESERVATION_CHANGED_BY_SUPPLIER':
      return t('Changed');
    case 'RESERVATION_CHANGED_BY_GUEST':
      return t('Changed (Guest)');
    case 'RESERVATION_MEDIA_DOWNLOAD_OFFER':
    case 'RESERVATION_MEDIA_DOWNLOAD_SUBMITTED':
      return t('Image Download');
    case 'RESERVATION_CARD_PAYMENT_NOTIFICATION':
      return t('Credit Card Payment');
  }
};
