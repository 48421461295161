import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { FieldWrapper, Radio, Button, Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { fetchProducts, fetchProductsByID } from 'client/actions/products';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers/index';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import { updateDispatchMiscResources } from 'client/actions/dispatchSettings';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import {
  FormValues,
  validateRange,
  getScheduleText,
} from 'client/libraries/util/resourceManager';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

import {
  getInitialValues,
  convertFormValuesToDispatchResources,
} from './formValues';
import styles from './MiscResourceAvailabilityModal.module.css';

type Props = {
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  resourceKey: string | null;
};

export const MiscResourceAvailabilityModal = ({
  open,
  onOpen,
  onClose,
  resourceKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('Edit availability for {{resourceName}}', {
    resourceName: resourceKey,
  });

  const resources = useSelector(allDispatchMiscResourcesSelector);

  const resource = resources.find((r) => r.key === resourceKey);

  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });

  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      onOpen={() => {
        onOpen && onOpen();
      }}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          const err = validateRange(values, t);
          if (err) {
            return err;
          }

          try {
            await dispatch(
              updateDispatchMiscResources(
                convertFormValuesToDispatchResources(
                  values,
                  resourceKey,
                  resources
                )
              )
            );
            onClose && onClose();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={getInitialValues(resource ?? null)}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Field name="unitType">
                  {({ input }) => (
                    <>
                      <Modal.Box>
                        <FieldWrapper
                          label={t('Type')}
                          tooltipText={t(
                            'Select "Per-participant" to assign resrouce per participants. Select "Per-booking" for resources required for each booking'
                          )}
                        >
                          <Radio
                            label={t('Per-participant')}
                            checked={input.value === 'PER_PARTICIPANT'}
                            onChange={() => {
                              input.onChange('PER_PARTICIPANT');
                            }}
                          />
                          <Radio
                            label={t('Per-booking')}
                            checked={input.value === 'PER_BOOKING'}
                            onChange={() => {
                              input.onChange('PER_BOOKING');
                            }}
                          />
                        </FieldWrapper>
                      </Modal.Box>
                      {input.value === 'PER_PARTICIPANT' && (
                        <Modal.Box>
                          <Field name="capacityPerUnit">
                            {({ input: inputCapacityPerUnit }) => (
                              <FieldWrapper
                                label={t('Capacity per unit')}
                                tooltipText={t(
                                  'Number of participants 1 resource can accommodate. e.g. select "2" for a two-seater buggy.'
                                )}
                              >
                                <Select
                                  options={[...nums]}
                                  value={inputCapacityPerUnit.value}
                                  onChange={(_, { value }) =>
                                    inputCapacityPerUnit.onChange(value)
                                  }
                                />
                              </FieldWrapper>
                            )}
                          </Field>
                        </Modal.Box>
                      )}
                    </>
                  )}
                </Field>
                <Modal.Box>
                  <FieldArray name="availabilitySchedules">
                    {({ fields }) => (
                      <FieldWrapper label={t('Resource Availability')}>
                        <div className={styles['page-productsEdit__select']}>
                          <div
                            className={styles['page-productsEdit__select__box']}
                          >
                            <label
                              className={clsx(baseStyles['base-form-select'])}
                            >
                              <select
                                value={activeScheduleIndex}
                                onChange={(e) => {
                                  setActiveScheduleIndex(
                                    parseInt(e.target.value)
                                  );
                                }}
                              >
                                {fields.value.map((priceRule, idx) => (
                                  <option value={idx} key={idx}>
                                    {getScheduleText(priceRule, t)}
                                  </option>
                                ))}
                              </select>
                            </label>
                            <a
                              className={clsx(
                                baseStyles['base-btn'],
                                baseStyles['small'],
                                baseStyles['green']
                              )}
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            >
                              {t('Add resource availability')}
                            </a>
                          </div>
                        </div>

                        <div
                          key={activeScheduleIndex}
                          className={baseStyles['base-modalSelectFrame']}
                        >
                          <div
                            className={baseStyles['base-selectFrame__header']}
                          >
                            <p
                              className={
                                baseStyles['base-selectFrame__header__ttl']
                              }
                            >
                              {t('Selected Resource Availability')}
                            </p>
                            {fields.value.length > 1 && (
                              <a
                                className={
                                  baseStyles['base-selectFrame__header__delete']
                                }
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                              >
                                {t('Remove this resource availability')}
                              </a>
                            )}
                          </div>
                          <div className={baseStyles['base-selectFrame__body']}>
                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Date Range')}
                              </p>
                              <div
                                className={
                                  styles['page-productsRegist__date__range']
                                }
                              >
                                <div className={baseStyles['base-form-range']}>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                  <p>-</p>
                                  <label
                                    className={baseStyles['base-form-calendar']}
                                  >
                                    <img src={calendarIcon} />
                                    <Field
                                      name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                    >
                                      {({ input }) => (
                                        <input type="date" {...input} />
                                      )}
                                    </Field>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Quantity')}
                              </p>

                              <Field
                                name={`${fields.name}.${activeScheduleIndex}.quantity`}
                              >
                                {({ input }) => (
                                  <Select
                                    options={[...nums]}
                                    value={input.value}
                                    onChange={(_, { value }) =>
                                      input.onChange(value)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Products')}
                                <Tooltip
                                  text={t(
                                    'Select product to links with resource availabilities'
                                  )}
                                  style={{ fontWeight: 'normal' }}
                                />
                              </p>

                              <ProductIds
                                name={`${fields.name}.${activeScheduleIndex}.productIds`}
                                scheduleIndex={activeScheduleIndex}
                              />
                            </div>
                            <div
                              className={styles['page-productsRegist__date']}
                            >
                              <p
                                className={
                                  styles['page-productsRegist__date__ttl']
                                }
                              >
                                {t('Add-ons')}
                                <Tooltip
                                  text={t(
                                    'Select product add-ons to links with resource availabilities'
                                  )}
                                  style={{ fontWeight: 'normal' }}
                                />
                              </p>

                              <ProductAddOn
                                name={`${fields.name}.${activeScheduleIndex}.addOns`}
                                scheduleIndex={activeScheduleIndex}
                              />
                            </div>
                          </div>
                        </div>
                      </FieldWrapper>
                    )}
                  </FieldArray>
                </Modal.Box>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
              </Modal.Content>

              <Modal.Actions>
                <Button.Cancel
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  {t('Discard')}
                </Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

const ProductAddOn = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(summariesSortedByBookmarkedSelector);
  const [newProductIds, setNewProductId] = React.useState<string[]>([]);

  const formState = useFormState();
  const productsById = useSelector((state: ReduxState) => state.products.byID);

  React.useEffect(() => {
    if (formState.values && formState.values.availabilitySchedules) {
      const newProductIds: string[] = [];
      formState.values.availabilitySchedules.forEach((schedule: any) => {
        if (schedule.addOns) {
          schedule.addOns.forEach((addOn: any) => {
            if (
              !productsById[addOn.productId] &&
              !newProductIds.includes(addOn.productId)
            ) {
              newProductIds.push(addOn.productId);
            }
          });
        }
      });
      setNewProductId(newProductIds);
    }
  }, [formState.values]);

  React.useEffect(() => {
    if (newProductIds.length > 0) {
      dispatch(fetchProductsByID(newProductIds));
    }
  }, [newProductIds]);

  const excludeProductIds = React.useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.productIds;
  }, [scheduleIndex, formState.values]);

  const candidateProducts = React.useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  const hasAddOn = (
    productId: string,
    unitType: 'PER_BOOKING' | 'PER_PARTICIPANT'
  ): boolean => {
    return (
      (productsById[productId]?.add_ons ?? []).filter(
        (addOn) => addOn?.pricing?.[0]?.method === unitType
      ).length !== 0
    );
  };

  const getAddOns = (
    productId: string,
    unitType: 'PER_BOOKING' | 'PER_PARTICIPANT'
  ): any[] => {
    return (productsById[productId]?.add_ons ?? [])
      .filter((addOn) => addOn?.pricing?.[0]?.method === unitType)
      .map((addOn) => ({
        value: addOn.key,
        text: addOn.title,
      }));
  };

  return (
    <div className={baseStyles['base-form-box']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div key={name} className={styles['addon__item']}>
                <Field name={`${name}.productId`} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <>
                      <div className={styles['addon__item__selector']}>
                        <Select
                          search={true}
                          options={(candidateProducts ?? []).map((p) => ({
                            value: p.id,
                            text: getVerboseDisplayProductName(p),
                          }))}
                          value={inputProductId.value ?? ''}
                          onChange={(_, { value }) => {
                            inputProductId.onChange(value);
                          }}
                        />
                      </div>
                      <Field name={`${name}.addOnKey`} key={`${idx}-addOnKey`}>
                        {({ input }) => (
                          <div className={styles['addon__item__selector']}>
                            <Select
                              search={true}
                              disabled={
                                !hasAddOn(
                                  inputProductId.value,
                                  formState.values.unitType
                                )
                              }
                              options={getAddOns(
                                inputProductId.value,
                                formState.values.unitType
                              )}
                              value={input.value ?? ''}
                              onChange={(_, { value }) => {
                                input.onChange(value);
                              }}
                              placeholder={
                                hasAddOn(
                                  inputProductId.value,
                                  formState.values.unitType
                                )
                                  ? t('Select Add-on')
                                  : t('No Add-on')
                              }
                            />
                          </div>
                        )}
                      </Field>
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </>
                  )}
                </Field>
              </div>
            ))}

            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  fields.push({
                    productId: '',
                    addOnKey: '',
                  });
                }}
              >
                {t('Add')}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

const ProductIds = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const formState = useFormState();

  const excludeProductIds = React.useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.addOns?.map(
      (addOn: any) => addOn.productId
    );
  }, [scheduleIndex, formState.values]);

  const candidateProducts = React.useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  return (
    <div className={baseStyles['base-form-box']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div key={name} className={styles['addon__item']}>
                <Field name={name} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <>
                      <div className={styles['addon__item__selector']}>
                        <Select
                          search={true}
                          options={(candidateProducts ?? [])
                            .filter(
                              (p) =>
                                !fields.value.includes(p.id) ||
                                p.id === inputProductId.value
                            )
                            .map((p) => ({
                              value: p.id,
                              text: getVerboseDisplayProductName(p),
                            }))}
                          value={inputProductId.value ?? ''}
                          onChange={(_, { value }) => {
                            inputProductId.onChange(value);
                          }}
                        />
                      </div>
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </>
                  )}
                </Field>
              </div>
            ))}

            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  fields.push('');
                }}
              >
                {t('Add')}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};
