import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';
import { Section } from '@nutmeglabs/falcon-ui';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { Editor } from 'client/components/Editor/Editor';
import { FalconUIContextProvider } from 'client/components/FalconUIContextProvider/FalconUIContextProvider';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { updateCustomPage, createCustomPage } from 'client/actions/customPage';
import { getBookingWidgetPmpEssentialPageUrlBase } from 'client/pages/EssentialPages/utils';
import { CustomSectionsEditor } from 'client/pages/EssentialPages/CustomPages/CustomSectionsEditor';
import { productOptionsSelector } from 'client/reducers/products';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import {
  Button,
  Input,
  ToggleButton,
  TextArea,
  Select,
} from 'client/components/Form';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { customPagesSelector } from 'client/reducers/customPages';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getArrayMutators } from 'client/libraries/util/form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import type {
  CustomPageType,
  CustomPagePatch,
  NewCustomPage,
  SourceLanguage,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/SiteSettingsForm/SiteSettingsForm.module.css';
import editorStyles from 'client/pages/EssentialPages/CustomPages/EditCustomPageModal.module.css';

type LocationState = {
  contentLanguage: SourceLanguage;
  type: CustomPageType;
  id?: string;
};

type FormValues = {
  visibility: boolean;
  title: string;
  path: string;
  body: any;
  sections: Section[];
  keyVisual: string;
  description: string;
  tag: string[];
  relatedProduct: string[];
};

const focusOnError: any = createDecorator();

export const EditCustomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollTo = React.useContext(ScrollToContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const customPages = useSelector(customPagesSelector);
  const productOptions = useSelector(productOptionsSelector);
  const propsLocation = location && (location.state as LocationState);

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [selfPath, setSelfPath] = React.useState<string | null>(null);
  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);

  const id = propsLocation?.id || '';
  const type = propsLocation.type;
  const contentLanguage = propsLocation.contentLanguage;
  const editCustomPage = customPages.find((customPage) => customPage.id === id);

  React.useEffect(() => {
    if (editCustomPage) {
      setSelfPath(editCustomPage.path);
    }
  }, [editCustomPage]);

  const initialValues = React.useMemo((): FormValues => {
    return editCustomPage
      ? {
          ...editCustomPage,
          visibility: editCustomPage.status === 'ACTIVE',
          body: JSON.parse(editCustomPage?.body || '{}'),
          title: editCustomPage.title,
          sections: !editCustomPage.sections
            ? [
                {
                  type: 'EDITORJS',
                  editorJsContent: editCustomPage?.body || '{}',
                  title: editCustomPage.title,
                  titleColor: '#000000',
                  backgroundColor: '#f9f9f9',
                },
              ]
            : JSON.parse(editCustomPage.sections || '[]'),
          keyVisual: editCustomPage.key_visual || '',
          description: editCustomPage.description || '',
          tag: editCustomPage.tag || [],
          relatedProduct: editCustomPage.related_product || [],
        }
      : {
          visibility: true,
          path: '',
          title: '',
          body: null,
          sections: [],
          keyVisual: '',
          description: '',
          tag: [],
          relatedProduct: [],
        };
  }, [activeUserOrganization, customPages]);

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['base-main__body__box']}>
      <FalconUIContextProvider language={contentLanguage}>
        <Form
          onSubmit={async (values: FormValues) => {
            const customPage = {
              title: values.title,
              path: values.path,
              body: JSON.stringify(values.body),
              sections: JSON.stringify(values.sections),
              status: values.visibility ? 'ACTIVE' : 'INACTIVE',
              key_visual: values.keyVisual,
              description: values.description,
              tag: values.tag,
              related_product: values.relatedProduct,
            };
            setSelfPath(values.path);

            try {
              if (editCustomPage) {
                await dispatch(
                  updateCustomPage(
                    editCustomPage.id,
                    customPage as CustomPagePatch
                  )
                );
              } else {
                await dispatch(
                  createCustomPage({
                    ...(customPage as NewCustomPage),
                    type: type,
                    content_language: contentLanguage,
                  })
                );
              }

              scrollTo(0, 0);
              setSaveSucceeded(true);
            } catch (error) {
              if (editCustomPage) {
                setSelfPath(editCustomPage.path);
              }

              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
          validate={(values) => {
            const errors: Record<string, string> = {};

            if (!values.title) {
              errors.title = t('Required');
            }

            if (selfPath !== values.path) {
              const duplicate = (customPages || []).find(
                (customPage) =>
                  customPage.path === values.path && customPage.type === type
              );

              if (duplicate) {
                errors.path = t('Path Not Available');
              }
            }

            if (!values.path) {
              errors.path = t('Required');
            }

            return errors;
          }}
          decorators={[focusOnError]}
          initialValues={initialValues}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitting, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={baseStyles['base-main__body__box__body']}>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>{t('Type')}</th>
                          <td>
                            <EnumRadioButtonGroup
                              name="type"
                              options={[
                                {
                                  value: 'NORMAL',
                                  label: t('Custom Page'),
                                },
                                {
                                  value: 'NEWS',
                                  label: t('NEWS'),
                                },
                                {
                                  value: 'OTHER_PAGE',
                                  label: t('OTHER_PAGE'),
                                },
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Content Language')}</th>
                          <td>
                            {getLanguageName(
                              uppercaseIsoToLowercaseIso[contentLanguage],
                              t
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Visibility')}</th>
                          <td>
                            <Field name="visibility" type="checkbox">
                              {({ input }) => (
                                <ToggleButton
                                  label={t('Show this page on booking site')}
                                  {...input}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('URL')}</th>
                          <td>
                            <div className={baseStyles['inline-block']}>
                              <p
                                style={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {`${getBookingWidgetPmpEssentialPageUrlBase(
                                  activeUserOrganization
                                )}${'/article'}/`}
                              </p>
                              <Field name="path">
                                {({ input, meta }) => (
                                  <>
                                    <input
                                      className={baseStyles['base-form-text']}
                                      {...input}
                                    />
                                    {meta.error && meta.touched && (
                                      <p
                                        className={
                                          baseStyles['base-form-box__err']
                                        }
                                      >
                                        {meta.error}
                                      </p>
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Title')}</th>
                          <td>
                            <Field name="title">
                              {({ input, meta }) => (
                                <Input
                                  maxWidth={800}
                                  error={
                                    meta.error && meta.touched && meta.error
                                  }
                                  {...input}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Key Visual')}</th>
                          <td>
                            <Field name="keyVisual">
                              {({ input }) => (
                                <SingleImageInput
                                  acceptedFileTypes={[
                                    'image/jpeg',
                                    'image/png',
                                  ]}
                                  onUploadFinished={(filename) =>
                                    input.onChange(filename)
                                  }
                                  initialValue={
                                    input.value
                                      ? `${config.s3Config.mediaPathRoot}/keyvisual/supplier/${activeUserOrganization?.id}/${input.value}`
                                      : ''
                                  }
                                  getFileSavePath={(filename) =>
                                    `keyvisual/supplier/${activeUserOrganization?.id}/${filename}`
                                  }
                                  uploadWithOriginalFilename={true}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Description')}</th>
                          <td>
                            <Field name="description">
                              {({ input, meta }) => (
                                <TextArea
                                  height={70}
                                  error={
                                    meta.error && meta.touched && meta.error
                                  }
                                  {...input}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        {hasSubscription(
                          activeUserOrganization,
                          'feature-custom-top-page'
                        ) &&
                        !isSubscriptionCancelled(
                          activeUserOrganization,
                          'feature-custom-top-page'
                        ) ? (
                          <>
                            <tr>
                              <th>{t('Sections')}</th>
                              <td>
                                <CustomSectionsEditor
                                  language={contentLanguage}
                                />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <th>{t('Body')}</th>
                              <td>
                                <div className={editorStyles['body-editor']}>
                                  <Field name="body">
                                    {({ input }) => (
                                      <Editor
                                        imageS3Prefix={`editor/supplier/${
                                          activeUserOrganization?.id || ''
                                        }`}
                                        data={input.value}
                                        onChange={(data: any, loading) => {
                                          setEditorJsLoading(loading);
                                          input.onChange(data);
                                        }}
                                        contentLanguage={contentLanguage}
                                      />
                                    )}
                                  </Field>
                                </div>
                                {t(
                                  '* Copy and paste URL of YouTube videos to show YouTube videos in the massage.'
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <th>{t('Tag')}</th>
                          <FieldArray name="tag">
                            {({ fields: tagFields }) => (
                              <>
                                <td>
                                  {tagFields.length === 0 && (
                                    <AddIcon
                                      onClick={() =>
                                        (tagFields as any).insertAt(0, '')
                                      }
                                    />
                                  )}
                                  {tagFields.map((idName, idx2) => (
                                    <Field key={idName} name={idName}>
                                      {({ input, meta }) => (
                                        <>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            mt={2}
                                            mr={3}
                                            mb={2}
                                          >
                                            <Input
                                              height={70}
                                              error={
                                                meta.error &&
                                                meta.touched &&
                                                meta.error
                                              }
                                              {...input}
                                            />
                                            <Box ml={4}>
                                              <AddIcon
                                                onClick={() =>
                                                  (tagFields as any).insertAt(
                                                    0,
                                                    ''
                                                  )
                                                }
                                              />
                                            </Box>
                                            <Box ml={2}>
                                              <DeleteIcon
                                                onClick={() => {
                                                  tagFields.remove(idx2);
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Field>
                                  ))}
                                </td>
                              </>
                            )}
                          </FieldArray>
                        </tr>
                        <tr>
                          <th>{t('Related Product')}</th>
                          <FieldArray name="relatedProduct">
                            {({ fields: productFields }) => (
                              <>
                                <td>
                                  {productFields.length === 0 && (
                                    <AddIcon
                                      onClick={() =>
                                        (productFields as any).insertAt(0, '')
                                      }
                                    />
                                  )}
                                  {productFields.map((idName, idx2) => (
                                    <Field key={idName} name={idName}>
                                      {({ input }) => (
                                        <>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            mt={2}
                                            mr={3}
                                            mb={2}
                                          >
                                            <Select
                                              search
                                              maxWidth={400}
                                              value={input.value}
                                              options={productOptions}
                                              style={{ textAlign: 'left' }}
                                              onChange={(e, { value }) => {
                                                input.onChange(value);
                                              }}
                                            />
                                            <Box ml={4}>
                                              <AddIcon
                                                onClick={() =>
                                                  (
                                                    productFields as any
                                                  ).insertAt(0, '')
                                                }
                                              />
                                            </Box>
                                            <Box ml={2}>
                                              <DeleteIcon
                                                onClick={() => {
                                                  productFields.remove(idx2);
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Field>
                                  ))}
                                </td>
                              </>
                            )}
                          </FieldArray>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      disabled={submitting}
                      style="gray"
                      size="middle"
                      onClick={() => {
                        //onClose();
                      }}
                    >
                      {t('Close')}
                    </Button>
                    <Button
                      disabled={
                        submitting ||
                        editorJsLoading ||
                        (!editCustomPage && saveSucceeded)
                      }
                      loading={submitting || editorJsLoading}
                      style="green"
                      size="middle"
                      type="submit"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </FalconUIContextProvider>
    </div>
  );
};
