// @flow

import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { Checkbox } from 'client/components/Form';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import baseStyles from 'client/base.module.css';

import styles from './ColorsEditor.module.css';

export const ColorsEditor = () => {
  const { t } = useTranslation();

  const form = useForm();
  const values = form.getState().values;

  const params = new URLSearchParams();
  params.append('p', values?.primaryColor ?? '');
  params.append('s', values?.secondaryColor ?? '');
  params.append('bg', values?.backgroundColor ?? '');

  const previewUrl = values?.shouldUseDefaultColors
    ? `/reservations/voucherpreview`
    : `/reservations/voucherpreview?${params.toString()}`;

  return (
    <div>
      <Box mb={2}>
        <Field name="shouldUseDefaultColors" type="checkbox">
          {({ input }) => (
            <Checkbox label={t('Use default colors')} {...input} />
          )}
        </Field>
      </Box>

      {!values?.shouldUseDefaultColors && (
        <>
          <div className={styles['c-tableChild']}>
            <ul>
              <li style={{ width: 160 }}>{t('Primary Color')}</li>
              <li>{t('Secondary Color')}</li>
            </ul>
            <ul>
              <li data-title={t('Primary Color')}>
                <Field name="primaryColor">
                  {({ input }) => (
                    <ColorPicker
                      placement="bottomRight"
                      color={input.value}
                      onChange={({ color }) => input.onChange(color)}
                    />
                  )}
                </Field>
              </li>
              <li data-title={t('Secondary Color')}>
                <Field name="secondaryColor">
                  {({ input }) => (
                    <ColorPicker
                      placement="bottomRight"
                      color={input.value}
                      onChange={({ color }) => input.onChange(color)}
                    />
                  )}
                </Field>
              </li>
            </ul>
          </div>
          <div className={styles['c-tableChild']}>
            <ul>
              <li style={{ width: 160 }}>{t('Background Color')}</li>
            </ul>
            <ul>
              <li data-title={t('Background Color')}>
                <Field name="backgroundColor">
                  {({ input }) => (
                    <ColorPicker
                      placement="bottomRight"
                      color={input.value}
                      onChange={({ color }) => input.onChange(color)}
                    />
                  )}
                </Field>
              </li>
            </ul>
          </div>
        </>
      )}

      <a
        className={clsx(
          baseStyles['base-btn'],
          baseStyles['middle'],
          baseStyles['blue']
        )}
        href={previewUrl}
        target="_blank"
      >
        <p>{t('Preview')}</p>
        <img src={newWindowIcon} />
      </a>
    </div>
  );
};
