import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/Form';
import { convertReviewRatingToNumber } from 'client/pages/ReservationDetails/ReservationSurveysTab/ReservationSurveysTab';
import { ReviewRating, SurveySubmission } from 'shared/models/swagger';

interface Props {
  surveySubmission: SurveySubmission;
}

export const SubmittedSurveyAnswers = ({ surveySubmission }: Props) => {
  return (
    <>
      {surveySubmission.survey_template?.pages?.map((page) =>
        page.questions?.map((question) => {
          const answer = surveySubmission.answers?.find(
            (answer) => answer.question_key === question.key
          );
          if (!answer) {
            return null;
          }

          if (
            question.response_type === 'SURVEY_QUESTION_STAR_RATING' &&
            (answer.responses ?? []).length > 0
          ) {
            return (
              <Box mt={2} key={question.key}>
                <FieldWrapper label={question.question_text ?? ''}>
                  {`${convertReviewRatingToNumber(
                    answer.responses?.[0] as ReviewRating
                  )} / 5`}
                </FieldWrapper>
              </Box>
            );
          }

          if (
            question.response_type === 'SURVEY_QUESTION_SELECT_MULTIPLE' ||
            question.response_type === 'SURVEY_QUESTION_SELECT_ONE'
          ) {
            return (
              <Box mt={2} key={question.key}>
                <FieldWrapper label={question.question_text ?? ''}>
                  {answer.responses
                    ?.map(
                      (response) =>
                        question?.response_options?.find(
                          (option) => option.key === response
                        )?.text ?? ''
                    )
                    .join(',')}
                </FieldWrapper>
              </Box>
            );
          }

          return (
            <Box mt={2} key={question.key}>
              <FieldWrapper label={question.question_text ?? ''}>
                <div className="newline">{answer.responses?.join(',')}</div>
              </FieldWrapper>
            </Box>
          );
        })
      )}
    </>
  );
};
