import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Button, Input } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  deleteNewsletterSegment,
  fetchNewsletterSegments,
} from 'client/actions/newsletterSegment';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { Edit } from 'client/components/Icons/Edit';
import { Delete } from 'client/components/Icons/Delete';
import { CustomersModal } from 'client/components/CustomersModal/CustomersModal';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { NewsletterCustomerSegment } from 'shared/models/swagger';

const EditDeleteCell = ({
  segment,
}: {
  segment: NewsletterCustomerSegment;
}) => {
  const { t } = useTranslation();
  const [showDeleteSegmentModal, setShowDeleteSegmentModal] =
    React.useState(false);
  const loading = useSelector(
    (state: ReduxState) => state.newsletterSegments.loading
  );
  const dispatch = useDispatch();

  return (
    <Box display="flex">
      <Link to={`/newsletters/segments/${segment.id}/edit`}>
        <Edit />
      </Link>
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteSegmentModal(true)} />
        {showDeleteSegmentModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Segment')}
            content={t('Are you sure you want to delete segment?')}
            onConfirm={async () => {
              await dispatch(deleteNewsletterSegment(segment.id as string));
            }}
            onClose={() => setShowDeleteSegmentModal(false)}
            open={showDeleteSegmentModal}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const ContactsCell = ({ segment }: { segment: NewsletterCustomerSegment }) => {
  const [showContactsModal, setShowContactsSegmentModal] =
    React.useState(false);

  return (
    <Box display="flex">
      <a onClick={() => setShowContactsSegmentModal(true)}>
        {(segment.customer_ids ?? []).length +
          (segment.imported_customer_count ?? 0)}
      </a>
      {showContactsModal && (
        <CustomersModal
          customerIds={segment.customer_ids ?? []}
          s3PresignedUrl={segment.imported_csv_file_url}
          onClose={() => setShowContactsSegmentModal(false)}
        />
      )}
    </Box>
  );
};

const useColumns = (): ColumnType<NewsletterCustomerSegment>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(activeUser, 'NEWSLETTER.SEGMENTS')
      ? [
          {
            Header: '',
            width: 100,
            id: 'edit',
            accessor: (segment) => <EditDeleteCell segment={segment} />,
          } as ColumnType<NewsletterCustomerSegment>,
        ]
      : []),
    {
      Header: t('Name'),
      id: 'name',
      accessor: (segment) => segment.name,
    },
    {
      Header: t('Contacts'),
      width: 80,
      id: 'contacts',
      accessor: (segment) => <ContactsCell segment={segment} />,
    },
    {
      Header: t('Description'),
      id: 'description',
      accessor: (segment) => (
        <TruncatedTextWithSeeMoreButton>
          {segment.description}
        </TruncatedTextWithSeeMoreButton>
      ),
    },
    {
      Header: t('Type'),
      width: 80,
      id: 'type',
      accessor: (segment) => {
        switch (segment.type) {
          case 'DYNAMIC':
            return t('Dynamic');
          case 'STATIC':
            return t('Static');
          case 'IMPORTED_CUSTOMER_LIST':
            return t('Import Customer List');
          default:
            return '';
        }
      },
    },
    {
      Header: t('Last Updated'),
      width: 160,
      id: 'lastUpdated',
      accessor: (segment) =>
        moment(segment.last_updated_date_time_utc).locale(locale).format('lll'),
    },
  ];
};

export const NewsletterSegmentList = () => {
  const { t } = useTranslation();

  const [filter, setFilter] = React.useState('');

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchNewsletterSegments());
  }, []);

  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const filteredSegments = segments.filter(
    (segment) =>
      !filter || segment.name?.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = useColumns();
  const activeUser = useSelector(activeUserSelector);

  return (
    <div>
      <Box mb={2} display="flex" alignItems="center">
        <Input
          label=""
          value={filter}
          onChange={(e, { value }) => setFilter(value)}
          placeholder={t('Filter by name')}
          width={200}
        />
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'NEWSLETTER.SEGMENTS'
        ) && (
          <Box ml="auto">
            <Link to="/newsletters/segments/new">
              <Button.Create size="middle">
                {t('Create New Segment')}
              </Button.Create>
            </Link>
          </Box>
        )}
      </Box>
      <PagedGenericTable<NewsletterCustomerSegment>
        allItems={filteredSegments}
        columns={columns}
      />
    </div>
  );
};
