import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import type { EquipmentBlockInstanceAttribute } from 'shared/models/swagger';

import { EditEquipmentBlockInstanceAttributeModal } from './EditEquipmentBlockInstanceAttributeModal';

export const EditDeleteCell = ({
  equipmentBlockInstanceAttribute,
  onConfirmClick,
}: {
  equipmentBlockInstanceAttribute: EquipmentBlockInstanceAttribute;
  onConfirmClick: () => void;
}) => {
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditEquipmentBlockInstanceAttributeModal
          equipmentBlockInstanceAttributeKey={
            equipmentBlockInstanceAttribute.key ?? ''
          }
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete Booking Source Group')}
          content={t('Are you sure you want to delete booking source group?')}
          onConfirm={async () => {
            await onConfirmClick();
            setShowDeleteModal(false);
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};
