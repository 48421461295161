import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';

import { Radio } from 'client/components/v3/Form/Radio';
import { Modal } from 'client/components/v3/Form/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { fetchProducts, fetchProductsByID } from 'client/actions/products';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers/index';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import { updateDispatchMiscResources } from 'client/actions/dispatchSettings';
import {
  FormValues,
  validateRange,
  getScheduleText,
} from 'client/libraries/util/resourceManager';
import {
  getInitialValues,
  convertFormValuesToDispatchResources,
} from 'client/pages/DispatchCustomize/ResourceList/MiscResourceAvailabilityModal/formValues';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

type Props = {
  open: boolean;
  onClose?: () => void;
  resourceKey: string | null;
};

export const ResourceAvailabilityModal = ({
  open,
  onClose,
  resourceKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('Edit availability for {{resourceName}}', {
    resourceName: resourceKey,
  });

  const resources = useSelector(allDispatchMiscResourcesSelector);
  const resource = resources.find((r) => r.key === resourceKey);

  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        const err = validateRange(values, t);
        if (err) {
          return err;
        }

        try {
          await dispatch(
            updateDispatchMiscResources(
              convertFormValuesToDispatchResources(
                values,
                resourceKey,
                resources
              )
            )
          );
          onClose && onClose();
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={getInitialValues(resource ?? null)}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              title={title}
              open={open}
              onClose={() => {
                onClose && onClose();
              }}
              rightActionChildren={
                <>
                  <Button
                    color="white"
                    onClick={() => {
                      onClose && onClose();
                    }}
                    text={t('Discard')}
                  />
                  <Button
                    text={t('Save')}
                    loading={submitting}
                    color="primary"
                    type="submit"
                  />
                </>
              }
              style={{ width: '600px', height: '600px' }}
            >
              <div className={styles['p-manifestsModalCustomize']}>
                <div className={styles['p-manifestsModalCustomize__item']}>
                  <p className={styles['p-manifestsModalCustomize__item__ttl']}>
                    {t('Type')}
                    <div style={{ marginLeft: '8px' }}>
                      <Tooltip
                        text={t(
                          'Select "Per-participant" to assign resrouce per participants. Select "Per-booking" for resources required for each booking'
                        )}
                      >
                        <i className="c-icon-outline-general-info-circle"></i>
                      </Tooltip>
                    </div>
                  </p>
                  <div
                    className={styles['p-manifestsModalCustomize__item__body']}
                  >
                    <Field name="unitType">
                      {({ input }) => (
                        <>
                          <Radio
                            label={t('Per-participant')}
                            checked={input.value === 'PER_PARTICIPANT'}
                            onChange={() => {
                              input.onChange('PER_PARTICIPANT');
                            }}
                            size="sm"
                          />
                          <Radio
                            label={t('Per-booking')}
                            checked={input.value === 'PER_BOOKING'}
                            onChange={() => {
                              input.onChange('PER_BOOKING');
                            }}
                            size="sm"
                          />
                          {input.value === 'PER_PARTICIPANT' && (
                            <Field name="capacityPerUnit">
                              {({ input: inputCapacityPerUnit }) => (
                                <div
                                  className={
                                    styles['p-manifestsModalCustomize__item']
                                  }
                                >
                                  <p
                                    className={
                                      styles[
                                        'p-manifestsModalCustomize__item__ttl'
                                      ]
                                    }
                                  >
                                    {t('Capacity per unit')}
                                    <div style={{ marginLeft: '8px' }}>
                                      <Tooltip
                                        text={t(
                                          'Number of participants 1 resource can accommodate. e.g. select "2" for a two-seater buggy.'
                                        )}
                                      >
                                        <i className="c-icon-outline-general-info-circle"></i>
                                      </Tooltip>
                                    </div>
                                  </p>
                                  <div
                                    className={
                                      styles[
                                        'p-manifestsModalCustomize__item__body'
                                      ]
                                    }
                                  >
                                    <SingleDropdown
                                      options={[...nums]}
                                      selectedOption={
                                        inputCapacityPerUnit.value
                                      }
                                      onChange={(value) =>
                                        inputCapacityPerUnit.onChange(value)
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Field>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <FieldArray name="availabilitySchedules">
                  {({ fields }) => (
                    <>
                      <div
                        className={styles['p-manifestsModalCustomize__item']}
                      >
                        <p
                          className={
                            styles['p-manifestsModalCustomize__item__ttl']
                          }
                        >
                          {t('Resource Availability')}
                        </p>
                        <div
                          className={
                            styles['p-manifestsModalCustomize__item__body']
                          }
                        >
                          <div
                            className={
                              styles[
                                'p-manifestsModalCustomize__item__body__flex'
                              ]
                            }
                          >
                            <SingleDropdown
                              options={
                                fields.value.length > 0
                                  ? fields.value.map((priceRule, idx) => ({
                                      text: getScheduleText(priceRule, t),
                                      value: idx.toString(),
                                    }))
                                  : []
                              }
                              selectedOption={activeScheduleIndex.toString()}
                              onChange={(e) => {
                                setActiveScheduleIndex(parseInt(e));
                              }}
                            />
                            <Button
                              text={t('Add resource availability')}
                              color="primary"
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles['p-frame']}>
                        <div className={styles['p-frame__header']}>
                          <p className={styles['p-frame__header__ttl']}>
                            {t('Selected Resource Availability')}
                          </p>
                          <div className={styles['p-frame__header__actions']}>
                            {fields.value.length > 1 && (
                              <Button
                                text={t('Remove this price schedule')}
                                size="sm"
                                color="tertiarygray"
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                                style={{ color: 'var(--error600)' }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          key={activeScheduleIndex}
                          className={styles['p-frame__body']}
                        >
                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Date Range')}
                            </p>
                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <div
                                className={
                                  styles[
                                    'p-manifestsModalCustomize__item__body__flex'
                                  ]
                                }
                              >
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                                <p style={{ marginBottom: 0 }}>-</p>
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Quantity')}
                            </p>
                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <Field
                                name={`${fields.name}.${activeScheduleIndex}.quantity`}
                              >
                                {({ input }) => (
                                  <SingleDropdown
                                    options={[...nums]}
                                    selectedOption={input.value}
                                    onChange={(value) => input.onChange(value)}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Products')}
                              <div style={{ marginLeft: '8px' }}>
                                <Tooltip
                                  text={t(
                                    'Select product to links with resource availabilities'
                                  )}
                                >
                                  <i className="c-icon-outline-general-info-circle"></i>
                                </Tooltip>
                              </div>
                            </p>
                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <ProductIds
                                name={`${fields.name}.${activeScheduleIndex}.productIds`}
                                scheduleIndex={activeScheduleIndex}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Add-ons')}
                              <div style={{ marginLeft: '8px' }}>
                                <Tooltip
                                  text={t(
                                    'Select product add-ons to links with resource availabilities'
                                  )}
                                >
                                  <i className="c-icon-outline-general-info-circle"></i>
                                </Tooltip>
                              </div>
                            </p>

                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <ProductAddOn
                                name={`${fields.name}.${activeScheduleIndex}.addOns`}
                                scheduleIndex={activeScheduleIndex}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>
                {submitError && (
                  <p className={baseStyles['u-error-msg']}>{submitError}</p>
                )}
              </div>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};

const ProductAddOn = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(summariesSortedByBookmarkedSelector);
  const [newProductIds, setNewProductId] = useState<string[]>([]);

  const formState = useFormState();
  const productsById = useSelector((state: ReduxState) => state.products.byID);

  useEffect(() => {
    if (formState.values && formState.values.availabilitySchedules) {
      const newProductIds: string[] = [];
      formState.values.availabilitySchedules.forEach((schedule: any) => {
        if (schedule.addOns) {
          schedule.addOns.forEach((addOn: any) => {
            if (
              !productsById[addOn.productId] &&
              !newProductIds.includes(addOn.productId)
            ) {
              newProductIds.push(addOn.productId);
            }
          });
        }
      });
      setNewProductId(newProductIds);
    }
  }, [formState.values]);

  useEffect(() => {
    if (newProductIds.length > 0) {
      dispatch(fetchProductsByID(newProductIds));
    }
  }, [newProductIds]);

  const excludeProductIds = useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.productIds;
  }, [scheduleIndex, formState.values]);

  const candidateProducts = useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  const hasAddOn = (
    productId: string,
    unitType: 'PER_BOOKING' | 'PER_PARTICIPANT'
  ): boolean => {
    return (
      (productsById[productId]?.add_ons ?? []).filter(
        (addOn) => addOn?.pricing?.[0]?.method === unitType
      ).length !== 0
    );
  };

  const getAddOns = (
    productId: string,
    unitType: 'PER_BOOKING' | 'PER_PARTICIPANT'
  ): any[] => {
    return (productsById[productId]?.add_ons ?? [])
      .filter((addOn) => addOn?.pricing?.[0]?.method === unitType)
      .map((addOn) => ({
        value: addOn.key,
        text: addOn.title,
      }));
  };

  return (
    <div className={styles['p-manifests__productListInput']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div
                key={name}
                className={
                  styles['p-manifestsModalCustomize__item__body__flex']
                }
              >
                <Field name={`${name}.productId`} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <>
                      <SingleDropdown
                        searchable={true}
                        options={(candidateProducts ?? []).map((p) => ({
                          value: p.id,
                          text: getVerboseDisplayProductName(p),
                        }))}
                        selectedOption={inputProductId.value ?? ''}
                        onChange={(value) => {
                          inputProductId.onChange(value);
                        }}
                      />
                      <Field name={`${name}.addOnKey`} key={`${idx}-addOnKey`}>
                        {({ input }) => (
                          <SingleDropdown
                            searchable={true}
                            disabled={
                              !hasAddOn(
                                inputProductId.value,
                                formState.values.unitType
                              )
                            }
                            options={getAddOns(
                              inputProductId.value,
                              formState.values.unitType
                            )}
                            selectedOption={input.value ?? ''}
                            onChange={(value) => {
                              input.onChange(value);
                            }}
                            placeholder={
                              hasAddOn(
                                inputProductId.value,
                                formState.values.unitType
                              )
                                ? t('Select Add-on')
                                : t('No Add-on')
                            }
                          />
                        )}
                      </Field>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </>
                  )}
                </Field>
              </div>
            ))}

            <Button
              color="primary"
              onClick={() => {
                fields.push({
                  productId: '',
                  addOnKey: '',
                });
              }}
              text={t('Add')}
            />
          </>
        )}
      </FieldArray>
    </div>
  );
};

const ProductIds = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const formState = useFormState();

  const excludeProductIds = useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.addOns?.map(
      (addOn: any) => addOn.productId
    );
  }, [scheduleIndex, formState.values]);

  const candidateProducts = useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  return (
    <div className={styles['p-manifests__productListInput']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div
                key={name}
                className={
                  styles['p-manifestsModalCustomize__item__body__flex']
                }
              >
                <Field name={name} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <>
                      <SingleDropdown
                        searchable={true}
                        options={(candidateProducts ?? [])
                          .filter(
                            (p) =>
                              !fields.value.includes(p.id) ||
                              p.id === inputProductId.value
                          )
                          .map((p) => ({
                            value: p.id,
                            text: getVerboseDisplayProductName(p),
                          }))}
                        selectedOption={inputProductId.value ?? ''}
                        onChange={(value) => {
                          inputProductId.onChange(value);
                        }}
                      />
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </>
                  )}
                </Field>
              </div>
            ))}

            <Button
              color="primary"
              onClick={() => {
                fields.push('');
              }}
              text={t('Add')}
            />
          </>
        )}
      </FieldArray>
    </div>
  );
};
