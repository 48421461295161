import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { calculateRebookFromReservationFares } from 'client/actions/fares';
import { separatedCurrency as currency } from 'shared/libraries/currency';
import { FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import type { NewReservation, Reservation } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  rebookFromReservation: Reservation;
};
export const AmountDifference = ({ rebookFromReservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );
  const rebookFromReservationBillingInfo = useSelector(
    (state: ReduxState) => state.fares.rebookFromReservationBillingInfo
  );
  React.useEffect(() => {
    if (!rebookFromReservation?.billing_info?.amount_gross) {
      dispatch(
        calculateRebookFromReservationFares({
          ...(rebookFromReservation as any as NewReservation),
          agent_reference: 'dummy-reference-amount-difference',
        })
      );
    }
  }, [rebookFromReservation]);

  if (
    !billingInfo ||
    !(
      rebookFromReservation.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo
    )
  ) {
    return null;
  }

  const totalAfter = currency(billingInfo?.amount_gross ?? '').format();
  const totalBefore = currency(
    rebookFromReservation?.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo?.amount_gross ||
      ''
  ).format();

  if (totalAfter == totalBefore) {
    return null;
  }

  const TableContent = () => {
    return (
      <div className={clsx(componentStyles['c-table-nowrap'])}>
        <table>
          <tbody>
            <tr>
              <th className={clsx(baseStyles['base-t-32'])}>
                {t('Total before')}
              </th>
              <td className={clsx(baseStyles['base-t-128'])}>{totalBefore}</td>
            </tr>
            <tr>
              <th className={clsx(baseStyles['base-t-32'])}>
                {t('Total after')}
              </th>
              <td className={clsx(baseStyles['base-t-128'])}>{totalAfter}</td>
            </tr>
            <tr>
              <th className={clsx(baseStyles['base-t-32'])}>
                {t('Difference')}
              </th>
              <td className={clsx(baseStyles['base-t-128'])}>
                {currency(totalAfter).subtract(totalBefore).format()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <FieldWrapper label={t('Total Gross Difference before & after the change')}>
      <TableContent />
    </FieldWrapper>
  );
};
