import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Select } from 'client/components/Form';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { CustomPageList } from 'client/pages/EssentialPages/CustomPages/CustomPageList';
import type { SourceLanguage } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type TabType = 'CUSTOM_PAGE' | 'NEWS';
export const CustomPages = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [activeTab, setActiveTab] = React.useState<TabType>('CUSTOM_PAGE');
  const [contentLanguage, setContentLanguage] =
    React.useState<SourceLanguage | null>(null);
  const [supportLanguages, setSupportLanguages] = React.useState<
    SourceLanguage[]
  >([]);
  React.useEffect(() => {
    setSupportLanguages(
      getBookingWidgetPmpSupportLanguages(activeUserOrganization)
    );
  }, [activeUserOrganization]);
  React.useEffect(() => {
    if (supportLanguages.length > 0) {
      setContentLanguage(supportLanguages[0]);
    }
  }, [supportLanguages]);
  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));
  const selectableContentLanguageOptions = languageOptions.filter((option) => {
    return supportLanguages.includes(option.value);
  });
  return (
    <>
      <div className={baseStyles['base-main__body__header']}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spSpacebetween'],
            baseStyles['spOrder-1']
          )}
        >
          <Select
            label={t('Content Language')}
            width={176}
            options={selectableContentLanguageOptions}
            onChange={(e, { value }) => {
              setContentLanguage(value as any);
            }}
            value={contentLanguage ?? ''}
          />
        </div>
      </div>

      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'CUSTOM_PAGE' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CUSTOM_PAGE');
            }}
          >
            <a>{t('Custom Pages')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'NEWS' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('NEWS');
            }}
          >
            <a>{t('News')}</a>
          </li>
        </ul>

        <CustomPageList
          isActive={true}
          type={activeTab === 'CUSTOM_PAGE' ? 'NORMAL' : 'NEWS'}
          contentLanguage={contentLanguage}
        />
      </div>
    </>
  );
};
