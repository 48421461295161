import { useTranslation } from 'react-i18next';

import { TranslateFuncType } from 'client/components/Translate';
import { Loading } from 'client/components/v3/Common/Loading';
import { Reservation } from 'shared/models/swagger';

import { CustomerReservationData } from '../type';
import { getAnnualPassData } from '../util';

import { Gadget } from './Gadget';
import { DonutChart } from './DonutChart';
import styles from './Gadget.module.css';

type Props = {
  data: CustomerReservationData[] | null;
};

// Function to return the highest number of renewals for a customer
const getHighestRenewalNumber = (
  customerRenewals: {
    initialReservationId: string;
    numberOfRenewals: number;
  }[]
): number => {
  if (customerRenewals.length === 0) return 0;

  return customerRenewals.reduce(
    (max, current) =>
      current.numberOfRenewals > max ? current.numberOfRenewals : max,
    customerRenewals[0].numberOfRenewals
  );
};

const getChartData = (
  data: CustomerReservationData[] | null,
  t: TranslateFuncType
) => {
  if (!data) {
    return null;
  }

  // Filter only annual pass reservations
  const annualPassData = getAnnualPassData(data);

  // Filter customers who have made reservations
  const customersWithAnnualPass = annualPassData.filter(
    (customerReservationData) => customerReservationData.reservations.length > 0
  );

  // For each customer, find reservations with continuing reservation id
  // If annual_pass_mode_info is not provided, consider it as annual pass not renewed
  // If annual_pass_mode_info is provided, follow the chain of continuation then calculate how many renewals
  const renewalCounts: { [customerId: string]: number } = {};
  for (const customerReservationData of customersWithAnnualPass) {
    const reservationMap: { [id: string]: Reservation } =
      customerReservationData.reservations.reduce((map, reservation) => {
        map[reservation.id] = reservation;
        return map;
      }, {} as { [id: string]: Reservation });

    const customerRenewals: {
      initialReservationId: string;
      numberOfRenewals: number;
    }[] = [];

    // A customer may have multiple annual passes so we count these passes individually and take the highest number for each customer
    // For example, customer A has annual pass X with 2 renewals and annual pass Y with 1 renewal
    // Thus, customer A -> 2 renewals
    for (const reservation of customerReservationData.reservations) {
      // If reservation status is not CONFIRMED, classify as not purchased
      if (reservation.status !== 'CONFIRMED') {
        continue;
      }

      if (reservation.annual_pass_mode_info) {
        let currentReservation = reservation;
        let chainLength = 0;

        while (
          currentReservation.annual_pass_mode_info?.continuing_reservation_id &&
          reservationMap[
            currentReservation.annual_pass_mode_info.continuing_reservation_id
          ]
        ) {
          const nextReservation =
            reservationMap[
              currentReservation.annual_pass_mode_info.continuing_reservation_id
            ];

          const isValidReservation = nextReservation.status_history.some(
            (statusHistory) => {
              if (statusHistory.status !== 'CONFIRMED') return false;
              return true;
            }
          );

          if (!isValidReservation) {
            break;
          }

          chainLength++;
          currentReservation = nextReservation;
        }

        customerRenewals.push({
          initialReservationId: reservation.id,
          numberOfRenewals: chainLength,
        });
      }
    }

    const highestRenewal = getHighestRenewalNumber(customerRenewals);
    renewalCounts[customerReservationData.customer.id ?? ''] = highestRenewal;
  }

  let zeroTimes = 0;
  let oneTime = 0;
  let twoTimes = 0;
  let threeTimesAndAbove = 0;

  Object.values(renewalCounts).forEach((count) => {
    if (count === 0) {
      zeroTimes++;
    } else if (count === 1) {
      oneTime++;
    } else if (count === 2) {
      twoTimes++;
    } else {
      threeTimesAndAbove++;
    }
  });

  return [
    {
      name: t('{{count}} time_and_above', { count: 3 }),
      value: threeTimesAndAbove,
    },
    {
      name: t('{{count}} time', { count: 2 }),
      value: twoTimes,
    },
    {
      name: t('{{count}} time', { count: 1 }),
      value: oneTime,
    },
    {
      name: t('{{count}} time', { count: 0 }),
      value: zeroTimes,
    },
  ];
};

const COLORS = ['#10B981', '#6EE7B7', '#D1FAE5', '#ECFDF5'];

export const AnnualPassRenewalChartGadget = ({ data }: Props) => {
  const { t } = useTranslation();

  const chartData = getChartData(data, t);

  let noDataToDisplay = false;
  if (chartData && chartData.every((data) => data.value === 0)) {
    noDataToDisplay = true;
  }

  return (
    <Gadget
      title={t('Percentage of Number of Annual Pass Renewals')}
      tooltipText={t(
        'Display the breakdown of annual pass renewals for members who have purchased an annual pass'
      )}
      positionTooltipToTheLeft={true}
    >
      <div className={styles['c-gadget__body_chart']}>
        {!chartData && <Loading size="md" />}
        {noDataToDisplay && (
          <div className={styles['noData']}>
            <p>{t('No data to display for the selected data set')}</p>
          </div>
        )}
        {chartData && !noDataToDisplay && (
          <DonutChart data={chartData} colors={COLORS} />
        )}
      </div>
    </Gadget>
  );
};
