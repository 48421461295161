import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { deleteProductAgentReference } from 'client/actions/productAgentReferences';

interface Props {
  id: string;
  name: string;
}

export const DeleteProductAgentReferenceButton = ({ id, name }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete reverse-mapping')}
          content={t(
            'Are you sure you want to delete reverse mapping "{{name}}"?',
            {
              name,
            }
          )}
          loading={loading}
          onConfirm={async () => {
            setLoading(true);
            await dispatch(deleteProductAgentReference(id));
            setLoading(false);
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}
      <Icon
        link
        color="red"
        name="delete"
        onClick={() => setShowDeleteModal(true)}
      />
    </>
  );
};
