import React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { SeatMap } from 'client/components/Seat/SeatMap';
import {
  getEquipmentStartTimeMapping,
  filterProductInstancesByProductMapping,
} from 'client/components/Seat/utils';
import { ProductInstance } from 'shared/models/swagger';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentsSelector } from 'client/reducers/equipments';
import { Modal } from 'client/components/Modal/Modal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import type { ReduxState } from 'client/reducers';
import { fetchProductInstances } from 'client/actions/productInstances';
import { batchFetchEquipmentAssignmentsByProductInstanceIds } from 'client/actions/equipmentAssignments';
import { Message } from 'client/components/Message/Message';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedEquipmentInstanceId: string;
  keepShowingEquipmentInstanceSelector?: boolean;
}

export const SeatSelectModal = ({
  open,
  onClose,
  selectedEquipmentInstanceId,
  keepShowingEquipmentInstanceSelector,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productInstanceById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );

  const [modalPosition, setModalPosition] = React.useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (instance) => instance.id === selectedEquipmentInstanceId
    );
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const selectedEquipmentStartTimeMapping = React.useMemo(() => {
    const equipment = (equipments ?? []).find(
      (equipment) => equipment.id === selectedEquipmentInstance?.equipment_id
    );
    return getEquipmentStartTimeMapping(
      selectedEquipmentInstance?.date ?? '',
      selectedEquipmentInstance?.start_time_key ?? '',
      equipment
    );
  }, [equipments, selectedEquipmentInstance]);

  React.useEffect(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return;
    }
    if (
      (selectedEquipmentStartTimeMapping.product_start_times ?? []).length == 0
    ) {
      return;
    }
    const startDate = moment.tz(
      selectedEquipmentInstance?.date ?? '',
      activeUserOrganization?.default_timezone ?? 'UTC'
    );
    const endDate = startDate.clone().add(1, 'day');
    (selectedEquipmentStartTimeMapping?.product_start_times || []).forEach(
      (time) => {
        if (!time.product_id) {
          return;
        }
        dispatch(fetchProductInstances(time.product_id, startDate, endDate));
      }
    );
  }, [selectedEquipmentStartTimeMapping]);

  React.useEffect(() => {
    fetchEquipmentAssignmentsHandler();
  }, [selectedEquipmentInstanceId]);

  const selectedProductInstances = React.useMemo(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return [] as ProductInstance[];
    }
    if (!productInstanceById) {
      return [] as ProductInstance[];
    }
    return (
      selectedEquipmentStartTimeMapping?.product_start_times ?? []
    ).reduce((acc: ProductInstance[], time) => {
      const filteredProductInstances = filterProductInstancesByProductMapping(
        time.product_id ?? '',
        time.recurrence_key ?? '',
        time.time_slot_key ?? '',
        selectedEquipmentInstance?.date ?? '',
        activeUserOrganization?.default_timezone ?? 'UTC',
        Object.values(productInstanceById)
      );
      acc.push(...filteredProductInstances);
      return acc;
    }, [] as ProductInstance[]);
  }, [productInstanceById, selectedEquipmentStartTimeMapping]);

  const relatedEquipmentAssignments = equipmentAssignments;

  const relatedReservationIds = React.useMemo(() => {
    return [
      ...new Set(relatedEquipmentAssignments.map((a) => a.reservation_id)),
    ];
  }, [relatedEquipmentAssignments]);

  const filteredReservations = React.useMemo(() => {
    return reservations.filter((reservation) => {
      return relatedReservationIds.includes(reservation.id);
    });
  }, [relatedReservationIds, reservations]);

  const changeModalPositionHandler = (args: { top: number; left: number }) => {
    if (modalPosition.top === args.top && modalPosition.left === args.left) {
      return;
    }
    setModalPosition(args);
  };

  const fetchEquipmentAssignmentsHandler = () => {
    if (!selectedProductInstances) {
      return;
    }

    if (selectedProductInstances.length === 0) {
      return;
    }

    const productInstanceIds = selectedProductInstances.map(
      (instance) => instance.id
    );

    dispatch(
      batchFetchEquipmentAssignmentsByProductInstanceIds(productInstanceIds)
    );
  };

  return (
    <Modal
      title={`${t('Select Seat')}`}
      open={open}
      onClose={onClose}
      onPositionChange={changeModalPositionHandler}
      activateTouchEvents={true}
    >
      <Modal.Content>
        <Message
          error
          header={t(
            'The selected seat may already be occupied by another reservation. Please reselect your seat.'
          )}
        />

        <div style={{ backgroundColor: '#fff' }}>
          <SeatMap
            selectedEquipmentInstanceId={selectedEquipmentInstanceId}
            positionOffset={modalPosition}
            filteredReservations={filteredReservations}
            filteredEquipmentAssignments={relatedEquipmentAssignments}
            keepShowingEquipmentInstanceSelector={
              keepShowingEquipmentInstanceSelector
            }
            hideEquipmentInstanceCloseButton={true}
            hideEquipmentInstanceEditButton={true}
            showInModal={true}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          type={'button'}
          onClick={() => {
            onClose();
          }}
        >
          {t('Confirm')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
