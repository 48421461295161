import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useCallback, useContext, useState } from 'react';

import { config } from 'client/config';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getValidators } from 'shared/libraries/validate/validator';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { PerParticipantPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerGroupPriceInput';
import type { Product } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { getScheduleText } from 'client/pages/ProductEditor/util';
import { TextField } from 'client/components/v3/Form/TextField';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import baseStyles from 'client/v3-base.module.css';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';

const getDefaultPerParticipantPrices = (
  product: Product | null,
  t: TranslateFuncType
) => {
  const language = product?.source_language
    ? uppercaseIsoToLowercaseIso[product.source_language]
    : 'ja';

  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units && units.some((unit) => unit.method === 'PER_PARTICIPANT')) {
    return units
      .filter((unit) => unit.method === 'PER_PARTICIPANT')
      .map((unit) => ({
        method: unit.method,
        unit: unit?.guest_type?.key ?? '',
        ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
        ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
        weight: unit?.guest_type?.minimum_participant_parameters
          ? unit?.guest_type?.minimum_participant_parameters.weight || 0
          : 1,
        price: '100',
        netPrice: '100',
      }));
  }

  return [
    {
      method: 'PER_PARTICIPANT',
      unit: t('Adult', { lng: language }),
      ageBandMinimum: 13,
      ageBandMaximum: 0,
      price: '100',
      netPrice: '100',
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Child', { lng: language }),
      ageBandMinimum: 4,
      ageBandMaximum: 12,
      price: '70',
      netPrice: '70',
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Infant', { lng: language }),
      ageBandMinimum: 0,
      ageBandMaximum: 3,
      price: '0',
      netPrice: '0',
    },
  ];
};

const getDefaultPerBookingPrices = () => {
  return [
    {
      method: 'PER_BOOKING',
      price: '100',
      netPrice: '100',
    },
  ];
};

export const PricesEditor = () => {
  const [activePriceIndex, setActivePriceIndex] = useState<number>(0);

  const { t } = useTranslation();

  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  const editingProduct = useContext(EditingProductContext);
  const language = editingProduct?.source_language
    ? uppercaseIsoToLowercaseIso[editingProduct.source_language]
    : 'ja';

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const { required } = getValidators(t);

  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);

  const validateSurchargeRate = useCallback(
    (value: string | null | typeof undefined) => {
      if (!value) {
        return t('Required');
      }

      if (!/^[0-9]*\.?[0-9]*$/.test(value)) {
        return t('Must be a number between 0 and 100');
      }
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) {
        return t('Must be a number between 0 and 100');
      }
      if (parsedValue < 0 || parsedValue > 100) {
        return t('Must be a number between 0 and 100');
      }
      return undefined;
    },
    []
  );

  return (
    <FieldArray name="priceSchedules">
      {({ fields }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <SingleDropdown
                options={fields.value.map((priceRule, idx) => ({
                  text: getScheduleText(priceRule, t),
                  value: idx.toString(),
                }))}
                selectedOption={activePriceIndex.toString()}
                onChange={(e) => {
                  setActivePriceIndex(parseInt(e));
                }}
              />
              <a
                className={styles['p-products__section__body__add']}
                onClick={() => {
                  fields.push({
                    dateRange: {
                      startDate: defaultStartDate,
                      endDate: defaultEndDate,
                    },
                    weekdays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
                    method: 'PER_PARTICIPANT',
                    prices: getDefaultPerParticipantPrices(editingProduct, t),
                    perGroupPrices: [],
                  });
                  setActivePriceIndex(fields.length ?? 0);
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Price Schedule')}
              </a>
            </div>
          </div>

          <div key={activePriceIndex} className={styles['p-frame']}>
            <div className={styles['p-frame__header']}>
              <p className={styles['p-frame__header__ttl']}>
                {t('Selected Price Schedule')}
              </p>
              <div className={styles['p-frame__header__actions']}>
                {fields.value.length > 1 && (
                  <Button
                    text={t('Remove this price schedule')}
                    size="sm"
                    color="tertiarygray"
                    onClick={() => {
                      fields.remove(activePriceIndex);
                      setActivePriceIndex(0);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                    style={{ color: 'var(--error600)' }}
                  />
                )}
              </div>
            </div>
            <div className={styles['p-frame__body']}>
              <ul className={styles['p-list']}>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      <div>{t('Title')}</div>
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name={`${fields.name}.${activePriceIndex}.title`}>
                      {({ input }) => <TextField {...input} />}
                    </Field>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      <div>{t('Date Range')}</div>
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <div className={styles['p-list__item__body__calendar']}>
                      <Field
                        name={`${fields.name}.${activePriceIndex}.dateRange`}
                      >
                        {({ input }) => {
                          return (
                            <DateRangeInput
                              onChange={(value) => {
                                if (value[0] !== null && value[1] !== null) {
                                  input.onChange({
                                    startDate: value[0],
                                    endDate: value[1],
                                  });
                                } else {
                                  input.onChange({
                                    startDate: value[0]
                                      ? value[0]
                                      : input.value.startDate,
                                    endDate: value[1]
                                      ? value[1]
                                      : input.value.endDate,
                                  });
                                }
                              }}
                              dateFrom={input.value.startDate as string}
                              dateTo={input.value.endDate as string}
                            />
                          );
                        }}
                      </Field>
                    </div>

                    <p className={baseStyles['u-mt-2']}>
                      {t(
                        '* Both price and "Participation Rules" in Reservation Parameters tab are required to become available on the calendars. Please make sure price and participation rules date ranges are covered.'
                      )}
                    </p>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      <div>{t('Days of Week')}</div>
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name={`${fields.name}.${activePriceIndex}.weekdays`}>
                      {({ input }) => (
                        <WeekdaysInput
                          value={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      <div>{t('Prices')}</div>
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name={`${fields.name}.${activePriceIndex}.prices`}>
                      {({ input }) => (
                        <EnumRadioButtonGroup
                          name={`${fields.name}.${activePriceIndex}.method`}
                          options={[
                            {
                              value: 'PER_PARTICIPANT',
                              label: t('Per-participant'),
                              tooltipText: t(
                                'Used when setting the price for each participant (Price setting per person)'
                              ),
                            },
                            {
                              value: 'PER_BOOKING',
                              label: t('Per-booking'),
                              tooltipText: t(
                                'Used when setting the price for each reservation (Price setting per reservation)'
                              ),
                            },
                          ]}
                          onChange={(newValue: string) => {
                            switch (newValue) {
                              case 'PER_PARTICIPANT':
                                input.onChange(
                                  getDefaultPerParticipantPrices(
                                    editingProduct,
                                    t
                                  )
                                );
                                break;
                              case 'PER_BOOKING':
                                input.onChange(getDefaultPerBookingPrices());
                                break;
                              default:
                                input.onChange([]);
                                break;
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <Field name={`${fields.name}.${activePriceIndex}.method`}>
                    {({ input: { value } }) => (
                      <>
                        {value === 'PER_PARTICIPANT' && (
                          <>
                            <PerParticipantPriceInput
                              showCustom
                              name={`${fields.name}.${activePriceIndex}.prices`}
                              language={language}
                              currency={defaultCurrency}
                              showNet={true}
                            />
                            <PerGroupPriceInput
                              name={`${fields.name}.${activePriceIndex}.perGroupPrices`}
                              currency={defaultCurrency}
                              showNet={true}
                            />
                          </>
                        )}
                        {value === 'PER_BOOKING' && (
                          <PerBookingPriceInput
                            name={`${fields.name}.${activePriceIndex}.prices`}
                            language={language}
                            currency={defaultCurrency}
                            showNet={true}
                          />
                        )}
                      </>
                    )}
                  </Field>
                </li>
              </ul>
              <div className={styles['p-box']}>
                <Field
                  name={`${fields.name}.${activePriceIndex}.applySurcharge`}
                >
                  {({ input: applySurchargeToggleInput }) => (
                    <>
                      <Toggle
                        label={t('Apply surcharge for these dates')}
                        checked={applySurchargeToggleInput.value}
                        onChange={() =>
                          applySurchargeToggleInput.onChange(
                            !applySurchargeToggleInput.value
                          )
                        }
                      />

                      {applySurchargeToggleInput.value && (
                        <div className={styles['p-box__list']}>
                          <TranslatedField
                            name={`${fields.name}.${activePriceIndex}.surchargeTitle`}
                            validate={required}
                          >
                            {({
                              input,
                              meta: { touched, error },
                              translationInput,
                            }) => (
                              <div className={styles['p-box__list__item']}>
                                <div
                                  className={styles['p-box__list__item__ttl']}
                                >
                                  {t('Surcharge Title')}
                                </div>
                                <div
                                  className={styles['p-box__list__item__body']}
                                >
                                  <>
                                    <TextField
                                      {...input}
                                      type="text"
                                      error={touched && error}
                                    />
                                    {translationTargetLanguage != null && (
                                      <TextField
                                        {...translationInput}
                                        placeholder={
                                          translationTargetLanguageName
                                        }
                                        type="text"
                                      />
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                          </TranslatedField>

                          <div className={styles['p-box__list__item']}>
                            <div className={styles['p-box__list__item__ttl']}>
                              {t('Surcharge Percent (1-100)')}
                            </div>
                            <div className={styles['p-box__list__item__body']}>
                              <>
                                <Field
                                  name={`${fields.name}.${activePriceIndex}.surchargeRate`}
                                  validate={validateSurchargeRate}
                                >
                                  {({ input, meta: { touched, error } }) => (
                                    <TextField
                                      value={input.value ?? ''}
                                      onChange={input.onChange}
                                      error={touched && error}
                                    />
                                  )}
                                </Field>
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>
              {config.enableProductMinimumPrice && (
                <div className={styles['p-box']}>
                  <Field
                    name={`${fields.name}.${activePriceIndex}.applyMinimumPrice`}
                  >
                    {({ input: minimumPriceToggleInput }) => (
                      <>
                        <Toggle
                          label={t('Use a minimum price for these dates')}
                          checked={minimumPriceToggleInput.value}
                          onChange={() =>
                            minimumPriceToggleInput.onChange(
                              !minimumPriceToggleInput.value
                            )
                          }
                        />

                        {minimumPriceToggleInput.value && (
                          <div className={styles['p-box__list']}>
                            <div className={styles['p-box__list__item']}>
                              <div className={styles['p-box__list__item__ttl']}>
                                {t('Amount (tax included {{currency}})', {
                                  currency: defaultCurrency,
                                })}
                              </div>
                              <div
                                className={styles['p-box__list__item__body']}
                              >
                                <div
                                  className={styles['p-box__item__currency']}
                                >
                                  <Field
                                    name={`${fields.name}.${activePriceIndex}.minimumGross`}
                                    validate={required}
                                  >
                                    {({ input, meta: { touched, error } }) => (
                                      <TextField
                                        value={input.value}
                                        onChange={(value) => {
                                          if (
                                            !currencyInputAllowed(
                                              defaultCurrency,
                                              value
                                            )
                                          ) {
                                            return;
                                          }

                                          input.onChange(value);
                                        }}
                                        error={touched && error}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className={styles['p-box__list__item']}>
                              <div className={styles['p-box__list__item__ttl']}>
                                {t('Net Amount ({{currency}})', {
                                  currency: defaultCurrency,
                                })}
                              </div>
                              <div
                                className={styles['p-box__list__item__body']}
                              >
                                <div
                                  className={styles['p-box__item__currency']}
                                >
                                  <Field
                                    name={`${fields.name}.${activePriceIndex}.minimumNet`}
                                    validate={required}
                                  >
                                    {({ input, meta: { touched, error } }) => (
                                      <TextField
                                        value={input.value}
                                        onChange={(value) => {
                                          if (
                                            !currencyInputAllowed(
                                              defaultCurrency,
                                              value
                                            )
                                          ) {
                                            return;
                                          }

                                          input.onChange(value);
                                        }}
                                        error={touched && error}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
