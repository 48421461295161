import * as React from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FieldWrapper } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { fetchInquiry } from 'client/actions/inquiries';
import { BulkInquiryReservationList } from 'client/components/InquiryItem/BulkInquiryReservationList';
import { Loading } from 'client/pages/Loading/Loading';
import baseStyles from 'client/base.module.css';

import { FormValues } from './formValues';

export const Complete = () => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;
  const dispatch = useDispatch();

  const submittedInquiry = useSelector(
    (state: ReduxState) => state.inquiries.lastSubmittedBulkInquiry
  );
  const inquiry = useSelector((state: ReduxState) => state.inquiries.details);

  React.useEffect(() => {
    if (submittedInquiry?.id) {
      const inquiryRefetch = setTimeout(() => {
        dispatch(fetchInquiry(submittedInquiry.id ?? ''));
      }, 3000);
      return () => {
        clearTimeout(inquiryRefetch);
      };
    }
  }, [submittedInquiry?.id]);

  return (
    <div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Bulk SMS Notification Has Been Sent')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Box mt={2}>
            <FieldWrapper label={t('Message')}>
              <div className="newline">{values.message}</div>
            </FieldWrapper>
          </Box>
          <Box mt={2}>
            {!inquiry || inquiry.id !== submittedInquiry?.id ? (
              <Loading />
            ) : (
              <BulkInquiryReservationList inquiry={inquiry} />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};
