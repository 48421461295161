import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';
import { FieldArray } from 'react-final-form-arrays';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateAboutUsSettings } from 'client/actions/essentialPages';
import { Message } from 'client/components/Message/Message';
import { Button, Input, TextArea, ToggleButton } from 'client/components/Form';
import { Loading } from 'client/pages/Loading';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { getArrayMutators } from 'client/libraries/util/form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import { Editor } from 'client/components/Editor/Editor';
import type { SourceLanguage, AboutUsSettings } from 'shared/models/swagger';
import * as Swagger from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './AboutUsEditor.module.css';

const focusOnError: any = createDecorator();
export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};
type MessageTranslationsFormValues = {
  message_body: string;
  trans_message_body: string;
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

export const AboutUsEditor = () => {
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const dispatch = useDispatch();
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);

  // supplier not always support EN_US
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>(
      getBookingWidgetPmpSupportLanguages(activeUserOrganization).find(
        (supportedLanguage) => supportedLanguage !== defaultLanguage
      ) || 'EN_US'
    );

  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);
  const [transEditorJsLoading, setTransEditorJsLoading] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (showTranslationMode) {
      const aboutUsSettings =
        activeUserOrganization?.essential_pages?.about_us_settings;

      if (aboutUsSettings) {
        const texts = [];

        if (aboutUsSettings.title) {
          texts.push(aboutUsSettings.title);
        }

        (aboutUsSettings.other_company_profiles || []).forEach((profile) => {
          if (profile.key) {
            texts.push(profile.key);
          }

          if (profile.value) {
            texts.push(profile.value);
          }
        });
        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);
  const initialValuesWithTranslations = React.useMemo(() => {
    const messages =
      activeUserOrganization?.essential_pages?.about_us_settings?.messages ||
      [];
    const defaultLanguageMessage = messages.find(
      (message) => message.content_language === defaultLanguage
    );
    const translationTargetLanguageMessage = messages.find(
      (message) => message.content_language === translationTargetLanguage
    );
    return {
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      ...activeUserOrganization?.essential_pages?.about_us_settings,
      message_body: JSON.parse(defaultLanguageMessage?.body || '{}'),
      trans_message_body: JSON.parse(
        translationTargetLanguageMessage?.body || '{}'
      ),
    };
  }, [
    apiTranslations,
    translationTargetLanguage,
    activeUserOrganization,
    defaultLanguage,
  ]);

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const getKeyVisualPath = (filename: string): string => {
    return `essential/supplier/${activeUserOrganization?.id}/${filename}`;
  };

  const changeShowTranslationMode = (show: boolean) => {
    setShowTranslationMode(show);
  };

  const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
    setTranslationTargetLanguage(lang);
  };

  return (
    <div className={baseStyles['base-main__body']}>
      <SettingTranslationLanguageSelector
        showTranslationMode={showTranslationMode}
        onShowTranslationModeChange={changeShowTranslationMode}
        translationTargetLanguage={translationTargetLanguage}
        onTranslationTargetLanguageChange={changeTranslationTargetLanguage}
      />
      <Form
        onSubmit={async (
          values: AboutUsSettings &
            TranslationsFormValues &
            MessageTranslationsFormValues
        ) => {
          const previousMessages =
            activeUserOrganization?.essential_pages?.about_us_settings
              ?.messages;
          let messages = previousMessages ? [...previousMessages] : [];
          messages = [
            ...messages.filter(
              (message) => message.content_language !== defaultLanguage
            ),
            {
              content_language: defaultLanguage,
              body: JSON.stringify(values.message_body),
            },
          ];

          if (showTranslationMode) {
            messages = [
              ...messages.filter(
                (message) =>
                  message.content_language !== translationTargetLanguage
              ),
              {
                content_language: translationTargetLanguage,
                body: JSON.stringify(values.trans_message_body),
              },
            ];
          }

          values.messages = messages;
          const promises = [dispatch(updateAboutUsSettings(values))];

          if (showTranslationMode) {
            promises.push(
              dispatch(
                updateTranslations(
                  values.translations.map((translation: any) => ({
                    source_language: defaultLanguage,
                    [defaultLanguage.toLowerCase()]: translation.source,
                    [translationTargetLanguage.toLowerCase()]:
                      translation.target,
                  }))
                )
              ) as any
            );
          }

          try {
            await Promise.all(promises);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
        initialValues={initialValuesWithTranslations}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, values, form, submitting }) => {
          React.useEffect(() => {
            let translations: { source: string; target: string }[] = [];

            if (defaultLanguage && translationTargetLanguage) {
              const defaultFieldName = defaultLanguage.toLowerCase();
              const translationFieldName =
                translationTargetLanguage.toLowerCase();
              translations = apiTranslations.map((trans: any) => ({
                source: trans[defaultFieldName],
                target: trans[translationFieldName],
              }));
            }

            form.change('translations', translations);
            const messages =
              activeUserOrganization?.essential_pages?.about_us_settings
                ?.messages;

            if (messages) {
              const translationTargetMessage = messages.find(
                (message) =>
                  message.content_language === translationTargetLanguage
              );

              form.change(
                'trans_message_body',
                JSON.parse(translationTargetMessage?.body || '{}')
              );
            }
          }, [
            defaultLanguage,
            apiTranslations,
            translationTargetLanguage,
            showTranslationMode,
          ]);
          return (
            <form onSubmit={handleSubmit}>
              {false && <pre>{JSON.stringify(values, undefined, 2)}</pre>}
              <div className={baseStyles['base-main__body__box']}>
                <div className={baseStyles['base-main__body__box__body']}>
                  <div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tbody>
                          <tr>
                            <th>{t('Visibility')}</th>
                            <td>
                              <Field name="visibility" type="checkbox">
                                {({ input }) => (
                                  <div>
                                    <ToggleButton
                                      label={t(
                                        'Show About Us page on booking website'
                                      )}
                                      {...input}
                                    />
                                  </div>
                                )}
                              </Field>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Title')}</th>

                            <td>
                              <div
                                className={
                                  styles['scheds__body__content__body']
                                }
                              >
                                <TranslatedField name="title">
                                  {({
                                    input,
                                    translationInput,
                                    meta: { error, touched },
                                  }) => (
                                    <>
                                      <div
                                        className={styles['scheds__body__form']}
                                      >
                                        <p className={styles['scheds__body']}>
                                          {' '}
                                          <Input
                                            {...input}
                                            {...{
                                              height: 100,
                                            }}
                                            error={touched && error}
                                          />{' '}
                                        </p>
                                      </div>
                                      {showTranslationMode && (
                                        <div
                                          className={
                                            styles['scheds__body__form']
                                          }
                                        >
                                          <p className={styles['scheds__body']}>
                                            {' '}
                                            <Input
                                              {...translationInput}
                                              {...{
                                                height: 100,
                                              }}
                                              placeholder={getLanguageName(
                                                uppercaseIsoToLowercaseIso[
                                                  translationTargetLanguage
                                                ],
                                                t
                                              )}
                                              error={touched && error}
                                            />{' '}
                                          </p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </TranslatedField>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Key visual')}</th>
                            <td>
                              <Field name="key_visual">
                                {({ input }) => (
                                  <SingleImageInput
                                    acceptedFileTypes={[
                                      'image/jpeg',
                                      'image/png',
                                    ]}
                                    onUploadFinished={(filename, objectUrl) =>
                                      input.onChange(objectUrl)
                                    }
                                    initialValue={input.value}
                                    getFileSavePath={(filename) =>
                                      getKeyVisualPath(filename)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Message')}</th>
                            <td>
                              {showTranslationMode && (
                                <p className={styles['scheds__ttl']}>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[defaultLanguage],
                                    t
                                  )}
                                </p>
                              )}
                              <Field name="message_body">
                                {({ input }) => (
                                  <Editor
                                    imageS3Prefix={`editor/supplier/${
                                      activeUserOrganization?.id || ''
                                    }`}
                                    data={input.value}
                                    onChange={(data: any, loading) => {
                                      setEditorJsLoading(loading);
                                      input.onChange(data);
                                    }}
                                  />
                                )}
                              </Field>
                              {showTranslationMode && (
                                <>
                                  <p className={styles['scheds__ttl']}>
                                    {getLanguageName(
                                      uppercaseIsoToLowercaseIso[
                                        translationTargetLanguage
                                      ],
                                      t
                                    )}
                                  </p>
                                  <Field name="trans_message_body">
                                    {({ input }) => (
                                      <Editor
                                        imageS3Prefix={`editor/supplier/${
                                          activeUserOrganization?.id || ''
                                        }`}
                                        data={input.value}
                                        onChange={(data: any, loading) => {
                                          setTransEditorJsLoading(loading);
                                          input.onChange(data);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </>
                              )}
                              {t(
                                '* Copy and paste URL of YouTube videos to show YouTube videos in the massage.'
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              {t('Other Company Profile')}
                              <Tooltip
                                text={t(
                                  'In addition to the company information that has already been registered, you can register the date of establishment, representative name, etc.'
                                )}
                              />
                            </th>
                            <td>
                              <div className={styles['scheds__body']}>
                                <FieldArray name={`other_company_profiles`}>
                                  {({ fields }) => {
                                    return fields.length === 0 ? (
                                      <AddIcon
                                        onClick={() =>
                                          (fields as any).insertAt(0, '')
                                        }
                                      />
                                    ) : (
                                      <table
                                        className={
                                          styles['scheds__body__content']
                                        }
                                      >
                                        {fields.map((name, formIdx) => (
                                          <tr key={formIdx}>
                                            <td>
                                              <div
                                                className={
                                                  styles[
                                                    'scheds__body__content__body'
                                                  ]
                                                }
                                              >
                                                <TranslatedField
                                                  name={`${name}.key`}
                                                >
                                                  {({
                                                    input,
                                                    translationInput,
                                                    meta: { error, touched },
                                                  }) => (
                                                    <>
                                                      <div
                                                        className={
                                                          styles[
                                                            'scheds__body__form'
                                                          ]
                                                        }
                                                      >
                                                        <p
                                                          className={
                                                            styles[
                                                              'scheds__ttl'
                                                            ]
                                                          }
                                                        >
                                                          {' '}
                                                          {t('Title')}{' '}
                                                        </p>
                                                        <p
                                                          className={
                                                            styles[
                                                              'scheds__body'
                                                            ]
                                                          }
                                                        >
                                                          {' '}
                                                          <Input
                                                            {...input}
                                                            error={
                                                              touched && error
                                                            }
                                                          />{' '}
                                                        </p>
                                                      </div>
                                                      {showTranslationMode && (
                                                        <div
                                                          className={
                                                            styles[
                                                              'scheds__body__form'
                                                            ]
                                                          }
                                                        >
                                                          <p
                                                            className={
                                                              styles[
                                                                'scheds__ttl'
                                                              ]
                                                            }
                                                          >
                                                            {' '}
                                                            {`${t(
                                                              'Title'
                                                            )} (${getLanguageName(
                                                              uppercaseIsoToLowercaseIso[
                                                                translationTargetLanguage
                                                              ],
                                                              t
                                                            )})`}{' '}
                                                          </p>
                                                          <p
                                                            className={
                                                              styles[
                                                                'scheds__body'
                                                              ]
                                                            }
                                                          >
                                                            {' '}
                                                            <Input
                                                              {...translationInput}
                                                              error={
                                                                touched && error
                                                              }
                                                            />{' '}
                                                          </p>
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </TranslatedField>
                                              </div>

                                              <div
                                                className={
                                                  styles[
                                                    'scheds__body__content__body'
                                                  ]
                                                }
                                              >
                                                <TranslatedField
                                                  name={`${name}.value`}
                                                >
                                                  {({
                                                    input,
                                                    translationInput,
                                                    meta: { error, touched },
                                                  }) => (
                                                    <>
                                                      <div
                                                        className={
                                                          styles[
                                                            'scheds__body__form'
                                                          ]
                                                        }
                                                      >
                                                        <p
                                                          className={
                                                            styles[
                                                              'scheds__ttl'
                                                            ]
                                                          }
                                                        >
                                                          {' '}
                                                          {t(
                                                            'Description'
                                                          )}{' '}
                                                        </p>
                                                        <p
                                                          className={
                                                            styles[
                                                              'scheds__body'
                                                            ]
                                                          }
                                                        >
                                                          {' '}
                                                          <Input
                                                            {...input}
                                                            error={
                                                              touched && error
                                                            }
                                                          />{' '}
                                                        </p>
                                                      </div>
                                                      {showTranslationMode && (
                                                        <div
                                                          className={
                                                            styles[
                                                              'scheds__body__form'
                                                            ]
                                                          }
                                                        >
                                                          <p
                                                            className={
                                                              styles[
                                                                'scheds__ttl'
                                                              ]
                                                            }
                                                          >
                                                            {`${t(
                                                              'Description'
                                                            )} (${getLanguageName(
                                                              uppercaseIsoToLowercaseIso[
                                                                translationTargetLanguage
                                                              ],
                                                              t
                                                            )})`}
                                                          </p>
                                                          <p
                                                            className={
                                                              styles[
                                                                'scheds__body'
                                                              ]
                                                            }
                                                          >
                                                            {' '}
                                                            <Input
                                                              {...translationInput}
                                                              error={
                                                                touched && error
                                                              }
                                                            />{' '}
                                                          </p>
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </TranslatedField>
                                              </div>
                                            </td>
                                            <td
                                              className={
                                                styles['c-table-list__btns']
                                              }
                                            >
                                              <AddIcon
                                                onClick={() =>
                                                  (fields as any).insertAt(
                                                    formIdx + 1,
                                                    ''
                                                  )
                                                }
                                              />
                                              <DeleteIcon
                                                onClick={() =>
                                                  fields.remove(formIdx)
                                                }
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </table>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>
                              {t('Google Map Embed Tag')}
                              <Tooltip
                                text={t(
                                  'Please locate your office in Google Map and paste embed map HTML <iframe> tag.'
                                )}
                              />
                            </th>
                            <td>
                              <div>
                                <Field name="google_map_tag">
                                  {({ input }) => (
                                    <TextArea
                                      maxWidth={800}
                                      height={100}
                                      placeholder={
                                        "<iframe src='https://www.google.com/maps/embed..."
                                      }
                                      {...(input as any)}
                                    />
                                  )}
                                </Field>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className={baseStyles['base-main__box__body__bottomBtns']}
                    >
                      {submitError && (
                        <p className={baseStyles['base-form-box__err']}>
                          {submitError}
                        </p>
                      )}
                      <Button
                        disabled={
                          submitting || editorJsLoading || transEditorJsLoading
                        }
                        loading={
                          submitting || editorJsLoading || transEditorJsLoading
                        }
                        type="submit"
                        style="blue"
                        size="small"
                      >
                        {t('Save Settings')}
                      </Button>
                    </div>
                    {saveSucceeded && (
                      <Message success header={t('Settings Saved')} />
                    )}
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
