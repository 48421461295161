import _ from 'lodash';

import { ManifestReservationShape } from './manifestReservationShape';

export const groupReservationsByPickupTime = (
  reservations: ManifestReservationShape[]
): {
  startTime: string;
  date: string;
  reservations: ManifestReservationShape[];
}[] => {
  const pickupTimeGroups = _.groupBy(
    reservations,
    (reservation: ManifestReservationShape) => {
      return reservation.pickup_checkin_time
        ? reservation.pickup_checkin_time.format()
        : '-';
    }
  );

  return _.sortBy(
    Object.values(pickupTimeGroups).map((reservations) => {
      const startTime = reservations[0].pickup_checkin_time
        ? reservations[0].pickup_checkin_time.format('HH:mm')
        : '';
      const date = reservations[0].pickup_checkin_time
        ? reservations[0].pickup_checkin_time.format('YYYY-MM-DD')
        : '';

      return {
        startTime,
        date,
        reservations,
      };
    }),
    (i) => i.startTime
  );
};
