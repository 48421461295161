import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import type { ReduxState } from 'client/reducers';
import {
  fetchTasks,
  setTasksFilters,
  fetchTaskCounts,
  setTasksPageSize,
  updateTasksPageTokens,
  setTasksDueDateFrom,
} from 'client/actions/tasks';
import { fetchReservations } from 'client/actions/reservations';
import {
  setDashboardTab,
  setDashboardRowCount,
  setDashboardCurrentPage,
} from 'client/actions/dashboardControls';
import { Loading } from 'client/pages/Loading';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { EditSendTaskNotificationEmailModal } from 'client/components/EditSendTaskNotificationEmailModal/EditSendTaskNotificationEmailModal';
import type { TabType } from 'client/libraries/util/dashboard';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { DashboardCustomTable } from './DashboardCustomTable';
import { DashboardCustomTableForTask } from './DashboardCustomTableForTask';
import type { ColumnType } from './utils';
import {
  taskShapesSelector,
  getColumnIds,
  getAllColumns,
  convertReservationToTaskShape,
  getNumOfTasksLabel,
  getNumOfTasks,
} from './utils';
import styles from './Dashboard.module.css';

export const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeTab = useSelector(
    (state: ReduxState) => state.dashboardControls.tab
  );
  const [previousTab, setPreviousTab] = React.useState<TabType>(activeTab);
  const rowCount = useSelector(
    (state: ReduxState) => state.dashboardControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.dashboardControls.currentPage
  );
  const taskCounts = useSelector((state: ReduxState) => state.tasks.taskCounts);

  const pageTokens = useSelector((state: ReduxState) => state.tasks.pageTokens);

  const pageSize = useSelector((state: ReduxState) => state.tasks.pageSize);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [numOfPinnedItems, setNumOfPinnedItems] = React.useState<number>(0);

  const taskShapes = useSelector((state: ReduxState) =>
    taskShapesSelector(state, { t })
  );

  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );

  const pinnedTaskShape = React.useMemo(
    () => allReservations.map((r) => convertReservationToTaskShape(r, t)),
    [allReservations, t]
  );

  const sortedPinnedTaskShape = pinnedTaskShape.sort((a, b) => {
    if (a.deadline.isBefore(b.deadline)) {
      return -1;
    }
    return 1;
  });

  React.useEffect(() => {
    setNumOfPinnedItems(pinnedTaskShape?.length ?? 0);
  }, [pinnedTaskShape]);

  // this value is used in fetchTasks
  React.useEffect(() => {
    if (rowCount !== pageSize) {
      dispatch(setTasksPageSize(rowCount));
    }
  }, [rowCount, pageSize]);

  React.useEffect(() => {
    dispatch(
      setTasksDueDateFrom(
        moment()
          .tz(activeUserOrganization?.default_timezone ?? 'UTC')
          .utc()
          .format()
      )
    );
    dispatch(
      fetchReservations({
        pinned_only: 'true',
      })
    );
    dispatch(
      updateTasksPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.currToken,
        indexToToken: pageTokens.indexToToken,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(fetchTasks());
    dispatch(fetchTaskCounts());
  }, [pageTokens]);

  // this value is used in fetchTasks
  const handleOnClickPrevious = () => {
    dispatch(
      updateTasksPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.allTokens[pageTokens.currToken].previous,
        indexToToken: pageTokens.indexToToken,
      })
    );

    dispatch(fetchTasks());
  };

  // this value is used in fetchTasks
  const handleOnClickNext = () => {
    const allTokens = pageTokens.allTokens;
    const nextToken = pageTokens.allTokens[pageTokens.currToken].next;
    if (nextToken != null) {
      allTokens[nextToken] = {
        previous: pageTokens.currToken,
        next: null,
      };
      dispatch(
        updateTasksPageTokens({
          allTokens: allTokens,
          currToken: nextToken,
          indexToToken: pageTokens.indexToToken,
        })
      );
      dispatch(fetchTasks());
    }
  };

  const taskLoading = useSelector((state: ReduxState) => state.tasks.loading);
  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const visibleColumns = React.useMemo(() => {
    const columns = getColumnIds(activeTab);
    const allColumnTypes = getAllColumns(locale, activeTab, t);

    const visibleColumns: ColumnType[] = [];

    columns.forEach((column: string) => {
      const c = allColumnTypes.find((columnType: ColumnType) => {
        return columnType.id === column;
      });
      if (c) {
        visibleColumns.push(c);
      }
    });

    return visibleColumns;
  }, [activeTab, locale, t]);

  React.useEffect(() => {
    let value;
    let isTask;
    if (activeTab === 'REQUESTED') {
      value = 'REQUESTED';
      isTask = true;
    } else if (activeTab === 'STANDBY') {
      value = 'STANDBY';
      isTask = true;
    } else if (activeTab === 'PICKUP_CHECKIN_INFO_REQURED') {
      value = 'PICKUP_DROPOFF_TBD';
      isTask = true;
    } else if (activeTab === 'PINNED') {
      value = 'PINNED';
      isTask = false;
    }
    if (isTask) {
      dispatch(
        setTasksFilters([
          {
            id: 'action_item',
            value,
          },
        ])
      );
      if (previousTab === activeTab) {
        dispatch(
          updateTasksPageTokens({
            allTokens: pageTokens.allTokens,
            currToken: pageTokens.currToken,
            indexToToken: pageTokens.indexToToken,
          })
        );
      }
      dispatch(fetchTasks());
    } else {
      dispatch(
        fetchReservations({
          pinned_only: 'true',
        })
      );
    }
    dispatch(fetchTaskCounts());
    setPreviousTab(activeTab);
  }, [activeTab]);

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="RESERVATION_DASHBOARD" />
      )}
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        ></div>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <EditSendTaskNotificationEmailModal />
        </div>
      </div>

      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'REQUESTED' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              dispatch(setDashboardTab('REQUESTED'));
            }}
          >
            <a>
              {t('Requested')} {getNumOfTasksLabel(taskCounts, 'REQUESTED')}
            </a>
          </li>

          <li
            className={clsx(
              activeTab === 'STANDBY' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              dispatch(setDashboardTab('STANDBY'));
            }}
          >
            <a>
              {t('Standby')} {getNumOfTasksLabel(taskCounts, 'STANDBY')}
            </a>
          </li>

          <li
            className={clsx(
              activeTab === 'PICKUP_CHECKIN_INFO_REQURED'
                ? componentStyles['is-active']
                : ''
            )}
            onClick={() => {
              dispatch(setDashboardTab('PICKUP_CHECKIN_INFO_REQURED'));
            }}
          >
            <a>
              {t('Pickup/Checkin Info Required')}{' '}
              {getNumOfTasksLabel(taskCounts, 'PICKUP_CHECKIN_INFO_REQURED')}
            </a>
          </li>

          <li
            className={clsx(
              activeTab === 'PINNED' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              dispatch(setDashboardTab('PINNED'));
            }}
          >
            <a>
              {t('Pinned')}{' '}
              <span
                className={clsx(
                  styles['num-of-task-base'],
                  numOfPinnedItems
                    ? styles['num-of-task-non-zero']
                    : styles['num-of-task-zero']
                )}
              >
                {numOfPinnedItems}
              </span>
            </a>
          </li>
        </ul>

        {activeTab === 'PINNED' ? (
          <DashboardCustomTable
            items={sortedPinnedTaskShape}
            columns={visibleColumns}
            rowCount={rowCount}
            currentPage={currentPage}
            onRowCountChange={(rowCount: number) => {
              dispatch(setDashboardRowCount(rowCount));
            }}
            onCurrentPageChange={(newCurrentPage: number) => {
              dispatch(setDashboardCurrentPage(newCurrentPage));
            }}
          />
        ) : (
          <DashboardCustomTableForTask
            items={taskShapes}
            numOfTasks={getNumOfTasks(taskCounts, activeTab)}
            columns={visibleColumns}
            rowCount={rowCount}
            currentPage={currentPage}
            onRowCountChange={(rowCount: number) => {
              dispatch(setDashboardRowCount(rowCount));
            }}
            onCurrentPageChange={(newCurrentPage: number) => {
              if (newCurrentPage < currentPage) {
                handleOnClickPrevious();
              } else {
                handleOnClickNext();
              }
              dispatch(setDashboardCurrentPage(newCurrentPage));
            }}
          />
        )}
      </div>

      {(taskLoading || reservationLoading) && <Loading />}
    </>
  );
};
