// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Message,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { cancelReservationWithFee } from 'client/actions/reservations';
import { FloatInput } from 'client/components/FloatInput';
import { currency } from 'shared/libraries/currency';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { Reservation } from 'shared/models/swagger';

type OwnProps = {
  open: boolean,
  onClose?: () => void,
  reservation: Reservation,
  warning: string,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  open: boolean,
  charge:
    | {
        type: 'fixed',
        amount: string,
      }
    | {
        type: 'percent',
        amount: number,
      },
  supplier_notes: string,
};

class CancelWithFeeModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: this.props.open,
      charge: {
        type: 'percent',
        amount: 0,
      },
      supplier_notes: '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  render() {
    const { onClose, reservation, t, warning } = this.props;
    const { open, charge, supplier_notes } = this.state;

    const grossTotal =
      reservation.billing_info && reservation.billing_info.amount_gross;
    return (
      <Modal
        open={open}
        onClose={() => onClose && onClose()}
        style={{
          marginTop: 0,
        }}
      >
        <Modal.Header>{t('Cancel')}</Modal.Header>
        <Modal.Content>
          <Form warning={Boolean(warning)}>
            <Message warning header={t('Warning')} content={warning} />
            <Form.Group inline>
              <label>{t('Cancellation fee type')}</label>
              <Form.Radio
                label={t('Fixed (ex. USD35.00)')}
                name={'radioGroup-cancellation-modal'}
                value="fixed"
                checked={charge.type === 'fixed'}
                onChange={() =>
                  this.setState({
                    charge: {
                      type: 'fixed',
                      amount: 'USD0.00',
                    },
                  })
                }
              />
              <Form.Radio
                label={t('Percent (0-100 floating point)')}
                name={'radioGroup-cancellation-modal'}
                value="percent"
                checked={charge.type === 'percent'}
                onChange={() =>
                  this.setState({
                    charge: {
                      type: 'percent',
                      amount: 0,
                    },
                  })
                }
              />
            </Form.Group>
            {charge.type === 'fixed' ? (
              <Form.Input
                label={t('Cancellation fee amount')}
                value={charge.amount}
                onChange={(e, { value: amount }) =>
                  this.setState({
                    charge: {
                      type: 'fixed',
                      amount,
                    },
                  })
                }
              />
            ) : (
              <Form.Field>
                <label>{t('Cancellation fee percent')}</label>
                <FloatInput
                  value={charge.amount}
                  onChange={(amount) =>
                    this.setState({
                      charge: {
                        type: 'percent',
                        amount,
                      },
                    })
                  }
                />
              </Form.Field>
            )}
            {charge.type === 'percent' && grossTotal && (
              <Message>
                {t(
                  'Cancellation fee: {{grossTotal}} x {{percent}}% = {{fee}}',
                  {
                    grossTotal,
                    percent: charge.amount,
                    fee: currency(grossTotal)
                      .multiply(charge.amount / 100)
                      .format(),
                  }
                )}
              </Message>
            )}
          </Form>
          <Modal.Description>
            <Header as="h5" style={{ paddingTop: '30px' }}>
              {t(
                'A cancellation fee of {{currencyAmount}} will be charged to the customer. Are you sure you want to cancel?',
                {
                  currencyAmount:
                    charge.type === 'fixed'
                      ? charge.amount
                      : grossTotal
                      ? currency(grossTotal)
                          .multiply(charge.amount / 100)
                          .format()
                      : `${charge.amount}%`,
                }
              )}
            </Header>
            <Divider section />
            <ReservationActorInputForm />
            <Form>
              <Form.Input
                control={TextArea}
                label={t('Replies')}
                value={supplier_notes}
                onChange={this.handleSupplierNotesChange}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                open: false,
              });
            }}
          >
            {t('No')}
          </Button>
          <Button
            primary
            onClick={() => {
              if (charge.type === 'fixed') {
                this.props.cancelReservationWithFee(
                  reservation.id,
                  charge.amount,
                  undefined,
                  this.state.supplier_notes
                );
              } else {
                this.props.cancelReservationWithFee(
                  reservation.id,
                  undefined,
                  charge.amount,
                  this.state.supplier_notes
                );
              }
              this.setState({
                open: false,
              });
            }}
          >
            {t('Yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  cancelReservationWithFee: (id, feeFixed?, feePercent?, supplier_notes?) =>
    dispatch(
      cancelReservationWithFee(id, feeFixed, feePercent, supplier_notes)
    ),
});

export const CancelWithFeeModal = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(CancelWithFeeModalComponent);
