import * as React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { config } from 'client/config';
import { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import { SystemFeeLineItem } from 'shared/models/swagger';
import { isPartnershipSupplier } from 'client/libraries/util/partnership';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign: 'right' | 'center' | 'left';
};

const getGuestName = (lineItem: SystemFeeLineItem): string => {
  const givenName = lineItem.given_name;
  const lastName = lineItem.family_name;
  const kanaGivenName = lineItem.kana_given_name;
  const kanaLastName = lineItem.kana_family_name;

  let customerName = '';
  if (givenName || lastName) {
    customerName = `${givenName} ${lastName}`;

    if (kanaGivenName || kanaLastName) {
      customerName =
        customerName + ' ' + `（${kanaLastName} ${kanaGivenName}）`;
    }
  } else {
    customerName = `${kanaLastName} ${kanaGivenName}`;
  }

  return customerName;
};

const getGuestDescription = (
  guestCounts: SystemFeeLineItem['unit_counts']
): string => {
  return (
    guestCounts
      ?.map(
        (guestCount) => `${guestCount.guest_type_title} x ${guestCount.count}`
      )
      .join(', ') ?? ''
  );
};

const getTotalPax = (guestCounts: SystemFeeLineItem['unit_counts']): number => {
  let count = 0;

  for (const guestCount of guestCounts ?? []) {
    count += guestCount.count ?? 0;
  }

  return count;
};

const useColumns = (
  showDepositDate: boolean
): ColumnType<SystemFeeLineItem>[] => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const organization = useSelector(activeUserOrganizationSelector);
  const { partnershipMode } = React.useContext(PartnershipModeContext);
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const showAsPartnership =
    config.enablePartnership &&
    isPartnershipSupplier(organization) &&
    (!config.enablePartnershipSystemFeeInvoiceTable || partnershipMode);

  return [
    {
      Header: '',
      id: 'edit',
      width: 'short',
      th: true,
      Cell: (cellInfo: any) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/reservations/${cellInfo.original.reservation_id}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </a>
      ),
      textAlign: 'center',
    },
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference ?? '-',
      width: 'middle',
      Cell: (cellInfo) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/reservations/${cellInfo.original.reservation_id}`}
        >
          {cellInfo.original.agent_reference}
        </a>
      ),
      textAlign: 'center',
    },
    ...(showAsPartnership
      ? ([
          {
            Header: t('Agent'),
            id: 'agent',
            accessor: (row) => {
              const agent = contracted.find(
                (org) => org.id === row.pif_agent_id
              );
              return agent?.name ?? '-';
            },
            textAlign: 'center',
          },
        ] as ColumnType<SystemFeeLineItem>[])
      : ([] as ColumnType<SystemFeeLineItem>[])),
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: (row) =>
        moment(row.booked_date_time_utc).locale(locale).format('lll'),
      textAlign: 'center',
    },
    {
      Header: t('Participation'),
      id: 'participation',
      accessor: (row) => {
        if (row.is_free_start_date_time && row.first_checkin_date_time_utc) {
          const m = moment
            .tz(row.first_checkin_date_time_utc, row.start_timezone ?? '')
            .locale(locale);
          return `${m.format('lll')} (GMT${m.format('Z')})`;
        }

        const m = moment
          .tz(row.start_date_time_utc, row.start_timezone ?? '')
          .locale(locale);

        return `${m.format('lll')} (GMT${m.format('Z')})`;
      },
      textAlign: 'center',
    },
    {
      Header: t('Customer'),
      id: 'customer',
      accessor: (row) => getGuestName(row),
      textAlign: 'center',
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => t(row.reservation_status as any),
      textAlign: 'center',
    },
    {
      Header: t('Product'),
      id: 'product',
      Cell: (cellInfo) => (
        <Link to={`/products/${cellInfo.original.product_id}`}>
          {cellInfo.original.internal_product_name ??
            cellInfo.original.product_name}
        </Link>
      ),
      textAlign: 'center',
    },
    {
      Header: t('Units'),
      id: 'units',
      accessor: (row) => getGuestDescription(row.unit_counts),
      textAlign: 'center',
    },
    {
      Header: t('Total Pax'),
      id: 'totalPax',
      accessor: (row) => `${getTotalPax(row.unit_counts)}`,
      textAlign: 'center',
    },
    {
      Header: t('Payment Type'),
      id: 'paymentType',
      accessor: (row) => (row.payment_type && t(row.payment_type as any)) || '',
      textAlign: 'center',
    },
    {
      Header: t('Credit Card Brand'),
      id: 'creditCardBrand',
      accessor: (row) =>
        row.payments?.find((payment) => payment.credit_card_brand)
          ?.credit_card_brand ?? '-',
      textAlign: 'center',
    },
    {
      Header: t('Gross'),
      id: 'gross',
      accessor: (row) => getFormattedAmount(row.amount_gross),
      textAlign: 'right',
    },
    {
      Header: t('Cancel Fee'),
      id: 'cancelFee',
      accessor: (row) => getFormattedAmount(row.amount_cancellation_fee_gross),
      textAlign: 'right',
    },
    ...(showAsPartnership
      ? ([] as ColumnType<SystemFeeLineItem>[])
      : ([
          {
            Header: t('Email Payment'),
            id: 'emailPayment',
            accessor: (row) => (row.is_email_payment ? t('yes') : t('no')),
            textAlign: 'center',
          },
        ] as ColumnType<SystemFeeLineItem>[])),
    ...(showAsPartnership
      ? ([
          {
            Header: t('Commission'),
            id: 'commission',
            accessor: (row: SystemFeeLineItem) =>
              getFormattedAmount(row.amount_commission),
            textAlign: 'right' as const,
          },
        ] as ColumnType<SystemFeeLineItem>[])
      : []),
    ...(showAsPartnership
      ? ([] as ColumnType<SystemFeeLineItem>[])
      : ([
          {
            Header: t('Booking Fee'),
            id: 'bookingFee',
            accessor: (row) => getFormattedAmount(row.amount_booking_fee),
            textAlign: 'right',
          },
          {
            Header: t('Credit Card Fee'),
            id: 'creditCardFee',
            accessor: (row) => getFormattedAmount(row.amount_credit_card_fee),
            textAlign: 'right',
          },
        ] as ColumnType<SystemFeeLineItem>[])),
    ...(showDepositDate
      ? [
          {
            Header: t('Deposit Date'),
            id: 'depositDate',
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payout_date_times_utc ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payout_date_times_utc?.map(
                    (dateTime, idx) => (
                      <li key={idx}>
                        {moment(dateTime).locale(locale).format('ll')}
                      </li>
                    )
                  )}
                </ul>
              ) : cellInfo.original.reservation_payment_gateway === 'GMO' ? (
                t('Settled by GMO')
              ) : null,
            textAlign: 'center' as const,
          },
          {
            Header: t('Deposit Amount'),
            id: 'depositAmount',
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payments ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payments?.map((payment, idx) =>
                    payment.amount_stripe_transfer ? (
                      <li key={idx}>
                        {payment.type === 'CHARGE'
                          ? getFormattedAmount(payment.amount_stripe_transfer)
                          : `-${getFormattedAmount(
                              payment.amount_stripe_transfer
                            )}`}
                      </li>
                    ) : null
                  )}
                </ul>
              ) : cellInfo.original.reservation_payment_gateway === 'GMO' ? (
                t('Settled by GMO')
              ) : null,
            textAlign: 'right' as const,
          },
          {
            Header: t('Transaction Date (UTC)'),
            id: 'transactionDate',
            width: 'middle' as const,
            Cell: (cellInfo: { original: SystemFeeLineItem }) =>
              (cellInfo.original.payments ?? []).length > 0 ? (
                <ul>
                  {cellInfo.original.payments?.map((payment, idx) =>
                    payment.stripe_transfer_date_time_utc ? (
                      <li key={idx}>
                        {moment(payment.stripe_transfer_date_time_utc)
                          .utc()
                          .format('YYYY/MM/DD HH:mm')}
                      </li>
                    ) : null
                  )}
                </ul>
              ) : cellInfo.original.reservation_payment_gateway === 'GMO' ? (
                t('Settled by GMO')
              ) : null,
            textAlign: 'right' as const,
          },
        ]
      : []),
  ];
};

interface QueryParams {
  id: string;
}

export const LineItemTable = ({
  lineItems,
  showDepositDate,
}: {
  lineItems: SystemFeeLineItem[];
  showDepositDate: boolean;
}) => {
  const { t } = useTranslation();
  const { id } = useParams<QueryParams>();
  const systemFeeInvoicesById = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.byId
  );

  const systemFeeInvoice = systemFeeInvoicesById[id];

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const formattedYearMonth = systemFeeInvoice
    ? moment(systemFeeInvoice.month).locale(locale).format(monthYearFormat)
    : '';

  const { setHeaderOverride } = React.useContext(PageHeaderOverrideContext);

  React.useEffect(() => {
    if (formattedYearMonth) {
      setHeaderOverride(
        t('Web Bookings - {{month}}', {
          month: formattedYearMonth,
        })
      );
    }

    return () => setHeaderOverride('');
  }, [formattedYearMonth, t]);

  const columns = useColumns(showDepositDate);

  return (
    <div>
      <CustomTable items={lineItems} columns={columns} usePaging={true} />
    </div>
  );
};
