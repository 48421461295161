import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import type { ReduxState } from 'client/reducers';
import { bulkOperateEquipmentAssignments } from 'client/actions/equipmentAssignments';
import { updateEquipmentInstance } from 'client/actions/equipmentInstances';
import {
  EquipmentAssignmentOperation,
  EquipmentBlockInstanceProperty,
  Reservation,
  ProductInstance,
} from 'shared/models/swagger';
import { addEquipmentNotification } from 'client/actions/equipmentNotifications';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SeatContext } from 'client/contexts/SeatContext';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';

import { SeatMap } from './SeatMap';
import { SeatReAssignModal } from './SeatReAssignModal';
import { SeatAssignmentNewReservationModal } from './SeatAssignmentNewReservationModal';
import { SeatAssignmentContext } from './SeatAssignmentContext';
import { EditEquipmentBlockInstancePropertyModal } from './EditEquipmentBlockInstancePropertyModal';
import { EditEquipmentInstanceModal } from './EditEquipmentInstanceModal';
import {
  getEquipmentAssignmentId,
  getEquipmentAssignment,
  findEquipmentBlockInstanceProperty,
  getCurrentReservationGuestCount,
  getEquipmentBlockInstanceProperty,
} from './utils';

interface Props {
  selectedReservationId: string;
  draggingReservationId: string;
  onReservationDragEnd?: (reservationId: string) => void;
  replacedEquipmentBlockInstanceKeyMap: {
    from: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
    to: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
  }[];
  onReplacedEquipmentBlockInstanceKeyMapChange: (
    arg: {
      from: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
      to: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
    }[]
  ) => void;
  onReset: () => void;
  selectedEquipmentInstanceId: string;
  hoveredReservationId: string;
  onHoveredReservationIdChange: (reservationId: string) => void;
  reservationNumber?: { [key: string]: string } | null;
  filteredReservations: Reservation[];
  selectedGuestTypeKey: string;
  onFetchReservationClick?: () => void;
  fetchReservationLoading?: boolean;
  selectedEquipmentId: string;
  selectedProductInstances: ProductInstance[];
  fetchEquipmentAssignments?: () => void;
}

export const SeatAssignmentMap = ({
  selectedReservationId,
  draggingReservationId,
  onReservationDragEnd,
  replacedEquipmentBlockInstanceKeyMap,
  onReplacedEquipmentBlockInstanceKeyMapChange,
  onReset,
  selectedEquipmentInstanceId,
  hoveredReservationId,
  onHoveredReservationIdChange,
  reservationNumber,
  filteredReservations,
  selectedGuestTypeKey,
  onFetchReservationClick,
  fetchReservationLoading,
  selectedEquipmentId,
  selectedProductInstances,
  fetchEquipmentAssignments,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedEquipmentBlockInstanceKeys,
    selectedAssignableEquipmentInstanceId,
  } = React.useContext(SeatContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const equipments = useSelector(equipmentsSelector);

  const equipmentInstances = useSelector(equipmentInstancesSelector);

  const { editMode } = React.useContext(SeatAssignmentContext);

  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);

  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    React.useState(false);
  const [showEditEquipmentInstanceModal, setShowEditEquipmentInstanceModal] =
    React.useState(false);
  const [showCreateNewReservationModal, setShowCreateNewReservationModal] =
    React.useState(false);
  const [
    showEditEquipmentBlockInstancePropertyModal,
    setShowEditEquipmentBlockInstancePropertyModal,
  ] = React.useState(false);

  const resetHandler = () => {
    onReset();
  };

  const selectedEquipmentBlockInstanceKeysInThisEquipmentInstance =
    React.useMemo(() => {
      return selectedEquipmentBlockInstanceKeys
        .filter(
          (key) =>
            key.equipmentInstanceId === selectedAssignableEquipmentInstanceId
        )
        .map((key) => key.equipmentBlockInstanceKey);
    }, [
      selectedEquipmentBlockInstanceKeys,
      selectedAssignableEquipmentInstanceId,
    ]);

  const filteredEquipmentAssignments = React.useMemo(() => {
    const reservationIds = filteredReservations.map(
      (reservation) => reservation.id
    );

    return equipmentAssignments.filter((assignment) =>
      reservationIds.includes(assignment.reservation_id ?? '')
    );
  }, [equipmentAssignments, filteredReservations]);

  const selectedReservation = React.useMemo(() => {
    return allReservations.find(
      (reservation) => reservation.id === selectedReservationId
    );
  }, [allReservations, selectedReservationId]);

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find((equipmentInstance) => {
      return equipmentInstance.id === selectedEquipmentInstanceId;
    });
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const selectedAssignableEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find((equipmentInstance) => {
      return equipmentInstance.id === selectedAssignableEquipmentInstanceId;
    });
  }, [equipmentInstances, selectedAssignableEquipmentInstanceId]);

  React.useEffect(() => {
    if (editMode === 'ASSIGN' && selectedReservation) {
      const reservationGuestCount = selectedReservation.guests.length || 0;
      const assignedGuestCount = getCurrentReservationGuestCount(
        selectedReservationId,
        equipmentAssignments
      );
      if (reservationGuestCount <= assignedGuestCount) {
        onReset();
      }
    }
  }, [equipmentAssignments, selectedReservationId, editMode]);

  const bulkOperateHander = async () => {
    const operations: EquipmentAssignmentOperation[] = [];

    replacedEquipmentBlockInstanceKeyMap.forEach((map) => {
      const equipmentAssignmentId = getEquipmentAssignmentId(
        map.from.equipmentInstanceId,
        map.from.equipmentBlockInstanceKey,
        filteredEquipmentAssignments
      );

      if (equipmentAssignmentId) {
        operations.push({
          operation_type: 'UNASSIGN',
          equipment_assignment_id: equipmentAssignmentId,
        });
      }
    });

    replacedEquipmentBlockInstanceKeyMap.forEach((map) => {
      const equipmentAssignment = getEquipmentAssignment(
        map.from.equipmentInstanceId,
        map.from.equipmentBlockInstanceKey,
        filteredEquipmentAssignments
      );

      const toEquipmentInstance = equipmentInstances.find(
        (equipmentInstance) =>
          equipmentInstance.id === map.to.equipmentInstanceId
      );
      const toEquipment = equipments.find(
        (equipment) => equipment.id === toEquipmentInstance?.equipment_id
      );

      const property = getEquipmentBlockInstanceProperty(
        map.to.equipmentBlockInstanceKey,
        toEquipmentInstance,
        toEquipment,
        activeUserOrganization
      );

      const mapping = (toEquipment?.equipment_cell_block_mappings || []).find(
        (m) =>
          m.equipment_block_instance_key
            ? m.equipment_block_instance_key ===
              map.to.equipmentBlockInstanceKey
            : false
      );

      const defaultReference = `${convertToAlphabet(
        (mapping?.column ?? 0) + 1
      )}${(mapping?.row ?? 0) + 1}`;

      if (equipmentAssignment) {
        operations.push({
          ...equipmentAssignment,
          equipment_block_instance_key: map.to.equipmentBlockInstanceKey,
          equipment_instance_id: map.to.equipmentInstanceId,
          equipment_block_reference: property?.reference ?? defaultReference,
          operation_type: 'ASSIGN',
        });
      }
    });

    await dispatch(bulkOperateEquipmentAssignments(operations));
    dispatch(
      addEquipmentNotification({
        id: '',
        payload: {
          message: t('Seat assignment has been updated.'),
        },
      })
    );
    if (selectedEquipmentBlockInstanceKeys.length !== 0) {
      dispatch(
        addEquipmentNotification({
          id: '',
          payload: {
            message: t('Remaining seats are not moved.'),
          },
        })
      );
    }
    onFetchReservationClick?.();
    onReset?.();
  };

  return (
    <div>
      <SeatMap
        selectedEquipmentInstanceId={selectedEquipmentInstanceId}
        hoveredReservationId={hoveredReservationId}
        onHoveredReservationIdChange={onHoveredReservationIdChange}
        replacedEquipmentBlockInstanceKeyMap={
          replacedEquipmentBlockInstanceKeyMap
        }
        onReplacedEquipmentBlockInstanceKeyMapChange={
          onReplacedEquipmentBlockInstanceKeyMapChange
        }
        onBulkOperationClick={bulkOperateHander}
        onReset={resetHandler}
        onNewReservationClick={() => {
          setShowCreateNewReservationModal(true);
        }}
        onEditPropertiesClick={() => {
          setShowEditEquipmentBlockInstancePropertyModal(true);
        }}
        selectedReservationId={selectedReservationId}
        draggingReservationId={draggingReservationId}
        onReservationDragEnd={onReservationDragEnd}
        selectedGuestTypeKey={selectedGuestTypeKey}
        reservationNumber={reservationNumber}
        filteredReservations={filteredReservations}
        filteredEquipmentAssignments={filteredEquipmentAssignments}
        selectedEquipmentId={selectedEquipmentId}
        onFetchReservationClick={onFetchReservationClick}
        fetchReservationLoading={fetchReservationLoading}
      />

      {showCreateNewReservationModal && (
        <SeatAssignmentNewReservationModal
          onReset={onReset}
          open={showCreateNewReservationModal}
          onClose={() => {
            setShowCreateNewReservationModal(false);
          }}
          selectedProductInstances={selectedProductInstances}
          selectedEquipmentInstanceId={selectedEquipmentInstanceId}
          fetchEquipmentAssignments={fetchEquipmentAssignments}
        />
      )}

      {showEditEquipmentBlockInstancePropertyModal && (
        <EditEquipmentBlockInstancePropertyModal
          equipmentBlockInstanceProperty={
            selectedEquipmentBlockInstanceKeys.length === 1
              ? findEquipmentBlockInstanceProperty(
                  selectedEquipmentBlockInstanceKeys[0]
                    ?.equipmentBlockInstanceKey,
                  selectedEquipmentBlockInstanceKeys[0]?.equipmentInstanceId,
                  equipments,
                  equipmentInstances,
                  activeUserOrganization
                )
              : null
          }
          open={showEditEquipmentBlockInstancePropertyModal}
          onClose={() => {
            setShowEditEquipmentBlockInstancePropertyModal(false);
          }}
          onSave={async (newProperty: EquipmentBlockInstanceProperty) => {
            dispatch(
              updateEquipmentInstance({
                id: selectedAssignableEquipmentInstanceId,
                equipment_id:
                  selectedAssignableEquipmentInstance?.equipment_id ?? '',
                date: selectedAssignableEquipmentInstance?.date ?? '',
                start_time_key:
                  selectedAssignableEquipmentInstance?.start_time_key ?? '',

                equipment_block_instance_properties: [
                  ...(selectedAssignableEquipmentInstance?.equipment_block_instance_properties?.filter(
                    (property) =>
                      !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
                        property.equipment_block_instance_key ?? ''
                      )
                  ) ?? []),
                  ...(selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.map(
                    (key) => {
                      const property =
                        selectedAssignableEquipmentInstance?.equipment_block_instance_properties?.find(
                          (property) =>
                            property.equipment_block_instance_key === key
                        );
                      return {
                        ...property,
                        ...newProperty,
                        equipment_block_instance_key: key,
                      };
                    }
                  ) ?? []),
                ],
              })
            );
          }}
        />
      )}

      <EditEquipmentInstanceModal
        open={showEditEquipmentInstanceModal}
        onClose={() => {
          setShowEditEquipmentInstanceModal(false);
        }}
        equipmentInstance={selectedEquipmentInstance}
      />
      <SeatReAssignModal
        fromEquipmentInstanceId={selectedEquipmentInstanceId}
        onClose={() => {
          setShowDeleteConfirmModal(false);
        }}
        open={showDeleteConfirmModal}
      />
    </div>
  );
};
