import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { useContext } from 'react';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getValidators } from 'shared/libraries/validate/validator';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { Badge } from 'client/components/v3/Common/Badge';

type Props = {
  name: string;
  currency: string;
  showNet?: boolean;
};
export const PerGroupPriceInput = ({ name }: Props) => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';
  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const { required } = getValidators(t);
  let units: string[] = [];

  for (const priceRule of editingProduct?.pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit?.guest_type?.key) {
        units.push(unit.guest_type.key);
      } else if (unit.method === 'PER_GROUP') {
        units.push(
          ...new Set(
            (unit.group_guest_types ?? []).map((guestType) => guestType.key)
          )
        );
      }
    }
  }

  units = [...new Set(units)];

  if (units.length === 0) {
    return (
      <p className={baseStyles['u-error-msg']}>
        {t(
          'Per-group prices require at least one separate per-participant price schedule'
        )}
      </p>
    );
  }

  return (
    <FieldArray name={name}>
      {({ fields: priceFields }) => (
        <div style={{ width: '100%', marginTop: '20px' }}>
          <div className={styles['p-box__header']}>
            <Toggle
              label={t('Set Up Per-Group Prices')}
              checked={priceFields.length !== 0}
              onChange={() => {
                if (priceFields.length === 0) {
                  (priceFields as any).insertAt(0, {
                    title: '',
                    method: 'PER_GROUP',
                    groupUnits: [],
                    price: '100',
                    netPrice: '100',
                  });
                } else {
                  priceFields.remove(0);
                }
              }}
            />
          </div>
          {priceFields.length !== 0 && (
            <>
              {priceFields.map((priceFieldName) => (
                <CollapsibleBox key={priceFieldName} title={t('Title')}>
                  <TranslatedField
                    name={`${priceFieldName}.title`}
                    validate={required}
                  >
                    {({
                      input,
                      meta: { touched, error },
                      translationInput,
                    }) => (
                      <ul className={styles['p-list']}>
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <div className={styles['p-list__item__ttl__txt']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                              <Badge
                                size="sm"
                                label={t('Required')}
                                color="warning-contained"
                              />
                            </div>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            <TextField
                              {...input}
                              type="text"
                              error={touched && error}
                            />
                          </div>
                        </li>
                        {translationTargetLanguage && (
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <p>
                                {getLanguageName(
                                  uppercaseIsoToLowercaseIso[
                                    translationTargetLanguage
                                  ],
                                  t
                                )}
                              </p>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextField {...translationInput} type="text" />
                            </div>
                          </li>
                        )}
                      </ul>
                    )}
                  </TranslatedField>
                </CollapsibleBox>
              ))}
            </>
          )}
        </div>
      )}
    </FieldArray>
  );
};
