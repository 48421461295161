import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useEffect, useMemo, useState } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDispatchCrewMembers } from 'client/actions/dispatchSettings';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import { fetchProducts } from 'client/actions/products';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  FormValues,
  validateRange,
  getScheduleText,
} from 'client/libraries/util/resourceManager';
import {
  getInitialValues,
  convertFormValuesToDispatchResources,
} from 'client/pages/DispatchCustomize/CrewMemberList/CrewAvailabilityModal/formValues';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

interface Props {
  open: boolean;
  onClose: () => void;
  resourceKey: string;
}

export const CrewAvailabilityModal = ({
  open,
  onClose,
  resourceKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('Edit availability for {{resourceName}}', {
    resourceName: resourceKey,
  });
  const resources = useSelector(allDispatchCrewMembersSelector);

  const resource = resources.find((r) => r.key === resourceKey);

  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        const err = validateRange(values, t);
        if (err) {
          return err;
        }

        try {
          await dispatch(
            updateDispatchCrewMembers(
              convertFormValuesToDispatchResources(
                values,
                resourceKey,
                resources
              )
            )
          );
          onClose && onClose();
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={getInitialValues(resource ?? null)}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              title={title}
              open={open}
              onClose={() => {
                onClose && onClose();
              }}
              rightActionChildren={
                <>
                  <Button
                    color="white"
                    onClick={() => {
                      onClose && onClose();
                    }}
                    text={t('Discard')}
                  />
                  <Button
                    text={t('Save')}
                    loading={submitting}
                    color="primary"
                    type="submit"
                  />
                </>
              }
              style={{ width: '600px', height: '600px' }}
            >
              <div className={styles['p-manifestsModalCustomize']}>
                <FieldArray name="availabilitySchedules">
                  {({ fields }) => (
                    <>
                      <div
                        className={styles['p-manifestsModalCustomize__item']}
                      >
                        <p
                          className={
                            styles['p-manifestsModalCustomize__item__ttl']
                          }
                        >
                          {t('Resource Availability')}
                        </p>
                        <div
                          className={
                            styles['p-manifestsModalCustomize__item__body']
                          }
                        >
                          <div
                            className={
                              styles[
                                'p-manifestsModalCustomize__item__body__flex'
                              ]
                            }
                          >
                            <SingleDropdown
                              options={
                                fields.value.length > 0
                                  ? fields.value.map((priceRule, idx) => ({
                                      text: getScheduleText(priceRule, t),
                                      value: idx.toString(),
                                    }))
                                  : []
                              }
                              selectedOption={
                                fields.value.length > 0
                                  ? activeScheduleIndex.toString()
                                  : ''
                              }
                              onChange={(e) => {
                                setActiveScheduleIndex(parseInt(e));
                              }}
                            />
                            <Button
                              text={t('Add resource availability')}
                              color="primary"
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles['p-frame']}>
                        <div className={styles['p-frame__header']}>
                          <p className={styles['p-frame__header__ttl']}>
                            {t('Selected Resource Availability')}
                          </p>
                          <div className={styles['p-frame__header__actions']}>
                            {fields.value.length > 1 && (
                              <Button
                                text={t('Remove this price schedule')}
                                size="sm"
                                color="tertiarygray"
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                                style={{ color: 'var(--error600)' }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          key={activeScheduleIndex}
                          className={styles['p-frame__body']}
                        >
                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Date Range')}
                            </p>
                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <div
                                className={
                                  styles[
                                    'p-manifestsModalCustomize__item__body__flex'
                                  ]
                                }
                              >
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                                <p style={{ marginBottom: 0 }}>-</p>
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Product')}
                            </p>
                            <ProductCapacities
                              name={`${fields.name}.${activeScheduleIndex}.productCapacities`}
                              scheduleIndex={activeScheduleIndex}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>
                {submitError && (
                  <p className={baseStyles['u-error-msg']}>{submitError}</p>
                )}
              </div>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};

const ProductCapacities = ({
  name,
  scheduleIndex,
}: {
  name: string;
  scheduleIndex: number;
}) => {
  const { t } = useTranslation();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const formState = useFormState();

  const excludeProductIds = useMemo(() => {
    return formState.values?.availabilitySchedules[scheduleIndex]?.addOns?.map(
      (addOn: any) => addOn.productId
    );
  }, [scheduleIndex, formState.values]);

  const candidateProducts = useMemo(() => {
    return products.filter((p) => !excludeProductIds?.includes(p.id));
  }, [products, excludeProductIds]);

  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });

  return (
    <div className={styles['p-manifests__productListInput']}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, idx) => (
              <div
                key={name}
                className={
                  styles['p-manifestsModalCustomize__item__body__flex']
                }
              >
                <Field name={`${name}.productId`} key={`${idx}-productId`}>
                  {({ input: inputProductId }) => (
                    <SingleDropdown
                      searchable={true}
                      options={(candidateProducts ?? [])
                        .filter(
                          (p) =>
                            !fields.value
                              .map((v) => v.productId)
                              .includes(p.id) || p.id === inputProductId.value
                        )
                        .map((p) => ({
                          value: p.id,
                          text: getVerboseDisplayProductName(p),
                        }))}
                      selectedOption={inputProductId.value ?? ''}
                      onChange={(value) => {
                        inputProductId.onChange(value);
                      }}
                    />
                  )}
                </Field>
                <Field name={`${name}.capacity`} key={`${idx}-capacity`}>
                  {({ input: inputCapacity }) => (
                    <SingleDropdown
                      options={[...nums]}
                      selectedOption={inputCapacity.value}
                      onChange={(value) => inputCapacity.onChange(value)}
                    />
                  )}
                </Field>
                <Button
                  size="icon"
                  color="tertiarygray"
                  onClick={() => fields.remove(idx)}
                  iconBeforeText={
                    <i className="c-icon-outline-general-trash-03"></i>
                  }
                />
              </div>
            ))}

            <Button
              color="primary"
              onClick={() => {
                fields.push('');
              }}
              text={t('Add')}
            />
          </>
        )}
      </FieldArray>
    </div>
  );
};
