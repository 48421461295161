import * as React from 'react';

import { Box } from 'client/components/Box/Box';
import { CustomersModal } from 'client/components/CustomersModal/CustomersModal';
import baseStyles from 'client/base.module.css';
import { Newsletter } from 'shared/models/swagger';

export const NewsletterTargetsButton = ({
  newsletter,
}: {
  newsletter: Newsletter;
}) => {
  const [showContactsModal, setShowContactsSegmentModal] =
    React.useState(false);

  return (
    <Box display="flex">
      <a
        className={baseStyles['base-link']}
        onClick={() => setShowContactsSegmentModal(true)}
      >
        {(newsletter.customer_ids ?? []).length +
          (newsletter.imported_customer_count ?? 0)}
      </a>
      {showContactsModal && (
        <CustomersModal
          customerIds={newsletter.customer_ids ?? []}
          s3PresignedUrl={
            (newsletter?.imported_csv_file_urls || []).length > 0
              ? newsletter?.imported_csv_file_urls?.[0]
              : undefined
          }
          onClose={() => setShowContactsSegmentModal(false)}
        />
      )}
    </Box>
  );
};
