import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_ACTIM_RESERVATION_VISIBLE_COLUMNS,
  SET_ACTIM_RESERVATION_ROW_COUNT,
  SET_ACTIM_RESERVATION_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
//import { activeUserSelector } from 'client/reducers/user';
//import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers';

const candidateColumns = [
  'id',
  'status',
  'site_status',
  'site_name',
  'rsv_created_at',
  'activity_started_at',
  'plan_name',
  'course_name',
  'customer',
  'units',
];

const visibleColumnsSelector = (state: ReduxState) =>
  state.actimReservationTableControls.visibleColumns;

export const actimReservationVisibleColumnsSelector = createSelector(
  visibleColumnsSelector,
  (visibleColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);
// Reducers
const defaultVisibleColumns = [
  'edit',
  'id',
  'status',
  'site_status',
  'site_name',
  'rsv_created_at',
  'activity_started_at',
  'plan_name',
  'course_name',
  'customer',
  'units',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_ACTIM_RESERVATION_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_ACTIM_RESERVATION_ROW_COUNT:
      // Reset current page when the search query changes or when the reservation row
      // count changes.
      return 1;

    case SET_ACTIM_RESERVATION_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_ACTIM_RESERVATION_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  currentPage: number;
  rowCount: number;
};

const reducer = combineReducers<State>({
  visibleColumns,
  currentPage,
  rowCount,
});

export const actimReservationTableControls = (state: State, action: any) => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
