import { Action, combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION,
} from 'client/constants/ActionTypes';
import type { SearchETicketFootprintsRequest } from 'client/libraries/util/searchETicketFootprints';

// Selectors
export const eTicketFootprintColumnCandidatesSelector = (): string[] => [
  'checkinDate',
  'agentReference',
  'supplierReference',
  'participation',
  'customer',
  'productName',
  'nearestLocationNameText',
];

const defaultSearchFilters: SearchETicketFootprintsRequest = {
  agentReference: '',
  supplierReference: '',
  customerGivenName: '',
  customerFamilyName: '',
  productIds: [],
  participationDateFrom: '',
  participationDateTo: '',
  checkinDateFrom: '',
  checkinDateTo: '',
  dateFilterPreset: 'CHECKIN_7_DAYS',
  nearestLocationNameText: '',
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

type State = {
  lastExecutedSearchCondition: SearchETicketFootprintsRequest;
};

const reducer = combineReducers<State>({
  lastExecutedSearchCondition,
});

export const eTicketFootprintTableControls = (state: State, action: Action) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
