import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import ColorPicker from 'rc-color-picker';
import { useTranslation } from 'react-i18next';
import { Form, Field, useForm } from 'react-final-form';

import { getArrayMutators } from 'client/libraries/util/form';
import { Box } from 'client/components/Box/Box';
import { Input, Button, FieldWrapper } from 'client/components/Form';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import { ReduxState } from 'client/reducers';
import {
  updateEquipmentAsset,
  calculateEquipmentAssetCellBlockMappings,
} from 'client/actions/equipmentAssets';
import { EquipmentAsset } from 'shared/models/swagger';

import styles from './EquipmentEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  availableBlockColor: string;
  equipmentBlockInstances: {
    key: string;
    equipmentBlockId: string;
    startColumn: number;
    startRow: number;
  }[];
}

const getInitialValues = (
  equipmentAsset: EquipmentAsset | undefined
): FormValues => {
  return {
    title: equipmentAsset?.title ?? '',
    description: equipmentAsset?.description ?? '',
    columnCount: equipmentAsset?.column_count ?? 1,
    rowCount: equipmentAsset?.row_count ?? 1,
    availableBlockColor:
      equipmentAsset?.available_block_color?.value ?? '#000000',
    equipmentBlockInstances:
      equipmentAsset?.equipment_block_instances?.map(
        (equipmentBlockInstance) => {
          return {
            key: equipmentBlockInstance.key ?? '',
            equipmentBlockId: equipmentBlockInstance.equipment_block_id ?? '',
            startColumn: equipmentBlockInstance.start_column ?? 0,
            startRow: equipmentBlockInstance.start_row ?? 0,
          };
        }
      ) ?? [],
  };
};

const convertToSwagger = (values: FormValues): EquipmentAsset => {
  return {
    title: values.title,
    description: values.description,
    column_count: values.columnCount,
    row_count: values.rowCount,
    available_block_color: {
      value: values.availableBlockColor,
    },
    equipment_block_instances: values.equipmentBlockInstances.map(
      (equipmentBlockInstance) => {
        return {
          key: equipmentBlockInstance.key,
          equipment_block_id: equipmentBlockInstance.equipmentBlockId,
          start_column: equipmentBlockInstance.startColumn,
          start_row: equipmentBlockInstance.startRow,
        };
      }
    ),
  };
};

interface Props {
  equipmentAsset: EquipmentAsset | undefined;
  draggingEquipmentBlockId?: string | null;
}

export const EquipmentAssetPanel = ({
  equipmentAsset,
  draggingEquipmentBlockId,
}: Props) => {
  const dispatch = useDispatch();
  const [editingEquipmentAsset, setEditingEquipmentAsset] = React.useState<
    EquipmentAsset | undefined
  >(equipmentAsset);

  const lastEquipmentAsset = useSelector(
    (state: ReduxState) => state.equipmentAssets.lastCalculatedEquipmentAsset
  );

  React.useEffect(() => {
    setEditingEquipmentAsset(equipmentAsset);
  }, [equipmentAsset]);

  React.useEffect(() => {
    if (lastEquipmentAsset) {
      setEditingEquipmentAsset(lastEquipmentAsset);
    }
  }, [lastEquipmentAsset]);

  return (
    <Form<FormValues>
      onSubmit={(values: FormValues) => {
        dispatch(
          updateEquipmentAsset(
            equipmentAsset?.id ?? '',
            convertToSwagger(values)
          )
        );
      }}
      initialValues={
        getInitialValues(editingEquipmentAsset) as Partial<EquipmentAsset>
      }
      mutators={getArrayMutators()}
      debug={console.log}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <EquipmentAssetPanelForm
              equipmentAsset={editingEquipmentAsset}
              submitting={submitting}
              draggingEquipmentBlockId={draggingEquipmentBlockId}
            />
          </form>
        );
      }}
    </Form>
  );
};

const EquipmentAssetPanelForm = ({
  equipmentAsset,
  submitting,
  draggingEquipmentBlockId,
}: {
  equipmentAsset: EquipmentAsset | undefined;
  submitting: boolean;
  draggingEquipmentBlockId?: string | null;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const cellWidth = 80;
  const cellHeight = 50;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const renderLayout = () => {
    const cells = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        const mapping = (
          equipmentAsset?.equipment_cell_block_mappings || []
        ).find((mapping) => {
          return (mapping.row ?? 0) === i && (mapping.column ?? 0) === j;
        });
        cells.push(
          <div
            key={`${i}-${j}`}
            className={clsx(
              styles['equipment-editor__panel-main__layout__cell']
            )}
            style={{
              width: cellWidth,
              height: cellHeight,
              ...(mapping?.color && { backgroundColor: mapping?.color }),
            }}
            onClick={() => {
              console.log('click');
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              console.log('drop', e);
              console.log('draggingEquipmentBlockId', draggingEquipmentBlockId);

              if (draggingEquipmentBlockId) {
                const newValues = {
                  ...values,
                  equipmentBlockInstances: [
                    ...values.equipmentBlockInstances,
                    {
                      key: uuidv4(),
                      equipmentBlockId: draggingEquipmentBlockId,
                      startColumn: j,
                      startRow: i,
                    },
                  ],
                };
                dispatch(
                  calculateEquipmentAssetCellBlockMappings(
                    convertToSwagger(newValues as FormValues)
                  )
                );
              }
              e.preventDefault();
            }}
          >
            {convertToAlphabet(j + 1)}
            {i + 1}
          </div>
        );
      }
    }
    return cells;
  };

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <Box mt={2} mr={2} ml={2}>
          <Field name="title">
            {({ input }) => (
              <Input
                label={t('Title')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
        <Box display="flex" mt={2} mr={2} ml={2}>
          <Box mr={2}>
            <Field name="columnCount">
              {({ input }) => (
                <Input
                  disabled={true}
                  type="number"
                  label={t('Column count')}
                  value={input.value}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                  {...{
                    min: 1,
                  }}
                />
              )}
            </Field>
          </Box>
          <Box ml={2}>
            <Field name="rowCount">
              {({ input }) => (
                <Input
                  disabled={true}
                  type="number"
                  label={t('Row count')}
                  value={input.value}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                  {...{
                    min: 1,
                  }}
                />
              )}
            </Field>
          </Box>
        </Box>
        <Box mt={2} mr={2} ml={2}>
          <FieldWrapper label={t('Colors')}>
            <Field name="availableBlockColor">
              {({ input }) => (
                <ColorPicker
                  color={input.value}
                  onChange={({ color }: any) => {
                    const newValues = {
                      ...values,
                      availableBlockColor: color,
                    };
                    dispatch(
                      calculateEquipmentAssetCellBlockMappings(
                        convertToSwagger(newValues as FormValues)
                      )
                    );
                  }}
                />
              )}
            </Field>
          </FieldWrapper>
        </Box>
      </div>
      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <div
          className={clsx(styles['equipment-editor__panel-main__layout'])}
          style={{
            gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
            gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
            width: layoutWidth,
            height: layoutHeight,
          }}
        >
          <Field name="equipmentBlockInstance">
            {() => <>{renderLayout()}</>}
          </Field>
        </div>
      </div>
      <Box
        mt={2}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          loading={submitting}
          size="middle"
          style="blue"
          type="submit"
          disabled={values.title ? false : true}
        >
          {t('Save')}
        </Button>
      </Box>
    </div>
  );
};
