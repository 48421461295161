import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  TextArea,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ReduxState } from 'client/reducers';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { DateInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DateInput';
import {
  createRealtimeNotificationTemplate,
  updateRealtimeNotificationTemplate,
} from 'client/actions/realtimeNotificationTemplates';
import calendarIcon from 'client/images/ic_calendar.svg';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { DeadlineInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DeadlineInput';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
} from './formValues';

const defaultInitialValues: FormValues = {
  title: '',
  status: 'DRAFT',
  deliveryMedium: 'WEB_PUSH',
  contentTitle: '',
  contentBody: '',
  contentLinkUrl: '',
  contentImageUrl: '',
  contentIconUrl: '',
  contentBadgeUrl: '',
  triggerType: 'ONE_TIME',
  productIds: [],
  startDateLocalFrom: '',
  startDateLocalTo: '',
};

export const NotificationTemplateEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const productOptions = allProducts.map((product) => ({
    value: product.id ?? '',
    text: product.internal_product_name ?? '',
  }));

  const existingNotificationTemplate = useSelector((state: ReduxState) =>
    state.realtimeNotificationTemplates.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const initialValues = React.useMemo(() => {
    return existingNotificationTemplate
      ? convertSwaggerToFormValues(existingNotificationTemplate)
      : defaultInitialValues;
  }, [existingNotificationTemplate]);

  const statusOptions = [
    {
      value: 'DRAFT',
      label: t('Draft'),
    },
    {
      value: 'ACTIVE',
      label: t('Active'),
    },
  ];

  const deliveryMediumOptions = [
    {
      value: 'WEB_PUSH',
      label: t('Web Push'),
    },
    {
      value: 'EMAIL',
      label: t('Email'),
    },
    {
      value: 'SMS',
      label: t('SMS'),
    },
  ];

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2}>
          <Link to="/notifications/templates">
            <BackArrow />
          </Link>
        </Box>
        <Form
          onSubmit={async (values: FormValues) => {
            if (id) {
              await dispatch(
                updateRealtimeNotificationTemplate(
                  id,
                  convertFormValuesToSwagger(values)
                )
              );
            } else {
              await dispatch(
                createRealtimeNotificationTemplate(
                  convertFormValuesToSwagger(values)
                )
              );
            }
          }}
          initialValues={initialValues}
          debug={console.log}
          mutators={getArrayMutators()}
        >
          {({
            handleSubmit,
            submitting,
            submitSucceeded,
            submitError,
            modifiedSinceLastSubmit,
            values,
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Basics')}</th>
                      <td>
                        <Field name="title">
                          {({ input }) => (
                            <Input label={t('Name')} {...input} />
                          )}
                        </Field>
                        <Box mt={2}>
                          <FieldWrapper label={t('Status')}>
                            <EnumRadioButtonGroup
                              name="status"
                              options={statusOptions}
                            />
                          </FieldWrapper>
                        </Box>
                        <FieldWrapper label={t('Delivery Medium')} />
                        <EnumRadioButtonGroup
                          name="deliveryMedium"
                          options={deliveryMediumOptions}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Content')}</th>
                      <td>
                        {values?.deliveryMedium !== 'SMS' && (
                          <Field name="contentTitle">
                            {({ input }) => (
                              <Input label={t('Subject')} {...input} />
                            )}
                          </Field>
                        )}
                        <Field name="contentBody">
                          {({ input }) => (
                            <TextArea label={t('Body')} {...input} />
                          )}
                        </Field>
                        {values?.deliveryMedium === 'WEB_PUSH' && (
                          <>
                            <Field name="contentLinkUrl">
                              {({ input }) => (
                                <Input label={t('Link URL')} {...input} />
                              )}
                            </Field>
                            <Field name="contentImageUrl">
                              {({ input }) => (
                                <Box mt={2}>
                                  <FieldWrapper label={t('Image')}>
                                    <ImageVideoAudioInput
                                      fileUrls={
                                        input.value ? [input.value] : []
                                      }
                                      onChange={(newValue) =>
                                        newValue.length > 0
                                          ? input.onChange(newValue[0])
                                          : input.onChange('')
                                      }
                                      maxFileCount={1}
                                      disableYoutubeVideos
                                    />
                                  </FieldWrapper>
                                </Box>
                              )}
                            </Field>
                            <Field name="contentIconUrl">
                              {({ input }) => (
                                <Box mt={2}>
                                  <FieldWrapper label={t('Icon')}>
                                    <ImageVideoAudioInput
                                      fileUrls={
                                        input.value ? [input.value] : []
                                      }
                                      onChange={(newValue) =>
                                        newValue.length > 0
                                          ? input.onChange(newValue[0])
                                          : input.onChange('')
                                      }
                                      maxFileCount={1}
                                      disableYoutubeVideos
                                    />
                                  </FieldWrapper>
                                </Box>
                              )}
                            </Field>
                            <Field name="contentBadgeUrl">
                              {({ input }) => (
                                <Box mt={2}>
                                  <FieldWrapper label={t('Badge')}>
                                    <ImageVideoAudioInput
                                      fileUrls={
                                        input.value ? [input.value] : []
                                      }
                                      onChange={(newValue) =>
                                        newValue.length > 0
                                          ? input.onChange(newValue[0])
                                          : input.onChange('')
                                      }
                                      maxFileCount={1}
                                      disableYoutubeVideos
                                    />
                                  </FieldWrapper>
                                </Box>
                              )}
                            </Field>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Timing')}</th>
                      <td>
                        <FieldWrapper label={t('Trigger Type')}>
                          <EnumRadioButtonGroup
                            name="triggerType"
                            options={[
                              {
                                value: 'ONE_TIME',
                                label: t('One Time'),
                              },
                              {
                                value: 'SCHEDULED',
                                label: t('Scheduled'),
                              },
                              {
                                value: 'EVENT',
                                label: t('Event Triggered'),
                                disabled: true,
                              },
                            ]}
                            onChange={(value) => {
                              if (value === 'SCHEDULED') {
                                form.change(
                                  'timingType',
                                  'START_TIME_RELATIVE'
                                );
                                form.change(
                                  'timingParticipationRelativeDeadline',
                                  {
                                    deadlineType: 'DAY',
                                    daysBefore: 1,
                                    timeOfDay: '17:00',
                                  }
                                );
                              }
                            }}
                          />
                        </FieldWrapper>

                        {values?.triggerType === 'SCHEDULED' && (
                          <>
                            <FieldWrapper
                              label={t(
                                'Send Timing (relative to participation)'
                              )}
                            />
                            <DeadlineInput
                              name="timingParticipationRelativeDeadline"
                              allowMinutes
                            />
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Conditions')}</th>
                      <td>
                        <Field name="productIds">
                          {({ input }) => (
                            <MultiSelect
                              label={t('Products')}
                              options={productOptions}
                              selectedValues={input.value}
                              onChange={({ value }) => input.onChange(value)}
                            />
                          )}
                        </Field>
                        <Box maxWidth="350px">
                          <FieldWrapper label={t('Participation Date Range')}>
                            <div className={baseStyles['base-form-range']}>
                              <label
                                className={baseStyles['base-form-calendar']}
                              >
                                <img src={calendarIcon} />
                                <Field name="startDateLocalFrom">
                                  {({ input }) => (
                                    <DateInput
                                      name={input.name}
                                      value={input.value}
                                      onChange={input.onChange}
                                    />
                                  )}
                                </Field>
                              </label>
                              <p>-</p>
                              <label
                                className={baseStyles['base-form-calendar']}
                              >
                                <img src={calendarIcon} />
                                <Field name="startDateLocalTo">
                                  {({ input }) => (
                                    <DateInput
                                      name={input.name}
                                      value={input.value}
                                      onChange={input.onChange}
                                    />
                                  )}
                                </Field>
                              </label>
                            </div>
                          </FieldWrapper>
                        </Box>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>

              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
