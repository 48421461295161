import * as React from 'react';
import ReactDOM from 'react-dom';
import _uniqueId from 'lodash/uniqueId';

type Props = {
  url: string;
  locale: string;
  size: 'small' | 'large';
};
export const LineShareButton = ({ url, locale, size }: Props) => {
  const [id] = React.useState<string>(_uniqueId('line-'));
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const encodedUrl = encodeURI(url);
  const wrapper = React.useRef(null);

  const appendSdkScript = () => {
    const script = document.createElement('script');
    script.id = `line-widget-${id}`;
    script.src =
      'https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js';
    script.async = true;
    script.defer = true;

    script.onload = () => setHasLoaded(true);

    (wrapper.current as any).appendChild(script);
  };

  const scriptAlreadyExists = () =>
    document.querySelector(`script#line-widget-${id}`) !== null;

  React.useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendSdkScript();
    }
  }, []);
  React.useEffect(() => {
    if (hasLoaded) {
      const node = document.getElementById(id);

      if (node) {
        ReactDOM.render(
          <div key={`line-tag-${id}`}>
            <div
              className="line-it-button"
              style={{
                display: 'none',
              }}
              data-lang={locale}
              data-type="share-a"
              data-ver="3"
              data-url={encodedUrl}
              data-color="default"
              data-size={size}
              data-count="false"
            />
          </div>,
          node,
          () => {
            (window as any).LineIt.loadButton();
          }
        );
      }
    }
  }, [hasLoaded, encodedUrl, locale, size]);
  return (
    <div ref={wrapper} className="c-sns__wrapper">
      <div key={encodedUrl + locale} id={id}>
        <div />
      </div>
    </div>
  );
};
