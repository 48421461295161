/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';
import StarRating from './StarRating';

interface FeedbackCardProps {
  orderId: string;
  restaurant: string;
  dish: string;
  rating: number;
  comment: string;
  author: string;
  time: string;
  images?: string[];
}

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  orderId,
  restaurant,
  dish,
  rating,
  comment,
  author,
  time,
  images,
}) => (
  <article className={styles.feedbackCard}>
    <div className={styles.feedbackHeader}>
      <div>
        <span className={styles.orderId}>{orderId}</span>
        <span className={styles.restaurant}>{restaurant}</span>
        <span className={styles.dish}>{dish}</span>
      </div>
      <div className={styles.ratingWrapper}>
        <span className={styles.ratingValue}>{rating.toFixed(1)}</span>
        <StarRating rating={rating} />
      </div>
    </div>
    <div className={styles.toggleWrapper}>
      <span>make public</span>
      <label className={styles.toggle}>
        <input type="checkbox" />
        <span className={styles.slider}></span>
      </label>
    </div>
    <p className={styles.feedbackComment}>{comment}</p>
    {images && (
      <div className={styles.imageGallery}>
        {images.map((src, index) => (
          <img key={index} src={src} alt="" className={styles.feedbackImage} />
        ))}
      </div>
    )}
    <div className={styles.authorInfo}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fa995db768b2655b5ee69b7a2f6626d4163336929a3153cdd16087e6f011be21?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
        alt=""
        className={styles.authorAvatar}
      />
      <div>
        <span className={styles.authorName}>{author}</span>
        <span className={styles.feedbackTime}>{time}</span>
      </div>
    </div>
  </article>
);

export default FeedbackCard;
