import * as React from 'react';
import _ from 'lodash';

import { ReviewRating } from 'shared/models/swagger';

import { withQueryParams } from '../util';

import { Heading } from './Heading';
import { Section } from './util';
import { BaseSection } from './BaseSection';
import { Button } from './Button';

type Props = {
  data: Section;
  language: string;
  newsletterCampaignId: string;
};

const getRatingStars = (rating: ReviewRating) => {
  switch (rating) {
    case 'REVIEW_RATING_5':
      return (
        <>
          {_.times(5, () => (
            <img
              src="https://assets.ntmg.com/newsletter/yellow-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
        </>
      );
    case 'REVIEW_RATING_4':
      return (
        <>
          {_.times(4, () => (
            <img
              src="https://assets.ntmg.com/newsletter/yellow-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
          {_.times(1, () => (
            <img
              src="https://assets.ntmg.com/newsletter/grey-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
        </>
      );
    case 'REVIEW_RATING_3':
      return (
        <>
          {_.times(3, () => (
            <img
              src="https://assets.ntmg.com/newsletter/yellow-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
          {_.times(2, () => (
            <img
              src="https://assets.ntmg.com/newsletter/grey-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
        </>
      );
    case 'REVIEW_RATING_2':
      return (
        <>
          {_.times(2, () => (
            <img
              src="https://assets.ntmg.com/newsletter/yellow-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
          {_.times(3, () => (
            <img
              src="https://assets.ntmg.com/newsletter/grey-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
        </>
      );
    case 'REVIEW_RATING_1':
      return (
        <>
          {_.times(1, () => (
            <img
              src="https://assets.ntmg.com/newsletter/yellow-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
          {_.times(4, () => (
            <img
              src="https://assets.ntmg.com/newsletter/grey-star.png"
              width={16}
              height={16}
              style={{ marginRight: '4px' }}
            />
          ))}
        </>
      );
  }
};

export const Product = ({ data, language, newsletterCampaignId }: Props) => {
  const product = data.product;

  if (!product) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <>
        <Heading
          title={product?.title}
          description={product?.description}
          titleFontFamily={product?.titleStyle?.fontFamily}
          titleColor={product?.titleStyle?.color}
          descriptionFontFamily={product?.descriptionStyle?.fontFamily}
          descriptionColor={product?.descriptionStyle?.color}
        />
        {!product.productId && !product.productInfo && <></>}
        {product.productId && product.productInfo && (
          <tr>
            <td>
              <div
                style={{
                  borderTop: '1px solid #E4E4E7',
                  borderRight: '1px solid #E4E4E7',
                  borderLeft: '1px solid #E4E4E7',
                  padding: '16px',
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                  backgroundColor: '#FFFF',
                }}
              >
                <table cellSpacing="0" cellPadding="0" width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src={product.productInfo?.imageUrl}
                          style={{
                            borderRadius:
                              product.style === 'SQUARE' ? '16px' : '9999px',
                            width: '100%',
                            marginBottom: '16px',
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style={{
                            margin: 0,
                            fontSize: '20px',
                            fontWeight: 700,
                            color: '#E11D48',
                            marginBottom: '16px',
                          }}
                        >
                          {product.productInfo?.price}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: 700,
                            marginBottom: '16px',
                          }}
                        >
                          {product.productInfo?.name}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table style={{ width: '100%', marginBottom: '16px' }}>
                          {product.productInfo?.tags?.map((tag, index) => (
                            <td
                              align="center"
                              valign="middle"
                              key={index}
                              style={{
                                margin: '0 4px 4px 0',
                                padding: '2px 6px',
                                display: 'inline-block',
                                fontSize: '12px',
                                color: '#008EC9',
                                border: '1px solid #008EC9',
                                borderRadius: '6px',
                              }}
                            >
                              {tag}
                            </td>
                          ))}
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style={{
                            margin: 0,
                            marginBottom: '16px',
                          }}
                        >
                          {product.productInfo?.description}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {product.productInfo?.daysOfOperation}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {product.productInfo?.reviews &&
                product.productInfo?.reviews?.length > 0 && (
                  <div
                    style={{
                      borderRight: '1px solid #E4E4E7',
                      borderLeft: '1px solid #E4E4E7',
                      padding: '16px',
                      backgroundColor: '#F4F4F5',
                    }}
                  >
                    <table
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '100%' }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {product.productInfo?.reviews &&
                              product.productInfo?.reviews.map(
                                (review, index) => {
                                  return (
                                    <table key={index}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div
                                              style={{
                                                padding: '16px',
                                                backgroundColor: '#FFF',
                                                marginBottom:
                                                  index ===
                                                  (product.productInfo?.reviews
                                                    ?.length ?? 0) -
                                                    1
                                                    ? undefined
                                                    : '16px',
                                                borderRadius: '8px',
                                              }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: '14px',
                                                }}
                                              >
                                                {review.guest_nickname}
                                              </p>
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: '8px',
                                                  color: '#71717A',
                                                  fontSize: '12px',
                                                }}
                                              >
                                                {review.written_date_time_utc}
                                              </p>
                                              <div
                                                style={{
                                                  marginBottom: '4px',
                                                }}
                                              >
                                                {getRatingStars(review.rating)}
                                              </div>
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: '8px',
                                                  fontSize: '18px',
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {review.title}
                                              </p>
                                              <p style={{ margin: 0 }}>
                                                {review.body}
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  );
                                }
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              <div
                style={{
                  borderRight: '1px solid #E4E4E7',
                  borderLeft: '1px solid #E4E4E7',
                  borderBottom: '1px solid #E4E4E7',
                  padding: '16px',
                  borderBottomLeftRadius: '16px',
                  borderBottomRightRadius: '16px',
                  backgroundColor: '#FFFF',
                }}
              >
                <table cellSpacing="0" cellPadding="0" width="100%">
                  <tbody>
                    <Button
                      buttonUrl={withQueryParams(product.buttonUrl, {
                        utm_source: 'ntmg.newsletter',
                        utm_medium: 'email',
                        utm_campaign: newsletterCampaignId,
                        utm_content: 'mainProduct',
                        lng: language,
                      })}
                      buttonText={product.buttonText}
                    />
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </>
    </BaseSection>
  );
};
