import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { toProductShape } from 'client/libraries/util/productShape';
import { Highlights } from 'client/pages/ProductDetails/ProductContentsPane/Highlights/Highlights';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ShareButtons } from 'client/components/Sns/ShareButtons';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getBookingWidgetPmpUrlBase } from 'client/libraries/util/getBookingWidgetPmpUrlBase';
import type { AccordionItemContent } from 'client/pages/ProductDetails/ProductContentsPane/AccordionList/AccordionItem';
import type { Product } from 'shared/models/swagger';
import { MediaCarousel } from 'client/components/v3/ProductDetails/MediaCarousel';
import { OperationInfo } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/OperationInfo/OperationInfo';
import { ProductOutline } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductOutline/ProductOutline';
import { AccordionList } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/AccordionList/AccordionList';
import { TourInformation } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/TourInformation/TourInformation';
import { TagList } from 'client/pages/ProductDetails/ProductContentsHeader/TagList/TagList';
import { ServiceList } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ServiceList/ServiceList';

import styles from './ProductDetailsSection.module.css';

type Props = {
  product: Product;
  contentLanguage?: string;
};

export const ProductContents = ({
  product: apiProduct,
  contentLanguage,
}: Props) => {
  const product = toProductShape(apiProduct);
  const { t, i18n } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const hasBookingWidgetPmp = Boolean(
    activeUserOrganization?.booking_widget_api_key
  );
  const urlBase = getBookingWidgetPmpUrlBase(activeUserOrganization);
  const showMediaCarousel = product.mediaURLs.length > 0;
  const showHighlights = product.highlights.length > 0;
  const showOutline =
    product.schedule.length > 0 ||
    product.pickupLocations.length > 0 ||
    product.checkinLocations.length > 0 ||
    product.dropoffLocations.length > 0 ||
    product.checkoutLocations.length > 0;
  const showAddonsAndTransportation =
    product.addOns.length > 0 || product.transportations.length > 0;
  const addOnsAccordionListItems: AccordionItemContent[] = [];

  if (product.addOns.length > 0) {
    addOnsAccordionListItems.push({
      header: t('Add-ons'),
      content: () => <ServiceList services={product.addOns} />,
      isActiveDefault: true,
    });
  }

  if (product.transportations.length > 0) {
    addOnsAccordionListItems.push({
      header: t('Transportation'),
      content: () => <ServiceList services={product.transportations} />,
      isActiveDefault: true,
    });
  }

  return (
    <section className="g-section">
      <div className={styles['p-productsDetail']}>
        {/* Header */}
        <div className={styles['p-productsDetail__header']}>
          <p className={styles['p-productsDetail__ttl']}>
            {apiProduct.product_name}
          </p>
          <div className={styles['p-productsDetail__actions']}>
            <TagList tags={apiProduct.product_tags ?? []} />
            {hasBookingWidgetPmp && (
              <ShareButtons
                url={`${urlBase}/products/${
                  product.id
                }?lng=${convertToBookingWidgetUrlLangCode(
                  contentLanguage ?? ''
                )}`}
                locale={i18n.language}
                productName={apiProduct.product_name || ''}
              />
            )}
          </div>
        </div>

        {/* Content */}
        <div className={styles['p-productsDetail__body']}>
          {showMediaCarousel && <MediaCarousel urls={product.mediaURLs} />}
          <OperationInfo
            product={product}
            contentLanguage={contentLanguage || i18n.language}
          />
          {showHighlights && (
            <section className={styles['p-productsDetail__section']}>
              <h2 className={styles['p-productsDetail__section__ttl']}>
                {t('Highlights')}
                {hasBookingWidgetPmp && (
                  <ShareButtons
                    url={`${urlBase}/products/${
                      product.id
                    }?highlight=1&lng=${convertToBookingWidgetUrlLangCode(
                      contentLanguage || i18n.language
                    )}`}
                    locale={i18n.language}
                    productName={product.name || ''}
                  />
                )}
              </h2>
              <div className={styles['p-productsDetail__section__body']}>
                <Highlights highlights={product.highlights} />
              </div>
            </section>
          )}
          {showOutline && (
            <section className={styles['p-productsDetail__section']}>
              <h2 className={styles['p-productsDetail__section__ttl']}>
                {t('Outline')}
              </h2>
              <div className={styles['p-productsDetail__section__body']}>
                <ProductOutline product={product} />
              </div>
            </section>
          )}
          {showAddonsAndTransportation && (
            <section className={styles['p-productsDetail__section']}>
              <h2 className={styles['p-productsDetail__section__ttl']}>
                {t('Add-ons / Transportation')}
              </h2>
              <div className={styles['p-productsDetail__section__body']}>
                <AccordionList items={addOnsAccordionListItems} />
              </div>
            </section>
          )}
          <section className={styles['p-productsDetail__section']}>
            <h2 className={styles['p-productsDetail__section__ttl']}>
              {t('Plan Information')}
            </h2>
            <div className={styles['p-productsDetail__section__body']}>
              <TourInformation product={product} />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
