import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useContext } from 'react';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { ReservationParamsFormValues } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';

type Props = {
  name?: string;
};
export const AvailabilityDatesInput = ({ name }: Props) => {
  const { t } = useTranslation();
  const namePrefix = name ? `${name}.` : '';

  const { values } = useFormState<ReservationParamsFormValues>();

  const { setReservationParamsFormValues } = useContext(ProductHelperContext);
  setReservationParamsFormValues(values);

  return (
    <>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>{t('Period')}</div>
        </div>
        <div className={styles['p-list__item__body']}>
          <FieldArray name={`${namePrefix}dateRanges`}>
            {({ fields }) => (
              <div className={styles['p-participationRules']}>
                {fields.map((name, idx) => (
                  <Field key={name} name={name}>
                    {({ input: dateRangeInput }) => (
                      <div className={styles['p-participationRules__item']}>
                        <div
                          className={styles['p-participationRules__item__date']}
                        >
                          <div
                            className={styles['p-participationRules__calendar']}
                          >
                            {dateRangeInput.value.startDate ===
                            dateRangeInput.value.endDate ? (
                              <SimpleDateInput
                                onChange={(value) => {
                                  dateRangeInput.onChange({
                                    startDate: value,
                                    endDate: value,
                                  });
                                }}
                                dateFrom={dateRangeInput.value.startDate}
                                name={`${name}.startDate`}
                              />
                            ) : (
                              <div
                                className={
                                  styles['p-list__item__body__calendar']
                                }
                              >
                                <Field name={`${name}.startDate`}>
                                  {({ input }) => (
                                    <SimpleDateInput
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                                <p>-</p>
                                <Field name={`${name}.endDate`}>
                                  {({ input }) => (
                                    <SimpleDateInput
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                              </div>
                            )}
                          </div>
                          <Button
                            size="icon"
                            color="tertiarygray"
                            onClick={() => {
                              fields.remove(idx);
                            }}
                            iconBeforeText={
                              <i className="c-icon-outline-general-trash-03"></i>
                            }
                          />
                        </div>
                        <Field name={name}>
                          {({ input }) => (
                            <div className={baseStyles['u-mt-2']}>
                              <Toggle
                                label={t('1 day only')}
                                checked={
                                  input.value.startDate &&
                                  input.value.startDate === input.value.endDate
                                }
                                onChange={() => {
                                  if (!input.value.startDate) return;

                                  if (
                                    input.value.startDate ===
                                    input.value.endDate
                                  ) {
                                    input.onChange({
                                      ...input.value,
                                      endDate: moment(input.value.startDate)
                                        .add('years', 1)
                                        .format('YYYY-MM-DD'),
                                    });
                                  } else {
                                    input.onChange({
                                      ...input.value,
                                      endDate: input.value.startDate,
                                    });
                                  }
                                }}
                                size="sm"
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    )}
                  </Field>
                ))}
                {name && (
                  <p className={styles['p-frame__body__note']}>
                    {t(
                      '* Both Participation Rules and "Prices" in Basic Information tab are required to become available on the calendars. Please make sure price and participation rules date ranges are covered.'
                    )}
                  </p>
                )}
                <a
                  className={clsx(
                    styles['p-box__table2__actions__add'],
                    baseStyles['u-mt-4']
                  )}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(fields.length + 1, {
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
                      });
                    } else {
                      (fields as any).insertAt(0, {
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
                      });
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Period')}
                </a>
              </div>
            )}
          </FieldArray>
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Days of Week')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name={`${namePrefix}weekdays`}>
            {({ input }) => (
              <WeekdaysInput value={input.value} onChange={input.onChange} />
            )}
          </Field>
        </div>
      </li>

      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Closed Dates')}
            <div style={{ marginLeft: '4px' }}>
              <Tooltip
                text={t(
                  'Set the days that will not be offered to hide them on the calendar. Specify holidays and company holidays on a daily basis.'
                )}
              >
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <FieldArray name={`${namePrefix}closedDates`}>
            {({ fields }) => (
              <>
                {fields.map((name, idx) => (
                  <div
                    key={idx}
                    className={clsx(styles['p-list__item__body__flex'])}
                  >
                    <div
                      className={clsx(
                        styles['p-list__item__body__flex__main'],
                        styles['p-list__excludedDates']
                      )}
                    >
                      <Field name={`${name}.date`}>
                        {({ input }) => (
                          <SimpleDateInput
                            onChange={input.onChange}
                            dateFrom={input.value}
                            name={input.name}
                          />
                        )}
                      </Field>
                      <Field name={`${name}.repeatsAnnually`} type="checkbox">
                        {({ input }) => (
                          <Toggle
                            label={t('Repeat Annually')}
                            checked={input.checked}
                            onChange={() => {
                              input.onChange(!input.checked);
                            }}
                            size="sm"
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      className={styles['p-list__item__body__flex__actions']}
                    >
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>
                ))}
                <div
                  className={fields.length === 0 ? '' : baseStyles['u-mt-4']}
                >
                  <a
                    className={styles['p-products__section__body__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, {
                          date: '',
                          repeatsAnnually: false,
                        });
                      } else {
                        (fields as any).insertAt(0, {
                          date: '',
                          repeatsAnnually: false,
                        });
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {t('Add Closed Date')}
                  </a>
                </div>
              </>
            )}
          </FieldArray>
        </div>
      </li>
    </>
  );
};
