import * as React from 'react';
import clsx from 'clsx';
import { Loader, Dimmer } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Tabs } from 'client/components/Tabs/Tabs';
import { fetchSurveySubmissionResults } from 'client/actions/surveySubmissionResults';
import { fetchSurveySubmissionsCSV } from 'client/actions/surveySubmissions';
import { Button } from 'client/components/Form';
import { SurveySubmissionSummary } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { FiltersDisplayBox } from './FiltersDisplayBox';
import { FilterModal } from './FilterModal';
import { PageConditions } from './PageConditions';
import styles from './SurveyTemplateResults.module.css';
import {
  FilterSurveySubmissionsRequest,
  convertSearchConditionToApiRequest,
  convertSearchConditionToCSVDownloadApiRequest,
} from './util';

const formattedResponseCount = (
  summary: SurveySubmissionSummary,
  questionKey: string,
  responseKey: string
) => {
  const result = summary.results?.find(
    (result) => result.question_key === questionKey
  );
  const responseCount =
    result?.response_counts?.find(
      (responseCount) => responseCount.response === responseKey
    )?.count ?? 0;
  const totalAnswers = result?.total_responses ?? 0;

  if (totalAnswers === 0) {
    return '-';
  }

  return `${responseCount} (${((responseCount * 100) / totalAnswers).toFixed(
    1
  )}%)`;
};

const getAverageRating = (
  summary: SurveySubmissionSummary,
  questionKey: string
): string => {
  const result = summary.results?.find(
    (result) => result.question_key === questionKey
  );
  const ratingSum =
    result?.response_counts
      ?.map((responseCount) => {
        switch (responseCount.response) {
          case 'REVIEW_RATING_1':
            return (responseCount.count ?? 0) * 1;
          case 'REVIEW_RATING_2':
            return (responseCount.count ?? 0) * 2;
          case 'REVIEW_RATING_3':
            return (responseCount.count ?? 0) * 3;
          case 'REVIEW_RATING_4':
            return (responseCount.count ?? 0) * 4;
          case 'REVIEW_RATING_5':
            return (responseCount.count ?? 0) * 5;
        }

        return 0;
      })
      .reduce((a, b) => a + b, 0) ?? 0;

  return (ratingSum / (result?.total_responses ?? 0)).toFixed(1);
};

const getAverageRatingForNpsSurvey = (
  summary: SurveySubmissionSummary,
  questionKey: string
): string => {
  const result = summary.results?.find(
    (result) => result.question_key === questionKey
  );
  const ratingSum =
    result?.response_counts
      ?.map((responseCount) => {
        switch (responseCount.response) {
          case '0':
            return (responseCount.count ?? 0) * 0;
          case '1':
            return (responseCount.count ?? 0) * 1;
          case '2':
            return (responseCount.count ?? 0) * 2;
          case '3':
            return (responseCount.count ?? 0) * 3;
          case '4':
            return (responseCount.count ?? 0) * 4;
          case '5':
            return (responseCount.count ?? 0) * 5;
          case '6':
            return (responseCount.count ?? 0) * 6;
          case '7':
            return (responseCount.count ?? 0) * 7;
          case '8':
            return (responseCount.count ?? 0) * 8;
          case '9':
            return (responseCount.count ?? 0) * 9;
          case '10':
            return (responseCount.count ?? 0) * 10;
        }

        return 0;
      })
      .reduce((a, b) => a + b, 0) ?? 0;

  return (ratingSum / (result?.total_responses ?? 0)).toFixed(1);
};

const defaultFilterCondition: FilterSurveySubmissionsRequest = {
  participationDateFrom: '',
  participationDateTo: '',
  submissionDateFrom: '',
  submissionDateTo: '',
};

export const SurveyTemplateResults = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [showFilterModal, setShowFilterModal] = React.useState(false);
  const [filterCondition, setFilterCondition] =
    React.useState<FilterSurveySubmissionsRequest>(defaultFilterCondition);
  const [lastExecutedFilterCondition, setLastExecutedFilterCondition] =
    React.useState<FilterSurveySubmissionsRequest>(filterCondition);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      fetchSurveySubmissionResults(
        convertSearchConditionToApiRequest(id, lastExecutedFilterCondition)
      )
    );
  }, [id, lastExecutedFilterCondition]);
  const summary = useSelector(
    (state: ReduxState) => state.surveySubmissionResults.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.surveySubmissionResults.loading
  );
  const surveyTemplates = useSelector(
    (state: ReduxState) => state.surveyTemplates.all
  );
  const surveyTemplate = surveyTemplates.find(
    (surveyTemplate) => surveyTemplate.id === id
  );
  const csvLoading = useSelector(
    (state: ReduxState) => state.surveySubmissions.csvLoading
  );

  if (!surveyTemplate) {
    return <h1>{t('Survey Template Not Found')}</h1>;
  }

  return (
    <div>
      <Dimmer active={loading} inverted>
        <Loader>{t('Loading')}</Loader>
      </Dimmer>
      <div className={baseStyles['base-back-arrow']}>
        <Link to="/surveys/templates">
          <BackArrow />
        </Link>
      </div>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        >
          <Button
            style="blue"
            size="middle"
            onClick={() => setShowFilterModal(true)}
          >
            {t('Filter')}
          </Button>
          {showFilterModal && (
            <FilterModal
              showModal={showFilterModal}
              onClose={() => setShowFilterModal(false)}
              onReset={() => {
                setFilterCondition(defaultFilterCondition);
                setShowFilterModal(false);
              }}
              onSearch={() => {
                setLastExecutedFilterCondition(filterCondition);
                setShowFilterModal(false);
              }}
              filterCondition={filterCondition}
              setFilterCondition={(
                newCondition: FilterSurveySubmissionsRequest
              ) => setFilterCondition(newCondition)}
            />
          )}
        </div>
      </div>

      <Box mb={2}>
        <FiltersDisplayBox filterCondition={lastExecutedFilterCondition} />
      </Box>
      <div
        className={baseStyles['base-main__body__box']}
        style={{ maxWidth: '1000px' }}
      >
        <div className={baseStyles['base-main__body__box__body']}>
          <p className={styles['table__section__header']}>
            {surveyTemplate.template_name ?? ''}
          </p>
          <b>{t('Summary')}</b>
          <FormTableBox>
            <table>
              <tbody>
                <tr>
                  <th>{t('Total Surveys Completed')}</th>
                  <td>{summary?.total_surveys ?? '-'}</td>
                </tr>
                <tr>
                  <th>{t('Total Questions Answered')}</th>
                  <td>{summary?.total_questions_answered ?? '-'}</td>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
        </div>
      </div>
      <Box mt={4}>
        <Tabs
          panes={(surveyTemplate.pages ?? []).map((page, idx) => {
            return {
              header: page.title ?? '',
              content: () => (
                <div
                  className={baseStyles['base-main__body__box']}
                  style={{ maxWidth: '1000px' }}
                >
                  <div className={baseStyles['base-main__body__box__body']}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        style="gray"
                        size="middle"
                        loading={csvLoading}
                        onClick={() =>
                          dispatch(
                            fetchSurveySubmissionsCSV(
                              convertSearchConditionToCSVDownloadApiRequest(
                                id,
                                idx,
                                lastExecutedFilterCondition
                              )
                            )
                          )
                        }
                      >
                        {t('Download CSV')}
                      </Button>
                    </Box>
                    <PageConditions template={surveyTemplate} page={page} />
                    {page.questions?.map((question, idx) => {
                      const totalResponses =
                        summary?.results?.find(
                          (result) => result.question_key === question.key
                        )?.total_responses ?? '-';
                      if (
                        question.response_type === 'SURVEY_QUESTION_STAR_RATING'
                      ) {
                        return (
                          <Box mt={4} key={idx}>
                            <b>{question.question_text}</b>
                            <FormTableBox>
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{t('Total Responses')}</th>
                                    <td>{totalResponses}</td>
                                  </tr>
                                  <tr>
                                    <th>{t('1-star')}</th>
                                    <td>
                                      {formattedResponseCount(
                                        summary,
                                        question.key ?? '',
                                        'REVIEW_RATING_1'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t('2-star')}</th>
                                    <td>
                                      {formattedResponseCount(
                                        summary,
                                        question.key ?? '',
                                        'REVIEW_RATING_2'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t('3-star')}</th>
                                    <td>
                                      {formattedResponseCount(
                                        summary,
                                        question.key ?? '',
                                        'REVIEW_RATING_3'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t('4-star')}</th>
                                    <td>
                                      {formattedResponseCount(
                                        summary,
                                        question.key ?? '',
                                        'REVIEW_RATING_4'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t('5-star')}</th>
                                    <td>
                                      {formattedResponseCount(
                                        summary,
                                        question.key ?? '',
                                        'REVIEW_RATING_5'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{t('Average Rating')}</th>
                                    <td>
                                      {getAverageRating(
                                        summary,
                                        question.key ?? ''
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </FormTableBox>
                          </Box>
                        );
                      }
                      if (
                        question.response_type ===
                        'SURVEY_QUESTION_STAR_RATING_NPS'
                      ) {
                        return (
                          <Box mt={4} key={idx}>
                            <b>{question.question_text}</b>
                            <FormTableBox>
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{t('Total Responses')}</th>
                                    <td>{totalResponses}</td>
                                  </tr>
                                  {[...Array(11)].map((_, index) => (
                                    <tr key={index}>
                                      <th>{index}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          index.toString()
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <th>{t('Average Rating')}</th>
                                    <td>
                                      {getAverageRatingForNpsSurvey(
                                        summary,
                                        question.key ?? ''
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </FormTableBox>
                          </Box>
                        );
                      }
                      if (
                        question.response_type === 'SURVEY_QUESTION_TEXT' ||
                        question.response_type === 'SURVEY_QUESTION_TEXT_AREA'
                      ) {
                        if (question.key === 'age') {
                          return (
                            <Box mt={4} key={idx}>
                              <b>{question.question_text}</b>
                              <FormTableBox>
                                <table>
                                  <tbody>
                                    <tr>
                                      <th>{t('Total Responses')}</th>
                                      <td>{totalResponses}</td>
                                    </tr>
                                    <tr>
                                      <th>{t('20s')}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          '20s'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>{t('30s')}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          '30s'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>{t('40s')}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          '40s'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>{t('50s')}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          '50s'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>{t('60+')}</th>
                                      <td>
                                        {formattedResponseCount(
                                          summary,
                                          question.key ?? '',
                                          '60+'
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </FormTableBox>
                            </Box>
                          );
                        }
                        return (
                          <Box mt={4} key={idx}>
                            <b>{question.question_text}</b>
                            <FormTableBox>
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{t('Total Responses')}</th>
                                    <td>{totalResponses}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </FormTableBox>
                          </Box>
                        );
                      }
                      if (
                        question.response_type === 'SURVEY_QUESTION_SELECT_ONE'
                      ) {
                        return (
                          <Box mt={4} key={idx}>
                            <b>{question.question_text}</b>
                            <FormTableBox>
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{t('Total Responses')}</th>
                                    <td>{totalResponses ?? '-'}</td>
                                  </tr>
                                  {question.response_options?.map((option) => {
                                    return (
                                      <tr key={option.key}>
                                        <th>{option.text}</th>
                                        <td>
                                          {formattedResponseCount(
                                            summary,
                                            question.key ?? '',
                                            option.key ?? ''
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </FormTableBox>
                          </Box>
                        );
                      }
                      if (
                        question.response_type ===
                        'SURVEY_QUESTION_SELECT_MULTIPLE'
                      ) {
                        return (
                          <Box mt={4} key={idx}>
                            <b>{question.question_text}</b>
                            <FormTableBox>
                              <table>
                                <tbody>
                                  <tr>
                                    <th>{t('Total Responses')}</th>
                                    <td>{totalResponses ?? '-'}</td>
                                  </tr>
                                  {question.response_options?.map((option) => {
                                    return (
                                      <tr key={option.key}>
                                        <th>{option.text}</th>
                                        <td>
                                          {formattedResponseCount(
                                            summary,
                                            question.key ?? '',
                                            option.key ?? ''
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </FormTableBox>
                          </Box>
                        );
                      }
                    })}
                  </div>
                </div>
              ),
            };
          })}
        />
      </Box>
    </div>
  );
};
