import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import type { LanguageISO } from 'client/libraries/i18n';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import type { TemplateSupplierSettings } from 'shared/models/swagger';

type Props = {
  template?: TemplateSupplierSettings;
  lang: LanguageISO;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCreate: () => void;
};

export const SiteTemplateSampleView = ({
  template,
  lang,
  setShowModal,
  onCreate,
}: Props) => {
  const { t } = useTranslation();
  const bwUrl = getBookingWidgetUrl(template?.booking_widget_subdomain ?? '');

  return (
    <Modal
      title={template?.banner_title ?? ''}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
      width={'wide'}
    >
      <Modal.Content>
        <Modal.Box>
          <iframe
            src={`${bwUrl}?lng=${convertToBookingWidgetUrlLangCode(lang)}`}
            width={'100%'}
            height={'700px'}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            onCreate();
            setShowModal(false);
          }}
        >
          {t('Create website')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
