import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { FieldWrapper, Input } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

import styles from './WaiverTemplateList.module.css';
import { ItemsEditor } from './ItemsEditor';

type Props = {
  name: string;
};
export const ConditionalEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  return (
    <div>
      <ul className={styles['scheds']}>
        <li className={styles['scheds__cell']}>
          <TranslatedField name={`${name}.question`}>
            {({ input, translationInput }) => (
              <>
                <div>
                  <p className={styles['scheds__body']}>
                    <Input {...input} label={t('Yes or No Question')} />
                  </p>
                </div>
                {translationTargetLanguage && (
                  <div>
                    <p className={styles['scheds__body']}>
                      <Input
                        {...translationInput}
                        label={t('Yes or No Question ({{language}})', {
                          language: translationTargetLanguageName,
                        })}
                      />
                    </p>
                  </div>
                )}
              </>
            )}
          </TranslatedField>
        </li>
      </ul>
      <Box>
        <FieldWrapper label={t('"Yes" Items')}>
          <ItemsEditor showConditional={false} name={`${name}.yesItems`} />
        </FieldWrapper>
      </Box>
      <Box mt={2}>
        <FieldWrapper label={t('"No" Items')}>
          <ItemsEditor showConditional={false} name={`${name}.noItems`} />
        </FieldWrapper>
      </Box>
    </div>
  );
};
