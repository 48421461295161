import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SUBSCRIPTION_INVOICES_REQUEST,
  FETCH_SUBSCRIPTION_INVOICES_FAILURE,
  FETCH_SUBSCRIPTION_INVOICES_SUCCESS,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import { ReduxState } from 'client/reducers';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSubscriptionInvoicesRequest = () => ({
  type: FETCH_SUBSCRIPTION_INVOICES_REQUEST,
});

const fetchSubscriptionInvoicesSuccess = (response: any) => ({
  type: FETCH_SUBSCRIPTION_INVOICES_SUCCESS,
  response,
});

const fetchSubscriptionInvoicesFailure = (error: any) => ({
  type: FETCH_SUBSCRIPTION_INVOICES_FAILURE,
  error,
});

export const fetchSubscriptionInvoices =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSubscriptionInvoicesRequest());
    return axios
      .get('/api/organizations/subscriptions/invoices', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchSubscriptionInvoicesSuccess(result.data)))
      .catch((error) => dispatch(fetchSubscriptionInvoicesFailure(error)));
  };
