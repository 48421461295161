import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, useForm } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  InquiryAttachment,
  InquiryAttachmentsEditor,
} from 'client/components/InquiryAttachmentsEditor/InquiryAttachmentsEditor';
import { InsertMessageTemplateModal } from 'client/pages/Inquiry/InsertMessageTemplateModal/InsertMessageTemplateModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';

import styles from './ComposeMessage.module.css';

interface Props {
  onBackClick: () => void;
}
export const ComposeMessage = ({ onBackClick }: Props) => {
  const [showInsertMessageTemplateModal, setShowInsertMessageTemplateModal] =
    React.useState(false);
  const { t } = useTranslation();

  const { required } = getValidators(t);
  const form = useForm();

  const org = useSelector(activeUserOrganizationSelector);
  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button type="submit" style="green" size="middle">
            {t('Continue to Step 3')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Email Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          {hasMessageTemplates && (
            <Box mb={2}>
              <Button.Create
                onClick={() => setShowInsertMessageTemplateModal(true)}
              >
                {t('Insert Message Template')}
              </Button.Create>
            </Box>
          )}
          {showInsertMessageTemplateModal && (
            <InsertMessageTemplateModal
              onClose={() => setShowInsertMessageTemplateModal(false)}
              onInsert={(messageTemplate) => {
                if (messageTemplate.subject) {
                  form.change('subject', messageTemplate.subject);
                }
                form.change('description', messageTemplate.message);
              }}
            />
          )}
          <Field name="subject" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <Input
                value={input.value}
                onChange={input.onChange}
                label={t('Subject')}
                error={touched && error}
              />
            )}
          </Field>
          <Box mt={2}>
            <Field name="description" validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextArea
                  value={input.value}
                  onChange={input.onChange}
                  label={t('Message')}
                  error={touched && error}
                />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <FieldWrapper label={t('Attachments')}>
              <Field name="attachments">
                {({ input }) => (
                  <InquiryAttachmentsEditor
                    attachments={input.value}
                    onChange={(newAttachments: InquiryAttachment[]) =>
                      input.onChange(newAttachments)
                    }
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
        </div>
      </div>
    </div>
  );
};
