// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Input, Select, FieldWrapper } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { updateManifestAlternateNameSettings } from 'client/actions/manifestSettings';
import { manifestAlternateNameSettingsSelector } from 'client/reducers/manifestSettings';
import { getArrayMutators } from 'client/libraries/util/form';
import type {
  ManifestAlternateName,
  ManifestIdAlternateName,
} from 'shared/models/swagger';
import styles from 'client/pages/ManifestCustomize/AlternateName/AlternateName.module.css';
import baseStyles from 'client/base.module.css';
import { LocationSearchInput } from 'client/components/LocationSearchInput';

type Props = {
  trigger: React.Element<'a' | 'button'>,
};

export const EditAlternateNameModal = ({ trigger }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alternateNameSettings = useSelector(
    manifestAlternateNameSettingsSelector
  );
  const products = useSelector(summariesWithBookmarksSelector);

  const agents = useSelector((state) => state.organizations.contracted);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const headerText = t('Edit abbreviated name');

  const productNameOptions = products.map((product) => {
    return {
      value: product.id,
      text: `${product.product_name || ''}(${
        product.supplier_reference || ''
      })`,
    };
  });

  const agentNameOptions = agents.map((agent) => {
    return {
      value: agent.id,
      text: agent.name,
    };
  });

  return (
    <Modal
      title={headerText}
      trigger={trigger}
      open={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
    >
      <Form
        onSubmit={async (values: {
          productNames: ManifestIdAlternateName[],
          locationNames: ManifestAlternateName[],
          agentNames: ManifestIdAlternateName[],
        }) => {
          try {
            dispatch(
              updateManifestAlternateNameSettings({
                id_alternate_names: values.productNames.filter((a) => {
                  return a.id !== '' && a.name !== '';
                }),
                alternate_names: values.locationNames.filter((a) => {
                  return a.key !== '' && a.name !== '';
                }),
                agent_id_alternate_names: values.agentNames.filter((a) => {
                  return a.id !== '' && a.name !== '';
                }),
              })
            );
            setShowModal(false);
          } catch (err) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        initialValues={{
          productNames: alternateNameSettings.id_alternate_names || [],
          locationNames: alternateNameSettings.alternate_names || [],
          agentNames: alternateNameSettings.agent_id_alternate_names || [],
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <FieldArray name={'productNames'}>
                    {({ fields }) => {
                      return (
                        <FieldWrapper label={t('Product name')}>
                          <table
                            className={clsx(styles['scheds__body__content'])}
                          >
                            <tbody>
                              {fields.map((name, idx) => {
                                return (
                                  <tr key={`product-name-${idx}`}>
                                    <td>
                                      <Field name={`${name}.id`}>
                                        {({ input }) => (
                                          <Select
                                            options={productNameOptions.filter(
                                              (option) => {
                                                // Remove existing product name
                                                return !(values: any).productNames
                                                  ?.map((p) => p.id)
                                                  .includes(option.value);
                                              }
                                            )}
                                            value={
                                              productNameOptions.find(
                                                (p) => p.value === input.value
                                              )?.text || ''
                                            }
                                            onChange={(e, { value }) => {
                                              input.onChange(value);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                    <td>
                                      <Field name={`${name}.name`}>
                                        {({ input }) => <Input {...input} />}
                                      </Field>
                                    </td>
                                    <td>
                                      <DeleteIcon
                                        onClick={() => fields.remove(idx)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div className={clsx(baseStyles['list-add-btn'])}>
                            <Button
                              style="green"
                              size="middle"
                              onClick={() => {
                                fields.push({ id: '', name: '' });
                              }}
                            >
                              {t('Add')}
                            </Button>
                          </div>
                        </FieldWrapper>
                      );
                    }}
                  </FieldArray>
                </Modal.Box>

                <Modal.Box>
                  <FieldArray name={'locationNames'}>
                    {({ fields }) => {
                      return (
                        <FieldWrapper label={t('Location name')}>
                          <table
                            className={clsx(styles['scheds__body__content'])}
                          >
                            <tbody>
                              {fields.map((name, idx) => {
                                return (
                                  <tr key={`product-name-${idx}`}>
                                    <td>
                                      <Field name={`${name}.key`}>
                                        {({ input }) => (
                                          <LocationSearchInput
                                            location={input.value}
                                            onSearchChange={(key) => {
                                              input.onChange(key);
                                            }}
                                            onLocationSelect={({
                                              title: key,
                                            }) => {
                                              input.onChange(key);
                                            }}
                                            maxWidth={274}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                    <td>
                                      <Field name={`${name}.name`}>
                                        {({ input }) => <Input {...input} />}
                                      </Field>
                                    </td>
                                    <td>
                                      <DeleteIcon
                                        onClick={() => fields.remove(idx)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div className={clsx(baseStyles['list-add-btn'])}>
                            <Button
                              style="green"
                              size="middle"
                              onClick={() => {
                                fields.push({ id: '', name: '' });
                              }}
                            >
                              {t('Add')}
                            </Button>
                          </div>
                        </FieldWrapper>
                      );
                    }}
                  </FieldArray>
                </Modal.Box>

                <Modal.Box>
                  <FieldArray name={'agentNames'}>
                    {({ fields }) => {
                      return (
                        <FieldWrapper label={t('Agent Name')}>
                          <table
                            className={clsx(styles['scheds__body__content'])}
                          >
                            <tbody>
                              {fields.map((name, idx) => {
                                return (
                                  <tr key={`agent-name-${idx}`}>
                                    <td>
                                      <Field name={`${name}.id`}>
                                        {({ input }) => (
                                          <Select
                                            options={agentNameOptions.filter(
                                              (option) => {
                                                // Remove existing product name
                                                return !(values: any).agentNames
                                                  ?.map((p) => p.id)
                                                  .includes(option.value);
                                              }
                                            )}
                                            value={
                                              agentNameOptions.find(
                                                (p) => p.value === input.value
                                              )?.text || ''
                                            }
                                            onChange={(e, { value }) => {
                                              input.onChange(value);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </td>
                                    <td>
                                      <Field name={`${name}.name`}>
                                        {({ input }) => <Input {...input} />}
                                      </Field>
                                    </td>
                                    <td>
                                      <DeleteIcon
                                        onClick={() => fields.remove(idx)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div className={clsx(baseStyles['list-add-btn'])}>
                            <Button
                              style="green"
                              size="middle"
                              onClick={() => {
                                fields.push({ id: '', name: '' });
                              }}
                            >
                              {t('Add')}
                            </Button>
                          </div>
                        </FieldWrapper>
                      );
                    }}
                  </FieldArray>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
