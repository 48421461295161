import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { TextArea, Button } from 'client/components/Form';
import { cancelReservation } from 'client/actions/reservations';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Divider } from 'client/components/Divider/Divider';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

type Props = {
  trigger: React.ReactComponentElement<'a' | 'button'>;
  text: string;
  reservationID: string;
};

export const CancelModalV2 = ({ reservationID, text, trigger }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [agentNotes, setAgentNotes] = React.useState<string>('');
  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const handleAgentNotesChange = (e: any) => {
    setAgentNotes(e.target.value);
  };

  const useAlternativeOrganization = partnershipMode;

  return (
    <Modal
      trigger={trigger}
      title={t('Cancel')}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <Message warning header={t('Warning')} content={text} />
        </Modal.Box>

        <Divider />

        <ReservationActorInputForm />

        <TextArea
          label={t('Remarks')}
          value={agentNotes}
          onChange={handleAgentNotesChange}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('No')}
        </Button.Cancel>
        <Button.Delete
          onClick={async () => {
            await dispatch(
              cancelReservation(
                reservationID,
                agentNotes,
                useAlternativeOrganization
              )
            );
            setShowModal(false);
          }}
        >
          {t('Yes')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  );
};
