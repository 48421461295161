import * as React from 'react';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { isTouchBackend } from 'client/libraries/util/isTouchBackend';
import { ReduxState } from 'client/reducers';
import styles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

type Props = {
  error?: string;
  label?: string;
  fromDate: string;
  toDate: string;
  onChangeFromDate: (arg0: string) => void;
  onChangeToDate: (arg0: string) => void;
};

const Input = React.forwardRef<any, any>((props) => {
  return <input {...props} readOnly={isTouchBackend() ? true : false} />;
});

export const DateRangeInput = ({
  error,
  label,
  fromDate,
  toDate,
  onChangeFromDate,
  onChangeToDate,
}: Props) => {
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const dateFormatCalendar = locale === 'ja' ? 'yyyy年MM月' : undefined;

  return (
    <div className={clsx(styles['base-form-box'])}>
      {label && (
        <div className={clsx(styles['base-form-box__header'])}>
          <label>{label}</label>
        </div>
      )}

      <div className={clsx(styles['base-form-box__body'])}>
        <div className={clsx(styles['base-form-range'])}>
          <label className={clsx(styles['base-form-calendar'])}>
            <img src={calendarIcon} />
            <DatePicker
              customInput={<Input />}
              selected={fromDate ? moment(fromDate).toDate() : null}
              dateFormat="yyyy/MM/dd"
              onChange={(date, e) => {
                e?.preventDefault();

                if (!date) {
                  onChangeFromDate('');
                } else {
                  onChangeFromDate(moment(date).format('YYYY-MM-DD'));
                }
              }}
              isClearable={true}
              locale={locale}
              dateFormatCalendar={dateFormatCalendar ?? undefined}
            />
          </label>

          <p>-</p>

          <label className={clsx(styles['base-form-calendar'])}>
            <img src={calendarIcon} />
            <DatePicker
              customInput={<Input />}
              selected={toDate ? moment(toDate).toDate() : null}
              dateFormat="yyyy/MM/dd"
              onChange={(date, e) => {
                e?.preventDefault();

                if (!date) {
                  onChangeToDate('');
                } else {
                  onChangeToDate(moment(date).format('YYYY-MM-DD'));
                }
              }}
              isClearable={true}
              locale={locale}
              dateFormatCalendar={dateFormatCalendar ?? undefined}
            />
          </label>
        </div>
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
