import * as React from 'react';

import { FieldWrapper } from 'client/components/Form';
import { listAddFront } from 'client/libraries/util/coreutil';
import addIcon from 'client/images/ic_add.svg';

export function ListInputLabel<T>({
  label,
  value,
  onChange,
  getDefaultElement,
}: {
  label: string;
  value: T[];
  onChange: (newValue: T[]) => void;
  getDefaultElement: () => T;
}) {
  return (
    <FieldWrapper label={label}>
      <a onClick={() => onChange(listAddFront(value, getDefaultElement()))}>
        <img src={addIcon} />
      </a>
    </FieldWrapper>
  );
}
