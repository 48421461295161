import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field, useForm } from 'react-final-form';

import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { ZoomablePane } from 'client/components/ZoomablePane/ZoomablePane';
import { getArrayMutators } from 'client/libraries/util/form';
import { Box } from 'client/components/Box/Box';
import { Button, Radio, FieldWrapper, Checkbox } from 'client/components/Form';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import { ReduxState } from 'client/reducers';
import {
  updateEquipment,
  calculateEquipmentCellBlockMappings,
  clearEquipmentCellBlockMappings,
  deleteEquipment,
} from 'client/actions/equipments';
import { EditEquipmentBlockInstancePropertyModal } from 'client/components/Seat/EditEquipmentBlockInstancePropertyModal';
import { EditEquipmentBlockInstancePriceModal } from 'client/components/Seat/EditEquipmentBlockInstancePriceModal';
import {
  getEquipmentBlockInstanceProperty,
  getEquipmentBlockInstancePriceKeyForEquipment,
  getRowColumnSpanForEquipmentBlockInstance,
  getRowColumnSpanForEquipmentAssetInstance,
  getCellBorderProperty,
  isDraggingEquipmentAssetPlaceable,
} from 'client/components/Seat/utils';
import {
  Equipment,
  EquipmentBlockInstanceProperty,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { equipmentAssetsSelector } from 'client/reducers/equipmentAssets';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { SelectPopupWindow } from './SelectPopupWindow';
import { TitleInputForm, ColumnRowCountInputForm } from './parts';
import { Cell } from './Cell';
import styles from './SeatEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  startPoint: string;
  direction: string;
  id: string;
  shouldAssignReservationPerEquipmentAsset: boolean;
}

const getInitialValues = (equipment: Equipment | undefined): FormValues => {
  return {
    id: equipment?.id ?? '',
    title: equipment?.title ?? '',
    description: equipment?.description ?? '',
    columnCount: equipment?.column_count ?? 1,
    rowCount: equipment?.row_count ?? 1,
    startPoint: equipment?.arrangement_rules?.start_point ?? 'LEFT_TOP',
    direction: equipment?.arrangement_rules?.direction ?? 'HORIZONTAL',
    shouldAssignReservationPerEquipmentAsset:
      equipment?.should_assign_reservation_per_equipment_asset?.value ?? false,
  };
};

const convertToSwagger = (
  values: FormValues,
  editingEquipment: Equipment | undefined
): Equipment => {
  return {
    id: values.id,
    title: values.title,
    description: values.description,
    column_count: Number(values.columnCount),
    row_count: Number(values.rowCount),
    arrangement_rules: {
      start_point: values.startPoint as any,
      direction: values.direction as any,
    },
    should_assign_reservation_per_equipment_asset: {
      value: values.shouldAssignReservationPerEquipmentAsset,
    },

    equipment_asset_instances: editingEquipment?.equipment_asset_instances,
    equipment_block_instance_properties:
      editingEquipment?.equipment_block_instance_properties,
    equipment_block_instance_price_settings:
      editingEquipment?.equipment_block_instance_price_settings,
  };
};

interface Props {
  equipment: Equipment | undefined;
  draggingEquipmentAssetId: string | null;
}

export const ResourcePane = ({
  equipment,
  draggingEquipmentAssetId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editingEquipment, setEditingEquipment] = React.useState<
    Equipment | undefined
  >(undefined);
  const [
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  ] = React.useState<string[]>([]);
  const [
    showEditEquipmentBlockInstanceModal,
    setShowEditEquipmentBlockInstanceModal,
  ] = React.useState<boolean>(false);
  const [
    showEditEquipmentBlockInstancePriceModal,
    setShowEditEquipmentBlockInstancePriceModal,
  ] = React.useState<boolean>(false);
  const [
    selectedEquipmentAssetInstanceKeys,
    setSelectedEquipmentAssetInstanceKeys,
  ] = React.useState<string[]>([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    React.useState<boolean>(false);
  const [editLayer, setEditLayer] = React.useState<'BLOCK' | 'ASSET'>('BLOCK');
  const [draggingOverPosition, setDraggingOverPosition] = React.useState<{
    row: number;
    column: number;
  } | null>(null);
  const [propertyUpdated, setPropertyUpdated] = React.useState<boolean>(false);
  const [priceUpdated, setPriceUpdated] = React.useState<boolean>(false);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const lastEquipment = useSelector(
    (state: ReduxState) => state.equipments.lastCalculatedEquipment
  );

  React.useEffect(() => {
    if (equipment) {
      dispatch(clearEquipmentCellBlockMappings());
      setEditingEquipment(equipment);
      setDraggingOverPosition(null);
      setSelectedEquipmentBlockInstanceKeys([]);
      setSelectedEquipmentAssetInstanceKeys([]);
      setPropertyUpdated(false);
      setPriceUpdated(false);
    }
  }, [equipment]);

  React.useEffect(() => {
    if (lastEquipment) {
      setEditingEquipment(lastEquipment);
    }
  }, [lastEquipment]);

  if (!equipment) {
    return <div className={clsx(styles['seats__main'])}></div>;
  }
  return (
    <>
      <div className={clsx(styles['seats__main'])}>
        <Form<FormValues>
          onSubmit={(values: FormValues) => {
            dispatch(
              updateEquipment(
                equipment?.id ?? '',
                convertToSwagger(values, editingEquipment)
              )
            );
          }}
          initialValues={getInitialValues(equipment)}
          mutators={getArrayMutators()}
          keepDirtyOnReinitialize={true}
        >
          {({ handleSubmit, submitting, pristine, form }) => {
            React.useEffect(() => {
              if (equipment) {
                form.setConfig('keepDirtyOnReinitialize', false);
                form.reset(getInitialValues(equipment));
                form.setConfig('keepDirtyOnReinitialize', true);
              }
            }, [equipment]);

            return (
              <form onSubmit={handleSubmit}>
                <ResourcePaneForm
                  equipment={editingEquipment}
                  submitting={submitting}
                  draggingEquipmentAssetId={draggingEquipmentAssetId}
                  selectedEquipmentBlockInstanceKeys={
                    selectedEquipmentBlockInstanceKeys
                  }
                  setSelectedEquipmentBlockInstanceKeys={
                    setSelectedEquipmentBlockInstanceKeys
                  }
                  setShowEditEquipmentBlockInstanceModal={
                    setShowEditEquipmentBlockInstanceModal
                  }
                  setShowEditEquipmentBlockInstancePriceModal={
                    setShowEditEquipmentBlockInstancePriceModal
                  }
                  selectedEquipmentAssetInstanceKeys={
                    selectedEquipmentAssetInstanceKeys
                  }
                  setSelectedEquipmentAssetInstanceKeys={
                    setSelectedEquipmentAssetInstanceKeys
                  }
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  onEditCancelClick={() => {
                    dispatch(clearEquipmentCellBlockMappings());
                    setSelectedEquipmentBlockInstanceKeys([]);
                    setSelectedEquipmentAssetInstanceKeys([]);
                    setDraggingOverPosition(null);
                  }}
                  changed={
                    Boolean(lastEquipment) ||
                    !pristine ||
                    propertyUpdated ||
                    priceUpdated
                  }
                  editLayer={editLayer}
                  onEditLayerChange={(layer) => {
                    setEditLayer(layer);
                  }}
                  draggingOverPosition={draggingOverPosition}
                  onDraggingOverPositionChange={(position) => {
                    setDraggingOverPosition(position);
                  }}
                />
              </form>
            );
          }}
        </Form>
      </div>
      {showEditEquipmentBlockInstanceModal && (
        <EditEquipmentBlockInstancePropertyModal
          equipmentBlockInstanceProperty={
            selectedEquipmentBlockInstanceKeys.length === 1
              ? getEquipmentBlockInstanceProperty(
                  selectedEquipmentBlockInstanceKeys[0],
                  editingEquipment,
                  undefined,
                  activeUserOrganization
                )
              : null
          }
          onClose={() => {
            setShowEditEquipmentBlockInstanceModal(false);
          }}
          open={showEditEquipmentBlockInstanceModal}
          onSave={async (newProperty: EquipmentBlockInstanceProperty) => {
            const newEditingEquipment = {
              ...editingEquipment,
              equipment_block_instance_properties: [
                ...(
                  editingEquipment?.equipment_block_instance_properties ?? []
                ).filter((property) => {
                  return !selectedEquipmentBlockInstanceKeys.includes(
                    property.equipment_block_instance_key ?? ''
                  );
                }),
                ...(selectedEquipmentBlockInstanceKeys.map((key) => {
                  const property =
                    editingEquipment?.equipment_block_instance_properties?.find(
                      (property) => {
                        return (
                          property.equipment_block_instance_key === key ?? ''
                        );
                      }
                    );
                  return {
                    ...property,
                    ...newProperty,
                    equipment_block_instance_key: key,
                  };
                }) ?? []),
              ],
            };
            setEditingEquipment(newEditingEquipment);
            setPropertyUpdated(true);
            setShowEditEquipmentBlockInstanceModal(false);
          }}
          saveButtonText={'設定する'}
          cancelButtonText={t('Close')}
        />
      )}
      {showEditEquipmentBlockInstancePriceModal && (
        <EditEquipmentBlockInstancePriceModal
          equipmentBlockInstancePriceKey={
            selectedEquipmentBlockInstanceKeys.length === 1
              ? getEquipmentBlockInstancePriceKeyForEquipment(
                  selectedEquipmentBlockInstanceKeys[0],
                  editingEquipment
                )
              : null
          }
          onClose={() => {
            setShowEditEquipmentBlockInstancePriceModal(false);
          }}
          open={showEditEquipmentBlockInstancePriceModal}
          onSave={async (newPriceKey: string) => {
            const newEditingEquipment = {
              ...editingEquipment,
              equipment_block_instance_price_settings: {
                equipment_block_instance_price_mappings: [
                  ...(
                    editingEquipment?.equipment_block_instance_price_settings
                      ?.equipment_block_instance_price_mappings ?? []
                  ).filter((mapping) => {
                    return !selectedEquipmentBlockInstanceKeys.includes(
                      mapping.equipment_block_instance_key ?? ''
                    );
                  }),
                  ...(selectedEquipmentBlockInstanceKeys.map((key) => {
                    return {
                      equipment_block_instance_price_key: newPriceKey,
                      equipment_block_instance_key: key,
                    };
                  }) ?? []),
                ],
              },
            };
            setEditingEquipment(newEditingEquipment);
            setPriceUpdated(true);
            setShowEditEquipmentBlockInstancePriceModal(false);
          }}
          saveButtonText={'設定する'}
          cancelButtonText={t('Close')}
        />
      )}
      {showConfirmDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete asset')}
          content={t('Are you sure you want to delete this asset?')}
          onConfirm={async () => {
            const removeEquipmentBlockInstanceKeys =
              editingEquipment?.equipment_cell_block_mappings
                ?.filter((mapping) => {
                  return selectedEquipmentAssetInstanceKeys.includes(
                    mapping.equipment_asset_instance_key ?? ''
                  );
                })
                .map((mapping) => {
                  return mapping.equipment_block_instance_key ?? '';
                });

            const newEditingEquipment = {
              ...editingEquipment,
              equipment_asset_instances: [
                ...(editingEquipment?.equipment_asset_instances ?? []).filter(
                  (instance) => {
                    return !selectedEquipmentAssetInstanceKeys.includes(
                      instance.key ?? ''
                    );
                  }
                ),
              ],
              equipment_cell_block_mappings: [
                ...(
                  editingEquipment?.equipment_cell_block_mappings ?? []
                ).filter((mapping) => {
                  return !selectedEquipmentAssetInstanceKeys.includes(
                    mapping.equipment_asset_instance_key ?? ''
                  );
                }),
              ],
              equipment_block_instance_properties: [
                ...(
                  editingEquipment?.equipment_block_instance_properties ?? []
                ).filter((property) => {
                  return !removeEquipmentBlockInstanceKeys?.includes(
                    property.equipment_block_instance_key ?? ''
                  );
                }),
              ],
              equipment_block_instance_price_settings: {
                equipment_block_instance_price_mappings: [
                  ...(
                    editingEquipment?.equipment_block_instance_price_settings
                      ?.equipment_block_instance_price_mappings ?? []
                  ).filter((mapping) => {
                    return !removeEquipmentBlockInstanceKeys?.includes(
                      mapping.equipment_block_instance_key ?? ''
                    );
                  }),
                ],
              },
            };
            setEditingEquipment(newEditingEquipment);
            setShowConfirmDeleteModal(false);
          }}
          onClose={() => {
            setShowConfirmDeleteModal(false);
          }}
          open={showConfirmDeleteModal}
        />
      )}
    </>
  );
};

const ResourcePaneForm = ({
  equipment,
  submitting,
  draggingEquipmentAssetId,
  selectedEquipmentBlockInstanceKeys,
  setSelectedEquipmentBlockInstanceKeys,
  setShowEditEquipmentBlockInstanceModal,
  setShowEditEquipmentBlockInstancePriceModal,
  selectedEquipmentAssetInstanceKeys,
  setSelectedEquipmentAssetInstanceKeys,
  changed,
  editLayer,
  onEditLayerChange,
  draggingOverPosition,
  onDraggingOverPositionChange,
  onEditCancelClick,
}: {
  equipment: Equipment | undefined;
  submitting: boolean;
  draggingEquipmentAssetId: string | null;
  selectedEquipmentBlockInstanceKeys: string[];
  setSelectedEquipmentBlockInstanceKeys: (keys: string[]) => void;
  setShowEditEquipmentBlockInstanceModal: (show: boolean) => void;
  setShowEditEquipmentBlockInstancePriceModal: (show: boolean) => void;
  selectedEquipmentAssetInstanceKeys: string[];
  setSelectedEquipmentAssetInstanceKeys: (keys: string[]) => void;
  setShowConfirmDeleteModal?: (show: boolean) => void;
  changed: boolean;
  editLayer: 'BLOCK' | 'ASSET';
  onEditLayerChange: (layer: 'BLOCK' | 'ASSET') => void;
  draggingOverPosition: { row: number; column: number } | null;
  onDraggingOverPositionChange: (
    position: {
      row: number;
      column: number;
    } | null
  ) => void;
  onEditCancelClick: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const equipmentAssets = useSelector(equipmentAssetsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const draggingEquipmentAsset = equipmentAssets.find((asset) => {
    return asset.id === draggingEquipmentAssetId;
  });
  const [popupPosition, setPopupPosition] = React.useState<{
    left: number;
    top: number;
  } | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    setPopupPosition(null);
  }, [equipment?.id]);

  const cellWidth = 60;
  const cellHeight = 60;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const startPointOptions = [
    { text: '左上', value: 'LEFT_TOP' },
    { text: '右上', value: 'RIGHT_TOP' },
    { text: '右下', value: 'RIGHT_BOTTOM' },
    { text: '左下', value: 'LEFT_BOTTOM' },
  ];

  const directionOptions = [
    { text: '横', value: 'HORIZONTAL' },
    { text: '縦', value: 'VERTICAL' },
  ];

  const dropInstanceHandler = (i: number, j: number) => {
    if (!draggingEquipmentAssetId) {
      return;
    }
    const isPlaceable = isDraggingEquipmentAssetPlaceable(
      i,
      j,
      draggingEquipmentAsset?.row_count ?? 0,
      draggingEquipmentAsset?.column_count ?? 0,
      equipment ?? undefined,
      equipmentAssets ?? undefined
    );
    if (isPlaceable) {
      const newEquipment: Equipment = {
        ...equipment,
        equipment_asset_instances: [
          ...(equipment?.equipment_asset_instances ?? []),
          {
            key: uuidv4(),
            equipment_asset_id: draggingEquipmentAssetId,
            start_column: j,
            start_row: i,
          },
        ],
      };
      dispatch(
        calculateEquipmentCellBlockMappings(
          convertToSwagger(values as FormValues, newEquipment)
        )
      );
    } else {
      alert('配置できません');
    }
  };

  const renderBlockLayout = () => {
    const cells = [];
    const placedEquipmentBlockInstanceKeys: string[] = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        const mapping = (equipment?.equipment_cell_block_mappings || []).find(
          (mapping) => {
            return (mapping.row ?? 0) === i && (mapping.column ?? 0) === j;
          }
        );

        const property = getEquipmentBlockInstanceProperty(
          mapping?.equipment_block_instance_key ?? '',
          equipment,
          undefined,
          activeUserOrganization
        );

        const isSelected =
          mapping && mapping?.equipment_block_instance_key
            ? selectedEquipmentBlockInstanceKeys.includes(
                mapping?.equipment_block_instance_key ?? ''
              )
            : false;

        const rowColumnSpan = getRowColumnSpanForEquipmentBlockInstance(
          i,
          j,
          values.rowCount,
          values.columnCount,
          mapping?.equipment_block_instance_key ?? '',
          placedEquipmentBlockInstanceKeys,
          equipment?.equipment_cell_block_mappings ?? []
        );

        if (rowColumnSpan.isSkipped) {
          continue;
        }

        const cellBoarderProperty = getCellBorderProperty(
          i,
          j,
          draggingOverPosition,
          draggingEquipmentAsset
            ? {
                rowCount: draggingEquipmentAsset.row_count ?? 0,
                columnCount: draggingEquipmentAsset.column_count ?? 0,
              }
            : null
        );

        cells.push(
          <Cell
            i={i}
            j={j}
            key={`${i}-${j}`}
            isSelected={isSelected}
            isClosed={property?.is_closed ?? false}
            cellBoarderProperty={cellBoarderProperty}
            size={{ width: cellWidth, height: cellHeight }}
            span={rowColumnSpan}
            label={property?.reference ?? `${convertToAlphabet(j + 1)}${i + 1}`}
            color={property?.color ?? ''}
            activateDragOver={Boolean(draggingEquipmentAssetId)}
            activateMouseOver={Boolean(mapping?.equipment_block_instance_key)}
            activateClick={Boolean(mapping?.equipment_block_instance_key)}
            activateDrop={Boolean(draggingEquipmentAssetId)}
            onCellClick={() => {
              if (!mapping?.equipment_block_instance_key) {
                return;
              }
              if (
                !selectedEquipmentBlockInstanceKeys.includes(
                  mapping.equipment_block_instance_key
                )
              ) {
                setSelectedEquipmentBlockInstanceKeys([
                  ...selectedEquipmentBlockInstanceKeys,
                  mapping.equipment_block_instance_key,
                ]);
              } else {
                setSelectedEquipmentBlockInstanceKeys([
                  ...selectedEquipmentBlockInstanceKeys.filter((key) => {
                    return key !== mapping?.equipment_block_instance_key ?? '';
                  }),
                ]);
              }
            }}
            onDraggingOverPositionChange={onDraggingOverPositionChange}
            onDropInstance={() => {
              dropInstanceHandler(i, j);
            }}
            onPopupPositionChange={setPopupPosition}
          />
        );
      }
    }
    return cells;
  };

  const renderAssetLayout = () => {
    const cells = [];
    const placedEquipmentAssetInstanceKeys: string[] = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        const mapping = (equipment?.equipment_cell_block_mappings || []).find(
          (mapping) => {
            return (mapping.row ?? 0) === i && (mapping.column ?? 0) === j;
          }
        );

        const property = getEquipmentBlockInstanceProperty(
          mapping?.equipment_block_instance_key ?? '',
          equipment,
          undefined,
          activeUserOrganization
        );

        const isSelected =
          mapping && mapping?.equipment_asset_instance_key
            ? selectedEquipmentAssetInstanceKeys.includes(
                mapping?.equipment_asset_instance_key ?? ''
              )
            : false;

        const rowColumnSpan = getRowColumnSpanForEquipmentAssetInstance(
          i,
          j,
          values.rowCount,
          values.columnCount,
          mapping?.equipment_asset_instance_key ?? '',
          placedEquipmentAssetInstanceKeys,
          equipment?.equipment_cell_block_mappings ?? []
        );

        if (rowColumnSpan.isSkipped) {
          continue;
        }

        const cellBoarderProperty = getCellBorderProperty(
          i,
          j,
          draggingOverPosition,
          draggingEquipmentAsset
            ? {
                rowCount: draggingEquipmentAsset.row_count ?? 0,
                columnCount: draggingEquipmentAsset.column_count ?? 0,
              }
            : null
        );

        const equipmentAssetInstance =
          equipment?.equipment_asset_instances?.find((instance) => {
            return instance.key === mapping?.equipment_asset_instance_key ?? '';
          });

        const equipmentAsset = equipmentAssets.find((asset) => {
          return asset.id === equipmentAssetInstance?.equipment_asset_id ?? '';
        });

        cells.push(
          <Cell
            i={i}
            j={j}
            key={`${i}-${j}`}
            isSelected={isSelected}
            cellBoarderProperty={cellBoarderProperty}
            size={{ width: cellWidth, height: cellHeight }}
            span={rowColumnSpan}
            label={equipmentAsset?.title ?? ''}
            color={property?.color ?? ''}
            activateDragOver={Boolean(draggingEquipmentAssetId)}
            activateMouseOver={Boolean(mapping?.equipment_asset_instance_key)}
            activateClick={Boolean(mapping?.equipment_asset_instance_key)}
            activateDrop={Boolean(draggingEquipmentAssetId)}
            onCellClick={() => {
              if (!mapping?.equipment_asset_instance_key) {
                return;
              }
              if (
                !selectedEquipmentAssetInstanceKeys.includes(
                  mapping.equipment_asset_instance_key ?? ''
                )
              ) {
                setSelectedEquipmentAssetInstanceKeys([
                  ...selectedEquipmentAssetInstanceKeys,
                  mapping.equipment_asset_instance_key ?? '',
                ]);
              } else {
                setSelectedEquipmentAssetInstanceKeys([
                  ...selectedEquipmentAssetInstanceKeys.filter((key) => {
                    return key !== mapping.equipment_asset_instance_key ?? '';
                  }),
                ]);
              }
            }}
            onDraggingOverPositionChange={onDraggingOverPositionChange}
            onDropInstance={() => {
              dropInstanceHandler(i, j);
            }}
            onPopupPositionChange={setPopupPosition}
          />
        );
      }
    }
    return cells;
  };

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <TitleInputForm />

        <ColumnRowCountInputForm />

        <Box mt={2} mr={2}>
          <div style={{ marginRight: '8px', width: '100%' }}>
            <div className={clsx(baseStyles['base-form-box'])}>
              <div className={clsx(baseStyles['base-form-box__header'])}>
                {t('Origin')}
              </div>
              <div className={clsx(baseStyles['base-form-box__body'])}>
                <Field name="startPoint">
                  {(props) => (
                    <select
                      className={clsx(baseStyles['base-form-text'])}
                      {...props.input}
                    >
                      {startPointOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.text}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div style={{ marginRight: '8px', width: '100%' }}>
            <div className={clsx(baseStyles['base-form-box'])}>
              <div className={clsx(baseStyles['base-form-box__header'])}>
                {t('equipment.Direction')}
              </div>
              <div className={clsx(baseStyles['base-form-box__body'])}>
                <Field name="direction">
                  {(props) => (
                    <select
                      className={clsx(baseStyles['base-form-text'])}
                      {...props.input}
                    >
                      {directionOptions.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.text}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <Box mt={2}>
            <Field name="shouldAssignReservationPerEquipmentAsset">
              {({ input }) => (
                <Checkbox
                  label={t('Assign reservation per asset')}
                  checked={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>
          </Box>

          <Box mt={2}>
            <FieldWrapper label={t('Item to edit')}>
              <Radio
                label={t('Block')}
                checked={editLayer === 'BLOCK'}
                onChange={() => {
                  onEditLayerChange('BLOCK');
                }}
              />
              <Radio
                label={t('Asset')}
                checked={editLayer === 'ASSET'}
                onChange={() => {
                  onEditLayerChange('ASSET');
                }}
              />
            </FieldWrapper>
          </Box>
        </Box>

        <Box mt={2} mr={2}>
          <Button
            loading={submitting}
            size="middle"
            style="blue"
            type="submit"
            disabled={!changed}
          >
            {t('Save')}
          </Button>
        </Box>
        <Box mt={2} mr={2}>
          <Button
            size="middle"
            style="gray"
            onClick={() => {
              onEditCancelClick();
              setPopupPosition(null);
            }}
          >
            変更を破棄
          </Button>
        </Box>
        <Box mt={8} mr={2}>
          <Button
            size="middle"
            style="red"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            {t('Delete')}
          </Button>

          <DeleteConfirmModal
            header={t('Delete Resource')}
            content={t('Are you sure you want to delete "{{resourceName}}"?', {
              resourceName: equipment?.title ?? '',
            })}
            onConfirm={async () => {
              await dispatch(deleteEquipment(equipment?.id ?? ''));
              setShowDeleteConfirm(false);
            }}
            onClose={() => setShowDeleteConfirm(false)}
            open={showDeleteConfirm}
            insertRoot={true}
          />
        </Box>
      </div>

      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <div className={clsx(baseStyles['base-form-box'])}>
          <div className={clsx(baseStyles['base-form-box__header'])}>
            {t('Preview')}
            {changed && (
              <span style={{ color: 'red' }}>({t('Not saved')})</span>
            )}
          </div>
          <ZoomablePane>
            {({
              targetRef,
              wrapperRef,
              onZoomInClick,
              onZoomOutClick,
              onReseatScale,
            }) => {
              React.useEffect(() => {
                onReseatScale?.();
              }, [equipment]);
              React.useEffect(() => {
                const handleOutsideClick = ({ target }: Event) => {
                  if (
                    target instanceof Node &&
                    !targetRef?.current?.contains(target) &&
                    wrapperRef?.current?.contains(target)
                  ) {
                    onDraggingOverPositionChange(null);
                    setSelectedEquipmentBlockInstanceKeys([]);
                    setSelectedEquipmentAssetInstanceKeys([]);
                  }
                };

                window.document.addEventListener(
                  'mousedown',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
                window.document.addEventListener(
                  'touchstart',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
                return () => {
                  window.document.removeEventListener(
                    'mousedown',
                    handleOutsideClick,
                    {
                      capture: true,
                    }
                  );
                  window.document.removeEventListener(
                    'touchstart',
                    handleOutsideClick,
                    {
                      capture: true,
                    }
                  );
                };
              }, []);

              return (
                <div className={styles['seatsAction']}>
                  <div className={clsx(styles['seatsAction__info'])}>
                    <div
                      className={clsx(
                        styles['seatsAction__btns'],
                        styles['reload']
                      )}
                    >
                      <a
                        onClick={() => {
                          onZoomInClick?.();
                        }}
                      >
                        +
                      </a>
                      <a
                        onClick={() => {
                          onZoomOutClick?.();
                        }}
                      >
                        -
                      </a>
                    </div>
                  </div>
                  <div className={styles['seatsAction__cell']}>
                    <div className={styles['content']} ref={wrapperRef}>
                      <ul
                        ref={targetRef}
                        style={{
                          gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
                          gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
                          width: layoutWidth,
                          height: layoutHeight,
                        }}
                      >
                        {editLayer === 'BLOCK'
                          ? renderBlockLayout()
                          : renderAssetLayout()}
                      </ul>
                    </div>
                  </div>

                  <SelectPopupWindow
                    title={
                      editLayer === 'ASSET'
                        ? t('Edit selected assets')
                        : t('Edit selected blocks')
                    }
                    open={
                      editLayer === 'ASSET'
                        ? selectedEquipmentAssetInstanceKeys.length !== 0
                        : selectedEquipmentBlockInstanceKeys.length !== 0
                    }
                    onDeleteClick={
                      editLayer === 'ASSET'
                        ? () => {
                            const newEquipment: Equipment = {
                              ...equipment,
                              equipment_asset_instances: [
                                ...(
                                  equipment?.equipment_asset_instances || []
                                ).filter((instance) => {
                                  return !selectedEquipmentAssetInstanceKeys.includes(
                                    instance.key ?? ''
                                  );
                                }),
                              ],
                            };
                            dispatch(
                              calculateEquipmentCellBlockMappings(
                                convertToSwagger(
                                  values as FormValues,
                                  newEquipment
                                )
                              )
                            );
                          }
                        : undefined
                    }
                    onChangePriceClick={
                      editLayer === 'BLOCK'
                        ? () => {
                            setShowEditEquipmentBlockInstancePriceModal(true);
                          }
                        : undefined
                    }
                    onChangeAttributeClick={
                      editLayer === 'BLOCK'
                        ? () => {
                            setShowEditEquipmentBlockInstanceModal(true);
                          }
                        : undefined
                    }
                    position={popupPosition}
                  />
                </div>
              );
            }}
          </ZoomablePane>
        </div>
      </div>
    </div>
  );
};
