// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { BasicStep } from 'client/components/NewProductEditor/BasicStep';
import { DetailsStep } from 'client/components/NewProductEditor/DetailsStep/DetailsStep';
import { ReservationParamsStep } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsStep';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchSourceLanguageProductById } from 'client/actions/products';
import { Loading } from 'client/pages/Loading/Loading';
import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/CreateNewProduct/CreateNewProduct.module.css';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { config } from 'client/config';

export const CreateNewProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();
  const { id } = useParams();

  const [editingProductId, setEditingProductId] = React.useState<string | null>(
    location.pathname.endsWith('copy') && id ? id : null
  );

  const [activeStep, setActiveStep] = React.useState<
    'basic' | 'reservation' | 'detailed' | 'complete'
  >('basic');

  const copy = location.pathname.endsWith('copy') && id ? true : false;

  const scrollTo = React.useContext(ScrollToContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const organizationLoading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  React.useEffect(() => {
    if (editingProductId) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId, activeStep]);

  React.useEffect(() => {
    scrollTo(0, 0);
  }, [activeStep]);

  if (
    !activeUserOrganization ||
    organizationLoading ||
    (editingProductId && !editingProduct)
  ) {
    return <Loading />;
  }

  if (activeStep === 'complete') {
    return <Redirect to={`/products/${editingProductId || ''}`} />;
  }

  return (
    <EditingProductContext.Provider value={editingProduct ?? null}>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin={copy ? 'PRODUCT_COPY' : 'PRODUCT_CREATE'} />
      )}
      <div>
        {(activeStep === 'basic' ||
          activeStep === 'reservation' ||
          activeStep === 'detailed') && (
          <ul className={styles['tutorial__nav']}>
            <li className={clsx(activeStep === 'basic' && styles['is-active'])}>
              STEP1<span> - {t('Basic Information')}</span>
            </li>
            <li
              className={clsx(
                activeStep === 'reservation' && styles['is-active']
              )}
            >
              STEP2<span> - {t('Reservation Parameters')}</span>
            </li>
            <li
              className={clsx(activeStep === 'detailed' && styles['is-active'])}
            >
              STEP3<span> - {t('Detail Information')}</span>
            </li>
          </ul>
        )}
        {activeStep === 'basic' && (
          <BasicStep
            copy={copy}
            sourceProductId={id || ''}
            onNextClick={(productId: string) => {
              setActiveStep('reservation');
              setEditingProductId(productId);
            }}
          />
        )}
        {activeStep === 'reservation' && (
          <ReservationParamsStep
            onNextClick={() => setActiveStep('detailed')}
            onBackClick={() => setActiveStep('basic')}
          />
        )}
        {activeStep === 'detailed' && (
          <DetailsStep
            onNextClick={() => setActiveStep('complete')}
            onBackClick={() => setActiveStep('reservation')}
          />
        )}
      </div>
    </EditingProductContext.Provider>
  );
};
