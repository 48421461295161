import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import clsx from 'clsx';

import { FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { batchCloseProductInstances } from 'client/actions/productInstances';
import baseStyles from 'client/v3-base.module.css';
import { Modal } from 'client/components/v3/Form/Modal';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

interface FormValues {
  startDate: string;
  endDate: string;
  productSelectionType: 'ALL_PRODUCTS' | 'SELECT_PRODUCTS';
  productIds: string[];
  memo: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CloseCalendarModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);

  const dispatch = useDispatch();
  const reduxStore = useStore<ReduxState>();

  const initialValues: FormValues = useMemo(
    () => ({
      startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      productSelectionType: 'ALL_PRODUCTS',
      productIds: [],
      memo: '',
    }),
    []
  );

  const productOptions = productSummaries
    .filter(
      (product) =>
        !product?.shared_allotment_references?.parent_product_id &&
        (
          product?.shared_allotment_references?.package_component_product_ids ??
          []
        ).length === 0
    )
    .map((product) => ({
      value: product.id ?? '',
      text: getVerboseDisplayProductName(product),
    }));

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        await dispatch(
          batchCloseProductInstances(
            values.startDate,
            moment(values.endDate).add(1, 'days').format('YYYY-MM-DD'),
            values.productIds,
            values.memo
          )
        );

        const error = reduxStore.getState().productInstances.batchCloseError;
        if (error) {
          return { [FORM_ERROR]: error };
        }

        onClose();
      }}
      validate={(values: FormValues) => {
        const errors: Partial<Record<keyof FormValues, string>> = {};
        if (values.startDate > values.endDate) {
          errors.startDate = t('Invalid date range');
        }
        if (
          moment(values.endDate).diff(moment(values.startDate), 'days') + 1 >=
          90
        ) {
          errors.startDate = t(
            'Sorry, the duration must be {{maxDuration}} days or less.',
            {
              maxDuration: 90,
            }
          );
        }
        if (
          values.productSelectionType === 'SELECT_PRODUCTS' &&
          values.productIds.length === 0
        ) {
          errors.productIds = t('At least one product required');
        }
        return errors;
      }}
    >
      {({ handleSubmit, values, submitting, submitError, form }) => (
        <Modal
          title={t('Close Calendars')}
          style={{ height: '568px' }}
          open={open}
          onClose={onClose}
          rightActionChildren={
            <>
              <Button
                text={t('Cancel')}
                size="md"
                color="white"
                onClick={onClose}
              />
              <Button
                // Associate button with form id
                form="closeCalendarForm"
                text={t('Save')}
                size="md"
                type="submit"
                loading={submitting}
              />
            </>
          }
        >
          <form onSubmit={handleSubmit} id="closeCalendarForm">
            <div className={commonStyles['p-availabilityModal']}>
              <div className={commonStyles['p-availabilityModal__item']}>
                <p className={commonStyles['p-availabilityModal__item__ttl']}>
                  {t('Select Date')}
                </p>
                <div
                  className={clsx(
                    commonStyles['p-availabilityModal__item__body'],
                    commonStyles['flex']
                  )}
                >
                  <Field name="startDate">
                    {({ input }) => (
                      <SimpleDateInput
                        onChange={input.onChange}
                        dateFrom={input.value}
                        name={input.name}
                        locale={locale}
                      />
                    )}
                  </Field>
                  <p>-</p>
                  <Field name="endDate">
                    {({ input }) => (
                      <SimpleDateInput
                        onChange={input.onChange}
                        dateFrom={input.value}
                        name={input.name}
                        locale={locale}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={commonStyles['p-availabilityModal__item']}>
                <p className={commonStyles['p-availabilityModal__item__ttl']}>
                  {t('Select Products')}
                </p>
                <div
                  className={commonStyles['p-availabilityModal__item__body']}
                >
                  <div
                    className={
                      commonStyles['p-availabilityModal__item__body__flex']
                    }
                    style={{ flexDirection: 'column' }}
                  >
                    <FieldWrapper>
                      <EnumRadioButtonGroup
                        name="productSelectionType"
                        options={[
                          {
                            label: t('All Products'),
                            value: 'ALL_PRODUCTS',
                          },
                          {
                            label: t('Select Products'),
                            value: 'SELECT_PRODUCTS',
                          },
                        ]}
                        onChange={(newValue: string) => {
                          if (newValue === 'ALL_PRODUCTS') {
                            form.change('productIds', []);
                          }
                        }}
                      />
                    </FieldWrapper>
                    {values?.productSelectionType === 'SELECT_PRODUCTS' && (
                      <Field name="productIds">
                        {({ input, meta: { touched, error } }) => (
                          <div
                            className={baseStyles['u-mt-4']}
                            style={{ width: '100%' }}
                          >
                            <MultiDropdown
                              options={productOptions}
                              onChange={(value) => input.onChange(value)}
                              selectedOptions={input.value}
                              searchable={true}
                              error={touched && error}
                            />
                          </div>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
              </div>
              <div className={commonStyles['p-availabilityModal__item']}>
                <p className={commonStyles['p-availabilityModal__item__ttl']}>
                  {t('Memo')}
                </p>
                <div
                  className={commonStyles['p-availabilityModal__item__body']}
                >
                  <Field name="memo">
                    {({ input }) => (
                      <TextArea
                        value={input.value}
                        height={100}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                  <p>
                    {t(
                      '* Calendar will be closed for all channels on the selected date. Please edit availability separately if you need to re-open on a particular product.'
                    )}
                  </p>
                  {submitError && (
                    <p className={baseStyles['base-form-box__err']}>
                      {t('Error closing calendars. Please try again.')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </Form>
  );
};
