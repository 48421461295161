import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Modal } from 'client/components/v3/Form/Modal';
import { ReservationActorInputForm } from 'client/pages/v3/Reservation/ReservationCreate/Booking/Form/ReservationActorInputForm';
import type { ReduxState } from 'client/reducers/index';
import { Button } from 'client/components/v3/Common/Button';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import type { Reservation } from 'shared/models/swagger';

type Props = {
  reservation: Reservation;
  onSubmit: () => void;
  open: boolean;
  onClose: () => void;
};

export const SubmitReservationModal = ({
  open,
  onClose,
  onSubmit,
  reservation,
}: Props) => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const startDateTime = moment
    .tz(reservation.start_date_time_utc, reservation.start_timezone ?? '')
    .locale(locale);

  const handleBook = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      title={t('Confirm booking submission')}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            color="white"
            size="md"
            type="button"
            onClick={onClose}
          />
          <Button
            text={t('Book')}
            color="primary"
            size="md"
            type="button"
            onClick={handleBook}
          />
        </>
      }
    >
      <div>
        <FieldWrapper label={t('Product')} wrapperClassName="p-modal__item">
          {reservation.product_name}
        </FieldWrapper>
        <FieldWrapper label={t('Date')} wrapperClassName="p-modal__item">
          {startDateTime.format('ll')}
        </FieldWrapper>
        <FieldWrapper label={t('Time')} wrapperClassName="p-modal__item">
          {startDateTime.format('LT')}
        </FieldWrapper>
        <FieldWrapper
          label={t('Total participants')}
          wrapperClassName="p-modal__item"
        >
          {(reservation.guests && reservation.guests.length) || 0}
        </FieldWrapper>
        <FieldWrapper label={t('Your Name')} wrapperClassName="p-modal__item">
          <ReservationActorInputForm />
        </FieldWrapper>
      </div>
    </Modal>
  );
};
