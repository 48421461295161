import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import type { TranslateFuncType } from 'client/components/Translate';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import { getEmailTypeText } from 'client/libraries/util/getEmailTypeText';
import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { resendEmail } from 'client/actions/emails';
import { fetchReservationByID } from 'client/actions/reservations';
import { Message } from 'client/components/Message/Message';
import { EmailSummary } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import emailOpenedIcon from 'client/images/ic_email_opened.svg';

import { CustomerReservationsContext } from './CustomerReservationsContext';
import { CustomerContext } from './CustomerContext';

interface Props {
  active: boolean;
}

export const CustomerEmailHistory = ({ active }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const reservations = React.useContext(CustomerReservationsContext);
  const customer = React.useContext(CustomerContext);

  // State to keep track the resending email Id.
  const [resendEmailId, setResendEmailId] = React.useState<string>('');
  // State to show or hide a resend success message.
  const [resendEmailSucceeded, setResendEmailSucceeded] =
    React.useState<boolean>(false);
  // State to show or hide a resend error message.
  const [resendEmailError, setResendEmailError] =
    React.useState<boolean>(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const emailLogs = React.useMemo(() => {
    const emailLogs: EmailSummary[] = [];
    for (const reservation of reservations ?? []) {
      for (const email of reservation.email_log ?? []) {
        if (email.recipient_entity_type === 'GUEST_ENTITY_TYPE') {
          emailLogs.push(email);
        }
      }
    }
    return emailLogs.sort((a, b) => {
      return moment
        .tz(a.sent_date_time_utc, 'UTC')
        .isAfter(moment.tz(b.sent_date_time_utc, 'UTC'))
        ? -1
        : 1;
    });
  }, [reservations]);

  const openedEmails = React.useMemo(() => {
    return [
      ...new Set(
        emailLogs
          .filter((item) => item.email_opened_at_date_time_utc)
          .map((item) => item.parent_email_id)
      ),
    ];
  }, [emailLogs]);

  const resendEmailByEmailId = async (emailId: string | undefined) => {
    if (!emailId) {
      return;
    }

    try {
      setResendEmailId(emailId);
      setResendEmailSucceeded(false);
      setResendEmailError(false);

      await dispatch(resendEmail(emailId, ''));

      const reservation = reservations?.find((reservation) => {
        return reservation.email_log?.find((email) => email.id === emailId);
      });

      if (reservation) {
        await dispatch(fetchReservationByID(reservation.id));
      }

      setResendEmailId('');
      setResendEmailSucceeded(true);
    } catch (e) {
      setResendEmailId('');
      setResendEmailError(true);
    }
  };

  const getColumns = (
    local: string,
    startTimezone: string,
    t: TranslateFuncType
  ): ColumnType<EmailSummary>[] => {
    return [
      {
        Header: '',
        id: '',
        accessor: (item: EmailSummary) => {
          return (
            <>
              {item.parent_email_id ? (
                <div className={clsx(baseStyles['base-t-60'])}>
                  {openedEmails.includes(item.parent_email_id) ? (
                    <div
                      className={clsx(
                        pageStyles['page-reservations__resend_email']
                      )}
                    >
                      <img src={emailOpenedIcon} />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        pageStyles['page-reservations__resend_email']
                      )}
                    >
                      <Button
                        size="small"
                        style="blue"
                        loading={item.id == resendEmailId}
                        onClick={() => {
                          resendEmailByEmailId(item.id);
                        }}
                        disabled={Boolean(
                          resendEmailId && item.id != resendEmailId
                        )}
                      >
                        {t('Resend')}
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className={clsx(baseStyles['base-t-60'])}>&nbsp;</div>
              )}
            </>
          );
        },
        width: 120,
      },
      {
        Header: t('Date/Time'),
        id: 'date_time',
        accessor: (item: EmailSummary) => {
          return getDateTimeDisplay(
            moment.tz(item.sent_date_time_utc ?? '', startTimezone ?? 'UTC'),
            local
          );
        },
        width: 120,
      },
      {
        Header: t('Type'),
        id: 'type',
        accessor: (item: EmailSummary) => {
          return item.email_type && getEmailTypeText(item.email_type, t);
        },
        width: 120,
      },
      {
        Header: t('Subject'),
        id: 'product_name',
        accessor: (item: EmailSummary) => {
          return item.email_title;
        },
      },
    ];
  };

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        active ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Email History')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GenericTable<EmailSummary>
          items={emailLogs.filter((_, index) => {
            const start = (currentPage - 1) * rowCount;
            const end = start + rowCount;
            return index >= start && index < end;
          })}
          totalCount={emailLogs?.length ?? 0}
          columns={getColumns(locale, customer?.start_timezone ?? 'UTC', t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </div>
      {resendEmailSucceeded && (
        <li key={'resend_success_msg'}>
          <Message success header={t('Send Successful')} />
        </li>
      )}
      {resendEmailError && (
        <li key={'resend_error_msg'}>
          <Message error header={t('Send Failed')} />
        </li>
      )}
    </div>
  );
};
