import { ReservationDataDownloadHeader } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadHeader/ReservationDataDownloadHeader';
import { ReservationDataDownloadInfo } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/ReservationDataDownloadInfo';
import { ReservationDataDownloadTable } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadTable/ReservationDataDownloadTable';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ReservationDataDownload = () => {
  return (
    <V3Page>
      <ReservationDataDownloadHeader />
      <ReservationDataDownloadInfo />
      <ReservationDataDownloadTable />
    </V3Page>
  );
};
