export const getBookingWidgetMultiProductCalendarUrl = (
  apiKey: string | null,
  language?: string
): string => {
  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  if (bookingWidgetBaseUrl && apiKey) {
    const params = new URLSearchParams();
    if (language) {
      params.set('lng', language);
    }

    return `${bookingWidgetBaseUrl}/embed/${apiKey}/multiProductCalendar?${params.toString()}`;
  }

  return '';
};
