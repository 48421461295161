import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import type { ResourceType } from 'client/libraries/util/resourceType';
import { Button } from 'client/components/Form';
import { ResourceAssignmentModal } from 'client/components/ResourceAssignmentModal/ResourceAssignmentModal';
import { SendEmailsButton } from 'client/pages/Manifest/SendEmailButton';
import { StartTimeManifestReservationsTableList } from 'client/components/StartTimeManifestReservationsTableList/StartTimeManifestReservationsTableList';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  fetchManifestPDF,
  sendManifestEmail,
  fetchManifestCSV,
} from 'client/actions/manifests';
import { ManifestActiveResourceContext } from 'client/contexts/ManifestActiveResourceContext';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchResourceAssignments } from 'client/actions/resourceAssignments';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import type {
  ManifestExportParams,
  ReservationColumn,
  ManifestCustomizedColumnName,
  ProductSummary,
} from 'shared/models/swagger';
import humanIcon from 'client/images/ic_human_black.svg';
import vehicleIcon from 'client/images/ic_dispatch_black.svg';
import reservationIcon from 'client/images/ic_reservations_black.svg';
import anotherIcon from 'client/images/ic_another.svg';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  loading: boolean;
  onResourceClick: () => void;
  participationDate: string;
  reservations: ManifestReservationShape[];
  resourceKey: string;
  resourceType: ResourceType;
  groupBy: 'START_TIME' | 'TRANSPORT_ROUTE' | 'PICKUP_TIME';
  visibleColumns: ReservationColumn[];
  title: string;
  open?: boolean;
  capacity?: number;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  orderByColumns: ReservationColumn[];
};

const ResourceIcon = ({ resourceType }: { resourceType: string }) => {
  if (resourceType === 'vehicle') {
    return <img src={vehicleIcon} />;
  } else if (resourceType === 'crew' || resourceType === 'guide') {
    return <img src={humanIcon} />;
  }

  return <img src={reservationIcon} />;
};

const getManifestExportDispatchFilterFieldName = (
  resourceType: ResourceType
):
  | 'dispatch_vehicles'
  | 'dispatch_crew'
  | 'dispatch_misc_resources'
  | 'dispatch_guides' => {
  if (resourceType === 'vehicle') {
    return 'dispatch_vehicles';
  } else if (resourceType === 'crew') {
    return 'dispatch_crew';
  } else if (resourceType === 'guide') {
    return 'dispatch_guides';
  }

  return 'dispatch_misc_resources';
};

export const ResourceListItem = ({
  onResourceClick,
  participationDate,
  reservations,
  resourceKey,
  resourceType,
  groupBy,
  visibleColumns,
  title,
  capacity,
  customizedColumnNames,
  orderByColumns,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const csvStatus = useSelector(
    (state: ReduxState) => state.manifests.csvStatus
  );
  const pdfStatus = useSelector(
    (state: ReduxState) => state.manifests.pdfStatus
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const [showAnotherButtons, setShowAnotherButtons] =
    React.useState<boolean>(false);
  const [openSendEmailModal, setOpenSendEmailModal] =
    React.useState<boolean>(false);
  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const products: ProductSummary[] = [];
  reservations.forEach((reservation) => {
    const product = productSummaries.find(
      (p) => p.id === reservation.product_id
    );
    if (product) {
      products.push(product);
    }
  });

  const formattedDate = moment(participationDate).locale(locale).format('LL');
  // Special rules apply when 'TRANSPORT_ROUTE' is selected for dispatch:
  //
  // Resource Case A: resource is assigned to at least one transport route
  // 1) Reservations are expanded into separate copies for each leg of the transport route.
  // 2) These expanded reservations are sorted by transport arrival time.
  // 3) For each resource, reservations are shown only if that resource is assigned to reservation's transport
  //    route leg.
  //
  // Resource Case B: resource is only assigned to reservations not transport routes
  // 1) Reservations are shown with empty transport route info
  // 2) Reservations are grouped by pickup time
  const shouldShowTransportRouteAssignmentFormat = (
    visibleColumns ?? []
  ).includes('TRANSPORT_ROUTE');
  const manifestExportRequest: ManifestExportParams = {
    date: participationDate,
    columns: visibleColumns,
    order_by_columns: orderByColumns,
    customized_column_names: customizedColumnNames,
    should_show_transport_route_assignment_format:
      shouldShowTransportRouteAssignmentFormat,
  };
  manifestExportRequest[
    getManifestExportDispatchFilterFieldName(resourceType)
  ] = [resourceKey];
  let description = null;

  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);

  if (resourceType === 'guide') {
    const guide = (activeUserOrganization?.guides || []).find(
      (guide) => guide.id === resourceKey
    );
    description = guide?.description;
  } else if (resourceType === 'crew') {
    const crew = (
      activeUserOrganization?.dispatch_settings?.dispatch_crew_members || []
    ).find((crew) => crew.key === resourceKey);
    description = crew?.description;
  } else if (resourceType === 'vehicle') {
    const vehicle = (
      activeUserOrganization?.dispatch_settings?.dispatch_vehicles || []
    ).find((vehicle) => vehicle.key === resourceKey);
    description = vehicle?.description;
  } else {
    const other = (
      activeUserOrganization?.dispatch_settings?.dispatch_misc_resources || []
    ).find((other) => other.key === resourceKey);
    description = other?.description;
  }
  React.useEffect(() => {
    if (resourceType === 'other') {
      dispatch(
        fetchResourceAssignments(
          'RESOURCE_TYPE_OTHER',
          resourceKey,
          moment
            .tz(
              participationDate,
              activeUserOrganization?.default_timezone ?? 'UTC'
            )
            .utc()
            .format(),
          moment
            .tz(
              participationDate,
              activeUserOrganization?.default_timezone ?? 'UTC'
            )
            .add(1, 'day')
            .utc()
            .format()
        )
      );
    }
  }, [participationDate, resourceType]);

  return (
    <div>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <a onClick={() => onResourceClick()}>
          <h3 className={clsx(componentStyles['dispatch-resource'])}>
            <ResourceIcon resourceType={resourceType} />
            <div className={clsx(componentStyles['dispatch-resource-content'])}>
              <p> {title} </p>
              <div
                className={clsx(
                  componentStyles['dispatch-resource-content-sub']
                )}
              >
                {reservations.length === 0 && <>{t('no assignments')}</>}
              </div>
            </div>
            {description && (
              <Tooltip
                icon={<i className={baseStyles['base-info__ic']}>i</i>}
                text={description}
              />
            )}
          </h3>
        </a>

        {hasCustomUserRoleWritePermissions(
          activeUser,
          'MANIFEST.RESOURCE_ASSIGNMENT'
        ) && (
          <div>
            <ResourceAssignmentModal
              trigger={
                <button
                  type="button"
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['middle'],
                    baseStyles['yellow']
                  )}
                >
                  {t('Assign')}
                </button>
              }
              resourceType={resourceType}
              resourceKey={resourceKey}
              title={title}
              customizedColumnNames={customizedColumnNames}
              visibleColumns={visibleColumns}
            />
          </div>
        )}

        {reservations.length > 0 && (
          <>
            <div
              className={clsx(
                baseStyles['base-main__body__header__right'],
                baseStyles['spOrder-2']
              )}
            >
              <div
                className={clsx(
                  baseStyles['base-main__body__header__right__another'],
                  showAnotherButtons
                    ? baseStyles['is-open']
                    : baseStyles['is-close']
                )}
              >
                <a
                  onClick={() => {
                    setShowAnotherButtons(!showAnotherButtons);
                  }}
                >
                  <img src={anotherIcon} />
                </a>
                <ul>
                  <li>
                    <Button
                      size={'middle'}
                      style={'gray'}
                      onClick={() =>
                        dispatch(fetchManifestPDF(manifestExportRequest))
                      }
                      loading={pdfStatus === 'IN_FLIGHT'}
                    >
                      {t('Download as PDF')}
                    </Button>
                  </li>
                  <li>
                    <Button
                      size={'middle'}
                      style={'gray'}
                      onClick={() => {
                        const manifestExportRequestForCSV: ManifestExportParams =
                          {
                            ...manifestExportRequest,
                            columns: [
                              'START_TIME',
                              ...(manifestExportRequest.columns || []),
                            ],
                            order_by_columns: [
                              'START_TIME',
                              ...(manifestExportRequest.order_by_columns || []),
                            ],
                          };
                        dispatch(fetchManifestCSV(manifestExportRequestForCSV));
                      }}
                      loading={csvStatus === 'IN_FLIGHT'}
                    >
                      {t('Download as CSV')}
                    </Button>
                  </li>
                  <li>
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['middle'],
                        baseStyles['gray']
                      )}
                      onClick={() => {
                        setOpenSendEmailModal(true);
                      }}
                    >
                      {t('Send Emails')}
                    </a>
                  </li>
                </ul>
              </div>

              <SendEmailsButton
                defaultSubject={t(
                  'Manifest - {{resourceKey}} - {{formattedDate}}',
                  {
                    resourceKey: title,
                    formattedDate,
                  }
                )}
                open={openSendEmailModal}
                onClick={(subject: string, toAddresses: string[]) =>
                  dispatch(
                    sendManifestEmail({
                      subject,
                      to_addresses: toAddresses,
                      ...manifestExportRequest,
                    })
                  )
                }
                onClose={() => {
                  setOpenSendEmailModal(false);
                }}
              />
            </div>
          </>
        )}
      </div>

      {reservations.length > 0 && (
        <>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <ManifestActiveResourceContext.Provider
              value={{
                activeResourceType: resourceType,
                activeResourceKey: resourceKey,
                shouldShowTransportRouteAssignmentFormat,
              }}
            >
              <StartTimeManifestReservationsTableList
                excludedFormFieldKeys={excludedFormFieldKeys}
                reservations={reservations}
                products={products}
                visibleColumns={visibleColumns}
                capacity={capacity}
                showTimeOnly={true}
                resourceKey={resourceKey}
                resourceType={resourceType}
                customizedColumnNames={customizedColumnNames}
                groupBy={groupBy}
              />
            </ManifestActiveResourceContext.Provider>
          </div>
        </>
      )}
    </div>
  );
};
