import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchETicketQrCodes,
  deleteETicketQrCode,
} from 'client/actions/eTicketQrCode';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import type { TranslateFuncType } from 'client/components/Translate';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Box } from 'client/components/Box/Box';
import { activeUserSelector } from 'client/reducers/user';
import { Button } from 'client/components/Form';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { summariesSelector } from 'client/reducers/products';
import baseStyles from 'client/base.module.css';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import type {
  ETicketQrCode,
  ETicketQrCodeLink,
} from 'shared/models/swagger/index';
import qrCodeIcon from 'client/images/ic_qr_code.svg';

import { EditETicketQrCodeModal } from './EditETicketQrCodeModal';
import { ETicketQrCodeDownloadModal } from './ETicketQrCodeDownloadModal';

const ControlCell = ({
  eTicketQrCode,
  onEditETicketQrCodeChange,
}: {
  eTicketQrCode: ETicketQrCode;
  onEditETicketQrCodeChange: (eTicketQrCode: ETicketQrCode | null) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  return (
    <Box display="flex" alignItems="center">
      <Box ml={2}>
        <button
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['icon'],
            baseStyles['narrow']
          )}
          onClick={() => onEditETicketQrCodeChange(eTicketQrCode)}
        >
          <img src={editIcon} />
        </button>
      </Box>

      <Box ml={2}>
        <button
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['icon'],
            baseStyles['narrow']
          )}
          onClick={() => setShowDeleteModal(true)}
        >
          <img src={deleteIcon} />
        </button>
      </Box>

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete Location Set')}
          content={t('Are you sure you want to delete location set?')}
          onConfirm={async () => {
            await dispatch(deleteETicketQrCode(eTicketQrCode.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}
    </Box>
  );
};

const LinkCell = ({ qrCodeLinks }: { qrCodeLinks: ETicketQrCodeLink[] }) => {
  const productSummaries = useSelector(summariesSelector);

  return (
    <ul>
      {qrCodeLinks.map((link, idx) => {
        const parts = [];

        const productSummary = productSummaries.find(
          (summary) => summary.id === link.product_id
        );

        if (productSummary) {
          parts.push(productSummary.product_name);
        }

        const stub = (productSummary?.qr_checkin_settings?.stubs ?? []).find(
          (stub) => stub.key === link.stub_key
        );

        if (stub) {
          parts.push(stub.text);
        }

        const stubOption = (stub?.options ?? []).find(
          (option) => option.key === link.stub_option_key
        );

        if (stubOption) {
          parts.push(stubOption.text);
        }

        return <li key={idx}>{parts.join(' - ')}</li>;
      })}
    </ul>
  );
};

export const ETicketQrCodeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eTicketQrCodes = useSelector((state: any) => state.eTicketQrCodes.all);
  const activeUser = useSelector(activeUserSelector);
  const [existingETicketQrCode, setExistingETicketQrCode] =
    React.useState<ETicketQrCode | null>(null);
  const [downloadQrCodeId, setDownloadQrCodeId] = React.useState<string | null>(
    null
  );

  const sortedETicketQrCodes = React.useMemo(() => {
    return eTicketQrCodes.sort((a: ETicketQrCode, b: ETicketQrCode) => {
      if ((a?.title ?? '') < (b?.title ?? '')) {
        return 1;
      }
      return -1;
    });
  }, [eTicketQrCodes]);

  const [showEditETicketQrCodeModal, setShowEditETicketQrCodeModal] =
    React.useState(false);

  React.useEffect(() => {
    dispatch(fetchETicketQrCodes());
  }, [dispatch]);

  const getColumns = (t: TranslateFuncType): ColumnType<ETicketQrCode>[] => {
    return [
      ...(hasCustomUserRoleWritePermissions(activeUser, 'E_TICKET')
        ? ([
            {
              Header: '',
              id: 'edit',
              accessor: (row: ETicketQrCode) => (
                <ControlCell
                  eTicketQrCode={row}
                  onEditETicketQrCodeChange={() => {
                    setExistingETicketQrCode(row);
                    setShowEditETicketQrCodeModal(true);
                  }}
                />
              ),
              width: 120,
            },
          ] as ColumnType<ETicketQrCode>[])
        : []),
      {
        Header: t('QR code'),
        id: 'qrCode',
        accessor: (row: ETicketQrCode) => {
          return (
            <div>
              <a
                onClick={() => {
                  setDownloadQrCodeId(row.id ?? '');
                }}
              >
                <img
                  style={{ width: '32px', height: '32px' }}
                  src={qrCodeIcon}
                />
              </a>
            </div>
          );
        },
        width: 80,
      },
      {
        Header: t('Title'),
        id: 'title',
        accessor: (row: ETicketQrCode) => row.title,
        width: 120,
      },
      {
        Header: t('Description'),
        id: 'description',
        accessor: (row: ETicketQrCode) => row.description,
        width: 200,
      },
      {
        Header: t('Related Usage'),
        id: 'relatedUsage',
        accessor: (row: ETicketQrCode) => {
          return <LinkCell qrCodeLinks={row.qr_code_links ?? []} />;
        },
      },
    ];
  };

  return (
    <div>
      <Box mb={2}>
        {hasCustomUserRoleWritePermissions(activeUser, 'E_TICKET') && (
          <Button
            size="middle"
            style="green"
            onClick={() => setShowEditETicketQrCodeModal(true)}
          >
            {t('Create New E-ticket QR Code')}
          </Button>
        )}
      </Box>
      <GenericTable<ETicketQrCode>
        items={sortedETicketQrCodes ?? []}
        totalCount={(sortedETicketQrCodes ?? []).length}
        columns={getColumns(t)}
        rowCount={10}
        currentPage={0}
        onRowCountChange={(rowCount: number) => {
          console.log(rowCount);
        }}
        onCurrentPageChange={(newCurrentPage: number) => {
          console.log(newCurrentPage);
        }}
      />
      {showEditETicketQrCodeModal && (
        <EditETicketQrCodeModal
          existingETicketQrCode={existingETicketQrCode}
          open={showEditETicketQrCodeModal}
          onClose={() => {
            setExistingETicketQrCode(null);
            setShowEditETicketQrCodeModal(false);
          }}
        />
      )}
      {downloadQrCodeId && (
        <ETicketQrCodeDownloadModal
          eTicketQrCodeId={downloadQrCodeId}
          open={!!downloadQrCodeId}
          onClose={() => setDownloadQrCodeId(null)}
        />
      )}
    </div>
  );
};
