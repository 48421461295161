/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';

const SearchFilter: React.FC = () => (
  <div className={styles.searchFilterContainer}>
    <div className={styles.searchInputWrapper}>
      <input type="text" placeholder="Search" className={styles.searchInput} />
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/568209518f65a10f636dd77dc9fa86d9775952310d6e803e64aadc1b212f6c35?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
        alt=""
        className={styles.searchIcon}
      />
    </div>
    <button className={styles.filterButton}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/85a6fe3c193888009de37e4bc4e68b656a0289e33214c5e59b376c5b69709258?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
        alt=""
        className={styles.filterIcon}
      />
      <span>Filters</span>
    </button>
  </div>
);

export default SearchFilter;
