import _ from 'lodash';
import type { Moment } from 'moment-timezone';

export const groupReservationsByNormalizedStartTime = <
  ReservationType extends {
    participates_at: Moment;
  }
>(
  reservations: ReservationType[]
): {
  startDateTime: Moment;
  reservations: ReservationType[];
}[] => {
  const startDateTimes = _.uniqBy(
    reservations.map((r) => r.participates_at),
    (p) => p.valueOf()
  );

  return _.orderBy(startDateTimes, [(p) => p.valueOf()]).map(
    (startDateTime) => ({
      startDateTime: startDateTime,
      reservations: reservations.filter(
        (r) => r.participates_at.valueOf() == startDateTime.valueOf()
      ),
    })
  );
};
