import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS,
  SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { eTicketDailyUsesDisplaySettings } from 'client/libraries/util/eTicketDailyUsesSettings';
import { defaultVisibleColumns } from 'client/libraries/util/eTicketDailyUsesSettings';

export const eTicketDailyUsesVisibleColumnsSelector = (state: ReduxState) =>
  state.eTicketDailyUsesSettings.visibleColumns;

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const defaultDisplaySettings: eTicketDailyUsesDisplaySettings = {
  productId: '',
  selectedDate: '',
  selectedProductGroup: '',
  shownPerProductGroup: false,
};

const displaySettings = (state = defaultDisplaySettings, action: any) => {
  switch (action.type) {
    case SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS:
      return action.displaySettings;

    default:
      return state;
  }
};

type State = {
  displaySettings: eTicketDailyUsesDisplaySettings;
  visibleColumns: string[];
};

const reducer = combineReducers<State>({
  displaySettings,
  visibleColumns,
});

export const eTicketDailyUsesSettings = (state: State, action: any) => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
