import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper, Button } from 'client/components/Form';
import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import type { ReduxState } from 'client/reducers';
import { getFormattedTime } from 'client/libraries/util/util';
import type { ReservationColumn, ProductSummary } from 'shared/models/swagger';

import { ResourceBulkUpdateReservationsTable } from './ResourceBulkUpdateReservationTable';

type Props = {
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  excludedFormFieldKeys: string[];
  products: ProductSummary[];
  open: boolean;
  onClose: () => void;
  onResourceAssignmentButtonChange: (arg0: string[]) => void;
};
export const ResourceBulkUpdateModal = ({
  reservations,
  visibleColumns,
  excludedFormFieldKeys,
  products,
  open,
  onClose,
  onResourceAssignmentButtonChange,
}: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationsByStartTime = groupReservationsByStartTime(
    reservations,
    locale
  );
  const [selectedReservationIds, setSelectedReservationIds] = React.useState<
    string[]
  >([]);
  return (
    <Modal
      title={t('Bulk assign resources')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <Modal.Content>
        <Modal.Box>
          <FieldWrapper label={t('Select Reservations')}></FieldWrapper>
        </Modal.Box>

        {reservationsByStartTime.map(({ startTime, reservations }, idx) => {
          return (
            <Modal.Box key={idx}>
              <FieldWrapper
                label={t('Start time: {{startTime}}', {
                  startTime: getFormattedTime(startTime, locale),
                })}
              >
                <ResourceBulkUpdateReservationsTable
                  excludedFormFieldKeys={excludedFormFieldKeys || []}
                  reservations={reservations}
                  visibleColumns={visibleColumns}
                  selectedReservationIds={selectedReservationIds.filter((id) =>
                    reservations.map((r) => r.id).includes(id)
                  )}
                  onSelectedReservationChange={(ids: string[]) => {
                    setSelectedReservationIds([
                      // unique array
                      ...new Set([
                        // remove unselected id
                        ...selectedReservationIds.filter((id) => {
                          if (
                            reservations.map((r) => r.id).includes(id) &&
                            !ids.includes(id)
                          ) {
                            return false;
                          }

                          return true;
                        }), // add selected id
                        ...ids,
                      ]),
                    ]);
                  }}
                  products={products}
                />
              </FieldWrapper>
            </Modal.Box>
          );
        })}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>

        <Button.Submit
          disabled={selectedReservationIds.length === 0}
          onClick={() => {
            onResourceAssignmentButtonChange(selectedReservationIds);
          }}
        >
          {t('Select resources')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
