import * as React from 'react';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';

import styles from './Message.module.css';

type Props = {
  warning?: boolean;
  success?: boolean;
  error?: boolean;
  info?: boolean;
  header?: string;
  content?: string;
  children?: React.ReactNode;
  autoHide?: boolean;
};
export const Message = ({
  warning,
  success,
  error,
  info,
  header,
  content,
  children,
  autoHide,
}: Props) => {
  const [showMessage, setShowMessage] = React.useState(true);
  let alert = '';

  if (warning) {
    alert = 'warning';
  } else if (success) {
    alert = 'success';
  } else if (error) {
    alert = 'error';
  } else if (info) {
    alert = 'info';
  } else {
    alert = '';
  }

  // Auto-clear message after 10 seconds
  React.useEffect(() => {
    if (!autoHide) {
      return;
    }

    const deleteMessageTimer = setTimeout(() => {
      setShowMessage(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(deleteMessageTimer);
    };
  }, [autoHide]);

  if (!showMessage) {
    return null;
  }

  return (
    <div
      className={clsx(
        baseStyles['message'],
        alert && baseStyles[alert],
        autoHide && styles['fade-out']
      )}
    >
      {header && <div className={clsx(baseStyles['header'])}>{header}</div>}
      {content && <p>{content}</p>}
      {children && children}
    </div>
  );
};
