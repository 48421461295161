import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../Form/Modal';
import { Button } from '../Common/Button';

type Props = {
  header: string;
  content: ReactNode;
  onConfirm: () => any;
  loading?: boolean;
  onClose: () => void;
  insertAtRoot?: boolean;
};
export const DeleteConfirmModal = ({
  header,
  content,
  onConfirm,
  loading,
  onClose,
  insertAtRoot,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={header}
      insertAtRoot={insertAtRoot}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            color="white"
            onClick={onClose}
            loading={loading}
          />
          <Button
            text={t('OK')}
            color="red"
            onClick={async () => {
              await onConfirm();
              onClose?.();
            }}
            loading={loading}
          />
        </>
      }
    >
      {content}
    </Modal>
  );
};
