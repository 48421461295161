import * as React from 'react';
import clsx from 'clsx';

import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import { Button } from 'client/components/v3/Common/Button';

import styles from './EditorButtonsRow.module.css';

type Props = {
  label: string;
  children: React.ReactNode;
};
export const ButtonMenu = ({ children, label }: Props) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const areaRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const handleOutsideClick: any = ({
      target,
    }: React.SyntheticEvent<HTMLElement>) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOption]);
  return (
    <div className={styles['base-form-box']} {...getTouchEventBoundaryProps()}>
      <div className={styles['base-form-box__body']}>
        <div className={clsx(styles['c-form-single'])} ref={areaRef}>
          <Button
            text={label}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => setShowOption(!showOption)}
          />
          {showOption && (
            <ul
              onClick={() => {
                setShowOption(false);
              }}
              className={clsx(styles['option'], styles['c-list'])}
            >
              {children}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export const ButtonMenuItem = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return <li onClick={onClick}>{text}</li>;
};
