import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { fetchGuidanceCoupons } from 'client/actions/guidanceCoupon';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ReduxState } from 'client/reducers';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  createMarketingAutomationTriggerCondition,
  updateMarketingAutomationTriggerCondition,
} from 'client/actions/marketingAutomationTriggerConditions';
import { config } from 'client/config';
import { fetchGuidanceStampRallies } from 'client/actions/guidanceStampRally';

import {
  convertFormValuesToSwagger,
  convertSwaggerToFormValues,
  FormValues,
} from './FormValues';
import { ProximityLocationEditor } from './ProximityLocationEditor';
import { ConditionRuleListEditor } from './ConditionRuleListEditor';
import { DelayInput } from './DelayInput';
import { AdvanceInput } from './AdvanceInput';

const defaultInitialValues: FormValues = {
  title: '',

  // Trigger
  displayTriggerType: 'ETICKET_BEFORE_REDEMPTION',
  triggerCouponIds: [],
  triggerStampRallyIds: [],

  triggerTiming: 'IMMEDIATE',

  triggerDelay: {
    delayType: 'HOUR',
    count: 0,
    timeOfDay: '00:00',
  },
  triggerAdvance: {
    advanceType: 'HOUR',
    count: 0,
    timeOfDay: '00:00',
  },

  // Conditions
  conditionRules: [
    {
      shouldFilterByBookedProductIds: false,
      bookedProductIds: [],
      participationStartDate: '',
      participationEndDate: '',
      shouldFilterByTimeOfDay: false,
      timeOfDayStart: '00:00',
      timeOfDayEnd: '23:59',

      // Limits
      limitType: 'NO_LIMIT',
      expirationTimeOfDay: '23:59',
    },
  ],
};

export const MarketingAutomationTriggerConditionEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const coupons = useSelector((state: ReduxState) => state.guidanceCoupons.all);
  const stampRallies = useSelector(
    (state: ReduxState) => state.guidanceStampRallies.all
  );

  const existingTrigger = useSelector((state: ReduxState) =>
    state.marketingAutomationTriggerConditions.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchGuidanceCoupons());
    dispatch(fetchGuidanceStampRallies());
  }, []);

  const initialValues = React.useMemo(() => {
    return existingTrigger
      ? convertSwaggerToFormValues(existingTrigger)
      : defaultInitialValues;
  }, [existingTrigger]);

  const couponOptions = coupons.map((coupon) => ({
    value: coupon.id ?? '',
    text: coupon.title ?? '',
  }));

  const stampRallyOptions = stampRallies.map((stampRally) => ({
    value: stampRally.id ?? '',
    text: stampRally.title ?? '',
  }));

  const displayTriggerTypeOptions = [
    {
      value: 'ETICKET_BEFORE_REDEMPTION',
      label: t('E-Ticket: ticket page is opened (before redemption)'),
    },
    {
      value: 'ETICKET_AFTER_REDEMPTION',
      label: t('E-Ticket: ticket is redeemed'),
    },
    {
      value: 'RESERVATION_THANK_YOU_PAGE',
      label: t('New Reservation: "Thank You" page is navigated to'),
    },
    {
      value: 'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION',
      label: t(
        'Online Guide OR Digital Map: customer is detected near a key location'
      ),
    },
    {
      value: 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION',
      label: t('Online Guide OR Digital Map: coupon is redeemed'),
    },
    {
      value: 'START_TIME',
      label: t('Start Time'),
    },
    {
      value: 'MAP_PAGE_VIEW',
      label: t('Digital Map: map page is viewed'),
    },
    ...(config.enableNpsSurvey
      ? [
          {
            value: 'ONLINE_GUIDE_AFTER_STAMP_RALLY_COMPLETION',
            label: t('Online Guide OR Digital Map: stamp rally is completed'),
          },
        ]
      : []),
  ];

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2}>
          <Link to="/marketingautomation/triggers">
            <BackArrow />
          </Link>
        </Box>
        <Form
          onSubmit={async (values: FormValues) => {
            if (id) {
              await dispatch(
                updateMarketingAutomationTriggerCondition(
                  id,
                  convertFormValuesToSwagger(values)
                )
              );
            } else {
              await dispatch(
                createMarketingAutomationTriggerCondition(
                  convertFormValuesToSwagger(values)
                )
              );
            }
          }}
          initialValues={initialValues}
          debug={console.log}
          mutators={getArrayMutators()}
        >
          {({
            handleSubmit,
            submitting,
            values,
            submitSucceeded,
            submitError,
            modifiedSinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Basics')}</th>
                      <td>
                        <Field name="title">
                          {({ input }) => (
                            <Input label={t('Name')} {...input} />
                          )}
                        </Field>
                        <Box mt={2}>
                          <FieldWrapper label={t('Trigger Event')} />
                          <EnumRadioButtonGroup
                            name="displayTriggerType"
                            options={displayTriggerTypeOptions}
                            liStyle={{ width: '400px' }}
                            onChange={(newValue) => {
                              if (
                                newValue !== 'START_TIME' &&
                                values?.triggerTiming === 'BEFORE_EVENT'
                              ) {
                                values.triggerTiming = 'IMMEDIATE';
                              }
                            }}
                          />
                          {values?.displayTriggerType ===
                            'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION' && (
                            <ProximityLocationEditor />
                          )}
                          {values?.displayTriggerType ===
                            'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION' && (
                            <Field name="triggerCouponIds">
                              {({ input }) => (
                                <MultiSelect
                                  label={t('Trigger Coupons')}
                                  search
                                  options={couponOptions}
                                  selectedValues={input.value}
                                  onChange={({ value }) =>
                                    input.onChange(value)
                                  }
                                />
                              )}
                            </Field>
                          )}
                          {values?.displayTriggerType ===
                            'ONLINE_GUIDE_AFTER_STAMP_RALLY_COMPLETION' && (
                            <Field name="triggerStampRallyIds">
                              {({ input }) => (
                                <MultiSelect
                                  label={t('Trigger Stamp Rallies')}
                                  search
                                  options={stampRallyOptions}
                                  selectedValues={input.value}
                                  onChange={({ value }) =>
                                    input.onChange(value)
                                  }
                                />
                              )}
                            </Field>
                          )}
                          <FieldWrapper label={t('Trigger Timing')} />
                          <EnumRadioButtonGroup
                            name="triggerTiming"
                            options={[
                              {
                                value: 'IMMEDIATE',
                                label: t('Immediately after event'),
                              },
                              {
                                value: 'DELAYED',
                                label: t('With a delay after event'),
                              },
                              ...(values?.displayTriggerType === 'START_TIME'
                                ? [
                                    {
                                      value: 'BEFORE_EVENT',
                                      label: t(
                                        'Specified time before start time'
                                      ),
                                    },
                                  ]
                                : []),
                            ]}
                          />
                          {values?.triggerTiming === 'DELAYED' && (
                            <>
                              <FieldWrapper label={t('Delay')} />
                              <DelayInput name="triggerDelay" />
                            </>
                          )}
                          {values?.displayTriggerType === 'START_TIME' &&
                            values?.triggerTiming === 'BEFORE_EVENT' && (
                              <>
                                <FieldWrapper
                                  label={t('Time Before Start Time')}
                                />
                                <AdvanceInput name="triggerAdvance" />
                              </>
                            )}
                        </Box>
                      </td>
                    </tr>

                    <tr>
                      <th>{t('Conditions')}</th>
                      <td>
                        <ConditionRuleListEditor />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>

              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="blue"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
