import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';

type Props = {
  data: GraphDataItem[];
  names: Record<string, string>;
  colors: string[];
};

export const AccessReportBarChartPane = ({ data, names, colors }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickFormatter={(value) => {
            return moment(value).format('MM/DD');
          }}
          tick={{ fill: '#A1A1AA', fontSize: '12px' }}
          tickLine={{ stroke: '#A1A1AA' }}
          axisLine={{ stroke: '#A1A1AA' }}
        />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          allowDecimals={false}
          tick={{ fill: '#A1A1AA', fontSize: '12px' }}
          tickLine={{ stroke: '#A1A1AA' }}
          axisLine={{ stroke: '#A1A1AA' }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickFormatter={(value) => {
            return value;
          }}
          allowDecimals={false}
          tick={{ fill: '#A1A1AA', fontSize: '12px' }}
          tickLine={{ stroke: '#A1A1AA' }}
          axisLine={{ stroke: '#A1A1AA' }}
        />
        <Tooltip
          formatter={(value: any, _, props) => {
            return [value, props.dataKey];
          }}
          cursor={{ fill: '#F4F4F5' }}
        />
        <Legend
          wrapperStyle={{
            fontSize: '12px',
          }}
        />
        {Object.keys(names).map((key, index) => {
          return (
            <Bar
              key={index}
              dataKey={key}
              fill={colors[index]}
              name={names[key]}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
