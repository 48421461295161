// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { SiteSettingsForm } from 'client/components/SiteSettingsForm/SiteSettingsForm';
import { activeUserOrganizationSelector } from 'client/reducers/user';

export const SiteSettingsTutorial = () => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  if (activeUserOrganization?.tutorial_stage !== 'SITE_SETTINGS') {
    return <Redirect to="/home" />;
  }

  return <SiteSettingsForm />;
};
