import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { updateReservation } from 'client/actions/reservations';
import { Modal } from 'client/components/v3/Form/Modal';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';

type Props = {
  reservation: ManifestReservationShape;
  header?: string;
  onClose: () => void;
};

export const EditSupplierInternalNotesModal = ({
  reservation,
  header,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [supplierInternalNotes, setSupplierInternalNotes] = useState(
    reservation.supplier_internal_notes || ''
  );

  const handleSave = () => {
    dispatch(
      updateReservation(reservation.id, {
        supplier_internal_notes: supplierInternalNotes,
      })
    );
    onClose();
  };

  const handleDiscardChanges = () => {
    setSupplierInternalNotes(reservation.supplier_internal_notes || '');
  };

  return (
    <>
      <Modal
        title={header ?? ''}
        open={true}
        onClose={onClose}
        useCloseButton={true}
        insertAtRoot={true}
        rightActionChildren={
          <>
            <Button
              text={t('Discard Changes')}
              color="white"
              onClick={handleDiscardChanges}
              disabled={
                supplierInternalNotes === reservation.supplier_internal_notes
              }
            />
            <Button
              text={t('Save')}
              color="primary"
              disabled={
                supplierInternalNotes === reservation.supplier_internal_notes
              }
              onClick={handleSave}
            />
          </>
        }
      >
        <div className={styles['p-manifestsModalMemo']}>
          <div className={styles['p-manifestsModalMemo__item']}>
            <p className={styles['p-manifestsModalMemo__item__ttl']}>
              {t('Memo')}
            </p>
            <div className={styles['p-manifestsModalMemo__item__body']}>
              <TextArea
                required
                value={supplierInternalNotes || ''}
                onChange={(_, { value }) => setSupplierInternalNotes(value)}
                height={100}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
