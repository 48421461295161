import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Box } from 'client/components/Box/Box';
import { Button, Checkbox, Input, ToggleButton } from 'client/components/Form';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';
import { EditTranslationsModal } from 'client/pages/BookingWidget/EditTranslationsModal/EditTranslationsModal';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { PercentInput } from 'client/pages/Home/Tutorial/PercentInput/PercentInput';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { getValidators } from 'shared/libraries/validate/validator';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { SiteSettingsTutorialPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/SiteSettingsTutorialPopUp';
import { GuestMyPageSettings } from 'client/pages/BookingWidget/BookingWidgetCustomize/GuestMyPageSettings';
import {
  defaultProductCurrencySelector,
  defaultProductLanguageSelector,
} from 'client/reducers/organizations';
import type { Weekday } from 'client/libraries/util/weekdays';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { getArrayMutators } from 'client/libraries/util/form';
import { getPOBPaymentText } from 'client/libraries/util/getPOBPaymentText';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import type {
  ConsumptionTaxCalculation,
  Organization,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/SiteSettingsForm/SiteSettingsForm.module.css';

import { EnumRadioButtonGroup } from '../EnumRadioButtonGroup/EnumRadioButtonGroup';

type Language = 'EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW';
type SiteSettingsFormValues = {
  googleAnalyticsTag: string;
  uaGoogleAnalyticsTag: string;
  ga4GoogleAnalyticsTag: string;
  googleTagManagerId: string;
  supportedLanguages: Language[];
  logoUrl: string;
  faviconUrl: string;
  officeAddress: string;
  officePhone: string;
  officeEmail: string;
  emergencyContact: string;
  operatingDaysOfWeek: Weekday[];
  operatingTimeOfDayStart: string;
  operatingTimeOfDayEnd: string;
  acceptedPaymentTypes: ('PAID_IN_FULL' | 'PAY_ON_BOARD')[];
  acceptedPIFPaymentCurrencies: string[];
  pobAcceptedPaymentMethods: ('CASH' | 'CREDIT_CARD' | 'BANK')[];
  pobCreditCardFeeFixed: string;
  pobCreditCardFeePercent: string;
  creditCardFeeType: 'PERCENT' | 'FIXED';
  snsIds: Record<string, string>;
  showEmailOffersOptionForAgentBookings: boolean;
  allowViewingReservationDetails: boolean;
  allowUpdatingReservationInfo: boolean;
  allowChangingReservationParameters: boolean;
  allowCancellingReservation: boolean;
  allowIssuingDownloadingReceiptPDF: boolean;
  enableNoIndex: boolean;
  receiptBusinessName: string;
  receiptProviso: string;
  businessRegistrationNumber: string;
  consumptionTaxCalculation: ConsumptionTaxCalculation;
  pobPaymentMessage: string;
};
const focusOnError: any = createDecorator();

const getInitialValues = (org: Organization): SiteSettingsFormValues => {
  const supplierGuestPaymentSettings = org.supplier_guest_payment_settings;
  const acceptedPaymentTypes =
    supplierGuestPaymentSettings?.accepted_guest_payment_types || [];
  const pobAcceptedPaymentMethods = (
    supplierGuestPaymentSettings?.accepted_guest_pob_payment_methods || []
  ).map((method) => method.payment_method ?? 'CREDIT_CARD');
  const creditCardRule =
    supplierGuestPaymentSettings?.accepted_guest_pob_payment_methods?.find(
      (m) => m.payment_method === 'CREDIT_CARD'
    );
  const acceptedPIFPaymentCurrencies =
    supplierGuestPaymentSettings?.accepted_pif_payment_currencies || [
      'JPY',
      'USD',
    ];
  let creditCardFeeType = 'PERCENT';
  let pobCreditCardFeePercent = `${creditCardRule?.fee_percent ?? 0}`;
  let pobCreditCardFeeFixed = '';

  if (creditCardRule?.fee_fixed) {
    creditCardFeeType = 'FIXED';
    pobCreditCardFeePercent = '0';
    pobCreditCardFeeFixed = creditCardRule?.fee_fixed?.substring(3);
  }

  const operatingHoursRule =
    org.operating_hours_rules && org.operating_hours_rules.length > 0
      ? org.operating_hours_rules[0]
      : undefined;
  const operatingDaysOfWeek = operatingHoursRule?.days_of_week || [];
  const operatingTimeOfDayStart =
    operatingHoursRule?.start_time_local || '9:00';
  const operatingTimeOfDayEnd = operatingHoursRule?.end_time_local || '17:00';
  const snsIds: Record<string, string> = {};
  (org?.sns_ids || []).forEach((snsId) => {
    if (snsId?.key) {
      snsIds[snsId.key] = snsId.id || '';
    }
  });

  const supplierGuestReceiptSettings = org.supplier_guest_receipt_settings;
  const consumptionTaxCalculation =
    supplierGuestReceiptSettings?.consumption_tax_calculation ?? 'ROUND_OFF';

  return {
    emergencyContact: org.emergency_contact ?? '',
    googleAnalyticsTag: org.booking_widget_settings?.google_analytics_tag ?? '',
    uaGoogleAnalyticsTag:
      org.booking_widget_settings?.ua_google_analytics_tag ?? '',
    ga4GoogleAnalyticsTag:
      org.booking_widget_settings?.ga4_google_analytics_tag ?? '',
    googleTagManagerId:
      org.booking_widget_settings?.google_tag_manager_id ?? '',
    logoUrl: org.logo_url ?? '',
    faviconUrl: org.booking_widget_design_params?.favicon_url ?? '',
    officeAddress: org.office_mailing_address ?? '',
    officePhone: org.office_phone ?? '',
    supportedLanguages: org.supported_languages ?? [],
    officeEmail: org.office_email ?? '',
    operatingDaysOfWeek,
    operatingTimeOfDayStart,
    operatingTimeOfDayEnd,
    acceptedPaymentTypes,
    pobAcceptedPaymentMethods,
    pobCreditCardFeePercent,
    pobCreditCardFeeFixed,
    creditCardFeeType: creditCardFeeType as 'PERCENT' | 'FIXED',
    acceptedPIFPaymentCurrencies,
    snsIds,
    allowViewingReservationDetails:
      org?.guest_my_page_settings?.allow_viewing_reservation_details ?? false,
    allowUpdatingReservationInfo:
      org?.guest_my_page_settings?.allow_updating_reservation_info ?? false,
    allowChangingReservationParameters:
      org?.guest_my_page_settings?.allow_changing_reservation_parameters ??
      false,
    allowCancellingReservation:
      org?.guest_my_page_settings?.allow_cancelling_reservation ?? false,
    allowIssuingDownloadingReceiptPDF:
      org?.guest_my_page_settings?.allow_issuing_downloading_receipt_pdf ??
      false,
    showEmailOffersOptionForAgentBookings:
      org?.common_subscription_settings
        ?.show_email_offers_option_for_agent_bookings ?? false,
    enableNoIndex: org?.booking_widget_settings?.enable_no_index ?? false,
    receiptBusinessName:
      org?.supplier_guest_receipt_settings?.receipt_business_name ?? '',
    receiptProviso: org?.supplier_guest_receipt_settings?.receipt_proviso ?? '',
    businessRegistrationNumber:
      org?.supplier_guest_receipt_settings?.business_registration_number ?? '',
    consumptionTaxCalculation: consumptionTaxCalculation,
    pobPaymentMessage:
      org?.booking_widget_design_params?.pob_payment_custom_message ?? '',
  };
};

const getSupplierGuestPaymentSettings = (
  values: SiteSettingsFormValues,
  defaultCurrency?: string,
  activeUserOrganization?: Organization
): Organization['supplier_guest_payment_settings'] => {
  const guestPaymentTypes: ('PAY_ON_BOARD' | 'PAID_IN_FULL')[] = [];
  const pobPaymentMethods: {
    payment_method?: 'CREDIT_CARD' | 'CASH' | 'BANK';
    fee_fixed?: string;
    fee_percent?: number;
  }[] = [];
  const acceptPOB = (values.acceptedPaymentTypes || []).includes(
    'PAY_ON_BOARD'
  );
  const acceptPIF = (values.acceptedPaymentTypes || []).includes(
    'PAID_IN_FULL'
  );
  const pobAcceptCreditCard = (values.pobAcceptedPaymentMethods || []).includes(
    'CREDIT_CARD'
  );
  const pobAcceptCash = (values.pobAcceptedPaymentMethods || []).includes(
    'CASH'
  );
  const pobAcceptBankTransfer = (
    values.pobAcceptedPaymentMethods || []
  ).includes('BANK');

  if (acceptPOB) {
    guestPaymentTypes.push('PAY_ON_BOARD');

    if (pobAcceptCash) {
      pobPaymentMethods.push({
        payment_method: 'CASH',
      });
    }

    if (pobAcceptCreditCard) {
      if (values.creditCardFeeType === 'FIXED') {
        pobPaymentMethods.push({
          payment_method: 'CREDIT_CARD',
          fee_fixed: `${defaultCurrency}${values.pobCreditCardFeeFixed || 0}`,
        });
      } else {
        pobPaymentMethods.push({
          payment_method: 'CREDIT_CARD',
          fee_percent: values.pobCreditCardFeePercent
            ? parseFloat(values.pobCreditCardFeePercent)
            : 0,
        });
      }
    }

    if (pobAcceptBankTransfer) {
      pobPaymentMethods.push({
        payment_method: 'BANK',
      });
    }
  }

  if (acceptPIF) {
    guestPaymentTypes.push('PAID_IN_FULL');
  }

  return {
    ...activeUserOrganization?.supplier_guest_payment_settings,
    accepted_guest_payment_types: guestPaymentTypes,
    accepted_guest_pob_payment_methods: pobPaymentMethods,
    accepted_pif_payment_currencies: values.acceptedPIFPaymentCurrencies,
  };
};

export const SiteSettingsForm = () => {
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [showPopUp, setShowPopUp] = React.useState<boolean>(true);
  const scrollTo = React.useContext(ScrollToContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultCurrency = useSelector(defaultProductCurrencySelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const bookingWidgetUrl = getBookingWidgetUrl(
    activeUserOrganization?.booking_widget_subdomain || ''
  );
  const tutorialActive = Boolean(useRouteMatch('/home/tutorial'));
  const validators = getValidators(t);

  const sourceLanguage = activeUserOrganization?.source_language || 'EN_US';
  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== sourceLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([sourceLanguage, ...translationLanguages])].length > 1;

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="BOOKING_SITE_SETTINGS" />
      )}
      <div className={baseStyles['base-main__body__box']}>
        {tutorialActive && (
          <div className={baseStyles['base-main__body__box__header']}>
            <div className={baseStyles['base-main__body__box__header__ttl']}>
              {t('Register Site Info')}
            </div>
          </div>
        )}

        <Form
          onSubmit={async (values: SiteSettingsFormValues) => {
            const orgId = activeUserOrganization.id;
            const supplierGuestPaymentSettings =
              getSupplierGuestPaymentSettings(
                values,
                defaultCurrency,
                activeUserOrganization
              );
            const snsIds = Object.entries(values.snsIds || [])
              .map(([key, id]) => {
                if (typeof id !== 'string') {
                  return {};
                }

                return {
                  key,
                  id,
                };
              })
              .filter((e) => e.key !== '');

            try {
              await dispatch(
                updateOrganization(orgId, 'SUPPLIER', {
                  booking_widget_settings: {
                    google_analytics_tag: values.googleAnalyticsTag,
                    ua_google_analytics_tag: values.uaGoogleAnalyticsTag,
                    ga4_google_analytics_tag: values.ga4GoogleAnalyticsTag,
                    google_tag_manager_id: values.googleTagManagerId,
                    enable_no_index: values.enableNoIndex,
                  },
                  supported_languages: values.supportedLanguages,
                  logo_url: values.logoUrl,
                  office_mailing_address: values.officeAddress,
                  office_phone: values.officePhone,
                  office_email: values.officeEmail,
                  emergency_contact: values.emergencyContact,
                  operating_hours_rules: [
                    {
                      days_of_week: values.operatingDaysOfWeek,
                      start_time_local: values.operatingTimeOfDayStart,
                      end_time_local: values.operatingTimeOfDayEnd,
                    },
                  ],
                  supplier_guest_payment_settings: supplierGuestPaymentSettings,
                  tutorial_stage: tutorialActive ? 'COMPLETED' : undefined,
                  sns_ids: snsIds,
                  guest_my_page_settings: {
                    allow_viewing_reservation_details:
                      values.allowViewingReservationDetails,
                    allow_updating_reservation_info:
                      values.allowUpdatingReservationInfo,
                    allow_changing_reservation_parameters:
                      values.allowChangingReservationParameters,
                    allow_cancelling_reservation:
                      values.allowCancellingReservation,
                    allow_issuing_downloading_receipt_pdf:
                      values.allowIssuingDownloadingReceiptPDF,
                  },
                  common_subscription_settings: {
                    show_email_offers_option_for_agent_bookings:
                      values.showEmailOffersOptionForAgentBookings,
                  },
                  booking_widget_design_params: {
                    ...activeUserOrganization?.booking_widget_design_params,
                    favicon_url: values.faviconUrl,
                    pob_payment_custom_message: values.pobPaymentMessage,
                  },
                  supplier_guest_receipt_settings: {
                    ...activeUserOrganization?.supplier_guest_receipt_settings,
                    receipt_business_name: values.receiptBusinessName,
                    receipt_proviso: values.receiptProviso,
                    business_registration_number:
                      values.businessRegistrationNumber,
                    consumption_tax_calculation:
                      values.consumptionTaxCalculation,
                  },
                })
              );

              if (tutorialActive) {
                history.push('/home');
              }

              scrollTo(0, 0);
              setSaveSucceeded(true);
            } catch (err) {
              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
          decorators={[focusOnError]}
          initialValues={
            tutorialActive
              ? {
                  supportedLanguages: [defaultLanguage],
                  officeEmail: activeUserOrganization?.office_email,
                  operatingDaysOfWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
                  operatingTimeOfDayStart: '9:00',
                  operatingTimeOfDayEnd: '17:00',
                  acceptedPaymentTypes: ['PAY_ON_BOARD', 'PAID_IN_FULL'],
                  pobAcceptedPaymentMethods: ['CASH', 'CREDIT_CARD'],
                  pobCreditCardFeePercent: '0',
                  markupPercent: '0',
                  creditCardFeeType: 'PERCENT',
                  acceptedPIFPaymentCurrencies: ['JPY', 'USD'],
                }
              : {
                  ...getInitialValues(activeUserOrganization),
                  ranslations: [],
                }
          }
          mutators={getArrayMutators()}
          keepDirtyOnReinitialize={true}
        >
          {({ handleSubmit, submitting, submitError, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={baseStyles['base-main__body__box__body']}>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                  {tutorialActive && (
                    <p className={styles['page-tutorial__ttl']}>
                      {t('Add remaining info needed to launch your site')}
                    </p>
                  )}

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['page-productsRegist__subttl']}>
                      <p>{t('Site Info')}</p>
                      <Tooltip
                        text={t(
                          'Set up details for the reservation site and the top page.'
                        )}
                      />
                    </div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>URL</th>
                          <td>
                            <div className={styles['page-productsRegist__url']}>
                              <a
                                href={bookingWidgetUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {bookingWidgetUrl}
                              </a>
                              <p>
                                {t(
                                  '* Switch to your own domain later if desired'
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Google Analytics')}</th>
                          <td>
                            <div
                              className={styles['page-productsRegist__google']}
                            >
                              <Field name="ga4GoogleAnalyticsTag">
                                {({ input }) => (
                                  <Input
                                    label={t('GA4 Analytics Tag (Recommended)')}
                                    placeholder="G-xxxxxx"
                                    maxWidth={800}
                                    {...input}
                                  />
                                )}
                              </Field>
                              <Field name="uaGoogleAnalyticsTag">
                                {({ input }) => (
                                  <Box mt={2}>
                                    <Input
                                      label={t('UA Analytics Tag')}
                                      maxWidth={800}
                                      placeholder="UA-xxxxxx"
                                      {...input}
                                    />
                                  </Box>
                                )}
                              </Field>

                              <p>
                                {t('Setup instructions ')}
                                <a
                                  href="/helps/settings/google-analytics"
                                  target="_blank"
                                >
                                  {t('here')}
                                </a>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Google Tag Manager Id')}</th>
                          <td>
                            <div
                              className={styles['page-productsRegist__google']}
                            >
                              <Field name="googleTagManagerId">
                                {({ input }) => (
                                  <Input maxWidth={800} {...input} />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Language')}</th>
                          <td>
                            <Field
                              name="supportedLanguages"
                              validate={validators.atLeastOneLanguageRequred}
                            >
                              {({ input, meta: { error, touched } }) => (
                                <div
                                  className={
                                    styles['page-productsRegist__checkbox']
                                  }
                                >
                                  {languageOptions.map((langOpt) => (
                                    <div
                                      key={langOpt.value}
                                      className={
                                        baseStyles['base-form-checkbox']
                                      }
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="supportedLanguages"
                                          checked={(input.value || []).includes(
                                            langOpt.value
                                          )}
                                          onChange={() => {
                                            if (
                                              (input.value || []).includes(
                                                langOpt.value
                                              )
                                            ) {
                                              input.onChange(
                                                input.value?.filter(
                                                  (lang) =>
                                                    langOpt.value !== lang
                                                )
                                              );
                                            } else {
                                              input.onChange([
                                                ...(input.value as any),
                                                langOpt.value,
                                              ]);
                                            }
                                          }}
                                        />
                                        <p></p>
                                        {langOpt.text}
                                      </label>
                                    </div>
                                  ))}
                                  {error && touched && (
                                    <p
                                      className={
                                        styles[
                                          'page-productsRegist__checkbox__error'
                                        ]
                                      }
                                    >
                                      {error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Search Engine')}</th>
                          <td>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Field name="enableNoIndex">
                                {({ input }) => (
                                  <Box width="50px">
                                    <ToggleButton
                                      label=""
                                      checked={input.value}
                                      onChange={() =>
                                        input.onChange(!input.value)
                                      }
                                    />
                                  </Box>
                                )}
                              </Field>
                              <div>
                                <ul>
                                  <li>
                                    {t(
                                      'Do not show booking site on Google and other search result'
                                    )}
                                  </li>
                                  <li>
                                    {t(
                                      '* Website can be displayed when the URL is accessed directly'
                                    )}
                                  </li>
                                </ul>
                              </div>
                              <Tooltip
                                text={t(
                                  'noindex tag will be added to each page on booking website to avoid being indexed by search engines.'
                                )}
                              />
                            </Box>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['page-productsRegist__subttl']}>
                      <p>{t('Logo・Icon')}</p>
                      <Tooltip
                        text={t(
                          'Set up the logo image or icon to be displayed on the reservation site and email.'
                        )}
                      />
                    </div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>{t('Logo (jpg,png)')}</th>
                          <td>
                            <Field name="logoUrl">
                              {({ input }) => (
                                <SingleImageInput
                                  acceptedFileTypes={[
                                    'image/jpeg',
                                    'image/png',
                                  ]}
                                  onUploadFinished={(filename) =>
                                    input.onChange(filename)
                                  }
                                  initialValue={
                                    input.value
                                      ? `${config.s3Config.mediaPathRoot}/logos/supplier/${activeUserOrganization?.id}/${input.value}`
                                      : ''
                                  }
                                  getFileSavePath={(filename) =>
                                    `logos/supplier/${activeUserOrganization?.id}/${filename}`
                                  }
                                  uploadWithOriginalFilename={true}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Icon (ico)')}</th>
                          <td>
                            <Field name="faviconUrl">
                              {({ input }) => (
                                <SingleImageInput
                                  acceptedFileTypes={['image/x-icon']}
                                  onUploadFinished={(_, newUploadedFileUrl) =>
                                    input.onChange(newUploadedFileUrl)
                                  }
                                  initialValue={
                                    input.value
                                      ? input.value
                                      : `${config.s3Config.mediaPathRoot}/logos/supplier/${activeUserOrganization?.id}/favicon.ico`
                                  }
                                  getFileSavePath={(filename) =>
                                    `logos/supplier/${activeUserOrganization?.id}/${filename}`
                                  }
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['page-productsRegist__subttl']}>
                      <p>{t('Company Info')}</p>
                      <Tooltip
                        text={t(
                          'Enter information displayed on the reservation site and email like address, phone number, business hours, and emergency contact information.'
                        )}
                      />
                    </div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>{t('Address')}</th>
                          <td>
                            <div>
                              <Field name="officeAddress">
                                {({ input }) => (
                                  <Input maxWidth={800} {...input} />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Company Phone Number')}</th>
                          <td>
                            <div>
                              <Field name="officePhone">
                                {({ input }) => (
                                  <Input maxWidth={800} {...input} />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Email')}</th>
                          <td>
                            <div>
                              <Field name="officeEmail">
                                {({ input }) => (
                                  <Input maxWidth={800} {...input} />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Emergency Contact Info')}</th>
                          <td>
                            <div>
                              <Field name="emergencyContact">
                                {({ input }) => (
                                  <Input maxWidth={800} {...input} />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Operating Days of the Week')}</th>
                          <td>
                            <Field name="operatingDaysOfWeek">
                              {({ input }) => (
                                <WeekdaysInput
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Operating Hours')}</th>
                          <td>
                            <div
                              className={
                                styles['page-productsRegist__businessHours']
                              }
                            >
                              <Field name="operatingTimeOfDayStart">
                                {({ input }) => (
                                  <TimePicker
                                    allowEmpty={false}
                                    value={moment(input.value, 'HH:mm')}
                                    onChange={(newMoment) => {
                                      if (!newMoment) {
                                        return;
                                      }

                                      input.onChange(newMoment.format('HH:mm'));
                                    }}
                                  />
                                )}
                              </Field>
                              <p>-</p>
                              <Field name="operatingTimeOfDayEnd">
                                {({ input }) => (
                                  <TimePicker
                                    allowEmpty={false}
                                    value={moment(input.value, 'HH:mm')}
                                    onChange={(newMoment) => {
                                      if (!newMoment) {
                                        return;
                                      }

                                      input.onChange(newMoment.format('HH:mm'));
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        {config.enableReservationReceipt && (
                          <>
                            <tr>
                              <th>{t('Receipt Business Name')}</th>
                              <td>
                                <div>
                                  <Field name="receiptBusinessName">
                                    {({ input }) => (
                                      <Input maxWidth={800} {...input} />
                                    )}
                                  </Field>
                                </div>
                              </td>
                            </tr>
                            {activeUserOrganization.default_timezone ===
                              'Asia/Tokyo' && (
                              <>
                                <tr>
                                  <th>{t('Receipt Proviso Text')}</th>
                                  <td>
                                    <div>
                                      <Field name="receiptProviso">
                                        {({ input }) => (
                                          <Input
                                            maxWidth={800}
                                            {...input}
                                            placeholder={t(
                                              'Default receipt proviso'
                                            )}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>{t('Business Registration Number')}</th>
                                  <td>
                                    <div>
                                      <Field name="businessRegistrationNumber">
                                        {({ input }) => (
                                          <Input maxWidth={800} {...input} />
                                        )}
                                      </Field>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {t('Consumption Tax Calculation')}{' '}
                                    <Tooltip
                                      text={t(
                                        'Applies to rounding when calculating the consumption tax amount included in the total gross price.'
                                      )}
                                    />
                                  </th>
                                  <td>
                                    <EnumRadioButtonGroup
                                      name="consumptionTaxCalculation"
                                      options={[
                                        {
                                          value: 'ROUND_OFF',
                                          label: t('Tax Round Off'),
                                        },
                                        {
                                          value: 'ROUND_UP',
                                          label: t('Tax Round Up'),
                                        },
                                        {
                                          value: 'ROUND_DOWN',
                                          label: t('Tax Round Down'),
                                        },
                                      ]}
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        )}
                      </table>
                    </div>
                  </div>

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['page-productsRegist__subttl']}>
                      <p>{t('Payment Types')}</p>
                      <Tooltip
                        text={t(
                          'Set up payment methods available on the booking site.'
                        )}
                      />
                    </div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>{t('Payment Types')}</th>
                          <td>
                            <Field
                              name="acceptedPaymentTypes"
                              validate={validators.atLeastOnePaymentTypeRequred}
                            >
                              {({ input, meta: { error, touched } }) => (
                                <div
                                  className={clsx(
                                    styles['page-productsRegist__checkbox'],
                                    styles['width160']
                                  )}
                                >
                                  <div
                                    className={baseStyles['base-form-checkbox']}
                                  >
                                    <label>
                                      <input
                                        name="acceptedPaymentTypes"
                                        type="checkbox"
                                        onChange={() => {
                                          if (
                                            !(input.value || []).includes(
                                              'PAID_IN_FULL'
                                            )
                                          ) {
                                            input.onChange([
                                              ...(input.value as any),
                                              'PAID_IN_FULL',
                                            ]);
                                          } else {
                                            input.onChange(
                                              (input.value || []).filter(
                                                (v) => v !== 'PAID_IN_FULL'
                                              )
                                            );
                                          }
                                        }}
                                        checked={(input.value || []).includes(
                                          'PAID_IN_FULL'
                                        )}
                                      />
                                      <p></p>
                                      {t('Online Payment')}
                                    </label>
                                  </div>
                                  <div
                                    className={baseStyles['base-form-checkbox']}
                                  >
                                    <label>
                                      <input
                                        name="acceptedPaymentTypes"
                                        type="checkbox"
                                        onChange={() => {
                                          if (
                                            !(input.value || []).includes(
                                              'PAY_ON_BOARD'
                                            )
                                          ) {
                                            input.onChange([
                                              ...(input.value as any),
                                              'PAY_ON_BOARD',
                                            ]);
                                          } else {
                                            input.onChange(
                                              (input.value || []).filter(
                                                (v) => v !== 'PAY_ON_BOARD'
                                              )
                                            );
                                          }
                                        }}
                                        checked={(input.value || []).includes(
                                          'PAY_ON_BOARD'
                                        )}
                                      />
                                      <p></p>
                                      {t('Local Payment')}
                                    </label>
                                  </div>
                                  {error && touched && (
                                    <p
                                      className={
                                        styles[
                                          'page-productsRegist__checkbox__error'
                                        ]
                                      }
                                    >
                                      {error}
                                    </p>
                                  )}
                                </div>
                              )}
                            </Field>
                          </td>
                        </tr>
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAID_IN_FULL'
                        ) && (
                          <tr>
                            <th>
                              {t('Accepted Currencies for Online Payment')}
                            </th>
                            <td>
                              <div
                                className={
                                  styles['page-productsRegist__credit']
                                }
                              >
                                <div
                                  className={
                                    styles['page-productsRegist__credit__item']
                                  }
                                >
                                  <Field
                                    name="acceptedPIFPaymentCurrencies"
                                    validate={
                                      validators.atLeastOneCurrencyRequred
                                    }
                                  >
                                    {({ input, meta: { error, touched } }) => (
                                      <div
                                        className={clsx(
                                          styles[
                                            'page-productsRegist__checkbox'
                                          ],
                                          styles['width160']
                                        )}
                                      >
                                        {['JPY', 'USD'].map((currencyOpt) => (
                                          <Checkbox
                                            key={currencyOpt}
                                            withoutFrame
                                            label={currencyOpt}
                                            onChange={() => {
                                              if (
                                                input.value?.includes(
                                                  currencyOpt
                                                )
                                              ) {
                                                input.onChange(
                                                  input.value.filter(
                                                    (curr) =>
                                                      curr !== currencyOpt
                                                  )
                                                );
                                              } else {
                                                input.onChange([
                                                  ...(input.value as any),
                                                  currencyOpt,
                                                ]);
                                              }
                                            }}
                                            checked={Boolean(
                                              input.value?.includes(currencyOpt)
                                            )}
                                          />
                                        ))}
                                        {error && touched && (
                                          <p
                                            className={
                                              styles[
                                                'page-productsRegist__checkbox__error'
                                              ]
                                            }
                                          >
                                            {error}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAY_ON_BOARD'
                        ) && (
                          <tr>
                            <th>{t('Accepted forms of local payment')}</th>
                            <td>
                              <Field
                                name="pobAcceptedPaymentMethods"
                                validate={
                                  validators.atLeastOnePayOnBoardPaymentMethodRequred
                                }
                              >
                                {({ input, meta: { touched, error } }) => (
                                  <div
                                    className={clsx(
                                      styles['page-productsRegist__checkbox'],
                                      styles['width160']
                                    )}
                                  >
                                    <div
                                      className={
                                        baseStyles['base-form-checkbox']
                                      }
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="pobAcceptedPaymentMethods"
                                          onChange={() => {
                                            if (
                                              !(input.value || []).includes(
                                                'CASH'
                                              )
                                            ) {
                                              input.onChange([
                                                ...(input.value as any),
                                                'CASH',
                                              ]);
                                            } else {
                                              input.onChange(
                                                (input.value || []).filter(
                                                  (v) => v !== 'CASH'
                                                )
                                              );
                                            }
                                          }}
                                          checked={(input.value || []).includes(
                                            'CASH'
                                          )}
                                        />
                                        <p></p>
                                        {t('Cash')}
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        baseStyles['base-form-checkbox']
                                      }
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="pobAcceptedPaymentMethods"
                                          onChange={() => {
                                            if (
                                              !(input.value || []).includes(
                                                'CREDIT_CARD'
                                              )
                                            ) {
                                              input.onChange([
                                                ...(input.value as any),
                                                'CREDIT_CARD',
                                              ]);
                                            } else {
                                              input.onChange(
                                                (input.value || []).filter(
                                                  (v) => v !== 'CREDIT_CARD'
                                                )
                                              );
                                            }
                                          }}
                                          checked={(input.value || []).includes(
                                            'CREDIT_CARD'
                                          )}
                                        />
                                        <p></p>
                                        {t('Credit Card')}
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        baseStyles['base-form-checkbox']
                                      }
                                    >
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="pobAcceptedPaymentMethods"
                                          onChange={() => {
                                            if (
                                              !(input.value || []).includes(
                                                'BANK'
                                              )
                                            ) {
                                              input.onChange([
                                                ...(input.value as any),
                                                'BANK',
                                              ]);
                                            } else {
                                              input.onChange(
                                                (input.value || []).filter(
                                                  (v) => v !== 'BANK'
                                                )
                                              );
                                            }
                                          }}
                                          checked={(input.value || []).includes(
                                            'BANK'
                                          )}
                                        />
                                        <p></p>
                                        {t('Bank Transfer')}
                                      </label>
                                    </div>
                                    {error && touched && (
                                      <p
                                        className={
                                          styles[
                                            'page-productsRegist__checkbox__error'
                                          ]
                                        }
                                      >
                                        {error}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </td>
                          </tr>
                        )}
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAY_ON_BOARD'
                        ) &&
                          (values?.pobAcceptedPaymentMethods || []).includes(
                            'CREDIT_CARD'
                          ) && (
                            <tr>
                              <th>{t('Local Payment Credit Card Fee')}</th>
                              <td>
                                <ul
                                  className={clsx(
                                    baseStyles['base-flex'],
                                    styles['page-productsRegist__priceSelect']
                                  )}
                                >
                                  <li>
                                    <div
                                      className={baseStyles['base-form-radio']}
                                    >
                                      <label>
                                        <Field
                                          name="creditCardFeeType"
                                          component="input"
                                          type="radio"
                                          value="PERCENT"
                                        />
                                        <p></p>
                                        {t('Set to percent of total')}
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      className={baseStyles['base-form-radio']}
                                    >
                                      <label>
                                        <Field
                                          name="creditCardFeeType"
                                          component="input"
                                          type="radio"
                                          value="FIXED"
                                        />
                                        <p></p>
                                        {t('Set to fixed amount')}
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                <div
                                  className={
                                    styles['page-productsRegist__priceMain']
                                  }
                                >
                                  <div
                                    className={
                                      styles['page-productsRegist__credit']
                                    }
                                  >
                                    {values?.creditCardFeeType ===
                                      'PERCENT' && (
                                      <div
                                        className={
                                          styles[
                                            'page-productsRegist__credit__item'
                                          ]
                                        }
                                      >
                                        <Field name="pobCreditCardFeePercent">
                                          {({ input }) => (
                                            <PercentInput
                                              value={input.value}
                                              onChange={input.onChange}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    )}
                                    {values?.creditCardFeeType === 'FIXED' && (
                                      <div
                                        className={
                                          styles[
                                            'page-productsRegist__credit__item'
                                          ]
                                        }
                                      >
                                        <div>
                                          <p>{defaultCurrency}</p>
                                          <Field name="pobCreditCardFeeFixed">
                                            {({ input }) => (
                                              <Input
                                                value={input.value}
                                                onChange={(e: any) => {
                                                  if (
                                                    !currencyInputAllowed(
                                                      defaultCurrency,
                                                      e.target.value
                                                    )
                                                  ) {
                                                    return;
                                                  }

                                                  input.onChange(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        {config.enablePOBCustomMessage &&
                          (values?.acceptedPaymentTypes || []).includes(
                            'PAY_ON_BOARD'
                          ) && (
                            <tr>
                              <th>{t('Message for local payment')}</th>
                              <td>
                                <div
                                  className={clsx(
                                    baseStyles['base-form-box__header']
                                  )}
                                >
                                  {t('Initial Message')}
                                </div>
                                <div style={{ marginBottom: '8px' }}>
                                  {getPOBPaymentText(
                                    getSupplierGuestPaymentSettings(
                                      values,
                                      defaultCurrency,
                                      activeUserOrganization
                                    ),
                                    t
                                  )}
                                </div>
                                <div
                                  className={clsx(
                                    baseStyles['base-form-box__header']
                                  )}
                                >
                                  {t('Custom Message')}
                                  <Tooltip
                                    text={t(
                                      'Instead of the initial message, custom message will be displayed on the checkout page of the booking website.'
                                    )}
                                  />
                                </div>
                                <Field name="pobPaymentMessage">
                                  {({ input }) => (
                                    <Input maxWidth={800} {...input} />
                                  )}
                                </Field>
                                {shouldShowMultilingualSettings && (
                                  <div
                                    style={{
                                      marginTop: '10px',
                                      maxWidth: '800px',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <EditTranslationsModal
                                      sourceLanguage={sourceLanguage}
                                      trigger={
                                        <Button style="gray" size="small">
                                          {t('Multilingual Settings')}
                                        </Button>
                                      }
                                      sourceText={
                                        values?.pobPaymentMessage || ''
                                      }
                                      translationLanguages={
                                        translationLanguages
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>

                  <div className={styles['page-productsRegist__tableBox']}>
                    <div className={styles['page-productsRegist__subttl']}>
                      <p>{t('SNS Accounts')}</p>
                      <Tooltip
                        text={t(
                          'Enter ID/URL of your SNS account, icons will be displayed on the booking site footer.'
                        )}
                      />
                    </div>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tr>
                          <th>{t('Facebook')}</th>
                          <td>
                            <div>
                              <Field name="snsIds.facebook">
                                {({ input }) => (
                                  <Input
                                    maxWidth={800}
                                    placeholder={t('username (e.g. ntmg.jp)')}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Twitter')}</th>
                          <td>
                            <div>
                              <Field name="snsIds.twitter">
                                {({ input }) => (
                                  <Input
                                    maxWidth={800}
                                    placeholder={t(
                                      'username without @ (e.g. ntmgjp)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Instagram')}</th>
                          <td>
                            <div>
                              <Field name="snsIds.instagram">
                                {({ input }) => (
                                  <Input
                                    maxWidth={800}
                                    placeholder={t(
                                      'username (e.g. nutmeglabsjapan)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('YouTube')}</th>
                          <td>
                            <div>
                              <Field name="snsIds.youtube">
                                {({ input }) => (
                                  <Input
                                    maxWidth={800}
                                    placeholder={t(
                                      'channel ID (e.g. UCE4moRPwnsR9mkpnCr9xK2g)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Line')}</th>
                          <td>
                            <div>
                              <Field name="snsIds.line">
                                {({ input }) => (
                                  <Input
                                    maxWidth={800}
                                    placeholder={t(
                                      'Add friend/Timeline URL (e.g. https://lin.ee/JmMjCIx )'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  {(hasSubscription(
                    activeUserOrganization,
                    'feature-guest-my-page'
                  ) ||
                    hasSubscription(
                      activeUserOrganization,
                      'feature-digital-waiver'
                    ) ||
                    hasSubscription(activeUserOrganization, 'feature-review') ||
                    hasSubscription(
                      activeUserOrganization,
                      'feature-image-download'
                    )) && (
                    <div className={styles['page-productsRegist__tableBox']}>
                      <div className={styles['page-productsRegist__subttl']}>
                        <p>{t('Subscriptions')}</p>
                      </div>
                      {hasSubscription(
                        activeUserOrganization,
                        'feature-guest-my-page'
                      ) && (
                        <div className={styles['c-table-list']}>
                          <table>
                            <tr>
                              <th>{t('My Page for Guest')}</th>
                              <td>
                                <div>
                                  {hasSubscription(
                                    activeUserOrganization,
                                    'feature-customer-ledger'
                                  ) ? (
                                    <>
                                      {t(
                                        'Please set the allowed operations on My Page for Guest on "Members > Settings" page'
                                      )}
                                    </>
                                  ) : (
                                    <GuestMyPageSettings />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                      {(hasSubscription(
                        activeUserOrganization,
                        'feature-digital-waiver'
                      ) ||
                        hasSubscription(
                          activeUserOrganization,
                          'feature-review'
                        ) ||
                        hasSubscription(
                          activeUserOrganization,
                          'feature-image-download'
                        )) && (
                        <div className={styles['c-table-list']}>
                          <table>
                            <tr>
                              <th>{t('Agent Reservations')}</th>
                              <td>
                                <div>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                  >
                                    <Field name="showEmailOffersOptionForAgentBookings">
                                      {({ input }) => (
                                        <Box width="50px">
                                          <ToggleButton
                                            label=""
                                            checked={input.value}
                                            onChange={() =>
                                              input.onChange(!input.value)
                                            }
                                          />
                                        </Box>
                                      )}
                                    </Field>
                                    <div>
                                      {t(
                                        'Allow agent reservations to opt-in to email offers when:'
                                      )}
                                      <ul
                                        style={{
                                          marginLeft: '12px',
                                          listStyleType: 'disc',
                                        }}
                                      >
                                        <li
                                          style={{
                                            listStyleType: 'disc',
                                          }}
                                        >
                                          {t('Submitting Waivers')}
                                        </li>
                                        <li
                                          style={{
                                            listStyleType: 'disc',
                                          }}
                                        >
                                          {t('Downloading Images')}
                                        </li>
                                      </ul>
                                    </div>
                                  </Box>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      loading={submitting}
                      type="submit"
                      style="blue"
                      size="small"
                    >
                      {t('Save Settings')}
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
        {tutorialActive && showPopUp && (
          <SiteSettingsTutorialPopUp popUpDisable={() => setShowPopUp(false)} />
        )}
      </div>
    </>
  );
};
