import { InquiryAttachment } from 'client/components/InquiryAttachmentsEditor/InquiryAttachmentsEditor';
import { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { InquiryBulkEmailRequest } from 'shared/models/swagger';

export interface FormValues {
  reservations: ReservationSummaryShape[];
  subject: string;
  description: string;
  attachments: InquiryAttachment[];
}

export const convertFormValuesToSwagger = (
  values: FormValues
): InquiryBulkEmailRequest => {
  const reservationIds = values.reservations.map((r) => r.id);

  return {
    reservation_ids: reservationIds,
    subject: values.subject,
    description: values.description,
    attachments: values.attachments?.map((a) => ({
      url: a.url,
      filename: a.filename,
    })),
  };
};
