import { Link } from 'react-router-dom';
import clsx from 'clsx';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import { tasksSelector } from 'client/reducers/tasks';
import { getGuestName } from 'client/libraries/util/getGuestName';
import type { TabType } from 'client/libraries/util/dashboard';
import {
  getHotelDisplay,
  getTransportationDisplay,
  getGuestDescriptionDisplay,
  getDateTimeDisplay,
} from 'client/libraries/util/util';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import type {
  ReservationStatus,
  Task,
  TaskCategory,
  Reservation,
} from 'shared/models/swagger';

import styles from './Dashboard.module.css';

export const getColumnIds = (tabType: TabType): string[] => {
  switch (tabType) {
    case 'REQUESTED':
    case 'PICKUP_CHECKIN_INFO_REQURED':
      return [
        'reservation_button',
        'deadline',
        'participates_at',
        'agent_reference',
        'booked_at',
        'product_name',
        'guest_display_name',
        'transportation',
        'hotel',
        'guest_count',
        'booking_source',
      ];
    case 'STANDBY':
      return [
        'reservation_button',
        'deadline',
        'participates_at',
        'standby_deadline',
        'agent_reference',
        'booked_at',
        'product_name',
        'guest_display_name',
        'transportation',
        'hotel',
        'guest_count',
        'booking_source',
      ];
    default:
      return [
        'reservation_button',
        'deadline',
        'pin_deadline',
        'pinned_memo',
        'status',
        'participates_at',
        'agent_reference',
        'booked_at',
        'product_name',
        'guest_display_name',
        'transportation',
        'guest_count',
        'booking_source',
      ];
  }
};

export type ColumnType = {
  Header: string;
  id: string;
  accessor: (arg: any) => any;
  th?: boolean;
  sub?: boolean;
  width?: number;
};

export const getAllColumns = (
  locale: string,
  activeTab: TabType,
  t: any
): ColumnType[] => {
  return [
    {
      Header: '',
      id: 'reservation_button',
      accessor: (item) => {
        return (
          <Link
            to={`/reservations/${item.reservationId}`}
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Detail')}
          >
            <img src={reservationDetailIcon} />
          </Link>
        );
      },
      th: true,
      width: 80,
    },
    {
      Header: t('Deadline'),
      id: 'deadline',
      accessor: (item) => {
        return getDeadlineLabel(item.deadline, t);
      },
      width: 120,
    },
    {
      Header: t('Standby until'),
      id: 'standby_deadline',
      accessor: (item) => {
        return getDateTimeDisplay(
          item?.standbyDeadline ?? item.deadline,
          locale
        );
      },
    },
    {
      Header: t('Pinned until'),
      id: 'pin_deadline',
      accessor: (item) => {
        return getDateTimeDisplay(item.deadline, locale);
      },
    },
    {
      Header: t('Pinned Memo'),
      id: 'pinned_memo',
      accessor: (item) => {
        return <TruncatedTextWithSeeMoreButton text={item.pinned_memo ?? ''} />;
      },
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (item) => {
        return item.reservationStatus;
      },
    },
    {
      Header: t('Participation'),
      id: 'participates_at',
      accessor: (item) => {
        return getDateTimeDisplay(item.participationDateTime, locale);
      },
    },
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (item) => {
        return item.agentReference;
      },
    },
    {
      Header: t('Product'),
      id: 'product_name',
      accessor: (item) => {
        return item.internalProductName || item.productName;
      },
    },
    {
      Header: t('Customer'),
      id: 'guest_display_name',
      accessor: (item) => {
        return item.guestDisplayName;
      },
    },
    {
      Header: t('Transportation'),
      id: 'transportation',
      accessor: (item) => {
        return item.transportation;
      },
    },
    {
      Header: t('Hotel'),
      id: 'hotel',
      accessor: (item) => {
        return item.hotel;
      },
    },
    {
      Header: t('Unit'),
      id: 'guest_count',
      accessor: (item) => {
        return item.unit;
      },
    },
    {
      Header: t('Source'),
      id: 'booking_source',
      accessor: (item) => {
        return item.bookingSource;
      },
    },
  ];
};

export const getFormattedBookingSource = (
  sourceName: string,
  sourceType: string,
  t: TranslateFuncType
): string => {
  let bookingSource = '';
  if (sourceName) {
    // Prefix booking agent with contract agent name if the two are different.
    // Ex: "H.I.S. Australia (HOPS)"
    bookingSource = `${sourceName} (${t(sourceType)})`;
  } else if (sourceType) {
    bookingSource = t(sourceType);
  }
  return bookingSource;
};

export type TaskShape = {
  agentReference: string;
  bookingSource: string;
  category: TaskCategory;
  guestDisplayName: string;
  hotel: string;
  id: string;
  participationDateTime: Moment | undefined;
  productName: string;
  reservationId: string;
  reservationStatus: ReservationStatus;
  transportation: string;
  unit: string;
  deadline: Moment;
  pinned_memo: string;
  standbyDeadline: Moment;
  internalProductName: string;
};

const tSelector = (
  _: ReduxState,
  props: {
    t: TranslateFuncType;
  }
) => props.t;

export const taskShapesSelector = createSelector(
  [tasksSelector, tSelector],
  (tasks, t) => tasks.map((task) => toTaskShape(task, t))
);

export const toTaskShape = (task: Task, t: TranslateFuncType): TaskShape => {
  const hotel = getHotelDisplay(task, t);
  const transportation = getTransportationDisplay(task, t);
  const unit = getGuestDescriptionDisplay(task);
  return {
    agentReference: task.reservation_agent_reference || '',
    bookingSource: getFormattedBookingSource(
      task.reservation_booking_source?.agent_name ??
        task.reservation_booking_source?.group_name ??
        '',
      task.reservation_booking_source?.source_type ?? '',
      t
    ),
    category: task.category,
    guestDisplayName: task.reservation_customer_full_name || '',
    hotel,
    id: task.id,
    participationDateTime: getParticipationDateTime(task),
    productName: task.reservation_product_name || '',
    reservationId: task.reservation_id,
    reservationStatus: task.reservation_status,
    transportation: transportation || '',
    unit,
    deadline: moment.tz(
      task.due_date_time_utc,
      task.reservation_start_timezone ?? ''
    ),
    pinned_memo: '',
    standbyDeadline: moment.tz(
      task.reservation_standby_deadline_date_time_utc ?? '',
      task.reservation_start_timezone ?? ''
    ),
    internalProductName: task.internal_product_name ?? '',
  };
};

export const convertReservationToTaskShape = (
  reservation: Reservation,
  t: TranslateFuncType
): TaskShape => {
  const hotel = getHotelDisplay(reservation, t);
  const transportation = getTransportationDisplay(reservation, t);
  const unit = getGuestDescriptionDisplay(reservation);
  return {
    agentReference: reservation.agent_reference || '',
    bookingSource: getFormattedBookingSource(
      reservation.booking_source?.agent_name ??
        reservation.booking_source?.group_name ??
        '',
      reservation.booking_source?.source_type ?? '',
      t
    ),
    category: 'RESERVATION_STATUS_SEEN',
    guestDisplayName: getGuestName(reservation) || '',
    hotel: hotel,
    id: '',
    participationDateTime: moment.tz(
      reservation.start_date_time_utc,
      reservation.start_timezone ?? ''
    ),
    productName: reservation.product_name || '',
    reservationId: reservation.id,
    reservationStatus: t(reservation.status),
    transportation: transportation || '',
    unit,
    deadline: moment.tz(
      reservation.pin_info?.due_date_time_utc,
      reservation.start_timezone ?? ''
    ),
    pinned_memo: reservation?.pin_info?.memo ?? '',
    standbyDeadline: moment(),
    internalProductName: reservation?.internal_product_name ?? '',
  };
};

const getParticipationDateTime = (task: Task): Moment | typeof undefined => {
  const { reservation_start_date_time_utc, reservation_start_timezone } = task;

  if (!reservation_start_date_time_utc || !reservation_start_timezone) {
    return undefined;
  }

  return moment.tz(reservation_start_date_time_utc, reservation_start_timezone);
};

export const getDurationToDeadline = (deadline: Moment): number => {
  const now = moment();
  return deadline.diff(now, 'hours');
};

export const getDeadlineLabel = (deadline: Moment, t: any): any => {
  const durationToDeadline = getDurationToDeadline(deadline);
  if (durationToDeadline <= 0) {
    return (
      <p className={clsx(styles['deadline-base'], styles['deadline-expired'])}>
        {t('deadline reached')}
      </p>
    );
  } else if (durationToDeadline <= 24) {
    return (
      <p className={clsx(styles['deadline-base'], styles['deadline-24hours'])}>
        {t('24 hours')}
      </p>
    );
  } else if (durationToDeadline <= 24 * 2) {
    return (
      <p className={clsx(styles['deadline-base'], styles['deadline-48hours'])}>
        {t('48 hours')}
      </p>
    );
  } else if (durationToDeadline <= 24 * 3) {
    return (
      <p className={clsx(styles['deadline-base'], styles['deadline-3days'])}>
        {t('3 days')}
      </p>
    );
  } else {
    return '';
  }
};

export const getNumOfTasks = (taskCounts: any, tab: TabType): number => {
  let count = 0;

  if (tab === 'REQUESTED') {
    count = taskCounts?.requested_count || 0;
  } else if (tab === 'STANDBY') {
    count = taskCounts?.standby_count ?? 0;
  } else if (tab === 'PICKUP_CHECKIN_INFO_REQURED') {
    count = taskCounts?.pickup_tbd_count ?? 0;
  }

  return count;
};

export const getNumOfTasksLabel = (taskCounts: any, tab: TabType): any => {
  const count = getNumOfTasks(taskCounts, tab);

  return (
    <span
      className={clsx(
        styles['num-of-task-base'],
        count ? styles['num-of-task-non-zero'] : styles['num-of-task-zero']
      )}
    >
      {count}
    </span>
  );
};
