import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import type { GroupDiscount } from 'shared/models/swagger';

type Props = {
  groupDiscount: GroupDiscount;
  onGroupDiscountChange: (newGroupDiscount: GroupDiscount) => void;
  guestTypeKeys: string[];
};
export const GroupDiscountInput = ({
  groupDiscount,
  guestTypeKeys,
  onGroupDiscountChange,
}: Props) => {
  const { t } = useTranslation();
  const eligibleGuestTypeKeys =
    (groupDiscount && groupDiscount.eligible_guest_type_keys) || [];
  const minGuestCount = (groupDiscount && groupDiscount.min_guest_count) || 1;
  return (
    <>
      <div
        style={{
          display: 'flex',
          fontWeight: 'var(--text-semibold)',
          marginTop: '20px',
          marginBottom: '5px',
        }}
      >
        <div style={{ marginRight: '5px' }}>{t('Group Size')}</div>
        <Tooltip
          text={t(
            'Number of participants needed for a booking to qualify. For example, if group size is 5, bookings of 5 or more eligible participants will qualify.'
          )}
        >
          <i className="c-icon-outline-general-info-circle"></i>
        </Tooltip>
      </div>
      <SingleDropdown
        selectedOption={minGuestCount as any}
        options={
          _.range(1, 20).map((v) => ({
            key: v,
            value: v,
            text: v,
          })) as any
        }
        onChange={(value: any) => {
          onGroupDiscountChange({ ...groupDiscount, min_guest_count: value });
        }}
      />

      <div
        style={{
          display: 'flex',
          fontWeight: 'var(--text-semibold)',
          marginTop: '20px',
          marginBottom: '5px',
        }}
      >
        <div style={{ marginRight: '5px' }}>{t('Eligible units')}</div>
        <Tooltip
          text={t(
            'Units that will be counted to determine if a booking qualifies'
          )}
        >
          <i className="c-icon-outline-general-info-circle"></i>
        </Tooltip>
      </div>
      <div style={{ display: 'flex' }}>
        {guestTypeKeys.map((guestTypeKey) => {
          const checked = eligibleGuestTypeKeys.indexOf(guestTypeKey) !== -1;
          return (
            <Checkbox
              key={guestTypeKey}
              label={guestTypeKey}
              name={guestTypeKey}
              value={guestTypeKey}
              checked={checked}
              disabled={checked && eligibleGuestTypeKeys.length === 1}
              onChange={() => {
                const newEligibleGuestTypeKeys =
                  eligibleGuestTypeKeys.indexOf(guestTypeKey) !== -1
                    ? eligibleGuestTypeKeys.filter(
                        (existingKey) => guestTypeKey !== existingKey
                      )
                    : [...eligibleGuestTypeKeys, guestTypeKey];
                onGroupDiscountChange({
                  ...groupDiscount,
                  eligible_guest_type_keys: newEligibleGuestTypeKeys.filter(
                    (key) => guestTypeKeys.includes(key)
                  ),
                });
              }}
              size="sm"
            />
          );
        })}
      </div>
    </>
  );
};
