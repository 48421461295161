import * as Swagger from 'shared/models/swagger';

export type TagFormValues = {
  tags: string[];
};
export const getInitialTagFormValues = (
  product: Swagger.Product | null
): TagFormValues => {
  return {
    tags:
      product?.product_tags && product.product_tags.length > 0
        ? product.product_tags
        : [],
  };
};
export const convertTagFormValuesToProductPatch = (
  values: TagFormValues
): Swagger.Product$Patch => ({
  product_tags: values.tags.filter((tag) => Boolean(tag)),
});
