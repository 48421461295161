import { TranslateFuncType } from 'client/components/Translate';
import { SubscriptionKey } from 'shared/models/swagger';

export const getSubscriptionTitle = (
  subscriptionKey: SubscriptionKey,
  t: TranslateFuncType
): string => {
  switch (subscriptionKey) {
    case 'feature-review':
      return t('Review Feature');

    case 'feature-email-customization':
      return t('Email Customization Feature');

    case 'feature-digital-waiver':
      return t('Digital Waiver Feature');

    case 'standard-plan':
      return t('Standard Plan');

    case 'feature-guide-account':
      return t('Shift Management');

    case 'feature-qr-checkin':
      return t('QR Checkin Feature');

    case 'feature-guest-my-page':
      return t('My Page for Guest Feature');

    case 'feature-image-download':
      return t('Image Download Feature');

    case 'feature-digital-guidance':
      return t('Digital Guidance Feature');

    case 'feature-inquiry':
      return t('Inquiry Feature');

    case 'feature-e-ticket':
      return t('E-ticket Feature');

    case 'feature-resource-management':
      return t('Resource Management Feature');

    case 'feature-vehicle-crew-resource-management':
      return t('Vehicle, Crew, Resource Management Feature');

    case 'feature-survey':
      return t('Survey Feature');

    case 'feature-customer-ledger':
      return t('Customer Membership & Newsletter Feature');

    case 'feature-seat-management':
      return t('Seat Management Feature');

    case 'feature-custom-top-page':
      return t('Custom TOP Page Feature');

    case 'feature-channel-manager':
      return t('Channel Manager Feature');

    case 'feature-annual-pass':
      return t('Annual Pass Feature');

    default:
      throw new Error('unknown subscription key');
  }
};
