import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { useSelector } from 'react-redux';
import { XYCoord } from 'dnd-core';
import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { ReduxState } from 'client/reducers';

import { EditFooterLinkModal } from './EditFooterLinkModal';
import type { FooterLink } from './FormValues';
import styles from './DraggableFooterLink.module.css';

const itemType = 'draggable-footer-link';
export interface Props {
  name: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem: () => Promise<void>;
  onSave: (value: FooterLink) => void;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const DraggableFooterLink = ({
  name,
  index,
  moveItem,
  deleteItem,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const form = useForm();
  const formState = useFormState();
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const value = _.get(formState.values, name);

  const ref = useRef<HTMLDivElement | null>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, DragItem, { handlerId: any }>(
    {
      accept: itemType,

      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },

      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }
  );
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: name,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  if (!value) {
    throw new Error(`value is nil for name=${name}`);
  }

  return (
    <>
      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete footer link')}
          content={t('Are you sure you want to delete footer link?')}
          onConfirm={async () => {
            await deleteItem();
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          loading={loading}
        />
      )}
      {showEditModal && (
        <EditFooterLinkModal
          name={name}
          open={showEditModal}
          onSave={(value: FooterLink) => {
            form.change(name, value);
            onSave?.(value);
          }}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <div
        ref={ref}
        className={styles['container']}
        style={{
          opacity,
        }}
        data-handler-id={handlerId}
      >
        {`#${index + 1}  `}
        <EditIcon
          className={styles['icon-box']}
          onClick={() => setShowEditModal(true)}
        />
        <DeleteIcon
          className={styles['icon-box']}
          onClick={() => setShowDeleteModal(true)}
        />
        <div className={styles['title']}>{value.text}</div>
      </div>
    </>
  );
};
