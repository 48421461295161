import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import { useSelector } from 'react-redux';

import {
  Button,
  Input,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getArrayMutators } from 'client/libraries/util/form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
// import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { ReduxState } from 'client/reducers';
import { ProductSummary } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { ImportProductsModal } from './ImportProductsModal';
import styles from './ServicesEditModal.module.css';

interface Price {
  currency: string;
  amount: string;
}

interface ServiceItem {
  category: string;
  includePrice: boolean;
  price?: Price;
  displayName: string;
  description: string;
  languageCode: string;
}

interface FormValues {
  serviceItems: ServiceItem[];
}

const convertToApiServiceItem = (serviceItem: ServiceItem) => {
  const priceAsFloat = serviceItem.price
    ? parseFloat(serviceItem.price.amount)
    : 0;

  return {
    price: serviceItem.includePrice
      ? {
          currencyCode: serviceItem.price?.currency ?? 'JPY',
          units: priceAsFloat.toFixed(0),
          nanos: (priceAsFloat - Math.floor(priceAsFloat)) * Math.pow(10, 8),
        }
      : undefined,
    freeFormServiceItem: {
      category: serviceItem.category,
      label: {
        displayName: serviceItem.displayName,
        description: serviceItem.description,
        languageCode: serviceItem.languageCode,
      },
    },
  };
};

const convertToFormValueServiceItem = (apiServiceItem: any): ServiceItem => {
  const includePrice = !!apiServiceItem.price;
  let price: Price = {
    currency: 'USD',
    amount: '0',
  };

  if (includePrice) {
    let priceAmount: string = apiServiceItem.price.units;
    if (apiServiceItem.price.nanos) {
      priceAmount = `${priceAmount}.${
        apiServiceItem.price.nanos * Math.pow(10, 9)
      }`;
    }

    price = {
      currency: apiServiceItem.price.currencyCode,
      amount: priceAmount,
    };
  }

  return {
    includePrice,
    price,
    category: apiServiceItem.freeFormServiceItem.category,
    displayName: apiServiceItem.freeFormServiceItem.label?.displayName,
    description: apiServiceItem.freeFormServiceItem.label?.description,
    languageCode: apiServiceItem.freeFormServiceItem.label?.languageCode,
  };
};

const getDefaultServiceItem = (
  defaultCurrency: string,
  category: string
): ServiceItem => {
  return {
    includePrice: false,
    price: {
      currency: defaultCurrency,
      amount: '0',
    },
    category,
    displayName: '',
    description: '',
    languageCode: 'en',
  };
};

interface Props {
  onClose: () => void;
  location: any;
  onSave: (location: any) => void;
}

export const ServicesEditModal = ({ onClose, location, onSave }: Props) => {
  const { t } = useTranslation();
  const [showImportProductsModal, setShowImportProductsModal] =
    React.useState(false);

  const defaultCurrency = useSelector(defaultProductCurrencySelector);

  const initialValues: FormValues = React.useMemo(
    () => ({
      serviceItems: (location.serviceItems ?? []).map((item: any) =>
        convertToFormValueServiceItem(item)
      ),
    }),
    [location]
  );

  const languageOptions = [
    {
      text: t('English'),
      value: 'en',
    },
    {
      text: t('Japanese'),
      value: 'ja',
    },
  ];

  const extranetLanguageCode = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <Modal title={t('Edit Products')} open={true} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          try {
            await onSave({
              serviceItems: values.serviceItems.map((item) =>
                convertToApiServiceItem(item)
              ),
            });
            onClose();
          } catch (err: any) {
            if (err.response?.data?.error) {
              return {
                [FORM_ERROR]: `${t('Error: ')}${
                  err.response.data.error.status
                }: ${JSON.stringify(err.response.data.error.details)}`,
              };
            }
          }
        }}
        mutators={getArrayMutators()}
        debug={console.log}
      >
        {({ handleSubmit, submitError, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Button
                size="middle"
                style="green"
                onClick={() => setShowImportProductsModal(true)}
              >
                {t('Import Products from Nutmeg')}
              </Button>
              {showImportProductsModal && (
                <ImportProductsModal
                  onImport={(products: ProductSummary[]) => {
                    form.change('serviceItems', [
                      ...values.serviceItems,
                      ...products.map((product) => ({
                        includePrice: !!product.lowest_price_gross,
                        price: product.lowest_price_gross
                          ? {
                              currency: product.lowest_price_gross.slice(0, 3),
                              amount: product.lowest_price_gross.slice(3),
                            }
                          : undefined,
                        category: location.categories.primaryCategory.name,
                        displayName: product.product_name ?? '',
                        description: product.description ?? '',
                        languageCode: extranetLanguageCode,
                      })),
                    ]);
                  }}
                  onClose={() => setShowImportProductsModal(false)}
                />
              )}
              <FieldArray name="serviceItems">
                {({ fields }) => (
                  <Box mt={2}>
                    <AddIcon
                      onClick={() =>
                        (fields as any).insertAt(
                          0,
                          getDefaultServiceItem(
                            defaultCurrency,
                            location.categories.primaryCategory.name
                          )
                        )
                      }
                    />
                    <table className={styles['service-table']}>
                      <tbody>
                        {fields.map((name, idx) => (
                          <tr key={name}>
                            <td>
                              <h3>
                                {t('Product #{{idx}}', {
                                  idx: idx + 1,
                                })}
                              </h3>
                              <Box display="flex" alignItems="center" mt={2}>
                                <Field name={`${name}.includePrice`}>
                                  {({ input }) => (
                                    <ToggleButton
                                      label={t('Include Price')}
                                      checked={input.value}
                                      onChange={() =>
                                        input.onChange(!input.value)
                                      }
                                    />
                                  )}
                                </Field>
                                {fields.value?.[idx].includePrice && (
                                  <div className={styles['price']}>
                                    <Field name={`${name}.price`}>
                                      {({ input }) => (
                                        <>
                                          <p>{input.value.currency}</p>
                                          <Input
                                            maxWidth={250}
                                            value={input.value.amount}
                                            onChange={(e: any) => {
                                              if (
                                                !currencyInputAllowed(
                                                  input.value.currency,
                                                  e.target.value
                                                )
                                              ) {
                                                return;
                                              }

                                              input.onChange({
                                                currency: input.value.currency,
                                                amount: e.target.value,
                                              });
                                            }}
                                          />
                                        </>
                                      )}
                                    </Field>
                                  </div>
                                )}
                              </Box>
                              <Field name={`${name}.displayName`}>
                                {({ input }) => (
                                  <Input
                                    label={t('Display Name')}
                                    value={input.value}
                                    onChange={(_: any, { value }) =>
                                      input.onChange(value)
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={`${name}.description`}>
                                {({ input }) => (
                                  <TextArea
                                    label={t('Description')}
                                    value={input.value}
                                    onChange={(_: any, { value }) =>
                                      input.onChange(value)
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={`${name}.languageCode`}>
                                {({ input }) => (
                                  <Select
                                    label={t('Language')}
                                    value={input.value}
                                    onChange={(_: any, { value }) =>
                                      input.onChange(value)
                                    }
                                    options={languageOptions}
                                  />
                                )}
                              </Field>
                              <AddIcon
                                onClick={() =>
                                  (fields as any).insertAt(
                                    idx + 1,
                                    getDefaultServiceItem(
                                      defaultCurrency,
                                      location.categories.primaryCategory.name
                                    )
                                  )
                                }
                              />
                              <DeleteIcon onClick={() => fields.remove(idx)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </FieldArray>

              {submitError && (
                <div
                  style={{ wordBreak: 'break-all' }}
                  className={baseStyles['base-form-box__err']}
                >
                  {submitError}
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button style="blue" size="middle" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
