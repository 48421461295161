import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';

import { config } from 'client/config';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import { Weekday, getWeekdayListText } from 'client/libraries/util/weekdays';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import { TranslateFuncType } from 'client/components/Translate';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { CrewAvailabilityDisplayBox } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/CrewAvailabilityDisplayBox';
import { CrewAvailabilityModal } from 'client/pages/v3/Manifest/ManifestEditResource/Modal/CrewAvailabilityModal';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { CrewEditTable } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/CrewEditTable';
import { DispatchCrewMember } from 'shared/models/swagger';
import { updateDispatchCrewMembers } from 'client/actions/dispatchSettings';

type Props = {
  isActive?: boolean;
};

type CrewMember = {
  key?: string;
  schedule?: {
    start_date_local?: string;
    end_date_local?: string;
    days_of_week?: string[];
    excluded_dates?: string[];
  }[];
  description?: string;
};

const scheduleAsComponent = (
  crewMember: CrewMember,
  t: TranslateFuncType
): JSX.Element => {
  if (!crewMember.schedule || crewMember.schedule.length === 0) {
    return <></>;
  }

  // moment(dateString).format('YYYY/MM/DD')
  const schedule = crewMember.schedule[0];
  const scheduleComponents = [];
  if (schedule.start_date_local && schedule.end_date_local) {
    scheduleComponents.push(
      `${moment(schedule.start_date_local).format('YYYY/MM/DD')} ~ ${moment(
        schedule.end_date_local
      ).format('YYYY/MM/DD')}`
    );
  } else if (schedule.start_date_local && !schedule.end_date_local) {
    scheduleComponents.push(
      `${moment(schedule.start_date_local).format('YYYY/MM/DD')} ~`
    );
  } else if (!schedule.start_date_local && schedule.end_date_local) {
    scheduleComponents.push(
      `~ ${moment(schedule.end_date_local).format('YYYY/MM/DD')}`
    );
  }

  let daysOfWeekComponent;
  if (schedule.days_of_week && schedule.days_of_week.length > 0) {
    // scheduleComponents.push(
    //   getWeekdayListText(schedule.days_of_week as Weekday[], t)
    // );
    daysOfWeekComponent = (
      <div>{getWeekdayListText(schedule.days_of_week as Weekday[], t)}</div>
    );
  }

  let excludedDatesComponent;
  if (schedule.excluded_dates && schedule.excluded_dates.length > 0) {
    const formattedExcludedDates = schedule.excluded_dates.map((dateString) =>
      moment(dateString).format('YYYY/MM/DD')
    );
    const excludedDates = formattedExcludedDates.join('; ');
    excludedDatesComponent = (
      <div className={styles['p-manifests__scheduleDisplay__excluded']}>
        <p>{t('Off days')}:</p>
        <p>{excludedDates}</p>
      </div>
    );
  }

  return (
    <div className={styles['p-manifests__scheduleDisplay']}>
      <div>{scheduleComponents.join(' - ')}</div>
      {schedule.days_of_week && schedule.days_of_week.length > 0 && (
        <>{daysOfWeekComponent}</>
      )}
      {schedule.excluded_dates && schedule.excluded_dates.length > 0 && (
        <>{excludedDatesComponent}</>
      )}
    </div>
  );
};

export const CrewList = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [existingCrewMember, setExistingCrewMember] =
    useState<CrewMember | null>(null);
  const [showCrewAvailabilityModal, setShowCrewAvailabilityModal] =
    useState<boolean>(false);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const currentCrews = useSelector(allDispatchCrewMembersSelector);
  const [crews, setCrews] = useState<DispatchCrewMember[]>([]);
  useEffect(() => {
    setCrews(_.sortBy(currentCrews, (c) => c.key));
  }, [currentCrews]);

  const [editMode, setEditMode] = useState(false);
  // Close edit mode if active tab is changed
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  // Refetch data when edit mode is active
  useEffect(() => {
    if (editMode) {
      setCrews(_.sortBy(currentCrews, (c) => c.key));
    }
  }, [editMode]);

  const getColumns = () => {
    return [
      {
        Header: t('Name'),
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo: any) => <>{cellInfo.value}</>,
        width: '240px',
      },
      {
        Header: t('Schedule'),
        id: 'schedule',
        accessor: (row: any) => scheduleAsComponent(row, t),
        width: '420px',
      },
      {
        Header: t('Description'),
        id: 'description',
        accessor: 'description',
      },
    ];
  };

  const columns = getColumns();

  if (
    config.enableResourceManagementVehicle &&
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      id: 'availability',
      accessor: 'availability',
      Cell: (cellInfo: any) => (
        <div className={styles['p-manifests__vehicleResourceColumn']}>
          <CrewAvailabilityDisplayBox resourceKey={cellInfo.original.key} />
          <Button
            text={t('Edit')}
            onClick={() => {
              setExistingCrewMember(cellInfo.original);
              setShowCrewAvailabilityModal(true);
            }}
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-edit-05"></i>}
          />
        </div>
      ),
      width: 'long',
    } as any);
  }

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Crew')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          {editMode && (
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
          )}
          <Button
            text={editMode ? t('Save') : t('Edit')}
            onClick={() => {
              setEditMode(!editMode);
              if (editMode) {
                dispatch(updateDispatchCrewMembers([...crews]));
              }
            }}
            color={editMode ? 'primary' : 'white'}
            iconBeforeText={
              editMode ? undefined : (
                <i className="c-icon-outline-general-edit-05"></i>
              )
            }
            // Disable button if crew key is empty in edit mode
            disabled={
              editMode && crews.filter((c) => c.key === undefined).length > 0
            }
          />
        </div>
      </div>
      {showCrewAvailabilityModal && (
        <CrewAvailabilityModal
          open={showCrewAvailabilityModal}
          onClose={() => {
            setShowCrewAvailabilityModal(false);
          }}
          resourceKey={existingCrewMember?.key ?? ''}
        />
      )}
      {!editMode && (
        <ManifestCustomizeCustomTable
          itemName={t('Crew')}
          items={_.sortBy(currentCrews, (c) => c.key)}
          columns={columns as any}
        />
      )}
      {editMode && <CrewEditTable initialCrews={crews} setCrews={setCrews} />}

      {editMode && (
        <div
          className={clsx(styles['p-manifests__fixed'], styles['is-active'])}
        >
          <div className={styles['p-manifests__fixed__main']}></div>
          <div className={styles['p-manifests__fixed__actions']}>
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
            <Button
              text={t('Save')}
              onClick={async () => {
                await setLoading(true);
                if (editMode) {
                  await dispatch(updateDispatchCrewMembers([...crews]));
                }
                await setLoading(false);
                await setEditMode(!editMode);
              }}
              color={'primary'}
              disabled={
                editMode && crews.filter((c) => c.key === undefined).length > 0
              }
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};
