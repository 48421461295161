import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  setAvailabilityEndDate,
  setAvailabilityStartDate,
  setMonthlyAvailabilitySelectedMonth,
} from 'client/actions/productCalendarListControls';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { CalendarEditButtonPopup } from 'client/pages/v3/Availability/Shared/Popup/CalendarEditButtonPopup';
import { CalendarEditContext } from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import { AvailabilityWrapper } from 'client/pages/v3/Availability/Shared/AvailabilityWrapper';
import baseStyles from 'client/v3-base.module.css';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { FilterPopup } from 'client/pages/v3/Availability/Shared/Popup/FilterPopup';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

export const AvailabilityMonthlyBody = () => {
  const [selectedProductInstanceIds, setSelectedProductInstanceIds] = useState<
    string[]
  >([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    useState(false);

  const dates = useSelector(productCalendarListDatesSelector);
  const selectedMonth = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.monthlyAvailabilitySelectedMonth
  );
  const activeUser = useSelector(activeUserSelector);
  const [activeView, setActiveView] = useState<'calendar' | 'table'>('table');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      setAvailabilityStartDate(
        moment(selectedMonth).startOf('month').format('YYYY-MM-DD')
      )
    );
    dispatch(
      setAvailabilityEndDate(
        moment(selectedMonth)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
    );
  }, [selectedMonth]);

  const handleViewChange = (view: 'calendar' | 'table') => {
    setActiveView(view);
  };

  return (
    <div className={baseStyles['l-main__body']}>
      <CalendarEditContext.Provider
        value={{
          selectedProductInstanceIds,
          selectProductInstanceIds: (...ids) => {
            const idsToAdd = ids.filter(
              (id) => !selectedProductInstanceIds.includes(id)
            );
            if (idsToAdd.length) {
              setSelectedProductInstanceIds([
                ...selectedProductInstanceIds,
                ...idsToAdd,
              ]);
            }
          },
          deselectProductInstanceIds: (...ids) => {
            setSelectedProductInstanceIds(
              selectedProductInstanceIds.filter((id) => !ids.includes(id))
            );
          },
          calendarEditModeIsActive,
          setCalendarEditModeIsActive,
        }}
      >
        <section className={baseStyles['g-section']}>
          <div
            className={clsx(
              commonStyles['p-availability'],
              calendarEditModeIsActive &&
                commonStyles['p-availability__fixedActive']
            )}
          >
            <div className={commonStyles['p-availability__header']}>
              <div className={commonStyles['p-availability__header__left']}>
                <div
                  className={commonStyles['p-availability__header__left__date']}
                >
                  <Button
                    size="icon"
                    color="white"
                    onClick={() => {
                      dispatch(
                        setMonthlyAvailabilitySelectedMonth(
                          moment(selectedMonth)
                            .add(-1, 'months')
                            .format('YYYY-MM-DD')
                        )
                      );
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-arrows-chevron-left"></i>
                    }
                  />
                  <fieldset>{moment(dates[0]).format('YYYY/MM')}</fieldset>
                  <Button
                    size="icon"
                    color="white"
                    onClick={() => {
                      dispatch(
                        setMonthlyAvailabilitySelectedMonth(
                          moment(selectedMonth)
                            .add(1, 'months')
                            .format('YYYY-MM-DD')
                        )
                      );
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-arrows-chevron-right"></i>
                    }
                  />
                </div>
                <ul
                  className={commonStyles['p-availability__header__left__tab']}
                >
                  <Tooltip
                    text={t(
                      'The availability of the product is displayed in a table.'
                    )}
                  >
                    <li
                      className={
                        activeView === 'table' ? commonStyles['is-active'] : ''
                      }
                      onClick={() => handleViewChange('table')}
                    >
                      <i className="c-icon-outline-layout-layout-alt-01"></i>
                    </li>
                  </Tooltip>
                  <Tooltip
                    text={t(
                      'The availability of the product is displayed in the calendar format. Please use filters to see the desired products’ availabilities.'
                    )}
                    width={360}
                  >
                    <li
                      className={
                        activeView === 'calendar'
                          ? commonStyles['is-active']
                          : ''
                      }
                      onClick={() => handleViewChange('calendar')}
                    >
                      <i className="c-icon-outline-time-calendar"></i>
                    </li>
                  </Tooltip>
                </ul>
                <div
                  className={
                    commonStyles['p-availability__header__left__filter']
                  }
                >
                  <FilterPopup />
                </div>
                {hasCustomUserRoleWritePermissions(
                  activeUser,
                  'AVAILABILITY.MONTHLY'
                ) && <CalendarEditButtonPopup view={activeView} />}
              </div>
            </div>

            <AvailabilityWrapper type="monthly" view={activeView} />
          </div>
        </section>
      </CalendarEditContext.Provider>
    </div>
  );
};
