import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import type { TFunction } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { Button, Input } from 'client/components/Form';
import { ListInput } from 'client/components/ListInput/ListInput';

type OwnProps = {
  onClick: (subject: string, toAddresses: string[]) => void;
  defaultSubject: string;
  trigger?: React.ReactElement<'a' | 'button'>;
  open?: boolean;
  onClose?: () => void;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps & I18nProps & ReturnType<typeof mapStateToProps>;
/* eslint-enable no-use-before-define */

type State = {
  toAddresses: string[];
  subject: string;
  status: 'IDLE' | 'IN_FLIGHT' | 'SUCCESS' | 'FAILURE';
  showModal: boolean;
};

class SendEmailsButtonComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { defaultSubject } = this.props;
    this.state = {
      toAddresses: [],
      subject: defaultSubject,
      status: 'IDLE',
      showModal: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { emailStatus } = this.props;

    if (emailStatus !== prevProps.emailStatus) {
      this.setState({
        status: emailStatus as any,
      });
    }
  }

  render() {
    const { emailError, onClick, t, trigger, open, onClose } = this.props;
    const { status, subject, toAddresses, showModal } = this.state;
    return (
      <Modal
        title={t('Send Manifest Emails')}
        trigger={trigger}
        open={open || showModal}
        onOpen={() => {
          this.setState({
            showModal: true,
          });
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }

          this.setState({
            showModal: false,
          });
        }}
      >
        <Modal.Content>
          <Modal.Box>
            <Input
              label={t('Subject')}
              value={subject}
              onChange={(e, { value }) =>
                this.setState({
                  subject: value,
                })
              }
            />
          </Modal.Box>
          <Modal.Box>
            <ListInput
              label={t('Email addresses')}
              value={toAddresses}
              onChange={(toAddresses) =>
                this.setState({
                  toAddresses,
                })
              }
            />
          </Modal.Box>
          <Modal.Box>
            {status === 'SUCCESS' && (
              <Message success header={t('Email sent successfully')} />
            )}

            {status === 'FAILURE' && (
              <Message
                error
                header={t('Email failed to send')}
                content={emailError}
              />
            )}
          </Modal.Box>
        </Modal.Content>

        <Modal.Actions>
          <Button.Submit
            onClick={() => onClick(subject, toAddresses)}
            disabled={!subject || (toAddresses || []).length === 0}
          >
            {status === 'IN_FLIGHT' ? t('Sending...') : t('Send')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  emailError: state.manifests.emailError,
  emailStatus: state.manifests.emailStatus,
});

export const SendEmailsButton = compose<Props, OwnProps>(
  connect(mapStateToProps),
  withTranslation()
)(SendEmailsButtonComponent);
