import { useState } from 'react';

import styles from './PcSidebarMenu.module.css';

type MenuProps = {
  sections: {
    title: string;
    id: string;
  }[];
};

export const PcSidebarMenu = ({ sections }: MenuProps) => {
  const [activeSection, setActiveSection] = useState<string | null>(
    'product-info'
  );

  const handleMenuItemClick = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
  };

  return (
    <nav className={styles['c-subnav']}>
      {sections.map((section, index) => {
        return (
          <li
            key={index}
            className={activeSection === section.id ? styles['is-active'] : ''}
          >
            <a onClick={() => handleMenuItemClick(section.id)}>
              {section.title}
            </a>
          </li>
        );
      })}
    </nav>
  );
};
