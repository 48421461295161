import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Checkbox } from 'client/components/v3/Form/Checkbox';

export const GuestMyPageSettings = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        style={{
          fontSize: 'var(--text-sm-size)',
          fontWeight: 'var(--text-semibold)',
          lineHeight: 'var(--text-sm-height)',
          color: 'var(--gray700);',
          marginBottom: '10px',
        }}
      >
        {t('Allowed operations on My Page for Guest')}
      </div>
      <div
        style={{
          marginBottom: '5px',
        }}
      >
        <Field name="allowViewingReservationDetails">
          {({ input }) => (
            <Checkbox
              label={t('View reservation details')}
              checked={input.value}
              onChange={() => input.onChange(!input.value)}
              size="sm"
            />
          )}
        </Field>
      </div>
      <div
        style={{
          marginBottom: '5px',
        }}
      >
        <Field name="allowUpdatingReservationInfo">
          {({ input }) => (
            <Checkbox
              label={t('Update hotel, form answers')}
              checked={input.value}
              onChange={() => input.onChange(!input.value)}
              size="sm"
            />
          )}
        </Field>
      </div>
      <div
        style={{
          marginBottom: '5px',
        }}
      >
        <Field name="allowChangingReservationParameters">
          {({ input }) => (
            <Checkbox
              label={t(
                'Change reservations (Participation date, time, participants, add-on, transportation)'
              )}
              checked={input.value}
              onChange={() => input.onChange(!input.value)}
              size="sm"
            />
          )}
        </Field>
      </div>
      <div
        style={{
          marginBottom: '5px',
        }}
      >
        <Field name="allowCancellingReservation">
          {({ input }) => (
            <Checkbox
              label={t('Cancel')}
              checked={input.value}
              onChange={() => input.onChange(!input.value)}
              size="sm"
            />
          )}
        </Field>
      </div>
      <Field name="allowIssuingDownloadingReceiptPDF">
        {({ input }) => (
          <Checkbox
            label={t('Issue receipt/download')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
            size="sm"
          />
        )}
      </Field>
    </div>
  );
};
