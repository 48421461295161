import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_WAIVER_TEMPLATE_REQUEST,
  CREATE_WAIVER_TEMPLATE_SUCCESS,
  CREATE_WAIVER_TEMPLATE_FAILURE,
  DELETE_WAIVER_TEMPLATE_REQUEST,
  DELETE_WAIVER_TEMPLATE_SUCCESS,
  DELETE_WAIVER_TEMPLATE_FAILURE,
  UPDATE_WAIVER_TEMPLATE_REQUEST,
  UPDATE_WAIVER_TEMPLATE_SUCCESS,
  UPDATE_WAIVER_TEMPLATE_FAILURE,
  FETCH_WAIVER_TEMPLATES_REQUEST,
  FETCH_WAIVER_TEMPLATES_SUCCESS,
  FETCH_WAIVER_TEMPLATES_FAILURE,
  FETCH_WAIVER_TEMPLATE_REQUEST,
  FETCH_WAIVER_TEMPLATE_SUCCESS,
  FETCH_WAIVER_TEMPLATE_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const createWaiverTemplateRequest = (
  newWaiverTemplate: Swagger.WaiverTemplateParams
) => ({
  type: CREATE_WAIVER_TEMPLATE_REQUEST,
  newWaiverTemplate,
});

const createWaiverTemplateSuccess = (response: any) => ({
  type: CREATE_WAIVER_TEMPLATE_SUCCESS,
  response,
});

const createWaiverTemplateFailure = (error: string) => ({
  type: CREATE_WAIVER_TEMPLATE_FAILURE,
  error,
});

export const createWaiverTemplate =
  (newWaiverTemplate: Swagger.WaiverTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createWaiverTemplateRequest(newWaiverTemplate));
    return axios
      .post('/api/waivertemplates', newWaiverTemplate, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createWaiverTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createWaiverTemplateFailure(err.message));
      });
  };

const updateWaiverTemplateRequest = (
  id: string,
  waiverTemplate: Swagger.WaiverTemplateParams
) => ({
  type: UPDATE_WAIVER_TEMPLATE_REQUEST,
  id,
  waiverTemplate,
});

const updateWaiverTemplateSuccess = (response: any) => ({
  type: UPDATE_WAIVER_TEMPLATE_SUCCESS,
  response,
});

const updateWaiverTemplateFailure = (error: string) => ({
  type: UPDATE_WAIVER_TEMPLATE_FAILURE,
  error,
});

export const updateWaiverTemplate =
  (id: string, waiverTemplate: Swagger.WaiverTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateWaiverTemplateRequest(id, waiverTemplate));
    return axios
      .patch(`/api/waivertemplates/${id}`, waiverTemplate, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateWaiverTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateWaiverTemplateFailure(err.message));
      });
  };

const fetchWaiverTemplatesRequest = () => ({
  type: FETCH_WAIVER_TEMPLATES_REQUEST,
});

const fetchWaiverTemplatesSuccess = (response: any) => ({
  type: FETCH_WAIVER_TEMPLATES_SUCCESS,
  response,
});

const fetchWaiverTemplatesFailure = (error: string) => ({
  type: FETCH_WAIVER_TEMPLATES_FAILURE,
  error,
});

export const fetchWaiverTemplates =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchWaiverTemplatesRequest());
    return axios
      .get('/api/waivertemplates', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchWaiverTemplatesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchWaiverTemplatesFailure(err.message));
      });
  };

const fetchWaiverTemplateRequest = () => ({
  type: FETCH_WAIVER_TEMPLATE_REQUEST,
});

const fetchWaiverTemplateSuccess = (response: any) => ({
  type: FETCH_WAIVER_TEMPLATE_SUCCESS,
  response,
});

const fetchWaiverTemplateFailure = (error: string) => ({
  type: FETCH_WAIVER_TEMPLATE_FAILURE,
  error,
});

export const fetchSourceLanguageWaiverTemplateByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchWaiverTemplateRequest());
    return axios
      .get(`/api/waivertemplates/${id}?ignore_request_language=true`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchWaiverTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchWaiverTemplateFailure(err.message));
      });
  };

const deleteWaiverTemplateRequest = (id: string) => ({
  type: DELETE_WAIVER_TEMPLATE_REQUEST,
  id,
});

const deleteWaiverTemplateSuccess = (
  response: Record<string, any>,
  id: string
) => ({
  type: DELETE_WAIVER_TEMPLATE_SUCCESS,
  response,
  id,
});

const deleteWaiverTemplateFailure = (error: string) => ({
  type: DELETE_WAIVER_TEMPLATE_FAILURE,
  error,
});

export const deleteWaiverTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteWaiverTemplateRequest(id));
    return fetch(`/api/waivertemplates/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response) => {
        dispatch(deleteWaiverTemplateSuccess(response, id));
      })
      .catch((error) => {
        error.json().then((e: any) => {
          dispatch(deleteWaiverTemplateFailure(e.message));
        });
      });
  };
