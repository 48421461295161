import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { ModalLoader } from 'client/components/ModalLoader';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { useCustomers } from 'client/hooks/useCustomers';
import { useImportedCustomers } from 'client/hooks/useImportedCustomers';
import { Customer } from 'shared/models/swagger';

interface Props {
  customerIds: string[];
  s3PresignedUrl?: string;
  onClose: () => void;
}

export const CustomersModal = ({
  customerIds,
  s3PresignedUrl,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const { customers, isLoading } = useCustomers({ customerIds });

  const { importedCustomers, isImportedLoading } = useImportedCustomers({
    s3PresignedUrl,
  });

  const allCustomer = [...(customers ?? []), ...(importedCustomers ?? [])];
  const allIsLoading = isLoading || isImportedLoading;

  const columns: ColumnType<Customer>[] = [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (c: Customer) => {
        if (c.family_name && c.given_name) {
          return `${c.given_name} ${c.family_name}`;
        }

        if (c.kana_family_name && c.kana_given_name) {
          return `${c.kana_family_name} ${c.kana_given_name}`;
        }
      },
    },
    {
      Header: t('Email'),
      id: 'email',
      accessor: (c: Customer) => {
        return c.email;
      },
    },
  ];

  return (
    <Modal open onClose={onClose} title={t('Contacts')}>
      <Modal.Content>
        {allIsLoading && <ModalLoader />}

        {!allIsLoading && (
          <PagedGenericTable allItems={allCustomer ?? []} columns={columns} />
        )}
      </Modal.Content>
    </Modal>
  );
};
