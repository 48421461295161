/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';

interface StarRatingProps {
  rating: number;
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;

  return (
    <div className={styles.starRating}>
      {[...Array(5)].map((_, index) => (
        <img
          key={index}
          src={
            index < fullStars
              ? 'https://cdn.builder.io/api/v1/image/assets/TEMP/db032c76ed5e27b0729aab301e07d92467e96ccfc21f75c9e8982fde013e25ac?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818'
              : index === fullStars && hasHalfStar
              ? 'https://cdn.builder.io/api/v1/image/assets/TEMP/734247268968883d43d0f413ba0620891d5425c1ed15b56fd39b738389e5df9e?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818'
              : 'https://cdn.builder.io/api/v1/image/assets/TEMP/dfb40b76d910a88b362b9f8e6346fd68c27ae7dab2f0928bf6e360e5e99e7f26?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818'
          }
          alt=""
          className={styles.starIcon}
        />
      ))}
    </div>
  );
};

export default StarRating;
