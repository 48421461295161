import * as React from 'react';

interface Props {
  data: {
    title: string;
    items: string[];
    style: string;
  };
}

export const PlanSummarySection = ({ data }: Props) => {
  const { title, items, style } = data;

  if (style === 'BLUE') {
    return (
      <>
        <div
          style={{
            marginTop: '1em',
            padding: '1em .5em',
            background: '#E6F4FA',
            borderRadius: '4px',
            borderLeft: '4px solid #008EC9',
          }}
        >
          <div style={{ width: '100%', display: 'table' }}>
            <div
              style={{
                width: '40px',
                height: '40px',
                background: '#FFFFFF',
                borderRadius: '100%',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
            >
              <img
                src="https://assets.ntmg.com/newsletter/calendar1.png"
                style={{
                  width: '24px',
                  height: '24px',
                  margin: '8px',
                }}
              />
            </div>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#008EC9',
                paddingLeft: '8px',
                display: 'table-cell',
                verticalAlign: 'middle',
                whiteSpace: 'pre-line',
              }}
            >
              {title}
            </p>
          </div>
          <div
            style={{
              width: '100%',
              marginTop: '.5em',
              display: 'table',
            }}
          >
            {items?.map((item, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  margin: '0 .5em 0 0',
                  padding: 0,
                  whiteSpace: 'pre-line',
                }}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
      </>
    );
  } else if (style === 'YELLOW') {
    return (
      <>
        <div
          style={{
            marginTop: '2em',
            padding: '1em .5em',
            borderRadius: '4px',
            border: '2px solid #FFCC00',
            position: 'relative',
          }}
        >
          <p
            style={{
              width: '280px',
              height: '24px',
              fontSize: '22px',
              fontWeight: 'bold',
              textAlign: 'center',
              background: '#FFFFFF',
              margin: '0 auto',
              padding: '0 .5em',
              display: 'block',
              position: 'relative',
              top: '-24px',
              boxSizing: 'border-box',
              whiteSpace: 'pre-line',
            }}
          >
            {title}
          </p>
          <div
            style={{
              width: '100%',
              marginTop: '-0.5em',
              display: 'table',
            }}
          >
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
            >
              {items?.map((item, idx) => (
                <p
                  key={idx}
                  style={{
                    margin: 0,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    verticalAlign: 'middle',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        style={{
          marginTop: '2em',
          padding: '1em .5em',
          borderRadius: '4px',
          border: '2px solid #FF6464',
          background: '#FFEBEB',
          position: 'relative',
        }}
      >
        <p
          style={{
            width: '280px',
            lineHeight: '36px',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            borderRadius: '36px',
            color: '#FFFFFF',
            background: '#FF6464',
            margin: '0 auto',
            padding: '0 .5em',
            display: 'block',
            position: 'relative',
            top: '-36px',
            boxSizing: 'border-box',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </p>
        <div
          style={{
            width: '100%',
            marginTop: '-1em',
            display: 'table',
          }}
        >
          {items?.map((item, idx) => (
            <p
              key={idx}
              style={{
                margin: 0,
                padding: '.5em',
                background: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};
