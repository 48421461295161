import * as React from 'react';

import { useBounds } from 'client/hooks/useBounds';
import infoIcon from 'client/images/ic_info.png';

import { PortalTooltip } from './PortalTooltip';

interface Props {
  text: string;
}

export const Info = ({ text }: Props) => {
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      ref={divRef}
    >
      <img src={infoIcon} />
      {showTooltip && (
        <PortalTooltip
          text={text}
          style={{
            left: divBounds.left + 40,
            top: divBounds.top,
          }}
        />
      )}
    </div>
  );
};
