import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';

import {
  Button,
  FieldWrapper,
  Input,
  OptionalIntegerInput,
  Select,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ReduxState } from 'client/reducers';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { Delete } from 'client/components/Icons/Delete';
import { Add } from 'client/components/Icons/Add';
import calendarIcon from 'client/images/ic_calendar.svg';
import {
  createGroupBookingTemplate,
  updateGroupBookingTemplate,
} from 'client/actions/groupBookingTemplates';
import { DateInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DateInput';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
} from './formValues';

const defaultInitialValues: FormValues = {
  name: '',
  status: 'OFF',
  products: [],
  startDateLocalFrom: '',
  startDateLocalTo: '',
  groupName: '',
  logoUrl: '',
};

export const GroupBookingTemplateEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productOptions = allProducts.map((product) => ({
    value: product.id ?? '',
    text: product.internal_product_name ?? '',
  }));

  const existingGroupBookingTemplate = useSelector((state: ReduxState) =>
    state.groupBookingTemplates.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [activeUserOrganization?.id]);

  const initialValues = React.useMemo(() => {
    return existingGroupBookingTemplate
      ? convertSwaggerToFormValues(existingGroupBookingTemplate)
      : defaultInitialValues;
  }, [existingGroupBookingTemplate]);

  const statusOptions = [
    {
      value: 'ON',
      label: t('On'),
    },
    {
      value: 'OFF',
      label: t('Off'),
    },
  ];

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2}>
          <Link to="/groups">
            <BackArrow />
          </Link>
        </Box>
        <Form
          onSubmit={async (values: FormValues) => {
            if (id) {
              await dispatch(
                updateGroupBookingTemplate(
                  id,
                  convertFormValuesToSwagger(values)
                )
              );
            } else {
              await dispatch(
                createGroupBookingTemplate(convertFormValuesToSwagger(values))
              );
            }
          }}
          initialValues={initialValues}
          debug={console.log}
          mutators={getArrayMutators()}
        >
          {({
            handleSubmit,
            submitting,
            submitSucceeded,
            submitError,
            modifiedSinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Basics')}</th>
                      <td>
                        <Field name="name">
                          {({ input }) => (
                            <Input label={t('Name (internal)')} {...input} />
                          )}
                        </Field>
                        <Box mt={2}>
                          <FieldWrapper label={t('Status')}>
                            <EnumRadioButtonGroup
                              name="status"
                              options={statusOptions}
                            />
                          </FieldWrapper>
                        </Box>
                        <Box maxWidth="350px">
                          <FieldWrapper label={t('Participation Date Range')}>
                            <div className={baseStyles['base-form-range']}>
                              <label
                                className={baseStyles['base-form-calendar']}
                              >
                                <img src={calendarIcon} />
                                <Field name="startDateLocalFrom">
                                  {({ input }) => (
                                    <DateInput
                                      name={input.name}
                                      value={input.value}
                                      onChange={input.onChange}
                                    />
                                  )}
                                </Field>
                              </label>
                              <p>-</p>
                              <label
                                className={baseStyles['base-form-calendar']}
                              >
                                <img src={calendarIcon} />
                                <Field name="startDateLocalTo">
                                  {({ input }) => (
                                    <DateInput
                                      name={input.name}
                                      value={input.value}
                                      onChange={input.onChange}
                                    />
                                  )}
                                </Field>
                              </label>
                            </div>
                          </FieldWrapper>
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Products')}</th>
                      <td>
                        <FieldArray name="products">
                          {({ fields }) => (
                            <>
                              <Add
                                onClick={() =>
                                  (fields as any).insertAt(0, {
                                    productId: '',
                                    limit: 10,
                                  })
                                }
                              />
                              {fields.map((name, index) => (
                                <div key={name}>
                                  <Box display="flex" alignItems="flex-end">
                                    <Field name={`${name}.productId`}>
                                      {({ input }) => (
                                        <Select
                                          label={t('Product')}
                                          search
                                          options={productOptions}
                                          value={input.value}
                                          onChange={(e, { value }) =>
                                            input.onChange(value)
                                          }
                                        />
                                      )}
                                    </Field>
                                    <Field name={`${name}.limit`}>
                                      {({ input }) => (
                                        <OptionalIntegerInput
                                          label={t('Limit')}
                                          value={input.value}
                                          onChange={(newValue) =>
                                            input.onChange(newValue)
                                          }
                                        />
                                      )}
                                    </Field>
                                    <Box ml={2}>
                                      <Delete
                                        onClick={() => fields.remove(index)}
                                      />
                                    </Box>
                                    <Box ml={2}>
                                      <Add
                                        onClick={() =>
                                          (fields as any).insertAt(index + 1, {
                                            productId: '',
                                            limit: 10,
                                          })
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </div>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Booking Site Display')}</th>
                      <td>
                        <Field name="groupName">
                          {({ input }) => (
                            <Input label={t('Group Name')} {...input} />
                          )}
                        </Field>
                        <Field name="logoUrl">
                          {({ input, meta: { touched, error } }) => (
                            <FieldWrapper label={t('Logo Image (jpg, png)')}>
                              <ImageVideoAudioInput
                                fileUrls={input.value ? [input.value] : []}
                                onChange={(newValue) =>
                                  newValue.length > 0
                                    ? input.onChange(newValue[0])
                                    : input.onChange('')
                                }
                                maxFileCount={1}
                                disableYoutubeVideos
                                error={touched && error}
                              />
                            </FieldWrapper>
                          )}
                        </Field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>

              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
