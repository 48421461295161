import { combineReducers } from 'redux';

import {
  FETCH_ACCESS_REPORT_DATA_FAILURE,
  FETCH_ACCESS_REPORT_DATA_REQUEST,
  FETCH_ACCESS_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { GetAccessReportDataResponse } from 'shared/models/swagger';

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_ACCESS_REPORT_DATA_FAILURE:
      return action.error;

    case FETCH_ACCESS_REPORT_DATA_REQUEST:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_ACCESS_REPORT_DATA_REQUEST:
      return true;
    case FETCH_ACCESS_REPORT_DATA_SUCCESS:
    case FETCH_ACCESS_REPORT_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

const reportData = (state = [], action: any): any => {
  switch (action.type) {
    case FETCH_ACCESS_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetAccessReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const fetchAccessReportDataStatus = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_ACCESS_REPORT_DATA_REQUEST:
      return 'REQUESTED';

    case FETCH_ACCESS_REPORT_DATA_SUCCESS:
      return 'SUCCEEDED';

    case FETCH_ACCESS_REPORT_DATA_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

export const accessReportData = combineReducers({
  error,
  loading,
  reportData,
  fetchAccessReportDataStatus,
});
