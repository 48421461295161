import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { updateProduct } from 'client/actions/products';
import { updateOrganization } from 'client/actions/organizations';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productPricesSummariesSortedByBookmarkedSelector } from 'client/reducers/productPrices';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  productId: string;
  deleteSuccess: () => void;
};

export const JaranMappingDeleteModal = ({
  setShowModal,
  productId,
  deleteSuccess,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productPrices = useSelector(
    productPricesSummariesSortedByBookmarkedSelector
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const jaranProductMapping =
    activeUserOrganization?.jaran_csv_import_products ?? [];

  const targetProduct = productPrices.find((p) => p.id == productId);
  const recurrence = targetProduct?.recurrence || [];
  const pricing = targetProduct?.pricing || [];
  recurrence.forEach((r) => {
    r.start_times.forEach((s) => {
      s.third_party_reference = '';
    });
  });
  pricing.forEach((p) => {
    p.units.forEach((u) => {
      if (u?.guest_type?.third_party_reference) {
        u.guest_type.third_party_reference = '';
      }
    });
  });
  const newMapping = jaranProductMapping.filter(
    (j) => j.product_id !== productId
  );

  const text = t(
    'Are you sure you want to delete setting for "{{productName}}"?',
    {
      productName: getDisplayProductName(targetProduct),
    }
  );

  return (
    <Modal
      title={t('Delete Setting')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <div>{text}</div>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button.Delete
          onClick={async () => {
            await dispatch(
              updateProduct(productId, {
                recurrence: recurrence,
                pricing: pricing,
              })
            );
            await dispatch(
              updateOrganization(activeUserOrganization?.id || '', 'SUPPLIER', {
                jaran_csv_import_products:
                  newMapping.length > 0 ? newMapping : [{}],
              })
            );
            setShowModal(false);
            deleteSuccess();
          }}
        >
          {t('OK')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  );
};
