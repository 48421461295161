import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { CrewScheduleInput } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/CrewScheduleInput';
import { DispatchCrewMember } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';

type Props = {
  initialCrews: DispatchCrewMember[];
  setCrews: Dispatch<SetStateAction<DispatchCrewMember[]>>;
};

export const CrewEditTable = ({ initialCrews, setCrews }: Props) => {
  const { t } = useTranslation();

  const handleScheduleChange = (
    startDate: string,
    endDate: string,
    daysOfWeek: string[],
    excludedDates: string[],
    index: number
  ) => {
    const newSchedule = [
      {
        start_date_local: startDate,
        end_date_local: endDate,
        days_of_week: daysOfWeek,
        excluded_dates: excludedDates,
      },
    ];
    const newCrews = [...initialCrews];
    newCrews[index] = {
      ...newCrews[index],
      schedule: newSchedule as any,
    };
    setCrews(newCrews);
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      {initialCrews.length === 0 && (
        <div>{t('{{item}} is not registered.', { item: t('Crew') })}</div>
      )}
      {initialCrews.length !== 0 && (
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              <th style={{ width: '25%' }}>{t('Name')}</th>
              <th style={{ width: '40%' }}>{t('Schedule')}</th>
              <th>{t('Description')}</th>
            </tr>
          </thead>
          <tbody>
            {initialCrews.map((crew, index) => (
              <tr key={index}>
                <td className={tableStyles['c-tableSmall__spTtl']}>
                  <TextField
                    value={crew.key ?? ''}
                    onChange={(value) => {
                      const newCrews = [...initialCrews];
                      newCrews[index] = {
                        ...newCrews[index],
                        key: value,
                      };
                      setCrews(newCrews);
                    }}
                  />
                </td>
                <td
                  className={clsx(
                    tableStyles['noBefore'],
                    styles['p-manifests__scheduleInput']
                  )}
                >
                  <CrewScheduleInput
                    crewMember={crew}
                    index={index}
                    onScheduleChange={handleScheduleChange}
                  />
                </td>
                <td className={tableStyles['noBefore']}>
                  <div
                    className={clsx(
                      styles['p-manifests__customize__flex'],
                      styles['p-manifests__scheduleInput__textArea']
                    )}
                    style={{ width: '100%' }}
                  >
                    <TextArea
                      value={crew.description ?? ''}
                      onChange={(_, { value }) => {
                        const newCrews = [...initialCrews];
                        newCrews[index] = {
                          ...newCrews[index],
                          description: value,
                        };
                        setCrews(newCrews);
                      }}
                      height={80}
                      fullContainerWidth={true}
                    />
                    <div className={baseStyles['u-spHidden']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => {
                          const newCrews = [...initialCrews];
                          newCrews.splice(index, 1);
                          setCrews(newCrews);
                        }}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>
                </td>
                <td className={baseStyles['u-pcHidden']}>
                  <Button
                    size="icon"
                    color="tertiarygray"
                    onClick={() => {
                      const newCrews = [...initialCrews];
                      newCrews.splice(index, 1);
                      setCrews(newCrews);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <a
        className={styles['p-manifests__customize__add']}
        onClick={() => {
          const newCrews = [...initialCrews];
          newCrews.splice(initialCrews.length + 1, initialCrews.length, {
            key: undefined,
            description: '',
          });
          setCrews(newCrews);
        }}
      >
        <i className="c-icon-outline-general-plus-circle"></i>
        {t('Add New Crew Member')}
      </a>
    </div>
  );
};
