import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  CREATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  CREATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  DELETE_PRODUCT_AGENT_REFERENCE_FAILURE,
  DELETE_PRODUCT_AGENT_REFERENCE_REQUEST,
  DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS,
  UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  ProductAgentReference,
  ListProductAgentReferencesResponse,
  CreateProductAgentReferenceResponse,
} from 'shared/models/swagger';

const byIDSelector = (state: ReduxState) => state.productAgentReferences.byID;

const idsSelector = (state: ReduxState) => state.productAgentReferences.ids;

export const productAgentReferencesSelector = createSelector(
  byIDSelector,
  idsSelector,
  (byID, ids) => ids.map((id: string) => byID[id])
);

const error = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_PRODUCT_AGENT_REFERENCE_FAILURE:
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE:
    case DELETE_PRODUCT_AGENT_REFERENCE_FAILURE:
      return action.error;

    case CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS:
    case DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case CREATE_PRODUCT_AGENT_REFERENCE_REQUEST:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST:
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST:
    case DELETE_PRODUCT_AGENT_REFERENCE_REQUEST:
    case UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST:
      return true;

    case CREATE_PRODUCT_AGENT_REFERENCE_FAILURE:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE:
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE:
    case DELETE_PRODUCT_AGENT_REFERENCE_FAILURE:
    case UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE:
    case CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS:
    case DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const byID = (
  state: Record<string, ProductAgentReference> = {},
  action: any
) => {
  switch (action.type) {
    case UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS: {
      const response =
        action.response as any as CreateProductAgentReferenceResponse;
      return { ...state, [response.id]: { ...response } };
    }
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS: {
      const response =
        action.response as any as ListProductAgentReferencesResponse;
      const result: { [id: string]: ProductAgentReference } = {};
      response.product_agent_references.forEach((g) => {
        result[g.id] = g;
      });
      return result;
    }

    case DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS: {
      const result = { ...state };
      delete result[action.id];
      return result;
    }
    default:
      return state;
  }
};

const ids = (state: string[] = [], action: any) => {
  switch (action.type) {
    case UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS: {
      const response =
        action.response as any as CreateProductAgentReferenceResponse;
      return [...new Set(state).add(response.id)];
    }

    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS: {
      const response =
        action.response as any as ListProductAgentReferencesResponse;
      return response.product_agent_references.map((g) => g.id);
    }

    case DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS:
      return state.filter((id) => id !== action.id);

    default:
      return state;
  }
};

const all = (
  state: ProductAgentReference[] = [],
  action: Record<string, any>
): ProductAgentReference[] => {
  switch (action.type) {
    case FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS: {
      const response =
        action.response as any as ListProductAgentReferencesResponse;
      return response.product_agent_references;
    }

    case UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS:
    case CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS: {
      const response =
        action.response as any as CreateProductAgentReferenceResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const productAgentReferences = combineReducers({
  error,
  loading,
  byID,
  ids,
  all,
});
