import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_RESTAURANTS_SUCCESS,
  FETCH_RESTAURANTS_FAILURE,
  FETCH_RESTAURANTS_REQUEST,
  FETCH_RESTAURANT_SUCCESS,
  FETCH_RESTAURANT_FAILURE,
  FETCH_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  CREATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_FAILURE,
  UPDATE_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_REQUEST,
  CREATE_RESTAURANT_FAILURE,
  CREATE_RESTAURANT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { RestaurantParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchRestaurantsRequest = () => ({
  type: FETCH_RESTAURANTS_REQUEST,
});

const fetchRestaurantsSuccess = (response: any) => ({
  type: FETCH_RESTAURANTS_SUCCESS,
  response,
});

const fetchRestaurantsFailure = (error: any) => ({
  type: FETCH_RESTAURANTS_FAILURE,
  error,
});

let fetchRestaurantsCancel: () => void | undefined;
export const fetchRestaurants =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchRestaurantsCancel?.();
    dispatch(fetchRestaurantsRequest());
    axios
      .get('/api/restaurants', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchRestaurantsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchRestaurantsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRestaurantsFailure('canceled'));
        dispatch(fetchRestaurantsFailure(error));
      });
  };

const fetchRestaurantRequest = () => ({
  type: FETCH_RESTAURANT_REQUEST,
});

const fetchRestaurantSuccess = (response: any) => ({
  type: FETCH_RESTAURANT_SUCCESS,
  response,
});

const fetchRestaurantFailure = (error: any) => ({
  type: FETCH_RESTAURANT_FAILURE,
  error,
});

export const fetchRestaurant =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchRestaurantRequest());
    axios
      .get(`/api/restaurants/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchRestaurantSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchRestaurantFailure('canceled'));
        else dispatch(fetchRestaurantFailure(error));
      });
  };

const deleteRestaurantRequest = (id: string) => ({
  type: DELETE_RESTAURANT_REQUEST,
  id,
});

const deleteRestaurantSuccess = (response: any, id: string) => ({
  type: DELETE_RESTAURANT_SUCCESS,
  response,
  id,
});

const deleteRestaurantFailure = (error: any) => ({
  type: DELETE_RESTAURANT_FAILURE,
  error,
});

export const deleteRestaurant =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteRestaurantRequest(id));
    axios
      .delete(`/api/restaurants/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteRestaurantSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteRestaurantFailure(error)));
  };

const createRestaurantRequest = (newRestaurant: RestaurantParams) => ({
  type: CREATE_RESTAURANT_REQUEST,
  newRestaurant,
});

const createRestaurantSuccess = (response: any) => ({
  type: CREATE_RESTAURANT_SUCCESS,
  response,
});

const createRestaurantFailure = (error: any) => ({
  type: CREATE_RESTAURANT_FAILURE,
  error,
});

export const createRestaurant =
  (newRestaurant: RestaurantParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createRestaurantRequest(newRestaurant));
    return axios
      .post('/api/restaurants', newRestaurant, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createRestaurantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createRestaurantFailure(err.message));
      });
  };

const updateRestaurantRequest = (id: string, patch: RestaurantParams) => ({
  type: UPDATE_RESTAURANT_REQUEST,
  id,
  patch,
});

const updateRestaurantSuccess = (response: any) => ({
  type: UPDATE_RESTAURANT_SUCCESS,
  response,
});

const updateRestaurantFailure = (error: any) => ({
  type: UPDATE_RESTAURANT_FAILURE,
  error,
});

export const updateRestaurant =
  (id: string, patch: RestaurantParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateRestaurantRequest(id, patch));
    return axios
      .patch(`/api/restaurants/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateRestaurantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateRestaurantFailure(err.message));
      });
  };
