import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_DIGITAL_MAPS_SUCCESS,
  FETCH_DIGITAL_MAPS_FAILURE,
  FETCH_DIGITAL_MAPS_REQUEST,
  FETCH_DIGITAL_MAP_SUCCESS,
  FETCH_DIGITAL_MAP_FAILURE,
  FETCH_DIGITAL_MAP_REQUEST,
  DELETE_DIGITAL_MAP_FAILURE,
  DELETE_DIGITAL_MAP_REQUEST,
  DELETE_DIGITAL_MAP_SUCCESS,
  CREATE_DIGITAL_MAP_REQUEST,
  UPDATE_DIGITAL_MAP_FAILURE,
  UPDATE_DIGITAL_MAP_SUCCESS,
  UPDATE_DIGITAL_MAP_REQUEST,
  CREATE_DIGITAL_MAP_FAILURE,
  CREATE_DIGITAL_MAP_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { DigitalMapParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchDigitalMapsRequest = () => ({
  type: FETCH_DIGITAL_MAPS_REQUEST,
});

const fetchDigitalMapsSuccess = (response: any) => ({
  type: FETCH_DIGITAL_MAPS_SUCCESS,
  response,
});

const fetchDigitalMapsFailure = (error: any) => ({
  type: FETCH_DIGITAL_MAPS_FAILURE,
  error,
});

let fetchDigitalMapsCancel: () => void | undefined;
export const fetchDigitalMaps =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchDigitalMapsCancel?.();
    dispatch(fetchDigitalMapsRequest());
    axios
      .get('/api/digitalmaps', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchDigitalMapsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchDigitalMapsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchDigitalMapsFailure('canceled'));
        dispatch(fetchDigitalMapsFailure(error));
      });
  };

const fetchDigitalMapRequest = () => ({
  type: FETCH_DIGITAL_MAP_REQUEST,
});

const fetchDigitalMapSuccess = (response: any) => ({
  type: FETCH_DIGITAL_MAP_SUCCESS,
  response,
});

const fetchDigitalMapFailure = (error: any) => ({
  type: FETCH_DIGITAL_MAP_FAILURE,
  error,
});

export const fetchDigitalMap =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchDigitalMapRequest());
    axios
      .get(`/api/digitalmaps/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchDigitalMapSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchDigitalMapFailure('canceled'));
        else dispatch(fetchDigitalMapFailure(error));
      });
  };

const deleteDigitalMapRequest = (id: string) => ({
  type: DELETE_DIGITAL_MAP_REQUEST,
  id,
});

const deleteDigitalMapSuccess = (response: any, id: string) => ({
  type: DELETE_DIGITAL_MAP_SUCCESS,
  response,
  id,
});

const deleteDigitalMapFailure = (error: any) => ({
  type: DELETE_DIGITAL_MAP_FAILURE,
  error,
});

export const deleteDigitalMap =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteDigitalMapRequest(id));
    axios
      .delete(`/api/digitalmaps/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteDigitalMapSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteDigitalMapFailure(error)));
  };

const createDigitalMapRequest = (newDigitalMap: DigitalMapParams) => ({
  type: CREATE_DIGITAL_MAP_REQUEST,
  newDigitalMap,
});

const createDigitalMapSuccess = (response: any) => ({
  type: CREATE_DIGITAL_MAP_SUCCESS,
  response,
});

const createDigitalMapFailure = (error: any) => ({
  type: CREATE_DIGITAL_MAP_FAILURE,
  error,
});

export const createDigitalMap =
  (newDigitalMap: DigitalMapParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createDigitalMapRequest(newDigitalMap));
    return axios
      .post('/api/digitalmaps', newDigitalMap, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createDigitalMapSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createDigitalMapFailure(err.message));
      });
  };

const updateDigitalMapRequest = (id: string, patch: DigitalMapParams) => ({
  type: UPDATE_DIGITAL_MAP_REQUEST,
  id,
  patch,
});

const updateDigitalMapSuccess = (response: any) => ({
  type: UPDATE_DIGITAL_MAP_SUCCESS,
  response,
});

const updateDigitalMapFailure = (error: any) => ({
  type: UPDATE_DIGITAL_MAP_FAILURE,
  error,
});

export const updateDigitalMap =
  (id: string, patch: DigitalMapParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateDigitalMapRequest(id, patch));
    return axios
      .patch(`/api/digitalmaps/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateDigitalMapSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateDigitalMapFailure(err.message));
      });
  };
