import * as React from 'react';

import { Heading } from './Heading';
import { Section } from './util';
import { BaseSection } from './BaseSection';
import { Button } from './Button';

type Props = {
  data: Section;
};

export const ImageGallery = ({ data }: Props) => {
  const imageGallery = data.imageGallery;

  if (!imageGallery) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <>
        <Heading
          title={imageGallery?.title}
          description={imageGallery?.description}
          titleFontFamily={imageGallery?.titleStyle?.fontFamily}
          titleColor={imageGallery?.titleStyle?.color}
          descriptionFontFamily={imageGallery?.descriptionStyle?.fontFamily}
          descriptionColor={imageGallery?.descriptionStyle?.color}
        />
        {imageGallery.style === 'ONE_PANE' && (
          <tr>
            <td>
              <table style={{ width: '100%' }}>
                <tbody>
                  {imageGallery.items.map((item, index) => (
                    <tr key={index}>
                      <img
                        src={item.imageUrl}
                        style={{
                          borderRadius: '1em',
                          width: '100%',
                          marginBottom: '1em',
                        }}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {/* Square aspect ratio for 2 pane
        600px - 20px - 20px = 560px (width of a row)
        560px - 20px = 520px (width for 2 pane)
        520px / 2 = 260px (width for each image in pane) */}
        {imageGallery.style === 'TWO_PANE' && (
          <tr>
            <td>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                  {Array.from({
                    length: Math.ceil(imageGallery.items.length / 2),
                  }).map((_, rowIndex) => (
                    <>
                      <tr key={rowIndex * 2}>
                        {imageGallery.items
                          .slice(rowIndex * 2, rowIndex * 2 + 2)
                          .map((item, index) => (
                            <>
                              <td
                                key={index}
                                style={{ width: '260px', padding: '0' }}
                              >
                                <div
                                  style={{
                                    height: 'auto',
                                    width: '100%',
                                    borderRadius: '1em',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    src={item.imageUrl}
                                    style={{
                                      top: '0',
                                      left: '0',
                                      height: '260px',
                                      width: '100%',
                                      objectFit: 'cover',
                                      objectPosition: 'center',
                                      display: 'block',
                                    }}
                                  />
                                </div>
                              </td>
                              {index === 0 && (
                                <td style={{ width: '20px', padding: '0' }} />
                              )}
                            </>
                          ))}
                        {imageGallery.items.length % 2 !== 0 &&
                          rowIndex ===
                            Math.ceil(imageGallery.items.length / 2) - 1 && (
                            <td style={{ width: '260px' }}>&nbsp;</td>
                          )}
                      </tr>
                      {rowIndex <
                        Math.ceil(imageGallery.items.length / 2) - 1 && (
                        <tr key={rowIndex * 2 + 1}>
                          <td colSpan={3} style={{ height: '20px' }}>
                            &nbsp;
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {/* Square aspect ratio for 3 pane
        600px - 20px - 20px = 560px (width of a row)
        560px - 20px - 20px = 500px (width for 3 pane)
        500px / 3 = 166.6667px (width for each image in pane) */}
        {imageGallery.style === 'THREE_PANE' && (
          <tr>
            <td>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                  {Array.from({
                    length: Math.ceil(imageGallery.items.length / 3),
                  }).map((_, rowIndex) => (
                    <>
                      <tr key={rowIndex * 2}>
                        {imageGallery.items
                          .slice(rowIndex * 3, rowIndex * 3 + 3)
                          .map((item, index) => (
                            <>
                              <td
                                key={index}
                                style={{ width: '166.6667px', padding: '0' }}
                              >
                                <div
                                  style={{
                                    height: 'auto',
                                    width: '100%',
                                    borderRadius: '1em',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    src={item.imageUrl}
                                    style={{
                                      top: '0',
                                      left: '0',
                                      height: '166.6667px',
                                      width: '100%',
                                      objectFit: 'cover',
                                      objectPosition: 'center',
                                      display: 'block',
                                    }}
                                  />
                                </div>
                              </td>
                              {index < 2 && (
                                <td style={{ width: '20px', padding: '0' }} />
                              )}
                            </>
                          ))}
                        {imageGallery.items.length % 3 !== 0 &&
                          rowIndex ===
                            Math.ceil(imageGallery.items.length / 3) - 1 && (
                            <td
                              colSpan={3 - (imageGallery.items.length % 3) * 2}
                              style={{ width: '166.6667px' }}
                            >
                              &nbsp;
                            </td>
                          )}
                      </tr>
                      {rowIndex <
                        Math.ceil(imageGallery.items.length / 3) - 1 && (
                        <tr key={rowIndex * 2 + 1}>
                          <td colSpan={5} style={{ height: '20px' }}>
                            &nbsp;
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        <Button
          buttonUrl={imageGallery.buttonUrl}
          buttonText={imageGallery.buttonText}
        />
      </>
    </BaseSection>
  );
};
