import {
  SET_LAST_EXECUTED_REDEEMED_RECORD_SEARCH_CONDITION,
  SET_REDEEMED_RECORD_ROW_COUNT,
  SET_REDEEMED_RECORD_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchRedeemedRecordsRequest } from 'client/libraries/util/searchRedeemedRecords';

export const setLastExecutedRedeemedRecordSearchCondition = (
  searchFilters: SearchRedeemedRecordsRequest
) => ({
  type: SET_LAST_EXECUTED_REDEEMED_RECORD_SEARCH_CONDITION,
  searchFilters,
});

export const setRedeemedRecordRowCount = (rowCount: number) => ({
  type: SET_REDEEMED_RECORD_ROW_COUNT,
  rowCount,
});

export const setRedeemedRecordCurrentPage = (currentPage: number) => ({
  type: SET_REDEEMED_RECORD_CURRENT_PAGE,
  currentPage,
});
