import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import {
  manifestCustomViewsSelector,
  manifestProductGroupsSelector,
} from 'client/reducers/manifestSettings';
import { DeleteCustomViewModal } from 'client/pages/v3/Manifest/ManifestCustomize/Modal/DeleteCustomViewModal';
import { EditCustomViewModal } from 'client/pages/v3/Manifest/ManifestCustomize/Modal/EditCustomViewModal';
import {
  getColumnHeaderText,
  getDefaultManifestViews,
} from 'client/reducers/manifestDefaults';
import type { ReduxState } from 'client/reducers';
import 'react-table/react-table.css';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';

type Props = {
  isActive: boolean;
};

const isCustomView = (viewName: string) =>
  viewName !== 'DRIVER' && viewName !== 'GUIDE' && viewName !== 'RECEPTIONIST';

export const ManifestViewList = ({ isActive }: Props) => {
  const { t } = useTranslation();

  const customViews = useSelector((state: ReduxState) =>
    manifestCustomViewsSelector(state)
  );
  const productGroups = useSelector((state: ReduxState) =>
    manifestProductGroupsSelector(state)
  );

  const manifestViewTypes = [...customViews, ...getDefaultManifestViews()];

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [existingView, setExistingView] = useState<any | null>(null);
  const [customViewToDelete, setCustomViewToDelete] = useState<any | null>(
    null
  );

  const columns = [
    {
      Header: t('Name'),
      accessor: 'key',
      Cell: (cellInfo: any) => t(cellInfo.value),
      width: '280px',
    },
    {
      Header: t('Sort'),
      accessor: (row: any) =>
        (row?.order_by || [])
          .map((col: any) => getColumnHeaderText(col, t))
          .join(','),
      width: '200px',
    },
    {
      Header: t('Columns'),
      accessor: (row: any) =>
        row.columns.map((col: any) => getColumnHeaderText(col, t)).join(','),
      width: 'longx2',
    },
    {
      Header: t('Action'),
      accessor: 'key',
      Cell: (cellInfo: any) =>
        isCustomView(cellInfo.value) ? (
          <div className={styles['p-manifests__customize__flex']}>
            <Button
              size="icon"
              color="white"
              iconBeforeText={
                <i className="c-icon-outline-general-edit-05"></i>
              }
              onClick={() => {
                setExistingView(cellInfo.original);
                setShowEditModal(true);
              }}
            />
            {!productGroups.some(
              (group: any) => group.view_type_key === cellInfo.value
            ) ? (
              <Button
                size="icon"
                color="white"
                onClick={() => {
                  setCustomViewToDelete(cellInfo.original);
                  setShowDeleteModal(true);
                }}
                iconBeforeText={
                  <i className="c-icon-outline-general-trash-03"></i>
                }
              />
            ) : null}
          </div>
        ) : null,
      sub: true,
      width: '128px',
    },
  ];

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Edit Manifest Views')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          <Button
            text={t('Create New View')}
            onClick={() => {
              setExistingView(null);
              setShowEditModal(true);
            }}
          />
        </div>
      </div>

      <>
        <EditCustomViewModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          existingView={existingView}
        />
        <DeleteCustomViewModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          customViewToDelete={customViewToDelete}
        />

        <ManifestCustomizeCustomTable
          itemName={t('Manifest view')}
          items={_.sortBy(manifestViewTypes, (p: any) => p.key)}
          columns={columns as any}
        />
      </>
    </div>
  );
};
