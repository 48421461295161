// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

import 'react-table/react-table.css';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { EditAlternateNameModal } from 'client/pages/ManifestCustomize/AlternateName/EditAlternateNameModal';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import { manifestAlternateNameSettingsSelector } from 'client/reducers/manifestSettings';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { summariesWithBookmarksSelector } from 'client/reducers/products';

type Props = {
  isActive?: boolean,
};

export const AlternateName = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alternateNameSettings = useSelector(
    manifestAlternateNameSettingsSelector
  );
  const products = useSelector(summariesWithBookmarksSelector);

  const agents = useSelector((state) => state.organizations.contracted);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  const columns = [
    {
      Header: t('Default name'),
      accessor: 'default',
      width: 'long',
    },
    {
      Header: t('Abbreviation'),
      accessor: 'alternate',
    },
  ];

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Output Abbreviations')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            <EditAlternateNameModal
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['small'],
                    baseStyles['flex'],
                    baseStyles['yellow']
                  )}
                >
                  {t('Edit')}
                </a>
              }
            />
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <h4>{t('Product name')}</h4>
          <ManifestCustomizeCustomTable
            items={(alternateNameSettings.id_alternate_names || []).map((a) => {
              return {
                default:
                  products.find((p) => p.id === a.id)?.product_name || '',
                alternate: a.name || '',
              };
            })}
            columns={columns}
          />
        </div>
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <h4>{t('Location name')}</h4>
          <ManifestCustomizeCustomTable
            items={(alternateNameSettings.alternate_names || []).map((a) => {
              return {
                default: a.key || '',
                alternate: a.name || '',
              };
            })}
            columns={columns}
          />
        </div>
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <h4>{t('Agent Name')}</h4>
          <ManifestCustomizeCustomTable
            items={(alternateNameSettings.agent_id_alternate_names || []).map(
              (a) => {
                return {
                  default: agents.find((p) => p.id === a.id)?.name || '',
                  alternate: a.name || '',
                };
              }
            )}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};
