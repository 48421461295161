import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import * as Swagger from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Button } from 'client/components/v3/Common/Button';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { deleteRestaurant, fetchRestaurants } from 'client/actions/restaurants';
import { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';

import styles from './RestaurantList.module.css';

export const Edit = ({ restaurant }: { restaurant: Swagger.Restaurant }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`/mobileorders/restaurants/${restaurant.id}`}
      className={styles['edit-link']}
    >
      <div className={styles['p-availability__select__menu__item']}>
        <i className="c-icon-outline-general-edit-05"></i>
        <p>{t('Edit')}</p>
      </div>
    </Link>
  );
};

export const Delete = ({ restaurant }: { restaurant: Swagger.Restaurant }) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div>
      <div
        className={clsx(
          styles['p-availability__select__menu__item'],
          styles['red']
        )}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="c-icon-outline-general-trash-03"></i>
        <p>{t('Delete')}</p>
      </div>
      {showDeleteModal && (
        <Modal
          onClose={() => setShowDeleteModal(false)}
          insertAtRoot
          title={t('Delete Restaurant')}
          open={true}
          rightActionChildren={
            <>
              <Button
                text={t('Cancel')}
                size="md"
                color="white"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              />
              <Button
                text={t('Delete')}
                onClick={async () => {
                  await dispatch(deleteRestaurant(restaurant?.id ?? ''));
                  setShowDeleteModal(false);
                }}
              />
            </>
          }
        >
          <div>
            {t('Are you sure you want to delete "{{name}}"?', {
              name: restaurant.name,
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

const useColumns = (): ColumnType<Swagger.Restaurant>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (restaurant: Swagger.Restaurant) => restaurant.name ?? '',
    },
    {
      Header: '',
      id: 'actions',
      accessor: (restaurant: Swagger.Restaurant) => (
        <div className={styles['actions']}>
          <Edit restaurant={restaurant} />
          <Delete restaurant={restaurant} />
        </div>
      ),
    },
  ];
};

export const RestaurantList = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const dispatch = useDispatch();
  const loading = useSelector((state: ReduxState) => state.restaurants.loading);
  const restaurants = useSelector((state: ReduxState) => state.restaurants.all);

  React.useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  return (
    <V3Page>
      <PageHeader title={t('Restaurants')} />
      <PageContent>
        <PagedGenericTable
          loading={loading}
          columns={columns}
          allItems={restaurants}
          headerBar={
            <Box display="flex" justifyContent="flex-end" mb={2} width="100%">
              <Link to="/mobileorders/restaurants/new">
                <Button
                  text={t('Add Restaurant')}
                  iconBeforeText={<i className="c-icon-outline-general-plus" />}
                />
              </Link>
            </Box>
          }
        />
      </PageContent>
    </V3Page>
  );
};
