// @flow

import type {
  EmailCustomizationEmailType,
  SourceLanguage,
} from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';

// Per-email messages use custom form values because messages are edited individually
// and translations are edited all at once.

export type FormValues = {
  message: string,

  translation: { [lang: string]: string },
};

export const getDefaultPerEmailMessage = (
  supplierName: string,
  emailType: EmailCustomizationEmailType,
  t: TranslateFuncType,
  language: SourceLanguage
): string => {
  switch (emailType) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      return t(
        'Reservation request received.\n{{supplierName}} will review availability and you will be notified if your reservation is confirmed. Please be aware that your reservation may be declined if the product is already fully booked or if {{supplierName}} is not able to operate the product on your selected date for any other reason. No payment will be collected unless your booking is confirmed. Please see reservation details below.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_CONFIRMED':
      return t(
        'Reservation is confirmed. Please see reservation details below.\nFor any questions or concerns, request for change or cancellation, please contact {{supplierName}} directly.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_REJECTED':
      return t(
        'Reservation was declined by {{supplierName}}.\nPlease see details below.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_WAITLISTED':
      return t(
        'Reservation has been waitlisted by {{supplierName}}.\nPlease see reservation details below.\nYou will be notified when {{supplierName}} updates your reservation status.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_CHANGED':
      return t(
        'Reservation is changed and confirmed. Please see changed reservation details below.\nFor any questions or concerns, request for change or cancellation, please contact {{supplierName}} directly.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      return t(
        'Reservation was canceled by {{supplierName}}.\nPlease contact {{supplierName}} directly for information about why your reservation was canceled.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_REMINDER':
      return t(
        "It's the day before your reservation!\nPlease review the following details and arrive at the pickup/checkin location at the specified time.\nFor inquiries on the day of participation, please use the local contact information to contact the organizer directly.",
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      return t(
        'Reservation has been updated by {{supplierName}}.\nPlease see reservation details below.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED':
      return t(
        'Reservation is confirmed. Please see reservation details below. You are required to complete payment within 48 hours. Click "Pay by credit card" button to proceed and complete payment.',
        {
          lng: uppercaseIsoToLowercaseIso[language],
        }
      );
    case 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE':
      return t(
        'Your payment has been completed.\nFor any questions, concerns, or requests for change or cancellation, please contact {{supplierName}} directly.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_CONFIRMED_LOTTERY':
      return t(
        'The lottery applied reservation was won, please check the details of the winning reservation below.\nIf you have any questions or concerns, or would like to change or cancel your reservation, please contact {{supplierName}} directly.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_RECEIPT':
      return t(
        'Please see the attached PDF receipt for your reservation. If you have any questions or concerns, please contact {{supplierName}} directly.',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    case 'RESERVATION_REJECTED_LOTTERY':
      return t(
        'After conducting a thorough drawing for the lucky draw, we regret to inform you that you were not selected. \nFor reasons and further details, please contact {{supplierName}} directly or check for comments from {{supplierName}}',
        { supplierName, lng: uppercaseIsoToLowercaseIso[language] }
      );
    default:
      return '';
  }
};

export const getDefaultEmailSubject = (
  emailType: EmailCustomizationEmailType,
  t: TranslateFuncType,
  language: SourceLanguage
): string => {
  switch (emailType) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      return t('reservation not yet confirmed', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CONFIRMED':
      return t('reservation confirmed', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_REJECTED':
      return t('reservation rejected', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_WAITLISTED':
      return t('reservation waitlisted', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CHANGED':
      return t('reservation changed', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      return t('reservation canceled by supplier', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_REMINDER':
      return t("It's the day before your reservation!", {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      return t('reservation updated by supplier', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED':
      return t('reservation confirmed (Payment required)', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE':
      return t('Your payment has been completed', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_CONFIRMED_LOTTERY':
      return t('reservation confirmed (Lottery mode)', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_RECEIPT':
      return t('sending receipt', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    case 'RESERVATION_REJECTED_LOTTERY':
      return t('result of your lucky draw', {
        lng: uppercaseIsoToLowercaseIso[language],
      });
    default:
      return '';
  }
};
