import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import { SurveySubmission } from 'shared/models/swagger';

import { SubmittedSurveyAnswersModal } from './SubmittedSurveyAnswersModal';

interface Props {
  submission: SurveySubmission;
}

const AnswersCell = ({ submission }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <a
        className={clsx(baseStyles['base-link'])}
        onClick={() => setShowModal(true)}
      >
        {t('Show')}
      </a>
      {showModal && (
        <SubmittedSurveyAnswersModal
          surveySubmissionId={submission.id ?? ''}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

export const useSurveyColumns = (): ColumnType<SurveySubmission>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const productSummaries = useSelector(summariesSelector);

  return [
    {
      Header: t('Reservation'),
      width: 'short',
      Cell: (cellInfo) =>
        cellInfo.original.reservation_id ? (
          <a
            href={`/reservations/${cellInfo.original.reservation_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Detail')}
          >
            <img src={reservationDetailIcon} />
          </a>
        ) : (
          <></>
        ),
      id: 'reservationDetails',
    },
    {
      Header: t('Responses'),
      width: 'short',
      Cell: (cellInfo) => <AnswersCell submission={cellInfo.original} />,
      id: 'reservationDetails',
    },
    {
      Header: t('Submitted'),
      width: 'middle',
      accessor: (row) =>
        moment(row.submitted_date_time_utc).locale(locale).format('lll'),
      id: 'updated',
    },
    {
      Header: t('Survey'),
      width: 'long',
      accessor: (row) => row.survey_template_name || '',
      id: 'survey',
    },
    {
      Header: t('Participation'),
      accessor: 'start_date_time_utc',
      Cell: (cellInfo) =>
        cellInfo.original.reservation_id ? (
          <Link
            to={`/reservations/${cellInfo.original.reservation_id}?t=survey#detail`}
            target="_blank"
          >
            {moment
              .tz(
                cellInfo.original.start_date_time_utc,
                cellInfo.original.start_timezone ?? ''
              )
              .locale(locale)
              .format('lll')}
          </Link>
        ) : (
          <></>
        ),
      id: 'participation',
    },
    {
      Header: t('Product'),
      accessor: 'product_name',
      Cell: (cellInfo) =>
        productSummaries.find((p) => p.id == cellInfo.original.product_id)
          ?.internal_product_name ?? cellInfo.original.product_name,
      id: 'product',
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.given_name || row.family_name
          ? `${row.given_name ?? ''} ${row.family_name ?? ''}`
          : '-',
      id: 'customer',
    },
  ];
};
