import { combineReducers } from 'redux';

import {
  FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';
import {
  CustomerEventReportDataSet,
  CustomerEventOnSiteReportDataSet,
  GetCustomerEventsReportDataResponse,
  GetCustomerEventsOnSiteReportDataResponse,
} from 'shared/models/swagger';

const reportData = (state: CustomerEventReportDataSet[] = [], action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetCustomerEventsReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const onSiteReportData = (
  state: CustomerEventOnSiteReportDataSet[] = [],
  action: any
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetCustomerEventsOnSiteReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST:
    case FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST:
      return true;
    case FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS:
    case FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE:
    case FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS:
    case FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

interface State {
  reportData: ReturnType<typeof reportData>;
  onSiteReportData: ReturnType<typeof onSiteReportData>;
  loading: ReturnType<typeof loading>;
}

const reducer = combineReducers({
  reportData,
  onSiteReportData,
  loading,
});
export const customerEvents = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
