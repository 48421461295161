import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import {
  FocusableInput,
  FormError,
  Input,
  ToggleButton,
} from 'client/components/Form';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';

import { PerChannelAllotment } from './ReservationParamsFormValues';

type Props = {
  name: string;
};
const defaultDedicatedAllotment: PerChannelAllotment = {
  channel: 'DIRECT_ALL',
  agentId: null,
  allotmentSlots: 10,
};
export const DedicatedAllotmentEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const editingProduct = React.useContext(EditingProductContext);
  const form = useForm();
  let channelOptions = [
    {
      value: 'DIRECT_ALL',
      text: t('Direct'),
    },
  ];

  const uniqueChannelsRequired = (value?: PerChannelAllotment[]): string => {
    if (value && value.length > 0) {
      const uniqueValues = _.uniqBy(value, (elem) =>
        elem.channel === 'DIRECT_ALL' ? 'DIRECT_ALL' : elem.agentId
      );

      if (uniqueValues.length !== value.length) {
        return t('Duplicate channels not allowed');
      }
    }

    return '';
  };

  if (editingProduct?.agents != null && editingProduct.agents.length > 0) {
    channelOptions = [
      {
        value: 'AGENT',
        text: t('Agent'),
      },
      ...channelOptions,
    ];
  }

  const agentOptions = (editingProduct?.agents ?? []).map((agent) => ({
    value: agent.id,
    text: agent.name,
  }));
  return (
    <>
      <FieldArray<PerChannelAllotment>
        name={name}
        validate={uniqueChannelsRequired as any}
      >
        {({ fields, meta: { error } }) => (
          <>
            <div>
              <ToggleButton
                label={t('Per-Channel Capacity Settings')}
                checked={Boolean(fields.length)}
                onChange={() => {
                  if (fields.length) {
                    const len = fields.length;

                    for (let i = 0; i < len; i++) {
                      fields.pop();
                    }
                  } else {
                    fields.insert(0, defaultDedicatedAllotment);
                  }
                }}
              />
            </div>
            {Boolean(fields.length) && (
              <div className={styles['c-tableChild']}>
                <ul>
                  <li className={baseStyles['base-t-240']}>{t('Channel')}</li>
                  <li className={baseStyles['base-t-240']}>
                    {t('Agent Name')}
                  </li>
                  <li className={baseStyles['base-t-96']}>
                    {t('Inventory Count')}
                  </li>
                  <li className={baseStyles['base-t-144']} />
                </ul>
                {fields.length === 0 && (
                  <AddIcon
                    onClick={() =>
                      (fields as any).insertAt(0, defaultDedicatedAllotment)
                    }
                  />
                )}
                {fields.map((fieldName, idx) => (
                  <ul key={fieldName}>
                    <li>
                      <Field name={`${fieldName}.channel`}>
                        {({ input }) => (
                          <select
                            className={baseStyles['base-form-select']}
                            value={input.value}
                            onChange={(e) => {
                              if (e.target.value === 'DIRECT_ALL') {
                                form.change(`${fieldName}.agentId`, null);
                              } else if (e.target.value) {
                                form.change(
                                  `${fieldName}.agentId`,
                                  agentOptions[0].value
                                );
                              }

                              input.onChange(e.target.value);
                            }}
                          >
                            {channelOptions.map((channelOption, idx) => (
                              <option key={idx} value={channelOption.value}>
                                {channelOption.text}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </li>
                    <li>
                      {fields.value[idx]?.channel === 'AGENT' && (
                        <Field name={`${fieldName}.agentId`}>
                          {({ input }) => (
                            <select
                              className={baseStyles['base-form-select']}
                              {...input}
                            >
                              {agentOptions.map((agentOption, idx) => (
                                <option key={idx} value={agentOption.value}>
                                  {agentOption.text}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>
                      )}
                    </li>
                    <li>
                      <Field name={`${fieldName}.allotmentSlots`}>
                        {({ input }) => (
                          <Input
                            value={input.value}
                            onChange={(e, { value }) => {
                              if (!isInteger(value)) {
                                return;
                              }

                              input.onChange(value);
                            }}
                            type="text"
                          />
                        )}
                      </Field>
                    </li>
                    <li className={baseStyles['base-flex']}>
                      <AddIcon
                        onClick={() =>
                          (fields as any).insertAt(
                            idx + 1,
                            defaultDedicatedAllotment
                          )
                        }
                      />
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </li>
                  </ul>
                ))}
              </div>
            )}
            {error && typeof error === 'string' && (
              <>
                <FocusableInput name={name} />
                <FormError error={error} />
              </>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};
