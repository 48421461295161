import { toSnakeCase } from 'client/libraries/util/coreutil';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { AgentReference } from 'client/pages/ProductEditor/types';
import type { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

export type FormField = {
  key: string;
  fieldType: 'PER_BOOKING' | 'PER_PARTICIPANT';
  prompt: string;
  required: 'WHEN_BOOKING' | 'BEFORE_PARTICIPATING' | 'OPTIONAL';
  choices: string[];
  responseConstraint: 'CHOICES' | 'FORMAT' | 'NO_RESTRICTION';
  inputElement?: 'TEXT_BOX' | 'TEXT_AREA';
  format: string;
  referencesInAgentSystems?: AgentReference[];
  referenceInSupplierSystem?: string;
};
export type ReservationFormFormValues = {
  formFields: FormField[];
  showHotelInformationInputForCheckinCheckout: boolean;
  showAgentNotesInputDuringGuestCheckout: boolean;
};
export const convertReservationFormFormValuesToProductPatch = (
  values: ReservationFormFormValues,
  t: TranslateFuncType,
  sourceLanguageLowercaseIso: string
): Swagger.Product$Patch => {
  let reservationFormFields: Swagger.Field[] = [
    ...(values.formFields ?? [])
      .filter((formField: FormField) => {
        return formField.key !== 'preferred_language_iso2';
      })
      .map((formField: FormField): Swagger.Field => {
        let key =
          formField.key === 'custom'
            ? convertPromptToKey(formField.prompt)
            : formField.key;

        if (formField.referenceInSupplierSystem) {
          // Retain the original key if this is a 3rd-party product
          key = formField.referenceInSupplierSystem;
        }

        return {
          key,
          type: formField.fieldType,
          prompt: formField.prompt,
          format: formField.format,
          choices: formField.choices,
          required: formField.required,
          agent_references: (formField.referencesInAgentSystems ?? []).map(
            (ref) => ({
              agent_id: ref.agentId,
              agent_reference: ref.reference,
            })
          ),
          input_element: formField.inputElement,
        };
      }),
  ];
  const representativeNameFormField = values.formFields.find(
    (formField: FormField) => {
      return formField.key === 'representative_name';
    }
  );

  if (representativeNameFormField) {
    if (
      representativeNameFormField.format === 'alpha-name' ||
      representativeNameFormField.format === 'alpha-kana-name'
    ) {
      reservationFormFields = [
        ...reservationFormFields,
        ...([
          {
            key: 'family_name',
            type: 'PER_BOOKING',
            prompt: t('Family Name', {
              lng: sourceLanguageLowercaseIso,
            }),
            format: 'alpha-name',
            choices: [],
            required: 'WHEN_BOOKING',
          },
          {
            key: 'given_name',
            type: 'PER_BOOKING',
            prompt: t('Given Name', {
              lng: sourceLanguageLowercaseIso,
            }),
            format: 'alpha-name',
            choices: [],
            required: 'WHEN_BOOKING',
          },
        ] as Swagger.Field[]),
      ];
    }

    if (
      representativeNameFormField.format === 'kana-name' ||
      representativeNameFormField.format === 'alpha-kana-name'
    ) {
      reservationFormFields = [
        ...reservationFormFields,
        ...([
          {
            key: 'kana_family_name',
            type: 'PER_BOOKING',
            prompt: t('Family Name(Kana)', {
              lng: sourceLanguageLowercaseIso,
            }),
            format: 'kana-name',
            choices: [],
            required: 'WHEN_BOOKING',
          },
          {
            key: 'kana_given_name',
            type: 'PER_BOOKING',
            prompt: t('Given Name(Kana)', {
              lng: sourceLanguageLowercaseIso,
            }),
            format: 'kana-name',
            choices: [],
            required: 'WHEN_BOOKING',
          },
        ] as Swagger.Field[]),
      ];
    }
  }

  const hotelInformationField = reservationFormFields.find(
    (field) => field.key === 'hotel_information'
  );

  if (
    values.showHotelInformationInputForCheckinCheckout &&
    hotelInformationField
  ) {
    // 'hotel_information_checkin_checkout' is handled as follows:
    // 1) During initialization of form values, presence/absence of a 'hotel_information_checkin_checkout' item is used
    //    to set the value of the boolean 'showHotelInformationInputForCheckinCheckout'
    // 2) In the reservation form editor, the 'hotel_information' form item has a toggle switch to set the value of
    //    'showHotelInformationInputForCheckinCheckout'.
    // 3) When saving the product, if showHotelInformationInputForCheckinCheckout = true, a 'hotel_information_checkin_checkout'
    //    item is added to 'reservation_form_fields' in the product patch.
    //
    // This disassembly + reassembly of the 'hotel_information_checkin_checkout' field is done to simplify the editing UX.
    reservationFormFields.push({
      ...hotelInformationField,
      key: 'hotel_information_checkin_checkout',
      prompt: t('Hotel Information (Checkin/Checkout)'),
    });
  }

  return {
    reservation_form_fields: reservationFormFields,
    hide_agent_notes_input_during_guest_checkout: {
      value: !values.showAgentNotesInputDuringGuestCheckout,
    },
  };
};
const builtinKeys = [
  'full_name',
  'kanji_full_name',
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'title',
  'email',
  'phone',
  'international_phone',
  'emergency_contact_name',
  'emergency_contact_phone',
  'height',
  'weight',
  'date_of_birth',
  'age',
  'gender',
  'home_address',
  'consent_form',
  'hotel_tbd_form',
];

const convertPromptToKey = (prompt: string): string => {
  return toSnakeCase(prompt);
};

export const getInitialReservationFormFormValues = (
  product: Swagger.Product | null,
  defaultCurrency: string,
  defaultTimezone: string,
  t: TranslateFuncType
): ReservationFormFormValues => {
  const defaultLanguage = 'JA_JP';
  const sourceLanguageUppercaseIso =
    product?.source_language ?? defaultLanguage;
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];
  return {
    showHotelInformationInputForCheckinCheckout: Boolean(
      product?.reservation_form_fields?.some(
        (field) => field.key === 'hotel_information_checkin_checkout'
      )
    ),
    showAgentNotesInputDuringGuestCheckout: Boolean(
      !product?.hide_agent_notes_input_during_guest_checkout?.value
    ),
    formFields: [
      ...([
        {
          key: 'preferred_language_iso2',
          fieldType: 'PER_BOOKING',
          prompt: t('Booking Language', {
            lng: sourceLanguageLowercaseIso,
          }),
          format: '',
          choices: ['ja-JP', 'en-US'],
          required: 'WHEN_BOOKING',
          responseConstraint: 'CHOICES',
        },
        {
          key: 'email',
          fieldType: 'PER_BOOKING',
          prompt: t('Email Address', {
            lng: sourceLanguageLowercaseIso,
          }),
          format: 'email',
          choices: [],
          required: 'WHEN_BOOKING',
          responseConstraint: 'FORMAT',
        },
        {
          key: 'representative_name',
          fieldType: 'PER_BOOKING',
          prompt: t('Representative Name', {
            lng: sourceLanguageLowercaseIso,
          }),
          format:
            product?.reservation_form_fields?.find((formField) => {
              return formField.key === 'representative_name';
            })?.format ??
            (defaultCurrency === 'JPY' &&
            defaultTimezone === 'Asia/Tokyo' &&
            product?.source_language === 'JA_JP'
              ? 'kana-name'
              : 'alpha-name'),
          choices: [],
          required: 'WHEN_BOOKING',
          responseConstraint: 'FORMAT',
        },
      ] as FormField[]),
      ...((product?.reservation_form_fields
        ?.filter(
          (formField) =>
            formField.key !== 'given_name' &&
            formField.key !== 'family_name' &&
            formField.key !== 'kana_given_name' &&
            formField.key !== 'kana_family_name' &&
            formField.key !== 'preferred_language_iso2' &&
            formField.key !== 'email' &&
            formField.key !== 'representative_name' &&
            formField.key !== 'hotel_information_checkin_checkout'
        )
        .map((formField) => ({
          key:
            formField.key && builtinKeys.includes(formField.key)
              ? formField.key
              : 'custom',
          fieldType: formField.type,
          prompt: formField.prompt ?? '',
          format: formField.format ?? '',
          choices: formField.choices ?? [],
          required: formField.required ?? 'OPTIONAL',
          responseConstraint: formField.format
            ? 'FORMAT'
            : formField.choices && formField.choices.length > 0
            ? 'CHOICES'
            : 'NO_RESTRICTION',
          referencesInAgentSystems: (formField.agent_references ?? []).map(
            (ref) => ({
              agentId: ref.agent_id ?? '',
              reference: ref.agent_reference ?? '',
            })
          ),
          inputElement: getInputElement(formField),
          referenceInSupplierSystem:
            product?.gateway_name && product?.gateway_name !== 'nutmeg'
              ? formField.key
              : undefined,
        })) ?? []) as FormField[]),
    ],
  };
};

const getInputElement = (
  formField: Swagger.Field
): 'TEXT_BOX' | 'TEXT_AREA' | typeof undefined => {
  if (formField.input_element) {
    return formField.input_element;
  }

  if (!formField.format && (formField.choices ?? []).length === 0) {
    // Default to 'TEXT_BOX' if response constraint is 'NO_RESTRICTION' and 'input_element' is uninitialized.
    return 'TEXT_BOX';
  }

  return undefined;
};
