import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Select } from 'client/components/Form';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { SourceLanguage } from 'shared/models/swagger';

export interface Props {
  contentLanguage: SourceLanguage | null;
  onChange: (contentLanguage: SourceLanguage) => void;
}

export const ContentLanguageSelector = ({
  contentLanguage,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));

  const [supportLanguages, setSupportLanguages] = React.useState<
    SourceLanguage[]
  >([]);

  const selectableContentLanguageOptions = languageOptions.filter((option) => {
    return supportLanguages.includes(option.value);
  });

  const activeOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    setSupportLanguages(
      getBookingWidgetPmpSupportLanguages(activeOrganization)
    );
  }, [activeOrganization]);

  React.useEffect(() => {
    if (selectableContentLanguageOptions.length !== 0) {
      const defaultContentLanguage = selectableContentLanguageOptions[0].value;
      if (contentLanguage === null) {
        onChange(defaultContentLanguage);
      }
    }
  }, [selectableContentLanguageOptions]);

  return (
    <Select
      label={t('Content Language')}
      width={176}
      options={selectableContentLanguageOptions}
      onChange={(_, { value }) => {
        onChange(value as SourceLanguage);
      }}
      value={contentLanguage as string}
    />
  );
};
