import * as React from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import { getHTTPRequestHeaders } from 'client/actions';
import { ReduxState } from 'client/reducers';

import styles from './FileUploadInput.module.css';

interface FileUpload {
  url: string;
  name: string;
}

type Props = {
  value: FileUpload[];
  onChange: (newValue: FileUpload[]) => void;
  maxFileCount?: number;
  error?: string;
};
export const FileUploadInput = ({ value, onChange, maxFileCount }: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const [uploadError, setUploadError] = React.useState<string>('');
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
  });

  const headers = useSelector((state: ReduxState) =>
    getHTTPRequestHeaders(state)
  );

  const handleAddFileClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const uploadFiles = async (files: File[]) => {
    const newFiles: FileUpload[] = [];
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';

    for (const file of files) {
      try {
        const imageUploadUrlResponse = await axios.get(
          '/api/imageuploadurls/new',
          {
            params: {
              mime_type: file.type,
              resource_id: organizationId,
              resource_category: 'RESERVATION',
            },
            headers,
          }
        );

        await axios.put(imageUploadUrlResponse.data.upload_url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        newFiles.push({
          url: imageUploadUrlResponse.data.public_access_url,
          name: file.name,
        });
      } catch (err) {
        console.log(err);
      }
    }

    onChange([...value, ...newFiles]);

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      {value.length > 0 && (
        <Box ml={-2}>
          {value.map((uploadedFile) => (
            <Box ml={2} key={uploadedFile.url} display="flex">
              <a
                className={styles['picture__item__link']}
                href={uploadedFile.url}
                download
                target="_blank"
                rel="noreferrer"
              >
                {uploadedFile.name}
              </a>
              <Box ml={2}>
                <a
                  className={styles['picture__item__delete']}
                  onClick={() => {
                    onChange(value.filter((f) => f.url !== uploadedFile.url));
                  }}
                >
                  {t('Remove')}
                </a>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <ul className={styles['picture']}>
        {(!maxFileCount || value.length < maxFileCount) && (
          <li className={styles['picture__item']}>
            <a
              {...getRootProps()}
              className={clsx(
                styles['picture__item__add'],
                isDragActive && styles['dragActive']
              )}
              onClick={handleAddFileClick}
            ></a>
            <input
              {...getInputProps()}
              multiple
              ref={fileInputRef}
              id="image-file-input"
              type="file"
              name="name"
              style={{
                display: 'none',
              }}
              onChange={handleFileInputChange}
            />
          </li>
        )}
      </ul>
      {uploadError && (
        <p className={baseStyles['base-form-box__err']}>{uploadError}</p>
      )}
    </>
  );
};
