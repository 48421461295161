import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Tab } from 'client/components/v3/Tab/Tab';

import { BasicSettings } from './Tabs/BasicSettings/BasicSettings';
import { TagSettings } from './Tabs/TagSettings/TagSettings';
import { AreaGroupSettings } from './Tabs/AreaGroupSettings/AreaGroupSettings';

type TabType = 'BASIC' | 'TAG' | 'AREA_GROUP';

export const CustomerSettings = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabType>('BASIC');

  const tabs = [
    { id: 'BASIC', title: t('Basic Settings') },
    { id: 'TAG', title: t('Tag Settings') },
    { id: 'AREA_GROUP', title: t('Area Group Settings') },
  ];

  return (
    <V3Page>
      <PageHeader title={t('Settings')}></PageHeader>
      <PageContent>
        <Tab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}>
          <>
            {activeTab === 'BASIC' && <BasicSettings />}
            {activeTab === 'TAG' && <TagSettings />}
            {activeTab === 'AREA_GROUP' && <AreaGroupSettings />}
          </>
        </Tab>
      </PageContent>
    </V3Page>
  );
};
