import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { fetchMemos } from 'client/actions/memos';
import { getResourceManagerAssignedResources } from 'client/libraries/util/resourceManager';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import resourceIcon from 'client/images/ic_resource.svg';
import { ManifestTableHeaderCell } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestTableHeaderCell';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Button } from 'client/components/v3/Common/Button';
import {
  Column,
  calcWidthToPx,
} from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/util';

type Props = {
  items: Record<string, any>[];
  columns: Column[];
  startTime?: string;
  totalPax?: number;
  capacity?: number;
  checkedInGuestCount: number | null;
  memoKey?: string | null;
};

export const CustomTable = ({
  items,
  columns,
  startTime,
  totalPax,
  capacity,
  checkedInGuestCount,
  memoKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [showAllReservations, setShowAllReservations] = useState(false);

  useEffect(() => {
    if (memoKey) {
      dispatch(fetchMemos(memoKey));
    }
  }, [memoKey]);

  const assignedResources = getResourceManagerAssignedResources(
    activeUserOrganization,
    items as any
  );

  const [showColumns, setShowColumns] = useState(
    [...Array(items.length)].map(() => false)
  );

  return (
    <>
      {totalPax && (
        <div className={styles['p-dashboard__body__header']}>
          {startTime && (
            <div>
              <p>
                {t('Start time: {{startTime}}', {
                  startTime: startTime,
                })}
              </p>
            </div>
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className="c-icon-outline-users-users-02"></i>
            <p style={{ marginBottom: 0 }}>
              {capacity
                ? t('Total Pax: {{paxCount}} / {{capacity}}', {
                    paxCount: totalPax,
                    capacity: capacity,
                  })
                : t('Total Pax: {{paxCount}}', {
                    paxCount: totalPax,
                  })}{' '}
              {checkedInGuestCount != null &&
                t('({{checkedInGuestCount}} / {{paxCount}} checked-in)', {
                  checkedInGuestCount,
                  paxCount: totalPax,
                })}
            </p>
            <p style={{ marginBottom: 0 }}>
              {assignedResources != null &&
                (assignedResources ?? []).length !== 0 && (
                  <>
                    <p style={{ margin: '4px' }}>
                      <img src={resourceIcon} />
                    </p>
                    {assignedResources
                      .map((pair) => {
                        return `${pair.resourceKey} x ${pair.quantity}`;
                      })
                      .join(', ')}
                  </>
                )}
            </p>
          </div>
        </div>
      )}

      <div className={styles['p-dashboard__body__table']}>
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              {columns.map((column, idx) => {
                const width = calcWidthToPx(column.width);
                return (
                  <th style={{ width: `${width}px` }} key={idx}>
                    <ManifestTableHeaderCell text={column.Header} />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {_.flatten(
              items.map((item, ridx) => {
                const rows = [];

                const subRowCount =
                  _.max(
                    columns.map(
                      (col) =>
                        (col.CellArray && col.CellArray(item)?.length) || 1
                    )
                  ) ?? 1;

                for (let subRowIdx = 0; subRowIdx < subRowCount; subRowIdx++) {
                  const row = (
                    <tr key={`${ridx}-${subRowIdx}`}>
                      {columns.map((column, idx) => {
                        if (subRowIdx > 0 && !column.CellArray) {
                          return null;
                        }

                        const rowSpan = column.CellArray ? 1 : subRowCount;
                        const width = calcWidthToPx(column.width);
                        let value: any;
                        let displayValue: any;

                        if (typeof column.accessor === 'string') {
                          value = item[column.accessor];
                        } else if (typeof column.accessor === 'function') {
                          value = column.accessor(item);
                        }

                        if (column.CellArray) {
                          const cellArray = column.CellArray(item);
                          displayValue =
                            subRowIdx < cellArray.length
                              ? cellArray[subRowIdx]
                              : '';
                        } else if (column.Cell) {
                          const cell = {
                            value: value,
                            original: item,
                          };
                          displayValue = column.Cell(cell);
                        } else {
                          displayValue = value;
                        }

                        const mql = window.matchMedia('(max-width: 1024px)');

                        return (
                          <td
                            key={idx}
                            className={clsx(
                              idx === 0 && tableStyles['c-tableSmall__spTtl'],
                              idx === 0 && tableStyles['sticky-left'],
                              6 < idx
                                ? showColumns[ridx]
                                  ? ''
                                  : mql.matches && tableStyles['hidden']
                                : ''
                            )}
                            style={{
                              width: `${width}px`,
                              wordWrap: 'break-word',
                            }}
                            data-text={column.Header}
                            rowSpan={rowSpan}
                          >
                            {displayValue}
                          </td>
                        );
                      })}
                      {/* Show 'see more' only if there are more than 5 columns */}
                      {columns.length > 5 && subRowIdx === subRowCount - 1 && (
                        <td
                          className={clsx(
                            tableStyles['c-table__detail'],
                            tableStyles['c-table__sp']
                          )}
                        >
                          <Button
                            text={
                              showColumns[ridx] ? t('Close') : t('See more')
                            }
                            onClick={() => {
                              const newData = [...showColumns];
                              newData[ridx] = !newData[ridx];
                              setShowColumns(newData);
                            }}
                            color="tertiarycolor"
                            iconAfterText={
                              showColumns[ridx] ? (
                                <i className="c-icon-solid-arrows-chevron-up"></i>
                              ) : (
                                <i className="c-icon-solid-arrows-chevron-down"></i>
                              )
                            }
                          />
                        </td>
                      )}
                    </tr>
                  );

                  // Hide reservations except the first 5 rows unless show button is clicked
                  if (ridx < 5) {
                    rows.push(row);
                  } else {
                    if (showAllReservations) {
                      rows.push(row);
                    }
                  }
                }

                return rows;
              })
            )}
          </tbody>
        </table>
      </div>
      {items.length > 5 && (
        <div className={styles['p-dashboard__body__actions']}>
          <Button
            text={
              showAllReservations
                ? t('Hide reservations')
                : t('Show all reservations')
            }
            color="secondary"
            iconAfterText={
              showAllReservations ? (
                <i className="c-icon-solid-arrows-chevron-up"></i>
              ) : (
                <i className="c-icon-solid-arrows-chevron-down"></i>
              )
            }
            useFullWidth={true}
            onClick={() => setShowAllReservations(!showAllReservations)}
          />
        </div>
      )}
    </>
  );
};
