import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps } from 'react-final-form';

import { Input } from 'client/components/Form';

import { TextStyleEditor } from './TextStyleEditor/TextStyleEditor';

export const SectionTitleEditor = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field name="title">
        {({ input }: FieldRenderProps<string>) => (
          <Input label={t('Title')} {...input} />
        )}
      </Field>
      <TextStyleEditor
        name="titleStyle"
        expanderLabel={t('Customize Title Style')}
      />
    </>
  );
};
