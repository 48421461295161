import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_E_TICKET_DAILY_USES_SUCCESS,
  FETCH_E_TICKET_DAILY_USES_FAILURE,
  FETCH_E_TICKET_DAILY_USES_REQUEST,
  FETCH_E_TICKET_DAILY_USES_CANCELED,
  DOWNLOAD_E_TICKET_DAILY_USES_CSV_SUCCESS,
  DOWNLOAD_E_TICKET_DAILY_USES_CSV_FAILURE,
  DOWNLOAD_E_TICKET_DAILY_USES_CSV_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import type {
  GetETicketDailyUsesRequest,
  ETicketDailyUsesExportParams,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchETicketDailyUsesRequest = () => ({
  type: FETCH_E_TICKET_DAILY_USES_REQUEST,
});

const fetchETicketDailyUsesSuccess = (response: any) => ({
  type: FETCH_E_TICKET_DAILY_USES_SUCCESS,
  response,
});

const fetchETicketDailyUsesFailure = (error: any) => ({
  type: FETCH_E_TICKET_DAILY_USES_FAILURE,
  error,
});

const fetchETicketDailyUsesCanceled = () => ({
  type: FETCH_E_TICKET_DAILY_USES_CANCELED,
});

let fetchETicketDailyUsesCancel: () => void | typeof undefined;

export const fetchETicketDailyUses =
  (query: GetETicketDailyUsesRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchETicketDailyUsesCancel && fetchETicketDailyUsesCancel();
    dispatch(fetchETicketDailyUsesRequest());
    axios
      .get('/api/eticketdailyuses', {
        params: {
          ...query,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchETicketDailyUsesCancel = c;
        }),
      })
      .then((result) => dispatch(fetchETicketDailyUsesSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchETicketDailyUsesCanceled());
        else dispatch(fetchETicketDailyUsesFailure(error));
      });
  };

const fetchETicketDailyUsesCSVRequest = () => ({
  type: DOWNLOAD_E_TICKET_DAILY_USES_CSV_REQUEST,
});

const fetchETicketDailyUsesCSVSuccess = () => ({
  type: DOWNLOAD_E_TICKET_DAILY_USES_CSV_SUCCESS,
});

const fetchETicketDailyUsesCSVFailure = (error: any) => ({
  type: DOWNLOAD_E_TICKET_DAILY_USES_CSV_FAILURE,
  error,
});

export const fetchETicketDailyUsesCSV =
  (req: ETicketDailyUsesExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchETicketDailyUsesCSVRequest());
    axios
      .post('/api/eticketdailyuses/csv', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, req.file_name);
        dispatch(fetchETicketDailyUsesCSVSuccess());
      })
      .catch((error) => dispatch(fetchETicketDailyUsesCSVFailure(error)));
  };
