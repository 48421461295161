import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_DISPATCH_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { ReservationColumn } from 'shared/models/swagger';
// Selectors
export const visibleColumnsForDispatchSelector = (state: ReduxState) =>
  state.dispatchTableControls.visibleColumns;
export const columnCandidatesForDispatch: ReservationColumn[] = [
  'ADD_ONS',
  'BOOKING_SOURCE',
  'DROPOFF_LOCATION',
  'GROSS',
  'GUEST_DISPLAY_NAME',
  'GIVEN_NAME',
  'FAMILY_NAME',
  'GUEST_DESCRIPTION',
  'GUEST_COUNT',
  'PAYMENT_TYPE',
  'PAYMENT_METHOD',
  'POB_AMOUNT',
  'PICKUP_CHECKIN_TIME',
  'PICKUP_CHECKIN_LOCATION',
  'PRODUCT_NAME', // This is a column group key that triggers display of multiple columns depending on reservation form fields
  // present in the product(s).
  'RESERVATION_FORM_FIELDS',
  'SUPPLIER_INTERNAL_NOTES',
  'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
  'SUPPLIER_NOTES',
  'SUPPLIER_REFERENCE',
  'SUPPLIER_PRODUCT_REFERENCE',
  'TRANSPORTATION',
  'AGENT_REFERENCE',
  'HOTEL',
  'PROMO_CODE',
  'INTERNAL_PRODUCT_TAGS',
];
// Reducers
const defaultVisibleColumns: ReservationColumn[] = [
  'ADD_ONS', // 'BOOKING_SOURCE',
  // 'DROPOFF_LOCATION',
  // 'GROSS',
  'GUEST_DISPLAY_NAME',
  'GUEST_DESCRIPTION',
  'GUEST_COUNT',
  'PAYMENT_TYPE',
  'PAYMENT_METHOD',
  'POB_AMOUNT',
  'PICKUP_CHECKIN_TIME',
  'PICKUP_CHECKIN_LOCATION',
  'PRODUCT_NAME', // This is a column group key that triggers display of multiple columns depending on reservation form fields
  // present in the product(s).
  // 'RESERVATION_FORM_FIELDS',
  'SUPPLIER_INTERNAL_NOTES', // 'SUPPLIER_NOTES',
  'SUPPLIER_REFERENCE',
  'SUPPLIER_PRODUCT_REFERENCE',
  'TRANSPORTATION',
  'AGENT_REFERENCE',
  'HOTEL',
  'PROMO_CODE',
  'INTERNAL_PRODUCT_TAGS',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_DISPATCH_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

type State = {
  visibleColumns: ReservationColumn[];
};

const reducer = combineReducers<State>({
  visibleColumns,
});
export const dispatchTableControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
