import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ListOps } from 'client/libraries/util/coreutil';
import { Input, Button } from 'client/components/Form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

import styles from './ListInput.module.css';

const { add, remove } = ListOps<string>();

const ListInputComponent = ({
  disabled,
  label,
  value,
  placeholder,
  isTranslation,
  onChange,
  tooltipText,
}: {
  disabled?: boolean;
  label: string;
  value: string[] | null | undefined;
  isTranslation?: boolean;
  placeholder?: string;
  onChange: (newValue: string[]) => void;
  tooltipText?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={baseStyles['base-form-box']}>
      {label && (
        <div className={baseStyles['base-form-box__header']}>
          {label}
          {tooltipText && <Tooltip text={tooltipText} />}
        </div>
      )}
      {value &&
        value.map((h, idx) => (
          <div
            key={idx}
            className={clsx(
              baseStyles['inline-block'],
              baseStyles['list-input']
            )}
          >
            <Input
              disabled={disabled}
              placeholder={placeholder}
              value={h || ''}
              onChange={(e, { value: newItem }) =>
                onChange(value.map((h, idx2) => (idx2 === idx ? newItem : h)))
              }
            />
            {!isTranslation && !disabled && (
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['tiny'],
                  baseStyles['icon'],
                  styles['delete-button']
                )}
                onClick={() => onChange(remove(value, idx))}
              >
                <img src={deleteIcon} />
              </a>
            )}
          </div>
        ))}
      <Button
        style="green"
        size="middle"
        onClick={() => onChange(add(value, value?.length || 0, ''))}
      >
        {t('Add')}
      </Button>
    </div>
  );
};

export const ListInput = ListInputComponent;
