import * as React from 'react';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { FORM_ERROR } from 'final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';

import { ReservationSearchSettingsForm } from './ReservationSearchSettingsForm';
import {
  getInitialValues,
  FormValues,
  convertToOrganizationPatch,
} from './ReservationSearchSettingsFormValues';

const focusOnError: any = createDecorator();
export const ReservationSearchSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        try {
          await dispatch(
            updateOrganization(
              activeUserOrganization?.id ?? '',
              activeUserOrganization?.type ?? 'SUPPLIER',
              convertToOrganizationPatch(values)
            )
          );
          setSaveSucceeded(true);
        } catch (e) {
          return { [FORM_ERROR]: t('Save Failed') };
        }
      }}
      initialValues={getInitialValues(activeUserOrganization)}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
      decorators={[focusOnError]}
      debug={console.log}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <ReservationSearchSettingsForm
            submitting={submitting}
            submitSucceeded={saveSucceeded}
            onSubmit={() => {
              setSaveSucceeded(false);
              handleSubmit();
            }}
          />
          <div>
            {saveSucceeded && <Message success header={t('Settings Saved')} />}
            {submitError && (
              <p className={baseStyles['base-form-box__err']}>{submitError}</p>
            )}
          </div>
        </form>
      )}
    </Form>
  );
};
