import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { DateRangeInput, Button } from 'client/components/Form';

import { FilterSurveySubmissionsRequest } from './util';

type Props = {
  showModal: boolean;
  onClose: () => void;
  onReset: () => void;
  onSearch: () => void;
  filterCondition: FilterSurveySubmissionsRequest;
  setFilterCondition: (arg0: FilterSurveySubmissionsRequest) => void;
};
export const FilterModal = ({
  showModal,
  onClose,
  onReset,
  onSearch,
  filterCondition,
  setFilterCondition,
}: Props) => {
  const { t } = useTranslation();

  const participationDateFromChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      participationDateFrom: date,
    });
  };

  const participationDateToChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      participationDateTo: date,
    });
  };

  const submissionDateFromChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      submissionDateFrom: date,
    });
  };

  const submissionDateToChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      submissionDateTo: date,
    });
  };

  return (
    <Modal
      title={t('Filter Survey Results')}
      open={showModal}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Box>
          <DateRangeInput
            label={t('Submission Date')}
            fromDate={filterCondition.submissionDateFrom}
            onChangeFromDate={submissionDateFromChangeHandler}
            toDate={filterCondition.submissionDateTo}
            onChangeToDate={submissionDateToChangeHandler}
          />
        </Modal.Box>
        <Modal.Box>
          <DateRangeInput
            label={t('Participation Date')}
            fromDate={filterCondition.participationDateFrom}
            onChangeFromDate={participationDateFromChangeHandler}
            toDate={filterCondition.participationDateTo}
            onChangeToDate={participationDateToChangeHandler}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
          }}
        >
          {t('Filter')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
