import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';

type Props = {
  trigger: React.ReactElement<any>;
};
export const EditOrganizationNameModal = ({ trigger }: Props) => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const [name, setName] = React.useState<string>(organization?.name || '');
  const [showModal, setShowModal] = React.useState<boolean>(false);
  React.useEffect(() => {
    setName(organization?.name || '');
  }, [organization]);
  return (
    <Modal
      title={t('Change Organization Name')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Message
          info
          content={t(
            'Note: after changing organization name, it can take up to 24 hours for the name change to be reflected in the entire system. Existing reservations will continue to use the old organization name.'
          )}
        />
        <Input
          label={t('Organization Name')}
          value={name}
          onChange={(e, { value }) => setName(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={() => setShowModal(false)}>
          {t('Cancel')}
        </Button>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            organization &&
              dispatch(
                updateOrganization(organization.id, organization.type, {
                  name,
                })
              );
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
