import _ from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import React, { useState } from 'react';

import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { getAvailabilityRuleScheduleText } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper/FieldWrapper';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { getValidators } from 'shared/libraries/validate/validator';

import { Schedule } from './formValues';
import { ScheduleDatesInput } from './ScheduleDatesInput';

const getDefaultSchedule = (): Schedule => {
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');
  return {
    dateRanges: [
      {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      },
    ],
    weekdays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    closedDates: [],
  };
};

interface Props {
  name: string;
}

export const OpenHourSchedulesEditor = ({ name }: Props) => {
  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const { t } = useTranslation();

  const form = useForm();
  const values = form.getState().values;
  const schedules = _.get(values, name);

  const { required } = getValidators(t);

  React.useEffect(() => {
    if (!schedules || schedules.length === 0) {
      form.change(name, [
        {
          schedule: getDefaultSchedule(),
        },
      ]);
    }
  }, [schedules]);

  return (
    <FieldArray name={name}>
      {({ fields, meta: { error, touched } }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <SingleDropdown
                options={fields.value.map((schedule, idx) => ({
                  text: getAvailabilityRuleScheduleText(schedule.schedule, t),
                  value: idx.toString(),
                }))}
                selectedOption={activeScheduleIndex.toString()}
                onChange={(e) => {
                  setActiveScheduleIndex(parseInt(e));
                }}
              />
              <a
                className={styles['p-products__section__body__add']}
                onClick={() => {
                  fields.push({
                    schedule: getDefaultSchedule(),
                  });
                  setActiveScheduleIndex(fields.length ?? 0);
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Schedule')}
              </a>
            </div>
            {touched && error && typeof error === 'string' && (
              <p className={baseStyles['u-error-msg']}>{error}</p>
            )}
          </div>

          <div key={activeScheduleIndex} className={styles['p-frame']}>
            <div className={styles['p-frame__header']}>
              <p className={styles['p-frame__header__ttl']}>
                {t('Selected Schedule')}
              </p>
              <div className={styles['p-frame__header__actions']}>
                {fields.value.length > 1 && (
                  <Button
                    text={t('Remove this Schedule')}
                    size="sm"
                    color="tertiarygray"
                    onClick={() => {
                      fields.remove(activeScheduleIndex);
                      setActiveScheduleIndex(0);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                    style={{ color: 'var(--error600)' }}
                  />
                )}
              </div>
            </div>
            <div className={styles['p-frame__body']}>
              <ul className={styles['p-list']}>
                <ScheduleDatesInput
                  name={`${name}.${activeScheduleIndex}.schedule`}
                />
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__body']}>
                      <Box display="flex" justifyContent="flex-start" gap={1}>
                        <Field
                          name={`${name}.${activeScheduleIndex}.openFrom`}
                          validate={required}
                        >
                          {({ input, meta: { touched, error } }) => (
                            <FieldWrapper label={t('Open From')}>
                              <Box width="150px">
                                <TimePicker
                                  value={
                                    input.value
                                      ? moment(input.value, 'HH:mm')
                                      : undefined
                                  }
                                  onChange={(newMoment) => {
                                    if (!newMoment) {
                                      input.onChange('');
                                    } else {
                                      input.onChange(newMoment.format('HH:mm'));
                                    }
                                  }}
                                />
                                {touched && error && (
                                  <div className={styles['p-err']}>{error}</div>
                                )}
                              </Box>
                            </FieldWrapper>
                          )}
                        </Field>
                        <Field
                          name={`${name}.${activeScheduleIndex}.openTo`}
                          validate={required}
                        >
                          {({ input, meta: { touched, error } }) => (
                            <FieldWrapper label={t('Open To')}>
                              <Box width="150px">
                                <TimePicker
                                  value={
                                    input.value
                                      ? moment(input.value, 'HH:mm')
                                      : undefined
                                  }
                                  onChange={(newMoment) => {
                                    if (!newMoment) {
                                      input.onChange('');
                                    } else {
                                      input.onChange(newMoment.format('HH:mm'));
                                    }
                                  }}
                                />
                                {touched && error && (
                                  <div className={styles['p-err']}>{error}</div>
                                )}
                              </Box>
                            </FieldWrapper>
                          )}
                        </Field>
                      </Box>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
