import { useMemo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import {
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  initialCustomizedColumnNames: ManifestCustomizedColumnName[];
  setCustomizedColumnNames: Dispatch<
    SetStateAction<ManifestCustomizedColumnName[]>
  >;
};

export const EditColumnNameTable = ({
  initialCustomizedColumnNames,
  setCustomizedColumnNames,
}: Props) => {
  const { t } = useTranslation();
  const allManifestColumns = useAllManifestColumns();

  const targetManifestColumnNames = useMemo(() => {
    const columns: ReservationColumn[] = [];

    for (const col of allManifestColumns.filter(
      (c) => c !== 'RESERVATION_FORM_FIELDS' && c !== 'RESERVATION_FORM_CUSTOM'
    )) {
      if (col === 'TRANSPORT_ROUTE') {
        columns.push(
          'TRANSPORT_DEPARTURE_TIME',
          'TRANSPORT_DEPARTURE_LOCATION',
          'TRANSPORT_ARRIVAL_TIME',
          'TRANSPORT_ARRIVAL_LOCATION'
        );
      } else {
        columns.push(col);
      }
    }

    return columns;
  }, [allManifestColumns]);

  return (
    <table className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}>
      <thead>
        <tr>
          <th style={{ width: '200px' }}>{t('Default name')}</th>
          <th style={{ width: '200px' }}>{t('Customized name')}</th>
        </tr>
      </thead>
      <tbody>
        {_.sortBy(targetManifestColumnNames, (c) => c.toString()).map(
          (column) => (
            <tr key={column}>
              <td className={tableStyles['c-tableSmall__spTtl']}>
                {getColumnHeaderText(column, t)}
              </td>
              <td data-text={t('Customized name')}>
                <TextField
                  value={
                    initialCustomizedColumnNames.find(
                      (c) => c.column === column
                    )?.name || ''
                  }
                  placeholder={getColumnHeaderText(column, t)}
                  onChange={(value) => {
                    setCustomizedColumnNames([
                      ...initialCustomizedColumnNames.filter(
                        (c) => c.column !== column
                      ),
                      {
                        column: column,
                        name: value,
                      },
                    ]);
                  }}
                />
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};
