import { ThunkDispatch } from 'redux-thunk';
import { Auth, CognitoUser } from '@aws-amplify/auth';

import {
  CHANGE_USER_ATTRIBUTES_FAILURE,
  CHANGE_USER_ATTRIBUTES_REQUEST,
  CHANGE_USER_ATTRIBUTES_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ERROR,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_REQUEST_CANCEL,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_RESET_FAILURE,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REQUIRED_PASSWORD_RESET_FAILURE,
  REQUIRED_PASSWORD_RESET_REQUEST,
  REQUIRED_PASSWORD_RESET_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_ALTERNATE_ORGANIZATION_IS_ACTIVE,
  SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET,
  ADD_BOOKMARK_REQUEST,
  REMOVE_BOOKMARK_REQUEST,
  SET_ACTOR_NAME,
  SEND_ONETIME_CODE_REQUEST,
  SEND_ONETIME_CODE_SUCCESS,
  SEND_ONETIME_CODE_FAILURE,
} from 'client/constants/ActionTypes';
import {
  CODE_MISMATCH_EXCEPTION,
  EMPTY_EMAIL_EXCEPTION,
  EMPTY_NEW_PASSWORD_EXCEPTION,
  EMPTY_PASSWORD_EXCEPTION,
  EMPTY_RECOVERY_CODE_EXCEPTION,
  INVALID_EMAIL_FORMAT_EXCEPTION,
  INVALID_PARAMETER_EXCEPTION,
  INVALID_PASSWORD_EXCEPTION,
  LIMIT_EXCEEDED_EXCEPTION,
  NOT_AUTHORIZED_EXCEPTION,
  PASSWORD_RESET_REQUIRED_EXCEPTION,
  USER_NOT_FOUND_EXCEPTION,
  EMPTY_ONETIME_CODE_EXCEPTION,
  INVALID_ONETIME_CODE_EXCEPTION,
} from 'client/constants/ErrorMessages';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { bookmarksSetSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';

Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
});

type Dispatch = ThunkDispatch<any, any, any>;

export const clearError = () => ({
  type: CLEAR_ERROR,
  error: '',
});

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (
  user: Record<string, any>,
  attributes: Record<string, any>
) => ({
  type: LOGIN_SUCCESS,
  user,
  attributes,
});

const loginFailure = (error: string) => ({
  type: LOGIN_FAILURE,
  error,
});

export const login =
  (user: string, password: string) => (dispatch: Dispatch) => {
    if (user === '') {
      dispatch(loginFailure(EMPTY_EMAIL_EXCEPTION));
    } else if (password === '') {
      dispatch(loginFailure(EMPTY_PASSWORD_EXCEPTION));
    } else if (!matchesFormat(user, 'email')) {
      dispatch(loginFailure(INVALID_EMAIL_FORMAT_EXCEPTION));
    } else {
      dispatch(loginRequest());
      return Auth.signIn({ username: user, password })
        .then((cognito) => {
          if (cognito.challengeName) {
            dispatch(
              loginSuccess(
                cognito,
                cognito?.challengeParam?.userAttributes ?? {}
              )
            );
          } else {
            Auth.currentUserInfo().then((response) => {
              dispatch(loginSuccess(cognito, response?.attributes ?? {}));
            });
          }
        })
        .catch((err) => {
          switch (err.code) {
            case USER_NOT_FOUND_EXCEPTION:
              dispatch(loginFailure(USER_NOT_FOUND_EXCEPTION));
              break;

            case PASSWORD_RESET_REQUIRED_EXCEPTION:
              dispatch(loginFailure(PASSWORD_RESET_REQUIRED_EXCEPTION));
              break;

            case LIMIT_EXCEEDED_EXCEPTION:
              dispatch(loginFailure(LIMIT_EXCEEDED_EXCEPTION));
              break;

            // TODO(yijun): more error types incoming. and change this to a map.
            default:
              dispatch(loginFailure(NOT_AUTHORIZED_EXCEPTION));
          }
        });
    }
  };

const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

const logoutSuccess = (response: any) => ({
  type: LOGOUT_SUCCESS,
  response,
});

const logoutFailure = (error: string) => ({
  type: LOGOUT_FAILURE,
  error,
});

export const logout = () => (dispatch: Dispatch) => {
  dispatch(logoutRequest());
  Auth.signOut()
    .then((response) => dispatch(logoutSuccess(response)))
    .catch((err) => {
      dispatch(logoutFailure(err.message));
    });
};

const forgotPasswordRequested = () => ({
  type: FORGOT_PASSWORD_REQUESTED,
});

const forgotPasswordRequestSuccess = () => ({
  type: FORGOT_PASSWORD_REQUEST_SUCCESS,
});

const forgotPasswordRequestFailure = (error: string) => ({
  type: FORGOT_PASSWORD_REQUEST_FAILURE,
  error,
});

export const forgotPasswordRequest = (user: string) => (dispatch: Dispatch) => {
  if (user === '') {
    dispatch(forgotPasswordRequestFailure(EMPTY_EMAIL_EXCEPTION));
  } else if (!matchesFormat(user, 'email')) {
    dispatch(forgotPasswordRequestFailure(INVALID_EMAIL_FORMAT_EXCEPTION));
  } else {
    dispatch(forgotPasswordRequested());
    Auth.forgotPassword(user)
      .then(() => dispatch(forgotPasswordRequestSuccess()))
      .catch((err) => {
        switch (err.code) {
          case USER_NOT_FOUND_EXCEPTION:
            dispatch(forgotPasswordRequestFailure(USER_NOT_FOUND_EXCEPTION));
            break;

          case LIMIT_EXCEEDED_EXCEPTION:
            dispatch(forgotPasswordRequestFailure(LIMIT_EXCEEDED_EXCEPTION));
            break;

          // TODO(yijun): more error types incoming. and change this to a map.
          default:
            dispatch(forgotPasswordRequestFailure(NOT_AUTHORIZED_EXCEPTION));
        }
      });
  }
};

const forgotPasswordRequestCancel = () => ({
  type: FORGOT_PASSWORD_REQUEST_CANCEL,
});

export const forgotPasswordCancel = () => (dispatch: Dispatch) => {
  dispatch(forgotPasswordRequestCancel());
};

const forgotPasswordResetFailure = (error: string) => ({
  type: FORGOT_PASSWORD_RESET_FAILURE,
  error,
});

export const forgotPasswordReset =
  (email: string, code: string, newPassword: string) => (dispatch: any) => {
    if (email === '') {
      dispatch(forgotPasswordResetFailure(EMPTY_EMAIL_EXCEPTION));
    } else if (code === '') {
      dispatch(forgotPasswordResetFailure(EMPTY_RECOVERY_CODE_EXCEPTION));
    } else if (newPassword === '') {
      dispatch(forgotPasswordResetFailure(EMPTY_NEW_PASSWORD_EXCEPTION));
    } else if (!matchesFormat(email, 'email')) {
      dispatch(forgotPasswordResetFailure(INVALID_EMAIL_FORMAT_EXCEPTION));
    } else {
      Auth.forgotPasswordSubmit(email, code, newPassword)
        .then(() => {
          dispatch(login(email, newPassword));
        })
        .catch((err) => {
          switch (err.code) {
            case INVALID_PARAMETER_EXCEPTION:
              dispatch(forgotPasswordResetFailure(INVALID_PARAMETER_EXCEPTION));
              break;

            case INVALID_PASSWORD_EXCEPTION:
              dispatch(forgotPasswordResetFailure(INVALID_PASSWORD_EXCEPTION));
              break;

            case LIMIT_EXCEEDED_EXCEPTION:
              dispatch(forgotPasswordResetFailure(LIMIT_EXCEEDED_EXCEPTION));
              break;

            case CODE_MISMATCH_EXCEPTION:
            default:
              dispatch(forgotPasswordResetFailure(CODE_MISMATCH_EXCEPTION));
          }
        });
    }
  };

const changeUserAttributesRequest = () => ({
  type: CHANGE_USER_ATTRIBUTES_REQUEST,
});

const changeUserAttributesSuccess = (newAttr: any, response: any) => ({
  type: CHANGE_USER_ATTRIBUTES_SUCCESS,
  newAttr,
  response,
});

const changeUserAttributesFailure = (error: string) => ({
  type: CHANGE_USER_ATTRIBUTES_FAILURE,
  error,
});

export const changeUserAttributes =
  (newAttr: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(changeUserAttributesRequest());
    // TODO(andrew): Should we use Auth.currentAuthenticatedUser() ??
    Auth.updateUserAttributes(getState().user.cognito, newAttr)
      .then((response) => {
        dispatch(changeUserAttributesSuccess(newAttr, response));
      })
      .catch((err) => {
        dispatch(changeUserAttributesFailure(err.message));
      });
  };

const addBookmarkRequest = (id: string) => ({
  type: ADD_BOOKMARK_REQUEST,
  id,
});

export const addBookmark =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(addBookmarkRequest(id));
    const bookmarks = bookmarksSetSelector(getState());
    bookmarks.add(id);
    const bookmarksArray = Array.from(bookmarks);
    dispatch(
      changeUserAttributes({
        'custom:bookmarks': bookmarksArray.join(','),
      })
    );
  };

const removeBookmarkRequest = (id: string) => ({
  type: REMOVE_BOOKMARK_REQUEST,
  id,
});

export const removeBookmark =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(removeBookmarkRequest(id));
    const bookmarks = bookmarksSetSelector(getState());
    bookmarks.delete(id);
    const bookmarksArray = Array.from(bookmarks);
    dispatch(
      changeUserAttributes({
        'custom:bookmarks': bookmarksArray.join(','),
      })
    );
  };

const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = (response: any) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  response,
});

const changePasswordFailure = (error: string) => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
});

export const changePassword =
  (oldPassword: string, newPassword: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(changePasswordRequest());
    Auth.changePassword(getState().user.cognito, oldPassword, newPassword)
      .then((response) => {
        dispatch(changePasswordSuccess(response));
      })
      .catch((err) => {
        dispatch(changePasswordFailure(err.message));
      });
  };

const requiredPasswordResetRequest = () => ({
  type: REQUIRED_PASSWORD_RESET_REQUEST,
});

const requiredPasswordResetSuccess = (response: any) => ({
  type: REQUIRED_PASSWORD_RESET_SUCCESS,
  response,
});

const requiredPasswordResetFailure = (error: string) => ({
  type: REQUIRED_PASSWORD_RESET_FAILURE,
  error,
});

export const requiredPasswordReset =
  (newPassword: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(requiredPasswordResetRequest());
    return Auth.completeNewPassword(getState().user.cognito, newPassword)
      .then(() => {
        // origResponse still has challengeName set -- WHY??!
        Auth.currentAuthenticatedUser().then((cognito) =>
          Auth.currentUserInfo().then((response) => {
            dispatch(requiredPasswordResetSuccess(response));
            dispatch(loginSuccess(cognito, response.attributes));
          })
        );
      })
      .catch((err) => {
        dispatch(requiredPasswordResetFailure(err.message));
      });
  };
export const setImpersonatedUserID = (id: string) => ({
  type: SET_IMPERSONATED_USER_ID,
  id,
});
export const setActorName = (payload: string) => ({
  type: SET_ACTOR_NAME,
  payload,
});
export const setAlternateOrganizationIsActive = (payload: boolean) => ({
  type: SET_ALTERNATE_ORGANIZATION_IS_ACTIVE,
  payload,
});
export const setAlternateOrganizationIsActiveInSecret = (payload: boolean) => ({
  type: SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET,
  payload,
});

const sendOnetimeCodeRequest = () => ({
  type: SEND_ONETIME_CODE_REQUEST,
});

export const sendOnetimeCodeSuccess = (
  user: Record<string, any>,
  attributes: Record<string, any>
) => ({
  type: SEND_ONETIME_CODE_SUCCESS,
  user,
  attributes,
});

const sendOnetimeCodeFailure = (error: string) => ({
  type: SEND_ONETIME_CODE_FAILURE,
  error,
});

export const sendOnetimeCode =
  (cognito: CognitoUser, onetimeCode: string) => (dispatch: Dispatch) => {
    if (onetimeCode === '') {
      dispatch(sendOnetimeCodeFailure(EMPTY_ONETIME_CODE_EXCEPTION));
    } else {
      dispatch(sendOnetimeCodeRequest());
      return Auth.sendCustomChallengeAnswer(cognito, onetimeCode)
        .then((updatedCognito) => {
          if (updatedCognito.challengeName) {
            dispatch(
              sendOnetimeCodeSuccess(
                updatedCognito,
                updatedCognito.challengeParam.userAttributes
              )
            );
          } else {
            Auth.currentUserInfo().then((response) => {
              dispatch(
                sendOnetimeCodeSuccess(updatedCognito, response.attributes)
              );
            });
          }
        })
        .catch((err) => {
          switch (err.code) {
            case USER_NOT_FOUND_EXCEPTION:
              dispatch(sendOnetimeCodeFailure(USER_NOT_FOUND_EXCEPTION));
              break;

            case PASSWORD_RESET_REQUIRED_EXCEPTION:
              dispatch(
                sendOnetimeCodeFailure(PASSWORD_RESET_REQUIRED_EXCEPTION)
              );
              break;

            case LIMIT_EXCEEDED_EXCEPTION:
              dispatch(sendOnetimeCodeFailure(LIMIT_EXCEEDED_EXCEPTION));
              break;

            // TODO(yijun): more error types incoming. and change this to a map.
            default:
              dispatch(sendOnetimeCodeFailure(INVALID_ONETIME_CODE_EXCEPTION));
          }
        });
    }
  };
