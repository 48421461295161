import {
  SET_INVOICE_SUMMARY_VISIBLE_COLUMNS,
  SET_INVOICE_SUMMARY_VISIBLE_ROWS,
  SET_INVOICE_RESERVATION_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';

export const setInvoiceReservationVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_INVOICE_RESERVATION_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setInvoiceSummaryVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_INVOICE_SUMMARY_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setInvoiceSummaryVisibleRows = (visibleRows: string[]) => ({
  type: SET_INVOICE_SUMMARY_VISIBLE_ROWS,
  visibleRows,
});
