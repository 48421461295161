// @flow

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';

import * as swagger from 'shared/models/swagger';
import { WeekdayInput } from 'client/components/WeekdayInput';

type OwnProps = {
  onChange: (swagger.OperatingHoursRule) => void,
  value: swagger.OperatingHoursRule,
};

/* eslint-disable no-use-before-define */
type Props = { ...OwnProps, ...TranslatorProps };
/* eslint-enable no-use-before-define */

const OperatingHoursInputComponent = (props: Props) => {
  const { onChange, t, value } = props;

  return (
    <Form.Field>
      <WeekdayInput
        label={t('Operating hours: open days of week')}
        value={value.days_of_week || []}
        setValue={(days_of_week) =>
          onChange({
            ...value,
            days_of_week,
          })
        }
      />
      <Form.Input
        label={t('Operating hours: opens (H:mm time)')}
        value={value.start_time_local || ''}
        onChange={(e, { value: start_time_local }) => {
          onChange({
            ...value,
            start_time_local,
          });
        }}
      />
      <Form.Input
        label={t('Operating hours: closes (H:mm time)')}
        value={value.end_time_local || ''}
        onChange={(e, { value: end_time_local }) => {
          onChange({
            ...value,
            end_time_local,
          });
        }}
      />
    </Form.Field>
  );
};

export const OperatingHoursInput = withTranslation()(
  OperatingHoursInputComponent
);
