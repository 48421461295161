import clsx from 'clsx';

import styles from './Badge.module.css';

export type BadgeColor =
  | 'error'
  | 'error-contained'
  | 'warning'
  | 'warning-contained'
  | 'success'
  | 'gray'
  | 'gray-contained'
  | 'orange'
  | 'primary'
  | 'primary-contained'
  | 'blue'
  | 'green'
  | 'yellow'
  | 'purple'
  | 'pink'
  | 'red';
// Add more if necessary

type BadgeProps = {
  label: string;
  size?: 'sm' | 'md' | 'lg';
  color?: BadgeColor;
  borderRadius?: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
};

export const Badge = ({
  label,
  size = 'md',
  color = 'primary',
  borderRadius,
  backgroundColor,
  borderColor,
  textColor,
}: BadgeProps) => {
  return (
    <p
      style={{ borderRadius, backgroundColor, borderColor, color: textColor }}
      className={clsx(styles['c-badge'], styles[size], styles[color])}
    >
      {label}
    </p>
  );
};
