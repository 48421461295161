import type { TranslateFuncType } from 'client/components/Translate';
import type { ReviewAttribution } from 'shared/models/swagger';

export const getReviewAttributionText = (
  attribution: ReviewAttribution,
  t: TranslateFuncType
): string => {
  switch (attribution) {
    case 'FAMILY':
      return t('Family');

    case 'COUPLE':
      return t('Couple');

    case 'FRIEND':
      return t('Friends / Colleagues');

    case 'SOLO':
      return t('Solo');

    case 'BUSINESS':
    default:
      return t('Work');
  }
};
