// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { config } from 'client/config';
import { EditCrewMemberModal } from 'client/pages/DispatchCustomize/CrewMemberList/EditCrewMemberModal';
import { DeleteCrewMemberModal } from 'client/pages/DispatchCustomize/CrewMemberList/DeleteCrewMemberModal';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import { getWeekdayListText } from 'client/libraries/util/weekdays';
import type { TranslateFuncType } from 'client/components/Translate';
import type { DispatchCrewMember } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import deleteIcon from 'client/images/ic_delete.svg';
import editIcon from 'client/images/ic_edit.svg';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { CrewAvailabilityModal } from './CrewAvailabilityModal/CrewAvailabilityModal';
import { CrewAvailabilityDisplayBox } from './CrewAvailabilityDisplayBox';
import styles from './CrewMemberList.module.css';

type Props = {
  isActive?: boolean,
};

const scheduleAsText = (
  crewMember: DispatchCrewMember,
  t: TranslateFuncType
): string => {
  if (!crewMember.schedule || crewMember.schedule.length === 0) {
    return '';
  }

  const schedule = crewMember.schedule[0];
  let scheduleComponents = [];
  if (schedule.start_date_local && schedule.end_date_local) {
    scheduleComponents.push(
      `(${schedule.start_date_local} ~ ${schedule.end_date_local})`
    );
  } else if (schedule.start_date_local && !schedule.end_date_local) {
    scheduleComponents.push(`(${schedule.start_date_local} ~ )`);
  } else if (!schedule.start_date_local && schedule.end_date_local) {
    scheduleComponents.push(`( ~ ${schedule.end_date_local})`);
  }

  if (schedule.days_of_week && schedule.days_of_week.length > 0) {
    scheduleComponents.push(getWeekdayListText(schedule.days_of_week, t));
  }

  if (schedule.excluded_dates && schedule.excluded_dates.length > 0) {
    const excludedDates = schedule.excluded_dates.join('; ');
    scheduleComponents.push(
      t('excluding: {{excludedDates}}', {
        excludedDates,
      })
    );
  }

  return scheduleComponents.join(' - ');
};

export const CrewMemberList = ({ isActive }: Props) => {
  const { t } = useTranslation();

  const crewMembers = useSelector(allDispatchCrewMembersSelector);

  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const [existingCrewMember, setExistingCrewMember] =
    React.useState<DispatchCrewMember | null>(null);

  const [crewMemberToDelete, setCrewMemberToDelete] =
    React.useState<DispatchCrewMember | null>(null);

  const [showCrewAvailabilityModal, setShowCrewAvailabilityModal] =
    React.useState<boolean>(false);

  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const getColumns = () => {
    return [
      {
        Header: '',
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
            onClick={() => {
              setExistingCrewMember(cellInfo.original);
              setShowEditModal(true);
            }}
          >
            <img src={editIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: '',
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Delete')}
            onClick={() => {
              setCrewMemberToDelete(cellInfo.original);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: t('Name'),
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => <>{cellInfo.value}</>,
        width: 'long',
      },
      {
        Header: t('Schedule'),
        id: 'schedule',
        accessor: (row) => scheduleAsText(row, t),
        width: 'longx2',
      },
      {
        Header: t('Description'),
        id: 'description',
        accessor: 'description',
      },
    ];
  };

  const columns = getColumns();

  if (
    config.enableResourceManagementVehicle &&
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      id: 'availability',
      accessor: 'availability',
      Cell: (cellInfo) => (
        <div className={styles['resource_list__display_box__column']}>
          <div className={styles['resource_list__display_box__wrapper']}>
            <CrewAvailabilityDisplayBox resourceKey={cellInfo.original.key} />
          </div>
          <div className={clsx(styles['resource_list__display_box__button'])}>
            <a
              className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
              data-text={t('Edit')}
              onClick={() => {
                setExistingCrewMember(cellInfo.original);
                setShowCrewAvailabilityModal(true);
              }}
            >
              <img src={editIcon} />
            </a>
          </div>
        </div>
      ),
    });
  }

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Crew')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['flex'],
              baseStyles['green']
            )}
            onClick={() => {
              setExistingCrewMember(null);
              setShowEditModal(true);
            }}
          >
            {t('Add New Crew Member')}
          </a>
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <EditCrewMemberModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          existingCrewMember={existingCrewMember}
        />

        <DeleteCrewMemberModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          crewMemberToDelete={crewMemberToDelete}
        />

        {showCrewAvailabilityModal && (
          <CrewAvailabilityModal
            open={showCrewAvailabilityModal}
            onClose={() => {
              setShowCrewAvailabilityModal(false);
            }}
            resourceKey={existingCrewMember?.key ?? ''}
          />
        )}

        <ManifestCustomizeCustomTable
          items={_.sortBy(crewMembers, (c) => c.key)}
          columns={columns}
          usePaging={false}
          useScrollButton={false}
          locale={'ja'}
        />
      </div>
    </div>
  );
};
