import * as React from 'react';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';

interface FormValue {
  priceKey: string;
}

interface Props {
  equipmentBlockInstancePriceKey: string | null;
  onClose: () => void;
  open: boolean;
  onSave: (value: string) => Promise<any>;
  saveButtonText?: string;
  cancelButtonText?: string;
}

const getInitialValues = (
  equipmentBlockInstancePriceKey: string | null
): FormValue => {
  return {
    priceKey: equipmentBlockInstancePriceKey ?? '',
  };
};

const convertToEquipmentBlockInstancePriceSettings = (
  value: FormValue
): string => {
  return value.priceKey;
};

export const EditEquipmentBlockInstancePriceModal = ({
  equipmentBlockInstancePriceKey,
  onClose,
  open,
  onSave,
  saveButtonText,
  cancelButtonText,
}: Props) => {
  const { t } = useTranslation();

  const organization = useSelector(activeUserOrganizationSelector);

  const equipmentBlockInstancePrices = React.useMemo(() => {
    return (
      organization?.seat_management_settings?.equipment_block_instance_prices ||
      []
    );
  }, [organization]);

  const [success, setSuccess] = React.useState<boolean>(false);

  const options = equipmentBlockInstancePrices.map((price) => ({
    value: price.key ?? '',
    text: price.title ?? '',
  }));

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t('Edit Seat Settings')}
      transparent={true}
    >
      <Form
        onSubmit={async (value: FormValue) => {
          setSuccess(false);
          await onSave(convertToEquipmentBlockInstancePriceSettings(value));
          setSuccess(true);
        }}
        initialValues={getInitialValues(equipmentBlockInstancePriceKey)}
      >
        {({ handleSubmit, submitting, error }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="priceKey">
                  {({ input }) => (
                    <Select
                      label={t('Price')}
                      options={options}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
              <div>
                {success && <Message success header={t('Save Successful')} />}
                {error && <Message error header={t('Save Failed')} />}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {cancelButtonText || t('Discard')}
              </Button.Cancel>
              <Button
                loading={submitting}
                style="green"
                size="middle"
                type="submit"
              >
                {saveButtonText || t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
