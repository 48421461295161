import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  fetchNewsletters,
  deleteNewsletter,
  createNewsletter,
  fetchNewslettersCSV,
} from 'client/actions/newsletters';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { Edit } from 'client/components/Icons/Edit';
import { Delete } from 'client/components/Icons/Delete';
import { Copy } from 'client/components/Icons/Copy';
import { formattedPercent } from 'client/libraries/util/formattedPercent';
import { NewsletterTargetsButton } from 'client/pages/SendNewsletterWizard/NewsletterTargetsButton';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { Newsletter } from 'shared/models/swagger';

import { NewsletterSearchModal } from './NewsletterSearchModal';
import { FiltersDisplayBox } from './FiltersDisplayBox';

const EditCopyDeleteCell = ({ newsletter }: { newsletter: Newsletter }) => {
  const { t } = useTranslation();
  const [showDeleteNewsletterModal, setShowDeleteNewsletterModal] =
    React.useState(false);
  const loading = useSelector((state: ReduxState) => state.newsletters.loading);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();

  return (
    <Box display="flex">
      {newsletter.status === 'DRAFT' && (
        <Link to={`/newsletters/${newsletter.id}/edit`}>
          <Edit />
        </Link>
      )}
      {newsletter.status === 'SENT' && (
        <Copy
          onClick={async () => {
            await dispatch(
              createNewsletter({
                status: 'DRAFT',
                segment_ids: newsletter.segment_ids,
                email_template_name: newsletter.email_template_name,
                email_template_values_json:
                  newsletter.email_template_values_json,
                email_subject: `[COPY] ${newsletter.email_subject}`,
                customer_ids: newsletter.customer_ids,
              })
            );

            if (store.getState().newsletters.lastCreatedNewsletter) {
              history.push(
                `/newsletters/${
                  store.getState().newsletters.lastCreatedNewsletter.id
                }/edit`
              );
            }
          }}
        />
      )}
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteNewsletterModal(true)} />
        {showDeleteNewsletterModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Newsletter')}
            content={t('Are you sure you want to delete newsletter?')}
            onConfirm={async () => {
              await dispatch(deleteNewsletter(newsletter.id as string));
            }}
            onClose={() => setShowDeleteNewsletterModal(false)}
            open={showDeleteNewsletterModal}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<Newsletter>[] => {
  const { t } = useTranslation();
  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(activeUser, 'NEWSLETTER.LIST')
      ? [
          {
            Header: '',
            id: 'edit',
            accessor: (newsletter) => (
              <EditCopyDeleteCell newsletter={newsletter} />
            ),
          } as ColumnType<Newsletter>,
        ]
      : []),
    {
      Header: t('Send Date'),
      id: 'name',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc
          ? moment(newsletter.sent_date_time_utc).locale(locale).format('lll')
          : '-',
    },
    {
      Header: t('Subject'),
      id: 'subject',
      accessor: (newsletter) => newsletter.email_subject,
    },
    {
      Header: t('Segments'),
      id: 'segments',
      accessor: (newsletter) => (
        <ul>
          {newsletter.segment_ids?.map((segmentId) => (
            <li key={segmentId}>
              {segments.find((s) => s.id === segmentId)?.name}
            </li>
          ))}
        </ul>
      ),
    },
    {
      Header: t('Targets'),
      id: 'targets',
      accessor: (newsletter) => (
        <NewsletterTargetsButton newsletter={newsletter} />
      ),
    },
    {
      Header: t('Deliveries'),
      id: 'delivered',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{newsletter.delivered_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                newsletter.delivered_count ?? 0,
                newsletter.customer_ids?.length ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Opens'),
      id: 'opened',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{newsletter.opened_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                newsletter.opened_count ?? 0,
                newsletter.customer_ids?.length ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },

    {
      Header: t('Clicks'),
      id: 'clicked',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{newsletter.clicked_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                newsletter.clicked_count ?? 0,
                newsletter.customer_ids?.length ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },

    {
      Header: t('Bounces'),
      id: 'bounced',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{newsletter.bounced_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                newsletter.bounced_count ?? 0,
                newsletter.customer_ids?.length ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Bookings'),
      id: 'booked',
      accessor: (newsletter) =>
        newsletter.sent_date_time_utc ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{newsletter.booked_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                newsletter.booked_count ?? 0,
                newsletter.customer_ids?.length ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('View'),
      id: 'view',
      accessor: (newsletter) => {
        if (newsletter.status !== 'DRAFT') {
          return (
            <>
              <div>
                <Link to={`/newsletters/${newsletter.id}/report`}>
                  <a>{t('Report')}</a>
                </Link>
              </div>
              <div>
                <Link to={`/newsletters/${newsletter.id}/email`}>
                  <a>{t('Email')}</a>
                </Link>
              </div>
            </>
          );
        }

        return '-';
      },
    },
  ];
};

export const NewsletterList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const csvLoading = useSelector(
    (state: ReduxState) => state.newsletters.csvLoading
  );

  React.useEffect(() => {
    dispatch(fetchNewsletters());
  }, []);

  const newsletters = useSelector((state: ReduxState) => state.newsletters.all);
  const filters = useSelector(
    (state: ReduxState) => state.newslettersSearch.filters
  );

  const filteredNewsletters = newsletters.filter(
    (newsletter) =>
      (!filters.sendDateFrom ||
        (newsletter.sent_date_time_utc &&
          moment(newsletter.sent_date_time_utc).format('YYYY-MM-DD') >=
            filters.sendDateFrom)) &&
      (!filters.sendDateTo ||
        (newsletter.sent_date_time_utc &&
          moment(newsletter.sent_date_time_utc).format('YYYY-MM-DD') <=
            filters.sendDateTo)) &&
      (!filters.segments?.length ||
        (newsletter.segment_ids &&
          newsletter.segment_ids.some((segmentId) =>
            filters.segments.includes(segmentId)
          )))
  );

  // Use stringified filters as a key for the table to force re-render when filters change
  const stringifiedFilters = JSON.stringify(filters);

  const columns = useColumns();

  const activeUser = useSelector(activeUserSelector);

  return (
    <div>
      <Box display="flex" mb={2}>
        <Button
          style="blue"
          size="middle"
          onClick={() => setShowSearchModal(true)}
        >
          {t('Search')}
        </Button>
        {showSearchModal && (
          <NewsletterSearchModal onClose={() => setShowSearchModal(false)} />
        )}
        <Box ml="auto">
          {hasCustomUserRoleWritePermissions(activeUser, 'NEWSLETTER.LIST') && (
            <Link to="/newsletters/new">
              <Button style="green" size="middle">
                {t('Create New Newsletter')}
              </Button>
            </Link>
          )}
        </Box>
        <Box ml={2}>
          <Button
            style="gray"
            size="middle"
            loading={csvLoading}
            onClick={() =>
              dispatch(
                fetchNewslettersCSV({
                  sent_date_time_utc_from: filters.sendDateFrom
                    ? moment(filters.sendDateFrom).utc().format()
                    : '',
                  sent_date_time_utc_to: filters.sendDateTo
                    ? moment(filters.sendDateTo).endOf('day').utc().format()
                    : '',
                  segment_ids: filters.segments,
                  timezone: moment.tz.guess(),
                })
              )
            }
          >
            {t('Download CSV')}
          </Button>
        </Box>
      </Box>

      <Box mb={2}>
        <FiltersDisplayBox />
      </Box>
      <PagedGenericTable<Newsletter>
        key={stringifiedFilters}
        allItems={filteredNewsletters}
        columns={columns}
      />
    </div>
  );
};
