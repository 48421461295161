import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import { ReduxState } from 'client/reducers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReservationReportBarChartPane } from 'client/pages/v3/FlexibleDashboard/Chart/ReservationReportBarChartPane';
import {
  getDateRange,
  getReservationReportDataSum,
} from 'client/pages/v3/FlexibleDashboard/util';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { getGraphColor } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/util';
import { COMPARISON_COLOR } from 'client/pages/v3/FlexibleDashboard/Chart/util';

interface Props {
  params: ReservationReportGadgetParams;
  reportDataSets: Swagger.ReservationReportDataSet[];
}

export const ReservationReportProductsBarChart = ({
  reportDataSets,
  params,
}: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const currencyCode = activeUserOrganization?.default_currency || null;

  const [baseRange, compareRange] = getDateRange(reportDataSets);

  const grouped = _.groupBy(reportDataSets[0].items ?? [], (item) => {
    return item.product_id;
  });

  const productsById = allProducts.reduce(
    (acc: Record<string, Swagger.ProductSummary>, product) => {
      acc[product.id] = product;
      return acc;
    },
    {}
  );

  const productCounts: { productName: string; count: number }[] = [];
  Object.keys(grouped).forEach((key) => {
    productCounts.push({
      productName: getDisplayProductName(productsById[key]) || key,
      count: getReservationReportDataSum(grouped[key], params.dataType),
    });
  });

  const sorted = _.sortBy(productCounts, (item) => -item.count);
  const data = sorted.map((item) => ({
    [item.productName]: item.count,
  }));

  // Create comparison data
  let dataComparison: {
    [x: string]: number;
  }[] = [];
  if (reportDataSets.length > 1) {
    const grouped = _.groupBy(reportDataSets[1].items ?? [], (item) => {
      return item.product_id;
    });
    const productCounts: { productName: string; count: number }[] = [];
    Object.keys(grouped).forEach((key) => {
      productCounts.push({
        productName: getDisplayProductName(productsById[key]) || key,
        count: getReservationReportDataSum(grouped[key], params.dataType),
      });
    });
    const sorted = _.sortBy(productCounts, (item) => -item.count);
    dataComparison = sorted.map((item) => ({
      [t('Compare') + ' ' + item.productName]: item.count,
    }));
  }

  const combineData = (arr1: any[], arr2: any[]) => {
    const combinedArray: any[] = [];

    for (const obj of arr1) {
      const key = Object.keys(obj)[0];
      const matchingObj = arr2.find((obj2) => `${t('Compare')} ${key}` in obj2);

      const newObj = {
        name: key,
        [baseRange]: obj[key],
      };

      if (arr2.length > 0) {
        newObj[compareRange] = matchingObj
          ? matchingObj[`${t('Compare')} ${key}`]
          : 0;
      }

      combinedArray.push(newObj);
    }

    return combinedArray;
  };

  let names = {
    [baseRange]: t('Base Range'),
  };
  let colors = [getGraphColor(params)];
  if (reportDataSets.length > 1) {
    names = {
      [baseRange]: t('Base Range'),
      [compareRange]: t('Compare Range'),
    };
    colors = [getGraphColor(params), COMPARISON_COLOR];
  }

  return (
    <div
      className={styles['p-transition__body__graph']}
      style={{ height: '400px' }}
    >
      <ReservationReportBarChartPane
        data={combineData(data, dataComparison)}
        names={names}
        currencyCode={currencyCode}
        colors={colors}
      />
    </div>
  );
};
