import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import type { ThunkDispatch } from 'redux-thunk';
import clsx from 'clsx';

import { Input, FieldWrapper, Button } from 'client/components/Form';
import { updateReservation } from 'client/actions/reservations';
import { Modal } from 'client/components/Modal/Modal';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReservationPatch } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  reservation: ManifestReservationShape;
  trigger: React.ReactElement<'a' | 'button'>;
  header?: string;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps & I18nProps & ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
type State = {
  showModal: boolean;
  supplierInternalNotes: string;
};

class EditSupplierInternalNotesModalComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      supplierInternalNotes:
        this.props.reservation.supplier_internal_notes || '',
    };
  }

  //   componentDidUpdate(prevProps: Props) {
  //     if (prevProps.reservation !== this.props.reservation) {
  //       this.setState({
  //         reservation: this.props.reservation
  //       });
  //     }
  //   }
  render() {
    const { reservation, trigger, header, t } = this.props;
    const { supplierInternalNotes, showModal } = this.state;
    return (
      <Modal
        title={header ?? ''}
        trigger={trigger}
        open={showModal}
        onClose={() => {
          this.setState({
            showModal: false,
          });
        }}
        onOpen={() => {
          this.setState({
            showModal: true,
          });
        }}
      >
        <div className={clsx(pageStyles['page-reservations__modal'])}>
          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <FieldWrapper label={t('ID')}>{reservation.id}</FieldWrapper>
          </div>

          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <Input
              required
              label={t('Memo')}
              value={supplierInternalNotes || ''}
              onChange={(e, { value }) =>
                this.setState({
                  supplierInternalNotes: value,
                })
              }
            />
          </div>
        </div>
        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <Button
            style="gray"
            size="middle"
            onClick={() => {
              this.setState({
                supplierInternalNotes:
                  this.props.reservation.supplier_internal_notes ?? '',
              });
            }}
            disabled={
              this.state.supplierInternalNotes ===
              this.props.reservation.supplier_internal_notes
            }
          >
            {t('Discard Changes')}
          </Button>
          <Button
            style="green"
            size="middle"
            disabled={
              this.state.supplierInternalNotes ===
              this.props.reservation.supplier_internal_notes
            }
            onClick={() => {
              this.props.updateReservation(reservation.id, {
                supplier_internal_notes: supplierInternalNotes,
              });
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateReservation: (id: string, patch: ReservationPatch) =>
    dispatch(updateReservation(id, patch)),
});

export const EditSupplierInternalNotesModal = compose<Props, OwnProps>(
  connect(null, mapDispatchToProps),
  withTranslation()
)(EditSupplierInternalNotesModalComponent);
