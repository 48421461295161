import { DashboardGadget } from 'client/reducers/dashboardSettings';

import styles from './GadgetRow.module.css';
import { SortableGadget } from './SortableGadget';

const determineGadgetPositioning = (totalInRow: number, index: number) => {
  switch (totalInRow) {
    case 1:
      return 'middle';
    case 2:
      if (index === 0) {
        return 'left';
      } else {
        return 'right';
      }
    case 3:
      if (index === 0) {
        return 'left';
      } else if (index === 1) {
        return 'middle';
      } else {
        return 'right';
      }
    default:
      return 'middle';
  }
};

export const GadgetRow = ({ gadgets }: { gadgets: DashboardGadget[] }) => {
  return (
    <div className={styles['row']}>
      {gadgets.map((gadget, index) => {
        return (
          <SortableGadget
            key={index}
            gadget={gadget}
            positionInRow={determineGadgetPositioning(gadgets.length, index)}
          />
        );
      })}
    </div>
  );
};
