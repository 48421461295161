import { useTranslation } from 'react-i18next';

import { TranslateFuncType } from 'client/components/Translate';
import { JourneyAnalyticsSettings } from 'shared/models/swagger';

import styles from './ConditionGroupDisplay.module.css';

type ConditionGroup = Exclude<
  Exclude<
    JourneyAnalyticsSettings['presets'],
    undefined
  >[0]['condition_groups'],
  undefined
>[0];

const getTimePeriodText = (
  conditionGroup: ConditionGroup,
  t: TranslateFuncType
) => {
  switch (conditionGroup.date_range_preset) {
    case 'LAST_24_HOURS':
      return t('Last 24 Hours');
    case 'LAST_1_WEEK':
      return t('Last 1 Week');
    case 'LAST_4_WEEKS':
      return t('Last 4 Weeks');
    case 'CUSTOM':
      return `${conditionGroup.date_range?.start_date} - ${conditionGroup.date_range?.end_date}`;
  }
};

const getEventTypeText = (eventType: string, t: TranslateFuncType) => {
  switch (eventType) {
    case 'page-view':
      return t('Page View');
    case 'session-start':
      return t('Session Start');
    case 'check-availability':
      return t('Check Availability');
    case 'begin-checkout':
      return t('Add-to-Cart');
    case 'reservation-thank-you':
      return t('Conversion');
  }

  return '';
};

const getDimensionText = (
  conditionGroup: ConditionGroup,
  t: TranslateFuncType
) => {
  switch (conditionGroup.dimension_type) {
    case 'PAGE_VIEW_COUNT_GTE':
      return `${t('Page Views:')} ${t('{{pageViewLowerBound}} or more', {
        pageViewLowerBound:
          conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'PAGE_VIEW_COUNT_LT':
      return `${t('Page Views:')} ${t('Less than {{pageViewUpperBound}}', {
        pageViewUpperBound:
          conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'PAGE_VIEW_COUNT_BETWEEN':
      return `${t('Page Views:')} ${t(
        'Between {{pageViewLowerBound}} and {{pageViewUpperBound}}',
        {
          pageViewLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          pageViewUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
    case 'SESSION_COUNT_GTE':
      return `${t('Sessions:')} ${t('{{sessionLowerBound}} or more', {
        sessionLowerBound:
          conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'SESSION_COUNT_LT':
      return `${t('Sessions:')} ${t('Less than {{sessionUpperBound}}', {
        sessionUpperBound:
          conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'SESSION_COUNT_BETWEEN':
      return `${t('Sessions:')} ${t(
        'Between {{sessionLowerBound}} and {{sessionUpperBound}}',
        {
          sessionLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          sessionUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
    case 'TOTAL_TIME_SECONDS_GTE':
      return `${t('Time Spent:')} ${t('{{timeLowerBound}} or more', {
        timeLowerBound: conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'TOTAL_TIME_SECONDS_LT':
      return `${t('Time Spent:')} ${t('Less than {{timeUpperBound}}', {
        timeUpperBound: conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'TOTAL_TIME_SECONDS_BETWEEN':
      return `${t('Time Spent:')} ${t(
        'Between {{timeLowerBound}} and {{timeUpperBound}}',
        {
          timeLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          timeUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
  }

  return '';
};

interface Props {
  conditionGroup: ConditionGroup;
}

export const ConditionGroupDisplay = ({ conditionGroup }: Props) => {
  const { t } = useTranslation();

  return (
    <table className={styles['conditions-table']}>
      <tbody>
        <tr>
          <td>{t('Time Period')}</td>
          <td>{getTimePeriodText(conditionGroup, t)}</td>
        </tr>
        {(conditionGroup.referral_paths?.length ?? 0) > 0 && (
          <tr>
            <td>{t('Landing Sources')}</td>
            <td>{conditionGroup.referral_paths?.join('/')}</td>
          </tr>
        )}
        {(conditionGroup.event_types?.length ?? 0) > 0 && (
          <tr>
            <td>{t('Event Types')}</td>
            <td>
              {conditionGroup.event_types
                ?.map((event) => getEventTypeText(event, t))
                .join('/')}
            </td>
          </tr>
        )}
        {conditionGroup.url && (
          <tr>
            <td>{t('URL')}</td>
            <td>{conditionGroup.url}</td>
          </tr>
        )}
        {(conditionGroup.tags?.length ?? 0) > 0 && (
          <tr>
            <td>{t('Tags')}</td>
            <td>{conditionGroup.tags?.join('/')}</td>
          </tr>
        )}
        {conditionGroup.dimension_type && (
          <tr>
            <td>{t('Dimension')}</td>
            <td>{getDimensionText(conditionGroup, t)}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
