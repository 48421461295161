import _ from 'lodash';
import { useSelector } from 'react-redux';

import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import baseStyles from 'client/v3-base.module.css';
import { ReduxState } from 'client/reducers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { getReservationReportDataSum } from 'client/pages/v3/FlexibleDashboard/util';

interface Props {
  params: ReservationReportGadgetParams;
  reportDataSets: Swagger.ReservationReportDataSet[];
}

export const ReservationReportProductsTable = ({
  reportDataSets,
  params,
}: Props) => {
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const total = getReservationReportDataSum(
    reportDataSets?.[0].items ?? [],
    params.dataType
  );

  const grouped = _.groupBy(reportDataSets[0].items ?? [], (item) => {
    return item.product_id;
  });

  const productsById = allProducts.reduce(
    (acc: Record<string, Swagger.ProductSummary>, product) => {
      acc[product.id] = product;
      return acc;
    },
    {}
  );

  const productCounts: {
    productName: string;
    count: number;
    comparisonCount?: number;
  }[] = [];
  Object.keys(grouped).forEach((key) => {
    productCounts.push({
      productName: getDisplayProductName(productsById[key]) || key,
      count: getReservationReportDataSum(grouped[key], params.dataType),
    });
  });

  // Create comparison data
  let comparisonTotal = 0;
  if (reportDataSets.length > 1) {
    comparisonTotal = getReservationReportDataSum(
      reportDataSets?.[1].items ?? [],
      params.dataType
    );

    const grouped = _.groupBy(reportDataSets[1].items ?? [], (item) => {
      return item.product_id;
    });

    productCounts.forEach((productCount, key) => {
      const comparisonCount = getReservationReportDataSum(
        grouped[key],
        params.dataType
      );
      productCount.comparisonCount = comparisonCount;
    });
  }

  const sorted = _.sortBy(productCounts, (item) => -item.count);

  return (
    <div className={styles['p-transition__body__table']}>
      <table className={tableSmallStyles['c-tableSmall']}>
        <tbody>
          {sorted.slice(0, 5).map((item, index) => (
            <tr key={index}>
              <th className={baseStyles['u-width-144']}>{item.productName}</th>
              <td>
                {item.count.toLocaleString()} (
                {((item.count / total) * 100).toFixed(0)}%)
                {reportDataSets.length > 1 &&
                  item.comparisonCount !== undefined && (
                    <span>
                      {item.comparisonCount.toLocaleString()} ( (
                      {((item.comparisonCount / comparisonTotal) * 100).toFixed(
                        0
                      )}
                      %)
                    </span>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
