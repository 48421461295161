import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_RESTAURANT_FAILURE,
  CREATE_RESTAURANT_REQUEST,
  CREATE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  FETCH_RESTAURANTS_FAILURE,
  FETCH_RESTAURANTS_REQUEST,
  FETCH_RESTAURANTS_SUCCESS,
  FETCH_RESTAURANT_FAILURE,
  FETCH_RESTAURANT_REQUEST,
  FETCH_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_FAILURE,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  Restaurant,
  CreateRestaurantResponse,
  ListRestaurantsResponse,
  UpdateRestaurantResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESTAURANTS_FAILURE:
    case FETCH_RESTAURANT_FAILURE:
    case CREATE_RESTAURANT_FAILURE:
    case UPDATE_RESTAURANT_FAILURE:
    case DELETE_RESTAURANT_FAILURE:
      return action.error;

    case FETCH_RESTAURANTS_SUCCESS:
    case FETCH_RESTAURANT_SUCCESS:
    case CREATE_RESTAURANT_SUCCESS:
    case UPDATE_RESTAURANT_SUCCESS:
    case DELETE_RESTAURANT_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_RESTAURANTS_REQUEST:
    case FETCH_RESTAURANT_REQUEST:
    case CREATE_RESTAURANT_REQUEST:
    case UPDATE_RESTAURANT_REQUEST:
    case DELETE_RESTAURANT_REQUEST:
      return true;

    case FETCH_RESTAURANTS_FAILURE:
    case FETCH_RESTAURANT_FAILURE:
    case CREATE_RESTAURANT_FAILURE:
    case UPDATE_RESTAURANT_FAILURE:
    case DELETE_RESTAURANT_FAILURE:
    case FETCH_RESTAURANTS_SUCCESS:
    case FETCH_RESTAURANT_SUCCESS:
    case CREATE_RESTAURANT_SUCCESS:
    case UPDATE_RESTAURANT_SUCCESS:
    case DELETE_RESTAURANT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: Restaurant[] = [], action: any): Restaurant[] => {
  switch (action.type) {
    case FETCH_RESTAURANTS_SUCCESS: {
      const response = action.response as ListRestaurantsResponse;
      return response.restaurants;
    }

    case FETCH_RESTAURANT_SUCCESS: {
      const response = action.response as Restaurant;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_RESTAURANT_SUCCESS: {
      const response = action.response as CreateRestaurantResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_RESTAURANT_SUCCESS: {
      const response = action.response as UpdateRestaurantResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_RESTAURANT_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const restaurants = combineReducers({
  error,
  loading,
  all,
});
