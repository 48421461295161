import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Radio } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ProductSummary, PartnershipProduct } from 'shared/models/swagger';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';

const StatusCell = ({
  value,
  onChange,
}: {
  value: 'SALE' | 'DISCONTINUED' | undefined;
  onChange: (value: 'SALE' | 'DISCONTINUED') => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box display="flex">
      <Radio
        label={t('On Sale')}
        checked={value === 'SALE'}
        onChange={() => {
          onChange('SALE');
        }}
      />
      <Radio
        label={t('Discontinued')}
        checked={value === 'DISCONTINUED'}
        onChange={() => {
          onChange('DISCONTINUED');
        }}
      />
    </Box>
  );
};
interface ProductItem {
  product_id: string;
  product_name: string;
  status: 'SALE' | 'DISCONTINUED';
}

interface Props {
  supplierId: string;
  products: ProductSummary[];
  open: boolean;
  onClose: () => void;
  onSubmitted: (arg: PartnershipProduct[]) => void;
}

const useColumns = ({
  productStatuses,
  setProductStatuses,
}: {
  productStatuses: { [index: string]: 'SALE' | 'DISCONTINUED' };
  setProductStatuses: (statuses: {
    [index: string]: 'SALE' | 'DISCONTINUED';
  }) => void;
}): ColumnType<ProductItem>[] => {
  const { t } = useTranslation();
  return [
    {
      Header: t('Product Name'),
      id: 'product_name',
      accessor: (row) => row.product_name,
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => (
        <StatusCell
          value={productStatuses[row.product_id]}
          onChange={(value) => {
            setProductStatuses({
              ...productStatuses,
              [row.product_id]: value,
            });
          }}
        />
      ),
    },
  ];
};

export const EditPartnershipSupplierProductsModal = ({
  supplierId,
  products,
  open,
  onClose,
  onSubmitted,
}: Props) => {
  const { t } = useTranslation();

  const [productStatuses, setProductStatuses] = React.useState<{
    [index: string]: 'SALE' | 'DISCONTINUED';
  }>({});
  const [rowCount, setRowCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    const supplierProducts =
      activeUserOrganization?.partnership_settings?.supplier_products || [];

    const products =
      supplierProducts.find((s) => s.supplier_id === supplierId)?.products ||
      [];

    const currentProductStatuses: { [index: string]: 'SALE' | 'DISCONTINUED' } =
      {};
    products.forEach((product) => {
      currentProductStatuses[product.product_id ?? ''] =
        product.status === 'SALE' ? 'SALE' : 'DISCONTINUED';
    });
    setProductStatuses(currentProductStatuses);
  }, [supplierId, activeUserOrganization]);

  const productItems: ProductItem[] = products
    .filter((product) => product.supplier_id === supplierId)
    .map((product) => ({
      product_id: product.id ?? '',
      product_name: product.product_name ?? '',
      status: productStatuses[product.id ?? ''] || 'SALE',
    }))
    .sort((a, b) => {
      if (a.product_name === b.product_name) {
        return 0;
      }
      if (!a.product_name) {
        return 1;
      }
      if (!b.product_name) {
        return -1;
      }
      return a.product_name.localeCompare(b.product_name);
    });

  const columns = useColumns({
    productStatuses,
    setProductStatuses,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit Partnership Supplier Products"
    >
      <Modal.Content>
        <GenericTable<ProductItem>
          items={(productItems ?? []).slice(
            (currentPage - 1) * rowCount,
            currentPage * rowCount
          )}
          totalCount={(productItems ?? []).length}
          columns={columns}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Modal.Actions>
          <Button.Cancel
            onClick={() => {
              onClose();
            }}
          >
            {t('Close')}
          </Button.Cancel>
          <Button.Submit
            onClick={() => {
              onSubmitted(
                Object.keys(productStatuses).map((productId) => ({
                  product_id: productId,
                  status: productStatuses[productId],
                })) ?? []
              );
              onClose();
            }}
          >
            {t('Save')}
          </Button.Submit>
        </Modal.Actions>
      </Modal.Actions>
    </Modal>
  );
};
