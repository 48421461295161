import * as React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';
import {
  activeUserOrganizationSelector,
  impersonatedAccountSelector,
} from 'client/reducers/user';
import { Button } from 'client/components/Form/Button';
import type { ReduxState } from 'client/reducers';
import { SiteTemplateTutorialCompletePopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/SiteTemplateTutorialCompletePopUp';
import { ReservationTutorialPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ReservationTutorialPopUp';
import { TutorialInformationPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/TutorialInformationPopUp';
import { updateOrganization } from 'client/actions/organizations';
import * as Swagger from 'shared/models/swagger';
import iconStep1 from 'client/images/ic_tutorial_step1.svg';
import iconStep2 from 'client/images/ic_tutorial_step2.svg';
import iconStep3 from 'client/images/ic_tutorial_step3.svg';

import styles from './Tutorial.module.css';

type TutorialStage = 0 | 1 | 2;

const COPY_SITE_TEMPLATE: TutorialStage = 0;
const TEST_RESERVATION: TutorialStage = 1;
const PRODUCT_EDIT: TutorialStage = 2;

export const NewTutorial = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const [activeStep, setActiveStep] =
    React.useState<TutorialStage>(COPY_SITE_TEMPLATE);

  const [siteSettingPopUp, setSiteSettingPopUp] = React.useState<boolean>(
    activeStep === TEST_RESERVATION ? true : false
  );
  const [reservationPopUp, setReservationPopUp] =
    React.useState<boolean>(false);
  const [tutorialInformationPopUp, setTutorialInformationPopUp] =
    React.useState<boolean>(false);
  const [reservationCheckLoading, setReservationCheckLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    activeStepHundling();
  }, [activeUserOrganization?.tutorial_stage]);

  const activeStepHundling = () => {
    switch (activeUserOrganization?.tutorial_stage) {
      case 'PRODUCT_EDIT_BASIC_INFO':
      case 'PRODUCT_EDIT_RESERVATION_INFO':
      case 'PRODUCT_EDIT_DETAILED_INFO':
      case 'SITE_SETTINGS':
        setActiveStep(PRODUCT_EDIT);
        break;
      case 'TEST_RESERVATION':
        setActiveStep(TEST_RESERVATION);
        break;
      case 'COPY_SITE_TEMPLATE':
        setActiveStep(COPY_SITE_TEMPLATE);
        setTutorialInformationPopUp(true);
        break;
      case 'COPY_SITE_TEMPLATE_COMPLETED':
        setActiveStep(TEST_RESERVATION);
        setSiteSettingPopUp(true);
        break;
    }
  };

  const onClickFunc = async () => {
    await axios
      .get('/api/reservations', {
        params: {
          is_ascending: false,
          page_size: 1,
        },
        headers: {
          authorization: `Bearer ${token}`,
          impersonate: impersonatedAccount
            ? buildImpersonateToken(impersonatedAccount)
            : '',
        },
      })
      .then((result) => {
        (async () => {
          const resp: Swagger.ListReservationsResponse = result.data;
          if ((resp?.reservations?.length ?? 0) > 0) {
            await dispatch(
              updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
                tutorial_stage: 'PRODUCT_EDIT_BASIC_INFO',
                tutorial_product_id: resp?.reservations?.[0]?.product_id,
              })
            );
          } else {
            await setReservationPopUp(true);
          }
        })();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={styles['page-home-tutorial']}>
        <div className={styles['page-home-tutorial__ttl']}>
          {activeStep === COPY_SITE_TEMPLATE && (
            <p>{t('3 steps to launch website')}</p>
          )}
          {activeStep === TEST_RESERVATION && (
            <p>{t('2 steps to launch website')}</p>
          )}
          {activeStep === PRODUCT_EDIT && (
            <p>{t('1 step to launch website')}</p>
          )}
          <p>{activeStep} / 3</p>
        </div>
        <ul className={styles['page-home-tutorial__list']}>
          <li>
            <div
              className={clsx(
                styles['page-tutorial__box'],
                activeStep === COPY_SITE_TEMPLATE && styles['is-active'],
                activeStep > COPY_SITE_TEMPLATE && styles['is-finish']
              )}
              data-num="1"
            >
              <div className={styles['page-tutorial__box__inner']}>
                <div className={styles['page-tutorial__box__pic']}>
                  <img src={iconStep3} />
                </div>
                <div className={styles['page-tutorial__box__body']}>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    {t('Create Website')}
                  </p>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    ({t('1 min')})
                  </p>
                  <div className={styles['page-tutorial__box__body__btn']}>
                    <Link to="/home/tutorial/sitetemplate">
                      <Button
                        size="small"
                        style="yellow"
                        disabled={activeStep !== COPY_SITE_TEMPLATE}
                      >
                        {t('NEXT')}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div
              className={clsx(
                styles['page-tutorial__box'],
                activeStep === TEST_RESERVATION && styles['is-active'],
                activeStep > TEST_RESERVATION && styles['is-finish']
              )}
              data-num="2"
            >
              <div className={styles['page-tutorial__box__inner']}>
                <div className={styles['page-tutorial__box__pic']}>
                  <img src={iconStep2} />
                </div>
                <div className={styles['page-tutorial__box__body']}>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    {t('Test Booking')}
                  </p>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    ({t('2 min')})
                  </p>
                  <div className={styles['page-tutorial__box__body__btn']}>
                    <Button
                      size="small"
                      style="yellow"
                      loading={reservationCheckLoading || loading}
                      disabled={activeStep !== TEST_RESERVATION}
                      onClick={async () => {
                        await setReservationCheckLoading(true);
                        await onClickFunc();
                        setReservationCheckLoading(false);
                      }}
                    >
                      {t('NEXT')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div
              className={clsx(
                styles['page-tutorial__box'],
                activeStep === PRODUCT_EDIT && styles['is-active'],
                activeStep > PRODUCT_EDIT && styles['is-finish']
              )}
              data-num="3"
            >
              <div className={styles['page-tutorial__box__inner']}>
                <div className={styles['page-tutorial__box__pic']}>
                  <img src={iconStep1} />
                </div>
                <div className={styles['page-tutorial__box__body']}>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    <p>{t('Product & Settings')}</p>
                  </p>
                  <p className={styles['page-tutorial__box__body__ttl']}>
                    <p>({t('2 min')})</p>
                  </p>
                  <div className={styles['page-tutorial__box__body__btn']}>
                    <Link to="/home/tutorial/product">
                      <Button
                        size="small"
                        style="yellow"
                        disabled={activeStep !== PRODUCT_EDIT}
                      >
                        {t('NEXT')}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {siteSettingPopUp && (
        <SiteTemplateTutorialCompletePopUp
          popUpDisable={() => {
            dispatch(
              updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
                tutorial_stage: 'TEST_RESERVATION',
              })
            );
            setSiteSettingPopUp(false);
          }}
        />
      )}
      {reservationPopUp && (
        <ReservationTutorialPopUp
          popUpDisable={() => setReservationPopUp(false)}
        />
      )}
      {tutorialInformationPopUp && (
        <TutorialInformationPopUp
          popUpDisable={() => setTutorialInformationPopUp(false)}
        />
      )}
    </>
  );
};
