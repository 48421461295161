import closeIcon from 'client/images/ic_close.svg';
import printIcon from 'client/images/ic_print.svg';

interface Props {
  isClosed: boolean;
  capacity: number;
  occupied: number;
  isCheckedIn: boolean;
  hide: boolean;
  label: string;
  isTopLeftCorner: boolean;
  isTicketPrinted: boolean;
}

export const MapCellView = ({
  isClosed,
  isCheckedIn,
  label,
  isTopLeftCorner,
  isTicketPrinted,
}: Props) => {
  if (isClosed) {
    return <img src={closeIcon} alt="closed" />;
  }

  return (
    <>
      {isTopLeftCorner && isTicketPrinted && (
        <p>
          <img src={printIcon} alt="printed" />
        </p>
      )}
      <span>
        {isCheckedIn && <i></i>}
        {label}
      </span>
    </>
  );
};
