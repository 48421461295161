import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_E_TICKET_LOCATION_FAILURE,
  CREATE_E_TICKET_LOCATION_REQUEST,
  CREATE_E_TICKET_LOCATION_SUCCESS,
  DELETE_E_TICKET_LOCATION_FAILURE,
  DELETE_E_TICKET_LOCATION_REQUEST,
  DELETE_E_TICKET_LOCATION_SUCCESS,
  FETCH_E_TICKET_LOCATIONS_FAILURE,
  FETCH_E_TICKET_LOCATIONS_REQUEST,
  FETCH_E_TICKET_LOCATIONS_SUCCESS,
  UPDATE_E_TICKET_LOCATION_FAILURE,
  UPDATE_E_TICKET_LOCATION_REQUEST,
  UPDATE_E_TICKET_LOCATION_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  ETicketLocation,
  CreateETicketLocationResponse,
  ListETicketLocationsResponse,
  UpdateETicketLocationResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATIONS_FAILURE:
    case CREATE_E_TICKET_LOCATION_FAILURE:
    case UPDATE_E_TICKET_LOCATION_FAILURE:
    case DELETE_E_TICKET_LOCATION_FAILURE:
      return action.error;

    case FETCH_E_TICKET_LOCATIONS_SUCCESS:
    case CREATE_E_TICKET_LOCATION_SUCCESS:
    case UPDATE_E_TICKET_LOCATION_SUCCESS:
    case DELETE_E_TICKET_LOCATION_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATIONS_REQUEST:
    case CREATE_E_TICKET_LOCATION_REQUEST:
    case UPDATE_E_TICKET_LOCATION_REQUEST:
    case DELETE_E_TICKET_LOCATION_REQUEST:
      return true;

    case FETCH_E_TICKET_LOCATIONS_FAILURE:
    case CREATE_E_TICKET_LOCATION_FAILURE:
    case UPDATE_E_TICKET_LOCATION_FAILURE:
    case DELETE_E_TICKET_LOCATION_FAILURE:
    case FETCH_E_TICKET_LOCATIONS_SUCCESS:
    case CREATE_E_TICKET_LOCATION_SUCCESS:
    case UPDATE_E_TICKET_LOCATION_SUCCESS:
    case DELETE_E_TICKET_LOCATION_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: ETicketLocation[] = [], action: any): ETicketLocation[] => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATIONS_SUCCESS: {
      const response = action.response as ListETicketLocationsResponse;
      return response.e_ticket_locations ?? [];
    }

    case CREATE_E_TICKET_LOCATION_SUCCESS: {
      const response = action.response as CreateETicketLocationResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_E_TICKET_LOCATION_SUCCESS: {
      const response = action.response as UpdateETicketLocationResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_E_TICKET_LOCATION_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const eTicketLocations = combineReducers({
  error,
  loading,
  all,
});
