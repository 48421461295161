import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import type { Account, GuideSettings } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';

import { EditGuideReservationInfoModal } from './EditGuideReservationInfoModal';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
};
export const GuideReservationInfo = ({ activeUser, guideSettings }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <>
      <div>
        <strong>
          {t(
            'Register reservation details to be displayed on schedule page and the notification emails.'
          )}
        </strong>
      </div>
      {(guideSettings?.columns || [])
        .map((column) => getColumnHeaderText(column, t))
        .join(',')}
      <Button
        color="white"
        size="sm"
        iconAfterText={<i className="c-icon-outline-general-edit-05"></i>}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <EditGuideReservationInfoModal
          activeUser={activeUser}
          guideSettings={guideSettings}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};
