import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import {
  setActimReservationRowCount,
  setActimReservationCurrentPage,
} from 'client/actions/actimReservationTableControls';
import type { ColumnType } from 'client/libraries/util/getActimReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ActimReservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  actimReservations: ActimReservation[];
  visibleColumns: ColumnType[];
};

const calcWidth = (width: string | typeof undefined): string => {
  if (width) return width;
  return 'short';
};

export const ActimReservationCustomTable = ({
  actimReservations,
  visibleColumns,
}: Props) => {
  const { t } = useTranslation();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const rowCount = useSelector(
    (state: ReduxState) => state.actimReservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.actimReservationTableControls.currentPage
  );

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    dispatch(setActimReservationRowCount(count));
    dispatch(setActimReservationCurrentPage(1));
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (actimReservations.length < rowCount * page) {
      page = Math.floor(actimReservations.length / rowCount);
    }

    dispatch(setActimReservationCurrentPage(page));
  };

  React.useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);
  const pageCount =
    Math.floor(actimReservations.length / rowCount) +
    (actimReservations.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      dispatch(setActimReservationCurrentPage(currentPage + 1));
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      dispatch(setActimReservationCurrentPage(currentPage - 1));
    }
  };

  const [showActimReservationColumns, setShowActimReservationColumns] =
    React.useState([...Array(actimReservations.length)].map(() => false));

  return (
    <>
      <div className={clsx(componentStyles['c-table-main'])}>
        <div
          className={clsx(componentStyles['c-table-main__thead'])}
          ref={tableHeaderRef}
        >
          <table>
            <tbody>
              <tr>
                {visibleColumns.map((visibleColumn, idx) => {
                  const width = calcWidth(visibleColumn.width);
                  return (
                    <>
                      <th
                        className={clsx(baseStyles['base-r-t-' + width])}
                        key={idx}
                      >
                        {visibleColumn.Header}
                      </th>
                    </>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={clsx(componentStyles['c-table-main__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table>
            <tbody>
              {actimReservations
                .filter((reservation, index) => {
                  if (index < rowHead) {
                    return false;
                  }

                  if (rowHead + rowCount <= index) {
                    return false;
                  }

                  return true;
                })
                .map((reservation, ridx) => {
                  return (
                    <tr key={ridx}>
                      {visibleColumns.map((visibleColumn, idx) => {
                        const width = calcWidth(visibleColumn.width);
                        let displayValue;

                        if (typeof visibleColumn.accessor === 'string') {
                          displayValue = reservation[visibleColumn.accessor];
                        } else if (
                          typeof visibleColumn.accessor === 'function'
                        ) {
                          displayValue = visibleColumn.accessor(reservation);
                        } else {
                          displayValue = '';
                        }

                        if (visibleColumn.Cell) {
                          const cell = {
                            value: displayValue,
                            original: reservation,
                          };
                          displayValue = visibleColumn.Cell(cell);
                        }

                        if (visibleColumn.th) {
                          return (
                            <th
                              className={clsx(baseStyles['base-r-t-' + width])}
                              key={idx}
                            >
                              {displayValue}
                            </th>
                          );
                        } else {
                          return (
                            <td
                              key={idx}
                              className={clsx(
                                baseStyles['base-r-t-' + width],
                                6 < idx
                                  ? showActimReservationColumns[ridx]
                                    ? ''
                                    : baseStyles['base-t-spHidden']
                                  : ''
                              )}
                              data-text={visibleColumn.Header}
                            >
                              {displayValue}
                            </td>
                          );
                        }
                      })}
                      <td className={baseStyles['base-t-spShow']}>
                        <a
                          className={clsx(
                            componentStyles['c-table-main__tbody__spMore'],
                            showActimReservationColumns[ridx]
                              ? componentStyles['arrowUp']
                              : ''
                          )}
                          onClick={() => {
                            const newData = [...showActimReservationColumns];
                            newData[ridx] = !newData[ridx];
                            setShowActimReservationColumns(newData);
                          }}
                        >
                          {showActimReservationColumns[ridx]
                            ? t('Close')
                            : t('See more')}
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['prev']
          )}
          onClick={() => {
            scrollButtonClickHandler('prev');
          }}
        >
          <p></p>
        </div>
        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['next']
          )}
          onClick={() => {
            scrollButtonClickHandler('next');
          }}
        >
          <p></p>
        </div>
      </div>

      <div className={clsx(componentStyles['c-pagination'])}>
        <div className={clsx(componentStyles['c-pagination__size'])}>
          <p>{t('Number of lines')}:</p>
          <label>
            <select value={rowCount} onChange={rowCountChangeHandler}>
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
        <div className={clsx(componentStyles['c-pagination__jump'])}>
          <p>{t('Page')}:</p>
          <input
            type="number"
            value={currentPage}
            onChange={currentPageHandler}
          />
          <p>of {pageCount}</p>
        </div>
        <div className={clsx(componentStyles['c-pagination__btns'])}>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              1 < currentPage ? '' : componentStyles['disable']
            )}
            onClick={pageBackClickHandler}
          >
            {t('Previous')}
          </a>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              currentPage < pageCount ? '' : componentStyles['disable']
            )}
            onClick={pageForwardClickHandler}
          >
            {t('Next')}
          </a>
        </div>
      </div>
    </>
  );
};
