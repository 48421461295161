import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { AccessSummaryGadgetParams } from 'client/reducers/dashboardSettings';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import { useAccessReportData } from 'client/pages/v3/FlexibleDashboard/useAccessReportData';
import { ModalLoader } from 'client/components/ModalLoader';
import { AccessReportSummaryTable } from 'client/pages/v3/FlexibleDashboard/AccessSummaryGadget/AccessReportSummaryTable';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';

interface Props {
  params: AccessSummaryGadgetParams;
}

type DateRange = {
  start: string;
  end: string;
};

export const AccessSummaryGadget = ({ params }: Props) => {
  const { t } = useTranslation();

  const [baseDateRange, setBaseDateRange] = useState<DateRange>(
    getDateRangeForPreset(params.dateRange)
  );
  const [comparisonDateRange, setComparisonDateRange] =
    useState<DateRange | null>(null);

  const { data, isLoading } = useAccessReportData(
    baseDateRange,
    params,
    comparisonDateRange
  );

  // Update when params changed
  useEffect(() => {
    setBaseDateRange(getDateRangeForPreset(params.dateRange));
  }, [params]);

  let dataToDisplay = null;
  if (isLoading) {
    dataToDisplay = <ModalLoader />;
  } else {
    if (data === null || (data && data[0].items?.length === 0)) {
      dataToDisplay = (
        <div>{t('No data to display for the selected data set')}</div>
      );
    } else {
      dataToDisplay = (
        <AccessReportSummaryTable
          reportDataSets={data}
          shouldShowComparison={Boolean(comparisonDateRange)}
        />
      );
    }
  }

  return (
    <>
      <div className={styles['p-transition']}>
        <ComparisonDateInput
          baseDateRange={baseDateRange}
          setBaseDateRange={setBaseDateRange}
          comparisonDateRange={comparisonDateRange}
          setComparisonDateRange={setComparisonDateRange}
          menuStyle={{ right: 'auto', left: 0 }}
        />
        <div className={styles['p-transition__body']}>{dataToDisplay}</div>
      </div>
    </>
  );
};
