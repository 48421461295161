import { DigitalMapAccessReportHeader } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReportHeader';
import { DigitalMapAccessReportSettingsBlock } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReportSettingsBlock';
import { DigitalMapAccessReportGraphBlock } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReportGraphBlock';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const DigitalMapAccessReport = () => {
  return (
    <V3Page>
      <DigitalMapAccessReportHeader />
      <DigitalMapAccessReportSettingsBlock />
      <DigitalMapAccessReportGraphBlock />
    </V3Page>
  );
};
