// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectAutoComplete } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import styles from 'client/components/AlgoliaSearchForm/AlgoliaSearchForm.module.css';
import { config } from 'client/config';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

const searchClient = algoliasearch(
  config.algoliaConfig.applicationId,
  config.algoliaConfig.searchOnlyApiKey
);

export const AlgoliaSearchForm = () => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const isAgent = activeUserOrganization?.type === 'AGENT';
  const selectedLanguage = useSelector(
    (state: ReduxState) => state.language.selected
  );
  const indexName = `${config.algoliaConfig.indexPrefix}-help-${
    selectedLanguage.iso === 'en' ? 'en' : 'ja'
  }${isAgent ? '-agent' : ''}`;
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <CustomAutocomplete />
    </InstantSearch>
  );
};

const Autocomplete = ({ hits, currentRefinement, refine }) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const areaRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: SyntheticEvent<HTMLElement>) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });

    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOption]);

  const { t } = useTranslation();
  return (
    <div className={baseStyles['base-form-box__body']}>
      <div
        className={clsx(
          componentStyles['c-form-single'],
          styles['search__form']
        )}
        ref={areaRef}
      >
        <label
          className={clsx(
            componentStyles['select'],
            styles['search__form__label'],
            showOption
              ? hits.length > 0
                ? componentStyles['is-active']
                : ''
              : ''
          )}
        >
          <input
            type="search"
            placeholder={t('Search within the help page')}
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            onClick={() => {
              setShowOption(true);
            }}
          />
        </label>
        <ul className={clsx(componentStyles['option'])}>
          {hits.map((hit, idx) => {
            return (
              <Link
                key={idx}
                target="_blank"
                to={`/helps/${
                  hit._key.split('/').slice(1).join('/').split('.')[0]
                }`}
              >
                <li key={idx} data-value={hit.objectID} data-idx={idx}>
                  <div className={clsx(styles['suggest__option'])}>
                    <div className={clsx(styles['suggest__option__title'])}>
                      {hit.title || 'NO TITLE'}
                    </div>
                    <div className={clsx(styles['suggest__option__tag_area'])}>
                      {hit._tags?.map((tag, idx) => {
                        return (
                          <span
                            key={idx}
                            className={clsx(styles['suggest__option__tag'])}
                          >
                            #{tag}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const CustomAutocomplete = connectAutoComplete(Autocomplete);
