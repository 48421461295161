import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { getFieldOptions } from 'client/libraries/util/coreutil';
import { getKnownFieldPrompt } from 'client/libraries/util/getKnownFieldPrompt';
import { FormField } from 'client/components/v3/Form/FormField/FormField';
import { Box } from 'client/components/Box/Box';
import type { Field } from 'shared/models/swagger';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { Button } from 'client/components/v3/Common/Button';

import styles from './FieldsForm.module.css';

type Props = {
  fields: Field[];
  unmappedFieldResponses?: Record<string, any>[];
  getFieldValue: (arg0: string) => string;
  getFieldLeaveBlankValue?: (arg0: string) => boolean;
  onFieldChange: (id: string, value: string, leaveBlank?: boolean) => void;
  errorMap: Record<string, string>;
  mode?: 'READ_ONLY' | 'TOGGLE' | 'INPUT';
  showLeaveBlankBox?: boolean;
  requiredFieldOptionalEntry?: boolean;
  type?: 'BOOK' | 'EDIT';
};

export const FieldsForm = ({
  errorMap = {},
  fields,
  unmappedFieldResponses,
  getFieldValue,
  getFieldLeaveBlankValue,
  mode = 'TOGGLE',
  showLeaveBlankBox,
  requiredFieldOptionalEntry,
  onFieldChange,
}: Props) => {
  const [editKey, setEditKey] = useState<string>('');
  const { t } = useTranslation();
  return (
    <div className={styles['c-fieldsForm']}>
      {fields.map((f, idx) =>
        (_.isEqual(editKey, f.key) && mode === 'TOGGLE') || mode === 'INPUT' ? (
          <>
            <FormField
              required={
                f.required !== 'OPTIONAL' && !requiredFieldOptionalEntry
              }
              error={errorMap[f.key || '']}
              key={f.key}
              options={getFieldOptions(f)}
              format={f.format}
              prompt={f.prompt}
              textArea={f.input_element === 'TEXT_AREA'}
              value={getFieldValue(f.key || '') || ''}
              onChange={(value) => onFieldChange(f.key || '', value)}
              onBlur={() => setEditKey('')}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  // ENTER
                  e.preventDefault();
                  setEditKey('');
                }
              }}
            />
            {showLeaveBlankBox && f.required !== 'OPTIONAL' && (
              <Box mt={1} mb={2}>
                <Checkbox
                  label={t('Leave Blank')}
                  onChange={() => {
                    const key = f.key || '';
                    const currentLeaveBlank = getFieldLeaveBlankValue?.(key);

                    onFieldChange(key, '', !currentLeaveBlank);
                  }}
                  checked={getFieldLeaveBlankValue?.(f.key || '') || false}
                />
              </Box>
            )}
          </>
        ) : (
          <div key={idx}>
            <p className={styles['c-fieldsForm__ttl']}>{f.prompt}</p>
            <span className={styles['c-fieldsForm__item']}>
              <p>{getFieldValue(f.key || '') || '---'}</p>
              {mode !== 'READ_ONLY' && (
                <Button
                  size="icon"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                  onClick={() => setEditKey(f.key || '')}
                />
              )}
            </span>
          </div>
        )
      )}
      {mode !== 'INPUT' &&
        mode !== 'TOGGLE' &&
        unmappedFieldResponses &&
        unmappedFieldResponses.map((field_response, idx) => (
          <div key={idx}>
            <p className={styles['c-fieldsForm__ttl']}>
              {getKnownFieldPrompt(field_response.key, t) || field_response.key}
            </p>
            <span>{field_response.response}</span>
          </div>
        ))}
    </div>
  );
};
