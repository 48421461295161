import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from 'client/components/v3/Common/Button';
import { Pagination } from 'client/components/v3/Pagination/Pagination';

import styles from './ProductListTable.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
  shouldOmitMobileFirstChildStyles?: boolean;
  hideSeeMoreButton?: boolean;
  tableHeaderRef: React.RefObject<HTMLDivElement>;
  tableBodyRef: React.RefObject<HTMLDivElement>;
};

export type CustomTableColumn = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  accessor?: string | ((arg0: any) => string);
  width?: string;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  id?: string;
};

export const calcWidthToPx = (width: string | typeof undefined): number => {
  if (width === 'short') return 100;
  if (width === 'middle') return 140;
  if (width === 'long') return 200;
  if (width === 'longx2') return 320;

  return 60;
};

export const CustomTable = ({
  items,
  columns,
  tableHeaderRef,
  tableBodyRef,
}: Props) => {
  const { t } = useTranslation();

  const [showReservationColumns, setShowReservationColumns] = React.useState(
    [...Array(items)].map(() => false)
  );

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = 10;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [items, rowCount]);
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  // If columns ordering has changed and operate column is placed first,
  // modify position surgically so that menu is not hidden by position sticky
  React.useEffect(() => {
    if (columns.length > 0 && columns[0].id === 'delete/copy') {
      const ulElements = document.querySelectorAll<HTMLUListElement>(
        '[class*="p-primary__select__menu"]'
      );
      if (ulElements) {
        ulElements.forEach((ulElement) => {
          ulElement.style.position = 'relative';
        });
      }
    }
  }, [columns]);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className={styles['p-reservations__body']}>
        <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
          <div
            className={clsx(
              styles['c-table'],
              styles['thead'],
              styles['sticky-top']
            )}
            ref={tableHeaderRef}
          >
            <table className={styles['c-table__body']}>
              <thead>
                <tr>
                  {columns.map((visibleColumn, idx) => {
                    const width = calcWidthToPx(visibleColumn.width);

                    if (idx == 0) {
                      return (
                        <th
                          className={clsx(
                            styles['thead__th'],
                            styles['sticky-top'],
                            styles['sticky-left']
                          )}
                          style={{
                            width: `${width}px`,
                          }}
                          key={idx}
                        >
                          {visibleColumn.Header}
                        </th>
                      );
                    }
                    return (
                      <th
                        className={clsx(styles['thead__th'])}
                        style={{
                          width: `${width}px`,
                        }}
                        key={idx}
                      >
                        {visibleColumn.Header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
          </div>

          <div
            className={clsx(styles['c-table'], styles['tbody'])}
            onScroll={scrollHandler}
            ref={tableBodyRef}
          >
            <table className={styles['c-table__body']}>
              <tbody>
                {items
                  .filter((_, idx) => {
                    return rowHead <= idx && idx < rowHead + rowCount;
                  })
                  .map((item, ridx) => {
                    return (
                      <tr key={ridx}>
                        {columns.map((visibleColumn, idx) => {
                          const width = calcWidthToPx(visibleColumn.width);
                          let displayValue;

                          if (typeof visibleColumn.accessor === 'string') {
                            displayValue = item[visibleColumn.accessor];
                          } else if (
                            typeof visibleColumn.accessor === 'function'
                          ) {
                            displayValue = visibleColumn.accessor(item);
                          } else {
                            displayValue = '';
                          }

                          if (visibleColumn.Cell) {
                            const cell = {
                              value: displayValue,
                              original: item,
                            };
                            displayValue = visibleColumn.Cell(cell);
                          }

                          // First index special treatment, checkbox for bulk cancel/decline + application number
                          if (idx == 0) {
                            return (
                              <td
                                className={clsx(
                                  styles['tbody__td'],
                                  styles['sticky-left'],
                                  styles['c-table__spHeader']
                                )}
                                style={{
                                  width: `${width}px`,
                                }}
                                key={idx}
                              >
                                {displayValue}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={idx}
                              className={clsx(
                                styles['tbody__td'],
                                6 < idx
                                  ? showReservationColumns[ridx]
                                    ? ''
                                    : styles['base-t-spHidden']
                                  : ''
                              )}
                              style={{
                                width: `${width}px`,
                              }}
                              data-text={visibleColumn.Header}
                            >
                              {displayValue}
                            </td>
                          );
                        })}

                        {/* This section is only for mobile */}
                        <td
                          className={clsx(
                            styles['c-table__detail'],
                            styles['c-table__sp'],
                            styles['tbody__td']
                          )}
                        >
                          <Button
                            text={
                              showReservationColumns[ridx]
                                ? t('Close')
                                : t('See more')
                            }
                            uiType="bg"
                            size="md"
                            color="tertiarycolor"
                            onClick={() => {
                              const newData = [...showReservationColumns];
                              newData[ridx] = !newData[ridx];
                              setShowReservationColumns(newData);
                            }}
                            iconAfterText={
                              showReservationColumns[ridx] ? (
                                <i className="c-icon-solid-arrows-chevron-up"></i>
                              ) : (
                                <i className="c-icon-solid-arrows-chevron-down"></i>
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {/* Prev and next side buttons */}
          <div
            className={clsx(styles['c-tableBtn'], styles['left'])}
            onClick={() => {
              scrollButtonClickHandler('prev');
            }}
          >
            <a>
              <i className="c-icon-solid-arrows-chevron-left"></i>
            </a>
          </div>
          <div
            className={clsx(styles['c-tableBtn'], styles['right'])}
            onClick={() => {
              scrollButtonClickHandler('next');
            }}
          >
            <a>
              <i className="c-icon-solid-arrows-chevron-left"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Pagination & no of items displayed selection */}
      <div className={styles['p-reservations__bottom']}>
        <Pagination
          totalItems={items.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
        />
      </div>
    </>
  );
};
