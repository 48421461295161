// focusable input for react-final-form focus-on-error
export const FocusableInput = ({ name }: { name: string }) => {
  return (
    <div
      style={{
        height: 0,
        width: 0,
        overflow: 'hidden',
      }}
    >
      <input
        name={name}
        style={{
          opacity: 0,
        }}
      />
    </div>
  );
};
