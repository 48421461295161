import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE,
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST,
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  MarketingAutomationCampaign,
  CreateMarketingAutomationCampaignResponse,
  ListMarketingAutomationCampaignsResponse,
  UpdateMarketingAutomationCampaignResponse,
  MarketingAutomationCampaignReportDataSet,
  GetMarketingAutomationCampaignsReportDataResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
      return action.error;

    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST:
      return true;

    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: MarketingAutomationCampaign[] = [],
  action: any
): MarketingAutomationCampaign[] => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS: {
      const response =
        action.response as ListMarketingAutomationCampaignsResponse;
      return response.marketing_automation_campaigns;
    }

    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS: {
      const response = action.response as MarketingAutomationCampaign;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS: {
      const response =
        action.response as CreateMarketingAutomationCampaignResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS: {
      const response =
        action.response as UpdateMarketingAutomationCampaignResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedCampaign = (
  state: MarketingAutomationCampaign | null = null,
  action: any
) => {
  switch (action.type) {
    case CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS: {
      const response =
        action.response as CreateMarketingAutomationCampaignResponse;
      return response;
    }

    default:
      return state;
  }
};

const reportData = (
  state: MarketingAutomationCampaignReportDataSet[] = [],
  action: any
) => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS: {
      const resp =
        action.response as GetMarketingAutomationCampaignsReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const reportDataLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST:
      return true;
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS:
    case FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export const marketingAutomationCampaigns = combineReducers({
  error,
  loading,
  all,
  lastCreatedCampaign,
  reportData,
  reportDataLoading,
});
