import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { localeSelector } from 'client/reducers';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

import styles from './BarListGadget.module.css';

interface Props {
  title: string;
  items: { label: string; percent: number; comparisonPercent?: number }[];
  color?: string;
  comparisonColor?: string;
  showComparison?: boolean;
  tooltipText?: string;
  unitLabel?: string;
}

export const BarListGadget = ({
  title,
  items,
  color,
  comparisonColor,
  showComparison,
  tooltipText,
  unitLabel,
}: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);

  const [hoveredText, setHoveredText] = React.useState<string | null>(null);

  const [mousePosition, setMousePosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);

  React.useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      setMousePosition({
        top: event.clientY,
        left: event.clientX,
      });
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  const unit = locale === 'ja' ? '人' : 'People';

  const maxPercent = items.reduce((max, item) => {
    let newMax = Math.max(max, item.percent);
    if (showComparison) {
      newMax = Math.max(newMax, item.comparisonPercent ?? 0);
    }
    return newMax;
  }, 0);

  const unitPersent = 100 / maxPercent;

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>
              {title}
              {unitLabel && (
                <span
                  style={{
                    marginLeft: '10px',
                    fontSize: '0.8rem',
                  }}
                >
                  ({unitLabel})
                </span>
              )}
            </div>
            <Tooltip text={tooltipText ?? ''}>
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          <table className={styles['table']}>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.label}</td>
                  <td>
                    <div className={styles['bar-container']}>
                      <div
                        className={styles['bar']}
                        style={{
                          width: `${unitPersent * item.percent}%`,
                          ...(color && { backgroundColor: color }),
                        }}
                        onMouseEnter={() => {
                          const text = item.percent.toString() + unit;
                          setHoveredText(text);
                        }}
                        onMouseLeave={() => {
                          setHoveredText(null);
                        }}
                      />
                    </div>
                    {showComparison && (
                      <div className={styles['bar-container']}>
                        <div
                          className={styles['bar']}
                          style={{
                            width: `${
                              unitPersent * (item.comparisonPercent ?? 0)
                            }%`,
                            ...(comparisonColor && {
                              backgroundColor: comparisonColor,
                            }),
                          }}
                          onMouseEnter={() => {
                            const text =
                              (item.comparisonPercent ?? 0).toLocaleString() +
                              unit;
                            setHoveredText(text);
                          }}
                          onMouseLeave={() => {
                            setHoveredText(null);
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles['labels']}>
          <div className={styles['item']}>
            <div
              className={styles['color']}
              style={{
                ...(color && { backgroundColor: color }),
              }}
            ></div>
            <span>{t('Reporting period')}</span>
          </div>
          {showComparison && (
            <div className={styles['item']}>
              <div
                className={styles['color']}
                style={{
                  ...(comparisonColor && { backgroundColor: comparisonColor }),
                }}
              ></div>
              <span>{t('Comparison period')}</span>
            </div>
          )}
        </div>
      </div>
      {hoveredText && (
        <HoveredText text={hoveredText} mousePosition={mousePosition} />
      )}
    </div>
  );
};

const HoveredText = ({
  text,
  mousePosition,
}: {
  text: string;
  mousePosition: { top: number; left: number } | null;
}) => {
  const hoverRef = React.useRef<HTMLDivElement>(null);
  const [popupWindowOpen, setPopupWindowOpen] = React.useState<{
    top: string;
    left: string;
  } | null>(null);

  React.useEffect(() => {
    if (hoverRef?.current) {
      setPopupWindowOpen({
        left: `${mousePosition?.left}px`,
        top: `${(mousePosition?.top ?? 0) - 60}px`,
      });
    }
  }, [hoverRef?.current, mousePosition]);

  return (
    <div
      className={clsx(styles['hover'])}
      ref={hoverRef}
      style={{
        top: popupWindowOpen?.top,
        left: popupWindowOpen?.left,
      }}
    >
      {text}
    </div>
  );
};
