import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { defaultProductTimezoneSelector } from 'client/reducers/organizations';
import { fetchInquirySmsStats } from 'client/actions/inquiries';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import pageStyles from 'client/pages/pages.module.css';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import styles from './SmsBilling.module.css';

export const SmsBilling = () => {
  const { t } = useTranslation();

  const [month, setMonth] = React.useState(moment().format('YYYY-MM'));
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);

  const smsStats = useSelector((state: ReduxState) => state.inquiries.smsStats);
  const loading = useSelector(
    (state: ReduxState) => state.inquiries.smsStatsLoading
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    const monthMoment = moment.tz(month, 'YYYY-MM', defaultTimezone);
    const startMoment = monthMoment.clone().startOf('month');
    const endMoment = monthMoment.clone().endOf('month');

    dispatch(
      fetchInquirySmsStats(startMoment.utc().format(), endMoment.utc().format())
    );
  }, [month, defaultTimezone]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box mt={2}>
        <div className={clsx(pageStyles['page-availability__datepick'])}>
          <a
            className={clsx(
              pageStyles['page-availability__datepick__btn'],
              pageStyles['prev']
            )}
            onClick={() => {
              setMonth(
                moment(month, 'YYYY-MM').add(-1, 'month').format('YYYY-MM')
              );
            }}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            {month}
          </Box>
          <a
            className={clsx(
              pageStyles['page-availability__datepick__btn'],
              pageStyles['next']
            )}
            onClick={() => {
              setMonth(
                moment(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM')
              );
            }}
          />
        </div>
      </Box>
      <div className={styles['summary-box']}>
        <b>{t('Totals')}</b>
        <ul>
          <li>{`${t('Message Count')}: ${
            smsStats?.totals?.message_count ?? '-'
          }`}</li>
          <li>{`${t('Segment Count')}: ${
            smsStats?.totals?.segment_count ?? '-'
          }`}</li>
          <li>{`${t('Price')}: ${formattedCurrencyAmount(
            smsStats?.totals?.total_price ?? ''
          )}`}</li>
        </ul>
      </div>
      <div className={componentStyles['c-table-main']}>
        <div className={componentStyles['c-table-main__thead']}>
          <table>
            <tbody>
              <tr>
                <th className={baseStyles['base-t-192']}>{t('Country')}</th>
                <th className={baseStyles['base-t-192']}>{t('Direction')}</th>
                <th className={baseStyles['base-t-192']}>
                  {t('Message Count')}
                </th>
                <th className={baseStyles['base-t-192']}>
                  {t('Segment Count')}
                </th>
                <th className={baseStyles['base-t-192']}>{t('Price')}</th>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={componentStyles['c-table-main__tbody']}>
          <table>
            <tbody>
              {smsStats?.stat_rows?.map((row, idx) => (
                <tr key={idx}>
                  <td
                    data-text={t('Country')}
                    className={baseStyles['base-t-192']}
                  >
                    {row.country}
                  </td>
                  <td
                    data-text={t('Direction')}
                    className={baseStyles['base-t-192']}
                  >
                    {row.direction === 'INBOUND' ? t('Inbound') : t('Outbound')}
                  </td>
                  <td
                    data-text={t('Message Count')}
                    className={baseStyles['base-t-192']}
                  >
                    {row.message_count ?? 0}
                  </td>
                  <td
                    data-text={t('Segment Count')}
                    className={baseStyles['base-t-192']}
                  >
                    {row.segment_count ?? 0}
                  </td>
                  <td
                    data-text={t('Price')}
                    className={baseStyles['base-t-192']}
                  >{`${row.segment_count ?? 0} x ${formattedCurrencyAmount(
                    row.price_per_segment ?? ''
                  )} = ${formattedCurrencyAmount(row.total_price ?? '')}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
