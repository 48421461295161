import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { allProductTagsSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';
import { MultiSelect, DateTimeInput } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  setWeeklyAvailabilityStartDate,
  setAvailabilityStartDate,
  setAvailabilityEndDate,
  setProductCalendarListTagFilter,
  setProductCalendarListSalesStatusFilter,
} from 'client/actions/productCalendarListControls';
import { getSalesStatusOptions } from 'client/libraries/util/getProductSalesStatus';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import pageStyles from 'client/pages/pages.module.css';
import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import { tagFilterSetSelector, salesStatusFilterSetSelector } from './util';
import { AvailabilityTable } from './AvailabilityTable';
import styles from './WeeklyAvailability.module.css';
import { CalendarEditButtonRow } from './CalendarEditButtonRow';
import { CalendarEditContext } from './CalendarEditContext';

export const WeeklyAvailability = () => {
  const { t } = useTranslation();

  const [selectedProductInstanceIds, setSelectedProductInstanceIds] =
    React.useState<string[]>([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    React.useState(false);
  const allTags = useSelector(allProductTagsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);

  const dates = useSelector(productCalendarListDatesSelector);
  const weeklyAvailabilityStartDate = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.weeklyAvailabilityStartDate
  );
  const activeUser = useSelector(activeUserSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setAvailabilityStartDate(
        moment(weeklyAvailabilityStartDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      setAvailabilityEndDate(
        moment(weeklyAvailabilityStartDate).add(7, 'days').format('YYYY-MM-DD')
      )
    );
  }, [weeklyAvailabilityStartDate]);

  const tagOptions = React.useMemo(
    () =>
      allTags.map((tag) => ({
        key: tag,
        value: tag,
        text: tag,
      })) || [],
    [allTags]
  );

  return (
    <CalendarEditContext.Provider
      value={{
        selectedProductInstanceIds,
        selectProductInstanceIds: (...ids) => {
          const idsToAdd = ids.filter(
            (id) => !selectedProductInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedProductInstanceIds([
              ...selectedProductInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectProductInstanceIds: (...ids) => {
          setSelectedProductInstanceIds(
            selectedProductInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="AVAILABILITY_WEEKLY" />
      )}
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                dispatch(
                  setWeeklyAvailabilityStartDate(
                    moment(weeklyAvailabilityStartDate)
                      .add(-7, 'days')
                      .format('YYYY-MM-DD')
                  )
                );
              }}
            />
            <div
              className={clsx(pageStyles['page-availability__datepick__main'])}
              style={{ zIndex: 9 }}
            >
              <DateTimeInput
                value={moment(dates[0])}
                locale={locale}
                onChange={(date) => {
                  dispatch(
                    setWeeklyAvailabilityStartDate(
                      moment(date).format('YYYY-MM-DD')
                    )
                  );
                }}
              />
            </div>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                dispatch(
                  setWeeklyAvailabilityStartDate(
                    moment(weeklyAvailabilityStartDate)
                      .add(7, 'days')
                      .format('YYYY-MM-DD')
                  )
                );
              }}
            />
          </div>
        </Box>

        <div className={styles['tag-select']}>
          <MultiSelect
            placeholder={t('Filter product status...')}
            options={getSalesStatusOptions(t)}
            selectedValues={salesStatusFilterSet as string[]}
            onChange={({ value }) => {
              dispatch(
                setProductCalendarListSalesStatusFilter(value as SalesStatus[])
              );
            }}
            withoutFrame={true}
            search
          />
        </div>

        <div className={styles['tag-select']}>
          <MultiSelect
            placeholder={t('Filter tags...')}
            options={tagOptions}
            selectedValues={tagFilterSet}
            onChange={({ value }) => {
              dispatch(setProductCalendarListTagFilter(value));
            }}
            withoutFrame={true}
            search
          />
        </div>

        {hasCustomUserRoleWritePermissions(
          activeUser,
          'AVAILABILITY.WEEKLY'
        ) && <CalendarEditButtonRow />}
      </div>

      <AvailabilityTable />
    </CalendarEditContext.Provider>
  );
};
