import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';

import { FieldWrapper, ToggleButton } from 'client/components/Form';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Box } from 'client/components/Box/Box';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { LanguageUppercaseISO, LanguageISO } from 'shared/libraries/i18n';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import { CopyToClipboard } from 'client/components/Icons/CopyToClipboard';

export const BookingWidgetSalesStatusEditor = () => {
  const { t } = useTranslation();
  const formState = useFormState();
  const salesStatus = formState.values?.salesStatus;
  const [copiedText, setCopiedText] = React.useState('');

  const org = useSelector(activeUserOrganizationSelector);

  const editingProduct = React.useContext(EditingProductContext);

  return (
    <>
      <EnumRadioButtonGroup
        name="salesStatus"
        liStyle={{ width: '195px', marginRight: '7px' }}
        options={[
          {
            value: 'ON_SALE',
            label: t('On Sale'),
            tooltipText: t(
              'Used for product to be listed and available on booking website.'
            ),
          },
          {
            value: 'LIST_ONLY',
            label: t('Not On Sale'),
            tooltipText: t(
              'Used for product to be listed on booking website, but not available for new reservations.'
            ),
          },
          {
            value: 'NOT_LISTED',
            label: t('Not Listed'),
            tooltipText: t(
              'Used for product when editing contents or before sales. Product is not listed on the booking website.'
            ),
          },
        ]}
      />
      {salesStatus !== 'NOT_LISTED' && (
        <>
          <Box mt={2} display="flex">
            <Field name="isHiddenFromProductListAndSearch">
              {({ input }) => (
                <ToggleButton
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                  label={
                    <div>
                      <div>
                        {t(
                          'Hide this product from product list on booking website and from search results in Google and other search engines.'
                        )}
                      </div>
                      <div>
                        {t(
                          '* Product page will be accessible when the URL is accessed directly'
                        )}
                      </div>
                    </div>
                  }
                />
              )}
            </Field>
            <Tooltip
              mobileStyle={{
                left: '-270px',
              }}
              text={t(
                'A "noindex" tag will be added to the product detail page to prevent indexing by search engines'
              )}
            />
          </Box>
          <Box mt={2}>
            <FieldWrapper label={t('Per-language Visibility')}>
              <div>
                <FieldArray name="languages">
                  {({ fields }) =>
                    fields.map((name, idx) => {
                      const productUrl = `${getBookingWebsiteUrl(
                        org,
                        fields.value[idx].language
                      )}/products/${editingProduct?.id}?lng=${
                        uppercaseIsoToLowercaseIso[
                          fields.value[idx].language as keyof Record<
                            LanguageUppercaseISO,
                            LanguageISO
                          >
                        ]
                      }`;

                      return (
                        <Field
                          type="checkbox"
                          name={`${name}.isVisible`}
                          key={name}
                        >
                          {({ input }) => (
                            <Box
                              mt={2}
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Box width="150px">
                                <ToggleButton
                                  {...input}
                                  label={getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      fields.value[idx]
                                        .language as keyof Record<
                                        LanguageUppercaseISO,
                                        LanguageISO
                                      >
                                    ],
                                    t
                                  )}
                                />
                              </Box>
                              <a
                                className={baseStyles['base-link']}
                                href={productUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t('Open booking site')}
                              </a>
                              <CopyToClipboard
                                text={productUrl}
                                onClick={() => {
                                  setCopiedText(productUrl);
                                }}
                              />

                              <Box ml={2} width="200px">
                                {copiedText === productUrl && (
                                  <div style={{ fontStyle: 'italic' }}>
                                    {t('Copied!')}
                                  </div>
                                )}
                              </Box>
                            </Box>
                          )}
                        </Field>
                      );
                    })
                  }
                </FieldArray>
              </div>
            </FieldWrapper>
          </Box>
        </>
      )}
    </>
  );
};
