import { combineReducers } from 'redux';

import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAILURE,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  UPDATE_PROMOTION_REQUEST,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
} from 'client/constants/ActionTypes';
import { Promotion } from 'shared/models/swagger';

// Reducers
const error = (state = '', action: any) => {
  switch (action.type) {
    case GET_PROMOTIONS_SUCCESS:
      return '';

    case GET_PROMOTIONS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case CREATE_PROMOTION_REQUEST:
    case GET_PROMOTIONS_REQUEST:
    case UPDATE_PROMOTION_REQUEST:
      return true;

    case CREATE_PROMOTION_SUCCESS:
    case CREATE_PROMOTION_FAILURE:
    case GET_PROMOTIONS_SUCCESS:
    case GET_PROMOTIONS_FAILURE:
    case UPDATE_PROMOTION_SUCCESS:
    case UPDATE_PROMOTION_FAILURE:
      return false;

    default:
      return state;
  }
};

const all = (state: Promotion[] = [], action: any): Promotion[] => {
  switch (action.type) {
    case GET_PROMOTIONS_SUCCESS:
      return action.response.promotions || [];

    case CREATE_PROMOTION_SUCCESS:
      return [action.response, ...state];

    case UPDATE_PROMOTION_SUCCESS:
      return state.map((elem) =>
        elem.id === action.response.id ? action.response : elem
      );

    default:
      return state;
  }
};

export const promotions = combineReducers({
  error,
  loading,
  all,
});
