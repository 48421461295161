import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ScrollToContext } from 'client/contexts/ScrollToContext';
import {
  Button,
  FieldWrapper,
  Input,
  Radio,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { ListInputLabel } from 'client/components/ListInputLabel/ListInputLabel';
import { ListInputElement } from 'client/components/ListInputElement/ListInputElement';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { listUpdate } from 'client/libraries/util/coreutil';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import { updatePrivateMarketplace } from 'client/actions/privateMarketplace';
import { PrivateMarketplaceSelect } from 'client/pages/PrivateMarketplace/PrivateMarketplaceSelect/PrivateMarketplaceSelect';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/PrivateMarketplace/PrivateMarketplaceMarketingAutomation/PrivateMarketplaceMarketingAutomation.module.css';

export const PrivateMarketplaceMarketingAutomation = () => {
  const { t } = useTranslation();
  const scrollTo = React.useContext(ScrollToContext);
  const dispatch = useDispatch();
  const privateMarketplace = useSelector(privateMarketplaceSelector);
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  let sourceLanguageISO = '';

  switch (privateMarketplace?.language) {
    case 'JA_JP':
      sourceLanguageISO = 'ja';
      break;

    case 'KO_KR':
      sourceLanguageISO = 'ko';
      break;

    case 'ZH_TW':
      sourceLanguageISO = 'zh-TW';
      break;

    case 'ZH_CN':
      sourceLanguageISO = 'zh-CN';
      break;

    case 'EN_US':
    default:
      sourceLanguageISO = 'en';
  }

  const productUpgradeHeaderTextOptions = [
    t('Customers who booked this product also upgraded to...', {
      lng: sourceLanguageISO,
    }),
    t('Popular products that people upgrade to', {
      lng: sourceLanguageISO,
    }),
    t('Early Booking Recommended (limited availability)', {
      lng: sourceLanguageISO,
    }),
    t('Enhance your experience', {
      lng: sourceLanguageISO,
    }),
  ];
  const addOnUpgradeHeaderTextOptions = [
    t('Customers who booked this product also upgraded...', {
      lng: sourceLanguageISO,
    }),
    t('Popular add-ons booked together with your product', {
      lng: sourceLanguageISO,
    }),
    t('Early Booking Recommended (limited availability)', {
      lng: sourceLanguageISO,
    }),
    t('Enhance your experience with product add-ons', {
      lng: sourceLanguageISO,
    }),
  ];
  const productCrossSellHeaderTextOptions = [
    t('Customers who booked this product also booked...', {
      lng: sourceLanguageISO,
    }),
    t('Popular products booked together with this product', {
      lng: sourceLanguageISO,
    }),
    t('Early Booking Recommended (limited availability)', {
      lng: sourceLanguageISO,
    }),
    t('Experience more in your destination', {
      lng: sourceLanguageISO,
    }),
  ];
  const initialUpSellEnabled = privateMarketplace?.up_sell_enabled || false;
  const initialCrossSellEnabled =
    privateMarketplace?.cross_sell_enabled || false;
  const initialFollowUpEmail7DayEnabled = (
    privateMarketplace?.follow_up_email_days || []
  ).includes(7);
  const initialFollowUpEmail14DayEnabled = (
    privateMarketplace?.follow_up_email_days || []
  ).includes(14);
  const initialCustomFollowUpEmailDays = React.useMemo(
    () =>
      (privateMarketplace?.follow_up_email_days || []).filter(
        (d) => d !== 7 && d !== 14
      ),
    [privateMarketplace]
  );
  const initialProductUpgradeHeaderText =
    privateMarketplace?.product_upgrade_header_text ||
    productUpgradeHeaderTextOptions[0];
  const initialAddOnUpgradeHeaderText =
    privateMarketplace?.add_on_upgrade_header_text ||
    addOnUpgradeHeaderTextOptions[0];
  const initialCrossSellSimilarProductHeaderText =
    privateMarketplace?.cross_sell_similar_product_header_text ||
    productCrossSellHeaderTextOptions[0];
  const [upSellEnabled, setUpSellEnabled] =
    React.useState<boolean>(initialUpSellEnabled);
  const [crossSellEnabled, setCrossSellEnabled] = React.useState<boolean>(
    initialCrossSellEnabled
  );
  const [followUpEmail7DayEnabled, setFollowUpEmail7DayEnabled] =
    React.useState<boolean>(initialFollowUpEmail7DayEnabled);
  const [followUpEmail14DayEnabled, setFollowUpEmail14DayEnabled] =
    React.useState<boolean>(initialFollowUpEmail14DayEnabled);
  const [customFollowUpEmailDays, setCustomFollowUpEmailDays] = React.useState<
    number[]
  >(initialCustomFollowUpEmailDays);
  const [productUpgradeHeaderText, setProductUpgradeHeaderText] =
    React.useState<string>(initialProductUpgradeHeaderText);
  const [addOnUpgradeHeaderText, setAddOnUpgradeHeaderText] =
    React.useState<string>(initialAddOnUpgradeHeaderText);
  const [
    crossSellSimilarProductHeaderText,
    setCrossSellSimilarProductHeaderText,
  ] = React.useState<string>(initialCrossSellSimilarProductHeaderText);
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  const reset = React.useCallback(() => {
    setUpSellEnabled(initialUpSellEnabled);
    setCrossSellEnabled(initialCrossSellEnabled);
    setFollowUpEmail7DayEnabled(initialFollowUpEmail7DayEnabled);
    setFollowUpEmail14DayEnabled(initialFollowUpEmail14DayEnabled);
    setCustomFollowUpEmailDays(initialCustomFollowUpEmailDays);
    setProductUpgradeHeaderText(initialProductUpgradeHeaderText);
    setAddOnUpgradeHeaderText(initialAddOnUpgradeHeaderText);
    setCrossSellSimilarProductHeaderText(
      initialCrossSellSimilarProductHeaderText
    );
  }, [
    initialUpSellEnabled,
    initialCrossSellEnabled,
    initialCustomFollowUpEmailDays,
    initialFollowUpEmail7DayEnabled,
    initialFollowUpEmail14DayEnabled,
    initialProductUpgradeHeaderText,
    initialAddOnUpgradeHeaderText,
    initialCrossSellSimilarProductHeaderText,
  ]);
  React.useEffect(() => {
    reset();
  }, [reset]);
  const pristine =
    upSellEnabled === initialUpSellEnabled &&
    crossSellEnabled === initialCrossSellEnabled &&
    customFollowUpEmailDays === initialCustomFollowUpEmailDays &&
    followUpEmail7DayEnabled === initialFollowUpEmail7DayEnabled &&
    followUpEmail14DayEnabled === initialFollowUpEmail14DayEnabled &&
    productUpgradeHeaderText === initialProductUpgradeHeaderText &&
    addOnUpgradeHeaderText === initialAddOnUpgradeHeaderText &&
    crossSellSimilarProductHeaderText ===
      initialCrossSellSimilarProductHeaderText;
  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <PrivateMarketplaceSelect />
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tbody>
              <tr>
                <th>{t('Cross-sell')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <ToggleButton
                      label={t('Activate Cross-Sell')}
                      checked={crossSellEnabled}
                      onChange={() => setCrossSellEnabled(!crossSellEnabled)}
                    />
                  </div>
                  {crossSellEnabled && (
                    <div className={styles['input-box']}>
                      <FieldWrapper
                        label={t(
                          'Cross Sell Product Header Text (shown in emails and on cross-sell/up-sell page)'
                        )}
                      >
                        {productCrossSellHeaderTextOptions.map(
                          (option, idx) => (
                            <Radio
                              key={idx}
                              label={`"${option}"`}
                              onChange={() =>
                                setCrossSellSimilarProductHeaderText(option)
                              }
                              checked={
                                crossSellSimilarProductHeaderText === option
                              }
                            />
                          )
                        )}
                        <Radio
                          label={t('Custom Text')}
                          checked={
                            !productCrossSellHeaderTextOptions.includes(
                              crossSellSimilarProductHeaderText
                            )
                          }
                          onChange={() =>
                            setCrossSellSimilarProductHeaderText('')
                          }
                        />
                        {!productCrossSellHeaderTextOptions.includes(
                          crossSellSimilarProductHeaderText
                        ) && (
                          <Input
                            placeholder={t('Enter Custom Text')}
                            value={crossSellSimilarProductHeaderText}
                            onChange={(e, { value }) =>
                              setCrossSellSimilarProductHeaderText(value)
                            }
                          />
                        )}
                      </FieldWrapper>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>{t('Up-sell')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <ToggleButton
                      label={t('Activate Up-Sell')}
                      checked={upSellEnabled}
                      onChange={() => setUpSellEnabled(!upSellEnabled)}
                    />
                  </div>
                  {upSellEnabled && (
                    <div className={styles['input-box']}>
                      <FieldWrapper
                        label={t(
                          'Product Upgrades Header Text (shown in marketing emails and on promotional web page)'
                        )}
                      >
                        {productUpgradeHeaderTextOptions.map((option, idx) => (
                          <Radio
                            key={idx}
                            label={`"${option}"`}
                            onChange={() => setProductUpgradeHeaderText(option)}
                            checked={productUpgradeHeaderText === option}
                          />
                        ))}
                        <Radio
                          label={t('Custom Text')}
                          checked={
                            !productUpgradeHeaderTextOptions.includes(
                              productUpgradeHeaderText
                            )
                          }
                          onChange={() => setProductUpgradeHeaderText('')}
                        />
                        {!productUpgradeHeaderTextOptions.includes(
                          productUpgradeHeaderText
                        ) && (
                          <Input
                            placeholder={t('Enter Custom Text')}
                            value={productUpgradeHeaderText}
                            onChange={(e, { value }) =>
                              setProductUpgradeHeaderText(value)
                            }
                          />
                        )}
                      </FieldWrapper>
                    </div>
                  )}
                  {upSellEnabled && (
                    <div className={styles['input-box']}>
                      <FieldWrapper
                        label={t(
                          'Add-On Upgrades Header Text (shown in marketing emails and on promotional web page)'
                        )}
                      >
                        {addOnUpgradeHeaderTextOptions.map((option, idx) => (
                          <Radio
                            key={idx}
                            label={`"${option}"`}
                            onChange={() => setAddOnUpgradeHeaderText(option)}
                            checked={addOnUpgradeHeaderText === option}
                          />
                        ))}
                        <Radio
                          label={t('Custom Text')}
                          checked={
                            !addOnUpgradeHeaderTextOptions.includes(
                              addOnUpgradeHeaderText
                            )
                          }
                          onChange={() => setAddOnUpgradeHeaderText('')}
                        />
                        {!addOnUpgradeHeaderTextOptions.includes(
                          addOnUpgradeHeaderText
                        ) && (
                          <Input
                            placeholder={t('Enter Custom Text')}
                            value={addOnUpgradeHeaderText}
                            onChange={(e, { value }) =>
                              setAddOnUpgradeHeaderText(value)
                            }
                          />
                        )}
                      </FieldWrapper>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>{t('Communications')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <ToggleButton
                      label={t(
                        'Send Follow-up Email 7 Days Before Participation'
                      )}
                      checked={followUpEmail7DayEnabled}
                      onChange={() =>
                        setFollowUpEmail7DayEnabled(!followUpEmail7DayEnabled)
                      }
                    />
                  </div>
                  <div className={styles['input-box']}>
                    <ToggleButton
                      label={t(
                        'Send Follow-up Email 14 Days Before Participation'
                      )}
                      checked={followUpEmail14DayEnabled}
                      onChange={() =>
                        setFollowUpEmail14DayEnabled(!followUpEmail14DayEnabled)
                      }
                    />
                  </div>
                  <div className={styles['input-box']}>
                    <ListInputLabel
                      label={t('Custom Follow-up Email Periods')}
                      getDefaultElement={() => 10}
                      value={customFollowUpEmailDays}
                      onChange={(newCustomFollowUpEmailDays) =>
                        setCustomFollowUpEmailDays(newCustomFollowUpEmailDays)
                      }
                    />
                    {customFollowUpEmailDays.map(
                      (customFollowUpEmailDay, idx) => {
                        return (
                          <ListInputElement
                            key={idx}
                            elemIndex={idx}
                            listValue={customFollowUpEmailDays}
                            onListChange={(newCustomFollowUpEmailDays) =>
                              setCustomFollowUpEmailDays(
                                newCustomFollowUpEmailDays
                              )
                            }
                            getDefaultElement={() => 10}
                          >
                            <Select
                              search
                              value={`${customFollowUpEmailDay}`}
                              onChange={(e, { value }) => {
                                setCustomFollowUpEmailDays(
                                  listUpdate(
                                    customFollowUpEmailDays,
                                    idx,
                                    parseInt(value)
                                  )
                                );
                              }}
                              options={_.times(45, (i) => i + 1)
                                .filter((day) => {
                                  return (
                                    day !== 7 &&
                                    day !== 14 &&
                                    (day === customFollowUpEmailDay ||
                                      !customFollowUpEmailDays.includes(day))
                                  );
                                })
                                .map((day) => ({
                                  key: day,
                                  text: t(
                                    '{{count}} days before participation',
                                    {
                                      count: day,
                                    }
                                  ),
                                  value: `${day}`,
                                }))}
                            />
                          </ListInputElement>
                        );
                      }
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button
            style="gray"
            size="middle"
            disabled={pristine}
            onClick={reset}
          >
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            disabled={pristine}
            onClick={async () => {
              await dispatch(
                updatePrivateMarketplace({
                  up_sell_enabled: upSellEnabled,
                  cross_sell_enabled: crossSellEnabled,
                  follow_up_email_days: [
                    ...(followUpEmail7DayEnabled ? [7] : []),
                    ...(followUpEmail14DayEnabled ? [14] : []),
                    ...customFollowUpEmailDays,
                  ],
                  product_upgrade_header_text: productUpgradeHeaderText,
                  cross_sell_similar_product_header_text:
                    crossSellSimilarProductHeaderText,
                  add_on_upgrade_header_text: addOnUpgradeHeaderText,
                })
              );
              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
