import type { TranslateFuncType } from 'client/components/Translate';

export type EmbedType = 'STARS' | 'REVIEW_SUMMARY' | 'REVIEW_SUMMARY_RECENT';
export const getEmbedTypeText = (
  embedType: EmbedType,
  t: TranslateFuncType
): string => {
  switch (embedType) {
    case 'STARS':
      return t('Review Stars');

    case 'REVIEW_SUMMARY':
      return t('Review Summary');

    case 'REVIEW_SUMMARY_RECENT':
      return t('Review Summary + Recent Reviews');
  }

  return '';
};
export const getEmbedTypePath = (
  embedType: EmbedType
): 'stars' | 'reviewSummary' | 'reviewSummaryRecent' => {
  switch (embedType) {
    case 'STARS':
      return 'stars';

    case 'REVIEW_SUMMARY':
      return 'reviewSummary';

    case 'REVIEW_SUMMARY_RECENT':
    default:
      return 'reviewSummaryRecent';
  }
};
