import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { NewsletterTemplate } from 'shared/components/templates/newsletter/NewsletterTemplate';
import { Button, FieldWrapper } from 'client/components/Form';

import { useTemplateOptions } from './useTemplateOptions';

interface Props {
  templateType: string;
  onClose: () => void;
  onSelect: () => void;
  onCancel: () => void;
}

export const PreviewModal = ({
  templateType,
  onClose,
  onSelect,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const templateOptions = useTemplateOptions();

  const activeOption = templateOptions.find(
    (option) => option.value === templateType
  ) as any;
  const defaultTemplateValues = activeOption?.defaultTemplateValues as any;

  return (
    <Modal
      width="wide"
      title={activeOption.title}
      open={true}
      onClose={onClose}
      scrollingContent
    >
      <Modal.Content>
        <FieldWrapper label={t('Description')}>
          {activeOption.description}
        </FieldWrapper>
        <FieldWrapper label={t('Subject')}>{activeOption.title}</FieldWrapper>
        <FieldWrapper label={t('Message')} />
        <NewsletterTemplate
          templateValues={defaultTemplateValues}
          selectedTemplate={templateType as any}
          customerName={t('John Nutmeg')}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="gray"
          size="middle"
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            onSelect();
            onClose();
          }}
        >
          {t('Use This Template')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
