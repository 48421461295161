// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Icon,
  Modal,
  Header,
  TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import {
  declineReservation,
  fetchReservationByID,
} from 'client/actions/reservations';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { getStartTime } from 'client/libraries/util/coreutil';
import type { ReduxState } from 'client/reducers/index';

type OwnProps = {
  onDeclineCompleted?: () => void,
  reservationID: string,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  supplier_notes: string,
};

class DeclineReservationButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      supplier_notes: '',
    };
  }

  handleOpen = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }

    this.setState({
      showModal: true,
    });
  };

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  render() {
    const {
      loading,
      onDeclineCompleted,
      reservation,
      reservationID,
      locale,
      t,
    } = this.props;
    const { showModal } = this.state;

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];

    const startTime = reservation && getStartTime(reservation).locale(locale);

    return (
      <>
        <Button icon basic onClick={this.handleOpen}>
          <Icon name="cancel" color="red" />
          {t('Decline')}
        </Button>
        <Modal
          className="fix-semantic-modal"
          eventPool={`${reservationID}-decline`}
          open={showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
        >
          <Header icon="cancel" content={t('Decline reservation')} />
          <Modal.Content>
            {loading ? (
              <ModalLoader />
            ) : (
              <Form>
                <div className="field">
                  <label>{t('Product')}</label>
                  {productName}
                </div>
                <div className="field">
                  <label>{t('Start time')}</label>
                  {moment(startTime).format('lll')}
                </div>
                <div className="field">
                  <label>{t('Total participants')}</label>
                  {guests.length}
                </div>
              </Form>
            )}
            <Divider section />
            <ReservationActorInputForm />
            <Form>
              <Form.Input
                control={TextArea}
                label={t('Replies')}
                value={this.state.supplier_notes}
                onChange={this.handleSupplierNotesChange}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              inverted
              onClick={() => {
                this.props
                  .declineReservation(reservationID, this.state.supplier_notes)
                  .then(() => onDeclineCompleted && onDeclineCompleted());
                this.setState({
                  showModal: false,
                });
              }}
            >
              <Icon name="cancel" /> {t('Decline')}
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  loading: state.reservations.loading,
  reservation: state.reservations.byID[ownProps.reservationID],
  locale: state.language.selected.iso,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  declineReservation: (id: string, supplier_notes: string) =>
    dispatch(declineReservation(id, supplier_notes)),
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
});

export const DeclineReservationButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(DeclineReservationButtonComponent);
