import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FocusableInput } from 'client/components/Form';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { getInitialMinimumParticipantFormValues } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/util';

export const MinimumParticipantsEditor = () => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const { basicFormValues } = useContext(ProductHelperContext);
  const form = useForm();

  // Use form change to manually update the values should values in basic info form change
  // because calculation of minimum participants depend on them
  useEffect(() => {
    if (basicFormValues) {
      const newInitialMinimumParticipantFormValues =
        getInitialMinimumParticipantFormValues(
          basicFormValues,
          defaultCurrency
        );
      form.change(
        'minimumParticipantCount',
        newInitialMinimumParticipantFormValues.minimumParticipantCount
      );
      form.change(
        'unitWeights',
        newInitialMinimumParticipantFormValues.unitWeights
      );
      form.change(
        'usePerBookingInventoryCounting',
        newInitialMinimumParticipantFormValues.usePerBookingInventoryCounting
      );
    }
  }, [basicFormValues]);

  const atLeastOneConsumeInventoryRequired = (
    value: any[] | null | typeof undefined
  ): string => {
    if (!value?.some((item) => item.shouldConsumeInventory)) {
      return t(
        'At least one unit must consume inventory. Units that consume inventory will contribute to guest counts in the product calendar.'
      );
    }

    return '';
  };

  return (
    <ul className={styles['p-list']}>
      <Field name="usePerBookingInventoryCounting">
        {({ input: inventoryCountingInput }) => (
          <>
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <div className={styles['p-list__item__ttl__txt']}>
                  {t('Inventory Counting')}
                </div>
              </div>
              <div className={styles['p-list__item__body']}>
                <Toggle
                  label={t(
                    'Count inventory per booking instead of per participant'
                  )}
                  checked={inventoryCountingInput.value}
                  onChange={() => {
                    if (!inventoryCountingInput.value) {
                      form.change('minimumParticipantCount', 0);
                    }
                    inventoryCountingInput.onChange(
                      !inventoryCountingInput.value
                    );
                  }}
                  size="sm"
                />
              </div>
            </li>
            {!inventoryCountingInput.value && (
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <div className={styles['p-list__item__ttl__txt']}>
                    {t('Minimum Participants')}
                  </div>
                </div>
                <div className={styles['p-list__item__body']}>
                  <Field name="minimumParticipantCount">
                    {({ input }) => (
                      <SingleDropdown
                        options={_.times(51, (count) => ({
                          value: `${count}`,
                          text: t('{{count}} people', {
                            count,
                          }) as string,
                        }))}
                        selectedOption={`${input.value}`}
                        onChange={(value) => {
                          input.onChange(parseInt(value));
                        }}
                      />
                    )}
                  </Field>
                </div>
              </li>
            )}
            {!inventoryCountingInput.value && (
              <li className={clsx(styles['p-list__item'], styles['row'])}>
                <div className={styles['p-list__item__ttl']}>
                  <div className={styles['p-list__item__ttl__txt']}>
                    {t('Per-unit Settings')}
                  </div>
                </div>
                <div
                  className={clsx(styles['p-list__item__body'], styles['row'])}
                >
                  <FieldArray
                    name="unitWeights"
                    validate={atLeastOneConsumeInventoryRequired}
                  >
                    {({ fields, meta: { touched, error } }) => (
                      <>
                        <div className={styles['p-unit']}>
                          <div className={styles['p-unit__header']}>
                            <p>{t('Unit')}</p>
                            <p>{t('Unit Weight')}</p>
                            <p>{t('Consume Inventory')}</p>
                          </div>
                          {fields.map((name) => (
                            <Field key={name} name={name}>
                              {({ input }) => (
                                <div className={styles['p-unit__body']}>
                                  <div data-title={t('Unit')}>
                                    {input.value.unitKey}
                                  </div>
                                  <div data-title={t('Unit Weight')}>
                                    <Field name={`${name}.weight`}>
                                      {({ input }) => (
                                        <SingleDropdown
                                          options={[
                                            {
                                              value: '0',
                                              text: t('0 people'),
                                            },
                                            {
                                              value: '0.25',
                                              text: t('0.25 people'),
                                            },
                                            {
                                              value: '0.5',
                                              text: t('0.5 people'),
                                            },
                                            {
                                              value: '1',
                                              text: t('1.0 people'),
                                            },
                                          ]}
                                          selectedOption={`${input.value}`}
                                          onChange={(value) => {
                                            input.onChange(parseFloat(value));
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div>
                                    <Field
                                      name={`${name}.shouldConsumeInventory`}
                                    >
                                      {({ input }) => (
                                        <Toggle
                                          label=""
                                          checked={input.value}
                                          onChange={() =>
                                            input.onChange(!input.value)
                                          }
                                          size="sm"
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              )}
                            </Field>
                          ))}
                          {error && typeof error === 'string' && (
                            <FocusableInput name="unitWeights" />
                          )}

                          {touched && error && (
                            <p className={baseStyles['u-error-msg']}>{error}</p>
                          )}

                          <div className={styles['p-unit__note']}>
                            <p>
                              {t('* Unit Weight')}
                              <br />
                              {t(
                                '・1 = for every person that applies, one is counted'
                              )}
                              <br />
                              {t(
                                '・0.5 = for every two people that apply, one is counted'
                              )}
                              <br />
                              {t(
                                '・0.25 = for every four people that apply, one is counted'
                              )}
                              <br />
                              {t('・0 = not counted')}
                            </p>
                            <p>
                              {t('* Consume Inventory')}
                              <br />
                              {t(
                                'When it is turned off, inventory will not be consumed for the unit.'
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </li>
            )}
          </>
        )}
      </Field>
    </ul>
  );
};
