import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';

import { fetchProductInstances } from 'client/actions/productInstances';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { makeProductInstancesSelector } from 'client/reducers/productInstances';
import { activeUserSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { operationAllowed } from 'shared/models/access';
import { toProductInstanceShape } from 'client/libraries/util/productInstanceShape';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ReduxState } from 'client/reducers';
import { getCustomCalendarEvents } from 'client/libraries/util/getCustomCalendarEvents';
import { getProductSalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { CustomCalendar } from 'client/components/CustomCalendar/CustomCalendar';
import { ProductInstanceModal } from 'client/components/ProductInstanceModal/ProductInstanceModal';
import { ReservationListModal } from 'client/components/ReservationListModal/ReservationListModal';
import type { ProductInstance, Reservation } from 'shared/models/swagger';

type ReservationListContext = {
  productIds: string[];
  date: string;
  rootProductInstanceId: string;
};
type DateRange = {
  startDate: Moment;
  endDate: Moment;
};
type Props = {
  defaultDate: Moment;
  onClose: (arg0: void) => void;
  reservation?: Reservation;
  productId?: string;
  onEditClick?: (arg0: ProductInstance) => void;
  customerId?: string;
};
export const ReservationCreateModal = ({
  defaultDate,
  onClose,
  reservation,
  productId,
  onEditClick,
  customerId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Calendar date range
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment(defaultDate.format())
      .startOf('month')
      .subtract(7, 'days'),
    endDate: moment(defaultDate.format()).endOf('month').add(7, 'days'),
  });
  const [newProductId, setNewProductId] = React.useState<string>(
    productId ? productId : ''
  );
  // State to keep track the selected Product Instance that user has clicked at calendar.
  // Show Prodct Instance modal if this state is not null.
  const [editingProductInstance, setEditingProductInstance] =
    React.useState<null | ProductInstance>(null);
  // State to show reservation list modal
  const [reservationListContext, setReservationListContext] =
    React.useState<null | ReservationListContext>(null);
  const locale = useSelector((state: ReduxState) => {
    return state.language.selected.iso;
  });
  const productInstancesLoading = useSelector((state: ReduxState) => {
    return state.productInstances.loading;
  });
  let products = useSelector(summariesSortedByBookmarkedSelector);
  const productInstances = useSelector(
    makeProductInstancesSelector(newProductId)
  );
  const product = useSelector((state: ReduxState) => {
    if (newProductId) {
      return state.products.byID[newProductId];
    }

    return null;
  });
  const activeUser = useSelector(activeUserSelector);
  const timezone = (product && product.start_timezone) || 'UTC';

  if (!operationAllowed(activeUser, 'write', 'productContents')) {
    products = products.filter(
      (product) => 'ON_SALE' === getProductSalesStatus(product)
    );
  }

  // fetch products
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [locale]);
  // fetch product instance
  React.useEffect(() => {
    if (newProductId) {
      dispatch(
        fetchProductInstances(
          newProductId,
          dateRange.startDate,
          dateRange.endDate
        )
      );
    }
  }, [newProductId, dateRange]);
  // fetch product
  React.useEffect(() => {
    if (newProductId) {
      dispatch(fetchProductByID(newProductId));
    }
  }, [newProductId]);

  // When product selection has changed
  const onChangeProduct = (productId: string) => {
    if (productId == 'FREE_FORMAT') {
      setNewProductId('');
    } else {
      setNewProductId(productId);
    }
  };

  // When date range changed
  const onRangeChange = (range: any) => {
    let startDate: Moment;
    let endDate: Moment;

    if (range.start && range.end) {
      // 'month' view returns a {start: Date, end: Date} object
      startDate = moment(range.start).subtract(1, 'days');
      endDate = moment(range.end).add(1, 'days');
    } else {
      // 'day' and 'week' views return an array
      startDate = moment(range[0]).subtract(1, 'days');
      endDate = moment(range[range.length - 1]).add(1, 'days');
    }

    if (
      !operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      endDate.isSameOrBefore(moment())
    ) {
      // We won't show past product instances unless the user can confirm reservations so prevent fetch
      return;
    }

    setDateRange({
      startDate,
      endDate,
    });
  };

  // When Product instance is clicked at calendar, show Product Instance modal (modal with 3 buttons: Edit, Book, Reservation List)
  const onEventClick = (productInstance: ProductInstance | null) => {
    setEditingProductInstance(productInstance);
  };

  // When Reservation list button is clicked at the product instance modal
  const onReservationListClick = (
    reservationListContext: ReservationListContext | null
  ) => {
    setReservationListContext(reservationListContext);
  };

  return (
    <>
      {
        <CustomCalendar
          defaultDate={defaultDate}
          productSelectorIsSearchable={true}
          productSelectorWithDisabled={true}
          title={t('Create new reservation')}
          onClose={onClose}
          events={
            productInstancesLoading
              ? null
              : getCustomCalendarEvents(
                  activeUser,
                  timezone,
                  productInstances,
                  locale,
                  t,
                  product?.request_booking_settings
                    ?.should_reject_bookings_beyond_capacity ?? false
                )
          }
          timezone={timezone}
          locale={locale}
          product={product}
          onRangeChange={onRangeChange}
          onEventClick={onEventClick}
          loading={productInstancesLoading}
          showProductSelector={true}
          products={products}
          onChangeProduct={onChangeProduct}
        />
      }

      {/* Product Instance modal */}
      {editingProductInstance && (
        <ProductInstanceModal
          insertRoot={true}
          productName={getDisplayProductName(product)}
          allotmentSettings={product?.allotment_settings ?? null}
          productInstance={editingProductInstance}
          timezone={timezone}
          onEditClick={onEditClick}
          instance={toProductInstanceShape(editingProductInstance)}
          open={editingProductInstance !== null}
          onClose={() => {
            setEditingProductInstance(null);
          }}
          onListClick={() => {
            onReservationListClick({
              // productIds: [product.id],
              productIds: [product?.id || ''],
              rootProductInstanceId: editingProductInstance?.id || '',
              date: moment(editingProductInstance?.start_date_time_utc)
                .tz(timezone)
                .format('YYYY-MM-DD'),
            });
          }}
          reservation={reservation}
          isFromNewReservationModal={true}
          customerId={customerId}
          shouldRejectBookingsBeyondCapacity={
            product?.request_booking_settings
              ?.should_reject_bookings_beyond_capacity ?? false
          }
        />
      )}

      {/* Reservation list modal */}
      {reservationListContext && (
        <ReservationListModal
          insertRoot={true}
          productIds={reservationListContext?.productIds || []}
          rootProductInstanceId={reservationListContext?.rootProductInstanceId}
          date={reservationListContext?.date || ''}
          onClose={() => onReservationListClick(null)}
          open={reservationListContext !== null}
        />
      )}
    </>
  );
};
