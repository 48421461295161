import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button, FieldWrapper, ToggleButton } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { Editor } from 'client/components/Editor/Editor';

import {
  convertFormValuesToSwaggerTopPageSection,
  TopPageFormValues,
} from '../FormValues';
import type { CustomIntroductionSection } from '../FormValues';
import { updateTopPageSection } from '../util';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
};
export const EditCustomIntroductionSectionModal = ({
  name,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [editorJSIsLoading, setEditorJSIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const language = formState.values?.sectionLanguage ?? 'JA_JP';

  const initialValues = _.get(formState.values, name);

  return (
    <Modal
      title={t('Edit custom introduction')}
      open={open}
      onClose={onClose}
      insertRoot
      width="wide"
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: CustomIntroductionSection) => {
          await dispatch(
            updateTopPageSection(
              convertFormValuesToSwaggerTopPageSection(values),
              language
            )
          );
          form.change(name as any, values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Box mb={2}>
                <Field type="checkbox" name="isVisible">
                  {({ input }) => (
                    <ToggleButton
                      {...input}
                      label={t(
                        'Show custom introduction on booking website top page'
                      )}
                    />
                  )}
                </Field>
                <Box mt={2}>
                  <FieldWrapper label={t('Custom Introduction')} />
                  <Field name="customIntroductionEditorJSObject">
                    {({ input }) => (
                      <Editor
                        imageS3Prefix={`editor/supplier/${
                          activeUserOrganization?.id || ''
                        }`}
                        data={input.value}
                        onChange={(data: any, loading) => {
                          setEditorJSIsLoading(loading);
                          input.onChange(data);
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  form.change(
                    'customIntroductionEditorJSObject',
                    initialValues.customIntroductionEditorJSObject
                  );
                  form.change('isVisible', initialValues.isVisible);
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                size="middle"
                style="blue"
                type="submit"
                loading={submitting}
                disabled={editorJSIsLoading}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
