import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

import { TemplateCard } from './TemplateCard';
import styles from './TemplateSelect.module.css';
import { useTemplateOptions } from './useTemplateOptions';

interface Props {
  onBackClick: () => void;
}

export const TemplateSelect = ({ onBackClick }: Props) => {
  const templateOptions = useTemplateOptions();

  const { t } = useTranslation();

  const form = useForm();

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Box mb={2}>
          <Button onClick={onBackClick} style="gray" size="middle">
            {t('Back')}
          </Button>
        </Box>
      </div>
      <Field name="selectedTemplate">
        {({ input }) => (
          <>
            <div className={styles['template-card-row']}>
              {templateOptions.map((option) => (
                <div
                  key={option.value}
                  className={styles['template-card-container']}
                >
                  <TemplateCard
                    title={option.title}
                    templateType={option.value}
                    description={option.description}
                    onSelect={() => {
                      input.onChange(option.value);
                      form.change(
                        'templateValues',
                        option.defaultTemplateValues
                      );
                    }}
                    onDeselect={() => {
                      input.onChange('');
                      form.change('templateValues', {});
                    }}
                    selected={input.value === option.value}
                    thumbnailUrl={option.thumbnail}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};
