import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { batchEditEquipmentInstances } from 'client/actions/equipmentInstances';

import { SeatCalendarEditContext } from './SeatCalendarEditContext';
import { EquipmentInstanceCloseCalendarModal } from './EquipmentInstanceCloseCalendarModal';
import styles from './SeatCalendarEditButtonRow.module.css';
import { SeatCalendarEditModal } from './SeatCalendarEditModal';

export const SeatCalendarEditButtonRow = () => {
  const {
    calendarEditModeIsActive,
    setCalendarEditModeIsActive,
    selectedEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
  } = React.useContext(SeatCalendarEditContext);
  const [showDateRangeCloseCalendarModal, setShowDateRangeCloseCalendarModal] =
    React.useState(false);
  const [showCloseCalendarModal, setShowCloseCalendarModal] =
    React.useState(false);
  const [showOpenCalendarModal, setShowOpenCalendarModal] =
    React.useState(false);
  const [expandedOnMobile, setExpandedOnMobile] = React.useState(false);

  const reset = React.useCallback(() => {
    setCalendarEditModeIsActive(false);
    deselectEquipmentInstanceIds(...selectedEquipmentInstanceIds);
  }, [
    selectedEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
    setCalendarEditModeIsActive,
  ]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <>
      <div className={styles['button-row']}>
        {calendarEditModeIsActive ? (
          <>
            <div className={styles['calendar-edit-btns-pc']}>
              <Box ml={-2} mt={-2} display="flex" flexWrap="wrap">
                <Box mt={2} ml={2}>
                  <Button
                    style="red"
                    size="middle"
                    width={120}
                    onClick={() => reset()}
                  >
                    {t('Discard Edits')}
                  </Button>
                </Box>
                <Box mt={2} ml={2}>
                  <Button
                    disabled={selectedEquipmentInstanceIds.length === 0}
                    style="yellow"
                    size="middle"
                    width={120}
                    onClick={() => setShowCloseCalendarModal(true)}
                  >
                    {t('Close calendar')}
                  </Button>
                </Box>
                <Box mt={2} ml={2}>
                  <Button
                    disabled={selectedEquipmentInstanceIds.length === 0}
                    style="yellow"
                    size="middle"
                    width={120}
                    onClick={() => setShowOpenCalendarModal(true)}
                  >
                    {t('Open calendar')}
                  </Button>
                </Box>
              </Box>
            </div>
            <div className={styles['calendar-edit-btns-mobile']}>
              <Box>
                <Button
                  style="red"
                  size="middle"
                  width={140}
                  onClick={() => reset()}
                >
                  {t('Discard Edits')}
                </Button>
              </Box>
              <div className={styles['edit-button-container']}>
                <Button
                  disabled={selectedEquipmentInstanceIds.length === 0}
                  style="yellow"
                  size="middle"
                  width={140}
                  onClick={() => {
                    setExpandedOnMobile(!expandedOnMobile);
                  }}
                >
                  {t('Edit')}
                </Button>

                {expandedOnMobile && (
                  <div className={styles['button-dropdown']}>
                    <Box mt={2}>
                      <Button
                        disabled={selectedEquipmentInstanceIds.length === 0}
                        style="yellow"
                        size="middle"
                        onClick={() => {
                          setShowCloseCalendarModal(true);
                          setExpandedOnMobile(false);
                        }}
                      >
                        {t('Close calendar')}
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <Button
                        disabled={selectedEquipmentInstanceIds.length === 0}
                        style="yellow"
                        size="middle"
                        onClick={() => {
                          setShowOpenCalendarModal(true);
                          setExpandedOnMobile(false);
                        }}
                      >
                        {t('Open calendar')}
                      </Button>
                    </Box>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <Box display="flex" flexWrap="wrap" ml={-2} mt={-2}>
            <Box ml={2} mt={2}>
              <Button
                style="yellow"
                size="middle"
                width={140}
                onClick={() => setCalendarEditModeIsActive(true)}
              >
                {t('Select Slots and Edit')}
              </Button>
            </Box>
            <Box ml={2} mt={2}>
              <Button
                style="yellow"
                size="middle"
                width={140}
                onClick={() => setShowDateRangeCloseCalendarModal(true)}
              >
                {t('Close Calendars')}
              </Button>
            </Box>
          </Box>
        )}
      </div>

      {showDateRangeCloseCalendarModal && (
        <EquipmentInstanceCloseCalendarModal
          open={showDateRangeCloseCalendarModal}
          onClose={() => setShowDateRangeCloseCalendarModal(false)}
        />
      )}
      {showCloseCalendarModal && (
        <SeatCalendarEditModal
          title={t('Close calendar')}
          message={t('Close calendar for the selected availability slots.')}
          onSave={async () => {
            await dispatch(
              batchEditEquipmentInstances(selectedEquipmentInstanceIds, 'CLOSE')
            );
            reset();
          }}
          onClose={() => setShowCloseCalendarModal(false)}
        />
      )}
      {showOpenCalendarModal && (
        <SeatCalendarEditModal
          title={t('Open calendar')}
          message={t('Open calendar for the selected availability slots.')}
          onSave={async () => {
            await dispatch(
              batchEditEquipmentInstances(selectedEquipmentInstanceIds, 'OPEN')
            );
            reset();
          }}
          onClose={() => setShowOpenCalendarModal(false)}
        />
      )}
    </>
  );
};
