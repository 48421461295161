import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useParams, useHistory, Link } from 'react-router-dom';
import clsx from 'clsx';

import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { MultiselectListInput } from 'client/components/MultiselectListInput';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { ModalLoader } from 'client/components/ModalLoader';
import { fetchProducts } from 'client/actions/products';
import { Button, Input, FocusableInput } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { ProductIdsListInput } from 'client/components/ProductIdsListInput/ProductIdsListInput';
import {
  createWaiverTemplate,
  updateWaiverTemplate,
  fetchSourceLanguageWaiverTemplateByID,
  fetchWaiverTemplates,
} from 'client/actions/waiverTemplates';
import { updateTranslations } from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import baseStyles from 'client/base.module.css';

import { WaiverTemplateContentEditor } from './WaiverTemplateContentEditor';
import {
  convertFormValuesToSwaggerWaiverTemplate,
  getInitialValues,
} from './formValues';
import type { FormValues } from './formValues';
import styles from './WaiverTemplateList.module.css';

const focusOnError: any = createDecorator();
export const WaiverTemplateEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const store = useStore();
  const history = useHistory();
  const waiverTemplates = useSelector(
    (state: ReduxState) => state.waiverTemplates.all
  );
  const products = useSelector(summariesWithBookmarksSelector);
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  React.useEffect(() => {
    dispatch(fetchWaiverTemplates());
  }, []);
  React.useEffect(() => {
    if (id) {
      dispatch(fetchSourceLanguageWaiverTemplateByID(id));
    }
  }, [id]);
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const existingWaiverTemplate = id
    ? waiverTemplates.find((waiverTemplate) => waiverTemplate.id === id) ??
      undefined
    : undefined;
  const dispatch = useDispatch();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const initialValues = React.useMemo(
    () => getInitialValues(existingWaiverTemplate),
    [existingWaiverTemplate]
  );

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const productIdsUnusedInOtherTemplates = React.useCallback(
    (productIds: any) => {
      if (!productIds) return '';

      for (const waiverTemplate of waiverTemplates) {
        if (waiverTemplate.id === id) {
          continue;
        }

        for (const productId of waiverTemplate.product_ids ?? []) {
          if (productIds.includes(productId)) {
            const productName =
              products.find((product) => product.id === productId)
                ?.product_name ?? productId;
            return t(
              'Waiver template "{{templateName}}" is already assigned to product "{{productName}}"',
              {
                templateName: waiverTemplate.template_name,
                productName,
              }
            );
          }
        }
      }

      return '';
    },
    [waiverTemplates, products, id, t]
  );
  const productIdsHaveFullNameFormField = React.useCallback(
    (productIds: string[]) => {
      if (!productIds) return '';

      for (const productId of productIds) {
        if (
          productId &&
          !products
            .find((p) => p.id === productId)
            ?.reservation_form_fields?.some(
              (f) => f.key === 'full_name' || f.key === 'kanji_full_name'
            )
        ) {
          return t(
            'Please set reservation form with "Full Name" or "Full Name(Kanji)" to product "{{productName}}"',
            {
              productName: products.find((p) => p.id === productId)
                ?.product_name,
            }
          );
        }
      }

      return '';
    },
    [products, t]
  );
  return (
    <div className={baseStyles['base-main__body']}>
      <div className={styles['back-arrow']}>
        <Link to="/waivers/templates">
          <BackArrow />
        </Link>
      </div>
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            const waiverTemplate =
              convertFormValuesToSwaggerWaiverTemplate(values);
            const waiverSaveAction = id
              ? updateWaiverTemplate(id, { ...waiverTemplate })
              : createWaiverTemplate(waiverTemplate);

            if (
              values?.showTranslationMode &&
              values?.translationTargetLanguage
            ) {
              const sourceLanguage = values?.sourceLanguage;
              const translationTargetLanguage =
                values?.translationTargetLanguage;
              await Promise.all([
                dispatch(waiverSaveAction),
                dispatch(
                  updateTranslations(
                    values?.translations.map((translation) => ({
                      source_language: values?.sourceLanguage,
                      [sourceLanguage.toLowerCase()]: translation.source,
                      [translationTargetLanguage?.toLowerCase()]:
                        translation.target,
                    }))
                  )
                ),
              ]);
            } else {
              await dispatch(waiverSaveAction);
            }

            setSaveSucceeded(true);

            if (!id) {
              const url = `/waivers/templates/${
                store.getState().waiverTemplates.lastCreated?.id ?? ''
              }/edit`;
              history.push(url);
            }
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        validate={(values: FormValues) => {
          if (values.signatureType === 'PER_PARTICIPANT_WITH_NAMES') {
            const productIds = productIdsHaveFullNameFormField(
              values.productIds
            );

            if (productIds) {
              return {
                productIds,
              };
            }
          }

          return undefined;
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={baseStyles['base-main__body__box']}>
                <div className={baseStyles['base-main__body__box__body']}>
                  <p className={styles['table__section__header']}>
                    {t('Settings')}
                  </p>
                  <FormTableBox>
                    <table>
                      <tbody>
                        <tr>
                          <th>{t('Template Name')}</th>
                          <td>
                            <Field name="templateName">
                              {({ input }) => <Input {...input} />}
                            </Field>
                          </td>
                          <td
                            className={clsx(
                              styles['c-table-list__btns'],
                              styles['hidden']
                            )}
                          />
                        </tr>

                        <tr>
                          <th>{t('Products')}</th>
                          <td>
                            {productsLoading ? (
                              <ModalLoader />
                            ) : (
                              <Field
                                name="productIds"
                                validate={productIdsUnusedInOtherTemplates}
                              >
                                {({ input, meta: { error, touched } }) => (
                                  <>
                                    <ProductIdsListInput
                                      productIds={input.value}
                                      onChange={(newProductIds) =>
                                        input.onChange(newProductIds)
                                      }
                                      error={touched ? error : undefined}
                                    />
                                    <FocusableInput name="productIds" />
                                  </>
                                )}
                              </Field>
                            )}
                          </td>
                          <td
                            className={clsx(
                              styles['c-table-list__btns'],
                              styles['hidden']
                            )}
                          />
                        </tr>
                        <tr>
                          <th>{t('Waiver Type')}</th>
                          <td>
                            <EnumRadioButtonGroup
                              isRequired
                              name="signatureType"
                              options={[
                                {
                                  value: 'PER_BOOKING',
                                  label: t('One waiver form per booking'),
                                },
                                {
                                  value: 'PER_PARTICIPANT',
                                  label: t(
                                    'One waiver form for each participant (no name tracking)'
                                  ),
                                },
                                {
                                  value: 'PER_PARTICIPANT_WITH_NAMES',
                                  label: t(
                                    'One waiver form for each participant (with name tracking)'
                                  ),
                                },
                              ]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Language')}</th>
                          <td>
                            {id != null ? (
                              values?.sourceLanguage === 'EN_US' ? (
                                t('English')
                              ) : (
                                t('Japanese')
                              )
                            ) : (
                              <EnumRadioButtonGroup
                                isRequired
                                name="sourceLanguage"
                                options={[
                                  {
                                    value: 'JA_JP',
                                    label: t('Japanese'),
                                  },
                                  {
                                    value: 'EN_US',
                                    label: t('English'),
                                  },
                                ]}
                              />
                            )}
                          </td>
                        </tr>
                        {values?.sourceLanguage && (
                          <tr>
                            <th>{t('Translated Languages')}</th>
                            <td>
                              <Field name="translatedLanguages">
                                {({ input }) => (
                                  <MultiselectListInput
                                    options={[
                                      {
                                        value: 'EN_US',
                                        text: t('English'),
                                      },
                                      {
                                        value: 'JA_JP',
                                        text: t('Japanese'),
                                      },
                                      {
                                        value: 'KO_KR',
                                        text: t('Korean'),
                                      },
                                      {
                                        value: 'ZH_CN',
                                        text: t('Chinese (Simplified)'),
                                      },
                                      {
                                        value: 'ZH_TW',
                                        text: t('Chinese (Traditional)'),
                                      },
                                    ].filter(
                                      (option) =>
                                        option.value !== values?.sourceLanguage
                                    )}
                                    value={values?.translatedLanguages}
                                    onChange={(languages) =>
                                      input.onChange(languages)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormTableBox>

                  <WaiverTemplateContentEditor
                    waiverTemplateId={existingWaiverTemplate?.id}
                  />

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      loading={submitting}
                      size="small"
                      style="blue"
                      type="submit"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
