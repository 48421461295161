import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { ReservationBooking } from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationBooking';

export const PartnershipReservationBooking = () => {
  return (
    <PartnershipModeContext.Provider value={{ partnershipMode: true }}>
      <ReservationBooking />
    </PartnershipModeContext.Provider>
  );
};
