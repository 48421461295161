import * as React from 'react';

import { BaseSection } from './BaseSection';
import { Section, SocialMedia as SocialMediaType } from './util';

type Props = {
  data: Section;
};

const getWidthByNumberOfSocialMedia = (socialMedia: SocialMediaType) => {
  let count = 0;
  if (socialMedia.facebookUrl) {
    count++;
  }
  if (socialMedia.instagramUrl) {
    count++;
  }
  if (socialMedia.tiktokUrl) {
    count++;
  }
  if (socialMedia.websiteurl) {
    count++;
  }
  if (socialMedia.xUrl) {
    count++;
  }

  let width = '40%';
  switch (count) {
    case 4:
      width = '35%';
      break;
    case 3:
      width = '25%';
      break;
    case 2:
      width = '15%';
      break;
  }

  return width;
};

export const SocialMedia = ({ data }: Props) => {
  const socialMedia = data.socialMedia;

  if (!socialMedia) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <tr>
        <td style={{ textAlign: 'center' }}>
          <table
            style={{
              width: getWidthByNumberOfSocialMedia(socialMedia),
              margin: '0 auto',
            }}
          >
            <tbody>
              <tr>
                {socialMedia.facebookUrl && (
                  <td style={{ textAlign: 'center' }}>
                    <a
                      href={socialMedia.facebookUrl}
                      target="__blank"
                      style={{
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={socialMedia.facebookImageUrl}
                        height={32}
                        width={32}
                      />
                    </a>
                  </td>
                )}
                {socialMedia.xUrl && (
                  <td style={{ textAlign: 'center' }}>
                    <a
                      href={socialMedia.xUrl}
                      target="__blank"
                      style={{
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <img src={socialMedia.xImageUrl} height={32} width={32} />
                    </a>
                  </td>
                )}
                {socialMedia.instagramUrl && (
                  <td style={{ textAlign: 'center' }}>
                    <a
                      href={socialMedia.instagramUrl}
                      target="__blank"
                      style={{
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={socialMedia.instagramImageUrl}
                        height={32}
                        width={32}
                      />
                    </a>
                  </td>
                )}
                {socialMedia.tiktokUrl && (
                  <td style={{ textAlign: 'center' }}>
                    <a
                      href={socialMedia.tiktokUrl}
                      target="__blank"
                      style={{
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={socialMedia.tiktokImageUrl}
                        height={32}
                        width={32}
                      />
                    </a>
                  </td>
                )}
                {socialMedia.websiteurl && (
                  <td style={{ textAlign: 'center' }}>
                    <a
                      href={socialMedia.websiteurl}
                      target="__blank"
                      style={{
                        textDecoration: 'none',
                      }}
                      rel="noreferrer"
                    >
                      <img
                        src={socialMedia.websiteImageUrl}
                        height={32}
                        width={32}
                      />
                    </a>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </BaseSection>
  );
};
