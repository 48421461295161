import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { config } from 'client/config';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { createPromotion, updatePromotion } from 'client/actions/promotions';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  getPromotionTypeText,
  getPromotionStatusText,
} from 'client/libraries/util/promotionTextHelpers';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { getProductCurrencyCode } from 'client/libraries/util/getProductCurrencyCode';
import {
  applyPriceDeltaToGross,
  applyPriceDeltaToNet,
} from 'client/libraries/util/applyPriceDelta';
import {
  PromotionShape,
  DateRange,
  getPriceSchedulesActiveInDateRanges,
  dateRangesOverlap,
} from 'client/libraries/util/promotionShape';
import type { ReduxState } from 'client/reducers';
import { toProductShape } from 'client/libraries/util/productShape';
import { productOptionsSelector } from 'client/reducers/products';
import { PromotionDetails } from 'client/pages/ProductDetails/ProductContentsPane/PromotionDetails/PromotionDetails';
import { UnitPriceDeltaListInput } from 'client/pages/v3/Product/ProductPromotions/DiscountInput/UnitPriceDeltaListInput';
import { FamilyDiscountInput } from 'client/pages/v3/Product/ProductPromotions/DiscountInput/FamilyDiscountInput';
import { GroupDiscountInput } from 'client/pages/v3/Product/ProductPromotions/DiscountInput/GroupDiscountInput';
import { ChannelListInput } from 'client/pages/v3/Product/ProductPromotions/DiscountInput/ChannelListInput';
import { Message } from 'client/components/Message/Message';
import type {
  Channel,
  DayOfWeek,
  FamilyDiscount,
  GroupDiscount,
  NewPromotion,
  Promotion,
  PromotionPatch,
  PromotionType,
  PromotionStatus,
  RelativeDateTime,
  UnitPriceDelta,
  Pricing,
} from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import { Radio } from 'client/components/v3/Form/Radio';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Modal } from 'client/components/v3/Form/Modal';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import styles from 'client/pages/v3/Product/ProductPromotions/ProductPromotions.module.css';

const promotionActiveForPriceSchedule = (
  promotion: Promotion,
  priceSchedule: Pricing
): boolean => {
  if (
    !promotion.participation_days_of_week?.some((day) =>
      priceSchedule.days_of_week?.includes(day)
    )
  ) {
    return false;
  }

  if (!promotion.participation_date_ranges?.length) {
    return true;
  }

  const priceDateRange = {
    startDate: priceSchedule.start_date_local ?? '',
    endDate: priceSchedule.end_date_local ?? '',
  };

  return promotion.participation_date_ranges.some((dateRange) =>
    dateRangesOverlap(
      {
        startDate: dateRange.start_date_local || '',
        endDate: dateRange.end_date_local || '',
      },
      priceDateRange
    )
  );
};

const allDaysOfWeek = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
] as DayOfWeek[];

type Props = {
  setShowModal: (show: boolean) => void;
  open: boolean;
  inputPromotion?: PromotionShape | null;
};

export const PromotionSettingsModal = ({
  setShowModal,
  open,
  inputPromotion,
}: Props) => {
  const { t } = useTranslation();
  // State
  const [promotionAppliesToAllProducts, setPromotionAppliesToAllProducts] =
    useState<boolean>(false);
  const [productId, setProductId] = useState<string>('');
  const [promotionType, setPromotionType] =
    useState<PromotionType>('SIMPLE_DISCOUNT');
  const [promotionStatus, setPromotionStatus] = useState<PromotionStatus>('ON');
  const [participationDateRanges, setParticipationDateRanges] = useState<
    DateRange[]
  >([]);
  const [participationDaysOfWeek, setParticipationDaysOfWeek] = useState<
    DayOfWeek[]
  >([]);
  const [bookedDateLocalFrom, setBookedDateLocalFrom] = useState<string>('');
  const [bookedDateLocalTo, setBookedDateLocalTo] = useState<string>('');
  const [unitPriceDeltas, setUnitPriceDeltas] = useState<UnitPriceDelta[]>([]);
  const [earlyBirdDeadline, setEarlyBirdDeadline] = useState<RelativeDateTime>({
    type: 'DAY',
    count: 30,
    time_local: '12:00',
  });
  const [lastMinuteStart, setLastMinuteStart] = useState<RelativeDateTime>({
    type: 'HOUR',
    count: 24,
  });
  const [familyDiscount, setFamilyDiscount] = useState<FamilyDiscount>({
    qualifying_base_guest_count: 1,
  });
  const [groupDiscount, setGroupDiscount] = useState<GroupDiscount>({
    min_guest_count: 1,
  });
  const [channels, setChannels] = useState<Channel[]>([]);
  const [appliesToSpecificChannels, setAppliesToSpecificChannels] =
    useState<boolean>(false);

  // |                     | N/A  | promoCode | oneTime | auto  |
  // |---------------------|------|-----------|---------|-------|
  // | usePromoCode        | true | true      | false   | false |
  // | useOneTimePromoCode | true | false     | true    | false |

  const [usePromoCode, setUsePromoCode] = useState<boolean>(false);
  const [useOneTimePromoCode, setUseOneTimePromoCode] =
    useState<boolean>(false);

  const [referenceLabel, setReferenceLabel] = useState<string>('');
  const [promoCode, setPromoCode] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [onProductListClick, setOnProductListClick] = useState<boolean>(false);
  useEffect(() => {
    setPromotionAppliesToAllProducts(
      (inputPromotion && inputPromotion.appliesToAllProducts) || false
    );
    setProductId((inputPromotion && inputPromotion.productId) || '');
    setPromotionType(
      (inputPromotion && inputPromotion.type) || 'SIMPLE_DISCOUNT'
    );
    setPromotionStatus((inputPromotion && inputPromotion.status) || 'ON');
    setParticipationDateRanges(
      (inputPromotion && inputPromotion.participationDateRanges) || [
        { startDate: '', endDate: '' },
      ]
    );
    setParticipationDaysOfWeek(
      (inputPromotion && inputPromotion.participationDaysOfWeek) || [
        ...allDaysOfWeek,
      ]
    );
    setBookedDateLocalFrom(
      (inputPromotion && inputPromotion.bookedDateLocalFrom) || ''
    );
    setBookedDateLocalTo(
      (inputPromotion && inputPromotion.bookedDateLocalTo) || ''
    );
    setUnitPriceDeltas(
      (inputPromotion && inputPromotion.unitPriceDeltas) || []
    );
    setEarlyBirdDeadline(
      (inputPromotion && inputPromotion.earlyBirdDeadline) || {
        type: 'DAY',
        count: 30,
        time_local: '12:00',
      }
    );
    setLastMinuteStart(
      (inputPromotion && inputPromotion.lastMinuteStart) || {
        type: 'HOUR',
        count: 24,
      }
    );
    setFamilyDiscount(
      (inputPromotion && inputPromotion.familyDiscount) || {
        qualifying_base_guest_count: 1,
      }
    );
    setGroupDiscount(
      (inputPromotion && inputPromotion.groupDiscount) || {
        min_guest_count: 1,
      }
    );
    setChannels((inputPromotion && inputPromotion.channels) || []);
    setAppliesToSpecificChannels(
      (inputPromotion &&
        inputPromotion.channels &&
        inputPromotion.channels.length > 0) ||
        false
    );
    setUsePromoCode(
      Boolean(inputPromotion && inputPromotion.promoCode) || false
    );
    setPromoCode((inputPromotion && inputPromotion.promoCode) || '');

    setUseOneTimePromoCode(
      Boolean(inputPromotion && inputPromotion.useOneTimePromoCode) || false
    );
    setReferenceLabel((inputPromotion && inputPromotion.referenceLabel) || '');

    setShowErrors(false);
  }, [inputPromotion]);
  // Redux
  const dispatch = useDispatch();

  const selectedProduct = useSelector(
    (state: ReduxState) => (productId && state.products.byID[productId]) || null
  );
  const contractedAgents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );
  const inputPromotionId = (inputPromotion && inputPromotion.id) || '';
  const mode: 'UPDATE' | 'CREATE' = inputPromotionId ? 'UPDATE' : 'CREATE';
  // Effects
  useEffect(() => {
    if (open) {
      dispatch(fetchContractedOrganizations());
    }
  }, [dispatch, open]);
  useEffect(() => {
    if (open && !inputPromotionId) {
      dispatch(fetchProducts());
    }
  }, [dispatch, inputPromotionId, open]);
  useEffect(() => {
    if (productId && open) {
      dispatch(fetchProductByID(productId));
    }
  }, [dispatch, productId, open]);
  const productOptions = useSelector(productOptionsSelector);
  const promotionTypeOptions = [
    'SIMPLE_DISCOUNT',
    'EARLY_BIRD_DISCOUNT',
    'LAST_MINUTE_DISCOUNT',
    'ADDITIONAL_CHARGE',
    'GROUP_DISCOUNT',
    'FAMILY_DISCOUNT',
  ].map((type) => ({
    text: getPromotionTypeText(type as PromotionType, t),
    value: type,
    key: type,
  }));
  const headerText =
    mode === 'UPDATE' ? t('Edit Promotion') : t('Create New Promotion');
  let unitPriceMethod: 'PER_PARTICIPANT' | 'PER_BOOKING' | 'ANY' =
    'PER_PARTICIPANT';
  let allGuestTypeKeys: string[] = [];

  const nonEmptyParticipationDateRanges = participationDateRanges.filter(
    (dateRange) => dateRange.startDate || dateRange.endDate
  );

  const startDateLocalFrom = nonEmptyParticipationDateRanges?.length
    ? nonEmptyParticipationDateRanges[0].startDate
    : '';
  const startDateLocalTo = nonEmptyParticipationDateRanges?.length
    ? nonEmptyParticipationDateRanges[0].endDate
    : '';

  if (promotionAppliesToAllProducts) {
    unitPriceMethod = 'ANY';
  } else if (selectedProduct) {
    const priceSchedules = getPriceSchedulesActiveInDateRanges(
      selectedProduct.pricing || [],
      nonEmptyParticipationDateRanges
    );

    if (priceSchedules.length > 0) {
      if (
        priceSchedules[0].units.length > 0 &&
        priceSchedules[0].units[0].method === 'PER_BOOKING'
      ) {
        unitPriceMethod = 'PER_BOOKING';
      }

      if (unitPriceMethod === 'PER_PARTICIPANT') {
        allGuestTypeKeys = [
          ...new Set(
            _.flatten(
              priceSchedules.map((priceSched) =>
                priceSched.units
                  .map((unit) => (unit.guest_type ? unit.guest_type.key : ''))
                  .filter((key) => !!key)
              )
            )
          ),
        ];
      }
    }
  }

  let unitPriceDeltaGuestTypeKeys = allGuestTypeKeys;

  if (promotionType === 'FAMILY_DISCOUNT') {
    unitPriceDeltaGuestTypeKeys =
      familyDiscount && familyDiscount.discount_guest_type_key
        ? [familyDiscount.discount_guest_type_key]
        : [];
  }

  if (promotionType === 'GROUP_DISCOUNT') {
    unitPriceDeltaGuestTypeKeys = (
      groupDiscount?.eligible_guest_type_keys || []
    ).filter((key) => allGuestTypeKeys.includes(key));
  }

  const nonZeroDeltas: UnitPriceDelta[] = unitPriceDeltas
    .filter(
      (delta: UnitPriceDelta) =>
        (unitPriceMethod !== 'PER_PARTICIPANT' ||
          (unitPriceDeltaGuestTypeKeys.includes(
            delta.guest_type_key as string
          ) &&
            allGuestTypeKeys.includes(delta.guest_type_key as string))) &&
        (delta.delta_fixed_gross ||
          delta.delta_fixed_net ||
          delta.delta_percent)
    )
    .map((delta: UnitPriceDelta) => {
      // Ensure 'DISCOUNT'/'CHARGE' corresponds with the promotion type. Cloning a simple discount from
      // an additional charge (or vice versa) without changing amounts would not change delta type so
      // we need to do it here.
      return {
        ...delta,
        delta_type:
          promotionType === 'ADDITIONAL_CHARGE' ? 'CHARGE' : 'DISCOUNT',
      };
    });

  const buildPromotionObject = (): Promotion => {
    return {
      id: inputPromotionId || 'dummyId',
      product_id: productId,
      type: promotionType,
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const buildNewPromotionObject = (): NewPromotion => {
    return {
      product_id: productId,
      type: promotionType,
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const buildPromotionPatch = (): PromotionPatch => {
    return {
      status: promotionStatus,
      participation_date_ranges: nonEmptyParticipationDateRanges.map(
        (dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })
      ),
      participation_days_of_week: participationDaysOfWeek,
      booked_date_local_from: bookedDateLocalFrom,
      booked_date_local_to: bookedDateLocalTo,
      unit_price_deltas: nonZeroDeltas as any,
      early_bird_deadline:
        promotionType === 'EARLY_BIRD_DISCOUNT' ? earlyBirdDeadline : undefined,
      last_minute_start:
        promotionType === 'LAST_MINUTE_DISCOUNT' ? lastMinuteStart : undefined,
      family_discount:
        promotionType === 'FAMILY_DISCOUNT' ? familyDiscount : undefined,
      group_discount:
        promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
      channels,
      promo_code: promoCode,
      use_one_time_promo_code: useOneTimePromoCode,
      reference_label: referenceLabel,
    };
  };

  const previewPromotion: Promotion = buildPromotionObject();
  const previewPricing = (
    (selectedProduct && selectedProduct.pricing) ||
    []
  ).map((priceSchedule) => {
    const previewPromotionIsActive =
      previewPromotion.status === 'ON' &&
      promotionActiveForPriceSchedule(previewPromotion, priceSchedule);

    return {
      ...priceSchedule,
      promotions: !previewPromotionIsActive
        ? priceSchedule.promotions
        : [
            ...((priceSchedule.promotions || [])?.filter(
              (promo) =>
                !previewPromotion.id || promo.id !== previewPromotion.id
            ) ?? []),
            {
              type: promotionType,
              participation_date_ranges: nonEmptyParticipationDateRanges.map(
                (dateRange) => ({
                  start_date_local: dateRange.startDate,
                  end_date_local: dateRange.endDate,
                })
              ),
              participation_days_of_week: participationDaysOfWeek,
              booked_date_local_from: bookedDateLocalFrom,
              booked_date_local_to: bookedDateLocalTo,
              early_bird_deadline:
                promotionType === 'EARLY_BIRD_DISCOUNT'
                  ? earlyBirdDeadline
                  : undefined,
              last_minute_start:
                promotionType === 'LAST_MINUTE_DISCOUNT'
                  ? lastMinuteStart
                  : undefined,
              family_discount:
                promotionType === 'FAMILY_DISCOUNT'
                  ? familyDiscount
                  : undefined,
              group_discount:
                promotionType === 'GROUP_DISCOUNT' ? groupDiscount : undefined,
              channels,
              promo_code: promoCode,
              adjusted_unit_prices: (
                previewPromotion.unit_price_deltas || []
              ).map((unitPriceDelta) => {
                const unit = priceSchedule.units.find((unit) => {
                  if (unitPriceDelta.unit_price_method === 'PER_PARTICIPANT') {
                    return (
                      unitPriceDelta.guest_type_key ===
                      (unit.guest_type && unit.guest_type.key)
                    );
                  }

                  return unitPriceDelta.unit_price_method === unit.method;
                });
                let adjustedGross = '';
                let adjustedNet = '';

                if (unit) {
                  adjustedGross = applyPriceDeltaToGross(
                    unit.gross,
                    unitPriceDelta
                  );
                  adjustedNet = applyPriceDeltaToNet(unit.net, unitPriceDelta);
                }

                return {
                  ...unitPriceDelta,
                  unit_price_method: unit?.method,
                  adjusted_gross: adjustedGross,
                  adjusted_net: adjustedNet,
                };
              }),
            },
          ],
    };
  });

  const showUnitPriceDeltaListInput =
    promotionType !== 'FAMILY_DISCOUNT' ||
    (promotionType === 'FAMILY_DISCOUNT' &&
      familyDiscount &&
      familyDiscount.discount_guest_type_key);
  const currencyCode = selectedProduct
    ? getProductCurrencyCode(selectedProduct)
    : '';

  const validate = (): string[] => {
    const validationErrors: string[] = [];

    if (usePromoCode && !promoCode) {
      validationErrors.push(t('Promo code required'));
    }

    if (useOneTimePromoCode && !referenceLabel) {
      validationErrors.push(t('Reference label required'));
    }

    if (promotionAppliesToAllProducts || productId) {
      if (!startDateLocalFrom && startDateLocalTo) {
        validationErrors.push(t('Eligible participation start date required'));
      }

      if (startDateLocalFrom && !startDateLocalTo) {
        validationErrors.push(t('Eligible participation end date required'));
      }

      if (!bookedDateLocalFrom && bookedDateLocalTo) {
        validationErrors.push(t('Eligible booking start date required'));
      }

      if (bookedDateLocalFrom && !bookedDateLocalTo) {
        validationErrors.push(t('Eligible booking end date required'));
      }

      if (
        !startDateLocalFrom &&
        !startDateLocalTo &&
        !bookedDateLocalFrom &&
        !bookedDateLocalTo
      ) {
        validationErrors.push(
          t('Eligible participation or booking  date required')
        );
      }
    }

    switch (promotionType) {
      case 'FAMILY_DISCOUNT':
        if (!familyDiscount.base_guest_type_key) {
          validationErrors.push(t('Family discount: base unit required'));
        }

        if (!familyDiscount.discount_guest_type_key) {
          validationErrors.push(t('Family discount: discount unit required'));
        }

        break;

      case 'EARLY_BIRD_DISCOUNT':
        if (
          earlyBirdDeadline.type === 'DAY' &&
          !matchesFormat(earlyBirdDeadline.time_local || '', '24-hour-time')
        ) {
          validationErrors.push(
            t("Early bird discount: 'hh:mm' required for promotion deadline")
          );
        }

        break;

      case 'LAST_MINUTE_DISCOUNT':
        if (
          lastMinuteStart.type === 'DAY' &&
          !matchesFormat(lastMinuteStart.time_local || '', '24-hour-time')
        ) {
          validationErrors.push(
            t("Last minute discount: 'hh:mm' required for promotion start")
          );
        }

        break;

      case 'GROUP_DISCOUNT':
        if (
          !groupDiscount?.eligible_guest_type_keys?.some((key) =>
            allGuestTypeKeys.includes(key)
          )
        ) {
          validationErrors.push(
            t('Group discount: At least one unit discount setting is required')
          );
        }

        break;

      default:
    }

    return validationErrors;
  };

  const validationErrors = validate();

  return (
    <>
      <Modal
        title={headerText}
        open={open}
        onClose={() => {
          setShowModal(false);
        }}
        rightActionChildren={
          <>
            <Button
              text={t('Cancel')}
              uiType="bg"
              size="md"
              color="white"
              onClick={() => {
                setShowModal(false);
              }}
            />
            <Button
              type="submit"
              text={t('Save')}
              uiType="bg"
              size="md"
              color="primary"
              disabled={
                (!productId && !promotionAppliesToAllProducts) ||
                !promotionStatus ||
                !promotionType
              }
              onClick={() => {
                if (validationErrors.length > 0) {
                  setShowErrors(true);
                  return;
                }

                if (inputPromotionId) {
                  dispatch(
                    updatePromotion(inputPromotionId, buildPromotionPatch())
                  );
                } else {
                  dispatch(createPromotion(buildNewPromotionObject()));
                }
                setShowModal(false);
                setShowErrors(false);
              }}
            />
          </>
        }
        style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
      >
        <div className={styles['p-downloadModal']}>
          <div className={styles['p-downloadModal__item']}>
            <div className={styles['p-downloadModal__item__ttl']}>
              {t('Promotion Coverage')}
            </div>
            <div className={styles['p-downloadModal__item__body']}>
              {mode === 'UPDATE' ? (
                promotionAppliesToAllProducts && <div>{t('All Products')}</div>
              ) : (
                <div className={styles['p-downloadModal__item__body__check']}>
                  <Radio
                    checked={!promotionAppliesToAllProducts}
                    label={t('Promotion applies to a single product', {
                      context: 'PromotionSettingsModal',
                    })}
                    onChange={() => {
                      setPromotionAppliesToAllProducts(false);
                    }}
                    size="sm"
                  />
                  <div style={{ marginLeft: '16px' }}>
                    <Radio
                      checked={promotionAppliesToAllProducts}
                      label={t('Promotion applies to all products', {
                        context: 'PromotionSettingsModal',
                      })}
                      onChange={() => {
                        setProductId('');
                        setPromotionType('SIMPLE_DISCOUNT');
                        setUnitPriceDeltas([
                          {
                            delta_type: 'DISCOUNT',
                            unit_price_method: 'ANY',
                            delta_percent: 0,
                          },
                        ]);
                        setPromotionAppliesToAllProducts(true);
                      }}
                      size="sm"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles['p-downloadModal__item']}>
            <div className={styles['p-downloadModal__item__ttl']}>
              {t('Product')}
            </div>
            <div
              className={styles['p-downloadModal__item__body']}
              style={onProductListClick ? { height: '350px' } : {}}
            >
              {!promotionAppliesToAllProducts &&
                (mode === 'UPDATE' ? (
                  <div>
                    {selectedProduct &&
                      (selectedProduct.internal_product_name ??
                        selectedProduct.product_name)}
                  </div>
                ) : (
                  <SingleDropdown
                    searchable
                    selectedOption={productId}
                    options={productOptions}
                    onChange={(value) => {
                      setProductId(value);
                    }}
                    onClick={setOnProductListClick}
                  />
                ))}
            </div>
          </div>
          {(productId || promotionAppliesToAllProducts) && (
            <>
              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Promotion Type')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  {mode === 'UPDATE' || promotionAppliesToAllProducts ? (
                    <div>{getPromotionTypeText(promotionType, t)}</div>
                  ) : (
                    <SingleDropdown
                      selectedOption={promotionType}
                      options={promotionTypeOptions}
                      onChange={(value) => {
                        setPromotionType(value as PromotionType);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Applicable Conditions')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  <div className={styles['p-downloadModal__item__body__check']}>
                    <Radio
                      checked={!usePromoCode && !useOneTimePromoCode}
                      label={t('Apply automatically')}
                      onChange={() => {
                        setPromoCode('');
                        setReferenceLabel('');
                        setUsePromoCode(false);
                        setUseOneTimePromoCode(false);
                      }}
                      size="sm"
                    />
                    <div style={{ marginLeft: '16px' }}>
                      <Radio
                        checked={usePromoCode && !useOneTimePromoCode}
                        label={t('Promo code')}
                        onChange={() => {
                          setReferenceLabel('');
                          setUsePromoCode(true);
                          setUseOneTimePromoCode(false);
                        }}
                        size="sm"
                      />
                    </div>
                    {config.enableInstantWin && (
                      <Radio
                        checked={!usePromoCode && useOneTimePromoCode}
                        label={t('One time promo code')}
                        onChange={() => {
                          setPromoCode('');
                          setUsePromoCode(false);
                          setUseOneTimePromoCode(true);
                        }}
                        size="sm"
                      />
                    )}
                  </div>
                </div>
              </div>
              {usePromoCode && (
                <div className={styles['p-downloadModal__item']}>
                  <div className={styles['p-downloadModal__item__ttl']}>
                    {t('Promo Code')}
                  </div>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      required
                      error={(showErrors && !promoCode) as any}
                      onChange={(value) => {
                        setPromoCode(value);
                      }}
                      value={promoCode}
                    />
                  </div>
                </div>
              )}

              {useOneTimePromoCode && (
                <div className={styles['p-downloadModal__item']}>
                  <div className={styles['p-downloadModal__item__ttl']}>
                    {t('Reference Label')}
                  </div>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      error={(showErrors && !referenceLabel) as any}
                      required
                      value={referenceLabel}
                      onChange={(value) => {
                        setReferenceLabel(value);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Eligible Participation Date Range')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  <Box mb={2}>
                    {participationDateRanges?.map((dateRange, index) => (
                      <Box
                        mt={2}
                        display="flex"
                        alignItems="center"
                        key={index}
                      >
                        <DateRangeInput
                          dateFrom={dateRange.startDate}
                          dateTo={dateRange.endDate}
                          onChange={(dates: [string | null, string | null]) => {
                            const [startDate, endDate] = dates;
                            const newParticipationDateRanges = [
                              ...participationDateRanges,
                            ];
                            newParticipationDateRanges[index] = {
                              startDate: startDate || '',
                              endDate: endDate || '',
                            };
                            setParticipationDateRanges(
                              newParticipationDateRanges
                            );
                          }}
                        />
                        <Box ml={1}>
                          <Button
                            size="icon"
                            color="tertiarygray"
                            iconBeforeText={
                              <i className="c-icon-outline-general-trash-03"></i>
                            }
                            onClick={() => {
                              const newParticipationDateRanges = [
                                ...participationDateRanges,
                              ];
                              newParticipationDateRanges.splice(index, 1);
                              setParticipationDateRanges(
                                newParticipationDateRanges
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Box mt={3}>
                      <a
                        className={styles['add__button']}
                        onClick={() => {
                          setParticipationDateRanges([
                            ...participationDateRanges,
                            {
                              startDate: '',
                              endDate: '',
                            },
                          ]);
                        }}
                      >
                        <i className="c-icon-outline-general-plus-circle"></i>
                        {t('Add Participation Date Range')}
                      </a>
                    </Box>
                  </Box>
                </div>
              </div>

              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Eligible Participation Days of Week')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  <WeekdaysInput
                    value={participationDaysOfWeek}
                    onChange={setParticipationDaysOfWeek}
                  />
                </div>
              </div>

              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Eligible Booking Date Range')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  <DateRangeInput
                    dateTo={bookedDateLocalFrom}
                    dateFrom={bookedDateLocalTo}
                    onChange={async (dates: [string | null, string | null]) => {
                      const [startDate, endDate] = dates;
                      await setBookedDateLocalFrom(startDate || '');
                      await setBookedDateLocalTo(endDate || '');
                    }}
                  />
                </div>
              </div>

              <div className={styles['p-downloadModal__item']}>
                <div className={styles['p-downloadModal__item__ttl']}>
                  {t('Status')}
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  <div className={styles['p-downloadModal__item__body__check']}>
                    <Radio
                      checked={promotionStatus == 'ON'}
                      label={getPromotionStatusText('ON', t)}
                      onChange={() => {
                        setPromotionStatus('ON');
                      }}
                      size="sm"
                    />
                    <Radio
                      checked={promotionStatus == 'OFF'}
                      label={getPromotionStatusText('OFF', t)}
                      onChange={() => {
                        setPromotionStatus('OFF');
                      }}
                      size="sm"
                    />
                    <Radio
                      checked={promotionStatus == 'DISCONTINUED'}
                      label={getPromotionStatusText('DISCONTINUED', t)}
                      onChange={() => {
                        setPromotionStatus('DISCONTINUED');
                      }}
                      size="sm"
                    />
                  </div>
                </div>
              </div>

              {promotionType === 'EARLY_BIRD_DISCOUNT' && (
                <div className={styles['p-downloadModal__item']}>
                  <div className={styles['p-downloadModal__item__ttl']}>
                    {t('Discount')}
                  </div>
                  <div className={styles['p-downloadModal__item__body']}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: 'calc((100% - 20px) / 3)',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'var(--text-semibold)',
                          }}
                        >
                          <div>{t('Discount applies until ')}</div>
                        </div>
                        <TextField
                          value={earlyBirdDeadline.count || (0 as any)}
                          onChange={(value) => {
                            let count = parseInt(value, 10);

                            if (isNaN(count)) {
                              count = 0;
                            }

                            setEarlyBirdDeadline({
                              ...earlyBirdDeadline,
                              count,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: 'calc((100% - 20px) / 3)',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'var(--text-semibold)',
                          }}
                        >
                          <div>{t('Days/Hours')}</div>
                        </div>
                        <SingleDropdown
                          selectedOption={
                            earlyBirdDeadline && earlyBirdDeadline.type
                          }
                          options={[
                            {
                              text: t('days before participation'),
                              value: 'DAY',
                            },
                            {
                              text: t('hours before participation'),
                              value: 'HOUR',
                            },
                          ]}
                          onChange={(value) => {
                            setEarlyBirdDeadline({
                              ...earlyBirdDeadline,
                              type: value as any,
                            });
                          }}
                        />
                      </div>
                      {earlyBirdDeadline.type === 'DAY' && (
                        <div
                          style={{
                            width: 'calc((100% - 20px) / 3)',
                            marginRight: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: 'var(--text-semibold)',
                            }}
                          >
                            <div>{t('at')}</div>
                          </div>
                          <TextField
                            required
                            error={
                              showErrors &&
                              (!matchesFormat(
                                earlyBirdDeadline.time_local || '',
                                '24-hour-time'
                              ) as any)
                            }
                            value={earlyBirdDeadline.time_local || ''}
                            placeholder={'hh:mm (24-hour time)'}
                            onChange={(value) =>
                              setEarlyBirdDeadline({
                                ...earlyBirdDeadline,
                                time_local: value,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {promotionType === 'LAST_MINUTE_DISCOUNT' && (
                <div className={styles['p-downloadModal__item']}>
                  <div className={styles['p-downloadModal__item__ttl']}>
                    {t('Discount')}
                  </div>
                  <div className={styles['p-downloadModal__item__body']}>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: 'calc((100% - 20px) / 3)',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'var(--text-semibold)',
                          }}
                        >
                          <div>{t('Discount starts ')}</div>
                        </div>
                        <TextField
                          value={lastMinuteStart.count || (0 as any)}
                          onChange={(value) => {
                            let count = parseInt(value, 10);

                            if (isNaN(count)) {
                              count = 0;
                            }

                            setLastMinuteStart({ ...lastMinuteStart, count });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: 'calc((100% - 20px) / 3)',
                          marginRight: '10px',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 'var(--text-semibold)',
                          }}
                        >
                          <div>{t('Days/Hours')}</div>
                        </div>
                        <SingleDropdown
                          selectedOption={
                            lastMinuteStart && lastMinuteStart.type
                          }
                          options={[
                            {
                              text: t('days before participation'),
                              value: 'DAY',
                            },
                            {
                              text: t('hours before participation'),
                              value: 'HOUR',
                            },
                          ]}
                          onChange={(value) =>
                            setLastMinuteStart({
                              ...lastMinuteStart,
                              type: value as any,
                            })
                          }
                        />
                      </div>
                      {lastMinuteStart.type === 'DAY' && (
                        <div
                          style={{
                            width: 'calc((100% - 20px) / 3)',
                            marginRight: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: 'var(--text-semibold)',
                            }}
                          >
                            <div>{t('at')}</div>
                          </div>
                          <TextField
                            required
                            error={
                              showErrors &&
                              (!matchesFormat(
                                lastMinuteStart.time_local || '',
                                '24-hour-time'
                              ) as any)
                            }
                            value={lastMinuteStart.time_local || ''}
                            placeholder={'hh:mm (24-hour time)'}
                            onChange={(value) =>
                              setLastMinuteStart({
                                ...lastMinuteStart,
                                time_local: value,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {promotionType === 'FAMILY_DISCOUNT' && (
                <FamilyDiscountInput
                  showErrors={showErrors}
                  familyDiscount={familyDiscount}
                  onFamilyDiscountChange={setFamilyDiscount}
                  guestTypeKeys={allGuestTypeKeys}
                />
              )}
              {promotionType === 'GROUP_DISCOUNT' && (
                <GroupDiscountInput
                  groupDiscount={groupDiscount}
                  onGroupDiscountChange={setGroupDiscount}
                  guestTypeKeys={allGuestTypeKeys}
                />
              )}
              {showUnitPriceDeltaListInput && (
                <UnitPriceDeltaListInput
                  type={
                    promotionType === 'ADDITIONAL_CHARGE'
                      ? 'CHARGE'
                      : 'DISCOUNT'
                  }
                  label={
                    promotionType === 'ADDITIONAL_CHARGE'
                      ? t('Charge Amounts')
                      : t('Discount Amounts')
                  }
                  currencyCode={currencyCode}
                  unitPriceMethod={unitPriceMethod}
                  guestTypeKeyCandidates={unitPriceDeltaGuestTypeKeys}
                  value={unitPriceDeltas}
                  onChange={(newUnitPriceDeltas) => {
                    setUnitPriceDeltas(newUnitPriceDeltas);
                  }}
                />
              )}
              {!promotionAppliesToAllProducts && selectedProduct && (
                <PromotionDetails
                  product={toProductShape({
                    ...selectedProduct,
                    pricing: previewPricing,
                  })}
                />
              )}
              <div
                className={styles['p-downloadModal__item']}
                style={{
                  borderRadius: 'var(--radius-xl)',
                  backgroundColor: 'var(--gray100)',
                  padding: '10px',
                }}
              >
                <div className={styles['p-downloadModal__item__body']}>
                  <Toggle
                    label={t('Promotion applies to specific channels')}
                    checked={appliesToSpecificChannels}
                    onChange={() => {
                      if (appliesToSpecificChannels) {
                        setChannels([]);
                      }
                      setAppliesToSpecificChannels(!appliesToSpecificChannels);
                    }}
                  />
                </div>
                <div className={styles['p-downloadModal__item__body']}>
                  {appliesToSpecificChannels && (
                    <ChannelListInput
                      channels={channels}
                      onChange={setChannels}
                      allAgents={
                        (selectedProduct && selectedProduct.agents) ||
                        contractedAgents
                      }
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {validationErrors.length > 0 && (
            <Message error>
              <ol>
                {validationErrors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ol>
            </Message>
          )}
        </div>
      </Modal>
    </>
  );
};
