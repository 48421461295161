// @flow

import * as React from 'react';
import { Button, List, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sendPaymentEmail } from 'client/actions/reservations';

type Props = {
  reservationID?: string,
  emailPaymentToAddress?: string,
  paymentLink?: string,
  reservationStatus?: string,
  paymentDeferredAvailable?: boolean,
};

export const EmailPaymentButton = ({
  reservationID,
  emailPaymentToAddress,
  paymentLink,
  reservationStatus,
  paymentDeferredAvailable,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const buttonEnabled =
    reservationStatus === 'CONFIRMED' &&
    emailPaymentToAddress &&
    paymentDeferredAvailable;

  return (
    <List>
      <List.Item>
        <List.Header>
          {t('Payment link')} :{' '}
          <a href={paymentLink} target="_blank" rel="noopener noreferrer">
            URL
          </a>
        </List.Header>
        <List.List>
          <Segment clearing>
            {emailPaymentToAddress}
            <Button
              positive
              disabled={!buttonEnabled}
              floated="right"
              onClick={() => {
                if (reservationID && emailPaymentToAddress) {
                  dispatch(
                    sendPaymentEmail(reservationID, emailPaymentToAddress)
                  );
                }
              }}
            >
              {t('Resend payment link again via Email')}
            </Button>
          </Segment>
        </List.List>
      </List.Item>
    </List>
  );
};
