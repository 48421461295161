import React from 'react';
import { useLocation } from 'react-router';

import { i18n } from 'client/libraries/i18n';
import 'client/loggedOutPages/Oops/Oops.css';

export const Oops = () => {
  const location = useLocation();
  return (
    <div>
      <div className="Oops">
        {i18n.t("Oops, page {{path}} doesn't exist!", {
          path: location.pathname,
        })}
      </div>
    </div>
  );
};
