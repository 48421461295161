import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { YouTubeVideoEditorModal } from 'client/components/ImageVideoAudioInput/YouTubeVideoEditorModal/YouTubeVideoEditorModal';
import { activeUserSelector } from 'client/reducers/user';
import { s3MediaStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import baseStyles from 'client/base.module.css';
import { isYouTubeUrl } from 'client/components/NewProductEditor/util';
import deleteIcon from 'client/images/ic_delete.svg';
import { getYouTubePreviewUrl } from 'client/libraries/util/getYouTubePreviewUrl';
import { Delete } from 'client/components/Icons/Delete';

import { AutoplayMedia } from '../formValues';

import styles from './AutoplayMediaInput.module.css';

type Props = {
  value?: AutoplayMedia;
  onChange: (newValue: AutoplayMedia | undefined) => void;
};
export const AutoplayMediaInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const [uploadError, setUploadError] = React.useState<string>('');
  const [showYouTubeVideoEditorModal, setShowYouTubeVideoEditorModal] =
    React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = ['audio/mpeg', 'video/mp4'];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const uploadFiles = async (files: File[]) => {
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';

    for (const file of files) {
      try {
        const filename = getRandomFilenameForUpload(file);
        const objName = `${organizationId}/tut/${filename}`;
        await s3MediaStorage.put(objName, file, {
          contentType: file.type,
        });
        const url = await s3MediaStorage.get(objName);
        // Remove pre-signed suffix
        const uploadedFileURL = url.split('?')[0];
        onChange({
          mediaUrl: uploadedFileURL,
          originalFilename: file.name,
        });
      } catch (err) {
        console.log('err', err);
        /* TODO */
      }

      // Only upload first
      break;
    }

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  const handleAddYouTubeVideoUrl = React.useCallback(
    (newUrl: string) => {
      onChange({
        mediaUrl: newUrl,
        originalFilename: '',
      });
    },
    [onChange]
  );

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <ul className={styles['page-productsRegist__picture']}>
        {value &&
          (isYouTubeUrl(value.mediaUrl) ? (
            <li className={styles['page-productsRegist__picture__item']}>
              <div
                className={styles['page-productsRegist__picture__item__pic']}
              >
                <img src={getYouTubePreviewUrl(value.mediaUrl)} />
                <a
                  className={styles['delete']}
                  onClick={() => onChange(undefined)}
                >
                  <img src={deleteIcon} />
                </a>
              </div>
            </li>
          ) : (
            <Box display="flex" alignItems="center">
              <Delete onClick={() => onChange(undefined)} />
              <Box ml={2}>{value.originalFilename}</Box>
            </Box>
          ))}

        {!value && (
          <li className={styles['page-productsRegist__picture__item']}>
            <a
              {...getRootProps()}
              className={clsx(
                styles['page-productsRegist__picture__item__add'],
                isDragActive && styles['dragActive']
              )}
              onClick={handleAddImageClick}
            ></a>
            <input
              {...getInputProps()}
              multiple
              ref={fileInputRef}
              id="file-input"
              type="file"
              name="name"
              accept={acceptedFileTypes.join(',')}
              style={{
                display: 'none',
              }}
              onChange={handleFileInputChange}
            />
          </li>
        )}
      </ul>
      {uploadError && (
        <p className={baseStyles['base-form-box__err']}>{uploadError}</p>
      )}

      {!value && (
        <>
          <Button
            size="middle"
            style="gray"
            onClick={() => setShowYouTubeVideoEditorModal(true)}
          >
            {t('Add YouTube Video')}
          </Button>
          {showYouTubeVideoEditorModal && (
            <YouTubeVideoEditorModal
              onClose={() => setShowYouTubeVideoEditorModal(false)}
              onAddUrl={(url: string) => {
                handleAddYouTubeVideoUrl(url);
                setShowYouTubeVideoEditorModal(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
