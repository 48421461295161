import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { FieldWrapper } from 'client/components/Form';
import { getScheduleText } from 'client/pages/ProductEditor/util';
import { getValidators } from 'shared/libraries/validate/validator';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

const defaultUnitMapping = {
  componentProductId: '',
  componentProductUnit: '',
};
type Props = {
  componentProducts: Product[];
};

const getUnitOptions = (product: Product): string[] => {
  const units: string[] = [];

  for (const priceRule of product?.pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit?.guest_type?.key) {
        units.push(unit.guest_type.key);
      }
    }
  }

  return units;
};

export const UnitMappingsEditor = ({ componentProducts }: Props) => {
  const { t } = useTranslation();
  const [activePriceIndex, setActivePriceIndex] = React.useState<number>(0);
  const { required } = getValidators(t);
  const unitOptionsByComponentProductId = Object.fromEntries(
    componentProducts.map((componentProduct) => [
      componentProduct.id,
      getUnitOptions(componentProduct),
    ])
  );
  return (
    <FieldArray name="priceSchedules">
      {({ fields }) => (
        <>
          <div className={styles['page-productsEdit__select']}>
            <p className={styles['page-productsEdit__select__ttl']}>
              {t('Select')}
            </p>
            <div className={styles['page-productsEdit__select__box']}>
              <label
                className={clsx(
                  baseStyles['base-form-select'],
                  baseStyles['base-maxwidth-800']
                )}
              >
                <select
                  value={activePriceIndex}
                  onChange={(e) => {
                    setActivePriceIndex(parseInt(e.target.value));
                  }}
                >
                  {fields.value.map((priceRule, idx) => (
                    <option key={idx} value={idx}>
                      {getScheduleText(priceRule, t)}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div
            key={activePriceIndex}
            className={baseStyles['base-selectFrame']}
          >
            <div className={baseStyles['base-selectFrame__header']}>
              <p className={baseStyles['base-selectFrame__header__ttl']}>
                {t('Selected Price Schedule')}
              </p>
            </div>
            <div className={baseStyles['base-selectFrame__body']}>
              <Field name={`priceSchedules.${activePriceIndex}.title`}>
                {({ input }) =>
                  input.value && (
                    <div>
                      <FieldWrapper label={t('Title')}>
                        {input.value}
                      </FieldWrapper>
                    </div>
                  )
                }
              </Field>
              <FieldArray name={`priceSchedules.${activePriceIndex}.prices`}>
                {({ fields }) => (
                  <table>
                    <tbody>
                      {fields.map((unitFieldName) => (
                        <tr key={unitFieldName}>
                          <td>
                            <Field name={`${unitFieldName}.unit`}>
                              {({ input }) => (
                                <FieldWrapper label={t('Unit')}>
                                  {input.value}
                                </FieldWrapper>
                              )}
                            </Field>
                            <FieldWrapper label={t('Mappings')}>
                              <FieldArray
                                name={`${unitFieldName}.packageComponentUnitMappings`}
                              >
                                {({ fields }) => (
                                  <div className={styles['c-tableChild']}>
                                    <ul>
                                      <li className={baseStyles['base-t-240']}>
                                        {t('Component Product')}
                                        <Tooltip
                                          text={t(
                                            'Note: Per-booking products do not require unit mappings and are not shown.'
                                          )}
                                        />
                                      </li>
                                      <li className={baseStyles['base-t-96']}>
                                        {t('Unit')}
                                      </li>
                                      <li
                                        className={baseStyles['base-t-144']}
                                      />
                                    </ul>
                                    <AddIcon
                                      onClick={() =>
                                        (fields as any).insertAt(
                                          0,
                                          defaultUnitMapping
                                        )
                                      }
                                    />
                                    {fields.map((fieldName, idx) => (
                                      <ul key={fieldName}>
                                        <li data-title={t('Component Product')}>
                                          <Field
                                            name={`${fieldName}.componentProductId`}
                                            validate={required}
                                          >
                                            {({ input, meta: { error } }) => (
                                              <div
                                                style={{
                                                  width: '100%',
                                                }}
                                              >
                                                <select
                                                  className={
                                                    baseStyles[
                                                      'base-form-select'
                                                    ]
                                                  }
                                                  value={input.value}
                                                  onChange={(e) => {
                                                    input.onChange(
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  <option value="" />
                                                  {componentProducts
                                                    .filter(
                                                      (product) =>
                                                        product.id ===
                                                          input.value ||
                                                        (!fields.value.some(
                                                          (mapping) =>
                                                            mapping.componentProductId ===
                                                            product.id
                                                        ) &&
                                                          (
                                                            unitOptionsByComponentProductId[
                                                              product.id
                                                            ] ?? []
                                                          ).length > 0)
                                                    )
                                                    .map(
                                                      (
                                                        componentProduct,
                                                        idx
                                                      ) => (
                                                        <option
                                                          key={idx}
                                                          value={
                                                            componentProduct.id
                                                          }
                                                        >
                                                          {
                                                            componentProduct.product_name
                                                          }
                                                        </option>
                                                      )
                                                    )}
                                                </select>
                                                {error && (
                                                  <p
                                                    className={
                                                      baseStyles[
                                                        'base-form-box__err'
                                                      ]
                                                    }
                                                  >
                                                    {error}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </li>
                                        <li data-title={t('Unit')}>
                                          <Field
                                            name={`${fieldName}.componentProductUnit`}
                                            validate={required}
                                          >
                                            {({ input, meta: { error } }) => {
                                              const componentProductId =
                                                fields.value[idx]
                                                  .componentProductId;
                                              const disabled =
                                                !componentProductId;
                                              const unitOptions =
                                                componentProductId
                                                  ? unitOptionsByComponentProductId[
                                                      componentProductId
                                                    ] ?? []
                                                  : [];
                                              return (
                                                <div
                                                  style={{
                                                    width: '100%',
                                                  }}
                                                >
                                                  <select
                                                    className={
                                                      baseStyles[
                                                        'base-form-select'
                                                      ]
                                                    }
                                                    {...input}
                                                    disabled={disabled}
                                                  >
                                                    <option value="" />
                                                    {unitOptions.map(
                                                      (unit, idx) => (
                                                        <option
                                                          key={idx}
                                                          value={unit}
                                                        >
                                                          {unit}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  {error && (
                                                    <p
                                                      className={
                                                        baseStyles[
                                                          'base-form-box__err'
                                                        ]
                                                      }
                                                    >
                                                      {error}
                                                    </p>
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </Field>
                                        </li>
                                        <li className={baseStyles['base-flex']}>
                                          <DeleteIcon
                                            onClick={() => fields.remove(idx)}
                                          />
                                        </li>
                                      </ul>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
                            </FieldWrapper>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </FieldArray>
            </div>
          </div>
        </>
      )}
    </FieldArray>
  );
};
