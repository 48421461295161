import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
};
export const DetailTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Date')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('visitCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('userCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('reservationCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('Conversion Rate')}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataItem, idx) => {
          return (
            <tr key={idx}>
              <td>{dataItem.name}</td>
              <td style={{ textAlign: 'right' }}>{dataItem.visitCount}</td>
              <td style={{ textAlign: 'right' }}>{dataItem.userCount}</td>
              <td style={{ textAlign: 'right' }}>
                {dataItem.reservationCount}
              </td>
              <td style={{ textAlign: 'right' }}>
                {(dataItem.userCount
                  ? (dataItem.reservationCount / dataItem.userCount) * 100
                  : 0
                ).toFixed(1)}{' '}
                %
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
