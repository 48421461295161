import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { manifestCustomViewsSelector } from 'client/reducers/manifestSettings';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { ManifestViewType } from 'shared/models/swagger';
import { updateManifestCustomViews } from 'client/actions/manifestSettings';

type Props = {
  customViewToDelete: ManifestViewType | null;
  open: boolean;
  onClose: () => void;
};

export const DeleteCustomViewModal = ({
  customViewToDelete = null,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allCustomViews = useSelector((state: ReduxState) =>
    manifestCustomViewsSelector(state)
  );

  return customViewToDelete ? (
    <Modal
      title={t('Delete Manifest View')}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button text={t('Cancel')} color="white" onClick={onClose} />
          <Button
            color="red"
            text={t('Delete')}
            onClick={() => {
              const newCustomViews = allCustomViews.filter(
                (view) => view.key !== customViewToDelete.key
              );
              dispatch(updateManifestCustomViews(newCustomViews));
              onClose();
            }}
          />
        </>
      }
    >
      {t('Are you sure you want to delete "{{name}}"?', {
        name: customViewToDelete.key,
      })}
    </Modal>
  ) : null;
};
