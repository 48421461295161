import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { updateManifestProductGroups } from 'client/actions/manifestSettings';
import {
  manifestProductGroupsSelector,
  manifestCustomViewsSelector,
} from 'client/reducers/manifestSettings';
import { DraggableSelect } from 'client/components/v3/DraggableSelect/DraggableSelect';
import { defaultManifestViewKeys } from 'client/reducers/manifestDefaults';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { ReduxState } from 'client/reducers';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/base.module.css';
import { ManifestProductGroup, ProductSummary } from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type Props = {
  existingProductGroup?: ManifestProductGroup | null;
  open: boolean;
  onClose: () => void;
};

export const EditProductGroupModal = ({
  existingProductGroup = null,
  open,
  onClose,
}: Props) => {
  const [productGroupKey, setProductGroupKey] = useState<string>('');
  const [manifestViewType, setManifestViewType] = useState<string>('');
  const [productsInGroup, setProductsInGroup] = useState<ProductSummary[]>([]);
  const [usedForGrouping, setUsedForGrouping] = useState<boolean>(false);

  const allProducts = useSelector((state: ReduxState) =>
    summariesSortedByBookmarkedSelector(state)
  );
  const productGroups = useSelector((state: ReduxState) =>
    manifestProductGroupsSelector(state)
  );
  const customViews = useSelector((state: ReduxState) =>
    manifestCustomViewsSelector(state)
  );

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    setProductsInGroup((productsInGroup) => {
      const newProducts = [...productsInGroup];
      const insertItem = productsInGroup[dragIndex];
      newProducts.splice(dragIndex, 1);
      newProducts.splice(hoverIndex, 0, insertItem);
      return newProducts;
    });
  };

  useEffect(() => {
    if (existingProductGroup && existingProductGroup.product_ids) {
      const productIds = existingProductGroup.product_ids;
      setProductsInGroup(
        productIds.map((p): any => allProducts.find((elm) => elm.id == p))
      );
    } else {
      setProductsInGroup([]);
    }
    setProductGroupKey(existingProductGroup?.key || '');
    setManifestViewType(existingProductGroup?.view_type_key || 'ALL_TYPES');
    setUsedForGrouping(existingProductGroup?.used_for_grouping || false);
  }, [allProducts, existingProductGroup]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const manifestViewTypeOptions = [
    {
      key: 'ALL_TYPES',
      value: 'ALL_TYPES',
      text: t('All types'),
    },
    ...customViews.map((view) => ({
      key: view.key,
      value: view.key,
      text: view.key,
    })),
    ...defaultManifestViewKeys.map((viewKey) => ({
      key: viewKey,
      value: viewKey,
      text: t(viewKey),
    })),
  ].filter(
    (option) =>
      option.key !== undefined &&
      option.value !== undefined &&
      option.text !== undefined
  ) as { text: string; value: string }[];

  const headerText = existingProductGroup
    ? t('Edit Manifest Group')
    : t('Create New Manifest Group');

  return (
    <Modal
      title={headerText}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button text={t('Cancel')} color="white" onClick={onClose} />
          <Button
            text={t('Save')}
            onClick={() => {
              const newManifestGroup = {
                key: productGroupKey,
                view_type_key:
                  manifestViewType !== 'ALL_TYPES' ? manifestViewType : '',
                product_ids: productsInGroup
                  .filter((p) => !!p)
                  .map((p) => p.id),
                used_for_grouping: usedForGrouping,
              };
              dispatch(
                updateManifestProductGroups([
                  ...productGroups.filter(
                    (group) => group.key !== productGroupKey
                  ),
                  newManifestGroup,
                ])
              );
              onClose();
            }}
            disabled={!productGroupKey || (productsInGroup || []).length === 0}
          />
        </>
      }
      style={{ width: '600px', height: '800px' }}
    >
      <div className={styles['p-manifestsModalCustomize']}>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Group Title')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <TextField
              disabled={Boolean(existingProductGroup)}
              value={productGroupKey}
              onChange={(value) => setProductGroupKey(value || '')}
            />
          </div>
        </div>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Applies To')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <SingleDropdown
              options={manifestViewTypeOptions}
              selectedOption={manifestViewType}
              onChange={(value) => setManifestViewType(value || '')}
            />
          </div>
        </div>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Products')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            {productsInGroup &&
              productsInGroup.map((product, idx) => (
                <DraggableSelect
                  key={product?.id ?? ''}
                  value={product?.id ?? ''}
                  options={allProducts
                    .filter(
                      (p) =>
                        (p && p.id === product?.id) ||
                        !productsInGroup.includes(p)
                    )
                    .map((p) => ({
                      value: p?.id,
                      key: p?.id,
                      text: getVerboseDisplayProductName(p),
                    }))}
                  index={idx}
                  onValueChange={(value) => {
                    const newItem = allProducts.find(
                      (p: ProductSummary) => p.id === value
                    );
                    const pList: any = productsInGroup.map(
                      (p: ProductSummary, pidx) => (pidx === idx ? newItem : p)
                    );

                    setProductsInGroup(pList);
                  }}
                  moveItem={(dragIndex: number, hoverIndex: number) => {
                    moveItem(dragIndex, hoverIndex);
                  }}
                  deleteItem={() => {
                    const newProducts = [...productsInGroup];
                    newProducts.splice(idx, 1);
                    setProductsInGroup(newProducts);
                  }}
                />
              ))}
            {productsInGroup && (
              <div className={clsx(baseStyles['list-add-btn'])}>
                <Button
                  text={t('Add')}
                  color="primary"
                  onClick={() => {
                    const newProducts: any = [...productsInGroup];
                    newProducts.splice(productsInGroup.length, 0, null);
                    setProductsInGroup(newProducts);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Manifest Grouping')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <Toggle
              label={t('Use it to group reservations on Manifest')}
              checked={usedForGrouping}
              onChange={() => {
                setUsedForGrouping(!usedForGrouping);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
