// @flow

import * as React from 'react';
import { Segment, Tab } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { AccountsTab } from 'client/pages/Manage/AccountsTab/AccountsTab';
import { BranchesTab } from 'client/pages/Manage/BranchesTab/BranchesTab';
import type { TranslateFuncType } from 'client/components/Translate';
import { ContractsTab } from 'client/pages/Manage/ContractsTab/ContractsTab';

type Props = {
  t: TranslateFuncType,
};
type State = {};

export class manage extends React.Component<Props, State> {
  render() {
    const { t } = this.props;

    const panes = [
      {
        menuItem: t('Branches'),
        render: () => <BranchesTab />,
      },
      {
        menuItem: t('Accounts'),
        render: () => <AccountsTab />,
      },
      {
        menuItem: t('Contract'),
        render: () => <ContractsTab />,
      },
    ];

    return (
      <div>
        <Segment>
          <Tab
            style={{ paddingTop: 15 }}
            menu={{ secondary: true, pointing: true }}
            panes={panes}
          />
        </Segment>
      </div>
    );
  }
}

export const Manage = withTranslation()(manage);
