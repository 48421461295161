import * as React from 'react';
import { Link } from 'react-router-dom';

import type { TranslateFuncType } from 'client/components/Translate';
import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';

export type Column = {
  Header: string;
  id: string;
  accessor: string | ((row: ReservationSummaryShape) => string);
  Cell?: (cellInfo: { original: ReservationSummaryShape }) => React.ReactNode;
  th?: boolean;
  width?: 'minimal' | 'short' | 'middle' | 'long' | 'longx2';
};
export const getReactTableColumns = (
  t: TranslateFuncType,
  locale: string,
  openLinksInNewWindow?: boolean
): Column[] => {
  const linkProps = openLinksInNewWindow
    ? {
        target: '_blank',
      }
    : {};
  return [
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference,
      Cell: (cellInfo) => (
        <Link to={`/reservations/${cellInfo.original.id}`} {...linkProps}>
          {cellInfo.original.agent_reference}
        </Link>
      ),
      width: 'middle',
      th: true,
    },
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: 'booked_at',
      width: 'middle',
      Cell: ({ original }) => original.booked_at.locale(locale).format('lll'),
    },
    {
      Header: t('Last Updated'),
      id: 'updated_at',
      accessor: 'updated_at',
      width: 'middle',
      Cell: ({ original }) => original.updated_at.locale(locale).format('lll'),
    },
    {
      Header: t('Status'),
      id: 'status',
      width: 'middle',
      accessor: (row) => t(row.status),
    },
    {
      Header: t('Customer'),
      id: 'guest_display_name',
      width: 'middle',
      accessor: 'guest_display_name',
    },
    {
      Header: t('Product'),
      id: 'product_name',
      accessor: 'product_name',
      width: 'long',
      Cell: ({ original }) => (
        <Link to={`/products/${original.product_id}`} {...linkProps}>
          {original.internal_product_name || original.product_name}
        </Link>
      ),
    },
    {
      Header: t('Participation'),
      id: 'participates_at',
      accessor: 'participates_at',
      width: 'middle',
      Cell: ({ original }) =>
        original.participates_at.locale(locale).format('lll'),
    },
    {
      Header: t('Units'),
      id: 'guest_description',
      width: 'middle',
      accessor: 'guest_description',
    },
    {
      Header: t('Total Pax'),
      id: 'guest_count',
      width: 'short',
      accessor: 'guest_count',
    },
    {
      Header: t('Hotel'),
      id: 'hotel',
      width: 'long',
      accessor: 'hotel',
    },
    {
      Header: t('Pickup/Checkin time'),
      id: 'pickup_checkin_time',
      width: 'short',
      accessor: 'pickup_checkin_time',
    },
    {
      Header: t('Pickup/Checkin location'),
      id: 'pickup_checkin_location',
      width: 'long',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: t('Payment Type'),
      id: 'payment_type',
      width: 'short',
      accessor: (row) => row.payment_type && t(row.payment_type),
    },
    {
      Header: t('Transportation'),
      id: 'transportation',
      width: 'middle',
      accessor: 'transportation',
    },
    {
      Header: t('Add-ons'),
      id: 'add_ons',
      width: 'middle',
      accessor: 'add_ons',
    },
    {
      Header: t('Source'),
      id: 'booking_source',
      width: 'middle',
      accessor: 'booking_source',
    },
    {
      Header: t('Remarks'),
      id: 'agent_notes',
      width: 'long',
      accessor: 'agent_notes',
    },
    {
      Header: t('Replies'),
      id: 'supplier_notes',
      width: 'long',
      accessor: 'supplier_notes',
    },
    {
      Header: t('Supplier'),
      id: 'supplier_name',
      width: 'middle',
      accessor: 'supplier_name',
    },
    {
      Header: t('Confirmation Number'),
      id: 'supplier_reference',
      width: 'middle',
      accessor: 'supplier_reference',
    },
    {
      Header: '#',
      id: 'id',
      accessor: 'id',
      width: 'long',
      Cell: ({ original }) => (
        <Link to={`/reservations/${original.id}`} {...linkProps}>
          {original.id}
        </Link>
      ),
    },
  ];
};
