import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { compose } from 'recompose';
import { withTranslation, TFunction } from 'react-i18next';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ProductInstanceCalendar } from 'client/components/ProductInstanceCalendar';
import { fetchProductInstances } from 'client/actions/productInstances';
import { fetchProductByID } from 'client/actions/products';
import { ProductInstanceEditModal } from 'client/components/ProductInstanceEditModal/ProductInstanceEditModal';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers';
import type { ProductInstance } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  trigger: React.ComponentType<any>;
  previousProductInstance?: ProductInstance;
  productID: string;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  I18nProps;
/* eslint-enable no-use-before-define */

type State = {
  editingProductInstance: ProductInstance | null;
  showCalendar: boolean;
};

class ReservationDatePickerComponentV2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editingProductInstance: null,
      showCalendar: false,
    };
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    if (!this.props.product || !this.props.product.start_timezone) {
      this.props.fetchProductByID(this.props.productID);
    }
  };
  initialDate = () => {
    return this.props.previousProductInstance
      ? moment(this.props.previousProductInstance.start_date_time_utc)
      : moment();
  };
  handleEditClick = (editingProductInstance: ProductInstance) =>
    this.setState({
      editingProductInstance,
    });
  handleEditModalClose = () =>
    this.setState({
      editingProductInstance: null,
    });
  toggleShowCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
    });
  };
  onCalendarClose = () => {
    this.setState({
      showCalendar: false,
    });
  };

  render() {
    const { activeUser, loading, product, trigger: Trigger } = this.props;
    const { editingProductInstance } = this.state;
    return (
      <>
        <Trigger onClick={this.toggleShowCalendar} />

        {this.state.showCalendar && (
          <div>
            {editingProductInstance && (
              <ProductInstanceEditModal
                insertRoot={true}
                oldProductInstance={editingProductInstance}
                onClose={() => {
                  this.setState({
                    editingProductInstance: null,
                  });
                }}
                open={editingProductInstance !== null}
              />
            )}
            {product && (
              <ProductInstanceCalendar
                defaultDate={this.initialDate()}
                onEditClick={
                  operationAllowed(activeUser, 'write', 'productInstances')
                    ? this.handleEditClick
                    : undefined
                }
                onClose={this.onCalendarClose}
                productId={product.id}
                loading={loading}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  loading: state.productInstances.loading || state.products.loading,
  product: state.products.byID[ownProps.productID],
  locale: state.language.selected.iso,
  monthYearFormat: state.language.selected.monthYearFormat,
  activeUser: activeUserSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProductInstances: (productID: string, start: Moment, end: Moment) =>
    dispatch(fetchProductInstances(productID, start, end)),
  fetchProductByID: (productID: string) =>
    dispatch(fetchProductByID(productID)),
});

export const ReservationDatePicker = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ReservationDatePickerComponentV2);
