import * as React from 'react';
import clsx from 'clsx';

import { formatStartTime } from 'client/pages/ProductDetails/formatStartTime';
import { applyRelativeTimeOffset } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/util';
import type { ProductShape } from 'client/libraries/util/productShape';
import styles from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/ProductOutline.module.css';

interface Props {
  product: ProductShape;
}
export const ScheduleTab = ({ product }: Props) => {
  const [selectedStartTime, setSelectedStartTime] = React.useState<string>(
    product.startTimes.length > 0 ? product.startTimes[0].hhMm : ''
  );

  const handleStartTimeChange = (event: any) =>
    setSelectedStartTime(event.target.value);

  return (
    <div className={styles['c-schedule']}>
      <label className={styles['c-schedule__select']}>
        <select
          name="startTime"
          value={selectedStartTime}
          onChange={handleStartTimeChange}
        >
          {product.startTimes.map((startTime) => (
            <option key={startTime.hhMm} value={startTime.hhMm}>
              {formatStartTime(startTime)}
            </option>
          ))}
        </select>
      </label>
      <ul className={styles['c-schedule__list']}>
        {product.schedule.map((event) => {
          const eventStartTime = event.startTimeRelative
            ? applyRelativeTimeOffset(
                selectedStartTime,
                event.startTimeRelative
              )
            : '';
          const eventEndTime = event.endTimeRelative
            ? applyRelativeTimeOffset(selectedStartTime, event.endTimeRelative)
            : '';
          const timeDescription = eventEndTime
            ? `${eventStartTime} - ${eventEndTime}`
            : eventStartTime;
          return (
            <li key={event.name} className={styles['c-schedule__list__item']}>
              <p className={styles['c-schedule__list__item__time']}>
                {timeDescription}
              </p>
              <div className={styles['c-schedule__list__item__body']}>
                <p className={styles['c-schedule__list__item__body__title']}>
                  {event.name}
                </p>
                <p
                  className={clsx(
                    styles['c-schedule__list__item__body__text'],
                    'newline'
                  )}
                >
                  {event.description}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
