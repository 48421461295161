import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { FreeFormatReservationFieldResponseUpdateModal } from 'client/pages/ReservationDetails/FreeFormatReservationDetails/FreeFormatReservationFieldResponseUpdateModal';
import { FileDownloadList } from 'client/pages/ReservationDetails/FileDownloadList';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { Message } from 'client/components/Message/Message';
import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import type { Reservation, Account, Guest } from 'shared/models/swagger';
import editIcon from 'client/images/ic_edit.svg';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  reservation: Reservation;
  activeUser: Account | null;
  readOnly: boolean;
  active: boolean;
};

const MAX_FIELD_RESPONSE = 100;
const MAX_PARTICIPANT = 100;

export const FreeFormatReservationGuestTab = ({
  reservation,
  activeUser,
  readOnly,
  active,
}: Props) => {
  const { t } = useTranslation();

  const [shouldRecalculateTableHeight, setShouldRecalculateTableHeight] =
    React.useState<boolean>();

  if (!reservation) {
    return null;
  }

  const excludedFormFieldKeys = [
    'family_name',
    'given_name',
    'kana_family_name',
    'kana_given_name',
    'email',
    'preferred_language_iso2',
    'booking_language',
    'hotel_information',
    'representative_name',
    'hotel_tbd_form',
    'consent_form',
    'phone',
    'international_phone',
  ];

  const builtinFormFieldsByKey = getCommonFormFieldsByKey(t);
  const unmappedFormFieldKeys = [
    ...new Set(
      _.flatten(
        reservation.guests.map((guest) =>
          guest.field_responses?.map((fieldResponse) => fieldResponse.key ?? '')
        )
      )
    ),
  ];
  const unregisteredBuiltinFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key || '')
    )
    .map((key) => ({
      key,
      title: builtinFormFieldsByKey[key ?? ''].text,
    }));
  const unregisteredUnknownFormFields = unmappedFormFieldKeys
    .filter(
      (key) =>
        !builtinFormFieldsByKey[key ?? ''] &&
        !excludedFormFieldKeys.includes(key || '')
    )
    .map((key) => ({
      key,
      title: key,
    }));
  const items = [
    ...unregisteredBuiltinFormFields,
    ...unregisteredUnknownFormFields,
  ];

  const getDisplayString = (guest: Guest, key: string): string => {
    const value = guest[key as keyof Guest];
    if (value) {
      if (typeof value === 'string') {
        return value;
      } else {
        return value.join('<br/>');
      }
    }
    if (guest.field_responses) {
      const fieldValue = guest.field_responses.find((field) => {
        return field.key === key;
      });
      if (fieldValue && fieldValue.response) {
        return fieldValue.response;
      }
    }
    return '';
  };

  // for table vertical scroll
  const divRef = React.useRef<HTMLDivElement | null>(null);

  // for adjust the height of each lines
  // TODO(goro) need to keep the same React Hook call count
  const itemRefs: (React.LegacyRef<HTMLLIElement> | undefined)[][] = [];
  [...Array(MAX_FIELD_RESPONSE)].forEach(() => {
    const _itemRefs: (React.LegacyRef<HTMLLIElement> | undefined)[] = [];
    _itemRefs.push(React.useRef(null));
    [...Array(MAX_PARTICIPANT)].forEach(() => {
      _itemRefs.push(React.useRef(null));
    });
    itemRefs.push(_itemRefs);
  });

  const getMax = React.useCallback((items) => {
    return items.reduce((max: number, item: any) => {
      const height = item?.current?.offsetHeight;
      if (!height) {
        return max;
      }
      return max < height ? height : max;
    }, 0);
  }, []);

  const setTableHeight = () => {
    itemRefs.forEach((items) => {
      const max = getMax(items);
      if (max) {
        items.forEach((item: any) => {
          if (item?.current?.style) {
            item.current.style.height = `${max}px`;
          }
        });
      }
    });
  };

  const handleResize = React.useCallback(() => {
    const clearHeights = () => {
      itemRefs.forEach((items) => {
        items.forEach((item: any) => {
          if (item?.current?.style?.height) {
            item.current.style.height = 'auto';
          }
        });
      });
    };

    clearHeights();
    setShouldRecalculateTableHeight(true);
  }, []);

  React.useEffect(() => {
    if (reservation) {
      setTableHeight();
    }
  }, [reservation, active]); // TOOD(goro) remove active

  React.useEffect(() => {
    if (shouldRecalculateTableHeight) {
      setTableHeight();
      setShouldRecalculateTableHeight(false);
    }
  }, [shouldRecalculateTableHeight]);

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughOrg =
    (reservation.agent_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'AGENT') ||
    (reservation.supplier_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'SUPPLIER');

  const userCanEditFormFieldResponses =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          active ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Guest Information')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            {userCanEditFormFieldResponses && (
              <FreeFormatReservationFieldResponseUpdateModal
                reservationID={reservation.id}
                trigger={
                  <a
                    className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  >
                    <img src={editIcon} />
                  </a>
                }
              />
            )}
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          {(reservation?.guests?.length > MAX_PARTICIPANT ||
            items.length > MAX_FIELD_RESPONSE) && (
            <Message
              warning
              header={t('Too much participants or field responses')}
            />
          )}

          {items.length > 0 && (
            <div className={clsx(componentStyles['c-table-slide'])}>
              <div
                className={clsx(componentStyles['c-table-slide__table'])}
                ref={divRef}
              >
                <div className="js-heightCalc">
                  <ul
                    className={clsx(
                      componentStyles['c-table-slide__table__thread'],
                      baseStyles['base-t-160']
                    )}
                  >
                    {items.map((item, idx) => {
                      return (
                        <li
                          key={idx}
                          ref={itemRefs[idx < MAX_FIELD_RESPONSE ? idx : 0][0]}
                          className={clsx(baseStyles['base-t-160'])}
                        >
                          {item.title ?? ''}
                        </li>
                      );
                    })}
                  </ul>
                  {reservation.guests.map((guest, gidx) => {
                    return (
                      <ul
                        key={gidx}
                        className={clsx(
                          componentStyles['c-table-slide__table__tbody'],
                          baseStyles['base-t-min-160']
                        )}
                      >
                        {items.map((item, iidx) => {
                          return (
                            <li
                              key={iidx}
                              className={clsx(
                                'newline',
                                baseStyles['base-t-min-160']
                              )}
                              ref={
                                itemRefs[iidx < MAX_FIELD_RESPONSE ? iidx : 0][
                                  gidx + 1 < MAX_PARTICIPANT ? gidx + 1 : 0
                                ]
                              }
                              data-text={t(item.title ?? '')}
                            >
                              <div style={{ width: '100%' }}>
                                {(item as any).format === 'file-upload' ? (
                                  <FileDownloadList
                                    key={`${gidx}-${iidx}`}
                                    fileUrlString={getDisplayString(
                                      guest,
                                      item.key ?? ''
                                    )}
                                  />
                                ) : (
                                  <TruncatedTextWithSeeMoreButton
                                    text={getDisplayString(
                                      guest,
                                      item.key ?? ''
                                    )}
                                    onResize={handleResize}
                                  />
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  })}
                </div>
              </div>
              <div
                className={clsx(
                  componentStyles['c-table-slide__btn'],
                  componentStyles['prev']
                )}
                onClick={() => {
                  if (divRef?.current?.scrollLeft != null) {
                    divRef.current.scrollLeft -= 160;
                  }
                }}
              ></div>
              <div
                className={clsx(
                  componentStyles['c-table-slide__btn'],
                  componentStyles['next']
                )}
                onClick={() => {
                  if (divRef?.current?.scrollLeft != null) {
                    divRef.current.scrollLeft += 160;
                  }
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
