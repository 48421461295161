import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import {
  ReservationSummaryShape,
  toReservationSummaryShape,
} from 'client/libraries/util/reservationSummaryShape';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import baseStyles from 'client/base.module.css';

import { CustomerReservationsContext } from './CustomerReservationsContext';

export const CustomerReservations = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const reservations = React.useContext(CustomerReservationsContext);

  const getColumns = (
    local: string,
    t: TranslateFuncType
  ): ColumnType<ReservationSummaryShape>[] => {
    return [
      {
        Header: t('Application Number'),
        id: 'agent_reference',
        accessor: (item: ReservationSummaryShape) => {
          return (
            <Link to={`/reservations/${item.id}`}>{item.agent_reference}</Link>
          );
        },
        width: 120,
      },
      {
        Header: t('Booked Date'),
        id: 'booked_at',
        accessor: (item: ReservationSummaryShape) => {
          return getDateTimeDisplay(item.booked_at, locale);
        },
        width: 120,
      },
      {
        Header: t('Participation'),
        id: 'participates_at',
        accessor: (item: ReservationSummaryShape) => {
          return getDateTimeDisplay(item.participates_at, locale);
        },
        width: 120,
      },
      {
        Header: t('Status'),
        id: 'status',
        accessor: (item: ReservationSummaryShape) => {
          return t(item.status);
        },
        width: 120,
      },
      {
        Header: t('Product'),
        id: 'product_name',
        accessor: (item: ReservationSummaryShape) => (
          <Link to={`/products/${item.product_id}`}>{item.product_name}</Link>
        ),
        width: 120,
      },
      {
        Header: t('Remarks'),
        id: 'agent_notes',
        accessor: (item: ReservationSummaryShape) => {
          return item.agent_notes;
        },
        width: 400,
      },
    ];
  };

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        baseStyles['scroll-target-pane']
      )}
      id="main"
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Reservations')}
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GenericTable<ReservationSummaryShape>
          items={(reservations ?? [])
            .map((reservation) =>
              toReservationSummaryShape(reservation, locale, t)
            )
            .filter((_, index) => {
              const start = (currentPage - 1) * rowCount;
              const end = start + rowCount;
              return index >= start && index < end;
            })}
          totalCount={reservations?.length ?? 0}
          columns={getColumns(locale, t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </div>
    </div>
  );
};
