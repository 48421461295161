import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_GUIDANCE_COUPON_FAILURE,
  CREATE_GUIDANCE_COUPON_REQUEST,
  CREATE_GUIDANCE_COUPON_SUCCESS,
  DELETE_GUIDANCE_COUPON_FAILURE,
  DELETE_GUIDANCE_COUPON_REQUEST,
  DELETE_GUIDANCE_COUPON_SUCCESS,
  FETCH_GUIDANCE_COUPONS_FAILURE,
  FETCH_GUIDANCE_COUPONS_REQUEST,
  FETCH_GUIDANCE_COUPONS_SUCCESS,
  UPDATE_GUIDANCE_COUPON_FAILURE,
  UPDATE_GUIDANCE_COUPON_REQUEST,
  UPDATE_GUIDANCE_COUPON_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GuidanceCoupon,
  CreateGuidanceCouponResponse,
  ListGuidanceCouponsResponse,
  UpdateGuidanceCouponResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_GUIDANCE_COUPONS_FAILURE:
    case CREATE_GUIDANCE_COUPON_FAILURE:
    case UPDATE_GUIDANCE_COUPON_FAILURE:
    case DELETE_GUIDANCE_COUPON_FAILURE:
      return action.error;

    case FETCH_GUIDANCE_COUPONS_SUCCESS:
    case CREATE_GUIDANCE_COUPON_SUCCESS:
    case UPDATE_GUIDANCE_COUPON_SUCCESS:
    case DELETE_GUIDANCE_COUPON_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_GUIDANCE_COUPONS_REQUEST:
    case CREATE_GUIDANCE_COUPON_REQUEST:
    case UPDATE_GUIDANCE_COUPON_REQUEST:
    case DELETE_GUIDANCE_COUPON_REQUEST:
      return true;

    case FETCH_GUIDANCE_COUPONS_FAILURE:
    case CREATE_GUIDANCE_COUPON_FAILURE:
    case UPDATE_GUIDANCE_COUPON_FAILURE:
    case DELETE_GUIDANCE_COUPON_FAILURE:
    case FETCH_GUIDANCE_COUPONS_SUCCESS:
    case CREATE_GUIDANCE_COUPON_SUCCESS:
    case UPDATE_GUIDANCE_COUPON_SUCCESS:
    case DELETE_GUIDANCE_COUPON_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: GuidanceCoupon[] = [], action: any): GuidanceCoupon[] => {
  switch (action.type) {
    case FETCH_GUIDANCE_COUPONS_SUCCESS: {
      const response = action.response as ListGuidanceCouponsResponse;
      return response.guidance_coupons ?? [];
    }

    case CREATE_GUIDANCE_COUPON_SUCCESS: {
      const response = action.response as CreateGuidanceCouponResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_GUIDANCE_COUPON_SUCCESS: {
      const response = action.response as UpdateGuidanceCouponResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_GUIDANCE_COUPON_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const guidanceCoupons = combineReducers({
  error,
  loading,
  all,
});
