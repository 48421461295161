import * as React from 'react';
import { HeatmapLayer, GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { currency } from 'shared/libraries/currency';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import { WithGoogleMapsScript } from 'client/components/WithGoogleMapsScript';
import { Loading } from 'client/components/v3/Common/Loading';
import { ReduxState } from 'client/reducers';

import styles from './AreaSalesHeatmapGadget.module.css';

export const AreaSalesHeatmapGadget = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );
  const { isLoaded: googleMapIsLoaded } = useGoogleMapsApi();

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.onSiteReportData
  );

  const data = React.useMemo(() => {
    if (!reportDataSets?.length) {
      return [];
    }
    return reportDataSets[0].gross_sales_locations?.map((item) => ({
      lat: item.latitude ?? 0,
      lng: item.longitude ?? 0,
      weight: currency(item?.gross_sales ?? '').value,
    }));
  }, [reportDataSets]);

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-inner']}>
          <div className={styles['header-text']}>{t('Sales per Area')}</div>
        </div>
      </div>
      <div className={styles['graph-container']}>
        {loading || !googleMapIsLoaded ? (
          <Loading size="md" />
        ) : (
          <WithGoogleMapsScript>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '400px' }}
              center={{ lat: 35.7, lng: 139.7 }}
              zoom={10}
            >
              <HeatmapLayer
                data={(data || []).map(
                  (point) => new google.maps.LatLng(point.lat, point.lng)
                )}
                options={{
                  radius: 20,
                  opacity: 0.6,
                }}
              />
            </GoogleMap>
          </WithGoogleMapsScript>
        )}
      </div>
    </div>
  );
};
