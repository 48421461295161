import { ThunkDispatch } from 'redux-thunk';
import type { Moment } from 'moment-timezone';
import queryString from 'query-string';
import axios from 'axios';

import { taskFilterQueryParamSelector } from 'client/reducers/tasks';
import type { ReduxState } from 'client/reducers';
import {
  DELETE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  FETCH_TASK_COUNTS_FAILURE,
  FETCH_TASK_COUNTS_REQUEST,
  FETCH_TASK_COUNTS_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_CANCELED,
  SET_TASKS_CREATED_AT_FROM,
  SET_TASKS_CREATED_AT_TO,
  SET_TASKS_DUE_DATE_FROM,
  SET_TASKS_DUE_DATE_TO,
  SET_TASKS_FILTERS,
  SET_TASKS_IS_ASCENDING,
  SET_TASKS_ORDER_BY,
  SET_TASKS_PAGE_SIZE,
  UPDATE_TASKS_PAGE_TOKENS,
} from 'client/constants/ActionTypes';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchTasksRequest = () => ({
  type: FETCH_TASKS_REQUEST,
});

const fetchTasksSuccess = (response: any, pageTokens: any) => ({
  type: FETCH_TASKS_SUCCESS,
  response,
  pageTokens,
});

const fetchTasksFailure = (error: any) => ({
  type: FETCH_TASKS_FAILURE,
  error,
});

const fetchTasksCanceled = () => ({
  type: FETCH_TASKS_CANCELED,
});

export const updateTasksPageTokens = (pageTokens: Record<string, any>) => ({
  type: UPDATE_TASKS_PAGE_TOKENS,
  pageTokens,
});
export const setTasksCreatedAtFrom = (createdAtFrom: Moment) => ({
  type: SET_TASKS_CREATED_AT_FROM,
  createdAtFrom,
});
export const setTasksCreatedAtTo = (createdAtTo: Moment) => ({
  type: SET_TASKS_CREATED_AT_TO,
  createdAtTo,
});
export const setTasksDueDateFrom = (dueDateFrom: Moment | string) => ({
  type: SET_TASKS_DUE_DATE_FROM,
  dueDateFrom,
});
export const setTasksDueDateTo = (dueDateTo: Moment) => ({
  type: SET_TASKS_DUE_DATE_TO,
  dueDateTo,
});
export const setTasksIsAscending = (isAscending: boolean) => ({
  type: SET_TASKS_IS_ASCENDING,
  isAscending,
});
export const setTasksOrderBy = (orderBy: string) => ({
  type: SET_TASKS_ORDER_BY,
  orderBy,
});
export const setTasksPageSize = (pageSize: number) => ({
  type: SET_TASKS_PAGE_SIZE,
  pageSize,
});
export const setTasksFilters = (filters: Record<string, any>[]) => ({
  type: SET_TASKS_FILTERS,
  filters,
});
let fetchTasksCancel: () => void | typeof undefined;
export const fetchTasks =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchTasksCancel && fetchTasksCancel();
    dispatch(fetchTasksRequest());
    const params: Record<string, string> = {
      page_token: getState().tasks.pageTokens.currToken,
      page_size: getState().tasks.pageSize.toString(),
      order_by: getState().tasks.orderBy.toString(),
      is_ascending: getState().tasks.isAscending.toString(),
      created_at_utc_from: getState().tasks.createdAtFrom?.toString() ?? '',
      created_at_utc_to: getState().tasks.createdAtTo?.toString() ?? '',
      due_date_utc_from: getState().tasks.dueDateFrom?.toString() ?? '',
      due_date_utc_to: getState().tasks.dueDateTo?.toString() ?? '',
      filter: queryString.stringify(taskFilterQueryParamSelector(getState())),
    };
    return axios
      .get('/api/tasks', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchTasksCancel = c;
        }),
      })
      .then((result) => {
        const data = result.data;
        const pageTokens = getState().tasks.pageTokens;

        if (data.next_page_token) {
          if (pageTokens.allTokens[pageTokens.currToken].next === null) {
            pageTokens.indexToToken.push(data.next_page_token);
          }

          pageTokens.allTokens[pageTokens.currToken].next =
            data.next_page_token;
        }

        dispatch(fetchTasksSuccess(data, pageTokens));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchTasksCanceled);
        else dispatch(fetchTasksFailure(error));
      });
  };

const deleteTaskRequest = (id: string) => ({
  type: DELETE_TASK_REQUEST,
  id,
});

const deleteTaskSuccess = (response: any) => ({
  type: DELETE_TASK_SUCCESS,
  response,
});

const deleteTaskFailure = (error: any) => ({
  type: DELETE_TASK_FAILURE,
  error,
});

export const deleteTask =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteTaskRequest(id));
    return fetch(`/api/tasks/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteTaskSuccess(result)))
      .catch((error) => dispatch(deleteTaskFailure(error)));
  };

const fetchTaskCountsRequest = () => ({
  type: FETCH_TASK_COUNTS_REQUEST,
});

const fetchTaskCountsSuccess = (response: any) => ({
  type: FETCH_TASK_COUNTS_SUCCESS,
  response,
});

const fetchTaskCountsFailure = (error: any) => ({
  type: FETCH_TASK_COUNTS_FAILURE,
  error,
});

export const fetchTaskCounts =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchTaskCountsRequest());
    const params: Record<string, string> = {
      due_date_utc_from: getState().tasks.dueDateFrom?.toString() ?? '',
      due_date_utc_to: getState().tasks.dueDateTo?.toString() ?? '',
    };
    return axios
      .get('/api/tasks/counts', {
        params,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchTaskCountsSuccess(result.data)))
      .catch((error) => dispatch(fetchTaskCountsFailure(error)));
  };
