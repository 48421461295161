import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fetchEquipmentBlocks } from 'client/actions/equipmentBlocks';
import { fetchEquipmentAssets } from 'client/actions/equipmentAssets';
import { fetchEquipments } from 'client/actions/equipments';
import { equipmentBlocksSelector } from 'client/reducers/equipmentBlocks';
import { equipmentAssetsSelector } from 'client/reducers/equipmentAssets';
import { equipmentsSelector } from 'client/reducers/equipments';

import { EquipmentEditorPanel } from './EquipmentEditorPanel';
import { EquipmentBlockPanel } from './EquipmentBlockPanel';
import { EquipmentAssetPanel } from './EquipmentAssetPanel';
import { EquipmentPanel } from './EquipmentPanel';
import { CreateEquipmentBlockModal } from './CreateEquipmentBlockModal';
import { CreateEquipmentAssetModal } from './CreateEquipmentAssetModal';
import { CreateEquipmentModal } from './CreateEquipmentModal';
import styles from './EquipmentEditor.module.css';

export const EquipmentEditor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<
    'BLOCK' | 'ASSET' | 'RESOURCE'
  >('BLOCK');
  const [selectedEquipmentBlockId, setSelectedEquipmentBlockId] =
    React.useState('');
  const [selectedEquipmentAssetId, setSelectedEquipmentAssetId] =
    React.useState('');
  const [selectedEquipmentId, setSelectedEquipmentId] = React.useState('');
  const [draggingEquipmentBlockId, setDraggingEquipmentBlockId] =
    React.useState<string | null>(null);
  const [draggingEquipmentAssetId, setDraggingEquipmentAssetId] =
    React.useState<string | null>(null);

  const [showCreateEquipmentBlockModal, setShowCreateEquipmentBlockModal] =
    React.useState(false);
  const [showCreateEquipmentAssetModal, setShowCreateEquipmentAssetModal] =
    React.useState(false);
  const [showCreateEquipmentModal, setShowCreateEquipmentModal] =
    React.useState(false);

  const equipmentBlocks = useSelector(equipmentBlocksSelector);
  const equipmentAssets = useSelector(equipmentAssetsSelector);
  const equipments = useSelector(equipmentsSelector);

  React.useEffect(() => {
    dispatch(fetchEquipmentBlocks());
    dispatch(fetchEquipmentAssets());
    dispatch(fetchEquipments());
  }, []);

  const selectedEquipmentBlock = React.useMemo(() => {
    return equipmentBlocks.find(
      (block) => block.id === selectedEquipmentBlockId
    );
  }, [equipmentBlocks, selectedEquipmentBlockId]);

  const selectedEquipmentAsset = React.useMemo(() => {
    return equipmentAssets.find(
      (asset) => asset.id === selectedEquipmentAssetId
    );
  }, [equipmentAssets, selectedEquipmentAssetId]);

  const selectedEquipment = React.useMemo(() => {
    return equipments.find((equipment) => equipment.id === selectedEquipmentId);
  }, [equipments, selectedEquipmentId]);

  return (
    <div className={clsx(styles['equipment-editor'])}>
      <div className={clsx(styles['equipment-editor__main'])}>
        <EquipmentEditorPanel
          title={t('Block')}
          open={activeTab === 'BLOCK'}
          onOpen={() => setActiveTab('BLOCK')}
          onSelect={(id) => setSelectedEquipmentBlockId(id)}
          onCreate={() => setShowCreateEquipmentBlockModal(true)}
          options={equipmentBlocks.map((block) => ({
            value: block.id ?? '',
            text: block.title ?? '',
          }))}
          selectedOption={selectedEquipmentBlockId}
          onDragStart={(id) => setDraggingEquipmentBlockId(id)}
        >
          {selectedEquipmentBlock ? (
            <EquipmentBlockPanel equipmentBlock={selectedEquipmentBlock} />
          ) : null}
        </EquipmentEditorPanel>
        <EquipmentEditorPanel
          title={t('Asset')}
          open={activeTab === 'ASSET'}
          onOpen={() => setActiveTab('ASSET')}
          onSelect={(id) => setSelectedEquipmentAssetId(id)}
          onCreate={() => setShowCreateEquipmentAssetModal(true)}
          options={equipmentAssets.map((asset) => ({
            value: asset.id ?? '',
            text: asset.title ?? '',
          }))}
          selectedOption={selectedEquipmentAssetId}
          onDragStart={(id) => setDraggingEquipmentAssetId(id)}
        >
          {selectedEquipmentAsset ? (
            <EquipmentAssetPanel
              equipmentAsset={selectedEquipmentAsset}
              draggingEquipmentBlockId={draggingEquipmentBlockId}
            />
          ) : null}
        </EquipmentEditorPanel>
        <EquipmentEditorPanel
          title={t('Resource')}
          open={activeTab === 'RESOURCE'}
          onOpen={() => setActiveTab('RESOURCE')}
          onSelect={(id) => setSelectedEquipmentId(id)}
          onCreate={() => setShowCreateEquipmentModal(true)}
          options={equipments.map((equipment) => ({
            value: equipment.id ?? '',
            text: equipment.title ?? '',
          }))}
          selectedOption={selectedEquipmentId}
        >
          {selectedEquipment ? (
            <EquipmentPanel
              equipment={selectedEquipment}
              draggingEquipmentAssetId={draggingEquipmentAssetId}
            />
          ) : null}
        </EquipmentEditorPanel>
      </div>
      <CreateEquipmentBlockModal
        open={showCreateEquipmentBlockModal}
        onClose={() => setShowCreateEquipmentBlockModal(false)}
      />
      <CreateEquipmentAssetModal
        open={showCreateEquipmentAssetModal}
        onClose={() => setShowCreateEquipmentAssetModal(false)}
      />
      <CreateEquipmentModal
        open={showCreateEquipmentModal}
        onClose={() => setShowCreateEquipmentModal(false)}
      />
    </div>
  );
};
