import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  name: string;
  description: string;
  type: 'STATIC' | 'DYNAMIC' | 'IMPORTED_CUSTOMER_LIST';
  conditionGroups: ConditionGroup[];
  importedCsvFileS3Key?: string;
  importedCsvFileUrl?: string;
}

export interface ConditionGroup {
  conditions: Condition[];
}

export interface Condition {
  filterCategory: 'RESERVATION' | 'REGISTERED_MEMBER' | 'CART_DROP';
  reservationFilter?: ReservationFilter;
  registeredMemberFilter?: RegisteredMemberFilter;
  cartDropFilter?: CartDropFilter;
}

export interface ReservationFilter {
  filterType:
    | 'PARTICIPATION_DATE'
    | 'BOOKED_DATE'
    | 'PRODUCT'
    | 'BOOKING_STATUS'
    | 'BOOKING_SOURCE'
    | 'BOOKING_LANGUAGE';
  participationDateFilter?: DateFilter;
  bookedDateFilter?: DateFilter;
  productFilter?: OptionFilter;
  bookingStatusFilter?: OptionFilter;
  bookingSourceFilter?: OptionFilter;
  bookingLanguageFilter?: OptionFilter;
}

export interface DateFilter {
  filterType: 'DATE' | 'DAYS';
  filterTypeOperator: 'BEFORE' | 'ON' | 'AFTER';
  filterTypeQualifier?: 'DAYS_AGO' | 'DAYS_FROM_NOW';
  value: string | number;
}

export interface OptionFilter {
  filterType: 'INCLUDE' | 'EXCLUDE';
  value: string[];
}

export interface CustomFieldFilter {
  filterType: 'INCLUDE' | 'EXCLUDE';
  key: string;
  value: string[];
}

export interface CountFilter {
  filterType: 'GTE' | 'LTE' | 'EQ';
  value: number;
}

export interface RegisteredMemberFilter {
  filterType:
    | 'REGISTRATION_DATE'
    | 'LAST_PARTICIPATION_DATE'
    | 'PARTICIPATION_COUNT'
    | 'AGE'
    | 'GENDER'
    | 'TAG'
    | 'LANGUAGE'
    | 'CUSTOM_FIELD';
  registrationDateFilter?: DateFilter;
  lastParticipationDateFilter?: DateFilter;
  participationCountFilter?: CountFilter;
  ageFilter?: OptionFilter;
  genderFilter?: OptionFilter;
  tagFilter?: OptionFilter;
  languageFilter?: OptionFilter;
  customFieldFilter?: CustomFieldFilter;
}

export interface CartDropFilter {
  filterType: 'CART_DROP_DATE' | 'PARTICIPATION_DATE' | 'PRODUCT';
  cartDropDateFilter?: DateFilter;
  participationDateFilter?: DateFilter;
  productFilter?: OptionFilter;
}

export const getDefaultConditionGroup = (): ConditionGroup => ({
  conditions: [
    {
      filterCategory: 'RESERVATION',
      reservationFilter: {
        filterType: 'PARTICIPATION_DATE',
        participationDateFilter: {
          filterType: 'DAYS',
          filterTypeOperator: 'BEFORE',
          filterTypeQualifier: 'DAYS_AGO',
          value: '1',
        },
      },
    },
  ],
});

export const getDefaultCondition = (): Condition => ({
  filterCategory: 'RESERVATION',
  reservationFilter: {
    filterType: 'PARTICIPATION_DATE',
    participationDateFilter: {
      filterType: 'DAYS',
      filterTypeOperator: 'BEFORE',
      filterTypeQualifier: 'DAYS_AGO',
      value: '1',
    },
  },
});

export const getInitialValues = (
  segment?: Swagger.NewsletterCustomerSegment
): FormValues => {
  if (segment) {
    return {
      name: segment.name ?? '',
      description: segment.description ?? '',
      type: segment.type ?? 'STATIC',
      conditionGroups:
        segment?.condition_groups?.map((group) => ({
          conditions:
            (group.conditions
              ?.map((cond) => convertSwaggerConditionToFormValues(cond))
              .filter((cond) => Boolean(cond)) as Condition[]) ?? [],
        })) ?? [],
      importedCsvFileUrl: segment.imported_csv_file_url,
    };
  }

  return {
    name: '',
    description: '',
    type: 'STATIC',
    conditionGroups: [],
  };
};

export const convertSwaggerConditionToFormValues = (
  condition: Swagger.NewsletterCustomerSegmentCondition
): Condition | undefined => {
  switch (condition.filter_category) {
    case 'RESERVATION':
      return {
        filterCategory: 'RESERVATION',
        reservationFilter: convertSwaggerReservationFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          condition.reservation_filter!
        ),
      };
    case 'REGISTERED_MEMBER':
      return {
        filterCategory: 'REGISTERED_MEMBER',
        registeredMemberFilter:
          convertSwaggerRegisteredMemberFilterToFormValues(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            condition.registered_member_filter!
          ),
      };
    case 'CART_DROP':
      return {
        filterCategory: 'CART_DROP',
        cartDropFilter: convertSwaggerCartDropFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          condition.cart_drop_filter!
        ),
      };
  }

  return undefined;
};

const convertSwaggerReservationFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentReservationFilter
): ReservationFilter => {
  switch (filter.filter_type) {
    case 'PARTICIPATION_DATE':
      return {
        filterType: 'PARTICIPATION_DATE',
        participationDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participation_date_filter!
        ),
      };
    case 'BOOKED_DATE':
      return {
        filterType: 'BOOKED_DATE',
        bookedDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.booked_date_filter!
        ),
      };
    case 'PRODUCT':
      return {
        filterType: 'PRODUCT',
        productFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.product_filter!
        ),
      };
    case 'BOOKING_STATUS':
      return {
        filterType: 'BOOKING_STATUS',
        bookingStatusFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.booking_status_filter!
        ),
      };
    case 'BOOKING_SOURCE':
      return {
        filterType: 'BOOKING_SOURCE',
        bookingSourceFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.booking_source_filter!
        ),
      };
    case 'BOOKING_LANGUAGE':
      return {
        filterType: 'BOOKING_LANGUAGE',
        bookingLanguageFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.booking_language_filter!
        ),
      };
  }

  throw new Error('Invalid reservation filter type', filter.filter_type);
};

const convertSwaggerRegisteredMemberFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentRegisteredMemberFilter
): RegisteredMemberFilter => {
  switch (filter.filter_type) {
    case 'REGISTRATION_DATE':
      return {
        filterType: 'REGISTRATION_DATE',
        registrationDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.registration_date_filter!
        ),
      };
    case 'LAST_PARTICIPATION_DATE':
      return {
        filterType: 'LAST_PARTICIPATION_DATE',
        lastParticipationDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.last_participation_date_filter!
        ),
      };
    case 'PARTICIPATION_COUNT':
      return {
        filterType: 'PARTICIPATION_COUNT',
        participationCountFilter: convertSwaggerCountFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participation_count_filter!
        ),
      };
    case 'AGE':
      return {
        filterType: 'AGE',
        ageFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.age_filter!
        ),
      };
    case 'GENDER':
      return {
        filterType: 'GENDER',
        genderFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.gender_filter!
        ),
      };
    case 'TAG':
      return {
        filterType: 'TAG',
        tagFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.tag_filter!
        ),
      };
    case 'LANGUAGE':
      return {
        filterType: 'LANGUAGE',
        languageFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.language_filter!
        ),
      };
    case 'CUSTOM_FIELD':
      return {
        filterType: 'CUSTOM_FIELD',
        customFieldFilter: convertSwaggerCustomFieldFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.custom_field_filter!
        ),
      };
  }

  throw new Error('Invalid registered member filter type', filter.filter_type);
};

const convertSwaggerCartDropFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentCartDropFilter
): CartDropFilter | undefined => {
  if (!filter) {
    return undefined;
  }

  switch (filter.filter_type) {
    case 'CART_DROP_DATE':
      return {
        filterType: 'CART_DROP_DATE',
        cartDropDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.cart_drop_date_filter!
        ),
      };
    case 'PARTICIPATION_DATE':
      return {
        filterType: 'PARTICIPATION_DATE',
        participationDateFilter: convertSwaggerDateFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participation_date_filter!
        ),
      };
    case 'PRODUCT':
      return {
        filterType: 'PRODUCT',
        productFilter: convertSwaggerOptionFilterToFormValues(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.product_filter!
        ),
      };
  }

  throw new Error('Invalid cart drop filter type', filter.filter_type);
};

const convertSwaggerDateFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentDateFilter
): DateFilter | undefined => {
  switch (filter.filter_type) {
    case 'BEFORE_X_DAYS_FROM_NOW':
      return {
        filterType: 'DAYS',
        filterTypeOperator: 'BEFORE',
        filterTypeQualifier: 'DAYS_FROM_NOW',
        value: filter.value ?? '',
      };
    case 'BEFORE_X_DAYS_AGO':
      return {
        filterType: 'DAYS',
        filterTypeOperator: 'BEFORE',
        filterTypeQualifier: 'DAYS_AGO',
        value: filter.value ?? '',
      };
    case 'AFTER_X_DAYS_FROM_NOW':
      return {
        filterType: 'DAYS',
        filterTypeOperator: 'AFTER',
        filterTypeQualifier: 'DAYS_FROM_NOW',
        value: filter.value ?? '',
      };
    case 'AFTER_X_DAYS_AGO':
      return {
        filterType: 'DAYS',
        filterTypeOperator: 'AFTER',
        filterTypeQualifier: 'DAYS_AGO',
        value: filter.value ?? '',
      };
    case 'DATE_GT':
      return {
        filterType: 'DATE',
        filterTypeOperator: 'AFTER',
        value: filter.value ?? '',
      };
    case 'DATE_LT':
      return {
        filterType: 'DATE',
        filterTypeOperator: 'BEFORE',
        value: filter.value ?? '',
      };
    case 'DATE_EQ':
      return {
        filterType: 'DATE',
        filterTypeOperator: 'ON',
        value: filter.value ?? '',
      };
  }
};

const convertSwaggerOptionFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentOptionFilter
): OptionFilter => {
  return {
    filterType: filter.filter_type ?? 'INCLUDE',
    value: filter.value ?? [],
  };
};

const convertSwaggerCustomFieldFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentCustomFieldFilter
): CustomFieldFilter => {
  return {
    filterType: filter.filter_type ?? 'INCLUDE',
    key: filter.key ?? '',
    value: filter.value ?? [],
  };
};

const convertSwaggerCountFilterToFormValues = (
  filter: Swagger.NewsletterCustomerSegmentCountFilter
): CountFilter => {
  return {
    filterType: filter.filter_type ?? 'GTE',
    value: parseInt(filter.value ?? '') || 0,
  };
};

export const convertFormValuesToSwaggerSegment = (
  values: FormValues
): Swagger.NewsletterCustomerSegmentParams => {
  return {
    name: values.name,
    description: values.description,
    type: values.type,
    condition_groups: values.conditionGroups.map((group) => ({
      conditions: group.conditions.map((cond) =>
        convertToSwaggerCondition(cond)
      ),
    })),
    imported_csv_file_s3_key: values.importedCsvFileS3Key,
  };
};

const convertToSwaggerReservationFilter = (
  filter: ReservationFilter
): Swagger.NewsletterCustomerSegmentReservationFilter => {
  switch (filter.filterType) {
    case 'PARTICIPATION_DATE':
      return {
        filter_type: 'PARTICIPATION_DATE',
        participation_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participationDateFilter!
        ),
      };
    case 'BOOKED_DATE':
      return {
        filter_type: 'BOOKED_DATE',
        booked_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.bookedDateFilter!
        ),
      };
    case 'PRODUCT':
      return {
        filter_type: 'PRODUCT',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        product_filter: convertToSwaggerOptionFilter(filter.productFilter!),
      };
    case 'BOOKING_STATUS':
      return {
        filter_type: 'BOOKING_STATUS',
        booking_status_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.bookingStatusFilter!
        ),
      };
    case 'BOOKING_SOURCE':
      return {
        filter_type: 'BOOKING_SOURCE',
        booking_source_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.bookingSourceFilter!
        ),
      };
    case 'BOOKING_LANGUAGE':
      return {
        filter_type: 'BOOKING_LANGUAGE',
        booking_language_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.bookingLanguageFilter!
        ),
      };
    default:
      throw new Error('Invalid filter type', filter.filterType);
  }
};

const convertToSwaggerDateFilter = (
  filter: DateFilter
): Swagger.NewsletterCustomerSegmentDateFilter => {
  if (filter.filterType === 'DATE') {
    switch (filter.filterTypeOperator) {
      case 'AFTER':
        return {
          filter_type: 'DATE_GT',
          value: `${filter.value}`,
        };
      case 'BEFORE':
        return {
          filter_type: 'DATE_LT',
          value: `${filter.value}`,
        };
      default:
        return {
          filter_type: 'DATE_EQ',
          value: `${filter.value}`,
        };
    }
  }

  switch (filter.filterTypeOperator) {
    case 'AFTER':
      if (filter.filterTypeQualifier === 'DAYS_FROM_NOW') {
        return {
          filter_type: 'AFTER_X_DAYS_FROM_NOW',
          value: `${filter.value}`,
        };
      }
      return {
        filter_type: 'AFTER_X_DAYS_AGO',
        value: `${filter.value}`,
      };
    default:
      if (filter.filterTypeQualifier === 'DAYS_FROM_NOW') {
        return {
          filter_type: 'BEFORE_X_DAYS_FROM_NOW',
          value: `${filter.value}`,
        };
      }
      return {
        filter_type: 'BEFORE_X_DAYS_AGO',
        value: `${filter.value}`,
      };
  }
};

const convertToSwaggerCountFilter = (
  filter: CountFilter
): Swagger.NewsletterCustomerSegmentCountFilter => {
  return {
    filter_type: filter.filterType,
    value: `${filter.value}`,
  };
};

const convertToSwaggerOptionFilter = (
  filter: OptionFilter
): Swagger.NewsletterCustomerSegmentOptionFilter => {
  return {
    filter_type: filter.filterType,
    value: filter.value,
  };
};

const convertToSwaggerCustomFieldFilter = (
  filter: CustomFieldFilter
): Swagger.NewsletterCustomerSegmentCustomFieldFilter => {
  return {
    filter_type: filter.filterType,
    key: filter.key,
    value: filter.value,
  };
};

const convertToSwaggerRegisteredMemberFilter = (
  filter: RegisteredMemberFilter
): Swagger.NewsletterCustomerSegmentRegisteredMemberFilter => {
  switch (filter.filterType) {
    case 'REGISTRATION_DATE':
      return {
        filter_type: 'REGISTRATION_DATE',
        registration_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.registrationDateFilter!
        ),
      };
    case 'LAST_PARTICIPATION_DATE':
      return {
        filter_type: 'LAST_PARTICIPATION_DATE',
        last_participation_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.lastParticipationDateFilter!
        ),
      };
    case 'PARTICIPATION_COUNT':
      return {
        filter_type: 'PARTICIPATION_COUNT',
        participation_count_filter: convertToSwaggerCountFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participationCountFilter!
        ),
      };
    case 'AGE':
      return {
        filter_type: 'AGE',
        age_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.ageFilter!
        ),
      };
    case 'GENDER':
      return {
        filter_type: 'GENDER',
        gender_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.genderFilter!
        ),
      };
    case 'TAG':
      return {
        filter_type: 'TAG',
        tag_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.tagFilter!
        ),
      };
    case 'LANGUAGE':
      return {
        filter_type: 'LANGUAGE',
        language_filter: convertToSwaggerOptionFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.languageFilter!
        ),
      };
    case 'CUSTOM_FIELD':
      return {
        filter_type: 'CUSTOM_FIELD',
        custom_field_filter: convertToSwaggerCustomFieldFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.customFieldFilter!
        ),
      };
    default:
      throw new Error('Invalid filter type', filter.filterType);
  }
};

const convertToSwaggerCartDropFilter = (
  filter: CartDropFilter
): Swagger.NewsletterCustomerSegmentCartDropFilter => {
  switch (filter.filterType) {
    case 'CART_DROP_DATE':
      return {
        filter_type: 'CART_DROP_DATE',
        cart_drop_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.cartDropDateFilter!
        ),
      };
    case 'PARTICIPATION_DATE':
      return {
        filter_type: 'PARTICIPATION_DATE',
        participation_date_filter: convertToSwaggerDateFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filter.participationDateFilter!
        ),
      };
    case 'PRODUCT':
      return {
        filter_type: 'PRODUCT',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        product_filter: convertToSwaggerOptionFilter(filter.productFilter!),
      };
    default:
      throw new Error('Invalid filter type');
  }
};

const convertToSwaggerCondition = (
  group: Condition
): Swagger.NewsletterCustomerSegmentCondition => {
  switch (group.filterCategory) {
    case 'RESERVATION':
      return {
        filter_category: 'RESERVATION',
        reservation_filter: convertToSwaggerReservationFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          group.reservationFilter!
        ),
      };
    case 'REGISTERED_MEMBER':
      return {
        filter_category: 'REGISTERED_MEMBER',
        registered_member_filter: convertToSwaggerRegisteredMemberFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          group.registeredMemberFilter!
        ),
      };
    case 'CART_DROP':
      return {
        filter_category: 'CART_DROP',
        cart_drop_filter: convertToSwaggerCartDropFilter(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          group.cartDropFilter!
        ),
      };
    default:
      throw new Error('Invalid filter category', group.filterCategory);
  }
};
