import { Icon } from 'client/components/Icons/Icon';
import toggleIcon from 'client/images/ic_toggle.svg';

type Props = {
  onClick?: () => void;
  className?: string;
};
export const Toggle = (props: Props) => {
  return <Icon {...props} src={toggleIcon} />;
};
