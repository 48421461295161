import * as React from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { config } from 'client/config';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';

import { GuidancePageItem } from './formValues';
import { EditDigitalGuidanceItemModal } from './EditDigitalGuidanceItemModal';
import { Location, LocationMap } from './LocationMap';

export const DigitalGuidanceItemListEditor = () => {
  const { t } = useTranslation();

  const products = useSelector(summariesSortedByBookmarkedSelector);

  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);

  const formState = useFormState();
  const items = formState.values?.items ?? [];

  const locations: Location[] = [];
  for (const item of items) {
    if (item.location) {
      locations.push({
        renderContent: () => (
          <div>
            <h4>{item.title}</h4>
            {item.location.name}
          </div>
        ),
        latitude: item.location.latitude,
        longitude: item.location.longitude,
      });
    }
  }

  const getTitle = (item: GuidancePageItem) => {
    if (item.itemType === 'PRODUCT') {
      const product = products.find((p) => p.id === item.productId);
      return t('[Additional reservations/purchases] {{productName}}', {
        productName: product?.product_name ?? '',
      });
    }
    return item.title;
  };

  return (
    <>
      <Box mt={2}>
        <FieldWrapper label={t('Items')}>
          <Field name="items">
            {({ input }) => (
              <>
                <Button
                  size="middle"
                  style="green"
                  onClick={() => setEditingIdx(input.value.length)}
                >
                  {config.enableBookInDigitalGuidance
                    ? t('Create New Guidance Item or Booking Link')
                    : t('Create New Guidance Item')}
                </Button>
                <EditDeleteButtonDndList
                  items={input.value?.map((item: GuidancePageItem) => ({
                    key: item.key,
                    text: getTitle(item),
                  }))}
                  setItems={(items) => {
                    input.onChange(
                      items.map((item) =>
                        input.value?.find(
                          (guidancePageItem: GuidancePageItem) =>
                            guidancePageItem.key === item.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setEditingIdx(idx);
                  }}
                />

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete guidance item')}
                    content={t(
                      'Are you sure you want to delete guidance item?'
                    )}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {editingIdx != null && (
                  <EditDigitalGuidanceItemModal
                    existingItem={
                      editingIdx >= (input.value ?? []).length
                        ? undefined
                        : input.value[editingIdx]
                    }
                    onSave={(item: GuidancePageItem) => {
                      if (editingIdx >= (input.value ?? []).length) {
                        input.onChange([...input.value, item]);
                      } else {
                        input.onChange(
                          input.value.map((existingItem: any, idx: number) =>
                            idx === editingIdx ? item : existingItem
                          )
                        );
                      }
                    }}
                    open={editingIdx != null}
                    onClose={() => setEditingIdx(null)}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>
        {locations && locations.length > 0 && (
          <Box mt={2} display="flex" justifyContent="center">
            <LocationMap locations={locations} />
          </Box>
        )}
      </Box>
    </>
  );
};
