import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import {
  fetchMarketingAutomationCampaignReportData,
  fetchMarketingAutomationCampaigns,
} from 'client/actions/marketingAutomationCampaigns';

import styles from './MarketingAutomationDashboard.module.css';
import { Gadgets } from './Gadgets';

interface DateRange {
  start: string;
  end: string;
}

export const MarketingAutomationDashboard = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = React.useState<DateRange>(
    getDateRangeForPreset('PREV_7_DAYS')
  );
  const [comparisonDateRange, setComparisonDateRange] =
    React.useState<DateRange | null>(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationCampaigns());
  }, []);

  React.useEffect(() => {
    const dateRanges = [{ start: dateRange.start, end: dateRange.end }];
    if (comparisonDateRange?.start && comparisonDateRange?.end) {
      dateRanges.push({
        start: comparisonDateRange.start,
        end: comparisonDateRange.end,
      });
    }

    dispatch(fetchMarketingAutomationCampaignReportData(dateRanges));
  }, [
    dateRange.start,
    dateRange.end,
    comparisonDateRange?.start,
    comparisonDateRange?.end,
  ]);

  return (
    <V3Page>
      <PageHeader title={t('Dashboard')}>
        <div className={styles['header-actions-container']}>
          <ComparisonDateInput
            baseDateRange={dateRange}
            setBaseDateRange={setDateRange}
            comparisonDateRange={comparisonDateRange}
            setComparisonDateRange={setComparisonDateRange}
            menuStyle={{ right: 0, left: 'auto' }}
          />
        </div>
      </PageHeader>
      <PageContent>
        <Gadgets />
      </PageContent>
    </V3Page>
  );
};
