import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { allProductTagsSelector } from 'client/reducers/products';
import { Box } from 'client/components/Box/Box';
import { MultiSelect } from 'client/components/Form';
import {
  setAvailabilityEndDate,
  setAvailabilityStartDate,
  setMonthlyAvailabilitySelectedMonth,
  setProductCalendarListTagFilter,
  setProductCalendarListSalesStatusFilter,
} from 'client/actions/productCalendarListControls';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { getSalesStatusOptions } from 'client/libraries/util/getProductSalesStatus';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import pageStyles from 'client/pages/pages.module.css';
import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import { tagFilterSetSelector, salesStatusFilterSetSelector } from './util';
import { AvailabilityTable } from './AvailabilityTable';
import styles from './MonthlyAvailability.module.css';
import { CalendarEditButtonRow } from './CalendarEditButtonRow';
import { CalendarEditContext } from './CalendarEditContext';

export const MonthlyAvailability = () => {
  const { t } = useTranslation();

  const [selectedProductInstanceIds, setSelectedProductInstanceIds] =
    React.useState<string[]>([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    React.useState(false);
  const allTags = useSelector(allProductTagsSelector);

  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);

  const dates = useSelector(productCalendarListDatesSelector);
  const selectedMonth = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.monthlyAvailabilitySelectedMonth
  );
  const activeUser = useSelector(activeUserSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setAvailabilityStartDate(
        moment(selectedMonth).startOf('month').format('YYYY-MM-DD')
      )
    );
    dispatch(
      setAvailabilityEndDate(
        moment(selectedMonth)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
    );
  }, [selectedMonth]);

  const tagOptions = React.useMemo(
    () =>
      allTags.map((tag) => ({
        key: tag,
        value: tag,
        text: tag,
      })) || [],
    [allTags]
  );

  return (
    <CalendarEditContext.Provider
      value={{
        selectedProductInstanceIds,
        selectProductInstanceIds: (...ids) => {
          const idsToAdd = ids.filter(
            (id) => !selectedProductInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedProductInstanceIds([
              ...selectedProductInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectProductInstanceIds: (...ids) => {
          setSelectedProductInstanceIds(
            selectedProductInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="AVAILABILITY_MONTHLY" />
      )}
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                dispatch(
                  setMonthlyAvailabilitySelectedMonth(
                    moment(selectedMonth).add(-1, 'months').format('YYYY-MM-DD')
                  )
                );
              }}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              {moment(dates[0]).format('YYYY/MM')}
            </Box>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                dispatch(
                  setMonthlyAvailabilitySelectedMonth(
                    moment(selectedMonth).add(1, 'months').format('YYYY-MM-DD')
                  )
                );
              }}
            />
          </div>
        </Box>

        <div className={styles['tag-select']}>
          <MultiSelect
            placeholder={t('Filter product status...')}
            options={getSalesStatusOptions(t)}
            selectedValues={salesStatusFilterSet as string[]}
            onChange={({ value }) => {
              dispatch(
                setProductCalendarListSalesStatusFilter(value as SalesStatus[])
              );
            }}
            withoutFrame={true}
            search
          />
        </div>

        <div className={styles['tag-select']}>
          <MultiSelect
            placeholder={t('Filter tags...')}
            options={tagOptions}
            selectedValues={tagFilterSet}
            onChange={({ value }) => {
              dispatch(setProductCalendarListTagFilter(value));
            }}
            withoutFrame={true}
            search
          />
        </div>

        {hasCustomUserRoleWritePermissions(
          activeUser,
          'AVAILABILITY.MONTHLY'
        ) && <CalendarEditButtonRow />}
      </div>

      <AvailabilityTable />
    </CalendarEditContext.Provider>
  );
};
