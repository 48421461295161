import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, NumberInput } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

interface Props {
  title: string;
  initialValue?: number;
  open: boolean;
  onClose: () => void;
  onSubmit: (value: number) => void;
}

export const NumberInputModal = ({
  title,
  initialValue,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(initialValue ?? 0);
  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Modal.Content>
        <NumberInput
          value={value}
          onChange={(_, { value }) => setValue(Number(value))}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button
          size="middle"
          style="blue"
          onClick={() => {
            onSubmit(value);
          }}
        >
          {t('Yes')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
