import * as React from 'react';
import { Field } from 'react-final-form';

import { ProductList, truncated, withQueryParams } from '../../util';

const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);
const PromoEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/PromoEditButton')
);
const ProductListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ProductListEditButton')
);

type Props = {
  data: {
    editMode: boolean;
    keyVisualUrl: string;
    eyeCatchText: string;
    promoLeadText: string;
    promoDiscountText: string;
    promoDescription: string;
    promoCode?: string;
    promoCodePreText?: string;
    promoPeriodText: string;
    periodText: string;
    noteText?: string;
    productList: ProductList;
    newsletterCampaignId: string;
    language: string;
  };
  isPromoCode?: boolean;
};

export const PromoSection = ({ data, isPromoCode = true }: Props) => {
  return (
    <tr style={{ margin: 0, padding: 0 }}>
      <td>
        <div
          style={{
            margin: 0,
            padding: '1.5em',
            backgroundColor: '#ffffff',
          }}
        >
          <>
            <>
              <div>
                <div style={{ marginBottom: '1.5em' }}>
                  {data.editMode && (
                    <Field name="templateValues.eyeCatchText">
                      {({ input }) => (
                        <TextAreaEditButton
                          initialValue={input.value}
                          onSave={(text) => input.onChange(text)}
                        />
                      )}
                    </Field>
                  )}
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 'bold',
                      fontSize: '1.5em',
                      whiteSpace: 'pre-line',
                      borderBottom: '1px solid #E4E4E7',
                      paddingBottom: '20px',
                    }}
                  >
                    {data.eyeCatchText}
                  </p>
                  {data.editMode && (
                    <div style={{ textAlign: 'left' }}>
                      <Field name="templateValues">
                        {({ input }) => (
                          <PromoEditButton
                            initialValue={{
                              promoDiscountText: input.value.promoDiscountText,
                              promoCode: input.value.promoCode,
                              promoLeadText: input.value.promoLeadText,
                              promoDescription: input.value.promoDescription,
                              promoPeriodText: input.value.promoPeriodText,
                              periodText: input.value.periodText,
                            }}
                            onSave={({
                              promoDiscountText,
                              promoCode,
                              promoLeadText,
                              promoDescription,
                              promoPeriodText,
                              periodText,
                            }: {
                              promoDiscountText: string;
                              promoCode: string;
                              promoLeadText: string;
                              promoDescription: string;
                              promoPeriodText: string;
                              periodText: string;
                            }) => {
                              input.onChange({
                                ...input.value,
                                promoDiscountText,
                                promoCode,
                                promoLeadText,
                                promoDescription,
                                promoPeriodText,
                                periodText,
                              });
                            }}
                            isPromoCode={isPromoCode}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <p
                    style={{
                      margin: '20px 0 0 0',
                      padding: 0,
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {data.promoDescription}
                  </p>
                </div>
                <div
                  style={{
                    backgroundImage:
                      'linear-gradient(90.94deg, #EF914D 0%, #E25263 100%)',
                    padding: '16px',
                    borderRadius: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <table style={{ width: '100%' }}>
                    <tbody>
                      {isPromoCode && (
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              <div
                                style={{
                                  padding: '4px 24px',
                                  border: '1px solid #FFF',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                  lineHeight: 1,
                                }}
                              >
                                <img
                                  src="https://assets.ntmg.com/newsletter/gift-1.png"
                                  height={16}
                                  width={16}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '4px',
                                    verticalAlign: 'middle',
                                  }}
                                />
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '2px',
                                  }}
                                >
                                  {data.promoLeadText}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr style={{ textAlign: 'center' }}>
                        <td style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              marginTop: '2em',
                              marginBottom: isPromoCode ? '1em' : '2em',
                              textAlign: 'center',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontSize: '48px',
                                fontWeight: 'bold',
                                color: '#FFF',
                                letterSpacing: '-2px',
                                fontFamily: 'Hiragino Sans',
                              }}
                            >
                              {data.promoDiscountText}
                            </p>
                          </div>
                        </td>
                      </tr>
                      {isPromoCode && (
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  paddingTop: '12px',
                                  paddingBottom: '12px',
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  lineHeight: '24px',
                                  width: '260px',
                                  display: 'inline-block',
                                  backgroundColor: '#FFF',
                                  borderRadius: '8px',
                                }}
                              >
                                {data.promoCode}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ marginBottom: '1.5em' }}>
                <div
                  style={{
                    backgroundColor: '#F4F4F5',
                    padding: '16px',
                    borderRadius: '8px',
                  }}
                >
                  <p style={{ margin: 0, whiteSpace: 'pre-line' }}>
                    {data.promoPeriodText}
                  </p>
                  <p style={{ margin: 0, whiteSpace: 'pre-line' }}>
                    {data.periodText}
                  </p>
                  {data.noteText && (
                    <>
                      {data.editMode && (
                        <Field name="templateValues.noteText">
                          {({ input }) => (
                            <TextAreaEditButton
                              initialValue={input.value}
                              onSave={(text) => input.onChange(text)}
                            />
                          )}
                        </Field>
                      )}
                      <p style={{ margin: 0, whiteSpace: 'pre-line' }}>
                        {data.noteText}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          </>
          <div style={{ padding: 0 }}>
            {data.editMode && (
              <Field name="templateValues.productList">
                {({ input }) => (
                  <ProductListEditButton
                    initialValue={data.productList}
                    onSave={(newValue) => input.onChange(newValue)}
                    language={data.language}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                fontSize: '1.5em',
                whiteSpace: 'pre-line',
                paddingBottom: '1em',
                paddingTop: '1em',
              }}
            >
              {data.productList.title}
            </p>
            <table
              cellSpacing={0}
              cellPadding={0}
              width="100%"
              style={{ margin: 0 }}
            >
              <tbody>
                {data.productList.products.map((product, idx) => (
                  <tr key={idx}>
                    <td
                      style={{
                        verticalAlign: 'top',
                        margin: 0,
                        paddingBottom:
                          idx !== data.productList.products.length - 1
                            ? '1.5em'
                            : undefined,
                      }}
                    >
                      <div
                        style={{
                          border: '1px solid #E4E4E7',
                          borderRadius: '16px',
                        }}
                      >
                        <a
                          href={withQueryParams(product.productUrl, {
                            utm_source: 'ntmg.newsletter',
                            utm_medium: 'email',
                            utm_campaign: data.newsletterCampaignId ?? 'todo',
                            utm_content: 'productList',
                            lng: data.language,
                          })}
                          style={{
                            width: '100%',
                            margin: '1em 0 0 0',
                            padding: 0,
                            background: '#F9F9F9',
                            textDecoration: 'none',
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div
                            style={{
                              margin: '1.5em',
                              verticalAlign: 'middle',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={product.productThumbnailUrl}
                              style={{
                                width: '100%',
                                borderRadius: '8px',
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: '0 1.5em 1.5em 1.5em',
                              color: '#333333',
                              verticalAlign: 'middle',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '16px',
                                fontWeight: 'bold',
                                lineHeight: '1.5em',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {product.productName}
                            </p>
                            <p
                              style={{
                                margin: '.5em 0 0 0',
                                padding: 0,
                                fontSize: '12px',
                                color: '#4A6C7A',
                                lineHeight: '1.5em',
                              }}
                            >
                              {truncated(product.productDescription, 200)}
                            </p>
                          </div>
                          <div style={{ padding: '0 1.5em 1.5em 1.5em' }}>
                            <p
                              style={{
                                maxWidth: '240px',
                                borderRadius: '9999px',
                                textDecoration: 'none',
                                padding: '2px',
                                display: 'block',
                                margin: '0 auto',
                              }}
                            >
                              <span
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  display: 'block',
                                  fontWeight: 'bold',
                                  width: '100%',
                                  height: '48px',
                                  lineHeight: '48px',
                                  fontSize: '16px',
                                  backgroundColor: '#4DA1EF',
                                  color: '#ffffff',
                                  textDecoration: 'none',
                                  textAlign: 'center',
                                  borderRadius: '40px',
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {product.buttonText}
                              </span>
                            </p>
                          </div>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};
