import { createContext } from 'react';

type Props = {
  columnWidths: { [key: number]: string };
};

// ViewContext can be used to pass UI-related information down the component tree for weekly
export const ViewContext = createContext<Props>({
  columnWidths: {},
});
