import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Button, Select } from 'client/components/Form';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

type Props = {
  productIds: string[];
  onChange: (arg0: string[]) => void;
  error?: string | typeof undefined;
};
export const ProductIdsListInput = ({ productIds, onChange, error }: Props) => {
  const { t } = useTranslation();
  const productsToChoose = useSelector(summariesWithBookmarksSelector);
  return (
    <div className={baseStyles['base-form-box']}>
      {productIds &&
        productIds.map((currentValue, currentValueIdx) => (
          <div
            key={currentValueIdx}
            className={clsx(baseStyles['inline-block'])}
          >
            <Select
              search
              options={productsToChoose
                .filter(
                  // Does not allow choosing duplicate
                  (p) => p.id === currentValue || !productIds.includes(p.id)
                )
                .map((p) => ({
                  value: p.id ?? '',
                  text: getVerboseDisplayProductName(p),
                }))}
              value={currentValue}
              onChange={(e, { value: newProductId }) => {
                onChange(
                  productIds.map((productId, idx) =>
                    idx === currentValueIdx ? newProductId : productId
                  )
                );
              }}
            />

            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['icon'],
                baseStyles['inline']
              )}
              onClick={() => {
                const newProductIds = [...productIds];
                newProductIds.splice(currentValueIdx, 1);
                onChange(newProductIds);
              }}
            >
              <img src={deleteIcon} />
            </a>
          </div>
        ))}

      {productIds && (
        <div className={clsx(baseStyles['list-add-btn'])}>
          <Button
            style="green"
            size="middle"
            onClick={() => {
              const newProductIds = [...productIds];
              newProductIds.splice(productIds.length + 1, 0, '');
              onChange(newProductIds);
            }}
          >
            {t('Add')}
          </Button>
        </div>
      )}
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
    </div>
  );
};
