import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';
import { BookingSourceType } from 'client/libraries/util/getBookingSourceOptions';
import * as Swagger from 'shared/models/swagger';

export type DateFilterPreset =
  | 'LAST_UPDATED_1_DAY'
  | 'LAST_UPDATED_3_DAYS'
  | 'LAST_UPDATED_7_DAYS'
  | 'BOOKED_AT_1_DAY'
  | 'BOOKED_AT_3_DAYS'
  | 'BOOKED_AT_7_DAYS'
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'EXPIRATION_1_WEEK'
  | 'EXPIRATION_2_WEEKS'
  | 'EXPIRATION_1_MONTH'
  | 'EXPIRATION_3_MONTHS'
  | 'EXPIRATION_6_MONTHS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'LAST_UPDATED_1_DAY',
      'LAST_UPDATED_3_DAYS',
      'LAST_UPDATED_7_DAYS',
      'BOOKED_AT_1_DAY',
      'BOOKED_AT_3_DAYS',
      'BOOKED_AT_7_DAYS',
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};

export const getExpirationFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'EXPIRATION_1_WEEK',
      'EXPIRATION_2_WEEKS',
      'EXPIRATION_1_MONTH',
      'EXPIRATION_3_MONTHS',
      'EXPIRATION_6_MONTHS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'LAST_UPDATED_1_DAY':
      return t('Last updated, past {{count}} days', {
        count: 1,
      });

    case 'LAST_UPDATED_3_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 3,
      });

    case 'LAST_UPDATED_7_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 7,
      });

    case 'BOOKED_AT_1_DAY':
      return t('Booked at, past {{count}} days', {
        count: 1,
      });

    case 'BOOKED_AT_3_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 3,
      });

    case 'BOOKED_AT_7_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
    case 'EXPIRATION_1_WEEK':
      return t('Expiration, next 1 week');
    case 'EXPIRATION_2_WEEKS':
      return t('Expiration, next {{count}} weeks', {
        count: 2,
      });
    case 'EXPIRATION_1_MONTH':
      return t('Expiration, next 1 month');
    case 'EXPIRATION_3_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 3,
      });
    case 'EXPIRATION_6_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 6,
      });
  }
};

export type SearchReservationsRequest = {
  presetKey: string;
  agentReference: string;
  agentIds: string[];
  supplierReference: string;
  id: string;
  statuses: Swagger.ReservationStatus[];
  customerGivenName: string;
  customerFamilyName: string;
  customerPhone: string;
  customerEmail: string;
  bookingSourceTypes: BookingSourceType[];
  groupIds: string[];
  supplierIds: string[];
  productIds: string[];
  bookedDateFrom: string;
  bookedDateTo: string;
  participationDateFrom: string;
  participationDateTo: string;
  lastUpdatedDateFrom: string;
  lastUpdatedDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
  orderBy: string;
  supplierOrAgentReference: string;
  reservationLanguages: Swagger.SourceLanguage[];
  mostRecentEmailBounced: boolean;
  pickupCheckinLocationName: string;
  waiverCompletionStatuses: Swagger.WaiverCompletionStatus[];
  checkinStatuses: Swagger.CheckinStatus[];

  annualPassOnly: boolean;
  expirationPresetKey: string;
  expirationDateFrom: string;
  expirationDateTo: string;
  expirationDateFilterPreset: DateFilterPreset | null;
  automaticContinuingStatus: 'ON' | 'OFF' | null;
};

export type SearchReservationsRequestKey =
  | 'presetKey'
  | 'agentReference'
  | 'agentIds'
  | 'supplierReference'
  | 'id'
  | 'statuses'
  | 'customerGivenName'
  | 'customerFamilyName'
  | 'customerPhone'
  | 'customerEmail'
  | 'bookingSourceTypes'
  | 'groupIds'
  | 'supplierIds'
  | 'productIds'
  | 'bookedDateFrom'
  | 'bookedDateTo'
  | 'participationDateFrom'
  | 'participationDateTo'
  | 'lastUpdatedDateFrom'
  | 'lastUpdatedDateTo'
  | 'dateFilterPreset'
  | 'orderBy'
  | 'supplierOrAgentReference'
  | 'reservationLanguages'
  | 'mostRecentEmailBounced'
  | 'pickupCheckinLocationName'
  | 'waiverCompletionStatuses'
  | 'checkinStatuses'
  | 'annualPassOnly'
  | 'expirationPresetKey'
  | 'expirationDateFrom'
  | 'expirationDateTo'
  | 'expirationDateFilterPreset'
  | 'automaticContinuingStatus';

export const buildSearchReservationsRequest = (
  req: SearchReservationsRequest
): Swagger.SearchReservationsRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let bookedDateTimeUtcFrom = '';
  let bookedDateTimeUtcTo = '';
  let lastUpdatedDateTimeUtcFrom = '';
  let lastUpdatedDateTimeUtcTo = '';
  let expirationDateFrom = '';
  let expirationDateTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'LAST_UPDATED_1_DAY':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_3_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(3, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_7_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_1_DAY':
        bookedDateTimeUtcFrom = now.subtract(1, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_3_DAYS':
        bookedDateTimeUtcFrom = now.subtract(3, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_7_DAYS':
        bookedDateTimeUtcFrom = now.subtract(7, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    bookedDateTimeUtcFrom = req.bookedDateFrom
      ? moment(req.bookedDateFrom).utc().format()
      : '';
    bookedDateTimeUtcTo = req.bookedDateTo
      ? moment(req.bookedDateTo).add(1, 'day').utc().format()
      : '';
    lastUpdatedDateTimeUtcFrom = req.lastUpdatedDateFrom
      ? moment(req.lastUpdatedDateFrom).utc().format()
      : '';
    lastUpdatedDateTimeUtcTo = req.lastUpdatedDateTo
      ? moment(req.lastUpdatedDateTo).add(1, 'day').utc().format()
      : '';
  }

  if (req.expirationDateFilterPreset) {
    const now = moment();
    switch (req.expirationDateFilterPreset) {
      case 'EXPIRATION_1_WEEK':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(1, 'week').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_2_WEEKS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(2, 'weeks').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_1_MONTH':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(1, 'month').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_3_MONTHS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(3, 'months').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_6_MONTHS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(6, 'months').format('YYYY-MM-DD');
        break;
    }
  } else {
    expirationDateFrom = req.expirationDateFrom;
    expirationDateTo = req.expirationDateTo
      ? moment(req.expirationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
  }

  let orderBy = '';
  let isAscending = true;
  switch (req.orderBy) {
    case 'auto':
      if (
        req.customerGivenName ||
        req.customerFamilyName ||
        req.agentReference ||
        req.supplierReference
      ) {
        // Use default relevance sorting if names or booking references are searched for
        break;
      }

      // Otherwise, sort by date field
      if (lastUpdatedDateTimeUtcFrom || lastUpdatedDateTimeUtcTo) {
        orderBy = 'last_updated_date_time_utc';
        isAscending = false;
        break;
      }
      if (bookedDateTimeUtcFrom || bookedDateTimeUtcTo) {
        orderBy = 'booked_date_time_utc';
        isAscending = false;
        break;
      }
      if (startDateLocalFrom || startDateLocalTo) {
        orderBy = 'start_date_time_utc';
        isAscending = true;
        break;
      }
      if (expirationDateFrom || expirationDateTo) {
        orderBy = 'checkin_info.expiration_date_time_utc';
        isAscending = true;
        break;
      }

      // Default to sorting by last updated.
      orderBy = 'last_updated_date_time_utc';
      isAscending = false;
      break;
    case 'last_updated_asc':
      orderBy = 'last_updated_date_time_utc';
      isAscending = true;
      break;
    case 'last_updated_desc':
      orderBy = 'last_updated_date_time_utc';
      isAscending = false;
      break;
    case 'booked_asc':
      orderBy = 'booked_date_time_utc';
      isAscending = true;
      break;
    case 'booked_desc':
      orderBy = 'booked_date_time_utc';
      isAscending = false;
      break;
    case 'participation_asc':
      orderBy = 'start_date_time_utc';
      isAscending = true;
      break;
    case 'participation_desc':
      orderBy = 'start_date_time_utc';
      isAscending = false;
      break;
    case 'expiration_asc':
      orderBy = 'checkin_info.expiration_date_time_utc';
      isAscending = true;
      break;
    case 'expiration_desc':
      orderBy = 'checkin_info.expiration_date_time_utc';
      isAscending = false;
      break;
    case 'family_name':
      orderBy = 'family_name';
      isAscending = true;
      break;
  }

  let reservationType = 'RESERVATION_TYPE_NORMAL';
  if (req.annualPassOnly) {
    reservationType = 'RESERVATION_TYPE_ANNUAL_PASS';
  }

  return {
    ...(!req.annualPassOnly
      ? {
          start_date_local_from: startDateLocalFrom,
          start_date_local_to: startDateLocalTo,
          booked_date_time_utc_from: bookedDateTimeUtcFrom,
          booked_date_time_utc_to: bookedDateTimeUtcTo,
          last_updated_date_time_utc_from: lastUpdatedDateTimeUtcFrom,
          last_updated_date_time_utc_to: lastUpdatedDateTimeUtcTo,
        }
      : {
          expiration_date_time_utc_from: expirationDateFrom,
          expiration_date_time_utc_to: expirationDateTo,
          ...(req.automaticContinuingStatus
            ? { automatic_continuing_status: req.automaticContinuingStatus }
            : {}),
        }),
    agent_reference: req.agentReference,
    booking_sources: req.bookingSourceTypes,
    group_ids: req.groupIds,
    agent_ids: req.agentIds,
    supplier_reference: req.supplierReference,
    reservation_id: req.id,
    statuses: req.statuses,
    family_name: req.customerFamilyName,
    given_name: req.customerGivenName,
    phone: req.customerPhone,
    email: req.customerEmail,
    supplier_ids: req.supplierIds,
    supplier_or_agent_reference: req.supplierOrAgentReference,
    reservation_languages: req.reservationLanguages,
    most_recent_email_bounced: req.mostRecentEmailBounced,
    product_ids: req.productIds,
    pickup_checkin_location_name: req.pickupCheckinLocationName,
    waiver_completion_statuses: req.waiverCompletionStatuses,
    checkin_statuses: req.checkinStatuses,
    reservation_type: [reservationType] as Swagger.ReservationType[],
    order_by: orderBy,
    is_ascending: isAscending,
  };
};
