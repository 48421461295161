import FileSaver from 'file-saver';
import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  DELETE_WAIVER_FAILURE,
  DELETE_WAIVER_REQUEST,
  DELETE_WAIVER_SUCCESS,
  CLEAR_PARTICIPANT_WAIVER_FAILURE,
  CLEAR_PARTICIPANT_WAIVER_REQUEST,
  CLEAR_PARTICIPANT_WAIVER_SUCCESS,
  FETCH_WAIVERS_REQUEST,
  FETCH_WAIVERS_SUCCESS,
  FETCH_WAIVERS_FAILURE,
  DOWNLOAD_WAIVER_PDF_REQUEST,
  DOWNLOAD_WAIVER_PDF_SUCCESS,
  DOWNLOAD_WAIVER_PDF_FAILURE,
  FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST,
  FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS,
  FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { ListWaiversRequest, ListWaiversResponse } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchWaiversRequest = () => ({
  type: FETCH_WAIVERS_REQUEST,
});

const fetchWaiversSuccess = (response: ListWaiversResponse) => ({
  type: FETCH_WAIVERS_SUCCESS,
  response,
});

const fetchWaiversFailure = (error: any) => ({
  type: FETCH_WAIVERS_FAILURE,
  error,
});

export const fetchWaivers =
  (query: ListWaiversRequest, pageSize = 1, offset = 0) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchWaiversRequest());
    return axios
      .get('/api/waivers', {
        params: {
          ...query,
          page_size: pageSize,
          offset,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchWaiversSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchWaiversFailure(err.message));
      });
  };

const deleteWaiverRequest = () => ({
  type: DELETE_WAIVER_REQUEST,
});

const deleteWaiverSuccess = (reservationId: string) => ({
  type: DELETE_WAIVER_SUCCESS,
  reservationId,
});

const deleteWaiverFailure = (error: any) => ({
  type: DELETE_WAIVER_FAILURE,
  error,
});

export const deleteWaiver =
  (reservationId: string, waiverId: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteWaiverRequest());
    return axios
      .delete(`/api/waivers/${waiverId}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => {
        dispatch(deleteWaiverSuccess(reservationId));
      })
      .catch((err) => {
        dispatch(deleteWaiverFailure(err.message));
      });
  };

const clearParticipantWaiverRequest = () => ({
  type: CLEAR_PARTICIPANT_WAIVER_REQUEST,
});

const clearParticipantWaiverSuccess = (reservationId: string) => ({
  type: CLEAR_PARTICIPANT_WAIVER_SUCCESS,
  reservationId,
});

const clearParticipantWaiverFailure = (error: any) => ({
  type: CLEAR_PARTICIPANT_WAIVER_FAILURE,
  error,
});

export const clearParticipantWaiver =
  (reservationId: string, waiverId: string, participantName: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(clearParticipantWaiverRequest());
    return axios
      .post(
        `/api/waivers/${waiverId}/clear-participant`,
        {
          participant_full_name: participantName,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => {
        dispatch(clearParticipantWaiverSuccess(reservationId));
      })
      .catch((err) => {
        dispatch(clearParticipantWaiverFailure(err.message));
      });
  };
const fetchWaiverPDFRequest = () => ({
  type: DOWNLOAD_WAIVER_PDF_REQUEST,
});

const fetchWaiverPDFSuccess = () => ({
  type: DOWNLOAD_WAIVER_PDF_SUCCESS,
});

const fetchWaiverPDFFailure = (error: any) => ({
  type: DOWNLOAD_WAIVER_PDF_FAILURE,
  error,
});

export const fetchWaiverPDF =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchWaiverPDFRequest());
    axios
      .post(`/api/waivers/${id}/pdf`, undefined, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        const filename =
          response.headers?.['content-disposition']
            .split('filename=')[1]
            .split(';')[0] ?? 'waiver.pdf';
        dispatch(fetchWaiverPDFSuccess());
        FileSaver.saveAs(response.data, filename);
      })
      .catch((err) => dispatch(fetchWaiverPDFFailure(err)));
  };

const fetchWaiversByReservationIDsRequest = (ids: string[]) => ({
  type: FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST,
  ids,
});

const fetchWaiversByReservationIDsSuccess = (responses: any) => ({
  type: FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS,
  responses,
});

const fetchWaiversByReservationIDsFailure = (error: any) => ({
  type: FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE,
  error,
});

export const fetchWaiversByReservationIDs =
  (ids: string[]) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(fetchWaiversByReservationIDsRequest(ids));
    const promises = ids.map((id) =>
      fetch(`/api/waivers?reservation_id=${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      }).then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
    );
    return Promise.all(promises)
      .then((responses) =>
        dispatch(fetchWaiversByReservationIDsSuccess(responses))
      )
      .catch((error) => dispatch(fetchWaiversByReservationIDsFailure(error)));
  };
