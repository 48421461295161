import { useTranslation } from 'react-i18next';
import {
  Field,
  FieldRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  ContentType,
  EntranceStyle,
  Widget,
} from '@nutmeglabs/nutmeg-sitecontrol-ui';

import { ButtonStyleEditor } from 'client/components/v3/ButtonStyleEditor/ButtonStyleEditor';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { fetchPromotions } from 'client/actions/promotions';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { fetchProducts } from 'client/actions/products';
import { TranslateFuncType } from 'client/components/Translate';
import { Promotion } from 'shared/models/swagger';
import { getApiPromotionTitleText } from 'client/pages/ProductDetails/getPromotionTitleText';
import { getPromotionPeriodText } from 'client/components/TemplateEditButtons/PromoEditButton';
import { ReduxState } from 'client/reducers';

import { convertFormValuesToSwagger, FormValues } from '../formValues';
import styles from '../SiteControlWidgetEditor.module.css';

import { ButtonListEditor } from './ButtonListEditor';

type Props = {
  type: ContentType;
};

const getContentTypeName = (type: ContentType) => {
  switch (type) {
    case 'COUPON':
      return 'coupon';
    case 'CARD':
      return 'card';
    case 'VERTICAL':
      return 'vertical';
    case 'MULTI_BUTTONS':
      return 'multiButtons';
    default:
      return '';
  }
};

const getPromotionName = (
  promotion: Promotion,
  t: TranslateFuncType,
  locale: string
): string => {
  const parts: string[] = [];

  if (promotion.promo_code) {
    parts.push(`[${promotion.promo_code}]`);
  }
  parts.push(promotion.product_name || t('All Products'));
  parts.push(getApiPromotionTitleText(promotion, t, locale));
  parts.push(getPromotionPeriodText(promotion, t, locale));

  return parts.join(' - ');
};

export const TemplateEdit = ({ type }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const promotions = useSelector((state: ReduxState) => state.promotions.all);
  _.orderBy(promotions, (promotion) => {
    switch (promotion.status) {
      case 'ON':
        return 1;
      case 'OFF':
        return 2;
      default:
        return 3;
    }
  });
  const promotionOptions: { text: string; value: string }[] = promotions
    .filter((promo) => promo.status === 'ON')
    .filter((promo) => promo.promo_code)
    .map((promo) => ({
      text: getPromotionName(promo, t, locale),
      value: promo.id,
    }));
  const [activeView, setActiveView] = useState<'PC' | 'SP'>('SP');

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchPromotions());
  }, [dispatch]);

  const { values } = useFormState<FormValues>();
  const form = useForm();

  const entranceStyleOptions: { text: string; value: EntranceStyle }[] = [
    {
      text: t('Slide-in'),
      value: 'SLIDE_IN',
    },
    {
      text: t('Modal', { context: 'site_control' }),
      value: 'MODAL_ZOOM',
    },
  ];

  const handleViewChange = (view: 'PC' | 'SP') => {
    setActiveView(view);
  };

  return (
    <div className={styles['p-pane']}>
      <div className={styles['p-pane__left']}>
        <section className={styles['p-section']}>
          <div className={styles['p-preview__header']}>
            <ul className={styles['p-toggle_previewView']}>
              <li
                className={activeView === 'PC' ? styles['is-active'] : ''}
                onClick={() => handleViewChange('PC')}
              >
                <div style={{ height: '20px', width: '20px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="100%"
                    height="100%"
                    fill="currentColor"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path d="M15.238 10.012V2.194c0-.791-.512-1.432-1.143-1.432H1.905c-.631 0-1.143.641-1.143 1.432v7.818c0 .69.56 1.25 1.25 1.25h11.976c.69 0 1.25-.56 1.25-1.25zm-9.752 5.226l.61-3.048H1.904A1.905 1.905 0 010 10.286V1.905C0 .853.853 0 1.905 0h12.19C15.147 0 16 .853 16 1.905v8.38a1.905 1.905 0 01-1.905 1.905h-4.023l.61 3.048h1.128a.381.381 0 010 .762H4.19a.381.381 0 010-.762h1.296zm4.419 0l-.61-3.048H6.872l-.61 3.048h3.643z"></path>
                  </svg>
                </div>
              </li>
              <li
                className={activeView === 'SP' ? styles['is-active'] : ''}
                onClick={() => handleViewChange('SP')}
              >
                <div style={{ height: '20px', width: '20px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 16"
                    width="100%"
                    height="100%"
                    fill="currentColor"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path d="M9.143 3.048H.762v9.142h8.38V3.048zm0-.762v-.381c0-.638-.466-1.143-1.026-1.143h-6.33c-.559 0-1.025.505-1.025 1.143v.38h8.38zm0 10.666H.762v1.143c0 .638.466 1.143 1.026 1.143h6.33c.559 0 1.025-.505 1.025-1.143v-1.143zm.762-11.047v12.19c0 1.046-.793 1.905-1.788 1.905h-6.33C.794 16 0 15.14 0 14.095V1.905C0 .859.793 0 1.788 0h6.33c.994 0 1.787.86 1.787 1.905z"></path>
                  </svg>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles['p-preview']}>
            {activeView === 'PC' ? (
              <div className={styles['p-preview__pc']}>
                <Widget
                  data={convertFormValuesToSwagger(values)}
                  displayMode="PREVIEW"
                />
              </div>
            ) : (
              <div className={styles['p-preview__sp']}>
                <Widget
                  data={convertFormValuesToSwagger(values)}
                  displayMode="PREVIEW"
                  forceMobileView={true}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      <div className={styles['p-pane__right']}>
        <section className={styles['p-section']}>
          <ul className={styles['p-list']}>
            {/* Common properties */}
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>{t('Entrance Style')}</p>
              </div>
              <div className={styles['p-list__item__body']}>
                <Field name="content.entranceStyle">
                  {({ input }: FieldRenderProps<string>) => (
                    <SingleDropdown
                      options={entranceStyleOptions}
                      selectedOption={input.value}
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </li>
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>{t('Title')}</p>
              </div>
              <div className={styles['p-list__item__body']}>
                <Field name={`content.${getContentTypeName(type)}.title`}>
                  {({ input }: FieldRenderProps<string>) => (
                    <TextField {...input} type="text" />
                  )}
                </Field>
              </div>
            </li>
            <li className={styles['p-list__item']}>
              <div className={styles['p-list__item__ttl']}>
                <p>{t('Description')}</p>
              </div>
              <div className={styles['p-list__item__body']}>
                <Field name={`content.${getContentTypeName(type)}.description`}>
                  {({ input }: FieldRenderProps<string>) => (
                    <TextArea {...input} height={120} />
                  )}
                </Field>
              </div>
            </li>

            {type === 'COUPON' && (
              <>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Image')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="content.coupon.imageUrl">
                      {({ input }: FieldRenderProps<string>) => (
                        <div className={styles['p-image']}>
                          <ImageVideoAudioInput
                            fileUrls={input.value ? [input.value] : []}
                            onChange={(newValue) =>
                              newValue?.length
                                ? input.onChange(newValue[0])
                                : input.onChange('')
                            }
                            maxFileCount={1}
                            disableYoutubeVideos
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Promotion')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="content.coupon.promotionId">
                      {({ input }: FieldRenderProps<string>) => (
                        <SingleDropdown
                          options={promotionOptions}
                          selectedOption={
                            values.content.coupon?.promotionId
                              ? input.value
                              : ''
                          }
                          onChange={(value) => {
                            input.onChange(value);
                            form.change(
                              'content.coupon.couponCode',
                              promotions.find((p) => p.id === value)?.promo_code
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__group__ttl']}>
                    <p>{t('Button Settings')}</p>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <ButtonStyleEditor
                      name="content.coupon.buttons[0].style"
                      expanderLabel={t('Customize Button Style')}
                      useFontPicker={false}
                    />
                  </div>
                </li>
              </>
            )}
            {type === 'CARD' && (
              <>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__group__ttl']}>
                    <p>{t('Button Settings')}</p>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <div className={styles['p-list__item__ttl']}>
                      <p>{t('Text')}</p>
                    </div>
                    <div className={styles['p-list__item__body']}>
                      <Field name="content.card.button.text">
                        {({ input }: FieldRenderProps<string>) => (
                          <TextField {...input} type="text" />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <div className={styles['p-list__item__ttl']}>
                      <p>{t('URL')}</p>
                    </div>
                    <div className={styles['p-list__item__body']}>
                      <Field name="content.card.button.url">
                        {({ input }: FieldRenderProps<string>) => (
                          <TextField {...input} type="text" />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <ButtonStyleEditor
                      name="content.card.button.style"
                      expanderLabel={t('Customize Button Style')}
                      useFontPicker={false}
                    />
                  </div>
                </li>
              </>
            )}
            {type === 'VERTICAL' && (
              <>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__group__ttl']}>
                    <p>{t('Button Settings')}</p>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <div className={styles['p-list__item__ttl']}>
                      <p>{t('Text')}</p>
                    </div>
                    <div className={styles['p-list__item__body']}>
                      <Field name="content.vertical.button.text">
                        {({ input }: FieldRenderProps<string>) => (
                          <TextField {...input} type="text" />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <div className={styles['p-list__item__ttl']}>
                      <p>{t('URL')}</p>
                    </div>
                    <div className={styles['p-list__item__body']}>
                      <Field name="content.vertical.button.url">
                        {({ input }: FieldRenderProps<string>) => (
                          <TextField {...input} type="text" />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles['p-list__item__group__body']}>
                    <ButtonStyleEditor
                      name="content.vertical.button.style"
                      expanderLabel={t('Customize Button Style')}
                      useFontPicker={false}
                    />
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Image')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="content.vertical.imageUrl">
                      {({ input }: FieldRenderProps<string>) => (
                        <div className={styles['p-image']}>
                          <ImageVideoAudioInput
                            fileUrls={input.value ? [input.value] : []}
                            onChange={(newValue) =>
                              newValue?.length
                                ? input.onChange(newValue[0])
                                : input.onChange('')
                            }
                            maxFileCount={1}
                            disableYoutubeVideos
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </li>

                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Text')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="content.vertical.text">
                      {({ input }: FieldRenderProps<string>) => (
                        <TextArea {...input} height={120} />
                      )}
                    </Field>
                  </div>
                </li>
              </>
            )}
            {type === 'MULTI_BUTTONS' && (
              <>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Image')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <Field name="content.multiButtons.imageUrl">
                      {({ input }: FieldRenderProps<string>) => (
                        <div className={styles['p-image']}>
                          <ImageVideoAudioInput
                            fileUrls={input.value ? [input.value] : []}
                            onChange={(newValue) =>
                              newValue?.length
                                ? input.onChange(newValue[0])
                                : input.onChange('')
                            }
                            maxFileCount={1}
                            disableYoutubeVideos
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </li>
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>{t('Buttons')}</p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <ButtonListEditor name="content.multiButtons.buttons" />
                  </div>
                </li>
              </>
            )}
          </ul>
        </section>
      </div>
    </div>
  );
};
