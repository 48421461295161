import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';

type Props = {
  header: string;
  content: React.ReactNode;
  onConfirm: () => any;
  loading?: boolean;
  onClose: () => void;
  insertRoot?: boolean;
};
export const DeleteConfirmModal = ({
  header,
  content,
  onConfirm,
  loading,
  onClose,
  insertRoot,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={header}
      insertAtRoot={insertRoot}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            color="white"
            onClick={onClose}
            loading={loading}
          />
          <Button
            text={t('OK')}
            color="red"
            onClick={async () => {
              await onConfirm();
              onClose();
            }}
            loading={loading}
          />
        </>
      }
    >
      <>{content}</>
    </Modal>
  );
};
