import * as React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  Radio,
  TextArea,
} from 'client/components/Form';
import { Divider } from 'client/components/Divider/Divider';
import { currency } from 'shared/libraries/currency';
import { activeUserSelector } from 'client/reducers/user';
import { createFareAdjustmentForInvoice } from 'client/actions/invoices';
import { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';
import type { FareAdjustmentForInvoicePatch } from 'shared/models/swagger';

import styles from './InvoiceFareAdjustmentModal.module.css';

const formatCurrencyAmount = (amount: number, currencyCode: string): string => {
  if (typeof amount === 'undefined' || !currencyCode) {
    return '';
  }

  const result = amount.toLocaleString(undefined, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code',
  });
  return result.replace(/[a-z]{3}/i, '').trim();
};

const negated = (amount: string) => {
  if (amount.startsWith('-')) {
    return amount.replace('-', '');
  }

  return `-${amount}`;
};

interface Props {
  header?: string;
  trigger: React.ComponentType<any>;
  invoice: {
    id: string;
    payer_id: string;
    payer_name: string;
    payer_type:
      | 'NUTMEG_ENTITY_TYPE'
      | 'AGENT_ENTITY_TYPE'
      | 'SUPPLIER_ENTITY_TYPE'
      | 'GUEST_ENTITY_TYPE';
    payee_id: string;
    payee_name: string;
    payee_type:
      | 'NUTMEG_ENTITY_TYPE'
      | 'AGENT_ENTITY_TYPE'
      | 'SUPPLIER_ENTITY_TYPE'
      | 'GUEST_ENTITY_TYPE';
    amount: number;
    currency: string;
  };
  includeDetails?: boolean;
}

export const InvoiceFareAdjustmentModal = ({
  header,
  trigger: Trigger,
  invoice,
  includeDetails,
}: Props) => {
  const activeUser = useSelector(activeUserSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencyInputHelper = new CurrencyAmountInputHelper(invoice.currency);

  const getUserName = () => {
    const name = activeUser ? activeUser.name : '';

    if (name === invoice.payee_name) {
      return invoice.payee_name;
    } else if (name === invoice.payer_name) {
      return invoice.payer_name;
    }

    return 'ADMIN';
  };

  const [showModal, setShowModal] = React.useState(false);
  const [payerToPayee, setPayerToPayee] = React.useState(
    getUserName() === invoice.payee_name ? false : true
  );
  const [amount, setAmount] = React.useState('0');
  const [notes, setNotes] = React.useState('');

  const getNewAmountDue = () => {
    if (amount) {
      return (
        invoice.amount + parseFloat(payerToPayee ? amount : negated(amount))
      );
    } else {
      return invoice.amount;
    }
  };

  return (
    <>
      <span onClick={() => setShowModal(true)}>
        <Trigger />
      </span>
      <Modal
        insertRoot
        title={header ?? ''}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Content>
          <Modal.Box column="two">
            <Radio
              label={t('Charge')}
              checked={payerToPayee}
              onChange={() => {
                setPayerToPayee((currentValue) => !currentValue);
              }}
            />
            <Radio
              label={t('Refund')}
              checked={!payerToPayee}
              onChange={() => {
                setPayerToPayee((currentValue) => !currentValue);
              }}
            />
          </Modal.Box>
          <Modal.Box>
            <Input
              label={t('Difference ({{currency}})', {
                currency: invoice.currency,
              })}
              type="number"
              value={amount}
              onChange={(e, { value }) => {
                let isNegative = false;
                if (value.startsWith('-')) {
                  isNegative = true;
                  value = value.substring(1);
                }

                if (!currencyInputHelper.inputAllowed(value)) {
                  return;
                }

                // Reject input if it has too many decimal places.
                if (
                  amount &&
                  currency(`${invoice.currency}${value}`).format().length <
                    value.length + invoice.currency.length
                ) {
                  return;
                }

                if (isNegative) {
                  setPayerToPayee(!payerToPayee);
                }

                setAmount(value);
              }}
            />
          </Modal.Box>
          <Modal.Box>
            <FieldWrapper label={`${t('Amount Due')} (${invoice.currency})`}>
              <div className={clsx(getNewAmountDue() < 0 && styles['red'])}>
                {formatCurrencyAmount(invoice.amount, invoice.currency)}
                {amount
                  ? ` => ${formatCurrencyAmount(
                      getNewAmountDue(),
                      invoice.currency
                    )}`
                  : ''}
              </div>
            </FieldWrapper>
          </Modal.Box>
          <Divider />
          <TextArea
            value={notes}
            label={t('Adjustment Notes')}
            onChange={(e, { value }) => {
              setNotes(value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button.Cancel
            onClick={() => {
              setShowModal(false);
              setAmount('0');
              setNotes('');
            }}
          >
            {t('Cancel')}
          </Button.Cancel>
          <Button.Submit
            onClick={() => {
              setShowModal(false);
              setAmount('0');
              setNotes('');
              const patch: FareAdjustmentForInvoicePatch = {
                payer_id: payerToPayee ? invoice.payer_id : invoice.payee_id,
                payee_id: payerToPayee ? invoice.payee_id : invoice.payer_id,
                currency_amount: currency(
                  `${invoice.currency}${amount}`
                ).format(),
                notes: notes,
                include_details: includeDetails,
              };
              dispatch(createFareAdjustmentForInvoice(invoice.id, patch));
            }}
            disabled={getNewAmountDue() === invoice.amount}
          >
            {t('Confirm')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    </>
  );
};
