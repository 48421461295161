import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import {
  fetchWaiversByReservationIDs,
  fetchWaiverPDF,
} from 'client/actions/waivers';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import { Loading } from 'client/pages/Loading';
import { Box } from 'client/components/Box/Box';
import { getWaiverStatusText } from 'client/libraries/util/getWaiverStatusText';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button } from 'client/components/Form';
import type { Waiver } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { CustomerReservationsContext } from './CustomerReservationsContext';

interface Props {
  active: boolean;
}

export const CustomerWaivers = ({ active }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reservations = React.useContext(CustomerReservationsContext);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const waiverPDFStatus = useSelector(
    (state: ReduxState) => state.waivers.pdfStatus
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const waiver = useSelector((state: ReduxState) => state.waivers.all);
  const loading = useSelector((state: ReduxState) => state.waivers.loading);

  React.useEffect(() => {
    if (reservations) {
      dispatch(fetchWaiversByReservationIDs(reservations.map((r) => r.id)));
    }
  }, [reservations]);

  const getColumns = (
    local: string,
    t: TranslateFuncType
  ): ColumnType<Waiver>[] => {
    return [
      {
        Header: t('Application Number'),
        id: 'agent_reference',
        accessor: (item: Waiver) => {
          return (
            <Link to={`/reservations/${item.reservation_id}`}>
              {item.reservation_agent_reference}
            </Link>
          );
        },
        width: 120,
      },
      {
        Header: t('Submission Date'),
        id: 'posted_date',
        accessor: (item: Waiver) => {
          return getDateTimeDisplay(
            moment(item.submitted_date_time_utc),
            local
          );
        },
        width: 120,
      },
      {
        Header: t('Waiver Title'),
        id: 'posted_date',
        accessor: (item: Waiver) => {
          return item.title;
        },
        width: 120,
      },
      {
        Header: t('Participation'),
        id: 'participates_at',
        accessor: (item: Waiver) => {
          return getDateTimeDisplay(moment(item.reservation_date_from), local);
        },
        width: 120,
      },
      {
        Header: t('Waiver Status'),
        id: 'review_status',
        accessor: (item: Waiver) => {
          const reservation = reservations?.find(
            (r) => r.id === item.reservation_id
          );

          if (!reservation) {
            return '';
          }

          return getWaiverStatusText(
            reservation.waiver_info,
            reservation.guests.length,
            t
          );
        },
        width: 120,
      },
      {
        Header: t('Product'),
        id: 'product_name',
        accessor: (item: Waiver) => (
          <Link to={`/products/${item.product_id}`}>{item.product_name}</Link>
        ),
        width: 120,
      },
      {
        Header: t('View/PDF'),
        id: 'view_pdf',
        accessor: (item: Waiver) => {
          const reservation = reservations?.find(
            (r) => r.id === item.reservation_id
          );

          if (!reservation) {
            return '';
          }
          return (
            <Box display="flex">
              <Box mr={2}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${bookingWebsiteUrl}/waivers/${
                    reservation.waiver_info?.waiver_id ?? ''
                  }`}
                  className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  data-text={t('View')}
                >
                  {t('View')}
                </a>
              </Box>
              <Button
                size="middle"
                style="gray"
                onClick={() => {
                  dispatch(
                    fetchWaiverPDF(reservation.waiver_info?.waiver_id ?? '')
                  );
                }}
                loading={waiverPDFStatus === 'IN_FLIGHT'}
              >
                {t('Download as PDF')}
              </Button>
            </Box>
          );
        },
      },
    ];
  };

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        active ? componentStyles['is-active'] : ''
      )}
    >
      {loading && <Loading />}
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Waivers')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GenericTable<Waiver>
          items={waiver ?? []}
          totalCount={(waiver ?? []).length}
          columns={getColumns(locale, t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </div>
    </div>
  );
};
