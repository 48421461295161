import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Cell,
} from 'recharts';

import { Loading } from 'client/components/v3/Common/Loading';

import styles from './LeadTimeGadget.module.css';
import { ChangeLabel } from './ChangeLabel';

interface Props {
  baseLeadTimeInDays: number;
  comparisonLeadTimeInDays: number | null;
  loading?: boolean;
}

export const LeadTimeGadget = ({
  baseLeadTimeInDays,
  comparisonLeadTimeInDays,
  loading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Average Lead Time')}</div>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          {loading ? (
            <Loading size="md" />
          ) : (
            <>
              <div className={styles['chart']}>
                <div className={styles['amount']}>
                  <div className={styles['amount-inner']}>
                    <div className={styles['amount-text']}>
                      {baseLeadTimeInDays.toLocaleString(undefined, {
                        maximumFractionDigits: 1,
                      })}
                    </div>
                    <div className={styles['amount-unit']}>{t('days')}</div>
                  </div>
                  {comparisonLeadTimeInDays != null && (
                    <div className={styles['amount-inner']}>
                      <div className={styles['amount-unit']}>
                        {t('{{count}} days', {
                          count: comparisonLeadTimeInDays.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 1,
                            }
                          ),
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {comparisonLeadTimeInDays != null && (
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                    debounce={300}
                  >
                    {comparisonLeadTimeInDays > baseLeadTimeInDays ? (
                      <PieChart>
                        <Pie
                          dataKey="value"
                          data={[
                            {
                              name: t('Comparison Lead Time'),
                              value: comparisonLeadTimeInDays,
                            },
                          ]}
                          cx="50%"
                          cy="50%"
                          outerRadius="90%"
                          innerRadius="70%"
                          cornerRadius={10}
                          paddingAngle={0}
                          startAngle={225}
                          endAngle={-45}
                        >
                          <Cell fill="#F4F4F5" />
                        </Pie>
                        <Pie
                          dataKey="value"
                          data={[
                            { name: t('Lead Time'), value: baseLeadTimeInDays },
                          ]}
                          cx="50%"
                          cy="50%"
                          outerRadius="90%"
                          innerRadius="70%"
                          cornerRadius={10}
                          paddingAngle={0}
                          startAngle={225}
                          endAngle={
                            225 -
                            (baseLeadTimeInDays / comparisonLeadTimeInDays) *
                              270
                          }
                        >
                          <Cell fill="#3B82F6" />
                        </Pie>
                        <RechartsTooltip />
                      </PieChart>
                    ) : (
                      <PieChart>
                        <Pie
                          dataKey="value"
                          data={[
                            { name: t('Lead Time'), value: baseLeadTimeInDays },
                          ]}
                          cx="50%"
                          cy="50%"
                          outerRadius="90%"
                          innerRadius="70%"
                          cornerRadius={10}
                          paddingAngle={0}
                          startAngle={225}
                          endAngle={-45}
                        >
                          <Cell fill="#3B82F6" />
                        </Pie>
                        <Pie
                          dataKey="value"
                          data={[
                            {
                              name: t('Comparison Lead Time'),
                              value: comparisonLeadTimeInDays,
                            },
                          ]}
                          cx="50%"
                          cy="50%"
                          outerRadius="90%"
                          innerRadius="70%"
                          cornerRadius={10}
                          paddingAngle={0}
                          startAngle={225}
                          endAngle={
                            225 -
                            (comparisonLeadTimeInDays / baseLeadTimeInDays) *
                              270
                          }
                        >
                          <Cell fill="#F4F4F5" />
                        </Pie>
                        <RechartsTooltip />
                      </PieChart>
                    )}
                  </ResponsiveContainer>
                )}
              </div>

              {comparisonLeadTimeInDays != null && (
                <div className={styles['text-section']}>
                  <ChangeLabel
                    amountChange={baseLeadTimeInDays - comparisonLeadTimeInDays}
                    unit="%"
                  />
                  <div className={styles['legend-item']}>
                    <div
                      className={clsx(styles['legend-dot'], styles['blue'])}
                    />
                    <div>{t('Lead Time')}</div>
                  </div>
                  <div className={styles['legend-item']}>
                    <div
                      className={clsx(styles['legend-dot'], styles['gray'])}
                    />
                    <div>{t('Comparison Lead Time')}</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
