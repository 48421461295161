import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_E_TICKET_DAILY_USES_SUCCESS,
  FETCH_E_TICKET_DAILY_USES_FAILURE,
  FETCH_E_TICKET_DAILY_USES_REQUEST,
  FETCH_E_TICKET_DAILY_USES_CANCELED,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';
import type {
  GetETicketDailyUsesResponse,
  ETicketDailyUse,
} from 'shared/models/swagger';

const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_E_TICKET_DAILY_USES_REQUEST:
      return true;

    case FETCH_E_TICKET_DAILY_USES_SUCCESS:
    case FETCH_E_TICKET_DAILY_USES_FAILURE:
    case FETCH_E_TICKET_DAILY_USES_CANCELED:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_E_TICKET_DAILY_USES_FAILURE:
      return action.error;

    case FETCH_E_TICKET_DAILY_USES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const all = (
  state: ETicketDailyUse[] = [],
  action: any
): Array<ETicketDailyUse> => {
  switch (action.type) {
    case FETCH_E_TICKET_DAILY_USES_SUCCESS: {
      const resp = action.response as GetETicketDailyUsesResponse;
      return resp.e_ticket_daily_uses || [];
    }
    default:
      return state;
  }
};

type State = {
  all: ETicketDailyUse[];
  loading: boolean;
  error: string;
};

const reducer = combineReducers<State>({
  error,
  loading,
  all,
});

export const eTicketDailyUses = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
