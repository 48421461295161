import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Accordion.module.css';

type AccordionProps = {
  children?: React.ReactNode;
};
export const Accordion = ({ children }: AccordionProps) => {
  return <ul className={styles['c-accordion']}>{children}</ul>;
};
export type AccordionItemChildRenderProps = {
  open: boolean;
};
type AccordionItemProps = {
  children?: (props: AccordionItemChildRenderProps) => React.ReactNode;
  header: string;
  subtitle?: string;
  open: boolean;
  onButtonClick: (arg0: void) => void;
};
export const AccordionItem = ({
  children,
  header,
  subtitle,
  open,
  onButtonClick,
}: AccordionItemProps) => {
  const { t } = useTranslation();
  return (
    <li
      className={clsx(
        styles['c-accordion__item'],
        styles['bg'],
        open && styles['is-active']
      )}
    >
      <div
        className={styles['c-accordion__item__header']}
        onClick={() => {
          onButtonClick();
        }}
      >
        <p>{header}</p>
        <div className={styles['ic']}>
          <p>{open ? t('Hide') : t('Show')}</p>
          <span></span>
        </div>
      </div>
      <div className={styles['c-accordion__item__body']}>
        {subtitle && (
          <div className={styles['acHeader']}>
            <p className={styles['acHeader__ttl']}>{subtitle}</p>
          </div>
        )}
        <div className={styles['acBody']}>
          {children?.({
            open,
          })}
        </div>
      </div>
    </li>
  );
};
