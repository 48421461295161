import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { BusinessLineItem } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import styles from './SummaryTable.module.css';
import {
  useInvoiceReservationColumns,
  InvoiceReservationColumnType,
} from './util';

type Props = {
  businessLineItemsOfPaymentType: BusinessLineItem[];
  getRenderedTransactionTable: (businessLineItem: any) => any;
  getRenderedTotalAmount: (
    totalAmount: string,
    moneyType: string,
    size: string
  ) => any;
  parseField: (field: string, start: number, end: number) => string;
};

export const LineItemTable = ({
  businessLineItemsOfPaymentType,
  getRenderedTransactionTable,
  getRenderedTotalAmount,
}: Props) => {
  const { t } = useTranslation();
  const [lineItemActiveIndex, setLineItemActiveIndex] = React.useState(-1);

  const visibleReservationColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleReservationColumns
  );
  const allColumns = useInvoiceReservationColumns();
  const columns = visibleReservationColumns
    .map((visibleCol) => allColumns.find((col) => col.id === visibleCol))
    .filter((col) => !!col) as InvoiceReservationColumnType[];

  const handleViewTransactionsClick = (index: number) => {
    const newIndex = lineItemActiveIndex === index ? -1 : index;
    setLineItemActiveIndex(newIndex);
  };

  if (
    !businessLineItemsOfPaymentType ||
    businessLineItemsOfPaymentType.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency: businessLineItemsOfPaymentType[0]?.amount_due?.slice(
              0,
              3
            ),
          })}
        </Box>
      </Box>
      <div className={componentStyles['c-table-wrap']}>
        <table>
          <tbody>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.id}
                  className={clsx(baseStyles['base-t-112'], styles['center'])}
                >
                  {column.Header}
                </th>
              ))}
              <th className={clsx(baseStyles['base-t-112'], styles['center'])}>
                {t('Transactions')}
              </th>
            </tr>
            {businessLineItemsOfPaymentType?.map((businessLineItem, idx) => {
              const renderedTransactionTable =
                getRenderedTransactionTable(businessLineItem);
              const renderedTransactionsTotalAmount = getRenderedTotalAmount(
                businessLineItem.amount_due ?? '',
                t('Transactions'),
                'medium'
              );
              return (
                <>
                  <tr>
                    {columns.map((column) =>
                      column.renderCell(businessLineItem)
                    )}
                    <td className={baseStyles['base-t-112']}>
                      <a
                        className={baseStyles['base-link']}
                        onClick={() => handleViewTransactionsClick(idx)}
                      >
                        {lineItemActiveIndex === idx
                          ? t('Close')
                          : t('View ({{count}})', {
                              count: businessLineItem.transactions?.length ?? 0,
                            })}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    {lineItemActiveIndex === idx && (
                      <td
                        style={{
                          background: '#F0F0F0',
                        }}
                        colSpan={13}
                      >
                        <div>
                          {renderedTransactionTable}
                          {renderedTransactionsTotalAmount}
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
