// @flow

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment-timezone';
import clsx from 'clsx';

import {
  batchUpdateProductPrices,
  downloadProductPricesPDF,
  downloadProductPricesCSV,
  fetchProductPrices,
} from 'client/actions/productPrices';
import { Loading } from 'client/pages/Loading';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { agentOptionsSelector } from 'client/pages/ProductPriceList/agentOptionsSelector';
import { ProductPriceTable } from 'client/pages/ProductPriceList/ProductPriceTable';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  changeCommissions,
  getDefaultCommissionPercent,
} from 'client/pages/ProductPriceList/commissionPercent';
import { CommissionPercentInput } from 'client/pages/ProductPriceList/CommissionPercentInput';
import { productPricesSummariesSortedByBookmarkedSelector } from 'client/reducers/productPrices';
import type { ProductSummary } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import {
  Select,
  MultiSelect,
  Radio,
  FieldWrapper,
  Button,
} from 'client/components/Form';
import anotherIcon from 'client/images/ic_another.svg';
import { Message } from 'client/components/Message/Message';

export const ProductPriceList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // State
  const [agentIdFilter, setAgentIdFilter] = React.useState<string>('');
  const [productIdsFilter, setProductIdsFilter] = React.useState<string[]>([]);
  const [productTagsFilter, setProductTagsFilter] = React.useState<string[]>(
    []
  );
  const [products, setProducts] = React.useState<ProductSummary[]>([]);
  const [mode, setMode] = React.useState<'EDIT' | 'READ'>('READ');
  const [pricePeriodFilterType, setPricePeriodFilterType] = React.useState<
    'CURRENT' | 'PAST'
  >('CURRENT');
  const [showAllHeader, setShowAllHeader] = React.useState<boolean>(false);
  const [showAnotherButtons, setShowAnotherButtons] =
    React.useState<boolean>(false);

  // Redux
  const agentsLoading = useSelector(
    (state: ReduxState) => state.organizations.contractedLoading
  );
  const lastUpdateBatch = useSelector(
    (state: ReduxState) => state.productPrices.lastUpdateBatch
  );
  const fetchedProducts = useSelector(
    productPricesSummariesSortedByBookmarkedSelector
  );
  const productsLoading = useSelector(
    (state: ReduxState) => state.productPrices.loading
  );
  const agentOptions = useSelector(agentOptionsSelector);
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );

  const activeUser = useSelector(activeUserSelector);

  // Effects
  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, [dispatch, invalidated]);
  React.useEffect(() => {
    dispatch(fetchProductPrices());
  }, [dispatch, invalidated]);

  const resetProducts = React.useCallback(() => {
    setProducts(
      fetchedProducts
        .filter(
          (product) =>
            !agentIdFilter ||
            ((product.whitelisted_agents || []).length > 0
              ? (product.whitelisted_agents || []).includes(agentIdFilter)
              : !(product.blacklisted_agents || []).includes(agentIdFilter))
        )
        .filter(
          (product) =>
            productTagsFilter.length === 0 ||
            product.product_tags?.some((tag) =>
              productTagsFilter.includes(tag)
            ) ||
            product.internal_product_tags?.some((tag) =>
              productTagsFilter.includes(tag)
            )
        )
        .filter(
          (product) =>
            productIdsFilter.length === 0 ||
            productIdsFilter.indexOf(product.id) !== -1
        )
    );
  }, [fetchedProducts, agentIdFilter, productTagsFilter, productIdsFilter]);

  React.useEffect(() => {
    resetProducts();
  }, [resetProducts]);

  const allAgentProducts = fetchedProducts.filter(
    (product) =>
      !agentIdFilter ||
      ((product.whitelisted_agents || []).length > 0
        ? (product.whitelisted_agents || []).includes(agentIdFilter)
        : !(product.blacklisted_agents || []).includes(agentIdFilter))
  );
  React.useEffect(() => {
    // If an update was successful, switch us back to 'READ' mode.
    if (lastUpdateBatch.length > 0) {
      setMode('READ');
    }
  }, [lastUpdateBatch]);

  const productPatches = products
    .filter((product) => {
      const fetchedProduct = fetchedProducts.find((p) => p.id === product.id);
      if (!fetchedProduct) {
        // Shouldn't happen
        return false;
      }
      return !_.isEqual(product.pricing, fetchedProduct.pricing);
    })
    .map(({ id, pricing }) => ({
      id,
      pricing,
    }));

  const productOptions = allAgentProducts.map((product) => ({
    key: product.id,
    value: product.id,
    text: getVerboseDisplayProductName(product),
  }));
  const allProductTags = allAgentProducts.reduce((acc, product) => {
    const tags = [
      ...(product.product_tags ?? []),
      ...(product.internal_product_tags ?? []),
    ];
    tags.forEach((tag) => {
      if (!acc.includes(tag)) {
        acc.push(tag);
      }
    });
    return acc;
  }, []);
  const productTagOptions = allProductTags.map((tag) => ({
    key: tag,
    value: tag,
    text: tag,
  }));

  let pricePeriodStartDate: string = '';
  let pricePeriodEndDate: string = '';
  if (pricePeriodFilterType === 'CURRENT') {
    pricePeriodStartDate = moment().format('YYYY-MM-DD');
    pricePeriodEndDate = '';
  } else if (pricePeriodFilterType === 'PAST') {
    pricePeriodStartDate = '';
    pricePeriodEndDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  }

  const defaultCommissionPercent = getDefaultCommissionPercent(
    fetchedProducts,
    pricePeriodStartDate,
    pricePeriodEndDate
  );

  if (productsLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={clsx(componentStyles['c-headline-search'])}>
        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <Select
            disabled={productPatches.length > 0}
            search
            fluid
            label={t('Select Agent')}
            options={[
              { key: 'COMMON', value: 'COMMON', text: t('Common') },
              ...agentOptions,
            ]}
            value={agentIdFilter || 'COMMON'}
            loading={agentsLoading}
            onChange={(e, { value }) => {
              if (value === 'COMMON') {
                setAgentIdFilter('');
              } else {
                setAgentIdFilter(value);
              }
            }}
          />
        </div>
        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <MultiSelect
            disabled={productPatches.length > 0}
            label={t('Select Products')}
            selectedValues={productIdsFilter}
            search
            options={productOptions}
            onChange={({ value }) => setProductIdsFilter(value)}
          />
        </div>
        <div
          className={clsx(
            componentStyles['c-headline-search__item'],
            showAllHeader ? '' : baseStyles['base-t-spHidden']
          )}
        >
          <MultiSelect
            disabled={productPatches.length > 0}
            multiple
            search
            fluid
            label={t('Select Product Tags')}
            search
            selectedValues={productTagsFilter}
            options={productTagOptions}
            onChange={({ value }) => setProductTagsFilter(value)}
          />
        </div>
        <a
          className={clsx(
            componentStyles['c-headline-search_spMore'],
            showAllHeader ? componentStyles['arrowUp'] : ''
          )}
          onClick={() => {
            setShowAllHeader(!showAllHeader);
          }}
        >
          {showAllHeader ? t('Close') : t('See more')}
        </a>
      </div>

      <FieldWrapper label={t('Price Periods')}>
        <Radio
          checked={pricePeriodFilterType === 'CURRENT'}
          label={t('Active/Future (ending {{today}} and after)', {
            today: moment().format('YYYY-MM-DD'),
          })}
          onChange={() => {
            setPricePeriodFilterType('CURRENT');
          }}
        />
        <Radio
          checked={pricePeriodFilterType === 'PAST'}
          label={t('Past (ended {{yesterday}} and before)', {
            yesterday: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          })}
          onChange={() => {
            setPricePeriodFilterType('PAST');
          }}
        />
      </FieldWrapper>

      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          {mode === 'READ' &&
            hasCustomUserRoleWritePermissions(
              activeUser,
              'PRODUCT.COMMISSIONS'
            ) && (
              <li>
                <Button
                  size="middle"
                  style="yellow"
                  width="100px"
                  onClick={() => setMode('EDIT')}
                >
                  {t('Edit')}
                </Button>
              </li>
            )}
          {mode === 'EDIT' && (
            <>
              <li>
                <Button
                  size="middle"
                  style="blue"
                  width="100px"
                  disabled={productPatches.length === 0}
                  onClick={() => {
                    dispatch(batchUpdateProductPrices(productPatches));
                  }}
                >
                  {t('Save')}
                </Button>
              </li>
              <li>
                <Button
                  size="middle"
                  style="red"
                  width="100px"
                  onClick={() => {
                    resetProducts();
                    setMode('READ');
                  }}
                >
                  {t('Discard Edits')}
                </Button>
              </li>
            </>
          )}
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              showAnotherButtons
                ? baseStyles['is-open']
                : baseStyles['is-close']
            )}
          >
            <ul>
              <li>
                <Button
                  size="middle"
                  style="gray"
                  disabled={productPatches.length > 0}
                  onClick={() =>
                    dispatch(
                      downloadProductPricesPDF({
                        agent_id: agentIdFilter,
                        product_ids: productIdsFilter,
                        product_tags: productTagsFilter,
                        price_period_start_date: pricePeriodStartDate,
                        price_period_end_date: pricePeriodEndDate,
                      })
                    )
                  }
                >
                  {t('Download as PDF')}
                </Button>
              </li>
              <li>
                <Button
                  size="middle"
                  style="gray"
                  disabled={productPatches.length > 0}
                  onClick={() =>
                    dispatch(
                      downloadProductPricesCSV({
                        agent_id: agentIdFilter,
                        product_ids: productIdsFilter,
                        product_tags: productTagsFilter,
                        price_period_start_date: pricePeriodStartDate,
                        price_period_end_date: pricePeriodEndDate,
                      })
                    )
                  }
                >
                  {t('Download as CSV')}
                </Button>
              </li>
            </ul>
            <a
              onClick={() => {
                setShowAnotherButtons(!showAnotherButtons);
              }}
            >
              <img src={anotherIcon} />
            </a>
          </div>
        </div>

        {productPatches.length > 0 && (
          <Message info>
            {t(
              '{{editedProductsCount}} products edited. Click "save" to keep changes.',
              {
                editedProductsCount: productPatches.length,
              }
            )}
          </Message>
        )}
      </div>

      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        >
          {mode === 'EDIT' ? (
            <CommissionPercentInput
              onChange={(commissionPercent) => {
                setProducts(
                  changeCommissions(
                    products,
                    pricePeriodStartDate,
                    pricePeriodEndDate,
                    agentIdFilter,
                    commissionPercent
                  )
                );
              }}
            />
          ) : (
            <>
              <label>{t('Default Commission Rate')}</label>
              {`${defaultCommissionPercent.toFixed(2)}%`}
            </>
          )}
        </div>
      </div>

      <ProductPriceTable
        allowEditing={mode === 'EDIT'}
        products={products}
        onProductsChange={setProducts}
        agentId={agentIdFilter}
        pricePeriodStartDate={pricePeriodStartDate}
        pricePeriodEndDate={pricePeriodEndDate}
      />
    </>
  );
};
