import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './ListEditor.module.css';

type ListEditorProps = {
  title?: string;
  tooltipText?: string;
  name: string;
  style?: Record<string, any>;
  fieldDisabled?: boolean;
  buttonDisabled?: boolean;
  suggestions?: string[];
  addButtonText?: string;
  validator?: FieldValidator<any> | undefined;
};

export const ListEditor = ({
  title,
  tooltipText,
  name,
  style,
  fieldDisabled,
  buttonDisabled,
  suggestions,
  addButtonText,
  validator,
}: ListEditorProps) => {
  const combinedStyle = {
    maxWidth: 800,
    ...style,
  };
  const { t } = useTranslation();

  return (
    <div className={styles['c-listEditor']}>
      {title && (
        <div className={styles['c-listEditor__ttl']}>
          <p>{title}</p>
          {tooltipText && (
            <Tooltip text={tooltipText}>
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          )}
        </div>
      )}
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={styles['c-listEditor__body']}>
            <div className={styles['c-listEditor__body__list']}>
              {fields.length !== 0 &&
                fields.map((name, idx) => (
                  <div
                    key={name}
                    className={styles['c-listEditor__body__list__item']}
                  >
                    <Field name={name} validate={validator}>
                      {({ input, meta: { error } }) => (
                        <>
                          <div style={{ width: '100%' }}>
                            {suggestions && suggestions.length > 0 ? (
                              <SingleDropdown
                                options={suggestions
                                  .filter(
                                    (suggestion) =>
                                      !(fields.value ?? []).includes(
                                        suggestion
                                      ) || input.value === suggestion
                                  )
                                  .map((suggestion) => ({
                                    value: suggestion,
                                    text: suggestion,
                                  }))}
                                searchable={true}
                                selectedOption={input.value}
                                onChange={(value) => input.onChange(value)}
                              />
                            ) : (
                              <TextField
                                style={combinedStyle}
                                disabled={fieldDisabled}
                                error={error}
                                helperText={error}
                                {...input}
                              />
                            )}
                          </div>
                          <div>
                            {!buttonDisabled && (
                              <Button
                                size="icon"
                                color="tertiarygray"
                                onClick={() => fields.remove(idx)}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                              />
                            )}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                ))}
            </div>
            {!buttonDisabled && (
              <div
                className={clsx(
                  fields.length && fields.length > 0 && baseStyles['u-mt-2']
                )}
              >
                <a
                  className={styles['c-listEditor__body__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(fields.length + 1, '');
                    } else {
                      (fields as any).insertAt(0, '');
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {addButtonText ?? t('Add')}
                </a>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};
