import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';

import styles from './PopularCategories.module.css';

const PopularCategories: React.FC = () => {
  const { t } = useTranslation();

  const reportData = useSelector(
    (state: ReduxState) => state.restaurantOrderReportData.reportData
  );

  const totalOrders = reportData?.category_totals?.reduce(
    (acc, curr) => acc + (curr.order_count || 0),
    0
  );

  const categories =
    reportData?.category_totals?.map((category) => ({
      name: category.category,
      percentage: totalOrders
        ? ((category.order_count || 0) / totalOrders) * 100
        : 0,
    })) || [];

  return (
    <section className={styles.popularCategories}>
      <h2 className={styles.sectionTitle}>
        {t('All-Time Most Popular Menu Categories')}
      </h2>
      <p className={styles.sectionDescription}>
        {t('Keep track of your most popular categories.')}
      </p>
      <ul className={styles.categoryList}>
        {categories.map((category, index) => (
          <li key={index} className={styles.categoryItem}>
            <span className={styles.categoryName}>{category.name}</span>
            <div className={styles.categoryBar}>
              <div
                className={styles.categoryProgress}
                style={{ width: `${category.percentage}%` }}
                aria-valuenow={category.percentage}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PopularCategories;
