import currency_js from 'currency.js';

import { matchesFormat } from 'shared/libraries/validate/validator';

import currencies from '../validate/currencies.json';

/* eslint-disable no-use-before-define */

/* https://github.com/babel/babel-eslint/issues/485 */
type CurrencyParam = number | string | Currency;

/* eslint-enable no-use-before-define */
type CurrencyOpts = {
  symbol?: string;
  separator?: string;
  decimal?: string;
  formatWithSymbol?: boolean;
  errorOnInvalid?: boolean;
  precision?: number;
  increment?: number;
  useVedic?: boolean;
  pattern?: string;
  negativePattern?: string;
};
export interface Currency {
  (value: CurrencyParam, opts?: CurrencyOpts): Currency;
  add(number: CurrencyParam): Currency;
  subtract(number: CurrencyParam): Currency;
  multiply(number: CurrencyParam): Currency;
  divide(number: CurrencyParam): Currency;
  distribute(count: number): Array<Currency>;
  dollars(): number;
  cents(): number;
  format(useSymbol?: boolean): string;
  toString(): string;
  toJSON(): number;
  readonly intValue: number;
  readonly value: number;
}
// TODO(andrew): fix output of negative currency values
export const currency = (
  value: CurrencyParam,
  currencyCode = 'USD'
): Currency => {
  return createCurrency(value, currencyCode, '');
};
export const separatedCurrency = (
  value: CurrencyParam,
  currencyCode = 'USD'
): Currency => {
  return createCurrency(value, currencyCode, ',');
};

const createCurrency = (
  value: CurrencyParam,
  currencyCode = 'USD',
  separator: string
): Currency => {
  const symbol =
    typeof value === 'string' && matchesFormat(value, value as any)
      ? value.substring(0, 3)
      : currencyCode;
  const curr = currencies[symbol as keyof typeof currencies];
  return currency_js(value, {
    symbol,
    formatWithSymbol: true,
    separator,
    precision: curr?.decimal_digits ?? 0,
  }) as Currency;
};
