import styles from './AddButton.module.css';

interface Props {
  selected: boolean;
  onClick: () => void;
}

export const AddButton = ({ selected, onClick }: Props) => {
  return (
    <div className={styles['p-dashboardDataList__item__header__actions']}>
      {selected ? (
        <a onClick={onClick} className={styles['on']}>
          <i className="c-icon-solid-general-check-circle"></i>
        </a>
      ) : (
        <a onClick={onClick} className={styles['off']}>
          <i className="c-icon-outline-general-plus-circle"></i>
        </a>
      )}
    </div>
  );
};
