import FileSaver from 'file-saver';
import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_INVOICE_BY_ID_REQUEST,
  FETCH_INVOICE_BY_ID_FAILURE,
  FETCH_INVOICE_BY_ID_SUCCESS,
  FETCH_INVOICES_FAILURE,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  ISSUE_INVOICE_FAILURE,
  ISSUE_INVOICE_REQUEST,
  ISSUE_INVOICE_SUCCESS,
  CONFIRM_INVOICE_FAILURE,
  CONFIRM_INVOICE_REQUEST,
  CONFIRM_INVOICE_SUCCESS,
  MARK_INVOICE_AS_PAID_FAILURE,
  MARK_INVOICE_AS_PAID_REQUEST,
  MARK_INVOICE_AS_PAID_SUCCESS,
  FARE_ADJUST_INVOICE_FAILURE,
  FARE_ADJUST_INVOICE_REQUEST,
  FARE_ADJUST_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_PDF_FAILURE,
  DOWNLOAD_INVOICE_PDF_REQUEST,
  DOWNLOAD_INVOICE_PDF_SUCCESS,
  DOWNLOAD_INVOICE_CSV_FAILURE,
  DOWNLOAD_INVOICE_CSV_REQUEST,
  DOWNLOAD_INVOICE_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  getHTTPRequestHeadersWithoutImpersonation,
  getHTTPRequestHeaders,
} from 'client/actions/index';
import type {
  FareAdjustmentForInvoicePatch,
  InvoiceReservationColumn,
  InvoiceSummaryColumn,
  InvoiceSummaryRow,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchInvoicesRequest = () => ({
  type: FETCH_INVOICES_REQUEST,
});

const fetchInvoicesSuccess = (response: any) => ({
  type: FETCH_INVOICES_SUCCESS,
  response,
});

const fetchInvoicesFailure = (error: string) => ({
  type: FETCH_INVOICES_FAILURE,
  error,
});

const fetchInvoiceByIDRequest = (id: string) => ({
  type: FETCH_INVOICE_BY_ID_REQUEST,
  id,
});

const fetchInvoiceByIDSuccess = (response: any) => ({
  type: FETCH_INVOICE_BY_ID_SUCCESS,
  response,
});

const fetchInvoiceByIDFailure = (error: string) => ({
  type: FETCH_INVOICE_BY_ID_FAILURE,
  error,
});

export const fetchInvoiceByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInvoiceByIDRequest(id));
    fetch(`/api/invoices/${id}`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchInvoiceByIDSuccess(result)))
      .catch((error) => dispatch(fetchInvoiceByIDFailure(error)));
  };
let fetchInvoicesCancel: (() => void) | null = null;
export const fetchInvoices =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchInvoicesCancel?.();
    dispatch(fetchInvoicesRequest());
    axios
      .get('/api/invoices', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchInvoicesCancel = c;
        }),
      })
      .then((result) => dispatch(fetchInvoicesSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchInvoicesFailure('canceled'));
        else dispatch(fetchInvoicesFailure(error));
      });
  };

const issueInvoiceRequest = () => ({
  type: ISSUE_INVOICE_REQUEST,
});

const issueInvoiceSuccess = (response: any) => ({
  type: ISSUE_INVOICE_SUCCESS,
  response,
});

const issueInvoiceFailure = (error: string) => ({
  type: ISSUE_INVOICE_FAILURE,
  error,
});

export const issueInvoice =
  (
    invoiceId: string,
    payeeReference: string,
    includeDetails: boolean,
    paymentMethodDescription: string,
    paymentMethod: string,
    remarks: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(issueInvoiceRequest());
    axios
      .post(
        `/api/invoices/${invoiceId}/issue`,
        {
          include_details: includeDetails,
          payee_reference: payeeReference,
          payment_method_description: paymentMethodDescription,
          payment_method: paymentMethod,
          remarks: remarks,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => dispatch(issueInvoiceSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(issueInvoiceFailure('canceled'));
        else dispatch(issueInvoiceFailure(error));
      });
  };

const confirmInvoiceRequest = () => ({
  type: CONFIRM_INVOICE_REQUEST,
});

const confirmInvoiceSuccess = (response: any) => ({
  type: CONFIRM_INVOICE_SUCCESS,
  response,
});

const confirmInvoiceFailure = (error: string) => ({
  type: CONFIRM_INVOICE_FAILURE,
  error,
});

export const confirmInvoice =
  (invoiceId: string, includeDetails: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(confirmInvoiceRequest());
    axios
      .post(
        `/api/invoices/${invoiceId}/confirm`,
        {
          include_details: includeDetails,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => dispatch(confirmInvoiceSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(confirmInvoiceFailure('canceled'));
        else dispatch(confirmInvoiceFailure(error));
      });
  };

const markInvoiceAsPaidRequest = () => ({
  type: MARK_INVOICE_AS_PAID_REQUEST,
});

const markInvoiceAsPaidSuccess = (response: any) => ({
  type: MARK_INVOICE_AS_PAID_SUCCESS,
  response,
});

const markInvoiceAsPaidFailure = (error: string) => ({
  type: MARK_INVOICE_AS_PAID_FAILURE,
  error,
});

export const markInvoiceAsPaid =
  (invoiceId: string, includeDetails: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(markInvoiceAsPaidRequest());
    axios
      .post(
        `/api/invoices/${invoiceId}/mark_as_paid`,
        {
          include_details: includeDetails,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => dispatch(markInvoiceAsPaidSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(markInvoiceAsPaidFailure('canceled'));
        else dispatch(markInvoiceAsPaidFailure(error));
      });
  };

const downloadInvoicePdfRequest = () => ({
  type: DOWNLOAD_INVOICE_PDF_REQUEST,
});

const downloadInvoicePdfSuccess = () => ({
  type: DOWNLOAD_INVOICE_PDF_SUCCESS,
});

const downloadInvoicePdfFailure = (error: string) => ({
  type: DOWNLOAD_INVOICE_PDF_FAILURE,
  error,
});
export const fetchInvoicePdf =
  (
    id: string,
    summaryColumns?: InvoiceSummaryColumn[],
    summaryRows?: InvoiceSummaryRow[],
    reservationColumns?: InvoiceReservationColumn[]
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadInvoicePdfRequest());

    const params: Record<string, any> = {};
    if (summaryColumns) {
      params.summary_columns = summaryColumns;
    }
    if (summaryRows) {
      params.summary_rows = summaryRows;
    }
    if (reservationColumns) {
      params.reservation_columns = reservationColumns;
    }
    fetch(`/api/invoices/${id}/pdf?${new URLSearchParams(params)}`, {
      headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }

        throw new Error(res.statusText);
      })
      .then((blob) => {
        dispatch(downloadInvoicePdfSuccess());
        FileSaver.saveAs(blob, id);
      })
      .catch((error) => {
        dispatch(downloadInvoicePdfFailure(error));
      });
  };
const downloadInvoiceCsvRequest = () => ({
  type: DOWNLOAD_INVOICE_CSV_REQUEST,
});

const downloadInvoiceCsvSuccess = () => ({
  type: DOWNLOAD_INVOICE_CSV_SUCCESS,
});

const downloadInvoiceCsvFailure = (error: string) => ({
  type: DOWNLOAD_INVOICE_CSV_FAILURE,
  error,
});
export const fetchInvoiceCsv =
  (id: string, reservationColumns?: InvoiceReservationColumn[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadInvoiceCsvRequest());

    const params: Record<string, any> = {};
    if (reservationColumns) {
      params.columns = reservationColumns;
    }

    fetch(`/api/invoices/${id}/csv?${new URLSearchParams(params)}`, {
      headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }

        throw new Error(res.statusText);
      })
      .then((blob) => {
        FileSaver.saveAs(blob, id);
        dispatch(downloadInvoiceCsvSuccess());
      })
      .catch((error) => {
        dispatch(downloadInvoiceCsvFailure(error));
        console.log(error);
      });
  };

const fareAdjustInvoiceRequest = () => ({
  type: FARE_ADJUST_INVOICE_REQUEST,
});

const fareAdjustInvoiceSuccess = (response: any) => ({
  type: FARE_ADJUST_INVOICE_SUCCESS,
  response,
});

const fareAdjustInvoiceFailure = (error: string) => ({
  type: FARE_ADJUST_INVOICE_FAILURE,
  error,
});

export const createFareAdjustmentForInvoice =
  (id: string, fare_adjust_params: FareAdjustmentForInvoicePatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fareAdjustInvoiceRequest());
    fetch(`/api/invoices/${id}/fare_adjust`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(fare_adjust_params),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fareAdjustInvoiceSuccess(result)))
      .catch((error) => dispatch(fareAdjustInvoiceFailure(error)));
  };
