import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { Select, FocusableInput } from 'client/components/Form';
import { ProductIdStartTimeListInput } from 'client/components/ProductIdStartTimeListInput/ProductIdStartTimeListInput';
import { Box } from 'client/components/Box/Box';

interface Props {
  name: string;
}

export const VehicleAvailabilityDepartureTimeForm = ({ name }: Props) => {
  const { t } = useTranslation();
  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });
  return (
    <>
      <Box display="flex">
        <Box mr={4}>
          <div>{t('Start Time')}</div>
          <Field name={`${name}.timeLocal`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        </Box>
        <Box>
          <div>{t('Capacity')}</div>
          <Field name={`${name}.capacity`}>
            {({ input }) => (
              <Select
                options={[...nums]}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        </Box>
      </Box>

      <Box mt={4}>
        <div>{t('Products')}</div>
        <Field name={`${name}.productStartTimes`}>
          {({ input, meta: { error, touched } }) => (
            <>
              <ProductIdStartTimeListInput
                productStartTimes={input.value}
                onChange={(newProductStartTimes) =>
                  input.onChange(newProductStartTimes)
                }
                error={touched ? error : undefined}
                filter={(product) =>
                  !product.booking_widget_settings?.is_free_start_date_time
                }
              />
              <FocusableInput name="productIds" />
            </>
          )}
        </Field>
      </Box>
    </>
  );
};
