import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchSurveySubmissions } from 'client/actions/surveySubmissions';
import { SubmittedSurveyAnswers } from 'client/pages/Survey/SubmittedSurveyList/SubmittedSurveyAnswers';
import { ReduxState } from 'client/reducers';
import { sendSurveyRequestEmail } from 'client/actions/reservations';
import type { Reservation, ReviewRating } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { SendSurveyRequestEmailModal } from './SendSurveyRequestEmailModal';

export function convertReviewRatingToNumber(
  reviewRating: ReviewRating
): number {
  return parseInt(reviewRating.replace('REVIEW_RATING_', ''));
}

type Props = {
  reservation?: Reservation;
};
export const ReservationSurveysTab = ({ reservation }: Props) => {
  const { t } = useTranslation();

  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();

  const [emailSendingStatus, setEmailSendingStatus] = React.useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const surveySubmissions = useSelector(
    (state: ReduxState) => state.surveySubmissions.summaries
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  React.useEffect(() => {
    if (reservation?.id) {
      dispatch(
        fetchSurveySubmissions({
          reservation_id: reservation.id,
          include_survey_template: true,
        })
      );
    }
  }, []);

  const surveySubmission =
    surveySubmissions.length > 0 ? surveySubmissions[0] : null;

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Survey')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {!surveySubmission && (
          <>
            {!isSubscriptionCancelled(activeOrganization, 'feature-survey') ? (
              <SendSurveyRequestEmailModal
                initialToAddress={email}
                trigger={
                  <Button
                    style="green"
                    size="middle"
                    disabled={emailSendingStatus === 'SUCCEEDED'}
                    loading={emailSendingStatus === 'REQUESTED'}
                  >
                    {t('Send Survey Request Email')}
                  </Button>
                }
                onSubmit={(email?: string) => {
                  try {
                    setEmailSendingStatus('REQUESTED');
                    dispatch(
                      sendSurveyRequestEmail(reservation?.id ?? '', email)
                    );
                    setEmailSendingStatus('SUCCEEDED');
                  } catch (e) {
                    setEmailSendingStatus('FAILED');
                  }
                }}
              />
            ) : (
              <>
                {t(
                  'Your subscription to the surveys feature has been deactivated so sending survey request emails is disabled. Resubscribe here: '
                )}{' '}
                <a
                  className={baseStyles['base-link']}
                  href={`${window.origin}/systemfee/settings`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Settings')}
                </a>
              </>
            )}
          </>
        )}

        {surveySubmission && (
          <table className={clsx(baseStyles['base-table'])}>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Submitted')}
                </th>
                <td>
                  {moment(surveySubmission.submitted_date_time_utc)
                    .locale(locale)
                    .format('lll')}
                </td>
              </tr>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Responses')}
                </th>
                <td>
                  <SubmittedSurveyAnswers surveySubmission={surveySubmission} />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
