import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_WAIT_TIMES_SUCCESS,
  FETCH_WAIT_TIMES_FAILURE,
  FETCH_WAIT_TIMES_REQUEST,
  FETCH_WAIT_TIME_SUCCESS,
  FETCH_WAIT_TIME_FAILURE,
  FETCH_WAIT_TIME_REQUEST,
  DELETE_WAIT_TIME_FAILURE,
  DELETE_WAIT_TIME_REQUEST,
  DELETE_WAIT_TIME_SUCCESS,
  CREATE_WAIT_TIME_REQUEST,
  UPDATE_WAIT_TIME_FAILURE,
  UPDATE_WAIT_TIME_SUCCESS,
  UPDATE_WAIT_TIME_REQUEST,
  CREATE_WAIT_TIME_FAILURE,
  CREATE_WAIT_TIME_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { WaitTimeParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchWaitTimesRequest = () => ({
  type: FETCH_WAIT_TIMES_REQUEST,
});

const fetchWaitTimesSuccess = (response: any) => ({
  type: FETCH_WAIT_TIMES_SUCCESS,
  response,
});

const fetchWaitTimesFailure = (error: any) => ({
  type: FETCH_WAIT_TIMES_FAILURE,
  error,
});

let fetchWaitTimesCancel: () => void | undefined;
export const fetchWaitTimes =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchWaitTimesCancel?.();
    dispatch(fetchWaitTimesRequest());
    axios
      .get('/api/waittimes', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchWaitTimesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchWaitTimesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchWaitTimesFailure('canceled'));
        dispatch(fetchWaitTimesFailure(error));
      });
  };

const fetchWaitTimeRequest = () => ({
  type: FETCH_WAIT_TIME_REQUEST,
});

const fetchWaitTimeSuccess = (response: any) => ({
  type: FETCH_WAIT_TIME_SUCCESS,
  response,
});

const fetchWaitTimeFailure = (error: any) => ({
  type: FETCH_WAIT_TIME_FAILURE,
  error,
});

export const fetchWaitTime =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchWaitTimeRequest());
    axios
      .get(`/api/waittimes/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchWaitTimeSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchWaitTimeFailure('canceled'));
        else dispatch(fetchWaitTimeFailure(error));
      });
  };

const deleteWaitTimeRequest = (id: string) => ({
  type: DELETE_WAIT_TIME_REQUEST,
  id,
});

const deleteWaitTimeSuccess = (response: any, id: string) => ({
  type: DELETE_WAIT_TIME_SUCCESS,
  response,
  id,
});

const deleteWaitTimeFailure = (error: any) => ({
  type: DELETE_WAIT_TIME_FAILURE,
  error,
});

export const deleteWaitTime =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteWaitTimeRequest(id));
    axios
      .delete(`/api/waittimes/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteWaitTimeSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteWaitTimeFailure(error)));
  };

const createWaitTimeRequest = (newWaitTime: WaitTimeParams) => ({
  type: CREATE_WAIT_TIME_REQUEST,
  newWaitTime,
});

const createWaitTimeSuccess = (response: any) => ({
  type: CREATE_WAIT_TIME_SUCCESS,
  response,
});

const createWaitTimeFailure = (error: any) => ({
  type: CREATE_WAIT_TIME_FAILURE,
  error,
});

export const createWaitTime =
  (newWaitTime: WaitTimeParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createWaitTimeRequest(newWaitTime));
    return axios
      .post('/api/waittimes', newWaitTime, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createWaitTimeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createWaitTimeFailure(err.message));
      });
  };

const updateWaitTimeRequest = (id: string, patch: WaitTimeParams) => ({
  type: UPDATE_WAIT_TIME_REQUEST,
  id,
  patch,
});

const updateWaitTimeSuccess = (response: any) => ({
  type: UPDATE_WAIT_TIME_SUCCESS,
  response,
});

const updateWaitTimeFailure = (error: any) => ({
  type: UPDATE_WAIT_TIME_FAILURE,
  error,
});

export const updateWaitTime =
  (id: string, patch: WaitTimeParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateWaitTimeRequest(id, patch));
    return axios
      .patch(`/api/waittimes/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateWaitTimeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateWaitTimeFailure(err.message));
      });
  };
