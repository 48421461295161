// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import {
  manifestCustomViewsSelector,
  manifestProductGroupsSelector,
} from 'client/reducers/manifestSettings';
import { DeleteCustomViewModal } from 'client/pages/ManifestCustomize/ManifestViewList/DeleteCustomViewModal';
import { EditCustomViewModal } from 'client/pages/ManifestCustomize/ManifestViewList/EditCustomViewModal';
import {
  getColumnHeaderText,
  getDefaultManifestViews,
} from 'client/reducers/manifestDefaults';
import type { ReduxState } from 'client/reducers/index';
import 'react-table/react-table.css';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import stopIcon from 'client/images/ic_stop.svg';

type OwnProps = {
  isActive?: boolean,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
};
/* eslint-enable no-use-before-define */

const isCustomView = (viewName: string) =>
  viewName !== 'DRIVER' && viewName !== 'GUIDE' && viewName !== 'RECEPTIONIST';

export const ManifestViewListComponent = ({
  customViews,
  productGroups,
  isActive,
}: Props) => {
  const { t } = useTranslation();

  const manifestViewTypes = [...customViews, ...getDefaultManifestViews()];

  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const [existingView, setExistingView] = React.useState<Object | null>(null);
  const [customViewToDelete, setCustomViewToDelete] =
    React.useState<Object | null>(null);

  const columns = [
    {
      Header: '',
      accessor: 'key',
      Cell: (cellInfo) =>
        isCustomView(cellInfo.value) ? (
          <>
            <a
              className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
              data-text={t('Edit')}
              onClick={() => {
                setExistingView(cellInfo.original);
                setShowEditModal(true);
              }}
            >
              <img src={editIcon} />
            </a>
          </>
        ) : (
          <>
            <img src={stopIcon} />
          </>
        ),
      sub: true,
      width: 'minimal',
    },
    {
      Header: '',
      accessor: 'key',
      Cell: (cellInfo) =>
        isCustomView(cellInfo.value) ? (
          <>
            {
              // Show delete button if no product group refers to this view type key.
              !productGroups.some(
                (group) => group.view_type_key === cellInfo.value
              ) ? (
                <a
                  className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  data-text={t('Delete')}
                  onClick={() => {
                    setCustomViewToDelete(cellInfo.original);
                    setShowDeleteModal(true);
                  }}
                >
                  <img src={deleteIcon} />
                </a>
              ) : (
                <>
                  <img src={stopIcon} />
                </>
              )
            }
          </>
        ) : (
          <>
            <img src={stopIcon} />
          </>
        ),
      sub: true,
      width: 'minimal',
    },
    {
      Header: t('Name'),
      accessor: 'key',
      Cell: (cellInfo) => t(cellInfo.value),
      width: 'long',
    },
    {
      Header: t('Sort'),
      accessor: (row) =>
        (row?.order_by || [])
          .map((col) => getColumnHeaderText(col, t))
          .join(','),
    },
    {
      Header: t('Columns'),
      accessor: (row) =>
        row.columns.map((col) => getColumnHeaderText(col, t)).join(','),
    },
  ];

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Edit Manifest Views')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['small'],
                baseStyles['flex'],
                baseStyles['green']
              )}
              onClick={() => {
                setExistingView(null);
                setShowEditModal(true);
              }}
            >
              {t('Create New View')}
            </a>
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <EditCustomViewModal
            open={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            existingView={existingView}
          />

          <DeleteCustomViewModal
            open={showDeleteModal}
            onClose={() => {
              setShowDeleteModal(false);
            }}
            customViewToDelete={customViewToDelete}
          />

          <ManifestCustomizeCustomTable
            items={_.sortBy(manifestViewTypes, (p) => p.key)}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  customViews: manifestCustomViewsSelector(state),
  productGroups: manifestProductGroupsSelector(state),
});

export const ManifestViewList = connect<*, *, *, *, *, *>(mapStateToProps)(
  ManifestViewListComponent
);
