import {
  InquiryCategory,
  InquirySettings,
  Organization,
  SourceLanguage,
} from 'shared/models/swagger';

export interface FormValues {
  categoriesLanguage: SourceLanguage;
  categories: FormInquiryCategory[];
  messageTemplates: FormInquiryMessageTemplate[];
}

export interface FormInquiryCategory {
  title: string;
  questions: string[];
  reservationInquiriesOnly: boolean;
}

export interface FormInquiryMessageTemplate {
  name: string;
  emailSubject: string;
  message: string;
}

export const getFormCategoriesForLanguage = (
  categories: InquiryCategory[],
  language: SourceLanguage
): FormInquiryCategory[] => {
  return categories
    .filter((category) => category.language === language)
    .map((category) => ({
      title: category.title ?? '',
      questions:
        category.questions?.map((question) => question.text ?? '') ?? [],
      reservationInquiriesOnly: category.reservation_inquiries_only ?? false,
    }));
};

export const convertToSwaggerInquirySettings = (
  values: FormValues,
  org: Organization | null
): InquirySettings => {
  const existingCategories = org?.inquiry_settings?.inquiry_categories ?? [];

  return {
    ...org?.inquiry_settings,
    inquiry_categories: [
      ...existingCategories.filter(
        (category) => category.language !== values.categoriesLanguage
      ),
      ...values.categories.map((category) => ({
        title: category.title,
        questions: category.questions?.map((question) => ({
          text: question,
        })),
        reservation_inquiries_only: category.reservationInquiriesOnly,
        language: values.categoriesLanguage,
      })),
    ],
    inquiry_message_templates: [
      ...values.messageTemplates.map((template) => ({
        name: template.name,
        subject: template.emailSubject,
        message: template.message,
      })),
    ],
  };
};

export const getInitialValues = (
  org: Organization | null,
  language: SourceLanguage
): FormValues => {
  return {
    categoriesLanguage: language,
    categories: getFormCategoriesForLanguage(
      org?.inquiry_settings?.inquiry_categories ?? [],
      language
    ),
    messageTemplates:
      org?.inquiry_settings?.inquiry_message_templates?.map((template) => ({
        name: template.name ?? '',
        emailSubject: template.subject ?? '',
        message: template.message ?? '',
      })) ?? [],
  };
};
