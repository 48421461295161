import * as React from 'react';
import { Field } from 'react-final-form';

import { BaseTemplate } from './BaseTemplate';
import { withQueryParams, truncated, ProductList } from './util';

const ImageEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ImageEditButton')
);
const ProductListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ProductListEditButton')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);
const PromoEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/PromoEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  eyeCatchText: string;
  keyVisualUrl: string;
  promoLeadText: string;
  promoDiscountText: string;
  promoCodePreText: string;
  promoCode: string;
  promoDescription: string;
  promoPeriodText: string;
  periodText: string;
  noteText: string;
  productList: ProductList;
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const PromoCode = ({
  editMode,
  customerName,
  newsletterCampaignId,
  topPageUrl,
  unsubscribeUrl,
  language,
  logoUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerIntroduction,
  introParagraph,
  eyeCatchText,
  keyVisualUrl,
  promoLeadText,
  promoDiscountText,
  promoCodePreText,
  promoCode,
  promoDescription,
  promoPeriodText,
  periodText,
  noteText,
  productList,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1em .5em',
              backgroundColor: '#fff',
            }}
          >
            <p style={{ marginTop: '.5em' }}>
              {customerName
                ? customerIntroduction.replace('{customerName}', customerName)
                : customerIntroduction}
              <br />
              <br />
              {editMode && (
                <Field name="templateValues.introParagraph">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <span style={{ whiteSpace: 'pre-line' }}>{introParagraph}</span>
            </p>
          </div>
        </td>
      </tr>
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '0em .5em 1em .5em',
              backgroundColor: '#ffffff',
            }}
          >
            <div style={{ background: '#FF6464' }}>
              {editMode && (
                <Field name="templateValues.eyeCatchText">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <p
                style={{
                  width: '300px',
                  padding: '0.25em',
                  margin: '0 auto',
                  background: '#ffffff',
                  color: '#FF6464',
                  textAlign: 'center',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  whiteSpace: 'pre-line',
                }}
              >
                {eyeCatchText}
              </p>
            </div>
            <div
              style={{
                width: '100%',
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  width: '100%',
                  marginTop: '1em',
                  background: '#ddd',
                  overflow: 'hidden',
                }}
              >
                {editMode && (
                  <Field name="templateValues.keyVisualUrl">
                    {({ input }) => (
                      <ImageEditButton
                        initialValue={input.value}
                        onSave={(text) => input.onChange(text)}
                      />
                    )}
                  </Field>
                )}
                <img
                  src={keyVisualUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '4px 4px 0 0',
                  }}
                />
              </div>
              <div
                style={{
                  padding: '1em 0.5em',
                  background: '#FFF8ED',
                  textAlign: 'center',
                }}
              >
                {editMode && (
                  <div style={{ textAlign: 'left' }}>
                    <Field name="templateValues">
                      {({ input }) => (
                        <PromoEditButton
                          initialValue={{
                            promoDiscountText: input.value.promoDiscountText,
                            promoCode: input.value.promoCode,
                            promoLeadText: input.value.promoLeadText,
                            promoDescription: input.value.promoDescription,
                            promoPeriodText: input.value.promoPeriodText,
                            periodText: input.value.periodText,
                          }}
                          onSave={({
                            promoDiscountText,
                            promoCode,
                            promoLeadText,
                            promoDescription,
                            promoPeriodText,
                            periodText,
                          }: {
                            promoDiscountText: string;
                            promoCode: string;
                            promoLeadText: string;
                            promoDescription: string;
                            promoPeriodText: string;
                            periodText: string;
                          }) => {
                            input.onChange({
                              ...input.value,
                              promoDiscountText,
                              promoCode,
                              promoLeadText,
                              promoDescription,
                              promoPeriodText,
                              periodText,
                            });
                          }}
                          isPromoCode
                        />
                      )}
                    </Field>
                  </div>
                )}
                <p
                  style={{
                    margin: 0,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {promoLeadText}
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      lineHeight: '32px',
                      color: '#FFFFFF',
                      background: '#FF6464',
                      margin: 0,
                      padding: '0 .25em',
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {promoDiscountText}
                  </span>
                </p>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    maxWidth: '320px',
                    margin: '1em auto 0 auto',
                    padding: '0.5em',
                    border: '2px solid #FFCC00',
                    background: '#ffffff',
                    borderRadius: '4px',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {promoCodePreText}
                  <span style={{ color: '#FF6464' }}>{promoCode}</span>
                </p>
              </div>
            </div>
            <p
              style={{
                margin: '1em 0 0 0',
                padding: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {promoDescription}
            </p>
            <div
              style={{
                marginTop: '2em',
                padding: '.5em',
                background: '#FFEBEB',
                border: '2px solid #FF6464',
                borderRadius: '8px',
              }}
            >
              <img
                src="https://assets.ntmg.com/newsletter/promo.png"
                style={{
                  width: '64px',
                  height: '64px',
                  margin: '0 auto',
                  display: 'block',
                  position: 'relative',
                  top: '-32px',
                }}
              />
              <div
                style={{
                  marginTop: '-1.5em',
                  textAlign: 'center',
                }}
              >
                <p
                  style={{
                    margin: '0 0 .5em 0',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {promoPeriodText}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#FF6464',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {periodText}
                </p>
              </div>
            </div>
            {editMode && (
              <Field name="templateValues.noteText">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p style={{ margin: '1em 0 0 0', padding: 0 }}>{noteText}</p>
            {editMode && (
              <Field name="templateValues.productList">
                {({ input }) => (
                  <ProductListEditButton
                    initialValue={productList}
                    onSave={(newValue) => input.onChange(newValue)}
                    language={language}
                  />
                )}
              </Field>
            )}
            <div style={{ marginTop: '1em', padding: 0 }}>
              <p
                style={{
                  margin: 0,
                  padding: '.5em',
                  fontWeight: 'bold',
                  color: '#ffffff',
                  backgroundColor: '#FFCC00',
                  borderRadius: '4px',
                  whiteSpace: 'pre-line',
                }}
              >
                {productList.title}
              </p>
              <table
                cellSpacing={0}
                cellPadding={0}
                width="100%"
                style={{ margin: 0, padding: '1em 0' }}
              >
                <tbody>
                  {productList.products.map((product, idx) => (
                    <tr key={idx}>
                      <td
                        style={{
                          verticalAlign: 'top',
                          margin: 0,
                          padding: '0 .5em 2em .5em',
                          width: '50%',
                        }}
                      >
                        <a
                          href={withQueryParams(product.productUrl, {
                            utm_source: 'ntmg.newsletter',
                            utm_medium: 'email',
                            utm_campaign: newsletterCampaignId ?? 'todo',
                            utm_content: 'productList',
                            lng: language,
                          })}
                          style={{
                            textDecoration: 'inherit',
                            color: 'inherit',
                            display: 'block',
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div
                            style={{
                              margin: '2em 0.5em 1em',
                              position: 'relative',
                              verticalAlign: 'middle',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={product.productThumbnailUrl}
                              style={{
                                width: '100%',
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: '.5em',
                              color: '#333333',
                              verticalAlign: 'middle',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '16px',
                                fontWeight: 'bold',
                                lineHeight: '1.5em',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {product.productName}
                            </p>
                            <p
                              style={{
                                margin: '.5em 0 0 0',
                                padding: 0,
                                fontSize: '12px',
                                color: '#4A6C7A',
                                lineHeight: '1.5em',
                              }}
                            >
                              {truncated(product.productDescription, 200)}
                            </p>

                            <p
                              style={{
                                margin: '1em auto 0 auto',
                                padding: 0,
                                display: 'block',
                                fontWeight: 'bold',
                                width: '100%',
                                height: '32px',
                                lineHeight: '32px',
                                backgroundColor: '#008EC9',
                                color: '#ffffff',
                                textDecoration: 'none',
                                textAlign: 'center',
                                borderRadius: '24px',
                                whiteSpace: 'pre-line',
                                maxWidth: '320px',
                              }}
                            >
                              {product.buttonText}
                            </p>
                          </div>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    </BaseTemplate>
  );
};
