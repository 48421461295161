// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'client/pages/Loading';
import { useData } from 'client/pages/Help/HelpArticle/useData';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/Help/HelpArticle/HelpArticle.module.css';
import { Divider } from 'client/components/Divider/Divider';
import { AlgoliaSearchForm } from 'client/components/AlgoliaSearchForm/AlgoliaSearchForm';

export const HelpArticle = ({ id }: { id: string }) => {
  const { i18n } = useTranslation();
  const { data, isLoading } = useData(i18n.language, id);

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['base-main__body']}>
      <AlgoliaSearchForm />

      <div className={styles['page-help']}>
        {data.title && (
          <>
            <h3
              className={styles['page-help__title']}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <Divider />
          </>
        )}
        {(data.contents || []).map((content, idx) => {
          return (
            <div className={styles['page-help__content']} key={idx}>
              {content.headline && (
                <h4
                  className={styles['page-help__content__headline']}
                  dangerouslySetInnerHTML={{ __html: content.headline }}
                />
              )}
              {content.imageUrl && <img src={content.imageUrl} />}
              <div
                className={styles['page-help__content__body']}
                dangerouslySetInnerHTML={{ __html: content.body }}
              />
            </div>
          );
        })}
        {(data.tags || []).map((tag) => (
          <span className={styles['page-help__tag']}>#{tag}</span>
        ))}
      </div>
    </div>
  );
};
