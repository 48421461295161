import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SuccessMessage.module.css';

type Props = {
  visible: boolean;
  dataExportStatus: string | null;
};
export const SuccessMessage = ({ visible, dataExportStatus }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.info}
      style={{
        display: visible ? 'block' : 'none',
      }}
    >
      {dataExportStatus === 'DATA_EXPORT_STATUS_EXPORTED' ? (
        <>
          {t(
            'CSV file is now available. Please download it from the "Downloads" tab.'
          )}
        </>
      ) : (
        <>
          {t(
            "CSV file is now being created. Email will be sent to you when it's ready for you to download."
          )}
        </>
      )}
    </div>
  );
};
