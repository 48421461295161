import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';

import { Button, Input } from 'client/components/Form';
import { EquipmentBlockInstancePrice } from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { updateOrganization } from 'client/actions/organizations';

interface FormValues {
  key: string;
  title: string;
  gross?: string;
  net?: string;
}

const getInitialValues = (
  equipmentBlockInstancePrice: EquipmentBlockInstancePrice | undefined
): FormValues => {
  return {
    key: equipmentBlockInstancePrice?.key ?? uuidv4(),
    title: equipmentBlockInstancePrice?.title ?? '',
    gross: equipmentBlockInstancePrice?.gross
      ? equipmentBlockInstancePrice?.gross?.substring(3)
      : '',
    net: equipmentBlockInstancePrice?.net?.[0].net_fixed
      ? equipmentBlockInstancePrice?.net[0].net_fixed.substring(3)
      : '',
  };
};

const convertToSwagger = (
  values: FormValues,
  currencyCode: string
): EquipmentBlockInstancePrice => {
  return {
    key: values.key,
    title: values.title,
    gross: values.gross ? `${currencyCode}${values.gross}` : '',
    net: [
      {
        net_fixed: values.net ? `${currencyCode}${values.net}` : '',
      },
    ],
  };
};

interface Props {
  equipmentBlockInstancePriceKey: string | null;
  onClose: () => void;
  open: boolean;
}

const focusOnError: any = createDecorator();

export const EditEquipmentBlockInstancePriceModal = ({
  equipmentBlockInstancePriceKey,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useSelector(activeUserOrganizationSelector);

  const equipmentBlockInstancePrices = React.useMemo(() => {
    return (
      organization?.seat_management_settings?.equipment_block_instance_prices ||
      []
    );
  }, [organization]);

  const selectedEquipmentBlockInstancePrice = React.useMemo(() => {
    return equipmentBlockInstancePrices.find(
      (a) => a.key === equipmentBlockInstancePriceKey
    );
  }, [equipmentBlockInstancePrices, equipmentBlockInstancePriceKey]);

  const validateMoney = React.useCallback(
    (value?: any) => {
      if (!value) return t('Required');
      if (!matchesFormat(value, 'float')) return t('Invalid amount');
      if (Number(value) < 0) return t('Amount must be positive');
      return undefined;
    },
    [t]
  );

  const { required } = getValidators(t);

  const currencyCode = organization?.default_currency ?? 'JPY';

  return (
    <Modal open={open} onClose={onClose} title={t('Edit Attribute')} insertRoot>
      <Form<FormValues>
        initialValues={getInitialValues(selectedEquipmentBlockInstancePrice)}
        onSubmit={async (value: FormValues) => {
          try {
            const patch = convertToSwagger(value, currencyCode);

            let newEquipmentBlockInstancePrices: EquipmentBlockInstancePrice[];
            if (selectedEquipmentBlockInstancePrice) {
              newEquipmentBlockInstancePrices =
                equipmentBlockInstancePrices.map((a) => {
                  if (a.key === equipmentBlockInstancePriceKey) {
                    return patch;
                  }
                  return a;
                });
            } else {
              newEquipmentBlockInstancePrices = [
                ...equipmentBlockInstancePrices,
                patch,
              ];
            }

            await dispatch(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              updateOrganization(organization!.id, organization!.type, {
                seat_management_settings: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  ...organization!.seat_management_settings,
                  equipment_block_instance_prices:
                    newEquipmentBlockInstancePrices,
                },
              })
            );
          } catch (error) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        mutators={getArrayMutators()}
        decorators={[focusOnError]}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field name="title" validate={required}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Title')}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
                <Modal.Box column={'two'}>
                  <Field name="gross" validate={validateMoney}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Gross') + ` (${currencyCode})`}
                        error={touched && error}
                      />
                    )}
                  </Field>
                  <Field name="net" validate={validateMoney}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Net') + ` (${currencyCode})`}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                <Button size="middle" style="gray" onClick={onClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
