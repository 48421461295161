// @flow

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReservationDetails } from 'client/pages/ReservationDetails/ReservationDetails';
import {
  demoReservationId,
  demoProductInstanceId,
} from 'client/libraries/util/demoReservation';
import { fetchProductByID } from 'client/actions/products';
import { fetchDemoProductInstance } from 'client/actions/productInstances';
import { fetchDemoReservation } from 'client/actions/reservations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { activeReservationTutorialPopupSelector } from 'client/reducers/tutorial';
import { updateOrganization } from 'client/actions/organizations';
import { Button } from 'client/components/Form/Button';
import styles from 'client/pages/ReservationDemo/ReservationDemo.module.css';
import iconTutorialComplete from 'client/images/ic_tutorial_comp.svg';
import iconTutorialStep2 from 'client/images/ic_tutorial_step2.svg';
import type { ReduxState } from 'client/reducers/index';

export const ReservationDemo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [artificialLoadingActive, setArtificialLoadingActive] =
    React.useState<boolean>(true);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const demoProductId = activeUserOrganization?.tutorial_product_id || '';

  const activeReservationTutorialPopup = useSelector(
    activeReservationTutorialPopupSelector
  );
  const demoProduct = useSelector(
    (state: ReduxState) => state.products.byID[demoProductId]
  );
  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[demoReservationId]
  );
  const productInstance = useSelector(
    (state: ReduxState) => state.productInstances.byID[demoProductInstanceId]
  );

  const product = useSelector(
    (state: ReduxState) => state.products.byID[demoProductId || '']
  );

  const tutorialStage = 'PRODUCT_EDIT_BASIC_INFO';

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setArtificialLoadingActive(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    if (activeReservationTutorialPopup === 'complete') {
      dispatch(
        updateOrganization(activeUserOrganization?.id || '', 'SUPPLIER', {
          tutorial_stage: tutorialStage,
        })
      );
    }
  }, [activeReservationTutorialPopup]);

  // Product
  React.useEffect(() => {
    if (demoProductId) {
      dispatch(fetchProductByID(demoProductId));
    }
  }, [demoProductId]);

  // Product Instance
  React.useEffect(() => {
    if (demoProduct) {
      dispatch(fetchDemoProductInstance(demoProduct));
    }
  }, [demoProduct]);

  // Reservation
  React.useEffect(() => {
    if (demoProduct) {
      dispatch(fetchDemoReservation(demoProduct));
    }
  }, [demoProduct]);

  return (
    <>
      {artificialLoadingActive && (
        <Loader active={artificialLoadingActive}>
          {t('Creating Reservation')}
        </Loader>
      )}
      <div className={styles['page-tutorial__popupFrame']}>
        {activeReservationTutorialPopup === 'complete' && (
          <div className={styles['page-tutorial__popupFinish']}>
            <p className={styles['page-tutorial__popupFinish__header']}>
              {t('Reservation Check Complete')}
            </p>
            <div className={styles['page-tutorial__popupFinish__body']}>
              <div className={styles['page-tutorial__popupFinish__body__pic']}>
                <img src={iconTutorialComplete} />
              </div>
              <p className={styles['page-tutorial__popupFinish__body__desc']}>
                {t('Now you can actually accept reservations!')}
              </p>

              <div
                className={clsx(
                  styles['page-tutorial__box'],
                  styles['page-tutorial__box--step3'],
                  styles['is-active']
                )}
                data-num="3"
              >
                <div className={styles['page-tutorial__box__left']}>
                  <p className={styles['page-tutorial__box__left__next']}>
                    NEXT STEP
                  </p>
                  <p className={styles['page-tutorial__box__left__desc']}>
                    {t('See how it appears on your website.')}
                    <br />
                    {t("Let's see if we can make a reservation.")}
                  </p>
                </div>
                <div className={styles['page-tutorial__box__right']}>
                  <div className={styles['page-tutorial__box__inner']}>
                    <div className={styles['page-tutorial__box__pic']}>
                      <img src={iconTutorialStep2} />
                    </div>
                    <div className={styles['page-tutorial__box__body']}>
                      <p className={styles['page-tutorial__box__body__ttl']}>
                        {t('Site Settings')}
                      </p>
                      <div className={styles['page-tutorial__box__body__btn']}>
                        <Link to="/home/tutorial/settings">
                          <Button size="small" style="yellow">
                            {t('Set Up')}
                          </Button>
                        </Link>
                      </div>
                      <p className={styles['page-tutorial__box__body__next']}>
                        NEXT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!artificialLoadingActive && (
        <ReservationDetails
          reservation={reservation}
          product={product}
          productInstance={productInstance}
        />
      )}
    </>
  );
};
