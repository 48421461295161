import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import {
  DashboardGadget,
  ReservationSearchPreset,
} from 'client/reducers/dashboardSettings';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import {
  agentOptionsSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { SourceLanguage } from 'shared/models/swagger';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import { Radio } from 'client/components/v3/Form/Radio';
import { TextField } from 'client/components/v3/Form/TextField';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { i18n } from 'client/libraries/i18n';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { getReservationSummaryTableColumns } from 'client/libraries/util/getReservationSummaryTableColumns';
import { TranslateFuncType } from 'client/components/Translate';
import { translateParamsForSearchPreset } from 'client/pages/v3/FlexibleDashboard/util';

const getSearchConditionText = (value: string, t: TranslateFuncType) => {
  if (value == 'booked_yesterday') {
    return t('Booked Yesterday');
  }
  if (value == 'today_participations') {
    return t("Today's Participations");
  }
  if (value == 'tomorrow_participations') {
    return t("Tomorrow's Participations");
  }
  return t('Custom');
};

export const ReservationSummaryGadgetEditor = () => {
  const { t } = useTranslation();

  const form = useForm<DashboardGadget>();
  const values = form.getState().values;
  values.gadgetType = 'reservation-summary';

  const searchConditionOptions: {
    text: string;
    value: ReservationSearchPreset;
  }[] = [
    {
      text: t('Booked Yesterday'),
      value: 'BOOKED_YESTERDAY',
    },
    {
      text: t(`Today's Participations`),
      value: 'PARTICIPATION_TODAY',
    },
    {
      text: t(`Tomorrow's Participations`),
      value: 'PARTICIPATION_TOMORROW',
    },
    {
      text: t('Custom'),
      value: 'CUSTOM',
    },
  ];

  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = useMemo(() => {
    const options = getSourceLanguageOptions(t);

    return options.filter((option) => {
      return supportedLanguages.includes(option.value as SourceLanguage);
    });
  }, [supportedLanguages, t]);

  const allColumns = getReservationSummaryTableColumns(t);
  const getColumns = (columnMask: string[]): any[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c));
  };

  return (
    <>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Search Conditions')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.searchPreset">
            {({ input: searchPresetsInput }) => (
              <>
                <SingleDropdown
                  selectedOption={searchPresetsInput.value}
                  onChange={searchPresetsInput.onChange}
                  options={searchConditionOptions}
                />
                {searchPresetsInput.value !== 'CUSTOM' && (
                  <div className={styles['p-dashboardModal__item__body__note']}>
                    <p className={styles['p-dashboardModal__item__ttl']}>
                      {t('Condition Details')}
                      {' ('}
                      {getSearchConditionText(searchPresetsInput.value, t)}
                      {')'}
                    </p>
                    <div
                      className={
                        styles['p-dashboardModal__item__body__note__body']
                      }
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: translateParamsForSearchPreset(
                            searchPresetsInput.value,
                            t
                          ),
                        }}
                      />
                    </div>
                  </div>
                )}
                {searchPresetsInput.value === 'CUSTOM' && (
                  <div className={styles['p-dashboardModal__item__body__note']}>
                    <p className={styles['p-dashboardModal__item__ttl']}>
                      {t('Condition Details')}
                      {' ('}
                      {getSearchConditionText(searchPresetsInput.value, t)}
                      {')'}
                    </p>
                    <div
                      className={
                        styles['p-dashboardModal__item__body__note__body']
                      }
                    >
                      <ul>
                        <li>
                          <Field name="params.statuses">
                            {({ input }) => (
                              <MultiDropdown
                                label={t('Booking Status')}
                                selectedOptions={input.value}
                                onChange={input.onChange}
                                options={statusOptions}
                              />
                            )}
                          </Field>
                        </li>
                        <li>
                          <Field name="params.dateType">
                            {({ input }) => (
                              <SingleDropdown
                                label={t('Date')}
                                selectedOption={input.value}
                                onChange={input.onChange}
                                options={[
                                  {
                                    value: 'BOOKED_DATE',
                                    text: t('Booking Date'),
                                  },
                                  {
                                    value: 'PARTICIPATION_DATE',
                                    text: t('Participation Date'),
                                  },
                                ]}
                              />
                            )}
                          </Field>
                        </li>

                        <Field name="params.targetDateType">
                          {({ input: targetDateTypeInput }) => (
                            <>
                              <li>
                                <div
                                  className={
                                    styles['p-dashboardModal__item__flex']
                                  }
                                >
                                  <p
                                    className={
                                      styles['p-dashboardModal__item__ttl']
                                    }
                                    style={{ width: '100%' }}
                                  >
                                    {t('Target Dates')}
                                  </p>
                                  <div
                                    className={
                                      styles['p-dashboardModal__item__radio']
                                    }
                                  >
                                    <Radio
                                      label={t('Current date')}
                                      checked={
                                        targetDateTypeInput.value ===
                                        'CURRENT_DATE'
                                      }
                                      onChange={() => {
                                        targetDateTypeInput.onChange(
                                          'CURRENT_DATE'
                                        );
                                      }}
                                      size="sm"
                                    />
                                    <Radio
                                      label={t('Previous date')}
                                      checked={
                                        targetDateTypeInput.value ===
                                        'PREVIOUS_DATE'
                                      }
                                      onChange={() => {
                                        targetDateTypeInput.onChange(
                                          'PREVIOUS_DATE'
                                        );
                                      }}
                                      size="sm"
                                    />
                                    <Radio
                                      label={t('Next date')}
                                      checked={
                                        targetDateTypeInput.value ===
                                        'NEXT_DATE'
                                      }
                                      onChange={() => {
                                        targetDateTypeInput.onChange(
                                          'NEXT_DATE'
                                        );
                                      }}
                                      size="sm"
                                    />
                                    <Radio
                                      label={t('Specific number of days')}
                                      checked={
                                        targetDateTypeInput.value === 'RANGE'
                                      }
                                      onChange={() => {
                                        targetDateTypeInput.onChange('RANGE');
                                      }}
                                      size="sm"
                                    />
                                  </div>
                                </div>
                              </li>

                              {targetDateTypeInput.value === 'RANGE' && (
                                <li>
                                  <div
                                    className={
                                      styles['p-dashboardModal__item__flex']
                                    }
                                  >
                                    <p
                                      className={
                                        styles['p-dashboardModal__item__ttl']
                                      }
                                      style={{ width: '100%' }}
                                    >
                                      {t('Number of days')}
                                    </p>
                                    <div
                                      className={
                                        styles[
                                          'p-dashboardModal__item__targetDates'
                                        ]
                                      }
                                    >
                                      {i18n.language === 'ja' ? (
                                        <>
                                          <div style={{ width: '150px' }}>
                                            今日から
                                          </div>
                                          <Field name="params.dateRange">
                                            {({ input }) => (
                                              <TextField
                                                type="number"
                                                value={input.value}
                                                onChange={(value) => {
                                                  if (
                                                    value !== '' &&
                                                    !matchesFormat(
                                                      value,
                                                      'non-negative-integer'
                                                    )
                                                  ) {
                                                    return;
                                                  }
                                                  input.onChange(value);
                                                }}
                                                {...{ min: 1 }}
                                              />
                                            )}
                                          </Field>
                                          <Field name="params.dateRangeType">
                                            {({ input }) => (
                                              <SingleDropdown
                                                options={[
                                                  {
                                                    value: 'NEXT',
                                                    text: t(
                                                      'dateRangeType.next'
                                                    ),
                                                  },
                                                  {
                                                    value: 'PREV',
                                                    text: t(
                                                      'dateRangeType.previous'
                                                    ),
                                                  },
                                                ]}
                                                selectedOption={input.value}
                                                onChange={(value) => {
                                                  input.onChange(value);
                                                }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <>
                                          <Field name="params.dateRangeType">
                                            {({ input }) => (
                                              <SingleDropdown
                                                options={[
                                                  {
                                                    value: 'NEXT',
                                                    text: t(
                                                      'dateRangeType.next'
                                                    ),
                                                  },
                                                  {
                                                    value: 'PREV',
                                                    text: t(
                                                      'dateRangeType.previous'
                                                    ),
                                                  },
                                                ]}
                                                selectedOption={input.value}
                                                onChange={(value) => {
                                                  input.onChange(value);
                                                }}
                                              />
                                            )}
                                          </Field>
                                          <Field name="params.dateRange">
                                            {({ input }) => (
                                              <TextField
                                                type="number"
                                                value={input.value}
                                                onChange={(value) => {
                                                  if (
                                                    value !== '' &&
                                                    !matchesFormat(
                                                      value,
                                                      'non-negative-integer'
                                                    )
                                                  ) {
                                                    return;
                                                  }
                                                  input.onChange(value);
                                                }}
                                                {...{ min: 1 }}
                                              />
                                            )}
                                          </Field>
                                          <div style={{ width: '100%' }}>
                                            days from today
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              )}
                            </>
                          )}
                        </Field>
                        <li>
                          <Field name="params.bookingSources">
                            {({ input }) => (
                              <MultiDropdown
                                label={t('Booking Source')}
                                selectedOptions={input.value}
                                onChange={input.onChange}
                                options={bookingSourceOptions}
                              />
                            )}
                          </Field>
                        </li>
                        <li>
                          <Field name="params.agents">
                            {({ input }) => (
                              <MultiDropdown
                                label={t('Agent')}
                                selectedOptions={input.value}
                                onChange={input.onChange}
                                options={agentOptions}
                              />
                            )}
                          </Field>
                        </li>
                        <li>
                          <Field name="params.languages">
                            {({ input }) => (
                              <MultiDropdown
                                label={t('Language')}
                                selectedOptions={input.value}
                                onChange={input.onChange}
                                options={languageOptions}
                              />
                            )}
                          </Field>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
            )}
          </Field>
          <div className={styles['p-dashboardModal__item__body__sort']}>
            <p className={styles['p-dashboardModal__item__ttl']}>
              {t('Edit Columns')}
              <span>
                {t(
                  'You can customize which columns to show and the display order.'
                )}
              </span>
            </p>
            <Field name="params.defaultColumns">
              {({ input }) => {
                return (
                  <ToggleableDragAndDropList
                    selectedItems={
                      input.value
                        ? getColumns(
                            input.value.map((c: any) => c.toLowerCase())
                          ).map((col) => ({
                            key: col.id,
                            text: col.Header,
                          }))
                        : // Always select product
                          getColumns(['product']).map((col) => ({
                            key: col.id,
                            text: col.Header,
                          }))
                    }
                    setSelectedItems={(items) =>
                      input.onChange(
                        items.map((item) => item.key.toUpperCase())
                      )
                    }
                    candidateItems={
                      allColumns.map((col) => ({
                        key: col.id,
                        text: col.Header,
                      })) || []
                    }
                    // Set product as fixed 1st column
                    toggleDisabledKeys={['product']}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </li>
    </>
  );
};
