import * as Swagger from 'shared/models/swagger';

export type HighlightsFormValues = {
  highlights: string[];
};
export const getInitialHighlightsFormValues = (
  product: Swagger.Product | null
): HighlightsFormValues => {
  return {
    highlights: product?.highlights ?? [],
  };
};
export const convertHighlightsFormValuesToProductPatch = (
  values: HighlightsFormValues
): Swagger.Product$Patch => ({
  highlights: values.highlights.filter((highlight) => Boolean(highlight)),
});
