import { useTranslation } from 'react-i18next';

import { getBookingWidgetProductGroupCalendarUrl } from 'client/libraries/util/getBookingWidgetProductGroupCalendarUrl';
import { EmbeddedProductGroupCalendar } from 'client/pages/v3/BookingWidget/WebSiteIntegration/EmbeddedProductGroupCalendar/EmbeddedProductGroupCalendar';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

type Props = {
  bookingWidgetApiKey: string;
  widgetLanguage: string;
};

export const BookingWidgetEmbeddedProductGroupCalendar = ({
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const { t } = useTranslation();

  const calendarUrl = getBookingWidgetProductGroupCalendarUrl(
    bookingWidgetApiKey,
    widgetLanguage
  );

  return (
    <div className={styles['p-inquiry-list__item__body__item']}>
      <div className={styles['p-bookingWidgetCustomize-box']}>
        <div className={styles['p-bookingWidgetCustomize-box__list']}>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Product Group')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <EmbeddedProductGroupCalendar
                  apiKey={bookingWidgetApiKey}
                  language={widgetLanguage}
                />
              </dd>
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Code')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <pre
                  className={styles['code-block']}
                >{`<script type="text/javascript">
        /*<![CDATA[*/
        (function () {
            loadScriptsAsync();
            function loadScriptsAsync() {
                if (window.buildMultiCalendarEmbed) {
                    NutmegMultiCalendarInit();
                } else {
                    var script = document.createElement('script');
                    script.src = 'https://assets.ntmg.com/scripts/falcon-embed-multi-product/v1/index.js';
                    script.async = true;
                    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                    script.onload = NutmegMultiCalendarInit
                }
            
                if (!window.NutmegCalendar) {
                  script = document.createElement('script');
                  script.src = 'https://assets.ntmg.com/scripts/falcon-embed/v1/index.js';
                  script.async = true;
                  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                }
            }
            function NutmegMultiCalendarInit() {
                window.buildMultiCalendarEmbed('${calendarUrl}');
            }
        })();
        /*]]>*/
    </script>
    <div id="nutmeg-multi-calendar"></div>`}</pre>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
