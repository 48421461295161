import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Modal } from 'client/components/Modal/Modal';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import {
  Button,
  Input,
  Select,
  TextArea,
  FieldWrapper,
} from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { bookingWidgetPMPSupportedLanguagesSelector } from 'client/reducers/user';
import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';
import { getLanguageName } from 'client/libraries/i18n';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import {
  createGuidanceStampRally,
  updateGuidanceStampRally,
} from 'client/actions/guidanceStampRally';
import baseStyles from 'client/base.module.css';
import * as Swagger from 'shared/models/swagger';

import { DigitalGuidanceStampRallyGiftListEditor } from './DigitalGuidanceStampRallyGiftListEditor';
import styles from './EditGuidanceStampRallyModal.module.css';
import {
  getInitialValues,
  FormValues,
  TranslationFormItem,
  convertFormValuesToSwagger,
} from './formValues';

type Props = {
  existingGuidanceStampRally?: Swagger.GuidanceStampRally;
  open: boolean;
  onClose: () => void;
};

export const EditGuidanceStampRallyModal = ({
  open,
  onClose,
  existingGuidanceStampRally,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  React.useEffect(() => {
    const sourceTexts = [
      existingGuidanceStampRally?.title,
      existingGuidanceStampRally?.description,
    ];

    existingGuidanceStampRally?.gifts?.forEach((gift) => {
      sourceTexts.push(gift.title);
      sourceTexts.push(gift.description);
    });

    const filteredSourceTexts = sourceTexts.filter((text) => Boolean(text));

    if (filteredSourceTexts.length > 0) {
      dispatch(batchGetTranslations(filteredSourceTexts as string[]));
    }
  }, [existingGuidanceStampRally]);

  const languageOptions = supportedLanguages
    .filter((lang) => lang !== defaultLanguage)
    .map((lang) => ({
      value: lang,
      text: getLanguageName(lang, t),
    }));

  const initialValues = React.useMemo(
    () => getInitialValues(existingGuidanceStampRally),
    [existingGuidanceStampRally]
  );

  return (
    <Modal
      title={t('Edit Stamp Rally')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        debug={console.log}
        onSubmit={async (values: FormValues) => {
          if (existingGuidanceStampRally) {
            await dispatch(
              updateGuidanceStampRally(
                existingGuidanceStampRally.id ?? '',
                convertFormValuesToSwagger(values)
              )
            );
          } else {
            await dispatch(
              createGuidanceStampRally(convertFormValuesToSwagger(values))
            );
          }

          // TODO: submit
          if (values.targetTranslationLanguage) {
            await dispatch(
              updateTranslations(
                values?.translations.map((translation) => ({
                  source_language: defaultLanguage,
                  [defaultLanguage.toLowerCase()]: translation.source,
                  [values.targetTranslationLanguage?.toLowerCase() as any]:
                    translation.target,
                }))
              )
            );
          }

          onClose();
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, values, form }) => {
          React.useEffect(() => {
            let translations: TranslationFormItem[] = [];
            if (defaultLanguage && values.targetTranslationLanguage) {
              const sourceFieldName: string = defaultLanguage.toLowerCase();
              const translationFieldName: string =
                values.targetTranslationLanguage.toLowerCase();
              translations = apiTranslations.map((trans) => ({
                source: (trans as any)[sourceFieldName],
                target: (trans as any)[translationFieldName],
              }));
            }

            form.change('translations', translations);
          }, [
            defaultLanguage,
            apiTranslations,
            values.targetTranslationLanguage,
          ]);

          React.useEffect(() => {
            if (!values.showTranslationMode) {
              form.change('targetTranslationLanguage', undefined);
            }
          }, [values.showTranslationMode]);

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                {submitting && (
                  <Loader active={submitting}>{t('Loading')}</Loader>
                )}
                <div className={baseStyles['base-main__body__header']}>
                  <div
                    className={clsx(
                      baseStyles['base-main__body__header__left'],
                      baseStyles['spSpacebetween'],
                      baseStyles['spOrder-1']
                    )}
                  >
                    <div className={baseStyles['base-form-toggle']}>
                      <label>
                        <Field name="showTranslationMode" type="checkbox">
                          {({ input }) => <input type="checkbox" {...input} />}
                        </Field>
                        <p></p>
                      </label>
                      {t('Translation mode')}
                    </div>
                    {values.showTranslationMode && (
                      <Field name="targetTranslationLanguage">
                        {({ input }) => (
                          <Select
                            width={176}
                            options={languageOptions.filter(
                              (lang) => lang.value !== defaultLanguage
                            )}
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <FormTableBox>
                  <table>
                    {values.targetTranslationLanguage ? (
                      <thead>
                        <tr className={clsx(styles['table-header'])}>
                          <th></th>
                          <td>{getLanguageName(defaultLanguage, t)}</td>
                          <td>
                            {getLanguageName(
                              values.targetTranslationLanguage,
                              t
                            )}
                          </td>
                        </tr>
                      </thead>
                    ) : null}
                    <tbody>
                      <tr>
                        <th>{t('Stamp Rally Title')}</th>
                        <TranslatedField name="title">
                          {({ input, translationInput }) => (
                            <>
                              <td>
                                <Input
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                />
                              </td>
                              {values.targetTranslationLanguage ? (
                                <td>
                                  <Input
                                    value={translationInput.value}
                                    onChange={(e, { value }) =>
                                      translationInput.onChange(value)
                                    }
                                    placeholder={getLanguageName(
                                      values.targetTranslationLanguage,
                                      t
                                    )}
                                  />
                                </td>
                              ) : null}
                            </>
                          )}
                        </TranslatedField>
                      </tr>
                      <tr>
                        <th>{t('Description')}</th>
                        <TranslatedField name="description">
                          {({ input, translationInput }) => (
                            <>
                              <td>
                                <TextArea
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                />
                              </td>
                              {values.targetTranslationLanguage ? (
                                <td>
                                  <TextArea
                                    value={translationInput.value}
                                    onChange={(e, { value }) =>
                                      translationInput.onChange(value)
                                    }
                                    placeholder={getLanguageName(
                                      values.targetTranslationLanguage,
                                      t
                                    )}
                                  />
                                </td>
                              ) : null}
                            </>
                          )}
                        </TranslatedField>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>

                <Box mt={2}>
                  <Field name="backgroundImageUrl">
                    {({ input, meta: { touched, error } }) => (
                      <FieldWrapper label={t('Background Image (jpg, png)')}>
                        <ImageVideoAudioInput
                          fileUrls={input.value ? [input.value] : []}
                          onChange={(newValue) =>
                            newValue.length > 0
                              ? input.onChange(newValue[0])
                              : input.onChange('')
                          }
                          maxFileCount={1}
                          disableYoutubeVideos
                          error={touched && error}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </Box>

                <Box mt={2}>
                  <Field name="stampImageUrl">
                    {({ input, meta: { touched, error } }) => (
                      <FieldWrapper label={t('Stamp Image (jpg, png)')}>
                        <ImageVideoAudioInput
                          fileUrls={input.value ? [input.value] : []}
                          onChange={(newValue) =>
                            newValue.length > 0
                              ? input.onChange(newValue[0])
                              : input.onChange('')
                          }
                          maxFileCount={1}
                          disableYoutubeVideos
                          error={touched && error}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </Box>

                <Box mt={2}>
                  <Field name="stampCount">
                    {({ input }) => (
                      <FieldWrapper label={t('The number of stamps')}>
                        <Select
                          options={[...Array(100)].map((_, i) => {
                            return { text: `${i + 1}`, value: `${i + 1}` };
                          })}
                          value={input.value}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </Box>

                <Box mt={2}>
                  <DigitalGuidanceStampRallyGiftListEditor />
                </Box>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
