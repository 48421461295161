import * as React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';

import { ColorSelectCheckbox } from 'client/components/ColorSelectCheckbox/ColorSelectCheckbox';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  Input,
  FieldWrapper,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import type { EquipmentBlockInstanceAttribute } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

interface Props {
  equipmentBlockInstanceAttributeKey: string | null;
  onClose: () => void;
  open: boolean;
}

const focusOnError: any = createDecorator();

type formValues = EquipmentBlockInstanceAttribute & {
  changeColor: boolean;
};

const getInitialValues = (
  equipmentBlockInstanceAttribute: EquipmentBlockInstanceAttribute | undefined
): formValues => {
  if (!equipmentBlockInstanceAttribute) {
    return {
      key: uuidv4(),
      title: '',
      booking_sources: [],
      changeColor: false,
    };
  }
  return {
    ...equipmentBlockInstanceAttribute,
    changeColor: !!equipmentBlockInstanceAttribute.color,
  };
};

const convertFormValues = (values: formValues) => {
  return {
    ...values,
    color: values.changeColor ? values.color : '',
  };
};

export const EditEquipmentBlockInstanceAttributeModal = ({
  equipmentBlockInstanceAttributeKey,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useSelector(activeUserOrganizationSelector);

  const contractedAgents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const equipmentBlockInstanceAttributes = React.useMemo(() => {
    return (
      organization?.seat_management_settings
        ?.equipment_block_instance_attributes || []
    );
  }, [organization]);

  const selectedEquipmentBlockInstanceAttribute = React.useMemo(() => {
    return equipmentBlockInstanceAttributes.find(
      (a) => a.key === equipmentBlockInstanceAttributeKey
    );
  }, [equipmentBlockInstanceAttributes, equipmentBlockInstanceAttributeKey]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('Edit Booking Source Group')}
      insertRoot
    >
      <Form<formValues>
        initialValues={getInitialValues(
          selectedEquipmentBlockInstanceAttribute
        )}
        onSubmit={async (value: formValues) => {
          try {
            const newEquipmentBlockInstanceAttributes =
              equipmentBlockInstanceAttributes.map((a) =>
                a.key === value.key ? convertFormValues(value) : a
              );
            if (
              !newEquipmentBlockInstanceAttributes.find(
                (a) => a.key === value.key
              )
            ) {
              newEquipmentBlockInstanceAttributes.push(
                convertFormValues(value)
              );
            }
            await dispatch(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              updateOrganization(organization!.id, organization!.type, {
                seat_management_settings: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  ...organization!.seat_management_settings,
                  equipment_block_instance_attributes:
                    newEquipmentBlockInstanceAttributes,
                },
              })
            );
            onClose();
          } catch (error) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        mutators={getArrayMutators()}
        decorators={[focusOnError]}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Box mb={2}>
                  <Field name="title">
                    {({ input }) => (
                      <Input
                        label={t('Title')}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <Field name="changeColor" type="checkbox">
                    {({ input: changeInput }) => (
                      <Box>
                        <Box mb={2}>
                          <ToggleButton
                            checked={changeInput.checked ?? false}
                            onChange={() => {
                              changeInput.onChange(!changeInput.checked);
                              if (changeInput.checked) {
                                form.change('color', '');
                              }
                            }}
                            label={t('Change Color')}
                          />
                        </Box>
                        <Box>
                          {changeInput.checked && (
                            <FieldWrapper label={t('Colors')}>
                              <Field name="color">
                                {({ input }) => (
                                  <ColorSelectCheckbox
                                    value={input.value}
                                    onChange={(color) => input.onChange(color)}
                                    defaultColors={[
                                      '#E6F8FF',
                                      '#FEFFE6',
                                      '#FFECE6',
                                      '#E7FFE6',
                                    ]}
                                  />
                                )}
                              </Field>
                            </FieldWrapper>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <FieldWrapper label={t('Booking Source')} />
                  <FieldArray name="booking_sources">
                    {({ fields }) => {
                      return (
                        <>
                          <Box mb={1}>
                            <table>
                              <tbody>
                                {fields.map((name, idx) => {
                                  return (
                                    <tr key={name}>
                                      <td>
                                        <DeleteIcon
                                          onClick={() => fields.remove(idx)}
                                        />
                                      </td>
                                      <Field name={`${name}.source_type`}>
                                        {({ input }) => (
                                          <>
                                            <td>
                                              <Select
                                                options={[
                                                  'DIRECT_WALK_IN',
                                                  'DIRECT_TELEPHONE',
                                                  'DIRECT_EMAIL',
                                                  'DIRECT_WEB',
                                                  'AGENT',
                                                  'OTHER',
                                                ].map((a, index) => ({
                                                  text: t(a),
                                                  value: a,
                                                  key: index.toString(),
                                                }))}
                                                value={input.value}
                                                onChange={(_, { value }) =>
                                                  input.onChange(value)
                                                }
                                              />
                                            </td>

                                            <td>
                                              {input.value === 'AGENT' && (
                                                <Field
                                                  name={`${name}.agent_id`}
                                                >
                                                  {({
                                                    input: agentIdInput,
                                                  }) => (
                                                    <Select
                                                      options={(
                                                        contractedAgents ?? []
                                                      ).map((a) => ({
                                                        text: a.name,
                                                        value: a.id,
                                                        key: a.id,
                                                      }))}
                                                      value={agentIdInput.value}
                                                      onChange={(
                                                        _,
                                                        { value }
                                                      ) =>
                                                        agentIdInput.onChange(
                                                          value
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Field>
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </Field>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Box>
                          <div>
                            <a
                              className={clsx(
                                baseStyles['base-btn'],
                                baseStyles['middle'],
                                baseStyles['green']
                              )}
                              onClick={() => {
                                fields.push({
                                  key: uuidv4(),
                                  tittle: '',
                                  booking_source: [],
                                });
                              }}
                            >
                              {t('Add Booking Source')}
                            </a>
                          </div>
                        </>
                      );
                    }}
                  </FieldArray>
                </Box>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
