import * as React from 'react';
import clsx from 'clsx';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

type Props = {
  error?: string;
  placeholder?: string;
  search?: boolean;
  selectedOption: string;
  onChange: (selectedOption: string) => void;
  options: {
    text: string;
    value: string;
  }[];
  style?: Record<string, any>;
  disabled?: boolean;
  menuStyle?: React.CSSProperties;
  resetSearchText?: boolean;
  optionOpensAt?: 'bottom' | 'top';
};
export const Select = ({
  error,
  placeholder,
  search,
  selectedOption,
  onChange,
  style,
  options,
  disabled,
  menuStyle,
  resetSearchText,
  optionOpensAt = 'bottom',
}: Props) => {
  const fieldSetRef = React.useRef<HTMLFieldSetElement | null>(null);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [searchMode, setSearchMode] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');

  const [placeHolder, setPlaceHolder] = React.useState<string>(
    placeholder || ''
  );
  const combinedStyle = {
    ...style,
  };
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOptions &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowOptions(false);
        setIsClicked(false);
        if (search) {
          setSearchMode(false);
        }
      }
    };

    if (showOptions) {
      window.document.addEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.addEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
      window.document.addEventListener('click', handleOutsideClick, {
        capture: true,
      });
      return () => {
        window.document.removeEventListener('mousedown', handleOutsideClick, {
          capture: true,
        });
        window.document.removeEventListener('touchstart', handleOutsideClick, {
          capture: true,
        });
        window.document.removeEventListener('click', handleOutsideClick, {
          capture: true,
        });
      };
    }
  }, [showOptions]);

  React.useEffect(() => {
    if (placeHolder) {
      setIsClicked(true);
    }
  }, [placeHolder]);

  const handleOptionClick = (value: string) => {
    onChange(value);
    setShowOptions(false);
    setIsClicked(false);
  };

  const filteredOptions = options.filter((o) => {
    if (o.text === '') {
      return false;
    }

    if (search && searchText) {
      return replaceFullWidthCharactersWithHalfWidth(
        o.text.toLowerCase()
      ).includes(searchText.toLowerCase());
    }

    return true;
  });
  return (
    <>
      <fieldset
        ref={fieldSetRef}
        className={clsx(
          styles['c-select'],
          isClicked && styles['is-active'],
          isHovered && styles['is-hover'],
          (isClicked || selectedOption) && styles['is-input'],
          disabled && styles['is-disabled'],
          error !== undefined && styles['is-error']
        )}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={combinedStyle}
        onClick={() => {
          setShowOptions(true);
          setIsClicked(true);
          if (search) {
            setSearchMode(true);
            setPlaceHolder(
              (searchText ||
                options.find((option) => option.value === selectedOption)
                  ?.text) ??
                ''
            );
            setSearchText('');
          }
        }}
        {...getTouchEventBoundaryProps()}
      >
        <div className={styles['c-select__body']}>
          <label>
            {search ? (
              <input
                className={
                  styles['c-dropdown__body__selected__input__canSearch']
                }
                type="text"
                value={
                  resetSearchText
                    ? ''
                    : searchMode
                    ? searchText
                    : (searchText ||
                        options.find(
                          (option) => option.value === selectedOption
                        )?.text) ??
                      ''
                }
                onChange={(event) => {
                  setSearchText(event.target.value);
                  onChange(event.target.value);
                }}
                placeholder={placeHolder}
              />
            ) : (
              <div className={styles['c-select__body__selected']}>
                <p>
                  {options.find((option) => option.value === selectedOption)
                    ?.text ??
                    selectedOption ??
                    ''}
                </p>
              </div>
            )}
          </label>
        </div>
        {filteredOptions.length > 0 && (
          <ul
            className={clsx(
              styles['c-select__menu'],
              showOptions && styles['is-active'],
              styles[optionOpensAt]
            )}
            style={menuStyle}
          >
            {filteredOptions.map((option, idx) => {
              return (
                <li
                  className={styles['c-select__menu__item']}
                  key={idx}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleOptionClick(option.value);
                    setSearchText('');
                    setShowOptions(false);
                  }}
                >
                  {/* <p>リスト</p> */}
                  {option.text || 'NO WORD'}
                </li>
              );
            })}
          </ul>
        )}
      </fieldset>
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </>
  );
};
