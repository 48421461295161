import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';

import {
  CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT,
  CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE,
  CLEAR_PRODUCT_INSTANCES,
  FETCH_PRODUCT_INSTANCES_FAILURE,
  FETCH_PRODUCT_INSTANCES_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS,
  FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE,
  UPDATE_PRODUCT_INSTANCE_SUCCESS,
  UPDATE_PRODUCT_INSTANCE_FAILURE,
  FETCH_PRODUCT_INSTANCES_REQUEST,
  FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST,
  UPDATE_PRODUCT_INSTANCE_REQUEST,
  LOGOUT_SUCCESS,
  BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST,
  BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS,
  BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  GetProductInstanceResponse,
  ListProductInstancesResponse,
  ProductInstance,
  UpdateProductInstanceResponse,
} from 'shared/models/swagger';

// Selectors
const idsSelector = (productID: string) => (state: ReduxState) =>
  state.productInstances.byProductIDInstanceIDs[productID] || [];

const byIDSelector = (state: ReduxState) => state.productInstances.byID;

const byProductIDInstanceIDsSelector = (state: ReduxState) =>
  state.productInstances.byProductIDInstanceIDs;

// const selectedProductIDSelector = state => state.productInstances.selectedProductID;
export const makeProductInstancesSelector = (productID: string) =>
  createSelector(idsSelector(productID), byIDSelector, (ids, byID) =>
    ids.map((id: string) => byID[id])
  );

export const productInstancesByProductIdSelector = createSelector(
  byIDSelector,
  byProductIDInstanceIDsSelector,
  (byID, byProductIDInstanceIDs): Record<string, ProductInstance[]> => {
    const productInstancesByProductId: Record<string, ProductInstance[]> = {};

    Object.entries(byProductIDInstanceIDs).map(([productId, instanceIds]) => {
      const instances = instanceIds.map((id) => byID[id] as ProductInstance);
      _.orderBy(instances, 'start_date_time_utc');

      productInstancesByProductId[productId] = instances;
    });

    return productInstancesByProductId;
  }
);

// Reducers
const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCES_FAILURE:
    case FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE:
    case UPDATE_PRODUCT_INSTANCE_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCES_REQUEST:
    case FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST:
    case UPDATE_PRODUCT_INSTANCE_REQUEST:
      return true;

    case FETCH_PRODUCT_INSTANCES_FAILURE:
    case FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE:
    case UPDATE_PRODUCT_INSTANCE_FAILURE:
    case FETCH_PRODUCT_INSTANCES_SUCCESS:
    case FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS:
    case UPDATE_PRODUCT_INSTANCE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const loadingProductId = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCES_REQUEST:
      return action.productID;

    case FETCH_PRODUCT_INSTANCES_FAILURE:
    case FETCH_PRODUCT_INSTANCES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const ids = (state: Record<string, string[]>, productID: string) => {
  return state[productID] || [];
};

// Map: productID => instanceID[]
const byProductIDInstanceIDs = (
  state: Record<string, string[]> = {},
  action: any
) => {
  switch (action.type) {
    case CLEAR_PRODUCT_INSTANCES:
      return {};

    case FETCH_PRODUCT_INSTANCES_SUCCESS: {
      const response = action.response as any as ListProductInstancesResponse;
      return {
        ...state,
        [response.product_id]: [
          ...new Set([
            ...response.instances.map((p) => p.id),
            ...(state[response.product_id] || []),
          ]),
        ],
      };
    }

    case FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS: {
      const response = action.response as any as GetProductInstanceResponse;
      return {
        ...state,
        [response.product_id]: [
          ...new Set([
            ...ids(state, response.product_id).filter(
              (id) => id !== response.id
            ),
            response.id,
          ]),
        ],
      };
    }

    case UPDATE_PRODUCT_INSTANCE_SUCCESS: {
      const response = action.response as any as UpdateProductInstanceResponse;
      return {
        ...state,
        [response.product_id]: [
          ...new Set([
            ...ids(state, response.product_id).filter(
              (id) => id !== response.id
            ),
            response.id,
          ]),
        ],
      };
    }

    default:
      return state;
  }
};

const buildByID = (instances: ProductInstance[]) => {
  const byID: Record<string, ProductInstance> = {};
  instances.forEach((p) => {
    byID[p.id] = p;
  });
  return byID;
};

const byID = (state: Record<string, ProductInstance> = {}, action: any) => {
  switch (action.type) {
    case CLEAR_PRODUCT_INSTANCES:
      return {};

    case FETCH_PRODUCT_INSTANCES_SUCCESS: {
      const response = action.response as any as ListProductInstancesResponse;
      return { ...state, ...buildByID(response.instances) };
    }

    case FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS:
    case UPDATE_PRODUCT_INSTANCE_SUCCESS: {
      const response = action.response as any as GetProductInstanceResponse;
      return { ...state, [response.id]: response };
    }

    default:
      return state;
  }
};

// This is separate from the "normal" product instances so that a calendar
// fetch will not re-render a booking form.
const current = (state: ProductInstance | null = null, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS: {
      return action.response;
    }

    default:
      return state;
  }
};

// This is separate from the "normal" product instances so that a calendar
// fetch will not re-render a booking form.
const currentLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST:
      return true;

    case FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS:
    case FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE:
      return false;

    default:
      return state;
  }
};

const selectedProductID = (state = '', action: any) => {
  switch (action.type) {
    case CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT:
      return action.productID;

    default:
      return state;
  }
};

const batchCloseError = (state = '', action: any) => {
  switch (action.type) {
    case BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST:
    case BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS:
      return '';
    case BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(1, 'days'),
};

const selectedDateRange = (state = defaultDateRange, action: any) => {
  switch (action.type) {
    case CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE:
      return {
        startDate: action.startDate,
        endDate: action.endDate,
      };

    default:
      return state;
  }
};

type State = {
  error: string;
  byProductIDInstanceIDs: Record<string, string[]>;
  byID: Record<string, ProductInstance>;
  current: ProductInstance | null;
  currentLoading: boolean;
  selectedProductID: string;
  selectedDateRange: { startDate: Moment; endDate: Moment };
  loading: boolean;
  loadingProductId: string;
  batchCloseError: string;
};

const reducer = combineReducers<State>({
  error,
  byProductIDInstanceIDs,
  byID,
  current,
  currentLoading,
  selectedProductID,
  selectedDateRange,
  loading,
  loadingProductId,
  batchCloseError,
});
export const productInstances = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
