import * as React from 'react';
import ReactTable from 'react-table';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getTimeSlotsFromRecurrence } from 'client/libraries/util/getTimeSlots';
import { formattedTimeSlot } from 'client/libraries/util/formattedTimeSlot';
import { EditProductAgentReferenceModal } from 'client/pages/EditProductContent/EditProductAgentReferenceModal/EditProductAgentReferenceModal';
import { fetchProductAgentReferencesByProductId } from 'client/actions/productAgentReferences';
import { activeUserSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import type { Product, ProductAgentReference } from 'shared/models/swagger';

import { DeleteProductAgentReferenceButton } from './DeleteProductAgentReferenceButton';
import { EditProductAgentUnitMappingsModal } from './EditProductAgentUnitMappingsModal';

interface CellInfo {
  value: string;
  original: ProductAgentReference;
}

type Props = {
  product: Product;
};

export const EditProductAgentReferenceForm = ({ product }: Props) => {
  const [showUnitMappingsModal, setShowUnitMappingsModal] =
    React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector(
    (state: ReduxState) => state.productAgentReferences.all
  );
  const organizations = useSelector(
    (state: ReduxState) => state.organizations.all
  );
  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    if (product?.id) {
      dispatch(fetchProductAgentReferencesByProductId(product?.id));
    }
  }, [product?.id]);

  const columns = React.useMemo(() => {
    const timeSlots = getTimeSlotsFromRecurrence(product?.recurrence || []);
    return [
      {
        Header: t('Id'),
        accessor: 'id',
        id: 'edit',
        Cell: (cellInfo: CellInfo) => (
          <React.Fragment key={cellInfo.value}>
            <EditProductAgentReferenceModal
              trigger={<Icon link color="orange" name="edit" />}
              currentProductAgentReference={cellInfo.original}
              product={product}
            />
            <DeleteProductAgentReferenceButton
              id={cellInfo.original.id ?? ''}
              name={cellInfo.original.agent_reference ?? ''}
            />
            {cellInfo.value}
          </React.Fragment>
        ),
      },
      ...(activeUser?.role === 'nutmeg_admin'
        ? [
            {
              Header: t('Agent Id'),
              accessor: 'agent_id',
            },
            {
              Header: t('Agent Name'),
              id: 'agent_name',
              Cell: (cellInfo: CellInfo) => {
                const agent = organizations.find(
                  (organization) =>
                    organization.type === 'AGENT' &&
                    organization.id === cellInfo.original.agent_id
                );
                return (agent && agent.name) || '';
              },
            },
          ]
        : []),
      {
        Header: t('Agent Product ID'),
        accessor: 'agent_reference',
      },
      {
        Header: t('Agent Product Name'),
        accessor: 'product_agent_reference_name',
      },
      {
        Header: t('Included Start Times'),
        id: 'time_slot_keys',
        Cell: (cellInfo: CellInfo) => {
          const timeSlotKeys = cellInfo.original.time_slot_keys;

          if (!timeSlotKeys || timeSlotKeys.length === 0) {
            return t('all start times / durations included');
          }

          return (
            <List>
              {timeSlotKeys.map((timeSlotKey) => {
                const timeSlot = timeSlots.find(
                  (timeSlot) => timeSlot.timeSlotKey === timeSlotKey
                );
                return (
                  timeSlot && (
                    <List.Item key={timeSlotKey}>
                      {formattedTimeSlot(timeSlot)}
                    </List.Item>
                  )
                );
              })}
            </List>
          );
        },
      },
      {
        Header: t('Included Add-ons'),
        accessor: 'add_on_keys',
        Cell: (cellInfo: CellInfo) =>
          cellInfo.original.add_on_keys && cellInfo.original.add_on_keys.length
            ? cellInfo.original.add_on_keys
            : t('Not set'),
      },
      {
        Header: t('Included Transportation'),
        accessor: 'transportation_key',
      },
      {
        Header: t('Included Guest Types'),
        accessor: 'guest_type_keys',
        Cell: (cellInfo: CellInfo) =>
          cellInfo.original.guest_type_keys &&
          cellInfo.original.guest_type_keys.length
            ? cellInfo.original.guest_type_keys
                .sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  }

                  if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                  }

                  return 0;
                })
                .map((key) => key + ' ')
            : t('Not set'),
      },
    ];
  }, []);

  return (
    <div className="ProductAgentReferenceTab">
      <Box display="flex" mb={2}>
        <EditProductAgentReferenceModal
          trigger={
            <Button style="green" size="middle">
              {t('Create new product-level reverse mapping')}
            </Button>
          }
          product={product}
        />

        {showUnitMappingsModal && (
          <EditProductAgentUnitMappingsModal
            product={product}
            onClose={() => setShowUnitMappingsModal(false)}
          />
        )}
        {activeUser?.organization_type === 'AGENT' && (
          <Box ml={2}>
            <Button
              style="green"
              size="middle"
              onClick={() => setShowUnitMappingsModal(true)}
            >
              {t('Edit Unit Mappings')}
            </Button>
          </Box>
        )}
      </Box>
      <ReactTable
        defaultFilterMethod={(filter: any, row: any) =>
          row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !==
          -1
        }
        filterable
        data={data}
        columns={columns}
      />
    </div>
  );
};
