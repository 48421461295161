import { useTranslation } from 'react-i18next';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { MultiDropdown } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/Dropdown/MultiDropdown';
import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';

import { TopPageFormValues } from '../FormValues';
import type { RecommendedProductList } from '../FormValues';

type Props = {
  name: string;
};
export const EditRecommendedProductsSection = ({ name }: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const productOptions = allProducts.map((product) => ({
    key: product.id ?? '',
    value: product.id ?? '',
    text: product.product_name ?? '',
  }));
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  //const language = formState.values?.sectionLanguage ?? 'JA_JP';
  const initialValues: RecommendedProductList = _.get(formState.values, name);

  return (
    <>
      <div className={styles['p-topPage-products__section__body__item']}>
        <Toggle
          label={t('Show recommended products on booking website top page')}
          checked={initialValues.isVisible}
          onChange={() => {
            form.change(`${name}.isVisible` as any, !initialValues.isVisible);
          }}
        />
      </div>
      <div className={styles['p-topPage-products__section__body__item']}>
        <MultiDropdown
          label={t('Product')}
          options={productOptions}
          onChange={(value: string[]) => {
            form.change(`${name}.productIds` as any, value);
          }}
          selectedOptions={initialValues.productIds}
          searchable={true}
        />
      </div>
    </>
  );
};
