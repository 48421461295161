import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment, { Moment } from 'moment-timezone';
import { enUS, ja, ko, zhCN, zhTW } from 'date-fns/locale';

import { isTouchBackend } from 'client/libraries/util/isTouchBackend';
import styles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';
import 'client/components/Form/style.css';

type Props = {
  onChange: (arg0: Moment) => void;
  value: Moment | null;
  locale?: 'ja' | 'en' | 'ko' | 'zh-CN' | 'zh-TW';
  showTimeInput?: boolean;
  popperPlacement?: string;
  disabled?: boolean;
  showTodayButton?: boolean;
  showWeekDay?: boolean;
};
registerLocale('ja', ja);
registerLocale('en', enUS);
registerLocale('ko', ko);
registerLocale('zh-CN', zhCN);
registerLocale('zh-TW', zhTW);

const Input = (props: Record<string, any>) => {
  return <input {...props} readOnly={isTouchBackend() ? true : false} />;
};

export const DateTimeTZPicker = ({
  onChange,
  value,
  locale,
  showTimeInput,
  popperPlacement,
  disabled,
  showTodayButton,
  showWeekDay,
}: Props) => {
  const { t } = useTranslation();
  let format = 'yyyy/MM/dd';
  if (showWeekDay) {
    format += '(EEE)';
  }
  if (showTimeInput) {
    format += ' HH:mm';
  }

  const offset = React.useMemo(() => {
    return new Date().getTimezoneOffset();
  }, []);
  // NOTE(goro) react-datepicker cannot support time zone.
  // Then we need to calculate the offset of current time and local time

  const selected =
    value && value.isValid()
      ? value
          .clone()
          .add(value.utcOffset() + offset, 'minutes')
          .toDate()
      : null;

  return (
    <label className={clsx(styles['base-form-calendar'])}>
      <img src={calendarIcon} />
      <DatePicker
        customInput={<Input />}
        selected={selected}
        dateFormat={format}
        onChange={(date: Date, e: React.SyntheticEvent) => {
          e.preventDefault();
          onChange(
            moment.tz(
              moment(date).format('YYYY-MM-DD HH:mm'),
              value?.tz() ?? ''
            )
          );
        }}
        locale={locale || 'en'}
        showTimeInput={showTimeInput}
        popperPlacement={popperPlacement as any}
        disabled={disabled}
        todayButton={showTodayButton ? t('Today') : undefined}
      />
    </label>
  );
};
