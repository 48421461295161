import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { ToggleButton } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';

type FormValues = {
  shouldShowSeatSelectorOnBookingWidget: boolean;
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    shouldShowSeatSelectorOnBookingWidget:
      product?.seat_management_settings
        ?.should_show_seat_selector_on_booking_widget || false,
  };
};
const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    seat_management_settings: {
      should_show_seat_selector_on_booking_widget:
        values.shouldShowSeatSelectorOnBookingWidget,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const ProductSeatManagementSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Seat Management Settings')}
      initialValues={getInitialValues(product)}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="shouldShowSeatSelectorOnBookingWidget">
        {({ input }) => {
          return (
            <>
              <Modal.Box>
                <ToggleButton
                  {...input}
                  label={t('Show seat selector on booking widget')}
                />
              </Modal.Box>
            </>
          );
        }}
      </Field>
    </ProductEditorModal>
  );
};
