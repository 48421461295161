import * as React from 'react';

import { Heading } from './Heading';
import { Section } from './util';
import { BaseSection } from './BaseSection';

type Props = {
  data: Section;
};

export const Promotion = ({ data }: Props) => {
  const promotion = data.promotion;

  if (!promotion) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <>
        <Heading
          title={promotion?.title}
          description={promotion?.description}
          titleFontFamily={promotion?.titleStyle?.fontFamily}
          titleColor={promotion?.titleStyle?.color}
          descriptionFontFamily={promotion?.descriptionStyle?.fontFamily}
          descriptionColor={promotion?.descriptionStyle?.color}
        />
        {promotion.type === 'PROMOTION_CODE' &&
          promotion.style === 'SIMPLE' && (
            <>
              <tr>
                <td>
                  <div
                    style={{
                      backgroundColor:
                        promotion.promotionBackgroundStyle === 'SINGLE_COLOR'
                          ? promotion.promotionBackgroundColor
                          : undefined,
                      backgroundImage:
                        promotion.promotionBackgroundStyle === 'GRADATION'
                          ? promotion.promotionBackgroundGradient
                          : undefined,
                      padding: '16px',
                      borderRadius: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              <div
                                style={{
                                  width: '240px',
                                  padding: '2px 4px',
                                  border: '1px solid #FFF',
                                  textAlign: 'center',
                                  lineHeight: 1,
                                  display: 'inline-block',
                                }}
                              >
                                <img
                                  src="https://assets.ntmg.com/newsletter/gift-1.png"
                                  height={16}
                                  width={16}
                                  style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginRight: '4px',
                                  }}
                                />
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginTop: '2px',
                                  }}
                                >
                                  {promotion.promotionCodeTitle}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '48px',
                                  fontWeight: 'bold',
                                  color: '#FFF',
                                  letterSpacing: '-2px',
                                  fontFamily: 'Hiragino Sans',
                                }}
                              >
                                {promotion.promotionCodeDescription}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  paddingTop: '12px',
                                  paddingBottom: '12px',
                                  paddingLeft: '4px',
                                  paddingRight: '4px',
                                  lineHeight: '24px',
                                  width: '260px',
                                  display: 'inline-block',
                                  backgroundColor: '#FFF',
                                  borderRadius: '8px',
                                }}
                              >
                                {promotion.promotionCode}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      backgroundColor: '#F4F4F5',
                      padding: '16px',
                      borderRadius: '8px',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        marginBottom: '8px',
                        fontWeight: 'bold',
                      }}
                    >
                      {promotion.promotionTitle}
                    </p>
                    <p style={{ margin: 0, whiteSpace: 'pre-line' }}>
                      {promotion.promotionDescription}
                    </p>
                  </div>
                </td>
              </tr>
            </>
          )}
        {promotion.type === 'COUPON_WITH_TEXT' &&
          promotion.style === 'SIMPLE' && (
            <>
              <tr>
                <td>
                  <div
                    style={{
                      backgroundColor:
                        promotion.promotionBackgroundStyle === 'SINGLE_COLOR'
                          ? promotion.promotionBackgroundColor
                          : undefined,
                      backgroundImage:
                        promotion.promotionBackgroundStyle === 'GRADATION'
                          ? promotion.promotionBackgroundGradient
                          : undefined,
                      padding: '16px',
                      borderRadius: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                                verticalAlign: 'middle',
                              }}
                            >
                              <div
                                style={{
                                  width: '240px',
                                  padding: '2px 4px',
                                  border: '1px solid #FFF',
                                  display: 'inline-block',
                                }}
                              >
                                <img
                                  src="https://assets.ntmg.com/newsletter/gift-1.png"
                                  height={16}
                                  width={16}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '4px',
                                    marginTop: '4px',
                                  }}
                                />
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: '16px',
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                  }}
                                >
                                  {promotion.promotionCodeTitle}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                                textAlign: 'center',
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '48px',
                                  fontWeight: 'bold',
                                  color: '#FFF',
                                  letterSpacing: '-2px',
                                  fontFamily: 'Hiragino Sans',
                                }}
                              >
                                {promotion.promotionCodeDescription}
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                marginTop: '2em',
                                marginBottom: '2em',
                              }}
                            >
                              <a
                                href={promotion.couponButtonUrl}
                                target="_blank"
                                style={{
                                  display: 'inline-block',
                                  lineHeight: '48px',
                                  width: '240px',
                                  height: '48px',
                                  backgroundColor: '#FFF',
                                  color: '#3F3F46',
                                  borderRadius: '24px',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  textDecoration: 'none',
                                }}
                                rel="noreferrer"
                              >
                                {promotion.couponButtonText} &#10132;
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    style={{
                      backgroundColor: '#F4F4F5',
                      padding: '16px',
                      borderRadius: '8px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      {promotion.promotionDescription}
                    </p>
                  </div>
                </td>
              </tr>
            </>
          )}
        {promotion.type === 'COUPON_WITH_IMAGE' && (
          <tr>
            <td>
              <div
                style={{
                  backgroundColor: '#F4F4F5',
                  padding: '16px',
                  borderRadius: '8px',
                }}
              >
                <table style={{ width: '100%', marginBottom: '16px' }}>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src={promotion.couponImageUrl}
                          style={{
                            width: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          style={{
                            backgroundColor: '#FFF',
                            padding: '20px',
                            borderRadius: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {promotion.promotionDescription}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </>
    </BaseSection>
  );
};
