import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Modal } from 'client/components/Modal/Modal';
import { getLanguageName } from 'client/libraries/i18n';
import { updateOrganization } from 'client/actions/organizations';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import { Button, Input } from 'client/components/Form';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import type {
  SourceLanguage,
  ProductSummary,
  Organization$Patch,
  Translation,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  productGroupName: string;
  existingProductGroup: boolean;
};

export const ProductGroupCalendarEditModal = ({
  open,
  onClose,
  productGroupName,
  existingProductGroup,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const allProducts = useSelector(summariesSortedByBookmarkedSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const productsCalendarGroup =
    activeOrganization?.booking_widget_calendar_product_groups?.find(
      (g) => g.product_group_name == productGroupName
    );

  const [productsInGroup, setProductsInGroup] = React.useState<
    ProductSummary[]
  >(
    productsCalendarGroup?.product_ids
      ? productsCalendarGroup.product_ids
          .map((id) => {
            return allProducts.find((product) => product.id == id);
          })
          .filter(
            (item): item is Exclude<typeof item, undefined> =>
              item !== undefined
          )
      : []
  );
  const originalGroupName = productGroupName;
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');
  const [groupName, setGroupName] = React.useState<string>(productGroupName);
  const [translateGroupName, setTranslateGroupName] = React.useState<string>(
    (apiTranslations as any).find(
      (t: any) =>
        t?.source_language == defaultLanguage &&
        t?.[defaultLanguage.toLowerCase()] == productGroupName
    )?.[translationTargetLanguage.toLowerCase()] ?? ''
  );

  React.useEffect(() => {
    setTranslateGroupName(
      (apiTranslations as any).find(
        (t: any) =>
          t?.source_language == defaultLanguage &&
          t?.[defaultLanguage.toLowerCase()] == productGroupName
      )?.[translationTargetLanguage.toLowerCase()] ?? ''
    );
  }, [groupName, translationTargetLanguage, defaultLanguage]);

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    setProductsInGroup((productsInGroup: ProductSummary[]) => {
      const newProducts = [...productsInGroup];
      const insertItem = productsInGroup[dragIndex];
      newProducts.splice(dragIndex, 1);
      newProducts.splice(hoverIndex, 0, insertItem);
      return newProducts;
    });
  };

  const getProductCalendarGroup =
    (): Organization$Patch['booking_widget_calendar_product_groups'] => {
      let groupList =
        activeOrganization?.booking_widget_calendar_product_groups ?? [];
      if (existingProductGroup) {
        groupList = groupList.filter(
          (list) => list.product_group_name !== originalGroupName
        );
      }

      if (groupName === '') {
        return groupList;
      } else {
        groupList.push({
          product_group_name: groupName,
          product_ids: productsInGroup.map((p) => p?.id),
        });
        return groupList;
      }
    };
  React.useEffect(() => {
    if (showTranslationMode) {
      const productGroup =
        activeOrganization?.booking_widget_calendar_product_groups;
      if (productGroup) {
        const texts: string[] = [];
        productGroup.forEach((p) => {
          texts.push(p.product_group_name ?? '');
        });
        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeOrganization]);

  const [column, setColumn] = React.useState<'two' | undefined>(undefined);
  const changeShowTranslationMode = (show: boolean) => {
    setShowTranslationMode(show);
    show ? setColumn('two') : setColumn(undefined);
  };
  const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
    setTranslationTargetLanguage(lang);
  };

  return (
    <Modal
      title={t('Edit Product Group')}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <SettingTranslationLanguageSelector
            showTranslationMode={showTranslationMode}
            onShowTranslationModeChange={changeShowTranslationMode}
            translationTargetLanguage={translationTargetLanguage}
            onTranslationTargetLanguageChange={changeTranslationTargetLanguage}
          />
        </Modal.Box>
        <div className={clsx(baseStyles['base-form-box__header'])}>
          {t('Group Title')}
        </div>
        <Modal.Box column={column}>
          <Input
            label={column ? getLanguageName(i18n.language, t) : ''}
            value={groupName}
            onChange={(e, { value }) => setGroupName(value)}
          />
          {column && (
            <Input
              label={getLanguageName(translationTargetLanguage, t)}
              value={translateGroupName}
              onChange={(e, { value }) => setTranslateGroupName(value)}
            />
          )}
        </Modal.Box>
        <Modal.Box>
          <div className={baseStyles['base-form-box']}>
            <div className={baseStyles['base-form-box__header']}>
              {t('Products')}
            </div>
            {productsInGroup &&
              productsInGroup.map((product, idx) => (
                <>
                  <DraggableSelect
                    key={product?.id ?? ''}
                    value={product?.id ?? ''}
                    options={allProducts
                      .filter(
                        (p) =>
                          (p && p.id === product?.id) ||
                          !productsInGroup.includes(p)
                      )
                      .map((p) => ({
                        value: p?.id,
                        key: p?.id,
                        text: getVerboseDisplayProductName(p),
                      }))}
                    index={idx}
                    onValueChange={(value) => {
                      const newItem = allProducts.find(
                        (p: ProductSummary) => p.id === value
                      );
                      const pList: any = productsInGroup.map(
                        (p: ProductSummary, pidx) =>
                          pidx === idx ? newItem : p
                      );

                      setProductsInGroup(pList);
                    }}
                    moveItem={(dragIndex: number, hoverIndex: number) => {
                      moveItem(dragIndex, hoverIndex);
                    }}
                    deleteItem={() => {
                      const newProducts = [...productsInGroup];
                      newProducts.splice(idx, 1);
                      setProductsInGroup(newProducts);
                    }}
                  />
                </>
              ))}
          </div>
          {productsInGroup && (
            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  const newProducts: any = [...productsInGroup];
                  newProducts.splice(productsInGroup.length, 0, null);
                  setProductsInGroup(newProducts);
                }}
              >
                {t('Add New Product')}
              </Button>
            </div>
          )}
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          disabled={Boolean(groupName === '')}
          loading={loading}
          onClick={async () => {
            if (showTranslationMode) {
              await Promise.all([
                dispatch(
                  updateTranslations([
                    {
                      source_language: defaultLanguage,
                      [defaultLanguage.toLowerCase()]: groupName,
                      [translationTargetLanguage.toLowerCase()]:
                        translateGroupName,
                    },
                  ] as Translation[])
                ),
                dispatch(
                  updateOrganization(activeOrganization?.id || '', 'SUPPLIER', {
                    booking_widget_calendar_product_groups:
                      getProductCalendarGroup() || [{}],
                  })
                ),
              ]);
            } else {
              await dispatch(
                updateOrganization(activeOrganization?.id || '', 'SUPPLIER', {
                  booking_widget_calendar_product_groups:
                    getProductCalendarGroup() || [{}],
                })
              );
            }
            onClose();
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
