import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILURE,
  FETCH_PAGE_OF_REVIEWS_REQUEST,
  FETCH_PAGE_OF_REVIEWS_SUCCESS,
  FETCH_PAGE_OF_REVIEWS_FAILURE,
  FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST,
  FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS,
  FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE,
  FETCH_REVIEW_STATS_REQUEST,
  FETCH_REVIEW_STATS_SUCCESS,
  FETCH_REVIEW_STATS_FAILURE,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import type { ReduxState } from 'client/reducers';
import type {
  ReviewPatch,
  Review,
  ReviewStatus,
  ReviewRating,
  SourceLanguage,
  ReviewAttribution,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const updateReviewRequest = (id: string, patch: ReviewPatch) => ({
  type: UPDATE_REVIEW_REQUEST,
  id,
  patch,
});

const updateReviewSuccess = (id: string, patch: ReviewPatch) => ({
  type: UPDATE_REVIEW_SUCCESS,
  id,
  patch,
});

const updateReviewFailure = (id: string, patch: ReviewPatch) => ({
  type: UPDATE_REVIEW_FAILURE,
  id,
  patch,
});

export const updateReview =
  (id: string, patch: ReviewPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateReviewRequest(id, patch));
    return axios
      .patch(`/api/reviews/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => {
        dispatch(updateReviewSuccess(id, patch));
      })
      .catch((err) => {
        dispatch(updateReviewFailure(id, patch));
        throw err;
      });
  };

const reviewsRequest = () => ({
  type: FETCH_REVIEWS_REQUEST,
});

const reviewsSuccess = (payload: Review[]) => ({
  type: FETCH_REVIEWS_SUCCESS,
  reviews: payload,
});

const reviewsFailure = (payload: string) => ({
  type: FETCH_REVIEWS_FAILURE,
  error: payload,
});

// fetchReviews will clear any reviews that we have already loaded before fetching new ones
export const fetchReviews =
  (params?: {
    statuses?: ReviewStatus[];
    productIds?: string[];
    language?: string;
    ratings?: ReviewRating[];
    languagesFilter?: SourceLanguage[];
    attributions?: ReviewAttribution[];
    months?: number[];
    pageSize?: number;
  }) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
    dispatch(reviewsRequest());
    return axios
      .get('/api/reviews', {
        params: {
          statuses: params?.statuses,
          product_ids: params?.productIds,
          ratings: params?.ratings,
          languages: params?.languagesFilter,
          attributions: params?.attributions,
          months: params?.months,
          page_size: params?.pageSize,
        },
        headers: {
          ...getHTTPRequestHeaders(getState(), {
            language: params?.language,
          }),
        },
      })
      .then((response) => {
        dispatch(reviewsSuccess(response.data.reviews));
      })
      .catch((err) => {
        dispatch(reviewsFailure(err.message));
      });
  };

const fetchPageOfReviewsRequest = () => ({
  type: FETCH_PAGE_OF_REVIEWS_REQUEST,
});

const fetchPageOfReviewsSuccess = (payload: Review[]) => ({
  type: FETCH_PAGE_OF_REVIEWS_SUCCESS,
  reviews: payload,
});

const fetchPageOfReviewsFailure = (payload: string) => ({
  type: FETCH_PAGE_OF_REVIEWS_FAILURE,
  error: payload,
});

// fetchPageOfReviews will append any newly-fetched reviews to the existing list of reviews
export const fetchPageOfReviews =
  (params?: {
    statuses?: ReviewStatus[];
    productIds?: string[];
    language?: string;
    ratings?: ReviewRating[];
    languagesFilter?: SourceLanguage[];
    attributions?: ReviewAttribution[];
    months?: number[];
    offset?: number;
    pageSize?: number;
  }) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<void> => {
    dispatch(fetchPageOfReviewsRequest());
    return axios
      .get('/api/reviews', {
        params: {
          statuses: params?.statuses,
          product_ids: params?.productIds,
          ratings: params?.ratings,
          languages: params?.languagesFilter,
          attributions: params?.attributions,
          months: params?.months,
          offset: params?.offset,
          page_size: params?.pageSize,
        },
        headers: {
          ...getHTTPRequestHeaders(getState(), {
            language: params?.language,
          }),
        },
      })
      .then((response) => {
        dispatch(fetchPageOfReviewsSuccess(response.data.reviews));
      })
      .catch((err) => {
        dispatch(fetchPageOfReviewsFailure(err.message));
      });
  };

const fetchReviewsByReservationIDsRequest = (ids: string[]) => ({
  type: FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST,
  ids,
});

const fetchReviewsByReservationIDsSuccess = (responses: any) => ({
  type: FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS,
  responses,
});

const fetchReviewsByReservationIDsFailure = (error: any) => ({
  type: FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE,
  error,
});

export const fetchReviewsByReservationIDs =
  (ids: string[]) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(fetchReviewsByReservationIDsRequest(ids));
    const promises = ids.map((id) =>
      fetch(`/api/reviews?reservation_id=${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      }).then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
    );
    return Promise.all(promises)
      .then((responses) =>
        dispatch(fetchReviewsByReservationIDsSuccess(responses))
      )
      .catch((error) => dispatch(fetchReviewsByReservationIDsFailure(error)));
  };

const fetchReviewStatsRequest = () => ({
  type: FETCH_REVIEW_STATS_REQUEST,
});

const fetchReviewStatsSuccess = (response: any) => ({
  type: FETCH_REVIEW_STATS_SUCCESS,
  response,
});

const fetchReviewStatsFailure = (error: any) => ({
  type: FETCH_REVIEW_STATS_FAILURE,
  error,
});

export const fetchReviewStats =
  (params?: { statuses?: ReviewStatus[]; productIds?: string[] }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReviewStatsRequest());
    axios
      .get('/api/reviews/stats', {
        params: {
          statuses: params?.statuses,
          product_ids: params?.productIds,
        },
        headers: {
          ...getHTTPRequestHeaders(getState()),
        },
      })
      .then((response) => {
        dispatch(fetchReviewStatsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchReviewStatsFailure(err.message));
      });
  };
