import { BookingWidgetSettingsHeader } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/BookingWidgetSettingsHeader';
import { BookingWidgetSettingsBody } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/BookingWidgetSettingsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const BookingWidgetSettings = () => {
  return (
    <V3Page>
      <BookingWidgetSettingsHeader />
      <BookingWidgetSettingsBody />
    </V3Page>
  );
};
