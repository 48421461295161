import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';

type Props = {
  name?: string;
};
export const StartTimesInput = ({ name }: Props) => {
  const { t } = useTranslation();
  const translationTargetLanguage = useContext(TranslationLanguageContext);
  const namePrefix = name ? `${name}.` : '';

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <div style={{ width: '100%' }}>
      <FieldArray name={`${namePrefix}startTimes`}>
        {({ fields }) => {
          return (
            <>
              {fields.map((name) => {
                return (
                  <Field key={name} name={`${name}.time`}>
                    {({ input }) => (
                      <CollapsibleBox
                        title={`[${t('Start Time')} ${input.value}] - ${t(
                          'Supplemental Note'
                        )}`}
                      >
                        <TranslatedField name={`${name}.description`}>
                          {({ input, translationInput }) => (
                            <ul className={styles['p-list']}>
                              <li className={styles['p-list__item']}>
                                <div className={styles['p-list__item__ttl']}>
                                  <p>
                                    {getLanguageName(
                                      lowerCaseIsoSourceLanguage,
                                      t
                                    )}
                                  </p>
                                </div>
                                <div className={styles['p-list__item__body']}>
                                  <TextField {...input} />
                                </div>
                              </li>
                              {translationTargetLanguage && (
                                <li className={styles['p-list__item']}>
                                  <div className={styles['p-list__item__ttl']}>
                                    <p>
                                      {getLanguageName(
                                        uppercaseIsoToLowercaseIso[
                                          translationTargetLanguage
                                        ],
                                        t
                                      )}
                                    </p>
                                  </div>
                                  <div className={styles['p-list__item__body']}>
                                    <TextField {...translationInput} />
                                  </div>
                                </li>
                              )}
                            </ul>
                          )}
                        </TranslatedField>
                      </CollapsibleBox>
                    )}
                  </Field>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
