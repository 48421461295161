import type { Moment } from 'moment-timezone';

import * as Swagger from 'shared/models/swagger';

export type FormValues = {
  isPinned: boolean;
  dueDateTimeUtc: Moment;
  memo: string;
};

export const convertFormValuesToReservation = (
  formValues: FormValues,
  reservation: Swagger.Reservation
): Swagger.Reservation$Input => {
  return {
    ...(reservation as Swagger.Reservation$Input),
    pin_info: {
      is_pinned: formValues.isPinned,
      due_date_time_utc: formValues.dueDateTimeUtc.clone().utc().format(),
      memo: formValues.memo,
    },
  };
};
