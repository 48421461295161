import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { CalendarEditContext } from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import { Button } from 'client/components/v3/Common/Button';
import { batchEditProductInstances } from 'client/actions/productInstances';
import { CalendarEditModal } from 'client/pages/v3/Availability/Shared/Modal/CalendarEditModal';
import { CloseCalendarModal } from 'client/pages/v3/Availability/Shared/Modal/CloseCalendarModal';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { VisibilitySettingsModal } from 'client/pages/v3/Availability/Shared/Modal/VisibilitySettingsModal';

type Props = {
  view: 'calendar' | 'table';
};

export const CalendarEditButtonPopup = ({ view }: Props) => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const {
    calendarEditModeIsActive,
    setCalendarEditModeIsActive,
    selectedProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const [showDateRangeCloseCalendarModal, setShowDateRangeCloseCalendarModal] =
    useState(false);
  const [showCloseCalendarModal, setShowCloseCalendarModal] = useState(false);
  const [showRequestCalendarModal, setShowRequestCalendarModal] =
    useState(false);
  const [showOpenCalendarModal, setShowOpenCalendarModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const reset = useCallback(() => {
    setCalendarEditModeIsActive(false);
    deselectProductInstanceIds(...selectedProductInstanceIds);
  }, [
    selectedProductInstanceIds,
    deselectProductInstanceIds,
    setCalendarEditModeIsActive,
  ]);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <fieldset
      ref={fieldSetRef}
      className={commonStyles['p-availability__select']}
    >
      <Button
        uiType="bg"
        size="icon"
        color="white"
        iconBeforeText={<i className="c-icon-solid-general-dots-vertical"></i>}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      />
      <ul
        className={clsx(
          commonStyles['p-availability__select__menu'],
          showMenu && commonStyles['is-active']
        )}
      >
        <li
          className={commonStyles['p-availability__select__menu__item']}
          onClick={async () => {
            await reset();
            await setCalendarEditModeIsActive(true);
            await setShowMenu(false);
          }}
        >
          <p>{t('Select Slots and Edit')}</p>
        </li>
        <li
          className={commonStyles['p-availability__select__menu__item']}
          onClick={() => {
            setShowDateRangeCloseCalendarModal(true);
            setShowMenu(false);
          }}
        >
          <p>{t('Close Calendars')}</p>
        </li>
        {view === 'calendar' && (
          <li
            className={commonStyles['p-availability__select__menu__item']}
            onClick={() => {
              setShowSettingsModal(true);
              setShowMenu(false);
            }}
          >
            <p>{t('Visibility Settings')}</p>
          </li>
        )}
      </ul>

      {showDateRangeCloseCalendarModal && (
        <CloseCalendarModal
          open={showDateRangeCloseCalendarModal}
          onClose={() => setShowDateRangeCloseCalendarModal(false)}
        />
      )}
      {showCloseCalendarModal && (
        <CalendarEditModal
          open={showCloseCalendarModal}
          title={t('Close calendar')}
          message={t('Close calendar for the selected availability slots.')}
          onSave={async (memo: string) => {
            await dispatch(
              batchEditProductInstances(
                selectedProductInstanceIds,
                'CLOSE',
                memo
              )
            );
            reset();
          }}
          onClose={() => setShowCloseCalendarModal(false)}
        />
      )}
      {showRequestCalendarModal && (
        <CalendarEditModal
          open={showRequestCalendarModal}
          title={t('Change to request booking')}
          message={t(
            'Change to request booking for the selected availability slots.'
          )}
          onSave={async (memo: string) => {
            await dispatch(
              batchEditProductInstances(
                selectedProductInstanceIds,
                'REQUEST',
                memo
              )
            );
            reset();
          }}
          onClose={() => setShowRequestCalendarModal(false)}
        />
      )}
      {showOpenCalendarModal && (
        <CalendarEditModal
          open={showOpenCalendarModal}
          title={t('Open calendar')}
          message={t('Open calendar for the selected availability slots.')}
          onSave={async (memo: string) => {
            await dispatch(
              batchEditProductInstances(
                selectedProductInstanceIds,
                'OPEN',
                memo
              )
            );
            reset();
          }}
          onClose={() => setShowOpenCalendarModal(false)}
        />
      )}
      {showSettingsModal && (
        <VisibilitySettingsModal
          open={showSettingsModal}
          title={t('Visibility Settings')}
          onSave={() => {
            reset();
          }}
          onClose={() => setShowSettingsModal(false)}
        />
      )}

      {/* Fixed footer, displayed when 'Select Slots and Edit' is clicked */}
      <div
        className={clsx(
          commonStyles['p-availability__fixed'],
          calendarEditModeIsActive && commonStyles['is-active']
        )}
      >
        <div className={commonStyles['p-availability__fixed__main']}></div>
        <div className={clsx(commonStyles['p-availability__fixed__actions'])}>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={() => {
              reset();
            }}
          />
          <Button
            text={t('Close calendar')}
            size="md"
            color="primary"
            onClick={() => setShowCloseCalendarModal(true)}
            disabled={selectedProductInstanceIds.length === 0}
          />
          <Button
            text={t('Change to Request')}
            size="md"
            color="primary"
            onClick={() => setShowRequestCalendarModal(true)}
            disabled={selectedProductInstanceIds.length === 0}
          />
          <Button
            text={t('Open calendar')}
            size="md"
            color="primary"
            onClick={() => setShowOpenCalendarModal(true)}
            disabled={selectedProductInstanceIds.length === 0}
          />
        </div>
      </div>
    </fieldset>
  );
};
