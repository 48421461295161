import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  activeUserOrganizationSelector,
  activeUserSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { getRoutes, getPageCategories } from 'client/routes';
import { ReservationCreateModalButton } from 'client/components/ReservationCreateModal/ReservationCreateModalButton';
import { Button } from 'client/components/v3/Common/Button';
import { config } from 'client/config';

import arrowIcon from '../../images/ic_arrow.svg';
import styles from '../../base.module.css';

type Props = {
  isExpanded: boolean;
  onIsExpandedChange: (arg0: boolean) => void;
  mobileNavIsOpen: boolean;
};
export const NavigationMenu = React.forwardRef<Props, any>((props, ref) => {
  const { isExpanded, onIsExpandedChange, mobileNavIsOpen } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const activeUser = useSelector(activeUserSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );
  const routes = getRoutes(
    t,
    activeUser,
    activeOrganization,
    loggedInAccountIsNutmegAdmin
  );
  const pageCategories = getPageCategories(t, activeUser, activeOrganization);
  // mobile bottom navbar will be hidden when tutorial is active
  const isTutorialActive = location.pathname.includes('/home/tutorial');
  return (
    <>
      <nav
        id="navigationMenu"
        className={clsx(
          styles['base-nav'],
          isExpanded ? styles['is-open'] : styles['is-close'],
          mobileNavIsOpen && styles['is-active']
        )}
      >
        <ul className={styles['base-nav__menu']} ref={ref as any}>
          {pageCategories.map((cat, idx) => {
            const categoryRoutes = routes.filter(
              (route) =>
                route.category === cat.category && route.navigationTitle
            );

            if (categoryRoutes.length === 0) {
              return null;
            }

            return (
              <>
                {cat.category != 'NEW_RESERVATIONS' && (
                  <li
                    key={idx}
                    className={clsx(
                      styles['base-nav__menu__item'],
                      location.pathname.startsWith(cat.basePath) &&
                        styles['is-active']
                    )}
                  >
                    <Link to={categoryRoutes[0].route}>
                      {cat.navIcon && <img src={cat.navIcon} />}
                      <p>{cat.title}</p>
                    </Link>
                    {categoryRoutes.length > 1 && isExpanded && (
                      <ul className={styles['base-nav__menu__item__children']}>
                        {categoryRoutes.map((route) => (
                          <li key={route.route}>
                            <Link to={route.route}>
                              {route.navigationTitle}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}

                {cat.category == 'NEW_RESERVATIONS' && isExpanded && (
                  <>
                    <li
                      key={idx}
                      className={clsx(
                        styles['base-nav__menu__item'],
                        styles['button_link']
                      )}
                    >
                      {/* TODO: remove this config flag when deploying to prod */}
                      {config.enableUIRevamp && (
                        <Link
                          // TODO: remove v3 from url later
                          to={{
                            pathname: `/reservations/new-v3`,
                            // Use state to persist to location
                            state: {
                              isFromNewReservationModal: true,
                            },
                          }}
                          target="_blank"
                        >
                          <Button
                            text={cat.title}
                            size="lg"
                            useFullWidth={true}
                          />
                        </Link>
                      )}
                      {/* TODO: remove this old button when UIUX revamp is released */}
                      {!config.enableUIRevamp && (
                        <div>
                          <ReservationCreateModalButton
                            trigger={
                              <button
                                className={clsx(
                                  styles['base-btn'],
                                  styles['middle'],
                                  styles['green'],
                                  i18n.language == 'en' && styles['flex']
                                )}
                              >
                                <p>
                                  {cat.navIcon && <img src={cat.navIcon} />}
                                  {cat.title}
                                </p>
                              </button>
                            }
                          />
                        </div>
                      )}
                    </li>
                    <li>&nbsp;</li>
                  </>
                )}
              </>
            );
          })}
        </ul>
        <a
          className={styles['base-nav__changeWidth']}
          onClick={() => onIsExpandedChange(!isExpanded)}
        >
          <p>
            <img src={arrowIcon} />
          </p>
        </a>
      </nav>
      {!isTutorialActive && (
        <nav className={styles['base-spNav']}>
          <ul>
            {pageCategories.map((cat) => {
              if (!cat.navIconMobile) {
                return null;
              }

              const categoryRoutes = routes.filter(
                (route) =>
                  route.category === cat.category && route.navigationTitle
              );

              if (categoryRoutes.length === 0) {
                return null;
              }

              return (
                <>
                  {cat.category != 'NEW_RESERVATIONS' && (
                    <li
                      key={cat.basePath}
                      className={clsx(
                        location.pathname.includes(cat.basePath) &&
                          styles['is-active']
                      )}
                    >
                      <Link to={categoryRoutes[0].route}>
                        <img src={cat.navIconMobile} />
                      </Link>
                    </li>
                  )}

                  {cat.category == 'NEW_RESERVATIONS' && (
                    <li key={cat.basePath}>
                      <ReservationCreateModalButton
                        trigger={
                          <a type="button">
                            <img src={cat.navIconMobile} />
                          </a>
                        }
                      />
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </nav>
      )}
    </>
  );
});
