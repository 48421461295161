import {
  convertToRelativeDeadline,
  convertToSwaggerRelativeDateTime,
  RelativeDeadline,
} from 'client/libraries/util/productShape';
import {
  AutomaticAvailabilityAdjustmentConditionOperator,
  AutomaticAvailabilityAdjustmentValueType,
  AutomaticAvailabilityAdjustmentActionType,
  Product,
  Product$Patch,
  AvailabilityChannelCategory,
} from 'shared/models/swagger';

export interface FormValues {
  schedules: AvailabilityAdjustmentSchedule[];
}

export interface AvailabilityAdjustmentSchedule {
  startDate: string;
  endDate: string;
  appliesFromParticipationRelativeDate?: RelativeDeadline;
  appliesUntilParticipationRelativeDate?: RelativeDeadline;
  rules: AvailabilityAdjustmentRule[];
}

export interface AvailabilityAdjustmentRule {
  conditions: AvailabilityAdjustmentCondition[];
  actions: AvailabilityAdjustmentAction[];
}

export interface AvailabilityAdjustmentCondition {
  valueType: AutomaticAvailabilityAdjustmentValueType;
  operator: AutomaticAvailabilityAdjustmentConditionOperator;
  value: number;
  appliesTo: 'TOTAL' | 'CHANNEL';
  channel?: Channel;
}

export interface Channel {
  agentId: string;
  channelCategory: AvailabilityChannelCategory;
}

export interface AvailabilityAdjustmentAction {
  actionType: AutomaticAvailabilityAdjustmentActionType;
  amount: number;
  channels: Channel[];
  fromChannel?: Channel;
  toChannel?: Channel;
}

export const getInitialValues = (product: Product | null): FormValues => {
  return {
    schedules:
      product?.automatic_availability_adjustment_settings?.schedules?.map(
        (schedule) => ({
          startDate: schedule.start_date ?? '',
          endDate: schedule.end_date ?? '',
          appliesFromParticipationRelativeDate:
            schedule.applies_from_participation_relative_date_time
              ? convertToRelativeDeadline(
                  schedule.applies_from_participation_relative_date_time
                )
              : undefined,
          appliesUntilParticipationRelativeDate:
            schedule.applies_until_participation_relative_date_time
              ? convertToRelativeDeadline(
                  schedule.applies_until_participation_relative_date_time
                )
              : undefined,
          rules:
            schedule.rules?.map((rule) => ({
              conditions:
                rule.conditions?.map((condition) => ({
                  valueType: condition.value_type ?? 'OCCUPANCY_COUNT',
                  operator: condition.operator ?? 'LTE',
                  value: condition.value ?? 0,
                  appliesTo: condition.applies_to ?? 'TOTAL',
                  channel:
                    condition.applies_to === 'CHANNEL'
                      ? {
                          agentId: condition.channel?.agent_id ?? '',
                          channelCategory:
                            condition.channel?.channel_category ?? 'DIRECT_ALL',
                        }
                      : undefined,
                })) ?? [],
              actions:
                rule.actions?.map((action) => ({
                  actionType: action.action_type ?? 'CLOSE_CHANNEL',
                  amount: action.amount ?? 0,
                  channels:
                    action.channels?.map((channel) => ({
                      agentId: channel.agent_id ?? '',
                      channelCategory: channel.channel_category ?? 'DIRECT_ALL',
                    })) ?? [],
                  fromChannel: action.from_channel
                    ? {
                        agentId: action.from_channel.agent_id ?? '',
                        channelCategory:
                          action.from_channel.channel_category ?? 'DIRECT_ALL',
                      }
                    : undefined,
                  toChannel: action.to_channel
                    ? {
                        agentId: action.to_channel.agent_id ?? '',
                        channelCategory:
                          action.to_channel.channel_category ?? 'DIRECT_ALL',
                      }
                    : undefined,
                })) ?? [],
            })) ?? [],
        })
      ) ?? [],
  };
};

export const convertFormValuesToProductPatch = (
  formValues: FormValues
): Product$Patch => {
  return {
    automatic_availability_adjustment_settings: {
      schedules: formValues.schedules.map((schedule) => ({
        start_date: schedule.startDate,
        end_date: schedule.endDate,
        applies_from_participation_relative_date_time:
          schedule.appliesFromParticipationRelativeDate
            ? convertToSwaggerRelativeDateTime(
                schedule.appliesFromParticipationRelativeDate
              )
            : undefined,
        applies_until_participation_relative_date_time:
          schedule.appliesUntilParticipationRelativeDate
            ? convertToSwaggerRelativeDateTime(
                schedule.appliesUntilParticipationRelativeDate
              )
            : undefined,
        rules: schedule.rules.map((rule) => ({
          conditions: rule.conditions.map((condition) => ({
            value_type: condition.valueType,
            operator: condition.operator,
            value: condition.value,
            applies_to: condition.appliesTo,
            channel:
              condition.appliesTo === 'CHANNEL'
                ? {
                    agent_id: condition.channel?.agentId,
                    channel_category: condition.channel?.channelCategory,
                  }
                : undefined,
          })),
          actions: rule.actions.map((action) => ({
            action_type: action.actionType,
            amount: action.amount,
            channels: action.channels.map((channel) => ({
              agent_id: channel.agentId,
              channel_category: channel.channelCategory,
            })),
            from_channel: action.fromChannel
              ? {
                  agent_id: action.fromChannel.agentId,
                  channel_category: action.fromChannel.channelCategory,
                }
              : undefined,
            to_channel: action.toChannel
              ? {
                  agent_id: action.toChannel.agentId,
                  channel_category: action.toChannel.channelCategory,
                }
              : undefined,
          })),
        })),
      })),
    },
  };
};
