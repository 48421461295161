import * as React from 'react';
import clsx from 'clsx';
import { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import type { TranslateFuncType } from 'client/components/Translate';
import { Box } from 'client/components/Box/Box';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Input, Select } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import type { Product } from 'shared/models/swagger';
import { Add as AddIcon } from 'client/components/Icons/Add';

import styles from './FootprintedModalDisplayKeySelector.module.css';

interface FootprintedModalDisplayKeySelectorProps {
  name: string;
  translationTargetLanguage: string | null;
}

export const FootprintedModalDisplayKeySelector = (
  props: FootprintedModalDisplayKeySelectorProps
) => {
  const { t } = useTranslation();
  const { name, translationTargetLanguage } = props;
  return (
    <td colSpan={translationTargetLanguage ? 2 : 1}>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            <div className={styles['c-table-list']}>
              <Box mb={2}>
                <AddIcon
                  onClick={() => {
                    fields.push({});
                  }}
                />
              </Box>
              <table>
                <tbody style={{ width: '100%' }}>
                  {translationTargetLanguage && (
                    <TranslationTableHeader hideThTag={true} />
                  )}
                  {fields.map((name, idx) => (
                    <DraggableItemInputForm
                      key={idx}
                      idx={idx}
                      name={name}
                      translationTargetLanguage={translationTargetLanguage}
                      onRemoveClick={() => {
                        fields.remove(idx);
                      }}
                      onMoveItem={(dragIndex: number, hoverIndex: number) => {
                        const dragItem = fields.value[dragIndex];
                        const newItems = [...fields.value];
                        newItems.splice(dragIndex, 1);
                        newItems.splice(hoverIndex, 0, dragItem);
                        newItems.forEach((item, index) => {
                          fields.update(index, item);
                        });
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <span className={styles['c-table-list__comment']}>
              {t('Drag-and-drop to reorder')}
            </span>
          </>
        )}
      </FieldArray>
    </td>
  );
};

interface DragItem {
  index: number;
  type: string;
}

type DraggableItemInputFormProps = {
  name: string;
  idx: number;
  translationTargetLanguage: string | null;
  onRemoveClick: () => void;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
};

const itemType = 'selectable-footprinted-modal-display-item';

export const DraggableItemInputForm = ({
  name,
  idx,
  translationTargetLanguage,
  onRemoveClick,
  onMoveItem,
}: DraggableItemInputFormProps) => {
  const ref = useRef<HTMLTableRowElement | null>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, DragItem, { handlerId: any }>(
    {
      accept: itemType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = idx;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        onMoveItem(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }
  );

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: { type: itemType, index: idx },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <ItemInputForm
        name={name}
        translationTargetLanguage={translationTargetLanguage}
        onRemoveClick={onRemoveClick}
      />
    </tr>
  );
};

interface ItemInputFormProps {
  name: string;
  translationTargetLanguage: string | null;
  onRemoveClick: () => void;
}

export const ItemInputForm = ({
  name,
  translationTargetLanguage,
  onRemoveClick,
}: ItemInputFormProps) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  return (
    <TranslatedField name={`${name}.title`}>
      {({ input, translationInput }) => (
        <>
          <td>
            <div
              className={clsx(
                baseStyles['base-flex'],
                styles['input'],
                translationTargetLanguage
                  ? styles['input__source_language']
                  : null
              )}
            >
              <Input {...input} />
            </div>
          </td>
          {translationTargetLanguage && (
            <td>
              <div
                className={clsx(
                  styles['input'],
                  translationTargetLanguage
                    ? styles['input__translation_language']
                    : null
                )}
              >
                <Input {...translationInput} />
              </div>
            </td>
          )}
          <td>
            <Field name={`${name}.key`}>
              {({ input }) => (
                <Select
                  value={input.value}
                  options={getItemFieldOptions(product, t)}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          </td>
          <td style={{ width: '45px' }}>
            <div className={baseStyles['base-flex']}>
              <DeleteIcon onClick={() => onRemoveClick()} />
            </div>
          </td>
        </>
      )}
    </TranslatedField>
  );
};

const getItemFieldOptions = (product: Product | null, t: TranslateFuncType) => {
  const options =
    product?.reservation_form_fields?.map((field) => ({
      value: field.key || '',
      text: field.prompt || '',
    })) ?? [];

  return [
    ...options,
    {
      value: 'supplier_reference',
      text: t('Confirmation Number'),
    },
  ];
};
