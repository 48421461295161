import { v4 as uuid } from 'uuid';

import { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

export type SurveyType = 'NORMAL' | 'NPS';
export interface FormValues {
  productIds: string[];
  templateName: string;
  status: Swagger.SurveyTemplate['status'];
  title: string;
  pages: SurveyPage[];
  sourceLanguage: 'EN_US' | 'JA_JP';
  translatedLanguages: ('EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW')[];
  showTranslationMode: boolean;
  translationTargetLanguage:
    | 'EN_US'
    | 'JA_JP'
    | 'KO_KR'
    | 'ZH_CN'
    | 'ZH_TW'
    | null;
  translations: {
    source: string;
    target: string;
  }[];
  surveyType: SurveyType;
}

export interface SurveyPage {
  presetConditions: Swagger.SurveyTemplatePresetCondition[];
  customConditions?: SurveyCustomCondition[];
  title: string;
  target: string[];
  key: string;
  surveyQuestions?: SurveyQuestion[];
}

export interface SurveyCustomCondition {
  customQuestionKey: string;
  customQuestionResponses: string[];
}

export interface SurveyQuestion {
  preset: string;
  text: string;
  key: string;
  responseType: Swagger.SurveyQuestionResponseType;
  options: SurveyQuestionOption[];
  format: string;
  required: boolean;
}

export interface SurveyQuestionOption {
  key: string;
  text: string;
}

export const getInitialValues = (
  surveyTemplate?: Swagger.SurveyTemplate
): FormValues => {
  return {
    productIds: surveyTemplate?.product_ids ?? [],
    templateName: surveyTemplate?.template_name ?? '',
    status: surveyTemplate?.status ?? 'SURVEY_TEMPLATE_UNPUBLISHED',
    title: surveyTemplate?.title ?? '',
    pages:
      surveyTemplate?.pages?.map((page) =>
        convertSwaggerSurveyPageToFormValuePage(page)
      ) ?? [],
    sourceLanguage: (surveyTemplate?.source_language as any) || 'EN_US',
    translatedLanguages: surveyTemplate?.translated_languages ?? [],
    showTranslationMode: false,
    translationTargetLanguage: null,
    translations: [],
    surveyType: surveyTemplate?.is_nps_survey
      ? surveyTemplate?.is_nps_survey.value
        ? 'NPS'
        : 'NORMAL'
      : 'NORMAL',
  };
};

interface PresetOption {
  text: string;
  value: string;
  responseType: Swagger.SurveyQuestionResponseType;
  format?: string;
  choices?: SurveyQuestionOption[];
}

export const getQuestionPresetOptions = (
  t: TranslateFuncType
): PresetOption[] => {
  return [
    {
      text: t('Date of Birth'),
      value: 'date_of_birth',
      responseType: 'SURVEY_QUESTION_TEXT',
      format: 'yyyy-mm-dd',
    },
    {
      text: t('Age'),
      value: 'age',
      responseType: 'SURVEY_QUESTION_TEXT',
      format: 'non-negative-integer',
    },
    {
      text: t('Gender'),
      value: 'gender',
      responseType: 'SURVEY_QUESTION_SELECT_ONE',
      choices: [
        {
          key: 'male',
          text: t('Male'),
        },
        {
          key: 'female',
          text: t('Female'),
        },
        {
          key: 'other',
          text: t('No Response'),
        },
      ],
    },
    {
      text: t('Home Address'),
      value: 'home_address',
      responseType: 'SURVEY_QUESTION_TEXT',
    },
  ];
};

export const getSurveyStatusText = (
  status: Swagger.SurveyTemplate['status'],
  t: TranslateFuncType
) => {
  return status === 'SURVEY_TEMPLATE_PUBLISHED'
    ? t('Published')
    : t('Unpublished');
};

const getPresetOptionKeys = () => {
  const presetOptions = getQuestionPresetOptions((s: string) => s);
  return presetOptions.map((opt) => opt.value);
};

export const convertSwaggerSurveyPageToFormValuePage = (
  page: Swagger.SurveyTemplatePage
): SurveyPage => {
  return {
    title: page.title ?? '',
    presetConditions: page.preset_conditions ?? [],
    customConditions:
      page.custom_conditions?.map((condition) => ({
        customQuestionKey: condition.custom_question_key ?? '',
        customQuestionResponses: condition.custom_question_responses ?? [],
      })) ?? [],
    target: [],
    key: uuid(),
    surveyQuestions:
      page.questions?.map((question) =>
        convertSwaggerSurveyQuestionToFormValueQuestion(question)
      ) ?? [],
  };
};

const convertSwaggerSurveyQuestionToFormValueQuestion = (
  question: Swagger.SurveyQuestion
): SurveyQuestion => {
  const presetKeys = getPresetOptionKeys();

  const preset =
    question.key && presetKeys.includes(question.key) ? question.key : 'custom';

  return {
    preset,
    text: question.question_text ?? '',
    key: question.key ?? '',
    responseType: question.response_type as Swagger.SurveyQuestionResponseType,
    options:
      question.response_options?.map((opt) => ({
        key: opt.key ?? '',
        text: opt.text ?? '',
      })) ?? [],
    format: question.format ?? '',
    required: question.required ?? false,
  };
};

export const convertFormValuesToSwaggerSurveyTemplate = (
  formValues: FormValues
): Swagger.SurveyTemplateParams => {
  return {
    title: formValues.title,
    template_name: formValues.templateName,
    product_ids: formValues.productIds,
    pages: formValues.pages?.map((page) =>
      convertFormValuePageToSwaggerSurveyPage(page)
    ),
    source_language: formValues.sourceLanguage,
    translated_languages: formValues.translatedLanguages,
    status: formValues.status,
    is_nps_survey:
      formValues.surveyType === 'NORMAL' ? { value: false } : { value: true },
  };
};

const convertFormValuePageToSwaggerSurveyPage = (
  page: SurveyPage
): Swagger.SurveyTemplatePage => {
  return {
    title: page.title,
    preset_conditions: page.presetConditions,
    custom_conditions:
      page.customConditions?.map((condition) => ({
        custom_question_key: condition.customQuestionKey,
        custom_question_responses: condition.customQuestionResponses,
      })) ?? [],
    questions: page.surveyQuestions?.map((question) =>
      convertFormValueQuestionToSwaggerSurveyQuestion(question)
    ),
  };
};

export const convertFormValueQuestionToSwaggerSurveyQuestion = (
  question: SurveyQuestion
): Swagger.SurveyQuestion => {
  return {
    question_text: question.text,
    key: question.key,
    response_type: question.responseType,
    response_options: question.options,
    format: question.format,
    required: question.required,
  };
};

export const createSurveyPageForNpsSurvey = (
  t: TranslateFuncType
): SurveyPage[] => {
  return [
    {
      title: '',
      presetConditions: [],
      customConditions: [],
      target: [],
      key: uuid(),
      surveyQuestions: [
        {
          preset: '',
          text: t(
            'Would you recommend our service to a close friend or acquaintance?'
          ),
          key: '1',
          responseType: 'SURVEY_QUESTION_STAR_RATING_NPS',
          options: [],
          format: '',
          required: true,
        },
        {
          preset: '',
          text: t(
            'Please select the factors that influenced your score above (can select multiple)'
          ),
          key: '2',
          responseType: 'SURVEY_QUESTION_SELECT_MULTIPLE',
          options: [],
          format: '',
          required: true,
        },
        {
          preset: '',
          text: t('Please tell us why you chose the above score'),
          key: '3',
          responseType: 'SURVEY_QUESTION_TEXT_AREA',
          options: [],
          format: '',
          required: false,
        },
      ],
    },
  ];
};
