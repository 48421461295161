import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import { EditExcludedFormFieldsModal } from 'client/pages/v3/Manifest/ManifestCustomize/Modal/EditExcludedFormFieldsModal';
import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

interface Props {
  isActive?: boolean;
}

export const ExcludedFormFieldList = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const commonFormFieldsByKey = getCommonFormFieldsByKey(t);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const excludedFormFieldKeys = useSelector((state: ReduxState) =>
    manifestExcludedFormFieldsSelector(state)
  );

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Filter Required Info')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          <Button
            text={t('Edit')}
            onClick={() => {
              setShowEditModal(true);
            }}
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-edit-05"></i>}
          />
        </div>
      </div>

      <EditExcludedFormFieldsModal
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
      />

      <table
        className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        style={{ overflow: 'hidden' }}
      >
        <thead>
          <tr>
            <th>{t('Items not displayed in the manifest')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              className={clsx(
                tableStyles['c-tableSmall__spTtl'],
                baseStyles['u-pcHidden']
              )}
            >
              {t('Items not displayed in the manifest')}
            </td>
            <td style={{ textAlign: 'left' }}>
              {excludedFormFieldKeys.length === 0
                ? t('(none)')
                : excludedFormFieldKeys.map((key) => {
                    const fieldText =
                      commonFormFieldsByKey[key ?? '']?.text ?? key;
                    return (
                      <React.Fragment key={key}>
                        {fieldText}
                        <br />
                      </React.Fragment>
                    );
                  })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
