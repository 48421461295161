import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Select } from 'client/components/Form';
import { equipmentsSelector } from 'client/reducers/equipments';
import { summariesSelector } from 'client/reducers/products';
import type { TranslateFuncType } from 'client/components/Translate';
import type { Equipment } from 'shared/models/swagger';

const getScheduleSelectOptions = (
  equipment: Equipment | null,
  t: TranslateFuncType
) => {
  const options: { value: string; text: string }[] = [];
  (equipment?.equipment_schedules ?? []).forEach((schedule, idx) => {
    options.push({
      value: String(idx),
      text: getOptionText(
        schedule?.start_date_local ?? null,
        schedule?.end_date_local ?? null,
        t
      ),
    });
  });

  return options;
};

const getOptionText = (
  startDateLocal: string | null,
  endDateLocal: string | null,
  t: TranslateFuncType
) => {
  if (startDateLocal && endDateLocal) {
    return `${startDateLocal} ~ ${endDateLocal}`;
  }
  if (startDateLocal) {
    return `${startDateLocal} ~ ${t('Unknown')}`;
  }
  if (endDateLocal) {
    return `${t('Unknown')} ~ ${endDateLocal}`;
  }

  return '~';
};

interface Props {
  equipmentId: string;
}

export const EquipmentScheduleDisplayBox = ({ equipmentId }: Props) => {
  const { t } = useTranslation();
  const equipments = useSelector(equipmentsSelector);
  const productSummaries = useSelector(summariesSelector);

  const selectedEquipment = equipments.find((equipment) => {
    return equipment.id === equipmentId;
  });

  const [scheduleIdx, setActiveScheduleIndex] = React.useState<string>('0');
  const [scheduleSettings, setScheduleSettings] = React.useState<string[]>([]);

  const options = getScheduleSelectOptions(selectedEquipment ?? null, t);

  const getDisplayText = (scheduleIdx: string) => {
    const schedule =
      selectedEquipment?.equipment_schedules?.[Number(scheduleIdx)];

    const lines = schedule?.start_time_mappings?.map((mapping) => {
      const productStartTimes = mapping?.product_start_times?.map(
        (productStartTime) => {
          const productSummary = productSummaries.find((product) => {
            return product.id === productStartTime.product_id;
          });

          const recurrence = productSummary?.recurrence?.find((recurrence) => {
            return recurrence.key === productStartTime.recurrence_key;
          });

          const startTime = recurrence?.start_times?.find((startTime) => {
            return startTime.time_slot_key === productStartTime.time_slot_key;
          });

          return `${productSummary?.product_name ?? ''} ${getOptionText(
            recurrence?.start_date_local ?? '',
            recurrence?.end_date_local ?? '',
            t
          )} ${recurrence?.days_of_week} ${startTime?.start_time_local ?? ''}`;
        }
      );

      return `${mapping.start_time_local ?? ''} ${(
        productStartTimes || []
      ).join(', ')}`;
    });
    return lines;
  };

  React.useEffect(() => {
    const lines = getDisplayText(scheduleIdx);
    setScheduleSettings(lines ?? []);
  }, [scheduleIdx, equipments, productSummaries]);

  if (!equipments || !productSummaries) {
    return null;
  }

  if (options.length === 0) {
    return <>{t('No settings')}</>;
  }

  return (
    <>
      <Select
        options={options}
        value={scheduleIdx}
        onChange={(_, { value }) => setActiveScheduleIndex(value)}
      />
      <ul>
        {scheduleSettings.map((f, idx) => (
          <li key={idx}>{f}</li>
        ))}
      </ul>
    </>
  );
};
