import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';

import styles from './DateDetails.module.css';
import { SessionSelectionContext } from './SessionSelectionContext';
import { SessionListItem } from './SessionListItem';
import { formattedDate } from './util';
import { useSessions } from './useSessions';

export const DateDetails = () => {
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const { selectedDate } = React.useContext(SessionSelectionContext);

  const sessions = useSessions();

  if (!selectedDate) {
    return null;
  }

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['header']}>
          {formattedDate(selectedDate, locale)}
        </div>
        {sessions
          ?.filter(
            (session) =>
              moment(session.start_date_time_utc).format('YYYY-MM-DD') ===
              selectedDate
          )
          .map((session, idx) => (
            <SessionListItem key={idx} session={session} />
          ))}
      </div>
    </>
  );
};
