import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';

import type { CustomerReportDataKeyCount } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

import styles from './AgesGadget.module.css';

const coloers: { [index: string]: string } = {
  MALE: '#3b82f6',
  FEMALE: '#ec4899',
  UNKNOWN: '#93c5fd',
  NO_RESPONSE: '#93c5fd',
};

const getTitle = (key: string, t: TFunction) => {
  switch (key) {
    case 'MALE':
      return t('Male');
    case 'FEMALE':
      return t('Female');
    case 'NO_RESPONSE':
      return t('No response');
    case 'UNKNOWN':
    default:
      return t('Unknown');
  }
};

const convertToPercentage = (
  counts: CustomerReportDataKeyCount[],
  t: TFunction
): { name: string; value: number; color: string }[] => {
  return counts
    .sort((a, b) => (b.count ?? 0) - (a.count ?? 0))
    .map((count) => {
      return {
        name: getTitle(count.key ?? '', t),
        value: Number(count.count ?? 0),
        color: coloers[count.key ?? 'UNKNOWN'] ?? '#000',
      };
    });
};

export const GendersGadget = () => {
  const { t } = useTranslation();

  const reportData = useSelector(
    (state: ReduxState) => state.customers.reportData
  );

  console.log(reportData);

  const customerCounts = reportData?.[0]?.totals?.customer_count ?? 0;
  const decadeCounts = reportData?.[0]?.totals?.gender_counts ?? [];

  const pieData = customerCounts ? convertToPercentage(decadeCounts, t) : [];

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Gender Percentage')}</div>
            <Tooltip
              text={t(
                'Display the percentage of members by gender as of the end date of the reporting period.'
              )}
            >
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          <div className={styles['chart']}>
            <ResponsiveContainer width="100%" height="100%" debounce={300}>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius="90%"
                  innerRadius="70%"
                  paddingAngle={0}
                  startAngle={270}
                  endAngle={-90}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} />
                  ))}
                </Pie>
                <RechartsTooltip
                  formatter={(value: any, name: any) => {
                    const percentage = (value / customerCounts) * 100;
                    const percentageString = Number.isInteger(percentage)
                      ? percentage.toFixed(0)
                      : percentage.toFixed(2);

                    return [
                      `${t('{{count}} person', {
                        count: value,
                      })} (${percentageString}%)`,
                      name,
                    ];
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={styles['text-section']}>
            {pieData.map((entry, index) => (
              <div key={index} className={styles['legend-item']}>
                <div
                  className={styles['legend-dot']}
                  style={{ backgroundColor: entry.color }}
                />
                {entry.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
