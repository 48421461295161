import * as React from 'react';

export const NtaReservationFormMessage = () => {
  return (
    <p
      style={{
        color: '#F00',
      }}
    >
      NTA商品種別が「ホテル」の商品では専用の予約フォームが設定されているため編集できません。
    </p>
  );
};
