import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { currency } from 'shared/libraries/currency';
import baseStyles from 'client/base.module.css';

import styles from './MediaDownloadOrderSummary.module.css';

const getTotalGross = (prices: string[]): string => {
  if (prices.length === 0) {
    return '';
  }

  let total = currency(prices[0]);

  for (let i = 1; i < prices.length; i++) {
    total = total.add(currency(prices[i]));
  }

  return total.format();
};

export const MediaDownloadOrderSummary = () => {
  const { t } = useTranslation();

  const mediaDownloadOrders = useSelector(
    (state: ReduxState) => state.mediaDownloadOrders.all
  );

  const defaultCurrency = useSelector(defaultProductCurrencySelector);

  const gross = getTotalGross(
    mediaDownloadOrders.map((order) => order.billing_info?.amount_gross ?? '')
  );

  return (
    <div className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}>
      <table>
        <tbody>
          <tr>
            <th className={clsx(baseStyles['base-t-112'])}>{t('Orders')}</th>
            <th className={clsx(baseStyles['base-t-112'])}>{t('Gross')}</th>
          </tr>
          <tr>
            <td className={clsx(styles['right-align'])}>
              {mediaDownloadOrders.length}
            </td>
            <td className={clsx(styles['right-align'])}>
              {formattedCurrencyAmount(gross)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={clsx(styles['footer-note'])}>
        {t('Currency Code') + ' : ' + defaultCurrency}
      </div>
    </div>
  );
};
