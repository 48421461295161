import type { Product, Product$Patch } from 'shared/models/swagger';

type Location = {
  locationName: string;
  googlePlaceId: string;
};
export type PerStartTimeOffset = {
  timeSlotKey: string;
  timeOffsetCheckinPickup: string;
  timeOffsetCheckoutDropoff: string;
};
export type LocationType =
  | 'PICKUP_DROPOFF'
  | 'PICKUP_ONLY'
  | 'DROPOFF_ONLY'
  | 'CHECKIN_CHECKOUT'
  | 'CHECKIN_ONLY'
  | 'CHECKOUT_ONLY';
export type PremappedLocation = {
  googlePlaceId: string;
  locationName: string;
};
export type LocationWithTime = {
  type: LocationType;
  timeOffsetCheckinPickup: string;
  timeOffsetCheckoutDropoff: string;
  location?: Location;
  description: string;
  perStartTimeOffsets: PerStartTimeOffset[];
  referenceInSupplierSystem?: string;
  timeReferenceInSupplierSystem?: string;
  routeReferenceInSupplierSystem?: string;
  premappedLocations: PremappedLocation[];
  imageUrls: string[];
};
export type CheckinPickupFormValues = {
  guestEmailPickupInstructions: string;
  guestEmailCheckinInstructions: string;
  checkinPickupLocations: LocationWithTime[];
  shouldShowPickupLocationSelector: boolean;
};
export const isPickupType = (type: LocationType): boolean => {
  return type === 'PICKUP_DROPOFF' || type === 'PICKUP_ONLY';
};
export const isDropoffType = (type: LocationType): boolean => {
  return type === 'PICKUP_DROPOFF' || type === 'DROPOFF_ONLY';
};

const isCheckinType = (type: LocationType): boolean => {
  return type === 'CHECKIN_CHECKOUT' || type === 'CHECKIN_ONLY';
};

const isCheckoutType = (type: LocationType): boolean => {
  return type === 'CHECKIN_CHECKOUT' || type === 'CHECKOUT_ONLY';
};

export const getInitialCheckinPickupFormValues = (
  product: Product | null
): CheckinPickupFormValues => {
  const pickupLocationsByLocationName = Object.fromEntries(
    (product?.pickup ?? []).map((loc) => [loc.location_name || '', loc])
  );
  const dropoffLocationsByLocationName = Object.fromEntries(
    (product?.dropoff ?? []).map((loc) => [loc.location_name || '', loc])
  );
  const checkinLocationsByLocationName = Object.fromEntries(
    (product?.checkin ?? []).map((loc) => [loc.location_name || '', loc])
  );
  const checkoutLocationsByLocationName = Object.fromEntries(
    (product?.checkout ?? []).map((loc) => [loc.location_name || '', loc])
  );
  const locations: LocationWithTime[] = [];

  for (const pickupLocation of product?.pickup ?? []) {
    const correspondingDropoff =
      dropoffLocationsByLocationName[pickupLocation.location_name];
    locations.push({
      type: correspondingDropoff ? 'PICKUP_DROPOFF' : 'PICKUP_ONLY',
      timeOffsetCheckinPickup: pickupLocation.time_relative ?? '',
      timeOffsetCheckoutDropoff: correspondingDropoff?.time_relative ?? '',
      location: {
        locationName: pickupLocation.location_name ?? '',
        googlePlaceId: pickupLocation.google_place_id ?? '',
      },
      description: pickupLocation.location_description ?? '',
      imageUrls: pickupLocation.image_urls ?? [],
      referenceInSupplierSystem: pickupLocation.third_party_reference,
      timeReferenceInSupplierSystem: pickupLocation.third_party_time_reference,
      routeReferenceInSupplierSystem:
        pickupLocation.third_party_route_reference,
      perStartTimeOffsets: (
        pickupLocation?.dedicated_start_time_info ?? []
      ).map((startTimeInfo) => {
        return {
          timeSlotKey: startTimeInfo.time_slot_key || '',
          timeOffsetCheckinPickup: startTimeInfo.time_relative || '0:00',
          timeOffsetCheckoutDropoff:
            correspondingDropoff?.dedicated_start_time_info?.find(
              (dropoffStartTimeInfo) =>
                dropoffStartTimeInfo.time_slot_key ===
                startTimeInfo.time_slot_key
            )?.time_relative || '0:00',
        };
      }),
      premappedLocations:
        pickupLocation?.premapped_locations?.map((loc) => ({
          googlePlaceId: loc.google_place_id ?? '',
          locationName: loc.location_name ?? '',
        })) ?? [],
    });
  }

  for (const dropoffLocation of product?.dropoff ?? []) {
    const correspondingPickup =
      pickupLocationsByLocationName[dropoffLocation.location_name];

    if (correspondingPickup) {
      continue;
    }

    locations.push({
      type: 'DROPOFF_ONLY',
      timeOffsetCheckinPickup: '',
      timeOffsetCheckoutDropoff: dropoffLocation?.time_relative ?? '',
      location: {
        locationName: dropoffLocation.location_name ?? '',
        googlePlaceId: dropoffLocation.google_place_id ?? '',
      },
      description: dropoffLocation.location_description ?? '',
      imageUrls: dropoffLocation.image_urls ?? [],
      referenceInSupplierSystem: dropoffLocation.third_party_reference,
      timeReferenceInSupplierSystem: dropoffLocation.third_party_time_reference,
      routeReferenceInSupplierSystem:
        dropoffLocation.third_party_route_reference,
      perStartTimeOffsets: (
        dropoffLocation?.dedicated_start_time_info ?? []
      ).map((startTimeInfo) => {
        return {
          timeSlotKey: startTimeInfo.time_slot_key || '',
          timeOffsetCheckoutDropoff: startTimeInfo?.time_relative || '0:00',
          timeOffsetCheckinPickup: '0:00',
        };
      }),
      premappedLocations:
        dropoffLocation?.premapped_locations?.map((loc) => ({
          googlePlaceId: loc.google_place_id ?? '',
          locationName: loc.location_name ?? '',
        })) ?? [],
    });
  }

  for (const checkinLocation of product?.checkin ?? []) {
    const correspondingCheckout =
      checkoutLocationsByLocationName[checkinLocation.location_name];
    locations.push({
      type: correspondingCheckout ? 'CHECKIN_CHECKOUT' : 'CHECKIN_ONLY',
      timeOffsetCheckinPickup: checkinLocation.time_relative ?? '',
      timeOffsetCheckoutDropoff: correspondingCheckout?.time_relative ?? '',
      location: {
        locationName: checkinLocation.location_name ?? '',
        googlePlaceId: checkinLocation.google_place_id ?? '',
      },
      description: checkinLocation.location_description ?? '',
      imageUrls: checkinLocation.image_urls ?? [],
      referenceInSupplierSystem: checkinLocation.third_party_reference,
      timeReferenceInSupplierSystem: checkinLocation.third_party_time_reference,
      routeReferenceInSupplierSystem:
        checkinLocation.third_party_route_reference,
      perStartTimeOffsets: (
        checkinLocation?.dedicated_start_time_info ?? []
      ).map((startTimeInfo) => {
        return {
          timeSlotKey: startTimeInfo.time_slot_key || '',
          timeOffsetCheckinPickup: startTimeInfo.time_relative || '0:00',
          timeOffsetCheckoutDropoff:
            correspondingCheckout?.dedicated_start_time_info?.find(
              (checkoutStartTimeInfo) =>
                checkoutStartTimeInfo.time_slot_key ===
                startTimeInfo.time_slot_key
            )?.time_relative || '0:00',
        };
      }),
      premappedLocations:
        checkinLocation?.premapped_locations?.map((loc) => ({
          googlePlaceId: loc?.google_place_id ?? '',
          locationName: loc?.location_name ?? '',
        })) ?? [],
    });
  }

  for (const checkoutLocation of product?.checkout ?? []) {
    const correspondingCheckin =
      checkinLocationsByLocationName[checkoutLocation.location_name];

    if (correspondingCheckin) {
      continue;
    }

    locations.push({
      type: 'CHECKOUT_ONLY',
      timeOffsetCheckinPickup: '0:00',
      timeOffsetCheckoutDropoff: checkoutLocation?.time_relative ?? '',
      location: {
        locationName: checkoutLocation.location_name ?? '',
        googlePlaceId: checkoutLocation.google_place_id ?? '',
      },
      referenceInSupplierSystem: checkoutLocation.third_party_reference,
      timeReferenceInSupplierSystem:
        checkoutLocation.third_party_time_reference,
      routeReferenceInSupplierSystem:
        checkoutLocation.third_party_route_reference,
      description: checkoutLocation.location_description ?? '',
      imageUrls: checkoutLocation.image_urls ?? [],
      perStartTimeOffsets: (
        checkoutLocation?.dedicated_start_time_info ?? []
      ).map((startTimeInfo) => {
        return {
          timeSlotKey: startTimeInfo.time_slot_key || '',
          timeOffsetCheckoutDropoff: startTimeInfo?.time_relative || '0:00',
          timeOffsetCheckinPickup: '0:00',
        };
      }),
      premappedLocations:
        checkoutLocation?.premapped_locations?.map((loc) => ({
          googlePlaceId: loc?.google_place_id ?? '',
          locationName: loc?.location_name ?? '',
        })) ?? [],
    });
  }

  return {
    checkinPickupLocations: locations,
    guestEmailPickupInstructions:
      product?.guest_email_settings?.pickup_instructions ?? '',
    guestEmailCheckinInstructions:
      product?.guest_email_settings?.checkin_instructions ?? '',
    shouldShowPickupLocationSelector:
      product?.should_show_pickup_location_selector?.value ?? false,
  };
};
export const convertCheckinPickupFormValuesToProductPatch = (
  values: CheckinPickupFormValues
): Product$Patch => {
  return {
    guest_email_settings: {
      pickup_instructions: values.guestEmailPickupInstructions,
      checkin_instructions: values.guestEmailCheckinInstructions,
    },
    pickup: values.checkinPickupLocations
      .filter((loc) => isPickupType(loc.type))
      .map((loc) => ({
        time_relative: loc.timeOffsetCheckinPickup,
        google_place_id: loc.location?.googlePlaceId ?? '',
        location_name: loc.location?.locationName ?? '',
        location_description: loc.description ?? '',
        image_urls: loc.imageUrls,
        third_party_reference: loc.referenceInSupplierSystem,
        third_party_time_reference: loc.timeReferenceInSupplierSystem,
        third_party_route_reference: loc.routeReferenceInSupplierSystem,
        dedicated_start_time_info: loc.perStartTimeOffsets.map(
          (perStartTimeOffset) => ({
            time_slot_key: perStartTimeOffset.timeSlotKey,
            time_relative: perStartTimeOffset.timeOffsetCheckinPickup,
          })
        ),
        premapped_locations: loc?.premappedLocations?.map((premappedLoc) => ({
          google_place_id: premappedLoc.googlePlaceId,
          location_name: premappedLoc.locationName,
        })),
      })),
    dropoff: values.checkinPickupLocations
      .filter((loc) => isDropoffType(loc.type))
      .map((loc) => ({
        time_relative: loc.timeOffsetCheckoutDropoff,
        google_place_id: loc.location?.googlePlaceId ?? '',
        location_name: loc.location?.locationName ?? '',
        location_description: loc.description ?? '',
        image_urls: loc.imageUrls,
        third_party_reference: loc.referenceInSupplierSystem,
        third_party_time_reference: loc.timeReferenceInSupplierSystem,
        third_party_route_reference: loc.routeReferenceInSupplierSystem,
        dedicated_start_time_info: loc.perStartTimeOffsets.map(
          (perStartTimeOffset) => ({
            time_slot_key: perStartTimeOffset.timeSlotKey,
            time_relative: perStartTimeOffset.timeOffsetCheckoutDropoff,
          })
        ),
        premapped_locations: loc?.premappedLocations?.map((premappedLoc) => ({
          google_place_id: premappedLoc.googlePlaceId,
          location_name: premappedLoc.locationName,
        })),
      })),
    checkin: values.checkinPickupLocations
      .filter((loc) => isCheckinType(loc.type))
      .map((loc) => ({
        time_relative: loc.timeOffsetCheckinPickup,
        google_place_id: loc.location?.googlePlaceId ?? '',
        location_name: loc.location?.locationName ?? '',
        location_description: loc.description ?? '',
        image_urls: loc.imageUrls,
        third_party_reference: loc.referenceInSupplierSystem,
        third_party_time_reference: loc.timeReferenceInSupplierSystem,
        third_party_route_reference: loc.routeReferenceInSupplierSystem,
        dedicated_start_time_info: loc.perStartTimeOffsets.map(
          (perStartTimeOffset) => ({
            time_slot_key: perStartTimeOffset.timeSlotKey,
            time_relative: perStartTimeOffset.timeOffsetCheckinPickup,
          })
        ),
        premapped_locations: loc?.premappedLocations?.map((premappedLoc) => ({
          google_place_id: premappedLoc.googlePlaceId,
          location_name: premappedLoc.locationName,
        })),
      })),
    checkout: values.checkinPickupLocations
      .filter((loc) => isCheckoutType(loc.type))
      .map((loc) => ({
        time_relative: loc.timeOffsetCheckoutDropoff,
        google_place_id: loc.location?.googlePlaceId ?? '',
        location_name: loc.location?.locationName ?? '',
        location_description: loc.description ?? '',
        image_urls: loc.imageUrls,
        third_party_reference: loc.referenceInSupplierSystem,
        third_party_time_reference: loc.timeReferenceInSupplierSystem,
        third_party_route_reference: loc.routeReferenceInSupplierSystem,
        dedicated_start_time_info: loc.perStartTimeOffsets.map(
          (perStartTimeOffset) => ({
            time_slot_key: perStartTimeOffset.timeSlotKey,
            time_relative: perStartTimeOffset.timeOffsetCheckoutDropoff,
          })
        ),
        premapped_locations: loc?.premappedLocations?.map((premappedLoc) => ({
          google_place_id: premappedLoc.googlePlaceId,
          location_name: premappedLoc.locationName,
        })),
      })),
    should_show_pickup_location_selector: {
      value: values.shouldShowPickupLocationSelector,
    },
  };
};
