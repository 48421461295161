import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// import { PromotionDetails } from 'components/ProductContentsPane/PromotionDetails/PromotionDetails';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getWeekdayText } from 'client/libraries/util/weekdays';
import { printPriceTitle } from 'client/libraries/util/productShape';
import type { ProductShape } from 'client/libraries/util/productShape';
import { getScheduleText } from 'client/libraries/util/getScheduleText';
import { PromotionDetails } from 'client/pages/ProductDetails/ProductContentsPane/PromotionDetails/PromotionDetails';
import baseStyles from 'client/base.module.css';

import styles from './OperationInfo.module.css';

interface OwnProps {
  product: ProductShape;
  contentLanguage?: string;
}
type Props = OwnProps;
export const OperationInfo = ({ product, contentLanguage }: Props) => {
  const [selectedPriceScheduleIdx, setSelectedPriceScheduleIdx] =
    React.useState<number>(0);
  const { t, i18n } = useTranslation();
  return (
    <section className={baseStyles['base-single__section']}>
      <div className={baseStyles['base-padding-16']}>
        <p className="newline">{product.description}</p>
        <ul
          className={clsx(styles['c-table1'], baseStyles['base-margin-top-32'])}
        >
          <li className={styles['c-table1__list']}>
            <p className={styles['c-table1__list__title']}>
              {t('Days of Week')}
            </p>
            <p className={styles['c-table1__list__body']}>
              {product.operatingWeekdays
                .map((weekday) => getWeekdayText(weekday, t))
                .join(',')}
            </p>
          </li>
          <li className={styles['c-table1__list']}>
            <p className={styles['c-table1__list__title']}>{t('Duration')}</p>
            <p className={styles['c-table1__list__body']}>
              {t('{{durationInHours}} hours', {
                durationInHours: product.durationInHours,
              })}
            </p>
          </li>
        </ul>
        {(product.priceSchedules ?? []).length > 0 && (
          <>
            <div className={baseStyles['base-margin-top-32']}>
              <label className={styles['c-schedule__select']}>
                <select
                  name="priceSchedule"
                  value={selectedPriceScheduleIdx}
                  onChange={(e) =>
                    setSelectedPriceScheduleIdx(e.target.value as any)
                  }
                >
                  {product.priceSchedules.map((priceSchedule, idx) => (
                    <option key={idx} value={idx}>
                      {getScheduleText(priceSchedule, t)}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            {product.priceSchedules[selectedPriceScheduleIdx].prices.length >
              0 && (
              <ul
                className={clsx(
                  styles['c-table2'],
                  baseStyles['base-margin-top-8']
                )}
              >
                {product.priceSchedules[selectedPriceScheduleIdx].prices.map(
                  (price) => (
                    <li
                      key={price.unitTitle}
                      className={styles['c-table2__list']}
                    >
                      <p className={styles['c-table2__list__title']}>
                        {printPriceTitle(price, t)}
                      </p>
                      <p className={styles['c-table2__list__body']}>
                        {formattedCurrencyAmount(price.amount)}
                      </p>
                    </li>
                  )
                )}
              </ul>
            )}
          </>
        )}
        <div>
          <PromotionDetails
            product={product}
            contentLanguage={contentLanguage || i18n.language}
          />
        </div>
      </div>
    </section>
  );
};
