import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Input } from 'client/components/Form';

interface Props {
  placeholder?: string;
}

export const CreateEquipmentFrameForm = ({ placeholder }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box mt={2} mr={2} ml={2}>
        <Field name="title">
          {({ input }) => (
            <Input
              label={t('Title')}
              value={input.value}
              onChange={(_, { value }) => {
                input.onChange(value);
              }}
              placeholder={placeholder}
            />
          )}
        </Field>
      </Box>
      <Box mt={2} mr={2} ml={2}>
        <Field name="columnCount">
          {({ input }) => (
            <Input
              type="number"
              label={t('Column count')}
              value={input.value}
              onChange={(_, { value }) => {
                input.onChange(value);
              }}
              {...{
                min: 1,
              }}
            />
          )}
        </Field>
      </Box>
      <Box mt={2} mr={2} ml={2}>
        <Field name="rowCount">
          {({ input }) => (
            <Input
              type="number"
              label={t('Row count')}
              value={input.value}
              onChange={(_, { value }) => {
                input.onChange(value);
              }}
              {...{
                min: 1,
              }}
            />
          )}
        </Field>
      </Box>
    </>
  );
};
