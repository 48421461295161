import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';

import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

interface Props {
  index: number;
}

export const RemoveSectionButton = ({ index }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  const form = useForm();

  const onRemove = () => {
    const newSections = [...form.getState().values.sections];
    newSections.splice(index, 1);
    form.change('sections', newSections);
  };

  return (
    <>
      <Delete onClick={() => setShowModal(true)} />
      {showModal && (
        <DeleteConfirmModal
          header={t('Delete section')}
          content={t('Are you sure you want to delete section?')}
          onConfirm={async () => {
            onRemove();
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
          open={showModal}
          insertRoot
        />
      )}
    </>
  );
};
