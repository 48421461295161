import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CrewList } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/CrewList';
import { ResourceList } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/ResourceList';
import { VehicleList } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/VehicleList';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import baseStyles from 'client/v3-base.module.css';
import { ManifestCustomizeHeader } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceHeader/ManifestEditResourceHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';

type TabType = 'VEHICLES' | 'CREW' | 'RESOURCES';

export const ManifestEditResource = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabType>('VEHICLES');

  return (
    <V3Page>
      <ManifestCustomizeHeader />
      <div className={baseStyles['l-main__body']}>
        <section
          className={clsx(baseStyles['g-section'], baseStyles['u-mb-4'])}
        >
          <div className={styles['p-manifests__customize']}>
            <div className={styles['p-manifests__customize__header']}>
              <ul className={styles['p-manifests__customize__header__tab']}>
                <li
                  className={clsx(
                    activeTab === 'VEHICLES' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('VEHICLES');
                  }}
                >
                  <a>{t('Vehicles')}</a>
                </li>
                <li
                  className={clsx(
                    activeTab === 'CREW' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('CREW');
                  }}
                >
                  <a>{t('Crew')}</a>
                </li>
                <li
                  className={clsx(
                    activeTab === 'RESOURCES' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('RESOURCES');
                  }}
                >
                  <a>{t('Resources')}</a>
                </li>
              </ul>
            </div>

            <div className={styles['p-manifests__customize__body']}>
              <VehicleList isActive={activeTab === 'VEHICLES'} />
              <CrewList isActive={activeTab === 'CREW'} />
              <ResourceList isActive={activeTab === 'RESOURCES'} />
            </div>
          </div>
        </section>
      </div>
    </V3Page>
  );
};
