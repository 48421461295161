import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { activeUserSelector } from 'client/reducers/user';
import { fetchCustomerByID } from 'client/actions/customers';
import { fetchReservationsByID } from 'client/actions/reservations';
import { fetchProductsByID } from 'client/actions/products';
import type { ReduxState } from 'client/reducers';

import { CustomerContext } from './CustomerContext';
import { CustomerReservationsContext } from './CustomerReservationsContext';
import { CustomerInformation } from './CustomerInformation';
import { CustomerReservations } from './CustomerReservations';
import { CustomerGuestInformation } from './CustomerGuestInformation';
import { CustomerTab } from './CustomerTab';

export const CustomerDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);

  const customer = useSelector((state: any) => state.customers.byID[id || '']);

  const reservationLoading = useSelector((state: ReduxState) => {
    return state.reservations.loading;
  });

  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.byID
  );

  const reservations = Object.values(allReservations).filter(
    (reservation: any) => {
      return customer?.reservation_ids.includes(reservation.id);
    }
  );

  React.useEffect(() => {
    if (typeof id === 'string') {
      dispatch(fetchCustomerByID(id));
    }
  }, [id, t, activeUser]);

  React.useEffect(() => {
    if ((customer?.reservation_ids ?? []).length > 0) {
      dispatch(fetchReservationsByID(customer.reservation_ids));
    }
  }, [customer?.reservation_ids]);

  React.useEffect(() => {
    if (reservations.length > 0) {
      dispatch(fetchProductsByID(reservations.map((r) => r.product_id ?? '')));
    }
  }, [reservations]);

  return (
    <CustomerContext.Provider value={customer}>
      <CustomerReservationsContext.Provider value={reservations}>
        <CustomerInformation />
        <CustomerReservations />
        <CustomerGuestInformation />
        <CustomerTab />
      </CustomerReservationsContext.Provider>
      <Loader active={reservationLoading} />
    </CustomerContext.Provider>
  );
};
