import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { ToggleButton } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';

type FormValues = {
  shouldShowOnlyForLoggedInCustomer: boolean;
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    shouldShowOnlyForLoggedInCustomer:
      product?.customer_ledger_settings
        ?.should_show_only_for_logged_in_customer ?? false,
  };
};
const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    customer_ledger_settings: {
      should_show_only_for_logged_in_customer:
        values.shouldShowOnlyForLoggedInCustomer,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const ProductCustomerLedgerSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Member Settings')}
      initialValues={getInitialValues(product)}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="shouldShowOnlyForLoggedInCustomer">
        {({ input }) => {
          return (
            <>
              <Modal.Box>
                <ToggleButton
                  {...input}
                  label={t('Show this product only for logged-in customers')}
                />
              </Modal.Box>
            </>
          );
        }}
      </Field>
    </ProductEditorModal>
  );
};
