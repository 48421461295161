export const generateRandomString = (
  length: number,
  charset = 'ABCDEFGHIJKLMNOPQRSTUVWZYZabcdefghijklmnopqrstuvwzyz0123456789'
): string => {
  let text = '';

  for (let i = 0; i < length; i++) {
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return text;
};
// shuffleString returns a string produced by randomly shuffling the characters in a parameter string.
export const shuffleString = (s: string): string => {
  const arr = s.split('');
  const n = arr.length;

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = arr[i];
    arr[i] = arr[j];
    arr[j] = tmp;
  }

  return arr.join('');
};
