import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
};
export const TotalTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  let totalVisitCount = 0;
  let totalUserCount = 0;
  let totalReservationCount = 0;
  data.forEach((dataItem) => {
    totalVisitCount += dataItem.visitCount;
    totalUserCount += dataItem.userCount;
    totalReservationCount += dataItem.reservationCount;
  });
  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('visitCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('userCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('reservationCount')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
              textAlign: 'right',
            }}
          >
            {t('Conversion Rate')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ textAlign: 'right' }}>{totalVisitCount}</td>
          <td style={{ textAlign: 'right' }}>{totalUserCount}</td>
          <td style={{ textAlign: 'right' }}>{totalReservationCount}</td>
          <td style={{ textAlign: 'right' }}>
            {(totalUserCount
              ? (totalReservationCount / totalUserCount) * 100
              : 0
            ).toFixed(1)}{' '}
            %
          </td>
        </tr>
      </tbody>
    </table>
  );
};
