import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import { productOptionsSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';

import styles from './FiltersDisplayBox.module.css';
import { FilterReservationsRequest, getPaymentTypeOptions } from './util';

type Props = {
  filterCondition: FilterReservationsRequest;
  resultsCount: number;
};
export const FiltersDisplayBox = ({ filterCondition, resultsCount }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  const statusOptions = getBookingStatusOptions(t);

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const paymentTypeOptions = getPaymentTypeOptions(t);

  if (filterCondition.productIds?.length > 0) {
    filters.push({
      typeText: t('Products'),
      filterValue: filterCondition.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (filterCondition.paymentTypes?.length > 0) {
    filters.push({
      typeText: t('Booking Status'),
      filterValue: filterCondition.reservationStatuses
        .map(
          (status) =>
            statusOptions.find((option) => option.value === status)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (filterCondition.paymentTypes?.length > 0) {
    filters.push({
      typeText: t('Payment Type'),
      filterValue: filterCondition.paymentTypes
        .map(
          (paymentType) =>
            paymentTypeOptions.find((option) => option.value === paymentType)
              ?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (filterCondition.transferDateFrom || filterCondition.transferDateTo) {
    filters.push({
      typeText: t('Deposit Date'),
      filterValue: `${filterCondition.transferDateFrom ?? ''} ~ ${
        filterCondition.transferDateTo
      }`,
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      {filters.length > 0 && (
        <div className={styles['filter-condition-container']}>
          <b>{t('Filter Conditions')}</b>
          {loading ? (
            <ModalLoader />
          ) : (
            <ul>
              {filters.map((f) => (
                <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
              ))}
            </ul>
          )}
        </div>
      )}
      <li>
        <b>{t('Total: ')}</b>

        {t('{{count}} records', {
          count: resultsCount,
        })}
      </li>
    </div>
  );
};
