import { useSelector } from 'react-redux';
import useSWR from 'swr';
import querystring from 'query-string';
import axios from 'axios';

import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';

interface DateRange {
  start: string;
  end: string;
}

const fetcher = (url: string, paramsString: string, headerString: string) =>
  axios
    .get(url, {
      params: JSON.parse(paramsString),
      headers: JSON.parse(headerString),
    })
    .then((res) => res.data?.sets ?? []);

export const useReservationReportData = (
  params: ReservationReportGadgetParams,
  dateRange: DateRange,
  comparisonDateRange?: DateRange | null
): {
  data: Swagger.ReservationReportDataSet[] | null;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToReservationReportRequest(
    params,
    dateRange,
    comparisonDateRange
  );

  const { data, error } = useSWR<Swagger.ReservationReportDataSet[]>(
    [
      `/api/reservations/reportdata`,
      JSON.stringify(queryParams),
      JSON.stringify(headers),
    ],
    fetcher
  );
  return {
    data: data ?? null,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const convertParamsToReservationReportRequest = (
  params: ReservationReportGadgetParams,
  dateRange: DateRange,
  comparisonDateRange?: DateRange | null
): Swagger.GetReservationReportDataRequest => {
  const ranges: { starts: string[]; ends: string[] } = {
    starts: [],
    ends: [],
  };
  ranges.starts.push(dateRange.start);
  ranges.ends.push(dateRange.end);
  if (comparisonDateRange) {
    ranges.starts.push(comparisonDateRange.start);
    ranges.ends.push(comparisonDateRange.end);
  }
  ranges.starts.push('END');
  ranges.ends.push('END');

  return {
    basis_date_type: params.dateType,
    ranges: querystring.stringify(ranges),
    filter: querystring.stringify({
      product_id: params.productIds,
    }),
  };
};
