import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { currency } from 'shared/libraries/currency';
import {
  CustomTableColumn,
  TableSmall,
} from 'client/components/v3/Table/TableSmall';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './CampaignRankingGadget.module.css';

interface CampaignSummary {
  campaign: string;
  contentType: Swagger.MarketingAutomationContent['type'];
  base: {
    revenue: number;
    purchases: number;
    clicks: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
  comparison?: {
    revenue: number;
    purchases: number;
    clicks: number;
    conversionRate: number;
    impressions: number;
    clickRate: number;
    targets: number;
  };
}

export const CampaignRankingGadget = () => {
  const { t } = useTranslation();

  const campaigns = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.all
  );

  const org = useSelector(activeUserOrganizationSelector);

  const reportData = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.reportData
  );
  const baseItemsByCampaign = reportData?.length
    ? reportData[0].items_by_campaign
    : [];
  const comparisonItemsByCampaign =
    reportData?.length > 1 ? reportData[1].items_by_campaign : [];

  const data = React.useMemo(() => {
    return _.orderBy(
      campaigns.map((campaign) => {
        const baseItem = baseItemsByCampaign?.find(
          (item) => item.campaign_id === campaign.id
        );
        const comparisonItem = comparisonItemsByCampaign?.find(
          (item) => item.campaign_id === campaign.id
        );

        const baseGrossSales = baseItem?.gross_sales
          ? currency(baseItem?.gross_sales)?.value
          : 0;
        const baseConversions = baseItem?.conversion_count ?? 0;
        const baseClicks = baseItem?.click_count ?? 0;
        const baseImpressions = baseItem?.impression_count ?? 0;
        const baseTargets = baseItem?.targeted_count ?? 0;
        const baseDeliveries = baseItem?.delivered_count ?? 0;
        const baseConversionRate =
          baseImpressions > 0 ? (100 * baseConversions) / baseTargets : 0;
        const baseClickRate =
          baseImpressions > 0 ? (100 * baseClicks) / baseDeliveries : 0;

        const comparisonGrossSales = comparisonItem?.gross_sales
          ? currency(comparisonItem?.gross_sales)?.value
          : 0;
        const comparisonConversions = comparisonItem?.conversion_count ?? 0;
        const comparisonClicks = comparisonItem?.click_count ?? 0;
        const comparisonImpressions = comparisonItem?.impression_count ?? 0;
        const comparisonTargets = comparisonItem?.targeted_count ?? 0;
        const comparisonDeliveries = comparisonItem?.delivered_count ?? 0;
        const comparisonConversionRate =
          comparisonImpressions > 0
            ? (100 * comparisonConversions) / comparisonTargets
            : 0;
        const comparisonClickRate =
          comparisonImpressions > 0
            ? (100 * comparisonClicks) / comparisonDeliveries
            : 0;

        return {
          campaign: campaign.title ?? '<MISSING>',
          contentType: baseItem?.campaign_content_type,
          base: {
            revenue: baseGrossSales,
            purchases: baseConversions,
            clicks: baseClicks,
            conversionRate: baseConversionRate,
            impressions: baseImpressions,
            clickRate: baseClickRate,
            targets: baseTargets,
          },
          comparison: comparisonItem
            ? {
                revenue: comparisonGrossSales,
                purchases: comparisonConversions,
                clicks: comparisonClicks,
                conversionRate: comparisonConversionRate,
                impressions: comparisonImpressions,
                clickRate: comparisonClickRate,
                targets: comparisonTargets,
              }
            : undefined,
        };
      }),
      ['base.revenue'],
      ['desc']
    );
  }, [campaigns, baseItemsByCampaign, comparisonItemsByCampaign]);

  const columns: CustomTableColumn<CampaignSummary>[] = React.useMemo(
    () => [
      {
        Header: t('Campaign'),
        accessor: 'campaign',
      },
      {
        Header: t('Targets'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            {campaign.contentType === 'EMAIL' ? (
              <>
                <div className={styles['cell-base']}>
                  {campaign.base.targets}
                </div>
                {campaign.comparison && (
                  <div className={styles['cell-comparison']}>
                    {campaign.comparison.targets}
                  </div>
                )}
              </>
            ) : (
              t('-')
            )}
          </div>
        ),
      },
      {
        Header: t('Impressions'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {campaign.base.impressions.toLocaleString()}
            </div>
            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {campaign.comparison.impressions.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Clicks'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {campaign.base.clicks.toLocaleString()}
            </div>

            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {campaign.comparison.clicks.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Click Rate'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {campaign.base.clickRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {campaign.comparison.clickRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Bookings'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {campaign.base.purchases.toLocaleString()}
            </div>
            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {campaign.comparison.purchases.toLocaleString()}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Conversion Rate'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {campaign.base.conversionRate.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              %
            </div>
            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {campaign.comparison.conversionRate.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </div>
        ),
      },
      {
        Header: t('Sales'),
        accessor: (campaign) => (
          <div className={styles['table-cell']}>
            <div className={styles['cell-base']}>
              {currency(campaign.base.revenue, org?.default_currency)
                .format()
                .substring(3)}
            </div>
            {campaign.comparison && (
              <div className={styles['cell-comparison']}>
                {currency(campaign.comparison.revenue).format().substring(3)}
              </div>
            )}
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Campaign Ranking')}</div>
          </div>
        </div>
        <div className={styles['body']}>
          <TableSmall items={data} columns={columns} usePaging />
        </div>
      </div>
    </div>
  );
};
