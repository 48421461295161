import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationCreateDetails.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  title: string;
  subtitle?: string;
  id?: string;
  initialOpen?: boolean;
  children: JSX.Element | JSX.Element[];
  ref?: any;
};

export const CollapsibleSection = ({
  title,
  subtitle,
  id,
  initialOpen = true,
  children,
  ref,
}: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    setIsActive(initialOpen);
  }, [initialOpen]);

  return (
    <div
      id={id}
      className={styles['p-reservationsCreateDetail__section']}
      ref={ref}
    >
      <div className={styles['p-reservationsCreateDetail__section__header']}>
        <p className={styles['p-reservationsCreateDetail__section__ttl']}>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </p>
        <div className={styles['p-reservationsCreateDetail__section__ic']}>
          <Button
            text={isActive ? t('Hide') : t('Show')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            iconBeforeText={
              isActive ? (
                <i className="c-icon-outline-general-minus"></i>
              ) : (
                <i className="c-icon-solid-general-plus"></i>
              )
            }
            onClick={() => {
              setIsActive(!isActive);
            }}
          />
        </div>
      </div>

      <div
        className={clsx(
          styles['p-reservationsCreateDetail__section__body'],
          isActive && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
