import moment from 'moment-timezone';

// getCalendarWeeks returns a grid of moment objects for displaying a calendar.
// It includes the "fill-in" days from the previous and next months to complete the grid.
export const getCalendarWeeks = (month: string): moment.Moment[][] => {
  const firstDayOfMonth = moment(`${month}-01`);
  let initialSunday = moment(firstDayOfMonth);

  while (initialSunday.day() !== 0) {
    initialSunday = initialSunday.subtract(1, 'day');
  }

  let endingSaturday = moment(firstDayOfMonth).endOf('month');
  while (endingSaturday.day() !== 6) {
    endingSaturday = endingSaturday.add(1, 'day');
  }

  const weeks: moment.Moment[][] = [];
  let currentDay = initialSunday;
  while (currentDay.isSameOrBefore(endingSaturday)) {
    const week: moment.Moment[] = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDay.clone());
      currentDay = currentDay.add(1, 'day');
    }

    weeks.push(week);
  }

  return weeks;
};
