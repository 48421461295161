import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_EQUIPMENT_ASSETS_SUCCESS,
  FETCH_EQUIPMENT_ASSETS_FAILURE,
  FETCH_EQUIPMENT_ASSETS_REQUEST,
  DELETE_EQUIPMENT_ASSET_FAILURE,
  DELETE_EQUIPMENT_ASSET_REQUEST,
  DELETE_EQUIPMENT_ASSET_SUCCESS,
  CREATE_EQUIPMENT_ASSET_REQUEST,
  UPDATE_EQUIPMENT_ASSET_FAILURE,
  UPDATE_EQUIPMENT_ASSET_SUCCESS,
  UPDATE_EQUIPMENT_ASSET_REQUEST,
  CREATE_EQUIPMENT_ASSET_FAILURE,
  CREATE_EQUIPMENT_ASSET_SUCCESS,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
  CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { NewEquipmentAsset, EquipmentAsset } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchEquipmentAssetsRequest = () => ({
  type: FETCH_EQUIPMENT_ASSETS_REQUEST,
});

const fetchEquipmentAssetsSuccess = (response: any) => ({
  type: FETCH_EQUIPMENT_ASSETS_SUCCESS,
  response,
});

const fetchEquipmentAssetsFailure = (error: any) => ({
  type: FETCH_EQUIPMENT_ASSETS_FAILURE,
  error,
});

export const fetchEquipmentAssets =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchEquipmentAssetsRequest());
    axios
      .get('/api/equipmentassets', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchEquipmentAssetsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchEquipmentAssetsFailure('canceled'));
        else dispatch(fetchEquipmentAssetsFailure(error));
      });
  };

const deleteEquipmentAssetRequest = (id: string) => ({
  type: DELETE_EQUIPMENT_ASSET_REQUEST,
  id,
});

const deleteEquipmentAssetSuccess = (response: any, id: string) => ({
  type: DELETE_EQUIPMENT_ASSET_SUCCESS,
  response,
  id,
});

const deleteEquipmentAssetFailure = (error: any) => ({
  type: DELETE_EQUIPMENT_ASSET_FAILURE,
  error,
});

export const deleteEquipmentAsset =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteEquipmentAssetRequest(id));
    fetch(`/api/equipmentassets/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteEquipmentAssetSuccess(result, id)))
      .catch((error) => dispatch(deleteEquipmentAssetFailure(error)));
  };

const createEquipmentAssetRequest = (newEquipmentAsset: NewEquipmentAsset) => ({
  type: CREATE_EQUIPMENT_ASSET_REQUEST,
  newEquipmentAsset,
});

const createEquipmentAssetSuccess = (response: any) => ({
  type: CREATE_EQUIPMENT_ASSET_SUCCESS,
  response,
});

const createEquipmentAssetFailure = (error: any) => ({
  type: CREATE_EQUIPMENT_ASSET_FAILURE,
  error,
});

export const createEquipmentAsset =
  (newEquipmentAsset: NewEquipmentAsset) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createEquipmentAssetRequest(newEquipmentAsset));
    return axios
      .post('/api/equipmentassets', newEquipmentAsset, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createEquipmentAssetSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createEquipmentAssetFailure(err.message));
      });
  };

const updateEquipmentAssetRequest = (id: string, patch: EquipmentAsset) => ({
  type: UPDATE_EQUIPMENT_ASSET_REQUEST,
  id,
  patch,
});

const updateEquipmentAssetSuccess = (response: any) => ({
  type: UPDATE_EQUIPMENT_ASSET_SUCCESS,
  response,
});

const updateEquipmentAssetFailure = (error: any) => ({
  type: UPDATE_EQUIPMENT_ASSET_FAILURE,
  error,
});

export const updateEquipmentAsset =
  (id: string, patch: EquipmentAsset) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateEquipmentAssetRequest(id, patch));
    return axios
      .patch(`/api/equipmentassets/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateEquipmentAssetSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateEquipmentAssetFailure(err.message));
      });
  };

const calculateEquipmentAssetCellBlockMappingsRequest = (response: any) => ({
  type: CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
  response,
});

const calculateEquipmentAssetCellBlockMappingsSuccess = (response: any) => ({
  type: CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS,
  response,
});

const calculateEquipmentAssetCellBlockMappingsFailure = (error: any) => ({
  type: CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE,
  error,
});

export const calculateEquipmentAssetCellBlockMappings =
  (newEquipmentAsset: NewEquipmentAsset) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      calculateEquipmentAssetCellBlockMappingsRequest(newEquipmentAsset)
    );
    return axios
      .post('/api/equipmentassets/calculatemappings', newEquipmentAsset, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(
          calculateEquipmentAssetCellBlockMappingsSuccess(response.data)
        );
      })
      .catch((err) => {
        dispatch(calculateEquipmentAssetCellBlockMappingsFailure(err.message));
      });
  };

const clearEquipmentAssetCellBlockMappingsRequest = () => ({
  type: CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
});

export const clearEquipmentAssetCellBlockMappings =
  () => (dispatch: Dispatch) => {
    dispatch(clearEquipmentAssetCellBlockMappingsRequest());
  };
