import * as React from 'react';
import useSWR from 'swr';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';
import editIcon from 'client/images/ic_edit.svg';
import type {
  OrganizationSummary,
  PartnershipProduct,
} from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { Account, ProductSummary } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import { EditPartnershipSupplierProductsModal } from 'client/components/EditPartnershipSupplierProductsModal/EditPartnershipSupplierProductsModal';
import { PartnershipSupplierProductsDisplayBox } from 'client/components/PartnershipSupplierProductsDisplayBox/PartnershipSupplierProductsDisplayBox';
import { ReduxState } from 'client/reducers';
import {
  activeUserOrganizationSelector,
  currentActorSelector,
  impersonatedAccountSelector,
} from 'client/reducers/user';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import {
  fetchContractedOrganizations,
  updateOrganization,
} from 'client/actions/organizations';
import { getIDToken } from 'client/libraries/cognito';
import { Loading } from 'client/pages/Loading';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  impersonatedAccount: Account,
  actorName: string,
  alternateOrganizationIsActive: boolean
) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
      'x-actor-name': encodeURIComponent(actorName ?? ''),
      'x-alt-org': alternateOrganizationIsActive ? 'true' : 'false',
    },
  }).then((res) => res.json());

export const PartnershipSupplierProducts = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [supplierId, setSupplierId] = React.useState('');
  const [
    showEditPartershipSupplierProductsModal,
    setShowEditPartershipSupplierProductsModal,
  ] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);
  const actor = useSelector(currentActorSelector);
  const contractedLoading = useSelector((state: ReduxState) => {
    state.organizations.contractedLoading;
  });

  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  const { data: products, error: productsError } = useSWR<{
    products: ProductSummary[];
  }>(
    [
      `/api/products`,
      token,
      i18n.language,
      impersonatedAccount,
      actor?.name ?? '',
      true,
    ],
    fetcher
  );

  const { data: contractedSuppliers, error: contractedSuppliersLoading } =
    useSWR<{
      organizations: OrganizationSummary[];
    }>(
      [
        `/api/organizations/contracted`,
        token,
        i18n.language,
        impersonatedAccount,
        actor?.name ?? '',
        true,
      ],
      fetcher
    );

  const loading =
    Boolean(contractedLoading) ||
    Boolean(!products && !productsError) ||
    Boolean(!contractedSuppliers && !contractedSuppliersLoading);

  const getColumns = (
    t: TranslateFuncType
  ): ColumnType<OrganizationSummary>[] => {
    return [
      {
        Header: t('Supplier'),
        id: 'supplier',
        accessor: (item: OrganizationSummary) => {
          return item.name;
        },
        width: 200,
      },
      {
        Header: t('Products'),
        id: 'products',
        accessor: (item: OrganizationSummary) => {
          return (
            <Box display="flex" alignItems="center">
              <div>
                <PartnershipSupplierProductsDisplayBox
                  supplierId={item.id ?? ''}
                  products={products?.products ?? []}
                />
              </div>
              <a
                className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                style={{ marginRight: '10px' }}
                data-text={t('Edit')}
                onClick={() => {
                  setSupplierId(item.id ?? '');
                  setShowEditPartershipSupplierProductsModal(true);
                }}
              >
                <img src={editIcon} />
              </a>
            </Box>
          );
        },
      },
    ];
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box__body'])}>
      {loading && <Loading />}
      <GenericTable<OrganizationSummary>
        items={(contractedSuppliers?.organizations ?? []).filter((_, index) => {
          const start = (currentPage - 1) * rowCount;
          const end = start + rowCount;
          return index >= start && index < end;
        })}
        totalCount={contractedSuppliers?.organizations?.length ?? 0}
        columns={getColumns(t)}
        rowCount={rowCount}
        currentPage={currentPage}
        onRowCountChange={(rowCount: number) => {
          setRowCount(rowCount);
        }}
        onCurrentPageChange={(newCurrentPage: number) => {
          setCurrentPage(newCurrentPage);
        }}
        hideScrollButtons={true}
      />

      {showEditPartershipSupplierProductsModal && (
        <EditPartnershipSupplierProductsModal
          supplierId={supplierId}
          products={products?.products ?? []}
          onClose={() => {
            setShowEditPartershipSupplierProductsModal(false);
          }}
          open={showEditPartershipSupplierProductsModal}
          onSubmitted={(products: PartnershipProduct[]) => {
            const supplierProducts =
              activeUserOrganization?.partnership_settings?.supplier_products ||
              [];

            const currentMap: { [index: string]: PartnershipProduct[] } = {};

            supplierProducts.forEach((supplierProduct) => {
              currentMap[supplierProduct.supplier_id ?? ''] =
                supplierProduct.products || [];
            });

            currentMap[supplierId] = products;

            const newSupplierProducts = Object.keys(currentMap).map(
              (supplierId) => {
                return {
                  supplier_id: supplierId,
                  products: currentMap[supplierId],
                };
              }
            );

            const newPartnershipSettings = {
              ...activeUserOrganization?.partnership_settings,
              supplier_products: newSupplierProducts,
            };

            dispatch(
              updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
                partnership_settings: newPartnershipSettings,
              })
            );
          }}
        />
      )}
    </div>
  );
};
