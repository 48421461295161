import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useState } from 'react';

import { TranslateFuncType } from 'client/components/Translate';
import {
  DashboardGadget,
  DashboardSettings,
  ReservationReportGadgetDataType,
  ReservationReportGadgetDisplayType,
  ReservationReportGadgetParams,
} from 'client/reducers/dashboardSettings';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/SelectPane.module.css';
import { AddCustomGadgetModal } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddCustomGadgetModal';
import { AddButton } from 'client/pages/v3/FlexibleDashboard/AddGadgetModal/AddButton';
import { Gadget } from 'client/pages/v3/FlexibleDashboard/Gadget';
import { getReservationReportGadgetInitialParams } from 'client/pages/v3/FlexibleDashboard/util';

const getGadgetTitle = (
  dataType: ReservationReportGadgetDataType,
  displayType: ReservationReportGadgetDisplayType,
  t: TranslateFuncType
): string => {
  switch (displayType) {
    case 'LINE_CHART':
      switch (dataType) {
        case 'RESERVATION_COUNT':
          return t('Reservation Trends');
        case 'PARTICIPANT_COUNT':
          return t('Participant Trends');
        case 'SALES':
          return t('Sales Trends');
      }
    // Indicate an intentional fallthrough
    // https://eslint.org/docs/latest/rules/no-fallthrough
    // fallsthrough
    case 'SOURCES_TABLE':
    case 'SOURCES_BAR_CHART':
      switch (dataType) {
        case 'RESERVATION_COUNT':
          return t('Reservations by Booking Source');
        case 'PARTICIPANT_COUNT':
          return t('Participants by Booking Source');
        case 'SALES':
          return t('Sales by Booking Source');
      }
    // fallsthrough
    case 'PRODUCTS_TABLE':
    case 'PRODUCTS_BAR_CHART':
      switch (dataType) {
        case 'RESERVATION_COUNT':
          return t('Reservations by Product');
        case 'PARTICIPANT_COUNT':
          return t('Participants by Product');
        case 'SALES':
          return t('Sales by Product');
      }
  }
};

const getPresetGadgets = (
  dataType: ReservationReportGadgetDataType,
  t: TranslateFuncType
): DashboardGadget[] => {
  const displayTypes: ReservationReportGadgetParams['displayType'][] = [
    'LINE_CHART',
    'SOURCES_TABLE',
    'SOURCES_BAR_CHART',
    'PRODUCTS_TABLE',
    'PRODUCTS_BAR_CHART',
  ];
  return displayTypes.map(
    (displayType: ReservationReportGadgetParams['displayType']) => {
      return {
        gadgetType: 'reservation-report',
        key: dataType.toLowerCase() + '-report-' + displayType,
        title: getGadgetTitle(dataType, displayType, t),
        params: getReservationReportGadgetInitialParams(dataType, displayType),
      };
    }
  );
};

type Props = {
  onClose: () => void;
};

export const ReservationReportSelectPane = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataType, setDataType] =
    useState<ReservationReportGadgetDataType | null>(null);

  const form = useForm<DashboardSettings>();
  const values = form.getState().values;

  return (
    <>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Number of Reservations')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('RESERVATION_COUNT');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getPresetGadgets('RESERVATION_COUNT', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Participant Number')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('PARTICIPANT_COUNT');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getPresetGadgets('PARTICIPANT_COUNT', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['p-dashboardData__body__box']}>
        <div className={styles['p-dashboardData__body__box__ttl']}>
          {t('Gross Sales')}
          <div className={styles['p-dashboardData__body__box__ttl__actions']}>
            <Button
              text={t('Create New')}
              onClick={() => {
                setShowModal(true);
                setDataType('SALES');
              }}
            />
          </div>
        </div>
        <ul className={styles['p-dashboardDataList']}>
          {getPresetGadgets('SALES', t).map((gadget) => (
            <li
              key={gadget.key}
              className={styles['p-dashboardDataList__item']}
            >
              <Gadget
                key={gadget.key}
                gadget={gadget}
                actions={
                  <AddButton
                    selected={values.gadgets.some(
                      (g) =>
                        gadget.key === g.key && !gadget.key.includes('custom')
                    )}
                    onClick={() => {
                      if (values.gadgets.find((g) => gadget.key === g.key)) {
                        form.change(
                          'gadgets',
                          values.gadgets.filter((g) => g.key !== gadget.key)
                        );
                      } else {
                        form.change('gadgets', [...values.gadgets, gadget]);
                      }
                    }}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </div>
      {showModal && (
        <AddCustomGadgetModal
          gadgetType="reservation-report"
          gadgetDataType={dataType ?? 'RESERVATION_COUNT'}
          onClose={() => {
            setShowModal(false);
            // Also close add gadget modal
            onClose();
          }}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};
