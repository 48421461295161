import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import baseStyles from 'client/base.module.css';

import styles from '../Tutorial.module.css';

type Props = {
  popUpDisable: () => void;
};

export const ReservationTutorialPopUp = ({ popUpDisable }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const bwUrl = getBookingWidgetUrl(
    activeUserOrganization?.booking_widget_subdomain ?? ''
  );

  return (
    <div className={styles['page-tutorial__popupFrame']}>
      <div className={styles['page-tutorial__popupFinish']}>
        <p className={styles['page-tutorial__popupFinish__header']}>
          {t('STEP2 - Test Booking')}
        </p>
        <div className={styles['page-tutorial__popupFinish__body']}>
          <p>
            {t(
              'Visit the booking website and make a test booking to see the product and checkout pages.'
            )}
          </p>
          <p>
            {t(
              'Please make a test booking in the same way as your customers and see the checkout flow for the new reservations. As long as bookings are cancelled, no booking fee will be charged.'
            )}
          </p>
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
            style={{ width: '128px', height: '32px', margin: 'auto' }}
            href={bwUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => popUpDisable?.()}
          >
            {t('Open website')}
          </a>
        </div>
      </div>
    </div>
  );
};
