import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';

import styles from './FiltersDisplayBox.module.css';

export const FiltersDisplayBox = () => {
  const savedFilters = useSelector(
    (state: ReduxState) => state.newslettersSearch.filters
  );
  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );

  const { t } = useTranslation();

  const filters = [];

  if (savedFilters.sendDateFrom || savedFilters.sendDateTo) {
    filters.push({
      typeText: t('Send Date'),
      filterValue: `${savedFilters.sendDateFrom ?? ''} ~ ${
        savedFilters.sendDateTo
      }`,
    });
  }

  if (savedFilters.segments?.length) {
    const segmentNames = savedFilters.segments.map((segmentId) => {
      const segment = segments.find((s) => s.id === segmentId);
      return segment?.name ?? '';
    });
    filters.push({
      typeText: t('Segments'),
      filterValue: segmentNames.join(', '),
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      {filters.length > 0 ? (
        <div className={styles['filter-condition-container']}>
          <b>{t('Filter Conditions')}</b>
          <ul>
            {filters.map((f) => (
              <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles['filter-condition-container']}>
          {t('No filter conditions')}
        </div>
      )}
    </div>
  );
};
