// This file contains custom headers used in our internal API.
// "Impersonate" is a feature available only to nutmeg admins which allows "impersonating" an agent or supplier user while remaining logged-in
// under the admin account. The header value is a JSON-encoded user Account object.
export const ImpersonateHeaderName = 'impersonate';
// The "actor" header allows a user claim responsibility for a modification made through the nutmeg API. Some customers share a single account
// among multiple real-life users so we provide the "actor" layer separate from the "name" stored in the user account.
export const ActorHeaderName = 'x-actor-name';
// The "activate alternate organization" header allows an account that has an alternate organization registered to execute API actions using that
// alternate organization. The use case is passthrough, where a real-life agent may resell products to other real-life agents, which requires the
// agent to access our system as a "supplier". A header value of "true" (case-insensitive), will cause the action source of API calls to be the
// account's alternate organization.
export const ActivateAlternateOrganizationHeaderName = 'x-alt-org';
