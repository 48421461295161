import * as React from 'react';
import clsx from 'clsx';

import { useBounds } from 'client/hooks/useBounds';
import { getYouTubePreviewUrl } from 'client/libraries/util/getYouTubePreviewUrl';

import styles from './MediaCarousel.module.css';

interface MediaCarouselProps {
  urls: {
    url: string;
    type: 'VIDEO' | 'IMAGE';
  }[];
}
export const MediaCarousel = ({ urls }: MediaCarouselProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [thumbnailStripOffset, setThumbnailStripOffset] = React.useState(0);
  const [firstThumbnailImageRef, firstThumbnailImageBounds] =
    useBounds<HTMLImageElement>();
  const [leftThumbnailArrowButtonRef, leftThumbnailArrowButtonBounds] =
    useBounds<HTMLButtonElement>();
  const [rightThumbnailArrowButtonRef, rightThumbnailArrowButtonBounds] =
    useBounds<HTMLButtonElement>();

  const handleNext = () => setActiveIndex((activeIndex + 1) % urls.length);

  const handlePrev = () =>
    setActiveIndex(activeIndex === 0 ? urls.length - 1 : activeIndex - 1);

  const mainImageStyle = {
    transform: `translateX(-${activeIndex * 100}%)`,
  };

  const handleThumbnailPrev = () => {
    // If we are already at the left bounds, do nothing
    if (thumbnailStripOffset === 0) {
      return;
    }

    const distanceBetweenThumbArrows =
      rightThumbnailArrowButtonBounds.left -
      leftThumbnailArrowButtonBounds.right;
    const thumbnailWidth = firstThumbnailImageBounds.width + 4;
    // Reduce thumbnail offset by the minimum between the current offset or the distance between the arrows.
    const delta = Math.min(thumbnailStripOffset, distanceBetweenThumbArrows);
    // Apply delta and round down to a multiple of thumbnail width
    const newOffset =
      Math.floor((thumbnailStripOffset - delta) / thumbnailWidth) *
      thumbnailWidth;
    setThumbnailStripOffset(newOffset);
  };

  const handleThumbnailNext = () => {
    const thumbnailWidth = firstThumbnailImageBounds.width + 4;
    const thumbnailStripWidth = urls.length * thumbnailWidth;
    const thumbnailStripRight =
      -thumbnailStripOffset +
      thumbnailStripWidth +
      leftThumbnailArrowButtonBounds.right;

    // If we are already at the right bounds or the entire group of thumbnails fits onscreen, do nothing.
    if (thumbnailStripRight <= rightThumbnailArrowButtonBounds.left) {
      return;
    }

    const distanceBetweenThumbArrows =
      rightThumbnailArrowButtonBounds.left -
      leftThumbnailArrowButtonBounds.right;
    const remainingStripWidth =
      thumbnailStripRight - rightThumbnailArrowButtonBounds.left;
    // Increase offset by the minimum of the remaining strip width or the space between the thumbnail arrows.
    const delta = Math.min(remainingStripWidth, distanceBetweenThumbArrows);
    const newOffset =
      delta === remainingStripWidth
        ? thumbnailStripOffset + delta
        : Math.floor((thumbnailStripOffset + delta) / thumbnailWidth) *
          thumbnailWidth;
    setThumbnailStripOffset(newOffset);
  };

  const thumbnailStyle = {
    transform: `translateX(-${thumbnailStripOffset}px)`,
  };
  return (
    <div className={styles['c-photo']}>
      <div className={styles['c-photo__main']}>
        <ul className={styles['c-photo__main__list']}>
          {urls.map((url) => (
            <li
              className={styles['c-photo__main__list__item']}
              style={mainImageStyle}
              key={url.url}
            >
              {url.type === 'IMAGE' ? (
                <img src={url.url} alt="TODO" />
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  src={url.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              )}
            </li>
          ))}
        </ul>
        <button
          className={styles['c-photo__main__prev']}
          onClick={handlePrev}
        />
        <button
          className={styles['c-photo__main__next']}
          onClick={handleNext}
        />
      </div>
      <div className={styles['c-photo__thumbs']}>
        <ul className={styles['c-photo__thumbs__list']}>
          {urls.map((url, idx) => {
            const className =
              activeIndex === idx
                ? clsx(
                    styles['c-photo__thumbs__list__item'],
                    styles['is-active']
                  )
                : styles['c-photo__thumbs__list__item'];
            return (
              <li
                key={idx}
                className={className}
                style={thumbnailStyle}
                onClick={() => setActiveIndex(idx)}
              >
                {url.type === 'IMAGE' ? (
                  <img
                    ref={idx === 0 ? firstThumbnailImageRef : null}
                    src={url.url}
                    alt="TODO"
                  />
                ) : (
                  <img
                    ref={idx === 0 ? firstThumbnailImageRef : null}
                    src={getYouTubePreviewUrl(url.url)}
                    alt="TODO"
                  />
                )}
              </li>
            );
          })}
        </ul>
        <button
          ref={leftThumbnailArrowButtonRef}
          className={styles['c-photo__thumbs__prev']}
          onClick={handleThumbnailPrev}
        />
        <button
          ref={rightThumbnailArrowButtonRef}
          className={styles['c-photo__thumbs__next']}
          onClick={handleThumbnailNext}
        />
      </div>
    </div>
  );
};
