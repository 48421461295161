import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { EmailSettingsModal } from 'client/pages/v3/Reservation/ReservationDashBoard/EmailSettingsModal';
import { Button } from 'client/components/v3/Common/Button';

export const ReservationDashBoardHeader = () => {
  const { t } = useTranslation();
  const [showEmailModal, setShowEmailModal] = React.useState<boolean>(false);

  return (
    <>
      <PageHeader title={t('Dash')}>
        <Button
          type="button"
          text={t('Email Notifications')}
          size="md"
          color="white"
          onClick={() => setShowEmailModal(true)}
          iconBeforeText={
            <i className="c-icon-outline-general-settings-04"></i>
          }
        />
      </PageHeader>
      {showEmailModal && (
        <EmailSettingsModal setShowModal={setShowEmailModal} />
      )}
    </>
  );
};
