import type { Moment } from 'moment-timezone';
import type { TFunction } from 'react-i18next';
import moment from 'moment-timezone';

import type {
  Customer,
  CustomerFormFieldResponse,
} from 'shared/models/swagger';

export type CustomerShape = {
  id: string;
  customerName: string;
  email: string;
  tags: string[];
  lastParticipationDate: Moment | null;
  lastParticipatedProductName: string;
  mailStatus: string;

  supplierReference: string;
  creditCard: string;
  reservationCount: number;
  totalGross: string;
  membershipRegistered: Moment | null;

  shouldReceiveSpecialEmailOffers: boolean;
  alertedCustomer: boolean;
  staffMemos: { dateTime: Moment; body: string }[];

  reservationIds: string[];

  formFieldResponses: {
    key?: string;
    value?: string;
  }[];
  autotags?: string[];
};

export const toCustomerShape = (
  customer: Customer | null,
  t: TFunction,
  countries?: { [index: string]: string },
  lang?: string
): CustomerShape => ({
  id: customer?.id ?? '',
  customerName: customer?.display_full_name || '',
  email: customer?.email ?? '',
  tags: customer?.tags ?? [],
  lastParticipationDate: customer?.last_participation_date_time_utc
    ? moment.tz(
        customer?.last_participation_date_time_utc,
        customer?.start_timezone ?? 'UTC'
      )
    : null,
  lastParticipatedProductName: customer?.last_participated_product_name ?? '',
  mailStatus: customer?.should_receive_special_email_offers ? 'on' : 'off',

  supplierReference: customer?.supplier_reference?.value ?? '',
  creditCard: customer?.payment_profile_card_info
    ?.payment_profile_gateway_reference
    ? `${customer?.card_settlement_currency} xxxx-xxxx-xxxx-${
        customer?.payment_profile_card_info?.last_four_digits ?? 'xxxx'
      }`
    : '',
  reservationCount: customer?.reservation_count ?? 0,
  totalGross: '',
  membershipRegistered: moment.tz(
    customer?.registration_date_time_utc,
    customer?.start_timezone ?? 'UTC'
  ),

  shouldReceiveSpecialEmailOffers:
    customer?.should_receive_special_email_offers?.value ?? false,

  alertedCustomer: customer?.alerted_customer?.value ?? false,

  staffMemos: (customer?.staff_memos ?? []).map((memo) => ({
    dateTime: moment.tz(memo.date_time_utc, customer?.start_timezone ?? 'UTC'),
    body: memo.body ?? '',
  })),

  reservationIds: customer?.reservation_ids ?? [],

  formFieldResponses: toCustomerFormFieldResponseShape(
    customer?.form_field_responses ?? [],
    t,
    countries,
    lang
  ),
  autotags: customer?.auto_tags ?? [],
});

export const toCustomerFormFieldResponseShape = (
  formFieldResponses: CustomerFormFieldResponse[],
  t: TFunction,
  countries?: { [index: string]: string },
  lang?: string
): {
  key?: string;
  value?: string;
}[] => {
  return formFieldResponses.map((formFieldResponse) => {
    if (formFieldResponse.key == 'address') {
      return {
        key: formFieldResponse.key ?? '',
        value:
          lang !== 'ja'
            ? `${formFieldResponse.address?.address_line ?? ''} ${
                formFieldResponse.address?.city ?? ''
              } ${formFieldResponse.address?.province ?? ''} ${
                formFieldResponse.address?.postal_code ?? ''
              } ${countries?.[formFieldResponse.address?.country ?? ''] ?? ''}`
            : `${countries?.[formFieldResponse.address?.country ?? ''] ?? ''} ${
                formFieldResponse.address?.postal_code
                  ? '〒' + formFieldResponse.address?.postal_code
                  : ''
              } ${formFieldResponse.address?.province ?? ''} ${
                formFieldResponse.address?.city ?? ''
              } ${formFieldResponse.address?.address_line ?? ''}`,
      };
    }
    if (formFieldResponse.key == 'gender') {
      return {
        key: formFieldResponse.key ?? '',
        value: toGenderValue(formFieldResponse.value ?? '', t),
      };
    }
    return {
      key: formFieldResponse.key ?? '',
      value: formFieldResponse.value,
    };
  });
};

export const toGenderValue = (value: string, t: TFunction) => {
  if (value === 'FEMALE') {
    value = t('Female');
  } else if (value === 'MALE') {
    value = t('Male');
  } else {
    value = t('No Response');
  }
  return value;
};
