import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import ColorPicker from 'rc-color-picker';
import { CheckerboardTile } from '@nutmeglabs/falcon-ui';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

import { LinkEditor } from '../LinkEditor';

interface Props {
  onClose: () => void;
  value: CheckerboardTile;
  onChange: (arg0: CheckerboardTile) => void;
}

export const EditCheckerboardTileModal = ({
  onClose,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      insertRoot
      open={true}
      onClose={onClose}
      title={t('Edit Checkerboard Tile')}
    >
      <Form
        initialValues={value}
        onSubmit={(values: CheckerboardTile) => {
          onChange(values);
          onClose();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Field name="title">
                {({ input }) => <Input label={t('Title')} {...input} />}
              </Field>
              <Field name="description">
                {({ input }) => (
                  <TextArea
                    label={t('Description')}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              <Field name="imageUrl">
                {({ input, meta: { touched, error } }) => (
                  <FieldWrapper label={t('Image (jpg, png)')}>
                    <ImageVideoAudioInput
                      fileUrls={input.value ? [input.value] : []}
                      onChange={(newValue) =>
                        newValue.length > 0
                          ? input.onChange(newValue[0])
                          : input.onChange('')
                      }
                      maxFileCount={1}
                      disableYoutubeVideos
                      error={touched && error}
                    />
                  </FieldWrapper>
                )}
              </Field>
              <LinkEditor name="link" />
              <Field name="buttonText">
                {({ input }) => <Input label={t('Button Text')} {...input} />}
              </Field>
              <Field name="buttonTextColor">
                {({ input }) => (
                  <FieldWrapper label={t('Button Text Color')}>
                    <ColorPicker
                      color={input.value}
                      onChange={({ color }: any) => input.onChange(color)}
                    />
                  </FieldWrapper>
                )}
              </Field>
              <Field name="buttonColor">
                {({ input }) => (
                  <FieldWrapper label={t('Button Color')}>
                    <ColorPicker
                      color={input.value}
                      onChange={({ color }: any) => input.onChange(color)}
                    />
                  </FieldWrapper>
                )}
              </Field>
            </Modal.Content>
            <Modal.Actions>
              <Button.Update type="submit">{t('Update')}</Button.Update>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
