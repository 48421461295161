import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { setLanguage } from 'client/actions';
import type { ReduxState } from 'client/reducers';

import logo from '../../images/logo.svg';
import langIcon from '../../images/ic_lang.svg';

import styles from './LogoHeader.module.css';

const selectLanguageOptions = createSelector(
  (state: ReduxState) => state.language.options,
  (allLanguages) =>
    allLanguages.filter((lang) => lang.iso === 'en' || lang.iso === 'ja')
);
export const LogoHeader = () => {
  const dispatch = useDispatch();
  const languageOptions = useSelector(selectLanguageOptions);
  const selectedLanguage = useSelector(
    (state: ReduxState) => state.language.selected
  );
  return (
    <header className={styles['l-header']}>
      <div className={styles['c-header__inner']}>
        <img
          src={logo}
          className={styles['c-header__logo']}
          alt="nutmeg logo"
        />
        <div className={styles['c-header__lang']}>
          <div>
            <label className={styles['c-header__nav__item__itemselectLang']}>
              <img src={langIcon} alt="language icon" />
              <select
                name="lang"
                value={selectedLanguage.iso}
                onChange={(e) => {
                  dispatch(setLanguage(e.target.value));
                }}
              >
                {languageOptions.map((l) => (
                  <option key={l.iso} value={l.iso}>
                    {l.text}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </div>
    </header>
  );
};
