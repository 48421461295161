import * as React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

import styles from './TemplateCard.module.css';
import { PreviewModal } from './PreviewModal';

interface Props {
  title: string;
  description: string;
  thumbnailUrl: string;
  selected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
  templateType: string;
}

export const TemplateCard = ({
  title,
  description,
  thumbnailUrl,
  onSelect,
  onDeselect,
  selected,
  templateType,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const form = useForm();

  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  return (
    <div
      className={clsx(styles['template-card'], selected && styles['selected'])}
    >
      <div className={styles['template-card__header']}>{title}</div>
      <div className={styles['template-card__body']}>{description}</div>
      <div className={styles['template-card__img']}>
        <img src={thumbnailUrl} />
      </div>
      <div className={styles['template-card__actions']}>
        <Button
          style="gray"
          size="middle"
          onClick={() => setShowPreviewModal(true)}
        >
          {t('Preview')}
        </Button>
        {showPreviewModal && (
          <PreviewModal
            onClose={() => setShowPreviewModal(false)}
            onSelect={() => {
              onSelect();
              setLoading(true);
              form.submit();
            }}
            onCancel={onDeselect}
            templateType={templateType}
          />
        )}
        <Box mt={2}>
          <Button
            onClick={() => {
              onSelect();
              setLoading(true);
              form.submit();
            }}
            style="blue"
            size="middle"
            loading={loading}
          >
            {t('Use This Template')}
          </Button>
        </Box>
      </div>
    </div>
  );
};
