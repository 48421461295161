import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from 'client/hooks/useWindowDimensions';
import { Box } from 'client/components/Box/Box';

import styles from './GenericTable.module.css';

export interface ColumnType<T> {
  Header: string;
  id: string;
  accessor: (arg: T) => any;
  th?: boolean;
  sub?: boolean;
  width?: number;
  icon?: string;
}

interface Props<T> {
  items: T[];
  columns: ColumnType<T>[];
  rowCount: number;
  currentPage: number;
  onRowCountChange: (rowCount: number) => void;
  onCurrentPageChange: (page: number) => void;
  totalCount: number;
  draggable?: boolean;
  onDragStart?: (item: T) => void;
  onDragEnd?: (item: T) => void;
  highlightRow?: (item: T) => boolean;
  hideScrollButtons?: boolean;
  onTrMouseEnter?: (item: T) => void;
  onTrMouseLeave?: (item: T) => void;
  onTableMouseLeave?: () => void;
}

export const GenericTable = <T,>({
  items,
  columns,
  rowCount,
  currentPage,
  onRowCountChange,
  onCurrentPageChange,
  totalCount,
  draggable = false,
  onDragStart,
  onDragEnd,
  highlightRow,
  hideScrollButtons = false,
  onTrMouseEnter,
  onTrMouseLeave,
  onTableMouseLeave,
}: Props<T>) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const pageCount =
    Math.floor(totalCount / rowCount) + (totalCount % rowCount ? 1 : 0);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const isMobileView = width < 786;

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  return (
    <>
      <div className={clsx(styles['c-table-main'])}>
        <div
          className={clsx(styles['c-table-main__thead'])}
          ref={tableHeaderRef}
        >
          <table>
            <tbody>
              <tr>
                {columns.map((column, idx) => {
                  const width = column.width || '';
                  return (
                    <th
                      style={isMobileView ? {} : { width: `${width}px` }}
                      key={idx}
                    >
                      {column.icon && (
                        <Box display="flex" alignItems="center">
                          <img
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                            src={column.icon}
                            alt={column.Header}
                          />
                        </Box>
                      )}
                      {!column.icon && column.Header}
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={clsx(styles['c-table-main__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table
            onMouseLeave={() => {
              onTableMouseLeave?.();
            }}
          >
            <tbody>
              {items.map((item, ridx) => {
                return (
                  <tr
                    draggable={draggable}
                    key={ridx}
                    onDragStart={() => {
                      onDragStart?.(item);
                    }}
                    onDragEnd={() => {
                      onDragEnd?.(item);
                    }}
                    style={
                      {
                        backgroundColor: highlightRow?.(item) ? 'yellow' : '',
                      } as any
                    }
                    onMouseEnter={() => {
                      onTrMouseEnter?.(item);
                    }}
                    onMouseLeave={() => {
                      onTrMouseLeave?.(item);
                    }}
                  >
                    {columns.map((column, idx) => {
                      const width = column.width || '';

                      const displayValue = column.accessor(item);

                      if (column.th) {
                        return (
                          <th
                            key={idx}
                            style={isMobileView ? {} : { width: `${width}px` }}
                          >
                            {displayValue}
                          </th>
                        );
                      } else {
                        return (
                          <td
                            key={idx}
                            style={isMobileView ? {} : { width: `${width}px` }}
                            className={clsx(
                              column.sub ? styles['c-table-sub__btn'] : ''
                            )}
                            data-text={column.Header}
                          >
                            {displayValue}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {!hideScrollButtons && (
          <>
            <div
              className={clsx(styles['c-table-main__btn'], styles['prev'])}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>
            <div
              className={clsx(styles['c-table-main__btn'], styles['next'])}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </>
        )}
      </div>

      <div className={clsx(styles['c-pagination'])}>
        <div className={clsx(styles['c-pagination__size'])}>
          <p>{t('Number of lines')}:</p>
          <label>
            <select
              value={rowCount}
              onChange={(event: any) => {
                const count = parseInt(event.target.value, 10);
                onRowCountChange(count);
              }}
            >
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
        <div className={clsx(styles['c-pagination__jump'])}>
          <p>{t('Page')}:</p>
          <p>
            {currentPage} of {pageCount}
          </p>
        </div>
        <div className={clsx(styles['c-pagination__btns'])}>
          <a
            className={clsx(
              styles['c-pagination__btn'],
              1 < currentPage ? '' : styles['disable']
            )}
            onClick={pageBackClickHandler}
          >
            {t('Previous')}
          </a>
          <a
            className={clsx(
              styles['c-pagination__btn'],
              currentPage < pageCount ? '' : styles['disable']
            )}
            onClick={pageForwardClickHandler}
          >
            {t('Next')}
          </a>
        </div>
      </div>
    </>
  );
};
