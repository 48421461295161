import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form, Field, useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { getArrayMutators } from 'client/libraries/util/form';
import { getValidators } from 'shared/libraries/validate/validator';
import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';

import { convertFormValuesToSwaggerAdditionalImage } from '../FormValues';
import type { AdditionalImage, TopPageSection } from '../FormValues';
import { updateTopPageAdditionalImage } from '../util';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
  language: Swagger.SourceLanguage;
  onSave?: (value: AdditionalImage) => void;
};
export const EditAdditionalImageModal = ({
  name,
  open,
  onClose,
  onSave,
  language,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm<TopPageSection>();
  const formState = useFormState<TopPageSection>();
  const { required } = getValidators(t);

  const initialValuesFromForm = _.get(formState.values, name);

  const initialValues = initialValuesFromForm
    ? initialValuesFromForm
    : {
        title: '',
        description: '',
        imageUrl: '',
        linkUrl: '',
        key: uuidv4(),
      };
  return (
    <Modal
      title={t('Edit additional photo')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={_.get(formState.values, name)}
        keepDirtyOnReinitialize
        onSubmit={async (values: AdditionalImage) => {
          await dispatch(
            updateTopPageAdditionalImage(
              convertFormValuesToSwaggerAdditionalImage(values),
              language
            )
          );
          onSave?.(values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <FieldWrapper label={t('Photo')}>
                <Field name="imageUrl" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <ImageVideoAudioInput
                      fileUrls={input.value ? [input.value] : []}
                      onChange={(newValue) =>
                        newValue.length > 0
                          ? input.onChange(newValue[0])
                          : input.onChange('')
                      }
                      maxFileCount={1}
                      disableYoutubeVideos
                      error={touched && error}
                    />
                  )}
                </Field>
              </FieldWrapper>
              <Box mb={2}>
                <Field name="title" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <Input
                      label={t('Title')}
                      error={touched && error}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Field name="description">
                  {({ input }) => (
                    <TextArea label={t('Description')} {...(input as any)} />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Field name="linkUrl">
                  {({ input }) => <Input label={t('URL')} {...input} />}
                </Field>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  form.change(name as any, initialValues);
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                size="middle"
                style="blue"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
