// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';

import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { updateManifestExcludedFormFields } from 'client/actions/manifestSettings';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import { productOptionsSelector } from 'client/reducers/products';
import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { Select, Button } from 'client/components/Form';

type OwnProps = {
  trigger: React.Element<'a' | 'button'>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

const formFieldKeysExcludedByDefault = [
  'given_name',
  'family_name',
  'representative_name',
  'hotel_information_checkin_checkout',
  'hotel_information',
  'hotel_tbd_form',
];

export const EditExcludedFormFieldsModalComponent = ({
  productOptions,
  existingExcludedFormFields,
  fetchProductByID,
  fetchProducts,
  loading,
  productsById,
  trigger,
  updateManifestExcludedFormFields,
}: Props) => {
  const [newExcludedFormFields, setNewExcludedFormFields] = React.useState<
    string[]
  >(existingExcludedFormFields);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = React.useState<string>('');

  const selectedProduct = productsById[selectedProductId];

  const { t } = useTranslation();

  React.useEffect(() => {
    if (productOptions.length === 0) {
      fetchProducts();
    }
  }, [productOptions.length, fetchProducts]);

  React.useEffect(() => {
    if (!selectedProduct) {
      fetchProductByID(selectedProductId);
    }
  }, [selectedProductId, selectedProduct, fetchProductByID]);

  const commonFormFieldsByKey = getCommonFormFieldsByKey(t);

  const headerText = t('Edit Filtered Required Info Fields');
  return (
    <Modal
      title={headerText}
      trigger={trigger}
      open={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
    >
      <Modal.Content>
        <>
          {existingExcludedFormFields.length > 0 && (
            <table>
              <tbody>
                {existingExcludedFormFields.map((fieldKey) => {
                  const fieldText =
                    commonFormFieldsByKey[fieldKey ?? '']?.text ?? fieldKey;

                  return newExcludedFormFields.indexOf(fieldKey) !== -1 ? (
                    <tr key={fieldKey}>
                      <td>
                        <Button
                          style="gray"
                          size="small"
                          onClick={() =>
                            setNewExcludedFormFields(
                              newExcludedFormFields.filter(
                                (field) => field !== fieldKey
                              )
                            )
                          }
                        >
                          {t('Include')}
                        </Button>
                      </td>
                      <td style={{ opacity: 0.4 }}>{fieldText}</td>
                    </tr>
                  ) : (
                    <tr key={fieldKey}>
                      <td>
                        <Button
                          style="gray"
                          size="small"
                          onClick={() =>
                            setNewExcludedFormFields([
                              ...new Set([...newExcludedFormFields, fieldKey]),
                            ])
                          }
                        >
                          {t('Exclude')}
                        </Button>
                      </td>
                      <td>{fieldText}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <Message>
            {t(
              "Select any product to load the product's required information fields. Once loaded, mark any field for exclusion from all manifests."
            )}
          </Message>
          <Select
            label={t('Select product')}
            options={productOptions}
            disabled={productOptions.length === 0}
            value={selectedProductId}
            loading={loading}
            onChange={(e, { value }) => setSelectedProductId(value)}
          />
        </>
        {selectedProduct ? (
          <table>
            <tbody>
              {selectedProduct.reservation_form_fields
                ?.filter(
                  (f) => formFieldKeysExcludedByDefault.indexOf(f.key) === -1
                )
                .map((f) => {
                  const fieldText =
                    commonFormFieldsByKey[f.key ?? '']?.text ?? f.key;

                  return newExcludedFormFields.indexOf(f.key) !== -1 ? (
                    <tr key={f.key}>
                      <td>
                        <Button
                          style="gray"
                          size="small"
                          onClick={() =>
                            setNewExcludedFormFields(
                              newExcludedFormFields.filter(
                                (field) => field !== f.key
                              )
                            )
                          }
                        >
                          {t('Include')}
                        </Button>
                      </td>
                      <td style={{ opacity: 0.4 }}>{fieldText}</td>
                    </tr>
                  ) : (
                    <tr key={f.key}>
                      <td>
                        <Button
                          style="gray"
                          size="small"
                          onClick={() =>
                            setNewExcludedFormFields([
                              ...new Set([
                                ...newExcludedFormFields,
                                f.key ?? '',
                              ]),
                            ])
                          }
                        >
                          {t('Exclude')}
                        </Button>
                      </td>
                      <td>{fieldText}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : loading ? (
          <ModalLoader />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          disabled={newExcludedFormFields === existingExcludedFormFields}
          onClick={() => {
            setNewExcludedFormFields(existingExcludedFormFields);
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          positive
          disabled={newExcludedFormFields === existingExcludedFormFields}
          onClick={() => {
            updateManifestExcludedFormFields(newExcludedFormFields);
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  productOptions: productOptionsSelector(state),
  loading: state.products.loading,
  productsById: state.products.byID,
  existingExcludedFormFields: manifestExcludedFormFieldsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchProducts: () => dispatch(fetchProducts()),
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
  updateManifestExcludedFormFields: (excludedFormFields: string[]) =>
    dispatch(updateManifestExcludedFormFields(excludedFormFields)),
});

export const EditExcludedFormFieldsModal = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(EditExcludedFormFieldsModalComponent);
