import moment from 'moment';

import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import {
  BookingDeadline,
  CancellationPolicy,
  convertToBookingDeadline,
  convertToCancellationPolicy,
  isDefaultCancellationPolicy,
} from 'client/libraries/util/productShape';
import {
  BasicFormValues,
  convertFormValuesToPricing,
  getInitialPriceSchedules,
} from 'client/pages/ProductEditor/BasicEditor/FormValues';
import {
  FormValues as BookingWidgetSettingsFormValues,
  getInitialValues as getInitialValuesForBookingWidgetSettings,
  convertFormValuesToProductPatch as convertFormValuesToProductPatchForBookingWidgetSettings,
} from 'client/pages/ProductDetails/ProductContentsHeader/BookingWidgetSettingsFormValues';
import {
  ReservationParamsFormValues,
  convertFormValuesToRecurrence,
  getInitialAvailabilityAllotmentSchedules,
  schedulesHaveRequestOnly,
} from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import {
  BookingDeadline$Patch,
  CancellationPolicy$Patch,
  Organization,
  Pricing$Patch,
  Product,
  Product$Patch,
} from 'shared/models/swagger';
import {
  convertToBookingDeadlinePatch,
  convertToCancellationPolicyPatch,
} from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { FormValues } from 'client/components/NewProductEditor/DetailsStep/FormValues';
import {
  convertCheckinPickupFormValuesToProductPatch,
  getInitialCheckinPickupFormValues,
} from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/FormValues';
import {
  convertDetailInfoFormValuesToProductPatch,
  getInitialDetailInfoFormValues,
} from 'client/components/NewProductEditor/DetailsStep/DetailInfoEditor/FormValues';
import {
  convertHighlightsFormValuesToProductPatch,
  getInitialHighlightsFormValues,
} from 'client/components/NewProductEditor/DetailsStep/HighlightsEditor/FormValues';
import {
  convertInternalTagFormValuesToProductPatch,
  getInitialInternalTagFormValues,
} from 'client/components/NewProductEditor/DetailsStep/InternalTagsEditor/FormValues';
import {
  convertItineraryFormValuesToProductPatch,
  getInitialItineraryFormValues,
} from 'client/components/NewProductEditor/DetailsStep/ItineraryEditor/FormValues';
import {
  MinimumParticipantFormValues,
  getInitialMinimumParticipantFormValues,
} from 'client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/FormValues';
import {
  convertReservationFormFormValuesToProductPatch,
  getInitialReservationFormFormValues,
} from 'client/components/NewProductEditor/DetailsStep/ReservationFormEditor/FormValues';
import {
  convertFormValueServicesToProductServices,
  getInitialServiceFormValues,
} from 'client/components/NewProductEditor/DetailsStep/ServicesEditor/FormValues';
import {
  convertTagFormValuesToProductPatch,
  getInitialTagFormValues,
} from 'client/components/NewProductEditor/DetailsStep/TagsEditor/FormValues';
import {
  convertTransportRouteFormValuesToProductPatch,
  getInitialTransportRouteFormValues,
} from 'client/components/NewProductEditor/DetailsStep/TransportRouteEditor/FormValues';
import { TranslateFuncType } from 'client/components/Translate';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';

type LocationFormValues = {
  area: {
    name: string;
    googlePlaceId: string;
  };
};

export type ProductFormValues = BasicFormValues &
  BookingWidgetSettingsFormValues &
  LocationFormValues &
  ReservationParamsFormValues &
  MinimumParticipantFormValues &
  // Omit minimum participant because it is moved to reservation params
  Omit<FormValues, 'MinimumParticipantFormValues'>;

export const getInitialValues = (
  product: Product | null,
  defaultCurrency: string,
  defaultTimezone: string,
  t: TranslateFuncType,
  showNet: boolean,
  organization?: Organization | null
): ProductFormValues => {
  // For reservation parameters section
  const availabilityAllotmentSchedules =
    getInitialAvailabilityAllotmentSchedules(product, []);
  let instantBookingDeadline: BookingDeadline | undefined = undefined;
  let requestBookingDeadline: BookingDeadline | undefined = undefined;
  const productHasBookingDeadlines =
    product?.booking_deadlines != null && product.booking_deadlines.length > 0;
  const productInstantBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'INSTANT'
  );
  const productRequestBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'REQUEST'
  );

  if (productHasBookingDeadlines) {
    if (productInstantBookingDeadline) {
      instantBookingDeadline = convertToBookingDeadline(
        productInstantBookingDeadline
      );
    }

    if (productRequestBookingDeadline) {
      requestBookingDeadline = convertToBookingDeadline(
        productRequestBookingDeadline
      );
    }
  } else {
    instantBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 2,
      timeOfDay: '17:00',
    };
    requestBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 1,
      timeOfDay: '17:00',
    };
  }

  const cancellationPolicies: CancellationPolicy[] =
    product?.cancellation_policies != null &&
    product.cancellation_policies.length > 0
      ? product.cancellation_policies
          .filter(
            (cancellationPolicy) =>
              !isDefaultCancellationPolicy(cancellationPolicy)
          )
          .map((cancellationPolicy) =>
            convertToCancellationPolicy(cancellationPolicy)
          )
      : [
          {
            deadlineType: 'DAY',
            daysBefore: 2,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '0',
          },
          {
            deadlineType: 'DAY',
            daysBefore: 1,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '50',
          },
        ];

  return {
    // Values for basic section
    productCode: product?.supplier_reference ?? '',
    productName: product?.product_name ?? '',
    internalProductName: product?.internal_product_name ?? '',
    productDescription: product?.description ?? '',
    mediaUrls: (product?.media || []).map((mediaItem) => mediaItem.url),
    priceSchedules: getInitialPriceSchedules(product),
    ...getInitialValuesForBookingWidgetSettings(product, organization ?? null),
    area: {
      name: product?.area_name ?? '',
      googlePlaceId: product?.area_google_place_id ?? '',
    },

    // Values for reservation parameters section
    availabilityAllotmentSchedules,
    instantBookingDeadline,
    requestBookingDeadline,
    cancellationPolicies,
    timezone: product?.start_timezone ?? '',
    agentGuestBookingAllowedDaysBefore:
      product?.agent_guest_booking_period_settings
        ?.booking_allowed_days_before_participation ?? null,
    shouldUseBookingStartDateTime:
      product?.agent_guest_booking_period_settings
        ?.should_use_booking_start_date_time ?? false,
    bookingStartDateTimeUtc:
      product?.agent_guest_booking_period_settings
        ?.booking_start_date_time_utc ?? moment.utc().format(),
    allowRequestBookingBeyondCapacity:
      !product?.request_booking_settings
        ?.should_reject_bookings_beyond_capacity,
    ...getInitialMinimumParticipantFormValues(product),

    // Values for details section
    ...getInitialCheckinPickupFormValues(product),
    ...getInitialDetailInfoFormValues(product),
    ...getInitialHighlightsFormValues(product),
    ...getInitialItineraryFormValues(product),
    ...getInitialReservationFormFormValues(
      product,
      defaultCurrency,
      defaultTimezone,
      t
    ),
    addOns: getInitialServiceFormValues(product?.add_ons ?? [], showNet),
    transportations: getInitialServiceFormValues(
      product?.transportations ?? [],
      showNet
    ),
    ...getInitialTagFormValues(product),
    ...getInitialTransportRouteFormValues(product),
    ...getInitialInternalTagFormValues(product),
    shouldSendReminderEmail:
      !product?.guest_email_send_settings?.do_not_send_reminder_email,
  };
};

export const convertFormValuesToProductPatch = (
  values: ProductFormValues,
  defaultCurrency: string,
  isPackage: boolean,
  editingProduct: Product | null,
  t: TranslateFuncType,
  showNet: boolean
): Product$Patch => {
  // For reservation parameters section
  const bookingDeadlines: BookingDeadline$Patch[] = [];

  if (values.requestBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.requestBookingDeadline, 'REQUEST')
    );
  }

  if (values.instantBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.instantBookingDeadline, 'INSTANT')
    );
  }

  const cancellationPolicies: CancellationPolicy$Patch[] = [];

  for (const cancellationPolicy of values.cancellationPolicies) {
    cancellationPolicies.push(
      convertToCancellationPolicyPatch(cancellationPolicy, defaultCurrency)
    );
  }

  if (
    !cancellationPolicies.some(
      (policy) => policy.relative_date?.type === 'ETERNAL'
    )
  ) {
    cancellationPolicies.push({
      relative_date: {
        type: 'HOUR',
        count: 0,
      },
      charge_percent: 100,
    });
  }

  const recurrence = convertFormValuesToRecurrence(
    values.availabilityAllotmentSchedules
  );
  const allotmentRules = !isPackage
    ? values.availabilityAllotmentSchedules.map((sched) => ({
        start_date_local:
          sched.dateRanges.length > 0 ? sched.dateRanges[0].startDate : '',
        end_date_local:
          sched.dateRanges.length > 0 ? sched.dateRanges[0].endDate : '',
        additional_date_ranges: sched.dateRanges.slice(1).map((dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })),
        exception_dates: sched.closedDates
          .filter((closedDate) => !closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
        annually_repeating_closed_dates: sched.closedDates
          .filter((closedDate) => closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
        days_of_week: sched.weekdays,
        start_time_allotments: sched.startTimes.map((startTime) => ({
          time_slot_key:
            startTime.timeSlotKey || `${startTime.time}-${startTime.duration}`,
          common_allotment_slots: parseInt(startTime.allotmentSlots as any),
          agent_allotments: (startTime.perChannelAllotments ?? []).map(
            (perChannelAllotment) => ({
              channel_category: perChannelAllotment.channel,
              agent_id: perChannelAllotment.agentId ?? '',
              slots: parseInt(perChannelAllotment.allotmentSlots as any),
            })
          ),
        })),
      }))
    : [];
  const isRequestOnlyProduct = schedulesHaveRequestOnly(
    values.availabilityAllotmentSchedules
  );

  const sourceLanguageUppercaseIso = editingProduct?.source_language ?? 'JA_JP';
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];

  const pricing = convertFormValuesToPricing(
    values.priceSchedules,
    defaultCurrency
  );

  const patch: Product$Patch = {
    // Values for basic section
    supplier_reference: values.productCode,
    product_name: values.productName,
    internal_product_name: values.internalProductName,
    description: values.productDescription,
    media: convertMediaUrlsToProductMediaItems(values.mediaUrls || []) as any,
    pricing: pricing,
    ...convertFormValuesToProductPatchForBookingWidgetSettings(values),
    area_name: values.area.name,
    area_google_place_id: values.area.googlePlaceId,

    // Values for reservation parameters section
    booking_deadlines: bookingDeadlines,
    cancellation_policies: cancellationPolicies,
    recurrence,
    allotment_rules: allotmentRules,
    start_timezone: values.timezone,
    agent_guest_booking_period_settings: {
      booking_allowed_days_before_participation:
        values.agentGuestBookingAllowedDaysBefore ?? 0,
      should_use_booking_start_date_time: values.shouldUseBookingStartDateTime,
      booking_start_date_time_utc: values.bookingStartDateTimeUtc,
    },
    request_booking_settings: {
      should_reject_bookings_beyond_capacity:
        !isRequestOnlyProduct && !values.allowRequestBookingBeyondCapacity,
    },
    // Note: as a side effect this fills in minimum participant info in product.pricing
    // Since form values are combined together now, use the currently edited pricing values
    ...convertMinimumParticipantFormValuesToProductPatch(values, pricing),

    // Values for details section
    ...convertCheckinPickupFormValuesToProductPatch(values),
    ...convertDetailInfoFormValuesToProductPatch(values),
    ...convertHighlightsFormValuesToProductPatch(values),
    ...convertItineraryFormValuesToProductPatch(values),
    ...convertReservationFormFormValuesToProductPatch(
      values,
      t,
      sourceLanguageLowercaseIso
    ),
    add_ons: convertFormValueServicesToProductServices(
      values.addOns,
      defaultCurrency,
      showNet
    ),
    transportations: convertFormValueServicesToProductServices(
      values.transportations,
      defaultCurrency,
      showNet
    ),
    ...convertTagFormValuesToProductPatch(values),
    ...convertTransportRouteFormValuesToProductPatch(values),
    ...convertInternalTagFormValuesToProductPatch(values),
    guest_email_send_settings: {
      do_not_send_reminder_email: !values.shouldSendReminderEmail,
    },
  };

  // If we are setting transportations, then ensure that 'hotel_information' is also present in
  // reservation_form_fields
  if ((patch?.transportations || []).length > 0) {
    const reservationFormFields = (patch?.reservation_form_fields ?? []).filter(
      (f) => f.key !== 'hotel_information' && f.key !== 'hotel_tbd_form'
    );
    patch.reservation_form_fields = [
      ...reservationFormFields,
      {
        key: 'hotel_information',
        type: 'PER_BOOKING',
        prompt: t('Hotel Information', {
          lng: sourceLanguageLowercaseIso,
        }),
        format: '',
        required: 'WHEN_BOOKING',
      },
      {
        key: 'hotel_tbd_form',
        type: 'PER_BOOKING',
        prompt: t('Hotel TBD Form', {
          lng: sourceLanguageLowercaseIso,
        }),
        format: '',
        required: 'OPTIONAL',
      },
    ];
  }

  return patch;
};

// Moved this from client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/FormValues.ts
// because we want to use currently edited values for pricing instead previous values in editing product
export const convertMinimumParticipantFormValuesToProductPatch = (
  values: MinimumParticipantFormValues,
  pricing: Pricing$Patch[] | null
): Product$Patch => {
  return {
    minimum_participant_count: {
      value: values.minimumParticipantCount,
    },
    pricing: pricing?.map((priceRule) => {
      const newPriceRule: any = {
        ...priceRule,
        units: priceRule.units?.map((unit) => {
          if (unit.method === 'PER_PARTICIPANT' && unit.guest_type) {
            return {
              ...unit,
              guest_type: {
                ...unit.guest_type,
                minimum_participant_parameters: {
                  weight:
                    values.unitWeights.find(
                      (unitWeight) =>
                        unitWeight.unitKey === unit.guest_type?.key
                    )?.weight ?? 1,
                },
              },
            };
          }

          return { ...unit };
        }),
      };
      return newPriceRule;
    }),
    allotment_settings: {
      allotment_counting_type: values.usePerBookingInventoryCounting
        ? 'PER_BOOKING'
        : 'DEFAULT',
      inventory_consumption_rules: values.unitWeights?.map((unitWeight) => ({
        unit: unitWeight.unitKey,
        should_not_count_inventory: !unitWeight.shouldConsumeInventory,
      })),
    },
  };
};
