import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { memosSelector } from 'client/reducers/memos';
import {
  fetchMemos,
  createMemo,
  updateMemo,
  deleteMemo,
} from 'client/actions/memos';
import { getArrayMutators } from 'client/libraries/util/form';
import baseStyles from 'client/base.module.css';

import type { FormValues } from './formValues';

type Props = {
  memoKey: string;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};
export const MemoModal = ({ memoKey, open, onOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memos = useSelector(memosSelector);
  React.useEffect(() => {
    if (memoKey) {
      dispatch(fetchMemos(memoKey));
    }
  }, [memoKey]);
  const memo = memos.find((memo) => memo.key === memoKey);
  const title = t('Edit memo');
  const initialValue = memo
    ? {
        content: memo.content,
      }
    : {
        content: '',
      };
  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      onOpen={() => {
        onOpen && onOpen();
      }}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            if (memo) {
              if (values.content) {
                dispatch(
                  updateMemo(memo.id, {
                    content: values.content,
                  })
                );
              } else {
                dispatch(deleteMemo(memo.id));
              }
            } else {
              if (values.content) {
                dispatch(
                  createMemo({
                    key: memoKey,
                    content: values.content,
                  })
                );
              }
            }

            onClose && onClose();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={initialValue}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field name={'content'}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Memo')}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button.Cancel
                  onClick={() => {
                    onClose && onClose();
                  }}
                >
                  {t('Discard')}
                </Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
