import * as React from 'react';

interface Props {
  data: {
    title: string;
    date: string;
    style: string;
  };
}

export const HighlightedBookingDeadlineSection = ({ data }: Props) => {
  const { title, date, style } = data;

  return (
    <div
      style={{
        marginTop: '32px',
        marginBottom: '24px',
        borderRadius: '8px',
        border: `2px solid ${
          style === 'BLUE'
            ? '#3B82F6'
            : style === 'YELLOW'
            ? '#EAB308'
            : '#F43F5E'
        }`,
      }}
    >
      <div
        style={{
          backgroundColor:
            style === 'BLUE'
              ? '#3B82F6'
              : style === 'YELLOW'
              ? '#EAB308'
              : '#F43F5E',
          padding: '16px',
          textAlign: 'center',
        }}
      >
        <img
          src="https://assets.ntmg.com/newsletter/calendar3.png"
          style={{
            width: '18px',
            height: '18px',
            display: 'inline-block',
            verticalAlign: 'middle',
            marginBottom: '2px',
            marginRight: '6px',
          }}
        />
        <p
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 700,
            fontFamily: 'Hiragino Sans',
            color: '#FFF',
            display: 'inline-block',
            margin: '0',
            verticalAlign: 'middle',
          }}
        >
          {title}
        </p>
      </div>
      <div style={{ padding: '24px' }}>
        <p
          style={{
            fontSize: '20px',
            fontWeight: 700,
            fontFamily: 'Hiragino Sans',
            lineHeight: '28px',
            color: '#3F3F46',
            margin: 0,
            padding: 0,
            textAlign: 'center',
          }}
        >
          {date}
        </p>
      </div>
    </div>
  );
};
