import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

import { RadioButtonGroup } from './RadioButtonGroup';

type QuestionType = 'CUSTOM' | 'INITIALS' | 'FULL_NAME' | 'REPRESENTATIVE';
type Props = {
  onSave: (text: string) => void;
  trigger: React.ReactElement<'a' | 'button'>;
  existingQuestionText?: string;
  lng: string;
};
export const TextEditorInsertQuestionModal = ({
  onSave,
  trigger,
  existingQuestionText,
  lng,
}: Props) => {
  const { t } = useTranslation();
  const questionTypeOptions: { value: QuestionType; label: string }[] = [
    {
      value: 'INITIALS',
      label: t('Initials', {
        lng,
      }),
    },
    {
      value: 'FULL_NAME',
      label: t('Full Name', {
        lng,
      }),
    },
    {
      value: 'REPRESENTATIVE',
      label: t('Representative', {
        lng,
      }),
    },
    {
      value: 'CUSTOM',
      label: t('Custom'),
    },
  ];
  const initialQuestionText =
    existingQuestionText ??
    t('Initials', {
      lng,
    }) ??
    '';
  const initialQuestionType =
    questionTypeOptions.find((option) => option.label === initialQuestionText)
      ?.value ?? 'CUSTOM';
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [questionType, setQuestionType] =
    React.useState<QuestionType>(initialQuestionType);
  const [questionText, setQuestionText] =
    React.useState<string>(initialQuestionText);
  return (
    <Modal
      title={t('Insert Question')}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => {
        setShowModal(false);
      }}
      trigger={trigger}
    >
      <Modal.Content>
        <FieldWrapper label={t('Question Type')}>
          <RadioButtonGroup
            options={questionTypeOptions}
            value={questionType}
            onChange={(newValue: string) => {
              const questionType: any = newValue;
              setQuestionType(questionType);

              switch (newValue) {
                case 'INITIALS':
                  setQuestionText(t('Initials'));
                  break;

                case 'FULL_NAME':
                  setQuestionText(t('Full Name'));
                  break;

                case 'CUSTOM':
                  setQuestionText('');
              }
            }}
          />
        </FieldWrapper>
        {questionType === 'CUSTOM' && (
          <Input
            value={questionText}
            onChange={(e: any) => setQuestionText(e.target.value)}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="gray"
          size="small"
          onClick={() => {
            setQuestionText('');
            setShowModal(false);
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          style="blue"
          size="small"
          onClick={() => {
            onSave(questionText);
            setQuestionText('');
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
