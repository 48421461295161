import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_INSTANT_WINS_SUCCESS,
  FETCH_INSTANT_WINS_FAILURE,
  FETCH_INSTANT_WINS_REQUEST,
  FETCH_INSTANT_WIN_SUCCESS,
  FETCH_INSTANT_WIN_FAILURE,
  FETCH_INSTANT_WIN_REQUEST,
  DELETE_INSTANT_WIN_FAILURE,
  DELETE_INSTANT_WIN_REQUEST,
  DELETE_INSTANT_WIN_SUCCESS,
  CREATE_INSTANT_WIN_REQUEST,
  UPDATE_INSTANT_WIN_FAILURE,
  UPDATE_INSTANT_WIN_SUCCESS,
  UPDATE_INSTANT_WIN_REQUEST,
  CREATE_INSTANT_WIN_FAILURE,
  CREATE_INSTANT_WIN_SUCCESS,
  GET_INSTANT_WIN_STATS_REQUEST,
  GET_INSTANT_WIN_STATS_SUCCESS,
  GET_INSTANT_WIN_STATS_FAILURE,
  DOWNLOAD_INSTANT_WIN_CSV_FAILURE,
  DOWNLOAD_INSTANT_WIN_CSV_REQUEST,
  DOWNLOAD_INSTANT_WIN_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { InstantWinParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchInstantWinsRequest = () => ({
  type: FETCH_INSTANT_WINS_REQUEST,
});

const fetchInstantWinsSuccess = (response: any) => ({
  type: FETCH_INSTANT_WINS_SUCCESS,
  response,
});

const fetchInstantWinsFailure = (error: any) => ({
  type: FETCH_INSTANT_WINS_FAILURE,
  error,
});

let fetchInstantWinsCancel: () => void | undefined;
export const fetchInstantWins =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchInstantWinsCancel?.();
    dispatch(fetchInstantWinsRequest());
    axios
      .get('/api/instantwins', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchInstantWinsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchInstantWinsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchInstantWinsFailure('canceled'));
        dispatch(fetchInstantWinsFailure(error));
      });
  };

const fetchInstantWinRequest = () => ({
  type: FETCH_INSTANT_WIN_REQUEST,
});

const fetchInstantWinSuccess = (response: any) => ({
  type: FETCH_INSTANT_WIN_SUCCESS,
  response,
});

const fetchInstantWinFailure = (error: any) => ({
  type: FETCH_INSTANT_WIN_FAILURE,
  error,
});

export const fetchInstantWin =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInstantWinRequest());
    axios
      .get(`/api/instantwins/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchInstantWinSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchInstantWinFailure('canceled'));
        else dispatch(fetchInstantWinFailure(error));
      });
  };

const deleteInstantWinRequest = (id: string) => ({
  type: DELETE_INSTANT_WIN_REQUEST,
  id,
});

const deleteInstantWinSuccess = (response: any, id: string) => ({
  type: DELETE_INSTANT_WIN_SUCCESS,
  response,
  id,
});

const deleteInstantWinFailure = (error: any) => ({
  type: DELETE_INSTANT_WIN_FAILURE,
  error,
});

export const deleteInstantWin =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteInstantWinRequest(id));
    axios
      .delete(`/api/instantwins/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteInstantWinSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteInstantWinFailure(error)));
  };

const createInstantWinRequest = (newInstantWin: InstantWinParams) => ({
  type: CREATE_INSTANT_WIN_REQUEST,
  newInstantWin,
});

const createInstantWinSuccess = (response: any) => ({
  type: CREATE_INSTANT_WIN_SUCCESS,
  response,
});

const createInstantWinFailure = (error: any) => ({
  type: CREATE_INSTANT_WIN_FAILURE,
  error,
});

export const createInstantWin =
  (newInstantWin: InstantWinParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createInstantWinRequest(newInstantWin));
    return axios
      .post('/api/instantwins', newInstantWin, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createInstantWinSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createInstantWinFailure(err.message));
      });
  };

const updateInstantWinRequest = (id: string, patch: InstantWinParams) => ({
  type: UPDATE_INSTANT_WIN_REQUEST,
  id,
  patch,
});

const updateInstantWinSuccess = (response: any) => ({
  type: UPDATE_INSTANT_WIN_SUCCESS,
  response,
});

const updateInstantWinFailure = (error: any) => ({
  type: UPDATE_INSTANT_WIN_FAILURE,
  error,
});

export const updateInstantWin =
  (id: string, patch: InstantWinParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateInstantWinRequest(id, patch));
    return axios
      .patch(`/api/instantwins/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateInstantWinSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateInstantWinFailure(err.message));
      });
  };

const getInstantWinStatsRequest = () => ({
  type: GET_INSTANT_WIN_STATS_REQUEST,
});

const getInstantWinStatsSuccess = (id: string, response: any) => ({
  type: GET_INSTANT_WIN_STATS_SUCCESS,
  id,
  response,
});

const getInstantWinStatsFailure = (error: any) => ({
  type: GET_INSTANT_WIN_STATS_FAILURE,
  error,
});

export const getInstantWinStats =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(getInstantWinStatsRequest());
    axios
      .get(`/api/instantwins/${id}/stats`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(getInstantWinStatsSuccess(id, result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(getInstantWinStatsFailure('canceled'));
        else dispatch(getInstantWinStatsFailure(error));
      });
  };

const downloadInstantWinCsvRequest = () => ({
  type: DOWNLOAD_INSTANT_WIN_CSV_REQUEST,
});

const downloadInstantWinCsvSuccess = () => ({
  type: DOWNLOAD_INSTANT_WIN_CSV_SUCCESS,
});

const downloadInstantWinCsvFailure = (error: string) => ({
  type: DOWNLOAD_INSTANT_WIN_CSV_FAILURE,
  error,
});
export const downloadInstantWinCsv =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadInstantWinCsvRequest());

    fetch(`/api/instantwins/${id}/csv`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }

        throw new Error(res.statusText);
      })
      .then((blob) => {
        FileSaver.saveAs(blob, id);
        dispatch(downloadInstantWinCsvSuccess());
      })
      .catch((error) => {
        dispatch(downloadInstantWinCsvFailure(error));
        console.log(error);
      });
  };
