import * as React from 'react';

import { listInsert, listRemove } from 'client/libraries/util/coreutil';
import baseStyles from 'client/base.module.css';
import addIcon from 'client/images/ic_add.svg';
import deleteIcon from 'client/images/ic_delete.svg';

export function ListInputElement<T>({
  elemIndex,
  listValue,
  onListChange,
  children,
  getDefaultElement,
  minElements = 1,
}: {
  elemIndex: number;
  listValue: T[];
  onListChange: (arg0: T[]) => void;
  children: React.ReactNode;
  getDefaultElement: () => T;
  minElements?: number;
}) {
  return (
    <div className={baseStyles['base-flex']}>
      {children}
      <a
        onClick={() =>
          onListChange(listInsert(listValue, elemIndex, getDefaultElement()))
        }
      >
        <img src={addIcon} />
      </a>
      {elemIndex + 1 > minElements && (
        <a onClick={() => onListChange(listRemove(listValue, elemIndex))}>
          <img src={deleteIcon} />
        </a>
      )}
    </div>
  );
}
