import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { getArrayMutators } from 'client/libraries/util/form';
import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Input,
  Select,
  MultiSelect,
  Radio,
  Button,
} from 'client/components/Form';
import { operationAllowed } from 'shared/models/access';
import {
  activeUserSelector,
  agentOptionsSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import { useReservationSearchConditionDateType } from 'client/hooks/useReservationSearchConditionDateType';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { supplierOptionsSelector } from 'client/reducers/products';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { Box } from 'client/components/Box/Box';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import { SourceLanguage } from 'shared/models/swagger';

import { Preset } from './ReservationSearchSettingsFormValues';

type Props = {
  existingItem?: Preset;
  open: boolean;
  onClose: () => void;
  onSave: (item: Preset) => void;
  submitting: boolean;
};

export const EditAccountReservationSearchPresetModal = ({
  existingItem,
  open,
  onClose,
  onSave,
  submitting,
}: Props) => {
  const { t, i18n } = useTranslation();

  const { required } = getValidators(t);

  const activeUser = useSelector(activeUserSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const languageOptions = React.useMemo(() => {
    const options = getSourceLanguageOptions(t);

    return options.filter((option) => {
      return supportedLanguages.includes(option.value as SourceLanguage);
    });
  }, [supportedLanguages, t]);

  const orderByOptions = useOrderByOptions();
  const dateTypeOptions = useReservationSearchConditionDateType();

  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  return (
    <Modal
      title={t('Preset search condition')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        onSubmit={(values: Preset) => {
          onSave(values);
        }}
        initialValues={
          existingItem ?? {
            key: uuidv4(),
            title: '',
            orderBy: 'last_updated_desc',
            dateType: 'PARTICIPATION_DATE',
            targetDateType: 'CURRENT_DATE',
            dateRangeType: 'NEXT',
            dateRange: '1',
          }
        }
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, values }) => (
          <form
            onSubmit={() => {
              handleSubmit();
            }}
          >
            <Modal.Content>
              <Modal.Box>
                <Field name="title" validate={required}>
                  {({ input, meta }) => (
                    <FieldWrapper label={t('Title')}>
                      <Input
                        {...input}
                        placeholder={t('Title')}
                        error={meta.touched && meta.error}
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Modal.Box>

              <Modal.Box>
                <Field name="orderBy">
                  {({ input }) => (
                    <Select
                      label={t('Order By')}
                      options={orderByOptions}
                      value={input.value}
                      onChange={(_, { value }) => {
                        input.onChange(value);
                      }}
                    />
                  )}
                </Field>
              </Modal.Box>

              <Modal.Box>
                <Field name="bookingStatuses">
                  {({ input }) => (
                    <MultiSelect
                      label={t('Booking Status')}
                      options={statusOptions}
                      selectedValues={
                        typeof input.value === 'object' ? input.value : []
                      }
                      onChange={({ value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>

              <Modal.Box>
                <Field name="dateType">
                  {({ input }) => (
                    <Select
                      label={t('Date')}
                      options={dateTypeOptions}
                      value={input.value}
                      onChange={(_, { value }) => {
                        input.onChange(value);
                      }}
                    />
                  )}
                </Field>
              </Modal.Box>

              <Field name="targetDateType">
                {({ input: targetDateTypeInput }) => (
                  <>
                    <Modal.Box>
                      <FieldWrapper label={t('Target Dates')}>
                        <>
                          <Modal.Box column="two">
                            <Radio
                              label={t('Current date')}
                              checked={
                                targetDateTypeInput.value === 'CURRENT_DATE'
                              }
                              onChange={() => {
                                targetDateTypeInput.onChange('CURRENT_DATE');
                              }}
                            />
                            <Radio
                              label={t('Previous date')}
                              checked={
                                targetDateTypeInput.value === 'PREVIOUS_DATE'
                              }
                              onChange={() => {
                                targetDateTypeInput.onChange('PREVIOUS_DATE');
                              }}
                            />
                          </Modal.Box>
                          <Modal.Box column="two">
                            <Radio
                              label={t('Next date')}
                              checked={
                                targetDateTypeInput.value === 'NEXT_DATE'
                              }
                              onChange={() => {
                                targetDateTypeInput.onChange('NEXT_DATE');
                              }}
                            />
                            <Radio
                              label={t('Specific number of days')}
                              checked={targetDateTypeInput.value === 'RANGE'}
                              onChange={() => {
                                targetDateTypeInput.onChange('RANGE');
                              }}
                            />
                          </Modal.Box>
                        </>
                      </FieldWrapper>
                    </Modal.Box>

                    {targetDateTypeInput.value === 'RANGE' && (
                      <Modal.Box>
                        <FieldWrapper label={t('Number of days')}>
                          {i18n.language === 'ja' ? (
                            <Box display="flex" alignItems="center">
                              <div>今日から</div>
                              <Box ml={1} mr={1}>
                                <Field name="dateRange">
                                  {({ input }) => (
                                    <Input
                                      type="number"
                                      value={input.value}
                                      onChange={(_, { value }) => {
                                        if (
                                          value !== '' &&
                                          !matchesFormat(
                                            value,
                                            'non-negative-integer'
                                          )
                                        ) {
                                          return;
                                        }
                                        input.onChange(value);
                                      }}
                                      {...{ min: 1 }}
                                    />
                                  )}
                                </Field>
                              </Box>
                              <Field name="dateRangeType">
                                {({ input }) => (
                                  <Select
                                    style={{ width: '120px' }}
                                    options={[
                                      {
                                        value: 'NEXT',
                                        text: t('dateRangeType.next'),
                                      },
                                      {
                                        value: 'PREVIOUS',
                                        text: t('dateRangeType.previous'),
                                      },
                                    ]}
                                    value={input.value}
                                    onChange={(_, { value }) => {
                                      input.onChange(value);
                                    }}
                                  />
                                )}
                              </Field>
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Field name="dateRangeType">
                                {({ input }) => (
                                  <Select
                                    style={{ width: '120px' }}
                                    options={[
                                      {
                                        value: 'NEXT',
                                        text: t('dateRangeType.next'),
                                      },
                                      {
                                        value: 'PREVIOUS',
                                        text: t('dateRangeType.previous'),
                                      },
                                    ]}
                                    value={input.value}
                                    onChange={(_, { value }) => {
                                      input.onChange(value);
                                    }}
                                  />
                                )}
                              </Field>
                              <Box ml={1} mr={1}>
                                <Field name="dateRange">
                                  {({ input }) => (
                                    <Input
                                      type="number"
                                      value={input.value}
                                      onChange={(_, { value }) => {
                                        if (
                                          value !== '' &&
                                          !matchesFormat(
                                            value,
                                            'non-negative-integer'
                                          )
                                        ) {
                                          return;
                                        }
                                        input.onChange(value);
                                      }}
                                      {...{ min: 1 }}
                                    />
                                  )}
                                </Field>
                              </Box>
                              <div
                                style={{ marginLeft: '10px', width: '100%' }}
                              >
                                days from today
                              </div>
                            </Box>
                          )}
                        </FieldWrapper>
                      </Modal.Box>
                    )}
                  </>
                )}
              </Field>

              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) ? (
                <>
                  <Modal.Box>
                    <Field name="bookingSources">
                      {({ input }) => (
                        <MultiSelect
                          label={t('Booking Source')}
                          options={bookingSourceOptions}
                          selectedValues={
                            typeof input.value === 'object' ? input.value : []
                          }
                          onChange={({ value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </Modal.Box>

                  <Modal.Box>
                    <Field name="agentIds">
                      {({ input }) => (
                        <MultiSelect
                          search
                          label={t('Agent')}
                          options={agentOptions}
                          selectedValues={
                            typeof input.value === 'object' ? input.value : []
                          }
                          onChange={({ value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                </>
              ) : (
                <>
                  <Modal.Box>
                    <Field name="supplierIds">
                      {(input) => (
                        <MultiSelect
                          search
                          label={t('Supplier')}
                          options={supplierOptions}
                          selectedValues={
                            typeof input.value === 'object' ? input.value : []
                          }
                          onChange={({ value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                </>
              )}

              <Modal.Box>
                <Field name="reservationLanguages">
                  {({ input }) => (
                    <MultiSelect
                      label={t('Language')}
                      options={languageOptions}
                      selectedValues={
                        typeof input.value === 'object' ? input.value : []
                      }
                      onChange={({ value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
                disabled={values.title ? false : true}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
