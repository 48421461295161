import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  fetchProductByID,
  fetchProducts,
  fetchSourceLanguageProductById,
  updateProduct,
} from 'client/actions/products';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Checkbox, FieldWrapper, Select } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { ModalLoader } from 'client/components/ModalLoader';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  Pricing,
  Service,
  Product$Patch,
  UnitPricing,
  Recurrence,
  StartTime,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { useSourceLanguageProduct } from './useSourceLanguageProduct';

type Props = {
  onClose: () => void;
  onSubmitSuccess?: () => void;
};

const removeAgentNet = (units: UnitPricing[]) => {
  for (const unit of units) {
    unit.per_agent_net = [];
  }
};

const removeUnitPackageMappings = (units: UnitPricing[]) => {
  for (const unit of units) {
    if (unit.guest_type != null) {
      unit.guest_type.package_component_guest_type_mappings = [];
    }
  }
};

const removeStartTimePackageMappings = (startTimes: StartTime[]) => {
  for (const startTime of startTimes) {
    startTime.package_component_time_slots = [];
  }
};

const mergedUnits = (
  currentUnits: UnitPricing[],
  toMergeUnits: UnitPricing[]
): UnitPricing[] => {
  const newUnits: UnitPricing[] = [];

  for (const mergeUnit of toMergeUnits) {
    const matchingUnit = currentUnits.find(
      (unit) =>
        unit.method === mergeUnit.method &&
        (unit.guest_type && unit.guest_type.key) ===
          (mergeUnit.guest_type && mergeUnit.guest_type.key)
    );

    if (matchingUnit) {
      newUnits.push(matchingUnit);
    } else {
      newUnits.push(mergeUnit);
    }
  }

  return newUnits;
};

export const mergedPricing = (
  currentPricing: Pricing[],
  toMergePricing: Pricing[]
): Pricing[] => {
  const newSchedules: Pricing[] = [];

  for (const priceSchedule of toMergePricing) {
    const matchingSchedule = currentPricing.find(
      (sched) =>
        sched.start_date_local === priceSchedule.start_date_local &&
        sched.end_date_local === priceSchedule.end_date_local &&
        _.isEqual(sched.days_of_week, priceSchedule.days_of_week)
    );

    if (matchingSchedule) {
      // Merge any unit types that aren't present yet
      newSchedules.push({
        ...matchingSchedule,
        units: mergedUnits(
          matchingSchedule.units || [],
          priceSchedule.units || []
        ),
      });
    } else {
      // No such schedule exists yet -- add it.
      newSchedules.push(priceSchedule);
    }
  }

  return newSchedules;
};
export const mergedServices = (
  currentServices: Service[],
  toMergeServices: Service[]
): Service[] => {
  const newServices: Service[] = [];

  for (const mergeService of toMergeServices) {
    const matchingService = currentServices.find(
      (svc) => svc.key === mergeService.key
    );

    if (matchingService) {
      newServices.push({
        ...matchingService,
        pricing: mergedUnits(
          matchingService.pricing || [],
          mergeService.pricing || []
        ),
      });
    } else {
      newServices.push(mergeService);
    }
  }

  return newServices;
};
type ProductDataCategory =
  | 'MEDIA'
  | 'PRICES'
  | 'PARTICIPATION_RULES'
  | 'BOOKING_DEADLINES'
  | 'CANCELLATION_POLICIES'
  | 'HIGHLIGHTS'
  | 'INCLUSIONS'
  | 'EXCLUSIONS'
  | 'WHAT_TO_BRING'
  | 'REQUIREMENTS'
  | 'RESTRICTIONS'
  | 'OTHER_INFO'
  | 'RESERVATION_FORM'
  | 'ADD_ONS'
  | 'ITINERARY'
  | 'TRANSPORTATION'
  | 'CHECKIN_PICKUP'
  | 'MIN_PAX'
  | 'TAGS';
const ProductFieldsByProductDataCategory: Record<
  ProductDataCategory,
  (keyof Product$Patch)[]
> = {
  MEDIA: ['media'],
  PRICES: ['pricing'],
  PARTICIPATION_RULES: ['recurrence', 'allotment_rules'],
  BOOKING_DEADLINES: ['booking_deadlines'],
  CANCELLATION_POLICIES: ['cancellation_policies'],
  HIGHLIGHTS: ['highlights'],
  INCLUSIONS: ['inclusions'],
  EXCLUSIONS: ['exclusions'],
  WHAT_TO_BRING: ['what_to_bring'],
  REQUIREMENTS: ['requirements'],
  RESTRICTIONS: ['restrictions'],
  OTHER_INFO: ['other_notes'],
  ITINERARY: ['itinerary'],
  RESERVATION_FORM: ['reservation_form_fields'],
  ADD_ONS: ['add_ons'],
  TRANSPORTATION: ['transportations'],
  CHECKIN_PICKUP: [
    'checkin',
    'pickup',
    'checkout',
    'dropoff',
    'guest_email_settings',
  ],
  MIN_PAX: ['minimum_participant_count'],
  TAGS: ['product_tags'],
};

const getProductCategoryName = (
  category: ProductDataCategory,
  t: TranslateFuncType
): string => {
  switch (category) {
    case 'MEDIA':
      return t('Images/Videos');

    case 'PRICES':
      return t('Prices');

    case 'PARTICIPATION_RULES':
      return t('Participation Rules');

    case 'BOOKING_DEADLINES':
      return t('Booking Deadlines');

    case 'CANCELLATION_POLICIES':
      return t('Cancellation Policies');

    case 'HIGHLIGHTS':
      return t('Highlights');

    case 'INCLUSIONS':
      return t('Inclusions');

    case 'EXCLUSIONS':
      return t('Exclusions');

    case 'REQUIREMENTS':
      return t('Requirements');

    case 'RESTRICTIONS':
      return t('Restrictions');

    case 'WHAT_TO_BRING':
      return t('What to Bring');

    case 'OTHER_INFO':
      return t('Other info');

    case 'ITINERARY':
      return t('Experience Itinerary');

    case 'ADD_ONS':
      return t('Add-Ons');

    case 'TRANSPORTATION':
      return t('Transportation');

    case 'CHECKIN_PICKUP':
      return t('Checkin/Pickup Locations');

    case 'RESERVATION_FORM':
      return t('Reservation Form');

    case 'MIN_PAX':
      return t('Minimum Participants');

    case 'TAGS':
      return t('Product Tags');

    default:
      throw new Error(`Unsupported category: ${category}`);
  }
};

export const ImportModal = ({ onClose, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const contentLanguageProduct = React.useContext(EditingProductContext);
  const {
    data: product,
    isLoading: sourceLanguageProductIsLoading,
    error: productFetchError,
  } = useSourceLanguageProduct(contentLanguageProduct?.id ?? '');
  const [isImporting, setIsImporting] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [dataCategoriesToImport, setDataCategoriesToImport] = React.useState<
    ProductDataCategory[]
  >([
    'MEDIA',
    'PRICES',
    'PARTICIPATION_RULES',
    'BOOKING_DEADLINES',
    'CANCELLATION_POLICIES',
    'HIGHLIGHTS',
    'INCLUSIONS',
    'EXCLUSIONS',
    'REQUIREMENTS',
    'RESTRICTIONS',
    'WHAT_TO_BRING',
    'OTHER_INFO',
    'ITINERARY',
    'ADD_ONS',
    'TRANSPORTATION',
    'CHECKIN_PICKUP',
    'RESERVATION_FORM',
    'MIN_PAX',
    'TAGS',
  ]);
  const [productIdToMerge, setProductIdToMerge] = React.useState<string>('');
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (productFetchError) {
      setError(productFetchError);
    }
  }, [productFetchError]);
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const allProducts = useSelector(summariesSortedByBookmarkedSelector);
  const passthroughBaseProductId =
    product?.shared_allotment_references?.passthrough_base_product_id ?? '';
  const sourceLanguage = product?.source_language;
  const onImport = React.useCallback(
    async (importedProductPatch: Product$Patch) => {
      setSuccess(false);
      setError('');
      const productPatch: Product$Patch = { ...(product as any) };

      for (const dataCategory of dataCategoriesToImport) {
        for (const field of ProductFieldsByProductDataCategory[dataCategory]) {
          const importedFieldData = importedProductPatch[field];

          if (
            !_.isNil(importedFieldData) &&
            (!_.isArrayLike(importedFieldData) || !_.isEmpty(importedFieldData))
          ) {
            (productPatch[field] as any) = importedFieldData as any;
          }
        }
      }

      try {
        await dispatch(updateProduct(product?.id || '', productPatch));
        setSuccess(true);
      } catch (err) {
        setError(t('Save Failed'));
      }
    },
    [product, dataCategoriesToImport]
  );
  const onSyncPricing = React.useCallback(
    async (
      pricing: Pricing[],
      addOns: Service[],
      transportations: Service[]
    ) => {
      setSuccess(false);
      setError('');
      const productPatch: Product$Patch = {
        ...product,
        pricing: mergedPricing(product?.pricing ?? [], pricing),
        add_ons: mergedServices(product?.add_ons ?? [], addOns),
        transportations: mergedServices(
          product?.transportations ?? [],
          transportations
        ),
      } as any;

      try {
        await dispatch(updateProduct(product?.id || '', productPatch));
      } catch (err) {
        setError(t('Save Failed'));
      }

      setSuccess(true);
    },
    [product]
  );

  React.useEffect(() => {
    if (success) onSubmitSuccess?.();
  }, [success]);

  const toggleProductCategory = (category: ProductDataCategory) => {
    if (dataCategoriesToImport.includes(category)) {
      setDataCategoriesToImport(
        dataCategoriesToImport.filter((f) => f !== category)
      );
    } else {
      setDataCategoriesToImport([...dataCategoriesToImport, category]);
    }
  };

  return (
    <Modal title={t('Import')} open={true} onClose={onClose}>
      {sourceLanguageProductIsLoading ? (
        <ModalLoader />
      ) : (
        <div>
          {passthroughBaseProductId && (
            <>
              <Button
                style="green"
                size="middle"
                width="100%"
                loading={sourceLanguageProductIsLoading || isImporting}
                onClick={async (event) => {
                  setIsImporting(true);
                  event.preventDefault();
                  // TODO: stop relying on the action produced by fetchProductByID and let the
                  // result pass through the redux store instead.
                  const action: any = await dispatch(
                    fetchSourceLanguageProductById(passthroughBaseProductId)
                  );

                  if (action.responses && action.responses.length === 1) {
                    // Filter out fields that shouldn't be imported for a passthrough product

                    /* eslint-disable no-unused-vars */
                    const {
                      shared_allotment_references,
                      allotment_rules,
                      area_name,
                      area_google_place_id,
                      booking_deadlines,
                      minimum_participant_count,
                      recurrence,
                      reservation_form_fields,
                      start_timezone,
                      ...rest
                    } = action.responses[0];

                    /* eslint-enable no-unused-vars */
                    // Strip agent net prices from response
                    (rest.pricing || []).map((priceSchedule: Pricing) => {
                      removeAgentNet(priceSchedule.units || []);
                      removeUnitPackageMappings(priceSchedule.units || []);
                    });
                    (rest.add_ons || []).map((addOn: Service) =>
                      removeAgentNet(addOn.pricing || [])
                    );
                    (rest.transportations || []).map((trans: Service) =>
                      removeAgentNet(trans.pricing || [])
                    );
                    await onImport(rest);
                  }

                  setIsImporting(false);
                }}
              >
                {t('Import From Passthrough Base Product')}
              </Button>
              <div
                className={clsx(
                  baseStyles['base-margin-top-8'],
                  baseStyles['base-margin-bottom-8']
                )}
              >
                <Button
                  style="green"
                  size="middle"
                  width="100%"
                  loading={sourceLanguageProductIsLoading || isImporting}
                  onClick={async (event) => {
                    setIsImporting(true);
                    event.preventDefault();
                    // TODO: stop relying on the action produced by fetchProductByID and let the
                    // result pass through the redux store instead.
                    const action: any = await dispatch(
                      fetchProductByID(passthroughBaseProductId, sourceLanguage)
                    );

                    if (action.responses && action.responses.length === 1) {
                      // Filter out fields that shouldn't be imported for a passthrough product
                      const addOns = action.responses[0].add_ons || [];
                      const pricing = action.responses[0].pricing || [];
                      const transportations =
                        action.responses[0].transportations || [];
                      // Strip agent net prices from response
                      pricing.map((priceSchedule: Pricing) => {
                        removeAgentNet(priceSchedule.units || []);
                        removeUnitPackageMappings(priceSchedule.units || []);
                      });
                      addOns.map((addOn: Service) =>
                        removeAgentNet(addOn.pricing || [])
                      );
                      transportations.map((trans: Service) =>
                        removeAgentNet(trans.pricing || [])
                      );
                      await onSyncPricing(pricing, addOns, transportations);
                    }

                    setIsImporting(false);
                  }}
                >
                  {t(
                    'Sync Prices Schedules, Units, Add-ons, and Transportations with Passthrough Base Product'
                  )}
                </Button>
              </div>
            </>
          )}
          <Select
            search
            placeholder={t('Select product')}
            label={t('Import data from another product.')}
            value={productIdToMerge}
            options={allProducts
              .filter((p) => p.id !== product?.id)
              .map((p) => ({
                text: getVerboseDisplayProductName(p),
                value: p.id,
              }))}
            onChange={(e, { value }) => setProductIdToMerge(value)}
          />
          {productIdToMerge && (
            <div className={baseStyles['base-margin-top-8']}>
              <FieldWrapper label={t('Data to Import')}>
                <div>{t('Basic Info')}</div>
                {['MEDIA', 'PRICES'].map((category: any) => (
                  <Checkbox
                    key={category}
                    label={getProductCategoryName(category, t)}
                    checked={dataCategoriesToImport.includes(category)}
                    onChange={() => toggleProductCategory(category)}
                  />
                ))}
                <div>{t('Reservation Parameters')}</div>
                {[
                  'PARTICIPATION_RULES',
                  'BOOKING_DEADLINES',
                  'CANCELLATION_POLICIES',
                ].map((category: any) => (
                  <Checkbox
                    key={category}
                    label={getProductCategoryName(category, t)}
                    checked={dataCategoriesToImport.includes(category)}
                    onChange={() => toggleProductCategory(category)}
                  />
                ))}
                <div>{t('Detailed Info')}</div>
                {[
                  'HIGHLIGHTS',
                  'INCLUSIONS',
                  'EXCLUSIONS',
                  'REQUIREMENTS',
                  'RESTRICTIONS',
                  'WHAT_TO_BRING',
                  'OTHER_INFO',
                  'ITINERARY',
                  'ADD_ONS',
                  'TRANSPORTATION',
                  'CHECKIN_PICKUP',
                  'RESERVATION_FORM',
                  'MIN_PAX',
                  'TAGS',
                ].map((category: any) => (
                  <Checkbox
                    key={category}
                    label={getProductCategoryName(category, t)}
                    checked={dataCategoriesToImport.includes(category)}
                    onChange={() => toggleProductCategory(category)}
                  />
                ))}
              </FieldWrapper>
            </div>
          )}
          <div>
            {success && <Message success header={t('Import Successful')} />}
            {error && <Message error header={t('Import Failed')} />}
          </div>
        </div>
      )}
      <Modal.Actions>
        <Button
          loading={sourceLanguageProductIsLoading || isImporting}
          disabled={!productIdToMerge}
          size="middle"
          style="green"
          onClick={async (event) => {
            setIsImporting(true);
            event.preventDefault();
            // TODO: stop relying on the action produced by fetchProductByID and let the
            // result pass through the redux store instead.
            const action: any = await dispatch(
              fetchSourceLanguageProductById(productIdToMerge)
            );

            if (action.responses && action.responses.length === 1) {
              (action.responses[0].pricing || []).map(
                (priceSchedule: Pricing) => {
                  removeUnitPackageMappings(priceSchedule.units || []);
                }
              );
              (action.responses[0].recurrence || []).map(
                (recurrenceRule: Recurrence) =>
                  removeStartTimePackageMappings(
                    recurrenceRule.start_times || []
                  )
              );
              await onImport(action.responses[0]);
            }

            setIsImporting(false);
          }}
        >
          {t('Import')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
