import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { currency } from 'shared/libraries/currency';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { updateSystemFeeInvoiceFareAdjustments } from 'client/actions/systemFeeInvoices';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { SystemFeeInvoice } from 'shared/models/swagger';

import { SystemFeeFareAdjustmentEditor } from './SystemFeeFareAdjustmentEditor';
import { FormValues } from './formValues';

interface Props {
  systemFeeInvoice: SystemFeeInvoice;
  onClose: () => void;
}

export const SystemFeeFareAdjustmentModal = ({
  systemFeeInvoice,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultCurrency = useSelector(defaultProductCurrencySelector);

  const initialValues: FormValues = React.useMemo(() => {
    return {
      exemptedReservationIds:
        systemFeeInvoice?.fare_adjustment?.exempted_reservation_ids ?? [],
      fareAdjustments:
        systemFeeInvoice?.fare_adjustment?.fare_adjustment_items?.map(
          (item) => ({
            amount: `${currency(item.amount_adjustment ?? '').value}`,
            note: item.note ?? '',
          })
        ) ?? [],
    };
  }, [systemFeeInvoice]);

  return (
    <Modal open={true} title={t('Fare Adjustment')} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            updateSystemFeeInvoiceFareAdjustments(systemFeeInvoice.id ?? '', {
              exempted_reservation_ids: values.exemptedReservationIds,
              fare_adjustment_items: values.fareAdjustments.map((adjust) => ({
                amount_adjustment: `${defaultCurrency}${adjust.amount}`,
                note: adjust.note,
              })),
            })
          );
          onClose();
        }}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <SystemFeeFareAdjustmentEditor />
            </Modal.Content>
            <Modal.Actions>
              <Button style="gray" size="middle" onClick={() => form.reset()}>
                {t('Discard')}
              </Button>
              <Button
                style="blue"
                size="middle"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
