import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  name: string;
};

export const AgentReferencesEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const agentOptions = (editingProduct?.agents ?? []).map((agent) => ({
    value: agent.id,
    text: agent.name,
  }));

  return (
    <div
      className={clsx(
        styles['p-list__item'],
        styles['row'],
        styles['p-list__item__agentReference']
      )}
    >
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={clsx(styles['p-list__item__body'], styles['row'])}>
            <div className={styles['p-list__item__body__list']}>
              {fields.length !== 0 &&
                fields.map((fieldName, idx) => (
                  <div
                    key={fieldName}
                    className={clsx(styles['p-list__item__body__flex'])}
                  >
                    <Field name={`${fieldName}.agentId`}>
                      {({ input }) => (
                        <SingleDropdown
                          options={agentOptions
                            .map((channelOption) => ({
                              value: channelOption.value,
                              text: channelOption.text,
                            }))
                            .concat({
                              value: '',
                              text: '',
                            })}
                          selectedOption={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                    <Field name={`${fieldName}.reference`}>
                      {({ input }) => <TextField {...input} />}
                    </Field>
                    <Button
                      size="icon"
                      color="tertiarygray"
                      onClick={() => fields.remove(idx)}
                      iconBeforeText={
                        <i className="c-icon-outline-general-trash-03"></i>
                      }
                    />
                  </div>
                ))}
              <div className={baseStyles['u-mt-2']}>
                <a
                  className={styles['p-box__table2__actions__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(fields.length + 1, '');
                    } else {
                      (fields as any).insertAt(0, '');
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Agent Reference')}
                </a>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};
