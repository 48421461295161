// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Button, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { matchesFormat } from 'shared/libraries/validate/validator';

type FormValues = {
  toAddress?: string,
};

type Props = {
  initialToAddress?: string,
  trigger: React.Element<'a' | 'button'>,
  onSubmit: (toAddress?: string) => void,
  title: string,
};

export const SendReservationEmailModal = ({
  initialToAddress,
  onSubmit,
  trigger,
  title,
}: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const initialValues = React.useMemo(
    (): FormValues => ({
      toAddress: initialToAddress,
    }),
    [initialToAddress]
  );

  const validateEmailAddress = React.useCallback((email?: any) => {
    if (!email) return t('Required');

    if (!matchesFormat(email, 'email')) return t('Invalid Email');

    return undefined;
  });

  return (
    <Modal
      title={title}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
      trigger={trigger}
    >
      <Form
        initialValues={initialValues}
        onSubmit={(values: FormValues) => {
          onSubmit(values.toAddress);
          setShowModal(false);
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Field name="toAddress" validate={validateEmailAddress}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    label={t('Email Address')}
                    disabled={Boolean(initialToAddress)}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </Modal.Content>
            <Modal.Actions>
              <Button
                style="gray"
                size="small"
                onClick={() => {
                  form.reset();
                  setShowModal(false);
                }}
              >
                {t('Cancel')}
              </Button>
              <Button style="blue" size="small" type="submit" positive>
                {t('Send')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
