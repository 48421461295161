import { combineReducers } from 'redux';

import {
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILURE,
  FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST,
  FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS,
  FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE,
  FETCH_REVIEW_STATS_REQUEST,
  FETCH_REVIEW_STATS_SUCCESS,
  FETCH_REVIEW_STATS_FAILURE,
  FETCH_PAGE_OF_REVIEWS_SUCCESS,
  FETCH_PAGE_OF_REVIEWS_FAILURE,
  FETCH_PAGE_OF_REVIEWS_REQUEST,
} from 'client/constants/ActionTypes';
import type { Review, ReviewStats } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case UPDATE_REVIEW_REQUEST:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST:
      return true;

    case UPDATE_REVIEW_SUCCESS:
    case UPDATE_REVIEW_FAILURE:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_REVIEWS_FAILURE:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE:
      return action.error;

    case FETCH_REVIEWS_REQUEST:
    case FETCH_REVIEWS_SUCCESS:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const all = (state: Review[] | null = null, action: any): Review[] | null => {
  switch (action.type) {
    case FETCH_REVIEWS_SUCCESS:
      return action.reviews;
    case FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS: {
      const newState: Review[] = [];
      for (const response of action.responses) {
        if (response.reviews) {
          newState.push(...response.reviews);
        }
      }
      return newState;
    }
    case FETCH_PAGE_OF_REVIEWS_SUCCESS: {
      return [
        ...(state ?? []),
        ...action.reviews.filter(
          (review: Review) => !state?.find((r) => r.id === review.id)
        ),
      ];
    }
    case FETCH_REVIEWS_REQUEST:
    case FETCH_REVIEWS_FAILURE:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST:
    case FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE:
      return null;

    default:
      return state;
  }
};

const lastFetchedPage = (
  state: Review[] | null = null,
  action: any
): Review[] | null => {
  switch (action.type) {
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
    case FETCH_REVIEWS_SUCCESS:
      return action.reviews;
    default:
      return state;
  }
};

const fetchReviewsLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_REVIEWS_REQUEST:
    case FETCH_PAGE_OF_REVIEWS_REQUEST:
      return true;
    case FETCH_REVIEWS_SUCCESS:
    case FETCH_REVIEWS_FAILURE:
    case FETCH_PAGE_OF_REVIEWS_SUCCESS:
    case FETCH_PAGE_OF_REVIEWS_FAILURE:
      return false;
    default:
      return state;
  }
};

const stats = (state = {}, action: any): ReviewStats => {
  switch (action.type) {
    case FETCH_REVIEW_STATS_SUCCESS:
      return action.response;
    case FETCH_REVIEW_STATS_REQUEST:
    case FETCH_REVIEW_STATS_FAILURE:
      return {};
    default:
      return state;
  }
};

const statsLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_REVIEW_STATS_REQUEST:
      return true;
    case FETCH_REVIEW_STATS_SUCCESS:
    case FETCH_REVIEW_STATS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const reviews = combineReducers({
  loading,
  error,
  all,
  stats,
  statsLoading,
  fetchReviewsLoading,
  lastFetchedPage,
});
