import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { setIn } from 'final-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { matchesFormat } from 'shared/libraries/validate/validator';
import type { Reservation, ReservationReceipt } from 'shared/models/swagger';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Message } from 'client/components/Message/Message';
import { getGuestName } from 'client/libraries/util/getGuestName';
import baseStyles from 'client/v3-base.module.css';
import { ReduxState } from 'client/reducers';
import { TextField } from 'client/components/v3/Form/TextField';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

type FormValues = {
  receiverName?: string;
  receiptProviso?: string;
  toAddress?: string;
};

type Props = {
  title: string;
  initialReceiptProviso?: string;
  initialToAddress?: string;
  onClose: () => void;
  onIssue: (receiverName?: string, receiptProviso?: string) => Promise<void>;
  onDownload: () => void;
  onSendEmail: (toAddress?: string) => void;
  reservation?: Reservation;
  reservationReceipt?: ReservationReceipt;
};

export const ReceiptModal = ({
  title,
  initialReceiptProviso,
  initialToAddress,
  onClose,
  onIssue,
  onDownload,
  onSendEmail,
  reservation,
  reservationReceipt,
}: Props) => {
  const { t } = useTranslation();
  const reservationReceiptIssueStatus = useSelector(
    (state: ReduxState) => state.reservationReceipts.issueStatus
  );
  const [activeButton, setActiveButton] = useState<
    'ISSUE' | 'DOWNLOAD' | 'EMAIL' | null
  >(null);
  const isIssued =
    reservationReceipt && reservationReceipt?.receiver_name !== undefined;
  const organization = useSelector(activeUserOrganizationSelector);
  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;
  const customerName = reservation ? getGuestName(reservation) : '';
  const isReservationLanguageJapanese = reservationLanguage?.startsWith('ja');
  // Special case for Japanese customer
  const customerNameWithPrefixOrSuffix = isReservationLanguageJapanese
    ? `${customerName} 様`
    : t('Bill to: {{customerName}}', { customerName: customerName });

  // Close modal when issuance is successful
  useEffect(() => {
    if (reservationReceiptIssueStatus === 'SUCCESS') {
      onClose();
    }
  }, [reservationReceiptIssueStatus]);

  const validateReceiptRelatedSettings = (): ReactNode[] => {
    const validationErrors: ReactNode[] = [];
    if (
      !organization?.supplier_guest_receipt_settings ||
      !organization.supplier_guest_receipt_settings.receipt_business_name
    ) {
      validationErrors.push(
        <>
          {t(
            'Please enter Receipt Business Name in the Booking Site > Settings page. '
          )}
          <Link
            to={`/bookingWidget/general`}
            className={baseStyles['base-link']}
          >
            {t('Click here to set.')}
          </Link>
        </>
      );
    }
    return validationErrors;
  };

  const receiptValidationErrors = validateReceiptRelatedSettings();
  return (
    <Form
      initialValues={{
        receiverName:
          reservationReceipt?.receiver_name ?? customerNameWithPrefixOrSuffix,
        receiptProviso: isReservationLanguageJapanese
          ? reservationReceipt?.receipt_proviso ?? initialReceiptProviso
          : '',
        toAddress: initialToAddress,
      }}
      onSubmit={(values: FormValues) => {
        let errors = {};
        const setError = (key: string, value: any) => {
          errors = setIn(errors, key, value);
        };

        switch (activeButton) {
          case 'ISSUE':
            if (!values.receiverName) {
              setError('receiverName', t('Required'));
              return errors;
            }
            if (!values.receiptProviso && isReservationLanguageJapanese) {
              setError('receiptProviso', t('Required'));
              return errors;
            }
            onIssue(values.receiverName, values.receiptProviso);
            break;
          case 'DOWNLOAD':
            onDownload();
            onClose();
            break;
          case 'EMAIL':
            if (!values.toAddress) {
              setError('toAddress', t('Required'));
              return errors;
            }
            if (!matchesFormat(values.toAddress, 'email')) {
              setError('toAddress', t('Invalid Email'));
              return errors;
            }
            onSendEmail(values.toAddress);
            onClose();
            break;
        }
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={title}
            open={true}
            onClose={() => {
              onClose();
            }}
            style={{ width: '600px' }}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  color="white"
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                {isIssued && (
                  <Button
                    text={t('Download')}
                    type="submit"
                    onClick={() => setActiveButton('DOWNLOAD')}
                    disabled={receiptValidationErrors.length > 0}
                  />
                )}
                {!isIssued && (
                  <Button
                    text={t('Issue receipt')}
                    type="submit"
                    onClick={() => setActiveButton('ISSUE')}
                    disabled={receiptValidationErrors.length > 0}
                    loading={reservationReceiptIssueStatus === 'IN_FLIGHT'}
                  />
                )}
                {isIssued && (
                  <Button
                    text={t('Send via Email')}
                    type="submit"
                    onClick={() => setActiveButton('EMAIL')}
                    disabled={receiptValidationErrors.length > 0}
                  />
                )}
              </>
            }
          >
            <div className={styles['p-reservationNum']}>
              <div className={styles['p-reservationNum__item']}>
                <p className={styles['p-reservationNum__item__ttl']}>
                  {t('Receiver')}
                </p>
                <Field name="receiverName">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <TextField
                      disabled={isIssued}
                      value={reservationReceipt?.receiver_name ?? input.value}
                      onChange={(value) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              </div>
              {isReservationLanguageJapanese && (
                <div className={styles['p-reservationNum__item']}>
                  <p className={styles['p-reservationNum__item__ttl']}>
                    {t('Receipt Proviso')}
                  </p>
                  <Field name="receiptProviso">
                    {({ input, meta: { touched, error, submitError } }) => (
                      <TextField
                        disabled={isIssued}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        error={touched && (error || submitError)}
                      />
                    )}
                  </Field>
                </div>
              )}
              {isIssued && (
                <div className={styles['p-reservationNum__item']}>
                  <p className={styles['p-reservationNum__item__ttl']}>
                    {t('Email Address')}
                  </p>
                  <Field name="toAddress">
                    {({ input, meta: { touched, error, submitError } }) => (
                      <TextField
                        disabled={Boolean(initialToAddress)}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        error={touched && (error || submitError)}
                      />
                    )}
                  </Field>
                </div>
              )}

              {receiptValidationErrors.length > 0 && (
                <Message error>
                  <ol>
                    {receiptValidationErrors.map((e, idx) => (
                      <li key={idx}>{e}</li>
                    ))}
                  </ol>
                </Message>
              )}
              {/* Show error message when receipt has already been issued when user tries to issue */}
              {reservationReceiptIssueStatus === 'FAILURE' && (
                <Message error>
                  <ol>
                    <li>{t('Receipt had already been issued.')}</li>
                  </ol>
                </Message>
              )}
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
