import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getLanguageName } from 'client/libraries/i18n';
import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { fetchProducts } from 'client/actions/products';
import { fetchWaiverTemplates } from 'client/actions/waiverTemplates';
import type { ReduxState } from 'client/reducers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ColumnType } from 'client/libraries/util/ColumnType';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import baseStyles from 'client/base.module.css';
import { WaiverTemplate } from 'shared/models/swagger';

import { DeleteWaiverTemplateModal } from './DeleteWaiverTemplateModal';

const EditDeleteCell = ({
  waiverTemplate,
}: {
  waiverTemplate: WaiverTemplate;
}) => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      {hasCustomUserRoleWritePermissions(activeUser, 'WAIVER.TEMPLATES') && (
        <>
          <Link
            to={`/waivers/templates/${waiverTemplate.id}/edit`}
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
          >
            <img src={editIcon} />
          </Link>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['icon'],
              baseStyles['narrow']
            )}
            onClick={() => setShowDeleteModal(true)}
          >
            <img src={deleteIcon} />
          </a>
        </>
      )}
      {showDeleteModal && (
        <DeleteWaiverTemplateModal
          waiverTemplate={waiverTemplate}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <Box ml={2}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${bookingWebsiteUrl}/waiverpreview?id=${waiverTemplate.id}`}
          className={clsx(baseStyles['base-btn'])}
        >
          {t('Preview')}
        </a>
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<WaiverTemplate>[] => {
  const { t } = useTranslation();
  const products = useSelector((state: ReduxState) => state.products.summaries);
  return [
    {
      Header: '',
      width: 'middle',
      Cell: (cellInfo) => <EditDeleteCell waiverTemplate={cellInfo.original} />,
      id: 'edit',
    },
    {
      Header: t('Name'),
      accessor: 'template_name',
      id: 'template_name',
    },
    {
      Header: t('Title'),
      accessor: 'title',
      id: 'title',
    },
    {
      Header: t('Type'),
      accessor: (row) => {
        switch (row.signature_type) {
          case 'PER_BOOKING':
            return t('Per-booking Form');
          case 'PER_PARTICIPANT':
            return t('Per-participant Form (no name tracking)');
          case 'PER_PARTICIPANT_WITH_NAMES':
            return t('Per-participant Form (with name tracking)');
          default:
            return '';
        }
      },
      id: 'type',
    },
    {
      Header: t('Languages'),
      accessor: (row) =>
        [row.source_language, ...(row.languages ?? [])]
          .map((lang) => (lang ? getLanguageName(lang, t) : ''))
          .join(','),
      id: 'languages',
    },
    {
      Header: t('Products'),
      width: 'long',
      Cell: (cellInfo) => (
        <div>
          <ul>
            {(cellInfo.original.product_ids ?? []).map((productId) => {
              const product = products.find((p) => p.id === productId);
              return product ? (
                <li key={productId}>{getDisplayProductName(product)}</li>
              ) : null;
            })}
          </ul>
        </div>
      ),
      id: 'products',
    },
  ];
};

export const WaiverTemplateList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const waiverTemplates = useSelector(
    (state: ReduxState) => state.waiverTemplates.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.waiverTemplates.loading
  );
  const columns = useColumns();
  React.useEffect(() => {
    dispatch(fetchWaiverTemplates());
    dispatch(fetchProducts());
  }, []);
  return (
    <div>
      {hasCustomUserRoleWritePermissions(activeUser, 'WAIVER.TEMPLATES') && (
        <Link to="/waivers/templates/new">
          <button
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['middle'],
              baseStyles['green']
            )}
          >
            {t('Create new waiver template')}
          </button>
        </Link>
      )}

      {!loading && waiverTemplates.length === 0 ? (
        <div className={baseStyles['base-form-box__err']}>
          {t('No Waiver Templates')}
        </div>
      ) : (
        <CustomTable items={waiverTemplates} columns={columns} />
      )}
    </div>
  );
};
