import * as React from 'react';
import { Field, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Input } from 'client/components/Form';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import { DeadlineInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DeadlineInput';
import { CancellationPolicyTimeline } from 'client/components/CancellationPolicyTimeline/CancellationPolicyTimeline';
import {
  compareRelativeDeadlines,
  CancellationPolicy,
} from 'client/libraries/util/productShape';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';
import deleteIcon from 'client/images/ic_delete.svg';
import addIcon from 'client/images/ic_add.svg';

export const CancellationPoliciesInput = () => {
  const field = useField('cancellationPolicies');
  const { t } = useTranslation();
  const cancellationPolicies: CancellationPolicy[] = field.input.value ?? [];
  cancellationPolicies.sort(compareRelativeDeadlines);
  const editingProduct = React.useContext(EditingProductContext);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const hasAnytimePolicy = cancellationPolicies.some(
    (policy) => policy?.deadlineType === 'ETERNAL'
  );
  return (
    <div>
      <CancellationPolicyTimeline
        cancellationPolicies={cancellationPolicies}
        currency={defaultCurrency}
      />
      <div className={styles['page-productsRegist__cancel']}>
        <div className={styles['c-tableChild']}>
          <ul>
            <li className={baseStyles['base-t-256']}>{t('Deadline')}</li>
            <li className={baseStyles['base-t-144']}>
              {t('Cancellation Fee')}
            </li>
            <li></li>
          </ul>
          <FieldArray name="cancellationPolicies">
            {({ fields }) => (
              <>
                <a
                  onClick={() =>
                    (fields as any).insertAt(0, {
                      deadlineType: 'DAY',
                      daysBefore: 1,
                      timeOfDay: '17:00',
                      feeType: 'PERCENT',
                      feePercent: '50',
                    })
                  }
                >
                  <img src={addIcon} />
                </a>
                {fields.map((name, idx) => {
                  return (
                    <ul key={idx}>
                      <li data-title={t('Deadline')}>
                        <div
                          className={
                            styles['page-productsRegist__cancel__limit']
                          }
                        >
                          <DeadlineInput
                            allowAnytimeDeadline={true}
                            name={name}
                          />
                        </div>
                      </li>
                      <Field name={name}>
                        {({ input: cancellationPolicyInput }) => (
                          <li data-title={t('Cancellation Fee')}>
                            <div className={baseStyles['base-flex']}>
                              {cancellationPolicyInput.value.feeType ===
                                'PERCENT' && (
                                <Field name={`${name}.feePercent`}>
                                  {({ input }) => (
                                    <Input
                                      value={input.value}
                                      onChange={(e: any) => {
                                        if (!isInteger(e.target.value)) {
                                          return;
                                        }

                                        input.onChange(e.target.value);
                                      }}
                                    />
                                  )}
                                </Field>
                              )}
                              {cancellationPolicyInput.value.feeType ===
                                'FIXED' && (
                                <Field name={`${name}.feeFixed`}>
                                  {({ input }) => (
                                    <Input
                                      value={input.value}
                                      onChange={(e: any) => {
                                        if (
                                          !currencyInputAllowed(
                                            defaultCurrency,
                                            e.target.value
                                          )
                                        ) {
                                          return;
                                        }

                                        input.onChange(e.target.value);
                                      }}
                                    />
                                  )}
                                </Field>
                              )}
                              <label className={baseStyles['base-form-select']}>
                                <Field name={`${name}.feeType`}>
                                  {({ input }) => (
                                    <select
                                      value={input.value}
                                      onChange={(e) => {
                                        if (e.target.value === 'FIXED') {
                                          cancellationPolicyInput.onChange({
                                            ...cancellationPolicyInput.value,
                                            feeType: 'FIXED',
                                            feeFixed: '0',
                                          });
                                        } else {
                                          cancellationPolicyInput.onChange({
                                            ...cancellationPolicyInput.value,
                                            feeType: 'PERCENT',
                                            feePercent: '50',
                                          });
                                        }
                                      }}
                                    >
                                      <option value="FIXED">
                                        {defaultCurrency}
                                      </option>
                                      <option value="PERCENT">%</option>
                                    </select>
                                  )}
                                </Field>
                              </label>
                            </div>
                          </li>
                        )}
                      </Field>
                      <li>
                        <div className={baseStyles['base-flex']}>
                          <a
                            onClick={() =>
                              (fields as any).insertAt(idx + 1, {
                                deadlineType: 'DAY',
                                daysBefore: 1,
                                timeOfDay: '17:00',
                                feeType: 'PERCENT',
                                feePercent: '50',
                              })
                            }
                          >
                            <img src={addIcon} />
                          </a>
                          <a onClick={() => fields.remove(idx)}>
                            <img src={deleteIcon} />
                          </a>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </>
            )}
          </FieldArray>
        </div>
      </div>
      {!hasAnytimePolicy && (
        <p
          className={clsx(
            styles['c-table-list__note'],
            styles['bold'],
            styles['blue']
          )}
        >
          {t('After the above, 100% cancellation fee')}
        </p>
      )}
      <p className={styles['c-table-list__note']}>
        {t(
          '* Decide when cancellation fees will be charged. New rules can be added with the "+" mark.'
        )}
      </p>
    </div>
  );
};
