import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useMemo } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { CustomTable } from 'client/components/v3/Table/CustomTable';
import { updateReservation } from 'client/actions/reservations';
import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import { manifestCustomizedColumnNamesSelector } from 'client/reducers/manifestSettings';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ResourceType } from 'client/libraries/util/resourceType';
import {
  convertFormValuesToReservationPatch,
  getInitialValuesFromManifestReservation,
} from 'client/formValues/ReservationTransportRouteFormValues';
import type {
  FormValues,
  TransportRouteItem,
} from 'client/formValues/ReservationTransportRouteFormValues';
import { guideAccountShapesSelector } from 'client/reducers/user';

const getEditingFieldName = (
  resourceType: ResourceType
): keyof TransportRouteItem => {
  switch (resourceType) {
    case 'crew':
      return 'dispatchCrew';

    case 'vehicle':
      return 'dispatchVehicles';

    case 'guide':
      return 'dispatchGuides';

    case 'other':
      return 'dispatchMiscResources';
  }

  throw new Error(`unknown resource type: ${resourceType}`);
};

type Props = {
  reservation: ManifestReservationShape;
  onClose: () => void;
  editingResourceType: ResourceType;
  editingResourceKey: string;
};
export const RouteAssignmentModal = ({
  onClose,
  reservation,
  editingResourceType,
  editingResourceKey,
}: Props) => {
  const { t } = useTranslation();
  const customizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  const initialValues = useMemo(
    () => getInitialValuesFromManifestReservation(reservation),
    []
  );

  const guideAccountShapes = useSelector(guideAccountShapesSelector);

  const dispatch = useDispatch();
  const editingFieldName = getEditingFieldName(editingResourceType);
  const columns = useMemo(() => {
    const getGuideName = (guideId: string) =>
      guideAccountShapes.find((guide) => guide.id === guideId)?.name ||
      'no name';

    return [
      {
        Header: '',
        id: 'assign',
        width: 'short',
        Cell: (cellInfo: {
          original: TransportRouteItem;
          rowIndex: number;
        }) => (
          <Field
            name={`transportRouteItems.${cellInfo.rowIndex}.${editingFieldName}`}
          >
            {({ input }) => (
              <Checkbox
                checked={input.value?.includes(editingResourceKey)}
                onChange={() =>
                  input.value?.includes(editingResourceKey)
                    ? input.onChange(
                        input.value.filter(
                          (item: string) => item !== editingResourceKey
                        )
                      )
                    : input.onChange([...input.value, editingResourceKey])
                }
              />
            )}
          </Field>
        ),
        th: true,
      },
      {
        Header: getColumnHeaderText('DISPATCH_VEHICLE', t),
        id: 'DISPATCH_VEHICLE',
        accessor: (row: TransportRouteItem) => row.dispatchVehicles.join(','),
      },
      {
        Header: getColumnHeaderText('DISPATCH_CREW', t),
        id: 'DISPATCH_CREW',
        accessor: (row: TransportRouteItem) =>
          [
            ...row.dispatchCrew,
            ...row.dispatchGuides.map((guide) => getGuideName(guide)),
          ].join(','),
      },
      {
        Header: getColumnHeaderText('DISPATCH_MISC_RESOURCE', t),
        id: 'DISPATCH_MISC_RESOURCE',
        accessor: (row: TransportRouteItem) =>
          row.dispatchMiscResources.join(','),
      },
      {
        Header: getColumnHeaderText(
          'TRANSPORT_DEPARTURE_TIME',
          t,
          customizedColumnNames
        ),
        id: 'TRANSPORT_DEPARTURE_TIME',
        accessor: (row: TransportRouteItem) => row.locationFrom.hhMm,
      },
      {
        Header: getColumnHeaderText(
          'TRANSPORT_DEPARTURE_LOCATION',
          t,
          customizedColumnNames
        ),
        id: 'TRANSPORT_DEPARTURE_LOCATION',
        accessor: (row: TransportRouteItem) => row.locationFrom.locationName,
      },
      {
        Header: getColumnHeaderText(
          'TRANSPORT_ARRIVAL_TIME',
          t,
          customizedColumnNames
        ),
        id: 'TRANSPORT_ARRIVAL_TIME',
        accessor: (row: TransportRouteItem) => row.locationTo.hhMm,
      },
      {
        Header: getColumnHeaderText(
          'TRANSPORT_ARRIVAL_LOCATION',
          t,
          customizedColumnNames
        ),
        id: 'TRANSPORT_ARRIVAL_LOCATION',
        accessor: (row: TransportRouteItem) => row.locationTo.locationName,
      },
    ];
  }, [editingFieldName, editingResourceKey, guideAccountShapes]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        const patch: any = convertFormValuesToReservationPatch(
          values,
          reservation.participates_at?.utc().format(),
          reservation.start_timezone ?? ''
        );
        await dispatch(updateReservation(reservation?.id ?? '', patch));
        onClose?.();
      }}
    >
      {({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit} id={`form-${reservation.id}`}>
          <Modal
            open={true}
            onClose={onClose}
            title={t('Assign Resources to Transport Routes')}
            style={{ width: '90%', height: '600px' }}
            useCloseButton={true}
            rightActionChildren={
              <Button
                text={t('Save')}
                color="primary"
                type="submit"
                loading={submitting}
                // Associate button with form id to submit
                form={`form-${reservation.id}`}
              />
            }
            insertAtRoot={true}
          >
            <>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              <CustomTable
                usePaging={false}
                useScrollButton={false}
                columns={columns}
                items={values?.transportRouteItems ?? []}
              />
            </>
          </Modal>
        </form>
      )}
    </Form>
  );
};
