import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import type { ThunkDispatch } from 'redux-thunk';
import clsx from 'clsx';

import {
  fetchReservationByID,
  updateReservation,
} from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import {
  convertToLocationWithMoment,
  locationWithMomentsAreEqual,
  getStartTime,
} from 'client/libraries/util/coreutil';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import { activeUserSelector } from 'client/reducers/user';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers/index';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import { Modal } from 'client/components/Modal/Modal';
import { Button, TextArea } from 'client/components/Form';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  reservationID: string;
  onUpdateCompleted?: (arg0: void) => void;
  trigger?: React.ReactElement<'a'>;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
type State = {
  showModal: boolean;
  guestHotel?: {
    location_name?: string;
    google_place_id?: string;
  };
  checkin?: LocationWithMoment;
  supplierNotes: string;
};

class CheckinEditButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      ...this.getStateFromReservation(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.reservation &&
      prevProps.reservation !== this.props.reservation
    ) {
      this.setState({ ...this.getStateFromReservation() });
      this.fetchProduct();
    }
  }

  getStateFromReservation = () => {
    const { locale, reservation } = this.props;

    if (!reservation) {
      return {
        checkin: undefined,
        guestHotel: undefined,
        supplierNotes: '',
      };
    }

    const checkin = convertToLocationWithMoment(
      reservation.checkin,
      reservation.start_timezone || '',
      locale
    );
    return {
      checkin,
      guestHotel: reservation.guest_hotel,
      supplierNotes: reservation?.supplier_notes ?? '',
    };
  };
  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }
  };
  fetchProduct = () => {
    const { fetchProductByID, reservation, product } = this.props;

    if (
      reservation &&
      reservation.product_id &&
      (!product || product.id !== reservation.product_id)
    ) {
      fetchProductByID(reservation.product_id);
    }
  };
  handleClickAutofillCheckin = () => {
    const { guestHotel } = this.state;
    const locationName = (guestHotel && guestHotel.location_name) || '';
    const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

    if (locationName) {
      this.setState((prevState) => ({
        checkin: { ...(prevState.checkin as any), locationName, googlePlaceID },
      }));
    }
  };
  handleOpen = () => {
    this.fetchReservation();
    this.setState({
      showModal: true,
    });
  };

  render() {
    const {
      loading,
      locale,
      onUpdateCompleted,
      product,
      productInstance,
      reservation,
      t,
      trigger,
    } = this.props;
    const { guestHotel, checkin, supplierNotes } = this.state;
    const startTime = reservation && getStartTime(reservation).locale(locale);
    const timezone = (reservation && reservation.start_timezone) || '';
    const originalGuestHotel = reservation && reservation.guest_hotel;
    const originalCheckin =
      reservation &&
      convertToLocationWithMoment(reservation.checkin, timezone, locale);
    const originalSupplierNotes =
      (reservation && reservation?.supplier_notes) || '';
    const pristine =
      guestHotel === originalGuestHotel &&
      supplierNotes === originalSupplierNotes &&
      checkin &&
      originalCheckin &&
      locationWithMomentsAreEqual(checkin, originalCheckin);
    const timeSlotKey = productInstance?.time_slot_key ?? '';
    return (
      <Modal
        title={t('Edit Checkin')}
        trigger={trigger}
        open={this.state.showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={this.handleOpen}
      >
        {loading || !checkin ? (
          <div className={clsx(pageStyles['page-reservations__modal'])}>
            <div className={clsx(pageStyles['page-reservations__modal__box'])}>
              <ModalLoader />
            </div>
          </div>
        ) : (
          <>
            <div className={clsx(pageStyles['page-reservations__modal'])}>
              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <LocationSearchInput
                  prompt={t('Customer Hotel')}
                  location={(guestHotel && guestHotel.location_name) || ''}
                  onSearchChange={(location_name) =>
                    this.setState({
                      guestHotel: {
                        location_name,
                      },
                    })
                  }
                  onLocationSelect={({
                    title: location_name,
                    key: google_place_id,
                  }) =>
                    this.setState({
                      guestHotel: {
                        location_name,
                        google_place_id,
                      },
                    })
                  }
                />
              </div>

              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={this.handleClickAutofillCheckin}
                >
                  {t('Autofill checkin')}
                </Button>
              </div>

              <div
                className={clsx(pageStyles['page-reservations__modal__box'])}
              >
                <LocationWithTimeEditFormFields
                  locationNameLabel={t('Checkin Location Name')}
                  locationDescriptionLabel={t(
                    'Checkin Location Description (ex: "Main lobby", "Car park", "Main wing")'
                  )}
                  locationTimeLabel={t('Checkin Time')}
                  locationDateLabel={t('Checkin Date')}
                  location={checkin}
                  productCandidateLocations={(product && product.checkin) || []}
                  startTime={startTime}
                  timeSlotKey={timeSlotKey}
                  onLocationChange={(checkin) => {
                    this.setState({
                      checkin,
                    });
                  }}
                />
              </div>
            </div>

            <div className={clsx(baseStyles['base-text-divider'])} />

            <ReservationActorInputForm />

            <TextArea
              label={t('Replies')}
              value={supplierNotes}
              onChange={(e: any) => {
                this.setState({
                  //...this.state,
                  supplierNotes: e.target.value,
                });
              }}
            />
          </>
        )}

        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <Button
            disabled={pristine}
            style="gray"
            size="middle"
            onClick={() =>
              this.setState({
                guestHotel: originalGuestHotel,
                checkin: originalCheckin,
                supplierNotes: originalSupplierNotes,
              })
            }
          >
            {t('Discard')}
          </Button>
          <Button
            disabled={pristine}
            style="green"
            size="middle"
            onClick={() => {
              checkin &&
                (
                  this.props.updateReservation(reservation.id, {
                    guest_hotel: guestHotel,
                    checkin: {
                      id: checkin.locationID,
                      google_place_id: checkin.googlePlaceID,
                      location_name: checkin.locationName,
                      location_description: checkin.locationDescription,
                      image_urls: checkin.imageUrls,
                      date_time_utc:
                        (checkin.locationDateTime &&
                          checkin.locationDateTime.utc().format()) ||
                        undefined,
                    },
                    supplier_notes: supplierNotes,
                  } as any) as any
                ).then(() => onUpdateCompleted && onUpdateCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];
  return {
    activeUser: activeUserSelector(state),
    loading: state.reservations.loading || state.products.loading,
    locale: state.language.selected.iso,
    monthYearFormat: state.language.selected.monthYearFormat,
    reservation,
    product:
      reservation &&
      reservation.product_id &&
      state.products.byID[reservation.product_id],
    productInstance: reservation?.product_instance_id
      ? state.productInstances.byID[reservation.product_instance_id]
      : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
  updateReservation: (id: string, patch: Record<string, any>) =>
    dispatch(updateReservation(id, patch)),
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
});

export const CheckinEditButton = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(CheckinEditButtonComponent);
