import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import { getDateFilterPresetText } from 'client/libraries/util/searchReservations';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import type { ReduxState } from 'client/reducers';
import {
  productOptionsSelector,
  supplierOptionsSelector,
} from 'client/reducers/products';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import {
  activeUserSelector,
  agentOptionsSelector,
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { ReservationSearchConditionPresetSelector } from 'client/components/ReservationSearchConditionPresetSelector/ReservationSearchConditionPresetSelector';
import { Box } from 'client/components/Box/Box';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import { presetSearchConditionsEnabledForAccount } from 'shared/models/access';
import { TranslateFuncType } from 'client/components/Translate';
import { CheckinStatus, WaiverCompletionStatus } from 'shared/models/swagger';

import styles from './ReservationSearchQueryDisplayBox.module.css';

const getWaiverCompletionStatusText = (
  waiverStatus: WaiverCompletionStatus,
  t: TranslateFuncType
): string => {
  switch (waiverStatus) {
    case 'NOT_APPLICABLE':
      return t('Waiver Not Required');
    case 'UNSIGNED':
      return t('Not Started');
    case 'PARTIALLY_SIGNED':
      return t('Partially Complete');
    case 'COMPLETED':
      return t('Completed');
  }

  return '';
};

const getCheckinStatusText = (
  checkinStatus: CheckinStatus,
  t: TranslateFuncType
): string => {
  switch (checkinStatus) {
    case 'NOT_CHECKED_IN':
      return t('Checkin Not Started');
    case 'IN_PROGRESS':
      return t('Partially Checked-in');
    case 'CHECKED_IN':
      return t('Checkin Complete');
  }

  return '';
};

const formattedTotalHits = (totalHits: number): string => {
  if (totalHits === 10000) {
    return `${totalHits.toLocaleString()}+`;
  }

  return totalHits.toLocaleString();
};

type Props = {
  inline?: boolean;
  searchFilters: SearchReservationsRequest;
  onChangePreset?: (presetKey: string) => void;
};
export const ReservationSearchQueryDisplayBox = ({
  inline,
  searchFilters,
  onChangePreset,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const groupOptions = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  ).map((option) => {
    return {
      value: option.id,
      text: option.name,
    };
  });
  const orderByOptions = useOrderByOptions();
  const contractedLoading = useSelector(
    (state: ReduxState) => state.organizations.contractedLoading
  );
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const totalHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const loading = contractedLoading || productsLoading;
  const statusOptions = getBookingStatusOptions(t);
  const bookingSourceOptions = getBookingSourceOptions(t);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (searchFilters.productIds?.length > 0) {
    filters.push({
      typeText: t('Products'),
      filterValue: searchFilters.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.statuses?.length > 0) {
    filters.push({
      typeText: t('Booking Status'),
      filterValue: searchFilters.statuses
        .map(
          (status) =>
            statusOptions.find((option) => option.value === status)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.agentReference) {
    filters.push({
      typeText: t('Application Number'),
      filterValue: searchFilters.agentReference,
    });
  }

  if (searchFilters.supplierOrAgentReference) {
    filters.push({
      typeText: t('Confirmation/Application Number'),
      filterValue: searchFilters.supplierOrAgentReference,
    });
  }

  if (searchFilters.supplierReference) {
    filters.push({
      typeText: t('Confirmation Number'),
      filterValue: searchFilters.supplierReference,
    });
  }

  if (searchFilters.id) {
    filters.push({
      typeText: t('#'),
      filterValue: searchFilters.id,
    });
  }

  if (searchFilters.annualPassOnly) {
    if (searchFilters.expirationDateFilterPreset) {
      const preset = searchFilters.expirationDateFilterPreset;
      filters.push({
        typeText: t('Date Preset'),
        filterValue: getDateFilterPresetText(preset, t),
      });
    }
    if (searchFilters.expirationDateFrom || searchFilters.expirationDateTo) {
      filters.push({
        typeText: t('Expiration Date'),
        filterValue: `${searchFilters.expirationDateFrom ?? ''} ~ ${
          searchFilters.expirationDateTo
        }`,
      });
    }
  } else {
    if (searchFilters.dateFilterPreset) {
      const preset = searchFilters.dateFilterPreset;
      filters.push({
        typeText: t('Date Preset'),
        filterValue: getDateFilterPresetText(preset, t),
      });
    }
    if (
      searchFilters.participationDateFrom ||
      searchFilters.participationDateTo
    ) {
      filters.push({
        typeText: t('Participation Date'),
        filterValue: `${searchFilters.participationDateFrom ?? ''} ~ ${
          searchFilters.participationDateTo
        }`,
      });
    }

    if (searchFilters.bookedDateFrom || searchFilters.bookedDateTo) {
      filters.push({
        typeText: t('Booked Date'),
        filterValue: `${searchFilters.bookedDateFrom ?? ''} ~ ${
          searchFilters.bookedDateTo
        }`,
      });
    }

    if (searchFilters.lastUpdatedDateFrom || searchFilters.lastUpdatedDateTo) {
      filters.push({
        typeText: t('Last Updated Date'),
        filterValue: `${searchFilters.lastUpdatedDateFrom ?? ''} ~ ${
          searchFilters.lastUpdatedDateTo
        }`,
      });
    }
  }

  if (locale === 'ja') {
    if (searchFilters.customerFamilyName) {
      filters.push({
        typeText: t('Family Name'),
        filterValue: searchFilters.customerFamilyName,
      });
    }

    if (searchFilters.customerGivenName) {
      filters.push({
        typeText: t('Given Name'),
        filterValue: searchFilters.customerGivenName,
      });
    }
  } else {
    if (searchFilters.customerGivenName) {
      filters.push({
        typeText: t('Given Name'),
        filterValue: searchFilters.customerGivenName,
      });
    }

    if (searchFilters.customerFamilyName) {
      filters.push({
        typeText: t('Family Name'),
        filterValue: searchFilters.customerFamilyName,
      });
    }
  }

  if (searchFilters.customerPhone) {
    filters.push({
      typeText: t('Phone'),
      filterValue: searchFilters.customerPhone,
    });
  }

  if (searchFilters.customerEmail) {
    filters.push({
      typeText: t('Email'),
      filterValue: searchFilters.customerEmail,
    });
  }

  if (searchFilters.bookingSourceTypes?.length > 0) {
    filters.push({
      typeText: t('Booking Source'),
      filterValue: searchFilters.bookingSourceTypes
        .map(
          (sourceType) =>
            bookingSourceOptions.find((option) => option.value === sourceType)
              ?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.agentIds?.length > 0) {
    filters.push({
      typeText: t('Agent'),
      filterValue: searchFilters.agentIds
        .map(
          (agentId) =>
            agentOptions.find((option) => option.value === agentId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.groupIds?.length > 0) {
    filters.push({
      typeText: t('Group'),
      filterValue: searchFilters.groupIds
        .map(
          (groupId) =>
            groupOptions.find((option) => option.value === groupId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.supplierIds?.length > 0) {
    filters.push({
      typeText: t('Supplier'),
      filterValue: searchFilters.supplierIds
        .map(
          (supplierId) =>
            supplierOptions.find((option) => option.value === supplierId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.reservationLanguages?.length > 0) {
    const options = getSourceLanguageOptions(t);
    filters.push({
      typeText: t('Language'),
      filterValue: searchFilters.reservationLanguages
        .map((lang) => options.find((option) => option.value === lang)?.text)
        .join(','),
    });
  }

  if (searchFilters.mostRecentEmailBounced) {
    filters.push({
      typeText: t('Error when sending emails'),
      filterValue: t('Yes'),
    });
  }

  if (searchFilters.pickupCheckinLocationName) {
    filters.push({
      typeText: t('Pickup/Checkin Location'),
      filterValue: searchFilters.pickupCheckinLocationName,
    });
  }

  if (searchFilters.waiverCompletionStatuses?.length > 0) {
    filters.push({
      typeText: t('Waiver Status'),
      filterValue: searchFilters.waiverCompletionStatuses
        ?.map((waiverStatus) => getWaiverCompletionStatusText(waiverStatus, t))
        .join(','),
    });
  }

  if (searchFilters.checkinStatuses?.length > 0) {
    filters.push({
      typeText: t('Checkin Status'),
      filterValue: searchFilters.checkinStatuses
        ?.map((checkinStatus) => getCheckinStatusText(checkinStatus, t))
        .join(','),
    });
  }

  if (searchFilters.automaticContinuingStatus) {
    filters.push({
      typeText: t('Automatic Continuing Status'),
      filterValue: searchFilters.automaticContinuingStatus,
    });
  }

  return (
    <div
      className={styles['search-filter-display-box']}
      style={
        inline
          ? {
              padding: 0,
            }
          : {}
      }
    >
      {!searchFilters.annualPassOnly &&
        presetSearchConditionsEnabledForAccount(activeUser) &&
        (
          activeUserOrganization?.account_reservation_search_settings
            ?.presets ?? []
        ).length > 0 &&
        !isNutmegAdmin &&
        onChangePreset && (
          <Box mb={2}>
            <b>{t('Preset Condition')}</b>
            <ReservationSearchConditionPresetSelector
              value={searchFilters.presetKey ?? ''}
              onChange={(presetKey) => {
                onChangePreset?.(presetKey);
              }}
            />
          </Box>
        )}

      {!inline && <b>{t('Search Conditions')}</b>}

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          <li>{`${t('Order By')}: ${
            orderByOptions.find(
              (option) => option.value === searchFilters.orderBy
            )?.text
          }`}</li>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
          {!inline && (
            <li className={styles['total']}>
              <b>{t('Total: ')}</b>

              {t('{{count}} records', {
                count: formattedTotalHits(totalHits),
              })}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
