import * as React from 'react';
import _ from 'lodash';
import { Field, Form, FormSpy } from 'react-final-form';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { config } from 'client/config';
import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { createDigitalMap, updateDigitalMap } from 'client/actions/digitalMaps';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Delete } from 'client/components/Icons/Delete';
import { Edit } from 'client/components/Icons/Edit';
import { productOptionsSelector } from 'client/reducers/products';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
  calculateDefaultMapCenter,
} from './formValues';
import { GoogleMapView } from './GoogleMapView';

export const DigitalMapBasicsEditor = () => {
  const { t } = useTranslation();
  const store = useStore<ReduxState>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoaded } = useGoogleMapsApi();

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );
  const stampRallies = useSelector(
    (state: ReduxState) => state.guidanceStampRallies.all
  );
  const productOptions = useSelector(productOptionsSelector);

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        if (id) {
          await dispatch(
            updateDigitalMap(id, convertFormValuesToSwagger(values))
          );
        } else {
          await dispatch(createDigitalMap(convertFormValuesToSwagger(values)));

          const newMapId =
            store.getState().digitalMaps.lastCreatedDigitalMap?.id;
          history.push(`/maps/${newMapId}/edit`);
        }
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
      debug={console.log}
    >
      {({
        form,
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          {submitSucceeded && !modifiedSinceLastSubmit && (
            <Message success header={t('Save Successful')} />
          )}
          {submitError && !modifiedSinceLastSubmit && (
            <Message error header={t('Save Failed')} />
          )}
          <div className={baseStyles['base-main__body__box']}>
            <div className={baseStyles['base-main__body__box__body']}>
              <Field name="name">
                {({ input }) => <Input label={t('Name')} {...input} />}
              </Field>
              <Field name="productIds">
                {({ input }) => (
                  <MultiSelect
                    label={t(
                      'Linked Products (include map link in reservation emails for these products)'
                    )}
                    selectedValues={input.value}
                    onChange={({ value }) => input.onChange(value)}
                    options={productOptions}
                    search
                  />
                )}
              </Field>
              {stampRallies.length > 0 && (
                <Box mt={2}>
                  <Field name="stampRallyId">
                    {({ input }) => (
                      <FieldWrapper label={t('Stamp rally')}>
                        <Select
                          options={[
                            {
                              value: '',
                              text: t('None'),
                            },
                            ...(stampRallies.map((stampRally) => ({
                              value: stampRally.id ?? '',
                              text: stampRally.title ?? '',
                            })) ?? []),
                          ]}
                          value={input.value ?? ''}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </Box>
              )}
              <Field name="ga4AnalyticsTag">
                {({ input }) => (
                  <Box mt={2}>
                    <Input label={t('GA4 Analytics Tag')} {...input} />
                  </Box>
                )}
              </Field>
              <Field name="background.useOverlayImage">
                {({ input }) => (
                  <Box mt={2} mb={2}>
                    <ToggleButton
                      label={t('Use Overlay Image')}
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                    />
                  </Box>
                )}
              </Field>
              {!values.background.useOverlayImage && (
                <Field name="mapCenter">
                  {({ input }) => (
                    <Box mb={4}>
                      <FieldWrapper label={t('Default Google Map Center')} />
                      <Box display="flex" alignItems="center">
                        {values.editingMode !== 'CENTER' && (
                          <div>
                            {`(${input.value.latitude}, ${input.value.longitude})`}
                          </div>
                        )}
                        {values.editingMode === 'NORMAL' && (
                          <Box ml={2}>
                            <Edit
                              onClick={() =>
                                form.change('editingMode', 'CENTER')
                              }
                            />
                          </Box>
                        )}
                        {values.editingMode === 'CENTER' && (
                          <Box display="flex" gap={1} alignItems="center">
                            <div>{t('Click on the map to change')}</div>
                            <Box ml={2}>
                              <Button.Create
                                onClick={() => {
                                  form.change('editingMode', 'NORMAL');
                                }}
                              >
                                {t('Done')}
                              </Button.Create>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Field>
              )}
              {values.background.useOverlayImage && (
                <>
                  {config.enableOverlayTiling && (
                    <Field name="background.useOverlayTiling">
                      {({ input }) => (
                        <Box mb={2} display="flex" alignItems="center">
                          <ToggleButton
                            label={
                              <>
                                {t('Enable overlay tiling')}
                                <Box ml={1}>
                                  <Tooltip
                                    text={t(
                                      'When enabled, the overlay image will be split into smaller images for efficient loading rather than loading a single large image.'
                                    )}
                                  >
                                    <i className="c-icon-outline-general-info-circle"></i>
                                  </Tooltip>
                                </Box>
                              </>
                            }
                            checked={input.value}
                            onChange={() => {
                              input.onChange(!input.value);
                            }}
                          />
                        </Box>
                      )}
                    </Field>
                  )}
                  <Field name="background.imageUrl">
                    {({ input }) => (
                      <Box mt={2}>
                        <FieldWrapper label={t('Background Image URL')} />
                        <ImageVideoAudioInput
                          enableSvgFormat
                          fileUrls={input.value ? [input.value] : []}
                          onChange={(newValue) => {
                            const newImageUrl = newValue[0];
                            newValue.length > 0
                              ? input.onChange(newValue[0])
                              : input.onChange('');

                            // Get aspect ratio of image
                            const img = new Image();
                            img.onload = () => {
                              form.change(
                                'background.aspectRatio.width' as keyof FormValues,
                                img.width
                              );
                              form.change(
                                'background.aspectRatio.height' as keyof FormValues,
                                img.height
                              );
                            };
                            img.src = newImageUrl;
                          }}
                          maxFileCount={1}
                          disableYoutubeVideos
                        />
                      </Box>
                    )}
                  </Field>
                  <FieldWrapper label={t('Background Image Aspect Ratio')}>
                    {`${values.background.aspectRatio.width}: ${values.background.aspectRatio.height}`}
                  </FieldWrapper>
                  <Field name="background">
                    {({ input }) => (
                      <Box mb={4}>
                        <FieldWrapper label={t('Top Left Corner')} />
                        <Box display="flex" alignItems="center">
                          {values.editingMode !== 'TOP_LEFT' && (
                            <div>
                              {`(${input.value.topLeft.latitude}, ${input.value.topLeft.longitude})`}
                            </div>
                          )}
                          {values.editingMode === 'NORMAL' && (
                            <Box ml={2}>
                              <Delete
                                onClick={() => {
                                  form.change(
                                    'background.topLeft.latitude' as any,
                                    0
                                  );
                                  form.change(
                                    'background.topLeft.longitude' as any,
                                    0
                                  );
                                }}
                              />
                              <Edit
                                onClick={() =>
                                  form.change('editingMode', 'TOP_LEFT')
                                }
                              />
                            </Box>
                          )}
                          {values.editingMode === 'TOP_LEFT' && (
                            <>
                              <div>{t('Click map to set new location')}</div>
                              <Box ml={2}>
                                <Button.Create
                                  onClick={() => {
                                    form.change('editingMode', 'NORMAL');
                                  }}
                                >
                                  {t('Done')}
                                </Button.Create>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Field>

                  <Field name="background.widthInMeters">
                    {({ input }) => (
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        mb={2}
                      >
                        <Box mr={2}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              let newValue = input.value - 100;
                              if (newValue < 0) {
                                newValue = 0;
                              }
                              input.onChange(newValue);
                            }}
                            width={30}
                          >
                            -100
                          </Button>
                        </Box>
                        <Box mr={2}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              let newValue = input.value - 10;
                              if (newValue < 0) {
                                newValue = 0;
                              }
                              input.onChange(newValue);
                            }}
                            width={30}
                          >
                            -10
                          </Button>
                        </Box>
                        <Box mr={2}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              let newValue = input.value - 1;
                              if (newValue < 0) {
                                newValue = 0;
                              }
                              input.onChange(newValue);
                            }}
                            width={30}
                          >
                            -
                          </Button>
                        </Box>
                        <Box width="100px">
                          <Input
                            label={t('Overlay Width in Meters')}
                            value={input.value}
                            onChange={(e, { value }) => {
                              try {
                                const newValue = parseInt(value, 10);

                                if (isNaN(newValue)) {
                                  input.onChange(0);
                                }

                                if (newValue < 0) {
                                  return;
                                }

                                input.onChange(newValue);
                              } catch (e) {
                                /* empty */
                              }
                            }}
                          />
                        </Box>
                        <Box ml={1}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              input.onChange(input.value + 1);
                            }}
                            width={30}
                          >
                            +
                          </Button>
                        </Box>
                        <Box ml={1}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              input.onChange(input.value + 10);
                            }}
                            width={30}
                          >
                            +10
                          </Button>
                        </Box>
                        <Box ml={1}>
                          <Button
                            size="middle"
                            style="yellow"
                            onClick={() => {
                              input.onChange(input.value + 100);
                            }}
                            width={30}
                          >
                            +100
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Field>
                  <Field name="showOverlayOnMap">
                    {({ input }) => (
                      <Box mb={2}>
                        <ToggleButton
                          label={t('Show Overlay')}
                          checked={input.value}
                          onChange={() => {
                            input.onChange(!input.value);
                          }}
                        />
                      </Box>
                    )}
                  </Field>
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {
                      if (isLoaded && values) {
                        const currentMapCenter = values.mapCenter;
                        const calculatedMapCenter = calculateDefaultMapCenter(
                          values as any
                        );
                        if (!_.isEqual(currentMapCenter, calculatedMapCenter)) {
                          form.change('mapCenter', calculatedMapCenter);
                        }
                      }
                    }}
                  />

                  <Field name="mapCenter">
                    {({ input }) => (
                      <>
                        <FieldWrapper label={t('Default Google Map Center')} />
                        <div>
                          {`(${input.value.latitude}, ${input.value.longitude})`}
                        </div>
                      </>
                    )}
                  </Field>
                </>
              )}
              <Field name="mapZoom">
                {({ input }) => (
                  <Box mb={4}>
                    <FieldWrapper label={t('Default Google Map Zoom Level')} />
                    <Box display="flex" alignItems="center">
                      {values.editingMode !== 'ZOOM' && (
                        <div>{input.value}</div>
                      )}
                      {values.editingMode === 'NORMAL' && (
                        <Box ml={2}>
                          <Edit
                            onClick={() => form.change('editingMode', 'ZOOM')}
                          />
                        </Box>
                      )}
                      {values.editingMode === 'ZOOM' && (
                        <Box display="flex" gap={1} alignItems="center">
                          <div>{t('Adjust zoom on the map to change')}</div>
                          <Box ml={2}>
                            <Button.Create
                              onClick={() => {
                                form.change('editingMode', 'NORMAL');
                              }}
                            >
                              {t('Done')}
                            </Button.Create>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Field>
              <Field name="mapRotation">
                {({ input }) => (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    mb={2}
                  >
                    <Box mr={2}>
                      <Button
                        size="middle"
                        style="yellow"
                        onClick={() =>
                          input.onChange(
                            input.value < 5
                              ? 360 - (5 - input.value)
                              : (input.value - 5) % 360
                          )
                        }
                        width={30}
                      >
                        -5°
                      </Button>
                    </Box>
                    <Box width="100px">
                      <Input
                        label={t('Map Rotation')}
                        value={input.value}
                        onChange={(e, { value }) => {
                          try {
                            const newValue = parseInt(value, 10);

                            if (isNaN(newValue)) {
                              input.onChange(0);
                            }

                            if (newValue < 0 || newValue > 359) {
                              return;
                            }

                            input.onChange(newValue);
                          } catch (e) {
                            /* empty */
                          }
                        }}
                      />
                    </Box>
                    <Box ml={1}>
                      <Button
                        size="middle"
                        style="yellow"
                        onClick={() => input.onChange((input.value + 5) % 360)}
                        width={30}
                      >
                        +5°
                      </Button>
                    </Box>
                  </Box>
                )}
              </Field>
              <GoogleMapView
                disableAutoBounds
                onClick={
                  values?.editingMode === 'TOP_LEFT'
                    ? (lat, lng) => {
                        form.change('background.topLeft.latitude' as any, lat);
                        form.change('background.topLeft.longitude' as any, lng);
                      }
                    : values?.editingMode === 'CENTER'
                    ? (lat, lng) => {
                        form.change('mapCenter.latitude' as any, lat);
                        form.change('mapCenter.longitude' as any, lng);
                      }
                    : undefined
                }
                pins={
                  !values?.background?.useOverlayImage
                    ? [
                        {
                          lat: values.mapCenter.latitude,
                          lng: values.mapCenter.longitude,
                        },
                      ]
                    : values?.editingMode === 'TOP_LEFT'
                    ? [
                        {
                          lat: values.background.topLeft.latitude,
                          lng: values.background.topLeft.longitude,
                        },
                      ]
                    : undefined
                }
                background={values.background}
                showOverlay={values.showOverlayOnMap}
                heading={values.mapRotation}
                zoom={values.mapZoom}
                initialCenter={
                  existingDigitalMap?.default_map_center
                    ? {
                        latitude:
                          existingDigitalMap.default_map_center.latitude ?? 0,
                        longitude:
                          existingDigitalMap.default_map_center.longitude ?? 0,
                      }
                    : undefined
                }
                onZoomChange={(zoom) => {
                  if (values.editingMode === 'ZOOM') {
                    form.change('mapZoom', zoom);
                  }
                }}
              />

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
