import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  name: string;
  description: string;
  latitude: number;
  longitude: number;
}

export interface GuidancePageItem {
  key: string;
  title: string;
  description: string;
  body: any;
}

export const getInitialValues = (
  guidanceLocation?: Swagger.GuidanceLocation
): FormValues => {
  return {
    name: guidanceLocation?.location_name ?? '',
    description: guidanceLocation?.location_description ?? '',
    latitude: guidanceLocation?.latitude ?? 0,
    longitude: guidanceLocation?.longitude ?? 0,
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.GuidanceLocationParams => {
  return {
    location_name: values.name,
    location_description: values.description,
    latitude: values?.latitude ?? 0,
    longitude: values?.longitude ?? 0,
  };
};
