import { ThunkDispatch } from 'redux-thunk';

import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import * as Swagger from 'shared/models/swagger';

type Dispatch = ThunkDispatch<Record<string, any>, void, any>;

export const updateFooterLink =
  (footerLink: Swagger.FooterLink, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForFooterLink(organization, footerLink, language)
      )
    );
  };
export const deleteFooterLink =
  (key: string, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        booking_widget_design_params: {
          ...organization?.booking_widget_design_params,
          footer_link_layouts:
            organization?.booking_widget_design_params?.footer_link_layouts?.map(
              (layout) =>
                layout.language === language
                  ? {
                      language,
                      footer_links: layout.footer_links?.filter(
                        (link) => link.key !== key
                      ),
                    }
                  : layout
            ),
        },
      })
    );
  };

const getOrganizationPatchForFooterLink = (
  organization: Swagger.Organization | null,
  newFooterLink: Swagger.FooterLink,
  language: Swagger.SourceLanguage
): Swagger.Organization$Patch => {
  const existingFooterLinkLayouts =
    organization?.booking_widget_design_params?.footer_link_layouts ?? [];
  const existingFooterLinks =
    existingFooterLinkLayouts.find((param) => param.language === language)
      ?.footer_links ?? [];
  const newFooterLinks = existingFooterLinks.some(
    (image) => image.key === newFooterLink.key
  )
    ? existingFooterLinks.map((existingImage) =>
        existingImage.key === newFooterLink.key ? newFooterLink : existingImage
      )
    : [...existingFooterLinks, newFooterLink];
  const newFooterLinkLayouts = [
    ...existingFooterLinkLayouts.filter(
      (layout) => layout.language !== language
    ),
    {
      language,
      footer_links: newFooterLinks,
    },
  ];
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      footer_link_layouts: newFooterLinkLayouts,
    },
  };
};

export const updatePopupMessage =
  (popupMessage: Swagger.PopupMessage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForPopupMessage(organization, popupMessage)
      )
    );
  };
export const deletePopupMessage =
  (key: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        booking_widget_design_params: {
          ...organization?.booking_widget_design_params,
          popup_messages:
            organization?.booking_widget_design_params?.popup_messages?.filter(
              (popupMessage) => popupMessage.key !== key
            ),
        },
      })
    );
  };

const getOrganizationPatchForPopupMessage = (
  organization: Swagger.Organization | null,
  newPopupMessage: Swagger.PopupMessage
): Swagger.Organization$Patch => {
  const existingPopupMessages =
    organization?.booking_widget_design_params?.popup_messages ?? [];
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      popup_messages: [
        ...existingPopupMessages.filter(
          (popupMessage) =>
            popupMessage.key !== newPopupMessage.key &&
            popupMessage.language !== newPopupMessage.language
        ),
        newPopupMessage,
      ],
    },
  };
};

export type FooterLink = {
  text: string;
  customPagePath: string;
  url: string;
  key: string;
  linkType: 'URL' | 'CUSTOM_PAGE_PATH';
};
export type PopupMessage = {
  key: string;
  displayStartDate: string;
  displayEndDate: string;
  displayType: Swagger.PopupMessageDisplayType;
  imageUrl: string;
  title: string;
  description: string;
  buttonText: string;
  buttonDestinationType: Swagger.PopupMessageButtonDestinationType;
  buttonDestinationUrl: string;
  buttonDestinationPagePath: string;
};
export type CustomizeFormValues = {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  subheaderColor: string;
  headerBackgroundColor: string;
  footerBackgroundColor: string;
  contentSectionBackgroundColor: string;
  theme: 'DEFAULT' | 'PACIFIC' | 'VIBRANT' | 'COOL_PROFESSIONAL' | 'FAMILY';
  useThemeDefaultColors: boolean;
  allowViewingReservationDetails: boolean;
  allowUpdatingReservationInfo: boolean;
  allowChangingReservationParameters: boolean;
  allowCancellingReservation: boolean;
  footerLinkLanguage: Swagger.SourceLanguage;
  footerLinks: FooterLink[];
  popupMessageLanguage: Swagger.SourceLanguage;
  popupMessage: PopupMessage | null;
  hideFrequentlyViewedProducts: boolean;
  showFooterJapaneseTermsLink: boolean;
};
export const getInitialValues = (
  organization: Swagger.Organization | null
): CustomizeFormValues => {
  const supportedLanguages = getBookingWidgetPmpSupportLanguages(organization);
  const initialSelectedLanguage =
    (supportedLanguages ?? []).length > 0 ? supportedLanguages[0] : 'JA_JP';
  return {
    theme: organization?.booking_widget_design_params?.theme || 'DEFAULT',
    primaryColor:
      organization?.booking_widget_design_params?.primary_color || '#0094cc',
    secondaryColor:
      organization?.booking_widget_design_params?.secondary_color || '#fc0',
    backgroundColor:
      organization?.booking_widget_design_params?.background_color || '#f9f9f9',
    subheaderColor:
      organization?.booking_widget_design_params?.key_texts_color || '#000000',
    headerBackgroundColor:
      organization?.booking_widget_design_params?.header_background_color ||
      '#fff',
    footerBackgroundColor:
      organization?.booking_widget_design_params?.footer_background_color ||
      '#eef3f6',
    contentSectionBackgroundColor:
      organization?.booking_widget_design_params
        ?.content_section_background_color || '#fff',
    useThemeDefaultColors: !(
      organization?.booking_widget_design_params?.primary_color ||
      organization?.booking_widget_design_params?.secondary_color ||
      organization?.booking_widget_design_params?.background_color ||
      organization?.booking_widget_design_params?.key_texts_color ||
      organization?.booking_widget_design_params?.header_background_color ||
      organization?.booking_widget_design_params?.footer_background_color ||
      organization?.booking_widget_design_params
        ?.content_section_background_color
    ),
    allowViewingReservationDetails:
      organization?.guest_my_page_settings?.allow_viewing_reservation_details ??
      false,
    allowUpdatingReservationInfo:
      organization?.guest_my_page_settings?.allow_updating_reservation_info ??
      false,
    allowChangingReservationParameters:
      organization?.guest_my_page_settings
        ?.allow_changing_reservation_parameters ?? false,
    allowCancellingReservation:
      organization?.guest_my_page_settings?.allow_cancelling_reservation ??
      false,
    footerLinkLanguage: initialSelectedLanguage,
    footerLinks: getFooterLinkFormValues(organization, initialSelectedLanguage),
    popupMessageLanguage: initialSelectedLanguage,
    popupMessage: getPopupMessageFormValues(
      organization,
      initialSelectedLanguage
    ),
    hideFrequentlyViewedProducts:
      organization?.booking_widget_design_params
        ?.hide_frequently_viewed_products ?? false,
    showFooterJapaneseTermsLink:
      !organization?.booking_widget_design_params
        ?.hide_footer_japanese_terms_link ?? true,
  };
};
export const getFooterLinkFormValues = (
  organization: Swagger.Organization | null,
  language: Swagger.SourceLanguage
): FooterLink[] => {
  return (
    organization?.booking_widget_design_params?.footer_link_layouts
      ?.find((layout) => layout.language === language)
      ?.footer_links?.map((footerLink) =>
        convertSwaggerFooterLinkToFormValue(footerLink)
      ) ?? []
  );
};
export const getPopupMessageFormValues = (
  organization: Swagger.Organization | null,
  language: Swagger.SourceLanguage
): PopupMessage | null => {
  const swaggerPopupMessage =
    organization?.booking_widget_design_params?.popup_messages?.find(
      (popupMessage) => popupMessage.language === language
    );

  if (!swaggerPopupMessage) {
    return null;
  }

  return convertSwaggerPopupMessageToFormValue(swaggerPopupMessage);
};
export const convertFormValuesToOrganizationPatch = (
  organization: Swagger.Organization | null,
  values: CustomizeFormValues
): Swagger.Organization$Patch => {
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      theme: values.theme,
      hide_frequently_viewed_products: values.hideFrequentlyViewedProducts,
      hide_footer_japanese_terms_link: !values.showFooterJapaneseTermsLink,
      ...(values.useThemeDefaultColors
        ? {
            background_color: '',
            key_texts_color: '',
            primary_color: '',
            secondary_color: '',
            header_background_color: '',
            footer_background_color: '',
            content_section_background_color: '',
          }
        : {
            background_color: values.backgroundColor,
            key_texts_color: values.subheaderColor,
            primary_color: values.primaryColor,
            secondary_color: values.secondaryColor,
            header_background_color: values.headerBackgroundColor,
            footer_background_color: values.footerBackgroundColor,
            content_section_background_color:
              values.contentSectionBackgroundColor,
          }),
      footer_link_layouts:
        organization?.booking_widget_design_params?.footer_link_layouts?.map(
          (layout) =>
            layout.language === values.footerLinkLanguage
              ? {
                  language: layout.language,
                  footer_links: values.footerLinks?.map((footerLink) =>
                    convertFormValuesToSwaggerFooterLink(footerLink)
                  ),
                }
              : layout
        ),
    },
    guest_my_page_settings: {
      allow_viewing_reservation_details: values.allowViewingReservationDetails,
      allow_updating_reservation_info: values.allowUpdatingReservationInfo,
      allow_changing_reservation_parameters:
        values.allowChangingReservationParameters,
      allow_cancelling_reservation: values.allowCancellingReservation,
    },
  };
};
export const convertFormValuesToSwaggerFooterLink = (
  values: FooterLink
): Swagger.FooterLink => {
  return {
    text: values.text,
    custom_page_path:
      values.linkType === 'CUSTOM_PAGE_PATH' ? values.customPagePath : '',
    url: values.linkType === 'URL' ? values.url : '',
    key: values.key,
  };
};
export const convertSwaggerFooterLinkToFormValue = (
  footerLink: Swagger.FooterLink
): FooterLink => {
  return {
    text: footerLink.text ?? '',
    customPagePath: footerLink.custom_page_path ?? '',
    url: footerLink.url ?? '',
    key: footerLink.key ?? '',
    linkType: footerLink.url ? 'URL' : 'CUSTOM_PAGE_PATH',
  };
};
export const convertSwaggerPopupMessageToFormValue = (
  popupMessage: Swagger.PopupMessage
): PopupMessage => {
  return {
    key: popupMessage.key ?? '',
    displayStartDate: popupMessage.display_start_date ?? '',
    displayEndDate: popupMessage.display_end_date ?? '',
    displayType: popupMessage.display_type ?? 'ALL_PAGES',
    imageUrl: popupMessage.image_url ?? '',
    title: popupMessage.title ?? '',
    description: popupMessage.description ?? '',
    buttonText: popupMessage.button_text ?? '',
    buttonDestinationType: popupMessage.button_destination_type ?? 'FULL_URL',
    buttonDestinationUrl: popupMessage.button_destination_url ?? '',
    buttonDestinationPagePath: popupMessage.button_destination_page_path ?? '',
  };
};
export const convertFormValuesToSwaggerPopupMessage = (
  popupMessage: PopupMessage,
  language: Swagger.SourceLanguage
): Swagger.PopupMessage => {
  return {
    key: popupMessage.key ?? '',
    language: language,
    display_start_date: popupMessage.displayStartDate,
    display_end_date: popupMessage.displayEndDate,
    display_type: popupMessage.displayType,
    image_url: popupMessage.imageUrl,
    title: popupMessage.title,
    description: popupMessage.description,
    button_text: popupMessage.buttonText,
    button_destination_type: popupMessage.buttonDestinationType,
    button_destination_url: popupMessage.buttonDestinationUrl,
    button_destination_page_path: popupMessage.buttonDestinationPagePath,
  };
};
