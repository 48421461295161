import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { SeatContext } from 'client/contexts/SeatContext';
import { bulkAssignEquipmentAssignments } from 'client/actions/equipmentAssignments';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import {
  EquipmentAssignment,
  Equipment,
  EquipmentInstance,
  Reservation,
} from 'shared/models/swagger';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './SeatAssignment.module.css';
import {
  getCurrentEquipmentBlockInstanceGuestCount,
  getEquipmentBlockInstanceProperty,
} from './utils';
import { SeatAssignmentContext } from './SeatAssignmentContext';
import { MapCellView } from './MapCellView';

interface Props {
  column: number;
  row: number;
  equipmentBlockInstanceKey: string;
  filteredEquipmentAssignments: EquipmentAssignment[] | undefined;
  cellReservationIdMapping: string[][];
  selectedEquipment: Equipment | undefined;
  hoveredReservationId?: string;
  setHoveredReservationId?: (reservationId: string) => void;
  selectedReservationId: string;
  draggingReservationId: string;
  onReservationDragEnd?: (reservationId: string) => void;
  selectedGuestTypeKey: string;
  participationDate: string;
  replacedEquipmentBlockInstanceKeyMap?: {
    from: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
    to: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
  }[];
  onReplacedEquipmentBlockInstanceKeyMapChange?: (
    arg: {
      from: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
      to: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
    }[]
  ) => void;
  selectedEquipmentInstance: EquipmentInstance | undefined;
  reservationNumber?: { [key: string]: string } | null;
  filteredReservations?: Reservation[];
  setPopupPosition: (arg: { left: number; top: number } | null) => void;
  setShowAssignedSeatHoverPopupWindow: (arg: boolean) => void;
  setShowAssignedSeatSelectPopupWindow: (arg: boolean) => void;
  setShowUnAssignedSeatSelectPopupWindow: (arg: boolean) => void;
  setShowUnAssignedSeatHoverPopupWindow: (arg: boolean) => void;
  columnWidth?: number;
  rowWidth?: number;
  onHoveredEquipmentBlockInstanceKeyChange?: (args: {
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  }) => void;
  onSelectedReservationIdOnSeatMapChange?: (arg: string) => void;
}

export const SeatAssignmentMapCell = ({
  column,
  row,
  equipmentBlockInstanceKey,
  filteredEquipmentAssignments,
  cellReservationIdMapping,
  selectedEquipment,
  hoveredReservationId,
  setHoveredReservationId,
  selectedReservationId,
  draggingReservationId,
  onReservationDragEnd,
  replacedEquipmentBlockInstanceKeyMap,
  onReplacedEquipmentBlockInstanceKeyMapChange,
  selectedEquipmentInstance,
  reservationNumber,
  filteredReservations,
  setPopupPosition,
  setShowAssignedSeatHoverPopupWindow,
  setShowAssignedSeatSelectPopupWindow,
  setShowUnAssignedSeatSelectPopupWindow,
  setShowUnAssignedSeatHoverPopupWindow,
  columnWidth,
  rowWidth,
  onHoveredEquipmentBlockInstanceKeyChange,
  onSelectedReservationIdOnSeatMapChange,
}: Props) => {
  const dispatch = useDispatch();
  const [touched, setTouched] = React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const {
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  } = React.useContext(SeatContext);

  const { editMode, setEditMode } = React.useContext(SeatAssignmentContext);

  const ref = React.useRef<HTMLLIElement>(null);

  const selectedReservation = filteredReservations?.find(
    (reservation) => reservation.id === selectedReservationId
  );

  const property = getEquipmentBlockInstanceProperty(
    equipmentBlockInstanceKey,
    selectedEquipment,
    selectedEquipmentInstance,
    activeUserOrganization
  );

  const color = property?.color;
  const capacity = property?.capacity ?? 0;

  const count = getCurrentEquipmentBlockInstanceGuestCount(
    equipmentBlockInstanceKey,
    filteredEquipmentAssignments
  );

  const isToEquipmentBlockInstance = (equipmentBlockInstanceKey: string) => {
    const map = replacedEquipmentBlockInstanceKeyMap?.find((map) => {
      return (
        map.to.equipmentBlockInstanceKey === equipmentBlockInstanceKey &&
        map.to.equipmentInstanceId === selectedEquipmentInstance?.id
      );
    });
    return Boolean(map);
  };

  const isMoveTo = isToEquipmentBlockInstance(equipmentBlockInstanceKey);

  const isFromEquipmentBlockInstance = (equipmentBlockInstanceKey: string) => {
    const map = replacedEquipmentBlockInstanceKeyMap?.find((map) => {
      return (
        map.from.equipmentBlockInstanceKey === equipmentBlockInstanceKey &&
        map.from.equipmentInstanceId === selectedEquipmentInstance?.id
      );
    });
    return Boolean(map);
  };

  const isMoveFrom = isFromEquipmentBlockInstance(equipmentBlockInstanceKey);

  const selectedEquipmentBlockInstanceKeysInThisEquipmentInstance =
    React.useMemo(() => {
      return selectedEquipmentBlockInstanceKeys
        .filter(
          (key) => key.equipmentInstanceId === selectedEquipmentInstance?.id
        )
        .map((key) => key.equipmentBlockInstanceKey);
    }, [selectedEquipmentBlockInstanceKeys, selectedEquipmentInstance]);

  const isSelected =
    selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
      equipmentBlockInstanceKey
    );

  let noBorderTop = false;
  let noBorderLeft = false;
  let noBorderRight = false;
  let noBorderBottom = false;
  let used = false;
  let isHoveredReservationCell = false;
  let isCheckedIn = false;
  const reservationId = cellReservationIdMapping[row][column];
  const reservation = filteredReservations?.find((reservation) => {
    return reservation.id === reservationId;
  });

  if (!isSelected) {
    if (reservationId) {
      used = true;
      noBorderTop =
        row > 0
          ? reservationId === cellReservationIdMapping[row - 1][column]
          : false;
      noBorderLeft =
        column > 0
          ? reservationId === cellReservationIdMapping[row][column - 1]
          : false;
      noBorderRight =
        column < (selectedEquipment?.column_count ?? 0) - 1
          ? reservationId === cellReservationIdMapping[row][column + 1]
          : false;
      noBorderBottom =
        row < (selectedEquipment?.row_count ?? 0) - 1
          ? reservationId === cellReservationIdMapping[row + 1][column]
          : false;
      isHoveredReservationCell = reservationId === hoveredReservationId;
      isCheckedIn = Boolean(
        reservation?.checkin_info?.checkin_records?.length ?? 0
      );
    }
  }

  const clickCellHander = (equipmentBlockInstanceKey: string) => {
    if (capacity <= 0) {
      return;
    }
    if (!editMode) {
      if (reservationId) {
        setEditMode('MOVE');
        moveModeClickCellHandler(equipmentBlockInstanceKey);
      } else {
        setEditMode('CREATE');
        createModeClickCellHandler(equipmentBlockInstanceKey);
      }
    } else {
      if (editMode === 'CREATE') {
        createModeClickCellHandler(equipmentBlockInstanceKey);
      } else if (editMode === 'MOVE') {
        moveModeClickCellHandler(equipmentBlockInstanceKey);
      } else if (editMode === 'EDIT') {
        editModeClickCellHandler(equipmentBlockInstanceKey);
      } else if (editMode === 'ASSIGN') {
        assignModeClickCellHandler(equipmentBlockInstanceKey);
      }
    }
  };

  const editModeClickCellHandler = (equipmentBlockInstanceKey: string) => {
    if (
      !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
        equipmentBlockInstanceKey
      )
    ) {
      setSelectedEquipmentBlockInstanceKeys([
        ...selectedEquipmentBlockInstanceKeys,
        {
          equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
          equipmentBlockInstanceKey,
        },
      ]);
    } else {
      setSelectedEquipmentBlockInstanceKeys(
        selectedEquipmentBlockInstanceKeys.filter(
          (key) =>
            !(
              key.equipmentBlockInstanceKey === equipmentBlockInstanceKey &&
              key.equipmentInstanceId === selectedEquipmentInstance?.id
            )
        )
      );
    }
  };

  const assignModeClickCellHandler = (equipmentBlockInstanceKey: string) => {
    if (
      !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
        equipmentBlockInstanceKey
      )
    ) {
      if (
        (selectedReservation?.guests || []).length >
        selectedEquipmentBlockInstanceKeys.length
      ) {
        setSelectedEquipmentBlockInstanceKeys([
          ...selectedEquipmentBlockInstanceKeys,
          {
            equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
            equipmentBlockInstanceKey,
          },
        ]);
      }
    } else {
      setSelectedEquipmentBlockInstanceKeys(
        selectedEquipmentBlockInstanceKeys.filter(
          (key) =>
            !(
              key.equipmentBlockInstanceKey === equipmentBlockInstanceKey &&
              key.equipmentInstanceId === selectedEquipmentInstance?.id
            )
        )
      );
    }
  };

  const createModeClickCellHandler = (equipmentBlockInstanceKey: string) => {
    if (
      !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
        equipmentBlockInstanceKey
      ) &&
      !reservationId
    ) {
      setSelectedEquipmentBlockInstanceKeys([
        ...selectedEquipmentBlockInstanceKeys,
        {
          equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
          equipmentBlockInstanceKey,
        },
      ]);
    } else {
      setSelectedEquipmentBlockInstanceKeys(
        selectedEquipmentBlockInstanceKeys.filter(
          (key) =>
            !(
              key.equipmentBlockInstanceKey === equipmentBlockInstanceKey &&
              key.equipmentInstanceId === selectedEquipmentInstance?.id
            )
        )
      );
    }
  };

  const moveModeClickCellHandler = (equipmentBlockInstanceKey: string) => {
    if (
      !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
        equipmentBlockInstanceKey
      ) &&
      reservationId
    ) {
      setSelectedEquipmentBlockInstanceKeys([
        ...selectedEquipmentBlockInstanceKeys,
        {
          equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
          equipmentBlockInstanceKey,
        },
      ]);
    } else {
      if (
        selectedEquipmentBlockInstanceKeys.length > 0 &&
        replacedEquipmentBlockInstanceKeyMap
      ) {
        if (isToEquipmentBlockInstance(equipmentBlockInstanceKey)) {
          onReplacedEquipmentBlockInstanceKeyMapChange?.(
            replacedEquipmentBlockInstanceKeyMap.filter(
              (map) =>
                map.to.equipmentBlockInstanceKey !==
                  equipmentBlockInstanceKey &&
                map.to.equipmentInstanceId !== selectedEquipmentInstance?.id
            )
          );
        } else {
          const selectedEquipmentBlockInstanceKey =
            selectedEquipmentBlockInstanceKeys.pop();
          onReplacedEquipmentBlockInstanceKeyMapChange?.([
            ...replacedEquipmentBlockInstanceKeyMap,
            {
              to: {
                equipmentBlockInstanceKey,
                equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
              },
              from: {
                equipmentBlockInstanceKey:
                  selectedEquipmentBlockInstanceKey?.equipmentBlockInstanceKey ??
                  '',
                equipmentInstanceId:
                  selectedEquipmentBlockInstanceKey?.equipmentInstanceId ?? '',
              },
            },
          ]);
        }
      }
    }
  };

  const dropCellHandler = async (
    equipmentBlockInstanceKey: string,
    targetReservationId: string
  ) => {
    if (!targetReservationId) {
      return;
    }
    try {
      await dispatch(
        bulkAssignEquipmentAssignments({
          reservation_ids: [targetReservationId],
          to_equipment_instance_identifiers: [
            {
              equipment_instance_id: selectedEquipmentInstance?.id ?? '',
              start_equipment_block_instance_key: equipmentBlockInstanceKey,
            },
          ],
          ignore_booking_source: true,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const popupWindowHandler = (
    windowType:
      | 'ASSIGNED_HOVER'
      | 'ASSIGNED_SELECT'
      | 'UNASSIGNED_HOVER'
      | 'UNASSIGNED_SELECT'
      | null
  ) => {
    switch (windowType) {
      case 'ASSIGNED_HOVER':
        setShowAssignedSeatHoverPopupWindow(true);
        setShowAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatHoverPopupWindow(false);
        break;
      case 'ASSIGNED_SELECT':
        setShowAssignedSeatHoverPopupWindow(false);
        setShowAssignedSeatSelectPopupWindow(true);
        setShowUnAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatHoverPopupWindow(false);
        break;
      case 'UNASSIGNED_HOVER':
        setShowAssignedSeatHoverPopupWindow(false);
        setShowAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatHoverPopupWindow(true);
        break;
      case 'UNASSIGNED_SELECT':
        setShowAssignedSeatHoverPopupWindow(false);
        setShowAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatSelectPopupWindow(true);
        setShowUnAssignedSeatHoverPopupWindow(false);
        break;
      default:
        setShowAssignedSeatHoverPopupWindow(false);
        setShowAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatSelectPopupWindow(false);
        setShowUnAssignedSeatHoverPopupWindow(false);
        break;
    }
  };

  const hide = Boolean(
    editMode === 'ASSIGN' &&
      (Boolean(selectedReservationId) || Boolean(draggingReservationId)) &&
      (capacity - count <= 0 || property?.is_closed)
  );

  const pos = ref?.current?.getBoundingClientRect();

  const isTopLeftCorner = !noBorderTop && !noBorderLeft;

  return (
    <li
      key={`${column}-${row}`}
      className={clsx(
        count > 0 ? styles['decided'] : '',
        capacity ? styles['enable'] : '',
        isSelected ? styles['select'] : '',
        isMoveTo ? styles['move-to'] : '',
        isMoveFrom ? styles['move-from'] : '',
        isHoveredReservationCell ? styles['hovered-reservation'] : '',
        noBorderTop ? styles['no-border__top'] : '',
        noBorderLeft ? styles['no-border__left'] : '',
        noBorderRight ? styles['no-border__right'] : '',
        noBorderBottom ? styles['no-border__bottom'] : '',
        used ? styles['used'] : '',
        hide ? styles['hide'] : ''
      )}
      style={{
        backgroundColor: color || undefined,
        gridColumn: `${column + 1} / span ${columnWidth ?? 1}`,
        gridRow: `${row + 1} / span ${rowWidth ?? 1}`,
      }}
      onClick={() => {
        clickCellHander(equipmentBlockInstanceKey);
        onSelectedReservationIdOnSeatMapChange?.(reservationId);
        setPopupPosition({
          left: pos?.left ?? 0,
          top: pos?.top ?? 0,
        });
        if ((editMode === null && reservationId) || editMode === 'MOVE') {
          popupWindowHandler('ASSIGNED_SELECT');
        } else {
          popupWindowHandler('UNASSIGNED_SELECT');
        }
      }}
      onTouchStart={() => {
        setTouched(true);
      }}
      onTouchMove={() => {
        setTouched(false);
      }}
      onTouchEnd={() => {
        if (touched) {
          clickCellHander(equipmentBlockInstanceKey);
          onSelectedReservationIdOnSeatMapChange?.(reservationId);
          setPopupPosition({
            left: pos?.left ?? 0,
            top: pos?.top ?? 0,
          });
          if ((editMode === null && reservationId) || editMode === 'MOVE') {
            popupWindowHandler('ASSIGNED_SELECT');
          } else {
            popupWindowHandler('UNASSIGNED_SELECT');
          }
        }
        setTouched(false);
      }}
      onMouseEnter={(event) => {
        if (count === 0 && capacity > 0) {
          event.currentTarget.style.cursor = 'pointer';
        }
        onHoveredEquipmentBlockInstanceKeyChange?.({
          equipmentInstanceId: selectedEquipmentInstance?.id ?? '',
          equipmentBlockInstanceKey: equipmentBlockInstanceKey,
        });
        setHoveredReservationId?.(reservationId);
        if (
          (editMode === 'CREATE' && isSelected) ||
          (editMode === 'MOVE' && (isMoveTo || isMoveFrom)) ||
          editMode === null
        ) {
          setPopupPosition({
            left: pos?.left ?? 0,
            top: pos?.top ?? 0,
          });
        }
        if (editMode === 'MOVE') {
          popupWindowHandler('ASSIGNED_SELECT');
        } else if (editMode === 'CREATE') {
          popupWindowHandler('UNASSIGNED_SELECT');
        } else {
          if (reservationId) {
            popupWindowHandler('ASSIGNED_HOVER');
          } else {
            popupWindowHandler('UNASSIGNED_HOVER');
          }
        }
      }}
      onMouseLeave={() => {
        setHoveredReservationId?.('');
      }}
      onDragOver={(e) => {
        e.preventDefault(); // これがないとdropイベントが発火しない
      }}
      onDrop={() => {
        if (property?.is_closed) {
          return;
        }
        if (capacity <= 0) {
          return;
        }
        dropCellHandler(equipmentBlockInstanceKey, draggingReservationId);
        onReservationDragEnd?.('');
        setEditMode(null);
      }}
      ref={ref}
    >
      <MapCellView
        isClosed={property?.is_closed ?? false}
        capacity={capacity}
        occupied={count}
        isCheckedIn={isCheckedIn}
        hide={hide}
        label={
          reservationNumber?.[reservationId] ??
          property?.reference ??
          `${convertToAlphabet(column + 1)}${row + 1}` ??
          ''
        }
        isTopLeftCorner={isTopLeftCorner}
        isTicketPrinted={Boolean(reservation?.equipment_ticket_printed)}
      />
    </li>
  );
};
