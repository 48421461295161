import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { RealtimeNotificationTemplate } from 'shared/models/swagger';
import {
  createRealtimeNotificationTemplate,
  deleteRealtimeNotificationTemplate,
  fetchRealtimeNotificationTemplates,
} from 'client/actions/realtimeNotificationTemplates';

const EditCopyDeleteCell = ({
  notificationTemplate,
}: {
  notificationTemplate: RealtimeNotificationTemplate;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector((state: ReduxState) => state.salesOffers.loading);
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();

  return (
    <Box display="flex">
      <Link to={`/notifications/templates/${notificationTemplate.id}/edit`}>
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createRealtimeNotificationTemplate({
              title: `${notificationTemplate.title} [COPY]`,
              status: 'DRAFT',
              content: notificationTemplate.content,
              trigger: notificationTemplate.trigger,
              conditions: notificationTemplate.conditions,
              medium: notificationTemplate.medium,
            })
          );

          const lastCreatedNotificationTemplate =
            store.getState().realtimeNotificationTemplates
              .lastCreatedNotificationTemplate;
          if (lastCreatedNotificationTemplate) {
            history.push(
              `/notifications/templates/${lastCreatedNotificationTemplate.id}/edit`
            );
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Notification Template')}
            content={t(
              'Are you sure you want to delete notification template?'
            )}
            onConfirm={async () => {
              await dispatch(
                deleteRealtimeNotificationTemplate(
                  notificationTemplate.id as string
                )
              );
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<RealtimeNotificationTemplate>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (template) => (
        <EditCopyDeleteCell notificationTemplate={template} />
      ),
      width: 150,
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (salesOffer) =>
        salesOffer.status === 'ACTIVE' ? t('Active') : t('Draft'),
      width: 80,
    },
    {
      Header: t('Medium'),
      id: 'medium',
      accessor: (salesOffer) =>
        salesOffer.medium === 'EMAIL'
          ? t('Email')
          : salesOffer.medium === 'SMS'
          ? t('SMS')
          : t('Web'),
      width: 80,
    },
    {
      Header: t('Title'),
      id: 'title',
      accessor: (salesOffer) => salesOffer.title,
    },
  ];
};

export const NotificationTemplateList = () => {
  const dispatch = useDispatch();

  const columns = useColumns();

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchRealtimeNotificationTemplates());
  }, []);

  const templates = useSelector(
    (state: ReduxState) => state.realtimeNotificationTemplates.all
  );

  const sortedTemplates = _.orderBy(templates, ['title'], ['asc']);

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to="/notifications/templates/new">
            <Button style="green" size="middle">
              {t('Create New Notification Template')}
            </Button>
          </Link>
        </Box>
      </Box>
      <PagedGenericTable allItems={sortedTemplates} columns={columns} />
    </div>
  );
};
