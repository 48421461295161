import { Field } from 'react-final-form';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import baseStyles from 'client/base.module.css';

import styles from './CheckinEndRelativeDateTime.module.css';

interface Props {
  name: string;
}

export const CheckinEndRelativeDateTime = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['input-container']}>
        <label
          className={baseStyles['base-form-select']}
          style={{
            width: '120px',
          }}
        >
          <Field
            name={`${name}.daysLater`}
            parse={(value) =>
              typeof value === 'string' ? parseInt(value) : value
            }
          >
            {({ input }) => (
              <select {...input}>
                {_.times(366).map((daysLater) => (
                  <option key={daysLater} value={daysLater}>
                    {daysLater === 0
                      ? t('On the participation date')
                      : t('{{count}} days after', { count: daysLater })}
                  </option>
                ))}
              </select>
            )}
          </Field>
        </label>
        <Field name={`${name}.timeOfDay`}>
          {({ input }) => (
            <TimePicker
              allowEmpty={false}
              value={moment(input.value, 'HH:mm')}
              onChange={(newMoment) => {
                if (!newMoment) {
                  return;
                }

                input.onChange(newMoment.format('HH:mm'));
              }}
            />
          )}
        </Field>
      </div>
    </div>
  );
};
