import { createSelector } from 'reselect';

import {
  reservationColumnCandidatesSelector,
  reservationVisibleColumnsSelector,
} from 'client/reducers/reservationTableControls';
import { fetchReservationSearchCSV } from 'client/actions/reservationSearch';
import {
  getReservationTableColumns,
  ColumnType,
} from 'client/libraries/util/getReservationTableColumns';
import { TranslateFuncType } from 'client/components/Translate';
import { ReservationColumn } from 'shared/models/swagger';

import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from '../util';

export const getReservationCSVColumns = (
  t: TranslateFuncType
): ColumnType[] => {
  return [
    ...getReservationTableColumns(t, 'en'),
    {
      Header: t('Action Source'),
      id: 'ACTION_SOURCE',
    },
    {
      Header: t('Currency Code'),
      id: 'CURRENCY_CODE',
    },
    {
      Header: t('Gross'),
      id: 'GROSS',
    },
    {
      Header: t('Net'),
      id: 'NET',
    },
    {
      Header: t('Commission'),
      id: 'COMMISSION',
    },
    {
      Header: t('Amount due by agent'),
      id: 'AMOUNT_DUE_BY_AGENT',
    },
    {
      Header: t('Amount due by supplier'),
      id: 'AMOUNT_DUE_BY_SUPPLIER',
    },
    {
      Header: t('Cancellation Fee Gross'),
      id: 'CANCELLATION_FEE_GROSS',
    },
    {
      Header: t('Cancellation Fee Net'),
      id: 'CANCELLATION_FEE_NET',
    },
    {
      Header: t('Cancellation Fee Commission'),
      id: 'CANCELLATION_FEE_COMMISSION',
    },
  ];
};
export const csvColumnCandidatesSelector = createSelector(
  reservationColumnCandidatesSelector,
  (tableColumnCandidates) => [
    ...tableColumnCandidates,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
    'CANCELLATION_FEE_GROSS',
    'CANCELLATION_FEE_NET',
    'CANCELLATION_FEE_COMMISSION',
  ]
);
export const csvInitialSelectedColumnsSelector = createSelector(
  reservationVisibleColumnsSelector,
  (tableVisibleColumns) => [
    ...tableVisibleColumns,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
    'CANCELLATION_FEE_GROSS',
    'CANCELLATION_FEE_NET',
    'CANCELLATION_FEE_COMMISSION',
  ]
);

export type DownloadReservationSearchCSVRequest = SearchReservationsRequest & {
  timezone: string;
  columns: ReservationColumn[];
};

export const downloadReservationSearchCSV = (
  req: DownloadReservationSearchCSVRequest
) =>
  fetchReservationSearchCSV({
    ...buildSearchReservationsRequest(req),
    timezone: req.timezone,
    columns: req.columns.join(','),
  });
