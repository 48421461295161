import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import {
  checkinReservation,
  undoCheckinReservation,
} from 'client/actions/reservations';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  reservation: ManifestReservationShape;
  product: Product;
  trigger: React.ReactElement<any>;
};
export const MultipleCheckinReservationModal = ({
  reservation,
  product,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const [undoCheckinLoadingStubKey, setUndoCheckinLoadingStubKey] =
    React.useState<string | null>(null);
  const [checkinLoadingStubKey, setCheckinLoadingStubKey] = React.useState<
    string | null
  >(null);

  const checkin = async (stubKey: string) => {
    try {
      setCheckinLoadingStubKey(stubKey);
      await dispatch(
        checkinReservation(reservation?.id ?? '', {
          email: '',
          guest_count: (reservation?.guests ?? []).length,
          package_component_reservation_id: '',
          stub_key: stubKey,
        })
      );
    } finally {
      setCheckinLoadingStubKey(null);
    }
  };

  const undoCheckin = async (stubKey: string) => {
    try {
      setUndoCheckinLoadingStubKey(stubKey);
      await dispatch(
        undoCheckinReservation(reservation?.id ?? '', {
          stub_key: stubKey,
        })
      );
    } finally {
      setUndoCheckinLoadingStubKey(null);
    }
  };

  const getCheckinTime = (
    reservation: ManifestReservationShape | null,
    stub_key: string
  ): Moment | null => {
    if (!reservation || !stub_key) {
      return null;
    }

    const record = (reservation?.checkin_info?.checkin_records || []).find(
      (record) => {
        return record.stub_key === stub_key;
      }
    );

    if (record) {
      return moment.tz(
        record.date_time_utc,
        reservation.start_timezone || 'UTC'
      );
    }

    return null;
  };

  const getCheckinStubOptionText = (
    reservation: ManifestReservationShape | null,
    product: Product | null,
    stubKey: string
  ): string => {
    if (!reservation || !product || !stubKey) {
      return '';
    }

    const record = (reservation?.checkin_info?.checkin_records || []).find(
      (record) => {
        return record.stub_key === stubKey;
      }
    );

    if (!record) {
      return '';
    }

    if (!record.stub_option_key) {
      return '';
    }

    const stub = (product?.qr_checkin_settings?.stubs || []).find((stub) => {
      return stub.key === stubKey;
    });

    if (!stub) {
      return '';
    }

    const option = (stub?.options || []).find((option) => {
      return option.key === record.stub_option_key;
    });

    if (!option) {
      return '';
    }

    return option.text ?? '';
  };

  let records: {
    key: string;
    text: string;
    dateTime: Moment | null;
    stubOptionText: string;
  }[] = [];
  if ((product?.qr_checkin_settings?.stubs ?? []).length > 0) {
    records = (product?.qr_checkin_settings?.stubs || [])
      .map((stub) => {
        const dateTime = getCheckinTime(reservation ?? null, stub.key ?? '');
        return {
          key: stub.key ?? '',
          text: reservation?.is_free_start_date_time
            ? `${t('Free Pass')}(${stub.text ?? ''})`
            : stub.text ?? '',
          dateTime: dateTime,
          stubOptionText: getCheckinStubOptionText(
            reservation ?? null,
            product ?? null,
            stub.key ?? ''
          ),
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  } else {
    const checkinRecords = reservation?.checkin_info?.checkin_records ?? [];
    const isFreeStartDateTime = reservation?.is_free_start_date_time;

    records = [
      {
        key: '',
        text: isFreeStartDateTime ? t('Free Pass') : t('Redeem Ticket'),
        dateTime:
          checkinRecords.length === 0
            ? null
            : moment.tz(
                checkinRecords[0].date_time_utc,
                reservation?.start_timezone || 'UTC'
              ),
        stubOptionText: '',
      },
    ];
  }

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setShowModal(true),
      })}
      <Modal
        title={t('Checkin')}
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{ width: '800px' }}
        insertAtRoot={true}
      >
        <div>
          <div className={clsx(styles['c-table-main'])}>
            <div
              className={clsx(styles['c-table-main__thead'])}
              ref={tableHeaderRef}
              onScroll={scrollHandler}
            >
              <table
                className={clsx(tableStyles['c-tableSmall'])}
                style={{ overflow: 'hidden' }}
              >
                <thead>
                  <tr>
                    <th className={clsx(baseStyles['base-t-192'])}></th>
                    <th className={clsx(baseStyles['base-t-192'])}></th>
                    <th className={clsx(baseStyles['base-t-192'])}>
                      {t('Date/Time')}
                    </th>
                    <th className={clsx(baseStyles['base-t-192'])}>
                      {t('Ticket Usage')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <Button
                            text={t('Redeem Ticket')}
                            disabled={!!record.dateTime}
                            onClick={() => {
                              checkin(record.key ?? '');
                            }}
                            loading={checkinLoadingStubKey === record.key}
                          />
                        </td>
                        <td>
                          <Button
                            text={t('Delete record')}
                            color="white"
                            disabled={!record.dateTime}
                            onClick={() => {
                              undoCheckin(record.key ?? '');
                            }}
                            loading={undoCheckinLoadingStubKey === record.key}
                          />
                        </td>
                        <td
                          className={clsx(styles['center-align'])}
                          data-text={t('Date/Time')}
                        >
                          {record.dateTime?.format('lll') ?? ''}
                        </td>
                        <td data-text={t('Ticket Usage')}>{record.text}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
