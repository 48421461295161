import { useTranslation } from 'react-i18next';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type {
  Product,
  Reservation,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';

import { ReservationGuestTypeRedemptionTableBody } from './ReservationGuestTypeRedemptionTableBody';

interface Props {
  reservation: ManifestReservationShape | Reservation | undefined;
  product: Product | ProductSummary | undefined;
}

export const ReservationGuestTypeRedemptionTable = ({
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={baseStyles['u-mb-2']}>
        <div style={{ fontWeight: 700 }}>{t('Ticket Redemptions')}</div>
      </div>
      <div>
        <ReservationGuestTypeRedemptionTableBody
          reservation={reservation}
          product={product}
        />
      </div>
    </div>
  );
};
