import * as React from 'react';

export const SeatContext = React.createContext<{
  selectedEquipmentBlockInstanceKeys: {
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  }[];
  setSelectedEquipmentBlockInstanceKeys: (
    arg: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    }[]
  ) => void;
  selectedAssignableEquipmentInstanceId: string;
  setSelectedAssignableEquipmentInstanceId: (arg: string) => void;
}>({
  selectedEquipmentBlockInstanceKeys: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedEquipmentBlockInstanceKeys: () => {},
  selectedAssignableEquipmentInstanceId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedAssignableEquipmentInstanceId: () => {},
});
