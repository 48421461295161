import { combineReducers } from 'redux';

import {
  ISSUE_RESERVATION_RECEIPT_REQUEST,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST,
  SEND_RESERVATION_RECEIPT_EMAIL_REQUEST,
  ISSUE_RESERVATION_RECEIPT_SUCCESS,
  ISSUE_RESERVATION_RECEIPT_FAILURE,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE,
  SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS,
  SEND_RESERVATION_RECEIPT_EMAIL_FAILURE,
  FETCH_RESERVATION_RECEIPT_FAILURE,
  FETCH_RESERVATION_RECEIPT_REQUEST,
  FETCH_RESERVATION_RECEIPT_SUCCESS,
  ISSUE_RESERVATION_RECEIPT_RESET,
} from 'client/constants/ActionTypes';
import {
  GetReservationReceiptResponse,
  IssueReservationReceiptResponse,
  ReservationReceipt,
} from 'shared/models/swagger';

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_REQUEST:
    case FETCH_RESERVATION_RECEIPT_REQUEST:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST:
    case SEND_RESERVATION_RECEIPT_EMAIL_REQUEST:
      return true;

    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
    case ISSUE_RESERVATION_RECEIPT_FAILURE:
    case FETCH_RESERVATION_RECEIPT_SUCCESS:
    case FETCH_RESERVATION_RECEIPT_FAILURE:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE:
    case SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS:
    case SEND_RESERVATION_RECEIPT_EMAIL_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_FAILURE:
    case FETCH_RESERVATION_RECEIPT_FAILURE:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE:
    case SEND_RESERVATION_RECEIPT_EMAIL_FAILURE:
      return action.error;

    case ISSUE_RESERVATION_RECEIPT_REQUEST:
    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
    case FETCH_RESERVATION_RECEIPT_REQUEST:
    case FETCH_RESERVATION_RECEIPT_SUCCESS:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST:
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS:
    case SEND_RESERVATION_RECEIPT_EMAIL_REQUEST:
    case SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS:
      return '';

    default:
      return state;
  }
};

const data = (
  state: ReservationReceipt = {},
  action: any
): ReservationReceipt => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_SUCCESS: {
      const response = action.response as IssueReservationReceiptResponse;
      return response ?? {};
    }

    case FETCH_RESERVATION_RECEIPT_SUCCESS: {
      const response = action.response as GetReservationReceiptResponse;
      return response ?? {};
    }

    default:
      return state;
  }
};

const issueStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case ISSUE_RESERVATION_RECEIPT_REQUEST:
      return 'IN_FLIGHT';

    case ISSUE_RESERVATION_RECEIPT_SUCCESS:
      return 'SUCCESS';

    case ISSUE_RESERVATION_RECEIPT_FAILURE:
      return 'FAILURE';

    case ISSUE_RESERVATION_RECEIPT_RESET:
      return 'IDLE';

    default:
      return state;
  }
};

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case SEND_RESERVATION_RECEIPT_EMAIL_REQUEST:
      return 'IN_FLIGHT';

    case SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS:
      return 'SUCCESS';

    case SEND_RESERVATION_RECEIPT_EMAIL_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

interface State {
  data: ReservationReceipt;
  loading: boolean;
  error: string;
  issueStatus: string;
  pdfStatus: string;
  emailStatus: string;
}

export const reservationReceipts = combineReducers<State>({
  data,
  loading,
  error,
  issueStatus,
  pdfStatus,
  emailStatus,
});
