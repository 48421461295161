import { SystemFeeInvoiceDetailsHeader } from 'client/pages/v3/SystemFee/SystemFeeInvoiceDetails/SystemFeeInvoiceDetailsHeader';
import { SystemFeeInvoiceDetailsBody } from 'client/pages/v3/SystemFee/SystemFeeInvoiceDetails/SystemFeeInvoiceDetailsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const SystemFeeInvoiceDetails = () => {
  return (
    <V3Page>
      <SystemFeeInvoiceDetailsHeader />
      <SystemFeeInvoiceDetailsBody />
    </V3Page>
  );
};
