import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';

import { FormField } from 'client/pages/v3/Reservation/ReservationCreate/Booking/Form/FormField';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { DateTimeTZInput as DateTimeInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import {
  convertToLocationWithMoment,
  defaultLocationWithMoment,
} from 'client/libraries/util/coreutil';
import { timePlusOffset } from 'client/libraries/util/util';
import styles from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationCreateDetails.module.css';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import type { LocationWithTime as ProductLocationWithTime } from 'shared/models/swagger';

type Props = {
  locationNameLabel?: string;
  locationDateLabel?: string;
  locationDescriptionLabel?: string;
  locationTimeLabel?: string;
  locationDateTimeLabel?: string;
  location: LocationWithMoment;
  productCandidateLocations: ProductLocationWithTime[];
  onLocationChange: (location: LocationWithMoment) => void;
  startTime: Moment;
  timeSlotKey: string;
};

export const LocationWithTimeEditFormFields = ({
  locationNameLabel,
  locationDateLabel,
  locationDescriptionLabel,
  locationTimeLabel,
  location,
  productCandidateLocations,
  onLocationChange,
  startTime,
  timeSlotKey,
}: Props) => {
  const { t } = useTranslation();
  const [candidateLocations, setCandidateLocations] = useState<
    ProductLocationWithTime[]
  >(productCandidateLocations);

  useEffect(() => {
    setCandidateLocations(getCandidateLocations());
  }, [productCandidateLocations]);

  const getCandidateLocations = () => {
    const candidateLocations: ProductLocationWithTime[] =
      productCandidateLocations || [];
    const { locationID: id, locationName: location_name } = location;

    if (id && candidateLocations.find((loc) => loc.id === id)) {
      return candidateLocations.map((loc) =>
        loc.id === id ? { ...loc, location_name } : loc
      );
    } else {
      return [
        ...candidateLocations,
        {
          id,
          location_name,
        },
      ];
    }
  };

  const handleLocationChange = (value: string) => {
    if (value === 'CHOOSE_LATER') {
      return onLocationChange({ ...defaultLocationWithMoment });
    }

    const locationMatch = candidateLocations.find(
      (p) => p.location_name === value
    );

    if (locationMatch) {
      const newLocation = convertToLocationWithMoment(
        locationMatch as any,
        '',
        ''
      );
      // Change default locationDateTime to start time
      let newLocationDateTime = startTime;
      // Note: this only checks for matching start time and not duration, even though
      // the dedicated start time info allows separate handling for two instances that
      // start at the same time but have different durations. This is highly uncommon.
      // Only fix if it becomes an issue.
      const dedicatedStartTimeItem = (
        locationMatch.dedicated_start_time_info || []
      ).find(
        (dedicatedStartTimeItem) =>
          dedicatedStartTimeItem.time_slot_key === timeSlotKey
      );

      if (dedicatedStartTimeItem && dedicatedStartTimeItem.time_relative) {
        newLocationDateTime = timePlusOffset(
          startTime,
          dedicatedStartTimeItem.time_relative
        );
      } else if (locationMatch.time_relative) {
        newLocationDateTime = timePlusOffset(
          startTime,
          locationMatch.time_relative
        );
      }

      return onLocationChange({
        ...newLocation,
        locationDateTime: newLocationDateTime,
      });
    } else {
      return onLocationChange({
        ...location,
        // This is a custom location not found in the pickup list. Clear any existing
        // Google place ID and location IDs.
        locationID: '',
        googlePlaceID: '',
        locationName: value,
      });
    }
  };

  const handleLocationDateChange = (newDate: Moment) => {
    if (newDate) {
      if (location.locationDateTime) {
        const newLocationDateTime = moment(location.locationDateTime)
          .set('year', newDate.year())
          .set('month', newDate.month())
          .set('date', newDate.date());
        onLocationChange({
          ...location,
          locationDateTime: newLocationDateTime,
        });
      } else {
        const newLocationDateTime = moment(startTime)
          .set('year', newDate.year())
          .set('month', newDate.month())
          .set('date', newDate.date());
        onLocationChange({
          ...location,
          locationDateTime: newLocationDateTime,
        });
      }
    }
  };

  const defaultLocationDateTime =
    startTime && moment(startTime).subtract(30, 'minutes');

  return (
    <>
      <FieldWrapper
        label={locationNameLabel || t('Location')}
        wrapperClassName="p-spot__item"
      >
        <SingleDropdown
          searchable={true}
          selectedOption={location.locationName || 'CHOOSE_LATER'}
          onChange={(e) => handleLocationChange(e)}
          options={[
            {
              text: t('Choose later'),
              value: 'CHOOSE_LATER',
            },
            ...(candidateLocations || []).map((p) => ({
              text: p.location_name,
              value: p.location_name,
            })),
          ]}
        />
      </FieldWrapper>

      <FieldWrapper
        label={locationDescriptionLabel || t('Description')}
        wrapperClassName="p-spot__item"
      >
        <FormField
          prompt={locationDescriptionLabel || t('Description')}
          value={location.locationDescription}
          onChange={(newLocationDescription) =>
            onLocationChange({
              ...location,
              locationDescription: newLocationDescription,
            })
          }
          disabled={location.locationName.length === 0}
          required={false}
          textArea
          height={100}
        />
      </FieldWrapper>

      <FieldWrapper label={t('Images')} wrapperClassName="p-spot__item">
        <ImageVideoAudioInput
          fileUrls={location.imageUrls ?? []}
          onChange={(newValue) =>
            onLocationChange({
              ...location,
              imageUrls: newValue,
            })
          }
          disableYoutubeVideos
        />
      </FieldWrapper>

      <div className={styles['p-spot__item']}>
        <div className={styles['p-spot__item__flex']}>
          <FieldWrapper
            label={locationDateLabel || t('Date')}
            wrapperClassName="p-spot__item__flex__item"
          >
            <DateTimeInput
              value={location.locationDateTime}
              onChange={(e) => handleLocationDateChange(e)}
            />
          </FieldWrapper>

          <FieldWrapper
            label={locationTimeLabel || t('Time')}
            wrapperClassName="p-spot__item__flex__item"
          >
            <TimePicker
              allowEmpty={false}
              defaultOpenValue={defaultLocationDateTime}
              value={location.locationDateTime ?? undefined}
              onChange={(time) => {
                if (!time) {
                  return;
                }

                onLocationChange({
                  ...location,
                  locationDateTime: (
                    location.locationDateTime?.clone() ||
                    defaultLocationDateTime.clone()
                  )
                    .set('hour', time.hour())
                    .set('minute', time.minute()),
                });
              }}
            />
          </FieldWrapper>
        </div>
      </div>
    </>
  );
};
