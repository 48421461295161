import { createContext } from 'react';

import {
  VisibilityStateByReservationSlot,
  VisibilityStateByResource,
} from 'client/libraries/util/visibilitySettings';
import { ProductInstance } from 'shared/models/swagger';

export type CalendarEditParams = {
  selectedProductInstanceIds: string[];
  selectProductInstanceIds: (...ids: string[]) => void;
  deselectProductInstanceIds: (...ids: string[]) => void;
  calendarEditModeIsActive: boolean;
  setCalendarEditModeIsActive: (b: boolean) => void;
};

// CalendarEditContext can be used to pass a the calendar edit information down the component tree
export const CalendarEditContext = createContext<CalendarEditParams>({
  selectedProductInstanceIds: [],
  calendarEditModeIsActive: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCalendarEditModeIsActive: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectProductInstanceIds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deselectProductInstanceIds: () => {},
});

export const shouldShowBasedOnVisibilityByReservationSlot = (
  instanceVisibility: VisibilityStateByReservationSlot,
  occupiedSlots: number
) => {
  if (instanceVisibility === 'SHOW_ALL') {
    return true;
  }
  if (
    instanceVisibility === 'SHOW_ITEMS_WITH_ONE_OR_MORE_RESERVATIONS' &&
    occupiedSlots > 0
  ) {
    return true;
  }
  if (
    instanceVisibility === 'SHOW_ITEMS_WITH_ZERO_RESERVATIONS' &&
    occupiedSlots === 0
  ) {
    return true;
  }

  return false;
};

export const shouldShowBasedOnVisibilityByResource = (
  instanceVisibility: VisibilityStateByResource[],
  productInstanceId: string,
  productInstances: Record<string, ProductInstance>,
  dispatchCrews: string[],
  dispatchVehicles: string[],
  dispatchMiscResources: string[]
) => {
  if (instanceVisibility.length === 0) {
    return true;
  }

  let visibility = false;
  if (instanceVisibility.includes('CREW') && dispatchCrews.length > 0) {
    visibility = true;
  }
  if (instanceVisibility.includes('VEHICLE') && dispatchVehicles.length > 0) {
    visibility = true;
  }
  if (instanceVisibility.includes('MISC') && dispatchMiscResources.length > 0) {
    visibility = true;
  }
  if (
    instanceVisibility.includes('MEMO') &&
    productInstances[productInstanceId] &&
    productInstances[productInstanceId].memo
  ) {
    visibility = true;
  }

  return visibility;
};
