import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { TranslateFuncType } from 'client/components/Translate';
import { Loading } from 'client/components/v3/Common/Loading';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { CustomerReservationData } from '../type';
import { getAnnualPassData } from '../util';

import { Gadget } from './Gadget';
import { DonutChart } from './DonutChart';
import styles from './Gadget.module.css';

const COLORS = ['#3B82F6', '#93C5FD', '#DBEAFE'];

type Props = {
  data: CustomerReservationData[] | null;
};

const getChartData = (
  data: CustomerReservationData[] | null,
  timezone: string,
  t: TranslateFuncType
) => {
  if (!data) {
    return null;
  }

  // Filter only annual pass reservations
  const annualPassData = getAnnualPassData(data);

  // Filter customers who have not made any reservations
  const customersWithoutAnyReservations = annualPassData.filter(
    (customerReservationData) =>
      customerReservationData.reservations.length === 0
  );
  const numberOfCustomersWithoutAnyReservations =
    customersWithoutAnyReservations.length;

  // Filter customers who have made reservations
  const customersWithAnnualPassPurchase = annualPassData.filter(
    (customerReservationData) => customerReservationData.reservations.length > 0
  );

  let numberOfCustomerWithAnnualPassAndAutoRenewal = 0;
  let numberOfCustomerWithAnnualPassButNoAutoRenewal = 0;
  let numberOfCustomersWithoutAnnualPass = 0;

  for (const customerReservationData of customersWithAnnualPassPurchase) {
    // Only consider CONFIRMED annual passes
    const confirmedReservations = customerReservationData.reservations.filter(
      (r) => r.status === 'CONFIRMED'
    );
    const noAnnualPass = confirmedReservations.length === 0;

    // Check if the customer has at least one annual pass with auto renewal
    const annualPassWithAutoRenewal = confirmedReservations.some(
      (r) => r.should_auto_renew_annual_pass
    );

    // Otherwise check if the customer has no auto renewal
    const annualPassWithoutAutoRenewal = confirmedReservations.every(
      (r) => !r.should_auto_renew_annual_pass
    );

    // Canceled
    // All annual passes which have expiration date and no auto renewal
    const canceledAnnualPass = confirmedReservations.every((r) => {
      // If checkin info has no expiration date, annual pass is not used yet, treat as valid annual pass
      if (!r.checkin_info?.expiration_date_time_utc) {
        return false;
      }

      // If e-ticket expiration date time is after current time, treat as valid annual pass
      const expirationDateTimeLocal = moment
        .utc(r.checkin_info?.expiration_date_time_utc)
        .tz(timezone);
      const currentDateTimeLocal = moment().tz(timezone);
      if (expirationDateTimeLocal.isAfter(currentDateTimeLocal)) {
        return false;
      }

      return !r.should_auto_renew_annual_pass;
    });

    // Customers with reservations but no annual passes
    if (noAnnualPass) {
      numberOfCustomersWithoutAnnualPass++;
    } else if (canceledAnnualPass) {
      numberOfCustomersWithoutAnnualPass++;
    } else if (annualPassWithoutAutoRenewal) {
      numberOfCustomerWithAnnualPassButNoAutoRenewal++;
    } else if (annualPassWithAutoRenewal) {
      numberOfCustomerWithAnnualPassAndAutoRenewal++;
    }
    // Falloff
    else {
      numberOfCustomersWithoutAnnualPass++;
    }
  }

  return [
    {
      name: t('Purchase with automatic renewal'),
      value: numberOfCustomerWithAnnualPassAndAutoRenewal,
    },
    {
      name: t('Purchase without automatic renewal'),
      value: numberOfCustomerWithAnnualPassButNoAutoRenewal,
    },
    {
      name: t('Not purchased'),
      value:
        numberOfCustomersWithoutAnyReservations +
        numberOfCustomersWithoutAnnualPass,
    },
  ];
};

export const PurchasedAnnualPassChartGadget = ({ data }: Props) => {
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const defaultTimezone = activeOrganization?.default_timezone;

  const chartData = getChartData(data, defaultTimezone ?? '', t);

  let noDataToDisplay = false;
  if (chartData && chartData.every((data) => data.value === 0)) {
    noDataToDisplay = true;
  }

  return (
    <Gadget
      title={t('Percentage of Users Purchased Annual Passes')}
      tooltipText={t(
        'Display the breakdown of users who purchased annual passes'
      )}
    >
      <div className={styles['c-gadget__body_chart']}>
        {!chartData && <Loading size="md" />}
        {noDataToDisplay && (
          <div className={styles['noData']}>
            <p>{t('No data to display for the selected data set')}</p>
          </div>
        )}
        {chartData && !noDataToDisplay && (
          <DonutChart data={chartData} colors={COLORS} />
        )}
      </div>
    </Gadget>
  );
};
