import * as React from 'react';
import { useDispatch } from 'react-redux';

import { setAlternateOrganizationIsActiveInSecret } from 'client/actions/user';
import { CheckinRecordTable } from 'client/components/CheckinRecordTable/CheckinRecordTable';

export const PartnershipCheckinRecordList = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setAlternateOrganizationIsActiveInSecret(true));
    return () => {
      dispatch(setAlternateOrganizationIsActiveInSecret(false));
    };
  }, []);

  return <CheckinRecordTable />;
};
