import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_ACTIM_RESERVATION_BY_ID_FAILURE,
  FETCH_ACTIM_RESERVATION_BY_ID_REQUEST,
  FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS,
  FETCH_ACTIM_RESERVATIONS_CANCELED,
  FETCH_ACTIM_RESERVATIONS_FAILURE,
  FETCH_ACTIM_RESERVATIONS_REQUEST,
  FETCH_ACTIM_RESERVATIONS_SUCCESS,
  UPDATE_ACTIM_RESERVATION_FAILURE,
  UPDATE_ACTIM_RESERVATION_REQUEST,
  UPDATE_ACTIM_RESERVATION_SUCCESS,
  DELETE_ACTIM_RESERVATION_FAILURE,
  DELETE_ACTIM_RESERVATION_REQUEST,
  DELETE_ACTIM_RESERVATION_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import type { ActimReservation$Patch } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchActimReservationsRequest = () => ({
  type: FETCH_ACTIM_RESERVATIONS_REQUEST,
});

const fetchActimReservationsSuccess = (response: any) => ({
  type: FETCH_ACTIM_RESERVATIONS_SUCCESS,
  response,
});

const fetchActimReservationsFailure = (error: any) => ({
  type: FETCH_ACTIM_RESERVATIONS_FAILURE,
  error,
});

const fetchActimReservationsCanceled = () => ({
  type: FETCH_ACTIM_RESERVATIONS_CANCELED,
});

let fetchActimReservationsCancel: () => void | typeof undefined;

export const fetchActimReservations =
  (supplier_id?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchActimReservationsCancel && fetchActimReservationsCancel();
    dispatch(fetchActimReservationsRequest());
    const params = {
      supplier_id,
    };
    axios
      .get('/api/actimReservations', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchActimReservationsCancel = c;
        }),
      })
      .then((result) => dispatch(fetchActimReservationsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchActimReservationsCanceled());
        else dispatch(fetchActimReservationsFailure(error));
      });
  };

const fetchActimReservationByIDRequest = (id: string) => ({
  type: FETCH_ACTIM_RESERVATION_BY_ID_REQUEST,
  id,
});

const fetchActimReservationByIDSuccess = (responses: any) => ({
  type: FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS,
  responses,
});

const fetchActimReservationByIDFailure = (error: any) => ({
  type: FETCH_ACTIM_RESERVATION_BY_ID_FAILURE,
  error,
});

export const fetchActimReservationByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchActimReservationByIDRequest(id));
    return fetch(`/api/actimReservations/${id}`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((response) => dispatch(fetchActimReservationByIDSuccess(response)))
      .catch((error) => dispatch(fetchActimReservationByIDFailure(error)));
  };

const updateActimReservationRequest = (
  id: string,
  patch: ActimReservation$Patch
) => ({
  type: UPDATE_ACTIM_RESERVATION_REQUEST,
  id,
  patch,
});

const updateActimReservationSuccess = (response: any) => ({
  type: UPDATE_ACTIM_RESERVATION_SUCCESS,
  response,
});

const updateActimReservationFailure = (error: any) => ({
  type: UPDATE_ACTIM_RESERVATION_FAILURE,
  error,
});

export const updateActimReservation =
  (id: string, patch: ActimReservation$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateActimReservationRequest(id, patch));
    return fetch(`/api/actimReservations/${id}`, {
      method: 'PATCH',
      headers: { ...getHTTPRequestHeaders(getState()) },
      body: JSON.stringify({
        ...patch,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response: any) => {
        dispatch(updateActimReservationSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((e: any) => {
            dispatch(updateActimReservationFailure(e.message));
          });
        } else {
          dispatch(updateActimReservationFailure(error));
        }

        throw error;
      });
  };

const deleteActimReservationRequest = (id: string) => ({
  type: DELETE_ACTIM_RESERVATION_REQUEST,
  id,
});

const deleteActimReservationSuccess = (
  response: Record<string, any>,
  id: string
) => ({
  type: DELETE_ACTIM_RESERVATION_SUCCESS,
  response,
  id,
});

const deleteActimReservationFailure = (error: any) => ({
  type: DELETE_ACTIM_RESERVATION_FAILURE,
  error,
});

export const deleteActimReservation =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteActimReservationRequest(id));
    return fetch(`/api/actimReservations/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response: any) => {
        dispatch(deleteActimReservationSuccess(response, id));
      })
      .catch((error) => {
        error.json().then((e: any) => {
          dispatch(deleteActimReservationFailure(e.message));
        });
      });
  };
