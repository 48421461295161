import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormState, useForm } from 'react-final-form';

import { getLanguageName } from 'client/libraries/i18n';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, TextArea } from 'client/components/Form';
import { batchGetTranslations } from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
import type { Translation } from 'shared/models/swagger';

interface Props {
  title: string;
  name: string;
  open: boolean;
  onClose?: () => void;
}

export const EditSupportedLanguageTextModal = ({
  title,
  name,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const form = useForm();

  const { values } = useFormState();

  const [defaultLanguageText, setDefaultLanguageText] = React.useState('');

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();

  const translationLanguages = React.useMemo(
    () => supportedLanguages.filter((lang) => lang !== defaultLanguage),
    [supportedLanguages, defaultLanguage]
  );

  React.useEffect(() => {
    if (values) {
      setDefaultLanguageText(values[name]);
    }
  }, [values, name]);

  React.useEffect(() => {
    if (defaultLanguageText) {
      dispatch(batchGetTranslations([defaultLanguageText]));
    }
  }, [defaultLanguageText]);

  React.useEffect(() => {
    const apiTrans = apiTranslations.find(
      (trans) =>
        trans[defaultLanguage.toLowerCase() as keyof Translation] ===
        defaultLanguageText
    );
    if (apiTrans) {
      for (const lang of translationLanguages) {
        form.change(
          `translation.${name}.${lang.toLowerCase()}`,
          apiTrans[lang.toLowerCase() as keyof Translation]
        );
      }
    }
  }, [
    defaultLanguageText,
    defaultLanguage,
    translationLanguages,
    activeUserOrganization,
    t,
    apiTranslations,
  ]);

  const { required } = getValidators(t);

  const sourceLanguageName = getLanguageName(defaultLanguage, t);

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <FieldWrapper label={sourceLanguageName}>
        <Field name={`${name}`} validate={required}>
          {({ input, meta: { touched, error } }) => (
            <TextArea
              value={input.value}
              onChange={(_: any, { value }) => input.onChange(value)}
              error={touched && error}
            />
          )}
        </Field>
      </FieldWrapper>
      {translationLanguages.map((lang) => (
        <Box mt={1} key={lang}>
          <FieldWrapper key={lang} label={getLanguageName(lang, t)}>
            <Field name={`translation.${name}.${lang.toLowerCase()}`}>
              {({ input }) => (
                <TextArea
                  value={input.value}
                  onChange={(_: any, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </FieldWrapper>
        </Box>
      ))}
      <Modal.Actions>
        <Button size="middle" style="blue" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
