import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import clsx from 'clsx';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { AgentReferencesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AgentReferencesEditor';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { PerParticipantPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerGroupPriceInput';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getValidators } from 'shared/libraries/validate/validator';
import type { TranslateFuncType } from 'client/components/Translate';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import * as Swagger from 'shared/models/swagger';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';

const getDefaultPerParticipantPrices = (
  product: Swagger.Product | null,
  t: TranslateFuncType,
  language: string
) => {
  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units && units.some((unit) => unit.method === 'PER_PARTICIPANT')) {
    return units
      .filter((unit) => unit.method === 'PER_PARTICIPANT')
      .map((unit) => ({
        method: unit.method,
        unit: unit?.guest_type?.key ?? '',
        perAgentNetPrices: [],
        ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
        ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
        price: '100',
        netPrice: '100',
      }));
  }

  const perBookingGuestTypes = units
    ? units.find(
        (unit) =>
          unit.method === 'PER_BOOKING' &&
          (unit.per_booking_guest_types ?? []).length > 0
      )?.per_booking_guest_types
    : null;

  if (perBookingGuestTypes && perBookingGuestTypes.length > 0) {
    return perBookingGuestTypes.map((guestType) => ({
      method: 'PER_PARTICIPANT',
      unit: guestType?.key ?? '',
      perAgentNetPrices: [],
      ageBandMinimum: guestType?.minimum_age ?? 0,
      ageBandMaximum: guestType?.maximum_age ?? 0,
      price: '100',
      netPrice: '100',
    }));
  }

  return [
    {
      method: 'PER_PARTICIPANT',
      unit: t('Adult', {
        lng: language,
      }),
      ageBandMinimum: 13,
      ageBandMaximum: 0,
      price: '100',
      netPrice: '70',
      perAgentNetPrices: [],
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Child', {
        lng: language,
      }),
      ageBandMinimum: 4,
      ageBandMaximum: 12,
      price: '70',
      netPrice: '70',
      perAgentNetPrices: [],
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Infant', {
        lng: language,
      }),
      ageBandMinimum: 0,
      ageBandMaximum: 3,
      price: '0',
      netPrice: '0',
      perAgentNetPrices: [],
    },
  ];
};

const getDefaultPerBookingPrices = () => {
  return [
    {
      method: 'PER_BOOKING',
      price: '100',
      netPrice: '100',
      // need to add the following props to use _.Equal
      ageBandMinimum: 0,
      ageBandMaximum: 0,
      unit: '',
      perAgentNetPrices: [],
    },
  ];
};

const getDefaultService = (product: Swagger.Product | null) => {
  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units) {
    return {
      title: '',
      description: '',
      priceType: units[0].method,
      prices: units
        .filter((unit) => unit.method === units[0].method)
        .map((unit) => ({
          method: unit.method,
          unit: unit?.guest_type?.key ?? '',
          ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
          ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
          price: '100',
          netPrice: '100',
          perAgentNetPrices: [],
        })),
      perGroupPrices: [],
      image: '',
      isPopular: false,
      isRecommended: false,
    };
  }

  return {
    title: '',
    description: '',
    priceType: 'FREE',
    prices: [],
    image: '',
  };
};

type ServiceInputProps = {
  name: string;
  editingProduct: Swagger.Product | null;
  isAddOnsEditor: boolean;
};

const ServiceInput = ({
  name,
  editingProduct,
  isAddOnsEditor,
}: ServiceInputProps) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const form = useForm();
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const isPMP = Boolean(useSelector(privateMarketplaceSelector));
  const language =
    uppercaseIsoToLowercaseIso[editingProduct?.source_language || 'JA_JP'];
  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');
  const showNet = location.pathname.includes('edit');
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>{t('Title')}</div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <TranslatedField name={`${name}.title`} validate={required}>
            {({ input, meta: { error, touched } }) => (
              <TextField {...input} error={touched && error} />
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Description')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name={`${name}.description`}>
            {({ input }) => (
              <TextArea
                {...(input as any)}
                height={80}
                fullContainerWidth={true}
              />
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>{t('Pricing')}</div>
        </div>
        <div className={styles['p-list__item__body']}>
          <EnumRadioButtonGroup
            name={`${name}.priceType`}
            options={[
              {
                value: 'PER_PARTICIPANT',
                label: t('Per-participant'),
                tooltipText: t(
                  'Used when setting the price for each participant (Price setting per person)'
                ),
              },
              {
                value: 'PER_BOOKING',
                label: t('Per-booking'),
                tooltipText: t(
                  'Used when setting the price for each reservation (Price setting per reservation)'
                ),
              },
              {
                value: 'FREE',
                label: t('Free'),
                tooltipText: t('Used when provided for free'),
              },
            ]}
            onChange={(newValue: string) => {
              switch (newValue) {
                case 'PER_PARTICIPANT':
                  form.change(
                    `${name}.prices`,
                    getDefaultPerParticipantPrices(editingProduct, t, language)
                  );
                  break;

                case 'PER_BOOKING':
                  form.change(`${name}.prices`, getDefaultPerBookingPrices());
                  break;

                default:
                  form.change(`${name}.prices`, []);
                  break;
              }
            }}
          />
        </div>

        <Field name={`${name}.priceType`}>
          {({ input: { value } }) => (
            <>
              {value === 'PER_PARTICIPANT' && (
                <>
                  <PerParticipantPriceInput
                    name={`${name}.prices`}
                    language={language}
                    currency={defaultCurrency}
                    showNet={showNet}
                    showCustom={isProductEditOrProductCopy}
                    showPerGroup={isProductEditOrProductCopy}
                  />
                  {isProductEditOrProductCopy && (
                    <PerGroupPriceInput
                      name={`${name}.perGroupPrices`}
                      currency={defaultCurrency}
                      showNet={showNet}
                    />
                  )}
                </>
              )}
              {value === 'PER_BOOKING' && (
                <PerBookingPriceInput
                  name={`${name}.prices`}
                  language={language}
                  currency={defaultCurrency}
                  showNet={showNet}
                />
              )}
            </>
          )}
        </Field>
      </li>
      {isPMP && isAddOnsEditor && (
        <>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                {t('Private Marketplace')}
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <div className={styles['p-products__section__body__item']}>
                <Field name={`${name}.image`}>
                  {({ input }) => (
                    <ImageVideoAudioInput
                      fileUrls={input.value.length > 0 ? [input.value] : []}
                      // Add-ons image_url should be string, so we take the first element from array
                      onChange={(newValue) => input.onChange(newValue[0])}
                      disableYoutubeVideos={true}
                      maxFileCount={1}
                    />
                  )}
                </Field>
              </div>
              <div className={styles['p-products__section__body__item']}>
                <Field name={`${name}.isPopular`}>
                  {({ input }) => (
                    <Toggle
                      label={t(
                        'This is a POPULAR add-on in the privated marketplace.'
                      )}
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                      size="sm"
                    />
                  )}
                </Field>
                <div className={baseStyles['u-mt-3']}></div>
                <Field name={`${name}.isRecommended`}>
                  {({ input }) => (
                    <Toggle
                      label={t(
                        'This is a RECOMMENDED add-on in the privated marketplace.'
                      )}
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                      size="sm"
                    />
                  )}
                </Field>
              </div>
            </div>
          </li>
        </>
      )}
      {isNutmegAdmin && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              {t('Agent References')}
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <AgentReferencesEditor name={`${name}.referencesInAgentSystems`} />
          </div>
        </li>
      )}
    </ul>
  );
};

type ServicesEditorProps = {
  name: 'addOns' | 'transportations';
  addButtonLabel?: string;
};
export const ServicesEditor = ({
  name,
  addButtonLabel,
}: ServicesEditorProps) => {
  const editingProduct = useContext(EditingProductContext);
  const isAddOnsEditor = name === 'addOns';

  const formState = useFormState();
  const service = formState.values[name];

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((fieldName, idx) => (
              <CollapsibleBox
                key={fieldName}
                title={`#${idx + 1} ${service[idx].title ?? ''}`}
                onDelete={() => fields.remove(idx)}
              >
                <ServiceInput
                  name={fieldName}
                  editingProduct={editingProduct}
                  isAddOnsEditor={isAddOnsEditor}
                />
              </CollapsibleBox>
            ))}
          <div className={fields.length === 0 ? '' : baseStyles['u-mt-4']}>
            <a
              className={styles['p-box__table2__actions__add']}
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  (fields as any).insertAt(fields.length + 1, '');
                } else {
                  (fields as any).insertAt(
                    0,
                    getDefaultService(editingProduct)
                  );
                }
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {addButtonLabel}
            </a>
          </div>
        </>
      )}
    </FieldArray>
  );
};
