import { BadgeColor } from 'client/components/v3/Common/Badge';

export const getBadgeColorForReservationStatus = (
  status: string
): BadgeColor => {
  switch (status) {
    case 'REQUESTED':
      return 'primary';

    case 'STANDBY':
      return 'warning';

    case 'CONFIRMED':
      return 'success';

    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'CANCELED_BY_SUPPLIER':
      return 'gray';

    case 'DECLINED_BY_SUPPLIER':
    case 'WITHDRAWN_BY_AGENT':
    case 'REJECTED':
      return 'error';

    default:
      return 'gray';
  }
};
