import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import clsx from 'clsx';

import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  title: string;
  id?: string;
  children: JSX.Element | JSX.Element[];
};

export const CollapsibleBox = ({ title, id, children }: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <div id={id} className={styles['p-frame']}>
      <div className={styles['p-frame__header']}>
        <div className={styles['p-frame__header__ttl']}>{title}</div>
        <div className={styles['p-frame__header__actions']}>
          <Button
            text={isActive ? t('Hide') : t('Show')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            iconBeforeText={
              isActive ? (
                <i className="c-icon-outline-general-minus"></i>
              ) : (
                <i className="c-icon-solid-general-plus"></i>
              )
            }
            onClick={() => {
              setIsActive(!isActive);
            }}
          />
        </div>
      </div>

      <div
        className={clsx(
          styles['p-frame__body'],
          isActive && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
