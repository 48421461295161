import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';

import { Button, FieldWrapper, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { sendInquirySMS } from 'client/actions/inquiries';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { InsertMessageTemplateModal } from 'client/pages/Inquiry/InsertMessageTemplateModal/InsertMessageTemplateModal';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import 'react-phone-input-2/lib/style.css';
import { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type FormValues = {
  message: string;
  phone: string;
};
type Props = {
  reservation: Reservation | undefined;
  onClose: () => void;
  open: boolean;
};
export const SendSMSModal = ({ onClose, open, reservation }: Props) => {
  const { t } = useTranslation();
  const [showInsertMessageTemplateModal, setShowInsertMessageTemplateModal] =
    React.useState(false);
  const [step, setStep] = React.useState<'EDIT' | 'CONFIRM'>('EDIT');
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const initialValues = React.useMemo((): FormValues => {
    let reservationPhone =
      reservation?.field_responses?.find((response) => response.key === 'phone')
        ?.response ||
      reservation?.field_responses?.find(
        (response) => response.key === 'international_phone'
      )?.response;

    if (reservationPhone) {
      reservationPhone = `${
        !reservationPhone.startsWith('+') ? '+' : ''
      }${reservationPhone.replace(/^\+0-9/g, '')}`;
    }

    return {
      phone: reservationPhone ?? '',
      message: '',
    };
  }, [reservation]);

  const { required } = getValidators(t);
  const validatePhone = React.useCallback(
    (phone?: any) => {
      if (!phone) return t('Required');

      if (!matchesFormat(phone, 'friendly_phone')) return t('Invalid Phone');

      if (
        ['+81', '+1', '+61'].every(
          (countryCode) => !phone.startsWith(countryCode)
        )
      ) {
        return t('Invalid Phone');
      }

      return undefined;
    },
    [t]
  );

  const org = useSelector(activeUserOrganizationSelector);
  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;

  const dispatch = useDispatch();

  const countryCodeOptions =
    activeUser?.locale === 'ja' ? ['jp', 'us', 'au'] : ['us', 'jp', 'au'];
  return (
    <Modal title={t('Send SMS')} open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          switch (step) {
            case 'EDIT':
              setStep('CONFIRM');
              break;
            case 'CONFIRM':
              await dispatch(
                sendInquirySMS({
                  reservation_id: reservation?.id,
                  phone: values.phone,
                  body: values.message,
                })
              );
              onClose();
              break;
          }
        }}
      >
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Box display="flex" alignItems="flex-end">
                <Field name="phone" validate={validatePhone}>
                  {({ input, meta: { touched, error } }) => (
                    <FieldWrapper
                      label={t('Phone')}
                      required={true}
                      error={touched && error}
                    >
                      <PhoneInput
                        placeholder={
                          locale === 'ja' ? '81 9012345678' : '1 800 123 4567'
                        }
                        masks={{ jp: '...........' }}
                        onlyCountries={countryCodeOptions}
                        localization={
                          locale === 'ja' ? jpPhoneLocalization : undefined
                        }
                        value={input.value?.replace('+', '')}
                        onChange={(
                          value: string,
                          data: { dialCode: string }
                        ) => {
                          if (data && !value?.startsWith(data.dialCode)) {
                            input.onChange(`+${data.dialCode}${value}`);
                          } else if (value && !value?.startsWith('+')) {
                            input.onChange(`+${value}`);
                          } else {
                            input.onChange(value);
                          }
                        }}
                        searchPlaceholder={t('Search')}
                        searchNotFound={t('No Results')}
                        isValid={() => {
                          return !touched || !error;
                        }}
                        enableSearch
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Box>
              {hasMessageTemplates && (
                <Box mt={2}>
                  <Button.Create
                    onClick={() => setShowInsertMessageTemplateModal(true)}
                  >
                    {t('Insert Message Template')}
                  </Button.Create>
                </Box>
              )}
              {showInsertMessageTemplateModal && (
                <InsertMessageTemplateModal
                  onClose={() => setShowInsertMessageTemplateModal(false)}
                  onInsert={(messageTemplate) => {
                    form.change('message', messageTemplate.message);
                  }}
                />
              )}
              <Box mt={2}>
                <Field name="message" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <TextArea
                      label={t('Message')}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                      error={touched && error}
                      showCharacterCount={true}
                    />
                  )}
                </Field>
              </Box>
              <Box>
                <div>
                  {t(
                    '* 1 message cost will be for approximately 60 characters.'
                  )}
                </div>
                <div>
                  {t(
                    '* Long messages may be split into multiple messages and charged for multiple messages'
                  )}
                </div>
                <div>
                  {t(
                    '* When including URLs in messages, we recommend shortening the URLs with a service like '
                  )}
                  <a
                    className={baseStyles['base-link']}
                    href="https://bitly.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bitly.com
                  </a>
                </div>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
              <Button style="blue" size="middle" type="submit">
                {t('Send')}
              </Button>
              {step === 'CONFIRM' && (
                <MessageModal
                  open={true}
                  onClose={() => setStep('EDIT')}
                  title={t('Send SMS')}
                  message={t('Are you sure you wish to send this SMS?')}
                  onSubmit={handleSubmit}
                  insertRoot
                  loading={submitting}
                />
              )}
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
