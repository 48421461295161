// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type { CardPaymentLogItem } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  paymentLog: CardPaymentLogItem[],
};

const getPaymentTypeText = (
  paymentType: 'CHARGE' | 'REFUND',
  t: TranslateFuncType
): string => {
  switch (paymentType) {
    case 'CHARGE':
      return t('Charge');
    case 'REFUND':
    default:
      return t('Refund');
  }
};

export const ReservationCardPaymentLog = ({ paymentLog }: Props) => {
  const { t } = useTranslation();

  if ((paymentLog?.length ?? 0) === 0) {
    return null;
  }

  return (
    <div>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Credit Card Payment History')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(componentStyles['c-table-nowrap'])}>
          <table>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-128'])}>
                  {t('Date/Time')}
                </th>
                <th className={clsx(baseStyles['base-t-128'])}>
                  {t('Charge/Refund')}
                </th>
                <th className={clsx(baseStyles['base-t-128'])}>
                  {t('Amount')}
                </th>
                <th className={clsx(baseStyles['base-t-128'])}>
                  {t('Card Info')}
                </th>
                <th className={clsx(baseStyles['base-t-128'])}>
                  {t('Processing ID')}
                </th>
              </tr>
              {paymentLog?.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {moment(item.date_time_utc).format('YYYY/MM/DD HH:mm')}
                    </td>
                    <td>{item.type ? getPaymentTypeText(item.type, t) : ''}</td>
                    <td>{formattedCurrencyAmount(item.amount ?? '')}</td>
                    <td>
                      <Box display="flex" flexWrap="wrap">
                        <Box mr={2}>{item.card_info?.card_brand ?? ''}</Box>
                        <Box ml={2} mr={2}>
                          xxxx
                          {item.card_info?.last_four_digits}
                        </Box>
                        <Box ml={2} mr={2}>
                          {t('Exp: ')}
                          {item.card_info?.exp_month} /{' '}
                          {(item.card_info?.exp_year ?? 0) % 100}
                        </Box>
                      </Box>
                    </td>
                    <td style={{ wordBreak: 'break-all' }}>
                      {item.card_info?.payment_profile_gateway_reference ?? ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
