import moment from 'moment-timezone';

import * as Swagger from 'shared/models/swagger';

type Session = Exclude<
  Swagger.AnalyticsCustomer['sessions'],
  undefined
>[number];
type Event = Exclude<Session['events'], undefined>[number];

export const formattedDate = (date: string, locale: string) => {
  if (locale === 'ja') {
    return moment(date).locale(locale).format('YYYY年MM月DD日 (ddd)');
  }

  return moment(date).locale(locale).format('MMM DD, YYYY (ddd)');
};

export const getFilteredEvents = (session: Session): Event[] => {
  return (
    session?.events?.filter((event) => {
      if (
        event.event_type === 'reservation-thank-you' ||
        event.event_type === 'check-availability' ||
        event.event_type === 'begin-checkout'
      ) {
        return true;
      }

      if (!event.page_path) {
        return false;
      }

      return true;
    }) ?? []
  );
};

export const getFilteredOnSiteEvents = (session: Session): Event[] => {
  return (
    session?.events?.filter((event) => {
      if (
        event.event_type === 'reservation-thank-you' ||
        event.event_type === 'eticket-view' ||
        event.event_type === 'eticket-redeem' ||
        event.event_type === 'eticket-footprint' ||
        event.event_type === 'website-popup-view' ||
        event.event_type === 'website-popup-click' ||
        event.event_type === 'email-open' ||
        event.event_type === 'email-click' ||
        event.event_type === 'push-notification-view' ||
        event.event_type === 'push-notification-click'
      ) {
        return true;
      }

      if (!event.page_path) {
        return false;
      }

      return true;
    }) ?? []
  );
};

export const formatLandingSource = (source: string) => {
  return source.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
};
