import * as React from 'react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { useSelector } from 'react-redux';

import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { ReservationSummaryGadget } from 'client/pages/v3/FlexibleDashboard/ReservationSummaryGadget/ReservationSummaryGadget';
import { ReservationReportGadget } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportGadget';
import { AccessReportGadget } from 'client/pages/v3/FlexibleDashboard/AccessReportGadget/AccessReportGadget';
import { GadgetEditContext } from 'client/pages/v3/FlexibleDashboard/GadgetEditContext';
import { AccessSummaryGadget } from 'client/pages/v3/FlexibleDashboard/AccessSummaryGadget/AccessSummaryGadget';
import { ReservationListGadget } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListGadget';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';

import styles from './Gadget.module.css';

interface Props {
  gadget: DashboardGadget;
  style?: React.CSSProperties;
  listeners?: SyntheticListenerMap;
  attributes?: DraggableAttributes;
  actions?: React.ReactNode;
  positionInRow?: 'left' | 'right' | 'middle';
}

export const Gadget = React.forwardRef<HTMLDivElement, Props>(
  ({ gadget, style, listeners, attributes, actions, positionInRow }, ref) => {
    const { editModeIsActive } = React.useContext(GadgetEditContext);
    const activeUser = useSelector(activeUserSelector);

    return (
      <div
        ref={ref}
        style={style}
        {...attributes}
        className={styles['p-dashboard']}
      >
        <div className={styles['p-dashboard__header']}>
          {editModeIsActive && (
            <div
              className={styles['p-dashboard__draggable__icon']}
              // Only make gadget draggable by icon
              {...listeners}
            >
              <i></i>
              <i></i>
              <i></i>
            </div>
          )}
          <p className={styles['p-dashboard__ttl']}>{gadget.title}</p>
          {actions && hasCustomUserRoleWritePermissions(activeUser, 'HOME') && (
            <div className={styles['p-dashboard__actions']}>{actions}</div>
          )}
        </div>
        <div className={styles['p-dashboard__body']}>
          {gadget.gadgetType === 'access-report' && (
            <AccessReportGadget
              params={gadget.params}
              positionInRow={positionInRow}
            />
          )}
          {gadget.gadgetType === 'access-summary' && (
            <AccessSummaryGadget params={gadget.params} />
          )}
          {gadget.gadgetType === 'reservation-report' && (
            <ReservationReportGadget
              params={gadget.params}
              positionInRow={positionInRow}
            />
          )}
          {gadget.gadgetType === 'reservation-summary' && (
            <ReservationSummaryGadget gadget={gadget} />
          )}
          {gadget.gadgetType === 'reservation-list' && (
            <ReservationListGadget gadget={gadget} />
          )}
        </div>
      </div>
    );
  }
);
