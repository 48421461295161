import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAILURE,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  UPDATE_PROMOTION_REQUEST,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import type { ReduxState } from 'client/reducers';
import type { NewPromotion, PromotionPatch } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

// Action creators
const getPromotionsRequest = () => ({
  type: GET_PROMOTIONS_REQUEST,
});

const getPromotionsSuccess = (response: any) => ({
  type: GET_PROMOTIONS_SUCCESS,
  response,
});

const getPromotionsFailure = (error: string) => ({
  type: GET_PROMOTIONS_FAILURE,
  error,
});

export const fetchPromotions =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(getPromotionsRequest());
    axios
      .get('/api/promotions', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(getPromotionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPromotionsFailure(err.message));
      });
  };

const createPromotionRequest = (newPromotion: NewPromotion) => ({
  type: CREATE_PROMOTION_REQUEST,
  newPromotion,
});

const createPromotionSuccess = (response: any) => ({
  type: CREATE_PROMOTION_SUCCESS,
  response,
});

const createPromotionFailure = (error: string) => ({
  type: CREATE_PROMOTION_FAILURE,
  error,
});

export const createPromotion =
  (newPromotion: NewPromotion) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createPromotionRequest(newPromotion));
    return axios
      .post('/api/promotions', newPromotion, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createPromotionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createPromotionFailure(err.message));
      });
  };

const updatePromotionRequest = (patch: PromotionPatch) => ({
  type: UPDATE_PROMOTION_REQUEST,
  patch,
});

const updatePromotionSuccess = (response: any) => ({
  type: UPDATE_PROMOTION_SUCCESS,
  response,
});

const updatePromotionFailure = (error: string) => ({
  type: UPDATE_PROMOTION_FAILURE,
  error,
});

export const updatePromotion =
  (id: string, patch: PromotionPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updatePromotionRequest(patch));
    return axios
      .patch(`/api/promotions/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updatePromotionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updatePromotionFailure(err.message));
      });
  };
