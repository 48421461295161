import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { SearchFormValues } from 'client/libraries/util/review';
import { fetchReviews } from 'client/actions/reviews';
import type { Product } from 'shared/models/swagger';

import { ReviewCard } from './ReviewCard';
import { ReviewSearchForm } from './ReviewSearchForm';
import { ReviewsSummary } from './ReviewsSummary';
import { ProductReviewsModal } from './ProductReviewsModal';
import styles from './ProductReviewsPane.module.css';

interface Props {
  product: Product | null;
  contentLanguage: string;
}
export const ProductReviewsPane = ({ product, contentLanguage }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchFormValues, setSearchFormValues] =
    React.useState<SearchFormValues>({
      ratings: [],
      attributions: [],
      monthStart: 1,
      monthEnd: 12,
      language: undefined,
      statuses: [],
      productIds: [],
    });
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const reviews = useSelector((state: ReduxState) => state.reviews.all);

  React.useEffect(() => {
    if (product?.id) {
      const months = _.range(
        searchFormValues.monthStart,
        searchFormValues.monthEnd + 1
      );

      dispatch(
        fetchReviews({
          productIds: [product?.id],
          language: contentLanguage,
          statuses: ['PUBLISHED'],
          ratings: searchFormValues.ratings,
          languagesFilter: searchFormValues.language
            ? ([searchFormValues.language] as any)
            : undefined,
          attributions: searchFormValues.attributions,
          months,
          pageSize: 5,
        })
      );
    }
  }, [product?.id, contentLanguage, searchFormValues]);

  const productIds = React.useMemo(
    () => (product?.id ? [product?.id] : undefined),
    [product?.id]
  );

  if (!product?.review_count) {
    return null;
  }

  return (
    <>
      {showModal && (
        <ProductReviewsModal
          product={product}
          contentLanguage={contentLanguage}
          searchFormValues={searchFormValues}
          onSearchFormValuesChange={setSearchFormValues}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className={styles['c-review']} id="reviews">
        <div className={styles['c-review__summary']}>
          <p className={styles['c-review__ttl']}>{t('Reviews')}</p>
          <ReviewsSummary productIds={productIds} reviewStatus="PUBLISHED" />
          <ReviewSearchForm
            onSearchFormValuesChange={setSearchFormValues}
            searchFormValues={searchFormValues}
          />
        </div>
        {reviews && (
          <div className={styles['c-review__list']}>
            <p className={styles['c-review__ttl']}>{t('Customer Reviews')}</p>
            <ul className={styles['c-review__list__comment']}>
              {reviews.slice(0, 5).map((review, idx) => (
                <ReviewCard key={idx} review={review} />
              ))}
            </ul>
            <a
              className={styles['c-review__list__btn']}
              onClick={() => setShowModal(true)}
            >
              {t('See All Customer Reviews')}
            </a>
          </div>
        )}
      </div>
    </>
  );
};
