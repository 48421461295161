import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Product } from 'shared/models/swagger';

type Props = {
  product: Product;
};

export const ProductTranslationHeader = ({ product }: Props) => {
  const { t } = useTranslation();

  const paths = [
    { text: t('Product List'), url: '/products-v3' },
    {
      text: product.internal_product_name ?? '',
      url: `/products-v3/${product.id}`,
    },
    { text: t('Product Translation'), url: '' },
  ];

  return (
    <PageHeader
      title={t('Product Translation')}
      breadcrumbPaths={paths}
      // TODO: Not sure where to link the settings screen for now, update later
      descriptionWithInnerHTML={t(
        'Set the language you want to translate for each item. You can change the language settings for translation on the <a href="/bookingWidget/general">settings screen</a>.'
      )}
    ></PageHeader>
  );
};
