import { useTranslation } from 'react-i18next';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from 'recharts';
import { useSelector } from 'react-redux';

import { BaseGadget } from 'client/components/v3/DashboardGadgets/BaseGadget';
import { NpsSurveyReportDataSet } from 'shared/models/swagger';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';

import { numberWithCommas, replaceJPYWithSymbol } from '../util';

import styles from './Chart.module.css';
import { AverageConversion, Props, TooltipProps } from './type';

const CustomTooltip = ({ active, payload, label, t, symbol }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className={styles['c-tooltip']}>
          <div className={styles['c-tooltip__header']}>
            <p className={styles['c-tooltip__header__ttl']}>
              {label}
              {t('pt')}
            </p>
          </div>
          <div className={styles['c-tooltip__body']}>
            {payload.map((payload, index) => (
              <p className={styles['c-tooltip__body__item']} key={index}>{`${
                payload.name === 'Base Period'
                  ? t('Base Period', { context: 'nps' })
                  : t(payload.name)
              }: ${numberWithCommas(payload.value, true)}${symbol}`}</p>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
};

export const PromotionMemberLifetimeValueCorrelationChart = ({
  baseDataSet,
  comparisonDataSet,
  loading = false,
}: Props) => {
  const { t } = useTranslation();
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const renderLegendText = (value: string) => {
    return (
      <span style={{ color: '#71717A' }}>{t(value, { context: 'nps' })}</span>
    );
  };

  const constructChartData = (
    baseDataSet: NpsSurveyReportDataSet,
    comparisonDataSet: NpsSurveyReportDataSet | null
  ): AverageConversion[] => {
    const chartData = Array.from({ length: 11 }, (_, i) => ({
      name: i.toString(),
      'Base Period': 0,
      'Comparison Period': 0,
    }));
    const baseScoreCounts = Array.from({ length: 11 }, () => 0);
    const comparisonScoreCounts = Array.from({ length: 11 }, () => 0);

    const processDataSet = (
      dataSet: NpsSurveyReportDataSet | null,
      scoreCount: number[],
      period: 'Base Period' | 'Comparison Period'
    ) => {
      if (dataSet && dataSet.items_by_date) {
        dataSet.items_by_date.forEach((item) => {
          item.results?.forEach((result) => {
            if (result.nps_score) {
              const npsScore = parseInt(result.nps_score, 10);
              let totalGrossSales = 0;
              if (result.total_gross_sales) {
                scoreCount[npsScore]++;
                totalGrossSales = parseInt(
                  result.total_gross_sales.substring(3),
                  10
                );
              }
              chartData[npsScore][period] += totalGrossSales;
            }
          });
        });
      }
    };

    processDataSet(baseDataSet, baseScoreCounts, 'Base Period');
    processDataSet(
      comparisonDataSet,
      comparisonScoreCounts,
      'Comparison Period'
    );

    chartData.forEach((data, index) => {
      if (baseScoreCounts[index] > 0) {
        data['Base Period'] /= baseScoreCounts[index];
      }
      if (comparisonScoreCounts[index] > 0) {
        data['Comparison Period'] /= comparisonScoreCounts[index];
      }
    });

    return chartData;
  };

  const chartData = constructChartData(baseDataSet, comparisonDataSet);

  return (
    <BaseGadget
      header={t('Promotion - Member LTV Correlation')}
      loading={loading}
    >
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={500}
          height={250}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis domain={[0, 10]} unit={t('pt')} tickLine={false} />
          <YAxis
            tickLine={false}
            tickFormatter={(value) => {
              return numberWithCommas(value);
            }}
            unit={replaceJPYWithSymbol(defaultSupplierCurrency)}
          />
          <Tooltip
            content={
              <CustomTooltip
                t={t}
                symbol={replaceJPYWithSymbol(defaultSupplierCurrency)}
              />
            }
            cursor={{ fill: 'transparent' }}
          />
          <Legend align="center" formatter={renderLegendText} />
          <Line
            type="linear"
            dataKey="Base Period"
            stroke="#3B82F6"
            activeDot={{
              r: 6,
            }}
            strokeWidth={2}
            dot={{ fill: '#3B82F6', r: 4 }}
          />
          {comparisonDataSet && (
            <Line
              type="linear"
              dataKey="Comparison Period"
              stroke="#BFDBFE"
              activeDot={{
                r: 6,
              }}
              strokeWidth={2}
              dot={{ fill: '#BFDBFE', r: 4 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </BaseGadget>
  );
};
