import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  ADD_SUBSCRIPTION_REQUEST,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import type { ReduxState } from 'client/reducers';
import type {
  Organization,
  SourceLanguage,
  SubscriptionKey,
  SubscriptionInterval,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

type SubscriptionParams = {
  key: SubscriptionKey;
  interval: SubscriptionInterval;
  pmpSubdomain?: string;
  pmpLanguage?: SourceLanguage;
  isManualSubscription?: boolean;
};

const addSubscriptionRequest = (params: SubscriptionParams) => ({
  type: ADD_SUBSCRIPTION_REQUEST,
  params,
});

const addSubscriptionSuccess = (organization: Organization) => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  organization,
});

const addSubscriptionFailure = (error: string) => ({
  type: ADD_SUBSCRIPTION_FAILURE,
  error,
});

export const addSubscription =
  (params: SubscriptionParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(addSubscriptionRequest(params));
    return axios
      .post(
        `/api/organizations/subscriptions`,
        {
          subscription_key: params.key,
          subscription_interval: params.interval,
          private_marketplace_subdomain: params.pmpSubdomain,
          private_marketplace_language: params.pmpLanguage,
          is_manual_subscription: params.isManualSubscription,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(addSubscriptionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(addSubscriptionFailure(err.message));
      });
  };

const cancelSubscriptionRequest = (subscriptionId: string) => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
  subscriptionId,
});

const cancelSubscriptionSuccess = (organization: Organization) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  organization,
});

const cancelSubscriptionFailure = (error: string) => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  error,
});

export const cancelSubscription =
  (subscriptionId: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(cancelSubscriptionRequest(subscriptionId));
    return axios
      .post(`/api/organizations/subscriptions/${subscriptionId}/cancel`, null, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(cancelSubscriptionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(cancelSubscriptionFailure(err.message));
      });
  };
