import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Modal } from 'client/components/v3/Form/Modal';
import { getValidators } from 'shared/libraries/validate/validator';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';

import styles from './MenuItemEditor.module.css';

interface Props {
  onClose: () => void;
  onSave: () => void;
  name: string;
}

export const TagModal = ({ onClose, onSave, name }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  return (
    <Modal
      title={t('New Tag')}
      onClose={onClose}
      open={true}
      rightActionChildren={
        <>
          <Button
            color="white"
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
          />
          <Button text={t('Save')} type="button" onClick={() => onSave()} />
        </>
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name={name} validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Tag')}
                onChange={(newValue) => {
                  input.onChange(newValue);
                }}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
    </Modal>
  );
};
