import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction } from 'react';

import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { setReservationVisibleColumns } from 'client/actions/reservationTableControls';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import {
  reservationColumnCandidatesSelector,
  reservationVisibleColumnsSelector,
} from 'client/reducers/reservationTableControls';
import { Modal } from 'client/components/v3/Form/Modal';
import type { ReduxState } from 'client/reducers';
import type {
  ColumnType,
  ReservationListColumn,
} from 'client/libraries/util/getReservationTableColumns';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const ColumnSettingsModal = ({ setShowModal }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allColumns = getReservationTableColumns(t, 'en');

  const columnCandidates = useSelector((state: ReduxState) =>
    reservationColumnCandidatesSelector(state)
  );
  const visibleColumns = useSelector((state: ReduxState) =>
    reservationVisibleColumnsSelector(state)
  );

  const getColumns = (visibleColumns: string[]): ColumnType[] => {
    // Filter out agent_reference because we show it separately in bulk cancel column
    return visibleColumns
      .filter((visibleCol) => visibleCol !== 'agent_reference')
      .map(
        (visibleCol) =>
          allColumns.find(
            (col) => col.id === visibleCol
          ) as ReservationListColumn
      );
  };

  const columnsToShow = getColumns(visibleColumns);
  const allCandidateColumns = getColumns(columnCandidates);

  return (
    <Modal
      title={t('Edit Columns')}
      subtitle={t(
        'You can customize which columns to show and the display order.'
      )}
      open={true}
      onClose={() => setShowModal(false)}
      useCloseButton={true}
      style={{ width: '600px', height: '600px' }}
    >
      <ToggleableDragAndDropList
        selectedItems={columnsToShow.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        candidateItems={allCandidateColumns.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        setSelectedItems={(items) =>
          dispatch(setReservationVisibleColumns(items.map((i) => i.key)))
        }
        doNotUseOuterBox={true}
      />
    </Modal>
  );
};
