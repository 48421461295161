import { combineReducers } from 'redux';

import {
  FETCH_WAIVERS_REQUEST,
  FETCH_WAIVERS_SUCCESS,
  FETCH_WAIVERS_FAILURE,
  DOWNLOAD_WAIVER_PDF_REQUEST,
  DOWNLOAD_WAIVER_PDF_SUCCESS,
  DOWNLOAD_WAIVER_PDF_FAILURE,
  FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST,
  FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS,
  FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE,
  CLEAR_PARTICIPANT_WAIVER_REQUEST,
  CLEAR_PARTICIPANT_WAIVER_SUCCESS,
  CLEAR_PARTICIPANT_WAIVER_FAILURE,
} from 'client/constants/ActionTypes';
import type { ListWaiversResponse, Waiver } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_WAIVERS_REQUEST:
    case FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST:
    case CLEAR_PARTICIPANT_WAIVER_REQUEST:
      return true;

    case FETCH_WAIVERS_SUCCESS:
    case FETCH_WAIVERS_FAILURE:
    case FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS:
    case FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE:
    case CLEAR_PARTICIPANT_WAIVER_SUCCESS:
    case CLEAR_PARTICIPANT_WAIVER_FAILURE:
      return false;

    default:
      return state;
  }
};

const totalHits = (state = 0, action: any) => {
  switch (action.type) {
    case FETCH_WAIVERS_SUCCESS: {
      const resp = action.response as ListWaiversResponse;
      return resp.total ?? 0;
    }
    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_WAIVERS_FAILURE:
    case FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE:
      return action.error;

    case FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST:
    case FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const all = (state: Waiver[] = [], action: any): Waiver[] => {
  switch (action.type) {
    case FETCH_WAIVERS_SUCCESS: {
      const resp = action.response as ListWaiversResponse;
      return resp.waivers;
    }
    case FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS: {
      const newState: Waiver[] = [];
      for (const response of action.responses) {
        if (response.waivers) {
          newState.push(...response.waivers);
        }
      }
      return newState;
    }

    default:
      return state;
  }
};

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_WAIVER_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_WAIVER_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_WAIVER_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

interface State {
  all: Waiver[];
  loading: boolean;
  totalHits: number;
  error: string;
  pdfStatus: string;
}

export const waivers = combineReducers<State>({
  all,
  loading,
  totalHits,
  error,
  pdfStatus,
});
