import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';

import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { FieldWrapper } from 'client/components/Form';

import { getDefaultWaiverItem } from './formValues';
import { ConditionalEditor } from './ConditionalEditor';
import { TextEditor } from './TextEditor';
import { FieldEditor } from './FieldEditor';
import { FileUploadEditor } from './FileUploadEditor';
import styles from './WaiverTemplateList.module.css';

type Props = {
  name: string;
  showConditional?: boolean;
};

export const ItemsEditor = ({ name, showConditional }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const typeOptions = [
    {
      value: 'TEXT',
      label: t('Text'),
    },
    {
      value: 'SIGNATURE',
      label: t('Signature Box'),
    },
    {
      value: 'FIELD',
      label: t('Input Field'),
    },
    {
      value: 'FILE_UPLOAD',
      label: t('Image/PDF Upload'),
    },
  ];

  if (showConditional) {
    typeOptions.push({
      value: 'CONDITIONAL',
      label: t('Conditional'),
    });
  }

  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return fields.length === 0 ? (
          <AddIcon
            onClick={() => (fields as any).insertAt(0, getDefaultWaiverItem())}
          />
        ) : (
          <div className={styles['c-table-list']}>
            <table>
              <tbody>
                {fields.map((name, idx) => (
                  <tr key={idx}>
                    <td>
                      <FieldWrapper label={t('Item Type')}>
                        <EnumRadioButtonGroup
                          name={`${name}.type`}
                          options={typeOptions}
                          onChange={(newValue) => {
                            if (newValue === 'FIELD') {
                              form.change(`${name}.format`, '');
                              form.change(
                                `${name}.inputType`,
                                'SHORT_TEXT_INPUT'
                              );
                            }
                          }}
                        />
                      </FieldWrapper>

                      {fields.value?.[idx]?.type === 'TEXT' && (
                        <TextEditor name={name} />
                      )}
                      {fields.value?.[idx]?.type === 'FIELD' && (
                        <FieldEditor name={name} />
                      )}
                      {fields.value?.[idx]?.type === 'FILE_UPLOAD' && (
                        <FileUploadEditor name={name} />
                      )}
                      {fields.value?.[idx]?.type === 'CONDITIONAL' && (
                        <ConditionalEditor name={name} />
                      )}
                    </td>
                    <td className={styles['c-table-list__btns']}>
                      <AddIcon
                        onClick={() =>
                          (fields as any).insertAt(
                            idx + 1,
                            getDefaultWaiverItem()
                          )
                        }
                      />
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    </FieldArray>
  );
};
