import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  MarketingAutomationTriggerCondition,
  CreateMarketingAutomationTriggerConditionResponse,
  ListMarketingAutomationTriggerConditionsResponse,
  UpdateMarketingAutomationTriggerConditionResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
      return action.error;

    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST:
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST:
    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST:
    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST:
      return true;

    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS:
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: MarketingAutomationTriggerCondition[] = [],
  action: any
): MarketingAutomationTriggerCondition[] => {
  switch (action.type) {
    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS: {
      const response =
        action.response as ListMarketingAutomationTriggerConditionsResponse;
      return response.marketing_automation_trigger_conditions;
    }

    case FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS: {
      const response = action.response as MarketingAutomationTriggerCondition;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS: {
      const response =
        action.response as CreateMarketingAutomationTriggerConditionResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS: {
      const response =
        action.response as UpdateMarketingAutomationTriggerConditionResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedTriggerCondition = (
  state: MarketingAutomationTriggerCondition | null = null,
  action: any
) => {
  switch (action.type) {
    case CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS: {
      const response =
        action.response as CreateMarketingAutomationTriggerConditionResponse;
      return response;
    }

    default:
      return state;
  }
};

export const marketingAutomationTriggerConditions = combineReducers({
  error,
  loading,
  all,
  lastCreatedTriggerCondition,
});
