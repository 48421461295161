import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import type { Reservation } from 'shared/models/swagger';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { updateReservation } from 'client/actions/reservations';
import { Button } from 'client/components/v3/Common/Button';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper/FieldWrapper';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextArea } from 'client/components/v3/Form/TextArea';

import styles from './EditFixedExpirationDateTimeModal.module.css';

type Props = {
  onClose: () => void;
  reservation: Reservation;
};

export const EditFixedExpirationDateTimeModal = ({
  onClose,
  reservation,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  const currentExpirationDateTime = moment.tz(
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
      reservation?.checkin_info?.expiration_date_time_utc,
    activeUserOrganization?.default_timezone || 'UTC'
  );

  const [patch, setPatch] = useState({
    agent_notes: reservation.agent_notes,
    supplier_notes: reservation.supplier_notes,
    supplier_internal_notes: reservation.supplier_internal_notes,
    supplier_internal_notes_for_dispatch:
      reservation.supplier_internal_notes_for_dispatch,
    checkin_fixed_expiration_date_time_utc: {
      value:
        reservation?.checkin_info?.fixed_expiration_date_time_utc ||
        reservation?.checkin_info?.expiration_date_time_utc ||
        moment().utc().format(),
    },
  });

  useEffect(() => {
    setPatch({
      agent_notes: reservation.agent_notes,
      supplier_notes: reservation.supplier_notes,
      supplier_internal_notes: reservation.supplier_internal_notes,
      supplier_internal_notes_for_dispatch:
        reservation.supplier_internal_notes_for_dispatch,
      checkin_fixed_expiration_date_time_utc: {
        value:
          reservation?.checkin_info?.fixed_expiration_date_time_utc ||
          reservation?.checkin_info?.expiration_date_time_utc ||
          moment().utc().format(),
      },
    });
  }, [reservation]);

  const currentStatus = getCurrentStatus(reservation);

  const userCanEditAgentNotes =
    operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditSupplierNotes = operationAllowed(
    activeUser,
    'write',
    'reservationSupplierNotes'
  );

  return (
    <Modal
      open={true}
      title={t('Update')}
      onClose={() => {
        onClose();
      }}
      rightActionChildren={
        <>
          <Button
            color="white"
            text={t('Discard')}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            text={t('Save')}
            onClick={() => {
              dispatch(updateReservation(reservation.id, patch));
              onClose();
            }}
          />
        </>
      }
      style={{ width: '568px', height: '600px' }}
    >
      <div className={styles['c-editFixedExpirationDateTime']}>
        <FieldWrapper label={t('Current expiration date')}>
          {currentExpirationDateTime.format('YYYY-MM-DD HH:mm')}
        </FieldWrapper>
        <FieldWrapper label={t('Expiration date after the change')}>
          <div
            className={clsx(
              styles['c-editFixedExpirationDateTime__flex'],
              styles['row']
            )}
          >
            <div style={{ width: '60%' }}>
              <DateTimeTZInput
                value={moment.tz(
                  patch['checkin_fixed_expiration_date_time_utc'].value,
                  activeUserOrganization?.default_timezone || 'UTC'
                )}
                onChange={(date) => {
                  const newDate = moment
                    .tz(
                      patch['checkin_fixed_expiration_date_time_utc'].value,
                      activeUserOrganization?.default_timezone || 'UTC'
                    )
                    .clone()
                    .set('year', date.year())
                    .set('month', date.month())
                    .set('date', date.date());

                  setPatch({
                    ...patch,
                    checkin_fixed_expiration_date_time_utc: {
                      value: newDate.utc().format(),
                    },
                  });
                }}
                locale={i18n.language}
              />
            </div>

            <div style={{ width: '40%' }}>
              <TimePicker
                allowEmpty={false}
                value={moment.tz(
                  patch['checkin_fixed_expiration_date_time_utc'].value,
                  activeUserOrganization?.default_timezone || 'UTC'
                )}
                onChange={(time) => {
                  if (!time) {
                    return;
                  }
                  const newDate = moment
                    .tz(
                      patch['checkin_fixed_expiration_date_time_utc'].value,
                      activeUserOrganization?.default_timezone || 'UTC'
                    )
                    .clone()
                    .set('hour', time.hour())
                    .set('minute', time.minute());
                  setPatch({
                    ...patch,
                    checkin_fixed_expiration_date_time_utc: {
                      value: newDate.utc().format(),
                    },
                  });
                }}
              />
            </div>
          </div>
        </FieldWrapper>

        {userCanEditAgentNotes && (
          <FieldWrapper label={t('Remarks')}>
            <TextArea
              value={patch['agent_notes'] ?? ''}
              onChange={(_, { value }) => {
                setPatch({
                  ...patch,
                  agent_notes: value,
                });
              }}
              height={100}
            />
          </FieldWrapper>
        )}

        {userCanEditSupplierNotes && (
          <>
            <FieldWrapper label={t('Replies')}>
              <TextArea
                value={patch['supplier_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_notes: value,
                  });
                }}
                height={100}
              />
            </FieldWrapper>

            <FieldWrapper label={t('Memo')}>
              <TextArea
                value={patch['supplier_internal_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes: value,
                  });
                }}
                height={100}
              />
            </FieldWrapper>

            <FieldWrapper label={t('Internal Note')}>
              <TextArea
                value={patch['supplier_internal_notes_for_dispatch'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes_for_dispatch: value,
                  });
                }}
                height={100}
              />
            </FieldWrapper>
          </>
        )}
      </div>
    </Modal>
  );
};
