import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { deleteWaiverTemplate } from 'client/actions/waiverTemplates';
import type { WaiverTemplate } from 'shared/models/swagger';

type Props = {
  waiverTemplate: WaiverTemplate;
  onClose: () => void;
};
export const DeleteWaiverTemplateModal = ({
  waiverTemplate,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return waiverTemplate ? (
    <Modal
      title={t('Delete waiver template')}
      open={true}
      onClose={onClose}
      insertRoot
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{productName}}"?', {
          productName: waiverTemplate.title,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        <Button.Caution
          onClick={() => {
            dispatch(deleteWaiverTemplate(waiverTemplate.id ?? ''));
            onClose();
          }}
        >
          {t('OK')}
        </Button.Caution>
      </Modal.Actions>
    </Modal>
  ) : null;
};
