// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { fetchSourceLanguageProductById } from 'client/actions/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/pages/Loading/Loading';
import iconTutorialStep2 from 'client/images/ic_tutorial_step2.svg';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/Home/Tutorial/ProductEditTutorial/ProductEditTutorial.module.css';
import type { ReduxState } from 'client/reducers';

export const ProductRegistrationCompleteStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const tutorialProductId = activeUserOrganization?.tutorial_product_id || '';

  const tutorialProduct = useSelector(
    (state: ReduxState) => state.products.byID[tutorialProductId]
  );

  React.useEffect(() => {
    if (tutorialProductId) {
      dispatch(fetchSourceLanguageProductById(tutorialProductId));
    }
  }, [tutorialProductId]);

  if (!tutorialProduct) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['base-main__body']}>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Product Registration Complete')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <p className={styles['tutorial__ttl']}>
            {t('Your first product was registered.')}
          </p>

          <div className={styles['c-table-list']}>
            <table>
              <tr>
                <th>{t('Product Code')}</th>
                <td>{tutorialProduct.supplier_reference}</td>
              </tr>
              <tr>
                <th>{t('Product Name')}</th>
                <td>{tutorialProduct.product_name}</td>
              </tr>
              <tr>
                <th>{t('Product Description')}</th>
                <td className="newline">{tutorialProduct.description}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div
        className={clsx(
          styles['tutorial__box'],
          styles['tutorial__box--step2'],
          styles['is-active']
        )}
        data-num="2"
      >
        <div className={styles['tutorial__box__left']}>
          <p className={styles['tutorial__box__left__next']}>NEXT STEP</p>
          <p className={styles['tutorial__box__left__desc']}>
            {t('See how it appears on your website.')}
            <br />
            {t("Let's see if we can make a reservation.")}
          </p>
        </div>
        <div className={styles['tutorial__box__right']}>
          <div className={styles['tutorial__box__inner']}>
            <div className={styles['tutorial__box__pic']}>
              <img src={iconTutorialStep2} />
            </div>
            <div className={styles['tutorial__box__body']}>
              <p className={styles['tutorial__box__body__ttl']}>
                {t('Test Booking')}
              </p>
              <div className={styles['tutorial__box__body__btn']}>
                <Link to="/home/tutorial/reservation">
                  <a
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['small'],
                      baseStyles['yellow']
                    )}
                  >
                    {t('Check Website')}
                  </a>
                </Link>
              </div>
              <p className={styles['tutorial__box__body__next']}>NEXT</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
