import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { updateDispatchMiscResources } from 'client/actions/dispatchSettings';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import type { DispatchResource } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  resourceToDelete: DispatchResource | null;
  open: boolean;
  onClose: () => void;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
const DeleteResourceModalComponent = ({
  allResources,
  resourceToDelete,
  updateResources,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return resourceToDelete ? (
    <Modal title={t('Delete Resource')} open={open} onClose={onClose}>
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: resourceToDelete.key,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>

        <Button.Delete
          onClick={() => {
            const newResources = allResources.filter(
              (resource) => resource.key !== resourceToDelete.key
            );
            updateResources(newResources);
            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  allResources: allDispatchMiscResourcesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateResources: (resources: DispatchResource[]) =>
    dispatch(updateDispatchMiscResources(resources)),
});

export const DeleteResourceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteResourceModalComponent);
