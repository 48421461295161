import * as React from 'react';
import ColorPicker from 'rc-color-picker';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { FieldWrapper } from 'client/components/Form';

import { FontPicker } from './FontPicker';

export interface Props {
  name: string;
  expanderLabel: string;
}

export const TextStyleEditor = ({ name, expanderLabel }: Props) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box mt={2} mb={2}>
      <a
        className={baseStyles['base-link']}
        onClick={() => setExpanded(!expanded)}
      >
        {`${expanderLabel} ${expanded ? '▲' : '▼'}`}
      </a>
      {expanded && (
        <Box display="flex">
          <Box width="200px">
            <Field name={`${name}.fontFamily`}>
              {({ input }) => (
                <FontPicker
                  label={t('Font')}
                  value={input.value}
                  onChange={(newValue) => input.onChange(newValue)}
                />
              )}
            </Field>
          </Box>
          <Box ml={2}>
            <FieldWrapper label={t('Color')}>
              <Field name={`${name}.color`}>
                {({ input }) => (
                  <ColorPicker
                    defaultColor="#000000"
                    color={input.value}
                    onChange={({ color }: any) => input.onChange(color)}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
        </Box>
      )}
    </Box>
  );
};
