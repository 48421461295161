import type { TFunction } from 'react-i18next';

import type { Organization } from 'shared/models/swagger';

export const getPOBPaymentText = (
  settings: Organization['supplier_guest_payment_settings'],
  t: TFunction
): string => {
  const pobPaymentMethods =
    settings && settings.accepted_guest_pob_payment_methods;

  if (!pobPaymentMethods || pobPaymentMethods.length === 0) {
    return '';
  }

  let creditProcessingFee = '';
  const cardPaymentMethod = pobPaymentMethods.find(
    (m) => m.payment_method === 'CREDIT_CARD'
  );

  if (cardPaymentMethod) {
    if (cardPaymentMethod.fee_fixed) {
      creditProcessingFee = cardPaymentMethod.fee_fixed;
    } else if (cardPaymentMethod.fee_percent) {
      creditProcessingFee = `${cardPaymentMethod.fee_percent}%`;
    }
  }

  const allowsCreditPOB = Boolean(cardPaymentMethod);
  const allowsCashPOB = Boolean(
    pobPaymentMethods.find((m) => m.payment_method === 'CASH')
  );
  const allowsBankPOB = Boolean(
    pobPaymentMethods.find((m) => m.payment_method === 'BANK')
  );

  let returnText = '';
  if (!allowsCashPOB && !allowsCreditPOB && allowsBankPOB) {
    returnText = t(
      'Payment by bank transfer prior to the participation will be accepted. Details for bank transfer will be notified later.'
    );
  } else if (!allowsCashPOB && allowsCreditPOB && !allowsBankPOB) {
    returnText = t('Only credit card payment will be accepted on arrival.');
  } else if (!allowsCashPOB && allowsCreditPOB && allowsBankPOB) {
    returnText = t(
      'Payment by bank transfer prior to the participation or credit card payment will be accepted on arrival. Details for bank transfer will be notified later.'
    );
  } else if (allowsCashPOB && !allowsCreditPOB && !allowsBankPOB) {
    returnText = t('Cash-only payment will be accepted on arrival.');
  } else if (allowsCashPOB && !allowsCreditPOB && allowsBankPOB) {
    returnText = t(
      'Payment by bank transfer prior to the participation or cash-only payment will be accepted on arrival. Details for bank transfer will be notified later.'
    );
  } else if (allowsCashPOB && allowsCreditPOB && !allowsBankPOB) {
    returnText = t('Cash or credit card payment will be accepted on arrival.');
  } else {
    returnText = t(
      'Payment by bank transfer prior to the participation or cash or credit card payment will be accepted on arrival. Details for bank transfer will be notified later.'
    );
  }

  if (creditProcessingFee) {
    returnText += t(
      'When paying by credit card, a {{creditProcessingFee}} processing fee will apply.',
      { creditProcessingFee }
    );
  }

  return returnText;
};
