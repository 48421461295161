// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { histogram } from 'client/libraries/util/util';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { activeUserSelector } from 'client/reducers/user';
import {
  fetchReservationByID,
  checkinReservation,
} from 'client/actions/reservations';
import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import styles from './CheckinReservation.module.css';
import { CheckinReservationForm } from './CheckinReservationForm';

type FormValues = {
  guestCount: number,
  packageComponentReservationId: string,
  email: string,
  stubKey: string,
};

const getGuestDescription = (reservation: Reservation): string => {
  const reservationGuestCounts = histogram(
    reservation.guests,
    (g) => g.guest_type_title || g.guest_type_key
  );
  return Object.keys(reservationGuestCounts)
    .map((guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`)
    .join(', ');
};

export const CheckinReservation = () => {
  const { t } = useTranslation();

  const [succeeded, setSucceeded] = React.useState<boolean>(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    if (typeof id === 'string') {
      dispatch(fetchReservationByID(id));
    }
  }, [id, t, activeUser]);
  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[id || '']
  );

  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const loading = reservationLoading;
  const reservationEmail = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  const remainingGuestsToCheckin = (reservation?.guests ?? []).length;

  if (reservation != null && reservation.status !== 'CONFIRMED') {
    return (
      <div className={baseStyles['base-main__body__box']}>
        <Box mt={4} mb={4} ml={2} className={styles['err']}>
          {t('Checkin not available for the scanned QR code.')}
        </Box>
      </div>
    );
  }

  const stubs = reservation?.checkin_info?.stubs ?? [];

  return (
    <>
      {loading && <Loading />}
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Process checkin')}
          </div>
        </div>
        {!loading && (
          <div className={baseStyles['base-main__body__box__body']}>
            <Form
              initialValues={{
                guestCount: remainingGuestsToCheckin,
                email: reservationEmail,
                packageComponentReservationId: '',
                stubKey: stubs.length > 0 ? stubs[0].key : '',
              }}
              onSubmit={async (values: FormValues) => {
                await dispatch(
                  checkinReservation(id ?? '', {
                    email: values.email,
                    guest_count: Number(values.guestCount),
                    package_component_reservation_id:
                      values.packageComponentReservationId,
                    stub_key: values.stubKey,
                  })
                );
                setSucceeded(true);
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <CheckinReservationForm />
                  {succeeded && (
                    <Message success header={t('Checkin Completed')} />
                  )}
                </form>
              )}
            </Form>
            <Box mt={4}>
              <Box mb={2}>
                <div
                  className={baseStyles['base-main__body__box__header__ttl']}
                >
                  {t('Reservation info')}
                </div>
              </Box>
              <div className={pageStyles['page-reservations__detailFrame']}>
                <FormTableBox>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Application Number')}</th>
                        <td>{reservation?.agent_reference}</td>
                      </tr>

                      <tr>
                        <th>{t('Confirmation Number')}</th>
                        <td>{reservation?.supplier_reference}</td>
                      </tr>

                      <tr>
                        <th>{t('Participation Date')}</th>
                        <td>
                          {reservation
                            ? moment
                                .tz(
                                  reservation.start_date_time_utc,
                                  reservation.start_timezone
                                )
                                .format('lll')
                            : ''}
                        </td>
                      </tr>

                      <tr>
                        <th>{t('Customer')}</th>
                        <td>{reservation ? getGuestName(reservation) : ''}</td>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>
                <FormTableBox style={{ marginTop: 0 }}>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Product Name')}</th>
                        <td>{reservation?.product_name}</td>
                      </tr>

                      <tr>
                        <th>{t('Units')}</th>
                        <td>
                          {reservation ? getGuestDescription(reservation) : ''}
                        </td>
                      </tr>

                      <tr>
                        <th>{t('Total Pax')}</th>
                        <td>
                          {reservation
                            ? (reservation?.guests ?? []).length
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>
              </div>
            </Box>
          </div>
        )}
      </div>
    </>
  );
};
