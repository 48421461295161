export const formattedAmount = (
  amount: number | string | null,
  currencyCode: string | null
): string => {
  return Number(amount)
    .toLocaleString(undefined, {
      currency: currencyCode || 'USD',
      style: 'currency',
      currencyDisplay: 'code',
    })
    .slice(4);
};
