import { Icon } from 'client/components/Icons/Icon';
import deleteIcon from 'client/images/ic_delete.svg';

type Props = {
  onClick?: () => void;
  className?: string;
};
export const Delete = (props: Props) => {
  return <Icon {...props} src={deleteIcon} />;
};
