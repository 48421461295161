import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { Message } from 'client/components/Message/Message';
import { Loading } from 'client/pages/Loading';
import {
  uploadJaranReservationCsv,
  fetchJaranImportReservationOrders,
} from 'client/actions/jaranReservationImportOrders';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { getImportTabCustumTableColumns } from 'client/pages/JaranImport/util';
import { ImportResultModal } from 'client/pages/JaranImport/ImportResultModal';
import baseStyles from 'client/base.module.css';

export const ImportTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const orders = useSelector(
    (state: ReduxState) => state.jaranReservationImportOrders.all
  );
  const uploadLoading = useSelector(
    (state: ReduxState) => state.jaranReservationImportOrders.uploadLoading
  );
  const loading = useSelector(
    (state: ReduxState) => state.jaranReservationImportOrders.loading
  );

  const csvInputRef = React.useRef<HTMLInputElement | null>(null);
  const [importSuccess, setImportSuccess] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [resultTargetOrderId, setResultTargetOrderId] =
    React.useState<string>('');

  const timeZone = activeUserOrganization?.default_timezone ?? 'UTC';
  const columns = getImportTabCustumTableColumns(
    t,
    locale,
    timeZone,
    setShowModal,
    setResultTargetOrderId
  );

  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(
        fetchJaranImportReservationOrders(activeUserOrganization?.id || '')
      );
    }
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const importSuccessFlagTimer = setTimeout(() => {
      setImportSuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(importSuccessFlagTimer);
    };
  }, [importSuccess]);

  const handleAddFileClick = () => {
    if (csvInputRef?.current) {
      csvInputRef?.current.click();
    }
  };

  const handleCsvInputChange = (e: any) => {
    if (e.target.files) {
      uploadFiles(e.target.files).then(async () => {
        await dispatch(
          fetchJaranImportReservationOrders(activeUserOrganization?.id || '')
        );
        setImportSuccess(true);
      });
      //e.target.value = '';
    }
  };

  const fetchAsText = (file: File) => {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = (e: any) => {
        resolve(e.currentTarget.result);
      };
      fr.readAsText(file);
    });
  };

  const uploadFiles = async (files: File[]) => {
    try {
      const text = await fetchAsText(files[0]);
      await dispatch(
        uploadJaranReservationCsv({
          fileData: {
            content: text as string,
            name: files[0].name,
          },
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {importSuccess && (
        <Message
          success
          header={t(
            'Successfully uploaded file. Please wait until import process is completed.'
          )}
        />
      )}
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'CHANNEL_MANAGER.JARAN_IMPORT'
        ) && (
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['small'],
                baseStyles['flex'],
                baseStyles['green'],
                uploadLoading && baseStyles['loading']
              )}
              onClick={() => {
                handleAddFileClick();
              }}
            >
              <input
                id="csv_input"
                accept="text/csv"
                type="file"
                name="csv"
                ref={csvInputRef}
                style={{ display: 'none' }}
                onChange={handleCsvInputChange}
              />
              {t('Import Jalan Reservations')}
            </a>
          </div>
        )}
      </div>
      <CustomTable items={orders} columns={columns} usePaging={true} />
      {showModal && (
        <ImportResultModal
          onClose={() => setShowModal(false)}
          order={orders.find((o) => o.id === resultTargetOrderId)}
        />
      )}
    </>
  );
};
