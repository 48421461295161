import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_GUIDANCE_LOCATIONS_SUCCESS,
  FETCH_GUIDANCE_LOCATIONS_FAILURE,
  FETCH_GUIDANCE_LOCATIONS_REQUEST,
  DELETE_GUIDANCE_LOCATION_FAILURE,
  DELETE_GUIDANCE_LOCATION_REQUEST,
  DELETE_GUIDANCE_LOCATION_SUCCESS,
  CREATE_GUIDANCE_LOCATION_REQUEST,
  UPDATE_GUIDANCE_LOCATION_FAILURE,
  UPDATE_GUIDANCE_LOCATION_SUCCESS,
  UPDATE_GUIDANCE_LOCATION_REQUEST,
  CREATE_GUIDANCE_LOCATION_FAILURE,
  CREATE_GUIDANCE_LOCATION_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { GuidanceLocationParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGuidanceLocationsRequest = () => ({
  type: FETCH_GUIDANCE_LOCATIONS_REQUEST,
});

const fetchGuidanceLocationsSuccess = (response: any) => ({
  type: FETCH_GUIDANCE_LOCATIONS_SUCCESS,
  response,
});

const fetchGuidanceLocationsFailure = (error: string) => ({
  type: FETCH_GUIDANCE_LOCATIONS_FAILURE,
  error,
});

let fetchGuidanceLocationsCancel: () => void | null;
export const fetchGuidanceLocations =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGuidanceLocationsCancel && fetchGuidanceLocationsCancel();
    dispatch(fetchGuidanceLocationsRequest());
    axios
      .get('/api/guidancelocations', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGuidanceLocationsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchGuidanceLocationsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGuidanceLocationsFailure('canceled'));
        else dispatch(fetchGuidanceLocationsFailure(error));
      });
  };

const deleteGuidanceLocationRequest = (id: string) => ({
  type: DELETE_GUIDANCE_LOCATION_REQUEST,
  id,
});
const deleteGuidanceLocationSuccess = (response: any, id: string) => ({
  type: DELETE_GUIDANCE_LOCATION_SUCCESS,
  response,
  id,
});
const deleteGuidanceLocationFailure = (error: string) => ({
  type: DELETE_GUIDANCE_LOCATION_FAILURE,
  error,
});
export const deleteGuidanceLocation =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGuidanceLocationRequest(id));
    axios
      .delete(`/api/guidancelocations/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteGuidanceLocationSuccess(result, id)))
      .catch((error) => dispatch(deleteGuidanceLocationFailure(error)));
  };

const createGuidanceLocationRequest = (
  newGuidanceLocation: GuidanceLocationParams
) => ({
  type: CREATE_GUIDANCE_LOCATION_REQUEST,
  newGuidanceLocation,
});
const createGuidanceLocationSuccess = (response: any) => ({
  type: CREATE_GUIDANCE_LOCATION_SUCCESS,
  response,
});
const createGuidanceLocationFailure = (error: string) => ({
  type: CREATE_GUIDANCE_LOCATION_FAILURE,
  error,
});

export const createGuidanceLocation =
  (newGuidanceLocation: GuidanceLocationParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGuidanceLocationRequest(newGuidanceLocation));
    return axios
      .post('/api/guidancelocations', newGuidanceLocation, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createGuidanceLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createGuidanceLocationFailure(err.message));
      });
  };

const updateGuidanceLocationRequest = (
  id: string,
  patch: GuidanceLocationParams
) => ({
  type: UPDATE_GUIDANCE_LOCATION_REQUEST,
  id,
  patch,
});
const updateGuidanceLocationSuccess = (response: any) => ({
  type: UPDATE_GUIDANCE_LOCATION_SUCCESS,
  response,
});
const updateGuidanceLocationFailure = (error: string) => ({
  type: UPDATE_GUIDANCE_LOCATION_FAILURE,
  error,
});

export const updateGuidanceLocation =
  (id: string, patch: GuidanceLocationParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGuidanceLocationRequest(id, patch));
    return axios
      .patch(`/api/guidancelocations/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateGuidanceLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateGuidanceLocationFailure(err.message));
      });
  };
