import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Message } from 'client/components/Message/Message';
import { fetchProducts } from 'client/actions/products';
import { fetchProductPrices } from 'client/actions/productPrices';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productPricesSummariesSortedByBookmarkedSelector } from 'client/reducers/productPrices';
import { Select } from 'client/components/Form';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { JaranMappingSettingModal } from 'client/pages/JaranImport/JaranMappingSettingModal';
import { JaranMappingDeleteModal } from 'client/pages/JaranImport/JaranMappingDeleteModal';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import baseStyles from 'client/base.module.css';
import type { Recurrence, Pricing } from 'shared/models/swagger';

type JaranCsvImportSetting = {
  productName: string;
  productId: string;
  jaranPlanName: string;
  recurrenceOptions: Options;
  pricingOptions: Options;
};

type Options = {
  key: string;
  text: string;
  value: string;
}[];

type SummaryType = Record<string, Record<string, string[]>>;

export const SettingsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productPrices = useSelector(
    productPricesSummariesSortedByBookmarkedSelector
  );

  const jaranProductMapping =
    activeUserOrganization?.jaran_csv_import_products ?? [];

  const [onClose, setOnClose] = React.useState<boolean>(true);
  const [selectedProductId, setSelectedProductId] = React.useState<string>('');
  const [deleteSuccess, setDeleteSuccess] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [deleteTargetProductId, setDeleteTargetProductId] =
    React.useState<string>('');
  const [selectedRecurrenceKey, setSelectedRecurrenceKey] = React.useState<
    Record<string, string>
  >({});
  const [selectedPricingKey, setSelectedPricingKey] = React.useState<
    Record<string, string>
  >({});

  const getRecurrenceOptions = (recurrence?: Recurrence[]) => {
    const optionsValue: Options = [];
    recurrence?.forEach((r) => {
      optionsValue.push({
        key: `${r?.key}`,
        text: `${r?.start_date_local} ~ ${r?.end_date_local}`,
        value: `${r?.key}`,
      });
    });
    return optionsValue;
  };

  const getPricingOptions = (pricing?: Pricing[]) => {
    const optionsValue: Options = [];
    pricing?.forEach((p, idx) => {
      const tmpId = String(idx);
      optionsValue.push({
        key: `${tmpId}`,
        text: `${p?.start_date_local} ~ ${p?.end_date_local}`,
        value: `${tmpId}`,
      });
    });
    return optionsValue;
  };

  const recurrenceSummary: SummaryType = React.useMemo(() => {
    const summary: Record<string, Record<string, string[]>> = {};
    const rKey: Record<string, string> = {};
    jaranProductMapping.forEach((j) => {
      const list: Record<string, string[]> = {};
      productPrices
        .find((p) => p.id == j.product_id)
        ?.recurrence?.forEach((r, idx) => {
          if (idx == 0) {
            rKey[j?.product_id || ''] = r.key || '';
          }
          list[r?.key || ''] = r.start_times.map((s) => {
            return `${s.start_time_local} - ${
              s?.third_party_reference || t('No settings')
            }`;
          });
        });
      summary[j?.product_id || ''] = list;
    });
    setSelectedRecurrenceKey(rKey);
    return summary;
  }, [activeUserOrganization, productPrices]);

  const pricingSummary: SummaryType = React.useMemo(() => {
    const summary: Record<string, Record<string, string[]>> = {};
    const pKey: Record<string, string> = {};
    jaranProductMapping.forEach((j) => {
      const list: Record<string, string[]> = {};
      productPrices
        .find((p) => p.id == j.product_id)
        ?.pricing?.forEach((p, idx2) => {
          if (idx2 == 0) {
            pKey[j?.product_id || ''] = String(idx2);
          }
          list[String(idx2) || ''] = p.units.map((u) => {
            if (u.method === 'PER_BOOKING') {
              return t('No settings required');
            } else {
              return `${u?.guest_type?.title || ''} - ${
                u?.guest_type?.third_party_reference || t('No settings')
              }`;
            }
          });
        });
      summary[j?.product_id || ''] = list;
    });
    setSelectedPricingKey(pKey);
    return summary;
  }, [activeUserOrganization, productPrices]);

  const getColumns = (
    recurrenceSummary: SummaryType,
    pricingSummary: SummaryType
  ) => {
    return [
      {
        Header: '',
        id: 'edit',
        width: 'short',
        Cell: (cellInfo: any) => (
          <div
            className={clsx(baseStyles['inline-block'])}
            style={{ justifyContent: 'center' }}
          >
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['icon'],
                baseStyles['narrow']
              )}
            >
              <img
                src={editIcon}
                onClick={() => {
                  setSelectedProductId(cellInfo.original.productId);
                  setOnClose(false);
                }}
              />
            </a>
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['icon'],
                baseStyles['narrow']
              )}
            >
              <img
                src={deleteIcon}
                onClick={() => {
                  setDeleteTargetProductId(cellInfo.original.productId);
                  setShowDeleteModal(true);
                }}
              />
            </a>
          </div>
        ),
      },
      {
        Header: t('Nutmeg Product'),
        id: 'product_name',
        accessor: 'productName',
        width: 'short',
      },
      {
        Header: t('Jalan Product Name'),
        id: 'jaran_plan_name',
        accessor: 'jaranPlanName',
        width: 'short',
      },
      {
        Header: t('Nutmeg Jalan Start Time Setting'),
        id: 'start_time_setting',
        width: 'short',
        Cell: (cellInfo: any) => (
          <>
            <div style={{ width: '100%' }}>
              <Select
                value={
                  selectedRecurrenceKey?.[cellInfo.original.productId] || ''
                }
                options={cellInfo.original.recurrenceOptions}
                onChange={(e, { value }) => {
                  const newKeys = { ...selectedRecurrenceKey };
                  newKeys[cellInfo.original.productId] = value;
                  setSelectedRecurrenceKey(newKeys);
                }}
                cFormSingleStyle={{ position: 'static' }}
                optionStyle={{
                  width: 'auto',
                }}
              />
            </div>
            <div>
              {(
                recurrenceSummary[cellInfo.original.productId][
                  selectedRecurrenceKey[cellInfo.original.productId]
                ] || []
              ).map((r, idx) => {
                if (r) {
                  return <div key={idx}>{r}</div>;
                } else {
                  return <></>;
                }
              })}
            </div>
          </>
        ),
      },
      {
        Header: t('Nutmeg Jalan Unit Setting'),
        id: 'unit_setting',
        width: 'short',
        Cell: (cellInfo: any) => {
          return (
            <>
              <div style={{ width: '100%' }}>
                <Select
                  value={
                    selectedPricingKey?.[cellInfo.original.productId] || ''
                  }
                  options={cellInfo.original.pricingOptions}
                  onChange={(e, { value }) => {
                    const newKeys = { ...selectedPricingKey };
                    newKeys[cellInfo.original.productId] = value;
                    setSelectedPricingKey(newKeys);
                  }}
                  cFormSingleStyle={{ position: 'static' }}
                  optionStyle={{
                    width: 'auto',
                  }}
                />
              </div>
              <div>
                {(
                  pricingSummary[cellInfo.original.productId][
                    selectedPricingKey[cellInfo.original.productId]
                  ] || []
                ).map((r, idx) => {
                  if (r) {
                    return <div key={idx}>{r}</div>;
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </>
          );
        },
      },
    ];
  };

  const columns = getColumns(recurrenceSummary, pricingSummary);

  React.useEffect(() => {
    dispatch(fetchProductPrices());
  }, [activeUserOrganization]);
  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
    }
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const deleteSuccessFlagTimer = setTimeout(() => {
      setDeleteSuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(deleteSuccessFlagTimer);
    };
  }, [deleteSuccess]);

  const jaranCsvImportSettings = React.useMemo(() => {
    const settings: JaranCsvImportSetting[] = [];
    jaranProductMapping.forEach((j) => {
      const targetProduct = productPrices.find((p) => p.id === j.product_id);
      settings.push({
        productName: getDisplayProductName(targetProduct),
        productId: j?.product_id ?? '',
        jaranPlanName: j?.jaran_product_name ?? '',
        recurrenceOptions: getRecurrenceOptions(
          targetProduct?.recurrence || []
        ),
        pricingOptions: getPricingOptions(targetProduct?.pricing || []),
      });
    });
    return settings;
  }, [activeUserOrganization, productPrices]);

  return (
    <>
      {deleteSuccess && <Message success header={t('Delete Success')} />}
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['flex'],
              baseStyles['green']
            )}
            onClick={() => {
              setOnClose(false);
            }}
          >
            {t('Add New Settings')}
          </a>
        </div>
      </div>
      <CustomTable
        columns={columns}
        items={jaranCsvImportSettings}
        usePaging={true}
      />
      {!onClose && (
        <JaranMappingSettingModal
          onClose={() => {
            setOnClose(true);
            setSelectedProductId('');
          }}
          productId={selectedProductId}
        />
      )}
      {showDeleteModal && (
        <JaranMappingDeleteModal
          setShowModal={setShowDeleteModal}
          productId={deleteTargetProductId}
          deleteSuccess={() => setDeleteSuccess(false)}
        />
      )}
    </>
  );
};
