import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  BATCH_UPDATE_GUIDANCE_PAGE_FAILURE,
  BATCH_UPDATE_GUIDANCE_PAGE_REQUEST,
  BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS,
  CREATE_GUIDANCE_PAGE_FAILURE,
  CREATE_GUIDANCE_PAGE_REQUEST,
  CREATE_GUIDANCE_PAGE_SUCCESS,
  DELETE_GUIDANCE_PAGE_FAILURE,
  DELETE_GUIDANCE_PAGE_REQUEST,
  DELETE_GUIDANCE_PAGE_SUCCESS,
  FETCH_GUIDANCE_PAGES_FAILURE,
  FETCH_GUIDANCE_PAGES_REQUEST,
  FETCH_GUIDANCE_PAGES_SUCCESS,
  UPDATE_GUIDANCE_PAGE_FAILURE,
  UPDATE_GUIDANCE_PAGE_REQUEST,
  UPDATE_GUIDANCE_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GuidancePage,
  CreateGuidancePageResponse,
  ListGuidancePagesResponse,
  UpdateGuidancePageResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_GUIDANCE_PAGES_FAILURE:
    case CREATE_GUIDANCE_PAGE_FAILURE:
    case UPDATE_GUIDANCE_PAGE_FAILURE:
    case DELETE_GUIDANCE_PAGE_FAILURE:
    case BATCH_UPDATE_GUIDANCE_PAGE_FAILURE:
      return action.error;

    case FETCH_GUIDANCE_PAGES_SUCCESS:
    case CREATE_GUIDANCE_PAGE_SUCCESS:
    case UPDATE_GUIDANCE_PAGE_SUCCESS:
    case DELETE_GUIDANCE_PAGE_SUCCESS:
    case BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_GUIDANCE_PAGES_REQUEST:
    case CREATE_GUIDANCE_PAGE_REQUEST:
    case UPDATE_GUIDANCE_PAGE_REQUEST:
    case DELETE_GUIDANCE_PAGE_REQUEST:
    case BATCH_UPDATE_GUIDANCE_PAGE_REQUEST:
      return true;

    case FETCH_GUIDANCE_PAGES_FAILURE:
    case CREATE_GUIDANCE_PAGE_FAILURE:
    case UPDATE_GUIDANCE_PAGE_FAILURE:
    case DELETE_GUIDANCE_PAGE_FAILURE:
    case FETCH_GUIDANCE_PAGES_SUCCESS:
    case CREATE_GUIDANCE_PAGE_SUCCESS:
    case UPDATE_GUIDANCE_PAGE_SUCCESS:
    case DELETE_GUIDANCE_PAGE_SUCCESS:
    case BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: GuidancePage[] = [], action: any): GuidancePage[] => {
  switch (action.type) {
    case FETCH_GUIDANCE_PAGES_SUCCESS: {
      const response = action.response as ListGuidancePagesResponse;
      return response.guidance_pages ?? [];
    }

    case CREATE_GUIDANCE_PAGE_SUCCESS: {
      const response = action.response as CreateGuidancePageResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_GUIDANCE_PAGE_SUCCESS: {
      const response = action.response as UpdateGuidancePageResponse;
      return [...state.map((i) => (i.id !== response.id ? i : response))];
    }

    case DELETE_GUIDANCE_PAGE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    case BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS: {
      const responses = action.responses as UpdateGuidancePageResponse[];
      return [
        ...state.filter((i) => !responses.find((r) => r.id === i.id)),
        ...responses,
      ];
    }

    default:
      return state;
  }
};

export const guidancePages = combineReducers({
  error,
  loading,
  all,
});
