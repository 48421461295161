import * as React from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import {
  fetchInvoices,
  confirmInvoice,
  markInvoiceAsPaid,
} from 'client/actions/invoices';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import { Button } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import { InvoiceFareAdjustmentModal } from 'client/pages/InvoiceRevamped/InvoiceFareAdjustmentModal';
import { BillingInfoFormModal } from 'client/pages/InvoiceRevamped/BillingInfoFormModal';
import { ColumnType } from 'client/libraries/util/ColumnType';
import { nonNull } from 'client/libraries/util/nonNull';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Invoice } from 'shared/models/swagger';

type Props = {
  monthYear: string;
};

const useColumns = (): ColumnType<Invoice>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);
  const dispatch = useDispatch();

  const getFormattedTimeFromUTC = (dateTimeUTC?: string) => {
    return (
      dateTimeUTC &&
      moment(dateTimeUTC, 'YYYY-MM-DD').locale(locale).format('YYYY-MM-DD')
    );
  };

  const getInvoiceStatusText = (status: string) => {
    switch (status) {
      case 'INVOICE_PENDING':
        return t('Pending');

      case 'INVOICE_ISSUED':
        return t('Issued');

      case 'INVOICE_LOCKED':
        return t('Locked');

      case 'INVOICE_IN_PROGRESS':
        return t('Payment in progress');

      case 'INVOICE_PAID':
        return t('Invoice Paid');

      case 'INVOICE_PAYMENT_FAILED':
        return t('Payment failed');

      case 'INVOICE_VOIDED':
        return t('Voided');

      default:
        return status;
    }
  };

  return [
    {
      Header: t('ID'),
      id: 'id',
      accessor: (row) => row.payee_reference || row.id,
      Cell: (cellInfo) => (
        <Link
          to={
            !config.enableChannelManager
              ? `/billing/${cellInfo.original.id}`
              : `/channelmanager/billing/${cellInfo.original.id}`
          }
        >
          {cellInfo.original.payee_reference || cellInfo.original.id}
        </Link>
      ),
    },
    {
      Header: t('Agent'),
      accessor: 'payer_name',
      id: 'payer_name',
    },
    {
      Header: t('Supplier'),
      accessor: 'payee_name',
      id: 'payee_name',
    },
    {
      Header: t('Total Amount'),
      id: 'total_amount',
      textAlign: 'right',
      Cell: (cellInfo) =>
        formattedCurrencyAmount(cellInfo.original.total_amount ?? ''),
    },
    {
      Header: t('Amount Due by Agent'),
      id: 'amount_due_by_payer',
      textAlign: 'right',
      Cell: (cellInfo) =>
        formattedCurrencyAmount(cellInfo.original.amount_due_by_payer ?? ''),
    },
    {
      Header: t('Amount Due by Supplier'),
      id: 'amount_due_by_payee',
      textAlign: 'right',
      Cell: (cellInfo) =>
        formattedCurrencyAmount(cellInfo.original.amount_due_by_payee ?? ''),
    },
    {
      Header: t('Payment Status'),
      id: 'payment_status',
      Cell: (cellInfo) =>
        getInvoiceStatusText(cellInfo.original.status as string),
    },
    {
      Header: t('Billing Period'),
      id: 'billing_period',
      Cell: (cellInfo) => (
        <div>{`${
          getFormattedTimeFromUTC(
            cellInfo.original.billing_period_start_date_time_utc
          ) || ''
        } - ${
          getFormattedTimeFromUTC(
            cellInfo.original.billing_period_end_date_time_utc
          ) || ''
        }`}</div>
      ),
    },
    {
      Header: t('Creation Date'),
      id: 'creation_date_time_utc',
      Cell: (cellInfo) =>
        moment(cellInfo.original.creation_date_time_utc).format('lll'),
    },
    {
      Header: t('Last Updated At'),
      id: 'last_updated_date_time_utc',
      Cell: (cellInfo) =>
        moment(cellInfo.original.last_updated_date_time_utc).format('lll'),
    },
    ...(hasCustomUserRoleWritePermissions(activeUser, 'BILLING.INVOICES')
      ? ([
          {
            Header: t('Action'),
            id: 'action',
            width: 'middle',
            textAlign: 'center',
            Cell: (cellInfo) => {
              switch (cellInfo.original.status) {
                case 'INVOICE_PENDING':
                  return (
                    <>
                      <BillingInfoFormModal
                        invoiceId={cellInfo.original.id}
                        includeDetails={false}
                        buttonSize="small"
                      />
                      <Box mt={1}>
                        <InvoiceFareAdjustmentModal
                          header={t('Fare Adjustment')}
                          trigger={() => (
                            <Button size="small" style="yellow">
                              {t('Adjust')}
                            </Button>
                          )}
                          invoice={{
                            id: cellInfo.original.id,
                            payer_id: cellInfo.original.payer_id ?? '',
                            payer_name: cellInfo.original.payer_name ?? '',
                            payer_type: nonNull(cellInfo.original.payer_type),
                            payee_id: cellInfo.original.payee_id ?? '',
                            payee_name: cellInfo.original.payee_name ?? '',
                            payee_type: nonNull(cellInfo.original.payee_type),
                            amount: cellInfo.original.total_amount
                              ? parseFloat(
                                  cellInfo.original.total_amount.slice(
                                    3,
                                    cellInfo.original.total_amount.length
                                  )
                                )
                              : 0,
                            currency: cellInfo.original.total_amount
                              ? cellInfo.original.total_amount.slice(0, 3)
                              : '',
                          }}
                          includeDetails={false}
                        />
                      </Box>
                    </>
                  );

                case 'INVOICE_ISSUED':
                  return (
                    <>
                      <Button
                        size="small"
                        style="blue"
                        onClick={() => {
                          dispatch(confirmInvoice(cellInfo.original.id, false));
                        }}
                      >
                        {t('Confirm')}
                      </Button>
                      {activeUser &&
                        operationAllowed(
                          activeUser,
                          'write',
                          'postIssuanceInvoiceFareAdjustment'
                        ) && (
                          <InvoiceFareAdjustmentModal
                            header={t('Fare Adjustment')}
                            trigger={() => (
                              <Button
                                size="small"
                                style="yellow"
                                content={t('Adjust')}
                              />
                            )}
                            invoice={{
                              id: cellInfo.original.id,
                              payer_id: cellInfo.original.payer_id ?? '',
                              payer_name: cellInfo.original.payer_name ?? '',
                              payer_type: nonNull(cellInfo.original.payer_type),
                              payee_id: cellInfo.original.payee_id ?? '',
                              payee_name: cellInfo.original.payee_name ?? '',
                              payee_type: nonNull(cellInfo.original.payee_type),
                              amount: cellInfo.original.total_amount
                                ? parseFloat(
                                    cellInfo.original.total_amount.slice(
                                      3,
                                      cellInfo.original.total_amount.length
                                    )
                                  )
                                : 0,
                              currency: cellInfo.original.total_amount
                                ? cellInfo.original.total_amount.slice(0, 3)
                                : '',
                            }}
                          />
                        )}
                    </>
                  );

                case 'INVOICE_LOCKED':
                  return (
                    <Button
                      size="small"
                      style="blue"
                      onClick={() => {
                        dispatch(
                          markInvoiceAsPaid(cellInfo.original.id, false)
                        );
                      }}
                    >
                      {t('Mark as paid')}
                    </Button>
                  );

                default:
                  return null;
              }
            },
          },
        ] as ColumnType<Invoice>[])
      : []),
  ];
};

export const InvoicesTab = ({ monthYear }: Props) => {
  const columns = useColumns();

  const dispatch = useDispatch();

  const invoices = useSelector(
    (state: ReduxState) => state.invoices.allInvoices
  );

  const activeUser = useSelector(activeUserSelector);
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );

  React.useEffect(() => {
    if (activeUser?.organization_id) {
      const creationDateTimeUtcFrom = moment(monthYear).format('YYYY-MM-01');
      const creationDateTimeUtcTo = moment(monthYear)
        .add(1, 'month')
        .format('YYYY-MM-01');
      dispatch(
        fetchInvoices({
          organization_id: activeUser?.organization_id,
          invoice_type: 'SUPPLIER_TO_AGENT_INVOICE_TYPE',
          creation_date_time_utc_from: creationDateTimeUtcFrom,
          creation_date_time_utc_to: creationDateTimeUtcTo,
        })
      );
    }
  }, [activeUser, invalidated, monthYear]);

  return (
    <div>
      <CustomTable items={invoices} columns={columns} />
    </div>
  );
};
