import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Input } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

type Props = {
  name: string;
};
export const AgentReferencesEditor = ({ name }: Props) => {
  const editingProduct = React.useContext(EditingProductContext);
  const agentOptions = (editingProduct?.agents ?? []).map((agent) => ({
    value: agent.id,
    text: agent.name,
  }));
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <div>
          <div>
            <AddIcon
              onClick={() =>
                (fields as any).insertAt(0, {
                  agentId: '',
                  reference: '',
                })
              }
            />
          </div>
          {fields.map((fieldName, idx) => (
            <div key={fieldName} className={baseStyles['base-flex']}>
              <Field name={`${fieldName}.agentId`}>
                {({ input }) => (
                  <select className={baseStyles['base-form-select']} {...input}>
                    <option value="" />
                    {agentOptions.map((channelOption) => (
                      <option
                        key={channelOption.value}
                        value={channelOption.value}
                      >
                        {channelOption.text}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              <Field name={`${fieldName}.reference`}>
                {({ input }) => <Input {...input} />}
              </Field>
              <DeleteIcon onClick={() => fields.remove(idx)} />
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
};
