import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import {
  SET_AVAILABILITY_START_DATE,
  SET_AVAILABILITY_END_DATE,
  SET_AVAILABILITY_CURRENT_PAGE,
  SET_AVAILABILITY_PAGE_SIZE,
  SET_AVAILABILITY_TAG_FILTER,
  SET_AVAILABILITY_SALES_STATUS_FILTER,
  SET_MONTHLY_AVAILABILITY_SELECTED_MONTH,
  SET_WEEKLY_AVAILABILITY_START_DATE,
  SET_CUSTOM_RANGE_AVAILABILITY_START_DATE,
  SET_CUSTOM_RANGE_AVAILABILITY_END_DATE,
  SET_AVAILABILITY_PRODUCT_FILTER,
  SET_AVAILABILITY_DISPATCH_CREW_FILTER,
  SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER,
  SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER,
  SET_AVAILABILITY_RESERVATION_SLOT_VISIBILITY_SETTING,
  SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';

// Selectors

export const productCalendarListDatesSelector = createSelector(
  (state: ReduxState): string => state.productCalendarListControls.startDate,
  (state: ReduxState): string => state.productCalendarListControls.endDate,
  (startDate, endDate) => {
    if (endDate < startDate) {
      return [];
    }

    const end = moment(endDate);
    const dates: string[] = [];
    for (
      let m = moment(startDate);
      m.isBefore(end);
      m = moment(m).add(1, 'days')
    ) {
      dates.push(m.format('YYYY-MM-DD'));
    }

    return dates;
  }
);

// Reducers

const startDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const endDate = (
  state = moment().add(7, 'days').format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_AVAILABILITY_END_DATE:
      return action.payload;
    default:
      return state;
  }
};

// "Monthly" availability page selected month as YYYY-MM-DD
const monthlyAvailabilitySelectedMonth = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_MONTHLY_AVAILABILITY_SELECTED_MONTH:
      return action.payload;
    default:
      return state;
  }
};

const weeklyAvailabilityStartDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_WEEKLY_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const customRangeAvailabilityStartDate = (
  state = moment().format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_CUSTOM_RANGE_AVAILABILITY_START_DATE:
      return action.payload;
    default:
      return state;
  }
};

const customRangeAvailabilityEndDate = (
  state = moment().add(6, 'days').format('YYYY-MM-DD'),
  action: any
): string => {
  switch (action.type) {
    case SET_CUSTOM_RANGE_AVAILABILITY_END_DATE:
      return action.payload;
    default:
      return state;
  }
};

const pageSize = (state = 10, action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const currentPage = (state = 0, action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_CURRENT_PAGE:
      return action.page;

    default:
      return state;
  }
};

const productFilter = (state = [], action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_PRODUCT_FILTER:
      return action.productFilter;

    default:
      return state;
  }
};

const productTagFilter = (state = [], action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_TAG_FILTER:
      return action.productTagFilter;

    default:
      return state;
  }
};

const productSalesStatusFilter = (state = [], action: any): any => {
  switch (action.type) {
    case SET_AVAILABILITY_SALES_STATUS_FILTER:
      return action.productSalesStatusFilter;

    default:
      return state;
  }
};

const dispatchCrewFilter = (state = [], action: any): any => {
  switch (action.type) {
    case SET_AVAILABILITY_DISPATCH_CREW_FILTER:
      return action.dispatchCrewFilter;

    default:
      return state;
  }
};

const dispatchVehicleFilter = (state = [], action: any): any => {
  switch (action.type) {
    case SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER:
      return action.dispatchVehicleFilter;

    default:
      return state;
  }
};

const dispatchMiscResourceFilter = (state = [], action: any): any => {
  switch (action.type) {
    case SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER:
      return action.dispatchMiscResourceFilter;

    default:
      return state;
  }
};

const reservationSlotVisibilitySetting = (state = 'SHOW_ALL', action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_RESERVATION_SLOT_VISIBILITY_SETTING:
      return action.visibilitySetting;

    default:
      return state;
  }
};

const resourceVisibilitySetting = (state = [], action: any) => {
  switch (action.type) {
    case SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING:
      return action.visibilitySetting;

    default:
      return state;
  }
};

const reducer = combineReducers({
  currentPage,
  startDate,
  endDate,
  pageSize,
  productFilter,
  productTagFilter,
  productSalesStatusFilter,
  dispatchCrewFilter,
  dispatchVehicleFilter,
  dispatchMiscResourceFilter,
  monthlyAvailabilitySelectedMonth,
  weeklyAvailabilityStartDate,
  customRangeAvailabilityStartDate,
  customRangeAvailabilityEndDate,
  reservationSlotVisibilitySetting,
  resourceVisibilitySetting,
});
export const productCalendarListControls = reducer;
