import * as Swagger from 'shared/models/swagger';

import { getOrderedProductIds } from './getOrderedProductIds';

export const getBookingWidgetProductSummaries = (
  organization: Swagger.Organization | null,
  products: Swagger.ProductSummary[],
  language: Swagger.SourceLanguage
): Swagger.ProductSummary[] => {
  return getOrderedProductIds(organization, products, language).map(
    (id) => products.find((s) => s.id === id) as Swagger.ProductSummary
  );
};
