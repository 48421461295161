import * as Swagger from 'shared/models/swagger';

type DisplayType = 'PER_PARTICIPANT' | 'PER_BOOKING';
export type FormValues = {
  templateName: string;
  title: string;
  description: string;
  productIds: string[];
  sourceLanguage: 'EN_US' | 'JA_JP';
  translatedLanguages: ('EN_US' | 'JA_JP' | 'KO_KR' | 'ZH_CN' | 'ZH_TW')[];
  sections: WaiverSection[];
  signatureType:
    | 'PER_PARTICIPANT'
    | 'PER_BOOKING'
    | 'PER_PARTICIPANT_WITH_NAMES';
  showTranslationMode: boolean;
  translationTargetLanguage:
    | 'EN_US'
    | 'JA_JP'
    | 'KO_KR'
    | 'ZH_CN'
    | 'ZH_TW'
    | null;
  translations: {
    source: string;
    target: string;
  }[];
};
export type WaiverSection = {
  header: string;
  items: WaiverItem[];
  displayType: DisplayType;
};
export type WaiverItem =
  | WaiverItemField
  | WaiverItemText
  | WaiverItemSignature
  | WaiverItemFileUpload
  | WaiverConditional;
export type WaiverItemField = {
  type: 'FIELD';
  prompt: string;
  required: boolean;
  format: string;
  responseConstraint: 'FORMAT' | 'NO_RESTRICTION';
  inputType: 'SHORT_TEXT_INPUT' | 'LONG_TEXT_INPUT' | 'TEXT_AREA' | 'CHECKBOX';
  displayType: DisplayType;
};
export type WaiverItemText = {
  type: 'TEXT';
  body: string;
  displayType: DisplayType;
};
export type WaiverItemSignature = {
  type: 'SIGNATURE';
  displayType: DisplayType;
};
export type WaiverItemFileUpload = {
  type: 'FILE_UPLOAD';
  displayType: DisplayType;
  label: string;
};
export type WaiverConditional = {
  type: 'CONDITIONAL';
  question: string;
  yesItems: WaiverItem[];
  noItems: WaiverItem[];
};
export const getInitialValues = (
  waiverTemplate?: Swagger.WaiverTemplate
): FormValues => {
  if (!waiverTemplate) {
    return {
      templateName: '',
      title: '',
      description: '',
      signatureType: 'PER_BOOKING',
      sourceLanguage: 'EN_US',
      translatedLanguages: [],
      productIds: [],
      sections: [],
      showTranslationMode: false,
      translationTargetLanguage: null,
      translations: [],
    };
  }

  return {
    templateName: waiverTemplate.template_name ?? '',
    title: waiverTemplate.title ?? '',
    description: waiverTemplate.description ?? '',
    signatureType: waiverTemplate.signature_type ?? 'PER_BOOKING',
    sourceLanguage: (waiverTemplate.source_language as any) ?? 'JA_JP',
    translatedLanguages: waiverTemplate.languages ?? [],
    productIds: waiverTemplate.product_ids ?? [],
    sections: (waiverTemplate?.sections ?? []).map((section) =>
      convertSwaggerWaiverSectionToFormValueSection(section)
    ),
    showTranslationMode: false,
    translationTargetLanguage: null,
    translations: [],
  };
};
export const getDefaultWaiverSection = (): WaiverSection => {
  return {
    header: '',
    items: [],
    displayType: 'PER_BOOKING',
  };
};
export const getDefaultWaiverItem = (): WaiverItem => {
  return {
    type: 'TEXT',
    body: '',
    displayType: 'PER_BOOKING',
  };
};
export const convertFormValuesToSwaggerWaiverTemplate = (
  formValues: FormValues
): Swagger.WaiverTemplateParams => {
  return {
    template_name: formValues.templateName,
    title: formValues.title,
    description: formValues.description,
    signature_type: formValues.signatureType,
    source_language: formValues.sourceLanguage,
    languages: formValues.translatedLanguages,
    product_ids: formValues.productIds,
    sections: formValues.sections.map((section) =>
      convertFormValueSectionToSwaggerWaiverSection(section)
    ),
  };
};
export const convertSwaggerWaiverSectionToFormValueSection = (
  section: Swagger.WaiverTemplateSection
): WaiverSection => {
  return {
    header: section.header ?? '',
    items: (section.items ?? []).map((item) =>
      convertSwaggerWaiverItemToFormValueItem(item)
    ),
    displayType: section.display_type ?? 'PER_BOOKING',
  };
};
export const convertSwaggerWaiverItemToFormValueItem = (
  item: Swagger.WaiverTemplateItem
): WaiverItem => {
  switch (item.type) {
    case 'TEXT':
      return {
        type: 'TEXT',
        body: item.text?.body ?? '',
        displayType: item.display_type ?? 'PER_BOOKING',
      };

    case 'SIGNATURE':
      return {
        type: 'SIGNATURE',
        displayType: item.display_type ?? 'PER_BOOKING',
      };

    case 'FIELD':
      return {
        type: 'FIELD',
        prompt: item.field?.prompt ?? '',
        required: item.field?.required ?? false,
        format: item.field?.format ?? '',
        inputType: item.field?.input_type ?? 'SHORT_TEXT_INPUT',
        displayType: item.display_type ?? 'PER_BOOKING',
        responseConstraint: item.field?.format ? 'FORMAT' : 'NO_RESTRICTION',
      };

    case 'FILE_UPLOAD':
      return {
        type: 'FILE_UPLOAD',
        label: item.file_upload?.label ?? '',
        displayType: item.display_type ?? 'PER_BOOKING',
      };
    case 'CONDITIONAL':
      return {
        type: 'CONDITIONAL',
        question: item.conditional?.question ?? '',
        yesItems: (item.conditional?.yes_items ?? []).map((item) =>
          convertSwaggerWaiverItemToFormValueItem(item)
        ),
        noItems: (item.conditional?.no_items ?? []).map((item) =>
          convertSwaggerWaiverItemToFormValueItem(item)
        ),
      };
  }

  throw new Error(`unexpected item type ${item.type ?? ''}`);
};

const convertFormValueSectionToSwaggerWaiverSection = (
  section: WaiverSection
): Swagger.WaiverTemplateSection => {
  return {
    header: section.header,
    items: (section.items ?? []).map((item) =>
      convertFormValueItemToSwaggerWaiverItem(item)
    ),
    display_type: section.displayType ?? 'PER_BOOKING',
  };
};

const convertFormValueItemToSwaggerWaiverItem = (
  item: WaiverItem
): Swagger.WaiverTemplateItem => {
  switch (item.type) {
    case 'TEXT':
      return {
        type: 'TEXT',
        text: {
          body: item.body,
        },
        display_type: item.displayType,
      };

    case 'SIGNATURE':
      return {
        type: 'SIGNATURE',
        display_type: item.displayType,
      };

    case 'FIELD':
      return {
        type: 'FIELD',
        field: {
          prompt: item.prompt,
          required: item.required,
          format: item.format,
          input_type: item.inputType ?? 'SHORT_TEXT_INPUT',
        },
        display_type: item.displayType,
      };

    case 'FILE_UPLOAD':
      return {
        type: 'FILE_UPLOAD',
        file_upload: {
          label: item.label,
        },
        display_type: item.displayType,
      };
    case 'CONDITIONAL':
      return {
        type: 'CONDITIONAL',
        conditional: {
          question: item.question,
          yes_items: item.yesItems?.map(
            (item) => convertFormValueItemToSwaggerWaiverItem(item) as any
          ),
          no_items: item.noItems?.map(
            (item) => convertFormValueItemToSwaggerWaiverItem(item) as any
          ),
        },
      };
  }

  throw new Error(`unexpected item type ${(item as any).type}`);
};
