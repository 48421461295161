import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MultiSelect, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getValidators } from 'shared/libraries/validate/validator';

interface Props {
  name: string;
  options: {
    text: string;
    value: string;
  }[];
}

export const OptionFilterEditor = ({ name, options }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  return (
    <Box display="flex" mt={2}>
      <Field name={`${name}.filterType`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <div>
            <div>{t('Condition')}</div>
            <Select
              width={364}
              options={[
                {
                  text: t('Any of'),
                  value: 'INCLUDE',
                },
                {
                  text: t('None of'),
                  value: 'EXCLUDE',
                },
              ]}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
              error={touched && error}
            />
          </div>
        )}
      </Field>
      <Field name={`${name}.value`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box ml={1} width="100%">
            <div>{t('Value')}</div>
            <MultiSelect
              options={options}
              selectedValues={
                typeof input.value === 'object' ? input.value : []
              }
              search
              onChange={({ value }) => input.onChange(value)}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
    </Box>
  );
};
