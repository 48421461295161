// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import type { Dispatch as ReduxDispatch } from 'redux';

import { fetchReservationVoucherPdf } from 'client/actions/reservations';
import type { Reservation } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';

type Props = {
  t: TranslateFuncType,
  reservation: Reservation,
  downloadReservationVoucherPdf: (id: string) => void,
  size: string,
};

class downloadVoucherButton extends React.Component<Props> {
  onDownload = () => {
    this.props.downloadReservationVoucherPdf(this.props.reservation.id);
  };

  render() {
    const { t, size } = this.props;
    return (
      <Button size={size} primary onClick={() => this.onDownload()}>
        <Icon name="pdf file outline" />
        {t('Voucher')}
      </Button>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  downloadReservationVoucherPdf: (id: string) =>
    dispatch(fetchReservationVoucherPdf(id)),
});

export const DownloadVoucherButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(downloadVoucherButton);
