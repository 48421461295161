export const getBookingWidgetUrl = (bookingWidgetSubdomain: string) => {
  if (
    /app\.dev\.ntmg\.com/.test(window.location.origin) ||
    /localhost:3000/.test(window.location.origin)
  ) {
    return `https://${bookingWidgetSubdomain}.book.dev.ntmg.com`;
  } else if (/app\.stg\.ntmg\.com/.test(window.location.origin)) {
    return `https://${bookingWidgetSubdomain}.book.stg.ntmg.com`;
  }

  return `https://${bookingWidgetSubdomain}.book.ntmg.com`;
};
export const getBookingWidgetApiKeyUrl = (apiKey: string): string => {
  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');
  return `${bookingWidgetBaseUrl}/${apiKey}`;
};
