import * as React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import { FormValues } from './formValues';
import { MessageEditor } from './MessageEditor';
import { NewsletterTargetsButton } from './NewsletterTargetsButton';

export const Complete = () => {
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values as FormValues;
  const allSegments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const segments = values?.segments.map((segmentId) =>
    allSegments.find((s) => s.id === segmentId)
  );
  const { id } = useParams<{ id: string }>();

  const existingNewsletter = useSelector((state: ReduxState) =>
    state.newsletters.all.find((n) => n.id === id)
  );

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__header']}>
        <div className={baseStyles['base-main__body__box__header__ttl']}>
          {t('Newsletter Has Been Sent')}
        </div>
      </div>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box display="flex" alignItems="flex-start">
          <Box>
            <FieldWrapper label={t('Target Segments')}>
              <ul>
                {segments.map((segment, idx) => (
                  <li key={idx}>{segment?.name}</li>
                ))}
              </ul>
            </FieldWrapper>
            <FieldWrapper
              label={
                <Box display="flex">
                  <Box mr={1}>{t('Total Targets: ')}</Box>
                  {existingNewsletter && (
                    <NewsletterTargetsButton newsletter={existingNewsletter} />
                  )}
                </Box>
              }
            />
            <FieldWrapper label={t('Subject')}>{values?.subject}</FieldWrapper>
            <FieldWrapper label={t('Message')}>
              <MessageEditor readOnly />
            </FieldWrapper>
          </Box>
          <Box ml="auto">
            <Link to={`/newsletters/${id}/report`}>
              <Button style="blue" size="middle">
                {t('View Report')}
              </Button>
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
};
