import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReduxState } from 'client/reducers';
import { fetchProductByID } from 'client/actions/products';
import { updateProductInstance } from 'client/actions/productInstances';
import { ModalLoader } from 'client/components/ModalLoader';
import { Modal } from 'client/components/v3/Form/Modal';
import { Message } from 'client/components/Message/Message';
import { ProductInstance } from 'shared/models/swagger';
import { getParentID } from 'client/libraries/util/util';
import { ProductInstanceEditInput } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ProductInstanceEditInput';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  oldProductInstance: ProductInstance;
  onClose: () => void;
  open: boolean;
  onUpdate?: (isUpdated: boolean) => void;
};

export const ProductInstanceEditModal = ({
  oldProductInstance,
  onClose,
  open,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const product = useSelector(
    (state: ReduxState) => state.products.byID[oldProductInstance.product_id]
  );
  const productLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const isProductInstancesLoading = useSelector(
    (state: ReduxState) => state.productInstances.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const getInitialProductInstance = () => ({
    ...oldProductInstance,
    prevent_allotment_changes_when_reprinting: true,
  });

  const [editingProductInstance, setEditingProductInstance] =
    useState<ProductInstance>(getInitialProductInstance());

  useEffect(() => {
    fetchProduct();
  }, [oldProductInstance]);

  const fetchProduct = () => {
    if (!product || product.id !== oldProductInstance.product_id) {
      dispatch(fetchProductByID(oldProductInstance.product_id));
    }
  };

  const editingProductInstanceHasChanged = () => {
    return (
      oldProductInstance.total_slots !== editingProductInstance.total_slots ||
      oldProductInstance.per_channel_info !==
        editingProductInstance.per_channel_info ||
      !editingProductInstance.prevent_allotment_changes_when_reprinting
    );
  };

  const startMoment = moment
    .tz(
      editingProductInstance.start_date_time_utc,
      product?.start_timezone || 'UTC'
    )
    .locale(locale);
  const start = startMoment.format('lll');
  const parentID = product && getParentID({ ...product });

  const handleClose = () => {
    setEditingProductInstance(getInitialProductInstance());
    onClose();
  };

  return (
    <Modal
      title={`${t('Edit allotment')}: ${start}`}
      open={open}
      insertAtRoot={true}
      onClose={onClose}
      style={{ maxWidth: '800px' }}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={handleClose}
          />
          <Button
            text={t('Save')}
            onClick={async () => {
              const {
                total_slots,
                per_channel_info,
                prevent_allotment_changes_when_reprinting,
                memo,
              } = editingProductInstance;
              await dispatch(
                updateProductInstance(editingProductInstance.id, {
                  total_slots,
                  per_channel_info,
                  prevent_allotment_changes_when_reprinting,
                  memo,
                  // Assert as any to avoid Typescript error
                } as any)
              );
              // Notify product instance is updated
              onUpdate && (await onUpdate(editingProductInstanceHasChanged()));
              await onClose();
            }}
            disabled={!editingProductInstanceHasChanged()}
            loading={isProductInstancesLoading}
          />
        </>
      }
    >
      {parentID ? (
        <>
          <Message
            content={t(
              'This product shares allotment with another product so calendar edit is disabled.'
            )}
          />
          <Message>
            <Link to={`/products/${parentID}`}>
              {t('Go to parent product')}
            </Link>
          </Message>
        </>
      ) : (
        <>
          {productLoading || !product ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <ModalLoader />
            </div>
          ) : (
            <ProductInstanceEditInput
              product={product || null}
              oldProductInstance={oldProductInstance}
              newProductInstance={editingProductInstance}
              handleProductInstanceChange={(
                newProductInstance: ProductInstance
              ) => setEditingProductInstance(newProductInstance)}
            />
          )}
        </>
      )}
    </Modal>
  );
};
