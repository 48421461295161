import _ from 'lodash';

import { MinimumParticipantFormValues } from 'client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/FormValues';
import { GuestType } from 'shared/models/swagger';
import { BasicFormValues } from 'client/pages/v3/Product/ProductEdit/types';

export const getInitialMinimumParticipantFormValues = (
  basicFormValues: BasicFormValues | null,
  defaultCurrency: string
): MinimumParticipantFormValues => {
  if (!basicFormValues) {
    return {
      minimumParticipantCount: 0,
      usePerBookingInventoryCounting: false,
      unitWeights: [],
      calendarUnitOrder: [],
    };
  }

  // Convert form value prices to pricing
  const pricing = [
    {
      days_of_week: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      units: [
        ...(basicFormValues.prices || []).map((price) => {
          let guestType;
          if (price.unit) {
            guestType = {
              title: price.unit,
              key: price.unit,
              minimum_age: price.ageBandMinimum,
              maximum_age: price.ageBandMaximum,
            };
          }

          return {
            method: price.method,
            guest_type: guestType,
            gross: `${defaultCurrency}${price.price}`,
            net: `${defaultCurrency}${price.price}`,
            per_booking_guest_types: price.perBookingUnits?.map(
              (perBookingUnit) => ({
                key: perBookingUnit.unit,
                title: perBookingUnit.title,
                minimum_age: perBookingUnit.ageBandMinimum,
                maximum_age: perBookingUnit.ageBandMaximum,
              })
            ),
          };
        }),
        ...(basicFormValues.perGroupPrices ?? []).map((price) => ({
          method: 'PER_GROUP' as const,
          title: price.title,
          group_guest_types: (price.groupUnits ?? []).map((groupUnit) => ({
            title: groupUnit,
            key: groupUnit,
          })),
          gross: `${defaultCurrency}${price.price ?? 0}`,
          net: `${defaultCurrency}${price.price ?? 0}`,
        })),
      ],
    },
  ];

  let units: GuestType[] = [];

  for (const priceRule of pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit.guest_type) {
        units.push({ ...unit.guest_type });
      }
    }
  }

  units = _.uniqBy(units, (unit) => unit.key);
  return {
    minimumParticipantCount: 0,
    usePerBookingInventoryCounting: false,
    unitWeights: units.map((unit) => ({
      unitKey: unit.key,
      ageBandMinimum: unit.minimum_age ?? null,
      ageBandMaximum: unit.maximum_age ?? null,
      weight: unit?.minimum_participant_parameters
        ? unit?.minimum_participant_parameters.weight || 0
        : 1,
      shouldConsumeInventory: true, // Just set as true initially
    })),
    calendarUnitOrder: units.map((u, idx) => {
      return {
        unit: u.key,
        title: u.title,
        default_display_in_calendar: idx < 3 ? true : false,
      };
    }),
  };
};
