import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { Button } from 'client/components/Form';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { updateInquiry } from 'client/actions/inquiries';
import { ReduxState } from 'client/reducers';
import { InquiryItem } from 'client/components/InquiryItem/InquiryItem';
import { TranslateFuncType } from 'client/components/Translate';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { Inquiry, InquiryStatus } from 'shared/models/swagger';

import styles from './InquiryAccordionItem.module.css';

const getSmsInquiryStatus = (
  inquiry: Inquiry,
  t: TranslateFuncType
): string => {
  if (inquiry.status === 'ARCHIVED') {
    return t('Archived');
  }

  return (
    inquiry.messages?.[inquiry.messages?.length - 1]?.sms_info?.sms_status ??
    'unknown'
  );
};

type Props = {
  inquiry: Inquiry;
};
export const InquiryAccordionItem = ({ inquiry }: Props) => {
  const { t } = useTranslation();

  const initialOpen = inquiry.status !== 'ARCHIVED';

  const loading = useSelector((state: ReduxState) => state.inquiries.loading);
  const activeUser = useSelector(activeUserSelector);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState<boolean>(initialOpen ?? false);

  const [showStatusChangeConfirmModal, setShowStatusChangeConfirmModal] =
    React.useState(false);

  const getStatusText = (status?: InquiryStatus): string => {
    switch (status) {
      case 'RECEIVED':
        return t('Received');
      case 'RESPONDED':
        return t('Responded');
      case 'ARCHIVED':
        return t('Archived');
      case 'SENT':
        return t('Sent');
    }

    return t('Completed');
  };

  const header =
    inquiry.initial_communication_type === 'SMS_BY_SUPPLIER'
      ? `SMS ${formattedPhone(
          inquiry.customer_phone || ''
        )} [${getSmsInquiryStatus(inquiry, t)}]`
      : `${inquiry.subject} [${getStatusText(inquiry.status)}]`;

  return (
    <li
      className={clsx(
        styles['c-accordion__item'],
        styles['bg'],
        open && styles['is-active']
      )}
    >
      <div
        className={styles['c-accordion__item__header']}
        onClick={() => setOpen(!open)}
      >
        <div className={styles['header']}>{header}</div>
        <div
          className={styles['archive-button-container']}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {inquiry.status === 'ARCHIVED' ? (
            <>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'INQUIRY.LIST'
              ) && (
                <Button
                  loading={loading}
                  style="gray"
                  size="small"
                  onClick={() => {
                    setShowStatusChangeConfirmModal(true);
                  }}
                >
                  {t('Re-activate')}
                </Button>
              )}
              {showStatusChangeConfirmModal && (
                <MessageModal
                  open={true}
                  onClose={() => setShowStatusChangeConfirmModal(false)}
                  title={t('Re-activate Inquiry')}
                  message={t(
                    'Are you sure you wish to re-activate this inquiry?'
                  )}
                  onSubmit={async () => {
                    await dispatch(
                      updateInquiry(inquiry.id ?? '', {
                        status: inquiry.messages?.some(
                          (message) =>
                            message.action_source?.entity_type ===
                            'SUPPLIER_ENTITY_TYPE'
                        )
                          ? 'RESPONDED'
                          : 'RECEIVED',
                      })
                    );
                    setShowStatusChangeConfirmModal(false);
                    setOpen(true);
                  }}
                />
              )}
            </>
          ) : (
            <>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'INQUIRY.LIST'
              ) && (
                <Button
                  loading={loading}
                  style="blue"
                  size="small"
                  onClick={() => {
                    setShowStatusChangeConfirmModal(true);
                  }}
                >
                  {t('Archive')}
                </Button>
              )}
              {showStatusChangeConfirmModal && (
                <MessageModal
                  open={true}
                  onClose={() => setShowStatusChangeConfirmModal(false)}
                  title={t('Archive Inquiry')}
                  message={t('Are you sure you wish to archive this inquiry?')}
                  onSubmit={async () => {
                    await dispatch(
                      updateInquiry(inquiry.id ?? '', {
                        status: 'ARCHIVED',
                      })
                    );
                    setShowStatusChangeConfirmModal(false);
                    setOpen(false);
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className={styles['ic']}>
          <p>{open ? t('Hide') : t('Show')}</p>
          <span></span>
        </div>
      </div>
      <div className={styles['c-accordion__item__body']}>
        <div>
          <InquiryItem item={inquiry} />
        </div>
      </div>
    </li>
  );
};
