import * as React from 'react';
import { Form, Field } from 'react-final-form';
import 'rc-color-picker/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { getArrayMutators } from 'client/libraries/util/form';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { fetchProducts } from 'client/actions/products';
import { productOptionsSelector } from 'client/reducers/products';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './FareharborTab.module.css';

type FormValues = {
  shortname: string;
  productMappings: { nutmegId: string; fareharborId: string }[];
};

export const FareharborTab = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const initialValues: FormValues = React.useMemo(() => {
    return {
      shortname: organization?.fareharbor_product_settings?.shortname || '',
      productMappings:
        organization?.fareharbor_product_settings?.product_mappings?.map(
          (mapping) => ({
            nutmegId: mapping.nutmeg_product_id ?? '',
            fareharborId: mapping.fareharbor_product_id ?? '',
          })
        ) || [],
    };
  }, []);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const productOptions = useSelector(productOptionsSelector);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateOrganization(organization?.id ?? '', 'SUPPLIER', {
            fareharbor_product_settings: {
              shortname: values.shortname,
              product_mappings: values.productMappings.map((mapping) => ({
                nutmeg_product_id: mapping.nutmegId,
                fareharbor_product_id: mapping.fareharborId,
              })),
            },
          })
        );
      }}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, submitting, submitSucceeded, form, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles['nutmeg-admin-note']}>
            Note: this tab is visible only to Nutmeg admins
          </div>

          <Snackbar
            text={t('Save Successful')}
            color="success"
            shouldShow={submitSucceeded}
            onClose={() => form.reset(values)}
          />

          <Field name="shortname">
            {({ input }) => (
              <TextField
                label={t('Shortname')}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          </Field>

          <FieldArray name="productMappings">
            {({ fields }) => (
              <div className={styles['id-editor']}>
                {fields.map((name, idx) => (
                  <div key={name} className={styles['id-editor-row']}>
                    <Field
                      name={`${name}.nutmegId`}
                      render={({ input }) => (
                        <SingleDropdown
                          searchable
                          label={t('Nutmeg Product')}
                          selectedOption={input.value}
                          onChange={input.onChange}
                          options={productOptions}
                        />
                      )}
                    />
                    <Field
                      name={`${name}.fareharborId`}
                      render={({ input }) => (
                        <TextField
                          label={t('Fareharbor Product ID')}
                          value={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    />
                    <Button
                      size="icon"
                      color="tertiarygray"
                      onClick={() => {
                        fields.remove(idx);
                      }}
                      iconBeforeText={
                        <i className="c-icon-outline-general-trash-03"></i>
                      }
                    />
                  </div>
                ))}
                <Button
                  size="md"
                  color="secondary"
                  type="button"
                  onClick={() =>
                    fields.push({ nutmegId: '', fareharborId: '' })
                  }
                  text={t('Add Mapping')}
                />
              </div>
            )}
          </FieldArray>

          <div className={styles['action-buttons']}>
            <Button
              loading={submitting}
              text={t('Save Settings')}
              type="submit"
              size="md"
              color="primary"
              uiType="bg"
            />
          </div>
        </form>
      )}
    </Form>
  );
};
