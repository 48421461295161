import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { DispatchResource } from 'shared/models/swagger';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  initialResources: DispatchResource[];
  setResources: Dispatch<SetStateAction<DispatchResource[]>>;
};

export const ResourceEditTable = ({
  initialResources,
  setResources,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '16px' }}>
      {initialResources.length === 0 && (
        <div>{t('{{item}} is not registered.', { item: t('Resource') })}</div>
      )}
      {initialResources.length !== 0 && (
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              <th style={{ width: '25%' }}>{t('Name')}</th>
              <th style={{ width: '20%' }}>{t('Capacity')}</th>
              <th>{t('Description')}</th>
            </tr>
          </thead>
          <tbody>
            {initialResources.map((resource, index) => (
              <tr key={index}>
                <td className={tableStyles['c-tableSmall__spTtl']}>
                  <TextField
                    value={resource.key ?? ''}
                    onChange={(value) => {
                      const newResources = [...initialResources];
                      newResources[index] = {
                        ...newResources[index],
                        key: value,
                      };
                      setResources(newResources);
                    }}
                  />
                </td>
                <td data-text={t('Capacity')}>
                  <SingleDropdown
                    options={[...Array(100)].map((_, idx) => {
                      return {
                        text: idx.toString(),
                        value: idx.toString(),
                      };
                    })}
                    selectedOption={(resource.capacity ?? '').toString()}
                    onChange={(value) => {
                      const newResources = [...initialResources];
                      newResources[index] = {
                        ...newResources[index],
                        capacity: parseInt(value, 10),
                      };
                      setResources(newResources);
                    }}
                  />
                </td>
                <td data-text={t('Description')}>
                  <div className={styles['p-manifests__customize__flex']}>
                    <TextArea
                      value={resource.description ?? ''}
                      onChange={(_, { value }) => {
                        const newResources = [...initialResources];
                        newResources[index] = {
                          ...newResources[index],
                          description: value,
                        };
                        setResources(newResources);
                      }}
                      height={80}
                      fullContainerWidth={true}
                    />
                    <Button
                      size="icon"
                      color="tertiarygray"
                      onClick={() => {
                        const newResources = [...initialResources];
                        newResources.splice(index, 1);
                        setResources(newResources);
                      }}
                      iconBeforeText={
                        <i className="c-icon-outline-general-trash-03"></i>
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <a
        className={styles['p-manifests__customize__add']}
        onClick={() => {
          const newResources = [...initialResources];
          newResources.splice(
            initialResources.length + 1,
            initialResources.length,
            {
              key: undefined,
              description: '',
            }
          );
          setResources(newResources);
        }}
      >
        <i className="c-icon-outline-general-plus-circle"></i>
        {t('Add New Resource')}
      </a>
    </div>
  );
};
