import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FalconUIRenderingContext, Menu } from '@nutmeglabs/falcon-ui';

import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Add } from 'client/components/Icons/Add';

import { LinkEditor } from '../LinkEditor';
import { getDefaultHeaderSubmenu } from '../../useTemplateOptions';

import { DraggableMenu } from './DraggableMenu';

interface Props {
  onClose: () => void;
  value: Menu;
  onChange: (arg0: Menu) => void;
  allowSubmenus?: boolean;
}

export const EditMenuModal = ({
  onClose,
  value,
  onChange,
  allowSubmenus,
}: Props) => {
  const topPageCtx = React.useContext(FalconUIRenderingContext);

  const { t } = useTranslation();

  const menuStyleOptions = [
    {
      value: 'DIRECT_LINK',
      label: t('Direct Link'),
    },
    {
      value: 'DROPDOWN',
      label: t('Dropdown'),
    },
  ];

  return (
    <Modal insertRoot open={true} onClose={onClose} title={t('Edit Menu')}>
      <Form
        initialValues={value}
        onSubmit={(values: Menu) => {
          onChange(values);
          onClose();
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Field name="title">
                  {({ input }) => (
                    <Input
                      label={t('Text')}
                      disabled={
                        values.style === 'DIRECT_LINK' &&
                        values.link?.type === 'LOGIN_PAGE'
                      }
                      {...input}
                    />
                  )}
                </Field>
                {allowSubmenus && (
                  <FieldWrapper label={t('Menu Style')}>
                    <EnumRadioButtonGroup
                      name="style"
                      options={menuStyleOptions}
                    />
                  </FieldWrapper>
                )}
                {values.style === 'DIRECT_LINK' && <LinkEditor name="link" />}
                {values.style === 'DROPDOWN' && (
                  <FieldWrapper label={t('Sub Menus')}>
                    <Field name="subMenus">
                      {({ input }) => (
                        <div>
                          {input.value?.map?.(
                            (subMenu: Menu, index: number) => (
                              <DraggableMenu
                                key={index}
                                index={index}
                                items={input.value}
                                onItemsChange={input.onChange}
                              />
                            )
                          )}

                          <Add
                            onClick={() => {
                              input.onChange([
                                ...input.value,
                                getDefaultHeaderSubmenu(topPageCtx.t),
                              ]);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </FieldWrapper>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button.Update type="submit">{t('Update')}</Button.Update>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
