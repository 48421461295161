import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReservationByID } from 'client/actions/reservations';
import type { ReduxState } from 'client/reducers';
import { resendEmail } from 'client/actions/emails';
import { getEmailTypeText } from 'client/libraries/util/getEmailTypeText';
import type { EmailSummary, Reservation } from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { ResendEmailButton } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/EmailLog/ResendEmailButton';
import { Badge } from 'client/components/v3/Common/Badge';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
};

export const EmailLog = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State to keep track the resending email Id.
  const [resendEmailId, setResendEmailId] = React.useState<string>('');
  // State to show or hide a resend success message.
  const [resendEmailSucceeded, setResendEmailSucceeded] =
    React.useState<boolean>(false);
  // State to show or hide a resend error message.
  const [resendEmailError, setResendEmailError] =
    React.useState<boolean>(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const emails = (reservation.email_log ?? []).filter(
    (email) => email.recipient_entity_type === 'GUEST_ENTITY_TYPE'
  );

  if (emails.length === 0) {
    return null;
  }

  // store parent_email_id into an array,
  // if the email is opened by the guest (email item contains "email_opened_at_date_time_utc" key).
  const openedEmails = [
    ...new Set(
      emails
        .filter((item) => item.email_opened_at_date_time_utc)
        .map((item) => item.parent_email_id)
    ),
  ];

  const resendEmailByEmailId = async (
    emailId: string | undefined,
    toAddress?: string
  ) => {
    if (!emailId) {
      return;
    }

    try {
      setResendEmailId(emailId);
      setResendEmailSucceeded(false);
      setResendEmailError(false);

      await dispatch(resendEmail(emailId, toAddress ?? ''));
      await dispatch(fetchReservationByID(reservation.id));

      setResendEmailId('');
      setResendEmailSucceeded(true);
    } catch (e) {
      setResendEmailId('');
      setResendEmailError(true);
    }
  };

  const BadgeForEmailStatus = (emailSummary: EmailSummary) => {
    if (emailSummary.email_bounced_at_date_time_utc) {
      return <Badge label={t('Sending Error')} color="error" />;
    }
    if (
      emailSummary.parent_email_id &&
      openedEmails.includes(emailSummary.parent_email_id)
    ) {
      return <Badge label={t('Opened')} color="gray" />;
    }

    return <Badge label={t('Unopened')} color="primary" />;
  };

  return (
    <section
      id="emailLog"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Emails Sent to Guest')}
          </p>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className="p-mails">
            <table
              className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
            >
              <thead>
                <tr>
                  {/* TODO: status shall be refined later */}
                  <th className={baseStyles['u-width-128']}>{t('Status')}</th>
                  <th className={baseStyles['u-width-208']}>
                    {t('Send Date')}
                  </th>
                  <th className={baseStyles['u-width-144']}>
                    {t('Direction')}
                  </th>
                  <th>{t('Content')}</th>
                  <th className={baseStyles['u-width-128']}></th>
                </tr>
              </thead>
              <tbody>
                {emails.map((emailSummary, idx) => (
                  <tr key={idx}>
                    <td>
                      {emailSummary.email_bounced_at_date_time_utc ? (
                        <Tooltip
                          text={t(
                            'Email bounced. This usually means that the email address is invalid.'
                          )}
                          width={260}
                          mobileStyle={{
                            left: '140px',
                          }}
                        >
                          <div className={styles['p-mails__status__error']}>
                            {BadgeForEmailStatus(emailSummary)}
                          </div>
                        </Tooltip>
                      ) : (
                        <>{BadgeForEmailStatus(emailSummary)}</>
                      )}
                    </td>

                    <td className={tableStyles['c-tableSmall__spNoTh']}>
                      {moment(emailSummary.sent_date_time_utc)
                        .locale(locale)
                        .format('lll')}
                    </td>
                    <td className={tableStyles['c-tableSmall__spNoTh']}>
                      {emailSummary.email_type != null
                        ? getEmailTypeText(emailSummary.email_type, t)
                        : ''}
                    </td>
                    <td
                      className={clsx(
                        tableStyles['c-tableSmall__spNoTh'],
                        tableStyles['c-tableSmall__spTopBorder']
                      )}
                    >
                      {emailSummary.email_title ?? ''}
                    </td>

                    {/*
                      Show read / resend icon if the record contains parent_email_id
                        - Show read icon if any one of the child record (record with same parent_email_id) contains email_opened_at_date_time_utc (email is opened by the guest)
                        - Show resend icon if the record does not contains email_opened_at_date_time_utc
                    */}
                    {emailSummary.parent_email_id ? (
                      <td className={tableStyles['c-tableSmall__spNoTh']}>
                        {openedEmails.includes(emailSummary.parent_email_id) ? (
                          <div></div>
                        ) : (
                          <div>
                            <ResendEmailButton
                              loading={emailSummary.id == resendEmailId}
                              onResendEmail={(toAddress?: string) =>
                                resendEmailByEmailId(emailSummary.id, toAddress)
                              }
                              showGuestEmailEditModal={Boolean(
                                emailSummary.email_bounced_at_date_time_utc
                              )}
                            />
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className={tableStyles['c-tableSmall__spNoTh']}>
                        &nbsp;
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {resendEmailSucceeded && (
              <div key={'resend_success_msg'}>
                <Snackbar
                  text={t('Send Successful')}
                  color="success"
                  shouldShow={resendEmailSucceeded}
                />
              </div>
            )}
            {resendEmailError && (
              <div key={'resend_error_msg'}>
                <Snackbar
                  text={t('Send Failed')}
                  color="error"
                  shouldShow={resendEmailError}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
