// @flow

import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import { tasksSelector } from 'client/reducers/tasks';
import type {
  ActionSource,
  BookingSource,
  BookingSourceType,
  GetTaskCountsResponse,
  ReservationLocationWithTime,
  ReservationStatus,
  Task,
  TaskCategory,
} from 'shared/models/swagger';
import { getActionSourceEntityName } from 'client/libraries/util/util';
import type { ReduxState } from 'client/reducers';
import type { ActionItemCategory } from 'client/reducers/tasks';
import type { TranslateFuncType } from 'client/components/Translate';

export const actionItemDescription = (
  task: TaskShape,
  t: TranslateFuncType
): string => {
  switch (task.category) {
    case 'RESERVATION_STATUS_UPDATED':
      switch (task.reservationStatus) {
        case 'REQUESTED':
          return t('New booking requested');
        case 'CANCELED_BY_AGENT':
        case 'CANCELED_BY_SUPPLIER':
        case 'CANCELED_BY_GUEST':
          return t('Canceled');
        case 'DECLINED_BY_SUPPLIER':
          return t('Declined');
        default:
          return t(task.reservationStatus);
      }
    case 'PICKUP_INFORMATION_UPDATED':
      return t('New pickup info');
    // INFO_REQUIRED not yet supported
    // case 'INFO_REQUIRED':
    //   return t('Customer info required');
    case 'PICKUP_DROPOFF_INFO_TBD':
      if (task.isCheckinCheckoutOnly) {
        return t('Checkin info required');
      } else {
        return t('Pickup info required');
      }
    case 'RESERVATION_STATUS_SEEN':
      return t('Reservation seen');
    default:
      return '';
  }
};

export const actionItemHelpText = (
  task: TaskShape,
  t: TranslateFuncType
): string => {
  switch (task.category) {
    case 'RESERVATION_STATUS_UPDATED':
      return task.reservationStatus === 'REQUESTED'
        ? t(
            'A new booking has been requested. Please review details and confirm, decline, or mark this booking as standby.'
          )
        : t('Booking status changed: {{status}}', {
            status: t(task.reservationStatus),
          });
    case 'PICKUP_INFORMATION_UPDATED':
      return t(
        'Pickup information has been updated. Please review and take appropriate action.'
      );
    case 'INFO_REQUIRED':
      return t(
        "Additional customer information is required for this booking. Please look at the 'Customer Form Input' section and provide responses to indicated fields"
      );
    case 'PICKUP_DROPOFF_INFO_TBD':
      return t(
        'Pickup information has not yet been provided for this booking. Please enter pickup time and place.'
      );
    case 'RESERVATION_STATUS_SEEN':
      return t('This reservation has been acknowledged by the opposite party.');
    default:
      return '';
  }
};

export type TaskShape = {
  id: string,
  reservationId: string,
  category: TaskCategory,
  bookingSourceType: BookingSourceType | '',
  bookingSourceAgent: string,
  agentReference: string,
  supplierReference: string,
  supplierName: string,
  reservationStatus: ReservationStatus,

  customerFullName: string,

  agentGuestLastUpdatedByFullText: string,
  supplierLastUpdatedByFullText: string,
  agentGuestLastUpdatedByActorNameOnly: string,
  supplierLastUpdatedByActorNameOnly: string,
  participationDateTime: Moment,
  pickupCheckinLocation: string,
  pickupCheckinTime: Moment,
  productName: string,

  isCheckinCheckoutOnly: boolean,
  supplierShortReference: string,
};

const tSelector = (state: ReduxState, props: { t: TranslateFuncType }) =>
  props.t;

export const taskShapesSelector = createSelector<ReduxState, *, *, *, *>(
  [tasksSelector, tSelector],
  (tasks, t) => tasks.map((task) => toTaskShape(task, t))
);

export const toTaskShape = (task: Task, t: TranslateFuncType): TaskShape => {
  return {
    id: task.id,
    reservationId: task.reservation_id,
    category: task.category,
    bookingSourceType: getBookingSourceType(task.reservation_booking_source),
    bookingSourceAgent: getBookingSourceAgent(task.reservation_booking_source),
    productName: task.reservation_product_name || '',
    agentReference: task.reservation_agent_reference || '',
    supplierReference: task.reservation_supplier_reference || '',
    reservationStatus: task.reservation_status,
    supplierName: task.reservation_supplier_name || '',
    customerFullName: task.reservation_customer_full_name || '',

    agentGuestLastUpdatedByFullText: getFormattedEntityNameAndDescription(
      task.reservation_last_agent_or_guest_action_source,
      t
    ),
    supplierLastUpdatedByFullText: getFormattedEntityNameAndDescription(
      task.reservation_last_supplier_action_source,
      t
    ),
    agentGuestLastUpdatedByActorNameOnly: getEntityDescription(
      task.reservation_last_agent_or_guest_action_source
    ),
    supplierLastUpdatedByActorNameOnly: getEntityDescription(
      task.reservation_last_supplier_action_source
    ),
    participationDateTime: getParticipationDateTime(task),
    pickupCheckinLocation: getPickupCheckinLocation(task),
    pickupCheckinTime: getPickupCheckinTime(task),
    isCheckinCheckoutOnly: task.reservation_is_checkin_checkout_only || false,
    supplierShortReference: task.reservation_supplier_short_reference || '',
  };
};

const getBookingSourceAgent = (bookingSource?: BookingSource): string => {
  if (!bookingSource) {
    return '';
  }

  return bookingSource.agent_name || '';
};

const getBookingSourceType = (
  bookingSource?: BookingSource
): BookingSourceType => {
  return (bookingSource && bookingSource.source_type) || 'OTHER';
};

const getFormattedEntityNameAndDescription = (
  actionSource?: ActionSource,
  t: TranslateFuncType
): string => {
  if (!actionSource) {
    return '';
  }

  const entityName = getActionSourceEntityName(actionSource, t);
  const entityDescription = actionSource.entity_description;

  if (entityName && entityDescription) {
    return t('{{entityName}} ({{personName}})', {
      personName: entityDescription,
      entityName,
    });
  } else if (entityName) {
    return t('{{entityName}}', {
      entityName,
    });
  } else if (entityDescription) {
    return t('{{personName}}', {
      personName: entityDescription,
    });
  }

  return '';
};

const getEntityDescription = (actionSource?: ActionSource): string => {
  if (!actionSource) {
    return '';
  }

  return actionSource.entity_description || '';
};

const getParticipationDateTime = (task: Task): Moment | typeof undefined => {
  const { reservation_start_date_time_utc, reservation_start_timezone } = task;

  if (!reservation_start_date_time_utc || !reservation_start_timezone) {
    return undefined;
  }

  return moment.tz(reservation_start_date_time_utc, reservation_start_timezone);
};

const getPickupCheckin = (
  task: Task
): ReservationLocationWithTime | typeof undefined => {
  const { reservation_checkin, reservation_pickup } = task;
  return reservation_pickup || reservation_checkin;
};

const getPickupCheckinLocation = (task: Task): string => {
  const pickupCheckin = getPickupCheckin(task);
  return (pickupCheckin && pickupCheckin.location_name) || '';
};

const getPickupCheckinTime = (task: Task): Moment | typeof undefined => {
  const startTimezone = task.reservation_start_timezone || 'UTC';

  const pickupCheckin = getPickupCheckin(task);
  const pickupCheckinDateTime = pickupCheckin && pickupCheckin.date_time_utc;
  return (
    pickupCheckinDateTime && moment.tz(pickupCheckinDateTime, startTimezone)
  );
};

export type ActionItemCategoryOption = {
  key: string,
  text: string,
  value: ActionItemCategory,
  countAccessor: (req: GetTaskCountsResponse) => number,
  color: string,
};

const colors = {
  default: 'grey',
  requested: 'olive',
  confirmed: 'green',
  canceled: 'pink',
  withdrawn: 'brown',
  pickupDropoffTBD: 'orange',
  standby: 'violet',
  declined: 'red',
  pickupInfoUpdated: 'teal',
  reservationSeen: 'blue',
};

export const getActionItemCategoryOptions = (
  t: TranslateFuncType,
  isSupplier?: boolean
): ActionItemCategoryOption[] => {
  if (isSupplier) {
    return [
      {
        key: 'TOTAL',
        text: t('All'),
        value: 'TOTAL',
        countAccessor: (req: GetTaskCountsResponse) => req.total || 0,
        color: colors.default,
      },
      {
        key: 'REQUESTED',
        text: t('Requested'),
        value: 'REQUESTED',
        countAccessor: (req: GetTaskCountsResponse) => req.requested_count || 0,
        color: colors.requested,
      },
      {
        key: 'CONFIRMED',
        text: t('New Instant Booking'),
        value: 'CONFIRMED',
        countAccessor: (req: GetTaskCountsResponse) => req.confirmed_count || 0,
        color: colors.confirmed,
      },
      {
        key: 'CANCELED',
        text: t('Canceled'),
        value: 'CANCELED',
        countAccessor: (req: GetTaskCountsResponse) => req.canceled_count || 0,
        color: colors.canceled,
      },
      {
        key: 'WITHDRAWN',
        text: t('Withdrawn'),
        value: 'WITHDRAWN',
        countAccessor: (req: GetTaskCountsResponse) => req.withdrawn_count || 0,
        color: colors.withdrawn,
      },
      {
        key: 'PICKUP_DROPOFF_TBD',
        text: t('Pickup/Checkin Info Required'),
        value: 'PICKUP_DROPOFF_TBD',
        countAccessor: (req: GetTaskCountsResponse) =>
          req.pickup_tbd_count || 0,
        color: colors.pickupDropoffTBD,
      },
    ];
  }

  return [
    {
      key: 'TOTAL',
      text: t('All'),
      value: 'TOTAL',
      countAccessor: (req: GetTaskCountsResponse) => req.total || 0,
      color: colors.default,
    },
    {
      key: 'STANDBY',
      text: t('Standby'),
      value: 'STANDBY',
      countAccessor: (req: GetTaskCountsResponse) => req.standby_count || 0,
      color: colors.standby,
    },
    {
      key: 'CONFIRMED',
      text: t('Confirmed'),
      value: 'CONFIRMED',
      countAccessor: (req: GetTaskCountsResponse) => req.confirmed_count || 0,
      color: colors.confirmed,
    },
    {
      key: 'DECLINED',
      text: t('Declined'),
      value: 'DECLINED',
      countAccessor: (req: GetTaskCountsResponse) => req.declined_count || 0,
      color: colors.declined,
    },
    {
      key: 'CANCELED',
      text: t('Canceled'),
      value: 'CANCELED',
      countAccessor: (req: GetTaskCountsResponse) => req.canceled_count || 0,
      color: colors.canceled,
    },
    {
      key: 'PICKUP_INFO_UPDATED',
      text: t('New Pickup Info'),
      value: 'PICKUP_INFO_UPDATED',
      countAccessor: (req: GetTaskCountsResponse) =>
        req.pickup_updated_count || 0,
      color: colors.pickupInfoUpdated,
    },
    {
      key: 'RESERVATION_SEEN',
      text: t('Reservation Seen'),
      value: 'RESERVATION_SEEN',
      countAccessor: (req: GetTaskCountsResponse) =>
        req.reservation_status_seen_count || 0,
      color: colors.reservationSeen,
    },
  ];
};

export const getColorForTask = (task: TaskShape): string => {
  switch (task.category) {
    case 'RESERVATION_STATUS_SEEN':
      return colors.reservationSeen;
    case 'PICKUP_INFORMATION_UPDATED':
      return colors.pickupInfoUpdated;
    case 'PICKUP_DROPOFF_INFO_TBD':
      return colors.pickupDropoffTBD;
    case 'RESERVATION_STATUS_UPDATED':
      switch (task.reservationStatus) {
        case 'REQUESTED':
          return colors.requested;
        case 'CONFIRMED':
          return colors.confirmed;
        case 'STANDBY':
          return colors.standby;
        case 'DECLINED_BY_SUPPLIER':
          return colors.declined;
        case 'CANCELED_BY_AGENT':
        case 'CANCELED_BY_SUPPLIER':
        case 'CANCELED_BY_GUEST':
          return colors.canceled;
        case 'WITHDRAWN_BY_AGENT':
          return colors.withdrawn;
        default:
          return colors.default;
      }
    default:
      return colors.default;
  }
};
