import type { Equipment } from 'shared/models/swagger';

export type EquipmentShape = {
  id: string;
  title: string;
  description: string;
};

export const toEquipmentShape = (equipment: Equipment): EquipmentShape => {
  return {
    id: equipment.id ?? '',
    title: equipment.title ?? '',
    description: equipment.description ?? '',
  };
};
