// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import { EditExcludedFormFieldsModal } from 'client/pages/ManifestCustomize/ExcludedFormFieldList/EditExcludedFormFieldsModal';
import type { ReduxState } from 'client/reducers';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type OwnProps = {
  isActive?: boolean,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
};
/* eslint-enable no-use-before-define */

export const ExcludedFormFieldListComponent = ({
  excludedFormFieldKeys,
  isActive,
}: Props) => {
  const { t } = useTranslation();

  const commonFormFieldsByKey = getCommonFormFieldsByKey(t);

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Filter Required Info')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <EditExcludedFormFieldsModal
            trigger={
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['small'],
                  baseStyles['yellow']
                )}
              >
                {t('Edit')}
              </a>
            }
          />
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(componentStyles['c-table-sub'])}>
          <table>
            <tr>
              <th>{t('Deleted reservation forms')}</th>
            </tr>
            <tr>
              <td data-text={t('Deleted reservation forms')}>
                {excludedFormFieldKeys.length === 0
                  ? t('(none)')
                  : excludedFormFieldKeys.map((key) => {
                      const fieldText =
                        commonFormFieldsByKey[key ?? '']?.text ?? key;

                      return (
                        <>
                          {fieldText}
                          <br />
                        </>
                      );
                    })}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  excludedFormFieldKeys: manifestExcludedFormFieldsSelector(state),
});

export const ExcludedFormFieldList = connect<*, *, *, *, *, *>(mapStateToProps)(
  ExcludedFormFieldListComponent
);
