import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/TagSettings.module.css';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchCustomerTags } from 'client/actions/customers';
import { fetchProducts } from 'client/actions/products';

import { CustomerAutoTagCondition } from './FormValues';

type Props = {
  name: string;
  fields: any;
  index: number;
};

export const ConditionEditor = ({ name, fields, index }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const allCustomerManualTags = useSelector(
    (state: ReduxState) => state.customers.tags
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productOptions = useMemo(
    () =>
      allProducts.map((product) => ({
        key: product.id,
        value: product.id,
        text: product.internal_product_name ?? '',
      })) || [],
    [allProducts]
  );
  const manualTagOptions = useMemo(
    () =>
      allCustomerManualTags.map((tag) => ({
        key: tag,
        value: tag,
        text: tag,
      })) || [],
    [allCustomerManualTags]
  );
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const form = useForm();
  const conditionValues: CustomerAutoTagCondition = _.get(
    form.getState().values,
    name
  );

  useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
      dispatch(fetchCustomerTags());
    }
  }, [activeUserOrganization]);

  const validateNumeric = useCallback((s: string) => {
    if (!s) {
      return undefined;
    }
    if (/^[0-9]*$/.test(s)) {
      return undefined;
    }

    return t('Positive numeric');
  }, []);

  const registrationDateRangePresetOptions = [
    {
      text: t('Custom'),
      value: 'REGISTRATION_DATE_CUSTOM',
    },
  ];

  const lastParticipationDateRangePresetOptions = [
    {
      text: t('{{count}} days ago', { count: 30 }),
      value: 'LAST_30_DAYS',
    },
    {
      text: t('{{count}} days ago', { count: 90 }),
      value: 'LAST_90_DAYS',
    },
    {
      text: t('{{count}} days ago', { count: 180 }),
      value: 'LAST_180_DAYS',
    },
    {
      text: t('Custom'),
      value: 'LAST_PARTICIPATION_DATE_CUSTOM',
    },
  ];

  const genderOptions = [
    {
      text: t('All'),
      value: 'ANY',
    },
    {
      text: t('Male'),
      value: 'MALE',
    },
    {
      text: t('Female'),
      value: 'FEMALE',
    },
    {
      text: t('Unspecified'),
      value: 'UNSPECIFIED',
    },
  ];

  return (
    <li className={styles['p-tagSettingsModal__condition__list__item']}>
      <div className={styles['p-tagSettingsModal__box']}>
        <div className={styles['p-tagSettingsModal__item']}>
          <p className={styles['p-tagSettingsModal__item__ttl']}>
            {t('Registration Date')}
          </p>
          <div className={styles['p-tagSettingsModal__item__body']}>
            <Field name={`${name}.registrationDateRangePreset`}>
              {({ input }) => (
                <SingleDropdown
                  options={registrationDateRangePresetOptions}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  selectedOption={input.value}
                  disabled={true}
                />
              )}
            </Field>
            {conditionValues.registrationDateRangePreset ===
              'REGISTRATION_DATE_CUSTOM' && (
              <div
                className={styles['p-tagSettingsModal__box']}
                style={{
                  marginTop: '12px',
                  backgroundColor: '#FAFAFA',
                }}
              >
                <p className={styles['p-tagSettingsModal__item__ttl']}>
                  {t('Custom Registration Date')}
                </p>
                <Field name={`${name}.registrationDateRange`}>
                  {({ input }) => (
                    <DateRangeInput
                      dateFrom={input.value.startDate}
                      dateTo={input.value.endDate}
                      onChange={(value) =>
                        input.onChange({
                          startDate: value[0],
                          endDate: value[1],
                        })
                      }
                    />
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
        <div className={styles['p-tagSettingsModal__item']}>
          <p className={styles['p-tagSettingsModal__item__ttl']}>
            {t('Last Participation Date')}
          </p>
          <div className={styles['p-tagSettingsModal__item__body']}>
            <Field name={`${name}.lastParticipationDateRangePreset`}>
              {({ input }) => (
                <SingleDropdown
                  options={lastParticipationDateRangePresetOptions}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  selectedOption={input.value}
                />
              )}
            </Field>
            {conditionValues.lastParticipationDateRangePreset ===
              'LAST_PARTICIPATION_DATE_CUSTOM' && (
              <div
                className={styles['p-tagSettingsModal__box']}
                style={{
                  marginTop: '12px',
                  backgroundColor: '#FAFAFA',
                }}
              >
                <p className={styles['p-tagSettingsModal__item__ttl']}>
                  {t('Custom Last Participation Date')}
                </p>
                <Field name={`${name}.lastParticipationDateRange`}>
                  {({ input }) => (
                    <DateRangeInput
                      dateFrom={input.value.startDate}
                      dateTo={input.value.endDate}
                      onChange={(value) =>
                        input.onChange({
                          startDate: value[0],
                          endDate: value[1],
                        })
                      }
                    />
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
        <div className={styles['p-tagSettingsModal__item']}>
          <p className={styles['p-tagSettingsModal__item__ttl']}>
            {t('Product')}
          </p>
          <div className={styles['p-tagSettingsModal__item__body']}>
            <Field name={`${name}.productIds`}>
              {({ input }) => (
                <MultiDropdown
                  options={productOptions}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  selectedOptions={input.value}
                  searchable={true}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles['p-tagSettingsModal__item']}>
          <p className={styles['p-tagSettingsModal__item__ttl']}>
            {t('Manual Tags')}
          </p>
          <div className={styles['p-tagSettingsModal__item__body']}>
            <Field name={`${name}.manualTagIds`}>
              {({ input }) => (
                <MultiDropdown
                  options={manualTagOptions}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  selectedOptions={input.value}
                  searchable={true}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles['p-tagSettingsModal__item']}>
          <p className={styles['p-tagSettingsModal__item__ttl']}>
            {t('Gender')}
          </p>
          <div className={styles['p-tagSettingsModal__item__body']}>
            <Field name={`${name}.gender`}>
              {({ input }) => (
                <SingleDropdown
                  options={genderOptions}
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  selectedOption={input.value}
                />
              )}
            </Field>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <p className={styles['p-tagSettingsModal__item__ttl']}>
              {t('Age')}
            </p>
            <div className={styles['p-tagSettingsModal__item__body']}>
              <div className={styles['flex']}>
                <Field
                  name={`${name}.ageRange.lowerBound`}
                  validate={validateNumeric}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>{t('years old')} ~</p>
                <Field
                  name={`${name}.ageRange.upperBound`}
                  validate={validateNumeric}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>{t('years old')}</p>
              </div>
            </div>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <div className={styles['p-tagSettingsModal__checkboxes']}>
              <Field name={`${name}.shouldReceiveSpecialEmailOffers`}>
                {({ input }) => (
                  <Checkbox
                    label={t('Receive Emails')}
                    size="sm"
                    onChange={() => input.onChange(!input.value)}
                    checked={input.value}
                  />
                )}
              </Field>
              <Field name={`${name}.alertedCustomer`}>
                {({ input }) => (
                  <Checkbox
                    label={t('Flag customer as alerted')}
                    size="sm"
                    onChange={() => input.onChange(!input.value)}
                    checked={input.value}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <p className={styles['p-tagSettingsModal__item__ttl']}>
              {t('Total Reservations')}
            </p>
            <div className={styles['p-tagSettingsModal__item__body']}>
              <div className={styles['flex']}>
                <Field
                  name={`${name}.reservationCountRange.lowerBound`}
                  validate={validateNumeric}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>~</p>
                <Field
                  name={`${name}.reservationCountRange.upperBound`}
                  validate={validateNumeric}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <p className={styles['p-tagSettingsModal__item__ttl']}>
              {t('Average booking price')}
            </p>
            <div className={styles['p-tagSettingsModal__item__body']}>
              <div className={styles['flex']}>
                <Field
                  name={`${name}.averageReservationAmountGrossRange.lowerBound`}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>
                  {`${defaultSupplierCurrency} ~`}
                </p>
                <Field
                  name={`${name}.averageReservationAmountGrossRange.upperBound`}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>
                  {defaultSupplierCurrency}
                </p>
              </div>
            </div>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <p className={styles['p-tagSettingsModal__item__ttl']}>
              {t('Member LTV')}
            </p>
            <div className={styles['p-tagSettingsModal__item__body']}>
              <div className={styles['flex']}>
                <Field name={`${name}.customerLtvRange.lowerBound`}>
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>
                  {`${defaultSupplierCurrency} ~`}
                </p>
                <Field name={`${name}.customerLtvRange.upperBound`}>
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      onChange={(value) => {
                        input.onChange(value);
                      }}
                      value={input.value}
                      error={touched && error ? error : undefined}
                    />
                  )}
                </Field>
                <p style={{ whiteSpace: 'nowrap' }}>
                  {defaultSupplierCurrency}
                </p>
              </div>
            </div>
          </div>
          <div className={styles['p-tagSettingsModal__item']}>
            <Button
              size="icon"
              color="tertiarygray"
              onClick={() => fields.remove(index)}
              iconBeforeText={
                <i className="c-icon-outline-general-trash-03"></i>
              }
            />
          </div>
        </div>
      </div>
      <div className={styles['p-tagSettingsModal__condition__list__item__or']}>
        {t('OR')}
      </div>
    </li>
  );
};
