import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  getDateFilterPresetOptions,
  SearchInquiriesRequest,
  DateFilterPreset,
} from 'client/libraries/util/searchInquiries';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  Checkbox,
  DateRangeInput,
  FieldWrapper,
  Input,
  Select,
  MultiSelect,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  searchCondition: SearchInquiriesRequest;
  setSearchCondition: (arg0: SearchInquiriesRequest) => void;
  trigger: React.ReactElement<any>;
};
export const InquirySearchSettingsModal = ({
  onReset,
  onSearch,
  searchCondition,
  setSearchCondition,
  trigger,
}: Props) => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);

  const categoryOptions = (org?.inquiry_settings?.inquiry_categories ?? []).map(
    (cat) => ({
      value: cat.title ?? '',
      text: cat.title ?? '',
    })
  );

  const communicationTypeOptions = [
    {
      value: 'EMAIL',
      text: t('Email'),
    },
    {
      value: 'SMS',
      text: t('SMS'),
    },
  ];

  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];

  const participationDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const participationDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateTo: date,
      dateFilterPreset: null,
    });
  };

  const updatedDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      updatedDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const updatedDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      updatedDateTo: date ? moment(date).format('YYYY-MM-DD') : '',
      dateFilterPreset: null,
    });
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Search Inquiries')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Modal.Box>
          <Select
            label={t('Date Preset')}
            options={dateFilterPresetOptions}
            value={searchCondition.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setSearchCondition({
                ...searchCondition,
                dateFilterPreset:
                  value === 'NONE' ? null : (value as DateFilterPreset),
                updatedDateFrom: '',
                updatedDateTo: '',
                participationDateFrom: '',
                participationDateTo: '',
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <DateRangeInput
            label={t('Participation Date')}
            fromDate={searchCondition.participationDateFrom}
            onChangeFromDate={participationDateDateFromChangeHandler}
            toDate={searchCondition.participationDateTo}
            onChangeToDate={participationDateDateToChangeHandler}
          />
          <DateRangeInput
            label={t('Updated Date')}
            fromDate={searchCondition.updatedDateFrom}
            onChangeFromDate={updatedDateFromChangeHandler}
            toDate={searchCondition.updatedDateTo}
            onChangeToDate={updatedDateToChangeHandler}
          />
        </Modal.Box>
        <Modal.Box column="two">
          <Input
            label={t('Given Name')}
            value={searchCondition.customerGivenName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerGivenName: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Family Name')}
            value={searchCondition.customerFamilyName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerFamilyName: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <FieldWrapper label={t('Communication Type')}>
            <Box width="100%" display="flex">
              {communicationTypeOptions.map((option, idx) => (
                <Box width="48%" ml={idx === 1 ? 2 : 0} key={option.value}>
                  <Checkbox
                    label={option.text}
                    checked={searchCondition.communicationTypes.includes(
                      option.value as any
                    )}
                    onChange={() => {
                      searchCondition.communicationTypes.includes(
                        option.value as any
                      )
                        ? setSearchCondition({
                            ...searchCondition,
                            communicationTypes:
                              searchCondition.communicationTypes.filter(
                                (p) => p !== option.value
                              ),
                          })
                        : setSearchCondition({
                            ...searchCondition,
                            communicationTypes: [
                              ...searchCondition.communicationTypes,
                              option.value as any,
                            ],
                          });
                    }}
                  />
                </Box>
              ))}
            </Box>
          </FieldWrapper>
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Email/Phone')}
            value={searchCondition.contact}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                contact: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Tag')}
            value={searchCondition.tag}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                tag: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <MultiSelect
            label={t('Categories')}
            selectedValues={searchCondition.categories}
            options={categoryOptions}
            onChange={({ value }) => {
              setSearchCondition({
                ...searchCondition,
                categories: value,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
            setShowModal(false);
          }}
        >
          {t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
