import { useTranslation } from 'react-i18next';

export const useOrderByOptions = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'last_updated_desc',
      text: t('Updated (most recent first)'),
    },
    {
      value: 'last_updated_asc',
      text: t('Updated (least recent first)'),
    },
    {
      value: 'booked_desc',
      text: t('Booked (most recent first)'),
    },
    {
      value: 'booked_asc',
      text: t('Booked (least recent first)'),
    },
    {
      value: 'participation_asc',
      text: t('Participation (earliest first)'),
    },
    {
      value: 'participation_desc',
      text: t('Participation (latest first)'),
    },
    {
      value: 'expiration_asc',
      text: t('Expiration (earliest first)'),
    },
    {
      value: 'expiration_desc',
      text: t('Expiration (latest first)'),
    },
    {
      value: 'family_name',
      text: t('Family Name (A-Z)'),
    },
  ];
};
