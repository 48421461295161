import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { ListEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ListEditor';

export const TagsEditor = () => {
  const { t } = useTranslation();
  const tagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.tags
  );

  return (
    <ul className={styles['p-list']}>
      <ListEditor
        title={t('Tags')}
        name="tags"
        suggestions={tagSuggestions}
        addButtonText={t('Add Tag')}
      />
    </ul>
  );
};
