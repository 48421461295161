import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import { ListEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ListEditor';

export const TagsEditor = () => {
  const { t } = useTranslation();
  const tagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.tags
  );

  return (
    <ListEditor
      title={t('Tags')}
      name="tags"
      suggestions={tagSuggestions}
      buttonDisabled={true}
    />
  );
};
