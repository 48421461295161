import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';
import { Form, Field, useForm } from 'react-final-form';
import { HeaderSettings } from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper, Input, Select } from 'client/components/Form';

import { HeaderMenusEditor } from './HeaderMenusEditor';

export const EditHeader = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  const form = useForm();
  const values = form.getState().values;

  const {
    logoUrl,
    companyName,
    pageDescription,
    languageButtonColor,
    headerTextColor,
    headerMobileMenuTextColor,
    headerStyle,
    headerBackgroundColor,
    headerMenus,
  } = values;

  const styleOptions = [
    { value: 'TRANSPARENT', text: t('Transparent') },
    { value: 'OPAQUE', text: t('Opaque') },
  ];

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          width="wide"
          title={t('Edit Header')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              logoUrl,
              companyName,
              pageDescription,
              languageButtonColor,
              headerStyle,
              headerBackgroundColor,
              headerTextColor,
              headerMobileMenuTextColor,
              headerMenus,
            }}
            onSubmit={(values: HeaderSettings) => {
              form.batch(() => {
                Object.entries(values).forEach(([key, value]) => {
                  form.change(key, value);
                });
              });
              setShowModal(false);
            }}
            debug={console.log}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <Field name="pageDescription">
                    {({ input }) => (
                      <Input
                        height={80}
                        maxWidth={600}
                        label={t('Header Page Description')}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                  <Field name="headerStyle">
                    {({ input }) => (
                      <Select
                        label={t('Style')}
                        options={styleOptions}
                        value={input.value}
                        onChange={(e, { value }) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                  <FieldWrapper label={t('Background Color')}>
                    <Field name="headerBackgroundColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper label={t('Text Color')}>
                    <Field name="headerTextColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper label={t('Mobile Menu Text Color')}>
                    <Field name="headerMobileMenuTextColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper label={t('Language Button Color')}>
                    <Field name="languageButtonColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper label={t('Menus')}>
                    <HeaderMenusEditor />
                  </FieldWrapper>
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
