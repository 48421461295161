import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { SubscriptionKey } from 'shared/models/swagger';

import { getSubscriptionPriceDescription } from './util';
import styles from './SubscriptionPriceDisplay.module.css';

interface Props {
  subscriptionKey: SubscriptionKey;
}

export const SubscriptionPriceDisplay = ({ subscriptionKey }: Props) => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const currency =
    (activeUserOrganization?.default_subscriptions_payment_currency ??
      'JPY') as 'JPY' | 'USD';

  return (
    <>
      <div className={styles['status-text']}>{t('Fee')}</div>
      <Box>{getSubscriptionPriceDescription(subscriptionKey, currency)}</Box>
    </>
  );
};
