type Option = {
  value: string;
  text: string;
};
export const timezoneOptions: Option[] = [
  {
    value: 'Etc/GMT+12',
    text: 'International Date Line West (GMT-12:00)',
  },
  {
    value: 'Pacific/Midway',
    text: 'Midway Island, Samoa (GMT-11:00)',
  },
  {
    value: 'Pacific/Honolulu',
    text: 'Hawaii (GMT-10:00)',
  },
  {
    value: 'US/Alaska',
    text: 'Alaska (GMT-09:00)',
  },
  {
    value: 'America/Los_Angeles',
    text: 'Pacific Time (US & Canada) (GMT-08:00)',
  },
  {
    value: 'America/Tijuana',
    text: 'Tijuana, Baja California (GMT-08:00)',
  },
  {
    value: 'US/Arizona',
    text: 'Arizona (GMT-07:00)',
  },
  {
    value: 'America/Chihuahua',
    text: 'Chihuahua, La Paz, Mazatlan (GMT-07:00)',
  },
  {
    value: 'US/Mountain',
    text: 'Mountain Time (US & Canada) (GMT-07:00)',
  },
  {
    value: 'America/Managua',
    text: 'Central America (GMT-06:00)',
  },
  {
    value: 'US/Central',
    text: 'Central Time (US & Canada) (GMT-06:00)',
  },
  {
    value: 'America/Mexico_City',
    text: 'Guadalajara, Mexico City, Monterrey (GMT-06:00)',
  },
  {
    value: 'Canada/Saskatchewan',
    text: 'Saskatchewan (GMT-06:00)',
  },
  {
    value: 'America/Bogota',
    text: 'Bogota, Lima, Quito, Rio Branco (GMT-05:00)',
  },
  {
    value: 'US/Eastern',
    text: 'Eastern Time (US & Canada) (GMT-05:00)',
  },
  {
    value: 'US/East-Indiana',
    text: 'Indiana (East) (GMT-05:00)',
  },
  {
    value: 'Canada/Atlantic',
    text: 'Atlantic Time (Canada) (GMT-04:00)',
  },
  {
    value: 'America/Caracas',
    text: 'Caracas, La Paz (GMT-04:00)',
  },
  {
    value: 'America/Manaus',
    text: 'Manaus (GMT-04:00)',
  },
  {
    value: 'America/Santiago',
    text: 'Santiago (GMT-04:00)',
  },
  {
    value: 'Canada/Newfoundland',
    text: 'Newfoundland (GMT-03:30)',
  },
  {
    value: 'America/Sao_Paulo',
    text: 'Brasilia (GMT-03:00)',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    text: 'Buenos Aires, Georgetown (GMT-03:00)',
  },
  {
    value: 'America/Godthab',
    text: 'Greenland (GMT-03:00)',
  },
  {
    value: 'America/Montevideo',
    text: 'Montevideo (GMT-03:00)',
  },
  {
    value: 'America/Noronha',
    text: 'Mid-Atlantic (GMT-02:00)',
  },
  {
    value: 'Atlantic/Cape_Verde',
    text: 'Cape Verde Is. (GMT-01:00)',
  },
  {
    value: 'Atlantic/Azores',
    text: 'Azores (GMT-01:00)',
  },
  {
    value: 'Africa/Casablanca',
    text: 'Casablanca, Monrovia, Reykjavik (GMT+00:00)',
  },
  {
    value: 'Etc/Greenwich',
    text: 'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (GMT+00:00)',
  },
  {
    value: 'Europe/Amsterdam',
    text: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (GMT+01:00)',
  },
  {
    value: 'Europe/Belgrade',
    text: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (GMT+01:00)',
  },
  {
    value: 'Europe/Brussels',
    text: 'Brussels, Copenhagen, Madrid, Paris (GMT+01:00)',
  },
  {
    value: 'Europe/Sarajevo',
    text: 'Sarajevo, Skopje, Warsaw, Zagreb (GMT+01:00)',
  },
  {
    value: 'Africa/Lagos',
    text: 'West Central Africa (GMT+01:00)',
  },
  {
    value: 'Asia/Amman',
    text: 'Amman (GMT+02:00)',
  },
  {
    value: 'Europe/Athens',
    text: 'Athens, Bucharest, Istanbul (GMT+02:00)',
  },
  {
    value: 'Asia/Beirut',
    text: 'Beirut (GMT+02:00)',
  },
  {
    value: 'Africa/Cairo',
    text: 'Cairo (GMT+02:00)',
  },
  {
    value: 'Africa/Harare',
    text: 'Harare, Pretoria (GMT+02:00)',
  },
  {
    value: 'Europe/Helsinki',
    text: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (GMT+02:00)',
  },
  {
    value: 'Asia/Jerusalem',
    text: 'Jerusalem (GMT+02:00)',
  },
  {
    value: 'Europe/Minsk',
    text: 'Minsk (GMT+02:00)',
  },
  {
    value: 'Africa/Windhoek',
    text: 'Windhoek (GMT+02:00)',
  },
  {
    value: 'Asia/Kuwait',
    text: 'Kuwait, Riyadh, Baghdad (GMT+03:00)',
  },
  {
    value: 'Europe/Moscow',
    text: 'Moscow, St. Petersburg, Volgograd (GMT+03:00)',
  },
  {
    value: 'Africa/Nairobi',
    text: 'Nairobi (GMT+03:00)',
  },
  {
    value: 'Asia/Tbilisi',
    text: 'Tbilisi (GMT+03:00)',
  },
  {
    value: 'Asia/Tehran',
    text: 'Tehran (GMT+03:30)',
  },
  {
    value: 'Asia/Muscat',
    text: 'Abu Dhabi, Muscat (GMT+04:00)',
  },
  {
    value: 'Asia/Baku',
    text: 'Baku (GMT+04:00)',
  },
  {
    value: 'Asia/Yerevan',
    text: 'Yerevan (GMT+04:00)',
  },
  {
    value: 'Asia/Kabul',
    text: 'Kabul (GMT+04:30)',
  },
  {
    value: 'Asia/Yekaterinburg',
    text: 'Yekaterinburg (GMT+05:00)',
  },
  {
    value: 'Asia/Karachi',
    text: 'Islamabad, Karachi, Tashkent (GMT+05:00)',
  },
  {
    value: 'Asia/Calcutta',
    text: 'Chennai, Kolkata, Mumbai, New Delhi (GMT+05:30)',
  },
  {
    value: 'Asia/Katmandu',
    text: 'Kathmandu (GMT+05:45)',
  },
  {
    value: 'Asia/Almaty',
    text: 'Almaty, Novosibirsk (GMT+06:00)',
  },
  {
    value: 'Asia/Dhaka',
    text: 'Astana, Dhaka (GMT+06:00)',
  },
  {
    value: 'Asia/Rangoon',
    text: 'Yangon (Rangoon) (GMT+06:30)',
  },
  {
    value: 'Asia/Bangkok',
    text: 'Bangkok, Hanoi, Jakarta (GMT+07:00)',
  },
  {
    value: 'Asia/Krasnoyarsk',
    text: 'Krasnoyarsk (GMT+07:00)',
  },
  {
    value: 'Asia/Hong_Kong',
    text: 'Beijing, Chongqing, Hong Kong, Urumqi (GMT+08:00)',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    text: 'Kuala Lumpur, Singapore (GMT+08:00)',
  },
  {
    value: 'Asia/Irkutsk',
    text: 'Irkutsk, Ulaan Bataar (GMT+08:00)',
  },
  {
    value: 'Australia/Perth',
    text: 'Perth (GMT+08:00)',
  },
  {
    value: 'Asia/Taipei',
    text: 'Taipei (GMT+08:00)',
  },
  {
    value: 'Asia/Tokyo',
    text: '日本 (GMT+09:00)',
  },
  {
    value: 'Asia/Seoul',
    text: 'Seoul (GMT+09:00)',
  },
  {
    value: 'Asia/Yakutsk',
    text: 'Yakutsk (GMT+09:00)',
  },
  {
    value: 'Australia/Adelaide',
    text: 'Adelaide (GMT+09:30)',
  },
  {
    value: 'Australia/Darwin',
    text: 'Darwin (GMT+09:30)',
  },
  {
    value: 'Australia/Brisbane',
    text: 'Brisbane (GMT+10:00)',
  },
  {
    value: 'Australia/Canberra',
    text: 'Canberra, Melbourne, Sydney (GMT+10:00)',
  },
  {
    value: 'Australia/Hobart',
    text: 'Hobart (GMT+10:00)',
  },
  {
    value: 'Pacific/Guam',
    text: 'Guam, Port Moresby (GMT+10:00)',
  },
  {
    value: 'Asia/Vladivostok',
    text: 'Vladivostok (GMT+10:00)',
  },
  {
    value: 'Asia/Magadan',
    text: 'Magadan, Solomon Is., New Caledonia (GMT+11:00)',
  },
  {
    value: 'Pacific/Auckland',
    text: 'Auckland, Wellington (GMT+12:00)',
  },
  {
    value: 'Pacific/Fiji',
    text: 'Fiji, Kamchatka, Marshall Is. (GMT+12:00)',
  },
  {
    value: 'Pacific/Tongatapu',
    text: "Nuku'alofa (GMT+13:00)",
  },
];
export const getFormattedTimezone = (timezone: string): string => {
  const timezoneOption = timezoneOptions.find(
    (option) => option.value === timezone
  );

  if (timezoneOption) {
    return timezoneOption.text;
  }

  return timezone;
};
