import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getArrayMutators } from 'client/libraries/util/form';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { createEquipment } from 'client/actions/equipments';

import { CreateEquipmentFrameForm } from './CreateEquipmentFrameForm';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateEquipmentModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal
      title={t('Create New Resource')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        onSubmit={async (value) => {
          try {
            await dispatch(
              createEquipment({
                title: value.title,
                column_count: Number(value.columnCount ?? 0),
                row_count: Number(value.rowCount ?? 0),
              })
            );
          } catch (e) {
            console.log(e);
          }
          onClose();
        }}
        initialValues={{
          title: '',
          columnCount: 1,
          rowCount: 1,
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <CreateEquipmentFrameForm placeholder={t('resource title')} />
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
                disabled={values.title ? false : true}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
