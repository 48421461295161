import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from 'client/base.module.css';

type Props = {
  originalValue: boolean;
  onChange: (
    arg0: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  checked: boolean;
};

export const PreventAutomaticAllotmentChangesCheckbox = ({
  originalValue,
  onChange,
  checked,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['base-form-box']}>
      <div className={styles['base-form-box__body']}>
        <div className={styles['base-form-checkbox']}>
          <label style={{ alignItems: 'start' }}>
            <input
              onChange={(e) =>
                onChange(e, {
                  value: e.target.dataset.value ?? '',
                })
              }
              type="checkbox"
              checked={checked}
            />
            <p></p>
            <div style={{ display: 'block' }}>
              {t('Prevent automatic allotment changes to this date and time.')}
              <br />
              {t(
                'Allotments are updated automatically and periodically with the allotment rules from the product settings. By leaving this box checked, any allotment edits you make will be preserved.'
              )}
              <br />
              <br />
              {originalValue ? t('Current value: ON') : t('Current value: OFF')}
              <br />
              <br />
              {t('Example')}
              <ol className={clsx(styles['product-calendar-edit-modal'])}>
                <li>
                  {t(
                    'Common allotments for all dates and times are 15 from product rules.'
                  )}
                </li>
                <li>{t('For April 1, 9:00, edit allotment from 15 to 10.')}</li>
                <li>
                  {t(
                    'Depending on the checkbox, the allotment will be different after the automatic update:'
                  )}
                  <ul style={{ paddingLeft: '25px' }}>
                    <li style={{ listStyle: 'disc' }}>
                      {t(
                        'Checked: allotment for April 1, 9:00, will remain 10.'
                      )}
                    </li>
                    <li style={{ listStyle: 'disc' }}>
                      {t(
                        'Unchecked: allotment for April 1, 9:00, will be updated to 15 from the product setting.'
                      )}
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};
