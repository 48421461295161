import moment from 'moment-timezone';

import { MediaDownloadSalesPage } from 'shared/models/swagger';

export type FormValues = {
  productIds: string[];
  date: string;
  status: 'MEDIA_SALES_PAGE_PENDING' | 'MEDIA_SALES_PAGE_PUBLISHED';
  mediaItems: MediaItem[];
  productInstanceIds: string[];
  shouldApplyToAllStartTimes: boolean;
  mediaSets: MediaSet[];
};
export type MediaItem = {
  key: string;
  url: string;
  priceId: string;
  mediaType: string;
  videoDurationSeconds?: number;
  videoFilesizeBytes?: number;
};
export type MediaSet = {
  key: string;
  priceId: string;
  mediaItems: MediaItem[];
};

export const getInitialValues = (
  timezone: string,
  existingSalesPage?: MediaDownloadSalesPage
): FormValues => {
  const defaultDate = moment.tz(timezone).format('YYYY-MM-DD');

  return existingSalesPage
    ? {
        productIds: existingSalesPage.product_ids ?? [],
        date: existingSalesPage.participation_date ?? defaultDate,
        status: existingSalesPage.status ?? 'MEDIA_SALES_PAGE_PENDING',
        mediaItems:
          existingSalesPage.media_items?.map((mediaItem) => ({
            key: mediaItem?.key ?? '',
            url: mediaItem?.content?.url ?? '',
            priceId: mediaItem?.price_id ?? '',
            mediaType: mediaItem?.content?.type ?? '',
            videoDurationSeconds:
              mediaItem?.content?.video_duration_seconds ?? 0,
            videoFilesizeBytes: mediaItem?.content?.video_filesize_bytes ?? 0,
          })) ?? [],
        productInstanceIds: existingSalesPage?.product_instance_ids ?? [],
        shouldApplyToAllStartTimes:
          (existingSalesPage?.product_instance_ids ?? []).length === 0,
        mediaSets:
          existingSalesPage.media_sets?.map((mediaSet) => ({
            key: mediaSet?.key ?? '',
            priceId: mediaSet?.price_id ?? '',
            mediaItems:
              mediaSet?.media_items?.map((mediaItem) => ({
                key: mediaItem?.key ?? '',
                url: mediaItem?.content?.url ?? '',
                priceId: mediaItem?.price_id ?? '',
                mediaType: mediaItem?.content?.type ?? '',
                videoDurationSeconds:
                  mediaItem?.content?.video_duration_seconds ?? 0,
                videoFilesizeBytes:
                  mediaItem?.content?.video_filesize_bytes ?? 0,
              })) ?? [],
          })) ?? [],
      }
    : {
        productIds: [],
        date: defaultDate,
        status: 'MEDIA_SALES_PAGE_PENDING',
        mediaItems: [],
        productInstanceIds: [],
        shouldApplyToAllStartTimes: true,
        mediaSets: [],
      };
};
