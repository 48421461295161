// @flow

import * as React from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type {
  Reservation,
  ReservationReceipt,
  Product,
  Account,
  ProductInstance,
} from 'shared/models/swagger';
import type { LanguageISO } from 'shared/libraries/i18n';
import { config } from 'client/config';
import {
  getCurrentStatus,
  getGuestTypesUsedInProductInstance,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { sendReservationStatusEmail } from 'client/actions/reservations';
import {
  issueReservationReceipt,
  downloadReservationReceiptPDF,
  sendReservationReceiptEmail,
  fetchReservationReceipt,
} from 'client/actions/reservationReceipts';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { operationAllowed } from 'shared/models/access';
import { calculateResellNet } from 'client/libraries/util/calculateResellNet';
import { ReservationMainInformationUpdateModal } from 'client/pages/ReservationDetails/ReservationMainInformationUpdateModal';
import { SendReservationEmailModal } from 'client/pages/ReservationDetails/SendReservationEmailModal';
import { ReceiptModal } from 'client/pages/ReservationDetails/ReceiptModal';
import { PaymentMethod } from 'client/pages/ReservationDetails/PaymentMethod';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import { LabelWithHelpText } from 'client/components/LabelWithHelpText';
import { ReservationCreateModalButton } from 'client/components/ReservationCreateModal/ReservationCreateModalButton';
import { ReservationPinModal } from 'client/components/ReservationPinModal/ReservationPinModal';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers/index';
import { isPartnershipComponentReservation } from 'client/libraries/util/partnership';

import editIcon from '../../images/ic_edit.svg';
import newWindowIcon from '../../images/ic_newwindow.svg';
import pinOnIcon from '../../images/ic_pin_on.svg';
import pinOffIcon from '../../images/ic_pin_off.svg';
import baseStyles from '../../base.module.css';
import pageStyles from '../pages.module.css';

type Props = {
  reservation: Reservation,
  reservationReceipt?: ReservationReceipt,
  product: Product,
  productInstance: ProductInstance,
  locale: LanguageISO,
  activeUser: Account | null,
  readOnly?: boolean,
};

export const ReservationMainInformation = React.forwardRef<Props, any>(
  (props, ref) => {
    const {
      reservation,
      reservationReceipt,
      product,
      productInstance,
      activeUser,
      readOnly,
    } = props;
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [
      reservationVoucherEmailSendingStatus,
      setReservationVoucherEmailSendingStatus,
    ] = React.useState<'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null>(null);
    const [
      reservationCancellationEmailSendingStatus,
      setReservationCancellationEmailSendingStatus,
    ] = React.useState<'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null>(null);

    const [openReservationPinModal, setOpenReservationPinModal] =
      React.useState<boolean>(false);

    if (!reservation || !product || !productInstance) {
      return null;
    }

    // Addon
    const addOnItems = [];
    (reservation.add_ons || []).forEach((addOnKey) => {
      const addOn =
        product.add_ons && product.add_ons.find((a) => a.key === addOnKey);
      if (addOn) {
        // mapped per-reservation addon
        addOnItems.push(addOn.title);
      } else {
        // unmapped per-reservation addon
        addOnItems.push(addOnKey);
      }
    });
    // mapped per-guest addons
    (product.add_ons || []).forEach((addOn) => {
      addOn.pricing &&
        addOn.pricing.forEach((pr) => {
          const guestType = pr.guest_type;
          if (pr.method === 'PER_PARTICIPANT' && guestType) {
            const count = (reservation.guests || []).filter(
              (g) =>
                (guestType.key === g.guest_type_key ||
                  // Match on guest type title because agent reverse mapping may cause
                  // the reservation guest type key to not match the corresponding product
                  // guest type key.
                  guestType.title === g.guest_type_title) &&
                (g.add_ons || []).indexOf(addOn.key) !== -1
            ).length;

            if (count > 0) {
              addOnItems.push(
                `${addOn.title} - ${guestType.title} (x${count})`
              );
            }
          }
        });
    });

    // unmapped per-guest addons
    (reservation.guests || []).forEach((guest) => {
      let unmapped_addons = (guest.add_ons || []).filter(
        (per_guest_addon_key) =>
          !(product.add_ons || [])
            .filter(
              (product_addon) =>
                product_addon.pricing &&
                product_addon.pricing
                  .map((pr) => pr.method)
                  .includes('PER_PARTICIPANT')
            )
            .map((product_addon) => product_addon.key)
            .includes(per_guest_addon_key)
      );
      unmapped_addons.forEach((unmapped_addon) => {
        addOnItems.push(unmapped_addon);
      });
    });
    const addOnSummary = addOnItems.join(', ');

    // Transportation
    let transportationSummary = '';
    const transKey = reservation.transportation;
    if (transKey) {
      const trans = (product.transportations || []).find(
        (trans) => trans.key === transKey
      );
      if (trans) {
        if (trans.service_type === 'FREE') {
          transportationSummary = trans.title;
        } else {
          const perBookingPricing =
            trans.pricing &&
            trans.pricing.find((pr) => pr.method === 'PER_BOOKING');
          if (perBookingPricing) {
            transportationSummary = trans.title;
          } else {
            const transItems = [];
            trans.pricing &&
              trans.pricing.forEach((pr) => {
                const guestType = pr.guest_type;
                const guestCount = (reservation.guests || []).filter(
                  (g) => guestType && g.guest_type_key === guestType.key
                ).length;
                if (guestType && guestCount > 0) {
                  transItems.push(
                    `${trans.title} - ${guestType.title} (x${guestCount})`
                  );
                }
              });

            transportationSummary = transItems.join(', ');
          }
        }
      } else {
        transportationSummary = transKey;
      }
    }
    const isCheckinCheckoutOnly = reservationIsCheckinCheckoutOnly(reservation);
    if (!transportationSummary) {
      transportationSummary = isCheckinCheckoutOnly
        ? t('Checkin/Checkout Only')
        : t('Pickup/Dropoff Included');
    }

    // Guest
    const guestCounts = [];
    const guestCountMap = {};
    const productGuestTypes = getGuestTypesUsedInProductInstance(
      productInstance,
      product,
      t
    );
    reservation.guests.forEach((g: any) => {
      const guestTitle =
        g.guest_type_title ||
        ((productGuestTypes.find(
          (guest_type) => guest_type.key === g.guest_type_key
        ): any)
          ? (productGuestTypes.find(
              (guest_type) => guest_type.key === g.guest_type_key
            ): any).title
          : g.guest_type_key);

      if (guestTitle in guestCountMap) {
        guestCountMap[guestTitle]++;
      } else {
        guestCountMap[guestTitle] = 1;
      }
    });
    for (var guestTitle in guestCountMap) {
      guestCounts.push({
        guestTitle: guestTitle,
        guestCount: guestCountMap[guestTitle],
      });
    }
    const guestSummary = guestCounts
      .map((g) => `${g.guestTitle} (x${g.guestCount})`)
      .join(', ');

    const customerName = getGuestName(reservation);

    const currentStatus = getCurrentStatus(reservation);

    const isResellAgent =
      reservation.contract_type === 'RESELL' &&
      activeUser?.organization_type === 'AGENT';
    let netTotal = '';
    if (reservation.billing_info && reservation.billing_info.amount_net) {
      netTotal = formattedCurrencyAmount(reservation.billing_info.amount_net);
      if (isResellAgent && reservation.billing_info?.amount_gross) {
        netTotal = calculateResellNet(
          reservation.billing_info.amount_gross,
          reservation.billing_info.amount_net ?? ''
        );
      }
    }

    const useHTMLVoucher = currentStatus === 'CONFIRMED';
    const useReceipt = currentStatus === 'CONFIRMED';

    const showSendCancellationEmailButton =
      currentStatus === 'CANCELED_BY_AGENT' ||
      currentStatus === 'CANCELED_BY_SUPPLIER' ||
      currentStatus === 'CANCELED_BY_GUEST';

    const participatesAt = moment.tz(
      reservation.start_date_time_utc,
      reservation.start_timezone
    );

    const userIsPassthroughOrg =
      (reservation.agent_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'AGENT') ||
      (reservation.supplier_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'SUPPLIER');

    const userCanEditSupplierReference =
      !userIsPassthroughOrg &&
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationSupplierReference') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
      !isTerminalReservationStatus(currentStatus);

    const email = reservation?.field_responses?.find(
      (fieldResponse) => fieldResponse.key === 'email'
    )?.response;

    const organization = useSelector(activeUserOrganizationSelector);
    const receiptProviso =
      organization?.supplier_guest_receipt_settings?.receipt_proviso ?? '';

    const reservationReceiptPdfStatus = useSelector(
      (state: ReduxState) => state.reservationReceipts.pdfStatus
    );
    const reservationReceiptEmailStatus = useSelector(
      (state: ReduxState) => state.reservationReceipts.emailStatus
    );

    const reservationLanguage = reservation?.field_responses?.find(
      (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
    )?.response;

    const isChangeReservationAvailable = () => {
      if (activeUser?.organization_type === 'AGENT') {
        return false;
      }

      var createReservationOrganizationId = '';
      if (reservation?.status_history?.length > 0) {
        createReservationOrganizationId =
          reservation?.status_history?.[0]?.action_source?.entity_id;
      }

      if (
        reservation?.booking_source?.source_type === 'AGENT' &&
        activeUser?.organization_id !== createReservationOrganizationId
      ) {
        return false;
      }

      if (reservation?.payment_type === 'PAID_PARTIALLY') {
        return false;
      }

      if (!hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST')) {
        return false;
      }

      const now = moment().tz(reservation?.start_timezone);
      const changeFromStartTime = moment.tz(
        reservation?.start_date_time_utc,
        reservation?.start_timezone
      );
      return changeFromStartTime.isSameOrAfter(now, 'month');
    };

    const onClickChangeReservation = () => {
      history.push({
        pathname: `/products/${product.id}/instances/${productInstance.id}/book`,
        state: {
          reservation,
          isChangeReservation: true,
        },
      });
    };

    const showPinButton = (
      reservation: Reservation,
      activeUser: Account | null
    ) => {
      if (
        [
          'WITHDRAWN_BY_AGENT',
          'DECLINED_BY_SUPPLIER',
          'CANCELED_BY_AGENT',
          'CANCELED_BY_GUEST',
          'CANCELED_BY_SUPPLIER',
        ].includes(reservation.status)
      ) {
        return false;
      }

      if (activeUser?.organization_type === 'AGENT') {
        return false;
      }

      return true;
    };

    const getCustomerNameDisplay = (name: string) => {
      return (
        <>
          {name.split(' ').map((s) => (
            <span
              style={{
                display: 'inline-block',
                marginRight: '2px',
              }}
            >
              {s}{' '}
            </span>
          ))}
        </>
      );
    };

    const customerId = (reservation?.field_responses ?? []).find(
      (fieldResponse) => fieldResponse.key === 'customer_id'
    )?.response;

    const isPartnershipComponent =
      isPartnershipComponentReservation(reservation);

    return (
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          baseStyles['scroll-target-pane']
        )}
        ref={ref}
        id="main"
      >
        <TutorialPopup
          name="main"
          content={
            <TutorialPopupContent>
              <TutorialPopupHeader text={t('Main Information')} />
              <TutorialPopupDescription
                text={t(
                  'This is the section where you can check the main information of the reservation. Information is always displayed for each item so that you can check the latest reservation status.'
                )}
              />
            </TutorialPopupContent>
          }
        />
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Main Information')}
          </div>
          {reservation.rebooked_from_reservation_id && (
            <>
              <div style={{ marginLeft: '5px' }}></div>
              <Link
                to={{
                  pathname: `/reservations/${reservation.rebooked_from_reservation_id}`,
                }}
              >
                ({t('see the reservation before the change')})
              </Link>
            </>
          )}
          {reservation.changed_to_reservation_id && (
            <>
              <div style={{ marginLeft: '5px' }}></div>
              <Link
                to={{
                  pathname: `/reservations/${reservation.changed_to_reservation_id}`,
                }}
              >
                ({t('see the reservation after the change')})
              </Link>
            </>
          )}
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            {hasCustomUserRoleWritePermissions(
              activeUser,
              'RESERVATION.LIST'
            ) && (
              <div
                className={clsx(
                  pageStyles['page-reservations__basicInfo__btn']
                )}
              >
                {showPinButton(reservation, activeUser) && (
                  <>
                    <ReservationPinModal
                      open={openReservationPinModal}
                      onClose={() => setOpenReservationPinModal(false)}
                      reservation={reservation}
                    />
                    <a
                      className={clsx(
                        baseStyles['base-btn icon'],
                        baseStyles['icon']
                      )}
                      onClick={() => setOpenReservationPinModal(true)}
                    >
                      <img
                        src={
                          reservation?.pin_info?.is_pinned
                            ? pinOnIcon
                            : pinOffIcon
                        }
                      />
                    </a>
                  </>
                )}
                {!isPartnershipComponent && (
                  <ReservationCreateModalButton
                    reservation={reservation}
                    trigger={
                      <button
                        className={clsx(
                          baseStyles['base-btn'],
                          baseStyles['middle'],
                          baseStyles['green'],
                          i18n.language == 'en' && baseStyles['flex']
                        )}
                      >
                        <p>{t('Create new reservation')}</p>
                      </button>
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <div className={clsx(pageStyles['page-reservations__basicInfo'])}>
            <div
              className={clsx(pageStyles['page-reservations__basicInfo__ttl'])}
            >
              <p
                className={clsx(
                  pageStyles['page-reservations__basicInfo__ttl__label'],
                  baseStyles['semantic-ui-react-override']
                )}
              >
                {t(currentStatus)}
              </p>
              <p
                className={clsx(
                  pageStyles['page-reservations__basicInfo__ttl__name']
                )}
              >
                # {id}
              </p>
            </div>

            <div
              className={clsx(pageStyles['page-reservations__basicInfo__btn'])}
            >
              {showSendCancellationEmailButton && !isPartnershipComponent && (
                <Box mr={2}>
                  <SendReservationEmailModal
                    initialToAddress={email}
                    trigger={
                      <Button
                        style="blue"
                        size="small"
                        width={140}
                        disabled={
                          reservationCancellationEmailSendingStatus ===
                          'SUCCEEDED'
                        }
                        loading={
                          reservationCancellationEmailSendingStatus ===
                          'REQUESTED'
                        }
                      >
                        {t('Send Cancellation Email')}
                      </Button>
                    }
                    title={t('Send Cancellation Email')}
                    onSubmit={(email?: string) => {
                      try {
                        setReservationCancellationEmailSendingStatus(
                          'REQUESTED'
                        );
                        dispatch(
                          sendReservationStatusEmail(
                            reservation?.id ?? '',
                            email
                          )
                        );
                        setReservationCancellationEmailSendingStatus(
                          'SUCCEEDED'
                        );
                      } catch (e) {
                        setReservationCancellationEmailSendingStatus('FAILED');
                      }
                    }}
                  />
                </Box>
              )}
              {config.enableReservationReceipt &&
                useReceipt &&
                !isPartnershipComponent && (
                  <Box mr={2}>
                    <ReceiptModal
                      initialReceiptProviso={
                        receiptProviso
                          ? receiptProviso
                          : reservationReceipt?.receipt_proviso
                      }
                      initialToAddress={email}
                      reservation={reservation}
                      reservationReceipt={reservationReceipt}
                      trigger={
                        <Button
                          style="blue"
                          size="small"
                          loading={
                            reservationReceiptPdfStatus === 'IN_FLIGHT' ||
                            reservationReceiptEmailStatus === 'IN_FLIGHT'
                          }
                        >
                          {reservationReceipt &&
                          reservationReceipt?.receiver_name
                            ? t('Receipt PDF')
                            : t('Issue Receipt')}
                        </Button>
                      }
                      title={t('Receipt')}
                      onDownload={() => {
                        dispatch(
                          downloadReservationReceiptPDF(reservation?.id ?? '')
                        );
                      }}
                      onIssue={async (
                        receiverName?: string,
                        receiptProviso?: string
                      ) => {
                        await dispatch(
                          issueReservationReceipt({
                            reservation_id: reservation?.id ?? '',
                            supplier_id: organization?.id ?? '',
                            receiver_name: receiverName,
                            receipt_proviso: receiptProviso,
                            language: reservationLanguage,
                            supplier_receipt_business_name:
                              organization?.supplier_guest_receipt_settings
                                ?.receipt_business_name,
                            supplier_business_registration_number:
                              organization?.supplier_guest_receipt_settings
                                ?.business_registration_number,
                            supplier_consumption_tax_calculation:
                              organization?.supplier_guest_receipt_settings
                                ?.consumption_tax_calculation,
                            supplier_address:
                              organization?.office_mailing_address,
                            supplier_phone_number: organization?.office_phone,
                          })
                        );
                        await dispatch(
                          fetchReservationReceipt(reservation?.id ?? '')
                        );
                      }}
                      onSendEmail={(email?: string) => {
                        dispatch(
                          sendReservationReceiptEmail(
                            reservation?.id ?? '',
                            email
                          )
                        );
                      }}
                    />
                  </Box>
                )}
              {useHTMLVoucher && !isPartnershipComponent && (
                <Box mr={2}>
                  <SendReservationEmailModal
                    initialToAddress={email}
                    trigger={
                      <Button
                        style="blue"
                        size="small"
                        disabled={
                          reservationVoucherEmailSendingStatus === 'SUCCEEDED'
                        }
                        loading={
                          reservationVoucherEmailSendingStatus === 'REQUESTED'
                        }
                      >
                        {t('Send Voucher Email')}
                      </Button>
                    }
                    title={t('Send Voucher Email')}
                    onSubmit={(email?: string) => {
                      try {
                        setReservationVoucherEmailSendingStatus('REQUESTED');
                        dispatch(
                          sendReservationStatusEmail(
                            reservation?.id ?? '',
                            email
                          )
                        );
                        setReservationVoucherEmailSendingStatus('SUCCEEDED');
                      } catch (e) {
                        setReservationVoucherEmailSendingStatus('FAILED');
                      }
                    }}
                  />
                </Box>
              )}
              {useHTMLVoucher && !isPartnershipComponent && (
                <Box mr={2}>
                  <Link
                    to={`/reservations/${reservation.id}/voucher`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['small'],
                        baseStyles['blue']
                      )}
                      target="_blank"
                    >
                      <p
                        className={clsx(
                          baseStyles['semantic-ui-react-override']
                        )}
                      >
                        {t('Voucher')}
                      </p>
                      <img src={newWindowIcon} />
                    </span>
                  </Link>
                </Box>
              )}
              {!isTerminalReservationStatus(currentStatus) &&
                isChangeReservationAvailable() &&
                !isPartnershipComponent && (
                  <Button
                    style="yellow"
                    size="small"
                    width={140}
                    disabled={['INVOICE_CHARGE_PENDING'].includes(
                      reservation.billing_info?.invoice_status
                    )}
                    onClick={onClickChangeReservation}
                  >
                    {t('Change Reservation')}
                  </Button>
                )}
            </div>
          </div>
          {(reservationVoucherEmailSendingStatus === 'SUCCEEDED' ||
            reservationCancellationEmailSendingStatus === 'SUCCEEDED') && (
            <Message success header={t('Send Successful')} />
          )}

          <div className={clsx(pageStyles['page-reservations__basicFrame'])}>
            <div>
              <table className={clsx(baseStyles['base-table'])}>
                <tbody>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Application Number')}
                    </th>
                    <td>{reservation.agent_reference}</td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Confirmation Number')}
                    </th>
                    <td>
                      <div
                        className={clsx(
                          pageStyles['page-reservations__reference']
                        )}
                      >
                        <div
                          className={clsx(
                            pageStyles['page-reservations__reference_text']
                          )}
                        >
                          {reservation.supplier_reference}
                        </div>
                        <div
                          className={clsx(
                            pageStyles['page-reservations__reference_btn']
                          )}
                        >
                          {userCanEditSupplierReference && (
                            <ReservationMainInformationUpdateModal
                              reservation={reservation}
                              trigger={
                                <a
                                  className={clsx(
                                    baseStyles['base-btn icon'],
                                    baseStyles['icon']
                                  )}
                                >
                                  <img src={editIcon} />
                                </a>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Participation Date')}
                    </th>
                    <td>{participatesAt.format('lll')}</td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Product Name')}
                    </th>
                    <td>
                      <p>{reservation?.internal_product_name}</p>
                      <p>({reservation?.product_name})</p>
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Add-ons')}
                    </th>
                    <td>{addOnSummary}</td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Transportation')}
                    </th>
                    <td>{transportationSummary}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table className={clsx(baseStyles['base-table'])}>
                <tbody>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Customer')}
                    </th>
                    <td>
                      {customerId ? (
                        <Link
                          to={`/customers/${customerId}`}
                          className={clsx(baseStyles['link'])}
                        >
                          {getCustomerNameDisplay(customerName)}
                        </Link>
                      ) : (
                        <>{getCustomerNameDisplay(customerName)}</>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Guest')}
                    </th>
                    <td>{guestSummary}</td>
                  </tr>
                  {config.enableSid && (
                    <tr>
                      <th className={clsx(baseStyles['base-t-160'])}>
                        {t('Landing Source')}
                      </th>
                      <td>
                        {reservation?.landing_sid ||
                          reservation?.landing_referrer}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Booking source')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationBookingSource'
                      ) &&
                        reservation.booking_source &&
                        (() => {
                          const bookingSource = reservation.booking_source;
                          const bookingSourceType = bookingSource?.source_type;
                          if (!bookingSourceType) {
                            return null;
                          }

                          const isResell =
                            reservation.contract_type === 'RESELL';
                          let bookingAgent = '';
                          if (bookingSource?.agent_name) {
                            if (
                              reservation.agent_name &&
                              reservation.agent_name !==
                                bookingSource.agent_name
                            ) {
                              // Prefix booking agent with contract agent name
                              // Ex: "H.I.S. Australia (HOPS)"
                              bookingAgent = `${reservation.agent_name} (${bookingSource.agent_name})`;
                            } else {
                              bookingAgent = bookingSource.agent_name;
                            }
                          }

                          if (bookingSourceType === 'AGENT') {
                            return (
                              <>
                                {(bookingAgent || '') +
                                  ` (${isResell ? t('resell') : t('agent')})`}
                              </>
                            );
                          }

                          if (bookingSourceType === 'GROUP') {
                            return <>{bookingSource?.group_name || ''}</>;
                          }

                          return <>{t(bookingSourceType)}</>;
                        })()}
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Payment Type')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationPaymentType'
                      ) &&
                        reservation.payment_type &&
                        (reservation.payment_type
                          ? t(reservation.payment_type)
                          : t('Unspecified'))}
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Payment Method')}
                    </th>
                    <td>
                      {operationAllowed(
                        activeUser,
                        'read',
                        'reservationPaymentType'
                      ) && <PaymentMethod reservation={reservation} />}
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Gross')}
                    </th>
                    <td>
                      {reservation.billing_info?.amount_gross ? (
                        formattedCurrencyAmount(
                          reservation.billing_info.amount_gross
                        )
                      ) : (
                        <LabelWithHelpText
                          text={t('Not confirmed')}
                          helpText={t(
                            'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
                          )}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {t('Net')}
                    </th>
                    <td>
                      {reservation.billing_info ? (
                        netTotal
                      ) : (
                        <LabelWithHelpText
                          text={t('Not confirmed')}
                          helpText={t(
                            'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
                          )}
                        />
                      )}
                    </td>
                  </tr>
                  {(reservation.status === 'CANCELED_BY_SUPPLIER' ||
                    reservation.status === 'CANCELED_BY_AGENT' ||
                    reservation.status == 'CANCELED_BY_GUEST') && (
                    <>
                      <tr>
                        <th className={clsx(baseStyles['base-t-160'])}>
                          {t('Cancellation Fee Gross')}
                        </th>
                        <td>
                          {reservation.billing_info
                            ?.amount_cancellation_fee_gross
                            ? formattedCurrencyAmount(
                                reservation.billing_info
                                  ?.amount_cancellation_fee_gross
                              )
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <th className={clsx(baseStyles['base-t-160'])}>
                          {t('Cancellation Fee Net')}
                        </th>
                        <td>
                          {reservation.billing_info?.amount_cancellation_fee_net
                            ? formattedCurrencyAmount(
                                reservation.billing_info
                                  ?.amount_cancellation_fee_net
                              )
                            : '-'}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
