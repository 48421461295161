import * as React from 'react';

import { Heading } from './Heading';
import { Section } from './util';
import { BaseSection } from './BaseSection';
import { Button } from './Button';

type Props = {
  data: Section;
};

export const Tiles = ({ data }: Props) => {
  const tiles = data.tile;

  if (!tiles) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <>
        <Heading
          title={tiles?.title}
          description={tiles?.description}
          titleFontFamily={tiles?.titleStyle?.fontFamily}
          titleColor={tiles?.titleStyle?.color}
          descriptionFontFamily={tiles?.descriptionStyle?.fontFamily}
          descriptionColor={tiles?.descriptionStyle?.color}
        />
        {tiles.style === 'SINGLE_PANE_CHECKBOARD' && (
          <tr>
            <td>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                }}
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  {tiles.items.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        width: '100%',
                      }}
                    >
                      {index % 2 === 0 ? (
                        <>
                          <td
                            style={{
                              width: '260px',
                            }}
                          >
                            <img
                              src={item.imageUrl ?? ''}
                              alt={item.text}
                              style={{
                                width: '100%',
                                height: '260px',
                                borderRadius:
                                  tiles.imageStyle === 'CIRCLE'
                                    ? '9999px'
                                    : '1em',
                                marginBottom: '1.5em',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </td>
                          <td style={{ width: '20px' }}>
                            <div></div>
                          </td>
                          <td style={{ width: '260px' }}>
                            <table
                              style={{
                                width: '100%',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <p
                                      style={{
                                        margin: 0,
                                        whiteSpace: 'pre-line',
                                      }}
                                    >
                                      {item.text}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ width: '260px' }}>
                            <table
                              style={{
                                width: '100%',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <p
                                      style={{
                                        margin: 0,
                                        whiteSpace: 'pre-line',
                                      }}
                                    >
                                      {item.text}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ width: '20px' }}>
                            <div></div>
                          </td>
                          <td style={{ width: '260px' }}>
                            <img
                              src={item.imageUrl ?? ''}
                              alt={item.text}
                              style={{
                                width: '100%',
                                height: '260px',
                                borderRadius:
                                  tiles.imageStyle === 'CIRCLE'
                                    ? '9999px'
                                    : '1em',
                                marginBottom: '1.5em',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {/* Square aspect ratio for stagger
        600px - 20px - 20px = 560px (width of a row)
        560px - 20px = 520px (width for 2 pane)
        image = 220px, text = 300px */}
        {tiles.style === 'ONE_PANE_STAGGER' && (
          <tr>
            <td>
              <table
                style={{
                  width: '100%',
                }}
              >
                <tbody
                  style={{
                    width: '100%',
                  }}
                >
                  {tiles.items.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        width: '100%',
                      }}
                    >
                      <td
                        style={{
                          width: '220px',
                        }}
                      >
                        <img
                          src={item.imageUrl ?? ''}
                          alt={item.text}
                          style={{
                            width: '100%',
                            height: '220px',
                            borderRadius:
                              tiles.imageStyle === 'CIRCLE' ? '9999px' : '1em',
                            marginBottom:
                              index === tiles.items.length - 1
                                ? undefined
                                : '1.5em',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            marginLeft: index % 2 === 0 ? undefined : '64px',
                          }}
                        />
                      </td>
                      <td style={{ width: '20px' }}>
                        <div></div>
                      </td>
                      <td
                        style={{
                          width: '300px',
                        }}
                      >
                        <table
                          style={{
                            width: '100%',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'left' }}>
                                <p
                                  style={{
                                    margin: 0,
                                    marginLeft:
                                      index % 2 === 0 ? undefined : '64px',
                                    marginRight:
                                      index % 2 === 0 ? '64px' : undefined,
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {item.text}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {/* Square aspect ratio for 3 pane
        600px - 20px - 20px = 560px (width of a row)
        560px - 20px - 20px = 500px (width for 3 pane)
        500px / 3 = 166.6667px (width for each image in pane) */}
        {tiles.style === 'THREE_PANE' && (
          <tr>
            <td>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                  {Array.from({
                    length: Math.ceil(tiles.items.length / 3),
                  }).map((_, rowIndex) => (
                    <>
                      <tr key={rowIndex * 2}>
                        {tiles.items
                          .slice(rowIndex * 3, rowIndex * 3 + 3)
                          .map((item, index) => (
                            <>
                              <td
                                key={index}
                                style={{
                                  width: '166.6667px',
                                  padding: '0',
                                  verticalAlign: 'top',
                                }}
                              >
                                <div
                                  style={{
                                    height: 'auto',
                                    width: '100%',
                                    borderRadius:
                                      tiles.imageStyle === 'CIRCLE'
                                        ? '9999px'
                                        : '1em',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    src={item.imageUrl}
                                    style={{
                                      top: '0',
                                      left: '0',
                                      height: '166.6667px',
                                      width: '100%',
                                      objectFit: 'cover',
                                      objectPosition: 'center',
                                      display: 'block',
                                    }}
                                  />
                                </div>
                                <p
                                  style={{
                                    margin: 0,
                                    marginTop: '16px',
                                    whiteSpace: 'pre-line',
                                  }}
                                >
                                  {item.text}
                                </p>
                              </td>
                              {index < 2 && (
                                <td style={{ width: '20px', padding: '0' }} />
                              )}
                            </>
                          ))}
                        {tiles.items.length % 3 !== 0 &&
                          rowIndex ===
                            Math.ceil(tiles.items.length / 3) - 1 && (
                            <td
                              colSpan={3 - (tiles.items.length % 3) * 2}
                              style={{ width: '166.6667px' }}
                            >
                              &nbsp;
                            </td>
                          )}
                      </tr>
                      {rowIndex < Math.ceil(tiles.items.length / 3) - 1 && (
                        <tr key={rowIndex * 2 + 1}>
                          <td colSpan={5} style={{ height: '20px' }}>
                            &nbsp;
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {/* Width of row = 560px
        First image column = 360px, height = 240px
        Second image column 560 - 360 + 20 = 180px, height = 120px */}
        {tiles.style === 'ONE_PANE_THREE_IMAGE' && (
          <tr>
            <td>
              <table
                style={{
                  marginBottom: '24px',
                  marginLeft: '4px',
                  marginRight: '4px',
                  borderCollapse: 'collapse',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <img
                        src={tiles.items[0].imageUrl}
                        style={{
                          objectFit: 'cover',
                          objectPosition: 'center',
                          zIndex: 0,
                          width: '364px',
                          borderRadius: '16px',
                          height: '240px',
                          display: 'block',
                        }}
                      />
                    </td>
                    <td style={{ width: '20px' }}>
                      <div></div>
                    </td>
                    <td style={{ width: '20px' }}>
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <img
                                src={tiles.items[1].imageUrl}
                                style={{
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                  zIndex: 0,
                                  width: 'auto',
                                  borderRadius: '16px',
                                  height: '110px',
                                  display: 'block',
                                }}
                              />
                            </td>
                          </tr>
                          <tr style={{ height: '20px' }}>
                            <div></div>
                          </tr>
                          <tr>
                            <td>
                              <img
                                src={tiles.items[2].imageUrl}
                                style={{
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                  zIndex: 0,
                                  width: 'auto',
                                  borderRadius: '16px',
                                  height: '110px',
                                  display: 'block',
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}
        {tiles.style === 'ONE_PANE_THREE_IMAGE' && (
          <tr>
            <td>
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#525252',
                  margin: '0',
                  whiteSpace: 'pre-line',
                }}
              >
                {tiles.imageText}
              </p>
            </td>
          </tr>
        )}
        <Button buttonUrl={tiles.buttonUrl} buttonText={tiles.buttonText} />
      </>
    </BaseSection>
  );
};
