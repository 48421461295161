import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { LabelWithHelpText } from 'client/components/LabelWithHelpText';
import { Modal } from 'client/components/Modal/Modal';
import { Select, FieldWrapper, Checkbox } from 'client/components/Form';
import type { GroupDiscount } from 'shared/models/swagger';

type Props = {
  groupDiscount: GroupDiscount;
  onGroupDiscountChange: (newGroupDiscount: GroupDiscount) => void;
  guestTypeKeys: string[];
};
export const GroupDiscountInput = ({
  groupDiscount,
  guestTypeKeys,
  onGroupDiscountChange,
}: Props) => {
  const { t } = useTranslation();
  const eligibleGuestTypeKeys =
    (groupDiscount && groupDiscount.eligible_guest_type_keys) || [];
  const minGuestCount = (groupDiscount && groupDiscount.min_guest_count) || 1;
  return (
    <>
      <Modal.Box>
        <Select
          label={
            <LabelWithHelpText
              text={t('Group Size')}
              helpText={t(
                'Number of participants needed for a booking to qualify. For example, if group size is 5, bookings of 5 or more eligible participants will qualify.'
              )}
            />
          }
          value={minGuestCount as any}
          options={
            _.range(1, 20).map((v) => ({
              key: v,
              value: v,
              text: v,
            })) as any
          }
          onChange={(e, { value }: any) => {
            onGroupDiscountChange({ ...groupDiscount, min_guest_count: value });
          }}
        />
      </Modal.Box>

      <Modal.Box>
        <FieldWrapper
          label={
            <LabelWithHelpText
              text={t('Eligible units')}
              helpText={t(
                'Units that will be counted to determine if a booking qualifies'
              )}
            />
          }
        >
          {guestTypeKeys.map((guestTypeKey) => {
            const checked = eligibleGuestTypeKeys.indexOf(guestTypeKey) !== -1;
            return (
              <Checkbox
                key={guestTypeKey}
                label={guestTypeKey}
                name={guestTypeKey}
                value={guestTypeKey}
                checked={checked}
                disabled={checked && eligibleGuestTypeKeys.length === 1}
                onChange={() => {
                  const newEligibleGuestTypeKeys =
                    eligibleGuestTypeKeys.indexOf(guestTypeKey) !== -1
                      ? eligibleGuestTypeKeys.filter(
                          (existingKey) => guestTypeKey !== existingKey
                        )
                      : [...eligibleGuestTypeKeys, guestTypeKey];
                  onGroupDiscountChange({
                    ...groupDiscount,
                    eligible_guest_type_keys: newEligibleGuestTypeKeys.filter(
                      (key) => guestTypeKeys.includes(key)
                    ),
                  });
                }}
              />
            );
          })}
        </FieldWrapper>
      </Modal.Box>
    </>
  );
};
