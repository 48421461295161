import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type {
  Product,
  ProductSummary,
  Reservation,
} from 'shared/models/swagger';

type ProductSummaryWithBookmark = ProductSummary & {
  bookmarked: boolean;
};

export const getDisplayProductName = (
  product: Product | ProductSummary | null | undefined
): string => {
  return product?.internal_product_name ?? product?.product_name ?? '';
};

export const getDisplayProductNameForReservation = (
  reservation: Reservation | ReservationSummaryShape | null | undefined
): string => {
  return reservation?.internal_product_name ?? reservation?.product_name ?? '';
};

export const getVerboseDisplayProductName = (
  product:
    | Product
    | ProductSummary
    | ProductSummaryWithBookmark
    | null
    | undefined
): string => {
  if (product?.product_name == product?.internal_product_name) {
    return product?.product_name ?? '';
  }
  return `${product?.internal_product_name ?? ''} (${
    product?.product_name ?? ''
  })`;
};

export const getProductEditorDisplayProductName = (
  product: Product | ProductSummary | null | undefined
): string => {
  if (!product) {
    return '';
  }

  return `${product?.internal_product_name ?? ''} [${
    product?.supplier_reference ?? ''
  }]`;
};
