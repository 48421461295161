import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { sendInquiryBulkEmail } from 'client/actions/inquiries';

import { FormValues, convertFormValuesToSwagger } from './formValues';
import { ReservationSelect } from './ReservationSelect';
import { ComposeMessage } from './ComposeMessage';
import styles from './BulkEmailNotification.module.css';
import { Confirm } from './Confirm';
import { Complete } from './Complete';

type Step = 'RESERVATION_SELECT' | 'MESSAGE' | 'CONFIRM' | 'COMPLETE';

const initialValues = {
  reservations: [],
  subject: '',
  description: '',
  attachments: [],
};

export const BulkEmailNotification = () => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<Step>('RESERVATION_SELECT');

  const dispatch = useDispatch();

  if (
    step === 'RESERVATION_SELECT' ||
    step === 'MESSAGE' ||
    step === 'CONFIRM' ||
    step === 'COMPLETE'
  ) {
    return (
      <>
        {(step === 'RESERVATION_SELECT' ||
          step === 'MESSAGE' ||
          step === 'CONFIRM') && (
          <ul className={styles['steps-nav']}>
            <li
              className={clsx(
                step === 'RESERVATION_SELECT' && styles['is-active']
              )}
            >
              STEP1<span> - {t('Select Reservations')}</span>
            </li>
            <li className={clsx(step === 'MESSAGE' && styles['is-active'])}>
              STEP2<span> - {t('Compose Message')}</span>
            </li>
            <li className={clsx(step === 'CONFIRM' && styles['is-active'])}>
              STEP3<span> - {t('Confirm & Send')}</span>
            </li>
          </ul>
        )}
        <Form
          onSubmit={async (values: FormValues) => {
            switch (step) {
              case 'RESERVATION_SELECT':
                setStep('MESSAGE');
                break;
              case 'MESSAGE':
                setStep('CONFIRM');
                break;
              case 'CONFIRM':
                await dispatch(
                  sendInquiryBulkEmail(convertFormValuesToSwagger(values))
                );
                setStep('COMPLETE');
                break;
            }
          }}
          initialValues={initialValues}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {step === 'RESERVATION_SELECT' && <ReservationSelect />}
              {step === 'MESSAGE' && (
                <ComposeMessage
                  onBackClick={() => setStep('RESERVATION_SELECT')}
                />
              )}
              {step === 'CONFIRM' && (
                <Confirm
                  loading={submitting}
                  onBackClick={() => setStep('MESSAGE')}
                />
              )}
              {step === 'COMPLETE' && <Complete />}
            </form>
          )}
        </Form>
      </>
    );
  }

  // We shouldn't reach here
  return null;
};
