/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import { Form } from 'react-final-form';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import {
  createRestaurant,
  fetchRestaurant,
  updateRestaurant,
} from 'client/actions/restaurants';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';
import { getArrayMutators } from 'client/libraries/util/form';

import { ActionsFooter } from '../ActionsFooter/ActionsFooter';

import { Details } from './Details';
import { MediaItems } from './MediaItems';
import { Hours } from './Hours';
import styles from './RestaurantEditor.module.css';
import {
  FormValues,
  convertFormValuesToSwagger,
  getInitialValues,
} from './formValues';
import { MenuButton } from './MenuButton';

export const RestaurantEditor: React.FC = () => {
  const { t } = useTranslation();
  const restaurantId = useParams<{ id: string }>().id;
  const dispatch = useDispatch();

  const existingRestaurant = useSelector((state: ReduxState) =>
    state.restaurants.all?.find((r) => r.id === restaurantId)
  );
  const loading = useSelector((state: ReduxState) => state.restaurants.loading);
  const pageTitle = restaurantId ? t('Edit Restaurant') : t('Add Restaurant');

  const history = useHistory();

  React.useEffect(() => {
    if (restaurantId) {
      dispatch(fetchRestaurant(restaurantId));
    }
  }, [restaurantId, dispatch]);

  const initialValues: FormValues = React.useMemo((): FormValues => {
    return getInitialValues(existingRestaurant || null);
  }, [existingRestaurant]);

  if (loading) {
    return <Loading size="lg" />;
  }

  return (
    <V3Page>
      <PageHeader
        title={pageTitle}
        breadcrumbPaths={[
          { text: t('Restaurants'), url: '/mobileorders/restaurants' },
          { text: pageTitle, url: '' },
        ]}
      />
      <Form
        initialValues={initialValues}
        onSubmit={(values: FormValues) => {
          if (restaurantId) {
            dispatch(
              updateRestaurant(restaurantId, convertFormValuesToSwagger(values))
            );
          } else {
            // dispatch create action
            dispatch(createRestaurant(convertFormValuesToSwagger(values)));
          }

          history.push('/mobileorders/restaurants');
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <PageContent>
              <section className={styles.content}>
                <Details />
                <Hours />
                <MediaItems />
                <MenuButton />
              </section>
            </PageContent>
            <ActionsFooter loading={submitting} />
          </form>
        )}
      </Form>
    </V3Page>
  );
};
