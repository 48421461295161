import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { Button, Checkbox } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { fetchNewsletterSegments } from 'client/actions/newsletterSegment';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import styles from './ComposeMessage.module.css';

interface Props {
  onBackClick: () => void;
  onSave: () => void;
}

export const TargetSelect = ({ onBackClick, onSave }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const loading = useSelector((state: ReduxState) => state.newsletters.loading);

  React.useEffect(() => {
    dispatch(fetchNewsletterSegments());
  }, []);

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button style="gray" size="middle" onClick={onBackClick}>
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} onClick={onSave} style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="green" size="middle">
            {t('Continue to Step 4')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Targets')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <FormTableBox>
            <table>
              <tbody>
                <tr>
                  <th>{t('Segments')}</th>
                  <td>
                    <Field name="segments">
                      {({ input, meta: { touched, error } }) => (
                        <>
                          {segments.map((segment) => (
                            <Checkbox
                              key={segment.id}
                              label={`${segment.name} (${
                                segment.customer_count ?? '-'
                              })`}
                              checked={input.value?.includes(segment.id)}
                              onChange={() => {
                                if (input.value?.includes(segment.id)) {
                                  input.onChange(
                                    input.value.filter(
                                      (id: string) => id !== segment.id
                                    )
                                  );
                                } else {
                                  input.onChange([...input.value, segment.id]);
                                }
                              }}
                            />
                          ))}
                          {touched && error && (
                            <p className={baseStyles['base-form-box__err']}>
                              {error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
        </div>
      </div>
    </div>
  );
};
