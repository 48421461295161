import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';
import { compose } from 'recompose';

import { getAllChannels } from 'client/pages/EditProductAvailabilityAllotment/ProductInstanceEditCalendar/util';
import {
  getInstanceDedicatedAllotments,
  getInstanceClosedChannels,
  getInstancePerChannelInfo,
  productInstanceChannelsAreClosed,
  productInstanceAllChannelsAreClosed,
  productInstanceAllChannelSlotsAreFull,
  productInstanceCloseAllAgentChannels,
  productInstanceOpenAllAgentChannels,
  productInstanceFillAllChannelSlots,
} from 'client/libraries/util/util';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { Checkbox, TextArea } from 'client/components/Form';
import type { ProductInstance, Product } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { PreventAutomaticAllotmentChangesCheckbox } from './PreventAutomaticAllotmentChangesCheckbox';
import 'client/pages/EditProductAvailabilityAllotment/ProductInstanceEditCalendar/styles.css';

type OwnProps = {
  product: Product | null;
  oldProductInstance: ProductInstance;
  newProductInstance: ProductInstance;
  handleProductInstanceChange: (arg0: ProductInstance) => void;
};

type Props = OwnProps & WithTranslation;

const printDifference = (x1?: number, x2?: number) => {
  if (!x2 || !x1) {
    return '';
  }

  if (x1 > x2) {
    return `(-${x1 - x2})`;
  } else if (x2 > x1) {
    return `(+${x2 - x1})`;
  }

  return '';
};

class ProductInstanceEditInputComponent extends React.PureComponent<Props> {
  render() {
    const {
      oldProductInstance,
      newProductInstance,
      handleProductInstanceChange,
    } = this.props;

    // Workaround react-i18next Typescript problem
    const { t } = this.props as any;

    const { prevent_allotment_changes_when_reprinting } = newProductInstance;
    const oldDedicatedAllotments =
      getInstanceDedicatedAllotments(oldProductInstance);
    const newDedicatedAllotments =
      getInstanceDedicatedAllotments(newProductInstance);
    const bookedSum =
      _.sumBy(oldDedicatedAllotments, (p) => p.occupied_slots || 0) +
      (oldProductInstance.occupied_slots || 0);
    const oldAllotmentSum =
      _.sumBy(oldDedicatedAllotments, (p) => p.total_slots || 0) +
      (oldProductInstance.total_slots || 0);
    const newAllotmentSum =
      _.sumBy(newDedicatedAllotments, (p) => p.total_slots || 0) +
      (newProductInstance.total_slots || 0);
    const allChannels = getAllChannels(this.props.product).filter((ch) => {
      if (ch.channel_category === 'DIRECT_ALL') {
        const oldAllotment = oldDedicatedAllotments.find((a) =>
          ch.channel_category === 'AGENT'
            ? a.agent_id === ch.agent_id
            : a.channel_category === ch.channel_category
        );
        const newAllotment = newDedicatedAllotments.find((a) =>
          ch.channel_category === 'AGENT'
            ? a.agent_id === ch.agent_id
            : a.channel_category === ch.channel_category
        );
        const newTotal = newAllotment && (newAllotment.total_slots || 0);
        const oldTotal = oldAllotment && (oldAllotment.total_slots || 0);
        const occupied = oldAllotment && (oldAllotment.occupied_slots || 0);

        if (
          newTotal === undefined &&
          oldTotal === undefined &&
          occupied === undefined
        ) {
          return false;
        }
      }

      return true;
    });
    const channels: {
      category?: string;
      agentID?: string;
      agentName?: string;
      newTotal?: number;
      oldTotal?: number;
      occupied?: number;
    }[] = allChannels.map((ch) => {
      if (ch.channel_category === 'COMMON') {
        return {
          category: 'COMMON',
          newTotal: newProductInstance.total_slots || 0,
          oldTotal: oldProductInstance.total_slots || 0,
          occupied: oldProductInstance.occupied_slots || 0,
        };
      }

      const oldAllotment = oldDedicatedAllotments.find((a) =>
        ch.channel_category === 'AGENT'
          ? a.agent_id === ch.agent_id
          : a.channel_category === ch.channel_category
      );
      const newAllotment = newDedicatedAllotments.find((a) =>
        ch.channel_category === 'AGENT'
          ? a.agent_id === ch.agent_id
          : a.channel_category === ch.channel_category
      );
      const newTotal = newAllotment && (newAllotment.total_slots || 0);
      const oldTotal = oldAllotment && (oldAllotment.total_slots || 0);
      const occupied = oldAllotment && (oldAllotment.occupied_slots || 0);
      return {
        category: ch.channel_category,
        agentID: ch.agent_id,
        agentName: ch.agent_name,
        newTotal,
        oldTotal,
        occupied,
      };
    });
    const agentChannels = allChannels.filter(
      (ch) =>
        ch.channel_category === 'AGENT' || ch.channel_category === 'RESELLER'
    );
    const allChannelsAreClosed =
      productInstanceAllChannelsAreClosed(newProductInstance);
    const allAgentsAreClosed = productInstanceChannelsAreClosed(
      newProductInstance,
      agentChannels
    );
    const allChannelSlotsAreFull =
      productInstanceAllChannelSlotsAreFull(newProductInstance);
    return (
      <>
        <div className={clsx(componentStyles['c-table-manifests'])}>
          <div className={clsx(componentStyles['c-table-manifests__tbody'])}>
            <Checkbox
              label={t('Close all channels')}
              checked={allChannelsAreClosed}
              onChange={() => {
                handleProductInstanceChange({
                  ...newProductInstance,
                  per_channel_info: {
                    ...getInstancePerChannelInfo(newProductInstance),
                    all_channels_are_closed: !allChannelsAreClosed,
                    closed_channels: [],
                  },
                });
              }}
            />
            <Checkbox
              label={t('Close all agents')}
              disabled={allChannelsAreClosed}
              checked={allAgentsAreClosed}
              onChange={() => {
                handleProductInstanceChange(
                  allAgentsAreClosed
                    ? productInstanceOpenAllAgentChannels(newProductInstance)
                    : productInstanceCloseAllAgentChannels(
                        newProductInstance,
                        agentChannels
                      )
                );
              }}
            />
            <Checkbox
              label={t('Close instant booking for all channels')}
              checked={allChannelSlotsAreFull}
              disabled={allChannelSlotsAreFull || allChannelsAreClosed}
              onChange={() => {
                handleProductInstanceChange(
                  productInstanceFillAllChannelSlots(newProductInstance)
                );
              }}
            />

            <table
              className={clsx(
                componentStyles['c-table-manifests__tbody__body']
              )}
            >
              <thead>
                <tr
                  className={clsx(
                    componentStyles['c-table-manifests__tbody__header']
                  )}
                >
                  <th>
                    <Popup
                      trigger={
                        <span className="info-trigger">{t('Closed')}</span>
                      }
                    >
                      {t(
                        'No bookings will be allowed through a "closed" channel regardless of remaining allotments.'
                      )}
                    </Popup>
                  </th>
                  <th> {t('Channel Name')} </th>
                  <th>
                    <Popup
                      trigger={
                        <span className="info-trigger">{t('Current')}</span>
                      }
                    >
                      {t('(# booked) / allotment')}
                    </Popup>
                  </th>
                  <th> {t('New allotment')} </th>
                  <th>
                    <Popup
                      trigger={
                        <span className="info-trigger">{t('Result')}</span>
                      }
                    >
                      {t('(# booked) / allotment')}
                    </Popup>
                  </th>
                </tr>
              </thead>
              <tbody>
                {channels.map((channel, idx) => {
                  const channelIsClosed =
                    allChannelsAreClosed ||
                    !!getInstanceClosedChannels(newProductInstance).find(
                      (c) =>
                        (channel.agentID || channel.category) ===
                        (c.agent_id || c.channel_category)
                    );
                  return (
                    <tr key={idx}>
                      <td
                        data-text={t('Closed')}
                        style={{ textAlign: 'center' }}
                      >
                        <Checkbox
                          disabled={allChannelsAreClosed}
                          checked={channelIsClosed}
                          onChange={() => {
                            const s = new Set(
                              getInstanceClosedChannels(newProductInstance).map(
                                (c) => c.agent_id || c.channel_category
                              )
                            );
                            const value = channel.agentID || channel.category;

                            if (s.has(value)) {
                              s.delete(value);
                            } else {
                              s.add(value);
                            }

                            const closed_channels = allChannels
                              .filter((c) =>
                                s.has(c.agent_id || c.channel_category)
                              )
                              .map((c) => ({ ...c }));
                            handleProductInstanceChange({
                              ...newProductInstance,
                              per_channel_info: {
                                ...getInstancePerChannelInfo(
                                  newProductInstance
                                ),
                                all_channels_are_closed:
                                  allChannels.length === closed_channels.length,
                                closed_channels,
                              },
                            });
                          }}
                        />
                      </td>
                      <td data-text={t('Channel Name')}>
                        {channel.category === 'AGENT'
                          ? channel.agentName
                          : t(channel.category)}
                      </td>
                      {channel.occupied !== undefined &&
                      channel.oldTotal !== undefined &&
                      channel.newTotal !== undefined ? (
                        <>
                          <td
                            data-text={t('Current')}
                            style={{ textAlign: 'center' }}
                          >
                            {`${
                              channel.occupied || 0
                            } / ${formattedTotalAllotmentSlots(
                              channel.oldTotal || 0
                            )}`}
                          </td>
                          <td
                            data-text={t('New allotment')}
                            style={{ textAlign: 'center' }}
                          >
                            <div className={baseStyles['base-form-box']}>
                              <div
                                className={baseStyles['base-form-box__body']}
                              >
                                <label
                                  className={baseStyles['base-form-select']}
                                >
                                  <select
                                    value={channel.newTotal}
                                    disabled={channelIsClosed}
                                    onChange={(e) => {
                                      let total_slots = 0;

                                      if (e.target.value.length > 0) {
                                        total_slots = parseInt(
                                          e.target.value,
                                          10
                                        );
                                      }

                                      if (isNaN(total_slots)) {
                                        total_slots = 0;
                                      }

                                      if (channel.category === 'COMMON') {
                                        handleProductInstanceChange({
                                          ...newProductInstance,
                                          total_slots,
                                        });
                                      } else {
                                        handleProductInstanceChange({
                                          ...newProductInstance,
                                          per_channel_info: {
                                            ...getInstancePerChannelInfo(
                                              newProductInstance
                                            ),
                                            dedicated_allotments: (
                                              newDedicatedAllotments || []
                                            ).map((a) => {
                                              if (
                                                channel.category === 'AGENT'
                                              ) {
                                                return channel.agentID ===
                                                  a.agent_id
                                                  ? { ...a, total_slots }
                                                  : a;
                                              }

                                              return channel.category ===
                                                a.channel_category
                                                ? { ...a, total_slots }
                                                : a;
                                            }),
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    {[...Array(2001)].map((_, idx) => {
                                      return (
                                        <option key={idx} value={idx}>
                                          {idx}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td
                            data-text={t('Result')}
                            style={{
                              color:
                                channel.newTotal !== channel.oldTotal
                                  ? 'red'
                                  : undefined,
                              textAlign: 'center',
                            }}
                          >
                            {`${
                              channel.occupied || 0
                            } / ${formattedTotalAllotmentSlots(
                              channel.newTotal || 0
                            )} ${printDifference(
                              channel.oldTotal,
                              channel.newTotal
                            )}`}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            data-text={t('Current')}
                            style={{ textAlign: 'center' }}
                          >
                            --
                          </td>
                          <td
                            data-text={t('New allotment')}
                            style={{ textAlign: 'center' }}
                          >
                            --
                          </td>
                          <td
                            data-text={t('Result')}
                            style={{ textAlign: 'center' }}
                          >
                            --
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td data-text={'-'} />
                  <td data-text={t('Channel Name')}>{t('Total')}</td>
                  <td
                    data-text={t('Current')}
                    style={{ textAlign: 'center' }}
                  >{`${bookedSum} / ${formattedTotalAllotmentSlots(
                    oldAllotmentSum
                  )}`}</td>
                  <td
                    data-text={t('New allotment')}
                    style={{ textAlign: 'center' }}
                  >
                    {newAllotmentSum}
                  </td>
                  <td
                    data-text={t('Result')}
                    style={{ textAlign: 'center' }}
                  >{`${bookedSum} / ${formattedTotalAllotmentSlots(
                    newAllotmentSum
                  )} ${printDifference(oldAllotmentSum, newAllotmentSum)}`}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <TextArea
            label={t('Memo')}
            value={newProductInstance?.memo?.value ?? ''}
            height={100}
            onChange={(e, { value }) => {
              handleProductInstanceChange({
                ...newProductInstance,
                memo: { value: value },
              });
            }}
          />
        </div>
        <div>
          <PreventAutomaticAllotmentChangesCheckbox
            originalValue={Boolean(
              oldProductInstance.prevent_allotment_changes_when_reprinting
            )}
            checked={Boolean(prevent_allotment_changes_when_reprinting)}
            onChange={() => {
              handleProductInstanceChange({
                ...newProductInstance,
                prevent_allotment_changes_when_reprinting:
                  !newProductInstance.prevent_allotment_changes_when_reprinting,
              });
            }}
          />
        </div>
      </>
    );
  }
}

export const ProductInstanceEditInput = compose<Props, OwnProps>(
  withTranslation()
)(ProductInstanceEditInputComponent);
