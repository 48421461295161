import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation, TFunction } from 'react-i18next';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ReduxState } from 'client/reducers';
import { Button, Select } from 'client/components/Form';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import type { ProductSummary } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

type OwnProps = {
  candidateProducts?: ProductSummary[];
  disabled?: boolean;
  label: string;
  products: (ProductSummary | null | undefined)[];
  onChange: (arg0: (ProductSummary | null | undefined)[]) => void;
  placeholder?: string;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps & ReturnType<typeof mapStateToProps> & I18nProps;
/* eslint-enable no-use-before-define */

class ProductListInputComponent extends React.Component<Props> {
  render() {
    const { productsToChoose, label, onChange, products, t } = this.props;
    return (
      <div className={baseStyles['base-form-box']}>
        {label && (
          <div className={baseStyles['base-form-box__header']}>{label}</div>
        )}
        {products &&
          products.map((currentValue, currentValueIdx) => (
            <div
              key={currentValueIdx}
              className={clsx(baseStyles['inline-block'])}
            >
              <Select
                search
                options={productsToChoose
                  .filter(
                    // Does not allow choosing duplicate
                    (p) => (p && p === currentValue) || !products.includes(p)
                  )
                  .map((p) => ({
                    value: p.id,
                    text: getVerboseDisplayProductName(p),
                  }))}
                value={(currentValue && currentValue.id) || ''}
                onChange={(e, { value: newItemId }) => {
                  const newItem = productsToChoose.find(
                    (p) => p.id === newItemId
                  );
                  onChange(
                    products.map((p, idx) =>
                      idx === currentValueIdx ? newItem : p
                    )
                  );
                }}
              />

              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['icon'],
                  baseStyles['inline']
                )}
                onClick={() => {
                  const newProducts = [...products];
                  newProducts.splice(currentValueIdx, 1);
                  onChange(newProducts);
                }}
              >
                <img src={deleteIcon} />
              </a>
            </div>
          ))}

        {products && (
          <div className={clsx(baseStyles['list-add-btn'])}>
            <Button
              style="green"
              size="middle"
              onClick={() => {
                const newProducts = [...products];
                newProducts.splice(products.length + 1, 0, null);
                onChange(newProducts);
              }}
            >
              {t('Add')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  loading: state.products.loading,
  productsToChoose: ownProps.candidateProducts
    ? ownProps.candidateProducts
    : summariesWithBookmarksSelector(state),
  productError: state.products.error,
});

export const ProductListInput = compose<Props, OwnProps>(
  connect(mapStateToProps, null),
  withTranslation()
)(ProductListInputComponent);
