import * as React from 'react';

interface ActiveOrderContextType {
  activeOrderId: string;
  setActiveOrderId: (orderId: string) => void;
}

export const ActiveOrderContext = React.createContext<ActiveOrderContextType>({
  activeOrderId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveOrderId: () => {},
});
