import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSurveySubmissions } from 'client/actions/surveySubmissions';
import { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';

import { useSurveyColumns } from './util';

export const SubmittedSurveyList = () => {
  const columns = useSurveyColumns();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchSurveySubmissions({}));
  }, []);

  const surveySubmissions = useSelector(
    (state: ReduxState) => state.surveySubmissions.summaries
  );
  return (
    <CustomTable items={surveySubmissions} columns={columns} usePaging={true} />
  );
};
