import moment from 'moment-timezone';

export type ResourceType = '' | 'vehicle' | 'crew' | 'other' | 'guide';
export type ManifestDisplaySettings = {
  manifestType: string;
  rootResource: {
    id: string;
    type: 'PRODUCT' | 'GROUP' | 'ALL_PRODUCTS';
  };
  participationDate: string;
  showCheckinCheckout: boolean;
  showDispatchColumn: boolean;
  showByProductGroup: boolean;
  resourceType: ResourceType;
  openSummary: boolean;
};
export const initialSettings: ManifestDisplaySettings = {
  manifestType: 'DRIVER',
  rootResource: {
    id: 'ALL_PRODUCTS',
    type: 'ALL_PRODUCTS',
  },
  participationDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  showCheckinCheckout: true,
  showDispatchColumn: false,
  showByProductGroup: true,
  resourceType: '',
  openSummary: true,
};
