import {
  convertToRelativeDeadline,
  convertToSwaggerRelativeDateTime,
  RelativeDeadline,
} from 'client/libraries/util/productShape';
import {
  RealtimeNotificationTemplate,
  RealtimeNotificationTemplateParams,
} from 'shared/models/swagger';

export interface FormValues {
  title: string;

  status: 'DRAFT' | 'ACTIVE';

  deliveryMedium: 'WEB_PUSH' | 'EMAIL' | 'SMS';

  /* CONTENT */

  contentTitle: string;
  contentBody: string;
  contentLinkUrl: string;
  contentImageUrl: string;
  contentIconUrl: string;
  contentBadgeUrl: string;

  triggerType: 'ONE_TIME' | 'SCHEDULED' | 'EVENT';
  timingType?: 'START_TIME_RELATIVE';
  timingParticipationRelativeDeadline?: RelativeDeadline;

  productIds: string[];
  startDateLocalFrom: string;
  startDateLocalTo: string;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): RealtimeNotificationTemplateParams => {
  return {
    title: values.title,
    status: values.status,
    content: {
      title: values.contentTitle,
      body: values.contentBody,
      link_url: values.contentLinkUrl,
      image_url: values.contentImageUrl,
      icon_url: values.contentIconUrl,
      badge_url: values.contentBadgeUrl,
    },
    trigger: {
      trigger_type: values.triggerType,
      timing: {
        timing_type: values.timingType,
        participation_relative_date_time:
          values.timingParticipationRelativeDeadline
            ? convertToSwaggerRelativeDateTime(
                values.timingParticipationRelativeDeadline
              )
            : undefined,
      },
    },
    conditions: {
      product_ids: values.productIds,
      start_date_local_from: values.startDateLocalFrom,
      start_date_local_to: values.startDateLocalTo,
    },
    medium: values.deliveryMedium ?? 'WEB_PUSH',
  };
};

export const convertSwaggerToFormValues = (
  notificationTemplate: RealtimeNotificationTemplate
): FormValues => {
  return {
    title: notificationTemplate.title ?? '',
    status: notificationTemplate.status ?? 'DRAFT',
    contentTitle: notificationTemplate.content?.title ?? '',
    contentBody: notificationTemplate.content?.body ?? '',
    contentLinkUrl: notificationTemplate.content?.link_url ?? '',
    contentImageUrl: notificationTemplate.content?.image_url ?? '',
    contentIconUrl: notificationTemplate.content?.icon_url ?? '',
    contentBadgeUrl: notificationTemplate.content?.badge_url ?? '',
    triggerType: notificationTemplate.trigger?.trigger_type ?? 'ONE_TIME',
    timingType:
      notificationTemplate.trigger?.timing?.timing_type ??
      'START_TIME_RELATIVE',
    timingParticipationRelativeDeadline: notificationTemplate.trigger?.timing
      ?.participation_relative_date_time
      ? convertToRelativeDeadline(
          notificationTemplate.trigger?.timing?.participation_relative_date_time
        )
      : undefined,
    productIds: notificationTemplate.conditions?.product_ids ?? [],
    startDateLocalFrom:
      notificationTemplate.conditions?.start_date_local_from ?? '',
    startDateLocalTo:
      notificationTemplate.conditions?.start_date_local_to ?? '',
    deliveryMedium: notificationTemplate.medium ?? 'WEB_PUSH',
  };
};
