import * as React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TranslateFuncType } from 'client/components/Translate';
import { Product } from 'shared/models/swagger';
import { EditingProductContext } from 'client/contexts/EditingProductContext';

import styles from './AdjustmentScheduleTable.module.css';
import {
  PriceAdjustmentSchedule,
  PriceAdjustmentCondition,
  PriceAdjustmentAction,
  Channel,
} from './formValues';
import { getDeadlineDescription } from './util';

const getConditionText = (
  condition: PriceAdjustmentCondition,
  product: Product | null,
  t: TranslateFuncType
): string => {
  const { valueType, operator, value, appliesTo, channel } = condition;

  const occupancyCategory =
    appliesTo === 'TOTAL'
      ? t('Total occupancy')
      : channel?.channelCategory === 'AGENT'
      ? t('{{occupancyType}} occupancy', {
          occupancyType: product?.agents?.find(
            (agent) => agent.id === channel?.agentId
          )?.name,
        })
      : channel?.channelCategory === 'COMMON'
      ? t('Common')
      : t('Direct');
  const occupancyValue = valueType === 'OCCUPANCY_COUNT' ? value : `${value}%`;
  const comparator =
    operator === 'LTE' ? '<=' : operator === 'GTE' ? '>=' : '=';

  return `${occupancyCategory} ${comparator} ${occupancyValue}`;
};

const getChannelText = (
  channel: Channel,
  product: Product | null,
  t: TranslateFuncType
) => {
  if (channel.channelCategory === 'AGENT') {
    return product?.agents?.find((agent) => agent.id === channel.agentId)?.name;
  }

  if (channel.channelCategory === 'DIRECT_ALL') {
    return t('Direct');
  }

  if (channel.channelCategory === 'COMMON') {
    return t('Common');
  }

  return t('<unknown>');
};

const getActionText = (
  action: PriceAdjustmentAction,
  product: Product | null,
  t: TranslateFuncType
): string => {
  const actionText = action.unitPriceDeltas
    .map((delta) => {
      const discount =
        action.percentOrFixed === 'PERCENT'
          ? `${delta.deltaPercent ?? 0}%`
          : `${delta.deltaFixedGross || ''}/${delta.deltaFixedNet || ''}`;
      return `${
        delta.method === 'PER_BOOKING' ? t('Per-Booking') : delta.guestTypeKey
      }: ${discount}`;
    })
    .join(', ');

  const channels =
    action.channels
      ?.map((channel) => getChannelText(channel, product, t))
      .join(', ') ?? '';

  return `${
    action.deltaType === 'CHARGE' ? t('Charge') : t('Discount')
  } (${channels}): ${actionText}`;
};

interface Props {
  activeScheduleIndex: number;
  setActiveScheduleIndex: (index: number) => void;
}

export const AdjustmentScheduleTable = ({
  activeScheduleIndex,
  setActiveScheduleIndex,
}: Props) => {
  const product = React.useContext(EditingProductContext);
  const { schedules } = useForm().getState().values;
  const { t } = useTranslation();

  if ((schedules ?? [])?.length === 0) {
    return null;
  }

  return (
    <table className={styles['table']}>
      <thead>
        <tr>
          <th>{t('Participation Date Range')}</th>
          <th>{t('Active Period')}</th>
          <th>{t('Condition')}</th>
          <th>{t('Action')}</th>
        </tr>
      </thead>
      <tbody>
        {schedules.map((schedule: PriceAdjustmentSchedule, index: number) => (
          <tr
            key={index}
            className={clsx(
              styles['table__row'],
              activeScheduleIndex === index && styles['table__row--active']
            )}
            onClick={() => setActiveScheduleIndex(index)}
          >
            <td>
              {schedule.participationDateRanges
                .map(
                  (dateRange) => `${dateRange.startDate} ~ ${dateRange.endDate}`
                )
                .join('; ')}
            </td>
            <td>{`${
              schedule.appliesFromParticipationRelativeDate
                ? getDeadlineDescription(
                    schedule.appliesFromParticipationRelativeDate,
                    t
                  )
                : ''
            } ~ ${
              schedule.appliesUntilParticipationRelativeDate
                ? getDeadlineDescription(
                    schedule.appliesUntilParticipationRelativeDate,
                    t
                  )
                : ''
            }`}</td>
            <td>
              {(schedule.rules ?? []).length > 0 &&
              (schedule.rules?.[0].conditions ?? [])?.length > 0
                ? getConditionText(schedule.rules[0].conditions[0], product, t)
                : ''}
            </td>
            <td>
              {(schedule.rules ?? []).length > 0 &&
                getActionText(schedule.rules[0].action, product, t)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
