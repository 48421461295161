import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type {
  DateFilterPreset,
  ReservationReportSettings,
} from 'client/libraries/util/reservationReportSettings';
import type { LanguageISO } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  DateRangeInput,
  Button,
  MultiSelect,
  Select,
  FieldWrapper,
  Radio,
  ToggleButton,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { activeUserSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { agentOptionsSelector } from 'client/components/ReservationSearchSettingsModal/util';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { operationAllowed } from 'shared/models/access';
import { initialSettings } from 'client/libraries/util/reservationReportSettings';
import type {
  BasisDateType,
  BookingSourceType,
  ReservationStatus,
} from 'shared/models/swagger';

export type { DateFilterPreset, ReservationReportSettings };
export { initialSettings };
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return ['7_DAY', '14_DAYS', '28_DAYS', '30_DAYS', 'CUSTOM'].map((preset) => ({
    value: preset as any,
    text: getDateFilterPresetText(preset as any, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  if (preset === 'CUSTOM') {
    return t('Custom');
  }

  const match = /^(\d+)_DAY.*$/.exec(preset);

  if (match && match.length > 0) {
    return t('Last {{count}} days', {
      count: match[1],
    });
  }

  return '';
};
type Props = {
  locale: LanguageISO;
  onReset: () => void;
  onSearch: () => void;
  reservationReportSettings: ReservationReportSettings;
  setReservationReportSettings: (arg0: ReservationReportSettings) => void;
  trigger: React.ReactElement<any>;
};
export const ReservationReportSettingsModal = ({
  onReset,
  onSearch,
  reservationReportSettings,
  setReservationReportSettings,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const [durationErrorMessage, setDurationErrorMessage] =
    React.useState<string>('');
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return { value: option.value, text: option.text };
  });
  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text ?? '',
    };
  });
  const dateFilterPresetOptions = [...getDateFilterPresetOptions(t)];
  const activeUser = useSelector(activeUserSelector);

  const changeProductId = ({ value }: { value: string[] }) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      productIds: value,
    });
  };

  const changeStatus = ({ value }: { value: ReservationStatus[] }) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      statuses: value,
    });
  };

  const changeBookingSource = ({ value }: { value: BookingSourceType[] }) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      bookingSourceTypes: value,
    });
  };

  const changeAgentId = ({ value }: { value: string[] }) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      agentIds: value,
    });
  };

  const startDateHandler = (date: string) => {
    const isValid = validateDuration(
      date,
      reservationReportSettings.endDate,
      reservationReportSettings.compare,
      reservationReportSettings.compareStartDate,
      reservationReportSettings.compareEndDate
    );

    if (!isValid) {
      if (reservationReportSettings.compare) {
        setCompareDurationErrorMessage();
      } else {
        setBaseDurationErrorMessage();
      }
    } else {
      clearDurationErrorMessage();

      if (
        reservationReportSettings.endDate &&
        moment(reservationReportSettings.endDate).isBefore(moment(date))
      ) {
        setReservationReportSettings({
          ...reservationReportSettings,
          startDate: reservationReportSettings.endDate,
          endDate: date,
        });
      } else {
        setReservationReportSettings({
          ...reservationReportSettings,
          startDate: date,
        });
      }
    }
  };

  const endDateHandler = (date: string) => {
    const isValid = validateDuration(
      reservationReportSettings.startDate,
      date,
      reservationReportSettings.compare,
      reservationReportSettings.compareStartDate,
      reservationReportSettings.compareEndDate
    );

    if (!isValid) {
      if (reservationReportSettings.compare) {
        setCompareDurationErrorMessage();
      } else {
        setBaseDurationErrorMessage();
      }
    } else {
      clearDurationErrorMessage();

      if (
        reservationReportSettings.startDate &&
        moment(reservationReportSettings.startDate).isAfter(moment(date))
      ) {
        setReservationReportSettings({
          ...reservationReportSettings,
          startDate: date,
          endDate: reservationReportSettings.startDate,
        });
      } else {
        setReservationReportSettings({
          ...reservationReportSettings,
          endDate: date,
        });
      }
    }
  };

  const compareStartDateHandler = (date: string) => {
    const isValid = validateDuration(
      reservationReportSettings.startDate,
      reservationReportSettings.endDate,
      reservationReportSettings.compare,
      date,
      reservationReportSettings.compareEndDate
    );

    if (!isValid) {
      if (reservationReportSettings.compare) {
        setCompareDurationErrorMessage();
      } else {
        setBaseDurationErrorMessage();
      }
    } else {
      clearDurationErrorMessage();

      if (
        reservationReportSettings.compareEndDate &&
        moment(reservationReportSettings.compareEndDate).isBefore(moment(date))
      ) {
        setReservationReportSettings({
          ...reservationReportSettings,
          compareStartDate: reservationReportSettings.compareEndDate,
          compareEndDate: date,
        });
      } else {
        setReservationReportSettings({
          ...reservationReportSettings,
          compareStartDate: date,
        });
      }
    }
  };

  const compareEndDateHandler = (date: string) => {
    const isValid = validateDuration(
      reservationReportSettings.startDate,
      reservationReportSettings.endDate,
      reservationReportSettings.compare,
      reservationReportSettings.compareStartDate,
      date
    );

    if (!isValid) {
      if (reservationReportSettings.compare) {
        setCompareDurationErrorMessage();
      } else {
        setBaseDurationErrorMessage();
      }
    } else {
      clearDurationErrorMessage();

      if (
        reservationReportSettings.compareStartDate &&
        moment(reservationReportSettings.compareStartDate).isAfter(moment(date))
      ) {
        setReservationReportSettings({
          ...reservationReportSettings,
          compareStartDate: date,
          compareEndDate: reservationReportSettings.compareStartDate,
        });
      } else {
        setReservationReportSettings({
          ...reservationReportSettings,
          compareEndDate: date,
        });
      }
    }
  };

  const basisDateTypeHandler = (basisDateType: BasisDateType) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      basisDateType: basisDateType,
    });
  };

  const comparisonHandler = () => {
    setReservationReportSettings({
      ...reservationReportSettings,
      compare: !reservationReportSettings.compare,
    });
  };

  const MAX_DURATION = 365;

  const validateDuration = (
    start: string,
    end: string,
    compare: boolean,
    compareStart: string,
    compareEnd: string
  ): boolean => {
    if (!compare && start && end) {
      if (Math.abs(moment(start).diff(moment(end), 'days')) > MAX_DURATION) {
        return false;
      }
    } else if (compare && start && end && compareStart && compareEnd) {
      const duration = Math.abs(moment(start).diff(moment(end), 'days'));
      const compareDuration = Math.abs(
        moment(compareStart).diff(moment(compareEnd), 'days')
      );

      if (duration + compareDuration > MAX_DURATION) {
        return false;
      }
    }

    return true;
  };

  const setBaseDurationErrorMessage = () => {
    setDurationErrorMessage(
      t('Sorry, the duration must be {{maxDuration}} days or less.', {
        maxDuration: MAX_DURATION,
      })
    );
  };

  const setCompareDurationErrorMessage = () => {
    setDurationErrorMessage(
      t('Sorry, total of two durations must be {{maxDuration}} days or less.', {
        maxDuration: MAX_DURATION,
      })
    );
  };

  const clearDurationErrorMessage = () => {
    setDurationErrorMessage('');
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Report Setting')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        {false && (
          <pre>{JSON.stringify(reservationReportSettings, undefined, 2)}</pre>
        )}
        <Modal.Box>
          <FieldWrapper label={t('Basis')}>
            <Radio
              label={t('Participation Date')}
              checked={
                reservationReportSettings.basisDateType === 'PARTICIPATION_DATE'
              }
              onChange={() => {
                basisDateTypeHandler('PARTICIPATION_DATE');
              }}
            />
            <Radio
              label={t('Booked date')}
              checked={
                reservationReportSettings.basisDateType === 'BOOKING_DATE'
              }
              onChange={() => {
                basisDateTypeHandler('BOOKING_DATE');
              }}
            />
          </FieldWrapper>
        </Modal.Box>

        <Modal.Box column="two">
          <Select
            label={t('Date Range')}
            options={dateFilterPresetOptions}
            value={reservationReportSettings.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setReservationReportSettings({
                ...reservationReportSettings,
                dateFilterPreset: value as any,
              });
            }}
          />
          {reservationReportSettings.dateFilterPreset === 'CUSTOM' && (
            <>
              <DateRangeInput
                label={t('Duration {{duration}}', {
                  duration: '1',
                })}
                fromDate={reservationReportSettings.startDate}
                onChangeFromDate={startDateHandler}
                toDate={reservationReportSettings.endDate}
                onChangeToDate={endDateHandler}
                error={durationErrorMessage}
              />
            </>
          )}
        </Modal.Box>

        <Modal.Box column="two">
          <FieldWrapper label={t('Compare')}>
            <ToggleButton
              label=""
              checked={reservationReportSettings.compare}
              onChange={() => {
                comparisonHandler();
              }}
            />
          </FieldWrapper>

          {reservationReportSettings.compare && (
            <DateRangeInput
              label={t('Duration {{duration}}', {
                duration: '2',
              })}
              fromDate={reservationReportSettings.compareStartDate}
              onChangeFromDate={compareStartDateHandler}
              toDate={reservationReportSettings.compareEndDate}
              onChangeToDate={compareEndDateHandler}
              error={durationErrorMessage}
            />
          )}
        </Modal.Box>

        <Modal.Box>
          <MultiSelect
            label={t('Booking Status')}
            options={statusOptions}
            selectedValues={reservationReportSettings.statuses}
            onChange={changeStatus as any}
          />
        </Modal.Box>

        <Modal.Box>
          <MultiSelect
            search
            label={t('Product')}
            options={productOptions}
            selectedValues={reservationReportSettings.productIds}
            onChange={changeProductId}
          />
        </Modal.Box>

        {operationAllowed(activeUser, 'write', 'reservationConfirmation') ? (
          <>
            <Modal.Box>
              <MultiSelect
                label={t('Booking Source')}
                options={bookingSourceOptions}
                selectedValues={reservationReportSettings.bookingSourceTypes}
                onChange={changeBookingSource as any}
              />
            </Modal.Box>

            <Modal.Box>
              <MultiSelect
                label={t('Agent')}
                options={agentOptions}
                selectedValues={reservationReportSettings.agentIds}
                onChange={changeAgentId as any}
              />
            </Modal.Box>
          </>
        ) : (
          <></>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
            setShowModal(false);
          }}
        >
          {t('Update')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
