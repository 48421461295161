import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, TFunction } from 'react-i18next';

import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { changeUserAttributes } from 'client/actions/user';
import { loggedInAccountSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers/index';

import styles from './Settings.module.css';

type Dispatch = ThunkDispatch<any, any, any>;
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  I18nProps;
/* eslint-enable no-use-before-define */

type State = {
  name: string;
  error: string;
  success: boolean;
};

class AccountSettingsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: this.getCurrentFullName(),
      error: '',
      success: false,
    };
  }

  getCurrentFullName = () => {
    return this.props.loggedInAccount?.name || '';
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.loggedInAccount !== this.props.loggedInAccount) {
      this.setState({
        name: this.getCurrentFullName(),
      });
    }

    if (
      prevProps.attrMap !== this.props.attrMap &&
      prevProps.attrMap['custom:email_mfa'] ===
        this.props.attrMap['custom:email_mfa']
    ) {
      this.setState({
        error: '',
        success: true,
      });
    }
  }

  buildAttrMap = () => {
    return {
      name: this.state.name,
    };
  };
  onInputChanged = (e: any) => {
    const { name, value } = e.target as any;
    this.setState({
      [name]: value,
    } as any);
  };

  render() {
    const { loggedInAccount, onChangeAttributes, t } = this.props;
    const { error, name, success } = this.state;
    return (
      <div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Email')}>
            {loggedInAccount?.email}
          </FieldWrapper>
        </div>
        <div className={styles['input-box']}>
          <Input
            label={t('Full Name')}
            name="name"
            value={name}
            onChange={this.onInputChanged}
          />
        </div>
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <div className={styles['buttons']}>
          <div className={styles['button-box']}>
            <Button
              style="gray"
              size="middle"
              onClick={() =>
                this.setState({
                  name: this.getCurrentFullName(),
                })
              }
            >
              {t('Discard')}
            </Button>
          </div>
          <div className={styles['button-box']}>
            <Button
              style="blue"
              size="middle"
              onClick={() => onChangeAttributes(this.buildAttrMap())}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    loggedInAccount: loggedInAccountSelector(state),
    attrMap: state.user.attrMap,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeAttributes: (newAttr: Record<string, string>) =>
    dispatch(changeUserAttributes(newAttr)),
});

export const AccountSettings = compose<Props, Record<string, never>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(AccountSettingsComponent);
