import * as React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import ReactQrReader from 'react-qr-reader';

import styles from './QrCodeReader.module.css';

export const QrCodeReader = (): any => {
  const history = useHistory();
  return (
    <>
      <div className={clsx(styles['base-wrap'])}>
        <main className={clsx(styles['base-main'])}>
          <div className={clsx(styles['c-qrreader__btn__pane'])}>
            <ReactQrReader
              delay={300}
              onError={(e: any) => {
                console.log(e);
              }}
              onScan={(scanData: any) => {
                if (scanData) {
                  history.push(scanData.split('.com')[1]);
                }
              }}
              style={{
                width: '100%',
              }}
            />
          </div>
        </main>
      </div>
    </>
  );
};
