import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import type { Product, Product$Patch } from 'shared/models/swagger';

import { AgentBlacklistWhitelistEditor } from './AgentBlacklistWhitelistEditor';

type FormValues = {
  type: 'WHITELIST' | 'BLACKLIST';
  whitelistedAgentIds: string[];
  blacklistedAgentIds: string[];
};

const getInitialValues = (product: Product | null): FormValues => {
  const whitelistedAgentIds = product?.whitelisted_agents ?? [];
  const blacklistedAgentIds = product?.blacklisted_agents ?? [];
  return {
    type: whitelistedAgentIds.length > 0 ? 'WHITELIST' : 'BLACKLIST',
    whitelistedAgentIds,
    blacklistedAgentIds,
  };
};

const convertFormValuesToProductPatch = (values: FormValues): Product$Patch => {
  return {
    whitelisted_agents: values.whitelistedAgentIds,
    blacklisted_agents: values.blacklistedAgentIds,
  };
};

type Props = {
  onClose: () => void;
};
export const AgentBlacklistWhitelistEditorModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Edit agent blacklist/whitelist')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <AgentBlacklistWhitelistEditor />
    </ProductEditorModal>
  );
};
