import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { removeCheckinNotification } from 'client/actions/notifications';
import type { Notification } from 'client/reducers/notifications';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';

import styles from './NotificationList.module.css';

type Props = {
  notification: Notification;
};
export const NotificationListElement = ({ notification }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Auto-clear notification after 11 seconds
  React.useEffect(() => {
    const deleteNotificationTimer = setTimeout(() => {
      dispatch(removeCheckinNotification(notification.id));
    }, 10 * 1000);
    return () => {
      clearTimeout(deleteNotificationTimer);
    };
  }, [notification.id]);
  return (
    <div className={clsx(styles['snackbar'], styles['fade-out'])}>
      <Box
        display="flex"
        className={styles['snackbar-header']}
        alignItems="center"
      >
        <img width={18} height={18} src={checkFinishedIcon} />
        <Box ml={1}>{t('Guests have just checked in')}</Box>
        <a
          className={clsx(styles['snackbar-close'])}
          onClick={() => {
            dispatch(removeCheckinNotification(notification.id));
          }}
        />
      </Box>
      <Box mt={1}>
        <div className={styles['snackbar-ellipsis']}>
          {t('Application Number: {{agent_reference}}', {
            agent_reference:
              notification.payload?.['reservation_agent_reference'],
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Product Name: {{productName}}', {
            productName: notification.payload?.['product_name'],
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Customer: {{customerName}}', {
            customerName: notification.payload?.['reservation_guest_name'],
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Checked-in Guests: {{checkedInCount}}/{{totalCount}}', {
            checkedInCount: notification.payload?.['checkin_guest_count'],
            totalCount: notification.payload?.['reservation_total_guest_count'],
          })}
        </div>
      </Box>
    </div>
  );
};
