import { ProductPromotionsHeader } from 'client/pages/v3/Product/ProductPromotions/ProductPromotionsHeader';
import { ProductPromotionsBody } from 'client/pages/v3/Product/ProductPromotions/ProductPromotionsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ProductPromotions = () => {
  return (
    <V3Page>
      <ProductPromotionsHeader />
      <ProductPromotionsBody />
    </V3Page>
  );
};
