import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { ToggleButton, Button } from 'client/components/Form';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';

export const EditSendTaskNotificationEmailModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    openEditSendTaskNotificationEmailModal,
    setOpenEditSendTaskNotificationEmailModal,
  ] = React.useState<boolean>(false);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <Modal
      title={t('Email Notifications')}
      open={openEditSendTaskNotificationEmailModal}
      onClose={() => setOpenEditSendTaskNotificationEmailModal(false)}
      onOpen={() => setOpenEditSendTaskNotificationEmailModal(true)}
      trigger={
        <a
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['square'],
            baseStyles['gray']
          )}
        >
          <img src={thIcon} />
        </a>
      }
    >
      <Form
        onSubmit={async (values: {
          shouldSendTaskNotificationEmail: boolean;
        }) => {
          try {
            await dispatch(
              updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
                should_send_task_notification_email: {
                  value: values.shouldSendTaskNotificationEmail,
                },
              })
            );
          } catch (error) {
            console.error(error);
          } finally {
            setOpenEditSendTaskNotificationEmailModal(false);
          }
        }}
        initialValues={{
          shouldSendTaskNotificationEmail:
            activeUserOrganization?.should_send_task_notification_email
              ?.value ?? false,
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="shouldSendTaskNotificationEmail" type="checkbox">
                  {({ input }) => {
                    return (
                      <ToggleButton
                        checked={input.checked ?? false}
                        onChange={() => input.onChange(!input.checked)}
                        label={t(
                          'Receive notification email about reservations on Dash.'
                        )}
                      />
                    );
                  }}
                </Field>
              </Modal.Box>
              <Modal.Box>
                <p>
                  {t(
                    'Turn this ON to receive daily notification email about reservations on Dash. Email will be delivered to the email address saved in the Email Notifications for booking notifications.'
                  )}
                </p>
              </Modal.Box>
            </Modal.Content>

            <Modal.Actions>
              {submitError && (
                <p className={baseStyles['base-form-box__err']}>
                  {submitError}
                </p>
              )}
              <Button.Cancel
                onClick={() => {
                  setOpenEditSendTaskNotificationEmailModal(false);
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
