import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { fetchInvoiceCsv } from 'client/actions/invoices';
import { ReduxState } from 'client/reducers';

type Props = {
  invoiceIds: string[];
};

export const ExportToCSVButton = ({ invoiceIds }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const csvLoading = useSelector(
    (state: ReduxState) => state.invoices.csvLoading
  );
  const invoiceReservationColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleReservationColumns
  );

  return (
    <Button
      style="gray"
      size="middle"
      loading={csvLoading}
      onClick={async () => {
        for (const invoiceId of invoiceIds) {
          await dispatch(fetchInvoiceCsv(invoiceId, invoiceReservationColumns));
        }
      }}
    >
      {t('Download CSV')}
    </Button>
  );
};
