import {
  SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION,
  SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS,
  SET_MEDIA_DOWNLOAD_ORDER_ROW_COUNT,
  SET_MEDIA_DOWNLOAD_ORDER_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchMediaDownloadOrdersRequest } from 'client/libraries/util/searchMediaDownloadOrders';

export const setMediaDownloadOrderVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setLastExecutedMediaDownloadOrderSearchCondition = (
  searchFilters: SearchMediaDownloadOrdersRequest
) => ({
  type: SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION,
  searchFilters,
});
export const setMediaDownloadOrderRowCount = (rowCount: number) => ({
  type: SET_MEDIA_DOWNLOAD_ORDER_ROW_COUNT,
  rowCount,
});
export const setMediaDownloadOrderCurrentPage = (currentPage: number) => ({
  type: SET_MEDIA_DOWNLOAD_ORDER_CURRENT_PAGE,
  currentPage,
});
