import * as Swagger from 'shared/models/swagger';

export type FormValues = {
  name: string;
  email: string;
  role: string;
  locale: 'ja' | 'en';
};
export const convertFromValuesToAccount = (
  formValues: FormValues,
  activeUser: Swagger.Account | null
): Swagger.Account$Input => {
  return {
    ...(activeUser as any),
    name: formValues.name,
    email: formValues.email,
    role: 'GUIDE',
    locale: formValues.locale,
  };
};
