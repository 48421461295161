import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { PackageSettingsEditor } from 'client/pages/ProductDetails/ProductContentsHeader/PackageSettingsModal/PackageSettingsEditor';
import { fetchProductsByID } from 'client/actions/products';
import {
  convertFormValuesToRecurrence,
  getInitialAvailabilityAllotmentSchedules,
} from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import {
  convertFormValuesToPricing,
  getInitialPriceSchedules,
  PriceSchedule,
} from 'client/pages/ProductEditor/BasicEditor/FormValues';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { Product, Product$Patch } from 'shared/models/swagger';

import { useSourceLanguageProduct } from '../useSourceLanguageProduct';

type FormValues = {
  availabilitySchedules: AvailabilityAllotmentSchedule[];
  priceSchedules: PriceSchedule[];
  isDynamicPackageProduct: boolean;
};

const getInitialValues = (
  product: Product | null,
  componentProducts: Product[]
): FormValues => {
  return {
    availabilitySchedules: getInitialAvailabilityAllotmentSchedules(
      product,
      componentProducts
    ),
    priceSchedules: getInitialPriceSchedules(product),
    isDynamicPackageProduct:
      product?.is_dynamic_package_product?.value ?? false,
  };
};

const convertToProductPatch = (
  values: FormValues,
  defaultCurrency: string
): Product$Patch => {
  return {
    recurrence: convertFormValuesToRecurrence(values.availabilitySchedules),
    pricing: convertFormValuesToPricing(values.priceSchedules, defaultCurrency),
    is_dynamic_package_product: {
      value: values.isDynamicPackageProduct,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const PackageSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const contentLanguageProduct = React.useContext(EditingProductContext);
  const { data: product, isLoading: sourceLanguageProductIsLoading } =
    useSourceLanguageProduct(contentLanguageProduct?.id ?? '');
  const packageComponentProductIds =
    product?.shared_allotment_references?.package_component_product_ids ?? [];
  React.useEffect(() => {
    if (packageComponentProductIds.length > 0) {
      dispatch(fetchProductsByID(packageComponentProductIds, '', true));
    }
  }, [packageComponentProductIds]);
  const componentProducts = useSelector((state: ReduxState) => {
    const result = [];

    for (const id of packageComponentProductIds) {
      if (state.products.byID[id]) {
        result.push(state.products.byID[id]);
      }
    }

    return result;
  });
  const initialValues = React.useMemo(
    () => getInitialValues(product, componentProducts),
    [product, componentProducts]
  );
  const defaultProductCurrency = product
    ? getProductCurrency(product)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const convertFormValuesToProductPatch = React.useCallback(
    (values: FormValues): Product$Patch =>
      convertToProductPatch(values, defaultCurrency),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      loading={productsLoading || sourceLanguageProductIsLoading}
      title={t('Package Settings')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <div>
        <PackageSettingsEditor componentProducts={componentProducts} />
      </div>
    </ProductEditorModal>
  );
};
