import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { fetchProducts } from 'client/actions/products';
import { fetchReservationReportData } from 'client/actions/reservations';
import { setLastReservationReportSettings } from 'client/actions/reservationReportSettings';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  DateFilterPreset,
  ReservationReportSettings,
  convertReservationReportSettingsToQueryParams,
  initialSettings,
} from 'client/pages/v3/Reservation/ReservationReport/utils';
import { SearchAccordion } from 'client/components/v3/ReservationList/SearchAccordion';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Radio } from 'client/components/v3/Form/Radio';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { productOptionsSelector } from 'client/reducers/products';
import { agentOptionsSelector } from 'client/components/ReservationSearchSettingsModal/util';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import styles from 'client/pages/v3/Reservation/ReservationReport/ReservationReport.module.css';
import { operationAllowed } from 'shared/models/access';
import type {
  BasisDateType,
  BookingSourceType,
  ReservationStatus,
} from 'shared/models/swagger';

export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return ['7_DAY', '14_DAYS', '28_DAYS', '30_DAYS', 'CUSTOM'].map((preset) => ({
    value: preset as any,
    text: getDateFilterPresetText(preset as any, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  if (preset === 'CUSTOM') {
    return t('Custom');
  }

  const match = /^(\d+)_DAY.*$/.exec(preset);

  if (match && match.length > 0) {
    return t('Last {{count}} days', {
      count: match[1],
    });
  }

  return '';
};

export const ReservationReportSettingsBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lastReservationReportSettings = useSelector(
    (state: ReduxState) =>
      state.reservationReportSettings.lastReservationReportSettings
  );
  const activeUser = useSelector(activeUserSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [reservationReportSettings, setReservationReportSettings] =
    React.useState<ReservationReportSettings>(lastReservationReportSettings);
  const dateFilterPresetOptions = [...getDateFilterPresetOptions(t)];

  const basisDateTypeHandler = (basisDateType: BasisDateType) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      basisDateType: basisDateType,
    });
  };

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return { value: option.value, text: option.text };
  });
  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text ?? '',
    };
  });

  const changeProductId = (value: string[]) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      productIds: value,
    });
  };

  const changeStatus = (value: ReservationStatus[]) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      statuses: value,
    });
  };

  const changeBookingSource = (value: BookingSourceType[]) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      bookingSourceTypes: value,
    });
  };

  const changeAgentId = (value: string[]) => {
    setReservationReportSettings({
      ...reservationReportSettings,
      agentIds: value,
    });
  };

  const dateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    const isValid = validateDuration(
      startDate || '',
      endDate || '',
      reservationReportSettings.compare,
      reservationReportSettings.compareStartDate,
      reservationReportSettings.compareEndDate
    );

    if (isValid) {
      let startDateParam = '';
      let endDateParam = '';
      if (
        reservationReportSettings.endDate &&
        moment(reservationReportSettings.endDate).isBefore(
          moment(startDate || '')
        )
      ) {
        endDateParam = startDate || '';
      } else {
        startDateParam = startDate || '';
      }

      if (
        reservationReportSettings.startDate &&
        moment(reservationReportSettings.startDate).isAfter(
          moment(endDate || '')
        )
      ) {
        startDateParam = endDate || '';
      } else {
        endDateParam = endDate || '';
      }
      setReservationReportSettings({
        ...reservationReportSettings,
        endDate: endDateParam,
        startDate: startDateParam,
      });
    }
  };
  const compareDateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    const isValid = validateDuration(
      reservationReportSettings.startDate,
      reservationReportSettings.endDate,
      reservationReportSettings.compare,
      startDate || '',
      endDate || ''
    );

    if (isValid) {
      let startDateParam = '';
      let endDateParam = '';
      if (
        reservationReportSettings.compareEndDate &&
        moment(reservationReportSettings.compareEndDate).isBefore(
          moment(startDate || '')
        )
      ) {
        endDateParam = startDate || '';
      } else {
        startDateParam = startDate || '';
      }
      if (
        reservationReportSettings.compareStartDate &&
        moment(reservationReportSettings.compareStartDate).isAfter(
          moment(endDate || '')
        )
      ) {
        startDateParam = endDate || '';
      } else {
        endDateParam = endDate || '';
      }
      setReservationReportSettings({
        ...reservationReportSettings,
        compareStartDate: startDateParam,
        compareEndDate: endDateParam,
      });
    }
  };

  const comparisonHandler = () => {
    setReservationReportSettings({
      ...reservationReportSettings,
      compare: !reservationReportSettings.compare,
    });
  };

  const MAX_DURATION = 365;

  const validateDuration = (
    start: string,
    end: string,
    compare: boolean,
    compareStart: string,
    compareEnd: string
  ): boolean => {
    if (!compare && start && end) {
      if (Math.abs(moment(start).diff(moment(end), 'days')) > MAX_DURATION) {
        return false;
      }
    } else if (compare && start && end && compareStart && compareEnd) {
      const duration = Math.abs(moment(start).diff(moment(end), 'days'));
      const compareDuration = Math.abs(
        moment(compareStart).diff(moment(compareEnd), 'days')
      );

      if (duration + compareDuration > MAX_DURATION) {
        return false;
      }
    }

    return true;
  };

  const reset = () => {
    setReservationReportSettings(initialSettings);
  };
  const search = () => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchReservationReportData(
          convertReservationReportSettingsToQueryParams(
            reservationReportSettings
          )
        )
      );
      dispatch(setLastReservationReportSettings(reservationReportSettings));
    }
  };

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(fetchProducts());
    }
  }, [locale]);
  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchReservationReportData(
          convertReservationReportSettingsToQueryParams(
            reservationReportSettings
          )
        )
      );
      dispatch(fetchProducts());
    }
  }, [isNutmegAdmin]);
  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  return (
    <section className={styles['g-section']}>
      <div className={styles['p-reports']}>
        <div className={styles['p-reports__header']}>
          <h2 className={styles['p-reports__header__title']}>
            {t('Report Setting')}
          </h2>
        </div>
        <div className={styles['p-reports__body']}>
          <div className={styles['p-reports__body__display']}>
            <div className={styles['p-reports__body__item']}>
              <div className={styles['p-reports__body__item__flex']}>
                <p className={styles['p-reports__body__item__radiotitle']}>
                  {t('Basis')}
                </p>
                <Radio
                  label={t('Participation Date')}
                  checked={
                    reservationReportSettings.basisDateType ===
                    'PARTICIPATION_DATE'
                  }
                  onChange={() => {
                    basisDateTypeHandler('PARTICIPATION_DATE');
                  }}
                  size="sm"
                />
                <Radio
                  label={t('Booked date')}
                  checked={
                    reservationReportSettings.basisDateType === 'BOOKING_DATE'
                  }
                  onChange={() => {
                    basisDateTypeHandler('BOOKING_DATE');
                  }}
                  size="sm"
                />
              </div>
            </div>
            <div
              className={clsx(
                styles['p-reports__body__item'],
                styles['p-reports__body__item--custom']
              )}
              style={{ alignItems: 'center' }}
            >
              <div style={{ width: '264px' }}>
                <SingleDropdown
                  label={t('Date Range')}
                  selectedOption={
                    reservationReportSettings.dateFilterPreset ?? 'NONE'
                  }
                  options={dateFilterPresetOptions}
                  onChange={(value) => {
                    setReservationReportSettings({
                      ...reservationReportSettings,
                      dateFilterPreset: value as any,
                    });
                  }}
                />
              </div>
              {reservationReportSettings.dateFilterPreset === 'CUSTOM' && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '1',
                    })}
                    dateFrom={reservationReportSettings.startDate}
                    dateTo={reservationReportSettings.endDate}
                    onChange={dateRangeInputChangeHandler}
                    //error={durationErrorMessage}
                  />
                </div>
              )}
              <Checkbox
                label={t('Compare')}
                checked={reservationReportSettings.compare}
                onChange={() => {
                  comparisonHandler();
                }}
                size="md"
              />

              {reservationReportSettings.compare && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '2',
                    })}
                    dateFrom={reservationReportSettings.compareStartDate}
                    dateTo={reservationReportSettings.compareEndDate}
                    onChange={compareDateRangeInputChangeHandler}
                    //error={durationErrorMessage}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={clsx(styles['p-reports__body__ac'])}>
            <SearchAccordion>
              <div
                className={clsx(
                  styles['p-reports__body__item'],
                  styles['p-reports__body__item--4col']
                )}
              >
                <div style={{ width: 'calc(50% - 8px)' }}>
                  <MultiDropdown
                    label={t('Booking Status')}
                    options={statusOptions}
                    onChange={changeStatus as any}
                    selectedOptions={reservationReportSettings.statuses}
                    searchable={true}
                  />
                </div>
                <div style={{ width: 'calc(50% - 8px)' }}>
                  <MultiDropdown
                    label={t('Product')}
                    options={productOptions}
                    onChange={changeProductId as any}
                    selectedOptions={reservationReportSettings.productIds}
                    searchable={true}
                  />
                </div>
              </div>

              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) ? (
                <>
                  <div
                    className={clsx(
                      styles['p-reports__body__item'],
                      styles['p-reports__body__item--4col']
                    )}
                  >
                    <div style={{ width: 'calc(50% - 8px)' }}>
                      <MultiDropdown
                        label={t('Booking Source')}
                        options={bookingSourceOptions}
                        onChange={changeBookingSource as any}
                        selectedOptions={
                          reservationReportSettings.bookingSourceTypes
                        }
                        searchable={true}
                      />
                    </div>
                    <div style={{ width: 'calc(50% - 8px)' }}>
                      <MultiDropdown
                        label={t('Agent')}
                        options={agentOptions}
                        onChange={changeAgentId as any}
                        selectedOptions={reservationReportSettings.agentIds}
                        searchable={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </SearchAccordion>
          </div>
        </div>
        <div className={clsx(styles['p-reports__bottom'], styles['center'])}>
          <Button
            text={t('Clear conditions')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            onClick={() => {
              reset();
            }}
            style={{ marginRight: '12px' }}
          />
          <Button
            type="submit"
            text={t('Search reservations')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={() => {
              search();
            }}
          />
        </div>
      </div>
    </section>
  );
};
