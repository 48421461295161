import * as React from 'react';
import clsx from 'clsx';

import { applyRelativeTimeOffset } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/util';
import type {
  LocationShape,
  StartTime,
} from 'client/libraries/util/productShape';
import styles from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/ProductOutline.module.css';

type Props = {
  startTime?: StartTime;
  locations: LocationShape[];
};
export const LocationList = ({ startTime, locations }: Props) => {
  return (
    <ul className={styles['c-schedule__list']}>
      {locations.map((location) => {
        const dedicatedStartTimeItem =
          location.dedicatedStartTimeInfoItems.find(
            (item) => item.timeSlotKey === startTime?.timeSlotKey
          );
        let timeRelative = location.timeRelative;

        if (dedicatedStartTimeItem && dedicatedStartTimeItem.timeRelative) {
          timeRelative = dedicatedStartTimeItem.timeRelative;
        }

        const timeDescription = timeRelative
          ? applyRelativeTimeOffset(startTime?.hhMm || '0:00', timeRelative)
          : '';
        return (
          <li key={location.name} className={styles['c-schedule__list__item']}>
            <p className={styles['c-schedule__list__item__time']}>
              {timeDescription}
            </p>
            <div className={styles['c-schedule__list__item__body']}>
              <p className={styles['c-schedule__list__item__body__title']}>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query_place_id=${location.googlePlaceID}&query=${location.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {location.name}
                </a>
              </p>
              <p
                className={clsx(
                  styles['c-schedule__list__item__body__text'],
                  'newline'
                )}
              >
                {location.description}
              </p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
