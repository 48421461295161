import * as React from 'react';
import clsx from 'clsx';

import styles from './FormTableBox.module.css';

type Props = {
  header?: string;
  children: React.ReactNode;
  style?: any;
};

export const FormTableBox = ({ children, header, style }: Props) => {
  return (
    <div
      className={clsx(styles['page-productsRegist__tableBox'])}
      style={style}
    >
      {header && (
        <div className={styles['page-productsRegist__subttl']}>
          <p>{header}</p>
        </div>
      )}
      <div className={styles['c-table-list']}>{children}</div>
    </div>
  );
};
