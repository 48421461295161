import {
  SET_ANNUAL_PASS_LAST_EXECUTED_SEARCH_CONDITION,
  SET_ANNUAL_PASS_RESERVATION_VISIBLE_COLUMNS,
  SET_ANNUAL_PASS_RESERVATION_ROW_COUNT,
  SET_ANNUAL_PASS_RESERVATION_CURRENT_PAGE,
  SET_ANNUAL_PASS_RESERVATION_DEFAULT_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { SearchReservationsRequest } from 'client/libraries/util/searchReservations';

export const setAnnualPassReservationVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_ANNUAL_PASS_RESERVATION_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setAnnualPassLastExecutedSearchCondition = (
  searchFilters: SearchReservationsRequest
) => ({
  type: SET_ANNUAL_PASS_LAST_EXECUTED_SEARCH_CONDITION,
  searchFilters,
});
export const setAnnualPassReservationRowCount = (rowCount: number) => ({
  type: SET_ANNUAL_PASS_RESERVATION_ROW_COUNT,
  rowCount,
});
export const setAnnualPassReservationCurrentPage = (currentPage: number) => ({
  type: SET_ANNUAL_PASS_RESERVATION_CURRENT_PAGE,
  currentPage,
});
export const setAnnualPassReservationDefaultVisibleColumns = (
  defaultVisibleColumns: string[]
) => ({
  type: SET_ANNUAL_PASS_RESERVATION_DEFAULT_VISIBLE_COLUMNS,
  defaultVisibleColumns,
});
