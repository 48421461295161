import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST,
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE,
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { ReservationDataExportOrder } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const createReservationDataCsvExportOrderRequest = (
  newReservationDataCsvExportOrder: ReservationDataExportOrder
) => ({
  type: CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST,
  newReservationDataCsvExportOrder,
});

const createReservationDataCsvExportOrderSuccess = (response: any) => ({
  type: CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS,
  response,
});

const createReservationDataCsvExportOrderFailure = (error: any) => ({
  type: CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE,
  error,
});

export const createReservationDataCsvExportOrder =
  (newReservationDataCsvExportOrder: ReservationDataExportOrder) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      createReservationDataCsvExportOrderRequest(
        newReservationDataCsvExportOrder
      )
    );
    return axios
      .post(
        '/api/reservationdataexportorders',
        {
          ...newReservationDataCsvExportOrder,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) =>
        dispatch(createReservationDataCsvExportOrderSuccess(result.data))
      )
      .catch((error) => {
        dispatch(createReservationDataCsvExportOrderFailure(error));
      });
  };

const fetchReservationDataCsvExportOrdersRequest = () => ({
  type: FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST,
});

const fetchReservationDataCsvExportOrdersSuccess = (response: any) => ({
  type: FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS,
  response,
});

const fetchReservationDataCsvExportOrdersFailure = (error: any) => ({
  type: FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE,
  error,
});

export const fetchReservationDataCsvExportOrders =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationDataCsvExportOrdersRequest());
    axios
      .get('/api/reservationdataexportorders', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchReservationDataCsvExportOrdersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchReservationDataCsvExportOrdersFailure('canceled'));
        else dispatch(fetchReservationDataCsvExportOrdersFailure(error));
      });
  };
