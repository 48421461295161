import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { currencyAmountInputHelper } from 'client/libraries/util/coreutil';
import { Modal } from 'client/components/v3/Form/Modal';
import { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationCreateDetails.module.css';
import type { NewReservation } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { Radio } from 'client/components/v3/Form/Radio';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

type Props = {
  header?: string;
  currency: string;
  onClose?: () => any;
  reservationParams: NewReservation;
  isChangeReservation?: boolean;
  adjustmentAmount: NewReservation['adjustment_amount'];
  saveAdjustingFareParamsNewReservation: (arg0: any) => void;
};

type State = {
  gross: string;
  net: string;
  notes: string;
  is_refund: boolean;
};

export const ReservationFareAdjustmentModal = ({
  header,
  currency,
  onClose,
  reservationParams,
  adjustmentAmount,
  saveAdjustingFareParamsNewReservation,
}: Props) => {
  const { t } = useTranslation();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );

  const [amountGrossError, setAmountGrossError] = useState<boolean>(false);
  const [amountNetError, setAmountNetError] = useState<boolean>(false);
  const [helperGrossText, setHelperGrossText] = useState('');
  const [helperNetText, setHelperNetText] = useState('');

  const helper = currencyAmountInputHelper(currency);
  const [state, setState] = useState<State>({
    gross: helper.moneyInput(adjustmentAmount?.gross?.substring(3) ?? '0'),
    net: helper.moneyInput(adjustmentAmount?.net?.substring(3) ?? '0'),
    notes: adjustmentAmount?.notes ?? '',
    is_refund: adjustmentAmount?.is_refund ?? false,
  });

  const editNet = reservationParams?.booking_source?.source_type == 'AGENT';
  const [totalGross, setTotalGross] = useState<string>(
    billingInfo?.amount_gross?.substring(3) ?? '0'
  );
  const [totalNet, setTotalNet] = useState<string>(
    billingInfo?.amount_net?.substring(3) ?? '0'
  );

  const validateForm = () => {
    const { gross, net } = state;
    return (
      parseFloat(gross) !== 0 &&
      !helper.inputInvalid(helper.moneyInput(gross)) &&
      !helper.inputInvalid(helper.moneyInput(net))
    );
  };

  useEffect(() => {
    if (state.is_refund) {
      setTotalGross(
        helper.diff(billingInfo?.amount_gross?.substring(3) ?? '0', state.gross)
      );
      setTotalNet(
        helper.diff(billingInfo?.amount_net?.substring(3) ?? '0', state.net)
      );
    } else {
      setTotalGross(
        helper.add(billingInfo?.amount_gross?.substring(3) ?? '0', state.gross)
      );
      setTotalNet(
        helper.add(billingInfo?.amount_net?.substring(3) ?? '0', state.net)
      );
    }
  }, [state.gross, state.net, state.is_refund]);

  return (
    <Modal
      title={header ?? ''}
      open={true}
      onClose={() => {
        saveAdjustingFareParamsNewReservation({});
        onClose?.();
      }}
      style={{ maxWidth: '560px' }}
      rightActionChildren={
        <>
          <Button
            text={t('Delete fare adjustment')}
            color="white"
            onClick={() => {
              saveAdjustingFareParamsNewReservation({});
              onClose?.();
            }}
          />
          <Button
            text={t('Confirm')}
            type="submit"
            color="primary"
            onClick={() => {
              saveAdjustingFareParamsNewReservation({
                gross: helper.moneyString(state.gross),
                net: helper.moneyString(state.net),
                notes: state.notes,
                is_refund: state.is_refund,
              });
              onClose?.();
            }}
            disabled={!validateForm()}
          />
        </>
      }
    >
      <>
        <div className={styles['p-fareAdjustment__modal']}>
          <Radio
            label={t('Additional Charge')}
            name="radioGroup"
            value="Charge"
            checked={!state.is_refund}
            onChange={() => {
              setState({
                ...state,
                is_refund: false,
              });
            }}
            size="sm"
          />
          <Radio
            label={t('Discount')}
            name="radioGroup"
            value="Refund"
            checked={state.is_refund}
            onChange={() => {
              setState({
                ...state,
                is_refund: true,
              });
            }}
            size="sm"
          />

          <FieldWrapper
            label={t('Gross') + ` (${currency})`}
            wrapperClassName="p-modal__item"
          >
            <TextField
              value={state.gross}
              error={amountGrossError}
              type="string"
              onChange={(value) => {
                if (!helper.inputAllowed(value)) {
                  return;
                }
                const moneyInput = helper.moneyInput(value);
                if (parseFloat(moneyInput) === 0) {
                  setHelperGrossText(t('Please enter amount.'));
                  setAmountGrossError(true);
                } else if (helper.inputInvalid(moneyInput)) {
                  setHelperGrossText(t('Invalid'));
                  setAmountGrossError(true);
                } else {
                  setAmountGrossError(false);
                }
                setState({
                  ...state,
                  gross: moneyInput,
                  net: moneyInput,
                });
              }}
              helperText={helperGrossText}
            />
          </FieldWrapper>
          <FieldWrapper
            label={t('Net') + ` (${currency})`}
            wrapperClassName="p-modal__item"
          >
            <TextField
              value={state.net}
              error={amountNetError}
              type="string"
              onChange={(value) => {
                if (!helper.inputAllowed(value)) {
                  return;
                }
                const moneyInput = helper.moneyInput(value);
                if (parseFloat(moneyInput) === 0) {
                  setHelperNetText(t('Please enter amount.'));
                  setAmountNetError(true);
                } else if (helper.inputInvalid(moneyInput)) {
                  setHelperNetText(t('Invalid'));
                  setAmountNetError(true);
                } else {
                  setAmountNetError(false);
                }
                setState({
                  ...state,
                  net: moneyInput,
                });
              }}
              helperText={helperNetText}
              disabled={!editNet}
            />
          </FieldWrapper>

          <FieldWrapper
            label={t('Total Gross')}
            wrapperClassName="p-modal__item"
          >
            <p>
              {helper.inputInvalid(totalGross)
                ? currency
                : formattedCurrencyAmount(helper.moneyString(totalGross))}
            </p>
          </FieldWrapper>
          <FieldWrapper label={t('Total Net')} wrapperClassName="p-modal__item">
            <p>
              {helper.inputInvalid(totalNet)
                ? currency
                : formattedCurrencyAmount(helper.moneyString(totalNet))}
            </p>
          </FieldWrapper>
        </div>

        <FieldWrapper
          label={t('Adjustment Notes')}
          wrapperClassName="p-modal__item"
        >
          <TextArea
            value={state.notes}
            onChange={(e: any) => {
              setState({
                ...state,
                notes: e.target.value,
              });
            }}
            height={140}
          />
        </FieldWrapper>
      </>
    </Modal>
  );
};
