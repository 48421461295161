import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  fetchContractedOrganizations,
  updateOrganization,
} from 'client/actions/organizations';
import { Box } from 'client/components/Box/Box';
import type { EquipmentBlockInstanceAttribute } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { EditEquipmentBlockInstanceAttributeModal } from './EditEquipmentBlockInstanceAttributeModal';
import { EditDeleteCell } from './EditDeleteCell';
import { BookingSourcesDisplayBox } from './BookingSourcesDisplayBox';

export const SeatManagementBookingSourceGroups = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);

  const organization = useSelector(activeUserOrganizationSelector);

  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const equipmentBlockInstanceAttributes = React.useMemo(() => {
    return (
      organization?.seat_management_settings
        ?.equipment_block_instance_attributes || []
    );
  }, [organization]);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  const deleteHandler = async (
    equipmentBlockInstanceAttribute: EquipmentBlockInstanceAttribute
  ) => {
    try {
      await dispatch(
        updateOrganization(organization!.id, organization!.type, {
          seat_management_settings: {
            ...organization!.seat_management_settings,
            equipment_block_instance_attributes:
              equipmentBlockInstanceAttributes.filter(
                (item) => item.key !== equipmentBlockInstanceAttribute.key
              ),
          },
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getColumns = (
    t: TranslateFuncType
  ): ColumnType<EquipmentBlockInstanceAttribute>[] => {
    return [
      {
        Header: '',
        id: 'edit',
        accessor: (row) => {
          return (
            <EditDeleteCell
              equipmentBlockInstanceAttribute={row}
              onConfirmClick={async () => {
                await deleteHandler(row);
              }}
            />
          );
        },
        width: 100,
      },
      {
        Header: t('Title'),
        id: 'title',
        accessor: (row) => row.title,
        width: 300,
      },
      {
        Header: t('Color'),
        id: 'color',
        accessor: (row) => {
          return (
            <Box display="flex" alignItems="center">
              <div
                style={{
                  backgroundColor: row.color,
                  width: '20px',
                  height: '20px',
                }}
              ></div>
              <Box ml={1}>{row.color}</Box>
            </Box>
          );
        },
        width: 100,
      },
      {
        Header: t('Booking Source'),
        id: 'booking_source',
        accessor: (row) => {
          return (
            <BookingSourcesDisplayBox equipmentBlockInstanceAttribute={row} />
          );
        },
      },
    ];
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box__body'])}>
      <Box mb={2}>
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Booking Source')}
        </Button>
      </Box>
      {equipmentBlockInstanceAttributes?.length !== 0 && (
        <GenericTable<EquipmentBlockInstanceAttribute>
          items={(equipmentBlockInstanceAttributes ?? []).filter((_, index) => {
            const start = (currentPage - 1) * rowCount;
            const end = start + rowCount;
            return index >= start && index < end;
          })}
          totalCount={equipmentBlockInstanceAttributes?.length ?? 0}
          columns={getColumns(t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
          hideScrollButtons={true}
        />
      )}
      {showEditModal && (
        <EditEquipmentBlockInstanceAttributeModal
          equipmentBlockInstanceAttributeKey={''}
          onClose={() => setShowEditModal(false)}
          open={showEditModal}
        />
      )}
      <Loader active={loading} />
    </div>
  );
};
