import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, useForm } from 'react-final-form';
import ColorPicker from 'rc-color-picker';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { FieldWrapper, ToggleButton } from 'client/components/Form';

export const SectionBackgroundEditor = () => {
  const { t } = useTranslation();

  const form = useForm();

  const { values } = form.getState();

  return (
    <>
      <Field name="useBackgroundImage">
        {({ input }: FieldRenderProps<boolean>) => (
          <ToggleButton
            label={t('Use background image')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
      {values.useBackgroundImage ? (
        <Field name="backgroundImageUrl">
          {({ input }: FieldRenderProps<string>) => (
            <FieldWrapper label={t('Background image (jpg, png)')}>
              <ImageVideoAudioInput
                fileUrls={input.value ? [input.value] : []}
                onChange={(newValue) =>
                  newValue?.length
                    ? input.onChange(newValue[0])
                    : input.onChange('')
                }
                maxFileCount={1}
                disableYoutubeVideos
              />
            </FieldWrapper>
          )}
        </Field>
      ) : (
        <Field name="backgroundColor">
          {({ input }: FieldRenderProps<string>) => (
            <FieldWrapper label={t('Background color')}>
              <ColorPicker
                color={input.value}
                onChange={({ color }: any) => input.onChange(color)}
              />
            </FieldWrapper>
          )}
        </Field>
      )}
    </>
  );
};
