import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { PerBookingUnitsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/PerBookingUnitsEditor';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  name: string;
  currency: string;
  language?: string;
  showNet?: boolean;
};

export const PerBookingPriceInput = ({
  currency,
  name,
  language,
  showNet,
}: Props) => {
  const { t } = useTranslation();
  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');

  return (
    <div className={styles['p-box']}>
      <div className={styles['p-box__header']}>{t('Per-Booking Prices')}</div>
      <div className={styles['p-box__table']}>
        <ul
          className={clsx(
            styles['p-box__table__header'],
            baseStyles['u-spHidden']
          )}
        >
          <li
            className={clsx(
              styles['p-box__table__item'],
              baseStyles['u-width-240']
            )}
          >
            <p className={styles['p-box__table__item__ttl']}>
              {t('Amount (tax included {{currency}})', {
                currency: currency,
              })}
            </p>
          </li>
          {showNet && (
            <li
              className={clsx(
                styles['p-box__table__item'],
                baseStyles['u-width-240']
              )}
            >
              <p className={styles['p-box__table__item__ttl']}>
                {t('Net Amount ({{currency}})', {
                  currency: currency,
                })}
              </p>
            </li>
          )}
        </ul>

        <FieldArray name={name}>
          {({ fields }) => (
            <>
              {fields.map((name) => (
                <ul key={name} className={clsx(styles['p-box__table__body'])}>
                  <li
                    className={clsx(
                      styles['p-box__table__item'],
                      styles['p-box__table__item__alignTop']
                    )}
                    data-title={t('Amount (tax included {{currency}})', {
                      currency: currency,
                    })}
                  >
                    <p
                      className={clsx(
                        styles['p-box__table__item__ttl'],
                        baseStyles['u-pcHidden']
                      )}
                    >
                      {t('Amount (tax included {{currency}})', {
                        currency: currency,
                      })}
                    </p>
                    <div className={styles['p-box__item__currency']}>
                      <Field name={`${name}.price`}>
                        {({ input }) => (
                          <TextField
                            value={input.value}
                            onChange={(value) => {
                              if (!currencyInputAllowed(currency, value)) {
                                return;
                              }

                              input.onChange(value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </li>
                  {showNet && (
                    <li
                      className={clsx(
                        styles['p-box__table__item'],
                        styles['p-box__table__item__alignTop']
                      )}
                      data-title={t('Net Amount ({{currency}})', {
                        currency: currency,
                      })}
                    >
                      <p
                        className={clsx(
                          styles['p-box__table__item__ttl'],
                          baseStyles['u-pcHidden']
                        )}
                      >
                        {t('Net Amount ({{currency}})', {
                          currency: currency,
                        })}
                      </p>
                      <div className={styles['p-box__item__currency']}>
                        <Field name={`${name}.netPrice`}>
                          {({ input }) => (
                            <TextField
                              value={input.value}
                              onChange={(value) => {
                                if (!currencyInputAllowed(currency, value)) {
                                  return;
                                }

                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </li>
                  )}
                </ul>
              ))}
            </>
          )}
        </FieldArray>
      </div>
      {isProductEditOrProductCopy && (
        <PerBookingUnitsEditor
          name={`${name}[0].perBookingUnits`}
          language={language ?? ''}
        />
      )}
    </div>
  );
};
