import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import List from '@editorjs/list';
import Iframe from '@editorjs/iframe';
import LinkTool from '@editorjs/link';
import ProductList from '@editorjs/product-list';
import Paragraph from 'editorjs-paragraph-with-alignment';
import FontSize from 'editorjs-inline-font-size-tool';
import ColorPlugin from 'editorjs-text-color-plugin';
import _uniqueId from 'lodash/uniqueId';

import { fetchProducts } from 'client/actions/products';
import {
  convertToBookingWidgetUrlLangCode,
  convertSourceLanguageToBookingWidgetUrlLangCode,
} from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getBookingWidgetPmpUrlBase } from 'client/libraries/util/getBookingWidgetPmpUrlBase';
import type { ReduxState } from 'client/reducers';
import { config } from 'client/config';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import { s3MediaStorage } from 'client/libraries/s3';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { SourceLanguage } from 'shared/models/swagger';

import './Editor.css';

type Props = {
  imageS3Prefix: string;
  data: EditorJS.OutputData | null;
  onChange: (arg0: any, arg1: boolean) => void;
  style?: Record<string, string>;
  allowVideoUploads?: boolean;
  allowAudioUploads?: boolean;
  contentLanguage?: SourceLanguage;
};
type LinkConfig = {
  endpoint: string;
  apikey: string;
  baseUrl: string;
  urlLinkWord: string;
  language: string;
  supplierName: string;
  seeMoreText: string;
  showLessText: string;
  participationDateText: string;
  writtenDateText: string;
  attribution: {
    FAMILY: string;
    COUPLE: string;
    FRIEND: string;
    SOLO: string;
    BUSINESS: string;
  };
};
export const Editor = ({
  imageS3Prefix,
  data,
  onChange,
  style,
  allowVideoUploads,
  allowAudioUploads,
  contentLanguage,
}: Props) => {
  const [id] = React.useState<string>(_uniqueId('editorjs-nutmeg-'));
  const ejInstance = React.useRef<EditorJS | null>();
  const timerRef = React.useRef<NodeJS.Timeout>();
  const organization = useSelector(activeUserOrganizationSelector);
  const products = useSelector((state: ReduxState) => state.products.summaries);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let language = convertToBookingWidgetUrlLangCode(i18n.language);
  if (contentLanguage) {
    language = convertSourceLanguageToBookingWidgetUrlLangCode(contentLanguage);
  }

  const baseUrl = getBookingWidgetPmpUrlBase(organization);
  const bookingWidgetApiKey = organization?.booking_widget_api_key ?? '';
  const supplierName = organization?.name ?? '';
  const acList = products.map((product) => ({
    title: product.product_name,
    //url: baseUrl + '/' + bookingWidgetApiKey + '/products/' + product.id + '?lng=' + language,
    url: baseUrl + '/products/' + product.id + '?lng=' + language,
  }));
  //if add review attribution. add item for attribution Object.
  const linkConfig: LinkConfig = {
    endpoint: config.ogpParserEndPoint,
    apikey: bookingWidgetApiKey,
    baseUrl: baseUrl,
    urlLinkWord: t('See the plan'),
    language: language,
    supplierName: supplierName,
    seeMoreText: t('See More'),
    showLessText: t('Show Less'),
    participationDateText: t('Participation Date'),
    writtenDateText: t('Posted Date'),
    attribution: {
      FAMILY: t('Family'),
      COUPLE: t('Couple'),
      FRIEND: t('Friends / Colleagues'),
      SOLO: t('Solo'),
      BUSINESS: t('Work'),
    },
  };
  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [organization]);
  React.useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }

    return () => {
      ejInstance.current?.destroy();
      ejInstance.current = null;
    };
  }, []);

  //Note.
  //If the data in the props is changed after an instance of EditorJs is created (language change -> display content switch).
  //and the ID of the Block is different from before rendering, the content will not be re-rendered.
  //so the EditorJS CoreAPI to re-render the content.
  //It is happening only in AboutUsEditor.tsx.
  React.useEffect(() => {
    if (ejInstance.current) {
      const blockCount = ejInstance.current.blocks.getBlocksCount();
      const propsBlockCount = data?.blocks?.length || 0;
      let reRender = true;

      if (blockCount != 0) {
        for (let i = 0; i < blockCount; i++) {
          const block = ejInstance.current.blocks.getBlockByIndex(i);
          data?.blocks?.forEach((b: any) => {
            if (b?.id === block?.id) {
              reRender = false;
            }
          });
        }
        if (reRender) {
          if (propsBlockCount == 0) {
            ejInstance.current.render({ blocks: [] } as EditorJS.OutputData);
            ejInstance.current.blocks.insert();
          } else {
            ejInstance.current.render(data as EditorJS.OutputData);
          }
        }
      }
    }
  }, [data]);

  let uploadMimeTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  if (allowVideoUploads) {
    uploadMimeTypes = [...uploadMimeTypes, 'video/mp4'];
  }
  if (allowAudioUploads) {
    uploadMimeTypes = [...uploadMimeTypes, 'audio/mpeg'];
  }

  const tools: any = {
    image: {
      class: ImageTool,
      config: {
        uploader: {
          // To upload and show images
          // The image file is uploaded to the <Bucket>/editor/supplier/<supplier_id>/<filename>
          uploadByFile: (file: File) => {
            const filename = getRandomFilenameForUpload(file);
            return s3MediaStorage
              .put(`${imageS3Prefix}/${filename}`, file)
              .then(() => {
                return s3MediaStorage.get(`${imageS3Prefix}/${filename}`);
              })
              .then((url) => {
                return {
                  success: 1,
                  file: {
                    url: url.split('?')[0],
                  },
                };
              });
          },
          // To show images using exsiting image using URL
          uploadByUrl: (url: string) => {
            return new Promise((resolve) => {
              resolve({
                success: 1,
                file: {
                  url: url,
                },
              });
            });
          },
        },
        types: uploadMimeTypes.join(','),
      },
    },
    header: Header,
    list: {
      class: List,
      inlineToolbar: true,
    },
    paragraph: {
      class: Paragraph,
      inlineToolbar: true,
    },
    fontSize: FontSize,
    Color: {
      class: ColorPlugin,
      // if load from CDN, please try: window.ColorPlugin
      config: {
        colorCollections: [
          '#FF1300',
          '#EC7878',
          '#9C27B0',
          '#673AB7',
          '#3F51B5',
          '#0070FF',
          '#03A9F4',
          '#00BCD4',
          '#4CAF50',
          '#8BC34A',
          '#CDDC39',
          '#FFF',
          '#000000',
        ],
        defaultColor: '#FF1300',
        type: 'text',
      },
    },
    Marker: {
      class: ColorPlugin,
      // if load from CDN, please try: window.ColorPlugin
      config: {
        defaultColor: '#FFBF00',
        type: 'marker',
      },
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
        },
      },
    },
    iframe: {
      class: Iframe,
    },
    linkTool: {
      class: LinkTool,
      config: linkConfig,
    },
    productList: {
      class: ProductList,
      config: {
        endpoint: config.ogpParserEndPoint,
        placeHolder: t('Click to select product'),
        language: language,
        acList: acList,
      },
    },
  };

  const initEditor = () => {
    const editor = new EditorJS({
      holder: id,
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: () => {
        ejInstance.current
          ?.save()
          .then((data) => {
            onChange(data, true);
          })
          .catch((err) => {
            console.log(err);
          });

        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
          ejInstance.current
            ?.save()
            .then((data) => {
              onChange(data, false);
            })
            .catch((err) => {
              console.log(err);
            });
        }, 2000);
      },
      data: data as any,
      tools: tools,
      minHeight: 100,
      i18n: {
        messages: {
          toolNames: {
            Image: [
              'Image',
              ...(allowVideoUploads ? ['Video'] : []),
              ...(allowVideoUploads ? ['Audio'] : []),
            ].join('/'),
          },
        },
      },
    });
  };

  return (
    <div style={style}>
      <div key={id} id={id}>
        {' '}
      </div>
    </div>
  );
};
