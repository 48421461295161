import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { impersonatedAccountSelector } from 'client/reducers/user';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type { Account } from 'shared/models/swagger';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());

export const ExpiredTimeReport = () => {
  const { i18n } = useTranslation();
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const { data } = useSWR(
    [
      `/api/reservations/expired-time-aggregations`,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );

  const graphData = data?.expired_time_aggregations?.map(
    (item: any) => {
      return {
        name: item.date,
        total: item.count,
      };
    },
    [data]
  );

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={graphData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="total" fill="#8884d8" />
          <Legend />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
