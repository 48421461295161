import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import clsx from 'clsx';

import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  title: string;
  id?: string;
  children: JSX.Element | JSX.Element[];
  onDelete?: () => void;
  isDeleteable?: boolean;
  isCollapsible?: boolean;
};

export const CollapsibleBox = ({
  title,
  id,
  children,
  onDelete,
  isDeleteable = true,
  isCollapsible = true,
}: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <div id={id} className={styles['p-topPage-acBox']}>
      <div className={styles['p-topPage-acBox__header']}>
        <div className={styles['p-topPage-acBox__header__ttl']}>
          {/* TODO: hide icon now until we implement drag&drop functionality */}
          <div className={styles['p-topPage-acBox__header__ttl__ic']}>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <p className={styles['p-topPage-acBox__header__ttl__txt']}>{title}</p>
        </div>
        <div className={styles['p-topPage-acBox__header__actions']}>
          {isDeleteable && (
            <Button
              size="icon"
              color="tertiarygray"
              iconBeforeText={
                <i className="c-icon-outline-general-trash-03"></i>
              }
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
            />
          )}
          {isCollapsible && (
            <Button
              text={isActive ? t('Hide') : t('Show')}
              uiType="bg"
              size="md"
              color="tertiarygray"
              iconBeforeText={
                isActive ? (
                  <i className="c-icon-outline-general-minus"></i>
                ) : (
                  <i className="c-icon-solid-general-plus"></i>
                )
              }
              onClick={() => {
                setIsActive(!isActive);
              }}
            />
          )}
        </div>
      </div>

      <div
        className={clsx(
          styles['p-topPage-acBox__body'],
          isActive && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
