import * as React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { config } from 'client/config';
import { fetchAgentSystemFeeInvoices } from 'client/actions/agentSystemFeeInvoices';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { Box } from 'client/components/Box/Box';
import { AgentSystemFeeInvoiceSummary } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { InfoTooltipWithPortal } from 'client/components/InfoTooltipWithPortal/InfoTooltipWithPortal';
import { fetchPartnershipAgentSystemFeeInvoices } from 'client/actions/partnershipSystemFeeInvoices';
import {
  SuppliersGrossCell,
  SuppliersCancellationCell,
  SuppliersCommissionCell,
} from 'client/components/PartnershipSystemFee/PartnershipSystemFee';
import baseStyles from 'client/base.module.css';
import { Tabs } from 'client/components/Tabs/Tabs';

const SupplierLineItemsCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const { t } = useTranslation();
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        return (
          <li key={org.id}>
            {org.name} :{' '}
            {
              <Link
                to={`/partnership/agentsystemfeelineitems?supplier_id=${org.id}&month=${invoice.month}`}
              >
                {t('List')}
              </Link>
            }
          </li>
        );
      })}
    </ul>
  );
};

export type ColumnType<T> = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
};

const useColumns = (): ColumnType<AgentSystemFeeInvoiceSummary>[] => {
  const { t } = useTranslation();

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const org = useSelector(activeUserOrganizationSelector);

  return [
    {
      Header: t('Invoice Month'),
      th: true,
      accessor: (row) =>
        moment(row.month).locale(locale).format(monthYearFormat),
      textAlign: 'center',
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Gross')}
          </Box>
        </Box>
      ),
      accessor: (row: AgentSystemFeeInvoiceSummary) =>
        getFormattedAmount(row.pif_gross_amount),
      textAlign:
        'right' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Gross by supplier')}
          </Box>
        </Box>
      ),
      Cell: (cellInfo: { original: AgentSystemFeeInvoiceSummary }) => {
        return (
          <Box width="100%" display="flex" justifyContent="flex-start">
            <SuppliersGrossCell invoice={cellInfo.original} />
          </Box>
        );
      },
      width: 'long' as ColumnType<AgentSystemFeeInvoiceSummary>['width'],
      textAlign:
        'left' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Cancel Fee')}
          </Box>
        </Box>
      ),
      accessor: (row: AgentSystemFeeInvoiceSummary) =>
        getFormattedAmount(row.pif_cancellation_fee_amount),
      textAlign:
        'right' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Cancel Fee by supplier')}
          </Box>
        </Box>
      ),
      Cell: (cellInfo: { original: AgentSystemFeeInvoiceSummary }) => {
        return (
          <Box width="100%" display="flex" justifyContent="flex-start">
            <SuppliersCancellationCell invoice={cellInfo.original} />
          </Box>
        );
      },
      width: 'long' as ColumnType<AgentSystemFeeInvoiceSummary>['width'],
      textAlign:
        'left' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Commission')}
          </Box>
        </Box>
      ),
      accessor: (row: AgentSystemFeeInvoiceSummary) =>
        getFormattedAmount(row.pif_commission_amount),
      textAlign:
        'right' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('PIF')}
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {t('Commission by supplier')}
          </Box>
        </Box>
      ),
      Cell: (cellInfo: { original: AgentSystemFeeInvoiceSummary }) => {
        return (
          <Box width="100%" display="flex" justifyContent="flex-start">
            <SuppliersCommissionCell invoice={cellInfo.original} />
          </Box>
        );
      },
      width: 'long' as ColumnType<AgentSystemFeeInvoiceSummary>['width'],
      textAlign:
        'left' as ColumnType<AgentSystemFeeInvoiceSummary>['textAlign'],
    },
    {
      Header: '',
      HeaderElement: (
        <Box display="flex" alignItems="center">
          <Box>
            <Box width="100%" display="flex" justifyContent="center">
              {t('PIF')}
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              {t('Booking Fee')}
            </Box>
          </Box>
          <InfoTooltipWithPortal
            tooltipStyle={{
              width: '210px',
            }}
            text={
              <Box>
                <div>{t('PIF Booking Fee is total of the following:')}</div>
                <div>{t(' - PIF Gross * booking fee rate')}</div>
                <div>{t(' - PIF Cancel Fee * booking fee rate')}</div>
              </Box>
            }
          />
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_booking_fee_amount),
      textAlign: 'right',
    },
    {
      Header: '',
      HeaderElement: (
        <Box display="flex" alignItems="center">
          <Box>
            <Box width="100%" display="flex" justifyContent="center">
              {t('PIF')}
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              {t('Credit Card Fee')}
            </Box>
          </Box>
        </Box>
      ),
      accessor: (row) => getFormattedAmount(row.pif_credit_card_fee_amount),
      textAlign: 'right',
    },
    {
      Header: t('Billing Amount'),
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return getFormattedAmount(
            cellInfo.original.amount_due_by_nutmeg_final
          );
        } else {
          return (
            <div style={{ color: 'red' }}>
              {getFormattedAmount(cellInfo.original.amount_due_by_agent_final)}
            </div>
          );
        }
      },
      textAlign: 'right',
    },

    {
      Header: t('Due By'),
      Cell: (cellInfo) => {
        if (cellInfo.original.amount_due_by_nutmeg_final) {
          return 'Nutmeg';
        } else if (cellInfo.original.amount_due_by_agent_final) {
          return <div style={{ color: 'red' }}>{org?.name ?? ''}</div>;
        }

        return '-';
      },
      textAlign: 'center',
    },

    {
      Header: t('Reservations'),
      Cell: (cellInfo) => {
        return (
          <Box width="100%" display="flex" justifyContent="center">
            <SupplierLineItemsCell invoice={cellInfo.original} />
          </Box>
        );
      },
      textAlign: 'center',
    },
  ];
};

export const SystemFeeInvoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const useAlternateOrganization = true;

  const agentSystemFeeInvoices = useSelector(
    (state: ReduxState) => state.agentSystemFeeInvoices.summaries
  );

  const org = useSelector(activeUserOrganizationSelector);
  const currency = useSelector(defaultProductCurrencySelector);

  React.useEffect(() => {
    if (org) {
      dispatch(fetchAgentSystemFeeInvoices(useAlternateOrganization));
      dispatch(fetchContractedOrganizations(useAlternateOrganization));
    }
  }, [org]);

  const columns = useColumns();

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <CustomTable items={agentSystemFeeInvoices} columns={columns} />
    </>
  );
};

export const IndependentSystemFeeInvoices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const useAlternateOrganization = true;

  const agentSystemFeeInvoices = useSelector(
    (state: ReduxState) => state.partnershipSystemFeeInvoices.agentSummaries
  );

  const org = useSelector(activeUserOrganizationSelector);
  const currency = useSelector(defaultProductCurrencySelector);

  React.useEffect(() => {
    if (org) {
      dispatch(
        fetchPartnershipAgentSystemFeeInvoices(useAlternateOrganization)
      );
      dispatch(fetchContractedOrganizations(useAlternateOrganization));
    }
  }, [org]);

  const columns = useColumns();

  const panes = React.useMemo(() => {
    const pastInvoices = _.orderBy(
      agentSystemFeeInvoices.filter(
        (invoice) =>
          invoice.month &&
          moment(invoice.month).isBefore(moment().format('YYYY-MM'))
      ),
      'month',
      ['desc']
    );
    const futureInvoices = _.orderBy(
      agentSystemFeeInvoices.filter(
        (invoice) =>
          !(
            invoice.month &&
            moment(invoice.month).isBefore(moment().format('YYYY-MM'))
          )
      ),
      'month',
      ['asc']
    );
    return [
      {
        header: t('Monthly Fees'),
        content: () =>
          pastInvoices.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable items={pastInvoices} columns={columns} />
          ),
      },
      {
        header: t('Upcoming'),
        content: () =>
          futureInvoices.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No System Fee Invoices Found')}
            </div>
          ) : (
            <CustomTable items={futureInvoices} columns={columns} />
          ),
      },
    ];
  }, [t, agentSystemFeeInvoices, columns]);

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <Tabs panes={panes} />
    </>
  );
};

export const PartnershipAgentSystemFeeInvoices = () => {
  return (
    <>
      {!config.enablePartnershipSystemFeeInvoiceTable && <SystemFeeInvoices />}
      {config.enablePartnershipSystemFeeInvoiceTable && (
        <IndependentSystemFeeInvoices />
      )}
    </>
  );
};
