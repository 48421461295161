import * as React from 'react';

import { NETWORK_PRINTER_IP_ADDRESS_KEY } from 'client/components/Seat/utils';

export const useTicketNetworkPrinterIpAddress = () => {
  const [networkPrinterIpAddress, setNetworkPrinterIpAddress] =
    React.useState('');

  const handleChange = (value: string) => {
    setNetworkPrinterIpAddress(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Save the data to local storage
    localStorage.setItem(
      NETWORK_PRINTER_IP_ADDRESS_KEY,
      networkPrinterIpAddress
    );
  };

  React.useEffect(() => {
    // Retrieve data from local storage
    const data = localStorage.getItem(NETWORK_PRINTER_IP_ADDRESS_KEY);
    if (data) {
      setNetworkPrinterIpAddress(data);
    }
  }, []);

  return {
    networkPrinterIpAddress,
    setNetworkPrinterIpAddress: handleChange,
    saveNetworkPrinterIpAddress: handleSubmit,
  };
};
