import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { AccessReportSettings } from 'client/libraries/util/accessReportSettings';
import { FieldWrapper, Radio } from 'client/components/Form';
import { Loading } from 'client/pages/Loading';
import {
  /* initialSettings, */
  convertAccessReportDataSetsToGraphData,
  convertAccessReportDataSetsToTableData,
} from 'client/libraries/util/accessReportSettings';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { fetchAccessReportData } from 'client/actions/accessReportData';
import { setLastAccessReportSettings } from 'client/actions/accessReportSettings';
import { operationAllowed } from 'shared/models/access';
import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import styles from './AccessReport.module.css';
import { ChartPane } from './ChartPane';
import { SettingForm } from './SettingForm';
import { DetailTablePane } from './DetailTablePane';
import { convertAccessReportSettingsToQueryParams } from './utils';
import { TotalTablePane } from './TotalTablePane';
import { CompareTablePane } from './CompareTablePane';

export const AccessReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const lastAccessReportSettings = useSelector(
    (state: ReduxState) => state.accessReportSettings.lastAccessReportSettings
  );
  const fetchAccessReportDataStatus = useSelector(
    (state: ReduxState) => state.accessReportData.fetchAccessReportDataStatus
  );
  const reportData = useSelector(
    (state: ReduxState) => state.accessReportData.reportData
  );
  const [accessReportSettings, setAccessReportSettings] =
    React.useState<AccessReportSettings>(lastAccessReportSettings);
  const graphData = React.useMemo(() => {
    return convertAccessReportDataSetsToGraphData(reportData);
  }, [reportData]);
  const tableData = React.useMemo(() => {
    return convertAccessReportDataSetsToTableData(reportData);
  }, [reportData]);
  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
    }
  }, [isNutmegAdmin]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);
  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
      dispatch(setLastAccessReportSettings(accessReportSettings));
    }
  }, [accessReportSettings.unit]);

  const search = () => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
      dispatch(setLastAccessReportSettings(accessReportSettings));
    }
  };

  const graphPaneHeight = 600;

  const getBarDateKeys = () => {
    if (accessReportSettings.chartDisplayType === 'PV') {
      return reportData.length === 1
        ? ['visitCount']
        : ['visitCount', 'compareVisitCount'];
    }

    return reportData.length === 1
      ? ['userCount']
      : ['userCount', 'compareUserCount'];
  };

  const names = {
    visitCount: t('visitCount'),
    compareVisitCount: t('compareVisitCount'),
    userCount: t('userCount'),
    compareUserCount: t('compareUserCount'),
    reservationCount: t('reservationCount'),
    compareReservationCount: t('compareReservationCount'),
  };
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="BOOKING_SITE_ACCESS_REPORTS" />
      )}
      <SettingForm
        accessReportSettings={accessReportSettings}
        setAccessReportSettings={setAccessReportSettings}
        onSearch={search}
      />

      <div className={styles['space']} />

      <div className={styles['main-content']}>
        <div className={styles['form-pane']}>
          <div>
            <FieldWrapper label={t('Display')}>
              <div className={styles['horizontal-radio-button']}>
                <Radio
                  label={t('visitCount')}
                  checked={accessReportSettings.chartDisplayType === 'PV'}
                  onChange={() => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      chartDisplayType: 'PV',
                    });
                  }}
                />
                <Radio
                  label={t('userCount')}
                  checked={accessReportSettings.chartDisplayType === 'USER'}
                  onChange={() => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      chartDisplayType: 'USER',
                    });
                  }}
                />
              </div>
            </FieldWrapper>
          </div>
          <div>
            <FieldWrapper label={t('Aggregation')}>
              <div className={styles['horizontal-radio-button']}>
                <Radio
                  label={t('day')}
                  checked={accessReportSettings.unit === 'DAY'}
                  onChange={() => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      unit: 'DAY',
                    });
                  }}
                />
                <Radio
                  label={t('week')}
                  checked={accessReportSettings.unit === 'WEEK'}
                  onChange={() => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      unit: 'WEEK',
                    });
                  }}
                />
                <Radio
                  label={t('month')}
                  checked={accessReportSettings.unit === 'MONTH'}
                  onChange={() => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      unit: 'MONTH',
                    });
                  }}
                />
              </div>
            </FieldWrapper>
          </div>
        </div>

        <div
          className={styles['graph-pane-wrapper']}
          style={{
            height: graphPaneHeight,
          }}
        >
          <ChartPane
            data={graphData}
            barDataKeys={getBarDateKeys()}
            names={names}
          />
        </div>

        <div className={styles['space']} />

        <div className={styles['summary-pane-wrapper']}>
          {reportData?.length === 1 && (
            <>
              <p>{t('Total')}</p>
              <TotalTablePane data={tableData} />
            </>
          )}
          {reportData?.length === 2 && (
            <>
              <p>{t('Compare')}</p>
              <CompareTablePane dataSets={reportData} />
            </>
          )}
        </div>

        {reportData?.length === 1 && (
          <>
            <div className={styles['space']} />

            <div>
              <p>{t('Details')}</p>
              <DetailTablePane data={tableData} />
            </div>
          </>
        )}
      </div>
      {fetchAccessReportDataStatus === 'REQUESTED' && <Loading />}
    </>
  );
};
