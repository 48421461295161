import { useJsApiLoader } from '@react-google-maps/api';

const libraries: any[] = ['geometry', 'drawing', 'places', 'visualization'];

const apiKey =
  process.env.NODE_ENV !== 'development'
    ? 'AIzaSyA6Cp6BHFkjfyoAFnk4wcQYDVlI8_oymRQ'
    : 'AIzaSyCRZLXj-up47cD0oZQHMHko7f9G-Cc4SUo';

export const useGoogleMapsApi = (): ReturnType<typeof useJsApiLoader> => {
  return useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: libraries,
  });
};
