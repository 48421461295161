import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';

import { AnalyticsCustomerAutoTagConditionGroup } from './formValues';
import styles from './ConditionGroupEditor.module.css';

interface Props {
  name: string;
}

export const ConditionGroupEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  const form = useForm();
  const values: AnalyticsCustomerAutoTagConditionGroup = _.get(
    form.getState().values,
    name
  );

  const classificationOptions = [
    {
      text: t('Any'),
      value: 'ANY',
    },
    {
      text: t('Booked'),
      value: 'BOOKED',
    },
    {
      text: t('Not Booked'),
      value: 'NOT_BOOKED',
    },
  ];
  const genderOptions = [
    {
      text: t('Any'),
      value: 'ANY',
    },
    {
      text: t('Male'),
      value: 'MALE',
    },
    {
      text: t('Female'),
      value: 'FEMALE',
    },
    {
      text: t('No Response'),
      value: 'NO_RESPONSE',
    },
  ];
  const ageGroupOptions = [
    {
      text: t('Any'),
      value: 'ANY',
    },
    {
      text: t('Under 18'),
      value: 'UNDER_18',
    },
    {
      text: t('18 to 24'),
      value: '18_TO_24',
    },
    {
      text: t('25 to 34'),
      value: '25_TO_34',
    },
    {
      text: t('35 to 44'),
      value: '35_TO_44',
    },
    {
      text: t('45 to 54'),
      value: '45_TO_54',
    },
    {
      text: t('55 to 64'),
      value: '55_TO_64',
    },
    {
      text: t('65+'),
      value: '65_PLUS',
    },
    {
      text: t('Custom'),
      value: 'CUSTOM',
    },
  ];
  const deviceOptions = [
    {
      text: t('Any'),
      value: 'ANY',
    },
    {
      text: t('Desktop'),
      value: 'DESKTOP',
    },
    {
      text: t('Mobile'),
      value: 'MOBILE',
    },
    {
      text: t('Tablet'),
      value: 'TABLET',
    },
  ];
  const referralPathOptions = [
    {
      text: 'Google',
      value: 'google',
    },
    {
      text: 'Facebook',
      value: 'facebook',
    },
    {
      text: 'Instagram',
      value: 'instagram',
    },
    {
      text: 'Twitter',
      value: 'twitter',
    },
    {
      text: 'Bing',
      value: 'bing',
    },
    {
      text: 'Yahoo',
      value: 'yahoo',
    },
  ];
  const dateRangePresetOptions = [
    {
      text: t(''),
      value: '',
    },
    {
      text: t('Last 24 Hours'),
      value: 'LAST_24_HOURS',
    },
    {
      text: t('Last 1 Week'),
      value: 'LAST_1_WEEK',
    },
    {
      text: t('Last 4 Weeks'),
      value: 'LAST_4_WEEKS',
    },
    {
      text: t('Custom'),
      value: 'CUSTOM',
    },
  ];
  const dimensionTypeOptions = [
    {
      text: t('None'),
      value: 'NONE',
    },
    {
      text: t('Page Views: X or more '),
      value: 'PAGE_VIEW_COUNT_GTE',
    },
    {
      text: t('Page Views: Less than X'),
      value: 'PAGE_VIEW_COUNT_LT',
    },
    {
      text: t('Page Views: Between X and Y'),
      value: 'PAGE_VIEW_COUNT_BETWEEN',
    },
    {
      text: t('Sessions: X or more'),
      value: 'SESSION_COUNT_GTE',
    },
    {
      text: t('Sessions: Less than X'),
      value: 'SESSION_COUNT_LT',
    },
    {
      text: t('Sessions: Between X and Y'),
      value: 'SESSION_COUNT_BETWEEN',
    },
    {
      text: t('Time Spent: X or more'),
      value: 'TOTAL_TIME_SECONDS_GTE',
    },
    {
      text: t('Time Spent: Less than X'),
      value: 'TOTAL_TIME_SECONDS_LT',
    },
    {
      text: t('Time Spent: Between X and Y'),
      value: 'TOTAL_TIME_SECONDS_BETWEEN',
    },
  ];

  return (
    <div className={styles['params-container']}>
      <Field name={`${name}.dateRangePreset`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={dateRangePresetOptions}
            label={t('Time Period')}
          />
        )}
      </Field>
      {values.dateRangePreset === 'CUSTOM' && (
        <Field name={`${name}.dateRange`}>
          {({ input }) => (
            <DateRangeInput
              label={t('Custom Time Period')}
              dateFrom={input.value.startDate}
              dateTo={input.value.endDate}
              onChange={(newValue) =>
                input.onChange({
                  startDate: newValue[0],
                  endDate: newValue[1],
                })
              }
            />
          )}
        </Field>
      )}
      {values.ageGroup === 'CUSTOM' && (
        <div className={styles['dimension-container']}>
          <div>{t('Custom Age')}</div>
          <div className={styles['between-inputs-container']}>
            <Field name={`${name}.ageRange.lowerBound`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('~')}</div>
            <Field name={`${name}.ageRange.upperBound`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      <Field name={`${name}.classification`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={classificationOptions}
            label={t('Classification')}
          />
        )}
      </Field>
      <Field name={`${name}.gender`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={genderOptions}
            label={t('Gender')}
          />
        )}
      </Field>
      <Field name={`${name}.ageGroup`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={ageGroupOptions}
            label={t('Age Group')}
          />
        )}
      </Field>
      <Field name={`${name}.device`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={deviceOptions}
            label={t('Device')}
          />
        )}
      </Field>
      <Field name={`${name}.referralPaths`}>
        {({ input }) => (
          <MultiDropdown
            label={t('Landing Source')}
            selectedOptions={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={referralPathOptions}
          />
        )}
      </Field>
      <Field name={`${name}.dimensionType`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={dimensionTypeOptions}
            label={t('Dimension')}
            renderOption={(option) => {
              return (
                <>
                  {[
                    'PAGE_VIEW_COUNT_GTE',
                    'PAGE_VIEW_COUNT_LT',
                    'PAGE_VIEW_COUNT_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Page Views')}
                    </div>
                  )}
                  {[
                    'SESSION_COUNT_GTE',
                    'SESSION_COUNT_LT',
                    'SESSION_COUNT_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Sessions')}
                    </div>
                  )}
                  {[
                    'TOTAL_TIME_SECONDS_GTE',
                    'TOTAL_TIME_SECONDS_LT',
                    'TOTAL_TIME_SECONDS_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Total Time Spent')}
                    </div>
                  )}
                  {[
                    'PAGE_VIEW_COUNT_GTE',
                    'SESSION_COUNT_GTE',
                    'TOTAL_TIME_SECONDS_GTE',
                  ].includes(option.value) && <div>{t('X or more')}</div>}
                  {[
                    'PAGE_VIEW_COUNT_LT',
                    'SESSION_COUNT_LT',
                    'TOTAL_TIME_SECONDS_LT',
                  ].includes(option.value) && <div>{t('Less than X')}</div>}
                  {[
                    'PAGE_VIEW_COUNT_BETWEEN',
                    'SESSION_COUNT_BETWEEN',
                    'TOTAL_TIME_SECONDS_BETWEEN',
                  ].includes(option.value) && <div>{t('Between X and Y')}</div>}
                  {option.value === 'NONE' && <div>{t('None')}</div>}
                </>
              );
            }}
          />
        )}
      </Field>
      {values.dimensionType === 'PAGE_VIEW_COUNT_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'PAGE_VIEW_COUNT_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'PAGE_VIEW_COUNT_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    </div>
  );
};
