import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Button, Select } from 'client/components/Form';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import type { ProductSummaryWithBookmark } from 'client/reducers/products';
import { Recurrence } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

interface ProductIdRecurrenceKeyTimeSlotKeyProps {
  productId?: string;
  recurrenceKey?: string;
  timeSlotKey?: string;
}

type Props = {
  productIdRecurrenceKeyTimeSlotKeys: ProductIdRecurrenceKeyTimeSlotKeyProps[];
  onChange: (arg0: ProductIdRecurrenceKeyTimeSlotKeyProps[]) => void;
  error?: string | typeof undefined;
  filter?: (arg0: ProductSummaryWithBookmark) => boolean;
};
export const ProductIdRecurrenceKeyTimeSlotKeyListInput = ({
  productIdRecurrenceKeyTimeSlotKeys,
  onChange,
  error,
  filter,
}: Props) => {
  const { t } = useTranslation();
  const productsToChoose = useSelector(summariesWithBookmarksSelector);

  const filteredProductsToChoose = productsToChoose.filter((p) => {
    if (filter) {
      return filter(p);
    }
    return true;
  });

  const getTimeSlotKeyOptions = (productId: string, recurrenceKey: string) => {
    const product = filteredProductsToChoose.find((p) => p.id === productId);
    if (!product) {
      return [];
    }

    const recurrence = product.recurrence;
    if (!recurrence || !recurrence.length) {
      return [];
    }

    const recurrenceToChoose = recurrence.find((r) => r.key === recurrenceKey);

    if (!recurrenceToChoose) {
      return [];
    }

    return recurrenceToChoose.start_times.map((startTime) => ({
      value: startTime.time_slot_key ?? '',
      text: startTime.start_time_local ?? '',
    }));
  };

  const getRecurrenceOptions = (productId: string) => {
    const product = filteredProductsToChoose.find((p) => p.id === productId);
    if (!product) {
      return [];
    }

    const recurrence = product.recurrence;
    if (!recurrence || !recurrence.length) {
      return [];
    }

    return recurrence.map((r) => ({
      value: r.key ?? '',
      text: getRecurrenceText(r),
    }));
  };

  const getRecurrenceText = (recurrence: Recurrence) => {
    return `${recurrence.start_date_local} - ${
      recurrence.end_date_local
    } - ${recurrence.days_of_week.join(', ')}`;
  };

  return (
    <div className={baseStyles['base-form-box']}>
      {(productIdRecurrenceKeyTimeSlotKeys || []).map(
        (currentValue, currentValueIdx) => (
          <div
            key={currentValueIdx}
            className={clsx(baseStyles['inline-block'])}
          >
            <Select
              search
              options={filteredProductsToChoose.map((p) => ({
                value: p.id ?? '',
                text: getVerboseDisplayProductName(p),
              }))}
              value={currentValue.productId ?? ''}
              onChange={(_, { value: newProductId }) => {
                onChange(
                  productIdRecurrenceKeyTimeSlotKeys.map(
                    (productStartTime, idx) =>
                      idx === currentValueIdx
                        ? { productId: newProductId }
                        : productStartTime
                  )
                );
              }}
            />

            <Select
              search
              options={getRecurrenceOptions(currentValue.productId ?? '')}
              value={currentValue.recurrenceKey ?? ''}
              onChange={(_, { value: newRecurrenceKey }) => {
                const newProductIdRecurrenceKeyTimeSlotKeys = [
                  ...productIdRecurrenceKeyTimeSlotKeys,
                ];
                newProductIdRecurrenceKeyTimeSlotKeys[currentValueIdx] = {
                  ...newProductIdRecurrenceKeyTimeSlotKeys[currentValueIdx],
                  recurrenceKey: newRecurrenceKey,
                  timeSlotKey: undefined,
                };
                onChange(newProductIdRecurrenceKeyTimeSlotKeys);
              }}
            />

            <Select
              options={getTimeSlotKeyOptions(
                currentValue.productId ?? '',
                currentValue.recurrenceKey ?? ''
              )}
              value={currentValue.timeSlotKey ?? ''}
              onChange={(_, { value: timeSlotKey }) => {
                const newProductIdRecurrenceKeyTimeSlotKeys = [
                  ...productIdRecurrenceKeyTimeSlotKeys,
                ];
                newProductIdRecurrenceKeyTimeSlotKeys[currentValueIdx] = {
                  ...newProductIdRecurrenceKeyTimeSlotKeys[currentValueIdx],
                  timeSlotKey: timeSlotKey,
                };
                onChange(newProductIdRecurrenceKeyTimeSlotKeys);
              }}
            />

            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['icon'],
                baseStyles['inline']
              )}
              onClick={() => {
                const newProductIdRecurrenceKeyTimeSlotKeys = [
                  ...productIdRecurrenceKeyTimeSlotKeys,
                ];
                newProductIdRecurrenceKeyTimeSlotKeys.splice(
                  currentValueIdx,
                  1
                );
                onChange(newProductIdRecurrenceKeyTimeSlotKeys);
              }}
            >
              <img src={deleteIcon} />
            </a>
          </div>
        )
      )}

      <div className={clsx(baseStyles['list-add-btn'])}>
        <Button
          style="green"
          size="middle"
          onClick={() => {
            const newProductIdRecurrenceKeyTimeSlotKeys = [
              ...productIdRecurrenceKeyTimeSlotKeys,
            ];
            newProductIdRecurrenceKeyTimeSlotKeys.splice(
              productIdRecurrenceKeyTimeSlotKeys.length + 1,
              0,
              {}
            );
            onChange(newProductIdRecurrenceKeyTimeSlotKeys);
          }}
        >
          {t('Add')}
        </Button>
      </div>
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
    </div>
  );
};
