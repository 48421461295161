import * as React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import styles from './Modal.module.css';

const ScrollingContentContext = React.createContext(false);

type Props = {
  title: string;
  subtitle?: string;

  // If scrollingContent = true, Modal.Content will have fixed height and scrollbars if content overflows.
  scrollingContent?: boolean;
  trigger?: React.ReactElement<any>;
  open: boolean;
  onOpen?: (arg0: any) => void;
  onClose?: (arg0: any) => void;
  children: any;
  width?: 'narrow' | 'wide';
  // Render modal in a portal. This can be useful when the z-index context that the modal is defined in does not allow
  // it to be displayed on top of other elements.
  // Note: 'trigger' is not compatible with 'insertRoot'
  insertRoot?: boolean;
  transparent?: boolean;
  onPositionChange?: (args: { top: number; left: number }) => void;
  activateTouchEvents?: boolean;
};

const ModalInternal = ({
  title,
  subtitle,
  trigger,
  scrollingContent,
  open,
  onOpen,
  onClose,
  children,
  width,
  transparent,
  onPositionChange,
  activateTouchEvents,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const pos = ref?.current?.getBoundingClientRect();

  React.useEffect(() => {
    if (pos && onPositionChange && open) {
      onPositionChange({ top: pos.top, left: pos.left });
    }
  }, [pos]);

  return (
    <ScrollingContentContext.Provider value={scrollingContent ?? false}>
      <div
        className={clsx(
          componentStyles['c-modal'],
          open ? componentStyles['is-active'] : ''
        )}
        {...(activateTouchEvents ? {} : getTouchEventBoundaryProps())}
      >
        <div
          className={clsx(
            componentStyles['c-modal__frame'],
            width === 'narrow'
              ? componentStyles['width-narrow']
              : width === 'wide'
              ? componentStyles['width-wide']
              : componentStyles['width-640'],
            transparent ? componentStyles['transparent'] : ''
          )}
          ref={ref}
        >
          <div className={clsx(componentStyles['c-modal__frame__header'])}>
            {!subtitle && (
              <p
                className={clsx(
                  componentStyles['c-modal__frame__header__ttl'],
                  baseStyles['semantic-ui-react-override']
                )}
              >
                {title}
              </p>
            )}
            {subtitle && (
              <div>
                <p
                  className={clsx(
                    componentStyles['c-modal__frame__header__ttl'],
                    baseStyles['semantic-ui-react-override']
                  )}
                >
                  {title}
                </p>
                <p
                  className={
                    componentStyles['c-modal__frame__header__subtitle']
                  }
                >
                  {subtitle}
                </p>
              </div>
            )}
            <a
              className={clsx(componentStyles['c-modal__frame__header__close'])}
              onClick={onClose}
            ></a>
          </div>

          <div className={clsx(componentStyles['c-modal__frame__body'])}>
            {children}
          </div>
        </div>
        <div
          className={clsx(
            componentStyles['c-modal__overlay'],
            transparent ? componentStyles['transparent'] : ''
          )}
          onClick={onClose}
        ></div>
      </div>

      {/* NOTE(goro) change the place because of .base-main__body__header__right > *:not(:last-child) */}
      {trigger && (
        <>
          {React.cloneElement(trigger, {
            onClick: onOpen,
          })}
        </>
      )}
    </ScrollingContentContext.Provider>
  );
};

export const Modal = ({ insertRoot, ...props }: Props) => {
  /* TODO need to refactoring */
  if (insertRoot) {
    const rootEl = document.getElementById('root');
    return ReactDOM.createPortal(
      <ModalInternal {...props} />,
      rootEl as Element
    );
  } else {
    return <ModalInternal {...props} />;
  }
};

const ModalContent = ({ children }: { children: any }) => {
  const scrollingContent = React.useContext(ScrollingContentContext);

  return (
    <div className={clsx(scrollingContent && styles['modal-content'])}>
      {children ? children : null}
    </div>
  );
};

const ModalDescription = ({ children }: { children: any }) => {
  return <div>{children ? children : null}</div>;
};

const ModalHeader = ({ children }: { children: any }) => {
  return <div>{children ? children : null}</div>;
};

const ModalActions = ({
  children,
  vertical,
}: {
  children: any;
  vertical?: boolean;
}) => {
  return (
    <div
      className={clsx(
        componentStyles['c-modal__frame__body__btns'],
        vertical ? componentStyles['column'] : ''
      )}
    >
      {children ? children : null}
    </div>
  );
};

const ModalBox = ({
  children,
  column,
}: {
  children: any;
  column?: 'two' | 'three';
}) => {
  return (
    <div
      className={clsx(
        pageStyles['page-reservations__modal__box'],
        column ? pageStyles[column] : ''
      )}
    >
      {children ? children : null}
    </div>
  );
};

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Description = ModalDescription;
Modal.Actions = ModalActions;
Modal.Box = ModalBox;
