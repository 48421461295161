import * as React from 'react';

import { TwitterShareButton } from 'client/components/Sns/TwitterShareButton';
import { LineShareButton } from 'client/components/Sns/LineShareButton';
import { FacebookShareButton } from 'client/components/Sns/FacebookShareButton';
import './sns.css';

type Props = {
  url: string;
  locale: string;
  productName: string;
};
export const ShareButtons = ({ url, productName, locale }: Props) => {
  return (
    <div className="c-sns">
      <FacebookShareButton url={url} locale={locale} size={'small'} />
      <LineShareButton url={url} locale={locale} size={'small'} />
      <TwitterShareButton
        url={url}
        text={productName}
        locale={locale}
        size={'small'}
      />
    </div>
  );
};
