import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  productOptionsSelector,
  summariesSortedByBookmarkedSelector,
} from 'client/reducers/products';
import { updateOrganization } from 'client/actions/organizations';
import { fetchProductPrices } from 'client/actions/productPrices';
import { productPricesSummariesSortedByBookmarkedSelector } from 'client/reducers/productPrices';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/Modal/Modal';
import { Input, Button, Select } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateProduct } from 'client/actions/products';
import type {
  Product$Patch,
  UnitPricing,
  Recurrence,
} from 'shared/models/swagger';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

type Props = {
  onClose: () => void;
  productId?: string;
};

type FormValues = {
  jaranPlanName: string;
  recurrences: Recurrence[];
  unitPricing: UnitPricing[];
};

type Options = {
  key: string;
  text: string;
  value: string;
}[];

export const JaranMappingSettingModal = ({ onClose, productId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);
  const productOptions = useSelector(productOptionsSelector);
  const productPrices = useSelector(
    productPricesSummariesSortedByBookmarkedSelector
  );

  const [selectedProductId, setSelectedProductId] = React.useState<string>(
    productId ?? ''
  );
  const [selectedRecurrenceKey, setSelectedRecurrenceKey] =
    React.useState<string>('');
  const [selectedPriceKey, setSelectedPriceKey] = React.useState<string>('');

  React.useEffect(() => {
    dispatch(fetchProductPrices());
  }, [dispatch]);

  const product = React.useMemo(() => {
    return productSummaries.find((p) => p.id === selectedProductId);
  }, [activeUserOrganization, selectedProductId]);
  const productPrice = React.useMemo(() => {
    return productPrices.find((p) => p.id === selectedProductId);
  }, [activeUserOrganization, selectedProductId]);
  const jaranProductMapping = React.useMemo(() => {
    return activeUserOrganization?.jaran_csv_import_products ?? [];
  }, [activeUserOrganization]);

  const startTimeOptions = React.useMemo(() => {
    const optionsValue: Options = [];
    if (product?.recurrence) {
      product.recurrence.forEach((recurrence, idx) => {
        optionsValue.push({
          key: `${idx}`,
          text: `${recurrence?.start_date_local} ~ ${recurrence?.end_date_local}`,
          value: `${idx}`,
        });
      });
      setSelectedRecurrenceKey(optionsValue?.[0]?.key ?? '');
    }
    return optionsValue;
  }, [activeUserOrganization, product]);

  const unitOptions = React.useMemo(() => {
    const optionsValue: Options = [];
    if (productPrice?.pricing) {
      productPrice.pricing.forEach((price, idx) => {
        optionsValue.push({
          key: `${idx}`,
          text: `${price.start_date_local} ~ ${price.end_date_local}`,
          value: `${idx}`,
        });
      });
      setSelectedPriceKey(optionsValue?.[0]?.key ?? '');
    }
    return optionsValue;
  }, [activeUserOrganization, product]);

  //recurrence's third party reference are also used in Actim plan time zone id.
  //e.g. 890, 220
  //Using actim and jaran at the same time may cause bugs.
  const initialValues = React.useMemo(() => {
    //if (selectedRecurrenceKey === recurrence.key) {
    //  formValuesRecurrence = recurrence;
    //}
    //if (selectedPriceKey === String(idx)) {
    //  formValuesUnitPricing = price.units;
    //}
    const planName =
      activeUserOrganization?.jaran_csv_import_products?.find(
        (j) => j.product_id === selectedProductId
      )?.jaran_product_name ?? '';
    return {
      recurrences: product?.recurrence ?? [],
      unitPricing: productPrice?.pricing ?? [],
      jaranPlanName: planName,
    };
  }, [
    activeUserOrganization,
    product,
    productPrice,
    selectedRecurrenceKey,
    selectedPriceKey,
  ]);

  const convertFormValuesToProductPatch = (
    values: FormValues
  ): Product$Patch => {
    return {
      recurrence: values.recurrences,
      pricing: values.unitPricing,
    };
  };

  return (
    <Modal
      insertRoot
      title={t('Edit Jalan Settings')}
      open={true}
      onClose={onClose}
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          try {
            const targetMapping = jaranProductMapping.find(
              (j) => j.product_id === selectedProductId
            );
            if (targetMapping) {
              jaranProductMapping.forEach((j) => {
                if (j.product_id === selectedProductId) {
                  j.jaran_product_name = values.jaranPlanName;
                }
              });
            } else {
              jaranProductMapping.push({
                product_id: selectedProductId,
                jaran_product_name: values.jaranPlanName,
              });
            }
            await dispatch(
              updateProduct(
                product?.id || '',
                convertFormValuesToProductPatch(values)
              )
            );
            await dispatch(
              updateOrganization(activeUserOrganization?.id || '', 'SUPPLIER', {
                jaran_csv_import_products: jaranProductMapping,
              })
            );
            onClose();
          } catch (err) {
            console.error(err);
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={styles['c-table-list']}
                style={{ textAlign: 'center' }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '7px',
                  }}
                >
                  {t('Nutmeg Jalan Products')}
                </div>
                <div
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {t(
                    'Please enter name of Jalan products to correspond with Nutmeg product'
                  )}
                </div>
                <table>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t('Nutmeg Product')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {t('Jalan Product Name')}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        search
                        maxWidth={400}
                        value={selectedProductId}
                        options={productOptions}
                        style={{ textAlign: 'left' }}
                        onChange={(e, { value }) => {
                          setSelectedProductId(value);
                        }}
                      />
                    </td>
                    <td>
                      <Field name="jaranPlanName" key="jaranPlanName">
                        {({ input }) => <Input {...input} />}
                      </Field>
                    </td>
                  </tr>
                </table>
                <div
                  style={{
                    marginTop: '15px',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {t('Nutmeg Jalan Start Time Setting')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    marginBottom: '5px',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>{t('Date Range')}</div>
                  <Select
                    maxWidth={250}
                    style={{ textAlign: 'left' }}
                    value={selectedRecurrenceKey}
                    options={startTimeOptions}
                    onChange={(e, { value }) => {
                      setSelectedRecurrenceKey(value);
                    }}
                  />
                </div>
                <div style={{ textAlign: 'left' }}>
                  {t(
                    'Please enter start times of Jalan products to correspond with Nutmeg product start time'
                  )}
                </div>
                <table>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t('Nutmeg Start Time')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {t('Jalan Start Time')}
                    </th>
                  </tr>
                  <FieldArray name="recurrences">
                    {({ fields: recurrencesFields }) =>
                      recurrencesFields.map((name, idx) => {
                        if (String(idx) === selectedRecurrenceKey) {
                          return (
                            <FieldArray key={idx} name={`${name}.start_times`}>
                              {({ fields: startTimesFields }) => {
                                return startTimesFields.map((startName) => {
                                  return (
                                    <Field key={startName} name={startName}>
                                      {({ input }) => (
                                        <>
                                          <tr>
                                            <td>{`${input.value.start_time_local}`}</td>
                                            <td>
                                              <Field
                                                name={`${startName}.third_party_reference`}
                                              >
                                                {({ input }) => (
                                                  <Input {...input} />
                                                )}
                                              </Field>
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </Field>
                                  );
                                });
                              }}
                            </FieldArray>
                          );
                        }
                      })
                    }
                  </FieldArray>
                </table>
                <div
                  style={{
                    marginTop: '15px',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {t('Nutmeg Jalan Unit Setting')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    marginBottom: '5px',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>{t('Date Range')}</div>
                  <Select
                    maxWidth={250}
                    style={{ textAlign: 'left' }}
                    value={selectedPriceKey}
                    options={unitOptions}
                    onChange={(e, { value }) => {
                      setSelectedPriceKey(value);
                    }}
                  />
                </div>
                <div style={{ textAlign: 'left' }}>
                  {t(
                    'Please enter unit names of Jalan products to correspond with Nutmeg product units'
                  )}
                </div>
                <table>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      {t('Nutmeg Unit Name')}
                    </th>
                    <th style={{ textAlign: 'center' }}>
                      {t('Jalan Unit Name')}
                    </th>
                  </tr>
                  <FieldArray name="unitPricing">
                    {({ fields: pricingFields }) => {
                      return pricingFields.map((name, idx) => {
                        if (String(idx) === selectedPriceKey) {
                          return (
                            <FieldArray name={`${name}.units`} key={idx}>
                              {({ fields: unitFields }) => {
                                const isPerBooking = Boolean(
                                  unitFields.value?.[0]?.method ===
                                    'PER_BOOKING'
                                );
                                return unitFields.map((unit, idx2) => {
                                  return (
                                    <Field
                                      key={idx2}
                                      name={`${unit}.guest_type.key`}
                                    >
                                      {({ input }) => (
                                        <>
                                          <tr>
                                            {!isPerBooking && (
                                              <td>{`${input.value}`}</td>
                                            )}
                                            {isPerBooking && (
                                              <td style={{ color: '#dc3e15' }}>
                                                {t(
                                                  'Unit setting is not required for per-booking pricing product'
                                                )}
                                              </td>
                                            )}
                                            <td>
                                              <Field
                                                name={`${unit}.guest_type.third_party_reference`}
                                              >
                                                {({ input }) => {
                                                  return (
                                                    <Input
                                                      {...input}
                                                      disabled={isPerBooking}
                                                    />
                                                  );
                                                }}
                                              </Field>
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </Field>
                                  );
                                });
                              }}
                            </FieldArray>
                          );
                        }
                      });
                    }}
                  </FieldArray>
                </table>
              </div>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  style="green"
                  size="middle"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
