import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState, localeSelector } from 'client/reducers';
import { currency } from 'shared/libraries/currency';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { QuantityGadget } from '../../components/v3/DashboardGadgets/QuantityGadget';
import { ProgressBarGadget } from '../../components/v3/DashboardGadgets/ProgressBarGadget';

import styles from './Gadgets.module.css';
import { OnSiteGraphGadget } from './OnSiteGraphGadget';
import { AreaSalesHeatmapGadget } from './AreaSalesHeatmapGadget';
import { MediaSalesTableGadget } from './MediaSalesTableGadget';
import { OnSiteProductHourlySalesGraphGadget } from './OnSiteProductHourlySalesGraphGadget';
import { OnSitePageViewGadget } from './OnSitePageViewGadget';

export const OnSiteGadgets = () => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);
  const org = useSelector(activeUserOrganizationSelector);

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.onSiteReportData
  );

  const eventDataLoading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );

  const baseDataSet = reportDataSets[0];
  const baseTotals = baseDataSet?.totals;

  const baseGrossSales = baseTotals?.gross_sales ?? '';
  const baseConversions = baseTotals?.conversion_count ?? 0;
  const baseSessions = baseTotals?.session_count ?? 0;
  const baseUniqueUsers = baseTotals?.unique_user_count ?? 0;
  const baseConversionRate =
    baseSessions > 0 ? (100 * baseConversions) / baseSessions : 0;
  const baseSessionsPerUser =
    baseUniqueUsers > 0 ? baseSessions / baseUniqueUsers : 0;

  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;
  const comparisonTotals = comparisonDataSet?.totals;

  const comparisonGrossSales = comparisonTotals?.gross_sales ?? '';
  const comparisonConversions = comparisonTotals?.conversion_count ?? 0;
  const comparisonSessions = comparisonTotals?.session_count ?? 0;
  const comparisonUniqueUsers = comparisonTotals?.unique_user_count ?? 0;
  const comparisonConversionRate =
    comparisonSessions > 0
      ? (100 * comparisonConversions) / comparisonSessions
      : 0;
  const comparisonSessionsPerUser =
    comparisonUniqueUsers > 0 ? comparisonSessions / comparisonUniqueUsers : 0;

  const formattedCurrency =
    org?.default_currency === 'JPY' && locale === 'ja'
      ? '円'
      : org?.default_currency || '';

  return (
    <div className={styles['container']}>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Sales')}
          unit={formattedCurrency}
          amount={currency(baseGrossSales ?? '').value}
          changeAmount={
            comparisonDataSet
              ? currency(baseGrossSales ?? '').subtract(
                  currency(comparisonGrossSales ?? '')
                ).value
              : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Conversions')}
          unit={locale === 'ja' ? '件' : ''}
          amount={baseConversions}
          changeAmount={
            comparisonDataSet ? baseConversions - comparisonConversions : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Sessions')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseSessions}
          changeAmount={
            comparisonDataSet ? baseSessions - comparisonSessions : null
          }
          loading={eventDataLoading}
        />
      </div>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Unique Access Users')}
          unit={locale === 'ja' ? '人' : ''}
          amount={baseUniqueUsers}
          changeAmount={
            comparisonDataSet ? baseUniqueUsers - comparisonUniqueUsers : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Unique User Conversions')}
          unit={locale === 'ja' ? '人' : ''}
          amount={baseConversions}
          changeAmount={
            comparisonDataSet ? baseConversions - comparisonConversions : null
          }
          loading={eventDataLoading}
        />
        <ProgressBarGadget
          header={t('Conversion Rate')}
          percent={baseConversionRate}
          percentChange={
            comparisonDataSet
              ? baseConversionRate - comparisonConversionRate
              : null
          }
          loading={eventDataLoading}
        />
      </div>
      <div className={styles['container-row']}>
        <OnSitePageViewGadget />
        <QuantityGadget
          header={t('Average Session Count')}
          unit={locale === 'ja' ? '回' : ''}
          amount={baseSessionsPerUser}
          changeAmount={
            comparisonDataSet
              ? baseSessionsPerUser - comparisonSessionsPerUser
              : null
          }
          loading={eventDataLoading}
        />
        <QuantityGadget
          header={t('Average Sales')}
          unit={formattedCurrency}
          amount={currency(baseGrossSales ?? '').value}
          changeAmount={
            comparisonDataSet
              ? currency(baseGrossSales ?? '').subtract(
                  currency(comparisonGrossSales ?? '')
                ).value
              : null
          }
          loading={eventDataLoading}
        />
      </div>
      <div className={styles['container-row']}>
        <OnSiteGraphGadget />
      </div>
      <div className={styles['container-row']}>
        <MediaSalesTableGadget />
        <OnSiteProductHourlySalesGraphGadget />
      </div>
      <div className={styles['container-row']}>
        <AreaSalesHeatmapGadget />
      </div>
    </div>
  );
};
