import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { FloatInput } from 'client/components/FloatInput';
import {
  LocationSearchInput,
  SearchResult,
} from 'client/components/LocationSearchInput';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, Input } from 'client/components/Form';

import { LocationMap } from './LocationMap';

export const GuidancePageLocationEditor = () => {
  const form = useForm();
  const values = form.getState().values;

  const { t } = useTranslation();
  const [locationSearchResult, setLocationSearchResult] =
    React.useState<SearchResult | null>(null);

  return (
    <div>
      <>
        <Box mt={2}>
          <LocationSearchInput
            prompt={t('Find a location')}
            onLocationSelect={(result) => setLocationSearchResult(result)}
            location={locationSearchResult?.title ?? ''}
          />
        </Box>
        <Box mt={2} mb={2}>
          <Button
            style="green"
            size="middle"
            onClick={async () => {
              if (locationSearchResult) {
                const geocoder = new google.maps.Geocoder();

                try {
                  const result = await geocoder.geocode({
                    placeId: locationSearchResult?.key ?? '',
                  });

                  form.change(
                    'location.latitude',
                    result.results[0].geometry.location.lat()
                  );
                  form.change(
                    'location.longitude',
                    result.results[0].geometry.location.lng()
                  );
                  form.change('location.name', locationSearchResult.title);
                } catch (e) {
                  console.log('error: ', e);
                }
              }
            }}
          >
            {t('Populate Location Data')}
          </Button>
        </Box>
        <Field name="location.name">
          {({ input }) => (
            <Input
              label={t('Location Name')}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        </Field>
        <Box display="flex" mt={2}>
          <Box maxWidth="300px">
            <FieldWrapper label={t('Latitude')}>
              <Field name="location.latitude">
                {({ input }) => (
                  <FloatInput
                    value={input.value}
                    onChange={(amount) => input.onChange(amount)}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
          <Box ml={2} maxWidth="300px">
            <FieldWrapper label={t('Longitude')}>
              <Field name="location.longitude">
                {({ input }) => (
                  <FloatInput
                    value={input.value}
                    onChange={(amount) => input.onChange(amount)}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          {values?.location?.latitude &&
            values?.location?.longitude &&
            values?.location?.name && (
              <LocationMap
                locations={[
                  {
                    renderContent: () => <div>{values?.location?.name}</div>,
                    latitude: values?.location?.latitude,
                    longitude: values?.location?.longitude,
                  },
                ]}
              />
            )}
        </Box>
      </>
    </div>
  );
};
