// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import type Moment from 'moment-timezone';

import {
  fetchReservationByID,
  standbyReservation,
} from 'client/actions/reservations';
import { getStartTime } from 'client/libraries/util/coreutil';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { Reservation } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';
import type { TranslateFuncType } from 'client/components/Translate';
import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Button,
  TextArea,
  Input,
  DateTimeInput,
} from 'client/components/Form';
import { Divider } from 'client/components/Divider/Divider';
import { TimePicker } from 'client/components/TimePicker/TimePicker';

type Props = {
  t: TranslateFuncType,
  reservationID: string,
  reservation?: Reservation,
  onStandbyCompleted?: (Reservation) => void,
  locale: string,
  loading: boolean,
  monthYearFormat: string,
  standbyReservation: (string, string, string, string) => Promise<Object>,
  fetchReservationByID: (string) => Promise<Object>,
  trigger: React.Element<'a' | 'button'>,
};

type State = {
  standbyTime: Moment,
  standbyDate: Moment,
  standbyDateFocused: boolean,
  showModal: boolean,
  supplier_notes: string,
  supplier_reference: string,
};

class StandbyReservationButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const after24Hours = moment()
      .tz(this.props.reservation?.start_timezone)
      .add(24, 'hours');
    this.state = {
      standbyTime: moment(after24Hours),
      standbyDate: moment(after24Hours),
      standbyDateFocused: false,
      showModal: false,
      supplier_notes: '',
      supplier_reference:
        (this.props.reservation && this.props.reservation.supplier_reference) ||
        '',
    };
  }

  getStandbyDateTime = (): Moment => {
    const m = moment(this.state.standbyDate);
    m.set('hours', this.state.standbyTime.hours());
    m.set('minutes', this.state.standbyTime.minutes());

    return m;
  };

  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }

    this.setState({
      showModal: true,
    });
  };

  handleSupplierNotesChange = (e) => {
    this.setState({ supplier_notes: e.target.value });
  };

  handleSupplierReferenceChange = (e) => {
    this.setState({ supplier_reference: e.target.value });
  };

  render() {
    const { reservation, reservationID, loading, locale, t, trigger } =
      this.props;
    const startTime = reservation && getStartTime(reservation).locale(locale);

    const productName = (reservation && reservation.product_name) || '';
    const guests = (reservation && reservation.guests) || [];

    return (
      <Modal
        title={t('Put reservation on standby')}
        trigger={trigger}
        open={this.state.showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={this.fetchReservation}
      >
        <Modal.Content>
          {loading ? (
            <ModalLoader />
          ) : (
            <>
              <Modal.Box>
                <FieldWrapper label={t('Product')}>{productName}</FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Start time')}>
                  {moment(startTime).format('lll')}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Total participants')}>
                  {guests.length}
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box>
                <FieldWrapper label={t('Standby until:')}>
                  <p></p>
                </FieldWrapper>
              </Modal.Box>

              <Modal.Box column="two">
                <DateTimeInput
                  value={this.state.standbyDate}
                  onChange={(date) => {
                    const newDate = this.state.standbyDate
                      .clone()
                      .set('year', date.year())
                      .set('month', date.month())
                      .set('date', date.date());
                    this.setState({
                      standbyDate: newDate,
                      standbyTime: newDate,
                    });
                  }}
                />

                <TimePicker
                  allowEmpty={false}
                  value={this.state.standbyDate}
                  onChange={(time) => {
                    if (!time) {
                      return;
                    }
                    const newDate = this.state.standbyDate
                      .clone()
                      .set('hour', time.hour())
                      .set('minute', time.minute());
                    this.setState({
                      standbyDate: newDate,
                      standbyTime: newDate,
                    });
                  }}
                />
              </Modal.Box>
            </>
          )}

          <Divider />

          <ReservationActorInputForm />

          <TextArea
            label={t('Replies')}
            value={this.state.supplier_notes}
            onChange={this.handleSupplierNotesChange}
          />
          <Input
            label={t('Confirmation Number')}
            value={this.state.supplier_reference}
            onChange={this.handleSupplierReferenceChange}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            style="yellow"
            size="middle"
            inverted
            onClick={() => {
              this.props
                .standbyReservation(
                  reservationID,
                  this.getStandbyDateTime().utc().format(),
                  this.state.supplier_notes,
                  this.state.supplier_reference
                )
                .then(
                  (res) =>
                    this.props.onStandbyCompleted &&
                    this.props.onStandbyCompleted(res)
                );
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Standby')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps) => ({
  locale: state.language.selected.iso,
  loading: state.reservations.loading,
  monthYearFormat: state.language.selected.monthYearFormat,
  reservation: state.reservations.byID[ownProps.reservationID],
});

const mapDispatchToProps = (dispatch) => ({
  standbyReservation: (id, untilTime, supplier_notes, supplier_reference) =>
    dispatch(
      standbyReservation(id, untilTime, supplier_notes, supplier_reference)
    ),
  fetchReservationByID: (id) => dispatch(fetchReservationByID(id)),
});

export const StandbyReservationButtonV2 = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(StandbyReservationButton);
