import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getWaiverStatus } from 'client/libraries/util/getWaiverStatusText';
import type { Reservation } from 'shared/models/swagger';
import { WaiverDisplay } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Waiver/WaiverDisplay';
import baseStyles from 'client/v3-base.module.css';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
};

export const Waiver = ({ reservation }: Props) => {
  const { t } = useTranslation();

  // If waiver status is NOT_APPLICABLE, do not show waiver section
  const waiverStatus = getWaiverStatus(reservation);
  if (waiverStatus === 'NOT_APPLICABLE') {
    return <></>;
  }

  return (
    <section
      id="waiver"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>{t('Waiver')}</p>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-agreement']}>
            <WaiverDisplay reservation={reservation} />
          </div>
        </div>
      </div>
    </section>
  );
};
