import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import { ReduxState } from 'client/reducers';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchAgentSystemFeeInvoicesRequest = () => ({
  type: FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
});

const fetchAgentSystemFeeInvoicesSuccess = (response: any) => ({
  type: FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
  response,
});

const fetchAgentSystemFeeInvoicesFailure = (error: any) => ({
  type: FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  error,
});

export const fetchAgentSystemFeeInvoices =
  (useAlternateOrganization?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchAgentSystemFeeInvoicesRequest());
    return axios
      .get('/api/agentsystemfeeinvoices', {
        headers: getHTTPRequestHeaders(getState(), {
          useAlternateOrganization,
        }),
      })
      .then((result) =>
        dispatch(fetchAgentSystemFeeInvoicesSuccess(result.data))
      )
      .catch((error) => dispatch(fetchAgentSystemFeeInvoicesFailure(error)));
  };
