import { CSSProperties, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { Button } from 'client/components/v3/Common/Button';

import styles from './Modal.module.css';

type Props = {
  title: string;
  subtitle?: string;
  style?: CSSProperties; // Use this to style the content of modal, eg. set fixed height to make content scrollable vertically
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  leftActionChildren?: JSX.Element;
  rightActionChildren?: JSX.Element;
  headerActionChildren?: JSX.Element;
  useCloseButton?: boolean; // Use this to have close button at the top right of modal
  // Render modal in a portal. This can be useful when the z-index context that the modal is defined in does not allow
  // it to be displayed on top of other elements.
  insertAtRoot?: boolean;
  allowContentOverflow?: boolean; // Allow content to overflow, eg. date input to show over modal content
};

export const ModalInternal = ({
  title,
  subtitle,
  style,
  open,
  onClose,
  children,
  leftActionChildren,
  rightActionChildren,
  headerActionChildren,
  useCloseButton = false,
  allowContentOverflow = false,
}: Props) => {
  const divRef = useRef<HTMLDivElement | null>(null);

  // Close modal when clicked outside
  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (target instanceof Node && !divRef?.current?.contains(target)) {
        onClose();
      }
    };

    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Disable scrolling when modal is opened
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <div
      className={styles['c-modal']}
      style={{ display: open ? 'flex' : 'none' }}
    >
      <div
        ref={divRef}
        className={styles['c-modal__content']}
        style={style}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <div className={styles['c-modal__content__header']}>
          <div>
            <p>{title}</p>
            {subtitle && <span>{subtitle}</span>}
          </div>
          {useCloseButton && (
            <Button
              size="icon"
              color="white"
              onClick={onClose}
              iconAfterText={<i className="c-icon-outline-general-x-close"></i>}
            />
          )}
          {!useCloseButton && headerActionChildren && (
            <div className={styles['c-modal__content__header__actions']}>
              {headerActionChildren}
            </div>
          )}
        </div>
        <div
          className={clsx(
            styles['c-modal__content__body'],
            allowContentOverflow && styles['front']
          )}
        >
          {children}
        </div>
        <div className={styles['c-modal__content__actions']}>
          <div className={styles['c-modal__content__actions__left']}>
            {leftActionChildren}
          </div>
          <div className={styles['c-modal__content__actions__right']}>
            {rightActionChildren}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Modal = ({ insertAtRoot, ...props }: Props) => {
  /* TODO need to refactoring */
  if (insertAtRoot) {
    const rootEl = document.getElementById('root');
    return ReactDOM.createPortal(
      <ModalInternal {...props} />,
      rootEl as Element
    );
  } else {
    return <ModalInternal {...props} />;
  }
};
