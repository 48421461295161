import { useTranslation } from 'react-i18next';

import { FieldWrapper, Checkbox } from 'client/components/Form';

type Day = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
const days: Day[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
export const WeekdayInput = ({
  disabled,
  label,
  value,
  setValue,
}: {
  disabled?: boolean;
  label: string;
  value: Day[] | null | undefined;
  setValue: (arg0: Day[]) => void;
}) => {
  const { t } = useTranslation();
  return (
    <FieldWrapper label={label}>
      {days.map((d) => (
        <Checkbox
          disabled={disabled}
          key={d}
          label={t(
            t(d.substring(0, 1).toUpperCase() + d.substring(1).toLowerCase())
          )}
          value={d}
          checked={(value && value.indexOf(d) !== -1) ?? false}
          onChange={(e, { value: day }) => {
            const s = new Set(value || []);

            if (s.has(day as Day)) {
              s.delete(day as Day);
            } else {
              s.add(day as Day);
            }

            setValue(days.filter((d) => s.has(d)));
          }}
          withoutFrame={true}
        />
      ))}
    </FieldWrapper>
  );
};
