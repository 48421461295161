import { combineReducers } from 'redux';

import {
  ADD_SUBSCRIPTION_REQUEST,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
} from 'client/constants/ActionTypes';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case ADD_SUBSCRIPTION_REQUEST:
      return true;

    case ADD_SUBSCRIPTION_SUCCESS:
    case ADD_SUBSCRIPTION_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case ADD_SUBSCRIPTION_FAILURE:
      return action.error;

    case ADD_SUBSCRIPTION_REQUEST:
    case ADD_SUBSCRIPTION_SUCCESS:
      return '';

    default:
      return state;
  }
};

export const subscriptions = combineReducers({
  loading,
  error,
});
