import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_GUIDANCE_COUPONS_SUCCESS,
  FETCH_GUIDANCE_COUPONS_FAILURE,
  FETCH_GUIDANCE_COUPONS_REQUEST,
  DELETE_GUIDANCE_COUPON_FAILURE,
  DELETE_GUIDANCE_COUPON_REQUEST,
  DELETE_GUIDANCE_COUPON_SUCCESS,
  CREATE_GUIDANCE_COUPON_REQUEST,
  UPDATE_GUIDANCE_COUPON_FAILURE,
  UPDATE_GUIDANCE_COUPON_SUCCESS,
  UPDATE_GUIDANCE_COUPON_REQUEST,
  CREATE_GUIDANCE_COUPON_FAILURE,
  CREATE_GUIDANCE_COUPON_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { GuidanceCouponParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGuidanceCouponsRequest = () => ({
  type: FETCH_GUIDANCE_COUPONS_REQUEST,
});

const fetchGuidanceCouponsSuccess = (response: any) => ({
  type: FETCH_GUIDANCE_COUPONS_SUCCESS,
  response,
});

const fetchGuidanceCouponsFailure = (error: string) => ({
  type: FETCH_GUIDANCE_COUPONS_FAILURE,
  error,
});

let fetchGuidanceCouponsCancel: () => void | null;
export const fetchGuidanceCoupons =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGuidanceCouponsCancel && fetchGuidanceCouponsCancel();
    dispatch(fetchGuidanceCouponsRequest());
    axios
      .get('/api/guidancecoupons', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGuidanceCouponsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchGuidanceCouponsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGuidanceCouponsFailure('canceled'));
        else dispatch(fetchGuidanceCouponsFailure(error));
      });
  };

const deleteGuidanceCouponRequest = (id: string) => ({
  type: DELETE_GUIDANCE_COUPON_REQUEST,
  id,
});
const deleteGuidanceCouponSuccess = (response: any, id: string) => ({
  type: DELETE_GUIDANCE_COUPON_SUCCESS,
  response,
  id,
});
const deleteGuidanceCouponFailure = (error: string) => ({
  type: DELETE_GUIDANCE_COUPON_FAILURE,
  error,
});
export const deleteGuidanceCoupon =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGuidanceCouponRequest(id));
    axios
      .delete(`/api/guidancecoupons/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteGuidanceCouponSuccess(result, id)))
      .catch((error) => dispatch(deleteGuidanceCouponFailure(error)));
  };

const createGuidanceCouponRequest = (
  newGuidanceCoupon: GuidanceCouponParams
) => ({
  type: CREATE_GUIDANCE_COUPON_REQUEST,
  newGuidanceCoupon,
});
const createGuidanceCouponSuccess = (response: any) => ({
  type: CREATE_GUIDANCE_COUPON_SUCCESS,
  response,
});
const createGuidanceCouponFailure = (error: string) => ({
  type: CREATE_GUIDANCE_COUPON_FAILURE,
  error,
});

export const createGuidanceCoupon =
  (newGuidanceCoupon: GuidanceCouponParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGuidanceCouponRequest(newGuidanceCoupon));
    return axios
      .post('/api/guidancecoupons', newGuidanceCoupon, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createGuidanceCouponSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createGuidanceCouponFailure(err.message));
      });
  };

const updateGuidanceCouponRequest = (
  id: string,
  patch: GuidanceCouponParams
) => ({
  type: UPDATE_GUIDANCE_COUPON_REQUEST,
  id,
  patch,
});
const updateGuidanceCouponSuccess = (response: any) => ({
  type: UPDATE_GUIDANCE_COUPON_SUCCESS,
  response,
});
const updateGuidanceCouponFailure = (error: string) => ({
  type: UPDATE_GUIDANCE_COUPON_FAILURE,
  error,
});

export const updateGuidanceCoupon =
  (id: string, patch: GuidanceCouponParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGuidanceCouponRequest(id, patch));
    return axios
      .patch(`/api/guidancecoupons/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateGuidanceCouponSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateGuidanceCouponFailure(err.message));
      });
  };
