import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Input } from 'client/components/Form';
import { matchesFormat } from 'shared/libraries/validate/validator';

interface FormValues {
  toAddress: string;
}

interface Props {
  loading: boolean;
  showGuestEmailEditModal: boolean;
  onResendEmail: (toAddress?: string) => Promise<void>;
}

export const ResendEmailButton = ({
  loading,
  showGuestEmailEditModal,
  onResendEmail,
}: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const validateEmailAddress = React.useCallback(
    (email?: any) => {
      console.log('validating');
      if (!email) return t('Required');
      if (!matchesFormat(email, 'email')) return t('Invalid Email');
      return undefined;
    },
    [t]
  );

  return (
    <>
      {showModal && (
        <Modal
          title={t('Resend Email')}
          insertRoot
          open={true}
          onClose={() => setShowModal(false)}
        >
          <Form
            initialValues={{}}
            onSubmit={(values: FormValues) => {
              onResendEmail(values.toAddress);
              setShowModal(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <Field name="toAddress" validate={validateEmailAddress}>
                    {({ input }) => (
                      <Input label={t('Email Address')} {...input} />
                    )}
                  </Field>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    loading={loading}
                    size="small"
                    style="blue"
                    type="submit"
                  >
                    {t('Resend')}
                  </Button>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
      <Button
        size="small"
        style="blue"
        loading={loading}
        onClick={() => {
          if (showGuestEmailEditModal) {
            setShowModal(true);
          } else {
            onResendEmail();
          }
        }}
      >
        {t('Resend')}
      </Button>
    </>
  );
};
