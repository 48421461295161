import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { DateTimeTZPicker } from 'client/components/Form/lib/DateTimeTZPicker';
import { FieldWrapper, Button } from 'client/components/Form';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

type Props = {
  label: string;
  dates: string[];
  setDates: (arg0: string[]) => void;
};

export const DateListInput = ({ label, dates, setDates }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <FieldWrapper label={label}>
        {dates.map((date, idx) => (
          <div key={idx} className={clsx(baseStyles['date-list-inline-block'])}>
            <DateTimeTZPicker
              value={date ? moment(date) : null}
              onChange={(newDate) =>
                setDates(
                  dates.map((d, idx2) =>
                    idx2 === idx ? newDate.format('YYYY-MM-DD') : d
                  )
                )
              }
            />
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['icon'],
                baseStyles['inline']
              )}
              onClick={() => {
                const newDates = [...(dates || [])];
                newDates.splice(idx, 1);
                setDates(newDates);
              }}
            >
              <img src={deleteIcon} />
            </a>
          </div>
        ))}
      </FieldWrapper>
      <div className={clsx(baseStyles['list-add-btn'])}>
        <Button
          style="green"
          size="middle"
          onClick={() => {
            const newDates = [...(dates || [])];
            newDates.splice(dates.length + 1, 0, '');
            setDates(newDates);
          }}
        >
          {t('Add')}
        </Button>
      </div>
    </>
  );
};
