import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import type { GraphDataItem } from 'client/libraries/util/digitalMapAccessReportSettings';
import { v3Colors } from 'client/libraries/util/colors';

type Props = {
  data: GraphDataItem[];
  barDataKeys: string[];
  names: Record<string, string>;
};
export const ChartPane = ({ data, barDataKeys, names }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          label={{
            value: names[barDataKeys[0]],
            position: 'left',
            angle: -90,
          }}
          allowDecimals={false}
        />
        <Tooltip
          formatter={(value: any) => {
            return value;
          }}
        />
        <Legend />
        {barDataKeys.map((barDataKey, idx) => {
          return (
            <Bar
              key={idx}
              dataKey={barDataKey}
              fill={v3Colors[idx]}
              name={names[barDataKey]}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
