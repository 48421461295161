import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  activeUserOrganizationSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { hideEmbedCalendar } from 'client/components/ESaas/Keihan/utils';
import componentStyles from 'client/components/components.module.css';
import { CustomDomainTab } from 'client/pages/v3/BookingWidget/WebSiteIntegration/Tab/CustomDomainTab';
import { EmbedCalendarTab } from 'client/pages/v3/BookingWidget/WebSiteIntegration/Tab/EmbedCalendarTab';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

import { FloatingButtonTab } from './Tab/FloatingButtonTab';
import { FareharborTab } from './Tab/FareharborTab';

type TabType = 'CUSTOM_DOMAIN' | 'EMBED' | 'FLOAT_BUTTON' | 'FAREHARBOR';

export const WebSiteIntegrationBody = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const [activeTab, setActiveTab] = React.useState<TabType>('CUSTOM_DOMAIN');

  return (
    <div className={baseStyles['l-main__body']}>
      <section className={styles['g-section']}>
        <div
          className={clsx(styles['p-inquiry-tabs'], styles['js-tab-scope'])}
          style={{ marginBottom: '70px' }}
        >
          <div className={styles['p-inquiry-tabs__head']}>
            <div className={styles['p-inquiry-tabs__tab-scroll']}>
              <ul className={styles['p-inquiry-tabs__tab-list']}>
                <li
                  className={clsx(
                    styles['p-inquiry-tabs__tab'],
                    activeTab === 'CUSTOM_DOMAIN'
                      ? styles['components_is-active__B15DT']
                      : ''
                  )}
                  style={{ display: 'block' }}
                  onClick={() => {
                    setActiveTab('CUSTOM_DOMAIN');
                  }}
                >
                  <a>{t('Custom Domain')}</a>
                </li>
                {!hideEmbedCalendar(activeUserOrganization) && (
                  <>
                    <li
                      className={clsx(
                        styles['p-inquiry-tabs__tab'],
                        activeTab === 'EMBED'
                          ? styles['components_is-active__B15DT']
                          : ''
                      )}
                      style={{ display: 'block' }}
                      onClick={() => {
                        setActiveTab('EMBED');
                      }}
                    >
                      <a>{t('Embed')}</a>
                    </li>
                    <li
                      className={clsx(
                        styles['p-inquiry-tabs__tab'],
                        activeTab === 'FLOAT_BUTTON'
                          ? styles['components_is-active__B15DT']
                          : ''
                      )}
                      style={{ display: 'block' }}
                      onClick={() => {
                        setActiveTab('FLOAT_BUTTON');
                      }}
                    >
                      <a>{t('Floating Button')}</a>
                    </li>
                  </>
                )}
                {loggedInAccountIsNutmegAdmin && (
                  <li
                    className={clsx(
                      styles['p-inquiry-tabs__tab'],
                      activeTab === 'FAREHARBOR'
                        ? componentStyles['is-active']
                        : ''
                    )}
                    onClick={() => {
                      setActiveTab('FAREHARBOR');
                    }}
                  >
                    <a>{t('FareHarbor')}</a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className={styles['p-inquiry-tabs__body']}>
            {activeTab === 'CUSTOM_DOMAIN' && <CustomDomainTab />}
            {activeTab === 'EMBED' && <EmbedCalendarTab />}
            {activeTab === 'FLOAT_BUTTON' && <FloatingButtonTab />}
            {activeTab === 'FAREHARBOR' && <FareharborTab />}
          </div>
        </div>
      </section>
    </div>
  );
};
