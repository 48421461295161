import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_CUSTOM_TOP_PAGE_REQUEST,
  FETCH_CUSTOM_TOP_PAGE_SUCCESS,
  FETCH_CUSTOM_TOP_PAGE_FAILURE,
  PUT_CUSTOM_TOP_PAGE_REQUEST,
  PUT_CUSTOM_TOP_PAGE_SUCCESS,
  PUT_CUSTOM_TOP_PAGE_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { SourceLanguage } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchCustomTopPageRequest = () => ({
  type: FETCH_CUSTOM_TOP_PAGE_REQUEST,
});

const fetchCustomTopPageSuccess = (response: any) => ({
  type: FETCH_CUSTOM_TOP_PAGE_SUCCESS,
  response,
});

const fetchCustomTopPageFailure = (error: any) => ({
  type: FETCH_CUSTOM_TOP_PAGE_FAILURE,
  error,
});

let fetchCustomTopPageCancel: () => void | typeof undefined;
export const fetchCustomTopPage =
  (language: SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomTopPageCancel && fetchCustomTopPageCancel();
    dispatch(fetchCustomTopPageRequest());
    axios
      .get('/api/customtoppage', {
        params: {
          language,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomTopPageCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchCustomTopPageSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchCustomTopPageFailure('canceled'));
        else dispatch(fetchCustomTopPageFailure(error));
      });
  };

const putCustomTopPageRequest = () => ({
  type: PUT_CUSTOM_TOP_PAGE_REQUEST,
});

const putCustomTopPageSuccess = (response: any) => ({
  type: PUT_CUSTOM_TOP_PAGE_SUCCESS,
  response,
});

const putCustomTopPageFailure = (error: any) => ({
  type: PUT_CUSTOM_TOP_PAGE_FAILURE,
  error,
});

let putCustomTopPageCancel: () => void | typeof undefined;
export const putCustomTopPage =
  (data: string, language: SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    putCustomTopPageCancel && putCustomTopPageCancel();
    dispatch(putCustomTopPageRequest());
    axios
      .put(
        '/api/customtoppage',
        {
          data,
          language,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(function executor(c) {
            putCustomTopPageCancel = c;
          }),
        }
      )
      .then((result) => {
        dispatch(putCustomTopPageSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(putCustomTopPageFailure('canceled'));
        else dispatch(putCustomTopPageFailure(error));
      });
  };
