import * as React from 'react';
import { Field } from 'react-final-form';

import { BaseTemplate } from './BaseTemplate';
import { withQueryParams, truncated, RichPlan } from './util';

const RichPlanListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/RichPlanListEditButton')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  planSections: RichPlan[];
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const SeasonalEvent = ({
  editMode,
  language,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  planSections,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1em .5em',
              backgroundColor: '#fff',
            }}
          >
            <p style={{ marginTop: '.5em' }}>
              {customerName
                ? customerIntroduction.replace('{customerName}', customerName)
                : customerIntroduction}
              <br />
              <br />

              {editMode && (
                <Field name="templateValues.introParagraph">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <span style={{ whiteSpace: 'pre-line' }}>{introParagraph}</span>
            </p>
          </div>
        </td>
      </tr>
      {editMode && (
        <Field name="templateValues.planSections">
          {({ input }) => (
            <RichPlanListEditButton
              initialValue={input.value}
              onSave={(newValue) => input.onChange(newValue)}
              language={language}
            />
          )}
        </Field>
      )}
      {planSections.map((planSection) => (
        <tr key={planSection.key} style={{ margin: 0, padding: 0 }}>
          <td>
            <div
              style={{
                margin: 0,
                padding: '1em .5em',
                backgroundColor: '#ffffff',
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: '.5em',
                  fontWeight: 'bold',
                  borderRadius: '4px',
                  color: '#ffffff',
                  backgroundColor: '#4A6C7A',
                  whiteSpace: 'pre-line',
                }}
              >
                {planSection.title}
              </p>
              <div
                style={{
                  width: '100%',
                  marginTop: '1em',
                  background: '#ddd',
                  overflow: 'hidden',
                }}
              >
                <img src={planSection.keyVisualUrl} style={{ width: '100%' }} />
              </div>
              <div
                style={{
                  borderRadius: '4px',
                  overflow: 'hidden',
                  border: '1px solid #FFCC00',
                  background: '#FFF8ED',
                }}
              >
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    background: '#FFCC00',
                    color: '#ffffff',
                    padding: '0 .5em',
                    whiteSpace: 'pre-line',
                  }}
                >
                  <img
                    src="https://assets.ntmg.com/newsletter/point.png"
                    style={{
                      width: '40px',
                      height: '40px',
                      verticalAlign: 'middle',
                    }}
                  />
                  {planSection.highlightText}
                </div>
                <div style={{ paddingBottom: '0.5em' }}>
                  {planSection.highlights.map((highlight) => (
                    <p
                      key={highlight}
                      style={{
                        width: '100%',
                        display: 'table',
                        margin: 0,
                        padding: '.5em',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        borderBottom: '1px dashed #FFCC00',
                        boxSizing: 'border-box',
                      }}
                    >
                      <img
                        src="https://assets.ntmg.com/newsletter/check.png"
                        style={{
                          width: '26px',
                          height: '26px',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                        }}
                      />
                      <span
                        style={{
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          whiteSpace: 'pre-line',
                          paddingLeft: '4px',
                          width: '100%',
                        }}
                      >
                        {highlight}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
              {planSection.linkType === 'PRODUCT' && (
                <a
                  href={withQueryParams(planSection.linkUrl, {
                    utm_source: 'ntmg.newsletter',
                    utm_medium: 'email',
                    utm_campaign: newsletterCampaignId ?? 'todo',
                    utm_content: 'mainProduct',
                    lng: language,
                  })}
                  style={{
                    margin: '1em 0 0 0',
                    padding: 0,
                    background: '#F9F9F9',
                    display: 'table',
                    textDecoration: 'none',
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    style={{
                      margin: '2em 0.5em 1em',
                      position: 'relative',
                      verticalAlign: 'middle',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={planSection.productThumbnailUrl}
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      padding: '.5em',
                      color: '#333333',
                      verticalAlign: 'middle',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: '16px',
                        fontWeight: 'bold',
                        lineHeight: '1.5em',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {planSection.productName}
                    </p>
                    <p
                      style={{
                        margin: '.5em 0 0 0',
                        padding: 0,
                        fontSize: '12px',
                        color: '#4A6C7A',
                        lineHeight: '1.5em',
                      }}
                    >
                      {truncated(planSection.productDescription, 200)}
                    </p>
                  </div>
                </a>
              )}
              <div style={{ margin: '1em auto 0 auto' }}>
                <a
                  href={withQueryParams(planSection.linkUrl, {
                    utm_source: 'ntmg.newsletter',
                    utm_medium: 'email',
                    utm_campaign: newsletterCampaignId ?? 'todo',
                    utm_content: 'mainProduct',
                    lng: language,
                  })}
                  style={{
                    width: '320px',
                    border: '1px solid #008EC9',
                    borderRadius: '48px',
                    textDecoration: 'none',
                    padding: '2px',
                    display: 'block',
                    margin: '0 auto',
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span
                    style={{
                      margin: 0,
                      padding: 0,
                      display: 'block',
                      fontWeight: 'bold',
                      width: '100%',
                      height: '48px',
                      lineHeight: '48px',
                      fontSize: '20px',
                      backgroundColor: '#008EC9',
                      color: '#ffffff',
                      textDecoration: 'none',
                      textAlign: 'center',
                      borderRadius: '40px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {planSection.detailsButtonText}
                  </span>
                </a>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </BaseTemplate>
  );
};
