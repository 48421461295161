import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { updateManifestSettings } from 'client/actions/manifestSettings';
import { ReduxState } from 'client/reducers';
import {
  manifestFuseStartTimesSelector,
  manifestShowReservationsOnAllOperatingDaysSelector,
} from 'client/reducers/manifestSettings';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  showCheckinCheckout: boolean;
  onToggleShowCheckinCheckout: () => void;
  showDispatchColumn: boolean;
  onToggleShowDispatchColumn: () => void;
  showByProductGroup: boolean;
  onToggleShowByProductGroup: () => void;
};

export const VisibilitySettingsModal = ({
  open,
  onClose,
  title,
  showCheckinCheckout,
  onToggleShowCheckinCheckout,
  showDispatchColumn,
  onToggleShowDispatchColumn,
  showByProductGroup,
  onToggleShowByProductGroup,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fuseStartTimes = useSelector((state: ReduxState) =>
    manifestFuseStartTimesSelector(state)
  );
  const showReservationsOnAllOperatingDays = useSelector((state: ReduxState) =>
    manifestShowReservationsOnAllOperatingDaysSelector(state)
  );
  const [splitStartTimes, setSplitStartTimes] = useState(!fuseStartTimes);
  const [showMultidayReservations, setShowMultidayReservations] = useState(
    showReservationsOnAllOperatingDays
  );

  const updateSettings = (
    fuseStartTimes: boolean,
    showReservationsOnAllOperatingDays: boolean
  ) => {
    dispatch(
      updateManifestSettings({
        fuse_start_times: {
          value: fuseStartTimes,
        },
        show_reservations_on_all_operating_days: {
          value: showReservationsOnAllOperatingDays,
        },
      })
    );
  };

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={onClose}
          />
          <Button
            text={t('Save')}
            onClick={() => {
              updateSettings(!splitStartTimes, showMultidayReservations);
              onClose();
            }}
          />
        </>
      }
      style={{ maxWidth: '560px' }}
    >
      <div className={styles['p-manifestsSetting']}>
        <ul className={styles['p-manifestsSetting__list']}>
          <li>
            <Toggle
              label={t('Show "checkin/checkout only" reservations')}
              checked={showCheckinCheckout}
              onChange={onToggleShowCheckinCheckout}
            />
          </li>
          <li>
            <Toggle
              label={t('Show dispatch resources')}
              checked={showDispatchColumn}
              onChange={onToggleShowDispatchColumn}
            />
          </li>
          <li>
            <Toggle
              label={t('Show by product/group')}
              checked={showByProductGroup}
              onChange={onToggleShowByProductGroup}
            />
          </li>
          <li>
            <Toggle
              label={t('Split start times')}
              checked={splitStartTimes}
              onChange={() => setSplitStartTimes(!splitStartTimes)}
            />
          </li>
          <li>
            <Toggle
              label={t(
                'Show multiday reservations on manifests for all operating days'
              )}
              checked={showMultidayReservations}
              onChange={() =>
                setShowMultidayReservations(!showMultidayReservations)
              }
            />
          </li>
        </ul>
      </div>
    </Modal>
  );
};
