import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { OptionalIntegerInput, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';

import styles from './GuidancePageLocationsEditor.module.css';
import { GuidancePageLocationEditor } from './GuidancePageLocationEditor';

export const DigitalGuidanceItemProductTypeForm = () => {
  const { t } = useTranslation();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  return (
    <Modal.Content>
      <Box mt={4}>
        <Field name="productId">
          {({ input }) => (
            <Select
              search={true}
              label={t('Product')}
              options={(products ?? []).map((p) => ({
                value: p.id,
                text: getVerboseDisplayProductName(p),
              }))}
              value={input.value ?? ''}
              onChange={(_, { value }) => {
                input.onChange(value);
              }}
            />
          )}
        </Field>
      </Box>
      <Box mt={4}>
        <GuidancePageLocationEditor />
        <Box mt={2}>
          <Field name="location.activationDistance">
            {({ input }) => (
              <OptionalIntegerInput
                label={t('Booking activation distance (m)')}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          </Field>
          <div className={styles['help-text']}>
            {t(
              'We recommend setting a longer distance (20 meters or more) since GPS locations from mobile devices are often imprecise.'
            )}
          </div>
        </Box>
      </Box>
    </Modal.Content>
  );
};
