import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';

import styles from './SessionListItem.module.css';
import { SessionSelectionContext } from './SessionSelectionContext';
import { getFilteredEvents } from './util';

interface Props {
  session: Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number];
}

export const SessionListItem = ({ session }: Props) => {
  const { t } = useTranslation();
  const { setSelectedSessionTimestamp, selectedSessionTimestamp } =
    React.useContext(SessionSelectionContext);
  const startDateTime = moment(session.start_date_time_utc);
  const endDateTime = moment(session.end_date_time_utc);
  const duration = formattedDuration(endDateTime.diff(startDateTime) / 1000, t);
  const hasReservation = session.events?.some(
    (event) => event.event_type === 'reservation-thank-you'
  );
  const isBounce =
    getFilteredEvents(session).filter((event) =>
      [
        'page-view',
        'check-availability',
        'reservation-thank-you',
        'begin-checkout',
      ].includes(event.event_type ?? '')
    ).length <= 1;

  return (
    <div
      className={clsx(
        styles['container'],
        session.start_date_time_utc === selectedSessionTimestamp &&
          styles['selected']
      )}
      onClick={() =>
        setSelectedSessionTimestamp(session.start_date_time_utc ?? '')
      }
    >
      <div className={styles['time-range']}>
        {startDateTime.format('H:mm')}~{endDateTime.format('H:mm')}
      </div>
      <div className={styles['duration']}>{duration}</div>
      {hasReservation && (
        <div className={clsx(styles['label'], styles['conversion'])}>
          {t('Booking')}
        </div>
      )}
      {isBounce && (
        <div className={clsx(styles['label'], styles['bounce'])}>
          {t('Bounce')}
        </div>
      )}
    </div>
  );
};
