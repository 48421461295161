import * as React from 'react';
import clsx from 'clsx';

import styles from './Timeline.module.css';

type Props = {
  color?: 'yellow' | 'blue';
  label: string;
  content: string;
};
export const TimelineItem = ({ label, color, content }: Props) => {
  return (
    <div className={styles['page-productsRegist__timeline__item']}>
      <div className={styles['page-productsRegist__timeline__item__txt']}>
        <p className={clsx(color && styles[color])}>{label}</p>
        <p>{content}</p>
      </div>
      <p className={styles['page-productsRegist__timeline__item__arrow']}></p>
    </div>
  );
};
