import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ModalLoader } from 'client/components/ModalLoader';
import { ProductInstanceModal } from 'client/pages/v3/Availability/Shared/Modal/ProductInstanceModal';
import { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import {
  fetchProductInstanceByID,
  fetchProductInstancesForProducts,
} from 'client/actions/productInstances';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import {
  ProductInstanceShape,
  toProductInstanceShape,
} from 'client/libraries/util/productInstanceShape';
import {
  ProductInstance,
  ProductDateAvailability,
  StartTimeAvailability,
} from 'shared/models/swagger';
import stopIcon from 'client/images/ic_stop.svg';
import rqIcon from 'client/images/ic_rq.svg';
import {
  getAvailabilityIconType,
  ProductWithOccupancy,
} from 'client/pages/Availability/util';
import componentStyles from 'client/pages/Availability/components.module.css';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { CalendarEditContext } from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import tableStyles from 'client/pages/v3/Availability/AvailabilityTable.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { getAvailabilityBackgroundColorClass } from 'client/pages/v3/Availability/util';

const getDateHeader = (
  column: CustomTableColumn,
  locale: string,
  dayMonthFormat: string
) => {
  const dateMoment = moment(column.accessor).locale(locale);
  return (
    <p>{`${dateMoment.format(dayMonthFormat)} (${dateMoment.format(
      'ddd'
    )})`}</p>
  );
};

const ProductBlock = ({
  item,
  columns,
  onEditProductInstanceClick,
  tableHeaders,
}: {
  item: ProductWithOccupancy;
  columns: CustomTableColumn[];
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
  tableHeaders: JSX.Element;
}) => {
  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const allProducts = useSelector(summariesSelector);
  const { t } = useTranslation();

  const dynamicPackageComponentProductIdToPackageProductNameMap =
    allProducts.reduce((acc, product) => {
      if (product.is_dynamic_package_product?.value) {
        product.shared_allotment_references?.package_component_product_ids?.forEach(
          (id) => {
            acc[id] = [
              ...(acc[id] ?? []),
              { id: product.id, names: product.product_name ?? '' },
            ];
          }
        );
      }
      return acc;
    }, {} as Record<string, { id: string; names: string }[]>);

  const productTitle = (
    <>
      <Link
        className={tableStyles['link1']}
        to={`/products/${item.id}`}
        data-text={t('Detail')}
      >
        {getDisplayProductName(allProducts.find((p) => p.id == item.id))}
      </Link>
      {item.childProducts.length > 0 && (
        <>
          {item.childProducts.map((childProduct) => (
            <Link
              key={childProduct.id}
              className={tableStyles['link2']}
              to={`/products/${childProduct.id}`}
              data-text={t('Detail')}
            >
              <i className="c-icon-outline-general-link-03"></i>
              <span>{childProduct.name}</span>
            </Link>
          ))}
        </>
      )}
      {dynamicPackageComponentProductIdToPackageProductNameMap[item.id]?.map(
        ({ id, names }) => (
          <Link
            key={id}
            className={tableStyles['link2']}
            to={`/products/${id}`}
            data-text={t('Detail')}
          >
            <i className="c-icon-outline-general-link-03"></i>
            <span>{names}</span>
          </Link>
        )
      )}
    </>
  );

  return (
    <div>
      <div className={tableStyles['p-availabilityTable__table__spTdHeader']}>
        <div className={tableStyles['p-availabilityTable__table__ttl']}>
          {productTitle}
        </div>
      </div>
      <table className={tableStyles['p-availabilityTable__table']}>
        <tbody>
          <tr className={tableStyles['p-availabilityTable__table__spHeader']}>
            {tableHeaders}
          </tr>
          {availabilityLoading ? (
            <tr>
              <td className={tableStyles['p-availabilitySticky-left2']}>
                <ModalLoader />
              </td>
              {columns.map((_, idx) => {
                return (
                  <>
                    <td key={idx}>
                      <ModalLoader />
                    </td>
                  </>
                );
              })}
            </tr>
          ) : (
            <>
              {item.startTimeAvailabilities.length === 0 && (
                <tr style={{ height: '46px' }}>
                  <td
                    className={tableStyles['p-availabilitySticky-left2']}
                    style={{ cursor: 'auto' }}
                  ></td>
                  <StartTimeBlankBlock columns={columns} />
                </tr>
              )}
              {item.startTimeAvailabilities.length > 0 &&
                item.startTimeAvailabilities.map((s, idx) => {
                  const onChange = () => {
                    const checked = s.date_availabilities?.every(
                      (availability) =>
                        selectedProductInstanceIds.includes(
                          availability.product_instance_id ?? ''
                        )
                    );
                    if (checked) {
                      deselectProductInstanceIds(
                        ...(s.date_availabilities?.map(
                          (availability) =>
                            availability.product_instance_id ?? ''
                        ) ?? [])
                      );
                    } else {
                      selectProductInstanceIds(
                        ...(s.date_availabilities?.map(
                          (availability) =>
                            availability.product_instance_id ?? ''
                        ) ?? [])
                      );
                    }
                  };
                  return (
                    <tr key={idx}>
                      <td
                        className={tableStyles['p-availabilitySticky-left2']}
                        onClick={onChange}
                        style={{
                          cursor: calendarEditModeIsActive ? 'pointer' : 'auto',
                        }}
                      >
                        {calendarEditModeIsActive && (
                          <div
                            className={
                              tableStyles['p-availabilityTable__table__check']
                            }
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              checked={
                                s.date_availabilities?.every((availability) =>
                                  selectedProductInstanceIds.includes(
                                    availability.product_instance_id ?? ''
                                  )
                                ) ?? false
                              }
                              onChange={onChange}
                              size="sm"
                            />
                          </div>
                        )}
                        {s.start_time_local}
                      </td>
                      {columns.map((column, idx) => {
                        return (
                          <StartTimeBlock
                            item={item}
                            column={column}
                            s={s}
                            key={idx}
                            onEditProductInstanceClick={
                              onEditProductInstanceClick
                            }
                          />
                        );
                      })}
                    </tr>
                  );
                })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

const ProductBlockPc = ({
  item,
  columns,
  onEditProductInstanceClick,
}: {
  item: ProductWithOccupancy;
  columns: CustomTableColumn[];
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
}) => {
  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const allProducts = useSelector(summariesSelector);
  const { t } = useTranslation();

  const dynamicPackageComponentProductIdToPackageProductNameMap =
    allProducts.reduce((acc, product) => {
      if (product.is_dynamic_package_product?.value) {
        product.shared_allotment_references?.package_component_product_ids?.forEach(
          (id) => {
            acc[id] = [
              ...(acc[id] ?? []),
              { id: product.id, names: product.product_name ?? '' },
            ];
          }
        );
      }
      return acc;
    }, {} as Record<string, { id: string; names: string }[]>);

  const productColumn = (
    <td
      className={tableStyles['p-availabilitySticky-left']}
      style={{ width: '240px', cursor: 'auto' }}
      rowSpan={
        item.startTimeAvailabilities.length > 1
          ? item.startTimeAvailabilities.length
          : undefined
      }
    >
      <div className={tableStyles['p-availabilityTable__table__ttl']}>
        <Link
          className={tableStyles['link1']}
          to={`/products/${item.id}`}
          data-text={t('Detail')}
        >
          {getDisplayProductName(allProducts.find((p) => p.id == item.id))}
        </Link>
        {item.childProducts.length > 0 && (
          <>
            {item.childProducts.map((childProduct) => (
              <Link
                key={childProduct.id}
                className={tableStyles['link2']}
                to={`/products/${childProduct.id}`}
                data-text={t('Detail')}
              >
                <i className="c-icon-outline-general-link-03"></i>
                <span>{childProduct.name}</span>
              </Link>
            ))}
          </>
        )}
        {dynamicPackageComponentProductIdToPackageProductNameMap[item.id]?.map(
          ({ id, names }) => (
            <Link
              key={id}
              className={tableStyles['link2']}
              to={`/products/${id}`}
              data-text={t('Detail')}
            >
              <i className="c-icon-outline-general-link-03"></i>
              <span>{names}</span>
            </Link>
          )
        )}
      </div>
    </td>
  );

  return (
    <>
      {availabilityLoading ? (
        <tr>
          {productColumn}
          <td className={tableStyles['p-availabilitySticky-left2']}>
            <ModalLoader />
          </td>
          {columns.map((_, idx) => {
            return (
              <>
                <td key={idx}>
                  <ModalLoader />
                </td>
              </>
            );
          })}
        </tr>
      ) : (
        <>
          {item.startTimeAvailabilities.length === 0 && (
            <tr style={{ height: '46px' }}>
              {productColumn}
              <td
                className={tableStyles['p-availabilitySticky-left2']}
                style={{ cursor: 'auto' }}
              ></td>
              <StartTimeBlankBlock columns={columns} />
            </tr>
          )}
          {item.startTimeAvailabilities.length > 0 &&
            item.startTimeAvailabilities.map((s, idx) => {
              const onChange = () => {
                const checked = s.date_availabilities?.every((availability) =>
                  selectedProductInstanceIds.includes(
                    availability.product_instance_id ?? ''
                  )
                );
                if (checked) {
                  deselectProductInstanceIds(
                    ...(s.date_availabilities?.map(
                      (availability) => availability.product_instance_id ?? ''
                    ) ?? [])
                  );
                } else {
                  selectProductInstanceIds(
                    ...(s.date_availabilities?.map(
                      (availability) => availability.product_instance_id ?? ''
                    ) ?? [])
                  );
                }
              };
              return (
                <tr key={idx}>
                  {idx === 0 && <>{productColumn}</>}
                  <td
                    className={tableStyles['p-availabilitySticky-left2']}
                    onClick={onChange}
                    style={{
                      cursor: calendarEditModeIsActive ? 'pointer' : 'auto',
                    }}
                  >
                    {calendarEditModeIsActive && (
                      <div
                        className={
                          tableStyles['p-availabilityTable__table__check']
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Checkbox
                          checked={
                            s.date_availabilities?.every((availability) =>
                              selectedProductInstanceIds.includes(
                                availability.product_instance_id ?? ''
                              )
                            ) ?? false
                          }
                          onChange={onChange}
                          size="sm"
                        />
                      </div>
                    )}
                    {s.start_time_local}
                  </td>
                  {columns.map((column, idx) => {
                    return (
                      <StartTimeBlock
                        item={item}
                        column={column}
                        s={s}
                        key={idx}
                        onEditProductInstanceClick={onEditProductInstanceClick}
                      />
                    );
                  })}
                </tr>
              );
            })}
        </>
      )}
    </>
  );
};

const StartTimeBlankBlock = ({ columns }: { columns: CustomTableColumn[] }) => {
  return (
    <>
      {columns.map((_, idx) => {
        return (
          <>
            <td key={idx}>
              <a></a>
            </td>
          </>
        );
      })}
    </>
  );
};

const StartTimeBlock = ({
  item,
  s,
  column,
  onEditProductInstanceClick,
}: {
  item: ProductWithOccupancy;
  s: StartTimeAvailability;
  column: CustomTableColumn;
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
}) => {
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const availability = s.date_availabilities?.find(
    (availability) => availability.date === column.accessor
  );
  const productInstanceLoading = useSelector(
    (state: ReduxState) => state.productInstances.loading
  );

  const backgroundColorClassName = getAvailabilityBackgroundColorClass(
    availability?.occupied_slots ?? 0,
    availability?.total_slots ?? 0,
    availability?.all_channels_are_closed ?? false
  );

  return (
    <>
      <td
        className={clsx(
          backgroundColorClassName && tableStyles[backgroundColorClassName]
        )}
        onClick={() => {
          if (availability) {
            if (calendarEditModeIsActive) {
              if (
                selectedProductInstanceIds.includes(
                  availability?.product_instance_id ?? ''
                )
              ) {
                deselectProductInstanceIds(
                  availability?.product_instance_id ?? ''
                );
              } else {
                selectProductInstanceIds(
                  availability?.product_instance_id ?? ''
                );
              }
            } else {
              onEditProductInstanceClick({
                product: item,
                startTime: s,
                column: column,
                availability: availability,
              });
            }
          }
        }}
        style={{
          // Set cursor as progress for better UX: instance is being loaded upon click
          cursor: productInstanceLoading
            ? 'progress'
            : availability
            ? undefined
            : 'auto',
        }}
      >
        {availability ? (
          <StartTimeCellAnchorTag item={item} availability={availability} />
        ) : (
          // No allotment
          <p></p>
        )}
      </td>
    </>
  );
};

const StartTimeCellAnchorTag = ({
  item,
  availability,
}: {
  item: ProductWithOccupancy;
  availability: ProductDateAvailability;
}) => {
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);

  const formattedOccupancy = `${
    availability.occupied_slots ?? 0
  } / ${formattedTotalAllotmentSlots(availability.total_slots ?? 0)}`;

  const Icon = () => {
    const iconType = getAvailabilityIconType(
      availability.occupied_slots ?? 0,
      availability.total_slots ?? 0,
      availability.all_channels_are_closed ?? false,
      item.shouldRejectRequestBookingsBeyondCapacity ||
        availability.rejects_request_booking_beyond_capacity,
      availability?.booking_deadlines ?? []
    );

    if (iconType === 'STOP') {
      return (
        <img
          className={componentStyles['availability__table']}
          src={stopIcon}
        />
      );
    }

    if (iconType === 'REQUEST') {
      return (
        <img className={componentStyles['availability__table']} src={rqIcon} />
      );
    }

    return null;
  };

  return (
    <>
      {calendarEditModeIsActive && (
        <div
          className={tableStyles['p-availabilityTable__table__check']}
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            checked={selectedProductInstanceIds.includes(
              availability.product_instance_id ?? ''
            )}
            onChange={() => {
              if (
                selectedProductInstanceIds.includes(
                  availability.product_instance_id ?? ''
                )
              ) {
                deselectProductInstanceIds(
                  availability.product_instance_id ?? ''
                );
              } else {
                selectProductInstanceIds(
                  availability.product_instance_id ?? ''
                );
              }
            }}
            size="sm"
          />
        </div>
      )}
      <div className={tableStyles['p-availabilityTable__table__td']}>
        <Icon />
        <p>{formattedOccupancy}</p>
      </div>
    </>
  );
};

type Props = {
  items: ProductWithOccupancy[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  setEditingProductInstance: (arg0: any) => any;
  setReservationListContext: (arg0: any) => any;
  onPageSizeChange: (arg0: number) => any;
  onCurrentPageChange: (arg0: number) => any;
  totalPaxByDate: Record<string, number>;
};

export type CustomTableColumn = {
  accessor: string;
};

type ProductInstanceModalParams = {
  product: ProductWithOccupancy;
  startTime: StartTimeAvailability;
  column: CustomTableColumn;
  availability: ProductDateAvailability;
};

export const AvailabilityCustomTable = ({
  items,
  columns,
  usePaging,
  setEditingProductInstance,
  setReservationListContext,
  onPageSizeChange,
  onCurrentPageChange,
  totalPaxByDate,
}: Props) => {
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = useContext(CalendarEditContext);
  const [productInstanceModalParams, setProductInstanceModalParams] =
    useState<ProductInstanceModalParams | null>(null);
  const [openProductInstanceModal, setOpenProductInstanceModal] =
    useState<boolean>(false);

  const allProducts = useSelector(summariesSelector);
  const dispatch = useDispatch();

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const clickedProductInstance: ProductInstance | null = useMemo(
    () =>
      productInstancesById[
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      ] ?? null,
    [
      productInstancesById,
      productInstanceModalParams?.availability?.product_instance_id,
    ]
  );
  const clickedProductInstanceShape: ProductInstanceShape | null = useMemo(
    () =>
      clickedProductInstance
        ? toProductInstanceShape(clickedProductInstance)
        : null,
    [clickedProductInstance]
  );

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = useState(defaultRowCount);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    onPageSizeChange(rowCount);
  }, [rowCount]);
  useEffect(() => {
    onCurrentPageChange(currentPage - 1);
  }, [currentPage]);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [rowCount]);
  useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);
  useEffect(() => {
    dispatch(
      fetchProductInstanceByID(
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      )
    );
  }, [productInstanceModalParams?.availability?.product_instance_id]);

  // ProductInstanceModal relies on child product instances existing in redux state in order to show these
  // reservations in the reservation list modal.
  useEffect(() => {
    if (clickedProductInstance) {
      const product = productSummaries.find(
        (product) => product.id === clickedProductInstance.product_id
      );

      const childProductIds =
        product?.shared_allotment_references?.child_product_ids ?? [];
      if (childProductIds.length > 0) {
        const startDate = moment.parseZone(
          clickedProductInstance.start_date_time_local
        );
        const endDate = moment
          .parseZone(clickedProductInstance.start_date_time_local)
          .add(1, 'days');
        dispatch(
          fetchProductInstancesForProducts(childProductIds, startDate, endDate)
        );
      }
    }
  }, [clickedProductInstance, productSummaries]);

  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const visibleItems = items.filter((_, index) => {
    if (index < rowHead) {
      return false;
    }

    if (rowHead + rowCount <= index) {
      return false;
    }

    return true;
  });

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getTotalPaxHeader = (column: CustomTableColumn) => {
    return (
      <>
        {typeof totalPaxByDate[column.accessor] === 'number' ? (
          <p>
            <span>{t('Total: ')}</span>
            {t('{{count}} pax', {
              count: totalPaxByDate[column.accessor],
            })}
          </p>
        ) : (
          <div style={{ marginTop: '8px' }}>
            <ModalLoader />
          </div>
        )}
      </>
    );
  };

  const tableHeaders = (
    <>
      <th className={tableStyles['p-availabilitySticky-left']}>
        {t('Product name')}
      </th>
      <th className={tableStyles['p-availabilitySticky-left2']}>
        {t('Start Times')}
      </th>
      {columns.map((column, idx) => {
        const columnProductInstanceIds: string[] = [];

        for (const item of visibleItems) {
          for (const availability of item.startTimeAvailabilities) {
            const productInstanceId = availability.date_availabilities?.find(
              (dateAvailability) => dateAvailability.date === column.accessor
            )?.product_instance_id;

            if (productInstanceId) {
              columnProductInstanceIds.push(productInstanceId);
            }
          }
        }

        // Only perform instance id check if array is not empty to prevent checked checkbox during loading
        let columnIsChecked = false;
        if (columnProductInstanceIds.length > 0) {
          columnIsChecked = columnProductInstanceIds.every((id) =>
            selectedProductInstanceIds.includes(id)
          );
        }

        return (
          <th key={idx + 1}>
            <div className={tableStyles['p-availabilityTable__table__th']}>
              {getDateHeader(column, locale, dayMonthFormat)}
              {getTotalPaxHeader(column)}

              {/* Checkbox to select the whole column */}
              {calendarEditModeIsActive &&
                typeof totalPaxByDate[column.accessor] === 'number' && (
                  <div
                    className={tableStyles['p-availabilityTable__table__check']}
                  >
                    <Checkbox
                      checked={columnIsChecked}
                      onChange={() => {
                        if (columnIsChecked) {
                          deselectProductInstanceIds(
                            ...columnProductInstanceIds
                          );
                        } else {
                          selectProductInstanceIds(...columnProductInstanceIds);
                        }
                      }}
                      size="sm"
                    />
                  </div>
                )}
            </div>
          </th>
        );
      })}
    </>
  );

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  return (
    <>
      <div className={tableStyles['p-availabilityTable']}>
        {/* Table header */}
        <div
          className={clsx(
            tableStyles['p-availabilityTable__header'],
            tableStyles['p-availabilitySticky-top'],
            baseStyles['u-spHidden']
          )}
          ref={tableHeaderRef}
        >
          <table className={tableStyles['p-availabilityTable__table']}>
            <thead>
              <tr>{tableHeaders}</tr>
            </thead>
          </table>
        </div>

        {/* Table body for mobile */}
        <div
          className={clsx(
            baseStyles['u-pcHidden'],
            tableStyles['p-availabilityTable__body']
          )}
        >
          {visibleItems.map((item, idx) => {
            return (
              <ProductBlock
                item={item}
                key={idx}
                columns={columns}
                onEditProductInstanceClick={(
                  params: ProductInstanceModalParams
                ) => {
                  setProductInstanceModalParams(params);
                  setOpenProductInstanceModal(true);
                }}
                tableHeaders={tableHeaders}
              />
            );
          })}
        </div>
        {/* Table body for PC */}
        <div
          className={clsx(
            baseStyles['u-spHidden'],
            tableStyles['p-availabilityTable__body']
          )}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table className={tableStyles['p-availabilityTable__table']}>
            <tbody>
              {visibleItems.map((item, idx) => {
                return (
                  <ProductBlockPc
                    item={item}
                    key={idx}
                    columns={columns}
                    onEditProductInstanceClick={(
                      params: ProductInstanceModalParams
                    ) => {
                      setProductInstanceModalParams(params);
                      setOpenProductInstanceModal(true);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Table pagination */}
      <div className={tableStyles['p-availabilityPagination']}>
        {usePaging && (
          <Pagination
            totalItems={items.length}
            currentPage={currentPage}
            pageCount={pageCount}
            selectedNumberOfLinesToDisplay={rowCount}
            onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
            onClickPrevButton={pageBackClickHandler}
            onClickNextButton={pageForwardClickHandler}
          />
        )}
      </div>

      {productInstanceModalParams &&
        clickedProductInstance &&
        clickedProductInstanceShape &&
        openProductInstanceModal && (
          <ProductInstanceModal
            productName={getDisplayProductName(
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )
            )}
            allotmentSettings={
              productInstanceModalParams.product.allotmentSettings
            }
            timezone={productInstanceModalParams.product.timezone}
            onEditClick={setEditingProductInstance}
            onListClick={() =>
              setReservationListContext({
                productIds: [
                  productInstanceModalParams.product.id,
                  ...productInstanceModalParams.product.childProducts.map(
                    (p) => p.id
                  ),
                ],
                rootProductInstanceId:
                  productInstanceModalParams.availability.product_instance_id ??
                  0,
                date: productInstanceModalParams.column.accessor,
              })
            }
            productInstance={clickedProductInstance}
            instance={clickedProductInstanceShape}
            onClose={() => {
              setOpenProductInstanceModal(false);
            }}
            shouldRejectBookingsBeyondCapacity={
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )?.request_booking_settings
                ?.should_reject_bookings_beyond_capacity ?? false
            }
          />
        )}
    </>
  );
};
