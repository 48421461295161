import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  GenericTable,
  ColumnType,
} from 'client/components/v3/GenericTable/GenericTable';
import { localeSelector, ReduxState } from 'client/reducers';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

import styles from './AddressAgeRatioGadget.module.css';

interface BarProps {
  persetn: number;
}

const Bar = ({ persetn }: BarProps) => {
  return (
    <div className={styles['bar-container']}>
      <div className={styles['numeric-value']}>{persetn}%</div>
      <div
        className={styles['bar']}
        style={{
          width: `${persetn}%`,
        }}
      ></div>
    </div>
  );
};

interface Item {
  prefecture: string;
  ratios: {
    [key: string]: number;
  };
}

const useColumns = (): ColumnType<Item>[] => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);

  const ageUnit = locale === 'ja' ? '代' : `'s`;

  const ages: { key: string }[] = [
    { key: '10' },
    { key: '20' },
    { key: '30' },
    { key: '40' },
    { key: '50' },
    { key: '60' },
  ];

  return [
    {
      Header: t('Address'),
      id: 'prefecture',
      accessor: (item: Item) => item.prefecture,
      th: true,
    },
    {
      Header: t('No answer'),
      id: 'null',
      accessor: (item: Item) => <Bar persetn={item?.ratios?.['no'] ?? 0} />,
    },
    ...ages.map((age) => ({
      Header: `${age.key}${ageUnit}`,
      id: age.key,
      accessor: (item: Item) => <Bar persetn={item?.ratios?.[age.key] ?? 0} />,
    })),
    {
      Header: t('Total', { context: 'crm' }),
      id: 'null',
      accessor: (item: Item) => <Bar persetn={item?.ratios?.['TOTAL'] ?? 0} />,
    },
  ];
};

export const AddressAgeRatioGadget = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const reportData = useSelector(
    (state: ReduxState) => state.customers.reportData
  );

  const items: Item[] = React.useMemo(() => {
    const total = (
      reportData?.[0]?.totals?.province_decade_counts ?? []
    ).reduce((acc: number, data: any) => {
      acc += data.counts.reduce((acc: number, countData: any) => {
        acc += countData.count;
        return acc;
      }, 0);
      return acc;
    }, 0);

    const dataItems = (
      reportData?.[0]?.totals?.province_decade_counts ?? []
    ).map((data: any) => {
      const totalOfTheArea = data.counts.reduce(
        (acc: number, countData: any) => {
          acc += countData.count;
          return acc;
        },
        0
      );

      const ratios = data.counts.reduce((acc: any, countData: any) => {
        acc[countData.key] = ((countData.count / totalOfTheArea) * 100).toFixed(
          1
        );
        return acc;
      }, {});

      ratios['TOTAL'] = ((totalOfTheArea / total) * 100).toFixed(1);

      return {
        prefecture: data.key,
        ratios,
      };
    });

    const unknownIndex = dataItems.findIndex(
      (item) => item.prefecture === 'UNKNOWN'
    );
    if (unknownIndex !== -1) {
      const unknownItem = dataItems.splice(unknownIndex, 1)[0];
      unknownItem.prefecture = t('Unknown');
      dataItems.push(unknownItem);
    }

    return dataItems;
  }, [reportData]);

  const pageItems = items.slice(
    (currentPage - 1) * rowCount,
    currentPage * rowCount
  );

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Address/age distribution')}</div>
            <Tooltip
              text={t(
                'Display the percentage of members who belong to a specific address and specific age group as of the end date of the reporting period'
              )}
            >
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          <GenericTable<Item>
            items={pageItems}
            columns={columns}
            rowCount={rowCount}
            onRowCountChange={setRowCount}
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            totalCount={items.length}
            hideScrollButtons={true}
            hidePagination={true}
          />
        </div>
      </div>
    </div>
  );
};
