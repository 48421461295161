import { combineReducers } from 'redux';
import moment from 'moment-timezone';

import {
  ADD_NOTIFICATION,
  FETCH_RESERVATION_NOTIFICATIONS_SUCCESS,
  REMOVE_NOTIFICATION,
  DELETE_RESERVATION_NOTIFICATION_SUCCESS,
  DELETE_RESERVATION_NOTIFICATION_FAILURE,
  DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE,
  DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS,
} from 'client/constants/ActionTypes';
import { Task } from 'shared/models/swagger';

export type Notification = {
  id: string;
  type: 'CHECKIN' | 'RESERVATION' | 'RESTAURANT_ORDER';
  payload: Record<string, string>;
};
let idCounter = 1;
const initialValue: Notification[] = [];

const convertToReservationNotification = (task: Task): Notification => {
  return {
    id: task.id ?? '',
    type: 'RESERVATION',
    payload: {
      task_id: task.id ?? '',
      reservation_notification_type: task.notification_type ?? '',
      reservation_agent_reference: task.reservation_agent_reference ?? '',
      reservation_status: task.reservation_status ?? '',
      product_name: task.reservation_product_name ?? '',
      internal_product_name: task.internal_product_name ?? '',
      reservation_start_date_time_local: moment
        .tz(
          task.reservation_start_date_time_utc ?? '',
          task.reservation_start_timezone ?? ''
        )
        .format(),
      reservation_id: task.reservation_id,
      booking_source_type: task.booking_source?.source_type ?? '',
      booking_source_agent_name: task.booking_source?.agent_name ?? '',
    },
  };
};

/*
[
  {
    id: -1,
    type: 'CHECKIN',
    payload: {
      reservation_agent_reference: 'ABABAB',
      product_name: 'TEST PRODUCT',
      reservation_guest_name: 'Test Checkin',
      checkin_guest_count: '2',
      reservation_total_guest_count: '2'
    }
  }
];
*/
const checkinNotifications = (
  state: Notification[] = initialValue,
  action: Record<string, any>
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.notification.type !== 'CHECKIN') return state;

      const newState = [
        ...state,
        {
          id: `${idCounter}`,
          ...action.notification,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);

    default:
      return state;
  }
};

const reservationNotifications = (
  state: Notification[] = initialValue,
  action: Record<string, any>
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (
        action.notification.type !== 'RESERVATION' ||
        state.find(
          (notification) => notification.id === action.notification?.id
        )
      ) {
        return state;
      }

      const newState = [
        {
          id: action.notification?.payload?.task_id ?? '',
          ...action.notification,
        },
        ...state,
      ];
      return newState;
    }

    case DELETE_RESERVATION_NOTIFICATION_SUCCESS:
    case DELETE_RESERVATION_NOTIFICATION_FAILURE:
      return state.filter((notification) => notification.id !== action.id);

    case DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE:
    case DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS:
      return state.filter(
        (notification) => !action.ids?.includes(notification.id)
      );

    case FETCH_RESERVATION_NOTIFICATIONS_SUCCESS:
      return action.response.tasks?.map((task: Task) =>
        convertToReservationNotification(task)
      );

    default:
      return state;
  }
};

export const restaurantOrderNotifications = (
  state: Notification[] = initialValue,
  action: Record<string, any>
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.notification.type !== 'RESTAURANT_ORDER') return state;

      const newState = [
        ...state,
        {
          id: `${idCounter}`,
          ...action.notification,
        },
      ];
      return newState;
    }

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);

    default:
      return state;
  }
};

export const notifications = combineReducers({
  checkinNotifications,
  reservationNotifications,
  restaurantOrderNotifications,
});
