import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { DashboardSettings } from 'client/reducers/dashboardSettings';
import { putDashboardSettings } from 'client/actions/dashboardSettings';

import { Tabs } from './Tabs';
import { ReservationInfoSelectPane } from './ReservationInfoSelectPane';
import { AccessReportSelectPane } from './AccessReportSelectPane';
import { ReservationReportSelectPane } from './ReservationReportSelectPane';

interface Props {
  onClose: () => void;
}

export const AddGadgetModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );

  return (
    <Form
      initialValues={settings?.gadgets ? settings : { gadgets: [] }}
      onSubmit={async (values: DashboardSettings) => {
        await dispatch(putDashboardSettings(JSON.stringify(values)));
        onClose();
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form id="addGadgetForm" onSubmit={handleSubmit}>
          <Modal
            insertAtRoot
            title={t('Add Gadgets')}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  size="md"
                  color="white"
                  onClick={onClose}
                />
                {/* 
                ref: https://github.com/final-form/react-final-form/blob/master/docs/faq.md#how-can-i-trigger-a-submit-from-outside-my-form
                Portal causes the button to render outside the form tree. Use form's id to connect this submit button to the form.
                */}
                <Button
                  loading={submitting}
                  form="addGadgetForm"
                  text={t('Save')}
                  type="submit"
                />
              </>
            }
            style={{
              width: '80%',
              height: '90vh',
            }}
          >
            <div>
              <Tabs
                panes={[
                  {
                    header: t('Reservation Report'),
                    content: () => (
                      <ReservationReportSelectPane onClose={onClose} />
                    ),
                  },
                  {
                    header: t('Access Report'),
                    content: () => <AccessReportSelectPane onClose={onClose} />,
                  },
                  {
                    header: t('Reservation Info'),
                    content: () => (
                      <ReservationInfoSelectPane onClose={onClose} />
                    ),
                  },
                ]}
              />
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
