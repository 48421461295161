import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { currency as currencyjs } from 'shared/libraries/currency';
import { createFareAdjustmentForReservation } from 'client/actions/reservations';
import type {
  FareAdjustmentForReservationPatch,
  Reservation,
} from 'shared/models/swagger';
import { currencyAmountInputHelper } from 'client/libraries/util/coreutil';
import type { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';
import baseStyles from 'client/v3-base.module.css';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Radio } from 'client/components/v3/Form/Radio';
import { TextField } from 'client/components/v3/Form/TextField';
import { Accordion } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/BillingInformation/Accordion';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

type State = {
  amount_gross: string;
  amount_net: string;
  amount_commission: string;
  amount_pay_in_advance: string;
  amount_pay_on_board: string;
  notes: string;
  is_refund: boolean;
  helper: CurrencyAmountInputHelper;
  cardFieldErrorMessage: string;
  cardSubmissionErrorMessage: string;
  cardInfoComplete: boolean;
  checkAgreement: boolean;
  supplierNotes: string;
};

type OwnProps = {
  header?: string;
  currency: string;
  id: string;
  savedPaymentInfoAvailable?: boolean;
  isDirectChargable?: boolean;
  isPIFAvailable?: boolean;
  reservation: Reservation;
  onClose: () => void;
};

type Props = OwnProps & ReactStripeElements.InjectedStripeProps;

export const ReservationFareAdjustmentModal = injectStripe(
  ({
    header,
    currency,
    id,
    savedPaymentInfoAvailable,
    isDirectChargable,
    isPIFAvailable,
    stripe,
    reservation,
    onClose,
  }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
      amount_gross: '0',
      amount_net: '0',
      amount_commission: '0',
      amount_pay_in_advance: '0',
      amount_pay_on_board: '0',
      notes: '',
      is_refund: false,
      helper: currencyAmountInputHelper(currency),
      cardFieldErrorMessage: '',
      cardSubmissionErrorMessage: '',
      cardInfoComplete: false,
      checkAgreement: false,
      supplierNotes: reservation?.supplier_notes ?? '',
    });

    const [fareAdjustStatus, setFareAdjustStatus] = useState<
      null | 'REQUESTED' | 'SUCCEEDED' | 'FAILED'
    >(null);

    const [amountGrossError, setAmountGrossError] = useState<boolean>(false);

    useEffect(() => {
      setFareAdjustStatus(null);
    }, []);

    useEffect(() => {
      setState({
        ...state,
        helper: currencyAmountInputHelper(currency),
      });
    }, [currency]);

    // This means that all inputs must be valid, with change in gross needing to be nonzero.
    const validateForm = () => {
      const {
        amount_gross,
        amount_net,
        amount_commission,
        amount_pay_in_advance,
        amount_pay_on_board,
        cardInfoComplete,
      } = state;
      const needsCardInfo =
        checkIfChargeable() && isDirectChargable && !savedPaymentInfoAvailable;
      return (
        parseFloat(amount_gross) !== 0 &&
        !state.helper.inputInvalid(state.helper.moneyInput(amount_gross)) &&
        !state.helper.inputInvalid(state.helper.moneyInput(amount_net)) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_commission)
        ) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_pay_in_advance)
        ) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_pay_on_board)
        ) &&
        (needsCardInfo ? cardInfoComplete : true)
      );
    };

    const isCancelledReservation =
      reservation.status === 'CANCELED_BY_AGENT' ||
      reservation.status === 'CANCELED_BY_GUEST' ||
      reservation.status === 'CANCELED_BY_SUPPLIER';

    // Deliberately verbose here for clarity, we want to check if result is negative or zero separately
    const resultAmountIsNegative = (() => {
      const { is_refund, amount_gross, amount_net } = state;

      if (isCancelledReservation) {
        if (reservation?.billing_info?.amount_cancellation_fee_gross) {
          const cancellationGrossAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_gross
          ).value;
          if (
            is_refund &&
            cancellationGrossAmount - parseFloat(amount_gross) < 0
          ) {
            return true;
          }
        }
        if (reservation?.billing_info?.amount_cancellation_fee_net) {
          const cancellationNetAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_net
          ).value;
          if (is_refund && cancellationNetAmount - parseFloat(amount_net) < 0) {
            return true;
          }
        }
        return false;
      }

      if (reservation?.billing_info?.amount_gross) {
        const reservationGrossAmount = currencyjs(
          reservation?.billing_info?.amount_gross
        ).value;
        if (
          is_refund &&
          reservationGrossAmount - parseFloat(amount_gross) < 0
        ) {
          return true;
        }
      }
      if (reservation?.billing_info?.amount_net) {
        const reservationNetAmount = currencyjs(
          reservation?.billing_info?.amount_net
        ).value;
        if (is_refund && reservationNetAmount - parseFloat(amount_net) < 0) {
          return true;
        }
      }
      return false;
    })();
    const resultAmountIsZero = (() => {
      const { is_refund, amount_gross, amount_net } = state;

      if (isCancelledReservation) {
        if (reservation?.billing_info?.amount_cancellation_fee_gross) {
          const cancellationGrossAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_gross
          ).value;
          if (
            is_refund &&
            cancellationGrossAmount - parseFloat(amount_gross) == 0
          ) {
            return true;
          }
        }
        if (reservation?.billing_info?.amount_cancellation_fee_net) {
          const cancellationNetAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_net
          ).value;
          if (
            is_refund &&
            cancellationNetAmount - parseFloat(amount_net) == 0
          ) {
            return true;
          }
        }
        return false;
      }

      if (reservation?.billing_info?.amount_gross) {
        const reservationGrossAmount = currencyjs(
          reservation?.billing_info?.amount_gross
        ).value;
        if (
          is_refund &&
          reservationGrossAmount - parseFloat(amount_gross) == 0
        ) {
          return true;
        }
      }
      if (reservation?.billing_info?.amount_net) {
        const reservationNetAmount = currencyjs(
          reservation?.billing_info?.amount_net
        ).value;
        if (is_refund && reservationNetAmount - parseFloat(amount_net) == 0) {
          return true;
        }
      }
      return false;
    })();

    const checkIfChargeable = (): boolean => {
      const { amount_pay_in_advance, is_refund, helper } = state;
      return (
        !helper.inputInvalid(helper.moneyInput(amount_pay_in_advance)) &&
        amount_pay_in_advance !== '' &&
        amount_pay_in_advance !== '0' &&
        !is_refund
      );
    };

    const checkIfRefundable = (): boolean => {
      const { amount_pay_in_advance, is_refund, helper } = state;
      return (
        !helper.inputInvalid(helper.moneyInput(amount_pay_in_advance)) &&
        amount_pay_in_advance !== '' &&
        amount_pay_in_advance !== '0' &&
        is_refund
      );
    };

    const handleCardChange = ({ error, complete }: any) => {
      setState({
        ...state,
        cardFieldErrorMessage: error ? error.message : '',
        cardSubmissionErrorMessage: '',
        cardInfoComplete: complete,
      });
    };

    const handleConfirmFareAdjustment = async () => {
      const isChargeable = checkIfChargeable();
      if (
        isChargeable &&
        isDirectChargable &&
        stripe &&
        !savedPaymentInfoAvailable
      ) {
        stripe
          .createPaymentMethod('card', {
            billing_details: {},
          })
          .then(async (response) => {
            if (response.error) {
              setState({
                ...state,
                cardSubmissionErrorMessage: response.error.message || '',
              });
            } else {
              if (response.paymentMethod) {
                const paymentMethodId = response.paymentMethod.id;
                const fare_adjust_params: FareAdjustmentForReservationPatch = {
                  amount_gross: state.helper.moneyString(state.amount_gross),
                  amount_net: state.helper.moneyString(state.amount_net),
                  amount_pay_on_board: state.helper.moneyString(
                    state.amount_pay_on_board
                  ),
                  notes: state.notes,
                  supplier_notes: state.supplierNotes,
                  is_refund: state.is_refund,
                  ...(paymentMethodId && {
                    payment_profile_gateway_reference: paymentMethodId,
                  }),
                };

                let closable = true;
                try {
                  setFareAdjustStatus('REQUESTED');
                  await dispatch(
                    createFareAdjustmentForReservation(id, fare_adjust_params)
                  );
                  setFareAdjustStatus('SUCCEEDED');
                } catch (err) {
                  closable = false;
                  setFareAdjustStatus('FAILED');
                }

                setState({
                  ...state,
                  cardFieldErrorMessage: '',
                  cardSubmissionErrorMessage: '',
                  cardInfoComplete: false,
                });
                if (closable) {
                  onClose();
                }
              }
            }
          });
      } else {
        const fare_adjust_params: FareAdjustmentForReservationPatch = {
          amount_gross: state.helper.moneyString(state.amount_gross),
          amount_net: state.helper.moneyString(state.amount_net),
          amount_pay_on_board: state.helper.moneyString(
            state.amount_pay_on_board
          ),
          notes: state.notes,
          supplier_notes: state.supplierNotes,
          is_refund: state.is_refund,
        };

        let closable = true;
        try {
          setFareAdjustStatus('REQUESTED');
          await dispatch(
            createFareAdjustmentForReservation(id, fare_adjust_params)
          );
          setFareAdjustStatus('SUCCEEDED');
        } catch (err) {
          closable = false;
          setFareAdjustStatus('FAILED');
        }

        setState({
          ...state,
          notes: '',
          checkAgreement: false,
        });
        if (closable) {
          onClose();
        }
      }
    };

    const getCardErrorMessages = () => {
      const { cardFieldErrorMessage, cardSubmissionErrorMessage } = state;
      const isChargeable = checkIfChargeable();
      return (
        <>
          {isChargeable && cardFieldErrorMessage && (
            <>
              {t('Please review your card information')}
              <p>{t(cardFieldErrorMessage)}</p>
            </>
          )}
          {isChargeable && cardSubmissionErrorMessage && (
            <>
              {t('Could not process payment')}
              <p>{t(cardSubmissionErrorMessage)}</p>
            </>
          )}
        </>
      );
    };

    const cardElementStyle = {
      base: {
        fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#C7C7C7',
        },
      },
      invalid: {
        color: '#9f3a38',
        iconColor: '#9f3a38',
      },
    };

    const isChargeable = checkIfChargeable();
    const isRefundable = checkIfRefundable();
    const cardInputErrors = getCardErrorMessages();

    const GrossAmountTextField = (
      <TextField
        value={state.amount_gross}
        error={amountGrossError ? t('Invalid') : ''}
        type="string"
        onChange={(value) => {
          if (!state.helper.inputAllowed(value)) {
            return;
          }
          const moneyInput = state.helper.moneyInput(value);
          if (
            state.helper.inputInvalid(moneyInput) ||
            parseFloat(moneyInput) === 0
          ) {
            setAmountGrossError(true);
          } else {
            setAmountGrossError(false);
          }
          if (isPIFAvailable) {
            // If PIF is available, auto-fill PIF amount based on gross
            setState({
              ...state,
              amount_net: state.helper.moneyInput(
                state.helper.diff(moneyInput, state.amount_commission)
              ),
              amount_pay_in_advance: state.helper.moneyInput(
                state.helper.diff(moneyInput, state.amount_pay_on_board)
              ),
              amount_gross: moneyInput,
            });
          } else {
            // If PIF is not available, auto-fill POB amount based on gross
            setState({
              ...state,
              amount_net: state.helper.moneyInput(
                state.helper.diff(moneyInput, state.amount_commission)
              ),
              amount_pay_on_board: state.helper.moneyInput(
                state.helper.diff(moneyInput, state.amount_pay_in_advance)
              ),
              amount_gross: moneyInput,
            });
          }
        }}
      />
    );

    return (
      <Modal
        style={{ height: 'fit-content', width: '600px', maxHeight: '85%' }}
        title={header ?? ''}
        open={true}
        onClose={onClose}
        rightActionChildren={
          <>
            <Button color="white" text={t('Close')} onClick={onClose} />
            <Button
              text={t('Confirm')}
              loading={fareAdjustStatus === 'REQUESTED'}
              onClick={() => handleConfirmFareAdjustment()}
              disabled={
                resultAmountIsNegative ||
                // Do not allow full refund for GMO, allow for other payment gateway
                (resultAmountIsZero &&
                  reservation.payment_gateway &&
                  reservation.payment_gateway === 'GMO') ||
                !validateForm() ||
                (isChargeable &&
                  isDirectChargable &&
                  savedPaymentInfoAvailable &&
                  !state.checkAgreement)
              }
            />
          </>
        }
      >
        <div className={styles['p-priceChange']}>
          <div className={styles['p-priceChange__box']}>
            <div className={styles['p-priceChange__item']}>
              <div className={styles['p-priceChange__item__check']}>
                <Radio
                  label={t('Charge')}
                  name="radioGroup"
                  value="Charge"
                  checked={!state.is_refund}
                  onChange={() => {
                    setState({
                      ...state,
                      is_refund: false,
                    });
                  }}
                  size="sm"
                />
                <Radio
                  label={t('Refund')}
                  name="radioGroup"
                  value="Refund"
                  checked={state.is_refund}
                  onChange={() => {
                    setState({
                      ...state,
                      is_refund: true,
                    });
                  }}
                  size="sm"
                />
              </div>
            </div>
            <div className={styles['p-priceChange__item']}>
              <p className={styles['p-priceChange__item__ttl']}>
                {t('Adjustment Reason')}
              </p>
              <TextField
                value={state.notes}
                onChange={(value) => {
                  setState({
                    ...state,
                    notes: value,
                  });
                }}
              />
            </div>
            <div className={styles['p-priceChange__item']}>
              <p className={styles['p-priceChange__item__ttl']}>
                {t('Adjustment Amount')}
              </p>
              {GrossAmountTextField}
            </div>
            <div className={styles['p-priceChange__item']}>
              <Accordion headerText={t('Adjust Price and Commission')}>
                <div className={styles['p-priceChange__item__ac__body__flex']}>
                  <div
                    className={
                      styles['p-priceChange__item__ac__body__flex__item']
                    }
                  >
                    <p
                      className={
                        styles['p-priceChange__item__ac__body__flex__item__ttl']
                      }
                    >
                      {t('Gross') + ` (${currency})`}
                    </p>
                    {GrossAmountTextField}
                  </div>
                  <div
                    className={
                      styles['p-priceChange__item__ac__body__flex__item']
                    }
                  >
                    <p
                      className={
                        styles['p-priceChange__item__ac__body__flex__item__ttl']
                      }
                    >
                      {t('Net') + ` (${currency})`}
                    </p>
                    <TextField
                      value={state.amount_net}
                      error={
                        state.helper.inputInvalid(state.amount_net)
                          ? t('Invalid')
                          : ''
                      }
                      type="string"
                      onChange={(value) => {
                        if (!state.helper.inputAllowed(value)) {
                          return;
                        }
                        const moneyInput = state.helper.moneyInput(value);
                        setState({
                          ...state,
                          amount_net: moneyInput,
                          amount_commission: state.helper.moneyInput(
                            state.helper.diff(state.amount_gross, moneyInput)
                          ),
                        });
                      }}
                    />
                  </div>
                  <div
                    className={
                      styles['p-priceChange__item__ac__body__flex__item']
                    }
                  >
                    <p
                      className={
                        styles['p-priceChange__item__ac__body__flex__item__ttl']
                      }
                    >
                      {t('Commission') + ` (${currency})`}
                    </p>
                    <TextField
                      value={state.amount_commission}
                      error={
                        state.helper.inputInvalid(state.amount_commission)
                          ? t('Invalid')
                          : ''
                      }
                      type="string"
                      onChange={(value) => {
                        if (!state.helper.inputAllowed(value)) {
                          return;
                        }
                        const moneyInput = state.helper.moneyInput(value);
                        setState({
                          ...state,
                          amount_commission: moneyInput,
                          amount_net: state.helper.moneyInput(
                            state.helper.diff(state.amount_gross, moneyInput)
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
              </Accordion>
            </div>
            <div className={styles['p-priceChange__item']}>
              <Accordion headerText={t('Adjust POB Amount')}>
                <div className={styles['p-priceChange__item__ac__body__flex']}>
                  <div
                    className={
                      styles['p-priceChange__item__ac__body__flex__item']
                    }
                  >
                    <p
                      className={
                        styles['p-priceChange__item__ac__body__flex__item__ttl']
                      }
                    >
                      {t('Paid in advance') + ` (${currency})`}
                    </p>
                    <TextField
                      disabled={!isPIFAvailable}
                      value={state.amount_pay_in_advance}
                      error={
                        state.helper.inputInvalid(state.amount_pay_in_advance)
                          ? t('Invalid')
                          : ''
                      }
                      type="string"
                      onChange={(value) => {
                        if (!state.helper.inputAllowed(value)) {
                          return;
                        }
                        const moneyInput = state.helper.moneyInput(value);
                        setState({
                          ...state,
                          amount_pay_in_advance: moneyInput,
                          amount_pay_on_board: state.helper.moneyInput(
                            state.helper.diff(state.amount_gross, moneyInput)
                          ),
                        });
                      }}
                    />
                  </div>
                  <div
                    className={
                      styles['p-priceChange__item__ac__body__flex__item']
                    }
                  >
                    <p
                      className={
                        styles['p-priceChange__item__ac__body__flex__item__ttl']
                      }
                    >
                      {t('Paid on board') + ` (${currency})`}
                    </p>
                    <TextField
                      disabled={!isPIFAvailable /* fill automatically */}
                      value={state.amount_pay_on_board}
                      error={
                        state.helper.inputInvalid(state.amount_pay_on_board)
                          ? t('Invalid')
                          : ''
                      }
                      type="string"
                      onChange={(value) => {
                        if (!state.helper.inputAllowed(value)) {
                          return;
                        }
                        const moneyInput = state.helper.moneyInput(value);
                        setState({
                          ...state,
                          amount_pay_on_board: moneyInput,
                          amount_pay_in_advance: state.helper.moneyInput(
                            state.helper.diff(state.amount_gross, moneyInput)
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                <>
                  {isDirectChargable && (
                    <p
                      className={styles['p-priceChange__item__ac__body__note']}
                    >
                      {t(
                        '* Amount in "Paid on board" will not be charged or refunded to credit card. To make an additional charge or refund to the credit card, please put the amount in "Paid in advance"'
                      )}
                    </p>
                  )}
                </>
              </Accordion>
            </div>
            {reservation?.billing_info?.amount_gross &&
              reservation?.billing_info?.amount_net &&
              state.amount_gross &&
              state.amount_gross !== '0' &&
              state.amount_net &&
              state.amount_net !== '0' && (
                <div className={styles['p-priceChange__item']}>
                  <table className={styles['p-priceChange__item__table']}>
                    <tbody>
                      <tr>
                        <th style={{ width: '96px' }}></th>
                        <th>{t('Current amount')}</th>
                        <th>{t('After amount adjustment')}</th>
                      </tr>
                      <tr>
                        <th>{t('Gross')}</th>
                        <td>
                          {/* Special handling for cancelled reservation with cancellation fee. We show cancellation fee in current amount. */}
                          {isCancelledReservation &&
                          reservation.billing_info.amount_cancellation_fee_gross
                            ? formattedCurrencyAmount(
                                currencyjs(
                                  reservation.billing_info
                                    .amount_cancellation_fee_gross
                                ).format()
                              )
                            : formattedCurrencyAmount(
                                currencyjs(
                                  reservation.billing_info?.amount_gross
                                ).format()
                              )}
                        </td>
                        <td>
                          {formattedCurrencyAmount(
                            state.is_refund
                              ? isCancelledReservation &&
                                reservation.billing_info
                                  .amount_cancellation_fee_gross
                                ? currencyjs(
                                    reservation.billing_info
                                      .amount_cancellation_fee_gross
                                  )
                                    .subtract(state.amount_gross)
                                    .format()
                                : currencyjs(
                                    reservation.billing_info?.amount_gross
                                  )
                                    .subtract(state.amount_gross)
                                    .format()
                              : isCancelledReservation &&
                                reservation.billing_info
                                  .amount_cancellation_fee_gross
                              ? currencyjs(
                                  reservation.billing_info
                                    .amount_cancellation_fee_gross
                                )
                                  .add(state.amount_gross)
                                  .format()
                              : currencyjs(
                                  reservation.billing_info?.amount_gross
                                )
                                  .add(state.amount_gross)
                                  .format()
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Net')}</th>
                        <td>
                          {isCancelledReservation &&
                          reservation.billing_info.amount_cancellation_fee_net
                            ? formattedCurrencyAmount(
                                currencyjs(
                                  reservation.billing_info
                                    .amount_cancellation_fee_net
                                ).format()
                              )
                            : formattedCurrencyAmount(
                                currencyjs(
                                  reservation.billing_info?.amount_net
                                ).format()
                              )}
                        </td>
                        <td>
                          {formattedCurrencyAmount(
                            state.is_refund
                              ? isCancelledReservation &&
                                reservation.billing_info
                                  .amount_cancellation_fee_net
                                ? currencyjs(
                                    reservation.billing_info
                                      .amount_cancellation_fee_net
                                  )
                                    .subtract(state.amount_net)
                                    .format()
                                : currencyjs(
                                    reservation.billing_info?.amount_net
                                  )
                                    .subtract(state.amount_net)
                                    .format()
                              : isCancelledReservation &&
                                reservation.billing_info
                                  .amount_cancellation_fee_net
                              ? currencyjs(
                                  reservation.billing_info
                                    .amount_cancellation_fee_net
                                )
                                  .add(state.amount_net)
                                  .format()
                              : currencyjs(reservation.billing_info?.amount_net)
                                  .add(state.amount_net)
                                  .format()
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            {resultAmountIsNegative && (
              <p className={baseStyles['u-error-msg']}>
                {t(
                  'Adjustment amounts that result in negative gross or negative net are not allowed'
                )}
              </p>
            )}
            {/* Do not allow full refund for GMO, allow for other payment gateway */}
            {resultAmountIsZero &&
              reservation.payment_gateway &&
              reservation.payment_gateway === 'GMO' && (
                <p className={baseStyles['u-error-msg']}>
                  {t(
                    'Unable to make adjustments for a full refund. Please contact Nutmeg support team.'
                  )}
                </p>
              )}
          </div>
          <div className={styles['p-priceChange__box']}>
            <TextArea
              label={t('Replies')}
              height={150}
              value={state.supplierNotes}
              onChange={(e: any) => {
                setState({
                  ...state,
                  supplierNotes: e.target.value,
                });
              }}
            />
          </div>

          {(isChargeable || isRefundable) &&
            isDirectChargable &&
            !savedPaymentInfoAvailable && (
              <div className={styles['p-priceChange__box']}>
                <div className={styles['p-priceChange__item']}>
                  <p className={styles['p-priceChange__item__ttl']}>
                    {t('Credit or Debit Card Information')}
                  </p>
                  <CardElement
                    onChange={handleCardChange}
                    style={cardElementStyle}
                    hidePostalCode={true}
                  />
                  <div
                    className={clsx(
                      baseStyles['u-mt-6'],
                      baseStyles['u-error-msg']
                    )}
                  >
                    {cardInputErrors}
                  </div>
                </div>
              </div>
            )}
          {(isChargeable || isRefundable) &&
            isDirectChargable &&
            savedPaymentInfoAvailable && (
              <div className={styles['p-priceChange__box']}>
                <div className={styles['p-priceChange__item']}>
                  <p className={styles['p-priceChange__item__ttl']}>
                    {t('Credit card payment')}
                  </p>
                  <div className={styles['p-priceChange__item__card']}>
                    <i className="c-icon-solid-finance-credit-card-up"></i>
                    <p>
                      {state.is_refund
                        ? t('Refund amount')
                        : t('Additional charge')}{' '}
                      {formattedCurrencyAmount(
                        currencyjs(
                          `${currency}${state.amount_pay_in_advance}`
                        ).format()
                      )}
                    </p>
                  </div>
                  {isChargeable && (
                    <div className={styles['p-priceChange__item__cardCheck']}>
                      <Checkbox
                        label={t(
                          'Guest has agreed that additional charge will be made to the same credit card.'
                        )}
                        checked={state.checkAgreement}
                        onChange={() => {
                          setState({
                            ...state,
                            checkAgreement: !state.checkAgreement,
                          });
                        }}
                        size="sm"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

          {fareAdjustStatus === 'FAILED' && (
            <div className={styles['p-priceChange__box']}>
              <p className={baseStyles['u-error-msg']}>
                {t(
                  'Failed to make an additional charge. Please contact guest to ask card issuer for the error details.'
                )}
              </p>
            </div>
          )}
        </div>
      </Modal>
    );
  }
);
