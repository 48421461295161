import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './JourneyAnalyticsEmbedCode.module.css';

export const JourneyAnalyticsEmbedCode = () => {
  const { t } = useTranslation();
  const [widgetLanguage, setWidgetLanguage] = React.useState<string>('ja-JP');
  const organization = useSelector(activeUserOrganizationSelector);
  const languageOptions = [
    {
      text: t('English'),
      value: 'en-US',
      key: 'en-US',
    },
    {
      text: t('Japanese'),
      value: 'ja-JP',
      key: 'ja-JP',
    },
  ];

  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';

  if (!bookingWidgetApiKey) {
    return (
      <Message warning>
        {t(
          'Booking widget not currently configured. Please contact sales@nutmeglabs.com if interested.'
        )}
      </Message>
    );
  }

  return (
    <V3Page>
      <PageHeader
        title={t('Embed Code')}
        description={t(
          'Create embed code for your own website to track user interactions'
        )}
      />
      <PageContent>
        <div className={styles['container']}>
          <Box mt={4}>
            <SingleDropdown
              label={t('Language')}
              options={languageOptions}
              onChange={(newOption) => {
                setWidgetLanguage(newOption);
              }}
              selectedOption={widgetLanguage}
            />
          </Box>
          <div className={styles['code-container']}>
            <div className={styles['code-label']}>{t('Code')}</div>
            <pre
              className={styles['code-block']}
            >{`<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        var scriptUrl = 'https://assets.ntmg.com/scripts/journey/index.min.js';

        if (window.NutmegAnalytics) {
            NutmegAnalyticsInit();
        } else {
            loadScriptAsync();
        }
        function loadScriptAsync() {
            var script = document.createElement('script');
            script.src = scriptUrl;
            script.async = true;
            (document.getElementsByTagName('head')[ 0 ] || document.getElementsByTagName('body')[ 0 ]).appendChild(script);
            script.onload = NutmegAnalyticsInit;
        }
        function NutmegAnalyticsInit() {
            window.NutmegAnalytics.init('${bookingWidgetApiKey}', '${config.nutmegExternalApiBaseUrl}', '${widgetLanguage}');
        }
    })();
    /*]]>*/
</script>`}</pre>
          </div>
        </div>
      </PageContent>
    </V3Page>
  );
};
