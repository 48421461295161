import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReservationBulkCancelTimeOutModal = ({ setShowModal }: Props) => {
  const { t } = useTranslation();

  const text = t(
    'There was a timeout with the network. It is possible that some bookings have not been canceled/declined. We apologize for the inconvenience, but please search and cancel/decline the reservation again.'
  );

  return (
    <Modal
      title={t('Cancellation/Decline Timeout')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <div>{text}</div>
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('OK')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
