import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Select } from 'client/components/Form';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';

type ListEditorProps = {
  name: string;
  title?: string;
  style?: Record<string, any>;
  fieldDisabled?: boolean;
  buttonDisabled?: boolean;
  suggestions?: string[];
  addButtonText?: string;
};

export const ListEditor = ({
  name,
  title,
  style,
  fieldDisabled,
  buttonDisabled,
  suggestions,
  addButtonText,
}: ListEditorProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);
  const combinedStyle = {
    maxWidth: 800,
    ...style,
  };

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            <div className={styles['p-list__item__body__list']}>
              {fields.length !== 0 &&
                fields.map((name, index) => (
                  <CollapsibleBox key={name} title={`${title} ${index + 1}`}>
                    <TranslatedField key={name} name={name}>
                      {({ input, translationInput }) => (
                        <ul className={styles['p-list']}>
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <>
                                {suggestions && suggestions.length > 0 ? (
                                  <Select
                                    search
                                    allowFreeInput={true}
                                    value={input.value}
                                    onChange={(e, { value }) =>
                                      input.onChange(value)
                                    }
                                    options={suggestions
                                      .filter(
                                        (suggestion) =>
                                          !(fields.value ?? []).includes(
                                            suggestion
                                          ) || input.value === suggestion
                                      )
                                      .map((suggestion) => ({
                                        value: suggestion,
                                        text: suggestion,
                                      }))}
                                  />
                                ) : (
                                  <TextField
                                    style={combinedStyle}
                                    disabled={fieldDisabled}
                                    {...input}
                                  />
                                )}
                              </>
                            </div>
                          </li>
                          {translationTargetLanguage && (
                            <li className={styles['p-list__item']}>
                              <div className={styles['p-list__item__ttl']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                              <div className={styles['p-list__item__body']}>
                                <TextField
                                  {...translationInput}
                                  disabled={fieldDisabled}
                                />
                              </div>
                            </li>
                          )}
                        </ul>
                      )}
                    </TranslatedField>
                  </CollapsibleBox>
                ))}
              {!buttonDisabled && (
                <div className={baseStyles['u-mt-2']}>
                  <a
                    className={styles['p-box__table2__actions__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, '');
                      } else {
                        (fields as any).insertAt(0, '');
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {addButtonText}
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
};
