import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ListEditor';
import type { ReduxState } from 'client/reducers';

export const InternalTagsEditor = () => {
  const { t } = useTranslation();
  const internalTagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.internalTags
  );

  return (
    <ListEditor
      title={t('Tags')}
      name="internalTags"
      suggestions={internalTagSuggestions}
      buttonDisabled={true}
    />
  );
};
