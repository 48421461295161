import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { convertAccessReportSettingsToQueryParams } from 'client/pages/v3/BookingWidget/AccessReport/utils';
import { fetchAccessReportData } from 'client/actions/accessReportData';
import { setLastAccessReportSettings } from 'client/actions/accessReportSettings';
import {
  convertAccessReportDataSetsToGraphData,
  convertAccessReportDataSetsToTableData,
} from 'client/libraries/util/accessReportSettings';
import type { AccessReportSettings } from 'client/libraries/util/accessReportSettings';
import { Radio } from 'client/components/v3/Form/Radio';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/AccessReport/AccessReport.module.css';
import { ChartPane } from 'client/pages/v3/BookingWidget/AccessReport/component/ChartPane';
import { CompareTablePane } from 'client/pages/v3/BookingWidget/AccessReport/component/CompareTablePane';
import { TotalTablePane } from 'client/pages/v3/BookingWidget/AccessReport/component/TotalTablePane';
import { DetailTablePane } from 'client/pages/v3/BookingWidget/AccessReport/component/DetailTablePane';

export const AccessReportGraphBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const reportData = useSelector(
    (state: ReduxState) => state.accessReportData.reportData
  );
  const lastAccessReportSettings = useSelector(
    (state: ReduxState) => state.accessReportSettings.lastAccessReportSettings
  );
  const loading = useSelector(
    (state: ReduxState) => state.accessReportData.loading
  );
  const [accessReportSettings, setAccessReportSettings] =
    React.useState<AccessReportSettings>(lastAccessReportSettings);

  const graphPaneHeight = 600;
  const getBarDateKeys = () => {
    if (accessReportSettings.chartDisplayType === 'PV') {
      return reportData.length === 1
        ? ['visitCount']
        : ['visitCount', 'compareVisitCount'];
    }

    return reportData.length === 1
      ? ['userCount']
      : ['userCount', 'compareUserCount'];
  };

  const names = {
    visitCount: t('visitCount'),
    compareVisitCount: t('compareVisitCount'),
    userCount: t('userCount'),
    compareUserCount: t('compareUserCount'),
    reservationCount: t('reservationCount'),
    compareReservationCount: t('compareReservationCount'),
  };
  const graphData = React.useMemo(() => {
    return convertAccessReportDataSetsToGraphData(reportData);
  }, [reportData]);
  const tableData = React.useMemo(() => {
    return convertAccessReportDataSetsToTableData(reportData);
  }, [reportData]);

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
      dispatch(setLastAccessReportSettings(accessReportSettings));
    }
  }, [accessReportSettings.unit]);

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(setLastAccessReportSettings(accessReportSettings));
    }
  }, [accessReportSettings.chartDisplayType]);

  React.useEffect(() => {
    setAccessReportSettings(lastAccessReportSettings);
  }, [lastAccessReportSettings]);

  return (
    <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
      <div className={styles['p-reservations']}>
        <div className={styles['p-reservations__header']}>
          <div
            className={styles['p-reports__body__item__flex']}
            style={{ alignItems: 'center' }}
          >
            <p className={styles['p-reports__body__item__radiotitle']}>
              {t('Display')}
            </p>
            <Radio
              label={t('visitCount')}
              checked={accessReportSettings.chartDisplayType === 'PV'}
              onChange={() => {
                setAccessReportSettings({
                  ...accessReportSettings,
                  chartDisplayType: 'PV',
                });
              }}
              size="sm"
            />
            <Radio
              label={t('userCount')}
              checked={accessReportSettings.chartDisplayType === 'USER'}
              onChange={() => {
                setAccessReportSettings({
                  ...accessReportSettings,
                  chartDisplayType: 'USER',
                });
              }}
              size="sm"
            />
          </div>
          <div className={styles['p-reservations__dropdown']}>
            <span className={styles['p-reservations__dropdown-txt']}>
              {t('Aggregation')}
            </span>
            <ul className={styles['p-reservations__tab']}>
              <li
                className={clsx(
                  accessReportSettings.unit === 'DAY' ? styles['is-active'] : ''
                )}
                onClick={() => {
                  setAccessReportSettings({
                    ...accessReportSettings,
                    unit: 'DAY',
                  });
                }}
              >
                {t('day')}
              </li>
              <li
                className={clsx(
                  accessReportSettings.unit === 'WEEK'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  setAccessReportSettings({
                    ...accessReportSettings,
                    unit: 'WEEK',
                  });
                }}
              >
                {t('week')}
              </li>
              <li
                className={clsx(
                  accessReportSettings.unit === 'MONTH'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  setAccessReportSettings({
                    ...accessReportSettings,
                    unit: 'MONTH',
                  });
                }}
              >
                {t('month')}
              </li>
            </ul>
          </div>
        </div>
        {loading ? (
          <Loading size="lg" />
        ) : (
          <>
            <div className={styles['p-reservations__graf']}>
              <div
                className={styles['graph-pane-wrapper']}
                style={{
                  height: graphPaneHeight,
                }}
              >
                <ChartPane
                  data={graphData}
                  barDataKeys={getBarDateKeys()}
                  names={names}
                />
              </div>
            </div>
            <div style={{ width: '100%', padding: '20px 24px' }}>
              {reportData?.length === 1 && (
                <div>
                  <p
                    style={{
                      fontWeight: 'var(--text-semibold) !important',
                      fontSize: 'var(--text-lg-size) !important',
                      marginBottom: '10px',
                    }}
                  >
                    {t('Total')}
                  </p>
                  <TotalTablePane data={tableData} />
                </div>
              )}
              {reportData?.length === 2 && (
                <div style={{ width: '100%', padding: '20px 0' }}>
                  <p
                    style={{
                      fontWeight: 'var(--text-semibold) !important',
                      fontSize: 'var(--text-lg-size) !important',
                      marginBottom: '10px',
                    }}
                  >
                    {t('Compare')}
                  </p>
                  <CompareTablePane dataSets={reportData} />
                </div>
              )}

              {reportData?.length === 1 && (
                <div style={{ width: '100%', padding: '20px 0' }}>
                  <p
                    style={{
                      fontWeight: 'var(--text-semibold) !important',
                      fontSize: 'var(--text-lg-size) !important',
                      marginBottom: '10px',
                    }}
                  >
                    {t('Details')}
                  </p>
                  <DetailTablePane data={tableData} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};
