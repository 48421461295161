import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateFaqSettings } from 'client/actions/essentialPages';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';
import type { SourceLanguage, FaqSettings } from 'shared/models/swagger';
import { CollapsibleBox } from 'client/pages/v3/BookingWidget/EssentialPages/CollapsibleSection/CollapsibleBox';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';

export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

export const FAQTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  console.log(saveSucceeded);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');
  console.log(setTranslationTargetLanguage);

  const { required } = getValidators(t);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      faqs: activeUserOrganization?.essential_pages?.faq_settings?.faqs || [],
      visibility:
        activeUserOrganization?.essential_pages?.faq_settings?.visibility ||
        false,
    }),
    [apiTranslations, translationTargetLanguage, activeUserOrganization]
  );

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  //const changeShowTranslationMode = (show: boolean) => {
  //  setShowTranslationMode(show);
  //};

  //const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
  //  setTranslationTargetLanguage(lang);
  //};

  return (
    <Form
      onSubmit={async (values: FaqSettings & TranslationsFormValues) => {
        try {
          await dispatch(updateFaqSettings(values));
          setSaveSucceeded(true);
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={initialValuesWithTranslations}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div
            className={clsx(
              styles['p-inquiry-tabs__tab-panel'],
              styles['components_is-active__B15DT']
            )}
            style={{ display: 'block' }}
          >
            {submitSucceeded && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'success'}
                text={t('Save Successful')}
                shouldShow={submitSucceeded}
              />
            )}
            {submitError && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'error'}
                text={t('Save Failed')}
                shouldShow={submitError}
              />
            )}
            <div className={styles['p-inquiry-tabs__content']}>
              <ul className={styles['p-inquiry-list']}>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Visibility')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="visibility" type="checkbox">
                        {({ input }) => (
                          <div>
                            <Toggle
                              label={t('Show FAQ page on booking website')}
                              {...input}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Other Company Profile')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <FieldArray name="faqs">
                        {({ fields }) => {
                          const sectionCount = fields.length ?? 0;
                          return (
                            <>
                              {fields.map((name, idx) => (
                                <>
                                  <CollapsibleBox
                                    key={fields.value?.[idx].key}
                                    id={name}
                                    title={t('Category #{{idx}}', {
                                      idx: idx + 1,
                                    })}
                                    isDeleteable={true}
                                    onDelete={async () => {
                                      fields.remove(idx);
                                    }}
                                  >
                                    <Field
                                      name={`${name}.category_title`}
                                      validate={required}
                                    >
                                      {({
                                        input,
                                        meta: { error, touched },
                                      }) => (
                                        <div>
                                          <p> {t('Category Title')} </p>
                                          <p>
                                            <TextField
                                              {...input}
                                              error={touched && error}
                                            />
                                          </p>
                                        </div>
                                      )}
                                    </Field>
                                    <FieldArray
                                      name={`${name}.question_and_answer`}
                                    >
                                      {({ fields: innerFields }) => {
                                        const innerSectionCount =
                                          innerFields.length ?? 0;
                                        return (
                                          <>
                                            {innerFields.map(
                                              (name, formIdx) => (
                                                <>
                                                  <CollapsibleBox
                                                    key={
                                                      innerFields.value?.[
                                                        formIdx
                                                      ].key
                                                    }
                                                    id={name}
                                                    title={t(
                                                      'Question #{{idx}}',
                                                      {
                                                        idx: formIdx + 1,
                                                      }
                                                    )}
                                                    isDeleteable={true}
                                                    onDelete={async () => {
                                                      innerFields.remove(
                                                        formIdx
                                                      );
                                                    }}
                                                  >
                                                    <Field
                                                      name={`${name}.question`}
                                                      validate={required}
                                                    >
                                                      {({
                                                        input,
                                                        meta: {
                                                          error,
                                                          touched,
                                                        },
                                                      }) => (
                                                        <div>
                                                          <p>{t('Question')}</p>
                                                          <p>
                                                            <TextField
                                                              {...input}
                                                              error={
                                                                touched && error
                                                              }
                                                            />
                                                          </p>
                                                        </div>
                                                      )}
                                                    </Field>
                                                    <Field
                                                      name={`${name}.answer`}
                                                      validate={required}
                                                    >
                                                      {({
                                                        input,
                                                        meta: {
                                                          error,
                                                          touched,
                                                        },
                                                      }) => (
                                                        <div>
                                                          <p> {t('Answer')} </p>
                                                          <p>
                                                            <TextField
                                                              {...(input as any)}
                                                              error={
                                                                touched && error
                                                              }
                                                              height={100}
                                                            />
                                                          </p>
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </CollapsibleBox>
                                                </>
                                              )
                                            )}
                                            <a
                                              className={styles['add__button']}
                                              onClick={() => {
                                                if (innerSectionCount === 0) {
                                                  (innerFields as any).insertAt(
                                                    innerSectionCount,
                                                    ''
                                                  );
                                                } else {
                                                  (innerFields as any).insertAt(
                                                    innerSectionCount + 1,
                                                    ''
                                                  );
                                                }
                                              }}
                                            >
                                              <i className="c-icon-outline-general-plus-circle"></i>
                                              {t('Add')}
                                            </a>
                                          </>
                                        );
                                      }}
                                    </FieldArray>
                                  </CollapsibleBox>
                                </>
                              ))}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  if (sectionCount === 0) {
                                    (fields as any).insertAt(sectionCount, '');
                                  } else {
                                    (fields as any).insertAt(
                                      sectionCount + 1,
                                      ''
                                    );
                                  }
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles['p-products__fixed'], styles['is-active'])}
          >
            <div className={styles['p-products__fixed__actions']}>
              <Button
                disabled={submitting}
                loading={submitting}
                text={t('Save')}
                type="submit"
                color="primary"
                size="md"
                form="submit"
                style={{
                  width: '60px',
                  fontWeight: 'var(--text-semibold)',
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
