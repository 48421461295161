import _ from 'lodash';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers/index';
import type { ResourceType } from 'client/libraries/util/resourceType';
import {
  fetchManifest,
  fetchManifestPDF,
  fetchManifestCSV,
} from 'client/actions/manifests';
import { fetchProducts } from 'client/actions/products';
import {
  allManifestViewsSelector,
  manifestFuseStartTimesSelector,
  manifestExcludedFormFieldsSelector,
  manifestProductGroupsSelector,
  manifestShowReservationsOnAllOperatingDaysSelector,
  manifestCustomizedColumnNamesSelector,
} from 'client/reducers/manifestSettings';
import { StartTimeManifestReservationsTableList } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/StartTimeManifestReservationsTableList';
import { ManifestReservationsTable } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestReservationsTable';
import {
  getDriverManifestView,
  getReservationSortMethodFromReservationColumn,
  getVisibleColumns,
  getOrderByColumns,
  getColumnsParam,
} from 'client/reducers/manifestDefaults';
import { Loading } from 'client/pages/Loading';
import { EditReservationResourceAssignmentModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/EditReservationResourceAssignmentModal';
import { fetchGuideSingleDaySchedules } from 'client/actions/guides';
import { ResourceBulkUpdateModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/ResourceBulkUpdateModal';
import { ResourceReservationListModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/ResourceReservationListModal';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import 'react-table/react-table.css';
import type { ManifestProductGroup, Product } from 'shared/models/swagger';
import { ManifestProductGroupTableList } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestProductGroupTableList';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { VisibilitySettingsModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/VisibilitySettingsModal';
import { SendEmailModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/SendEmailModal';

type Props = {
  reservations: ManifestReservationShape[];
  products: Product[];
  startTimeHhMmFilters: string[];
  manifestType?: string;
  participationDate?: string;
  rootResource?: {
    id: string;
    type: 'PRODUCT' | 'GROUP' | 'ALL_PRODUCTS';
  };
  showCheckinCheckout: boolean;
  showDispatchColumn: boolean;
  showByProductGroup: boolean;
  onVisualFlagsChange: (arg0: {
    showCheckinCheckout: boolean;
    showDispatchColumn: boolean;
    showByProductGroup: boolean;
  }) => void;
};
export const ManifestDetails = ({
  reservations,
  startTimeHhMmFilters,
  manifestType,
  participationDate,
  rootResource,
  showCheckinCheckout,
  showDispatchColumn,
  showByProductGroup,
  onVisualFlagsChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDownloadMenu, setShowDownloadMenu] = useState<boolean>(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showDownloadMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowDownloadMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showDownloadMenu]);

  const allManifestViews = useSelector(allManifestViewsSelector);
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const loading = useSelector(
    (state: ReduxState) =>
      state.manifests.loading ||
      state.products.loading ||
      state.reservations.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const manifestCsvStatus = useSelector(
    (state: ReduxState) => state.manifests.csvStatus
  );
  const manifestCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  const manifestEmailStatus = useSelector(
    (state: ReduxState) => state.manifests.emailStatus
  );
  const manifestExcludedFormFieldKeys = useSelector(
    manifestExcludedFormFieldsSelector
  );
  const manifestPdfStatus = useSelector(
    (state: ReduxState) => state.manifests.pdfStatus
  );
  const manifestProductGroups = useSelector(manifestProductGroupsSelector);
  const showReservationsOnAllOperatingDays = useSelector(
    manifestShowReservationsOnAllOperatingDaysSelector
  );
  const splitStartTimes = useSelector(
    (state: ReduxState) => !manifestFuseStartTimesSelector(state)
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);

  const activeUser = useSelector(activeUserSelector);

  const [
    editResourceTargetReservationIds,
    setEditResourceTargetReservationIds,
  ] = useState<string[]>([]);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] =
    useState<boolean>(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState<boolean>(false);
  const [showVisibilitySettings, setShowVisibilitySettings] =
    useState<boolean>(false);
  const [showResourceTarget, setShowResourceTarget] = useState<{
    key: string;
    name?: string;
    resourceType: ResourceType;
  } | null>(null);
  const [productIds, setProductIds] = useState<string[]>([]);
  const manifestView =
    allManifestViews.find((view) => view.key === manifestType) ||
    getDriverManifestView();
  let productGroup: ManifestProductGroup | null = null;

  if (rootResource?.type === 'GROUP') {
    const productGroupKey = rootResource?.id;

    if (productGroupKey) {
      productGroup =
        manifestProductGroups.find((group) => group.key === productGroupKey) ??
        null;
    }
  }

  let manifestProductIds: string[] = [];

  if (productGroup && productGroup.product_ids) {
    manifestProductIds = productGroup.product_ids;
  } else {
    if (rootResource?.type === 'PRODUCT') {
      const productId = rootResource?.id;

      if (productId) {
        manifestProductIds = [productId];
      }
    }
  }

  const visibleColumns = getVisibleColumns(manifestView);
  const formattedDate =
    participationDate && moment(participationDate).locale(locale).format('LL');
  const totalPax = reservations.reduce((p, c) => {
    return p + (c.guests || []).length;
  }, 0);

  const sortedReservations = _.orderBy(
    reservations,
    (getOrderByColumns(manifestView, splitStartTimes) || []).map((column) => {
      return getReservationSortMethodFromReservationColumn(column);
    })
  );

  let manifestNote = '';

  if (productGroup && productGroup.key) {
    manifestNote = productGroup.key;
  } else {
    if (rootResource?.type === 'PRODUCT') {
      const productId = rootResource?.id;

      if (productId) {
        const product = productSummaries.find((p) => p.id === productId);

        if (product) {
          manifestNote = product.product_name || '';
        }
      }
    }
  }

  useEffect(() => {
    const newProductIds = _.sortBy([
      ...new Set(reservations.map((r) => r.product_id)),
    ]);

    if (!_.isEqual(productIds, newProductIds)) {
      setProductIds(newProductIds);
    }
  }, [reservations]);
  useEffect(() => {
    dispatch(fetchGuideSingleDaySchedules());
  }, []);

  // Fetch manifests whenever conditions change
  // eg. date is changed, type is changed, resource is assigned etc..
  useEffect(() => {
    fetchTargetManifest();
  }, [
    invalidated,
    participationDate,
    rootResource,
    manifestType,
    showCheckinCheckout,
  ]);

  useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(fetchProducts());
    }
  }, [isNutmegAdmin]);

  const fetchTargetManifest = () => {
    dispatch(
      fetchManifest({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        exclude_checkin_checkout: !showCheckinCheckout,
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        product_group_key: productGroup ? productGroup.key : undefined,
      })
    );
  };

  const downloadCSV = () => {
    dispatch(
      fetchManifestCSV({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        columns: [
          'START_TIME',
          ...getColumnsParam(
            manifestView,
            splitStartTimes,
            showReservationsOnAllOperatingDays
          ),
        ],
        order_by_columns: [
          'START_TIME',
          ...getOrderByColumns(manifestView, splitStartTimes),
        ],
        exclude_reservation_form_field_keys: manifestExcludedFormFieldKeys,
        timezone: moment.tz.guess(),
        exclude_checkin_checkout: !showCheckinCheckout,
        start_times_local: startTimeHhMmFilters,
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        customized_column_names: manifestCustomizedColumnNames,
        product_group_key: productGroup ? productGroup.key : undefined,
      })
    );
  };

  const downloadPDF = () => {
    dispatch(
      fetchManifestPDF({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        columns: getColumnsParam(
          manifestView,
          splitStartTimes,
          showReservationsOnAllOperatingDays
        ),
        exclude_reservation_form_field_keys: manifestExcludedFormFieldKeys,
        order_by_columns: getOrderByColumns(manifestView, splitStartTimes),
        exclude_checkin_checkout: !showCheckinCheckout,
        start_times_local: startTimeHhMmFilters,
        timezone: moment.tz.guess(),
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        customized_column_names: manifestCustomizedColumnNames,
        fuse_start_times: {
          value: !splitStartTimes,
        },
        product_group_key: productGroup ? productGroup.key : undefined,
        should_show_by_product_group: showByProductGroup ?? undefined,
      })
    );
  };

  const handleToggleShowCheckinCheckout = () => {
    onVisualFlagsChange({
      showCheckinCheckout: !showCheckinCheckout,
      showDispatchColumn: showDispatchColumn,
      showByProductGroup: showByProductGroup,
    });
  };

  const handleToggleShowDispatchColumn = () => {
    onVisualFlagsChange({
      showCheckinCheckout: showCheckinCheckout,
      showDispatchColumn: !showDispatchColumn,
      showByProductGroup: showByProductGroup,
    });
  };

  const handleToggleShowByProductGroup = () => {
    onVisualFlagsChange({
      showCheckinCheckout: showCheckinCheckout,
      showDispatchColumn: showDispatchColumn,
      showByProductGroup: !showByProductGroup,
    });
  };

  const handleShowResourceTarget = (
    key: string,
    resourceType: ResourceType,
    name: string
  ) => {
    setShowResourceTarget({
      key,
      name,
      resourceType,
    });
  };

  const setEditResourceTarget = (reservationId: string) => {
    setEditResourceTargetReservationIds([reservationId]);
  };

  if (!participationDate || !manifestView) {
    return null;
  }

  return (
    <>
      <div className={styles['p-manifests__actions']}>
        <div className={styles['p-manifests__actions__left']}>
          <Button
            text={t('Visibility Settings')}
            size="md"
            color="white"
            onClick={() => setShowVisibilitySettings(true)}
            iconBeforeText={
              <i className="c-icon-outline-general-settings-01"></i>
            }
          />
          {showVisibilitySettings && (
            <VisibilitySettingsModal
              open={showVisibilitySettings}
              title={t('Visibility Settings')}
              onClose={() => setShowVisibilitySettings(false)}
              showCheckinCheckout={showCheckinCheckout}
              showDispatchColumn={showDispatchColumn}
              showByProductGroup={showByProductGroup}
              onToggleShowCheckinCheckout={handleToggleShowCheckinCheckout}
              onToggleShowDispatchColumn={handleToggleShowDispatchColumn}
              onToggleShowByProductGroup={handleToggleShowByProductGroup}
            />
          )}
        </div>
        <div className={styles['p-manifests__actions__right']}>
          {hasCustomUserRoleWritePermissions(activeUser, 'MANIFEST.DAILY') && (
            <Button
              text={t('Bulk assign')}
              size="md"
              color="primary"
              onClick={() => {
                setOpenBulkUpdateModal(true);
              }}
            />
          )}
          <fieldset ref={fieldSetRef} className={styles['p-manifests__select']}>
            <Button
              text={t('Download')}
              color="white"
              onClick={() => {
                setShowDownloadMenu(!showDownloadMenu);
              }}
              iconBeforeText={
                <i className="c-icon-outline-general-download-02"></i>
              }
              iconAfterText={
                <i className="c-icon-outline-arrows-chevron-selector-vertical"></i>
              }
              loading={
                manifestPdfStatus === 'IN_FLIGHT' ||
                manifestCsvStatus === 'IN_FLIGHT'
              }
            />
            <ul
              className={clsx(
                styles['p-manifests__select__menu'],
                showDownloadMenu && styles['is-active']
              )}
            >
              <li className={styles['p-manifests__select__menu__item']}>
                <p
                  onClick={() => {
                    setShowDownloadMenu(false);
                    downloadPDF();
                  }}
                >
                  {t('Download as PDF')}
                </p>
              </li>
              <li className={styles['p-manifests__select__menu__item']}>
                <p
                  onClick={() => {
                    setShowDownloadMenu(false);
                    downloadCSV();
                  }}
                >
                  {t('Download as CSV')}
                </p>
              </li>
            </ul>
          </fieldset>
          <Button
            text={t('Send Emails')}
            size="md"
            color="white"
            onClick={() => {
              setOpenSendEmailModal(true);
            }}
            loading={manifestEmailStatus === 'IN_FLIGHT'}
          />
          <SendEmailModal
            defaultSubject={
              formattedDate
                ? t('Manifest - {{formattedDate}}', {
                    formattedDate,
                  })
                : t('Manifest')
            }
            open={openSendEmailModal}
            onClose={() => {
              setOpenSendEmailModal(false);
            }}
            manifestExportParams={{
              date: participationDate,
              note: manifestNote,
              product_ids: productGroup ? [] : manifestProductIds,
              columns: getColumnsParam(
                manifestView,
                splitStartTimes,
                showReservationsOnAllOperatingDays
              ),
              exclude_reservation_form_field_keys:
                manifestExcludedFormFieldKeys,
              order_by_columns: getOrderByColumns(
                manifestView,
                splitStartTimes
              ),
              exclude_checkin_checkout: !showCheckinCheckout,
              start_times_local: startTimeHhMmFilters,
              timezone: moment.tz.guess(),
              include_multiday_continuations:
                showReservationsOnAllOperatingDays,
              customized_column_names: manifestCustomizedColumnNames,
              fuse_start_times: {
                value: !splitStartTimes,
              },
              product_group_key: productGroup ? productGroup.key : undefined,
            }}
          />
        </div>
      </div>

      {showByProductGroup ? (
        <>
          <ManifestProductGroupTableList
            excludedFormFieldKeys={manifestExcludedFormFieldKeys}
            products={productSummaries}
            reservations={sortedReservations}
            visibleColumns={visibleColumns}
            open={true}
            customizedColumnNames={manifestCustomizedColumnNames}
            onEditResourceButtonClick={setEditResourceTarget}
            onResourceTextClick={handleShowResourceTarget}
            showDispatchColumn={showDispatchColumn}
            splitStartTimes={splitStartTimes}
            manifestDate={participationDate}
            rootResource={rootResource}
          />
        </>
      ) : (
        <>
          {splitStartTimes ? (
            <StartTimeManifestReservationsTableList
              manifestDate={participationDate}
              excludedFormFieldKeys={manifestExcludedFormFieldKeys}
              products={productSummaries}
              reservations={sortedReservations}
              visibleColumns={visibleColumns as any}
              customizedColumnNames={manifestCustomizedColumnNames}
              onEditResourceButtonClick={setEditResourceTarget}
              onResourceTextClick={handleShowResourceTarget}
              showDispatchColumn={showDispatchColumn}
            />
          ) : (
            <>
              <ManifestReservationsTable
                excludedFormFieldKeys={manifestExcludedFormFieldKeys}
                loading={loading}
                products={productSummaries}
                reservations={sortedReservations}
                visibleColumns={visibleColumns}
                totalPax={totalPax}
                open={true}
                customizedColumnNames={manifestCustomizedColumnNames}
                onEditResourceButtonClick={setEditResourceTarget}
                onResourceTextClick={handleShowResourceTarget}
                showDispatchColumn={showDispatchColumn}
              />
            </>
          )}
        </>
      )}

      <ResourceBulkUpdateModal
        reservations={sortedReservations}
        visibleColumns={visibleColumns}
        excludedFormFieldKeys={manifestExcludedFormFieldKeys}
        products={productSummaries}
        open={openBulkUpdateModal}
        onClose={() => {
          setOpenBulkUpdateModal(false);
        }}
        onResourceAssignmentButtonChange={setEditResourceTargetReservationIds}
      />

      <ResourceReservationListModal
        open={showResourceTarget !== null}
        resourceType={showResourceTarget?.resourceType || 'crew'}
        resourceKey={showResourceTarget?.key || ''}
        visibleColumns={visibleColumns}
        onClose={() => {
          setShowResourceTarget(null);
        }}
        reservations={sortedReservations}
        title={showResourceTarget?.name || ''}
      />

      <EditReservationResourceAssignmentModal
        open={editResourceTargetReservationIds.length > 0}
        reservations={[
          ...sortedReservations.filter((reservation) => {
            return editResourceTargetReservationIds.includes(reservation.id);
          }),
        ]}
        participationDate={participationDate}
        onClose={() => {
          setEditResourceTargetReservationIds([]);
          setOpenBulkUpdateModal(false);
        }}
        guideSingleDaySchedules={guideSingleDaySchedules || []}
        onSubmitSuccess={() => {
          // TODO: think of a better solution
          setTimeout(() => {
            fetchTargetManifest();
          }, 8000);
        }}
      />
      {loading && <Loading />}
    </>
  );
};
