import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { Button } from 'client/components/Form';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

interface Props {
  submitting: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ReservationSearchSettingsDefaultColumnModal = ({
  submitting,
  open,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const allColumns = getReservationTableColumns(t, 'en').filter((col) => {
    return col.id !== 'edit';
  });

  const getColumns = (columnMask: string[]): any[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c));
  };

  return (
    <Modal
      title={t('Edit Reservation List Columns')}
      open={open}
      onClose={() => onClose()}
    >
      <Modal.Content>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <Field name="defaultColumns">
                {({ input }) => (
                  <ToggleableDndList
                    selectedItems={getColumns(
                      input.value.map((c: any) => c.toLowerCase())
                    ).map((col) => ({
                      key: col.id,
                      text: col.Header,
                    }))}
                    setSelectedItems={(items) =>
                      input.onChange(
                        items.map((item) => item.key.toUpperCase())
                      )
                    }
                    candidateItems={
                      allColumns.map((col) => ({
                        key: col.id,
                        text: col.Header,
                      })) || []
                    }
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={submitting}
          size="middle"
          style="blue"
          onClick={() => {
            onSubmit();
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
