import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { ToggleButton } from 'client/components/Form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const DynamicPackageToggleButton = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Field name="isDynamicPackageProduct" type="checkbox">
      {({ input }) => (
        <Box mt={2} mb={2}>
          <ToggleButton
            label={
              <>
                {t('Sell this product as a dynamic package')}
                <Tooltip
                  text={t(
                    'Customers will be able to select the participation date and time for each sub-product when making a new booking.'
                  )}
                />
              </>
            }
            checked={value}
            onChange={() => {
              onChange(!value);
              input.onChange(!value);
            }}
          />
        </Box>
      )}
    </Field>
  );
};
