import * as React from 'react';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { config } from 'client/config';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { equipmentsSelector } from 'client/reducers/equipments';
import {
  fetchEquipments,
  setSortSubEquipments,
  setCandidateSubEquipments,
} from 'client/actions/equipments';
import { getArrayMutators } from 'client/libraries/util/form';
import { SubEquipmentSortModal } from 'client/components/Seat/SubEquipmentSortModal';
import { Checkbox, MultiSelect, Button } from 'client/components/Form';
import {
  updateEquipmentInstance,
  fetchEquipmentInstances,
} from 'client/actions/equipmentInstances';
import { EquipmentInstance } from 'shared/models/swagger';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';

interface FormValues {
  closeOriginalEquipment: boolean;
  additionalEquipmentIds: string[];
}

const getInitialValues = (
  equipmentInstance: EquipmentInstance | undefined
): FormValues => {
  return {
    closeOriginalEquipment:
      equipmentInstance?.additional_equipment_settings
        ?.close_original_equipment ?? false,
    additionalEquipmentIds:
      equipmentInstance?.additional_equipment_settings
        ?.additional_equipment_ids ?? [],
  };
};

const convertToSwagger = (values: FormValues): EquipmentInstance => {
  return {
    additional_equipment_settings: {
      close_original_equipment: values.closeOriginalEquipment,
      additional_equipment_ids: values.additionalEquipmentIds,
    },
  };
};

interface EditEquipmentInstanceModalProps {
  open: boolean;
  onClose: () => void;
  equipmentInstance: EquipmentInstance | undefined;
}

export const EditEquipmentInstanceModal = (
  props: EditEquipmentInstanceModalProps
) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const equipments = useSelector(equipmentsSelector);

  const sortedSubEquipments = useSelector(
    (state: ReduxState) => state.equipments.sortedSubEquipments
  );
  const candidateSubEquipments = useSelector(
    (state: ReduxState) => state.equipments.candidateSubEquipments
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [showSubEquipmentSortModal, setShowSubEquipmentSortModal] =
    React.useState<boolean>(false);

  const equipmentOptions = sortedSubEquipments
    .filter((id) => id !== props.equipmentInstance?.equipment_id)
    .map((id) => {
      const target = (equipments ?? []).find((e) => id === e.id);
      return {
        text: target?.title ?? '',
        value: target?.id ?? '',
      };
    });

  React.useEffect(() => {
    dispatch(fetchEquipments());
  }, []);
  React.useEffect(() => {
    const isSameSubCandidate = equipments.every((e) =>
      candidateSubEquipments.find((c) => e.id === c.value)
    );
    if (!isSameSubCandidate) {
      dispatch(
        setCandidateSubEquipments(
          equipments.map((e) => ({
            text: e.title || '',
            value: e.id || '',
          }))
        )
      );
      const subEquipments = equipments
        .filter((e) => e?.id !== props.equipmentInstance?.equipment_id)
        .map((e) => e.id || '');
      dispatch(setSortSubEquipments(subEquipments));
    }
  }, [equipments, props.equipmentInstance]);

  return (
    <Modal
      title={t('Change/Add Resources')}
      open={props.open}
      onClose={props.onClose}
      insertRoot
    >
      <Form
        onSubmit={async (value: FormValues) => {
          const startDate = props.equipmentInstance?.date;
          const endDate = moment
            .tz(startDate, activeUserOrganization?.default_timezone ?? 'UTC')
            .add(1, 'day')
            .format('YYYY-MM-DD');

          try {
            await dispatch(
              updateEquipmentInstance({
                ...props.equipmentInstance,
                ...convertToSwagger(value),
              })
            );
            await dispatch(
              fetchEquipmentInstances(
                '',
                props.equipmentInstance?.equipment_id ?? '',
                startDate,
                endDate
              )
            );
          } catch (e) {
            console.log(e);
          }
          props.onClose();
        }}
        initialValues={getInitialValues(props.equipmentInstance)}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="closeOriginalEquipment" type="checkbox">
                  {({ input }) => (
                    <Checkbox
                      {...(input as any)}
                      label={t('Close original resource')}
                    />
                  )}
                </Field>
              </Modal.Box>
              <Modal.Box>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'end',
                  }}
                >
                  <Field name="additionalEquipmentIds">
                    {({ input }) => (
                      <MultiSelect
                        label={t('Add other resources')}
                        options={equipmentOptions}
                        selectedValues={(() => {
                          if (typeof input.value === 'object') {
                            let deleteCandidate = false;
                            input.value.forEach((id: string) => {
                              if (
                                !equipmentOptions.find((e) => e.value === id)
                              ) {
                                deleteCandidate = true;
                              }
                            });
                            if (deleteCandidate) {
                              const newSelectedValue = input.value.filter(
                                (id: string) =>
                                  equipmentOptions.find((e) => e.value === id)
                              );
                              input.onChange(newSelectedValue);
                              return newSelectedValue;
                            } else {
                              return input.value;
                            }
                          } else {
                            return [];
                          }
                        })()}
                        onChange={({ value }) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                  {config.enableEquipmentMemoAndSortSubEquipment && (
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['square'],
                        baseStyles['gray']
                      )}
                      onClick={() => setShowSubEquipmentSortModal(true)}
                      style={{ marginLeft: '10px' }}
                    >
                      <img src={thIcon} />
                    </a>
                  )}
                  {showSubEquipmentSortModal && (
                    <SubEquipmentSortModal
                      onClose={setShowSubEquipmentSortModal}
                      equipmentInstance={props?.equipmentInstance}
                    />
                  )}
                </div>
              </Modal.Box>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
