import React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';

import {
  DateTimeInput,
  FieldWrapper,
  Select,
  Input,
  TextArea,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import type { TranslateFuncType } from 'client/components/Translate';
import { LabelWithHelpText } from 'client/components/LabelWithHelpText';
import { ReduxState } from 'client/reducers';

import 'react-phone-input-2/lib/style.css';
import { FileUploadInput } from './FileUploadInput/FileUploadInput';
import { TimePicker } from './TimePicker/TimePicker';

const getFormFieldHelpText = (format: string, t: TranslateFuncType): string => {
  if (format === 'alpha-name') {
    return t('Alphabet characters or spaces');
  } else if (format === 'non-negative-integer') {
    return t('Positive numeric');
  } else if (format === 'float') {
    return t('Numeric');
  } else if (format === 'yyyy-mm-dd') {
    return 'YYYY-MM-DD';
  }

  return '';
};

export const FormFieldV2 = (props: {
  prompt: string;
  onChange: (arg0: string) => void;
  value: string;
  options?: {
    text: string;
    value: string;
    key: string;
  }[];
  error?: string;
  format?: string;
  required?: boolean;
  disabled?: boolean;
  search?: boolean;
  allowFreeInput?: boolean;
  autoComplete?: 'on' | 'off';
  placeholder?: string;
  onBlur?: () => void;
  onKeyUp?: (e: any) => void;
  textArea?: boolean;
  height?: number;
}) => {
  const {
    prompt,
    onChange,
    options,
    error,
    format,
    required,
    disabled,
    search,
    allowFreeInput,
    placeholder,
    textArea,
    ...rest
  } = props;
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  if (format === 'yyyy-mm-dd') {
    return (
      <DateTimeInput
        label={prompt}
        value={props.value ? moment(props.value) : null}
        onChange={(value) =>
          value ? onChange(value.format('YYYY-MM-DD')) : onChange('')
        }
      />
    );
  }

  if (format === 'datetime-local') {
    return (
      <Box display="flex" gap={1} alignItems="flex-end">
        <DateTimeInput
          label={prompt}
          value={props.value ? moment(props.value) : null}
          onChange={(value) =>
            value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
          }
        />
        <Box width="150px">
          <TimePicker
            value={props.value ? moment(props.value) : undefined}
            onChange={(value) =>
              value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
            }
          />
        </Box>
      </Box>
    );
  }

  if (format === 'friendly_phone') {
    return (
      <FieldWrapper label={prompt} required={required} error={error}>
        <PhoneInput
          placeholder={locale === 'ja' ? '81 9012345678' : '1 800 123 4567'}
          masks={{ jp: '...........' }}
          preferredCountries={locale === 'ja' ? ['jp', 'us'] : ['us', 'jp']}
          localization={locale === 'ja' ? jpPhoneLocalization : undefined}
          value={props.value?.replace('+', '')}
          onChange={(value: string, data: { dialCode: string }) => {
            if (data && !value?.startsWith(data.dialCode)) {
              onChange(`+${data.dialCode}${value}`);
            } else if (value && !value?.startsWith('+')) {
              onChange(`+${value}`);
            } else {
              onChange(value);
            }
          }}
          searchPlaceholder={t('Search')}
          searchNotFound={t('No Results')}
          enableSearch
        />
      </FieldWrapper>
    );
  }

  if (format === 'file-upload') {
    return (
      <FieldWrapper label={prompt} required={required} error={error}>
        <FileUploadInput
          value={props.value ? JSON.parse(props.value) : []}
          onChange={(newValue) => props.onChange(JSON.stringify(newValue))}
          error={error}
        />
      </FieldWrapper>
    );
  }

  /* TODO: use radio buttons for select from < (N = 4?) options */
  return options && options.length > 0 ? (
    <Select
      {...rest}
      error={error}
      required={required ?? true ? true : required}
      name={prompt}
      label={prompt}
      onChange={(e, { value }) => onChange(value)}
      options={options}
      search={search}
      allowFreeInput={allowFreeInput}
      placeholder={placeholder || prompt}
      disabled={!!disabled}
    />
  ) : textArea ? (
    <TextArea
      {...rest}
      error={error}
      required={required ?? true ? true : required} // fluid /* Temp comment out, this is not ready at Input.js*/
      label={
        <LabelWithHelpText
          text={prompt}
          helpText={getFormFieldHelpText(format ?? '', t)}
        />
      }
      onChange={(e, { value }) => onChange(value)}
      disabled={!!disabled}
      maxHeight={180}
    />
  ) : (
    <Input
      {...rest}
      error={error}
      name={prompt}
      required={required ?? true ? true : required} // fluid /* Temp comment out, this is not ready at Input.js*/
      label={
        <LabelWithHelpText
          text={prompt}
          helpText={getFormFieldHelpText(format ?? '', t)}
        />
      }
      onChange={(e, { value }) => onChange(value)}
      placeholder={prompt}
      disabled={!!disabled}
    />
  );
};
