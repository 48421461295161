import * as React from 'react';

import { Input } from 'client/components/Form';
import { isFloat } from 'client/components/NewProductEditor/isFloat';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
};
export const PercentInput = ({ value, onChange }: Props) => {
  return (
    <div>
      <Input
        value={value}
        onChange={(e: any) => {
          if (!isFloat(e.target.value)) {
            return;
          }

          onChange(e.target.value);
        }}
      />
      <p>%</p>
    </div>
  );
};
