import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './MediaSalesGraphGadget.module.css';

export const OnSiteProductHourlySalesGraphGadget = () => {
  const { t } = useTranslation();

  const [graphDataType, setGraphDataType] = React.useState<string>('total');

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.onSiteReportData
  );

  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const targetProductOptions = React.useMemo(() => {
    if (!reportDataSets?.length) {
      return [];
    }

    const sortedProductIds = (
      reportDataSets[0].hourly_gross_sales_by_product || []
    )
      .map((product) => {
        const totalGrossSales =
          product.hourly_gross_sales?.reduce((total, sales) => {
            const grossSales = parseFloat(sales.gross_sales || '0');
            return total + grossSales;
          }, 0) || 0;

        return { product_id: product.product_id, totalGrossSales };
      })
      .sort((a, b) => b.totalGrossSales - a.totalGrossSales)
      .map((product) => product.product_id || '');

    const comparitionProductOptions = (
      reportDataSets[1]?.hourly_gross_sales_by_product || []
    )
      .filter((product) => !sortedProductIds.includes(product.product_id ?? ''))
      .map((product) => {
        const totalGrossSales =
          product.hourly_gross_sales?.reduce((total, sales) => {
            const grossSales = parseFloat(sales.gross_sales || '0');
            return total + grossSales;
          }, 0) || 0;
        return { product_id: product.product_id, totalGrossSales };
      })
      .sort((a, b) => b.totalGrossSales - a.totalGrossSales)
      .map((product) => product.product_id || '');

    return [...sortedProductIds, ...comparitionProductOptions].map(
      (productId) => {
        const productData = allProducts?.find((p) => p.id === productId);
        return {
          value: productId,
          text: productData?.internal_product_name ?? '',
        };
      }
    );
  }, [allProducts, reportDataSets]);

  const loading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );

  const data = React.useMemo(() => {
    if (!reportDataSets?.length) {
      return [];
    }

    const data: { hour: string; base: number; comp: number }[] = Array(24)
      .fill(0)
      .map((_, i) => i)
      .map((hour) => {
        return {
          hour: hour.toString().padStart(2, '0'),
          base: 0,
          comp: 0,
        };
      });

    reportDataSets[0].hourly_gross_sales_by_product
      ?.filter((product) => {
        if (graphDataType === 'total') {
          return true;
        }
        return product.product_id === graphDataType;
      })
      .map((product) => {
        product.hourly_gross_sales?.forEach((item) => {
          const hour = data.find((d) => d.hour === item.hour);
          if (hour && hour['base'] !== undefined) {
            hour['base'] += currency(item.gross_sales ?? '').value ?? 0;
          }
        });
      });

    reportDataSets[1]?.hourly_gross_sales_by_product
      ?.filter((product) => {
        if (graphDataType === 'total') {
          return true;
        }
        return product.product_id === graphDataType;
      })
      .map((product) => {
        product.hourly_gross_sales?.forEach((item) => {
          const hour = data.find((d) => d.hour === item.hour);
          if (hour && hour['comp'] !== undefined) {
            hour['comp'] += currency(item.gross_sales ?? '').value ?? 0;
          }
        });
      });

    return data.map((d) => {
      return {
        hour: d.hour,
        [t('Base')]: d.base,
        [t('Comparison')]: d.comp,
      };
    });
  }, [graphDataType, reportDataSets, t]);

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-inner']}>
          <div className={styles['header-text']}>{t('Sales per Hour')}</div>
          <div className={styles['header-select']}>
            <SingleDropdown
              label={t('Products')}
              options={[
                { value: 'total', text: t('Total') },
                ...targetProductOptions,
              ]}
              selectedOption={graphDataType}
              onChange={(value) => setGraphDataType(value)}
            />
          </div>
        </div>
      </div>
      <div className={styles['graph-container']}>
        {loading ? (
          <Loading size="md" />
        ) : (
          <ResponsiveContainer width="100%" height="100%" debounce={300}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hour" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Bar type="monotone" dataKey={t('Base')} stroke="#3B82F6" />
              {reportDataSets?.length > 1 && (
                <Bar
                  type="monotone"
                  dataKey={t('Comparison')}
                  stroke="#EC4899"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
