import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_RESTAURANT_ORDERS_SUCCESS,
  FETCH_RESTAURANT_ORDERS_FAILURE,
  FETCH_RESTAURANT_ORDERS_REQUEST,
  FETCH_RESTAURANT_ORDER_SUCCESS,
  FETCH_RESTAURANT_ORDER_FAILURE,
  FETCH_RESTAURANT_ORDER_REQUEST,
  DELETE_RESTAURANT_ORDER_FAILURE,
  DELETE_RESTAURANT_ORDER_REQUEST,
  DELETE_RESTAURANT_ORDER_SUCCESS,
  CREATE_RESTAURANT_ORDER_REQUEST,
  UPDATE_RESTAURANT_ORDER_FAILURE,
  UPDATE_RESTAURANT_ORDER_SUCCESS,
  UPDATE_RESTAURANT_ORDER_REQUEST,
  CREATE_RESTAURANT_ORDER_FAILURE,
  CREATE_RESTAURANT_ORDER_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { RestaurantOrderParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchRestaurantOrdersRequest = () => ({
  type: FETCH_RESTAURANT_ORDERS_REQUEST,
});

const fetchRestaurantOrdersSuccess = (response: any) => ({
  type: FETCH_RESTAURANT_ORDERS_SUCCESS,
  response,
});

const fetchRestaurantOrdersFailure = (error: any) => ({
  type: FETCH_RESTAURANT_ORDERS_FAILURE,
  error,
});

let fetchRestaurantOrdersCancel: () => void | undefined;
export const fetchRestaurantOrders =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchRestaurantOrdersCancel?.();
    dispatch(fetchRestaurantOrdersRequest());
    axios
      .get('/api/restaurantorders', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchRestaurantOrdersCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchRestaurantOrdersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRestaurantOrdersFailure('canceled'));
        dispatch(fetchRestaurantOrdersFailure(error));
      });
  };

const fetchRestaurantOrderRequest = () => ({
  type: FETCH_RESTAURANT_ORDER_REQUEST,
});

const fetchRestaurantOrderSuccess = (response: any) => ({
  type: FETCH_RESTAURANT_ORDER_SUCCESS,
  response,
});

const fetchRestaurantOrderFailure = (error: any) => ({
  type: FETCH_RESTAURANT_ORDER_FAILURE,
  error,
});

export const fetchRestaurantOrder =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchRestaurantOrderRequest());
    axios
      .get(`/api/restaurantorders/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchRestaurantOrderSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRestaurantOrderFailure('canceled'));
        else dispatch(fetchRestaurantOrderFailure(error));
      });
  };

const deleteRestaurantOrderRequest = (id: string) => ({
  type: DELETE_RESTAURANT_ORDER_REQUEST,
  id,
});

const deleteRestaurantOrderSuccess = (response: any, id: string) => ({
  type: DELETE_RESTAURANT_ORDER_SUCCESS,
  response,
  id,
});

const deleteRestaurantOrderFailure = (error: any) => ({
  type: DELETE_RESTAURANT_ORDER_FAILURE,
  error,
});

export const deleteRestaurantOrder =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteRestaurantOrderRequest(id));
    axios
      .delete(`/api/restaurantorders/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteRestaurantOrderSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteRestaurantOrderFailure(error)));
  };

const createRestaurantOrderRequest = (
  newRestaurantOrder: RestaurantOrderParams
) => ({
  type: CREATE_RESTAURANT_ORDER_REQUEST,
  newRestaurantOrder,
});

const createRestaurantOrderSuccess = (response: any) => ({
  type: CREATE_RESTAURANT_ORDER_SUCCESS,
  response,
});

const createRestaurantOrderFailure = (error: any) => ({
  type: CREATE_RESTAURANT_ORDER_FAILURE,
  error,
});

export const createRestaurantOrder =
  (newRestaurantOrder: RestaurantOrderParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createRestaurantOrderRequest(newRestaurantOrder));
    return axios
      .post('/api/restaurantorders', newRestaurantOrder, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createRestaurantOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createRestaurantOrderFailure(err.message));
      });
  };

const updateRestaurantOrderRequest = (
  id: string,
  patch: RestaurantOrderParams
) => ({
  type: UPDATE_RESTAURANT_ORDER_REQUEST,
  id,
  patch,
});

const updateRestaurantOrderSuccess = (response: any) => ({
  type: UPDATE_RESTAURANT_ORDER_SUCCESS,
  response,
});

const updateRestaurantOrderFailure = (error: any) => ({
  type: UPDATE_RESTAURANT_ORDER_FAILURE,
  error,
});

export const updateRestaurantOrder =
  (id: string, patch: RestaurantOrderParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateRestaurantOrderRequest(id, patch));
    return axios
      .patch(`/api/restaurantorders/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateRestaurantOrderSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateRestaurantOrderFailure(err.message));
      });
  };
