import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import type { Product, Product$Patch } from 'shared/models/swagger';

type FormValues = {
  area: {
    name: string;
    googlePlaceId: string;
  };
};

const getInitialValues = (product: Product | null): FormValues => {
  return {
    area: {
      name: product?.area_name ?? '',
      googlePlaceId: product?.area_google_place_id ?? '',
    },
  };
};

const convertFormValuesToProductPatch = (values: FormValues): Product$Patch => {
  return {
    area_name: values.area.name,
    area_google_place_id: values.area.googlePlaceId,
  };
};

type Props = {
  onClose: () => void;
};
export const LocationEditorModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Product Location')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field
        name="area"
        validate={(value: any | null | undefined): string => {
          if (!value?.name) return t('Required');
          return '';
        }}
      >
        {({ input, meta: { touched, error } }) => (
          <LocationSearchInput
            prompt={t('Location Name')}
            location={input.value.name}
            onSearchChange={(name) =>
              input.onChange({
                name,
                googlePlaceId: '',
              })
            }
            onLocationSelect={({ detail: name, key: googlePlaceId }) => {
              input.onChange({
                name,
                googlePlaceId,
              });
            }}
            error={touched && error}
            maxWidth={800}
          />
        )}
      </Field>
    </ProductEditorModal>
  );
};
