import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { MultiSelect, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserOrganizationSelector } from 'client/reducers/user';

interface Props {
  name: string;
}

export const CustomFieldFilterEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;
  const customFieldKey = _.get(values, `${name}.key`);

  const { required } = getValidators(t);

  const organization = useSelector(activeUserOrganizationSelector);

  // Flatten array
  const uniqueFields = _.uniqBy(
    _.flatten(
      organization?.customer_ledger_settings?.form_field_sets?.map((fieldSet) =>
        fieldSet.form_fields?.filter(
          (field) =>
            field.type === 'CUSTOM' && field.response_type === 'SELECT_ONE'
        )
      )
    ),
    (field) => field?.key
  );
  const fieldOptions = uniqueFields.map((field) => ({
    text: field?.field_name ?? '',
    value: field?.key ?? '',
  }));

  const valueOptions =
    uniqueFields
      .find((field) => field?.key === customFieldKey)
      ?.options?.map((option) => ({
        text: option.text ?? '',
        value: option.key ?? '',
      })) ?? [];

  return (
    <Box display="flex" mt={2}>
      <Field name={`${name}.key`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box width="100%">
            <div>{t('Field')}</div>
            <Select
              options={fieldOptions}
              value={input.value}
              search
              onChange={(e, { value }) => {
                input.onChange(value);
                form.change(`${name}.value`, []);
              }}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
      <Field name={`${name}.filterType`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box ml={1}>
            <div>{t('Condition')}</div>
            <Select
              width={200}
              options={[
                {
                  text: t('Any of'),
                  value: 'INCLUDE',
                },
                {
                  text: t('None of'),
                  value: 'EXCLUDE',
                },
              ]}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
      <Field name={`${name}.value`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box ml={1} width="100%">
            <div>{t('Value')}</div>
            <MultiSelect
              options={valueOptions}
              selectedValues={
                typeof input.value === 'object' ? input.value : []
              }
              search
              onChange={({ value }) => input.onChange(value)}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
    </Box>
  );
};
