import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment-timezone';

import type { ReduxState } from 'client/reducers';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { activeUserSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { operationAllowed } from 'shared/models/access';
import {
  productColumnCandidatesSelector,
  productVisibleColumnsSelector,
} from 'client/reducers/productTableControls';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { V3Page } from 'client/components/v3/Page/V3Page';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductList/ProductList.module.css';
import { ProductListHeader } from 'client/pages/v3/Product/ProductList/ProductListHeader/ProductListHeader';
import { ProductListTable } from 'client/pages/v3/Product/ProductList/ProductListTable/ProductListTable';
import { OperateButton } from 'client/pages/v3/Product/ProductList/OperateButton';
import {
  ColumnSettingsModal,
  ColumnType as ModalColumnType,
} from 'client/pages/v3/Product/ProductList/ColumnSettingsModal';
import { CustomTableColumn } from 'client/pages/v3/Product/ProductList/ProductListTable/CustomTable';

type ColumnType = CustomTableColumn &
  ModalColumnType & { filterable?: boolean };

export const ProductList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState<SalesStatus>('ON_SALE');
  const [inputText, setInputText] = React.useState<string>('');
  const [showColumnSettingsModal, setShowColumnSettingsModal] =
    React.useState<boolean>(false);
  const tableHeaderRef = React.useRef<HTMLDivElement>(null);
  const tableBodyRef = React.useRef<HTMLDivElement>(null);

  const activeUser = useSelector(activeUserSelector);
  const columnCandidates = useSelector(productColumnCandidatesSelector);
  const visibleColumns = useSelector(productVisibleColumnsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  React.useEffect(() => {
    setActiveTab('ON_SALE');
    setInputText('');
  }, []);

  const getAllColumns = (): ColumnType[] => {
    return [
      {
        Header: t('Operate'),
        id: 'delete/copy',
        accessor: (data) => data.product_name || '',
        Cell: (cellInfo) => (
          <OperateButton product={cellInfo.original} tableRef={tableBodyRef} />
        ),
        width: 'middle',
      },
      {
        Header: t('Name'),
        id: 'product_name',
        accessor: (data) => data.product_name || '',
        Cell: (cellInfo) => (
          <div className={styles['p-reservations__productNameLink']}>
            {cellInfo.original.bookmarked && (
              <i className="c-icon-solid-general-pin-02"></i>
            )}
            {/* TODO: remove v3 from url later */}
            <Link to={`/products-v3/${cellInfo.original.id}`}>
              {/* <Link to={`/products/${cellInfo.original.id}`}> */}
              {cellInfo.value}
            </Link>
          </div>
        ),
        width: 'longx2',
      },
      {
        Header: t('Internal Product Name'),
        id: 'internal_product_name',
        accessor: (data) => data.internal_product_name || '',
        Cell: (cellInfo) => (
          <Link to={`/products/${cellInfo.original.id}`}>{cellInfo.value}</Link>
        ),
        width: 'long',
      },

      {
        Header: t('Availability Calendar'),
        id: 'availability',
        Cell: (cellInfo) => {
          moment.locale(locale);

          if (
            !cellInfo.original.booking_widget_settings?.is_view_only ||
            (cellInfo.original.booking_widget_settings?.is_view_only &&
              operationAllowed(activeUser, 'write', 'productContents'))
          ) {
            return (
              <div className={styles['p-reservations__calendarLink']}>
                <i className="c-icon-outline-time-calendar"></i>
                <Link
                  // TODO: remove v3 from url later
                  to={{
                    pathname: `/reservations/new-v3`,
                    // Use state to persist to location
                    state: {
                      productId: cellInfo.original.id,
                      isFromProductDetailsPage: true,
                    },
                  }}
                >
                  <p>{t('See calendar')}</p>
                </Link>
              </div>
            );
          } else {
            return null;
          }
        },
        width: 'long',
      },
      {
        Header: t('Location'),
        id: 'area_name',
        width: 'middle',
        accessor: 'area_name',
      },
      {
        Header: t('Supplier'),
        id: 'supplier_name',
        width: 'middle',
        accessor: 'supplier_name',
      },
      {
        Header: t('Product Code'),
        id: 'supplier_reference',
        width: 'middle',
        accessor: 'supplier_reference',
      },
      {
        Header: '#',
        id: 'id',
        width: 'long',
        accessor: 'id',
      },
      {
        Header: t('Tags'),
        id: 'product_tags',
        width: 'long',
        accessor: (data) => (data.product_tags || []).join(','),
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: t('Internal Tags'),
        id: 'internal_product_tags',
        width: 'long',
        accessor: (data) => (data.internal_product_tags || []).join(','),
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
    ];
  };

  const allColumns = getAllColumns();

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask.map(
      (col) => allColumns.find((c) => c.id === col) as ColumnType
    );
  };

  const getColumnsWithEdit = (columnMasks: string[]): ColumnType[] => {
    return [...columnMasks]
      .map((col) => allColumns.find((c) => c.id === col) as ColumnType)
      .filter(Boolean);
  };

  const columnsToShow = getColumns(visibleColumns);
  const allCandidateColumns = getColumns(columnCandidates);
  const customTableColumns = getColumnsWithEdit(visibleColumns).map((col) => {
    return col;
  });

  return (
    <V3Page>
      <ProductListHeader />
      <div className={clsx(baseStyles['l-main__body'])}>
        <section className={styles['g-section']}>
          <div className={clsx(styles['p-reservations'])}>
            <div className={styles['p-reservations__header']}>
              <ul className={styles['p-reservations__tab']}>
                <li
                  className={clsx(
                    activeTab === 'ON_SALE' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('ON_SALE');
                  }}
                >
                  <a>{t('On Sale')}</a>
                </li>
                <li
                  className={clsx(
                    activeTab === 'LIST_ONLY' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('LIST_ONLY');
                  }}
                >
                  <a>{t('Not On Sale')}</a>
                </li>
                <li
                  className={clsx(
                    activeTab === 'NOT_LISTED' ? styles['is-active'] : ''
                  )}
                  onClick={() => {
                    setActiveTab('NOT_LISTED');
                  }}
                >
                  <a>{t('Not Listed')}</a>
                </li>
              </ul>
              <div className={styles['p-reservations__actions']}>
                <Button
                  text={t('Edit columns')}
                  uiType="bg"
                  size="md"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-layout-columns-03"></i>
                  }
                  onClick={() => setShowColumnSettingsModal(true)}
                />
                <TextField
                  value={inputText}
                  onChange={(e) => setInputText(e)}
                  placeholder={t('Search')}
                />
              </div>
            </div>

            <ProductListTable
              filterText={inputText}
              columns={customTableColumns}
              activeTab={activeTab}
              tableHeaderRef={tableHeaderRef}
              tableBodyRef={tableBodyRef}
            />
          </div>
        </section>
        {showColumnSettingsModal && (
          <ColumnSettingsModal
            columnsToShow={columnsToShow}
            allCandidateColumns={allCandidateColumns}
            onClose={() => setShowColumnSettingsModal(false)}
          />
        )}
      </div>
    </V3Page>
  );
};
