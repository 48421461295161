// @flow

import { currency } from 'shared/libraries/currency';

export const calculateResellNet = (gross: string, net: string): string => {
  const netValue = currency(net);
  const grossValue = currency(gross);
  const resellNet = netValue.add(grossValue.multiply(0.05));

  if (resellNet.value > grossValue.value) {
    return netValue.format();
  }

  return resellNet.format();
};
