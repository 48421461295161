import { Field, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useContext } from 'react';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import { DeadlineInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/DeadlineInput';
import { CancellationPolicyTimeline } from 'client/components/CancellationPolicyTimeline/CancellationPolicyTimeline';
import {
  compareRelativeDeadlines,
  CancellationPolicy,
} from 'client/libraries/util/productShape';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';

export const CancellationPoliciesInput = () => {
  const field = useField('cancellationPolicies');
  const { t } = useTranslation();
  const cancellationPolicies: CancellationPolicy[] = field.input.value ?? [];
  cancellationPolicies.sort(compareRelativeDeadlines);
  const editingProduct = useContext(EditingProductContext);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const mql = window.matchMedia('(max-width: 1024px)');

  const hasAnytimePolicy = cancellationPolicies.some(
    (policy) => policy?.deadlineType === 'ETERNAL'
  );
  return (
    <>
      <ul className={styles['p-limit']}>
        <CancellationPolicyTimeline
          cancellationPolicies={cancellationPolicies}
          currency={defaultCurrency}
        />
      </ul>
      <div className={clsx(styles['p-box'], styles['padding-0'])}>
        <div className={styles['p-box__table2']}>
          <div
            className={clsx(
              styles['p-box__table2__header'],
              baseStyles['u-spHidden']
            )}
          >
            <p className={baseStyles['u-width-296']}>{t('Deadline')}</p>
            <p className={baseStyles['u-width-208']}>{t('Cancellation Fee')}</p>
            <p></p>
          </div>
          <FieldArray name="cancellationPolicies">
            {({ fields }) => (
              <>
                {fields.map((name, idx) => {
                  return (
                    <div key={idx} className={styles['p-box__table2__body']}>
                      <div
                        className={clsx(
                          styles['p-box__table2__body__item'],
                          styles['flex']
                        )}
                      >
                        <div
                          data-title={t('Deadline')}
                          className={clsx(
                            baseStyles['u-width-296'],
                            baseStyles['u-pr-4']
                          )}
                        >
                          <p
                            className={clsx(
                              styles['p-box__table2__body__item__ttl'],
                              baseStyles['u-pcHidden']
                            )}
                          >
                            {t('Deadline')}
                          </p>
                          <DeadlineInput
                            allowAnytimeDeadline={true}
                            name={name}
                          />
                        </div>
                        <Field name={name}>
                          {({ input: cancellationPolicyInput }) => (
                            <div
                              data-title={t('Cancellation Fee')}
                              className={clsx(
                                baseStyles['u-width-208'],
                                mql.matches ? baseStyles['u-mt-4'] : ''
                              )}
                            >
                              <p
                                className={clsx(
                                  styles['p-box__table2__body__item__ttl'],
                                  baseStyles['u-pcHidden']
                                )}
                              >
                                {t('Cancellation Fee')}
                              </p>
                              <div className={styles['p-deadline__cancelFee']}>
                                {cancellationPolicyInput.value.feeType ===
                                  'PERCENT' && (
                                  <Field name={`${name}.feePercent`}>
                                    {({ input }) => (
                                      <TextField
                                        value={input.value}
                                        onChange={(value) => {
                                          if (!isInteger(value)) {
                                            return;
                                          }

                                          input.onChange(value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                )}
                                {cancellationPolicyInput.value.feeType ===
                                  'FIXED' && (
                                  <Field name={`${name}.feeFixed`}>
                                    {({ input }) => (
                                      <TextField
                                        value={input.value}
                                        onChange={(value) => {
                                          if (
                                            !currencyInputAllowed(
                                              defaultCurrency,
                                              value
                                            )
                                          ) {
                                            return;
                                          }

                                          input.onChange(value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                )}
                                <Field name={`${name}.feeType`}>
                                  {({ input }) => (
                                    <SingleDropdown
                                      options={[
                                        {
                                          text: defaultCurrency,
                                          value: 'FIXED',
                                        },
                                        {
                                          text: '%',
                                          value: 'PERCENT',
                                        },
                                      ]}
                                      selectedOption={input.value}
                                      onChange={(value) => {
                                        if (value === 'FIXED') {
                                          cancellationPolicyInput.onChange({
                                            ...cancellationPolicyInput.value,
                                            feeType: 'FIXED',
                                            feeFixed: '0',
                                          });
                                        } else {
                                          cancellationPolicyInput.onChange({
                                            ...cancellationPolicyInput.value,
                                            feeType: 'PERCENT',
                                            feePercent: '50',
                                          });
                                        }
                                      }}
                                      width={mql.matches ? 100 : 80}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          )}
                        </Field>
                        <div
                          className={mql.matches ? baseStyles['u-mt-4'] : ''}
                        >
                          <Button
                            size="icon"
                            color="tertiarygray"
                            onClick={() => fields.remove(idx)}
                            iconBeforeText={
                              <i className="c-icon-outline-general-trash-03"></i>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={styles['p-box__table2__actions']}>
                  <a
                    className={styles['p-box__table2__actions__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, {
                          deadlineType: 'DAY',
                          daysBefore: 1,
                          timeOfDay: '17:00',
                          feeType: 'PERCENT',
                          feePercent: '50',
                        });
                      } else {
                        (fields as any).insertAt(0, {
                          deadlineType: 'DAY',
                          daysBefore: 1,
                          timeOfDay: '17:00',
                          feeType: 'PERCENT',
                          feePercent: '50',
                        });
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {t('Add Cancellation Policy')}
                  </a>
                </div>
              </>
            )}
          </FieldArray>
        </div>
      </div>
      {!hasAnytimePolicy && (
        <p className={styles['p-cancelpolicy']}>
          {t('After the above, 100% cancellation fee')}
        </p>
      )}
    </>
  );
};
