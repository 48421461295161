import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import clsx from 'clsx';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import { getSalesStatusText } from 'client/libraries/util/getProductSalesStatus';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';

const itemType = 'selectable-item';
interface Option {
  text: string;
  key: string;
  status: SalesStatus;
}
export interface ListProps {
  options: Option[];
  moveItem: (arg0: Option[]) => void;
}
export interface ItemProps {
  title: string;
  status: SalesStatus;
  value: string;
  index: number;
  //onValueChange: (arg0: string) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const ProductDraggableList = ({ options, moveItem }: ListProps) => {
  const changeItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = options[dragIndex];
    const newSelectedItems = [...options];
    newSelectedItems.splice(dragIndex, 1);
    newSelectedItems.splice(hoverIndex, 0, dragItem);
    moveItem(newSelectedItems);
  };
  return (
    <div className={styles['p-topPage-box']}>
      <ul className={styles['p-topPage-sortList']}>
        {options.map((p, idx) => (
          <>
            <ProductDraggableItem
              title={p.text}
              value={p.key}
              index={idx}
              status={p.status}
              moveItem={changeItem}
            />
          </>
        ))}
      </ul>
    </div>
  );
};

export const ProductDraggableItem = ({
  title,
  index,
  status,
  moveItem,
  value,
}: ItemProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLLIElement | null>(null);
  const [, drop] = useDrop<DragItem>({
    accept: itemType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: value,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li
      ref={ref}
      className={styles['p-topPage-sortList__item']}
      style={{
        opacity,
      }}
    >
      <div className={styles['p-topPage-sortList__item__left']}>
        <div className={styles['p-topPage-sortList__item__ic']}>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <p>{title}</p>
      </div>
      <div className={styles['p-topPage-sortList__item__right']}>
        <span
          className={clsx(
            styles['p-topPage-badges'],
            styles[`p-topPage-badges--${getBadgeColor(status)}`]
          )}
        >
          {getSalesStatusText(status as SalesStatus, t)}
        </span>
      </div>
    </li>
  );
};

const getBadgeColor = (status: SalesStatus) => {
  switch (status) {
    case 'ON_SALE':
      return 'blue';
    case 'LIST_ONLY':
      return 'orange';
    case 'NOT_LISTED':
      return 'gray';
    default:
      return 'gray';
    //green
  }
};
