import { combineReducers } from 'redux';
//import { createSelector } from 'reselect';
import type { Action } from 'redux';

import {
  FETCH_ACTIM_RESERVATION_BY_ID_FAILURE,
  FETCH_ACTIM_RESERVATION_BY_ID_REQUEST,
  FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS,
  FETCH_ACTIM_RESERVATIONS_CANCELED,
  FETCH_ACTIM_RESERVATIONS_FAILURE,
  FETCH_ACTIM_RESERVATIONS_REQUEST,
  FETCH_ACTIM_RESERVATIONS_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  UPDATE_ACTIM_RESERVATION_FAILURE,
  UPDATE_ACTIM_RESERVATION_REQUEST,
  UPDATE_ACTIM_RESERVATION_SUCCESS,
  DELETE_ACTIM_RESERVATION_FAILURE,
  DELETE_ACTIM_RESERVATION_REQUEST,
  DELETE_ACTIM_RESERVATION_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GetActimReservationResponse,
  ListActimReservationsResponse,
  ActimReservation,
} from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    // These actions always trigger the loading screen
    case UPDATE_ACTIM_RESERVATION_REQUEST:
    case FETCH_ACTIM_RESERVATIONS_REQUEST:
    case FETCH_ACTIM_RESERVATION_BY_ID_REQUEST:
    case DELETE_ACTIM_RESERVATION_REQUEST:
      return true;

    // These actions always remove the loading screen
    case UPDATE_ACTIM_RESERVATION_SUCCESS:
    case FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS:
    case UPDATE_ACTIM_RESERVATION_FAILURE:
    case FETCH_ACTIM_RESERVATION_BY_ID_FAILURE:
    case DELETE_ACTIM_RESERVATION_SUCCESS:
    case DELETE_ACTIM_RESERVATION_FAILURE:
    case FETCH_ACTIM_RESERVATIONS_SUCCESS:
    case FETCH_ACTIM_RESERVATIONS_FAILURE:
    case FETCH_ACTIM_RESERVATIONS_CANCELED:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case UPDATE_ACTIM_RESERVATION_FAILURE:
    case FETCH_ACTIM_RESERVATIONS_FAILURE:
    case FETCH_ACTIM_RESERVATION_BY_ID_FAILURE:
    case DELETE_ACTIM_RESERVATION_FAILURE:
      return action.error;

    case FETCH_ACTIM_RESERVATIONS_SUCCESS:
    case FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS:
    case UPDATE_ACTIM_RESERVATION_SUCCESS:
    case DELETE_ACTIM_RESERVATION_SUCCESS:
      return '';

    default:
      return state;
  }
};

const byID = (state: Record<string, ActimReservation> = {}, action: any) => {
  switch (action.type) {
    case FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS: {
      const responses = action.responses as any as GetActimReservationResponse;
      const actimReservationsByID: Record<string, ActimReservation> = {};
      actimReservationsByID[responses.id] = responses;
      //responses.forEach((r) => {
      //  actimReservationsByID[r.id] = r;
      //});
      return { ...state, ...actimReservationsByID };
    }

    case UPDATE_ACTIM_RESERVATION_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    case DELETE_ACTIM_RESERVATION_SUCCESS: {
      const result = { ...state };
      delete result[action.id];
      return result;
    }
    default:
      return state;
  }
};

const all = (
  state: ActimReservation[] = [],
  action: any
): Array<ActimReservation> => {
  switch (action.type) {
    case FETCH_ACTIM_RESERVATIONS_SUCCESS: {
      const resp = action.response as any as ListActimReservationsResponse;
      return resp.actimReservations || [];
    }
    case DELETE_ACTIM_RESERVATION_SUCCESS:
      return state.filter((i) => i.id !== action.id);

    default:
      return state;
  }
};

const reducer = combineReducers({
  error,
  byID,
  loading,
  all,
});

export const actimReservations = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
