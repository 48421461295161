import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { useBounds } from 'client/hooks/useBounds';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import v3Styles from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationCreateDetails.module.css';

import styles from './TruncatedLabel.module.css';

interface Props {
  text: string;
  wrapperClassName?: string;
}

export const TruncatedLabel = ({ text, wrapperClassName = '' }: Props) => {
  const [divRef] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowTooltip(false);
    };
    window.document.addEventListener('scroll', handleScroll, { capture: true });
    return () => {
      window.document.removeEventListener('scroll', handleScroll, {
        capture: true,
      });
    };
  }, []);

  return (
    <p
      onMouseEnter={() => {
        const scrollHeight = divRef.current?.scrollHeight ?? 0;
        const clientHeight = divRef.current?.clientHeight ?? 0;
        if (scrollHeight > clientHeight) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => setShowTooltip(false)}
      ref={divRef}
      className={(clsx(styles['label']), v3Styles[wrapperClassName])}
    >
      {text}
      {showTooltip && (
        <Tooltip text={text}>
          <i className="c-icon-outline-general-info-circle"></i>
        </Tooltip>
      )}
    </p>
  );
};
