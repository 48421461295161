import type { TranslateFuncType } from 'client/components/Translate';
import { BadgeColor } from 'client/components/v3/Common/Badge';
import type {
  ReviewAttribution,
  ReviewRating,
  ReviewStatus,
  Review,
} from 'shared/models/swagger';

export function convertReviewRatingToNumber(
  reviewRating: ReviewRating
): number {
  return parseInt(reviewRating.replace('REVIEW_RATING_', ''));
}
export function filterReviews(
  reviews: Review[],
  searchFormValues: SearchFormValues
): Review[] {
  return reviews.filter((review) => {
    if (
      searchFormValues.ratings != null &&
      searchFormValues.ratings.length > 0
    ) {
      if (!searchFormValues.ratings.includes(review.rating)) {
        return false;
      }
    }

    if (
      searchFormValues.attributions != null &&
      searchFormValues.attributions.length > 0
    ) {
      if (!searchFormValues.attributions.includes(review.attribution)) {
        return false;
      }
    }

    if (
      searchFormValues.statuses != null &&
      searchFormValues.statuses.length > 0
    ) {
      if (!searchFormValues.statuses.includes(review.status)) {
        return false;
      }
    }

    if (
      searchFormValues.productIds != null &&
      searchFormValues.productIds.length > 0
    ) {
      if (!searchFormValues.productIds.includes(review.product_id)) {
        return false;
      }
    }

    const groups = review.participation_date_time_local.match(/\d{4}-(\d{2})/);

    if (!groups || groups.length < 2) {
      return false;
    }

    const participationMonth = parseInt(groups[1]);

    if (
      searchFormValues.monthStart <= searchFormValues.monthEnd &&
      (participationMonth < searchFormValues.monthStart ||
        searchFormValues.monthEnd < participationMonth)
    ) {
      return false;
    }

    if (
      searchFormValues.monthStart > searchFormValues.monthEnd &&
      participationMonth < searchFormValues.monthStart &&
      searchFormValues.monthEnd < participationMonth
    ) {
      return false;
    }

    if (
      searchFormValues.language != null &&
      review.guest_language !== searchFormValues.language
    ) {
      return false;
    }

    return true;
  });
}
export const getReviewStatusColor = (status: ReviewStatus): string => {
  switch (status) {
    case 'PENDING':
      return '#ffcc00';

    case 'PUBLISHED':
      return '#0094cc';

    case 'DECLINED':
      return '#dc3e15';
  }
};
export const getReviewStatusBadgeColor = (status: ReviewStatus): BadgeColor => {
  switch (status) {
    case 'PENDING':
      return 'warning';

    case 'PUBLISHED':
      return 'primary';

    case 'DECLINED':
      return 'error';
  }
};
export const getReviewStatusText = (
  status: ReviewStatus,
  t: TranslateFuncType
): string => {
  switch (status) {
    case 'PENDING':
      return t('Pending');

    case 'PUBLISHED':
      return t('Published');

    case 'DECLINED':
      return t('Rejected');
  }
};
export const sortReviews = (reviews: Review[], language: string): Review[] => {
  const apiLanguage = convertToApiLanguage(language);
  return [
    ...reviews.filter((review) => review.guest_language === apiLanguage),
    ...reviews.filter((review) => review.guest_language !== apiLanguage),
  ];
};

const convertToApiLanguage = (language: string) => {
  if (language.includes('en')) {
    return 'EN_US';
  }

  if (language.includes('ko')) {
    return 'KO_KR';
  }

  return 'JA_JP';
};

export type SearchFormValues = {
  ratings: ReviewRating[];
  attributions: ReviewAttribution[];
  monthStart: number;
  monthEnd: number;
  language: string | undefined;
  productIds: string[];
  statuses: ReviewStatus[];
};
