// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Linkify from 'react-linkify';

import type { Reservation, Product, Account } from 'shared/models/swagger';
import type { LanguageISO } from 'shared/libraries/i18n';
import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { ReservationFieldResponseUpdateModal } from 'client/pages/ReservationDetails/ReservationFieldResponseUpdateModal';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { operationAllowed } from 'shared/models/access';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupHeader,
  TutorialPopupDescription,
} from 'client/components/TutorialPopup/TutorialPopup';
import { Button } from 'client/components/Form';
import { sendPaymentEmail } from 'client/actions/reservations';
import editIcon from 'client/images/ic_edit.svg';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import baseStyles from 'client/base.module.css';
import type { ReduxState } from 'client/reducers/index';
import { Message } from 'client/components/Message/Message';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { UploadedImageThumbnail } from 'client/components/UploadedImageThumbnail/UploadedImageThumbnail';
import { FileDownloadList } from './FileDownloadList';

type Props = {
  reservation: Reservation,
  product: Product,
  locale: LanguageISO,
  activeUser: Account | null,
  readOnly?: boolean,
};

export const ReservationRelatedInformation = React.forwardRef<Props, any>(
  (props, ref) => {
    const { reservation, product, activeUser, readOnly } = props;

    if (!reservation || !product) {
      return null;
    }

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const keyTitles = {};
    product.reservation_form_fields?.forEach((field) => {
      if (field.key) {
        keyTitles[field.key] = field.prompt;
      }
    });

    const excludedFormFieldKeys = [
      'family_name',
      'given_name',
      'kana_family_name',
      'kana_given_name',
      'full_name',
      'email',
      'preferred_language_iso2',
      'booking_language',
      'hotel_information',
      'representative_name',
      'hotel_tbd_form',
      'consent_form',
      'customer_id',
    ];

    const builtinFormFieldsByKey = getCommonFormFieldsByKey(t);
    const productReservationFormFields = [
      ...(product.reservation_form_fields || [])
        .filter((field) => {
          return (
            !excludedFormFieldKeys.includes(field.key) &&
            field.type === 'PER_BOOKING'
          );
        })
        .map((field) => ({
          key: field.key,
          prompt: field.prompt,
          format: field.format,
        })),
    ];
    const unmappedFormFieldKeys = [
      ...new Set(
        reservation?.field_responses
          ?.map((fieldResponse) => fieldResponse.key)
          .filter(
            (key) =>
              !productReservationFormFields.some(
                (formField) => formField.key === key
              )
          )
      ),
    ];
    const unregisteredBuiltinFormFields = unmappedFormFieldKeys
      .filter(
        (key) =>
          builtinFormFieldsByKey[key ?? ''] &&
          !excludedFormFieldKeys.includes(key)
      )
      .map((key) => ({
        key,
        prompt: builtinFormFieldsByKey[key ?? ''].text,
      }));
    const unregisteredUnknownFormFields = unmappedFormFieldKeys
      .filter(
        (key) =>
          !builtinFormFieldsByKey[key ?? ''] &&
          !excludedFormFieldKeys.includes(key)
      )
      .map((key) => ({
        key,
        prompt: key,
      }));
    const formFields = [
      ...productReservationFormFields,
      ...unregisteredBuiltinFormFields,
      ...unregisteredUnknownFormFields,
    ];

    const currentStatus = getCurrentStatus(reservation);

    const userIsPassthroughOrg =
      (reservation.agent_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'AGENT') ||
      (reservation.supplier_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'SUPPLIER');

    const userCanEditFormFieldResponses =
      !userIsPassthroughOrg &&
      !readOnly &&
      !isTerminalReservationStatus(currentStatus) &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');

    const numOfFieldResponse = formFields.length;

    const paymentDeferredAvailable =
      reservation.payment_deferred &&
      reservation.billing_info &&
      (reservation.billing_info.payment_deferred_available ||
        reservation.billing_info.invoice_status === 'INVOICE_IN_PROGRESS' ||
        reservation.billing_info.invoice_status === 'INVOICE_CHARGE_PENDING');

    const userEmail =
      reservation?.field_responses?.find((response) => response.key === 'email')
        ?.response || '';
    const userLanguage =
      reservation?.field_responses?.find(
        (response) => response.key === 'preferred_language_iso2'
      )?.response || '';
    const consentForm =
      reservation?.field_responses?.find(
        (response) => response.key === 'consent_form'
      )?.response || '';
    const consentFormPrompt =
      product.reservation_form_fields?.find(
        (field) => field.key === 'consent_form'
      )?.prompt || '';

    const sending = useSelector(
      (state: ReduxState) => state.reservations.paymentEmailSending
    );

    const [sendingStatus, setSendingStatus] = React.useState<
      'REQUIRED' | 'SUCCEEDED' | 'FAILED'
    >('REQUIRED');

    const componentDecorator = (href: string, text: string, key: number) => (
      <a
        key={key}
        href={href}
        style={{ color: 'blue' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );

    return (
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          baseStyles['scroll-target-pane']
        )}
        id="related"
        ref={ref}
      >
        <TutorialPopup
          name="related"
          content={
            <TutorialPopupContent>
              <TutorialPopupHeader text={t('Related Information')} />
              <TutorialPopupDescription
                text={t(
                  'The information set in "Per-Booking" on the applicant\'s reservation form will be displayed. Please check here for contact information and stay information.'
                )}
              />
            </TutorialPopupContent>
          }
        />
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Related Information')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            {userCanEditFormFieldResponses && (
              <ReservationFieldResponseUpdateModal
                reservationID={reservation.id}
                size="big"
                trigger={
                  <a
                    className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  >
                    <img src={editIcon} />
                  </a>
                }
              />
            )}
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <table className={clsx(baseStyles['base-table'])}>
            <tbody>
              {formFields.map((field, idx) => {
                const response = reservation.field_responses?.find(
                  (response) => response.key === field.key
                );
                return (
                  <tr key={idx}>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      {field.prompt}
                    </th>
                    <td className="newline" style={{ height: 'auto' }}>
                      {field.key === 'phone' ||
                      field.key === 'international_phone' ? (
                        formattedPhone(response?.response ?? '')
                      ) : field.format === 'file-upload' ? (
                        <FileDownloadList
                          fileUrlString={response?.response ?? ''}
                        />
                      ) : field.format === 'image-upload' ? (
                        <UploadedImageThumbnail
                          fileUrlString={response?.response ?? ''}
                        />
                      ) : (
                        <TruncatedTextWithSeeMoreButton
                          text={response?.response ?? ''}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
              {userEmail && (
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Email')}
                  </th>
                  <td>{userEmail}</td>
                </tr>
              )}
              {userLanguage && (
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Language')}
                  </th>
                  <td>{userLanguage}</td>
                </tr>
              )}
              {reservation.promo_code && (
                <tr key={numOfFieldResponse}>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Promo Code')}
                  </th>
                  <td>{reservation.promo_code}</td>
                </tr>
              )}
              {consentForm && (
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    <Linkify componentDecorator={componentDecorator}>
                      {consentFormPrompt}
                    </Linkify>
                  </th>
                  <td>{t(consentForm)}</td>
                </tr>
              )}
              {operationAllowed(
                activeUser,
                'write',
                'reservationPaymentMethod'
              ) &&
                reservation.payment_deferred && (
                  <tr key={numOfFieldResponse + 1}>
                    <th className={clsx(baseStyles['base-t-160'])}>
                      <a
                        className={clsx(baseStyles['payment-link'])}
                        href={reservation.payment_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          className={clsx(
                            baseStyles['base-btn'],
                            baseStyles['small'],
                            baseStyles['blue']
                          )}
                          target="_blank"
                        >
                          <p
                            className={clsx(
                              baseStyles['semantic-ui-react-override']
                            )}
                          >
                            {t('Payment link')}
                          </p>
                          <img src={newWindowIcon} />
                        </span>
                      </a>
                    </th>
                    <td>
                      <div
                        className={clsx(
                          baseStyles['reservation-detail-email-payment']
                        )}
                      >
                        {reservation.email_payment_to_address}
                        <div className={clsx(baseStyles['btn'])}>
                          <Button
                            style="green"
                            size="middle"
                            disabled={
                              !(
                                reservation.status === 'CONFIRMED' &&
                                reservation.email_payment_to_address &&
                                paymentDeferredAvailable
                              )
                            }
                            onClick={async () => {
                              if (
                                reservation.id &&
                                reservation.email_payment_to_address
                              ) {
                                try {
                                  setSendingStatus('REQUIRED');
                                  await dispatch(
                                    sendPaymentEmail(
                                      reservation.id,
                                      reservation.email_payment_to_address || ''
                                    )
                                  );
                                  setSendingStatus('SUCCEEDED');
                                } catch (e) {
                                  setSendingStatus('FAILED');
                                }
                              }
                            }}
                            loading={sending}
                          >
                            {t('Resend payment link again via Email')}
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__footer'])}>
          {sendingStatus == 'SUCCEEDED' && (
            <Message success header={t('Send Successful')} />
          )}
          {sendingStatus == 'FAILED' && (
            <Message error header={t('Send Failed')} />
          )}
        </div>
      </div>
    );
  }
);
