import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { FloatInput } from 'client/components/FloatInput';
import {
  LocationSearchInput,
  SearchResult,
} from 'client/components/LocationSearchInput';
import { Box } from 'client/components/Box/Box';
import {
  Button,
  FieldWrapper,
  Input,
  OptionalIntegerInput,
} from 'client/components/Form';

export const ProximityLocationEditor = () => {
  const form = useForm();

  const { t } = useTranslation();
  const [locationSearchResult, setLocationSearchResult] =
    React.useState<SearchResult | null>(null);

  return (
    <div>
      <>
        <Box mt={2}>
          <LocationSearchInput
            prompt={t('Find a location')}
            onLocationSelect={(result) => setLocationSearchResult(result)}
            location={locationSearchResult?.title ?? ''}
          />
        </Box>
        <Box mt={2} mb={2}>
          <Button
            style="green"
            size="middle"
            onClick={async () => {
              if (locationSearchResult) {
                const geocoder = new google.maps.Geocoder();

                try {
                  const result = await geocoder.geocode({
                    placeId: locationSearchResult?.key ?? '',
                  });

                  form.change(
                    'locationTriggerSettings.latitude',
                    result.results[0].geometry.location.lat()
                  );
                  form.change(
                    'locationTriggerSettings.longitude',
                    result.results[0].geometry.location.lng()
                  );
                  form.change(
                    'locationTriggerSettings.locationName',
                    locationSearchResult.title
                  );

                  // eslint-disable-next-line no-empty
                } catch (e) {}
              }
            }}
          >
            {t('Populate Location Data')}
          </Button>
        </Box>
        <Field name="locationTriggerSettings.locationName">
          {({ input }) => (
            <Input
              label={t('Location Name')}
              value={input.value}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        </Field>
        <Box display="flex" mt={2}>
          <Box maxWidth="300px">
            <FieldWrapper label={t('Latitude')}>
              <Field name="locationTriggerSettings.latitude">
                {({ input }) => (
                  <FloatInput
                    value={input.value}
                    onChange={(amount) => input.onChange(amount)}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
          <Box ml={2} maxWidth="300px">
            <FieldWrapper label={t('Longitude')}>
              <Field name="locationTriggerSettings.longitude">
                {({ input }) => (
                  <FloatInput
                    value={input.value}
                    onChange={(amount) => input.onChange(amount)}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
        </Box>
      </>
      <Box mt={2}>
        <Field name="locationTriggerSettings.activationRange">
          {({ input }) => (
            <OptionalIntegerInput
              label={t('Activation distance (m)')}
              value={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
        <Box mt={2} mb={4}>
          {t(
            'We recommend setting a longer distance (20 meters or more) since GPS locations from mobile devices are often imprecise.'
          )}
        </Box>
      </Box>
    </div>
  );
};
