// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslatedInput } from '../TranslatedInput';
import { TranslatedTextArea } from '../TranslatedTextArea';

type Props = {
  name: string,
};

export const CommonMessageEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <TranslatedInput name={`${name}.title`} label={t('Title')} />
      <TranslatedTextArea
        name={`${name}.message`}
        label={t('Message')}
        isRequired={true}
      />
    </div>
  );
};
