import * as React from 'react';
import _ from 'lodash';
import { useField, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getTimeSlotsFromRecurrence } from 'client/libraries/util/getTimeSlots';
import { formattedTimeSlot } from 'client/libraries/util/formattedTimeSlot';
import { Select, ToggleButton } from 'client/components/Form';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { Product } from 'shared/models/swagger';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';
import baseStyles from 'client/base.module.css';

const getEarliestStartDate = (
  schedule: AvailabilityAllotmentSchedule
): string => {
  return _.minBy(schedule.dateRanges, 'startDate')?.startDate ?? '';
};

const getLatestEndDate = (schedule: AvailabilityAllotmentSchedule): string => {
  return _.maxBy(schedule.dateRanges, 'endDate')?.endDate ?? '';
};

type Props = {
  name: string;
  activeSchedule: AvailabilityAllotmentSchedule;
  componentProduct?: Product;
  showPickupDropoffOptions: boolean;
};
export const ComponentProductTimeSlotEditor = ({
  name,
  activeSchedule,
  componentProduct,
  showPickupDropoffOptions,
}: Props) => {
  const field = useField(name);
  const hasTransportationPreset =
    field.input.value.transportationKey ||
    field.input.value.pickup ||
    field.input.value.dropoff;
  const [showTransportationPreset, setShowTransportationPreset] =
    React.useState<boolean>(Boolean(hasTransportationPreset));
  const dayOffset = field.input.value.dayOffset || 0;
  const [showDayOffset, setShowDayOffset] = React.useState<boolean>(
    Boolean(dayOffset)
  );
  React.useEffect(() => {
    setShowDayOffset(!!dayOffset);
  }, [dayOffset]);
  const { t } = useTranslation();
  const transportationOptions = [
    {
      key: 'none',
      value: 'none',
      text: t('Checkin/Checkout Only'),
    },
    ...(componentProduct?.transportations || []).map((trans) => ({
      key: trans.key ?? '',
      value: trans.key ?? '',
      text: trans.title ?? '',
    })),
  ];

  const clearTimeSlotTransportation = () => {
    field.input.onChange({
      ...field.input.value,
      transportationKey: '',
      pickup: undefined,
      dropoff: undefined,
    });
  };

  const pickupOptions = (componentProduct?.pickup || []).map((pickupLoc) => ({
    key: pickupLoc.id ?? '',
    value: pickupLoc.id ?? '',
    text: pickupLoc.location_name ?? '',
  }));
  const dropoffOptions = (componentProduct?.dropoff || []).map(
    (dropoffLoc) => ({
      key: dropoffLoc.id ?? '',
      value: dropoffLoc.id ?? '',
      text: dropoffLoc.location_name ?? '',
    })
  );

  const scheduleEarliestStartDate = getEarliestStartDate(activeSchedule);
  const scheduleLatestEndDate = getLatestEndDate(activeSchedule);
  const timeSlots = getTimeSlotsFromRecurrence(
    componentProduct?.recurrence ?? [],
    scheduleEarliestStartDate,
    scheduleLatestEndDate
  );
  const timeSlotOptions = [
    {
      key: 'NONE',
      value: 'NONE',
      text: '',
    },
    ...timeSlots.map((timeSlot) => ({
      value: timeSlot.timeSlotKey,
      key: timeSlot.timeSlotKey,
      text: formattedTimeSlot(timeSlot),
    })),
  ];

  return (
    <div className={styles['package-start-time-component-section']}>
      <div>
        {t('Component Product: {{productName}}', {
          productName: componentProduct?.product_name,
        })}
      </div>
      <Field name={`${name}.dayOffset`}>
        {({ input }) => (
          <div className={baseStyles['base-margin-top-8']}>
            <ToggleButton
              label={t(
                'This component starts on a different day than the package starts on'
              )}
              checked={showDayOffset}
              onChange={() => {
                input.onChange(showDayOffset ? 0 : 1);
                setShowDayOffset(!showDayOffset);
              }}
            />
          </div>
        )}
      </Field>
      {showDayOffset && (
        <Field name={`${name}.dayOffset`}>
          {({ input }) => (
            <div className={baseStyles['base-margin-top-8']}>
              <Select
                label={t('Days After Package Start')}
                value={input.value}
                onChange={(e, { value }) => {
                  input.onChange(parseInt(value));
                }}
                options={[1, 2, 3, 4, 5].map((n) => ({
                  value: `${n}`,
                  key: n,
                  text: `${n}`,
                }))}
              />
            </div>
          )}
        </Field>
      )}
      <Field name={`${name}.timeSlotKey`}>
        {({ input }) => (
          <div className={baseStyles['base-margin-top-8']}>
            <div className={baseStyles['base-form-box']}>
              <div className={baseStyles['base-form-box__header']}>
                {t('Start Time')}
              </div>

              <label className={baseStyles['base-form-select']}>
                <select value={input.value || 'NONE'} onChange={input.onChange}>
                  {timeSlotOptions.map((timeSlot, idx) => (
                    <option key={idx} value={timeSlot.value}>
                      {timeSlot.text}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        )}
      </Field>
      {showPickupDropoffOptions && (
        <>
          <ToggleButton
            label={t('Set Pickup/Dropoff')}
            checked={showTransportationPreset}
            onChange={() => {
              if (showTransportationPreset) {
                clearTimeSlotTransportation();
              }

              setShowTransportationPreset(!showTransportationPreset);
            }}
          />
          {showTransportationPreset && (
            <>
              <Field name={`${name}.transportationKey`}>
                {({ input }) => (
                  <Select
                    search
                    label={t('Transportation')}
                    onChange={(e, { value }) => {
                      if (value === 'none') {
                        clearTimeSlotTransportation();
                      } else {
                        input.onChange(value);
                      }
                    }}
                    value={input.value || 'none'}
                    options={transportationOptions}
                  />
                )}
              </Field>
              {field.input.value.transportationKey && (
                <>
                  <Field name={`${name}.pickup`}>
                    {({ input }) => (
                      <Select
                        search
                        label={t('Pickup')}
                        onChange={(e, { value }) => {
                          if (value) {
                            const newPickup = (
                              componentProduct?.pickup || []
                            ).find((pickupLoc) => pickupLoc.id === value);

                            if (newPickup) {
                              input.onChange({
                                id: newPickup.id,
                                locationName: newPickup.location_name,
                                googlePlaceId: newPickup.google_place_id,
                              });
                            }
                          }
                        }}
                        value={input.value.id}
                        options={pickupOptions}
                      />
                    )}
                  </Field>
                  <Field name={`${name}.dropoff`}>
                    {({ input }) => (
                      <Select
                        search
                        label={t('Dropoff')}
                        onChange={(e, { value }) => {
                          if (value) {
                            const newDropoff = (
                              componentProduct?.dropoff || []
                            ).find((dropoffLoc) => dropoffLoc.id === value);

                            if (newDropoff) {
                              input.onChange({
                                id: newDropoff.id,
                                locationName: newDropoff.location_name,
                                googlePlaceId: newDropoff.google_place_id,
                              });
                            }
                          }
                        }}
                        value={input.value.id}
                        options={dropoffOptions}
                      />
                    )}
                  </Field>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
