import { useTranslation } from 'react-i18next';

import styles from './Note.module.css';

interface Props {
  text: string;
}

export const Note = ({ text }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div>
        <div className={styles['title']}>{t('Note')}</div>
        <div>{text}</div>
      </div>
    </div>
  );
};
