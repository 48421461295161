import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field, useForm } from 'react-final-form';

import { getArrayMutators } from 'client/libraries/util/form';
import { Box } from 'client/components/Box/Box';
import { Input, Button, Select } from 'client/components/Form';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import { ReduxState } from 'client/reducers';
import {
  updateEquipment,
  calculateEquipmentCellBlockMappings,
} from 'client/actions/equipments';
import { EditEquipmentBlockInstancePropertyModal } from 'client/components/Seat/EditEquipmentBlockInstancePropertyModal';
import { getEquipmentBlockInstancePropertyForEquipment } from 'client/components/Seat/utils';
import {
  Equipment,
  EquipmentBlockInstanceProperty,
} from 'shared/models/swagger';

import styles from './EquipmentEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  equipmentAssetInstances: {
    key: string;
    equipmentAssetId: string;
    startColumn: number;
    startRow: number;
  }[];
  equipmentBlockInstanceProperties: {
    equipmentBlockInstanceKey: string;
    equipmentBlockInstanceAttributeKey: string;
    color: string;
    capacity: number;
    isClosed: boolean;
  }[];
  startPoint: string;
  direction: string;
}

const getInitialValues = (equipment: Equipment | undefined): FormValues => {
  return {
    title: equipment?.title ?? '',
    description: equipment?.description ?? '',
    columnCount: equipment?.column_count ?? 1,
    rowCount: equipment?.row_count ?? 1,
    equipmentAssetInstances: (equipment?.equipment_asset_instances ?? []).map(
      (instance) => {
        return {
          key: instance.key ?? '',
          equipmentAssetId: instance.equipment_asset_id ?? '',
          startColumn: instance.start_column ?? 0,
          startRow: instance.start_row ?? 0,
        };
      }
    ),
    equipmentBlockInstanceProperties: (
      equipment?.equipment_block_instance_properties ?? []
    ).map((property) => {
      return {
        equipmentBlockInstanceKey: property.equipment_block_instance_key ?? '',
        equipmentBlockInstanceAttributeKey:
          property.equipment_block_instance_attribute_key ?? '',
        color: property.color ?? '',
        capacity: property.capacity ?? 0,
        isClosed: property.is_closed ?? false,
      };
    }),
    startPoint: equipment?.arrangement_rules?.start_point ?? 'LEFT_TOP',
    direction: equipment?.arrangement_rules?.direction ?? 'HORIZONTAL',
  };
};

const convertToSwagger = (values: FormValues): Equipment => {
  return {
    title: values.title,
    description: values.description,
    column_count: values.columnCount,
    row_count: values.rowCount,
    equipment_asset_instances: values.equipmentAssetInstances.map(
      (instance) => {
        return {
          key: instance.key,
          equipment_asset_id: instance.equipmentAssetId,
          start_column: instance.startColumn,
          start_row: instance.startRow,
        };
      }
    ),
    equipment_block_instance_properties:
      values.equipmentBlockInstanceProperties.map((property) => {
        return {
          equipment_block_instance_key: property.equipmentBlockInstanceKey,
          equipment_block_instance_attribute_key:
            property.equipmentBlockInstanceAttributeKey,
          color: property.color,
          capacity: property.capacity,
          is_closed: property.isClosed,
        };
      }),
    arrangement_rules: {
      start_point: values.startPoint as any,
      direction: values.direction as any,
    },
  };
};

interface Props {
  equipment: Equipment | undefined;
  draggingEquipmentAssetId: string | null;
}

export const EquipmentPanel = ({
  equipment,
  draggingEquipmentAssetId,
}: Props) => {
  const dispatch = useDispatch();
  const [editingEquipment, setEditingEquipment] = React.useState<
    Equipment | undefined
  >(equipment);
  const [
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  ] = React.useState<string[]>([]);
  const [
    showEditEquipmentBlockInstanceModal,
    setShowEditEquipmentBlockInstanceModal,
  ] = React.useState<boolean>(false);

  const lastEquipment = useSelector(
    (state: ReduxState) => state.equipments.lastCalculatedEquipment
  );

  React.useEffect(() => {
    setEditingEquipment(equipment);
  }, [equipment]);

  React.useEffect(() => {
    if (lastEquipment) {
      setEditingEquipment(lastEquipment);
    }
  }, [lastEquipment]);

  return (
    <>
      <Form<FormValues>
        onSubmit={(values: FormValues) => {
          dispatch(
            updateEquipment(equipment?.id ?? '', convertToSwagger(values))
          );
        }}
        initialValues={getInitialValues(editingEquipment) as Partial<Equipment>}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <EquipmentPanelForm
                equipment={editingEquipment}
                submitting={submitting}
                draggingEquipmentAssetId={draggingEquipmentAssetId}
                selectedEquipmentBlockInstanceKeys={
                  selectedEquipmentBlockInstanceKeys
                }
                setSelectedEquipmentBlockInstanceKeys={
                  setSelectedEquipmentBlockInstanceKeys
                }
                setShowEditEquipmentBlockInstanceModal={
                  setShowEditEquipmentBlockInstanceModal
                }
              />
            </form>
          );
        }}
      </Form>
      {showEditEquipmentBlockInstanceModal && (
        <EditEquipmentBlockInstancePropertyModal
          equipmentBlockInstanceProperty={
            selectedEquipmentBlockInstanceKeys.length === 1
              ? getEquipmentBlockInstancePropertyForEquipment(
                  selectedEquipmentBlockInstanceKeys[0],
                  equipment
                )
              : null
          }
          onClose={() => {
            setShowEditEquipmentBlockInstanceModal(false);
          }}
          open={showEditEquipmentBlockInstanceModal}
          onSave={async (newProperty: EquipmentBlockInstanceProperty) => {
            const newEditingEquipment = {
              ...equipment,
              equipment_block_instance_properties: [
                ...(
                  equipment?.equipment_block_instance_properties ?? []
                ).filter((property) => {
                  return !selectedEquipmentBlockInstanceKeys.includes(
                    property.equipment_block_instance_key ?? ''
                  );
                }),
                ...(selectedEquipmentBlockInstanceKeys.map((key) => {
                  return {
                    ...newProperty,
                    equipment_block_instance_key: key,
                  };
                }) ?? []),
              ],
            };
            console.log('newEditingEquipment', newEditingEquipment);
            setEditingEquipment(newEditingEquipment);
            setShowEditEquipmentBlockInstanceModal(false);
          }}
        />
      )}
    </>
  );
};

const EquipmentPanelForm = ({
  equipment,
  submitting,
  draggingEquipmentAssetId,
  selectedEquipmentBlockInstanceKeys,
  setSelectedEquipmentBlockInstanceKeys,
  setShowEditEquipmentBlockInstanceModal,
}: {
  equipment: Equipment | undefined;
  submitting: boolean;
  draggingEquipmentAssetId: string | null;
  selectedEquipmentBlockInstanceKeys: string[];
  setSelectedEquipmentBlockInstanceKeys: (keys: string[]) => void;
  setShowEditEquipmentBlockInstanceModal: (show: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const cellWidth = 80;
  const cellHeight = 50;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const startPointOptions = [
    { text: '左上', value: 'LEFT_TOP' },
    { text: '右上', value: 'RIGHT_TOP' },
    { text: '左下', value: 'LEFT_BOTTOM' },
    { text: '右下', value: 'RIGHT_BOTTOM' },
  ];

  const directionOptions = [
    { text: '横', value: 'HORIZONTAL' },
    { text: '縦', value: 'VERTICAL' },
  ];

  const renderLayout = () => {
    const cells = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        const mapping = (equipment?.equipment_cell_block_mappings || []).find(
          (mapping) => {
            return (mapping.row ?? 0) === i && (mapping.column ?? 0) === j;
          }
        );

        const property = getEquipmentBlockInstancePropertyForEquipment(
          mapping?.equipment_block_instance_key ?? '',
          equipment
        );

        const isSelected =
          mapping && mapping?.equipment_block_instance_key
            ? selectedEquipmentBlockInstanceKeys.includes(
                mapping?.equipment_block_instance_key ?? ''
              )
            : false;

        cells.push(
          <div
            key={`${i}-${j}`}
            className={clsx(
              isSelected ? styles['selected'] : '',
              styles['equipment-editor__panel-main__layout__cell']
            )}
            style={{
              width: cellWidth,
              height: cellHeight,
              ...(property?.color && { backgroundColor: property?.color }),
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onMouseOver={(event) => {
              if (mapping && mapping.equipment_block_instance_key) {
                event.currentTarget.style.cursor = 'pointer';
              }
            }}
            onClick={() => {
              if (mapping && mapping.equipment_block_instance_key) {
                if (
                  !selectedEquipmentBlockInstanceKeys.includes(
                    mapping.equipment_block_instance_key
                  )
                ) {
                  setSelectedEquipmentBlockInstanceKeys([
                    ...selectedEquipmentBlockInstanceKeys,
                    mapping.equipment_block_instance_key,
                  ]);
                } else {
                  setSelectedEquipmentBlockInstanceKeys([
                    ...selectedEquipmentBlockInstanceKeys.filter((key) => {
                      return (
                        key !== mapping?.equipment_block_instance_key ?? ''
                      );
                    }),
                  ]);
                }
              }
            }}
            onDrop={(e) => {
              if (draggingEquipmentAssetId) {
                const newValues = {
                  ...values,
                  equipmentAssetInstances: [
                    ...values.equipmentAssetInstances,
                    {
                      key: uuidv4(),
                      equipmentAssetId: draggingEquipmentAssetId,
                      startColumn: j,
                      startRow: i,
                    },
                  ],
                };
                dispatch(
                  calculateEquipmentCellBlockMappings(
                    convertToSwagger(newValues as FormValues)
                  )
                );
              }
              e.preventDefault();
            }}
          >
            {convertToAlphabet(j + 1)}
            {i + 1}
          </div>
        );
      }
    }
    return cells;
  };

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <Box mt={2} mr={2} ml={2}>
          <Field name="title">
            {({ input }) => (
              <Input
                label={t('Title')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
        <Box mt={2} mr={2} ml={2}>
          <Field name="columnCount">
            {({ input }) => (
              <Input
                type="number"
                label={t('Column count')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
                {...{
                  min: 1,
                }}
              />
            )}
          </Field>
        </Box>
        <Box mt={2} mr={2} ml={2}>
          <Field name="rowCount">
            {({ input }) => (
              <Input
                type="number"
                label={t('Row count')}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
                {...{
                  min: 1,
                }}
              />
            )}
          </Field>
        </Box>
        <Box display="flex" alignItems="center" mt={2} mr={2} ml={2}>
          <Field name="startPoint">
            {({ input }) => (
              <Select
                label={t('Start point')}
                options={startPointOptions}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>

          <Field name="direction">
            {({ input }) => (
              <Select
                label={t('equipment.Direction')}
                options={directionOptions}
                value={input.value}
                onChange={(_, { value }) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
      </div>
      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <Box>
          <Button
            disabled={!selectedEquipmentBlockInstanceKeys.length}
            style="blue"
            size="middle"
            onClick={() => {
              setShowEditEquipmentBlockInstanceModal(true);
            }}
          >
            属性の編集
          </Button>
        </Box>
        <div
          className={clsx(styles['equipment-editor__panel-main__layout'])}
          style={{
            gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
            gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
            width: layoutWidth,
            height: layoutHeight,
          }}
        >
          {renderLayout()}
        </div>
      </div>
      <Box
        mt={2}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          loading={submitting}
          size="middle"
          style="blue"
          type="submit"
          disabled={values.title ? false : true}
        >
          {t('Save')}
        </Button>
      </Box>
    </div>
  );
};
