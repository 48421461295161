// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch as ReduxDispatch } from 'redux';

import { updateDispatchVehicles } from 'client/actions/dispatchSettings';
import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import type { DispatchResource } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Input, TextArea } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

type OwnProps = {
  existingVehicle?: DispatchResource,
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

const EditVehicleModalComponent = ({
  existingVehicle,
  allVehicles,
  updateVehicles,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [vehicleKey, setVehicleKey] = React.useState<string>('');
  const [vehicleCapacity, setVehicleCapacity] = React.useState<
    number | typeof undefined
  >(undefined);
  const [vehicleDescription, setVehicleDescription] =
    React.useState<string>('');

  React.useEffect(() => {
    setVehicleKey(existingVehicle?.key || '');
    setVehicleCapacity(existingVehicle?.capacity || 0);
    setVehicleDescription(existingVehicle?.description || '');
  }, [existingVehicle]);

  const headerText = existingVehicle ? t('Edit Vehicle') : t('Add New Vehicle');
  return (
    <Modal title={headerText} open={open} onClose={onClose} onOpen={() => {}}>
      <Modal.Content>
        <Modal.Box>
          <Input
            disabled={Boolean(existingVehicle)}
            label={t('Name')}
            value={vehicleKey}
            onChange={(e, { value }) => setVehicleKey(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <TextArea
            label={t('Description')}
            value={vehicleDescription}
            onChange={(e, { value }) => setVehicleDescription(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <div className={baseStyles['base-form-box']}>
            <div className={baseStyles['base-form-box__header']}>
              {t('Capacity')}
            </div>
            <div className={baseStyles['base-form-box__body']}>
              <label className={baseStyles['base-form-select']}>
                <select
                  value={vehicleCapacity}
                  onChange={(e) =>
                    setVehicleCapacity(parseInt(e.target.value, 10))
                  }
                >
                  {[...Array(100)].map((_, idx) => {
                    return (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            const newVehicle = {
              key: vehicleKey,
              capacity: vehicleCapacity,
              description: vehicleDescription,
            };
            updateVehicles([
              ...allVehicles.filter((vehicle) => vehicleKey !== vehicle.key),
              newVehicle,
            ]);
            onClose();
          }}
          disabled={!vehicleKey}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  allVehicles: allDispatchVehiclesSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  updateVehicles: (vehicles: DispatchResource[]) =>
    dispatch(updateDispatchVehicles(vehicles)),
});

export const EditVehicleModal = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(EditVehicleModalComponent);
