import * as React from 'react';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { NotificationListElement } from './NotificationListElement';
import styles from './NotificationList.module.css';

export const NotificationList = () => {
  const notifications = useSelector(
    (state: ReduxState) => state.notifications.checkinNotifications
  );
  const org = useSelector(activeUserOrganizationSelector);

  if (org?.extranet_notification_settings?.disable_qr_checkin_notifications) {
    return null;
  }

  return (
    <div className={styles['snackbar-container']}>
      {notifications.map((notification, idx) => (
        <NotificationListElement key={idx} notification={notification} />
      ))}
    </div>
  );
};
