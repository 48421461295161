import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCTS_BY_ID_FAILURE,
  FETCH_PRODUCTS_BY_ID_REQUEST,
  FETCH_PRODUCTS_BY_ID_SUCCESS,
  FETCH_PRODUCT_FOR_EDITING_FAILURE,
  FETCH_PRODUCT_FOR_EDITING_REQUEST,
  FETCH_PRODUCT_FOR_EDITING_SUCCESS,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_CANCELED,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  SET_PRODUCT_RESPONSIBLE_AGENT_ID_FAILURE,
  SET_PRODUCT_RESPONSIBLE_AGENT_ID_REQUEST,
  SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS,
} from 'client/constants/ActionTypes';
import { contentLanguageOptions } from 'client/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import type {
  AgentUnitMapping,
  ListProductsRequest,
  Product$Patch,
  Product$Input,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchProductsRequest = () => ({
  type: FETCH_PRODUCTS_REQUEST,
});

const fetchProductsSuccess = (response: any) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  response,
});

const fetchProductsFailure = (error: any) => ({
  type: FETCH_PRODUCTS_FAILURE,
  error,
});

const fetchProductsCanceled = () => ({
  type: FETCH_PRODUCTS_CANCELED,
});

let fetchProductsCancel: () => void | typeof undefined;
export const fetchProducts =
  (supplier_id?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchProductsCancel && fetchProductsCancel();
    dispatch(fetchProductsRequest());
    const params = {
      supplier_id,
    };
    axios
      .get('/api/products', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchProductsCancel = c;
        }),
      })
      .then((result) => dispatch(fetchProductsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchProductsCanceled());
        else dispatch(fetchProductsFailure(error));
      });
  };

const fetchPassthroughCandidateProductsRequest = () => ({
  type: FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST,
});

const fetchPassthroughCandidateProductsSuccess = (response: any) => ({
  type: FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS,
  response,
});

const fetchPassthroughCandidateProductsFailure = (error: any) => ({
  type: FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE,
  error,
});

let fetchPassthroughCandidateProductsCancel: () => void | typeof undefined;
export const fetchPassthroughCandidateProducts =
  (params: ListProductsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchPassthroughCandidateProductsCancel &&
      fetchPassthroughCandidateProductsCancel();
    dispatch(fetchPassthroughCandidateProductsRequest());
    axios
      .get('/api/products', {
        params,
        headers: getHTTPRequestHeaders(getState(), {
          useAlternateOrganization: true,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchPassthroughCandidateProductsCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchPassthroughCandidateProductsSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(
            fetchPassthroughCandidateProductsFailure(
              new Error('request canceled')
            )
          );
        else dispatch(fetchPassthroughCandidateProductsFailure(error));
      });
  };

const fetchProductsByIDRequest = (ids: string[]) => ({
  type: FETCH_PRODUCTS_BY_ID_REQUEST,
  ids,
});

const fetchProductsByIDSuccess = (responses: any) => ({
  type: FETCH_PRODUCTS_BY_ID_SUCCESS,
  responses,
});

const fetchProductsByIDFailure = (error: any) => ({
  type: FETCH_PRODUCTS_BY_ID_FAILURE,
  error,
});

export const fetchProductsByID =
  (ids: string[], language?: string, getSourceLanguageContents?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductsByIDRequest(ids));
    const promises = ids.map((id) =>
      fetch(
        getSourceLanguageContents
          ? `/api/products/${id}?ignore_request_language=true`
          : `/api/products/${id}`,
        {
          headers: {
            ...getHTTPRequestHeaders(getState(), {
              language,
            }),
          },
        }
      ).then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
    );
    return Promise.all(promises)
      .then((responses) => dispatch(fetchProductsByIDSuccess(responses)))
      .catch((error) => dispatch(fetchProductsByIDFailure(error)));
  };
export const fetchProductByID = (id: string, language?: string) =>
  fetchProductsByID([id], language);
export const fetchSourceLanguageProductById = (id: string) =>
  fetchProductsByID([id], '', true);

const fetchProductForEditingRequest = () => ({
  type: FETCH_PRODUCT_FOR_EDITING_REQUEST,
});

const fetchProductForEditingSuccess = (responses: any) => ({
  type: FETCH_PRODUCT_FOR_EDITING_SUCCESS,
  responses,
});

const fetchProductForEditingFailure = (error: any) => ({
  type: FETCH_PRODUCT_FOR_EDITING_FAILURE,
  error,
});

// fetchProductForEditing fetches all language contents for a product instead of just one language
export const fetchProductForEditing =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductForEditingRequest());
    const supportedLanguages = contentLanguageOptions.map(
      (option) => option.iso
    );
    const translationPromises: Promise<any>[] = [];
    supportedLanguages.forEach((lang) => {
      translationPromises.push(
        fetch(`/api/products/${id}`, {
          headers: {
            ...getHTTPRequestHeaders(getState(), {
              language: lang,
            }),
          },
        }).then((res) => {
          if (!res.ok) {
            throw res.statusText;
          }

          return res.json();
        })
      );
    });
    return Promise.all(translationPromises)
      .then((responses) => {
        const responseObject: Record<string, any> = {};
        supportedLanguages.forEach((lang, idx) => {
          responseObject[lang] = responses[idx];
        });
        dispatch(fetchProductForEditingSuccess(responseObject));
      })
      .catch((error) => dispatch(fetchProductForEditingFailure(error)));
  };

const createProductRequest = (newProduct: Product$Input) => ({
  type: CREATE_PRODUCT_REQUEST,
  newProduct,
});

const createProductSuccess = (response: any) => ({
  type: CREATE_PRODUCT_SUCCESS,
  response,
});

const createProductFailure = (error: any) => ({
  type: CREATE_PRODUCT_FAILURE,
  error,
});

export const createProduct =
  (newProduct: Product$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createProductRequest(newProduct));
    return fetch('/api/products', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newProduct),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => {
        dispatch(createProductSuccess(result));
        return result;
      })
      .catch((error) => {
        dispatch(createProductFailure(error));
        throw error;
      });
  };

const updateProductRequest = (id: string, patch: Product$Patch) => ({
  type: UPDATE_PRODUCT_REQUEST,
  id,
  patch,
});

const updateProductSuccess = (response: any) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  response,
});

const updateProductFailure = (error: any) => ({
  type: UPDATE_PRODUCT_FAILURE,
  error,
});

export const updateProduct =
  (id: string, patch: Product$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateProductRequest(id, patch));
    return fetch(`/api/products/${id}`, {
      method: 'PATCH',
      headers: { ...getHTTPRequestHeaders(getState()) },
      body: JSON.stringify({
        ...patch,
        reservation_form_fields: patch.reservation_form_fields
          ? patch.reservation_form_fields.map((field) => ({
              ...field,
              required: field.required ? field.required : 'OPTIONAL',
              updatable: field.updatable ? field.updatable : false,
            }))
          : undefined,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response: any) => {
        dispatch(updateProductSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((e: any) => {
            dispatch(updateProductFailure(e.message));
          });
        } else {
          dispatch(updateProductFailure(error));
        }

        throw error;
      });
  };

const deleteProductRequest = (id: string) => ({
  type: DELETE_PRODUCT_REQUEST,
  id,
});

const deleteProductSuccess = (response: Record<string, any>, id: string) => ({
  type: DELETE_PRODUCT_SUCCESS,
  response,
  id,
});

const deleteProductFailure = (error: any) => ({
  type: DELETE_PRODUCT_FAILURE,
  error,
});

export const deleteProduct =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteProductRequest(id));
    return fetch(`/api/products/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response: any) => {
        dispatch(deleteProductSuccess(response, id));
      })
      .catch((error) => {
        error.json().then((e: any) => {
          dispatch(deleteProductFailure(e.message));
        });
      });
  };

const setProductResponsibleAgentRequest = (
  productID: string,
  responsibleAgentID: string
) => ({
  type: SET_PRODUCT_RESPONSIBLE_AGENT_ID_REQUEST,
  productID,
  responsibleAgentID,
});

const setProductResponsibleAgentSuccess = (
  productID: string,
  responsibleAgentID: string
) => ({
  type: SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS,
  productID,
  responsibleAgentID,
});

const setProductResponsibleAgentFailure = (
  productID: string,
  error: string
) => ({
  type: SET_PRODUCT_RESPONSIBLE_AGENT_ID_FAILURE,
  productID,
  error,
});

export const setProductResponsibleAgent =
  (productID: string, responsibleAgentID: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(setProductResponsibleAgentRequest(productID, responsibleAgentID));
    return axios
      .post(
        `/api/products/${productID}/responsible_agent_id`,
        {
          responsible_agent_id: responsibleAgentID,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => {
        dispatch(
          setProductResponsibleAgentSuccess(productID, responsibleAgentID)
        );
      })
      .catch((err) => {
        dispatch(setProductResponsibleAgentFailure(productID, err.message));
      });
  };

const setProductAgentUnitMappingsRequest = (
  productID: string,
  unitMappings: AgentUnitMapping[]
) => ({
  type: SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST,
  productID,
  unitMappings,
});

const setProductAgentUnitMappingsSuccess = (
  productID: string,
  response: any
) => ({
  type: SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS,
  productID,
  response,
});

const setProductAgentUnitMappingsFailure = (
  productID: string,
  error: string
) => ({
  type: SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE,
  productID,
  error,
});

export const setProductAgentUnitMappings =
  (productID: string, unitMappings: AgentUnitMapping[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(setProductAgentUnitMappingsRequest(productID, unitMappings));
    return axios
      .post(
        `/api/products/${productID}/unitmappings`,
        {
          unit_mappings: unitMappings,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(setProductAgentUnitMappingsSuccess(productID, response));
      })
      .catch((err) => {
        dispatch(setProductAgentUnitMappingsFailure(productID, err.message));
      });
  };

const updateProductBlacklistWhitelistRequest = (
  id: string,
  patch: Product$Patch
) => ({
  type: UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST,
  id,
  patch,
});

const updateProductBlacklistWhitelistSuccess = (response: any) => ({
  type: UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS,
  response,
});

const updateProductBlacklistWhitelistFailure = (error: any) => ({
  type: UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE,
  error,
});

// this action is same with updateProduct. the action type is not same.
// we separate this function to update contents of product.ByID[id]
export const updateProductBlacklistWhitelist =
  (id: string, patch: Product$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateProductBlacklistWhitelistRequest(id, patch));
    return fetch(`/api/products/${id}`, {
      method: 'PATCH',
      headers: { ...getHTTPRequestHeaders(getState()) },
      body: JSON.stringify({
        ...patch,
        reservation_form_fields: patch.reservation_form_fields
          ? patch.reservation_form_fields.map((field) => ({
              ...field,
              required: field.required ? field.required : 'OPTIONAL',
              updatable: field.updatable ? field.updatable : false,
            }))
          : undefined,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response: any) => {
        dispatch(updateProductBlacklistWhitelistSuccess(response));
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((e: any) => {
            dispatch(updateProductBlacklistWhitelistFailure(e.message));
          });
        } else {
          dispatch(updateProductBlacklistWhitelistFailure(error));
        }
      });
  };

const fetchPartnershipPackageProductsRequest = () => ({
  type: FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST,
});

const fetchPartnershipPackageProductsSuccess = (response: any) => ({
  type: FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS,
  response,
});

const fetchPartnershipPackageProductsFailure = (error: any) => ({
  type: FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE,
  error,
});

export const fetchPartnershipPackageProducts =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipPackageProductsRequest());
    return axios
      .get('/api/products/partnershippackage', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchPartnershipPackageProductsSuccess(result.data));
      })
      .catch((error) => {
        dispatch(fetchPartnershipPackageProductsFailure(error));
      });
  };
