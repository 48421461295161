import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import {
  GraphDataItem,
  convertAccessReportDataSetsToGraphData,
} from 'client/libraries/util/accessReportSettings';
import { AccessReportGadgetParams } from 'client/reducers/dashboardSettings';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { AccessReportBarChartPane } from 'client/pages/v3/FlexibleDashboard/Chart/AccessReportBarChartPane';
import { getDateRange } from 'client/pages/v3/FlexibleDashboard/util';
import { COMPARISON_COLOR } from 'client/pages/v3/FlexibleDashboard/Chart/util';

interface Props {
  params: AccessReportGadgetParams;
  reportDataSets: Swagger.AccessReportDataSet[];
}

const getGraphColor = (params: AccessReportGadgetParams): string => {
  switch (params.dataType) {
    case 'PAGE_VIEWS':
      return '#84CC16';
    case 'USERS':
      return '#8B5CF6';
    case 'RESERVATIONS':
      return '#F97316';
  }
};

export const AccessReportBarChart = ({ reportDataSets, params }: Props) => {
  const { t } = useTranslation();

  const [baseRange, compareRange] = getDateRange(reportDataSets);

  const graphData = convertAccessReportDataSetsToGraphData(
    reportDataSets ?? []
  );

  const getKeys = (isComparison: boolean): (keyof GraphDataItem)[] => {
    switch (params.dataType) {
      case 'PAGE_VIEWS':
        if (isComparison) {
          return ['visitCount', 'compareVisitCount'];
        }
        return ['visitCount'];
      case 'USERS':
        if (isComparison) {
          return ['userCount', 'compareUserCount'];
        }
        return ['userCount'];
      case 'RESERVATIONS':
      default:
        if (isComparison) {
          return ['reservationCount', 'compareReservationCount'];
        }
        return ['reservationCount'];
    }
  };

  // Convert graph data to this format
  /* 
  [
    {
      "name": xxx,
      [baseRange]: xxx,
      [compareRange]: xxx,
    }
  ]
  */
  const convertDashboardGraphData = (
    graphData: GraphDataItem[],
    keys: (keyof GraphDataItem)[],
    ranges: string[]
  ) => {
    return graphData.map((item) => {
      const newItem: any = { name: item.name };
      keys.forEach((key, index) => {
        if (index === reportDataSets.length) {
          return;
        }
        newItem[ranges[index]] = item[key];
      });
      return newItem;
    });
  };

  let names = {
    [baseRange]: t('Base Range'),
  };
  let colors = [getGraphColor(params)];
  if (reportDataSets.length > 1) {
    names = {
      [baseRange]: t('Base Range'),
      [compareRange]: t('Compare Range'),
    };
    colors = [getGraphColor(params), COMPARISON_COLOR];
  }

  return (
    <div
      className={styles['p-transition__body__graph']}
      // TODO: for now this should be hardcoded, find a way to do 100%
      style={{ height: '400px' }}
    >
      <AccessReportBarChartPane
        data={convertDashboardGraphData(
          graphData,
          getKeys(reportDataSets.length > 1),
          [baseRange, compareRange]
        )}
        names={names}
        colors={colors}
      />
    </div>
  );
};
