import * as Swagger from 'shared/models/swagger';

export const getOrderedProductIds = (
  organization: Swagger.Organization | null,
  products: Swagger.ProductSummary[],
  language: Swagger.SourceLanguage
): string[] => {
  const customOrder =
    organization?.booking_widget_design_params?.product_display_orders?.find(
      (order) => order.language === language
    )?.product_ids ?? [];
  const allIds = products
    .filter(
      (product) =>
        product?.booking_widget_settings?.is_visible &&
        !product?.booking_widget_settings?.blacklisted_languages?.includes(
          language
        ) &&
        !product?.booking_widget_settings
          ?.is_hidden_from_product_list_and_search
    )
    .map((product) => product.id);
  return [
    ...customOrder.filter((id) => allIds.includes(id)),
    ...allIds.filter((id) => !customOrder.includes(id)),
  ];
};
