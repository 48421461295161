import * as React from 'react';
import clsx from 'clsx';

import { ModalLoader } from 'client/components/ModalLoader';
import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import { isTouchBackend } from 'client/libraries/util/isTouchBackend';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  name?: string;
  error?: string;
  label?: string | React.ReactElement;
  placeholder?: string;
  search?: boolean;
  // If 'true', input outside of the choices will be accepted and shown.
  allowFreeInput?: boolean;
  additionLabel?: string;
  value: string;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  width?: number;
  maxWidth?: number;
  options: {
    text: string;
    value: string;
  }[];
  style?: Record<string, any>;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  optionStyle?: Record<string, string>;
  cFormSingleStyle?: Record<string, string>;
};
export const Select = ({
  error,
  label,
  placeholder,
  search,
  allowFreeInput,
  // additionLabel,
  value,
  onChange,
  width,
  maxWidth,
  style,
  options,
  disabled,
  loading,
  optionStyle,
  cFormSingleStyle,
}: Props) => {
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const [placeHolder, setPlaceHolder] = React.useState<string>(
    placeholder || ''
  );
  const [displayText, setDisplayText] = React.useState<string>(`${value}`);
  const areaRef = React.useRef<HTMLDivElement | null>(null);
  const combinedStyle = {
    width,
    maxWidth,
    ...style,
  };
  const internalCombinedStyle = {
    ...style,
  };
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);

        // Clicking the input cleared displayText so we need to set it again on outside click
        if (displayText === '') {
          setDisplayText(options.find((o) => o.value === value)?.text || '');
        }
      }
    };

    if (showOption) {
      window.document.addEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.addEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
      return () => {
        window.document.removeEventListener('mousedown', handleOutsideClick, {
          capture: true,
        });
        window.document.removeEventListener('touchstart', handleOutsideClick, {
          capture: true,
        });
      };
    }
  }, [showOption, displayText]);
  React.useEffect(() => {
    const newDisplayText =
      options.find((o) => o.value === value)?.text ?? `${value}`;

    if (newDisplayText !== displayText && !showOption) {
      setDisplayText(newDisplayText);
    }
  }, [value, displayText]);
  React.useEffect(() => {
    // If the options list changes, see if we should change the display text.
    const newDisplayText =
      options.find((o) => o.value === value)?.text ?? value;

    if (newDisplayText !== displayText) {
      setDisplayText(newDisplayText);
    }
  }, [options]);
  const filteredOptions = options.filter((o) => {
    if (o.text === '') {
      return false;
    }

    if (search && displayText) {
      return replaceFullWidthCharactersWithHalfWidth(
        o.text.toLowerCase()
      ).includes(displayText.toLowerCase());
    }

    return true;
  });
  return (
    <div
      className={baseStyles['base-form-box']}
      style={combinedStyle}
      {...getTouchEventBoundaryProps()}
    >
      {label && (
        <div className={baseStyles['base-form-box__header']}>{label}</div>
      )}
      <div className={baseStyles['base-form-box__body']}>
        <div
          style={cFormSingleStyle ? { ...cFormSingleStyle } : {}}
          className={clsx(componentStyles['c-form-single'])}
          ref={areaRef}
        >
          <label
            className={clsx(
              componentStyles['select'],
              showOption
                ? filteredOptions.length > 0
                  ? componentStyles['is-active']
                  : ''
                : ''
            )}
            style={combinedStyle}
          >
            {loading ? (
              <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <ModalLoader />
              </div>
            ) : (
              <input
                disabled={disabled}
                value={displayText}
                placeholder={placeHolder}
                style={internalCombinedStyle}
                onClick={() => {
                  setPlaceHolder(displayText);
                  setDisplayText('');
                  setShowOption(true);
                }}
                onChange={(e) => {
                  if (allowFreeInput) {
                    onChange(e, {
                      value: e.target.value,
                    });
                  }

                  setDisplayText(
                    options.find((o) => o.value === e.target.value)?.text ??
                      e.target.value
                  );
                }}
                type="text"
                readOnly={
                  !search || (isTouchBackend() && !allowFreeInput)
                    ? true
                    : undefined
                }
              />
            )}
          </label>
          {filteredOptions.length > 0 && (
            <ul
              style={optionStyle ? { ...optionStyle } : {}}
              className={clsx(componentStyles['option'])}
            >
              {filteredOptions.map((option, idx) => {
                return (
                  <li
                    key={idx}
                    data-value={option.value}
                    data-idx={idx}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      onChange(e, {
                        value: option.value,
                      });
                      // Clicking the input cleared display text so we need to set it again in case we are clicking on the same
                      // value that was set previously.
                      setDisplayText(option.text);
                      setShowOption(false);
                    }}
                  >
                    {option.text || 'NO WORD'}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
