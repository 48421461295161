import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import clsx from 'clsx';

import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { fetchWaiverTemplateTranslations } from 'client/actions/translations';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Box } from 'client/components/Box/Box';
import { FieldWrapper, Input, Select, TextArea } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/pages/Loading';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import { FormValues, getDefaultWaiverSection } from './formValues';
import { ItemsEditor } from './ItemsEditor';
import styles from './WaiverTemplateList.module.css';

type Props = {
  waiverTemplateId?: string;
};
export const WaiverTemplateContentEditor = ({ waiverTemplateId }: Props) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const values = form.getState().values;
  const showTranslationMode = values?.showTranslationMode ?? '';
  const translationTargetLanguage = values?.showTranslationMode
    ? values?.translationTargetLanguage ?? null
    : null;
  const translationTargetLanguageName = translationTargetLanguage
    ? getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t)
    : '';
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (waiverTemplateId != null) {
      dispatch(fetchWaiverTemplateTranslations(waiverTemplateId));
    }
  }, [waiverTemplateId]);
  const waiverTemplateTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const displayTypeOptions = [
    {
      value: 'PER_BOOKING',
      label: t('Show once'),
    },
    {
      value: 'PER_PARTICIPANT',
      label: t('Show for each participant'),
    },
  ];
  React.useEffect(() => {
    let translations: {
      source: string;
      target: string;
    }[] = [];

    if (
      values?.sourceLanguage &&
      translationTargetLanguage &&
      waiverTemplateId
    ) {
      const sourceFieldName = values?.sourceLanguage.toLowerCase();
      const translationFieldName = translationTargetLanguage.toLowerCase();
      translations = waiverTemplateTranslations.map((trans) => ({
        source: (trans as any)[sourceFieldName],
        target: (trans as any)[translationFieldName],
      }));
    }

    form.change('translations', translations);
  }, [
    form,
    values?.sourceLanguage,
    waiverTemplateTranslations,
    translationTargetLanguage,
    waiverTemplateId,
  ]);

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <TranslationLanguageContext.Provider value={translationTargetLanguage}>
      <Box mt={2}>
        {(values?.translatedLanguages ?? []).length > 0 && (
          <div className={baseStyles['base-main__body__header']}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spSpacebetween'],
                baseStyles['spOrder-1']
              )}
            >
              <div className={baseStyles['base-form-toggle']}>
                <label>
                  <Field name="showTranslationMode" type="checkbox">
                    {({ input }) => <input type="checkbox" {...input} />}
                  </Field>
                  <p></p>
                </label>
                {t('Translation mode')}
              </div>
              {showTranslationMode && (
                <Field<
                  FormValues['translationTargetLanguage']
                > name="translationTargetLanguage">
                  {({ input }) => (
                    <Select
                      width={176}
                      options={(values?.translatedLanguages ?? []).map(
                        (option) => ({
                          value: option,
                          text: getLanguageName(option, t),
                        })
                      )}
                      value={input.value ?? ''}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </div>
          </div>
        )}
      </Box>
      <p className={styles['table__section__header']}>{t('Waiver Content')}</p>
      <FormTableBox>
        <table>
          <tbody>
            <tr>
              <th>{t('Title')}</th>
              <td>
                <ul className={styles['scheds']}>
                  <li className={styles['scheds__cell']}>
                    <TranslatedField name="title">
                      {({ input, translationInput }) => (
                        <>
                          <div>
                            <p className={styles['scheds__body']}>
                              <Input {...input} />
                            </p>
                          </div>
                          {translationTargetLanguage && (
                            <div>
                              <p className={styles['scheds__body']}>
                                <Input {...translationInput} />
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </TranslatedField>
                  </li>
                </ul>
              </td>
              <td
                className={clsx(styles['c-table-list__btns'], styles['hidden'])}
              />
            </tr>
            <tr>
              <th>{t('Description')}</th>
              <td>
                <ul className={styles['scheds']}>
                  <li className={styles['scheds__cell']}>
                    <TranslatedField name="description">
                      {({ input, translationInput }) => (
                        <>
                          <div>
                            <p className={styles['scheds__body']}>
                              <TextArea
                                onChange={input.onChange}
                                value={input.value}
                              />
                            </p>
                          </div>
                          {translationTargetLanguage && (
                            <div>
                              <p className={styles['scheds__body']}>
                                <TextArea
                                  onChange={translationInput.onChange}
                                  value={translationInput.value}
                                />
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </TranslatedField>
                  </li>
                </ul>
              </td>
              <td
                className={clsx(styles['c-table-list__btns'], styles['hidden'])}
              />
            </tr>
            <FieldArray name="sections">
              {({ fields }) => {
                return fields.length === 0 ? (
                  <tr>
                    <th>{t('Sections')}</th>
                    <td colSpan={2}>
                      <AddIcon
                        onClick={() =>
                          (fields as any).insertAt(0, getDefaultWaiverSection())
                        }
                      />
                    </td>
                  </tr>
                ) : (
                  fields.map((name, idx) => (
                    <tr key={idx}>
                      <th>
                        {t('Section #{{sectionNumber}}', {
                          sectionNumber: idx + 1,
                        })}
                      </th>
                      <td>
                        <FieldWrapper label={t('Section Display Type')}>
                          <EnumRadioButtonGroup
                            name={`${name}.displayType`}
                            options={displayTypeOptions}
                          />
                        </FieldWrapper>
                        <ul className={styles['scheds']}>
                          <li className={styles['scheds__cell']}>
                            <TranslatedField name={`${name}.header`}>
                              {({ input, translationInput }) => (
                                <>
                                  <div>
                                    <p className={styles['scheds__body']}>
                                      <Input
                                        {...input}
                                        label={t('Section Title')}
                                      />
                                    </p>
                                  </div>
                                  {translationTargetLanguage && (
                                    <div>
                                      <p className={styles['scheds__body']}>
                                        <Input
                                          {...translationInput}
                                          label={t(
                                            'Section Title ({{language}})',
                                            {
                                              language:
                                                translationTargetLanguageName,
                                            }
                                          )}
                                        />
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </TranslatedField>
                          </li>
                        </ul>
                        <ItemsEditor showConditional name={`${name}.items`} />
                      </td>
                      <td className={styles['c-table-list__btns']}>
                        <div className={baseStyles['base-flex']}>
                          <AddIcon
                            onClick={() =>
                              (fields as any).insertAt(
                                idx + 1,
                                getDefaultWaiverSection()
                              )
                            }
                          />
                          <DeleteIcon onClick={() => fields.remove(idx)} />
                        </div>
                      </td>
                    </tr>
                  ))
                );
              }}
            </FieldArray>
          </tbody>
        </table>
      </FormTableBox>
    </TranslationLanguageContext.Provider>
  );
};
