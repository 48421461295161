import * as React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'client/config';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  Select,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { fetchGuidanceCoupons } from 'client/actions/guidanceCoupon';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ReduxState } from 'client/reducers';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { createSalesOffer, updateSalesOffer } from 'client/actions/salesOffers';
import { fetchProducts } from 'client/actions/products';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { DraggableProductList } from 'client/pages/ProductDetails/ProductContentsHeader/DraggableProductList/DraggableProductList';

import {
  convertFormValuesToSwagger,
  convertSwaggerToFormValues,
  FormValues,
} from './FormValues';
import { ProximityLocationEditor } from './ProximityLocationEditor';
import { ConditionRuleListEditor } from './ConditionRuleListEditor';

const defaultInitialValues: FormValues = {
  title: '',
  status: 'DRAFT',
  deliveryMedium: 'WEBSITE',

  // Content
  offerContentType: 'PRODUCT',
  productIds: [],
  promoCode: '',
  couponId: '',
  thumbnailUrl: '',
  displayType: 'POPUP',

  // Trigger
  displayTriggerType: 'ETICKET_BEFORE_REDEMPTION',
  triggerCouponIds: [],

  // Conditions
  conditionRules: [
    {
      shouldFilterByBookedProductIds: false,
      bookedProductIds: [],
      participationStartDate: '',
      participationEndDate: '',
      shouldFilterByTimeOfDay: false,
      timeOfDayStart: '00:00',
      timeOfDayEnd: '23:59',

      // Limits
      limitType: 'NO_LIMIT',
      expirationTimeOfDay: '23:59',
    },
  ],
};

interface Props {
  annualPassMode?: boolean;
}

export const SalesOfferEditor = (
  { annualPassMode = false }: Props = { annualPassMode: false }
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const coupons = useSelector((state: ReduxState) => state.guidanceCoupons.all);

  const existingSalesOffer = useSelector((state: ReduxState) =>
    state.salesOffers.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchGuidanceCoupons());
  }, []);

  const initialValues = React.useMemo(() => {
    return existingSalesOffer
      ? convertSwaggerToFormValues(existingSalesOffer)
      : {
          ...defaultInitialValues,
          ...(annualPassMode
            ? {
                offerContentType: 'ANNUAL_PASS',
              }
            : {}),
        };
  }, [existingSalesOffer]);

  const offerTypeOptions = [
    ...(annualPassMode
      ? [
          {
            value: 'ANNUAL_PASS',
            label: t('Annual Pass'),
          },
        ]
      : [
          {
            value: 'PRODUCT',
            label: t('Product'),
          },
          {
            value: 'COUPON',
            label: t('Coupon'),
          },
          ...(config.enableMaRevamp
            ? [
                {
                  value: 'PRODUCT_LIST',
                  label: t('Product List'),
                },
              ]
            : []),
        ]),
  ];

  const displayTypeOptions = [
    {
      value: 'POPUP',
      label: t('Popup'),
    },
    {
      value: 'INLINE',
      label: t('Inline'),
    },
  ];

  const statusOptions = [
    {
      value: 'DRAFT',
      label: t('Draft'),
    },
    {
      value: 'ACTIVE',
      label: t('Active'),
    },
  ];

  const productOptions = allProducts
    .filter((product) => {
      return (
        annualPassMode ===
        (product?.annual_pass_mode_settings?.is_enabled ?? false)
      );
    })
    .map((product) => ({
      key: product.id ?? '',
      value: product.id ?? '',
      text: product.internal_product_name ?? '',
    }));

  const couponOptions = coupons.map((coupon) => ({
    value: coupon.id ?? '',
    text: coupon.title ?? '',
  }));

  const displayTriggerTypeOptions = [
    {
      value: 'ETICKET_BEFORE_REDEMPTION',
      label: t('E-Ticket: ticket page is opened (before redemption)'),
    },
    {
      value: 'ETICKET_AFTER_REDEMPTION',
      label: t('E-Ticket: ticket is redeemed'),
    },
    {
      value: 'RESERVATION_THANK_YOU_PAGE',
      label: t('New Reservation: "Thank You" page is navigated to'),
    },
    {
      value: 'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION',
      label: t('Online Guide: customer is detected near a key location'),
    },
    {
      value: 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION',
      label: t('Online Guide: coupon is redeemed'),
    },
  ];

  const deliveryMediumOptions = [
    {
      value: 'WEBSITE',
      label: t('Website'),
    },
    ...(annualPassMode
      ? []
      : [
          {
            value: 'EMAIL',
            label: t('Email'),
          },
        ]),
  ];

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2}>
          <Link to={`${annualPassMode ? '/annualpasses' : ''}/offers`}>
            <BackArrow />
          </Link>
        </Box>
        <Form
          onSubmit={async (values: FormValues) => {
            if (id) {
              await dispatch(
                updateSalesOffer(id, convertFormValuesToSwagger(values))
              );
            } else {
              await dispatch(
                createSalesOffer(convertFormValuesToSwagger(values))
              );
            }
          }}
          initialValues={initialValues}
          debug={console.log}
          mutators={getArrayMutators()}
        >
          {({
            handleSubmit,
            submitting,
            values,
            submitSucceeded,
            submitError,
            modifiedSinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Basics')}</th>
                      <td>
                        <Field name="title">
                          {({ input }) => (
                            <Input label={t('Name')} {...input} />
                          )}
                        </Field>
                        <Box mt={2}>
                          <FieldWrapper label={t('Status')}>
                            <EnumRadioButtonGroup
                              name="status"
                              options={statusOptions}
                            />
                          </FieldWrapper>
                        </Box>
                        <FieldWrapper label={t('Delivery Medium')} />
                        <EnumRadioButtonGroup
                          name="deliveryMedium"
                          options={deliveryMediumOptions}
                        />
                        <Box mt={2}>
                          <FieldWrapper label={t('Trigger Event')} />
                          <EnumRadioButtonGroup
                            name="displayTriggerType"
                            options={displayTriggerTypeOptions}
                            liStyle={{ width: '400px' }}
                          />
                          {values?.displayTriggerType ===
                            'ONLINE_GUIDE_PROXIMITY_TO_KEY_LOCATION' && (
                            <ProximityLocationEditor />
                          )}
                          {values?.displayTriggerType ===
                            'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION' && (
                            <Field name="triggerCouponIds">
                              {({ input }) => (
                                <MultiSelect
                                  label={t('Trigger Coupons')}
                                  search
                                  options={couponOptions}
                                  selectedValues={input.value}
                                  onChange={({ value }) =>
                                    input.onChange(value)
                                  }
                                />
                              )}
                            </Field>
                          )}
                        </Box>

                        <FieldWrapper label={t('Offer Item')}>
                          <EnumRadioButtonGroup
                            name="offerContentType"
                            options={offerTypeOptions}
                          />
                        </FieldWrapper>

                        {['PRODUCT', 'ANNUAL_PASS'].includes(
                          values?.offerContentType
                        ) && (
                          <>
                            <Field name="productIds">
                              {({ input }) => (
                                <Select
                                  label={t('Product')}
                                  search
                                  options={productOptions}
                                  value={
                                    input.value?.length ? input.value[0] : ''
                                  }
                                  onChange={(e, { value }) =>
                                    input.onChange([value])
                                  }
                                />
                              )}
                            </Field>
                            {!annualPassMode && (
                              <Box mt={2}>
                                <Field name="promoCode">
                                  {({ input }) => (
                                    <Input
                                      label={t('Promo Code (optional)')}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </Box>
                            )}

                            {config.enableMaRevamp && (
                              <Box mt={2}>
                                <FieldWrapper label={t('Display Type')}>
                                  <EnumRadioButtonGroup
                                    name="displayType"
                                    options={displayTypeOptions}
                                  />
                                </FieldWrapper>
                              </Box>
                            )}
                          </>
                        )}
                        {values?.offerContentType === 'COUPON' && (
                          <>
                            <Field name="couponId">
                              {({ input }) => (
                                <Select
                                  label={t('Coupon')}
                                  search
                                  options={couponOptions}
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                />
                              )}
                            </Field>
                            <Field name="thumbnailUrl">
                              {({ input }: FieldRenderProps<string>) => (
                                <FieldWrapper
                                  label={t('Thumbnail image (jpg, png)')}
                                >
                                  <ImageVideoAudioInput
                                    fileUrls={input.value ? [input.value] : []}
                                    onChange={(newValue) =>
                                      newValue?.length
                                        ? input.onChange(newValue[0])
                                        : input.onChange('')
                                    }
                                    maxFileCount={1}
                                    disableYoutubeVideos
                                  />
                                </FieldWrapper>
                              )}
                            </Field>
                          </>
                        )}

                        {values?.offerContentType === 'PRODUCT_LIST' && (
                          <>
                            <Field name="productIds">
                              {({ input }) => (
                                <DraggableProductList
                                  productIds={input.value}
                                  setProductIds={input.onChange}
                                  options={productOptions}
                                  label={t('Product List')}
                                />
                              )}
                            </Field>
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th>{t('Conditions')}</th>
                      <td>
                        <ConditionRuleListEditor />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>

              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
