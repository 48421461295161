export const getEmbeddedReviewUrl = (
  apiKey: string | null,
  productId: string,
  embedTypePath: 'stars' | 'reviewSummary' | 'reviewSummaryRecent',
  language?: string
): string => {
  const bookingWidgetBaseUrl = window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'https://book.');

  if (bookingWidgetBaseUrl && apiKey) {
    if (productId) {
      const params = new URLSearchParams();

      if (language) {
        params.set('lng', language);
      }

      return `${bookingWidgetBaseUrl}/embed/${apiKey}/products/${productId}/${embedTypePath}?${params.toString()}`;
    }
  }

  return '';
};
