import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';

type Props = {
  data: GraphDataItem[];
  barDataKeys: string[];
  names: Record<string, string>;
};
export const ChartPane = ({ data, barDataKeys, names }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          label={{
            value: names[barDataKeys[0]],
            position: 'left',
            angle: -90,
          }}
          allowDecimals={false}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickFormatter={(value) => {
            return value;
          }}
          stroke={'#EC4899'}
          label={{
            value: names['reservationCount'],
            position: 'right',
            angle: 90,
          }}
          allowDecimals={false}
        />
        <Tooltip
          formatter={(value: any) => {
            return value;
          }}
        />
        <Legend />
        {barDataKeys.map((barDataKey, idx) => {
          let color = '#3B82F6';
          if (idx !== 0) {
            color = '#93C5FD';
          }
          return (
            <Bar
              key={idx}
              dataKey={barDataKey}
              fill={color}
              name={names[barDataKey]}
            />
          );
        })}
        <Line
          legendType={'circle'}
          dataKey={'reservationCount'}
          yAxisId="right"
          stroke={'#EC4899'}
          strokeWidth={3}
          name={names['reservationCount']}
          {...{ connectNull: true }}
          activeDot={{
            r: 8,
          }}
          dot={{
            fill: '#EC4899',
            r: 3,
          }}
          type="monotone"
        />
        {barDataKeys.length === 2 && (
          <Line
            legendType={'circle'}
            dataKey={'compareReservationCount'}
            yAxisId="right"
            stroke={'#EC4899'}
            strokeWidth={3}
            strokeDasharray={'5 5'}
            name={names['compareReservationCount']}
            {...{ connectNull: true }}
            activeDot={{
              r: 8,
            }}
            type="monotone"
            dot={{
              fill: '#EC4899',
              r: 3,
            }}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
