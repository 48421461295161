import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MARKETING_AUTOMATION_CONTENTS_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CONTENTS_FAILURE,
  FETCH_MARKETING_AUTOMATION_CONTENTS_REQUEST,
  FETCH_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CONTENT_FAILURE,
  FETCH_MARKETING_AUTOMATION_CONTENT_REQUEST,
  DELETE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  DELETE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  DELETE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  CREATE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  UPDATE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  UPDATE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  UPDATE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  CREATE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  CREATE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { MarketingAutomationContentParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMarketingAutomationContentsRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENTS_REQUEST,
});

const fetchMarketingAutomationContentsSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENTS_SUCCESS,
  response,
});

const fetchMarketingAutomationContentsFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENTS_FAILURE,
  error,
});

let fetchMarketingAutomationContentsCancel: () => void | undefined;
export const fetchMarketingAutomationContents =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchMarketingAutomationContentsCancel?.();
    dispatch(fetchMarketingAutomationContentsRequest());
    axios
      .get('/api/marketingautomationcontents', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchMarketingAutomationContentsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationContentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMarketingAutomationContentsFailure('canceled'));
        dispatch(fetchMarketingAutomationContentsFailure(error));
      });
  };

const fetchMarketingAutomationContentRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENT_REQUEST,
});

const fetchMarketingAutomationContentSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  response,
});

const fetchMarketingAutomationContentFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CONTENT_FAILURE,
  error,
});

export const fetchMarketingAutomationContent =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMarketingAutomationContentRequest());
    axios
      .get(`/api/marketingautomationcontents/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationContentSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMarketingAutomationContentFailure('canceled'));
        else dispatch(fetchMarketingAutomationContentFailure(error));
      });
  };

const deleteMarketingAutomationContentRequest = (id: string) => ({
  type: DELETE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  id,
});

const deleteMarketingAutomationContentSuccess = (
  response: any,
  id: string
) => ({
  type: DELETE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  response,
  id,
});

const deleteMarketingAutomationContentFailure = (error: any) => ({
  type: DELETE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  error,
});

export const deleteMarketingAutomationContent =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMarketingAutomationContentRequest(id));
    axios
      .delete(`/api/marketingautomationcontents/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteMarketingAutomationContentSuccess(response.data, id));
      })
      .catch((error) =>
        dispatch(deleteMarketingAutomationContentFailure(error))
      );
  };

const createMarketingAutomationContentRequest = (
  newMarketingAutomationContent: MarketingAutomationContentParams
) => ({
  type: CREATE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  newMarketingAutomationContent,
});

const createMarketingAutomationContentSuccess = (response: any) => ({
  type: CREATE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  response,
});

const createMarketingAutomationContentFailure = (error: any) => ({
  type: CREATE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  error,
});

export const createMarketingAutomationContent =
  (newMarketingAutomationContent: MarketingAutomationContentParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      createMarketingAutomationContentRequest(newMarketingAutomationContent)
    );
    return axios
      .post('/api/marketingautomationcontents', newMarketingAutomationContent, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createMarketingAutomationContentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createMarketingAutomationContentFailure(err.message));
      });
  };

const updateMarketingAutomationContentRequest = (
  id: string,
  patch: MarketingAutomationContentParams
) => ({
  type: UPDATE_MARKETING_AUTOMATION_CONTENT_REQUEST,
  id,
  patch,
});

const updateMarketingAutomationContentSuccess = (response: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_CONTENT_SUCCESS,
  response,
});

const updateMarketingAutomationContentFailure = (error: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_CONTENT_FAILURE,
  error,
});

export const updateMarketingAutomationContent =
  (id: string, patch: MarketingAutomationContentParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMarketingAutomationContentRequest(id, patch));
    return axios
      .patch(`/api/marketingautomationcontents/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateMarketingAutomationContentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateMarketingAutomationContentFailure(err.message));
      });
  };
