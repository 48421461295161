import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_REDEEMED_RECORDS_REQUEST,
  FETCH_REDEEMED_RECORDS_SUCCESS,
  FETCH_REDEEMED_RECORDS_FAILURE,
  DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE,
  DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST,
  DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  ListRedeemedRecordsResponse,
  ListRedeemedRecordsRequest,
  DownloadRedeemedRecordCSVRequest,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchRedeemedRecordsRequest = () => ({
  type: FETCH_REDEEMED_RECORDS_REQUEST,
});

const fetchRedeemedRecordsSuccess = (
  response: ListRedeemedRecordsResponse
) => ({
  type: FETCH_REDEEMED_RECORDS_SUCCESS,
  response,
});

const fetchRedeemedRecordsFailure = (error: any) => ({
  type: FETCH_REDEEMED_RECORDS_FAILURE,
  error,
});

export const fetchRedeemedRecords =
  (queryParams?: ListRedeemedRecordsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchRedeemedRecordsRequest());
    return axios
      .get('/api/redeemedrecords', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchRedeemedRecordsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchRedeemedRecordsFailure(err.message));
      });
  };

const downloadRedeemedRecordCSVRequest = () => ({
  type: DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST,
});

const downloadRedeemedRecordCSVSuccess = () => ({
  type: DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS,
});

const downloadRedeemedRecordCSVFailure = (error: string) => ({
  type: DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE,
  error,
});

export const downloadRedeemedRecordCSV =
  (queryParams?: DownloadRedeemedRecordCSVRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadRedeemedRecordCSVRequest());
    axios
      .get(`/api/redeemedrecordcsv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'redeemed_records.csv');
        dispatch(downloadRedeemedRecordCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          downloadRedeemedRecordCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
