import * as React from 'react';

import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import type { SourceLanguage } from 'shared/models/swagger';

// TranslationLanguageContext holds the current language for translation
export const TranslationLanguageContext =
  React.createContext<SourceLanguage | null>(null);
export const useTranslationTargetLanguage = (t: TranslateFuncType) => {
  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );
  let translationTargetLanguageName = '';

  if (translationTargetLanguage) {
    translationTargetLanguageName = getLanguageName(
      uppercaseIsoToLowercaseIso[translationTargetLanguage],
      t
    );
  }

  return {
    translationTargetLanguage,
    translationTargetLanguageName,
  };
};
