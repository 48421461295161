import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  activeUserOrganizationSelector,
  accountsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import type { ResourceType } from 'client/libraries/util/resourceType';
import { getColumns } from 'client/components/ManifestReservationsTable/util';
import { ManifestCustomTable } from 'client/pages/Manifest/ManifestCustomTable';
import type { Column as CustomTableColumn } from 'client/pages/Manifest/ManifestCustomTable';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
} from 'shared/models/swagger';

type Props = {
  excludedFormFieldKeys: string[];
  loading?: boolean;
  products: ProductSummary[];
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  startTime?: string;
  totalPax?: number;
  open?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  onEditResourceButtonClick?: (arg0: string) => void;
  onResourceTextClick?: (
    arg0: string,
    arg1: ResourceType,
    arg2: string
  ) => void;
  showDispatchColumn?: boolean;
  capacity?: number;
  memoKey?: string | null;
};

const defaultProducts: ProductSummary[] = [];

export const ManifestReservationsTable = ({
  excludedFormFieldKeys,
  products = defaultProducts,
  reservations,
  visibleColumns,
  startTime,
  totalPax,
  open,
  customizedColumnNames,
  onEditResourceButtonClick,
  onResourceTextClick,
  showDispatchColumn,
  capacity,
  memoKey,
}: Props) => {
  const [columns, setColumns] = React.useState<CustomTableColumn[]>([]);
  const { t } = useTranslation();
  const accounts = useSelector(accountsSelector);
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );
  const shouldShowCheckinInfo = hasSubscription(
    activeUserOrganization,
    'feature-qr-checkin'
  );
  const checkedInGuestCount =
    _.sum(
      reservations.map((reservation) => {
        if ((reservation.package_component_reservations ?? []).length > 0) {
          const checkedInGuestsByPackageComponent: Record<string, number> = {};

          for (const packageComponentReservation of reservation.package_component_reservations) {
            checkedInGuestsByPackageComponent[
              packageComponentReservation.reservation_id
            ] = 0;
          }

          for (const checkinRecord of reservation?.checkin_info
            ?.checkin_records ?? []) {
            if (checkinRecord.package_component_reservation_id) {
              checkedInGuestsByPackageComponent[
                checkinRecord.package_component_reservation_id
              ] += checkinRecord.guest_count ?? 0;
            } else {
              for (const reservationId of Object.keys(
                checkedInGuestsByPackageComponent
              )) {
                checkedInGuestsByPackageComponent[reservationId] +=
                  checkinRecord.guest_count ?? 0;
              }
            }
          }

          let minCheckedInGuestCount = (reservation?.guests ?? []).length;

          for (const productId of Object.keys(
            checkedInGuestsByPackageComponent
          )) {
            if (
              checkedInGuestsByPackageComponent[productId] <
              minCheckedInGuestCount
            ) {
              minCheckedInGuestCount =
                checkedInGuestsByPackageComponent[productId];
            }
          }

          return minCheckedInGuestCount;
        } else {
          return Math.min(
            _.sum(
              (reservation?.checkin_info?.checkin_records ?? []).map(
                (record) => record.guest_count ?? 0
              )
            ),
            (reservation?.guests ?? []).length
          );
        }
      })
    ) ?? 0;
  React.useEffect(() => {
    const columns = getColumns(
      locale,
      t,
      excludedFormFieldKeys,
      products,
      reservations,
      showDispatchColumn ? ['edit', 'dispatch'] : ['edit'],
      visibleColumns,
      customizedColumnNames || [],
      guideAccountShapes || [],
      null,
      null,
      activeUser,
      onEditResourceButtonClick,
      onResourceTextClick
    ).map((c) => {
      return c as CustomTableColumn;
    });
    setColumns(columns);
  }, [
    locale,
    t,
    excludedFormFieldKeys,
    products,
    visibleColumns,
    reservations,
  ]);
  return (
    <ManifestCustomTable
      items={reservations}
      columns={columns}
      startTime={startTime}
      totalPax={totalPax}
      open={open}
      capacity={capacity}
      checkedInGuestCount={shouldShowCheckinInfo ? checkedInGuestCount : null}
      memoKey={memoKey}
    />
  );
};
