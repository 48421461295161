import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Select } from 'client/components/Form';
import { getLanguageName, contentLanguageOptions } from 'client/libraries/i18n';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import baseStyles from 'client/base.module.css';
import type { SourceLanguage } from 'shared/models/swagger';

type Props = {
  showTranslationMode: boolean;
  onShowTranslationModeChange: (arg0: boolean) => void;
  translationTargetLanguage: SourceLanguage;
  onTranslationTargetLanguageChange: (arg0: SourceLanguage) => void;
};

export const SettingTranslationLanguageSelector = ({
  showTranslationMode,
  onShowTranslationModeChange,
  translationTargetLanguage,
  onTranslationTargetLanguageChange,
}: Props) => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const supportLanguages = React.useMemo(
    () => getBookingWidgetPmpSupportLanguages(activeUserOrganization),
    [activeUserOrganization]
  );

  React.useEffect(() => {
    if (supportLanguages.length > 0) {
      onTranslationTargetLanguageChange(
        supportLanguages.find(
          (supportedLanguage) => supportedLanguage !== defaultLanguage
        ) || defaultLanguage
      );
    }
  }, []);

  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));

  const selectableLanguageOptions = languageOptions.filter((option) => {
    return (
      supportLanguages.includes(option.value) &&
      defaultLanguage !== option.value
    );
  });

  return (
    <>
      {(selectableLanguageOptions?.length || 0) > 0 && (
        <div className={baseStyles['base-main__body__header']}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spSpacebetween'],
              baseStyles['spOrder-1']
            )}
          >
            <div className={baseStyles['base-form-toggle']}>
              <label className={baseStyles['disabled']}>
                <input
                  type="checkbox"
                  checked={showTranslationMode}
                  onClick={() =>
                    onShowTranslationModeChange(!showTranslationMode)
                  }
                />
                <p></p>
              </label>
              {t('Translation mode')}
            </div>
            {showTranslationMode && (
              <Select
                width={176}
                options={selectableLanguageOptions}
                onChange={(e, { value }) => {
                  onTranslationTargetLanguageChange(value as SourceLanguage);
                }}
                value={translationTargetLanguage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
