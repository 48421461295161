import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Box } from 'client/components/Box/Box';
import { removeReservationNotification } from 'client/actions/notifications';
import type { Notification } from 'client/reducers/notifications';
import { ReduxState } from 'client/reducers';

import styles from './ReservationNotificationList.module.css';
import { NotificationTypeChip } from './NotificationTypeChip';

type Props = {
  notification: Notification;
};
export const ReservationNotificationListElement = ({ notification }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div
      className={clsx(styles['snackbar'])}
      onClick={(e) => {
        dispatch(removeReservationNotification(notification.payload?.task_id));
        history.push(`/reservations/${notification.payload?.reservation_id}`);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        display="flex"
        className={styles['snackbar-header']}
        alignItems="center"
      >
        <NotificationTypeChip
          type={notification.payload?.reservation_notification_type}
        />
        <a
          className={clsx(styles['snackbar-close'])}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(removeReservationNotification(notification.id));
          }}
        />
      </Box>
      <Box mt={1}>
        <div className={styles['snackbar-ellipsis']}>
          {t('Application Number: {{agent_reference}}', {
            agent_reference: notification.payload?.reservation_agent_reference,
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Status: {{status}}', {
            status: t(notification.payload?.reservation_status),
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Product Name: {{productName}}', {
            productName: notification.payload?.internal_product_name,
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Participation: {{participation}}', {
            participation: moment(
              notification.payload?.reservation_start_date_time_local
            )
              .locale(locale)
              .format('lll'),
          })}
        </div>
        <div className={styles['snackbar-ellipsis']}>
          {t('Booking Source: {{bookingSource}}', {
            bookingSource:
              notification.payload?.booking_source_type === 'AGENT'
                ? notification.payload?.booking_source_agent_name
                : t(notification.payload?.booking_source_type),
          })}
        </div>
      </Box>
    </div>
  );
};
