import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  CREATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  DELETE_PRODUCT_AGENT_REFERENCE_FAILURE,
  DELETE_PRODUCT_AGENT_REFERENCE_REQUEST,
  DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST,
  FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST,
  FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS,
  UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  ProductAgentReference$Input,
  ProductAgentReferencePatch,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchProductAgentReferencesRequest = () => ({
  type: FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST,
});

const fetchProductAgentReferencesSuccess = (response: any) => ({
  type: FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS,
  response,
});

const fetchProductAgentReferencesFailure = (error: any) => ({
  type: FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE,
  error,
});

export const fetchProductAgentReferencesByProductId =
  (product_id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductAgentReferencesRequest());
    fetch(`/api/productagentreferences?product_id=${product_id}`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchProductAgentReferencesSuccess(result)))
      .catch((error) => dispatch(fetchProductAgentReferencesFailure(error)));
  };

const fetchProductAgentReferenceByIDRequest = (id: string) => ({
  type: FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST,
  id,
});

const fetchProductAgentReferenceByIDSuccess = (response: any) => ({
  type: FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS,
  response,
});

const fetchProductAgentReferenceByIDFailure = (error: any) => ({
  type: FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE,
  error,
});

export const fetchProductAgentReferenceByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductAgentReferenceByIDRequest(id));
    return fetch(`/api/productagentreferences/${id}`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchProductAgentReferenceByIDSuccess(result)))
      .catch((error) => dispatch(fetchProductAgentReferenceByIDFailure(error)));
  };

const deleteProductAgentReferenceRequest = (id: string) => ({
  type: DELETE_PRODUCT_AGENT_REFERENCE_REQUEST,
  id,
});

const deleteProductAgentReferenceSuccess = (response: any, id: string) => ({
  type: DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  response,
  id,
});

const deleteProductAgentReferenceFailure = (error: any) => ({
  type: DELETE_PRODUCT_AGENT_REFERENCE_FAILURE,
  error,
});

export const deleteProductAgentReference =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteProductAgentReferenceRequest(id));
    fetch(`/api/productagentreferences/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) =>
        dispatch(deleteProductAgentReferenceSuccess(result, id))
      )
      .catch((error) => dispatch(deleteProductAgentReferenceFailure(error)));
  };

const createProductAgentReferenceRequest = (
  newProductAgentReference: ProductAgentReference$Input
) => ({
  type: CREATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  newProductAgentReference,
});

const createProductAgentReferenceSuccess = (response: any) => ({
  type: CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  response,
});

const createProductAgentReferenceFailure = (error: any) => ({
  type: CREATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  error,
});

export const createProductAgentReference =
  (newProductAgentReference: ProductAgentReference$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createProductAgentReferenceRequest(newProductAgentReference));
    return fetch('/api/productagentreferences', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newProductAgentReference),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(createProductAgentReferenceSuccess(result)))
      .catch((error) => dispatch(createProductAgentReferenceFailure(error)));
  };

const updateProductAgentReferenceRequest = (
  id: string,
  patch: ProductAgentReferencePatch
) => ({
  type: UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST,
  id,
  patch,
});

const updateProductAgentReferenceSuccess = (response: any) => ({
  type: UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS,
  response,
});

const updateProductAgentReferenceFailure = (error: any) => ({
  type: UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE,
  error,
});

export const updateProductAgentReference =
  (id: string, patch: ProductAgentReferencePatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateProductAgentReferenceRequest(id, patch));
    return axios
      .patch(`/api/productagentreferences/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(updateProductAgentReferenceSuccess(result.data))
      )
      .catch((error) => {
        dispatch(updateProductAgentReferenceFailure(error));
      });
  };
