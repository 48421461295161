import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { Input } from 'client/components/Form';
import { Modal } from 'client/components/v3/Form/Modal';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { Button } from 'client/components/v3/Common/Button';

type FormValues = {
  toAddress?: string;
};
type Props = {
  initialToAddress?: string;
  onSubmit: (toAddress?: string) => void;
  open: boolean;
  onClose: () => void;
};

export const SendMediaDownloadOfferEmailModal = ({
  initialToAddress,
  onSubmit,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const initialValues = React.useMemo(
    (): FormValues => ({
      toAddress: initialToAddress,
    }),
    [initialToAddress]
  );
  const validateEmailAddress = React.useCallback(
    (email?: any) => {
      if (!email) return t('Required');
      if (!matchesFormat(email, 'email')) return t('Invalid Email');
      return undefined;
    },
    [t]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        await onSubmit(values.toAddress);
        await onClose();
      }}
    >
      {({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={t('Send Photo Download Offer Email')}
            open={open}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  color="white"
                  text={t('Cancel')}
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                <Button
                  text={t('Send')}
                  color="primary"
                  onClick={() => {
                    form.submit();
                    onClose();
                  }}
                  loading={submitting}
                />
              </>
            }
          >
            <>
              <Field name="toAddress" validate={validateEmailAddress}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    label={t('Email Address')}
                    disabled={Boolean(initialToAddress)}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </>
          </Modal>
        </form>
      )}
    </Form>
  );
};
