import * as React from 'react';
import {
  makeMoveable,
  DraggableProps,
  ScalableProps,
  Draggable,
  Scalable,
} from 'react-moveable';
import MoveableHelper from 'moveable-helper';

export type ChildRenderProps = {
  targetRef: React.RefObject<any>;
  wrapperRef: React.RefObject<any>;
  onZoomInClick?: () => void;
  onZoomOutClick?: () => void;
  onReseatScale?: () => void;
};

interface Props {
  children?: (props: ChildRenderProps) => React.ReactNode;
}

const Moveable = makeMoveable<DraggableProps & ScalableProps>([
  Draggable,
  Scalable,
]);

export const ZoomablePane = ({ children }: Props) => {
  const [helper] = React.useState(() => {
    return new MoveableHelper();
  });
  const [scale, setScale] = React.useState<number[]>([1, 1]);
  const [translate, setTranslate] = React.useState<number[]>([0, 0]);
  const [dimensions, setDimensions] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [scaleInitialized, setScaleInitialized] =
    React.useState<boolean>(false);

  const targetRef = React.useRef<any>(null);
  const wrapperRef = React.useRef<any>(null);

  React.useEffect(() => {
    const targetElement = targetRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      if (
        dimensions.width === entries[0].target.clientWidth &&
        dimensions.height === entries[0].target.clientHeight
      ) {
        return;
      }
      setDimensions({
        width: entries[0].target.clientWidth,
        height: entries[0].target.clientHeight,
      });
    });

    if (targetElement) {
      resizeObserver.observe(targetElement);
    }

    return () => {
      resizeObserver.unobserve(targetElement);
    };
  }, [targetRef]);

  React.useEffect(() => {
    const wrapperWidth = wrapperRef?.current?.clientWidth;
    const targetWidth = dimensions.width;

    if (!wrapperWidth || !targetWidth) {
      return;
    }

    const frame = helper.createFrame(targetRef.current);

    if (!scaleInitialized) {
      initializeScaleAndTransform(wrapperWidth, targetWidth, dimensions, frame);
    } else {
      updateTransform(wrapperWidth, targetWidth, frame);
    }
  }, [dimensions]);

  function initializeScaleAndTransform(
    wrapperWidth: number,
    targetWidth: number,
    dimensions: { width: number; height: number },
    frame: any
  ) {
    const wrapperHeight = wrapperRef?.current?.clientHeight;
    const targetHeight = dimensions.height;
    const scaleX = (wrapperWidth * 0.7) / targetWidth;
    const scaleY = wrapperHeight / targetHeight;
    const scale = Math.min(scaleX, scaleY);
    const xOffset = (wrapperWidth - targetWidth * scale) / 2;
    const left = (targetWidth / 2) * scale - targetWidth / 2 + xOffset;
    const top = (targetHeight / 2) * scale - targetHeight / 2;
    const translateX = `${left}px`;
    const translateY = `${top}px`;

    frame.set('transform', 'translate', `${translateX},${translateY}`);
    frame.set('transform', 'scale', `${scale},${scale}`);
    helper.render(targetRef.current, frame);
    setScale([scale, scale]);
    setTranslate([left, top]);
    setScaleInitialized(true);
  }

  function updateTransform(
    wrapperWidth: number,
    targetWidth: number,
    frame: any
  ) {
    const left = (wrapperWidth - targetWidth) / 2;
    const top = 0;
    const translateX = `${left}px`;
    const translateY = `${top}px`;

    frame.set('transform', 'translate', `${translateX},${translateY}`);
    helper.render(targetRef.current, frame);
    setTranslate([left, top]);
  }

  const onZoomInClick = () => {
    const newScale = [scale[0] + 0.1, scale[1] + 0.1];

    if (targetRef.current) {
      const frame = helper.createFrame(targetRef.current);
      const translateX = `${translate[0]}px`;
      const translateY = `${translate[1]}px`;
      frame.set('transform', 'translate', `${translateX},${translateY}`);
      frame.set('transform', 'scale', `${newScale[0]},${newScale[1]}`);
      helper.render(targetRef.current, frame);
      setScale(newScale);
    }
  };

  const onZoomOutClick = () => {
    const newScale = [scale[0] - 0.1, scale[1] - 0.1];

    if (targetRef.current) {
      const frame = helper.createFrame(targetRef.current);
      const translateX = `${translate[0]}px`;
      const translateY = `${translate[1]}px`;
      frame.set('transform', 'translate', `${translateX},${translateY}`);
      frame.set('transform', 'scale', `${newScale[0]},${newScale[1]}`);
      helper.render(targetRef.current, frame);
      setScale(newScale);
    }
  };

  const onReseatScale = () => {
    setScaleInitialized(false);
  };

  return (
    <>
      {children?.({
        targetRef,
        wrapperRef,
        onZoomInClick,
        onZoomOutClick,
        onReseatScale,
      })}
      <Moveable
        target={targetRef}
        draggable={true}
        keepRatio={true}
        onDragStart={(props) => {
          helper.onDragStart(props);
        }}
        onDrag={(props) => {
          setTranslate(props.translate.slice(0, 2));
          helper.onDrag(props);
        }}
        hideDefaultLines={true}
      />
    </>
  );
};
