import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { FalconUIRenderingContext, Section } from '@nutmeglabs/falcon-ui';

import { Add } from 'client/components/Icons/Add';
import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper, Radio } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import {
  getDefaultInfoSection,
  getDefaultProductListSection,
} from '../useTemplateOptions';

export interface Props {
  index: number;
  excludedSectionTypes?: Section['type'][];
}

export const AddSectionButton = ({ index }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [sectionType, setSectionType] =
    React.useState<Section['type']>('HIGHLIGHTS');
  const { t } = useTranslation();

  const organization = useSelector(activeUserOrganizationSelector);
  const products = useSelector((state: ReduxState) => state.products.summaries);

  const loading = useSelector((state: ReduxState) => state.products.loading);

  const form = useForm();

  const onChange = (newSection: Section) => {
    const newSections = [...form.getState().values.sections];
    newSections.splice(index, 0, newSection);
    form.change('sections', newSections);
  };

  const topPageCtx = React.useContext(FalconUIRenderingContext);

  const options: { label: string; value: Section['type'] }[] = [
    {
      label: t('Highlights'),
      value: 'HIGHLIGHTS',
    },
    {
      label: t('Product List'),
      value: 'PRODUCT_LIST',
    },
    {
      label: t('Menu Tiles'),
      value: 'MENU_TILES',
    },
    {
      label: t('Checkerboard Tiles'),
      value: 'CHECKERBOARD_TILES',
    },
    {
      label: t('Icon Tiles'),
      value: 'ICON_TILES',
    },
    {
      label: t('Image Tiles'),
      value: 'IMAGE_TILES',
    },
    {
      label: t('Image Gallery'),
      value: 'IMAGE_GALLERY',
    },
    {
      label: t('Editor.JS'),
      value: 'EDITORJS',
    },
  ];

  return (
    <>
      <Add onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Insert Section')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Modal.Content>
            <FieldWrapper label={t('Section Type')}>
              {options.map((option) => (
                <Radio
                  key={option.value}
                  label={option.label}
                  checked={sectionType === option.value}
                  onChange={() =>
                    setSectionType(option.value as Section['type'])
                  }
                />
              ))}
            </FieldWrapper>
          </Modal.Content>
          <Modal.Actions>
            <Button.Update
              loading={loading}
              onClick={() => {
                onChange(
                  sectionType === 'PRODUCT_LIST'
                    ? getDefaultProductListSection(
                        organization,
                        products,
                        topPageCtx.language,
                        topPageCtx.t
                      )
                    : getDefaultInfoSection(sectionType, topPageCtx.t)
                );
                setShowModal(false);
              }}
            >
              {t('Insert')}
            </Button.Update>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
