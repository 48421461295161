import { FormValues } from 'client/libraries/util/resourceManager';
import type { DispatchResource } from 'shared/models/swagger';

export const getInitialValues = (
  resource: DispatchResource | null
): FormValues => {
  return {
    unitType: resource?.availability?.unit_type || 'PER_BOOKING',
    capacityPerUnit: resource?.availability?.capacity_per_unit ?? 1,
    availabilitySchedules: (resource?.availability?.schedules ?? []).map(
      (schedule) => ({
        startDateLocal: schedule.start_date_local ?? '',
        endDateLocal: schedule.end_date_local ?? '',
        quantity: schedule.quantity ?? 1,
        addOns: (schedule.add_ons ?? []).map((addOn) => ({
          productId: addOn.product_id ?? '',
          addOnKey: addOn.add_on_key ?? '',
        })),
        productIds: schedule.product_ids ?? [],
      })
    ),
  };
};

export const convertFormValuesToDispatchResources = (
  values: FormValues,
  resourceKey: string | null,
  resources: DispatchResource[]
): DispatchResource[] => {
  const availability = {
    unit_type: values.unitType,
    capacity_per_unit: Number(values.capacityPerUnit),
    schedules: values.availabilitySchedules?.map((schedule) => ({
      start_date_local: schedule.startDateLocal,
      end_date_local: schedule.endDateLocal,
      quantity: Number(schedule.quantity),
      add_ons: (schedule.addOns ?? []).map((addOn: any) => ({
        product_id: addOn.productId,
        add_on_key: addOn.addOnKey,
      })),
      product_ids: schedule.productIds,
    })),
  };

  const resource = resources.find((resource) => resource.key === resourceKey);

  if (resource) {
    resource.availability = availability;
  }

  return resources;
};
