import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import { accountsSelector } from 'client/reducers/accounts';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { deleteAccount } from 'client/actions/accounts';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { ReduxState } from 'client/reducers';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Account } from 'shared/models/swagger';

import { EditAccountModal } from './EditAccountModal';

const EditDeleteCell = ({ account }: { account: Account }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state: ReduxState) => state.accounts.loading);

  const [showEditAccountModal, setShowEditAccountModal] = React.useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    React.useState(false);

  return (
    <div>
      <Box display="flex">
        <EditIcon onClick={() => setShowEditAccountModal(true)} />
        <Box ml={1}>
          <DeleteIcon onClick={() => setShowDeleteAccountModal(true)} />
        </Box>
      </Box>
      {showEditAccountModal && (
        <EditAccountModal
          existingAccount={account}
          onClose={() => setShowEditAccountModal(false)}
        />
      )}
      {showDeleteAccountModal && (
        <DeleteConfirmModal
          loading={loading}
          header={t('Delete Account')}
          content={t('Are you sure you want to delete account?')}
          onConfirm={async () => {
            await dispatch(deleteAccount(account.id as string));
          }}
          onClose={() => setShowDeleteAccountModal(false)}
          open={showDeleteAccountModal}
          insertRoot
        />
      )}
    </div>
  );
};

const useColumns = (): ColumnType<Account>[] => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(activeUser, 'ACCOUNT.LIST')
      ? [
          {
            id: 'edit',
            Header: '',
            accessor: (row: Account) => <EditDeleteCell account={row} />,
          },
        ]
      : []),
    {
      id: 'email',
      Header: t('Email'),
      accessor: (row: Account) => row.email,
    },
    {
      id: 'name',
      Header: t('Name'),
      accessor: (row: Account) => row.name ?? '',
    },
    ...(activeUser?.role === 'ADMIN' || activeUser?.role === 'PRODUCT_EDITOR'
      ? [
          {
            id: '',
            Header: t('Two-Factor Authentication'),
            accessor: (row: Account) =>
              row.email_mfa ? t('Enabled') : t('Disabled'),
          },
        ]
      : []),
  ];
};

const ApiAccountNameMarker = '[<API>]';

export const AccountList = () => {
  const [showCreateAccountModal, setShowCreateAccountModal] =
    React.useState(false);
  const [currentPage, onPageChange] = React.useState(1);
  const [rowCount, onRowCountChange] = React.useState(10);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  const { t } = useTranslation();

  const accounts = useSelector(accountsSelector).filter(
    (acct) =>
      (!activeUserOrganization ||
        acct.organization_id === activeUserOrganization?.id) &&
      !acct?.name?.includes(ApiAccountNameMarker)
  );

  const items: Account[] = _.orderBy(accounts, [
    (account) => account?.name?.toLowerCase(),
  ]).slice((currentPage - 1) * rowCount, currentPage * rowCount);

  const columns = useColumns();

  return (
    <div>
      {hasCustomUserRoleWritePermissions(activeUser, 'ACCOUNT.LIST') && (
        <Box mb={2}>
          <Button.Create onClick={() => setShowCreateAccountModal(true)}>
            {t('Create New Account')}
          </Button.Create>
        </Box>
      )}
      {showCreateAccountModal && (
        <EditAccountModal onClose={() => setShowCreateAccountModal(false)} />
      )}
      <GenericTable<Account>
        items={items}
        columns={columns}
        rowCount={rowCount}
        onRowCountChange={onRowCountChange}
        currentPage={currentPage}
        onCurrentPageChange={onPageChange}
        totalCount={accounts.length}
      />
    </div>
  );
};
