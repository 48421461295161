import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FalconUIRenderingContext, Link, Menu } from '@nutmeglabs/falcon-ui';
import '@nutmeglabs/falcon-ui/dist/esm/index.css';
import { useTranslation } from 'react-i18next';

import { fetchProducts } from 'client/actions/products';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { SourceLanguage } from 'shared/models/swagger';

const ImageComponent = ({ src }: { src: string }) => {
  return (
    <img
      src={src}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        color: 'transparent',
      }}
    />
  );
};

const replaceJPYWithSymbol = (s: string): string => {
  if (s.includes('JPY')) {
    s = `${s.replace(/JPY\s*/, '')}円`;
    if (s === '0円') {
      return '無料';
    }
  }

  return s;
};

interface Props {
  children: React.ReactNode;
  language: SourceLanguage;
}

export const FalconUIContextProvider = ({ children, language }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const org = useSelector(activeUserOrganizationSelector);

  const supportedLanguages = getBookingWidgetPmpSupportLanguages(org);

  const pmp = org?.private_marketplaces?.find(
    (pmp) => pmp.language === language
  );

  const guestMyPageEnabled = hasSubscription(org, 'feature-guest-my-page');

  const languageOptions = (
    [
      {
        value: 'EN_US',
        text: 'English',
      },
      {
        value: 'JA_JP',
        text: '日本語',
      },
      {
        value: 'KO_KR',
        text: '한국어',
      },
      {
        value: 'ZH_CN',
        text: '简体中文',
      },
      {
        value: 'ZH_TW',
        text: '繁體中文',
      },
    ] as { value: SourceLanguage; text: string }[]
  ).filter((lang) => supportedLanguages.includes(lang.value as SourceLanguage));

  const websiteUrl = getBookingWebsiteUrl(org, language);

  return (
    <FalconUIRenderingContext.Provider
      value={{
        companyName: org?.name || '',
        companyLogoUrl: org?.logo_full_url || '',
        language,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        setLanguage: (lang: SourceLanguage) => {},
        supportedLanguages: languageOptions,
        t: (key: string, args?: Record<string, string>) => {
          const argsWithLng = args ?? {};
          argsWithLng.lng = uppercaseIsoToLowercaseIso[language];

          switch (key) {
            case 'Su':
              return t('Su', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'M':
              return t('M', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'Tu':
              return t('Tu', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'W':
              return t('W', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'Th':
              return t('Th', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'F':
              return t('F', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'Sa':
              return t('Sa', {
                lng: uppercaseIsoToLowercaseIso[language],
              });
            case 'Days of Week: {{daysOfWeek}}':
              return t('Days of Week: {{daysOfWeek}}', argsWithLng);
            case 'Hours: {{operatingHours}}':
              return t('Hours: {{operatingHours}}', argsWithLng);
          }

          return t(key, { lng: uppercaseIsoToLowercaseIso[language] });
        },
        formatCurrencyAmount: (s: string): string => {
          const formatted = formattedCurrencyAmount(s);

          if (language === 'JA_JP') {
            return replaceJPYWithSymbol(formatted);
          }

          return formatted;
        },
        linkFormatter: (link: Link): string => {
          if (!link) {
            return '';
          }
          switch (link.type) {
            case 'PRODUCT':
              return pmp
                ? `${websiteUrl}/top/products/${link.productId}?lng=${uppercaseIsoToLowercaseIso[language]}`
                : `${websiteUrl}/products/${link.productId}`;
            case 'CUSTOM_PAGE':
              return `${websiteUrl}${link.customPagePath}?lng=${uppercaseIsoToLowercaseIso[language]}`;
            case 'FULL_URL':
              return link.fullUrl || '';
            case 'PRIVATE_MARKETPLACE':
              return `${websiteUrl}${link.privateMarketplacePath}`;
            case 'LOGIN_PAGE':
              return `${websiteUrl}${'/me/signin'}`;
            default:
              return '';
          }
        },
        getMenuTitle: (menu: Menu): string => {
          if (menu.link?.type === 'LOGIN_PAGE') {
            if (guestMyPageEnabled) {
              return t('Login') + '/' + t('Reservations');
            } else {
              return t('Login');
            }
          }
          return menu.title;
        },
        Image: ImageComponent,
        getImageUrl: ({ src }: { src: string }): string => src,
      }}
    >
      {children}
    </FalconUIRenderingContext.Provider>
  );
};
