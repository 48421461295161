import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_INSTANT_WIN_FAILURE,
  CREATE_INSTANT_WIN_REQUEST,
  CREATE_INSTANT_WIN_SUCCESS,
  DELETE_INSTANT_WIN_FAILURE,
  DELETE_INSTANT_WIN_REQUEST,
  DELETE_INSTANT_WIN_SUCCESS,
  FETCH_INSTANT_WINS_FAILURE,
  FETCH_INSTANT_WINS_REQUEST,
  FETCH_INSTANT_WINS_SUCCESS,
  FETCH_INSTANT_WIN_FAILURE,
  FETCH_INSTANT_WIN_REQUEST,
  FETCH_INSTANT_WIN_SUCCESS,
  UPDATE_INSTANT_WIN_FAILURE,
  UPDATE_INSTANT_WIN_REQUEST,
  UPDATE_INSTANT_WIN_SUCCESS,
  GET_INSTANT_WIN_STATS_SUCCESS,
  DOWNLOAD_INSTANT_WIN_CSV_FAILURE,
  DOWNLOAD_INSTANT_WIN_CSV_REQUEST,
  DOWNLOAD_INSTANT_WIN_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  InstantWin,
  InstantWinStats,
  CreateInstantWinResponse,
  ListInstantWinsResponse,
  UpdateInstantWinResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_INSTANT_WINS_FAILURE:
    case FETCH_INSTANT_WIN_FAILURE:
    case CREATE_INSTANT_WIN_FAILURE:
    case UPDATE_INSTANT_WIN_FAILURE:
    case DELETE_INSTANT_WIN_FAILURE:
      return action.error;

    case FETCH_INSTANT_WINS_SUCCESS:
    case FETCH_INSTANT_WIN_SUCCESS:
    case CREATE_INSTANT_WIN_SUCCESS:
    case UPDATE_INSTANT_WIN_SUCCESS:
    case DELETE_INSTANT_WIN_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_INSTANT_WINS_REQUEST:
    case FETCH_INSTANT_WIN_REQUEST:
    case CREATE_INSTANT_WIN_REQUEST:
    case UPDATE_INSTANT_WIN_REQUEST:
    case DELETE_INSTANT_WIN_REQUEST:
      return true;

    case FETCH_INSTANT_WINS_FAILURE:
    case FETCH_INSTANT_WIN_FAILURE:
    case CREATE_INSTANT_WIN_FAILURE:
    case UPDATE_INSTANT_WIN_FAILURE:
    case DELETE_INSTANT_WIN_FAILURE:
    case FETCH_INSTANT_WINS_SUCCESS:
    case FETCH_INSTANT_WIN_SUCCESS:
    case CREATE_INSTANT_WIN_SUCCESS:
    case UPDATE_INSTANT_WIN_SUCCESS:
    case DELETE_INSTANT_WIN_SUCCESS:
      return false;

    default:
      return state;
  }
};

const allStats = (
  state: Record<string, InstantWinStats> = {},
  action: any
): Record<string, InstantWinStats> => {
  switch (action.type) {
    case GET_INSTANT_WIN_STATS_SUCCESS: {
      const response = action.response as InstantWinStats;
      const id = action.id as string;
      return { ...state, [id]: response };
    }
    default:
      return state;
  }
};

const all = (state: InstantWin[] = [], action: any): InstantWin[] => {
  switch (action.type) {
    case FETCH_INSTANT_WINS_SUCCESS: {
      const response = action.response as ListInstantWinsResponse;
      return response.instant_wins;
    }

    case FETCH_INSTANT_WIN_SUCCESS: {
      const response = action.response as InstantWin;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_INSTANT_WIN_SUCCESS: {
      const response = action.response as CreateInstantWinResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_INSTANT_WIN_SUCCESS: {
      const response = action.response as UpdateInstantWinResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_INSTANT_WIN_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case DOWNLOAD_INSTANT_WIN_CSV_REQUEST:
      return true;
    case DOWNLOAD_INSTANT_WIN_CSV_SUCCESS:
    case DOWNLOAD_INSTANT_WIN_CSV_FAILURE:
      return false;
    default:
      return state;
  }
};

export const instantWins = combineReducers({
  error,
  loading,
  all,
  allStats,
  csvLoading,
});
