import * as React from 'react';
import { Button, Form, Header, List, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';

import { organizationsSelector } from 'client/reducers/user';
import { createAccount, updateAccount } from 'client/actions/accounts';
import type { ReduxState } from 'client/reducers/index';
import type {
  Account,
  Account$Input,
  Account$Patch,
} from 'shared/models/swagger';

type OwnProps = {
  account?: Partial<Account>;
  trigger: React.ComponentType<any>;
  header?: string;
};
type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
type State = {
  showModal: boolean;
  account?: Partial<Account>;
};

type Dispatch = ThunkDispatch<any, any, any>;

class EditAccountModalComponent extends React.Component<Props, State> {
  static defaultProps = {
    account: {
      name: '',
      email: '',
      locale: 'en',
    },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      account: { ...props.account },
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.account !== this.props.account) {
      this.setState({
        account: this.props.account,
      });
    }
  }

  render() {
    const { organizations, trigger: Trigger, header, t } = this.props;
    const { account, showModal } = this.state;
    const alternateOrgName = account?.alternate_organization_name || '';
    const alternateOrgType = account?.alternate_organization_type || '';
    const agentRoleOptions = [
      'REVERSE_MAPPING_ADMIN',
      'ADMIN',
      'RESERVATIONIST',
    ].map((r) => ({
      key: r,
      text: r,
      value: r,
    }));
    const supplierRoleOptions = [
      'ADMIN',
      'RESERVATIONIST',
      'ACCOUNTANT',
      'GUIDE',
      'PRODUCT_EDITOR',
    ].map((r) => ({
      key: r,
      text: r,
      value: r,
    }));
    return (
      <Modal
        trigger={
          <span
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          >
            <Trigger />
          </span>
        }
        style={{
          marginTop: 0,
        }}
        open={showModal}
        onClose={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <Header icon="edit" content={header} />
        <Modal.Content>
          <Modal.Description>
            <Form>
              {account?.id && account?.email ? (
                <List>
                  <List.Item>{t('ID') + `: ${account?.id}`}</List.Item>
                  <List.Item>{t('Email') + `: ${account?.email}`}</List.Item>
                </List>
              ) : (
                <Form.Input
                  required
                  label={t('Email')}
                  value={account?.email || ''}
                  onChange={(e, { value }) =>
                    this.setState({
                      account: { ...account, email: value },
                    })
                  }
                />
              )}
              <Form.Input
                required
                label={t('Name')}
                value={account?.name || ''}
                onChange={(e, { value }) =>
                  this.setState({
                    account: { ...account, name: value },
                  })
                }
              />
              <Form.Select
                required
                search
                label={t('Organization')}
                value={`${account?.organization_type || ''}:${
                  account?.organization_id || ''
                }`}
                options={organizations.map((org) => ({
                  text: `${org.name || ''} (${org.type})`,
                  value: `${org.type}:${org.id}`,
                }))}
                onChange={(e, { value }) => {
                  const parts = (value as string).split(':');
                  const orgType = parts[0];
                  const roleOptions =
                    orgType === 'AGENT'
                      ? agentRoleOptions
                      : supplierRoleOptions;
                  let newRole = account?.role;

                  if (
                    newRole &&
                    !roleOptions.some((opt) => opt.value === newRole)
                  ) {
                    newRole = roleOptions[0].value as Account['role'];
                  }

                  this.setState({
                    account: {
                      ...account,
                      organization_type:
                        parts[0] as Account['organization_type'],
                      organization_id: parts[1],
                      role: newRole,
                    },
                  });
                }}
              />
              {account?.alternate_organization_name && (
                <Form.Field>
                  <label>{t('Alternate Organization')}</label>
                  {`${alternateOrgName} (${alternateOrgType})`}
                </Form.Field>
              )}
              <Form.Select
                required
                label={t('Role')}
                value={account?.role}
                options={
                  account?.organization_type === 'AGENT'
                    ? agentRoleOptions
                    : supplierRoleOptions
                }
                onChange={(e, { value }) =>
                  this.setState({
                    account: {
                      ...account,
                      role: value as Account['role'],
                    },
                  })
                }
              />
              <Form.Select
                required
                label={t('Language')}
                value={account?.locale}
                options={[
                  {
                    key: 'en',
                    value: 'en',
                    text: t('English'),
                  },
                  {
                    key: 'ja',
                    value: 'ja',
                    text: t('Japanese'),
                  },
                ]}
                onChange={(e, { value }) =>
                  this.setState({
                    account: { ...account, locale: value as string },
                  })
                }
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                account: this.props.account,
              });
            }}
            disabled={this.state.account === this.props.account}
          >
            {t('Discard Changes')}
          </Button>
          <Button
            primary
            disabled={
              !this.state.account?.name ||
              !this.state.account?.organization_id ||
              !this.state.account?.role ||
              !this.state.account?.email ||
              !this.state.account?.locale
            }
            onClick={() => {
              if (this.props.account?.id) {
                this.props.updateAccount(
                  this.props.account.id,
                  this.state.account as any
                );
              } else {
                this.props.createAccount(this.state.account as any);
              }

              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  organizations: organizationsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAccount: (account: Account$Input) => dispatch(createAccount(account)),
  updateAccount: (id: string, patch: Account$Patch) =>
    dispatch(updateAccount(id, patch)),
});

export const EditAccountModal = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(EditAccountModalComponent);
