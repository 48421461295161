import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import {
  Key,
  ReservationReportLineChartPane,
} from 'client/pages/v3/FlexibleDashboard/Chart/ReservationReportLineChartPane';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  convertReservationReportDataSetsToGraphData,
  getGraphColor,
} from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/util';
import { TranslateFuncType } from 'client/components/Translate';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { getDateRange } from 'client/pages/v3/FlexibleDashboard/util';
import { COMPARISON_COLOR } from 'client/pages/v3/FlexibleDashboard/Chart/util';

const getTargetType = (
  params: ReservationReportGadgetParams
): 'NUM_OF_RESERVATIONS' | 'PAX' | 'AMOUNT_GROSS' => {
  switch (params.dataType) {
    case 'RESERVATION_COUNT':
      return 'NUM_OF_RESERVATIONS';
    case 'PARTICIPANT_COUNT':
      return 'PAX';
    case 'SALES':
      return 'AMOUNT_GROSS';
  }
};

const getDataTypeName = (
  params: ReservationReportGadgetParams,
  t: TranslateFuncType
): string => {
  switch (params.dataType) {
    case 'RESERVATION_COUNT':
      return t('Total Reservations');
    case 'PARTICIPANT_COUNT':
      return t('Pax');
    case 'SALES':
      return t('Gross Sales');
  }
};

interface Props {
  params: ReservationReportGadgetParams;
  reportDataSets: Swagger.ReservationReportDataSet[];
}

export const ReservationReportLineChart = ({
  reportDataSets,
  params,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const currencyCode = activeUserOrganization?.default_currency || null;

  const graphData = convertReservationReportDataSetsToGraphData(
    reportDataSets ?? [],
    'TOTAL',
    getTargetType(params),
    params.aggregationType
  );

  const [baseRange, compareRange] = getDateRange(reportDataSets);

  let keys: Key[];
  if (reportDataSets.length === 1) {
    keys = [{ data: baseRange, color: getGraphColor(params) }];
  } else {
    keys = [
      { data: baseRange, color: getGraphColor(params) },
      { data: compareRange, color: COMPARISON_COLOR },
    ];
  }

  let names = {
    [baseRange]: t('Base Range'),
  };
  if (reportDataSets.length > 1) {
    names = {
      [baseRange]: t('Base Range'),
      [compareRange]: t('Compare Range'),
    };
  }

  return (
    <div
      className={styles['p-transition__body__graph']}
      style={{ height: '400px' }}
    >
      <ReservationReportLineChartPane
        data={graphData}
        keys={keys}
        names={names}
        currencyCode={currencyCode}
        dataTypeName={getDataTypeName(params, t)}
      />
    </div>
  );
};
