import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_EQUIPMENT_INSTANCES_SUCCESS,
  FETCH_EQUIPMENT_INSTANCES_FAILURE,
  FETCH_EQUIPMENT_INSTANCES_REQUEST,
  UPDATE_EQUIPMENT_INSTANCE_SUCCESS,
  UPDATE_EQUIPMENT_INSTANCE_FAILURE,
  UPDATE_EQUIPMENT_INSTANCE_REQUEST,
  CLEAR_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS,
  BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE,
  BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_EDIT_EQUIPMENT_INSTANCES_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { EquipmentInstancePatch } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchEquipmentInstancesRequest = () => ({
  type: FETCH_EQUIPMENT_INSTANCES_REQUEST,
});

const fetchEquipmentInstancesSuccess = (response: any) => ({
  type: FETCH_EQUIPMENT_INSTANCES_SUCCESS,
  response,
});

const fetchEquipmentInstancesFailure = (error: any) => ({
  type: FETCH_EQUIPMENT_INSTANCES_FAILURE,
  error,
});

export const fetchEquipmentInstances =
  (
    productInstanceId: string,
    equipmentId?: string,
    dateFromStart?: string,
    dateFromEnd?: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchEquipmentInstancesRequest());
    axios
      .get('/api/equipments/-/instances', {
        params: {
          product_instance_id: productInstanceId,
          equipment_id: equipmentId,
          start_date_local_from: dateFromStart,
          start_date_local_to: dateFromEnd,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchEquipmentInstancesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchEquipmentInstancesFailure('canceled'));
        else dispatch(fetchEquipmentInstancesFailure(error));
      });
  };

const updateEquipmentInstanceRequest = () => ({
  type: UPDATE_EQUIPMENT_INSTANCE_REQUEST,
});

const updateEquipmentInstanceSuccess = (response: any) => ({
  type: UPDATE_EQUIPMENT_INSTANCE_SUCCESS,
  response,
});

const updateEquipmentInstanceFailure = (error: any) => ({
  type: UPDATE_EQUIPMENT_INSTANCE_FAILURE,
  error,
});

export const updateEquipmentInstance =
  (equipmentInstancePatch: EquipmentInstancePatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateEquipmentInstanceRequest());
    axios
      .patch('/api/equipments/-/instances', equipmentInstancePatch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(updateEquipmentInstanceSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(updateEquipmentInstanceFailure('canceled'));
        else dispatch(updateEquipmentInstanceFailure(error));
      });
  };

const clearEquipmentInstancesRequest = () => ({
  type: CLEAR_EQUIPMENT_INSTANCES_REQUEST,
});

export const clearEquipmentInstances = () => (dispatch: Dispatch) => {
  dispatch(clearEquipmentInstancesRequest());
};

const batchCloseEquipmentInstancesRequest = (
  dateFromStart: string,
  dateFromEnd: string,
  equipmentIds: string[]
) => ({
  type: BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST,
  dateFromStart,
  dateFromEnd,
  equipmentIds,
});

const batchCloseEquipmentInstancesSuccess = (response: any) => ({
  type: BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS,
  response,
});

const batchCloseEquipmentInstancesFailure = (error: any) => ({
  type: BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE,
  error,
});

export const batchCloseEquipmentInstances =
  (dateFromStart: string, dateFromEnd: string, equipmentIds: string[]) =>
  async (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(
      batchCloseEquipmentInstancesRequest(
        dateFromStart,
        dateFromEnd,
        equipmentIds
      )
    );
    try {
      const res = await axios.post(
        '/api/equipments/-/instances/batchclose',
        {
          start_date_local_from: dateFromStart,
          start_date_local_to: dateFromEnd,
          equipment_ids: equipmentIds,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      );
      dispatch(batchCloseEquipmentInstancesSuccess(res.data));
    } catch (err) {
      dispatch(batchCloseEquipmentInstancesFailure(err));
    }
  };

const batchEditEquipmentInstancesRequest = (
  equipmentInstnaceIds: string[],
  operation: 'CLOSE' | 'OPEN' | 'REQUEST'
) => ({
  type: BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST,
  equipmentInstnaceIds,
  operation,
});

const batchEditEquipmentInstancesSuccess = (response: any) => ({
  type: BATCH_EDIT_EQUIPMENT_INSTANCES_SUCCESS,
  response,
});

const batchEditEquipmentInstancesFailure = (error: any) => ({
  type: BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE,
  error,
});

export const batchEditEquipmentInstances =
  (equipmentInstnaceIds: string[], operation: 'CLOSE' | 'OPEN') =>
  async (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(
      batchEditEquipmentInstancesRequest(equipmentInstnaceIds, operation)
    );
    try {
      const res = await axios.post(
        '/api/equipments/-/instances/batchedit',
        {
          equipment_instance_ids: equipmentInstnaceIds,
          operation,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      );
      dispatch(batchEditEquipmentInstancesSuccess(res.data));
    } catch (err) {
      dispatch(batchEditEquipmentInstancesFailure(err));
    }
  };
