import clsx from 'clsx';

import baseStyles from 'client/v3-base.module.css';

import styles from './Checkbox.module.css';

type OnChangeWithParameters = (
  event: React.SyntheticEvent<Record<string, any>>,
  args: { value: string }
) => void;

type OnChangeWithoutParameters = () => void;

type CheckboxProps = {
  label?: string;
  subLabel?: string;
  value?: string;
  checked?: boolean;
  onChange: OnChangeWithParameters | OnChangeWithoutParameters;
  size?: 'sm' | 'md';
  children?: JSX.Element;
  disabled?: boolean;
  name?: string;
  error?: boolean;
  helperText?: string;
};

export const Checkbox = ({
  label,
  subLabel,
  value,
  checked,
  onChange,
  size = 'md',
  children,
  disabled,
  name,
  error,
  helperText,
}: CheckboxProps) => {
  // Check the arity (number of parameters) of the function to determine which onChange to use
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      if (onChange.length === 2) {
        const value = event.target.value;
        (onChange as OnChangeWithParameters)(event, { value });
      } else {
        (onChange as OnChangeWithoutParameters)();
      }
    }
  };

  return (
    <fieldset className={clsx(error && styles['is-error'])}>
      <div className={clsx(styles['c-inputcontent'], styles[size])}>
        <label className={clsx(styles['c-checkbox'], styles[size])}>
          <div className={styles['c-checkbox__menu__item']}>
            <input
              className={styles['c-checkbox__body__selected__input']}
              type="checkbox"
              name={name ?? `checkbox-${value}`}
              value={value}
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
            />
            <i></i>
            {label && (
              <p className={disabled ? styles['is-disabled'] : ''}>{label}</p>
            )}
            {children}
          </div>
        </label>
        {subLabel && <span>{subLabel}</span>}
      </div>
      {helperText && (
        <p className={baseStyles['u-error-msg']}>{helperText ?? error}</p>
      )}
    </fieldset>
  );
};
