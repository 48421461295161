import {
  SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS,
  SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE,
  SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE,
  SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME,
  SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB,
} from 'client/constants/ActionTypes';

export const setWebJourneyCustomerListVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS,
  visibleColumns,
});

export const setWebJourneyCustomerListCurrentPage = (currentPage: number) => ({
  type: SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE,
  currentPage,
});

export const setWebJourneyCustomerListPageSize = (pageSize: number) => ({
  type: SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE,
  pageSize,
});

export const setWebJourneyCustomerListSelectedPresetName = (
  selectedPreset: string
) => ({
  type: SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME,
  selectedPreset,
});

export const setWebJourneyCustomerListActiveTab = (activeTab: string) => ({
  type: SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB,
  activeTab,
});
