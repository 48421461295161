import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';

import { ToggleButton, Checkbox } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/Modal/Modal';

import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from './BookingWidgetSettingsFormValues';

type Props = {
  onClose: () => void;
};
export const PaymentTypeModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [onlineError, setOnlineError] = React.useState<string>('');
  const [localError, setLocalError] = React.useState<string>('');
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product, activeUserOrganization),
    [activeUserOrganization, product]
  );
  const organizationPaymentTypes = [
    ...(activeUserOrganization?.supplier_guest_payment_settings
      ?.accepted_guest_payment_types ?? []),
  ];
  return (
    <ProductEditorModal
      open={true}
      disabled={!!onlineError || !!localError}
      onClose={onClose}
      title={t('Booking Website Payment Types')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="isProductPaymentTypeEnabled">
        {({ input: isProductPaymentTypeEnabled }) => {
          return (
            <Modal.Box>
              <ToggleButton
                {...isProductPaymentTypeEnabled}
                label={t("Specify product's payment type")}
              />
            </Modal.Box>
          );
        }}
      </Field>

      <Modal.Box column="two">
        <Field type="checkbox" name="isOnlinePaymentAvailable">
          {({ input }) => {
            const formState = useFormState();

            if (!formState.values?.isProductPaymentTypeEnabled) {
              return null;
            }

            return (
              <Checkbox
                checked={Boolean(input.checked)}
                onChange={(e, v) => {
                  if (
                    !formState.values?.isLocalPaymentAvailable &&
                    input.checked
                  ) {
                    setOnlineError(t('Select a payment type'));
                  } else {
                    setOnlineError('');
                    setLocalError('');
                  }

                  (input as any).onChange(e, v);
                }}
                disabled={
                  !organizationPaymentTypes.includes('PAID_IN_FULL') ||
                  !formState.values?.isProductPaymentTypeEnabled
                }
                label={t('Online Payment')}
                error={onlineError}
              />
            );
          }}
        </Field>
        <Field type="checkbox" name="isLocalPaymentAvailable">
          {({ input }) => {
            const formState = useFormState();

            if (!formState.values?.isProductPaymentTypeEnabled) {
              return null;
            }

            return (
              <Checkbox
                checked={Boolean(input.checked)}
                onChange={(e, v) => {
                  if (
                    !formState.values?.isOnlinePaymentAvailable &&
                    input.checked
                  ) {
                    setLocalError(t('Select a payment type'));
                  } else {
                    setOnlineError('');
                    setLocalError('');
                  }

                  (input as any).onChange(e, v);
                }}
                disabled={
                  !organizationPaymentTypes.includes('PAY_ON_BOARD') ||
                  !formState.values?.isProductPaymentTypeEnabled
                }
                label={t('Local Payment')}
                error={localError}
              />
            );
          }}
        </Field>
      </Modal.Box>
    </ProductEditorModal>
  );
};
