import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { DispatchResource } from 'shared/models/swagger';
import { TextField } from 'client/components/v3/Form/TextField';

type Props = {
  initialVehicles: DispatchResource[];
  setVehicles: Dispatch<SetStateAction<DispatchResource[]>>;
};

export const VehicleEditTable = ({ initialVehicles, setVehicles }: Props) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '16px' }}>
      {initialVehicles.length === 0 && (
        <div>{t('{{item}} is not registered.', { item: t('Vehicle') })}</div>
      )}
      {initialVehicles.length !== 0 && (
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        >
          <thead>
            <tr>
              <th style={{ width: '25%' }}>{t('Name')}</th>
              <th style={{ width: '15%' }}>{t('Capacity')}</th>
              <th>{t('Description')}</th>
            </tr>
          </thead>
          <tbody>
            {initialVehicles.map((vehicle, index) => (
              <tr key={index}>
                <td className={tableStyles['c-tableSmall__spTtl']}>
                  <TextField
                    value={vehicle.key ?? ''}
                    onChange={(value) => {
                      const newVehicles = [...initialVehicles];
                      newVehicles[index] = {
                        ...newVehicles[index],
                        key: value,
                      };
                      setVehicles(newVehicles);
                    }}
                  />
                </td>
                <td data-text={t('Capacity')}>
                  <SingleDropdown
                    options={[...Array(100)].map((_, idx) => {
                      return {
                        text: idx.toString(),
                        value: idx.toString(),
                      };
                    })}
                    selectedOption={(vehicle.capacity ?? '').toString()}
                    onChange={(value) => {
                      const newVehicles = [...initialVehicles];
                      newVehicles[index] = {
                        ...newVehicles[index],
                        capacity: parseInt(value, 10),
                      };
                      setVehicles(newVehicles);
                    }}
                  />
                </td>
                <td data-text={t('Description')}>
                  <div className={styles['p-manifests__customize__flex']}>
                    <TextArea
                      value={vehicle.description ?? ''}
                      onChange={(_, { value }) => {
                        const newVehicles = [...initialVehicles];
                        newVehicles[index] = {
                          ...newVehicles[index],
                          description: value,
                        };
                        setVehicles(newVehicles);
                      }}
                      height={80}
                      fullContainerWidth={true}
                    />
                    <Button
                      size="icon"
                      color="tertiarygray"
                      onClick={() => {
                        const newVehicles = [...initialVehicles];
                        newVehicles.splice(index, 1);
                        setVehicles(newVehicles);
                      }}
                      iconBeforeText={
                        <i className="c-icon-outline-general-trash-03"></i>
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <a
        className={styles['p-manifests__customize__add']}
        onClick={() => {
          const newVehicles = [...initialVehicles];
          newVehicles.splice(
            initialVehicles.length + 1,
            initialVehicles.length,
            {
              key: undefined,
              description: '',
            }
          );
          setVehicles(newVehicles);
        }}
      >
        <i className="c-icon-outline-general-plus-circle"></i>
        {t('Add New Vehicle')}
      </a>
    </div>
  );
};
