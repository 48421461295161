// This component loads a ChannelIO script

import * as React from 'react';
import { useSelector } from 'react-redux';

import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';

const configureChannelIO = () => {
  const w: any = window;
  if (w.ChannelIO) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (window.console.error || window.console.log || function () {})(
      'ChannelIO script included twice.'
    );
  }
  const ch: any = function () {
    // eslint-disable-next-line prefer-rest-params
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args: any) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;
  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
    s.charset = 'UTF-8';
    const x: any = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
  if (document.readyState === 'complete') {
    l();
  } else if (w.attachEvent) {
    w.attachEvent('onload', l);
  } else {
    w.addEventListener('DOMContentLoaded', l, false);
    w.addEventListener('load', l, false);
  }
};

configureChannelIO();

interface Props {
  children?: React.ReactNode;
}

export const ChannelIO = ({ children }: Props) => {
  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      (window as any)?.ChannelIO?.('boot', {
        pluginKey: 'ac060eee-4762-482d-a56e-e06025797b7b',
      });
    }

    return () => {
      (window as any)?.ChannelIO?.('shutdown');
    };
  }, [activeUser]);

  return <>{children}</>;
};
