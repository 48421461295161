import {
  SET_EQUIPMENT_AVAILABILITY_START_DATE,
  SET_EQUIPMENT_AVAILABILITY_END_DATE,
  SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE,
  SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE,
  SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE,
  SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH,
} from 'client/constants/ActionTypes';

export const setEquipmentAvailabilityStartDate = (payload: string) => ({
  type: SET_EQUIPMENT_AVAILABILITY_START_DATE,
  payload,
});
export const setEquipmentAvailabilityEndDate = (payload: string) => ({
  type: SET_EQUIPMENT_AVAILABILITY_END_DATE,
  payload,
});
export const setWeeklyEquipmentAvailabilityStartDate = (payload: string) => ({
  type: SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE,
  payload,
});
export const setEquipmentCalendarListCurrentPage = (page: number) => ({
  type: SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE,
  page,
});
export const setEquipmentCalendarListPageSize = (pageSize: number) => ({
  type: SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE,
  pageSize,
});
export const setMonthlyEquipmentAvailabilitySelectedMonth = (
  payload: string
) => ({
  type: SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH,
  payload,
});
