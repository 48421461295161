import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { guidesSelector } from 'client/reducers/guides';
import type { ReduxState } from 'client/reducers';
import type { GuideSettings$Patch } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateGuides =
  (id: string, patch: GuideSettings$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const guides = guidesSelector(getState());
    const guide = guides.find((guide) => guide.id === id);
    let newGuide;

    if (guide) {
      newGuide = { ...guide, ...patch };
    } else {
      newGuide = { ...patch, id: id };
    }

    return dispatch(
      updateActiveUserOrganization({
        guides: [
          ...guides.filter((guide) => guide.id !== id),
          newGuide,
        ] as any as GuideSettings$Patch[],
      })
    );
  };
export const updateGuideSettings = (id: string, patch: GuideSettings$Patch) =>
  updateGuides(id, patch);
