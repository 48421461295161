import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type { CancellationPolicy } from 'client/libraries/util/productShape';
import type { TranslateFuncType } from 'client/components/Translate';

import styles from './CancellationPolicyTimeline.module.css';

const getFeeDescription = (
  cancellationPolicy: CancellationPolicy,
  currency: string
): string => {
  return cancellationPolicy.feeType === 'PERCENT'
    ? `${cancellationPolicy.feePercent ?? 0}%`
    : formattedCurrencyAmount(`${currency}${cancellationPolicy.feeFixed}`);
};

const getDeadlineDescription = (
  cancellationPolicy: CancellationPolicy,
  t: TranslateFuncType
): string => {
  if (cancellationPolicy.deadlineType === 'ETERNAL') {
    return t('After confirmation');
  } else if (cancellationPolicy.deadlineType === 'DAY') {
    return t('{{count}} days before to {{timeOfDay}}', {
      count: cancellationPolicy.daysBefore,
      timeOfDay: cancellationPolicy.timeOfDay,
    });
  } else if (cancellationPolicy.deadlineType === 'HOUR') {
    return t('{{count}} hours before', {
      count: cancellationPolicy.hoursBefore,
    });
  } else {
    // Cancellation policy applies until participation
    return t('Until start time');
  }
};

const getPostDeadlineDescription = (
  cancellationPolicy: CancellationPolicy,
  t: TranslateFuncType
): string => {
  if (cancellationPolicy.deadlineType === 'ETERNAL') {
    return '';
  }
  if (cancellationPolicy.deadlineType === 'DAY') {
    return t('After {{timeOfDay}}, {{count}} days before', {
      count: cancellationPolicy.daysBefore,
      timeOfDay: cancellationPolicy.timeOfDay,
    });
  } else if (cancellationPolicy.deadlineType === 'HOUR') {
    return t('Within {{count}} hours of start', {
      count: cancellationPolicy.hoursBefore,
    });
  } else {
    return t('After start time');
  }
};

type Props = {
  cancellationPolicies: CancellationPolicy[];
  currency: string;
};
export const CancellationPolicyTimeline = ({
  cancellationPolicies,
  currency,
}: Props) => {
  const { t } = useTranslation();
  const lastCancellationPolicy =
    cancellationPolicies.length > 0
      ? cancellationPolicies[cancellationPolicies.length - 1]
      : null;
  return (
    <div className={styles['page-productsRegist__timeline']}>
      {cancellationPolicies?.map((cancellationPolicy, idx) =>
        cancellationPolicy.deadlineType === 'ETERNAL' && idx > 0 ? (
          <div
            key={idx}
            className={styles['page-productsRegist__timeline__item']}
          >
            <div className={styles['page-productsRegist__timeline__item__txt']}>
              <p className={styles['blue']}>
                {getFeeDescription(cancellationPolicy, currency)}
              </p>
              <p>
                {getPostDeadlineDescription(cancellationPolicies[idx - 1], t)}
              </p>
            </div>
            <p
              className={styles['page-productsRegist__timeline__item__arrow']}
            ></p>
          </div>
        ) : cancellationPolicy.deadlineType === 'ETERNAL' && idx === 0 ? (
          <div className={styles['page-productsRegist__timeline__item']}>
            <div className={styles['page-productsRegist__timeline__item__txt']}>
              <p>{t('After confirmation')}</p>
              <p className={styles['blue']}>
                {getFeeDescription(cancellationPolicy, currency)}
              </p>
            </div>
            <p
              className={styles['page-productsRegist__timeline__item__arrow']}
            ></p>
          </div>
        ) : (
          <div
            key={idx}
            className={styles['page-productsRegist__timeline__item']}
          >
            <div className={styles['page-productsRegist__timeline__item__txt']}>
              <p className={styles['blue']}>
                {getFeeDescription(cancellationPolicy, currency)}
              </p>
              <p>{getDeadlineDescription(cancellationPolicy, t)}</p>
            </div>
            <p
              className={styles['page-productsRegist__timeline__item__arrow']}
            ></p>
          </div>
        )
      )}
      {lastCancellationPolicy?.deadlineType !== 'ETERNAL' && (
        <>
          <div className={styles['page-productsRegist__timeline__item']}>
            <div className={styles['page-productsRegist__timeline__item__txt']}>
              {lastCancellationPolicy == null ? (
                <p>{t('After confirmation')}</p>
              ) : (
                <p>{getPostDeadlineDescription(lastCancellationPolicy, t)}</p>
              )}
              <p className={styles['charcoal']}>100%</p>
            </div>
            <p
              className={styles['page-productsRegist__timeline__item__arrow']}
            ></p>
          </div>
          <p className={styles['page-productsRegist__timeline__last']}>
            {t('Participation Date')}
          </p>
        </>
      )}
    </div>
  );
};
