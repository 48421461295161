import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import {
  Button,
  FieldWrapper,
  Radio,
  Select,
  TextArea,
} from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Message } from 'client/components/Message/Message';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { PrivateMarketplaceSelect } from 'client/pages/PrivateMarketplace/PrivateMarketplaceSelect/PrivateMarketplaceSelect';
import { updateRankingPage } from 'client/actions/privateMarketplace';
import { fetchProducts } from 'client/actions/products';
import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import type { ReduxState } from 'client/reducers';
import type {
  ProductSummary,
  ProductRankingMethod,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './PrivateMarketplaceProductRanking.module.css';

const getRankedProducts = (
  products: ProductSummary[],
  rankingMethod: ProductRankingMethod
): ProductSummary[] => {
  switch (rankingMethod) {
    case 'AUTO_TOTAL_BOOKED_30DAY':
      return products
        .filter((p) => Boolean(p.total_booked30_day))
        .sort(
          (p1, p2) =>
            (p2.total_booked30_day || 0) - (p1.total_booked30_day || 0)
        )
        .slice(0, 10);

    case 'AUTO_TOTAL_BOOKED_90DAY':
      return products
        .filter((p) => Boolean(p.total_booked90_day))
        .sort(
          (p1, p2) =>
            (p2.total_booked90_day || 0) - (p1.total_booked90_day || 0)
        )
        .slice(0, 10);

    case 'AUTO_TOTAL_BOOKED_180DAY':
      return products
        .filter((p) => Boolean(p.total_booked180_day))
        .sort(
          (p1, p2) =>
            (p2.total_booked180_day || 0) - (p1.total_booked180_day || 0)
        )
        .slice(0, 10);

    case 'AUTO_GROSS_30DAY':
      return products
        .filter((p) => Boolean(p.gross30_day))
        .sort((p1, p2) => (p2.gross30_day || 0) - (p1.gross30_day || 0))
        .slice(0, 10);

    case 'AUTO_GROSS_90DAY':
      return products
        .filter((p) => Boolean(p.gross90_day))
        .sort((p1, p2) => (p2.gross90_day || 0) - (p1.gross90_day || 0))
        .slice(0, 10);

    case 'AUTO_GROSS_180DAY':
      return products
        .filter((p) => Boolean(p.gross180_day))
        .sort((p1, p2) => (p2.gross180_day || 0) - (p1.gross180_day || 0))
        .slice(0, 10);

    default:
      return [];
  }
};

export const PrivateMarketplaceProductRanking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const privateMarketplace = useSelector(privateMarketplaceSelector);
  const products = useSelector(bookingWidgetProductSummariesSelector);
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const scrollTo = React.useContext(ScrollToContext);
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const [rankingMethod, setRankingMethod] =
    React.useState<ProductRankingMethod>(
      privateMarketplace?.ranking_page?.ranking_method || 'MANUAL'
    );
  const [manualRankingProductIds, setManualRankingProductIds] = React.useState<
    string[]
  >(
    privateMarketplace?.ranking_page?.manually_ranked_product_ids ||
      _.times(5, () => '')
  );
  const [description, setDescription] = React.useState<string>(
    privateMarketplace?.ranking_page?.description || ''
  );
  const reset = React.useCallback(() => {
    setRankingMethod(
      privateMarketplace?.ranking_page?.ranking_method || 'MANUAL'
    );
    setManualRankingProductIds(
      privateMarketplace?.ranking_page?.manually_ranked_product_ids ||
        _.times(5, () => '')
    );
  }, [privateMarketplace]);
  React.useEffect(() => {
    reset();
  }, [privateMarketplace, reset]);
  const productOptions = products.map((product) => ({
    key: product.id || '',
    value: product.id || '',
    text: product.product_name || '',
  }));
  const rankingMetricOptions = [
    {
      key: 'AUTO_TOTAL_BOOKED_30DAY',
      value: 'AUTO_TOTAL_BOOKED_30DAY',
      text: t('Total pax booked last 30 days by participation date'),
    },
    {
      key: 'AUTO_TOTAL_BOOKED_90DAY',
      value: 'AUTO_TOTAL_BOOKED_90DAY',
      text: t('Total pax booked last 90 days by participation date'),
    },
    {
      key: 'AUTO_TOTAL_BOOKED_180DAY',
      value: 'AUTO_TOTAL_BOOKED_180DAY',
      text: t('Total pax booked last 180 days by participation date'),
    },
    {
      key: 'AUTO_GROSS_30DAY',
      value: 'AUTO_GROSS_30DAY',
      text: t('Gross sales last 30 days by participation date'),
    },
    {
      key: 'AUTO_GROSS_90DAY',
      value: 'AUTO_GROSS_90DAY',
      text: t('Gross sales last 90 days by participation date'),
    },
    {
      key: 'AUTO_GROSS_180DAY',
      value: 'AUTO_GROSS_180DAY',
      text: t('Gross sales last 180 days by participation date'),
    },
  ];
  const rankedProducts = getRankedProducts(products, rankingMethod);
  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <PrivateMarketplaceSelect />
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tbody>
              <tr>
                <th>{t('Page Description (meta description)')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <TextArea
                      value={description}
                      onChange={(event, { value }) => {
                        setDescription(value);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Ranking Settings')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <FieldWrapper label="">
                      <Radio
                        checked={rankingMethod === 'MANUAL'}
                        label={t('Rank Products Manually')}
                        onChange={() => setRankingMethod('MANUAL')}
                      />
                      <Radio
                        checked={rankingMethod !== 'MANUAL'}
                        label={t('Use Automatic Ranking')}
                        onChange={() =>
                          setRankingMethod('AUTO_TOTAL_BOOKED_30DAY')
                        }
                      />
                    </FieldWrapper>
                  </div>
                  {rankingMethod !== 'MANUAL' && (
                    <>
                      <div className={styles['input-box']}>
                        <Select
                          label={t('Ranking Metric')}
                          options={rankingMetricOptions}
                          value={rankingMethod}
                          onChange={(e, { value }) =>
                            setRankingMethod(value as ProductRankingMethod)
                          }
                        />
                      </div>
                      {rankedProducts.length > 0 ? (
                        <div className={styles['input-box']}>
                          <FieldWrapper label="">
                            <label>{t('Current Ranking')}</label>
                            <ol>
                              {rankedProducts.map((p) => (
                                <li key={p.id}>{p.product_name}</li>
                              ))}
                            </ol>
                          </FieldWrapper>
                        </div>
                      ) : (
                        <div className={styles['input-box']}>
                          <FieldWrapper label="">
                            <label>
                              {t(
                                'No products have data for the selected ranking metric. You may need to wait up to 24 hours for statistics to be collected.'
                              )}
                            </label>
                          </FieldWrapper>
                        </div>
                      )}
                    </>
                  )}
                  {rankingMethod === 'MANUAL' && (
                    <>
                      <div className={styles['input-box']}>
                        <Select
                          label={t(
                            'Number of Products to Show in Ranking List'
                          )}
                          options={[5, 6, 7, 8, 9, 10].map((num) => ({
                            key: num,
                            value: `${num}`,
                            text: `${num}`,
                          }))}
                          value={`${manualRankingProductIds.length}`}
                          onChange={(e, { value }) => {
                            setManualRankingProductIds(
                              _.times(
                                parseInt(value),
                                (idx) => manualRankingProductIds[idx] || ''
                              )
                            );
                          }}
                        />
                      </div>
                      <div className={styles['input-box']}>
                        <FieldWrapper
                          label={t('Products (drag-and-drop to reorder)')}
                        >
                          <div className={styles['products-input-box']}>
                            {manualRankingProductIds.map((productId, idx) => {
                              return (
                                <DraggableSelect
                                  key={productId || idx}
                                  value={productId}
                                  options={
                                    // Options are any product IDs not yet present
                                    productOptions.filter(
                                      (p) =>
                                        p.key === productId ||
                                        !manualRankingProductIds.includes(p.key)
                                    )
                                  }
                                  index={idx}
                                  onValueChange={(newValue) => {
                                    const newProductIds = [
                                      ...manualRankingProductIds,
                                    ];
                                    newProductIds[idx] = newValue;
                                    setManualRankingProductIds(newProductIds);
                                  }}
                                  moveItem={(
                                    dragIndex: number,
                                    hoverIndex: number
                                  ) => {
                                    const draggingProductId =
                                      manualRankingProductIds[dragIndex];
                                    const newProductIds = [
                                      ...manualRankingProductIds,
                                    ];
                                    newProductIds.splice(dragIndex, 1);
                                    newProductIds.splice(
                                      hoverIndex,
                                      0,
                                      draggingProductId
                                    );
                                    setManualRankingProductIds(newProductIds);
                                  }}
                                />
                              );
                            })}
                          </div>
                        </FieldWrapper>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button style="gray" size="middle" onClick={reset}>
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            onClick={async () => {
              await dispatch(
                updateRankingPage({
                  ranking_method: rankingMethod,
                  manually_ranked_product_ids: manualRankingProductIds,
                  description: description,
                })
              );
              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
