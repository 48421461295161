import type { Product } from 'shared/models/swagger';

export const getProductCurrency = (product: Product): string | null => {
  for (const priceSchedule of product.pricing ?? []) {
    for (const unit of priceSchedule.units) {
      if (unit.gross && unit.gross.length > 3) {
        return unit.gross.substring(0, 3);
      }
    }
  }

  return null;
};
