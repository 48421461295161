import { SingleDropdown } from '../Form/Dropdown/SingleDropdown';

export interface Props {
  value: string;
  onChange: (newValue: string) => void;
  label?: string;
}

export const FontPicker = ({ value, onChange, label }: Props) => {
  const options = [
    {
      value: 'helvetica',
      text: 'Helvetica',
    },
    {
      value: 'arial',
      text: 'Arial',
    },
    {
      value: 'arial black',
      text: 'Arial Black',
    },
    {
      value: 'verdana',
      text: 'Verdana',
    },
    {
      value: 'tahoma',
      text: 'Tahoma',
    },
    {
      value: 'trebuchet ms',
      text: 'Trebuchet MS',
    },
    {
      value: 'impact',
      text: 'Impact',
    },
    {
      value: 'gill sans',
      text: 'Gill Sans',
    },
    {
      value: 'times new roman',
      text: 'Times New Roman',
    },
    {
      value: 'georgia',
      text: 'Georgia',
    },
    {
      value: 'palatino',
      text: 'Palatino',
    },
    {
      value: 'baskerville',
      text: 'Baskerville',
    },
    {
      value: 'andale mono',
      text: 'Andale Mono',
    },
    {
      value: 'courier',
      text: 'Courier',
    },
    {
      value: 'lucida',
      text: 'Lucida',
    },
    {
      value: 'monaco',
      text: 'Monaco',
    },
    {
      value: 'bradley hand',
      text: 'Bradley Hand',
    },
    {
      value: 'brush script mt',
      text: 'Brush Script MT',
    },
    {
      value: 'luminari',
      text: 'Luminari',
    },
    {
      value: 'comic sans ms',
      text: 'Comic Sans MS',
    },
    {
      value: "'BIZ UDPMincho', serif",
      text: 'BIZ UDPMincho',
    },
    { value: "'Klee One', cursive", text: 'Klee One' },
    { value: "'Kosugi Maru', sans-serif", text: 'Kosugi Maru' },
    { value: "'M PLUS 1p', sans-serif", text: 'M PLUS 1p' },
    { value: "'Noto Sans JP', sans-serif", text: 'Noto Sans JP' },
    { value: "'Noto Serif JP', serif", text: 'Noto Serif JP' },
    { value: "'Shippori Mincho', serif", text: 'Shippori Mincho' },
    { value: "'Zen Kaku Gothic New', sans-serif", text: 'Zen Kaku Gothic New' },
  ];

  return (
    <div>
      {label && <div style={{ marginBottom: '4px' }}>{label}</div>}
      <div>
        <SingleDropdown
          options={options}
          selectedOption={options.find((o) => o.value === value)?.text ?? ''}
          onChange={(option) => onChange(option)}
          renderOption={(option) => (
            <div style={{ fontFamily: option.value }}>
              {option.text || 'NO WORD'}
            </div>
          )}
          inputStyle={{ fontFamily: value }}
        />
      </div>
    </div>
  );
};
