import * as React from 'react';

import { SellOut } from 'shared/components/templates/newsletter/SellOut';
import { LimitedTimeSale } from 'shared/components/templates/newsletter/LimitedTimeSale';
import { SoaringPopularity } from 'shared/components/templates/newsletter/SoaringPopularity';
import { IntroduceNewPlan } from 'shared/components/templates/newsletter/IntroduceNewPlan';
import { SeasonalEvent } from 'shared/components/templates/newsletter/SeasonalEvent';
import { PromoCode } from 'shared/components/templates/newsletter/PromoCode';
import { AutoPromo } from 'shared/components/templates/newsletter/AutoPromo';
import { Plain } from 'shared/components/templates/newsletter/Plain';
import { Plain as NewPlain } from 'shared/components/templates/newsletter/new-design/Plain';
import { SellOut as NewSellOut } from 'shared/components/templates/newsletter/new-design/SellOut';
import { LimitedTimeSale as NewLimitedTimeSale } from 'shared/components/templates/newsletter/new-design/LimitedTimeSale';
import { SoaringPopularity as NewSoaringPopularity } from 'shared/components/templates/newsletter/new-design/SoaringPopularity';
import { IntroduceNewPlan as NewIntroduceNewPlan } from 'shared/components/templates/newsletter/new-design/IntroduceNewPlan';
import { SeasonalEvent as NewSeasonalEvent } from 'shared/components/templates/newsletter/new-design/SeasonalEvent';
import { PromoCode as NewPromoCode } from 'shared/components/templates/newsletter/new-design/PromoCode';
import { AutoPromo as NewAutoPromo } from 'shared/components/templates/newsletter/new-design/AutoPromo';
import { SimpleTemplate as NewSimpleTemplate } from 'shared/components/templates/newsletter/new-design/SimpleTemplate';
import { CustomEditor } from 'shared/components/templates/newsletter/new-design/CustomEditor';
import { config } from 'server/config';

import { Custom } from './Custom';

interface Props {
  editMode?: boolean;
  selectedTemplate:
    | 'SellOut'
    | 'LimitedTimeSale'
    | 'SoaringPopularity'
    | 'IntroduceNewPlan'
    | 'SeasonalEvent'
    | 'PromoCode'
    | 'AutoPromo'
    | 'Plain'
    | 'Custom'
    | 'CustomEditor';
  customerName?: string;
  newsletterCampaignId?: string;
  templateValues: any;
}

export const NewsletterTemplate = ({
  selectedTemplate,
  templateValues,
  editMode,
  customerName,
  newsletterCampaignId,
}: Props) => {
  if (config.enableNewsletterNewDesign) {
    return (
      <>
        {selectedTemplate === 'SellOut' && (
          <NewSellOut
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'LimitedTimeSale' && (
          <NewLimitedTimeSale
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'SoaringPopularity' && (
          <NewSoaringPopularity
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'IntroduceNewPlan' && (
          <NewIntroduceNewPlan
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'SeasonalEvent' && (
          <NewSeasonalEvent
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'PromoCode' && (
          <NewPromoCode
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'AutoPromo' && (
          <NewAutoPromo
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'Plain' && (
          <NewPlain editMode={editMode} {...templateValues} />
        )}
        {selectedTemplate === 'Custom' && (
          <NewSimpleTemplate
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
        {selectedTemplate === 'CustomEditor' && (
          <CustomEditor
            editMode={editMode}
            {...templateValues}
            customerName={customerName}
            newsletterCampaignId={newsletterCampaignId}
          />
        )}
      </>
    );
  }

  return (
    <>
      {selectedTemplate === 'SellOut' && (
        <SellOut
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'LimitedTimeSale' && (
        <LimitedTimeSale
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'SoaringPopularity' && (
        <SoaringPopularity
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'IntroduceNewPlan' && (
        <IntroduceNewPlan
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'SeasonalEvent' && (
        <SeasonalEvent
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'PromoCode' && (
        <PromoCode
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'AutoPromo' && (
        <AutoPromo
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
      {selectedTemplate === 'Plain' && (
        <Plain editMode={editMode} {...templateValues} />
      )}
      {selectedTemplate === 'Custom' && (
        <Custom
          editMode={editMode}
          {...templateValues}
          customerName={customerName}
          newsletterCampaignId={newsletterCampaignId}
        />
      )}
    </>
  );
};
