import { useSelector } from 'react-redux';

import { hasSubscription } from 'client/libraries/util/subscriptions';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  ManifestViewType,
  ReservationColumn,
  ManifestCustomizedColumnName,
} from 'shared/models/swagger';

import { activeUserOrganizationSelector } from './user';

export const useAllManifestColumns = (): ReservationColumn[] => {
  const columns: ReservationColumn[] = [
    'ADD_ONS',
    'AGENT_NOTES',
    'BOOKING_SOURCE',
    'DROPOFF_LOCATION',
    'GROSS',
    'GUEST_DISPLAY_NAME',
    'GIVEN_NAME',
    'FAMILY_NAME',
    'GUEST_DESCRIPTION',
    'GUEST_COUNT',
    'PAYMENT_TYPE',
    'PAYMENT_METHOD',
    'POB_AMOUNT',
    'PICKUP_CHECKIN_TIME',
    'PICKUP_CHECKIN_LOCATION',
    'PRODUCT_NAME', // This is a column group key that triggers display of multiple columns depending on reservation form fields
    // present in the product(s).
    'RESERVATION_FORM_FIELDS',
    'SUPPLIER_INTERNAL_NOTES',
    'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
    'SUPPLIER_NOTES',
    'SUPPLIER_PRODUCT_REFERENCE',
    'SUPPLIER_REFERENCE',
    'TRANSPORTATION',
    'AGENT_REFERENCE',
    'HOTEL',
    'PROMO_CODE',
    'DISPATCH_VEHICLE',
    'DISPATCH_CREW',
    'DISPATCH_MISC_RESOURCE',
    'INTERNAL_PRODUCT_TAGS',
    'INTERNAL_PRODUCT_NAME',
    'TRANSPORT_ROUTE',
    'TRANSPORT_CREW',
    'TRANSPORT_VEHICLE',
    'TRANSPORT_OTHER',
    'RESERVATION_FORM_EMAIL',
    'RESERVATION_FORM_FULL_NAME',
    'RESERVATION_FORM_FULL_NAME_KANJI',
    'RESERVATION_FORM_TITLE',
    'RESERVATION_FORM_PHONE',
    'RESERVATION_FORM_INTERNATIONAL_PHONE',
    'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
    'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
    'RESERVATION_FORM_HEIGHT',
    'RESERVATION_FORM_WEIGHT',
    'RESERVATION_FORM_DATE_OF_BIRTH',
    'RESERVATION_FORM_AGE',
    'RESERVATION_FORM_GENDER',
    'RESERVATION_FORM_HOME_ADDRESS',
    'RESERVATION_FORM_CONSENT_FORM',
    'RESERVATION_FORM_FAMILY_NAME_KANA',
    'RESERVATION_FORM_GIVEN_NAME_KANA',
    'RESERVATION_FORM_CUSTOM',
  ];
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  if (hasSubscription(activeUserOrganization, 'feature-digital-waiver')) {
    columns.push('WAIVER_STATUS');
  }

  if (
    hasSubscription(activeUserOrganization, 'feature-qr-checkin') ||
    hasSubscription(activeUserOrganization, 'feature-e-ticket')
  ) {
    columns.push('CHECKIN_STATUS');
  }

  if (
    hasSubscription(activeUserOrganization, 'feature-resource-management') ||
    hasSubscription(
      activeUserOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push('REQUIRED_RESOURCES');
  }

  return columns;
};
// t('DRIVER')
// t('GUIDE')
// t('RECEPTIONIST')
export const defaultManifestViewKeys: string[] = [
  'DRIVER',
  'GUIDE',
  'RECEPTIONIST',
];
export const defaultManifestViewColumnMap: Record<string, ReservationColumn[]> =
  {
    DRIVER: [
      'PICKUP_CHECKIN_TIME',
      'PICKUP_CHECKIN_LOCATION',
      'DROPOFF_LOCATION',
      'GUEST_DISPLAY_NAME',
      'GUEST_DESCRIPTION',
      'BOOKING_SOURCE',
      'SUPPLIER_NOTES',
      'SUPPLIER_REFERENCE',
      'SUPPLIER_INTERNAL_NOTES',
    ],
    GUIDE: [
      'PRODUCT_NAME',
      'GUEST_DISPLAY_NAME',
      'GUEST_DESCRIPTION',
      'GUEST_COUNT',
      'BOOKING_SOURCE',
      'ADD_ONS',
      'TRANSPORTATION',
      'SUPPLIER_NOTES',
      'SUPPLIER_INTERNAL_NOTES', // This is a column group key that triggers display of multiple columns depending on reservation form fields
      // present in the product(s).
      'RESERVATION_FORM_FIELDS',
    ],
    RECEPTIONIST: [
      'GUEST_DISPLAY_NAME',
      'GUEST_DESCRIPTION',
      'BOOKING_SOURCE',
      'GROSS',
      'PAYMENT_TYPE',
      'PAYMENT_METHOD',
      'POB_AMOUNT',
      'SUPPLIER_NOTES',
      'SUPPLIER_INTERNAL_NOTES',
    ],
  };
export const defaultManifestOrderBy: Record<string, ReservationColumn> = {
  DRIVER: 'PICKUP_CHECKIN_TIME',
  GUIDE: 'PRODUCT_NAME',
  RECEPTIONIST: 'GUEST_DISPLAY_NAME',
};
export const getDefaultManifestViews = (): ManifestViewType[] =>
  defaultManifestViewKeys.map((key) => ({
    key,
    columns: defaultManifestViewColumnMap[key],
  }));
export const getDriverManifestView = (): ManifestViewType => ({
  key: 'DRIVER',
  columns: defaultManifestViewColumnMap['DRIVER'],
});
export const getColumnHeaderText = (
  column: ReservationColumn,
  t: TranslateFuncType,
  customizedColumnNames?: ManifestCustomizedColumnName[]
) => {
  const customizedName =
    (customizedColumnNames || []).find((c) => c.column === column)?.name || '';

  if (customizedName) {
    return customizedName;
  }

  switch (column) {
    case 'ADD_ONS':
      return t('Add-ons');

    case 'AGENT_NOTES':
      return t('Remarks');

    case 'BOOKING_SOURCE':
      return t('Source');

    case 'DROPOFF_LOCATION':
      return t('Dropoff location');

    case 'GROSS':
      return t('Gross');

    case 'GUEST_DISPLAY_NAME':
      return t('Customer');

    case 'GIVEN_NAME':
      return t('Given Name');

    case 'FAMILY_NAME':
      return t('Family Name');

    case 'GUEST_DESCRIPTION':
      return t('Units');

    case 'GUEST_COUNT':
      return t('Total Pax');

    case 'PAYMENT_TYPE':
      return t('Payment Type');

    case 'PAYMENT_METHOD':
      return t('Payment Method');

    case 'POB_AMOUNT':
      return t('POB Amount');

    case 'PICKUP_CHECKIN_TIME':
      return t('Pickup/Checkin time');

    case 'PICKUP_CHECKIN_LOCATION':
      return t('Pickup/Checkin location');

    case 'PRODUCT_NAME':
      return t('Product');

    case 'RESERVATION_FORM_FIELDS':
      return t('Required info');

    case 'SUPPLIER_INTERNAL_NOTES':
      return t('Memo');

    case 'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH':
      return t('Internal Note');

    case 'SUPPLIER_NOTES':
      return t('Replies');

    case 'SUPPLIER_PRODUCT_REFERENCE':
      return t('Product Code');

    case 'SUPPLIER_REFERENCE':
      return t('Confirmation Number');

    case 'AGENT_REFERENCE':
      return t('Application Number');

    case 'HOTEL':
      return t('Hotel');

    case 'TRANSPORTATION':
      return t('Transportation');

    case 'DISPATCH_CREW':
      return t('Crew');

    case 'DISPATCH_VEHICLE':
      return t('Vehicle');

    case 'DISPATCH_MISC_RESOURCE':
      return t('Other Resources');

    case 'PROMO_CODE':
      return t('Promo Code');

    case 'INTERNAL_PRODUCT_TAGS':
      return t('Internal Tags');

    case 'INTERNAL_PRODUCT_NAME':
      return t('Internal Product Name');

    case 'WAIVER_STATUS':
      return t('Waiver Status');

    case 'CHECKIN_STATUS':
      return t('Checkin Status');

    case 'TRANSPORT_DEPARTURE_TIME':
      return t('Transport Departure Time');

    case 'TRANSPORT_DEPARTURE_LOCATION':
      return t('Transport Departure Location');

    case 'TRANSPORT_ARRIVAL_TIME':
      return t('Transport Arrival Time');

    case 'TRANSPORT_ARRIVAL_LOCATION':
      return t('Transport Arrival Location');

    case 'TRANSPORT_ROUTE':
      return t('Transport Routes');

    case 'TRANSPORT_CREW':
      return t('Transport Crew');

    case 'TRANSPORT_VEHICLE':
      return t('Transport Vehicle');

    case 'TRANSPORT_OTHER':
      return t('Transport Other Resource');

    case 'RESERVATION_FORM_EMAIL':
      return t('Reservation Form: Email Address');

    case 'RESERVATION_FORM_FULL_NAME':
      return t('Reservation Form: Full Name');

    case 'RESERVATION_FORM_FULL_NAME_KANJI':
      return t('Reservation Form: Full Name(Kanji)');

    case 'RESERVATION_FORM_TITLE':
      return t('Reservation Form: Title');

    case 'RESERVATION_FORM_PHONE':
      return t('Reservation Form: Phone Number');

    case 'RESERVATION_FORM_INTERNATIONAL_PHONE':
      return t('Reservation Form: International Phone Number');

    case 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME':
      return t("Reservation Form: Emergency Contact's Name");

    case 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE':
      return t("Reservation Form: Emergency Contact's Phone Number");

    case 'RESERVATION_FORM_HEIGHT':
      return t('Reservation Form: Height (cm)');

    case 'RESERVATION_FORM_WEIGHT':
      return t('Reservation Form: Weight (kg)');

    case 'RESERVATION_FORM_DATE_OF_BIRTH':
      return t('Reservation Form: Date of Birth');

    case 'RESERVATION_FORM_AGE':
      return t('Reservation Form: Age');

    case 'RESERVATION_FORM_GENDER':
      return t('Reservation Form: Gender');

    case 'RESERVATION_FORM_HOME_ADDRESS':
      return t('Reservation Form: Home Address');

    case 'RESERVATION_FORM_CONSENT_FORM':
      return t('Reservation Form: Consent Form');

    case 'RESERVATION_FORM_FAMILY_NAME_KANA':
      return t('Reservation Form: Family Name(Kana)');

    case 'RESERVATION_FORM_GIVEN_NAME_KANA':
      return t('Reservation Form: Given Name(Kana)');

    case 'RESERVATION_FORM_CUSTOM':
      return t('Reservation Form: Custom');

    case 'REQUIRED_RESOURCES':
      return t('Required Resources');

    default:
      return '';
    // throw new Error(`unknown column: ${column}`);
  }
};
export const getManifestViewOrderBy = (key: string): ReservationColumn => {
  return defaultManifestOrderBy[key] || 'PRODUCT_NAME';
};
export const getReservationSortMethodFromReservationColumn = (
  column: ReservationColumn
) => {
  return column.toLowerCase();
};
export const getVisibleColumns = (manifestView: ManifestViewType) => {
  return [...((manifestView && manifestView.columns) || [])];
};
export const getColumnsParam = (
  manifestView: ManifestViewType,
  splitStartTimes: boolean,
  showReservationsOnAllOperatingDays: boolean
): ReservationColumn[] => {
  let startTimeColumn: ReservationColumn[] = [];

  if (!splitStartTimes) {
    startTimeColumn = [
      showReservationsOnAllOperatingDays
        ? 'START_TIME_WITH_DATE'
        : 'START_TIME',
    ];
  }

  return [
    ...startTimeColumn,
    ...(getVisibleColumns(manifestView).filter(
      (col) => (col as any) !== 'edit'
    ) as any),
  ];
};
export const getOrderByColumns = (
  manifestView: ManifestViewType,
  splitStartTimes: boolean
): ReservationColumn[] => {
  const orderBy = [
    ...((manifestView && manifestView.order_by) || []),
    ...[getDefaultSortColumn(manifestView)],
  ];

  if (splitStartTimes) {
    orderBy.push('START_TIME');
  }

  return orderBy;
};

const getDefaultSortColumn = (manifestView: ManifestViewType) => {
  const orderBy = getManifestViewOrderBy(
    (manifestView && manifestView.key) || ''
  );
  return orderBy;
};
