import { useTranslation } from 'react-i18next';

import { AvailabilityHeader } from 'client/pages/v3/Availability/Shared/AvailabilityHeader';
import { AvailabilityWeeklyBody } from 'client/pages/v3/Availability/AvailabilityWeekly/AvailabilityWeeklyBody/AvailabilityWeeklyBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const AvailabilityWeekly = () => {
  const { t } = useTranslation();

  return (
    <V3Page>
      <AvailabilityHeader title={t('Weekly Availability')} />
      <AvailabilityWeeklyBody />
    </V3Page>
  );
};
