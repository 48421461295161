import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import axios from 'axios';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { ModalLoader } from 'client/components/ModalLoader';
import baseStyles from 'client/base.module.css';

import { ImportPhotosModal } from './ImportPhotosModal';

type MediaCategory =
  | 'COVER'
  | 'PROFILE'
  | 'LOGO'
  | 'EXTERIOR'
  | 'INTERIOR'
  | 'FOOD_AND_DRINK'
  | 'ADDITIONAL';

interface FormValues {
  coverPhotoUrl: string;
  profilePhotoUrls: string[];
  logoUrl: string;
  additionalUrls: string[];
}

const getMediaUrlsForCategory = (
  mediaItems: any[],
  category: MediaCategory
): string[] => {
  return (
    mediaItems
      ?.filter((item: any) => item?.locationAssociation?.category === category)
      ?.map((item: any) => item?.sourceUrl) ?? []
  );
};

interface Props {
  accountName: string;
  onClose: () => void;
  location: any;
  onSave: (location: any) => void;
  idToken: string;
}

export const MediaEditModal = ({
  accountName,
  onClose,
  location,
  idToken,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);

  const [media, setMedia] = React.useState<any>(null);

  React.useEffect(() => {
    (async () => {
      const mediaResp = await axios.get(
        `https://mybusiness.googleapis.com/v4/${accountName}/${location.name}/media`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      setMedia(mediaResp.data);
    })();
  }, []);

  const initialValues = React.useMemo(() => {
    const coverPhotos = getMediaUrlsForCategory(
      media?.mediaItems ?? [],
      'COVER'
    );
    const profilePhotos = getMediaUrlsForCategory(
      media?.mediaItems ?? [],
      'PROFILE'
    );
    const logoPhotos = getMediaUrlsForCategory(media?.mediaItems ?? [], 'LOGO');
    const additionalPhotos = getMediaUrlsForCategory(
      media?.mediaItems ?? [],
      'ADDITIONAL'
    );

    return {
      coverPhotoUrl: coverPhotos.length > 0 ? coverPhotos[0] : '',
      profilePhotoUrls: profilePhotos,
      logoUrl: logoPhotos.length > 0 ? logoPhotos[0] : '',
      additionalUrls: additionalPhotos,
    };
  }, [media]);

  return (
    <Modal title={t('Edit Photos')} open={true} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          setLoading(true);
          try {
            if (
              values.coverPhotoUrl &&
              initialValues.coverPhotoUrl !== values.coverPhotoUrl
            ) {
              await axios.post(
                `https://mybusiness.googleapis.com/v4/${accountName}/${location.name}/media`,
                {
                  mediaFormat: 'PHOTO',
                  locationAssociation: {
                    category: 'COVER',
                  },
                  sourceUrl: values.coverPhotoUrl,
                },
                {
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }
              );
            }
            for (const profileUrl of values.profilePhotoUrls) {
              if (!initialValues.profilePhotoUrls?.includes(profileUrl)) {
                await axios.post(
                  `https://mybusiness.googleapis.com/v4/${accountName}/${location.name}/media`,
                  {
                    mediaFormat: 'PHOTO',
                    locationAssociation: {
                      category: 'PROFILE',
                    },
                    sourceUrl: profileUrl,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${idToken}`,
                    },
                  }
                );
              }
            }
            /*
            if (values.logoUrl && initialValues.logoUrl !== values.logoUrl) {
              await axios.post(
                `https://mybusiness.googleapis.com/v4/${accountName}/${location.name}/media`,
                {
                  mediaFormat: 'PHOTO',
                  locationAssociation: {
                    category: 'LOGO',
                  },
                  sourceUrl: values.logoUrl,
                },
                {
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }
              );
            }
            */
            for (const additionalUrl of values.additionalUrls) {
              if (!initialValues.additionalUrls?.includes(additionalUrl)) {
                await axios.post(
                  `https://mybusiness.googleapis.com/v4/${accountName}/${location.name}/media`,
                  {
                    mediaFormat: 'PHOTO',
                    locationAssociation: {
                      category: 'ADDITIONAL',
                    },
                    sourceUrl: additionalUrl,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${idToken}`,
                    },
                  }
                );
              }
            }

            // Delete removed items
            const existingCoverMediaItem = media?.mediaItems?.find(
              (item: any) => item?.locationAssociation?.category === 'COVER'
            );
            if (!values.coverPhotoUrl && existingCoverMediaItem) {
              await axios.delete(
                `https://mybusiness.googleapis.com/v4/${existingCoverMediaItem.name}`,
                {
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }
              );
            }
            const existingProfileMediaItems =
              media?.mediaItems?.filter(
                (item: any) => item?.locationAssociation?.category === 'PROFILE'
              ) ?? [];
            for (const existingItem of existingProfileMediaItems) {
              if (!values.profilePhotoUrls?.includes(existingItem?.sourceUrl)) {
                await axios.delete(
                  `https://mybusiness.googleapis.com/v4/${existingItem.name}`,
                  {
                    headers: {
                      authorization: `Bearer ${idToken}`,
                    },
                  }
                );
              }
            }
            /*
            const existingLogoMediaItem = media?.mediaItems?.find(
              (item: any) => item?.locationAssociation?.category === 'LOGO'
            );
            if (!values.logoUrl && existingLogoMediaItem) {
              await axios.delete(
                `https://mybusiness.googleapis.com/v4/${existingLogoMediaItem.name}`,
                {
                  headers: {
                    authorization: `Bearer ${idToken}`,
                  },
                }
              );
            }
            */
            const existingAdditionalMediaItems =
              media?.mediaItems?.filter(
                (item: any) =>
                  item?.locationAssociation?.category === 'ADDITIONAL'
              ) ?? [];
            for (const existingItem of existingAdditionalMediaItems) {
              if (!values.additionalUrls?.includes(existingItem?.sourceUrl)) {
                await axios.delete(
                  `https://mybusiness.googleapis.com/v4/${existingItem.name}`,
                  {
                    headers: {
                      authorization: `Bearer ${idToken}`,
                    },
                  }
                );
              }
            }

            setLoading(false);
            onClose();
          } catch (err: any) {
            setLoading(false);
            return {
              [FORM_ERROR]: `${t('Error: ')}${
                err.response.data.error.status
              }: ${JSON.stringify(err.response.data.error.details)}`,
            };
          }
        }}
        mutators={getArrayMutators()}
        debug={console.log}
      >
        {({ handleSubmit, submitError, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {loading && <ModalLoader />}
              <FieldWrapper
                label={t(
                  'Cover Photo (req. aspect ratio 265w x 149h; max 2120px/1192px w/h; min 10KB)'
                )}
              >
                <Field name="coverPhotoUrl">
                  {({ input, meta: { touched, error } }) => (
                    <ImageVideoAudioInput
                      fileUrls={input.value ? [input.value] : []}
                      onChange={(newValue) =>
                        newValue.length > 0
                          ? input.onChange(newValue[0])
                          : input.onChange('')
                      }
                      maxFileCount={1}
                      disableYoutubeVideos
                      error={touched && error}
                    />
                  )}
                </Field>
              </FieldWrapper>
              <Box mt={2}>
                <FieldWrapper label={t('Profile Photo')}>
                  <Field name="profilePhotoUrls">
                    {({ input }) => (
                      <ImageVideoAudioInput
                        fileUrls={input.value || []}
                        onChange={(newValue) => input.onChange(newValue)}
                        disableYoutubeVideos
                        maxFileCount={1}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Box>
              {/*
              <Box mt={2}>
                <FieldWrapper label={t('Logo')}>
                  <Field name="logoUrl">
                    {({ input, meta: { touched, error } }) => (
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue.length > 0
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                        error={touched && error}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Box>
              */}
              <Box mt={2}>
                <FieldWrapper label={t('General Photos')}>
                  <Button
                    style="green"
                    size="middle"
                    onClick={() => setShowImportModal(true)}
                  >
                    {t('Import Photos')}
                  </Button>
                  {showImportModal && (
                    <ImportPhotosModal
                      onClose={() => setShowImportModal(false)}
                      onImport={(photoUrls) =>
                        form.change('additionalUrls', [
                          ...(values?.additionalUrls ?? []),
                          ...photoUrls,
                        ])
                      }
                    />
                  )}
                  <Field name="additionalUrls">
                    {({ input }) => (
                      <ImageVideoAudioInput
                        fileUrls={input.value || []}
                        onChange={(newValue) => input.onChange(newValue)}
                        disableYoutubeVideos
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Box>
              {submitError && (
                <div
                  style={{ wordBreak: 'break-all' }}
                  className={baseStyles['base-form-box__err']}
                >
                  {submitError}
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={loading}
                style="blue"
                size="middle"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
