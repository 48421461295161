import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { EditOrganizationNameModal } from 'client/pages/Settings/EditOrganizationNameModal';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';

import styles from './Settings.module.css';

export const OrganizationNameSettings = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  return organization ? (
    <div className={baseStyles['base-flex']}>
      <div className={styles['company-name-box']}>{organization.name}</div>
      <EditOrganizationNameModal
        trigger={
          <a className={clsx(baseStyles['base-btn'], baseStyles['icon'])}>
            <img src={editIcon} />
          </a>
        }
      />
    </div>
  ) : (
    <div>{t('No organization found')}</div>
  );
};
