import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, FieldWrapper, TextArea } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { replyToInquiry } from 'client/actions/inquiries';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Box } from 'client/components/Box/Box';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import {
  InquiryAttachmentsEditor,
  InquiryAttachment,
} from 'client/components/InquiryAttachmentsEditor/InquiryAttachmentsEditor';
import baseStyles from 'client/base.module.css';
import { Inquiry } from 'shared/models/swagger';

import styles from './InquiryResponseSection.module.css';

interface FormValues {
  message: string;
  cc: string[];
  bcc: string[];
  attachments: InquiryAttachment[];
}

interface Props {
  inquiry: Inquiry;
  onClose: () => void;
}

const initialValues: FormValues = {
  message: '',
  cc: [],
  bcc: [],
  attachments: [],
};

export const InquiryResponseSection = ({ inquiry, onClose }: Props) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<'EDIT' | 'CONFIRM'>('EDIT');

  const organization = useSelector(activeUserOrganizationSelector);

  const { required } = getValidators(t);

  const dispatch = useDispatch();

  const isSms = Boolean(inquiry.customer_phone);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        switch (step) {
          case 'EDIT':
            setStep('CONFIRM');
            break;
          case 'CONFIRM':
            await dispatch(
              replyToInquiry(
                inquiry.id ?? '',
                values.message,
                values.cc,
                values.bcc,
                values.attachments?.map((attachment) => ({
                  url: attachment.url,
                  filename: attachment.filename,
                }))
              )
            );

            onClose();
            break;
        }
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles['response-section']}>
            <Box mt={2}>
              <FieldWrapper label={t('From')}>
                <div className={styles['from-input']}>
                  <Box mb={3}>{organization?.name ?? ''}</Box>
                  <div className={styles['from-input-actor-name']}>
                    <ReservationActorInputForm />
                  </div>
                  <Box mb={3}>
                    <Tooltip
                      text={t(
                        'Please enter your name here. Your name will be shown in emails to guests.'
                      )}
                    />
                  </Box>
                </div>
              </FieldWrapper>
            </Box>
            {inquiry.customer_email && (
              <>
                <Box mt={2}>
                  <ListEditor name="cc" title={t('CC')} />
                </Box>
                <Box mt={2}>
                  <ListEditor name="bcc" title={t('BCC')} />
                </Box>
              </>
            )}
            <Box mt={2}>
              <Field name="message" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <TextArea
                    label={t('Message')}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                    error={touched && error}
                    showCharacterCount={isSms}
                  />
                )}
              </Field>
            </Box>
            {isSms && (
              <Box>
                <div>
                  {t(
                    '* 1 message cost will be for approximately 60 characters.'
                  )}
                </div>
                <div>
                  {t(
                    '* Long messages may be split into multiple messages and charged for multiple messages'
                  )}
                </div>
                <div>
                  {t(
                    '* When including URLs in messages, we recommend shortening the URLs with a service like '
                  )}
                  <a
                    className={baseStyles['base-link']}
                    href="https://bitly.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    bitly.com
                  </a>
                </div>
              </Box>
            )}
            {!isSms && (
              <Box mt={2}>
                <FieldWrapper label={t('Attachments')}>
                  <Field name="attachments">
                    {({ input }) => (
                      <InquiryAttachmentsEditor
                        attachments={input.value}
                        onChange={(newAttachments: InquiryAttachment[]) =>
                          input.onChange(newAttachments)
                        }
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Box>
            )}

            <div className={styles['buttons']}>
              <Button.Cancel
                onClick={() => {
                  onClose();
                }}
              >
                {t('Cancel')}
              </Button.Cancel>
              <Button size="middle" style="blue" type="submit">
                {t('Send')}
              </Button>
              {step === 'CONFIRM' && (
                <MessageModal
                  open={true}
                  onClose={() => setStep('EDIT')}
                  title={t('Send Response')}
                  message={t(
                    'Are you sure you wish to send this inquiry response?'
                  )}
                  onSubmit={handleSubmit}
                  insertRoot
                  loading={submitting}
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
