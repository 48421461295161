import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';

import { AvailabilityAdjustmentSchedule } from './formValues';
import styles from './AutomaticAvailabilityAdjustmentEditor.module.css';
import { AdjustmentScheduleEditor } from './AdjustmentScheduleEditor';
import { AdjustmentScheduleTable } from './AdjustmentScheduleTable';

const getDefaultAdjustmentSchedule = (): AvailabilityAdjustmentSchedule => ({
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
  rules: [
    {
      conditions: [
        {
          valueType: 'OCCUPANCY_PERCENT',
          operator: 'LTE',
          value: 50,
          appliesTo: 'TOTAL',
        },
      ],
      actions: [
        {
          actionType: 'INCREASE_TOTAL',
          amount: 0,
          channels: [
            {
              channelCategory: 'COMMON',
              agentId: '',
            },
          ],
        },
      ],
    },
  ],
});

export const AutomaticAvailabilityAdjustmentEditor = () => {
  const { t } = useTranslation();
  const [activeScheduleIndex, setActiveScheduleIndex] = React.useState(0);

  return (
    <FieldArray name="schedules">
      {({ fields }) => (
        <>
          <div className={styles['select__box']}>
            <a
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['small'],
                baseStyles['green']
              )}
              onClick={() => {
                fields.push(getDefaultAdjustmentSchedule());
                setActiveScheduleIndex(fields.length ?? 0);
              }}
            >
              {t('Add Adjustment Schedule')}
            </a>
            {fields.value?.length > 1 && (
              <div className={styles['select__box__note']}>
                {t('Click on a row to edit the adjustment schedule')}
              </div>
            )}
          </div>
          <Box mt={2} mb={2}>
            <AdjustmentScheduleTable
              activeScheduleIndex={activeScheduleIndex}
              setActiveScheduleIndex={setActiveScheduleIndex}
            />
          </Box>
          {fields.value?.length > 0 && (
            <div
              key={activeScheduleIndex}
              className={baseStyles['base-selectFrame']}
              style={{ overflow: 'unset' }}
            >
              <div className={baseStyles['base-selectFrame__header']}>
                <p className={baseStyles['base-selectFrame__header__ttl']}>
                  {t('Selected adjustment schedule')}
                </p>
                <a
                  className={baseStyles['base-selectFrame__header__delete']}
                  onClick={() => {
                    fields.remove(activeScheduleIndex);
                    setActiveScheduleIndex(0);
                  }}
                >
                  {t('Remove this schedule')}
                </a>
              </div>
              <div className={baseStyles['base-selectFrame__body']}>
                <AdjustmentScheduleEditor
                  name={`schedules.${activeScheduleIndex}`}
                />
              </div>
            </div>
          )}
        </>
      )}
    </FieldArray>
  );
};
