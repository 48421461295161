import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { SystemFeeLineItem } from 'shared/models/swagger';
import { Tabs } from 'client/components/Tabs/Tabs';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

import { SystemFeeInvoiceDownloadCSVModal } from './SystemFeeInvoiceDownloadCSVModal/SystemFeeInvoiceDownloadCSVModal';
import { LineItemTable } from './LineItemTable';
import { FilterReservationsRequest } from './util';

export const LineItemTableTab = ({
  lineItems,
  showDepositDate,
  filterCondition,
  systemFeeInvoiceId,
}: {
  lineItems: SystemFeeLineItem[];
  showDepositDate: boolean;
  filterCondition: FilterReservationsRequest;
  systemFeeInvoiceId: string;
}) => {
  const { t } = useTranslation();
  const csvLoading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.csvLoading
  );
  const panes = React.useMemo(() => {
    return [
      {
        header: t('Nutmeg Settlement'),
        content: () => (
          <div
            style={{
              background: 'white',
              padding: '4px',
              border: '1px solid #cfdae1',
            }}
          >
            <Box mb={2} mt={2} display="flex" justifyContent="flex-end">
              <SystemFeeInvoiceDownloadCSVModal
                systemFeeInvoiceId={systemFeeInvoiceId}
                filters={filterCondition}
                trigger={
                  <Button style="gray" size="middle" loading={csvLoading}>
                    {t('Download CSV')}
                  </Button>
                }
                showDepositDate={false}
                settlementType={'NUTMEG'}
              />
            </Box>
            <LineItemTable
              lineItems={lineItems.filter(
                (item) => item?.settlement_type === 'NUTMEG'
              )}
              showDepositDate={false}
            />
          </div>
        ),
      },
      {
        header: t('Direct Settlement'),
        content: () => (
          <div
            style={{
              background: 'white',
              padding: '4px',

              border: '1px solid #cfdae1',
            }}
          >
            <Box mb={2} mt={2} display="flex" justifyContent="flex-end">
              <SystemFeeInvoiceDownloadCSVModal
                systemFeeInvoiceId={systemFeeInvoiceId}
                filters={filterCondition}
                trigger={
                  <Button style="gray" size="middle" loading={csvLoading}>
                    {t('Download CSV')}
                  </Button>
                }
                showDepositDate={showDepositDate ?? false}
                settlementType={'DIRECT'}
              />
            </Box>
            <LineItemTable
              lineItems={lineItems.filter(
                (item) => item?.settlement_type === 'DIRECT'
              )}
              showDepositDate={showDepositDate ?? false}
            />
          </div>
        ),
      },
    ];
  }, [lineItems, showDepositDate]);

  return (
    <>
      <Tabs panes={panes} />
    </>
  );
};
