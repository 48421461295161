import { v4 as uuidv4 } from 'uuid';

import * as Swagger from 'shared/models/swagger';

export type AdditionalImage = {
  title: string;
  description: string;
  imageUrl: string;
  linkUrl: string;
  key: string;
};
export type AdditionalImagesSection = {
  sectionType: 'ADDITIONAL_IMAGES_SECTION';
  isVisible: boolean;
  additionalImages: AdditionalImage[];
  key: string;
};
export type CustomIntroductionSection = {
  sectionType: 'CUSTOM_INTRODUCTION';
  isVisible: boolean;
  // Editor JS object type
  customIntroductionEditorJSObject: any;
  key: string;
};
export type RecommendedProductList = {
  sectionType: 'RECOMMENDED_PRODUCT_LIST';
  isVisible: boolean;
  productIds: string[];
  key: string;
};
export type CustomProductList = {
  sectionType: 'CUSTOM_PRODUCT_LIST';
  // isVisible always true so we omit it here
  title: string;
  productIds: string[];
  key: string;
};
export type TopPageSection =
  | AdditionalImagesSection
  | CustomIntroductionSection
  | RecommendedProductList
  | CustomProductList;
export type TopPageFormValues = {
  bannerImageUrls: string[];
  bannerTitle: string;
  bannerDescription: string;
  headerDescription: string;
  productListLanguage: Swagger.SourceLanguage;
  productIds: string[];
  sectionLanguage: Swagger.SourceLanguage;
  customSections: TopPageSection[];
};
export const getCustomSectionFormValues = (
  organization: Swagger.Organization | null,
  language: Swagger.SourceLanguage
): TopPageSection[] => {
  const defaultSections: TopPageSection[] = [
    {
      sectionType: 'ADDITIONAL_IMAGES_SECTION',
      key: 'ADDITIONAL_IMAGES_SECTION',
      isVisible: false,
      additionalImages: [],
    },
    {
      sectionType: 'CUSTOM_INTRODUCTION',
      key: 'CUSTOM_INTRODUCTION',
      isVisible: false,
      customIntroductionEditorJSObject: {},
    },
    {
      sectionType: 'RECOMMENDED_PRODUCT_LIST',
      key: 'RECOMMENDED_PRODUCT_LIST',
      isVisible: false,
      productIds: [],
    },
  ];
  const existingTopPageLayouts =
    organization?.booking_widget_design_params?.top_page_custom_layouts ?? [];
  const newTopPageLayout = existingTopPageLayouts?.find(
    (param) => param.language === language
  );
  const existingTopPageSections = newTopPageLayout?.sections ?? [];
  return [
    ...existingTopPageSections.map((topPageSection) =>
      convertSwaggerTopPageSectionToFormValues(topPageSection)
    ),
    ...defaultSections.filter(
      (section) =>
        !existingTopPageSections.some(
          (existingSection) =>
            existingSection.section_type === section.sectionType
        )
    ),
  ];
};
export const convertFormValuesToSwaggerTopPageSection = (
  section: TopPageSection
): Swagger.TopPageSection => {
  switch (section.sectionType) {
    case 'ADDITIONAL_IMAGES_SECTION':
      return {
        section_type: section.sectionType,
        key: section.key,
        is_visible: section.isVisible,
        additional_images: section.additionalImages.map((image) =>
          convertFormValuesToSwaggerAdditionalImage(image)
        ),
      };

    case 'RECOMMENDED_PRODUCT_LIST':
      return {
        section_type: section.sectionType,
        key: section.key,
        is_visible: section.isVisible,
        recommended_product_ids: section.productIds,
      };

    case 'CUSTOM_INTRODUCTION':
      return {
        section_type: section.sectionType,
        key: section.key,
        is_visible: section.isVisible,
        custom_introduction: {
          message: JSON.stringify(section.customIntroductionEditorJSObject),
        },
      };

    case 'CUSTOM_PRODUCT_LIST':
      return {
        section_type: section.sectionType,
        key: section.key,
        is_visible: true,
        custom_product_list: {
          title: section.title,
          product_ids: section.productIds,
        },
      };

    default:
      throw new Error(
        `unrecognized section type: ${(section as any).sectionType}`
      );
  }
};
export const convertSwaggerTopPageSectionToFormValues = (
  section: Swagger.TopPageSection
): TopPageSection => {
  switch (section.section_type) {
    case 'ADDITIONAL_IMAGES_SECTION':
      return {
        sectionType: 'ADDITIONAL_IMAGES_SECTION',
        key: section.key ?? 'ADDITIONAL_IMAGES_SECTION',
        isVisible: section.is_visible ?? false,
        additionalImages:
          section.additional_images?.map((image) => ({
            imageUrl: image.image_url ?? '',
            key: image.key ?? '',
            title: image.title ?? '',
            description: image.description ?? '',
            linkUrl: image.link_url ?? '',
          })) ?? [],
      };

    case 'RECOMMENDED_PRODUCT_LIST':
      return {
        sectionType: 'RECOMMENDED_PRODUCT_LIST',
        key: section.key ?? 'RECOMMENDED_PRODUCT_LIST',
        isVisible: section.is_visible ?? false,
        productIds: section.recommended_product_ids ?? [],
      };

    case 'CUSTOM_INTRODUCTION':
      return {
        sectionType: 'CUSTOM_INTRODUCTION',
        key: section.key ?? 'CUSTOM_INTRODUCTION',
        isVisible: section.is_visible ?? false,
        customIntroductionEditorJSObject: section.custom_introduction?.message
          ? JSON.parse(section.custom_introduction?.message)
          : {},
      };

    case 'CUSTOM_PRODUCT_LIST':
      return {
        sectionType: 'CUSTOM_PRODUCT_LIST',
        key: section.key ?? 'CUSTOM_PRODUCT_LIST',
        productIds: section.custom_product_list?.product_ids ?? [],
        title: section.custom_product_list?.title ?? '',
      };

    default:
      throw new Error(
        `unrecognized section type: ${section.section_type ?? '<nil>'}`
      );
  }
};
export const convertFormValuesToOrganizationPatch = (
  activeUserOrganization: Swagger.Organization | null,
  values: TopPageFormValues
): Swagger.Organization$Patch => {
  const existingProductDisplayOrders =
    activeUserOrganization?.booking_widget_design_params
      ?.product_display_orders ?? [];
  const newProductDisplayOrder = {
    language: values.productListLanguage,
    product_ids: values.productIds,
  };
  const existingTopPageLayouts =
    activeUserOrganization?.booking_widget_design_params
      ?.top_page_custom_layouts ?? [];
  const newTopPageLayouts: Swagger.BookingWidgetTopPageCustomLayout = {
    language: values.sectionLanguage,
    sections: values.customSections.map((section) =>
      convertFormValuesToSwaggerTopPageSection(section)
    ),
  };
  const allTopPageLayouts = [
    ...existingTopPageLayouts.filter(
      (params) => params.language !== newTopPageLayouts.language
    ),
    newTopPageLayouts,
  ];
  return {
    booking_widget_design_params: {
      ...activeUserOrganization?.booking_widget_design_params,
      banner_image_url:
        values.bannerImageUrls.length > 0 ? values.bannerImageUrls[0] : '',
      additional_banner_image_urls: values.bannerImageUrls.slice(1),
      banner_title: values.bannerTitle,
      banner_description: values.bannerDescription,
      header_description_text: values.headerDescription,
      product_display_orders: [
        ...existingProductDisplayOrders.filter(
          (order) => order.language !== newProductDisplayOrder.language
        ),
        newProductDisplayOrder,
      ],
      top_page_custom_layouts: allTopPageLayouts,
    },
  };
};
export const convertFormValuesToSwaggerAdditionalImage = (
  image: AdditionalImage
): Swagger.TopPageAdditionalImage => {
  return {
    key: image.key || uuidv4(),
    title: image.title,
    description: image.description,
    link_url: image.linkUrl,
    image_url: image.imageUrl,
  };
};
