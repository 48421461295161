// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { config } from 'client/config';
import { DeleteVehicleModal } from 'client/pages/DispatchCustomize/VehicleList/DeleteVehicleModal';
import { EditVehicleModal } from 'client/pages/DispatchCustomize/VehicleList/EditVehicleModal';
import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import deleteIcon from 'client/images/ic_delete.svg';
import editIcon from 'client/images/ic_edit.svg';
import type { DispatchResource } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { VehicleAvailabilityDisplayBox } from './VehicleAvailabilityDisplayBox';
import { VehicleAvailabilityModal } from './VehicleAvailabilityModal/VehicleAvailabilityModal';
import styles from './VehicleList.module.css';

type Props = {
  isActive?: boolean,
};

export const VehicleList = ({ isActive }: Props) => {
  const { t } = useTranslation();

  const vehicles = useSelector(allDispatchVehiclesSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const [existingVehicle, setExistingVehicle] =
    React.useState<DispatchResource | null>(null);

  const [showVehicleAvailabilityModal, setShowVehicleAvailabilityModal] =
    React.useState<boolean>(false);

  const [vehicleToDelete, setVehicleToDelete] =
    React.useState<DispatchResource | null>(null);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const getColumns = () => {
    return [
      {
        Header: '',
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
            onClick={() => {
              setExistingVehicle(cellInfo.original);
              setShowEditModal(true);
            }}
          >
            <img src={editIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: '',
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Delete')}
            onClick={() => {
              setVehicleToDelete(cellInfo.original);
              setShowDeleteModal(true);
            }}
          >
            <img src={deleteIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: t('Name'),
        id: 'key',
        accessor: 'key',
        Cell: (cellInfo) => <>{cellInfo.value}</>,
        width: 'long',
      },
      {
        Header: t('Capacity'),
        id: 'capacity',
        accessor: 'capacity',
        width: 'long',
      },
      {
        Header: t('Description'),
        id: 'description',
        accessor: 'description',
      },
    ];
  };

  const columns = getColumns();

  if (
    config.enableResourceManagementVehicle &&
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      id: 'availability',
      accessor: 'availability',
      Cell: (cellInfo) => (
        <div className={styles['resource_list__display_box__column']}>
          <div className={styles['resource_list__display_box__wrapper']}>
            <VehicleAvailabilityDisplayBox
              resourceKey={cellInfo.original.key}
            />
          </div>
          <div className={clsx(styles['resource_list__display_box__button'])}>
            <a
              className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
              data-text={t('Edit')}
              onClick={() => {
                setExistingVehicle(cellInfo.original);
                setShowVehicleAvailabilityModal(true);
              }}
            >
              <img src={editIcon} />
            </a>
          </div>
        </div>
      ),
    });
  }

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Vehicles')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['flex'],
              baseStyles['green']
            )}
            onClick={() => {
              setExistingVehicle(null);
              setShowEditModal(true);
            }}
          >
            {t('Add New Vehicle')}
          </a>
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <EditVehicleModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          existingVehicle={existingVehicle}
        />

        <DeleteVehicleModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          vehicleToDelete={vehicleToDelete}
        />

        {showVehicleAvailabilityModal && (
          <VehicleAvailabilityModal
            open={showVehicleAvailabilityModal}
            onClose={() => {
              setShowVehicleAvailabilityModal(false);
            }}
            resourceKey={existingVehicle?.key ?? ''}
          />
        )}

        <ManifestCustomizeCustomTable
          items={_.sortBy(vehicles, (v) => v.key)}
          columns={columns}
          usePaging={false}
          useScrollButton={false}
          locale={locale}
        />
      </div>
    </div>
  );
};
