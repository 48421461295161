import { createContext } from 'react';

type Props = {
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  selectedSessionTimestamp: string;
  setSelectedSessionTimestamp: (timestamp: string) => void;
};

// SessionSelectionContext can be used to pass the selected date and session index down the component tree
export const SessionSelectionContext = createContext<Props>({
  selectedDate: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedDate: () => {},
  selectedSessionTimestamp: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedSessionTimestamp: () => {},
});
