import ReactDOM from 'react-dom';

import styles from './PortalTooltip.module.css';

interface Props {
  text: string;
  style: any;
}

export const PortalTooltip = ({ text, style }: Props) => {
  const rootEl = document.getElementById('root');

  return ReactDOM.createPortal(
    <span style={style} className={styles['tooltip']}>
      {text}
    </span>,
    rootEl as Element
  );
};
