import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { getValidators } from 'shared/libraries/validate/validator';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { ListEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ListEditor';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';

/*
  NOTE(goro):
    preferred_language_iso2 : Used for displaying the field at Extranet. no need to store it to DynamoDB
    representative_name     : Used for keep the representative name char set (alphabet/kana/alphabet&kana). need to store it to DynamoDB. not used at Falconet
    hotel_information       : Used for the flag to show hotel form at Falconet. need to store it DynamoDB
    family_name             : Used for showing forms at Falconet. no neeed at Falconet
    given_name              : Same with the above item
    kana_family_name        : Same with the above item
    kana_given_name         : Same with the above item
*/
const disabledFields = [
  'email',
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'hotel_tbd_form',
];
const disabledExceptQuestionFormFields: string[] = [];

const isQuestionFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptQuestionFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isFormDisabled = (fieldName: string): boolean => {
  if (disabledFields.includes(fieldName)) {
    return true;
  }

  return false;
};

export const ReservationFormEditor = () => {
  const { t } = useTranslation();
  // NOTE(goro) : override CSS definition
  const transparentStyle = {
    backgroundColor: 'transparent',
  };

  const { translationTargetLanguage } = useTranslationTargetLanguage(t);
  const { required } = getValidators(t);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <>
      <FieldArray name="formFields">
        {({ fields }) => (
          <>
            {fields.length !== 0 &&
              fields.map((name, idx) => (
                <>
                  <div className={styles['p-productsTranslation']}>
                    <p className={styles['p-productsTranslation__ttl']}>
                      {t('Form {{index}}', {
                        index: idx + 1,
                      })}
                    </p>
                  </div>

                  <CollapsibleBox key={name} title={t('Question Text')}>
                    <TranslatedField
                      name={`${name}.prompt`}
                      validate={required}
                    >
                      {({
                        input,
                        meta: { error, touched },
                        translationInput,
                      }) => (
                        <ul className={styles['p-list']}>
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <p>
                                {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                              </p>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <TextArea
                                {...(input as any)}
                                disabled={isQuestionFormDisabled(
                                  fields.value[idx].key
                                )}
                                height={80}
                                error={Boolean(touched && error)}
                                helperText={error}
                              />
                            </div>
                          </li>
                          {translationTargetLanguage && (
                            <li className={styles['p-list__item']}>
                              <div className={styles['p-list__item__ttl']}>
                                <p>
                                  {getLanguageName(
                                    uppercaseIsoToLowercaseIso[
                                      translationTargetLanguage
                                    ],
                                    t
                                  )}
                                </p>
                              </div>
                              <div className={styles['p-list__item__body']}>
                                <TextArea
                                  {...(translationInput as any)}
                                  disabled={isQuestionFormDisabled(
                                    fields.value[idx].key
                                  )}
                                  height={80}
                                  error={Boolean(touched && error)}
                                  helperText={error}
                                />
                              </div>
                            </li>
                          )}
                        </ul>
                      )}
                    </TranslatedField>
                  </CollapsibleBox>

                  {/* Note: translation is only necessary for response constraint CHOICES */}
                  <Field name={`${name}.responseConstraint`}>
                    {({ input: { value } }) => (
                      <>
                        {value === 'CHOICES' && (
                          <CollapsibleBox
                            key={name}
                            title={t('Response Constraint')}
                          >
                            <ListEditor
                              title={t('Choices')}
                              fieldDisabled={isFormDisabled(
                                fields.value[idx].key
                              )}
                              buttonDisabled={true}
                              name={`${name}.choices`}
                              style={transparentStyle}
                            />
                          </CollapsibleBox>
                        )}
                      </>
                    )}
                  </Field>
                </>
              ))}
          </>
        )}
      </FieldArray>
    </>
  );
};
