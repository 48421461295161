import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import { Button, FieldWrapper } from 'client/components/Form';

import styles from './DraggableProductList.module.css';

type Props = {
  productIds: string[];
  label: string;
  setProductIds: (arg0: string[]) => void;
  options: {
    key: string;
    value: string;
    text: string;
  }[];
};
export const DraggableProductList = ({
  productIds,
  label,
  setProductIds,
  options,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['input-box']}>
      <FieldWrapper label={label}>
        <div className={styles['products-input-box']}>
          <div className={styles['input-box']}>
            <Button
              style="green"
              size="middle"
              onClick={() => setProductIds(['', ...productIds])}
            >
              {t('Add New Product')}
            </Button>
          </div>
          <div className={styles['input-box']}>
            {productIds.map((productId, idx) => {
              return (
                <DraggableSelect
                  key={productId || idx}
                  value={productId}
                  options={
                    // Options are any product IDs not yet present
                    options.filter(
                      (p) => productId === p.key || !productIds.includes(p.key)
                    )
                  }
                  index={idx}
                  onValueChange={(newValue) => {
                    const newProductIds = [...productIds];
                    newProductIds[idx] = newValue;
                    setProductIds(newProductIds);
                  }}
                  moveItem={(dragIndex: number, hoverIndex: number) => {
                    const draggingProductId = productIds[dragIndex];
                    const newProductIds = [...productIds];
                    newProductIds.splice(dragIndex, 1);
                    newProductIds.splice(hoverIndex, 0, draggingProductId);
                    setProductIds(newProductIds);
                  }}
                  deleteItem={() => {
                    const newProductIds = [...productIds];
                    newProductIds.splice(idx, 1);
                    setProductIds(newProductIds);
                  }}
                />
              );
            })}
          </div>
        </div>
      </FieldWrapper>
    </div>
  );
};
