import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuProps } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/Menu/menuType';

import styles from './PcSidebarMenu.module.css';

export const PcSidebarMenu = ({ sections }: MenuProps) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState<string | null>('main');

  const handleMenuItemClick = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
  };

  return (
    <nav className={styles['c-subnav']}>
      <p>{t('Table of Contents')}</p>
      {sections.map((section, index) => {
        if (!section.hasPermission) {
          return null;
        }

        return (
          <li
            key={index}
            className={activeSection === section.id ? styles['is-active'] : ''}
          >
            <a onClick={() => handleMenuItemClick(section.id)}>
              {section.title}
            </a>
          </li>
        );
      })}
    </nav>
  );
};
