import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_RESOURCE_QUANTITIES_SUCCESS,
  FETCH_RESOURCE_QUANTITIES_FAILURE,
  FETCH_RESOURCE_QUANTITIES_REQUEST,
  DELETE_RESOURCE_QUANTITY_FAILURE,
  DELETE_RESOURCE_QUANTITY_REQUEST,
  DELETE_RESOURCE_QUANTITY_SUCCESS,
  CREATE_RESOURCE_QUANTITY_REQUEST,
  UPDATE_RESOURCE_QUANTITY_FAILURE,
  UPDATE_RESOURCE_QUANTITY_SUCCESS,
  UPDATE_RESOURCE_QUANTITY_REQUEST,
  CREATE_RESOURCE_QUANTITY_FAILURE,
  CREATE_RESOURCE_QUANTITY_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';
import type {
  NewResourceQuantity,
  ResourceQuantityPatch,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchResourceQuantitiesRequest = () => ({
  type: FETCH_RESOURCE_QUANTITIES_REQUEST,
});

const fetchResourceQuantitiesSuccess = (response: any) => ({
  type: FETCH_RESOURCE_QUANTITIES_SUCCESS,
  response,
});

const fetchResourceQuantitiesFailure = (error: any) => ({
  type: FETCH_RESOURCE_QUANTITIES_FAILURE,
  error,
});

export const fetchResourceQuantities =
  (
    resource_type: Swagger.ResourceType,
    resource_key: string,
    date_local_start: string,
    date_local_end: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchResourceQuantitiesRequest());
    axios
      .get('/api/resourcequantities', {
        params: {
          resource_type,
          resource_key,
          date_local_start,
          date_local_end,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchResourceQuantitiesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchResourceQuantitiesFailure('canceled'));
        else dispatch(fetchResourceQuantitiesFailure(error));
      });
  };

const deleteResourceQuantityRequest = (id: string) => ({
  type: DELETE_RESOURCE_QUANTITY_REQUEST,
  id,
});

const deleteResourceQuantitySuccess = (response: any, id: string) => ({
  type: DELETE_RESOURCE_QUANTITY_SUCCESS,
  response,
  id,
});

const deleteResourceQuantityFailure = (error: any) => ({
  type: DELETE_RESOURCE_QUANTITY_FAILURE,
  error,
});

export const deleteResourceQuantity =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteResourceQuantityRequest(id));
    fetch(`/api/resourcequantities/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteResourceQuantitySuccess(result, id)))
      .catch((error) => dispatch(deleteResourceQuantityFailure(error)));
  };

const createResourceQuantityRequest = (
  newResourceQuantity: NewResourceQuantity
) => ({
  type: CREATE_RESOURCE_QUANTITY_REQUEST,
  newResourceQuantity,
});

const createResourceQuantitySuccess = (response: any) => ({
  type: CREATE_RESOURCE_QUANTITY_SUCCESS,
  response,
});

const createResourceQuantityFailure = (error: any) => ({
  type: CREATE_RESOURCE_QUANTITY_FAILURE,
  error,
});

export const createResourceQuantity =
  (newResourceQuantity: NewResourceQuantity) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createResourceQuantityRequest(newResourceQuantity));
    return axios
      .post('/api/resourcequantities', newResourceQuantity, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createResourceQuantitySuccess(response.data));
      })
      .catch((err) => {
        dispatch(createResourceQuantityFailure(err.message));
      });
  };

const updateResourceQuantityRequest = (
  id: string,
  patch: ResourceQuantityPatch
) => ({
  type: UPDATE_RESOURCE_QUANTITY_REQUEST,
  id,
  patch,
});

const updateResourceQuantitySuccess = (response: any) => ({
  type: UPDATE_RESOURCE_QUANTITY_SUCCESS,
  response,
});

const updateResourceQuantityFailure = (error: any) => ({
  type: UPDATE_RESOURCE_QUANTITY_FAILURE,
  error,
});

export const updateResourceQuantity =
  (id: string, patch: ResourceQuantityPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateResourceQuantityRequest(id, patch));
    return axios
      .patch(`/api/resourcequantities/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateResourceQuantitySuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateResourceQuantityFailure(err.message));
      });
  };
