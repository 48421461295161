import moment from 'moment-timezone';

import type { LanguageISO } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { JaranReservationImportOrder } from 'shared/models/swagger';

export type JaranCsvImportStatus =
  | 'JARAN_CSV_IMPORT_STATUS_PENDING'
  | 'JARAN_CSV_IMPORT_STATUS_DONE'
  | 'JARAN_CSV_IMPORT_STATUS_FILE_ERROR'
  | 'JARAN_CSV_IMPORT_STATUS_CONTRACT_ERROR';

export const getJaranCsvImportStatusText = (
  status: JaranCsvImportStatus,
  t: TranslateFuncType
): string => {
  switch (status) {
    case 'JARAN_CSV_IMPORT_STATUS_PENDING':
      return t('Pending');
    case 'JARAN_CSV_IMPORT_STATUS_DONE':
      return t('Completed');
    case 'JARAN_CSV_IMPORT_STATUS_FILE_ERROR':
      return t('Error');
    case 'JARAN_CSV_IMPORT_STATUS_CONTRACT_ERROR':
      return t('Error');
  }
};

export type JalanErrorReservationStatus =
  | 'ERROR_JARAN_RESERVATION_STATUS_OTHER_ERROR'
  | 'ERROR_JARAN_RESERVATION_STATUS_NOT_FOUND_INSTANCE'
  | 'ERROR_JARAN_RESERVATION_STATUS_NO_START_TIME_SETTING'
  | 'ERROR_JARAN_RESERVATION_STATUS_NO_UNIT_SETTING'
  | 'ERROR_JARAN_RESERVATION_STATUS_NO_MATCH_UNIT'
  | 'ERROR_JARAN_RESERVATION_STATUS_NO_MATCH_PRODUCT';

export const getJalanErrorReservationStatusText = (
  t: TranslateFuncType,
  status?: JalanErrorReservationStatus
): string => {
  switch (status) {
    case 'ERROR_JARAN_RESERVATION_STATUS_NOT_FOUND_INSTANCE':
      return t(
        'There is no inventory for the specified "体験開始日" & "体験開始時間"'
      );
    case 'ERROR_JARAN_RESERVATION_STATUS_NO_START_TIME_SETTING':
      return t('No start time setting with Jalan product');
    case 'ERROR_JARAN_RESERVATION_STATUS_NO_UNIT_SETTING':
      return t('No price setting for the specified participation date');
    case 'ERROR_JARAN_RESERVATION_STATUS_NO_MATCH_UNIT':
      return t('No unit name setting with Jalan product');
    case 'ERROR_JARAN_RESERVATION_STATUS_NO_MATCH_PRODUCT':
      return t('No settings for Jalan product');
    case 'ERROR_JARAN_RESERVATION_STATUS_OTHER_ERROR':
      return t(
        "Failed to create a new reservations. Please check product's price and participation rules and Jalan Start time & Unit Settings"
      );
    default:
      return t('Error');
  }
};

export const getImportTabCustomTableItems = (
  orders: JaranReservationImportOrder[]
): Record<string, any>[] => {
  return orders.map((order) => {
    return {
      ...order,
    };
  });
};

export const getImportTabCustumTableColumns = (
  t: TranslateFuncType,
  locale: LanguageISO,
  timeZone: string,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  setResultTargetOrderId: React.Dispatch<React.SetStateAction<string>>
): CustomTableColumn[] => {
  return [
    {
      Header: t('Upload Date'),
      id: 'upload_date',
      width: 'short',
      accessor: (row) => {
        if (row.created_at_utc) {
          return moment
            .tz(row.created_at_utc, timeZone)
            .locale(locale)
            .format('lll');
        }
        return '';
      },
    },
    {
      Header: t('File Name'),
      id: 'file_name',
      accessor: 'file_name',
      width: 'short',
    },
    {
      Header: t('Status'),
      id: 'jaran_csv_import_status',
      accessor: (row) =>
        getJaranCsvImportStatusText(row.jaran_csv_import_status, t),
      width: 'short',
    },
    {
      Header: t('Import Complete Time'),
      id: 'import_completed_at_utc',
      accessor: (row) => {
        if (row.import_completed_at_utc) {
          return moment
            .tz(row.import_completed_at_utc, timeZone)
            .locale(locale)
            .format('lll');
        }
        return '';
      },
      width: 'short',
    },
    {
      Header: t('Import Target Count'),
      id: 'import_target_count',
      accessor: 'import_target_count',
      width: 'short',
    },
    {
      Header: t('Result'),
      id: 'result',
      Cell: (cellInfo: any) => {
        const showErrorModal =
          cellInfo.original?.jaran_csv_import_status ===
            'JARAN_CSV_IMPORT_STATUS_DONE' &&
          cellInfo.original?.error_jaran_reservations?.length !== 0;

        if (
          cellInfo.original?.jaran_csv_import_status ===
          'JARAN_CSV_IMPORT_STATUS_FILE_ERROR'
        ) {
          return (
            <>
              <div>{t('Incorrect file format.')}</div>
              <div>
                {t('Please upload the file downloaded from Jalan website.')}
              </div>
            </>
          );
        }

        if (
          cellInfo.original?.jaran_csv_import_status ===
          'JARAN_CSV_IMPORT_STATUS_CONTRACT_ERROR'
        ) {
          return (
            <>
              <div>
                {t(
                  'Agent "Jalan" is not registered, yet. Please contact Nutmeg support team for the Agent configuration.'
                )}
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              {t('Saved')}: {cellInfo.original?.import_success_count || 0}
            </div>
            {!showErrorModal && (
              <div>
                {t('Error')}:{' 0'}
              </div>
            )}
            {showErrorModal && (
              <>
                <a
                  onClick={() => {
                    setResultTargetOrderId(cellInfo.original?.id || '');
                    setShowModal(true);
                  }}
                >
                  {t('Error')}:{' '}
                  {cellInfo.original?.error_jaran_reservations?.length || 0}
                </a>
              </>
            )}
            <div>
              {t('Skipped')}:{' '}
              {cellInfo.original?.skip_jaran_reservations?.length || 0}
            </div>
          </>
        );
      },
      width: 'short',
    },
  ];
};
