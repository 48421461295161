import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';

import baseStyles from 'client/v3-base.module.css';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Product, UnitPricing } from 'shared/models/swagger';
import { makeProductInstancesSelector } from 'client/reducers/productInstances';
import { fetchProductInstances } from 'client/actions/productInstances';
import { getBookingWidgetEmbeddedCheckoutUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCheckoutUrl';

import styles from './ProductDetailsSection.module.css';

type Props = {
  product: Product;
  contentLanguage: string;
};

const determineGuestCount = (
  units: UnitPricing[] | undefined
): Record<string, string> => {
  const guestCount: Record<string, string> = {};

  if (units && units.length > 0) {
    const firstUnit = units[0];

    switch (firstUnit.method) {
      // If price is PER_PARTICIPANT
      case 'PER_PARTICIPANT':
        if (firstUnit.guest_type) {
          guestCount[firstUnit.guest_type.key] = '2';
        }
        break;

      case 'PER_BOOKING':
        // If price is PER_BOOKING and unit is set
        if (
          firstUnit.per_booking_guest_types &&
          firstUnit.per_booking_guest_types.length > 0
        ) {
          guestCount[firstUnit.per_booking_guest_types[0].key] = '2';
        }
        // If price is PER_BOOKING and unit is not set
        else {
          guestCount['guest'] = '2';
        }
        break;

      // TODO: handle PER_GROUP
      // case 'PER_GROUP':

      default:
        break;
    }
  }

  return guestCount;
};

export const BookingWidgetPreview = ({ product, contentLanguage }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  // Fetch product instances
  useEffect(() => {
    const currentDate = moment();
    const futureDate = moment(currentDate).add(1, 'months');
    dispatch(fetchProductInstances(product.id, currentDate, futureDate));
  }, []);

  const productInstances = useSelector(
    makeProductInstancesSelector(product.id)
  );

  const lastProductInstance =
    productInstances.length > 0
      ? productInstances[productInstances.length - 1]
      : null;

  // Set 2 persons for the first guest type to show unit prices during checkout
  // since some products may have multiple guest types which could be too many to show in preview
  const units = lastProductInstance?.units;
  const guestCount = determineGuestCount(units);

  const url = getBookingWidgetEmbeddedCheckoutUrl(
    activeUserOrganization?.booking_widget_api_key ?? '',
    product.id,
    lastProductInstance ? lastProductInstance.id : '',
    JSON.stringify(guestCount),
    contentLanguage
  );

  return (
    <>
      {activeUserOrganization?.booking_widget_api_key && (
        <section className={clsx('g-section', baseStyles['u-mt-6'])}>
          <p className={styles['p-productsDetail__ttl__noBg']}>
            {t('Checkout Page (Preview)')}
          </p>

          <div className={baseStyles['u-mt-2']}>
            <div
              className={
                styles['p-productsDetail__bookingwidgetpreview__wrapper']
              }
            >
              <iframe
                // Note: for now height is fixed, there is no way to adjust height according to content height unless we can communicate with parent using postMessage
                height={900}
                id={`nutmeg-embed-iframe-checkout-${product.id}`}
                src={url}
                className={styles['p-productsDetail__bookingwidgetpreview']}
                title="embedded-checkout"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};
