import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingDeadlineTimelineItem } from 'client/components/BookingDeadlineTimeline/BookingDeadlineTimelineItem';
import {
  compareRelativeDeadlines,
  getRelativeDeadlineDescription,
} from 'client/libraries/util/productShape';
import type { BookingDeadline } from 'client/libraries/util/productShape';

import styles from './BookingDeadlineTimeline.module.css';

type Props = {
  instantBookingDeadline?: BookingDeadline | null;
  requestBookingDeadline?: BookingDeadline | null;
};
export const BookingDeadlineTimeline = ({
  instantBookingDeadline,
  requestBookingDeadline,
}: Props) => {
  const { t } = useTranslation();
  const bookingDeadlineTimelineItems: {
    label: string;
    content: string;
    color?: 'blue' | 'yellow';
  }[] = [];

  if (instantBookingDeadline) {
    bookingDeadlineTimelineItems.push({
      label: t('Instant'),
      content: getRelativeDeadlineDescription(instantBookingDeadline, t),
      color: 'blue',
    });
  }

  if (requestBookingDeadline) {
    if (
      instantBookingDeadline &&
      compareRelativeDeadlines(requestBookingDeadline, instantBookingDeadline) <
        0
    ) {
      bookingDeadlineTimelineItems.unshift({
        label: t('Request'),
        content: getRelativeDeadlineDescription(requestBookingDeadline, t),
        color: 'yellow',
      });
    } else {
      bookingDeadlineTimelineItems.push({
        label: t('Request'),
        content: getRelativeDeadlineDescription(requestBookingDeadline, t),
        color: 'yellow',
      });
    }
  }

  bookingDeadlineTimelineItems.push({
    label: t('Unavailable'),
    content: t('Until Participation'),
  });
  return (
    <div className={styles['page-productsRegist__timeline']}>
      {bookingDeadlineTimelineItems.map((item, idx) => (
        <BookingDeadlineTimelineItem
          key={idx}
          label={item.label}
          content={item.content}
          color={item.color}
        />
      ))}
      <p className={styles['page-productsRegist__timeline__last']}>
        {t('Participation Date')}
      </p>
    </div>
  );
};
