import { combineReducers } from 'redux';

import {
  SET_CUSTOMER_EVENT_FOCUSED_SITE,
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
} from 'client/constants/ActionTypes';

const focusedSite = (state = 'website', action: any) => {
  switch (action.type) {
    case SET_CUSTOMER_EVENT_FOCUSED_SITE:
      return action.focusedSite;
    default:
      return state;
  }
};

type State = {
  focusedSite: string;
};

const reducer = combineReducers<State>({
  focusedSite,
});

export const customerEventControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
