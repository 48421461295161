import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Input } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateCustomer } from 'client/actions/customers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { CustomerFormFieldSet } from 'shared/models/swagger';

import { CustomerContext } from './CustomerContext';
import { CustomerFormFieldResponseInput } from './CustomerFormFieldResponseInput';

interface FormValues {
  supplierReference: string;
  familyName: string;
  givenName: string;
  kanaFamilyName: string;
  kanaGivenName: string;
  formFieldResponses: {
    [key: string]: {
      value?: string;
      year?: string;
      month?: string;
      day?: string;
      postalCode?: string;
      country?: string;
      province?: string;
      city?: string;
      addressLine?: string;
    };
  };
}

interface Props {
  onClose: () => void;
}

export const EditCustomerInformationModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customer = React.useContext(CustomerContext);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const customerFormFieldSet = (
    activeUserOrganization?.customer_ledger_settings?.form_field_sets ?? []
  ).find(
    (fieldSet: CustomerFormFieldSet) =>
      fieldSet.content_language === (customer?.language || 'JA_JP')
  );

  const initialValues: FormValues = React.useMemo(() => {
    const formFieldResponses: FormValues['formFieldResponses'] = {};

    for (const formField of customerFormFieldSet?.form_fields ?? []) {
      const response = customer?.form_field_responses?.find(
        (response) => response.key === formField.key
      );

      if (formField.type === 'ADDRESS') {
        formFieldResponses[formField.key ?? ''] = {
          postalCode: response?.address?.postal_code,
          country: response?.address?.country,
          province: response?.address?.province,
          city: response?.address?.city,
          addressLine: response?.address?.address_line,
        };
      } else if (formField.format === 'YYYY_MM_DD') {
        const [year, month, day] = response?.value?.split('-') ?? [];
        formFieldResponses[formField.key ?? ''] = {
          year,
          month,
          day,
        };
      } else {
        formFieldResponses[formField.key ?? ''] = {
          value: response?.value ?? '',
        };
      }
    }

    return {
      supplierReference: customer?.supplier_reference?.value ?? '',
      familyName: customer?.family_name ?? '',
      givenName: customer?.given_name ?? '',
      kanaFamilyName: customer?.kana_family_name ?? '',
      kanaGivenName: customer?.kana_given_name ?? '',
      formFieldResponses,
    };
  }, [customer, customerFormFieldSet]);

  const convertFormValuesToCustomer = (values: FormValues) => {
    const formFieldResponses = Object.entries(values.formFieldResponses).map(
      ([key, value]) => {
        const formField = (customerFormFieldSet?.form_fields || []).find(
          (formField) => formField.key === key
        );

        if (formField?.type === 'ADDRESS') {
          return {
            key,
            address: {
              postal_code: value.postalCode,
              country: value.country,
              province: value.province,
              city: value.city,
              address_line: value.addressLine,
            },
          };
        } else if (formField?.format === 'YYYY_MM_DD') {
          return {
            key,
            value: `${value.year}-${value.month}-${value.day}`,
          };
        } else {
          return {
            key,
            value: value.value,
          };
        }
      }
    );

    return {
      supplier_reference: {
        value: values.supplierReference,
      },
      family_name: values.familyName,
      given_name: values.givenName,
      kana_family_name: values.kanaFamilyName,
      kana_given_name: values.kanaGivenName,
      form_field_responses: formFieldResponses,
    };
  };

  return (
    <Modal
      insertRoot
      title={t('Edit Customer Information')}
      open={true}
      onClose={onClose}
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          const patch = convertFormValuesToCustomer(values);
          await dispatch(updateCustomer(customer?.id ?? '', patch));
          onClose();
        }}
        debug={console.log}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="supplierReference">
                  {({ input, meta }) => (
                    <Input
                      label={t('Supplier Customer Reference')}
                      error={meta.error && meta.touched && meta.error}
                      {...input}
                    />
                  )}
                </Field>
              </Modal.Box>
              {(customerFormFieldSet?.customer_name_format === 'ALPHABET' ||
                customerFormFieldSet?.customer_name_format ===
                  'KANA_OR_ALPHABET') && (
                <>
                  <Modal.Box>
                    <Field name="familyName">
                      {({ input, meta }) => (
                        <Input
                          label={t('Family Name')}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>

                  <Modal.Box>
                    <Field name="givenName">
                      {({ input, meta }) => (
                        <Input
                          label={t('Given Name')}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                </>
              )}

              {(customerFormFieldSet?.customer_name_format === 'KANA' ||
                customerFormFieldSet?.customer_name_format ===
                  'KANA_OR_ALPHABET') && (
                <>
                  <Modal.Box>
                    <Field name="kanaFamilyName">
                      {({ input, meta }) => (
                        <Input
                          label={t('Family Name(Kana)')}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>

                  <Modal.Box>
                    <Field name="kanaGivenName">
                      {({ input, meta }) => (
                        <Input
                          label={t('Given Name(Kana)')}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                </>
              )}
              {customerFormFieldSet?.form_fields?.map((formField) => (
                <CustomerFormFieldResponseInput
                  key={formField.key}
                  formField={formField}
                />
              ))}
            </Modal.Content>
            <Modal.Actions>
              <Button style="gray" size="middle" onClick={() => form.reset()}>
                {t('Discard')}
              </Button>
              <Button
                style="blue"
                size="middle"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
