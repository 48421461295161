import { Link } from 'react-router-dom';
import clsx from 'clsx';

import type { TranslateFuncType } from 'client/components/Translate';
import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkUnfinishedIcon from 'client/images/ic_check_unfinished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';
import { Box } from 'client/components/Box/Box';

const getCheckinIcon = (status: string): any => {
  switch (status) {
    case 'CHECKED_IN':
      return checkFinishedIcon;
    case 'IN_PROGRESS':
      return checkInProgressIcon;
    default:
      return checkUnfinishedIcon;
  }
};

export type ColumnType = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: ((arg0: any) => string) | keyof ReservationSummaryShape;
  width?: 'short' | 'middle' | 'long';
  Cell?: (arg0: any) => any;
  th?: boolean;
};

export type ReservationListColumn = ColumnType & {
  HeaderElement?: (arg0: any) => JSX.Element;
};

export const getReservationTableColumns = (
  t: TranslateFuncType,
  locale: string,
  annualPassMode?: boolean,
  partnershipMode?: boolean
): ReservationListColumn[] => {
  const reservationsBasePath = partnershipMode
    ? '/partnership/reservations'
    : annualPassMode
    ? '/annualpasses'
    : '/reservations';

  return [
    {
      Header: '',
      id: 'edit',
      width: 'short',
      th: true,
      Cell: (cellInfo: any) => (
        <Link
          to={`${reservationsBasePath}/${cellInfo.original.id}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </Link>
      ),
    },
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference,
      width: 'middle',
      Cell: (cellInfo) => (
        <Link to={`${reservationsBasePath}/${cellInfo.original.id}`}>
          {cellInfo.original.agent_reference}
        </Link>
      ),
    },
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: 'booked_at',
      Cell: (row) => row.value.locale(locale).format('lll'),
      width: 'middle',
    },
    {
      Header: t('Last Updated'),
      id: 'updated_at',
      accessor: 'updated_at',
      Cell: (row) => row.value.locale(locale).format('lll'),
      width: 'middle',
    },
    {
      Header: t('Status'),
      id: 'status',
      width: 'middle',
      accessor: (row) => t(row.status),
    },
    {
      Header: t('Customer'),
      id: 'guest_display_name',
      width: 'middle',
      accessor: 'guest_display_name',
      Cell: (cellInfo) => (
        <div>
          {cellInfo.original.guest_display_name
            .split(' ')
            .map((s: any, idx: number) => (
              <span
                key={idx}
                style={{
                  display: 'inline-block',
                  marginRight: '2px',
                }}
              >
                {s}{' '}
              </span>
            ))}
        </div>
      ),
    },
    {
      Header: t('Product'),
      id: 'product_name',
      accessor: 'product_name',
      Cell: (cellInfo) =>
        partnershipMode ? (
          <>{cellInfo.value}</>
        ) : (
          <Link to={`/products/${cellInfo.original.product_id}`}>
            {cellInfo.value}
          </Link>
        ),
      width: 'long',
    },
    {
      Header: t('Internal Product Name'),
      id: 'internal_product_name',
      accessor: 'internal_product_name',
      Cell: (cellInfo) => (
        <Link to={`/products/${cellInfo.original.product_id}`}>
          {cellInfo.value ?? ''}
        </Link>
      ),
      width: 'long',
    },
    {
      Header: t('Product Code'),
      id: 'supplier_product_reference',
      width: 'middle',
      accessor: 'supplier_product_reference',
    },
    {
      Header: t('Participation'),
      id: 'participates_at',
      accessor: 'participates_at',
      width: 'middle',
      Cell: (row) =>
        `${row.value.locale(locale).format('lll')} (GMT${row.value
          .locale(locale)
          .format('Z')})`,
    },
    {
      Header: t('Units'),
      id: 'guest_description',
      width: 'middle',
      accessor: 'guest_description',
    },
    {
      Header: t('Total Pax'),
      id: 'guest_count',
      width: 'short',
      accessor: 'guest_count',
    },
    {
      Header: t('Hotel'),
      id: 'hotel',
      width: 'long',
      accessor: 'hotel',
    },
    {
      Header: t('Pickup/Checkin time'),
      id: 'pickup_checkin_time',
      width: 'short',
      accessor: 'pickup_checkin_time',
    },
    {
      Header: t('Pickup/Checkin location'),
      id: 'pickup_checkin_location',
      width: 'long',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: t('Payment Type'),
      id: 'payment_type',
      width: 'short',
      accessor: (row) => row.payment_type && t(row.payment_type),
    },
    {
      Header: t('Payment Method'),
      id: 'payment_method',
      width: 'short',
      accessor: (row) => row.payment_method && t(row.payment_method),
    },
    {
      Header: t('Transportation'),
      id: 'transportation',
      width: 'middle',
      accessor: 'transportation',
    },
    {
      Header: t('Add-ons'),
      id: 'add_ons',
      width: 'middle',
      accessor: 'add_ons',
    },
    {
      Header: t('Source'),
      id: 'booking_source',
      accessor: 'booking_source',
      width: 'middle',
    },
    {
      Header: t('Remarks'),
      id: 'agent_notes',
      width: 'long',
      accessor: 'agent_notes',
      Cell: (cellInfo) => (
        <div className="newline">
          <TruncatedTextWithSeeMoreButton
            text={cellInfo.original.agent_notes || ''}
          />
        </div>
      ),
    },
    {
      Header: t('Replies'),
      id: 'supplier_notes',
      width: 'long',
      accessor: 'supplier_notes',
      Cell: (cellInfo) => (
        <div className="newline">
          <TruncatedTextWithSeeMoreButton
            text={cellInfo.original.supplier_notes || ''}
          />
        </div>
      ),
    },
    {
      Header: t('Supplier'),
      id: 'supplier_name',
      width: 'middle',
      accessor: 'supplier_name',
    },
    {
      Header: t('Confirmation Number'),
      id: 'supplier_reference',
      width: 'middle',
      accessor: 'supplier_reference',
    },
    {
      Header: '#',
      id: 'id',
      accessor: 'id',
      width: 'long',
      Cell: (row) => (
        <Link to={`${reservationsBasePath}/${row.value}`}>{row.value}</Link>
      ),
    },
    {
      Header: t('Supplier Reservation Short ID'),
      id: 'supplier_short_reference',
      width: 'middle',
      accessor: 'supplier_short_reference',
    },
    {
      Header: t('(Internal) Last Updated By'),
      id: 'supplier_last_updated_by_actor_name_only',
      width: 'middle',
      accessor: 'supplier_last_updated_by_actor_name_only',
    },
    {
      Header: t('(Internal) Last Updated By'),
      id: 'agent_guest_last_updated_by_actor_name_only',
      width: 'middle',
      accessor: 'agent_guest_last_updated_by_actor_name_only',
    },
    {
      Header: t('Email Payment'),
      id: 'uses_email_payment',
      width: 'short',
      accessor: 'uses_email_payment',
    },
    {
      Header: t('Promo Code'),
      id: 'promo_code',
      width: 'middle',
      accessor: 'promo_code',
    },
    {
      Header: t('Memo'),
      id: 'supplier_internal_notes',
      width: 'long',
      accessor: 'supplier_internal_notes',
      Cell: (cellInfo) => (
        <div className="newline">
          <TruncatedTextWithSeeMoreButton
            text={cellInfo.original.supplier_internal_notes || ''}
          />
        </div>
      ),
    },
    {
      Header: t('Internal Note'),
      id: 'supplier_internal_notes_for_dispatch',
      width: 'long',
      accessor: 'supplier_internal_notes_for_dispatch',
      Cell: (cellInfo) => (
        <div className="newline">
          <TruncatedTextWithSeeMoreButton
            text={cellInfo.original.supplier_internal_notes_for_dispatch || ''}
          />
        </div>
      ),
    },
    {
      Header: t('Language'),
      id: 'reservation_language',
      width: 'short',
      accessor: 'reservation_language',
    },
    {
      Header: t('E-ticket Status'),
      id: 'eticket_status',
      width: 'short',
      accessor: 'eticket_status',
      Cell: (row) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={getCheckinIcon(row.value)} />
        </Box>
      ),
    },
    {
      Header: t('E-ticket First Use'),
      id: 'eticket_first_footprinted_at',
      accessor: 'eticket_first_footprinted_at',
      width: 'middle',
      Cell: (row) => {
        if (!row.value) {
          return '';
        }
        return `${row.value.locale(locale).format('lll')} (GMT${row.value
          .locale(locale)
          .format('Z')})`;
      },
    },
    {
      Header: t('E-ticket Last Use'),
      id: 'eticket_last_footprinted_at',
      accessor: 'eticket_last_footprinted_at',
      width: 'middle',
      Cell: (row) => {
        if (!row.value) {
          return '';
        }
        return `${row.value.locale(locale).format('lll')} (GMT${row.value
          .locale(locale)
          .format('Z')})`;
      },
    },
    {
      Header: t('E-ticket Use Day Count'),
      id: 'eticket_footprint_day_count',
      accessor: 'eticket_footprint_day_count',
      width: 'short',
    },
    {
      Header: t('E-ticket Expiration'),
      id: 'eticket_expire_at',
      accessor: 'eticket_expire_at',
      width: 'middle',
      Cell: (row) => {
        if (!row.value) {
          return '';
        }
        return `${row.value.locale(locale).format('lll')} (GMT${row.value
          .locale(locale)
          .format('Z')})`;
      },
    },
    {
      Header: t('Auto Continuing'),
      id: 'automatic_continuing_status',
      accessor: 'automatic_continuing_status',
      width: 'short',
      Cell: (row) => {
        return `${row.value ? t('ON') : t('OFF')}`;
      },
    },
    {
      Header: t('Landing Source'),
      id: 'landing_source',
      accessor: 'landing_source',
      width: 'middle',
    },
  ];
};
