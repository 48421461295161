import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Highlights.module.css';

type Props = {
  highlights: string[];
};
export const Highlights = ({ highlights }: Props) => {
  const { t } = useTranslation();
  return (
    <ul className={styles['c-point']}>
      {highlights.map((highlight, idx) => (
        <li key={highlight} className={styles['c-point__item']}>
          <p className={styles['c-point__item__title']}>
            {t('Highlight #{{highlightNumber}}', {
              highlightNumber: idx + 1,
            })}
          </p>
          <p className={styles['c-point__item__body']}>{highlight}</p>
        </li>
      ))}
    </ul>
  );
};
