import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';
import type { ProductCollectionHighlight } from 'shared/models/swagger';

import styles from './PrivateMarketplaceProductCollectionPages.module.css';

type Props = {
  highlight?: ProductCollectionHighlight;
  onSave: (arg0: ProductCollectionHighlight) => void;
  open: boolean;
  onClose: () => any;
};
export const EditProductCollectionHighlightModal = ({
  highlight,
  onSave,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [title, setTitle] = React.useState<string>(highlight?.title || '');
  const [description, setDescription] = React.useState<string>(
    highlight?.description || ''
  );
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string>(
    highlight?.thumbnail_url || ''
  );

  const reset = () => {
    setTitle(highlight?.title || '');
    setDescription(highlight?.description || '');
    setThumbnailUrl(highlight?.thumbnail_url || '');
  };

  return (
    <Modal title={t('Edit Highlight')} open={open} onClose={onClose}>
      <Modal.Content>
        <div className={styles['input-box']}>
          <Input
            label={t('Title')}
            value={title}
            onChange={(event, { value }) => setTitle(value)}
          />
        </div>
        <div className={styles['input-box']}>
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(event, { value }) => setDescription(value)}
          />
        </div>
        <div className={styles['input-box']}>
          <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
            <SingleImageInput
              acceptedFileTypes={['image/jpeg', 'image/png']}
              onUploadFinished={(filename, objectUrl) =>
                setThumbnailUrl(objectUrl)
              }
              initialValue={thumbnailUrl}
              getFileSavePath={(filename) =>
                `${activeUserOrganization?.id || ''}/${filename}`
              }
            />
          </FieldWrapper>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={reset}>
          {t('Discard')}
        </Button>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            onSave({
              title,
              description,
              thumbnail_url: thumbnailUrl,
            });
            onClose();
          }}
        >
          {highlight ? t('Change Highlight') : t('Add Highlight')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
