import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { ManifestActiveResourceContext } from 'client/contexts/ManifestActiveResourceContext';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

interface Props {
  reservation: ManifestReservationShape;
}

export const EditCell = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const activeResource = useContext(ManifestActiveResourceContext);

  let reservationHasTransportRouteAssignments = false;
  let reservationHasReservationAssignments = false;
  if (activeResource) {
    switch (activeResource.activeResourceType) {
      case 'guide':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_guides?.some(
            (guide) => guide === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_guides?.some(
              (guide) => guide === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'crew':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_crew?.some(
            (crew) => crew === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_crew?.some(
              (crew) => crew === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'vehicle':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_vehicles?.some(
            (vehicle) => vehicle === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_vehicles?.some(
              (vehicle) => vehicle === activeResource.activeResourceKey
            )
          )
        );
        break;
      case 'other':
        reservationHasReservationAssignments = Boolean(
          reservation?.dispatch_misc_resources?.some(
            (otherResource) =>
              otherResource === activeResource.activeResourceKey
          )
        );
        reservationHasTransportRouteAssignments = Boolean(
          reservation?.transport_route?.some((route) =>
            route?.dispatch_misc_resources?.some(
              (otherResource) =>
                otherResource === activeResource.activeResourceKey
            )
          )
        );
        break;
    }
  }

  let tooltipText = '';
  if (
    reservationHasTransportRouteAssignments &&
    !activeResource?.shouldShowTransportRouteAssignmentFormat
  ) {
    tooltipText = t('Transport routes are assigned');
  }
  if (
    reservationHasReservationAssignments &&
    activeResource?.shouldShowTransportRouteAssignmentFormat
  ) {
    tooltipText = t('Assigned to reservation');
  }

  return (
    <div className={styles['infoBox']}>
      <Link
        to={`/reservations/${reservation.id}`}
        data-text={t('Detail')}
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <i className="c-icon-outline-files-file-02"></i>
        <p style={{ marginLeft: '4px' }}>{t('Details')}</p>
      </Link>
      {tooltipText && (
        <Tooltip text={tooltipText}>
          <i className="c-icon-outline-general-info-circle"></i>
        </Tooltip>
      )}
    </div>
  );
};
