import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_SALES_OFFER_STATS_FAILURE,
  FETCH_SALES_OFFER_STATS_REQUEST,
  FETCH_SALES_OFFER_STATS_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GetSalesOfferStatsResponse,
  SalesOfferStatRow,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_SALES_OFFER_STATS_FAILURE:
      return action.error;

    case FETCH_SALES_OFFER_STATS_REQUEST:
    case FETCH_SALES_OFFER_STATS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_SALES_OFFER_STATS_REQUEST:
      return true;

    case FETCH_SALES_OFFER_STATS_FAILURE:
    case FETCH_SALES_OFFER_STATS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: SalesOfferStatRow[] = [],
  action: any
): SalesOfferStatRow[] => {
  switch (action.type) {
    case FETCH_SALES_OFFER_STATS_SUCCESS: {
      const response = action.response as GetSalesOfferStatsResponse;
      return response.sales_offer_stat_rows;
    }

    default:
      return state;
  }
};

export const salesOfferStats = combineReducers({
  error,
  loading,
  all,
});
