import type { SourceLanguage } from 'shared/models/swagger';

const bookingWidgetUrlLangCodeMap: Record<string, string> = {
  en: 'en-US',
  ja: 'ja-JP',
  ko: 'ko-KR',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

export const convertToBookingWidgetUrlLangCode = (lang: string): string => {
  return bookingWidgetUrlLangCodeMap[lang] || 'ja-JP';
};

const sourceLanguageToBookingWidgetUrlLangCodeMap: Record<
  SourceLanguage,
  string
> = {
  EN_US: 'en-US',
  JA_JP: 'ja-JP',
  KO_KR: 'ko-KR',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
};
export const convertSourceLanguageToBookingWidgetUrlLangCode = (
  lang: SourceLanguage
): string => {
  return sourceLanguageToBookingWidgetUrlLangCodeMap[lang] || 'ja-JP';
};
