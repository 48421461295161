import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ReduxState } from 'client/reducers';
import { fetchGuidanceStampRallies } from 'client/actions/guidanceStampRally';
import { GuidanceStampRally } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { EditGuidanceStampRallyModal } from './EditGuidanceStampRallyModal';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const EditDeleteCell = ({ stampRally }: { stampRally: GuidanceStampRally }) => {
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditGuidanceStampRallyModal
          existingGuidanceStampRally={stampRally}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete stamp rally')}
          content={t('Are you sure you want to delete stamp rally?')}
          onConfirm={async () => {
            console.log('delete');
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<GuidanceStampRally>[] => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(
      activeUser,
      'DIGITAL_GUIDANCE.STAMP_RALLIES'
    )
      ? ([
          {
            Header: '',
            th: true,
            width: 'middle',
            Cell: (cellInfo) => (
              <EditDeleteCell stampRally={cellInfo.original} />
            ),
          },
        ] as ColumnType<GuidanceStampRally>[])
      : []),
    {
      Header: t('Title'),
      accessor: 'title',
    },
    {
      Header: t('Description'),
      accessor: 'description',
    },
    {
      Header: t('Redemptions'),
      accessor: (row) => `${row.redemption_count ?? 0}`,
    },
  ];
};

export const DigitalGuidanceStampRallyList = () => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);

  const dispatch = useDispatch();
  const columns = useColumns();
  const activeUser = useSelector(activeUserSelector);

  const stampRallies = useSelector(
    (state: ReduxState) => state.guidanceStampRallies.all
  );

  React.useEffect(() => {
    dispatch(fetchGuidanceStampRallies());
  }, []);

  return (
    <div>
      {hasCustomUserRoleWritePermissions(
        activeUser,
        'DIGITAL_GUIDANCE.STAMP_RALLIES'
      ) && (
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Stamp Rally')}
        </Button>
      )}
      {showEditModal && (
        <EditGuidanceStampRallyModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {stampRallies.length === 0 ? (
        <div className={baseStyles['base-form-box__err']}>
          {t('No Stamp Rallies Found')}
        </div>
      ) : (
        <CustomTable items={stampRallies} columns={columns} />
      )}
    </div>
  );
};
