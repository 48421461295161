import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import clsx from 'clsx';

import { getValidators } from 'shared/libraries/validate/validator';
import {
  fetchPassthroughCandidateProducts,
  fetchProducts,
} from 'client/actions/products';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { isPartnershipAgent } from 'client/libraries/util/partnership';
import { ProductSummary } from 'shared/models/swagger';

// focusable input for react-final-form focus-on-error
const HiddenInput = ({ name }: { name: string }) => {
  return (
    <div
      style={{
        width: 0,
        overflow: 'hidden',
      }}
    >
      <input name={name} style={{ opacity: 0 }} />
    </div>
  );
};

export const ProductTypeInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const showPassthrough = Boolean(
    activeUserOrganization?.corresponding_organization_id
  );

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const passthroughProductCandidates = useSelector(
    (state: ReduxState) => state.products.passthroughCandidates
  );

  const editingProduct = useContext(EditingProductContext);

  const form = useForm();
  const productTypeField = useField('productType');

  const { atLeastOneProductRequired, required } = getValidators(t);

  useEffect(() => {
    if (productTypeField.input.value === 'PASSTHROUGH') {
      dispatch(
        fetchPassthroughCandidateProducts({
          agent_id: activeUserOrganization?.corresponding_organization_id ?? '',
        })
      );
    } else if (productTypeField.input.value === 'PACKAGE') {
      dispatch(fetchProducts(activeUserOrganization?.id ?? ''));
      if (
        isPartnershipAgent(activeUserOrganization) &&
        activeUserOrganization?.is_partnership_hub_supplier?.value
      ) {
        dispatch(
          fetchPassthroughCandidateProducts({
            agent_id:
              activeUserOrganization?.corresponding_organization_id ?? '',
          })
        );
      }
    }
  }, [productTypeField.input.value]);

  const typeOptions = [
    {
      value: 'NORMAL',
      label: t('Normal'),
    },
    {
      value: 'PACKAGE',
      label: t('Package'),
    },
  ];

  if (showPassthrough) {
    typeOptions.push({
      value: 'PASSTHROUGH',
      label: t('Passthrough'),
    });
  }

  const packageProductOptions = [
    ...allProducts.map((product) => ({
      value: product.id,
      text: getVerboseDisplayProductName(product),
    })),
    ...(isPartnershipAgent(activeUserOrganization)
      ? passthroughProductCandidates
      : ([] as ProductSummary[])
    ).map((product) => ({
      value: product.id,
      text: `[${product.supplier_name}]${getVerboseDisplayProductName(
        product
      )}`,
    })),
  ];

  // Assert as any because passthroughProductCandidates is actually of type never
  const passthroughCandidateOptions = (passthroughProductCandidates as any).map(
    (product: { id: any; product_name: any }) => ({
      value: product.id ?? '',
      text: product.product_name ?? '',
    })
  );

  return (
    <div>
      <EnumRadioButtonGroup
        name="productType"
        options={typeOptions}
        onChange={() => {
          form.change('packageProductIds', ['']);
          form.change('passthroughProductId', '');
        }}
        disabled={Boolean(editingProduct?.id)}
      />
      {productTypeField.input.value === 'PACKAGE' && (
        <div className={baseStyles['u-mt-2']} style={{ width: '100%' }}>
          <div>{t('Package Sub-Products')}</div>
          <FieldArray
            name="packageProductIds"
            validate={atLeastOneProductRequired}
          >
            {({ fields, meta: { error, touched } }) => (
              <>
                <div>
                  <div className={styles['p-list__item__body__list']}>
                    {fields.length !== 0 &&
                      fields.map((name, idx) => (
                        <div
                          key={name}
                          className={clsx(styles['p-list__item__body__flex'])}
                        >
                          <Field key={name} name={name}>
                            {({ input }) => (
                              <>
                                <div
                                  className={
                                    styles['p-list__item__body__flex__main']
                                  }
                                >
                                  <SingleDropdown
                                    options={packageProductOptions.filter(
                                      (option) =>
                                        option.value !== editingProduct?.id
                                    )}
                                    searchable={true}
                                    selectedOption={input.value}
                                    onChange={(value) => input.onChange(value)}
                                  />
                                </div>
                                <div
                                  className={
                                    styles['p-list__item__body__flex__actions']
                                  }
                                >
                                  <Button
                                    size="icon"
                                    color="tertiarygray"
                                    onClick={() => fields.remove(idx)}
                                    iconBeforeText={
                                      <i className="c-icon-outline-general-trash-03"></i>
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </Field>
                        </div>
                      ))}
                  </div>
                  {error && touched && (
                    <p className={baseStyles['u-error-msg']}>{error}</p>
                  )}
                </div>

                <div className={baseStyles['u-mt-2']}>
                  <a
                    className={styles['p-box__table2__actions__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, ['']);
                      } else {
                        (fields as any).insertAt(0, '');
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {t('Add Package SubProduct')}
                  </a>
                </div>
              </>
            )}
          </FieldArray>
          <HiddenInput name="packageProductIds" />
        </div>
      )}
      {productTypeField.input.value === 'PASSTHROUGH' && (
        <div className={baseStyles['u-mt-2']} style={{ width: '100%' }}>
          <div>{t('Passthrough Base Product')}</div>
          <Field name="passthroughProductId" validate={required}>
            {({ input, meta: { error, touched } }) => (
              <SingleDropdown
                options={passthroughCandidateOptions}
                searchable={true}
                selectedOption={input.value}
                onChange={(value) => input.onChange(value)}
                error={touched && error}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};
