import * as React from 'react';

import { EditDeleteButtonItem } from './EditDeleteButtonItem';

type Item<T> = {
  key: T;
  text: string;
};
type Props<T> = {
  items: Item<T>[];
  setItems: (items: Item<T>[]) => void;
  onEdit: (index: number) => void;
  onRemove: (index: number) => void;
};
export const EditDeleteButtonDndList = ({
  items,
  setItems,
  onEdit,
  onRemove,
}: Props<any>) => {
  const moveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = items[dragIndex];
      const newItems = [...items];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);
      setItems(newItems);
    },
    [items, setItems]
  );
  return (
    <>
      {items.map((item, idx) => (
        <EditDeleteButtonItem
          key={item.key}
          text={item.text}
          index={idx}
          id={item}
          moveItem={moveItem}
          onRemove={onRemove}
          onEdit={onEdit}
        />
      ))}
    </>
  );
};
