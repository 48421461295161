import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TranslateFuncType } from 'client/components/Translate';

import styles from './NotificationTypeChip.module.css';

const getTypeText = (type: string, t: TranslateFuncType) => {
  switch (type) {
    case 'NEW_RESERVATION':
      return t('New Reservation');
    case 'RESERVATION_UPDATED':
      return t('Updated');
    case 'RESERVATION_CANCELED':
      return t('Canceled');
    case 'RESERVATION_WITHDRAWN':
      return t('Withdrawn');
    case 'RESERVATION_CHANGED':
      return t('Changed');
  }

  return '';
};

const getTypeClassName = (type: string) => {
  switch (type) {
    case 'NEW_RESERVATION':
      return 'chip-new-reservation';
    case 'RESERVATION_UPDATED':
      return 'chip-updated';
    case 'RESERVATION_CANCELED':
      return 'chip-canceled';
    case 'RESERVATION_WITHDRAWN':
      return 'chip-withdrawn';
    case 'RESERVATION_CHANGED':
      return 'chip-changed';
  }

  return '';
};

interface Props {
  type: string;
}

export const NotificationTypeChip = ({ type }: Props) => {
  const { t } = useTranslation();
  const text = getTypeText(type, t);
  const className = getTypeClassName(type);

  if (!text || !className) {
    return null;
  }

  return <div className={clsx(styles['chip'], styles[className])}>{text}</div>;
};
