import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { getReviewAttributionText } from 'client/libraries/util/getReviewAttributionText';
import { Message } from 'client/components/Message/Message';
import { summariesSelector } from 'client/reducers/products';
import {
  convertReviewRatingToNumber,
  getReviewStatusColor,
  getReviewStatusText,
} from 'client/libraries/util/review';
import { Stars } from 'client/components/Stars/Stars';
import type { Review } from 'shared/models/swagger';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import reviewUrlCopyIcon from 'client/images/ic_copy.svg';
import baseStyles from 'client/base.module.css';

import { ImageSlider } from './ImageSlider';
import { ProductReviewBody } from './ProductReviewBody';
import { ProductReviewSupplierComments } from './ProductReviewSupplierComments';
import { ReviewEditModal } from './ReviewEditModal';
import styles from './ProductReviewsPane.module.css';

interface Props {
  review: Review;
  onReviewUpdate?: () => void;
  showProductName?: boolean;
  showStatus?: boolean;
  showEditButton?: boolean;
  showReservationDetailsLink?: boolean;
}
export const ReviewCard = ({
  review,
  onReviewUpdate,
  showProductName,
  showStatus,
  showEditButton,
  showReservationDetailsLink,
}: Props) => {
  const { t } = useTranslation();
  const productSummaries = useSelector(summariesSelector);
  const internalProductName = productSummaries.find(
    (p) => p.id == review.product_id
  )?.internal_product_name;
  const ratingAsNumber = convertReviewRatingToNumber(review.rating);
  const copyReviewUrl =
    config.nutmegExternalApiBaseUrl + '/reviews/' + review.id;
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);
  React.useEffect(() => {
    const copySuccessFlagTimer = setTimeout(() => {
      setCopySuccess(false);
    }, 10 * 1000);
    return () => {
      clearTimeout(copySuccessFlagTimer);
    };
  }, [copySuccess]);
  return (
    <li className={styles['c-review__list__comment__item']}>
      {copySuccess && <Message success header={t('Copied')} />}
      {(showProductName ||
        showStatus ||
        showEditButton ||
        showReservationDetailsLink) && (
        <Box display="flex" alignItems="center">
          {showProductName && (
            <Box>{internalProductName ?? review.product_name}</Box>
          )}
          <div
            className={
              styles['c-review__list__comment__item__top__info__status']
            }
          >
            {showStatus && (
              <Box ml={2} mr={2}>
                <p
                  className={
                    styles['c-review__list__comment__item__top__info__type']
                  }
                  style={{
                    color: getReviewStatusColor(review.status),
                    borderColor: getReviewStatusColor(review.status),
                  }}
                >
                  {getReviewStatusText(review.status, t)}
                </p>
              </Box>
            )}
            {(showReservationDetailsLink || showEditButton) && (
              <Box display="flex">
                {showReservationDetailsLink && (
                  <HashLink
                    to={`/reservations/${review.reservation_id}?t=reviews#detail`}
                    className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                    data-text={t('Detail')}
                  >
                    <img src={reservationDetailIcon} />
                  </HashLink>
                )}
                {showEditButton && (
                  <ReviewEditModal
                    review={review}
                    trigger={<EditIcon />}
                    onReviewUpdate={onReviewUpdate}
                  />
                )}
                {review.status == 'PUBLISHED' && (
                  <div
                    className={clsx(
                      styles['c-review__list__comment__item__tooltip__info']
                    )}
                  >
                    <img
                      src={reviewUrlCopyIcon}
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                      style={{
                        borderRadius: '0',
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(copyReviewUrl);
                        setCopySuccess(true);
                      }}
                    />
                    <div
                      className={clsx(
                        styles[
                          'c-review__list__comment__item__tooltip__info__body'
                        ]
                      )}
                    >
                      {t(
                        'Copy link to embed review in Custom pages in Homepage feature'
                      )}
                    </div>
                  </div>
                )}
              </Box>
            )}
          </div>
        </Box>
      )}
      <div className={styles['c-review__list__comment__item__top']}>
        <div className={styles['c-review__list__comment__item__top__review']}>
          <div
            className={
              styles['c-review__list__comment__item__top__review__stars']
            }
          >
            <Stars rating={ratingAsNumber} />
          </div>
          <p
            className={
              styles['c-review__list__comment__item__top__review__num']
            }
          >
            {ratingAsNumber}
          </p>
        </div>
        <div className={styles['c-review__list__comment__item__top__info']}>
          <p
            className={styles['c-review__list__comment__item__top__info__date']}
          >
            {t('Participation Date: {{participationDate}}', {
              participationDate: review.participation_date_time_local.substring(
                0,
                7
              ),
            })}
          </p>
          <p
            className={styles['c-review__list__comment__item__top__info__type']}
          >
            {getReviewAttributionText(review.attribution, t)}
          </p>
        </div>
      </div>
      <p className={styles['c-review__list__comment__item__ttl']}>
        {review.title}
      </p>
      <ProductReviewBody review={review} />
      {review.media_items != null && review.media_items.length > 0 && (
        <ImageSlider
          imageUrls={
            review.media_items?.map((mediaItem) => mediaItem.url) ?? []
          }
        />
      )}
      <div className={styles['c-review__list__comment__item__user']}>
        <p className={styles['c-review__list__comment__item__user__ic']}>
          {review.guest_nickname.substring(0, 1)}
        </p>
        <div className={styles['c-review__list__comment__item__user__info']}>
          <p
            className={
              styles['c-review__list__comment__item__user__info__name']
            }
          >
            {review.guest_nickname}
          </p>
          <p
            className={
              styles['c-review__list__comment__item__user__info__date']
            }
          >
            {t('Posted Date: {{postedDate}}', {
              postedDate: moment(review.written_date_time_utc).format(
                'YYYY/MM/DD'
              ),
            })}
          </p>
        </div>
      </div>
      <ProductReviewSupplierComments review={review} />
    </li>
  );
};
