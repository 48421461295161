// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button, Icon } from 'semantic-ui-react';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import { EditAccountModal } from 'client/pages/Manage/AccountsTab/EditAccountModal';
import { DeleteAccountModal } from 'client/pages/Manage/AccountsTab/DeleteAccountModal';
import { EditMultipleUserAdminModal } from 'client/pages/Manage/AccountsTab/EditMultipleUserAdminModal/EditMultipleUserAdminModal';
import { accountsSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers/index';
import type { Account } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { translatedReactTableProps } from 'client/libraries/util/coreutil';

type Props = {
  t: TranslateFuncType,
  loading: boolean,
  user: Account,
  accounts: Account[],
};

type State = {
  columns: Object[],
};

class component extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.columns(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.t !== this.props.t) {
      this.setState({
        columns: this.columns(),
      });
    }
  }

  columns = () => {
    const { t } = this.props;
    return [
      {
        Header: t('ID'),
        id: 'edit',
        accessor: 'id',
        filterable: false,
        Cell: (cellInfo) => (
          <React.Fragment key={cellInfo.value}>
            {cellInfo.original.role === 'PRODUCT_EDITOR' && (
              <EditMultipleUserAdminModal
                account={cellInfo.original}
                trigger={<Icon link color="orange" name="sitemap" />}
              />
            )}
            <EditAccountModal
              header={t('Edit account information')}
              trigger={(props) => (
                <Icon link color="orange" name="edit" {...props} />
              )}
              account={cellInfo.original}
              onSaveClick={() => {}}
            />
            {(!this.props.user || this.props.user.id !== cellInfo.value) && (
              <DeleteAccountModal
                trigger={(props) => (
                  <Icon link color="red" name="delete" {...props} />
                )}
                account={cellInfo.original}
              />
            )}
            {cellInfo.value}
          </React.Fragment>
        ),
      },
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: t('Email'),
        accessor: 'email',
      },
      {
        Header: t('Role'),
        accessor: 'role',
      },
      {
        Header: t('Organization ID'),
        accessor: 'organization_id',
      },
      {
        Header: t('Organization'),
        accessor: 'organization_name',
      },
      {
        Header: t('Organization type'),
        accessor: 'organization_type',
      },
      {
        Header: t('Status'),
        accessor: 'status',
      },
      {
        Header: t('Created'),
        accessor: 'created_at_date_time_utc',
      },
    ];
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <EditAccountModal
          header={t('Create new account')}
          trigger={(props) => (
            <Button positive style={{ marginBottom: '10px' }} {...props}>
              {t('Create new account')}
            </Button>
          )}
          onSaveClick={() => {}}
        />

        <ReactTable
          filterable
          defaultFilterMethod={(filter, row) =>
            replaceFullWidthCharactersWithHalfWidth(row[filter.id] || '')
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          }
          loading={this.props.loading}
          data={this.props.accounts.filter(
            (account) => account.api_access_type !== 'DIRECT'
          )}
          columns={this.state.columns}
          minRows={10}
          {...translatedReactTableProps(t)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  loading: state.accounts.loading,
  accounts: accountsSelector(state),
});

export const AccountsTab = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, null),
  withTranslation()
)(component);
