import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './SeatEditor.module.css';

interface SelectPopupWindowProps {
  open: boolean;
  onDeleteClick?: (() => void) | undefined;
  onChangeAttributeClick?: (() => void) | undefined;
  onChangePriceClick?: (() => void) | undefined;
  position: { left: number; top: number } | null | undefined;
  title: string;
}

export const SelectPopupWindow = ({
  open,
  onChangeAttributeClick,
  onChangePriceClick,
  onDeleteClick,
  position,
  title,
}: SelectPopupWindowProps) => {
  const { t } = useTranslation();
  const hoverRef = React.useRef<HTMLDivElement>(null);
  const [popupWindowOpen, setPopupWindowOpen] = React.useState<{
    top: string;
    left: string;
  } | null>(null);

  React.useEffect(() => {
    if (hoverRef?.current) {
      setPopupWindowOpen({
        left: `${(position?.left ?? 0) - 64}px`,
        top: `${
          (position?.top ?? 0) - (hoverRef?.current?.clientHeight ?? 150) - 12
        }px`,
      });
    }
  }, [hoverRef?.current, position]);

  React.useEffect(() => {
    if (hoverRef?.current) {
      setPopupWindowOpen({
        left: `${(position?.left ?? 0) - 64}px`,
        top: `${
          (position?.top ?? 0) - (hoverRef?.current?.clientHeight ?? 150) - 12
        }px`,
      });
    }
  }, [hoverRef?.current, position]);

  if (!position) {
    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <div
      className={clsx(styles['popup'], styles['edit'])}
      ref={hoverRef}
      style={{
        top: popupWindowOpen?.top,
        left: popupWindowOpen?.left,
      }}
    >
      <ul className={styles['popup__info']}>
        <li>
          <p>{title}</p>
          <p></p>
        </li>
      </ul>
      <ul className={clsx(styles['popup__list'])}>
        {onDeleteClick && (
          <li
            style={{
              backgroundColor: 'red',
            }}
            onClick={() => {
              onDeleteClick?.();
            }}
          >
            {t('Delete Block')}
          </li>
        )}
        {onChangeAttributeClick && (
          <li
            onClick={() => {
              onChangeAttributeClick?.();
            }}
          >
            {t('Change Attribute')}
          </li>
        )}
        {onChangePriceClick && (
          <li
            onClick={() => {
              onChangePriceClick?.();
            }}
          >
            {t('Change Price')}
          </li>
        )}
      </ul>
    </div>
  );
};
