import { Icon } from 'client/components/Icons/Icon';
import addIcon from 'client/images/ic_add.svg';

type Props = {
  onClick?: () => void;
  className?: string;
};
export const Add = (props: Props) => {
  return <Icon {...props} src={addIcon} />;
};
