import currencies from 'shared/libraries/validate/currencies.json';

type Option = {
  value: string;
  text: string;
};
export const currencyOptions: Option[] = Object.keys(currencies).map(
  (currency) => ({
    value: currency,
    text: currency,
  })
);
