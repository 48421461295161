import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { isTouchBackend } from 'client/libraries/util/isTouchBackend';
import { summariesSelector } from 'client/reducers/products';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  convertReservationReportDataSetsToGraphData,
  convertReservationReportDataSetsToTableData,
  getProductIds,
  getBookingSourceIds,
  targetTypes,
  getTargetTypeText,
  getCompareFieldName,
} from 'client/pages/v3/Reservation/ReservationReport/utils';
import type {
  TargetType,
  AggregationType,
} from 'client/pages/v3/Reservation/ReservationReport/utils';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Radio } from 'client/components/v3/Form/Radio';
import { Loading } from 'client/components/v3/Common/Loading';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationReport/ReservationReport.module.css';
import { BarChartPane } from 'client/pages/v3/Reservation/ReservationReport/BarChartPane';
import { LineChartPane } from 'client/pages/v3/Reservation/ReservationReport/LineChartPane';
import { TablePane } from 'client/pages/v3/Reservation/ReservationReport/TablePane';
import { TotalTablePane } from 'client/pages/v3/Reservation/ReservationReport/TotalTablePane';
import { CompareTablePane } from 'client/pages/v3/Reservation/ReservationReport/CompareTablePane';

export const ReservationReportGraphBlock = () => {
  const { t } = useTranslation();
  const reportData = useSelector(
    (state: ReduxState) => state.reservations.reportData
  );
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const products = useSelector(summariesSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [aggregationType, setAggregationType] =
    React.useState<AggregationType>('TOTAL');
  const [targetType, setTargetType] =
    React.useState<TargetType>('AMOUNT_GROSS');

  const keys = React.useMemo(() => {
    if (reportData.length > 1) {
      return reportData.map((dataSet, idx) => {
        return getCompareFieldName(dataSet, idx);
      });
    } else {
      switch (aggregationType) {
        case 'PRODUCT':
          return getProductIds(reportData);

        case 'BOOKING_SOURCE':
          return getBookingSourceIds(reportData);

        case 'TOTAL':
        default:
          return ['total'];
      }
    }
  }, [reportData, aggregationType]);
  const names = React.useMemo(() => {
    if (reportData.length > 1) {
      return reportData.reduce((acc, dataSet, idx) => {
        const fieldName = getCompareFieldName(dataSet, idx);

        if (fieldName) {
          acc[fieldName] = fieldName;
        }

        return acc;
      }, {});
    } else {
      switch (aggregationType) {
        case 'PRODUCT':
          return products.reduce((acc, product) => {
            (acc as any)[product.id] = getDisplayProductName(product);
            return acc;
          }, {});

        case 'BOOKING_SOURCE':
          return ((reportData[0] || {}).items || []).reduce(
            (acc: any, data: any) => {
              if (data.booking_source?.agent_id) {
                acc[data.booking_source?.agent_id] =
                  data.booking_source?.agent_name || '';
              } else {
                const sourceType =
                  data.booking_source?.source_type || 'NO BOOKING SOURCE';
                acc[sourceType] = t(sourceType);
              }

              return acc;
            },
            {}
          );

        case 'TOTAL':
        default:
          return {
            total: t('total'),
          };
      }
    }
  }, [reportData, aggregationType, products]);
  const graphData = React.useMemo(() => {
    return convertReservationReportDataSetsToGraphData(
      reportData,
      aggregationType,
      targetType
    );
  }, [reportData, aggregationType, targetType]);
  const tableData = React.useMemo(() => {
    return convertReservationReportDataSetsToTableData(
      reportData,
      aggregationType,
      names
    );
  }, [reportData, aggregationType, names]);
  const targetTypeOptions = targetTypes.map((targetType) => {
    return {
      value: targetType,
      text: t(getTargetTypeText(targetType)),
    };
  });
  const graphPaneHeight = isTouchBackend()
    ? 500 + 16 * (Object.keys(names) || []).length
    : 600;
  const currencyCode = activeUserOrganization?.default_currency || null;

  return (
    <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
      <div className={styles['p-reservations']}>
        <div className={styles['p-reservations__header']}>
          <div className={styles['p-reservations__dropdown']}>
            <span className={styles['p-reservations__dropdown-txt']}>
              {t('Show on chart')}
            </span>
            <SingleDropdown
              label={t('Show on chart')}
              onChange={(value) => setTargetType(value as TargetType)}
              options={targetTypeOptions}
              selectedOption={targetType}
              width={170}
            />
          </div>
          {reportData?.length === 1 && (
            <>
              <div className={styles['p-reports__body__item__flex']}>
                <p className={styles['p-reports__body__item__radiotitle']}>
                  {t('Display')}
                </p>
                <Radio
                  label={t('Total')}
                  checked={aggregationType === 'TOTAL'}
                  onChange={() => {
                    setAggregationType('TOTAL');
                  }}
                  size="sm"
                />
                <Radio
                  label={t('By product')}
                  checked={aggregationType === 'PRODUCT'}
                  onChange={() => {
                    setAggregationType('PRODUCT');
                  }}
                  size="sm"
                />
                <Radio
                  label={t('By booking source')}
                  checked={aggregationType === 'BOOKING_SOURCE'}
                  onChange={() => {
                    setAggregationType('BOOKING_SOURCE');
                  }}
                  size="sm"
                />
              </div>
            </>
          )}
        </div>
        {loading ? (
          <Loading size="lg" />
        ) : (
          <>
            <div className={styles['p-reservations__graf']}>
              <div
                className={styles['graph-pane-wrapper']}
                style={{
                  height: graphPaneHeight,
                }}
              >
                {reportData?.length === 1 && (
                  <BarChartPane
                    data={graphData}
                    keys={keys}
                    names={names}
                    currencyCode={currencyCode}
                  />
                )}
                {reportData?.length === 2 && (
                  <LineChartPane
                    data={graphData}
                    keys={keys}
                    names={names}
                    currencyCode={currencyCode}
                  />
                )}
              </div>
            </div>
            <div className={styles['p-reservations__bottom']}>
              {reportData?.length === 2 && (
                <CompareTablePane
                  data={tableData}
                  currencyCode={currencyCode}
                />
              )}

              {reportData?.length === 1 && (
                <div style={{ display: 'block', width: '100%' }}>
                  <div
                    style={{
                      fontSize: 'var(--text-lg-size) !important',
                      fontWeight: 'var(--text-semibold) !important',
                      marginBottom: '10px',
                    }}
                  >
                    {t('Total')}
                  </div>
                  <div>
                    <TotalTablePane
                      data={tableData}
                      aggregationType={aggregationType}
                      currencyCode={currencyCode}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 'var(--text-lg-size) !important',
                      fontWeight: 'var(--text-semibold) !important',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {t('Detail')}
                  </div>
                  <div>
                    <TablePane
                      data={tableData}
                      aggregationType={aggregationType}
                      currencyCode={currencyCode}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};
