import * as Swagger from 'shared/models/swagger';

export type InternalTagFormValues = {
  internalTags: string[];
};
export const getInitialInternalTagFormValues = (
  product: Swagger.Product | null
): InternalTagFormValues => {
  return {
    internalTags:
      product?.internal_product_tags && product.internal_product_tags.length > 0
        ? product.internal_product_tags
        : [],
  };
};
export const convertInternalTagFormValuesToProductPatch = (
  values: InternalTagFormValues
): Swagger.Product$Patch => ({
  internal_product_tags: values.internalTags.filter((tag) => Boolean(tag)),
});
