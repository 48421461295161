import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { ManifestReservationsTable } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestReservationsTable';
import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import { groupReservationsByTransportRouteStartTime } from 'client/libraries/util/groupReservationsByTransportRouteStartTime';
import { groupReservationsByPickupTime } from 'client/libraries/util/groupReservationsByPickupTime';
import type { ManifestReservationsTableColumn } from 'client/components/ManifestReservationsTable/util';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import type { ResourceType } from 'client/libraries/util/resourceType';
import {
  getFormattedTime,
  getFormattedDateTime,
} from 'client/libraries/util/util';
import { getManifestStartTimeMemoKey } from 'client/libraries/util/memo';
import type {
  ProductSummary,
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

type Props = {
  excludedFormFieldKeys: string[];
  manifestDate?: string;
  products: ProductSummary[];
  reservations: ManifestReservationShape[];
  visibleColumns: ManifestReservationsTableColumn[];
  showTimeOnly?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  onEditResourceButtonClick?: (arg0: string) => void;
  onResourceTextClick?: (
    arg0: string,
    arg1: ResourceType,
    arg2: string
  ) => void;
  showDispatchColumn?: boolean;
  capacity?: number;
  resourceKey?: string;
  resourceType?: ResourceType;
  groupBy?: 'START_TIME' | 'TRANSPORT_ROUTE' | 'PICKUP_TIME';
  useCollapsible?: boolean;
  insideGroup?: boolean;
};

const defaultProducts: ProductSummary[] = [];

export const StartTimeManifestReservationsTableList = ({
  manifestDate,
  excludedFormFieldKeys,
  products = defaultProducts,
  reservations,
  visibleColumns,
  showTimeOnly,

  /* TODO(goro) check whether this param must be requried or not */
  customizedColumnNames,
  onEditResourceButtonClick,
  onResourceTextClick,
  showDispatchColumn,
  capacity,
  resourceKey,
  resourceType,
  groupBy = 'START_TIME',
  useCollapsible,
  insideGroup = false,
}: Props) => {
  const loading = useSelector((state: ReduxState) => state.manifests.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const reservationsByStartTime =
    groupBy === 'TRANSPORT_ROUTE'
      ? groupReservationsByTransportRouteStartTime(reservations)
      : groupBy === 'PICKUP_TIME'
      ? groupReservationsByPickupTime(reservations)
      : groupReservationsByStartTime(reservations, locale);
  return (
    <>
      {reservationsByStartTime.map(({ startTime, date, reservations }, idx) => {
        const formattedStartTime = showTimeOnly
          ? getFormattedTime(startTime, locale)
          : manifestDate === date
          ? getFormattedTime(startTime, locale)
          : getFormattedDateTime(startTime, date, locale);
        const totalPax = reservations.reduce((p, c) => {
          return p + (c.guests || []).length;
        }, 0);
        const memoKey = resourceType
          ? getManifestStartTimeMemoKey(
              resourceType,
              resourceKey ?? '',
              date,
              startTime
            )
          : null;
        return (
          <div
            key={idx}
            className={clsx(!insideGroup && styles['p-manifests__section'])}
          >
            <ManifestReservationsTable
              excludedFormFieldKeys={excludedFormFieldKeys}
              loading={loading}
              products={products}
              reservations={reservations}
              visibleColumns={visibleColumns as ReservationColumn[]}
              startTime={formattedStartTime}
              totalPax={totalPax}
              open={idx === 0}
              customizedColumnNames={customizedColumnNames}
              onEditResourceButtonClick={onEditResourceButtonClick}
              onResourceTextClick={onResourceTextClick}
              showDispatchColumn={showDispatchColumn}
              capacity={capacity}
              memoKey={memoKey}
              useCollapsible={useCollapsible}
            />
          </div>
        );
      })}
    </>
  );
};
