import maxBy from 'lodash/maxBy';

import { currency } from 'shared/libraries/currency';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getRelativeDateDescription } from 'client/libraries/util/util';
import type { TranslateFuncType } from 'client/components/Translate';
import type { PromotionSummary } from 'client/libraries/util/productShape';
import * as Swagger from 'shared/models/swagger';

export const getPromotionSummaryTitleText = (
  promotion: PromotionSummary,
  t: TranslateFuncType,
  locale: string
): string => {
  const percentDeltaPrice =
    promotion.prices &&
    promotion.prices.length > 0 &&
    promotion.prices.find((p) => Boolean(p.deltaPercent));
  let percentDeltaDescription = '';
  let fixedDeltaDescription = '';

  if (percentDeltaPrice) {
    percentDeltaDescription = percentDeltaPrice.deltaPercent;
  } else {
    if (!promotion.prices || promotion.prices.length === 0) {
      percentDeltaDescription = '0%';
    } else {
      const maxDeltaPrice = maxBy(
        promotion.prices.filter((price) => Boolean(price.deltaAmount)),
        (price) => currency(price.deltaAmount).value
      );
      fixedDeltaDescription = formattedCurrencyAmount(
        (maxDeltaPrice && maxDeltaPrice.deltaAmount) || ''
      );
    }
  }

  return getPromotionTitleText(
    {
      promotionType: promotion.type,
      percentDeltaDescription,
      fixedDeltaDescription,
      earlyBirdDeadline: promotion.earlyBirdDeadline || null,
      lastMinuteStart: promotion.lastMinuteStart,
      familyDiscount: promotion.familyDiscount,
      groupDiscount: promotion.groupDiscount,
    },
    t,
    locale
  );
};

export const getApiPromotionTitleText = (
  promotion: Swagger.Promotion,
  t: TranslateFuncType,
  locale: string
): string => {
  const percentDeltaPrice = promotion.unit_price_deltas?.find((p) =>
    Boolean(p.delta_percent)
  );
  let percentDeltaDescription = '';
  let fixedDeltaDescription = '';

  if (percentDeltaPrice) {
    percentDeltaDescription = `${percentDeltaPrice.delta_percent}%`;
  } else {
    if (
      !promotion.unit_price_deltas ||
      promotion.unit_price_deltas.length === 0
    ) {
      percentDeltaDescription = '0%';
    } else {
      const maxDeltaPrice = maxBy(
        promotion.unit_price_deltas.filter((price) =>
          Boolean(price.delta_fixed_gross)
        ),
        (price) => currency(price.delta_fixed_gross ?? '').value
      );
      fixedDeltaDescription = formattedCurrencyAmount(
        (maxDeltaPrice && maxDeltaPrice.delta_fixed_gross) || ''
      );
    }
  }

  return getPromotionTitleText(
    {
      promotionType: promotion.type,
      percentDeltaDescription,
      fixedDeltaDescription,
      earlyBirdDeadline: promotion.early_bird_deadline || null,
      lastMinuteStart: promotion.last_minute_start,
      familyDiscount: promotion.family_discount,
      groupDiscount: promotion.group_discount,
    },
    t,
    locale
  );
};

interface PromotionParams {
  promotionType: Swagger.PromotionType;
  percentDeltaDescription: string;
  fixedDeltaDescription: string;
  earlyBirdDeadline?: Swagger.RelativeDateTime | null;
  lastMinuteStart?: Swagger.RelativeDateTime | null;
  groupDiscount?: Swagger.GroupDiscount | null;
  familyDiscount?: Swagger.FamilyDiscount | null;
}
const getPromotionTitleText = (
  {
    promotionType,
    percentDeltaDescription,
    fixedDeltaDescription,
    earlyBirdDeadline,
    lastMinuteStart,
    groupDiscount,
    familyDiscount,
  }: PromotionParams,
  t: TranslateFuncType,
  locale: string
): string => {
  switch (promotionType) {
    case 'SIMPLE_DISCOUNT':
      if (percentDeltaDescription) {
        return t('Discount! {{discountDescription}} OFF!', {
          discountDescription: percentDeltaDescription,
        });
      } else {
        return t('Discount! Up to {{discountDescription}} OFF!', {
          discountDescription: fixedDeltaDescription,
        });
      }

    case 'ADDITIONAL_CHARGE':
      if (percentDeltaDescription) {
        return t(
          'Additional Charge! Premium of +{{chargeDescription}} applies for this period!',
          {
            chargeDescription: percentDeltaDescription,
          }
        );
      } else {
        return t(
          'Additional Charge! Premium of up to +{{chargeDescription}} applies for this period!',
          {
            chargeDescription: fixedDeltaDescription,
          }
        );
      }

    case 'EARLY_BIRD_DISCOUNT':
      if (!earlyBirdDeadline) {
        return '';
      }

      if (percentDeltaDescription) {
        return t(
          'Early Bird Discount! {{discountDescription}} OFF until {{relativeDateDescription}}!',
          {
            discountDescription: percentDeltaDescription,
            relativeDateDescription: getRelativeDateDescription(
              earlyBirdDeadline,
              t,
              locale
            ),
          }
        );
      } else {
        return t(
          'Early Bird Discount! Up to {{discountDescription}} OFF until {{relativeDateDescription}}!',
          {
            discountDescription: fixedDeltaDescription,
            relativeDateDescription: getRelativeDateDescription(
              earlyBirdDeadline,
              t,
              locale
            ),
          }
        );
      }

    case 'LAST_MINUTE_DISCOUNT':
      if (!lastMinuteStart) {
        return '';
      }

      if (percentDeltaDescription) {
        return t(
          'Last Minute Discount! {{discountDescription}} OFF starting {{relativeDateDescription}}!',
          {
            discountDescription: percentDeltaDescription,
            relativeDateDescription: getRelativeDateDescription(
              lastMinuteStart,
              t,
              locale
            ),
          }
        );
      } else {
        return t(
          'Last Minute Discount! Up to {{discountDescription}} OFF starting {{relativeDateDescription}}!',
          {
            discountDescription: fixedDeltaDescription,
            relativeDateDescription: getRelativeDateDescription(
              lastMinuteStart,
              t,
              locale
            ),
          }
        );
      }

    case 'GROUP_DISCOUNT':
      if (!groupDiscount) {
        return '';
      }

      if (percentDeltaDescription) {
        return t(
          'Group Discount! {{discountDescription}} OFF for {{groupSize}} or more guests!',
          {
            discountDescription: percentDeltaDescription,
            groupSize: groupDiscount.min_guest_count,
          }
        );
      } else {
        return t(
          'Group Discount! Up to {{discountDescription}} OFF for {{groupSize}} or more guests!',
          {
            discountDescription: fixedDeltaDescription,
            groupSize: groupDiscount.min_guest_count,
          }
        );
      }

    case 'FAMILY_DISCOUNT': {
      if (!familyDiscount) {
        return '';
      }

      return t(
        'Family Discount! For every {{baseUnitCount}} x {{baseUnit}} booked, receive {{discountDescription}} off 1 x {{discountUnit}}!',
        {
          discountDescription: percentDeltaDescription || fixedDeltaDescription,
          baseUnitCount: familyDiscount.qualifying_base_guest_count,
          baseUnit:
            familyDiscount.base_guest_type_title ||
            familyDiscount.base_guest_type_key,
          discountUnit:
            familyDiscount.discount_guest_type_title ||
            familyDiscount.discount_guest_type_key,
        }
      );
    }

    default:
      return '';
  }
};
