import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Add as AddIcon } from 'client/components/Icons/Add';
import dndIcon from 'client/images/ic_dnd.svg';

import styles from './SeatEditor.module.css';

interface Props {
  items: {
    title: string;
    id: string;
  }[];
  selectedItemId: string;
  onItemChange: (id: string) => void;
  componentPaneTitle?: string;
  componentItems?: {
    title: string;
    id: string;
  }[];
  onComponentItemDragStart?: (id: string) => void;
  children?: React.ReactNode;
  onCreate: () => void;
}

export const SeatEditorPane = ({
  items,
  selectedItemId,
  onItemChange,
  componentPaneTitle,
  componentItems,
  onComponentItemDragStart,
  children,
  onCreate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles['seats'])}>
      <div className={clsx(styles['seats__side'])}>
        <ul className={clsx(styles['seats__side__list'])}>
          {items.map((item) => (
            <li
              key={item.id}
              className={clsx(
                item.id === selectedItemId ? styles['is-active'] : null
              )}
            >
              <a
                onClick={() => {
                  onItemChange(item.id);
                }}
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
        <div className={clsx(styles['seats__side__add'])}>
          <a>
            <AddIcon onClick={() => onCreate()} />
          </a>
        </div>
      </div>
      {children}
      {componentItems && componentItems.length > 0 && (
        <div className={clsx(styles['seats__parts'])}>
          <p className={clsx(styles['seats__parts__ttl'])}>
            {componentPaneTitle ?? ''}
          </p>
          <p className={clsx(styles['seats__parts__note'])}>
            {t('Drag and drop to place items in the preview')}
          </p>
          <ul className={clsx(styles['seats__parts__list'])}>
            {(componentItems || []).map((item) => (
              <li
                key={item.id}
                draggable={true}
                onMouseDown={() => {
                  onComponentItemDragStart?.(item.id);
                }}
              >
                <a>
                  <img src={dndIcon} />
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
