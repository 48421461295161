import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import { buildSearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import {
  createReservationDataCsvExportOrder,
  fetchReservationDataCsvExportOrders,
} from 'client/actions/reservationDataCsvExportOrders';
import { InputActionOwnerNameModal } from 'client/components/v3/InputActionOwnerNameModal/InputActionOwnerNameModal';
import { loggedInAccountSelector } from 'client/reducers/user';
import type { ReservationColumn } from 'shared/models/swagger';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

export const DownloadCsv = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lastDownloadItems = useSelector(
    (state: ReduxState) =>
      (state as any).reservationDataCsvExportOrderTableControls.visibleColumns
  );
  const appliedSearchCondition = useSelector(
    (state: ReduxState) => (state as any).reservationSearch.lastQuery
  );
  const loggedInAccount = useSelector(loggedInAccountSelector);
  const lastCreated = useSelector(
    (state: ReduxState) => state.reservationDataCsvExportOrders.lastCreated
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const downloadable = (): boolean => {
    if ((lastDownloadItems ?? []).length === 0) {
      return false;
    }

    return true;
  };

  const successMessage = (): string => {
    if (lastCreated) {
      if (
        (lastCreated as any)?.data_export_status ===
        'DATA_EXPORT_STATUS_EXPORTED'
      ) {
        return t('CSV file is now available.');
      }
      if (
        (lastCreated as any)?.data_export_status ===
        'DATA_EXPORT_STATUS_PENDING'
      ) {
        return t(
          `CSV file is now being created. Email will be sent to you when it's ready for you to download.`
        );
      }
    }
    return t('Send Successful');
  };

  return (
    <div className={styles['p-download__body__item']}>
      {showSuccessMessage && (
        <Snackbar
          text={successMessage()}
          color="success"
          shouldShow={showSuccessMessage}
        />
      )}
      <div className={styles['p-download__body__item__num']}>
        {downloadable() ? <p className={styles['is-active']}>3</p> : <p>3</p>}
      </div>
      <p className={styles['p-download__body__item__ttl']}>{t('Create CSV')}</p>
      <div className={styles['p-download__body__item__action']}>
        <Button
          color="primary"
          size="sm"
          iconAfterText={
            <>
              <i className="c-icon-outline-general-download-02"></i>
              <p>{t('Create CSV')}</p>
            </>
          }
          disabled={!downloadable()}
          onClick={() => setShowModal(true)}
          loading={loading}
        />
        {showModal && (
          <InputActionOwnerNameModal
            initialName={loggedInAccount?.name || ''}
            open={showModal}
            onClose={() => setShowModal(false)}
            onActionStart={(name) => {
              const fn = async () => {
                const columns = (lastDownloadItems ?? []).map(
                  (col: any) => col.toUpperCase() as any as ReservationColumn
                );
                const request = buildSearchReservationsRequest(
                  appliedSearchCondition
                );

                const { most_recent_email_bounced, ...rest } = request;

                try {
                  //setShowSuccessMessage(false);
                  setLoading(true);
                  await dispatch(
                    createReservationDataCsvExportOrder({
                      ...rest,
                      most_recent_email_bounced: most_recent_email_bounced
                        ? {
                            value: most_recent_email_bounced,
                          }
                        : undefined,
                      columns,
                      name,
                    })
                  );
                  await dispatch(fetchReservationDataCsvExportOrders());
                  setShowSuccessMessage(true);
                } catch (e) {
                  console.error(e);
                } finally {
                  setLoading(false);
                }
              };
              fn();
            }}
            title={t('Download Reservation Data')}
            buttonText={t('Create CSV file')}
          />
        )}
      </div>
    </div>
  );
};
