import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { deleteCustomPage } from 'client/actions/customPage';
import type { CustomPage } from 'shared/models/swagger';

type Props = {
  open: boolean;
  onClose: () => void;
  customPage: CustomPage | null;
};
export const DeleteCustomPageModal = ({ open, onClose, customPage }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal
      title={t('Delete Custom Page')}
      open={open}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      onClose={onClose}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: customPage?.title,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>

        <Button.Delete
          onClick={() => {
            if (customPage) {
              dispatch(deleteCustomPage(customPage.id));
            }

            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  );
};
