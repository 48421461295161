import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';

import {
  countFilteredFields,
  getGuestTypesUsedInProductInstance,
  getDisplayReservationFormFields,
} from 'client/libraries/util/util';
import { activeUserSelector } from 'client/reducers/user';
import { updateReservation } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { FieldsForm } from 'client/components/v3/Form/FieldsForm/FieldsForm';
import { getFieldResponseErrors } from 'client/libraries/util/coreutil';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/v3/ReservationActorInputForm/ReservationActorInputForm';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextArea } from 'client/components/v3/Form/TextArea';
import type { ReduxState } from 'client/reducers';
import type { Guest, Reservation } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

type Props = {
  onClose: () => void;
  reservationID: string;
  openFrom: 'related-info' | 'guest-info';
};

export const ReservationFieldResponseUpdateModal = ({
  onClose,
  reservationID,
  openFrom,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[reservationID]
  );
  const loading = useSelector(
    (state: ReduxState) =>
      state.products.loading || state.productInstances.loading
  );
  const product = useSelector(
    (state: ReduxState) => state.products.byID[reservation?.product_id || '']
  );
  const productInstance = useSelector(
    (state: ReduxState) =>
      state.productInstances.byID[
        reservation && reservation.product_instance_id
      ]
  );
  const activeUser = useSelector((state: ReduxState) =>
    activeUserSelector(state)
  );

  const [newPerBookingResponses, setNewPerBookingResponses] = useState<
    { key?: string; response?: string }[]
  >([]);
  const [newGuests, setNewGuests] = useState<Guest[]>([]);
  const [
    lastSubmissionValidationErrorMap,
    setLastSubmissionValidationErrorMap,
  ] = useState<{ [key: string]: string }>({});
  const [agentNotes, setAgentNotes] = useState<string>(
    reservation.agent_notes || ''
  );
  const [supplierNotes, setSupplierNotes] = useState<string>(
    reservation.supplier_notes || ''
  );

  useEffect(() => {
    if (reservation) {
      setNewPerBookingResponses([...(reservation.field_responses || [])]);
      setNewGuests([...reservation.guests]);
      setSupplierNotes(reservation.supplier_notes || '');
    }
  }, [reservation]);

  const reservationFromState = (): Reservation => {
    return {
      product_id: product.id,
      guests: newGuests,
      field_responses: newPerBookingResponses,
    } as any;
  };

  useEffect(() => {
    const productID = reservation?.product_id;
    const productInstanceID = reservation?.product_instance_id;

    if (productID && (!product || product.id !== productID)) {
      dispatch(fetchProductByID(productID));
    }
    if (
      productInstanceID &&
      (!productInstance || productInstance.id !== productInstanceID)
    ) {
      dispatch(fetchProductInstanceByID(productInstanceID));
    }
  }, []);

  const getFormInputErrors = useCallback(() => {
    if (activeUser?.organization_type === 'SUPPLIER') {
      return {};
    }

    let errorMap = {};

    const perBookingFields = getDisplayReservationFormFields(
      product?.reservation_form_fields ?? [],
      t
    ).filter((f) => f.type === 'PER_BOOKING');
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newPerBookingResponses || [],
        perBookingFields,
        t
      ),
    };

    const perParticipantFields = getDisplayReservationFormFields(
      product?.reservation_form_fields ?? [],
      t
    ).filter((f) => f.type === 'PER_PARTICIPANT');
    (newGuests || []).forEach((g) => {
      errorMap = {
        ...errorMap,
        ...getFieldResponseErrors(
          g.field_responses || [],
          perParticipantFields,
          t
        ),
      };
    });

    return errorMap;
  }, [activeUser, newPerBookingResponses, newGuests, product, t]);

  const getNotesInput = useCallback(() => {
    if (activeUser?.organization_type === 'AGENT') {
      return (
        <TextArea
          label={t('Remarks')}
          value={agentNotes}
          onChange={(e) => {
            setAgentNotes((e as any).target.value);
          }}
          height={120}
        />
      );
    } else if (activeUser?.organization_type === 'SUPPLIER') {
      return (
        <TextArea
          label={t('Replies')}
          value={supplierNotes}
          onChange={(e) => {
            setSupplierNotes((e as any).target.value);
          }}
          height={120}
        />
      );
    }
  }, [activeUser, agentNotes, supplierNotes, t]);

  const currentValidationErrorMap = getFormInputErrors();

  const errors = Object.values(lastSubmissionValidationErrorMap);

  const perParticipantFields = getDisplayReservationFormFields(
    product?.reservation_form_fields ?? [],
    t
  ).filter((f) => f.type === 'PER_PARTICIPANT');

  const perBookingFields = getDisplayReservationFormFields(
    product?.reservation_form_fields ?? [],
    t
  ).filter((f) => f.type === 'PER_BOOKING');

  const allGuestTypes = getGuestTypesUsedInProductInstance(
    productInstance,
    product,
    t
  );

  return (
    <Modal
      title={t('Update Field Response')}
      open={true}
      onClose={onClose}
      style={{ height: '600px' }}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Clear Changes')}
            onClick={() => {
              setNewPerBookingResponses([
                ...(reservation.field_responses || []),
              ]);
              setNewGuests([...reservation.guests]);
            }}
            color="white"
          />
          <Button
            text={t('Save')}
            type="submit"
            onClick={() => {
              if (Object.keys(currentValidationErrorMap).length > 0) {
                setLastSubmissionValidationErrorMap(currentValidationErrorMap);
              } else {
                setNewGuests((prevGuests) =>
                  prevGuests.map((g2) => ({
                    ...g2,
                    field_responses: [
                      ...(g2.field_responses || []).filter(
                        (r) => r.key !== 'full_name'
                      ),
                      {
                        key: 'full_name',
                        response: [
                          g2.field_responses?.find(
                            (f) => f.key === 'given_name'
                          )?.response,
                          g2.field_responses?.find(
                            (f) => f.key === 'family_name'
                          )?.response,
                        ].join(' '),
                      },
                    ],
                  }))
                );

                dispatch(
                  updateReservation(reservationID, {
                    field_responses: [...newPerBookingResponses],
                    guests: [...newGuests],
                    agent_notes: agentNotes,
                    supplier_notes: supplierNotes,
                  })
                );

                onClose();
              }
            }}
          />
        </>
      }
    >
      <div className={styles['p-secondaryContent']}>
        <div className={styles['p-secondaryContent__box']}>
          {loading || !product || !productInstance ? (
            <ModalLoader />
          ) : (
            <>
              {openFrom === 'related-info' &&
                countFilteredFields(
                  reservationFromState(),
                  product,
                  (f) => f.type === 'PER_BOOKING'
                ) > 0 && (
                  <FieldsForm
                    fields={perBookingFields}
                    errorMap={{}}
                    getFieldValue={(key) => {
                      const r = newPerBookingResponses.find(
                        (r) => r.key === key
                      );

                      return (r && r.response) || '';
                    }}
                    onFieldChange={(key, value) =>
                      setNewPerBookingResponses((prevResponses) => {
                        const updatedResponses = [
                          ...prevResponses.filter((r) => r.key !== key),
                          { key, response: value },
                        ];
                        return updatedResponses;
                      })
                    }
                    mode="INPUT"
                    type="EDIT"
                  />
                )}
              {openFrom === 'guest-info' &&
                countFilteredFields(
                  reservationFromState(),
                  product,
                  (f) => f.type === 'PER_PARTICIPANT'
                ) > 0 &&
                newGuests.map((g, idx) => {
                  const guestErrorMap =
                    errors.length > 0
                      ? getFieldResponseErrors(
                          g.field_responses || [],
                          perParticipantFields,
                          t
                        )
                      : {};

                  let guestTypeTitle = g.guest_type_title;
                  if (!guestTypeTitle) {
                    const matchingGuestType = allGuestTypes.find(
                      (guestType) => guestType.key === g.guest_type_key
                    );
                    guestTypeTitle = matchingGuestType
                      ? matchingGuestType.title
                      : g.guest_type_key;
                  }

                  return (
                    <div className={clsx(baseStyles['segment'])} key={idx}>
                      <h3>{guestTypeTitle}</h3>
                      <FieldsForm
                        errorMap={guestErrorMap}
                        fields={perParticipantFields}
                        getFieldValue={(key) => {
                          const r = (g.field_responses || []).find(
                            (r) => r.key === key
                          );
                          if (!r) {
                            return '';
                          }

                          return r.response || '';
                        }}
                        onFieldChange={(key, value) =>
                          setNewGuests((prevGuests) =>
                            prevGuests.map((g2, idx2) =>
                              idx === idx2
                                ? {
                                    ...g2,
                                    field_responses: [
                                      ...(g2.field_responses || []).filter(
                                        (r) => r.key !== key
                                      ),
                                      { key, response: value },
                                    ],
                                  }
                                : g2
                            )
                          )
                        }
                        mode="INPUT"
                      />
                    </div>
                  );
                })}
            </>
          )}
        </div>
        <div className={styles['p-secondaryContent__box']}>
          <div className={styles['p-secondaryContent__box__form']}>
            <div className={styles['p-secondaryContent__box__form__item']}>
              <ReservationActorInputForm />
            </div>
            <div className={styles['p-secondaryContent__box__form__item']}>
              {getNotesInput()}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
