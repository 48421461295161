import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  activeUserIsNutmegAdminSelector,
} from 'client/reducers/user';
import { getLanguageName } from 'client/libraries/i18n';
import { timezoneOptions } from 'client/libraries/util/timezoneOptions';
import { AccountSettings } from 'client/pages/Settings/AccountSettings';
import { ChangePassword } from 'client/pages/Settings/ChangePassword';
import { MultiFactorAuthSettings } from 'client/pages/Settings/MultiFactorAuthSettings';
import { OrganizationEmailSettings } from 'client/pages/Settings/OrganizationEmailSettings';
import { OrganizationNameSettings } from 'client/pages/Settings/OrganizationNameSettings';
import { ReservationSearchSettings } from 'client/pages/Settings/ReservationSearchSettings';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Loading } from 'client/pages/Loading';
import { presetSearchConditionsEnabledForAccount } from 'shared/models/access';
import baseStyles from 'client/base.module.css';

import { GuideSettingsFormTable } from './GuideSettingsTable';
import { OrganizationUpdateNotificationSettings } from './OrganizationUpdateNotificationSettings';

export const Settings = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const timezone =
    timezoneOptions.find((opt) => opt.value === organization?.default_timezone)
      ?.text ?? organization?.default_timezone;
  const language = getLanguageName(organization?.source_language ?? 'JA_JP', t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  if (!isNutmegAdmin && !organization) {
    return <Loading />;
  }

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && <ToggleNewUI origin="SETTINGS" />}
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <FormTableBox header={t('Account')}>
            <table>
              <tbody>
                <tr>
                  <th>{t('Account Info')}</th>
                  <td>
                    <AccountSettings />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>{t('Change Password')}</th>
                  <td>
                    <ChangePassword />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>{t('Two Factor Authentication')}</th>
                  <td>
                    <MultiFactorAuthSettings />
                  </td>
                </tr>
              </tbody>
            </table>
            {presetSearchConditionsEnabledForAccount(activeUser) && (
              <table>
                <tbody>
                  <tr>
                    <th>{t('Reservation List')}</th>
                    <td>
                      <ReservationSearchSettings />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </FormTableBox>

          {organization && (
            <>
              {organization?.type === 'SUPPLIER' &&
              activeUser?.role === 'GUIDE' ? (
                <GuideSettingsFormTable />
              ) : (
                (activeUser?.role === 'ADMIN' ||
                  activeUser?.role === 'PRODUCT_EDITOR') && (
                  <FormTableBox header={t('Organization')}>
                    <table>
                      <tbody>
                        <tr>
                          <th>{t('Organization Name')}</th>
                          <td>
                            <OrganizationNameSettings />
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Language')}</th>
                          <td>{language}</td>
                        </tr>
                        <tr>
                          <th>{t('Timezone')}</th>
                          <td>{timezone}</td>
                        </tr>
                        <tr>
                          <th>{t('Currency')}</th>
                          <td>{organization?.default_currency ?? ''}</td>
                        </tr>
                        <tr>
                          <th>{t('Email Notifications')}</th>
                          <td>
                            <OrganizationEmailSettings />
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Update Notifications')}</th>
                          <td>
                            <OrganizationUpdateNotificationSettings />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormTableBox>
                )
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
