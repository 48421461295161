import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import {
  MarketingAutomationCampaign,
  MarketingAutomationContent,
} from 'shared/models/swagger';
import {
  createMarketingAutomationCampaign,
  deleteMarketingAutomationCampaign,
  fetchMarketingAutomationCampaigns,
} from 'client/actions/marketingAutomationCampaigns';
import { fetchNewsletterSegments } from 'client/actions/newsletterSegment';
import { fetchMarketingAutomationTriggerConditions } from 'client/actions/marketingAutomationTriggerConditions';
import { fetchMarketingAutomationContents } from 'client/actions/marketingAutomationContents';
import { formattedPercent } from 'client/libraries/util/formattedPercent';
import { TranslateFuncType } from 'client/components/Translate';

import { TargetsButton } from './TargetsButton';

const getContentType = (
  contentType: MarketingAutomationContent['type'],
  t: TranslateFuncType
) => {
  switch (contentType) {
    case 'WEBSITE_POPUP':
      return t('Website Popup');
    case 'EMAIL':
      return t('Email');
    case 'SMS':
      return t('SMS');
    case 'PUSH_NOTIFICATION':
      return t('Push Notification');
    default:
      return contentType;
  }
};

const EditCopyDeleteCell = ({
  campaign,
}: {
  campaign: MarketingAutomationCampaign;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.loading
  );
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();

  return (
    <Box display="flex">
      <Link to={`/marketingautomation/campaigns/${campaign.id}/edit`}>
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createMarketingAutomationCampaign({
              title: `${campaign.title} [COPY]`,
              status: 'DRAFT',
              segment_ids: campaign.segment_ids,
              content_id: campaign.content_id,
              trigger_id: campaign.trigger_id,
            })
          );

          const lastCreatedCampaign =
            store.getState().marketingAutomationCampaigns.lastCreatedCampaign;
          if (lastCreatedCampaign) {
            history.push(
              `/marketingautomation/campaigns/${lastCreatedCampaign.id}/edit`
            );
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Campaign')}
            content={t('Are you sure you want to delete campaign?')}
            onConfirm={async () => {
              await dispatch(
                deleteMarketingAutomationCampaign(campaign.id as string)
              );
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<MarketingAutomationCampaign>[] => {
  const { t } = useTranslation();

  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const triggers = useSelector(
    (state: ReduxState) => state.marketingAutomationTriggerConditions.all
  );
  const contents = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.all
  );

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (campaign) => <EditCopyDeleteCell campaign={campaign} />,
      width: 150,
    } as ColumnType<MarketingAutomationCampaign>,

    {
      Header: t('Title'),
      id: 'title',
      accessor: (campaign) => campaign.title,
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (salesOffer) =>
        salesOffer.status === 'ACTIVE' ? t('Active') : t('Draft'),
      width: 80,
    },
    {
      Header: t('Segments'),
      id: 'segments',
      accessor: (campaign) =>
        campaign.segment_ids
          ?.map((segmentId) => segments.find((s) => s.id === segmentId)?.name)
          ?.filter((n) => Boolean(n))
          .join(', '),
    },
    {
      Header: t('Trigger'),
      id: 'trigger',
      accessor: (campaign) =>
        campaign.is_single_send
          ? t('Send Once')
          : triggers.find((t) => t.id === campaign.trigger_id)?.title,
    },
    {
      Header: t('Content'),
      id: 'content',
      accessor: (campaign) => {
        const contentItem = contents.find((c) => c.id === campaign.content_id);

        const contentType = getContentType(contentItem?.type, t);

        return `${contentItem?.title} (${contentType})`;
      },
    },
    {
      Header: t('Targets'),
      id: 'targets',
      accessor: (campaign) =>
        campaign.targeted_count ? <TargetsButton campaign={campaign} /> : '-',
    },
    {
      Header: t('Deliveries'),
      id: 'deliveries',
      accessor: (campaign) =>
        campaign.targeted_count ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{campaign.delivered_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                campaign.delivered_count ?? 0,
                campaign.targeted_count ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Opens'),
      id: 'opens',
      accessor: (campaign) =>
        campaign.targeted_count ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{campaign.opened_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                campaign.opened_count ?? 0,
                campaign.targeted_count ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Clicks'),
      id: 'clicks',
      accessor: (campaign) =>
        campaign.targeted_count ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{campaign.clicked_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                campaign.clicked_count ?? 0,
                campaign.targeted_count ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Bounces'),
      id: 'bounces',
      accessor: (campaign) =>
        campaign.targeted_count ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{campaign.bounced_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                campaign.bounced_count ?? 0,
                campaign.targeted_count ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('Bookings'),
      id: 'bookings',
      accessor: (campaign) =>
        campaign.targeted_count ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <div>{campaign.booked_count ?? 0}</div>
            <div>
              {`(${formattedPercent(
                campaign.booked_count ?? 0,
                campaign.targeted_count ?? 0
              )})`}
            </div>
          </Box>
        ) : (
          '-'
        ),
    },
    {
      Header: t('View'),
      id: 'view',
      accessor: (campaign) => {
        const contentItem = contents.find((c) => c.id === campaign.content_id);

        if (campaign.status !== 'DRAFT') {
          return (
            <div>
              {contentItem?.type === 'EMAIL' ? (
                <Link
                  to={`/marketingautomation/campaigns/${campaign.id}/email`}
                >
                  <a>{t('Email')}</a>
                </Link>
              ) : (
                <Link
                  to={`/marketingautomation/campaigns/${campaign.id}/report`}
                >
                  <a>{t('Report')}</a>
                </Link>
              )}
            </div>
          );
        }

        return '-';
      },
    },
  ];
};

export const MarketingAutomationCampaignList = () => {
  const dispatch = useDispatch();

  const columns = useColumns();

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchNewsletterSegments());
    dispatch(fetchMarketingAutomationTriggerConditions());
    dispatch(fetchMarketingAutomationContents());
    dispatch(fetchMarketingAutomationCampaigns());
  }, []);

  const campaigns = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.all
  );
  const sortedCampaigns = _.orderBy(campaigns, [
    (campaign) => campaign.status,
    (campaign) => campaign.title,
  ]);

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to="/marketingautomation/campaigns/new">
            <Button style="green" size="middle">
              {t('Create New Campaign')}
            </Button>
          </Link>
        </Box>
      </Box>
      <PagedGenericTable allItems={sortedCampaigns} columns={columns} />
    </div>
  );
};
