import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import FileSaver from 'file-saver';

import {
  ISSUE_RESERVATION_RECEIPT_REQUEST,
  ISSUE_RESERVATION_RECEIPT_SUCCESS,
  ISSUE_RESERVATION_RECEIPT_FAILURE,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS,
  DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE,
  SEND_RESERVATION_RECEIPT_EMAIL_FAILURE,
  SEND_RESERVATION_RECEIPT_EMAIL_REQUEST,
  SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS,
  FETCH_RESERVATION_RECEIPT_FAILURE,
  FETCH_RESERVATION_RECEIPT_REQUEST,
  FETCH_RESERVATION_RECEIPT_SUCCESS,
  ISSUE_RESERVATION_RECEIPT_RESET,
} from 'client/constants/ActionTypes';
import {
  ReservationReceiptParams,
  GetReservationReceiptResponse,
} from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const issueReservationReceiptRequest = (
  newReservationReceipt: ReservationReceiptParams
) => ({
  type: ISSUE_RESERVATION_RECEIPT_REQUEST,
  newReservationReceipt,
});

const issueReservationReceiptSuccess = (response: any) => ({
  type: ISSUE_RESERVATION_RECEIPT_SUCCESS,
  response,
});

const issueReservationReceiptFailure = (error: any) => ({
  type: ISSUE_RESERVATION_RECEIPT_FAILURE,
  error,
});

const issueReservationReceiptReset = () => ({
  type: ISSUE_RESERVATION_RECEIPT_RESET,
});

export const issueReservationReceipt =
  (newReservationReceipt: ReservationReceiptParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(issueReservationReceiptRequest(newReservationReceipt));
    return axios
      .post('/api/reservationreceipts', newReservationReceipt, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(issueReservationReceiptSuccess(response.data));
      })
      .catch((err) => {
        dispatch(issueReservationReceiptFailure(err.message));
      });
  };

export const resetIssueReservationReceiptStatus =
  () => (dispatch: Dispatch) => {
    dispatch(issueReservationReceiptReset());
  };

const fetchReservationReceiptRequest = () => ({
  type: FETCH_RESERVATION_RECEIPT_REQUEST,
});

const fetchReservationReceiptSuccess = (
  response: GetReservationReceiptResponse
) => ({
  type: FETCH_RESERVATION_RECEIPT_SUCCESS,
  response,
});

const fetchReservationReceiptFailure = (error: any) => ({
  type: FETCH_RESERVATION_RECEIPT_FAILURE,
  error,
});

export const fetchReservationReceipt =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationReceiptRequest());
    return axios
      .get(`/api/reservationreceipts/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchReservationReceiptSuccess(response.data));
      })
      .catch((err) => {
        if (err.response) {
          // Treat 404 as success as reservation may not have reservation receipt
          if (err.response.status === 404) {
            dispatch(fetchReservationReceiptSuccess({}));
          }
        } else {
          dispatch(fetchReservationReceiptFailure(err.message));
        }
      });
  };

const downloadReservationReceiptPDFRequest = () => ({
  type: DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST,
});

const downloadReservationReceiptPDFSuccess = () => ({
  type: DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS,
});

const downloadReservationReceiptPDFFailure = (error: any) => ({
  type: DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE,
  error,
});

export const downloadReservationReceiptPDF =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadReservationReceiptPDFRequest());
    axios
      .post(`/api/reservationreceipts/${id}/pdf`, undefined, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        const filename =
          response.headers?.['content-disposition']
            .split('filename=')[1]
            .split(';')[0] ?? `${id}_receipt.pdf`;
        dispatch(downloadReservationReceiptPDFSuccess());
        FileSaver.saveAs(response.data, filename);
      })
      .catch((err) => dispatch(downloadReservationReceiptPDFFailure(err)));
  };

const sendReservationReceiptEmailRequest = () => ({
  type: SEND_RESERVATION_RECEIPT_EMAIL_REQUEST,
});

const sendReservationReceiptEmailSuccess = () => ({
  type: SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS,
});

const sendReservationReceiptEmailFailure = (error: any) => ({
  type: SEND_RESERVATION_RECEIPT_EMAIL_FAILURE,
  error,
});

export const sendReservationReceiptEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendReservationReceiptEmailRequest());
    return axios
      .post(
        `/api/reservationreceipts/${reservationId}/email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendReservationReceiptEmailSuccess()))
      .catch((error) => dispatch(sendReservationReceiptEmailFailure(error)));
  };
