import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
} from 'client/constants/ActionTypes';
import type { AgentSystemFeeInvoiceSummary } from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE:
      return action.error;

    case FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST:
      return true;

    case FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE:
    case FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const summaries = (
  state: AgentSystemFeeInvoiceSummary[] = [],
  action: any
): AgentSystemFeeInvoiceSummary[] => {
  switch (action.type) {
    case FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS: {
      return [...action.response];
    }

    default:
      return state;
  }
};

export const agentSystemFeeInvoices = combineReducers({
  error,
  loading,
  summaries,
});
