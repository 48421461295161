import type { ProductSummaryWithBookmark } from 'client/reducers/products';
import { ProductSummary, Organization } from 'shared/models/swagger';

export const sortedByBookmark = (
  summaries: ProductSummary[],
  bookmarks: Set<string>,
  org: Organization | null
): ProductSummaryWithBookmark[] => {
  const language = org?.source_language;
  const displayOrders =
    org?.booking_widget_design_params?.product_display_orders?.find(
      (order) => order.language === language
    )?.product_ids ?? [];
  return summaries
    .map(
      (s: ProductSummary) =>
        ({
          ...s,
          bookmarked: bookmarks.has(s.id),
        } as ProductSummaryWithBookmark)
    )
    .sort((a, b) => {
      if (a.bookmarked && !b.bookmarked) {
        return -1;
      } else if (!a.bookmarked && b.bookmarked) {
        return 1;
      }

      if (displayOrders.indexOf(a.id) === -1) {
        return 1;
      } else if (displayOrders.indexOf(b.id) === -1) {
        return -1;
      }
      if (displayOrders.indexOf(a.id) < displayOrders.indexOf(b.id)) {
        return -1;
      } else if (displayOrders.indexOf(a.id) > displayOrders.indexOf(b.id)) {
        return 1;
      }

      return 0;
    });
};
