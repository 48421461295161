// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { TranslateFuncType } from 'client/components/Translate';

import styles from './ContentItemsEditor.module.css';

const getContentItemName = (
  contentItem: string,
  t: TranslateFuncType
): string => {
  switch (contentItem) {
    case 'LOGO':
      return t('Logo');
    case 'COMPANY_INFO':
      return t('Company Info');
    case 'MESSAGE_TO_GUEST':
      return t('Message to guest (fixed message)');
    case 'WAIVER':
      return t('Waiver');
    case 'E_TICKET':
      return t('E-ticket');
    case 'ANCHOR_LINKS':
      return t('Anchor Links');
    case 'CONFIRMATION_NUMBER_AND_STATUS':
      return t('Confirmation Number, Reservation Status');
    case 'QR_CODE':
      return t('QR code');
    case 'PARTICIPATION_DETAILS':
      return t('Participation Details');
    case 'FROM_ORGANIZER':
      return t('From Organizer');
    case 'CHANGES':
      return t('Changes');
    case 'PICKUP_CHECKIN':
      return t('Pickup / Checkin');
    case 'LOCAL_CONTACT_INFO':
      return t('Local Contact Information');
    case 'TRANSPORTATION':
      return t('Transportation');
    case 'ADD_ONS':
      return t('Add-ons');
    case 'PARTICIPANT_INFO':
      return t('Participant Information');
    case 'FORM_INPUT':
      return t('Form Input');
    case 'SPECIAL_REQUESTS':
      return t('Special Requests');
    case 'BILLING':
      return t('Billing');
    case 'CANCELLATION_POLICY':
      return t('Cancellation Policy');
    case 'INCLUDED':
      return t('Included');
    case 'NOT_INCLUDED':
      return t('Not Included');
    case 'REQUIREMENTS':
      return t('Requirements');
    case 'RESTRICTIONS':
      return t('Restrictions');
    case 'WHAT_TO_BRING':
      return t('What to bring / Attire');
    case 'ADDITIONAL_INFO':
      return t('Additional Info');
    case 'PRODUCT_INFO_FOR_EMAILS':
      return t('Product info for emails');
    case 'COMMON_MESSAGE':
      return t('Common message');
  }

  throw new Error(`unrecognized content item: ${contentItem}`);
};

const headerItemCandidates = [
  'LOGO',
  'COMPANY_INFO',
  'MESSAGE_TO_GUEST',
  'WAIVER',
  'E_TICKET',
  'ANCHOR_LINKS',
];
const overviewItemCandidates = [
  'CONFIRMATION_NUMBER_AND_STATUS',
  'QR_CODE',
  'PARTICIPATION_DETAILS',
  'FROM_ORGANIZER',
  'PICKUP_CHECKIN',
  'LOCAL_CONTACT_INFO',
];
const detailsItemCandidates = [
  'TRANSPORTATION',
  'ADD_ONS',
  'PARTICIPANT_INFO',
  'FORM_INPUT',
  'SPECIAL_REQUESTS',
  'BILLING',
];
const miscInfoItemCandidates = [
  'CANCELLATION_POLICY',
  'INCLUDED',
  'NOT_INCLUDED',
  'REQUIREMENTS',
  'RESTRICTIONS',
  'WHAT_TO_BRING',
  'ADDITIONAL_INFO',
  'PRODUCT_INFO_FOR_EMAILS',
  'COMMON_MESSAGE',
];

export const ContentItemsEditor = () => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <div>
      <div className={styles['header']}>
        {t('Contents of emails to guests (voucher)')}
      </div>
      <div>
        {t('Uncheck to hide items from email, drag-and-drop to reorder')}
      </div>
      <div className={clsx(styles['header'], styles['top-margin-8'])}>
        {t('Header')}
      </div>
      <Field name="headerItems">
        {({ input }) => (
          <ToggleableDndList
            dragItemType="header-item"
            selectedItems={input.value.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            candidateItems={headerItemCandidates.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            setSelectedItems={(items) => {
              input.onChange(items.map((item) => item.key));
            }}
            toggleDisabledKeys={['LOGO', 'COMPANY_INFO', 'MESSAGE_TO_GUEST']}
          />
        )}
      </Field>
      <div className={clsx(styles['header'], styles['top-margin-8'])}>
        {t('Overview')}
      </div>
      <Field name="overviewItems">
        {({ input }) => (
          <ToggleableDndList
            dragItemType="overview-item"
            selectedItems={input.value
              .filter(
                (item) =>
                  item !== 'QR_CODE' ||
                  (hasSubscription(
                    activeUserOrganization,
                    'feature-qr-checkin'
                  ) &&
                    !isSubscriptionCancelled(
                      activeUserOrganization,
                      'feature-qr-checkin'
                    ))
              )
              .map((item) => ({
                key: item,
                text: getContentItemName(item, t),
              }))}
            candidateItems={overviewItemCandidates
              .filter(
                (item) =>
                  item !== 'QR_CODE' ||
                  (hasSubscription(
                    activeUserOrganization,
                    'feature-qr-checkin'
                  ) &&
                    !isSubscriptionCancelled(
                      activeUserOrganization,
                      'feature-qr-checkin'
                    ))
              )
              .map((item) => ({
                key: item,
                text: getContentItemName(item, t),
              }))}
            setSelectedItems={(items) => {
              input.onChange(items.map((item) => item.key));
            }}
            toggleDisabledKeys={['CONFIRMATION_NUMBER_AND_STATUS', 'QR_CODE']}
          />
        )}
      </Field>
      <div className={clsx(styles['header'], styles['top-margin-8'])}>
        {t('Details')}
      </div>
      <Field name="detailsItems">
        {({ input }) => (
          <ToggleableDndList
            dragItemType="details-item"
            selectedItems={input.value.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            candidateItems={detailsItemCandidates.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            setSelectedItems={(items) => {
              input.onChange(items.map((item) => item.key));
            }}
          />
        )}
      </Field>{' '}
      <div className={clsx(styles['header'], styles['top-margin-8'])}>
        {t('Please read through the following')}
      </div>
      <Field name="miscInfoItems">
        {({ input }) => (
          <ToggleableDndList
            dragItemType="misc-info-item"
            selectedItems={input.value.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            candidateItems={miscInfoItemCandidates.map((item) => ({
              key: item,
              text: getContentItemName(item, t),
            }))}
            setSelectedItems={(items) => {
              input.onChange(items.map((item) => item.key));
            }}
          />
        )}
      </Field>
    </div>
  );
};
