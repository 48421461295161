import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FieldWrapper } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { MediaDownloadSalesPage } from 'shared/models/swagger';

import { MediaItemView } from './MediaItemView';
import styles from './PublishedSalesPageForm.module.css';

interface Props {
  salesPage: MediaDownloadSalesPage;
  onUnpublish: () => void;
}
export const PublishedSalesPageForm = ({ salesPage, onUnpublish }: Props) => {
  const [showUnpublishConfirmModal, setShowUnpublishConfirmModal] =
    React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      {showUnpublishConfirmModal && (
        <MessageModal
          open={true}
          onClose={() => setShowUnpublishConfirmModal(false)}
          title={t('Unpublish Sales Page')}
          message={t(
            'Are you sure you wish to unpublish this sales page? Warning: if any customers have already received an email about this sales page, "Unpublish" will prevent these customers from accessing the page and may lead to confusion.'
          )}
          onSubmit={onUnpublish}
        />
      )}
      <div>
        <Box mb={2} display="flex">
          <FieldWrapper label={t('Status')}>{t('Published')}</FieldWrapper>
          <Box>
            <Button.Cancel onClick={() => setShowUnpublishConfirmModal(true)}>
              {t('Unpublish')}
            </Button.Cancel>
          </Box>
        </Box>
        <Box mb={2}>
          <FieldWrapper label={t('Participation Date')}>
            {salesPage.participation_date ?? ''}
          </FieldWrapper>
        </Box>
        <Box mb={2}>
          <FieldWrapper label={t('Products')}>
            {
              <ul>
                {salesPage.product_summaries?.map((productSummary) => (
                  <li key={productSummary.product_id}>
                    {`${productSummary.product_name}${
                      (productSummary.start_times_local ?? []).length > 0
                        ? ` [${productSummary.start_times_local?.join(', ')}]`
                        : ''
                    }`}
                  </li>
                ))}
              </ul>
            }
          </FieldWrapper>
        </Box>
        {(salesPage.media_items ?? []).length > 0 && (
          <Box mb={2}>
            <FieldWrapper label={t('Individual Photos and Videos')}>
              <ul className={styles['picture']}>
                {salesPage.media_items?.map((mediaItem, idx) => (
                  <li key={idx} className={styles['picture__item']}>
                    <MediaItemView
                      mediaItem={mediaItem}
                      shouldShowPrices={true}
                    />
                  </li>
                ))}
              </ul>
            </FieldWrapper>
          </Box>
        )}
        {salesPage.media_sets?.map((mediaSet, idx) => (
          <Box mb={2} key={idx}>
            <FieldWrapper
              label={t('Package #{{index}} - {{priceDescription}}', {
                index: idx + 1,
                priceDescription: `${mediaSet.price?.label} (${mediaSet.price?.price})`,
              })}
            >
              <ul className={styles['picture']}>
                {mediaSet.media_items?.map((mediaItem, idx) => (
                  <li key={idx} className={styles['picture__item']}>
                    <MediaItemView
                      mediaItem={mediaItem}
                      shouldShowPrices={false}
                    />
                  </li>
                ))}
              </ul>
            </FieldWrapper>
          </Box>
        ))}
      </div>
    </>
  );
};
