import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import {
  MarketingAutomationCampaign,
  MarketingAutomationCampaignCustomerRecord,
} from 'shared/models/swagger';
import { fetchMarketingAutomationCampaign } from 'client/actions/marketingAutomationCampaigns';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

interface Props {
  campaign: MarketingAutomationCampaign;
  onClose: () => void;
}

export const CustomersModal = ({ campaign, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.loading
  );

  React.useEffect(() => {
    if (campaign.targeted_count && !campaign.customer_records?.length) {
      dispatch(fetchMarketingAutomationCampaign(campaign.id ?? ''));
    }
  }, [campaign]);

  const columns: ColumnType<MarketingAutomationCampaignCustomerRecord>[] = [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (c) => {
        return c.customer_name;
      },
    },
    {
      Header: t('Email'),
      id: 'email',
      accessor: (c) => {
        return c.customer_email;
      },
    },
  ];

  return (
    <Modal open onClose={onClose} title={t('Contacts')}>
      <Modal.Content>
        {loading ? (
          <Loading size="md" />
        ) : (
          <PagedGenericTable
            allItems={campaign.customer_records ?? []}
            columns={columns}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
