import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import baseStyles from 'client/v3-base.module.css';

import styles from './TextArea.module.css';

type Props = {
  label?: string | React.ReactElement;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  value: string;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  minLength?: number;
  maxLength?: number;
  type?: string;
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  showCharacterCount?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  tooltipText?: string;
  // TODO: think of a better way to do this
  fullContainerWidth?: boolean;
  // Note: error and helperText should behave as follows to separate error state amd the helper text we want to show
  // https://mui.com/material-ui/react-text-field/#validation
  // TODO: make error boolean
  error?: string | boolean;
  helperText?: string;
};

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      error,
      label,
      width,
      height,
      maxWidth,
      maxHeight,
      showCharacterCount,
      disabled,
      onChange,
      value,
      required,
      placeholder,
      tooltipText,
      fullContainerWidth,
      helperText,
      ...inputProps
    }: Props,
    ref
  ) => {
    const { t } = useTranslation();
    const style = {
      width,
      height,
      maxWidth,
      maxHeight,
    };

    return (
      <div
        className={disabled ? styles['is-disabled'] : ''}
        style={{ width: fullContainerWidth ? '100%' : undefined }}
      >
        {label && (
          <div
            className={clsx(
              styles['c-textarea__header']
              // TODO: for now comment out required
              // required ? styles['required'] : ''
            )}
          >
            <p>{label}</p>

            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        )}
        <div>
          <textarea
            ref={ref}
            className={clsx(styles['c-textarea'], error && styles['is-error'])}
            value={value}
            onChange={(e) =>
              onChange(e, {
                value: e.target.value,
              })
            }
            style={style}
            placeholder={placeholder}
            {...inputProps}
          />
          {showCharacterCount && (
            <span className={styles['c-textarea__textCount']}>
              {t('{{count}} characters', {
                count: value?.length || 0,
              })}
            </span>
          )}
          {!error && helperText && (
            <p className={baseStyles['u-info-msg']}>{helperText}</p>
          )}
          {error && (
            <p className={baseStyles['u-error-msg']}>{helperText || error}</p>
          )}
        </div>
      </div>
    );
  }
);
