import { AccordionItem } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/AccordionList/AccordionItem';
import type { AccordionItemContent } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/AccordionList/AccordionItem';

import styles from './AccordionList.module.css';

type Props = {
  items: AccordionItemContent[];
};
export const AccordionList = ({ items }: Props) => {
  return (
    <ul className={styles['c-accordion']}>
      {items.map((item) => (
        <AccordionItem key={item.header} item={item} />
      ))}
    </ul>
  );
};
