import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type {
  GraphDataItem,
  AggregationType,
} from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';
import baseStyles from 'client/base.module.css';

import styles from './ReservationReport.module.css';

type Props = {
  data: GraphDataItem[];
  aggregationType: AggregationType;
  currencyCode: string | null;
};
export const TablePane = ({ data, aggregationType, currencyCode }: Props) => {
  const { t } = useTranslation();

  const getTitle = (aggregationType: AggregationType) => {
    let title = '';

    switch (aggregationType) {
      case 'TOTAL':
        title = t('Date');
        break;

      case 'PRODUCT':
        title = t('Product');
        break;

      case 'BOOKING_SOURCE':
      default:
        title = t('Booking source');
    }

    return title;
  };

  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {getTitle(aggregationType)}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Gross')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Net')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Number of Reservations')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Pax')}
            </th>
          </tr>
          {data.map((dataItem, idx) => {
            return (
              <tr key={idx}>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['center-align']
                  )}
                >
                  {dataItem.name}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {formattedAmount(dataItem.amountGross, currencyCode)}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {formattedAmount(dataItem.amountNet, currencyCode)}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {dataItem.numOfReservations}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {dataItem.pax}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
