import * as React from 'react';

type Props = {
  value: string;
  onChange: (arg0: any) => void;
  name: string;
};
export const DateInput = ({ value, onChange, name }: Props) => {
  const [myValue, setMyValue] = React.useState<string>(value);
  React.useEffect(() => {
    if (value !== myValue) {
      setMyValue(value);
    }
  }, [value]);
  return (
    <input
      type="date"
      name={name}
      value={myValue}
      onBlur={onChange}
      onChange={(e) => setMyValue(e.target.value)}
    />
  );
};
