import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';

import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import type { TranslateFuncType } from 'client/components/Translate';
import { ReduxState } from 'client/reducers';
import 'react-phone-input-2/lib/style.css';
import { FileUploadInput } from 'client/components/v3/FileUploadInput/FileUploadInput';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';

const getFormFieldHelpText = (format: string, t: TranslateFuncType): string => {
  if (format === 'alpha-name') {
    return t('Alphabet characters or spaces');
  } else if (format === 'non-negative-integer') {
    return t('Positive numeric');
  } else if (format === 'float') {
    return t('Numeric');
  } else if (format === 'yyyy-mm-dd') {
    return 'YYYY-MM-DD';
  }

  return '';
};

export const FormField = (props: {
  prompt: string;
  onChange: (arg0: string) => void;
  value: string;
  options?: {
    text: string;
    value: string;
    key: string;
  }[];
  error?: string;
  format?: string;
  required?: boolean;
  disabled?: boolean;
  search?: boolean;
  allowFreeInput?: boolean;
  autoComplete?: 'on' | 'off';
  placeholder?: string;
  onBlur?: () => void;
  onKeyUp?: (e: any) => void;
  textArea?: boolean;
  height?: number;
}) => {
  const {
    prompt,
    onChange,
    options,
    error,
    format,
    required,
    disabled,
    search,
    allowFreeInput,
    placeholder,
    textArea,
    ...rest
  } = props;
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  if (format === 'yyyy-mm-dd') {
    return (
      <DateTimeTZInput
        value={props.value ? moment(props.value) : null}
        onChange={(value) =>
          value ? onChange(value.format('YYYY-MM-DD')) : onChange('')
        }
        locale={locale}
      />
    );
  }

  if (format === 'datetime-local') {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '60%' }}>
          <DateTimeTZInput
            value={props.value ? moment(props.value) : null}
            onChange={(value) =>
              value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
            }
            locale={locale}
          />
        </div>
        <div style={{ width: '40%', marginLeft: '8px' }}>
          <TimePicker
            value={props.value ? moment(props.value) : undefined}
            onChange={(value) =>
              value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
            }
          />
        </div>
      </div>
    );
  }

  if (format === 'friendly_phone') {
    return (
      <PhoneInput
        placeholder={locale === 'ja' ? '81 9012345678' : '1 800 123 4567'}
        masks={{ jp: '...........' }}
        preferredCountries={locale === 'ja' ? ['jp', 'us'] : ['us', 'jp']}
        localization={locale === 'ja' ? jpPhoneLocalization : undefined}
        value={props.value?.replace('+', '')}
        onChange={(value: string, data: { dialCode: string }) => {
          if (data && !value?.startsWith(data.dialCode)) {
            onChange(`+${data.dialCode}${value}`);
          } else if (value && !value?.startsWith('+')) {
            onChange(`+${value}`);
          } else {
            onChange(value);
          }
        }}
        searchPlaceholder={t('Search')}
        searchNotFound={t('No Results')}
        enableSearch
      />
    );
  }

  if (format === 'file-upload') {
    return (
      <FileUploadInput
        value={props.value ? JSON.parse(props.value) : []}
        onChange={(newValue) => props.onChange(JSON.stringify(newValue))}
        error={error}
      />
    );
  }

  const { value }: { value: string } = rest;

  return options && options.length > 0 ? (
    <SingleDropdown
      {...rest}
      error={error}
      onChange={(value) => onChange(value)}
      options={options}
      searchable={search}
      placeholder={placeholder || prompt}
      disabled={!!disabled}
      selectedOption={value}
    />
  ) : textArea ? (
    <TextArea
      {...rest}
      error={error}
      required={required ?? true ? true : required}
      tooltipText={getFormFieldHelpText(format ?? '', t)}
      onChange={(_, { value }) => onChange(value)}
      disabled={!!disabled}
      height={120}
    />
  ) : (
    <TextField
      {...rest}
      error={error}
      name={prompt}
      required={required ?? true ? true : required}
      tooltipText={getFormFieldHelpText(format ?? '', t)}
      onChange={(value) => onChange(value)}
      placeholder={prompt}
      disabled={!!disabled}
    />
  );
};
