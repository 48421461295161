import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getLanguageName } from 'client/libraries/i18n';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { selectedPrivateMarketplaceIndexSelector } from 'client/reducers/privateMarketplace';
import { setSelectedPrivateMarketplaceIndex } from 'client/actions/privateMarketplace';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';

export const PrivateMarketplaceSelect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const selectedPmpIndex = useSelector(selectedPrivateMarketplaceIndexSelector);

  if ((activeOrganization?.private_marketplaces?.length ?? 0) <= 1) {
    return null;
  }

  const options =
    activeOrganization?.private_marketplaces?.map((pmp, idx) => ({
      value: `${idx}`,
      text: `${pmp.domain ?? ''} (${getLanguageName(
        uppercaseIsoToLowercaseIso[pmp.language ?? 'EN_US'],
        t
      )})`,
    })) ?? [];

  return (
    <Box mb={2}>
      <Select
        label={t('Private Marketplace Website')}
        options={options}
        value={`${selectedPmpIndex}`}
        onChange={(e, { value }) => {
          dispatch(setSelectedPrivateMarketplaceIndex(parseInt(value)));
        }}
      />
    </Box>
  );
};
