import * as React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';
import type { ProductSummary, PartnershipProduct } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { Box } from 'client/components/Box/Box';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  fetchContractedOrganizations,
  updateOrganization,
} from 'client/actions/organizations';
import { Loading } from 'client/pages/Loading';
import {
  fetchProducts,
  fetchPartnershipPackageProducts,
} from 'client/actions/products';
import { Radio } from 'client/components/Form';

type PartnershipPackageProduct = {
  package_product_supplier_id: string;
  package_product_id: string;
  package_product_name: string;
  component_products: {
    component_product_id: string;
    component_product_name: string;
  }[];
  status: PartnershipProduct['status'];
};

export const PartnershipPackageProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);

  const contractedLoading = useSelector((state: ReduxState) => {
    return state.organizations.contractedLoading;
  });
  const updateOrganizationLoading = useSelector((state: ReduxState) => {
    return state.organizations.loading;
  });

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const partnershipPackageProducts = useSelector(
    (state: ReduxState) => state.products.partnershipPackageProducts
  );
  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const packageProducts: PartnershipPackageProduct[] = React.useMemo(() => {
    return (
      partnershipPackageProducts?.map((packageProduct: ProductSummary) => {
        return {
          package_product_supplier_id: packageProduct.supplier_id ?? '',
          package_product_id: packageProduct.id,
          package_product_name: packageProduct.product_name ?? '',
          component_products:
            packageProduct.shared_allotment_references?.package_component_product_ids?.map(
              (componentProductId) => {
                const componentProduct = productSummaries?.find(
                  (productSummary: ProductSummary) =>
                    productSummary.id === componentProductId
                );
                return {
                  component_product_id: componentProductId,
                  component_product_name: componentProduct?.product_name ?? '',
                };
              }
            ) || [],
          status: activeUserOrganization?.partnership_settings?.package_products
            ?.flatMap((packageProduct) => packageProduct.products)
            .find((product) => product?.product_id === packageProduct.id)
            ?.status,
        };
      }) || []
    );
  }, [
    partnershipPackageProducts,
    productSummaries,
    activeUserOrganization?.partnership_settings,
  ]);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
    dispatch(fetchProducts());
    dispatch(fetchPartnershipPackageProducts());
  }, []);

  const loading =
    Boolean(contractedLoading) || Boolean(updateOrganizationLoading);

  const onChange = (
    packageProductSupplierId: string,
    packageProductId: string,
    value: 'SALE' | 'DISCONTINUED'
  ) => {
    const newPartnershipSettings = {
      ...activeUserOrganization?.partnership_settings,
    };

    let supplierPartnershipSettings =
      newPartnershipSettings?.package_products?.find(
        (supplierProduct) =>
          supplierProduct.supplier_id === packageProductSupplierId
      );
    if (!supplierPartnershipSettings) {
      supplierPartnershipSettings = {
        supplier_id: packageProductSupplierId,
        products: [],
      };
      newPartnershipSettings.package_products = [
        ...(newPartnershipSettings.package_products || []),
        supplierPartnershipSettings,
      ];
    }

    let product = supplierPartnershipSettings.products?.find(
      (product) => product.product_id === packageProductId
    );
    if (!product) {
      product = {
        product_id: packageProductId,
        status: 'SALE',
      };
      supplierPartnershipSettings.products = [
        ...(supplierPartnershipSettings.products || []),
        product,
      ];
    }

    product.status = value;

    console.log(newPartnershipSettings);

    dispatch(
      updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
        partnership_settings: newPartnershipSettings,
      })
    );
  };

  const getColumns = (
    t: TranslateFuncType
  ): ColumnType<PartnershipPackageProduct>[] => {
    return [
      {
        Header: t('Package Product'),
        id: 'supplier',
        accessor: (item: PartnershipPackageProduct) => {
          return item.package_product_name;
        },
        width: 200,
      },
      {
        Header: t('Component Products'),
        id: 'products',
        accessor: (item: PartnershipPackageProduct) => {
          return (
            <Box display="flex" alignItems="center">
              {item.component_products.map((componentProduct) => {
                return (
                  <div key={componentProduct.component_product_id}>
                    {componentProduct.component_product_name}
                  </div>
                );
              })}
            </Box>
          );
        },
      },
      {
        Header: t('Status'),
        id: 'status',
        accessor: (item: PartnershipPackageProduct) => {
          return (
            <Box display="flex">
              <Radio
                label={t('On Sale')}
                checked={item.status === 'SALE'}
                onChange={() => {
                  onChange(
                    item.package_product_supplier_id,
                    item.package_product_id,
                    'SALE'
                  );
                }}
              />
              <Radio
                label={t('Discontinued')}
                checked={item.status === 'DISCONTINUED'}
                onChange={() => {
                  onChange(
                    item.package_product_supplier_id,
                    item.package_product_id,
                    'DISCONTINUED'
                  );
                }}
              />
            </Box>
          );
        },
      },
    ];
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box__body'])}>
      {loading && <Loading />}
      <GenericTable<PartnershipPackageProduct>
        items={packageProducts}
        totalCount={packageProducts.length}
        columns={getColumns(t)}
        rowCount={rowCount}
        currentPage={currentPage}
        onRowCountChange={(rowCount: number) => {
          setRowCount(rowCount);
        }}
        onCurrentPageChange={(newCurrentPage: number) => {
          setCurrentPage(newCurrentPage);
        }}
        hideScrollButtons={true}
      />
    </div>
  );
};
