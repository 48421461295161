import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_BY_ID_SUCCESS,
  FETCH_CUSTOMER_BY_ID_FAILURE,
  FETCH_CUSTOMER_BY_ID_REQUEST,
  DELETE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_LIST_PAGE_TOKEN,
  RESET_CUSTOMER_LIST_PAGE_TOKEN,
  UPDATE_CUSTOMER_SEARCH_SETTINGS,
  FETCH_CUSTOMER_TAGS_SUCCESS,
  FETCH_CUSTOMER_REPORT_DATA_FAILURE,
  FETCH_CUSTOMER_REPORT_DATA_REQUEST,
  FETCH_CUSTOMER_REPORT_DATA_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  Customer,
  CreateCustomerResponse,
  ListCustomersResponse,
  UpdateCustomerResponse,
  CustomerReportDataSet,
  GetCustomerReportDataResponse,
} from 'shared/models/swagger';

export type CustomerSearchSettings = {
  orderBy: string;
  isAscending: boolean;
  registrationDateFrom: string;
  registrationDateTo: string;
  lastParticipationDateFrom: string;
  lastParticipationDateTo: string;
  reservationCount: number;
  reservationCountOperator: string;
  email: string;
  productIds: string[];
  tags: string[];
  onlyShouldReceiveSpecialEmailOffers: boolean;
  onlyAlertedCustomer: boolean;
  familyName: string;
  givenName: string;
};

export const customersSelector = (state: ReduxState) => state.customers.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_CUSTOMERS_FAILURE:
    case CREATE_CUSTOMER_FAILURE:
    case UPDATE_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_FAILURE:
    case FETCH_CUSTOMER_BY_ID_FAILURE:
    case FETCH_CUSTOMER_REPORT_DATA_FAILURE:
      return action.error;

    case FETCH_CUSTOMERS_SUCCESS:
    case CREATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_SUCCESS:
    case FETCH_CUSTOMER_BY_ID_SUCCESS:
    case FETCH_CUSTOMER_REPORT_DATA_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
    case CREATE_CUSTOMER_REQUEST:
    case UPDATE_CUSTOMER_REQUEST:
    case DELETE_CUSTOMER_REQUEST:
    case FETCH_CUSTOMER_BY_ID_REQUEST:
    case FETCH_CUSTOMER_REPORT_DATA_REQUEST:
      return true;

    case FETCH_CUSTOMERS_FAILURE:
    case CREATE_CUSTOMER_FAILURE:
    case UPDATE_CUSTOMER_FAILURE:
    case DELETE_CUSTOMER_FAILURE:
    case FETCH_CUSTOMER_BY_ID_FAILURE:
    case FETCH_CUSTOMER_REPORT_DATA_FAILURE:
    case FETCH_CUSTOMERS_SUCCESS:
    case CREATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
    case DELETE_CUSTOMER_SUCCESS:
    case FETCH_CUSTOMER_BY_ID_SUCCESS:
    case FETCH_CUSTOMER_REPORT_DATA_SUCCESS:
      return false;

    default:
      return state;
  }
};

export const defaultSearchSettings: CustomerSearchSettings = {
  orderBy: 'REGISTRATION',
  isAscending: false,
  registrationDateFrom: '',
  registrationDateTo: '',
  lastParticipationDateFrom: '',
  lastParticipationDateTo: '',
  reservationCount: 0,
  reservationCountOperator: '',
  email: '',
  productIds: [],
  tags: [],
  onlyShouldReceiveSpecialEmailOffers: false,
  onlyAlertedCustomer: false,
  familyName: '',
  givenName: '',
};

const searchSettings = (
  state: CustomerSearchSettings = defaultSearchSettings,
  action: any
): CustomerSearchSettings => {
  switch (action.type) {
    case UPDATE_CUSTOMER_SEARCH_SETTINGS:
      return action.searchSettings;
    default:
      return state;
  }
};

const all = (state: Customer[] = [], action: any): Customer[] => {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS: {
      const response = action.response as ListCustomersResponse;
      return [...response.customers];
    }

    case CREATE_CUSTOMER_SUCCESS: {
      const response = action.response as CreateCustomerResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_CUSTOMER_SUCCESS: {
      const response = action.response as UpdateCustomerResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_CUSTOMER_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const byID = (state: Record<string, any> = {}, action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_BY_ID_SUCCESS:
    case UPDATE_CUSTOMER_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    default:
      return state;
  }
};

type PageTokens = {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
};

const pageTokens = (
  state: PageTokens = {
    allTokens: {
      '': {
        previous: null,
        next: null,
      },
    },
    currToken: '',
    indexToToken: [''],
  },
  action: any
): {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
} => {
  switch (action.type) {
    case RESET_CUSTOMER_LIST_PAGE_TOKEN:
      return {
        allTokens: {
          '': {
            previous: null,
            next: null,
          },
        },
        currToken: '',
        indexToToken: [''],
      };

    case UPDATE_CUSTOMER_LIST_PAGE_TOKEN:
    case FETCH_CUSTOMERS_SUCCESS:
      if (action.pageTokens) {
        return action.pageTokens;
      } else {
        return {
          allTokens: {
            '': {
              previous: null,
              next: null,
            },
          },
          currToken: '',
          indexToToken: [''],
        };
      }

    default:
      return state;
  }
};

const totalSize = (state = 0, action: any): number => {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS:
      return action.response.total_size ?? 0;
    default:
      return state;
  }
};

const tags = (state: string[] = [], action: any): string[] => {
  switch (action.type) {
    case FETCH_CUSTOMER_TAGS_SUCCESS:
      return action.response.customer_tags;
    default:
      return state;
  }
};

const reportData = (state: CustomerReportDataSet[] = [], action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetCustomerReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

interface State {
  error: ReturnType<typeof error>;
  loading: ReturnType<typeof loading>;
  all: ReturnType<typeof all>;
  byID: ReturnType<typeof byID>;
  pageTokens: ReturnType<typeof pageTokens>;
  searchSettings: ReturnType<typeof searchSettings>;
  totalSize: ReturnType<typeof totalSize>;
  tags: ReturnType<typeof tags>;
  reportData: ReturnType<typeof reportData>;
}

const reducer = combineReducers({
  error,
  loading,
  all,
  byID,
  pageTokens,
  searchSettings,
  totalSize,
  tags,
  reportData,
});

export const customers = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
