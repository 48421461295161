import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useSWR from 'swr';
import { Icon } from 'semantic-ui-react';

import { Box } from 'client/components/Box/Box';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { getIDToken } from 'client/libraries/cognito';
import { getTouchEventBoundaryProps } from 'client/libraries/util/getTouchEventBoundaryProps';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { Account, ReservationStatus } from 'shared/models/swagger';
import { useDebounce } from 'client/hooks/useDebounce';

import styles from './ReservationSuggestForm.module.css';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  text: string,
  impersonatedAccount?: Account
) =>
  axios
    .get(url, {
      params: {
        page_size: 100,
        offset: 0,
        multi_field_query: text,
        is_ascending: false,
        order_by: 'booked_date_time_utc',
      },
      headers: {
        authorization: `Bearer ${token}`,
        'accept-language': lang,
        impersonate: impersonatedAccount
          ? buildImpersonateToken(impersonatedAccount)
          : '',
      },
    })
    .then((result) => result.data);

interface Option {
  value: string;
  text: string;
  reservationId?: string;
  status?: ReservationStatus;
  applicationNumber?: string;
  productName?: string;
  participation?: string;
  guestName?: string;
}

interface Props {
  newUI?: boolean;
}

export const ReservationSuggestForm = ({ newUI }: Props) => {
  const { i18n, t } = useTranslation();
  const [value, onChange] = React.useState<string>('');
  const [query, setQuery] = React.useState<string>('');
  const [showOption, setShowOption] = React.useState<boolean>(false);
  const [onHover, setOnHover] = React.useState<boolean>(false);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const debouncedQuery = useDebounce(value, 500);

  React.useEffect(() => {
    if (debouncedQuery) {
      setQuery(debouncedQuery);
    }
  }, [debouncedQuery]);

  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const impersonatedAccount = useSelector(impersonatedAccountSelector);

  const { data, isValidating } = useSWR(
    query
      ? [
          '/api/reservations/search',
          token,
          i18n.language,
          query,
          impersonatedAccount,
        ]
      : null,
    fetcher
  );

  const options: Option[] = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reservations.map((reservation: any) => {
      const reservationSummaryShape = toReservationSummaryShape(
        reservation,
        i18n.language,
        t
      );

      return {
        value: reservation.id,
        text: reservation.product_name,
        reservationId: reservation.id,
        status: reservationSummaryShape.status,
        applicationNumber: reservationSummaryShape.agent_reference,
        productName:
          reservationSummaryShape?.internal_product_name ??
          reservationSummaryShape.product_name,
        participation: reservationSummaryShape.participates_at
          .locale(locale)
          .format('ll LT'),
        guestName: reservationSummaryShape.guest_display_name,
      };
    });
  }, [data]);

  const areaRef = React.useRef<HTMLFieldSetElement | null>(null);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    if (showOption) {
      window.document.addEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.addEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
      return () => {
        window.document.removeEventListener('mousedown', handleOutsideClick, {
          capture: true,
        });
        window.document.removeEventListener('touchstart', handleOutsideClick, {
          capture: true,
        });
      };
    }
  }, [showOption]);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOption &&
        target instanceof Node &&
        !areaRef?.current?.contains(target)
      ) {
        setShowOption(false);
      }
    };

    if (showOption) {
      window.document.addEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.addEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
      return () => {
        window.document.removeEventListener('mousedown', handleOutsideClick, {
          capture: true,
        });
        window.document.removeEventListener('touchstart', handleOutsideClick, {
          capture: true,
        });
      };
    }
  }, [showOption]);

  return (
    <>
      <div
        className={styles['p-search__body__item']}
        {...getTouchEventBoundaryProps()}
      >
        <fieldset
          className={clsx(
            styles['c-dropdown'],
            showOption
              ? options.length > 0
                ? (styles['is-active'], styles['is-input'])
                : ''
              : '',
            onHover ? styles['is-hover'] : ''
          )}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          ref={areaRef}
        >
          <div className={styles['c-dropdown__body']}>
            <label>
              <div className={styles['c-dropdown__body__selected']}>
                <Box display="flex" alignItems="center" width="100%">
                  {isValidating && <Icon name="circle notched" loading />}
                  <input
                    className={styles['c-dropdown__body__selected__input']}
                    placeholder={t('Search by keyword')}
                    value={value}
                    onClick={() => {
                      setShowOption(true);
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    type="text"
                  />
                </Box>
              </div>
            </label>
          </div>
          {options.length > 0 && (
            <ul
              className={clsx(
                styles['c-dropdown__menu'],
                showOption
                  ? options.length > 0
                    ? styles['is-active']
                    : ''
                  : '',
                newUI ? styles['right-side'] : ''
              )}
            >
              {options.map((option, idx) => {
                return (
                  <li
                    className={styles['c-dropdown__menu__item']}
                    key={idx}
                    data-value={option.value}
                    data-idx={idx}
                  >
                    <a
                      href={`/reservations/${option.reservationId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReservationSuggestOptionCard {...option} />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </fieldset>
      </div>
    </>
  );
};

const getReservationStatusStyle = (status?: ReservationStatus) => {
  switch (status) {
    case 'CONFIRMED':
      return styles['success'];
    case 'REQUESTED':
      return styles['primary'];
    case 'STANDBY':
      return styles['warning'];
    case 'DECLINED_BY_SUPPLIER':
      return styles['error'];
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCELED_BY_GUEST':
    case 'CANCELED_BY_AGENT':
    case 'PENDING':
    case 'CANCELLATION_PENDING':
    case 'AWAITING_UPDATE_CONFIRMATION':
    case 'NO_SHOW':
    case 'CANCEL_REQUESTED_BY_AGENT':
    case 'WITHDRAWN_BY_AGENT':
    case 'PARTICIPATED':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
    case 'UNKNOWN':
      return styles['gray'];
    default:
      return styles['gray'];
  }
};

const ReservationSuggestOptionCard = (option: Option) => {
  const { t } = useTranslation();
  const statusStyle = getReservationStatusStyle(option.status);
  return (
    <>
      <div className={styles['p-reservationsSelectProducts']}>
        <p className={clsx(styles['c-badge'], styles['md'], statusStyle)}>
          {t(option.status as string)}
        </p>
        <div className={styles['suggestReservationInfo']}>
          <div className={styles['suggestReservationInfoLeft']}>
            <div className={styles['infoBlock']}>
              {t('Application Number')} : {option.applicationNumber}{' '}
            </div>
            <div className={styles['infoBlock']}>
              {t('Participation Date')} : {option.participation}{' '}
            </div>
          </div>
          <div className={styles['suggestReservationInfoRight']}>
            <div className={styles['infoBlock']}>
              {t('Product Name')}: {option.productName}{' '}
            </div>
            <div className={styles['infoBlock']}>
              {t('Customer')}: {option.guestName}{' '}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
