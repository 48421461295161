import * as React from 'react';
import { useForm } from 'react-final-form';

import arrowIcon from 'client/images/ic_arrow.svg';

import styles from './MoveSectionDownButton.module.css';

interface Props {
  index: number;
}

export const MoveSectionDownButton = ({ index }: Props) => {
  const form = useForm();

  const onMove = () => {
    const newSections = [...form.getState().values.sections];
    const temp = newSections[index];
    newSections[index] = newSections[index + 1];
    newSections[index + 1] = temp;

    form.change('sections', newSections);
  };

  return (
    <p onClick={onMove} className={styles['arrow-circle']}>
      <img src={arrowIcon} />
    </p>
  );
};
