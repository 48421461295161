import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DropoffCheckoutTab } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/DropoffCheckoutTab/DropoffCheckoutTab';
import { PickupCheckinTab } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/PickupCheckinTab/PickupCheckinTab';
import { ScheduleTab } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/ScheduleTab/ScheduleTab';
import { Tabs } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/Tabs/Tabs';
import type { TabPane } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/Tabs/Tabs';
import type { ProductShape } from 'client/libraries/util/productShape';

type OwnProps = {
  product: ProductShape;
};
type Props = OwnProps;
export const ProductOutline = ({ product }: Props) => {
  const { t } = useTranslation();
  const tabPanes: TabPane[] = [];

  if (product.schedule.length > 0) {
    tabPanes.push({
      header: t('Product Schedule'),
      content: () => <ScheduleTab product={product} />,
    });
  }

  if (
    product.pickupLocations.length > 0 ||
    product.checkinLocations.length > 0
  ) {
    tabPanes.push({
      header: t('Pickup / Checkin'),
      content: () => <PickupCheckinTab product={product} />,
    });
  }

  if (
    product.dropoffLocations.length > 0 ||
    product.checkoutLocations.length > 0
  ) {
    tabPanes.push({
      header: t('Dropoff / Checkout'),
      content: () => <DropoffCheckoutTab product={product} />,
    });
  }

  return <Tabs panes={tabPanes} />;
};
