import * as React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { fetchManifest } from 'client/actions/manifests';
import { getReactTableColumns } from 'client/components/ReservationListModal/util';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type { Column } from 'client/components/ReservationListModal/util';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';

type Props = {
  productIds: string[];
  rootProductInstanceId: string;
  date: string;
  onClose: () => void;
  open: boolean;
  insertRoot?: boolean;
};
export const ReservationListModal = ({
  productIds,
  rootProductInstanceId,
  date,
  onClose,
  open,
  insertRoot,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector((state: ReduxState) =>
    state.manifests.reservations.map((r) =>
      toReservationSummaryShape(r, locale, t)
    )
  );
  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );

  const toCustomTableColumns = (columns: Column[]): CustomTableColumn[] => {
    return columns.map((c) => {
      const newColumn: CustomTableColumn = {
        Header: c.Header,
        accessor: c.accessor,
        Cell: c.Cell,
        th: c.th,
        width: c.width,
      };
      return newColumn;
    });
  };

  const [columns, setColumns] = React.useState<CustomTableColumn[]>(
    toCustomTableColumns(getReactTableColumns(t, locale, true))
  );
  React.useEffect(() => {
    setColumns(toCustomTableColumns(getReactTableColumns(t, locale, true)));
  }, [t, locale]);
  React.useEffect(() => {
    if (date) {
      dispatch(
        fetchManifest({
          date: date,
          product_ids: productIds,
        })
      );
    }
  }, [date, productIds]);
  let tableRows = [];

  if (rootProductInstanceId) {
    // If rootProductInstanceId prop is passed, include:
    // 1) all reservations that have exactly that product instance ID
    // 2) all reservations for any product instance that is a child instance of that product instance ID
    // 3) all reservations for any package that has a component product instance the root product instance ID
    tableRows = reservations.filter((reservation) => {
      return (
        // 1) all reservations that have exactly that product instance ID
        rootProductInstanceId === reservation.product_instance_id || // 2) all reservations for any product instance that is a child instance of that product instance ID
        (productInstancesById &&
          rootProductInstanceId ===
            productInstancesById[reservation.product_instance_id]
              ?.shared_allotment_references?.parent_product_instance_id) || // 3) all reservations for any package that has a component product instance the root product instance ID
        reservation.package_component_reservation_summaries?.some(
          (packageComponent) =>
            rootProductInstanceId === packageComponent.product_instance_id
        )
      );
    });
  } else {
    tableRows = reservations;
  }

  let totalPaxCount = 0;

  for (const reservation of tableRows) {
    totalPaxCount += reservation.guest_count;
  }

  const rootProductInstance = productInstancesById?.[rootProductInstanceId];
  const formattedDateTime = rootProductInstance
    ? moment
        .parseZone(rootProductInstance.start_date_time_local)
        .locale(locale)
        .format('lll')
    : moment(date, 'YYYY-MM-DD').locale(locale).format('ll');
  return (
    <Modal
      title={formattedDateTime}
      open={open}
      onClose={onClose}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      width="wide"
      insertRoot={insertRoot}
    >
      <Modal.Content>
        {totalPaxCount ? (
          <List>
            <List.Item>
              {t('Total Pax: {{paxCount}}', {
                paxCount: totalPaxCount,
              })}
            </List.Item>
          </List>
        ) : null}
        <CustomTable
          items={tableRows}
          columns={columns}
          usePaging={false}
          useScrollButton={true}
        />
      </Modal.Content>
    </Modal>
  );
};
