// @flow

import * as Swagger from 'shared/models/swagger';

export type Translation = {
  source: string,
  target: string,
};

type TranslationsFormValues = {
  translations: Translation[],
};

export type CustomizationFormValues = {
  shouldUseDefaultColors: boolean,
  primaryColor: string,
  secondaryColor: string,
  backgroundColor: string,
  sections: Swagger.EmailContentSection[],
  headerItems: Swagger.EmailContentItem[],
  overviewItems: Swagger.EmailContentItem[],
  detailsItems: Swagger.EmailContentItem[],
  miscInfoItems: Swagger.EmailContentItem[],
  commonMessages: {
    title: string,
    message: string,
  }[],
  perProductMessages: {
    productId: string,
    title: string,
    description: string,
    listItems: string[],
  }[],
};

export type FormValues = CustomizationFormValues & TranslationsFormValues;

export const getInitialValues = (
  organization: Swagger.Organization | null
): CustomizationFormValues => {
  const emailCustomization = organization?.email_customization_settings;
  const contentSections = emailCustomization?.content_sections ?? [];
  return {
    shouldUseDefaultColors: !emailCustomization?.use_custom_colors,
    primaryColor: emailCustomization?.primary_color || '#4682B4',
    secondaryColor: emailCustomization?.secondary_color || '#B0C4DE',
    backgroundColor: emailCustomization?.background_color || '#DCDCDC',
    sections:
      contentSections.length > 0
        ? contentSections
        : [
            'HEADER',
            'OVERVIEW',
            'GUEST_MY_PAGE',
            'CHANGES_SECTION',
            'INQUIRY',
            'DETAILS',
            'MISC_INFO',
          ],
    headerItems: emailCustomization?.header_content_items ?? [],
    overviewItems: emailCustomization?.overview_content_items ?? [],
    detailsItems: emailCustomization?.details_content_items ?? [],
    miscInfoItems: emailCustomization?.misc_info_content_items ?? [],
    commonMessages:
      emailCustomization?.common_custom_messages?.map((msg) => ({
        title: msg.title ?? '',
        message: msg.message ?? '',
      })) ?? [],
    perProductMessages:
      emailCustomization?.per_product_custom_messages?.map((msg) => ({
        productId: msg.product_id ?? '',
        title: msg.title ?? '',
        description: msg.description ?? '',
        listItems: msg.list_items ?? [],
      })) ?? [],
  };
};

export const convertFormValuesToOrganizationPatch = (
  values: FormValues,
  organization: Swagger.Organization | null
): Swagger.Organization$Patch => {
  return {
    email_customization_settings: {
      use_custom_colors: !values.shouldUseDefaultColors,
      primary_color: values.primaryColor,
      secondary_color: values.secondaryColor,
      background_color: values.backgroundColor,
      content_sections: values.sections,
      header_content_items: values.headerItems,
      overview_content_items: values.overviewItems,
      details_content_items: values.detailsItems,
      misc_info_content_items: values.miscInfoItems,
      per_email_custom_messages:
        organization?.email_customization_settings?.per_email_custom_messages ??
        [],
      custom_email_subjects:
        organization?.email_customization_settings?.custom_email_subjects ?? [],
      common_custom_messages: values.commonMessages.map((msg) => ({
        title: msg.title,
        message: msg.message,
      })),
      per_product_custom_messages: values.perProductMessages.map((msg) => ({
        product_id: msg.productId,
        title: msg.title,
        description: msg.description,
        list_items: msg.listItems,
      })),
    },
  };
};
