import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { savePaymentMethod } from 'client/actions/paymentMethods';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';

type Props = ReactStripeElements.InjectedStripeProps & {
  onSuccess?: () => void;
  onOpen?: () => void;
};
export const PaymentMethodEditorModal = injectStripe(
  ({ stripe, onOpen, onSuccess }: Props) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [cardErrorMessage, setCardErrorMessage] = React.useState<
      string | null
    >(null);
    const [updateErrorMessage, setUpdateErrorMessage] = React.useState<
      string | null
    >(null);
    const [cardIsReadyToSubmit, setCardIsReadyToSubmit] =
      React.useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const store = useStore();
    const activeUserOrganization = useSelector(activeUserOrganizationSelector);
    const handleCardChange = React.useCallback(({ error, complete }) => {
      setCardErrorMessage(error ? error.message : null);
      setCardIsReadyToSubmit(complete);
    }, []);
    const handlePaymentMethodSave = React.useCallback(async () => {
      setLoading(true);
      const response = await stripe?.createPaymentMethod('card', {
        billing_details: {},
      });

      if (response?.error) {
        setUpdateErrorMessage(
          t(
            'Update failed. Please check your credit card number, expiry month/year, and CVC, or use different credit card.'
          )
        );
      }

      if (response?.paymentMethod) {
        const paymentMethodId = response.paymentMethod.id;
        await dispatch(savePaymentMethod(paymentMethodId));

        if (store.getState().paymentMethods.error) {
          setUpdateErrorMessage(
            t(
              'Update failed. Please check your credit card number, expiry month/year, and CVC, or use different credit card.'
            )
          );
        } else {
          setShowModal(false);
          onSuccess?.();
        }
      }

      setLoading(false);
    }, []);
    return (
      <Modal
        open={showModal}
        onOpen={() => {
          setShowModal(true);
          setUpdateErrorMessage(null);
          onOpen?.();
        }}
        onClose={() => {
          setShowModal(false);
        }}
        title={t('Payment Method')}
        trigger={
          <Button
            disabled={!activeUserOrganization}
            style="green"
            size="middle"
          >
            {activeUserOrganization?.payment_profile_card_info
              ? t('Change Payment Method')
              : t('Add Payment Method')}
          </Button>
        }
      >
        <Modal.Content>
          <Box mb={2}>
            <FieldWrapper label={t('Payment Currency')}>
              {activeUserOrganization?.default_subscriptions_payment_currency}
            </FieldWrapper>
          </Box>
          <FieldWrapper
            label={t('Credit or Debit Card Information')}
            error={cardErrorMessage ?? undefined}
          />
          <Box mt={2}>
            <CardElement
              onChange={handleCardChange}
              style={{
                base: {
                  fontFamily:
                    'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
                  fontSmoothing: 'antialiased',
                  fontSize: '14px',
                  '::placeholder': {
                    color: '#C7C7C7',
                  },
                },
                invalid: {
                  color: '#9f3a38',
                  iconColor: '#9f3a38',
                },
              }}
              hidePostalCode={true}
            />
          </Box>
          {updateErrorMessage && (
            <Message
              error
              header={t('Update failed')}
              content={updateErrorMessage}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={!cardIsReadyToSubmit}
            style="green"
            size="middle"
            onClick={handlePaymentMethodSave}
            loading={loading}
          >
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);
