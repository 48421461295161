import type { Product } from 'shared/models/swagger';

export const getProductGuestTypeKeys = (product: Product): string[] => {
  const guestTypeKeys = new Set<string>();
  (product.pricing || []).forEach((pricing) => {
    pricing.units
      .filter((unitPricing) => unitPricing.method === 'PER_PARTICIPANT')
      .forEach((unitPricing) => {
        if (unitPricing.guest_type) {
          guestTypeKeys.add(unitPricing.guest_type.key);
        }
      });
  });
  return [...guestTypeKeys];
};
export const getProductAddOnKeys = (product: Product): string[] => {
  const addOnKeys = new Set<string>();
  (product.add_ons || []).forEach((add_on) => {
    if (add_on.key) {
      addOnKeys.add(add_on.key);
    }
  });
  return [...addOnKeys];
};
export const getProductTransportationKeys = (product: Product): string[] => {
  const transportationKeys = new Set<string>();
  (product.transportations || []).forEach((transportation) => {
    if (transportation.key) {
      transportationKeys.add(transportation.key);
    }
  });
  return [...transportationKeys];
};
