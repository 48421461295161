import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import { Button } from 'client/components/v3/Common/Button';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { updateGuideSettings } from 'client/actions/guideSettings';
import type {
  Account,
  GuideSettings,
  ReservationColumn,
} from 'shared/models/swagger';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};
export const EditGuideReservationInfoModal = ({
  activeUser,
  guideSettings,
  setShowModal,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<ReservationColumn[]>(
    guideSettings?.columns || []
  );
  const allManifestColumns = useAllManifestColumns();
  useEffect(() => {
    setColumns(guideSettings?.columns || []);
  }, [guideSettings]);

  return (
    <Modal
      title={t('Edit reservation info')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Button
            type="submit"
            text={t('Save')}
            uiType="bg"
            size="md"
            color="primary"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await dispatch(
                updateGuideSettings(activeUser?.id ?? '', {
                  columns,
                })
              );
              setLoading(false);
              setShowModal(false);
            }}
          />
        </>
      }
      style={{ width: '600px', height: '600px' }}
    >
      <ToggleableDragAndDropList
        selectedItems={columns.map((col) => ({
          key: col,
          text: getColumnHeaderText(col, t),
        }))}
        setSelectedItems={(selectedItems) =>
          setColumns(selectedItems.map((i) => i.key))
        }
        candidateItems={allManifestColumns.map((col) => ({
          key: col,
          text: getColumnHeaderText(col, t),
        }))}
        doNotUseOuterBox={true}
      />
    </Modal>
  );
};
