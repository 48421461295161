import * as React from 'react';

import { ProductCardFields, ProductList, groupedProducts } from '../util';

import { BaseTemplate } from './BaseTemplate';
import { KeyVisualSection } from './section/KeyVisualSection';
import { CustomerGreetingsSection } from './section/CustomerGreetingsSection';
import { OtherRecommendedProductsSection } from './section/OtherRecommendedProductsSection';
import { MainSection } from './section/MainSection';

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  highlightedPlanSummarySection: {
    title: string;
    items: string[];
    style: string;
  };
  mainProduct: ProductCardFields;
  productList: ProductList;
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const SoaringPopularity = ({
  editMode,
  language,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  keyVisualUrl,
  headline,
  headlineDescription,
  highlightedPlanSummarySection,
  mainProduct,
  productList,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* Key visual */}
      <KeyVisualSection
        data={{
          editMode: editMode ?? false,
          keyVisualUrl: keyVisualUrl,
        }}
      />
      {/* Customer greetings お客様向けメッセージ */}
      <CustomerGreetingsSection
        data={{
          editMode: editMode ?? false,
          customerName: customerName ?? '',
          customerIntroduction: customerIntroduction,
          introParagraph: introParagraph,
        }}
      />
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* 当店一番人気のプランは残席わずか！ */}
      <MainSection
        data={{
          editMode: editMode ?? false,
          headline: headline,
          headlineDescription: headlineDescription,
          highlightedPlanSummarySection: highlightedPlanSummarySection,
          mainProduct: mainProduct,
          newsletterCampaignId: newsletterCampaignId ?? '',
          language: language,
        }}
      />
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* Other recommended products その他のおすすめプラン */}
      <OtherRecommendedProductsSection
        data={{
          editMode: editMode ?? false,
          productList: productList,
          language: language,
          groupedProducts: groupedProducts(productList.products),
          newsletterCampaignId: newsletterCampaignId ?? '',
        }}
      />
    </BaseTemplate>
  );
};
