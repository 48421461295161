import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';

import { mediaDownloadPricesSelector } from 'client/reducers/mediaDownloadPrices';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';

import { EditMediaPackageModal } from './EditMediaPackageModal';

export const MediaPackagesEditor = () => {
  const { t } = useTranslation();
  const [editingName, setEditingName] = React.useState('');
  const prices = useSelector(mediaDownloadPricesSelector);
  const defaultPriceId =
    prices.find((price) => price.type === 'MEDIA_SET')?.id ?? '';

  return (
    <FieldArray name="mediaSets">
      {({ fields }) => (
        <div>
          {editingName && (
            <EditMediaPackageModal
              open={Boolean(editingName)}
              onClose={() => setEditingName('')}
              name={editingName}
            />
          )}
          <Button
            onClick={() => {
              (fields as any).insertAt(0, {
                priceId: defaultPriceId,
                mediaItems: [],
              });
              setEditingName(`mediaSets.0`);
            }}
            size="middle"
            style="green"
          >
            {t('Add New Package')}
          </Button>
          {fields.map((name, idx) => (
            <Box mt={2} key={name}>
              {t('Package #{{index}}', {
                index: idx + 1,
              })}
              <EditIcon
                onClick={() => {
                  setEditingName(name);
                }}
              />
              <DeleteIcon onClick={() => fields.remove(idx)} />
            </Box>
          ))}
        </div>
      )}
    </FieldArray>
  );
};
