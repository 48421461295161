import type { TranslateFuncType } from 'client/components/Translate';
import type { Product } from 'shared/models/swagger';

export type SalesStatus = 'ON_SALE' | 'LIST_ONLY' | 'NOT_LISTED';

export const getProductSalesStatus = (product: Product | null): SalesStatus => {
  // If null product is passed, retun default status
  if (!product) return 'NOT_LISTED';

  const isVisible = product?.booking_widget_settings?.is_visible;
  const isViewOnly = product?.booking_widget_settings?.is_view_only;

  // On Sale
  if (isVisible && !isViewOnly) {
    return 'ON_SALE';
  }

  // Not On Sale(Listed Not On Sale)
  if (isVisible && isViewOnly) {
    return 'LIST_ONLY';
  }

  // Not Listed(Unlisted)
  return 'NOT_LISTED';
};

export const getSalesStatusText = (
  salesStatus: SalesStatus,
  t: TranslateFuncType
): string => {
  switch (salesStatus) {
    case 'ON_SALE':
      return t('On Sale');
    case 'LIST_ONLY':
      return t('Not On Sale');
    case 'NOT_LISTED':
      return t('Not Listed');
  }
};

export const getSalesStatusStyle = (salesStatus: SalesStatus): string => {
  switch (salesStatus) {
    case 'ON_SALE':
      return 'on-sale';
    case 'LIST_ONLY':
      return 'not-on-sale';
    case 'NOT_LISTED':
      return 'not-listed';
  }
};

export const convertSalesStatusToModelValue = (
  salesStatus: SalesStatus
): {
  is_visible: boolean;
  is_view_only: boolean;
} => {
  switch (salesStatus) {
    case 'ON_SALE':
      return {
        is_visible: true,
        is_view_only: false,
      };
    case 'LIST_ONLY':
      return {
        is_visible: true,
        is_view_only: true,
      };
    case 'NOT_LISTED':
      return {
        is_visible: false,
        is_view_only: false,
      };
  }
};

export const getSalesStatusOptions = (
  t: TranslateFuncType
): {
  key: string;
  value: string;
  text: string;
}[] => {
  return [
    {
      key: 'ON_SALE',
      value: 'ON_SALE',
      text: t('On Sale'),
    },
    {
      key: 'LIST_ONLY',
      value: 'LIST_ONLY',
      text: t('Not On Sale'),
    },
    {
      key: 'NOT_LISTED',
      value: 'NOT_LISTED',
      text: t('Not Listed'),
    },
  ];
};
