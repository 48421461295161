import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Customer, Reservation } from 'shared/models/swagger';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';

import styles from './Dashboard.module.css';
import { GadgetList } from './GadgetList';
import { useReservationData } from './useReservationData';
import { useCustomerData } from './useCustomerData';
import { CustomerReservationData, DateRange } from './type';

export const consolidateCustomerData = (
  customerData: Customer[] | null,
  annualPassesData: Reservation[] | null,
  allOtherReservationsData: Reservation[] | null
): CustomerReservationData[] | null => {
  // Indicate loading with null
  if (!customerData || !annualPassesData || !allOtherReservationsData) {
    return null;
  }

  const allData = [...annualPassesData, ...allOtherReservationsData];

  const data: CustomerReservationData[] = [];
  for (const customer of customerData) {
    const reservations = customer.reservation_ids;
    if (!reservations) {
      // This means the customer has not reserved anything
      continue;
    }

    const customerReservations: Reservation[] = [];
    for (const reservation of reservations) {
      const matchingReservationFound = allData.find(
        (res) => res.id === reservation
      );
      if (!matchingReservationFound) {
        // This should never happen, any reservation under a customer should exist
        // Unless the reservation is fresh and not added to elasticsearch yet
        continue;
      }

      // Reservation is found
      customerReservations.push(matchingReservationFound);
    }

    data.push({
      customer: customer,
      reservations: customerReservations,
    });
  }

  return data;
};

export const AnnualPassDashboard = () => {
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const [dateRange, setDateRange] = useState<DateRange>(
    getDateRangeForPreset('PREV_7_DAYS')
  );
  const [comparisonDateRange, setComparisonDateRange] =
    useState<DateRange | null>(null);

  // Get all reservations for supplier including non-annual pass reservations
  const { data: annualPassesData } = useReservationData(true);
  const { data: allOtherReservationsData } = useReservationData();
  const { data: customerData } = useCustomerData({
    supplierId: activeOrganization?.id ?? '',
  });

  // Create mapping of customers to all of their reservations
  const customerReservationData = useMemo(
    () =>
      consolidateCustomerData(
        customerData,
        annualPassesData,
        allOtherReservationsData
      ),
    [customerData, annualPassesData, allOtherReservationsData]
  );

  return (
    <V3Page>
      <PageHeader title={t('Annual Pass Dashboard')}>
        <div className={styles['p-dashboard__header__dateRange']}>
          <ComparisonDateInput
            baseDateRange={dateRange}
            setBaseDateRange={setDateRange}
            comparisonDateRange={comparisonDateRange}
            setComparisonDateRange={setComparisonDateRange}
            menuStyle={{ right: 0, left: 'auto' }}
          />
        </div>
      </PageHeader>
      <PageContent>
        <div className={styles['p-dashboard__body']}>
          {/* // TODO: for now search panel is out of scope, to be implemented later */}
          {/* <SearchPanel /> */}
          <GadgetList
            data={customerReservationData}
            dateRange={dateRange}
            comparisonDateRange={comparisonDateRange}
          />
        </div>
      </PageContent>
    </V3Page>
  );
};
