import * as React from 'react';

interface AspectRatio {
  width: number;
  height: number;
}

interface LatLng {
  latitude: number;
  longitude: number;
}

interface Props {
  map: google.maps.Map | null;
  url: string;
  aspectRatio: AspectRatio;
  topLeft: LatLng;
  widthInMeters: number;
  zIndex?: number;
  heading?: number;
}

export const IllustrationOverlay = ({
  url,
  map,
  aspectRatio,
  topLeft,
  widthInMeters,
  zIndex,
  heading,
}: Props) => {
  const overlayRef = React.useRef<any | null>(null);

  React.useEffect(() => {
    class CustomOverlay extends google.maps.OverlayView {
      private aspectRatio: AspectRatio;
      private topLeft: LatLng;
      private widthInMeters: number;
      private image: string;
      private heading: number;
      private div?: HTMLElement;

      constructor(
        image: string,
        heading: number,
        aspectRatio: AspectRatio,
        topLeft: LatLng,
        widthInMeters: number
      ) {
        super();

        this.image = image;
        this.heading = heading;
        this.aspectRatio = aspectRatio;
        this.topLeft = topLeft;
        this.widthInMeters = widthInMeters;
      }

      /**
       * onAdd is called when the map's panes are ready and the overlay has been
       * added to the map.
       */
      onAdd() {
        this.div = document.createElement('div');
        this.div.style.borderStyle = 'none';
        this.div.style.borderWidth = '0px';
        this.div.style.position = 'absolute';

        // Create the img element and attach it to the div.
        const img = document.createElement('img');

        img.src = this.image;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        this.div.appendChild(img);

        // Add the element to the "overlayLayer" pane.
        const panes = this.getPanes()!;

        panes.overlayLayer.appendChild(this.div);
      }

      draw() {
        const overlayProjection = this.getProjection();

        if (this.div) {
          const topLeftPixel = overlayProjection.fromLatLngToDivPixel(
            new google.maps.LatLng(
              this.topLeft.latitude,
              this.topLeft.longitude
            )
          );

          if (topLeftPixel) {
            const unrotatedTopRight =
              google.maps.geometry.spherical.computeOffset(
                new google.maps.LatLng(
                  this.topLeft.latitude,
                  this.topLeft.longitude
                ),
                this.widthInMeters,
                90 + (this.heading ?? 0)
              );

            const unrotatedTopRightPixel =
              overlayProjection.fromLatLngToDivPixel(unrotatedTopRight);

            const width = (unrotatedTopRightPixel?.x ?? 0) - topLeftPixel.x;

            const height = this.aspectRatio.width
              ? (this.aspectRatio.height * width) / this.aspectRatio.width
              : 0;

            this.div.style.left = topLeftPixel.x + 'px';
            this.div.style.top = topLeftPixel.y + 'px';
            this.div.style.width = width + 'px';
            this.div.style.height = height + 'px';

            if (zIndex) {
              this.div.style.zIndex = zIndex.toString();
            }
          }
        }
      }

      /**
       * The onRemove() method will be called automatically from the API if
       * we ever set the overlay's map property to 'null'.
       */
      onRemove() {
        if (this.div) {
          (this.div.parentNode as HTMLElement).removeChild(this.div);
          delete this.div;
        }
      }

      /**
       *  Set the visibility to 'hidden' or 'visible'.
       */
      hide() {
        if (this.div) {
          this.div.style.visibility = 'hidden';
        }
      }

      show() {
        if (this.div) {
          this.div.style.visibility = 'visible';
        }
      }

      toggle() {
        if (this.div) {
          if (this.div.style.visibility === 'hidden') {
            this.show();
          } else {
            this.hide();
          }
        }
      }

      toggleDOM(map: google.maps.Map) {
        if (this.getMap()) {
          this.setMap(null);
        } else {
          this.setMap(map);
        }
      }
    }

    overlayRef.current = new CustomOverlay(
      url,
      heading ?? 0,
      aspectRatio,
      topLeft,
      widthInMeters
    );

    overlayRef.current.setMap(map);

    return () => {
      if (overlayRef.current) {
        overlayRef.current.setMap(null);
      }
    };
  }, [url, aspectRatio, topLeft, widthInMeters, map, heading]);

  return null;
};
