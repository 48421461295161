import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { ConditionsTab } from './ConditionsTab/ConditionsTab';
import { DownloadListTab } from './DownloadListTab/DownloadListTab';

type TabType = 'CONDITIONS' | 'DOWNLOADS';

export const ReservationDataDownload = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<TabType>('CONDITIONS');
  const location = useLocation();
  React.useEffect(() => {
    if (location.hash === '#list') {
      setActiveTab('DOWNLOADS');
    }
  }, [location]);

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="RESERVATION_DATA_DOWNLOAD" />
      )}
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'CONDITIONS' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CONDITIONS');
            }}
          >
            <a>{t('Conditions')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'DOWNLOADS' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('DOWNLOADS');
            }}
          >
            <a>{t('Downloads')}</a>
          </li>
        </ul>
        {activeTab === 'CONDITIONS' && <ConditionsTab />}
        {activeTab === 'DOWNLOADS' && <DownloadListTab />}
      </div>
    </>
  );
};
