import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { ToggleButton } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';

type FormValues = {
  isEnable: boolean;
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    isEnable: product?.lottery_mode_settings?.is_enabled || false,
  };
};
const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    lottery_mode_settings: {
      is_enabled: values.isEnable,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const LotteryModeSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Lucky Draw Mode Settings')}
      initialValues={getInitialValues(product)}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="isEnable">
        {({ input }) => {
          return (
            <Modal.Box>
              <ToggleButton
                {...input}
                label={t('Use lucky draw mode for this product')}
              />
            </Modal.Box>
          );
        }}
      </Field>
    </ProductEditorModal>
  );
};
