import * as React from 'react';

import {
  AccordionItem,
  AccordionItemChildRenderProps,
} from 'client/components/Accordion/Accordion';

interface Props {
  openTableName: string;
  closedTableName: string;
  content: (props: AccordionItemChildRenderProps) => React.ReactNode;
  defaultOpen: boolean;
}
export const LineItemDropdown = ({
  defaultOpen,
  openTableName,
  closedTableName,
  content,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpen);

  if (!content) {
    return null;
  }

  return (
    <AccordionItem
      open={isOpen}
      header={isOpen ? openTableName : closedTableName}
      onButtonClick={() => setIsOpen(!isOpen)}
    >
      {content}
    </AccordionItem>
  );
};
