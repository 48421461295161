export const cityCodes = [
  {
    value: 'AAN',
    text: 'アラブ首長国連邦(アル・アイン) - AAN',
  },
  {
    value: 'AUH',
    text: 'アラブ首長国連邦(アブダビ) - AUH',
  },
  {
    value: 'DXB',
    text: 'アラブ首長国連邦(ドバイ) - DXB',
  },
  {
    value: 'SHJ',
    text: 'アラブ首長国連邦(シャルジャ) - SHJ',
  },
  {
    value: 'LAD',
    text: 'アンゴラ(ルアンダ) - LAD',
  },
  {
    value: 'OUA',
    text: 'ブルキナファソ(ワガドゥグ－) - OUA',
  },
  {
    value: 'BAH',
    text: 'バーレーン(バーレーン) - BAH',
  },
  {
    value: 'COO',
    text: 'ベナン(コトヌ) - COO',
  },
  {
    value: 'BBK',
    text: 'ボツワナ(カサネ) - BBK',
  },
  {
    value: 'GBE',
    text: 'ボツワナ(ハボローネ) - GBE',
  },
  {
    value: 'MUB',
    text: 'ボツワナ(マウン) - MUB',
  },
  {
    value: 'ABJ',
    text: 'コートジボワール(アビジャン) - ABJ',
  },
  {
    value: 'DLA',
    text: 'カメルーン(ドゥーアラ) - DLA',
  },
  {
    value: 'YAO',
    text: 'カメルーン(ヤウンデ) - YAO',
  },
  {
    value: 'RAI',
    text: 'カーボベルデ(プライア) - RAI',
  },
  {
    value: 'JIB',
    text: 'ジブチ(ジブチ) - JIB',
  },
  {
    value: 'ABS',
    text: 'エジプト(アブシンベル) - ABS',
  },
  {
    value: 'ALY',
    text: 'エジプト(アレクサンドリア) - ALY',
  },
  {
    value: 'ASW',
    text: 'エジプト(アスワン) - ASW',
  },
  {
    value: 'ATZ',
    text: 'エジプト(アシット) - ATZ',
  },
  {
    value: 'CAI',
    text: 'エジプト(カイロ) - CAI',
  },
  {
    value: 'HRG',
    text: 'エジプト(フルガダ) - HRG',
  },
  {
    value: 'LXR',
    text: 'エジプト(ルクソール) - LXR',
  },
  {
    value: 'MUH',
    text: 'エジプト(メルサマトルーフ) - MUH',
  },
  {
    value: 'SSH',
    text: 'エジプト(シャルムエルシエイク) - SSH',
  },
  {
    value: 'ASM',
    text: 'エリトリア(アスマラ) - ASM',
  },
  {
    value: 'ADD',
    text: 'エチオピア(アシスアベバ) - ADD',
  },
  {
    value: 'LBV',
    text: 'ガボン(リーブルビル) - LBV',
  },
  {
    value: 'ACC',
    text: 'ガーナ(アクラ) - ACC',
  },
  {
    value: 'CKY',
    text: 'ギニア(コナクリー) - CKY',
  },
  {
    value: 'SSG',
    text: '赤道ギニア(マラボ) - SSG',
  },
  {
    value: 'ETH',
    text: 'イスラエル(エラート) - ETH',
  },
  {
    value: 'TLV',
    text: 'イスラエル(テルアビブ) - TLV',
  },
  {
    value: 'BND',
    text: 'イラン(バンダルアバス) - BND',
  },
  {
    value: 'BUZ',
    text: 'イラン(ブシェール) - BUZ',
  },
  {
    value: 'IFN',
    text: 'イラン(イスファハーン) - IFN',
  },
  {
    value: 'KIH',
    text: 'イラン(キッシュアイランド) - KIH',
  },
  {
    value: 'MHD',
    text: 'イラン(マシュハド) - MHD',
  },
  {
    value: 'OMH',
    text: 'イラン(オルーミーイェ) - OMH',
  },
  {
    value: 'SYZ',
    text: 'イラン(シーラーズ) - SYZ',
  },
  {
    value: 'THR',
    text: 'イラン(テヘラン) - THR',
  },
  {
    value: 'AMM',
    text: 'ヨルダン(アンマン) - AMM',
  },
  {
    value: 'MBA',
    text: 'ケニア(モンバサ) - MBA',
  },
  {
    value: 'NBO',
    text: 'ケニア(ナイロビ) - NBO',
  },
  {
    value: 'YVA',
    text: 'コモロ(モロニ) - YVA',
  },
  {
    value: 'KWI',
    text: 'クウェート(クウェート) - KWI',
  },
  {
    value: 'BEY',
    text: 'レバノン(ベイルート) - BEY',
  },
  {
    value: 'MLW',
    text: 'リベリア(モンロビア) - MLW',
  },
  {
    value: 'MSU',
    text: 'レソト(マセル) - MSU',
  },
  {
    value: 'AGA',
    text: 'モロッコ(アガジール) - AGA',
  },
  {
    value: 'CAS',
    text: 'モロッコ(カサブランカ) - CAS',
  },
  {
    value: 'FEZ',
    text: 'モロッコ(フェズ) - FEZ',
  },
  {
    value: 'RAK',
    text: 'モロッコ(マラケシュ) - RAK',
  },
  {
    value: 'RBA',
    text: 'モロッコ(ラバト) - RBA',
  },
  {
    value: 'TNG',
    text: 'モロッコ(タンジール) - TNG',
  },
  {
    value: 'VIL',
    text: 'モロッコ(ダクラ) - VIL',
  },
  {
    value: 'MXM',
    text: 'マダガスカル(モロンベ) - MXM',
  },
  {
    value: 'TNR',
    text: 'マダガスカル(アンタナナリボ) - TNR',
  },
  {
    value: 'MRU',
    text: 'モーリシャス(モーリシャス) - MRU',
  },
  {
    value: 'BLZ',
    text: 'マラウイ(ブランタイル) - BLZ',
  },
  {
    value: 'LLW',
    text: 'マラウイ(リロングウェ) - LLW',
  },
  {
    value: 'APL',
    text: 'モザンビーク(ナンプラ) - APL',
  },
  {
    value: 'BEW',
    text: 'モザンビーク(ベイラ) - BEW',
  },
  {
    value: 'MPM',
    text: 'モザンビーク(マプト) - MPM',
  },
  {
    value: 'POL',
    text: 'モザンビーク(ペンバ) - POL',
  },
  {
    value: 'TET',
    text: 'モザンビーク(テテ) - TET',
  },
  {
    value: 'WDH',
    text: 'ナミビア(ウィンドフォーク) - WDH',
  },
  {
    value: 'WVB',
    text: 'ナミビア(ウォルビスベイ) - WVB',
  },
  {
    value: 'ABV',
    text: 'ナイジェリア(アブジャ) - ABV',
  },
  {
    value: 'KAN',
    text: 'ナイジェリア(カーノ) - KAN',
  },
  {
    value: 'LOS',
    text: 'ナイジェリア(ラゴス) - LOS',
  },
  {
    value: 'PHC',
    text: 'ナイジェリア(ポートハーコート) - PHC',
  },
  {
    value: 'MCT',
    text: 'オマーン(マスカット) - MCT',
  },
  {
    value: 'SLL',
    text: 'オマーン(サララ) - SLL',
  },
  {
    value: 'DOH',
    text: 'カタール(ドーハ) - DOH',
  },
  {
    value: 'RUN',
    text: 'レユニオン(レユニオン島) - RUN',
  },
  {
    value: 'DHA',
    text: 'サウジアラビア(ダーラン) - DHA',
  },
  {
    value: 'DMM',
    text: 'サウジアラビア(ダンマン) - DMM',
  },
  {
    value: 'ELQ',
    text: 'サウジアラビア(ガッシム) - ELQ',
  },
  {
    value: 'JED',
    text: 'サウジアラビア(ジッダ) - JED',
  },
  {
    value: 'MED',
    text: 'サウジアラビア(メディナ) - MED',
  },
  {
    value: 'RUH',
    text: 'サウジアラビア(リヤド) - RUH',
  },
  {
    value: 'PRI',
    text: 'セーシェル(プラリン島) - PRI',
  },
  {
    value: 'SEZ',
    text: 'セーシェル(マヘ) - SEZ',
  },
  {
    value: 'FNA',
    text: 'シエラレオネ(フリータウン) - FNA',
  },
  {
    value: 'DKR',
    text: 'セネガル(ダカール) - DKR',
  },
  {
    value: 'TMS',
    text: 'サントメ・プリンシペ(サントメ) - TMS',
  },
  {
    value: 'MTS',
    text: 'スワジランド(マンジニ) - MTS',
  },
  {
    value: 'SHO',
    text: 'スワジランド(マンジニ) - SHO',
  },
  {
    value: 'LFW',
    text: 'トーゴ(ロメ) - LFW',
  },
  {
    value: 'DJE',
    text: 'チュニジア(ジェルバ) - DJE',
  },
  {
    value: 'TOE',
    text: 'チュニジア(トズール) - TOE',
  },
  {
    value: 'TUN',
    text: 'チュニジア(チュニス) - TUN',
  },
  {
    value: 'ADA',
    text: 'トルコ(アダナ) - ADA',
  },
  {
    value: 'AJI',
    text: 'トルコ(アール) - AJI',
  },
  {
    value: 'ANK',
    text: 'トルコ(アンカラ) - ANK',
  },
  {
    value: 'ASR',
    text: 'トルコ(カイセリ) - ASR',
  },
  {
    value: 'AYT',
    text: 'トルコ(アンタルヤ) - AYT',
  },
  {
    value: 'BJV',
    text: 'トルコ(ボドルム) - BJV',
  },
  {
    value: 'DLM',
    text: 'トルコ(ダラマン) - DLM',
  },
  {
    value: 'DNZ',
    text: 'トルコ(デニズリ) - DNZ',
  },
  {
    value: 'ERZ',
    text: 'トルコ(エルズルン) - ERZ',
  },
  {
    value: 'GZT',
    text: 'トルコ(ガジアンテップ) - GZT',
  },
  {
    value: 'IST',
    text: 'トルコ(イスタンブール) - IST',
  },
  {
    value: 'IZM',
    text: 'トルコ(イズミール) - IZM',
  },
  {
    value: 'KSY',
    text: 'トルコ(カルス) - KSY',
  },
  {
    value: 'KYA',
    text: 'トルコ(コンヤ) - KYA',
  },
  {
    value: 'NAV',
    text: 'トルコ(ネヴシェヒール) - NAV',
  },
  {
    value: 'QRQ',
    text: 'トルコ(マルマリス) - QRQ',
  },
  {
    value: 'SZF',
    text: 'トルコ(サムスン) - SZF',
  },
  {
    value: 'TZX',
    text: 'トルコ(トラブゾン) - TZX',
  },
  {
    value: 'VAN',
    text: 'トルコ(バン) - VAN',
  },
  {
    value: 'YEI',
    text: 'トルコ(ブルサ) - YEI',
  },
  {
    value: 'DAR',
    text: 'タンザニア(ダルエスサラーム) - DAR',
  },
  {
    value: 'JRO',
    text: 'タンザニア(キリマンジャロ) - JRO',
  },
  {
    value: 'ZNZ',
    text: 'タンザニア(ザンジバル) - ZNZ',
  },
  {
    value: 'EBB',
    text: 'ウガンダ(エンテベ) - EBB',
  },
  {
    value: 'AAM',
    text: '南アフリカ(マラマラ) - AAM',
  },
  {
    value: 'BFN',
    text: '南アフリカ(ブルームフォンテーン) - BFN',
  },
  {
    value: 'CPT',
    text: '南アフリカ(ケープタウン) - CPT',
  },
  {
    value: 'DUR',
    text: '南アフリカ(ダーバン) - DUR',
  },
  {
    value: 'ELS',
    text: '南アフリカ(イーストロンドン) - ELS',
  },
  {
    value: 'GRJ',
    text: '南アフリカ(ジョージ) - GRJ',
  },
  {
    value: 'HDS',
    text: '南アフリカ(フートスプレイト) - HDS',
  },
  {
    value: 'JNB',
    text: '南アフリカ(ヨハネスブルグ) - JNB',
  },
  {
    value: 'KIM',
    text: '南アフリカ(キンバリー) - KIM',
  },
  {
    value: 'MQP',
    text: '南アフリカ(ネルスポロイト) - MQP',
  },
  {
    value: 'NTY',
    text: '南アフリカ(サンシティ) - NTY',
  },
  {
    value: 'PHW',
    text: '南アフリカ(ファラボルワ) - PHW',
  },
  {
    value: 'PLZ',
    text: '南アフリカ(ポートエリザベス) - PLZ',
  },
  {
    value: 'PTG',
    text: '南アフリカ(ポロクワネ) - PTG',
  },
  {
    value: 'PZB',
    text: '南アフリカ(ピーターマリッツバーグ) - PZB',
  },
  {
    value: 'RCB',
    text: '南アフリカ(リチャーズ ベイ) - RCB',
  },
  {
    value: 'SZK',
    text: '南アフリカ(スククザ) - SZK',
  },
  {
    value: 'UTN',
    text: '南アフリカ(アピントン) - UTN',
  },
  {
    value: 'UTT',
    text: '南アフリカ(ウムタタ) - UTT',
  },
  {
    value: 'LUN',
    text: 'ザンビア(ルサカ) - LUN',
  },
  {
    value: 'LVI',
    text: 'ザンビア(リビングストーン) - LVI',
  },
  {
    value: 'NLA',
    text: 'ザンビア(ヌドラ) - NLA',
  },
  {
    value: 'BUQ',
    text: 'ジンバブエ(ブラワヨ) - BUQ',
  },
  {
    value: 'HRE',
    text: 'ジンバブエ(ハラーレ) - HRE',
  },
  {
    value: 'HWN',
    text: 'ジンバブエ(ホワンゲ) - HWN',
  },
  {
    value: 'KAB',
    text: 'ジンバブエ(カリバ) - KAB',
  },
  {
    value: 'VFA',
    text: 'ジンバブエ(ヴィクトリアフォ－ルズ) - VFA',
  },
  {
    value: 'LAU',
    text: 'カナダ(ローレンシャン) - LAU',
  },
  {
    value: 'XLV',
    text: 'カナダ(ナイアガラフォールズ（カナダ）) - XLV',
  },
  {
    value: 'YAL',
    text: 'カナダ(アレートベイ) - YAL',
  },
  {
    value: 'YAM',
    text: 'カナダ(スー　セント　マリー) - YAM',
  },
  {
    value: 'YBA',
    text: 'カナダ(バンフ) - YBA',
  },
  {
    value: 'YBC',
    text: 'カナダ(ベイ コモー) - YBC',
  },
  {
    value: 'YBG',
    text: 'カナダ(バゴトビル) - YBG',
  },
  {
    value: 'YBL',
    text: 'カナダ(キャンベルリバー) - YBL',
  },
  {
    value: 'YCB',
    text: 'カナダ(ケンブリッジベイ) - YCB',
  },
  {
    value: 'YCD',
    text: 'カナダ(ナナイモ) - YCD',
  },
  {
    value: 'YCF',
    text: 'カナダ(コーツアイランド) - YCF',
  },
  {
    value: 'YCG',
    text: 'カナダ(カスルガー) - YCG',
  },
  {
    value: 'YCH',
    text: 'カナダ(ミラミチ) - YCH',
  },
  {
    value: 'YCL',
    text: 'カナダ(チャーロ) - YCL',
  },
  {
    value: 'YDF',
    text: 'カナダ(ディアレイク) - YDF',
  },
  {
    value: 'YDQ',
    text: 'カナダ(ドーソンクリーク) - YDQ',
  },
  {
    value: 'YEA',
    text: 'カナダ(エドモントン) - YEA',
  },
  {
    value: 'YEK',
    text: 'カナダ(アービアト) - YEK',
  },
  {
    value: 'YEL',
    text: 'カナダ(エリオットレイク) - YEL',
  },
  {
    value: 'YEV',
    text: 'カナダ(イヌビク) - YEV',
  },
  {
    value: 'YFB',
    text: 'カナダ(イカルイト) - YFB',
  },
  {
    value: 'YFC',
    text: 'カナダ(フレデリクトン) - YFC',
  },
  {
    value: 'YFO',
    text: 'カナダ(フリンフロン) - YFO',
  },
  {
    value: 'YGK',
    text: 'カナダ(キングストン) - YGK',
  },
  {
    value: 'YGP',
    text: 'カナダ(ガスペ) - YGP',
  },
  {
    value: 'YGQ',
    text: 'カナダ(ジェラルドトン) - YGQ',
  },
  {
    value: 'YGR',
    text: 'カナダ(マドレーヌ諸島) - YGR',
  },
  {
    value: 'YGX',
    text: 'カナダ(ジラン) - YGX',
  },
  {
    value: 'YHD',
    text: 'カナダ(ドライデン) - YHD',
  },
  {
    value: 'YHG',
    text: 'カナダ(シャーロットタウン) - YHG',
  },
  {
    value: 'YHM',
    text: 'カナダ(ハミルトン) - YHM',
  },
  {
    value: 'YHZ',
    text: 'カナダ(ハリファクス) - YHZ',
  },
  {
    value: 'YJA',
    text: 'カナダ(ジャスパー) - YJA',
  },
  {
    value: 'YJT',
    text: 'カナダ(ステファンビル) - YJT',
  },
  {
    value: 'YKA',
    text: 'カナダ(カムル－プス) - YKA',
  },
  {
    value: 'YKF',
    text: 'カナダ(ウォータールー) - YKF',
  },
  {
    value: 'YLW',
    text: 'カナダ(ケローナ) - YLW',
  },
  {
    value: 'YMM',
    text: 'カナダ(フォートマクマレー) - YMM',
  },
  {
    value: 'YMQ',
    text: 'カナダ(モントリオール) - YMQ',
  },
  {
    value: 'YOJ',
    text: 'カナダ(ハイレベル) - YOJ',
  },
  {
    value: 'YOW',
    text: 'カナダ(オタワ) - YOW',
  },
  {
    value: 'YPE',
    text: 'カナダ(ピースリバー) - YPE',
  },
  {
    value: 'YPR',
    text: 'カナダ(プリンスルパート) - YPR',
  },
  {
    value: 'YQB',
    text: 'カナダ(ケベックシティ) - YQB',
  },
  {
    value: 'YQD',
    text: 'カナダ(ザ　パス) - YQD',
  },
  {
    value: 'YQG',
    text: 'カナダ(ウインザー) - YQG',
  },
  {
    value: 'YQH',
    text: 'カナダ(ワトソンレイク) - YQH',
  },
  {
    value: 'YQL',
    text: 'カナダ(レスブリッジ) - YQL',
  },
  {
    value: 'YQM',
    text: 'カナダ(モンクトン) - YQM',
  },
  {
    value: 'YQQ',
    text: 'カナダ(コモックス) - YQQ',
  },
  {
    value: 'YQR',
    text: 'カナダ(レジャイナ) - YQR',
  },
  {
    value: 'YQT',
    text: 'カナダ(サンダーベイ) - YQT',
  },
  {
    value: 'YQU',
    text: 'カナダ(グランドプレーリー) - YQU',
  },
  {
    value: 'YQX',
    text: 'カナダ(ガンダー) - YQX',
  },
  {
    value: 'YQY',
    text: 'カナダ(シドニー) - YQY',
  },
  {
    value: 'YQZ',
    text: 'カナダ(クイネル) - YQZ',
  },
  {
    value: 'YSB',
    text: 'カナダ(サドバリー) - YSB',
  },
  {
    value: 'YSJ',
    text: 'カナダ(セントジョン) - YSJ',
  },
  {
    value: 'YSL',
    text: 'カナダ(セントレオナルド) - YSL',
  },
  {
    value: 'YSM',
    text: 'カナダ(フォートスミス) - YSM',
  },
  {
    value: 'YST',
    text: 'カナダ(セイントテレーズ) - YST',
  },
  {
    value: 'YTH',
    text: 'カナダ(トンプソン) - YTH',
  },
  {
    value: 'YTO',
    text: 'カナダ(トロント) - YTO',
  },
  {
    value: 'YTS',
    text: 'カナダ(ティミンズ) - YTS',
  },
  {
    value: 'YUY',
    text: 'カナダ(ローイン) - YUY',
  },
  {
    value: 'YVC',
    text: 'カナダ(ラロンガ) - YVC',
  },
  {
    value: 'YVO',
    text: 'カナダ(バルドール) - YVO',
  },
  {
    value: 'YVR',
    text: 'カナダ(バンクーバー) - YVR',
  },
  {
    value: 'YWG',
    text: 'カナダ(ウィニペグ) - YWG',
  },
  {
    value: 'YWK',
    text: 'カナダ(ワブッシュ) - YWK',
  },
  {
    value: 'YWL',
    text: 'カナダ(ウィリアムズレイク) - YWL',
  },
  {
    value: 'YWS',
    text: 'カナダ(ウィスラ－) - YWS',
  },
  {
    value: 'YXC',
    text: 'カナダ(クランブルック) - YXC',
  },
  {
    value: 'YXE',
    text: 'カナダ(サスカトゥーン) - YXE',
  },
  {
    value: 'YXH',
    text: 'カナダ(メディシンハット) - YXH',
  },
  {
    value: 'YXJ',
    text: 'カナダ(フォートセントジョージ) - YXJ',
  },
  {
    value: 'YXS',
    text: 'カナダ(プリンスジョージ) - YXS',
  },
  {
    value: 'YXT',
    text: 'カナダ(テラス) - YXT',
  },
  {
    value: 'YXU',
    text: 'カナダ(ロンドン) - YXU',
  },
  {
    value: 'YXY',
    text: 'カナダ(ホワイトホース) - YXY',
  },
  {
    value: 'YYB',
    text: 'カナダ(ノースベイ) - YYB',
  },
  {
    value: 'YYC',
    text: 'カナダ(カルガリー) - YYC',
  },
  {
    value: 'YYD',
    text: 'カナダ(スミサーズ) - YYD',
  },
  {
    value: 'YYE',
    text: 'カナダ(フォートネルソン) - YYE',
  },
  {
    value: 'YYF',
    text: 'カナダ(ペンチクトン) - YYF',
  },
  {
    value: 'YYG',
    text: 'カナダ(シャーロットタウン（プリンスエドワード島）) - YYG',
  },
  {
    value: 'YYJ',
    text: 'カナダ(ビクトリア) - YYJ',
  },
  {
    value: 'YYQ',
    text: 'カナダ(チャーチル) - YYQ',
  },
  {
    value: 'YYR',
    text: 'カナダ(グーズベイ) - YYR',
  },
  {
    value: 'YYT',
    text: 'カナダ(セントションズ) - YYT',
  },
  {
    value: 'YYY',
    text: 'カナダ(モントジョイ) - YYY',
  },
  {
    value: 'YZF',
    text: 'カナダ(イエローナイフ) - YZF',
  },
  {
    value: 'YZP',
    text: 'カナダ(サンドスピット) - YZP',
  },
  {
    value: 'YZR',
    text: 'カナダ(サルニア) - YZR',
  },
  {
    value: 'YZT',
    text: 'カナダ(ポートハーディー) - YZT',
  },
  {
    value: 'YZV',
    text: 'カナダ(セプトイリス) - YZV',
  },
  {
    value: 'ZBF',
    text: 'カナダ(バースルスト) - ZBF',
  },
  {
    value: 'ABE',
    text: 'アメリカ合衆国(アレンタウン) - ABE',
  },
  {
    value: 'ABI',
    text: 'アメリカ合衆国(アビリーン) - ABI',
  },
  {
    value: 'ABQ',
    text: 'アメリカ合衆国(アルバカ－キ) - ABQ',
  },
  {
    value: 'ABR',
    text: 'アメリカ合衆国(アバディーン（サウスダコタ州）) - ABR',
  },
  {
    value: 'ABY',
    text: 'アメリカ合衆国(アルバニー（ジョージア州）) - ABY',
  },
  {
    value: 'ACK',
    text: 'アメリカ合衆国(ナンタッケット) - ACK',
  },
  {
    value: 'ACT',
    text: 'アメリカ合衆国(ウェーコー) - ACT',
  },
  {
    value: 'ACV',
    text: 'アメリカ合衆国(アーカタ（ユーリカ）) - ACV',
  },
  {
    value: 'ACY',
    text: 'アメリカ合衆国(アトランティックシティ) - ACY',
  },
  {
    value: 'ADQ',
    text: 'アメリカ合衆国(コディアック) - ADQ',
  },
  {
    value: 'AEX',
    text: 'アメリカ合衆国(アレクサンドリア（ルイジアナ州）) - AEX',
  },
  {
    value: 'AGS',
    text: 'アメリカ合衆国(オーガスタ（ジョージア州）) - AGS',
  },
  {
    value: 'AHN',
    text: 'アメリカ合衆国(アセンズ) - AHN',
  },
  {
    value: 'AIA',
    text: 'アメリカ合衆国(アライアンス) - AIA',
  },
  {
    value: 'AKN',
    text: 'アメリカ合衆国(キングサーモン) - AKN',
  },
  {
    value: 'ALB',
    text: 'アメリカ合衆国(アルバニー（ニューヨーク州）) - ALB',
  },
  {
    value: 'ALM',
    text: 'アメリカ合衆国(アラモゴード) - ALM',
  },
  {
    value: 'ALO',
    text: 'アメリカ合衆国(ウォータールー) - ALO',
  },
  {
    value: 'ALS',
    text: 'アメリカ合衆国(アラモサ) - ALS',
  },
  {
    value: 'ALW',
    text: 'アメリカ合衆国(ウォラウォラ) - ALW',
  },
  {
    value: 'AMA',
    text: 'アメリカ合衆国(アマリロ) - AMA',
  },
  {
    value: 'ANA',
    text: 'アメリカ合衆国(アナハイム) - ANA',
  },
  {
    value: 'ANB',
    text: 'アメリカ合衆国(アニストン) - ANB',
  },
  {
    value: 'ANC',
    text: 'アメリカ合衆国(アンカレッジ) - ANC',
  },
  {
    value: 'ANI',
    text: 'アメリカ合衆国(アニアック) - ANI',
  },
  {
    value: 'AOO',
    text: 'アメリカ合衆国(アルトゥーナ) - AOO',
  },
  {
    value: 'APF',
    text: 'アメリカ合衆国(ナプルズ) - APF',
  },
  {
    value: 'APN',
    text: 'アメリカ合衆国(アルピーナ) - APN',
  },
  {
    value: 'ART',
    text: 'アメリカ合衆国(ウォータータウン（ニューヨーク州）) - ART',
  },
  {
    value: 'ARV',
    text: 'アメリカ合衆国(ミノッカ) - ARV',
  },
  {
    value: 'ASE',
    text: 'アメリカ合衆国(アスペン) - ASE',
  },
  {
    value: 'ATL',
    text: 'アメリカ合衆国(アトランタ) - ATL',
  },
  {
    value: 'ATW',
    text: 'アメリカ合衆国(アップルトン) - ATW',
  },
  {
    value: 'ATY',
    text: 'アメリカ合衆国(ウォータータウン（サウスダコタ州）) - ATY',
  },
  {
    value: 'AUG',
    text: 'アメリカ合衆国(オーガスタ（メイン州）) - AUG',
  },
  {
    value: 'AUS',
    text: 'アメリカ合衆国(オースティン) - AUS',
  },
  {
    value: 'AVL',
    text: 'アメリカ合衆国(アッシュビル) - AVL',
  },
  {
    value: 'AVP',
    text: 'アメリカ合衆国(ウィルクスバレー) - AVP',
  },
  {
    value: 'AZO',
    text: 'アメリカ合衆国(カラマズー) - AZO',
  },
  {
    value: 'BCC',
    text: 'アメリカ合衆国(ベアクリーク) - BCC',
  },
  {
    value: 'BCE',
    text: 'アメリカ合衆国(ブライズキャニオン（国立公園）) - BCE',
  },
  {
    value: 'BDL',
    text: 'アメリカ合衆国(ハートフォード) - BDL',
  },
  {
    value: 'BDR',
    text: 'アメリカ合衆国(ブリッジポート) - BDR',
  },
  {
    value: 'BEH',
    text: 'アメリカ合衆国(ベントンハーバー) - BEH',
  },
  {
    value: 'BET',
    text: 'アメリカ合衆国(ベセル（アラスカ州）) - BET',
  },
  {
    value: 'BFD',
    text: 'アメリカ合衆国(ブラッドフォード) - BFD',
  },
  {
    value: 'BFF',
    text: 'アメリカ合衆国(スコッツブルフ) - BFF',
  },
  {
    value: 'BFL',
    text: 'アメリカ合衆国(ベーカースフィールド) - BFL',
  },
  {
    value: 'BGM',
    text: 'アメリカ合衆国(ビンガムトン) - BGM',
  },
  {
    value: 'BGR',
    text: 'アメリカ合衆国(バンガー) - BGR',
  },
  {
    value: 'BHB',
    text: 'アメリカ合衆国(バーハーバー) - BHB',
  },
  {
    value: 'BHM',
    text: 'アメリカ合衆国(バーミンガム) - BHM',
  },
  {
    value: 'BID',
    text: 'アメリカ合衆国(ブロックアイランド) - BID',
  },
  {
    value: 'BIH',
    text: 'アメリカ合衆国(ビショップ) - BIH',
  },
  {
    value: 'BIL',
    text: 'アメリカ合衆国(ビリングス) - BIL',
  },
  {
    value: 'BIS',
    text: 'アメリカ合衆国(ビスマーク) - BIS',
  },
  {
    value: 'BJI',
    text: 'アメリカ合衆国(ベミジー) - BJI',
  },
  {
    value: 'BKW',
    text: 'アメリカ合衆国(ベックリー) - BKW',
  },
  {
    value: 'BKX',
    text: 'アメリカ合衆国(ブルッキングス) - BKX',
  },
  {
    value: 'BLF',
    text: 'アメリカ合衆国(ブルーフィールド) - BLF',
  },
  {
    value: 'BLH',
    text: 'アメリカ合衆国(ブリス) - BLH',
  },
  {
    value: 'BLI',
    text: 'アメリカ合衆国(ベリンナム) - BLI',
  },
  {
    value: 'BMG',
    text: 'アメリカ合衆国(ブルーミントン（インディアナ州）) - BMG',
  },
  {
    value: 'BMI',
    text: 'アメリカ合衆国(ブルーミントン（イリノイ州）) - BMI',
  },
  {
    value: 'BNA',
    text: 'アメリカ合衆国(ナッシュビル) - BNA',
  },
  {
    value: 'BOI',
    text: 'アメリカ合衆国(ボイシ) - BOI',
  },
  {
    value: 'BOS',
    text: 'アメリカ合衆国(ボストン) - BOS',
  },
  {
    value: 'BPT',
    text: 'アメリカ合衆国(ボーモント) - BPT',
  },
  {
    value: 'BRD',
    text: 'アメリカ合衆国(ブレーナード) - BRD',
  },
  {
    value: 'BRL',
    text: 'アメリカ合衆国(バーリントン（アイオワ州）) - BRL',
  },
  {
    value: 'BRO',
    text: 'アメリカ合衆国(ブラウンズビル) - BRO',
  },
  {
    value: 'BRW',
    text: 'アメリカ合衆国(バロー) - BRW',
  },
  {
    value: 'BTM',
    text: 'アメリカ合衆国(ビュッテ) - BTM',
  },
  {
    value: 'BTR',
    text: 'アメリカ合衆国(バートンルージュ) - BTR',
  },
  {
    value: 'BTT',
    text: 'アメリカ合衆国(ベテルス) - BTT',
  },
  {
    value: 'BTV',
    text: 'アメリカ合衆国(バーリントン（バーモント州）) - BTV',
  },
  {
    value: 'BUF',
    text: 'アメリカ合衆国(バッファロー) - BUF',
  },
  {
    value: 'BUR',
    text: 'アメリカ合衆国(バーバンク) - BUR',
  },
  {
    value: 'BWD',
    text: 'アメリカ合衆国(ブラウンウッド) - BWD',
  },
  {
    value: 'BWI',
    text: 'アメリカ合衆国(ボルティモア) - BWI',
  },
  {
    value: 'BXS',
    text: 'アメリカ合衆国(ボーレグスプリングス) - BXS',
  },
  {
    value: 'BZN',
    text: 'アメリカ合衆国(ボースマン) - BZN',
  },
  {
    value: 'CAD',
    text: 'アメリカ合衆国(キャディラック) - CAD',
  },
  {
    value: 'CAE',
    text: 'アメリカ合衆国(コロンビア（サウスカロライナ州）) - CAE',
  },
  {
    value: 'CAK',
    text: 'アメリカ合衆国(アクロン・カントン) - CAK',
  },
  {
    value: 'CBE',
    text: 'アメリカ合衆国(コンバーランド) - CBE',
  },
  {
    value: 'CCR',
    text: 'アメリカ合衆国(コンコート) - CCR',
  },
  {
    value: 'CDC',
    text: 'アメリカ合衆国(シーダーシティ) - CDC',
  },
  {
    value: 'CDH',
    text: 'アメリカ合衆国(カムデン) - CDH',
  },
  {
    value: 'CDR',
    text: 'アメリカ合衆国(チャドロン) - CDR',
  },
  {
    value: 'CDV',
    text: 'アメリカ合衆国(コルドバ) - CDV',
  },
  {
    value: 'CEC',
    text: 'アメリカ合衆国(クレセントシティ) - CEC',
  },
  {
    value: 'CEZ',
    text: 'アメリカ合衆国(コーデス) - CEZ',
  },
  {
    value: 'CGA',
    text: 'アメリカ合衆国(クライグ) - CGA',
  },
  {
    value: 'CHA',
    text: 'アメリカ合衆国(チャタヌーガ) - CHA',
  },
  {
    value: 'CHI',
    text: 'アメリカ合衆国(シカゴ) - CHI',
  },
  {
    value: 'CHO',
    text: 'アメリカ合衆国(シャーロッツビル（ヴァージニア）) - CHO',
  },
  {
    value: 'CHS',
    text: 'アメリカ合衆国(チャールストン) - CHS',
  },
  {
    value: 'CIC',
    text: 'アメリカ合衆国(チコ) - CIC',
  },
  {
    value: 'CID',
    text: 'アメリカ合衆国(シーダーラピッズ) - CID',
  },
  {
    value: 'CKB',
    text: 'アメリカ合衆国(クラークスバーグ) - CKB',
  },
  {
    value: 'CKV',
    text: 'アメリカ合衆国(クラックスビル) - CKV',
  },
  {
    value: 'CLD',
    text: 'アメリカ合衆国(カールスバッド（カリフォルニア州）) - CLD',
  },
  {
    value: 'CLE',
    text: 'アメリカ合衆国(クリーブランド) - CLE',
  },
  {
    value: 'CLL',
    text: 'アメリカ合衆国(カレッジステーション) - CLL',
  },
  {
    value: 'CLM',
    text: 'アメリカ合衆国(ポートエンジェルズ) - CLM',
  },
  {
    value: 'CLT',
    text: 'アメリカ合衆国(シャーロット（ノースキャロライナ）) - CLT',
  },
  {
    value: 'CMH',
    text: 'アメリカ合衆国(コロンバス（オハイオ州）) - CMH',
  },
  {
    value: 'CMI',
    text: 'アメリカ合衆国(シャンペイン) - CMI',
  },
  {
    value: 'CMX',
    text: 'アメリカ合衆国(ハンクック) - CMX',
  },
  {
    value: 'CNM',
    text: 'アメリカ合衆国(カールスバッド（ニューメキシコ州）) - CNM',
  },
  {
    value: 'CNY',
    text: 'アメリカ合衆国(モアブ) - CNY',
  },
  {
    value: 'COD',
    text: 'アメリカ合衆国(コディ) - COD',
  },
  {
    value: 'COE',
    text: 'アメリカ合衆国(コードラン) - COE',
  },
  {
    value: 'COS',
    text: 'アメリカ合衆国(コロラドスプリングス) - COS',
  },
  {
    value: 'COU',
    text: 'アメリカ合衆国(コロンビア（ミズーリ州）) - COU',
  },
  {
    value: 'CPR',
    text: 'アメリカ合衆国(カスパー) - CPR',
  },
  {
    value: 'CRP',
    text: 'アメリカ合衆国(コ－パスクリスティ－) - CRP',
  },
  {
    value: 'CRW',
    text: 'アメリカ合衆国(チャールストン) - CRW',
  },
  {
    value: 'CSG',
    text: 'アメリカ合衆国(コロンバス（ジョージア州）) - CSG',
  },
  {
    value: 'CSL',
    text: 'アメリカ合衆国(サンルイスオビスポ) - CSL',
  },
  {
    value: 'CVG',
    text: 'アメリカ合衆国(シンシナティ) - CVG',
  },
  {
    value: 'CVN',
    text: 'アメリカ合衆国(クロビス) - CVN',
  },
  {
    value: 'CWI',
    text: 'アメリカ合衆国(クリントン（アイオワ州）) - CWI',
  },
  {
    value: 'CXL',
    text: 'アメリカ合衆国(カレクシコ) - CXL',
  },
  {
    value: 'CYS',
    text: 'アメリカ合衆国(シャイアン) - CYS',
  },
  {
    value: 'DAB',
    text: 'アメリカ合衆国(デイトナビーチ) - DAB',
  },
  {
    value: 'DAN',
    text: 'アメリカ合衆国(ダンビル（バージニア州）) - DAN',
  },
  {
    value: 'DAY',
    text: 'アメリカ合衆国(デイトン) - DAY',
  },
  {
    value: 'DBQ',
    text: 'アメリカ合衆国(ダビューク) - DBQ',
  },
  {
    value: 'DDC',
    text: 'アメリカ合衆国(ダッジシティ) - DDC',
  },
  {
    value: 'DEC',
    text: 'アメリカ合衆国(ディケーター（イリノイ州）) - DEC',
  },
  {
    value: 'DEN',
    text: 'アメリカ合衆国(デンバー) - DEN',
  },
  {
    value: 'DFW',
    text: 'アメリカ合衆国(ダラスフォートワース) - DFW',
  },
  {
    value: 'DHN',
    text: 'アメリカ合衆国(ドーサン) - DHN',
  },
  {
    value: 'DIK',
    text: 'アメリカ合衆国(ディクソン) - DIK',
  },
  {
    value: 'DLG',
    text: 'アメリカ合衆国(ダリングハム) - DLG',
  },
  {
    value: 'DLH',
    text: 'アメリカ合衆国(ダルース) - DLH',
  },
  {
    value: 'DNV',
    text: 'アメリカ合衆国(ダンビル（イリノイ州）) - DNV',
  },
  {
    value: 'DRO',
    text: 'アメリカ合衆国(ドゥランゴ) - DRO',
  },
  {
    value: 'DSM',
    text: 'アメリカ合衆国(デモイン（アイオワ州）) - DSM',
  },
  {
    value: 'DTA',
    text: 'アメリカ合衆国(デルタ) - DTA',
  },
  {
    value: 'DTT',
    text: 'アメリカ合衆国(デトロイト) - DTT',
  },
  {
    value: 'DUJ',
    text: 'アメリカ合衆国(デュボア) - DUJ',
  },
  {
    value: 'DUT',
    text: 'アメリカ合衆国(ダッチハーバー) - DUT',
  },
  {
    value: 'DVL',
    text: 'アメリカ合衆国(デビルスレーク) - DVL',
  },
  {
    value: 'EAR',
    text: 'アメリカ合衆国(キーアニー) - EAR',
  },
  {
    value: 'EAT',
    text: 'アメリカ合衆国(ウエナッチ) - EAT',
  },
  {
    value: 'EAU',
    text: 'アメリカ合衆国(オークレアー) - EAU',
  },
  {
    value: 'EEN',
    text: 'アメリカ合衆国(キーン) - EEN',
  },
  {
    value: 'EGE',
    text: 'アメリカ合衆国(ベイルイーグル) - EGE',
  },
  {
    value: 'EGV',
    text: 'アメリカ合衆国(イーグルリバー) - EGV',
  },
  {
    value: 'EKI',
    text: 'アメリカ合衆国(エルクハート) - EKI',
  },
  {
    value: 'EKN',
    text: 'アメリカ合衆国(エルキンス) - EKN',
  },
  {
    value: 'EKO',
    text: 'アメリカ合衆国(エルコ) - EKO',
  },
  {
    value: 'ELD',
    text: 'アメリカ合衆国(エルドラド) - ELD',
  },
  {
    value: 'ELM',
    text: 'アメリカ合衆国(エルミラ) - ELM',
  },
  {
    value: 'ELP',
    text: 'アメリカ合衆国(エルパソ) - ELP',
  },
  {
    value: 'ELY',
    text: 'アメリカ合衆国(イリー（ネバダ州）) - ELY',
  },
  {
    value: 'ENA',
    text: 'アメリカ合衆国(キーナイ) - ENA',
  },
  {
    value: 'ERI',
    text: 'アメリカ合衆国(エリー) - ERI',
  },
  {
    value: 'ESC',
    text: 'アメリカ合衆国(エスカナーバ) - ESC',
  },
  {
    value: 'ESD',
    text: 'アメリカ合衆国(イーストサウンド) - ESD',
  },
  {
    value: 'ESF',
    text: 'アメリカ合衆国(アレクサンドリア) - ESF',
  },
  {
    value: 'EUG',
    text: 'アメリカ合衆国(ユジーン) - EUG',
  },
  {
    value: 'EVV',
    text: 'アメリカ合衆国(エブァンスビル) - EVV',
  },
  {
    value: 'EWB',
    text: 'アメリカ合衆国(ニューベッドフォード) - EWB',
  },
  {
    value: 'EWN',
    text: 'アメリカ合衆国(ニューバーン) - EWN',
  },
  {
    value: 'EYW',
    text: 'アメリカ合衆国(キーウェスト) - EYW',
  },
  {
    value: 'FAI',
    text: 'アメリカ合衆国(フェアバンクス) - FAI',
  },
  {
    value: 'FAR',
    text: 'アメリカ合衆国(ファーゴ（ノースダコタ州）) - FAR',
  },
  {
    value: 'FAT',
    text: 'アメリカ合衆国(フレスノ) - FAT',
  },
  {
    value: 'FAY',
    text: 'アメリカ合衆国(ファイヤットビル) - FAY',
  },
  {
    value: 'FCA',
    text: 'アメリカ合衆国(カリスペル) - FCA',
  },
  {
    value: 'FHU',
    text: 'アメリカ合衆国(フォードフーチュカ) - FHU',
  },
  {
    value: 'FKL',
    text: 'アメリカ合衆国(フランクリン) - FKL',
  },
  {
    value: 'FLL',
    text: 'アメリカ合衆国(フォートローダーデール) - FLL',
  },
  {
    value: 'FLO',
    text: 'アメリカ合衆国(フローレンス（サウスカロライナ州）) - FLO',
  },
  {
    value: 'FMN',
    text: 'アメリカ合衆国(ファーミントン（ニューメキシコ州）) - FMN',
  },
  {
    value: 'FMY',
    text: 'アメリカ合衆国(フォートマイヤーズ) - FMY',
  },
  {
    value: 'FNL',
    text: 'アメリカ合衆国(フォートコリンズ) - FNL',
  },
  {
    value: 'FNT',
    text: 'アメリカ合衆国(フリント) - FNT',
  },
  {
    value: 'FOD',
    text: 'アメリカ合衆国(フォートダッジ) - FOD',
  },
  {
    value: 'FPR',
    text: 'アメリカ合衆国(フォートピアース) - FPR',
  },
  {
    value: 'FRD',
    text: 'アメリカ合衆国(フライデイハーバー) - FRD',
  },
  {
    value: 'FSD',
    text: 'アメリカ合衆国(スーフォールズ) - FSD',
  },
  {
    value: 'FSM',
    text: 'アメリカ合衆国(フォートスミス（アーカンソー州）) - FSM',
  },
  {
    value: 'FWA',
    text: 'アメリカ合衆国(フォートウェイン) - FWA',
  },
  {
    value: 'FYV',
    text: 'アメリカ合衆国(ファイヤットビル) - FYV',
  },
  {
    value: 'GAD',
    text: 'アメリカ合衆国(ガッズデン) - GAD',
  },
  {
    value: 'GBD',
    text: 'アメリカ合衆国(グレートベンド) - GBD',
  },
  {
    value: 'GBG',
    text: 'アメリカ合衆国(ゲールズバーグ（イリノイ州）) - GBG',
  },
  {
    value: 'GCC',
    text: 'アメリカ合衆国(ジレット) - GCC',
  },
  {
    value: 'GCK',
    text: 'アメリカ合衆国(ガーデンシティ) - GCK',
  },
  {
    value: 'GCN',
    text: 'アメリカ合衆国(グランドキャニオン) - GCN',
  },
  {
    value: 'GDV',
    text: 'アメリカ合衆国(グレンダイブ) - GDV',
  },
  {
    value: 'GEG',
    text: 'アメリカ合衆国(スポーケン) - GEG',
  },
  {
    value: 'GFK',
    text: 'アメリカ合衆国(グランドフォークス) - GFK',
  },
  {
    value: 'GGG',
    text: 'アメリカ合衆国(ロングビュー) - GGG',
  },
  {
    value: 'GGW',
    text: 'アメリカ合衆国(グラスゴー) - GGW',
  },
  {
    value: 'GJT',
    text: 'アメリカ合衆国(グランドジャンクション) - GJT',
  },
  {
    value: 'GLD',
    text: 'アメリカ合衆国(グッドランド) - GLD',
  },
  {
    value: 'GLH',
    text: 'アメリカ合衆国(グリーンビル) - GLH',
  },
  {
    value: 'GMT',
    text: 'アメリカ合衆国(グラナイト・マウンテン) - GMT',
  },
  {
    value: 'GNV',
    text: 'アメリカ合衆国(ゲインズビル) - GNV',
  },
  {
    value: 'GON',
    text: 'アメリカ合衆国(ニューロンドンロートン) - GON',
  },
  {
    value: 'GPT',
    text: 'アメリカ合衆国(ガルフポート) - GPT',
  },
  {
    value: 'GPZ',
    text: 'アメリカ合衆国(グランドラピッズ) - GPZ',
  },
  {
    value: 'GRB',
    text: 'アメリカ合衆国(グリーンベイ) - GRB',
  },
  {
    value: 'GRI',
    text: 'アメリカ合衆国(グランドアイランド) - GRI',
  },
  {
    value: 'GRK',
    text: 'アメリカ合衆国(キリーン・フォート・フット) - GRK',
  },
  {
    value: 'GRR',
    text: 'アメリカ合衆国(グランドラピッズ) - GRR',
  },
  {
    value: 'GSO',
    text: 'アメリカ合衆国(グリーンズボロ) - GSO',
  },
  {
    value: 'GSP',
    text: 'アメリカ合衆国(グリーンビル) - GSP',
  },
  {
    value: 'GST',
    text: 'アメリカ合衆国(ガステイヴァス) - GST',
  },
  {
    value: 'GTF',
    text: 'アメリカ合衆国(グレートフォールズ（モンタナ州）) - GTF',
  },
  {
    value: 'GTR',
    text: 'アメリカ合衆国(コロンバス（ミシシッピ州）) - GTR',
  },
  {
    value: 'GUC',
    text: 'アメリカ合衆国(ガニソン) - GUC',
  },
  {
    value: 'GUP',
    text: 'アメリカ合衆国(ギャラップ) - GUP',
  },
  {
    value: 'HAE',
    text: 'アメリカ合衆国(ハバスパイ) - HAE',
  },
  {
    value: 'HAR',
    text: 'アメリカ合衆国(ハリスバーグ) - HAR',
  },
  {
    value: 'HDN',
    text: 'アメリカ合衆国(スティ－ムボ－トスプリングス) - HDN',
  },
  {
    value: 'HFD',
    text: 'アメリカ合衆国(ハートフォード) - HFD',
  },
  {
    value: 'HGR',
    text: 'アメリカ合衆国(ヘーガーズタウン) - HGR',
  },
  {
    value: 'HHH',
    text: 'アメリカ合衆国(ヒルトンヘッド島) - HHH',
  },
  {
    value: 'HIB',
    text: 'アメリカ合衆国(ヒビング) - HIB',
  },
  {
    value: 'HKY',
    text: 'アメリカ合衆国(ヒッコリー) - HKY',
  },
  {
    value: 'HLN',
    text: 'アメリカ合衆国(ヘレナ) - HLN',
  },
  {
    value: 'HNH',
    text: 'アメリカ合衆国(フーナ) - HNH',
  },
  {
    value: 'HNS',
    text: 'アメリカ合衆国(ハインズ（アラスカ州）) - HNS',
  },
  {
    value: 'HOB',
    text: 'アメリカ合衆国(ホッブス) - HOB',
  },
  {
    value: 'HOM',
    text: 'アメリカ合衆国(ホーマー) - HOM',
  },
  {
    value: 'HON',
    text: 'アメリカ合衆国(ヒューロン) - HON',
  },
  {
    value: 'HOU',
    text: 'アメリカ合衆国(ヒューストン) - HOU',
  },
  {
    value: 'HPN',
    text: 'アメリカ合衆国(ウエストチェスターカントリー) - HPN',
  },
  {
    value: 'HRL',
    text: 'アメリカ合衆国(ハーリンゲン) - HRL',
  },
  {
    value: 'HRO',
    text: 'アメリカ合衆国(ハリスン) - HRO',
  },
  {
    value: 'HSI',
    text: 'アメリカ合衆国(ハスティングス) - HSI',
  },
  {
    value: 'HST',
    text: 'アメリカ合衆国(ホームステッド) - HST',
  },
  {
    value: 'HSV',
    text: 'アメリカ合衆国(ハンツビル) - HSV',
  },
  {
    value: 'HTO',
    text: 'アメリカ合衆国(イーストハンプトン) - HTO',
  },
  {
    value: 'HTS',
    text: 'アメリカ合衆国(ハンティントン) - HTS',
  },
  {
    value: 'HUF',
    text: 'アメリカ合衆国(テレホート) - HUF',
  },
  {
    value: 'HVN',
    text: 'アメリカ合衆国(ニューヘブン) - HVN',
  },
  {
    value: 'HVR',
    text: 'アメリカ合衆国(ハバー) - HVR',
  },
  {
    value: 'HYA',
    text: 'アメリカ合衆国(ハイアニス（マサチューセッツ州）) - HYA',
  },
  {
    value: 'HYS',
    text: 'アメリカ合衆国(ヘイズ) - HYS',
  },
  {
    value: 'IAG',
    text: 'アメリカ合衆国(ナイアガラフォールズ（ニューヨーク州) - IAG',
  },
  {
    value: 'ICT',
    text: 'アメリカ合衆国(ウィチタ) - ICT',
  },
  {
    value: 'IDA',
    text: 'アメリカ合衆国(アイダホフォールズ) - IDA',
  },
  {
    value: 'IGM',
    text: 'アメリカ合衆国(キングマン（アリゾナ州）) - IGM',
  },
  {
    value: 'ILE',
    text: 'アメリカ合衆国(キリーン) - ILE',
  },
  {
    value: 'ILI',
    text: 'アメリカ合衆国(イリアンナ) - ILI',
  },
  {
    value: 'ILM',
    text: 'アメリカ合衆国(ウィルミントン) - ILM',
  },
  {
    value: 'IMT',
    text: 'アメリカ合衆国(アイアンマウンテン) - IMT',
  },
  {
    value: 'IND',
    text: 'アメリカ合衆国(インディアナポリス) - IND',
  },
  {
    value: 'INL',
    text: 'アメリカ合衆国(インターナショナルフォールズ) - INL',
  },
  {
    value: 'INT',
    text: 'アメリカ合衆国(ウィンストンサレム) - INT',
  },
  {
    value: 'INW',
    text: 'アメリカ合衆国(ウィンズロー) - INW',
  },
  {
    value: 'IPL',
    text: 'アメリカ合衆国(エルセントロ) - IPL',
  },
  {
    value: 'IPT',
    text: 'アメリカ合衆国(ウィリアムズポート) - IPT',
  },
  {
    value: 'IRK',
    text: 'アメリカ合衆国(カークスビル) - IRK',
  },
  {
    value: 'ISN',
    text: 'アメリカ合衆国(ウィリストン) - ISN',
  },
  {
    value: 'ISO',
    text: 'アメリカ合衆国(キンストン) - ISO',
  },
  {
    value: 'ISP',
    text: 'アメリカ合衆国(イスリップ) - ISP',
  },
  {
    value: 'ITH',
    text: 'アメリカ合衆国(イサカ) - ITH',
  },
  {
    value: 'IWD',
    text: 'アメリカ合衆国(アイアンウッド) - IWD',
  },
  {
    value: 'IYK',
    text: 'アメリカ合衆国(インヨーカーン) - IYK',
  },
  {
    value: 'JAC',
    text: 'アメリカ合衆国(ジャクソン（ワイオミング州）) - JAC',
  },
  {
    value: 'JAN',
    text: 'アメリカ合衆国(ジャクソン（ミシシッピ州）) - JAN',
  },
  {
    value: 'JAX',
    text: 'アメリカ合衆国(ジャクソンビル) - JAX',
  },
  {
    value: 'JBR',
    text: 'アメリカ合衆国(ジョーンズバロ（アーカンソー州）) - JBR',
  },
  {
    value: 'JHW',
    text: 'アメリカ合衆国(ジェームスタウン（ニューヨーク州）) - JHW',
  },
  {
    value: 'JLN',
    text: 'アメリカ合衆国(ジョプリン) - JLN',
  },
  {
    value: 'JMS',
    text: 'アメリカ合衆国(ジェームスタウン（ノースダコタ州）) - JMS',
  },
  {
    value: 'JNU',
    text: 'アメリカ合衆国(ジューノー) - JNU',
  },
  {
    value: 'JST',
    text: 'アメリカ合衆国(ジョンズタウン（ペンシルベニア州）) - JST',
  },
  {
    value: 'JVL',
    text: 'アメリカ合衆国(ジャネスビル) - JVL',
  },
  {
    value: 'JXN',
    text: 'アメリカ合衆国(ジャクソン（ミシガン州）) - JXN',
  },
  {
    value: 'KSM',
    text: 'アメリカ合衆国(セントメリーズ) - KSM',
  },
  {
    value: 'KTN',
    text: 'アメリカ合衆国(ケチカン) - KTN',
  },
  {
    value: 'LAA',
    text: 'アメリカ合衆国(ラマー) - LAA',
  },
  {
    value: 'LAF',
    text: 'アメリカ合衆国(ラファイエット) - LAF',
  },
  {
    value: 'LAM',
    text: 'アメリカ合衆国(ロスアラモス) - LAM',
  },
  {
    value: 'LAN',
    text: 'アメリカ合衆国(ランシング) - LAN',
  },
  {
    value: 'LAR',
    text: 'アメリカ合衆国(ララミー) - LAR',
  },
  {
    value: 'LAS',
    text: 'アメリカ合衆国(ラスベガス) - LAS',
  },
  {
    value: 'LAW',
    text: 'アメリカ合衆国(ロートン（オクラホマ州）) - LAW',
  },
  {
    value: 'LAX',
    text: 'アメリカ合衆国(ロサンゼルス) - LAX',
  },
  {
    value: 'LBB',
    text: 'アメリカ合衆国(ラバック) - LBB',
  },
  {
    value: 'LBE',
    text: 'アメリカ合衆国(ラトローブ) - LBE',
  },
  {
    value: 'LBF',
    text: 'アメリカ合衆国(ノースプラット) - LBF',
  },
  {
    value: 'LBL',
    text: 'アメリカ合衆国(リベラル) - LBL',
  },
  {
    value: 'LCH',
    text: 'アメリカ合衆国(レークチャールズ) - LCH',
  },
  {
    value: 'LCI',
    text: 'アメリカ合衆国(ラコニア) - LCI',
  },
  {
    value: 'LEB',
    text: 'アメリカ合衆国(レバノン) - LEB',
  },
  {
    value: 'LEW',
    text: 'アメリカ合衆国(リューイストン（メイン州）) - LEW',
  },
  {
    value: 'LEX',
    text: 'アメリカ合衆国(レキシントン) - LEX',
  },
  {
    value: 'LFT',
    text: 'アメリカ合衆国(ラファイエット) - LFT',
  },
  {
    value: 'LGB',
    text: 'アメリカ合衆国(ロングビーチ) - LGB',
  },
  {
    value: 'LHU',
    text: 'アメリカ合衆国(レイクハバス) - LHU',
  },
  {
    value: 'LIT',
    text: 'アメリカ合衆国(リトルロック) - LIT',
  },
  {
    value: 'LMT',
    text: 'アメリカ合衆国(クラマスフォールズ) - LMT',
  },
  {
    value: 'LNK',
    text: 'アメリカ合衆国(リンカーン) - LNK',
  },
  {
    value: 'LNS',
    text: 'アメリカ合衆国(ランカスター) - LNS',
  },
  {
    value: 'LOU',
    text: 'アメリカ合衆国(ルイビル（ケンタッキー州）) - LOU',
  },
  {
    value: 'LPS',
    text: 'アメリカ合衆国(ロペツアイランド) - LPS',
  },
  {
    value: 'LRD',
    text: 'アメリカ合衆国(ラレード) - LRD',
  },
  {
    value: 'LRU',
    text: 'アメリカ合衆国(ラスクルーセス) - LRU',
  },
  {
    value: 'LSE',
    text: 'アメリカ合衆国(ラクロス（ウイスコンシン州）) - LSE',
  },
  {
    value: 'LUL',
    text: 'アメリカ合衆国(ローレル) - LUL',
  },
  {
    value: 'LWB',
    text: 'アメリカ合衆国(ルイスバーグ（ウエストバージニア州）) - LWB',
  },
  {
    value: 'LWS',
    text: 'アメリカ合衆国(ルイストン) - LWS',
  },
  {
    value: 'LWT',
    text: 'アメリカ合衆国(ルイスタウン（モンタナ州）) - LWT',
  },
  {
    value: 'LYH',
    text: 'アメリカ合衆国(リンチバーグ) - LYH',
  },
  {
    value: 'LYU',
    text: 'アメリカ合衆国(エリー) - LYU',
  },
  {
    value: 'MAF',
    text: 'アメリカ合衆国(ミッドランド（テキサス州）) - MAF',
  },
  {
    value: 'MBL',
    text: 'アメリカ合衆国(マニスティー) - MBL',
  },
  {
    value: 'MBS',
    text: 'アメリカ合衆国(サギノー) - MBS',
  },
  {
    value: 'MCD',
    text: 'アメリカ合衆国(マキナック) - MCD',
  },
  {
    value: 'MCE',
    text: 'アメリカ合衆国(マーセド) - MCE',
  },
  {
    value: 'MCK',
    text: 'アメリカ合衆国(マックック) - MCK',
  },
  {
    value: 'MCN',
    text: 'アメリカ合衆国(メーコン) - MCN',
  },
  {
    value: 'MCW',
    text: 'アメリカ合衆国(メイソンシティ) - MCW',
  },
  {
    value: 'MDH',
    text: 'アメリカ合衆国(カーボンデール（イリノイ州）) - MDH',
  },
  {
    value: 'MDJ',
    text: 'アメリカ合衆国(マドラス) - MDJ',
  },
  {
    value: 'MEI',
    text: 'アメリカ合衆国(メリディアン) - MEI',
  },
  {
    value: 'MEM',
    text: 'アメリカ合衆国(メンフィス) - MEM',
  },
  {
    value: 'MFD',
    text: 'アメリカ合衆国(マンスフィールド) - MFD',
  },
  {
    value: 'MFE',
    text: 'アメリカ合衆国(マクアーレン) - MFE',
  },
  {
    value: 'MFR',
    text: 'アメリカ合衆国(メッドフォード（オレゴン州）) - MFR',
  },
  {
    value: 'MGM',
    text: 'アメリカ合衆国(モンゴメリー（アラバマ州）) - MGM',
  },
  {
    value: 'MGR',
    text: 'アメリカ合衆国(モールトリー) - MGR',
  },
  {
    value: 'MGW',
    text: 'アメリカ合衆国(モーガンタウン) - MGW',
  },
  {
    value: 'MHE',
    text: 'アメリカ合衆国(ミッチェル) - MHE',
  },
  {
    value: 'MHK',
    text: 'アメリカ合衆国(マンハッタン（カンザス州）) - MHK',
  },
  {
    value: 'MHT',
    text: 'アメリカ合衆国(マンチェスター) - MHT',
  },
  {
    value: 'MIA',
    text: 'アメリカ合衆国(マイアミ) - MIA',
  },
  {
    value: 'MIE',
    text: 'アメリカ合衆国(マンシー) - MIE',
  },
  {
    value: 'MKC',
    text: 'アメリカ合衆国(カンサスシティ（ミズーリ州) - MKC',
  },
  {
    value: 'MKE',
    text: 'アメリカ合衆国(ミルウォーキー) - MKE',
  },
  {
    value: 'MKG',
    text: 'アメリカ合衆国(マスキーゴン) - MKG',
  },
  {
    value: 'MKL',
    text: 'アメリカ合衆国(ジャクソン（テネシー州）) - MKL',
  },
  {
    value: 'MKT',
    text: 'アメリカ合衆国(マンケート) - MKT',
  },
  {
    value: 'MLB',
    text: 'アメリカ合衆国(メルボルン) - MLB',
  },
  {
    value: 'MLC',
    text: 'アメリカ合衆国(マカレスター) - MLC',
  },
  {
    value: 'MLI',
    text: 'アメリカ合衆国(モリン) - MLI',
  },
  {
    value: 'MLS',
    text: 'アメリカ合衆国(マイルズシティ) - MLS',
  },
  {
    value: 'MLU',
    text: 'アメリカ合衆国(モンロー) - MLU',
  },
  {
    value: 'MMH',
    text: 'アメリカ合衆国(マンモスレイク) - MMH',
  },
  {
    value: 'MML',
    text: 'アメリカ合衆国(マーシャル) - MML',
  },
  {
    value: 'MNM',
    text: 'アメリカ合衆国(メノミニ) - MNM',
  },
  {
    value: 'MOB',
    text: 'アメリカ合衆国(モビール) - MOB',
  },
  {
    value: 'MOD',
    text: 'アメリカ合衆国(モデスト) - MOD',
  },
  {
    value: 'MOT',
    text: 'アメリカ合衆国(マイノット) - MOT',
  },
  {
    value: 'MPV',
    text: 'アメリカ合衆国(モントピーリア) - MPV',
  },
  {
    value: 'MQT',
    text: 'アメリカ合衆国(マーケット（ミシガン州）) - MQT',
  },
  {
    value: 'MRY',
    text: 'アメリカ合衆国(モントレー（カリフォルニア州) - MRY',
  },
  {
    value: 'MSD',
    text: 'アメリカ合衆国(マウントプレサント) - MSD',
  },
  {
    value: 'MSL',
    text: 'アメリカ合衆国(マスルショールス) - MSL',
  },
  {
    value: 'MSN',
    text: 'アメリカ合衆国(マディソン) - MSN',
  },
  {
    value: 'MSO',
    text: 'アメリカ合衆国(ミズーラ) - MSO',
  },
  {
    value: 'MSP',
    text: 'アメリカ合衆国(ミネアポリス) - MSP',
  },
  {
    value: 'MSS',
    text: 'アメリカ合衆国(マセーナ) - MSS',
  },
  {
    value: 'MSY',
    text: 'アメリカ合衆国(ニューオーリンズ) - MSY',
  },
  {
    value: 'MTH',
    text: 'アメリカ合衆国(マラソン) - MTH',
  },
  {
    value: 'MTJ',
    text: 'アメリカ合衆国(モントローズ（コロラド州）) - MTJ',
  },
  {
    value: 'MTO',
    text: 'アメリカ合衆国(マトゥーン) - MTO',
  },
  {
    value: 'MTW',
    text: 'アメリカ合衆国(マニトウォク) - MTW',
  },
  {
    value: 'MVN',
    text: 'アメリカ合衆国(マウントバーノン（イリノイ州）) - MVN',
  },
  {
    value: 'MVY',
    text: 'アメリカ合衆国(マーサスビニヤード島) - MVY',
  },
  {
    value: 'MWA',
    text: 'アメリカ合衆国(マリオン) - MWA',
  },
  {
    value: 'MWH',
    text: 'アメリカ合衆国(モーゼスレイク) - MWH',
  },
  {
    value: 'MYR',
    text: 'アメリカ合衆国(マートルビーチ) - MYR',
  },
  {
    value: 'NPH',
    text: 'アメリカ合衆国(ネフィ) - NPH',
  },
  {
    value: 'NRS',
    text: 'アメリカ合衆国(インペリアルビーチ) - NRS',
  },
  {
    value: 'NYC',
    text: 'アメリカ合衆国(ニューヨーク) - NYC',
  },
  {
    value: 'OAJ',
    text: 'アメリカ合衆国(ジャクソンビル) - OAJ',
  },
  {
    value: 'OAK',
    text: 'アメリカ合衆国(オークランド（カリフォルニア州）) - OAK',
  },
  {
    value: 'ODW',
    text: 'アメリカ合衆国(オークハーバー（ワシントン州）) - ODW',
  },
  {
    value: 'OFK',
    text: 'アメリカ合衆国(ノーフォーク（ネブラスカ州）) - OFK',
  },
  {
    value: 'OGS',
    text: 'アメリカ合衆国(オグデンズバーグ（ニューヨーク州）) - OGS',
  },
  {
    value: 'OIC',
    text: 'アメリカ合衆国(ノーウィッチ) - OIC',
  },
  {
    value: 'OKC',
    text: 'アメリカ合衆国(オクラホマシティ) - OKC',
  },
  {
    value: 'OKK',
    text: 'アメリカ合衆国(ココモ) - OKK',
  },
  {
    value: 'OLF',
    text: 'アメリカ合衆国(ウルフポイント) - OLF',
  },
  {
    value: 'OLU',
    text: 'アメリカ合衆国(コロンバス（ネブラスカ州）) - OLU',
  },
  {
    value: 'OMA',
    text: 'アメリカ合衆国(オマハ) - OMA',
  },
  {
    value: 'OME',
    text: 'アメリカ合衆国(ノーマ) - OME',
  },
  {
    value: 'ONT',
    text: 'アメリカ合衆国(オンタリオ) - ONT',
  },
  {
    value: 'ORF',
    text: 'アメリカ合衆国(ノーフォーク（バージニア州）) - ORF',
  },
  {
    value: 'ORH',
    text: 'アメリカ合衆国(ウースター) - ORH',
  },
  {
    value: 'ORL',
    text: 'アメリカ合衆国(オーランド) - ORL',
  },
  {
    value: 'OSH',
    text: 'アメリカ合衆国(オシュコシュ（ウイスコンシン州）) - OSH',
  },
  {
    value: 'OTH',
    text: 'アメリカ合衆国(ノースベンド（オレゴン州）) - OTH',
  },
  {
    value: 'OTM',
    text: 'アメリカ合衆国(オタムア) - OTM',
  },
  {
    value: 'OTZ',
    text: 'アメリカ合衆国(コッツェビュー) - OTZ',
  },
  {
    value: 'OWB',
    text: 'アメリカ合衆国(オーエンズボロ) - OWB',
  },
  {
    value: 'OXR',
    text: 'アメリカ合衆国(オックスナード) - OXR',
  },
  {
    value: 'PAH',
    text: 'アメリカ合衆国(パデュカ) - PAH',
  },
  {
    value: 'PBI',
    text: 'アメリカ合衆国(ウエストパームビーチ) - PBI',
  },
  {
    value: 'PCT',
    text: 'アメリカ合衆国(プリンストン) - PCT',
  },
  {
    value: 'PDT',
    text: 'アメリカ合衆国(ペンドルトン) - PDT',
  },
  {
    value: 'PDX',
    text: 'アメリカ合衆国(ポートランド（オレゴン州）) - PDX',
  },
  {
    value: 'PGA',
    text: 'アメリカ合衆国(ページ（アリゾナ州）) - PGA',
  },
  {
    value: 'PGV',
    text: 'アメリカ合衆国(グリーンビル) - PGV',
  },
  {
    value: 'PHF',
    text: 'アメリカ合衆国(ニューポートニューズ) - PHF',
  },
  {
    value: 'PHL',
    text: 'アメリカ合衆国(フィラデルフィア) - PHL',
  },
  {
    value: 'PHX',
    text: 'アメリカ合衆国(フェニックス) - PHX',
  },
  {
    value: 'PIA',
    text: 'アメリカ合衆国(ペオリア) - PIA',
  },
  {
    value: 'PIH',
    text: 'アメリカ合衆国(ポカテッロ) - PIH',
  },
  {
    value: 'PIR',
    text: 'アメリカ合衆国(ピア) - PIR',
  },
  {
    value: 'PIT',
    text: 'アメリカ合衆国(ピッツバーグ) - PIT',
  },
  {
    value: 'PKB',
    text: 'アメリカ合衆国(パーカーズバーグ) - PKB',
  },
  {
    value: 'PLN',
    text: 'アメリカ合衆国(ペルストン) - PLN',
  },
  {
    value: 'PMD',
    text: 'アメリカ合衆国(パルンダレー) - PMD',
  },
  {
    value: 'PNC',
    text: 'アメリカ合衆国(ポンカシティ) - PNC',
  },
  {
    value: 'PNS',
    text: 'アメリカ合衆国(ペンサコラ) - PNS',
  },
  {
    value: 'POU',
    text: 'アメリカ合衆国(ポーキープシ) - POU',
  },
  {
    value: 'PPF',
    text: 'アメリカ合衆国(パーソンズ) - PPF',
  },
  {
    value: 'PQI',
    text: 'アメリカ合衆国(プレスク アイル（メイン州）) - PQI',
  },
  {
    value: 'PRC',
    text: 'アメリカ合衆国(プレスコット（アリゾナ州）) - PRC',
  },
  {
    value: 'PRX',
    text: 'アメリカ合衆国(パリ（テキサス州）) - PRX',
  },
  {
    value: 'PSC',
    text: 'アメリカ合衆国(パスコ) - PSC',
  },
  {
    value: 'PSF',
    text: 'アメリカ合衆国(ピッツフィールド（マサチューセッツ州）) - PSF',
  },
  {
    value: 'PSG',
    text: 'アメリカ合衆国(ピーターズバーグ) - PSG',
  },
  {
    value: 'PSM',
    text: 'アメリカ合衆国(ポーツマス) - PSM',
  },
  {
    value: 'PSP',
    text: 'アメリカ合衆国(パームスプリングス) - PSP',
  },
  {
    value: 'PUB',
    text: 'アメリカ合衆国(プエブロ) - PUB',
  },
  {
    value: 'PUC',
    text: 'アメリカ合衆国(プライス) - PUC',
  },
  {
    value: 'PUW',
    text: 'アメリカ合衆国(プルマン（ワシントン州）) - PUW',
  },
  {
    value: 'PVD',
    text: 'アメリカ合衆国(プロビデンス) - PVD',
  },
  {
    value: 'PVU',
    text: 'アメリカ合衆国(プロボ) - PVU',
  },
  {
    value: 'PWM',
    text: 'アメリカ合衆国(ポートランド（メイン州）) - PWM',
  },
  {
    value: 'RAP',
    text: 'アメリカ合衆国(ラピッドシティ) - RAP',
  },
  {
    value: 'RCE',
    text: 'アメリカ合衆国(ロシュハーバー) - RCE',
  },
  {
    value: 'RDD',
    text: 'アメリカ合衆国(レディング) - RDD',
  },
  {
    value: 'RDG',
    text: 'アメリカ合衆国(レディング) - RDG',
  },
  {
    value: 'RDM',
    text: 'アメリカ合衆国(レッドモンド) - RDM',
  },
  {
    value: 'RDU',
    text: 'アメリカ合衆国(ローリーダーラム) - RDU',
  },
  {
    value: 'RFD',
    text: 'アメリカ合衆国(ロックフォード) - RFD',
  },
  {
    value: 'RHI',
    text: 'アメリカ合衆国(ラインランダ) - RHI',
  },
  {
    value: 'RIC',
    text: 'アメリカ合衆国(リッチモンド) - RIC',
  },
  {
    value: 'RIF',
    text: 'アメリカ合衆国(リッチフィールド（ユタ州）) - RIF',
  },
  {
    value: 'RIW',
    text: 'アメリカ合衆国(リバートン) - RIW',
  },
  {
    value: 'RKD',
    text: 'アメリカ合衆国(ロックランド) - RKD',
  },
  {
    value: 'RKS',
    text: 'アメリカ合衆国(ロックスプリングス) - RKS',
  },
  {
    value: 'RNO',
    text: 'アメリカ合衆国(リノ) - RNO',
  },
  {
    value: 'ROA',
    text: 'アメリカ合衆国(ロアノーク) - ROA',
  },
  {
    value: 'ROC',
    text: 'アメリカ合衆国(ロチェスター) - ROC',
  },
  {
    value: 'ROW',
    text: 'アメリカ合衆国(ロズウェル) - ROW',
  },
  {
    value: 'RST',
    text: 'アメリカ合衆国(ロチェスター（ミネソタ州）) - RST',
  },
  {
    value: 'RUI',
    text: 'アメリカ合衆国(ルイドソ) - RUI',
  },
  {
    value: 'RUT',
    text: 'アメリカ合衆国(ラットランド) - RUT',
  },
  {
    value: 'RWI',
    text: 'アメリカ合衆国(ロッキーマウント（ノースカロライナ州）) - RWI',
  },
  {
    value: 'SAF',
    text: 'アメリカ合衆国(サンタフェ（ニューメキシコ州）) - SAF',
  },
  {
    value: 'SAN',
    text: 'アメリカ合衆国(サンディエゴ（カリフォルニア州）) - SAN',
  },
  {
    value: 'SAT',
    text: 'アメリカ合衆国(サン・アントニオ) - SAT',
  },
  {
    value: 'SAV',
    text: 'アメリカ合衆国(サバンナ) - SAV',
  },
  {
    value: 'SBA',
    text: 'アメリカ合衆国(サンタバーバラ) - SBA',
  },
  {
    value: 'SBN',
    text: 'アメリカ合衆国(サウスベンド) - SBN',
  },
  {
    value: 'SBO',
    text: 'アメリカ合衆国(サリーナ) - SBO',
  },
  {
    value: 'SBP',
    text: 'アメリカ合衆国(サンルイスオビスポ) - SBP',
  },
  {
    value: 'SBS',
    text: 'アメリカ合衆国(スティームボートスプリングス) - SBS',
  },
  {
    value: 'SBY',
    text: 'アメリカ合衆国(ソールズベリー) - SBY',
  },
  {
    value: 'SCC',
    text: 'アメリカ合衆国(デットホース) - SCC',
  },
  {
    value: 'SCE',
    text: 'アメリカ合衆国(ステートカレッジ) - SCE',
  },
  {
    value: 'SCK',
    text: 'アメリカ合衆国(ストックトン) - SCK',
  },
  {
    value: 'SDF',
    text: 'アメリカ合衆国(ルイスビル) - SDF',
  },
  {
    value: 'SDX',
    text: 'アメリカ合衆国(セドナ) - SDX',
  },
  {
    value: 'SDY',
    text: 'アメリカ合衆国(シドニー) - SDY',
  },
  {
    value: 'SEA',
    text: 'アメリカ合衆国(シアトル) - SEA',
  },
  {
    value: 'SFO',
    text: 'アメリカ合衆国(サンフランシスコ) - SFO',
  },
  {
    value: 'SGF',
    text: 'アメリカ合衆国(スプリングフィールド（ミズーリ州）) - SGF',
  },
  {
    value: 'SGU',
    text: 'アメリカ合衆国(センドジョージ) - SGU',
  },
  {
    value: 'SHD',
    text: 'アメリカ合衆国(スタントン) - SHD',
  },
  {
    value: 'SHR',
    text: 'アメリカ合衆国(シェリダン) - SHR',
  },
  {
    value: 'SHV',
    text: 'アメリカ合衆国(シュリーブポート) - SHV',
  },
  {
    value: 'SIT',
    text: 'アメリカ合衆国(シトカ) - SIT',
  },
  {
    value: 'SJC',
    text: 'アメリカ合衆国(サン・ノゼ) - SJC',
  },
  {
    value: 'SJT',
    text: 'アメリカ合衆国(サンアンジェロ) - SJT',
  },
  {
    value: 'SLC',
    text: 'アメリカ合衆国(ソルトレイクシティ) - SLC',
  },
  {
    value: 'SLE',
    text: 'アメリカ合衆国(セーレム) - SLE',
  },
  {
    value: 'SLK',
    text: 'アメリカ合衆国(サラナク) - SLK',
  },
  {
    value: 'SLN',
    text: 'アメリカ合衆国(サリナ（カンザス州）) - SLN',
  },
  {
    value: 'SMF',
    text: 'アメリカ合衆国(サクラメント) - SMF',
  },
  {
    value: 'SMO',
    text: 'アメリカ合衆国(サンタモニカ) - SMO',
  },
  {
    value: 'SMX',
    text: 'アメリカ合衆国(サンタマリア) - SMX',
  },
  {
    value: 'SNA',
    text: 'アメリカ合衆国(サンタアナ) - SNA',
  },
  {
    value: 'SNP',
    text: 'アメリカ合衆国(セントポールアイランド) - SNP',
  },
  {
    value: 'SNY',
    text: 'アメリカ合衆国(シドニー（ネブラスカ州）) - SNY',
  },
  {
    value: 'SPI',
    text: 'アメリカ合衆国(スプリングフィールド（イリノイ州）) - SPI',
  },
  {
    value: 'SPS',
    text: 'アメリカ合衆国(ウィチタフォールズ) - SPS',
  },
  {
    value: 'SPW',
    text: 'アメリカ合衆国(スペンサー（アイオワ州）) - SPW',
  },
  {
    value: 'SQI',
    text: 'アメリカ合衆国(ステアリング) - SQI',
  },
  {
    value: 'SRQ',
    text: 'アメリカ合衆国(サラソタ) - SRQ',
  },
  {
    value: 'SSI',
    text: 'アメリカ合衆国(ブランズウィック（ジョージア州）) - SSI',
  },
  {
    value: 'SSM',
    text: 'アメリカ合衆国(スーセントマリー) - SSM',
  },
  {
    value: 'STC',
    text: 'アメリカ合衆国(セントクロード) - STC',
  },
  {
    value: 'STL',
    text: 'アメリカ合衆国(セントルイス) - STL',
  },
  {
    value: 'STS',
    text: 'アメリカ合衆国(サンタ・ローザ（カリフォルニア州）) - STS',
  },
  {
    value: 'SUN',
    text: 'アメリカ合衆国(サンバレー) - SUN',
  },
  {
    value: 'SUX',
    text: 'アメリカ合衆国(スーシティ) - SUX',
  },
  {
    value: 'SVC',
    text: 'アメリカ合衆国(シルバーシティ) - SVC',
  },
  {
    value: 'SWF',
    text: 'アメリカ合衆国(ニューバーグ) - SWF',
  },
  {
    value: 'SYR',
    text: 'アメリカ合衆国(シラキュース) - SYR',
  },
  {
    value: 'TBN',
    text: 'アメリカ合衆国(フォートレオナードウッド) - TBN',
  },
  {
    value: 'TCL',
    text: 'アメリカ合衆国(タスカルーサ) - TCL',
  },
  {
    value: 'TEX',
    text: 'アメリカ合衆国(テルユライド) - TEX',
  },
  {
    value: 'TLH',
    text: 'アメリカ合衆国(タラハシー) - TLH',
  },
  {
    value: 'TNP',
    text: 'アメリカ合衆国(トゥエンティーナインパームス) - TNP',
  },
  {
    value: 'TOL',
    text: 'アメリカ合衆国(トレド) - TOL',
  },
  {
    value: 'TOP',
    text: 'アメリカ合衆国(トピーカ) - TOP',
  },
  {
    value: 'TPA',
    text: 'アメリカ合衆国(タンパ) - TPA',
  },
  {
    value: 'TPL',
    text: 'アメリカ合衆国(テンプル) - TPL',
  },
  {
    value: 'TRI',
    text: 'アメリカ合衆国(ブリストル) - TRI',
  },
  {
    value: 'TSM',
    text: 'アメリカ合衆国(タオス) - TSM',
  },
  {
    value: 'TTN',
    text: 'アメリカ合衆国(トレントン) - TTN',
  },
  {
    value: 'TUL',
    text: 'アメリカ合衆国(タルサ) - TUL',
  },
  {
    value: 'TUP',
    text: 'アメリカ合衆国(テューペロ) - TUP',
  },
  {
    value: 'TUS',
    text: 'アメリカ合衆国(ツ－ソン) - TUS',
  },
  {
    value: 'TVC',
    text: 'アメリカ合衆国(トラバース) - TVC',
  },
  {
    value: 'TVF',
    text: 'アメリカ合衆国(シーフリバーフォールズ) - TVF',
  },
  {
    value: 'TVL',
    text: 'アメリカ合衆国(サウスレイクタホ) - TVL',
  },
  {
    value: 'TWF',
    text: 'アメリカ合衆国(ツインフォールズ) - TWF',
  },
  {
    value: 'TXK',
    text: 'アメリカ合衆国(テクサーカナ（アーカンソー州）) - TXK',
  },
  {
    value: 'TYR',
    text: 'アメリカ合衆国(タイラー（テキサス州）) - TYR',
  },
  {
    value: 'TYS',
    text: 'アメリカ合衆国(ノックスビル) - TYS',
  },
  {
    value: 'UIN',
    text: 'アメリカ合衆国(クインシー（イリノイ州）) - UIN',
  },
  {
    value: 'VCT',
    text: 'アメリカ合衆国(ビクトリア) - VCT',
  },
  {
    value: 'VDZ',
    text: 'アメリカ合衆国(バルディーズ) - VDZ',
  },
  {
    value: 'VEL',
    text: 'アメリカ合衆国(バーナル) - VEL',
  },
  {
    value: 'VIS',
    text: 'アメリカ合衆国(バイセリア) - VIS',
  },
  {
    value: 'VLD',
    text: 'アメリカ合衆国(バルドスタ) - VLD',
  },
  {
    value: 'VPS',
    text: 'アメリカ合衆国(フォートウォルトンビーチ) - VPS',
  },
  {
    value: 'VRB',
    text: 'アメリカ合衆国(ベロビーチ) - VRB',
  },
  {
    value: 'VYS',
    text: 'アメリカ合衆国(ペルー) - VYS',
  },
  {
    value: 'WAS',
    text: 'アメリカ合衆国(ワシントンDC) - WAS',
  },
  {
    value: 'WDG',
    text: 'アメリカ合衆国(イーニッド（オクラホマ州）) - WDG',
  },
  {
    value: 'WFK',
    text: 'アメリカ合衆国(フレンチビル) - WFK',
  },
  {
    value: 'WRL',
    text: 'アメリカ合衆国(ワーランド) - WRL',
  },
  {
    value: 'WST',
    text: 'アメリカ合衆国(ウェスターリー) - WST',
  },
  {
    value: 'WVL',
    text: 'アメリカ合衆国(ウォータービル) - WVL',
  },
  {
    value: 'WYS',
    text: 'アメリカ合衆国(ウエストイエローストン) - WYS',
  },
  {
    value: 'XNA',
    text: 'アメリカ合衆国(ファイヤットビル（アーカンソー州）) - XNA',
  },
  {
    value: 'YAK',
    text: 'アメリカ合衆国(ヤクタット) - YAK',
  },
  {
    value: 'YKM',
    text: 'アメリカ合衆国(ヤキマ) - YKM',
  },
  {
    value: 'YKN',
    text: 'アメリカ合衆国(ヤンクトン) - YKN',
  },
  {
    value: 'YNG',
    text: 'アメリカ合衆国(ヤングスタウン／ワ－レン) - YNG',
  },
  {
    value: 'YUM',
    text: 'アメリカ合衆国(ユマ（アリゾナ州）) - YUM',
  },
  {
    value: 'BAK',
    text: 'アゼルバイジャン(バクー) - BAK',
  },
  {
    value: 'CGP',
    text: 'バングラデシュ(チッタゴン) - CGP',
  },
  {
    value: 'DAC',
    text: 'バングラデシュ(ダッカ) - DAC',
  },
  {
    value: 'ZYL',
    text: 'バングラデシュ(シレット) - ZYL',
  },
  {
    value: 'BWN',
    text: 'ブルネイ(バンダルスリベガワン) - BWN',
  },
  {
    value: 'PBH',
    text: 'ブータン(パロ) - PBH',
  },
  {
    value: 'BAV',
    text: '中華人民共和国（中国）(包頭) - BAV',
  },
  {
    value: 'BHY',
    text: '中華人民共和国（中国）(北海（ベイハイ）) - BHY',
  },
  {
    value: 'BPE',
    text: '中華人民共和国（中国）(秦皇島（チンホワンタオ）) - BPE',
  },
  {
    value: 'BPX',
    text: '中華人民共和国（中国）(昌都（ショウト）) - BPX',
  },
  {
    value: 'CAN',
    text: '中華人民共和国（中国）(クワンチョウ（広州）) - CAN',
  },
  {
    value: 'CGD',
    text: '中華人民共和国（中国）(常徳（ジョウトク）) - CGD',
  },
  {
    value: 'CGO',
    text: '中華人民共和国（中国）(鄭州) - CGO',
  },
  {
    value: 'CGQ',
    text: '中華人民共和国（中国）(チョウシュン（長春）) - CGQ',
  },
  {
    value: 'CHG',
    text: '中華人民共和国（中国）(朝陽) - CHG',
  },
  {
    value: 'CIF',
    text: '中華人民共和国（中国）(赤峰／セキホウ) - CIF',
  },
  {
    value: 'CIH',
    text: '中華人民共和国（中国）(長治（チョウジ）) - CIH',
  },
  {
    value: 'CKG',
    text: '中華人民共和国（中国）(チョンチン（重慶）) - CKG',
  },
  {
    value: 'CSX',
    text: '中華人民共和国（中国）(チャンシャ（長沙）) - CSX',
  },
  {
    value: 'CTU',
    text: '中華人民共和国（中国）(チョントゥ（成都）) - CTU',
  },
  {
    value: 'CZX',
    text: '中華人民共和国（中国）(常州（ジョウシュウ）) - CZX',
  },
  {
    value: 'DAT',
    text: '中華人民共和国（中国）(大同（ダイドウ）) - DAT',
  },
  {
    value: 'DAX',
    text: '中華人民共和国（中国）(達州（タツシュウ）) - DAX',
  },
  {
    value: 'DDG',
    text: '中華人民共和国（中国）(丹東（タントン）) - DDG',
  },
  {
    value: 'DIG',
    text: '中華人民共和国（中国）(デチェン・シャングリラ) - DIG',
  },
  {
    value: 'DLC',
    text: '中華人民共和国（中国）(ダーレン（大連）) - DLC',
  },
  {
    value: 'DLU',
    text: '中華人民共和国（中国）(大理) - DLU',
  },
  {
    value: 'DNH',
    text: '中華人民共和国（中国）(敦煌（トンコウ）) - DNH',
  },
  {
    value: 'DQA',
    text: '中華人民共和国（中国）(大慶薩爾図空港) - DQA',
  },
  {
    value: 'DSN',
    text: '中華人民共和国（中国）(鄂尓多斯（ガカトス）) - DSN',
  },
  {
    value: 'DYG',
    text: '中華人民共和国（中国）(張家界) - DYG',
  },
  {
    value: 'FOC',
    text: '中華人民共和国（中国）(フウチョウ（福州）) - FOC',
  },
  {
    value: 'GOQ',
    text: '中華人民共和国（中国）(格爾木／ゴルムド) - GOQ',
  },
  {
    value: 'GYS',
    text: '中華人民共和国（中国）(広元／コウゲン) - GYS',
  },
  {
    value: 'HAK',
    text: '中華人民共和国（中国）(ハイコウ（海口）) - HAK',
  },
  {
    value: 'HET',
    text: '中華人民共和国（中国）(呼和浩特) - HET',
  },
  {
    value: 'HFE',
    text: '中華人民共和国（中国）(合肥) - HFE',
  },
  {
    value: 'HGH',
    text: '中華人民共和国（中国）(ハンチョウ（杭州）) - HGH',
  },
  {
    value: 'HIA',
    text: '中華人民共和国（中国）(淮安) - HIA',
  },
  {
    value: 'HLD',
    text: '中華人民共和国（中国）(海拉尓（ハイラル）) - HLD',
  },
  {
    value: 'HLH',
    text: '中華人民共和国（中国）(烏蘭浩特／ウランホト) - HLH',
  },
  {
    value: 'HRB',
    text: '中華人民共和国（中国）(哈爾浜) - HRB',
  },
  {
    value: 'HSN',
    text: '中華人民共和国（中国）(舟山) - HSN',
  },
  {
    value: 'HTN',
    text: '中華人民共和国（中国）(和田／ホータン) - HTN',
  },
  {
    value: 'HYN',
    text: '中華人民共和国（中国）(黄岩) - HYN',
  },
  {
    value: 'INC',
    text: '中華人民共和国（中国）(銀川) - INC',
  },
  {
    value: 'JDZ',
    text: '中華人民共和国（中国）(景徳鎮（ケイトクチン）) - JDZ',
  },
  {
    value: 'JGN',
    text: '中華人民共和国（中国）(嘉峪関（チアユーコワン）) - JGN',
  },
  {
    value: 'JGS',
    text: '中華人民共和国（中国）(井岡山（イオカサン）) - JGS',
  },
  {
    value: 'JHG',
    text: '中華人民共和国（中国）(景洪) - JHG',
  },
  {
    value: 'JJN',
    text: '中華人民共和国（中国）(晋江) - JJN',
  },
  {
    value: 'JMU',
    text: '中華人民共和国（中国）(佳木斯) - JMU',
  },
  {
    value: 'JNZ',
    text: '中華人民共和国（中国）(錦州) - JNZ',
  },
  {
    value: 'JZH',
    text: '中華人民共和国（中国）(九塞溝) - JZH',
  },
  {
    value: 'KHG',
    text: '中華人民共和国（中国）(カシュガル) - KHG',
  },
  {
    value: 'KHN',
    text: '中華人民共和国（中国）(南昌) - KHN',
  },
  {
    value: 'KMG',
    text: '中華人民共和国（中国）(クンミン（昆明）) - KMG',
  },
  {
    value: 'KOW',
    text: '中華人民共和国（中国）(カン州黄金機場) - KOW',
  },
  {
    value: 'KWE',
    text: '中華人民共和国（中国）(貴陽) - KWE',
  },
  {
    value: 'KWL',
    text: '中華人民共和国（中国）(クイリン（桂林）) - KWL',
  },
  {
    value: 'LHW',
    text: '中華人民共和国（中国）(蘭州) - LHW',
  },
  {
    value: 'LJG',
    text: '中華人民共和国（中国）(ソーチアン（麗江）) - LJG',
  },
  {
    value: 'LUM',
    text: '中華人民共和国（中国）(芒市＜ルーシー＞) - LUM',
  },
  {
    value: 'LXA',
    text: '中華人民共和国（中国）(ラサ) - LXA',
  },
  {
    value: 'LYA',
    text: '中華人民共和国（中国）(洛陽（ラクヨウ）) - LYA',
  },
  {
    value: 'LYG',
    text: '中華人民共和国（中国）(連雲港) - LYG',
  },
  {
    value: 'LYI',
    text: '中華人民共和国（中国）(臨沂) - LYI',
  },
  {
    value: 'LZH',
    text: '中華人民共和国（中国）(柳州) - LZH',
  },
  {
    value: 'LZY',
    text: '中華人民共和国（中国）(林芝（リンジィ）) - LZY',
  },
  {
    value: 'MDG',
    text: '中華人民共和国（中国）(牡丹江) - MDG',
  },
  {
    value: 'MIG',
    text: '中華人民共和国（中国）(錦陽（メンヨウ）) - MIG',
  },
  {
    value: 'NDG',
    text: '中華人民共和国（中国）(斉斉哈爾) - NDG',
  },
  {
    value: 'NGB',
    text: '中華人民共和国（中国）(寧波) - NGB',
  },
  {
    value: 'NKG',
    text: '中華人民共和国（中国）(ナンキン（南京）) - NKG',
  },
  {
    value: 'NNG',
    text: '中華人民共和国（中国）(ナンニン（南寧）) - NNG',
  },
  {
    value: 'NTG',
    text: '中華人民共和国（中国）(南通) - NTG',
  },
  {
    value: 'PEK',
    text: '中華人民共和国（中国）(ベイジン（北京）) - PEK',
  },
  {
    value: 'PZI',
    text: '中華人民共和国（中国）(攀枝花（パンシカ）) - PZI',
  },
  {
    value: 'SHA',
    text: '中華人民共和国（中国）(シャンハイ（上海）) - SHA',
  },
  {
    value: 'SHE',
    text: '中華人民共和国（中国）(シンヨウ（瀋陽）) - SHE',
  },
  {
    value: 'SIA',
    text: '中華人民共和国（中国）(西安) - SIA',
  },
  {
    value: 'SJW',
    text: '中華人民共和国（中国）(石家荘) - SJW',
  },
  {
    value: 'SWA',
    text: '中華人民共和国（中国）(汕頭（サントウ）) - SWA',
  },
  {
    value: 'SYX',
    text: '中華人民共和国（中国）(サンヤ（三亜）) - SYX',
  },
  {
    value: 'SZX',
    text: '中華人民共和国（中国）(シンセン（深セン）) - SZX',
  },
  {
    value: 'TAO',
    text: '中華人民共和国（中国）(チンタオ（青島）) - TAO',
  },
  {
    value: 'TGO',
    text: '中華人民共和国（中国）(通遼) - TGO',
  },
  {
    value: 'TNA',
    text: '中華人民共和国（中国）(チーナン（済南）) - TNA',
  },
  {
    value: 'TSN',
    text: '中華人民共和国（中国）(テンシン（天津）) - TSN',
  },
  {
    value: 'TXN',
    text: '中華人民共和国（中国）(屯渓（黄山）) - TXN',
  },
  {
    value: 'TYN',
    text: '中華人民共和国（中国）(タイユワン（太原）) - TYN',
  },
  {
    value: 'URC',
    text: '中華人民共和国（中国）(ウルムチ（烏魯木斉）) - URC',
  },
  {
    value: 'UYN',
    text: '中華人民共和国（中国）(楡林) - UYN',
  },
  {
    value: 'WEF',
    text: '中華人民共和国（中国）(イ坊) - WEF',
  },
  {
    value: 'WEH',
    text: '中華人民共和国（中国）(イカイ（威海）) - WEH',
  },
  {
    value: 'WNZ',
    text: '中華人民共和国（中国）(温州) - WNZ',
  },
  {
    value: 'WUH',
    text: '中華人民共和国（中国）(ウーハン（武漢）) - WUH',
  },
  {
    value: 'WUS',
    text: '中華人民共和国（中国）(武夷山) - WUS',
  },
  {
    value: 'WUX',
    text: '中華人民共和国（中国）(無錫) - WUX',
  },
  {
    value: 'WXN',
    text: '中華人民共和国（中国）(万県（バンケン）) - WXN',
  },
  {
    value: 'XFN',
    text: '中華人民共和国（中国）(襄樊（ジョウハン）) - XFN',
  },
  {
    value: 'XIL',
    text: '中華人民共和国（中国）(錫林浩特（シリンホト）) - XIL',
  },
  {
    value: 'XMN',
    text: '中華人民共和国（中国）(シャーメン（厦門）) - XMN',
  },
  {
    value: 'XNN',
    text: '中華人民共和国（中国）(シーニン（西寧）) - XNN',
  },
  {
    value: 'XUZ',
    text: '中華人民共和国（中国）(徐州（ジョシュウ）) - XUZ',
  },
  {
    value: 'YBP',
    text: '中華人民共和国（中国）(宜賓（ＹＩＰＩＮ）) - YBP',
  },
  {
    value: 'YCU',
    text: '中華人民共和国（中国）(運城（ウンジョウ）) - YCU',
  },
  {
    value: 'YIH',
    text: '中華人民共和国（中国）(宜昌) - YIH',
  },
  {
    value: 'YIW',
    text: '中華人民共和国（中国）(義烏（ギウ）) - YIW',
  },
  {
    value: 'YNJ',
    text: '中華人民共和国（中国）(ヤンジ（延吉）) - YNJ',
  },
  {
    value: 'YNT',
    text: '中華人民共和国（中国）(煙台) - YNT',
  },
  {
    value: 'YNZ',
    text: '中華人民共和国（中国）(鹽城（エンジョウ）) - YNZ',
  },
  {
    value: 'YTY',
    text: '中華人民共和国（中国）(揚州) - YTY',
  },
  {
    value: 'ZHA',
    text: '中華人民共和国（中国）(チャンチィアン（湛江）) - ZHA',
  },
  {
    value: 'ZUH',
    text: '中華人民共和国（中国）(珠海) - ZUH',
  },
  {
    value: 'ZUJ',
    text: '中華人民共和国（中国）(鎮江) - ZUJ',
  },
  {
    value: 'HKG',
    text: '香港・マカオ(ホンコン（香港）) - HKG',
  },
  {
    value: 'MFM',
    text: '香港・マカオ(マカオ) - MFM',
  },
  {
    value: 'AMI',
    text: 'インドネシア(マタラム（ロンボクアイランド）) - AMI',
  },
  {
    value: 'AMQ',
    text: 'インドネシア(アンボン) - AMQ',
  },
  {
    value: 'BAL',
    text: 'インドネシア(バリ) - BAL',
  },
  {
    value: 'BDJ',
    text: 'インドネシア(バンジャルマシン) - BDJ',
  },
  {
    value: 'BDO',
    text: 'インドネシア(バンドウン) - BDO',
  },
  {
    value: 'BIK',
    text: 'インドネシア(ビアク島) - BIK',
  },
  {
    value: 'BPN',
    text: 'インドネシア(バリクパパン) - BPN',
  },
  {
    value: 'BTH',
    text: 'インドネシア(バタンバツバサール) - BTH',
  },
  {
    value: 'BTJ',
    text: 'インドネシア(バンダアチェ) - BTJ',
  },
  {
    value: 'DPS',
    text: 'インドネシア(デンパサール) - DPS',
  },
  {
    value: 'JKT',
    text: 'インドネシア(ジャカルタ) - JKT',
  },
  {
    value: 'JOG',
    text: 'インドネシア(ジョグ・ジャカルタ) - JOG',
  },
  {
    value: 'KDI',
    text: 'インドネシア(ケンダリ) - KDI',
  },
  {
    value: 'KOE',
    text: 'インドネシア(クパン) - KOE',
  },
  {
    value: 'LOP',
    text: 'インドネシア(プラヤ) - LOP',
  },
  {
    value: 'MDC',
    text: 'インドネシア(マナド) - MDC',
  },
  {
    value: 'MES',
    text: 'インドネシア(メダン) - MES',
  },
  {
    value: 'PDG',
    text: 'インドネシア(パダン) - PDG',
  },
  {
    value: 'PKU',
    text: 'インドネシア(パカンバル) - PKU',
  },
  {
    value: 'PLM',
    text: 'インドネシア(パレンバン) - PLM',
  },
  {
    value: 'PLW',
    text: 'インドネシア(パル) - PLW',
  },
  {
    value: 'PNK',
    text: 'インドネシア(ポンティアナク) - PNK',
  },
  {
    value: 'SOC',
    text: 'インドネシア(ソロ・シティー) - SOC',
  },
  {
    value: 'SRG',
    text: 'インドネシア(スマラン) - SRG',
  },
  {
    value: 'SRI',
    text: 'インドネシア(サマリンダ) - SRI',
  },
  {
    value: 'SUB',
    text: 'インドネシア(スラバヤ) - SUB',
  },
  {
    value: 'SWQ',
    text: 'インドネシア(スンバワ島) - SWQ',
  },
  {
    value: 'TKG',
    text: 'インドネシア(バンダ－ランプ－ン) - TKG',
  },
  {
    value: 'TTR',
    text: 'インドネシア(タナトラジャ) - TTR',
  },
  {
    value: 'UPG',
    text: 'インドネシア(マカッサル（ウジュンパンダン）) - UPG',
  },
  {
    value: 'AGR',
    text: 'インド(アグラ) - AGR',
  },
  {
    value: 'AMD',
    text: 'インド(アフマダバード) - AMD',
  },
  {
    value: 'ATQ',
    text: 'インド(アムリトサル) - ATQ',
  },
  {
    value: 'BBI',
    text: 'インド(ブバネーシュワル) - BBI',
  },
  {
    value: 'BDQ',
    text: 'インド(ヴァドーダラー) - BDQ',
  },
  {
    value: 'BHO',
    text: 'インド(ボパール) - BHO',
  },
  {
    value: 'BLR',
    text: 'インド(バンガロール) - BLR',
  },
  {
    value: 'BOM',
    text: 'インド(ムンバイ) - BOM',
  },
  {
    value: 'CCJ',
    text: 'インド(カリカット) - CCJ',
  },
  {
    value: 'CCU',
    text: 'インド(カルカッタ) - CCU',
  },
  {
    value: 'CJB',
    text: 'インド(コインバトーレ) - CJB',
  },
  {
    value: 'COK',
    text: 'インド(コーチン) - COK',
  },
  {
    value: 'DED',
    text: 'インド(デヘラードゥーン) - DED',
  },
  {
    value: 'DEL',
    text: 'インド(デリー) - DEL',
  },
  {
    value: 'GAU',
    text: 'インド(ガウハチ) - GAU',
  },
  {
    value: 'GAY',
    text: 'インド(ガヤ) - GAY',
  },
  {
    value: 'GOI',
    text: 'インド(ゴア) - GOI',
  },
  {
    value: 'HJR',
    text: 'インド(カジュラホ) - HJR',
  },
  {
    value: 'HYD',
    text: 'インド(ハイデラバード) - HYD',
  },
  {
    value: 'IDR',
    text: 'インド(インドール) - IDR',
  },
  {
    value: 'IMF',
    text: 'インド(インパール) - IMF',
  },
  {
    value: 'IXA',
    text: 'インド(アガタラ) - IXA',
  },
  {
    value: 'IXB',
    text: 'インド(バグドグラ) - IXB',
  },
  {
    value: 'IXC',
    text: 'インド(チャンディガール) - IXC',
  },
  {
    value: 'IXE',
    text: 'インド(マンガロール) - IXE',
  },
  {
    value: 'IXL',
    text: 'インド(レー) - IXL',
  },
  {
    value: 'IXM',
    text: 'インド(マドゥライ) - IXM',
  },
  {
    value: 'IXR',
    text: 'インド(ラーンチー) - IXR',
  },
  {
    value: 'IXU',
    text: 'インド(アウランガバード) - IXU',
  },
  {
    value: 'JAI',
    text: 'インド(ジャイブール) - JAI',
  },
  {
    value: 'JDH',
    text: 'インド(ジョドプール) - JDH',
  },
  {
    value: 'LKO',
    text: 'インド(ラクナウ) - LKO',
  },
  {
    value: 'MAA',
    text: 'インド(チェンナイ) - MAA',
  },
  {
    value: 'NAG',
    text: 'インド(ナグプール) - NAG',
  },
  {
    value: 'PAT',
    text: 'インド(パトナ) - PAT',
  },
  {
    value: 'PNQ',
    text: 'インド(プーナ) - PNQ',
  },
  {
    value: 'TRV',
    text: 'インド(トリバンドラム) - TRV',
  },
  {
    value: 'TRZ',
    text: 'インド(ティルチラーパッリ) - TRZ',
  },
  {
    value: 'UDR',
    text: 'インド(ウダイプール) - UDR',
  },
  {
    value: 'VNS',
    text: 'インド(バラナシ) - VNS',
  },
  {
    value: 'VTZ',
    text: 'インド(ヴィッシャーカパトナム) - VTZ',
  },
  {
    value: 'AKJ',
    text: '日本(旭川) - AKJ',
  },
  {
    value: 'AOJ',
    text: '日本(青森) - AOJ',
  },
  {
    value: 'ASJ',
    text: '日本(奄美大島) - ASJ',
  },
  {
    value: 'AXT',
    text: '日本(秋田) - AXT',
  },
  {
    value: 'FKS',
    text: '日本(福島) - FKS',
  },
  {
    value: 'FSZ',
    text: '日本(静岡) - FSZ',
  },
  {
    value: 'FUK',
    text: '日本(福岡) - FUK',
  },
  {
    value: 'GAJ',
    text: '日本(山形) - GAJ',
  },
  {
    value: 'HIJ',
    text: '日本(広島) - HIJ',
  },
  {
    value: 'HKD',
    text: '日本(函館) - HKD',
  },
  {
    value: 'HNA',
    text: '日本(花巻) - HNA',
  },
  {
    value: 'HSG',
    text: '日本(佐賀) - HSG',
  },
  {
    value: 'ISG',
    text: '日本(石垣) - ISG',
  },
  {
    value: 'IWJ',
    text: '日本(益田) - IWJ',
  },
  {
    value: 'IZO',
    text: '日本(出雲空港) - IZO',
  },
  {
    value: 'KCZ',
    text: '日本(高知) - KCZ',
  },
  {
    value: 'KIJ',
    text: '日本(新潟) - KIJ',
  },
  {
    value: 'KKJ',
    text: '日本(北九州) - KKJ',
  },
  {
    value: 'KMI',
    text: '日本(宮崎) - KMI',
  },
  {
    value: 'KMJ',
    text: '日本(熊本) - KMJ',
  },
  {
    value: 'KMQ',
    text: '日本(小松) - KMQ',
  },
  {
    value: 'KOJ',
    text: '日本(鹿児島) - KOJ',
  },
  {
    value: 'KUH',
    text: '日本(釧路) - KUH',
  },
  {
    value: 'MBE',
    text: '日本(紋別) - MBE',
  },
  {
    value: 'MMB',
    text: '日本(女満別) - MMB',
  },
  {
    value: 'MMY',
    text: '日本(宮古) - MMY',
  },
  {
    value: 'MSJ',
    text: '日本(三沢) - MSJ',
  },
  {
    value: 'MYJ',
    text: '日本(松山) - MYJ',
  },
  {
    value: 'NGO',
    text: '日本(名古屋) - NGO',
  },
  {
    value: 'NGS',
    text: '日本(長崎) - NGS',
  },
  {
    value: 'NTQ',
    text: '日本(能登) - NTQ',
  },
  {
    value: 'OBO',
    text: '日本(帯広) - OBO',
  },
  {
    value: 'OIT',
    text: '日本(大分) - OIT',
  },
  {
    value: 'OKA',
    text: '日本(沖繩) - OKA',
  },
  {
    value: 'OKJ',
    text: '日本(岡山) - OKJ',
  },
  {
    value: 'ONJ',
    text: '日本(大館能代) - ONJ',
  },
  {
    value: 'OSA',
    text: '日本(大阪) - OSA',
  },
  {
    value: 'SDJ',
    text: '日本(仙台) - SDJ',
  },
  {
    value: 'SHB',
    text: '日本(中標津) - SHB',
  },
  {
    value: 'SHM',
    text: '日本(白浜) - SHM',
  },
  {
    value: 'SPK',
    text: '日本(札幌) - SPK',
  },
  {
    value: 'SYO',
    text: '日本(庄内) - SYO',
  },
  {
    value: 'TAK',
    text: '日本(高松) - TAK',
  },
  {
    value: 'TKS',
    text: '日本(徳島) - TKS',
  },
  {
    value: 'TOY',
    text: '日本(富山) - TOY',
  },
  {
    value: 'TTJ',
    text: '日本(鳥取) - TTJ',
  },
  {
    value: 'TYO',
    text: '日本(東京) - TYO',
  },
  {
    value: 'UBJ',
    text: '日本(宇部) - UBJ',
  },
  {
    value: 'WKJ',
    text: '日本(稚内) - WKJ',
  },
  {
    value: 'YGJ',
    text: '日本(米子) - YGJ',
  },
  {
    value: 'FRU',
    text: 'キルギス(ビシュケク) - FRU',
  },
  {
    value: 'OSS',
    text: 'キルギス(オシ) - OSS',
  },
  {
    value: 'KK1',
    text: 'カンボジア(ケップ) - KK1',
  },
  {
    value: 'KOS',
    text: 'カンボジア(シアヌークビル) - KOS',
  },
  {
    value: 'PNH',
    text: 'カンボジア(プノンペン) - PNH',
  },
  {
    value: 'REP',
    text: 'カンボジア(シェムリアップ) - REP',
  },
  {
    value: 'CJJ',
    text: '大韓民国(韓国）(清州（チョンジュ）) - CJJ',
  },
  {
    value: 'CJU',
    text: '大韓民国(韓国）(チェジュ（済州島）) - CJU',
  },
  {
    value: 'HIN',
    text: '大韓民国(韓国）(晋州（チンジュ）) - HIN',
  },
  {
    value: 'KAG',
    text: '大韓民国(韓国）(カンヌン) - KAG',
  },
  {
    value: 'KPO',
    text: '大韓民国(韓国）(浦項（ポハン）) - KPO',
  },
  {
    value: 'KUV',
    text: '大韓民国(韓国）(群山（クンサン）) - KUV',
  },
  {
    value: 'KWJ',
    text: '大韓民国(韓国）(光州) - KWJ',
  },
  {
    value: 'KYJ',
    text: '大韓民国(韓国）(慶州) - KYJ',
  },
  {
    value: 'PUS',
    text: '大韓民国(韓国）(プサン（釜山）) - PUS',
  },
  {
    value: 'RSU',
    text: '大韓民国(韓国）(麗水（ヨス）) - RSU',
  },
  {
    value: 'SEL',
    text: '大韓民国(韓国）(ソウル) - SEL',
  },
  {
    value: 'TAE',
    text: '大韓民国(韓国）(テグ) - TAE',
  },
  {
    value: 'USN',
    text: '大韓民国(韓国）(蔚山（ウルサン）) - USN',
  },
  {
    value: 'ALA',
    text: 'カザフスタン(マルマトイ) - ALA',
  },
  {
    value: 'TSE',
    text: 'カザフスタン(アスタナ) - TSE',
  },
  {
    value: 'LPQ',
    text: 'ラオス(ルアンプラバン) - LPQ',
  },
  {
    value: 'PKZ',
    text: 'ラオス(パークセー) - PKZ',
  },
  {
    value: 'VTE',
    text: 'ラオス(ビエンチャン) - VTE',
  },
  {
    value: 'ZVK',
    text: 'ラオス(サワンナケート) - ZVK',
  },
  {
    value: 'CMB',
    text: 'スリランカ(コロンボ) - CMB',
  },
  {
    value: 'HRI',
    text: 'スリランカ(ハンバントタ) - HRI',
  },
  {
    value: 'MDL',
    text: 'ミャンマー(マンダレー) - MDL',
  },
  {
    value: 'RGN',
    text: 'ミャンマー(ヤンゴン) - RGN',
  },
  {
    value: 'ULN',
    text: 'モンゴル(ウランバートル) - ULN',
  },
  {
    value: 'MLE',
    text: 'モルディブ(マレ) - MLE',
  },
  {
    value: 'AOR',
    text: 'マレーシア(アロースター) - AOR',
  },
  {
    value: 'BKI',
    text: 'マレーシア(コタキナバル) - BKI',
  },
  {
    value: 'BTU',
    text: 'マレーシア(ビントゥール) - BTU',
  },
  {
    value: 'CAM',
    text: 'マレーシア(キャメロンハイランド) - CAM',
  },
  {
    value: 'GTB',
    text: 'マレーシア(ゲンティン) - GTB',
  },
  {
    value: 'IPH',
    text: 'マレーシア(イポー) - IPH',
  },
  {
    value: 'JHB',
    text: 'マレーシア(ジョホ－ルバ－ル) - JHB',
  },
  {
    value: 'KBR',
    text: 'マレーシア(コタバル) - KBR',
  },
  {
    value: 'KCH',
    text: 'マレーシア(クチン) - KCH',
  },
  {
    value: 'KTE',
    text: 'マレーシア(ケルテ) - KTE',
  },
  {
    value: 'KUA',
    text: 'マレーシア(クアンタン) - KUA',
  },
  {
    value: 'KUL',
    text: 'マレーシア(クアラルンプール) - KUL',
  },
  {
    value: 'LBU',
    text: 'マレーシア(ラブアン) - LBU',
  },
  {
    value: 'LGK',
    text: 'マレーシア(ランカウイ島) - LGK',
  },
  {
    value: 'LMN',
    text: 'マレーシア(リンバン) - LMN',
  },
  {
    value: 'MKZ',
    text: 'マレーシア(マラッカ) - MKZ',
  },
  {
    value: 'MUR',
    text: 'マレーシア(マルディ) - MUR',
  },
  {
    value: 'MYY',
    text: 'マレーシア(ミリ) - MYY',
  },
  {
    value: 'MZV',
    text: 'マレーシア(ムル) - MZV',
  },
  {
    value: 'PEN',
    text: 'マレーシア(ペナン) - PEN',
  },
  {
    value: 'PKG',
    text: 'マレーシア(パンコール) - PKG',
  },
  {
    value: 'SBW',
    text: 'マレーシア(シブ) - SBW',
  },
  {
    value: 'SDK',
    text: 'マレーシア(サンダカン) - SDK',
  },
  {
    value: 'TGG',
    text: 'マレーシア(クアラトレンガヌ) - TGG',
  },
  {
    value: 'TOD',
    text: 'マレーシア(ティオマン) - TOD',
  },
  {
    value: 'TWU',
    text: 'マレーシア(タワウ) - TWU',
  },
  {
    value: 'BGL',
    text: 'ネパール(バグラン) - BGL',
  },
  {
    value: 'JMO',
    text: 'ネパール(ジョムソン) - JMO',
  },
  {
    value: 'KTM',
    text: 'ネパール(カトマンズ) - KTM',
  },
  {
    value: 'PKR',
    text: 'ネパール(ポカラ) - PKR',
  },
  {
    value: 'BAG',
    text: 'フィリピン(バギオ) - BAG',
  },
  {
    value: 'BCD',
    text: 'フィリピン(バコロド) - BCD',
  },
  {
    value: 'BO1',
    text: 'フィリピン(ボラカイ島) - BO1',
  },
  {
    value: 'BXU',
    text: 'フィリピン(ブターン) - BXU',
  },
  {
    value: 'CBO',
    text: 'フィリピン(コタバト) - CBO',
  },
  {
    value: 'CEB',
    text: 'フィリピン(セブ) - CEB',
  },
  {
    value: 'CGY',
    text: 'フィリピン(カガヤンデオロ) - CGY',
  },
  {
    value: 'DGT',
    text: 'フィリピン(ドマゲテ) - DGT',
  },
  {
    value: 'DPL',
    text: 'フィリピン(ディポログ) - DPL',
  },
  {
    value: 'DVO',
    text: 'フィリピン(ダバオ) - DVO',
  },
  {
    value: 'GES',
    text: 'フィリピン(ゼネラルサントス) - GES',
  },
  {
    value: 'ILO',
    text: 'フィリピン(イロイロ) - ILO',
  },
  {
    value: 'KLO',
    text: 'フィリピン(カリボ) - KLO',
  },
  {
    value: 'LAO',
    text: 'フィリピン(ラオアグ) - LAO',
  },
  {
    value: 'LB1',
    text: 'フィリピン(バダンガス) - LB1',
  },
  {
    value: 'LGP',
    text: 'フィリピン(レガスピ) - LGP',
  },
  {
    value: 'MNL',
    text: 'フィリピン(マニラ) - MNL',
  },
  {
    value: 'MRQ',
    text: 'フィリピン(マリンドゥケ) - MRQ',
  },
  {
    value: 'PI1',
    text: 'フィリピン(パラワン島) - PI1',
  },
  {
    value: 'PPS',
    text: 'フィリピン(プエルトプリンセサ) - PPS',
  },
  {
    value: 'RXS',
    text: 'フィリピン(ロハス) - RXS',
  },
  {
    value: 'SFS',
    text: 'フィリピン(ス－ビック) - SFS',
  },
  {
    value: 'SJI',
    text: 'フィリピン(サン・ホセ) - SJI',
  },
  {
    value: 'TAC',
    text: 'フィリピン(タクロバン) - TAC',
  },
  {
    value: 'TAG',
    text: 'フィリピン(タグビララン) - TAG',
  },
  {
    value: 'WNP',
    text: 'フィリピン(ナガ) - WNP',
  },
  {
    value: 'ZAM',
    text: 'フィリピン(サンボアンガ) - ZAM',
  },
  {
    value: 'BHC',
    text: 'パキスタン(ブルバンヘリポート) - BHC',
  },
  {
    value: 'CJL',
    text: 'パキスタン(シトラル) - CJL',
  },
  {
    value: 'ISB',
    text: 'パキスタン(イスラマバード) - ISB',
  },
  {
    value: 'KHI',
    text: 'パキスタン(カラチ) - KHI',
  },
  {
    value: 'LHE',
    text: 'パキスタン(ラホール) - LHE',
  },
  {
    value: 'MJD',
    text: 'パキスタン(モヘンジョダロ) - MJD',
  },
  {
    value: 'MUX',
    text: 'パキスタン(マルタン) - MUX',
  },
  {
    value: 'PEW',
    text: 'パキスタン(ペシャワール) - PEW',
  },
  {
    value: 'SKT',
    text: 'パキスタン(シアールコート) - SKT',
  },
  {
    value: 'SIN',
    text: 'シンガポール(シンガポール) - SIN',
  },
  {
    value: 'BKK',
    text: 'タイ(バンコク) - BKK',
  },
  {
    value: 'CEI',
    text: 'タイ(チェンライ) - CEI',
  },
  {
    value: 'CJM',
    text: 'タイ(チュムポーン) - CJM',
  },
  {
    value: 'CNX',
    text: 'タイ(チェンマイ) - CNX',
  },
  {
    value: 'HDY',
    text: 'タイ(ハジャイ) - HDY',
  },
  {
    value: 'HGN',
    text: 'タイ(メホンソン) - HGN',
  },
  {
    value: 'HHQ',
    text: 'タイ(ホアヒン) - HHQ',
  },
  {
    value: 'HKT',
    text: 'タイ(プーケット) - HKT',
  },
  {
    value: 'KBV',
    text: 'タイ(クラビ) - KBV',
  },
  {
    value: 'KKC',
    text: 'タイ(コンカン) - KKC',
  },
  {
    value: 'KOP',
    text: 'タイ(ナコンパノム) - KOP',
  },
  {
    value: 'LPT',
    text: 'タイ(ランパーン) - LPT',
  },
  {
    value: 'NAK',
    text: 'タイ(ナコンラチャシマ) - NAK',
  },
  {
    value: 'NAW',
    text: 'タイ(ナラティワート) - NAW',
  },
  {
    value: 'NST',
    text: 'タイ(ナコン・シータマラート) - NST',
  },
  {
    value: 'PG1',
    text: 'タイ(パンガー) - PG1',
  },
  {
    value: 'PHS',
    text: 'タイ(ピサノローク) - PHS',
  },
  {
    value: 'PRH',
    text: 'タイ(プレー) - PRH',
  },
  {
    value: 'PYX',
    text: 'タイ(パタヤ) - PYX',
  },
  {
    value: 'SNO',
    text: 'タイ(サコンナコーン) - SNO',
  },
  {
    value: 'TDX',
    text: 'タイ(トラート) - TDX',
  },
  {
    value: 'THS',
    text: 'タイ(スコ－タイ) - THS',
  },
  {
    value: 'TST',
    text: 'タイ(トラン) - TST',
  },
  {
    value: 'UBP',
    text: 'タイ(ウボンラチャタニ) - UBP',
  },
  {
    value: 'UNN',
    text: 'タイ(ラノーン) - UNN',
  },
  {
    value: 'URT',
    text: 'タイ(スラタニ) - URT',
  },
  {
    value: 'USM',
    text: 'タイ(コ－サムイ（サムイ島）) - USM',
  },
  {
    value: 'UTH',
    text: 'タイ(ウジュンタニ) - UTH',
  },
  {
    value: 'UTP',
    text: 'タイ(ウタパオ) - UTP',
  },
  {
    value: 'DYU',
    text: 'タジキスタン(ドゥシャンベ) - DYU',
  },
  {
    value: 'DIL',
    text: '東ティモール(ディリ) - DIL',
  },
  {
    value: 'ASB',
    text: 'トルクメニスタン(アシハバード) - ASB',
  },
  {
    value: 'CYI',
    text: '台湾(嘉義) - CYI',
  },
  {
    value: 'HCN',
    text: '台湾(恒春) - HCN',
  },
  {
    value: 'HUN',
    text: '台湾(花蓮) - HUN',
  },
  {
    value: 'KHH',
    text: '台湾(カオシュン（高雄）) - KHH',
  },
  {
    value: 'KNH',
    text: '台湾(金門島) - KNH',
  },
  {
    value: 'MZG',
    text: '台湾(馬公) - MZG',
  },
  {
    value: 'TNN',
    text: '台湾(台南) - TNN',
  },
  {
    value: 'TPE',
    text: '台湾(台北) - TPE',
  },
  {
    value: 'TTT',
    text: '台湾(台東) - TTT',
  },
  {
    value: 'TXG',
    text: '台湾(台中) - TXG',
  },
  {
    value: 'BHK',
    text: 'ウズベキスタン(ブハラ) - BHK',
  },
  {
    value: 'FEG',
    text: 'ウズベキスタン(フェルガナ) - FEG',
  },
  {
    value: 'SKD',
    text: 'ウズベキスタン(サマルカンド) - SKD',
  },
  {
    value: 'TAS',
    text: 'ウズベキスタン(タシケント) - TAS',
  },
  {
    value: 'UGC',
    text: 'ウズベキスタン(ウルゲンチ) - UGC',
  },
  {
    value: 'BMV',
    text: 'ベトナム(バンメトート) - BMV',
  },
  {
    value: 'CAH',
    text: 'ベトナム(カマウ) - CAH',
  },
  {
    value: 'DAD',
    text: 'ベトナム(ダナン) - DAD',
  },
  {
    value: 'DIN',
    text: 'ベトナム(ディエンビエンフー) - DIN',
  },
  {
    value: 'DLI',
    text: 'ベトナム(ダラッド) - DLI',
  },
  {
    value: 'HAN',
    text: 'ベトナム(ハノイ) - HAN',
  },
  {
    value: 'HLO',
    text: 'ベトナム(ハロン) - HLO',
  },
  {
    value: 'HPH',
    text: 'ベトナム(ハイフォン) - HPH',
  },
  {
    value: 'HUI',
    text: 'ベトナム(フェ) - HUI',
  },
  {
    value: 'NHA',
    text: 'ベトナム(ニャチャン) - NHA',
  },
  {
    value: 'PQC',
    text: 'ベトナム(フーコック) - PQC',
  },
  {
    value: 'PXU',
    text: 'ベトナム(プレイク) - PXU',
  },
  {
    value: 'QAN',
    text: 'ベトナム(ホイアン) - QAN',
  },
  {
    value: 'SGN',
    text: 'ベトナム(ホーチミン) - SGN',
  },
  {
    value: 'SQH',
    text: 'ベトナム(ソンラ) - SQH',
  },
  {
    value: 'TBB',
    text: 'ベトナム(トゥイホア) - TBB',
  },
  {
    value: 'THD',
    text: 'ベトナム(タインホア) - THD',
  },
  {
    value: 'UIH',
    text: 'ベトナム(クイニョン) - UIH',
  },
  {
    value: 'VCA',
    text: 'ベトナム(カントー) - VCA',
  },
  {
    value: 'VCL',
    text: 'ベトナム(チューレイ) - VCL',
  },
  {
    value: 'VCS',
    text: 'ベトナム(コンダオ島) - VCS',
  },
  {
    value: 'VDH',
    text: 'ベトナム(ドンホイ) - VDH',
  },
  {
    value: 'VII',
    text: 'ベトナム(ヴィン) - VII',
  },
  {
    value: 'VKG',
    text: 'ベトナム(ラックザー) - VKG',
  },
  {
    value: 'VN1',
    text: 'ベトナム(ハティン) - VN1',
  },
  {
    value: 'VN2',
    text: 'ベトナム(クアロー) - VN2',
  },
  {
    value: 'VN3',
    text: 'ベトナム(ブンタウ) - VN3',
  },
  {
    value: 'TIA',
    text: 'アルバニア(ティラナ) - TIA',
  },
  {
    value: 'EVN',
    text: 'アルメニア(エレバン) - EVN',
  },
  {
    value: 'GRZ',
    text: 'オーストリア(グラーツ) - GRZ',
  },
  {
    value: 'INN',
    text: 'オーストリア(インスブルック) - INN',
  },
  {
    value: 'KLU',
    text: 'オーストリア(クラーゲンフルト) - KLU',
  },
  {
    value: 'LNZ',
    text: 'オーストリア(リンツ) - LNZ',
  },
  {
    value: 'SZG',
    text: 'オーストリア(ザルツブルグ) - SZG',
  },
  {
    value: 'VIE',
    text: 'オーストリア(ウィーン) - VIE',
  },
  {
    value: 'SJJ',
    text: 'ボスニア・ヘルツェゴビナ(サラエボ) - SJJ',
  },
  {
    value: 'ANR',
    text: 'ベルギー(アントワープ) - ANR',
  },
  {
    value: 'BRU',
    text: 'ベルギー(ブリュッセル) - BRU',
  },
  {
    value: 'BOJ',
    text: 'ブルガリア(ブルガス) - BOJ',
  },
  {
    value: 'SOF',
    text: 'ブルガリア(ソフィア) - SOF',
  },
  {
    value: 'VAR',
    text: 'ブルガリア(バルナ) - VAR',
  },
  {
    value: 'MSQ',
    text: 'ベラルーシ(ミンスク) - MSQ',
  },
  {
    value: 'ACH',
    text: 'スイス(アルテンハイム) - ACH',
  },
  {
    value: 'BRN',
    text: 'スイス(ベルン) - BRN',
  },
  {
    value: 'BSL',
    text: 'スイス(バーゼル) - BSL',
  },
  {
    value: 'GVA',
    text: 'スイス(ジュネーブ) - GVA',
  },
  {
    value: 'LUG',
    text: 'スイス(ルガーノ) - LUG',
  },
  {
    value: 'ZRH',
    text: 'スイス(チューリッヒ) - ZRH',
  },
  {
    value: 'ECN',
    text: 'キプロス(エルカン) - ECN',
  },
  {
    value: 'LCA',
    text: 'キプロス(ラルナカ) - LCA',
  },
  {
    value: 'PFO',
    text: 'キプロス(パフォス) - PFO',
  },
  {
    value: 'BRQ',
    text: 'チェコ(ブルノ) - BRQ',
  },
  {
    value: 'KLV',
    text: 'チェコ(カルロヴィ・ヴァリ) - KLV',
  },
  {
    value: 'OSR',
    text: 'チェコ(オストラバ) - OSR',
  },
  {
    value: 'PRG',
    text: 'チェコ(プラハ) - PRG',
  },
  {
    value: 'AGB',
    text: 'ドイツ(アウグスブルク) - AGB',
  },
  {
    value: 'BER',
    text: 'ドイツ(ベルリン) - BER',
  },
  {
    value: 'BRE',
    text: 'ドイツ(ブレーメン) - BRE',
  },
  {
    value: 'BYU',
    text: 'ドイツ(バイロイト) - BYU',
  },
  {
    value: 'CGN',
    text: 'ドイツ(ケルン) - CGN',
  },
  {
    value: 'DRS',
    text: 'ドイツ(ドレスデン) - DRS',
  },
  {
    value: 'DTM',
    text: 'ドイツ(ドルトムント) - DTM',
  },
  {
    value: 'DUS',
    text: 'ドイツ(デュッセルドルフ) - DUS',
  },
  {
    value: 'ERF',
    text: 'ドイツ(エルフルト) - ERF',
  },
  {
    value: 'ESS',
    text: 'ドイツ(エッセン) - ESS',
  },
  {
    value: 'FDH',
    text: 'ドイツ(フリードリッヒハーフェン) - FDH',
  },
  {
    value: 'FMO',
    text: 'ドイツ(ミュンスター) - FMO',
  },
  {
    value: 'FRA',
    text: 'ドイツ(フランクフルト) - FRA',
  },
  {
    value: 'GWT',
    text: 'ドイツ(ウェスターランド) - GWT',
  },
  {
    value: 'HAJ',
    text: 'ドイツ(ハノーバー) - HAJ',
  },
  {
    value: 'HAM',
    text: 'ドイツ(ハンブルグ) - HAM',
  },
  {
    value: 'HDB',
    text: 'ドイツ(ハイデルベルグ) - HDB',
  },
  {
    value: 'HOQ',
    text: 'ドイツ(ホフ) - HOQ',
  },
  {
    value: 'KEL',
    text: 'ドイツ(キール) - KEL',
  },
  {
    value: 'LEJ',
    text: 'ドイツ(ライプチヒ) - LEJ',
  },
  {
    value: 'MHG',
    text: 'ドイツ(マンハイム) - MHG',
  },
  {
    value: 'MUC',
    text: 'ドイツ(ミュンヘン) - MUC',
  },
  {
    value: 'NUE',
    text: 'ドイツ(ニュルンベルグ) - NUE',
  },
  {
    value: 'PAD',
    text: 'ドイツ(パーデンボルン) - PAD',
  },
  {
    value: 'RLG',
    text: 'ドイツ(ロストック) - RLG',
  },
  {
    value: 'SCN',
    text: 'ドイツ(ザールブリュッケン) - SCN',
  },
  {
    value: 'STR',
    text: 'ドイツ(シュトゥットガルト) - STR',
  },
  {
    value: 'AAL',
    text: 'デンマーク(オールボーグ) - AAL',
  },
  {
    value: 'AAR',
    text: 'デンマーク(オールフス) - AAR',
  },
  {
    value: 'BLL',
    text: 'デンマーク(ビルン) - BLL',
  },
  {
    value: 'CPH',
    text: 'デンマーク(コペンハーゲン) - CPH',
  },
  {
    value: 'EBJ',
    text: 'デンマーク(エスビャー) - EBJ',
  },
  {
    value: 'KRP',
    text: 'デンマーク(カラップ) - KRP',
  },
  {
    value: 'ODE',
    text: 'デンマーク(オデンセ) - ODE',
  },
  {
    value: 'RNN',
    text: 'デンマーク(ボーンホルム) - RNN',
  },
  {
    value: 'SGD',
    text: 'デンマーク(ソンダーボルグ) - SGD',
  },
  {
    value: 'SKS',
    text: 'デンマーク(ボジェン) - SKS',
  },
  {
    value: 'TAY',
    text: 'エストニア(タルト) - TAY',
  },
  {
    value: 'TLL',
    text: 'エストニア(タリン) - TLL',
  },
  {
    value: 'ACE',
    text: 'スペイン(ランサローテ) - ACE',
  },
  {
    value: 'AGP',
    text: 'スペイン(マラガ) - AGP',
  },
  {
    value: 'ALC',
    text: 'スペイン(アリカンテ) - ALC',
  },
  {
    value: 'BCN',
    text: 'スペイン(バルセロナ) - BCN',
  },
  {
    value: 'BIO',
    text: 'スペイン(ビルバオ) - BIO',
  },
  {
    value: 'BJZ',
    text: 'スペイン(バダホス) - BJZ',
  },
  {
    value: 'EAS',
    text: 'スペイン(サンセバスチアン) - EAS',
  },
  {
    value: 'FUE',
    text: 'スペイン(フェルテベントゥーラ) - FUE',
  },
  {
    value: 'GRX',
    text: 'スペイン(グラナダ) - GRX',
  },
  {
    value: 'IBZ',
    text: 'スペイン(イビーサ) - IBZ',
  },
  {
    value: 'LCG',
    text: 'スペイン(ラコルーニャ) - LCG',
  },
  {
    value: 'LEI',
    text: 'スペイン(アルメリア) - LEI',
  },
  {
    value: 'LPA',
    text: 'スペイン(グランカナリア島) - LPA',
  },
  {
    value: 'MAD',
    text: 'スペイン(マドリッド) - MAD',
  },
  {
    value: 'MAH',
    text: 'スペイン(メノルカ) - MAH',
  },
  {
    value: 'MJV',
    text: 'スペイン(ムルシア) - MJV',
  },
  {
    value: 'OVD',
    text: 'スペイン(アストゥリアス) - OVD',
  },
  {
    value: 'PMI',
    text: 'スペイン(パルマ　デ　マヨルカ) - PMI',
  },
  {
    value: 'PNA',
    text: 'スペイン(パンプロ－ナ) - PNA',
  },
  {
    value: 'SCQ',
    text: 'スペイン(サンティアゴデコンポステラ) - SCQ',
  },
  {
    value: 'SDR',
    text: 'スペイン(サンタンデル) - SDR',
  },
  {
    value: 'SVQ',
    text: 'スペイン(セビリア) - SVQ',
  },
  {
    value: 'TCI',
    text: 'スペイン(テネリフェ) - TCI',
  },
  {
    value: 'VGO',
    text: 'スペイン(ビィーゴ) - VGO',
  },
  {
    value: 'VLC',
    text: 'スペイン(バレンシア) - VLC',
  },
  {
    value: 'VLL',
    text: 'スペイン(バリャドリー) - VLL',
  },
  {
    value: 'XRY',
    text: 'スペイン(ヘレスフロンテラ) - XRY',
  },
  {
    value: 'ZAZ',
    text: 'スペイン(サラゴーサ) - ZAZ',
  },
  {
    value: 'ENF',
    text: 'フィンランド(エノンテキオ) - ENF',
  },
  {
    value: 'HEL',
    text: 'フィンランド(ヘルシンキ) - HEL',
  },
  {
    value: 'IVL',
    text: 'フィンランド(イヴァロ) - IVL',
  },
  {
    value: 'JOE',
    text: 'フィンランド(ヨエンスー) - JOE',
  },
  {
    value: 'JYV',
    text: 'フィンランド(ユヴァスキュラ) - JYV',
  },
  {
    value: 'KAJ',
    text: 'フィンランド(カヤーニ) - KAJ',
  },
  {
    value: 'KAO',
    text: 'フィンランド(クーサモ) - KAO',
  },
  {
    value: 'KEM',
    text: 'フィンランド(ケミ) - KEM',
  },
  {
    value: 'KOK',
    text: 'フィンランド(コッコラ) - KOK',
  },
  {
    value: 'KTT',
    text: 'フィンランド(キッティラ) - KTT',
  },
  {
    value: 'KUO',
    text: 'フィンランド(クオピオ) - KUO',
  },
  {
    value: 'LPP',
    text: 'フィンランド(ラッペーンランタ) - LPP',
  },
  {
    value: 'MHQ',
    text: 'フィンランド(マリエハム) - MHQ',
  },
  {
    value: 'OUL',
    text: 'フィンランド(オウル) - OUL',
  },
  {
    value: 'POR',
    text: 'フィンランド(ポリ) - POR',
  },
  {
    value: 'RVN',
    text: 'フィンランド(ロヴァニエミ) - RVN',
  },
  {
    value: 'SJY',
    text: 'フィンランド(セイナヨキ) - SJY',
  },
  {
    value: 'SVL',
    text: 'フィンランド(サヴォンリンナ) - SVL',
  },
  {
    value: 'TKU',
    text: 'フィンランド(トゥルク) - TKU',
  },
  {
    value: 'TMP',
    text: 'フィンランド(タンペレ) - TMP',
  },
  {
    value: 'VAA',
    text: 'フィンランド(ヴァーサ) - VAA',
  },
  {
    value: 'VRK',
    text: 'フィンランド(ヴァルカウス) - VRK',
  },
  {
    value: 'AJA',
    text: 'フランス(アジャクシオ) - AJA',
  },
  {
    value: 'ANE',
    text: 'フランス(アンジェー) - ANE',
  },
  {
    value: 'AVN',
    text: 'フランス(アビニョン) - AVN',
  },
  {
    value: 'BES',
    text: 'フランス(ブレスト) - BES',
  },
  {
    value: 'BIA',
    text: 'フランス(バスティア) - BIA',
  },
  {
    value: 'BIQ',
    text: 'フランス(ビアリーツ) - BIQ',
  },
  {
    value: 'BOD',
    text: 'フランス(ボルドー) - BOD',
  },
  {
    value: 'CEQ',
    text: 'フランス(カンヌ) - CEQ',
  },
  {
    value: 'CFE',
    text: 'フランス(クリアモントフェランド) - CFE',
  },
  {
    value: 'CLY',
    text: 'フランス(カルヴィ) - CLY',
  },
  {
    value: 'CMF',
    text: 'フランス(シャンベリー) - CMF',
  },
  {
    value: 'EGC',
    text: 'フランス(ベルジュラック) - EGC',
  },
  {
    value: 'EPL',
    text: 'フランス(エピナル) - EPL',
  },
  {
    value: 'ETZ',
    text: 'フランス(メッツナンシー) - ETZ',
  },
  {
    value: 'FNI',
    text: 'フランス(ニーム) - FNI',
  },
  {
    value: 'FSC',
    text: 'フランス(フィガリ) - FSC',
  },
  {
    value: 'GNB',
    text: 'フランス(グルノーブル) - GNB',
  },
  {
    value: 'LAI',
    text: 'フランス(ラニオン) - LAI',
  },
  {
    value: 'LDE',
    text: 'フランス(ルルド) - LDE',
  },
  {
    value: 'LEH',
    text: 'フランス(ルアーブル) - LEH',
  },
  {
    value: 'LIG',
    text: 'フランス(リモージュ) - LIG',
  },
  {
    value: 'LIL',
    text: 'フランス(リール) - LIL',
  },
  {
    value: 'LME',
    text: 'フランス(ルマン) - LME',
  },
  {
    value: 'LRT',
    text: 'フランス(ロリヤン) - LRT',
  },
  {
    value: 'LYS',
    text: 'フランス(リヨン) - LYS',
  },
  {
    value: 'MLH',
    text: 'フランス(ミュールーズ) - MLH',
  },
  {
    value: 'MPL',
    text: 'フランス(モンペリエ) - MPL',
  },
  {
    value: 'MRS',
    text: 'フランス(マルセイユ) - MRS',
  },
  {
    value: 'MS1',
    text: 'フランス(モンサンミシェル) - MS1',
  },
  {
    value: 'MZM',
    text: 'フランス(メス) - MZM',
  },
  {
    value: 'NCE',
    text: 'フランス(ニース) - NCE',
  },
  {
    value: 'NCY',
    text: 'フランス(アヌシー) - NCY',
  },
  {
    value: 'NTE',
    text: 'フランス(ナント) - NTE',
  },
  {
    value: 'PAR',
    text: 'フランス(パリ) - PAR',
  },
  {
    value: 'PGF',
    text: 'フランス(ペルピニャン) - PGF',
  },
  {
    value: 'PIS',
    text: 'フランス(ポアティエ) - PIS',
  },
  {
    value: 'PUF',
    text: 'フランス(ポー) - PUF',
  },
  {
    value: 'RDZ',
    text: 'フランス(ロデズ) - RDZ',
  },
  {
    value: 'RNS',
    text: 'フランス(レンヌ) - RNS',
  },
  {
    value: 'SXB',
    text: 'フランス(ストラスブール) - SXB',
  },
  {
    value: 'TLN',
    text: 'フランス(トゥーロン) - TLN',
  },
  {
    value: 'TLS',
    text: 'フランス(トゥールーズ) - TLS',
  },
  {
    value: 'TUF',
    text: 'フランス(ツール) - TUF',
  },
  {
    value: 'UIP',
    text: 'フランス(カンペール) - UIP',
  },
  {
    value: 'ABZ',
    text: 'イギリス(アバディーン) - ABZ',
  },
  {
    value: 'BFS',
    text: 'イギリス(ベルファースト) - BFS',
  },
  {
    value: 'BHX',
    text: 'イギリス(バーミンガム) - BHX',
  },
  {
    value: 'BRS',
    text: 'イギリス(ブリストル) - BRS',
  },
  {
    value: 'CAX',
    text: 'イギリス(カーライル) - CAX',
  },
  {
    value: 'CWL',
    text: 'イギリス(カ－ディフ) - CWL',
  },
  {
    value: 'EDI',
    text: 'イギリス(エジンバラ) - EDI',
  },
  {
    value: 'EMA',
    text: 'イギリス(イーストミッドランド) - EMA',
  },
  {
    value: 'GCI',
    text: 'イギリス(ガーンジィー) - GCI',
  },
  {
    value: 'GLA',
    text: 'イギリス(グラスゴー) - GLA',
  },
  {
    value: 'HUY',
    text: 'イギリス(ハンバーサイド) - HUY',
  },
  {
    value: 'ILY',
    text: 'イギリス(アイラ) - ILY',
  },
  {
    value: 'INV',
    text: 'イギリス(インバネス) - INV',
  },
  {
    value: 'IOM',
    text: 'イギリス(マン島) - IOM',
  },
  {
    value: 'JER',
    text: 'イギリス(ジャージー) - JER',
  },
  {
    value: 'KOI',
    text: 'イギリス(カークウォール) - KOI',
  },
  {
    value: 'LBA',
    text: 'イギリス(リーズ) - LBA',
  },
  {
    value: 'LON',
    text: 'イギリス(ロンドン) - LON',
  },
  {
    value: 'LPL',
    text: 'イギリス(リバプール) - LPL',
  },
  {
    value: 'MAN',
    text: 'イギリス(マンチェスター) - MAN',
  },
  {
    value: 'MME',
    text: 'イギリス(ダラム) - MME',
  },
  {
    value: 'NCL',
    text: 'イギリス(ニューキャッスル) - NCL',
  },
  {
    value: 'NQY',
    text: 'イギリス(ニューキー) - NQY',
  },
  {
    value: 'NWI',
    text: 'イギリス(ノーウィッチ) - NWI',
  },
  {
    value: 'PLH',
    text: 'イギリス(プリマス) - PLH',
  },
  {
    value: 'SDZ',
    text: 'イギリス(シェットランド) - SDZ',
  },
  {
    value: 'SOU',
    text: 'イギリス(サウサンプトン) - SOU',
  },
  {
    value: 'TBS',
    text: 'グルジア(トビリシ) - TBS',
  },
  {
    value: 'GIB',
    text: 'ジブラルタル(ジブラルタル) - GIB',
  },
  {
    value: 'SFJ',
    text: 'グリーンランド(カンゲルルススアーク) - SFJ',
  },
  {
    value: 'ATH',
    text: 'ギリシャ(アテネ) - ATH',
  },
  {
    value: 'CFU',
    text: 'ギリシャ(コルフ) - CFU',
  },
  {
    value: 'CHQ',
    text: 'ギリシャ(ハニア) - CHQ',
  },
  {
    value: 'EFL',
    text: 'ギリシャ(ケファロニア島) - EFL',
  },
  {
    value: 'HER',
    text: 'ギリシャ(ヘラクリオン) - HER',
  },
  {
    value: 'JMK',
    text: 'ギリシャ(ミコノス) - JMK',
  },
  {
    value: 'JTR',
    text: 'ギリシャ(ティラ（サントリーニ）) - JTR',
  },
  {
    value: 'JTY',
    text: 'ギリシャ(アスティパレア島) - JTY',
  },
  {
    value: 'KGS',
    text: 'ギリシャ(コス) - KGS',
  },
  {
    value: 'RHO',
    text: 'ギリシャ(ロードス) - RHO',
  },
  {
    value: 'SKG',
    text: 'ギリシャ(テッサロニーキ) - SKG',
  },
  {
    value: 'SMI',
    text: 'ギリシャ(サモス) - SMI',
  },
  {
    value: 'DBV',
    text: 'クロアチア(ドゥブロブニク) - DBV',
  },
  {
    value: 'SPU',
    text: 'クロアチア(スプリト) - SPU',
  },
  {
    value: 'ZAD',
    text: 'クロアチア(ザダール) - ZAD',
  },
  {
    value: 'ZAG',
    text: 'クロアチア(ザグレブ) - ZAG',
  },
  {
    value: 'BUD',
    text: 'ハンガリー(ブダペスト) - BUD',
  },
  {
    value: 'DUB',
    text: 'アイルランド(ダブリン) - DUB',
  },
  {
    value: 'GWY',
    text: 'アイルランド(ゴールウェイ) - GWY',
  },
  {
    value: 'KIR',
    text: 'アイルランド(ケリーカントリー) - KIR',
  },
  {
    value: 'ORK',
    text: 'アイルランド(コーク) - ORK',
  },
  {
    value: 'SNN',
    text: 'アイルランド(シャノン) - SNN',
  },
  {
    value: 'SXL',
    text: 'アイルランド(スライゴー) - SXL',
  },
  {
    value: 'REK',
    text: 'アイスランド(レイキャビック) - REK',
  },
  {
    value: 'AHO',
    text: 'イタリア(アルゲーロ) - AHO',
  },
  {
    value: 'AMF',
    text: 'イタリア(アマルフィ) - AMF',
  },
  {
    value: 'AOI',
    text: 'イタリア(アンコナ) - AOI',
  },
  {
    value: 'BDS',
    text: 'イタリア(ブリンディジ) - BDS',
  },
  {
    value: 'BLQ',
    text: 'イタリア(ボローニャ) - BLQ',
  },
  {
    value: 'BRI',
    text: 'イタリア(バリ) - BRI',
  },
  {
    value: 'BZO',
    text: 'イタリア(ボルツァーノ) - BZO',
  },
  {
    value: 'CAG',
    text: 'イタリア(カルアーリ) - CAG',
  },
  {
    value: 'CTA',
    text: 'イタリア(カタニア) - CTA',
  },
  {
    value: 'CUF',
    text: 'イタリア(クーネオ) - CUF',
  },
  {
    value: 'FLR',
    text: 'イタリア(フィレンツェ) - FLR',
  },
  {
    value: 'GOA',
    text: 'イタリア(ジェノア) - GOA',
  },
  {
    value: 'LMP',
    text: 'イタリア(ランペドゥーザ島) - LMP',
  },
  {
    value: 'MIL',
    text: 'イタリア(ミラノ) - MIL',
  },
  {
    value: 'NAP',
    text: 'イタリア(ナポリ) - NAP',
  },
  {
    value: 'OLB',
    text: 'イタリア(オルビア) - OLB',
  },
  {
    value: 'PEG',
    text: 'イタリア(ペルージャ) - PEG',
  },
  {
    value: 'PMF',
    text: 'イタリア(パルマ) - PMF',
  },
  {
    value: 'PMO',
    text: 'イタリア(パレルモ) - PMO',
  },
  {
    value: 'PNL',
    text: 'イタリア(パンテッレリーア) - PNL',
  },
  {
    value: 'PSA',
    text: 'イタリア(ピサ) - PSA',
  },
  {
    value: 'PSR',
    text: 'イタリア(ペスカラ) - PSR',
  },
  {
    value: 'REG',
    text: 'イタリア(レッジョカラブリア) - REG',
  },
  {
    value: 'RMI',
    text: 'イタリア(リミニ) - RMI',
  },
  {
    value: 'ROM',
    text: 'イタリア(ローマ) - ROM',
  },
  {
    value: 'SUF',
    text: 'イタリア(ラメーツィアテルメ) - SUF',
  },
  {
    value: 'TPS',
    text: 'イタリア(トラパニ) - TPS',
  },
  {
    value: 'TRN',
    text: 'イタリア(トリノ) - TRN',
  },
  {
    value: 'TRS',
    text: 'イタリア(トリエステ) - TRS',
  },
  {
    value: 'VCE',
    text: 'イタリア(ベニス（ベネチア）) - VCE',
  },
  {
    value: 'VRN',
    text: 'イタリア(ベローナ) - VRN',
  },
  {
    value: 'PLQ',
    text: 'リトアニア(クライペダ) - PLQ',
  },
  {
    value: 'VNO',
    text: 'リトアニア(ビリニュス) - VNO',
  },
  {
    value: 'LUX',
    text: 'ルクセンブルク(ルクセンブルグ) - LUX',
  },
  {
    value: 'RIX',
    text: 'ラトビア(リガ) - RIX',
  },
  {
    value: 'KIV',
    text: 'モルドバ(キシニョフ) - KIV',
  },
  {
    value: 'TGD',
    text: 'モンテネグロ(ポトゴリカ) - TGD',
  },
  {
    value: 'TIV',
    text: 'モンテネグロ(ティヴァト) - TIV',
  },
  {
    value: 'SKP',
    text: 'マケドニア共和国(スコピエ) - SKP',
  },
  {
    value: 'MLA',
    text: 'マルタ(マルタ) - MLA',
  },
  {
    value: 'AMS',
    text: 'オランダ(アムステルダム) - AMS',
  },
  {
    value: 'CUR',
    text: 'オランダ(キュラソー島) - CUR',
  },
  {
    value: 'EIN',
    text: 'オランダ(アイントホーベン) - EIN',
  },
  {
    value: 'GRQ',
    text: 'オランダ(フロ－ニンゲン) - GRQ',
  },
  {
    value: 'LID',
    text: 'オランダ(ライデン) - LID',
  },
  {
    value: 'MST',
    text: 'オランダ(マーストリヒト) - MST',
  },
  {
    value: 'RTM',
    text: 'オランダ(ロッテルダム) - RTM',
  },
  {
    value: 'AES',
    text: 'ノルウェー(オーレスン) - AES',
  },
  {
    value: 'ALF',
    text: 'ノルウェー(アルタ) - ALF',
  },
  {
    value: 'BGO',
    text: 'ノルウェー(ベルゲン) - BGO',
  },
  {
    value: 'BOO',
    text: 'ノルウェー(ボデ) - BOO',
  },
  {
    value: 'EVE',
    text: 'ノルウェー(ハーシュタ・ナルヴィク) - EVE',
  },
  {
    value: 'HAU',
    text: 'ノルウェー(ハウゲスン) - HAU',
  },
  {
    value: 'KKN',
    text: 'ノルウェー(キルケネス) - KKN',
  },
  {
    value: 'KRS',
    text: 'ノルウェー(クリスチャンサン) - KRS',
  },
  {
    value: 'KSU',
    text: 'ノルウェー(クリティアンスン) - KSU',
  },
  {
    value: 'MOL',
    text: 'ノルウェー(モルデ) - MOL',
  },
  {
    value: 'OSL',
    text: 'ノルウェー(オスロ) - OSL',
  },
  {
    value: 'SVG',
    text: 'ノルウェー(スタヴァンゲル) - SVG',
  },
  {
    value: 'TOS',
    text: 'ノルウェー(トロムソ) - TOS',
  },
  {
    value: 'TRD',
    text: 'ノルウェー(トロンヘイム) - TRD',
  },
  {
    value: 'GDN',
    text: 'ポーランド(クダンスク) - GDN',
  },
  {
    value: 'KRK',
    text: 'ポーランド(クラクフ) - KRK',
  },
  {
    value: 'KTW',
    text: 'ポーランド(カトヴィーツエ) - KTW',
  },
  {
    value: 'POZ',
    text: 'ポーランド(ポツナム) - POZ',
  },
  {
    value: 'RZE',
    text: 'ポーランド(ジェンシュフ) - RZE',
  },
  {
    value: 'SZZ',
    text: 'ポーランド(シュチェチン) - SZZ',
  },
  {
    value: 'WAW',
    text: 'ポーランド(ワルシャワ) - WAW',
  },
  {
    value: 'WRO',
    text: 'ポーランド(ヴラツラフ) - WRO',
  },
  {
    value: 'FAO',
    text: 'ポルトガル(ファロ) - FAO',
  },
  {
    value: 'FNC',
    text: 'ポルトガル(フンシャール) - FNC',
  },
  {
    value: 'LIS',
    text: 'ポルトガル(リスボン) - LIS',
  },
  {
    value: 'OPO',
    text: 'ポルトガル(ポルト) - OPO',
  },
  {
    value: 'BAY',
    text: 'ルーマニア(バヤ・マーレ) - BAY',
  },
  {
    value: 'BUH',
    text: 'ルーマニア(ブカレスト) - BUH',
  },
  {
    value: 'CLJ',
    text: 'ルーマニア(クルナポカ) - CLJ',
  },
  {
    value: 'IAS',
    text: 'ルーマニア(ヤーシ) - IAS',
  },
  {
    value: 'SBZ',
    text: 'ルーマニア(シビウ) - SBZ',
  },
  {
    value: 'SCV',
    text: 'ルーマニア(スチャバ) - SCV',
  },
  {
    value: 'TSR',
    text: 'ルーマニア(ティミショアラ) - TSR',
  },
  {
    value: 'BEG',
    text: 'セルビア(ベオグラード) - BEG',
  },
  {
    value: 'PRN',
    text: 'セルビア(プリシュチナ) - PRN',
  },
  {
    value: 'ABA',
    text: 'ロシア(アバカン) - ABA',
  },
  {
    value: 'AER',
    text: 'ロシア(ソチ) - AER',
  },
  {
    value: 'ARH',
    text: 'ロシア(アルカンゲルスク) - ARH',
  },
  {
    value: 'CEK',
    text: 'ロシア(チェリャビンスク) - CEK',
  },
  {
    value: 'GOJ',
    text: 'ロシア(ニジニ・ノブゴロド) - GOJ',
  },
  {
    value: 'HMA',
    text: 'ロシア(ハンティ・マンシースク) - HMA',
  },
  {
    value: 'IKT',
    text: 'ロシア(イルクーツク) - IKT',
  },
  {
    value: 'KCK',
    text: 'ロシア(キレンスク) - KCK',
  },
  {
    value: 'KEJ',
    text: 'ロシア(ケメロボ) - KEJ',
  },
  {
    value: 'KHV',
    text: 'ロシア(ハバロフスク) - KHV',
  },
  {
    value: 'KJA',
    text: 'ロシア(クラスノヤルスク) - KJA',
  },
  {
    value: 'KRR',
    text: 'ロシア(クラスノダール) - KRR',
  },
  {
    value: 'KUF',
    text: 'ロシア(サマラ) - KUF',
  },
  {
    value: 'KZN',
    text: 'ロシア(カザン) - KZN',
  },
  {
    value: 'LED',
    text: 'ロシア(サンクトペテルスブルグ) - LED',
  },
  {
    value: 'MMK',
    text: 'ロシア(ムーマンスク) - MMK',
  },
  {
    value: 'MOW',
    text: 'ロシア(モスクワ) - MOW',
  },
  {
    value: 'NBC',
    text: 'ロシア(ニジネカムスク) - NBC',
  },
  {
    value: 'OVB',
    text: 'ロシア(ノボシビルスク) - OVB',
  },
  {
    value: 'PEE',
    text: 'ロシア(ペルミ) - PEE',
  },
  {
    value: 'PKC',
    text: 'ロシア(ペトロパブロフスク) - PKC',
  },
  {
    value: 'ROV',
    text: 'ロシア(ロストフ) - ROV',
  },
  {
    value: 'SVX',
    text: 'ロシア(エカテリンブルク) - SVX',
  },
  {
    value: 'UFA',
    text: 'ロシア(ウファ) - UFA',
  },
  {
    value: 'UUS',
    text: 'ロシア(ユジノ　サハリンスク) - UUS',
  },
  {
    value: 'VVO',
    text: 'ロシア(ウラジオストック) - VVO',
  },
  {
    value: 'BLE',
    text: 'スウェーデン(ブルレンイエ) - BLE',
  },
  {
    value: 'GOT',
    text: 'スウェーデン(ヨーテボリ) - GOT',
  },
  {
    value: 'HAD',
    text: 'スウェーデン(ハルムスタード) - HAD',
  },
  {
    value: 'JKG',
    text: 'スウェーデン(インチェピング) - JKG',
  },
  {
    value: 'KLR',
    text: 'スウェーデン(カルマー) - KLR',
  },
  {
    value: 'KRF',
    text: 'スウェーデン(クラムフォシュ) - KRF',
  },
  {
    value: 'KRN',
    text: 'スウェーデン(キルナ) - KRN',
  },
  {
    value: 'KSD',
    text: 'スウェーデン(カールスタッド) - KSD',
  },
  {
    value: 'LLA',
    text: 'スウェーデン(ルレア) - LLA',
  },
  {
    value: 'LPI',
    text: 'スウェーデン(リンコピン) - LPI',
  },
  {
    value: 'MMA',
    text: 'スウェーデン(マルメ) - MMA',
  },
  {
    value: 'MXX',
    text: 'スウェーデン(モ－ラ) - MXX',
  },
  {
    value: 'NRK',
    text: 'スウェーデン(ノルチェピング) - NRK',
  },
  {
    value: 'OER',
    text: 'スウェーデン(オルンスコルドスビーク) - OER',
  },
  {
    value: 'ORB',
    text: 'スウェーデン(エレブルー) - ORB',
  },
  {
    value: 'OSD',
    text: 'スウェーデン(エステルスンド) - OSD',
  },
  {
    value: 'RNB',
    text: 'スウェーデン(ロネビー) - RNB',
  },
  {
    value: 'SDL',
    text: 'スウェーデン(スンズヴァル) - SDL',
  },
  {
    value: 'SFT',
    text: 'スウェーデン(シェレフテオ) - SFT',
  },
  {
    value: 'STO',
    text: 'スウェーデン(ストックホルム) - STO',
  },
  {
    value: 'THN',
    text: 'スウェーデン(トロールハッタン) - THN',
  },
  {
    value: 'UME',
    text: 'スウェーデン(ウメア) - UME',
  },
  {
    value: 'VBY',
    text: 'スウェーデン(ビスビー) - VBY',
  },
  {
    value: 'VST',
    text: 'スウェーデン(ベステロス) - VST',
  },
  {
    value: 'VXO',
    text: 'スウェーデン(ベクショー) - VXO',
  },
  {
    value: 'LJU',
    text: 'スロベニア(リュブリャーナ) - LJU',
  },
  {
    value: 'MBX',
    text: 'スロベニア(マルボル) - MBX',
  },
  {
    value: 'BTS',
    text: 'スロバキア(ブラチスラバ) - BTS',
  },
  {
    value: 'KSC',
    text: 'スロバキア(コシツェ) - KSC',
  },
  {
    value: 'DNK',
    text: 'ウクライナ(ドニエペトロフスク) - DNK',
  },
  {
    value: 'DOK',
    text: 'ウクライナ(ドネツク) - DOK',
  },
  {
    value: 'HRK',
    text: 'ウクライナ(ハリコフ) - HRK',
  },
  {
    value: 'IEV',
    text: 'ウクライナ(キエフ) - IEV',
  },
  {
    value: 'LWO',
    text: 'ウクライナ(リヴィウ) - LWO',
  },
  {
    value: 'ODS',
    text: 'ウクライナ(オデッサ) - ODS',
  },
  {
    value: 'SIP',
    text: 'ウクライナ(シンフェローポリ) - SIP',
  },
  {
    value: 'HNL',
    text: 'ハワイ(ホノルル(オアフ島）) - HNL',
  },
  {
    value: 'HNM',
    text: 'ハワイ(ハナ（マウイ島）) - HNM',
  },
  {
    value: 'ITO',
    text: 'ハワイ(ヒロ（ハワイ島）) - ITO',
  },
  {
    value: 'JHM',
    text: 'ハワイ(カパルア（マウイ島）) - JHM',
  },
  {
    value: 'KOA',
    text: 'ハワイ(コナ（ハワイ島）) - KOA',
  },
  {
    value: 'LIH',
    text: 'ハワイ(リフェ（カウアイ島）) - LIH',
  },
  {
    value: 'LNY',
    text: 'ハワイ(ラナイ（ラナイ島）) - LNY',
  },
  {
    value: 'MKK',
    text: 'ハワイ(モロカイ島) - MKK',
  },
  {
    value: 'OGG',
    text: 'ハワイ(カフルイ（マウイ島）) - OGG',
  },
  {
    value: 'KSA',
    text: 'ミクロネシア連邦(コスラエ島) - KSA',
  },
  {
    value: 'PNI',
    text: 'ミクロネシア連邦(ポナペ) - PNI',
  },
  {
    value: 'TKK',
    text: 'ミクロネシア連邦(トラック) - TKK',
  },
  {
    value: 'YAP',
    text: 'ミクロネシア連邦(ヤップ) - YAP',
  },
  {
    value: 'GUM',
    text: 'グアム(グアム) - GUM',
  },
  {
    value: 'KWA',
    text: 'マーシャル諸島(クェゼリン) - KWA',
  },
  {
    value: 'MAJ',
    text: 'マーシャル諸島(マジュロ) - MAJ',
  },
  {
    value: 'ROP',
    text: '北マリアナ諸島（サイパン）(ロタ) - ROP',
  },
  {
    value: 'SPN',
    text: '北マリアナ諸島（サイパン）(サイパン) - SPN',
  },
  {
    value: 'ROR',
    text: 'パラオ(コロール) - ROR',
  },
  {
    value: 'PPG',
    text: 'アメリカ領サモア(パゴパゴ) - PPG',
  },
  {
    value: 'ABM',
    text: 'オーストラリア(バマガ) - ABM',
  },
  {
    value: 'ABX',
    text: 'オーストラリア(オールベリ) - ABX',
  },
  {
    value: 'ADL',
    text: 'オーストラリア(アデレード) - ADL',
  },
  {
    value: 'ALH',
    text: 'オーストラリア(アルバニイ) - ALH',
  },
  {
    value: 'ARM',
    text: 'オーストラリア(ア－ミデ－ル) - ARM',
  },
  {
    value: 'ASP',
    text: 'オーストラリア(アリススプリングス) - ASP',
  },
  {
    value: 'AYQ',
    text: 'オーストラリア(エアーズロック) - AYQ',
  },
  {
    value: 'BDB',
    text: 'オーストラリア(ブンダバーグ) - BDB',
  },
  {
    value: 'BME',
    text: 'オーストラリア(ブルーム) - BME',
  },
  {
    value: 'BNE',
    text: 'オーストラリア(ブリスベーン) - BNE',
  },
  {
    value: 'BNK',
    text: 'オーストラリア(バリナ) - BNK',
  },
  {
    value: 'BWT',
    text: 'オーストラリア(バーニー) - BWT',
  },
  {
    value: 'CAZ',
    text: 'オーストラリア(コバー) - CAZ',
  },
  {
    value: 'CBR',
    text: 'オーストラリア(キャンベラ) - CBR',
  },
  {
    value: 'CDA',
    text: 'オーストラリア(クーインダ) - CDA',
  },
  {
    value: 'CFS',
    text: 'オーストラリア(コフスハーバー) - CFS',
  },
  {
    value: 'CNS',
    text: 'オーストラリア(ケアンズ) - CNS',
  },
  {
    value: 'COJ',
    text: 'オーストラリア(クーナバラブラン) - COJ',
  },
  {
    value: 'DBO',
    text: 'オーストラリア(ドボ) - DBO',
  },
  {
    value: 'DKI',
    text: 'オーストラリア(ダンクアイランド) - DKI',
  },
  {
    value: 'DPO',
    text: 'オーストラリア(デボンポート) - DPO',
  },
  {
    value: 'DRB',
    text: 'オーストラリア(デルビー) - DRB',
  },
  {
    value: 'DRW',
    text: 'オーストラリア(ダーウィン) - DRW',
  },
  {
    value: 'EMD',
    text: 'オーストラリア(エメラルド) - EMD',
  },
  {
    value: 'GET',
    text: 'オーストラリア(ジェラルトン) - GET',
  },
  {
    value: 'GFF',
    text: 'オーストラリア(グリフィス) - GFF',
  },
  {
    value: 'GFN',
    text: 'オーストラリア(グラフトン) - GFN',
  },
  {
    value: 'GLT',
    text: 'オーストラリア(グラッドストーン) - GLT',
  },
  {
    value: 'HAP',
    text: 'オーストラリア(ロングアイランド) - HAP',
  },
  {
    value: 'HBA',
    text: 'オーストラリア(ホバート) - HBA',
  },
  {
    value: 'HIS',
    text: 'オーストラリア(ヘイマン島) - HIS',
  },
  {
    value: 'HTI',
    text: 'オーストラリア(ハミルトン島) - HTI',
  },
  {
    value: 'HVB',
    text: 'オーストラリア(ハービーベイ) - HVB',
  },
  {
    value: 'ISA',
    text: 'オーストラリア(マウントイサ) - ISA',
  },
  {
    value: 'IVR',
    text: 'オーストラリア(インバエル) - IVR',
  },
  {
    value: 'KGC',
    text: 'オーストラリア(キングスコート) - KGC',
  },
  {
    value: 'KGI',
    text: 'オーストラリア(カルグーリー) - KGI',
  },
  {
    value: 'KNX',
    text: 'オーストラリア(クヌヌーラ) - KNX',
  },
  {
    value: 'KTR',
    text: 'オーストラリア(キャサリン) - KTR',
  },
  {
    value: 'LDH',
    text: 'オーストラリア(ロードハウ) - LDH',
  },
  {
    value: 'LEA',
    text: 'オーストラリア(リアマンス) - LEA',
  },
  {
    value: 'LHG',
    text: 'オーストラリア(ライトニングリッジ) - LHG',
  },
  {
    value: 'LST',
    text: 'オーストラリア(ローンセストン) - LST',
  },
  {
    value: 'LSY',
    text: 'オーストラリア(リスモアー) - LSY',
  },
  {
    value: 'LZR',
    text: 'オーストラリア(リザード島) - LZR',
  },
  {
    value: 'MBH',
    text: 'オーストラリア(メリーボロー) - MBH',
  },
  {
    value: 'MCY',
    text: 'オーストラリア(サンシャインコ－スト) - MCY',
  },
  {
    value: 'MEL',
    text: 'オーストラリア(メルボルン) - MEL',
  },
  {
    value: 'MGB',
    text: 'オーストラリア(マウントガンビア) - MGB',
  },
  {
    value: 'MIM',
    text: 'オーストラリア(メリンブラ) - MIM',
  },
  {
    value: 'MJK',
    text: 'オーストラリア(モンキーマイア) - MJK',
  },
  {
    value: 'MKY',
    text: 'オーストラリア(マカイ) - MKY',
  },
  {
    value: 'MQL',
    text: 'オーストラリア(ミルドューラ) - MQL',
  },
  {
    value: 'MRZ',
    text: 'オーストラリア(モリー) - MRZ',
  },
  {
    value: 'MYA',
    text: 'オーストラリア(モルヤ) - MYA',
  },
  {
    value: 'NAA',
    text: 'オーストラリア(ナラブライ) - NAA',
  },
  {
    value: 'NLK',
    text: 'オーストラリア(ノーフォーク) - NLK',
  },
  {
    value: 'NRA',
    text: 'オーストラリア(ナランデラ) - NRA',
  },
  {
    value: 'NTL',
    text: 'オーストラリア(ニューキャッスル) - NTL',
  },
  {
    value: 'OAG',
    text: 'オーストラリア(オレンジ) - OAG',
  },
  {
    value: 'OOL',
    text: 'オーストラリア(ゴールドコースト) - OOL',
  },
  {
    value: 'OOM',
    text: 'オーストラリア(クーマ) - OOM',
  },
  {
    value: 'PER',
    text: 'オーストラリア(パース) - PER',
  },
  {
    value: 'PKE',
    text: 'オーストラリア(パークス) - PKE',
  },
  {
    value: 'PLO',
    text: 'オーストラリア(ポートリンカン) - PLO',
  },
  {
    value: 'PPP',
    text: 'オーストラリア(プローザパイン) - PPP',
  },
  {
    value: 'PQQ',
    text: 'オーストラリア(ポートマクオリー) - PQQ',
  },
  {
    value: 'RMK',
    text: 'オーストラリア(レンマーク) - RMK',
  },
  {
    value: 'ROK',
    text: 'オーストラリア(ロックハンプトン) - ROK',
  },
  {
    value: 'RTS',
    text: 'オーストラリア(ロットネストアイランド) - RTS',
  },
  {
    value: 'SRN',
    text: 'オーストラリア(ストラーアン) - SRN',
  },
  {
    value: 'SYD',
    text: 'オーストラリア(シドニー) - SYD',
  },
  {
    value: 'TGN',
    text: 'オーストラリア(トララルゴン) - TGN',
  },
  {
    value: 'TIS',
    text: 'オーストラリア(サースディアイランド) - TIS',
  },
  {
    value: 'TMW',
    text: 'オーストラリア(タムワース) - TMW',
  },
  {
    value: 'TRO',
    text: 'オーストラリア(タリー) - TRO',
  },
  {
    value: 'TSV',
    text: 'オーストラリア(タウンズビル) - TSV',
  },
  {
    value: 'TWB',
    text: 'オーストラリア(ツーウンバ) - TWB',
  },
  {
    value: 'UEE',
    text: 'オーストラリア(クィーンズタウン) - UEE',
  },
  {
    value: 'WEI',
    text: 'オーストラリア(ウエイパ) - WEI',
  },
  {
    value: 'WGA',
    text: 'オーストラリア(ワガワガ) - WGA',
  },
  {
    value: 'RAR',
    text: 'クック諸島(ラロトンガ) - RAR',
  },
  {
    value: 'NAN',
    text: 'フィジー(ナンディ) - NAN',
  },
  {
    value: 'SUV',
    text: 'フィジー(スバ) - SUV',
  },
  {
    value: 'TVU',
    text: 'フィジー(タベウニ) - TVU',
  },
  {
    value: 'TRW',
    text: 'キリバス(タラワ) - TRW',
  },
  {
    value: 'ILP',
    text: 'ニューカレドニア(イルデバン) - ILP',
  },
  {
    value: 'NOU',
    text: 'ニューカレドニア(ヌーメア) - NOU',
  },
  {
    value: 'INU',
    text: 'ナウル(ナウル) - INU',
  },
  {
    value: 'AKL',
    text: 'ニュージーランド(オークランド) - AKL',
  },
  {
    value: 'BHE',
    text: 'ニュージーランド(ブレーナム) - BHE',
  },
  {
    value: 'CHC',
    text: 'ニュージーランド(クライストチャーチ) - CHC',
  },
  {
    value: 'DUD',
    text: 'ニュージーランド(ダニーデン) - DUD',
  },
  {
    value: 'GIS',
    text: 'ニュージーランド(ギスボーン) - GIS',
  },
  {
    value: 'HKK',
    text: 'ニュージーランド(ホキティカ) - HKK',
  },
  {
    value: 'HLZ',
    text: 'ニュージーランド(ハミルトン) - HLZ',
  },
  {
    value: 'IVC',
    text: 'ニュージーランド(インバカーギル) - IVC',
  },
  {
    value: 'KAT',
    text: 'ニュージーランド(カイタイア) - KAT',
  },
  {
    value: 'KKE',
    text: 'ニュージーランド(ケリケリ) - KKE',
  },
  {
    value: 'MFN',
    text: 'ニュージーランド(ミルフォードサウンド) - MFN',
  },
  {
    value: 'MON',
    text: 'ニュージーランド(マウントクック) - MON',
  },
  {
    value: 'MRO',
    text: 'ニュージーランド(マスタートン) - MRO',
  },
  {
    value: 'NPE',
    text: 'ニュージーランド(ネ－ピア－) - NPE',
  },
  {
    value: 'NPL',
    text: 'ニュージーランド(ニュープリマス) - NPL',
  },
  {
    value: 'NSN',
    text: 'ニュージーランド(ネルソン) - NSN',
  },
  {
    value: 'OAM',
    text: 'ニュージーランド(オマル) - OAM',
  },
  {
    value: 'PMR',
    text: 'ニュージーランド(パーマストンノース) - PMR',
  },
  {
    value: 'ROT',
    text: 'ニュージーランド(ロトルア) - ROT',
  },
  {
    value: 'TEU',
    text: 'ニュージーランド(テアナウ) - TEU',
  },
  {
    value: 'TIU',
    text: 'ニュージーランド(ティマル) - TIU',
  },
  {
    value: 'TRG',
    text: 'ニュージーランド(タウランガ) - TRG',
  },
  {
    value: 'TUO',
    text: 'ニュージーランド(タウポ) - TUO',
  },
  {
    value: 'WAG',
    text: 'ニュージーランド(ワンガヌイ) - WAG',
  },
  {
    value: 'WHK',
    text: 'ニュージーランド(ワカタネ) - WHK',
  },
  {
    value: 'WKA',
    text: 'ニュージーランド(ワナカ) - WKA',
  },
  {
    value: 'WLG',
    text: 'ニュージーランド(ウェリントン) - WLG',
  },
  {
    value: 'WRE',
    text: 'ニュージーランド(ワンガレイ) - WRE',
  },
  {
    value: 'WSZ',
    text: 'ニュージーランド(ウエストポート) - WSZ',
  },
  {
    value: 'ZQN',
    text: 'ニュージーランド(クィーンズタウン) - ZQN',
  },
  {
    value: 'AXR',
    text: 'フランス領ポリネシア（タヒチ）(アルツア) - AXR',
  },
  {
    value: 'BOB',
    text: 'フランス領ポリネシア（タヒチ）(ボラボラ) - BOB',
  },
  {
    value: 'MAU',
    text: 'フランス領ポリネシア（タヒチ）(マウピチ) - MAU',
  },
  {
    value: 'MOZ',
    text: 'フランス領ポリネシア（タヒチ）(モーレア) - MOZ',
  },
  {
    value: 'PPT',
    text: 'フランス領ポリネシア（タヒチ）(パペーテ) - PPT',
  },
  {
    value: 'RFP',
    text: 'フランス領ポリネシア（タヒチ）(ライアテア) - RFP',
  },
  {
    value: 'RGI',
    text: 'フランス領ポリネシア（タヒチ）(ランギロア) - RGI',
  },
  {
    value: 'XMH',
    text: 'フランス領ポリネシア（タヒチ）(マニヒ) - XMH',
  },
  {
    value: 'GKA',
    text: 'パプアニューギニア(ゴロカ) - GKA',
  },
  {
    value: 'GUR',
    text: 'パプアニューギニア(アロタウ) - GUR',
  },
  {
    value: 'HGU',
    text: 'パプアニューギニア(マウンテンハーゲン) - HGU',
  },
  {
    value: 'HKN',
    text: 'パプアニューギニア(ホスキンス) - HKN',
  },
  {
    value: 'KDR',
    text: 'パプアニューギニア(カンドリアン) - KDR',
  },
  {
    value: 'KVG',
    text: 'パプアニューギニア(カビエン) - KVG',
  },
  {
    value: 'LAE',
    text: 'パプアニューギニア(ラエ) - LAE',
  },
  {
    value: 'MAG',
    text: 'パプアニューギニア(メダン) - MAG',
  },
  {
    value: 'POM',
    text: 'パプアニューギニア(ポートモレスビー) - POM',
  },
  {
    value: 'RAB',
    text: 'パプアニューギニア(ラバウル) - RAB',
  },
  {
    value: 'TIZ',
    text: 'パプアニューギニア(タリ) - TIZ',
  },
  {
    value: 'VAI',
    text: 'パプアニューギニア(バニモ) - VAI',
  },
  {
    value: 'WWK',
    text: 'パプアニューギニア(ウェワク) - WWK',
  },
  {
    value: 'HIR',
    text: 'ソロモン諸島(ホニアラ) - HIR',
  },
  {
    value: 'SCZ',
    text: 'ソロモン諸島(サンタクルーズ) - SCZ',
  },
  {
    value: 'TBU',
    text: 'トンガ(トンガタプ) - TBU',
  },
  {
    value: 'VAV',
    text: 'トンガ(ババウ) - VAV',
  },
  {
    value: 'FUN',
    text: 'ツバル(フナフティアトル) - FUN',
  },
  {
    value: 'VLI',
    text: 'バヌアツ(ポートビラ) - VLI',
  },
  {
    value: 'WLS',
    text: 'ウォリス・フツナ(ワリス諸島) - WLS',
  },
  {
    value: 'APW',
    text: 'サモア(アーピア) - APW',
  },
  {
    value: 'ANU',
    text: 'アンティグア・バーブーダ(アンティグア) - ANU',
  },
  {
    value: 'AXA',
    text: 'アンギラ(アンギラ) - AXA',
  },
  {
    value: 'BON',
    text: 'アンティル列島(ボネール) - BON',
  },
  {
    value: 'BRC',
    text: 'アルゼンチン(サンカルロス) - BRC',
  },
  {
    value: 'BUE',
    text: 'アルゼンチン(ブエノスアイレス) - BUE',
  },
  {
    value: 'COR',
    text: 'アルゼンチン(コルドバ) - COR',
  },
  {
    value: 'FMA',
    text: 'アルゼンチン(フォルモサ) - FMA',
  },
  {
    value: 'FTE',
    text: 'アルゼンチン(エルカラファテ) - FTE',
  },
  {
    value: 'IGR',
    text: 'アルゼンチン(イグアス) - IGR',
  },
  {
    value: 'MDQ',
    text: 'アルゼンチン(マルデルプラタ) - MDQ',
  },
  {
    value: 'MDZ',
    text: 'アルゼンチン(メンドーサ) - MDZ',
  },
  {
    value: 'NQN',
    text: 'アルゼンチン(ネウケン) - NQN',
  },
  {
    value: 'RES',
    text: 'アルゼンチン(レシステンシア) - RES',
  },
  {
    value: 'RGL',
    text: 'アルゼンチン(リオガジェゴス) - RGL',
  },
  {
    value: 'SFN',
    text: 'アルゼンチン(サンタフェ) - SFN',
  },
  {
    value: 'SLA',
    text: 'アルゼンチン(サルタ) - SLA',
  },
  {
    value: 'USH',
    text: 'アルゼンチン(ウシュアイア) - USH',
  },
  {
    value: 'AUA',
    text: 'アルバ(アルバ) - AUA',
  },
  {
    value: 'BGI',
    text: 'バルバドス(バルバドス) - BGI',
  },
  {
    value: 'BDA',
    text: 'バミューダ諸島(バミュ－ダ) - BDA',
  },
  {
    value: 'CBB',
    text: 'ボリビア(コチャバンバ) - CBB',
  },
  {
    value: 'LPB',
    text: 'ボリビア(ラパス) - LPB',
  },
  {
    value: 'SRE',
    text: 'ボリビア(スクレ) - SRE',
  },
  {
    value: 'SRZ',
    text: 'ボリビア(サンタ・クルース) - SRZ',
  },
  {
    value: 'TDD',
    text: 'ボリビア(トリニダ) - TDD',
  },
  {
    value: 'TJA',
    text: 'ボリビア(タリハ) - TJA',
  },
  {
    value: 'AJU',
    text: 'ブラジル(アラカンジュ) - AJU',
  },
  {
    value: 'ARU',
    text: 'ブラジル(アラサツーバ) - ARU',
  },
  {
    value: 'BEL',
    text: 'ブラジル(ベレン) - BEL',
  },
  {
    value: 'BHZ',
    text: 'ブラジル(ベロオリゾンテ) - BHZ',
  },
  {
    value: 'BPS',
    text: 'ブラジル(ポートセグロ) - BPS',
  },
  {
    value: 'BSB',
    text: 'ブラジル(ブラジリア) - BSB',
  },
  {
    value: 'BVB',
    text: 'ブラジル(ボア　ヴィスタ) - BVB',
  },
  {
    value: 'CAC',
    text: 'ブラジル(カスカヴェル) - CAC',
  },
  {
    value: 'CGB',
    text: 'ブラジル(クイアバ) - CGB',
  },
  {
    value: 'CGR',
    text: 'ブラジル(カンポグランデ) - CGR',
  },
  {
    value: 'CWB',
    text: 'ブラジル(クリティーバ) - CWB',
  },
  {
    value: 'FLN',
    text: 'ブラジル(フロリアノポリス) - FLN',
  },
  {
    value: 'FOR',
    text: 'ブラジル(フォルタレーザ) - FOR',
  },
  {
    value: 'GYN',
    text: 'ブラジル(ゴーニャ) - GYN',
  },
  {
    value: 'IGU',
    text: 'ブラジル(イグアスフォールズ) - IGU',
  },
  {
    value: 'JOI',
    text: 'ブラジル(ジョインビル) - JOI',
  },
  {
    value: 'JPA',
    text: 'ブラジル(ジョアンペソア) - JPA',
  },
  {
    value: 'LDB',
    text: 'ブラジル(ロンドリナ) - LDB',
  },
  {
    value: 'MAO',
    text: 'ブラジル(マナウス) - MAO',
  },
  {
    value: 'MCP',
    text: 'ブラジル(マカパ) - MCP',
  },
  {
    value: 'MCZ',
    text: 'ブラジル(マセイオ) - MCZ',
  },
  {
    value: 'MGF',
    text: 'ブラジル(マリンガ) - MGF',
  },
  {
    value: 'NAT',
    text: 'ブラジル(ネーテル) - NAT',
  },
  {
    value: 'NVT',
    text: 'ブラジル(ナベガンテス) - NVT',
  },
  {
    value: 'PNZ',
    text: 'ブラジル(ペトロリーナ) - PNZ',
  },
  {
    value: 'POA',
    text: 'ブラジル(ポルトアレグレ) - POA',
  },
  {
    value: 'PPB',
    text: 'ブラジル(プレジデンテ・プルデンテ) - PPB',
  },
  {
    value: 'PVH',
    text: 'ブラジル(ポルトベリョー) - PVH',
  },
  {
    value: 'RAO',
    text: 'ブラジル(リベイラプレト) - RAO',
  },
  {
    value: 'RBR',
    text: 'ブラジル(リオブランコ) - RBR',
  },
  {
    value: 'REC',
    text: 'ブラジル(レシフェ) - REC',
  },
  {
    value: 'RIO',
    text: 'ブラジル(リオ・デ・ジャネイロ) - RIO',
  },
  {
    value: 'SAO',
    text: 'ブラジル(サンパウロ) - SAO',
  },
  {
    value: 'SJP',
    text: 'ブラジル(サンジョゼ・リオプレット) - SJP',
  },
  {
    value: 'SLZ',
    text: 'ブラジル(サンルイス) - SLZ',
  },
  {
    value: 'SSA',
    text: 'ブラジル(サンバドル) - SSA',
  },
  {
    value: 'STM',
    text: 'ブラジル(サンタレン) - STM',
  },
  {
    value: 'THE',
    text: 'ブラジル(テレジーナ) - THE',
  },
  {
    value: 'UBA',
    text: 'ブラジル(ウベラバ) - UBA',
  },
  {
    value: 'UDI',
    text: 'ブラジル(ウベランディア) - UDI',
  },
  {
    value: 'VIX',
    text: 'ブラジル(ビトリア) - VIX',
  },
  {
    value: 'ASD',
    text: 'バハマ(アンドロスタウン) - ASD',
  },
  {
    value: 'ATC',
    text: 'バハマ(アートヒューズタウン) - ATC',
  },
  {
    value: 'AXP',
    text: 'バハマ(スプリングポイント) - AXP',
  },
  {
    value: 'BIM',
    text: 'バハマ(ビミニ) - BIM',
  },
  {
    value: 'CRI',
    text: 'バハマ(クロックド島) - CRI',
  },
  {
    value: 'ELH',
    text: 'バハマ(ノースエルーセラ) - ELH',
  },
  {
    value: 'FPO',
    text: 'バハマ(フリーポート) - FPO',
  },
  {
    value: 'GGT',
    text: 'バハマ(ジョージタウン) - GGT',
  },
  {
    value: 'GHB',
    text: 'バハマ(ゴベルナーズハーバー) - GHB',
  },
  {
    value: 'LGI',
    text: 'バハマ(デッドマンズカイ) - LGI',
  },
  {
    value: 'MAY',
    text: 'バハマ(マングローブカイ) - MAY',
  },
  {
    value: 'MHH',
    text: 'バハマ(マーシュハーバー) - MHH',
  },
  {
    value: 'MYG',
    text: 'バハマ(マーヤグアーナ) - MYG',
  },
  {
    value: 'NAS',
    text: 'バハマ(ナッソー) - NAS',
  },
  {
    value: 'RSD',
    text: 'バハマ(ロックサウンド) - RSD',
  },
  {
    value: 'SAQ',
    text: 'バハマ(サンアンドロス) - SAQ',
  },
  {
    value: 'SML',
    text: 'バハマ(ステーラマリス) - SML',
  },
  {
    value: 'TCB',
    text: 'バハマ(トレジャーカイ) - TCB',
  },
  {
    value: 'TZN',
    text: 'バハマ(サウスアンドロス) - TZN',
  },
  {
    value: 'WKR',
    text: 'バハマ(ウォーカーズカイ) - WKR',
  },
  {
    value: 'WTD',
    text: 'バハマ(ウェストエンド) - WTD',
  },
  {
    value: 'ZSA',
    text: 'バハマ(サンサルバド－ル) - ZSA',
  },
  {
    value: 'BZE',
    text: 'ベリーズ(ベリセシティ) - BZE',
  },
  {
    value: 'ANF',
    text: 'チリ(アントファガスタ) - ANF',
  },
  {
    value: 'ARI',
    text: 'チリ(アリカ) - ARI',
  },
  {
    value: 'CCP',
    text: 'チリ(コンセプシオン) - CCP',
  },
  {
    value: 'CPO',
    text: 'チリ(コピアポ) - CPO',
  },
  {
    value: 'GXQ',
    text: 'チリ(コイハイケ) - GXQ',
  },
  {
    value: 'IPC',
    text: 'チリ(イースター) - IPC',
  },
  {
    value: 'IQQ',
    text: 'チリ(イキケ) - IQQ',
  },
  {
    value: 'KNA',
    text: 'チリ(ビーニャ　デル　マル) - KNA',
  },
  {
    value: 'LSC',
    text: 'チリ(ラセレナ) - LSC',
  },
  {
    value: 'PMC',
    text: 'チリ(プエルトモント) - PMC',
  },
  {
    value: 'PUQ',
    text: 'チリ(プンタアレナス) - PUQ',
  },
  {
    value: 'SCL',
    text: 'チリ(サンティアゴ) - SCL',
  },
  {
    value: 'ZCO',
    text: 'チリ(テムコ) - ZCO',
  },
  {
    value: 'BAQ',
    text: 'コロンビア(バランチャ) - BAQ',
  },
  {
    value: 'BOG',
    text: 'コロンビア(ボゴタ) - BOG',
  },
  {
    value: 'CLO',
    text: 'コロンビア(カリ) - CLO',
  },
  {
    value: 'CTG',
    text: 'コロンビア(カータジェナ) - CTG',
  },
  {
    value: 'IBE',
    text: 'コロンビア(イバゲ) - IBE',
  },
  {
    value: 'LET',
    text: 'コロンビア(レティシア) - LET',
  },
  {
    value: 'MDE',
    text: 'コロンビア(メディン) - MDE',
  },
  {
    value: 'PEI',
    text: 'コロンビア(ペレイラ) - PEI',
  },
  {
    value: 'LIR',
    text: 'コスタリカ(リベリア) - LIR',
  },
  {
    value: 'SJO',
    text: 'コスタリカ(サン・ホセ) - SJO',
  },
  {
    value: 'CCC',
    text: 'キューバ(ココ島) - CCC',
  },
  {
    value: 'HAV',
    text: 'キューバ(ハバナ) - HAV',
  },
  {
    value: 'DOM',
    text: 'ドミニカ国(ドミニカ) - DOM',
  },
  {
    value: 'LRM',
    text: 'ドミニカ共和国(ラロマーナ) - LRM',
  },
  {
    value: 'POP',
    text: 'ドミニカ共和国(プエルトプラタ) - POP',
  },
  {
    value: 'PUJ',
    text: 'ドミニカ共和国(プンタカナ) - PUJ',
  },
  {
    value: 'SDQ',
    text: 'ドミニカ共和国(サント・ドミンゴ) - SDQ',
  },
  {
    value: 'STI',
    text: 'ドミニカ共和国(サンティアゴ) - STI',
  },
  {
    value: 'CUE',
    text: 'エクアドル(クエンカ) - CUE',
  },
  {
    value: 'GPS',
    text: 'エクアドル(ガラパゴス島) - GPS',
  },
  {
    value: 'GYE',
    text: 'エクアドル(グアヤキル) - GYE',
  },
  {
    value: 'UIO',
    text: 'エクアドル(キト) - UIO',
  },
  {
    value: 'GND',
    text: 'グレナダ(グレナダ) - GND',
  },
  {
    value: 'CAY',
    text: 'フランス領ギアナ(カイエンヌ) - CAY',
  },
  {
    value: 'PTP',
    text: 'グアドループ島(ポインテアピートル) - PTP',
  },
  {
    value: 'FRS',
    text: 'グアテマラ(フローレス) - FRS',
  },
  {
    value: 'GUA',
    text: 'グアテマラ(グアテマラシティ) - GUA',
  },
  {
    value: 'GEO',
    text: 'ガイアナ(ジョージタウン) - GEO',
  },
  {
    value: 'LCE',
    text: 'ホンジュラス(ラセイバ) - LCE',
  },
  {
    value: 'RTB',
    text: 'ホンジュラス(ロアタン) - RTB',
  },
  {
    value: 'SAP',
    text: 'ホンジュラス(サン・ベドロスーラ) - SAP',
  },
  {
    value: 'TGU',
    text: 'ホンジュラス(テグシガルパ) - TGU',
  },
  {
    value: 'PAP',
    text: 'ハイチ(ポルトープランス) - PAP',
  },
  {
    value: 'KIN',
    text: 'ジャマイカ(キングストン) - KIN',
  },
  {
    value: 'MBJ',
    text: 'ジャマイカ(モンテゴベイ) - MBJ',
  },
  {
    value: 'SKB',
    text: 'セントクリストファー・ネイビス(セントキッツ) - SKB',
  },
  {
    value: 'CYB',
    text: 'ケイマン諸島(ケイマンブラック) - CYB',
  },
  {
    value: 'GCM',
    text: 'ケイマン諸島(グランドケイマン) - GCM',
  },
  {
    value: 'SLU',
    text: 'セントルシア(セントルシア) - SLU',
  },
  {
    value: 'FDF',
    text: 'マルチニーク島(フォールトドフランス) - FDF',
  },
  {
    value: 'ACA',
    text: 'メキシコ(アカプルコ) - ACA',
  },
  {
    value: 'AGU',
    text: 'メキシコ(アグアスカリエンテス) - AGU',
  },
  {
    value: 'BJX',
    text: 'メキシコ(レオングアナフアト) - BJX',
  },
  {
    value: 'CEN',
    text: 'メキシコ(スーダットオビリガン) - CEN',
  },
  {
    value: 'CJS',
    text: 'メキシコ(シウダーフレアス) - CJS',
  },
  {
    value: 'CLQ',
    text: 'メキシコ(コリマ) - CLQ',
  },
  {
    value: 'CME',
    text: 'メキシコ(シウダー・フアレス) - CME',
  },
  {
    value: 'CPE',
    text: 'メキシコ(カンペチェ) - CPE',
  },
  {
    value: 'CTM',
    text: 'メキシコ(チェトゥマル) - CTM',
  },
  {
    value: 'CUL',
    text: 'メキシコ(クリアカン) - CUL',
  },
  {
    value: 'CUN',
    text: 'メキシコ(カンクン) - CUN',
  },
  {
    value: 'CUU',
    text: 'メキシコ(チワワ) - CUU',
  },
  {
    value: 'CZM',
    text: 'メキシコ(コズメル) - CZM',
  },
  {
    value: 'GDL',
    text: 'メキシコ(グアダラハラ) - GDL',
  },
  {
    value: 'GUB',
    text: 'メキシコ(ゲレロネグロ) - GUB',
  },
  {
    value: 'HMO',
    text: 'メキシコ(エルモシヨ) - HMO',
  },
  {
    value: 'HUX',
    text: 'メキシコ(フアツルコ) - HUX',
  },
  {
    value: 'LAP',
    text: 'メキシコ(ラパス) - LAP',
  },
  {
    value: 'LMM',
    text: 'メキシコ(ロスモチス) - LMM',
  },
  {
    value: 'LOV',
    text: 'メキシコ(モンクローバ) - LOV',
  },
  {
    value: 'LTO',
    text: 'メキシコ(ロレト) - LTO',
  },
  {
    value: 'MEX',
    text: 'メキシコ(メキシコシティ) - MEX',
  },
  {
    value: 'MID',
    text: 'メキシコ(メリダ) - MID',
  },
  {
    value: 'MLM',
    text: 'メキシコ(モレリア) - MLM',
  },
  {
    value: 'MTT',
    text: 'メキシコ(ミナチトラン) - MTT',
  },
  {
    value: 'MTY',
    text: 'メキシコ(モンテレイ) - MTY',
  },
  {
    value: 'MXL',
    text: 'メキシコ(メヒカリ) - MXL',
  },
  {
    value: 'MZT',
    text: 'メキシコ(マサトラン) - MZT',
  },
  {
    value: 'NLD',
    text: 'メキシコ(ヌエボ　ラレド) - NLD',
  },
  {
    value: 'OAX',
    text: 'メキシコ(オアハカ) - OAX',
  },
  {
    value: 'PBC',
    text: 'メキシコ(プエブラ) - PBC',
  },
  {
    value: 'PDS',
    text: 'メキシコ(ピエドラスネグラス) - PDS',
  },
  {
    value: 'PVR',
    text: 'メキシコ(プエルトバジャルタ) - PVR',
  },
  {
    value: 'PXM',
    text: 'メキシコ(プエルトエスコンディード) - PXM',
  },
  {
    value: 'QRO',
    text: 'メキシコ(ケレタロ) - QRO',
  },
  {
    value: 'REX',
    text: 'メキシコ(レイノサ) - REX',
  },
  {
    value: 'SJD',
    text: 'メキシコ(サン・ホセ・デルカボ＜ロス・カボス＞) - SJD',
  },
  {
    value: 'SLP',
    text: 'メキシコ(サンルイスポトス) - SLP',
  },
  {
    value: 'SLW',
    text: 'メキシコ(サルティーヨ) - SLW',
  },
  {
    value: 'TAM',
    text: 'メキシコ(タンピコ) - TAM',
  },
  {
    value: 'TAP',
    text: 'メキシコ(タパチュラ) - TAP',
  },
  {
    value: 'TGZ',
    text: 'メキシコ(トゥクストラグティエレース) - TGZ',
  },
  {
    value: 'TIJ',
    text: 'メキシコ(ティファナ) - TIJ',
  },
  {
    value: 'TRC',
    text: 'メキシコ(トレオン) - TRC',
  },
  {
    value: 'VER',
    text: 'メキシコ(ベラクルス) - VER',
  },
  {
    value: 'VSA',
    text: 'メキシコ(ビジャエルモッサ) - VSA',
  },
  {
    value: 'ZCL',
    text: 'メキシコ(サカテラス) - ZCL',
  },
  {
    value: 'ZIH',
    text: 'メキシコ(シワタネホ) - ZIH',
  },
  {
    value: 'ZLO',
    text: 'メキシコ(マンサニヨ) - ZLO',
  },
  {
    value: 'MGA',
    text: 'ニカラグア(マナグア) - MGA',
  },
  {
    value: 'PTY',
    text: 'パナマ(パナマシティ) - PTY',
  },
  {
    value: 'CUZ',
    text: 'ペルー(クスコ) - CUZ',
  },
  {
    value: 'IQT',
    text: 'ペルー(イキトス) - IQT',
  },
  {
    value: 'JUL',
    text: 'ペルー(フリアカ) - JUL',
  },
  {
    value: 'LIM',
    text: 'ペルー(リマ) - LIM',
  },
  {
    value: 'MP1',
    text: 'ペルー(マチュピチュ) - MP1',
  },
  {
    value: 'TCQ',
    text: 'ペルー(タクナ) - TCQ',
  },
  {
    value: 'TRU',
    text: 'ペルー(トルヒーリョ) - TRU',
  },
  {
    value: 'BQN',
    text: 'プエルトリコ(ラファエル・エルナンデス) - BQN',
  },
  {
    value: 'PSE',
    text: 'プエルトリコ(ポンセ) - PSE',
  },
  {
    value: 'SJU',
    text: 'プエルトリコ(サン・ファン) - SJU',
  },
  {
    value: 'AGT',
    text: 'パラグアイ(シーダッドデルエステ) - AGT',
  },
  {
    value: 'ASU',
    text: 'パラグアイ(アスンシオン) - ASU',
  },
  {
    value: 'PBM',
    text: 'スリナム(パラマリボ) - PBM',
  },
  {
    value: 'SAL',
    text: 'エルサルバドル(サンサルバドル) - SAL',
  },
  {
    value: 'SXM',
    text: 'オランダ領シント・マールテン(セントマーチン島) - SXM',
  },
  {
    value: 'GDT',
    text: 'タークス・カイコス諸島(グランドターク) - GDT',
  },
  {
    value: 'PLS',
    text: 'タークス・カイコス諸島(プロビデンシアルズ) - PLS',
  },
  {
    value: 'POS',
    text: 'トリニダード・トバゴ(ポートオブスペイン) - POS',
  },
  {
    value: 'TAB',
    text: 'トリニダード・トバゴ(トバコ) - TAB',
  },
  {
    value: 'MVD',
    text: 'ウルグアイ(モンテビデオ) - MVD',
  },
  {
    value: 'SVD',
    text: 'セントビンセント・グレナディーン諸島(セントビンセント) - SVD',
  },
  {
    value: 'BLA',
    text: 'ベネズエラ(バルセロナ) - BLA',
  },
  {
    value: 'CAJ',
    text: 'ベネズエラ(カナイマ) - CAJ',
  },
  {
    value: 'CCS',
    text: 'ベネズエラ(カラカス) - CCS',
  },
  {
    value: 'MAR',
    text: 'ベネズエラ(マラカイボ) - MAR',
  },
  {
    value: 'SOM',
    text: 'ベネズエラ(サントメ) - SOM',
  },
  {
    value: 'VLN',
    text: 'ベネズエラ(バレンシア) - VLN',
  },
  {
    value: 'EIS',
    text: 'イギリス領ヴァージン諸島(ビーフアイランド) - EIS',
  },
  {
    value: 'ST1',
    text: 'アメリカ領ヴァージン諸島(セントジョン島) - ST1',
  },
  {
    value: 'STT',
    text: 'アメリカ領ヴァージン諸島(セントトーマスアイランド) - STT',
  },
  {
    value: 'STX',
    text: 'アメリカ領ヴァージン諸島(セントクロイ島) - STX',
  },
];
