import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';

import {
  activeUserSelector,
  activeUserOrganizationSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { getRoutes } from 'client/routes';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';
import { isV3Route } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import styles from './CategoryHeader.module.css';

export const CategoryHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { headerOverride } = React.useContext(PageHeaderOverrideContext);
  const activeUser = useSelector(activeUserSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );
  const routes = getRoutes(
    t,
    activeUser,
    activeOrganization,
    loggedInAccountIsNutmegAdmin
  );
  const pathname = location.pathname;
  const activeRoute = routes.find((r) =>
    matchPath(pathname, {
      path: r.route,
      exact: true,
    })
  );

  if (!activeRoute) {
    return null;
  }

  // TODO: reza exclude header for new v3 UIUX revamp routes, remove for release
  if (
    config.enableUIRevamp ||
    config.enableUIRevampForDemo ||
    config.enableUIRevampForRelease
  ) {
    if (isV3Route(pathname)) {
      return null;
    }
  }

  const categoryRoutes = routes.filter(
    (r) => r.category === activeRoute.category && r.navigationTitle
  );
  return (
    <div className={baseStyles['base-main__headline']}>
      <div className={baseStyles['base-main__headline__ttl']}>
        {activeRoute?.headerIcon && <img src={activeRoute?.headerIcon} />}
        <h1>{headerOverride || activeRoute.title}</h1>
      </div>
      {categoryRoutes.length > 1 && (
        <div className={baseStyles['base-main__headline__sub']}>
          <ul className={styles['c-headline-tab']}>
            {categoryRoutes.map((r, i) => (
              <li
                key={i}
                className={clsx(
                  location.pathname === r.route && styles['is-active']
                )}
              >
                <Link to={r.route}>{r.navigationTitle}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
