import { Dispatch, SetStateAction } from 'react';
//import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { Modal } from 'client/components/v3/Form/Modal';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
//import thIcon from 'client/images/ic_th.svg';
//import baseStyles from 'client/base.module.css';
//import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EmailSettingsModal = ({ setShowModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  return (
    <>
      <Form
        onSubmit={async (values: {
          shouldSendTaskNotificationEmail: boolean;
        }) => {
          try {
            await dispatch(
              updateOrganization(activeUserOrganization?.id ?? '', 'SUPPLIER', {
                should_send_task_notification_email: {
                  value: values.shouldSendTaskNotificationEmail,
                },
              })
            );
          } catch (error) {
            console.error(error);
          } finally {
            setShowModal(false);
          }
        }}
        initialValues={{
          shouldSendTaskNotificationEmail:
            activeUserOrganization?.should_send_task_notification_email
              ?.value ?? false,
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Email Notifications')}
              open={true}
              onClose={() => {
                setShowModal(false);
              }}
              rightActionChildren={
                <>
                  <Button
                    text={t('Cancel')}
                    uiType="bg"
                    size="md"
                    color="white"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  />
                  <Button
                    type="submit"
                    text={t('Save')}
                    uiType="bg"
                    size="md"
                    color="primary"
                  />
                </>
              }
              style={{
                width: '600px',
                height: 'fit-content',
                maxHeight: '85%',
              }}
            >
              <div style={{ display: 'block' }}>
                <Field name="shouldSendTaskNotificationEmail" type="checkbox">
                  {({ input }) => {
                    return (
                      <Toggle
                        checked={input.checked ?? false}
                        onChange={() => input.onChange(!input.checked)}
                        label={t(
                          'Receive notification email about reservations on Dash.'
                        )}
                      />
                    );
                  }}
                </Field>
                <div style={{ marginLeft: '44px' }}>
                  <p>
                    {t(
                      'Turn this ON to receive daily notification email about reservations on Dash. Email will be delivered to the email address saved in the Email Notifications for booking notifications.'
                    )}
                  </p>
                </div>
              </div>
            </Modal>
          </form>
        )}
      </Form>
    </>
  );
};
