import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import clsx from 'clsx';
import _ from 'lodash';

import type { ReduxState } from 'client/reducers';
import type { SearchCheckinRecordsRequest } from 'client/libraries/util/searchCheckinRecords';
import {
  convertSearchCheckinRecordsRequestToQueryParams,
  convertSearchCheckinRecordsRequestToDownloadQueryParams,
} from 'client/libraries/util/searchCheckinRecords';
import { Button } from 'client/components/Form';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
  alternateOrganizationIsActiveSelectorInSecret,
} from 'client/reducers/user';
import {
  fetchCheckinRecords,
  downloadCheckinRecordCSV,
} from 'client/actions/checkinRecords';
import { fetchProducts } from 'client/actions/products';
import { setLastExecutedCheckinRecordSearchCondition } from 'client/actions/checkinRecordTableControls';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';
import fileDLIcon from 'client/images/ic_file_download.svg';

import { CheckinRecordSearchFiltersDisplayBox } from './CheckinRecordSearchFiltersDisplayBox/CheckinRecordSearchFiltersDisplayBox';
import { useCheckinRecordColumns } from './util';
import { CheckinRecordSearchSettingsModal } from './CheckinRecordSearchSettingsModal';

export const CheckinRecordTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [runSearch, setRunSearch] = React.useState<boolean>(false);
  const allColumns = useCheckinRecordColumns();

  const loading = useSelector(
    (state: ReduxState) => state.checkinRecords.loading
  );
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.checkinRecordTableControls.lastExecutedSearchCondition
  );

  const alternateOrganizationIsActiveInSecret = useSelector(
    alternateOrganizationIsActiveSelectorInSecret
  );

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const checkinRecords = useSelector(
    (state: ReduxState) => state.checkinRecords.all
  );

  const downloading = useSelector(
    (state: ReduxState) => state.checkinRecords.downloading
  );

  const filteredCheckinRecords = checkinRecords.filter((record) => {
    if (!lastExecutedSearchCondition.stubText) {
      return true;
    }
    if (
      (record.stub_text ?? '').indexOf(lastExecutedSearchCondition.stubText) !==
      -1
    ) {
      return true;
    }
    return false;
  });

  const [searchCondition, setSearchCondition] =
    React.useState<SearchCheckinRecordsRequest>(lastExecutedSearchCondition);

  React.useEffect(() => {
    setRunSearch(true);
  }, [activeUserOrganization]);

  React.useEffect(() => {
    setRunSearch(true);
  }, [alternateOrganizationIsActiveInSecret]);

  React.useEffect(() => {
    if (runSearch) {
      search();
      setRunSearch(false);
    }
  }, [runSearch]);

  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      supplierReference: '',
      customerGivenName: '',
      customerFamilyName: '',
      productIds: [],
      participationDateFrom: '',
      participationDateTo: '',
      checkinDateFrom: '',
      checkinDateTo: '',
      dateFilterPreset: 'PARTICIPATION_1_DAY',
      stubText: '',
    });
  };

  const search = () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(setLastExecutedCheckinRecordSearchCondition(searchCondition));
      }

      dispatch(
        fetchCheckinRecords(
          convertSearchCheckinRecordsRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const downloadCSV = () => {
    if (!isNutmegAdmin) {
      dispatch(
        downloadCheckinRecordCSV(
          convertSearchCheckinRecordsRequestToDownloadQueryParams(
            searchCondition
          )
        )
      );
    }
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <CheckinRecordSearchSettingsModal
              onReset={reset}
              onSearch={() => {
                setRunSearch(true);
              }}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>

          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-main__body__header__right__another'],
                baseStyles['is-close']
              )}
            >
              <a
                className={clsx(baseStyles['base-btn'])}
                onClick={() => {
                  if (!downloading) {
                    downloadCSV();
                  }
                }}
              >
                <img src={fileDLIcon} />
              </a>

              <ul>
                <li>
                  <Button.Sub
                    content={<>{t('Download CSV')}</>}
                    onClick={() => {
                      if (!downloading) {
                        downloadCSV();
                      }
                    }}
                    loading={downloading}
                    disabled={downloading}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <Box mb={2}>
        <CheckinRecordSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
          totalHits={filteredCheckinRecords.length ?? 0}
        />
      </Box>
      <CustomTable
        items={filteredCheckinRecords}
        columns={allColumns}
        usePaging={true}
      />
    </div>
  );
};
