import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { Box } from 'client/components/Box/Box';
import { Button, Input, TextArea } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { Add } from 'client/components/Icons/Add';
import { Delete } from 'client/components/Icons/Delete';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getArrayMutators } from 'client/libraries/util/form';
import { fetchProducts } from 'client/actions/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  createNewsletterSegment,
  updateNewsletterSegment,
  fetchNewsletterSegments,
} from 'client/actions/newsletterSegment';
import { ReduxState } from 'client/reducers';
import { fetchCustomerTags } from 'client/actions/customers';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';

import { ConditionGroupEditor } from './ConditionGroupEditor';
import { FileInputForm } from './FileInputForm';
import {
  convertFormValuesToSwaggerSegment,
  getDefaultConditionGroup,
  getInitialValues,
} from './formValues';

export const MarketingAutomationSegmentEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { required } = getValidators(t);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const newsletterSegments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const existingSegment = id
    ? newsletterSegments.find((segment) => segment.id === id) ?? undefined
    : undefined;
  const initialValues = React.useMemo(
    () => getInitialValues(existingSegment),
    [existingSegment]
  );

  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
      dispatch(fetchNewsletterSegments());
      dispatch(fetchCustomerTags());
    }
  }, [activeUserOrganization]);

  return (
    <div>
      <Box mb={2}>
        <Link to="/marketingautomation/segments">
          <BackArrow />
        </Link>
      </Box>
      <Form
        onSubmit={async (values) => {
          try {
            if (existingSegment) {
              await dispatch(
                updateNewsletterSegment(
                  id,
                  convertFormValuesToSwaggerSegment(values)
                )
              );
            } else {
              await dispatch(
                createNewsletterSegment(
                  convertFormValuesToSwaggerSegment(values)
                )
              );
            }

            history.push('/marketingautomation/segments');
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({
          handleSubmit,
          values,
          submitting,
          submitSucceeded,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={baseStyles['base-main__body__box']}>
              <div className={baseStyles['base-main__body__box__body']}>
                <FormTableBox>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Name')}</th>
                        <td>
                          <Field name="name" validate={required}>
                            {({ input, meta: { touched, error } }) => (
                              <Input {...input} error={touched && error} />
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Description')}</th>
                        <td>
                          <Field name="description">
                            {({ input }) => (
                              <TextArea
                                value={input.value}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {t('Type')}
                          <Tooltip
                            text={t(
                              'Dynamic segments evaluate conditions at the time of sending newsletter. For static segments, the list of customers is updated at the time of creation and editing the segment.'
                            )}
                          />
                        </th>
                        <td>
                          <EnumRadioButtonGroup
                            name="type"
                            options={[
                              { label: t('Dynamic'), value: 'DYNAMIC' },
                              { label: t('Static'), value: 'STATIC' },
                              ...(config.enableImportedCustomerListSegment
                                ? [
                                    {
                                      label: t('Import Customer List'),
                                      value: 'IMPORTED_CUSTOMER_LIST',
                                    },
                                  ]
                                : []),
                            ]}
                            isRequired={true}
                          />
                        </td>
                      </tr>
                      {values.type === 'IMPORTED_CUSTOMER_LIST' ? (
                        <tr>
                          <th>{t('Import Customer List')}</th>
                          <td>
                            {existingSegment?.imported_csv_file_url && (
                              <a
                                href={existingSegment?.imported_csv_file_url}
                                target="_blank"
                                rel="noreferrer"
                                className={baseStyles['base-link']}
                              >
                                {t('Download')}
                              </a>
                            )}
                            <Field
                              name="importedCsvFileS3Key"
                              component={FileInputForm}
                            />
                          </td>
                        </tr>
                      ) : (
                        <FieldArray
                          name="conditionGroups"
                          validate={
                            getValidators(t).atLeastOneConditionRequired
                          }
                        >
                          {({ fields, meta: { error, touched } }) =>
                            fields.length === 0 ? (
                              <tr>
                                <th>{t('Condition Groups')}</th>
                                <td>
                                  <Add
                                    onClick={() =>
                                      (fields as any).insertAt(
                                        0,
                                        getDefaultConditionGroup()
                                      )
                                    }
                                  />
                                  {touched && error && (
                                    <p
                                      className={
                                        baseStyles['base-form-box__err']
                                      }
                                    >
                                      {error}
                                    </p>
                                  )}
                                </td>
                              </tr>
                            ) : (
                              fields.map((name, idx) => (
                                <>
                                  {idx > 0 && (
                                    <tr>
                                      <th></th>
                                      <td style={{ fontWeight: 'bold' }}>
                                        {t('OR')}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <th>
                                      {t('Condition Group #{{groupNum}}', {
                                        groupNum: idx + 1,
                                      })}
                                    </th>
                                    <td>
                                      <table>
                                        <colgroup>
                                          <col />
                                          <col style={{ width: '80px' }} />
                                        </colgroup>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <ConditionGroupEditor
                                                name={name}
                                              />
                                            </td>
                                            <td>
                                              <Box display="flex">
                                                <Add
                                                  onClick={() =>
                                                    (fields as any).insertAt(
                                                      idx + 1,
                                                      getDefaultConditionGroup()
                                                    )
                                                  }
                                                />
                                                {(fields?.length || 0) > 1 && (
                                                  <Delete
                                                    onClick={() =>
                                                      (fields as any).remove(
                                                        idx
                                                      )
                                                    }
                                                  />
                                                )}
                                              </Box>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                              ))
                            )
                          }
                        </FieldArray>
                      )}
                    </tbody>
                  </table>
                </FormTableBox>
                {submitSucceeded && (
                  <Message success header={t('Save Successful')} />
                )}
                {submitError && <Message error header={t('Save Failed')} />}
                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  <Button
                    loading={submitting}
                    size="small"
                    style="blue"
                    type="submit"
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
