import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import type { ReduxState } from 'client/reducers';
import type { Organization } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const savePaymentMethodRequest = (stripePaymentMethod: string) => ({
  type: SAVE_PAYMENT_METHOD_REQUEST,
  stripePaymentMethod,
});

const savePaymentMethodSuccess = (organization: Organization) => ({
  type: SAVE_PAYMENT_METHOD_SUCCESS,
  organization,
});

const savePaymentMethodFailure = (error: string) => ({
  type: SAVE_PAYMENT_METHOD_FAILURE,
  error,
});

export const savePaymentMethod =
  (stripePaymentMethod: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(savePaymentMethodRequest(stripePaymentMethod));
    return axios
      .post(
        `/api/organizations/paymentmethods`,
        {
          stripe_payment_method: stripePaymentMethod,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(savePaymentMethodSuccess(response.data));
      })
      .catch((err) => {
        dispatch(savePaymentMethodFailure(err.message));
      });
  };
