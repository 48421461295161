import { combineReducers } from 'redux';

import {
  FETCH_PRODUCT_TAGS_FAILURE,
  FETCH_PRODUCT_TAGS_REQUEST,
  FETCH_PRODUCT_TAGS_SUCCESS,
} from 'client/constants/ActionTypes';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_TAGS_REQUEST:
      return true;

    case FETCH_PRODUCT_TAGS_FAILURE:
    case FETCH_PRODUCT_TAGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_TAGS_FAILURE:
      return action.error;

    case FETCH_PRODUCT_TAGS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const tags = (state = [], action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_TAGS_SUCCESS:
      return action.response.product_tags;

    default:
      return state;
  }
};

const internalTags = (state = [], action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_TAGS_SUCCESS:
      return action.response.internal_product_tags;

    default:
      return state;
  }
};

export const productTags = combineReducers({
  tags,
  internalTags,
  error,
  loading,
});
