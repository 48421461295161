import * as React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { DurationInput } from 'client/components/NewProductEditor/DurationInput/DurationInput';
import { Input, TextArea } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getValidators } from 'shared/libraries/validate/validator';
import { timePlusOffset } from 'client/libraries/util/util';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getStartTimesFromRecurrence } from 'client/libraries/util/getStartTimesFromRecurrence';
import baseStyles from 'client/base.module.css';

import styles from '../DetailsStep.module.css';

import { getDefaultItineraryItem } from './FormValues';

type ItineraryItemInputProps = {
  name: string;
  startTimes: string[];
};

const ItineraryItemInput = ({ name, startTimes }: ItineraryItemInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const { required } = getValidators(t);
  return (
    <ul className={styles['scheds']}>
      <li>
        <p className={styles['scheds__ttl']}>{t('Start Time')}</p>
        <Field name={`${name}.timeOffsetStart`}>
          {({ input }) => (
            <div className={styles['scheds__body']}>
              <div className={styles['scheds__body__item']}>
                <p>{t('Time Offset:')}</p>
                <div className={styles['duration-input-box']}>
                  <DurationInput showSignSelect {...input} />
                </div>
              </div>
              <div className={styles['scheds__body__item']}>
                <p>{t('Displayed Time: ')}</p>
                <span className={styles['blue']}>
                  {startTimes
                    .map((startTime) => {
                      if (!input.value) {
                        return '-';
                      }

                      const st = moment(startTime, 'H:mm');
                      return timePlusOffset(st, input.value).format('H:mm');
                    })
                    .join(',')}
                </span>
              </div>
            </div>
          )}
        </Field>
      </li>
      <li>
        <p className={styles['scheds__ttl']}>{t('End Time')}</p>
        <Field name={`${name}.timeOffsetEnd`}>
          {({ input }) => (
            <div className={styles['scheds__body']}>
              <div className={styles['scheds__body__item']}>
                <p>{t('Time Offset:')}</p>
                <div className={styles['duration-input-box']}>
                  <DurationInput showSignSelect {...input} />
                </div>
              </div>
              <div className={styles['scheds__body__item']}>
                <p>{t('Displayed Time: ')}</p>
                <span className={styles['blue']}>
                  {startTimes
                    .map((startTime) => {
                      if (!input.value) {
                        return '-';
                      }

                      const st = moment(startTime, 'H:mm');
                      return timePlusOffset(st, input.value).format('H:mm');
                    })
                    .join(',')}
                </span>
              </div>
            </div>
          )}
        </Field>
      </li>
      <li className={styles['scheds__cell']}>
        <TranslatedField name={`${name}.title`} validate={required}>
          {({ input, translationInput, meta: { error, touched } }) => (
            <>
              <div>
                <p className={styles['scheds__ttl']}>{t('Title')}</p>
                <p className={styles['scheds__body']}>
                  <Input {...input} maxWidth={800} error={touched && error} />
                </p>
              </div>
              {translationTargetLanguage && (
                <div>
                  <p className={styles['scheds__ttl']}>{`${t(
                    'Title'
                  )} (${translationTargetLanguageName})`}</p>
                  <p className={styles['scheds__body']}>
                    <Input
                      {...translationInput}
                      maxWidth={800}
                      error={touched && error}
                    />
                  </p>
                </div>
              )}
            </>
          )}
        </TranslatedField>
      </li>
      <li className={styles['scheds__cell']}>
        <TranslatedField name={`${name}.description`}>
          {({ input, translationInput }) => (
            <>
              <div>
                <p className={styles['scheds__ttl']}>{t('Description')}</p>
                <p className={styles['scheds__body']}>
                  <TextArea {...(input as any)} maxWidth={800} height={80} />
                </p>
              </div>
              {translationTargetLanguage && (
                <div>
                  <p className={styles['scheds__ttl']}>{`${t(
                    'Description'
                  )} (${translationTargetLanguageName})`}</p>
                  <p className={styles['scheds__body']}>
                    <TextArea
                      {...(translationInput as any)}
                      maxWidth={800}
                      height={80}
                    />
                  </p>
                </div>
              )}
            </>
          )}
        </TranslatedField>
      </li>
    </ul>
  );
};

export const ItineraryEditor = () => {
  const { t } = useTranslation();
  const editingProduct = React.useContext(EditingProductContext);
  const startTimes = getStartTimesFromRecurrence(
    editingProduct?.recurrence || []
  ).map((startTime) => startTime.hhMm || '');
  return (
    <div className={styles['acBody']}>
      <div className={styles['c-table-list']}>
        <table>
          <tbody>
            <FieldArray name="itinerary">
              {({ fields }) =>
                fields.length === 0 ? (
                  <AddIcon
                    onClick={() =>
                      (fields as any).insertAt(0, getDefaultItineraryItem())
                    }
                  />
                ) : (
                  fields.map((name, idx) => (
                    <tr key={name}>
                      <th>
                        {
                          t('Itinerary Item #{{itemNumber}}', {
                            itemNumber: idx + 1,
                          })
                          /* スケジュール1 */
                        }
                      </th>
                      <td colSpan={2}>
                        <div className={baseStyles['base-flex']}>
                          <ItineraryItemInput
                            name={name}
                            startTimes={startTimes}
                          />
                          <div className={baseStyles['base-flex']}>
                            <AddIcon
                              onClick={() =>
                                (fields as any).insertAt(
                                  idx + 1,
                                  getDefaultItineraryItem()
                                )
                              }
                            />
                            <DeleteIcon onClick={() => fields.remove(idx)} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )
              }
            </FieldArray>
          </tbody>
        </table>
      </div>
    </div>
  );
};
