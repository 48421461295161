import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import {
  Button,
  FieldWrapper,
  Input,
  Select,
  TextArea,
} from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { fetchPromotions } from 'client/actions/promotions';
import { ReduxState } from 'client/reducers';
import { TranslateFuncType } from 'client/components/Translate';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getApiPromotionTitleText } from 'client/pages/ProductDetails/getPromotionTitleText';
import { Promotion } from 'shared/models/swagger';

export const getPromotionPeriodText = (
  promotion: Promotion,
  t: TranslateFuncType,
  locale: string
) => {
  const participationDateText = promotion.participation_date_ranges?.length
    ? t('Participation Date: {{participationDate}}', {
        dateRanges: promotion.participation_date_ranges
          .map(
            (dateRange) =>
              `${moment(dateRange.start_date_local)
                .locale(locale)
                .format('ll')} ~ ${moment(dateRange.end_date_local)
                .locale(locale)
                .format('ll')}`
          )
          .join('; '),
      })
    : '';
  const bookedDateText =
    promotion.booked_date_local_from || promotion.booked_date_local_to
      ? t('Booked Date: {{bookedDateFrom}} ~ {{bookedDateTo}}', {
          bookedDateFrom: moment(promotion.booked_date_local_from)
            .locale(locale)
            .format('ll'),
          bookedDateTo: moment(promotion.booked_date_local_to)
            .locale(locale)
            .format('ll'),
        })
      : '';
  const texts: string[] = [];
  if (participationDateText) {
    texts.push(participationDateText);
  }
  if (bookedDateText) {
    texts.push(bookedDateText);
  }

  return texts.join('\n');
};

const getPromotionName = (
  promotion: Promotion,
  t: TranslateFuncType,
  locale: string
): string => {
  const parts: string[] = [];

  if (promotion.promo_code) {
    parts.push(`[${promotion.promo_code}]`);
  }
  parts.push(promotion.product_name || t('All Products'));
  parts.push(getApiPromotionTitleText(promotion, t, locale));
  parts.push(getPromotionPeriodText(promotion, t, locale));

  return parts.join(' - ');
};

const getPromotionMaxDiscountDescription = (
  promotion: Promotion,
  t: TranslateFuncType
) => {
  const percentDelta = promotion.unit_price_deltas?.find((delta) =>
    Boolean(delta.delta_percent)
  );
  if (percentDelta) {
    if (percentDelta.delta_type === 'CHARGE') {
      return `+${percentDelta}%`;
    }
    return t('{{percent}}% OFF', {
      percent: percentDelta.delta_percent,
    });
  }

  const absoluteDelta = _.maxBy(
    promotion.unit_price_deltas?.filter((delta) =>
      Boolean(delta.delta_fixed_gross)
    ),
    (delta) => currency(delta.delta_fixed_gross ?? '').value
  );

  if (absoluteDelta) {
    if (absoluteDelta.delta_type === 'CHARGE') {
      return `+${formattedCurrencyAmount(
        absoluteDelta.delta_fixed_gross ?? ''
      )}`;
    }
    return t('{{fixedAmount}} OFF', {
      fixedAmount: formattedCurrencyAmount(
        absoluteDelta.delta_fixed_gross ?? ''
      ),
    });
  }

  return '';
};

export interface PromotionTexts {
  promoDiscountText: string;
  promoCode: string;
  promoLeadText: string;
  promoDescription: string;
  promoPeriodText: string;
  periodText: string;
}

interface Props {
  isPromoCode?: boolean;
  initialValue: PromotionTexts;
  onSave: (newValue: PromotionTexts) => void;
}

export const PromoEditButton = ({
  onSave,
  initialValue,
  isPromoCode,
}: Props) => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [selectedPromotionId, setSelectedPromotionId] = React.useState('');
  const [promoDiscountText, setPromoDiscountText] = React.useState(
    initialValue.promoDiscountText
  );
  const [promoLeadText, setPromoLeadText] = React.useState(
    initialValue.promoLeadText
  );
  const [promoDescription, setPromoDescription] = React.useState(
    initialValue.promoDescription
  );
  const [promoCode, setPromoCode] = React.useState(initialValue.promoCode);
  const [promoPeriodText, setPromoPeriodText] = React.useState(
    initialValue.promoPeriodText
  );
  const [periodText, setPeriodText] = React.useState(initialValue.periodText);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchPromotions());
  }, []);

  const promotions = useSelector((state: ReduxState) => state.promotions.all);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  _.orderBy(promotions, (promotion) => {
    switch (promotion.status) {
      case 'ON':
        return 1;
      case 'OFF':
        return 2;
      default:
        return 3;
    }
  });
  const promotionOptions: { text: string; value: string }[] = promotions
    .filter((promo) => promo.status === 'ON')
    .filter((promo) =>
      isPromoCode ? Boolean(promo.promo_code) : !promo.promo_code
    )
    .map((promo) => ({
      text: getPromotionName(promo, t, locale),
      value: promo.id,
    }));

  return (
    <>
      <Box display="flex" mb={-4} position="relative" zIndex={9}>
        <Box ml="auto">
          <Edit onClick={() => setShowEditModal(true)} />
        </Box>
      </Box>
      {showEditModal && (
        <Modal
          open={true}
          title={t('Edit Promotion Texts')}
          onClose={() => setShowEditModal(false)}
        >
          <Modal.Content>
            <Box display="flex" alignItems="flex-end">
              <Box width="75%">
                <Select
                  label={t('Select Promotion')}
                  options={promotionOptions}
                  value={selectedPromotionId}
                  onChange={(e, { value }) =>
                    setSelectedPromotionId(value as string)
                  }
                />
              </Box>
              <Box ml={2}>
                <Button
                  size="middle"
                  style="green"
                  onClick={() => {
                    const selectedPromotion = promotions.find(
                      (promo) => promo.id === selectedPromotionId
                    );

                    if (selectedPromotion) {
                      const title = getApiPromotionTitleText(
                        selectedPromotion,
                        t,
                        locale
                      );
                      setPromoLeadText(title);
                      setPromoDescription(title);
                      setPromoCode(selectedPromotion.promo_code || '');

                      setPromoPeriodText(title);
                      const periodText = getPromotionPeriodText(
                        selectedPromotion,
                        t,
                        locale
                      );
                      setPeriodText(periodText);

                      setPromoDiscountText(
                        getPromotionMaxDiscountDescription(selectedPromotion, t)
                      );
                    }
                  }}
                >
                  {t('Apply')}
                </Button>
              </Box>
            </Box>
            {isPromoCode && (
              <FieldWrapper label={t('Promo Code')}>{promoCode}</FieldWrapper>
            )}
            {isPromoCode && (
              <Input
                label={t('Promo Lead Text')}
                value={promoLeadText}
                onChange={(e, { value }) => setPromoLeadText(value)}
              />
            )}
            <Input
              label={t('Discount')}
              value={promoDiscountText}
              onChange={(e, { value }) => setPromoDiscountText(value)}
            />
            <TextArea
              label={t('Promo Description')}
              value={promoDescription}
              onChange={(e, { value }) => setPromoDescription(value)}
            />
            <Input
              label={t('Message')}
              value={promoPeriodText}
              onChange={(e, { value }) => setPromoPeriodText(value)}
            />
            <TextArea
              label={t('Applicable Conditions')}
              value={periodText}
              onChange={(e, { value }) => setPeriodText(value)}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button.Cancel onClick={() => setShowEditModal(false)}>
              {t('Cancel')}
            </Button.Cancel>
            <Button.Submit
              onClick={() => {
                onSave({
                  promoDiscountText,
                  promoLeadText,
                  promoCode,
                  promoDescription,
                  promoPeriodText,
                  periodText,
                });
                setShowEditModal(false);
              }}
            >
              {t('Update')}
            </Button.Submit>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
export default PromoEditButton;
