import { combineReducers } from 'redux';

import {
  FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST,
  FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS,
  FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE,
  FETCH_NPS_SURVEY_REPORT_DATA_FAILURE,
  FETCH_NPS_SURVEY_REPORT_DATA_REQUEST,
  FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GetNpsSurveyReportDataResponse,
  NpsSurveyReportDataSet,
  SurveySubmissionSummary,
} from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST:
      return true;

    case FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS:
    case FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (
  state: SurveySubmissionSummary = {},
  action: any
): SurveySubmissionSummary => {
  switch (action.type) {
    case FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

const reportData = (state: NpsSurveyReportDataSet[] = [], action: any) => {
  switch (action.type) {
    case FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetNpsSurveyReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const reportDataLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_NPS_SURVEY_REPORT_DATA_REQUEST:
      return true;
    case FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS:
    case FETCH_NPS_SURVEY_REPORT_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export const surveySubmissionResults = combineReducers({
  loading,
  error,
  all,
  reportData,
  reportDataLoading,
});
