import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_NEWSLETTER_SEGMENT_FAILURE,
  CREATE_NEWSLETTER_SEGMENT_REQUEST,
  CREATE_NEWSLETTER_SEGMENT_SUCCESS,
  DELETE_NEWSLETTER_SEGMENT_FAILURE,
  DELETE_NEWSLETTER_SEGMENT_REQUEST,
  DELETE_NEWSLETTER_SEGMENT_SUCCESS,
  FETCH_NEWSLETTER_SEGMENTS_FAILURE,
  FETCH_NEWSLETTER_SEGMENTS_REQUEST,
  FETCH_NEWSLETTER_SEGMENTS_SUCCESS,
  UPDATE_NEWSLETTER_SEGMENT_FAILURE,
  UPDATE_NEWSLETTER_SEGMENT_REQUEST,
  UPDATE_NEWSLETTER_SEGMENT_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  NewsletterCustomerSegment,
  CreateNewsletterCustomerSegmentResponse,
  ListNewsletterCustomerSegmentsResponse,
  UpdateNewsletterCustomerSegmentResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_NEWSLETTER_SEGMENTS_FAILURE:
    case CREATE_NEWSLETTER_SEGMENT_FAILURE:
    case UPDATE_NEWSLETTER_SEGMENT_FAILURE:
    case DELETE_NEWSLETTER_SEGMENT_FAILURE:
      return action.error;

    case FETCH_NEWSLETTER_SEGMENTS_SUCCESS:
    case CREATE_NEWSLETTER_SEGMENT_SUCCESS:
    case UPDATE_NEWSLETTER_SEGMENT_SUCCESS:
    case DELETE_NEWSLETTER_SEGMENT_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_NEWSLETTER_SEGMENTS_REQUEST:
    case CREATE_NEWSLETTER_SEGMENT_REQUEST:
    case UPDATE_NEWSLETTER_SEGMENT_REQUEST:
    case DELETE_NEWSLETTER_SEGMENT_REQUEST:
      return true;

    case FETCH_NEWSLETTER_SEGMENTS_FAILURE:
    case CREATE_NEWSLETTER_SEGMENT_FAILURE:
    case UPDATE_NEWSLETTER_SEGMENT_FAILURE:
    case DELETE_NEWSLETTER_SEGMENT_FAILURE:
    case FETCH_NEWSLETTER_SEGMENTS_SUCCESS:
    case CREATE_NEWSLETTER_SEGMENT_SUCCESS:
    case UPDATE_NEWSLETTER_SEGMENT_SUCCESS:
    case DELETE_NEWSLETTER_SEGMENT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: NewsletterCustomerSegment[] = [],
  action: any
): NewsletterCustomerSegment[] => {
  switch (action.type) {
    case FETCH_NEWSLETTER_SEGMENTS_SUCCESS: {
      const response =
        action.response as ListNewsletterCustomerSegmentsResponse;
      return response.segments;
    }

    case CREATE_NEWSLETTER_SEGMENT_SUCCESS: {
      const response =
        action.response as CreateNewsletterCustomerSegmentResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_NEWSLETTER_SEGMENT_SUCCESS: {
      const response =
        action.response as UpdateNewsletterCustomerSegmentResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_NEWSLETTER_SEGMENT_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const newsletterSegments = combineReducers({
  error,
  loading,
  all,
});
