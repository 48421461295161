import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type {
  ColumnType,
  ReservationListColumn,
} from 'client/libraries/util/getReservationTableColumns';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  reservations: ReservationSummaryShape[];
  visibleColumns: (ColumnType & ReservationListColumn)[];
  totalHits: number;
  headerElementItems?: Record<string, boolean>;
  rowCount: number;
  onRowCountChange: (rowCount: number) => void;
  currentPage: number;
  onCurrentPageChange: (page: number) => void;
};

const calcWidth = (width: string | typeof undefined): string => {
  if (width) return width;
  return 'short';
};

export const ReservationSearchCustomTableControlled = ({
  reservations,
  totalHits,
  visibleColumns,
  headerElementItems,
  rowCount,
  onRowCountChange,
  currentPage,
  onCurrentPageChange,
}: Props) => {
  const { t } = useTranslation();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    onRowCountChange(count);
    onCurrentPageChange(1);
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (totalHits < rowCount * page) {
      page = Math.floor(totalHits / rowCount);
    }

    onCurrentPageChange(page);
  };

  React.useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);
  const pageCount =
    Math.floor(totalHits / rowCount) + (totalHits % rowCount ? 1 : 0);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  const [showReservationColumns, setShowReservationColumns] = React.useState(
    [...Array(totalHits)].map(() => false)
  );
  return (
    <>
      <div className={clsx(componentStyles['c-table-main'])}>
        <div
          className={clsx(componentStyles['c-table-main__thead'])}
          ref={tableHeaderRef}
        >
          <table>
            <tbody>
              <tr>
                {visibleColumns.map(
                  (visibleColumn: ColumnType & ReservationListColumn, idx) => {
                    const width = calcWidth(visibleColumn.width);
                    if (
                      visibleColumn.Header !== '' &&
                      typeof visibleColumn.HeaderElement === 'undefined'
                    ) {
                      return (
                        <>
                          <th
                            className={clsx(
                              baseStyles[
                                ('base-r-t-' + width) as keyof typeof baseStyles
                              ]
                            )}
                            key={idx}
                          >
                            {visibleColumn.Header}
                          </th>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <th
                            className={clsx(
                              baseStyles[
                                ('base-r-t-' + width) as keyof typeof baseStyles
                              ]
                            )}
                            key={idx}
                          >
                            {visibleColumn.HeaderElement?.(headerElementItems)}
                          </th>
                        </>
                      );
                    }
                  }
                )}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={clsx(componentStyles['c-table-main__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table>
            <tbody>
              {reservations.map((reservation, ridx) => {
                return (
                  <tr key={ridx}>
                    {visibleColumns.map((visibleColumn, idx) => {
                      const width = calcWidth(visibleColumn.width);
                      let displayValue;

                      if (typeof visibleColumn.accessor === 'string') {
                        displayValue = reservation[visibleColumn.accessor];
                      } else if (typeof visibleColumn.accessor === 'function') {
                        displayValue = visibleColumn.accessor(reservation);
                      } else {
                        displayValue = '';
                      }

                      if (visibleColumn.Cell) {
                        const cell = {
                          value: displayValue,
                          original: reservation,
                        };
                        displayValue = visibleColumn.Cell(cell);
                      }

                      if (visibleColumn.th) {
                        return (
                          <th
                            className={clsx(
                              baseStyles[
                                ('base-r-t-' + width) as keyof typeof baseStyles
                              ]
                            )}
                            key={idx}
                          >
                            {displayValue}
                          </th>
                        );
                      } else {
                        return (
                          <td
                            key={idx}
                            className={clsx(
                              baseStyles[
                                ('base-r-t-' + width) as keyof typeof baseStyles
                              ],
                              6 < idx
                                ? showReservationColumns[ridx]
                                  ? ''
                                  : baseStyles['base-t-spHidden']
                                : ''
                            )}
                            data-text={visibleColumn.Header}
                          >
                            {displayValue}
                          </td>
                        );
                      }
                    })}
                    <td className={baseStyles['base-t-spShow']}>
                      <a
                        className={clsx(
                          componentStyles['c-table-main__tbody__spMore'],
                          showReservationColumns[ridx]
                            ? componentStyles['arrowUp']
                            : ''
                        )}
                        onClick={() => {
                          const newData = [...showReservationColumns];
                          newData[ridx] = !newData[ridx];
                          setShowReservationColumns(newData);
                        }}
                      >
                        {showReservationColumns[ridx]
                          ? t('Close')
                          : t('See more')}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['prev']
          )}
          onClick={() => {
            scrollButtonClickHandler('prev');
          }}
        >
          <p></p>
        </div>
        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['next']
          )}
          onClick={() => {
            scrollButtonClickHandler('next');
          }}
        >
          <p></p>
        </div>
      </div>

      <div className={clsx(componentStyles['c-pagination'])}>
        <div className={clsx(componentStyles['c-pagination__size'])}>
          <p>{t('Number of lines')}:</p>
          <label>
            <select value={rowCount} onChange={rowCountChangeHandler}>
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
        <div className={clsx(componentStyles['c-pagination__jump'])}>
          <p>{t('Page')}:</p>
          <input
            type="number"
            value={currentPage}
            onChange={currentPageHandler}
          />
          <p>of {pageCount}</p>
        </div>
        <div className={clsx(componentStyles['c-pagination__btns'])}>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              1 < currentPage ? '' : componentStyles['disable']
            )}
            onClick={pageBackClickHandler}
          >
            {t('Previous')}
          </a>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              currentPage < pageCount ? '' : componentStyles['disable']
            )}
            onClick={pageForwardClickHandler}
          >
            {t('Next')}
          </a>
        </div>
      </div>
    </>
  );
};
