import { PurchasedAnnualPassChartGadget } from './Gadgets/PurchasedAnnualPassChartGadget';
import styles from './Dashboard.module.css';
import { AnnualPassStageGadget } from './Gadgets/AnnualPassStageGadget';
import { AnnualPassRenewalChartGadget } from './Gadgets/AnnualPassRenewalChartGadget';
import { CustomerReservationData, DateRange } from './type';

type Props = {
  data: CustomerReservationData[] | null;
  dateRange: DateRange;
  comparisonDateRange: DateRange | null;
};

export const GadgetList = ({ data, dateRange, comparisonDateRange }: Props) => {
  return (
    <div className={styles['c-gadgetList']}>
      <div className={styles['c-gadgetList__threeColumns']}>
        <PurchasedAnnualPassChartGadget data={data} />
        <AnnualPassRenewalChartGadget data={data} />
      </div>
      <div>
        <AnnualPassStageGadget
          data={data}
          dateRange={dateRange}
          comparisonDateRange={comparisonDateRange}
        />
      </div>
    </div>
  );
};
