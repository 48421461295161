import type { TranslateFuncType } from 'client/components/Translate';

export const getKnownFieldPrompt = (
  key: string,
  t: TranslateFuncType
): string => {
  switch (key) {
    case 'preferred_language_iso2':
      return t('Language');

    default:
      return '';
  }
};
