// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/Form';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Message } from 'client/components/Message/Message';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import type { EmailCustomizationEmailType } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';

import {
  getDefaultEmailSubject,
  getDefaultPerEmailMessage,
} from './FormValues';
import { PerEmailMessageEditorModal } from './PerEmailMessageEditorModal';
import { PerEmailSubjectEditorModal } from './PerEmailSubjectEditorModal';
import styles from './PerEmailMessageEditor.module.css';

const getTimingText = (
  emailType: EmailCustomizationEmailType,
  t: TranslateFuncType
): string => {
  switch (emailType) {
    case 'RESERVATION_AWAITING_CONFIRMATION':
      return t('After guest makes a new request booking');
    case 'RESERVATION_CONFIRMED':
      return t(
        'After guest makes a new instant confirmation booking & request booking is confirmed'
      );
    case 'RESERVATION_REJECTED':
      return t('After request booking is declined');
    case 'RESERVATION_WAITLISTED':
      return t('After request booking is put on standby');
    case 'RESERVATION_CHANGED':
      return t('After booking is changed');
    case 'RESERVATION_CANCELED_BY_SUPPLIER':
      return t('After booking is cancelled');
    case 'RESERVATION_REMINDER':
      return t('Automatically sent on the day before participation');
    case 'RESERVATION_UPDATED_BY_SUPPLIER':
      return t(
        'After booking info, such as pickup/check-in location & date, is updated'
      );
    case 'RESERVATION_CONFIRMED_PAYMENT_REQUIRED':
      return t('After new booking is created using email payment link.');
    case 'RESERVATION_CONFIRMED_PAYMENT_COMPLETE':
      return t('After payment is completed from the payment link email');
    case 'RESERVATION_CONFIRMED_LOTTERY':
      return t(
        'After guest makes a new instant confirmation booking & request booking (Lottery mode) is confirmed'
      );
    case 'RESERVATION_RECEIPT':
      return t(
        'After receipt is issued and send receipt via email button is pressed'
      );
    case 'RESERVATION_REJECTED_LOTTERY':
      return t('After request booking (Lottery mode) is declined');
    default:
      return '';
  }
};

type Props = {
  emailType: EmailCustomizationEmailType,
};

export const PerEmailMessageAndSubjectEditor = ({ emailType }: Props) => {
  const { t } = useTranslation();
  const [showMessageEditModal, setShowMessageEditModal] = React.useState(false);
  const [showSubjectEditModal, setShowSubjectEditModal] = React.useState(false);
  const [saveSucceeded, setSaveSucceeded] = React.useState(false);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    // Reset save succeeded to false if we open message edit modal
    if (showMessageEditModal) {
      setSaveSucceeded(false);
    }
  }, [showMessageEditModal]);

  React.useEffect(() => {
    // Reset save succeeded to false if we open subject edit modal
    if (showSubjectEditModal) {
      setSaveSucceeded(false);
    }
  }, [showSubjectEditModal]);

  const message =
    activeUserOrganization?.email_customization_settings?.per_email_custom_messages?.find(
      (msg) => msg.email_type === emailType
    )?.text ??
    getDefaultPerEmailMessage(
      activeUserOrganization?.name ?? '',
      emailType,
      t,
      defaultLanguage
    );

  const subject =
    activeUserOrganization?.email_customization_settings?.custom_email_subjects?.find(
      (msg) => msg.email_type === emailType
    )?.subject ?? getDefaultEmailSubject(emailType, t, defaultLanguage);

  return (
    <>
      {saveSucceeded && (
        <Message autoHide success header={t('Settings Saved')} />
      )}
      <Box mb={1}>
        <FieldWrapper label={t('Subject')}>
          <Box display="flex" alignItems="center">
            <div>{subject}</div>
            <Box ml={1}>
              <EditIcon onClick={() => setShowSubjectEditModal(true)} />
            </Box>
            {showSubjectEditModal && (
              <PerEmailSubjectEditorModal
                emailType={emailType}
                open={showSubjectEditModal}
                onClose={() => setShowSubjectEditModal(false)}
                onSaveSuccessful={() => setSaveSucceeded(true)}
              />
            )}
          </Box>
        </FieldWrapper>
      </Box>
      <Box mb={1}>
        <FieldWrapper label={t('Send Timing')}>
          {getTimingText(emailType, t)}
        </FieldWrapper>
      </Box>
      <FieldWrapper label={t('Fixed Message')}>
        <Box display="flex" alignItems="center">
          <div className={styles['fixed-message']}>{message}</div>
          <Box ml={1}>
            <EditIcon onClick={() => setShowMessageEditModal(true)} />
          </Box>
          {showMessageEditModal && (
            <PerEmailMessageEditorModal
              emailType={emailType}
              open={showMessageEditModal}
              onClose={() => setShowMessageEditModal(false)}
              onSaveSuccessful={() => setSaveSucceeded(true)}
            />
          )}
        </Box>
      </FieldWrapper>
    </>
  );
};
