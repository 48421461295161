import moment from 'moment-timezone';
import _ from 'lodash';

import type {
  ReservationReportDataSet,
  ReservationReportDataItem,
} from 'shared/models/swagger';

export type GraphDataItem = Record<string, string | number>;
export type AggregationType = 'TOTAL' | 'PRODUCT' | 'BOOKING_SOURCE';
export type TargetType =
  | 'AMOUNT_GROSS'
  | 'AMOUNT_NET'
  | 'NUM_OF_RESERVATIONS'
  | 'PAX';
export const aggregationTypes: AggregationType[] = [
  'TOTAL',
  'PRODUCT',
  'BOOKING_SOURCE',
];
export const targetTypes: TargetType[] = [
  'AMOUNT_GROSS',
  'AMOUNT_NET',
  'NUM_OF_RESERVATIONS',
  'PAX',
];
export const getTargetTypeText = (targetType: TargetType): string => {
  switch (targetType) {
    case 'AMOUNT_GROSS':
      return 'Gross';

    case 'AMOUNT_NET':
      return 'Net';

    case 'NUM_OF_RESERVATIONS':
      return 'Number of Reservations';

    case 'PAX':
    default:
      return 'Pax';
  }
};
export const convertReservationReportDataSetsToGraphData = (
  dataSets: ReservationReportDataSet[],
  aggregationType: AggregationType,
  targetType: TargetType,
  dateAggregation: 'DAILY' | 'WEEKLY' | 'MONTHLY' = 'DAILY'
): GraphDataItem[] => {
  const graphData: GraphDataItem[] = [];

  if (dataSets.length < 1 || 2 < dataSets.length) {
    return [];
  }

  if (dataSets.length === 1) {
    let start = dataSets[0].range?.start
      ? moment(dataSets[0].range.start)
      : moment();
    const end = dataSets[0].range?.end
      ? moment(dataSets[0].range.end)
      : moment();
    const fields = getFieldNames(dataSets[0], aggregationType);

    while (start.isSameOrBefore(end)) {
      graphData.push({
        name:
          dateAggregation === 'MONTHLY'
            ? start.format('YYYY-MM')
            : start.format('YYYY-MM-DD'),
        amountGross: 0,
        amountNet: 0,
        numOfReservations: 0,
        pax: 0,
        ...fields.reduce((obj, field) => {
          (obj as any)[field] = 0;
          return obj;
        }, {}),
      });
      if (dateAggregation === 'DAILY') {
        start = start.add(1, 'days');
      } else if (dateAggregation === 'WEEKLY') {
        start = start.add(1, 'weeks');
      } else if (dateAggregation === 'MONTHLY') {
        start = start.add(1, 'months');
      }
    }

    (dataSets[0].items || []).forEach((item) => {
      const graphDataItem = graphData.find((e) => {
        switch (dateAggregation) {
          case 'DAILY':
            return e.name === item.date;
          case 'WEEKLY':
            return (
              item.date &&
              e.name <= item.date &&
              moment(e.name).add(1, 'weeks').format('YYYY-MM-DD') > item.date
            );
          case 'MONTHLY':
            return (
              item.date &&
              e.name <= item.date &&
              moment(e.name).add(1, 'months').format('YYYY-MM-DD') > item.date
            );
        }
      });

      if (!graphDataItem) {
        return;
      }

      const fieldName = getFieldName(item, aggregationType);
      const fieldValue = getFieldValue(item, targetType);

      if (graphDataItem[fieldName]) {
        graphDataItem[fieldName] += fieldValue as any;
      } else {
        graphDataItem[fieldName] = fieldValue;
      }
    });
    return _.sortBy(graphData, (graphDataItem) => graphDataItem.name);
  } else {
    const duration = dataSets.reduce((acc, dataSet) => {
      const start = dataSet.range?.start
        ? moment(dataSet.range.start)
        : moment();
      const end = dataSet.range?.end ? moment(dataSet.range.end) : moment();
      const duration = end.diff(start, 'days') + 1;

      if (acc < duration) {
        return duration;
      }

      return acc;
    }, 0);

    for (let i = 0; i < duration; i++) {
      graphData.push({
        name: String(i),
        ...(dataSets.reduce((obj, dataSet, idx) => {
          (obj as any)[getCompareFieldName(dataSet, idx)] = 0;
          return obj;
        }, {}) as any),
      });
    }

    (dataSets || []).forEach((dataSet, idx) => {
      const fieldName = getCompareFieldName(dataSet, idx);
      const start = dataSet?.range?.start
        ? moment(dataSet.range.start)
        : moment();
      (dataSet.items || []).forEach((item) => {
        const offset = moment(item.date).diff(start, 'days');
        const graphDataItem = graphData.find((e) => e.name === String(offset));

        if (!graphDataItem) {
          return;
        }

        const fieldValue = getFieldValue(item, targetType);

        if (graphDataItem[fieldName]) {
          graphDataItem[fieldName] += fieldValue as any;
        } else {
          graphDataItem[fieldName] = fieldValue;
        }
      });
    });
    return _.sortBy(graphData, (graphDataItem) => Number(graphDataItem.name));
  }
};
export const getProductIds = (
  dataSets: ReservationReportDataSet[]
): string[] => {
  return Array.from(
    new Set(
      ((dataSets[0] || {}).items || []).map(
        (item) => item.product_id || 'NO PRODUCT'
      )
    )
  );
};
export const getBookingSourceIds = (
  dataSets: ReservationReportDataSet[]
): string[] => {
  return Array.from(
    new Set(
      ((dataSets[0] || {}).items || []).map(
        (item) =>
          item.booking_source?.agent_id ||
          item.booking_source?.source_type ||
          'NO BOOKING SOURCE'
      )
    )
  );
};

const getFieldNames = (
  dataSet: ReservationReportDataSet,
  aggregationType: AggregationType
): string[] => {
  return Array.from(
    new Set(
      (dataSet.items || []).map((item) => getFieldName(item, aggregationType))
    )
  );
};

const getFieldValue = (
  item: ReservationReportDataItem,
  targetType: TargetType
): number => {
  switch (targetType) {
    case 'AMOUNT_GROSS':
      return item.amount_gross || 0;

    case 'AMOUNT_NET':
      return item.amount_net || 0;

    case 'NUM_OF_RESERVATIONS':
      return 1;

    case 'PAX':
      return item.pax || 0;

    default:
      return 0;
  }
};

const getFieldName = (
  item: ReservationReportDataItem,
  aggregationType: AggregationType
): keyof GraphDataItem => {
  switch (aggregationType) {
    case 'PRODUCT':
      return item.product_id || 'NO PRODUCT';

    case 'BOOKING_SOURCE':
      return (
        item.booking_source?.agent_id ||
        item.booking_source?.source_type ||
        'NO BOOKING SOURCE'
      );

    case 'TOTAL':
    default:
      return 'total';
  }
};

export const convertReservationReportDataSetsToTableData = (
  dataSets: ReservationReportDataSet[],
  aggregationType: AggregationType,
  names: Record<string, string>
): GraphDataItem[] => {
  let graphData: GraphDataItem[] = [];

  if (dataSets.length < 1 || 2 < dataSets.length) {
    return [];
  }

  if (dataSets.length === 1) {
    if (aggregationType === 'TOTAL') {
      let start = dataSets[0].range?.start
        ? moment(dataSets[0].range.start)
        : moment();
      const end = dataSets[0].range?.end
        ? moment(dataSets[0].range.end)
        : moment();
      const fields = getFieldNames(dataSets[0], aggregationType);

      while (start.isSameOrBefore(end)) {
        graphData.push({
          name: start.format('YYYY-MM-DD'),
          amountGross: 0,
          amountNet: 0,
          numOfReservations: 0,
          pax: 0,
          ...fields.reduce((obj, field) => {
            (obj as any)[field] = 0;
            return obj;
          }, {}),
        });
        start = start.add(1, 'days');
      }

      (dataSets[0].items || []).forEach((item) => {
        const graphDataItem = graphData.find((e) => e.name === item.date);

        if (!graphDataItem) {
          return;
        }

        // for table
        graphDataItem.amountGross += (item.amount_gross || 0) as any;
        graphDataItem.amountNet += (item.amount_net || 0) as any;
        graphDataItem.numOfReservations += 1 as any;
        graphDataItem.pax += (item.pax || 0) as any;
      });
    } else {
      (dataSets[0].items || []).forEach((item) => {
        const fieldName = getFieldName(item, aggregationType);
        let graphDataItem = graphData.find((e) => e.name === fieldName);

        if (!graphDataItem) {
          graphDataItem = {
            name: fieldName,
            amountGross: item.amount_gross || 0,
            amountNet: item.amount_net || 0,
            numOfReservations: 1,
            pax: item.pax || 0,
          };
          graphData.push(graphDataItem);
        } else {
          graphDataItem.amountGross += (item.amount_gross || 0) as any;
          graphDataItem.amountNet += (item.amount_net || 0) as any;
          graphDataItem.numOfReservations += 1 as any;
          graphDataItem.pax += (item.pax || 0) as any;
        }
      });
      graphData = graphData.map((data) => {
        return { ...data, name: names[String(data.name)] || data.name };
      });
    }

    graphData = _.sortBy(graphData, (graphDataItem) => graphDataItem.name);
  } else {
    (dataSets || []).forEach((dataSet, idx) => {
      const fieldName = getCompareFieldName(dataSet, idx);
      const graphDataItem = {
        name: fieldName,
        amountGross: 0,
        amountNet: 0,
        numOfReservations: 0,
        pax: 0,
      };
      (dataSet.items || []).forEach((item) => {
        graphDataItem.amountGross += item.amount_gross || 0;
        graphDataItem.amountNet += item.amount_net || 0;
        graphDataItem.numOfReservations += 1;
        graphDataItem.pax += item.pax || 0;
      });
      graphData.push(graphDataItem);
    });
    graphData = graphData.map((data) => {
      return { ...data, name: names[String(data.name)] || data.name };
    });
  }

  return graphData;
};
export const getCompareFieldName = (
  dataSet: ReservationReportDataSet,
  idx: number
): string => {
  const start = dataSet.range?.start ? moment(dataSet.range.start) : moment();
  const end = dataSet.range?.end ? moment(dataSet.range.end) : moment();
  return `${idx + 1}.${start.format('YYYY-MM-DD')} - ${end.format(
    'YYYY-MM-DD'
  )}`;
};
export const formattedAmount = (
  amount: number | string | null,
  currencyCode: string | null
): string => {
  return Number(amount)
    .toLocaleString(undefined, {
      currency: currencyCode || 'USD',
      style: 'currency',
      currencyDisplay: 'code',
    })
    .slice(4);
};
