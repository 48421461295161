import { createSelector } from 'reselect';

import { allOrganizationsSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';

export const agentNameByIdSelector = createSelector(
  (state: ReduxState) => state.organizations.contracted,
  allOrganizationsSelector,
  (contractedOrganizations, allOrganizations) => {
    const agentOrgs = [
      ...contractedOrganizations,
      ...allOrganizations.filter((org) => org.type === 'AGENT'),
    ];
    const agentsById: Record<string, string> = {};

    for (const agent of agentOrgs) {
      agentsById[agent.id] = agent.name ?? '';
    }

    return agentsById;
  }
);

export const supplierNameByIdSelector = createSelector(
  (state: ReduxState) => state.organizations.contracted,
  allOrganizationsSelector,
  (contractedOrganizations, allOrganizations) => {
    const supplierOrgs = [
      ...contractedOrganizations,
      ...allOrganizations.filter((org) => org.type === 'SUPPLIER'),
    ];
    const suppliersById: Record<string, string> = {};

    for (const supplier of supplierOrgs) {
      suppliersById[supplier.id] = supplier.name ?? '';
    }

    return suppliersById;
  }
);
