import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { ReservationGuestTypeRedemptionTable } from 'client/components/ReservationGuestTypeRedemptionTable/ReservationGuestTypeRedemptionTable';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { Product, ProductSummary, Reservation } from 'shared/models/swagger';

interface Props {
  trigger: React.ReactElement<any>;
  reservation: Reservation | ManifestReservationShape | undefined;
  product: Product | ProductSummary | undefined;
}

export const ReservationGuestTypeRedemptionModal = ({
  trigger,
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setShowModal(true),
      })}
      <Modal
        title={t('Checkin')}
        open={showModal}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
        width={'wide'}
        insertRoot
      >
        <Modal.Content>
          <ReservationGuestTypeRedemptionTable
            reservation={reservation}
            product={product}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
