import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { AgentReferencesEditor } from 'client/components/NewProductEditor/AgentReferencesEditor/AgentReferencesEditor';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { PerParticipantPriceInput } from 'client/components/NewProductEditor/PerParticipantPriceInput/PerParticipantPriceInput';
import { PerBookingPriceInput } from 'client/components/NewProductEditor/PerBookingPriceInput/PerBookingPriceInput';
import { PerGroupPriceInput } from 'client/components/NewProductEditor/PerGroupPriceInput/PerGroupPriceInput';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { SingleProductImageInput } from 'client/components/NewProductEditor/DetailsStep/SingleProductImageInput';
import { Input, TextArea, ToggleButton } from 'client/components/Form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getValidators } from 'shared/libraries/validate/validator';
import type { TranslateFuncType } from 'client/components/Translate';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import * as Swagger from 'shared/models/swagger';

import styles from '../DetailsStep.module.css';

const getDefaultPerParticipantPrices = (
  product: Swagger.Product | null,
  t: TranslateFuncType,
  language: string
) => {
  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units && units.some((unit) => unit.method === 'PER_PARTICIPANT')) {
    return units
      .filter((unit) => unit.method === 'PER_PARTICIPANT')
      .map((unit) => ({
        method: unit.method,
        unit: unit?.guest_type?.key ?? '',
        perAgentNetPrices: [],
        ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
        ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
        price: '100',
        netPrice: '100',
      }));
  }

  const perBookingGuestTypes = units
    ? units.find(
        (unit) =>
          unit.method === 'PER_BOOKING' &&
          (unit.per_booking_guest_types ?? []).length > 0
      )?.per_booking_guest_types
    : null;

  if (perBookingGuestTypes && perBookingGuestTypes.length > 0) {
    return perBookingGuestTypes.map((guestType) => ({
      method: 'PER_PARTICIPANT',
      unit: guestType?.key ?? '',
      perAgentNetPrices: [],
      ageBandMinimum: guestType?.minimum_age ?? 0,
      ageBandMaximum: guestType?.maximum_age ?? 0,
      price: '100',
      netPrice: '100',
    }));
  }

  return [
    {
      method: 'PER_PARTICIPANT',
      unit: t('Adult', {
        lng: language,
      }),
      ageBandMinimum: 13,
      ageBandMaximum: 0,
      price: '100',
      netPrice: '70',
      perAgentNetPrices: [],
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Child', {
        lng: language,
      }),
      ageBandMinimum: 4,
      ageBandMaximum: 12,
      price: '70',
      netPrice: '70',
      perAgentNetPrices: [],
    },
    {
      method: 'PER_PARTICIPANT',
      unit: t('Infant', {
        lng: language,
      }),
      ageBandMinimum: 0,
      ageBandMaximum: 3,
      price: '0',
      netPrice: '0',
      perAgentNetPrices: [],
    },
  ];
};

const getDefaultPerBookingPrices = () => {
  return [
    {
      method: 'PER_BOOKING',
      price: '100',
      netPrice: '100',
      // need to add the following props to use _.Equal
      ageBandMinimum: 0,
      ageBandMaximum: 0,
      unit: '',
      perAgentNetPrices: [],
    },
  ];
};

const getDefaultService = (product: Swagger.Product | null) => {
  const units =
    (product?.pricing &&
      product.pricing.length > 0 &&
      product.pricing[0].units &&
      product.pricing[0].units.length > 0 &&
      product.pricing[0].units) ??
    null;

  if (units) {
    return {
      title: '',
      description: '',
      priceType: units[0].method,
      prices: units
        .filter((unit) => unit.method === units[0].method)
        .map((unit) => ({
          method: unit.method,
          unit: unit?.guest_type?.key ?? '',
          ageBandMinimum: unit?.guest_type?.minimum_age ?? 0,
          ageBandMaximum: unit?.guest_type?.maximum_age ?? 0,
          price: '100',
          netPrice: '100',
          perAgentNetPrices: [],
        })),
      perGroupPrices: [],
      image: '',
      isPopular: false,
      isRecommended: false,
    };
  }

  return {
    title: '',
    description: '',
    priceType: 'FREE',
    prices: [],
    image: '',
  };
};

type ServiceInputProps = {
  name: string;
  editingProduct: Swagger.Product | null;
  isAddOnsEditor: boolean;
};

const ServiceInput = ({
  name,
  editingProduct,
  isAddOnsEditor,
}: ServiceInputProps) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const form = useForm();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const isPMP = Boolean(useSelector(privateMarketplaceSelector));
  const language =
    uppercaseIsoToLowercaseIso[editingProduct?.source_language || 'JA_JP'];
  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');
  const showNet = location.pathname.includes('edit');
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  return (
    <ul className={styles['scheds']}>
      <li className={styles['scheds__cell']}>
        <TranslatedField name={`${name}.title`} validate={required}>
          {({ input, translationInput, meta: { error, touched } }) => (
            <>
              <div>
                <p className={styles['scheds__ttl']}>{t('Title')}</p>
                <p className={styles['scheds__body']}>
                  <Input {...input} maxWidth={800} error={touched && error} />
                </p>
              </div>
              {translationTargetLanguage && (
                <div>
                  <p className={styles['scheds__ttl']}>{`${t(
                    'Title'
                  )} (${translationTargetLanguageName})`}</p>
                  <p className={styles['scheds__body']}>
                    <Input
                      {...translationInput}
                      maxWidth={800}
                      error={touched && error}
                    />
                  </p>
                </div>
              )}
            </>
          )}
        </TranslatedField>
      </li>
      <li className={styles['scheds__cell']}>
        <TranslatedField name={`${name}.description`}>
          {({ input, translationInput }) => (
            <>
              <div>
                <p className={styles['scheds__ttl']}>{t('Description')}</p>
                <p className={styles['scheds__body']}>
                  <TextArea {...(input as any)} maxWidth={800} height={80} />
                </p>
              </div>
              {translationTargetLanguage && (
                <div>
                  <p className={styles['scheds__ttl']}>{`${t(
                    'Description'
                  )} (${translationTargetLanguageName})`}</p>
                  <p className={styles['scheds__body']}>
                    <TextArea
                      {...(translationInput as any)}
                      maxWidth={800}
                      height={80}
                    />
                  </p>
                </div>
              )}
            </>
          )}
        </TranslatedField>
      </li>
      <li className={styles['borderTop']}>
        <EnumRadioButtonGroup
          name={`${name}.priceType`}
          options={[
            {
              value: 'PER_PARTICIPANT',
              label: t('Per-participant'),
              tooltipText: t(
                'Used when setting the price for each participant (Price setting per person)'
              ),
            },
            {
              value: 'PER_BOOKING',
              label: t('Per-booking'),
              tooltipText: t(
                'Used when setting the price for each reservation (Price setting per reservation)'
              ),
            },
            {
              value: 'FREE',
              label: t('Free'),
              tooltipText: t('Used when provided for free'),
            },
          ]}
          onChange={(newValue: string) => {
            switch (newValue) {
              case 'PER_PARTICIPANT':
                form.change(
                  `${name}.prices`,
                  getDefaultPerParticipantPrices(editingProduct, t, language)
                );
                break;

              case 'PER_BOOKING':
                form.change(`${name}.prices`, getDefaultPerBookingPrices());
                break;

              default:
                form.change(`${name}.prices`, []);
                break;
            }
          }}
        />
        <Field name={`${name}.priceType`}>
          {({ input: { value } }) => (
            <>
              {value === 'PER_PARTICIPANT' && (
                <>
                  <PerParticipantPriceInput
                    name={`${name}.prices`}
                    language={language}
                    currency={defaultCurrency}
                    showNet={showNet}
                    showCustom={isProductEditOrProductCopy}
                    showPerGroup={isProductEditOrProductCopy}
                  />
                  {isProductEditOrProductCopy && (
                    <PerGroupPriceInput
                      name={`${name}.perGroupPrices`}
                      currency={defaultCurrency}
                      showNet={showNet}
                    />
                  )}
                </>
              )}
              {value === 'PER_BOOKING' && (
                <PerBookingPriceInput
                  name={`${name}.prices`}
                  language={language}
                  currency={defaultCurrency}
                  showNet={showNet}
                />
              )}
            </>
          )}
        </Field>
      </li>
      {isPMP && isAddOnsEditor && (
        <>
          <li>
            <div>
              <p className="scheds__ttl">{t('Image')}</p>
              <Field name={`${name}.image`}>
                {({ input }) => (
                  <SingleProductImageInput
                    imageUrl={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <p className={styles['c-table-list__note']}>
                {t('* jpg/png format')}
              </p>
            </div>
          </li>
          <li>
            <div>
              <Field name={`${name}.isPopular`}>
                {({ input }) => (
                  <ToggleButton
                    label={t(
                      'This is a POPULAR add-on in the privated marketplace.'
                    )}
                    checked={input.value}
                    onChange={() => input.onChange(!input.value)}
                  />
                )}
              </Field>
            </div>
          </li>
          <li>
            <div>
              <Field name={`${name}.isRecommended`}>
                {({ input }) => (
                  <ToggleButton
                    label={t(
                      'This is a RECOMMENDED add-on in the privated marketplace.'
                    )}
                    checked={input.value}
                    onChange={() => input.onChange(!input.value)}
                  />
                )}
              </Field>
            </div>
          </li>
        </>
      )}
      {isNutmegAdmin && (
        <li>
          <div>
            <p className="scheds__ttl">{t('Agent References')}</p>
            <AgentReferencesEditor name={`${name}.referencesInAgentSystems`} />
          </div>
        </li>
      )}
    </ul>
  );
};

type ServicesEditorProps = {
  name: 'addOns' | 'transportations';
  getItemLabel: (idx: number) => string;
};
export const ServicesEditor = ({ name, getItemLabel }: ServicesEditorProps) => {
  const editingProduct = React.useContext(EditingProductContext);
  const isAddOnsEditor = name === 'addOns';
  return (
    <div className={styles['acBody']}>
      <FieldArray name={name}>
        {({ fields }) =>
          fields.length === 0 ? (
            <>
              <AddIcon
                onClick={() =>
                  (fields as any).insertAt(0, getDefaultService(editingProduct))
                }
              />
            </>
          ) : (
            <div className={styles['c-table-list']}>
              <table>
                {fields.map((name, idx) => (
                  <tr key={name}>
                    <th>{getItemLabel(idx + 1)}</th>
                    <td colSpan={2}>
                      <ServiceInput
                        name={name}
                        editingProduct={editingProduct}
                        isAddOnsEditor={isAddOnsEditor}
                      />
                    </td>
                    <td className={styles['c-table-list__btns']}>
                      <AddIcon
                        onClick={() =>
                          (fields as any).insertAt(
                            idx + 1,
                            getDefaultService(editingProduct)
                          )
                        }
                      />
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )
        }
      </FieldArray>
    </div>
  );
};
