import * as React from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { DeadlineInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DeadlineInput';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';
import deleteIcon from 'client/images/ic_delete.svg';
import addIcon from 'client/images/ic_add.svg';

import { Timeline } from './Timeline';

interface Props {
  name: string;
}

export const StartTimeRelativeLimitsEditor = ({ name }: Props) => {
  const form = useForm();
  const values = _.get(form.getState().values, name);
  const { t } = useTranslation();

  return (
    <div>
      <Box mt={1}>
        <div className={styles['page-productsRegist__priceMain__ttl']}>
          {t('Active Period')}
        </div>
        <div>
          <Timeline
            fromRelativeDeadline={values?.appliesFromParticipationRelativeDate}
            toRelativeDeadline={values?.appliesUntilParticipationRelativeDate}
          />
          <div className={styles['page-productsRegist__reserve']}>
            <div className={styles['page-productsRegist__reserve__item']}>
              <p>{t('Apply From')}</p>
              {values?.appliesFromParticipationRelativeDate && (
                <div>
                  <DeadlineInput
                    name={`${name}.appliesFromParticipationRelativeDate`}
                  />
                  <div className={baseStyles['base-flex']}>
                    {values?.appliesFromParticipationRelativeDate && (
                      <a
                        onClick={() =>
                          form.change(
                            `${name}.appliesFromParticipationRelativeDate`,
                            null
                          )
                        }
                      >
                        <img src={deleteIcon} />
                      </a>
                    )}
                  </div>
                </div>
              )}
              {!values?.appliesFromParticipationRelativeDate && (
                <div className={baseStyles['base-flex']}>
                  {
                    <a
                      onClick={() =>
                        form.change(
                          `${name}.appliesFromParticipationRelativeDate`,
                          {
                            deadlineType: 'DAY',
                            daysBefore: 2,
                            timeOfDay: '17:00',
                          }
                        )
                      }
                    >
                      <img src={addIcon} />
                    </a>
                  }
                </div>
              )}
            </div>
            <div className={styles['page-productsRegist__reserve__item']}>
              <p>{t('Apply Until')}</p>
              {values?.appliesUntilParticipationRelativeDate && (
                <div>
                  <DeadlineInput
                    name={`${name}.appliesUntilParticipationRelativeDate`}
                  />
                  <div className={baseStyles['base-flex']}>
                    {values?.appliesUntilParticipationRelativeDate && (
                      <a
                        onClick={() =>
                          form.change(
                            `${name}.appliesUntilParticipationRelativeDate`,
                            null
                          )
                        }
                      >
                        <img src={deleteIcon} />
                      </a>
                    )}
                  </div>
                </div>
              )}
              {!values?.appliesUntilParticipationRelativeDate && (
                <a
                  onClick={() =>
                    form.change(
                      `${name}.appliesUntilParticipationRelativeDate`,
                      {
                        deadlineType: 'DAY',
                        daysBefore: 1,
                        timeOfDay: '17:00',
                      }
                    )
                  }
                >
                  <img src={addIcon} />
                </a>
              )}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};
