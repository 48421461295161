// @flow

import * as React from 'react';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { TranslatorProps } from 'react-i18next';

import { deleteTask } from 'client/actions/tasks';
import { ConfirmReservationButton } from 'client/components/ConfirmReservationButton';
import { StandbyReservationButton } from 'client/components/StandbyReservationButton';
import { DeclineReservationButton } from 'client/components/DeclineReservationButton';
import { EditFieldResponseButton } from 'client/components/EditFieldResponseButton';
import { CheckinEditButton } from 'client/pages/ReservationDetails/CheckinEditButton';
import { PickupDropoffEditButton } from 'client/pages/ReservationDetails/PickupDropoffEditButton';
import type { TaskShape } from 'client/pages/Dash/util';

type OwnProps = {
  task: TaskShape,
  onTaskChange: () => void,
  stack?: boolean,
  size?:
    | 'mini'
    | 'tiny'
    | 'small'
    | 'medium'
    | 'large'
    | 'big'
    | 'huge'
    | 'massive',
  floated?: 'left' | 'right',
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  modalID: string,
};

class ActionButtonsComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalID: '',
    };
  }

  render() {
    const { floated, onTaskChange, size, stack, task, t } = this.props;
    const { modalID } = this.state;

    const buttonSize = size || 'tiny';

    if (task.category === 'INFO_REQUIRED') {
      return (
        <EditFieldResponseButton
          required={true}
          onUpdate={onTaskChange}
          reservationID={task.reservationId}
        />
      );
    } else if (task.category === 'PICKUP_DROPOFF_INFO_TBD') {
      if (task.isCheckinCheckoutOnly) {
        return (
          <CheckinEditButton
            trigger={
              <Button icon floated={floated} basic size={buttonSize}>
                <Icon name="edit" color="orange" />
                {t('Edit Checkin')}
              </Button>
            }
            reservationID={task.reservationId}
            onUpdateCompleted={onTaskChange}
          />
        );
      } else {
        return (
          <PickupDropoffEditButton
            trigger={
              <Button icon floated={floated} basic size={buttonSize}>
                <Icon name="edit" color="orange" />
                {t('Edit Pickup/Dropoff')}
              </Button>
            }
            reservationID={task.reservationId}
            onUpdateCompleted={onTaskChange}
          />
        );
      }
    } else {
      switch (task.reservationStatus) {
        case 'REQUESTED':
          return (
            <Button.Group vertical={stack} size={buttonSize} basic>
              <ConfirmReservationButton
                reservationID={task.reservationId}
                onConfirmCompleted={onTaskChange}
              />
              <StandbyReservationButton
                reservationID={task.reservationId}
                onStandbyCompleted={onTaskChange}
              />
              <DeclineReservationButton
                reservationID={task.reservationId}
                onDeclineCompleted={onTaskChange}
              />
            </Button.Group>
          );
        default:
          return (
            <>
              <Confirm
                className="fix-semantic-modal"
                trigger={
                  <Button
                    floated={floated}
                    basic
                    size={buttonSize}
                    onClick={() =>
                      this.setState({
                        modalID: task.id,
                      })
                    }
                  >
                    {t('Dismiss')}
                  </Button>
                }
                header={t('Dismiss')}
                cancelButton={t('No')}
                confirmButton={t('OK')}
                content={t('Are you sure?')}
                open={modalID === task.id}
                onCancel={() => {
                  this.setState({
                    modalID: '',
                  });
                }}
                onConfirm={() => {
                  this.setState({
                    modalID: '',
                  });
                  this.props.onDismissTask(task.id).then(onTaskChange);
                }}
              />
            </>
          );
      }
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  onDismissTask: (id: string) => dispatch(deleteTask(id)),
});

export const ActionButtons = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(ActionButtonsComponent);
