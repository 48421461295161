import * as React from 'react';
import clsx from 'clsx';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

export type TabPane = {
  header: string;
  content: React.ComponentType<any>;
};
interface Props {
  panes: TabPane[];
  // Pass both properties below to use Tabs as controlled component
  activeIndex?: number | null;
  onActiveIndexChange?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const Tabs = ({
  panes,
  activeIndex: index = null,
  onActiveIndexChange,
}: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (index != null) {
      setActiveIndex(index);
    }
  }, [index]);

  return (
    <div
      className={clsx(
        componentStyles['c-tab-box'],
        baseStyles['scroll-target-pane']
      )}
    >
      <ul className={componentStyles['c-tab-box__tab']}>
        {panes.map((pane, idx) => {
          return (
            <li
              key={pane.header}
              className={clsx(
                idx === activeIndex && componentStyles['is-active']
              )}
              onClick={() => {
                onActiveIndexChange
                  ? onActiveIndexChange(idx)
                  : setActiveIndex(idx);
              }}
            >
              <a>{pane.header}</a>
            </li>
          );
        })}
      </ul>
      {panes.map((pane, idx) => {
        const Content = pane.content;
        return idx === activeIndex ? <Content key={idx} /> : null;
      })}
    </div>
  );
};
