import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Button } from 'client/components/Form';
import {
  fetchETicketLocations,
  createETicketLocation,
} from 'client/actions/eTicketLocation';
import {
  fetchETicketLocationSets,
  deleteETicketLocationSet,
  createETicketLocationSet,
} from 'client/actions/eTicketLocationSet';
import { ReduxState } from 'client/reducers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { ConfirmModal } from 'client/components/ConfirmModal/ConfirmModal';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import copyIcon from 'client/images/ic_copy.svg';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import { ETicketLocationSet } from 'shared/models/swagger';

import { EditETicketLocationSetModal } from './EditETicketLocationSetModal';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const ProductsCell = ({ productIds }: { productIds: string[] }) => {
  const products = useSelector((state: ReduxState) => state.products.summaries);

  return (
    <div>
      <ul>
        {productIds.map((productId) => {
          const product = products.find((p) => p.id === productId);
          return product ? (
            <li key={productId}>{getDisplayProductName(product)}</li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

const ControlCell = ({
  eTicketLocationSet,
  onEditETicketLocationSetChange,
}: {
  eTicketLocationSet: ETicketLocationSet;
  onEditETicketLocationSetChange: (
    eTicketLocationSet: ETicketLocationSet | null
  ) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showCopyModal, setShowCopyModal] = React.useState(false);

  const locations = useSelector(
    (state: ReduxState) => state.eTicketLocations.all
  );

  return (
    <Box display="flex" alignItems="center">
      <Box ml={2}>
        <button
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['icon'],
            baseStyles['narrow']
          )}
          onClick={() => onEditETicketLocationSetChange(eTicketLocationSet)}
        >
          <img src={editIcon} />
        </button>
      </Box>

      <Box ml={2}>
        <button
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['icon'],
            baseStyles['narrow']
          )}
          onClick={() => setShowDeleteModal(true)}
        >
          <img src={deleteIcon} />
        </button>
      </Box>

      <Box ml={2}>
        <button
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['icon'],
            baseStyles['narrow']
          )}
          onClick={() => setShowCopyModal(true)}
        >
          <img src={copyIcon} />
        </button>
      </Box>

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete Location Set')}
          content={t('Are you sure you want to delete location set?')}
          onConfirm={async () => {
            await dispatch(
              deleteETicketLocationSet(eTicketLocationSet.id ?? '')
            );
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      {showCopyModal && (
        <ConfirmModal
          header={t('Copy Location Set')}
          content={t('Are you sure you want to copy {{locationSetName}}?', {
            locationSetName: eTicketLocationSet.location_set_name,
          })}
          onConfirm={async () => {
            try {
              const newETicketLocationSet = await dispatch(
                createETicketLocationSet({
                  ...eTicketLocationSet,
                  location_set_name: `[copy] ${eTicketLocationSet.location_set_name}`,
                  product_ids: [],
                })
              );
              locations.forEach((location) => {
                if (
                  location.e_ticket_location_set_id === eTicketLocationSet.id
                ) {
                  const fn = async () => {
                    try {
                      await dispatch(
                        createETicketLocation({
                          ...location,
                          e_ticket_location_set_id: (
                            newETicketLocationSet as ETicketLocationSet
                          ).id,
                        })
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  };
                  fn();
                }
              });
              onEditETicketLocationSetChange(
                newETicketLocationSet as ETicketLocationSet
              );
            } catch (e) {
              console.error(e);
            }
          }}
          onClose={() => setShowCopyModal(false)}
          open={showCopyModal}
          insertRoot={true}
        />
      )}
    </Box>
  );
};

const useColumns = ({
  onEditETicketLocationSetChange,
}: {
  onEditETicketLocationSetChange: (
    eTicketLocationSet: ETicketLocationSet | null
  ) => void;
}): ColumnType<ETicketLocationSet>[] => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(activeUser, 'E_TICKET.LOCATIONS')
      ? ([
          {
            Header: '',
            th: true,
            width: 'short',
            Cell: (cellInfo) => (
              <ControlCell
                eTicketLocationSet={cellInfo.original}
                onEditETicketLocationSetChange={onEditETicketLocationSetChange}
              />
            ),
          },
        ] as ColumnType<ETicketLocationSet>[])
      : []),
    {
      Header: t('Title'),
      accessor: 'location_set_name',
    },
    {
      Header: t('Description'),
      accessor: 'location_set_description',
    },
    {
      Header: t('Products'),
      width: 'long',
      Cell: (cellInfo) => (
        <ProductsCell productIds={cellInfo.original.product_ids ?? []} />
      ),
    },
  ];
};

export const ETicketLocationList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEditLocationSetModal, setShowEditLocationSetModal] =
    React.useState(false);
  const [editETicketLocationSet, setEditETicketLocationSet] =
    React.useState<ETicketLocationSet | null>(null);

  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    dispatch(fetchETicketLocations());
    dispatch(fetchETicketLocationSets());
  }, []);

  const columns = useColumns({
    onEditETicketLocationSetChange: setEditETicketLocationSet,
  });

  const eTicketLocationSets = useSelector(
    (state: ReduxState) => state.eTicketLocationSets.all
  );

  return (
    <div>
      <Box mb={2}>
        {hasCustomUserRoleWritePermissions(
          activeUser,
          'E_TICKET.LOCATIONS'
        ) && (
          <Button
            size="middle"
            style="green"
            onClick={() => setShowEditLocationSetModal(true)}
          >
            {t('Create New E-ticket Location Set')}
          </Button>
        )}
      </Box>

      <CustomTable columns={columns} items={eTicketLocationSets} />

      {(showEditLocationSetModal || !!editETicketLocationSet) && (
        <EditETicketLocationSetModal
          existingETicketLocationSet={editETicketLocationSet}
          open={showEditLocationSetModal || !!editETicketLocationSet}
          onClose={() => {
            setShowEditLocationSetModal(false);
            setEditETicketLocationSet(null);
          }}
        />
      )}
    </div>
  );
};
