import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { Moment } from 'moment-timezone';

import {
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASK_COUNTS_FAILURE,
  FETCH_TASK_COUNTS_REQUEST,
  FETCH_TASK_COUNTS_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_TASKS_CREATED_AT_FROM,
  SET_TASKS_CREATED_AT_TO,
  SET_TASKS_DUE_DATE_FROM,
  SET_TASKS_DUE_DATE_TO,
  SET_TASKS_FILTERS,
  SET_TASKS_IS_ASCENDING,
  SET_TASKS_ORDER_BY,
  SET_TASKS_PAGE_SIZE,
  UPDATE_TASKS_PAGE_TOKENS,
} from 'client/constants/ActionTypes';
import { activeUserSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import type {
  GetTaskCountsResponse,
  ReservationStatus,
  Task,
  TaskCategory,
} from 'shared/models/swagger';

export type ActionItemCategory =
  | 'TOTAL'
  | 'REQUESTED'
  | 'CONFIRMED'
  | 'STANDBY'
  | 'DECLINED'
  | 'WITHDRAWN'
  | 'CANCELED'
  | 'PICKUP_DROPOFF_TBD'
  | 'PICKUP_INFO_UPDATED'
  | 'RESERVATION_SEEN';

const byIDSelector = (state: ReduxState) => state.tasks.byID;

const idsSelector = (state: ReduxState) => state.tasks.ids;

export const tasksSelector = createSelector(
  byIDSelector,
  idsSelector,
  (byID, ids) => ids.map((id: string) => byID[id])
);

const filtersSelector = (state: ReduxState) => state.tasks.filters;

export const taskFilterQueryParamSelector = createSelector(
  filtersSelector,
  activeUserSelector,
  (filters, activeUser) => {
    const isSupplier = activeUser?.organization_type === 'SUPPLIER';
    const actionItemFilter = filters.find((f: any) => f.id === 'action_item');

    if (actionItemFilter) {
      return getQueryParamsForActionItemCategory(
        actionItemFilter.value,
        isSupplier
      );
    }

    return {};
  }
);

const getQueryParamsForActionItemCategory = (
  category: ActionItemCategory,
  isSupplier: boolean
): {
  category?: TaskCategory;
  reservation_status?: ReservationStatus[];
} => {
  switch (category) {
    case 'REQUESTED':
      return {
        category: 'RESERVATION_STATUS_UPDATED',
        reservation_status: ['REQUESTED'],
      };

    case 'STANDBY':
      return {
        category: 'RESERVATION_STATUS_UPDATED',
        reservation_status: ['STANDBY'],
      };

    case 'DECLINED':
      return {
        category: 'RESERVATION_STATUS_UPDATED',
        reservation_status: ['DECLINED_BY_SUPPLIER'],
      };

    case 'WITHDRAWN':
      return {
        category: 'RESERVATION_STATUS_UPDATED',
        reservation_status: ['WITHDRAWN_BY_AGENT'],
      };

    case 'CONFIRMED':
      return {
        category: 'RESERVATION_STATUS_UPDATED',
        reservation_status: ['CONFIRMED'],
      };

    case 'CANCELED':
      if (isSupplier) {
        return {
          category: 'RESERVATION_STATUS_UPDATED',
          reservation_status: ['CANCELED_BY_AGENT', 'CANCELED_BY_GUEST'],
        };
      } else {
        return {
          category: 'RESERVATION_STATUS_UPDATED',
          reservation_status: ['CANCELED_BY_SUPPLIER'],
        };
      }

    case 'PICKUP_DROPOFF_TBD':
      return {
        category: 'PICKUP_DROPOFF_INFO_TBD',
      };

    case 'PICKUP_INFO_UPDATED':
      return {
        category: 'PICKUP_INFORMATION_UPDATED',
      };

    case 'RESERVATION_SEEN':
      return {
        category: 'RESERVATION_STATUS_SEEN',
      };

    default:
      return {};
  }
};

// Reducers
function updater<T>(
  state: T,
  action: any,
  successType: string,
  successItem: T
): T {
  if (action.type === successType) {
    return successItem;
  } else {
    return state;
  }
}

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_TASKS_REQUEST:
      return true;

    case FETCH_TASKS_FAILURE:
    case FETCH_TASKS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any): Record<string, any> =>
  updater<Record<string, any>>(
    state,
    action,
    FETCH_TASKS_FAILURE,
    action.error
  );

const byID = (state = {}, action: any): Record<string, Task> => {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS: {
      const result: Record<string, Task> = {};
      const tasks = action.response.tasks;
      tasks &&
        tasks.forEach((p: Task) => {
          result[p.id] = p;
        });
      return result;
    }
    default:
      return state;
  }
};

const ids = (state: string[] = [], action: any): string[] => {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS: {
      const tasks = action.response.tasks;
      return [...tasks.map((p: Task) => p.id)];
    }
    default:
      return state;
  }
};

// NOTE: Not currently being used
const createdAtFrom = (
  state: Moment | string = '',
  action: any
): Moment | string =>
  updater<Moment | string>(
    state,
    action,
    SET_TASKS_CREATED_AT_FROM,
    action.createdAtFrom
  );

// NOTE: Not currently being used
const createdAtTo = (
  state: Moment | string = '',
  action: any
): Moment | string =>
  updater<Moment | string>(
    state,
    action,
    SET_TASKS_CREATED_AT_TO,
    action.createdAtTo
  );

// NOTE: Not currently being used
const dueDateFrom = (
  state: Moment | string = '',
  action: any
): Moment | string =>
  updater<Moment | string>(
    state,
    action,
    SET_TASKS_DUE_DATE_FROM,
    action.dueDateFrom
  );

// NOTE: Not currently being used
const dueDateTo = (state: Moment | string = '', action: any): Moment | string =>
  updater<Moment | string>(
    state,
    action,
    SET_TASKS_DUE_DATE_TO,
    action.dueDateTo
  );

// NOTE: Not currently being used
const isAscending = (state = true, action: any): boolean =>
  updater<boolean>(state, action, SET_TASKS_IS_ASCENDING, action.isAscending);

// NOTE: Not currently being used
const orderBy = (state = 'due_date', action: any): string =>
  updater<string>(state, action, SET_TASKS_ORDER_BY, action.orderBy);

const pageSize = (state = 14, action: any): number =>
  updater<number>(state, action, SET_TASKS_PAGE_SIZE, action.pageSize);

const filters = (
  state: Record<string, any>[] = [],
  action: any
): Record<string, any>[] => {
  switch (action.type) {
    case SET_TASKS_FILTERS:
      return action.filters;

    default:
      return state;
  }
};

const pageTokens = (
  state: PageTokens = {
    allTokens: {
      '': {
        previous: null,
        next: null,
      },
    },
    currToken: '',
    indexToToken: [''],
  },
  action: any
): {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
} => {
  switch (action.type) {
    case SET_TASKS_PAGE_SIZE:
    case SET_TASKS_ORDER_BY:
    case SET_TASKS_IS_ASCENDING:
    case SET_TASKS_DUE_DATE_TO:
    case SET_TASKS_DUE_DATE_FROM:
    case SET_TASKS_CREATED_AT_TO:
    case SET_TASKS_CREATED_AT_FROM:
    case SET_TASKS_FILTERS:
      return {
        allTokens: {
          '': {
            previous: null,
            next: null,
          },
        },
        currToken: '',
        indexToToken: [''],
      };

    case UPDATE_TASKS_PAGE_TOKENS:
    case FETCH_TASKS_SUCCESS:
      if (action.pageTokens) {
        return action.pageTokens;
      } else {
        return {
          allTokens: {
            '': {
              previous: null,
              next: null,
            },
          },
          currToken: '',
          indexToToken: [''],
        };
      }

    default:
      return state;
  }
};

const loadingTaskCounts = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_TASK_COUNTS_REQUEST:
      return true;

    case FETCH_TASK_COUNTS_FAILURE:
    case FETCH_TASK_COUNTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const taskCounts = (
  state: GetTaskCountsResponse = {
    total: 0,
  },
  action: any
): GetTaskCountsResponse => {
  switch (action.type) {
    case FETCH_TASK_COUNTS_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

type PageTokens = {
  allTokens: Record<
    string,
    {
      previous: string | null;
      next: string | null;
    }
  >;
  currToken: string;
  indexToToken: string[];
};

type TaskState = {
  byID: { [id: string]: Task };
  createdAtFrom: Moment | string;
  createdAtTo: Moment | string;
  dueDateFrom: Moment | string;
  dueDateTo: Moment | string;
  error: any;
  filters: any[];
  ids: string[];
  isAscending: boolean;
  loading: boolean;
  loadingTaskCounts: boolean;
  orderBy: string;
  pageSize: number;
  pageTokens: PageTokens;
  taskCounts: GetTaskCountsResponse;
};

const reducer = combineReducers<TaskState>({
  byID,
  createdAtFrom,
  createdAtTo,
  dueDateFrom,
  dueDateTo,
  error,
  filters,
  ids,
  isAscending,
  loading,
  loadingTaskCounts,
  orderBy,
  pageSize,
  pageTokens,
  taskCounts,
});

export const tasks = (state: TaskState, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
