import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { FieldWrapper, Input, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Add as AddIcon } from 'client/components/Icons/Add';

import styles from './InquiryMessageTemplatesEditor.module.css';

export const InquiryMessageTemplatesEditor = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FieldWrapper label={t('Email/SMS Message Templates')} />
      <FieldArray name="messageTemplates">
        {({ fields }) => (
          <>
            {fields.length === 0 && (
              <AddIcon
                onClick={() =>
                  (fields as any).insertAt(0, {
                    name: '',
                    emailSubject: '',
                    message: '',
                  })
                }
              />
            )}
            <table>
              <tbody>
                {fields.map((name, idx) => (
                  <tr key={name}>
                    <td>
                      <Box mb={2}>
                        {t('Template #{{idx}}', {
                          idx: idx + 1,
                        })}
                      </Box>
                      <Box width="100%">
                        <Field name={`${name}.name`}>
                          {({ input }) => (
                            <Input label={t('Nickname')} {...input} />
                          )}
                        </Field>
                        <Field name={`${name}.emailSubject`}>
                          {({ input }) => (
                            <Box mt={2} mb={2}>
                              <Input
                                label={t('Email Subject (optional)')}
                                {...input}
                              />
                            </Box>
                          )}
                        </Field>
                        <Field name={`${name}.message`}>
                          {({ input }) => (
                            <Box mt={2} mb={2}>
                              <TextArea
                                label={t('Message')}
                                value={input.value}
                                onChange={input.onChange}
                              />
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </td>
                    <td className={styles['add-delete-buttons-container']}>
                      <Box display="flex" ml={1}>
                        <AddIcon
                          onClick={() =>
                            (fields as any).insertAt(idx + 1, {
                              name: '',
                              emailSubject: '',
                              message: '',
                            })
                          }
                        />
                        <DeleteIcon onClick={() => fields.remove(idx)} />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </FieldArray>
    </div>
  );
};
