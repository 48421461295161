import * as React from 'react';
import clsx from 'clsx';

import styles from 'client/base.module.css';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  onChange: (arg0: React.SyntheticEvent<Record<string, any>>) => void;
  checked?: boolean;
  name?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
};
export const ToggleButton = ({
  error,
  label,
  onChange,
  checked,
  name,
  value,
  disabled,
}: Props) => {
  return (
    <div className={styles['base-form-box']}>
      <div className={styles['base-form-box__body']}>
        <div
          className={clsx(
            styles['base-form-toggle'],
            disabled && styles['disabled']
          )}
        >
          <label>
            <input
              disabled={disabled}
              onChange={!disabled ? onChange : undefined}
              type="checkbox"
              name={name || ''}
              value={value || ''}
              checked={checked}
            />
            <p></p>
          </label>
          {label ?? ''}
        </div>
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
