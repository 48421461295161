import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TagList } from 'client/pages/ProductDetails/ProductContentsHeader/TagList/TagList';
import { Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { contentLanguageOptions, getLanguageName } from 'client/libraries/i18n';
import { ReservationDatePicker } from 'client/components/ReservationDatePicker';
import { getProductEditorDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import { ShareButtons } from 'client/components/Sns/ShareButtons';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getBookingWidgetPmpUrlBase } from 'client/libraries/util/getBookingWidgetPmpUrlBase';
import {
  getProductSalesStatus,
  getSalesStatusText,
  getSalesStatusStyle,
} from 'client/libraries/util/getProductSalesStatus';
import EditIcon from 'client/images/ic_edit.svg';
import type { Product } from 'shared/models/swagger';
import calenderDetailIcon from 'client/images/ic_calendarDetail.svg';
import baseStyles from 'client/base.module.css';
import starIcon from 'client/images/ic_star_on.svg';

import styles from './ProductContentsHeader.module.css';
import { EditorButtonsRow } from './EditorButtonsRow/EditorButtonsRow';

interface Props {
  product: Product;
  contentLanguage: string;
  onContentLanguageChange: (arg0: string) => void;
}
export const ProductContentsHeader = ({
  product,
  contentLanguage,
  onContentLanguageChange,
}: Props) => {
  const { t, i18n } = useTranslation();
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const hasBookingWidgetPmp = Boolean(
    activeUserOrganization?.booking_widget_api_key
  );
  const urlBase = getBookingWidgetPmpUrlBase(activeUserOrganization);
  const languageOptions = contentLanguageOptions.map((option) => ({
    value: option.iso,
    text: getLanguageName(option.iso, t),
  }));
  const productSalesStatus = getProductSalesStatus(product);
  const statusStyle = getSalesStatusStyle(productSalesStatus);
  return (
    <>
      <div className={styles['base-single__header']}>
        {operationAllowed(activeUser, 'write', 'productContents') && (
          <span
            className={clsx(
              styles['base-single__sales__status'],
              styles[`${statusStyle}` as keyof typeof styles]
            )}
          >
            {getSalesStatusText(productSalesStatus, t)}
          </span>
        )}
        <div className={clsx(styles['base-single__title__wrapper'])}>
          <div
            className={clsx(
              styles['base-single__title'],
              baseStyles['base-flex']
            )}
          >
            <h1 className={styles['base-single__title__h1']}>
              {getProductEditorDisplayProductName(product)}
            </h1>
            {product.review_count != null && product.review_count != 0 && (
              <div className={styles['base-single__headerinfo__other__review']}>
                <a href="#reviews">
                  <img src={starIcon} alt="star" />
                  <span>{(product.review_rating ?? 0).toFixed(1)}</span>
                  <span>({product.review_count})</span>
                </a>
              </div>
            )}
            {operationAllowed(activeUser, 'write', 'productContents') &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') && (
                <div>
                  <Link
                    to={`/products/${product.id}/edit`}
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['icon'],
                      baseStyles['inline-icon']
                    )}
                  >
                    <img src={EditIcon} />
                  </Link>
                </div>
              )}
            {(productSalesStatus !== 'LIST_ONLY' ||
              (productSalesStatus === 'LIST_ONLY' &&
                operationAllowed(activeUser, 'write', 'productContents'))) && (
              <div>
                <ReservationDatePicker
                  productID={product.id}
                  trigger={(props) => (
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon']
                      )}
                      {...props}
                    >
                      <img src={calenderDetailIcon} />
                    </a>
                  )}
                />
              </div>
            )}
          </div>
        </div>
        {((operationAllowed(activeUser, 'write', 'productContents') &&
          hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST')) ||
          operationAllowed(activeUser, 'write', 'reverseMappings')) && (
          <EditorButtonsRow product={product} />
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          className={styles['language-select']}
        >
          <p
            style={{
              paddingRight: '5px',
            }}
          >
            {t('Content Language')}
          </p>
          <Select
            width={240}
            options={languageOptions}
            onChange={(e, { value }) => {
              onContentLanguageChange(value);
            }}
            value={contentLanguage}
          />
        </div>
        <Box display="flex" justifyContent="center" width="100%">
          <div className={styles['base-single__internal__title']}>
            {`${product.product_name ?? ''}`}
          </div>
          <div {...{ width: '500px' }}>
            {hasBookingWidgetPmp && (
              <ShareButtons
                url={`${urlBase}/products/${
                  product.id
                }?lng=${convertToBookingWidgetUrlLangCode(contentLanguage)}`}
                locale={i18n.language}
                productName={product.product_name || ''}
              />
            )}
          </div>
        </Box>
        <TagList tags={product.product_tags ?? []} />
      </div>
    </>
  );
};
