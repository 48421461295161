import { createSelector } from 'reselect';

import {
  reservationColumnCandidatesSelector,
  reservationVisibleColumnsSelector,
} from 'client/reducers/reservationTableControls';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import type { TranslateFuncType } from 'client/components/Translate';

export const getReservationCSVColumns = (
  t: TranslateFuncType
): ColumnType[] => {
  return [
    ...getReservationTableColumns(t, 'en'),
    {
      Header: t('Action Source'),
      id: 'ACTION_SOURCE',
    },
    {
      Header: t('Currency Code'),
      id: 'CURRENCY_CODE',
    },
    {
      Header: t('Gross'),
      id: 'GROSS',
    },
    {
      Header: t('Net'),
      id: 'NET',
    },
    {
      Header: t('Commission'),
      id: 'COMMISSION',
    },
    {
      Header: t('Amount due by agent'),
      id: 'AMOUNT_DUE_BY_AGENT',
    },
    {
      Header: t('Amount due by supplier'),
      id: 'AMOUNT_DUE_BY_SUPPLIER',
    },
    {
      Header: t('Reservation Form: Full Name'),
      id: 'RESERVATION_FORM_FULL_NAME',
    },
    {
      Header: t('Reservation Form: Full Name(Kanji)'),
      id: 'RESERVATION_FORM_FULL_NAME_KANJI',
    },
    {
      Header: t('Reservation Form: Title'),
      id: 'RESERVATION_FORM_TITLE',
    },
    {
      Header: t('Reservation Form: Email Address'),
      id: 'RESERVATION_FORM_EMAIL',
    },
    {
      Header: t('Reservation Form: Phone Number'),
      id: 'RESERVATION_FORM_PHONE',
    },
    {
      Header: t('Reservation Form: International Phone Number'),
      id: 'RESERVATION_FORM_INTERNATIONAL_PHONE',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Name"),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Phone Number"),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
    },
    {
      Header: t('Reservation Form: Height (cm)'),
      id: 'RESERVATION_FORM_HEIGHT',
    },
    {
      Header: t('Reservation Form: Weight (kg)'),
      id: 'RESERVATION_FORM_WEIGHT',
    },
    {
      Header: t('Reservation Form: Date of Birth'),
      id: 'RESERVATION_FORM_DATE_OF_BIRTH',
    },
    {
      Header: t('Reservation Form: Age'),
      id: 'RESERVATION_FORM_AGE',
    },
    {
      Header: t('Reservation Form: Gender'),
      id: 'RESERVATION_FORM_GENDER',
    },
    {
      Header: t('Reservation Form: Home Address'),
      id: 'RESERVATION_FORM_HOME_ADDRESS',
    },
    {
      Header: t('Reservation Form: Custom'),
      id: 'RESERVATION_FORM_CUSTOM',
    },
    {
      Header: t('Family Name'),
      id: 'FAMILY_NAME',
    },
    {
      Header: t('Given Name'),
      id: 'GIVEN_NAME',
    },
    {
      Header: t('Reservation Form: Family Name(Kana)'),
      id: 'RESERVATION_FORM_FAMILY_NAME_KANA',
    },
    {
      Header: t('Reservation Form: Given Name(Kana)'),
      id: 'RESERVATION_FORM_GIVEN_NAME_KANA',
    },
    {
      Header: t('Memo'),
      id: 'SUPPLIER_INTERNAL_NOTES',
    },
    {
      Header: t('Internal Note'),
      id: 'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
    },
    {
      Header: t('Cancellation Fee Gross'),
      id: 'CANCELLATION_FEE_GROSS',
    },
    {
      Header: t('Cancellation Fee Net'),
      id: 'CANCELLATION_FEE_NET',
    },
    {
      Header: t('Cancellation Fee Commission'),
      id: 'CANCELLATION_FEE_COMMISSION',
    },
  ];
};
export const csvColumnCandidatesSelector = createSelector(
  reservationColumnCandidatesSelector,
  (tableColumnCandidates) => [
    ...tableColumnCandidates,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
    'RESERVATION_FORM_FULL_NAME',
    'RESERVATION_FORM_FULL_NAME_KANJI',
    'RESERVATION_FORM_TITLE',
    'RESERVATION_FORM_EMAIL',
    'RESERVATION_FORM_PHONE',
    'RESERVATION_FORM_INTERNATIONAL_PHONE',
    'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
    'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
    'RESERVATION_FORM_HEIGHT',
    'RESERVATION_FORM_WEIGHT',
    'RESERVATION_FORM_DATE_OF_BIRTH',
    'RESERVATION_FORM_AGE',
    'RESERVATION_FORM_GENDER',
    'RESERVATION_FORM_HOME_ADDRESS',
    'RESERVATION_FORM_CUSTOM',
    'FAMILY_NAME',
    'GIVEN_NAME',
    'RESERVATION_FORM_FAMILY_NAME_KANA',
    'RESERVATION_FORM_GIVEN_NAME_KANA',
    'CANCELLATION_FEE_GROSS',
    'CANCELLATION_FEE_NET',
    'CANCELLATION_FEE_COMMISSION',
  ]
);
export const csvInitialSelectedColumnsSelector = createSelector(
  reservationVisibleColumnsSelector,
  (tableVisibleColumns) => [
    ...tableVisibleColumns,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
  ]
);
export const piiItems = [
  'guest_display_name',
  'RESERVATION_FORM_FULL_NAME',
  'RESERVATION_FORM_FULL_NAME_KANJI',
  'RESERVATION_FORM_TITLE',
  'RESERVATION_FORM_EMAIL',
  'RESERVATION_FORM_PHONE',
  'RESERVATION_FORM_INTERNATIONAL_PHONE',
  'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
  'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
  'RESERVATION_FORM_DATE_OF_BIRTH',
  'RESERVATION_FORM_HOME_ADDRESS',
  'FAMILY_NAME',
  'GIVEN_NAME',
  'RESERVATION_FORM_FAMILY_NAME_KANA',
  'RESERVATION_FORM_GIVEN_NAME_KANA',
];
