import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { ReservationDetailsWrapper as ReservationDetailsV3 } from 'client/pages/v3/Reservation/ReservationDetails/ReservationDetailsWrapper';

export const PartnershipReservation = () => {
  return (
    <PartnershipModeContext.Provider value={{ partnershipMode: true }}>
      <ReservationDetailsV3 />
    </PartnershipModeContext.Provider>
  );
};
