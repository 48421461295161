import moment from 'moment-timezone';

export const getFormattedPackageComponentStartTime = (
  componentStartTimeUtc: string,
  timezone: string,
  showDate: boolean
): string => {
  const componentStartMoment = moment.tz(componentStartTimeUtc, timezone);

  if (showDate) {
    return `${componentStartMoment.format(
      'YYYY-MM-DD'
    )} ${componentStartMoment.format('LT')}`;
  }

  return componentStartMoment.format('LT');
};
