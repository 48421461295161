import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_CONTRACTED_ORGANIZATIONS_FAILURE,
  FETCH_CONTRACTED_ORGANIZATIONS_REQUEST,
  FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_BY_ID_FAILURE,
  FETCH_ORGANIZATION_BY_ID_REQUEST,
  FETCH_ORGANIZATION_BY_ID_SUCCESS,
  REFRESH_ORGANIZATIONS_FAILURE,
  REFRESH_ORGANIZATIONS_REQUEST,
  REFRESH_ORGANIZATIONS_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import { activeUserSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { Organization$Input, Organization$Patch } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchOrganizationsRequest = () => ({
  type: FETCH_ORGANIZATIONS_REQUEST,
});

const fetchOrganizationsSuccess = (response: any) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  response,
});

const fetchOrganizationsFailure = (error: any) => ({
  type: FETCH_ORGANIZATIONS_FAILURE,
  error,
});

export const fetchOrganizations =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchOrganizationsRequest());
    fetch('/api/organizations', {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchOrganizationsSuccess(result)))
      .catch((error) => dispatch(fetchOrganizationsFailure(error)));
  };

const refreshOrganizationsRequest = () => ({
  type: REFRESH_ORGANIZATIONS_REQUEST,
});

const refreshOrganizationsSuccess = (response: any) => ({
  type: REFRESH_ORGANIZATIONS_SUCCESS,
  response,
});

const refreshOrganizationsFailure = (error: any) => ({
  type: REFRESH_ORGANIZATIONS_FAILURE,
  error,
});

// refreshOrganizations differs from fetchOrganizations because it doesn't trigger the loading
// state change.
export const refreshOrganizations =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(refreshOrganizationsRequest());
    fetch('/api/organizations', {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(refreshOrganizationsSuccess(result)))
      .catch((error) => dispatch(refreshOrganizationsFailure(error)));
  };

const fetchOrganizationByIDRequest = (id: string) => ({
  type: FETCH_ORGANIZATION_BY_ID_REQUEST,
  id,
});

const fetchOrganizationByIDSuccess = (response: any) => ({
  type: FETCH_ORGANIZATION_BY_ID_SUCCESS,
  response,
});

const fetchOrganizationByIDFailure = (error: any) => ({
  type: FETCH_ORGANIZATION_BY_ID_FAILURE,
  error,
});

let fetchOrganizationByIDCancel: () => void | typeof undefined;
export const fetchOrganizationByID =
  (id: string, type: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchOrganizationByIDCancel && fetchOrganizationByIDCancel();
    dispatch(fetchOrganizationByIDRequest(id));
    axios
      .get(`/api/organizations/${id}`, {
        params: {
          type: type,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchOrganizationByIDCancel = c;
        }),
      })
      .then((result) => dispatch(fetchOrganizationByIDSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchOrganizationByIDFailure('canceled'));
        else dispatch(fetchOrganizationByIDFailure(error));
      });
  };

const deleteOrganizationRequest = (
  id: string,
  orgType: 'AGENT' | 'SUPPLIER'
) => ({
  type: DELETE_ORGANIZATION_REQUEST,
  id,
  orgType,
});

const deleteOrganizationSuccess = (
  response: any,
  id: string,
  orgType: string
) => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  response,
  id,
  orgType,
});

const deleteOrganizationFailure = (error: any) => ({
  type: DELETE_ORGANIZATION_FAILURE,
  error,
});

export const deleteOrganization =
  (id: string, orgType: 'AGENT' | 'SUPPLIER') =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteOrganizationRequest(id, orgType));
    axios
      .delete(`/api/organizations/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
        params: {
          type: orgType,
        },
      })
      .then((result) =>
        dispatch(deleteOrganizationSuccess(result.data, id, orgType))
      )
      .catch((error) => {
        dispatch(deleteOrganizationFailure(error));
      });
  };

const createOrganizationRequest = (newOrganization: Organization$Input) => ({
  type: CREATE_ORGANIZATION_REQUEST,
  newOrganization,
});

const createOrganizationSuccess = (response: any) => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  response,
});

const createOrganizationFailure = (error: any) => ({
  type: CREATE_ORGANIZATION_FAILURE,
  error,
});

export const createOrganization =
  (newOrganization: Organization$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createOrganizationRequest(newOrganization));
    return fetch('/api/organizations', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newOrganization),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(createOrganizationSuccess(result)))
      .catch((error) => dispatch(createOrganizationFailure(error)));
  };

const updateOrganizationRequest = (
  id: string,
  orgType: 'AGENT' | 'SUPPLIER',
  patch: Organization$Patch
) => ({
  type: UPDATE_ORGANIZATION_REQUEST,
  id,
  orgType,
  patch,
});

const updateOrganizationSuccess = (response: any) => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  response,
});

const updateOrganizationFailure = (error: any) => ({
  type: UPDATE_ORGANIZATION_FAILURE,
  error,
});

export const updateOrganization =
  (
    id: string,
    orgType: 'AGENT' | 'SUPPLIER' | 'GUEST',
    patch: Organization$Patch
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    if (orgType === 'GUEST' || id === '') {
      throw new Error(
        `invalid params for updateOrganization: orgType = ${orgType}, id = ${id}`
      );
    }

    dispatch(updateOrganizationRequest(id, orgType, patch));
    return axios
      .patch(`/api/organizations/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
        params: {
          type: orgType,
        },
      })
      .then((result) => dispatch(updateOrganizationSuccess(result.data)))
      .catch((error) => dispatch(updateOrganizationFailure(error)));
  };
export const updateActiveUserOrganization =
  (patch: Organization$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const activeUser = activeUserSelector(getState());
    const orgId = activeUser?.organization_id;
    const orgType = activeUser?.organization_type;

    if (!orgId || !orgType) {
      throw new Error(
        `invalid params for updateOrganization: orgType = ${
          orgType || ''
        }, id = ${orgId || ''}`
      );
    }

    return dispatch(updateOrganization(orgId, orgType, patch));
  };

const fetchContractedOrganizationsRequest = () => ({
  type: FETCH_CONTRACTED_ORGANIZATIONS_REQUEST,
});

const fetchContractedOrganizationsSuccess = (response: any) => ({
  type: FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS,
  response,
});

const fetchContractedOrganizationsFailure = (error: any) => ({
  type: FETCH_CONTRACTED_ORGANIZATIONS_FAILURE,
  error,
});

export const fetchContractedOrganizations =
  (useAlternateOrganization?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchContractedOrganizationsRequest());
    fetch('/api/organizations/contracted', {
      headers: getHTTPRequestHeaders(getState(), {
        useAlternateOrganization,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchContractedOrganizationsSuccess(result)))
      .catch((error) => dispatch(fetchContractedOrganizationsFailure(error)));
  };
