import {
  SET_CUSTOMER_LIST_ROW_COUNT,
  SET_CUSTOMER_LIST_CURRENT_PAGE,
  SET_CUSTOMER_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';

export const setCustomerListRowCount = (rowCount: number) => ({
  type: SET_CUSTOMER_LIST_ROW_COUNT,
  rowCount,
});

export const setCustomerListCurrentPage = (currentPage: number) => ({
  type: SET_CUSTOMER_LIST_CURRENT_PAGE,
  currentPage,
});

export const setCustomerVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_CUSTOMER_VISIBLE_COLUMNS,
  visibleColumns,
});
