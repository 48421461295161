import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { to_language } from 'client/libraries/i18n';
import { Radio } from 'client/components/v3/Form/Radio';
import { EmbeddedCalendar } from 'client/pages/v3/BookingWidget/WebSiteIntegration/EmbeddedCalendar/EmbeddedCalendar';
import { getBookingWidgetEmbeddedCalendarUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCalendarUrl';
import { getBookingWidgetSingleProductStandaloneUrl } from 'client/libraries/util/getBookingWidgetSingleProductStandaloneUrl';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

type Props = {
  bookingWidgetApiKey: string;
  selectedProductId: string;
  widgetLanguage: string;
};

export const BookingWidgetEmbeddedSingleProductCalendar = ({
  selectedProductId,
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const { t } = useTranslation();

  const [
    showCalendarCustomizationOptions,
    setShowCalendarCustomizationOptions,
  ] = React.useState<boolean>(false);
  const [calendarDesignType, setCalendarDesignType] = React.useState<
    'BASIC' | 'BASIC_WITH_PRICES'
  >('BASIC');

  const embedCalendarUrl = getBookingWidgetEmbeddedCalendarUrl(
    bookingWidgetApiKey,
    selectedProductId,
    calendarDesignType,
    widgetLanguage
  );
  const singleProductStandaloneUrl = getBookingWidgetSingleProductStandaloneUrl(
    bookingWidgetApiKey,
    selectedProductId,
    widgetLanguage
  );

  const widgetTextT = widgetLanguage ? to_language(widgetLanguage) : t;

  if (!selectedProductId) {
    return null;
  }

  return (
    <div className={styles['p-inquiry-list__item__body__item']}>
      <div className={styles['p-bookingWidgetCustomize-box']}>
        <div className={styles['p-bookingWidgetCustomize-box__list']}>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Standalone Link')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <a
                  href={singleProductStandaloneUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {widgetTextT('Book')}
                </a>
              </dd>
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('HTML')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <pre
                  className={styles['code-block']}
                >{`<a href="${singleProductStandaloneUrl}" target="_blank" rel="noopener noreferrer">${widgetTextT(
                  'Book'
                )}</a>`}</pre>
              </dd>
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Embedded Calendar')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <Toggle
                  label={t('Customize')}
                  checked={showCalendarCustomizationOptions}
                  onChange={() => {
                    setShowCalendarCustomizationOptions(
                      !showCalendarCustomizationOptions
                    );
                  }}
                />
              </dd>
              {showCalendarCustomizationOptions && (
                <>
                  <dt
                    className={
                      styles[
                        'p-bookingWidgetCustomize-products__section__list__term'
                      ]
                    }
                  >
                    {t('Calendar Design')}
                  </dt>
                  <dd
                    className={
                      styles[
                        'p-bookingWidgetCustomize-products__section__list__desc'
                      ]
                    }
                  >
                    <Radio
                      label={t('Basic')}
                      value="BASIC"
                      onChange={() => {
                        setCalendarDesignType('BASIC');
                      }}
                      checked={calendarDesignType === 'BASIC'}
                      size="sm"
                    />
                    <Radio
                      label={t('Basic + Show Prices for Selected Date')}
                      value="BASIC_WITH_PRICES"
                      onChange={() => {
                        setCalendarDesignType('BASIC_WITH_PRICES');
                      }}
                      checked={calendarDesignType === 'BASIC_WITH_PRICES'}
                      size="sm"
                    />
                  </dd>
                </>
              )}
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Embedded Calendar')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <EmbeddedCalendar
                  apiKey={bookingWidgetApiKey}
                  productId={selectedProductId}
                  designType={calendarDesignType}
                  language={widgetLanguage}
                />
              </dd>
            </dl>
          </div>
          <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
            <dl
              className={
                styles['p-bookingWidgetCustomize-products__section__list']
              }
            >
              <dt
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__term'
                  ]
                }
              >
                <span>{t('Code')}</span>
              </dt>
              <dd
                className={
                  styles[
                    'p-bookingWidgetCustomize-products__section__list__desc'
                  ]
                }
              >
                <pre
                  className={styles['code-block']}
                >{`<div id="nutmeg-calendar-${selectedProductId}">
</div>
<script type="text/javascript">
  /*<![CDATA[*/
  (function () {
      var scriptUrl = 'https://assets.ntmg.com/scripts/falcon-embed/v1/index.js';
      if (window.NutmegCalendar) {
          NutmegCalendarInit();
      } else {
          loadScriptAsync();
      }
      function loadScriptAsync() {
          var script = document.createElement('script');
          script.src = scriptUrl;
          script.async = true;
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
          script.onload = NutmegCalendarInit;
      }
      function NutmegCalendarInit() {
          window.NutmegCalendar.buildEmbed('${selectedProductId}', '${embedCalendarUrl}')
      }
  })();
  /*]]>*/
</script>`}</pre>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
