import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import type { TranslateFuncType } from 'client/components/Translate';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import { CustomerShape } from 'client/libraries/util/customerShape';
import { Box } from 'client/components/Box/Box';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { CustomerSearchSettings } from 'client/reducers/customers';
import { ReservationCreateModalButton } from 'client/components/ReservationCreateModal/ReservationCreateModalButton';
import styles from 'client/base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { config } from 'client/config';
import { v3CustomerTagColors } from 'client/libraries/util/colors';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SwaggerAutoTag } from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/FormValues';
import { Badge } from 'client/components/v3/Common/Badge';

export const getColumnIds = (): string[] => {
  return [
    'customer_name',
    'email',
    'tags',
    'last_participation_date',
    'last_participated_product_name',
    'mail_status',
  ];
};

export const getAllColumns = (
  locale: string,
  t: TranslateFuncType
): ColumnType<CustomerShape>[] => {
  return getColumns(locale, t);
};

export const getColumns = (
  locale: string,
  t: TranslateFuncType
): ColumnType<CustomerShape>[] => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const autoTags = (activeUserOrganization?.customer_ledger_settings
    ?.customer_auto_tags ?? []) as SwaggerAutoTag[];

  return [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (item: CustomerShape) => {
        return (
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 'auto' }}>
              <Link to={`/customers/${item.id}`}>
                {item.customerName || t('No name')}
              </Link>
            </span>
            <Box ml={2}>
              {/* TODO: remove this config flag when deploying to prod */}
              {config.enableUIRevamp && (
                <Link
                  // TODO: remove v3 from url later
                  to={{
                    pathname: `/reservations/new-v3?customer_id=${item.id}`,
                  }}
                  target="_blank"
                >
                  <Button
                    text={t('Create new reservation')}
                    size="md"
                    color="primary"
                  />
                </Link>
              )}
              {/* TODO: remove this old button when UIUX revamp is released */}
              {!config.enableUIRevamp && (
                <ReservationCreateModalButton
                  customerId={item.id}
                  trigger={
                    <button
                      className={clsx(
                        styles['base-btn'],
                        styles['middle'],
                        styles['green']
                      )}
                      style={{ width: '110px', padding: '0 10px' }}
                    >
                      {t('Create new reservation')}
                    </button>
                  }
                />
              )}
            </Box>
          </Box>
        );
      },
      width: 400,
    },
    {
      Header: t('Registration Date'),
      id: 'registrationDate',
      accessor: (item: CustomerShape) => {
        if (!item.membershipRegistered) {
          return null;
        }
        return getDateTimeDisplay(item?.membershipRegistered, locale);
      },
      width: 220,
    },
    {
      Header: t('Email'),
      id: 'email',
      accessor: (item: CustomerShape) => {
        return item.email;
      },
      width: 220,
    },
    {
      Header: t('Manual Tags'),
      id: 'tags',
      accessor: (item: CustomerShape) => {
        return item.tags.map((tag) => '#' + tag).join(', ');
      },
      width: 220,
    },
    {
      Header: t('Auto Tags'),
      id: 'autotags',
      accessor: (item: CustomerShape) => {
        return item.autotags ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {item.autotags.map((tag, index) => {
              const autoTag = autoTags.find((autoTag) => autoTag.name === tag);
              // If auto tag is not found (e.g. auto tag is already deleted or renamed), render nothing
              if (!autoTag) {
                return tag;
              }
              const color = v3CustomerTagColors.find(
                (tagColor) => tagColor.value === autoTag.color
              );
              return (
                <Badge
                  key={index}
                  label={autoTag.name ?? ''}
                  backgroundColor={color?.backgroundColor}
                  borderColor={color?.borderColor}
                  textColor={color?.color}
                />
              );
            })}
          </div>
        ) : (
          ''
        );
      },
      width: 220,
    },
    {
      Header: t('Last Participation Date'),
      id: 'lastParticipationDate',
      accessor: (item: CustomerShape) => {
        if (item.lastParticipationDate) {
          return item?.lastParticipationDate.locale(locale).format('ll');
        }
        return null;
      },
      width: 220,
    },
    {
      Header: t('Last Participated Product Name'),
      id: 'lastParticipateProduct',
      accessor: (item: CustomerShape) => {
        return item.lastParticipatedProductName;
      },
      width: 220,
    },
    {
      Header: t('Mail Status'),
      id: 'mailStatus',
      accessor: (item: CustomerShape) => {
        return item.mailStatus;
      },
      width: 120,
    },
    {
      Header: t('Total Reservations'),
      id: 'totalReservations',
      accessor: (item: CustomerShape) => {
        return item.reservationCount;
      },
      width: 120,
    },
  ];
};

export const getOrderByOptions = (
  t: TranslateFuncType
): { value: string; text: string }[] => {
  return [
    {
      text: t('Registration Date(last recent first)'),
      value: 'REGISTRATION#ASC',
    },
    {
      text: t('Registration Date(most recent first)'),
      value: 'REGISTRATION#DESC',
    },
    {
      text: t('Last Participation Date(last recent first)'),
      value: 'LAST_PARTICIPATION#ASC',
    },
    {
      text: t('Last Participation Date(most recent first)'),
      value: 'LAST_PARTICIPATION#DESC',
    },
  ];
};

export const getOrderByValue = (
  searchSettings: CustomerSearchSettings
): string => {
  const { orderBy, isAscending } = searchSettings;
  return `${orderBy}#${isAscending ? 'ASC' : 'DESC'}`;
};

export const getOrderByName = (
  searchSettings: CustomerSearchSettings,
  t: TranslateFuncType
): string => {
  const value = getOrderByValue(searchSettings);
  return (
    getOrderByOptions(t).find((option) => option.value === value)?.text || ''
  );
};

export const getNumOfReservationsOptions = (t: TranslateFuncType) => {
  return [
    {
      text: t('-'),
      value: '',
    },
    {
      text: t('greater than or equal to '),
      value: 'MORE_THAN_EQUAL',
    },
    {
      text: t('equal to'),
      value: 'EQUAL',
    },
    {
      text: t('less than or equal to '),
      value: 'LESS_THAN_EQUAL',
    },
  ];
};
