import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { productOptionsSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';

import { getOrderByName, getNumOfReservationsOptions } from './utils';
import styles from './CustomerListConditionDisplayBox.module.css';

export const CustomerListConditionDisplayBox = () => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  const searchSettings = useSelector((state: ReduxState) => {
    return state.customers.searchSettings;
  });

  const totalSize = useSelector((state: ReduxState) => {
    return state.customers.totalSize;
  });

  if (
    searchSettings?.registrationDateFrom ||
    searchSettings?.registrationDateTo
  ) {
    filters.push({
      typeText: t('Registration Date') + ':',
      filterValue: `${searchSettings.registrationDateFrom ?? ''} ~ ${
        searchSettings.registrationDateTo
      }`,
    });
  }

  if (
    searchSettings?.lastParticipationDateFrom ||
    searchSettings?.lastParticipationDateTo
  ) {
    filters.push({
      typeText: t('Last Participation Date') + ':',
      filterValue: `${searchSettings.lastParticipationDateFrom ?? ''} ~ ${
        searchSettings.lastParticipationDateTo
      }`,
    });
  }

  if (searchSettings?.reservationCountOperator) {
    filters.push({
      typeText: t('Number of Reservations') + ':',
      filterValue: `${
        getNumOfReservationsOptions(t).find(
          (option) => option.value === searchSettings.reservationCountOperator
        )?.text
      } ${searchSettings.reservationCount}`,
    });
  }

  if (searchSettings?.email) {
    filters.push({
      typeText: t('Email') + ':',
      filterValue: searchSettings.email,
    });
  }

  if (searchSettings?.productIds?.length > 0) {
    filters.push({
      typeText: t('Products') + ':',
      filterValue: searchSettings.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchSettings?.onlyShouldReceiveSpecialEmailOffers) {
    filters.push({
      typeText: t('Receive offer emails'),
      filterValue: '',
    });
  }

  if (searchSettings?.onlyAlertedCustomer) {
    filters.push({
      typeText: t('Alerted Customer'),
      filterValue: '',
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      <b>{t('Search Conditions')}</b>

      <ul>
        <li>{`${t('Order By')}: ${getOrderByName(searchSettings, t)}`}</li>
        {filters.map((f) => (
          <li key={f.typeText}>{`${f.typeText} ${f.filterValue}`}</li>
        ))}

        <li className={styles['total']}>
          <b>{t('Total: ')}</b>

          {t('{{count}} records', {
            count: totalSize,
          })}
        </li>
      </ul>
    </div>
  );
};
