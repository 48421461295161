import * as React from 'react';

import { useBounds } from 'client/hooks/useBounds';
import { PortalTooltip } from 'client/components/PortalTooltip/PortalTooltip';
import baseStyles from 'client/base.module.css';

type Props = {
  text: string | React.ReactElement<any>;
  tooltipStyle?: Record<string, any>;
};

export const InfoTooltipWithPortal = ({ text, tooltipStyle }: Props) => {
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      ref={divRef}
    >
      <i className={baseStyles['base-info__ic']}>?</i>
      {showTooltip && (
        <PortalTooltip
          text={text}
          style={{
            ...tooltipStyle,
            left: divBounds.left + 40,
            top: divBounds.top,
          }}
        />
      )}
    </div>
  );
};
