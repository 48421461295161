import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/digitalMapAccessReportSettings';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
};
export const TotalTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  let totalVisitCount = 0;
  let totalUserCount = 0;
  data.forEach((dataItem) => {
    totalVisitCount += dataItem.visitCount;
    totalUserCount += dataItem.userCount;
  });
  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th style={{ width: 'calc(100% / 5)' }}>{t('visitCount')}</th>
          <th style={{ width: 'calc(100% / 5)' }}>{t('userCount')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{totalVisitCount}</td>
          <td>{totalUserCount}</td>
        </tr>
      </tbody>
    </table>
  );
};
