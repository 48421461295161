import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Radio } from 'client/components/v3/Form/Radio';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { updateGuideSettings } from 'client/actions/guideSettings';
import type { Account, GuideSettings } from 'shared/models/swagger';
import styles from 'client/pages/v3/Settings/Settings.module.css';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
};
export const GuideNotificationMail = ({ activeUser, guideSettings }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getTimeLocalText = (guideSettings: GuideSettings | null) => {
    const base =
      guideSettings?.notification_settings?.relative_date?.time_local || '9:00';
    const hour = base.split(':')[0];
    return `${('00' + hour).slice(-2)}:00`;
  };

  const [needReservationEmail, setNeedReservationEmail] =
    React.useState<boolean>(
      guideSettings?.notification_settings?.send_notification_mail || false
    );
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [relativeCount, setRelativeCount] = React.useState<number>(
    guideSettings?.notification_settings?.relative_date?.count || 0
  );
  const [success, setSuccess] = React.useState<boolean>(false);
  const [timeLocal, setTimeLocal] = React.useState<string>(
    getTimeLocalText(guideSettings)
  );
  const reset = React.useCallback(() => {
    setNeedReservationEmail(
      guideSettings?.notification_settings?.send_notification_mail || false
    );
    setRelativeCount(
      guideSettings?.notification_settings?.relative_date?.count || 0
    );
    setTimeLocal(getTimeLocalText(guideSettings));
  }, [guideSettings]);
  React.useEffect(() => {
    reset();
  }, [reset]);

  const selectOptions = _.times(24, String).map((i) => {
    const hour = `${('00' + i).slice(-2)}:00`;
    return {
      value: hour,
      text: hour,
    };
  });

  return (
    <>
      <Toggle
        label={t('Automatically send email when reservations are assigned')}
        checked={needReservationEmail}
        onChange={() => setNeedReservationEmail(!needReservationEmail)}
      />

      {needReservationEmail && (
        <div
          className={clsx(styles['notification-time'])}
          style={{ marginTop: '10px' }}
        >
          <div>{t('Notification Date/Time')}</div>
          <div style={{ marginLeft: '10px' }}>
            <Radio
              label={t('On the day')}
              size="sm"
              checked={relativeCount === 0}
              onChange={() => {
                setRelativeCount(0);
              }}
            />
          </div>
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Radio
              label={t('Previous day')}
              size="sm"
              checked={relativeCount === 1}
              onChange={() => {
                setRelativeCount(1);
              }}
            />
          </div>
          <SingleDropdown
            options={selectOptions}
            selectedOption={timeLocal}
            onChange={(value) => setTimeLocal(value)}
          />
        </div>
      )}

      {error && (
        <Snackbar
          color="error"
          text={error}
          content={error}
          shouldShow={error != ''}
        />
      )}
      {success && (
        <Snackbar
          color="success"
          text={t('Update succeeded')}
          shouldShow={success}
        />
      )}
      <div className={styles['buttons']}>
        <div className={styles['button-box']}>
          <Button text={t('Discard')} color="gray" size="lg" onClick={reset} />
        </div>
        <div className={styles['button-box']}>
          <Button
            text={t('Save')}
            color="primary"
            size="lg"
            loading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await dispatch(
                  updateGuideSettings(activeUser?.id ?? '', {
                    notification_settings: {
                      send_notification_mail: needReservationEmail,
                      relative_date: {
                        type: 'DAY',
                        count: relativeCount,
                        time_local: timeLocal,
                      },
                    },
                  })
                );
                setSuccess(true);
                setError('');
              } catch (err) {
                setSuccess(false);
                setError(err as string);
              }

              setLoading(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
