// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ProductGroupList } from 'client/pages/ManifestCustomize/ProductGroupList';
import { ManifestViewList } from 'client/pages/ManifestCustomize/ManifestViewList';
import { ExcludedFormFieldList } from 'client/pages/ManifestCustomize/ExcludedFormFieldList';
import { CustomizedColumnName } from 'client/pages/ManifestCustomize/CustomizedColumnName';
import { AlternateName } from 'client/pages/ManifestCustomize/AlternateName';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

type TabType =
  | 'EDIT_GROUP'
  | 'EDIT_VIEWS'
  | 'FILTER'
  | 'CUSTOMIZED_COLUMN_NAME'
  | 'ALTERNATE_NAME';

export const ManifestCustomize = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<TabType>('EDIT_GROUP');

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="MANIFEST_CUSTOMIZE" />
      )}
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'EDIT_GROUP' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('EDIT_GROUP');
            }}
          >
            <a>{t('Edit Manifest Groups')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'EDIT_VIEWS' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('EDIT_VIEWS');
            }}
          >
            <a>{t('Edit Manifest Views')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'FILTER' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('FILTER');
            }}
          >
            <a>{t('Filter Required Info')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'CUSTOMIZED_COLUMN_NAME'
                ? componentStyles['is-active']
                : ''
            )}
            onClick={() => {
              setActiveTab('CUSTOMIZED_COLUMN_NAME');
            }}
          >
            <a>{t('Column names')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'ALTERNATE_NAME' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('ALTERNATE_NAME');
            }}
          >
            <a>{t('Output Abbreviations')}</a>
          </li>
        </ul>

        <ProductGroupList isActive={activeTab === 'EDIT_GROUP'} />

        <ManifestViewList isActive={activeTab === 'EDIT_VIEWS'} />

        <ExcludedFormFieldList isActive={activeTab === 'FILTER'} />

        <CustomizedColumnName
          isActive={activeTab === 'CUSTOMIZED_COLUMN_NAME'}
        />

        <AlternateName isActive={activeTab === 'ALTERNATE_NAME'} />
      </div>
    </>
  );
};
