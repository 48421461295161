import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';

export const HighlightsEditor = () => {
  const { t } = useTranslation();

  return (
    <FieldArray name="highlights">
      {({ fields }) => (
        <ul className={styles['p-list']}>
          {fields.map((name, idx) => (
            <TranslatedField key={name} name={name}>
              {({ input }) => (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <div className={styles['p-list__item__ttl__txt']}>
                      <div>
                        {t('POINT.{{pointNumber}}', {
                          pointNumber: idx + 1,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <div className={styles['p-list__item__body__flex']}>
                      <div
                        className={clsx(
                          styles['p-list__item__body__flex__main'],
                          styles['p-list__highlight']
                        )}
                      >
                        <TextField {...input} />
                      </div>
                      <div
                        className={styles['p-list__item__body__flex__actions']}
                      >
                        <Button
                          size="icon"
                          color="tertiarygray"
                          onClick={() => fields.remove(idx)}
                          iconBeforeText={
                            <i className="c-icon-outline-general-trash-03"></i>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </TranslatedField>
          ))}
          <li className={styles['p-list__item']}>
            <a
              className={styles['p-box__table2__actions__add']}
              onClick={() => {
                if (fields.length && fields.length > 0) {
                  (fields as any).insertAt((fields.length ?? 0) + 1, '');
                } else {
                  (fields as any).insertAt(0, '');
                }
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Highlight')}
            </a>
          </li>
        </ul>
      )}
    </FieldArray>
  );
};
