import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  Line,
  LineChart,
} from 'recharts';

import { currency } from 'shared/libraries/currency';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './SalesTrends.module.css';
import { DateRange } from './util';

interface SalesTrendsProps {
  dateRange: DateRange;
  comparisonDateRange: DateRange | null;
  onDateRangeChange: (dateRange: DateRange) => void;
  onComparisonDateRangeChange: (dateRange: DateRange | null) => void;
}

const SalesTrends: React.FC<SalesTrendsProps> = ({
  dateRange,
  comparisonDateRange,
  onDateRangeChange,
  onComparisonDateRangeChange,
}) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.restaurantOrderReportData.loading
  );

  const reportData = useSelector(
    (state: ReduxState) => state.restaurantOrderReportData.reportData
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const hasComparisonData = !!reportData?.sets?.find(
    (item) =>
      item.range?.start === comparisonDateRange?.start &&
      item.range?.end === comparisonDateRange?.end
  );

  const data = React.useMemo(() => {
    if (!reportData?.sets?.length) {
      return [];
    }

    const baseData = reportData.sets.find(
      (item) =>
        item.range?.start === dateRange.start &&
        item.range?.end === dateRange.end
    );

    const comparisonData = reportData.sets.find(
      (item) =>
        item.range?.start === comparisonDateRange?.start &&
        item.range?.end === comparisonDateRange?.end
    );

    if (!comparisonData && baseData) {
      return baseData.items?.map((item) => ({
        date: moment(item.date, 'YYYY-MM-DD').locale(locale).format('l'),
        [t('Base')]: item.gross_sales ? currency(item.gross_sales).value : 0,
      }));
    }

    if (baseData && comparisonData) {
      return _.zip(baseData.items ?? [], comparisonData.items ?? []).map(
        (pair) => {
          return {
            date: `${moment(pair[0]?.date, 'YYYY-MM-DD')
              .locale(locale)
              .format('l')}`,
            [t('Base')]: pair[0]?.gross_sales
              ? currency(pair[0]?.gross_sales).value
              : 0,
            [t('Comparison')]: pair[1]?.gross_sales
              ? currency(pair[1]?.gross_sales).value
              : 0,
          };
        }
      );
    }
  }, [reportData, t]);

  console.log(data);

  return (
    <section className={styles.salesTrends}>
      <header className={styles.trendsHeader}>
        <h2 className={styles.trendsTitle}>{t('Sales Trends')}</h2>
        <div className={styles.periodSelect}>
          <ComparisonDateInput
            baseDateRange={dateRange}
            setBaseDateRange={onDateRangeChange}
            comparisonDateRange={comparisonDateRange}
            setComparisonDateRange={onComparisonDateRangeChange}
          />
        </div>
      </header>
      <div className={styles['graph-container']}>
        {loading ? (
          <Loading size="md" />
        ) : (
          <ResponsiveContainer width="100%" height="100%" debounce={300}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={t('Base')}
                stroke="#3B82F6"
                activeDot={{ r: 8 }}
              />
              {hasComparisonData && (
                <Line
                  type="monotone"
                  dataKey={t('Comparison')}
                  stroke="#EC4899"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </section>
  );
};

export default SalesTrends;
