import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setActorName } from 'client/actions/user';
import { currentActorSelector } from 'client/reducers/user';
import { TextField } from 'client/components/v3/Form/TextField';
import styles from 'client/components/v3/ReservationActorInputForm/ReservationActorInputForm.module.css';

type Props = {
  label?: string;
};

export const ReservationActorInputForm = ({ label }: Props) => {
  const { t } = useTranslation();
  const actor = useSelector(currentActorSelector);
  const dispatch = useDispatch();

  return (
    <div className={styles['c-resActorInputForm']}>
      <p className={styles['c-resActorInputForm__ttl']}>
        {label ? label : t('Your Name')}
      </p>
      <TextField
        value={actor.name ?? ''}
        onChange={(value) => dispatch(setActorName(value))}
      />
    </div>
  );
};
