import clsx from 'clsx';

import { Add as AddIcon } from 'client/components/Icons/Add';
import { Toggle as ToggleIcon } from 'client/components/Icons/Toggle';
import { Box } from 'client/components/Box/Box';

import styles from './EquipmentEditor.module.css';

interface Props {
  title: string;
  open: boolean;
  onOpen: () => void;
  onSelect: (id: string) => void;
  onCreate: () => void;
  children: React.ReactNode;
  options?: {
    value: string;
    text: string;
  }[];
  selectedOption?: string;
  onDragStart?: (id: string) => void;
}

export const EquipmentEditorPanel = ({
  title,
  open,
  onOpen,
  onSelect,
  onCreate,
  options,
  children,
  selectedOption,
  onDragStart,
}: Props) => {
  return (
    <div
      className={clsx(
        styles['equipment-editor__panel'],
        open ? styles['is-active'] : ''
      )}
    >
      <div className={clsx(styles['equipment-editor__panel-header'])}>
        {title}
      </div>
      <div className={clsx(styles['equipment-editor__panel-body'])}>
        <EquipmentEditorPanelSidebar
          open={open}
          onOpen={onOpen}
          onSelect={onSelect}
          onCreate={onCreate}
          options={options}
          selectedOption={selectedOption}
          onDragStart={onDragStart}
        />
        <div
          className={clsx(
            styles['equipment-editor__panel-main'],
            open ? styles['is-active'] : ''
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

interface SidebarProps {
  open: boolean;
  onOpen: () => void;
  onSelect: (id: string) => void;
  onCreate: () => void;
  options?: {
    value: string;
    text: string;
  }[];
  selectedOption?: string;
  onDragStart?: (id: string) => void;
}

const EquipmentEditorPanelSidebar = ({
  open,
  onOpen,
  onSelect,
  onCreate,
  options,
  selectedOption,
  onDragStart,
}: SidebarProps) => {
  return (
    <div
      className={clsx(
        styles['equipment-editor__panel-sidebar'],
        open ? styles['is-active'] : ''
      )}
    >
      <ul className={clsx(styles['equipment-editor__panel-sidebar__list'])}>
        {options?.map((option) => (
          <li
            draggable={true}
            className={clsx(
              selectedOption === option.value ? styles['is-selected'] : ''
            )}
            key={option.value}
            onDragStart={() => onDragStart?.(option.value)}
          >
            <a onClick={() => onSelect(option.value)}>{option.text}</a>
          </li>
        ))}
      </ul>
      {open && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <AddIcon onClick={() => onCreate()} />
        </Box>
      )}
      {!open && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={'auto'}
        >
          <ToggleIcon onClick={() => onOpen()} />
        </Box>
      )}
    </div>
  );
};
