import { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

export type FilterReservationsRequest = {
  reservationStatuses: Swagger.ReservationStatus[];
  productIds: string[];
  paymentTypes: string[];
  transferDateFrom: string;
  transferDateTo: string;
  agentIds: string[];
};

export const getPaymentTypeOptions = (
  t: TranslateFuncType
): { value: string; text: string }[] => {
  return [
    {
      value: 'PAID_IN_FULL',
      text: t('PAID_IN_FULL'),
    },
    {
      value: 'PAY_ON_BOARD',
      text: t('PAY_ON_BOARD'),
    },
  ];
};
