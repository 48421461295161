import * as React from 'react';

import styles from './TagList.module.css';

interface OwnProps {
  tags: string[];
}
type Props = OwnProps;
export const TagList = ({ tags }: Props) => (
  <ul className={styles['c-tag']}>
    {tags.map((tag) => (
      <li key={tag}>
        <button>{tag}</button>
      </li>
    ))}
  </ul>
);
