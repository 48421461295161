import { combineReducers } from 'redux';

import {
  ADD_EQUIPMENT_NOTIFICATION,
  REMOVE_EQUIPMENT_NOTIFICATION,
} from 'client/constants/ActionTypes';

export type EquipmentNotification = {
  id: string;
  payload: Record<string, string>;
};

let idCounter = 1;
const initialValue: EquipmentNotification[] = [];

export const notifications = (
  state: EquipmentNotification[] = initialValue,
  action: Record<string, any>
): EquipmentNotification[] => {
  switch (action.type) {
    case ADD_EQUIPMENT_NOTIFICATION: {
      const newState = [
        ...state,
        {
          ...action.notification,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_EQUIPMENT_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);

    default:
      return state;
  }
};

export const equipmentNotifications = combineReducers({
  notifications,
});
