import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import { parseDuration } from 'client/libraries/util/util';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/DurationInput/DurationInput.module.css';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  showSignSelect?: boolean;
  required?: boolean;
};

const getDuration = (days: string, hours: string, minutes: string): string => {
  const d = parseInt(days);
  const h = parseInt(hours);
  const m = parseInt(minutes);
  return `${d * 24 + h}:${m < 10 ? `0${m}` : m}`;
};

export const DurationInput = ({
  required,
  showSignSelect,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [sign, setSign] = React.useState<'NEGATIVE' | 'POSITIVE'>(
    value?.includes('-') ? 'NEGATIVE' : 'POSITIVE'
  );
  React.useEffect(() => {
    const newSign = value?.includes('-') ? 'NEGATIVE' : 'POSITIVE';

    if (newSign !== sign) {
      setSign(newSign);
    }
  }, [sign, value]);
  let { hours: rawHours, minutes: rawMinutes } = parseDuration(value);

  if (rawHours < 0) {
    rawHours = -rawHours;
  }

  if (rawMinutes < 0) {
    rawMinutes = -rawMinutes;
  }

  const days = value ? `${Math.floor(rawHours / 24)}` : '';
  const hours = value ? `${rawHours % 24}` : '';
  const minutes = value ? `${rawMinutes}` : '';
  return (
    <div className={styles['duration-input']}>
      {showSignSelect && (
        <label
          className={clsx(
            baseStyles['base-form-select'],
            styles['sign-select']
          )}
        >
          <select
            value={sign}
            onChange={(e) => {
              const newSign = e.target.value as 'NEGATIVE' | 'POSITIVE';
              setSign(newSign);
              onChange(
                newSign === 'NEGATIVE' ? `-${value}` : value.replace('-', '')
              );
            }}
          >
            <option value="NEGATIVE">-</option>
            <option value="POSITIVE">+</option>
          </select>
        </label>
      )}
      <label
        className={clsx(baseStyles['base-form-select'], styles['day-select'])}
      >
        <select
          value={days}
          onChange={(e) => {
            const newDays = e.target.value;

            if (newDays === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  newDays,
                  hours || '0',
                  minutes || '0'
                )}`
              );
            }
          }}
        >
          {!required && <option value="">-</option>}
          {_.times(11).map((d) => (
            <option key={d} value={d}>
              {t('{{count}}d', {
                count: d,
              })}
            </option>
          ))}
        </select>
      </label>
      <label
        className={clsx(baseStyles['base-form-select'], styles['hour-select'])}
      >
        <select
          value={hours}
          onChange={(e) => {
            const newHours = e.target.value;

            if (newHours === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  days || '0',
                  newHours,
                  minutes || '0'
                )}`
              );
            }
          }}
        >
          {!required && <option value="">-</option>}
          {_.times(24).map((hours) => (
            <option key={hours} value={hours}>
              {t('{{count}}h', {
                count: hours,
              })}
            </option>
          ))}
        </select>
      </label>
      <label
        className={clsx(
          baseStyles['base-form-select'],
          styles['minute-select']
        )}
      >
        <select
          value={minutes}
          onChange={(e) => {
            const newMinutes = e.target.value;

            if (newMinutes === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  days || '0',
                  hours || '0',
                  newMinutes
                )}`
              );
            }
          }}
        >
          {!required && <option value="">-</option>}
          {_.times(60).map((min) => (
            <option key={min} value={min}>
              {t('{{count}}m', {
                count: min,
              })}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};
