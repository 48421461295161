import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { formatTextWithLineBreaks } from 'client/libraries/util/formatTextWithLineBreaks';
import { getStubRedemptionButtonFontSize } from 'client/libraries/util/getStubRedemptionButtonFontSize';

import styles from './ETicketStubRedemptionButtonPreview.module.css';

interface Props {
  buttonText: string | null | undefined;
  buttonColor: string | null | undefined;
  buttonTextColor: string | null | undefined;
}

export const ETicketStubRedemptionButtonPreview = ({
  buttonText,
  buttonColor,
  buttonTextColor,
}: Props) => {
  const { t } = useTranslation();

  const fontSize = getStubRedemptionButtonFontSize(buttonText ?? '');

  return (
    <button
      className={clsx(
        styles['btn'],
        styles['btn--submit'],
        styles['width-128']
      )}
      style={{
        backgroundColor: buttonColor ?? '#000000',
        color: buttonTextColor ?? '#ffffff',
        fontSize: `${fontSize}px`,
      }}
    >
      {buttonText ? formatTextWithLineBreaks(buttonText) : t('Redeem')}
    </button>
  );
};
