// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { Button, Input, Select } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateDigitalGuidanceSettings } from 'client/actions/digitalGuidanceSettings';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import styles from './DigitalGuidanceSettings.module.css';

export const DigitalGuidanceSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);

  const initialAvailableDays =
    organization?.digital_guidance_settings?.available_days ?? 0;
  const initialExpireDays =
    organization?.digital_guidance_settings?.expire_days ?? 0;
  const initialUAGoogleAnalyticsTag =
    organization?.digital_guidance_settings?.ua_google_analytics_tag ?? '';
  const initialGA4GoogleAnalyticsTag =
    organization?.digital_guidance_settings?.ga4_google_analytics_tag ?? '';

  const [availableDays, setAvailableDays] =
    React.useState<number>(initialAvailableDays);
  const [expireDays, setExpireDays] = React.useState<number>(initialExpireDays);
  const [uaGoogleAnalyticsTag, setUaGoogleAnalyticsTag] = React.useState(
    initialUAGoogleAnalyticsTag
  );
  const [ga4GoogleAnalyticsTag, setGa4GoogleAnalyticsTag] = React.useState(
    initialGA4GoogleAnalyticsTag
  );

  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );

  const kioskUrl = `${getBookingWebsiteUrl(organization)}/guidance/verify`;

  const reset = React.useCallback(() => {
    setAvailableDays(initialAvailableDays);
    setExpireDays(initialExpireDays);
    setUaGoogleAnalyticsTag(initialUAGoogleAnalyticsTag);
    setGa4GoogleAnalyticsTag(initialGA4GoogleAnalyticsTag);
  }, [
    initialAvailableDays,
    initialExpireDays,
    initialUAGoogleAnalyticsTag,
    initialGA4GoogleAnalyticsTag,
  ]);

  React.useEffect(() => {
    reset();
  }, [reset]);

  const pristine =
    initialAvailableDays === availableDays &&
    initialExpireDays === expireDays &&
    initialUAGoogleAnalyticsTag === uaGoogleAnalyticsTag &&
    initialGA4GoogleAnalyticsTag === ga4GoogleAnalyticsTag;

  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tr>
              <th>{t('Digital Guidance Page Link')}</th>
              <td>
                <a
                  className={styles['link']}
                  href={kioskUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {kioskUrl}
                </a>
                <Box mt={2} mb={2}>
                  <QRCode value={kioskUrl} />
                </Box>
              </td>
            </tr>
            <tr>
              <th>{t('Guidance Content Availability')}</th>
              <td>
                <Select
                  label={t('Guidance content available from')}
                  search
                  value={`${availableDays}`}
                  onChange={(_, { value }) => {
                    setAvailableDays(parseInt(value));
                  }}
                  options={[
                    {
                      text: t('participation date'),
                      value: '0',
                    },
                    ..._.times(45, (i) => i + 1).map((day) => ({
                      key: day,
                      text: t('{{count}} days before participation', {
                        count: day,
                      }),
                      value: `${day}`,
                    })),
                  ]}
                />
                <Box mt={2}>
                  <Select
                    label={t('Guidance content available until')}
                    search
                    value={`${expireDays}`}
                    onChange={(_, { value }) => {
                      setExpireDays(parseInt(value));
                    }}
                    options={[
                      {
                        text: t('participation date'),
                        value: '0',
                      },
                      ..._.times(365, (i) => i + 1).map((day) => ({
                        key: day,
                        text: t('{{count}} days after participation', {
                          count: day,
                        }),
                        value: `${day}`,
                      })),
                    ]}
                  />
                </Box>
              </td>
            </tr>
            <tr>
              <th>{t('Google Analytics (Digital Guidance only)')}</th>
              <td>
                <Input
                  label={t('Universal Analytics tag')}
                  value={uaGoogleAnalyticsTag}
                  onChange={(_, { value }) => {
                    setUaGoogleAnalyticsTag(value);
                  }}
                  placeholder="UA-xxxxxxxx"
                />
                <Box mt={2}>
                  <Input
                    label={t('GA4 tag (recommended)')}
                    value={ga4GoogleAnalyticsTag}
                    onChange={(_, { value }) => {
                      setGa4GoogleAnalyticsTag(value);
                    }}
                    placeholder="G-xxxxxxxx"
                  />
                </Box>
              </td>
            </tr>
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button
            style="gray"
            size="middle"
            disabled={pristine}
            onClick={reset}
          >
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            disabled={pristine}
            onClick={async () => {
              await dispatch(
                updateDigitalGuidanceSettings({
                  available_days: availableDays,
                  expire_days: expireDays,
                  ua_google_analytics_tag: uaGoogleAnalyticsTag,
                  ga4_google_analytics_tag: ga4GoogleAnalyticsTag,
                })
              );

              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
