import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';

import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';

type Props = {
  name: string;
  allowAnytimeDeadline?: boolean;
  allowMinutes?: boolean;
};

export const DeadlineInput = ({
  name,
  allowAnytimeDeadline,
  allowMinutes,
}: Props) => {
  const field = useField(name);
  const { t } = useTranslation();
  return (
    <div className={styles['p-deadline']}>
      {field.input.value.deadlineType === 'HOUR' && (
        <div className={styles['p-deadline__first']}>
          <Field
            name={`${name}.hoursBefore`}
            parse={(value) =>
              typeof value === 'string' ? parseInt(value) : value
            }
          >
            {({ input }) => (
              <SingleDropdown
                options={_.times(101, (hoursBefore) => ({
                  value: hoursBefore.toString(),
                  text: hoursBefore.toString(),
                }))}
                selectedOption={input.value.toString()}
                onChange={input.onChange}
              />
            )}
          </Field>
        </div>
      )}
      {field.input.value.deadlineType === 'DAY' && (
        <div className={styles['p-deadline__first']}>
          <Field
            name={`${name}.daysBefore`}
            parse={(value) =>
              typeof value === 'string' ? parseInt(value) : value
            }
          >
            {({ input }) => (
              <SingleDropdown
                options={_.times(366, (daysBefore) => ({
                  value: daysBefore.toString(),
                  text: daysBefore.toString(),
                }))}
                selectedOption={input.value.toString()}
                onChange={input.onChange}
              />
            )}
          </Field>
        </div>
      )}
      {field.input.value.deadlineType === 'MINUTE' && (
        <div className={styles['p-deadline__first']}>
          <Field
            name={`${name}.minutesBefore`}
            parse={(value) =>
              typeof value === 'string' ? parseInt(value) : value
            }
          >
            {({ input }) => (
              <SingleDropdown
                options={_.times(100, (minutesBefore) => ({
                  value: (minutesBefore * 5).toString(),
                  text: (minutesBefore * 5).toString(),
                }))}
                selectedOption={input.value.toString()}
                onChange={input.onChange}
              />
            )}
          </Field>
        </div>
      )}
      <div className={styles['p-deadline__second']}>
        <Field name={`${name}.deadlineType`}>
          {({ input }) => (
            <SingleDropdown
              options={[
                ...(allowMinutes
                  ? [{ text: t('minutes before'), value: 'MINUTE' }]
                  : []),
                { text: t('hours before'), value: 'HOUR' },
                { text: t('days before'), value: 'DAY' },
                ...(allowAnytimeDeadline
                  ? [{ text: t('No deadline'), value: 'ETERNAL' }]
                  : []),
              ]}
              selectedOption={input.value}
              onChange={(value) => {
                if (value === 'HOUR') {
                  field.input.onChange({
                    ...field.input.value,
                    deadlineType: 'HOUR',
                    hoursBefore: field.input.value.hoursBefore ?? 24,
                  });
                } else if (value === 'DAY') {
                  field.input.onChange({
                    ...field.input.value,
                    deadlineType: 'DAY',
                    daysBefore: field.input.value.daysBefore ?? 1,
                    timeOfDay: field.input.value.timeOfDay ?? '17:00',
                  });
                } else if (value === 'MINUTE') {
                  field.input.onChange({
                    ...field.input.value,
                    deadlineType: 'MINUTE',
                    minutesBefore: field.input.value.minutesBefore ?? 30,
                  });
                } else {
                  field.input.onChange({
                    ...field.input.value,
                    deadlineType: 'ETERNAL',
                  });
                }
              }}
            />
          )}
        </Field>
      </div>
      {field.input.value.deadlineType === 'DAY' && (
        <div
          className={styles['p-deadline__third']}
          style={{ maxWidth: '80px' }}
        >
          <Field name={`${name}.timeOfDay`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};
