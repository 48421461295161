import {
  SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS,
  SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { eTicketDailyUsesDisplaySettings } from 'client/libraries/util/eTicketDailyUsesSettings';

export const setETicketDailyUsesDisplaySettings = (
  displaySettings: eTicketDailyUsesDisplaySettings
) => ({
  type: SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS,
  displaySettings,
});

export const setETicketDailyUsesVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS,
  visibleColumns,
});
