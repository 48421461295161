import { createSelector } from 'reselect';

import { ReduxState } from 'client/reducers';

export type VisibilityStateByReservationSlot =
  | 'SHOW_ALL'
  | 'SHOW_ITEMS_WITH_ONE_OR_MORE_RESERVATIONS'
  | 'SHOW_ITEMS_WITH_ZERO_RESERVATIONS';

export type VisibilityStateByResource = 'CREW' | 'VEHICLE' | 'MISC' | 'MEMO';

export const reservationSlotVisibilitySettingSelector = createSelector(
  (state: ReduxState) =>
    state.productCalendarListControls.reservationSlotVisibilitySetting,
  (
    visibilitySetting: VisibilityStateByReservationSlot
  ): VisibilityStateByReservationSlot => visibilitySetting
);

export const resourceVisibilitySettingSelector = createSelector(
  (state: ReduxState) =>
    state.productCalendarListControls.resourceVisibilitySetting,
  (
    visibilitySetting: VisibilityStateByResource[]
  ): VisibilityStateByResource[] => [...new Set(visibilitySetting)]
);
