import * as React from 'react';

import { getEmbeddedReviewUrl } from 'client/libraries/util/getEmbeddedReviewUrl';

import { getEmbedTypePath } from '../util';
import type { EmbedType } from '../util';
import './EmbeddedReview.module.css';

type Props = {
  apiKey: string | null;
  productId: string;
  language?: string;
  embedType: EmbedType;
};
export const EmbeddedReview = ({
  apiKey,
  productId,
  language,
  embedType,
}: Props) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://assets.ntmg.com/scripts/falcon-embed-review/v1/index.js`;
    script.async = true;
    (document as any).body.appendChild(script);
    return () => {
      (document as any).body.removeChild(script);
    };
  }, []);
  const embedTypePath = getEmbedTypePath(embedType);
  const url = getEmbeddedReviewUrl(apiKey, productId, embedTypePath, language);
  return (
    <div className="snippet-example">
      <div
        id={`nutmeg-${embedType.toLowerCase()}-${productId}`}
        className={
          embedType === 'STARS'
            ? 'nutmeg-review-stars-enc-div'
            : 'nutmeg-review-summary-enc-div'
        }
      >
        <iframe
          id={`nutmeg-embed-iframe-${embedType.toLowerCase()}-${productId}`}
          src={url}
          className={
            embedType === 'STARS' ? 'nutmeg-review-stars' : 'nutmeg-review'
          }
          title="embedded-review"
          height={30}
        />
      </div>
    </div>
  );
};
