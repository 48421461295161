import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Select, ToggleButton } from 'client/components/Form';
import { getLanguageName } from 'client/libraries/i18n';
import { Box } from 'client/components/Box/Box';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { SourceLanguage } from 'shared/models/swagger';

import { deleteFooterLink } from './util';
import { EditFooterLinkModal } from './EditFooterLinkModal';
import { DraggableFooterLink } from './DraggableFooterLink';
import { CustomizeFormValues, getFooterLinkFormValues } from './FormValues';
import type { FooterLink } from './FormValues';

export const FooterLinksLayoutEditor = () => {
  const { t } = useTranslation();
  const [showEditFooterLinkModal, setShowEditFooterLinkModal] =
    React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));
  const parentFormState = useFormState<CustomizeFormValues>();
  const parentForm = useForm<CustomizeFormValues>();

  const parentFormSaveFooterLink = (newFooterLink: FooterLink) => {
    const existingFooterLinks = parentFormState.values
      ?.footerLinks as FooterLink[];
    parentForm.change(
      'footerLinks',
      existingFooterLinks?.some(
        (footerLink) => footerLink.key === newFooterLink.key
      )
        ? existingFooterLinks?.map((existingFooterLink) =>
            existingFooterLink.key === newFooterLink.key
              ? newFooterLink
              : existingFooterLink
          )
        : [...(existingFooterLinks ?? []), newFooterLink]
    );
  };

  const language = parentFormState.values?.footerLinkLanguage || 'JA_JP';
  const dispatch = useDispatch();
  return (
    <>
      <Box
        ml={-1}
        mb={2}
        flexWrap="wrap"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box ml={1} fontWeight="bold">
          {t('Content Language')}
        </Box>
        <Box ml={1}>
          <Field name="footerLinkLanguage">
            {({ input }) => (
              <Select
                maxWidth={200}
                options={languageOptions}
                value={input.value}
                onChange={(e, { value }) => {
                  parentForm.change(
                    'footerLinks',
                    getFooterLinkFormValues(
                      activeUserOrganization,
                      value as SourceLanguage
                    )
                  );
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
      </Box>
      <FieldArray name="footerLinks">
        {({ fields }) => (
          <>
            <Box mb={2}>
              {t('* drag-and-drop to reorder')}
              <Button
                style="green"
                size="middle"
                onClick={() => setShowEditFooterLinkModal(true)}
              >
                {t('Add footer link')}
              </Button>
            </Box>
            {showEditFooterLinkModal && (
              <EditFooterLinkModal
                name=""
                onSave={(value: FooterLink) => {
                  fields.push(value);
                  parentFormSaveFooterLink(value);
                }}
                open={showEditFooterLinkModal}
                onClose={() => setShowEditFooterLinkModal(false)}
              />
            )}
            {fields.map((name, idx) => (
              <DraggableFooterLink
                key={fields.value?.[idx]?.key}
                name={name}
                index={idx}
                moveItem={(dragIndex: number, hoverIndex: number) => {
                  fields.move(dragIndex, hoverIndex);
                }}
                deleteItem={async () => {
                  await dispatch(
                    deleteFooterLink(fields.value[idx].key, language)
                  );
                  fields.remove(idx);
                }}
                onSave={(value: FooterLink) => parentFormSaveFooterLink(value)}
              />
            ))}
          </>
        )}
      </FieldArray>
      {parentForm.getState().values?.footerLinkLanguage === 'JA_JP' && (
        <Field name="showFooterJapaneseTermsLink" type="checkbox">
          {({ input }) => {
            return (
              <ToggleButton
                checked={input.checked ?? false}
                onChange={() => input.onChange(!input.checked)}
                label={
                  <div>
                    <div>
                      {t(
                        'Show the default "特定商取引法" link on Japanese website.'
                      )}
                    </div>
                    <div>
                      {t(
                        'Note: It is required to show 特定商取引法 page by Japanese law. If you want to hide the default link, please add a link to equivalent page of your organization or create a page using Homepage feature.'
                      )}
                    </div>
                  </div>
                }
              />
            );
          }}
        </Field>
      )}
    </>
  );
};
