import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { PortalTooltip } from 'client/components/PortalTooltip/PortalTooltip';
import { useBounds } from 'client/hooks/useBounds';
import baseStyles from 'client/base.module.css';
import copyIcon from 'client/images/ic_copy.svg';

interface Props {
  text: string;
  onClick?: () => void;
  tooltipText?: string;
}

export const CopyToClipboard = ({ text, onClick, tooltipText }: Props) => {
  const { t } = useTranslation();
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      ref={divRef}
    >
      <button
        className={clsx(
          baseStyles['base-info'],
          baseStyles['base-btn'],
          baseStyles['icon'],
          baseStyles['narrow']
        )}
        onClick={() => {
          onClick?.();
          navigator.clipboard.writeText(text);
          setShowTooltip(false);
        }}
        type="button"
      >
        {showTooltip && (
          <PortalTooltip
            text={tooltipText || t('Copy product page URL to clipboard')}
            style={{
              left: divBounds.left + 40,
              top: divBounds.top,
            }}
          />
        )}
        <img src={copyIcon} />
      </button>
    </div>
  );
};
