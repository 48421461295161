import { useSelector } from 'react-redux';

import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import type { ReduxState } from 'client/reducers';
import type { ResourceType } from 'client/libraries/util/resourceType';
import {
  getFormattedTime,
  getFormattedDateTime,
} from 'client/libraries/util/util';
import { getManifestStartTimeMemoKey } from 'client/libraries/util/memo';
import type {
  ProductSummary,
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import { FlexibleDashboardReservationListShape } from 'client/libraries/util/flexibleDashboardReservationListShape';
import { ReservationListTable } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListTable';

type Props = {
  excludedFormFieldKeys: string[];
  manifestDate?: string;
  products: ProductSummary[];
  reservations: FlexibleDashboardReservationListShape[];
  visibleColumns: ReservationColumn[];
  showTimeOnly?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  onEditResourceButtonClick?: (arg0: string) => void;
  onResourceTextClick?: (
    arg0: string,
    arg1: ResourceType,
    arg2: string
  ) => void;
  showDispatchColumn?: boolean;
  capacity?: number;
  resourceKey?: string;
  resourceType?: ResourceType;
  groupBy?: 'START_TIME' | 'TRANSPORT_ROUTE' | 'PICKUP_TIME';
  gadgetKey: string;
};

const defaultProducts: ProductSummary[] = [];

export const GroupByStartTimeTableList = ({
  manifestDate,
  excludedFormFieldKeys,
  products = defaultProducts,
  reservations,
  visibleColumns,
  showTimeOnly,

  /* TODO(goro) check whether this param must be requried or not */
  customizedColumnNames,
  resourceKey,
  resourceType,
  gadgetKey,
}: Props) => {
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const reservationsByStartTime = groupReservationsByStartTime(
    reservations,
    locale
  );

  return (
    <>
      {reservationsByStartTime.map(({ startTime, date, reservations }, idx) => {
        const formattedStartTime = showTimeOnly
          ? getFormattedTime(startTime, locale)
          : manifestDate === date
          ? getFormattedTime(startTime, locale)
          : getFormattedDateTime(startTime, date, locale);
        const totalPax = reservations.reduce((p, c) => {
          return p + (c.guests || []).length;
        }, 0);
        const memoKey = resourceType
          ? getManifestStartTimeMemoKey(
              resourceType,
              resourceKey ?? '',
              date,
              startTime
            )
          : null;
        return (
          <ReservationListTable
            excludedFormFieldKeys={excludedFormFieldKeys}
            products={products}
            reservations={reservations}
            visibleColumns={visibleColumns as ReservationColumn[]}
            startTime={formattedStartTime}
            totalPax={totalPax}
            key={idx}
            customizedColumnNames={customizedColumnNames}
            memoKey={memoKey}
            gadgetKey={gadgetKey}
          />
        );
      })}
    </>
  );
};
