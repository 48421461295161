import * as React from 'react';

import { getBookingWidgetMultiProductCalendarUrl } from 'client/libraries/util/getBookingWidgetMultiProductCalendarUrl';

import styles from './EmbeddedMultiProductCalendar.module.css';

type Props = {
  apiKey: string | null;
  language?: string;
};

export const EmbeddedMultiProductCalendar = ({ apiKey, language }: Props) => {
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  React.useEffect(() => {
    if (!(window as any).buildMultiCalendarEmbed) {
      const script = document.createElement('script');

      script.src = `https://assets.ntmg.com/scripts/falcon-embed-multi-product/v1/index.js`;
      script.async = true;

      (document as any).body.appendChild(script);

      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);

  const url = getBookingWidgetMultiProductCalendarUrl(apiKey, language);
  return (
    <div className="snippet-example">
      <div
        id="nutmeg-multi-calendar"
        className={styles['nutmeg-multi-calendar-enc-div']}
      >
        <iframe
          id="nutmeg-multi-calendar-embed-iframe"
          src={url}
          className={styles['nutmeg-multi-calendar']}
          title="embedded-calendar"
        />
      </div>
    </div>
  );
};
