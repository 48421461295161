import { MarketingAutomationContentParams } from 'shared/models/swagger';

export type FormValues =
  | {
      title: string;
      type: 'WEBSITE_POPUP';

      contentType:
        | 'PRODUCT'
        | 'PRODUCT_UPGRADE'
        | 'COUPON'
        | 'ANNUAL_PASS'
        | 'MESSAGE'
        | 'PRODUCT_LIST'
        | 'SURVEY'
        | 'POPULAR_PRODUCT_LIST';
      productIds: string[];
      promoCode: string;
      couponId: string;
      thumbnailUrl: string;
      buttonImageUrl: string;
      surveyTemplateId: string;

      displayType: 'INLINE' | 'POPUP' | 'FLOATING_BUTTON';

      // MESSAGE type fields
      messageTitle: string;
      messageDescription: string;
      messageButtonText: string;
      messageButtonDestinationType:
        | 'PRODUCT_PAGE'
        | 'CUSTOM_PAGE'
        | 'FULL_URL'
        | 'PRIVATE_MARKETPLACE';
      messageButtonDestinationUrl: string;
      messageButtonDestinationPagePath: string;
      hideButton: boolean;
      shouldShowInitially: boolean;
    }
  | {
      title: string;
      type: 'EMAIL';

      selectedTemplate: string;
      templateValues: any;
      subject: string;
    }
  | {
      title: string;
      type: 'SMS';

      smsContent: string;
    }
  | {
      title: string;
      type: 'PUSH_NOTIFICATION';

      pushNotificationTitle: string;
      pushNotificationBody: string;
      pushNotificationLinkUrl: string;
      pushNotificationImageUrl: string;
      pushNotificationIconUrl: string;
      pushNotificationBadgeUrl: string;
    };

export const convertFormValuesToSwagger = (
  values: FormValues
): MarketingAutomationContentParams => {
  switch (values.type) {
    case 'EMAIL':
      return {
        title: values.title,
        type: 'EMAIL',
        email_content: {
          email_template_name: values.selectedTemplate,
          email_template_values_json: JSON.stringify(values.templateValues),
          email_subject: values.subject,
        },
      };
    case 'WEBSITE_POPUP':
      return {
        title: values.title,
        type: values.type,
        website_popup_content: {
          content_type: values.contentType,
          product_ids: values.productIds,
          promo_code: values.promoCode,
          coupon_id: values.couponId,
          thumbnail_url: values.thumbnailUrl,
          button_image_url: values.buttonImageUrl,
          survey_template_id: values.surveyTemplateId,
          display_type: values.displayType,
          message_popup_content: {
            title: values.messageTitle,
            description: values.messageDescription,
            button_text: values.messageButtonText,
            button_destination_type: values.messageButtonDestinationType,
            button_destination_url: values.messageButtonDestinationUrl,
            button_destination_page_path:
              values.messageButtonDestinationPagePath,
            image_url: values.thumbnailUrl,
            hide_button: values.hideButton,
          },
          should_show_initially: values.shouldShowInitially,
        },
      };
    case 'SMS':
      return {
        title: values.title,
        type: 'SMS',
        sms_content: {
          sms_text: values.smsContent,
        },
      };
    case 'PUSH_NOTIFICATION':
      return {
        title: values.title,
        type: 'PUSH_NOTIFICATION',
        push_notification_content: {
          title: values.pushNotificationTitle,
          body: values.pushNotificationBody,
          link_url: values.pushNotificationLinkUrl,
          image_url: values.pushNotificationImageUrl,
          icon_url: values.pushNotificationIconUrl,
          badge_url: values.pushNotificationBadgeUrl,
        },
      };
  }
};

export const convertSwaggerToFormValues = (
  content: MarketingAutomationContentParams
): FormValues => {
  switch (content.type) {
    case 'WEBSITE_POPUP':
      return {
        title: content.title ?? '',
        type: 'WEBSITE_POPUP',
        contentType: content.website_popup_content?.content_type ?? 'PRODUCT',
        productIds: content.website_popup_content?.product_ids ?? [],
        promoCode: content.website_popup_content?.promo_code ?? '',
        couponId: content.website_popup_content?.coupon_id ?? '',
        thumbnailUrl: content.website_popup_content?.thumbnail_url ?? '',
        buttonImageUrl: content.website_popup_content?.button_image_url ?? '',
        surveyTemplateId:
          content.website_popup_content?.survey_template_id ?? '',
        displayType: content.website_popup_content?.display_type ?? 'INLINE',
        messageTitle:
          content.website_popup_content?.message_popup_content?.title ?? '',
        messageDescription:
          content.website_popup_content?.message_popup_content?.description ??
          '',
        messageButtonText:
          content.website_popup_content?.message_popup_content?.button_text ??
          '',
        messageButtonDestinationType:
          content.website_popup_content?.message_popup_content
            ?.button_destination_type ?? 'PRODUCT_PAGE',
        messageButtonDestinationUrl:
          content.website_popup_content?.message_popup_content
            ?.button_destination_url ?? '',
        messageButtonDestinationPagePath:
          content.website_popup_content?.message_popup_content
            ?.button_destination_page_path ?? '',
        hideButton:
          content.website_popup_content?.message_popup_content?.hide_button ??
          false,
        shouldShowInitially:
          content.website_popup_content?.should_show_initially ?? false,
      };
    case 'EMAIL':
      return {
        title: content.title ?? '',
        type: 'EMAIL',
        selectedTemplate: content.email_content?.email_template_name ?? '',
        templateValues: JSON.parse(
          content.email_content?.email_template_values_json ?? '{}'
        ),
        subject: content.email_content?.email_subject ?? '',
      };
    case 'SMS':
      return {
        title: content.title ?? '',
        type: 'SMS',
        smsContent: content.sms_content?.sms_text ?? '',
      };
    case 'PUSH_NOTIFICATION':
      return {
        title: content.title ?? '',
        type: 'PUSH_NOTIFICATION',
        pushNotificationTitle: content.push_notification_content?.title || '',
        pushNotificationBody: content.push_notification_content?.body || '',
        pushNotificationLinkUrl:
          content.push_notification_content?.link_url || '',
        pushNotificationImageUrl:
          content.push_notification_content?.image_url || '',
        pushNotificationIconUrl:
          content.push_notification_content?.icon_url || '',
        pushNotificationBadgeUrl:
          content.push_notification_content?.badge_url || '',
      };
  }

  return {
    title: '',
    type: 'EMAIL',
    selectedTemplate: '',
    templateValues: {},
    subject: '',
  };
};
