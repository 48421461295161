import clsx from 'clsx';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useContext } from 'react';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { DurationInput } from 'client/components/v3/DurationInput/DurationInput';
import { DedicatedAllotmentEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/DedicatedAllotmentEditor';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import type { StartTime } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';

const freesaleSlotLimit = 10000000;

const getDefaultStartTime = (): StartTime => {
  return {
    time: '9:00',
    duration: '3:00',
    allotmentSlots: 10,
    description: '',
    perChannelAllotments: [],
    packageComponentTimeSlots: [],
  };
};

type Props = {
  name?: string;
  showAllotment: boolean;
};
export const StartTimesInput = ({ name, showAllotment }: Props) => {
  const { t } = useTranslation();
  const namePrefix = name ? `${name}.` : '';
  const isProductEdit = location.pathname.includes('edit');
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const formState = useFormState();
  const { setReservationParamsFormValues } = useContext(ProductHelperContext);
  setReservationParamsFormValues(formState.values as any);

  return (
    <>
      <FieldArray name={`${namePrefix}startTimes`}>
        {({ fields }) => {
          return (
            <>
              {fields.map((name, idx) => {
                return (
                  <CollapsibleBox
                    key={name}
                    title={`#${idx + 1} ${fields.value[idx].time}`}
                    isDeleteable={idx !== 0}
                    onDelete={() => fields.remove(idx)}
                  >
                    <ul className={styles['p-box__table3__item__list']}>
                      <li className={styles['p-box__table3__item__list__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                          data-title={t('Start Time')}
                        >
                          {t('Start Time')}
                          <Tooltip
                            text={t(
                              'Enter the estimated start time of the experience. You may register multiple times.'
                            )}
                          >
                            <i className="c-icon-outline-general-info-circle"></i>
                          </Tooltip>
                        </p>
                        <Field name={`${name}.time`}>
                          {({ input }) => (
                            <TimePicker
                              allowEmpty={false}
                              value={moment(input.value, 'HH:mm')}
                              onChange={(newMoment) => {
                                if (!newMoment) {
                                  return;
                                }

                                input.onChange(newMoment.format('HH:mm'));
                              }}
                            />
                          )}
                        </Field>
                      </li>
                      <li className={styles['p-box__table3__item__list__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                          data-title={t('Duration')}
                        >
                          {t('Duration')}
                          <Tooltip
                            text={t(
                              'Enter the approximate length of the experience. You may set different lengths for each start time.'
                            )}
                          >
                            <i className="c-icon-outline-general-info-circle"></i>
                          </Tooltip>
                        </p>
                        <Field name={`${name}.duration`}>
                          {({ input }) => <DurationInput required {...input} />}
                        </Field>
                      </li>
                      {showAllotment && (
                        <li
                          className={styles['p-box__table3__item__list__item']}
                        >
                          <p
                            className={
                              styles['p-box__table3__item__body__item__ttl']
                            }
                            // TODO: not sure why data title is different here
                            data-title={t('Capacity')}
                          >
                            {t('Inventory Count')}
                            <Tooltip
                              text={t(
                                'The number of people for which you can accept reservations automatically. Please enter the capacity.'
                              )}
                            >
                              <i className="c-icon-outline-general-info-circle"></i>
                            </Tooltip>
                          </p>
                          <Field name={`${name}.allotmentSlots`}>
                            {({ input }) => (
                              <TextField
                                disabled={fields.value[idx].isFreesale}
                                value={
                                  input.value >= freesaleSlotLimit
                                    ? '-'
                                    : input.value
                                }
                                onChange={(value) => {
                                  if (!isInteger(value)) {
                                    return;
                                  }

                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                          <div
                            className={clsx(
                              baseStyles['u-mt-2'],
                              styles['p-timesAndInventory']
                            )}
                          >
                            <Toggle
                              label={t('Unlimited Inventory')}
                              checked={fields.value[idx].isFreesale}
                              onChange={() => {
                                if (fields.value[idx].isFreesale) {
                                  fields.update(idx, {
                                    ...fields.value[idx],
                                    isFreesale: false,
                                    allotmentSlots: 10,
                                  });
                                } else {
                                  fields.update(idx, {
                                    ...fields.value[idx],
                                    isFreesale: true,
                                    allotmentSlots: freesaleSlotLimit,
                                    perChannelAllotments: [],
                                  });
                                }
                              }}
                              size="sm"
                            />
                            <Toggle
                              label={t('Request Booking')}
                              checked={!fields.value[idx].allotmentSlots}
                              onChange={() => {
                                if (fields.value[idx].allotmentSlots) {
                                  fields.update(idx, {
                                    ...fields.value[idx],
                                    allotmentSlots: 0,
                                  });
                                } else {
                                  fields.update(idx, {
                                    ...fields.value[idx],
                                    allotmentSlots: 10,
                                  });
                                }
                              }}
                              disabled={fields.value[idx].isFreesale}
                              size="sm"
                            />
                          </div>
                        </li>
                      )}
                      <li className={styles['p-box__table3__item__list__item']}>
                        <p
                          className={
                            styles['p-box__table3__item__body__item__ttl']
                          }
                          data-title={t('Supplemental Note')}
                        >
                          {t('Supplemental Note')}
                          <Tooltip
                            text={t(
                              'Used when there is extra context for the start time. Example: range of business hours (~ 18: 00), final reception, etc.'
                            )}
                          >
                            <i className="c-icon-outline-general-info-circle"></i>
                          </Tooltip>
                        </p>
                        <TranslatedField name={`${name}.description`}>
                          {({ input }) => (
                            <>
                              <TextField {...input} />
                            </>
                          )}
                        </TranslatedField>
                      </li>
                      {isNutmegAdmin && (
                        <li
                          className={styles['p-box__table3__item__list__item']}
                        >
                          <p
                            className={
                              styles['p-box__table3__item__body__item__ttl']
                            }
                            data-title={t('Third Party Reference')}
                          >
                            {t('Third Party Reference')}
                          </p>
                          <Field name={`${name}.referenceInSupplierSystem`}>
                            {({ input }) => <TextField {...input} />}
                          </Field>
                        </li>
                      )}
                      {isProductEdit &&
                        showAllotment &&
                        !fields.value[idx].isFreesale && (
                          <li
                            className={
                              styles['p-box__table3__item__list__item']
                            }
                          >
                            <DedicatedAllotmentEditor
                              name={`${name}.perChannelAllotments`}
                            />
                          </li>
                        )}
                    </ul>
                  </CollapsibleBox>
                );
              })}
              <div className={baseStyles['u-mt-4']}>
                <a
                  className={styles['p-box__table2__actions__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(
                        fields.length + 1,
                        getDefaultStartTime()
                      );
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Times and Inventory')}
                </a>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};
