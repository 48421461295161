import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';
import { fetchETicketFootprints } from 'client/actions/eTicketFootprints';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import {
  ListETicketFootprintsRequest,
  DownloadETicketFootprintCSVRequest,
} from 'shared/models/swagger';

export type DateFilterPreset =
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'CHECKIN_1_DAY'
  | 'CHECKIN_3_DAYS'
  | 'CHECKIN_7_DAYS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
      'CHECKIN_1_DAY',
      'CHECKIN_3_DAYS',
      'CHECKIN_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'CHECKIN_1_DAY':
      return t('Redemption, past {{count}} days', {
        count: 1,
      });

    case 'CHECKIN_3_DAYS':
      return t('Redemption, past {{count}} days', {
        count: 3,
      });

    case 'CHECKIN_7_DAYS':
      return t('Redemption, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
  }

  return '';
};
export type SearchETicketFootprintsRequest = {
  agentReference: string;
  supplierReference: string;
  customerGivenName: string;
  customerFamilyName: string;
  productIds: string[];
  participationDateFrom: string;
  participationDateTo: string;
  checkinDateFrom: string;
  checkinDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
  nearestLocationNameText: string;
};
export const convertSearchETicketFootprintsRequestToQueryParams = (
  req: SearchETicketFootprintsRequest
): ListETicketFootprintsRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let checkinDateTimeUtcFrom = '';
  let checkinDateTimeUtcTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'CHECKIN_1_DAY':
        checkinDateTimeUtcFrom = now.subtract(1, 'days').utc().format();
        break;

      case 'CHECKIN_3_DAYS':
        checkinDateTimeUtcFrom = now.subtract(3, 'days').utc().format();
        break;

      case 'CHECKIN_7_DAYS':
        checkinDateTimeUtcFrom = now.subtract(7, 'days').utc().format();
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    checkinDateTimeUtcFrom = req.checkinDateFrom
      ? moment(req.checkinDateFrom).utc().format()
      : '';
    checkinDateTimeUtcTo = req.checkinDateTo
      ? moment(req.checkinDateTo).add(1, 'day').utc().format()
      : '';
  }

  let givenName = '';
  let kanaGivenName = '';
  if (isKanaWord(req.customerGivenName)) {
    kanaGivenName = req.customerGivenName;
  } else {
    givenName = req.customerGivenName;
  }

  let familyName = '';
  let kanaFamilyName = '';
  if (isKanaWord(req.customerFamilyName)) {
    kanaFamilyName = req.customerFamilyName;
  } else {
    familyName = req.customerFamilyName;
  }

  return {
    reservation_start_date_local_from: startDateLocalFrom,
    reservation_start_date_local_to: startDateLocalTo,
    footprint_date_time_utc_from: checkinDateTimeUtcFrom,
    footprint_date_time_utc_to: checkinDateTimeUtcTo,
    product_ids: req.productIds,
    reservation_agent_reference: req.agentReference,
    reservation_supplier_reference: req.supplierReference,
    reservation_guest_given_name: givenName,
    reservation_guest_kana_given_name: kanaGivenName,
    reservation_guest_family_name: familyName,
    reservation_guest_kana_family_name: kanaFamilyName,
  };
};

export const convertSearchETicketFootprintsRequestToDownloadQueryParams = (
  req: SearchETicketFootprintsRequest
): DownloadETicketFootprintCSVRequest => {
  const fetchParams = convertSearchETicketFootprintsRequestToQueryParams(req);

  return {
    ...fetchParams,
  };
};

export const searchETicketFootprints = (req: SearchETicketFootprintsRequest) =>
  fetchETicketFootprints(
    convertSearchETicketFootprintsRequestToQueryParams(req)
  );
