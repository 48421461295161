import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';
import { getJalanErrorReservationStatusText } from 'client/pages/JaranImport/util';
import type { JaranReservationImportOrder } from 'shared/models/swagger';

type Props = {
  order?: JaranReservationImportOrder;
  onClose: () => void;
};

export const ImportResultModal = ({ order, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        insertRoot
        title={t('Import: Error Data')}
        open={true}
        onClose={onClose}
      >
        <div>
          {t('File Name')}: {order?.file_name ?? ''}
        </div>
        <div className={styles['c-table-list']} style={{ textAlign: 'center' }}>
          <table>
            <tr>
              <th style={{ textAlign: 'center' }}>
                {t('Jalan Reservation Number')}
              </th>
              <th style={{ textAlign: 'center' }}>{t('Reason')}</th>
            </tr>
            {(order?.error_jaran_reservations ?? []).map((o, idx) => (
              <tr key={idx}>
                <td style={{ textAlign: 'center' }}>
                  {o.jaran_reservation_id}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {getJalanErrorReservationStatusText(
                    t,
                    o?.error_jaran_reservation_status
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <Modal.Actions>
          <Button
            style="blue"
            size="middle"
            type="submit"
            onClick={() => onClose?.()}
          >
            {t('OK')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
