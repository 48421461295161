import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import type { Subscription, SubscriptionKey } from 'shared/models/swagger';

import styles from './SubscriptionDisplay.module.css';
import { SubscriptionPriceDisplay } from './SubscriptionPriceDisplay';

type Props = {
  subscription: Subscription;
};
export const SubscriptionDisplay = ({ subscription }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['status-text']}>{t('Subscription is Active')}</div>
      {subscription.status === 'ACTIVE' &&
        subscription.period_end_behavior === 'RENEW' && (
          <div>
            <Box mt={2} mb={2}>
              {t(
                'Your subscription is set to automatically renew at {{date}}.',
                {
                  date: moment(
                    subscription.current_period_end_date_time_utc
                  ).format('YYYY/MM/DD hh:mm'),
                }
              )}
            </Box>
            <Box mt={2} mb={2}>
              {t('Please contact us to change or cancel your subscription: ')}
              <a href="mailto:support@nutmeglabs.com">support@nutmeglabs.com</a>
            </Box>
          </div>
        )}
      {subscription.status === 'ACTIVE' &&
        subscription.period_end_behavior === 'CANCEL' && (
          <div>
            <Box mt={2} mb={2}>
              {t(
                'Your subscription has been cancelled and will remain active until {{date}}.',
                {
                  date: moment(
                    subscription.current_period_end_date_time_utc
                  ).format('YYYY/MM/DD hh:mm'),
                }
              )}
            </Box>
            <Box mt={2} mb={2}>
              {t('Please contact us to change your subscription: ')}
              <a href="mailto:support@nutmeglabs.com">support@nutmeglabs.com</a>
            </Box>
          </div>
        )}
      {subscription.status === 'ACTIVE' && (
        <SubscriptionPriceDisplay
          subscriptionKey={subscription.key as SubscriptionKey}
        />
      )}
    </div>
  );
};
