import * as React from 'react';

import baseStyles from 'client/base.module.css';

type Props = {
  error?: string;
};

export const FormError = ({ error }: Props) => {
  if (!error) return null;
  return <p className={baseStyles['base-form-box__err']}>{error}</p>;
};
