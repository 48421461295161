import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { currencyAmountInputHelper } from 'client/libraries/util/coreutil';
import { Input, TextArea, Button, Checkbox } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { ReduxState } from 'client/reducers';
import styles from 'client/pages/BookingForm/Book.module.css';
import type { NewReservation } from 'shared/models/swagger';

type Props = {
  header?: string;
  currency: string;
  onClose?: () => any;
  reservationParams: NewReservation;
  isChangeReservation?: boolean;
  adjustmentAmount: NewReservation['adjustment_amount'];
  saveAdjustingFareParamsNewReservation: (arg0: any) => void;
};

type State = {
  gross: string;
  net: string;
  notes: string;
  is_refund: boolean;
};

export const ReservationFareAdjustmentModal = ({
  header,
  currency,
  onClose,
  reservationParams,
  adjustmentAmount,
  saveAdjustingFareParamsNewReservation,
}: Props) => {
  const { t } = useTranslation();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );

  const [amountGrossError, setAmountGrossError] =
    React.useState<boolean>(false);

  const helper = currencyAmountInputHelper(currency);
  const [state, setState] = React.useState<State>({
    gross: helper.moneyInput(adjustmentAmount?.gross?.substring(3) ?? '0'),
    net: helper.moneyInput(adjustmentAmount?.net?.substring(3) ?? '0'),
    notes: adjustmentAmount?.notes ?? '',
    is_refund: adjustmentAmount?.is_refund ?? false,
  });

  const editNet = reservationParams?.booking_source?.source_type == 'AGENT';
  const [totalGross, setTotalGross] = React.useState<string>(
    billingInfo?.amount_gross?.substring(3) ?? '0'
  );
  const [totalNet, setTotalNet] = React.useState<string>(
    billingInfo?.amount_net?.substring(3) ?? '0'
  );

  const validateForm = () => {
    const { gross, net } = state;
    return (
      parseFloat(gross) !== 0 &&
      !helper.inputInvalid(helper.moneyInput(gross)) &&
      !helper.inputInvalid(helper.moneyInput(net))
    );
  };

  React.useEffect(() => {
    if (state.is_refund) {
      setTotalGross(
        helper.diff(billingInfo?.amount_gross?.substring(3) ?? '0', state.gross)
      );
      setTotalNet(
        helper.diff(billingInfo?.amount_net?.substring(3) ?? '0', state.net)
      );
    } else {
      setTotalGross(
        helper.add(billingInfo?.amount_gross?.substring(3) ?? '0', state.gross)
      );
      setTotalNet(
        helper.add(billingInfo?.amount_net?.substring(3) ?? '0', state.net)
      );
    }
  }, [state.gross, state.net, state.is_refund]);

  return (
    <Modal
      title={header ?? ''}
      open={true}
      onClose={() => {
        saveAdjustingFareParamsNewReservation({});
        onClose?.();
      }}
      insertRoot={true}
    >
      <Modal.Content>
        <Modal.Box column="two">
          <Checkbox
            label={t('Additional Charge')}
            name="radioGroup"
            value="Charge"
            checked={!state.is_refund}
            onChange={() => {
              setState({
                ...state,
                is_refund: false,
              });
            }}
          />
          <Checkbox
            label={t('Discount')}
            name="radioGroup"
            value="Refund"
            checked={state.is_refund}
            onChange={() => {
              setState({
                ...state,
                is_refund: true,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Gross') + ` (${currency})`}
            value={state.gross}
            error={amountGrossError ? t('Invalid') : ''}
            type="string"
            onChange={(e: any) => {
              const v = e.target.value;
              if (!helper.inputAllowed(v)) {
                return;
              }
              const moneyInput = helper.moneyInput(v);
              if (
                helper.inputInvalid(moneyInput) ||
                parseFloat(moneyInput) === 0
              ) {
                setAmountGrossError(true);
              } else {
                setAmountGrossError(false);
              }
              setState({
                ...state,
                gross: moneyInput,
                net: moneyInput,
              });
            }}
          />
          <Input
            label={t('Net') + ` (${currency})`}
            value={state.net}
            error={helper.inputInvalid(state.net) ? t('Invalid') : ''}
            type="string"
            onChange={(e: any) => {
              const v = e.target.value;
              if (!helper.inputAllowed(v)) {
                return;
              }
              const moneyInput = helper.moneyInput(v);
              setState({
                ...state,
                net: moneyInput,
              });
            }}
            disabled={!editNet}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <div>
            <p className={clsx(styles['fare-summary-table__bold'])}>
              {t('Total Gross')}
            </p>
            <p>{helper.moneyString(totalGross)}</p>
          </div>
          <div>
            <p className={clsx(styles['fare-summary-table__bold'])}>
              {t('Total Net')}
            </p>
            <p>{helper.moneyString(totalNet)}</p>
          </div>
        </Modal.Box>

        <Modal.Box>
          <TextArea
            label={t('Adjustment Notes')}
            value={state.notes}
            onChange={(e: any) => {
              setState({
                ...state,
                notes: e.target.value,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            saveAdjustingFareParamsNewReservation({});
            onClose?.();
          }}
        >
          {t('Delete fare adjustment')}
        </Button.Cancel>
        <Button
          type="submit"
          style="blue"
          size="middle"
          onClick={() => {
            saveAdjustingFareParamsNewReservation({
              gross: helper.moneyString(state.gross),
              net: helper.moneyString(state.net),
              notes: state.notes,
              is_refund: state.is_refund,
            });
            onClose?.();
          }}
          disabled={!validateForm()}
        >
          {t('Confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
