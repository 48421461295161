import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_EQUIPMENT_INSTANCES_FAILURE,
  FETCH_EQUIPMENT_INSTANCES_REQUEST,
  FETCH_EQUIPMENT_INSTANCES_SUCCESS,
  UPDATE_EQUIPMENT_INSTANCE_SUCCESS,
  UPDATE_EQUIPMENT_INSTANCE_FAILURE,
  UPDATE_EQUIPMENT_INSTANCE_REQUEST,
  CLEAR_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS,
  BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE,
  BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST,
  BATCH_EDIT_EQUIPMENT_INSTANCES_SUCCESS,
  BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  EquipmentInstance,
  ListEquipmentInstancesResponse,
  UpdateEquipmentInstanceResponse,
} from 'shared/models/swagger';

export const equipmentInstancesSelector = (state: ReduxState) =>
  state.equipmentInstances.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_FAILURE:
    case UPDATE_EQUIPMENT_INSTANCE_FAILURE:
      return action.error;

    case FETCH_EQUIPMENT_INSTANCES_SUCCESS:
    case UPDATE_EQUIPMENT_INSTANCE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_REQUEST:
    case UPDATE_EQUIPMENT_INSTANCE_REQUEST:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST:
      return true;

    case FETCH_EQUIPMENT_INSTANCES_FAILURE:
    case FETCH_EQUIPMENT_INSTANCES_SUCCESS:
    case UPDATE_EQUIPMENT_INSTANCE_FAILURE:
    case UPDATE_EQUIPMENT_INSTANCE_SUCCESS:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const updateLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case UPDATE_EQUIPMENT_INSTANCE_REQUEST:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST:
      return true;
    case UPDATE_EQUIPMENT_INSTANCE_FAILURE:
    case UPDATE_EQUIPMENT_INSTANCE_SUCCESS:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const batchEditLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST:
      return true;
    case BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE:
    case BATCH_EDIT_EQUIPMENT_INSTANCES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const batchCloseLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST:
      return true;
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const all = (
  state: EquipmentInstance[] = [],
  action: any
): EquipmentInstance[] => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_SUCCESS: {
      const response = action.response as ListEquipmentInstancesResponse;
      const newIds = (response.equipment_instances ?? []).map(
        (equipment_instance) => equipment_instance.id
      );
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...(response.equipment_instances ?? []),
      ];
    }
    case UPDATE_EQUIPMENT_INSTANCE_SUCCESS: {
      const response = action.response as EquipmentInstance;
      return [...state.filter((i) => i.id !== response.id), response];
    }
    case CLEAR_EQUIPMENT_INSTANCES_REQUEST: {
      return [];
    }
    default:
      return state;
  }
};

const ids = (state: Record<string, string[]>, equipmentId: string) => {
  return state[equipmentId] || [];
};

const byEquipmentIdInstanceIds = (
  state: Record<string, string[]> = {},
  action: any
) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_INSTANCES_SUCCESS: {
      const response = action.response as any as ListEquipmentInstancesResponse;
      return {
        ...state,
        [response.equipment_id ?? '']: [
          ...new Set([
            ...response.equipment_instances.map((p) => p.id),
            ...(state[response.equipment_id ?? ''] || []),
          ]),
        ],
      };
    }

    case UPDATE_EQUIPMENT_INSTANCE_SUCCESS: {
      const response =
        action.response as any as UpdateEquipmentInstanceResponse;
      return {
        ...state,
        [response.equipment_id ?? '']: [
          ...new Set([
            ...ids(state, response.equipment_id ?? '').filter(
              (id) => id !== response.id
            ),
            response.id,
          ]),
        ],
      };
    }

    default:
      return state;
  }
};

const batchCloseError = (state = '', action: any) => {
  switch (action.type) {
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST:
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS:
      return '';
    case BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE:
      return action.error;
    default:
      return state;
  }
};

export const equipmentInstances = combineReducers({
  error,
  loading,
  all,
  updateLoading,
  byEquipmentIdInstanceIds,
  batchCloseError,
  batchEditLoading,
  batchCloseLoading,
});
