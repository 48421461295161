import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { Button, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';

import { ConditionRuleEditor } from './ConditionRuleEditor';
import { ConditionRule, getDefaultConditionRule } from './FormValues';

const getConditionRuleText = (conditionRule: ConditionRule) => {
  return `${conditionRule.participationStartDate} - ${conditionRule.participationEndDate}`;
};

export const ConditionRuleListEditor = () => {
  const { t } = useTranslation();
  const [activeRuleIndex, setActiveRuleIndex] = React.useState(0);

  return (
    <FieldArray name="conditionRules">
      {({ fields }) => (
        <>
          <Select
            label={t('Select')}
            options={fields.value.map((conditionRule, index) => ({
              value: `${index}`,
              text: getConditionRuleText(conditionRule),
            }))}
            value={`${activeRuleIndex}`}
            onChange={(e, { value }) => {
              setActiveRuleIndex(parseInt(value));
            }}
          />
          <Box display="flex">
            <Button
              onClick={() => {
                fields.push(getDefaultConditionRule());
                setActiveRuleIndex(fields.length ?? 0);
              }}
              size="small"
              style="green"
            >
              {t('Add Condition Rule')}
            </Button>
            {fields.value.length > 1 && (
              <a
                className={baseStyles['base-selectFrame__header__delete']}
                onClick={() => {
                  fields.remove(activeRuleIndex);
                  setActiveRuleIndex(0);
                }}
              >
                {t('Remove this Rule')}
              </a>
            )}
          </Box>
          <ConditionRuleEditor name={`conditionRules.${activeRuleIndex}`} />
        </>
      )}
    </FieldArray>
  );
};
