import * as React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import {
  getEquipmentBlockInstanceProperty,
  getEquipmentBlockInstancePrice,
  getCorrectedPopupWindowPosition,
} from './utils';
import styles from './SeatAssignment.module.css';

interface Props {
  position: { left: number; top: number } | null | undefined;
  open: boolean;
  onSeatMap?: boolean;
  hoveredEquipmentBlockInstanceKey?: {
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  } | null;
}

export const UnAssignedSeatHoverPopupWindow = ({
  position,
  open,
  onSeatMap,
  hoveredEquipmentBlockInstanceKey,
}: Props) => {
  const { t } = useTranslation();
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipments = useSelector(equipmentsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [popupWindowOpen, setPopupWindowOpen] = React.useState<{
    top: string;
    left: string;
  } | null>(null);

  const hoveredEquipmentInstance = equipmentInstances.find(
    (equipmentInstance) =>
      equipmentInstance.id ===
      hoveredEquipmentBlockInstanceKey?.equipmentInstanceId
  );

  const hoveredEquipment = equipments.find(
    (equipment) => equipment.id === hoveredEquipmentInstance?.equipment_id
  );

  const property = getEquipmentBlockInstanceProperty(
    hoveredEquipmentBlockInstanceKey?.equipmentBlockInstanceKey ?? '',
    hoveredEquipment,
    hoveredEquipmentInstance,
    activeUserOrganization
  );

  let equipmentBlockInstanceAttributeTitle = '';
  if (property?.equipment_block_instance_attribute_key) {
    equipmentBlockInstanceAttributeTitle =
      activeUserOrganization?.seat_management_settings?.equipment_block_instance_attributes?.find(
        (attribute) =>
          attribute.key === property?.equipment_block_instance_attribute_key
      )?.title ?? '';
  }

  const equipmentBlockInstancePriceMapping = getEquipmentBlockInstancePrice(
    hoveredEquipmentBlockInstanceKey?.equipmentBlockInstanceKey ?? '',
    hoveredEquipment
  );
  let equipmentBlockInstancePriceTitle = '';
  if (equipmentBlockInstancePriceMapping) {
    equipmentBlockInstancePriceTitle =
      activeUserOrganization?.seat_management_settings?.equipment_block_instance_prices?.find(
        (price) =>
          price.key ===
          equipmentBlockInstancePriceMapping?.equipment_block_instance_price_key
      )?.title ?? '';
  }

  const hoverRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (hoverRef?.current) {
      const correctedPosition = getCorrectedPopupWindowPosition(
        hoverRef?.current?.clientHeight ?? 150,
        position
      );
      setPopupWindowOpen({
        left: `${correctedPosition.left}px`,
        top: `${correctedPosition.top}px`,
      });
    }
  }, [hoverRef?.current, position]);

  if (!position) {
    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <div
      className={clsx(styles['hover'])}
      ref={hoverRef}
      style={{
        ...(onSeatMap
          ? {
              right: '43px',
              position: 'absolute',
            }
          : {
              top: popupWindowOpen?.top,
              left: popupWindowOpen?.left,
            }),
      }}
    >
      <ul>
        <li>
          <p>{t('Source')}</p>
          <p>{equipmentBlockInstanceAttributeTitle}</p>
        </li>
        <li>
          <p>{t('Additional Price')}</p>
          <p>{equipmentBlockInstancePriceTitle}</p>
        </li>
      </ul>
    </div>
  );
};
