// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';

import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { activeUserSelector } from 'client/reducers/user';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { getProductSalesStatus } from 'client/libraries/util/getProductSalesStatus';
import type { ReduxState } from 'client/reducers';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ProductSummary } from 'shared/models/swagger';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';

type FilterColumn =
  | 'product_name'
  | 'internal_product_name'
  | 'supplier_name'
  | 'supplier_reference'
  | 'product_tags'
  | 'internal_product_tags';

type ProductSummaryWithBookmark = { ...ProductSummary, bookmarked: boolean };

const filterProducts = (
  inputText: string,
  columns: FilterColumn[],
  productSummaries: ProductSummaryWithBookmark[]
): ProductSummaryWithBookmark[] => {
  if (!inputText) {
    return productSummaries;
  }
  return productSummaries.filter((productSummary) => {
    return inputText.split(/\s+/).every((input) => {
      return !!columns.find((column) => {
        if (!productSummary[column]) {
          return false;
        }
        if (typeof productSummary[column] === 'string') {
          return productSummary[column]
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        if (typeof productSummary[column] === 'object') {
          return !!productSummary[column].find((element) => {
            return element.toLowerCase().includes(input.toLowerCase());
          });
        }
        return false;
      });
    });
  });
};

type Props = {
  filterText: string,
  columns: CustomTableColumn[],
  activeTab?: SalesStatus,
};

export const ProductCustomTable = ({
  filterText,
  columns,
  activeTab,
}: Props) => {
  const allProducts: ProductSummaryWithBookmark[] = useSelector(
    summariesWithBookmarksSelector
  );
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const items = React.useMemo(() => {
    let salesStatusProducts;
    if (activeTab != null) {
      salesStatusProducts = allProducts.filter((product) => {
        return getProductSalesStatus(product) === activeTab;
      });
    } else {
      salesStatusProducts = allProducts;
    }

    const filterTarget =
      activeUser?.organization_type === 'AGENT'
        ? ['product_name', 'supplier_name']
        : [
            'product_name',
            'internal_product_name',
            'supplier_reference',
            'product_tags',
            'internal_product_tags',
          ];

    return filterProducts(filterText, filterTarget, salesStatusProducts);
  }, [filterText, activeUser, allProducts, activeTab]);

  return (
    <CustomTable
      items={items}
      columns={columns}
      locale={locale}
      usePaging={true}
      useScrollButton={true}
    />
  );
};
