import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';

export const AccessSummaryGadgetEditor = () => {
  const { t } = useTranslation();

  return (
    <>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Default Date Range')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.dateRange">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  {
                    value: 'PREV_7_DAYS',
                    text: t('Last {{count}} days', { count: 7 }),
                  },
                  {
                    value: 'PREV_28_DAYS',
                    text: t('Last {{count}} days', { count: 28 }),
                  },
                  {
                    value: 'PREV_3_MONTHS',
                    text: t('Last {{count}} months', { count: 3 }),
                  },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
    </>
  );
};
