import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { ReduxState } from 'client/reducers';
import { SystemFeeLineItem } from 'shared/models/swagger';
import { activeUserSelector } from 'client/reducers/user';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { fetchProducts } from 'client/actions/products';
import { fetchSystemFeeInvoice } from 'client/actions/systemFeeInvoices';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { useQuery } from 'client/hooks/useQuery';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { AccordionItem } from 'client/components/Accordion/Accordion';
import { PartnershipAgentSystemFeeInvoiceDownloadCSVModal } from 'client/components/PartnershipSystemFee/PartnershipAgentSystemFeeInvoiceDownloadCSVModal';
import anotherIcon from 'client/images/ic_another.svg';

import { PartnershipAgentSystemFeeInvoice } from '../PartnershipAgentSystemFeeInvoices/PartnershipAgentSystemFeeInvoice';

import { useAgentSystemFeeLineItems } from './useAgentSystemFeeLineItems';
import { FilterDisplayBox } from './FilterDisplayBox';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign: 'right' | 'center' | 'left';
};

const getGuestName = (lineItem: SystemFeeLineItem): string => {
  const givenName = lineItem.given_name;
  const lastName = lineItem.family_name;
  const kanaGivenName = lineItem.kana_given_name;
  const kanaLastName = lineItem.kana_family_name;

  let customerName = '';
  if (givenName || lastName) {
    customerName = `${givenName} ${lastName}`;

    if (kanaGivenName || kanaLastName) {
      customerName =
        customerName + ' ' + `（${kanaLastName} ${kanaGivenName}）`;
    }
  } else {
    customerName = `${kanaLastName} ${kanaGivenName}`;
  }

  return customerName;
};

const getGuestDescription = (
  guestCounts: SystemFeeLineItem['unit_counts']
): string => {
  return (
    guestCounts
      ?.map(
        (guestCount) => `${guestCount.guest_type_title} x ${guestCount.count}`
      )
      .join(', ') ?? ''
  );
};

const getTotalPax = (guestCounts: SystemFeeLineItem['unit_counts']): number => {
  let count = 0;

  for (const guestCount of guestCounts ?? []) {
    count += guestCount.count ?? 0;
  }

  return count;
};

const useColumns = (): ColumnType<SystemFeeLineItem>[] => {
  const { t } = useTranslation();

  const contracts = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return [
    {
      Header: '',
      id: 'edit',
      width: 'short',
      th: true,
      Cell: (cellInfo: any) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/partnership/reservations/${cellInfo.original.reservation_id}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </a>
      ),
      textAlign: 'center',
    },
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference ?? '-',
      width: 'middle',
      Cell: (cellInfo) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/partnership/reservations/${cellInfo.original.reservation_id}`}
        >
          {cellInfo.original.agent_reference}
        </a>
      ),
      textAlign: 'center',
    },
    {
      Header: t('Supplier'),
      id: 'supplier',
      accessor: (row) =>
        contracts.find((c) => c.id === row.supplier_id)?.name ?? '-',
      textAlign: 'center',
    },
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: (row) =>
        moment(row.booked_date_time_utc).locale(locale).format('lll'),
      textAlign: 'center',
    },
    {
      Header: t('Participation'),
      id: 'participation',
      accessor: (row) => {
        if (row.is_free_start_date_time && row.first_checkin_date_time_utc) {
          const m = moment
            .tz(row.first_checkin_date_time_utc, row.start_timezone ?? '')
            .locale(locale);
          return `${m.format('lll')} (GMT${m.format('Z')})`;
        }

        const m = moment
          .tz(row.start_date_time_utc, row.start_timezone ?? '')
          .locale(locale);

        return `${m.format('lll')} (GMT${m.format('Z')})`;
      },
      textAlign: 'center',
    },
    {
      Header: t('Customer'),
      id: 'customer',
      accessor: (row) => getGuestName(row),
      textAlign: 'center',
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => t(row.reservation_status as any),
      textAlign: 'center',
    },
    {
      Header: t('Product'),
      id: 'product',
      accessor: (row) => row.internal_product_name ?? row.product_name ?? '',
      textAlign: 'center',
    },
    {
      Header: t('Units'),
      id: 'units',
      accessor: (row) => getGuestDescription(row.unit_counts),
      textAlign: 'center',
    },
    {
      Header: t('Total Pax'),
      id: 'totalPax',
      accessor: (row) => `${getTotalPax(row.unit_counts)}`,
      textAlign: 'center',
    },
    {
      Header: t('Credit Card Brand'),
      id: 'creditCardBrand',
      accessor: (row) =>
        row.payments?.find((payment) => payment.credit_card_brand)
          ?.credit_card_brand ?? '-',
      textAlign: 'center',
    },
    {
      Header: t('Gross'),
      id: 'gross',
      accessor: (row) => getFormattedAmount(row.amount_gross),
      textAlign: 'right',
    },
    {
      Header: t('Cancel Fee'),
      id: 'cancelFee',
      accessor: (row) => getFormattedAmount(row.amount_cancellation_fee_gross),
      textAlign: 'right',
    },
    {
      Header: t('Commission'),
      id: 'bookingFee',
      accessor: (row) => getFormattedAmount(row.amount_commission),
      textAlign: 'right',
    },
    {
      Header: t('Booking Fee'),
      id: 'bookingFee',
      accessor: (row) => getFormattedAmount(row.agent_amount_booking_fee),
      textAlign: 'right',
    },
    {
      Header: t('Credit Card Fee'),
      id: 'creditCardFee',
      accessor: (row) => getFormattedAmount(row.agent_amount_credit_card_fee),
      textAlign: 'right',
    },
  ];
};

interface QueryParams {
  id: string;
}

export const PartnershipAgentSystemFeeLineItems = () => {
  const { t } = useTranslation();
  const { id } = useParams<QueryParams>();
  const currency = useSelector(defaultProductCurrencySelector);
  const [summaryOpen, setSummaryOpen] = React.useState(false);
  const csvLoading = useSelector(
    (state: ReduxState) => state.partnershipSystemFeeInvoices.csvLoading
  );
  const contractedOrganizations = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const dispatch = useDispatch();
  const query = useQuery();

  const supperId = query.get('supplier_id') || '';
  const month = query.get('month') || '';

  const cntractedOrganization = contractedOrganizations.find(
    (c) => c.id === supperId
  );

  const { data } = useAgentSystemFeeLineItems(supperId, month);

  const activeUser = useSelector(activeUserSelector);

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations(true));
  }, []);

  const agentSystemFeeInvoices = useSelector(
    (state: ReduxState) => state.partnershipSystemFeeInvoices.agentSummaries
  );

  const systemFeeInvoice = agentSystemFeeInvoices.find(
    (invoice) => invoice.month === month
  );

  const formattedYearMonth = systemFeeInvoice
    ? moment(month).locale(locale).format(monthYearFormat)
    : '';

  const { setHeaderOverride } = React.useContext(PageHeaderOverrideContext);

  React.useEffect(() => {
    if (formattedYearMonth) {
      setHeaderOverride(
        t('Fee - {{month}} - {{supplierName}}', {
          month: formattedYearMonth,
          supplierName: cntractedOrganization?.name,
        })
      );
    }

    return () => setHeaderOverride('');
  }, [formattedYearMonth, t, cntractedOrganization]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);

  React.useEffect(() => {
    dispatch(fetchSystemFeeInvoice(id));
  }, [id]);

  const columns = useColumns();

  return (
    <div>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        ></div>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              baseStyles['is-close']
            )}
          >
            <PartnershipAgentSystemFeeInvoiceDownloadCSVModal
              supplierId={supperId}
              month={month}
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    csvLoading && baseStyles['loading']
                  )}
                >
                  <img src={anotherIcon} />
                </a>
              }
            />
          </div>

          <ul>
            <li>
              <PartnershipAgentSystemFeeInvoiceDownloadCSVModal
                supplierId={supperId}
                month={month}
                trigger={
                  <Button style="gray" size="middle" loading={csvLoading}>
                    {t('Download CSV')}
                  </Button>
                }
              />
            </li>
          </ul>
        </div>
      </div>

      <FilterDisplayBox resultsCount={data?.length ?? 0} />
      <Box mt={2} mb={2}>
        <AccordionItem
          header={t('Summary')}
          open={summaryOpen}
          onButtonClick={() => setSummaryOpen(!summaryOpen)}
        >
          {() => {
            if (!systemFeeInvoice) {
              return null;
            }

            return (
              <PartnershipAgentSystemFeeInvoice
                agentSystemFeeInvoice={systemFeeInvoice}
              />
            );
          }}
        </AccordionItem>
      </Box>

      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        ></div>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        ></div>
      </div>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <CustomTable items={data ?? []} columns={columns} usePaging={true} />
    </div>
  );
};
