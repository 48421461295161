import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { Moment } from 'moment-timezone';

import {
  BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE,
  BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST,
  BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS,
  BATCH_EDIT_PRODUCT_INSTANCES_FAILURE,
  BATCH_EDIT_PRODUCT_INSTANCES_REQUEST,
  BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS,
  CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT,
  CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE,
  FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE,
  FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST,
  FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS,
  FETCH_PRODUCT_INSTANCES_FAILURE,
  FETCH_PRODUCT_INSTANCES_REQUEST,
  FETCH_PRODUCT_INSTANCES_SUCCESS,
  CLEAR_PRODUCT_INSTANCES,
  UPDATE_PRODUCT_INSTANCE_FAILURE,
  UPDATE_PRODUCT_INSTANCE_REQUEST,
  UPDATE_PRODUCT_INSTANCE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  getHTTPRequestHeaders,
  URLWithQueryParams,
} from 'client/actions/index';
import { getDemoProductInstance } from 'client/libraries/util/demoReservation';
import type { ProductInstance$Patch, Product } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const clearProductInstances = () => ({
  type: CLEAR_PRODUCT_INSTANCES,
});

export const changeProductInstancesSelectedProduct = (productID: string) => ({
  type: CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT,
  productID,
});
export const changeProductInstancesSelectedDateRange = (
  startDate: Moment,
  endDate: Moment
) => ({
  type: CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE,
  startDate,
  endDate,
});

const fetchProductInstancesRequest = (
  productID: string,
  startDate: Moment,
  endDate: Moment
) => ({
  type: FETCH_PRODUCT_INSTANCES_REQUEST,
  productID,
  startDate,
  endDate,
});

const fetchProductInstancesSuccess = (response: any) => ({
  type: FETCH_PRODUCT_INSTANCES_SUCCESS,
  response,
});

const fetchProductInstancesFailure = (error: any) => ({
  type: FETCH_PRODUCT_INSTANCES_FAILURE,
  error,
});

export const fetchProductInstances =
  (productID: string, startDate: Moment, endDate: Moment) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductInstancesRequest(productID, startDate, endDate));
    fetch(
      URLWithQueryParams(`/api/products/${productID}/instances`, {
        start_date_local_from: startDate.format('YYYY-MM-DD'),
        start_date_local_to: endDate.format('YYYY-MM-DD'),
      }),
      {
        headers: getHTTPRequestHeaders(getState()),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchProductInstancesSuccess(result)))
      .catch((error: any) => dispatch(fetchProductInstancesFailure(error)));
  };

const fetchProductInstanceByIDRequest = (id: string) => ({
  type: FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST,
  id,
});

const fetchProductInstanceByIDSuccess = (response: any) => ({
  type: FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS,
  response,
});

const fetchProductInstanceByIDFailure = (error: any) => ({
  type: FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE,
  error,
});

export const fetchProductInstanceByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductInstanceByIDRequest(id));
    fetch(
      `/api/products/-/instances/${id}?include_resource_availability=true`,
      {
        headers: getHTTPRequestHeaders(getState()),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchProductInstanceByIDSuccess(result)))
      .catch((error: any) => dispatch(fetchProductInstanceByIDFailure(error)));
  };
export const fetchDemoProductInstance =
  (demoProduct: Product) => (dispatch: Dispatch) => {
    dispatch(
      fetchProductInstanceByIDSuccess(getDemoProductInstance(demoProduct))
    );
  };

const updateProductInstanceRequest = (
  id: string,
  patch: ProductInstance$Patch
) => ({
  type: UPDATE_PRODUCT_INSTANCE_REQUEST,
  id,
  patch,
});

const updateProductInstanceSuccess = (response: any) => ({
  type: UPDATE_PRODUCT_INSTANCE_SUCCESS,
  response,
});

const updateProductInstanceFailure = (error: any) => ({
  type: UPDATE_PRODUCT_INSTANCE_FAILURE,
  error,
});

export const updateProductInstance =
  (id: string, patch: ProductInstance$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(updateProductInstanceRequest(id, patch));
    return fetch(`/api/products/-/instances/${id}`, {
      method: 'PATCH',
      headers: { ...getHTTPRequestHeaders(getState()) },
      body: JSON.stringify(patch),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(updateProductInstanceSuccess(result)))
      .catch((error: any) => dispatch(updateProductInstanceFailure(error)));
  };

let fetchProductInstancesForProductsCancel: (() => void) | typeof undefined;

const setCancelFunc = (c: () => void) => {
  fetchProductInstancesForProductsCancel = c;
};

export const fetchProductInstancesForProducts =
  (productIDs: string[], startDate: Moment, endDate: Moment) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    if (fetchProductInstancesForProductsCancel) {
      fetchProductInstancesForProductsCancel();
    }

    for (const productID of productIDs) {
      dispatch(fetchProductInstancesRequest(productID, startDate, endDate));

      try {
        const res = await axios.get(`/api/products/${productID}/instances`, {
          params: {
            start_date_local_from: startDate.format('YYYY-MM-DD'),
            start_date_local_to: endDate.format('YYYY-MM-DD'),
          },
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(setCancelFunc),
        });
        dispatch(fetchProductInstancesSuccess(res.data));
      } catch (err) {
        dispatch(fetchProductInstancesFailure(err));

        if (axios.isCancel(err)) {
          return;
        }
      }
    }
  };

const batchCloseProductInstancesRequest = (
  dateFromStart: string,
  dateFromEnd: string,
  productIds: string[],
  memo: string
) => ({
  type: BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST,
  dateFromStart,
  dateFromEnd,
  productIds,
  memo,
});

const batchCloseProductInstancesSuccess = (response: any) => ({
  type: BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS,
  response,
});

const batchCloseProductInstancesFailure = (error: any) => ({
  type: BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE,
  error,
});

export const batchCloseProductInstances =
  (
    dateFromStart: string,
    dateFromEnd: string,
    productIds: string[],
    memo: string
  ) =>
  async (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(
      batchCloseProductInstancesRequest(
        dateFromStart,
        dateFromEnd,
        productIds,
        memo
      )
    );
    try {
      const res = await axios.post(
        '/api/products/-/instances/batchclose',
        {
          date_from_start: dateFromStart,
          date_from_end: dateFromEnd,
          product_ids: productIds,
          memo: {
            value: memo,
          },
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      );
      dispatch(batchCloseProductInstancesSuccess(res.data));
    } catch (err) {
      dispatch(batchCloseProductInstancesFailure(err));
    }
  };

const batchEditProductInstancesRequest = (
  productInstanceIds: string[],
  operation: 'CLOSE' | 'OPEN' | 'REQUEST',
  memo: string
) => ({
  type: BATCH_EDIT_PRODUCT_INSTANCES_REQUEST,
  productInstanceIds,
  operation,
  memo,
});

const batchEditProductInstancesSuccess = (response: any) => ({
  type: BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS,
  response,
});

const batchEditProductInstancesFailure = (error: any) => ({
  type: BATCH_EDIT_PRODUCT_INSTANCES_FAILURE,
  error,
});

export const batchEditProductInstances =
  (
    productInstanceIds: string[],
    operation: 'CLOSE' | 'OPEN' | 'REQUEST',
    memo: string
  ) =>
  async (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(
      batchEditProductInstancesRequest(productInstanceIds, operation, memo)
    );
    try {
      const res = await axios.post(
        '/api/products/-/instances/batchedit',
        {
          product_instance_ids: productInstanceIds,
          operation,
          memo: {
            value: memo,
          },
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      );
      dispatch(batchEditProductInstancesSuccess(res.data));
    } catch (err) {
      dispatch(batchEditProductInstancesFailure(err));
    }
  };
