import * as React from 'react';

import {
  configureGa,
  trackPageView,
} from 'client/libraries/util/googleAnalytics';

configureGa();
type Props = {
  location: Location;
};
// HOC to send page changes to Google Analytics.
export class Analytics extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Initial page load - only fired once
    this.sendPageChange(props.location.pathname, props.location.search);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // When props change, check if the URL has changed or not
    if (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.location.search !== nextProps.location.search
    ) {
      this.sendPageChange(
        nextProps.location.pathname,
        nextProps.location.search
      );
    }
  }

  sendPageChange(pathname: string, search = '') {
    const page = pathname + search;
    trackPageView(page);
  }

  render() {
    return null;
  }
}
