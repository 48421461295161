import { Field, Form } from 'react-final-form';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { setIn } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Input } from 'client/components/Form';
import { matchesFormat } from 'shared/libraries/validate/validator';
import type { Reservation, ReservationReceipt } from 'shared/models/swagger';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Message } from 'client/components/Message/Message';
import { getGuestName } from 'client/libraries/util/getGuestName';
import baseStyles from 'client/base.module.css';
import { ReduxState } from 'client/reducers';
import { resetIssueReservationReceiptStatus } from 'client/actions/reservationReceipts';

type FormValues = {
  receiverName?: string;
  receiptProviso?: string;
  toAddress?: string;
};

type Props = {
  title: string;
  initialReceiptProviso?: string;
  initialToAddress?: string;
  trigger: React.ReactElement<'a' | 'button'>;
  onIssue: (receiverName?: string, receiptProviso?: string) => Promise<void>;
  onDownload: () => void;
  onSendEmail: (toAddress?: string) => void;
  reservation?: Reservation;
  reservationReceipt?: ReservationReceipt;
};

export const ReceiptModal = ({
  title,
  initialReceiptProviso,
  initialToAddress,
  trigger,
  onIssue,
  onDownload,
  onSendEmail,
  reservation,
  reservationReceipt,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservationReceiptIssueStatus = useSelector(
    (state: ReduxState) => state.reservationReceipts.issueStatus
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [activeButton, setActiveButton] = React.useState<
    'ISSUE' | 'DOWNLOAD' | 'EMAIL' | null
  >(null);
  const isIssued =
    reservationReceipt && reservationReceipt?.receiver_name !== undefined;
  const organization = useSelector(activeUserOrganizationSelector);
  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;
  const customerName = reservation ? getGuestName(reservation) : '';
  const isReservationLanguageJapanese = reservationLanguage?.startsWith('ja');
  // Special case for Japanese customer
  const customerNameWithPrefixOrSuffix = isReservationLanguageJapanese
    ? `${customerName} 様`
    : t('Bill to: {{customerName}}', { customerName: customerName });

  // Close modal when issuance is successful
  React.useEffect(() => {
    if (reservationReceiptIssueStatus === 'SUCCESS') {
      setShowModal(false);
    }
  }, [reservationReceiptIssueStatus]);

  const validateReceiptRelatedSettings = (): React.ReactNode[] => {
    const validationErrors: React.ReactNode[] = [];
    if (
      !organization?.supplier_guest_receipt_settings ||
      !organization.supplier_guest_receipt_settings.receipt_business_name
    ) {
      validationErrors.push(
        <>
          {t(
            'Please enter Receipt Business Name in the Booking Site > Settings page. '
          )}
          <Link
            to={`/bookingWidget/general`}
            className={baseStyles['base-link']}
          >
            {t('Click here to set.')}
          </Link>
        </>
      );
    }
    return validationErrors;
  };

  const receiptValidationErrors = validateReceiptRelatedSettings();
  return (
    <Modal
      title={title}
      open={showModal}
      onOpen={() => {
        setShowModal(true);
        // Reset action status for issue each time the modal is opened
        dispatch(resetIssueReservationReceiptStatus());
      }}
      onClose={() => {
        setShowModal(false);
      }}
      trigger={trigger}
    >
      <Form
        initialValues={{
          receiverName:
            reservationReceipt?.receiver_name ?? customerNameWithPrefixOrSuffix,
          receiptProviso: isReservationLanguageJapanese
            ? reservationReceipt?.receipt_proviso ?? initialReceiptProviso
            : '',
          toAddress: initialToAddress,
        }}
        onSubmit={(values: FormValues) => {
          let errors = {};
          const setError = (key: string, value: any) => {
            errors = setIn(errors, key, value);
          };

          switch (activeButton) {
            case 'ISSUE':
              if (!values.receiverName) {
                setError('receiverName', t('Required'));
                return errors;
              }
              if (!values.receiptProviso && isReservationLanguageJapanese) {
                setError('receiptProviso', t('Required'));
                return errors;
              }
              onIssue(values.receiverName, values.receiptProviso);
              break;
            case 'DOWNLOAD':
              onDownload();
              setShowModal(false);
              break;
            case 'EMAIL':
              if (!values.toAddress) {
                setError('toAddress', t('Required'));
                return errors;
              }
              if (!matchesFormat(values.toAddress, 'email')) {
                setError('toAddress', t('Invalid Email'));
                return errors;
              }
              onSendEmail(values.toAddress);
              setShowModal(false);
              break;
          }
        }}
      >
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Field name="receiverName">
                {({ input, meta: { touched, error, submitError } }) => (
                  <Input
                    label={t('Receiver')}
                    disabled={isIssued}
                    value={reservationReceipt?.receiver_name ?? input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                    error={touched && (error || submitError)}
                  />
                )}
              </Field>
              {isReservationLanguageJapanese && (
                <>
                  <br />
                  <Field name="receiptProviso">
                    {({ input, meta: { touched, error, submitError } }) => (
                      <Input
                        label={t('Receipt Proviso')}
                        disabled={isIssued}
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                        error={touched && (error || submitError)}
                      />
                    )}
                  </Field>
                </>
              )}
              <br />
              {isIssued && (
                <Field name="toAddress">
                  {({ input, meta: { touched, error, submitError } }) => (
                    <Input
                      label={t('Email Address')}
                      disabled={Boolean(initialToAddress)}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                      error={touched && (error || submitError)}
                    />
                  )}
                </Field>
              )}
              {receiptValidationErrors.length > 0 && (
                <Message error>
                  <ol>
                    {receiptValidationErrors.map((e, idx) => (
                      <li key={idx}>{e}</li>
                    ))}
                  </ol>
                </Message>
              )}
              {/* Show error message when receipt has already been issued when user tries to issue */}
              {reservationReceiptIssueStatus === 'FAILURE' && (
                <Message error>
                  <ol>
                    <li>{t('Receipt had already been issued.')}</li>
                  </ol>
                </Message>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                style="gray"
                size="small"
                onClick={() => {
                  form.reset();
                  setShowModal(false);
                }}
              >
                {t('Cancel')}
              </Button>
              {isIssued && (
                <Button
                  style="blue"
                  size="small"
                  type="submit"
                  onClick={() => setActiveButton('DOWNLOAD')}
                  disabled={receiptValidationErrors.length > 0}
                >
                  {t('Download')}
                </Button>
              )}
              {!isIssued && (
                <Button
                  style="blue"
                  size="small"
                  type="submit"
                  onClick={() => setActiveButton('ISSUE')}
                  disabled={receiptValidationErrors.length > 0}
                  loading={reservationReceiptIssueStatus === 'IN_FLIGHT'}
                >
                  {t('Issue receipt')}
                </Button>
              )}
              {isIssued && (
                <Button
                  style="blue"
                  size="small"
                  type="submit"
                  onClick={() => setActiveButton('EMAIL')}
                  disabled={receiptValidationErrors.length > 0}
                >
                  {t('Send via Email')}
                </Button>
              )}
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
