import { createSelector } from 'reselect';
import { Action, combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_WAIVER_VISIBLE_COLUMNS,
  SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION,
  SET_WAIVER_CURRENT_PAGE,
  SET_WAIVER_ROW_COUNT,
} from 'client/constants/ActionTypes';
import type { SearchWaiversRequest } from 'client/libraries/util/searchWaivers';
import type { ReduxState } from 'client/reducers';

// Selectors
export const waiverColumnCandidatesSelector = (): string[] => [
  'submissionDate',
  'title',
  'language',
  'agentReference',
  'supplierReference',
  'participation',
  'customer',
  'productName',
  'status',
];

const visibleColumnsSelector = (state: ReduxState) =>
  state.waiverTableControls?.visibleColumns ?? [];

export const waiverVisibleColumnsSelector: (state: ReduxState) => string[] =
  createSelector(
    visibleColumnsSelector,
    waiverColumnCandidatesSelector,
    (visibleColumns, candidateColumns) =>
      visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
  );

// Reducers
const defaultVisibleColumns = [
  'submissionDate',
  'title',
  'language',
  'agentReference',
  'supplierReference',
  'participation',
  'customer',
  'productName',
  'status',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_WAIVER_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const defaultSearchFilters: SearchWaiversRequest = {
  agentReference: '',
  supplierReference: '',
  customerGivenName: '',
  customerFamilyName: '',
  productIds: [],
  participationDateFrom: '',
  participationDateTo: '',
  submittedDateFrom: '',
  submittedDateTo: '',
  dateFilterPreset: 'SUBMITTED_7_DAYS',
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION:
    case SET_WAIVER_ROW_COUNT:
      // Reset current page when the search query changes or when the waiver row count changes
      return 1;

    case SET_WAIVER_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_WAIVER_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  currentPage: number;
  rowCount: number;
  lastExecutedSearchCondition: SearchWaiversRequest;
};

const reducer = combineReducers<State>({
  visibleColumns,
  currentPage,
  rowCount,
  lastExecutedSearchCondition,
});
export const waiverTableControls = (state: State, action: Action) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
