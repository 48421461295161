import * as React from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import _ from 'lodash';

import { Search } from 'client/components/Form/Search';
import { LocationWithTime } from 'shared/models/swagger';

import { WithGoogleMapsScript } from './WithGoogleMapsScript';

declare global {
  interface Window {
    google: any;
  }
}

type Prediction = {
  description: string;
  place_id: string;
  terms: {
    value: string;
    offset: number;
  }[];
  types: string[];
  matched_substrings: {
    offset: number;
    length: number;
  };
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      offset: number;
      length: number;
    };
    secondary_text: string;
  };
};
export type SearchResult = {
  description: string;
  detail: string;
  key: string;
  title: string;
};
type OwnProps = {
  disabled?: boolean;
  error?: string;
  prompt?: string;
  location: string;
  candidateLocations?: LocationWithTime[];
  onSearchChange?: (arg0: string) => void;
  onLocationSelect: (arg0: SearchResult) => void;
  required?: boolean;
  maxWidth?: number;
  minWidth?: number;
};
type I18nProps = {
  t: TFunction;
};
type Props = OwnProps & I18nProps;
type State = {
  address: string;
  loading: boolean;
  predictions: Prediction[];
};

class LocationSearchInputComponent extends React.PureComponent<Props, State> {
  autocompleteService!: Record<string, any>;
  debouncedFetchPredictions: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      address: props.location,
      loading: false,
      predictions: [],
    };
    this.debouncedFetchPredictions = _.debounce(this.fetchPredictions, 500);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        address: this.props.location,
      });
    }
  }

  componentDidMount() {
    this.initGoogleAPI();
  }

  initGoogleAPI = () => {
    if (!window.google) {
      throw new Error(
        '[LocationInputComponent]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        '[LocationInputComponent]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    this.autocompleteService =
      new window.google.maps.places.AutocompleteService();
  };
  handleChange = (address: any) => {
    this.debouncedFetchPredictions();
    this.props.onSearchChange && this.props.onSearchChange(address);
    this.setState({
      address,
    });
  };
  handleSelection = (result: any) => {
    this.props.onLocationSelect(result);
  };
  fetchPredictions = () => {
    const { address } = this.state;

    if (address.length) {
      this.setState({
        loading: true,
      });
      this.autocompleteService.getPlacePredictions(
        {
          input: address,
        },
        this.onPredictionsLoaded
      );
    }
  };
  getSearchResults = () => {
    const { t } = this.props;
    const pickupLocations = this.props.candidateLocations
      ? this.props.candidateLocations
          .filter((l) =>
            l.location_name
              .toUpperCase()
              .includes(this.state.address.toUpperCase())
          )
          .map((location) => ({
            title: location.location_name,
            description: t('Supplier provided pickup location'),
            detail: location.location_name,
            key: location.google_place_id,
          }))
      : [];
    const formattedPredictions = this.state.predictions.map((p) => ({
      title: p.structured_formatting.main_text,
      description: p.structured_formatting.secondary_text,
      detail: p.description,
      key: p.place_id,
    }));
    // avoid duplicates and show location on product's pickup list
    const placeIdSet = new Set(pickupLocations.map((location) => location.key));
    formattedPredictions.filter(
      (predicted_location) => !placeIdSet.has(predicted_location as any)
    );
    return pickupLocations.concat(formattedPredictions);
  };
  onPredictionsLoaded = (p?: Prediction[]) => {
    const predictions = p || [];
    this.setState({
      loading: false,
    });
    this.setState({
      predictions,
    });
  };

  render() {
    const { disabled, error, minWidth, maxWidth, prompt, required } =
      this.props;
    const { address } = this.state;
    const classes = [];

    if (error) {
      classes.push('error');
    }

    if (required) {
      classes.push('required');
    }

    return (
      <Search
        label={prompt}
        disabled={disabled}
        value={address}
        onSearchChange={(e, { value }) => this.handleChange(value)}
        onResultSelect={(e, { result }) => this.handleSelection(result)}
        results={this.getSearchResults()}
        maxWidth={maxWidth}
        minWidth={minWidth}
        error={error}
      />
    );
  }
}

export const LocationSearchInput = (ownProps: OwnProps) => {
  const i18nProps = useTranslation();

  return (
    <WithGoogleMapsScript>
      <LocationSearchInputComponent {...ownProps} {...(i18nProps as any)} />
    </WithGoogleMapsScript>
  );
};
