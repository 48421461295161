import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Product } from 'shared/models/swagger';
import { HeaderButtons } from 'client/pages/v3/Product/ProductDetails/ProductDetailsHeader/HeaderButtons';

type Props = {
  product: Product;
  contentLanguage: string;
  onContentLanguageChange: (arg0: string) => void;
};

export const ProductDetailsHeader = ({
  product,
  contentLanguage,
  onContentLanguageChange,
}: Props) => {
  const { t } = useTranslation();

  const paths = [
    { text: t('Product List'), url: '/products-v3' },
    { text: product.internal_product_name ?? '', url: '' },
  ];

  return (
    <PageHeader breadcrumbPaths={paths}>
      <HeaderButtons
        product={product}
        contentLanguage={contentLanguage}
        onContentLanguageChange={onContentLanguageChange}
      />
    </PageHeader>
  );
};
