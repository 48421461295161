import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  AccessReportGadgetDataType,
  AccessReportGadgetDisplayType,
  AccessReportGadgetParams,
  AccessSummaryGadgetParams,
  DashboardGadget,
  GadgetType,
  ReservationListGadgetParams,
  ReservationListSearchPreset,
  ReservationReportGadgetDataType,
  ReservationReportGadgetDisplayType,
  ReservationReportGadgetParams,
  ReservationSearchPreset,
  ReservationSummaryGadgetParams,
} from 'client/reducers/dashboardSettings';
import { TranslateFuncType } from 'client/components/Translate';
import { getReservationSummaryTableColumns } from 'client/libraries/util/getReservationSummaryTableColumns';
import {
  AccessReportDataSet,
  ReservationColumn,
  ReservationReportDataItem,
  ReservationReportDataSet,
} from 'shared/models/swagger';

export const getReservationReportDataSum = (
  items: ReservationReportDataItem[],
  dataType: ReservationReportGadgetParams['dataType']
): number => {
  return _.sumBy(items, (item): number => {
    switch (dataType) {
      case 'RESERVATION_COUNT':
        return 1;
      case 'PARTICIPANT_COUNT':
        return item.pax ?? 0;
      case 'SALES':
        return item.amount_gross ?? 0;
    }
  });
};

export const getReservationReportGadgetInitialParams = (
  dataType: ReservationReportGadgetDataType,
  displayType: ReservationReportGadgetDisplayType
): ReservationReportGadgetParams => {
  return {
    dataType,
    displayType,
    dateType: 'BOOKING_DATE',
    dateRange: 'PREV_7_DAYS',
    productIds: [],
    aggregationType: 'DAILY',
  };
};

export const getAccessReportGadgetInitialParams = (
  dataType: AccessReportGadgetDataType,
  displayType: AccessReportGadgetDisplayType
): AccessReportGadgetParams => {
  return {
    dataType,
    displayType,
    dateRange: 'PREV_7_DAYS',
    aggregationType: 'DAILY',
  };
};

// Note: access summary only has dateRange, no need for a function

export const getReservationSummaryGadgetInitialParams = (
  t: TranslateFuncType
): ReservationSummaryGadgetParams => {
  const allColumns = getReservationSummaryTableColumns(t).map((c) => {
    return c.id.toUpperCase();
  });
  return {
    searchPreset: 'CUSTOM',
    orderBy: 'last_updated_desc',
    statuses: ['CONFIRMED'],
    dateType: 'PARTICIPATION_DATE',
    targetDateType: 'CURRENT_DATE',
    defaultColumns: allColumns,
  };
};

export const getReservationListInitialColumnsAsString = [
  'AGENT_REFERENCE',
  'PARTICIPATES_AT',
  'INTERNAL_PRODUCT_NAME',
  'STATUS',
  'GUEST_DISPLAY_NAME',
  'GUEST_COUNT',
  'GUEST_DESCRIPTION',
  'ADD_ONS',
  'TRANSPORTATION',
  'PICKUP_CHECKIN_TIME',
  'PICKUP_CHECKIN_LOCATION',
];

export const getReservationListInitialColumns = () => {
  return getReservationListInitialColumnsAsString as ReservationColumn[];
};

export const getReservationListGadgetInitialParams =
  (): ReservationListGadgetParams => {
    return {
      searchPreset: 'CUSTOM',
      orderBy: 'last_updated_desc',
      statuses: ['CONFIRMED'],
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'CURRENT_DATE',
      defaultColumns: getReservationListInitialColumns(),
    };
  };

// Get params except for preset CUSTOM
export const getParamsForReservationSummarySearchPreset = (
  searchPreset: Exclude<ReservationSearchPreset, 'custom'>
): ReservationSummaryGadgetParams => {
  if (searchPreset === 'BOOKED_YESTERDAY') {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'BOOKED_DATE',
      targetDateType: 'PREVIOUS_DATE',
      statuses: ['REQUESTED', 'STANDBY', 'CONFIRMED'],
    };
  } else if (searchPreset === 'PARTICIPATION_TODAY') {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'CURRENT_DATE',
      statuses: ['CONFIRMED'],
    };
  }
  // PARTICIPATION_TOMORROW
  else {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'NEXT_DATE',
      statuses: ['CONFIRMED'],
    };
  }
};

// Get params except for preset CUSTOM
export const getParamsForReservationListSearchPreset = (
  searchPreset: Exclude<ReservationListSearchPreset, 'custom'>
): ReservationListGadgetParams => {
  if (searchPreset === 'BOOKED_YESTERDAY') {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'BOOKED_DATE',
      targetDateType: 'PREVIOUS_DATE',
      statuses: ['REQUESTED', 'STANDBY', 'CONFIRMED'],
    };
  } else if (searchPreset === 'PARTICIPATION_TODAY') {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'CURRENT_DATE',
      statuses: ['CONFIRMED'],
    };
  } else if (searchPreset === 'PARTICIPATION_TOMORROW') {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'NEXT_DATE',
      statuses: ['CONFIRMED'],
    };
  }
  // CONFIRMATION_REQUIRED
  else {
    return {
      searchPreset,
      orderBy: 'last_updated_desc',
      dateType: 'PARTICIPATION_DATE',
      targetDateType: 'RANGE',
      dateRangeType: 'NEXT',
      shouldSetAsIndefinite: true,
      statuses: ['REQUESTED', 'STANDBY'],
    };
  }
  // UNSETTLED_EMAIL_PAYMENT
  // TODO: hide this for now
  // else {
  //   return {
  //     searchPreset,
  //     orderBy: 'last_updated_desc',
  //     dateType: 'PARTICIPATION_DATE',
  //     targetDateType: 'PREV_DATE',
  //     statuses: ['CONFIRMED'],
  //   };
  // }
};

export const translateParamsForSearchPreset = (
  searchPreset: Exclude<
    ReservationSearchPreset | ReservationListSearchPreset,
    'CUSTOM'
  >,
  t: TranslateFuncType
) => {
  // TODO: order by should be Internal Product Name, fix later
  if (searchPreset === 'BOOKED_YESTERDAY') {
    return `
      ・${t('Order By')}: ${t('Updated (most recent first)')}
      <br>
      ・${t('Booking Status')}: ${t('Requested')}, ${t('Standby')}, ${t(
      'Confirmed'
    )}
      <br>
      ・${t('Booked Date')}: ${t('Yesterday')}
    `;
  } else if (searchPreset === 'PARTICIPATION_TODAY') {
    return `
      ・${t('Order By')}: ${t('Updated (most recent first)')}
      <br>
      ・${t('Booking Status')}: ${t('Confirmed')}
      <br>
      ・${t('Participation Date')}: ${t('Today')}
    `;
  } else if (searchPreset === 'PARTICIPATION_TOMORROW') {
    return `
      ・${t('Order By')}: ${t('Updated (most recent first)')}
      <br>
      ・${t('Booking Status')}: ${t('Confirmed')}
      <br>
      ・${t('Participation Date')}: ${t('Tomorrow')}
    `;
  } else if (searchPreset === 'CONFIRMATION_REQUIRED') {
    return `
      ・${t('Order By')}: ${t('Updated (most recent first)')}
      <br>
      ・${t('Booking Status')}: ${t('Requested')}, ${t('Standby')}
      <br>
      ・${t('Participation Date')}: ${t('From today')}
    `;
  }
  // TODO: Else: UNSETTLED_EMAIL_PAYMENT
  return '';
};

export const checkIfReservationReportGadgetParamsHaveChanged = (
  initialParams: ReservationReportGadgetParams,
  modifiedParams: ReservationReportGadgetParams
): boolean => {
  return (
    // Data type and display type can be ignored when comparing
    initialParams.dateType !== modifiedParams.dateType ||
    initialParams.dateRange !== modifiedParams.dateRange ||
    JSON.stringify(initialParams.productIds) !==
      JSON.stringify(modifiedParams.productIds)
  );
};

export const checkIfAccessReportGadgetParamsHaveChanged = (
  initialParams: AccessReportGadgetParams,
  modifiedParams: AccessReportGadgetParams
): boolean => {
  return (
    // Data type and display type can be ignored when comparing
    initialParams.dateRange !== modifiedParams.dateRange
  );
};

export const checkIfAccessSummaryGadgetParamsHaveChanged = (
  initialParams: AccessSummaryGadgetParams,
  modifiedParams: AccessSummaryGadgetParams
): boolean => {
  // Only has data range
  return initialParams.dateRange !== modifiedParams.dateRange;
};

export const checkIfReservationSummaryListGadgetParamsHaveChanged = (
  initialParams: ReservationSummaryGadgetParams | ReservationListGadgetParams,
  modifiedParams: ReservationSummaryGadgetParams | ReservationListGadgetParams
): boolean => {
  return (
    modifiedParams.searchPreset === 'CUSTOM' ||
    initialParams.orderBy !== modifiedParams.orderBy ||
    JSON.stringify(initialParams.statuses) !==
      JSON.stringify(modifiedParams.statuses) ||
    initialParams.dateType !== modifiedParams.dateType ||
    initialParams.targetDateType !== modifiedParams.targetDateType ||
    initialParams.orderBy !== modifiedParams.orderBy ||
    initialParams.dateRange !== modifiedParams.dateRange
  );
};

export const isAccessReportParams = (
  params: any
): params is AccessReportGadgetParams =>
  'dataType' in params &&
  'displayType' in params &&
  'aggregationType' in params;

export const getDateRange = (
  reportDataSets: AccessReportDataSet[] | ReservationReportDataSet[]
): [string, string] => {
  const baseRange = `${moment(reportDataSets[0].range?.start).format(
    'YYYY/MM/DD'
  )} ~ ${moment(reportDataSets[0].range?.end).format('YYYY/MM/DD')}`;
  const compareRange =
    reportDataSets.length > 1
      ? `${moment(reportDataSets[1].range?.start).format(
          'YYYY/MM/DD'
        )} ~ ${moment(reportDataSets[1].range?.end).format('YYYY/MM/DD')}`
      : '';
  return [baseRange, compareRange];
};

export const getCustomGadgetKey = (
  gadgetType: GadgetType,
  dataType?: AccessReportGadgetDataType | ReservationReportGadgetDataType,
  displayType?:
    | AccessReportGadgetDisplayType
    | ReservationReportGadgetDisplayType
) => {
  if (gadgetType === 'reservation-report' || gadgetType === 'access-report') {
    return (
      dataType?.toLowerCase() + '-report-' + displayType + '-custom-' + uuidv4()
    );
  } else if (gadgetType === 'access-summary') {
    return 'access-summary-custom-' + uuidv4();
  } else if (gadgetType === 'reservation-summary') {
    return 'reservation-summary-custom-' + uuidv4();
  } else {
    return 'reservation-list-custom-' + uuidv4();
  }
};

export const getDefaultGadgets = (t: TranslateFuncType): DashboardGadget[] => {
  const allColumns = getReservationSummaryTableColumns(t).map((c) => {
    return c.id.toUpperCase();
  });

  return [
    {
      gadgetType: 'reservation-report',
      key: 'reservation_count-report-LINE_CHART',
      title: t('Reservation Trends'),
      params: getReservationReportGadgetInitialParams(
        'RESERVATION_COUNT',
        'LINE_CHART'
      ),
    },
    {
      gadgetType: 'reservation-report',
      key: 'participant_count-report-LINE_CHART',
      title: t('Participants by Product'),
      params: getReservationReportGadgetInitialParams(
        'PARTICIPANT_COUNT',
        'PRODUCTS_TABLE'
      ),
    },
    {
      gadgetType: 'reservation-report',
      key: 'sales-report-LINE_CHART',
      title: t('Sales Trends'),
      params: getReservationReportGadgetInitialParams('SALES', 'LINE_CHART'),
    },
    {
      gadgetType: 'access-summary',
      key: 'access-summary',
      title: t('Booking Site Access Summary'),
      params: { dateRange: 'PREV_7_DAYS' },
    },
    {
      gadgetType: 'reservation-summary',
      key: 'reservation-summary-TODAY',
      title: t("Today's Reservation Summary"),
      params: {
        searchPreset: 'CUSTOM',
        orderBy: 'last_updated_desc',
        statuses: ['CONFIRMED'],
        dateType: 'PARTICIPATION_DATE',
        targetDateType: 'CURRENT_DATE',
        defaultColumns: allColumns,
      },
    },
  ];
};
