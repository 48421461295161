import * as React from 'react';
import clsx from 'clsx';

import baseStyles from 'client/v3-base.module.css';

import styles from './Toggle.module.css';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  onChange: (arg0: React.SyntheticEvent<Record<string, any>>) => void;
  checked?: boolean;
  name?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  size?: 'sm' | 'md';
};

export const Toggle = ({
  error,
  label,
  onChange,
  checked,
  name,
  value,
  disabled,
  size = 'sm',
}: Props) => {
  return (
    <div>
      <label className={clsx(styles['c-toggle'], styles[size])}>
        <input
          disabled={disabled}
          onChange={!disabled ? onChange : undefined}
          type="checkbox"
          name={name || ''}
          value={value || ''}
          checked={checked}
        />
        <i></i>
        {label && <p>{label}</p>}
      </label>
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </div>
  );
};
