import moment from 'moment-timezone';
import querystring from 'query-string';
import _ from 'lodash';

import type { TranslateFuncType } from 'client/components/Translate';
import { Inquiry, ListInquiriesRequest } from 'shared/models/swagger';

export type DateFilterPreset =
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'UPDATED_1_DAY'
  | 'UPDATED_3_DAYS'
  | 'UPDATED_7_DAYS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
      'UPDATED_1_DAY',
      'UPDATED_3_DAYS',
      'UPDATED_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'UPDATED_1_DAY':
      return t('Updated, past {{count}} days', {
        count: 1,
      });

    case 'UPDATED_3_DAYS':
      return t('Updated, past {{count}} days', {
        count: 3,
      });

    case 'UPDATED_7_DAYS':
      return t('Updated, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
  }

  return '';
};
export type SearchInquiriesRequest = {
  participationDateFrom: string;
  participationDateTo: string;
  updatedDateFrom: string;
  updatedDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
  customerGivenName: string;
  customerFamilyName: string;
  contact: string;
  communicationTypes: ('EMAIL' | 'SMS')[];
  categories: string[];
  tag: string;
};
export const convertSearchInquiriesRequestToQueryParams = (
  req: SearchInquiriesRequest
): ListInquiriesRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let updatedDateTimeUtcFrom = '';
  let updatedDateTimeUtcTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'UPDATED_1_DAY':
        updatedDateTimeUtcFrom = now.subtract(1, 'days').utc().format();
        break;

      case 'UPDATED_3_DAYS':
        updatedDateTimeUtcFrom = now.subtract(3, 'days').utc().format();
        break;

      case 'UPDATED_7_DAYS':
        updatedDateTimeUtcFrom = now.subtract(7, 'days').utc().format();
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = moment(now).format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = moment(now).format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = moment(now).format('YYYY-MM-DD');
        startDateLocalTo = moment(now).add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    updatedDateTimeUtcFrom = req.updatedDateFrom
      ? moment(req.updatedDateFrom).utc().format()
      : '';
    updatedDateTimeUtcTo = req.updatedDateTo
      ? moment(req.updatedDateTo).add(1, 'day').utc().format()
      : '';
  }

  const initialCommunicationTypes: Inquiry['initial_communication_type'][] = [];
  const communicationTypes = req.communicationTypes ?? [];
  if (communicationTypes.length > 0) {
    if (communicationTypes.includes('EMAIL')) {
      initialCommunicationTypes.push(
        'EMAIL_BY_GUEST',
        'EMAIL_BY_SUPPLIER',
        'BULK_EMAIL_BY_SUPPLIER'
      );
    }
    if (communicationTypes.includes('SMS')) {
      initialCommunicationTypes.push('SMS_BY_SUPPLIER', 'BULK_SMS_BY_SUPPLIER');
    }
  }

  return {
    start_date_local_from: startDateLocalFrom,
    start_date_local_to: startDateLocalTo,
    last_updated_date_time_utc_from: updatedDateTimeUtcFrom,
    last_updated_date_time_utc_to: updatedDateTimeUtcTo,
    contact: req.contact,
    tag: req.tag,
    initial_communication_types: initialCommunicationTypes as any,
    filter: querystring.stringify({
      customer_given_name: req.customerGivenName
        ? [
            ...new Set([
              req.customerGivenName,
              req.customerGivenName.toLowerCase(),
              req.customerGivenName.toUpperCase(),
              _.startCase(req.customerGivenName.toLowerCase()),
            ]),
          ]
        : '',
      customer_family_name: req.customerFamilyName
        ? [
            ...new Set([
              req.customerFamilyName,
              req.customerFamilyName.toLowerCase(),
              req.customerFamilyName.toUpperCase(),
              _.startCase(req.customerFamilyName.toLowerCase()),
            ]),
          ]
        : '',
      inquiry_category: req.categories ?? [],
    }),
  };
};
