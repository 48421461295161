import { useTranslation } from 'react-i18next';

import { ListEditor } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/SectionEditor/ListEditor';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';

export const DetailInfoEditor = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['p-productsTranslation']}>
        <p className={styles['p-productsTranslation__ttl']}>
          {t('Experience Content')}
        </p>
        <div className={styles['p-productsTranslation__body']}>
          <ListEditor
            name="inclusions"
            title={t('Inclusions')}
            buttonDisabled
          />
          <ListEditor
            name="exclusions"
            title={t('Exclusions')}
            buttonDisabled
          />
        </div>
      </div>
      <div className={styles['p-productsTranslation']}>
        <p className={styles['p-productsTranslation__ttl']}>
          {t('Participation Conditions')}
        </p>
        <div className={styles['p-productsTranslation__body']}>
          <ListEditor
            name="requirements"
            title={t('Requirements')}
            buttonDisabled
          />
          <ListEditor
            name="restrictions"
            title={t('Restrictions')}
            buttonDisabled
          />
        </div>
      </div>
      <div className={styles['p-productsTranslation']}>
        <p className={styles['p-productsTranslation__ttl']}>
          {t('Participation Info')}
        </p>
        <div className={styles['p-productsTranslation__body']}>
          <ListEditor
            name="whatToBring"
            title={t('What to Bring')}
            buttonDisabled
          />
          <ListEditor
            name="otherNotes"
            title={t('Other Info')}
            buttonDisabled
          />
        </div>
      </div>
    </>
  );
};
