import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { setShouldShowDetailedConditions } from 'client/actions/reservationSearch';

import styles from './SearchAccordion.module.css';

type SearchAccordionProps = {
  children: JSX.Element[];
};

export const SearchAccordion = ({ children }: SearchAccordionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shouldShowDetailedConditions = useSelector(
    (state: ReduxState) => state.reservationSearch.shouldShowDetailedConditions
  );
  const [isOpen, setIsOpen] = useState<boolean>(shouldShowDetailedConditions);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const accordionElement = accordionRef.current;

    if (accordionElement) {
      accordionElement.style.height = isOpen ? '100%' : '0';
      accordionElement.style.overflow = isOpen ? '' : 'hidden';
      accordionElement.style.opacity = isOpen ? '1' : '0';
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(setShouldShowDetailedConditions(isOpen));
  }, [isOpen]);

  const handleToggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className={styles['p-search__body__ac']} ref={accordionRef}>
        {children}
      </div>
      <div className={styles['p-search__body__acbtn']}>
        <Button
          text={
            isOpen
              ? t('Close detailed conditions')
              : t('Show detailed conditions')
          }
          uiType="bg"
          size="md"
          color="secondary"
          useFullWidth={true}
          iconAfterText={
            isOpen ? (
              <i className="c-icon-solid-arrows-chevron-up"></i>
            ) : (
              <i className="c-icon-solid-arrows-chevron-down"></i>
            )
          }
          onClick={handleToggleAccordion}
        />
      </div>
    </>
  );
};
