import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { Reservation } from 'shared/models/swagger';

interface Props {
  reservation: Reservation | undefined;
  onClose: () => void;
  equipmentBlockReference: string;
}

export const PrintETicket = ({
  reservation,
  onClose,
  equipmentBlockReference,
}: Props) => {
  React.useEffect(() => {
    if (!reservation) {
      return;
    }

    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      @page {
          size: 74mm 105mm;
      }
      @media print {
        body > :not(.print-ticket) {
          display: none;
        }
        .print-ticket {
          display: block;
          width: 100%;
        }
        .print-ticket .info{
          width: 90%;
          margin: 10px auto;
          border: 1px solid #000;
          font-size: 6px;
        }
        .print-ticket .seat-number{
          width: 90%;
          margin: 10px auto;
          border: 1px solid #000;
          font-size: 6px;
          text-align: center;
        }
        .print-ticket .seat-number td.body{
          font-size: 20px;
        }
        .print-ticket .note{
          width: 90%;
          margin: 10px auto;
          font-size: 6px;
          text-align: center;
        }
      }
    `;
    document.head.appendChild(styleEl);

    window.onafterprint = () => {
      console.log('after print');
      document.head.removeChild(styleEl);
      onClose();
    };

    window.print();
  }, [reservation]);

  const bodyEl = document.getElementsByTagName('body')[0];
  return ReactDOM.createPortal(
    <PrintETicketContext
      reservation={reservation}
      equipmentBlockReference={equipmentBlockReference}
    />,
    bodyEl
  );
};

const PrintETicketContext = ({
  reservation,
  equipmentBlockReference,
}: {
  reservation: Reservation | undefined;
  equipmentBlockReference: string;
}) => {
  const { t, i18n } = useTranslation();
  if (!reservation) {
    return null;
  }

  const reservationSummaryShape = toReservationSummaryShape(
    reservation,
    i18n.language,
    t
  );

  return (
    <div className="print-ticket">
      <table className="info">
        <tbody>
          <tr>
            <td>{t('Product')}</td>
            <td>{reservationSummaryShape?.product_name}</td>
          </tr>
          <tr>
            <td>{t('Application Number')}</td>
            <td>{reservationSummaryShape?.agent_reference}</td>
          </tr>
          <tr>
            <td>{t('Participation')}</td>
            <td>{reservationSummaryShape?.participates_at.format('lll')}</td>
          </tr>
          <tr>
            <td>{t('Guests')}</td>
            <td>{reservationSummaryShape?.guest_description}</td>
          </tr>
        </tbody>
      </table>

      <table className="seat-number">
        <tbody>
          <tr>
            <td className="title">座席番号</td>
          </tr>
          <tr>
            <td className="body">{equipmentBlockReference}</td>
          </tr>
        </tbody>
      </table>
      <div className="note">乗車時に係員にご提示ください。</div>
    </div>
  );
};
