import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import moment from 'moment-timezone';

import {
  Button,
  FieldWrapper,
  Input,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { Modal } from 'client/components/Modal/Modal';
import { getWeekdayText, Weekday } from 'client/libraries/util/weekdays';
import { getArrayMutators } from 'client/libraries/util/form';
import { parseDuration } from 'client/libraries/util/util';
import baseStyles from 'client/base.module.css';

const weekdays: Weekday[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

interface DayHours {
  day: Weekday;
  isOpen: boolean;
  openTime: string;
  closeTime: string;
}

interface FormValues {
  title: string;
  websiteUrl: string;
  description: string;
  phoneNumber: string;
  operatingHours: DayHours[];
}

type ApiWeekday =
  | 'SUNDAY'
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY';

const convertToApiWeekday = (weekday: Weekday): ApiWeekday => {
  switch (weekday) {
    case 'SUN':
      return 'SUNDAY';
    case 'MON':
      return 'MONDAY';
    case 'TUE':
      return 'TUESDAY';
    case 'WED':
      return 'WEDNESDAY';
    case 'THU':
      return 'THURSDAY';
    case 'FRI':
      return 'FRIDAY';
    case 'SAT':
    default:
      return 'SATURDAY';
  }
};

interface ApiTimeOfDay {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}

const convertToApiTimeOfDay = (time: string): ApiTimeOfDay => {
  const { hours, minutes } = parseDuration(time);

  return {
    hours,
    minutes,
    seconds: 0,
    nanos: 0,
  };
};

const getOperatingHours = (location: any): DayHours[] => {
  return weekdays.map((weekday) => {
    const period = location.regularHours?.periods?.find(
      (period: any) => period.openDay === convertToApiWeekday(weekday)
    );
    if (period) {
      return {
        day: weekday,
        isOpen: true,
        openTime: `${period.openTime?.hours ?? 0}:${
          period.openTime?.minutes ?? '00'
        }`,
        closeTime: `${period.closeTime?.hours ?? 0}:${
          period.closeTime?.minutes ?? '00'
        }`,
      };
    }

    return {
      day: weekday,
      isOpen: false,
      openTime: '9:00',
      closeTime: '17:00',
    };
  });
};

interface Props {
  onClose: () => void;
  location: any;
  onSave: (location: any) => void;
}

export const LocationEditModal = ({ onClose, location, onSave }: Props) => {
  const { t } = useTranslation();

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: location.title,
      websiteUrl: location.websiteUri,
      description: location.profile?.description ?? '',
      phoneNumber: location.phoneNumbers?.primaryPhone ?? '',
      operatingHours: getOperatingHours(location),
    }),
    [location]
  );

  return (
    <Modal title={t('Edit Location')} open={true} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          try {
            await onSave({
              title: values.title,
              profile: {
                description: values.description,
              },
              websiteUri: values.websiteUrl,
              phoneNumbers: {
                primaryPhone: values.phoneNumber,
                additionalPhones: [],
              },
              regularHours: {
                periods: values.operatingHours
                  .filter((dayHours) => dayHours.isOpen)
                  .map((dayHours) => ({
                    openDay: convertToApiWeekday(dayHours.day),
                    closeDay: convertToApiWeekday(dayHours.day),
                    openTime: convertToApiTimeOfDay(dayHours.openTime),
                    closeTime: convertToApiTimeOfDay(dayHours.closeTime),
                  })),
              },
            });
            onClose();
          } catch (err: any) {
            return {
              [FORM_ERROR]: `${t('Error: ')}${
                err.response.data.error.status
              }: ${JSON.stringify(err.response.data.error.details)}`,
            };
          }
        }}
        mutators={getArrayMutators()}
        debug={console.log}
      >
        {({ handleSubmit, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Field name="title">
                {({ input }) => (
                  <Input
                    label={t('Title')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <Field name="websiteUrl">
                {({ input }) => (
                  <Input
                    label={t('Website URL')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <Field name="phoneNumber">
                {({ input }) => (
                  <Input
                    label={t('Phone Number')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <Field name="description">
                {({ input }) => (
                  <TextArea
                    label={t('Description')}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <FieldWrapper label={t('Operating Hours')}>
                <table>
                  <tbody>
                    <FieldArray name="operatingHours">
                      {({ fields }) =>
                        fields.map((name, idx) => (
                          <tr style={{ width: '100%' }} key={name}>
                            <td>
                              <Field name={`${name}.day`}>
                                {({ input }) => getWeekdayText(input.value, t)}
                              </Field>
                            </td>
                            <td>
                              <Box ml={2}>
                                <Field name={`${name}.isOpen`}>
                                  {({ input }) => (
                                    <ToggleButton
                                      label={t('Open')}
                                      checked={input.value}
                                      onChange={() =>
                                        input.onChange(!input.value)
                                      }
                                    />
                                  )}
                                </Field>
                              </Box>
                            </td>
                            <td style={{ minWidth: '200px' }}>
                              {fields.value[idx].isOpen && (
                                <Box ml={2} display="flex">
                                  <Field name={`${name}.openTime`}>
                                    {({ input }) => (
                                      <TimePicker
                                        allowEmpty={false}
                                        value={moment(input.value, 'HH:mm')}
                                        onChange={(newMoment) => {
                                          if (!newMoment) {
                                            return;
                                          }

                                          input.onChange(
                                            newMoment.format('HH:mm')
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                  <p>-</p>
                                  <Field name={`${name}.closeTime`}>
                                    {({ input }) => (
                                      <TimePicker
                                        allowEmpty={false}
                                        value={moment(input.value, 'HH:mm')}
                                        onChange={(newMoment) => {
                                          if (!newMoment) {
                                            return;
                                          }

                                          input.onChange(
                                            newMoment.format('HH:mm')
                                          );
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Box>
                              )}
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </table>
              </FieldWrapper>

              {submitError && (
                <div
                  style={{ wordBreak: 'break-all' }}
                  className={baseStyles['base-form-box__err']}
                >
                  {submitError}
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button style="blue" size="middle" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
