import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import moment from 'moment-timezone';

import { Modal } from 'client/components/v3/Form/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import type { ReduxState } from 'client/reducers/index';
import { updateReservation } from 'client/actions/reservations';
import baseStyles from 'client/v3-base.module.css';
import * as Swagger from 'shared/models/swagger';
import type { FormValues } from 'client/components/ReservationPinModal/formValues';
import { convertFormValuesToReservation } from 'client/components/ReservationPinModal/formValues';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';

type Props = {
  onClose: () => void;
  open: boolean;
  reservation: Swagger.Reservation;
};

export const ReservationPinModal = ({ onClose, open, reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        const newReservation = convertFormValuesToReservation(
          values,
          reservation
        );
        await dispatch(updateReservation(reservation.id, newReservation));
        onClose();
      }}
      initialValues={{
        isPinned: reservation?.pin_info?.is_pinned,
        dueDateTimeUtc: moment
          .tz(
            reservation?.pin_info?.due_date_time_utc,
            reservation?.start_timezone ?? ''
          )
          .locale(locale),
        memo: reservation?.pin_info?.memo,
      }}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit} id="formPinReservation">
          <Modal
            title={t('Pin reservation')}
            open={open}
            onClose={onClose}
            insertAtRoot={true}
            rightActionChildren={
              <>
                <Button
                  text={t('Discard')}
                  color="white"
                  onClick={() => {
                    onClose();
                  }}
                />
                <Button
                  text={t('Save')}
                  loading={submitting}
                  type="submit"
                  form="formPinReservation"
                />
              </>
            }
            allowContentOverflow={true}
          >
            <div className={styles['p-pin']}>
              <div className={styles['p-pin__item']}>
                <Field name="isPinned" type="checkbox">
                  {({ input }) => {
                    return (
                      <Toggle
                        checked={input.checked ?? false}
                        onChange={() => input.onChange(!input.checked)}
                        label={t('Pin reservation')}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className={styles['p-pin__item']}>
                <div className={styles['p-pin__item__flex']}>
                  <p className={styles['p-pin__item__ttl']}>
                    {t('Pinned until')}
                  </p>
                  <Field name="dueDateTimeUtc">
                    {({ input }) => (
                      <SimpleDateInput
                        dateFrom={moment(input.value).format('YYYY/MM/DD')}
                        onChange={(date) => {
                          const dateMoment = moment(date);
                          const newDate = input.value
                            .clone()
                            .set('year', dateMoment.year())
                            .set('month', dateMoment.month())
                            .set('date', dateMoment.date());
                          input.onChange(newDate);
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['p-pin__item__fix']}>
                  <p className={styles['p-pin__item__ttl']}>{t('Time')}</p>
                  <Field name="dueDateTimeUtc">
                    {({ input }) => (
                      <TimePicker
                        allowEmpty={false}
                        value={input.value}
                        onChange={(time) => {
                          if (!time) {
                            return;
                          }

                          const newDate = input.value
                            .clone()
                            .set('hour', time.hour())
                            .set('minute', time.minute());
                          input.onChange(newDate);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={styles['p-pin__item']}>
                <div className={styles['p-pin__item__flex']}>
                  <p className={styles['p-pin__item__ttl']}>{t('Memo')}</p>
                  <Field name="memo">
                    {({ input, meta: { touched, error } }) => (
                      <TextArea
                        value={input.value}
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                        height={80}
                        maxWidth={800}
                        showCharacterCount
                        error={touched && error}
                      ></TextArea>
                    )}
                  </Field>
                </div>
              </div>
            </div>

            {submitError && (
              <p className={baseStyles['u-error-msg']}>{submitError}</p>
            )}
          </Modal>
        </form>
      )}
    </Form>
  );
};
