import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Input, FieldWrapper, Button } from 'client/components/Form';
import { updateReservation } from 'client/actions/reservations';
import { Modal } from 'client/components/Modal/Modal';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import componentStyles from 'client/components/components.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  reservation: ManifestReservationShape;
  trigger: React.ReactElement<'a' | 'button'>;
  header?: string;
};

export const EditSupplierInternalNotesModalForDispatchModal = ({
  reservation,
  trigger,
  header,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);
  const [
    supplierInternalNotesForDispatch,
    setSupplierInternalNotesForDispatch,
  ] = React.useState(reservation?.supplier_internal_notes_for_dispatch ?? '');

  return (
    <Modal
      title={header ?? ''}
      trigger={trigger}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <FieldWrapper label={t('ID')}>{reservation.id}</FieldWrapper>
        </div>

        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <Input
            required
            label={t('Internal Note')}
            value={supplierInternalNotesForDispatch || ''}
            onChange={(e, { value }) =>
              setSupplierInternalNotesForDispatch(value)
            }
          />
        </div>
      </div>
      <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
        <Button
          style="gray"
          size="middle"
          onClick={() => {
            setSupplierInternalNotesForDispatch(
              reservation.supplier_internal_notes_for_dispatch ?? ''
            );
          }}
          disabled={
            supplierInternalNotesForDispatch ===
            reservation.supplier_internal_notes_for_dispatch
          }
        >
          {t('Discard Changes')}
        </Button>
        <Button
          style="green"
          size="middle"
          disabled={
            supplierInternalNotesForDispatch ===
            reservation.supplier_internal_notes_for_dispatch
          }
          onClick={() => {
            dispatch(
              updateReservation(reservation.id, {
                supplier_internal_notes_for_dispatch:
                  supplierInternalNotesForDispatch,
              })
            );
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </Modal>
  );
};
