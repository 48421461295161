import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { TextField } from 'client/components/v3/Form/TextField';
import { MultiDropdown } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/Dropdown/MultiDropdown';
import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';

import { TopPageFormValues } from '../FormValues';
import type { CustomProductList } from '../FormValues';

type Props = {
  name: string;
};
export const EditCustomProductListSection = ({ name }: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const productOptions = allProducts.map((product) => ({
    key: product.id ?? '',
    value: product.id ?? '',
    text: product.product_name ?? '',
  }));
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const initialValuesFromForm: CustomProductList = _.get(
    formState.values,
    name
  );
  const initialValues: CustomProductList = initialValuesFromForm
    ? initialValuesFromForm
    : {
        sectionType: 'CUSTOM_PRODUCT_LIST',
        title: '',
        key: uuidv4(),
        productIds: [''],
      };

  return (
    <>
      <div className={styles['p-topPage-products__section__body__item']}>
        <TextField
          label={t('Title')}
          value={initialValues.title}
          onChange={(value) => {
            form.change(`${name}.title` as any, value);
          }}
        />
      </div>
      <div className={styles['p-topPage-products__section__body__item']}>
        <MultiDropdown
          label={t('Product')}
          options={productOptions}
          onChange={(value: string[]) => {
            form.change(`${name}.productIds` as any, value);
          }}
          selectedOptions={initialValues.productIds}
          searchable={true}
        />
      </div>
    </>
  );
};
