import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import {
  clearParticipantWaiver,
  deleteWaiver,
  fetchWaivers,
  fetchWaiverPDF,
} from 'client/actions/waivers';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  fetchReservationByID,
  sendWaiverRequestEmail,
} from 'client/actions/reservations';
import { SendWaiverRequestEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Waiver/SendWaiverRequestEmailModal';
import {
  getWaiverStatusText,
  getWaiverStatus,
} from 'client/libraries/util/getWaiverStatusText';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import type { ReduxState } from 'client/reducers';
import type { Reservation, Waiver, WaiverItem } from 'shared/models/swagger';
// TODO: remove oldBaseStyles later
import oldBaseStyles from 'client/base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';
import buttonStyles from 'client/components/v3/Common/Button.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { Badge } from 'client/components/v3/Common/Badge';
import { getBadgeColorForWaiverStatus } from 'client/libraries/util/getBadgeColorForWaiverStatus';

const getConditionalItemSignatures = (item: WaiverItem): string[] => {
  const isYes = Boolean(
    (item.user_responses ?? []).length > 0 &&
      item.user_responses?.[0] === 'true'
  );

  const signatures: string[] = [];

  (isYes
    ? item.template_item?.conditional?.yes_items
    : item.template_item?.conditional?.no_items
  )?.forEach((yesNoItem, yesNoItemIdx) => {
    if (yesNoItem?.type === 'SIGNATURE') {
      if (
        (item.conditional_item_responses ?? []).length > yesNoItemIdx &&
        (item.conditional_item_responses?.[yesNoItemIdx]?.user_responses ?? [])
          .length > 0
      ) {
        signatures.push(
          item.conditional_item_responses?.[yesNoItemIdx]
            ?.user_responses?.[0] ?? ''
        );
      }
    }
  });

  return signatures;
};

type Fingerprint = {
  ipAddress: string;
  requestorId: string;
  signatures: string[];
  submissionDateTimeUtc: string;
  participantFullName?: string;
};

const getFingerprints = (waiver: Waiver): Fingerprint[] => {
  if ((waiver.per_participant_waivers ?? []).length > 0) {
    return (waiver.per_participant_waivers ?? []).map((perPaxWaiver) => {
      const fingerprint = perPaxWaiver.submission_fingerprint;

      const signatures = _.flatten(
        perPaxWaiver?.sections?.map((section) => [
          ...(section?.items
            ?.filter((item) => item?.template_item?.type === 'SIGNATURE')
            .map((item) => item.user_responses?.[0] ?? '') ?? []),
          ..._.flatten(
            section?.items
              ?.filter((item) => item?.template_item?.type === 'CONDITIONAL')
              .map((item) => getConditionalItemSignatures(item))
          ),
        ]) ?? []
      );

      return {
        ipAddress: fingerprint?.ip_address ?? '',
        requestorId: fingerprint?.requestor_id ?? '',
        signatures,
        submissionDateTimeUtc: perPaxWaiver.submitted_date_time_utc ?? '',
        participantFullName: perPaxWaiver.participant_full_name ?? '',
      };
    });
  }

  const fingerprint = waiver.submission_fingerprints?.[0];

  const signatures = _.flatten(
    waiver?.sections?.map(
      (section) =>
        section?.items
          ?.filter((item) => item?.template_item?.type === 'SIGNATURE')
          .map((item) => item.user_responses?.[0] ?? '') ?? []
    ) ?? []
  );

  return [
    {
      ipAddress: fingerprint?.ip_address ?? '',
      requestorId: fingerprint?.requestor_id ?? '',
      signatures,
      submissionDateTimeUtc: waiver.submitted_date_time_utc ?? '',
    },
  ];
};

type Props = {
  reservation: Reservation;
};
export const WaiverDisplay = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const waiverPDFStatus = useSelector(
    (state: ReduxState) => state.waivers.pdfStatus
  );
  const waivers = useSelector((state: ReduxState) => state.waivers.all);
  const waiverLoading = useSelector(
    (state: ReduxState) => state.waivers.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [participantNameToClear, setParticpantNameToClear] = useState('');
  const [showClearWaiversModal, setShowClearWaiversModal] = useState(false);
  const [emailSendingStatus, setEmailSendingStatus] = useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const [showModal, setShowModal] = useState(false);
  const [showDigitalFingerprint, setShowDigitalFingerprint] =
    useState<boolean>(false);
  const [waiversHaveLoaded, setWaiversHaveLoaded] = useState<boolean>(false);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  useEffect(() => {
    if (showDigitalFingerprint && !waiversHaveLoaded) {
      setWaiversHaveLoaded(true);
      dispatch(
        fetchWaivers({
          reservation_id: reservation.id,
        })
      );
    }
  }, [showDigitalFingerprint, waiversHaveLoaded]);
  const waiverStatus = getWaiverStatus(reservation);
  const complete = waiverStatus === 'COMPLETE';
  const fingerprints =
    (waivers ?? []).length > 0 ? getFingerprints(waivers[0]) : [];

  const isFutureReservation = moment(reservation.start_date_time_utc).isAfter(
    moment()
  );
  const waiverIsComplete = getWaiverStatus(reservation) === 'COMPLETE';
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  const StatusBadge = (
    <Badge
      label={getWaiverStatusText(
        reservation.waiver_info,
        reservation.guests.length,
        t
      )}
      color={getBadgeColorForWaiverStatus(
        reservation.waiver_info,
        reservation.guests.length
      )}
    />
  );

  return (
    <div>
      {showClearWaiversModal && (
        <DeleteConfirmModal
          header={t('Clear Waivers')}
          content={t(
            'Are you sure you want to clear waivers for this reservation? Warning: any waiver progress will be lost and this action can not be undone.'
          )}
          onConfirm={async () => {
            await dispatch(
              deleteWaiver(
                reservation?.id ?? '',
                reservation.waiver_info?.waiver_id ?? ''
              )
            );
          }}
          onClose={() => setShowClearWaiversModal(false)}
          open={showClearWaiversModal}
        />
      )}
      {participantNameToClear && (
        <DeleteConfirmModal
          header={t('Clear Participant Waiver')}
          content={t(
            'Are you sure you want to clear the waiver for "{{participantName}}"? Warning: this action can not be undone.',
            {
              participantName: participantNameToClear,
            }
          )}
          onConfirm={async () => {
            await dispatch(
              clearParticipantWaiver(
                reservation?.id ?? '',
                reservation.waiver_info?.waiver_id ?? '',
                participantNameToClear
              )
            );
            await dispatch(fetchReservationByID(reservation.id));
          }}
          loading={waiverLoading}
          onClose={() => setParticpantNameToClear('')}
          open={Boolean(participantNameToClear)}
        />
      )}
      <table className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}>
        <thead>
          <tr>
            <th>{t('Status')}</th>
            {(reservation.waiver_info?.participant_statuses ?? []).length !=
              0 && <th>{t('Guest')}</th>}
            {complete && (
              <>
                <th className={baseStyles['u-width-128']}>
                  {t('Submission Date')}
                </th>
                <th>{t('Action')}</th>
                <th>{t('Digital Fingerprints')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-text={t('Status')}>{StatusBadge}</td>
            {(reservation.waiver_info?.participant_statuses ?? []).length !=
              0 && (
              <td
                className={clsx(styles['p-agreement__table__guests'])}
                data-text={t('Guest')}
              >
                {(reservation.waiver_info?.participant_statuses ?? []).map(
                  (participantStatus, idx) => (
                    <div
                      key={idx}
                      className={styles['p-agreement__table__guests__details']}
                    >
                      <div>
                        {participantStatus.status === 'UNSIGNED' ? (
                          <Badge label={t('Not Signed')} color="gray" />
                        ) : (
                          <Badge label={t('Completed')} color="success" />
                        )}
                      </div>
                      <p style={{ marginLeft: '8px', marginBottom: '0px' }}>
                        {participantStatus.participant_full_name}
                      </p>
                      <div>
                        {participantStatus.status !== 'UNSIGNED' &&
                          hasCustomUserRoleWritePermissions(
                            activeUser,
                            'RESERVATION'
                          ) && (
                            <Button
                              text={t('Delete')}
                              size="xs"
                              color="white"
                              onClick={() =>
                                setParticpantNameToClear(
                                  participantStatus.participant_full_name ?? ''
                                )
                              }
                              iconBeforeText={
                                <i className="c-icon-outline-general-trash-03"></i>
                              }
                            />
                          )}
                      </div>
                    </div>
                  )
                )}
              </td>
            )}
            {complete && (
              <>
                <td data-text={t('Submission Date')}>
                  <div>
                    {moment(reservation.waiver_info?.submitted_date_time_utc)
                      .locale(locale)
                      .format('lll')}
                  </div>
                </td>
                <td data-text={t('Action')}>
                  <div>
                    <div style={{ marginBottom: '5px' }}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${bookingWebsiteUrl}/waivers/${
                          reservation.waiver_info?.waiver_id ?? ''
                        }`}
                        data-text={t('View')}
                        style={{ display: 'inline-block' }}
                      >
                        <Button
                          text={t('View')}
                          uiType="bg"
                          size="md"
                          color="white"
                          iconAfterText={
                            <i className="c-icon-outline-general-link-external-02"></i>
                          }
                        />
                      </a>
                    </div>
                    <Button
                      text={t('Download as PDF')}
                      uiType="bg"
                      size="md"
                      color="primary"
                      loading={waiverPDFStatus === 'IN_FLIGHT'}
                      onClick={() => {
                        dispatch(
                          fetchWaiverPDF(
                            reservation.waiver_info?.waiver_id ?? ''
                          )
                        );
                      }}
                    />
                  </div>
                </td>
                <td
                  className={styles['p-agreement__finger__print']}
                  style={{ height: 'auto' }}
                >
                  <div
                    className={
                      styles['p-agreement__table__digitalFingerprints']
                    }
                  >
                    <div
                      onClick={() =>
                        setShowDigitalFingerprint(!showDigitalFingerprint)
                      }
                      className={styles['link']}
                    >
                      {showDigitalFingerprint ? t('Hide') : t('Show')}
                    </div>

                    {/* TODO: for now I'm not going to touch styling of digital fingerprints, this should be included in new design mockup HTML */}
                    {showDigitalFingerprint &&
                      fingerprints.map((fingerprint, idx) => (
                        <div
                          key={idx}
                          style={{ marginTop: '8px' }}
                          className={oldBaseStyles['base-selectFrame']}
                        >
                          <div
                            className={
                              oldBaseStyles['base-selectFrame__header']
                            }
                          >
                            <p
                              className={
                                oldBaseStyles['base-selectFrame__header__ttl']
                              }
                            >
                              {`#${idx + 1} - ${
                                fingerprint.submissionDateTimeUtc
                                  ? moment(
                                      fingerprint.submissionDateTimeUtc
                                    ).format('lll')
                                  : '-'
                              }`}
                            </p>
                          </div>
                          <div
                            className={oldBaseStyles['base-selectFrame__body']}
                          >
                            {fingerprint.ipAddress && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div
                                  className={clsx(
                                    styles['p-agreement__finger__print__items']
                                  )}
                                >
                                  {t('IP Address: ')}
                                </div>
                                <div>{fingerprint.ipAddress}</div>
                              </div>
                            )}
                            {fingerprint.requestorId && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div
                                  className={clsx(
                                    styles['p-agreement__finger__print__items']
                                  )}
                                >
                                  {t('ID: ')}
                                </div>
                                <div>{fingerprint.requestorId}</div>
                              </div>
                            )}
                            {fingerprint.participantFullName && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div
                                  className={clsx(
                                    styles['p-agreement__finger__print__items']
                                  )}
                                >
                                  {t('Participant Name: ')}
                                </div>
                                <div>{fingerprint.participantFullName}</div>
                              </div>
                            )}
                            {fingerprint.signatures &&
                              fingerprint.signatures.length > 0 && (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <div
                                      className={clsx(
                                        styles[
                                          'p-agreement__finger__print__items'
                                        ]
                                      )}
                                    >
                                      {t('Signatures: ')}
                                    </div>
                                  </div>
                                  <div>
                                    {fingerprint.signatures?.map(
                                      (signature, idx) => (
                                        <div
                                          className={styles['signature-box']}
                                          key={idx}
                                        >
                                          <img src={signature} />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <div style={{ display: 'flex' }}>
        {!waiverIsComplete && isFutureReservation && (
          <div style={{ marginTop: '24px' }}>
            {!isSubscriptionCancelled(
              activeUserOrganization,
              'feature-digital-waiver'
            ) ? (
              <>
                <Button
                  text={t('Send Waiver Request Email')}
                  uiType="bg"
                  size="md"
                  color="primary"
                  disabled={emailSendingStatus === 'SUCCEEDED'}
                  loading={emailSendingStatus === 'REQUESTED'}
                  onClick={() => setShowModal(true)}
                />
                {showModal && (
                  <SendWaiverRequestEmailModal
                    initialToAddress={email}
                    onClose={() => setShowModal(false)}
                    onSubmit={(email?: string) => {
                      try {
                        setEmailSendingStatus('REQUESTED');
                        dispatch(
                          sendWaiverRequestEmail(reservation?.id ?? '', email)
                        );
                        setEmailSendingStatus('SUCCEEDED');
                      } catch (e) {
                        setEmailSendingStatus('FAILED');
                      }
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {t(
                  'Your subscription to the waivers feature has been deactivated so sending waiver request emails is disabled. Resubscribe here: '
                )}{' '}
                <a
                  className={styles['link']}
                  href={`${window.origin}/systemfee/settings`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Settings')}
                </a>
              </>
            )}
            <Snackbar
              text={t('Send Successful')}
              color="success"
              shouldShow={emailSendingStatus == 'SUCCEEDED'}
            />
            <Snackbar
              text={t('Send Failed')}
              color="error"
              shouldShow={emailSendingStatus == 'FAILED'}
            />
          </div>
        )}
        {(waiverStatus === 'COMPLETE' || waiverStatus === 'IN_PROGRESS') &&
          hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') && (
            <div className={styles['p-agreement__bottom']}>
              <a
                className={clsx(
                  buttonStyles['c-button'],
                  buttonStyles['bg'],
                  buttonStyles['md'],
                  buttonStyles['white']
                )}
                onClick={() => setShowClearWaiversModal(true)}
              >
                <i className="c-icon-outline-general-trash-03"></i>
                <p>{t('Clear Waivers')}</p>
              </a>
            </div>
          )}
      </div>
    </div>
  );
};
