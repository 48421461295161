import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldRenderProps } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, TextArea } from 'client/components/Form';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';

import { TextEditorInsertQuestionModal } from './TextEditorInsertQuestionModal';
import styles from './WaiverTemplateList.module.css';

const getAllQuestions = (text: string, baseIdx: number): Question[] => {
  if (!text) {
    return [];
  }

  const questionMatch = text.match(/{{(.+)}}/);

  if (!questionMatch) {
    return [];
  }

  return [
    {
      text: questionMatch[1],
      startIndex: baseIdx + (questionMatch.index ?? 0),
      endIndex: baseIdx + (questionMatch.index ?? 0) + questionMatch[0].length,
    },
    ...getAllQuestions(
      text.substring((questionMatch.index ?? 0) + questionMatch[0].length),
      baseIdx + (questionMatch.index ?? 0) + questionMatch[0].length
    ),
  ];
};

type Props = {
  inputProps: FieldRenderProps<any>['input'];
  label: string;
  lng: string;
};
interface Question {
  text: string;
  // start index inclusive
  startIndex: number;
  // end index inclusive
  endIndex: number;
}
export const TextInput = ({ inputProps, label, lng }: Props) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const [selectedQuestion, setSelectedQuestion] =
    React.useState<Question | null>(null);
  const { t } = useTranslation();
  const questions = getAllQuestions(inputProps.value, 0);
  return (
    <div>
      <p className={styles['scheds__body']}>
        <TextArea {...(inputProps as any)} label={label} ref={textAreaRef} />
      </p>
      <Box mt={2} mb={2}>
        {t(
          'Note: To request customer input inside text for "Initials", "Full Name", or any custom question, click the desired location in "Text Content" and then click "Insert Question"'
        )}
      </Box>
      <TextEditorInsertQuestionModal
        trigger={
          <Button size="middle" style="green">
            {t('Insert Question')}
          </Button>
        }
        lng={lng}
        onSave={(text: string) => {
          if (selectedQuestion) {
            inputProps.onChange(
              [
                inputProps.value.slice(0, selectedQuestion.startIndex),
                text ? `{{${text}}}` : '',
                inputProps.value.slice(selectedQuestion.endIndex),
              ].join('')
            );
          } else {
            const cursorPosition = textAreaRef.current?.selectionStart ?? 0;
            inputProps.onChange(
              [
                inputProps.value.slice(0, cursorPosition),
                text ? `{{${text}}}` : '',
                inputProps.value.slice(cursorPosition),
              ].join('')
            );
          }

          setSelectedQuestion(null);
        }}
        existingQuestionText={selectedQuestion?.text}
      />
      {questions.length > 0 && (
        <Box mt={4}>
          <FieldWrapper label={t('Edit questions that appear in text')}>
            <table>
              <tbody>
                {questions.map((question, idx) => (
                  <tr key={idx}>
                    <td>{question.text}</td>
                    <td width="80px">
                      <Box display="flex">
                        <TextEditorInsertQuestionModal
                          lng={lng}
                          trigger={
                            <a>
                              <img src={editIcon} />
                            </a>
                          }
                          onSave={(text: string) => {
                            inputProps.onChange(
                              [
                                inputProps.value.slice(0, question.startIndex),
                                text ? `{{${text}}}` : '',
                                inputProps.value.slice(question.endIndex),
                              ].join('')
                            );
                          }}
                          existingQuestionText={question.text}
                        />
                        <DeleteConfirmModal
                          trigger={
                            <a>
                              <img src={deleteIcon} />
                            </a>
                          }
                          onConfirm={() => {
                            inputProps.onChange(
                              [
                                inputProps.value.slice(0, question.startIndex),
                                inputProps.value.slice(question.endIndex),
                              ].join('')
                            );
                          }}
                          header={t('Delete Question')}
                          content={t(
                            'Are you sure you want to delete "{{name}}"?',
                            {
                              name: question.text,
                            }
                          )}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </FieldWrapper>
        </Box>
      )}
    </div>
  );
};
