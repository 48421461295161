import * as React from 'react';
import { CheckerboardTile } from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Delete } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import { DraggableItem } from '../DraggableItem';

import { EditCheckerboardTileModal } from './EditCheckerboardTileModal';

interface Props {
  items: CheckerboardTile[];
  onItemsChange: (arg0: CheckerboardTile[]) => void;
  index: number;
}

export const DraggableCheckerboardTile = ({
  items,
  onItemsChange,
  index,
}: Props) => {
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  return (
    <>
      {showEditModal && (
        <EditCheckerboardTileModal
          value={items[index]}
          onChange={(newValue) =>
            onItemsChange(
              items.map((item, i) => (i === index ? newValue : item))
            )
          }
          onClose={() => {
            setShowEditModal(false);
          }}
        />
      )}
      <DraggableItem
        value={items[index]}
        index={index}
        listValue={items}
        onListValueChange={onItemsChange}
      >
        <Edit
          className={baseStyles['icon-box']}
          onClick={() => setShowEditModal(true)}
        />
        <Delete
          className={baseStyles['icon-box']}
          onClick={() => onItemsChange(items.filter((i, idx) => idx !== index))}
        />
        {items[index].title}
      </DraggableItem>
    </>
  );
};
