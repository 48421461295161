import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { ReservationRedemptionCountTable } from 'client/components/v3/ReservationRedemptionCountTable/ReservationRedemptionCountTable';
import type {
  Reservation,
  Product,
  ProductSummary,
} from 'shared/models/swagger';

type Props = {
  reservation?: Reservation | ManifestReservationShape;
  product?: Product | ProductSummary;
};

export const RedemptionCountCheckin = ({ reservation, product }: Props) => {
  return (
    <ReservationRedemptionCountTable
      reservation={reservation}
      product={product}
    />
  );
};
