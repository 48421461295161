import * as React from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';

import { CustomConditionsEditor } from './CustomConditionsEditor';
import { TranslatedInputContainer } from './TranslatedInputContainer';
import { EditSurveyQuestionModal } from './EditSurveyQuestionModal';
import { DraggableSurveyQuestion } from './DraggableSurveyQuestion';
import { FormValues, SurveyQuestion } from './formValues';

interface Props {
  initialName: string;
  onClose: () => void;
}

export const EditSurveyPageModal = ({ initialName, onClose }: Props) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);
  const [name] = React.useState(initialName);
  const [editingQuestionName, setEditingQuestionName] = React.useState('');
  const { values } = useFormState<FormValues>();

  const form = useForm();

  React.useEffect(() => {
    const initialValue = _.get(form.getState().values, name);

    // Set key for the page if it doesn't exist
    if (!initialValue?.key) {
      form.change(`${name}.key`, uuid());
    }
  }, [name]);

  const targetOptions = [
    {
      value: 'GENDER_FEMALE',
      text: t('(Gender) Female'),
    },
    {
      value: 'GENDER_MALE',
      text: t('(Gender) Male'),
    },
    {
      value: 'GENDER_OTHER',
      text: t('(Gender) No Response'),
    },
    {
      value: 'AGE_20s',
      text: t('(Age) 20s'),
    },
    {
      value: 'AGE_30s',
      text: t('(Age) 30s'),
    },
    {
      value: 'AGE_40s',
      text: t('(Age) 40s'),
    },
    {
      value: 'AGE_50s',
      text: t('(Age) 50s'),
    },
    {
      value: 'AGE_60_plus',
      text: t('(Age) 60+'),
    },
  ];

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t('Edit Survey Page')}
      insertRoot
    >
      <Modal.Content>
        <TranslatedField name={`${name}.title`}>
          {({ input, translationInput }) => (
            <TranslatedInputContainer>
              <Input {...input} label={t('Title')} />
              {translationTargetLanguage && (
                <Input
                  {...translationInput}
                  label={t('Title ({{language}})', {
                    language: translationTargetLanguageName,
                  })}
                />
              )}
            </TranslatedInputContainer>
          )}
        </TranslatedField>
        <Box mt={2}>
          <Field name={`${name}.presetConditions`}>
            {({ input }) => (
              <MultiSelect
                label={t('Preset Targets')}
                options={targetOptions}
                selectedValues={
                  /* On page initialization, value may be string. Check for array type. */
                  Array.isArray(input.value) ? input.value : []
                }
                onChange={({ value }) => input.onChange(value)}
              />
            )}
          </Field>
        </Box>
        <FieldWrapper label={t('Custom Targets')} />
        <CustomConditionsEditor name={`${name}.customConditions`} />
        <Box mt={2}>
          <FieldWrapper label={t('Questions')}>
            <FieldArray name={`${name}.surveyQuestions`}>
              {({ fields }) => (
                <>
                  {/* Do not show add question button if survey type = 'NPS' */}
                  {values.surveyType === 'NORMAL' && (
                    <Box mb={2}>
                      {t('* drag-and-drop to reorder')}
                      <Button
                        style="green"
                        size="middle"
                        onClick={() =>
                          setEditingQuestionName(
                            `${fields.name}[${fields.length}]`
                          )
                        }
                      >
                        {t('Add question')}
                      </Button>
                    </Box>
                  )}
                  {editingQuestionName && (
                    <EditSurveyQuestionModal
                      initialName={editingQuestionName}
                      onClose={() => setEditingQuestionName('')}
                    />
                  )}
                  {fields.map((name, idx) => (
                    <DraggableSurveyQuestion
                      key={fields.value?.[idx]?.key}
                      onEditClick={() => setEditingQuestionName(name)}
                      name={name}
                      index={idx}
                      moveItem={(dragIndex: number, hoverIndex: number) => {
                        {
                          /* Do not allow drag&drop if survey type = 'NPS' */
                        }
                        if (values.surveyType === 'NORMAL') {
                          fields.move(dragIndex, hoverIndex);
                        }
                      }}
                      deleteItem={async () => {
                        fields.remove(idx);
                      }}
                      onSave={(value: SurveyQuestion) =>
                        form.change(name, value)
                      }
                    />
                  ))}
                </>
              )}
            </FieldArray>
          </FieldWrapper>
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button style="green" size="middle" onClick={onClose}>
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
