import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getReservationCSVColumns } from '../components/DownloadReservationsCSVModal/util';

type Props = {
  appliedDownloadItems: string[];
};
export const DownloadItemsDisplayBox = ({ appliedDownloadItems }: Props) => {
  const { t } = useTranslation();
  const allColumns = getReservationCSVColumns(t);
  const downloadItems = appliedDownloadItems.map((item) => {
    return allColumns.find((col) => col.id === item)?.Header;
  });
  return (
    <div>
      <p>{downloadItems.join(', ')}</p>
    </div>
  );
};
