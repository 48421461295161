import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { FieldWrapper, Input, ToggleButton } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

interface Props {
  name: string;
}

export const StampRallyEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const form = useForm();
  const values = _.get(form.getState().values, name);

  return (
    <>
      <Box mt={4}>
        <Field name={`${name}.shouldUseStampRally`} type="checkbox">
          {({ input }) => (
            <ToggleButton
              label={t('Use stamp rally')}
              checked={input.checked}
              onChange={() => input.onChange(!input.checked)}
            />
          )}
        </Field>
      </Box>
      {values?.shouldUseStampRally && (
        <>
          <Box mt={2}>
            <Field name={`${name}.stampRallyLabel`} type="input">
              {({ input }) => <Input label={t('Stamp title')} {...input} />}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name={`${name}.stampImageUrl`}>
              {({ input, meta: { touched, error } }) => (
                <FieldWrapper label={t('Stamp Image (jpg, png)')}>
                  <ImageVideoAudioInput
                    fileUrls={input.value ? [input.value] : []}
                    onChange={(newValue) =>
                      newValue.length > 0
                        ? input.onChange(newValue[0])
                        : input.onChange('')
                    }
                    maxFileCount={1}
                    disableYoutubeVideos
                    error={touched && error}
                  />
                </FieldWrapper>
              )}
            </Field>
          </Box>
        </>
      )}
    </>
  );
};
