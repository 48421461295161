import React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SeatMap } from 'client/components/Seat/SeatMap';
import { EditEquipmentBlockInstancePropertyModal } from 'client/components/Seat/EditEquipmentBlockInstancePropertyModal';
import {
  findEquipmentBlockInstanceProperty,
  getEquipmentStartTimeMapping,
  filterProductInstancesByProductMapping,
} from 'client/components/Seat/utils';
import {
  EquipmentBlockInstanceProperty,
  ProductInstance,
} from 'shared/models/swagger';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentsSelector } from 'client/reducers/equipments';
import { updateEquipmentInstance } from 'client/actions/equipmentInstances';
import { Modal } from 'client/components/Modal/Modal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import type { ReduxState } from 'client/reducers';
import { SeatAssignmentNewReservationModal } from 'client/components/Seat/SeatAssignmentNewReservationModal';
import { fetchProductInstances } from 'client/actions/productInstances';
import { SeatAssignmentContext } from 'client/components/Seat/SeatAssignmentContext';
import { batchFetchEquipmentAssignmentsByProductInstanceIds } from 'client/actions/equipmentAssignments';
import { SeatContext } from 'client/contexts/SeatContext';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedEquipmentInstanceId: string;
}

export const EditSeatMapModal = ({
  open,
  onClose,
  selectedEquipmentInstanceId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const productInstanceById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );

  const { setEditMode } = React.useContext(SeatAssignmentContext);

  const [showCreateNewReservationModal, setShowCreateNewReservationModal] =
    React.useState(false);
  const [modalPosition, setModalPosition] = React.useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const [
    selectedAssignableEquipmentInstanceId,
    setSelectedAssignableEquipmentInstanceId,
  ] = React.useState<string>('');

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (instance) => instance.id === selectedEquipmentInstanceId
    );
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const selectedAssignableEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (instance) => instance.id === selectedAssignableEquipmentInstanceId
    );
  }, [equipmentInstances, selectedAssignableEquipmentInstanceId]);

  const selectedEquipmentStartTimeMapping = React.useMemo(() => {
    const equipment = (equipments ?? []).find(
      (equipment) => equipment.id === selectedEquipmentInstance?.equipment_id
    );
    return getEquipmentStartTimeMapping(
      selectedEquipmentInstance?.date ?? '',
      selectedEquipmentInstance?.start_time_key ?? '',
      equipment
    );
  }, [equipments, selectedEquipmentInstance]);

  React.useEffect(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return;
    }
    if (
      (selectedEquipmentStartTimeMapping.product_start_times ?? []).length == 0
    ) {
      return;
    }
    const startDate = moment.tz(
      selectedEquipmentInstance?.date ?? '',
      activeUserOrganization?.default_timezone ?? 'UTC'
    );
    const endDate = startDate.clone().add(1, 'day');
    (selectedEquipmentStartTimeMapping?.product_start_times || []).forEach(
      (time) => {
        if (!time.product_id) {
          return;
        }
        dispatch(fetchProductInstances(time.product_id, startDate, endDate));
      }
    );
  }, [selectedEquipmentStartTimeMapping]);

  React.useEffect(() => {
    fetchEquipmentAssignmentsHandler();
  }, [selectedEquipmentInstanceId]);

  const selectedProductInstances = React.useMemo(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return [] as ProductInstance[];
    }
    if (!productInstanceById) {
      return [] as ProductInstance[];
    }
    return (
      selectedEquipmentStartTimeMapping?.product_start_times ?? []
    ).reduce((acc: ProductInstance[], time) => {
      const filteredProductInstances = filterProductInstancesByProductMapping(
        time.product_id ?? '',
        time.recurrence_key ?? '',
        time.time_slot_key ?? '',
        selectedEquipmentInstance?.date ?? '',
        activeUserOrganization?.default_timezone ?? 'UTC',
        Object.values(productInstanceById)
      );
      acc.push(...filteredProductInstances);
      return acc;
    }, [] as ProductInstance[]);
  }, [productInstanceById, selectedEquipmentStartTimeMapping]);

  const [
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  ] = React.useState<
    {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    }[]
  >([]);

  const relatedEquipmentAssignments = equipmentAssignments;

  const relatedReservationIds = React.useMemo(() => {
    return [
      ...new Set(relatedEquipmentAssignments.map((a) => a.reservation_id)),
    ];
  }, [relatedEquipmentAssignments]);

  const filteredReservations = React.useMemo(() => {
    return reservations.filter((reservation) => {
      return relatedReservationIds.includes(reservation.id);
    });
  }, [relatedReservationIds, reservations]);

  const selectedEquipmentBlockInstanceKeysInThisEquipmentInstance =
    React.useMemo(() => {
      return selectedEquipmentBlockInstanceKeys
        .filter(
          (key) =>
            key.equipmentInstanceId === selectedAssignableEquipmentInstanceId
        )
        .map((key) => key.equipmentBlockInstanceKey);
    }, [
      selectedEquipmentBlockInstanceKeys,
      selectedAssignableEquipmentInstanceId,
    ]);

  const [showEditEquipmentInstanceModal, setShowEditEquipmentInstanceModal] =
    React.useState(false);

  const changeModalPositionHandler = (args: { top: number; left: number }) => {
    if (modalPosition.top === args.top && modalPosition.left === args.left) {
      return;
    }
    setModalPosition(args);
  };

  const fetchEquipmentAssignmentsHandler = () => {
    if (!selectedProductInstances) {
      return;
    }

    if (selectedProductInstances.length === 0) {
      return;
    }

    const productInstanceIds = selectedProductInstances.map(
      (instance) => instance.id
    );

    dispatch(
      batchFetchEquipmentAssignmentsByProductInstanceIds(productInstanceIds)
    );
  };

  return (
    <Modal
      title={`${t('Edit Equipment')}`}
      open={open}
      onClose={onClose}
      width="wide"
      onPositionChange={changeModalPositionHandler}
      activateTouchEvents={true}
    >
      <SeatContext.Provider
        value={{
          selectedEquipmentBlockInstanceKeys,
          setSelectedEquipmentBlockInstanceKeys,
          selectedAssignableEquipmentInstanceId,
          setSelectedAssignableEquipmentInstanceId,
        }}
      >
        <div style={{ backgroundColor: '#fff' }}>
          <SeatMap
            selectedEquipmentInstanceId={selectedEquipmentInstanceId}
            onEditPropertiesClick={() => {
              setShowEditEquipmentInstanceModal(true);
            }}
            positionOffset={modalPosition}
            onReset={() => {
              setSelectedEquipmentBlockInstanceKeys([]);
              setEditMode(null);
            }}
            filteredReservations={filteredReservations}
            filteredEquipmentAssignments={relatedEquipmentAssignments}
            onNewReservationClick={() => {
              setShowCreateNewReservationModal(true);
            }}
          />
        </div>

        {showCreateNewReservationModal && (
          <SeatAssignmentNewReservationModal
            onReset={() => {
              setSelectedEquipmentBlockInstanceKeys([]);
            }}
            open={showCreateNewReservationModal}
            onClose={() => {
              setShowCreateNewReservationModal(false);
            }}
            selectedProductInstances={selectedProductInstances}
            selectedEquipmentInstanceId={selectedEquipmentInstanceId}
          />
        )}
      </SeatContext.Provider>

      <EditEquipmentBlockInstancePropertyModal
        equipmentBlockInstanceProperty={
          selectedEquipmentBlockInstanceKeys.length === 1
            ? findEquipmentBlockInstanceProperty(
                selectedEquipmentBlockInstanceKeys[0]
                  ?.equipmentBlockInstanceKey,
                selectedEquipmentBlockInstanceKeys[0]?.equipmentInstanceId,
                equipments,
                equipmentInstances,
                activeUserOrganization
              )
            : null
        }
        open={showEditEquipmentInstanceModal}
        onClose={() => {
          setShowEditEquipmentInstanceModal(false);
          setSelectedEquipmentBlockInstanceKeys([]);
        }}
        onSave={async (newProperty: EquipmentBlockInstanceProperty) => {
          await dispatch(
            updateEquipmentInstance({
              id: selectedAssignableEquipmentInstanceId,
              equipment_id:
                selectedAssignableEquipmentInstance?.equipment_id ?? '',
              date: selectedAssignableEquipmentInstance?.date ?? '',
              start_time_key:
                selectedAssignableEquipmentInstance?.start_time_key ?? '',
              equipment_block_instance_properties: [
                ...(selectedAssignableEquipmentInstance?.equipment_block_instance_properties?.filter(
                  (property) =>
                    !selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.includes(
                      property.equipment_block_instance_key ?? ''
                    )
                ) ?? []),
                ...(selectedEquipmentBlockInstanceKeysInThisEquipmentInstance.map(
                  (key) => {
                    return {
                      ...newProperty,
                      equipment_block_instance_key: key,
                    };
                  }
                ) ?? []),
              ],
            })
          );
        }}
      />
    </Modal>
  );
};
