import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from './EditTargetModal.module.css';
import { QueryListEditor } from './QueryListEditor';
import { FormValues } from './formValues';

type Props = {
  name: string;
  onClose: () => void;
};

export const EditTargetModal = ({ name, onClose }: Props) => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();

  return (
    <Modal
      title={t('Edit Target')}
      open={true}
      onClose={onClose}
      rightActionChildren={<Button text={t('Set')} onClick={onClose} />}
      insertAtRoot={true}
      style={{ width: '600px', height: '800px' }}
    >
      <div className={styles['p-targetModal']}>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Base URL')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <TextField
              value={formState.values.baseUrl}
              onChange={() => {
                // For display only
              }}
              disabled={true}
            />
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Path')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <Field name={`${name}.path`}>
              {({ input, meta: { error, touched } }) => {
                return (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                    error={touched && error ? error : undefined}
                  />
                );
              }}
            </Field>
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Query')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <QueryListEditor
              name={`${name}.queries`}
              addButtonText={t('Add Query')}
            />
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Hash')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <Field name={`${name}.hash`}>
              {({ input, meta: { error, touched } }) => {
                return (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                    error={touched && error ? error : undefined}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </Modal>
  );
};
