import { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import type { SourceLanguage } from 'shared/models/swagger';

import { ItemInputForm } from './ItemInputForm';

interface DragItem {
  index: number;
  type: string;
}

type Props = {
  name: string;
  idx: number;
  translationTargetLanguage: SourceLanguage | null;
  onRemoveClick: () => void;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
};

const itemType = 'selectable-line-message-item';

export const DraggableItemInputForm = ({
  name,
  idx,
  translationTargetLanguage,
  onRemoveClick,
  onMoveItem,
}: Props) => {
  const ref = useRef<HTMLTableRowElement | null>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, DragItem, { handlerId: any }>(
    {
      accept: itemType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = idx;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        onMoveItem(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }
  );

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: { type: itemType, index: idx },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <tr ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <ItemInputForm
        name={name}
        translationTargetLanguage={translationTargetLanguage}
        onRemoveClick={onRemoveClick}
      />
    </tr>
  );
};
