import * as React from 'react';
import { Field } from 'react-final-form';

import { BaseTemplate } from './BaseTemplate';
import { withQueryParams, truncated, CustomCard } from './util';

const ImageEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ImageEditButton')
);
const CustomCardEditButtons = React.lazy(
  () => import('client/components/TemplateEditButtons/CustomCardEditButtons')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  customCards: CustomCard[];
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const Custom = ({
  editMode,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  keyVisualUrl,
  headline,
  headlineDescription,
  customCards,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
  language,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* お客様向けメッセージ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1em .5em',
              backgroundColor: '#fff',
            }}
          >
            <p style={{ marginTop: '.5em' }}>
              {customerName
                ? customerIntroduction.replace('{customerName}', customerName)
                : customerIntroduction}
              <br />
              <br />

              {editMode && (
                <Field name="templateValues.introParagraph">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <span style={{ whiteSpace: 'pre-line' }}>{introParagraph}</span>
            </p>
          </div>
        </td>
      </tr>
      {/* 当店一番人気のプランは残席わずか！ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '0em .5em 1em .5em',
              backgroundColor: '#ffffff',
            }}
          >
            {editMode && (
              <Field name="templateValues.keyVisualUrl">
                {({ input }) => (
                  <ImageEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <div
              style={{
                width: '100%',
                marginBottom: '1em',
                background: '#ddd',
                overflow: 'hidden',
              }}
            >
              <img src={keyVisualUrl} style={{ width: '100%' }} />
            </div>
            {editMode && (
              <Field name="templateValues.headline">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                padding: '.5em',
                fontWeight: 'bold',
                borderRadius: '4px',
                color: '#ffffff',
                backgroundColor: '#4A6C7A',
                whiteSpace: 'pre-line',
              }}
            >
              {headline}
            </p>
            {editMode && (
              <Field name="templateValues.headlineDescription">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: '1em 0 0 0',
                padding: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {headlineDescription}
            </p>

            {customCards.map((card, idx) => (
              <>
                {editMode && (
                  <>
                    <Field name="templateValues.customCards">
                      {({ input }) => (
                        <CustomCardEditButtons
                          onSave={(newValue) => input.onChange(newValue)}
                          initialValue={input.value}
                          cardIdx={idx}
                        />
                      )}
                    </Field>
                  </>
                )}
                <a
                  href={withQueryParams(card.linkUrl, {
                    utm_source: 'ntmg.newsletter',
                    utm_medium: 'email',
                    utm_campaign: newsletterCampaignId ?? 'todo',
                    utm_content: 'mainProduct',
                    lng: language,
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    width: '100%',
                    margin: '1em 0 0 0',
                    padding: 0,
                    background: '#F9F9F9',
                    textDecoration: 'none',
                  }}
                >
                  <div
                    style={{
                      margin: '2em 0.5em 1em',
                      position: 'relative',
                      verticalAlign: 'middle',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={card.thumbnailUrl}
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      padding: '.5em',
                      color: '#333333',
                      verticalAlign: 'middle',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: '16px',
                        fontWeight: 'bold',
                        lineHeight: '1.5em',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {card.title}
                    </p>
                    <p
                      style={{
                        margin: '.5em 0 0 0',
                        padding: 0,
                        fontSize: '12px',
                        color: '#4A6C7A',
                        lineHeight: '1.5em',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {truncated(card.description, 200)}
                    </p>
                  </div>
                </a>
                <div style={{ margin: '1em auto 0 auto' }}>
                  <a
                    href={withQueryParams(card.linkUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: newsletterCampaignId ?? 'todo',
                      utm_content: 'mainProduct',
                      lng: language,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: '320px',
                      border: '1px solid #008EC9',
                      borderRadius: '48px',
                      textDecoration: 'none',
                      padding: '2px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                  >
                    <span
                      style={{
                        margin: 0,
                        padding: 0,
                        display: 'block',
                        fontWeight: 'bold',
                        width: '100%',
                        height: '48px',
                        lineHeight: '48px',
                        fontSize: '20px',
                        backgroundColor: '#008EC9',
                        color: '#ffffff',
                        textDecoration: 'none',
                        textAlign: 'center',
                        borderRadius: '40px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {card.buttonText}
                    </span>
                  </a>
                </div>
              </>
            ))}
          </div>
        </td>
      </tr>
    </BaseTemplate>
  );
};
