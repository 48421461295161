import * as React from 'react';

import { useBounds } from 'client/hooks/useBounds';
import { PortalTooltip } from 'client/components/PortalTooltip/PortalTooltip';

import styles from './ManifestTableHeaderCell.module.css';

interface Props {
  text: string;
}

export const ManifestTableHeaderCell = ({ text }: Props) => {
  const [divRef, divBounds] = useBounds<HTMLDivElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <div
      onMouseEnter={() => {
        const scrollHeight = divRef.current?.scrollHeight ?? 0;
        const clientHeight = divRef.current?.clientHeight ?? 0;
        if (scrollHeight > clientHeight) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => setShowTooltip(false)}
      ref={divRef}
      className={styles['table-header-container']}
    >
      {text}
      {showTooltip && (
        <PortalTooltip
          text={text}
          style={{
            left: divBounds.left,
            top: divBounds.top,
          }}
        />
      )}
    </div>
  );
};
