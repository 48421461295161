import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';
import { Form, Field, useForm } from 'react-final-form';
import { FooterSettings } from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import { Button, FieldWrapper } from 'client/components/Form';

export const EditFooter = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  const form = useForm();
  const values = form.getState().values;

  const { footerBackgroundColor, footerTextColor } = values;

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          width="wide"
          title={t('Edit Footer')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              footerBackgroundColor,
              footerTextColor,
            }}
            onSubmit={(values: FooterSettings) => {
              form.batch(() => {
                Object.entries(values).forEach(([key, value]) => {
                  form.change(key, value);
                });
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <FieldWrapper label={t('Background Color')}>
                    <Field name="footerBackgroundColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  <FieldWrapper label={t('Text Color')}>
                    <Field name="footerTextColor">
                      {({ input }) => (
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
