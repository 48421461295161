/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';

import styles from './USATerms.module.css';

export const USATerms = () => {
  return (
    <div className={styles['p-regist__terms']}>
      <div className={styles['p-regist__terms__header']}>
        Terms and Conditions
      </div>
      <div className={styles['p-regist__terms__body']}>
        <section className={styles['p-newsArchive__body']}>
          <div className={styles['c-inner--middle']}>
            <div className={styles['terms']}>
              <h2 className={styles['c-headline']}>Provider Agreement</h2>
              <div className={styles['terms-block']}>
                <p>
                  This Provider Agreement (<strong>“Provider Agreement</strong>
                  ”) contains the terms under which NutmegLabs, Inc. (
                  <strong>“Nutmeg”</strong>) agrees to grant Provider access to
                  and use of Nutmeg’s digital booking services and solutions.
                  The parties agree as follows:
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>1. DEFINITIONS</h3>
                <ul>
                  <li>
                    <strong>1.1. “Affiliate" </strong> means any entity that,
                    directly or indirectly, controls, is controlled by or is
                    under common control with such entity (but only for so long
                    as such control exists), where <strong>“control" </strong>{' '}
                    means the ownership of more than 50% of the outstanding
                    shares or securities representing the right to vote in the
                    election of directors or other managing authority of such
                    entity.
                  </li>
                  <li>
                    <strong>1.2. “Agreement" </strong> means this Provider
                    Agreement together with each Service Addendum.
                  </li>
                  <li>
                    <strong>1.3. “Chargeback" </strong> means a request to
                    invalidate a payment that a Customer files directly with (a)
                    his or her debit or credit card company; (b) his or her
                    debit or credit card-issuing bank; or (c) any other entity
                    that provides payment services.
                  </li>
                  <li>
                    <strong>1.4. “Confidential Information" </strong> means
                    information in any form, whether oral or written, of a
                    business, financial or technical nature which is disclosed
                    by a party (“Disclosing Party”) in the course of this
                    Agreement and which the recipient (“Receiving Party”)
                    reasonably should know is confidential, but excluding the
                    information listed in Section 12.2. The terms of this
                    Agreement (including pricing) are each party’s Confidential
                    Information.
                  </li>
                  <li>
                    <strong>1.5. “Customer(s)" </strong> means one or more of
                    any person or entity that reserves or purchases a Provider
                    Service from Provider’s business, where the purchase is made
                    through or entered into the Nutmeg Platform .
                  </li>
                  <li>
                    <strong>1.6. “Effective Date" </strong> means the date when
                    Provider creates Provider Account in the Nutmeg Platform.
                  </li>
                  <li>
                    <strong>1.7. “Fraudulent Charge" </strong> means a credit or
                    debit card charge by a Customer that is determined by the
                    card company or payment processor to be fraudulent.
                  </li>
                  <li>
                    <strong>1.8. “Nutmeg Content" </strong> means all
                    information, data, products, materials, and all other
                    content, software and services made available on or through
                    the Nutmeg Platform or otherwise generally provided to
                    Provider by Nutmeg.
                  </li>
                  <li>
                    <strong>1.9. “Nutmeg Fees" </strong> means the Commissions,
                    Subscription fees and other amounts payable to Nutmeg.
                  </li>
                  <li>
                    <strong>1.10. “Nutmeg Platform" </strong> means the Nutmeg
                    Platform offering that consists of (a) an online service to
                    enable online bookings (referred to as "Nutmeg Booking
                    Website"), (b) a comprehensive product suite, including any
                    Nutmeg mobile or similar application for use in connection
                    with such online service and related Nutmeg Content, and (c)
                    all services to enable connectivity with Provider and/or
                    other Third parties (referred to as "Nutmeg APIs").
                  </li>
                  <li>
                    <strong>1.11. “Provider" </strong> means the entity
                    identified in this agreement
                  </li>
                  <li>
                    <strong>1.12. “Provider Content" </strong> means (a) each
                    and all of the text, graphics, logos, video, audio,
                    information, data, software, and other content and materials
                    relating to the Provider Services which are produced or
                    provided by or for Provider, and (b) any and all copyrights,
                    trademarks and other intellectual property and proprietary
                    rights related thereto, as now or hereafter constituted and
                    in any form or media.
                  </li>
                  <li>
                    <strong>1.13. "Provider Online Channel(s)" </strong>means
                    one or more of any website, mobile application or other
                    online platform of Provider or its Affiliates which offer
                    Provider Services.
                  </li>
                  <li>
                    <strong>1.14. “Provider Service(s)" </strong> means one or
                    more of Provider’s activities or other goods or services
                    that the Provider offers to Customers using the Nutmeg
                    Platform, or otherwise manages using the Nutmeg Platform.
                  </li>
                  <li>
                    <strong>1.15. “Service Addendum" </strong> means an addendum
                    attached to this Agreement, which contains terms specific to
                    a purchased Nutmeg Platform offering.
                  </li>
                  <li>
                    <strong>1.16. “Voucher" </strong> means a confirmation of
                    purchase provided by Nutmeg to a Customer after processing
                    the Customer’s purchase.
                  </li>
                </ul>
              </div>
              <div className={styles['terms-block']}>
                <h3>2. THE SERVICES</h3>
                <h4>2.1 License:</h4>
                <p>
                  Subject to Provider’s full compliance with this Agreement
                  (including this Service Addendum) at all times, Nutmeg grants
                  to Provider a limited non-exclusive, non-sublicensable,
                  non-transferable, non-assignable and revocable right and
                  license during the Term to access and use the Nutmeg Platform
                  solely for its internal business operations and for purposes
                  of (a) processing sales made directly from Provider Online
                  Channels and (b) using web and mobile tools to assist Provider
                  in managing its business. The use of the Nutmeg Platform for
                  any purpose not expressly permitted by this Agreement
                  (including this Service Addendum) is prohibited and will be
                  deemed to be a material breach of this Agreement (including
                  this Service Addendum).
                </p>
                <h4>2.2 Provider Account</h4>
                <p>
                  Provider must set up an account with Nutmeg in connection with
                  Provider’s use of the Nutmeg Platform ("Provider Account").
                  Provider will safeguard Provider Account user authentication
                  credentials in its possession or under its control and is
                  responsible for acts and omissions of its Provider Account
                  users relating to this Agreement as though they were
                  Provider’s own. Provider is responsible for all activities
                  that occur under the Provider Account and for the accuracy,
                  quality and integrity of the Provider Content that Provider or
                  its users Feedback into the Nutmeg Platform.
                </p>
                <h4>2.3 Use of the Nutmeg Platform. </h4>
                <p>
                  The rights granted to Provider to use each Nutmeg Platform
                  offering are set forth in the applicable Service Addendum, and
                  are subject to any use limitations that may be set forth in
                  the applicable Service Addendum.
                </p>
                <h5>2.3.1. Paid Plans.</h5>
                <p>
                  If Provider has purchased Nutmeg’s Paid Plans, the Nutmeg Paid
                  Plan Addendum attached hereto will apply.
                </p>
                <h4>2.4 Modifications.</h4>
                <p>
                  Nutmeg may modify or update the Nutmeg Platform at any time
                  without prior notice.
                </p>
                <h4>2.5 Changes to Agreement.</h4>
                <p>
                  From time to time Nutmeg may change the terms of this Master
                  Agreement or a Service Addendum. Nutmeg reserves the right to
                  make any and all such changes. Nutmeg will strive to provide
                  advance notice of any change that materially alters the terms
                  of this Master Agreement or a Service Addendum.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>3. SALE AND PERFORMANCE OF PROVIDER SERVICES</h3>
                <h4>3.1 Merchant of Record.</h4>
                <p>
                  Provider agrees that it is the sole merchant of record of all
                  Provider Services, and that Nutmeg’s activities are undertaken
                  solely to assist Provider in facilitating the sale of such
                  Services. Nutmeg will not be a party to any agreement between
                  Provider and the Customer and Provider is directly responsible
                  for any and all breaches or other liabilities occurring in
                  connection with the Provider Services.
                </p>
                <h4>3.2 Collection of Payment from Customers.</h4>
                <p>
                  Provider appoints Nutmeg as its agent to collect credit and
                  debit card payments on its behalf from Customers for purchases
                  of Provider Services made through or entered into the Nutmeg
                  Platform. Nutmeg will use third party payment processors for
                  all credit and debit card transactions as determined solely by
                  Nutmeg, and this Agreement will be subject to the requirements
                  of such processors and of applicable law.
                </p>
                <h4>3.3 Vouchers.</h4>
                <p>
                  Nutmeg will provide each purchasing Customer with a Voucher
                  and directions on how to use a Voucher to redeem the purchased
                  Provider Service. Provider agrees to fully and faithfully
                  accept and honor all Vouchers sold to Customers and to perform
                  all Provider Services in accordance with the terms and
                  conditions as stated in the Voucher at the time of purchase.
                  Provider will accept Vouchers presented on mobile devices
                  unless otherwise agreed upon in writing.
                </p>
                <h4>3.4 DATA</h4>
                <p>
                  Provider owns all individual Customer and sales information
                  collected by Provider in connection with the Provider Services
                  sold through Provider Online Channels or other sales of
                  Provider. Provider understands that Nutmeg collects the
                  foregoing information into a database through the Nutmeg
                  Platform. Provider hereby grants to Nutmeg and its successors
                  and assignees a non-exclusive, worldwide, fully paid,
                  assignable, transferable, sublicensable, and irrevocable
                  license (a) during the Term to access, reproduce and otherwise
                  use such information in connection with providing the Nutmeg
                  Platform and performing this Agreement, and (b) during and
                  following the Term to access, reproduce and otherwise use such
                  information on an anonymized and aggregated basis in
                  connection with Nutmeg’s business. For clarity, Nutmeg will
                  not sell or disclose identifiable information about Provider
                  or Provider’s Customers to a competitor of Provider.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>
                  4. FEES; COLLECTION AND REMITTANCE; CHARGEBACKS; REFUNDS
                </h3>
                <h4>4.1 Fees.</h4>
                <h5>4.1.1 Commission </h5>
                <p>
                  Nutmeg shall be paid a commission (“Commission”) on all
                  (excludes non-direct transactions i.e., Travel Agents, API
                  connected customers) of the Provider Services purchased
                  through the Nutmeg Booking Website. Commission is 3.0% of
                  total retail price purchased by Customer and confirmed by
                  Provider, or if a cancellation occurs, commission fee is also
                  applicable for cancellation fee amount.
                </p>
                <h5>4.1.2 Payment fee </h5>
                <p>
                  Provider shall pay credit card processing fee (“Payment fee”)
                  on all of the credit and debit card payment through the Nutmeg
                  Platform. Payment fee is solely determined by Stripe.
                </p>
                <h5>4.1.3 Fee payment.</h5>
                <p>
                  Nutmeg will withhold and retain the 3% commission Nutmeg Fees
                  for its own account from Customer Payments via Stripe.
                </p>
                <h4>4.2 Collection and Remittance. </h4>
                <p>
                  Nutmeg will typically fully receive payments collected by
                  Nutmeg from Customers when credit and debit card payment made
                  through Stripe, and withhold a total amount of payments until
                  Customer successfully participate on Provider service. After
                  the end of participated month, Nutmeg will remit to Provider,
                  the total amount of the payments collected by Nutmeg from
                  Customers for the Provider Services (“Customer Payments”)
                  minus all Commissions, Payment fees and other Nutmeg Fees and
                  amounts due to Nutmeg, as well as all Customer refunds,
                  Chargebacks and Fraudulent Charges. All such payments by
                  Nutmeg are collectively referred to as{' '}
                  <strong>“Provider Payments”</strong>. Unless otherwise
                  provided in the Service Addendum. Provider Payments will be
                  made within thirty (30) days after the end of participated
                  month. All Provider Payments will be converted into and made
                  in U.S. Dollars, unless otherwise agreed upon by the parties
                  in another agreement. Nutmeg is not responsible for the
                  collection or payment of any incidentals from Customers.
                  Nutmeg has the right to recoup any wire fees due to incorrect,
                  incomplete bank information provided by the Provider, and may
                  exercise the right to do so.
                </p>
                <h4>4.3. Taxes</h4>
                <p>
                  With respect to payments collected by Nutmeg for purchases of
                  Provider Services through the Nutmeg Platform, Nutmeg will
                  collect and withhold from Customers all applicable Transaction
                  Taxes and will remit such Transaction Taxes to Provider as
                  part of the Provider Payments.{' '}
                  <strong>“Transaction Taxes" </strong> means all applicable
                  sales, use taxes, value added taxes (VAT), and other
                  transactional taxes for purchases of Provider Services.
                  Provider is responsible for filing all returns and remitting
                  all taxes and other governmental charges in connection with
                  the sale or performance of the Provider Services (including
                  all Transaction Taxes, whether Nutmeg collected the Customer
                  payment or Provider collected the Customer payment directly)
                  and any payments accrued or received by Provider for the
                  Provider Services, and Provider agrees to fully indemnify,
                  hold harmless and defend Nutmeg and its Affiliates and
                  contractors from all liabilities related to taxes and other
                  governmental charges. Provider also agrees to fully comply
                  with all applicable laws and to timely file and remit all
                  taxes and other charges to the appropriate tax or other
                  authorities. Nutmeg further may be legally required to report
                  the Provider Payments and other payments made to Provider to
                  tax or other governmental authorities under applicable law.
                </p>
                <h4>4.4. Chargebacks and Fraudulent Charges.</h4>
                <h5>4.4.1. Handling of Chargebacks.</h5>
                <p>
                  In the event a Customer who purchased the Provider Services
                  initiates a Chargeback, Provider agrees that Nutmeg will
                  deduct the full amount of the Chargeback, as well as any
                  associated fees, fines, or penalties, from Customer Payments
                  for the next Provider Payment due to Provider. If Provider’s
                  next Provider Payment is insufficient, Provider agrees to pay
                  Nutmeg the full Chargeback balance upon invoicing as set forth
                  in Section 4.1. Nutmeg agrees to use reasonable efforts to
                  investigate and, if appropriate, challenge suspect
                  Chargebacks. Provider agrees to use reasonable efforts, at
                  Provider’s expense, to assist Nutmeg, at Nutmeg’s request, in
                  the investigation of any such suspect Chargebacks. If Nutmeg,
                  in its sole judgment, determines that a separate agreement
                  sufficiently protects it from a particular Chargeback, Nutmeg
                  may, in its sole discretion, waive Provider’s obligation to
                  compensate Nutmeg directly.
                </p>
                <h5>4.4.2. Excessive Chargebacks.</h5>
                <p>
                  If Nutmeg determines that Provider is incurring excessive
                  Chargebacks, Nutmeg may establish controls or conditions
                  governing Provider’s account, such as: (a) establishing new
                  processing fees, (b) delaying payouts, (c) refusing the award
                  of Chargebacks, or (d) terminating or suspending services.
                </p>
                <h5>4.4.3. Fraudulent Charges.</h5>
                <p>
                  In the event of a Fraudulent Charge, Provider agrees that
                  Nutmeg will deduct the full amount of the Fraudulent Charge,
                  as well as any associated fees, fines, or penalties, from
                  Customer Payments for the next Provider Payment due to
                  Provider. If Provider’s next Provider Payment is insufficient,
                  Provider agrees to pay Nutmeg the full balance upon invoicing
                  as set forth in Section 4.1.
                </p>
                <h4>4.5 Refunds. </h4>
                <h5>4.5.1 Refunds.</h5>
                <p>
                  Provider may provide refunds or adjustments to Customers for
                  their purchases of Provider Services made through the Nutmeg
                  Platform using refund functionality enabled by Nutmeg and
                  accessible through the Nutmeg Platform. Funds to cover refunds
                  will be deducted from Provider’s payout balance during each
                  remittance of Provider Payments. In the event of insufficient
                  funds to cover the amount of the refund, Provider’s credit
                  card on file may be charged. Nutmeg may retain from Provider
                  or Customers any Nutmeg Fees and applicable processing fees on
                  refunded transactions.
                </p>
                <h5>4.5.2 Cancellation and Fraudulent Charge.</h5>
                <p>
                  In the event of a Cancellation or Fraudulent Charge to a
                  Customer, (a) Nutmeg will not charge or retain any applicable
                  Commission on the applicable transaction, and (b) Nutmeg will
                  not be responsible for paying any amount to the Provider for
                  such transaction, except to the extent otherwise expressly
                  provided in a Service Addendum.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>5. PROPRIETARY RIGHTS</h3>
                <h4>5.1 Nutmeg Property and Rights. </h4>
                <p>
                  Provider acknowledges and agrees that Nutmeg and its
                  successors and assignees are and will remain the exclusive
                  owners in all jurisdictions of the Nutmeg Platform and Nutmeg
                  Content, including all patents, copyrights, trademarks, trade
                  secrets, moral rights and other intellectual property rights
                  and proprietary rights related thereto, excluding only the
                  Provider Content (together,{' '}
                  <strong>“Nutmeg Property and Rights”</strong>). All rights not
                  expressly granted to Provider under this Agreement are hereby
                  reserved by Nutmeg, and no license or other rights are being
                  granted by Nutmeg by implication, estoppel or otherwise.
                </p>
                <h4>5.2 Provider Content.</h4>
                <p>
                  Nutmeg acknowledges and agrees that, as between Nutmeg and
                  Provider, Provider is and will remain the exclusive owner in
                  all jurisdictions of the Provider Content, including all
                  patents, copyrights, trademarks, trade secrets, moral rights
                  and other intellectual property rights and proprietary rights
                  related thereto. Provider hereby grants to Nutmeg a worldwide,
                  nonexclusive, fully-paid up, royalty-free, transferable right
                  and license (with right to sublicense through multiple tiers)
                  during the Term to copy, reproduce, distribute, disclose,
                  publish, broadcast, communicate, display and/or perform
                  (whether publicly or otherwise), host, store, prepare
                  derivative works from, translate, modify or combine with other
                  data or information or content, and/or otherwise use any and
                  all of the Provider Content and to have others exercise such
                  rights and license on behalf of Nutmeg in connection with the
                  Provider Services.
                </p>
                <h4>5.3. Provider Feedback.</h4>
                <p>
                  Nutmeg welcomes any Feedback that Provider may provide Nutmeg
                  concerning improvements to the Nutmeg Platform (“Feedback”).
                  Feedback excludes Provider’s Confidential Information. By
                  providing Feedback to Nutmeg, Provider agrees that all
                  Feedback becomes Nutmeg’s exclusive property, and Provider
                  hereby assigns to Nutmeg all its rights to Feedback, including
                  all intellectual property rights in Feedback. At Nutmeg’s
                  request and expense, Provider agrees to execute documents and
                  take such further action as Nutmeg may reasonably request to
                  assist it in acquiring, perfecting, or maintaining
                  intellectual property rights in Feedback.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>6. RESTRICTED ACTIVITIES.</h3>
                <p>Provider agrees that it will not directly or indirectly:</p>
                <ul>
                  <li>
                    (a) infringe upon or misappropriate any Nutmeg Property and
                    Rights, or remove or alter any proprietary notices or
                    labels;
                  </li>
                  <li>
                    (b) take any act which undermines the integrity or
                    functionality of or interferes with, or transmits or uploads
                    any content or code which contains a virus, Trojan horse,
                    worm or other harmful component, or circumvents or disables
                    any security or data protection measures for any part of the
                    Nutmeg Platform;
                  </li>
                  <li>
                    (c) reproduce, modify, translate, enhance, decompile,
                    disassemble, reverse engineer, or create derivative works of
                    the Nutmeg Property and Rights;
                  </li>
                  <li>
                    (d) circumvent or disable any technological or security
                    features or measures in the Nutmeg Platform;
                  </li>
                  <li>
                    (e) rent, lease, or sublicense the Nutmeg Platform or
                    otherwise provide unauthorized access thereto;
                  </li>
                  <li>
                    (f) reproduce, distribute, transmit, publish, display,
                    perform, disclose, prepare derivative works from, translate,
                    modify or combine or use any of the Nutmeg Property and
                    Rights, excepting only uses expressly permitted by this
                    Agreement;
                  </li>
                  <li>
                    (g) upload or submit or transmit any Provider Content or
                    other material which (i) infringes upon or misappropriates
                    the intellectual property rights or other legal rights of
                    any third party, (ii) constitutes unlawful, obscene,
                    harassing or offensive content or activity or (iii) breaches
                    or violates any privacy or confidentiality or other legal
                    obligations; or
                  </li>
                  <li>
                    (h) frame, mirror or link to any of the Nutmeg Platform
                    without the express written consent of Nutmeg in its sole
                    discretion.
                  </li>
                </ul>
              </div>
              <div className={styles['terms-block']}>
                <h3>7. INDEMNIFICATION</h3>
                <h4>7.1 Indemnification by Nutmeg.</h4>
                <h5>7.1.1.Indemnification</h5>
                <p>
                  Nutmeg agrees to fully indemnify, defend and hold harmless
                  Provider and its officers, directors, employees,
                  representatives and agents (collectively,{' '}
                  <strong>“Provider Indemnified Parties”</strong>) from and
                  against any and all liabilities, losses, judgments, damages or
                  expenses, including but not limited to reasonable attorney’s
                  fees and expenses (collectively, <strong>“Losses”</strong>),
                  incurred by any Provider Indemnified Party arising out of any
                  third party claim, proceeding or suit (each,{' '}
                  <strong>“Claim”</strong>) that alleges that the use of the
                  Nutmeg Platform by the Provider Indemnified Parties infringes
                  or misappropriates any United States, patent, copyright,
                  trademark or other proprietary rights of such third party, if
                  (a) the applicable Provider Indemnified Party gives Nutmeg
                  prompt written notice of the Claim; (b) Nutmeg has full and
                  complete control over the defense and settlement of the Claim;
                  (c) the applicable Provider Indemnified Party provides
                  assistance in connection with the defense and settlement of
                  the Claim as Nutmeg may reasonably request; and (d) the
                  applicable Provider Indemnified Party complies with any
                  settlement or court order made in connection with the Claim
                  (e.g., relating to the future use of any infringing
                  materials).
                </p>
                <h5>7.1.2. Mitigation.</h5>
                <p>
                  If Provider’s or their respective agents’ use of the Nutmeg
                  Platform is, or in Nutmeg’s reasonable opinion is likely to
                  become, enjoined or materially diminished as a result of a
                  Claim under Section 7.1, then Nutmeg may either: (a) procure
                  the continuing right of Provider to use the Nutmeg Platform;
                  (b) replace or modify the Nutmeg Platform in a functionally
                  equivalent manner so that it no longer infringes; or if,
                  despite its commercially reasonable efforts, Nutmeg is unable
                  to do either (a) or (b), Nutmeg may (c) terminate Provider’s
                  rights to the Nutmeg Platform under this Agreement and provide
                  Provider with a prorated refund of prepaid fees, if
                  applicable, covering the remainder of the term of this
                  Agreement.
                </p>
                <h5>7.1.3. Exceptions.</h5>
                <p>
                  Nutmeg will have no obligation under this Section 7.1 for any
                  infringement or misappropriation to the extent that it arises
                  out of or is based upon (a) the combination, operation, or use
                  of the Nutmeg Platform with any software, services, tools,
                  hardware, equipment, supplies, accessories, or any other
                  materials or services not furnished by Nutmeg or recommended
                  in writing by Nutmeg if such infringement would have been
                  avoided but for such combination, operation, or use; (b)
                  designs, requirements, or specifications for the Nutmeg
                  Platform that Provider required or provided, if the alleged
                  infringement would not have occurred but for such designs,
                  requirements, or specifications; (c) use of the Nutmeg
                  Platform outside of the scope of the license granted to
                  Provider; (d) any modification of the Nutmeg Platform not made
                  or authorized in writing by Nutmeg where such infringement
                  would not have occurred absent such modification; or (e) the
                  Provider Content.
                </p>
                <h4>7.2. Indemnification by Provider. </h4>
                <p>
                  Provider agrees to fully indemnify, defend and hold harmless
                  Nutmeg and its officers, directors, employees,
                  representatives, agents, distribution partners and their
                  respective successors and assigns (collectively,{' '}
                  <strong>“Nutmeg Indemnified Parties”</strong>), from and
                  against any and all Losses incurred by any Nutmeg Indemnified
                  Party arising out of any Claim relating to or arising out of
                  (a) any of the Provider Services; (b) Provider’s breach of any
                  provision, warranty or representation in this Agreement; (c)
                  Provider’s intentional acts or omissions or any form of
                  negligence or other similar wrongdoing; (d) any infringement
                  or misappropriation of intellectual property or other rights
                  by any Provider Content; or (e) Provider’s failure to comply
                  with any applicable laws, if (i) the applicable Nutmeg
                  Indemnified Party gives Provider prompt written notice of the
                  Claim; (ii) Provider has full and complete control over the
                  defense and settlement of the Claim; provided, however, that
                  Provider shall not settle any Claim that admits liability or
                  places any obligation on a Nutmeg Indemnified Party; (iii) the
                  applicable Nutmeg Indemnified Party provides assistance in
                  connection with the defense and settlement of the Claim as
                  Provider may reasonably request; and (iv) the applicable
                  Nutmeg Indemnified Party complies with any settlement or court
                  order made in connection with the Claim (e.g., relating to the
                  future use of any infringing materials).
                </p>
                <h4>7.3. Disclaimer.</h4>
                <p>
                  Neither Nutmeg nor Provider will be liable for any form of
                  equitable or implied indemnification to the other with respect
                  to this Agreement, the subject matter of this Agreement or
                  otherwise.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>8. WARRANTIES AND DISCLAIMERS</h3>
                <h4>8.1. Mutual Warranties. </h4>
                <p>
                  Each party represents and warrants that it has power and
                  authority to enter this Agreement, the person or entity
                  executing this Agreement has the power and authority to bind
                  the party to this Agreement, and its entry into and
                  performance of this Agreement will not breach any contractual
                  obligations with third parties.
                </p>
                <h4>8.2. Disclaimer.</h4>
                <p>
                  NOTWITHSTANDING ANY CONTRARY PROVISION, AND TO THE FULLEST
                  EXTENT NOT PROHIBITED BY APPLICABLE LAW:
                </p>
                <h5>
                  8.2.1.THE NUTMEG PLATFORM AND ALL COMPONENTS THEREOF, AND ALL
                  ADVICE, INFORMATION AND MATERIALS, ARE BEING PROVIDED TO
                  PROVIDER AND ANY RELATED PERSON OR ENTITY{' '}
                  <strong>“AS IS" </strong> AND WITH ALL FAULTS. ANY AND ALL
                  REPRESENTATIONS AND WARRANTIES OF ANY KIND (WHETHER EXPRESS OR
                  IMPLIED OR STATUTORY) OF NUTMEG OR ANY AFFILIATE ARE HEREBY
                  EXCLUDED AND DISCLAIMED, INCLUDING BUT NOT LIMITED TO (a) ANY
                  AND ALL IMPLIED OR OTHER WARRANTIES OF TITLE OR
                  NON-INFRINGEMENT, (b) ANY AND ALL IMPLIED OR OTHER WARRANTIES
                  OF PROVIDERABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                  PARTICULAR PURPOSE, USEFULNESS OR SUITABILITY, AND (c) ANY
                  REPRESENTATION OR WARRANTY THAT THE NUTMEG PLATFORM OR ANY
                  PART THEREOF WILL BE SECURE, RELIABLE, TIMELY, UNINTERRUPTED,
                  ERROR-FREE OR VIRUS-FREE; OR THE RESULTS THAT MAY BE OBTAINED
                  FROM ANY OF THE FOREGOING.
                </h5>
                <h5>
                  8.2.2.ANY ACCESS TO OR USE OF THE NUTMEG PLATFORM SHALL BE AT
                  PROVIDER’S SOLE RISK AND PROVIDER IS RESPONSIBLE FOR
                  EVALUATING AND BEARING ALL RISKS ASSOCIATED WITH SUCH ACCESS
                  AND USE.
                </h5>
                <h4></h4>
              </div>
              <div className={styles['terms-block']}>
                <h3>9. LIMITATIONS OF LIABILITY.</h3>
                <p>
                  NOTWITHSTANDING ANY CONTRARY PROVISION OR ANY FAILURE OF THE
                  ESSENTIAL PURPOSE OF ANY REMEDY OF ANY KIND, TO THE FULLEST
                  EXTENT NOT PROHIBITED BY APPLICABLE LAW, NUTMEG WILL NOT BE
                  LIABLE TO PROVIDER OR ANY OTHER PERSON FOR ANY INDIRECT,
                  INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR FOR ANY LOSS
                  OF PROFIT, REVENUE, DATA, BUSINESS OR USE ARISING OUT OF OR
                  RELATED TO THIS AGREEMENT OR ANY OF THE SUBJECT MATTER OF THIS
                  AGREEMENT. IN NO EVENT WILL NUTMEG’S CUMULATIVE LIABILITY
                  ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY OF THE
                  SUBJECT MATTER OF THIS AGREEMENT, INCLUDING ANY RELATED
                  CLAIMS, ACTIONS OR CAUSES OF ACTION AND ANY CLAIMS FOR
                  INDEMNIFICATION, EXCEED IN THE AGGREGATE THE TOTAL AMOUNT OF
                  NUTMEG FEES PAID TO NUTMEG FOR THE PROVIDER SERVICES DURING
                  THE TWELVE (12) MONTHS PRIOR TO THE DATE OF THE EVENT FIRST
                  GIVING RISE TO THE RELEVANT CLAIM, ACTION OR CAUSE OF ACTION.
                  PROVIDER UNDERSTANDS AND AGREES THAT THE FOREGOING LIMITATIONS
                  ARE AN ESSENTIAL ELEMENT OF THIS AGREEMENT AND ARE REASONABLE
                  AND THAT IN THE ABSENCE OF SUCH LIMITATIONS THIS AGREEMENT
                  WOULD BE SUBSTANTIALLY DIFFERENT. THE FOREGOING LIMITATIONS
                  SHALL NOT BE INCREASED BY MULTIPLE CLAIMS, ACTIONS OR CAUSES
                  OF ACTION WHETHER SIMILAR OR NOT.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>10. INSURANCE</h3>
                <p>
                  Provider agrees to maintain in full force and effect adequate
                  liability insurance coverage in connection with the Provider
                  Services at all times during the Term and for a period of two
                  (2) years following the end of the Term. Provider agrees at
                  the request of Nutmeg to name Nutmeg and its Affiliates as
                  additional insureds on such insurance policies. This can
                  generally be done easily and without additional cost by
                  contacting the insurance broker or insurance company.
                  Provider’s compliance with this Section 10 does not limit its
                  indemnification or other obligations under this Agreement.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>11. TERM AND TERMINATION</h3>
                <h4>11.1. Term.</h4>
                <p>
                  The term of this Agreement shall be one (1) month from on the
                  Effective Date (“Initial Term”). Upon expiration of the
                  Initial Term the parties may renew this Agreement for
                  successive renewal periods (each, a{' '}
                  <strong>“Renewal Term”</strong>) automatically. The Initial
                  Term and each Renewal Term are referred to in this Agreement
                  as the <strong>“Term”</strong>.
                </p>
                <h4>11.2. Termination.</h4>
                <p>
                  Either party can terminate this Agreement for cause (including
                  any material breach of this Agreement by the other party) by
                  immediate written notice to the other party.
                </p>
                <h4>11.3. Effects of Termination.</h4>
                <p>
                  Upon termination of this Agreement for any reason: (a) each
                  license to a Nutmeg Platform offering granted by Nutmeg to
                  Provider will immediately terminate; (b) Provider will purge
                  all copies of all Nutmeg Content from any computer, server or
                  other hardware and each party will return the other party’s
                  Confidential Information; (c) each party’s respective rights
                  and obligations under Sections 3, 4, 5, 7, 8, 9, 10, 11.3, 12
                  and 13 of this Provider Agreement and relevant portions of
                  each Service Addendum, including the payment to Nutmeg of all
                  compensation obligations incurred prior to the effective date
                  of termination, each shall survive and remain in full force
                  and effect; (d) Provider shall continue to fulfill all unused
                  Vouchers, and all terms of this Agreement with respect to the
                  fulfillment of unused Vouchers (except all licenses granted by
                  Nutmeg) shall survive termination until their applicable
                  expiration; and (e) all claims or causes of action in
                  existence as of the date of termination shall survive.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>12. CONFIDENTIALITY</h3>
                <h4>12.1. Non-disclosure.</h4>
                <p>
                  The Receiving Party will take reasonable steps, at least
                  substantially equivalent to the steps it takes to protect its
                  own proprietary information, but not less than reasonable
                  care, to prevent the unauthorized duplication or use of the
                  Disclosing Party’s Confidential Information and the disclosure
                  of the Disclosing Party’s Confidential Information to third
                  parties without the Disclosing Party’s prior written consent.
                  The Receiving Party may disclose the Disclosing Party’s
                  Confidential Information to the Receiving Party’s Affiliates,
                  consultants, contractors, representatives and agents
                  (including financial advisors, accountants and attorneys)
                  (collectively, <strong>“Representatives”</strong>) who are
                  acting on behalf of the Receiving Party and are bound by, or
                  are otherwise protected by legal privilege or confidentiality
                  and non-disclosure commitments on terms no less protective of
                  the Confidential Information than the terms of this Agreement.
                  If a Receiving Party is legally compelled to disclose the
                  Disclosing Party’s Confidential Information, the Receiving
                  Party shall (a) provide prompt written notice (if legally
                  permissible) to the Disclosing Party so that the Disclosing
                  Party can seek a protective order or other appropriate remedy,
                  and (b) limit any such disclosure to the extent of the legal
                  requirement, and the disclosed information will remain
                  Confidential Information despite such disclosure.
                </p>
                <h4>12.2. Exceptions.</h4>
                <p>
                  Confidential Information excludes information which: (a) is or
                  becomes (through no act or omission of the Receiving Party in
                  breach of this Agreement), generally available to the public;
                  (b) becomes known to the Receiving Party or any of its
                  Affiliates on a non-confidential basis through a third party
                  who is not subject to an obligation of confidentiality with
                  respect to that information; (c) was lawfully in the
                  possession of the Receiving Party or any of its Affiliates
                  prior to such disclosure; (d) is independently developed by
                  the Receiving Party or any of its Affiliates; or (e) the
                  Disclosing Party agrees is not confidential or may be
                  disclosed, to the extent of that consent.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>13. GENERAL</h3>
                <h4>13.1. Provider Warranty.</h4>
                <p>
                  Without limiting Provider’s other obligations, Provider
                  represents and warrants that (a) it will provide the Provider
                  Services in a professional and safe manner at all times; (b)
                  it will not infringe upon or misappropriate the intellectual
                  property or other rights of any other person or entity with
                  respect to the subject matter of this Agreement; (c) Provider
                  has all necessary rights to grant the licenses granted by it
                  to Nutmeg, including the right to use the name, likeness, and
                  identifying information of any identifiable person in the
                  Provider Content; and (d) Provider possesses all licenses and
                  registrations necessary to conduct its business and to provide
                  all Provider Services to be offered herein.
                </p>
                <h4>13.2. Governing Law.</h4>
                <p>
                  This Agreement and all matters relating to or in connection
                  with this Agreement shall be governed by the laws of the State
                  of California, United States of America, without regard to any
                  conflict of laws principles that require the application of
                  the laws of a different jurisdiction.
                </p>
                <h4>13.3. Arbitration. </h4>
                <p>
                  {' '}
                  Notwithstanding any contrary provision hereof, all disputes,
                  claims, controversies and matters relating to or in connection
                  with this Agreement (or the breach thereof) or any
                  transactions hereunder shall be settled by binding arbitration
                  administered by the American Arbitration Association under its
                  Commercial Arbitration Rules (“AAA Rules”), and judgment on
                  the award rendered by the arbitrator(s) may be entered in any
                  court having jurisdiction thereof. The arbitration shall take
                  place in San Francisco, California, USA before a single
                  neutral arbitrator appointed in accordance with the AAA Rules
                  and shall be conducted in the English language. All
                  arbitrations shall be conducted and resolved on an individual
                  basis and not a class-wide, multiple plaintiff or similar
                  basis. No arbitration shall be consolidated with any other
                  arbitration proceeding involving any other person or entity.
                </p>
                <h4>13.4. Venue.</h4>
                <p>
                  Subject to Section 13.3, any other actions (if any) arising
                  out of or related to this Agreement must be brought
                  exclusively in the federal or state courts located in the
                  County of San Francisco, California USA, and each of the
                  parties agrees that such courts shall have exclusive
                  jurisdiction and venue for any such actions. Nutmeg also may
                  seek injunctive or other equitable relief for breach of this
                  Agreement in any court of competent jurisdiction wherever
                  located. The prevailing party in any suit, action or
                  proceeding, including any arbitration proceeding, will be
                  entitled to recover its reasonable attorney’s fees and costs
                  from the other party.
                </p>
                <h4>13.5. Assignment.</h4>
                <p>
                  Provider may not assign, transfer, delegate or sublicense this
                  Agreement or any rights or obligations hereunder, either
                  voluntarily or by operation of law or means of a merger,
                  consolidation or sale of stock or other ownership interest or
                  other change of control or ownership, and any such purported
                  act shall be null and void. Subject to the foregoing
                  restrictions this Agreement shall benefit and be binding upon
                  each of the parties and their respective successors and
                  permitted assignees. Other than in the case of indemnified
                  parties, there shall be no third party beneficiaries of this
                  Agreement.
                </p>
                <h4>13.6 Notices.</h4>
                <p>
                  All notices and other communications (collectively{' '}
                  <strong>“Notices”</strong>) required to be given or delivered
                  to a party shall be in writing and addressed to the party at
                  its principal corporate offices or as otherwise designated by
                  notice hereunder. All Notices shall be deemed to have been
                  given or delivered upon: (a) personal delivery; (b) three (3)
                  days after deposit in the United States mail by certified or
                  registered mail (return receipt requested); (c) two (2)
                  business days after deposit with an internationally recognized
                  overnight courier; or (d) one (1) business day after
                  transmission by electronic mail. All Notices and other
                  communications required or permitted under this Agreement
                  shall be made in the English language.
                </p>
                <h4>13.7. Miscellaneous.</h4>
                <p>
                  This Agreement (including all Product Schedules and the
                  Service Order) constitutes the entire understanding and
                  agreement with respect to its subject matter and supersedes
                  all prior and other current agreements and discussions between
                  the parties, provided that any confidential or proprietary
                  information disclosed previously to Provider shall be part of
                  Nutmeg’s Confidential Information. The waiver by either party
                  of any default or breach of this Agreement shall not
                  constitute a waiver of any other or subsequent default or
                  breach. In the event that any of the provisions of this
                  Agreement shall be held to be invalid or unenforceable by a
                  competent court or arbitrator, the remaining portions hereof
                  shall remain in full force and effect and such provision shall
                  be enforced to the maximum extent possible so as to effect the
                  intent of the parties and shall be reformed to the extent
                  necessary to make such provision valid and enforceable. When
                  the context requires, the plural shall include the singular
                  and the singular the plural, and any gender shall include any
                  other gender. No provision of this Agreement shall be
                  interpreted or construed against any party because such party
                  or its counsel was the drafter thereof. Notwithstanding any
                  contrary provision of this Agreement, whenever the words{' '}
                  <strong>“include," </strong> <strong>“includes" </strong> or{' '}
                  <strong>“including" </strong> or any other variation are used
                  in this Agreement, such words shall be deemed, unless the
                  context otherwise requires, to be followed by the words{' '}
                  <strong>“without limitation." </strong> Section headings are
                  only for convenience and are not part of this Agreement.
                </p>
                <h4>13.8. Counterparts. </h4>
                <p>
                  The Service Order may be executed in counterparts, each of
                  which will be deemed to be an original and together will
                  constitute one and the same agreement. The Service Order may
                  also be executed and delivered by electronic mail and such
                  execution and delivery will have the same force and effect of
                  an original document with original signatures.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles['p-newsArchive__body']}>
          <div className={styles['c-inner--middle']}>
            <div className={styles['terms']}>
              <h2 className={styles['c-headline']}>
                Nutmeg Paid Plan Addendum
              </h2>
              <div className={styles['terms-block']}>
                <p>
                  This document is a Service Addendum to the Provider Agreement
                  between Nutmeg and Provider. Any capitalized terms not
                  otherwise defined in this Service Addendum have the meaning
                  given in such Provider Agreement. The parties agree as
                  follows:
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>1. DEFINITIONS</h3>
                <ul>
                  <li>
                    <strong>1.1 "Additional Services" </strong>means, with
                    respect to this Service Addendum, specific available
                    features for Paid Plans and Paid Features by Nutmeg in
                    addition to Nutmeg Platform
                  </li>
                  <li>
                    <strong>1.2. "Channel Manager" </strong>means, with respect
                    to this Service Addendum, automated distribution solution
                    for Provider with Distribution Channels via Nutmeg APIs by
                    Nutmeg.
                  </li>
                  <li>
                    <strong>1.3. "Distribution Channels" </strong>means, with
                    respect to this Service Addendum, Travel Agents, Online
                    Travel Agents or other Resellers who have system
                    connectivity with Nutmeg.
                  </li>
                  <li>
                    <strong>1.4. "Marketing Automation" </strong>means, with
                    respect to this Service Addendum, advanced features of
                    automated email and its recommendations for Provider by
                    Nutmeg.
                  </li>
                  <li>
                    <strong>1.5. "Premium Plan" </strong>means, with respect to
                    this Service Addendum, one of Paid Plans for Provider
                    defined by Nutmeg.
                  </li>
                  <li>
                    <strong>1.6. "Private Marketplace" </strong>means, with
                    respect to this Service Addendum, advanced features of
                    Nutmeg Booking Website for Provider by Nutmeg.
                  </li>
                  <li>
                    <strong>1.7. "Paid Plans and Paid Features" </strong>means,
                    with respect to this Service Addendum, monthly subscription
                    services for Provider by Nutmeg .
                  </li>
                  <li>
                    <strong>1.8. "Special Support" </strong>means, with respect
                    to this Service Addendum, advanced and dedicated support for
                    Provider defined by Nutmeg.
                  </li>
                  <li>
                    <strong>1.9. "Standard Plan" </strong>means, with respect to
                    this Service Addendum, one of Paid Plans for Provider
                    defined by Nutmeg.
                  </li>
                </ul>
              </div>
              <div className={styles['terms-block']}>
                <h3>2. NUTMEG PAID PLANS AND FEATURES</h3>
                <h4>2.1. License</h4>
                <p>
                  Subject to Provider’s full compliance with this Agreement
                  (including this Service Addendum) at all times, Nutmeg grants
                  to Provider a limited non-exclusive, non-sublicensable,
                  non-transferable, non-assignable and revocable right and
                  license during the Term to access and use Nutmeg Additional
                  Services solely for its internal business operations and for
                  Nutmeg Platform. The use of the Nutmeg Additional Services for
                  any purpose not expressly permitted by this Agreement
                  (including this Service Addendum) is prohibited and will be
                  deemed to be a material breach of this Agreement (including
                  this Service Addendum).
                </p>
                <h4>2.2. Setup and Support.</h4>
                <p>
                  Nutmeg will provide assistance with setup and activation, as
                  well as support for the Nutmeg Paid Plans, according to
                  Nutmeg’s standard policies as made available or provided to
                  Provider.
                </p>
                <h4>2.3. Subscription Fees. </h4>
                <p>
                  The Subscription Fees are charged on a recurring basis (except
                  where may otherwise have been agreed with Provider).
                  Accordingly, where applicable, Nutmeg will attempt to
                  automatically renew the Paid Plans on a monthly basis (each
                  month being a <strong>“Billing Period”</strong>), and will
                  automatically charge Provider the applicable Subscription Fees
                  using the payment method Provider have on file within Provider
                  Account. If Provider wish to stop using Paid Plans, Provider
                  need to notify Nutmeg within Provider Account at least two (2)
                  US business days prior to the start of the next Billing
                  Period. Nutmeg will then remove Provider access to such Paid
                  Plans from the end of Provider’s last Billing Period.
                </p>
                <h4>2.4. Modifications.</h4>
                <p>
                  Nutmeg may modify or update the Nutmeg Paid Plans and its
                  Additional Services at any time without prior notice.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>3. STANDARD PLAN</h3>
                <h4>3.1. Additional Services. </h4>
                <p>
                  Provider who subscribes Standard Plan can use the Additional
                  Services offering that consists of (a) Private Marketplace,
                  (b) Marketing Automation during the Billing Period.
                </p>
                <h4>3.2. Fees</h4>
                <p>
                  Nutmeg will charge Provider with one hundred (100) in U.S.
                  Dollars subscription fee with applicable taxes
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>4. PREMIUM PLAN</h3>
                <h4>4.1. Additional Services. </h4>
                <p>
                  Provider who subscribes Premium Plan can use the Additional
                  Services offering that consists of (a) Private Marketplace,
                  (b) Marketing Automation, (c) Channel Manager with up to
                  hundred (100) bookings in a month, (d) Special Support during
                  the Billing Period
                </p>
                <h4>4.2. Fees</h4>
                <p>
                  Nutmeg will charge Provider with three hundred (300) in U.S.
                  Dollars subscription fee with applicable taxes.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <h3>5. ADD ON FEATURES</h3>
                <h4>5.1. Additional Services. </h4>
                <p>
                  Provider who subscribes Add on Features can use the Additional
                  Services offering that consists of Review Feature during the
                  Billing Period
                </p>
                <h4>5.2. Fees</h4>
                <p>
                  Nutmeg will charge Provider with fifty hundred (50) in U.S.
                  Dollars subscription fee with applicable taxes.
                </p>
              </div>
              <div className={styles['terms-block']}>
                <p>[Updates]</p>
                <p>5/28/2023: Revision 1.1</p>
                <p>7/7/2021: Revision 1.0</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
