import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import {
  createGroupBookingTemplate,
  deleteGroupBookingTemplate,
  fetchGroupBookingTemplates,
} from 'client/actions/groupBookingTemplates';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { ReduxState } from 'client/reducers';
import { GroupBookingTemplate } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import linkIcon from 'client/images/ic_link.svg';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { Tabs } from 'client/components/Tabs/Tabs';

import styles from './GroupBookingTemplateList.module.css';

const EditCopyDeleteCell = ({
  groupBookingTemplate,
}: {
  groupBookingTemplate: GroupBookingTemplate;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.loading
  );
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <Box display="flex">
      <Link to={`/groups/${groupBookingTemplate.id}/edit`}>
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createGroupBookingTemplate({
              name: `${groupBookingTemplate.name} [COPY]`,
              status: 'OFF',
              products: groupBookingTemplate.products,
              start_date_local_from: groupBookingTemplate.start_date_local_from,
              start_date_local_to: groupBookingTemplate.start_date_local_to,
            })
          );

          const lastCreatedGroupBookingTemplate =
            store.getState().groupBookingTemplates
              .lastCreatedGroupBookingTemplate;
          if (lastCreatedGroupBookingTemplate) {
            history.push(`/groups/${lastCreatedGroupBookingTemplate.id}/edit`);
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Group')}
            content={t('Are you sure you want to delete group?')}
            onConfirm={async () => {
              await dispatch(
                deleteGroupBookingTemplate(groupBookingTemplate.id as string)
              );
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
      <Box ml={1}>
        <a
          target="_blank"
          href={`${getBookingWidgetApiKeyUrl(
            activeUserOrganization?.booking_widget_api_key ?? ''
          )}/groups/${groupBookingTemplate.id}`}
          rel="noreferrer"
        >
          <img className={styles['link-icon']} src={linkIcon} width="25px" />
        </a>
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<GroupBookingTemplate>[] => {
  const { t } = useTranslation();
  const allProducts = useSelector(summariesSortedByBookmarkedSelector);

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (template) => (
        <EditCopyDeleteCell groupBookingTemplate={template} />
      ),
      width: 150,
    },
    {
      Header: t('Name'),
      id: 'name',
      accessor: (template) => <div>{template.name}</div>,
    },
    {
      Header: t('Reservations'),
      id: 'reservationList',
      accessor: (template) => (
        <Link to={`/groups/${template.id}/reservations`}>{t('List')}</Link>
      ),
    },
    {
      Header: t('Status'),
      id: 'description',
      accessor: (item) => {
        switch (item.status) {
          case 'ON':
            return t('On');
          case 'OFF':
            return t('Off');
          default:
            return '';
        }
      },
    },
    {
      Header: t('Dates'),
      id: 'actions',
      accessor: (item) =>
        `${item.start_date_local_from} ~ ${item.start_date_local_to}`,
    },
    {
      Header: t('Products'),
      id: 'products',
      accessor: (item) => {
        return (
          <table>
            <tbody>
              {item.products?.map((groupProduct) => {
                const product = allProducts.find(
                  (product) => product.id === groupProduct.product_id
                );
                if (!product) {
                  return null;
                }

                return (
                  <tr key={groupProduct.product_id}>
                    <td style={{ border: 'none' }}>{product.product_name}</td>
                    <td style={{ border: 'none' }} width="60px">
                      {groupProduct.current_occupancy || 0}/{groupProduct.limit}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      },
    },
  ];
};

const isPastDate = (date: string): boolean => {
  if (!date) {
    return false;
  }

  return date < moment().format('YYYY-MM-DD');
};

export const GroupBookingTemplateList = () => {
  const dispatch = useDispatch();
  const columns = useColumns();
  const { t } = useTranslation();

  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    dispatch(fetchGroupBookingTemplates());
    dispatch(fetchProducts());
  }, [activeUserOrganization?.id]);

  const onGroupBookingTemplates = groupBookingTemplates.filter(
    (template) =>
      template.status === 'ON' &&
      !isPastDate(template.start_date_local_to ?? '')
  );
  const offGroupBookingTemplates = groupBookingTemplates.filter(
    (template) =>
      template.status === 'OFF' &&
      !isPastDate(template.start_date_local_to ?? '')
  );
  const discontinuedGroupBookingTemplates = groupBookingTemplates.filter(
    (template) => isPastDate(template.start_date_local_to ?? '')
  );

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to="/groups/new">
            <Button style="green" size="middle">
              {t('Create New Group')}
            </Button>
          </Link>
        </Box>
      </Box>
      <Tabs
        panes={[
          {
            header: t('On'),
            content: () => (
              <PagedGenericTable
                allItems={onGroupBookingTemplates}
                columns={columns}
              />
            ),
          },

          {
            header: t('Off'),
            content: () => (
              <PagedGenericTable
                allItems={offGroupBookingTemplates}
                columns={columns}
              />
            ),
          },

          {
            header: t('Discontinued'),
            content: () => (
              <PagedGenericTable
                allItems={discontinuedGroupBookingTemplates}
                columns={columns}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
