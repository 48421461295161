// @flow

import * as React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import type { Dispatch as ReduxDispatch } from 'redux';
import type { TranslatorProps } from 'react-i18next';

import { setProductResponsibleAgent } from 'client/actions/products';
import type { ReduxState } from 'client/reducers';
import {
  activeUserAllOrganizationsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import type { Product } from 'shared/models/swagger';

type OwnProps = {
  trigger: React.ComponentType<{}>,
  product: Product,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  responsibleAgentID: string,
  showModal: boolean,
};

class UpdateResponsibleAgentModalComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      responsibleAgentID: props.product.responsible_agent_id || '',
      showModal: false,
    };
  }

  render() {
    const {
      activeUser,
      organizations,
      product,
      t,
      trigger: Trigger,
    } = this.props;

    const { responsibleAgentID, showModal } = this.state;

    const orgOptions = organizations
      .filter((org) => org.id !== activeUser.organization_id)
      .map((org) => ({ key: org.id, text: org.name, value: org.id }));

    return (
      <Modal
        eventPool="update-responsible-agent"
        trigger={
          <span
            onClick={() =>
              this.setState({
                showModal: true,
              })
            }
          >
            <Trigger />
          </span>
        }
        open={showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        style={{
          marginTop: 0,
        }}
      >
        <Modal.Header>{t('Update Responsible Agent')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Select
                fluid
                search
                label={t('Responsible Agent')}
                value={responsibleAgentID}
                options={orgOptions}
                onChange={(e, { value }) =>
                  this.setState({
                    responsibleAgentID: value,
                  })
                }
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              this.setState(() => {
                this.props.setProductResponsibleAgent(
                  product.id,
                  responsibleAgentID
                );

                return {
                  showModal: false,
                };
              });
            }}
            disabled={
              (product.responsible_agent_id || '') === responsibleAgentID
            }
          >
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    activeUser: activeUserSelector(state),
    organizations: activeUserAllOrganizationsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  setProductResponsibleAgent: (productID: string, responsibleAgentID: string) =>
    dispatch(setProductResponsibleAgent(productID, responsibleAgentID)),
});

export const UpdateResponsibleAgentModal = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(UpdateResponsibleAgentModalComponent);
