// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';

import { Loading } from 'client/pages/Loading';
import { Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import {
  getInitialValues,
  convertFormValuesToOrganizationPatch,
} from '../FormValues';

import { ContentItemsEditor } from './ContentItemsEditor';
import { ColorsEditor } from './ColorsEditor';
import { SectionOrderEditor } from './SectionOrderEditor';

export const BasicTab = () => {
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const scrollTo = React.useContext(ScrollToContext);

  const initialValues = React.useMemo(
    () => getInitialValues(activeUserOrganization),
    [activeUserOrganization]
  );

  const dispatch = useDispatch();
  return (
    <div className={baseStyles['base-main__body__box']}>
      {loading && <Loading />}
      <div className={baseStyles['base-main__body__box__body']}>
        {saveSucceeded && <Message success header={t('Settings Saved')} />}
        <Form
          onSubmit={async (values) => {
            setSaveSucceeded(false);

            scrollTo(0, 0);

            await dispatch(
              updateOrganization(
                activeUserOrganization?.id ?? '',
                'SUPPLIER',
                convertFormValuesToOrganizationPatch(
                  values,
                  activeUserOrganization
                )
              )
            );

            setSaveSucceeded(true);
          }}
          keepDirtyOnReinitialize
          initialValues={initialValues}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormTableBox>
                <table>
                  <tr>
                    <th>{t('Colors')}</th>
                    <td>
                      <ColorsEditor />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Email Contents')}</th>
                    <td>
                      <ContentItemsEditor />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Section Display Order')}</th>
                    <td>
                      <SectionOrderEditor />
                    </td>
                  </tr>
                </table>
              </FormTableBox>
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button type="submit" style="blue" size="small">
                  {t('Save Settings')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
