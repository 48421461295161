import { TFunction } from 'react-i18next';

export const getDrawTriggerTypeOptions = (t: TFunction) => {
  return [
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED',
      text: t('Reservation Created'),
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_SURVEY_ANSWERED',
      text: t('Survey Completed'),
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_REVIEW_POSTED',
      text: t('Review Posted'),
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_STAMP_RALLY_COMPLETED',
      text: t('Stamp Rally Completed'),
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_CUSTOMER_REGISTERED',
      text: t('Customer Registered'),
    },
  ];
};
