import { useTranslation } from 'react-i18next';

export const useReservationSearchConditionDateType = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'PARTICIPATION_DATE',
      text: t('Participation Date'),
    },
    {
      value: 'BOOKED_DATE',
      text: t('Booked Date'),
    },
    {
      value: 'LAST_UPDATED_DATE',
      text: t('Last Updated Date'),
    },
  ];
};
