import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Column } from 'client/pages/v3/Manifest/util';

type Props = {
  itemName: string;
  items: Record<string, any>[];
  columns: Column[];
  startTime?: string;
  totalPax?: number;
};

export const ManifestCustomizeCustomTable = ({
  itemName,
  items,
  columns,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {items.length === 0 && (
        <div>{t('{{item}} is not registered.', { item: itemName })}</div>
      )}
      {items.length !== 0 && (
        <>
          <table
            className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          >
            <thead>
              <tr>
                {columns.map((column, idx) => {
                  const width = column.width;

                  return (
                    <th key={idx} style={{ width: width }}>
                      {column.Header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items.map((item, ridx) => {
                return (
                  <tr key={ridx}>
                    {columns.map((column, idx) => {
                      let value: any;
                      let displayValue: any;

                      if (typeof column.accessor === 'string') {
                        value = item[column.accessor];
                      } else if (typeof column.accessor === 'function') {
                        value = column.accessor(item);
                      }

                      if (column.Cell) {
                        const cell = {
                          value: value,
                          original: item,
                        };
                        displayValue = column.Cell(cell);
                      } else {
                        displayValue = value;
                      }

                      const width = column.width;

                      // If no action is available, render empty <td>
                      if (column.Header === 'Action' && displayValue === null) {
                        return <td key={idx}></td>;
                      }

                      return (
                        <td
                          key={idx}
                          className={clsx(
                            idx === 0 && tableStyles['c-tableSmall__spTtl'],
                            // Set only when column is action
                            idx === columns.length - 1 &&
                              column.Header === 'Action' &&
                              tableStyles['c-tableSmall__spTopBorder']
                          )}
                          data-text={idx === 0 ? '' : column.Header}
                          // Use word-break property to force content to follow column width
                          style={{
                            width: width,
                            wordBreak: 'break-all',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {displayValue}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
