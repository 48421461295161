import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper, Input, TextArea, Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { cancelReservationWithFee } from 'client/actions/reservations';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Divider } from 'client/components/Divider/Divider';
import { currency } from 'shared/libraries/currency';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  trigger: React.ReactComponentElement<'a' | 'button'>;
  reservation: Reservation;
  warning: string;
};

type Charge =
  | {
      type: 'fixed';
      amount: number;
    }
  | {
      type: 'percent';
      amount: number;
    };

export const CancelWithFeeModalV2 = ({
  trigger,
  reservation,
  warning,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [charge, setCharge] = React.useState<Charge>({
    type: 'percent',
    amount: 0,
  });
  const [supplierNotes, setSupplierNotes] = React.useState<string>(
    reservation.supplier_notes ?? ''
  );

  React.useEffect(() => {
    setSupplierNotes(reservation.supplier_notes ?? '');
  }, [reservation]);

  const grossTotal =
    reservation.billing_info && reservation.billing_info.amount_gross;

  return (
    <Modal
      trigger={trigger}
      title={t('Cancel')}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <Message warning header={t('Warning')} content={warning} />
        </Modal.Box>

        <Modal.Box>
          <FieldWrapper label={t('Cancellation fee type')}>
            <div className={clsx(baseStyles['base-form-checkbox'])}>
              <label>
                <input
                  type="checkbox"
                  name={'radioGroup-cancellation-modal'}
                  value="fixed"
                  checked={charge.type === 'fixed'}
                  onChange={() =>
                    setCharge({
                      type: 'fixed',
                      amount: 0.0,
                    })
                  }
                />
                <p></p>
                {t('Fixed (ex. {{grossTotal}})', {
                  grossTotal: currency(grossTotal ?? '').format(),
                })}
              </label>
            </div>
            <div className={clsx(baseStyles['base-form-checkbox'])}>
              <label>
                <input
                  type="checkbox"
                  name={'radioGroup-cancellation-modal'}
                  value="percent"
                  checked={charge.type === 'percent'}
                  onChange={() =>
                    setCharge({
                      type: 'percent',
                      amount: 0,
                    })
                  }
                />
                <p></p>
                {t('Percent (0-100 floating point)')}
              </label>
            </div>
          </FieldWrapper>
        </Modal.Box>

        {charge.type === 'fixed' ? (
          <Modal.Box>
            <Input
              label={t('Cancellation fee amount')}
              value={String(charge.amount)}
              onChange={(e, { value: amount }) => {
                setCharge({
                  type: 'fixed',
                  amount: parseFloat(amount),
                });
              }}
              type="number"
              step="0.01"
            />
          </Modal.Box>
        ) : (
          <Modal.Box>
            <FieldWrapper label={t('Cancellation fee percent')}>
              <label className={baseStyles['base-form-select']}>
                <select
                  value={charge.amount}
                  onChange={(e) =>
                    setCharge({
                      type: 'percent',
                      amount: parseInt(e.target.value, 10),
                    })
                  }
                >
                  {[...Array(101)].map((_, idx) => {
                    return (
                      <option key={idx} value={idx}>
                        {idx}
                      </option>
                    );
                  })}
                </select>
              </label>
            </FieldWrapper>
          </Modal.Box>
        )}

        {charge.type === 'percent' && grossTotal && (
          <Message>
            {t('Cancellation fee: {{grossTotal}} x {{percent}}% = {{fee}}', {
              grossTotal,
              percent: charge.amount,
              fee: currency(grossTotal)
                .multiply(charge.amount / 100)
                .format(),
            })}
          </Message>
        )}

        <Modal.Box>
          <Message warning>
            {t(
              'A cancellation fee of {{currencyAmount}} will be charged to the customer. Are you sure you want to cancel?',
              {
                currencyAmount:
                  charge.type === 'fixed'
                    ? currency(grossTotal ?? '')
                        .multiply(0)
                        .add(charge.amount)
                        .format(true)
                    : grossTotal
                    ? currency(grossTotal)
                        .multiply(charge.amount / 100)
                        .format()
                    : `${charge.amount}%`,
              }
            )}
          </Message>
        </Modal.Box>

        <Divider />

        <ReservationActorInputForm />

        <TextArea
          label={t('Replies')}
          value={supplierNotes}
          onChange={(e, { value }) => {
            setSupplierNotes(value);
          }}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('No')}
        </Button.Cancel>
        <Button.Delete
          onClick={() => {
            if (charge.type === 'fixed') {
              dispatch(
                cancelReservationWithFee(
                  reservation.id,
                  currency(grossTotal ?? '')
                    .multiply(0)
                    .add(charge.amount)
                    .format(true),
                  undefined,
                  supplierNotes
                )
              );
            } else {
              dispatch(
                cancelReservationWithFee(
                  reservation.id,
                  undefined,
                  charge.amount,
                  supplierNotes
                )
              );
            }
            setShowModal(false);
          }}
        >
          {t('Yes')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  );
};
