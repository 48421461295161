import {
  SET_ACTIM_RESERVATION_VISIBLE_COLUMNS,
  SET_ACTIM_RESERVATION_ROW_COUNT,
  SET_ACTIM_RESERVATION_CURRENT_PAGE,
} from 'client/constants/ActionTypes';

export const setActimReservationVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_ACTIM_RESERVATION_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setActimReservationRowCount = (rowCount: number) => ({
  type: SET_ACTIM_RESERVATION_ROW_COUNT,
  rowCount,
});
export const setActimReservationCurrentPage = (currentPage: number) => ({
  type: SET_ACTIM_RESERVATION_CURRENT_PAGE,
  currentPage,
});
