import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';

import { Button, TextArea, Input, ToggleButton } from 'client/components/Form';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { getArrayMutators } from 'client/libraries/util/form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { Loading } from 'client/pages/Loading';
import { updateCovid19Settings } from 'client/actions/essentialPages';
import { Message } from 'client/components/Message/Message';
import type { ReduxState } from 'client/reducers';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import * as Swagger from 'shared/models/swagger';
import type { SourceLanguage, Covid19Settings } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './Covid19Editor.module.css';

export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

const defaultPrecautions = [
  'Guides & drivers wear masks',
  'Staff required to wash hands regularly',
  'Staff required to check temperature regularly',
  'Regularly sanitize high-traffic area',
  'Regularly sanitize equipments, vehicles',
  'Hand sanitizer available to travelers and staff',
  'Reduced group sizes',
  'Keep social distance throughout the activity',
];
const defaultToGuests = [
  'Guests are required to check temperature upon arrival',
  'Guests are required to bring and wear masks',
  'Hand sanitizer available to travelers',
];
export const Covid19Editor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  React.useEffect(() => {
    if (showTranslationMode) {
      const covid19Settings =
        activeUserOrganization?.essential_pages?.covid19_settings;

      if (covid19Settings) {
        const texts = [];

        if (covid19Settings.introduction) {
          texts.push(covid19Settings.introduction);
        }

        (covid19Settings.custom_precautions || []).forEach(
          (customPrecaution) => {
            texts.push(customPrecaution);
          }
        );
        (covid19Settings.custom_to_guests || []).forEach((customToGuest) => {
          texts.push(customToGuest);
        });

        if (covid19Settings.other_message) {
          texts.push(covid19Settings.other_message);
        }

        dispatch(batchGetTranslations(texts));
      }
    }
  }, [showTranslationMode, activeUserOrganization]);
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      ...activeUserOrganization?.essential_pages?.covid19_settings,
    }),
    [apiTranslations, translationTargetLanguage, activeUserOrganization]
  );

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const changeShowTranslationMode = (show: boolean) => {
    setShowTranslationMode(show);
  };

  const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
    setTranslationTargetLanguage(lang);
  };

  return (
    <div className={baseStyles['base-main__body']}>
      {false && (
        <>
          {/* Need to this part to register the terms to translation catalog file */}
          {t('Guides & drivers wear masks')}
          {t('Staff required to wash hands regularly')}
          {t('Staff required to check temperature regularly')}
          {t('Regularly sanitize high-traffic area')}
          {t('Regularly sanitize equipments, vehicles')}
          {t('Hand sanitizer available to travelers and staff')}
          {t('Reduced group sizes')}
          {t('Keep social distance throughout the activity')}
          {t('Guests are required to check temperature upon arrival')}
          {t('Guests are required to bring and wear masks')}
          {t('Hand sanitizer available to travelers')}
        </>
      )}

      <SettingTranslationLanguageSelector
        showTranslationMode={showTranslationMode}
        onShowTranslationModeChange={changeShowTranslationMode}
        translationTargetLanguage={translationTargetLanguage}
        onTranslationTargetLanguageChange={changeTranslationTargetLanguage}
      />

      <Form
        onSubmit={async (values: Covid19Settings & TranslationsFormValues) => {
          const newCovid19Settings = {
            visibility: values.visibility,
            introduction: values.introduction,
            precautions: defaultPrecautions.filter((precaution) =>
              (values.precautions || []).includes(precaution)
            ),
            custom_precautions: values.custom_precautions,
            to_guests: defaultToGuests.filter((toGuest) =>
              (values.to_guests || []).includes(toGuest)
            ),
            custom_to_guests: values.custom_to_guests,
            other_message: values.other_message,
          };

          try {
            if (showTranslationMode) {
              await Promise.all([
                dispatch(updateCovid19Settings(newCovid19Settings)),
                dispatch(
                  updateTranslations(
                    values.translations.map((translation) => ({
                      source_language: defaultLanguage,
                      [defaultLanguage.toLowerCase()]: translation.source,
                      [translationTargetLanguage.toLowerCase()]:
                        translation.target,
                    }))
                  )
                ),
              ]);
            } else {
              await dispatch(updateCovid19Settings(newCovid19Settings));
            }

            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={initialValuesWithTranslations}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting, values, form }) => {
          React.useEffect(() => {
            let translations: { source: string; target: string }[] = [];

            if (defaultLanguage && translationTargetLanguage) {
              const defaultFieldName = defaultLanguage.toLowerCase();
              const translationFieldName =
                translationTargetLanguage.toLowerCase();
              translations = apiTranslations.map((trans: any) => ({
                source: trans[defaultFieldName],
                target: trans[translationFieldName],
              }));
            }

            form.change('translations', translations);
          }, [
            defaultLanguage,
            apiTranslations,
            translationTargetLanguage,
            showTranslationMode,
          ]);
          return (
            <form onSubmit={handleSubmit}>
              {false && <pre>{JSON.stringify(values, undefined, 2)}</pre>}
              <div className={baseStyles['base-main__body__box']}>
                <div className={baseStyles['base-main__body__box__body']}>
                  <div className={styles['acBody']}>
                    <div className={styles['c-table-list']}>
                      <table>
                        <tbody>
                          <tr>
                            <th>{t('Visibility')}</th>
                            <td>
                              <Field name="visibility" type="checkbox">
                                {({ input }) => (
                                  <div className={styles['select']}>
                                    <ToggleButton
                                      label={t(
                                        'Show COVID-19 Safety Measures page on booking website'
                                      )}
                                      {...input}
                                    />
                                  </div>
                                )}
                              </Field>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Introduction')}</th>
                            <td>
                              <div
                                className={
                                  styles['scheds__body__content__body']
                                }
                              >
                                <TranslatedField name="introduction">
                                  {({
                                    input,
                                    translationInput,
                                    meta: { error, touched },
                                  }) => (
                                    <>
                                      <div
                                        className={styles['scheds__body__form']}
                                      >
                                        <p className={styles['scheds__body']}>
                                          {' '}
                                          <TextArea
                                            {...(input as any)}
                                            height={100}
                                            error={touched && error}
                                          />{' '}
                                        </p>
                                      </div>
                                      {showTranslationMode && (
                                        <div
                                          className={
                                            styles['scheds__body__form']
                                          }
                                        >
                                          <p className={styles['scheds__body']}>
                                            {' '}
                                            <TextArea
                                              {...(translationInput as any)}
                                              height={100}
                                              placeholder={getLanguageName(
                                                uppercaseIsoToLowercaseIso[
                                                  translationTargetLanguage
                                                ],
                                                t
                                              )}
                                              error={touched && error}
                                            />{' '}
                                          </p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </TranslatedField>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Precautions')}</th>
                            <td>
                              {defaultPrecautions.map((precaution, idx) => {
                                return (
                                  <Field
                                    key={idx}
                                    name="precautions"
                                    type="checkbox"
                                    value={t(precaution, {
                                      lng: defaultLanguage,
                                    })}
                                  >
                                    {({ input }) => (
                                      <div
                                        key={idx}
                                        className={styles['select']}
                                      >
                                        <ToggleButton
                                          label={t(precaution)}
                                          {...input}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                );
                              })}
                              <FieldArray name="custom_precautions">
                                {({ fields }) => (
                                  <>
                                    {fields.length === 0 && (
                                      <AddIcon
                                        onClick={() =>
                                          (fields as any).insertAt(0, '')
                                        }
                                      />
                                    )}
                                    <div className={styles['c-table-list']}>
                                      <table>
                                        <tbody>
                                          {fields.map((name, idx) => (
                                            <TranslatedField
                                              key={name}
                                              name={name}
                                            >
                                              {({
                                                input,
                                                translationInput,
                                              }) => (
                                                <tr>
                                                  <td>
                                                    <div
                                                      className={
                                                        baseStyles['base-flex']
                                                      }
                                                    >
                                                      <Input
                                                        {...input}
                                                        maxWidth={800}
                                                      />
                                                      <div
                                                        className={
                                                          baseStyles[
                                                            'base-flex'
                                                          ]
                                                        }
                                                      >
                                                        <AddIcon
                                                          onClick={() =>
                                                            (
                                                              fields as any
                                                            ).insertAt(
                                                              idx + 1,
                                                              ''
                                                            )
                                                          }
                                                        />
                                                        <DeleteIcon
                                                          onClick={() =>
                                                            fields.remove(idx)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  {showTranslationMode && (
                                                    <td>
                                                      <Input
                                                        {...translationInput}
                                                        maxWidth={800}
                                                        placeholder={getLanguageName(
                                                          uppercaseIsoToLowercaseIso[
                                                            translationTargetLanguage
                                                          ],
                                                          t
                                                        )}
                                                      />
                                                    </td>
                                                  )}
                                                </tr>
                                              )}
                                            </TranslatedField>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('To Guests')}</th>
                            <td>
                              {defaultToGuests.map((toGuest, idx) => {
                                return (
                                  <Field
                                    key={idx}
                                    name="to_guests"
                                    type="checkbox"
                                    value={t(toGuest, {
                                      lng: defaultLanguage,
                                    })}
                                  >
                                    {({ input }) => (
                                      <div
                                        key={idx}
                                        className={styles['select']}
                                      >
                                        <ToggleButton
                                          label={t(toGuest)}
                                          {...input}
                                        />
                                      </div>
                                    )}
                                  </Field>
                                );
                              })}

                              <FieldArray name="custom_to_guests">
                                {({ fields }) => (
                                  <>
                                    {fields.length === 0 && (
                                      <AddIcon
                                        onClick={() =>
                                          (fields as any).insertAt(0, '')
                                        }
                                      />
                                    )}
                                    <div className={styles['c-table-list']}>
                                      <table>
                                        <tbody>
                                          {fields.map((name, idx) => (
                                            <TranslatedField
                                              key={idx}
                                              name={name}
                                            >
                                              {({
                                                input,
                                                translationInput,
                                              }) => (
                                                <tr>
                                                  <td>
                                                    <div
                                                      className={
                                                        baseStyles['base-flex']
                                                      }
                                                    >
                                                      <Input
                                                        {...input}
                                                        maxWidth={800}
                                                      />
                                                      <div
                                                        className={
                                                          baseStyles[
                                                            'base-flex'
                                                          ]
                                                        }
                                                      >
                                                        <AddIcon
                                                          onClick={() =>
                                                            (
                                                              fields as any
                                                            ).insertAt(
                                                              idx + 1,
                                                              ''
                                                            )
                                                          }
                                                        />
                                                        <DeleteIcon
                                                          onClick={() =>
                                                            fields.remove(idx)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  {showTranslationMode &&
                                                    translationTargetLanguage && (
                                                      <td>
                                                        <Input
                                                          {...translationInput}
                                                          maxWidth={800}
                                                          placeholder={getLanguageName(
                                                            uppercaseIsoToLowercaseIso[
                                                              translationTargetLanguage
                                                            ],
                                                            t
                                                          )}
                                                        />
                                                      </td>
                                                    )}
                                                </tr>
                                              )}
                                            </TranslatedField>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </td>
                          </tr>

                          <tr>
                            <th>{t('Other Message')}</th>
                            <td>
                              <div
                                className={
                                  styles['scheds__body__content__body']
                                }
                              >
                                <TranslatedField name="other_message">
                                  {({
                                    input,
                                    translationInput,
                                    meta: { error, touched },
                                  }) => (
                                    <>
                                      <div
                                        className={styles['scheds__body__form']}
                                      >
                                        <p className={styles['scheds__body']}>
                                          {' '}
                                          <TextArea
                                            {...(input as any)}
                                            height={100}
                                            error={touched && error}
                                          />{' '}
                                        </p>
                                      </div>
                                      {showTranslationMode && (
                                        <div
                                          className={
                                            styles['scheds__body__form']
                                          }
                                        >
                                          <p className={styles['scheds__body']}>
                                            {' '}
                                            <TextArea
                                              {...(translationInput as any)}
                                              height={100}
                                              placeholder={getLanguageName(
                                                uppercaseIsoToLowercaseIso[
                                                  translationTargetLanguage
                                                ],
                                                t
                                              )}
                                              error={touched && error}
                                            />{' '}
                                          </p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </TranslatedField>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      loading={submitting}
                      size="small"
                      style="blue"
                      type="submit"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
