import * as React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { MarketingAutomationCampaignReportDataItem } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import styles from './GraphGadget.module.css';

const getDataValue = (
  item: MarketingAutomationCampaignReportDataItem | undefined,
  dataType: GraphDataType
): number | undefined => {
  if (!item) {
    return undefined;
  }
  switch (dataType) {
    case 'SALES':
      return currency(item?.gross_sales ?? '').value;
    case 'PURCHASES':
      return item?.conversion_count ?? 0;
    case 'CLICKS':
      return item?.click_count ?? 0;
    case 'IMPRESSIONS':
      return item?.impression_count ?? 0;
  }
};

type GraphDataType = 'SALES' | 'PURCHASES' | 'CLICKS' | 'IMPRESSIONS';

export const GraphGadget = () => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reportDataSets = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.reportData
  );

  const [graphDataType, setGraphDataType] =
    React.useState<GraphDataType>('SALES');

  const data = React.useMemo(() => {
    if (!reportDataSets?.length) {
      return [];
    }
    if (reportDataSets?.length === 1) {
      return reportDataSets[0].items_by_date?.map((item) => ({
        date: moment(item.date, 'YYYY-MM-DD').locale(locale).format('l'),
        [t('Base')]: getDataValue(item, graphDataType),
      }));
    }

    return _.zip(
      reportDataSets[0].items_by_date ?? [],
      reportDataSets[1].items_by_date ?? []
    ).map((pair) => {
      return {
        date: `${moment(pair[0]?.date, 'YYYY-MM-DD')
          .locale(locale)
          .format('l')}`,
        [t('Base')]: getDataValue(pair[0], graphDataType),
        [t('Comparison')]: getDataValue(pair[1], graphDataType),
      };
    });
  }, [graphDataType, reportDataSets, t]);

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-inner']}>
          <div className={styles['header-text']}>{t('Data Graph')}</div>
          <div className={styles['header-select']}>
            <SingleDropdown
              label={t('Data Type')}
              options={[
                { value: 'SALES', text: t('Sales') },
                { value: 'PURCHASES', text: t('Bookings') },
                { value: 'CLICKS', text: t('Clicks') },
                { value: 'IMPRESSIONS', text: t('Impressions') },
              ]}
              selectedOption={graphDataType}
              onChange={(value) => setGraphDataType(value as GraphDataType)}
            />
          </div>
        </div>
      </div>
      <div className={styles['graph-container']}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={t('Base')}
              stroke="#3B82F6"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey={t('Comparison')} stroke="#EC4899" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
