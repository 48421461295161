import * as React from 'react';
import clsx from 'clsx';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  items: Record<string, any>[];
  columns: Column[];
  startTime?: string;
  totalPax?: number;
};
export type Column = {
  Header: string;
  accessor?: string | ((arg0: any) => string);
  width?: 'minimal' | 'short' | 'middle' | 'long' | 'longx2';
  Cell?: (arg0: any) => any;
  th?: boolean;
  sub?: boolean;
};
export const ManifestCustomizeCustomTable = ({ items, columns }: Props) => {
  return (
    <>
      <div className={clsx(componentStyles['c-table-sub'])}>
        <table>
          <tbody>
            <tr>
              {columns.map((column, idx) => {
                const width = column.width || '';
                return (
                  <th
                    className={clsx(
                      width ? baseStyles['base-r-t-' + width] : ''
                    )}
                    key={idx}
                  >
                    {column.Header}
                  </th>
                );
              })}
            </tr>

            {items.map((item, ridx) => {
              return (
                <tr key={ridx}>
                  {columns.map((column, idx) => {
                    const width = column.width || '';
                    let value: any;
                    let displayValue: any;

                    if (typeof column.accessor === 'string') {
                      value = item[column.accessor];
                    } else if (typeof column.accessor === 'function') {
                      value = column.accessor(item);
                    }

                    if (column.Cell) {
                      const cell = {
                        value: value,
                        original: item,
                      };
                      displayValue = column.Cell(cell);
                    } else {
                      displayValue = value;
                    }

                    if (column.th) {
                      return (
                        <th
                          className={clsx(baseStyles['base-r-t-' + width])}
                          key={idx}
                        >
                          {displayValue}
                        </th>
                      );
                    } else {
                      return (
                        <td
                          key={idx}
                          className={clsx(
                            baseStyles['base-r-t-' + width],
                            column.sub
                              ? componentStyles['c-table-sub__btn']
                              : ''
                          )}
                          data-text={column.Header}
                        >
                          {displayValue}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
