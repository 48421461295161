import type { TranslateFuncType } from 'client/components/Translate';
import type { Reservation, WaiverInfo } from 'shared/models/swagger';

import { ManifestReservationShape } from './manifestReservationShape';

export const getWaiverStatusText = (
  waiverInfo: WaiverInfo | undefined,
  guestCount: number,
  t: TranslateFuncType
): string => {
  if (!waiverInfo || waiverInfo.waiver_status === 'NOT_APPLICABLE') {
    return t('N/A');
  }

  switch (waiverInfo.waiver_status) {
    case 'UNSIGNED':
      return t('Not Signed');

    case 'SIGNED':
    case 'SIGNED_AND_NOTIFIED':
      if (waiverInfo.participant_completions) {
        if (waiverInfo.participant_completions < guestCount) {
          return t('In progress ({{completions}}/{{totalGuestCount}})', {
            completions: waiverInfo.participant_completions,
            totalGuestCount: guestCount,
          });
        } else {
          return t('Completed ({{completions}}/{{totalGuestCount}})', {
            completions: waiverInfo.participant_completions,
            totalGuestCount: guestCount,
          });
        }
      } else {
        return t('Completed');
      }
  }

  return t('<unknown status>');
};

export const getWaiverStatus = (
  reservation: Reservation | ManifestReservationShape
): 'NOT_APPLICABLE' | 'UNSIGNED' | 'COMPLETE' | 'IN_PROGRESS' => {
  if (
    !reservation.waiver_info ||
    reservation.waiver_info.waiver_status === 'NOT_APPLICABLE'
  ) {
    return 'NOT_APPLICABLE';
  }

  switch (reservation.waiver_info.waiver_status) {
    case 'UNSIGNED':
      return 'UNSIGNED';

    case 'SIGNED':
    case 'SIGNED_AND_NOTIFIED':
      if (reservation.waiver_info.participant_completions) {
        if (
          reservation.waiver_info.participant_completions <
          (reservation.guests ?? []).length
        ) {
          return 'IN_PROGRESS';
        } else {
          return 'COMPLETE';
        }
      } else {
        return 'COMPLETE';
      }
  }

  return 'NOT_APPLICABLE';
};
