import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { EquipmentBlockInstancePrice } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Edit as EditIcon } from 'client/components/Icons/Edit';

import { EditEquipmentBlockInstancePriceModal } from './EditEquipmentBlockInstancePriceModal';

export const EditDeleteCell = ({
  equipmentBlockInstancePrice,
}: {
  equipmentBlockInstancePrice: EquipmentBlockInstancePrice;
}) => {
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditEquipmentBlockInstancePriceModal
          equipmentBlockInstancePriceKey={equipmentBlockInstancePrice.key ?? ''}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete stamp rally')}
          content={t('Are you sure you want to delete stamp rally?')}
          onConfirm={async () => {
            console.log('delete');
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};
