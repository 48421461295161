import * as Swagger from 'shared/models/swagger';

export type TransportLocation = {
  googlePlaceId: string;
  locationName: string;
  relativeTime: string;
};
export type TransportRouteItem = {
  locationFrom: TransportLocation | null;
  locationTo: TransportLocation | null;
};
export type TransportRouteFormValues = {
  transportRoute: TransportRouteItem[];
};
export const getInitialTransportRouteFormValues = (
  product: Swagger.Product | null
): TransportRouteFormValues => {
  const transportRoute = product?.transport_route ?? [];
  return {
    transportRoute:
      transportRoute.length === 0
        ? [
            {
              locationFrom: {
                googlePlaceId: '',
                locationName: '',
                relativeTime: '',
              },
              locationTo: {
                googlePlaceId: '',
                locationName: '',
                relativeTime: '',
              },
            },
          ]
        : transportRoute.map((transportRouteItem) => ({
            locationFrom: transportRouteItem.location_from
              ? {
                  googlePlaceId:
                    transportRouteItem.location_from.google_place_id ?? '',
                  locationName:
                    transportRouteItem.location_from.location_name ?? '',
                  relativeTime:
                    transportRouteItem.location_from.relative_time ?? '',
                }
              : {
                  googlePlaceId: '',
                  locationName: '',
                  relativeTime: '',
                },
            locationTo: transportRouteItem.location_to
              ? {
                  googlePlaceId:
                    transportRouteItem.location_to.google_place_id ?? '',
                  locationName:
                    transportRouteItem.location_to.location_name ?? '',
                  relativeTime:
                    transportRouteItem.location_to.relative_time ?? '',
                }
              : {
                  googlePlaceId: '',
                  locationName: '',
                  relativeTime: '',
                },
          })),
  };
};
export const convertTransportRouteFormValuesToProductPatch = (
  values: TransportRouteFormValues
): Swagger.Product$Patch => {
  if (values.transportRoute.length === 1) {
    return {
      transport_route: [],
    };
  }

  return {
    transport_route: values.transportRoute.map(
      ({ locationFrom, locationTo }, idx) => ({
        location_from:
          locationFrom?.locationName && idx !== 0
            ? {
                google_place_id: locationFrom.googlePlaceId,
                location_name: locationFrom.locationName,
                relative_time: locationFrom.relativeTime,
              }
            : undefined,
        location_to:
          locationTo?.locationName && idx !== values.transportRoute.length - 1
            ? {
                google_place_id: locationTo.googlePlaceId,
                location_name: locationTo.locationName,
                relative_time: locationTo.relativeTime,
              }
            : undefined,
      })
    ),
  };
};
