import * as React from 'react';
import { Field } from 'react-final-form';

const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  logoPath: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  title: string;
  message: string;
  poweredByText: string;
}

export const Plain = ({
  editMode,
  logoPath,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  title,
  message,
  poweredByText,
}: Props) => {
  return (
    <div style={{ backgroundColor: '#EEF3F6', color: '#333333' }}>
      <div
        style={{
          maxWidth: '600px',
          margin: '0 auto',
          backgroundColor: '#FFFFFF',
        }}
      >
        <table
          cellSpacing="0"
          cellPadding="0"
          style={{
            width: '100%',
            height: '72px',
            borderBottom: '1px solid #DDDDDD',
            padding: '16px',
          }}
        >
          <tr>
            <td
              style={{ textAlign: 'center', margin: 0, padding: '0 0 16px 0' }}
            >
              <img style={{ height: '32px' }} src={logoPath} />
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '12px',
                fontWeight: '600',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
            >
              {supplierName}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '12px',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
            >
              <a
                href={`mailto:${supplierEmail}`}
                target="_blank"
                rel="noreferrer"
              >
                {supplierEmail}
              </a>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '12px',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
            >
              {supplierOfficeAddress}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '12px',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
            >
              <a
                href={`tel:${supplierOfficePhone}`}
                style={{ marginRight: '.5em' }}
                target="_blank"
                rel="noreferrer"
              >
                {supplierOfficePhone}
              </a>
              <span style={{ marginTop: '.25em' }}>{supplierOfficeHours}</span>
            </td>
          </tr>
        </table>

        <table
          cellSpacing="0"
          cellPadding="0"
          style={{ width: '100%', paddingBottom: '32px' }}
        >
          <tr>
            <td style={{ padding: '32px 16px 16px 16px' }}>
              {editMode && (
                <Field name="templateValues.title">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: 0,
                  whiteSpace: 'pre-line',
                }}
              >
                {title}
              </p>
            </td>
          </tr>

          <tr>
            <td style={{ padding: '16px' }}>
              {editMode && (
                <Field name="templateValues.message">
                  {({ input }) => (
                    <TextAreaEditButton
                      initialValue={input.value}
                      onSave={(text) => input.onChange(text)}
                    />
                  )}
                </Field>
              )}
              <p
                style={{
                  fontSize: '16px',
                  margin: '0',
                  whiteSpace: 'pre-line',
                }}
              >
                {message}
              </p>
            </td>
          </tr>
        </table>

        <table
          cellSpacing="0"
          cellPadding="0"
          style={{ width: '100%', height: '72px', backgroundColor: '#EEF3F6' }}
        >
          <tr>
            <td
              style={{
                fontSize: '12px',
                textAlign: 'center',
                margin: '0',
                padding: '0',
              }}
            >
              <a
                style={{ fontSize: '12px', color: '#0094CC' }}
                href="https://ntmg.com/"
              >
                {poweredByText}
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
