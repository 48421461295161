import * as React from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';

import styles from './SelectedItem.module.css';

const style = {
  cursor: 'move',
};
interface Props {
  id: any;
  text: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  toggle: () => void;
  dragItemType?: string | typeof undefined;
  disabled?: boolean;
  pii: boolean;
}
const DragItemType = 'selected-item';
// SelectedItem is a toggleable, drag-and-droppable list item.
// Reference: http://react-dnd.github.io/react-dnd/examples/sortable/simple; code was adapted from this example.
export const SelectedItem = ({
  id,
  dragItemType,
  text,
  index,
  moveItem,
  toggle,
  disabled,
  pii,
}: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop({
    accept: dragItemType || DragItemType,

    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: dragItemType || DragItemType,
    item: {
      type: dragItemType || DragItemType,
      id,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={clsx(
        baseStyles['base-form-checkbox'],
        disabled && baseStyles['disabled'],
        pii && styles['pii']
      )}
      style={{ ...style, opacity }}
    >
      <label>
        <input
          type="checkbox"
          name={text}
          checked={true}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (!disabled) {
              toggle();
            }
          }}
        />
        <p></p>
        {text}
      </label>
    </div>
  );
};
