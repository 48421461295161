import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_RESOURCE_ASSIGNMENTS_FAILURE,
  FETCH_RESOURCE_ASSIGNMENTS_REQUEST,
  FETCH_RESOURCE_ASSIGNMENTS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  ResourceAssignment,
  ListResourceAssignmentsResponse,
} from 'shared/models/swagger';

export const memosSelector = (state: ReduxState) => state.memos.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESOURCE_ASSIGNMENTS_FAILURE:
      return action.error;

    case FETCH_RESOURCE_ASSIGNMENTS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_RESOURCE_ASSIGNMENTS_REQUEST:
      return true;

    case FETCH_RESOURCE_ASSIGNMENTS_FAILURE:
    case FETCH_RESOURCE_ASSIGNMENTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: ResourceAssignment[] = [],
  action: any
): ResourceAssignment[] => {
  switch (action.type) {
    case FETCH_RESOURCE_ASSIGNMENTS_SUCCESS: {
      const response = action.response as ListResourceAssignmentsResponse;
      const newIds = response.resource_assignments.map(
        (resource_assignment) => resource_assignment.id
      );
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...response.resource_assignments,
      ];
    }

    default:
      return state;
  }
};

const latestQueryResults = (
  state: ResourceAssignment[] = [],
  action: any
): ResourceAssignment[] => {
  switch (action.type) {
    case FETCH_RESOURCE_ASSIGNMENTS_SUCCESS: {
      const response = action.response as ListResourceAssignmentsResponse;
      return [...response.resource_assignments];
    }

    default:
      return state;
  }
};

export const resourceAssignments = combineReducers({
  error,
  loading,
  all,
  latestQueryResults,
});
