import * as React from 'react';

type Props = {
  buttonUrl: string;
  buttonText: string;
};

export const Button = ({ buttonUrl, buttonText }: Props) => {
  return (
    <tr style={{ textAlign: 'center' }}>
      <td style={{ textAlign: 'center' }}>
        <div
          style={{
            marginTop: '2em',
            marginBottom: '2em',
          }}
        >
          <a
            href={buttonUrl}
            target="_blank"
            style={{
              display: 'inline-block',
              lineHeight: '48px',
              width: '240px',
              height: '48px',
              backgroundColor: '#4DA1EF',
              color: '#FFF',
              borderRadius: '24px',
              fontSize: '16px',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        </div>
      </td>
    </tr>
  );
};
