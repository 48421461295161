import clsx from 'clsx';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';

import { Box } from 'client/components/Box/Box';
import { Input, FieldWrapper } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

export const TitleInputForm = () => {
  const { t } = useTranslation();
  return (
    <Box mt={2} mr={2}>
      <Field name="title">
        {({ input }) => (
          <Input
            label={t('Title')}
            value={input.value}
            onChange={(_, { value }) => {
              input.onChange(value);
            }}
          />
        )}
      </Field>
    </Box>
  );
};

export const ColumnRowCountInputForm = () => {
  const { t } = useTranslation();
  return (
    <Box mt={2} mr={2} display={'flex'}>
      <div style={{ marginRight: '8px', width: '300px' }}>
        <div className={clsx(baseStyles['base-form-box'])}>
          <div className={clsx(baseStyles['base-form-box__header'])}>
            {t('seat.Columns')}
          </div>
          <div className={clsx(baseStyles['base-form-box__body'])}>
            <Field name="columnCount">
              {(props) => (
                <input
                  type="number"
                  className={clsx(baseStyles['base-form-text'])}
                  {...props.input}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
      <div style={{ marginRight: '8px', width: '300px' }}>
        <div className={clsx(baseStyles['base-form-box'])}>
          <div className={clsx(baseStyles['base-form-box__header'])}>
            {t('seat.Rows')}
          </div>
          <div className={clsx(baseStyles['base-form-box__body'])}>
            <Field name="rowCount">
              {({ input }) => (
                <input
                  type="number"
                  className={clsx(baseStyles['base-form-text'])}
                  {...input}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </Box>
  );
};

export const ColorInputForm = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (color: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box mt={2} mr={2}>
      <FieldWrapper label={t('Colors')}>
        <ColorPicker
          color={value}
          onChange={({ color }: any) => {
            onChange(color);
          }}
        />
      </FieldWrapper>
    </Box>
  );
};

export const CapacityInputForm = () => {
  const { t } = useTranslation();
  return (
    <Box mt={2} mr={2}>
      <div style={{ marginRight: '8px', width: '100px' }}>
        <Field name="capacity">
          {({ input }) => (
            <Input
              type="number"
              label={t('seat.Capacity')}
              value={input.value}
              onChange={(_, { value }) => {
                if (Number(value) < 0) {
                  return;
                }
                if (Number(value) > 1) {
                  return;
                }
                input.onChange(value);
              }}
            />
          )}
        </Field>
      </div>
    </Box>
  );
};
