import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { setImpersonatedUserID } from 'client/actions/user';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { Select } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import type { Account } from 'shared/models/swagger';

import styles from '../../base.module.css';

const formatUser = (a: Account | null | undefined) =>
  a && a.organization_name && a.organization_type
    ? `${a.organization_name} (${a.organization_type}) ${
        a.role === 'CUSTOM_USER' ? a.email : a.role
      }`
    : '';

export const ImpersonationSelect = () => {
  const dispatch = useDispatch();
  const childAccounts = useSelector((state: ReduxState) => state.accounts.all);
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const accountOptions = [
    {
      id: 'NO_IMPERSONATION',
    },
    ..._.sortBy(
      _.uniqBy(
        childAccounts.filter(
          (a) =>
            a.api_access_type !== 'DIRECT' &&
            (a.organization_type === 'AGENT' ||
              a.organization_type === 'SUPPLIER')
        ),
        (a) =>
          `${a?.organization_id ?? '<MISSING>'}:${
            a.organization_type ?? '<MISSING>'
          }:${a.role === 'CUSTOM_USER' ? a.email : a.role}`
      ),
      (a) => a.organization_name
    ),
  ];
  return (
    <label className={styles['base-header__info__impersonation']}>
      <Select
        search
        name="impersonation"
        value={impersonatedAccount?.id ?? 'NO_IMPERSONATION'}
        onChange={(e, { value }) => {
          if (value !== (impersonatedAccount?.id ?? 'NO_IMPERSONATION')) {
            dispatch(
              setImpersonatedUserID(value === 'NO_IMPERSONATION' ? '' : value)
            );
          }
        }}
        options={accountOptions.map((acc) => ({
          value: acc.id,
          text:
            acc?.id === 'NO_IMPERSONATION'
              ? 'No Impersonation'
              : formatUser(acc as any),
        }))}
      />
    </label>
  );
};
