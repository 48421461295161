import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { MemoModal } from 'client/components/MemoModal/MemoModal';
import { fetchMemos } from 'client/actions/memos';
import { memosSelector } from 'client/reducers/memos';
import { getResourceManagerAssignedResources } from 'client/libraries/util/resourceManager';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import editIcon from 'client/images/ic_edit.svg';
import humanIcon from 'client/images/ic_human.svg';
import resourceIcon from 'client/images/ic_resource.svg';

import { ManifestTableHeaderCell } from './ManifestTableHeaderCell';
import styles from './Manifest.module.css';

type Props = {
  items: Record<string, any>[];
  columns: Column[];
  startTime?: string;
  totalPax?: number;
  open?: boolean;
  capacity?: number;
  checkedInGuestCount: number | null;
  memoKey?: string | null;
};
export type Column = {
  Header: string;
  accessor?: string | ((arg0: any) => string);
  width?: string;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
};

const calcWidth = (width: string | typeof undefined): string => {
  if (width) return width;
  return 'short';
};

export const ManifestCustomTable = ({
  items,
  columns,
  startTime,
  totalPax,
  open,
  capacity,
  checkedInGuestCount,
  memoKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memos = useSelector(memosSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const memo = memos.find((memo) => memo.key === memoKey);
  const tableRef = React.useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(open || false);
  const [showMemoModal, setShowMemoModal] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (memoKey) {
      dispatch(fetchMemos(memoKey));
    }
  }, [memoKey]);

  const scrollButtonClickHandler = (direction: any) => {
    if (tableRef?.current?.scrollLeft != null) {
      if (direction === 'next') {
        tableRef.current.scrollLeft += 100;
      } else {
        tableRef.current.scrollLeft -= 100;
      }
    }
  };

  const assignedResources = getResourceManagerAssignedResources(
    activeUserOrganization,
    items as any
  );

  const [showColumns, setShowColumns] = React.useState(
    [...Array(items.length)].map(() => false)
  );
  return (
    <>
      <div className={clsx(componentStyles['c-table-manifests'])}>
        <div className={clsx(componentStyles['c-table-manifests__tbody'])}>
          <div
            className={clsx(
              componentStyles['c-table-manifests__tbody__ttl'],
              isOpen ? componentStyles['is-open'] : componentStyles['is-close']
            )}
          >
            {startTime && (
              <p
                className={clsx(
                  componentStyles['c-table-manifests__tbody__ttl__time']
                )}
              >
                {t('Start time: {{startTime}}', {
                  startTime: startTime,
                })}
              </p>
            )}
            <div
              className={clsx(
                componentStyles['c-table-manifests__tbody__ttl__num']
              )}
            >
              <p>
                <img src={humanIcon} />
              </p>
              {capacity
                ? t('Total Pax: {{paxCount}} / {{capacity}}', {
                    paxCount: totalPax,
                    capacity: capacity,
                  })
                : t('Total Pax: {{paxCount}}', {
                    paxCount: totalPax,
                  })}{' '}
              {checkedInGuestCount != null &&
                t('({{checkedInGuestCount}} / {{paxCount}} checked-in)', {
                  checkedInGuestCount,
                  paxCount: totalPax,
                })}
              {assignedResources != null &&
                (assignedResources ?? []).length !== 0 && (
                  <>
                    <p style={{ margin: '4px' }}>
                      <img src={resourceIcon} />
                    </p>
                    {assignedResources
                      .map((pair) => {
                        return `${pair.resourceKey} x ${pair.quantity}`;
                      })
                      .join(', ')}
                  </>
                )}
            </div>
            <span
              className={clsx(
                componentStyles['c-table-manifests__tbody__ttl__ic']
              )}
              onClick={() => setIsOpen(!isOpen)}
            ></span>
          </div>

          {memoKey && (
            <div className={clsx(styles['memo'])}>
              <span className={clsx(styles['memo-title'])}>{t('Memo')}</span>:{' '}
              <div className={clsx(styles['memo-content'])}>
                {' '}
                {memo?.content}{' '}
                {hasCustomUserRoleWritePermissions(
                  activeUser,
                  'MANIFEST.DAILY'
                ) && (
                  <img
                    className={clsx(styles['memo-button'])}
                    src={editIcon}
                    onClick={() => setShowMemoModal(true)}
                  />
                )}
              </div>
            </div>
          )}
          <div className={clsx(componentStyles['c-table-manifests__frame'])}>
            <div
              className={clsx(componentStyles['c-table-manifests__table'])}
              ref={tableRef}
            >
              <table
                className={clsx(
                  componentStyles['c-table-manifests__tbody__body']
                )}
              >
                <thead>
                  <tr
                    className={clsx(
                      componentStyles['c-table-manifests__tbody__header']
                    )}
                  >
                    {columns.map((column, idx) => {
                      const width = calcWidth(column.width);
                      return (
                        <th
                          className={clsx(
                            baseStyles[
                              ('base-r-t-' + width) as keyof typeof baseStyles
                            ]
                          )}
                          key={idx}
                        >
                          <ManifestTableHeaderCell text={column.Header} />
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {_.flatten(
                    items.map((item, ridx) => {
                      const rows = [];

                      const subRowCount =
                        _.max(
                          columns.map(
                            (col) =>
                              (col.CellArray && col.CellArray(item)?.length) ||
                              1
                          )
                        ) ?? 1;

                      for (
                        let subRowIdx = 0;
                        subRowIdx < subRowCount;
                        subRowIdx++
                      ) {
                        const row = (
                          <tr
                            className={clsx(
                              subRowIdx > 0 && componentStyles['subrow'],
                              subRowIdx === subRowCount - 1 &&
                                componentStyles[
                                  'last-subrow' as keyof typeof componentStyles
                                ]
                            )}
                            key={`${ridx}-${subRowIdx}`}
                          >
                            {columns.map((column, idx) => {
                              if (subRowIdx > 0 && !column.CellArray) {
                                return null;
                              }

                              const rowSpan = column.CellArray
                                ? 1
                                : subRowCount;
                              const width = calcWidth(column.width);
                              let value: any;
                              let displayValue: any;

                              if (typeof column.accessor === 'string') {
                                value = item[column.accessor];
                              } else if (
                                typeof column.accessor === 'function'
                              ) {
                                value = column.accessor(item);
                              }

                              if (column.CellArray) {
                                const cellArray = column.CellArray(item);
                                displayValue =
                                  subRowIdx < cellArray.length
                                    ? cellArray[subRowIdx]
                                    : '';
                              } else if (column.Cell) {
                                const cell = {
                                  value: value,
                                  original: item,
                                };
                                displayValue = column.Cell(cell);
                              } else {
                                displayValue = value;
                              }

                              if (column.th) {
                                return (
                                  <th
                                    className={clsx(
                                      baseStyles[
                                        ('base-r-t-' +
                                          width) as keyof typeof baseStyles
                                      ]
                                    )}
                                    key={idx}
                                    rowSpan={rowSpan}
                                  >
                                    {displayValue}
                                  </th>
                                );
                              } else {
                                return (
                                  <td
                                    key={idx}
                                    className={clsx(
                                      baseStyles[
                                        ('base-r-t-' +
                                          width) as keyof typeof baseStyles
                                      ],
                                      6 < idx
                                        ? showColumns[ridx]
                                          ? ''
                                          : baseStyles['base-t-spHidden']
                                        : '',
                                      subRowIdx > 0 && componentStyles['subrow']
                                    )}
                                    data-text={column.Header}
                                    rowSpan={rowSpan}
                                  >
                                    {displayValue}
                                  </td>
                                );
                              }
                            })}
                            {subRowIdx === subRowCount - 1 && (
                              <td className={clsx(baseStyles['base-t-spShow'])}>
                                <a
                                  className={clsx(
                                    componentStyles[
                                      'c-table-main__tbody__spMore'
                                    ],
                                    showColumns[ridx]
                                      ? componentStyles['arrowUp']
                                      : ''
                                  )}
                                  onClick={() => {
                                    const newData = [...showColumns];
                                    newData[ridx] = !newData[ridx];
                                    setShowColumns(newData);
                                  }}
                                >
                                  {showColumns[ridx]
                                    ? t('Close')
                                    : t('See more')}
                                </a>
                              </td>
                            )}
                          </tr>
                        );
                        rows.push(row);
                      }

                      return rows;
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div
              className={clsx(
                componentStyles['c-table-manifests__btn'],
                componentStyles['prev']
              )}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>
            <div
              className={clsx(
                componentStyles['c-table-manifests__btn'],
                componentStyles['next']
              )}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <MemoModal
        memoKey={memoKey || ''}
        open={showMemoModal}
        onOpen={() => {
          setShowMemoModal(true);
        }}
        onClose={() => {
          setShowMemoModal(false);
        }}
      />
    </>
  );
};
