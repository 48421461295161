import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import type { ManifestDisplaySettings } from 'client/libraries/util/manifestDisplaySettings';
import type { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { ManifestDetails } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestDetails';
import { ManifestSummary } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestSummary';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  manifestProductGroupsSelector,
  allManifestViewsSelector,
} from 'client/reducers/manifestSettings';
import { setLastManifestDisplaySettings } from 'client/actions/manifestDisplaySettings';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { ManifestDailyHeader } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyHeader/ManifestDailyHeader';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { CollapsibleSection } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/CollapsibleSection';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ManifestDaily = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const allManifestViewTypeKeys = useSelector((state: ReduxState) =>
    allManifestViewsSelector(state).map((view) => view.key)
  );
  const manifestLoading = useSelector(
    (state: ReduxState) => state.manifests.loading
  );
  const singleDayScheduleLoading = useSelector(
    (state: ReduxState) => state.guides.singleDayScheduleLoading
  );
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const productGroups = useSelector(manifestProductGroupsSelector);
  const products = useSelector(summariesSortedByBookmarkedSelector);
  const reservations = useSelector((state: ReduxState) =>
    state.manifests.reservations.map((r) => toManifestReservationShape(r, t))
  );
  const [displaySettings, setDisplaySettings] =
    useState<ManifestDisplaySettings>(lastManifestDisplaySettings);
  const [startTimeFilters, setStartTimeFilters] = useState<string[]>([]);
  useEffect(() => {
    if (!_.isEqual(displaySettings, lastManifestDisplaySettings)) {
      dispatch(setLastManifestDisplaySettings(displaySettings));
    }
  }, [displaySettings]);
  const getProductAndProductGroupOptions = useMemo(() => {
    return [
      {
        text: t('All Products'),
        key: 'ALL_PRODUCTS',
        value: 'ALL_PRODUCTS',
      },
      ...productGroups.map((p) => ({
        text: p.key ?? '',
        key: p.key ?? '',
        value: p.key ?? '',
      })),
      ...products.map((p) => ({
        value: p.id ?? '',
        key: p.id ?? '',
        text: getVerboseDisplayProductName(p),
      })),
    ];
  }, [productGroups, products]);

  const getManifestTypeOptions = (): string[] => {
    const { rootResource } = displaySettings;

    if (rootResource && rootResource.type === 'GROUP') {
      const productGroup = productGroups.find((g) => g.key === rootResource.id);

      if (productGroup && productGroup.view_type_key) {
        return [productGroup.view_type_key];
      }
    }

    return allManifestViewTypeKeys as any;
  };

  const handleRootResourceChange = (id: string) => {
    if (id === 'ALL_PRODUCTS') {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: 'ALL_PRODUCTS',
          type: 'ALL_PRODUCTS',
        },
      });
    }

    const product = products.find((p) => p.id === id);

    if (product) {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: product.id,
          type: 'PRODUCT',
        },
      });
      return;
    }

    const productGroup = productGroups.find((p) => p.key === id);

    if (productGroup) {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: productGroup.key || '',
          type: 'GROUP',
        },
        manifestType:
          productGroup.view_type_key ||
          displaySettings.manifestType ||
          'DRIVER',
      });
      return;
    }
  };

  const handleDateChange = (dateString: string | null) => {
    const date = moment(dateString);
    setDisplaySettings({
      ...displaySettings,
      participationDate: date.format('YYYY-MM-DD'),
    });
  };

  const handleOpenSummary = () => {
    setDisplaySettings({
      ...displaySettings,
      openSummary: !displaySettings.openSummary,
    });
  };

  const getFilteredReservations = () => {
    if (_.isEmpty(startTimeFilters)) {
      return reservations;
    }

    return reservations.filter(
      (r) =>
        startTimeFilters.indexOf(String(r.participates_at.valueOf())) !== -1
    );
  };

  const getStartTimeFilterOptions = () => {
    const uniqueDateTimes = _.uniqBy(
      reservations.map((r) => r.participates_at),
      (m) => m.valueOf()
    );

    const options = uniqueDateTimes.map((d) => {
      if (d.format('YYYY-MM-DD') === displaySettings.participationDate) {
        return {
          key: d.valueOf(),
          value: d.valueOf(),
          text: d.locale(locale).format('HH:mm'),
        };
      }

      return {
        key: d.valueOf(),
        value: d.valueOf(),
        text: `${d.locale(locale).format('HH:mm')} (${d
          .locale(locale)
          .format('YYYY-MM-DD')})`,
      };
    });
    return options
      .sort((opt1, opt2) => opt1.value - opt2.value)
      .map((o) => {
        return {
          text: o.text,
          value: String(o.value),
        };
      });
  };

  const manifestTypeOptions = getManifestTypeOptions();
  const startTimeFilterOptions = getStartTimeFilterOptions();

  const {
    manifestType,
    participationDate,
    rootResource,
    showCheckinCheckout,
    showDispatchColumn,
    showByProductGroup,
  } = displaySettings;

  return (
    <V3Page>
      <ManifestDailyHeader />
      <div className={baseStyles['l-main__body']}>
        <section className={baseStyles['g-section']}>
          <div className={styles['p-manifests__search']}>
            <div className={styles['p-manifests__search__item']}>
              <SingleDropdown
                label={t('Select Product/Group')}
                options={getProductAndProductGroupOptions}
                searchable={true}
                selectedOption={(rootResource && rootResource.id) || ''}
                onChange={handleRootResourceChange}
              />
              <SingleDropdown
                label={t('Select Type')}
                options={manifestTypeOptions.map((option) => ({
                  text: t(option),
                  value: option,
                }))}
                selectedOption={manifestType}
                onChange={(value) =>
                  setDisplaySettings({
                    ...displaySettings,
                    manifestType: value,
                  })
                }
                disabled={manifestTypeOptions.length === 1}
              />
              <SimpleDateInput
                label={t('Select Date')}
                onChange={handleDateChange}
                dateFrom={moment(participationDate)
                  .format('YYYY/MM/DD')
                  .toString()}
                locale={locale}
              />
              {reservations.length > 0 && (
                <MultiDropdown
                  label={t('Filter start times...')}
                  options={startTimeFilterOptions}
                  selectedOptions={startTimeFilters}
                  onChange={(value) => setStartTimeFilters(value)}
                />
              )}
            </div>
          </div>
        </section>

        <section
          className={clsx(baseStyles['g-section'], baseStyles['u-mt-6'])}
        >
          <CollapsibleSection
            title={t('Summary')}
            open={displaySettings.openSummary}
            onClick={handleOpenSummary}
          >
            <ManifestSummary
              reservations={getFilteredReservations()}
              supplierCurrency={activeUserOrganization?.default_currency || ''}
            />
          </CollapsibleSection>
        </section>

        <section
          className={clsx(baseStyles['g-section'], baseStyles['u-mt-6'])}
        >
          <ManifestDetails
            reservations={getFilteredReservations()}
            products={[]}
            // Map back from moment "values" to HH:mm format for passing to the PDF/CSV/email export APIs
            startTimeHhMmFilters={startTimeFilters.map(
              (filterValue) =>
                startTimeFilterOptions.find(
                  (option) => option.value === filterValue
                )?.text || ''
            )}
            manifestType={manifestType}
            participationDate={participationDate}
            rootResource={rootResource}
            showCheckinCheckout={showCheckinCheckout}
            showDispatchColumn={showDispatchColumn}
            showByProductGroup={showByProductGroup}
            onVisualFlagsChange={({
              showCheckinCheckout,
              showDispatchColumn,
              showByProductGroup,
            }) => {
              setDisplaySettings({
                ...displaySettings,
                showCheckinCheckout,
                showDispatchColumn,
                showByProductGroup,
              });
            }}
          />
        </section>

        {(loading || manifestLoading || singleDayScheduleLoading) && (
          <Loading />
        )}
      </div>
    </V3Page>
  );
};
