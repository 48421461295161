import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useForm, Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import { BookingDeadlineTimeline } from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/TourInformation/BookingDeadlineTimeline/BookingDeadlineTimeline';
import { DeadlineInput } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/DeadlineInput';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';

export const BookingDeadlinesInput = () => {
  const form = useForm();

  // Note: use useFormState to get values because getting values from useForm does not seem to work (values are not updated correctly)
  const formState = useFormState();
  const instantBookingDeadlineValues =
    formState.values['instantBookingDeadline'];
  const requestBookingDeadlineValues =
    formState.values['requestBookingDeadline'];

  const { t, i18n } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const startDateText = t(
    'Accept reservations from {{days}} days before participation',
    {
      days: '<DAYS>',
    }
  );
  const startDateTextParts = startDateText.split('<DAYS>');

  const startDateTimeText = t('Accept reservations from {{date}}.', {
    date: '<DATE>',
  });
  const startDateTimeTextParts = startDateTimeText.split('<DATE>');

  return (
    <ul className={styles['p-list']}>
      <li className={clsx(styles['p-list__item'], styles['row'])}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Booking Start Date & Time')}
          </div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <div className={styles['p-reservationStart']}>
            <Field name="shouldUseBookingStartDateTime">
              {({ input }) => (
                <>
                  <div className={styles['p-reservationStart__toggle']}>
                    <Toggle
                      label={t('Set the start date and time')}
                      checked={input.value}
                      onChange={() => {
                        input.onChange(!input.value);
                      }}
                      size="sm"
                    />
                    <Tooltip
                      text={t(
                        'If date & time is not set, all dates with participation rules and prices will be always available for bookings.'
                      )}
                    >
                      <i className="c-icon-outline-general-info-circle"></i>
                    </Tooltip>
                  </div>
                  {input.value && (
                    <div className={styles['p-reservationStart__flex']}>
                      {startDateTimeTextParts[0] !== '' && (
                        <p>{startDateTimeTextParts[0]}</p>
                      )}
                      <div>
                        <Field name="bookingStartDateTimeUtc">
                          {({ input: startDateTimeInput }) => (
                            <DateTimeTZInput
                              value={moment.tz(
                                startDateTimeInput.value,
                                activeUserOrganization?.default_timezone ||
                                  'UTC'
                              )}
                              onChange={(date) => {
                                const newDate = moment
                                  .tz(
                                    startDateTimeInput.value,
                                    activeUserOrganization?.default_timezone ||
                                      'UTC'
                                  )
                                  .clone()
                                  .set('year', date.year())
                                  .set('month', date.month())
                                  .set('date', date.date());
                                startDateTimeInput.onChange(
                                  newDate.utc().format()
                                );
                              }}
                              locale={i18n.language}
                              disabled={!input.value}
                            />
                          )}
                        </Field>
                      </div>
                      <div>
                        <Field name="bookingStartDateTimeUtc">
                          {({ input: startDateTimeInput }) => (
                            <TimePicker
                              allowEmpty={false}
                              value={moment.tz(
                                startDateTimeInput.value,
                                activeUserOrganization?.default_timezone ||
                                  'UTC'
                              )}
                              onChange={(time) => {
                                if (!time) {
                                  return;
                                }
                                const newDate = moment
                                  .tz(
                                    startDateTimeInput.value,
                                    activeUserOrganization?.default_timezone ||
                                      'UTC'
                                  )
                                  .clone()
                                  .set('hour', time.hour())
                                  .set('minute', time.minute());
                                startDateTimeInput.onChange(
                                  newDate.utc().format()
                                );
                              }}
                              disabled={!input.value}
                            />
                          )}
                        </Field>
                      </div>
                      <p>{startDateTimeTextParts[1]}</p>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className={styles['p-reservationStart']}>
            <Field name="agentGuestBookingAllowedDaysBefore">
              {({ input }) => (
                <>
                  <div className={styles['p-reservationStart__toggle']}>
                    <Toggle
                      label={t(
                        'Set the number of days from the participation date'
                      )}
                      checked={Boolean(input.value)}
                      onChange={() => {
                        if (input.value) {
                          input.onChange(null);
                        } else {
                          input.onChange(20);
                        }
                      }}
                      size="sm"
                    />
                    <Tooltip
                      text={t(
                        'If date & time is not set, all dates with participation rules and prices will be always available for bookings.'
                      )}
                    >
                      <i className="c-icon-outline-general-info-circle"></i>
                    </Tooltip>
                  </div>
                  {input.value && (
                    <div className={styles['p-reservationStart__flex']}>
                      <p>{startDateTextParts[0]}</p>
                      <div>
                        <SingleDropdown
                          options={_.times(365, (count) => ({
                            value: `${count + 1}`,
                            text: `${count + 1}`,
                          }))}
                          width={100}
                          selectedOption={input.value.toString()}
                          onChange={(value) => input.onChange(parseInt(value))}
                          disabled={!input.value}
                        />
                      </div>
                      <p>{startDateTextParts[1]}</p>
                    </div>
                  )}
                </>
              )}
            </Field>
          </div>
        </div>
      </li>

      <li className={clsx(styles['p-list__item'], styles['row'])}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Deadlines')}
          </div>
        </div>
        <div className={clsx(styles['p-list__item__body'], styles['row'])}>
          <BookingDeadlineTimeline
            requestBookingDeadline={requestBookingDeadlineValues}
            instantBookingDeadline={instantBookingDeadlineValues}
          />
          <div className={baseStyles['u-mt-4']}>
            <div className={styles['p-limitReservations']}>
              <div className={styles['p-limitReservations__item']}>
                <p className={styles['p-limitReservations__item__ttl']}>
                  {t('Instant Booking')}
                </p>
                {instantBookingDeadlineValues && (
                  <div className={styles['p-limitReservations__item__flex']}>
                    <div style={{ flex: 8 }}>
                      <DeadlineInput name="instantBookingDeadline" />
                    </div>
                    <div style={{ flex: 2 }}>
                      {requestBookingDeadlineValues && (
                        <Button
                          size="icon"
                          color="tertiarygray"
                          onClick={() =>
                            form.change('instantBookingDeadline', null)
                          }
                          iconBeforeText={
                            <i className="c-icon-outline-general-trash-03"></i>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {!instantBookingDeadlineValues && (
                  <div className={baseStyles['u-mt-2']}>
                    {
                      <a
                        className={styles['p-box__table2__actions__add']}
                        onClick={() =>
                          form.change('instantBookingDeadline', {
                            deadlineType: 'DAY',
                            daysBefore: 1,
                            timeOfDay: '17:00',
                          })
                        }
                      >
                        <i className="c-icon-outline-general-plus-circle"></i>
                        {t('Add')}
                      </a>
                    }
                  </div>
                )}
              </div>
              <div className={styles['p-limitReservations__item']}>
                <p className={styles['p-limitReservations__item__ttl']}>
                  {t('Request Booking')}
                </p>
                {requestBookingDeadlineValues && (
                  <div className={styles['p-limitReservations__item__flex']}>
                    <div style={{ flex: 8 }}>
                      <DeadlineInput name="requestBookingDeadline" />
                    </div>
                    <div style={{ flex: 2 }}>
                      {instantBookingDeadlineValues && (
                        <Button
                          size="icon"
                          color="tertiarygray"
                          onClick={() => {
                            form.change('requestBookingDeadline', null);
                          }}
                          iconBeforeText={
                            <i className="c-icon-outline-general-trash-03"></i>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {!requestBookingDeadlineValues && (
                  <div className={baseStyles['u-mt-2']}>
                    <a
                      className={styles['p-box__table2__actions__add']}
                      onClick={() =>
                        form.change('requestBookingDeadline', {
                          deadlineType: 'DAY',
                          daysBefore: 2,
                          timeOfDay: '17:00',
                        })
                      }
                    >
                      <i className="c-icon-outline-general-plus-circle"></i>
                      {t('Add')}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};
