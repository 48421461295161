import * as React from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { FormRenderProps } from 'react-final-form';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { updateProduct } from 'client/actions/products';
import { updateTranslations } from 'client/actions/translations';
import { getArrayMutators } from 'client/libraries/util/form';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

const focusOnError: any = createDecorator();

export type Translation = {
  source: string;
  target: string;
};

type TranslationsFormValues = {
  translations: Translation[];
};

type Props<FormValues> = {
  onSubmitStart?: () => void;
  onSubmitSuccess?: () => void;
  initialValues: FormValues;
  subscription?: { [key: string]: boolean };
  convertFormValuesToProductPatch: (
    formValues: FormValues
  ) => Swagger.Product$Patch;
  children: (
    props: FormRenderProps<FormValues & TranslationsFormValues, Partial<any>>
  ) => React.ReactNode;
  debug?: (params: any) => void;
  id?: string; // Use this if we want to submit form externally (eg. from button outside form)
};

const getInitialTranslationValues = (
  editingProduct: Swagger.Product | null,
  productTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  const sourceLanguage = editingProduct?.source_language ?? '';
  let translations: Translation[] = [];
  if (sourceLanguage && translationTargetLanguage) {
    const sourceFieldName = sourceLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = productTranslations.map((trans) => ({
      source: trans[sourceFieldName as keyof Swagger.Translation] as string,
      target: trans[
        translationFieldName as keyof Swagger.Translation
      ] as string,
    }));
  }

  return {
    translations,
  };
};

export function ProductEditorForm<FormValues>({
  debug,
  onSubmitStart,
  onSubmitSuccess,
  initialValues,
  subscription,
  convertFormValuesToProductPatch,
  children,
  id,
}: Props<FormValues>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const editingProduct = React.useContext(EditingProductContext);

  const productTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const sourceLanguage = editingProduct?.source_language ?? '';

  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );

  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...initialValues,
      ...getInitialTranslationValues(
        editingProduct,
        productTranslations,
        translationTargetLanguage
      ),
    }),
    [initialValues, productTranslations, translationTargetLanguage]
  );

  return (
    <Form
      keepDirtyOnReinitialize={true}
      onSubmit={async (values: FormValues & TranslationsFormValues) => {
        try {
          onSubmitStart?.();
          if (translationTargetLanguage) {
            await Promise.all([
              dispatch(
                updateProduct(
                  editingProduct?.id || '',
                  convertFormValuesToProductPatch(values)
                )
              ),
              dispatch(
                updateTranslations(
                  values.translations.map((translation) => ({
                    source_language: editingProduct?.source_language,
                    [sourceLanguage.toLowerCase()]: translation.source,
                    [translationTargetLanguage.toLowerCase()]:
                      translation.target,
                  }))
                )
              ),
            ]);
          } else {
            await dispatch(
              updateProduct(
                editingProduct?.id || '',
                convertFormValuesToProductPatch(values)
              )
            );
          }

          onSubmitSuccess?.();
        } catch (err) {
          debug?.(err);
          return { [FORM_ERROR]: t('Save Failed') };
        }
      }}
      decorators={[focusOnError]}
      debug={debug}
      initialValues={initialValuesWithTranslations}
      subscription={subscription}
      mutators={getArrayMutators()}
    >
      {(props) => {
        const sourceLanguage = editingProduct?.source_language;
        React.useEffect(() => {
          let translations: Translation[] = [];
          if (sourceLanguage && translationTargetLanguage) {
            const sourceFieldName = sourceLanguage.toLowerCase();
            const translationFieldName =
              translationTargetLanguage.toLowerCase();
            translations = productTranslations.map((trans) => ({
              source: trans[
                sourceFieldName as keyof Swagger.Translation
              ] as string,
              target: trans[
                translationFieldName as keyof Swagger.Translation
              ] as string,
            }));
          }

          props.form.change('translations', translations as any);
        }, [sourceLanguage, productTranslations, translationTargetLanguage]);

        return (
          <form id={id} onSubmit={props.handleSubmit}>
            {children(props)}
          </form>
        );
      }}
    </Form>
  );
}
