import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_E_TICKET_LOCATIONS_SUCCESS,
  FETCH_E_TICKET_LOCATIONS_FAILURE,
  FETCH_E_TICKET_LOCATIONS_REQUEST,
  DELETE_E_TICKET_LOCATION_FAILURE,
  DELETE_E_TICKET_LOCATION_REQUEST,
  DELETE_E_TICKET_LOCATION_SUCCESS,
  CREATE_E_TICKET_LOCATION_REQUEST,
  UPDATE_E_TICKET_LOCATION_FAILURE,
  UPDATE_E_TICKET_LOCATION_SUCCESS,
  UPDATE_E_TICKET_LOCATION_REQUEST,
  CREATE_E_TICKET_LOCATION_FAILURE,
  CREATE_E_TICKET_LOCATION_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { ETicketLocationParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchETicketLocationsRequest = () => ({
  type: FETCH_E_TICKET_LOCATIONS_REQUEST,
});

const fetchETicketLocationsSuccess = (response: any) => ({
  type: FETCH_E_TICKET_LOCATIONS_SUCCESS,
  response,
});

const fetchETicketLocationsFailure = (error: string) => ({
  type: FETCH_E_TICKET_LOCATIONS_FAILURE,
  error,
});

let fetchETicketLocationsCancel: () => void | null;
export const fetchETicketLocations =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchETicketLocationsCancel && fetchETicketLocationsCancel();
    dispatch(fetchETicketLocationsRequest());
    axios
      .get('/api/eticketlocations', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchETicketLocationsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchETicketLocationsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchETicketLocationsFailure('canceled'));
        else dispatch(fetchETicketLocationsFailure(error));
      });
  };

const deleteETicketLocationRequest = (id: string) => ({
  type: DELETE_E_TICKET_LOCATION_REQUEST,
  id,
});
const deleteETicketLocationSuccess = (response: any, id: string) => ({
  type: DELETE_E_TICKET_LOCATION_SUCCESS,
  response,
  id,
});
const deleteETicketLocationFailure = (error: string) => ({
  type: DELETE_E_TICKET_LOCATION_FAILURE,
  error,
});
export const deleteETicketLocation =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteETicketLocationRequest(id));
    axios
      .delete(`/api/eticketlocations/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteETicketLocationSuccess(result, id)))
      .catch((error) => dispatch(deleteETicketLocationFailure(error)));
  };

const createETicketLocationRequest = (
  newETicketLocation: ETicketLocationParams
) => ({
  type: CREATE_E_TICKET_LOCATION_REQUEST,
  newETicketLocation,
});
const createETicketLocationSuccess = (response: any) => ({
  type: CREATE_E_TICKET_LOCATION_SUCCESS,
  response,
});
const createETicketLocationFailure = (error: string) => ({
  type: CREATE_E_TICKET_LOCATION_FAILURE,
  error,
});

export const createETicketLocation =
  (newETicketLocation: ETicketLocationParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createETicketLocationRequest(newETicketLocation));
    axios
      .post('/api/eticketlocations', newETicketLocation, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createETicketLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createETicketLocationFailure(err.message));
      });
  };

const updateETicketLocationRequest = (
  id: string,
  patch: ETicketLocationParams
) => ({
  type: UPDATE_E_TICKET_LOCATION_REQUEST,
  id,
  patch,
});
const updateETicketLocationSuccess = (response: any) => ({
  type: UPDATE_E_TICKET_LOCATION_SUCCESS,
  response,
});
const updateETicketLocationFailure = (error: string) => ({
  type: UPDATE_E_TICKET_LOCATION_FAILURE,
  error,
});

export const updateETicketLocation =
  (id: string, patch: ETicketLocationParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateETicketLocationRequest(id, patch));
    axios
      .patch(`/api/eticketlocations/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateETicketLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateETicketLocationFailure(err.message));
      });
  };
