import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'react-final-form';

import { ZoomablePane } from 'client/components/ZoomablePane/ZoomablePane';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import {
  updateEquipmentAsset,
  calculateEquipmentAssetCellBlockMappings,
  clearEquipmentAssetCellBlockMappings,
  deleteEquipmentAsset,
} from 'client/actions/equipmentAssets';
import { EquipmentAsset } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

import { SelectPopupWindow } from './SelectPopupWindow';
import { BlockInstance } from './BlockInstance';
import { TitleInputForm, ColumnRowCountInputForm } from './parts';
import styles from './SeatEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  equipmentBlockInstances: {
    key: string;
    equipmentBlockId: string;
    startColumn: number;
    startRow: number;
  }[];
  id: string;
}

const getInitialValues = (
  equipmentAsset: EquipmentAsset | undefined
): FormValues => {
  return {
    title: equipmentAsset?.title ?? '',
    description: equipmentAsset?.description ?? '',
    columnCount: equipmentAsset?.column_count ?? 1,
    rowCount: equipmentAsset?.row_count ?? 1,
    equipmentBlockInstances:
      equipmentAsset?.equipment_block_instances?.map(
        (equipmentBlockInstance) => {
          return {
            key: equipmentBlockInstance.key ?? '',
            equipmentBlockId: equipmentBlockInstance.equipment_block_id ?? '',
            startColumn: equipmentBlockInstance.start_column ?? 0,
            startRow: equipmentBlockInstance.start_row ?? 0,
          };
        }
      ) ?? [],
    id: equipmentAsset?.id ?? '',
  };
};

const convertToSwagger = (
  values: FormValues,
  editingEquipmentAsset: EquipmentAsset | undefined
): EquipmentAsset => {
  return {
    title: values.title,
    description: values.description,
    column_count: values.columnCount,
    row_count: values.rowCount,
    equipment_block_instances: editingEquipmentAsset?.equipment_block_instances,
    id: values.id,
  };
};

interface Props {
  equipmentAsset: EquipmentAsset | undefined;
  draggingEquipmentBlockId?: string | null;
}

export const AssetPane = ({
  equipmentAsset,
  draggingEquipmentBlockId,
}: Props) => {
  const dispatch = useDispatch();
  const [
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  ] = React.useState<string[]>([]);
  const [editingEquipmentAsset, setEditingEquipmentAsset] = React.useState<
    EquipmentAsset | undefined
  >(undefined);

  const lastEquipmentAsset = useSelector(
    (state: ReduxState) => state.equipmentAssets.lastCalculatedEquipmentAsset
  );
  const [draggingOverPosition, setDraggingOverPosition] = React.useState<{
    row: number;
    column: number;
  } | null>(null);

  React.useEffect(() => {
    if (equipmentAsset) {
      dispatch(clearEquipmentAssetCellBlockMappings());
      setEditingEquipmentAsset(equipmentAsset);
      setDraggingOverPosition(null);
      setSelectedEquipmentBlockInstanceKeys([]);
    }
  }, [equipmentAsset]);

  React.useEffect(() => {
    if (lastEquipmentAsset) {
      setEditingEquipmentAsset(lastEquipmentAsset);
    }
  }, [lastEquipmentAsset]);

  if (!equipmentAsset) {
    return <div className={clsx(styles['seats__main'])}></div>;
  }

  return (
    <div className={clsx(styles['seats__main'])}>
      <Form<FormValues>
        onSubmit={(values: FormValues) => {
          dispatch(
            updateEquipmentAsset(
              equipmentAsset?.id ?? '',
              convertToSwagger(values, editingEquipmentAsset)
            )
          );
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting, pristine, form }) => {
          React.useEffect(() => {
            if (equipmentAsset) {
              form.setConfig('keepDirtyOnReinitialize', false);
              form.reset(getInitialValues(equipmentAsset));
              form.setConfig('keepDirtyOnReinitialize', true);
            }
          }, [equipmentAsset]);

          return (
            <form onSubmit={handleSubmit}>
              <AssetPaneForm
                equipmentAsset={editingEquipmentAsset}
                submitting={submitting}
                draggingEquipmentBlockId={draggingEquipmentBlockId}
                draggingOverPosition={draggingOverPosition}
                onDraggingOverPositionChange={(position) => {
                  setDraggingOverPosition(position);
                }}
                selectedEquipmentBlockInstanceKeys={
                  selectedEquipmentBlockInstanceKeys
                }
                setSelectedEquipmentBlockInstanceKeys={(keys) => {
                  setSelectedEquipmentBlockInstanceKeys(keys);
                }}
                onEditCancelClick={() => {
                  dispatch(clearEquipmentAssetCellBlockMappings());
                  setEditingEquipmentAsset(equipmentAsset);
                  setDraggingOverPosition(null);
                  setSelectedEquipmentBlockInstanceKeys([]);
                }}
                changed={Boolean(lastEquipmentAsset) || !pristine}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

const AssetPaneForm = ({
  equipmentAsset,
  submitting,
  draggingEquipmentBlockId,
  draggingOverPosition,
  onDraggingOverPositionChange,
  selectedEquipmentBlockInstanceKeys,
  setSelectedEquipmentBlockInstanceKeys,
  changed,
  onEditCancelClick,
}: {
  equipmentAsset: EquipmentAsset | undefined;
  submitting: boolean;
  draggingEquipmentBlockId?: string | null;
  draggingOverPosition: { row: number; column: number } | null;
  onDraggingOverPositionChange: (
    position: {
      row: number;
      column: number;
    } | null
  ) => void;
  selectedEquipmentBlockInstanceKeys: string[];
  setSelectedEquipmentBlockInstanceKeys: (keys: string[]) => void;
  changed: boolean;
  onEditCancelClick: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;
  const [popupPosition, setPopupPosition] = React.useState<{
    left: number;
    top: number;
  } | null>(null);

  React.useEffect(() => {
    setPopupPosition(null);
  }, [equipmentAsset?.id]);

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const cellWidth = 60;
  const cellHeight = 60;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const renderLayout = () => {
    const cells = [];
    const placedEquipmentBlockInstanceKeys: string[] = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        cells.push(
          <BlockInstance
            i={i}
            j={j}
            rowCount={values.rowCount}
            columnCount={values.columnCount}
            equipmentCellBlockMappings={
              equipmentAsset?.equipment_cell_block_mappings ?? []
            }
            selectedEquipmentBlockInstanceKeys={
              selectedEquipmentBlockInstanceKeys
            }
            placedEquipmentBlockInstanceKeys={placedEquipmentBlockInstanceKeys}
            equipmentBlockInstances={
              equipmentAsset?.equipment_block_instances ?? []
            }
            draggingEquipmentBlockId={draggingEquipmentBlockId}
            draggingOverPosition={draggingOverPosition}
            onSelectedEquipmentBlockInstanceKeysChange={
              setSelectedEquipmentBlockInstanceKeys
            }
            onDraggingOverPositionChange={onDraggingOverPositionChange}
            onEquipmentBlockInstancesChange={() => {
              if (!draggingEquipmentBlockId) {
                return;
              }
              const newEquipmentAsset: EquipmentAsset = {
                ...equipmentAsset,
                equipment_block_instances: [
                  ...(equipmentAsset?.equipment_block_instances ?? []),
                  {
                    key: uuidv4(),
                    equipment_block_id: draggingEquipmentBlockId,
                    start_column: j,
                    start_row: i,
                  },
                ],
              };
              dispatch(
                calculateEquipmentAssetCellBlockMappings(
                  convertToSwagger(values as FormValues, newEquipmentAsset)
                )
              );
            }}
            onPopupPositionChange={setPopupPosition}
            equipmentAsset={equipmentAsset}
          />
        );
      }
    }

    return cells;
  };

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <TitleInputForm />

        <ColumnRowCountInputForm />

        <Box mt={2} mr={2}>
          <Button
            loading={submitting}
            size="middle"
            style="blue"
            type="submit"
            disabled={!changed}
          >
            {t('Save')}
          </Button>
        </Box>

        <Box mt={2} mr={2}>
          <Button
            size="middle"
            style="gray"
            onClick={() => {
              onEditCancelClick();
              setPopupPosition(null);
            }}
          >
            変更を破棄
          </Button>
        </Box>

        <Box mt={8} mr={2}>
          <Button
            size="middle"
            style="red"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            {t('Delete')}
          </Button>

          <DeleteConfirmModal
            header={t('Delete Asset')}
            content={t('Are you sure you want to delete "{{assetTitle}}"?', {
              assetTitle: equipmentAsset?.title,
            })}
            onConfirm={async () => {
              await dispatch(deleteEquipmentAsset(equipmentAsset?.id ?? ''));
              setShowDeleteConfirm(false);
            }}
            onClose={() => setShowDeleteConfirm(false)}
            open={showDeleteConfirm}
            insertRoot={true}
          />
        </Box>
      </div>

      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <div className={clsx(baseStyles['base-form-box'])}>
          <div className={clsx(baseStyles['base-form-box__header'])}>
            {t('Preview')}
            {changed && (
              <span style={{ color: 'red' }}>({t('Not saved')})</span>
            )}
          </div>
          <ZoomablePane>
            {({
              targetRef,
              wrapperRef,
              onZoomInClick,
              onZoomOutClick,
              onReseatScale,
            }) => {
              React.useEffect(() => {
                onReseatScale?.();
              }, [equipmentAsset]);

              React.useEffect(() => {
                const handleOutsideClick = ({ target }: Event) => {
                  if (
                    target instanceof Node &&
                    !targetRef?.current?.contains(target) &&
                    wrapperRef?.current?.contains(target)
                  ) {
                    onDraggingOverPositionChange(null);
                    setSelectedEquipmentBlockInstanceKeys([]);
                  }
                };

                window.document.addEventListener(
                  'mousedown',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
                window.document.addEventListener(
                  'touchstart',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
                return () => {
                  window.document.removeEventListener(
                    'mousedown',
                    handleOutsideClick,
                    {
                      capture: true,
                    }
                  );
                  window.document.removeEventListener(
                    'touchstart',
                    handleOutsideClick,
                    {
                      capture: true,
                    }
                  );
                };
              }, []);
              return (
                <div className={styles['seatsAction']}>
                  <div className={clsx(styles['seatsAction__info'])}>
                    <div
                      className={clsx(
                        styles['seatsAction__btns'],
                        styles['reload']
                      )}
                    >
                      <a
                        onClick={() => {
                          onZoomInClick?.();
                        }}
                      >
                        +
                      </a>
                      <a
                        onClick={() => {
                          onZoomOutClick?.();
                        }}
                      >
                        -
                      </a>
                    </div>
                  </div>
                  <div className={styles['seatsAction__cell']}>
                    <div className={styles['content']} ref={wrapperRef}>
                      <ul
                        style={{
                          gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
                          gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
                          width: layoutWidth,
                          height: layoutHeight,
                        }}
                        ref={targetRef}
                      >
                        {renderLayout()}
                      </ul>
                    </div>
                  </div>
                  <SelectPopupWindow
                    title={t('Edit selected blocks')}
                    open={selectedEquipmentBlockInstanceKeys.length !== 0}
                    onDeleteClick={() => {
                      const newEquipmentAsset: EquipmentAsset = {
                        ...equipmentAsset,
                        equipment_block_instances: [
                          ...(
                            equipmentAsset?.equipment_block_instances ?? []
                          ).filter((instance: any) => {
                            return !selectedEquipmentBlockInstanceKeys.includes(
                              instance.key
                            );
                          }),
                        ],
                      };
                      dispatch(
                        calculateEquipmentAssetCellBlockMappings(
                          convertToSwagger(
                            values as FormValues,
                            newEquipmentAsset
                          )
                        )
                      );
                    }}
                    position={popupPosition}
                  />
                </div>
              );
            }}
          </ZoomablePane>
        </div>
      </div>
    </div>
  );
};
