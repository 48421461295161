import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { activeUserSelector } from 'client/reducers/user';
import { updateReservation } from 'client/actions/reservations';
import { FieldsFormV2 as FieldsForm } from 'client/components/FieldsFormV2';
import { getFieldResponseErrors } from 'client/libraries/util/coreutil';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Modal } from 'client/components/Modal/Modal';
import { TextArea, Button } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import type { Guest, Field, ReservationPatch } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  onUpdate?: (reservation: ReservationPatch) => void;
  reservationID: string;
  trigger?: React.ReactElement<'a'>;
};

export const FreeFormatReservationFieldResponseUpdateModal = ({
  onUpdate,
  reservationID,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const reservation = useSelector(
    (state: ReduxState) => state.reservations.byID[reservationID]
  );
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [newPerBookingResponses, setNewPerBookingResponses] = React.useState<
    { key?: string; response?: string }[]
  >([...(reservation?.field_responses || [])]);
  const [newGuests, setNewGuests] = React.useState<Guest[]>([
    ...(reservation?.guests || []),
  ]);
  const [
    lastSubmissionValidationErrorMap,
    setLastSubmissionValidationErrorMap,
  ] = React.useState<{ [key: string]: string }>({});
  const [agentNotes, setAgentNotes] = React.useState<string>(
    reservation?.agent_notes || ''
  );
  const [supplierNotes, setSupplierNotes] = React.useState<string>(
    reservation?.supplier_notes || ''
  );

  React.useEffect(() => {
    setNewPerBookingResponses([...(reservation?.field_responses || [])]);
    setNewGuests([...(reservation?.guests || [])]);
    setSupplierNotes(reservation?.supplier_notes || '');
  }, [reservation]);

  const reservationFormFields: Field[] = [
    {
      key: 'family_name',
      prompt: t('Family Name'),
      format: 'alpha-name',
      required: 'WHEN_BOOKING',
      type: 'PER_BOOKING',
    },
    {
      key: 'given_name',
      prompt: t('Given Name'),
      format: 'alpha-name',
      required: 'WHEN_BOOKING',
      type: 'PER_BOOKING',
    },
    {
      key: 'email',
      prompt: t('Email'),
      format: 'email',
      required: 'OPTIONAL',
      type: 'PER_BOOKING',
    },
  ];

  const getFormInputErrors = () => {
    if (activeUser?.organization_type === 'SUPPLIER') {
      return {};
    }

    let errorMap: Record<string, string> = {};

    const perBookingFields = reservationFormFields;
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newPerBookingResponses || [],
        perBookingFields,
        t
      ),
    };
    return errorMap;
  };

  const getNotesInput = () => {
    if (activeUser?.organization_type === 'AGENT') {
      return (
        <TextArea
          label={t('Remarks')}
          value={agentNotes}
          onChange={(e) => {
            setAgentNotes((e.target as HTMLInputElement).value);
          }}
        />
      );
    } else if (activeUser?.organization_type === 'SUPPLIER') {
      return (
        <TextArea
          label={t('Replies')}
          value={supplierNotes}
          onChange={(e) => {
            setSupplierNotes((e.target as HTMLInputElement).value);
          }}
        />
      );
    }
  };

  const currentValidationErrorMap = getFormInputErrors();
  const errorMap: Record<string, string> = {};

  // Set keys for all fields that were invalid in the last submission.
  Object.keys(lastSubmissionValidationErrorMap).forEach((errKey) => {
    errorMap[errKey] = currentValidationErrorMap[errKey];
  });

  const perBookingFields = reservationFormFields;

  return (
    <Modal
      title={t('Update Field Response')}
      trigger={trigger}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => setShowModal(true)}
    >
      <Modal.Content>
        {loading ? (
          <ModalLoader />
        ) : (
          <>
            <FieldsForm
              fields={perBookingFields}
              errorMap={errorMap}
              getFieldValue={(key) => {
                const r = newPerBookingResponses.find((r) => r.key === key);
                return (r && r.response) || '';
              }}
              onFieldChange={(key, value) =>
                setNewPerBookingResponses([
                  ...newPerBookingResponses.filter((r) => r.key !== key),
                  {
                    key,
                    response: value,
                  },
                ])
              }
              mode="INPUT"
              type="EDIT"
            />
          </>
        )}

        <hr />

        <div className={clsx(baseStyles['base-text-divider'])} />

        <ReservationActorInputForm />
        <br />

        {getNotesInput()}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setNewPerBookingResponses([...(reservation.field_responses || [])]);
            setNewGuests([...reservation.guests]);
          }}
        >
          {t('Clear Changes')}
        </Button.Cancel>
        <Button.Submit
          onClick={async () => {
            if (Object.keys(currentValidationErrorMap).length > 0) {
              setLastSubmissionValidationErrorMap(currentValidationErrorMap);
            } else {
              if (
                newGuests.length > 0 &&
                newGuests[0].field_responses
                  ?.map((f) => f.key)
                  .includes('full_name')
              ) {
                const givenName =
                  newPerBookingResponses.find((f) => f.key === 'given_name')
                    ?.response || '';
                const familyName =
                  newPerBookingResponses.find((f) => f.key === 'family_name')
                    ?.response || '';
                const fullName = [givenName, familyName].join(' ');
                newGuests[0].field_responses = [
                  ...(newGuests[0].field_responses || []).filter(
                    (f) => f.key !== 'full_name'
                  ),
                  {
                    key: 'full_name',
                    response: fullName,
                  },
                ];
              }

              await dispatch(
                updateReservation(reservation.id, {
                  field_responses: [...newPerBookingResponses],
                  guests: [...newGuests],
                  agent_notes: agentNotes,
                  supplier_notes: supplierNotes,
                })
              );
              onUpdate && onUpdate(reservation);
              setShowModal(false);
            }
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
