import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { fetchNewsletterSegments } from 'client/actions/newsletterSegment';
import {
  Button,
  Input,
  MultiSelect,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import {
  createMarketingAutomationCampaign,
  fetchMarketingAutomationCampaign,
  updateMarketingAutomationCampaign,
} from 'client/actions/marketingAutomationCampaigns';
import { fetchMarketingAutomationContents } from 'client/actions/marketingAutomationContents';
import { fetchMarketingAutomationTriggerConditions } from 'client/actions/marketingAutomationTriggerConditions';
import { getValidators } from 'shared/libraries/validate/validator';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { Message } from 'client/components/Message/Message';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';

import {
  FormValues,
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
} from './formValues';

export const MarketingAutomationCampaignEditor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const existingCampaign = useSelector((state: ReduxState) =>
    state.marketingAutomationCampaigns.all.find((n) => n.id === id)
  );
  React.useEffect(() => {
    dispatch(fetchMarketingAutomationContents());
    dispatch(fetchNewsletterSegments());
    dispatch(fetchMarketingAutomationTriggerConditions());
  }, []);

  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const triggers = useSelector(
    (state: ReduxState) => state.marketingAutomationTriggerConditions.all
  );
  const contents = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.all
  );

  const { required } = getValidators(t);

  React.useEffect(() => {
    if (id && !existingCampaign) {
      dispatch(fetchMarketingAutomationCampaign(id));
    }
  }, [id, existingCampaign]);

  const initialValues = React.useMemo((): FormValues => {
    if (existingCampaign) {
      return convertSwaggerToFormValues(existingCampaign);
    }

    return {
      title: '',
      status: 'DRAFT',
      segmentIds: [],
      triggerId: '',
      contentId: '',
      sendOnce: false,
    };
  }, [existingCampaign]);

  if (id && !existingCampaign) {
    return <Loading />;
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        if (id) {
          await dispatch(
            updateMarketingAutomationCampaign(
              id,
              convertFormValuesToSwagger(values)
            )
          );
        } else {
          await dispatch(
            createMarketingAutomationCampaign(
              convertFormValuesToSwagger(values)
            )
          );
        }
      }}
    >
      {({ handleSubmit, submitSucceeded, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={baseStyles['base-main__body__box']}>
            <div className={baseStyles['base-main__body__box__body']}>
              <Box mb={2}>
                <Link to="/marketingautomation/campaigns">
                  <BackArrow />
                </Link>
              </Box>
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Title')}</th>
                      <td>
                        <Field name="title">
                          {({ input }) => (
                            <Input label={t('Campaign Name')} {...input} />
                          )}
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Parameters')}</th>
                      <td>
                        <EnumRadioButtonGroup
                          name="status"
                          options={[
                            { value: 'DRAFT', label: t('Draft') },
                            { value: 'ACTIVE', label: t('Active') },
                          ]}
                        />
                        <Field name="segmentIds">
                          {({ input }) => (
                            <MultiSelect
                              label={t('Segments')}
                              search
                              options={segments.map((s) => ({
                                value: s.id ?? '',
                                text: s.name ?? '',
                              }))}
                              onChange={({ value }) => input.onChange(value)}
                              selectedValues={input.value}
                            />
                          )}
                        </Field>
                        <Box mt={2} mb={2}>
                          <Field name="sendOnce">
                            {({ input }) => (
                              <ToggleButton
                                label={t('One-time Send When Saved')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                        </Box>
                        {!values.sendOnce && (
                          <Field name="triggerId" validate={required}>
                            {({ input }) => (
                              <Select
                                label={t('Trigger/Condition')}
                                search
                                options={triggers.map((t) => ({
                                  value: t.id ?? '',
                                  text: t.title ?? '',
                                }))}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                                value={input.value}
                              />
                            )}
                          </Field>
                        )}
                        <Field name="contentId" validate={required}>
                          {({ input }) => (
                            <Select
                              label={t('Content')}
                              search
                              options={contents.map((c) => ({
                                value: c.id ?? '',
                                text: c.title ?? '',
                              }))}
                              onChange={(e, { value }) => input.onChange(value)}
                              value={input.value}
                            />
                          )}
                        </Field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>
              {submitSucceeded && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && <Message error header={t('Save Failed')} />}
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  loading={submitting}
                  size="small"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
