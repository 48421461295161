import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { manifestCustomizedColumnNamesSelector } from 'client/reducers/manifestSettings';
import {
  getColumnHeaderText,
  useAllManifestColumns,
} from 'client/reducers/manifestDefaults';
import 'react-table/react-table.css';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { Button } from 'client/components/v3/Common/Button';
import {
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import { EditColumnNameTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/EditColumnNameTable';
import { updateManifestCustomizedColumnNames } from 'client/actions/manifestSettings';

type Props = {
  isActive?: boolean;
};

export const CustomizedColumnName = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const [customizedColumnNames, setCustomizedColumnNames] = useState<
    ManifestCustomizedColumnName[]
  >([]);

  const currentCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  useEffect(() => {
    setCustomizedColumnNames(currentCustomizedColumnNames);
  }, [currentCustomizedColumnNames]);

  // Close edit mode if active tab is changed
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  const columns = useMemo(
    () => [
      {
        Header: t('Default name'),
        accessor: 'column',
        Cell: (cellInfo: any) =>
          getColumnHeaderText(cellInfo.original.column, t),
        width: 'long',
      },
      {
        Header: t('Customized name'),
        accessor: 'name',
        width: 'long',
      },
    ],
    [t]
  );

  const allManifestColumns = useAllManifestColumns();
  const targetManifestColumnNames = useMemo(() => {
    const columns: ReservationColumn[] = [];

    for (const col of allManifestColumns.filter(
      (c) => c !== 'RESERVATION_FORM_FIELDS' && c !== 'RESERVATION_FORM_CUSTOM'
    )) {
      if (col === 'TRANSPORT_ROUTE') {
        columns.push(
          'TRANSPORT_DEPARTURE_TIME',
          'TRANSPORT_DEPARTURE_LOCATION',
          'TRANSPORT_ARRIVAL_TIME',
          'TRANSPORT_ARRIVAL_LOCATION'
        );
      } else {
        columns.push(col);
      }
    }

    return columns;
  }, [allManifestColumns]);

  const mergedColumnNames: ManifestCustomizedColumnName[] =
    targetManifestColumnNames.map((column) => {
      // Find the corresponding name from customizedColumnNames
      const matchedCustomizedColumn = customizedColumnNames.find(
        (customizedColumn) => customizedColumn.column === column
      );

      // If there is a matching name, use it, otherwise leave the name blank
      return {
        column: column,
        name: matchedCustomizedColumn ? matchedCustomizedColumn.name : '',
      };
    });

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
      ref={ref}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Column names')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          {editMode && (
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
          )}
          <Button
            text={editMode ? t('Save') : t('Edit')}
            onClick={() => {
              setEditMode(!editMode);
              if (editMode) {
                dispatch(
                  updateManifestCustomizedColumnNames(
                    customizedColumnNames.filter((c) => c.name !== '')
                  )
                );
              }
            }}
            color={editMode ? 'primary' : 'white'}
            iconBeforeText={
              editMode ? undefined : (
                <i className="c-icon-outline-general-edit-05"></i>
              )
            }
          />
        </div>
      </div>
      <>
        {!editMode && (
          <ManifestCustomizeCustomTable
            itemName={t('Customized name')}
            items={_.sortBy(mergedColumnNames, (p) => p.column)}
            columns={columns as any}
          />
        )}
        {editMode && (
          <EditColumnNameTable
            initialCustomizedColumnNames={_.sortBy(
              customizedColumnNames,
              (p) => p.column
            )}
            setCustomizedColumnNames={setCustomizedColumnNames}
          />
        )}
      </>

      {/* TODO: figure out how to implement fixed footer later */}
      {editMode && (
        <div
          className={clsx(
            styles['p-manifests__fixed'],
            styles['is-active'] // showFixed &&
          )}
        >
          <div className={styles['p-manifests__fixed__main']}></div>
          <div className={styles['p-manifests__fixed__actions']}>
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
            <Button
              text={t('Save')}
              onClick={() => {
                setEditMode(!editMode);
                if (editMode) {
                  dispatch(
                    updateManifestCustomizedColumnNames(
                      customizedColumnNames.filter((c) => c.name !== '')
                    )
                  );
                }
              }}
              color={'primary'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizedColumnName;
