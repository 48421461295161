import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './calendar-override.css';
import moment from 'moment-timezone';

import { ReduxState } from 'client/reducers';

import styles from './Calendar.module.css';

type DateRangeInputProps = {
  label?: string;
  onChange: (dateRange: [string | null, string | null]) => void;
  dateFrom: string;
  dateTo: string;
};

export const DateRangeInput = ({
  label,
  onChange,
  dateFrom,
  dateTo,
}: DateRangeInputProps) => {
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dateFrom ? moment(dateFrom).toDate() : null,
    dateTo ? moment(dateTo).toDate() : null,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const isMouseClick = event.type === 'mousedown';
      const isTouchStart = event.type === 'touchstart';

      const target = isMouseClick
        ? (event as MouseEvent).target
        : (event as TouchEvent).target;

      if (isMouseClick || isTouchStart) {
        // Check if the target element is outside of the wrapper element
        if (
          fieldSetRef.current &&
          !fieldSetRef.current.contains(target as Node)
        ) {
          setIsClicked(false);
        }
      }
    };

    // Add event listeners to document for click outside
    document.addEventListener('mousedown', handleClickOutside, {
      capture: true,
    });
    document.addEventListener('touchstart', handleClickOutside, {
      capture: true,
    });

    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      });
      document.removeEventListener('touchstart', handleClickOutside, {
        capture: true,
      });
    };
  }, []);

  const [startDate, endDate] = dateRange;

  // Reset dates if values have been cleared by calling component
  useEffect(() => {
    if (!dateFrom && !dateTo) {
      setDateRange([null, null]);
    }
  }, [dateFrom, dateTo]);

  // Notify the calling component of changed dates
  useEffect(() => {
    const formattedStartDate = startDate
      ? moment(startDate).format('YYYY-MM-DD')
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format('YYYY-MM-DD')
      : null;
    onChange([formattedStartDate, formattedEndDate]);
  }, [startDate, endDate]);

  const dateFormatCalendar = locale === 'ja' ? 'yyyy年MM月' : undefined;

  const CustomInput = (
    <label>
      {label && (
        <legend className={styles['c-calendar__body__title']}>{label}</legend>
      )}
      <div className={styles['c-calendar__body__selected']}>
        <input
          name={`date-range-input-${label}`}
          type="text"
          value={`${dateFrom ? dateFrom : ''} ${dateFrom ? '~' : ''} ${
            dateTo ? dateTo : ''
          }`}
          readOnly
          ref={inputRef}
        ></input>
      </div>
    </label>
  );

  return (
    <fieldset
      className={clsx(
        styles['c-calendar'],
        isHovered && styles['is-hover'],
        isClicked && styles['is-active'],
        // Treat as if fieldset is clicked whenever date is selected manually or set from outside component
        (isClicked ||
          inputRef.current?.value.trim() !== '' ||
          dateRange[0] !== null ||
          dateRange[1] !== null ||
          (dateFrom && dateTo)) &&
          styles['is-input']
      )}
      ref={fieldSetRef}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsClicked(true)}
    >
      <div
        className={clsx(
          styles['c-calendar__body'],
          'react-datepicker-override'
        )}
      >
        <DatePicker
          wrapperClassName={styles['c-calendar__wrapper']}
          customInput={CustomInput}
          selected={startDate}
          dateFormat="yyyy/MM/dd"
          onChange={(update: [Date | null, Date | null]) => {
            setDateRange(update);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          isClearable={true}
          locale={locale}
          dateFormatCalendar={dateFormatCalendar}
          open={isClicked} // Use this to open calendar if clicked outside of input field
        />
      </div>
    </fieldset>
  );
};
