import moment from 'moment-timezone';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useBounds } from 'client/hooks/useBounds';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getPromotionSummaryTitleText } from 'client/pages/ProductDetails/getPromotionTitleText';
import { printPriceTitle } from 'client/libraries/util/productShape';
import type { ProductShape } from 'client/libraries/util/productShape';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ShareButtons } from 'client/components/Sns/ShareButtons';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getBookingWidgetPmpUrlBase } from 'client/libraries/util/getBookingWidgetPmpUrlBase';
import PromoIcon from 'client/images/ic_special.svg';
import baseStyles from 'client/base.module.css';
import { getDaysOfWeekText } from 'client/libraries/util/getDaysOfWeekText';

import styles from './PromotionDetails.module.css';

type Props = {
  product: ProductShape;
  contentLanguage?: string;
};
export const PromotionDetails = ({ product, contentLanguage }: Props) => {
  const { i18n, t } = useTranslation();
  const [promoListRef, promoListRefBounds] = useBounds<HTMLUListElement>();
  const [viewMore, setViewMore] = React.useState<boolean>(false);
  const locale = i18n ? i18n.language : '';
  const shouldTruncate = promoListRefBounds.height > 600;
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const hasBookingWidgetPmp = Boolean(
    activeUserOrganization?.booking_widget_api_key
  );
  const urlBase = getBookingWidgetPmpUrlBase(activeUserOrganization);

  if (product.promotionSummaries.length === 0) {
    return null;
  }

  return (
    <div className={baseStyles['base-padding-top-16']}>
      <div
        className={clsx(
          styles['c-promotion'],
          shouldTruncate && (viewMore ? styles['is-open'] : styles['is-close'])
        )}
      >
        <ul ref={promoListRef} className={styles['c-promotion__list']}>
          {product.promotionSummaries.map((promotion, idx) => {
            const participationDaysOfWeekText = getDaysOfWeekText(
              promotion.participationDaysOfWeek,
              t
            );

            return (
              <li className={styles['c-promotion__list__item']} key={idx}>
                <div className={styles['c-promotion__list__item__header']}>
                  <p
                    className={styles['c-promotion__list__item__header__label']}
                  >
                    <img alt="special" src={PromoIcon} />
                    {t('Campaign')}
                  </p>
                  <p className={styles['c-promotion__list__item__header__ttl']}>
                    {getPromotionSummaryTitleText(promotion, t, locale)}
                  </p>
                  {hasBookingWidgetPmp && (
                    <ShareButtons
                      url={`${urlBase}/products/${product.id}?promo=${
                        promotion.id
                      }&lng=${convertToBookingWidgetUrlLangCode(
                        contentLanguage || locale
                      )}`}
                      locale={locale}
                      productName={product.name || ''}
                    />
                  )}
                </div>
                <div className={styles['c-promotion__list__item__body']}>
                  <div
                    className={styles['c-promotion__list__item__body__period']}
                  >
                    {promotion.participationDateRanges.length > 0 && (
                      <p className={styles['maincolor-txt']}>
                        {t('Participation Date: {{participationDate}}', {
                          participationDate: `${promotion.participationDateRanges
                            .map(
                              (dateRange) =>
                                `${moment(dateRange.startDate)
                                  .locale(locale)
                                  .format('ll')} ~ ${moment(dateRange.endDate)
                                  .locale(locale)
                                  .format('ll')}`
                            )
                            .join('; ')}${
                            participationDaysOfWeekText
                              ? ` (${participationDaysOfWeekText})`
                              : ''
                          }`,
                        })}
                      </p>
                    )}
                    {(promotion.bookedDateLocalFrom ||
                      promotion.bookedDateLocalTo) && (
                      <p className={styles['maincolor-txt']}>
                        {t(
                          'Booked Date: {{bookedDateFrom}} ~ {{bookedDateTo}}',
                          {
                            bookedDateFrom: moment(
                              promotion.bookedDateLocalFrom
                            )
                              .locale(locale)
                              .format('ll'),
                            bookedDateTo: moment(promotion.bookedDateLocalTo)
                              .locale(locale)
                              .format('ll'),
                          }
                        )}
                      </p>
                    )}
                  </div>
                  <ul
                    className={styles['c-promotion__list__item__body__target']}
                  >
                    {promotion.prices
                      .filter(
                        (price) =>
                          price.amountAfterChargeOrDiscount !== price.amount
                      )
                      .map((price) => {
                        return (
                          <li
                            className={
                              styles[
                                'c-promotion__list__item__body__target__item'
                              ]
                            }
                            key={price.unitTitle}
                          >
                            <p
                              className={
                                styles[
                                  'c-promotion__list__item__body__target__item__ttl'
                                ]
                              }
                            >
                              {printPriceTitle(price, t)}
                            </p>
                            <div
                              className={
                                styles[
                                  'c-promotion__list__item__body__target__item__main'
                                ]
                              }
                            >
                              <div
                                className={
                                  styles[
                                    'c-promotion__list__item__body__target__item__main__discountprice'
                                  ]
                                }
                              >
                                {(price.deltaPercent || price.deltaAmount) &&
                                  (price.deltaType === 'CHARGE' ? (
                                    <p className={styles['percent']}>
                                      {t('+{{charge}}', {
                                        charge:
                                          price.deltaPercent ||
                                          formattedCurrencyAmount(
                                            price.deltaAmount
                                          ),
                                      })}
                                    </p>
                                  ) : (
                                    <p className={styles['percent']}>
                                      {t('{{discount}} OFF', {
                                        discount:
                                          price.deltaPercent ||
                                          formattedCurrencyAmount(
                                            price.deltaAmount
                                          ),
                                      })}
                                    </p>
                                  ))}
                                <p className={styles['price']}>
                                  {formattedCurrencyAmount(
                                    price.amountAfterChargeOrDiscount
                                  )}
                                </p>
                              </div>
                              {price.amountAfterChargeOrDiscount !==
                                price.amount && (
                                <p
                                  className={
                                    styles[
                                      'c-promotion__list__item__body__target__item__main__normalprice'
                                    ]
                                  }
                                >
                                  {formattedCurrencyAmount(price.amount)}
                                </p>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
        {shouldTruncate && (
          <div
            className={clsx(
              styles['c-promotion__more'],
              shouldTruncate &&
                (viewMore ? styles['is-open'] : styles['is-close'])
            )}
          >
            <button
              type="button"
              className={clsx(
                styles['c-promotion__more__btn'],
                styles['maincolor-txt'],
                styles['maincolor-border']
              )}
              onClick={() => setViewMore(!viewMore)}
            >
              {viewMore ? t('Show Less') : t('Show More')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
