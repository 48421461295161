import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { fetchPageOfReviews, fetchReviews } from 'client/actions/reviews';
import {
  SearchFormValues,
  getReviewStatusText,
} from 'client/libraries/util/review';
import { activeUserSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { ReviewCard } from 'client/pages/ProductDetails/ProductReviewsPane/ReviewCard';
import { ReviewSearchForm } from 'client/pages/ProductDetails/ProductReviewsPane/ReviewSearchForm';
import { ReviewsSummary } from 'client/pages/ProductDetails/ProductReviewsPane/ReviewsSummary';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import styles from 'client/pages/ProductDetails/ProductReviewsPane/ProductReviewsPane.module.css';
import { ReviewStatus } from 'shared/models/swagger';

const defaultPageSize = 30;

export const ReviewSearch = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // updateCount is used to trigger refetch when a record is updated.
  const [updateCount, setUpdateCount] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState<ReviewStatus>('PENDING');
  const [offset, setOffset] = React.useState(0);
  const [searchFormValues, setSearchFormValues] =
    React.useState<SearchFormValues>({
      ratings: [],
      attributions: [],
      monthStart: 1,
      monthEnd: 12,
      language: undefined,
      statuses: [],
      productIds: [],
    });
  React.useEffect(() => {
    setOffset(0);
    dispatch(
      fetchReviews({
        statuses: [activeTab],
        productIds: searchFormValues.productIds,
        language: i18n.language,
        ratings: searchFormValues.ratings,
        languagesFilter: searchFormValues.language
          ? ([searchFormValues.language] as any)
          : undefined,
        attributions: searchFormValues.attributions,
        months: _.range(
          searchFormValues.monthStart,
          searchFormValues.monthEnd + 1
        ),
        pageSize: defaultPageSize + 1,
      })
    );
  }, [updateCount, searchFormValues, activeTab, i18n.language]);

  const fetchNextPage = React.useCallback(() => {
    const newOffset = offset + defaultPageSize;
    const months = _.range(
      searchFormValues.monthStart,
      searchFormValues.monthEnd + 1
    );

    (async () => {
      await dispatch(
        fetchPageOfReviews({
          language: i18n.language,
          statuses: [activeTab],
          ratings: searchFormValues.ratings,
          languagesFilter: searchFormValues.language
            ? ([searchFormValues.language] as any)
            : undefined,
          attributions: searchFormValues.attributions,
          months,
          offset: newOffset,
          pageSize: defaultPageSize + 1,
        })
      );
      setOffset(newOffset);
    })();
  }, [searchFormValues, offset, i18n.language, activeTab]);

  const reviews = useSelector((state: ReduxState) => state.reviews.all) || [];
  const loading = useSelector(
    (state: ReduxState) => state.reviews.fetchReviewsLoading
  );
  const activeUser = useSelector(activeUserSelector);
  const lastFetchedPage = useSelector(
    (state: ReduxState) => state.reviews.lastFetchedPage
  );

  const showSeeMoreButton =
    (reviews?.length ?? 0) > 0 &&
    (lastFetchedPage?.length ?? 0) > defaultPageSize;

  return (
    <>
      <div
        className={styles['c-review']}
        style={{
          paddingTop: '8px',
        }}
      >
        <div className={styles['c-review__summary']}>
          <ReviewsSummary />
          <ReviewSearchForm
            onSearchFormValuesChange={setSearchFormValues}
            searchFormValues={searchFormValues}
            showProductFilter
          />
        </div>
        <div className={styles['c-review__list']}>
          <p
            className={styles['c-review__ttl']}
            style={{
              borderBottom: 'none',
            }}
          >
            {t('Customer Reviews')}
          </p>
          <div className={clsx(componentStyles['c-tab-box'])}>
            <ul className={clsx(componentStyles['c-tab-box__tab'])}>
              <li
                className={clsx(
                  activeTab === 'PENDING' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('PENDING');
                }}
              >
                <a>{getReviewStatusText('PENDING', t)}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'PUBLISHED' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('PUBLISHED');
                }}
              >
                <a>{getReviewStatusText('PUBLISHED', t)}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'DECLINED' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('DECLINED');
                }}
              >
                <a>{getReviewStatusText('DECLINED', t)}</a>
              </li>
            </ul>

            <div
              className={clsx(
                baseStyles['base-main__body__box'],
                styles['c-review__list__box']
              )}
            >
              <ul className={styles['c-review__list__comment']}>
                {reviews.length === 0 && !loading ? (
                  <div className={baseStyles['base-form-box__err']}>
                    {t('No Reviews')}
                  </div>
                ) : (
                  reviews
                    .slice(0, offset + defaultPageSize)
                    .map((review, idx) => (
                      <ReviewCard
                        key={idx}
                        review={review}
                        onReviewUpdate={() => setUpdateCount(updateCount + 1)}
                        showProductName
                        showStatus
                        showEditButton={hasCustomUserRoleWritePermissions(
                          activeUser,
                          'REVIEW.REVIEWS'
                        )}
                        showReservationDetailsLink
                      />
                    ))
                )}
              </ul>
              {showSeeMoreButton && (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    size="middle"
                    style="blue"
                    onClick={fetchNextPage}
                    loading={loading}
                  >
                    {t('Load More Reviews')}
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
