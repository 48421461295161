import {
  developmentFeatureFlags,
  releaseFeatureFlags,
  stgDemoFeatureFlags,
  FeatureFlags,
} from './featureConfig';

const mediaBucketName = 'ntmg-media';
const mediaBucketRegion = 'us-west-1';
const mediaDownloadBucketName = 'media-download-supplier-content';
const mediaDownloadBucketRegion = 'ap-southeast-2';
const mediaPathRoot = `https://${mediaBucketName}.s3.${mediaBucketRegion}.amazonaws.com`;
const assetBucketName = 'assets.ntmg.com';
const assetBucketRegion = 'ap-southeast-2';
const assetPathRoot = `https://s3-ap-southeast-2.amazonaws.com/assets.ntmg.com`;
const customerListBucketName = 'newsletter-customer-list';
const customerListBucketRegion = 'ap-southeast-2';
type DefaultConfig = {
  s3Config: {
    mediaBucketName: string;
    mediaBucketRegion: string;
    mediaDownloadBucketName: string;
    mediaDownloadBucketRegion: string;
    mediaPathRoot: string;
    assetBucketName: string;
    assetBucketRegion: string;
    assetPathRoot: string;
    customerListBucketName: string;
    customerListBucketRegion: string;
  };
};
type EnvConfig = {
  cognitoConfig: {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  stripeConfig: {
    publicUSDApiKey: string;
    publicJPYApiKey: string;
    publicJPYExpressApiKey: string;
    publicHIS3DSApiKey: string;
  };
  algoliaConfig: {
    applicationId: string;
    searchOnlyApiKey: string;
    indexPrefix: string;
  };
  webSocketEndpointUrl: string;
  bookingWidgetIpAddress: string;
  googleAnalyticsMeasurementId: string;
  sentryEnvironment: string;
  ogpParserEndPoint: string;
  nutmegExternalApiBaseUrl: string;
  settingsPollingInterval: number;
};
export type Config = DefaultConfig & EnvConfig & FeatureFlags;
export const allConfig: {
  default: DefaultConfig;
  development: EnvConfig & FeatureFlags;
  staging: EnvConfig & FeatureFlags;
  production: EnvConfig & FeatureFlags;
} = {
  default: {
    s3Config: {
      mediaBucketName,
      mediaBucketRegion,
      mediaDownloadBucketName,
      mediaDownloadBucketRegion,
      mediaPathRoot,
      assetBucketName,
      assetBucketRegion,
      assetPathRoot,
      customerListBucketName,
      customerListBucketRegion,
    },
  },
  development: {
    cognitoConfig: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-west-2:ad94e67d-8596-45bb-8f55-0bb75bba452a',
      region: 'us-west-2',
      userPoolId: 'us-west-2_eFp5dbnXa',
      userPoolWebClientId: '3ebhhdetheci4raghdqgd7870f',
    },
    stripeConfig: {
      publicUSDApiKey: 'pk_test_vrNTE42OAi4zZj4xnPVgsFxe',
      publicJPYApiKey: 'pk_test_2TKw7zhBdniZEVO9jc5zA4lA000LiRGO7f',
      publicJPYExpressApiKey:
        'pk_test_51J0IlTBrTpDuVJFbdk3w7yKHujjR6QLJZStwXcWmmolBpMTApWB3lVnu9mGOZU52v6aT7HCgN7Mrw6lmqbsiZMXg00JTdBsxRk',
      publicHIS3DSApiKey:
        'pk_test_51MszO3Cdk9AyStWtht7GyjOVCybfRGSfP7M1PM0gubGrBAscktUh1sfn6H2JwttZsNMWp9g6wsTvOdyJVHU0Wknu00NNtrUtNo',
    },
    algoliaConfig: {
      applicationId: 'SCTQ6Y1Y8Z',
      searchOnlyApiKey: 'cc2f78a17180d0b8c4497ce8816af1ce',
      indexPrefix: 'stg',
    },
    webSocketEndpointUrl:
      'wss://rctaas6zh6.execute-api.us-west-1.amazonaws.com/development',
    bookingWidgetIpAddress: '54.151.98.217',
    googleAnalyticsMeasurementId: 'G-CBXLF35S9X',
    sentryEnvironment: 'development',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    nutmegExternalApiBaseUrl: 'https://api.dev.ntmg.com/v1',
    settingsPollingInterval: 15000,
    ...developmentFeatureFlags,
  },
  staging: {
    cognitoConfig: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'ap-southeast-2:8add0e7e-c3af-42a5-945d-5a8aa52340df',
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_OymGFrC8M',
      userPoolWebClientId: '47cgfsj5qqo7i4pjl0f5nst97h',
    },
    stripeConfig: {
      publicUSDApiKey: 'pk_test_vrNTE42OAi4zZj4xnPVgsFxe',
      publicJPYApiKey: 'pk_test_2TKw7zhBdniZEVO9jc5zA4lA000LiRGO7f',
      publicJPYExpressApiKey:
        'pk_test_51J0IlTBrTpDuVJFbdk3w7yKHujjR6QLJZStwXcWmmolBpMTApWB3lVnu9mGOZU52v6aT7HCgN7Mrw6lmqbsiZMXg00JTdBsxRk',
      publicHIS3DSApiKey:
        'pk_test_51MszO3Cdk9AyStWtht7GyjOVCybfRGSfP7M1PM0gubGrBAscktUh1sfn6H2JwttZsNMWp9g6wsTvOdyJVHU0Wknu00NNtrUtNo',
    },
    algoliaConfig: {
      applicationId: 'SCTQ6Y1Y8Z',
      searchOnlyApiKey: 'cc2f78a17180d0b8c4497ce8816af1ce',
      indexPrefix: 'stg',
    },
    webSocketEndpointUrl:
      'wss://snbjll5h9l.execute-api.ap-southeast-2.amazonaws.com/staging',
    bookingWidgetIpAddress: '13.236.128.46',
    googleAnalyticsMeasurementId: 'G-CBXLF35S9X',
    sentryEnvironment: 'staging',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    nutmegExternalApiBaseUrl: 'https://api.stg.ntmg.com/v1',
    settingsPollingInterval: 15000,
    ...releaseFeatureFlags,
    ...stgDemoFeatureFlags,
  },
  production: {
    cognitoConfig: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'ap-southeast-2:e86346af-6726-4b2e-91cf-40d21b051dc6',
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_8FyfxM1RT',
      userPoolWebClientId: '3djudn3dvco0vi3gh23220ffuu',
    },
    stripeConfig: {
      publicUSDApiKey: 'pk_live_EF02izkIrPPFrHw31wlBzLub',
      publicJPYApiKey: 'pk_live_6coR0QKva5NxjWIjc8Tfe1aQ00yZna3AOG',
      publicJPYExpressApiKey:
        'pk_live_51J0IlTBrTpDuVJFb78xXeLMEct1VRRiEWpezT00LlOpYlFM4pty21C5U7FMT182w5B6D3ZRGvkqKtetgYCUUFlkz009MzYg8TS',
      publicHIS3DSApiKey:
        'pk_live_51MszO3Cdk9AyStWthWXljCwBr6jDfP6EPDcPD41tvxsfborfSg5CY9X3tL6byR9p0CrRfEOJiSK8bmvkD7ByjPUZ00msFbNICw',
    },
    algoliaConfig: {
      applicationId: 'SCTQ6Y1Y8Z',
      searchOnlyApiKey: 'cc2f78a17180d0b8c4497ce8816af1ce',
      indexPrefix: 'prod',
    },
    webSocketEndpointUrl:
      'wss://mklusxtnt6.execute-api.ap-southeast-2.amazonaws.com/production',
    bookingWidgetIpAddress: '13.248.207.159',
    googleAnalyticsMeasurementId: 'G-JCDWQRNB90',
    sentryEnvironment: 'production',
    ogpParserEndPoint:
      'https://tfs15glil4.execute-api.us-west-1.amazonaws.com/ogp-parser-development',
    nutmegExternalApiBaseUrl: 'https://api.ntmg.com/v1',
    settingsPollingInterval: 300000,
    ...releaseFeatureFlags,
  },
};
