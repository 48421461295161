import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { ScheduleItem } from 'client/components/ScheduleTable/ScheduleTable';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  ResourceType,
  convertToSwagger,
} from 'client/libraries/util/resourceType';
import {
  ResourceAssignment,
  ProductSummary,
  ResourceQuantity,
  Organization,
} from 'shared/models/swagger';

export const convertResourceAssignmentsToScheduleTableEvents = (
  resourceAssignments: ResourceAssignment[],
  products: ProductSummary[],
  resourceType: ResourceType,
  resourceKey: string,
  vehicleDepartureTimeKey: string,
  resourceQuantities: ResourceQuantity[],
  activeOrganization: Organization | null,
  onClick: (date: string) => void,
  t: TranslateFuncType
): ScheduleItem[] => {
  const itemMaps: {
    [key: string]: {
      product_id: string;
      quantity: number;
      dateFromUtc: string;
      dateToUtc: string;
      requestedQuantity: number;
    };
  } = {};

  resourceAssignments.forEach((resourceAssignment) => {
    const eventKey = getKey(resourceAssignment);
    if (itemMaps[eventKey]) {
      itemMaps[eventKey].quantity += resourceAssignment.quantity ?? 0;
    } else {
      itemMaps[eventKey] = {
        product_id: resourceAssignment.product_id ?? '',
        quantity: resourceAssignment.quantity ?? 0,
        dateFromUtc: resourceAssignment.date_from ?? '',
        dateToUtc: resourceAssignment.date_to ?? '',
        requestedQuantity: resourceAssignment.reservation_is_confirmed
          ? 0
          : resourceAssignment.quantity ?? 0,
      };
    }
  });

  const maxQuantityOfTheDay: { [key: string]: number } = {};

  const events = Object.keys(itemMaps).map((key) => {
    const item = itemMaps[key];

    const dateLocal = moment
      .tz(item.dateFromUtc, activeOrganization?.default_timezone ?? 'UTC')
      .format('YYYY-MM-DD');
    let maxQuantity = maxQuantityOfTheDay[dateLocal];

    if (maxQuantity === undefined) {
      maxQuantity =
        getDesignatedDateResourceQuantity(
          dateLocal,
          resourceType,
          resourceKey,
          vehicleDepartureTimeKey,
          resourceQuantities,
          activeOrganization
        )?.quantity ?? 0;
      maxQuantityOfTheDay[dateLocal] = maxQuantity;
    }

    return {
      title:
        products.find((product) => item.product_id === product.id)
          ?.product_name ??
        t('No product') ??
        '',
      description: `${String(item.quantity)} / ${String(maxQuantity)}`,
      description2:
        item.requestedQuantity > 0
          ? t('(requested: {{quantity}})', { quantity: item.requestedQuantity })
          : '',
      dateFromUtc: item.dateFromUtc,
      dateToUtc: item.dateToUtc,
      onClick: () => onClick(dateLocal),
    };
  });

  return events;
};

const getKey = (resourceAssignment: ResourceAssignment) => {
  return `${resourceAssignment.product_id}-${resourceAssignment.date_from}-${resourceAssignment.date_to}`;
};

export const getDefaultResourceQuantity = (
  date: string,
  resourceType: string,
  resourceKey: string,
  vehicleDepartureTimeKey: string,
  activeOrganization: Organization | null
): ResourceQuantity | null => {
  if (resourceType === 'other') {
    const dispatchSettings =
      activeOrganization?.dispatch_settings?.dispatch_misc_resources?.find(
        (d) => d.key === resourceKey
      );

    if (!dispatchSettings) {
      return null;
    }

    const schedule = dispatchSettings.availability?.schedules?.find((s) => {
      return (
        moment(s.start_date_local).isSameOrBefore(date) &&
        moment(s.end_date_local).isSameOrAfter(date)
      );
    });

    if (!schedule) {
      return {
        id: '',
        resource_type: 'RESOURCE_TYPE_OTHER',
        resource_key: resourceKey,
        date_local: date,
        quantity: dispatchSettings.capacity ?? 0,
      };
    }

    return {
      id: '',
      resource_type: 'RESOURCE_TYPE_OTHER',
      resource_key: resourceKey,
      date_local: date,
      quantity: schedule.quantity ?? 0,
    };
  } else if (resourceType === 'crew') {
    const dispatchSettings =
      activeOrganization?.dispatch_settings?.dispatch_crew_members?.find(
        (d) => d.key === resourceKey
      );

    if (!dispatchSettings) {
      return null;
    }

    const schedule = dispatchSettings.availability?.schedules?.find((s) => {
      return (
        moment(s.start_date_local).isSameOrBefore(date) &&
        moment(s.end_date_local).isSameOrAfter(date)
      );
    });

    if (!schedule) {
      return {
        id: '',
        resource_type: 'RESOURCE_TYPE_CREW',
        resource_key: resourceKey,
        date_local: date,
        quantity: 0,
      };
    }

    return {
      id: '',
      resource_type: 'RESOURCE_TYPE_CREW',
      resource_key: resourceKey,
      date_local: date,
      quantity:
        schedule.product_capacities?.reduce((acc, cur) => {
          return acc + (cur?.capacity ?? 0);
        }, 0) ?? 0,
    };
  } else if (resourceType === 'vehicle') {
    const dispatchSettings =
      activeOrganization?.dispatch_settings?.dispatch_vehicles?.find(
        (d) => d.key === resourceKey
      );

    if (!dispatchSettings) {
      return null;
    }

    const schedule = dispatchSettings.availability?.schedules?.find((s) => {
      return (
        moment(s.start_date_local).isSameOrBefore(date) &&
        moment(s.end_date_local).isSameOrAfter(date)
      );
    });

    if (!schedule) {
      return {
        id: '',
        resource_type: 'RESOURCE_TYPE_VEHICLE',
        resource_key: resourceKey,
        date_local: date,
        quantity: dispatchSettings.capacity ?? 0,
      };
    }

    const departureTime = schedule?.departure_time_capacities?.find((d) => {
      return d.key === vehicleDepartureTimeKey;
    });

    if (departureTime) {
      return {
        id: '',
        resource_type: 'RESOURCE_TYPE_VEHICLE',
        resource_key: departureTime.key,
        date_local: date,
        quantity: departureTime.capacity ?? 0,
      };
    }

    return {
      id: '',
      resource_type: 'RESOURCE_TYPE_VEHICLE',
      resource_key: resourceKey,
      date_local: date,
      quantity: dispatchSettings.capacity ?? 0,
    };
  }

  return null;
};

export const getDesignatedDateResourceQuantity = (
  date: string,
  resourceType: ResourceType,
  resourceKey: string,
  vehicleDepartureTimeKey: string,
  resourceQuantities: ResourceQuantity[] | null,
  activeOrganization: Organization | null
): ResourceQuantity | null => {
  if (!date || !resourceQuantities) {
    return null;
  }

  const resourceQuantity = resourceQuantities.find(
    (rq) =>
      rq.resource_type === convertToSwagger(resourceType) &&
      rq.resource_key ===
        getResourceAssignmentKey(
          resourceType,
          resourceKey,
          vehicleDepartureTimeKey
        ) &&
      rq.date_local === date
  );

  if (resourceQuantity) {
    return resourceQuantity;
  }

  return getDefaultResourceQuantity(
    date,
    resourceType,
    resourceKey,
    vehicleDepartureTimeKey,
    activeOrganization
  );
};

export const getReactTableColumns = (
  t: TranslateFuncType,
  locale: string,
  openLinksInNewWindow?: boolean
): CustomTableColumn[] => {
  const linkProps = openLinksInNewWindow
    ? {
        target: '_blank',
      }
    : {};
  return [
    {
      Header: t('Application Number'),
      Cell: (cellInfo) => (
        <Link to={`/reservations/${cellInfo.original?.id}`} {...linkProps}>
          {cellInfo.original?.agent_reference}
        </Link>
      ),
      width: 'middle',
      th: true,
    },
    {
      Header: t('Booked Date'),
      width: 'middle',
      Cell: ({ original }) =>
        original.booked_at?.locale(locale).format('lll') ?? '',
    },
    {
      Header: t('Last Updated'),
      width: 'middle',
      Cell: ({ original }) =>
        original.updated_at?.locale(locale).format('lll') ?? '',
    },
    {
      Header: t('Status'),
      width: 'middle',
      accessor: (row) => t(row.status),
    },
    {
      Header: t('Customer'),
      width: 'middle',
      accessor: 'guest_display_name',
    },
    {
      Header: t('Product'),
      width: 'long',
      Cell: ({ original }) => (
        <Link to={`/products/${original.product_id}`} {...linkProps}>
          {original.product_name}
        </Link>
      ),
    },
    {
      Header: t('Participation'),
      width: 'middle',
      Cell: ({ original }) =>
        original.participates_at?.locale(locale).format('lll'),
    },
    {
      Header: t('Units'),
      width: 'middle',
      accessor: 'guest_description',
    },
    {
      Header: t('Total Pax'),
      width: 'short',
      accessor: 'guest_count',
    },
    {
      Header: t('Hotel'),
      accessor: 'hotel',
    },
    {
      Header: t('Pickup/Checkin time'),
      width: 'short',
      Cell: ({ original }) =>
        original.pickup_checkin_time?.locale(locale).format('lll'),
    },
    {
      Header: t('Pickup/Checkin location'),
      width: 'long',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: t('Payment Type'),
      width: 'short',
      accessor: (row) => row.payment_type && t(row.payment_type),
    },
    {
      Header: t('Transportation'),
      width: 'middle',
      accessor: 'transportation',
    },
    {
      Header: t('Add-ons'),
      width: 'middle',
      accessor: 'add_ons',
    },
    {
      Header: t('Source'),
      width: 'middle',
      accessor: 'booking_source',
    },
    {
      Header: t('Remarks'),
      width: 'long',
      accessor: 'agent_notes',
    },
    {
      Header: t('Replies'),
      width: 'long',
      accessor: 'supplier_notes',
    },
    {
      Header: t('Supplier'),
      width: 'middle',
      accessor: 'supplier_name',
    },
    {
      Header: t('Confirmation Number'),
      width: 'middle',
      accessor: 'supplier_reference',
    },
    {
      Header: '#',
      width: 'long',
      Cell: ({ original }) => (
        <Link to={`/reservations/${original.id}`} {...linkProps}>
          {original.id}
        </Link>
      ),
    },
  ];
};

export const getResourceAssignmentKey = (
  resourceType: ResourceType,
  resourceKey: string,
  vehicleDepartureTimeKey: string
): string => {
  if (resourceType === 'vehicle') {
    return vehicleDepartureTimeKey ?? '';
  }
  return resourceKey;
};
