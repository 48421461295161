import { TFunction } from 'react-i18next';

export const getTierTypeOptions = (t: TFunction) => {
  return [
    {
      value: 'INSTANT_WIN_TIER_TYPE_E_TICKET',
      text: t('E-ticket'),
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_DOWNLOAD',
      text: t('Download'),
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_PROMO_CODE',
      text: t('Promo Code'),
    },
    {
      value: 'INSTANT_WIN_TIER_TYPE_GIFT',
      text: t('Gifts'),
    },
  ];
};
