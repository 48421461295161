import { useSelector } from 'react-redux';
import useSWR from 'swr';
import querystring from 'query-string';
import axios from 'axios';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import {
  AccessReportGadgetParams,
  AccessSummaryGadgetParams,
} from 'client/reducers/dashboardSettings';
import { isAccessReportParams } from 'client/pages/v3/FlexibleDashboard/util';

interface DateRange {
  start: string;
  end: string;
}

const fetcher = (url: string, paramsString: string, headerString: string) =>
  axios
    .get(url, {
      params: JSON.parse(paramsString),
      headers: JSON.parse(headerString),
    })
    .then((res) => res.data?.sets ?? []);

export const useAccessReportData = (
  dateRange: DateRange,
  params: AccessReportGadgetParams | AccessSummaryGadgetParams,
  comparisonDateRange?: DateRange | null
): {
  data: Swagger.AccessReportDataSet[] | null;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToAccessReportRequest(
    dateRange,
    params,
    comparisonDateRange
  );

  const { data, error } = useSWR<Swagger.AccessReportDataSet[]>(
    [
      `/api/accessreportdata`,
      JSON.stringify(queryParams),
      JSON.stringify(headers),
    ],
    fetcher
  );
  return {
    data: data ?? null,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const convertToAccessReportUnit = (aggregationType: string | undefined) => {
  switch (aggregationType) {
    case 'DAILY':
      return 'DAY';
    case 'WEEKLY':
      return 'WEEK';
    case 'MONTHLY':
      return 'MONTH';
    default:
      return 'DAY';
  }
};

const convertParamsToAccessReportRequest = (
  dateRange: DateRange,
  params: AccessReportGadgetParams | AccessSummaryGadgetParams,
  comparisonDateRange?: DateRange | null
): Swagger.GetAccessReportDataRequest => {
  const ranges: { starts: string[]; ends: string[] } = {
    starts: [],
    ends: [],
  };
  ranges.starts.push(dateRange.start);
  ranges.ends.push(dateRange.end);
  if (comparisonDateRange) {
    ranges.starts.push(comparisonDateRange.start);
    ranges.ends.push(comparisonDateRange.end);
  }
  ranges.starts.push('END');
  ranges.ends.push('END');

  if (isAccessReportParams(params)) {
    return {
      unit: convertToAccessReportUnit(params.aggregationType),
      ranges: querystring.stringify(ranges),
    };
  } else {
    return {
      // Need to add unit & page_size just like in access report page
      // This will always be day
      unit: 'DAY',
      page_size: 1000,
      ranges: querystring.stringify(ranges),
    };
  }
};
