import {
  ADD_EQUIPMENT_NOTIFICATION,
  REMOVE_EQUIPMENT_NOTIFICATION,
} from 'client/constants/ActionTypes';
import type { EquipmentNotification } from 'client/reducers/equipmentNotifications';

export const addEquipmentNotification = (
  notification: EquipmentNotification
) => ({
  type: ADD_EQUIPMENT_NOTIFICATION,
  notification,
});
export const removeEquipmentNotification = (id: string) => ({
  type: REMOVE_EQUIPMENT_NOTIFICATION,
  id,
});
