import type { Moment } from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import {
  allDispatchVehiclesSelector,
  allDispatchMiscResourcesSelector,
} from 'client/reducers/dispatchSettings';
import { ResourceAssignmentReservationsTable } from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignmentContents/ResourceAssignmentReservationsTable';
import { getAssignedResources } from 'client/components/ResourceAssignmentModal/resources';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { ReduxState } from 'client/reducers/index';
import { Modal } from 'client/components/v3/Form/Modal';
import { Divider } from 'client/components/Divider/Divider';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { groupReservationsByNormalizedStartTime } from 'client/libraries/util/groupReservationsByNormalizedStartTime';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import type {
  ReservationColumn,
  ReservationPatch,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  resourceType: ResourceType;
  resourceKey: string;
  visibleColumns: ReservationColumn[];
  reservations: ManifestReservationShape[];
  title?: string;
  timezone?: string;
};
export const defaultVisibleColumns: ReservationColumn[] = [
  'AGENT_REFERENCE',
  'PRODUCT_NAME',
  'GUEST_DISPLAY_NAME',
  'GUEST_COUNT',
  'GUEST_DESCRIPTION',
  'TRANSPORTATION',
  'PICKUP_CHECKIN_TIME',
  'PICKUP_CHECKIN_LOCATION',
  'DROPOFF_LOCATION',
  'ADD_ONS',
];
export const ResourceReservationListModal = ({
  open,
  onClose,
  resourceType,
  resourceKey,
  visibleColumns,
  reservations,
  title,
  timezone,
}: Props) => {
  const [updateRequests, setUpdateRequests] = useState<
    {
      id: string;
      patch: ReservationPatch;
    }[]
  >([]);
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const allDispatchMiscResources = useSelector(
    allDispatchMiscResourcesSelector
  );
  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);
  const allDispatchVehicles = useSelector(allDispatchVehiclesSelector);
  const productsById = useSelector((state: ReduxState) => state.products.byID);
  const products = [
    ...new Set(reservations.map((reservation) => reservation.product_id)),
  ]
    .map((productId) => productsById[productId])
    .filter((p) => Boolean(p));
  let resourceTypeText = '';
  let filteredReservations: ManifestReservationShape[] = [];

  switch (resourceType) {
    case 'vehicle':
      resourceTypeText = t('Vehicle');
      filteredReservations = reservations.filter((r) =>
        (r.dispatch_vehicles || []).includes(resourceKey)
      );
      break;

    case 'crew':
      resourceTypeText = t('Crew Member');
      filteredReservations = reservations.filter((r) =>
        (r.dispatch_crew || []).includes(resourceKey)
      );
      break;

    case 'other':
      resourceTypeText = t('Other');
      filteredReservations = reservations.filter((r) =>
        (r.dispatch_misc_resources || []).includes(resourceKey)
      );
      break;

    case 'guide':
      resourceTypeText = t('Staff');
      filteredReservations = reservations.filter((r) =>
        (r.dispatch_guides || []).includes(resourceKey)
      );
      break;

    default:
      break;
  }

  const reservationsByStartTime = groupReservationsByNormalizedStartTime(
    filteredReservations || []
  );

  const getStartTimeText = (startTime: Moment) => {
    if (timezone) {
      return startTime.tz(timezone).locale(locale).format('HH:mm');
    }

    return startTime.locale(locale).format('HH:mm');
  };

  const capacityDescriptions = reservationsByStartTime.map(
    ({ startDateTime, reservations }) => {
      const startTimeText = getStartTimeText(startDateTime);
      let capacityDescription = '';

      if (resourceType === 'vehicle' || resourceType === 'other') {
        const resourceList =
          resourceType === 'vehicle'
            ? allDispatchVehicles
            : allDispatchMiscResources;
        const resource = resourceList.find(
          (resource) => resource.key === resourceKey
        );
        let totalCapacity = 0;

        if (resource && resource.capacity) {
          totalCapacity = resource.capacity;
        }

        let currentOccupiedCapacity = 0;
        reservations.forEach((reservation) => {
          if (
            getAssignedResources(reservation, resourceType).indexOf(
              resourceKey
            ) !== -1
          ) {
            currentOccupiedCapacity += reservation.guests.length;
          }
        });
        capacityDescription = `${startTimeText} : (${currentOccupiedCapacity}/${totalCapacity})`;
      }

      return capacityDescription;
    }
  );
  return (
    <Modal
      title={t('Assigned Reservations')}
      open={open}
      onClose={onClose}
      insertAtRoot={true}
      style={{ width: '80%', height: '600px' }}
      useCloseButton={true}
    >
      <div className={styles['manifestsModalAssigned']}>
        <div className={styles['p-manifestsModalAssigned__item']}>
          <p className={styles['p-manifestsModalAssigned__item__ttl']}>
            {t('Resource Type')}
          </p>
          <div className={styles['p-manifestsModalAssigned__item__body']}>
            {resourceTypeText}
          </div>
        </div>
        <div className={styles['p-manifestsModalAssigned__item']}>
          <p className={styles['p-manifestsModalAssigned__item__ttl']}>
            {t('Resource')}
          </p>
          <div className={styles['p-manifestsModalAssigned__item__body']}>
            {title || resourceKey}
          </div>
        </div>
        {['vehicle', 'other'].includes(resourceType) && (
          <div className={styles['p-manifestsModalAssigned__item']}>
            <p className={styles['p-manifestsModalAssigned__item__ttl']}>
              {t('Capacity')}
            </p>
            <div className={styles['p-manifestsModalAssigned__item__body']}>
              <ul>
                {capacityDescriptions.map((capacityDescription, idx) => (
                  <li key={idx}>{capacityDescription}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div>
          <Divider />

          {reservationsByStartTime.map(
            ({ startDateTime, reservations }, idx) => (
              <div
                key={idx}
                className={styles['p-manifestsModalAssigned__item']}
              >
                <p className={styles['p-manifestsModalAssigned__item__ttl']}>
                  {t('Start time: {{startTime}}', {
                    startTime: getStartTimeText(startDateTime),
                  }) +
                    ' ' +
                    t('Total Pax: {{paxCount}}', {
                      paxCount: String(
                        (reservations || []).reduce((sum, reservation) => {
                          return sum + (reservation.guests || []).length;
                        }, 0)
                      ),
                    })}
                </p>
                <div className={styles['p-manifestsModalAssigned__item__body']}>
                  <ResourceAssignmentReservationsTable
                    excludedFormFieldKeys={excludedFormFieldKeys}
                    reservations={reservations}
                    visibleColumns={visibleColumns}
                    products={products}
                    editingResourceType={resourceType}
                    editingResourceKey={resourceKey}
                    updateRequests={updateRequests}
                    onUpdateRequestsChanged={(newUpdateRequests) =>
                      setUpdateRequests(newUpdateRequests)
                    }
                    readOnly={true}
                    hideResourceColumns={true}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};
