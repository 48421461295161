import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
  SET_CUSTOMER_LIST_ROW_COUNT,
  SET_CUSTOMER_LIST_CURRENT_PAGE,
  SET_CUSTOMER_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_CUSTOMER_LIST_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_CUSTOMER_LIST_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

const defaultVisibleColumns = [
  'name',
  'tags',
  'autotags',
  'registrationDate',
  'lastParticipationDate',
  'lastParticipateProduct',
  'mailStatus',
  'totalReservations',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_CUSTOMER_VISIBLE_COLUMNS:
      return action.visibleColumns;
    default:
      return state;
  }
};

type State = {
  currentPage: number;
  rowCount: number;
  visibleColumns: string[];
};

const reducer = combineReducers<State>({
  currentPage,
  rowCount,
  visibleColumns,
});
export const customerListControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export const customerListColumnCandidatesSelector = (): string[] => {
  return defaultVisibleColumns;
};

const visibleColumnsSelector = (state: ReduxState) =>
  state.customerListControls?.visibleColumns ?? [];

export const customerListVisibleColumnsSelector: (
  state: ReduxState
) => string[] = createSelector(
  visibleColumnsSelector,
  customerListColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);
