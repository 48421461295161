import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type { LanguageISO } from 'shared/libraries/i18n';
import { getActionSourceEntityName } from 'client/libraries/util/util';
import { TranslateFuncType } from 'client/components/Translate';
import type {
  Reservation,
  ActionSource,
  ReservationUpdateCategory,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  reservation: Reservation;
  locale: LanguageISO;
};
type Action = {
  actionDescription: string;
  actionSource?: ActionSource;
  time: string;
  updateCategories?: ReservationUpdateCategory[];
};

const getUpdateCategoryText = (
  category: ReservationUpdateCategory,
  t: TranslateFuncType
) => {
  switch (category) {
    case 'SUPPLIER_REFERENCE':
      return t('Edit Confirmation Number');
    case 'PICKUP_DROPOFF':
      return t('Edit Pickup/Dropoff');
    case 'CHECKIN':
      return t('Edit Checkin');
    case 'GUEST_HOTEL':
      return t('Edit Customer Hotel');
    case 'FIELD_RESPONSES':
      return t('Update Field Response');
    case 'SUPPLIER_NOTES':
      return t('Edit Replies, Memo, Internal Note');
    case 'AGENT_NOTES':
      return t('Update: Remarks');
    case 'FARE_ADJUSTMENT_CHARGE':
      return t('Fare Adjustment: Charge');
    case 'FARE_ADJUSTMENT_REFUND':
      return t('Fare Adjustment: Refund');
  }
};

export const ReservationActivityLog = ({ reservation, locale }: Props) => {
  const { t } = useTranslation();

  const getActions = (): Action[] => {
    const activities: Action[] = [];
    const statusHistory = reservation.status_history.slice(1);

    for (let i = 0; i < statusHistory.length; i++) {
      const s = statusHistory[i];

      switch (s.status) {
        case 'REQUESTED':
        case 'CONFIRMED':
        case 'DECLINED_BY_SUPPLIER':
        case 'WITHDRAWN_BY_AGENT':
        case 'STANDBY':
        case 'CANCELED_BY_AGENT':
        case 'CANCELED_BY_SUPPLIER':
        case 'CANCELED_BY_GUEST':
          activities.push({
            actionDescription: t(s.status),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
            updateCategories: s.update_categories,
          });
          break;

        default:
          continue;
      }
    }

    return activities;
  };

  const printAction = (action: Action): string => {
    const { actionDescription, actionSource } = action;

    if (actionSource) {
      const entityName = getActionSourceEntityName(actionSource, t);
      const entityDescription = actionSource.entity_description;
      if (entityName && entityDescription)
        return t('{{entityName}} ({{personName}})', {
          personName: entityDescription,
          entityName,
          actionDescription,
        });
      else if (entityName)
        return t('{{entityName}}', {
          entityName,
          actionDescription,
        });
      else if (entityDescription)
        return t('{{personName}}', {
          personName: entityDescription,
          actionDescription,
        });
    }

    return '';
  };

  const actions = getActions();
  return (
    <>
      <div className={clsx(baseStyles['base-main__body__box'])}>
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Activity Log')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          ></div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <ul className={clsx(pageStyles['page-reservations__history'])}>
            {actions.map((action, idx) => {
              const actionText = printAction(action);
              return (
                actionText && (
                  <li key={idx}>
                    <p className={clsx(baseStyles['base-t-160'])}>
                      {moment(action.time).locale(locale).format('lll')}
                    </p>
                    <p className={clsx(baseStyles['base-t-240'])}>
                      {actionText}
                    </p>
                    <p className={clsx(baseStyles['base-t-160'])}>
                      {action.actionDescription}
                    </p>
                    {action.updateCategories?.length ? (
                      <p>
                        {getUpdateCategoryText(action.updateCategories[0], t)}
                      </p>
                    ) : null}
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
