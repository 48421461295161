import * as React from 'react';
import clsx from 'clsx';
import { Moment } from 'moment-timezone';

import { DateTimeTZPicker } from 'client/components/Form/lib/DateTimeTZPicker';
import styles from 'client/base.module.css';
import 'client/components/Form/style.css';

type Props = {
  error?: string;
  label?: string;
  onChange: (arg0: Moment) => void;
  value: Moment | null;
  locale?: 'ja' | 'en' | 'ko' | 'zh-CN' | 'zh-TW';
  showTimeInput?: boolean;
  withoutFrame?: boolean;
  popperPlacement?: string;
  disabled?: boolean;
  showTodayButton?: boolean;
  showWeekDay?: boolean;
};
export const DateTimeInput = ({
  error,
  label,
  onChange,
  value,
  locale,
  showTimeInput,
  popperPlacement,
  disabled,
  showTodayButton,
  showWeekDay,
}: Props) => {
  return (
    <div className={clsx(styles['base-form-box'])}>
      {label && <div className={styles['base-form-box__header']}>{label}</div>}
      <div className={styles['base-form-box__body']}>
        <DateTimeTZPicker
          onChange={onChange}
          value={value}
          locale={locale}
          showTimeInput={showTimeInput}
          popperPlacement={popperPlacement}
          disabled={disabled}
          showTodayButton={showTodayButton}
          showWeekDay={showWeekDay}
        />
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
