import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE,
  FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST,
  FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { GetRestaurantOrderReportDataResponse } from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE:
      return action.error;

    case FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST:
      return true;

    case FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE:
    case FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS:
      return false;

    default:
      return state;
  }
};

const reportData = (
  state: GetRestaurantOrderReportDataResponse | null = null,
  action: any
): GetRestaurantOrderReportDataResponse | null => {
  switch (action.type) {
    case FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetRestaurantOrderReportDataResponse;
      return resp;
    }
    default:
      return state;
  }
};

const status = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST:
      return 'REQUESTED';

    case FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS:
      return 'SUCCEEDED';

    case FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

export const restaurantOrderReportData = combineReducers({
  error,
  loading,
  reportData,
  status,
});
