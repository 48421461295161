import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import { DownloadItemsModal } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/DownloadItemsModal';
import { DownloadItemsDisplayBox } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/DownloadItemsDisplayBox';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

export const DownloadItems = () => {
  const { t } = useTranslation();
  const lastDownloadItems = useSelector(
    (state: ReduxState) =>
      (state as any).reservationDataCsvExportOrderTableControls.visibleColumns
  );

  const [showDownloadItemsModal, setShowDownloadItemsModal] =
    React.useState<boolean>(false);

  return (
    <div className={styles['p-download__body__item']}>
      <div className={styles['p-download__body__item__num']}>
        {(lastDownloadItems ?? []).length === 0 ? (
          <p>2</p>
        ) : (
          <p className={styles['is-active']}>2</p>
        )}
      </div>
      <p className={styles['p-download__body__item__ttl']}>
        {t('Download Items')}
      </p>
      {(lastDownloadItems ?? []).length === 0 && (
        <div className={styles['p-download__body__item__action']}>
          <Button
            color="tertiarycolor"
            size="sm"
            iconAfterText={
              <>
                <i className="c-icon-outline-general-edit-05"></i>
                <p>{t('Download Items')}</p>
              </>
            }
            onClick={() => setShowDownloadItemsModal(true)}
          />
        </div>
      )}
      {(lastDownloadItems ?? []).length !== 0 && (
        <div className={styles['p-download__body__item__edit']}>
          <DownloadItemsDisplayBox appliedDownloadItems={lastDownloadItems} />
          <Button
            color="white"
            size="sm"
            iconAfterText={<i className="c-icon-outline-general-edit-05"></i>}
            onClick={() => setShowDownloadItemsModal(true)}
          />
        </div>
      )}
      {showDownloadItemsModal && (
        <DownloadItemsModal setShowModal={setShowDownloadItemsModal} />
      )}
    </div>
  );
};
