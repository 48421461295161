import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import type { ReduxState } from 'client/reducers';

import styles from '../DetailsStep.module.css';

export const TagsEditor = () => {
  const { t } = useTranslation();
  const targetTranslationLanguage = React.useContext(
    TranslationLanguageContext
  );
  const tagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.tags
  );
  return (
    <div className={styles['c-table-list']}>
      <table>
        <tbody>
          {targetTranslationLanguage && <TranslationTableHeader />}
          <tr>
            <th>{t('Tags')}</th>
            <td colSpan={2}>
              <ListEditor name="tags" suggestions={tagSuggestions} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
