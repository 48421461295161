import * as React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import type { TranslateFuncType } from 'client/components/Translate';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import type { ReduxState } from 'client/reducers';
import type { Reservation, Product, Guest, Field } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { CustomerReservationsContext } from './CustomerReservationsContext';

export const CustomerGuestInformation = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = React.useContext(CustomerReservationsContext);

  const products = useSelector((state: ReduxState) => state.products.byID);

  const excludedFormFieldKeys = [
    'family_name',
    'given_name',
    'kana_family_name',
    'kana_given_name',
    'full_name',
    'email',
    'preferred_language_iso2',
    'booking_language',
    'hotel_information',
    'representative_name',
    'hotel_tbd_form',
    'consent_form',
  ];
  const getBookingFieldResponsesBox = (
    reservation: Reservation,
    product: Product
  ) => {
    const fields = (product?.reservation_form_fields ?? []).filter(
      (field) =>
        field.type === 'PER_BOOKING' &&
        !excludedFormFieldKeys.includes(field.key ?? '')
    );

    return (
      <ul>
        {fields.map((field, idx) => {
          const response = (reservation?.field_responses ?? []).find(
            (response) => {
              return response.key === field.key;
            }
          );
          return (
            <li key={idx}>
              {field.prompt}: {response?.response}
            </li>
          );
        })}
      </ul>
    );
  };

  const getGuestFieldResponsesBox = (
    reservation: Reservation,
    product: Product
  ) => {
    const fields = (product?.reservation_form_fields ?? []).filter(
      (field) => field.type === 'PER_PARTICIPANT'
    );

    if (fields.length === 0) {
      return null;
    }
    return (
      <>
        {reservation?.guests?.map((guest, idx) => {
          return (
            <div key={idx}>
              <p>{guest?.guest_type_title}</p>
              {getEachGuestFieldResponsesBox(guest, fields)}
            </div>
          );
        })}
      </>
    );
  };

  const getEachGuestFieldResponsesBox = (guest: Guest, fields: Field[]) => {
    return (
      <ul>
        {fields.map((field, idx) => {
          const response = (guest?.field_responses ?? []).find((response) => {
            return response.key === field.key;
          });
          return (
            <li key={idx}>
              {field.prompt}: {response?.response}
            </li>
          );
        })}
      </ul>
    );
  };

  const getColumns = (
    local: string,
    t: TranslateFuncType
  ): ColumnType<Reservation>[] => {
    return [
      {
        Header: t('Application Number'),
        id: 'agent_reference',
        accessor: (item: Reservation) => {
          return (
            <Link to={`/reservations/${item.id}`}>{item.agent_reference}</Link>
          );
        },
        width: 120,
      },
      {
        Header: t('Participation'),
        id: 'participates_at',
        accessor: (item: Reservation) => {
          return getDateTimeDisplay(
            moment.tz(item.start_date_time_utc, item.start_timezone ?? ''),
            local
          );
        },
        width: 120,
      },
      {
        Header: t('Per-booking Reservation Form'),
        id: 'per_booking_reservation_from',
        accessor: (item: Reservation) => {
          const product = products[item.product_id ?? ''];
          if (product) {
            return getBookingFieldResponsesBox(item, product);
          }
          return '';
        },
        width: 300,
      },
      {
        Header: t('Per-participant Reservation Form'),
        id: 'per_participant_reservation_from',
        accessor: (item: Reservation) => {
          const product = products[item.product_id ?? ''];
          if (product) {
            return getGuestFieldResponsesBox(item, product);
          }
          return '';
        },
        width: 300,
      },
    ];
  };
  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        baseStyles['scroll-target-pane']
      )}
      id="main"
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Guest Information')}
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GenericTable<Reservation>
          items={(reservations ?? []).filter((_, index) => {
            const start = (currentPage - 1) * rowCount;
            const end = start + rowCount;
            return index >= start && index < end;
          })}
          totalCount={reservations?.length ?? 0}
          columns={getColumns(locale, t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </div>
    </div>
  );
};
