import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

interface Props {
  onClose: () => void;
  onAddValue: (value: string) => void;
}

export const StructuredInfoValueModal = ({ onAddValue, onClose }: Props) => {
  const [value, setValue] = React.useState('');

  const { t } = useTranslation();

  return (
    <Modal open title={t('Add New Value')} insertRoot onClose={onClose}>
      <Input
        label={t('Value')}
        value={value}
        onChange={(e, { value }) => {
          setValue(value);
        }}
      />
      <Button
        onClick={() => {
          onAddValue(value);
          onClose();
        }}
        size="middle"
        style="green"
      >
        {t('Add')}
      </Button>
    </Modal>
  );
};
