import querystring from 'query-string';
import moment from 'moment-timezone';
import _ from 'lodash';

import type { TranslateFuncType } from 'client/components/Translate';
import {
  fetchReservations,
  fetchReservationStats,
} from 'client/actions/reservations';
import type { ReservationStatus } from 'shared/models/swagger';

import { BookingSourceType } from './getBookingSourceOptions';

export type DateFilterPreset =
  | 'LAST_UPDATED_1_DAY'
  | 'LAST_UPDATED_3_DAYS'
  | 'LAST_UPDATED_7_DAYS'
  | 'BOOKED_AT_1_DAY'
  | 'BOOKED_AT_3_DAYS'
  | 'BOOKED_AT_7_DAYS'
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'EXPIRATION_1_WEEK'
  | 'EXPIRATION_2_WEEKS'
  | 'EXPIRATION_1_MONTH'
  | 'EXPIRATION_3_MONTHS'
  | 'EXPIRATION_6_MONTHS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'LAST_UPDATED_1_DAY',
      'LAST_UPDATED_3_DAYS',
      'LAST_UPDATED_7_DAYS',
      'BOOKED_AT_1_DAY',
      'BOOKED_AT_3_DAYS',
      'BOOKED_AT_7_DAYS',
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};

export const getExpirationFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'EXPIRATION_1_WEEK',
      'EXPIRATION_2_WEEKS',
      'EXPIRATION_1_MONTH',
      'EXPIRATION_3_MONTHS',
      'EXPIRATION_6_MONTHS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'LAST_UPDATED_1_DAY':
      return t('Last updated, past {{count}} days', {
        count: 1,
      });

    case 'LAST_UPDATED_3_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 3,
      });

    case 'LAST_UPDATED_7_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 7,
      });

    case 'BOOKED_AT_1_DAY':
      return t('Booked at, past {{count}} days', {
        count: 1,
      });

    case 'BOOKED_AT_3_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 3,
      });

    case 'BOOKED_AT_7_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
    case 'EXPIRATION_1_WEEK':
      return t('Expiration, next 1 week');
    case 'EXPIRATION_2_WEEKS':
      return t('Expiration, next {{count}} weeks', {
        count: 2,
      });
    case 'EXPIRATION_1_MONTH':
      return t('Expiration, next 1 month');
    case 'EXPIRATION_3_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 3,
      });
    case 'EXPIRATION_6_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 6,
      });
  }
};
export type SearchReservationsRequest = {
  agentReference: string;
  agentIds: string[];
  supplierReference: string;
  id: string;
  statuses: ReservationStatus[];
  customerGivenName: string;
  customerFamilyName: string;
  bookingSourceTypes: BookingSourceType[];
  supplierIds: string[];
  productIds: string[];
  bookedDateFrom: string;
  bookedDateTo: string;
  participationDateFrom: string;
  participationDateTo: string;
  lastUpdatedDateFrom: string;
  lastUpdatedDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
};

export const convertSearchReservationsRequestToQueryParams = (
  req: SearchReservationsRequest
): Record<string, string> => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let bookedDateTimeUtcFrom = '';
  let bookedDateTimeUtcTo = '';
  let lastUpdatedDateTimeUtcFrom = '';
  let lastUpdatedDateTimeUtcTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'LAST_UPDATED_1_DAY':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_3_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(3, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_7_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_1_DAY':
        bookedDateTimeUtcFrom = now.subtract(1, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_3_DAYS':
        bookedDateTimeUtcFrom = now.subtract(3, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_7_DAYS':
        bookedDateTimeUtcFrom = now.subtract(7, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    bookedDateTimeUtcFrom = req.bookedDateFrom
      ? moment(req.bookedDateFrom).utc().format()
      : '';
    bookedDateTimeUtcTo = req.bookedDateTo
      ? moment(req.bookedDateTo).add(1, 'day').utc().format()
      : '';
    lastUpdatedDateTimeUtcFrom = req.lastUpdatedDateFrom
      ? moment(req.lastUpdatedDateFrom).utc().format()
      : '';
    lastUpdatedDateTimeUtcTo = req.lastUpdatedDateTo
      ? moment(req.lastUpdatedDateTo).add(1, 'day').utc().format()
      : '';
  }

  return {
    start_date_local_from: startDateLocalFrom,
    start_date_local_to: startDateLocalTo,
    booked_date_time_utc_from: bookedDateTimeUtcFrom,
    booked_date_time_utc_to: bookedDateTimeUtcTo,
    last_updated_date_time_utc_from: lastUpdatedDateTimeUtcFrom,
    last_updated_date_time_utc_to: lastUpdatedDateTimeUtcTo,
    filter: querystring.stringify({
      agent_reference: req.agentReference ? [req.agentReference, ''] : '',
      'booking_source.source_type':
        req.bookingSourceTypes.length > 0 ? req.bookingSourceTypes : '',
      agent_id: req.agentIds.length > 0 ? req.agentIds : '',
      supplier_reference: req.supplierReference
        ? [req.supplierReference, '']
        : '',
      id: req.id ? [req.id, ''] : '',
      status: req.statuses.length > 0 ? req.statuses : '',
      'field_responses.family_name.response':
        req.customerFamilyName &&
        !/^[ァ-ヶー\u3000]+$/.test(req.customerFamilyName)
          ? [
              ...new Set([
                req.customerFamilyName,
                req.customerFamilyName.toLowerCase(),
                req.customerFamilyName.toUpperCase(),
                _.startCase(req.customerFamilyName.toLowerCase()),
              ]),
            ]
          : '',
      'field_responses.kana_family_name.response':
        req.customerFamilyName &&
        /^[ァ-ヶー\u3000]+$/.test(req.customerFamilyName)
          ? [...new Set([req.customerFamilyName])]
          : '',
      'field_responses.given_name.response':
        req.customerGivenName &&
        !/^[ァ-ヶー\u3000]+$/.test(req.customerGivenName)
          ? [
              ...new Set([
                req.customerGivenName,
                req.customerGivenName.toLowerCase(),
                req.customerGivenName.toUpperCase(),
                _.startCase(req.customerGivenName.toLowerCase()),
              ]),
            ]
          : '',
      'field_responses.kana_given_name.response':
        req.customerGivenName &&
        /^[ァ-ヶー\u3000]+$/.test(req.customerGivenName)
          ? [...new Set([req.customerGivenName])]
          : '',
      supplier_id: req.supplierIds.length > 0 ? req.supplierIds : '',
      product_id: req.productIds.length > 0 ? req.productIds : '',
    }),
  };
};
export const searchReservations = (req: SearchReservationsRequest) =>
  fetchReservations(convertSearchReservationsRequestToQueryParams(req));
export const getReservationStats = (req: SearchReservationsRequest) =>
  fetchReservationStats(convertSearchReservationsRequestToQueryParams(req));
