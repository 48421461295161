import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useContext } from 'react';

import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ListEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ListEditor';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import { getCommonFormFields } from 'client/libraries/util/coreutil';
import { AgentReferencesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/AgentReferencesEditor';
import type { Product } from 'shared/models/swagger';
import type { FormField } from 'client/components/NewProductEditor/DetailsStep/ReservationFormEditor/FormValues';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import baseStyles from 'client/v3-base.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { config } from 'client/config';

/*
  NOTE(goro):
    preferred_language_iso2 : Used for displaying the field at Extranet. no need to store it to DynamoDB
    representative_name     : Used for keep the representative name char set (alphabet/kana/alphabet&kana). need to store it to DynamoDB. not used at Falconet
    hotel_information       : Used for the flag to show hotel form at Falconet. need to store it DynamoDB
    family_name             : Used for showing forms at Falconet. no neeed at Falconet
    given_name              : Same with the above item
    kana_family_name        : Same with the above item
    kana_given_name         : Same with the above item
*/
const unRemovableFields = [
  'email',
  'preferred_language_iso2',
  'representative_name',
];
const disabledFields = [
  'email',
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'hotel_tbd_form',
];
const disabledExceptRequiredFormFields = ['hotel_information'];
const disabledExceptFormatFormFields = ['representative_name'];
const disabledExceptQuestionFormFields: string[] = [];
const disableConstraintFormFields = ['consent_form'];
const uniqueFields = [
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'international_phone',
  'consent_form',
  'hotel_tbd_form',
];

const isRemovableField = (
  fieldName: string,
  product: Product | null
): boolean => {
  if (unRemovableFields.includes(fieldName)) {
    return false;
  }

  // NOTE(goro) : can remove the form to input hotel information at BookingWidget&PMP when no transportations
  if (
    (fieldName === 'hotel_information' || fieldName === 'hotel_tbd_form') &&
    product?.transportations?.length !== 0
  ) {
    return false;
  }

  return true;
};

const isFormatFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptFormatFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isRequiredFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptRequiredFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isQuestionFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptQuestionFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isConstraintFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) ||
    disableConstraintFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isFormDisabled = (fieldName: string): boolean => {
  if (disabledFields.includes(fieldName)) {
    return true;
  }

  return false;
};

const isKanaAvailableField = (fieldName: string): boolean => {
  if (fieldName === 'representative_name') {
    return true;
  }

  return false;
};

const getFormatOptions = (t: TranslateFuncType, fieldName: string) => {
  return [
    ...[
      {
        value: 'alpha-name',
        text: t('Alphabet characters or spaces'),
      },
    ],
    ...(isKanaAvailableField(fieldName)
      ? [
          {
            value: 'kana-name',
            text: t('Kana or Alphabet characters'),
          },
          {
            value: 'alpha-kana-name',
            text: t('Alphabet characters or spaces form & Kana form'),
          },
        ]
      : [
          {
            value: 'email',
            text: t('Email'),
          },
          {
            value: 'phone',
            text: t('Phone Number'),
          },
          {
            value: 'friendly_phone',
            text: t('Phone Number (Friendly)'),
          },
          {
            value: 'international_phone',
            text: t('International Phone Number'),
          },
          {
            value: 'float',
            text: t('Numeric'),
          },
          {
            value: 'non-negative-integer',
            text: t('Positive Numeric'),
          },
          {
            value: 'yyyy-mm-dd',
            text: t('YYYY-MM-DD'),
          },
          {
            value: 'datetime-local',
            text: t('Date/Time'),
          },
          {
            value: 'file-upload',
            text: t('File Upload'),
          },
          ...(config.enableFieldResponseImageUpload
            ? [
                {
                  text: t('Image Upload'),
                  value: 'image-upload',
                },
              ]
            : []),
        ]),
  ];
};

const getDefaultFormField = (): FormField => {
  return {
    key: 'custom',
    fieldType: 'PER_PARTICIPANT',
    prompt: '',
    required: 'WHEN_BOOKING',
    choices: [],
    responseConstraint: 'NO_RESTRICTION',
    format: '',
    inputElement: 'TEXT_BOX',
  };
};

export const ReservationFormEditor = () => {
  const { t } = useTranslation();
  // NOTE(goro) : override CSS definition
  const transparentStyle = {
    backgroundColor: 'transparent',
  };
  const editingProduct = useContext(EditingProductContext);
  const sourceLanguageUppercaseIso = editingProduct?.source_language ?? 'JA_JP';
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];
  const form = useForm();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);
  const { required } = getValidators(t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  return (
    <>
      <FieldArray name="formFields">
        {({ fields }) => (
          <>
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <div className={styles['p-list__item__ttl__txt']}>
                    {t('Special Requests')}
                  </div>
                </div>
                <div className={styles['p-list__item__body']}>
                  <Field name="showAgentNotesInputDuringGuestCheckout">
                    {({ input }) => (
                      <Toggle
                        label={t(
                          'Show input form for "Special Requests" on the checkout page.'
                        )}
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                        size="sm"
                      />
                    )}
                  </Field>
                </div>
              </li>

              {fields.length !== 0 &&
                fields.map((name, idx) => (
                  <CollapsibleBox
                    key={name}
                    title={`#${idx + 1} ${fields.value[idx].prompt}`}
                    onDelete={() => fields.remove(idx)}
                    // Flag which form item can be deleted or not
                    isDeleteable={isRemovableField(
                      fields.value[idx].key,
                      editingProduct
                    )}
                  >
                    <ul key={name} className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            {t('Form Input Name')}
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name={`${name}.key`}>
                            {({ input }) => (
                              <SingleDropdown
                                disabled={isFormDisabled(fields.value[idx].key)}
                                options={getCommonFormFields(
                                  t,
                                  sourceLanguageLowercaseIso
                                ).filter((formField) => {
                                  return !(
                                    uniqueFields.includes(formField.value) &&
                                    formField.value !== input.value &&
                                    fields.value.find((field) => {
                                      return field.key === formField.value;
                                    })
                                  );
                                })}
                                selectedOption={input.value}
                                onChange={(value) => {
                                  const formField = getCommonFormFields(
                                    t,
                                    sourceLanguageLowercaseIso
                                  ).find((field) => field.value === value);

                                  if (value !== 'custom') {
                                    form?.change(
                                      `${name}.prompt`,
                                      formField?.text || ''
                                    );
                                    form?.change(
                                      `${name}.fieldType`,
                                      formField?.defaultType ||
                                        'PER_PARTICIPANT'
                                    );
                                    form?.change(
                                      `${name}.responseConstraint`,
                                      formField?.responseConstraint || 'NONE'
                                    );
                                    form?.change(
                                      `${name}.format`,
                                      formField?.format || ''
                                    );
                                    form?.change(
                                      `${name}.choices`,
                                      formField?.choices || []
                                    );
                                    form?.change(
                                      `${name}.required`,
                                      formField?.required || 'WHEN_BOOKING'
                                    );
                                    form.change(
                                      `${name}.inputElement`,
                                      formField?.responseConstraint ===
                                        'NO_RESTRICTION'
                                        ? 'TEXT_BOX'
                                        : undefined
                                    );
                                  }
                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            {t('Form Input Type')}
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <EnumRadioButtonGroup
                            disabled={isFormDisabled(fields.value[idx].key)}
                            name={`${name}.fieldType`}
                            options={[
                              {
                                value: 'PER_PARTICIPANT',
                                label: t('Per-participant'),
                                tooltipText: t(
                                  'Specify the question you want to ask for each participant (answers required for the number of people)'
                                ),
                              },
                              {
                                value: 'PER_BOOKING',
                                label: t('Per-booking'),
                                tooltipText: t(
                                  'Specify the question you want to ask for each reservation (one answer is enough for each reservation)'
                                ),
                              },
                            ]}
                          />
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            {t('Required / Optional')}
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <EnumRadioButtonGroup
                            disabled={isRequiredFormDisabled(
                              fields.value[idx].key
                            )}
                            name={`${name}.required`}
                            options={[
                              {
                                value: 'WHEN_BOOKING',
                                label: t('Required when Booking'),
                                tooltipText: t(
                                  'Select if you want to require an answer when making a reservation (reservation is not possible without an answer)'
                                ),
                              },
                              {
                                value: 'BEFORE_PARTICIPATING',
                                label: t('Required before participation'),
                                tooltipText: t(
                                  'Select if you want to require an answer by the time you participate (you can make a reservation without an answer)'
                                ),
                              },
                              {
                                value: 'OPTIONAL',
                                label: t('Optional'),
                                tooltipText: t(
                                  'Select if you want the answer to be optional (reservation is possible without answering)'
                                ),
                              },
                            ]}
                          />
                          {fields.value[idx].key === 'hotel_information' && (
                            <div className={baseStyles['u-mt-4']}>
                              <Field name="showHotelInformationInputForCheckinCheckout">
                                {({ input }) => (
                                  <Toggle
                                    label={t(
                                      'Ask for hotel information for checkin/checkout reservations'
                                    )}
                                    checked={input.value}
                                    onChange={() => {
                                      input.onChange(!input.value);
                                    }}
                                    size="sm"
                                  />
                                )}
                              </Field>
                            </div>
                          )}
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            <p style={{ marginRight: '4px' }}>
                              {t('Question Text')}
                            </p>
                            <Tooltip
                              text={t(
                                'Enter the question text to be displayed on the reservation screen'
                              )}
                            >
                              <i className="c-icon-outline-general-info-circle"></i>
                            </Tooltip>
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <TranslatedField
                            name={`${name}.prompt`}
                            validate={required}
                          >
                            {({
                              input,
                              translationInput,
                              meta: { error, touched },
                            }) => (
                              <>
                                <TextArea
                                  fullContainerWidth={true}
                                  {...(input as any)}
                                  disabled={isQuestionFormDisabled(
                                    fields.value[idx].key
                                  )}
                                  height={80}
                                  error={touched && error}
                                />
                                {translationTargetLanguage && (
                                  <TextArea
                                    {...(translationInput as any)}
                                    height={80}
                                    error={touched && error}
                                  />
                                )}
                              </>
                            )}
                          </TranslatedField>
                        </div>
                      </li>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <div className={styles['p-list__item__ttl__txt']}>
                            {t('Response Constraint')}
                          </div>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <Field name={`${name}.responseConstraint`}>
                            {({ input: { value } }) => (
                              <div
                                className={
                                  styles['p-reservationForm__item__list']
                                }
                              >
                                <div>
                                  {/* TODO: temporary styling using style directly */}
                                  <EnumRadioButtonGroup
                                    disabled={isConstraintFormDisabled(
                                      fields.value[idx].key
                                    )}
                                    name={`${name}.responseConstraint`}
                                    options={[
                                      {
                                        value: 'CHOICES',
                                        label: t('Choices'),
                                        tooltipText: t(
                                          'Choose from provided options'
                                        ),
                                      },
                                      {
                                        value: 'FORMAT',
                                        label: t('Text Format'),
                                        tooltipText: t(
                                          'Input required to be in specified format'
                                        ),
                                      },
                                      {
                                        value: 'NO_RESTRICTION',
                                        label: t('None'),
                                        tooltipText: t('Allow free text entry'),
                                      },
                                    ]}
                                    onChange={(newValue) => {
                                      switch (newValue) {
                                        case 'CHOICES':
                                          form?.change(`${name}.format`, '');
                                          form?.change(`${name}.choices`, ['']);
                                          form.change(
                                            `${name}.inputElement`,
                                            undefined
                                          );
                                          break;

                                        case 'FORMAT':
                                          form.change(`${name}.choices`, []);
                                          form.change(
                                            `${name}.format`,
                                            'alpha-name'
                                          );
                                          form.change(
                                            `${name}.inputElement`,
                                            undefined
                                          );
                                          break;

                                        default:
                                          form.change(`${name}.format`, '');
                                          form.change(`${name}.choices`, []);
                                          form.change(
                                            `${name}.inputElement`,
                                            'TEXT_BOX'
                                          );
                                          break;
                                      }
                                    }}
                                  />
                                </div>
                                {value === 'NO_RESTRICTION' &&
                                  !isConstraintFormDisabled(
                                    fields.value[idx].key
                                  ) && (
                                    <div>
                                      <p>{t('Input Element')}</p>
                                      <Field name={`${name}.inputElement`}>
                                        {({ input }) => (
                                          <SingleDropdown
                                            options={[
                                              {
                                                value: 'TEXT_BOX',
                                                text: t('Text box'),
                                              },
                                              {
                                                value: 'TEXT_AREA',
                                                text: t('Text area'),
                                              },
                                            ]}
                                            selectedOption={input.value}
                                            onChange={(value) => {
                                              input.onChange(value);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  )}
                                {value === 'FORMAT' && (
                                  <div>
                                    <p>{t('Text Format')}</p>
                                    <Field name={`${name}.format`}>
                                      {({ input }) => (
                                        <SingleDropdown
                                          disabled={isFormatFormDisabled(
                                            fields.value[idx].key
                                          )}
                                          options={getFormatOptions(
                                            t,
                                            fields.value[idx].key
                                          )}
                                          selectedOption={input.value}
                                          onChange={(value) => {
                                            input.onChange(value);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                )}
                                {value === 'CHOICES' && (
                                  <div>
                                    <p>{t('Choices')}</p>
                                    <ul>
                                      <ListEditor
                                        fieldDisabled={isFormDisabled(
                                          fields.value[idx].key
                                        )}
                                        buttonDisabled={isFormDisabled(
                                          fields.value[idx].key
                                        )}
                                        name={`${name}.choices`}
                                        style={transparentStyle}
                                        addButtonText={t('Add Choice')}
                                      />
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                      </li>
                      {isNutmegAdmin && (
                        <>
                          <li className={styles['p-list__item']}>
                            <div className={styles['p-list__item__ttl']}>
                              <div className={styles['p-list__item__ttl__txt']}>
                                {t('Agent References')}
                              </div>
                            </div>
                            <div className={styles['p-list__item__body']}>
                              <AgentReferencesEditor
                                name={`${name}.referencesInAgentSystems`}
                              />
                            </div>
                          </li>
                          {/* TODO: not sure if this is used currently */}
                          {editingProduct?.gateway_name &&
                            editingProduct?.gateway_name !== 'nutmeg' && (
                              <li className={styles['p-list__item']}>
                                <div className={styles['p-list__item__ttl']}>
                                  <div
                                    className={styles['p-list__item__ttl__txt']}
                                  >
                                    {t('Supplier System Reference')}
                                  </div>
                                </div>
                                <div className={styles['p-list__item__body']}>
                                  <Field
                                    name={`${name}.referenceInSupplierSystem`}
                                  >
                                    {({ input }) => <TextField {...input} />}
                                  </Field>
                                </div>
                              </li>
                            )}
                        </>
                      )}
                    </ul>
                  </CollapsibleBox>
                ))}
            </ul>

            <div className={fields.length === 0 ? '' : baseStyles['u-mt-4']}>
              <a
                className={styles['p-box__table2__actions__add']}
                onClick={() => {
                  if (fields.length && fields.length > 0) {
                    (fields as any).insertAt(
                      fields.length + 1,
                      getDefaultFormField()
                    );
                  } else {
                    (fields as any).insertAt(0, getDefaultFormField());
                  }
                }}
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add Form Item')}
              </a>
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
};
