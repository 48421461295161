import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { MarketingAutomationContent } from 'shared/models/swagger';
import {
  createMarketingAutomationContent,
  deleteMarketingAutomationContent,
  fetchMarketingAutomationContents,
} from 'client/actions/marketingAutomationContents';
import { TranslateFuncType } from 'client/components/Translate';

const getTypeText = (
  type: MarketingAutomationContent['type'],
  t: TranslateFuncType
): string => {
  switch (type) {
    case 'NEWSLETTER':
      return t('Newsletter');
    case 'WEBSITE_POPUP':
      return t('Website Popup');
    case 'EMAIL':
      return t('Email');
    case 'SMS':
      return t('SMS');
    case 'PUSH_NOTIFICATION':
      return t('Push Notification');
  }

  return '';
};

const EditCopyDeleteCell = ({
  content,
}: {
  content: MarketingAutomationContent;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.loading
  );
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();

  return (
    <Box display="flex">
      <Link to={`/marketingautomation/contents/${content.id}/edit`}>
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createMarketingAutomationContent({
              title: `${content.title} [COPY]`,
              type: content.type,
              website_popup_content: content.website_popup_content,
              email_content: content.email_content,
              sms_content: content.sms_content,
              push_notification_content: content.push_notification_content,
            })
          );

          const lastCreatedContent =
            store.getState().marketingAutomationContents.lastCreatedContent;
          if (lastCreatedContent) {
            history.push(
              `/marketingautomation/contents/${lastCreatedContent.id}/edit`
            );
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Content')}
            content={t('Are you sure you want to delete content?')}
            onConfirm={async () => {
              await dispatch(
                deleteMarketingAutomationContent(content.id as string)
              );
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<MarketingAutomationContent>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (content) => <EditCopyDeleteCell content={content} />,
      width: 150,
    } as ColumnType<MarketingAutomationContent>,
    {
      Header: t('Title'),
      id: 'title',
      accessor: (content) => content.title,
    },
    {
      Header: t('Type'),
      id: 'type',
      accessor: (content) => getTypeText(content.type, t),
    },
  ];
};

export const MarketingAutomationContentList = () => {
  const dispatch = useDispatch();

  const columns = useColumns();

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationContents());
  }, []);

  const contents = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.all
  );

  const sortedContents = _.orderBy(contents, 'title', 'asc');

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to="/marketingautomation/contents/new">
            <Button style="green" size="middle">
              {t('Create New Content')}
            </Button>
          </Link>
        </Box>
      </Box>
      <PagedGenericTable allItems={sortedContents} columns={columns} />
    </div>
  );
};
