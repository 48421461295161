import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ProductSummary } from 'shared/models/swagger';

interface Props {
  supplierId: string;
  products: ProductSummary[];
}

export const PartnershipSupplierProductsDisplayBox = ({
  supplierId,
  products,
}: Props) => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const supplierProducts =
    activeUserOrganization?.partnership_settings?.supplier_products || [];
  const selectedProducts =
    supplierProducts.find(
      (supplierProduct) => supplierProduct.supplier_id === supplierId
    )?.products || [];

  return (
    <ul>
      {selectedProducts
        .filter((partnershipProduct) => partnershipProduct.status === 'SALE')
        .map((partnershipProduct) => {
          const product = products.find(
            (product) => product.id === partnershipProduct.product_id
          );
          return <li key={product?.id ?? ''}>{product?.product_name ?? ''}</li>;
        })}
    </ul>
  );
};
