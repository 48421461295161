import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import * as Swagger from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { ReduxState } from 'client/reducers';

import styles from './ActionButton.module.css';
import { DeleteAreaGroupModal } from './DeleteAreaGroupModal';

type Props = {
  areaGroup: Swagger.CustomerAreaGroup;
  onEdit: () => void;
  lastItemOnList: boolean;
};

export const ActionButton = ({ areaGroup, onEdit, lastItemOnList }: Props) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
      <div>
        <Button
          uiType="bg"
          size="icon"
          color="white"
          iconBeforeText={
            <i className="c-icon-solid-general-dots-vertical"></i>
          }
          onClick={() => setShowMenu(!showMenu)}
          loading={loading}
        />
      </div>
      <ul
        className={clsx(
          styles['p-primary__select__menu'],
          showMenu && styles['is-active'],
          lastItemOnList && styles['open-above']
        )}
        ref={menuRef}
      >
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={() => {
            onEdit();
            setShowMenu(false);
          }}
        >
          <div>
            <i className="c-icon-outline-general-edit-05"></i>
            <div>{t('Edit Area Group')}</div>
          </div>
        </li>
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={async () => {
            const orgId = activeUserOrganization?.id || '';
            try {
              const existingCustomerAreaGroups =
                activeUserOrganization?.customer_ledger_settings
                  ?.customer_area_groups ?? [];
              const copyCustomerAreaGroup = {
                ...areaGroup,
                key: uuidv4(),
                name: `${areaGroup.name} [COPY]`,
              };
              existingCustomerAreaGroups.push(copyCustomerAreaGroup);
              await dispatch(
                updateOrganization(orgId, 'SUPPLIER', {
                  customer_ledger_settings: {
                    ...activeUserOrganization?.customer_ledger_settings,
                    customer_area_groups: existingCustomerAreaGroups,
                  },
                })
              );
              await setShowMenu(false);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <div>
            <i className="c-icon-outline-general-copy-01"></i>
            <div>{t('Copy Area Group')}</div>
          </div>
        </li>
        <li
          className={clsx(
            styles['p-primary__select__menu__item'],
            styles['red']
          )}
          onClick={() => setShowModal(true)}
        >
          <div>
            <i className="c-icon-outline-general-trash-03"></i>
            <div>{t('Delete Area Group')}</div>
          </div>
        </li>
      </ul>
      {showModal && (
        <DeleteAreaGroupModal
          areaGroupName={areaGroup.name ?? ''}
          areaGroupKey={areaGroup.key ?? ''}
          onClose={() => {
            setShowModal(false);
            setShowMenu(false);
          }}
        />
      )}
    </fieldset>
  );
};
