export const withQueryParams = (
  url: string,
  queryParams: {
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
    lng: string;
  }
) => {
  queryParams.lng = convertToBookingWidgetUrlLangCode(queryParams.lng);

  if (url.includes('?')) {
    return `${url}&${new URLSearchParams(queryParams).toString()}`;
  }

  return `${url}?${new URLSearchParams(queryParams).toString()}`;
};

export const truncated = (s: string, length: number) => {
  if (s.length <= length) {
    return s;
  }

  return `${s.slice(0, length)}...`;
};

const bookingWidgetUrlLangCodeMap: Record<string, string> = {
  en: 'en-US',
  ja: 'ja-JP',
  ko: 'ko-KR',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};

export const convertToBookingWidgetUrlLangCode = (lang: string): string => {
  return bookingWidgetUrlLangCodeMap[lang] || lang;
};

export interface ProductCardFields {
  productId: string;
  productThumbnailUrl: string;
  productUrl: string;
  productName: string;
  productDescription: string;
  buttonText: string;
}

export interface ProductList {
  title: string;
  products: ProductCardFields[];
}

export interface CustomCard {
  title: string;
  description: string;
  linkUrl: string;
  thumbnailUrl: string;
  buttonText: string;
}

export interface Link {
  text: string;
  url: string;
}

export interface RichPlan {
  key: string;
  title: string;
  keyVisualUrl: string;
  highlightText: string;
  highlights: string[];
  linkType: 'PRODUCT' | 'CUSTOM_PAGE';
  linkUrl: string;
  productThumbnailUrl: string;
  productId: string;
  productName: string;
  productDescription: string;
  detailsButtonText: string;
}

// Group products into pairs
export const groupedProducts = (
  products: ProductCardFields[]
): ProductCardFields[][] => {
  return products.reduce(
    (
      result: ProductCardFields[][],
      product: ProductCardFields,
      index: number
    ) => {
      if (index % 2 === 0) {
        result.push([product]);
      } else {
        result[result.length - 1].push(product);
      }
      return result;
    },
    []
  );
};
