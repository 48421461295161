import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Button } from 'client/components/Form';
import { calculateReservationFares } from 'client/actions/fares';
import type {
  NewReservation,
  ReservationBillingInfoLineItem,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  reservation: NewReservation;
  onChange: (arg0: string) => void;
};
export const PromoCodeInput = ({ reservation, onChange }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const checkAvailablePromoCode = async () => {
    setErrorMessage('');

    try {
      const resp: any = await dispatch(calculateReservationFares(reservation));

      if (
        (resp.response?.line_items || []).every(
          (lineItem: ReservationBillingInfoLineItem) =>
            (lineItem.amount_gross || '').indexOf('-') === -1
        )
      ) {
        setErrorMessage(t('Unapplicable promo code'));
      }
    } catch (err) {
      setErrorMessage(t('Could not check this promo code'));
    }
  };

  return (
    <div className={baseStyles['inline-block']}>
      <div
        style={{
          width: '200px',
          marginRight: '8px',
        }}
      >
        <Input
          value={reservation?.promo_code || ''}
          onChange={(e, { value: promoCode }) => {
            onChange(promoCode);
          }}
          colorStyle={errorMessage ? 'error' : undefined}
        />
      </div>
      <Button
        size={'middle'}
        style={'green'}
        type={'button'}
        disabled={
          (reservation?.guests || []).length === 0 ||
          (reservation?.promo_code || '').length === 0
        }
        onClick={() => {
          checkAvailablePromoCode();
        }}
      >
        {t('Apply')}
      </Button>
      <div>
        {errorMessage && (
          <p className={baseStyles['base-form-box__err']}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};
