import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { FloatInput } from 'client/components/FloatInput';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  createETicketLocation,
  updateETicketLocation,
} from 'client/actions/eTicketLocation';
import {
  LocationSearchInput,
  SearchResult,
} from 'client/components/LocationSearchInput';
import * as Swagger from 'shared/models/swagger';

import {
  getInitialValues,
  convertFormValuesToSwagger,
  FormValues,
} from './formValues';

type Props = {
  existingETicketLocation?: Swagger.ETicketLocation | null;
  open: boolean;
  onClose: () => void;
  eTicketLocationSetId?: string;
};

export const EditETicketLocationModal = ({
  open,
  onClose,
  existingETicketLocation,
  eTicketLocationSetId,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [locationSearchResult, setLocationSearchResult] =
    React.useState<SearchResult | null>(null);

  const initialValues = React.useMemo(
    () => getInitialValues(existingETicketLocation),
    [existingETicketLocation]
  );

  return (
    <Modal
      title={t('Edit E-ticket Location')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <Form
        initialValues={initialValues}
        debug={console.log}
        onSubmit={async (values: FormValues) => {
          try {
            if (existingETicketLocation) {
              await dispatch(
                updateETicketLocation(
                  existingETicketLocation.id ?? '',
                  convertFormValuesToSwagger(values, eTicketLocationSetId ?? '')
                )
              );
            } else {
              await dispatch(
                createETicketLocation(
                  convertFormValuesToSwagger(values, eTicketLocationSetId ?? '')
                )
              );
            }
          } catch (e) {
            console.error(e);
          } finally {
            onClose();
          }
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ form, handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              <LocationSearchInput
                prompt={t('Find a location')}
                onLocationSelect={(result) => setLocationSearchResult(result)}
                location={locationSearchResult?.title ?? ''}
              />
              <Box mt={2} mb={2}>
                <Button
                  style="green"
                  size="middle"
                  onClick={async () => {
                    if (locationSearchResult) {
                      const geocoder = new google.maps.Geocoder();

                      try {
                        const result = await geocoder.geocode({
                          placeId: locationSearchResult?.key ?? '',
                        });

                        form.change(
                          'latitude',
                          result.results[0].geometry.location.lat()
                        );
                        form.change(
                          'longitude',
                          result.results[0].geometry.location.lng()
                        );
                        form.change('name', locationSearchResult.title);
                      } catch (e) {
                        console.log('error: ', e);
                      }
                    }
                  }}
                >
                  {t('Populate Location Data')}
                </Button>
              </Box>
              <Field name="name">
                {({ input }) => (
                  <Input
                    label={t('Location Name')}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              <Box mt={2}>
                <Field name="description">
                  {({ input }) => (
                    <TextArea
                      label={t('Location Description')}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Box>
              <Box display="flex" mt={2}>
                <Box maxWidth="300px">
                  <FieldWrapper label={t('Latitude')}>
                    <Field name="latitude">
                      {({ input }) => (
                        <FloatInput
                          value={input.value}
                          onChange={(amount) => input.onChange(amount)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Box>
                <Box ml={2} maxWidth="300px">
                  <FieldWrapper label={t('Longitude')}>
                    <Field name="longitude">
                      {({ input }) => (
                        <FloatInput
                          value={input.value}
                          onChange={(amount) => input.onChange(amount)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Box>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
