import { Field } from 'react-final-form';

import { Box } from 'client/components/Box/Box';

import { DownloadItemInput } from './DownloadItemInput';

export const EditInstantWinTierDownloadForm = () => {
  return (
    <Box mb={2}>
      <Field name="downloadItems">
        {({ input }) => (
          <DownloadItemInput
            mediaItems={input.value || []}
            onChange={(newValue) => {
              console.log('newValue', newValue);
              input.onChange(newValue);
            }}
          />
        )}
      </Field>
    </Box>
  );
};
