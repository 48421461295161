import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  UPLOAD_JARAN_RESERVATION_CSV_FAILURE,
  UPLOAD_JARAN_RESERVATION_CSV_REQUEST,
  UPLOAD_JARAN_RESERVATION_CSV_SUCCESS,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import type { ImportJaranCsvRequest } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchJaranImportReservationOrdersRequest = () => ({
  type: FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST,
});

const fetchJaranImportReservationOrdersSuccess = (response: any) => ({
  type: FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS,
  response,
});

const fetchJaranImportReservationOrdersFailure = (error: any) => ({
  type: FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE,
  error,
});

const fetchJaranImportReservationOrdersCanceled = () => ({
  type: FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED,
});

let fetchJaranImportReservationOrdersCancel: () => void | typeof undefined;

export const fetchJaranImportReservationOrders =
  (supplier_id?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchJaranImportReservationOrdersCancel &&
      fetchJaranImportReservationOrdersCancel();
    dispatch(fetchJaranImportReservationOrdersRequest());
    const params = {
      supplier_id,
    };
    axios
      .get('/api/jaran/importorders', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchJaranImportReservationOrdersCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchJaranImportReservationOrdersSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchJaranImportReservationOrdersCanceled());
        else dispatch(fetchJaranImportReservationOrdersFailure(error));
      });
  };

const uploadJaranReservationCsvRequest = () => ({
  type: UPLOAD_JARAN_RESERVATION_CSV_REQUEST,
});

const uploadJaranReservationCsvSuccess = (response: any) => ({
  type: UPLOAD_JARAN_RESERVATION_CSV_SUCCESS,
  response,
});

const uploadJaranReservationCsvFailure = (error: any) => ({
  type: UPLOAD_JARAN_RESERVATION_CSV_FAILURE,
  error,
});

export const uploadJaranReservationCsv =
  (req: ImportJaranCsvRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(uploadJaranReservationCsvRequest());
    return axios
      .post('/api/jaran/importcsv', req.fileData, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(uploadJaranReservationCsvSuccess(result.data)))
      .catch((error) => dispatch(uploadJaranReservationCsvFailure(error)));
  };
