import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { sequentialBatchUpdateReservations } from 'client/actions/reservations';
import { visibleColumnsForDispatchSelector } from 'client/reducers/dispatchTableControls';
import {
  allDispatchVehiclesSelector,
  allDispatchMiscResourcesSelector,
} from 'client/reducers/dispatchSettings';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers/index';
import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper, Button, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { ResourceAssignmentReservationsTable } from 'client/components/ResourceAssignmentModal/ResourceAssignmentReservationsTable';
import { getAssignedResources } from 'client/components/ResourceAssignmentModal/resources';
import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import { Divider } from 'client/components/Divider/Divider';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import type {
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';

const reservationIncludesPickup = (
  reservation: ManifestReservationShape,
  pickupLocationFilter: string
) =>
  reservation.pickup_checkin_location === pickupLocationFilter ||
  reservation.transport_route?.some(
    (routeItem) =>
      routeItem.location_from?.location_name === pickupLocationFilter
  );

const reservationIncludesDropoff = (
  reservation: ManifestReservationShape,
  dropoffLocationFilter: string
) =>
  reservation.dropoff_checkout_location === dropoffLocationFilter ||
  reservation.transport_route?.some(
    (routeItem) =>
      routeItem.location_to?.location_name === dropoffLocationFilter
  );

const filteredReservations = (
  reservations: ManifestReservationShape[],
  pickupLocationFilter: string,
  dropoffLocationFilter: string
): ManifestReservationShape[] => {
  return reservations.filter(
    (reservation) =>
      (!pickupLocationFilter ||
        reservationIncludesPickup(reservation, pickupLocationFilter)) &&
      (!dropoffLocationFilter ||
        reservationIncludesDropoff(reservation, dropoffLocationFilter))
  );
};

type Props = {
  trigger: React.ReactElement<'a' | 'button'>;
  resourceType: ResourceType;
  resourceKey: string;
  title: string;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  visibleColumns?: ReservationColumn[];
};
export const ResourceAssignmentModal = ({
  trigger,
  resourceType,
  resourceKey,
  title,
  customizedColumnNames,
  visibleColumns,
}: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [updateRequests, setUpdateRequests] = React.useState<any[]>([]);
  const [pickupLocationFilter, setPickupLocationFilter] = React.useState('');
  const [dropoffLocationFilter, setDropoffLocationFilter] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const allReservations = useSelector((state: ReduxState) =>
    state.dispatch.reservations.map((r) => toManifestReservationShape(r, t))
  );
  const allDispatchMiscResources = useSelector(
    allDispatchMiscResourcesSelector
  );
  const resourceViewVisibleColumns = useSelector(
    visibleColumnsForDispatchSelector
  );
  const allDispatchVehicles = useSelector(allDispatchVehiclesSelector);

  const handleOpen = () => setShowModal(true);

  const handleClose = () => setShowModal(false);

  const reservationWillBeAssignedSelectedResource = (
    reservation: ManifestReservationShape
  ) => {
    const updateRequest = updateRequests.find(
      (req) => req.id === reservation.id
    );

    if (updateRequest) {
      const updateRequestResourceList = getAssignedResources(
        updateRequest.patch,
        resourceType
      );
      return updateRequestResourceList.indexOf(resourceKey) !== -1;
    }

    return (
      getAssignedResources(reservation, resourceType).indexOf(resourceKey) !==
      -1
    );
  };

  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);
  const productsById = useSelector((state: ReduxState) => state.products.byID);
  const products = [
    ...new Set(allReservations.map((reservation) => reservation.product_id)),
  ]
    .map((productId) => productsById[productId])
    .filter((p) => Boolean(p));
  let resourceTypeText = '';

  switch (resourceType) {
    case 'vehicle':
      resourceTypeText = t('Vehicle');
      break;

    case 'crew':
      resourceTypeText = t('Crew Member');
      break;

    case 'other':
      resourceTypeText = t('Other');
      break;

    case 'guide':
      resourceTypeText = t('Staff');
      break;

    default:
      break;
  }

  const reservationsByStartTime = groupReservationsByStartTime(
    allReservations,
    locale
  );
  const capacityDescriptions = reservationsByStartTime.map(
    ({ startTime, reservations }) => {
      let capacityDescription = '';

      if (resourceType === 'vehicle' || resourceType === 'other') {
        const resourceList =
          resourceType === 'vehicle'
            ? allDispatchVehicles
            : allDispatchMiscResources;
        const resource = resourceList.find(
          (resource) => resource.key === resourceKey
        );
        let totalCapacity = 0;

        if (resource && resource.capacity) {
          totalCapacity = resource.capacity;
        }

        let currentOccupiedCapacity = 0;
        let newOccupiedCapacity = 0;
        reservations.forEach((reservation) => {
          if (
            getAssignedResources(reservation, resourceType).indexOf(
              resourceKey
            ) !== -1
          ) {
            currentOccupiedCapacity += reservation.guests.length;
          }

          if (reservationWillBeAssignedSelectedResource(reservation)) {
            newOccupiedCapacity += reservation.guests.length;
          }
        });

        if (currentOccupiedCapacity === newOccupiedCapacity) {
          capacityDescription = `(${currentOccupiedCapacity}/${totalCapacity})`;
        } else {
          capacityDescription = `(${currentOccupiedCapacity}/${totalCapacity}) -> (${newOccupiedCapacity}/${totalCapacity})`;
        }
      }

      return `${startTime} : ${capacityDescription}`;
    }
  );
  const pickupFilterOptions = [
    {
      value: '',
      key: '',
      text: t('not selected'),
    },
    ...[
      ...new Set(
        _.flatten(
          allReservations.map((reservation) =>
            [
              reservation.pickup_checkin_location,
              ...(reservation.transport_route ?? []).map(
                (routeItem) => routeItem.location_from?.location_name
              ),
            ].filter((loc) => Boolean(loc))
          )
        )
      ),
    ].map((option) => ({
      value: option ?? '',
      key: option ?? '',
      text: option ?? '',
    })),
  ];
  const dropoffFilterOptions = [
    {
      value: '',
      key: '',
      text: t('not selected'),
    },
    ...[
      ...new Set(
        _.flatten(
          allReservations.map((reservation) =>
            [
              reservation.dropoff_checkout_location,
              ...(reservation.transport_route ?? []).map(
                (routeItem) => routeItem.location_to?.location_name
              ),
            ].filter((loc) => Boolean(loc))
          )
        )
      ),
    ].map((option) => ({
      value: option ?? '',
      key: option ?? '',
      text: option ?? '',
    })),
  ];
  return (
    <Modal
      title={t('Assign Resource')}
      trigger={trigger}
      open={showModal}
      onClose={handleClose}
      onOpen={handleOpen}
      width="wide"
    >
      <Modal.Content>
        <div>
          <div>
            <div>
              <div>
                <FieldWrapper label={t('Resource Type')}>
                  {resourceTypeText}
                </FieldWrapper>

                <FieldWrapper label={t('Resource')}>
                  {title || resourceKey}
                </FieldWrapper>

                {resourceType !== 'crew' && resourceType !== 'guide' && (
                  <FieldWrapper label={t('Capacity')}>
                    <ul>
                      {capacityDescriptions.map((capacityDescription, idx) => (
                        <li key={idx}>{capacityDescription}</li>
                      ))}
                    </ul>
                  </FieldWrapper>
                )}

                <Box display="flex">
                  <Box>
                    <Select
                      label={t('Filter by pickup location')}
                      options={pickupFilterOptions}
                      value={pickupLocationFilter}
                      onChange={(e, { value }) =>
                        setPickupLocationFilter(value)
                      }
                    />
                  </Box>
                  <Box ml={2}>
                    <Select
                      label={t('Filter by dropoff location')}
                      options={dropoffFilterOptions}
                      value={dropoffLocationFilter}
                      onChange={(e, { value }) =>
                        setDropoffLocationFilter(value)
                      }
                    />
                  </Box>
                </Box>
                <Divider />

                <FieldWrapper label={t('Select Reservations')}>
                  {reservationsByStartTime.map(
                    ({ startTime, reservations }, idx) => (
                      <React.Fragment key={idx}>
                        <h4>
                          {t('Start time: {{startTime}}', {
                            startTime,
                          })}
                        </h4>
                        <ResourceAssignmentReservationsTable
                          excludedFormFieldKeys={excludedFormFieldKeys}
                          reservations={filteredReservations(
                            reservations,
                            pickupLocationFilter,
                            dropoffLocationFilter
                          )}
                          visibleColumns={
                            visibleColumns ?? resourceViewVisibleColumns
                          }
                          products={products}
                          editingResourceType={resourceType}
                          editingResourceKey={resourceKey}
                          updateRequests={updateRequests}
                          onUpdateRequestsChanged={(newUpdateRequests) =>
                            setUpdateRequests(newUpdateRequests)
                          }
                          customizedColumnNames={customizedColumnNames || []}
                        />
                      </React.Fragment>
                    )
                  )}
                </FieldWrapper>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button.Submit
          loading={reservationLoading}
          disabled={updateRequests.length === 0}
          onClick={() => {
            dispatch(sequentialBatchUpdateReservations(updateRequests));
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
