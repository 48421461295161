import type { TranslateFuncType } from 'client/components/Translate';

import { getReservationTableColumns } from './getReservationTableColumns';

export const getReservationSummaryListColumns = (
  t: TranslateFuncType,
  locale: string
) => {
  const reservationTableColumns = getReservationTableColumns(
    t,
    locale,
    false,
    false
  ).filter(
    (c) =>
      // Filter unnecessary columns
      c.id !== 'edit' &&
      c.id !== 'agent_guest_last_updated_by_actor_name_only' &&
      c.id !== 'supplier_name'
  );

  // Add manifest fields manually
  reservationTableColumns.push(
    {
      Header: t('Dispatch resources'),
      id: 'dispatch',
    },
    {
      Header: t('Crew'),
      id: 'dispatch_crew',
    },
    {
      Header: t('Vehicle'),
      id: 'dispatch_vehicle',
    },
    {
      Header: t('Other Resources'),
      id: 'dispatch_misc_resource',
    },
    {
      Header: t('Waiver Status'),
      id: 'waiver_status',
    },
    {
      Header: t('Checkin Status'),
      id: 'checkin_status',
    },

    {
      Header: t('Required Resources'),
      id: 'required_resources',
    },
    {
      Header: t('Reservation Form: Email Address'),
      id: 'reservation_form_email',
    },
    {
      Header: t('Reservation Form: Full Name'),
      id: 'reservation_form_full_name',
    },
    {
      Header: t('Reservation Form: Full Name(Kanji)'),
      id: 'reservation_form_full_name_kanji',
    },
    { Header: t('Reservation Form: Title'), id: 'reservation_form_title' },
    {
      Header: t('Reservation Form: Phone Number'),
      id: 'reservation_form_phone',
    },
    {
      Header: t('Reservation Form: International Phone Number'),
      id: 'reservation_form_international_phone',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Name"),
      id: 'reservation_form_emergency_contact_name',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Phone Number"),
      id: 'reservation_form_emergency_contact_phone',
    },
    {
      Header: t('Reservation Form: Height (cm)'),
      id: 'reservation_form_height',
    },
    {
      Header: t('Reservation Form: Weight (kg)'),
      id: 'reservation_form_weight',
    },
    {
      Header: t('Reservation Form: Date of Birth'),
      id: 'reservation_form_date_of_birth',
    },
    { Header: t('Reservation Form: Age'), id: 'reservation_form_age' },
    { Header: t('Reservation Form: Gender'), id: 'reservation_form_gender' },
    {
      Header: t('Reservation Form: Home Address'),
      id: 'reservation_form_home_address',
    },
    {
      Header: t('Reservation Form: Consent Form'),
      id: 'reservation_form_consent_form',
    },
    {
      Header: t('Reservation Form: Family Name(Kana)'),
      id: 'reservation_form_family_name_kana',
    },
    {
      Header: t('Reservation Form: Given Name(Kana)'),
      id: 'reservation_form_given_name_kana',
    },
    { Header: t('Reservation Form: Custom'), id: 'reservation_form_custom' }
  );

  return reservationTableColumns;
};
