import * as React from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { deleteETicketLocation } from 'client/actions/eTicketLocation';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import { ETicketLocation } from 'shared/models/swagger';

import styles from './ETicketLocationMap.module.css';

type Props = {
  eTicketLocationSetId?: string;
  onEditETicketLocationChange: (
    eTicketLocation: ETicketLocation | null
  ) => void;
};

export const ETicketLocationMap = ({
  eTicketLocationSetId,
  onEditETicketLocationChange,
}: Props) => {
  const { isLoaded } = useGoogleMapsApi();

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [activeInfoWindowIndex, setActiveInfoWindowIndex] = React.useState<
    number | null
  >(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [targetETicketLocations, setTargetETicketLocations] = React.useState<
    ETicketLocation[]
  >([]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const locations = useSelector(
    (state: ReduxState) => state.eTicketLocations.all
  );

  React.useEffect(() => {
    if (map && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();

      const newLocations = locations
        .filter((loc) => Boolean(loc.e_ticket_location_set_id))
        .filter((loc) => {
          if (!eTicketLocationSetId) {
            return true;
          } else {
            return loc.e_ticket_location_set_id === eTicketLocationSetId;
          }
        });

      setTargetETicketLocations(newLocations);

      newLocations.forEach((loc) => {
        bounds.extend({ lat: loc.latitude, lng: loc.longitude });
      });
      map?.fitBounds(bounds);
    }
  }, [map, locations, eTicketLocationSetId]);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerClassName={styles['map-container']}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {targetETicketLocations.map((location, idx) => (
            <Marker
              key={idx}
              position={{
                lat: location.latitude ?? 0,
                lng: location.longitude ?? 0,
              }}
              onClick={() => setActiveInfoWindowIndex(idx)}
            >
              {activeInfoWindowIndex === idx && (
                <InfoWindow onCloseClick={() => setActiveInfoWindowIndex(null)}>
                  <div>
                    <h4>{location.location_name}</h4>
                    <Box mb={2} className="newline">
                      {location.location_description}
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <EditIcon
                        onClick={() => onEditETicketLocationChange(location)}
                      />
                      <Box ml={2}>
                        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
                      </Box>
                    </Box>
                    {showDeleteModal && (
                      <DeleteConfirmModal
                        insertRoot
                        header={t('Delete location')}
                        content={t('Are you sure you want to delete location?')}
                        onConfirm={async () => {
                          await dispatch(
                            deleteETicketLocation(location.id ?? '')
                          );
                          setActiveInfoWindowIndex(null);
                        }}
                        onClose={() => setShowDeleteModal(false)}
                        open={showDeleteModal}
                      />
                    )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      ) : null}
    </>
  );
};
