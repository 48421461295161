import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS,
  FETCH_GROUP_BOOKING_TEMPLATES_FAILURE,
  FETCH_GROUP_BOOKING_TEMPLATES_REQUEST,
  FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS,
  FETCH_GROUP_BOOKING_TEMPLATE_FAILURE,
  FETCH_GROUP_BOOKING_TEMPLATE_REQUEST,
  DELETE_GROUP_BOOKING_TEMPLATE_FAILURE,
  DELETE_GROUP_BOOKING_TEMPLATE_REQUEST,
  DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  CREATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  CREATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { GroupBookingTemplateParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGroupBookingTemplatesRequest = () => ({
  type: FETCH_GROUP_BOOKING_TEMPLATES_REQUEST,
});

const fetchGroupBookingTemplatesSuccess = (response: any) => ({
  type: FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS,
  response,
});

const fetchGroupBookingTemplatesFailure = (error: any) => ({
  type: FETCH_GROUP_BOOKING_TEMPLATES_FAILURE,
  error,
});

let fetchGroupBookingTemplatesCancel: () => void | undefined;
export const fetchGroupBookingTemplates =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGroupBookingTemplatesCancel?.();
    dispatch(fetchGroupBookingTemplatesRequest());
    axios
      .get('/api/groupbookingtemplates', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGroupBookingTemplatesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchGroupBookingTemplatesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGroupBookingTemplatesFailure('canceled'));
        dispatch(fetchGroupBookingTemplatesFailure(error));
      });
  };

const fetchGroupBookingTemplateRequest = () => ({
  type: FETCH_GROUP_BOOKING_TEMPLATE_REQUEST,
});

const fetchGroupBookingTemplateSuccess = (response: any) => ({
  type: FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS,
  response,
});

const fetchGroupBookingTemplateFailure = (error: any) => ({
  type: FETCH_GROUP_BOOKING_TEMPLATE_FAILURE,
  error,
});

export const fetchGroupBookingTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchGroupBookingTemplateRequest());
    axios
      .get(`/api/groupbookingtemplates/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchGroupBookingTemplateSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGroupBookingTemplateFailure('canceled'));
        else dispatch(fetchGroupBookingTemplateFailure(error));
      });
  };

const deleteGroupBookingTemplateRequest = (id: string) => ({
  type: DELETE_GROUP_BOOKING_TEMPLATE_REQUEST,
  id,
});

const deleteGroupBookingTemplateSuccess = (response: any, id: string) => ({
  type: DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  response,
  id,
});

const deleteGroupBookingTemplateFailure = (error: any) => ({
  type: DELETE_GROUP_BOOKING_TEMPLATE_FAILURE,
  error,
});

export const deleteGroupBookingTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGroupBookingTemplateRequest(id));
    axios
      .delete(`/api/groupbookingtemplates/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteGroupBookingTemplateSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteGroupBookingTemplateFailure(error)));
  };

const createGroupBookingTemplateRequest = (
  newGroupBookingTemplate: GroupBookingTemplateParams
) => ({
  type: CREATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  newGroupBookingTemplate,
});

const createGroupBookingTemplateSuccess = (response: any) => ({
  type: CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  response,
});

const createGroupBookingTemplateFailure = (error: any) => ({
  type: CREATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  error,
});

export const createGroupBookingTemplate =
  (newGroupBookingTemplate: GroupBookingTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGroupBookingTemplateRequest(newGroupBookingTemplate));
    return axios
      .post('/api/groupbookingtemplates', newGroupBookingTemplate, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createGroupBookingTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createGroupBookingTemplateFailure(err.message));
      });
  };

const updateGroupBookingTemplateRequest = (
  id: string,
  patch: GroupBookingTemplateParams
) => ({
  type: UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  id,
  patch,
});

const updateGroupBookingTemplateSuccess = (response: any) => ({
  type: UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  response,
});

const updateGroupBookingTemplateFailure = (error: any) => ({
  type: UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  error,
});

export const updateGroupBookingTemplate =
  (id: string, patch: GroupBookingTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGroupBookingTemplateRequest(id, patch));
    return axios
      .patch(`/api/groupbookingtemplates/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateGroupBookingTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateGroupBookingTemplateFailure(err.message));
      });
  };
