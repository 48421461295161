/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import PromotionStats from './PromotionStats';
import PromotionList from './PromotionList';
import styles from './PromotionsPage.module.css';

const PromotionsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.promotionsPage}>
      <main className={styles.mainContent}>
        <section className={styles.promotionsSection}>
          <h1 className={styles.pageTitle}>Promotions</h1>
          <p className={styles.pageDescription}>
            {t("Stay up to date on your restaurant's targets.")}
          </p>
          <div className={styles.actionBar}>
            <input
              type="search"
              className={styles.searchInput}
              placeholder="Search"
              aria-label="Search promotions"
            />
            <button className={styles.createButton}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e73a57f52e9093fdf7a4edc9996e9666b9363042d6c323bce55bacac14050612?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
                alt=""
                className={styles.plusIcon}
              />
              Create Promotion
            </button>
          </div>
          <PromotionStats />
          <PromotionList />
        </section>
      </main>
    </div>
  );
};

export default PromotionsPage;
