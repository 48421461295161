import { combineReducers } from 'redux';

import {
  CHECK_AVAILABILITY_REQUEST,
  CHECK_AVAILABILITY_FAILURE,
  CHECK_AVAILABILITY_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
} from 'client/constants/ActionTypes';

// Reducers
const error = (state = '', action: any) => {
  switch (action.type) {
    case CHECK_AVAILABILITY_REQUEST:
    case CHECK_AVAILABILITY_SUCCESS:
      return '';

    case CHECK_AVAILABILITY_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case CHECK_AVAILABILITY_REQUEST:
      return true;

    case CHECK_AVAILABILITY_FAILURE:
    case CHECK_AVAILABILITY_SUCCESS:
      return false;

    default:
      return state;
  }
};

const confirmationType = (state = '', action: any) => {
  switch (action.type) {
    case CHECK_AVAILABILITY_SUCCESS:
      return action.response.confirmation_type_for_given_guests;

    default:
      return state;
  }
};

const availabilityReason = (state = '', action: any) => {
  switch (action.type) {
    case CHECK_AVAILABILITY_SUCCESS:
      return action.response.availability_reason_for_given_guests ?? '';

    default:
      return state;
  }
};
type AvailabilityState = {
  error: string;
  confirmationType: string;
  availabilityReason: string;
  loading: boolean;
};

const reducer = combineReducers<AvailabilityState>({
  error,
  confirmationType,
  availabilityReason,
  loading,
});
export const availability = (state: AvailabilityState, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
