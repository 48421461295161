// @flow

import * as React from 'react';
import { useParams } from 'react-router-dom';

import { HelpArticle } from 'client/pages/Help/HelpArticle/HelpArticle';

export const Help = () => {
  const { id } = useParams();

  /*
  if (!activeUserOrganization) {
    return <Loading />;
  }
  */

  return <HelpArticle id={id || 'home'} />;
};
