import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_DISPATCH_RESERVATIONS_FAILURE,
  FETCH_DISPATCH_RESERVATIONS_REQUEST,
  FETCH_DISPATCH_RESERVATIONS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { GetManifestRequest } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchDispatchReservationsRequest = () => ({
  type: FETCH_DISPATCH_RESERVATIONS_REQUEST,
});

const fetchDispatchReservationsSuccess = (response: any) => ({
  type: FETCH_DISPATCH_RESERVATIONS_SUCCESS,
  response,
});

const fetchDispatchReservationsFailure = (error: any) => ({
  type: FETCH_DISPATCH_RESERVATIONS_FAILURE,
  error,
});

let fetchDispatchReservationsCancel: () => void | undefined;
export const fetchDispatchReservations =
  (queryParams: GetManifestRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchDispatchReservationsCancel && fetchDispatchReservationsCancel();
    dispatch(fetchDispatchReservationsRequest());
    axios
      .get('/api/manifests', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchDispatchReservationsCancel = c;
        }),
      })
      .then((result) => dispatch(fetchDispatchReservationsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchDispatchReservationsFailure('canceled'));
        else dispatch(fetchDispatchReservationsFailure(error));
      });
  };
