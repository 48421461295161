import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { ReservationSuggestForm } from 'client/components/ReservationSuggestForm/ReservationSuggestForm';

export const ReservationListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('Reservation List')}
      description={t(
        'Display a list of reservations and search reservations by conditions.'
      )}
    >
      <ReservationSuggestForm newUI={true} />
    </PageHeader>
  );
};
