import React from 'react';
import clsx from 'clsx';
import querystring from 'query-string';
import type { Moment } from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { GuideScheduleCalendarEvent } from 'client/components/GuideScheduleCalendar/GuideScheduleCalendar';
import type { ReduxState } from 'client/reducers';
import { EditSingleDayOperatingHourModal } from 'client/components/EditSingleDayOperatingHourModal/EditSingleDayOperatingHourModal';
import { GuideScheduleCalendar } from 'client/components/GuideScheduleCalendar/GuideScheduleCalendar';
import {
  ResourceReservationListModal,
  defaultVisibleColumns,
} from 'client/components/ResourceReservationListModal/ResourceReservationListModal';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  accountsSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchGuideSingleDaySchedules } from 'client/actions/guides';
import { fetchReservations } from 'client/actions/reservations';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import { groupReservationsByNormalizedStartTime } from 'client/libraries/util/groupReservationsByNormalizedStartTime';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

type Props = {
  isActive?: boolean;
};
export const Calendar = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [date, setDate] = React.useState<string | null>(null);
  const [range, setRange] = React.useState<{
    start: Moment;
    end: Moment;
  } | null>(null);
  const [eventDate, setEventDate] = React.useState<string | null>(null);
  const accounts = useSelector(accountsSelector);
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );
  React.useEffect(() => {
    if (range) {
      dispatch(
        fetchReservations({
          start_date_local_from: range.start.format('YYYY-MM-DD'),
          start_date_local_to: range.end.format('YYYY-MM-DD'),
          filter: querystring.stringify({
            status: ['CONFIRMED'],
          }),
        })
      );
      dispatch(
        fetchGuideSingleDaySchedules({
          date_local_from: range.start.format('YYYY-MM-DD'),
          date_local_to: range.end.format('YYYY-MM-DD'),
        })
      );
    }
  }, [range]);
  React.useEffect(() => {
    if (range && isActive) {
      dispatch(
        fetchGuideSingleDaySchedules({
          date_local_from: range.start.format('YYYY-MM-DD'),
          date_local_to: range.end.format('YYYY-MM-DD'),
        })
      );
    }
  }, [range, isActive]);
  const timezone =
    activeUserOrganization?.default_timezone || 'America/Los_Angeles';
  const manifestReservationShape = (reservations || [])
    .filter((reservation) =>
      (reservation.dispatch_guides || []).includes(activeUser?.id ?? '')
    )
    .map((reservation) => toManifestReservationShape(reservation, t))
    .map((reservation) => {
      return {
        ...reservation,
        participates_at: reservation.participates_at.clone().tz(timezone),
      };
    });
  const reservationsByNormalizedStartTime =
    groupReservationsByNormalizedStartTime(manifestReservationShape);
  const guideSettings = (activeUserOrganization?.guides || []).find(
    (guide) => guide.id === activeUser?.id
  );
  const events = (reservationsByNormalizedStartTime || []).map(
    (reservationStartTime) => {
      const startDateTime = reservationStartTime.startDateTime;
      const ret: GuideScheduleCalendarEvent = {
        id: startDateTime.valueOf() as any,
        time: startDateTime.tz(timezone).locale(locale).format('HH:mm'),
        startDateTimeUtc: startDateTime.utc().format(),
        title: String(
          reservationStartTime.reservations.reduce((acc, reservation) => {
            return reservation.guests.length + acc;
          }, 0)
        ),
      };
      return ret;
    }
  );
  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GuideScheduleCalendar
          events={events}
          timezone={timezone}
          locale={locale}
          onRangeChange={setRange}
          onEventDayClick={(id) => {
            setEventDate(id);
          }}
          onDayClick={(date) => {
            if (
              hasCustomUserRoleWritePermissions(
                activeUser,
                'SHIFT_MANAGEMENT.SCHEDULE'
              )
            ) {
              setDate(date);
            }
          }}
          loading={loading}
          guideAccountShape={
            guideAccountShapes.find(
              (account) => account.id === activeUser?.id
            ) || null
          }
          guideSingleDaySchedules={guideSingleDaySchedules || []}
        />
      </div>

      <EditSingleDayOperatingHourModal
        open={date !== null}
        onClose={() => {
          setDate(null);
        }}
        date={date || ''}
        accountId={activeUser?.id || ''}
        operatingHoursRule={guideSettings?.operating_hours_rule || null}
        guideSingleDaySchedule={
          guideSingleDaySchedules.find((schedule) => {
            if (
              schedule.guide_account_id === activeUser?.id &&
              schedule.date_local === date
            ) {
              return true;
            }

            return false;
          }) || null
        }
        onSubmit={() => {
          dispatch(fetchGuideSingleDaySchedules());
        }}
      />

      <ResourceReservationListModal
        open={eventDate !== null}
        resourceType={'guide'}
        resourceKey={activeUser?.id || ''}
        visibleColumns={defaultVisibleColumns}
        onClose={() => {
          setEventDate(null);
        }}
        reservations={manifestReservationShape.filter((reservation) => {
          return (
            reservation.participates_at.tz(timezone).format('YYYY-MM-DD') ===
            eventDate
          );
        })}
        title={activeUser?.name || ''}
        timezone={timezone}
      />
    </div>
  );
};
