import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import type { TranslateFuncType } from 'client/components/Translate';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { ReduxState } from 'client/reducers';
import styles from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/PrivateMarketplaceProductCollectionPages.module.css';

import { TopPageFormValues, TopPageSection } from '../FormValues';

import { EditAdditionalImagesSectionModal } from './EditAdditionalImagesSectionModal';
import { EditCustomIntroductionSectionModal } from './EditCustomIntroductionSectionModal';
import { EditRecommendedProductsSectionModal } from './EditRecommendedProductsSectionModal';
import { EditCustomProductListSectionModal } from './EditCustomProductListSectionModal';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};
const itemType = 'draggable-top-page-section';

const getSectionName = (
  section: TopPageSection,
  t: TranslateFuncType
): string => {
  switch (section.sectionType) {
    case 'ADDITIONAL_IMAGES_SECTION':
      return t('Additional Photos');

    case 'CUSTOM_INTRODUCTION':
      return t('Custom Introduction');

    case 'RECOMMENDED_PRODUCT_LIST':
      return t('Recommended Products');

    case 'CUSTOM_PRODUCT_LIST':
      return section.title;

    default:
      throw new Error(`unknown section type: ${(section as any).sectionType}`);
  }
};

export interface Props {
  name: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem: () => any;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export const DraggableTopPageSection = ({
  name,
  index,
  moveItem,
  deleteItem,
}: Props) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const formState = useFormState<TopPageFormValues>();

  const value = _.get(formState.values, name);

  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, DragItem, { handlerId: any }>(
    {
      accept: itemType,

      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },

      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }
  );
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: name,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  if (!value) {
    throw new Error(`value is nil for name=${name}`);
  }

  return (
    <>
      {value.sectionType === 'ADDITIONAL_IMAGES_SECTION' && showEditModal && (
        <EditAdditionalImagesSectionModal
          name={name}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {value.sectionType === 'CUSTOM_INTRODUCTION' && showEditModal && (
        <EditCustomIntroductionSectionModal
          name={name}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {value.sectionType === 'RECOMMENDED_PRODUCT_LIST' && showEditModal && (
        <EditRecommendedProductsSectionModal
          name={name}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {value.sectionType === 'CUSTOM_PRODUCT_LIST' && showEditModal && (
        <EditCustomProductListSectionModal
          name={name}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {value.sectionType === 'CUSTOM_PRODUCT_LIST' && showDeleteModal && (
        <DeleteConfirmModal
          loading={loading}
          header={t('Delete custom product list')}
          content={t('Are you sure you want to delete "{{name}}"?', {
            name: value.title,
          })}
          onConfirm={deleteItem}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
        />
      )}
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        {`#${index + 1}  `}
        <EditIcon
          className={styles['icon-box']}
          onClick={() => setShowEditModal(true)}
        />
        {value?.sectionType === 'CUSTOM_PRODUCT_LIST' && (
          <DeleteIcon
            className={styles['icon-box']}
            onClick={() => setShowDeleteModal(true)}
          />
        )}
        {getSectionName(value, t)}
      </div>
    </>
  );
};
