import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import * as Swagger from 'shared/models/swagger';

type Dispatch = ThunkDispatch<Record<string, any>, void, any>;

export const updateFooterLink =
  (footerLink: Swagger.FooterLink, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForFooterLink(organization, footerLink, language)
      )
    );
  };
export const deleteFooterLink =
  (key: string, language: Swagger.SourceLanguage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        booking_widget_design_params: {
          ...organization?.booking_widget_design_params,
          footer_link_layouts:
            organization?.booking_widget_design_params?.footer_link_layouts?.map(
              (layout) =>
                layout.language === language
                  ? {
                      language,
                      footer_links: layout.footer_links?.filter(
                        (link) => link.key !== key
                      ),
                    }
                  : layout
            ),
        },
      })
    );
  };

const getOrganizationPatchForFooterLink = (
  organization: Swagger.Organization | null,
  newFooterLink: Swagger.FooterLink,
  language: Swagger.SourceLanguage
): Swagger.Organization$Patch => {
  const existingFooterLinkLayouts =
    organization?.booking_widget_design_params?.footer_link_layouts ?? [];
  const existingFooterLinks =
    existingFooterLinkLayouts.find((param) => param.language === language)
      ?.footer_links ?? [];
  const newFooterLinks = existingFooterLinks.some(
    (image) => image.key === newFooterLink.key
  )
    ? existingFooterLinks.map((existingImage) =>
        existingImage.key === newFooterLink.key ? newFooterLink : existingImage
      )
    : [...existingFooterLinks, newFooterLink];
  const newFooterLinkLayouts = [
    ...existingFooterLinkLayouts.filter(
      (layout) => layout.language !== language
    ),
    {
      language,
      footer_links: newFooterLinks,
    },
  ];
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      footer_link_layouts: newFooterLinkLayouts,
    },
  };
};

export const updatePopupMessage =
  (popupMessage: Swagger.PopupMessage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization(
        getOrganizationPatchForPopupMessage(organization, popupMessage)
      )
    );
  };
export const deletePopupMessage =
  (key: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    const organization = activeUserOrganizationSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        booking_widget_design_params: {
          ...organization?.booking_widget_design_params,
          popup_messages:
            organization?.booking_widget_design_params?.popup_messages?.filter(
              (popupMessage) => popupMessage.key !== key
            ),
        },
      })
    );
  };

const getOrganizationPatchForPopupMessage = (
  organization: Swagger.Organization | null,
  newPopupMessage: Swagger.PopupMessage
): Swagger.Organization$Patch => {
  const existingPopupMessages =
    organization?.booking_widget_design_params?.popup_messages ?? [];
  return {
    booking_widget_design_params: {
      ...organization?.booking_widget_design_params,
      popup_messages: [
        ...existingPopupMessages.filter(
          (popupMessage) =>
            popupMessage.key !== newPopupMessage.key &&
            popupMessage.language !== newPopupMessage.language
        ),
        newPopupMessage,
      ],
    },
  };
};
