import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_E_TICKET_LOCATION_SET_FAILURE,
  CREATE_E_TICKET_LOCATION_SET_REQUEST,
  CREATE_E_TICKET_LOCATION_SET_SUCCESS,
  DELETE_E_TICKET_LOCATION_SET_FAILURE,
  DELETE_E_TICKET_LOCATION_SET_REQUEST,
  DELETE_E_TICKET_LOCATION_SET_SUCCESS,
  FETCH_E_TICKET_LOCATION_SETS_FAILURE,
  FETCH_E_TICKET_LOCATION_SETS_REQUEST,
  FETCH_E_TICKET_LOCATION_SETS_SUCCESS,
  UPDATE_E_TICKET_LOCATION_SET_FAILURE,
  UPDATE_E_TICKET_LOCATION_SET_REQUEST,
  UPDATE_E_TICKET_LOCATION_SET_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  ETicketLocationSet,
  CreateETicketLocationSetResponse,
  ListETicketLocationSetsResponse,
  UpdateETicketLocationSetResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATION_SETS_FAILURE:
    case CREATE_E_TICKET_LOCATION_SET_FAILURE:
    case UPDATE_E_TICKET_LOCATION_SET_FAILURE:
    case DELETE_E_TICKET_LOCATION_SET_FAILURE:
      return action.error;

    case FETCH_E_TICKET_LOCATION_SETS_SUCCESS:
    case CREATE_E_TICKET_LOCATION_SET_SUCCESS:
    case UPDATE_E_TICKET_LOCATION_SET_SUCCESS:
    case DELETE_E_TICKET_LOCATION_SET_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATION_SETS_REQUEST:
    case CREATE_E_TICKET_LOCATION_SET_REQUEST:
    case UPDATE_E_TICKET_LOCATION_SET_REQUEST:
    case DELETE_E_TICKET_LOCATION_SET_REQUEST:
      return true;

    case FETCH_E_TICKET_LOCATION_SETS_FAILURE:
    case CREATE_E_TICKET_LOCATION_SET_FAILURE:
    case UPDATE_E_TICKET_LOCATION_SET_FAILURE:
    case DELETE_E_TICKET_LOCATION_SET_FAILURE:
    case FETCH_E_TICKET_LOCATION_SETS_SUCCESS:
    case CREATE_E_TICKET_LOCATION_SET_SUCCESS:
    case UPDATE_E_TICKET_LOCATION_SET_SUCCESS:
    case DELETE_E_TICKET_LOCATION_SET_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: ETicketLocationSet[] = [],
  action: any
): ETicketLocationSet[] => {
  switch (action.type) {
    case FETCH_E_TICKET_LOCATION_SETS_SUCCESS: {
      const response = action.response as ListETicketLocationSetsResponse;
      return response.e_ticket_location_sets ?? [];
    }

    case CREATE_E_TICKET_LOCATION_SET_SUCCESS: {
      const response = action.response as CreateETicketLocationSetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_E_TICKET_LOCATION_SET_SUCCESS: {
      const response = action.response as UpdateETicketLocationSetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_E_TICKET_LOCATION_SET_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const eTicketLocationSets = combineReducers({
  error,
  loading,
  all,
});
