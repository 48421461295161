import * as React from 'react';

import { CustomCard } from '../util';
import { Section } from '../CustomEditor/util';
import TemplateContent from '../CustomEditor/TemplateContent';

import { BaseTemplate } from './BaseTemplate';

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  customCards: CustomCard[];
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
  sections: Section[];
}

export const CustomEditor = ({
  editMode,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
  language,
  sections,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      <TemplateContent
        editMode={editMode}
        sections={sections}
        language={language}
        newsletterCampaignId={newsletterCampaignId}
      />
    </BaseTemplate>
  );
};
