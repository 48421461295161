import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchNewsletter } from 'client/actions/newsletters';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { Loading } from 'client/pages/Loading';
import { ReduxState } from 'client/reducers';
import { NewsletterTemplate } from 'shared/components/templates/newsletter/NewsletterTemplate';

export const NewsletterEmail = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchNewsletter(id));
  }, [id]);

  const newsletter = useSelector((state: ReduxState) =>
    state.newsletters.all.find((n) => n.id === id)
  );

  if (!newsletter) {
    return <Loading />;
  }

  const selectedTemplate = newsletter.email_template_name;
  const templateValues = JSON.parse(
    newsletter.email_template_values_json ?? ''
  );

  return (
    <>
      <Link to="/newsletters">
        <BackArrow />
      </Link>
      <React.Suspense fallback={<Loading />}>
        <div>
          <NewsletterTemplate
            selectedTemplate={selectedTemplate as any}
            templateValues={templateValues}
            customerName={t('John Nutmeg')}
          />
        </div>
      </React.Suspense>
    </>
  );
};
