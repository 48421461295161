import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { fetchReservationNotifications } from 'client/actions/notifications';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import notificationsNone from 'client/images/ic_notifications_none.svg';
import notificationsActive from 'client/images/ic_notifications_active.svg';

import { ReservationNotificationList } from './ReservationNotificationList';
import styles from './ReservationNotificationBell.module.css';

export const ReservationNotificationBell = () => {
  const notificationListRef = React.useRef<HTMLAnchorElement>(null);
  const dispatch = useDispatch();
  const [showNotifications, setShowNotifications] = React.useState(false);
  const organization = useSelector(activeUserOrganizationSelector);

  const isTutorialCompleted: boolean = (() => {
    return (
      organization?.tutorial_stage === 'COMPLETED' ||
      organization?.tutorial_stage === undefined
    );
  })();

  React.useEffect(() => {
    dispatch(fetchReservationNotifications());
  }, []);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showNotifications &&
        target instanceof Node &&
        !notificationListRef?.current?.contains(target)
      ) {
        setShowNotifications(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showNotifications]);

  const reservationNotifications = useSelector(
    (state: ReduxState) => state.notifications.reservationNotifications
  );

  if (
    !organization ||
    organization?.extranet_notification_settings
      ?.disable_reservation_notifications ||
    !isTutorialCompleted
  ) {
    return null;
  }

  return reservationNotifications.length > 0 ? (
    <>
      <a
        ref={notificationListRef}
        className={styles['notification-bell']}
        onClick={() => setShowNotifications(!showNotifications)}
      >
        <img src={notificationsActive} />
        <span className={styles['notification-count-disk']}>
          {reservationNotifications.length}
        </span>
        {showNotifications && <ReservationNotificationList />}
      </a>
    </>
  ) : (
    <div className={styles['notification-bell-empty']}>
      <img src={notificationsNone} />
    </div>
  );
};
