import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { SeatAssignmentNotificationListElement } from './SeatAssignmentNotificationListElement';
import styles from './SeatAssignmentNotificationList.module.css';

export const SeatAssignmentNotificationList = () => {
  const equipmentNotifications = useSelector(
    (state: ReduxState) => state.equipmentNotifications.notifications
  );
  const org = useSelector(activeUserOrganizationSelector);

  if (org?.extranet_notification_settings?.disable_qr_checkin_notifications) {
    return null;
  }

  return (
    <div className={styles['snackbar-container']}>
      {equipmentNotifications.map((notification, idx) => (
        <SeatAssignmentNotificationListElement
          key={idx}
          notification={notification}
        />
      ))}
    </div>
  );
};
