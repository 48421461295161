import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import {
  fetchCustomers,
  updateCustomerListPageTokens,
} from 'client/actions/customers';
import {
  setCustomerListRowCount,
  setCustomerListCurrentPage,
} from 'client/actions/customerListControls';
import { Loading } from 'client/pages/Loading';
import { Button } from 'client/components/Form';
import {
  CustomerShape,
  toCustomerShape,
} from 'client/libraries/util/customerShape';
import { GenericTable } from 'client/components/GenericTable/GenericTable';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { customerListVisibleColumnsSelector } from 'client/reducers/customerListControls';
import thIcon from 'client/images/ic_th.svg';
import searchIcon from 'client/images/ic_search.svg';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { getAllColumns } from './utils';
import { CustomerListConditionDisplayBox } from './CustomerListConditionDisplayBox';
import { CustomerSearchSettingsModal } from './CustomerSearchSettingsModal';
import { CustomerListSettingsModal } from './CustomerListSettingsModal';

export const CustomerList = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [openSearchSettingsModal, setOpenSearchSettingsModal] =
    React.useState(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const customerShapes = useSelector((state: ReduxState) =>
    state.customers.all.map((customer) => toCustomerShape(customer, t, i18n))
  );
  const loading = useSelector((state: ReduxState) => state.customers.loading);

  const totalSize = useSelector(
    (state: ReduxState) => state.customers.totalSize
  );

  const pageTokens = useSelector(
    (state: ReduxState) => state.customers.pageTokens
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.customerListControls.currentPage
  );
  const rowCount = useSelector(
    (state: ReduxState) => state.customerListControls.rowCount
  );
  const searchSettings = useSelector(
    (state: ReduxState) => state.customers.searchSettings
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const visibleColumns = useSelector(customerListVisibleColumnsSelector);

  const allColumns = getAllColumns(locale, t);

  const columns = React.useMemo(() => {
    const getColumns = (columnMask: string[]) => {
      return columnMask.map(
        (c) => allColumns.find((col) => col.id === c) as any
      );
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, allColumns]);

  React.useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch, searchSettings]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  // this value is used in fetchCustomerList
  const handleOnClickPrevious = () => {
    dispatch(
      updateCustomerListPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.allTokens[pageTokens.currToken].previous,
        indexToToken: pageTokens.indexToToken,
      })
    );

    dispatch(fetchCustomers());
  };

  // this value is used in fetchCustomerList
  const handleOnClickNext = () => {
    const allTokens = pageTokens.allTokens;
    const nextToken = pageTokens.allTokens[pageTokens.currToken].next;
    if (nextToken != null) {
      allTokens[nextToken] = {
        previous: pageTokens.currToken,
        next: null,
      };
      dispatch(
        updateCustomerListPageTokens({
          allTokens: allTokens,
          currToken: nextToken,
          indexToToken: pageTokens.indexToToken,
        })
      );
      dispatch(fetchCustomers());
    }
  };

  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        >
          <Button.Transition
            content={
              <>
                <img src={searchIcon} />
                {t('Search')}
              </>
            }
            onClick={() => setOpenSearchSettingsModal(true)}
          />
        </div>

        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              baseStyles['is-close']
            )}
          >
            <ul>
              <li>
                <CustomerListSettingsModal
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['square'],
                        baseStyles['gray']
                      )}
                    >
                      <img src={thIcon} />
                    </a>
                  }
                />
              </li>
            </ul>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <div
            className={clsx(
              componentStyles['c-tab-box'],
              baseStyles['scroll-target-pane']
            )}
          >
            <CustomerListConditionDisplayBox />
            <GenericTable<CustomerShape>
              items={customerShapes}
              totalCount={totalSize}
              columns={columns}
              rowCount={rowCount}
              currentPage={currentPage}
              onRowCountChange={(rowCount: number) => {
                dispatch(setCustomerListRowCount(rowCount));
                dispatch(fetchCustomers());
              }}
              onCurrentPageChange={(newCurrentPage: number) => {
                if (newCurrentPage < currentPage) {
                  handleOnClickPrevious();
                } else {
                  handleOnClickNext();
                }
                dispatch(setCustomerListCurrentPage(newCurrentPage));
              }}
            />
          </div>
        </div>
      </div>

      {openSearchSettingsModal && (
        <CustomerSearchSettingsModal
          open={openSearchSettingsModal}
          onClose={() => setOpenSearchSettingsModal(false)}
        />
      )}

      {loading && <Loading />}
    </>
  );
};
