import FileSaver from 'file-saver';
import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  DOWNLOAD_GUIDE_CSV_FAILURE,
  DOWNLOAD_GUIDE_CSV_REQUEST,
  DOWNLOAD_GUIDE_CSV_SUCCESS,
  DOWNLOAD_GUIDE_PDF_FAILURE,
  DOWNLOAD_GUIDE_PDF_REQUEST,
  DOWNLOAD_GUIDE_PDF_SUCCESS,
  SEND_GUIDE_EMAIL_FAILURE,
  SEND_GUIDE_EMAIL_REQUEST,
  SEND_GUIDE_EMAIL_SUCCESS,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST,
  FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  GuideExportParams,
  GuideSingleDaySchedule$Input,
  GuideSingleDaySchedule$Patch,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGuideCSVRequest = () => ({
  type: DOWNLOAD_GUIDE_CSV_REQUEST,
});

const fetchGuideCSVSuccess = () => ({
  type: DOWNLOAD_GUIDE_CSV_SUCCESS,
});

const fetchGuideCSVFailure = (error: any) => ({
  type: DOWNLOAD_GUIDE_CSV_FAILURE,
  error,
});

export const fetchGuideCSV =
  (req: GuideExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchGuideCSVRequest());
    axios
      .post('/api/guides/csv', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(fetchGuideCSVSuccess());
        FileSaver.saveAs(response.data, 'schedule.csv');
      })
      .catch((error) => dispatch(fetchGuideCSVFailure(error)));
  };

const fetchGuidePDFRequest = () => ({
  type: DOWNLOAD_GUIDE_PDF_REQUEST,
});

const fetchGuidePDFSuccess = () => ({
  type: DOWNLOAD_GUIDE_PDF_SUCCESS,
});

const fetchGuidePDFFailure = (error: any) => ({
  type: DOWNLOAD_GUIDE_PDF_FAILURE,
  error,
});

export const fetchGuidePDF =
  (req: GuideExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchGuidePDFRequest());
    axios
      .post('/api/guides/pdf', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(fetchGuidePDFSuccess());
        FileSaver.saveAs(response.data, 'schedule.pdf');
      })
      .catch((err) => dispatch(fetchGuidePDFFailure(err)));
  };

const sendGuideEmailRequest = () => ({
  type: SEND_GUIDE_EMAIL_REQUEST,
});

const sendGuideEmailSuccess = () => ({
  type: SEND_GUIDE_EMAIL_SUCCESS,
});

const sendGuideEmailFailure = (error: any) => ({
  type: SEND_GUIDE_EMAIL_FAILURE,
  error,
});

export const sendGuideEmail =
  (req: GuideExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendGuideEmailRequest());
    axios
      .post('/api/guides/email', req, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => dispatch(sendGuideEmailSuccess()))
      .catch((error) => dispatch(sendGuideEmailFailure(error)));
  };

const fetchGuideSingleDaySchedulesRequest = () => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST,
});

const fetchGuideSingleDaySchedulesSuccess = (response: any) => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS,
  response,
});

const fetchGuideSingleDaySchedulesFailure = (error: any) => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE,
  error,
});

const fetchGuideSingleDaySchedulesCanceled = () => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED,
});

let fetchGuideSingleDaySchedulesCancel: () => void | undefined;
export const fetchGuideSingleDaySchedules =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGuideSingleDaySchedulesCancel && fetchGuideSingleDaySchedulesCancel();
    dispatch(fetchGuideSingleDaySchedulesRequest());
    const params = { ...queryParams };
    axios
      .get('/api/guides/singledayschedules', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGuideSingleDaySchedulesCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchGuideSingleDaySchedulesSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGuideSingleDaySchedulesCanceled());
        else dispatch(fetchGuideSingleDaySchedulesFailure(error));
      });
  };

const fetchGuideSingleDaySchedulesByIDRequest = (ids: string[]) => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST,
  ids,
});

const fetchGuideSingleDaySchedulesByIDSuccess = (responses: any) => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS,
  responses,
});

const fetchGuideSingleDaySchedulesByIDFailure = (error: any) => ({
  type: FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE,
  error,
});

export const fetchGuideSingleDaySchedulesByID =
  (ids: string[], language?: string, getSourceLanguageContents?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchGuideSingleDaySchedulesByIDRequest(ids));
    const promises = ids.map((id) =>
      fetch(
        getSourceLanguageContents
          ? `/api/guides/singledayschedules/${id}?ignore_request_language=true`
          : `/api/guides/singledayschedules/${id}`,
        {
          headers: {
            ...getHTTPRequestHeaders(getState(), {
              language,
            }),
          },
        }
      ).then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
    );
    return Promise.all(promises)
      .then((responses) =>
        dispatch(fetchGuideSingleDaySchedulesByIDSuccess(responses))
      )
      .catch((error) =>
        dispatch(fetchGuideSingleDaySchedulesByIDFailure(error))
      );
  };
export const fetchGuideSingleDayScheduleByID = (
  id: string,
  language?: string
) => fetchGuideSingleDaySchedulesByID([id], language);

const createGuideSingleDayScheduleRequest = (
  newGuideSingleDaySchedule: GuideSingleDaySchedule$Input
) => ({
  type: CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  newGuideSingleDaySchedule,
});

const createGuideSingleDayScheduleSuccess = (response: any) => ({
  type: CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  response,
});

const createGuideSingleDayScheduleFailure = (error: any) => ({
  type: CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  error,
});

export const createGuideSingleDaySchedule =
  (newGuideSingleDaySchedule: GuideSingleDaySchedule$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGuideSingleDayScheduleRequest(newGuideSingleDaySchedule));
    return fetch('/api/guides/singledayschedules', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newGuideSingleDaySchedule),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => {
        dispatch(createGuideSingleDayScheduleSuccess(result));
        return result;
      })
      .catch((error) => {
        dispatch(createGuideSingleDayScheduleFailure(error));
        throw error;
      });
  };

const updateGuideSingleDayScheduleRequest = (
  id: string,
  patch: GuideSingleDaySchedule$Patch
) => ({
  type: UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  id,
  patch,
});

const updateGuideSingleDayScheduleSuccess = (response: any) => ({
  type: UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  response,
});

const updateGuideSingleDayScheduleFailure = (error: any) => ({
  type: UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  error,
});

export const updateGuideSingleDaySchedule =
  (id: string, patch: GuideSingleDaySchedule$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGuideSingleDayScheduleRequest(id, patch));
    return fetch(`/api/guides/singledayschedules/${id}`, {
      method: 'PATCH',
      headers: { ...getHTTPRequestHeaders(getState()) },
      body: JSON.stringify(patch),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response) => {
        dispatch(updateGuideSingleDayScheduleSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((e: any) => {
            dispatch(updateGuideSingleDayScheduleFailure(e.message));
          });
        } else {
          dispatch(updateGuideSingleDayScheduleFailure(error));
        }

        throw error;
      });
  };

const deleteGuideSingleDayScheduleRequest = (id: string) => ({
  type: DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST,
  id,
});

const deleteGuideSingleDayScheduleSuccess = (
  response: Record<string, any>,
  id: string
) => ({
  type: DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS,
  response,
  id,
});

const deleteGuideSingleDayScheduleFailure = (error: any) => ({
  type: DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE,
  error,
});

export const deleteGuideSingleDaySchedule =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGuideSingleDayScheduleRequest(id));
    return fetch(`/api/guides/singledayschedules/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response) => {
        dispatch(deleteGuideSingleDayScheduleSuccess(response, id));
      })
      .catch((error) => {
        error.json().then((e: any) => {
          dispatch(deleteGuideSingleDayScheduleFailure(e.message));
        });
      });
  };
