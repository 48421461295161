import { useTranslation } from 'react-i18next';

import { AvailabilityHeader } from 'client/pages/v3/Availability/Shared/AvailabilityHeader';
import { AvailabilityMonthlyBody } from 'client/pages/v3/Availability/AvailabilityMonthly/AvailabilityMonthlyBody/AvailabilityMonthlyBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const AvailabilityMonthly = () => {
  const { t } = useTranslation();

  return (
    <V3Page>
      <AvailabilityHeader title={t('Monthly Availability')} />
      <AvailabilityMonthlyBody />
    </V3Page>
  );
};
