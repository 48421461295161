import _ from 'lodash';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

import type {
  ManifestDisplaySettings,
  ResourceType,
} from 'client/libraries/util/manifestDisplaySettings';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import {
  activeUserOrganizationSelector,
  accountsSelector,
} from 'client/reducers/user';
import {
  allDispatchCrewMembersSelector,
  allDispatchVehiclesSelector,
  allDispatchMiscResourcesSelector,
} from 'client/reducers/dispatchSettings';
import {
  allManifestViewsSelector,
  manifestCustomizedColumnNamesSelector,
} from 'client/reducers/manifestSettings';
import {
  getGuideAccountShapes,
  GuideAccountShape,
  isActiveAccount,
  isOutOfOffice,
} from 'client/libraries/util/accountShape';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import {
  getDriverManifestView,
  getOrderByColumns,
  getReservationSortMethodFromReservationColumn,
  getVisibleColumns,
} from 'client/reducers/manifestDefaults';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { fetchProducts } from 'client/actions/products';
import { ResourceListItem } from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignmentContents/ResourceListItem';
import { crewMemberIsActiveForDate } from 'client/pages/Dispatch/util';
import { setLastManifestDisplaySettings } from 'client/actions/manifestDisplaySettings';
import { fetchDispatchReservations } from 'client/actions/dispatch';
import { fetchManifestPDF } from 'client/actions/manifests';
import { Loading } from 'client/pages/Loading';
import { getResourceManagerCapacity } from 'client/libraries/util/resourceManager';
import type { ManifestExportParams } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import dailyStyles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import styles from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignment.module.css';
import { ManifestResourceAssignmentHeader } from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignmentHeader/ManifestResourceAssignmentHeader';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import { V3Page } from 'client/components/v3/Page/V3Page';

type ResourceListItemGroupByType =
  | 'START_TIME'
  | 'TRANSPORT_ROUTE'
  | 'PICKUP_TIME';

interface ResourceListItemType {
  title: string;
  capacity?: number;
  key: string;
  resourceType: ResourceType;
  reservations: ManifestReservationShape[];
  groupBy: ResourceListItemGroupByType;
}

export const ManifestResourceAssignment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selector
  const accounts = useSelector(accountsSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const allCrewMembers = useSelector(allDispatchCrewMembersSelector);
  const allOtherResources = useSelector(allDispatchMiscResourcesSelector);
  const allVehicles = useSelector(allDispatchVehiclesSelector);
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector((state: ReduxState) =>
    state.dispatch.reservations.map((reservation) =>
      toManifestReservationShape(reservation, t)
    )
  );
  const dispatchReservationsLoading = useSelector(
    (state: ReduxState) => state.dispatch.loading
  );
  const allManifestViews = useSelector(allManifestViewsSelector);
  const pdfStatus = useSelector(
    (state: ReduxState) => state.manifests.pdfStatus
  );
  const allManifestViewTypeKeys = allManifestViews.map((view) => view.key);
  const manifestCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  // State
  const [displaySettings, setDisplaySettings] =
    useState<ManifestDisplaySettings>(lastManifestDisplaySettings);
  const { manifestType, participationDate, resourceType } = displaySettings;
  const manifestView = useMemo(
    () =>
      allManifestViews.find((view) => view.key === manifestType) ||
      getDriverManifestView(),
    [allManifestViews, manifestType]
  );
  const visibleColumns = getVisibleColumns(manifestView);
  // Special rules apply when 'TRANSPORT_ROUTE' is selected for dispatch:
  //
  // Resource Case A: resource is assigned to at least one transport route
  // 1) Reservations are expanded into separate copies for each leg of the transport route.
  // 2) These expanded reservations are sorted by transport arrival time.
  // 3) For each resource, reservations are shown only if that resource is assigned to reservation's transport
  //    route leg.
  //
  // Resource Case B: resource is only assigned to reservations not transport routes
  // 1) Reservations are shown with empty transport route info
  // 2) Reservations are grouped by pickup time
  const shouldShowTransportRouteAssignmentFormat = (
    manifestView.columns ?? []
  ).includes('TRANSPORT_ROUTE');
  const sortedReservations = useMemo(() => {
    return _.orderBy(
      reservations,
      (getOrderByColumns(manifestView, false) || []).map((column) => {
        return getReservationSortMethodFromReservationColumn(column);
      })
    );
  }, [reservations, manifestView]);
  // Special rules apply when 'TRANSPORT_ROUTE' is selected for dispatch:
  //
  // Resource Case A: resource is assigned to at least one transport route
  // 1) Reservations are expanded into separate copies for each leg of the transport route.
  // 2) These expanded reservations are sorted by transport arrival time.
  // 3) For each resource, reservations are shown only if that resource is assigned to reservation's transport
  //    route leg.
  //
  // Resource Case B: resource is only assigned to reservations not transport routes
  // 1) Reservations are shown with empty transport route info
  // 2) Reservations are grouped by pickup time
  const transportRouteReservationRows = useMemo(() => {
    const expandedReservations = _.flatten(
      reservations.map(
        (r) =>
          r.transport_route?.map((transportRouteItem) => ({
            ...r,
            transport_route: [transportRouteItem],
          })) ?? []
      )
    );

    return _.orderBy(expandedReservations, [
      (reservation) =>
        (reservation.transport_route &&
          reservation.transport_route[0]?.location_to?.date_time_utc) ??
        '',
      (reservation) =>
        (reservation.transport_route &&
          reservation.transport_route[0]?.location_from?.date_time_utc) ??
        '',
      ...(getOrderByColumns(manifestView, false) || []).map((column) => {
        return getReservationSortMethodFromReservationColumn(column);
      }),
    ]);
  }, [reservations, manifestView]);
  const reservationsWithoutTransportRows = useMemo(() => {
    const strippedReservations =
      reservations.map((r) => ({ ...r, transport_route: [] })) ?? [];
    return _.orderBy(
      strippedReservations,
      (getOrderByColumns(manifestView, false) || []).map((column) => {
        return getReservationSortMethodFromReservationColumn(column);
      })
    );
  }, [reservations, manifestView]);
  // Fetch products if reservations change
  useEffect(() => {
    dispatch(fetchProducts());
  }, [locale]);
  // Effect
  useEffect(() => {
    dispatch(setLastManifestDisplaySettings(displaySettings));
  }, [displaySettings]);
  useEffect(() => {
    dispatch(
      fetchDispatchReservations({
        date: participationDate,
      })
    );
  }, [participationDate]);

  // Handler

  const handleResourceTypeChange = (value: any) => {
    if (value === 'all') {
      setDisplaySettings({ ...displaySettings, resourceType: '' });
    } else {
      setDisplaySettings({ ...displaySettings, resourceType: value });
    }
  };

  const handleDateChange = (dateString: string | null) => {
    const date = moment(dateString);
    setDisplaySettings({
      ...displaySettings,
      participationDate: date.format('YYYY-MM-DD'),
    });
  };

  // Options
  const manifestTypeOptions = allManifestViewTypeKeys.map((option) => ({
    text: t(option as any),
    value: option ?? '',
  }));
  const resourceTypeOptions = [
    {
      text: t('Vehicle'),
      value: 'vehicle',
    },
    {
      text: t('Crew'),
      value: 'crew',
    },
    {
      text: t('Others'),
      value: 'other',
    },
    {
      text: t('All'),
      value: 'all',
    },
  ];
  const activeCrewMembers = allCrewMembers.filter((crewMember) =>
    crewMemberIsActiveForDate(crewMember, participationDate)
  );
  const isGuideAccountActive = hasSubscription(
    activeOrganization,
    'feature-guide-account'
  );
  let activeGuides: GuideAccountShape[] = [];

  if (isGuideAccountActive) {
    const activeGuideAccountShapes = getGuideAccountShapes(
      accounts,
      activeOrganization
    ).filter((guide) => isActiveAccount(guide));
    activeGuides = (activeGuideAccountShapes || []).filter(
      (guide) =>
        !isOutOfOffice(participationDate, guide, guideSingleDaySchedules)
    );
  }

  const resourceListItems: ResourceListItemType[] = [];
  const allCrewKeys = new Set<string>([
    ...activeCrewMembers.map((crewMember) => crewMember.key || ''),
  ]);
  const allVehicleKeys = new Set<string>([
    ...allVehicles.map((v) => v.key || ''),
  ]);
  const allOtherResourceKeys = new Set<string>([
    ...allOtherResources.map((r) => r.key || ''),
  ]);

  for (const reservation of sortedReservations) {
    reservation.dispatch_crew.forEach((c) => allCrewKeys.add(c));
    reservation.dispatch_vehicles.forEach((v) => allVehicleKeys.add(v));
    reservation.dispatch_misc_resources.forEach((r) =>
      allOtherResourceKeys.add(r)
    );
  }

  const showCrew = !resourceType || resourceType === 'crew';

  if (showCrew) {
    const crewListItems = [...allCrewKeys].map(
      (crewKey): ResourceListItemType => {
        let groupBy: ResourceListItemGroupByType = 'START_TIME';
        let crewMemberReservations: ManifestReservationShape[];

        if (shouldShowTransportRouteAssignmentFormat) {
          if (
            sortedReservations.some((r) =>
              r.transport_route?.some((route) =>
                route.dispatch_crew?.includes(crewKey)
              )
            )
          ) {
            crewMemberReservations = transportRouteReservationRows.filter((r) =>
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_crew?.includes(crewKey)
              )
            );
            groupBy = 'TRANSPORT_ROUTE';
          } else {
            crewMemberReservations = reservationsWithoutTransportRows.filter(
              (r) => r.dispatch_crew?.includes(crewKey)
            );
            groupBy = 'PICKUP_TIME';
          }
        } else {
          crewMemberReservations = sortedReservations.filter(
            (r) =>
              r.dispatch_crew?.includes(crewKey) ||
              r.transport_route?.some((route) =>
                route.dispatch_crew?.includes(crewKey)
              )
          );
        }

        return {
          title: crewKey,
          key: crewKey,
          resourceType: 'crew',
          reservations: crewMemberReservations,
          groupBy,
        };
      }
    );
    resourceListItems.push(...crewListItems);
  }

  if (showCrew && isGuideAccountActive) {
    const guideListItems = [...activeGuides].map(
      (guide): ResourceListItemType => {
        let groupBy: ResourceListItemGroupByType = 'START_TIME';
        let guideReservations: ManifestReservationShape[];

        if (shouldShowTransportRouteAssignmentFormat) {
          if (
            sortedReservations.some((r) =>
              r.transport_route?.some((route) =>
                route.dispatch_guides?.includes(guide.id)
              )
            )
          ) {
            guideReservations = transportRouteReservationRows.filter((r) =>
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_guides?.includes(guide.id)
              )
            );
            groupBy = 'TRANSPORT_ROUTE';
          } else {
            guideReservations = reservationsWithoutTransportRows.filter((r) =>
              r.dispatch_guides?.includes(guide.id)
            );
            groupBy = 'PICKUP_TIME';
          }
        } else {
          guideReservations = sortedReservations.filter(
            (r) =>
              r.dispatch_guides?.includes(guide.id) ||
              r.transport_route?.some((route) =>
                route.dispatch_guides?.includes(guide.id)
              )
          );
        }

        return {
          title: guide.name,
          key: guide.id,
          resourceType: 'guide',
          reservations: guideReservations,
          groupBy,
        };
      }
    );
    resourceListItems.push(...guideListItems);
  }

  const showVehicles = !resourceType || resourceType === 'vehicle';

  if (showVehicles) {
    const vehicleResourceListItems = [...allVehicleKeys].map(
      (vehicleKey): ResourceListItemType => {
        let groupBy: ResourceListItemGroupByType = 'START_TIME';
        let vehicleReservations: ManifestReservationShape[];

        if (shouldShowTransportRouteAssignmentFormat) {
          if (
            sortedReservations.some((r) =>
              r.transport_route?.some((route) =>
                route.dispatch_vehicles?.includes(vehicleKey)
              )
            )
          ) {
            vehicleReservations = transportRouteReservationRows.filter((r) =>
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_vehicles?.includes(vehicleKey)
              )
            );
            groupBy = 'TRANSPORT_ROUTE';
          } else {
            vehicleReservations = reservationsWithoutTransportRows.filter((r) =>
              r.dispatch_vehicles?.includes(vehicleKey)
            );
            groupBy = 'PICKUP_TIME';
          }
        } else {
          vehicleReservations = sortedReservations.filter(
            (r) =>
              r.dispatch_vehicles?.includes(vehicleKey) ||
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_vehicles?.includes(vehicleKey)
              )
          );
        }

        const vehicle = allVehicles.find((v) => v.key === vehicleKey);
        return {
          title: vehicleKey,
          capacity: (vehicle && vehicle.capacity) || 0,
          key: vehicleKey,
          resourceType: 'vehicle',
          reservations: vehicleReservations,
          groupBy,
        };
      }
    );
    resourceListItems.push(...vehicleResourceListItems);
  }

  const showOtherResources = !resourceType || resourceType === 'other';

  if (showOtherResources) {
    const otherResourceListItems = [...allOtherResourceKeys].map(
      (otherResourceKey): ResourceListItemType => {
        let groupBy: ResourceListItemGroupByType = 'START_TIME';
        let resourceReservations: ManifestReservationShape[];

        if (shouldShowTransportRouteAssignmentFormat) {
          if (
            sortedReservations.some((r) =>
              r.transport_route?.some((route) =>
                route.dispatch_misc_resources?.includes(otherResourceKey)
              )
            )
          ) {
            resourceReservations = transportRouteReservationRows.filter((r) =>
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_misc_resources?.includes(otherResourceKey)
              )
            );
            groupBy = 'TRANSPORT_ROUTE';
          } else {
            resourceReservations = reservationsWithoutTransportRows.filter(
              (r) => r.dispatch_misc_resources?.includes(otherResourceKey)
            );
            groupBy = 'PICKUP_TIME';
          }
        } else {
          resourceReservations = sortedReservations.filter(
            (r) =>
              r.dispatch_misc_resources?.includes(otherResourceKey) ||
              r.transport_route?.some((routeItem) =>
                routeItem.dispatch_misc_resources?.includes(otherResourceKey)
              )
          );
        }

        const resource = allOtherResources.find(
          (r) => r.key === otherResourceKey
        );

        const resourceManagerCapacity = getResourceManagerCapacity(
          activeOrganization,
          participationDate,
          'other',
          otherResourceKey
        );

        return {
          title: otherResourceKey,
          capacity:
            resourceManagerCapacity ?? ((resource && resource.capacity) || 0),
          key: otherResourceKey,
          resourceType: 'other',
          reservations: resourceReservations,
          groupBy,
        };
      }
    );
    resourceListItems.push(...otherResourceListItems);
  }

  const getAssignedResources = (
    resourceType: ResourceType
  ): string[] | null => {
    const keys = resourceListItems
      .filter(
        (resourceListItem) =>
          resourceListItem.resourceType === resourceType &&
          resourceListItem.reservations.length > 0
      )
      .map((resourceListItem) => resourceListItem.key);

    if (keys && keys.length > 0) {
      return keys;
    }

    return null;
  };

  const manifestExportRequest: ManifestExportParams = {
    date: participationDate,
    columns: visibleColumns,
    order_by_columns: getOrderByColumns(manifestView, false),
    customized_column_names: manifestCustomizedColumnNames,
    should_show_transport_route_assignment_format:
      shouldShowTransportRouteAssignmentFormat,
  };
  const vehicle = getAssignedResources('vehicle');

  if (vehicle) {
    manifestExportRequest['dispatch_vehicles'] = vehicle;
  }

  const crew = getAssignedResources('crew');

  if (crew) {
    manifestExportRequest['dispatch_crew'] = crew;
  }

  const guide = getAssignedResources('guide');

  if (guide) {
    manifestExportRequest['dispatch_guides'] = guide;
  }

  const other = getAssignedResources('other');

  if (other) {
    manifestExportRequest['dispatch_misc_resources'] = other;
  }

  return (
    <V3Page>
      <ManifestResourceAssignmentHeader />
      <div className={baseStyles['l-main__body']}>
        <section className={baseStyles['g-section']}>
          <div className={dailyStyles['p-manifests__search']}>
            <div className={dailyStyles['p-manifests__search__item']}>
              <SingleDropdown
                label={t('Select Type')}
                options={manifestTypeOptions}
                selectedOption={manifestType}
                onChange={(value) =>
                  setDisplaySettings({
                    ...displaySettings,
                    manifestType: value,
                  })
                }
                disabled={manifestTypeOptions.length === 1}
              />
              <SimpleDateInput
                label={t('Select Date')}
                onChange={handleDateChange}
                dateFrom={moment(participationDate)
                  .format('YYYY/MM/DD')
                  .toString()}
                locale={locale}
              />
              <SingleDropdown
                label={t('Show Resource Type')}
                options={resourceTypeOptions}
                selectedOption={resourceType === '' ? t('All') : resourceType}
                onChange={handleResourceTypeChange}
              />
              <Button
                text={t('Bulk download PDF')}
                color="primary"
                onClick={() =>
                  dispatch(fetchManifestPDF(manifestExportRequest))
                }
                loading={pdfStatus === 'IN_FLIGHT'}
              />
            </div>
          </div>
        </section>

        <>
          {participationDate &&
            resourceListItems.map((resourceListItem, idx) => (
              <section
                key={resourceListItem.title}
                className={clsx(
                  styles['p-assignments__section'],
                  baseStyles['g-section'],
                  baseStyles['u-mt-6']
                )}
              >
                <ResourceListItem
                  participationDate={participationDate}
                  title={resourceListItem.title}
                  resourceKey={resourceListItem.key}
                  resourceType={resourceListItem.resourceType}
                  groupBy={resourceListItem.groupBy}
                  reservations={resourceListItem.reservations}
                  loading={dispatchReservationsLoading}
                  open={idx === 0}
                  capacity={resourceListItem.capacity}
                  visibleColumns={visibleColumns}
                  customizedColumnNames={manifestCustomizedColumnNames}
                  orderByColumns={getOrderByColumns(manifestView, false)}
                />
              </section>
            ))}
        </>
        {dispatchReservationsLoading && <Loading />}
      </div>
    </V3Page>
  );
};
