import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { localeSelector, ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import { QuantityGadget } from '../../components/v3/DashboardGadgets/QuantityGadget';

import { AgesGadget } from './AgesGadget';
import { GendersGadget } from './GendersGadget';
import { AddressAgeRatioGadget } from './AddressAgeRatioGadget';
import { BarListGadget } from './BarListGadget';
import { ProgressBarGadget } from './ProgressBarGadget';
import styles from './Gadgets.module.css';

interface Props {
  viewKey: 'flow' | 'stock';
}

const incrementFirstDigit = (numStr: string): number => {
  if (!numStr) return 0;
  const numArr = numStr.split('');
  numArr[0] = (parseInt(numArr[0]) + 1).toString();
  return parseFloat(numArr.join(''));
};

export const Gadgets = ({ viewKey }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(localeSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const reportData = useSelector(
    (state: ReduxState) => state.customers.reportData
  );

  const showComparison = reportData.length > 1;

  const baseData = React.useMemo(() => {
    return reportData?.[0]?.totals;
  }, [reportData]);

  const comparisonData = React.useMemo(() => {
    return (reportData || []).length > 1 ? reportData?.[1]?.totals : null;
  }, [reportData]);

  const optedInRation = React.useMemo(() => {
    return baseData?.customer_count
      ? ((baseData?.opted_in_count ?? 0) / baseData?.customer_count) * 100
      : 0;
  }, [baseData]);

  const comparisonOptedInRation = React.useMemo(() => {
    return comparisonData?.customer_count
      ? ((comparisonData?.opted_in_count ?? 0) /
          comparisonData?.customer_count) *
          100
      : 0;
  }, [comparisonData]);

  const averageAmounts = React.useMemo(() => {
    return (baseData?.average_amount_counts ?? [])
      .sort((a, b) => {
        // First sort by count in descending order
        const countDiff = (b.count ?? 0) - (a.count ?? 0);
        if (countDiff !== 0) {
          return countDiff;
        }
        // If counts are equal, sort by key (numeric string) in descending order
        return parseFloat(b.key ?? '0') - parseFloat(a.key ?? '0');
      })
      .slice(0, 5)
      .map((count) => {
        return {
          label:
            count.key === '0'
              ? '0'
              : `${Number(
                  count.key ?? ''
                ).toLocaleString()} ~ ${incrementFirstDigit(
                  count.key ?? ''
                ).toLocaleString()}`,
          percent: count.count ?? 0,
          comparisonPercent:
            comparisonData?.average_amount_counts?.find(
              (c) => c.key === count.key
            )?.count ?? 0,
        };
      });
  }, [baseData, comparisonData]);

  const ltvAmounts = React.useMemo(() => {
    return (baseData?.ltv_amount_counts ?? [])
      .sort((a, b) => {
        // First sort by count in descending order
        const countDiff = (b.count ?? 0) - (a.count ?? 0);
        if (countDiff !== 0) {
          return countDiff;
        }
        // If counts are equal, sort by key (numeric string) in descending order
        return parseFloat(b.key ?? '0') - parseFloat(a.key ?? '0');
      })
      .slice(0, 5)
      .map((count) => {
        return {
          label:
            count.key === '0'
              ? '0'
              : `${Number(
                  count.key ?? ''
                ).toLocaleString()} ~ ${incrementFirstDigit(
                  count.key ?? ''
                ).toLocaleString()}`,
          percent: count.count ?? 0,
          comparisonPercent:
            comparisonData?.ltv_amount_counts?.find((c) => c.key === count.key)
              ?.count ?? 0,
        };
      });
  }, [baseData, comparisonData]);

  const reservationCounts = React.useMemo(() => {
    return (baseData?.reservation_counts ?? [])
      .sort((a, b) => {
        // First sort by count in descending order
        const countDiff = (b.count ?? 0) - (a.count ?? 0);
        if (countDiff !== 0) {
          return countDiff;
        }
        // If counts are equal, sort by key (numeric string) in descending order
        return parseFloat(b.key ?? '0') - parseFloat(a.key ?? '0');
      })
      .slice(0, 5)
      .map((count) => {
        return {
          label: `${Number(count.key ?? '').toLocaleString()}`,
          percent: count.count ?? 0,
          comparisonPercent:
            comparisonData?.reservation_counts?.find((c) => c.key === count.key)
              ?.count ?? 0,
        };
      });
  }, [baseData, comparisonData]);

  return (
    <div className={styles['container']}>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('Number of memberships')}
          unit={locale === 'ja' ? '人' : 'People'}
          amount={baseData?.customer_count ?? 0}
          changeAmount={
            showComparison
              ? (baseData?.customer_count ?? 0) -
                (comparisonData?.customer_count ?? 0)
              : null
          }
          tooltipText={t(
            'Display the total number of new memberships from the start date to the end date of the reporting period, and compare it with the total number of memberships from the start date to the end date of the comparison period.'
          )}
        />
        <ProgressBarGadget
          header={t('Newsletter subscription rate')}
          percent={optedInRation}
          percentChange={
            showComparison ? optedInRation - comparisonOptedInRation : null
          }
          tooltipText={t(
            'Display the percentage of people who subscribed to the newsletter among those who registered as members during the reporting period.'
          )}
        />
        <AgesGadget />
        <GendersGadget />
      </div>
      <div className={styles['container-row']}>
        <AddressAgeRatioGadget />
      </div>
      {viewKey === 'stock' && (
        <div className={styles['container-row']}>
          <BarListGadget
            title={t('Average booking price')}
            items={averageAmounts}
            color={'#3b82f6'}
            comparisonColor={'#bfdbfe'}
            showComparison={showComparison}
            tooltipText={t(
              'Display a graph showing the number of members by average booking price range.'
            )}
            unitLabel={activeUserOrganization?.default_currency || 'JPY'}
          />
          <BarListGadget
            title={t('Member Lifetime Value (LTV).')}
            items={ltvAmounts}
            color={'#11b981'}
            comparisonColor={'#d1fae5'}
            showComparison={showComparison}
            tooltipText={t(
              'Display a graph showing the number of members by their lifetime value (LTV).'
            )}
            unitLabel={activeUserOrganization?.default_currency || 'JPY'}
          />
          <BarListGadget
            title={t('Total number of reservations for members')}
            items={reservationCounts}
            color={'#ec4899'}
            comparisonColor={'#fbcfe8'}
            showComparison={showComparison}
            tooltipText={t(
              'Display a graph showing the number of members by their total number of bookings.'
            )}
            unitLabel={t('times')}
          />
        </div>
      )}
    </div>
  );
};
