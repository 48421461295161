import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment, { Moment } from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ManifestDisplaySettings } from 'client/libraries/util/manifestDisplaySettings';
import type { ReduxState } from 'client/reducers';
import { AccordionItem } from 'client/components/Accordion/Accordion';
import { Loading } from 'client/pages/Loading';
import { ManifestDetails } from 'client/pages/Manifest/ManifestDetails';
import { ManifestSummary } from 'client/pages/Manifest/ManifestSummary';
import { Select, MultiSelect, DateTimeInput } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import {
  manifestProductGroupsSelector,
  allManifestViewsSelector,
} from 'client/reducers/manifestSettings';
import { setLastManifestDisplaySettings } from 'client/actions/manifestDisplaySettings';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { config } from 'client/config';

import styles from './Manifest.module.css';

export const Manifest = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const allManifestViewTypeKeys = useSelector((state: ReduxState) =>
    allManifestViewsSelector(state).map((view) => view.key)
  );
  const manifestLoading = useSelector(
    (state: ReduxState) => state.manifests.loading
  );
  const singleDayScheduleLoading = useSelector(
    (state: ReduxState) => state.guides.singleDayScheduleLoading
  );
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const productGroups = useSelector(manifestProductGroupsSelector);
  const products = useSelector(summariesSortedByBookmarkedSelector);
  const reservations = useSelector((state: ReduxState) =>
    state.manifests.reservations.map((r) => toManifestReservationShape(r, t))
  );
  const [displaySettings, setDisplaySettings] =
    React.useState<ManifestDisplaySettings>(lastManifestDisplaySettings);
  const [showAllHeader, setShowAllHeader] = React.useState<boolean>(false);
  const [startTimeFilters, setStartTimeFilters] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (!_.isEqual(displaySettings, lastManifestDisplaySettings)) {
      dispatch(setLastManifestDisplaySettings(displaySettings));
    }
  }, [displaySettings]);
  const getProductAndProductGroupOptions = React.useMemo(() => {
    return [
      {
        text: t('All Products'),
        key: 'ALL_PRODUCTS',
        value: 'ALL_PRODUCTS',
      },
      ...productGroups.map((p) => ({
        text: p.key ?? '',
        key: p.key ?? '',
        value: p.key ?? '',
      })),
      ...products.map((p) => ({
        value: p.id ?? '',
        key: p.id ?? '',
        text: getVerboseDisplayProductName(p),
      })),
    ];
  }, [productGroups, products]);

  const getManifestTypeOptions = (): string[] => {
    const { rootResource } = displaySettings;

    if (rootResource && rootResource.type === 'GROUP') {
      const productGroup = productGroups.find((g) => g.key === rootResource.id);

      if (productGroup && productGroup.view_type_key) {
        return [productGroup.view_type_key];
      }
    }

    return allManifestViewTypeKeys as any;
  };

  const handleManifestTypeChange = (_: any, { value }: { value: any }) => {
    setDisplaySettings({ ...displaySettings, manifestType: value });
  };

  const handleRootResourceChange = (_: any, { value: id }: { value: any }) => {
    if (id === 'ALL_PRODUCTS') {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: 'ALL_PRODUCTS',
          type: 'ALL_PRODUCTS',
        },
      });
    }

    const product = products.find((p) => p.id === id);

    if (product) {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: product.id,
          type: 'PRODUCT',
        },
      });
      return;
    }

    const productGroup = productGroups.find((p) => p.key === id);

    if (productGroup) {
      setDisplaySettings({
        ...displaySettings,
        rootResource: {
          id: productGroup.key || '',
          type: 'GROUP',
        },
        manifestType:
          productGroup.view_type_key ||
          displaySettings.manifestType ||
          'DRIVER',
      });
      return;
    }
  };

  const handleDateChange = (date: Moment) => {
    setDisplaySettings({
      ...displaySettings,
      participationDate: date.format('YYYY-MM-DD'),
    });
  };

  const handleOpenSummary = () => {
    setDisplaySettings({
      ...displaySettings,
      openSummary: !displaySettings.openSummary,
    });
  };

  const getFilteredReservations = () => {
    if (_.isEmpty(startTimeFilters)) {
      return reservations;
    }

    return reservations.filter(
      (r) =>
        startTimeFilters.indexOf(String(r.participates_at.valueOf())) !== -1
    );
  };

  const getStartTimeFilterOptions = () => {
    const uniqueDateTimes = _.uniqBy(
      reservations.map((r) => r.participates_at),
      (m) => m.valueOf()
    );

    const options = uniqueDateTimes.map((d) => {
      if (d.format('YYYY-MM-DD') === displaySettings.participationDate) {
        return {
          key: d.valueOf(),
          value: d.valueOf(),
          text: d.locale(locale).format('HH:mm'),
        };
      }

      return {
        key: d.valueOf(),
        value: d.valueOf(),
        text: `${d.locale(locale).format('HH:mm')} (${d
          .locale(locale)
          .format('YYYY-MM-DD')})`,
      };
    });
    return options
      .sort((opt1, opt2) => opt1.value - opt2.value)
      .map((o) => {
        return {
          text: o.text,
          value: String(o.value),
        };
      });
  };

  const changeStartTime = ({ value }: { value: string[] }) => {
    setStartTimeFilters(value);
  };

  const toggleShowAllHeader = () => {
    setShowAllHeader(!showAllHeader);
  };

  const manifestTypeOptions = getManifestTypeOptions();
  const startTimeFilterOptions = getStartTimeFilterOptions();
  const checkinUrl = `${getBookingWidgetApiKeyUrl(
    activeUserOrganization?.booking_widget_api_key || ''
  )}/qrreader`;
  const {
    manifestType,
    participationDate,
    rootResource,
    showCheckinCheckout,
    showDispatchColumn,
    showByProductGroup,
  } = displaySettings;
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <div style={{ marginBottom: '24px' }}>
          <ToggleNewUI origin="MANIFEST_DAILY" />
        </div>
      )}
      <div className={clsx(componentStyles['c-headline-search'])}>
        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <Select
            label={t('Select Product/Group')}
            search
            value={(rootResource && rootResource.id) || ''}
            options={getProductAndProductGroupOptions}
            onChange={handleRootResourceChange}
          />
        </div>

        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <Select
            label={t('Select Type')}
            value={manifestType}
            onChange={handleManifestTypeChange}
            disabled={manifestTypeOptions.length === 1}
            options={manifestTypeOptions.map((option) => ({
              text: t(option),
              value: option,
            }))}
          />
        </div>

        <div
          className={clsx(
            componentStyles['c-headline-search__item'],
            showAllHeader ? '' : baseStyles['base-t-spHidden']
          )}
        >
          <DateTimeInput
            label={t('Select Date')}
            value={moment(participationDate)}
            locale={locale}
            onChange={handleDateChange}
          />
        </div>

        {reservations.length > 0 && (
          <div
            className={clsx(
              componentStyles['c-headline-search__item'],
              showAllHeader ? '' : baseStyles['base-t-spHidden']
            )}
          >
            <MultiSelect
              label={t('Filter start times...')}
              options={startTimeFilterOptions}
              selectedValues={startTimeFilters}
              onChange={changeStartTime}
            />
          </div>
        )}

        {hasSubscription(activeUserOrganization, 'feature-qr-checkin') && (
          <div
            className={clsx(
              baseStyles['base-flex'],
              componentStyles['c-headline-search__item']
            )}
          >
            <div className={styles['search-item-button']}>
              <Link
                className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
                to="/qrreader"
                target="_blank"
              >
                <p>{t('Go To QR Reader')}</p>
                <img src={newWindowIcon} />
              </Link>
            </div>
            <div className={styles['search-item-button']}>
              <a
                className={clsx(baseStyles['base-btn'], baseStyles['blue'])}
                href={checkinUrl}
                target="_blank"
                rel="noreferrer"
              >
                <p>{t('Go To Self Checkin Page')}</p>
                <img src={newWindowIcon} />
              </a>
            </div>
          </div>
        )}

        <a
          className={clsx(
            componentStyles['c-headline-search_spMore'],
            showAllHeader ? componentStyles['arrowUp'] : ''
          )}
          onClick={toggleShowAllHeader}
        >
          {showAllHeader ? t('Close') : t('See more')}
        </a>
      </div>

      <div>
        <AccordionItem
          header={t('Summary')}
          open={displaySettings.openSummary}
          onButtonClick={handleOpenSummary}
        >
          {() => {
            return (
              <ManifestSummary
                reservations={getFilteredReservations()}
                supplierCurrency={
                  activeUserOrganization?.default_currency || ''
                }
              />
            );
          }}
        </AccordionItem>
      </div>
      <ManifestDetails
        reservations={getFilteredReservations()}
        products={[]}
        // Map back from moment "values" to HH:mm format for passing to the PDF/CSV/email export APIs
        startTimeHhMmFilters={startTimeFilters.map(
          (filterValue) =>
            startTimeFilterOptions.find(
              (option) => option.value === filterValue
            )?.text || ''
        )}
        manifestType={manifestType}
        participationDate={participationDate}
        rootResource={rootResource}
        showCheckinCheckout={showCheckinCheckout}
        showDispatchColumn={showDispatchColumn}
        showByProductGroup={showByProductGroup}
        onVisualFlagsChange={({
          showCheckinCheckout,
          showDispatchColumn,
          showByProductGroup,
        }) => {
          setDisplaySettings({
            ...displaySettings,
            showCheckinCheckout,
            showDispatchColumn,
            showByProductGroup,
          });
        }}
      />
      {(loading || manifestLoading || singleDayScheduleLoading) && <Loading />}
    </>
  );
};
