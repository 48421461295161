import { createContext } from 'react';

type Props = {
  showAllItemsByRow: { [key: number]: boolean };
};

// ViewContext can be used to pass UI-related information down the component tree for monthly
export const ViewContext = createContext<Props>({
  showAllItemsByRow: {},
});
