import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';
import baseStyles from 'client/base.module.css';

import styles from './AccessReport.module.css';

type Props = {
  data: GraphDataItem[];
};
export const TotalTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  let totalVisitCount = 0;
  let totalUserCount = 0;
  let totalReservationCount = 0;
  data.forEach((dataItem) => {
    totalVisitCount += dataItem.visitCount;
    totalUserCount += dataItem.userCount;
    totalReservationCount += dataItem.reservationCount;
  });
  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('visitCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('userCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('reservationCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Conversion Rate')}
            </th>
          </tr>
          <tr>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {totalVisitCount}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {totalUserCount}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {totalReservationCount}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {(totalUserCount
                ? (totalReservationCount / totalUserCount) * 100
                : 0
              ).toFixed(1)}{' '}
              %
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
