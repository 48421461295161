import { combineReducers } from 'redux';

import {
  SET_INVOICE_RESERVATION_VISIBLE_COLUMNS,
  SET_INVOICE_SUMMARY_VISIBLE_COLUMNS,
  SET_INVOICE_SUMMARY_VISIBLE_ROWS,
} from 'client/constants/ActionTypes';
import {
  InvoiceReservationColumn,
  InvoiceSummaryColumn,
  InvoiceSummaryRow,
} from 'shared/models/swagger';

export const invoiceSummaryColumnCandidates: InvoiceSummaryColumn[] = [
  'GROSS',
  'NET',
  'COMMISSION',
  'AMOUNT_DUE_BY_AGENT',
  'AMOUNT_DUE_BY_SUPPLIER',
];

export const invoiceSummaryRowCandidates: InvoiceSummaryRow[] = [
  'PIF',
  'POB',
  'SPLIT_PAYMENT',
  'SUBTOTAL',
  'INVOICE_ADJUSTMENT',
];

export const invoiceReservationColumnCandidates: InvoiceReservationColumn[] = [
  'AGENT_REFERENCE',
  'SUPPLIER_REFERENCE',
  'RESERVATION_STATUS',
  'PRODUCT_NAME',
  'INTERNAL_PRODUCT_NAME',
  'PARTICIPATES_AT',
  'GROSS',
  'NET',
  'COMMISSION',
  'POB_AMOUNT',
  'AMOUNT_DUE_BY_AGENT',
  'AMOUNT_DUE_BY_SUPPLIER',
  'GUEST_DISPLAY_NAME',
  'GIVEN_NAME',
  'FAMILY_NAME',
  'GUEST_DESCRIPTION',
  'GUEST_COUNT',
  'TRANSPORTATION',
  'ADD_ONS',
  'AGENT_NOTES',
  'SUPPLIER_NOTES',
  'ID',
  'SHORT_ID',
];

// Reducers
const defaulSummaryVisibleColumns: InvoiceSummaryColumn[] = [
  'GROSS',
  'NET',
  'COMMISSION',
  'AMOUNT_DUE_BY_AGENT',
  'AMOUNT_DUE_BY_SUPPLIER',
];
const defaultSummaryVisibleRows: InvoiceSummaryRow[] = [
  'PIF',
  'POB',
  'SPLIT_PAYMENT',
  'SUBTOTAL',
  'INVOICE_ADJUSTMENT',
];
const defaultReservationVisibleColumns: InvoiceReservationColumn[] = [
  'AGENT_REFERENCE',
  'SUPPLIER_REFERENCE',
  'RESERVATION_STATUS',
  'PRODUCT_NAME',
  'PARTICIPATES_AT',
  'GROSS',
  'NET',
  'COMMISSION',
  'POB_AMOUNT',
  'AMOUNT_DUE_BY_AGENT',
  'AMOUNT_DUE_BY_SUPPLIER',
];

const visibleSummaryColumns = (
  state = defaulSummaryVisibleColumns,
  action: any
): InvoiceSummaryColumn[] => {
  switch (action.type) {
    case SET_INVOICE_SUMMARY_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const visibleSummaryRows = (
  state = defaultSummaryVisibleRows,
  action: any
): InvoiceSummaryRow[] => {
  switch (action.type) {
    case SET_INVOICE_SUMMARY_VISIBLE_ROWS:
      return action.visibleRows;

    default:
      return state;
  }
};

const visibleReservationColumns = (
  state = defaultReservationVisibleColumns,
  action: any
): InvoiceReservationColumn[] => {
  switch (action.type) {
    case SET_INVOICE_RESERVATION_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

type State = {
  visibleSummaryColumns: InvoiceSummaryColumn[];
  visibleSummaryRows: InvoiceSummaryRow[];
  visibleReservationColumns: InvoiceReservationColumn[];
};

export const invoiceTableControls = combineReducers<State>({
  visibleSummaryColumns,
  visibleSummaryRows,
  visibleReservationColumns,
});
