import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import type { TranslateFuncType } from 'client/components/Translate';
import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { Badge } from 'client/components/v3/Common/Badge';
import { getBadgeColorForReservationStatus } from 'client/libraries/util/getBadgeColorForReservationStatus';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { getAvailabilityIconType } from 'client/libraries/util/productInstanceShape';
import {
  productInstanceAllChannelsAreClosed,
  getInstanceDedicatedAllotments,
  getInstanceError,
  hasBookingDeadline,
  bookingDeadlineExpired,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { Account, ProductInstance } from 'shared/models/swagger';
import { CustomCalendarEvent } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/CustomCalendar';

export type Column = {
  Header: string;
  id: string;
  accessor: string | ((row: ReservationSummaryShape) => string);
  Cell?: (cellInfo: { original: ReservationSummaryShape }) => React.ReactNode;
  th?: boolean;
};
export const getReactTableColumns = (
  t: TranslateFuncType,
  locale: string,
  openLinksInNewWindow?: boolean
): Column[] => {
  const linkProps = openLinksInNewWindow
    ? {
        target: '_blank',
      }
    : {};
  return [
    {
      Header: t('Application Number'),
      id: 'agent_reference',
      accessor: (row) => row.agent_reference,
      Cell: (cellInfo) => (
        <Link to={`/reservations/${cellInfo.original.id}`} {...linkProps}>
          {cellInfo.original.agent_reference}
        </Link>
      ),
      th: true,
    },
    {
      Header: t('Booked Date'),
      id: 'booked_at',
      accessor: 'booked_at',
      Cell: ({ original }) => original.booked_at.locale(locale).format('lll'),
    },
    {
      Header: t('Last Updated'),
      id: 'updated_at',
      accessor: 'updated_at',
      Cell: ({ original }) => original.updated_at.locale(locale).format('lll'),
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: 'status',
      Cell: (cellInfo) => (
        <Badge
          label={t(cellInfo.original.status)}
          color={getBadgeColorForReservationStatus(cellInfo.original.status)}
        />
      ),
    },
    {
      Header: t('Customer'),
      id: 'guest_display_name',
      accessor: 'guest_display_name',
    },
    {
      Header: t('Product'),
      id: 'product_name',
      accessor: 'product_name',
      Cell: ({ original }) => (
        <Link to={`/products/${original.product_id}`} {...linkProps}>
          {original.internal_product_name || original.product_name}
        </Link>
      ),
    },
    {
      Header: t('Participation'),
      id: 'participates_at',
      accessor: 'participates_at',
      Cell: ({ original }) =>
        original.participates_at.locale(locale).format('lll'),
    },
    {
      Header: t('Units'),
      id: 'guest_description',
      accessor: 'guest_description',
    },
    {
      Header: t('Total Pax'),
      id: 'guest_count',
      accessor: 'guest_count',
    },
    {
      Header: t('Hotel'),
      id: 'hotel',
      accessor: 'hotel',
    },
    {
      Header: t('Pickup/Checkin time'),
      id: 'pickup_checkin_time',
      accessor: 'pickup_checkin_time',
    },
    {
      Header: t('Pickup/Checkin location'),
      id: 'pickup_checkin_location',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: t('Payment Type'),
      id: 'payment_type',
      accessor: (row) => row.payment_type && t(row.payment_type),
    },
    {
      Header: t('Transportation'),
      id: 'transportation',
      accessor: 'transportation',
    },
    {
      Header: t('Add-ons'),
      id: 'add_ons',
      accessor: 'add_ons',
    },
    {
      Header: t('Source'),
      id: 'booking_source',
      accessor: 'booking_source',
    },
    {
      Header: t('Remarks'),
      id: 'agent_notes',
      accessor: 'agent_notes',
    },
    {
      Header: t('Replies'),
      id: 'supplier_notes',
      accessor: 'supplier_notes',
    },
    {
      Header: t('Supplier'),
      id: 'supplier_name',
      accessor: 'supplier_name',
    },
    {
      Header: t('Confirmation Number'),
      id: 'supplier_reference',
      accessor: 'supplier_reference',
    },
    {
      Header: '#',
      id: 'id',
      accessor: 'id',
      Cell: ({ original }) => (
        <Link to={`/reservations/${original.id}`} {...linkProps}>
          {original.id}
        </Link>
      ),
    },
  ];
};

const isMultidayProductInstanceId = (id: string): boolean => {
  const parts = id.split(':');
  return parts.length === 3;
};

export const getCustomCalendarEvents = (
  activeUser: Account | null,
  timezone: string,
  productInstances: ProductInstance[],
  locale: string,
  t: TranslateFuncType,
  shouldRejectRequestBookingsBeyondCapacity: boolean
): CustomCalendarEvent[] => {
  const sortedProductInstances = _.orderBy(productInstances, [
    'start_date_time_utc',
    'end_date_time_utc',
  ]);

  const result: CustomCalendarEvent[] = [];
  sortedProductInstances.forEach((instance) => {
    // Remove MultidayProductInstance
    if (isMultidayProductInstanceId(instance.id)) {
      return;
    }

    const tzStartMoment = moment
      .tz(instance.start_date_time_utc, timezone)
      .locale(locale);
    const time = tzStartMoment.format('LT').replace(' ', '');

    const userIsPassthroughSupplier =
      activeUser?.organization_type === 'SUPPLIER' &&
      instance.shared_allotment_references &&
      instance.shared_allotment_references.passthrough_base_product_instance_id;
    const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);

    if (
      operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      !userIsPassthroughSupplier
    ) {
      const bookedSlots =
        (instance.occupied_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(instance),
          (a) => a.occupied_slots || 0
        );

      const totalSlots =
        (instance.total_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(instance),
          (a) => a.total_slots || 0
        );

      const instanceError = getInstanceError(instance, t);
      const { title, style } = instanceError
        ? {
            title: t('Error'),
            style: {
              // TODO: might not be necessary
              frontColor: '#ff7878',
            },
          }
        : {
            title: `${bookedSlots}/${formattedTotalAllotmentSlots(totalSlots)}`,
            style: {
              backgroundColor: getAvailabilityBackgroundColorClass(
                instance,
                bookedSlots,
                totalSlots
              ),
              iconType: getAvailabilityIconType(
                instance,
                bookedSlots,
                totalSlots,
                shouldRejectRequestBookingsBeyondCapacity
              ),
            },
          };
      result.push({
        id: instance.id,
        title,
        resource: instance,
        style,
        time,
      });
    } else {
      const hasRequestDeadline = hasBookingDeadline(instance, 'REQUEST');
      const requestDeadlineExpired = bookingDeadlineExpired(
        instance,
        'REQUEST'
      );
      const hasInstantDeadline = hasBookingDeadline(instance, 'INSTANT');
      const instantDeadlineExpired = bookingDeadlineExpired(
        instance,
        'INSTANT'
      );

      if (
        (!hasRequestDeadline || requestDeadlineExpired) &&
        (!hasInstantDeadline || instantDeadlineExpired)
      ) {
        return;
      }

      const availableSlots = instance.available_slots || 0;
      const { title, style } =
        instance.is_closed || allChannelsClosed
          ? {
              title: t('Closed'),
              style: {
                backgroundColor: 'gray',
              },
            }
          : (availableSlots <= 0 ||
              !hasInstantDeadline ||
              instantDeadlineExpired) &&
            hasRequestDeadline &&
            !requestDeadlineExpired
          ? {
              title: '',
              style: {
                backgroundColor: 'red1',
                iconType: 'REQUEST',
              },
            }
          : {
              title: t('{{count}} spot', {
                count: formattedTotalAllotmentSlots(availableSlots),
              }),
              style: {
                backgroundColor: '',
              },
            };
      result.push({
        id: instance.id,
        title,
        resource: instance,
        style,
        time,
      });
    }
  });
  return result;
};

type ColorClassName = 'gray' | 'red1' | 'red2' | 'red3' | 'red4' | 'red5' | '';

export const getAvailabilityBackgroundColorClass = (
  instance: ProductInstance,
  bookedSlots: number,
  totalSlots: number
): ColorClassName => {
  const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);

  if (instance.is_closed || allChannelsClosed) {
    return 'gray';
  }

  if (totalSlots === 0) {
    if (bookedSlots !== 0) {
      // TODO: decide the correct color
      return 'red5';
    }
  } else {
    // Free sale
    if (totalSlots === 10000000 && bookedSlots > 0) {
      return 'red1';
    }

    if (bookedSlots >= totalSlots) {
      return 'red4';
    }

    const ratio = bookedSlots / totalSlots;

    if (ratio >= 0.8) {
      return 'red3';
    }

    if (ratio >= 0.5) {
      return 'red2';
    }

    if (ratio > 0) {
      return 'red1';
    }
  }

  return '';
};
