import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_PRODUCT_TAGS_REQUEST,
  FETCH_PRODUCT_TAGS_SUCCESS,
  FETCH_PRODUCT_TAGS_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchProductTagsRequest = () => ({
  type: FETCH_PRODUCT_TAGS_REQUEST,
});

const fetchProductTagsSuccess = (response: any) => ({
  type: FETCH_PRODUCT_TAGS_SUCCESS,
  response,
});

const fetchProductTagsFailure = (error: string) => ({
  type: FETCH_PRODUCT_TAGS_FAILURE,
  error,
});

export const fetchProductTags =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchProductTagsRequest());
    axios
      .get('/api/products/tags', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchProductTagsSuccess(result.data)))
      .catch((error) => {
        dispatch(fetchProductTagsFailure(error));
      });
  };
