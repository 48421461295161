import * as React from 'react';

import baseStyles from 'client/v3-base.module.css';
import { Breadcrumbs } from 'client/components/v3/Common/Breadcrumbs';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

import styles from './PageHeader.module.css';

type PageHeaderProps = {
  title?: string;
  description?: string;
  descriptionWithInnerHTML?: string;
  breadcrumbPaths?: {
    text: string;
    url: string;
  }[];
  children?: JSX.Element | JSX.Element[];
  hideNewUIToggle?: boolean;
};

export const PageHeader = ({
  breadcrumbPaths,
  title,
  description,
  descriptionWithInnerHTML,
  children,
  hideNewUIToggle = false,
}: PageHeaderProps) => {
  const { partnershipMode } = React.useContext(PartnershipModeContext);
  return (
    <div className={baseStyles['l-main__header']}>
      {!partnershipMode && !hideNewUIToggle && (
        <ToggleNewUI origin="PAGE_HEADER" />
      )}
      {breadcrumbPaths && <Breadcrumbs paths={breadcrumbPaths} />}
      <div className={styles['c-pageHeader']}>
        {title && (
          <div className={styles['c-pageHeader__title']}>
            <h1>{title}</h1>
            {description && <span>{description}</span>}
            {descriptionWithInnerHTML && (
              <span
                className={styles['c-pageHeader__descriptionLink']}
                dangerouslySetInnerHTML={{ __html: descriptionWithInnerHTML }}
              />
            )}
          </div>
        )}
        <div
          className={
            title
              ? styles['c-pageHeader__actions']
              : styles['c-pageHeader__actions__noTitle']
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};
