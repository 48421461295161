import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './SavedSearchConditionsList.module.css';

type SearchConditionsListProps = {
  selectedOption: string;
  onChange: (selectedOption: string) => void;
};

export const SavedSearchConditionsList = ({
  selectedOption,
  onChange,
}: SearchConditionsListProps) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const presetOptions = useMemo(() => {
    if (!activeUserOrganization) {
      return [];
    }

    return (
      activeUserOrganization?.account_reservation_search_settings?.presets?.map(
        (preset) => ({
          text: preset.title ?? '',
          value: preset.key ?? '',
        })
      ) ?? []
    );
  }, [activeUserOrganization]);

  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (target instanceof Node && !fieldSetRef?.current?.contains(target)) {
        setIsClicked(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);
    window.document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [isClicked]);

  return (
    <>
      {/* Only show the component if preset options are available */}
      {presetOptions.length > 0 && (
        <fieldset
          className={styles['p-search__select']}
          ref={fieldSetRef}
          onClick={() => {
            setIsClicked(true);
          }}
        >
          <div className={styles['p-search__select__body']}>
            <legend className={styles['p-search__select__body__title']}>
              {t('Preset Condition')}
            </legend>
          </div>

          <ul
            className={clsx(
              styles['p-search__select__menu'],
              isClicked && styles['is-active']
            )}
          >
            {presetOptions.map((presetOption, index) => {
              return (
                <li
                  key={index}
                  className={styles['p-search__select__menu__item']}
                >
                  <input
                    type="radio"
                    name="select"
                    value={presetOption.value}
                    checked={selectedOption === presetOption.value}
                    onChange={() => {
                      onChange(presetOption.value);
                      setIsClicked(false);
                    }}
                  />
                  <i></i>
                  <p>{presetOption.text}</p>
                </li>
              );
            })}
          </ul>
        </fieldset>
      )}
    </>
  );
};
