import moment from 'moment-timezone';

import type { Recurrence } from 'shared/models/swagger';

export type StartTime = {
  timeSlotKey: string;
  hhMm: string;
  description: string;
  startDate: string;
  packageComponentTimeSlots: {
    productId: string;
    timeSlotKey: string;
    dayOffset: number;
  }[];
};

export const getStartTimesFromRecurrence = (
  recurrence: Recurrence[],
  today: string = moment().format('YYYY-MM-DD')
): StartTime[] => {
  const startTimesByHhMm: Record<string, StartTime> = {};

  for (const rule of recurrence) {
    if (rule.end_date_local && rule.end_date_local < today) {
      // Skip recurrence rules that have already passed
      continue;
    }

    if (!rule.start_times) {
      continue;
    }

    for (const ruleStartTime of rule.start_times) {
      if (!ruleStartTime.start_time_local) {
        continue;
      }

      const existingStartTime =
        startTimesByHhMm[ruleStartTime.start_time_local];

      if (
        existingStartTime &&
        existingStartTime.startDate < rule.start_date_local
      ) {
        continue;
      }

      const packageComponentTimeSlots = (
        ruleStartTime.package_component_time_slots || []
      ).map((timeSlot) => ({
        productId: timeSlot.product_id || '',
        timeSlotKey: timeSlot.time_slot_key || '',
        dayOffset: timeSlot.day_offset || 0,
      }));
      startTimesByHhMm[ruleStartTime.start_time_local] = {
        hhMm: ruleStartTime.start_time_local,
        timeSlotKey: ruleStartTime.time_slot_key || '',
        description: ruleStartTime.description || '',
        startDate: rule.start_date_local,
        packageComponentTimeSlots,
      };
    }
  }

  const allHhMm = Object.keys(startTimesByHhMm);
  allHhMm.sort(
    (hhMm1, hhMm2) =>
      moment(hhMm1, 'h:mm').valueOf() - moment(hhMm2, 'h:mm').valueOf()
  );
  const startTimes: StartTime[] = [];
  allHhMm.forEach((hhMm) => {
    startTimes.push(startTimesByHhMm[hhMm]);
  });
  return startTimes;
};
