import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type Props = {
  header: string;
  content: string;
  trigger?: React.ReactElement<any>;
  onConfirm: () => any;
  loading?: boolean;
  onClose?: () => any;
  open?: boolean;
  insertRoot?: boolean;
};
export const ConfirmModal = ({
  header,
  content,
  trigger,
  onConfirm,
  loading,
  onClose: onCloseFromProps,
  open: openFromProps,
  insertRoot,
}: Props) => {
  const { t } = useTranslation();
  const [myOpen, setMyOpen] = React.useState<boolean>(false);

  const onOpen = () => setMyOpen(true);

  const onClose = onCloseFromProps ?? (() => setMyOpen(false));

  const open = openFromProps ?? myOpen;
  return (
    <Modal
      trigger={trigger}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      title={header}
      insertRoot={insertRoot}
    >
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose} loading={loading}>
          {t('Cancel')}
        </Button>
        <Button
          style="blue"
          size="middle"
          onClick={async () => {
            await onConfirm();
            onClose();
          }}
          loading={loading}
        >
          {t('OK')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
