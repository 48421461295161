import * as React from 'react';

import { AccordionItem } from 'client/pages/ProductDetails/ProductContentsPane/AccordionList/AccordionItem';
import type { AccordionItemContent } from 'client/pages/ProductDetails/ProductContentsPane/AccordionList/AccordionItem';

import styles from './AccordionList.module.css';

type Props = {
  items: AccordionItemContent[];
};
export const AccordionList = ({ items }: Props) => {
  return (
    <ul className={styles['c-accordion']}>
      {items.map((item) => (
        <AccordionItem key={item.header} item={item} />
      ))}
    </ul>
  );
};
