import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  Input,
  TextArea,
  FieldWrapper,
  MultiSelect,
} from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { productOptionsSelector } from 'client/reducers/products';
import {
  createETicketLocationSet,
  updateETicketLocationSet,
} from 'client/actions/eTicketLocationSet';
import { ETicketLocationSet, ETicketLocation } from 'shared/models/swagger';

import { ETicketLocationMap } from './ETicketLocationMap';
import { EditETicketLocationModal } from './EditETicketLocationModal';
import {
  FormValues,
  getInitialValues,
  convertFormValuesToSwagger,
} from './locationSetFormValues';

type Props = {
  open: boolean;
  onClose: () => void;
  existingETicketLocationSet?: ETicketLocationSet | null;
};

export const EditETicketLocationSetModal = ({
  open,
  onClose,
  existingETicketLocationSet,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditLocationModal, setShowEditLocationModal] =
    React.useState(false);

  const productOptions = useSelector(productOptionsSelector);

  const initialValues = React.useMemo(
    () => getInitialValues(existingETicketLocationSet),
    [existingETicketLocationSet]
  );

  const [editETicketLocationSet, setEditETicketLocationSet] =
    React.useState<ETicketLocationSet | null>(
      existingETicketLocationSet ?? null
    );

  const [editETicketLocation, setEditETicketLocation] =
    React.useState<ETicketLocation | null>(null);

  return (
    <Modal
      title={t('Edit E-ticket Location Set')}
      open={open}
      onClose={onClose}
      insertRoot
      width="wide"
    >
      <Form
        initialValues={initialValues}
        debug={console.log}
        onSubmit={async (values: FormValues) => {
          try {
            let newETicketLocationSet;
            if (editETicketLocationSet) {
              newETicketLocationSet = await dispatch(
                updateETicketLocationSet(
                  editETicketLocationSet.id ?? '',
                  convertFormValuesToSwagger(values)
                )
              );
              onClose();
            } else {
              newETicketLocationSet = await dispatch(
                createETicketLocationSet(convertFormValuesToSwagger(values))
              );
            }
            setEditETicketLocationSet(
              newETicketLocationSet as ETicketLocationSet
            );
          } catch (e) {
            console.error(e);
          }
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              <Modal.Box>
                <Field name="name">
                  {({ input }) => (
                    <Input
                      label={t('Location Set Name')}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
              <Modal.Box>
                <Field name="description">
                  {({ input }) => (
                    <TextArea
                      label={t('Location Set Description')}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
              <Modal.Box>
                <FieldWrapper label={t('Select Products')}>
                  <Field name="productIds">
                    {({ input }) => (
                      <MultiSelect
                        options={productOptions}
                        selectedValues={input.value}
                        onChange={({ value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Modal.Box>
              {editETicketLocationSet && (
                <>
                  <Modal.Box>
                    <Button
                      size="middle"
                      style="green"
                      onClick={() => setShowEditLocationModal(true)}
                    >
                      {t('Create New E-ticket Location')}
                    </Button>
                  </Modal.Box>
                  <Modal.Box>
                    <ETicketLocationMap
                      eTicketLocationSetId={editETicketLocationSet?.id ?? ''}
                      onEditETicketLocationChange={(eTicketLocation) =>
                        setEditETicketLocation(eTicketLocation)
                      }
                    />
                  </Modal.Box>
                </>
              )}
            </Modal.Content>
            <Modal.Actions>
              {editETicketLocationSet ? (
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              ) : (
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Create')}
                </Button>
              )}
            </Modal.Actions>
          </form>
        )}
      </Form>
      {(showEditLocationModal || editETicketLocation) && (
        <EditETicketLocationModal
          open={showEditLocationModal || !!editETicketLocation}
          onClose={() => {
            setShowEditLocationModal(false);
            setEditETicketLocation(null);
          }}
          eTicketLocationSetId={editETicketLocationSet?.id ?? ''}
          existingETicketLocation={editETicketLocation ?? null}
        />
      )}
    </Modal>
  );
};
