import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Field } from 'react-final-form';

import { Button } from 'client/components/v3/Common/Button';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { Modal } from 'client/components/v3/Form/Modal';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';

type Props = {
  submitting: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
  open: boolean;
};

export const ReservationColumnSettingModal = ({
  submitting,
  setShowModal,
  onSubmit,
  open,
}: Props) => {
  const { t } = useTranslation();

  const allColumns = getReservationTableColumns(t, 'en').filter((col) => {
    return col.id !== 'edit';
  });
  const getColumns = (columnMask: string[]): any[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c));
  };

  return (
    <Modal
      title={t('Edit Reservation List Columns')}
      open={open}
      onClose={() => setShowModal(false)}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Button
            type="submit"
            text={t('Save')}
            uiType="bg"
            size="md"
            color="primary"
            loading={submitting}
            onClick={async () => {
              onSubmit();
              setShowModal(false);
            }}
          />
        </>
      }
      style={{ width: '600px', height: '600px' }}
    >
      <Field name="defaultColumns">
        {({ input }) => (
          <ToggleableDragAndDropList
            selectedItems={getColumns(
              input.value.map((c: any) => c.toLowerCase())
            ).map((col) => ({
              key: col.id,
              text: col.Header,
            }))}
            setSelectedItems={(items) =>
              input.onChange(items.map((item) => item.key.toUpperCase()))
            }
            candidateItems={
              allColumns.map((col) => ({
                key: col.id,
                text: col.Header,
              })) || []
            }
            doNotUseOuterBox={true}
          />
        )}
      </Field>
    </Modal>
  );
};
