import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fetchETicketFootprints } from 'client/actions/eTicketFootprints';
import { ReduxState } from 'client/reducers';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import reservationStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  reservation?: Reservation;
};

export const Footprint = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const eTicketFootprints = useSelector(
    (state: ReduxState) => state.eTicketFootprints.all
  );

  React.useEffect(() => {
    dispatch(
      fetchETicketFootprints({
        reservation_id: reservation?.id ?? '',
      })
    );
  }, []);

  const footprints = React.useMemo(() => {
    return eTicketFootprints
      .map((record) => {
        let nearestLocationNameOrStubText = '';
        if (record.reservation_is_free_start_date_time && record.stub_text) {
          let text = t('Free Pass');
          if (record.stub_text) {
            text += `(${record.stub_text})`;
          }
          nearestLocationNameOrStubText = text;
        } else {
          nearestLocationNameOrStubText = record.nearest_location_name ?? '';
        }

        return {
          id: record.id,
          dateTime: record?.date_time_utc
            ? moment.tz(
                record.date_time_utc,
                reservation?.start_timezone ?? 'UTC'
              )
            : null,
          nearestLocationName: nearestLocationNameOrStubText,
          latitude: record.latitude,
          longitude: record.longitude,
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  }, [eTicketFootprints, reservation]);

  return (
    <section
      id="footprint"
      className={clsx(reservationStyles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={reservationStyles['p-reservationsDetail']}>
        <div className={reservationStyles['p-reservationsDetail__header']}>
          <p className={reservationStyles['p-reservationsDetail__ttl']}>
            {t('E-ticket View history')}
          </p>
          <div className={reservationStyles['p-reservationsDetail__actions']}>
            {(footprints ?? []).length > 0 && (
              <a
                href={`https://www.google.com/maps/dir/${footprints
                  .filter(
                    (footprint) => footprint.latitude && footprint.longitude
                  )
                  .map(
                    (footprint) =>
                      `${footprint.latitude},${footprint.longitude}`
                  )
                  .join('/')}`}
                target="_blank"
                rel="noreferrer"
                className={baseStyles['link']}
              >
                <Button
                  text={t('Show route on Google Maps')}
                  iconAfterText={
                    <i className="c-icon-outline-general-link-external-02"></i>
                  }
                />
              </a>
            )}
          </div>
        </div>
        <div className={reservationStyles['p-reservationsDetail__body']}>
          <div className={reservationStyles['p-relations']}>
            <table
              className={clsx(
                tableSmallStyles['c-tableSmall'],
                tableSmallStyles['row']
              )}
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr>
                  <th className={baseStyles['u-width-96']}>{t('Date/Time')}</th>
                  <th className={baseStyles['u-width-176']}>
                    {t('Latitude') + ' / ' + t('Longitude')}
                  </th>
                  <th className={baseStyles['u-width-208']}>
                    {t('Nearest Location Name') + ' / ' + t('Ticket Usage')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {footprints.length === 0 && (
                  <tr>
                    <td>{t('No data')}</td>
                  </tr>
                )}
                {footprints.length > 0 &&
                  footprints.map((footprint, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{footprint.dateTime?.format('lll') ?? ''}</td>
                        <td>
                          {footprint.latitude && footprint.longitude && (
                            <a
                              href={`https://www.google.com/maps?q=${footprint.latitude},${footprint.longitude}`}
                              target="_blank"
                              rel="noreferrer"
                              className={baseStyles['link']}
                            >
                              {footprint.latitude}, {footprint.longitude}
                            </a>
                          )}
                        </td>
                        <td>{footprint.nearestLocationName}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
