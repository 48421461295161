import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import querystring from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { EditSingleDayOperatingHourModal } from 'client/components/EditSingleDayOperatingHourModal/EditSingleDayOperatingHourModal';
import { Loading } from 'client/pages/Loading';
import { MultiSelect, Button } from 'client/components/Form';
import { ParticipationDate } from 'client/components/ParticipationDate/ParticipationDate';
import {
  ResourceReservationListModal,
  defaultVisibleColumns,
} from 'client/components/ResourceReservationListModal/ResourceReservationListModal';
import { SendEmailModal } from 'client/components/SendEmailModal/SendEmailModal';
import {
  accountsSelector,
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import {
  fetchGuideCSV,
  fetchGuidePDF,
  sendGuideEmail,
  fetchGuideSingleDaySchedules,
} from 'client/actions/guides';
import { fetchReservations } from 'client/actions/reservations';
import {
  getGuideAccountShapes,
  isActiveAccount,
} from 'client/libraries/util/accountShape';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import anotherIcon from 'client/images/ic_another.svg';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { GuideScheduleCustomTable } from './GuideScheduleCustomTable';

export const GuideSchedule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [csvLoading, setCsvLoading] = React.useState<boolean>(false);
  //  const [emailSending, setEmailSending] = React.useState<boolean>( false);
  const [participationDate, setParticipationDate] = React.useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [pdfLoading, setPdfLoading] = React.useState<boolean>(false);
  const [selectedGuides, setSelectedGuides] = React.useState<string[]>([]);
  const [showAnotherButtons, setShowAnotherButtons] =
    React.useState<boolean>(false);
  const [showReservationListConfig, setShowReservationListConfig] =
    React.useState<{
      accountId: string;
      date: string;
    } | null>(null);
  const [showSendEmailModal, setShowSendEmailModal] =
    React.useState<boolean>(false);
  const [showSingleDayScheduleConfig, setShowSingleDayScheduleConfig] =
    React.useState<{
      accountId: string;
      date: string;
    } | null>(null);
  const accounts = useSelector(accountsSelector);
  const accountsLoading = useSelector(
    (state: ReduxState) => state.accounts.loading
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const emailStatus = useSelector(
    (state: ReduxState) => state.guides.emailStatus
  );
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organizationsLoading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const reservationsLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const loading =
    accountsLoading || organizationsLoading || reservationsLoading;
  const timezone =
    activeUserOrganization?.default_timezone || 'America/Los_Angeles';
  const activeUser = useSelector(activeUserSelector);
  React.useEffect(() => {
    const startDate = moment(participationDate).tz(timezone);
    dispatch(
      fetchReservations({
        start_date_local_from: startDate.format('YYYY-MM-DD'),
        start_date_local_to: startDate.add(7, 'days').format('YYYY-MM-DD'),
        filter: querystring.stringify({
          status: ['CONFIRMED'],
        }),
      })
    );
    dispatch(fetchGuideSingleDaySchedules());
  }, [participationDate]);
  const manifestReservationShapes = (reservations || []).map((reservation) =>
    toManifestReservationShape(reservation, t)
  );
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  ).filter((account) => isActiveAccount(account));
  const sortedGuideAccountShapes = React.useMemo(() => {
    return _.sortBy(
      guideAccountShapes,
      (guideAccountShape) => guideAccountShape.name
    );
  }, [guideAccountShapes]);
  const guideOptions = (sortedGuideAccountShapes || []).map((guide) => {
    return {
      key: guide.id,
      value: guide.id,
      text: guide.name,
    };
  });
  const items = sortedGuideAccountShapes
    .filter((account) => {
      if (selectedGuides.length === 0) {
        return true;
      }

      return selectedGuides.includes(account.id);
    })
    .map((account) => {
      const pax: Record<string, string> = {};
      manifestReservationShapes.forEach((reservation) => {
        if ((reservation.dispatch_guides || []).includes(account.id)) {
          const participationDate: string =
            reservation.participates_at?.tz(timezone).format('YYYY-MM-DD') ||
            '';
          const paxOfTheDay = reservation.guests.length || 0;
          pax[participationDate] = String(
            paxOfTheDay + Number(pax[participationDate] || '0')
          );
        }
      });
      return {
        account,
        pax,
      };
    });

  const createExportParam = () => {
    const startDate = moment(participationDate);
    const targetGuides = (sortedGuideAccountShapes || []).filter((account) => {
      if (selectedGuides.length === 0) {
        return true;
      }

      return selectedGuides.includes(account.id);
    });
    return {
      date_from_start: startDate.format('YYYY-MM-DD'),
      date_from_end: startDate.add(6, 'days').format('YYYY-MM-DD'),
      timezone: timezone,
      guide_account_ids: targetGuides.map((account) => account.id),
      guide_names: targetGuides.map((account) => {
        return {
          id: account.id,
          name: account.name,
        };
      }),
    };
  };

  return (
    <>
      <div className={clsx(componentStyles['c-headline-search'])}>
        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <ParticipationDate
            value={participationDate}
            onChange={setParticipationDate}
            locale={locale}
          />
        </div>

        {guideOptions.length > 0 && (
          <div className={clsx(componentStyles['c-headline-search__item'])}>
            <MultiSelect
              options={guideOptions}
              selectedValues={selectedGuides}
              onChange={({ value }) => setSelectedGuides(value)}
            />
          </div>
        )}
      </div>

      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              showAnotherButtons
                ? baseStyles['is-open']
                : baseStyles['is-close']
            )}
          >
            <ul>
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={async () => {
                    try {
                      setPdfLoading(true);
                      await dispatch(fetchGuidePDF({ ...createExportParam() }));
                    } catch (err) {
                      console.log(err);
                    }

                    setPdfLoading(false);
                  }}
                  loading={pdfLoading}
                  disabled={items.length === 0}
                >
                  {t('Download as PDF')}
                </Button>
              </li>
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={async () => {
                    try {
                      setCsvLoading(true);
                      await dispatch(fetchGuideCSV({ ...createExportParam() }));
                    } catch (err) {
                      console.log(err);
                    }

                    setCsvLoading(false);
                  }}
                  loading={csvLoading}
                  disabled={items.length === 0}
                >
                  {t('Download as CSV')}
                </Button>
              </li>
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={() => {
                    setShowSendEmailModal(true);
                  }}
                  disabled={items.length === 0}
                >
                  {t('Send Emails')}
                </Button>
              </li>
            </ul>
            <a
              onClick={() => {
                setShowAnotherButtons(!showAnotherButtons);
              }}
            >
              <img src={anotherIcon} />
            </a>
          </div>
        </div>
      </div>

      <SendEmailModal
        title={t('Send Email')}
        value={{
          subject: participationDate
            ? t('Staff schedule - {{formattedDate}}', {
                formattedDate: participationDate,
              })
            : t('Staff schedule'),
          toAddresses: [],
        }}
        open={showSendEmailModal}
        emailStatus={emailStatus}
        onClose={() => {
          setShowSendEmailModal(false);
        }}
        onSendClick={(value) => {
          return dispatch(
            sendGuideEmail({
              ...createExportParam(),
              subject: value.subject,
              to_addresses: value.toAddresses,
            })
          );
        }}
      />

      <GuideScheduleCustomTable
        items={items}
        columns={[0, 1, 2, 3, 4, 5, 6].map((offset) => {
          const startDate = moment(participationDate);
          const ret = startDate.add(offset, 'days').format('YYYY-MM-DD');
          return ret;
        })}
        locale={locale}
        dayMonthFormat={dayMonthFormat}
        onPaxClick={setShowReservationListConfig}
        onEventDayClick={(e) => {
          if (
            hasCustomUserRoleWritePermissions(
              activeUser,
              'SHIFT_MANAGEMENT.SCHEDULE'
            )
          ) {
            setShowSingleDayScheduleConfig(e);
          }
        }}
        guideSingleDaySchedules={guideSingleDaySchedules || []}
      />

      <ResourceReservationListModal
        open={showReservationListConfig !== null}
        resourceType={'guide'}
        resourceKey={showReservationListConfig?.accountId || ''}
        visibleColumns={defaultVisibleColumns}
        onClose={() => {
          setShowReservationListConfig(null);
        }}
        reservations={manifestReservationShapes.filter((reservation) => {
          return (
            reservation.participates_at.tz(timezone).format('YYYY-MM-DD') ===
            showReservationListConfig?.date
          );
        })}
        title={
          (accounts || []).find(
            (account) => account.id === showReservationListConfig?.accountId
          )?.name || ''
        }
        timezone={timezone}
      />

      <EditSingleDayOperatingHourModal
        open={showSingleDayScheduleConfig !== null}
        onClose={() => {
          setShowSingleDayScheduleConfig(null);
        }}
        date={showSingleDayScheduleConfig?.date || ''}
        accountId={showSingleDayScheduleConfig?.accountId || ''}
        operatingHoursRule={
          (sortedGuideAccountShapes || []).find(
            (account) => account.id === showSingleDayScheduleConfig?.accountId
          )?.operating_hours_rule || null
        }
        guideSingleDaySchedule={
          guideSingleDaySchedules.find((schedule) => {
            if (
              schedule.guide_account_id ===
                showSingleDayScheduleConfig?.accountId &&
              schedule.date_local === showSingleDayScheduleConfig?.date
            ) {
              return true;
            }

            return false;
          }) || null
        }
        onSubmit={() => {
          dispatch(fetchGuideSingleDaySchedules());
        }}
      />

      {loading && <Loading />}
    </>
  );
};
