import * as React from 'react';
import { Field } from 'react-final-form';

import { RichPlan, truncated, withQueryParams } from '../../util';

const RichPlanListEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/RichPlanListEditButton')
);

type Props = {
  data: {
    editMode: boolean;
    planSections: RichPlan[];
    newsletterCampaignId: string;
    language: string;
  };
};

export const PlansSection = ({ data }: Props) => {
  return (
    <>
      {data.editMode && (
        <Field name="templateValues.planSections">
          {({ input }) => (
            <RichPlanListEditButton
              initialValue={input.value}
              onSave={(newValue) => input.onChange(newValue)}
              language={data.language}
            />
          )}
        </Field>
      )}
      {data.planSections.map((planSection) => (
        <tr key={planSection.key} style={{ margin: 0, padding: 0 }}>
          <td>
            <div
              style={{
                margin: 0,
                padding: '1.5em',
                backgroundColor: '#ffffff',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  fontSize: '1.5em',
                  whiteSpace: 'pre-line',
                  borderBottom: '1px solid #E4E4E7',
                  paddingBottom: '1em',
                }}
              >
                {planSection.title}
              </p>
              <div
                style={{
                  margin: '1.5em 0',
                  verticalAlign: 'middle',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={planSection.keyVisualUrl}
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </div>
              <div style={{ width: '100%', marginBottom: '1.5em' }}>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  style={{
                    width: '100%',
                    padding: '16px',
                    backgroundColor: 'rgb(37, 99, 235)',
                    color: '#FFF',
                    borderTopLeftRadius: '1em',
                    borderTopRightRadius: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0 }}>{planSection.highlightText}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: '100%',
                    padding: '16px',
                    textAlign: 'left',
                    backgroundColor: 'rgb(239, 246, 255)',
                    color: 'rgb(63, 63, 70)',
                    borderBottomLeftRadius: '1em',
                    borderBottomRightRadius: '1em',
                  }}
                >
                  <tbody>
                    {planSection.highlights.map((highlight, index) => (
                      <tr
                        key={index}
                        style={{
                          marginTop: index === 0 ? undefined : '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingBottom:
                            index === planSection.highlights.length - 1
                              ? undefined
                              : '8px',
                          borderBottom:
                            index === planSection.highlights.length - 1
                              ? undefined
                              : '1px solid rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <td
                          style={{
                            fontWeight: 'bold',
                            lineHeight: '1.25em',
                            fontSize: '1.2em',
                            color: 'rgb(37, 99, 235)',
                          }}
                        >
                          {'✓'}
                        </td>
                        <td style={{ marginLeft: '12px' }}>{highlight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {planSection.linkType === 'PRODUCT' && (
                <div
                  style={{
                    marginTop: '1em',
                    border: '1px solid #E4E4E7',
                    borderRadius: '16px',
                  }}
                >
                  <a
                    href={withQueryParams(planSection.linkUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: data.newsletterCampaignId ?? 'todo',
                      utm_content: 'mainProduct',
                      lng: data.language,
                    })}
                    style={{
                      margin: '0',
                      padding: 0,
                      display: 'table',
                      textDecoration: 'none',
                      width: '100%',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{
                        margin: '1.5em',
                        verticalAlign: 'middle',
                        overflow: 'hidden',
                        minHeight: planSection.productThumbnailUrl
                          ? undefined
                          : '200px',
                      }}
                    >
                      <img
                        src={planSection.productThumbnailUrl}
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '0 1.5em 1.5em 1.5em',
                        color: '#333333',
                        verticalAlign: 'middle',
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '16px',
                          fontWeight: 'bold',
                          lineHeight: '1.5em',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {planSection.productName}
                      </p>
                      <p
                        style={{
                          margin: '.5em 0 0 0',
                          padding: 0,
                          fontSize: '12px',
                          color: '#4A6C7A',
                          lineHeight: '1.5em',
                        }}
                      >
                        {truncated(planSection.productDescription, 200)}
                      </p>
                    </div>
                  </a>
                  <div style={{ padding: '0 1.5em 1.5em 1.5em' }}>
                    <a
                      href={withQueryParams(planSection.linkUrl, {
                        utm_source: 'ntmg.newsletter',
                        utm_medium: 'email',
                        utm_campaign: data.newsletterCampaignId ?? 'todo',
                        utm_content: 'mainProduct',
                        lng: data.language,
                      })}
                      style={{
                        maxWidth: '240px',
                        borderRadius: '9999px',
                        textDecoration: 'none',
                        padding: '2px',
                        display: 'block',
                        margin: '0 auto',
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        style={{
                          margin: 0,
                          padding: 0,
                          display: 'block',
                          fontWeight: 'bold',
                          width: '100%',
                          height: '48px',
                          lineHeight: '48px',
                          fontSize: '16px',
                          backgroundColor: '#4DA1EF',
                          color: '#ffffff',
                          textDecoration: 'none',
                          textAlign: 'center',
                          borderRadius: '40px',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {planSection.detailsButtonText}
                      </span>
                    </a>
                  </div>
                </div>
              )}
              {planSection.linkType === 'CUSTOM_PAGE' && (
                <div style={{ margin: '2em 1.5em' }}>
                  <a
                    href={withQueryParams(planSection.linkUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: data.newsletterCampaignId ?? 'todo',
                      utm_content: 'mainProduct',
                      lng: data.language,
                    })}
                    style={{
                      maxWidth: '240px',
                      borderRadius: '9999px',
                      textDecoration: 'none',
                      padding: '2px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      style={{
                        margin: 0,
                        padding: 0,
                        display: 'block',
                        fontWeight: 'bold',
                        width: '100%',
                        height: '48px',
                        lineHeight: '48px',
                        fontSize: '16px',
                        backgroundColor: '#4DA1EF',
                        color: '#ffffff',
                        textDecoration: 'none',
                        textAlign: 'center',
                        borderRadius: '40px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {planSection.detailsButtonText}
                    </span>
                  </a>
                </div>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
