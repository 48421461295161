import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from 'client/hooks/useWindowDimensions';
import componentStyles from 'client/components/components.module.css';

import type { ColumnType, TaskShape } from './utils';

type Props = {
  items: TaskShape[];
  columns: ColumnType[];
  rowCount: number;
  currentPage: number;
  onRowCountChange: (rowCount: number) => void;
  onCurrentPageChange: (page: number) => void;
};

export const DashboardCustomTable = ({
  items,
  columns,
  rowCount,
  currentPage,
  onRowCountChange,
  onCurrentPageChange,
}: Props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const isMobileView = width < 786;

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (items.length < rowCount * page) {
      page = Math.floor(items.length / rowCount);
    }

    onCurrentPageChange(page);
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  return (
    <>
      <div className={clsx(componentStyles['c-table-main'])}>
        <div
          className={clsx(componentStyles['c-table-main__thead'])}
          ref={tableHeaderRef}
        >
          <table>
            <tbody>
              <tr>
                {columns.map((column, idx) => {
                  const width = column.width || '';
                  return (
                    <th
                      style={isMobileView ? {} : { width: `${width}px` }}
                      key={idx}
                    >
                      {column.Header}
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={clsx(componentStyles['c-table-main__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <table>
            <tbody>
              {items
                .filter((_, index) => {
                  if (index < rowHead) {
                    return false;
                  }

                  if (rowHead + rowCount <= index) {
                    return false;
                  }

                  return true;
                })
                .map((item, ridx) => {
                  return (
                    <tr key={ridx}>
                      {columns.map((column, idx) => {
                        const width = column.width || '';

                        const displayValue = column.accessor(item);

                        if (column.th) {
                          return (
                            <th
                              key={idx}
                              style={
                                isMobileView ? {} : { width: `${width}px` }
                              }
                            >
                              {displayValue}
                            </th>
                          );
                        } else {
                          return (
                            <td
                              key={idx}
                              style={
                                isMobileView ? {} : { width: `${width}px` }
                              }
                              className={clsx(
                                column.sub
                                  ? componentStyles['c-table-sub__btn']
                                  : ''
                              )}
                              data-text={column.Header}
                            >
                              {displayValue}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['prev']
          )}
          onClick={() => {
            scrollButtonClickHandler('prev');
          }}
        >
          <p></p>
        </div>
        <div
          className={clsx(
            componentStyles['c-table-main__btn'],
            componentStyles['next']
          )}
          onClick={() => {
            scrollButtonClickHandler('next');
          }}
        >
          <p></p>
        </div>
      </div>

      <div className={clsx(componentStyles['c-pagination'])}>
        <div className={clsx(componentStyles['c-pagination__size'])}>
          <p>{t('Number of lines')}:</p>
          <label>
            <select
              value={rowCount}
              onChange={(event: any) => {
                const count = parseInt(event.target.value, 10);
                onRowCountChange(count);
              }}
            >
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
        <div className={clsx(componentStyles['c-pagination__jump'])}>
          <p>{t('Page')}:</p>
          <input
            type="number"
            value={currentPage}
            onChange={currentPageHandler}
          />
          <p>of {pageCount}</p>
        </div>
        <div className={clsx(componentStyles['c-pagination__btns'])}>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              1 < currentPage ? '' : componentStyles['disable']
            )}
            onClick={pageBackClickHandler}
          >
            {t('Previous')}
          </a>
          <a
            className={clsx(
              componentStyles['c-pagination__btn'],
              currentPage < pageCount ? '' : componentStyles['disable']
            )}
            onClick={pageForwardClickHandler}
          >
            {t('Next')}
          </a>
        </div>
      </div>
    </>
  );
};
