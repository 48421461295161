import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import type { FamilyDiscount } from 'shared/models/swagger';

type Props = {
  showErrors: boolean;
  familyDiscount: FamilyDiscount;
  onFamilyDiscountChange: (newFamilyDiscount: FamilyDiscount) => void;
  guestTypeKeys: string[];
};
export const FamilyDiscountInput = ({
  familyDiscount,
  guestTypeKeys,
  onFamilyDiscountChange,
  showErrors,
}: Props) => {
  const { t } = useTranslation();
  const baseGuestTypeKey =
    (familyDiscount && familyDiscount.base_guest_type_key) || '';
  const qualifyingBaseGuestCount =
    (familyDiscount && familyDiscount.qualifying_base_guest_count) || 1;
  const discountGuestTypeKey =
    (familyDiscount && familyDiscount.discount_guest_type_key) || '';
  const guestTypeKeyOptions = guestTypeKeys.map((key) => ({
    key,
    value: key,
    text: key,
  }));
  return (
    <Modal.Box column="three">
      <Select
        label={t('Base unit (ex. Adult)')}
        required
        error={(showErrors && !baseGuestTypeKey) as any}
        value={baseGuestTypeKey}
        options={guestTypeKeyOptions}
        onChange={(e, { value }) => {
          onFamilyDiscountChange({
            ...familyDiscount,
            base_guest_type_key: value,
          });
        }}
      />
      <Select
        label={t('Base unit quantity')}
        value={qualifyingBaseGuestCount as any}
        options={
          [1, 2, 3, 4].map((v) => ({
            key: v,
            value: v,
            text: v,
          })) as any
        }
        onChange={(e, { value }) => {
          onFamilyDiscountChange({
            ...familyDiscount,
            qualifying_base_guest_count: parseInt(value, 10),
          });
        }}
      />
      <Select
        label={t('Discounted unit (ex. Child)')}
        required
        error={(showErrors && !discountGuestTypeKey) as any}
        value={discountGuestTypeKey}
        options={guestTypeKeyOptions}
        onChange={(e, { value }) => {
          onFamilyDiscountChange({
            ...familyDiscount,
            discount_guest_type_key: value,
          });
        }}
      />
    </Modal.Box>
  );
};
