import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { Dimmer, Loader } from 'semantic-ui-react';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import {
  fetchDashboardSettings,
  putDashboardSettings,
} from 'client/actions/dashboardSettings';
import {
  activeAccountSelector,
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { fetchProducts } from 'client/actions/products';
import { getDefaultGadgets } from 'client/pages/v3/FlexibleDashboard/util';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';

import { GadgetRow } from './GadgetRow';
import { Gadget } from './Gadget';
import { GadgetEditContext } from './GadgetEditContext';
import { GadgetEditorModal } from './GadgetEditorModal';
import { AddGadgetModal } from './AddGadgetModal/AddGadgetModal';

const groupGadgets = (gadgets: DashboardGadget[]): DashboardGadget[][] => {
  const groupedGadgets: DashboardGadget[][] = [];
  let currentGroup: DashboardGadget[] = [];

  gadgets.forEach((gadget) => {
    if (
      gadget.gadgetType === 'access-summary' ||
      gadget.gadgetType === 'reservation-list' ||
      gadget.gadgetType === 'reservation-summary'
    ) {
      // These gadgets are full width and need their own group. Push the current group, push a group with this gadget, and
      // start a new group.
      if (currentGroup.length) {
        groupedGadgets.push(currentGroup);
        currentGroup = [];
      }
      groupedGadgets.push([gadget]);
    } else {
      if (currentGroup.length === 3) {
        // If the current group is full, push it and start a new group.
        groupedGadgets.push(currentGroup);
        currentGroup = [];
      }
      currentGroup.push(gadget);
    }
  });

  if (currentGroup.length) {
    groupedGadgets.push(currentGroup);
  }

  return groupedGadgets;
};

export const FlexibleDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editModeIsActive, setEditModeIsActive] = React.useState(false);
  const [saveSucceeded, setSaveSucceeded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activeGadget, setActiveGadget] =
    React.useState<DashboardGadget | null>(null);
  const [editingGadget, setEditingGadget] =
    React.useState<DashboardGadget | null>(null);
  const [gadgets, setGadgets] = React.useState<DashboardGadget[]>([]);
  const [showAddGadgetModal, setShowAddGadgetModal] = React.useState(false);
  const activeAccount = useSelector(activeAccountSelector);
  const settings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );
  const dashboardSettingsLoading = useSelector(
    (state: ReduxState) => state.dashboardSettings.loading
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    if (activeAccount?.id) {
      dispatch(fetchDashboardSettings());
    }
  }, [activeAccount?.id]);
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [activeUserOrganization]);

  const defaultGadgets: DashboardGadget[] = getDefaultGadgets(t);

  React.useEffect(() => {
    // Wait until settings is ready to check for the existence of gadgets in settings
    // to prevent gadgets appearing then disappearing
    if (settings) {
      if (settings.gadgets) {
        setGadgets(settings.gadgets);
      } else {
        setGadgets(defaultGadgets);
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [settings]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const newActiveGadget = gadgets.find((w) => w.key === event.active.id);

    if (newActiveGadget) {
      setActiveGadget(newActiveGadget);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setGadgets((gadgets) => {
        const oldIndex = gadgets.findIndex((w) => w.key === active.id);
        const newIndex = gadgets.findIndex((w) => w.key === over?.id);

        return arrayMove(gadgets, oldIndex, newIndex);
      });
    }
  };

  const gadgetGroups = groupGadgets(gadgets);

  return (
    <V3Page>
      <Snackbar
        text={t('Save Successful')}
        color="success"
        shouldShow={saveSucceeded}
        onClose={() => setSaveSucceeded(false)}
      />
      <PageHeader title={t('Dashboard')}>
        {editModeIsActive ? (
          <>
            <Button
              color="white"
              text={t('Cancel')}
              onClick={() => {
                setEditModeIsActive(false);
              }}
            />
            <Button
              color="primary"
              text={t('Save')}
              loading={dashboardSettingsLoading}
              onClick={async () => {
                await dispatch(
                  putDashboardSettings(
                    JSON.stringify({
                      gadgets: gadgets,
                    })
                  )
                );
                setSaveSucceeded(true);
                setEditModeIsActive(false);
              }}
            />
          </>
        ) : (
          <>
            {hasCustomUserRoleWritePermissions(activeUser, 'HOME') && (
              <Button
                color="white"
                iconBeforeText={<i className="c-icon-outline-general-plus" />}
                text={t('Add Gadget')}
                onClick={() => setShowAddGadgetModal(true)}
              />
            )}
            {hasCustomUserRoleWritePermissions(activeUser, 'HOME') && (
              <Button
                color="primary"
                iconBeforeText={
                  <i className="c-icon-outline-general-edit-05" />
                }
                text={t('Edit')}
                onClick={() => setEditModeIsActive(true)}
              />
            )}
          </>
        )}
      </PageHeader>
      <PageContent>
        {loading && (
          <div
            style={{
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Dimmer active={loading} page={true} inverted>
              <Loader>{t('Loading')}</Loader>
            </Dimmer>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            fontSize: '16px',
          }}
        >
          <GadgetEditContext.Provider
            value={{
              editModeIsActive,
              onDeleteGadget: (key: string) => {
                setGadgets(gadgets.filter((w) => w.key !== key));
              },
              onEditGadgetClick: (key: string) => {
                const gadget = gadgets.find((w) => w.key === key);
                if (gadget) {
                  setEditingGadget(gadget);
                }
              },
            }}
          >
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
            >
              <SortableContext
                items={gadgets.map((w) => w.key)}
                strategy={rectSortingStrategy}
              >
                {gadgetGroups.map((group) => (
                  <GadgetRow key={group[0].key} gadgets={group} />
                ))}
              </SortableContext>
              <DragOverlay>
                {activeGadget ? <Gadget gadget={activeGadget} /> : null}
              </DragOverlay>
            </DndContext>
          </GadgetEditContext.Provider>
        </div>
      </PageContent>
      {editingGadget && (
        <GadgetEditorModal
          editingGadget={editingGadget}
          onClose={() => setEditingGadget(null)}
        />
      )}
      {showAddGadgetModal && (
        <AddGadgetModal onClose={() => setShowAddGadgetModal(false)} />
      )}
    </V3Page>
  );
};
