import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { updateReview } from 'client/actions/reviews';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { Button, TextArea } from 'client/components/Form';
import type { Review } from 'shared/models/swagger';

type FormValues = {
  status?: 'PUBLISHED' | 'DECLINED';
  supplierComments: string;
};
type Props = {
  review: Review;
  trigger: React.ReactNode;
  onReviewUpdate?: () => void;
};
export const ReviewEditModal = ({ review, trigger, onReviewUpdate }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues: FormValues = React.useMemo(() => {
    return {
      status:
        review.status && review.status !== 'PENDING'
          ? review.status
          : undefined,
      supplierComments: review.supplier_comments ?? '',
    };
  }, [review.status, review.supplier_comments]);
  return (
    <>
      <span onClick={() => setShowModal(true)}>{trigger}</span>
      <Modal
        insertRoot
        title={t('Edit Review')}
        open={showModal}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
      >
        <Form
          initialValues={initialValues}
          onSubmit={async (values: FormValues) => {
            try {
              await dispatch(
                updateReview(review.id, {
                  status: values.status,
                  supplier_comments: values.supplierComments,
                })
              );
              setShowModal(false);
              onReviewUpdate?.();
            } catch (err) {
              console.log(err);
              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <EnumRadioButtonGroup
                name="status"
                options={[
                  {
                    value: 'PUBLISHED',
                    label: t('Publish'),
                    tooltipText: t(
                      'Show this review to customers and include in public ratings'
                    ),
                  },
                  {
                    value: 'DECLINED',
                    label: t('Reject'),
                    tooltipText: t('Do not show this review to customers'),
                  },
                ]}
              />
              <Box mt={2} mb={2}>
                <Field name="supplierComments">
                  {({ input }) => (
                    <TextArea
                      label={t('Supplier Comments')}
                      {...(input as any)}
                    />
                  )}
                </Field>
              </Box>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  style="green"
                  size="middle"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          )}
        </Form>
      </Modal>
    </>
  );
};
