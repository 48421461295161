import type { Translation } from 'shared/models/swagger';

export const isNullTranslation = (translation: Translation): boolean => {
  // Translation is null if it has no source language
  if (!translation.source_language) {
    return true;
  }

  const lang =
    translation?.source_language?.toLowerCase() as keyof typeof translation;

  // Translation is null if it has no source language text
  if (!translation[lang]) {
    return true;
  }

  // Translation is null if it has no translated text for a language other than source
  // language
  for (const key of Object.keys(translation)) {
    if (
      key !== 'source_language' &&
      key !== translation.source_language?.toLowerCase()
    ) {
      return false;
    }
  }

  return true;
};
