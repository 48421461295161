import * as React from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { DraggablePreviewImage } from 'client/components/ImageVideoAudioInput/DraggablePreviewImage';
import { YouTubeVideoEditorModal } from 'client/components/ImageVideoAudioInput/YouTubeVideoEditorModal/YouTubeVideoEditorModal';
import { activeUserSelector } from 'client/reducers/user';
import { s3MediaStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import baseStyles from 'client/base.module.css';

import styles from './ImageVideoAudioInput.module.css';

type Props = {
  fileUrls: string[];
  onChange: (newValue: string[]) => void;
  disableYoutubeVideos?: boolean;
  maxFileCount?: number;
  error?: string;
  enableSvgFormat?: boolean;
  fileTypes?: string[];
};
export const ImageVideoAudioInput = ({
  fileUrls,
  onChange,
  disableYoutubeVideos,
  maxFileCount,
  error,
  enableSvgFormat,
  fileTypes,
}: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const [uploadError, setUploadError] = React.useState<string>('');
  const [showYouTubeVideoEditorModal, setShowYouTubeVideoEditorModal] =
    React.useState(false);
  const imageFileInputRef = React.useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = fileTypes
    ? fileTypes
    : [
        'image/jpeg',
        'image/png',
        ...(enableSvgFormat ? ['image/svg+xml'] : []),
      ];
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (imageFileInputRef?.current) {
      imageFileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const handleMoveImage = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = fileUrls[dragIndex];
      const newfileUrls = [...fileUrls];
      newfileUrls.splice(dragIndex, 1);
      newfileUrls.splice(hoverIndex, 0, dragImage);
      onChange(newfileUrls);
    },
    [fileUrls]
  );

  const uploadFiles = async (files: File[]) => {
    const newImages: string[] = [];
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';

    for (const file of files) {
      try {
        const filename = getRandomFilenameForUpload(file);
        const objName = `${organizationId}/tut/${filename}`;
        await s3MediaStorage.put(objName, file, {
          contentType: file.type,
        });
        const url = await s3MediaStorage.get(objName);
        // Remove pre-signed suffix
        const uploadedFileURL = url.split('?')[0];
        newImages.push(uploadedFileURL);
        onChange([...fileUrls, ...newImages]);
      } catch (err) {
        console.log('err', err);
        /* TODO */
      }
    }

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  const handleAddYouTubeVideoUrl = React.useCallback(
    (newUrl: string) => {
      onChange([...fileUrls, newUrl]);
    },
    [fileUrls, onChange]
  );
  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <ul className={styles['page-productsRegist__picture']}>
        {fileUrls.map((imageVideoUrl, idx) => (
          <li
            key={`${imageVideoUrl}-idx`}
            className={styles['page-productsRegist__picture__item']}
          >
            <DraggablePreviewImage
              imageVideoUrl={imageVideoUrl}
              index={idx}
              onRemove={() =>
                onChange(fileUrls.filter((i) => i !== imageVideoUrl))
              }
              onMoveImage={handleMoveImage}
            />
          </li>
        ))}

        {(!maxFileCount || fileUrls.length < maxFileCount) && (
          <li className={styles['page-productsRegist__picture__item']}>
            <a
              {...getRootProps()}
              className={clsx(
                styles['page-productsRegist__picture__item__add'],
                isDragActive && styles['dragActive']
              )}
              onClick={handleAddImageClick}
            ></a>
            <input
              {...getInputProps()}
              multiple
              ref={imageFileInputRef}
              id="image-file-input"
              type="file"
              name="name"
              accept={acceptedFileTypes.join(',')}
              style={{
                display: 'none',
              }}
              onChange={handleFileInputChange}
            />
          </li>
        )}
      </ul>
      {uploadError && (
        <p className={baseStyles['base-form-box__err']}>{uploadError}</p>
      )}
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}

      {!fileTypes && (
        <p>
          {enableSvgFormat ? t('* jpg/png/svg format') : t('* jpg/png format')}
        </p>
      )}
      {maxFileCount !== 1 && <p>{t('* drag-and-drop to reorder')}</p>}

      {!disableYoutubeVideos && (
        <>
          <Button
            size="middle"
            style="gray"
            onClick={() => setShowYouTubeVideoEditorModal(true)}
          >
            {t('Add YouTube Video')}
          </Button>
          {showYouTubeVideoEditorModal && (
            <YouTubeVideoEditorModal
              onClose={() => setShowYouTubeVideoEditorModal(false)}
              onAddUrl={(url: string) => {
                handleAddYouTubeVideoUrl(url);
                setShowYouTubeVideoEditorModal(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
