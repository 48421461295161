import { combineReducers } from 'redux';

import {
  GET_FARES_REQUEST,
  GET_FARES_SUCCESS,
  GET_FARES_FAILURE,
  GET_REBOOK_FROM_RESERVATION_FARES_REQUEST,
  GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS,
  GET_REBOOK_FROM_RESERVATION_FARES_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReservationBillingInfo } from 'shared/models/swagger';

// Reducers
const error = (state = '', action: any) => {
  switch (action.type) {
    case GET_FARES_FAILURE:
    case GET_REBOOK_FROM_RESERVATION_FARES_FAILURE:
      return action.error;

    case GET_REBOOK_FROM_RESERVATION_FARES_REQUEST:
    case GET_FARES_REQUEST:
    case GET_FARES_SUCCESS:
    case GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case GET_FARES_REQUEST:
    case GET_REBOOK_FROM_RESERVATION_FARES_REQUEST:
      return true;

    case GET_FARES_SUCCESS:
    case GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS:
    case GET_FARES_FAILURE:
    case GET_REBOOK_FROM_RESERVATION_FARES_FAILURE:
      return false;

    default:
      return state;
  }
};

const billingInfo = (
  state: ReservationBillingInfo | null = null,
  action: any
) => {
  switch (action.type) {
    case GET_FARES_SUCCESS:
      return action.response;

    case GET_FARES_FAILURE:
      return null;

    default:
      return state;
  }
};

const rebookFromReservationBillingInfo = (
  state: ReservationBillingInfo | null = null,
  action: any
) => {
  switch (action.type) {
    case GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS:
      return action.response;

    case GET_REBOOK_FROM_RESERVATION_FARES_FAILURE:
      return null;

    default:
      return state;
  }
};

interface FaresState {
  error: string;
  loading: boolean;
  billingInfo: ReservationBillingInfo | null;
  rebookFromReservationBillingInfo: ReservationBillingInfo | null;
}

export const fares = combineReducers<FaresState>({
  error,
  loading,
  billingInfo,
  rebookFromReservationBillingInfo,
});
