import _ from 'lodash';
import { Section, SectionView, SourceLanguage } from '@nutmeglabs/falcon-ui';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { AddSectionButton } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/AddSectionButton';
import { RemoveSectionButton } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/RemoveSectionButton';
import { EditSection } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/EditSection';
import { getOrderedProductIds } from 'client/libraries/util/getOrderedProductIds';
import styles from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/TemplateContent.module.css';
import { MoveSectionUpButton } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/MoveSectionUpButton/MoveSectionUpButton';
import { MoveSectionDownButton } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/TemplateContent/MoveSectionDownButton/MoveSectionDownButton';

interface Props {
  language: SourceLanguage;
}

export const CustomSectionsEditor = ({ language }: Props) => {
  const form = useForm();
  const summaries = useSelector(bookingWidgetProductSummariesSelector);
  const org = useSelector(activeUserOrganizationSelector);

  const sections = _.get(form.getState().values, 'sections') as Section[];
  const products = getOrderedProductIds(org, summaries, language).map((id) =>
    summaries.find((s) => s.id === id)
  );

  return (
    <>
      {sections?.map((section, index) => (
        <>
          <Box display="flex" justifyContent="center">
            <Box
              className={styles['opacity-hover']}
              position="absolute"
              zIndex={1000}
            >
              <AddSectionButton
                index={index}
                excludedSectionTypes={['PRODUCT_LIST']}
              />
            </Box>
            <Box
              mt={12}
              position="absolute"
              zIndex={1000}
              display="flex"
              alignItems="center"
            >
              {index > 0 && (
                <Box className={styles['opacity-hover']} mr={2}>
                  <MoveSectionUpButton index={index} />
                </Box>
              )}
              <div className={styles['opacity-hover']}>
                <RemoveSectionButton index={index} />
              </div>
              <Box className={styles['opacity-hover']} ml={2}>
                <EditSection name={`sections.${index}`} />
              </Box>

              {index < sections.length - 1 && (
                <Box className={styles['opacity-hover']} ml={2}>
                  <MoveSectionDownButton index={index} />
                </Box>
              )}
            </Box>
          </Box>

          <SectionView data={section} products={products as any} />
        </>
      ))}
      <Box
        className={styles['opacity-hover']}
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <Box position="absolute" top={-36} zIndex={1000}>
          <AddSectionButton
            index={sections?.length ?? 0}
            excludedSectionTypes={['PRODUCT_LIST']}
          />
        </Box>
      </Box>
    </>
  );
};
