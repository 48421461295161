import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentsSelector } from 'client/reducers/equipments';
import { bulkMoveEquipmentAssignments } from 'client/actions/equipmentAssignments';
import type { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';

interface Props {
  fromEquipmentInstanceId: string;
  open: boolean;
  onClose: () => void;
}

export const SeatReAssignModal = ({
  fromEquipmentInstanceId,
  open,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const loading = useSelector(
    (state: ReduxState) => state.equipmentAssignments.loading
  );
  const error = useSelector(
    (state: ReduxState) => state.equipmentAssignments.error
  );
  const [toEquipmentInstanceId, setToEquipmentInstanceId] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const equipmentInstanceOptions = equipmentInstances
    .filter((equipmentInstance) => {
      return equipmentInstance.id !== fromEquipmentInstanceId;
    })
    .map((equipmentInstance) => {
      const equipment = equipments.find(
        (equipment) => equipment.id === equipmentInstance.equipment_id
      );
      return {
        value: equipmentInstance.id || '',
        text: equipment?.title || '',
      };
    });

  React.useEffect(() => {
    if (equipmentInstanceOptions.length > 0) {
      setToEquipmentInstanceId(equipmentInstanceOptions[0].value);
    }
  }, [equipmentInstanceOptions]);

  const handleSubmit = async () => {
    const toEquipmentInstance = equipmentInstances.find(
      (equipmentInstance) => equipmentInstance.id === toEquipmentInstanceId
    );

    if (!toEquipmentInstance) {
      return;
    }

    try {
      setSuccess(false);
      await dispatch(
        bulkMoveEquipmentAssignments({
          from_equipment_instance_id: fromEquipmentInstanceId,
          to_equipment_instance_identifier: {
            supplier_id: toEquipmentInstance.supplier_id,
            equipment_id: toEquipmentInstance.equipment_id,
            equipment_instance_id: toEquipmentInstance.id,
            date: toEquipmentInstance.date,
            start_time_key: toEquipmentInstance.start_time_key,
            original_equipment_instance_id:
              toEquipmentInstance.original_equipment_instance_id,
          },
        })
      );
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal title="Re-Assign Seat" open={open} onClose={onClose}>
      <Modal.Content>
        <Modal.Box>
          <Select
            label={t('Select a resource')}
            search
            value={toEquipmentInstanceId}
            options={equipmentInstanceOptions}
            onChange={(_, { value }) => {
              setToEquipmentInstanceId(value);
            }}
          />
        </Modal.Box>
        <div>
          {success && <Message success header={t('Move Successful')} />}
          {error && <Message error header={t('Move Failed')} />}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button
          loading={loading}
          size="middle"
          style="blue"
          onClick={handleSubmit}
        >
          {t('Move')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
