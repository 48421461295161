import clsx from 'clsx';
import {
  CSSProperties,
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import baseStyles from 'client/v3-base.module.css';

import styles from './TextField.module.css';

type TextFieldProps = {
  label?: string;
  name?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
  tooltipText?: string;
  required?: boolean;
  // Note: error and helperText should behave as follows to separate error state amd the helper text we want to show
  // https://mui.com/material-ui/react-text-field/#validation
  // TODO: make error boolean
  error?: string | boolean;
  helperText?: string;
};

export const TextField = ({
  label,
  name,
  type = 'text',
  value,
  defaultValue,
  onChange,
  placeholder,
  disabled,
  style,
  tooltipText,
  required,
  error,
  helperText,
  ...inputProps
}: TextFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      // Check if the target element is outside of the wrapper element
      if (target instanceof Node && !inputRef?.current?.contains(target)) {
        setIsClicked(false);
      }
    };

    // Add event listeners to document for click outside
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Focus cursor to input whenever fieldset is clicked
  useEffect(() => {
    if (isClicked && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isClicked]);

  return (
    <div className={styles['c-fieldWrapper']}>
      <fieldset
        className={clsx(
          styles['c-field'],
          error && styles['is-error'],
          isHovered && styles['is-hover'],
          isClicked && styles['is-active'],
          (isClicked || value) && styles['is-input'],
          disabled && styles['is-disabled']
        )}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsClicked(true)}
      >
        <div className={styles['c-field__body']}>
          <label>
            {label && (
              <legend className={styles['c-field__body__title']}>
                {label}
                {tooltipText && (
                  <Tooltip text={tooltipText}>
                    <i className="c-icon-outline-general-info-circle"></i>
                  </Tooltip>
                )}
              </legend>
            )}

            <div className={styles['c-field__body__selected']}>
              <input
                name={name}
                ref={inputRef}
                className={styles['c-field__body__selected__input']}
                type={type}
                value={value}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                style={style}
                {...inputProps}
              />
            </div>
          </label>
        </div>
      </fieldset>
      {error ? (
        <p className={baseStyles['u-error-msg']}>{helperText || error}</p>
      ) : (
        <p className={baseStyles['u-info-msg']}>{helperText}</p>
      )}
    </div>
  );
};
