import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_INSTANT_WIN_EVENTS_SUCCESS,
  FETCH_INSTANT_WIN_EVENTS_FAILURE,
  FETCH_INSTANT_WIN_EVENTS_REQUEST,
  FETCH_INSTANT_WIN_EVENT_SUCCESS,
  FETCH_INSTANT_WIN_EVENT_FAILURE,
  FETCH_INSTANT_WIN_EVENT_REQUEST,
  DELETE_INSTANT_WIN_EVENT_FAILURE,
  DELETE_INSTANT_WIN_EVENT_REQUEST,
  DELETE_INSTANT_WIN_EVENT_SUCCESS,
  CREATE_INSTANT_WIN_EVENT_REQUEST,
  UPDATE_INSTANT_WIN_EVENT_FAILURE,
  UPDATE_INSTANT_WIN_EVENT_SUCCESS,
  UPDATE_INSTANT_WIN_EVENT_REQUEST,
  CREATE_INSTANT_WIN_EVENT_FAILURE,
  CREATE_INSTANT_WIN_EVENT_SUCCESS,
  UPDATE_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  InstantWinEventParams,
  ListInstantWinEventsRequest,
  ListInstantWinEventsResponse,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchInstantWinEventsRequest = () => ({
  type: FETCH_INSTANT_WIN_EVENTS_REQUEST,
});

const fetchInstantWinEventsSuccess = (
  response: ListInstantWinEventsResponse,
  pageTokens: any
) => ({
  type: FETCH_INSTANT_WIN_EVENTS_SUCCESS,
  response,
  pageTokens,
});

const fetchInstantWinEventsFailure = (error: any) => ({
  type: FETCH_INSTANT_WIN_EVENTS_FAILURE,
  error,
});

let fetchInstantWinEventsCancel: () => void | undefined;
export const fetchInstantWinEvents =
  (req: ListInstantWinEventsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchInstantWinEventsCancel?.();
    dispatch(fetchInstantWinEventsRequest());
    axios
      .get('/api/instantwinevents', {
        params: {
          ...req,
          page_token: getState().instantWinEvents.pageTokens.currToken,
          page_size:
            getState().instantWinEventTableControls.rowCount.toString(),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchInstantWinEventsCancel = c;
        }),
      })
      .then((result) => {
        const data = result.data;
        const pageTokens = getState().instantWinEvents.pageTokens;

        if (data.next_page_token) {
          if (pageTokens.allTokens[pageTokens.currToken].next === null) {
            pageTokens.indexToToken.push(data.next_page_token);
          }

          pageTokens.allTokens[pageTokens.currToken].next =
            data.next_page_token;
        }
        dispatch(fetchInstantWinEventsSuccess(data, pageTokens));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchInstantWinEventsFailure('canceled'));
        dispatch(fetchInstantWinEventsFailure(error));
      });
  };

export const updateInstantWinEventListPageTokens = (
  pageTokens: Record<string, any>
) => ({
  type: UPDATE_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN,
  pageTokens,
});

const fetchInstantWinEventRequest = () => ({
  type: FETCH_INSTANT_WIN_EVENT_REQUEST,
});

const fetchInstantWinEventSuccess = (response: any) => ({
  type: FETCH_INSTANT_WIN_EVENT_SUCCESS,
  response,
});

const fetchInstantWinEventFailure = (error: any) => ({
  type: FETCH_INSTANT_WIN_EVENT_FAILURE,
  error,
});

export const fetchInstantWinEvent =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchInstantWinEventRequest());
    axios
      .get(`/api/instantwinevents/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchInstantWinEventSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchInstantWinEventFailure('canceled'));
        else dispatch(fetchInstantWinEventFailure(error));
      });
  };

const deleteInstantWinEventRequest = (id: string) => ({
  type: DELETE_INSTANT_WIN_EVENT_REQUEST,
  id,
});

const deleteInstantWinEventSuccess = (response: any, id: string) => ({
  type: DELETE_INSTANT_WIN_EVENT_SUCCESS,
  response,
  id,
});

const deleteInstantWinEventFailure = (error: any) => ({
  type: DELETE_INSTANT_WIN_EVENT_FAILURE,
  error,
});

export const deleteInstantWinEvent =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteInstantWinEventRequest(id));
    axios
      .delete(`/api/instantwinevents/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteInstantWinEventSuccess(response.data, id));
      })
      .catch((error) => dispatch(deleteInstantWinEventFailure(error)));
  };

const createInstantWinEventRequest = (
  newInstantWinEvent: InstantWinEventParams
) => ({
  type: CREATE_INSTANT_WIN_EVENT_REQUEST,
  newInstantWinEvent,
});

const createInstantWinEventSuccess = (response: any) => ({
  type: CREATE_INSTANT_WIN_EVENT_SUCCESS,
  response,
});

const createInstantWinEventFailure = (error: any) => ({
  type: CREATE_INSTANT_WIN_EVENT_FAILURE,
  error,
});

export const createInstantWinEvent =
  (newInstantWinEvent: InstantWinEventParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createInstantWinEventRequest(newInstantWinEvent));
    return axios
      .post('/api/instantwinevents', newInstantWinEvent, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createInstantWinEventSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createInstantWinEventFailure(err.message));
      });
  };

const updateInstantWinEventRequest = (
  id: string,
  patch: InstantWinEventParams
) => ({
  type: UPDATE_INSTANT_WIN_EVENT_REQUEST,
  id,
  patch,
});

const updateInstantWinEventSuccess = (response: any) => ({
  type: UPDATE_INSTANT_WIN_EVENT_SUCCESS,
  response,
});

const updateInstantWinEventFailure = (error: any) => ({
  type: UPDATE_INSTANT_WIN_EVENT_FAILURE,
  error,
});

export const updateInstantWinEvent =
  (id: string, patch: InstantWinEventParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateInstantWinEventRequest(id, patch));
    return axios
      .patch(`/api/instantwinevents/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateInstantWinEventSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateInstantWinEventFailure(err.message));
      });
  };
