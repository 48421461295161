import * as React from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { Button, Select } from 'client/components/Form';
import { getLanguageName } from 'client/libraries/i18n';
import { SourceLanguage } from 'shared/models/swagger';

import { deleteTopPageSection } from '../util';
import { getCustomSectionFormValues } from '../FormValues';
import type { CustomProductList, TopPageFormValues } from '../FormValues';

import { EditCustomProductListSectionModal } from './EditCustomProductListSectionModal';
import { DraggableTopPageSection } from './DraggableTopPageSection';

export const TopPageSectionsEditor = () => {
  const { t } = useTranslation();
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const dispatch = useDispatch();
  const [showCustomProductListModal, setShowCustomProductListModal] =
    React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));
  const sectionLanguage = formState.values?.sectionLanguage || 'JA_JP';
  return (
    <div>
      <Box
        ml={-1}
        mb={2}
        flexWrap="wrap"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box ml={1} fontWeight="bold">
          {t('Content Language')}
        </Box>
        <Box ml={1}>
          <Field name="sectionLanguage">
            {({ input }) => (
              <Select
                maxWidth={200}
                options={languageOptions}
                value={input.value}
                onChange={(e, { value }) => {
                  form.change(
                    'customSections',
                    getCustomSectionFormValues(
                      activeUserOrganization,
                      value as SourceLanguage
                    )
                  );
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </Box>
      </Box>
      <Box>
        <FieldArray name="customSections">
          {({ fields }) => (
            <>
              {fields.map((name, idx) => (
                <DraggableTopPageSection
                  key={fields.value?.[idx].key}
                  name={name}
                  index={idx}
                  deleteItem={async () => {
                    await dispatch(
                      deleteTopPageSection(
                        fields.value[idx].key,
                        sectionLanguage
                      )
                    );
                    fields.remove(idx);
                  }}
                  moveItem={(dragIndex: number, hoverIndex: number) => {
                    fields.move(dragIndex, hoverIndex);
                  }}
                />
              ))}
              {showCustomProductListModal && (
                <EditCustomProductListSectionModal
                  name=""
                  open={showCustomProductListModal}
                  onClose={() => setShowCustomProductListModal(false)}
                  onSave={(values: CustomProductList) => fields.push(values)}
                />
              )}
            </>
          )}
        </FieldArray>
      </Box>
      <Button
        style="green"
        size="middle"
        onClick={() => setShowCustomProductListModal(true)}
      >
        {t('Add Custom Product List')}
      </Button>
    </div>
  );
};
