import {
  SET_AVAILABILITY_START_DATE,
  SET_AVAILABILITY_END_DATE,
  SET_AVAILABILITY_CURRENT_PAGE,
  SET_AVAILABILITY_PAGE_SIZE,
  SET_AVAILABILITY_TAG_FILTER,
  SET_WEEKLY_AVAILABILITY_START_DATE,
  SET_MONTHLY_AVAILABILITY_SELECTED_MONTH,
  SET_CUSTOM_RANGE_AVAILABILITY_START_DATE,
  SET_CUSTOM_RANGE_AVAILABILITY_END_DATE,
  SET_AVAILABILITY_SALES_STATUS_FILTER,
  SET_AVAILABILITY_PRODUCT_FILTER,
  SET_AVAILABILITY_DISPATCH_CREW_FILTER,
  SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER,
  SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER,
  SET_AVAILABILITY_RESERVATION_SLOT_VISIBILITY_SETTING,
  SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING,
} from 'client/constants/ActionTypes';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import {
  VisibilityStateByReservationSlot,
  VisibilityStateByResource,
} from 'client/libraries/util/visibilitySettings';

export const setAvailabilityStartDate = (payload: string) => ({
  type: SET_AVAILABILITY_START_DATE,
  payload,
});
export const setAvailabilityEndDate = (payload: string) => ({
  type: SET_AVAILABILITY_END_DATE,
  payload,
});
export const setWeeklyAvailabilityStartDate = (payload: string) => ({
  type: SET_WEEKLY_AVAILABILITY_START_DATE,
  payload,
});
export const setMonthlyAvailabilitySelectedMonth = (payload: string) => ({
  type: SET_MONTHLY_AVAILABILITY_SELECTED_MONTH,
  payload,
});
export const setCustomRangeAvailabilityStartDate = (payload: string) => ({
  type: SET_CUSTOM_RANGE_AVAILABILITY_START_DATE,
  payload,
});
export const setCustomRangeAvailabilityEndDate = (payload: string) => ({
  type: SET_CUSTOM_RANGE_AVAILABILITY_END_DATE,
  payload,
});
export const setProductCalendarListCurrentPage = (page: number) => ({
  type: SET_AVAILABILITY_CURRENT_PAGE,
  page,
});
export const setProductCalendarListPageSize = (pageSize: number) => ({
  type: SET_AVAILABILITY_PAGE_SIZE,
  pageSize,
});
export const setProductCalendarListProductFilter = (
  productFilter: string[]
) => ({
  type: SET_AVAILABILITY_PRODUCT_FILTER,
  productFilter,
});
export const setProductCalendarListTagFilter = (
  productTagFilter: string[]
) => ({
  type: SET_AVAILABILITY_TAG_FILTER,
  productTagFilter,
});
export const setProductCalendarListSalesStatusFilter = (
  productSalesStatusFilter: SalesStatus[]
) => ({
  type: SET_AVAILABILITY_SALES_STATUS_FILTER,
  productSalesStatusFilter,
});
export const setDispatchCrewFilter = (dispatchCrewFilter: string[]) => ({
  type: SET_AVAILABILITY_DISPATCH_CREW_FILTER,
  dispatchCrewFilter,
});
export const setDispatchVehicleFilter = (dispatchVehicleFilter: string[]) => ({
  type: SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER,
  dispatchVehicleFilter,
});
export const setDispatchMiscResourceFilter = (
  dispatchMiscResourceFilter: string[]
) => ({
  type: SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER,
  dispatchMiscResourceFilter,
});
export const setProductCalendarListReservationSlotVisibilitySetting = (
  visibilitySetting: VisibilityStateByReservationSlot
) => ({
  type: SET_AVAILABILITY_RESERVATION_SLOT_VISIBILITY_SETTING,
  visibilitySetting,
});
export const setProductCalendarListResourceVisibilitySetting = (
  visibilitySetting: VisibilityStateByResource[]
) => ({
  type: SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING,
  visibilitySetting,
});
