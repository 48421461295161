import * as React from 'react';

import { Section } from './util';

type Props = {
  data: Section;
  children: JSX.Element | JSX.Element[];
};

export const BaseSection = ({ data, children }: Props) => {
  return (
    <>
      {/* Workaround for Gmail ignoring background color & gradient
      Inspired from https://github.com/resend/react-email/issues/662 */}
      {data.backgroundStyle === 'SINGLE_COLOR' && (
        <head>
          <style>
            {`
              table.single-color {
                background-color: ${data.backgroundColor};
              }
            }
          `}
          </style>
        </head>
      )}
      {data.backgroundStyle === 'GRADATION' && (
        <head>
          <style>
            {`
              table.gradient {
                background-image: ${data.backgroundGradient};
              }
          `}
          </style>
        </head>
      )}
      <table
        width="100%"
        style={{
          padding: '20px',
          backgroundColor:
            data.backgroundStyle === 'SINGLE_COLOR'
              ? data.backgroundColor
              : undefined,
          backgroundImage:
            data.backgroundStyle === 'GRADATION'
              ? data.backgroundGradient
              : data.backgroundStyle === 'IMAGE'
              ? `url(${data.backgroundImageUrl})`
              : undefined,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className={
          data.backgroundStyle === 'SINGLE_COLOR'
            ? 'single-color'
            : data.backgroundStyle === 'GRADATION'
            ? 'gradient'
            : ''
        }
      >
        <tbody>{children}</tbody>
      </table>
    </>
  );
};
