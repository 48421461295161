import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { fetchETicketDailyUsesCSV } from 'client/actions/eTicketDailyUses';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Radio } from 'client/components/Form';
import type { ETicketDailyUsesExportParams } from 'shared/models/swagger';
import styles from 'client/base.module.css';

type Props = {
  downloadParams: ETicketDailyUsesExportParams;
};

export const ETicketDailyUsesCSVDownloadModal = ({ downloadParams }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [CSVFormat, setCSVFormat] =
    React.useState<ETicketDailyUsesExportParams['csv_format']>('SUMMARY');
  const [showSummaryDetail, setShowSummaryDetail] =
    React.useState<boolean>(false);

  const fileNameDate = moment
    .tz(
      downloadParams.checkin_info_date_time_utc_from,
      downloadParams.timezone ?? 'UTC'
    )
    .format('YYYYMMDD');
  const fileName = `ticket-uses-${fileNameDate}-${(
    CSVFormat as string
  ).toLowerCase()}.csv`;

  return (
    <Modal
      title={t('Show columns')}
      trigger={
        <Button size={'middle'} style={'gray'}>
          {t('Download as CSV')}
        </Button>
      }
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Radio
          label={t('Summary data')}
          checked={CSVFormat === 'SUMMARY'}
          onChange={() => {
            setCSVFormat('SUMMARY');
          }}
        />
        {CSVFormat === 'SUMMARY' && (
          <div className={styles['base-form-box']}>
            <div className={styles['base-form-box__body']}>
              <div
                className={clsx(styles['base-form-toggle'])}
                style={{ margin: '8px' }}
              >
                <label>
                  <input
                    onChange={() => {
                      setShowSummaryDetail(!showSummaryDetail);
                    }}
                    type="checkbox"
                    name={'showSummaryDetail'}
                    checked={showSummaryDetail}
                  />
                  <p></p>
                </label>
                {t(
                  'Include breakdown records (Ticket Usage, Ticket Usage Option)'
                )}
              </div>
            </div>
          </div>
        )}
        <Radio
          label={t('Ticket redemption list')}
          checked={CSVFormat === 'LIST'}
          onChange={() => {
            setCSVFormat('LIST');
            setShowSummaryDetail(false);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button
          type="submit"
          style="blue"
          size="middle"
          onClick={() => {
            dispatch(
              fetchETicketDailyUsesCSV({
                ...downloadParams,
                csv_format: CSVFormat,
                file_name: fileName,
                is_summary_shown_detail: showSummaryDetail,
              })
            );
            setShowModal(false);
          }}
        >
          {t('Download')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
