// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import type { Dispatch } from 'redux';

import { updateDispatchCrewMembers } from 'client/actions/dispatchSettings';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import type { DispatchCrewMember } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type OwnProps = {
  crewMemberToDelete: {
    ...DispatchCrewMember,
  },
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};

/* eslint-enable no-use-before-define */
const DeleteCrewMemberModalComponent = ({
  allCrewMembers,
  crewMemberToDelete,
  updateCrewMembers,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return crewMemberToDelete ? (
    <Modal
      title={t('Delete Crew Member')}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: crewMemberToDelete.key,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>

        <Button.Delete
          onClick={() => {
            const newCrewMembers = allCrewMembers.filter(
              (crewMember) => crewMember.key !== crewMemberToDelete.key
            );
            updateCrewMembers(newCrewMembers);
            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  allCrewMembers: allDispatchCrewMembersSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateCrewMembers: (crewMembers: DispatchCrewMember[]) =>
    dispatch(updateDispatchCrewMembers(crewMembers)),
});

export const DeleteCrewMemberModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCrewMemberModalComponent);
