import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CHECK_AVAILABILITY_REQUEST,
  CHECK_AVAILABILITY_SUCCESS,
  CHECK_AVAILABILITY_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';

type Dispatch = ThunkDispatch<any, any, any>;

const checkAvailabilityRequest = (id: string) => ({
  type: CHECK_AVAILABILITY_REQUEST,
  id,
});

const checkAvailabilitySuccess = (response: any) => ({
  type: CHECK_AVAILABILITY_SUCCESS,
  response,
});

const checkAvailabilityFailure = (error: any) => ({
  type: CHECK_AVAILABILITY_FAILURE,
  error,
});

export const checkAvailability =
  (productInstanceId: string, guestTypeKeys: string[]) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(checkAvailabilityRequest(productInstanceId));

    try {
      const response = await axios.get(
        `/api/products/-/instances/${productInstanceId}`,
        {
          headers: getHTTPRequestHeaders(getState()),
          params: {
            guest_type_keys: guestTypeKeys,
          },
        }
      );
      dispatch(checkAvailabilitySuccess(response.data));
    } catch (err) {
      dispatch(checkAvailabilityFailure(err));
    }
  };
