import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './BaseGadget.module.css';

interface Props {
  header: string;
  tooltipText?: string;
  loading?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const BaseGadget = ({
  header,
  tooltipText,
  loading,
  children,
}: Props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{header}</div>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles['body']}>
          {loading ? <Loading size="md" /> : <>{children}</>}
        </div>
      </div>
    </div>
  );
};
