import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { mediaDownloadPricesSelector } from 'client/reducers/mediaDownloadPrices';
import { Button, FieldWrapper, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { getValidators } from 'shared/libraries/validate/validator';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

import { MediaItemInput } from './MediaItemInput/MediaItemInput';

type Props = {
  open: boolean;
  onClose: () => void;
  name: string;
};
export const EditMediaPackageModal = ({ open, onClose, name }: Props) => {
  const { t } = useTranslation();
  const prices = useSelector(mediaDownloadPricesSelector);
  const priceOptions = [
    ...prices
      .filter((price) => price.type === 'MEDIA_SET')
      .map((price) => ({
        key: price.id ?? '',
        value: price.id ?? '',
        text: `${price.label ?? ''} (${formattedCurrencyAmount(
          price.price ?? ''
        )})`,
      })),
  ];

  const { required } = getValidators(t);

  return (
    <Modal title={t('Edit Package')} open={open} onClose={onClose} insertRoot>
      <Modal.Content>
        <Box mb={2}>
          <Field name={`${name}.priceId`} validate={required}>
            {({ input }) => (
              <Select
                label={t('Price')}
                options={priceOptions}
                value={input.value}
                onChange={(e, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        </Box>
        <FieldWrapper label={t('Add Photos and Videos')}>
          <Field name={`${name}.mediaItems`}>
            {({ input }) => (
              <MediaItemInput
                hidePriceInput={true}
                mediaItems={input.value || []}
                onChange={(newValue) => input.onChange(newValue)}
              />
            )}
          </Field>
        </FieldWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} size="middle" style="blue" type="submit">
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
