import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { WaiverSettings$Patch } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateWaiverSettings =
  (patch: WaiverSettings$Patch) => (dispatch: Dispatch) => {
    return dispatch(
      updateActiveUserOrganization({
        waiver_settings: patch,
      })
    );
  };
