import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Checkbox } from 'client/components/Form';
import type { Channel, OrganizationSummary } from 'shared/models/swagger';

type Props = {
  channels: Channel[];
  onChange: (arg0: Channel[]) => void;
  allAgents: OrganizationSummary[];
};
export const ChannelListInput = ({ channels, onChange, allAgents }: Props) => {
  const { t } = useTranslation();
  const directOnlineChecked = channels.some(
    (channel) => channel.channel_category === 'DIRECT_ONLINE'
  );
  const directOfflineChecked = channels.some(
    (channel) => channel.channel_category === 'DIRECT_OFFLINE'
  );
  const allAgentsChecked = allAgents.every((agent) =>
    channels.some(
      (channel) =>
        channel.channel_category === 'AGENT' && channel.agent_id === agent.id
    )
  );
  return (
    <Modal.Box>
      <Checkbox
        label={t('Direct (Online)')}
        checked={directOnlineChecked}
        onChange={() =>
          onChange(
            directOnlineChecked
              ? channels.filter(
                  (channel) => channel.channel_category !== 'DIRECT_ONLINE'
                )
              : [
                  ...channels,
                  {
                    channel_category: 'DIRECT_ONLINE',
                  },
                ]
          )
        }
      />
      <Checkbox
        label={t('Direct (Offline)')}
        checked={directOfflineChecked}
        onChange={() =>
          onChange(
            directOfflineChecked
              ? channels.filter(
                  (channel) => channel.channel_category !== 'DIRECT_OFFLINE'
                )
              : [
                  ...channels,
                  {
                    channel_category: 'DIRECT_OFFLINE',
                  },
                ]
          )
        }
      />
      <Checkbox
        label={t('All Agents')}
        checked={allAgentsChecked}
        onChange={() => {
          let newChannels = channels.filter(
            (channel) => channel.channel_category !== 'AGENT'
          );

          if (!allAgentsChecked) {
            newChannels = [
              ...newChannels,
              ...allAgents.map((agent) => ({
                channel_category: 'AGENT',
                agent_id: agent.id || '',
              })),
            ] as any;
          }

          onChange(newChannels);
        }}
      />
      {allAgents.map((agent) => {
        const checked = channels.some(
          (channel) =>
            channel.channel_category === 'AGENT' &&
            channel.agent_id === agent.id
        );
        return (
          <Checkbox
            key={agent.id}
            label={agent.name || ''}
            checked={checked}
            onChange={() =>
              onChange(
                checked
                  ? channels.filter((channel) => channel.agent_id !== agent.id)
                  : [
                      ...channels,
                      {
                        channel_category: 'AGENT',
                        agent_id: agent.id,
                      },
                    ]
              )
            }
          />
        );
      })}
    </Modal.Box>
  );
};
