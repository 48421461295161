import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import { ReduxState } from 'client/reducers';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchPartnershipSystemFeeInvoicesRequest = () => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST,
});

const fetchPartnershipSystemFeeInvoicesSuccess = (response: any) => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS,
  response,
});

const fetchPartnershipSystemFeeInvoicesFailure = (error: any) => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE,
  error,
});

export const fetchPartnershipSystemFeeInvoices =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipSystemFeeInvoicesRequest());
    return axios
      .get('/api/partnershipsystemfeeinvoices', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(fetchPartnershipSystemFeeInvoicesSuccess(result.data))
      )
      .catch((error) =>
        dispatch(fetchPartnershipSystemFeeInvoicesFailure(error))
      );
  };

const fetchPartnershipSystemFeeInvoiceRequest = () => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST,
});

const fetchPartnershipSystemFeeInvoiceSuccess = (response: any) => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS,
  response,
});

const fetchPartnershipSystemFeeInvoiceFailure = (error: any) => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE,
  error,
});

export const fetchPartnershipSystemFeeInvoice =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipSystemFeeInvoiceRequest());
    return axios
      .get(`/api/partnershipsystemfeeinvoices/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(fetchPartnershipSystemFeeInvoiceSuccess(result.data))
      )
      .catch((error) =>
        dispatch(fetchPartnershipSystemFeeInvoiceFailure(error))
      );
  };

const fetchPartnershipSystemFeeInvoiceCSVRequest = () => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST,
});

const fetchPartnershipSystemFeeInvoiceCSVSuccess = () => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
});

const fetchPartnershipSystemFeeInvoiceCSVFailure = (error: string) => ({
  type: FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  error,
});

export const fetchPartnershipSystemFeeInvoiceCSV =
  (id: string, queryParams?: Record<string, any>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipSystemFeeInvoiceCSVRequest());
    axios
      .get(`/api/partnershipsystemfeeinvoices/${id}/csv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'reservations.csv');
        dispatch(fetchPartnershipSystemFeeInvoiceCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchPartnershipSystemFeeInvoiceCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };

const fetchPartnershipAgentSystemFeeInvoicesRequest = () => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
});

const fetchPartnershipAgentSystemFeeInvoicesSuccess = (response: any) => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
  response,
});

const fetchPartnershipAgentSystemFeeInvoicesFailure = (error: any) => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  error,
});

export const fetchPartnershipAgentSystemFeeInvoices =
  (useAlternateOrganization?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipAgentSystemFeeInvoicesRequest());
    return axios
      .get('/api/partnershipagentsystemfeeinvoices', {
        headers: getHTTPRequestHeaders(getState(), {
          useAlternateOrganization,
        }),
      })
      .then((result) =>
        dispatch(fetchPartnershipAgentSystemFeeInvoicesSuccess(result.data))
      )
      .catch((error) =>
        dispatch(fetchPartnershipAgentSystemFeeInvoicesFailure(error))
      );
  };

const fetchPartnershipAgentSystemFeeInvoiceCSVRequest = () => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST,
});

const fetchPartnershipAgentSystemFeeInvoiceCSVSuccess = () => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
});

const fetchPartnershipAgentSystemFeeInvoiceCSVFailure = (error: string) => ({
  type: FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  error,
});

export const fetchPartnershipAgentSystemFeeInvoiceCSV =
  (queryParams?: Record<string, any>, useAlternateOrganization?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchPartnershipAgentSystemFeeInvoiceCSVRequest());
    axios
      .get(`/api/partnershipagentsystemfeelineitems/csv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState(), {
          useAlternateOrganization,
        }),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'reservations.csv');
        dispatch(fetchPartnershipAgentSystemFeeInvoiceCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchPartnershipAgentSystemFeeInvoiceCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
