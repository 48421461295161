import moment from 'moment-timezone';

import type { AccessReportSettings } from 'client/libraries/util/accessReportSettings';

export const convertAccessReportSettingsToQueryParams = (
  req: AccessReportSettings
): Record<string, any> => {
  let start = '';
  let end = '';

  const ranges = [];

  if (req.dateFilterPreset !== 'CUSTOM') {
    let count = 0;
    const match = /^(\d+)_DAY.*$/.exec(req.dateFilterPreset || '');

    if (match && match.length > 0) {
      count = Number(match[1]);
    }

    start = moment().subtract(count, 'days').format('YYYY-MM-DD');
    end = moment().subtract(1, 'days').format('YYYY-MM-DD');
  } else {
    start = req.startDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    end = req.endDate || moment().format('YYYY-MM-DD');
  }

  ranges.push(`${start},${end}`);

  if (req.compare) {
    start =
      req.compareStartDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    end = req.compareEndDate || moment().format('YYYY-MM-DD');
    ranges.push(`${start},${end}`);
  }

  // to create array as the value of starts/end. When the length is 1, it is changed to normal string valuek (not array)
  return {
    unit: req.unit || 'DAY',
    date_ranges: ranges,
  };
};
