const freesaleSlotLimit = 9000000;

export const formattedTotalAllotmentSlots = (
  slots: number
): string | number => {
  if (slots >= freesaleSlotLimit) {
    return '∞';
  }

  return slots;
};
