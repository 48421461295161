import _ from 'lodash';
import { useForm } from 'react-final-form';
import { Section } from '@nutmeglabs/falcon-ui';

import { EditHighlightsButton } from './EditHighlightsButton/EditHighlightsButton';
import { EditMenuTilesButton } from './EditMenuTilesButton/EditMenuTilesButton';
import { EditCheckerboardTilesButton } from './EditCheckerboardTilesButton/EditCheckerboardTilesButton';
import { EditProductListButton } from './EditProductListButton';
import { EditIconTilesButton } from './EditIconTilesButton/EditIconTilesButton';
import { EditImageTilesButton } from './EditImageTilesButton/EditImageTilesButton';
import { EditImageGalleryButton } from './EditImageGalleryButton/EditImageGalleryButton';
import { EditEditorJsSectionModal } from './EditEditorJsSectionModal';

interface Props {
  name: string;
}

export const EditSection = ({ name }: Props) => {
  const form = useForm();
  const { values } = form.getState();
  const section = _.get(values, name) as Section;

  switch (section.type) {
    case 'HIGHLIGHTS':
      return <EditHighlightsButton name={name} />;
    case 'PRODUCT_LIST':
      return <EditProductListButton name={name} />;
    case 'MENU_TILES':
      return <EditMenuTilesButton name={name} />;
    case 'CHECKERBOARD_TILES':
      return <EditCheckerboardTilesButton name={name} />;
    case 'ICON_TILES':
      return <EditIconTilesButton name={name} />;
    case 'IMAGE_TILES':
      return <EditImageTilesButton name={name} />;
    case 'IMAGE_GALLERY':
      return <EditImageGalleryButton name={name} />;
    case 'EDITORJS':
      return <EditEditorJsSectionModal name={name} />;
    default:
      return null;
  }
};
