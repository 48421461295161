import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { selectedPrivateMarketplaceIndexSelector } from 'client/reducers/privateMarketplace';
import { SET_SELECTED_PRIVATE_MARKETPLACE_INDEX } from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type {
  PrivateMarketplace,
  ProductCollectionPage,
  RankingPage,
  RecommendedProductsPage,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const setSelectedPrivateMarketplaceIndex = (newIndex: number) => ({
  type: SET_SELECTED_PRIVATE_MARKETPLACE_INDEX,
  newIndex,
});

export const updatePrivateMarketplace =
  (patch: PrivateMarketplace) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const pmpIdx = selectedPrivateMarketplaceIndexSelector(getState());
    const organization = activeUserOrganizationSelector(getState());
    const privateMarketplaces = organization?.private_marketplaces ?? [];
    return dispatch(
      updateActiveUserOrganization({
        private_marketplaces: privateMarketplaces.map(
          (existingPrivateMarketplace, idx) =>
            pmpIdx === idx
              ? { ...existingPrivateMarketplace, ...patch }
              : existingPrivateMarketplace
        ),
      })
    );
  };

export const updateRankingPage = (rankingPage: RankingPage) =>
  updatePrivateMarketplace({
    ranking_page: rankingPage,
  });

export const updateRecommendedProductsPage = (
  recommendedProductPage: RecommendedProductsPage
) =>
  updatePrivateMarketplace({
    recommended_products_page: recommendedProductPage,
  });

export const updateCategoryPages = (categoryPages: ProductCollectionPage[]) =>
  updatePrivateMarketplace({
    category_pages: categoryPages,
  });

export const updateFeaturePages = (featurePages: ProductCollectionPage[]) =>
  updatePrivateMarketplace({
    feature_pages: featurePages,
  });
