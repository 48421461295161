import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import type {
  DateFilterPreset,
  AccessReportSettings,
} from 'client/libraries/util/accessReportSettings';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  DateRangeInput,
  Select,
  FieldWrapper,
  ToggleButton,
  Button,
} from 'client/components/Form';

import styles from './AccessReport.module.css';

export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return ['7_DAY', '14_DAYS', '28_DAYS', '30_DAYS', 'CUSTOM'].map((preset) => ({
    value: preset as DateFilterPreset,
    text: getDateFilterPresetText(preset as DateFilterPreset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  if (preset === 'CUSTOM') {
    return t('Custom');
  }

  const match = /^(\d+)_DAY.*$/.exec(preset);

  if (match && match.length > 0) {
    return t('Last {{count}} days', {
      count: match[1],
    });
  }

  return '';
};
type Props = {
  accessReportSettings: AccessReportSettings;
  setAccessReportSettings: (arg0: AccessReportSettings) => void;
  onSearch: (arg0: void) => void;
};
export const SettingForm = ({
  accessReportSettings,
  setAccessReportSettings,
  onSearch,
}: Props) => {
  const { t } = useTranslation();
  const dateFilterPresetOptions = [...getDateFilterPresetOptions(t)];

  const startDateHandler = (date: string) => {
    if (
      accessReportSettings.endDate &&
      moment(accessReportSettings.endDate).isBefore(moment(date))
    ) {
      setAccessReportSettings({
        ...accessReportSettings,
        startDate: accessReportSettings.endDate,
        endDate: date,
      });
    } else {
      setAccessReportSettings({ ...accessReportSettings, startDate: date });
    }
  };

  const endDateHandler = (date: string) => {
    if (
      accessReportSettings.startDate &&
      moment(accessReportSettings.startDate).isAfter(moment(date))
    ) {
      setAccessReportSettings({
        ...accessReportSettings,
        startDate: date,
        endDate: accessReportSettings.startDate,
      });
    } else {
      setAccessReportSettings({ ...accessReportSettings, endDate: date });
    }
  };

  const comparisonHandler = () => {
    setAccessReportSettings({
      ...accessReportSettings,
      compare: !accessReportSettings.compare,
    });
  };

  const compareStartDateHandler = (date: string) => {
    if (
      accessReportSettings.compareEndDate &&
      moment(accessReportSettings.compareEndDate).isBefore(moment(date))
    ) {
      setAccessReportSettings({
        ...accessReportSettings,
        compareStartDate: accessReportSettings.compareEndDate,
        compareEndDate: date,
      });
    } else {
      setAccessReportSettings({
        ...accessReportSettings,
        compareStartDate: date,
      });
    }
  };

  const compareEndDateHandler = (date: string) => {
    if (
      accessReportSettings.compareStartDate &&
      moment(accessReportSettings.compareStartDate).isAfter(moment(date))
    ) {
      setAccessReportSettings({
        ...accessReportSettings,
        compareStartDate: date,
        compareEndDate: accessReportSettings.compareStartDate,
      });
    } else {
      setAccessReportSettings({
        ...accessReportSettings,
        compareEndDate: date,
      });
    }
  };

  return (
    <div className={styles['condition-display-box']}>
      <b>{t('Report Setting')}</b>

      <div className={styles['setting-form__box']}>
        <div>
          <Select
            label={t('Date Range')}
            options={dateFilterPresetOptions}
            value={accessReportSettings.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setAccessReportSettings({
                ...accessReportSettings,
                dateFilterPreset: value as DateFilterPreset,
              });
            }}
          />
        </div>
        {accessReportSettings.dateFilterPreset === 'CUSTOM' && (
          <div>
            <DateRangeInput
              label={t('Duration {{duration}}', {
                duration: '1',
              })}
              fromDate={accessReportSettings.startDate}
              onChangeFromDate={startDateHandler}
              toDate={accessReportSettings.endDate}
              onChangeToDate={endDateHandler}
            />
          </div>
        )}
      </div>

      <div className={styles['setting-form__box']}>
        <div>
          <FieldWrapper label={t('Compare')}>
            <ToggleButton
              label=""
              checked={accessReportSettings.compare}
              onChange={() => {
                comparisonHandler();
              }}
            />
          </FieldWrapper>
        </div>
        {accessReportSettings.compare && (
          <div>
            <DateRangeInput
              label={t('Duration {{duration}}', {
                duration: '2',
              })}
              fromDate={accessReportSettings.compareStartDate}
              onChangeFromDate={compareStartDateHandler}
              toDate={accessReportSettings.compareEndDate}
              onChangeToDate={compareEndDateHandler}
            />
          </div>
        )}
      </div>

      <Button.Submit
        onClick={() => {
          onSearch();
        }}
      >
        {t('Apply')}
      </Button.Submit>
    </div>
  );
};
