import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { formatStartTime } from 'client/pages/ProductDetails/formatStartTime';
import { LocationList } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/LocationList/LocationList';
import type { ProductShape } from 'client/libraries/util/productShape';
import styles from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/ProductOutline.module.css';

type Props = {
  product: ProductShape;
};
export const DropoffCheckoutTab = ({ product }: Props) => {
  const { t } = useTranslation();
  const [selectedTimeSlotKey, setSelectedTimeSlotKey] = React.useState<string>(
    product.startTimes.length > 0 ? product.startTimes[0].timeSlotKey : ''
  );

  const handleStartTimeChange = (event: any) =>
    setSelectedTimeSlotKey(event.target.value);

  const showDropoffSection = product.dropoffLocations.length > 0;
  const showCheckoutSection = product.checkoutLocations.length > 0;
  const selectedStartTime = product.startTimes.find(
    (startTime) => startTime.timeSlotKey === selectedTimeSlotKey
  );
  return (
    <div className={styles['c-schedule']}>
      <label className={styles['c-schedule__select']}>
        <select
          name="startTime"
          value={selectedTimeSlotKey}
          onChange={handleStartTimeChange}
        >
          {product.startTimes.map((startTime) => (
            <option key={startTime.timeSlotKey} value={startTime.timeSlotKey}>
              {formatStartTime(startTime)}
            </option>
          ))}
        </select>
      </label>
      {showDropoffSection && (
        <>
          <p className={styles['c-schedule__headline']}>
            {t('Dropoff Locations / Times')}
          </p>
          <LocationList
            startTime={selectedStartTime}
            locations={product.dropoffLocations}
          />
        </>
      )}
      {showCheckoutSection && (
        <>
          <p className={styles['c-schedule__headline']}>
            {t('Checkout Times / Locations')}
          </p>
          <LocationList
            startTime={selectedStartTime}
            locations={product.checkoutLocations}
          />
        </>
      )}
    </div>
  );
};
