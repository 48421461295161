import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SALES_OFFER_STATS_SUCCESS,
  FETCH_SALES_OFFER_STATS_FAILURE,
  FETCH_SALES_OFFER_STATS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSalesOfferStatsRequest = () => ({
  type: FETCH_SALES_OFFER_STATS_REQUEST,
});

const fetchSalesOfferStatsSuccess = (response: any) => ({
  type: FETCH_SALES_OFFER_STATS_SUCCESS,
  response,
});

const fetchSalesOfferStatsFailure = (error: any) => ({
  type: FETCH_SALES_OFFER_STATS_FAILURE,
  error,
});

let fetchSaleStatsOffersCancel: () => void | undefined;
export const fetchSalesOfferStats =
  (dateFrom: string, dateTo: string, timezone: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchSaleStatsOffersCancel?.();
    dispatch(fetchSalesOfferStatsRequest());
    axios
      .get('/api/salesoffers/stats', {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          timezone,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchSaleStatsOffersCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchSalesOfferStatsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSalesOfferStatsFailure('canceled'));
        dispatch(fetchSalesOfferStatsFailure(error));
      });
  };
