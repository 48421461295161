// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import { Button, Form, Header, List, Modal } from 'semantic-ui-react';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { updateContract } from 'client/actions/contract';
import type { Contract, Contract$Patch } from 'shared/models/swagger';

type OwnProps = {
  contract: Contract,
  trigger: React.ComponentType<{}>,
  header?: string,
  info: any,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  contract: Contract,
};

const options = [
  { key: 'DIRECT', text: 'DIRECT', value: 'DIRECT' },
  { key: 'RESELL', text: 'RESELL', value: 'RESELL' },
];

class EditContractModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      contract: props.contract,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.contract !== this.props.contract) {
      this.setState({
        contract: this.props.contract,
      });
    }
  }

  updateContract = () => {
    this.props.updateContract(
      this.props.contract.id,
      (this.state.contract: any)
    );
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { trigger: Trigger, header, t, info } = this.props;

    const { contract } = this.state;

    return (
      <React.Fragment>
        <Modal
          eventPool="edit-contract-modal"
          trigger={
            <span
              onClick={() => {
                this.setState({
                  showModal: true,
                });
              }}
            >
              <Trigger />
            </span>
          }
          open={this.state.showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
        >
          <Header icon="edit" content={header} />
          <Modal.Content>
            <Modal.Description>
              <List>
                {contract.id && (
                  <List.Item>{t('ID') + `: ${contract.id}`}</List.Item>
                )}
                {info.agent_name && (
                  <List.Item>
                    <b>{t('Agent Name')}:</b> {info.agent_name}
                  </List.Item>
                )}
                {info.supplier_name && (
                  <List.Item>
                    <b>{t('Supplier Name')}:</b> {info.supplier_name}
                  </List.Item>
                )}
              </List>
              <Form>
                <Form.Select
                  fluid
                  label={t('Contract Type')}
                  options={options}
                  defaultValue={contract.type}
                  onChange={(e, { value }) =>
                    this.setState({
                      contract: {
                        ...contract,
                        type: value,
                      },
                    })
                  }
                  placeholder="Contract Type"
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  contract: this.props.contract,
                });
              }}
              disabled={this.state.contract === this.props.contract}
            >
              {t('Discard Changes')}
            </Button>
            <Button
              primary
              disabled={this.state.contract === this.props.contract}
              onClick={this.updateContract}
            >
              {t('Save')}
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateContract: (id: string, contract: Contract$Patch) =>
    dispatch(updateContract(id, contract)),
});

export const EditContractModal = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(EditContractModalComponent);
