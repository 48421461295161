import * as Swagger from 'shared/models/swagger';

export type ResourceType = '' | 'vehicle' | 'crew' | 'other' | 'guide';

export const convertToSwagger = (
  resourceType: ResourceType
): Swagger.ResourceType => {
  switch (resourceType) {
    case 'vehicle':
      return 'RESOURCE_TYPE_VEHICLE';
    case 'crew':
      return 'RESOURCE_TYPE_CREW';
    case 'other':
      return 'RESOURCE_TYPE_OTHER';
    case 'guide':
      return 'RESOURCE_TYPE_GUIDE';
    default:
      return 'RESOURCE_TYPE_OTHER';
  }
};
