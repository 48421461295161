import { combineReducers } from 'redux';

import { initialSettings } from 'client/libraries/util/reservationReportSettings';
import { SET_LAST_RESERVATION_REPORT_SETTINGS } from 'client/constants/ActionTypes';

const lastReservationReportSettings = (
  state = initialSettings,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_RESERVATION_REPORT_SETTINGS:
      return action.reservationReportSettings;

    default:
      return state;
  }
};

export const reservationReportSettings = combineReducers({
  lastReservationReportSettings,
});
