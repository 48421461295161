import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';
import componentStyles from 'client/components/components.module.css';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
  currencyCode: string | null;
};
export const CompareTablePane = ({ data, currencyCode }: Props) => {
  const { t } = useTranslation();

  const calcRatio = (base: number, compare: number): string => {
    return `${compare - base > 0 ? '+' : ''}${(
      ((compare - base) / base) *
      100
    ).toFixed(2)} %`;
  };

  if (data.length !== 2) {
    return (
      <div className={clsx(componentStyles['c-table-nowrap'])}>
        Invalid data
      </div>
    );
  }

  let ratioAmountGross: string | number = 0;
  let ratioAmountNet: string | number = 0;
  let ratioNumOfReservations: string | number = 0;
  let ratioPax: string | number = 0;

  if (data[0].amountGross && data[1].amountGross) {
    ratioAmountGross = calcRatio(
      Number(data[1].amountGross),
      Number(data[0].amountGross)
    );
  } else {
    ratioAmountNet = '-';
  }

  if (data[0].amountNet && data[1].amountNet) {
    ratioAmountNet = calcRatio(
      Number(data[1].amountNet),
      Number(data[0].amountNet)
    );
  } else {
    ratioAmountNet = '-';
  }

  if (data[0].pax && data[1].pax) {
    ratioNumOfReservations = calcRatio(
      Number(data[1].numOfReservations),
      Number(data[0].numOfReservations)
    );
  } else {
    ratioNumOfReservations = '-';
  }

  if (data[0].pax && data[1].pax) {
    ratioPax = calcRatio(Number(data[1].pax), Number(data[0].pax));
  } else {
    ratioPax = '-';
  }

  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Date')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Gross')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Net')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Number of Reservations')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Pax')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data[0].name}</td>
          <td style={{ textAlign: 'right' }}>
            {formattedAmount(data[0].amountGross, currencyCode)}
          </td>
          <td style={{ textAlign: 'right' }}>
            {formattedAmount(data[0].amountNet, currencyCode)}
          </td>
          <td style={{ textAlign: 'right' }}>{data[0].numOfReservations}</td>
          <td style={{ textAlign: 'right' }}>{data[0].pax}</td>
        </tr>
        <tr>
          <td>{data[1].name}</td>
          <td style={{ textAlign: 'right' }}>
            {formattedAmount(data[1].amountGross, currencyCode)}
          </td>
          <td style={{ textAlign: 'right' }}>
            {formattedAmount(data[1].amountNet, currencyCode)}
          </td>
          <td style={{ textAlign: 'right' }}>{data[1].numOfReservations}</td>
          <td style={{ textAlign: 'right' }}>{data[1].pax}</td>
        </tr>
        <tr>
          <td style={{ fontWeight: 'var(--text-semibold)' }}></td>
          <td
            style={{
              fontWeight: 'var(--text-semibold)',
              textAlign: 'right',
            }}
          >
            {ratioAmountGross}
          </td>
          <td
            style={{
              fontWeight: 'var(--text-semibold)',
              textAlign: 'right',
            }}
          >
            {ratioAmountNet}
          </td>
          <td
            style={{
              fontWeight: 'var(--text-semibold)',
              textAlign: 'right',
            }}
          >
            {ratioNumOfReservations}
          </td>
          <td
            style={{
              fontWeight: 'var(--text-semibold)',
              textAlign: 'right',
            }}
          >
            {ratioPax}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
