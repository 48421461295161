import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ListEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ListEditor';
import type { ReduxState } from 'client/reducers';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';

export const InternalTagsEditor = () => {
  const { t } = useTranslation();
  const internalTagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.internalTags
  );

  return (
    <ul className={styles['p-list']}>
      <ListEditor
        title={t('Tags')}
        name="internalTags"
        suggestions={internalTagSuggestions}
        addButtonText={t('Add Tag')}
      />
    </ul>
  );
};
