import * as React from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';

import styles from './LocationMap.module.css';

export interface Location {
  latitude: number;
  longitude: number;
  renderContent: () => React.ReactNode;
}

interface Props {
  locations: Location[];
}

export const LocationMap = ({ locations }: Props) => {
  const { isLoaded } = useGoogleMapsApi();

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [activeIdx, setActiveIdx] = React.useState<number | null>(null);

  const onLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (map && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const location of locations) {
        bounds.extend({ lat: location.latitude, lng: location.longitude });
      }

      map?.fitBounds(bounds);
    }
  }, [map, locations.length]);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerClassName={styles['map-container']}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {locations.map((location, idx) => (
            <Marker
              key={idx}
              position={{
                lat: location.latitude ?? 0,
                lng: location.longitude ?? 0,
              }}
              onClick={() => setActiveIdx(idx)}
            >
              {activeIdx === idx && (
                <InfoWindow onCloseClick={() => setActiveIdx(null)}>
                  {location.renderContent()}
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      ) : null}
    </>
  );
};
