import * as React from 'react';
import clsx from 'clsx';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import baseStyles from 'client/base.module.css';

import styles from './RadioButtonGroup.module.css';

type Props = {
  disabled?: boolean;
  options: {
    label: string;
    value: string;
    tooltipText?: string;
  }[];
  value: string;
  onChange: (newValue: string) => void;
  style?: Record<string, any>;
};
export const RadioButtonGroup = ({
  disabled,
  options,
  value,
  onChange,
  style,
}: Props) => {
  return (
    <ul className={clsx(baseStyles['base-flex'], styles['priceSelect'])}>
      {options.map((option, idx) => (
        <li key={idx}>
          <div className={baseStyles['base-form-radio']}>
            <label className={disabled ? baseStyles['disabled'] : undefined}>
              <input
                disabled={disabled}
                type="radio"
                checked={option.value === value}
                onChange={() => {
                  onChange(option.value);
                }}
                style={style}
              />
              <p></p>
              {option.label}
            </label>
          </div>
          {option.tooltipText && (
            <div className={styles['tooltip-box']}>
              <Tooltip text={option.tooltipText} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
