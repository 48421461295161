import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import * as Swagger from 'shared/models/swagger';

import {
  convertCheckinPickupFormValuesToProductPatch,
  getInitialCheckinPickupFormValues,
} from './CheckinPickupLocationsEditor/FormValues';
import {
  convertDetailInfoFormValuesToProductPatch,
  getInitialDetailInfoFormValues,
} from './DetailInfoEditor/FormValues';
import {
  convertHighlightsFormValuesToProductPatch,
  getInitialHighlightsFormValues,
} from './HighlightsEditor/FormValues';
import {
  convertItineraryFormValuesToProductPatch,
  getInitialItineraryFormValues,
} from './ItineraryEditor/FormValues';
import {
  convertMinimumParticipantFormValuesToProductPatch,
  getInitialMinimumParticipantFormValues,
} from './MinimumParticipantsEditor/FormValues';
import {
  convertReservationFormFormValuesToProductPatch,
  getInitialReservationFormFormValues,
} from './ReservationFormEditor/FormValues';
import {
  convertFormValueServicesToProductServices,
  getInitialServiceFormValues,
} from './ServicesEditor/FormValues';
import {
  convertTagFormValuesToProductPatch,
  getInitialTagFormValues,
} from './TagsEditor/FormValues';
import {
  convertTransportRouteFormValuesToProductPatch,
  getInitialTransportRouteFormValues,
} from './TransportRouteEditor/FormValues';
import {
  convertInternalTagFormValuesToProductPatch,
  getInitialInternalTagFormValues,
} from './InternalTagsEditor/FormValues';
import type { CheckinPickupFormValues } from './CheckinPickupLocationsEditor/FormValues';
import type { DetailInfoFormValues } from './DetailInfoEditor/FormValues';
import type { HighlightsFormValues } from './HighlightsEditor/FormValues';
import type { ItineraryFormValues } from './ItineraryEditor/FormValues';
import type { MinimumParticipantFormValues } from './MinimumParticipantsEditor/FormValues';
import type { ReservationFormFormValues } from './ReservationFormEditor/FormValues';
import type { Service } from './ServicesEditor/FormValues';
import type { TagFormValues } from './TagsEditor/FormValues';
import type { TransportRouteFormValues } from './TransportRouteEditor/FormValues';
import type { InternalTagFormValues } from './InternalTagsEditor/FormValues';

export type FormValues = CheckinPickupFormValues &
  DetailInfoFormValues &
  HighlightsFormValues &
  ItineraryFormValues &
  MinimumParticipantFormValues &
  ReservationFormFormValues &
  TagFormValues &
  TransportRouteFormValues &
  InternalTagFormValues & {
    addOns: Service[];
    transportations: Service[];
  };
export const getInitialValues = (
  product: Swagger.Product | null,
  defaultCurrency: string,
  defaultTimezone: string,
  t: TranslateFuncType,
  showNet: boolean
): FormValues => {
  return {
    ...getInitialCheckinPickupFormValues(product),
    ...getInitialDetailInfoFormValues(product),
    ...getInitialHighlightsFormValues(product),
    ...getInitialItineraryFormValues(product),
    ...getInitialMinimumParticipantFormValues(product),
    ...getInitialReservationFormFormValues(
      product,
      defaultCurrency,
      defaultTimezone,
      t
    ),
    addOns: getInitialServiceFormValues(product?.add_ons ?? [], showNet),
    transportations: getInitialServiceFormValues(
      product?.transportations ?? [],
      showNet
    ),
    ...getInitialTagFormValues(product),
    ...getInitialTransportRouteFormValues(product),
    ...getInitialInternalTagFormValues(product),
  };
};
export const convertFormValuesToProductPatch = (
  values: FormValues,
  previous: Swagger.Product | null,
  t: TranslateFuncType,
  defaultCurrency: string,
  showNet: boolean
): Swagger.Product$Patch => {
  const sourceLanguageUppercaseIso = previous?.source_language ?? 'JA_JP';
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];
  const patch: Swagger.Product$Patch = {
    ...convertCheckinPickupFormValuesToProductPatch(values),
    ...convertDetailInfoFormValuesToProductPatch(values),
    ...convertHighlightsFormValuesToProductPatch(values),
    ...convertItineraryFormValuesToProductPatch(values),
    // Note: as a side effect this fills in minimum participant info in product.pricing
    ...convertMinimumParticipantFormValuesToProductPatch(values, previous),
    ...convertReservationFormFormValuesToProductPatch(
      values,
      t,
      sourceLanguageLowercaseIso
    ),
    add_ons: convertFormValueServicesToProductServices(
      values.addOns,
      defaultCurrency,
      showNet
    ),
    transportations: convertFormValueServicesToProductServices(
      values.transportations,
      defaultCurrency,
      showNet
    ),
    ...convertTagFormValuesToProductPatch(values),
    ...convertTransportRouteFormValuesToProductPatch(values),
    ...convertInternalTagFormValuesToProductPatch(values),
  };

  // If we are setting transportations, then ensure that 'hotel_information' is also present in
  // reservation_form_fields
  if ((patch?.transportations || []).length > 0) {
    const reservationFormFields = (patch?.reservation_form_fields ?? []).filter(
      (f) => f.key !== 'hotel_information' && f.key !== 'hotel_tbd_form'
    );
    patch.reservation_form_fields = [
      ...reservationFormFields,
      {
        key: 'hotel_information',
        type: 'PER_BOOKING',
        prompt: t('Hotel Information', {
          lng: sourceLanguageLowercaseIso,
        }),
        format: '',
        required: 'WHEN_BOOKING',
      },
      {
        key: 'hotel_tbd_form',
        type: 'PER_BOOKING',
        prompt: t('Hotel TBD Form', {
          lng: sourceLanguageLowercaseIso,
        }),
        format: '',
        required: 'OPTIONAL',
      },
    ];
  }

  return patch;
};
