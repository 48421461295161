import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';

import styles from './CreateCSVFileButton.module.css';

type Props = {
  disabled: boolean;
  onClick: () => void;
  loading: boolean;
};
export const CreateCSVFileButton = ({ disabled, onClick, loading }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Button
        style="blue"
        size="middle"
        flex={true}
        disabled={disabled}
        onClick={onClick}
        loading={loading}
      >
        {t('Create CSV file')}
      </Button>
    </div>
  );
};
