import * as React from 'react';
import clsx from 'clsx';

import type { TemplateSupplierSettings } from 'shared/models/swagger';

import styles from './ProductSummaryCardView.module.css';

type Props = {
  template?: TemplateSupplierSettings;
  onClick: () => void;
};

export const ProductSummaryCardView = ({ template, onClick }: Props) => {
  return (
    <a onClick={() => onClick()} className={styles['c-card']}>
      <div className={styles['c-card__pic']}>
        <img src={`${template?.banner_image_url ?? ''}`} alt={'test'} />
      </div>
      <div className={styles['c-card__info']}>
        <h3 className={clsx(styles['c-card__info__ttl'], 'maincolor-txt')}>
          {template?.banner_title}
        </h3>
        <p className={styles['c-card__info__desc']}>
          {template?.banner_description}
        </p>
      </div>
    </a>
  );
};
