import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import {
  fetchProductByID,
  fetchSourceLanguageProductById,
} from 'client/actions/products';
import type { ReduxState } from 'client/reducers';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/v3-base.module.css';
import { PcSidebarMenu } from 'client/pages/v3/Product/ProductEdit/Menu/PcSidebarMenu';
import { Button } from 'client/components/v3/Common/Button';
import { SpMenu } from 'client/pages/v3/Product/ProductEdit/Menu/SpMenu';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { ProductCreateHeader } from 'client/pages/v3/Product/ProductCreate/ProductCreateHeader/ProductCreateHeader';
import { BasicInformationEditor } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/BasicInformationEditor';
import { ReservationParametersEditor } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/ReservationParametersEditor';
import { ReservationParamsFormValues } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { ProductHelperContext } from 'client/pages/v3/Product/ProductEdit/ProductHelperContext';
import { DetailInformationEditor } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/DetailInformationEditor';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { BasicFormValues } from 'client/pages/v3/Product/ProductEdit/types';

type Params = {
  id: string;
};

export const ProductCreate = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { id } = useParams<Params>();
  const location = useLocation();
  const [contentLanguage] = useState<string>(i18n.language);
  const activeUser = useSelector(activeUserSelector);
  const [editingProductId, setEditingProductId] = useState<string | null>(
    location.pathname.includes('copy') && id ? id : null
  );

  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [productCreationSuccess, setProductCreationSuccess] = useState<
    boolean | null
  >(null);

  const [reservationParamsFormValues, setReservationParamsFormValues] =
    useState<ReservationParamsFormValues | null>(null);
  const [basicFormValues, setBasicFormValues] =
    useState<BasicFormValues | null>(null);

  const editingProduct =
    useSelector(
      (state: ReduxState) => state.products.byID[editingProductId || '']
    ) ?? null;

  useEffect(() => {
    if (editingProductId) {
      dispatch(fetchSourceLanguageProductById(editingProductId));
    }
  }, [editingProductId]);

  // Refetch product upon successful product creation or from params during copy
  useEffect(() => {
    dispatch(fetchProductByID(editingProductId ?? '', contentLanguage));
  }, [editingProductId, contentLanguage, activeUser]);

  // Submit subsequent forms if the previous form submission is successful via document.getElementById
  useEffect(() => {
    if (editingProductId && editingProduct) {
      const formReservationParameters = window.document.getElementById(
        'createProductReservationParametersForm'
      );

      if (formReservationParameters && step === 2) {
        formReservationParameters.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }
  }, [editingProductId, editingProduct, step]);
  useEffect(() => {
    if (editingProductId && editingProduct) {
      const formDetailInformation = window.document.getElementById(
        'createProductDetailInformationForm'
      );

      if (formDetailInformation && step === 3) {
        formDetailInformation.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }
  }, [editingProductId, editingProduct, step]);

  const isFromCopy = location.pathname.includes('copy') && id ? true : false;

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;

  const hasPerParticipantPricing =
    editingProduct?.pricing == null ||
    editingProduct?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });

  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );
  const isPassthrough = Boolean(
    editingProduct?.shared_allotment_references?.passthrough_base_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;

  // Sections & permission to show for sidebar
  const sections = [
    {
      title: t('Basic Information'),
      id: 'basic',
      hasPermission: true,
      subsections: [
        {
          title: t('Products'),
          id: 'products',
          hasPermission: true,
        },
        {
          title: t('Prices'),
          id: 'prices',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Reservation Parameters'),
      id: 'reservationParameters',
      hasPermission: true,
      subsections: [
        {
          title: t('Availability Settings'),
          id: 'availabilitySettings',
          hasPermission: true,
        },
        {
          title: t('Times and Inventory'),
          id: 'timesAndInventory',
          hasPermission: true,
        },
        {
          title: t('Minimum Participants & Per-Unit Settings'),
          id: 'mininumParticipantsAndPerUnitSettings',
          hasPermission:
            showMinimumParticipants &&
            basicFormValues?.productType === 'NORMAL',
        },
        {
          title: t('Booking Period'),
          id: 'bookingPeriod',
          hasPermission: true,
        },
        {
          title: t('Cancellation Policies'),
          id: 'cancellationPolicies',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Detail Information'),
      id: 'detail',
      hasPermission: true,
      subsections: [
        {
          title: t('Highlights'),
          id: 'highlights',
          hasPermission: true,
        },
        {
          title: t('Detailed Info (shown on voucher)'),
          id: 'detailedInfoVoucher',
          hasPermission: true,
        },
        {
          title: t('Experience Itinerary'),
          id: 'experienceItinerary',
          hasPermission: true,
        },
        {
          title: t('Add-Ons'),
          id: 'addOns',
          hasPermission: true,
        },
        {
          title: t('Transportation'),
          id: 'transportation',
          hasPermission: true,
        },
        {
          title: t('Checkin/Pickup Locations'),
          id: 'checkinPickupLocations',
          hasPermission: true,
        },
        {
          title: t('Reservation Form'),
          id: 'reservationForm',
          hasPermission: true,
        },
        {
          title: t('Product Tags'),
          id: 'productTags',
          hasPermission: true,
        },
      ],
    },
    {
      title: t('Internal Management'),
      id: 'internalManagement',
      hasPermission: true,
      subsections: [
        {
          title: t('Transport Routes'),
          id: 'transportRoutes',
          hasPermission: true,
        },
        {
          title: t('Internal Use Product Tags'),
          id: 'internalProductTags',
          hasPermission: true,
        },
      ],
    },
  ];

  const [currentSectionId, setCurrentSectionId] = useState('products');

  const getProductIdOnCreate = (id: string) => {
    setEditingProductId(id);
  };

  // Submit the first form via document.getElementById()
  const handleButtonClick = async () => {
    setProductCreationSuccess(false);
    const formBasicInformation = window.document.getElementById(
      'createProductBasicInformationForm'
    );
    if (formBasicInformation) {
      formBasicInformation.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  // Redirect to product details page when product is created and all forms submission has completed
  if (productCreationSuccess && step === 3) {
    {
      /* TODO: remove v3 from url later */
    }
    return <Redirect to={`/products-v3/${editingProductId || ''}`} />;
    // return <Redirect to={`/products/${editingProductId || ''}`} />;
  }

  return (
    <V3Page>
      <EditingProductContext.Provider value={editingProduct ?? null}>
        <ProductHelperContext.Provider
          value={{
            basicFormValues,
            setBasicFormValues,
            reservationParamsFormValues,
            setReservationParamsFormValues,
          }}
        >
          <ProductCreateHeader />
          <div
            className={clsx(
              baseStyles['l-main__body'],
              styles['p-productEditor']
            )}
          >
            <div className={baseStyles['l-main__body__flex']}>
              <div className={baseStyles['l-main__body__flex__left']}>
                <SpMenu
                  sections={sections}
                  currentSectionId={currentSectionId}
                />
                <BasicInformationEditor
                  copy={isFromCopy}
                  sourceProductId={id}
                  onSubmitSuccess={async (productId: string) => {
                    await getProductIdOnCreate(productId);
                    await setStep(2);
                  }}
                  // Reset flag upon submission failure
                  onSubmitFailed={async () => setProductCreationSuccess(null)}
                  // Reset flag upon validation failure
                  onValidationError={() => setProductCreationSuccess(null)}
                  setCurrentSectionId={setCurrentSectionId}
                />
                <ReservationParametersEditor
                  onSubmitSuccess={() => setStep(3)}
                  // Reset flag upon submission failure
                  onSubmitFailed={async () => setProductCreationSuccess(null)}
                  setCurrentSectionId={setCurrentSectionId}
                />
                <DetailInformationEditor
                  onSubmitSuccess={async () => {
                    // Indicate that product creation is successful and stop button loading
                    setProductCreationSuccess(true);
                  }}
                  // Reset flag upon submission failure
                  onSubmitFailed={async () => setProductCreationSuccess(null)}
                  setCurrentSectionId={setCurrentSectionId}
                />
                {/* TODO: make fixed footer show up upon scroll */}
                <div
                  className={clsx(
                    styles['p-products__fixed'],
                    styles['is-active']
                  )}
                >
                  <div className={styles['p-products__fixed__main']}></div>
                  <div className={styles['p-products__fixed__actions']}>
                    {/* TODO: translation mode is scheduled to be implemented in upcoming sprint */}
                    {/* <div className={styles['p-productEditor__toggle']}>
                <div className={baseStyles['base-form-toggle']}>
                  <label>
                    <input
                      type="checkbox"
                      checked={showTranslationMode}
                      onClick={() =>
                        setShowTranslationMode(!showTranslationMode)
                      }
                    />
                    <p></p>
                  </label>
                  {t('Translation mode')}
                </div>
                {showTranslationMode && (
                  <Select
                    width={176}
                    options={languageOptions}
                    onChange={(e, { value }) => {
                      setTranslationTargetLanguage(value as any);
                    }}
                    value={translationTargetLanguage ?? ''}
                    optionStyle={
                      mql.matches
                        ? { bottom: '50px', right: '0' }
                        : { bottom: '50px', right: '-130px' }
                    }
                  />
                )}
              </div> */}
                    <Button
                      text={t('Save')}
                      type="submit"
                      color="primary"
                      size="md"
                      loading={
                        productCreationSuccess !== null &&
                        !productCreationSuccess
                      }
                      onClick={handleButtonClick}
                    />
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  baseStyles['l-main__body__flex__right'],
                  baseStyles['sticky']
                )}
              >
                <PcSidebarMenu
                  sections={sections}
                  currentSectionId={currentSectionId}
                />
              </div>
            </div>
          </div>
        </ProductHelperContext.Provider>
      </EditingProductContext.Provider>
    </V3Page>
  );
};
