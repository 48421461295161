import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_NEWSLETTER_SEGMENTS_SUCCESS,
  FETCH_NEWSLETTER_SEGMENTS_FAILURE,
  FETCH_NEWSLETTER_SEGMENTS_REQUEST,
  DELETE_NEWSLETTER_SEGMENT_FAILURE,
  DELETE_NEWSLETTER_SEGMENT_REQUEST,
  DELETE_NEWSLETTER_SEGMENT_SUCCESS,
  CREATE_NEWSLETTER_SEGMENT_REQUEST,
  UPDATE_NEWSLETTER_SEGMENT_FAILURE,
  UPDATE_NEWSLETTER_SEGMENT_SUCCESS,
  UPDATE_NEWSLETTER_SEGMENT_REQUEST,
  CREATE_NEWSLETTER_SEGMENT_FAILURE,
  CREATE_NEWSLETTER_SEGMENT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { NewsletterCustomerSegmentParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchNewsletterSegmentsRequest = () => ({
  type: FETCH_NEWSLETTER_SEGMENTS_REQUEST,
});

const fetchNewsletterSegmentsSuccess = (response: any) => ({
  type: FETCH_NEWSLETTER_SEGMENTS_SUCCESS,
  response,
});

const fetchNewsletterSegmentsFailure = (error: any) => ({
  type: FETCH_NEWSLETTER_SEGMENTS_FAILURE,
  error,
});

export const fetchNewsletterSegments =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchNewsletterSegmentsRequest());
    axios
      .get('/api/newslettersegments', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchNewsletterSegmentsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchNewsletterSegmentsFailure('canceled'));
        else dispatch(fetchNewsletterSegmentsFailure(error));
      });
  };

const deleteNewsletterSegmentRequest = (id: string) => ({
  type: DELETE_NEWSLETTER_SEGMENT_REQUEST,
  id,
});

const deleteNewsletterSegmentSuccess = (response: any, id: string) => ({
  type: DELETE_NEWSLETTER_SEGMENT_SUCCESS,
  response,
  id,
});

const deleteNewsletterSegmentFailure = (error: any) => ({
  type: DELETE_NEWSLETTER_SEGMENT_FAILURE,
  error,
});

export const deleteNewsletterSegment =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteNewsletterSegmentRequest(id));
    fetch(`/api/newslettersegments/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteNewsletterSegmentSuccess(result, id)))
      .catch((error) => dispatch(deleteNewsletterSegmentFailure(error)));
  };

const createNewsletterSegmentRequest = (
  newMemo: NewsletterCustomerSegmentParams
) => ({
  type: CREATE_NEWSLETTER_SEGMENT_REQUEST,
  newMemo,
});

const createNewsletterSegmentSuccess = (response: any) => ({
  type: CREATE_NEWSLETTER_SEGMENT_SUCCESS,
  response,
});

const createNewsletterSegmentFailure = (error: any) => ({
  type: CREATE_NEWSLETTER_SEGMENT_FAILURE,
  error,
});

export const createNewsletterSegment =
  (newSegment: NewsletterCustomerSegmentParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createNewsletterSegmentRequest(newSegment));
    return axios
      .post('/api/newslettersegments', newSegment, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createNewsletterSegmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createNewsletterSegmentFailure(err.message));
      });
  };

const updateNewsletterSegmentRequest = (
  id: string,
  patch: NewsletterCustomerSegmentParams
) => ({
  type: UPDATE_NEWSLETTER_SEGMENT_REQUEST,
  id,
  patch,
});

const updateNewsletterSegmentSuccess = (response: any) => ({
  type: UPDATE_NEWSLETTER_SEGMENT_SUCCESS,
  response,
});

const updateNewsletterSegmentFailure = (error: any) => ({
  type: UPDATE_NEWSLETTER_SEGMENT_FAILURE,
  error,
});

export const updateNewsletterSegment =
  (id: string, patch: NewsletterCustomerSegmentParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateNewsletterSegmentRequest(id, patch));
    return axios
      .patch(`/api/newslettersegments/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateNewsletterSegmentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateNewsletterSegmentFailure(err.message));
      });
  };
