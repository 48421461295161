import { useTranslation } from 'react-i18next';
import {
  ResponsiveContainer,
  BarChart,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  XAxis,
} from 'recharts';
import { useSelector } from 'react-redux';

import { BaseGadget } from 'client/components/v3/DashboardGadgets/BaseGadget';
import { NpsSurveyReportDataSet } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import { getAnswersFromNPSSurveySecondQuestion } from '../util';

import { Props, TooltipProps } from './type';
import styles from './Chart.module.css';

const CustomTooltip = ({ active, payload, label, t }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className={styles['c-tooltip']}>
          <div className={styles['c-tooltip__header']}>
            <p className={styles['c-tooltip__header__ttl']}>{label}</p>
          </div>
          <div className={styles['c-tooltip__body']}>
            {payload.map((payload, index) => (
              <p className={styles['c-tooltip__body__item']} key={index}>{`${
                payload.name === 'Base Period'
                  ? t('Base Period', { context: 'nps' })
                  : t(payload.name)
              }: ${payload.value}`}</p>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
};

const tickFormatter = (value: string) => {
  const limit = 10;
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

const CustomYAxisTick = ({
  y,
  payload,
  mql,
}: {
  y: number;
  payload: { value: string };
  mql: MediaQueryList;
}) => {
  let value = payload.value;
  if (mql.matches) {
    value = tickFormatter(payload.value);
  }

  return (
    <g transform={`translate(${0},${y})`}>
      <text x={0} y={0} textAnchor="start" fill="#666">
        {value}
      </text>
    </g>
  );
};

type FactorChart = Props & {
  title: string;
  type: 'NPS_SURVEY_RESULT_TYPE_DETRACTOR' | 'NPS_SURVEY_RESULT_TYPE_PROMOTER';
  baseColor: string;
  comparisonColor: string;
};

export const NPSRatingsFactorChart = ({
  baseDataSet,
  comparisonDataSet,
  loading = false,
  title,
  type,
  baseColor,
  comparisonColor,
}: FactorChart) => {
  const { t } = useTranslation();
  const surveyTemplates = useSelector((state: ReduxState) =>
    state.surveyTemplates.all.filter(
      (template) =>
        template.is_nps_survey &&
        template.status === 'SURVEY_TEMPLATE_PUBLISHED'
    )
  );
  const mql = window.matchMedia('(max-width: 768px)');

  const allAnswers = surveyTemplates.flatMap((surveyTemplate) =>
    getAnswersFromNPSSurveySecondQuestion(surveyTemplate)
  );

  const answerTextMap = allAnswers.reduce((acc, answer) => {
    if (answer.key && answer.text) {
      acc[answer.key] = answer.text;
    }
    return acc;
  }, {} as Record<string, string>);

  const countNpsFactors = (dataSet: NpsSurveyReportDataSet | null) => {
    return (
      dataSet?.items_by_date?.reduce((acc, item) => {
        item.results?.forEach((result) => {
          if (result.type === type) {
            result.nps_factors?.forEach((factor) => {
              const factorText = answerTextMap[factor] || factor;
              acc[factorText] = (acc[factorText] || 0) + 1;
            });
          }
        });
        return acc;
      }, {} as Record<string, number>) || {}
    );
  };

  const baseFactorCounts = countNpsFactors(baseDataSet);
  const comparisonFactorCounts = countNpsFactors(comparisonDataSet);

  let chartData = Object.keys(baseFactorCounts)
    .map((factor) => ({
      name: factor,
      'Base Period': baseFactorCounts[factor],
      'Comparison Period': comparisonDataSet
        ? comparisonFactorCounts[factor] || 0
        : undefined,
    }))
    .sort((a, b) => b['Base Period'] - a['Base Period'])
    .slice(0, 5);

  // Add numbering after sorting
  chartData = chartData.map((data, index) => ({
    ...data,
    name: `${index + 1}. ${data.name}`,
  }));

  const renderLegendText = (value: string) => {
    return (
      <span style={{ color: '#71717A' }}>{t(value, { context: 'nps' })}</span>
    );
  };

  return (
    <BaseGadget header={title} loading={loading}>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart data={chartData} layout="vertical" barSize={20} barGap={6}>
          <XAxis type="number" hide={true} />
          <YAxis
            dataKey="name"
            type="category"
            axisLine={false}
            tickLine={false}
            width={mql.matches ? 120 : 200}
            includeHidden={true}
            tick={
              <CustomYAxisTick
                y={0}
                payload={{
                  value: '',
                }}
                mql={mql}
              />
            }
          />
          <Tooltip
            content={<CustomTooltip t={t} />}
            cursor={{ fill: 'transparent' }}
          />
          <Legend
            align="center"
            iconType="square"
            formatter={renderLegendText}
            wrapperStyle={{ paddingTop: '12px' }}
          />
          <Bar dataKey="Base Period" fill={baseColor} radius={[0, 6, 6, 0]} />
          {comparisonDataSet && (
            <Bar
              dataKey="Comparison Period"
              fill={comparisonColor}
              radius={[0, 6, 6, 0]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </BaseGadget>
  );
};
