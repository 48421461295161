import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type {
  GraphDataItem,
  AggregationType,
} from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
  aggregationType: AggregationType;
  currencyCode: string | null;
};
export const TablePane = ({ data, aggregationType, currencyCode }: Props) => {
  const { t } = useTranslation();

  const getTitle = (aggregationType: AggregationType) => {
    let title = '';

    switch (aggregationType) {
      case 'TOTAL':
        title = t('Date');
        break;

      case 'PRODUCT':
        title = t('Product');
        break;

      case 'BOOKING_SOURCE':
      default:
        title = t('Booking source');
    }

    return title;
  };

  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th
            style={{
              width: 'calc(100% / 5)',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {getTitle(aggregationType)}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Gross')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Net')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Number of Reservations')}
          </th>
          <th
            style={{
              width: 'calc(100% / 5)',
              textAlign: 'right',
              fontWeight: 'var(--text-semibold) !important',
            }}
          >
            {t('Pax')}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataItem, idx) => {
          return (
            <tr key={idx}>
              <td>{dataItem.name}</td>
              <td style={{ textAlign: 'right' }}>
                {formattedAmount(dataItem.amountGross, currencyCode)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {formattedAmount(dataItem.amountNet, currencyCode)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {dataItem.numOfReservations}
              </td>
              <td style={{ textAlign: 'right' }}>{dataItem.pax}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
