import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';

import { AutomaticAvailabilityAdjustmentEditor } from './AutomaticAvailabilityAdjustmentEditor';
import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from './formValues';

type Props = {
  onClose: () => void;
};
export const AutomaticAvailabilityAdjustmentModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Edit automatic availability adjustments')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
      width="wide"
    >
      <AutomaticAvailabilityAdjustmentEditor />
    </ProductEditorModal>
  );
};
