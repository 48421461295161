import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper, MultiSelect } from 'client/components/Form';
import { productOptionsSelector } from 'client/reducers/products';

export const EditInstantWinDrawTriggerReservationCreatedForm = () => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);

  return (
    <Box mb={2}>
      <FieldWrapper label={t('Select Products')}>
        <Field name="productIds">
          {({ input }) => (
            <MultiSelect
              search
              options={productOptions}
              selectedValues={input.value}
              onChange={({ value }) => input.onChange(value)}
            />
          )}
        </Field>
      </FieldWrapper>
    </Box>
  );
};
