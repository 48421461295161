import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { TextArea, Button } from 'client/components/Form';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Message } from 'client/components/Message/Message';
import { Divider } from 'client/components/Divider/Divider';
import {
  bulkCancelReservation,
  bulkDeclineReservation,
} from 'client/actions/reservations';

export type BULK_OPERATION = 'CANCEL' | 'DECLINE' | undefined;

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  cancelDeclineList: Record<string, BULK_OPERATION>;
  onCancel: () => void;
};

export const ReservationBulkCancelModal = ({
  setShowModal,
  cancelDeclineList,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cancelReservationIds: string[] = [];
  const declineReservationIds: string[] = [];
  Object.keys(cancelDeclineList).forEach((elm) => {
    if (cancelDeclineList[elm] === 'CANCEL') {
      cancelReservationIds.push(elm);
    } else if (cancelDeclineList[elm] === 'DECLINE') {
      declineReservationIds.push(elm);
    }
  });

  const [agentNote, setAgentNote] = React.useState<string>('');

  const handleAgentNotesChange = (value: string) => {
    setAgentNote(value);
  };

  return (
    <Modal
      title={t('Cancel/Decline Reservations')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <Message warning header={t('Warning')}>
            {t('Selected reservations will be canceled/declined. ')}
            <br />
            {cancelReservationIds.length > 0 && (
              <>
                -{' '}
                {t('Cancel reservations : {{n}}', {
                  n: cancelReservationIds.length,
                })}
                <br />
              </>
            )}
            {declineReservationIds.length > 0 && (
              <>
                -{' '}
                {t('Decline reservations : {{n}}', {
                  n: declineReservationIds.length,
                })}
                <br />
              </>
            )}
            {t('Cancel fee will not be charged for bulk cancellation.')}
            <br />
            {t(
              'Cancel & decline process will take longer depending on the number of reservations. It may take more than one minute to complete all processes.'
            )}
          </Message>
        </Modal.Box>

        <Divider />

        <ReservationActorInputForm />

        <TextArea
          label={t('Replies')}
          value={agentNote}
          onChange={(_, { value }) => handleAgentNotesChange(value)}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button.Delete
          onClick={() => {
            if (cancelReservationIds.length > 0) {
              dispatch(bulkCancelReservation(cancelReservationIds, agentNote));
            }
            if (declineReservationIds.length > 0) {
              dispatch(
                bulkDeclineReservation(declineReservationIds, agentNote)
              );
            }
            setShowModal(false);
            onCancel();
          }}
        >
          {t('Cancel, Decline Reservations')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  );
};
