import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchEquipments } from 'client/actions/equipments';
import { fetchEquipmentAssets } from 'client/actions/equipmentAssets';
import { fetchEquipmentBlocks } from 'client/actions/equipmentBlocks';
import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentAssetsSelector } from 'client/reducers/equipmentAssets';
import { equipmentBlocksSelector } from 'client/reducers/equipmentBlocks';
import { CreateEquipmentModal } from 'client/pages/EquipmentEditor/CreateEquipmentModal';
import { CreateEquipmentAssetModal } from 'client/pages/EquipmentEditor/CreateEquipmentAssetModal';
import { CreateEquipmentBlockModal } from 'client/pages/EquipmentEditor/CreateEquipmentBlockModal';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { SeatEditorPane } from './SeatEditorPane';
import { ResourcePane } from './ResourcePane';
import { AssetPane } from './AssetPane';
import { BlockPane } from './BlockPane';

export const SeatEditor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const equipments = useSelector(equipmentsSelector);
  const equipmentAssets = useSelector(equipmentAssetsSelector);
  const equipmentBlocks = useSelector(equipmentBlocksSelector);

  const equipmentLoading = useSelector(
    (state: ReduxState) => state.equipments.loading
  );
  const equipmentAssetLoading = useSelector(
    (state: ReduxState) => state.equipmentAssets.loading
  );
  const equipmentBlockLoading = useSelector(
    (state: ReduxState) => state.equipmentBlocks.loading
  );
  const loading =
    equipmentLoading || equipmentAssetLoading || equipmentBlockLoading;

  const [selectedEquipmentId, setSelectedEquipmentId] = React.useState('');
  const [selectedEquipmentAssetId, setSelectedEquipmentAssetId] =
    React.useState('');
  const [selectedEquipmentBlockId, setSelectedEquipmentBlockId] =
    React.useState('');

  const [showCreateEquipmentModal, setShowCreateEquipmentModal] =
    React.useState(false);
  const [draggingEquipmentAssetId, setDraggingEquipmentAssetId] =
    React.useState<string | null>(null);
  const [draggingEquipmentBlockId, setDraggingEquipmentBlockId] =
    React.useState<string | null>(null);

  const [activeTab, setActiveTab] = React.useState<
    'RESOURCE' | 'ASSET' | 'BLOCK'
  >('RESOURCE');

  const selectedEquipment = React.useMemo(() => {
    return equipments.find((equipment) => equipment.id === selectedEquipmentId);
  }, [equipments, selectedEquipmentId]);

  const selectedEquipmentAsset = React.useMemo(() => {
    return equipmentAssets.find(
      (equipmentAsset) => equipmentAsset.id === selectedEquipmentAssetId
    );
  }, [equipmentAssets, selectedEquipmentAssetId]);

  const selectedEquipmentBlock = React.useMemo(() => {
    return equipmentBlocks.find(
      (equipmentBlock) => equipmentBlock.id === selectedEquipmentBlockId
    );
  }, [equipmentBlocks, selectedEquipmentBlockId]);

  React.useEffect(() => {
    dispatch(fetchEquipments());
    dispatch(fetchEquipmentAssets());
    dispatch(fetchEquipmentBlocks());
  }, [activeUserOrganization]);

  return (
    <>
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={componentStyles['c-tab-box__tab']}>
          <li
            className={clsx(
              'RESOURCE' === activeTab && componentStyles['is-active']
            )}
            onClick={() => setActiveTab('RESOURCE')}
          >
            <a>{t('Resource')}</a>
          </li>
          <li
            className={clsx(
              'ASSET' === activeTab && componentStyles['is-active']
            )}
            onClick={() => setActiveTab('ASSET')}
          >
            <a>{t('Asset')}</a>
          </li>
          <li
            className={clsx(
              'BLOCK' === activeTab && componentStyles['is-active']
            )}
            onClick={() => setActiveTab('BLOCK')}
          >
            <a>{t('Block')}</a>
          </li>
        </ul>
        {activeTab === 'RESOURCE' && (
          <SeatEditorPane
            items={equipments
              .map((equipment) => ({
                id: equipment.id || '',
                title: equipment.title || '',
              }))
              .sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })}
            selectedItemId={selectedEquipmentId}
            onItemChange={(id) => {
              setSelectedEquipmentId(id);
            }}
            componentPaneTitle={t('Saved Assets')}
            componentItems={equipmentAssets.map((equipmentAsset) => ({
              id: equipmentAsset.id || '',
              title: equipmentAsset.title || '',
            }))}
            onComponentItemDragStart={(id) => {
              setDraggingEquipmentAssetId(id);
            }}
            onCreate={() => {
              setShowCreateEquipmentModal(true);
            }}
          >
            <ResourcePane
              equipment={selectedEquipment}
              draggingEquipmentAssetId={draggingEquipmentAssetId}
            />
            <CreateEquipmentModal
              open={showCreateEquipmentModal}
              onClose={() => setShowCreateEquipmentModal(false)}
            />
          </SeatEditorPane>
        )}
        {activeTab === 'ASSET' && (
          <SeatEditorPane
            items={equipmentAssets.map((asset) => ({
              id: asset.id || '',
              title: asset.title || '',
            }))}
            selectedItemId={selectedEquipmentAssetId}
            onItemChange={(id) => {
              setSelectedEquipmentAssetId(id);
            }}
            componentPaneTitle={t('Saved Blocks')}
            componentItems={equipmentBlocks.map((block) => ({
              id: block.id || '',
              title: block.title || '',
            }))}
            onComponentItemDragStart={(id) => {
              setDraggingEquipmentBlockId(id);
            }}
            onCreate={() => {
              setShowCreateEquipmentModal(true);
            }}
          >
            <AssetPane
              equipmentAsset={selectedEquipmentAsset}
              draggingEquipmentBlockId={draggingEquipmentBlockId}
            />
            <CreateEquipmentAssetModal
              open={showCreateEquipmentModal}
              onClose={() => setShowCreateEquipmentModal(false)}
            />
          </SeatEditorPane>
        )}
        {activeTab === 'BLOCK' && (
          <SeatEditorPane
            items={equipmentBlocks.map((block) => ({
              id: block.id || '',
              title: block.title || '',
            }))}
            selectedItemId={selectedEquipmentBlockId}
            onItemChange={(id) => {
              setSelectedEquipmentBlockId(id);
            }}
            onCreate={() => {
              setShowCreateEquipmentModal(true);
            }}
          >
            <BlockPane equipmentBlock={selectedEquipmentBlock} />
            <CreateEquipmentBlockModal
              open={showCreateEquipmentModal}
              onClose={() => setShowCreateEquipmentModal(false)}
            />
          </SeatEditorPane>
        )}

        {loading && <Loading />}
      </div>
    </>
  );
};
