// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { updateManifestCustomViews } from 'client/actions/manifestSettings';
import { manifestCustomViewsSelector } from 'client/reducers/manifestSettings';
import type { ManifestViewType } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type OwnProps = {
  customViewToDelete: {
    ...ManifestViewType,
  },
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};

/* eslint-enable no-use-before-define */
const DeleteCustomViewModalComponent = ({
  allCustomViews,
  customViewToDelete,
  open,
  onClose,
  updateManifestViews,
}: Props) => {
  const { t } = useTranslation();

  return customViewToDelete ? (
    <Modal
      title={t('Delete Manifest View')}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: customViewToDelete.key,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button.Delete
          onClick={() => {
            const newCustomViews = allCustomViews.filter(
              (view) => view.key !== customViewToDelete.key
            );
            updateManifestViews(newCustomViews);
            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  allCustomViews: manifestCustomViewsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateManifestViews: (views: ManifestViewType[]) =>
    dispatch(updateManifestCustomViews(views)),
});

export const DeleteCustomViewModal = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCustomViewModalComponent);
