import * as React from 'react';

type Props = {
  title: string;
  description: string;
  titleFontFamily?: string;
  titleColor?: string;
  descriptionFontFamily?: string;
  descriptionColor?: string;
};

export const Heading = ({
  title,
  description,
  titleFontFamily,
  titleColor,
  descriptionFontFamily,
  descriptionColor,
}: Props) => {
  return (
    <tr>
      <td>
        <div style={{ marginBottom: '1.5em' }}>
          <p
            style={{
              margin: 0,
              fontWeight: 'bold',
              fontSize: '1.5em',
              whiteSpace: 'pre-line',
              borderBottom: '1px solid #E4E4E7',
              paddingBottom: '20px',
              fontFamily: titleFontFamily,
              color: titleColor,
            }}
          >
            {title}
          </p>
          <p
            style={{
              margin: '20px 0 0 0',
              padding: 0,
              whiteSpace: 'pre-line',
              fontFamily: descriptionFontFamily,
              color: descriptionColor,
            }}
          >
            {description}
          </p>
        </div>
      </td>
    </tr>
  );
};
