import type {
  AvailabilityChannelCategory,
  Product,
} from 'shared/models/swagger';

export const getAllChannels = (
  product: Product | null
): {
  channel_category?: AvailabilityChannelCategory;
  agent_id?: string;
  agent_name?: string;
}[] => {
  const agents = product?.agents || [];
  return [
    {
      channel_category: 'COMMON',
    },
    ...agents.map((a) => ({
      channel_category: 'AGENT' as AvailabilityChannelCategory,
      agent_id: a.id,
      agent_name: a.name,
    })),
    {
      channel_category: 'DIRECT_ALL',
    },
  ];
};
