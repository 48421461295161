import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReservationByID } from 'client/actions/reservations';
import { Message } from 'client/components/Message/Message';
import type { ReduxState } from 'client/reducers';
import { resendEmail } from 'client/actions/emails';
import { getEmailTypeText } from 'client/libraries/util/getEmailTypeText';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import emailOpenedIcon from 'client/images/ic_email_opened.svg';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';

import pageStyles from '../pages.module.css';

import { ResendEmailButton } from './ResendEmailButton';

type Props = {
  reservation: Reservation;
};

export const EmailLog = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State to keep track the resending email Id.
  const [resendEmailId, setResendEmailId] = React.useState<string>('');
  // State to show or hide a resend success message.
  const [resendEmailSucceeded, setResendEmailSucceeded] =
    React.useState<boolean>(false);
  // State to show or hide a resend error message.
  const [resendEmailError, setResendEmailError] =
    React.useState<boolean>(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const emails = (reservation.email_log ?? []).filter(
    (email) => email.recipient_entity_type === 'GUEST_ENTITY_TYPE'
  );

  if (emails.length === 0) {
    return null;
  }

  // store parent_email_id into an array,
  // if the email is opened by the guest (email item contains "email_opened_at_date_time_utc" key).
  const openedEmails = [
    ...new Set(
      emails
        .filter((item) => item.email_opened_at_date_time_utc)
        .map((item) => item.parent_email_id)
    ),
  ];

  const resendEmailByEmailId = async (
    emailId: string | undefined,
    toAddress?: string
  ) => {
    if (!emailId) {
      return;
    }

    try {
      setResendEmailId(emailId);
      setResendEmailSucceeded(false);
      setResendEmailError(false);

      await dispatch(resendEmail(emailId, toAddress ?? ''));
      await dispatch(fetchReservationByID(reservation.id));

      setResendEmailId('');
      setResendEmailSucceeded(true);
    } catch (e) {
      setResendEmailId('');
      setResendEmailError(true);
    }
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Emails Sent to Guest')}
        </div>
        <div
          className={clsx(baseStyles['base-main__body__box__header__btn'])}
        ></div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <ul className={clsx(pageStyles['page-reservations__history'])}>
          {emails.map((emailSummary, idx) => (
            <li key={idx}>
              {/*
                Show read / resend icon if the record contains parent_email_id
                  - Show read icon if any one of the child record (record with same parent_email_id) contains email_opened_at_date_time_utc (email is opened by the guest)
                  - Show resend icon if the record does not contains email_opened_at_date_time_utc
              */}
              {emailSummary.parent_email_id ? (
                <div className={clsx(baseStyles['base-t-160'])}>
                  {openedEmails.includes(emailSummary.parent_email_id) ? (
                    <div
                      className={clsx(
                        pageStyles['page-reservations__resend_email']
                      )}
                    >
                      <img src={emailOpenedIcon} />
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        pageStyles['page-reservations__resend_email']
                      )}
                    >
                      <ResendEmailButton
                        loading={emailSummary.id == resendEmailId}
                        onResendEmail={(toAddress?: string) =>
                          resendEmailByEmailId(emailSummary.id, toAddress)
                        }
                        showGuestEmailEditModal={Boolean(
                          emailSummary.email_bounced_at_date_time_utc
                        )}
                      />
                      <Box ml="auto" mr={2}>
                        {emailSummary.email_bounced_at_date_time_utc && (
                          <Tooltip
                            text={t(
                              'Email bounced. This usually means that the email address is invalid.'
                            )}
                            mobileStyle={{
                              left: '-180px',
                            }}
                            icon={
                              <i
                                style={{ background: '#dc3e15' }}
                                className={baseStyles['base-info__ic']}
                              >
                                !
                              </i>
                            }
                          />
                        )}
                      </Box>
                    </div>
                  )}
                </div>
              ) : (
                <div className={clsx(baseStyles['base-t-160'])}>&nbsp;</div>
              )}

              <div className={clsx(baseStyles['base-t-160'])}>
                {moment(emailSummary.sent_date_time_utc)
                  .locale(locale)
                  .format('lll')}
              </div>
              <div className={clsx(baseStyles['base-t-160'])}>
                {emailSummary.email_type != null
                  ? getEmailTypeText(emailSummary.email_type, t)
                  : ''}
              </div>
              <div
                className={clsx(pageStyles['page-reservations__email_title'])}
              >
                {emailSummary.email_title ?? ''}
              </div>
            </li>
          ))}
          {resendEmailSucceeded && (
            <li key={'resend_success_msg'}>
              <Message success header={t('Send Successful')} />
            </li>
          )}
          {resendEmailError && (
            <li key={'resend_error_msg'}>
              <Message error header={t('Send Failed')} />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
