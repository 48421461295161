import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const ReservationBulkCancelTimeOutModal = ({ setShowModal }: Props) => {
  const { t } = useTranslation();

  const text = t(
    'There was a timeout with the network. It is possible that some bookings have not been canceled/declined. We apologize for the inconvenience, but please search and cancel/decline the reservation again.'
  );

  return (
    <Modal
      title={t('Cancellation/Decline Timeout')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      rightActionChildren={
        <>
          <Button
            text={t('OK')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </>
      }
      useCloseButton={true}
      style={{ width: '600px' }}
    >
      <div>{text}</div>
    </Modal>
  );
};
