import * as React from 'react';
import { Field } from 'react-final-form';

import { withQueryParams, truncated, CustomCard } from '../util';

import { BaseTemplate } from './BaseTemplate';
import { CustomerGreetingsSection } from './section/CustomerGreetingsSection';
import { KeyVisualSection } from './section/KeyVisualSection';

const CustomCardEditButtons = React.lazy(
  () => import('client/components/TemplateEditButtons/CustomCardEditButtons')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  customCards: CustomCard[];
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const SimpleTemplate = ({
  editMode,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  keyVisualUrl,
  headline,
  headlineDescription,
  customCards,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
  language,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* Key visual */}
      <KeyVisualSection
        data={{
          editMode: editMode ?? false,
          keyVisualUrl: keyVisualUrl,
        }}
      />
      {/* Customer greetings お客様向けメッセージ */}
      <CustomerGreetingsSection
        data={{
          editMode: editMode ?? false,
          customerName: customerName ?? '',
          customerIntroduction: customerIntroduction,
          introParagraph: introParagraph,
        }}
      />
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* 当店一番人気のプランは残席わずか！ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1.5em',
              backgroundColor: '#ffffff',
            }}
          >
            {editMode && (
              <Field name="templateValues.headline">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                fontSize: '1.5em',
                whiteSpace: 'pre-line',
                borderBottom: '1px solid #E4E4E7',
                paddingBottom: '1em',
              }}
            >
              {headline}
            </p>
            {editMode && (
              <Field name="templateValues.headlineDescription">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: '1em 0 0 0',
                padding: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {headlineDescription}
            </p>

            {customCards.map((card, idx) => (
              <>
                {editMode && (
                  <>
                    <Field name="templateValues.customCards">
                      {({ input }) => (
                        <CustomCardEditButtons
                          onSave={(newValue) => input.onChange(newValue)}
                          initialValue={input.value}
                          cardIdx={idx}
                        />
                      )}
                    </Field>
                  </>
                )}
                <div
                  style={{
                    marginTop: '1em',
                    border: '1px solid #E4E4E7',
                    borderRadius: '16px',
                  }}
                >
                  <a
                    href={withQueryParams(card.linkUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: newsletterCampaignId ?? 'todo',
                      utm_content: 'mainProduct',
                      lng: language,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: '100%',
                      margin: '1em 0 0 0',
                      padding: 0,
                      background: '#F9F9F9',
                      textDecoration: 'none',
                    }}
                  >
                    <div
                      style={{
                        margin: '1.5em',
                        verticalAlign: 'middle',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={card.thumbnailUrl}
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: '0 1.5em 1.5em 1.5em',
                        color: '#333333',
                        verticalAlign: 'middle',
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '16px',
                          fontWeight: 'bold',
                          lineHeight: '1.5em',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {card.title}
                      </p>
                      <p
                        style={{
                          margin: '.5em 0 0 0',
                          padding: 0,
                          fontSize: '12px',
                          color: '#4A6C7A',
                          lineHeight: '1.5em',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {truncated(card.description, 200)}
                      </p>
                    </div>
                    <div style={{ padding: '0 1.5em 1.5em 1.5em' }}>
                      <a
                        href={withQueryParams(card.linkUrl, {
                          utm_source: 'ntmg.newsletter',
                          utm_medium: 'email',
                          utm_campaign: newsletterCampaignId ?? 'todo',
                          utm_content: 'mainProduct',
                          lng: language,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          maxWidth: '240px',
                          borderRadius: '9999px',
                          textDecoration: 'none',
                          padding: '2px',
                          display: 'block',
                          margin: '0 auto',
                        }}
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            display: 'block',
                            fontWeight: 'bold',
                            width: '100%',
                            height: '48px',
                            lineHeight: '48px',
                            fontSize: '16px',
                            backgroundColor: '#4DA1EF',
                            color: '#ffffff',
                            textDecoration: 'none',
                            textAlign: 'center',
                            borderRadius: '40px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {card.buttonText}
                        </span>
                      </a>
                    </div>
                  </a>
                </div>
              </>
            ))}
          </div>
        </td>
      </tr>
    </BaseTemplate>
  );
};
