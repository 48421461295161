import { createContext } from 'react';

import { ReservationParamsFormValues } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { BasicFormValues } from 'client/pages/v3/Product/ProductEdit/types';

type Props = {
  basicFormValues: BasicFormValues | null;
  setBasicFormValues: (values: BasicFormValues) => void;
  reservationParamsFormValues: ReservationParamsFormValues | null;
  setReservationParamsFormValues: (values: ReservationParamsFormValues) => void;
};

// ProductHelperContext can be used to provide product related information during creation of a product
// This is because where previously we relied on product passed in EditingProductContext, during the new product creation page,
// some information are not available yet (not yet updated via PATCH) and thus we need auxiliary information
export const ProductHelperContext = createContext<Props>({
  basicFormValues: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBasicFormValues: () => {},
  reservationParamsFormValues: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setReservationParamsFormValues: () => {},
});
