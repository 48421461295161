import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field, useFormState, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getArrayMutators } from 'client/libraries/util/form';
import { Button, FieldWrapper, ToggleButton } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';

import {
  convertFormValuesToSwaggerTopPageSection,
  TopPageFormValues,
  TopPageSection,
} from '../FormValues';
import type { AdditionalImage } from '../FormValues';
import { deleteAdditionalImage, updateTopPageSection } from '../util';

import { EditAdditionalImageModal } from './EditAdditionalImageModal';
import { DraggableAdditionalImage } from './DraggableAdditionalImage';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
};
export const EditAdditionalImagesSectionModal = ({
  name,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [showImageEditModal, setShowImageEditModal] = React.useState(false);
  const dispatch = useDispatch();
  const parentForm = useForm<TopPageFormValues>();
  const parentFormState = useFormState<TopPageFormValues>();
  const language = parentFormState.values?.sectionLanguage ?? 'JA_JP';

  const initialValues = _.get(parentFormState.values, name);

  const parentFormSaveAdditionalImage = (
    newAdditionalImage: AdditionalImage
  ) => {
    const existingAdditionalImages: AdditionalImage[] =
      _.get(parentFormState.values, `${name}.additionalImages`) ?? [];
    parentForm.change(
      `${name}.additionalImages` as any,
      existingAdditionalImages?.some(
        (existingImage) => existingImage.key === newAdditionalImage.key
      )
        ? existingAdditionalImages?.map((existingImage) =>
            existingImage.key === newAdditionalImage.key
              ? newAdditionalImage
              : existingAdditionalImages
          )
        : [...(existingAdditionalImages ?? []), newAdditionalImage]
    );
  };

  const parentFormDeleteAdditionalImage = (key: string) => {
    const existingAdditionalImages: AdditionalImage[] =
      _.get(parentFormState.values, `${name}.additionalImages`) ?? [];
    parentForm.change(
      `${name}.additionalImages` as any,
      existingAdditionalImages?.filter(
        (existingImage) => existingImage.key === key
      )
    );
  };

  return (
    <Modal
      title={t('Set additional photos')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: TopPageSection) => {
          await dispatch(
            updateTopPageSection(
              convertFormValuesToSwaggerTopPageSection(values),
              language
            )
          );
          parentForm.change(name as any, values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Box mb={2}>
                <Field type="checkbox" name="isVisible">
                  {({ input }) => (
                    <ToggleButton
                      {...input}
                      label={t(
                        'Show additional photos on booking website top page'
                      )}
                    />
                  )}
                </Field>
              </Box>
              <FieldWrapper label={t('Additional Photos')}>
                <FieldArray name="additionalImages">
                  {({ fields }) => (
                    <>
                      <Box mb={2}>
                        {t('* drag-and-drop to reorder')}
                        <Button
                          style="green"
                          size="middle"
                          onClick={() => setShowImageEditModal(true)}
                        >
                          {t('Add photo')}
                        </Button>
                      </Box>
                      {showImageEditModal && (
                        <EditAdditionalImageModal
                          name=""
                          onSave={(value: AdditionalImage) => {
                            fields.push(value);
                            parentFormSaveAdditionalImage(value);
                          }}
                          open={showImageEditModal}
                          onClose={() => setShowImageEditModal(false)}
                          language={language}
                        />
                      )}
                      {fields.map((name, idx) => (
                        <DraggableAdditionalImage
                          key={fields.value?.[idx].key}
                          name={name}
                          index={idx}
                          moveItem={(dragIndex: number, hoverIndex: number) => {
                            fields.move(dragIndex, hoverIndex);
                          }}
                          deleteItem={async () => {
                            await dispatch(
                              deleteAdditionalImage(
                                fields.value[idx].key,
                                language
                              )
                            );
                            fields.remove(idx);
                            parentFormDeleteAdditionalImage(
                              fields.value[idx].key
                            );
                          }}
                          onSave={(value: AdditionalImage) =>
                            parentFormSaveAdditionalImage(value)
                          }
                          language={language}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </FieldWrapper>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  parentForm.change(
                    `${name}.additionalImages` as any,
                    initialValues.additionalImages
                  );
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                size="middle"
                style="blue"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
