import * as React from 'react';

import { Section } from 'shared/components/templates/newsletter/CustomEditor/util';
import { SectionView } from 'shared/components/templates/newsletter/CustomEditor/SectionView';

const AddSectionButton = React.lazy(
  () =>
    import(
      'client/components/NewsletterTemplate/CustomEditor/Button/AddSectionButton'
    )
);
const ButtonGroup = React.lazy(
  () =>
    import(
      'client/components/NewsletterTemplate/CustomEditor/Button/ButtonGroup'
    )
);

interface Props {
  editMode?: boolean;
  sections: Section[];
  language?: string;
  newsletterCampaignId?: string;
}

export const TemplateContent = ({
  editMode,
  sections,
  language,
  newsletterCampaignId,
}: Props) => {
  return (
    <tr>
      <td>
        <>
          {sections?.map((section, index) => {
            return (
              <>
                {editMode && (
                  <>
                    <AddSectionButton index={index} />
                    <ButtonGroup
                      index={index}
                      length={sections.length}
                      language={language}
                    />
                  </>
                )}
                <SectionView
                  data={section}
                  language={language}
                  newsletterCampaignId={newsletterCampaignId}
                />
              </>
            );
          })}
        </>
        {editMode && <AddSectionButton index={sections?.length ?? 0} />}
      </td>
    </tr>
  );
};

export default TemplateContent;
