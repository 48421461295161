import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE,
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST,
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  RealtimeNotificationTemplate,
  CreateRealtimeNotificationTemplateResponse,
  ListRealtimeNotificationTemplatesResponse,
  UpdateRealtimeNotificationTemplateResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
      return action.error;

    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST:
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST:
    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST:
    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST:
      return true;

    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS:
    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: RealtimeNotificationTemplate[] = [],
  action: any
): RealtimeNotificationTemplate[] => {
  switch (action.type) {
    case FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS: {
      const response =
        action.response as ListRealtimeNotificationTemplatesResponse;
      return response.realtime_notification_templates;
    }

    case FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS: {
      const response = action.response as RealtimeNotificationTemplate;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS: {
      const response =
        action.response as CreateRealtimeNotificationTemplateResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS: {
      const response =
        action.response as UpdateRealtimeNotificationTemplateResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedNotificationTemplate = (
  state: RealtimeNotificationTemplate | null = null,
  action: any
) => {
  switch (action.type) {
    case CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE: {
      const response =
        action.response as CreateRealtimeNotificationTemplateResponse;
      return response;
    }

    default:
      return state;
  }
};

export const realtimeNotificationTemplates = combineReducers({
  error,
  loading,
  all,
  lastCreatedNotificationTemplate,
});
