import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
} from 'shared/models/swagger';
import { CollapsibleSection } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/CollapsibleSection';
import { ReservationListTable } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListTable';
import { GroupByStartTimeTableList } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/GroupByStartTimeTableList';
import { FlexibleDashboardReservationListShape } from 'client/libraries/util/flexibleDashboardReservationListShape';

type Props = {
  excludedFormFieldKeys: string[];
  product?: ProductSummary;
  reservations: FlexibleDashboardReservationListShape[];
  visibleColumns: ReservationColumn[];
  open?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  capacity?: number;
  splitStartTimes?: boolean;
  manifestDate?: string;
  gadgetKey: string;
};
export const ProductTable = ({
  excludedFormFieldKeys,
  product,
  reservations,
  visibleColumns,
  open,
  customizedColumnNames,
  capacity,
  splitStartTimes,
  manifestDate,
  gadgetKey,
}: Props) => {
  const totalPax = reservations.reduce((total, reservation) => {
    return total + reservation.guests.length;
  }, 0);
  const displayProductName = getDisplayProductName(product);
  return (
    <CollapsibleSection
      title={
        displayProductName
          ? displayProductName
          : reservations?.[0]?.product_name
      }
      open={true}
    >
      <>
        {splitStartTimes ? (
          <GroupByStartTimeTableList
            manifestDate={manifestDate}
            excludedFormFieldKeys={excludedFormFieldKeys}
            products={product ? [product] : []}
            reservations={reservations}
            visibleColumns={visibleColumns as any}
            customizedColumnNames={customizedColumnNames}
            gadgetKey={gadgetKey}
          />
        ) : (
          <ReservationListTable
            excludedFormFieldKeys={excludedFormFieldKeys}
            products={product ? [product] : []}
            reservations={reservations}
            visibleColumns={visibleColumns as any}
            totalPax={totalPax}
            open={open}
            customizedColumnNames={customizedColumnNames}
            capacity={capacity}
            gadgetKey={gadgetKey}
          />
        )}
      </>
    </CollapsibleSection>
  );
};
