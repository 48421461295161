// @flow

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { activeReservationTutorialPopupSelector } from 'client/reducers/tutorial';
import { advanceReservationTutorialPopup } from 'client/actions/tutorial';
import { Button } from 'client/components/Form';
import type { ReservationTutorialPopup } from 'client/reducers/tutorial';
import iconPoint from 'client/images/ic_point.svg';
import styles from 'client/components/TutorialPopup/TutorialPopup.module.css';

type Props = {
  name: ReservationTutorialPopup,
  content: React$Node,
};

export const TutorialPopup = ({ content, name }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const activeReservationTutorialPopup = useSelector(
    activeReservationTutorialPopupSelector
  );
  const handleNextClick = () => {
    dispatch(advanceReservationTutorialPopup());
  };

  const tutorialActive = Boolean(useRouteMatch('/reservations/demo'));

  return (
    <>
      {activeReservationTutorialPopup === name && tutorialActive && (
        <div className={styles['page-tutorial__popup']}>
          {content}
          <div className={styles['page-tutorial__popup__btns']}>
            <Button style="blue" size="middle" onClick={handleNextClick}>
              {t('Next')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export const TutorialPopupContent = ({ children }: { children: React$Node }) =>
  children;

export const TutorialPopupHeader = ({ text }: { text: string }) => (
  <div className={styles['page-tutorial__popup__ttl']}>
    <img src={iconPoint} />
    <p>{text}</p>
  </div>
);

export const TutorialPopupDescription = ({ text }: { text: string }) => (
  <p className={styles['page-tutorial__popup__desc']}>{text}</p>
);
