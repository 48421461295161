import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  BATCH_UPDATE_CUSTOM_PAGE_REQUEST,
  BATCH_UPDATE_CUSTOM_PAGE_FAILURE,
  BATCH_UPDATE_CUSTOM_PAGE_SUCCESS,
  FETCH_CUSTOM_PAGES_SUCCESS,
  FETCH_CUSTOM_PAGES_FAILURE,
  FETCH_CUSTOM_PAGES_REQUEST,
  DELETE_CUSTOM_PAGE_FAILURE,
  DELETE_CUSTOM_PAGE_REQUEST,
  DELETE_CUSTOM_PAGE_SUCCESS,
  CREATE_CUSTOM_PAGE_REQUEST,
  UPDATE_CUSTOM_PAGE_FAILURE,
  UPDATE_CUSTOM_PAGE_SUCCESS,
  UPDATE_CUSTOM_PAGE_REQUEST,
  CREATE_CUSTOM_PAGE_FAILURE,
  CREATE_CUSTOM_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  NewCustomPage,
  CustomPagePatch,
  CustomPageBatch,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchCustomPagesRequest = () => ({
  type: FETCH_CUSTOM_PAGES_REQUEST,
});

const fetchCustomPagesSuccess = (response: any) => ({
  type: FETCH_CUSTOM_PAGES_SUCCESS,
  response,
});

const fetchCustomPagesFailure = (error: any) => ({
  type: FETCH_CUSTOM_PAGES_FAILURE,
  error,
});

let fetchCustomPagesCancel: () => void | typeof undefined;
export const fetchCustomPages =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchCustomPagesCancel && fetchCustomPagesCancel();
    dispatch(fetchCustomPagesRequest());
    axios
      .get('/api/custompages', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchCustomPagesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchCustomPagesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchCustomPagesFailure('canceled'));
        else dispatch(fetchCustomPagesFailure(error));
      });
  };

const deleteCustomPageRequest = (id: string) => ({
  type: DELETE_CUSTOM_PAGE_REQUEST,
  id,
});

const deleteCustomPageSuccess = (response: any, id: string) => ({
  type: DELETE_CUSTOM_PAGE_SUCCESS,
  response,
  id,
});

const deleteCustomPageFailure = (error: any) => ({
  type: DELETE_CUSTOM_PAGE_FAILURE,
  error,
});

export const deleteCustomPage =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteCustomPageRequest(id));
    fetch(`/api/custompages/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteCustomPageSuccess(result, id)))
      .catch((error) => dispatch(deleteCustomPageFailure(error)));
  };

const createCustomPageRequest = (newCustomPage: NewCustomPage) => ({
  type: CREATE_CUSTOM_PAGE_REQUEST,
  newCustomPage,
});

const createCustomPageSuccess = (response: any) => ({
  type: CREATE_CUSTOM_PAGE_SUCCESS,
  response,
});

const createCustomPageFailure = (error: any) => ({
  type: CREATE_CUSTOM_PAGE_FAILURE,
  error,
});

export const createCustomPage =
  (newCustomPage: NewCustomPage) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createCustomPageRequest(newCustomPage));
    return axios
      .post('/api/custompages', newCustomPage, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createCustomPageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createCustomPageFailure(err.message));
      });
  };

const updateCustomPageRequest = (id: string, patch: CustomPagePatch) => ({
  type: UPDATE_CUSTOM_PAGE_REQUEST,
  id,
  patch,
});

const updateCustomPageSuccess = (response: any) => ({
  type: UPDATE_CUSTOM_PAGE_SUCCESS,
  response,
});

const updateCustomPageFailure = (error: any) => ({
  type: UPDATE_CUSTOM_PAGE_FAILURE,
  error,
});

export const updateCustomPage =
  (id: string, patch: CustomPagePatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateCustomPageRequest(id, patch));
    return axios
      .patch(`/api/custompages/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateCustomPageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateCustomPageFailure(err.message));
      });
  };

const batchUpdateCustomPagesRequest = () => ({
  type: BATCH_UPDATE_CUSTOM_PAGE_REQUEST,
});

const batchUpdateCustomPagesFailure = (error: any) => ({
  type: BATCH_UPDATE_CUSTOM_PAGE_FAILURE,
  error,
});

const batchUpdateCustomPagesSuccess = (responses: any) => ({
  type: BATCH_UPDATE_CUSTOM_PAGE_SUCCESS,
  responses,
});

export const batchUpdateCustomPages =
  (customPages: CustomPageBatch[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchUpdateCustomPagesRequest());
    const promises = customPages.map((customPage) =>
      axios
        .patch(`/api/custompages/${customPage.id}`, customPage, {
          headers: getHTTPRequestHeaders(getState()),
        })
        .then((result) => result.data)
    );
    return Promise.all(promises)
      .then((responses) => dispatch(batchUpdateCustomPagesSuccess(responses)))
      .catch((error) =>
        dispatch(batchUpdateCustomPagesFailure(error.response.data.message))
      );
  };
