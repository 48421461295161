import { useDispatch, useSelector } from 'react-redux';

import {
  setReservationRowCount,
  setReservationCurrentPage,
} from 'client/actions/reservationTableControls';
import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type {
  ColumnType,
  ReservationListColumn,
} from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';

import { ReservationSearchCustomTableControlled } from './ReservationSearchCustomTableControlled';

type Props = {
  reservations: ReservationSummaryShape[];
  visibleColumns: (ColumnType & ReservationListColumn)[];
  totalHits: number;
  headerElementItems?: Record<string, boolean>;

  // TODO: the following properties drilling are needed because we made bulk cancel decline operation as part of the table
  cancelDeclineListCount: number;
  onResetCancelDeclineList: () => void;
  onExecuteCancelDecline: () => void;
};

export const ReservationSearchCustomTable = ({
  reservations,
  totalHits,
  visibleColumns,
  headerElementItems,
  cancelDeclineListCount,
  onResetCancelDeclineList,
  onExecuteCancelDecline,
}: Props) => {
  const dispatch = useDispatch();
  const rowCount = useSelector(
    (state: ReduxState) => state.reservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.reservationTableControls.currentPage
  );

  return (
    <ReservationSearchCustomTableControlled
      reservations={reservations}
      totalHits={totalHits}
      visibleColumns={visibleColumns}
      headerElementItems={headerElementItems}
      rowCount={rowCount}
      currentPage={currentPage}
      onRowCountChange={(rowCount) => {
        dispatch(setReservationRowCount(rowCount));
      }}
      onCurrentPageChange={(page) => {
        dispatch(setReservationCurrentPage(page));
      }}
      cancelDeclineListCount={cancelDeclineListCount}
      onResetCancelDeclineList={onResetCancelDeclineList}
      onExecuteCancelDecline={onExecuteCancelDecline}
    />
  );
};
