import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST,
  FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS,
  FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE,
  FETCH_NPS_SURVEY_REPORT_DATA_FAILURE,
  FETCH_NPS_SURVEY_REPORT_DATA_REQUEST,
  FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { GetSurveySubmissionSummaryRequest } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSurveySubmissionResultsRequest = () => ({
  type: FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST,
});

const fetchSurveySubmissionResultsSuccess = (response: any) => ({
  type: FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS,
  response,
});

const fetchSurveySubmissionResultsFailure = (error: string) => ({
  type: FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE,
  error,
});

export const fetchSurveySubmissionResults =
  (req: GetSurveySubmissionSummaryRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveySubmissionResultsRequest());
    return axios
      .get('/api/surveysubmissions/summary', {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchSurveySubmissionResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSurveySubmissionResultsFailure(err.message));
      });
  };

const fetchNpsSurveyReportDataRequest = () => ({
  type: FETCH_NPS_SURVEY_REPORT_DATA_REQUEST,
});

const fetchNpsSurveyReportDataSuccess = (response: any) => ({
  type: FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS,
  response,
});

const fetchNpsSurveyReportDataFailure = (error: string) => ({
  type: FETCH_NPS_SURVEY_REPORT_DATA_FAILURE,
  error,
});

let fetchNpsSurveyReportDataCancel: () => void | typeof undefined;

export const fetchNpsSurveyReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchNpsSurveyReportDataCancel && fetchNpsSurveyReportDataCancel();
    dispatch(fetchNpsSurveyReportDataRequest());

    axios
      .get('/api/surveysubmissions/npsreportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchNpsSurveyReportDataCancel = c;
        }),
      })
      .then((result) => dispatch(fetchNpsSurveyReportDataSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchNpsSurveyReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchNpsSurveyReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
