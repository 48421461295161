import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_EQUIPMENT_BLOCKS_SUCCESS,
  FETCH_EQUIPMENT_BLOCKS_FAILURE,
  FETCH_EQUIPMENT_BLOCKS_REQUEST,
  DELETE_EQUIPMENT_BLOCK_FAILURE,
  DELETE_EQUIPMENT_BLOCK_REQUEST,
  DELETE_EQUIPMENT_BLOCK_SUCCESS,
  CREATE_EQUIPMENT_BLOCK_REQUEST,
  UPDATE_EQUIPMENT_BLOCK_FAILURE,
  UPDATE_EQUIPMENT_BLOCK_SUCCESS,
  UPDATE_EQUIPMENT_BLOCK_REQUEST,
  CREATE_EQUIPMENT_BLOCK_FAILURE,
  CREATE_EQUIPMENT_BLOCK_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { NewEquipmentBlock, EquipmentBlock } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchEquipmentBlocksRequest = () => ({
  type: FETCH_EQUIPMENT_BLOCKS_REQUEST,
});

const fetchEquipmentBlocksSuccess = (response: any) => ({
  type: FETCH_EQUIPMENT_BLOCKS_SUCCESS,
  response,
});

const fetchEquipmentBlocksFailure = (error: any) => ({
  type: FETCH_EQUIPMENT_BLOCKS_FAILURE,
  error,
});

export const fetchEquipmentBlocks =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchEquipmentBlocksRequest());
    axios
      .get('/api/equipmentblocks', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchEquipmentBlocksSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchEquipmentBlocksFailure('canceled'));
        else dispatch(fetchEquipmentBlocksFailure(error));
      });
  };

const deleteEquipmentBlockRequest = (id: string) => ({
  type: DELETE_EQUIPMENT_BLOCK_REQUEST,
  id,
});

const deleteEquipmentBlockSuccess = (response: any, id: string) => ({
  type: DELETE_EQUIPMENT_BLOCK_SUCCESS,
  response,
  id,
});

const deleteEquipmentBlockFailure = (error: any) => ({
  type: DELETE_EQUIPMENT_BLOCK_FAILURE,
  error,
});

export const deleteEquipmentBlock =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteEquipmentBlockRequest(id));
    fetch(`/api/equipmentblocks/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteEquipmentBlockSuccess(result, id)))
      .catch((error) => dispatch(deleteEquipmentBlockFailure(error)));
  };

const createEquipmentBlockRequest = (newEquipmentBlock: NewEquipmentBlock) => ({
  type: CREATE_EQUIPMENT_BLOCK_REQUEST,
  newEquipmentBlock,
});

const createEquipmentBlockSuccess = (response: any) => ({
  type: CREATE_EQUIPMENT_BLOCK_SUCCESS,
  response,
});

const createEquipmentBlockFailure = (error: any) => ({
  type: CREATE_EQUIPMENT_BLOCK_FAILURE,
  error,
});

export const createEquipmentBlock =
  (newEquipmentBlock: NewEquipmentBlock) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createEquipmentBlockRequest(newEquipmentBlock));
    return axios
      .post('/api/equipmentblocks', newEquipmentBlock, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createEquipmentBlockSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createEquipmentBlockFailure(err.message));
      });
  };

const updateEquipmentBlockRequest = (id: string, patch: EquipmentBlock) => ({
  type: UPDATE_EQUIPMENT_BLOCK_REQUEST,
  id,
  patch,
});

const updateEquipmentBlockSuccess = (response: any) => ({
  type: UPDATE_EQUIPMENT_BLOCK_SUCCESS,
  response,
});

const updateEquipmentBlockFailure = (error: any) => ({
  type: UPDATE_EQUIPMENT_BLOCK_FAILURE,
  error,
});

export const updateEquipmentBlock =
  (id: string, patch: EquipmentBlock) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateEquipmentBlockRequest(id, patch));
    return axios
      .patch(`/api/equipmentblocks/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateEquipmentBlockSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateEquipmentBlockFailure(err.message));
      });
  };
