import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { fetchProducts } from 'client/actions/products';
import type { ReduxState } from 'client/reducers';

import { GuideReservationInfo } from './GuideReservationInfo';
import { GuideNotificationMail } from './GuideNotificationMail';

export const GuideSettingsFormTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);
  const guideSettings = (organization?.guides || []).find(
    (guide) => guide.id === activeUser?.id
  );
  return (
    <FormTableBox header={t('Others')}>
      <table>
        <tbody>
          <tr>
            <th>{t('Reservation info')}</th>
            <td>
              <GuideReservationInfo
                activeUser={activeUser}
                guideSettings={guideSettings || null}
              />
            </td>
          </tr>
          <tr>
            <th>{t('Automatic notifications')}</th>
            <td>
              <GuideNotificationMail
                activeUser={activeUser}
                guideSettings={guideSettings || null}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FormTableBox>
  );
};
