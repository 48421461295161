import * as React from 'react';
import _uniqueId from 'lodash/uniqueId';

type Props = {
  url: string;
  text: string;
  locale: string;
  size: 'small' | 'large';
};
export const TwitterShareButton = ({ url, text, locale, size }: Props) => {
  const [id] = React.useState<string>(_uniqueId('twttr-'));
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);

  const appendSdkScript = () => {
    const script = document.createElement('script');
    script.id = `twttr-widget-${id}`;
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    script.onload = () => setHasLoaded(true);

    document?.body?.append(script);
  };

  const scriptAlreadyExists = () =>
    document.querySelector(`script#twttr-widget-${id}`) !== null;

  React.useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendSdkScript();
    }
  }, []);
  // TODO : need to change this part to share the one script between all button
  React.useEffect(() => {
    if (hasLoaded) {
      const node = document.getElementById(id);

      if (node) {
        node.innerHTML = '';
      }

      (window as any).twttr.widgets.createShareButton(
        url,
        document.getElementById(id),
        {
          text: text,
          lang: locale,
          size: size,
        }
      );
    }
  }, [hasLoaded, url, text, locale, size]);
  return (
    <div className="c-sns__wrapper">
      <div key={`twttr-tag-${id}`}>
        <div key={id} id={id}></div>
      </div>
    </div>
  );
};
