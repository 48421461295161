import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { Reservation, Account } from 'shared/models/swagger';
import { ReservationSupplementaryInformationUpdateModal } from 'client/pages/ReservationDetails/ReservationSupplementaryInformationUpdateModal';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupHeader,
  TutorialPopupDescription,
} from 'client/components/TutorialPopup/TutorialPopup';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { operationAllowed } from 'shared/models/access';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Props = {
  reservation: Reservation;
  activeUser: Account | null;
  readOnly?: boolean;
};

export const FreeFormatReservationSupplementaryInformation = React.forwardRef<
  Props,
  any
>((props, ref) => {
  const { reservation, activeUser, readOnly } = props;

  const { t } = useTranslation();

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughOrg =
    (reservation.agent_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'AGENT') ||
    (reservation.supplier_side_passthrough_reservation_id &&
      activeUser?.organization_type === 'SUPPLIER');

  const userCanEditSupplierReference =
    !userIsPassthroughOrg &&
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationSupplierReference') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditAgentNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditSupplierNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationSupplierNotes') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
  const userCanViewInternalSupplierNotes = operationAllowed(
    activeUser,
    'write',
    'reservationConfirmation'
  );

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        baseStyles['scroll-target-pane']
      )}
      ref={ref as any}
      id="supplementary"
    >
      <TutorialPopup
        name="supplementary"
        content={
          <TutorialPopupContent>
            <TutorialPopupHeader text={t('Supplementary Information')} />
            <TutorialPopupDescription
              text={t(
                'You can check customer requests and replies from your company from here. Please use internal memo for communications not intended for customers to see.'
              )}
            />
          </TutorialPopupContent>
        }
      />
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Supplementary Information')}
        </div>

        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          {(userCanEditSupplierReference ||
            userCanEditAgentNotes ||
            userCanEditSupplierNotes) && (
            <ReservationSupplementaryInformationUpdateModal
              reservation={reservation}
              activeUser={activeUser}
              trigger={
                <a className={clsx(baseStyles['base-btn'], baseStyles['icon'])}>
                  <img src={editIcon} />
                </a>
              }
            />
          )}
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(pageStyles['page-reservations__desc'])}>
          <p className={clsx(pageStyles['page-reservations__desc__ttl'])}>
            {t('Remarks')}
          </p>
          <TruncatedTextWithSeeMoreButton
            text={reservation.agent_notes ?? ''}
          />
        </div>

        <div className={clsx(pageStyles['page-reservations__desc'])}>
          <p className={clsx(pageStyles['page-reservations__desc__ttl'])}>
            {t('Replies')}
          </p>
          <TruncatedTextWithSeeMoreButton
            text={reservation.supplier_notes ?? ''}
          />
        </div>

        {userCanViewInternalSupplierNotes && (
          <div className={clsx(pageStyles['page-reservations__desc'])}>
            <p className={clsx(pageStyles['page-reservations__desc__ttl'])}>
              {t('Memo')}
            </p>
            <TruncatedTextWithSeeMoreButton
              text={reservation.supplier_internal_notes ?? ''}
            />
          </div>
        )}

        {userCanViewInternalSupplierNotes && (
          <div className={clsx(pageStyles['page-reservations__desc'])}>
            <p className={clsx(pageStyles['page-reservations__desc__ttl'])}>
              {t('Internal Note')}
            </p>
            <TruncatedTextWithSeeMoreButton
              text={reservation.supplier_internal_notes_for_dispatch ?? ''}
            />
          </div>
        )}
      </div>
    </div>
  );
});
