import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  createETicketQrCode,
  updateETicketQrCode,
} from 'client/actions/eTicketQrCode';
import { Button } from 'client/components/Form';
import * as Swagger from 'shared/models/swagger';

import {
  getInitialValues,
  FormValues,
  convertFormValuesToSwagger,
} from './formValues';
import { EditETicketQrCodeForm } from './EditETicketQrCodeForm';

interface Props {
  open: boolean;
  onClose: () => void;
  existingETicketQrCode?: Swagger.ETicketQrCode | null;
}

export const EditETicketQrCodeModal = ({
  open,
  onClose,
  existingETicketQrCode,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialValues = React.useMemo(
    () => getInitialValues(existingETicketQrCode),
    [existingETicketQrCode]
  );

  return (
    <Modal title={t('Edit E-ticket QR Code')} open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          try {
            if (existingETicketQrCode) {
              await dispatch(
                updateETicketQrCode(
                  existingETicketQrCode.id ?? '',
                  convertFormValuesToSwagger(values)
                )
              );
            } else {
              await dispatch(
                createETicketQrCode(convertFormValuesToSwagger(values))
              );
            }
            onClose();
          } catch (e) {
            console.log(e);
          }
        }}
        debug={console.log}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <EditETicketQrCodeForm />
            <Modal.Actions>
              <Button
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
