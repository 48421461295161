import { useTranslation } from 'react-i18next';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationCreateDetails.module.css';
import { Badge } from 'client/components/v3/Common/Badge';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  required?: boolean;
  children?: React.ReactNode;
  tooltipText?: string;
  wrapperClassName?: string;
};

export const FieldWrapper = ({
  error,
  label,
  required,
  children,
  tooltipText,
  wrapperClassName = '',
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles[wrapperClassName]}>
      <div className={styles[`${wrapperClassName}__ttl`]}>
        <p>{label}</p>
        {required && (
          <Badge size="sm" label={t('Required')} color="warning-contained" />
        )}
        {tooltipText && (
          <Tooltip text={tooltipText}>
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        )}
      </div>
      <div className={styles[`${wrapperClassName}__body`]}>
        {children}
        {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
      </div>
    </div>
  );
};
