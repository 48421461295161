/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './PromotionStats.module.css';

interface StatCardProps {
  title: string;
  value: string | number;
  icon: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, value, icon }) => (
  <div className={styles.statCard}>
    <div className={styles.cardHeader}>
      <h3 className={styles.cardTitle}>{title}</h3>
      <img src={icon} alt="" className={styles.cardIcon} />
    </div>
    <div className={styles.cardContent}>
      <span className={styles.statValue}>{value}</span>
      {title === 'sales from promotions' && (
        <span className={styles.currency}>$</span>
      )}
    </div>
  </div>
);

const PromotionStats: React.FC = () => {
  const stats = [
    {
      title: 'sales from promotions',
      value: '1,575',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/dce12c737bccd3101cae5fbc0eeee08987d52915a75a0b7771f73f73e229d5be?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818',
    },
    {
      title: 'ongoing promotions',
      value: '2',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/dce12c737bccd3101cae5fbc0eeee08987d52915a75a0b7771f73f73e229d5be?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818',
    },
    {
      title: 'upcoming promotions',
      value: '4',
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/dce12c737bccd3101cae5fbc0eeee08987d52915a75a0b7771f73f73e229d5be?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818',
    },
  ];

  return (
    <section className={styles.promotionStats}>
      {stats.map((stat, index) => (
        <StatCard key={index} {...stat} />
      ))}
    </section>
  );
};

export default PromotionStats;
