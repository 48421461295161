import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  SET_ACTIVE_RESERVATION_TUTORIAL_POPUP,
  COPY_SITE_TEMPLATE_SUCCESS,
  COPY_SITE_TEMPLATE_FAILURE,
  COPY_SITE_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import type { ReservationTutorialPopup } from 'client/reducers/tutorial';
import type { CopySiteTemplateRequest } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const setActiveReservationTutorialPopup = (
  value: ReservationTutorialPopup
) => ({
  type: SET_ACTIVE_RESERVATION_TUTORIAL_POPUP,
  value,
});

export const advanceReservationTutorialPopup =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    const currentState = getState().tutorial.activeReservationPopup;

    switch (currentState) {
      case 'main':
        dispatch(setActiveReservationTutorialPopup('detail'));
        window.location.href = '#detail';
        return;
      case 'detail':
        dispatch(setActiveReservationTutorialPopup('related'));
        window.location.href = '#related';
        return;
      case 'related':
        dispatch(setActiveReservationTutorialPopup('supplementary'));
        window.location.href = '#supplementary';
        return;
      case 'supplementary':
        dispatch(setActiveReservationTutorialPopup('complete'));
        return;
    }
  };

type CopySiteTemplateParams = CopySiteTemplateRequest['params'];

const copySiteTemplateRequest = () => ({
  type: COPY_SITE_TEMPLATE_REQUEST,
});

const copySiteTemplateSuccess = () => ({
  type: COPY_SITE_TEMPLATE_SUCCESS,
});

const copySiteTemplateFailure = (error: any) => ({
  type: COPY_SITE_TEMPLATE_FAILURE,
  error,
});

export const copySiteTemplate =
  (req: CopySiteTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(copySiteTemplateRequest());
    axios
      .post('/api/tutorial/copytemplate', req, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => dispatch(copySiteTemplateSuccess()))
      .catch((error) => dispatch(copySiteTemplateFailure(error)));
  };

const fetchTemplateSuppliersSettingsRequest = () => ({
  type: FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST,
});

const fetchTemplateSuppliersSettingsSuccess = (response: any) => ({
  type: FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS,
  response,
});

const fetchTemplateSuppliersSettingsFailure = (error: any) => ({
  type: FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE,
  error,
});

const fetchTemplateSuppliersSettingsCanceled = () => ({
  type: FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED,
});

let fetchTemplateSuppliersSettingsCancel: () => void | typeof undefined;

export const fetchTemplateSuppliersSettings =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchTemplateSuppliersSettingsCancel &&
      fetchTemplateSuppliersSettingsCancel();
    dispatch(fetchTemplateSuppliersSettingsRequest());
    axios
      .get('/api/tutorial/templatesettings', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchTemplateSuppliersSettingsCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchTemplateSuppliersSettingsSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchTemplateSuppliersSettingsCanceled());
        else dispatch(fetchTemplateSuppliersSettingsFailure(error));
      });
  };
