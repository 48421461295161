import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { calculateRebookFromReservationFares } from 'client/actions/fares';
import { currency } from 'shared/libraries/currency';
import { MoneyInput } from 'client/components/MoneyInput/MoneyInput';
import { ReduxState } from 'client/reducers';
import type { NewReservation, Reservation } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  rebookFromReservation: Reservation;
  amountToPayOnBoard?: string;
  onChangeAmountToPayOnBoard?: (arg0: string) => void;
};
export const PaymentForChangeReservation = ({
  rebookFromReservation,
  amountToPayOnBoard,
  onChangeAmountToPayOnBoard,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );
  const rebookFromReservationBillingInfo = useSelector(
    (state: ReduxState) => state.fares.rebookFromReservationBillingInfo
  );
  React.useEffect(() => {
    if (!rebookFromReservation?.billing_info?.amount_gross) {
      dispatch(
        calculateRebookFromReservationFares({
          ...(rebookFromReservation as any as NewReservation),
          agent_reference: 'dummy-reference-payment',
        })
      );
    }
  }, [rebookFromReservation]);

  if (
    !billingInfo ||
    !(
      rebookFromReservation.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo
    )
  ) {
    return null;
  }

  const currencyCode = (billingInfo.amount_gross || '').substring(0, 3);
  const amountGrossOfNewReservation = billingInfo?.amount_gross;
  const amountPaidOfRebookedFromReservation =
    rebookFromReservation?.billing_info?.amount_pay_in_advance ||
    currencyCode + '0';
  return (
    <div className={clsx(componentStyles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th className={clsx(baseStyles['base-t-32'])}>
              {t('Paid in advance')}
            </th>
            <td className={clsx(baseStyles['base-t-128'])}>
              {amountPaidOfRebookedFromReservation}
            </td>
          </tr>
          <tr>
            <th className={clsx(baseStyles['base-t-32'])}>
              {t('Paid on board')}
            </th>
            <td className={clsx(baseStyles['base-t-128'])}>
              <MoneyInput
                currencyCode={currencyCode}
                moneyAmount={currencyCode + amountToPayOnBoard || ''}
                onChange={(value) => {
                  if (onChangeAmountToPayOnBoard) {
                    onChangeAmountToPayOnBoard(value.substring(3));
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <th className={clsx(baseStyles['base-t-32'])}>
              {t('Additional payment')}
            </th>
            <td className={clsx(baseStyles['base-t-128'])}>
              {currency(amountGrossOfNewReservation ?? '')
                .subtract(amountPaidOfRebookedFromReservation)
                .subtract(currencyCode + amountToPayOnBoard)
                .format()}
            </td>
          </tr>
          <tr>
            <th className={clsx(baseStyles['base-t-32'])}>{t('Total')}</th>
            <td className={clsx(baseStyles['base-t-128'])}>
              {currency(amountGrossOfNewReservation ?? '').format()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
