import * as React from 'react';
import clsx from 'clsx';

import closeIcon from 'client/images/ic_close.svg';

import styles from './SeatEditor.module.css';

interface CellProps {
  i: number;
  j: number;
  isSelected: boolean;
  isClosed?: boolean;
  cellBoarderProperty?: {
    borderTop: boolean;
    borderLeft: boolean;
    borderRight: boolean;
    borderBottom: boolean;
  };
  size: {
    width: number;
    height: number;
  };
  span: {
    columnSpan: number;
    rowSpan: number;
    isSkipped: boolean;
  };
  label: string;
  color: string;

  activateDragOver: boolean;
  activateMouseOver: boolean;
  activateClick: boolean;
  activateDrop: boolean;

  onCellClick: () => void;
  onDraggingOverPositionChange: (position: {
    column: number;
    row: number;
  }) => void;
  onDropInstance: () => void;
  onPopupPositionChange: (arg: { left: number; top: number } | null) => void;
}

export const Cell = ({
  i,
  j,
  isSelected,
  isClosed,
  cellBoarderProperty,
  size,
  span,
  label,
  color,

  activateDragOver,
  activateMouseOver,
  activateClick,
  activateDrop,

  onCellClick,
  onDraggingOverPositionChange,
  onDropInstance,
  onPopupPositionChange,
}: CellProps) => {
  const ref = React.useRef<HTMLLIElement>(null);
  const pos = ref?.current?.getBoundingClientRect();
  return (
    <li
      ref={ref}
      className={clsx(
        isSelected ? styles['select'] : '',
        cellBoarderProperty?.borderTop ? styles['border__top'] : '',
        cellBoarderProperty?.borderLeft ? styles['border__left'] : '',
        cellBoarderProperty?.borderRight ? styles['border__right'] : '',
        cellBoarderProperty?.borderBottom ? styles['border__bottom'] : ''
      )}
      style={{
        width: size.width * span.columnSpan,
        height: size.height * span.rowSpan,
        gridColumn: `${j + 1} / span ${span.columnSpan ?? 1}`,
        gridRow: `${i + 1} / span ${span.rowSpan ?? 1}`,
        backgroundColor: color,
      }}
      onDragOver={(e) => {
        if (activateDragOver) {
          onDraggingOverPositionChange({ row: i, column: j });
          e.preventDefault();
        }
      }}
      onMouseOver={(event) => {
        if (activateMouseOver) {
          event.currentTarget.style.cursor = 'pointer';
        }
      }}
      onClick={() => {
        if (activateClick) {
          onPopupPositionChange({
            left: pos?.left ?? 0,
            top: pos?.top ?? 0,
          });
          onCellClick();
        }
      }}
      onDrop={(e) => {
        if (activateDrop) {
          onDropInstance();
        }
        e.preventDefault();
      }}
    >
      {isClosed && <img src={closeIcon} alt="closed" />}
      {!isClosed && <div className={styles['label']}>{label}</div>}
    </li>
  );
};
