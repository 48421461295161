import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

interface Props {
  text: string;
  onClick?: () => void;
}

export const CopyToClipboardButton = ({ text, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip text={t('Copy product page URL to clipboard')}>
      <Button
        color="white"
        size="icon"
        onClick={() => {
          onClick?.();
          navigator.clipboard.writeText(text);
          // setShowTooltip(false);
        }}
        type="button"
        iconAfterText={<i className="c-icon-outline-general-copy-02"></i>}
      />
    </Tooltip>
  );
};
