import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import { FieldWrapper, Button } from 'client/components/Form';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { updateGuideSettings } from 'client/actions/guideSettings';
import type {
  Account,
  GuideSettings,
  ReservationColumn,
} from 'shared/models/swagger';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
  trigger: React.ReactElement<'a' | 'button'>;
};
export const EditGuideReservationInfoModal = ({
  activeUser,
  guideSettings,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<ReservationColumn[]>(
    guideSettings?.columns || []
  );
  const allManifestColumns = useAllManifestColumns();
  React.useEffect(() => {
    setColumns(guideSettings?.columns || []);
  }, [guideSettings]);
  const title = t('Edit reservation info');
  return (
    <Modal
      title={title}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
      trigger={trigger}
    >
      <Modal.Content>
        <Modal.Box>
          <FieldWrapper label={t('Columns')}>
            <ToggleableDndList
              selectedItems={columns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
              setSelectedItems={(selectedItems) =>
                setColumns(selectedItems.map((i) => i.key))
              }
              candidateItems={allManifestColumns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
            />
          </FieldWrapper>
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            dispatch(
              updateGuideSettings(activeUser?.id ?? '', {
                columns,
              })
            );
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
