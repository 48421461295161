import * as React from 'react';

// PageHeaderOverrideContext can be used to override the category title for the page based on specific content.
export const PageHeaderOverrideContext = React.createContext<{
  headerOverride: string;
  setHeaderOverride: (s: string) => void;
}>({
  headerOverride: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHeaderOverride: () => {},
});
