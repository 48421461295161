import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

export const useOnSiteAnalyticsCustomer = ():
  | Swagger.AnalyticsCustomer
  | undefined => {
  const { id } = useParams<{ id: string }>();
  const allCustomers = useSelector(
    (state: ReduxState) => state.onSiteAnalyticsCustomers.all
  );
  return allCustomers.find((c) => c.id === id);
};
