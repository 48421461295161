import _ from 'lodash';
import moment from 'moment-timezone';

import { ManifestReservationShape } from './manifestReservationShape';

export const groupReservationsByTransportRouteStartTime = (
  reservations: ManifestReservationShape[]
): {
  startTime: string;
  date: string;
  reservations: ManifestReservationShape[];
}[] => {
  const transportRouteGroups = _.groupBy(
    reservations,
    (reservation: ManifestReservationShape) => {
      if ((reservation.transport_route ?? []).length === 0) {
        return '';
      }
      const isPickupSameAsTransportDeparture =
        reservation.pickup_checkin_location ===
          reservation.transport_route?.[0].location_from?.location_name &&
        ((!reservation.pickup_checkin_time &&
          !reservation.transport_route?.[0].location_from?.date_time_utc) ||
          reservation.pickup_checkin_time?.clone().utc().format() ===
            moment(
              reservation.transport_route?.[0].location_from?.date_time_utc
            )
              .utc()
              .format());

      if (isPickupSameAsTransportDeparture) {
        return `arrival:${
          reservation.transport_route?.[0].location_to?.location_name ?? ''
        }:${reservation.transport_route?.[0].location_to?.date_time_utc}`;
      }

      return `departure:${
        reservation.transport_route?.[0].location_from?.location_name ?? ''
      }:${reservation.transport_route?.[0].location_from?.date_time_utc}`;
    }
  );

  return _.sortBy(
    Object.values(transportRouteGroups).map((reservations) => {
      const earliestDepartureDateTimeUtc =
        getEarliestDepartureDateTimeUtc(reservations);

      return {
        startTime: moment
          .tz(
            earliestDepartureDateTimeUtc,
            reservations[0].start_timezone ?? ''
          )
          .format('HH:mm'),
        date: moment
          .tz(
            earliestDepartureDateTimeUtc,
            reservations[0].start_timezone ?? ''
          )
          .format('YYYY-MM-DD'),
        reservations,
      };
    }),
    (i) => i.startTime
  );
};

const getEarliestDepartureDateTimeUtc = (
  reservations: ManifestReservationShape[]
): string => {
  const departureTimesUtc = reservations
    .map((reservation) =>
      (reservation.transport_route ?? []).length > 0
        ? reservation.transport_route?.[0].location_from?.date_time_utc ?? ''
        : ''
    )
    .filter((dateTimeUtc) => Boolean(dateTimeUtc));

  if (departureTimesUtc.length === 0) {
    return '-';
  }

  return departureTimesUtc.sort()[0];
};
