import { useTranslation } from 'react-i18next';

import { getReservationCSVColumns } from 'client/pages/v3/Reservation/ReservationDataDownload/utils';

type Props = {
  appliedDownloadItems: string[];
};
export const DownloadItemsDisplayBox = ({ appliedDownloadItems }: Props) => {
  const { t } = useTranslation();
  const allColumns = getReservationCSVColumns(t);
  const downloadItems = appliedDownloadItems.map((item) => {
    return allColumns.find((col) => col.id === item)?.Header;
  });
  //return (
  //  <div>
  //    <p>{downloadItems.join(', ')}</p>
  //  </div>
  //);
  return <p style={{ marginRight: '12px' }}>{downloadItems.join(', ')}</p>;
};
