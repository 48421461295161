import * as React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Input } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import type { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './DraggableStubInputForm.module.css';

type Props = {
  name: string;
  idx: number;
  translationTargetLanguage: SourceLanguage | null;
  onRemoveClick: () => void;
  onEditClick: () => void;
};
export const StubInputForm = ({
  name,
  idx,
  translationTargetLanguage,
  onRemoveClick,
  onEditClick,
}: Props) => {
  const formState = useFormState();
  const showEditButton =
    ((formState?.values?.stubs[idx]?.options?.length ?? 0) > 0 ||
      formState?.values?.shouldUseRedemptionCount) &&
    !formState?.values?.shouldUseCountGuestsWithGuestType;

  return (
    <TranslatedField name={`${name}.text`}>
      {({ input, translationInput }) => (
        <>
          <td>
            <div
              className={clsx(
                baseStyles['base-flex'],
                styles['input'],
                translationTargetLanguage
                  ? styles['input__source_language']
                  : null
              )}
            >
              <Input {...input} />
              <div className={baseStyles['base-flex']}>
                <DeleteIcon onClick={() => onRemoveClick()} />
                {showEditButton && (
                  <EditIcon
                    onClick={() => {
                      onEditClick();
                    }}
                  />
                )}
              </div>
            </div>
          </td>
          {translationTargetLanguage && (
            <td>
              <div
                className={clsx(
                  styles['input'],
                  translationTargetLanguage
                    ? styles['input__translation_language']
                    : null
                )}
              >
                <Input {...translationInput} />
              </div>
            </td>
          )}
        </>
      )}
    </TranslatedField>
  );
};
