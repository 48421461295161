import * as React from 'react';

export const formatTextWithLineBreaks = (text: string) => {
  const lines = text
    .replace(/\u3000/g, ' ')
    .replace(/\s+/g, ' ')
    .split('\\n');
  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ));
};
