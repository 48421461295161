import * as React from 'react';
import { Field, useForm } from 'react-final-form';

import { TemplateCard } from './TemplateCard';
import styles from './TemplateSelect.module.css';
import { useTemplateOptions } from './useTemplateOptions';

export const TemplateSelect = () => {
  const templateOptions = useTemplateOptions();

  const form = useForm();

  return (
    <div>
      <Field name="selectedTemplate">
        {({ input }) => (
          <>
            <div className={styles['template-card-row']}>
              {templateOptions.map((option) => (
                <div
                  key={option.value}
                  className={styles['template-card-container']}
                >
                  <TemplateCard
                    title={option.title}
                    templateType={option.value}
                    description={option.description}
                    onSelect={() => {
                      input.onChange(option.value);
                      form.change(
                        'templateValues',
                        option.defaultTemplateValues
                      );
                    }}
                    onDeselect={() => {
                      input.onChange('');
                      form.change('templateValues', {});
                    }}
                    selected={input.value === option.value}
                    thumbnailUrl={option.thumbnail}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Field>
    </div>
  );
};
