import type { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { manifestSettingsSelector } from 'client/reducers/manifestSettings';
import type { ReduxState } from 'client/reducers';
import type {
  ManifestProductGroup,
  ManifestSettings$Patch,
  ManifestViewType,
  ManifestCustomizedColumnName,
  ManifestAlternateNameSettings,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateManifestSettings =
  (patch: ManifestSettings$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const currentManifestSettings = manifestSettingsSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        manifest_settings: { ...currentManifestSettings, ...patch },
      })
    );
  };
export const updateManifestCustomViews = (views: ManifestViewType[]) =>
  updateManifestSettings({
    manifest_view_types: views,
  });
export const updateManifestProductGroups = (
  productGroups: ManifestProductGroup[]
) =>
  updateManifestSettings({
    manifest_product_groups: productGroups,
  });
export const updateManifestExcludedFormFields = (
  excludedFormFields: string[]
) =>
  updateManifestSettings({
    excluded_form_field_keys: excludedFormFields,
  });
export const updateManifestCustomizedColumnNames = (
  customizedColumnNames: ManifestCustomizedColumnName[]
) =>
  updateManifestSettings({
    customized_column_names: customizedColumnNames,
  });
export const updateManifestAlternateNameSettings = (
  alternateNameSettings: ManifestAlternateNameSettings
) =>
  updateManifestSettings({
    alternate_name_settings: alternateNameSettings,
  });
