import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import baseStyles from 'client/v3-base.module.css';
import { Section } from 'client/pages/v3/Product/ProductEdit/Menu/menuType';
import useScroll from 'client/hooks/useScroll';

import styles from './SpMenu.module.css';

const findSectionBySubsectionId = (
  sections: Section[],
  subsectionId: string | undefined
): Section | undefined => {
  if (!subsectionId) {
    return undefined;
  }
  for (const section of sections) {
    const foundSubsection = section.subsections?.find(
      (subsection) => subsection.id === subsectionId
    );
    if (foundSubsection) {
      return section;
    }
  }
  return undefined;
};

type Props = {
  sections: Section[];
  currentSectionId?: string;
};

export const SpMenu = ({ sections, currentSectionId }: Props) => {
  const ulListRef = useRef<HTMLUListElement | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>('basic');
  const [activeSubsection, setActiveSubsection] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isScrolled = useScroll();

  useEffect(() => {
    const currentSection = findSectionBySubsectionId(
      sections,
      currentSectionId
    );
    if (currentSection) {
      setActiveSection(currentSection.id);
      setActiveSubsection(
        currentSection?.subsections?.find(
          (subsection) => subsection.id === currentSectionId
        )?.id ?? ''
      );
    }
  }, [currentSectionId]);

  const handleSubsectionClick = (subsectionId: string) => {
    setActiveSubsection(subsectionId);
    const sectionElement = document.getElementById(subsectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    setShowMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !ulListRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div
      className={clsx(
        styles['p-products__spMenu'],
        baseStyles['u-pcHidden'],
        isScrolled ? styles['is-scrolled'] : ''
      )}
    >
      <div className={styles['p-products__spMenu__inner']}>
        <fieldset
          className={clsx(styles['c-select'], showMenu && styles['is-active'])}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className={styles['c-select__body']}>
            <label>
              <div className={styles['c-select__body__selected']}>
                <p>
                  {
                    findSectionBySubsectionId(
                      sections,
                      activeSubsection ?? ''
                    )?.subsections?.find(
                      (subsection) => subsection.id === activeSubsection
                    )?.title
                  }
                </p>
              </div>
            </label>
          </div>
        </fieldset>
        <ul
          ref={ulListRef}
          className={clsx(
            styles['c-select__menu'],
            showMenu ? styles['is-active'] : ''
          )}
        >
          {sections.map((section, index) => {
            if (!section.hasPermission) {
              return null;
            }

            const isSectionActive = activeSection === section.id;

            return (
              <li
                key={index}
                className={clsx(
                  activeSection === section.id ? styles['is-active'] : '',
                  styles['c-select__menu__item'],
                  styles['p-products__spMenu__sectionList__item']
                )}
              >
                <p
                  onClick={() => {
                    setActiveSection(section.id);
                  }}
                >
                  {section.title}
                </p>

                {section.subsections && isSectionActive && (
                  <ul className={styles['p-products__spMenu__subsectionList']}>
                    {section.subsections.map((subsection, subIndex) => {
                      if (!subsection.hasPermission) {
                        return null;
                      }

                      return (
                        <li
                          key={subIndex}
                          className={clsx(
                            styles['p-products__spMenu__subsectionList__item']
                          )}
                          onClick={() => {
                            handleSubsectionClick(subsection.id);
                          }}
                        >
                          <p>{subsection.title}</p>
                          <input
                            type="radio"
                            name={subsection.id}
                            checked={
                              activeSubsection
                                ? activeSubsection === subsection.id
                                : subsection.id === 'basic'
                            }
                            readOnly
                          />
                          <i></i>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
