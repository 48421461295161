import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import styles from 'client/components/v3/Form/FieldWrapper/FieldWrapper.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Badge } from 'client/components/v3/Common/Badge';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  minLength?: string;
  maxLength?: string;
  type?: 'text' | 'password';
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  tooltipText?: string;
};

export const FieldWrapper = ({
  error,
  label,
  required,
  children,
  tooltipText,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['c-fieldWrapper']}>
      <div
        className={clsx(
          styles['c-fieldWrapper__header'],
          error ? styles['error'] : ''
        )}
      >
        <p>{label}</p>
        {tooltipText && (
          <Tooltip text={tooltipText}>
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        )}
        {required && (
          <Badge size="sm" label={t('Required')} color="warning-contained" />
        )}
      </div>
      <div className={styles['c-fieldWrapper__body']}>
        {children}
        {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
      </div>
    </div>
  );
};
