import React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import type { ReduxState } from 'client/reducers';
import type { ReservationReportSettings } from 'client/components/ReservationReportSettingsModal/ReservationReportSettingsModal';
import type {
  TargetType,
  AggregationType,
} from 'client/libraries/util/reservationReport';
import { Button, Select, FieldWrapper, Radio } from 'client/components/Form';
import { Loading } from 'client/pages/Loading';
import {
  ReservationReportSettingsModal,
  initialSettings,
} from 'client/components/ReservationReportSettingsModal/ReservationReportSettingsModal';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  convertReservationReportDataSetsToGraphData,
  convertReservationReportDataSetsToTableData,
  getProductIds,
  getBookingSourceIds,
  targetTypes,
  getTargetTypeText,
  getCompareFieldName,
} from 'client/libraries/util/reservationReport';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { fetchProducts } from 'client/actions/products';
import { fetchReservationReportData } from 'client/actions/reservations';
import { summariesSelector } from 'client/reducers/products';
import { setLastReservationReportSettings } from 'client/actions/reservationReportSettings';
import { isTouchBackend } from 'client/libraries/util/isTouchBackend';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { operationAllowed } from 'shared/models/access';
import baseStyles from 'client/base.module.css';

import styles from './ReservationReport.module.css';
import { BarChartPane } from './BarChartPane';
import { LineChartPane } from './LineChartPane';
import { ReservationReportSettingsDisplayBox } from './ReservationReportSettingsDisplayBox';
import { TablePane } from './TablePane';
import { convertReservationReportSettingsToQueryParams } from './utils';
import { TotalTablePane } from './TotalTablePane';
import { CompareTablePane } from './CompareTablePane';

export const ReservationReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [aggregationType, setAggregationType] =
    React.useState<AggregationType>('TOTAL');
  const [targetType, setTargetType] =
    React.useState<TargetType>('AMOUNT_GROSS');
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const fetchReservationReportDataStatus = useSelector(
    (state: ReduxState) => state.reservations.fetchReservationReportDataStatus
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const lastReservationReportSettings = useSelector(
    (state: ReduxState) =>
      state.reservationReportSettings.lastReservationReportSettings
  );
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const products = useSelector(summariesSelector);
  const reportData = useSelector(
    (state: ReduxState) => state.reservations.reportData
  );
  const [reservationReportSettings, setReservationReportSettings] =
    React.useState<ReservationReportSettings>(lastReservationReportSettings);
  const keys = React.useMemo(() => {
    if (reportData.length > 1) {
      return reportData.map((dataSet, idx) => {
        return getCompareFieldName(dataSet, idx);
      });
    } else {
      switch (aggregationType) {
        case 'PRODUCT':
          return getProductIds(reportData);

        case 'BOOKING_SOURCE':
          return getBookingSourceIds(reportData);

        case 'TOTAL':
        default:
          return ['total'];
      }
    }
  }, [reportData, aggregationType]);
  const names = React.useMemo(() => {
    if (reportData.length > 1) {
      return reportData.reduce((acc, dataSet, idx) => {
        const fieldName = getCompareFieldName(dataSet, idx);

        if (fieldName) {
          acc[fieldName] = fieldName;
        }

        return acc;
      }, {});
    } else {
      switch (aggregationType) {
        case 'PRODUCT':
          return products.reduce((acc, product) => {
            (acc as any)[product.id] = getDisplayProductName(product);
            return acc;
          }, {});

        case 'BOOKING_SOURCE':
          return ((reportData[0] || {}).items || []).reduce(
            (acc: any, data: any) => {
              if (data.booking_source?.agent_id) {
                acc[data.booking_source?.agent_id] =
                  data.booking_source?.agent_name || '';
              } else {
                const sourceType =
                  data.booking_source?.source_type || 'NO BOOKING SOURCE';
                acc[sourceType] = t(sourceType);
              }

              return acc;
            },
            {}
          );

        case 'TOTAL':
        default:
          return {
            total: t('total'),
          };
      }
    }
  }, [reportData, aggregationType, products]);
  const graphData = React.useMemo(() => {
    return convertReservationReportDataSetsToGraphData(
      reportData,
      aggregationType,
      targetType
    );
  }, [reportData, aggregationType, targetType]);
  const tableData = React.useMemo(() => {
    return convertReservationReportDataSetsToTableData(
      reportData,
      aggregationType,
      names
    );
  }, [reportData, aggregationType, names]);
  const reservationReportSettingsForDisplay = React.useMemo(() => {
    return reservationReportSettings;
  }, [reportData]);
  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(fetchProducts());
    }
  }, [locale]);
  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchReservationReportData(
          convertReservationReportSettingsToQueryParams(
            reservationReportSettings
          )
        )
      );
      dispatch(fetchProducts());
    }
  }, [isNutmegAdmin]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  const reset = () => {
    setReservationReportSettings(initialSettings);
  };

  const search = () => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchReservationReportData(
          convertReservationReportSettingsToQueryParams(
            reservationReportSettings
          )
        )
      );
      dispatch(setLastReservationReportSettings(reservationReportSettings));
    }
  };

  const targetTypeOptions = targetTypes.map((targetType) => {
    return {
      value: targetType,
      text: t(getTargetTypeText(targetType)),
    };
  });
  const graphPaneHeight = isTouchBackend()
    ? 500 + 16 * (Object.keys(names) || []).length
    : 600;
  const currencyCode = activeUserOrganization?.default_currency || null;
  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="RESERVATION_REPORTS" />
      )}
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1']
          )}
        >
          <ReservationReportSettingsModal
            onReset={reset}
            onSearch={search}
            reservationReportSettings={reservationReportSettings}
            setReservationReportSettings={(condition) =>
              setReservationReportSettings(condition)
            }
            locale={locale}
            trigger={<Button.Transition content={<>{t('Report Setting')}</>} />}
          />
        </div>
      </div>

      <ReservationReportSettingsDisplayBox
        reservationReportSettings={reservationReportSettingsForDisplay}
        fetchReservationReportDataStatus={fetchReservationReportDataStatus}
      />

      <div className={styles['space']} />

      <div className={styles['main-content']}>
        <div className={styles['form-pane']}>
          <div className={styles['form-pane-type-select']}>
            <Select
              label={t('Show on chart')}
              onChange={(e, { value }) => setTargetType(value as TargetType)}
              options={targetTypeOptions}
              value={targetType}
            />
          </div>
          {reportData?.length === 1 && (
            <>
              <div>
                <FieldWrapper label={t('Display')}>
                  <div className={styles['horizontal-radio-button']}>
                    <Radio
                      label={t('Total')}
                      checked={aggregationType === 'TOTAL'}
                      onChange={() => {
                        setAggregationType('TOTAL');
                      }}
                    />
                    <Radio
                      label={t('By product')}
                      checked={aggregationType === 'PRODUCT'}
                      onChange={() => {
                        setAggregationType('PRODUCT');
                      }}
                    />
                    <Radio
                      label={t('By booking source')}
                      checked={aggregationType === 'BOOKING_SOURCE'}
                      onChange={() => {
                        setAggregationType('BOOKING_SOURCE');
                      }}
                    />
                  </div>
                </FieldWrapper>
              </div>
            </>
          )}
        </div>

        <div className={styles['currency-code-pane']}>
          <p>
            {t('Currency Code')} : {currencyCode}
          </p>
        </div>

        <div
          className={styles['graph-pane-wrapper']}
          style={{
            height: graphPaneHeight,
          }}
        >
          {reportData?.length === 1 && (
            <BarChartPane
              data={graphData}
              keys={keys}
              names={names}
              currencyCode={currencyCode}
            />
          )}
          {reportData?.length === 2 && (
            <LineChartPane
              data={graphData}
              keys={keys}
              names={names}
              currencyCode={currencyCode}
            />
          )}
        </div>

        <div className={styles['space']} />

        <div className={styles['summary-pane-wrapper']}>
          {reportData?.length === 1 && (
            <>
              <p>{t('Total')}</p>
              <TotalTablePane data={tableData} currencyCode={currencyCode} />
            </>
          )}
          {reportData?.length === 2 && (
            <>
              <p>{t('Compare')}</p>
              <CompareTablePane data={tableData} currencyCode={currencyCode} />
            </>
          )}
        </div>

        {reportData?.length === 1 && (
          <>
            <div className={styles['space']} />

            <div>
              <p>{t('Details')}</p>
              <TablePane
                data={tableData}
                aggregationType={aggregationType}
                currencyCode={currencyCode}
              />
            </div>
          </>
        )}
      </div>
      {loading && <Loading />}
    </>
  );
};
