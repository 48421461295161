import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';

export type Weekday = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
export const getWeekdayText = (
  weekday: Weekday,
  t: TranslateFuncType
): string => {
  switch (weekday) {
    case 'SUN':
      return t('Sun');

    case 'MON':
      return t('Mon');

    case 'TUE':
      return t('Tue');

    case 'WED':
      return t('Wed');

    case 'THU':
      return t('Thu');

    case 'FRI':
      return t('Fri');

    case 'SAT':
      return t('Sat');

    default:
      return '';
  }
};
export const getWeekdayListText = (
  weekdays: Weekday[],
  t: TranslateFuncType
): string => {
  if ([...new Set(weekdays)].length === 7) {
    return t('Everyday');
  }

  return weekdays.map((d) => getWeekdayText(d, t)).join(',');
};
export const getWeekdayFromDate = (yyyyMmDd: string): Weekday => {
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  // Moment returns 0 (Sun) to 6 (Sat)
  const dayIdx = moment(yyyyMmDd).day();
  return days[dayIdx] as Weekday;
};
export const getWeekdayFromIndex = (index: number): Weekday => {
  const mod = index % 7;

  switch (mod) {
    case 0:
      return 'SUN';

    case 1:
      return 'MON';

    case 2:
      return 'TUE';

    case 3:
      return 'WED';

    case 4:
      return 'THU';

    case 5:
      return 'FRI';

    case 6:
      return 'SAT';

    default:
      return 'SUN';
  }
};
