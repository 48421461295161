import * as Swagger from 'shared/models/swagger';

export interface AnalyticsCustomerSearchPreset {
  presetName: string;
  conditionGroups: AnalyticsCustomerSearchConditionGroup[];
}

export interface AnalyticsCustomerSearchConditionGroup {
  referralPaths: string[];
  eventTypes: string[];
  url: string;
  dateRangePreset:
    | ''
    | 'LAST_24_HOURS'
    | 'LAST_1_WEEK'
    | 'LAST_4_WEEKS'
    | 'CUSTOM';
  dateRange?: {
    startDate: string;
    endDate: string;
  };
  tags: string[];
  dimensionType:
    | 'NONE'
    | 'PAGE_VIEW_COUNT_GTE'
    | 'PAGE_VIEW_COUNT_LT'
    | 'PAGE_VIEW_COUNT_BETWEEN'
    | 'SESSION_COUNT_GTE'
    | 'SESSION_COUNT_LT'
    | 'SESSION_COUNT_BETWEEN'
    | 'TOTAL_TIME_SECONDS_GTE'
    | 'TOTAL_TIME_SECONDS_LT'
    | 'TOTAL_TIME_SECONDS_BETWEEN';
  dimensionComparisonValue?: {
    lowerBound: string;
    upperBound: string;
  };
}

export interface AnalyticsCustomerSearchPreset {
  presetName: string;
  conditionGroups: AnalyticsCustomerSearchConditionGroup[];
}

type SwaggerPreset = Exclude<
  Swagger.JourneyAnalyticsSettings['presets'],
  undefined
>[0];

export const convertSwaggerPresetToFormValues = (
  preset: SwaggerPreset
): AnalyticsCustomerSearchPreset => {
  return {
    presetName: preset.preset_name ?? '',
    conditionGroups:
      preset.condition_groups?.map((conditionGroup) => ({
        referralPaths: conditionGroup.referral_paths ?? [],
        eventTypes: conditionGroup.event_types ?? [],
        url: conditionGroup.url ?? '',
        dateRangePreset: conditionGroup.date_range_preset ?? '',
        dateRange:
          conditionGroup.date_range_preset === 'CUSTOM' &&
          conditionGroup.date_range
            ? {
                startDate: conditionGroup.date_range.start_date ?? '',
                endDate: conditionGroup.date_range.end_date ?? '',
              }
            : undefined,
        tags: conditionGroup.tags ?? [],
        dimensionType: conditionGroup.dimension_type || 'NONE',
        dimensionComparisonValue: conditionGroup.dimension_comparison_value
          ? {
              lowerBound: conditionGroup.dimension_comparison_value.lower_bound
                ? `${conditionGroup.dimension_comparison_value.lower_bound}`
                : '',
              upperBound: conditionGroup.dimension_comparison_value.upper_bound
                ? `${conditionGroup.dimension_comparison_value.upper_bound}`
                : '',
            }
          : undefined,
      })) ?? [],
  };
};

export const convertFormValuesToSwaggerAnalyticsPreset = (
  formValues: AnalyticsCustomerSearchPreset
): SwaggerPreset => {
  return {
    preset_name: formValues.presetName,
    condition_groups: formValues.conditionGroups.map((conditionGroup) => ({
      referral_paths: conditionGroup.referralPaths,
      event_types: conditionGroup.eventTypes,
      url: conditionGroup.url,
      date_range_preset: conditionGroup.dateRangePreset,
      date_range:
        conditionGroup.dateRangePreset === 'CUSTOM' && conditionGroup.dateRange
          ? {
              start_date: conditionGroup.dateRange.startDate,
              end_date: conditionGroup.dateRange.endDate,
            }
          : undefined,
      tags: conditionGroup.tags,
      dimension_type:
        conditionGroup.dimensionType === 'NONE'
          ? undefined
          : conditionGroup.dimensionType,
      dimension_comparison_value: conditionGroup.dimensionComparisonValue
        ? {
            lower_bound: conditionGroup.dimensionComparisonValue.lowerBound
              ? parseInt(conditionGroup.dimensionComparisonValue.lowerBound)
              : undefined,
            upper_bound: conditionGroup.dimensionComparisonValue.upperBound
              ? parseInt(conditionGroup.dimensionComparisonValue.upperBound)
              : undefined,
          }
        : undefined,
    })),
  };
};
