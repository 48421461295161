import { BadgeColor } from 'client/components/v3/Common/Badge';
import { WaiverInfo } from 'shared/models/swagger';

export const getBadgeColorForWaiverStatus = (
  waiverInfo: WaiverInfo | undefined,
  guestCount: number
): BadgeColor => {
  if (!waiverInfo || waiverInfo.waiver_status === 'NOT_APPLICABLE') {
    return 'gray';
  }

  switch (waiverInfo.waiver_status) {
    case 'UNSIGNED':
      return 'gray';

    case 'SIGNED':
    case 'SIGNED_AND_NOTIFIED':
      if (waiverInfo.participant_completions) {
        if (waiverInfo.participant_completions < guestCount) {
          return 'warning';
        } else {
          return 'success';
        }
      } else {
        return 'success';
      }
  }

  return 'gray';
};
