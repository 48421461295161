import * as React from 'react';

import { RichPlan } from '../util';

import { BaseTemplate } from './BaseTemplate';
import { CustomerGreetingsSection } from './section/CustomerGreetingsSection';
import { PlansSection } from './section/PlansSection';

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  planSections: RichPlan[];
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const SeasonalEvent = ({
  editMode,
  language,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  planSections,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* Customer greetings お客様向けメッセージ */}
      <CustomerGreetingsSection
        data={{
          editMode: editMode ?? false,
          customerName: customerName ?? '',
          customerIntroduction: customerIntroduction,
          introParagraph: introParagraph,
        }}
      />
      {/* Plans 季節のイベントのご案内 */}
      <PlansSection
        data={{
          editMode: editMode ?? false,
          planSections: planSections,
          newsletterCampaignId: newsletterCampaignId ?? '',
          language: language,
        }}
      />
    </BaseTemplate>
  );
};
