import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';

export const MobileOrderSettings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <a href="/mobileorders/kitchen" target="_blank" rel="noreferrer">
        <Button text={t('Kitchen System')} />
      </a>
    </div>
  );
};
