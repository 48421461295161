import * as React from 'react';
import clsx from 'clsx';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { Select, Input } from 'client/components/Form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TranslateFuncType } from 'client/components/Translate';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import { getFieldPresetOptions } from './formValues';
import styles from './CustomerSettings.module.css';

export interface Props {
  idx: number;
  name: string;
  onAddClick: (idx: number) => void;
  onDeleteClick: (idx: number) => void;
}

export const CustomerFormField = ({
  idx,
  name,
  onAddClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation();
  const transparentStyle = {
    backgroundColor: 'transparent',
  };

  const form = useForm();

  const formState = useFormState();

  const formValues = _.get(formState.values, `${name}`);

  const fieldPresetOptions = getFieldPresetOptions(t);

  return (
    <tr>
      <th>
        {t('Form {{index}}', {
          index: idx + 1,
        })}
      </th>
      <td colSpan={2}>
        <ul className={styles['scheds']}>
          <li className={styles['scheds__cell']}>
            <Field name={`${name}.type`}>
              {({ input }) => (
                <div>
                  <div className={styles['detailInfo__ttl']}>
                    <p>{t('Type')}</p>
                    <Tooltip
                      text={t(
                        'Enter the question text to be displayed on the registration screen'
                      )}
                    />
                  </div>
                  <p className={styles['scheds__body']}>
                    <Select
                      maxWidth={800}
                      value={input.value}
                      onChange={(e, { value }) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const selectedPresetFieldValue =
                          fieldPresetOptions.find(
                            (option) => option.type === value
                          );

                        if (selectedPresetFieldValue) {
                          form.change(name, {
                            ...selectedPresetFieldValue,
                            text: undefined,
                          });
                        }
                      }}
                      options={[
                        ...fieldPresetOptions.map((option) => ({
                          value: option.type,
                          text: option.text,
                        })),
                      ]}
                    />
                  </p>
                </div>
              )}
            </Field>
          </li>
          <li className={styles['scheds__cell']}>
            <Field name={`${name}.fieldName`}>
              {({ input, meta: { error, touched } }) => (
                <>
                  <div>
                    <div className={styles['detailInfo__ttl']}>
                      <p>{t('Form Input Name')}</p>
                      <Tooltip
                        text={t(
                          'Enter the question text to be displayed on the registration screen'
                        )}
                      />
                    </div>
                    <p className={styles['scheds__body']}>
                      <Input
                        value={input.value}
                        maxWidth={800}
                        error={touched && error}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    </p>
                  </div>
                </>
              )}
            </Field>
          </li>
          <li>
            <div className={styles['detailInfo__ttl']}>
              <p>{t('Required / Optional')}</p>
            </div>
            <div className={styles['scheds__body']}>
              <EnumRadioButtonGroup
                name={`${name}.required`}
                options={[
                  {
                    value: 'WHEN_SIGN_UP',
                    label: t('Required when sign-up'),
                  },
                  {
                    value: 'OPTIONAL',
                    label: t('Optional'),
                  },
                ]}
                style={transparentStyle}
              />
            </div>
          </li>
          {formValues?.type !== 'ADDRESS' && (
            <li className={styles['scheds__cell']}>
              <Field name={`${name}.responseType`}>
                {({ input: { value } }) => (
                  <div>
                    <div className={styles['detailInfo__ttl']}>
                      <p>{t('Response Constraint')}</p>
                    </div>
                    <div className={styles['scheds__body']}>
                      <EnumRadioButtonGroup
                        name={`${name}.responseType`}
                        options={[
                          {
                            value: 'SELECT_ONE',
                            label: t('Choices'),
                            tooltipText: t('Choose from provided options'),
                          },
                          {
                            value: 'FORMATTED_TEXT',
                            label: t('Text Format'),
                            tooltipText: t(
                              'Input required to be in specified format'
                            ),
                          },
                          {
                            value: 'TEXT',
                            label: t('None'),
                            tooltipText: t('Allow free text entry'),
                          },
                        ]}
                        onChange={() => {
                          form?.change(`${name}.format`, '');
                          form?.change(`${name}.options`, ['']);
                        }}
                        style={transparentStyle}
                      />
                    </div>
                    {value === 'FORMATTED_TEXT' && (
                      <>
                        <p
                          className={clsx(
                            styles['scheds__ttl'],
                            baseStyles['blue']
                          )}
                        >
                          {t('Text Format')}
                        </p>
                        <div className={styles['scheds__body']}>
                          <Field name={`${name}.format`}>
                            {({ input }) => (
                              <Select
                                maxWidth={320}
                                options={getFormatOptions(t)}
                                value={input.value}
                                onChange={(e, { value: newValue }) => {
                                  input.onChange(newValue);
                                }}
                                style={transparentStyle}
                              />
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                    {value === 'SELECT_ONE' && (
                      <>
                        <p
                          className={clsx(
                            styles['scheds__ttl'],
                            styles['blue']
                          )}
                        >
                          {t('Choices')}
                        </p>
                        <ListEditor
                          name={`${name}.options`}
                          style={transparentStyle}
                        />
                      </>
                    )}
                  </div>
                )}
              </Field>
            </li>
          )}
        </ul>
      </td>
      <td className={styles['c-table-list__btns']}>
        <div className={baseStyles['base-flex']}>
          <AddIcon onClick={() => onAddClick(idx)} />
          <DeleteIcon
            onClick={() => {
              onDeleteClick(idx);
            }}
          />
        </div>
      </td>
    </tr>
  );
};

const getFormatOptions = (t: TranslateFuncType) => {
  return [
    {
      value: 'float',
      text: t('Numeric'),
    },
    {
      value: 'yyyy-mm-dd',
      text: t('YYYY-MM-DD'),
    },
  ];
};
