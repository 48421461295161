import { combineReducers } from 'redux';

import { SET_USE_NEW_UI } from 'client/constants/ActionTypes';

// Reducers

const useNewUI = (state = false, action: any) => {
  switch (action.type) {
    case SET_USE_NEW_UI:
      return action.flag;

    default:
      return state;
  }
};

type State = {
  useNewUI: boolean;
};

const reducer = combineReducers<State>({
  useNewUI,
});
export const newUIControls = reducer;
