import type { TranslateFuncType } from 'client/components/Translate';
import { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';

export type ColumnType = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: ((arg0: any) => string) | keyof ManifestReservationShape;
  width?: 'short' | 'middle' | 'long';
  Cell?: (arg0: any) => any;
  th?: boolean;
};

export type ReservationSummaryColumn = ColumnType & {
  HeaderElement?: (arg0: any) => JSX.Element;
};

export const getReservationSummaryTableColumns = (
  t: TranslateFuncType
): ReservationSummaryColumn[] => {
  return [
    {
      Header: t('Product'),
      id: 'product',
      width: 'middle',
    },
    {
      Header: t('Reservations'),
      id: 'num_of_reservations',
      width: 'middle',
    },
    {
      Header: t('Total Pax'),
      id: 'num_of_participants',
      width: 'middle',
    },
    {
      Header: t('Units'),
      id: 'units',
      width: 'middle',
    },
    {
      Header: t('Gross'),
      id: 'gross',
      width: 'middle',
    },
    {
      Header: t('PIF Amount'),
      id: 'pif_amount',
      width: 'middle',
    },
    {
      Header: t('POB Amount'),
      id: 'pob_amount',
      width: 'middle',
    },
  ];
};
