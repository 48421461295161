import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { operationAllowed } from 'shared/models/access';
import { setReservationDataCsvExportOrderVisibleColumns } from 'client/actions/reservationDataCsvExportOrderTableControls';
import {
  getReservationCSVColumns,
  csvColumnCandidatesSelector,
} from 'client/pages/v3/Reservation/ReservationDataDownload/utils';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { Modal } from 'client/components/v3/Form/Modal';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const DownloadItemsModal = ({ setShowModal }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const lastDownloadItems = useSelector(
    (state: ReduxState) =>
      (state as any).reservationDataCsvExportOrderTableControls.visibleColumns
  );
  const [appliedDownloadItems, setAppliedDownloadItems] = useState<string[]>(
    lastDownloadItems ?? []
  );

  const allColumns = getReservationCSVColumns(t);
  const activeUser = useSelector(activeUserSelector);

  const userCanViewInternalSupplierNotes = operationAllowed(
    activeUser,
    'write',
    'reservationConfirmation'
  );

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask
      .filter((c) => {
        if (userCanViewInternalSupplierNotes) {
          return true;
        }
        if (
          [
            'SUPPLIER_INTERNAL_NOTES',
            'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
          ].includes(c)
        ) {
          return false;
        }
        return true;
      })
      .map((c) => allColumns.find((col) => col.id === c) as any);
  };

  const columnCandidates = useSelector((state: ReduxState) =>
    csvColumnCandidatesSelector(state)
  );
  const columnsToShow = getColumns(appliedDownloadItems);
  const allCandidateColumns = getColumns(columnCandidates);
  return (
    <Modal
      title={t('Download Items')}
      open={true}
      onClose={() => setShowModal(false)}
      useCloseButton={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Button
            type="submit"
            text={t('Save')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={async () => {
              if (!isNutmegAdmin) {
                await dispatch(
                  setReservationDataCsvExportOrderVisibleColumns(
                    appliedDownloadItems ?? []
                  )
                );
              }
              setShowModal(false);
            }}
          />
        </>
      }
      style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
    >
      <div style={{ paddingBottom: '10px' }}>
        <a
          style={{ color: '#008ec9', fontSize: '1.1em', fontWeight: 'bold' }}
          onClick={() => {
            setAppliedDownloadItems(allCandidateColumns.map((c) => c.id));
          }}
        >
          {t('Select all')}
        </a>{' '}
        /{' '}
        <a
          style={{ color: '#008ec9', fontSize: '1.1em', fontWeight: 'bold' }}
          onClick={() => {
            setAppliedDownloadItems([]);
          }}
        >
          {t('Clear')}
        </a>
      </div>
      <ToggleableDragAndDropList
        selectedItems={columnsToShow.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        candidateItems={allCandidateColumns.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        setSelectedItems={(items) =>
          setAppliedDownloadItems(items.map((item) => item.key))
        }
        doNotUseOuterBox={true}
      />
    </Modal>
  );
};
