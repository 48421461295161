import * as React from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { config } from 'client/config';
import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import {
  setMonthlyEquipmentAvailabilitySelectedMonth,
  setEquipmentAvailabilityStartDate,
  setEquipmentAvailabilityEndDate,
} from 'client/actions/equipmentCalendarListControls';
import { equipmentCalendarListDatesSelector } from 'client/reducers/equipmentCalendarListControls';
import pageStyles from 'client/pages/pages.module.css';

import { EquipmentInstanceCloseCalendarModal } from './EquipmentInstanceCloseCalendarModal';
import { SeatAvailabilityTable } from './SeatAvailabilityTable';
import { SeatCalendarEditContext } from './SeatCalendarEditContext';
import { SeatCalendarEditButtonRow } from './SeatCalendarEditButtonRow';
import styles from './WeeklySeatAvailability.module.css';

export const MonthlySeatAvailability = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dates = useSelector(equipmentCalendarListDatesSelector);
  const selectedMonth = useSelector(
    (state: ReduxState) =>
      state.equipmentCalendarListControls
        .monthlyEquipmentAvailabilitySelectedMonth
  );

  const [selectedEquipmentInstanceIds, setSelectedEquipmentInstanceIds] =
    React.useState<string[]>([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    React.useState(false);
  const [showCloseCalendarModal, setShowCloseCalendarModal] =
    React.useState(false);
  React.useEffect(() => {
    dispatch(
      setEquipmentAvailabilityStartDate(
        moment(selectedMonth).startOf('month').format('YYYY-MM-DD')
      )
    );
    dispatch(
      setEquipmentAvailabilityEndDate(
        moment(selectedMonth)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
    );
  }, [selectedMonth]);

  return (
    <SeatCalendarEditContext.Provider
      value={{
        selectedEquipmentInstanceIds,
        selectEquipmentInstanceIds: (...ids: any[]) => {
          const idsToAdd = ids.filter(
            (id) => !selectedEquipmentInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedEquipmentInstanceIds([
              ...selectedEquipmentInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectEquipmentInstanceIds: (...ids: any) => {
          setSelectedEquipmentInstanceIds(
            selectedEquipmentInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                dispatch(
                  setMonthlyEquipmentAvailabilitySelectedMonth(
                    moment(selectedMonth).add(-1, 'month').format('YYYY-MM-DD')
                  )
                );
              }}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              {moment(dates[0]).format('YYYY/MM')}
            </Box>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                dispatch(
                  setMonthlyEquipmentAvailabilitySelectedMonth(
                    moment(selectedMonth).add(1, 'months').format('YYYY-MM-DD')
                  )
                );
              }}
            />
          </div>
        </Box>

        {config.enableBatchEditEquipmentInstanceAvailability ? (
          <SeatCalendarEditButtonRow />
        ) : (
          <div
            className={styles['tag-select']}
            style={{
              marginLeft: 'auto',
            }}
          >
            <div className={styles['button-row']}>
              <div className={styles['calendar-edit-btns-pc']}>
                <Box ml={-2} mt={-2} display="flex" flexWrap="wrap">
                  <Box mt={2} ml={2}>
                    <Button
                      style="yellow"
                      size="middle"
                      width={120}
                      onClick={() => {
                        setShowCloseCalendarModal(true);
                      }}
                    >
                      {t('Close calendar')}
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
            <EquipmentInstanceCloseCalendarModal
              open={showCloseCalendarModal}
              onClose={() => setShowCloseCalendarModal(false)}
            />
          </div>
        )}
      </div>

      <SeatAvailabilityTable />
    </SeatCalendarEditContext.Provider>
  );
};
