import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SUPPLIER_SETTINGS_SUCCESS,
  FETCH_SUPPLIER_SETTINGS_FAILURE,
  FETCH_SUPPLIER_SETTINGS_REQUEST,
  PUT_SUPPLIER_SETTINGS_FAILURE,
  PUT_SUPPLIER_SETTINGS_SUCCESS,
  PUT_SUPPLIER_SETTINGS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

type SettingsKey = Exclude<
  Swagger.GetSupplierSettingsResponse['key'],
  undefined
>;
type SettingsData = Exclude<
  Swagger.GetSupplierSettingsResponse['data'],
  undefined
>;

const fetchSupplierSettingsRequest = () => ({
  type: FETCH_SUPPLIER_SETTINGS_REQUEST,
});

const fetchSupplierSettingsSuccess = (response: any) => ({
  type: FETCH_SUPPLIER_SETTINGS_SUCCESS,
  response,
});

const fetchSupplierSettingsFailure = (error: any) => ({
  type: FETCH_SUPPLIER_SETTINGS_FAILURE,
  error,
});

let fetchSupplierSettingsCancel: () => void | undefined;
export const fetchSupplierSettings =
  (key: SettingsKey) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchSupplierSettingsCancel?.();
    dispatch(fetchSupplierSettingsRequest());
    axios
      .get('/api/suppliersettings', {
        params: {
          key,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchSupplierSettingsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchSupplierSettingsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSupplierSettingsFailure('canceled'));
        dispatch(fetchSupplierSettingsFailure(error));
      });
  };
const putSupplierSettingsRequest = (req: {
  key: SettingsKey;
  data: SettingsData;
}) => ({
  type: PUT_SUPPLIER_SETTINGS_REQUEST,
  req,
});

const putSupplierSettingsSuccess = (response: any) => ({
  type: PUT_SUPPLIER_SETTINGS_SUCCESS,
  response,
});

const putSupplierSettingsFailure = (error: any) => ({
  type: PUT_SUPPLIER_SETTINGS_FAILURE,
  error,
});

export const putSupplierSettings =
  (key: SettingsKey, data: SettingsData) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const req = { key, data };
    dispatch(putSupplierSettingsRequest(req));
    return axios
      .put('/api/suppliersettings', req, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(putSupplierSettingsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(putSupplierSettingsFailure(err.message));
      });
  };
