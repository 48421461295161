import * as React from 'react';

import { Input } from 'client/components/Form';

type OwnProps = {
  disabled?: boolean;
  label: string;
  value?: number;
  onChange: (value?: number) => void;
  error?: string;
  disallowNegative?: boolean;
  width?: number;
};

type Props = OwnProps;

export const OptionalIntegerInput = ({
  disabled,
  label,
  value,
  onChange,
  error,
  disallowNegative,
  width,
}: Props) => (
  <Input
    disabled={disabled}
    type="number"
    min={disallowNegative ? 0 : undefined}
    value={`${value}`}
    label={label}
    onKeyDown={(e) => {
      if (disallowNegative && e.key === '-') {
        e.preventDefault();
      }
    }}
    onChange={(e, { value }) => {
      if (value.startsWith('-') && disallowNegative) {
        return;
      }

      let newValue = undefined;

      if (value.length > 0) {
        newValue = parseInt(value, 10);

        if (isNaN(newValue)) {
          newValue = undefined;
        }
      }

      onChange(newValue);
    }}
    error={error}
    width={width}
  />
);
