import moment from 'moment';

import { TranslateFuncType } from 'client/components/Translate';

export interface DateRange {
  start: string;
  end: string;
}

export type DateRangePreset =
  | 'PREV_7_DAYS'
  | 'PREV_28_DAYS'
  | 'PREV_3_MONTHS'
  | 'NEXT_7_DAYS'
  | 'NEXT_28_DAYS'
  | 'NEXT_3_MONTHS';

export const getDateRangePresetOptions = (
  t: TranslateFuncType
): { value: string; text: string }[] => {
  return [
    { value: 'PREV_7_DAYS', text: t('Previous 7 days') },
    { value: 'PREV_28_DAYS', text: t('Previous 28 days') },
    { value: 'PREV_3_MONTHS', text: t('Previous 3 Months') },
    { value: 'NEXT_7_DAYS', text: t('Next 7 days') },
    { value: 'NEXT_28_DAYS', text: t('Next 28 days') },
    { value: 'NEXT_3_MONTHS', text: t('Next 3 Months') },
  ];
};

export const getDateRangeForPreset = (preset: DateRangePreset): DateRange => {
  switch (preset) {
    case 'PREV_7_DAYS':
      return {
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      };
    case 'PREV_28_DAYS':
      return {
        start: moment().subtract(28, 'days').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      };
    case 'PREV_3_MONTHS':
      return {
        start: moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      };
    case 'NEXT_7_DAYS':
      return {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
    case 'NEXT_28_DAYS':
      return {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(28, 'days').format('YYYY-MM-DD'),
      };
    case 'NEXT_3_MONTHS':
      return {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(3, 'months').format('YYYY-MM-DD'),
      };
  }
};

export const inferDateRangePresetFromDateRange = (
  dateRange: DateRange
): DateRangePreset | null => {
  for (const preset of [
    'PREV_7_DAYS',
    'PREV_28_DAYS',
    'PREV_3_MONTHS',
    'NEXT_7_DAYS',
    'NEXT_28_DAYS',
    'NEXT_3_MONTHS',
  ] as DateRangePreset[]) {
    const presetDateRange = getDateRangeForPreset(preset);

    if (
      presetDateRange.start === dateRange.start &&
      presetDateRange.end === dateRange.end
    ) {
      return preset;
    }
  }

  return null;
};
