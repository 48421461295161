import * as React from 'react';

import { getBookingWidgetEmbeddedCalendarUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCalendarUrl';

import './EmbeddedCalendar.module.css';

type Props = {
  apiKey: string | null;
  productId: string;
  designType: 'BASIC' | 'BASIC_WITH_PRICES';
  primaryColor?: string;
  secondaryColor?: string;
  language?: string;
};
export const EmbeddedCalendar = ({
  apiKey,
  productId,
  designType,
  primaryColor,
  secondaryColor,
  language,
}: Props) => {
  React.useEffect(() => {
    if (!(window as any).NutmegCalendar) {
      const script = document.createElement('script');
      script.src = `https://assets.ntmg.com/scripts/falcon-embed/v1/index.js`;
      script.async = true;
      (document as any).body.appendChild(script);
      return () => {
        (document as any).body.removeChild(script);
      };
    }
  }, []);
  const url = getBookingWidgetEmbeddedCalendarUrl(
    apiKey,
    productId,
    designType,
    language,
    primaryColor,
    secondaryColor
  );
  return (
    <div className="snippet-example">
      <div
        id={`nutmeg-calendar-${productId}`}
        className="nutmeg-calendar-enc-div"
      >
        <iframe
          id={`nutmeg-embed-iframe-${productId}`}
          src={url}
          className="nutmeg-calendar"
          title="embedded-calendar"
        />
      </div>
    </div>
  );
};
