import * as React from 'react';
import { FalconUIRenderingContext, Menu } from '@nutmeglabs/falcon-ui';
import { Field, FieldRenderProps } from 'react-final-form';

import { Add } from 'client/components/Icons/Add';

import { getDefaultHeaderMenu } from '../../useTemplateOptions';

import { DraggableMenu } from './DraggableMenu';

export const HeaderMenusEditor = () => {
  const topPageCtx = React.useContext(FalconUIRenderingContext);

  return (
    <div>
      <Field name="headerMenus">
        {({ input }: FieldRenderProps<Menu[]>) => (
          <>
            {input.value?.map?.((menu, idx) => (
              <DraggableMenu
                key={menu.key}
                items={input.value}
                onItemsChange={input.onChange}
                index={idx}
                allowSubmenus={true}
              />
            ))}

            <Add
              onClick={() => {
                input.onChange([
                  ...input.value,
                  getDefaultHeaderMenu(topPageCtx.t),
                ]);
              }}
            />
          </>
        )}
      </Field>
    </div>
  );
};
