import * as React from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Loading } from 'client/pages/Loading/Loading';
import { NewsletterTemplate } from 'shared/components/templates/newsletter/NewsletterTemplate';

interface Props {
  readOnly?: boolean;
}

export const MessageEditor = ({ readOnly }: Props) => {
  const form = useForm();
  const { t } = useTranslation();
  const { selectedTemplate, templateValues } = form.getState().values;

  return (
    <React.Suspense fallback={<Loading />}>
      <div>
        <NewsletterTemplate
          templateValues={templateValues}
          selectedTemplate={selectedTemplate}
          editMode={!readOnly}
          customerName={t('John Nutmeg')}
        />
      </div>
    </React.Suspense>
  );
};
