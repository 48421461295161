import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { parseDuration } from 'client/libraries/util/util';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './DurationInput.module.css';

type Props = {
  value: string;
  onChange: (arg0: string) => void;
  showSignSelect?: boolean;
  required?: boolean;
};

const getDuration = (days: string, hours: string, minutes: string): string => {
  const d = parseInt(days);
  const h = parseInt(hours);
  const m = parseInt(minutes);
  return `${d * 24 + h}:${m < 10 ? `0${m}` : m}`;
};

export const DurationInput = ({
  required,
  showSignSelect,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [sign, setSign] = useState<'NEGATIVE' | 'POSITIVE'>(
    value?.includes('-') ? 'NEGATIVE' : 'POSITIVE'
  );
  useEffect(() => {
    const newSign = value?.includes('-') ? 'NEGATIVE' : 'POSITIVE';

    if (newSign !== sign) {
      setSign(newSign);
    }
  }, [sign, value]);
  let { hours: rawHours, minutes: rawMinutes } = parseDuration(value);

  if (rawHours < 0) {
    rawHours = -rawHours;
  }

  if (rawMinutes < 0) {
    rawMinutes = -rawMinutes;
  }

  const days = value ? `${Math.floor(rawHours / 24)}` : '';
  const hours = value ? `${rawHours % 24}` : '';
  const minutes = value ? `${rawMinutes}` : '';

  return (
    <div className={styles['c-durationInput']}>
      {showSignSelect && (
        <div className={styles['c-durationInput__sign']}>
          <SingleDropdown
            options={[
              { value: 'NEGATIVE', text: '-' },
              { value: 'POSITIVE', text: '+' },
            ]}
            selectedOption={sign}
            onChange={(value) => {
              const newSign = value as 'NEGATIVE' | 'POSITIVE';
              setSign(newSign);
              onChange(
                newSign === 'NEGATIVE' ? `-${value}` : value.replace('-', '')
              );
            }}
          />
        </div>
      )}
      {/* Select day */}
      <div className={styles['c-durationInput__duration']}>
        <SingleDropdown
          options={[
            ...(!required
              ? [
                  {
                    value: '',
                    text: '-',
                  },
                ]
              : []),
            ..._.times(11).map((d) => ({
              value: d.toString(),
              text: t('{{count}}d', { count: d }),
            })),
          ]}
          selectedOption={days}
          onChange={(value) => {
            if (value === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  value,
                  hours || '0',
                  minutes || '0'
                )}`
              );
            }
          }}
        />
      </div>
      {/* Select hour */}
      <div className={styles['c-durationInput__duration']}>
        <SingleDropdown
          options={[
            ...(!required
              ? [
                  {
                    value: '',
                    text: '-',
                  },
                ]
              : []),
            ..._.times(24).map((h) => ({
              value: h.toString(),
              text: t('{{count}}h', { count: h }),
            })),
          ]}
          selectedOption={hours}
          onChange={(value) => {
            if (value === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  days || '0',
                  value,
                  minutes || '0'
                )}`
              );
            }
          }}
        />
      </div>
      {/* Select minute */}
      <div className={styles['c-durationInput__duration']}>
        <SingleDropdown
          options={[
            ...(!required
              ? [
                  {
                    value: '',
                    text: '-',
                  },
                ]
              : []),
            ..._.times(60).map((min) => ({
              value: min.toString(),
              text: t('{{count}}m', { count: min }),
            })),
          ]}
          selectedOption={minutes}
          onChange={(value) => {
            if (value === '') {
              onChange('');
            } else {
              onChange(
                `${sign === 'NEGATIVE' ? '-' : ''}${getDuration(
                  days || '0',
                  hours || '0',
                  value
                )}`
              );
            }
          }}
        />
      </div>
    </div>
  );
};
