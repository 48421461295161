import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { FieldWrapper } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { ReduxState } from 'client/reducers';
import { TranslateFuncType } from 'client/components/Translate';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import { Inquiry } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

const formattedSendStatus = (status: string, t: TranslateFuncType) => {
  switch (status) {
    case 'delivered':
      return t('Delivered');
    case 'failed':
      return t('Failed');
    default:
      return status;
  }
};

interface Props {
  inquiry: Inquiry;
}

export const BulkInquiryReservationList = ({ inquiry }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = inquiry?.bulk_send_info?.reservations ?? [];

  return (
    <FieldWrapper
      label={t('Reservations (Total: {{count}})', {
        count: reservations.length,
      })}
    >
      <FormTableBox>
        <table style={{ tableLayout: 'auto' }}>
          <thead>
            <tr>
              <th></th>
              <th>{t('Product Name')}</th>
              <th>{t('Participation')}</th>
              <th>{t('Customer')}</th>
              {inquiry.initial_communication_type ===
                'BULK_SMS_BY_SUPPLIER' && <th>{t('SMS Send Status')}</th>}
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation, idx) => (
              <tr key={idx}>
                <td>
                  <a
                    href={`/reservations/${reservation.reservation_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                    data-text={t('Detail')}
                  >
                    <img src={reservationDetailIcon} />
                  </a>
                </td>
                <td>{reservation.product_name}</td>
                <td>
                  {moment
                    .tz(
                      reservation.start_date_time_utc,
                      reservation.start_timezone ?? ''
                    )
                    .locale(locale)
                    .format('lll')}
                </td>
                <td>{reservation.customer_name}</td>
                {inquiry.initial_communication_type ===
                  'BULK_SMS_BY_SUPPLIER' && (
                  <td>
                    {formattedSendStatus(reservation.send_status ?? '', t)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </FormTableBox>
    </FieldWrapper>
  );
};
