import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import type { ReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type {
  ColumnType,
  ReservationListColumn,
} from 'client/libraries/util/getReservationTableColumns';
import { Button } from 'client/components/v3/Common/Button';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { ColumnSettingsModal } from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/ColumnSettingsModal';
import { ReduxState } from 'client/reducers';

import styles from './ReservationSearchCustomTableControlled.module.css';

type Props = {
  reservations: ReservationSummaryShape[];
  visibleColumns: (ColumnType & ReservationListColumn)[];
  totalHits: number;
  headerElementItems?: Record<string, boolean>;
  rowCount: number;
  onRowCountChange: (rowCount: number) => void;
  currentPage: number;
  onCurrentPageChange: (page: number) => void;

  // TODO: the following properties drilling are needed because we made bulk cancel decline operation as part of the table
  cancelDeclineListCount: number;
  onResetCancelDeclineList: () => void;
  onExecuteCancelDecline: () => void;
};

const calcWidth = (width: string | typeof undefined): string => {
  if (width) return width;
  return 'short';
};

export const ReservationSearchCustomTableControlled = ({
  reservations,
  totalHits,
  visibleColumns,
  headerElementItems,
  rowCount,
  onRowCountChange,
  currentPage,
  onCurrentPageChange,
  cancelDeclineListCount,
  onResetCancelDeclineList,
  onExecuteCancelDecline,
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);

  const bulkCancelLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const rowCountChangeHandler = (count: number) => {
    onRowCountChange(count);
    onCurrentPageChange(1);
  };

  // TODO: remove if not needed (new UIUX design has no page input for pagination)
  // const currentPageHandler = (event: any) => {
  //   let page = parseInt(event.target.value, 10);

  //   if (page < 1) {
  //     page = 1;
  //   }

  //   if (totalHits < rowCount * page) {
  //     page = Math.floor(totalHits / rowCount);
  //   }

  //   onCurrentPageChange(page);
  // };

  const [showColumnSettingsModal, setShowColumnSettingsModal] =
    useState<boolean>(false);

  useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);
  const pageCount =
    Math.floor(totalHits / rowCount) + (totalHits % rowCount ? 1 : 0);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  const [showReservationColumns, setShowReservationColumns] = useState(
    [...Array(totalHits)].map(() => false)
  );

  return (
    <>
      {totalHits == 0 && (
        <section className={styles['g-section']}>
          <div className={styles['p-reservations']}>
            <div className={styles['p-reservations__body']}>
              <p className={styles['p-reservations__empty']}>
                {t(
                  'There is no reservation that matches the specified search conditions'
                )}
              </p>
            </div>
          </div>
        </section>
      )}
      {totalHits != 0 && (
        <section className={styles['g-section']} ref={ref}>
          <div
            className={clsx(
              styles['p-reservations'],
              cancelDeclineListCount != 0 &&
                styles['p-reservations__fixed__footer__margin']
            )}
          >
            <div className={styles['p-reservations__header']}>
              {/* TODO: hide this for now since it's not implemented yet */}
              {/* Filter items: all, not viewed, viewed */}
              {/* <ul className={styles['p-reservations__tab']}>
                <li className={styles['is-active']}>すべて</li>
                <li>未閲覧</li>
                <li>閲覧済み</li>
              </ul> */}
              <div className={styles['p-reservations__actions']}>
                <Button
                  text={t('Edit columns')}
                  size="md"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-layout-columns-03"></i>
                  }
                  onClick={() => setShowColumnSettingsModal(true)}
                />
                {showColumnSettingsModal && (
                  <ColumnSettingsModal
                    setShowModal={setShowColumnSettingsModal}
                  />
                )}
              </div>
            </div>

            <div className={styles['p-reservations__body']}>
              <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
                {/* Table header */}
                <div
                  className={clsx(
                    styles['c-table'],
                    styles['thead'],
                    styles['sticky-top']
                  )}
                  ref={tableHeaderRef}
                >
                  <table className={styles['c-table__body']}>
                    <thead>
                      <tr>
                        {visibleColumns.map(
                          (
                            visibleColumn: ColumnType & ReservationListColumn,
                            idx
                          ) => {
                            const width = calcWidth(visibleColumn.width);
                            if (
                              visibleColumn.Header !== '' &&
                              typeof visibleColumn.HeaderElement === 'undefined'
                            ) {
                              return (
                                <th
                                  className={clsx(
                                    styles['thead__th'],
                                    styles[width as keyof typeof styles]
                                  )}
                                  key={idx}
                                >
                                  {visibleColumn.Header}
                                </th>
                              );
                            } else {
                              // First index special treatment, checkbox for bulk cancel/decline + application number
                              if (idx == 0) {
                                return (
                                  <th
                                    className={clsx(
                                      styles['thead__th'],
                                      styles['sticky-top'],
                                      styles['sticky-left'],
                                      styles[width as keyof typeof styles]
                                    )}
                                    key={idx}
                                  >
                                    {visibleColumn.HeaderElement?.(
                                      headerElementItems
                                    )}
                                  </th>
                                );
                              }
                              return (
                                <th
                                  className={clsx(
                                    styles['thead__th'],
                                    styles[width as keyof typeof styles]
                                  )}
                                  key={idx}
                                >
                                  {visibleColumn.HeaderElement?.(
                                    headerElementItems
                                  )}
                                </th>
                              );
                            }
                          }
                        )}
                      </tr>
                    </thead>
                  </table>
                </div>

                {/* Table body */}
                <div
                  className={clsx(styles['c-table'], styles['tbody'])}
                  onScroll={scrollHandler}
                  ref={tableBodyRef}
                >
                  <table className={styles['c-table__body']}>
                    <tbody>
                      {reservations.map((reservation, ridx) => {
                        return (
                          <tr key={ridx}>
                            {visibleColumns.map((visibleColumn, idx) => {
                              const width = calcWidth(visibleColumn.width);
                              let displayValue;

                              if (typeof visibleColumn.accessor === 'string') {
                                displayValue =
                                  reservation[visibleColumn.accessor];
                              } else if (
                                typeof visibleColumn.accessor === 'function'
                              ) {
                                displayValue =
                                  visibleColumn.accessor(reservation);
                              } else {
                                displayValue = '';
                              }

                              if (visibleColumn.Cell) {
                                const cell = {
                                  value: displayValue,
                                  original: reservation,
                                };
                                displayValue = visibleColumn.Cell(cell);
                              }

                              if (visibleColumn.th) {
                                // First index special treatment, checkbox for bulk cancel/decline + application number
                                if (idx == 0) {
                                  return (
                                    <td
                                      className={clsx(
                                        styles['tbody__td'],
                                        styles['sticky-left'],
                                        styles['c-table__spHeader'],
                                        styles[width as keyof typeof styles]
                                      )}
                                      key={idx}
                                    >
                                      {displayValue}
                                    </td>
                                  );
                                }
                                return (
                                  <td
                                    className={clsx(
                                      styles['tbody__td'],
                                      styles[width as keyof typeof styles]
                                    )}
                                    key={idx}
                                  >
                                    {displayValue}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={idx}
                                    className={clsx(
                                      styles['tbody__td'],
                                      styles[width as keyof typeof styles],
                                      6 < idx
                                        ? showReservationColumns[ridx]
                                          ? ''
                                          : styles['base-t-spHidden']
                                        : ''
                                    )}
                                    data-text={visibleColumn.Header}
                                  >
                                    {displayValue}
                                  </td>
                                );
                              }
                            })}

                            {/* This section is only for mobile */}
                            <td
                              className={clsx(
                                styles['c-table__detail'],
                                styles['c-table__sp'],
                                styles['tbody__td']
                              )}
                            >
                              <Button
                                text={
                                  showReservationColumns[ridx]
                                    ? t('Close')
                                    : t('See more')
                                }
                                uiType="bg"
                                size="md"
                                color="tertiarycolor"
                                onClick={() => {
                                  const newData = [...showReservationColumns];
                                  newData[ridx] = !newData[ridx];
                                  setShowReservationColumns(newData);
                                }}
                                iconAfterText={
                                  showReservationColumns[ridx] ? (
                                    <i className="c-icon-solid-arrows-chevron-up"></i>
                                  ) : (
                                    <i className="c-icon-solid-arrows-chevron-down"></i>
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Prev and next side buttons */}
                <div
                  className={clsx(styles['c-tableBtn'], styles['left'])}
                  onClick={() => {
                    scrollButtonClickHandler('prev');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
                <div
                  className={clsx(styles['c-tableBtn'], styles['right'])}
                  onClick={() => {
                    scrollButtonClickHandler('next');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* Pagination & no of items displayed selection */}
            <div className={styles['p-reservations__bottom']}>
              <Pagination
                totalItems={totalHits}
                currentPage={currentPage}
                pageCount={pageCount}
                selectedNumberOfLinesToDisplay={rowCount}
                onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
                onClickPrevButton={pageBackClickHandler}
                onClickNextButton={pageForwardClickHandler}
              />
            </div>

            {/* Bulk cancel/decline fixed footer */}
            <div
              className={clsx(
                styles['p-reservations__fixed'],
                cancelDeclineListCount != 0 && styles['is-active'],
                styles['width']
              )}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    cancelDeclineListCount == 1
                      ? t('<span><b>{{count}}</b></span> item selected', {
                          count: cancelDeclineListCount,
                        })
                      : t('<span><b>{{count}}</b></span> items selected', {
                          count: cancelDeclineListCount,
                        }),
                }}
              />
              <div>
                <Button
                  text={t('Discard')}
                  uiType="bg"
                  size="md"
                  color="tertiarygray"
                  onClick={onResetCancelDeclineList}
                />
                <Button
                  type="submit"
                  text={t('Cancel/Decline Reservations')}
                  uiType="bg"
                  size="md"
                  color="primary"
                  disabled={cancelDeclineListCount == 0}
                  onClick={onExecuteCancelDecline}
                  loading={bulkCancelLoading}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
