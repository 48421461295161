import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS,
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE,
  CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE,
  FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST,
} from 'client/constants/ActionTypes';
import {
  ReservationDataExportOrder,
  ListReservationDataExportOrdersResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE:
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE:
      return action.error;

    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS:
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST:
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST:
      return true;

    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE:
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE:
    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS:
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS:
      return false;

    default:
      return state;
  }
};

const lastCreated = (
  state = null,
  action: any
): ReservationDataExportOrder | null => {
  switch (action.type) {
    case CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS:
      return action.response as ReservationDataExportOrder;
    default:
      return state;
  }
};

const all = (
  state: ReservationDataExportOrder[] = [],
  action: any
): ReservationDataExportOrder[] => {
  switch (action.type) {
    case FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS: {
      const response =
        action.response as ListReservationDataExportOrdersResponse;
      return [...response.reservation_data_export_orders];
    }

    default:
      return state;
  }
};

export const reservationDataCsvExportOrders = combineReducers({
  error,
  loading,
  lastCreated,
  all,
});
