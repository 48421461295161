/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './PromotionList.module.css';

interface Promotion {
  name: string;
  promoCode: string;
  items: string;
  discount: string;
  timePeriod: string;
  redemptions: number;
  isActive: boolean;
}

const promotions: Promotion[] = [
  {
    name: 'Taco Tuesday',
    promoCode: 'TACO736',
    items: 'Original Taco, Burrito (5 more)',
    discount: '20% off',
    timePeriod: 'Jul 5 - Jul 25',
    redemptions: 19,
    isActive: true,
  },
  {
    name: 'Memorial Day Sale',
    promoCode: 'MEMO24',
    items: 'All entrees',
    discount: '$5 off',
    timePeriod: 'Jul 5 - Jul 25',
    redemptions: 19,
    isActive: false,
  },
];

const PromotionList: React.FC = () => {
  return (
    <section className={styles.promotionList}>
      <div className={styles.listHeader}>
        <h2 className={styles.listTitle}>Active Promotions</h2>
        <div className={styles.periodSelector}>
          <label htmlFor="periodSelect" className={styles.periodLabel}>
            Period
          </label>
          <select id="periodSelect" className={styles.periodSelect}>
            <option>July 2024</option>
          </select>
        </div>
      </div>
      <div className={styles.chart}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1650872f4a2f3633e1e4f2e1c1f9070971c11d7a5981265277691b93f862f894?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
          alt="Promotions chart"
          className={styles.chartImage}
        />
        <div className={styles.chartLegend}>
          <div className={styles.legendItem}>
            <span className={`${styles.legendColor} ${styles.blue}`}></span>
            <span className={styles.legendText}>Taco Tuesday</span>
          </div>
          <div className={styles.legendItem}>
            <span className={`${styles.legendColor} ${styles.orange}`}></span>
            <span className={styles.legendText}>Memorial Day</span>
          </div>
        </div>
      </div>
      <div className={styles.listTabs}>
        <button className={`${styles.tabButton} ${styles.active}`}>
          Ongoing
        </button>
        <button className={styles.tabButton}>Upcoming</button>
        <button className={styles.tabButton}>All</button>
      </div>
      <table className={styles.promotionTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Promo Code</th>
            <th>Items</th>
            <th>Discount</th>
            <th>Time Period</th>
            <th>Redemptions</th>
            <th>Details</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {promotions.map((promo, index) => (
            <tr key={index}>
              <td>{promo.name}</td>
              <td className={styles.promoCode}>{promo.promoCode}</td>
              <td>{promo.items}</td>
              <td>{promo.discount}</td>
              <td className={styles.timePeriod}>{promo.timePeriod}</td>
              <td>{promo.redemptions}</td>
              <td>
                <button className={styles.detailsButton}>View Details</button>
              </td>
              <td>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={promo.isActive}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                  />
                  <span className={styles.slider}></span>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default PromotionList;
