import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { Button } from 'client/components/v3/Common/Button';

import styles from './Table.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  useScrollButton?: boolean;
  shouldOmitMobileFirstChildStyles?: boolean;
};
export type CustomTableColumn = {
  Header?: string;
  HeaderElement?: ReactElement<any>;
  accessor?: string | ((arg0: any) => string);
  width?: string;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  id?: string;
};

const calcWidthToPx = (width: string | typeof undefined): number => {
  if (width === 'short') return 80;
  if (width === 'middle') return 120;
  if (width === 'long') return 200;
  return 80;
};

export const CustomTable = ({
  items,
  columns,
  usePaging,
  useScrollButton,
  shouldOmitMobileFirstChildStyles,
}: Props) => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const scrollButtonClickHandler = (direction: any) => {
    if (tableRef?.current?.scrollLeft != null) {
      if (direction === 'next') {
        tableRef.current.scrollLeft += 100;
      } else {
        tableRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = useState(defaultRowCount);
  const [currentPage, setCurrentPage] = useState(1);

  const rowCountChangeHandler = (count: number) => {
    setRowCount(count);
    setCurrentPage(1);
  };

  //const currentPageHandler = (event: any) => {
  //  let page = parseInt(event.target.value, 10);

  //  if (page < 1) {
  //    page = 1;
  //  }

  //  if (items.length < rowCount * page) {
  //    page = Math.floor(items.length / rowCount);
  //  }

  //  setCurrentPage(page);
  //};

  useEffect(() => {
    setCurrentPage(1);
  }, [items, rowCount]);
  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [showColumns, setShowColumns] = useState(
    [...Array(items.length)].map(() => false)
  );
  return (
    <>
      <div
        className={clsx(
          styles['c-tableFrame'],
          // TODO: check this later
          !shouldOmitMobileFirstChildStyles && styles['mobile-header']
        )}
      >
        <div
          className={clsx(styles['c-overflowRef'], styles['arrow'])}
          ref={tableRef}
        >
          <div className={styles['c-table']}>
            <div
              className={clsx(
                styles['c-table'],
                styles['thead'],
                styles['sticky-top']
              )}
            >
              <table className={styles['c-table__body']}>
                <thead>
                  <tr>
                    {columns.map((column, idx) => {
                      const width = calcWidthToPx(column.width);
                      return (
                        <th
                          key={idx}
                          className={clsx(
                            idx === 0 && styles['sticky-top'],
                            idx === 0 && styles['sticky-left']
                          )}
                          style={{
                            width: `${width}px`,
                          }}
                        >
                          {column.Header}
                          {column.HeaderElement}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>

            <div className={clsx(styles['c-table'], styles['tbody'])}>
              <table className={styles['c-table__body']}>
                <tbody>
                  {items
                    .filter((_, index) => {
                      if (index < rowHead) {
                        return false;
                      }

                      if (rowHead + rowCount <= index) {
                        return false;
                      }

                      return true;
                    })
                    .map((item, ridx) => {
                      const rows = [];

                      const subRowCount =
                        _.max(
                          columns.map(
                            (col) =>
                              (col.CellArray && col.CellArray(item)?.length) ||
                              1
                          )
                        ) ?? 1;

                      for (
                        let subRowIdx = 0;
                        subRowIdx < subRowCount;
                        subRowIdx++
                      ) {
                        const row = (
                          <tr key={`${ridx}-${subRowIdx}`}>
                            {columns.map((column, idx) => {
                              if (subRowIdx > 0 && !column.CellArray) {
                                return null;
                              }

                              const rowSpan = column.CellArray
                                ? 1
                                : subRowCount;
                              let value: any;
                              let displayValue: any;

                              if (typeof column.accessor === 'string') {
                                value = item[column.accessor];
                              } else if (
                                typeof column.accessor === 'function'
                              ) {
                                value = column.accessor(item);
                              }

                              if (column.CellArray) {
                                const cellArray = column.CellArray(item);
                                displayValue =
                                  subRowIdx < cellArray.length
                                    ? cellArray[subRowIdx]
                                    : '';
                              } else if (column.Cell) {
                                const cell = {
                                  value: value,
                                  original: item,
                                  rowIndex: ridx,
                                };
                                displayValue = column.Cell(cell);
                              } else {
                                displayValue = value;
                              }

                              const width = calcWidthToPx(column.width);

                              return (
                                <td
                                  key={idx}
                                  className={clsx(
                                    idx === 0 && styles['c-table__spHeader'],
                                    idx === 0 && styles['sticky-left'],
                                    6 < idx
                                      ? showColumns[ridx]
                                        ? ''
                                        : styles['hidden']
                                      : ''
                                  )}
                                  style={{
                                    width: `${width}px`,
                                  }}
                                  rowSpan={rowSpan}
                                  data-text={column.Header}
                                >
                                  {displayValue}
                                </td>
                              );
                            })}
                            {subRowIdx === subRowCount - 1 && (
                              <td
                                className={clsx(
                                  styles['c-table__detail'],
                                  styles['c-table__sp']
                                )}
                              >
                                <Button
                                  text={
                                    showColumns[ridx]
                                      ? t('Close')
                                      : t('See more')
                                  }
                                  onClick={() => {
                                    const newData = [...showColumns];
                                    newData[ridx] = !newData[ridx];
                                    setShowColumns(newData);
                                  }}
                                  color="tertiarycolor"
                                  iconAfterText={
                                    showColumns[ridx] ? (
                                      <i className="c-icon-solid-arrows-chevron-up"></i>
                                    ) : (
                                      <i className="c-icon-solid-arrows-chevron-down"></i>
                                    )
                                  }
                                />
                              </td>
                            )}
                          </tr>
                        );
                        rows.push(row);
                      }

                      return rows;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {useScrollButton && (
          <>
            <div
              className={clsx(styles['c-tableBtn'], styles['left'])}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <a>
                <i className="c-icon-solid-arrows-chevron-left"></i>
              </a>
            </div>
            <div
              className={clsx(styles['c-tableBtn'], styles['right'])}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <a>
                <i className="c-icon-solid-arrows-chevron-left"></i>
              </a>
            </div>
          </>
        )}
      </div>

      {usePaging && (
        <>
          {/*
            <div className={clsx(styles['c-pagination'])}>
              <div className={clsx(styles['c-pagination__size'])}>
                <p>{t('Number of lines')}:</p>
                <label>
                  <select value={rowCount} onChange={rowCountChangeHandler}>
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="50">50</option>
                  </select>
                </label>
              </div>
              <div className={clsx(styles['c-pagination__jump'])}>
                <p>{t('Page')}:</p>
                <input
                  type="number"
                  value={currentPage}
                  onChange={currentPageHandler}
                />
                <p>of {pageCount}</p>
              </div>
              <div className={clsx(styles['c-pagination__btns'])}>
                <a
                  className={clsx(
                    styles['c-pagination__btn'],
                    1 < currentPage ? '' : styles['disable']
                  )}
                  onClick={pageBackClickHandler}
                >
                  {t('Previous')}
                </a>
                <a
                  className={clsx(
                    styles['c-pagination__btn'],
                    currentPage < pageCount ? '' : styles['disable']
                  )}
                  onClick={pageForwardClickHandler}
                >
                  {t('Next')}
                </a>
              </div>
            </div>
          */}
          <div className={styles['c-pagination__wrapper']}>
            <Pagination
              totalItems={items.length}
              currentPage={currentPage}
              pageCount={pageCount}
              selectedNumberOfLinesToDisplay={rowCount}
              onChangeNumberOfLinesToDisplay={rowCountChangeHandler}
              onClickPrevButton={pageBackClickHandler}
              onClickNextButton={pageForwardClickHandler}
            />
          </div>
        </>
      )}
    </>
  );
};
