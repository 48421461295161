import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { ReduxState } from 'client/reducers';

import styles from './OrderCount.module.css';

const OrderCount: React.FC = () => {
  const { t } = useTranslation();

  const reportData = useSelector(
    (state: ReduxState) => state.restaurantOrderReportData.reportData
  );

  const graphData = reportData?.monthly_totals?.map((item) => {
    return {
      name: item.month,
      total: item.order_count,
    };
  });

  return (
    <section className={styles.orderCount}>
      <h2 className={styles.sectionTitle}>{t('Order Count')}</h2>
      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="total" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default OrderCount;
