import React from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Message,
  Segment,
  List,
  Dimmer,
  Divider,
  Grid,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';

import { LogoHeader } from 'client/components/LogoHeader/LogoHeader';
import { isSignedIn } from 'client/libraries/cognito/index';
import { requiredPasswordReset } from 'client/actions/user';
import type { ReduxState } from 'client/reducers/index';

type Dispatch = ThunkDispatch<any, any, any>;

type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */

type State = {
  newPassword: string;
  newPasswordHidden: boolean;
  resetDisabled: boolean;
  lengthRequirementDisabled: boolean;
  specialCharRequirementDisabled: boolean;
  uppercaseRequirementDisabled: boolean;
  lowercaseRequirementDisabled: boolean;
  numberRequirementDisabled: boolean;
};

class component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordHidden: true,
      resetDisabled: true,
      lengthRequirementDisabled: false,
      specialCharRequirementDisabled: false,
      uppercaseRequirementDisabled: false,
      lowercaseRequirementDisabled: false,
      numberRequirementDisabled: false,
    };
    this.onInputChanged = this.onInputChanged.bind(this);
    this.toggleNewPasswordHidden = this.toggleNewPasswordHidden.bind(this);
    this.passwordIsValid = this.passwordIsValid.bind(this);
  }

  onInputChanged(e: any) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      resetDisabled: !this.passwordIsValid(value),
    } as any);
  }

  passwordIsValid = (value: string) => {
    const lowercasePattern = new RegExp('^(?=.*[a-z])');
    const uppercasePattern = new RegExp('^(?=.*[A-Z])');
    const specialCharPattern = new RegExp('^(?=.*[^a-zA-Z0-9])');
    const numberPattern = new RegExp('^(?=.*[0-9])');
    const lengthPattern = new RegExp('^(?=.{8,})');
    const minLowercaseRequirementSatisfied = lowercasePattern.test(value);
    const minUppercaseRequirementSatisfied = uppercasePattern.test(value);
    const minSpecialCharRequirementSatisfied = specialCharPattern.test(value);
    const minNumberRequirementSatisfied = numberPattern.test(value);
    const minLengthRequirementSatisfied = lengthPattern.test(value);
    this.setState({
      lowercaseRequirementDisabled: minLowercaseRequirementSatisfied,
      uppercaseRequirementDisabled: minUppercaseRequirementSatisfied,
      specialCharRequirementDisabled: minSpecialCharRequirementSatisfied,
      numberRequirementDisabled: minNumberRequirementSatisfied,
      lengthRequirementDisabled: minLengthRequirementSatisfied,
    });
    return (
      minLowercaseRequirementSatisfied &&
      minUppercaseRequirementSatisfied &&
      minSpecialCharRequirementSatisfied &&
      minNumberRequirementSatisfied &&
      minLengthRequirementSatisfied
    );
  };

  toggleNewPasswordHidden() {
    this.setState((prevState) => ({
      newPasswordHidden: !prevState.newPasswordHidden,
    }));
  }

  render() {
    const { newPassword } = this.state;
    const { t } = this.props;

    if (this.props.succeeded) {
      return <Redirect to="/signup/defaults" />;
    } else if (!this.props.user.cognito) {
      return <Redirect to="/" />;
    }

    const passwordStatusAndRequirements = [
      [this.state.lengthRequirementDisabled, t('At least 8 characters')],
      [
        this.state.lowercaseRequirementDisabled,
        t('At least 1 lowercase character'),
      ],
      [
        this.state.uppercaseRequirementDisabled,
        t('At least 1 uppercase character'),
      ],
      [this.state.numberRequirementDisabled, t('At least 1 number')],
      [
        this.state.specialCharRequirementDisabled,
        t('At least 1 special character'),
      ],
    ];
    const renderedPasswordStatusAndRequirements =
      passwordStatusAndRequirements.map((statAndReq, idx) => (
        <Dimmer.Dimmable key={idx} dimmed>
          <Dimmer active={Boolean(statAndReq[0])} inverted />
          <List>
            <List.Item>
              <List.Icon
                name="circle"
                color="blue"
                size="tiny"
                verticalAlign="middle"
              />
              <List.Content>{statAndReq[1]}</List.Content>
            </List.Item>
          </List>
        </Dimmer.Dimmable>
      ));
    return (
      <>
        <LogoHeader />
        <Divider hidden />
        <Container text>
          <Segment>
            <Header as="h2">{t('New Password Required')}</Header>
            <Form error={!!this.props.error}>
              <Form.Input
                type={this.state.newPasswordHidden ? 'password' : 'text'}
                label={t('New password')}
                name="newPassword"
                value={newPassword}
                onChange={this.onInputChanged}
                icon={
                  newPassword ? (
                    <Icon
                      name="eye"
                      link
                      onClick={this.toggleNewPasswordHidden}
                    />
                  ) : null
                }
              />
              <Message error header="Error" content={this.props.error} />
              <Grid columns={2}>
                <Grid.Column width={7}>
                  {renderedPasswordStatusAndRequirements.slice(0, 3)}
                </Grid.Column>
                <Grid.Column>
                  {renderedPasswordStatusAndRequirements.slice(3, 5)}
                </Grid.Column>
              </Grid>
              <Divider hidden />
              <Button
                positive
                onClick={() => this.props.onReset(newPassword)}
                disabled={this.state.resetDisabled}
              >
                {t('Confirm')}
              </Button>
            </Form>
          </Segment>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  succeeded: isSignedIn(state.user.cognito),
  user: state.user,
  error: state.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onReset: (newPassword: string) =>
    dispatch(requiredPasswordReset(newPassword)),
});

export const NewUser = compose<Props, Record<string, unknown>>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(component);
