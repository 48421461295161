import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import {
  FalconUIRenderingContext,
  Highlight,
  Section,
} from '@nutmeglabs/falcon-ui';
import ColorPicker from 'rc-color-picker';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { Add } from 'client/components/Icons/Add';
import { Box } from 'client/components/Box/Box';

import { getDefaultHighlight } from '../../useTemplateOptions';
import { SectionTitleEditor } from '../SectionTitleEditor';
import { SectionBackgroundEditor } from '../SectionBackgroundEditor';

import { DraggableHighlight } from './DraggableHighlight';

interface Props {
  name: string;
}

export const EditHighlightsButton = ({ name }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const form = useForm();

  const highlightsStyleOptions = [
    {
      value: 'CIRCULAR_ROW',
      text: t('Round Images'),
    },
    {
      value: 'SQUARE_ROW',
      text: t('Square Images'),
    },
    {
      value: 'STACKED',
      text: t('Stacked'),
    },
  ];

  const {
    highlights,
    title,
    titleStyle,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
    highlightsStyle,
    themePrimaryColor,
    highlightsHideNumbers,
  } = _.get(form.getState().values, name) as Section;

  const topPageCtx = React.useContext(FalconUIRenderingContext);

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Edit Highlights')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              highlights,
              title,
              titleStyle,
              useBackgroundImage,
              backgroundColor,
              backgroundImageUrl,
              highlightsStyle,
              themePrimaryColor,
              highlightsHideNumbers,
            }}
            onSubmit={(values: Section) => {
              form.change(name, {
                type: 'HIGHLIGHTS',
                highlights: values.highlights,
                title: values.title,
                titleStyle: values.titleStyle,
                useBackgroundImage: values.useBackgroundImage,
                backgroundColor: values.backgroundColor,
                backgroundImageUrl: values.backgroundImageUrl,
                highlightsStyle: values.highlightsStyle,
                themePrimaryColor: values.themePrimaryColor,
                highlightsHideNumbers: values.highlightsHideNumbers,
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <SectionTitleEditor />
                  <SectionBackgroundEditor />
                  <Field name="highlightsStyle">
                    {({ input }: FieldRenderProps<string>) => {
                      return (
                        <Select
                          label={t('Highlights Style')}
                          options={highlightsStyleOptions}
                          value={input.value}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      );
                    }}
                  </Field>
                  {values.highlightsStyle === 'STACKED' && (
                    <Field name="highlightsHideNumbers">
                      {({ input }: FieldRenderProps<boolean>) => (
                        <Box mt={2} mb={2}>
                          <ToggleButton
                            label={t('Show numbers')}
                            checked={!input.value}
                            onChange={() => input.onChange(!input.value)}
                          />
                        </Box>
                      )}
                    </Field>
                  )}
                  {values.highlightsStyle === 'STACKED' &&
                    !values.highlightsHideNumbers && (
                      <Field name="themePrimaryColor">
                        {({ input }: FieldRenderProps<string>) => (
                          <FieldWrapper label={t('Theme: Main color')}>
                            <ColorPicker
                              color={input.value}
                              onChange={({ color }: any) =>
                                input.onChange(color)
                              }
                            />
                          </FieldWrapper>
                        )}
                      </Field>
                    )}
                  <Box width="100%" mt={2}>
                    <Field name="highlights">
                      {({ input }: FieldRenderProps<Highlight[]>) => (
                        <>
                          {input.value?.map((highlight, idx) => (
                            <DraggableHighlight
                              key={highlight.key}
                              value={highlight}
                              index={idx}
                              onValueChange={(h) => {
                                const newHighlights = [...input.value];
                                newHighlights[idx] = h;
                                input.onChange(newHighlights);
                              }}
                              moveItem={(dragIndex, hoverIndex) => {
                                const dragItem = input.value[dragIndex];
                                const newHighlights = [...input.value];
                                newHighlights.splice(dragIndex, 1);
                                newHighlights.splice(hoverIndex, 0, dragItem);
                                input.onChange(newHighlights);
                              }}
                              deleteItem={() => {
                                const newHighlights = [...input.value];
                                newHighlights.splice(idx, 1);
                                input.onChange(newHighlights);
                              }}
                            />
                          ))}
                          <Add
                            onClick={() => {
                              input.onChange([
                                ...input.value,
                                getDefaultHighlight(topPageCtx.t),
                              ]);
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
