import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { Tabs } from 'client/components/Tabs/Tabs';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { fetchProducts } from 'client/actions/products';
import { ReduxState } from 'client/reducers';
import {
  createGuidancePage,
  deleteGuidancePage,
  fetchGuidancePages,
} from 'client/actions/guidancePage';
import { getLanguageName } from 'client/libraries/i18n';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { fetchGuidanceLocations } from 'client/actions/guidanceLocation';
import { fetchGuidanceCoupons } from 'client/actions/guidanceCoupon';
import { fetchGuidanceStampRallies } from 'client/actions/guidanceStampRally';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import baseStyles from 'client/base.module.css';
import { GuidancePage, SourceLanguage } from 'shared/models/swagger';
import { Copy as CopyIcon } from 'client/components/Icons/Copy';

import { EditDigitalGuidancePageModal } from './EditDigitalGuidancePageModal';
import { EditDigitalGuidancePagesDisplayOrderModal } from './EditDigitalGuidancePagesDisplayOrderModal';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const ProductsCell = ({ productIds }: { productIds: string[] }) => {
  const products = useSelector((state: ReduxState) => state.products.summaries);

  return (
    <div>
      <ul>
        {productIds.map((productId) => {
          const product = products.find((p) => p.id === productId);
          return product ? (
            <li key={productId}>{getDisplayProductName(product)}</li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

type EditCopyDeleteCellProps = {
  existingPage: GuidancePage;
  onCopy: () => void;
};

const EditCopyDeleteCell = ({
  existingPage,
  onCopy,
}: EditCopyDeleteCellProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {hasCustomUserRoleWritePermissions(
        activeUser,
        'DIGITAL_GUIDANCE.PAGES'
      ) && (
        <>
          {showEditModal && (
            <EditDigitalGuidancePageModal
              existingDigitalGuidancePage={existingPage}
              open={showEditModal}
              onClose={() => setShowEditModal(false)}
            />
          )}
          <EditIcon onClick={() => setShowEditModal(true)} />

          {showDeleteModal && (
            <DeleteConfirmModal
              header={t('Delete guidance page')}
              content={t('Are you sure you want to delete guidance page?')}
              onConfirm={async () => {
                await dispatch(deleteGuidancePage(existingPage?.id ?? ''));
              }}
              onClose={() => setShowDeleteModal(false)}
              open={showDeleteModal}
              insertRoot={true}
            />
          )}

          <Box ml={1} mr={-1}>
            <CopyIcon
              onClick={async () => {
                await dispatch(
                  createGuidancePage({
                    title: `[COPY] ${existingPage.title}`,
                    description: existingPage.description,
                    language: existingPage.language,
                    status: 'GUIDANCE_PAGE_INACTIVE',
                    items: existingPage.items,
                    product_ids: existingPage.product_ids,
                    sort_order: existingPage.sort_order,
                    thumbnail_url: existingPage.thumbnail_url,
                    tags: existingPage.tags,
                    stamp_rally_id: existingPage.stamp_rally_id,
                  })
                );

                onCopy();
              }}
            />
          </Box>

          <Box ml={2}>
            <DeleteIcon onClick={() => setShowDeleteModal(true)} />
          </Box>
        </>
      )}

      <Box ml={2}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${bookingWebsiteUrl}/guidancepreview/${
            existingPage.id
          }?lng=${convertToBookingWidgetUrlLangCode(
            uppercaseIsoToLowercaseIso[existingPage.language as SourceLanguage]
          )}&stampRallyId=${existingPage?.stamp_rally_id ?? ''}`}
          className={clsx(baseStyles['base-btn'])}
        >
          {t('Preview')}
        </a>
      </Box>
    </Box>
  );
};

const useColumns = (onCopy: () => void): ColumnType<GuidancePage>[] => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);

  const columns: ColumnType<GuidancePage>[] = React.useMemo(
    () => [
      {
        Header: '',
        width: 'middle',
        Cell: (cellInfo) => (
          <EditCopyDeleteCell
            existingPage={cellInfo.original}
            onCopy={onCopy}
          />
        ),
      },
      {
        Header: t('Title'),
        accessor: 'title',
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },
      {
        Header: t('Language'),
        accessor: (row) =>
          getLanguageName(
            uppercaseIsoToLowercaseIso[
              row.language as keyof typeof uppercaseIsoToLowercaseIso
            ],
            t
          ),
      },
      {
        Header: t('Products'),
        width: 'long',
        Cell: (cellInfo) => (
          <ProductsCell productIds={cellInfo.original.product_ids ?? []} />
        ),
      },
    ],
    [t, activeUser, bookingWebsiteUrl]
  );

  return columns;
};

export const DigitalGuidancePageList = () => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showEditDisplayOrderModal, setShowEditDisplayOrderModal] =
    React.useState(false);
  const [activeTabIndex, setActiveTabIndex] = React.useState<number | null>(
    null
  );
  const columns = useColumns(() => setActiveTabIndex(0));
  const activeUser = useSelector(activeUserSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchGuidancePages());
    dispatch(fetchGuidanceLocations());
    dispatch(fetchGuidanceCoupons());
    dispatch(fetchGuidanceStampRallies());
  }, []);

  const guidancePages = useSelector(
    (state: ReduxState) => state.guidancePages.all
  );
  const { t } = useTranslation();
  const tabPanes = React.useMemo(() => {
    const publishedPages = _.orderBy(
      _.orderBy(
        guidancePages.filter((p) => p.status === 'GUIDANCE_PAGE_ACTIVE'),
        (g) => g.sort_order
      ),
      (g) => g.language
    );
    const pendingPages = _.orderBy(
      _.orderBy(
        guidancePages.filter((p) => p.status === 'GUIDANCE_PAGE_INACTIVE'),
        (g) => g.sort_order
      ),
      (g) => g.language
    );
    return [
      {
        header: t('Pending'),
        content: () =>
          pendingPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Pending Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable
              items={pendingPages}
              columns={columns}
              shouldOmitMobileFirstChildStyles={true}
              hideSeeMoreButton={true}
            />
          ),
      },
      {
        header: t('Published'),
        content: () =>
          publishedPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Published Guidance Pages Found')}
            </div>
          ) : (
            <CustomTable
              items={publishedPages}
              columns={columns}
              shouldOmitMobileFirstChildStyles={true}
              hideSeeMoreButton={true}
            />
          ),
      },
    ];
  }, [t, guidancePages, columns]);
  return (
    <div>
      {hasCustomUserRoleWritePermissions(
        activeUser,
        'DIGITAL_GUIDANCE.PAGES'
      ) && (
        <Box display="flex">
          <Button
            size="middle"
            style="green"
            onClick={() => setShowEditModal(true)}
          >
            {t('Create New Guidance Page')}
          </Button>
          {showEditModal && (
            <EditDigitalGuidancePageModal
              open={showEditModal}
              onClose={() => setShowEditModal(false)}
            />
          )}

          <Box ml={1}>
            <Button
              size="middle"
              style="green"
              onClick={() => setShowEditDisplayOrderModal(true)}
            >
              {t('Edit Display Order')}
            </Button>
          </Box>
          {showEditDisplayOrderModal && (
            <EditDigitalGuidancePagesDisplayOrderModal
              open={showEditDisplayOrderModal}
              onClose={() => setShowEditDisplayOrderModal(false)}
            />
          )}
        </Box>
      )}

      <Box mt={2}>
        <Tabs
          panes={tabPanes}
          activeIndex={activeTabIndex}
          onActiveIndexChange={setActiveTabIndex}
        />
      </Box>
    </div>
  );
};
