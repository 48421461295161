import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_SURVEY_TEMPLATE_REQUEST,
  CREATE_SURVEY_TEMPLATE_SUCCESS,
  CREATE_SURVEY_TEMPLATE_FAILURE,
  DELETE_SURVEY_TEMPLATE_REQUEST,
  DELETE_SURVEY_TEMPLATE_SUCCESS,
  DELETE_SURVEY_TEMPLATE_FAILURE,
  UPDATE_SURVEY_TEMPLATE_REQUEST,
  UPDATE_SURVEY_TEMPLATE_SUCCESS,
  UPDATE_SURVEY_TEMPLATE_FAILURE,
  FETCH_SURVEY_TEMPLATES_REQUEST,
  FETCH_SURVEY_TEMPLATES_SUCCESS,
  FETCH_SURVEY_TEMPLATES_FAILURE,
  FETCH_SURVEY_TEMPLATE_REQUEST,
  FETCH_SURVEY_TEMPLATE_SUCCESS,
  FETCH_SURVEY_TEMPLATE_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const createSurveyTemplateRequest = (
  newSurveyTemplate: Swagger.SurveyTemplateParams
) => ({
  type: CREATE_SURVEY_TEMPLATE_REQUEST,
  newSurveyTemplate,
});
const createSurveyTemplateSuccess = (response: any) => ({
  type: CREATE_SURVEY_TEMPLATE_SUCCESS,
  response,
});
const createSurveyTemplateFailure = (error: string) => ({
  type: CREATE_SURVEY_TEMPLATE_FAILURE,
  error,
});

export const createSurveyTemplate =
  (newSurveyTemplate: Swagger.SurveyTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createSurveyTemplateRequest(newSurveyTemplate));
    return axios
      .post('/api/surveytemplates', newSurveyTemplate, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createSurveyTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createSurveyTemplateFailure(err.message));
      });
  };

const updateSurveyTemplateRequest = (
  id: string,
  waiverTemplate: Swagger.SurveyTemplateParams
) => ({
  type: UPDATE_SURVEY_TEMPLATE_REQUEST,
  id,
  waiverTemplate,
});
const updateSurveyTemplateSuccess = (response: any) => ({
  type: UPDATE_SURVEY_TEMPLATE_SUCCESS,
  response,
});
const updateSurveyTemplateFailure = (error: string) => ({
  type: UPDATE_SURVEY_TEMPLATE_FAILURE,
  error,
});

export const updateSurveyTemplate =
  (id: string, waiverTemplate: Swagger.SurveyTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateSurveyTemplateRequest(id, waiverTemplate));
    return axios
      .patch(`/api/surveytemplates/${id}`, waiverTemplate, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateSurveyTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateSurveyTemplateFailure(err.message));
      });
  };

const fetchSurveyTemplatesRequest = () => ({
  type: FETCH_SURVEY_TEMPLATES_REQUEST,
});
const fetchSurveyTemplatesSuccess = (response: any) => ({
  type: FETCH_SURVEY_TEMPLATES_SUCCESS,
  response,
});
const fetchSurveyTemplatesFailure = (error: string) => ({
  type: FETCH_SURVEY_TEMPLATES_FAILURE,
  error,
});

export const fetchSurveyTemplates =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveyTemplatesRequest());
    return axios
      .get('/api/surveytemplates', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchSurveyTemplatesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSurveyTemplatesFailure(err.message));
      });
  };

const fetchSurveyTemplateRequest = () => ({
  type: FETCH_SURVEY_TEMPLATE_REQUEST,
});
const fetchSurveyTemplateSuccess = (response: any) => ({
  type: FETCH_SURVEY_TEMPLATE_SUCCESS,
  response,
});
const fetchSurveyTemplateFailure = (error: string) => ({
  type: FETCH_SURVEY_TEMPLATE_FAILURE,
  error,
});

export const fetchSourceLanguageSurveyTemplateByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveyTemplateRequest());
    return axios
      .get(`/api/surveytemplates/${id}?ignore_request_language=true`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchSurveyTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSurveyTemplateFailure(err.message));
      });
  };

const deleteSurveyTemplateRequest = (id: string) => ({
  type: DELETE_SURVEY_TEMPLATE_REQUEST,
  id,
});
const deleteSurveyTemplateSuccess = (response: any, id: string) => ({
  type: DELETE_SURVEY_TEMPLATE_SUCCESS,
  response,
  id,
});
const deleteSurveyTemplateFailure = (error: string) => ({
  type: DELETE_SURVEY_TEMPLATE_FAILURE,
  error,
});

export const deleteSurveyTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteSurveyTemplateRequest(id));
    return fetch(`/api/surveytemplates/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((response) => {
        dispatch(deleteSurveyTemplateSuccess(response, id));
      })
      .catch((error) => {
        error.json().then((e: any) => {
          dispatch(deleteSurveyTemplateFailure(e.message));
        });
      });
  };
