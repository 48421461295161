import type { Reservation } from 'shared/models/swagger';

export const getAddOnsSummary = (reservation: Reservation): string => {
  const addOnCounts: Record<string, number> = {};
  (reservation.add_on_titles || []).forEach((addOn) => {
    addOnCounts[addOn] = (addOnCounts[addOn] || 0) + 1;
  });

  for (const guest of reservation.guests || []) {
    for (const guestAddOn of guest.add_on_titles || []) {
      const addOnTitle = `${guestAddOn} - ${guest.guest_type_title || ''}`;
      addOnCounts[addOnTitle] = (addOnCounts[addOnTitle] || 0) + 1;
    }
  }

  const addOnCountDescriptions: string[] = [];
  Object.keys(addOnCounts).forEach((addOnTitle) => {
    const count = addOnCounts[addOnTitle];

    if (count === 1) {
      addOnCountDescriptions.push(addOnTitle);
    } else {
      addOnCountDescriptions.push(`${addOnTitle} (x${count})`);
    }
  });
  return addOnCountDescriptions.join(', ');
};
