import _ from 'lodash';
import type { Moment } from 'moment-timezone';

export const groupReservationsByStartTime = <
  ReservationType extends {
    participates_at: Moment;
  }
>(
  reservations: ReservationType[],
  locale: string
): {
  startTime: string;
  date: string;
  reservations: ReservationType[];
}[] => {
  const startDateTimes = _.uniqBy(
    reservations.map((r) => r.participates_at),
    (p) => p.valueOf()
  );

  return _.orderBy(startDateTimes, [(p) => p.valueOf()]).map(
    (startDateTime) => ({
      startTime: startDateTime.locale(locale).format('HH:mm'),
      date: startDateTime.locale(locale).format('YYYY-MM-DD'),
      reservations: reservations.filter((r) =>
        r.participates_at.isSame(startDateTime)
      ),
    })
  );
};
