import { forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { LanguageISO } from 'shared/libraries/i18n';
import {
  Account,
  Product,
  Reservation,
  ReservationLocationWithTime,
} from 'shared/models/swagger';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { CheckinEditButton } from 'client/pages/ReservationDetails/CheckinEditButton';
import { PickupDropoffEditButton } from 'client/pages/ReservationDetails/PickupDropoffEditButton';
import { GuestHotelEditButton } from 'client/pages/ReservationDetails/GuestHotelEditButton';
import { RequestedPickupDropoffEditButton } from 'client/pages/ReservationDetails/RequestedPickupDropoffEditButton';
import { TransportRoutePanel } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DetailInformation/TransportRoutePanel';
import { Button } from 'client/components/v3/Common/Button';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  product: Product;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly: boolean;
};

export const DetailedInformation = forwardRef<any, Props>((props: Props) => {
  const { reservation, product, locale, activeUser, readOnly } = props;
  const { partnershipMode } = useContext(PartnershipModeContext);

  const { t } = useTranslation();

  const currentStatus = getCurrentStatus(reservation);

  const userIsPassthroughAgent =
    reservation.agent_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'AGENT';

  const userIsPassthroughSupplier =
    reservation.supplier_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'SUPPLIER';

  const userIsPassthroughOrg =
    userIsPassthroughAgent || userIsPassthroughSupplier;

  const userCanEditPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;
  const userCanEditRequestedPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    !operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  const isCheckinCheckoutOnly = reservationIsCheckinCheckoutOnly(reservation);

  const LocationWithTime = ({
    label,
    locationWithTime,
  }: {
    label: string;
    locationWithTime: ReservationLocationWithTime;
  }) => {
    return (
      <div className={styles['p-secondary__item']}>
        <p className={styles['p-secondary__item__ttl']}>{label}</p>
        <table
          className={tableStyles['c-tableSmall']}
          style={{ overflow: 'hidden' }}
        >
          <tbody>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Time')}</th>
              <td>
                {locationWithTime && locationWithTime.date_time_utc
                  ? moment
                      .tz(
                        locationWithTime.date_time_utc,
                        product.start_timezone ?? ''
                      )
                      .locale(locale)
                      .format('lll')
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Location')}</th>
              <td>
                {locationWithTime && locationWithTime.location_name
                  ? locationWithTime.location_name
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Details')}</th>
              <td>
                {locationWithTime && locationWithTime.location_description
                  ? locationWithTime.location_description
                  : t('TBD')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const defaultLocationWithTime: ReservationLocationWithTime = { id: '' };

  return (
    <section
      id="detail"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <TutorialPopup
        name="detail"
        content={
          <TutorialPopupContent>
            <TutorialPopupDescription
              text={t(
                'Each tab shows detailed information about the reservation. You can find the necessary information by switching tabs, so please check if necessary.'
              )}
            />
            <TutorialPopupHeader text={t('Detail Information')} />
            <TutorialPopupDescription
              text={t(
                'View details about your booking. In addition to reservation status, information like checkin or pickup information is also displayed.'
              )}
            />
            <TutorialPopupHeader text={t('Guest Information')} />
            <TutorialPopupDescription
              text={t(
                'Reservation form information received for each participant is shown here.'
              )}
            />
            <TutorialPopupHeader text={t('Billing Info')} />
            <TutorialPopupDescription
              text={t(
                'Shows a breakdown of the price amounts for the reservation. Please make adjustments like additional charges and refunds here.'
              )}
            />
          </TutorialPopupContent>
        }
      />

      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Detail Information')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {userCanEditPickupDropoff ? (
              isCheckinCheckoutOnly ? (
                <CheckinEditButton
                  reservationID={reservation.id}
                  trigger={
                    <Button
                      text={t('Edit')}
                      uiType="bg"
                      size="sm"
                      color="white"
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                    />
                  }
                />
              ) : (
                <PickupDropoffEditButton
                  reservationID={reservation.id}
                  trigger={
                    <Button
                      text={t('Edit')}
                      uiType="bg"
                      size="sm"
                      color="white"
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                    />
                  }
                />
              )
            ) : userCanEditRequestedPickupDropoff ? (
              isCheckinCheckoutOnly ? (
                <GuestHotelEditButton
                  reservation={reservation}
                  trigger={
                    <Button
                      text={t('Edit')}
                      uiType="bg"
                      size="sm"
                      color="white"
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                    />
                  }
                />
              ) : (
                <RequestedPickupDropoffEditButton
                  reservation={reservation}
                  trigger={
                    <Button
                      text={t('Edit')}
                      uiType="bg"
                      size="sm"
                      color="white"
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                    />
                  }
                />
              )
            ) : null}
          </div>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-secondary']}>
            {isCheckinCheckoutOnly ? (
              <>
                <LocationWithTime
                  label={t('Checkin')}
                  locationWithTime={
                    reservation?.checkin || defaultLocationWithTime
                  }
                />
                <LocationWithTime
                  label={t('Checkout')}
                  locationWithTime={
                    reservation?.checkout || defaultLocationWithTime
                  }
                />
              </>
            ) : (
              <>
                <LocationWithTime
                  label={t('Pickup')}
                  locationWithTime={
                    reservation?.pickup || defaultLocationWithTime
                  }
                />
                <LocationWithTime
                  label={t('Dropoff')}
                  locationWithTime={
                    reservation?.dropoff || defaultLocationWithTime
                  }
                />
              </>
            )}
            <div className={clsx(styles['p-secondary__item'], styles['max'])}>
              <p className={styles['p-secondary__item__ttl']}>
                {t('Customer Hotel')}
              </p>
              <table
                className={tableStyles['c-tableSmall']}
                style={{ overflow: 'hidden' }}
              >
                <tbody>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Customer Hotel')}
                    </th>
                    <td>
                      {(reservation?.guest_hotel?.location_name === 'TBD'
                        ? t('TBD')
                        : reservation?.guest_hotel?.location_name) ||
                        t('(none)')}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Desired Pickup Location')}
                    </th>
                    <td>
                      {(reservation.requested_pickup_location?.location_name ===
                      'TBD'
                        ? t('TBD')
                        : reservation.requested_pickup_location
                            ?.location_name) || t('(none)')}
                      {userCanEditRequestedPickupDropoff && (
                        <RequestedPickupDropoffEditButton
                          reservation={reservation}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Desired Dropoff Location')}
                    </th>
                    <td>
                      {(reservation.requested_dropoff_location
                        ?.location_name === 'TBD'
                        ? t('TBD')
                        : reservation.requested_dropoff_location
                            ?.location_name) || t('(none)')}
                      {userCanEditRequestedPickupDropoff && (
                        // RequestedPickupDropoffEditButton
                        <RequestedPickupDropoffEditButton
                          reservation={reservation}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* No need to show transport routes if there is only 1 route defined */}
            {reservation?.transport_route &&
              reservation?.transport_route.length > 1 && (
                <TransportRoutePanel reservation={reservation} />
              )}
          </div>
        </div>
      </div>
    </section>
  );
});
