// @flow

import _ from 'lodash';

import { currency } from 'shared/libraries/currency';
import type {
  Pricing,
  ProductSummary,
  UnitPricing,
} from 'shared/models/swagger';

export const getDefaultCommissionPercent = (
  products: ProductSummary[],
  pricePeriodStartDate: string,
  pricePeriodEndDate: string
): number => {
  let commissionPercentCounts: { [commission: string]: number } = {};
  products.forEach((product) => {
    (product.pricing || []).forEach((priceSchedule) => {
      if (
        pricePeriodEndDate &&
        (!priceSchedule.end_date_local ||
          priceSchedule.end_date_local > pricePeriodEndDate)
      ) {
        return;
      }
      if (
        pricePeriodStartDate &&
        priceSchedule.end_date_local &&
        priceSchedule.end_date_local < pricePeriodStartDate
      ) {
        return;
      }

      (priceSchedule.units || []).forEach((unit) => {
        const percent = getCommissionPercent(unit, '');
        commissionPercentCounts[String(percent)] =
          (commissionPercentCounts[String(percent)] || 0) + 1;
      });
    });
  });

  return parseInt(
    _.maxBy(
      Object.keys(commissionPercentCounts),
      (percent) => commissionPercentCounts[percent]
    )
  );
};

export const changeCommissions = (
  products: ProductSummary[],
  pricePeriodStartDate: string,
  pricePeriodEndDate: string,
  agentId: string,
  newCommissionPercent: number
): ProductSummary[] => {
  return products.map((product) =>
    changeProductCommissions(
      product,
      pricePeriodStartDate,
      pricePeriodEndDate,
      agentId,
      newCommissionPercent
    )
  );
};

const changeProductCommissions = (
  product: ProductSummary,
  pricePeriodStartDate: string,
  pricePeriodEndDate: string,
  agentId: string,
  newCommissionPercent: number
): ProductSummary => {
  return {
    ...product,
    pricing: (product.pricing || []).map((priceSchedule) => {
      if (
        pricePeriodEndDate &&
        (!priceSchedule.end_date_local ||
          priceSchedule.end_date_local > pricePeriodEndDate)
      ) {
        return priceSchedule;
      }
      if (
        pricePeriodStartDate &&
        priceSchedule.end_date_local &&
        priceSchedule.end_date_local < pricePeriodStartDate
      ) {
        return priceSchedule;
      }

      return changePriceScheduleCommissions(
        priceSchedule,
        agentId,
        newCommissionPercent
      );
    }),
  };
};

const changePriceScheduleCommissions = (
  priceSchedule: Pricing,
  agentId: string,
  newCommissionPercent: number
): Pricing => {
  return {
    ...priceSchedule,
    units: (priceSchedule.units || []).map((unit) =>
      changeUnitCommissions(unit, agentId, newCommissionPercent)
    ),
  };
};

const changeUnitCommissions = (
  unit: UnitPricing,
  agentId: string,
  newCommissionPercent: number
): UnitPricing => {
  return setCommissionPercent(unit, agentId, newCommissionPercent);
};

export const getCommissionPercent = (
  unit: UnitPricing,
  agentId: string
): number => {
  let net = unit.net;
  if (agentId) {
    const netPrice = (unit.per_agent_net || []).find(
      (n) => n.agent_id === agentId
    );
    if (netPrice) {
      net = netPrice.net_fixed;
    }
  }
  return (
    Math.round(
      (currency(unit.gross).subtract(currency(net)).value /
        currency(unit.gross).value) *
        10000
    ) / 100
  );
};

const setCommissionPercent = (
  unit: UnitPricing,
  agentId: string,
  commissionPercent: number
): UnitPricing => {
  const newNetPrice = currency(unit.gross)
    .subtract(currency(unit.gross).multiply(commissionPercent / 100))
    .format();

  if (newNetPrice !== unit.net) {
    if (agentId) {
      return {
        ...unit,
        per_agent_net: [
          ...(unit.per_agent_net || []).filter(
            (netPrice) => netPrice.agent_id !== agentId
          ),
          {
            agent_id: agentId,
            net_fixed: newNetPrice,
          },
        ],
      };
    } else {
      return {
        ...unit,
        net: newNetPrice,
      };
    }
  }

  if (agentId) {
    return {
      ...unit,
      per_agent_net: (unit.per_agent_net || []).filter(
        (netPrice) => netPrice.agent_id !== agentId
      ),
    };
  }

  return unit;
};
