import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';

import { getArrayMutators } from 'client/libraries/util/form';
import { Button, Select, FieldWrapper } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import {
  updateResourceQuantity,
  deleteResourceQuantity,
  createResourceQuantity,
} from 'client/actions/resourceQuantities';
import { fetchDispatchReservations } from 'client/actions/dispatch';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ResourceQuantity } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { ResourceAvailabilityReservationListModal } from './ResourceAvailabilityReservationListModal';
import styles from './DesignatedDateResourceAvailabilityModal.module.css';

export type Occupation = {
  startTime: string;
  productId: string;
  productName?: string;
  quantity: number;
  reservations?: {
    startTime?: string;
    pax?: number;
  }[];
  reservationIds?: string[];
};

type Props = {
  date: string | null;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  resourceQuantity?: ResourceQuantity | null;
  occupations?: Occupation[];
  resourceKey?: string;
  capacityPerUnit?: number;
  defaultQuantity?: number;
};

export type FormValues = {
  quantity: string;
};

export const DesignatedDateResourceAvailabilityModal = ({
  date,
  open,
  onOpen,
  onClose,
  resourceQuantity,
  occupations,
  resourceKey,
  defaultQuantity,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listCondition, setListCondition] = React.useState<{
    startDateTime: Moment;
    reservationIds: string[];
    resourceKey: string;
    productId: string;
    occupiedQuantity: number;
  } | null>(null);

  const activeOrganization = useSelector(activeUserOrganizationSelector);
  React.useEffect(() => {
    if (date) {
      dispatch(
        fetchDispatchReservations({
          date: date,
        })
      );
    }
  }, [date]);

  const reservations = useSelector((state: ReduxState) =>
    state.dispatch.reservations.map((reservation) =>
      toManifestReservationShape(reservation, t)
    )
  );

  return (
    <>
      <Modal
        title={t('Edit Resource Availability: {{date}}', { date })}
        open={open}
        onClose={() => {
          onClose && onClose();
        }}
        onOpen={() => {
          onOpen && onOpen();
        }}
      >
        <Form
          onSubmit={async (values: FormValues) => {
            try {
              if (resourceQuantity?.id !== '') {
                if (defaultQuantity === Number(values.quantity)) {
                  await dispatch(
                    deleteResourceQuantity(resourceQuantity?.id ?? '')
                  );
                } else {
                  await dispatch(
                    updateResourceQuantity(resourceQuantity?.id ?? '', {
                      quantity: Number(values.quantity ?? 0),
                    })
                  );
                }
              } else {
                await dispatch(
                  createResourceQuantity({
                    ...resourceQuantity,
                    quantity: Number(values.quantity ?? 0),
                  })
                );
              }
              onClose?.();
            } catch (err) {
              console.log(err);
              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
          initialValues={{ quantity: resourceQuantity?.quantity || '0' }}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitError, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <Modal.Box>
                    <FieldWrapper label={t('Resource Name')}>
                      {resourceKey}
                    </FieldWrapper>
                  </Modal.Box>

                  <Modal.Box>
                    <div className={styles['c-table-nowrap']}>
                      <table>
                        <thead>
                          <tr>
                            <th>{t('Quantity')}</th>
                            <th>{t('New Quantity')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              {resourceQuantity?.quantity}
                            </td>
                            <td>
                              <Field name={'quantity'}>
                                {({ input }) => (
                                  <Select
                                    options={[...Array(100)].map((_, i) => {
                                      return {
                                        text: `${i}`,
                                        value: `${i}`,
                                      };
                                    })}
                                    value={input.value}
                                    onChange={(_, { value }) =>
                                      input.onChange(value)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal.Box>

                  <Modal.Box>
                    <div className={styles['c-table-nowrap']}>
                      <table style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '15%' }}>{t('Start Time')}</th>
                            <th style={{ width: '15%' }}>
                              {t('Reserved Resource')}
                            </th>
                            <th>{t('Product')}</th>
                            <th style={{ width: '15%' }}>
                              {t('Reservations')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {occupations?.map((occupation, index1) => {
                            return (
                              <tr key={index1}>
                                <td>{occupation.startTime}</td>
                                <td>
                                  {occupation.quantity} /{' '}
                                  {resourceQuantity?.quantity ?? 0}
                                </td>
                                <td>{occupation.productName}</td>
                                <td>
                                  <a
                                    className={baseStyles['link']}
                                    onClick={() =>
                                      setListCondition({
                                        startDateTime: moment.tz(
                                          date + ' ' + occupation.startTime,
                                          activeOrganization?.default_timezone ??
                                            'UTC'
                                        ),
                                        reservationIds:
                                          occupation.reservationIds ?? [],
                                        resourceKey: resourceKey ?? '',
                                        productId: occupation.productId ?? '',
                                        occupiedQuantity: occupation.quantity,
                                      })
                                    }
                                  >
                                    {t('List')}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Modal.Box>
                </Modal.Content>
                <Modal.Actions>
                  {submitError && (
                    <p className={baseStyles['base-form-box__err']}>
                      {submitError}
                    </p>
                  )}
                  <Button.Cancel
                    onClick={() => {
                      onClose && onClose();
                    }}
                  >
                    {t('Discard')}
                  </Button.Cancel>
                  <Button
                    loading={submitting}
                    size="middle"
                    style="blue"
                    type="submit"
                  >
                    {t('Save')}
                  </Button>
                </Modal.Actions>
              </form>
            );
          }}
        </Form>
      </Modal>
      <ResourceAvailabilityReservationListModal
        dateTime={listCondition?.startDateTime ?? null}
        open={Boolean(listCondition && listCondition.reservationIds.length > 0)}
        onClose={() => setListCondition(null)}
        reservations={reservations.filter((reservation) =>
          (listCondition?.reservationIds ?? []).includes(reservation.id)
        )}
        occupiedQuantity={listCondition?.occupiedQuantity ?? 0}
        quantity={resourceQuantity?.quantity ?? 0}
        resourceKey={listCondition?.resourceKey ?? ''}
        productId={listCondition?.productId ?? ''}
      />
    </>
  );
};
