import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import querystring from 'query-string';

import type { LanguageISO } from 'shared/libraries/i18n';
import { BookingSourceType } from 'client/libraries/util/getBookingSourceOptions';
import * as Swagger from 'shared/models/swagger';
import {
  reservationColumnCandidatesSelector,
  reservationVisibleColumnsSelector,
} from 'client/reducers/reservationTableControls';
import { Button } from 'client/components/v3/Common/Button';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import type { TranslateFuncType } from 'client/components/Translate';
import type { CustomTableColumn } from 'client/components/v3/Table/CustomTable';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
//import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import { ReservationSearchQueryDisplayBox } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import { ReservationDataExportOrder } from 'shared/models/swagger';

//import styles from './DownloadListTab.module.css';

const getExportOrderBy = (
  order: ReservationDataExportOrder
): SearchReservationsRequest['orderBy'] => {
  switch (order.order_by) {
    case 'last_updated_date_time_utc':
      return order.is_ascending ? 'last_updated_asc' : 'last_updated_desc';
    case 'booked_date_time_utc':
      return order.is_ascending ? 'booked_asc' : 'booked_desc';
    case 'start_date_time_utc':
      return order.is_ascending ? 'participation_asc' : 'participation_desc';
    case 'family_name':
      return 'family_name';
  }

  return 'last_updated_desc';
};

const convertReservationDataExportOrderToReservationSearchRequest = (
  order: ReservationDataExportOrder,
  timezone: string,
  locale: LanguageISO
): SearchReservationsRequest => {
  const getDateTime = (utcTime: string) =>
    moment.tz(utcTime, timezone).locale(locale).format('ll');
  const getDateTimeSubtract1Day = (utcTime: string) =>
    moment.tz(utcTime, timezone).locale(locale).subtract(1).format('ll');
  return {
    agentReference: order.agent_reference ?? '',
    agentIds: order.agent_ids ?? [],
    supplierReference: order.supplier_reference ?? '',
    id: order.reservation_id ?? '',
    statuses: order.statuses ?? [],
    customerGivenName: order.given_name ?? '',
    customerFamilyName: order.family_name ?? '',
    customerPhone: order.phone ?? '',
    customerEmail: order.email ?? '',
    bookingSourceTypes: order.booking_sources ?? [],
    groupIds: order.group_ids ?? [],
    supplierIds: order.supplier_ids ?? [],
    productIds: order.product_ids ?? [],
    bookedDateFrom: order.booked_date_time_utc_from
      ? getDateTime(order.booked_date_time_utc_from)
      : '',
    bookedDateTo: order.booked_date_time_utc_to
      ? getDateTimeSubtract1Day(order.booked_date_time_utc_to)
      : '',
    participationDateFrom: order.start_date_local_from
      ? getDateTime(order.start_date_local_from)
      : '',
    participationDateTo: order.start_date_local_to
      ? getDateTimeSubtract1Day(order.start_date_local_to)
      : '',
    lastUpdatedDateFrom: order.last_updated_date_time_utc_from
      ? getDateTime(order.last_updated_date_time_utc_from)
      : '',
    lastUpdatedDateTo: order.last_updated_date_time_utc_to
      ? getDateTimeSubtract1Day(order.last_updated_date_time_utc_to)
      : '',
    orderBy: getExportOrderBy(order),
    supplierOrAgentReference: order.supplier_or_agent_reference ?? '',
    reservationLanguages: order.reservation_languages ?? [],
    mostRecentEmailBounced: order.most_recent_email_bounced?.value ?? false,
    pickupCheckinLocationName: order.pickup_checkin_location_name ?? '',
    waiverCompletionStatuses: order.waiver_completion_statuses ?? [],
    checkinStatuses: order.checkin_statuses ?? [],
    presetKey: '',
    dateFilterPreset: null,
    annualPassOnly: false,
    expirationPresetKey: '',
    expirationDateFrom: '',
    expirationDateTo: '',
    expirationDateFilterPreset: null,
    automaticContinuingStatus: null,
  };
};

export const useDownloadListColumns = (): CustomTableColumn[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const allColumns = getReservationCSVColumns(t);
  return [
    {
      Header: t('Created Date'),
      width: 'middle',
      accessor: (row) =>
        moment
          .tz(row.created_date_time_utc, row.timezone ?? 'UTC')
          .locale(locale)
          .format('lll'),
    },
    {
      Header: t('Downloads'),
      width: 'short',
      th: true,
      Cell: (cellInfo) => (
        <div
          style={{
            textAlign: 'left',
          }}
        >
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_EXPORTED' && (
            <Button
              color="white"
              size="sm"
              iconAfterText={
                <>
                  <i className="c-icon-outline-general-download-02"></i>
                </>
              }
              onClick={() => cellInfo?.original?.onDownload?.()}
            />
          )}
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_PENDING' && <>{t('In progress')}</>}
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_DELETED' && <>{t('Deleted')}</>}
        </div>
      ),
    },
    {
      Header: t('Account Name'),
      width: 'middle',
      accessor: (row) => {
        return getExportRequestUserName(row);
      },
    },
    {
      Header: t('Search Condition'),
      width: 'long',
      Cell: (cellInfo) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          <ReservationSearchQueryDisplayBox
            inline
            searchFilters={convertReservationDataExportOrderToReservationSearchRequest(
              cellInfo.original,
              cellInfo.original.timezone,
              locale
            )}
          />
        </TruncatedTextWithSeeMoreButton>
      ),
    },
    {
      Header: t('Download Items'),
      width: 'long',
      Cell: (cellInfo) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          {(cellInfo?.original?.columns || []).map(
            (column: any, idx: number) => {
              const columnInfo = allColumns.find(
                (c) => c.id.toUpperCase() === column.toUpperCase()
              );

              if (!columnInfo) {
                return null;
              }

              if (
                piiItems
                  .map((item) => item.toUpperCase())
                  .includes(columnInfo.id.toUpperCase())
              ) {
                return (
                  <span
                    style={{
                      color: 'red',
                    }}
                    key={idx}
                  >
                    {columnInfo.Header}{' '}
                  </span>
                );
              }

              return <span key={idx}>{columnInfo.Header} </span>;
            }
          )}
        </TruncatedTextWithSeeMoreButton>
      ),
    },
    {
      Header: t('Log'),
      width: 'middle',
      Cell: (cellInfo: any) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          {(cellInfo?.original?.data_download_logs || []).length == 0 ? (
            <div>-</div>
          ) : (
            <>
              {(cellInfo?.original?.data_download_logs || []).map(
                (log: any) => (
                  <>
                    <div>
                      {moment
                        .tz(
                          log.date_time_utc,
                          cellInfo.original?.timezone ?? 'UTC'
                        )
                        .locale(locale)
                        .format('lll')}
                    </div>
                    <div>{getDownloadUserName(log)}</div>
                  </>
                )
              )}
            </>
          )}
        </TruncatedTextWithSeeMoreButton>
      ),
    },
  ];
};

export const getDownloadUserName = (log: any) => {
  if (log?.name) {
    return log?.name;
  }

  if (log?.user_info?.login_account?.role === 'nutmeg_admin') {
    return log?.user_info?.login_account?.name;
  }

  return log?.user_info?.account?.name;
};

export const getExportRequestUserName = (reservationDataExportOrder: any) => {
  if (reservationDataExportOrder?.name) {
    return reservationDataExportOrder?.name;
  }

  if (
    reservationDataExportOrder?.action_source?.user_info?.login_account
      ?.role === 'nutmeg_admin'
  ) {
    return reservationDataExportOrder?.action_source?.user_info?.login_account
      ?.name;
  }

  return reservationDataExportOrder?.action_source?.user_info?.account?.name;
};

export const convertFilterToSearchCondition = (
  condition: Record<string, string>
) => {
  const { t } = useTranslation();
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const parsed = querystring.parse(condition?.filter ?? '');
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (parsed.product_id) {
    let productIds = [];

    if (typeof parsed.product_id === 'string') {
      productIds = [parsed.product_id];
    } else if (Array.isArray(parsed.product_id)) {
      productIds = parsed.product_id;
    }

    filters.push({
      typeText: t('Products'),
      filterValue: productIds
        .map(
          (productId: any) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t: any) => Boolean(t))
        .join(','),
    });
  }

  if (parsed.agent_reference) {
    filters.push({
      typeText: t('Application Number'),
      filterValue: String(parsed?.agent_reference) ?? '',
    });
  }

  if (parsed.supplier_reference) {
    filters.push({
      typeText: t('Confirmation Number'),
      filterValue: String(parsed?.supplier_reference) ?? '',
    });
  }

  if (parsed.id) {
    filters.push({
      typeText: t('#'),
      filterValue: String(parsed?.id) ?? '',
    });
  }

  if (condition.start_date_local_from || condition.start_date_local_to) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${condition.start_date_local_from ?? ''} ~ ${
        condition.start_date_local_to ?? ''
      }`,
    });
  }

  if (
    condition.booked_date_time_utc_from ||
    condition.booked_date_time_utc_to
  ) {
    let start = '';

    if (condition.booked_date_time_utc_from) {
      start = moment
        .tz(condition.booked_date_time_utc_from, condition.timezone ?? 'UTC')
        .format('YYYY-MM-DD');
    }

    let end = '';

    if (condition.booked_date_time_utc_to) {
      end = moment
        .tz(condition.booked_date_time_utc_to, condition.timezone ?? 'UTC')
        .format('YYYY-MM-DD');
    }

    filters.push({
      typeText: t('Booked Date'),
      filterValue: `${start} ~ ${end}`,
    });
  }

  if (
    condition.last_updated_date_time_utc_from ||
    condition.last_updated_date_time_utc_to
  ) {
    let start = '';

    if (condition.last_updated_date_time_utc_from) {
      start = moment
        .tz(
          condition.last_updated_date_time_utc_from,
          condition.timezone ?? 'UTC'
        )
        .format('YYYY-MM-DD');
    }

    let end = '';

    if (condition.last_updated_date_time_utc_to) {
      end = moment
        .tz(
          condition.last_updated_date_time_utc_to,
          condition.timezone ?? 'UTC'
        )
        .format('YYYY-MM-DD');
    }

    filters.push({
      typeText: t('Last Updated Date'),
      filterValue: `${start} ~ ${end}`,
    });
  }

  if (
    parsed['field_responses.given_name.response'] &&
    Array.isArray(parsed['field_responses.given_name.response']) &&
    parsed['field_responses.given_name.response'].length > 0
  ) {
    const name = (parsed['field_responses.given_name.response'] || []).shift();
    filters.push({
      typeText: t('Given Name'),
      filterValue: name as any as string,
    });
  } else {
    if (parsed['field_responses.kana_given_name.response']) {
      const name = parsed['field_responses.kana_given_name.response'] || '';
      filters.push({
        typeText: t('Given Name'),
        filterValue: name as any as string,
      });
    }
  }

  if (
    parsed['field_responses.family_name.response'] &&
    Array.isArray(parsed['field_responses.family_name.response']) &&
    parsed['field_responses.family_name.response'].length > 0
  ) {
    const name = (parsed['field_responses.family_name.response'] || []).shift();
    filters.push({
      typeText: t('Family Name'),
      filterValue: name as any as string,
    });
  } else {
    if (parsed['field_responses.kana_family_name.response']) {
      const name = parsed['field_responses.kana_family_name.response'] || '';
      filters.push({
        typeText: t('Family Name'),
        filterValue: name as any as string,
      });
    }
  }

  return (
    <>
      {filters.map((f) => (
        <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
      ))}
    </>
  );
};

export const getReservationCSVColumns = (
  t: TranslateFuncType
): ColumnType[] => {
  return [
    ...getReservationTableColumns(t, 'en'),
    {
      Header: t('Action Source'),
      id: 'ACTION_SOURCE',
    },
    {
      Header: t('Currency Code'),
      id: 'CURRENCY_CODE',
    },
    {
      Header: t('Gross'),
      id: 'GROSS',
    },
    {
      Header: t('Net'),
      id: 'NET',
    },
    {
      Header: t('Commission'),
      id: 'COMMISSION',
    },
    {
      Header: t('Amount due by agent'),
      id: 'AMOUNT_DUE_BY_AGENT',
    },
    {
      Header: t('Amount due by supplier'),
      id: 'AMOUNT_DUE_BY_SUPPLIER',
    },
    {
      Header: t('Reservation Form: Full Name'),
      id: 'RESERVATION_FORM_FULL_NAME',
    },
    {
      Header: t('Reservation Form: Full Name(Kanji)'),
      id: 'RESERVATION_FORM_FULL_NAME_KANJI',
    },
    {
      Header: t('Reservation Form: Title'),
      id: 'RESERVATION_FORM_TITLE',
    },
    {
      Header: t('Reservation Form: Email Address'),
      id: 'RESERVATION_FORM_EMAIL',
    },
    {
      Header: t('Reservation Form: Phone Number'),
      id: 'RESERVATION_FORM_PHONE',
    },
    {
      Header: t('Reservation Form: International Phone Number'),
      id: 'RESERVATION_FORM_INTERNATIONAL_PHONE',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Name"),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
    },
    {
      Header: t("Reservation Form: Emergency Contact's Phone Number"),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
    },
    {
      Header: t('Reservation Form: Height (cm)'),
      id: 'RESERVATION_FORM_HEIGHT',
    },
    {
      Header: t('Reservation Form: Weight (kg)'),
      id: 'RESERVATION_FORM_WEIGHT',
    },
    {
      Header: t('Reservation Form: Date of Birth'),
      id: 'RESERVATION_FORM_DATE_OF_BIRTH',
    },
    {
      Header: t('Reservation Form: Age'),
      id: 'RESERVATION_FORM_AGE',
    },
    {
      Header: t('Reservation Form: Gender'),
      id: 'RESERVATION_FORM_GENDER',
    },
    {
      Header: t('Reservation Form: Home Address'),
      id: 'RESERVATION_FORM_HOME_ADDRESS',
    },
    {
      Header: t('Reservation Form: Custom'),
      id: 'RESERVATION_FORM_CUSTOM',
    },
    {
      Header: t('Family Name'),
      id: 'FAMILY_NAME',
    },
    {
      Header: t('Given Name'),
      id: 'GIVEN_NAME',
    },
    {
      Header: t('Reservation Form: Family Name(Kana)'),
      id: 'RESERVATION_FORM_FAMILY_NAME_KANA',
    },
    {
      Header: t('Reservation Form: Given Name(Kana)'),
      id: 'RESERVATION_FORM_GIVEN_NAME_KANA',
    },
    {
      Header: t('Memo'),
      id: 'SUPPLIER_INTERNAL_NOTES',
    },
    {
      Header: t('Internal Note'),
      id: 'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
    },
    {
      Header: t('Cancellation Fee Gross'),
      id: 'CANCELLATION_FEE_GROSS',
    },
    {
      Header: t('Cancellation Fee Net'),
      id: 'CANCELLATION_FEE_NET',
    },
    {
      Header: t('Cancellation Fee Commission'),
      id: 'CANCELLATION_FEE_COMMISSION',
    },
  ];
};
export const csvColumnCandidatesSelector = createSelector(
  reservationColumnCandidatesSelector,
  (tableColumnCandidates) => [
    ...tableColumnCandidates,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
    'RESERVATION_FORM_FULL_NAME',
    'RESERVATION_FORM_FULL_NAME_KANJI',
    'RESERVATION_FORM_TITLE',
    'RESERVATION_FORM_EMAIL',
    'RESERVATION_FORM_PHONE',
    'RESERVATION_FORM_INTERNATIONAL_PHONE',
    'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
    'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
    'RESERVATION_FORM_HEIGHT',
    'RESERVATION_FORM_WEIGHT',
    'RESERVATION_FORM_DATE_OF_BIRTH',
    'RESERVATION_FORM_AGE',
    'RESERVATION_FORM_GENDER',
    'RESERVATION_FORM_HOME_ADDRESS',
    'RESERVATION_FORM_CUSTOM',
    'FAMILY_NAME',
    'GIVEN_NAME',
    'RESERVATION_FORM_FAMILY_NAME_KANA',
    'RESERVATION_FORM_GIVEN_NAME_KANA',
    'CANCELLATION_FEE_GROSS',
    'CANCELLATION_FEE_NET',
    'CANCELLATION_FEE_COMMISSION',
  ]
);
export const csvInitialSelectedColumnsSelector = createSelector(
  reservationVisibleColumnsSelector,
  (tableVisibleColumns) => [
    ...tableVisibleColumns,
    'ACTION_SOURCE',
    'CURRENCY_CODE',
    'GROSS',
    'NET',
    'COMMISSION',
    'AMOUNT_DUE_BY_AGENT',
    'AMOUNT_DUE_BY_SUPPLIER',
  ]
);
export const piiItems = [
  'guest_display_name',
  'RESERVATION_FORM_FULL_NAME',
  'RESERVATION_FORM_FULL_NAME_KANJI',
  'RESERVATION_FORM_TITLE',
  'RESERVATION_FORM_EMAIL',
  'RESERVATION_FORM_PHONE',
  'RESERVATION_FORM_INTERNATIONAL_PHONE',
  'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
  'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
  'RESERVATION_FORM_DATE_OF_BIRTH',
  'RESERVATION_FORM_HOME_ADDRESS',
  'FAMILY_NAME',
  'GIVEN_NAME',
  'RESERVATION_FORM_FAMILY_NAME_KANA',
  'RESERVATION_FORM_GIVEN_NAME_KANA',
];

export type DateFilterPreset =
  | 'LAST_UPDATED_1_DAY'
  | 'LAST_UPDATED_3_DAYS'
  | 'LAST_UPDATED_7_DAYS'
  | 'BOOKED_AT_1_DAY'
  | 'BOOKED_AT_3_DAYS'
  | 'BOOKED_AT_7_DAYS'
  | 'PARTICIPATION_1_DAY'
  | 'PARTICIPATION_3_DAYS'
  | 'PARTICIPATION_7_DAYS'
  | 'EXPIRATION_1_WEEK'
  | 'EXPIRATION_2_WEEKS'
  | 'EXPIRATION_1_MONTH'
  | 'EXPIRATION_3_MONTHS'
  | 'EXPIRATION_6_MONTHS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'LAST_UPDATED_1_DAY',
      'LAST_UPDATED_3_DAYS',
      'LAST_UPDATED_7_DAYS',
      'BOOKED_AT_1_DAY',
      'BOOKED_AT_3_DAYS',
      'BOOKED_AT_7_DAYS',
      'PARTICIPATION_1_DAY',
      'PARTICIPATION_3_DAYS',
      'PARTICIPATION_7_DAYS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};

export const getExpirationFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    [
      'EXPIRATION_1_WEEK',
      'EXPIRATION_2_WEEKS',
      'EXPIRATION_1_MONTH',
      'EXPIRATION_3_MONTHS',
      'EXPIRATION_6_MONTHS',
    ] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'LAST_UPDATED_1_DAY':
      return t('Last updated, past {{count}} days', {
        count: 1,
      });

    case 'LAST_UPDATED_3_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 3,
      });

    case 'LAST_UPDATED_7_DAYS':
      return t('Last updated, past {{count}} days', {
        count: 7,
      });

    case 'BOOKED_AT_1_DAY':
      return t('Booked at, past {{count}} days', {
        count: 1,
      });

    case 'BOOKED_AT_3_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 3,
      });

    case 'BOOKED_AT_7_DAYS':
      return t('Booked at, past {{count}} days', {
        count: 7,
      });

    case 'PARTICIPATION_1_DAY':
      return t('Participation, next 1 day', {
        count: 1,
      });

    case 'PARTICIPATION_3_DAYS':
      return t('Participation, next {{count}} days', {
        count: 3,
      });

    case 'PARTICIPATION_7_DAYS':
      return t('Participation, next {{count}} days', {
        count: 7,
      });
    case 'EXPIRATION_1_WEEK':
      return t('Expiration, next 1 week');
    case 'EXPIRATION_2_WEEKS':
      return t('Expiration, next {{count}} weeks', {
        count: 2,
      });
    case 'EXPIRATION_1_MONTH':
      return t('Expiration, next 1 month');
    case 'EXPIRATION_3_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 3,
      });
    case 'EXPIRATION_6_MONTHS':
      return t('Expiration, next {{count}} months', {
        count: 6,
      });
  }
};

export type SearchReservationsRequest = {
  presetKey: string;
  agentReference: string;
  agentIds: string[];
  supplierReference: string;
  id: string;
  statuses: Swagger.ReservationStatus[];
  customerGivenName: string;
  customerFamilyName: string;
  customerPhone: string;
  customerEmail: string;
  bookingSourceTypes: BookingSourceType[];
  groupIds: string[];
  supplierIds: string[];
  productIds: string[];
  bookedDateFrom: string;
  bookedDateTo: string;
  participationDateFrom: string;
  participationDateTo: string;
  lastUpdatedDateFrom: string;
  lastUpdatedDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
  orderBy: string;
  supplierOrAgentReference: string;
  reservationLanguages: Swagger.SourceLanguage[];
  mostRecentEmailBounced: boolean;
  pickupCheckinLocationName: string;
  waiverCompletionStatuses: Swagger.WaiverCompletionStatus[];
  checkinStatuses: Swagger.CheckinStatus[];

  annualPassOnly: boolean;
  expirationPresetKey: string;
  expirationDateFrom: string;
  expirationDateTo: string;
  expirationDateFilterPreset: DateFilterPreset | null;
  automaticContinuingStatus: 'ON' | 'OFF' | null;
};

export type SearchReservationsRequestKey =
  | 'presetKey'
  | 'agentReference'
  | 'agentIds'
  | 'supplierReference'
  | 'id'
  | 'statuses'
  | 'customerGivenName'
  | 'customerFamilyName'
  | 'customerPhone'
  | 'customerEmail'
  | 'bookingSourceTypes'
  | 'groupIds'
  | 'supplierIds'
  | 'productIds'
  | 'bookedDateFrom'
  | 'bookedDateTo'
  | 'participationDateFrom'
  | 'participationDateTo'
  | 'lastUpdatedDateFrom'
  | 'lastUpdatedDateTo'
  | 'dateFilterPreset'
  | 'orderBy'
  | 'supplierOrAgentReference'
  | 'reservationLanguages'
  | 'mostRecentEmailBounced'
  | 'pickupCheckinLocationName'
  | 'waiverCompletionStatuses'
  | 'checkinStatuses'
  | 'annualPassOnly'
  | 'expirationPresetKey'
  | 'expirationDateFrom'
  | 'expirationDateTo'
  | 'expirationDateFilterPreset'
  | 'automaticContinuingStatus';

export const buildSearchReservationsRequest = (
  req: SearchReservationsRequest
): Swagger.SearchReservationsRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';
  let bookedDateTimeUtcFrom = '';
  let bookedDateTimeUtcTo = '';
  let lastUpdatedDateTimeUtcFrom = '';
  let lastUpdatedDateTimeUtcTo = '';
  let expirationDateFrom = '';
  let expirationDateTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'LAST_UPDATED_1_DAY':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_3_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(3, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'LAST_UPDATED_7_DAYS':
        lastUpdatedDateTimeUtcFrom = now
          .subtract(7, 'days')
          .format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_1_DAY':
        bookedDateTimeUtcFrom = now.subtract(1, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_3_DAYS':
        bookedDateTimeUtcFrom = now.subtract(3, 'days').format('YYYY-MM-DD');
        break;

      case 'BOOKED_AT_7_DAYS':
        bookedDateTimeUtcFrom = now.subtract(7, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'PARTICIPATION_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.participationDateFrom;
    startDateLocalTo = req.participationDateTo
      ? moment(req.participationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
    bookedDateTimeUtcFrom = req.bookedDateFrom
      ? moment(req.bookedDateFrom).utc().format()
      : '';
    bookedDateTimeUtcTo = req.bookedDateTo
      ? moment(req.bookedDateTo).add(1, 'day').utc().format()
      : '';
    lastUpdatedDateTimeUtcFrom = req.lastUpdatedDateFrom
      ? moment(req.lastUpdatedDateFrom).utc().format()
      : '';
    lastUpdatedDateTimeUtcTo = req.lastUpdatedDateTo
      ? moment(req.lastUpdatedDateTo).add(1, 'day').utc().format()
      : '';
  }

  if (req.expirationDateFilterPreset) {
    const now = moment();
    switch (req.expirationDateFilterPreset) {
      case 'EXPIRATION_1_WEEK':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(1, 'week').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_2_WEEKS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(2, 'weeks').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_1_MONTH':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(1, 'month').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_3_MONTHS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(3, 'months').format('YYYY-MM-DD');
        break;
      case 'EXPIRATION_6_MONTHS':
        expirationDateFrom = now.format('YYYY-MM-DD');
        expirationDateTo = now.add(6, 'months').format('YYYY-MM-DD');
        break;
    }
  } else {
    expirationDateFrom = req.expirationDateFrom;
    expirationDateTo = req.expirationDateTo
      ? moment(req.expirationDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
  }

  let orderBy = '';
  let isAscending = true;
  switch (req.orderBy) {
    case 'auto':
      if (
        req.customerGivenName ||
        req.customerFamilyName ||
        req.agentReference ||
        req.supplierReference
      ) {
        // Use default relevance sorting if names or booking references are searched for
        break;
      }

      // Otherwise, sort by date field
      if (lastUpdatedDateTimeUtcFrom || lastUpdatedDateTimeUtcTo) {
        orderBy = 'last_updated_date_time_utc';
        isAscending = false;
        break;
      }
      if (bookedDateTimeUtcFrom || bookedDateTimeUtcTo) {
        orderBy = 'booked_date_time_utc';
        isAscending = false;
        break;
      }
      if (startDateLocalFrom || startDateLocalTo) {
        orderBy = 'start_date_time_utc';
        isAscending = true;
        break;
      }
      if (expirationDateFrom || expirationDateTo) {
        orderBy = 'checkin_info.expiration_date_time_utc';
        isAscending = true;
        break;
      }

      // Default to sorting by last updated.
      orderBy = 'last_updated_date_time_utc';
      isAscending = false;
      break;
    case 'last_updated_asc':
      orderBy = 'last_updated_date_time_utc';
      isAscending = true;
      break;
    case 'last_updated_desc':
      orderBy = 'last_updated_date_time_utc';
      isAscending = false;
      break;
    case 'booked_asc':
      orderBy = 'booked_date_time_utc';
      isAscending = true;
      break;
    case 'booked_desc':
      orderBy = 'booked_date_time_utc';
      isAscending = false;
      break;
    case 'participation_asc':
      orderBy = 'start_date_time_utc';
      isAscending = true;
      break;
    case 'participation_desc':
      orderBy = 'start_date_time_utc';
      isAscending = false;
      break;
    case 'expiration_asc':
      orderBy = 'checkin_info.expiration_date_time_utc';
      isAscending = true;
      break;
    case 'expiration_desc':
      orderBy = 'checkin_info.expiration_date_time_utc';
      isAscending = false;
      break;
    case 'family_name':
      orderBy = 'family_name';
      isAscending = true;
      break;
  }

  let reservationType = 'RESERVATION_TYPE_NORMAL';
  if (req.annualPassOnly) {
    reservationType = 'RESERVATION_TYPE_ANNUAL_PASS';
  }

  return {
    ...(!req.annualPassOnly
      ? {
          start_date_local_from: startDateLocalFrom,
          start_date_local_to: startDateLocalTo,
          booked_date_time_utc_from: bookedDateTimeUtcFrom,
          booked_date_time_utc_to: bookedDateTimeUtcTo,
          last_updated_date_time_utc_from: lastUpdatedDateTimeUtcFrom,
          last_updated_date_time_utc_to: lastUpdatedDateTimeUtcTo,
        }
      : {
          expiration_date_time_utc_from: expirationDateFrom,
          expiration_date_time_utc_to: expirationDateTo,
          ...(req.automaticContinuingStatus
            ? { automatic_continuing_status: req.automaticContinuingStatus }
            : {}),
        }),
    agent_reference: req.agentReference,
    booking_sources: req.bookingSourceTypes,
    group_ids: req.groupIds,
    agent_ids: req.agentIds,
    supplier_reference: req.supplierReference,
    reservation_id: req.id,
    statuses: req.statuses,
    family_name: req.customerFamilyName,
    given_name: req.customerGivenName,
    phone: req.customerPhone,
    email: req.customerEmail,
    supplier_ids: req.supplierIds,
    supplier_or_agent_reference: req.supplierOrAgentReference,
    reservation_languages: req.reservationLanguages,
    most_recent_email_bounced: req.mostRecentEmailBounced,
    product_ids: req.productIds,
    pickup_checkin_location_name: req.pickupCheckinLocationName,
    waiver_completion_statuses: req.waiverCompletionStatuses,
    checkin_statuses: req.checkinStatuses,
    reservation_type: [reservationType] as Swagger.ReservationType[],
    order_by: orderBy,
    is_ascending: isAscending,
  };
};
