import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './MenuButton.module.css';

export const MenuButton: React.FC = () => {
  const { t } = useTranslation();

  const restaurantId = useParams<{ id: string }>().id;

  return (
    <section className={styles.menu}>
      <h2 className={styles.sectionTitle}>{t('menu')}</h2>
      <Link to={`/mobileorders/restaurants/${restaurantId}/menu`}>
        <button type="button" className={styles.editMenuButton}>
          <span>{`edit this restaurant's menu`}</span>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccb9372723f86927531bad403c7b224343cdf4c3368199c07129b73e815adc56?apiKey=3d59b5c84d524ccda3d6150a37390818&&apiKey=3d59b5c84d524ccda3d6150a37390818"
            alt="Edit menu icon"
          />
        </button>
      </Link>
    </section>
  );
};
