import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/base.module.css';
import type { EquipmentBlockInstancePrice } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchContractedOrganizations } from 'client/actions/organizations';

import { EditDeleteCell } from './EditDeleteCell';
import { EditEquipmentBlockInstancePriceModal } from './EditEquipmentBlockInstancePriceModal';

export const SeatManagementPricing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(50);

  const organization = useSelector(activeUserOrganizationSelector);

  const equipmentBlockInstancePrices = React.useMemo(() => {
    return (
      organization?.seat_management_settings?.equipment_block_instance_prices ||
      []
    );
  }, [organization]);

  React.useEffect(() => {
    dispatch(fetchContractedOrganizations());
  }, []);

  const getColumns = (
    t: TranslateFuncType
  ): ColumnType<EquipmentBlockInstancePrice>[] => {
    return [
      {
        Header: '',
        id: 'edit',
        accessor: (row) => {
          return <EditDeleteCell equipmentBlockInstancePrice={row} />;
        },
        width: 100,
      },
      {
        Header: t('Title'),
        id: 'title',
        accessor: (row) => row.title,
        width: 300,
      },
      {
        Header: t('Gross'),
        id: 'gross',
        accessor: (row) => row.gross,
      },
      {
        Header: t('Net'),
        id: 'Net',
        accessor: (row) => row?.net?.[0].net_fixed || 0,
      },
    ];
  };

  return (
    <div className={clsx(baseStyles['base-main__body__box__body'])}>
      <Box mb={2}>
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Price')}
        </Button>
      </Box>
      {equipmentBlockInstancePrices?.length !== 0 && (
        <GenericTable<EquipmentBlockInstancePrice>
          items={(equipmentBlockInstancePrices ?? []).filter((_, index) => {
            const start = (currentPage - 1) * rowCount;
            const end = start + rowCount;
            return index >= start && index < end;
          })}
          totalCount={equipmentBlockInstancePrices?.length ?? 0}
          columns={getColumns(t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
          hideScrollButtons={true}
        />
      )}
      {showEditModal && (
        <EditEquipmentBlockInstancePriceModal
          equipmentBlockInstancePriceKey={''}
          onClose={() => setShowEditModal(false)}
          open={showEditModal}
        />
      )}
    </div>
  );
};
