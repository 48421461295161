import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  name: string;
};

export const LocationListEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles['p-list__item'], styles['row'])}>
      <FieldArray name={name}>
        {({ fields, meta: { error, touched } }) => (
          <>
            <div className={clsx(styles['p-list__item__body'], styles['row'])}>
              <div className={styles['p-list__item__body__list']}>
                {fields.length !== 0 &&
                  fields.map((name, idx) => (
                    <div
                      key={name}
                      className={clsx(styles['p-list__item__body__flex'])}
                    >
                      <Field
                        name={name}
                        validate={(value: any | null | undefined): string => {
                          if (!value?.locationName) return t('Required');
                          return '';
                        }}
                      >
                        {({ input, meta: { touched, error } }) => (
                          <>
                            <div
                              className={
                                styles['p-list__item__body__flex__main']
                              }
                            >
                              <LocationSearchInput
                                location={input.value.locationName}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onSearchChange={() => {}}
                                onLocationSelect={({ title, key }) =>
                                  input.onChange({
                                    locationName: title,
                                    googlePlaceId: key,
                                  })
                                }
                                error={touched && error}
                                maxWidth={800}
                              />
                            </div>
                            <div
                              className={
                                styles['p-list__item__body__flex__actions']
                              }
                            >
                              <Button
                                size="icon"
                                color="tertiarygray"
                                onClick={() => fields.remove(idx)}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                              />
                            </div>
                          </>
                        )}
                      </Field>
                    </div>
                  ))}
                {error && touched && (
                  <p className={baseStyles['u-error-msg']}>{error}</p>
                )}

                <div className={baseStyles['u-mt-2']}>
                  <a
                    className={styles['p-box__table2__actions__add']}
                    onClick={() => {
                      if (fields.length && fields.length > 0) {
                        (fields as any).insertAt(fields.length + 1, '');
                      } else {
                        (fields as any).insertAt(0, '');
                      }
                    }}
                  >
                    <i className="c-icon-outline-general-plus-circle"></i>
                    {t('Add Hotel')}
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};
