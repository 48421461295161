import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';

import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { updateManifestAlternateNameSettings } from 'client/actions/manifestSettings';
import { manifestAlternateNameSettingsSelector } from 'client/reducers/manifestSettings';
import { getArrayMutators } from 'client/libraries/util/form';
import type {
  ManifestAlternateName,
  ManifestIdAlternateName,
} from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import baseStyles from 'client/v3-base.module.css';
import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';
import { ReduxState } from 'client/reducers';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  onSubmit: (arg0: boolean) => void;
};

export const EditOutputAbbreviationTable = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alternateNameSettings = useSelector(
    manifestAlternateNameSettingsSelector
  );
  const products = useSelector(summariesWithBookmarksSelector);

  const agents = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const productNameOptions = products.map((product) => {
    return {
      value: product.id,
      text: `${product.product_name || ''}(${
        product.supplier_reference || ''
      })`,
    };
  });

  const agentNameOptions = agents.map((agent) => {
    return {
      value: agent.id,
      text: agent.name,
    };
  });

  return (
    <>
      <Form
        onSubmit={async (values: {
          productNames: ManifestIdAlternateName[];
          locationNames: ManifestAlternateName[];
          agentNames: ManifestIdAlternateName[];
        }) => {
          try {
            await onSubmit(true);
            await dispatch(
              updateManifestAlternateNameSettings({
                id_alternate_names: values.productNames.filter((a) => {
                  return a.id !== '' && a.name !== '';
                }),
                alternate_names: values.locationNames.filter((a) => {
                  return a.key !== '' && a.name !== '';
                }),
                agent_id_alternate_names: values.agentNames.filter((a) => {
                  return a.id !== '' && a.name !== '';
                }),
              })
            );
            await onSubmit(false);
          } catch (err) {
            return { [FORM_ERROR]: t('Save Failed') };
          }
        }}
        initialValues={{
          productNames: alternateNameSettings.id_alternate_names || [],
          locationNames: alternateNameSettings.alternate_names || [],
          agentNames: alternateNameSettings.agent_id_alternate_names || [],
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, values }) => {
          return (
            <>
              {submitError && (
                <p className={baseStyles['u-error-msg']}>{submitError}</p>
              )}
              <form onSubmit={handleSubmit} id="outputAbbreviationForm">
                <>
                  <FieldArray name={'productNames'}>
                    {({ fields }) => {
                      return (
                        <div
                          className={
                            styles['p-manifests__customize__body__box']
                          }
                        >
                          <p
                            className={
                              styles['p-manifests__customize__body__box__ttl']
                            }
                          >
                            {t('Product name')}
                          </p>

                          {fields.length === 0 && (
                            <div>
                              {t('{{item}} is not registered.', {
                                item: t('Abbreviation'),
                              })}
                            </div>
                          )}
                          {fields.length !== 0 && (
                            <table
                              className={clsx(
                                tableStyles['c-tableSmall'],
                                tableStyles['row']
                              )}
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: '50%' }}>
                                    {t('Default name')}
                                  </th>
                                  <th style={{ width: '50%' }}>
                                    {t('Abbreviation')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map((name, idx) => {
                                  return (
                                    <tr key={`product-name-${idx}`}>
                                      <td
                                        className={
                                          tableStyles['c-tableSmall__spTtl']
                                        }
                                      >
                                        <Field name={`${name}.id`}>
                                          {({ input }) => (
                                            <SingleDropdown
                                              options={productNameOptions.filter(
                                                (option) => {
                                                  // Remove existing product name
                                                  return !values.productNames
                                                    ?.map((p) => p.id)
                                                    .includes(option.value);
                                                }
                                              )}
                                              selectedOption={
                                                productNameOptions.find(
                                                  (p) => p.value === input.value
                                                )?.text || ''
                                              }
                                              onChange={(value) => {
                                                input.onChange(value);
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </td>
                                      <td data-text={t('Abbreviation')}>
                                        <div
                                          className={
                                            styles[
                                              'p-manifests__customize__flex'
                                            ]
                                          }
                                        >
                                          <Field name={`${name}.name`}>
                                            {({ input }) => (
                                              <TextField {...input} />
                                            )}
                                          </Field>
                                          <Button
                                            size="icon"
                                            color="white"
                                            onClick={() => fields.remove(idx)}
                                            iconBeforeText={
                                              <i className="c-icon-outline-general-trash-03"></i>
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}

                          <a
                            className={styles['p-manifests__customize__add']}
                            onClick={() => {
                              fields.push({ id: '', name: '' });
                            }}
                          >
                            <i className="c-icon-outline-general-plus-circle"></i>
                            {t('Add')}
                          </a>
                        </div>
                      );
                    }}
                  </FieldArray>

                  <FieldArray name={'locationNames'}>
                    {({ fields }) => {
                      return (
                        <div
                          className={
                            styles['p-manifests__customize__body__box']
                          }
                        >
                          <p
                            className={
                              styles['p-manifests__customize__body__box__ttl']
                            }
                          >
                            {t('Location name')}
                          </p>

                          {fields.length === 0 && (
                            <div>
                              {t('{{item}} is not registered.', {
                                item: t('Abbreviation'),
                              })}
                            </div>
                          )}
                          {fields.length !== 0 && (
                            <table
                              className={clsx(
                                tableStyles['c-tableSmall'],
                                tableStyles['row']
                              )}
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: '50%' }}>
                                    {t('Default name')}
                                  </th>
                                  <th style={{ width: '50%' }}>
                                    {t('Abbreviation')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map((name, idx) => {
                                  return (
                                    <tr key={`product-name-${idx}`}>
                                      <td
                                        className={
                                          tableStyles['c-tableSmall__spTtl']
                                        }
                                      >
                                        <Field name={`${name}.key`}>
                                          {({ input }) => (
                                            <LocationSearchInput
                                              location={input.value}
                                              onSearchChange={(key) => {
                                                input.onChange(key);
                                              }}
                                              onLocationSelect={({
                                                title: key,
                                              }) => {
                                                input.onChange(key);
                                              }}
                                              maxWidth={274}
                                            />
                                          )}
                                        </Field>
                                      </td>
                                      <td data-text={t('Abbreviation')}>
                                        <div
                                          className={
                                            styles[
                                              'p-manifests__customize__flex'
                                            ]
                                          }
                                        >
                                          <Field name={`${name}.name`}>
                                            {({ input }) => (
                                              <TextField {...input} />
                                            )}
                                          </Field>
                                          <Button
                                            size="icon"
                                            color="white"
                                            onClick={() => fields.remove(idx)}
                                            iconBeforeText={
                                              <i className="c-icon-outline-general-trash-03"></i>
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}

                          <a
                            className={styles['p-manifests__customize__add']}
                            onClick={() => {
                              fields.push({ id: '', name: '' });
                            }}
                          >
                            <i className="c-icon-outline-general-plus-circle"></i>
                            {t('Add')}
                          </a>
                        </div>
                      );
                    }}
                  </FieldArray>

                  <FieldArray name={'agentNames'}>
                    {({ fields }) => {
                      return (
                        <div
                          className={
                            styles['p-manifests__customize__body__box']
                          }
                        >
                          <p
                            className={
                              styles['p-manifests__customize__body__box__ttl']
                            }
                          >
                            {t('Agent Name')}
                          </p>

                          {fields.length === 0 && (
                            <div>
                              {t('{{item}} is not registered.', {
                                item: t('Abbreviation'),
                              })}
                            </div>
                          )}
                          {fields.length !== 0 && (
                            <table
                              className={clsx(
                                tableStyles['c-tableSmall'],
                                tableStyles['row']
                              )}
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: '50%' }}>
                                    {t('Default name')}
                                  </th>
                                  <th style={{ width: '50%' }}>
                                    {t('Customized name')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map((name, idx) => {
                                  return (
                                    <tr key={`agent-name-${idx}`}>
                                      <td
                                        className={
                                          tableStyles['c-tableSmall__spTtl']
                                        }
                                      >
                                        <Field name={`${name}.id`}>
                                          {({ input }) => (
                                            <SingleDropdown
                                              options={agentNameOptions.filter(
                                                (option) => {
                                                  // Remove existing agent name
                                                  return !values.agentNames
                                                    ?.map((p) => p.id)
                                                    .includes(option.value);
                                                }
                                              )}
                                              selectedOption={
                                                agentNameOptions.find(
                                                  (p) => p.value === input.value
                                                )?.text || ''
                                              }
                                              onChange={(value) => {
                                                input.onChange(value);
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </td>
                                      <td data-text={t('Customized name')}>
                                        <div
                                          className={
                                            styles[
                                              'p-manifests__customize__flex'
                                            ]
                                          }
                                        >
                                          <Field name={`${name}.name`}>
                                            {({ input }) => (
                                              <TextField {...input} />
                                            )}
                                          </Field>
                                          <Button
                                            size="icon"
                                            color="white"
                                            onClick={() => fields.remove(idx)}
                                            iconBeforeText={
                                              <i className="c-icon-outline-general-trash-03"></i>
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}

                          <a
                            className={styles['p-manifests__customize__add']}
                            onClick={() => {
                              fields.push({ id: '', name: '' });
                            }}
                          >
                            <i className="c-icon-outline-general-plus-circle"></i>
                            {t('Add')}
                          </a>
                        </div>
                      );
                    }}
                  </FieldArray>
                </>
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
};
