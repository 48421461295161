import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';
import clsx from 'clsx';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { FocusableInput, FormError } from 'client/components/Form';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { PerChannelAllotment } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type Props = {
  name: string;
};
const defaultDedicatedAllotment: PerChannelAllotment = {
  channel: 'DIRECT_ALL',
  agentId: null,
  allotmentSlots: 10,
};
export const DedicatedAllotmentEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const editingProduct = React.useContext(EditingProductContext);
  const form = useForm();
  let channelOptions = [
    {
      value: 'DIRECT_ALL',
      text: t('Direct'),
    },
  ];

  const uniqueChannelsRequired = (value?: PerChannelAllotment[]): string => {
    if (value && value.length > 0) {
      const uniqueValues = _.uniqBy(value, (elem) =>
        elem.channel === 'DIRECT_ALL' ? 'DIRECT_ALL' : elem.agentId
      );

      if (uniqueValues.length !== value.length) {
        return t('Duplicate channels not allowed');
      }
    }

    return '';
  };

  if (editingProduct?.agents != null && editingProduct.agents.length > 0) {
    channelOptions = [
      {
        value: 'AGENT',
        text: t('Agent'),
      },
      ...channelOptions,
    ];
  }

  const agentOptions = (editingProduct?.agents ?? []).map((agent) => ({
    value: agent.id,
    text: agent.name,
  }));
  return (
    <div className={styles['p-box__table2__body__item__box']}>
      <FieldArray<PerChannelAllotment>
        name={name}
        validate={uniqueChannelsRequired as any}
      >
        {({ fields, meta: { error } }) => (
          <>
            <div className={fields.length ? baseStyles['u-mb-4'] : ''}>
              <Toggle
                label={t('Per-Channel Capacity Settings')}
                checked={Boolean(fields.length)}
                onChange={() => {
                  if (fields.length) {
                    const len = fields.length;

                    for (let i = 0; i < len; i++) {
                      fields.pop();
                    }
                  } else {
                    fields.insert(0, defaultDedicatedAllotment);
                  }
                }}
              />
            </div>
            {Boolean(fields.length) && (
              <div className={styles['p-box__table2__body__item__box__table']}>
                <div
                  className={clsx(
                    styles['p-box__table2__body__item__box__table__header'],
                    baseStyles['u-spHidden']
                  )}
                >
                  <div
                    className={
                      styles['p-box__table2__body__item__box__table__item']
                    }
                  >
                    <p
                      className={
                        styles[
                          'p-box__table2__body__item__box__table__item__ttl'
                        ]
                      }
                    >
                      {t('Channel')}
                    </p>
                  </div>
                  <div
                    className={
                      styles['p-box__table2__body__item__box__table__item']
                    }
                  >
                    <p
                      className={
                        styles[
                          'p-box__table2__body__item__box__table__item__ttl'
                        ]
                      }
                    >
                      {t('Agent Name')}
                    </p>
                  </div>
                  <div
                    className={
                      styles['p-box__table2__body__item__box__table__item']
                    }
                  >
                    <p
                      className={
                        styles[
                          'p-box__table2__body__item__box__table__item__ttl'
                        ]
                      }
                    >
                      {t('Inventory Count')}
                    </p>
                  </div>
                  <div
                    className={
                      styles['p-box__table2__body__item__box__table__item']
                    }
                  ></div>
                </div>
                {fields.map((fieldName, idx) => (
                  <div
                    key={fieldName}
                    className={
                      styles['p-box__table2__body__item__box__table__body']
                    }
                  >
                    <div
                      className={
                        styles['p-box__table2__body__item__box__table__item']
                      }
                    >
                      <p
                        className={clsx(
                          styles[
                            'p-box__table2__body__item__box__table__item__ttl'
                          ],
                          baseStyles['u-pcHidden']
                        )}
                      >
                        {t('Channel')}
                      </p>
                      <Field name={`${fieldName}.channel`}>
                        {({ input }) => (
                          <SingleDropdown
                            options={channelOptions.map((channelOption) => ({
                              value: channelOption.value,
                              text: channelOption.text,
                            }))}
                            selectedOption={input.value}
                            onChange={(value) => {
                              if (value === 'DIRECT_ALL') {
                                form.change(`${fieldName}.agentId`, null);
                              } else if (value) {
                                form.change(
                                  `${fieldName}.agentId`,
                                  agentOptions[0].value
                                );
                              }

                              input.onChange(value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      className={
                        styles['p-box__table2__body__item__box__table__item']
                      }
                    >
                      <p
                        className={clsx(
                          styles[
                            'p-box__table2__body__item__box__table__item__ttl'
                          ],
                          baseStyles['u-pcHidden']
                        )}
                      >
                        {t('Agent Name')}
                      </p>
                      {fields.value[idx]?.channel === 'AGENT' && (
                        <Field name={`${fieldName}.agentId`}>
                          {({ input }) => (
                            <SingleDropdown
                              options={agentOptions.map((agentOption) => ({
                                value: agentOption.value,
                                text: agentOption.text,
                              }))}
                              selectedOption={input.value}
                              onChange={input.onChange}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                    <div
                      className={
                        styles['p-box__table2__body__item__box__table__item']
                      }
                    >
                      <p
                        className={clsx(
                          styles[
                            'p-box__table2__body__item__box__table__item__ttl'
                          ],
                          baseStyles['u-pcHidden']
                        )}
                      >
                        {t('Inventory Count')}
                      </p>
                      <Field name={`${fieldName}.allotmentSlots`}>
                        {({ input }) => (
                          <TextField
                            value={input.value}
                            onChange={(value) => {
                              if (!isInteger(value)) {
                                return;
                              }

                              input.onChange(value);
                            }}
                            type="text"
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      className={
                        styles['p-box__table2__body__item__box__table__item']
                      }
                    >
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {error && typeof error === 'string' && (
              <>
                <FocusableInput name={name} />
                <FormError error={error} />
              </>
            )}
            {Boolean(fields.length) && (
              <div className={baseStyles['u-mt-4']}>
                <a
                  className={styles['p-box__table2__actions__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(
                        fields.length + 1,
                        defaultDedicatedAllotment
                      );
                    } else {
                      (fields as any).insertAt(0, defaultDedicatedAllotment);
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Capacity Settings')}
                </a>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
};
