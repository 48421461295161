import { v4 as uuidv4 } from 'uuid';

import type { Weekday } from 'client/libraries/util/weekdays';
import type {
  BookingDeadline,
  CancellationPolicy,
} from 'client/libraries/util/productShape';
import type {
  AvailabilityChannelCategory,
  BookingDeadline$Patch,
  CancellationPolicy$Patch,
  Product$Patch,
} from 'shared/models/swagger';

type ReservationLocationWithTimeInput = {
  id: string;
  dateTimeUtc: string;
  locationName: string;
  locationDescription: string;
  googlePlaceId: string;
};
export type PackageComponentTimeSlot = {
  productId: string;
  timeSlotKey: string;
  dayOffset: number;
  transportation_key?: string;
  pickup?: ReservationLocationWithTimeInput;
  dropoff?: ReservationLocationWithTimeInput;
};
export type PerChannelAllotment = {
  channel: AvailabilityChannelCategory;
  agentId: string | null;
  allotmentSlots: number;
};
export type StartTime = {
  time: string;
  duration: string;
  timeSlotKey?: string;
  allotmentSlots: number;
  perChannelAllotments: PerChannelAllotment[];
  description: string;
  packageComponentTimeSlots: PackageComponentTimeSlot[];
  referenceInSupplierSystem?: string;
  parentProductTimeSlotKey?: string;
  // freesale is represented by common allotment slots of 10,000,000 or more. "isFreesale" exists
  // for convenience in the product editing UI.
  isFreesale?: boolean;
};
export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};
export type ReservationParamsFormValues = {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
  startTimes: StartTime[];
  requestBookingDeadline?: BookingDeadline;
  instantBookingDeadline?: BookingDeadline;
  cancellationPolicies: CancellationPolicy[];
  agentGuestBookingAllowedDaysBefore: number | null;
  shouldSendReminderEmail: boolean;
};
export const convertReservationParamsFormValuesToProductPatch = (
  values: ReservationParamsFormValues,
  isPackage: boolean,
  currency: string
): Product$Patch => {
  const bookingDeadlines: BookingDeadline$Patch[] = [];

  if (values.requestBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.requestBookingDeadline, 'REQUEST')
    );
  }

  if (values.instantBookingDeadline) {
    bookingDeadlines.push(
      convertToBookingDeadlinePatch(values.instantBookingDeadline, 'INSTANT')
    );
  }

  const cancellationPolicies: CancellationPolicy$Patch[] = [];

  for (const cancellationPolicy of values.cancellationPolicies) {
    cancellationPolicies.push(
      convertToCancellationPolicyPatch(cancellationPolicy, currency)
    );
  }

  if (
    !cancellationPolicies.some(
      (policy) => policy.relative_date?.type === 'ETERNAL'
    )
  ) {
    cancellationPolicies.push({
      relative_date: {
        type: 'HOUR',
        count: 0,
      },
      charge_percent: 100,
    });
  }
  const recurrence = [
    {
      start_date_local:
        values.dateRanges.length > 0 ? values.dateRanges[0].startDate : '',
      end_date_local:
        values.dateRanges.length > 0 ? values.dateRanges[0].endDate : '',
      additional_date_ranges: values.dateRanges.slice(1).map((dateRange) => ({
        start_date_local: dateRange.startDate,
        end_date_local: dateRange.endDate,
      })),
      closed_dates: values.closedDates
        .filter((closedDate) => !closedDate.repeatsAnnually)
        .map((closedDate) => closedDate.date),
      annually_repeating_closed_dates: values.closedDates
        .filter((closedDate) => closedDate.repeatsAnnually)
        .map((closedDate) => closedDate.date),
      days_of_week: values.weekdays,
      start_times: values.startTimes.map((startTime) => ({
        start_time_local: startTime.time,
        duration: startTime.duration,
        description: startTime.description,
      })),
      key: uuidv4(),
    },
  ];
  const allotmentRules = !isPackage
    ? [
        {
          start_date_local:
            values.dateRanges.length > 0 ? values.dateRanges[0].startDate : '',
          end_date_local:
            values.dateRanges.length > 0 ? values.dateRanges[0].endDate : '',
          additional_date_ranges: values.dateRanges
            .slice(1)
            .map((dateRange) => ({
              start_date_local: dateRange.startDate,
              end_date_local: dateRange.endDate,
            })),
          exception_dates: values.closedDates
            .filter((closedDate) => !closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
          annually_repeating_closed_dates: values.closedDates
            .filter((closedDate) => closedDate.repeatsAnnually)
            .map((closedDate) => closedDate.date),
          days_of_week: values.weekdays,
          start_time_allotments: values.startTimes.map((startTime) => ({
            time_slot_key: `${startTime.time}-${startTime.duration}`,
            common_allotment_slots: parseInt(startTime.allotmentSlots as any),
          })),
        },
      ]
    : [];
  return {
    booking_deadlines: bookingDeadlines,
    cancellation_policies: cancellationPolicies,
    recurrence,
    allotment_rules: allotmentRules,
    agent_guest_booking_period_settings:
      values.agentGuestBookingAllowedDaysBefore
        ? {
            booking_allowed_days_before_participation:
              values.agentGuestBookingAllowedDaysBefore,
          }
        : undefined,
  };
};
export const convertToBookingDeadlinePatch = (
  bookingDeadline: BookingDeadline,
  confirmationType: 'INSTANT' | 'REQUEST'
): BookingDeadline$Patch => {
  return {
    confirmation_type: confirmationType,
    relative_date:
      bookingDeadline.deadlineType === 'ETERNAL'
        ? {
            type: 'ETERNAL',
          }
        : bookingDeadline.deadlineType === 'HOUR'
        ? {
            type: 'HOUR',
            count: bookingDeadline.hoursBefore,
          }
        : bookingDeadline.deadlineType === 'DAY'
        ? {
            type: 'DAY',
            count: bookingDeadline.daysBefore,
            time_local: bookingDeadline.timeOfDay,
          }
        : undefined,
  };
};
export const convertToCancellationPolicyPatch = (
  cancellationPolicy: CancellationPolicy,
  currency: string
): CancellationPolicy$Patch => {
  return {
    relative_date:
      cancellationPolicy.deadlineType === 'ETERNAL'
        ? {
            type: 'ETERNAL',
          }
        : cancellationPolicy.deadlineType === 'HOUR'
        ? {
            type: 'HOUR',
            count: cancellationPolicy.hoursBefore,
          }
        : cancellationPolicy.deadlineType === 'DAY'
        ? {
            type: 'DAY',
            count: cancellationPolicy.daysBefore,
            time_local: cancellationPolicy.timeOfDay,
          }
        : undefined,
    ...(cancellationPolicy.feeType === 'PERCENT'
      ? {
          charge_percent: parseFloat(cancellationPolicy.feePercent),
        }
      : {
          charge_fixed: `${currency}${cancellationPolicy.feeFixed}`,
        }),
  };
};
export const getMinStartDate = (
  dateRanges: {
    startDate: string;
    endDate: string;
  }[]
): string => {
  if (dateRanges.length == 0) {
    return '';
  }

  let minStartDate = dateRanges[0].startDate;

  for (const dateRange of dateRanges) {
    if (dateRange.startDate < minStartDate) {
      minStartDate = dateRange.startDate;
    }
  }

  return minStartDate;
};
export const getMaxEndDate = (
  dateRanges: {
    startDate: string;
    endDate: string;
  }[]
): string => {
  if (dateRanges.length == 0) {
    return '';
  }

  let maxEndDate = dateRanges[0].endDate;

  for (const dateRange of dateRanges) {
    if (dateRange.endDate > maxEndDate) {
      maxEndDate = dateRange.endDate;
    }
  }

  return maxEndDate;
};
