import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import {
  getEquipmentBlockReference,
  getEquipmentBlockInstanceProperty,
  getCorrectedPopupWindowPosition,
} from './utils';
import styles from './SeatAssignment.module.css';

interface Props {
  selectedEquipmentBlockInstanceKeys: {
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  }[];
  position: { left: number; top: number } | null | undefined;
  onNewReservationClick?: () => void;
  onEditPropertiesClick?: () => void;
  onReset?: () => void;
  open: boolean;
  onSeatMap?: boolean;
  onClose: () => void;
  onSeatCloseClick?: () => void;
  onSeatOpenClick?: () => void;
}

export const UnAssignedSeatSelectPopupWindow = ({
  selectedEquipmentBlockInstanceKeys,
  position,
  onNewReservationClick,
  onEditPropertiesClick,
  open,
  onSeatMap,
  onClose,
  onSeatOpenClick,
  onSeatCloseClick,
}: Props) => {
  const { t } = useTranslation();
  const hoverRef = React.useRef<HTMLDivElement>(null);

  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [popupWindowOpen, setPopupWindowOpen] = React.useState<{
    top: string;
    left: string;
  } | null>(null);

  const closedEquipmentBlockInstanceSelected = React.useMemo(() => {
    return selectedEquipmentBlockInstanceKeys.some((key) => {
      const selectedEquipmentInstance = equipmentInstances.find(
        (instance) => instance.id === key.equipmentInstanceId
      );

      const selectedEquipment = equipments.find(
        (equipment) => equipment.id === selectedEquipmentInstance?.equipment_id
      );

      const property = getEquipmentBlockInstanceProperty(
        key.equipmentBlockInstanceKey,
        selectedEquipment,
        selectedEquipmentInstance,
        activeUserOrganization
      );

      return (property?.capacity ?? 0) === 0 || property?.is_closed;
    });
  }, [selectedEquipmentBlockInstanceKeys, equipments, equipmentInstances]);

  React.useEffect(() => {
    if (hoverRef?.current) {
      const correctedPosition = getCorrectedPopupWindowPosition(
        hoverRef?.current?.clientHeight ?? 150,
        position
      );
      setPopupWindowOpen({
        left: `${correctedPosition.left}px`,
        top: `${correctedPosition.top}px`,
      });
    }
  }, [hoverRef?.current, position]);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !hoverRef?.current?.contains(target)) {
        onClose();
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  if (!position) {
    return null;
  }

  if (!open) {
    return null;
  }

  const selectedEquipmentBlockReferences = selectedEquipmentBlockInstanceKeys
    .map((key) => {
      const equipmentInstance = equipmentInstances.find(
        (instance) => instance.id === key.equipmentInstanceId
      );

      if (!equipmentInstance) {
        return null;
      }

      const equipment = equipments.find(
        (equipment) => equipment.id === equipmentInstance.equipment_id
      );

      if (!equipment) {
        return null;
      }

      const property = getEquipmentBlockInstanceProperty(
        key.equipmentBlockInstanceKey,
        equipment,
        equipmentInstance,
        activeUserOrganization
      );

      return (
        property?.reference ||
        getEquipmentBlockReference(key.equipmentBlockInstanceKey, equipment)
      );
    })
    .sort();

  return (
    <div
      className={clsx(styles['popup'], styles['create'])}
      ref={hoverRef}
      style={{
        ...(onSeatMap
          ? {
              right: '43px',
              position: 'absolute',
            }
          : {
              top: popupWindowOpen?.top,
              left: popupWindowOpen?.left,
            }),
      }}
    >
      <ul className={styles['popup__info']}>
        <li>
          <p>{t('Selected')}</p>
          <p>{selectedEquipmentBlockReferences.join(', ')}</p>
        </li>
        <li>
          <p>{t('Total')}</p>
          <p>{selectedEquipmentBlockInstanceKeys.length}</p>
        </li>
      </ul>
      <ul className={styles['popup__list']}>
        {onNewReservationClick && !closedEquipmentBlockInstanceSelected && (
          <li
            onClick={() => {
              onNewReservationClick();
            }}
          >
            <p
              style={{
                backgroundColor: '#00BF98',
                color: '#fff',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {t('New Reservation')}
            </p>
          </li>
        )}
        {onSeatCloseClick && (
          <li
            onClick={() => {
              onSeatCloseClick();
            }}
          >
            <p
              style={{
                backgroundColor: '#FFD654',
                color: '#fff',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {t('Closed')}
            </p>
          </li>
        )}
        {onSeatOpenClick && (
          <li
            onClick={() => {
              onSeatOpenClick();
            }}
          >
            <p
              style={{
                backgroundColor: '#00aaff',
                color: '#fff',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {t('Open')}
            </p>
          </li>
        )}
        {onEditPropertiesClick && (
          <li
            onClick={() => {
              onEditPropertiesClick();
            }}
          >
            <p
              style={{
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {t('Edit seats')}
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};
