import { Action, combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION,
} from 'client/constants/ActionTypes';
import { SearchInquiriesRequest } from 'client/libraries/util/searchInquiries';

const defaultSearchFilters: SearchInquiriesRequest = {
  participationDateFrom: '',
  participationDateTo: '',
  updatedDateFrom: '',
  updatedDateTo: '',
  dateFilterPreset: 'UPDATED_7_DAYS',
  customerGivenName: '',
  customerFamilyName: '',
  contact: '',
  tag: '',
  categories: [],
  communicationTypes: [],
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

type State = {
  lastExecutedSearchCondition: SearchInquiriesRequest;
};

const reducer = combineReducers<State>({
  lastExecutedSearchCondition,
});
export const inquiryTableControls = (state: State, action: Action) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
