import { useTranslation } from 'react-i18next';

import styles from './CalendarLegend.module.css';

export const CalendarLegend = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['item']}>
        <div className={styles['session-marker']} />
        <div className={styles['div-4']}>{t('Session')}</div>
      </div>
      <div className={styles['item']}>
        <div className={styles['conversion-marker']} />
        <div className={styles['text']}>{t('Conversion')}</div>
      </div>
    </div>
  );
};
