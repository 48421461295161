import styles from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList.module.css';
import { Toggle } from 'client/components/v3/Form/Toggle';

interface Props {
  text: string;
  toggle: () => void;
}
export const UnselectedItem = ({ text, toggle }: Props) => {
  return (
    <li className={styles['c-toggleableList__item']}>
      <div className={styles['c-toggleableList__item__left']}>
        <p>{text}</p>
      </div>
      <div className={styles['c-toggleableList__item__right']}>
        <Toggle
          checked={false}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggle();
          }}
        />
      </div>
    </li>
  );
};
