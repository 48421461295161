import { combineReducers } from 'redux';

import {
  FETCH_RESERVATION_VOUCHER_FAILURE,
  FETCH_RESERVATION_VOUCHER_REQUEST,
  FETCH_RESERVATION_VOUCHER_SUCCESS,
} from 'client/constants/ActionTypes';

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_RESERVATION_VOUCHER_REQUEST:
      return true;

    case FETCH_RESERVATION_VOUCHER_FAILURE:
    case FETCH_RESERVATION_VOUCHER_SUCCESS:
      return false;

    default:
      return state;
  }
};

const html = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESERVATION_VOUCHER_REQUEST:
      return '';

    case FETCH_RESERVATION_VOUCHER_SUCCESS:
      return action.html;

    default:
      return state;
  }
};

export const reservationVoucher = combineReducers({
  loading,
  html,
});
