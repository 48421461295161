import * as React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Stars } from 'client/components/Stars/Stars';
import { fetchReviewStats } from 'client/actions/reviews';
import type {
  ReviewAttribution,
  ReviewRating,
  ReviewStatus,
} from 'shared/models/swagger';

import styles from './ProductReviewsPane.module.css';

interface Props {
  productIds?: string[];
  reviewStatus?: ReviewStatus;
}

export const ReviewsSummary = ({ productIds, reviewStatus }: Props) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const reviewStats = useSelector((state: ReduxState) => state.reviews.stats);
  const loading = useSelector(
    (state: ReduxState) => state.reviews.statsLoading
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      fetchReviewStats({
        productIds: productIds,
        statuses: reviewStatus ? [reviewStatus] : [],
      })
    );
  }, [productIds, reviewStatus]);

  if (!loading && (reviewStats.review_count ?? 0) === 0) {
    return null;
  }

  const reviewRatingOptions: {
    value: ReviewRating;
    text: string;
  }[] = [
    {
      value: 'REVIEW_RATING_5',
      text: t('5-star'),
    },
    {
      value: 'REVIEW_RATING_4',
      text: t('4-star'),
    },
    {
      value: 'REVIEW_RATING_3',
      text: t('3-star'),
    },
    {
      value: 'REVIEW_RATING_2',
      text: t('2-star'),
    },
    {
      value: 'REVIEW_RATING_1',
      text: t('1-star'),
    },
  ];
  const attributionOptions: {
    value: ReviewAttribution;
    text: string;
  }[] = [
    {
      value: 'FAMILY',
      text: t('Family'),
    },
    {
      value: 'COUPLE',
      text: t('Couple'),
    },
    {
      value: 'FRIEND',
      text: t('Friend'),
    },
    {
      value: 'SOLO',
      text: t('Solo'),
    },
    {
      value: 'BUSINESS',
      text: t('Work'),
    },
  ];

  const avgRating = reviewStats?.review_rating ?? 0;

  return (
    <div className={styles['c-review__box']}>
      {loading && (
        <Dimmer active inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      )}
      <a
        className={clsx(
          styles['c-review__summary__total'],
          styles['ac'],
          isExpanded ? styles['is-open'] : styles['is-close']
        )}
      >
        <p className={styles['c-review__summary__total__ttl']}>
          {t('Average Rating')}
        </p>
        <div className={styles['c-review__summary__total__stars']}>
          <Stars rating={avgRating} />
        </div>
        <p className={styles['c-review__summary__total__num']}>
          {avgRating.toFixed(1)}
          <span>({reviewStats?.review_count ?? 0})</span>
        </p>
        <p
          className={styles['c-review__close']}
          onClick={() => setIsExpanded(!isExpanded)}
        ></p>
      </a>
      <div
        className={clsx(
          styles['c-review__summary__main'],
          styles['ac'],
          isExpanded ? styles['is-open'] : styles['is-close']
        )}
      >
        <div className={styles['c-review__summary__star']}>
          <ul className={styles['c-review__summary__star__list']}>
            {reviewRatingOptions.map((ratingOption) => {
              const ratingCount = reviewStats?.rating_counts?.find(
                (ratingCount) => ratingCount.rating === ratingOption.value
              );

              return (
                <li
                  key={ratingOption.value}
                  className={styles['c-review__summary__star__list__item']}
                >
                  <p
                    className={
                      styles['c-review__summary__star__list__item__name']
                    }
                  >
                    {ratingOption.text}
                  </p>
                  <p
                    className={
                      styles['c-review__summary__star__list__item__bar']
                    }
                  >
                    <span
                      style={{
                        width: `${
                          (100 * (ratingCount?.count ?? 0)) /
                          (reviewStats?.review_count ?? 1)
                        }%`,
                      }}
                    ></span>
                  </p>
                  <p
                    className={
                      styles['c-review__summary__star__list__item__num']
                    }
                  >
                    {ratingCount?.count ?? 0}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles['c-review__summary__type']}>
          <ul className={styles['c-review__summary__type__list']}>
            {attributionOptions.map((option) => {
              const attributionCount = reviewStats?.attribution_counts?.find(
                (attributionCount) =>
                  attributionCount.attribution === option.value
              );
              return (
                <li
                  key={option.value}
                  className={styles['c-review__summary__type__list__item']}
                >
                  <p
                    className={
                      styles['c-review__summary__type__list__item__name']
                    }
                  >
                    {option.text}
                  </p>
                  <div
                    className={
                      styles['c-review__summary__type__list__item__stars']
                    }
                  >
                    <Stars rating={attributionCount?.review_rating ?? 0} />
                  </div>
                  <p
                    className={
                      styles['c-review__summary__type__list__item__num']
                    }
                  >
                    {(attributionCount?.review_rating ?? 0).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 1,
                      }
                    )}
                    <span>({attributionCount?.count ?? 0})</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
