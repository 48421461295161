import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ScheduleTable,
  DateEvent,
} from 'client/components/ScheduleTable/ScheduleTable';
import { DateTimeInput, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers';
import {
  setResourceAvailabilityStartDate,
  setResourceAvailabilityResourceKey,
  setResourceAvailabilityResourceType,
  setResourceAvailabilityVehicleDepartureTimeKey,
} from 'client/actions/resourceAvailabilityControls';
import { fetchResourceAssignments } from 'client/actions/resourceAssignments';
import { fetchResourceQuantities } from 'client/actions/resourceQuantities';
import { fetchProducts } from 'client/actions/products';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  allDispatchMiscResourcesSelector,
  allDispatchVehiclesSelector,
  allDispatchCrewMembersSelector,
} from 'client/reducers/dispatchSettings';
import { Loading } from 'client/pages/Loading';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import {
  ResourceType,
  convertToSwagger,
} from 'client/libraries/util/resourceType';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import pageStyles from 'client/pages/pages.module.css';

import styles from './ResourceAvailability.module.css';
import {
  convertResourceAssignmentsToScheduleTableEvents,
  getDefaultResourceQuantity,
  getDesignatedDateResourceQuantity,
  getResourceAssignmentKey,
} from './utils';
import {
  DesignatedDateResourceAvailabilityModal,
  Occupation,
} from './DesignatedDateResourceAvailabilityModal';

export const ResourceAvailability = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state: ReduxState) => state.resourceAvailabilityControls.startDate
  );
  const resourceKey = useSelector(
    (state: ReduxState) => state.resourceAvailabilityControls.resourceKey
  );
  const resourceType = useSelector(
    (state: ReduxState) => state.resourceAvailabilityControls.resourceType
  );
  const vehicleDepartureTimeKey = useSelector(
    (state: ReduxState) =>
      state.resourceAvailabilityControls.vehicleDepartureTimeKey
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  const miscResources = useSelector(allDispatchMiscResourcesSelector);
  const vehicles = useSelector(allDispatchVehiclesSelector);
  const crews = useSelector(allDispatchCrewMembersSelector);

  const products = useSelector((state: ReduxState) => state.products.summaries);

  const loading = useSelector(
    (state: ReduxState) => state.resourceAssignments.loading
  );
  const resourceAssignments = useSelector(
    (state: ReduxState) => state.resourceAssignments.latestQueryResults
  );
  const resourceQuantities = useSelector(
    (state: ReduxState) => state.resourceQuantities.all
  );

  const [designatedDate, setDesignatedDate] = React.useState<string | null>(
    null
  );

  const [dateEvents, setDateEvents] = React.useState<DateEvent[]>([]);

  React.useEffect(() => {
    const newDateEvents: DateEvent[] = [];
    const date = moment
      .tz(startDate, activeOrganization?.default_timezone ?? 'UTC')
      .format('YYYY-MM-DD');
    [...Array(7).keys()].forEach((i) => {
      const newDate = moment(date).add(i, 'days').format('YYYY-MM-DD');
      const resourceQuantity = getDesignatedDateResourceQuantity(
        newDate,
        resourceType,
        resourceKey,
        vehicleDepartureTimeKey,
        resourceQuantities,
        activeOrganization
      );
      newDateEvents.push({
        date: newDate,
        title: t('Quantity: {{capacity}}', {
          capacity: resourceQuantity?.quantity ?? 0,
        }),
        onClick: () => setDesignatedDate(newDate),
      });
    });
    setDateEvents(newDateEvents);
  }, [resourceQuantities, startDate]);

  const getOccupations = (): Occupation[] => {
    if (!designatedDate || !resourceAssignments) {
      return [];
    }

    const occupations: Occupation[] = [];

    resourceAssignments.forEach((ra) => {
      const startDateTimeMoment = moment.tz(
        ra.date_from,
        activeOrganization?.default_timezone ?? 'UTC'
      );

      if (
        startDateTimeMoment.isBefore(
          moment.tz(
            designatedDate,
            activeOrganization?.default_timezone ?? 'UTC'
          )
        )
      ) {
        return;
      }

      if (
        startDateTimeMoment.isSameOrAfter(
          moment
            .tz(designatedDate, activeOrganization?.default_timezone ?? 'UTC')
            .add(1, 'days')
        )
      ) {
        return;
      }

      const startTime = startDateTimeMoment.format('HH:mm');
      const occupation = occupations.find(
        (o) => o.productId === ra.product_id && o.startTime === startTime
      );

      if (occupation) {
        occupation.quantity += ra.quantity ?? 0;
        occupation.reservationIds?.push(ra.reservation_id ?? '');
      } else {
        occupations?.push({
          startTime: startTime,
          productId: ra.product_id ?? '',
          productName:
            products.find((p) => p.id === ra.product_id)?.product_name ?? '',
          quantity: ra.quantity ?? 0,
          reservationIds: [ra.reservation_id ?? ''],
        });
      }
    });

    return occupations.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      }
      if (a.startTime > b.startTime) {
        return 1;
      }
      return 0;
    });
  };

  const getCapacity = (): number => {
    const dispatchSettings =
      activeOrganization?.dispatch_settings?.dispatch_misc_resources?.find(
        (d) => d.key === resourceKey
      );

    if (!dispatchSettings) {
      return 0;
    }

    return dispatchSettings.availability?.capacity_per_unit ?? 0;
  };

  const resourceKeyOptions = React.useMemo(() => {
    if (resourceType === 'vehicle') {
      return vehicles.map((vehicle) => ({
        value: vehicle.key ?? '',
        text: vehicle.key ?? '',
      }));
    }
    if (resourceType === 'crew') {
      return crews.map((crew) => ({
        value: crew.key ?? '',
        text: crew.key ?? '',
      }));
    }
    return miscResources.map((resource) => ({
      value: resource.key ?? '',
      text: resource.key ?? '',
    }));
  }, [miscResources, vehicles, resourceType]);

  const departureTimeOptions = React.useMemo(() => {
    const vechicle = vehicles.find((v) => v.key === resourceKey);
    if (!vechicle) {
      return [];
    }

    const date = moment.tz(
      startDate,
      activeOrganization?.default_timezone ?? 'UTC'
    );

    const schedule = vechicle.availability?.schedules?.find((s) => {
      const start = moment.tz(
        s.start_date_local,
        activeOrganization?.default_timezone ?? 'UTC'
      );

      const end = moment.tz(
        s.end_date_local,
        activeOrganization?.default_timezone ?? 'UTC'
      );

      if (date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
        return true;
      }
    });

    if (!schedule) {
      return [];
    }

    return (
      schedule.departure_time_capacities?.map((d) => ({
        value: d.key ?? '',
        text: d.time_local ?? '',
      })) ?? []
    );
  }, [vehicles, resourceKey, startDate]);

  const resourceTypeOptions = React.useMemo(() => {
    if (
      hasSubscription(
        activeOrganization,
        'feature-vehicle-crew-resource-management'
      )
    ) {
      return [
        {
          value: 'vehicle',
          text: t('Vehicle'),
        },
        {
          value: 'crew',
          text: t('Crew'),
        },
        {
          value: 'other',
          text: t('Other'),
        },
      ];
    } else {
      return [
        {
          value: 'other',
          text: t('Other'),
        },
      ];
    }
  }, [activeOrganization, t]);

  const timezone = React.useMemo(() => {
    return activeOrganization?.default_timezone ?? 'UTC';
  }, [activeOrganization]);

  const scheduleItems = React.useMemo(() => {
    return convertResourceAssignmentsToScheduleTableEvents(
      resourceAssignments,
      products,
      resourceType,
      resourceKey,
      vehicleDepartureTimeKey,
      resourceQuantities,
      activeOrganization,
      (date: string) => setDesignatedDate(date),
      t
    );
  }, [resourceAssignments, resourceQuantities, products, t]);

  React.useEffect(() => {
    if (startDate && resourceType && resourceKey) {
      const virtualResourceKey = getResourceAssignmentKey(
        resourceType,
        resourceKey,
        vehicleDepartureTimeKey
      );

      if (virtualResourceKey) {
        dispatch(
          fetchResourceAssignments(
            convertToSwagger(resourceType),
            virtualResourceKey,
            moment.tz(startDate, timezone).utc().format(),
            moment.tz(startDate, timezone).utc().add(1, 'week').format()
          )
        );
        dispatch(
          fetchResourceQuantities(
            convertToSwagger(resourceType),
            virtualResourceKey,
            moment.tz(startDate, timezone).format('YYYY-MM-DD'),
            moment.tz(startDate, timezone).add(1, 'week').format('YYYY-MM-DD')
          )
        );
      }
    }
  }, [startDate, resourceType, resourceKey, vehicleDepartureTimeKey, timezone]);

  React.useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProducts());
    }
  }, [products]);

  return (
    <div>
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                dispatch(
                  setResourceAvailabilityStartDate(
                    moment(startDate).add(-7, 'days').format('YYYY-MM-DD')
                  )
                );
              }}
            />
            <div
              className={clsx(pageStyles['page-availability__datepick__main'])}
              style={{ zIndex: 9 }}
            >
              <DateTimeInput
                value={moment(startDate)}
                locale={locale}
                onChange={(date) => {
                  dispatch(
                    setResourceAvailabilityStartDate(
                      moment(date).format('YYYY-MM-DD')
                    )
                  );
                }}
              />
            </div>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                dispatch(
                  setResourceAvailabilityStartDate(
                    moment(startDate).add(7, 'days').format('YYYY-MM-DD')
                  )
                );
              }}
            />
          </div>
        </Box>

        <div className={styles['tag-select']}>
          <Box display="flex">
            <Box ml={2}>
              <Select
                placeholder={t('Select resource type')}
                options={resourceTypeOptions}
                value={resourceType}
                onChange={(_, { value }) => {
                  dispatch(
                    setResourceAvailabilityResourceType(value as ResourceType)
                  );
                  dispatch(setResourceAvailabilityResourceKey(''));
                  dispatch(setResourceAvailabilityVehicleDepartureTimeKey(''));
                }}
              />
            </Box>
            <Box ml={2}>
              <Select
                placeholder={t('Select resource')}
                options={resourceKeyOptions}
                value={resourceKey}
                onChange={(_, { value }) => {
                  dispatch(setResourceAvailabilityResourceKey(value));
                }}
              />
            </Box>
            {resourceType === 'vehicle' && (
              <Box ml={2}>
                <Select
                  placeholder={t('Departure time')}
                  options={departureTimeOptions}
                  value={vehicleDepartureTimeKey}
                  onChange={(_, { value }) => {
                    dispatch(
                      setResourceAvailabilityVehicleDepartureTimeKey(value)
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        </div>
      </div>
      <ScheduleTable
        startDateLocal={moment(startDate).format('YYYY-MM-DD')}
        scheduleItems={scheduleItems}
        timezone={timezone}
        dateEvents={dateEvents}
      />
      {designatedDate &&
        hasCustomUserRoleWritePermissions(
          activeUser,
          'MANIFEST.RESOURCE_AVAILABILITY'
        ) && (
          <DesignatedDateResourceAvailabilityModal
            date={designatedDate}
            open={designatedDate !== null}
            onClose={() => {
              setDesignatedDate(null);
            }}
            resourceKey={resourceKey}
            capacityPerUnit={getCapacity()}
            resourceQuantity={getDesignatedDateResourceQuantity(
              designatedDate,
              resourceType,
              resourceKey,
              vehicleDepartureTimeKey,
              resourceQuantities,
              activeOrganization
            )}
            occupations={getOccupations()}
            defaultQuantity={
              getDefaultResourceQuantity(
                designatedDate,
                resourceType,
                resourceKey,
                vehicleDepartureTimeKey,
                activeOrganization
              )?.quantity ?? 0
            }
          />
        )}
      {loading && <Loading />}
    </div>
  );
};
