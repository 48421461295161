// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch as ReduxDispatch } from 'redux';

import { updateDispatchCrewMembers } from 'client/actions/dispatchSettings';
import { allDispatchCrewMembersSelector } from 'client/reducers/dispatchSettings';
import type { DispatchCrewMember } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import {
  Input,
  Button,
  TextArea,
  DateRangeInput,
  WeekdayInput,
  DateListInput,
} from 'client/components/Form';

type Day = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
const allDays: Day[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

type OwnProps = {
  existingCrewMember?: DispatchCrewMember,
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

const EditCrewMemberModalComponent = ({
  existingCrewMember,
  allCrewMembers,
  updateCrewMembers,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  let [crewMemberKey, setCrewMemberKey] = React.useState<string>('');
  let [crewMemberDescription, setCrewMemberDescription] =
    React.useState<string>('');
  let [startDate, setStartDate] = React.useState<string>('');
  let [endDate, setEndDate] = React.useState<string>('');
  let [daysOfWeek, setDaysOfWeek] = React.useState<Day[]>(allDays);
  let [excludedDates, setExcludedDates] = React.useState<string[]>([]);

  React.useEffect(() => {
    setCrewMemberKey(existingCrewMember?.key || '');
    setCrewMemberDescription(existingCrewMember?.description || '');
    const schedule = existingCrewMember && existingCrewMember.schedule;
    const firstSchedule = schedule && schedule.length > 0 && schedule[0];
    setStartDate((firstSchedule && firstSchedule.start_date_local) || '');
    setEndDate((firstSchedule && firstSchedule.end_date_local) || '');
    setDaysOfWeek(
      firstSchedule && firstSchedule.days_of_week
        ? firstSchedule.days_of_week
        : allDays
    );
    setExcludedDates(
      firstSchedule && firstSchedule.excluded_dates
        ? firstSchedule.excluded_dates
        : []
    );
  }, [existingCrewMember]);

  const headerText = existingCrewMember
    ? t('Edit Crew Member')
    : t('Add New Crew Member');
  return (
    <Modal title={headerText} open={open} onClose={onClose} onOpen={() => {}}>
      <Modal.Content>
        <Modal.Box>
          <Input
            disabled={Boolean(existingCrewMember)}
            label={t('Name')}
            value={crewMemberKey}
            onChange={(e, { value }) => setCrewMemberKey(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <TextArea
            label={t('Description')}
            value={crewMemberDescription}
            onChange={(e, { value }) => setCrewMemberDescription(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <DateRangeInput
            label={t('Schedule Date Range (optional)')}
            fromDate={startDate}
            toDate={endDate}
            onChangeFromDate={(date: string) => {
              setStartDate(date);
            }}
            onChangeToDate={(date: string) => {
              setEndDate(date);
            }}
          />
        </Modal.Box>
        <Modal.Box>
          <WeekdayInput
            label={t('Schedule Days of week')}
            value={daysOfWeek}
            setValue={(newDaysOfWeek) => setDaysOfWeek(newDaysOfWeek)}
          />
        </Modal.Box>
        <Modal.Box>
          <DateListInput
            label={t('Schedule Excluded Dates')}
            dates={excludedDates}
            setDates={(newExcludedDates) => setExcludedDates(newExcludedDates)}
          />
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          onClick={() => {
            const newCrewMember = {
              key: crewMemberKey,
              description: crewMemberDescription,
              schedule: [
                {
                  start_date_local: startDate,
                  end_date_local: endDate,
                  days_of_week: daysOfWeek,
                  excluded_dates: excludedDates,
                },
              ],
            };
            updateCrewMembers([
              ...allCrewMembers.filter(
                (crewMember) => crewMember.key !== newCrewMember.key
              ),
              newCrewMember,
            ]);
            onClose();
          }}
          disabled={!crewMemberKey}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  allCrewMembers: allDispatchCrewMembersSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  updateCrewMembers: (crewMembers: DispatchCrewMember[]) =>
    dispatch(updateDispatchCrewMembers(crewMembers)),
});

export const EditCrewMemberModal = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(EditCrewMemberModalComponent);
