import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import icCoupon from 'client/images/ic_coupon.svg';

import styles from './ETicketCouponButtonPreview.module.css';

interface Props {
  buttonText: string | null | undefined;
  buttonImageUrl: string | null | undefined;
}

export const ETicketCouponButtonPreview = ({
  buttonText,
  buttonImageUrl,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['card__coupon']}>
      <p className={clsx(styles['btnSmall'], styles['btn--submit'])}>
        {buttonImageUrl ? (
          <img className={styles['btnSmall__coupon']} src={buttonImageUrl} />
        ) : (
          <img className={styles['btnSmall__coupon']} src={icCoupon} />
        )}
        {buttonText?.replace(/\u3000/g, ' ') || t('Coupon')}
      </p>
    </div>
  );
};
