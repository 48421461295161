import { combineReducers } from 'redux';

import {
  FETCH_CHECKIN_RECORDS_REQUEST,
  FETCH_CHECKIN_RECORDS_SUCCESS,
  FETCH_CHECKIN_RECORDS_FAILURE,
  DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE,
  DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST,
  DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { CheckinRecord } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_CHECKIN_RECORDS_REQUEST:
      return true;

    case FETCH_CHECKIN_RECORDS_SUCCESS:
    case FETCH_CHECKIN_RECORDS_FAILURE:
      return false;

    default:
      return state;
  }
};

const downloading = (state = false, action: any): boolean => {
  switch (action.type) {
    case DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST:
      return true;

    case DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS:
    case DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_CHECKIN_RECORDS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (state: CheckinRecord[] = [], action: any): CheckinRecord[] => {
  switch (action.type) {
    case FETCH_CHECKIN_RECORDS_SUCCESS:
      return action.response.checkin_records;

    default:
      return state;
  }
};

interface State {
  all: CheckinRecord[];
  loading: boolean;
  error: string;
  downloading: boolean;
}

export const checkinRecords = combineReducers<State>({
  all,
  loading,
  error,
  downloading,
});
