import _ from 'lodash';
import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_INQUIRY_FAILURE,
  FETCH_INQUIRY_REQUEST,
  FETCH_INQUIRY_SUCCESS,
  FETCH_INQUIRIES_FAILURE,
  FETCH_INQUIRIES_REQUEST,
  FETCH_INQUIRIES_SUCCESS,
  REPLY_TO_INQUIRY_FAILURE,
  REPLY_TO_INQUIRY_REQUEST,
  REPLY_TO_INQUIRY_SUCCESS,
  UPDATE_INQUIRY_FAILURE,
  UPDATE_INQUIRY_REQUEST,
  UPDATE_INQUIRY_SUCCESS,
  SEND_INQUIRY_SMS_SUCCESS,
  SEND_INQUIRY_SMS_FAILURE,
  SEND_INQUIRY_SMS_REQUEST,
  SEND_INQUIRY_EMAIL_SUCCESS,
  SEND_INQUIRY_EMAIL_FAILURE,
  SEND_INQUIRY_EMAIL_REQUEST,
  FETCH_INQUIRY_SMS_STATS_REQUEST,
  FETCH_INQUIRY_SMS_STATS_SUCCESS,
  FETCH_INQUIRY_SMS_STATS_FAILURE,
  SEND_INQUIRY_BULK_EMAIL_REQUEST,
  SEND_INQUIRY_BULK_SMS_REQUEST,
  SEND_INQUIRY_BULK_EMAIL_SUCCESS,
  SEND_INQUIRY_BULK_SMS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  GetInquirySmsStatsResponse,
  Inquiry,
} from 'shared/models/swagger';

export const inquiriesSelector = (state: ReduxState) => state.inquiries.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_INQUIRIES_FAILURE:
    case FETCH_INQUIRY_FAILURE:
    case REPLY_TO_INQUIRY_FAILURE:
    case UPDATE_INQUIRY_FAILURE:
    case SEND_INQUIRY_SMS_FAILURE:
    case SEND_INQUIRY_EMAIL_FAILURE:
      return action.error;

    case FETCH_INQUIRIES_SUCCESS:
    case FETCH_INQUIRY_SUCCESS:
    case REPLY_TO_INQUIRY_SUCCESS:
    case UPDATE_INQUIRY_SUCCESS:
    case SEND_INQUIRY_SMS_SUCCESS:
    case SEND_INQUIRY_EMAIL_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_INQUIRIES_REQUEST:
    case FETCH_INQUIRY_REQUEST:
    case REPLY_TO_INQUIRY_REQUEST:
    case UPDATE_INQUIRY_REQUEST:
    case SEND_INQUIRY_SMS_REQUEST:
    case SEND_INQUIRY_EMAIL_REQUEST:
      return true;

    case FETCH_INQUIRY_FAILURE:
    case FETCH_INQUIRY_SUCCESS:
    case FETCH_INQUIRIES_FAILURE:
    case FETCH_INQUIRIES_SUCCESS:
    case REPLY_TO_INQUIRY_FAILURE:
    case REPLY_TO_INQUIRY_SUCCESS:
    case UPDATE_INQUIRY_FAILURE:
    case UPDATE_INQUIRY_SUCCESS:
    case SEND_INQUIRY_SMS_FAILURE:
    case SEND_INQUIRY_SMS_SUCCESS:
    case SEND_INQUIRY_EMAIL_FAILURE:
    case SEND_INQUIRY_EMAIL_SUCCESS:
      return false;

    default:
      return state;
  }
};

const smsStats = (
  state: null | GetInquirySmsStatsResponse = null,
  action: any
): null | GetInquirySmsStatsResponse => {
  switch (action.type) {
    case FETCH_INQUIRY_SMS_STATS_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

const smsStatsLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_INQUIRY_SMS_STATS_REQUEST:
      return true;

    case FETCH_INQUIRY_SMS_STATS_SUCCESS:
    case FETCH_INQUIRY_SMS_STATS_FAILURE:
      return false;

    default:
      return state;
  }
};

const all = (state: Inquiry[] = [], action: any): Inquiry[] => {
  switch (action.type) {
    case FETCH_INQUIRIES_SUCCESS: {
      return [...action.response.inquiries];
    }

    case REPLY_TO_INQUIRY_SUCCESS:
    case UPDATE_INQUIRY_SUCCESS: {
      const updatedInquiry = action.response;
      return [
        ...state.map((existingInquiry) =>
          existingInquiry.id === updatedInquiry.id
            ? updatedInquiry
            : existingInquiry
        ),
      ];
    }

    case SEND_INQUIRY_SMS_SUCCESS:
    case SEND_INQUIRY_EMAIL_SUCCESS: {
      const inquiry = action.response;

      return state.some((i) => i.id === inquiry.id)
        ? [
            ...state.map((existingInquiry) =>
              existingInquiry.id === inquiry.id ? inquiry : existingInquiry
            ),
          ]
        : _.orderBy([...state, inquiry], (i) => i.last_updated_date_time_utc, [
            'desc',
          ]);
    }

    default:
      return state;
  }
};

const details = (state: Inquiry | null = null, action: any): Inquiry | null => {
  switch (action.type) {
    case FETCH_INQUIRY_SUCCESS:
      return action.response as Inquiry;

    default:
      return state;
  }
};

const lastSubmittedBulkInquiry = (
  state: Inquiry | null = null,
  action: any
): Inquiry | null => {
  switch (action.type) {
    case SEND_INQUIRY_BULK_EMAIL_REQUEST:
    case SEND_INQUIRY_BULK_SMS_REQUEST:
      return null;
    case SEND_INQUIRY_BULK_EMAIL_SUCCESS:
    case SEND_INQUIRY_BULK_SMS_SUCCESS:
      return action.response?.inquiry as Inquiry;
    default:
      return state;
  }
};

export const inquiries = combineReducers({
  error,
  loading,
  all,
  details,
  smsStats,
  smsStatsLoading,
  lastSubmittedBulkInquiry,
});
