import * as React from 'react';

export type SeatAssignmentModeType =
  | 'CREATE'
  | 'MOVE'
  | 'ASSIGN'
  | 'EDIT'
  | null;

export const SeatAssignmentContext = React.createContext<{
  editMode: SeatAssignmentModeType;
  setEditMode: (arg: SeatAssignmentModeType) => void;
}>({
  editMode: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEditMode: () => {},
});
