import * as React from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { activeUserSelector } from 'client/reducers/user';
import { s3MediaStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import deleteIcon from 'client/images/ic_delete.svg';
import baseStyles from 'client/base.module.css';

import styles from './VideoInput.module.css';

type Props = {
  videoUrl: string;
  onChange: (newValue: string) => void;
  error?: string;
};
export const VideoInput = ({ videoUrl, onChange, error }: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const [uploadError, setUploadError] = React.useState<string>('');
  const imageFileInputRef = React.useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = ['video/mp4'];
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (imageFileInputRef?.current) {
      imageFileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const uploadFiles = async (files: File[]) => {
    const newImages: string[] = [];
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';

    for (const file of files) {
      try {
        const filename = getRandomFilenameForUpload(file);
        const objName = `${organizationId}/tut/${filename}`;
        await s3MediaStorage.put(objName, file);
        const url = await s3MediaStorage.get(objName);
        // Remove pre-signed suffix
        const uploadedFileURL = url.split('?')[0];
        newImages.push(uploadedFileURL);
        onChange(uploadedFileURL);
        break;
      } catch (err) {
        /* TODO */
      }
    }

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <ul className={styles['page-productsRegist__picture']}>
        {videoUrl ? (
          <li
            key={`${videoUrl}-idx`}
            className={styles['page-productsRegist__picture__item__pic']}
          >
            <video width="160px" height="90px" src={videoUrl} controls></video>

            <a className={styles['delete']} onClick={() => onChange('')}>
              <img src={deleteIcon} />
            </a>
          </li>
        ) : (
          <li className={styles['page-productsRegist__picture__item']}>
            <a
              {...getRootProps()}
              className={clsx(
                styles['page-productsRegist__picture__item__add'],
                isDragActive && styles['dragActive']
              )}
              onClick={handleAddImageClick}
            ></a>
            <input
              {...getInputProps()}
              multiple
              ref={imageFileInputRef}
              id="image-file-input"
              type="file"
              name="name"
              accept={acceptedFileTypes.join(',')}
              style={{
                display: 'none',
              }}
              onChange={handleFileInputChange}
            />
          </li>
        )}
      </ul>
      {uploadError && (
        <p className={baseStyles['base-form-box__err']}>{uploadError}</p>
      )}
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}

      <p>{t('* mp4 format')}</p>
    </>
  );
};
