import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  header: string;
  content: JSX.Element;
  onClose: () => any;
  open: boolean;
};
export const AlertModal = ({ header, content, onClose, open }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={header}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <Button
          text={t('OK')}
          color="primary"
          size="md"
          onClick={() => {
            onClose();
          }}
        />
      }
      style={{ width: '480px' }}
    >
      {content}
    </Modal>
  );
};
