import { v4 as uuidv4 } from 'uuid';

const getExtensionFromMimeType = (mimeType?: string) => {
  switch (mimeType) {
    case 'image/jpeg':
      return 'jpg';

    case 'image/png':
      return 'png';

    case 'image/x-icon':
    case 'image/vnd.microsoft.icon':
      return 'ico';

    case 'video/mp4':
      return 'mp4';

    case 'audio/mpeg':
      return 'mp3';

    case 'application/pdf':
      return 'pdf';

    case 'image/svg+xml':
      return 'svg';

    default:
      throw new Error(`invalid mime type: ${mimeType ?? 'undefined'}`);
  }
};

export const getRandomFilenameForUpload = (file: File): string => {
  const ext = getExtensionFromMimeType(file.type);
  return `${uuidv4()}.${ext}`;
};
