import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const resendEmailRequest = () => ({
  type: RESEND_EMAIL_REQUEST,
});

const resendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS,
});

const resendEmailFailure = (error: any) => ({
  type: RESEND_EMAIL_FAILURE,
  error,
});

export const resendEmail =
  (emailId: string, toAddress: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(resendEmailRequest());
    return axios
      .get(`/api/emails/${emailId}/resend`, {
        params: {
          to_address: toAddress,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => dispatch(resendEmailSuccess()))
      .catch((error) => {
        dispatch(resendEmailFailure(error));
        throw error;
      });
  };
