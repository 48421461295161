import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';

import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';

import { TextInput } from './TextInput';
import styles from './WaiverTemplateList.module.css';

type Props = {
  name: string;
};
export const TextEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);
  const form = useForm();
  const lng =
    uppercaseIsoToLowercaseIso[
      form.getState().values
        ?.sourceLanguage as keyof typeof uppercaseIsoToLowercaseIso
    ];
  return (
    <div>
      <ul className={styles['scheds']}>
        <li className={styles['scheds__cell']}>
          <TranslatedField name={`${name}.body`}>
            {({ input, translationInput }) => (
              <>
                <TextInput
                  inputProps={input}
                  label={t('Text Content')}
                  lng={lng}
                />

                {translationTargetLanguage && (
                  <TextInput
                    lng={uppercaseIsoToLowercaseIso[translationTargetLanguage]}
                    inputProps={translationInput}
                    label={t('Text Content ({{language}})', {
                      language: translationTargetLanguageName,
                    })}
                  />
                )}
              </>
            )}
          </TranslatedField>
        </li>
      </ul>
    </div>
  );
};
