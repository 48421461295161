import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';

import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import { fetchProducts } from 'client/actions/products';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { DeleteProductGroupModal } from 'client/pages/v3/Manifest/ManifestCustomize/Modal/DeleteProductGroupModal';
import { EditProductGroupModal } from 'client/pages/v3/Manifest/ManifestCustomize/Modal/EditProductGroupModal';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { ReduxState } from 'client/reducers/index';
import { Loading } from 'client/pages/Loading';
import { ManifestProductGroup, Product } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';

type Props = {
  isActive: boolean;
};

export const ProductGroupList = ({ isActive }: Props) => {
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const products = useSelector((state: ReduxState) => state.products.summaries);
  const productGroups = useSelector((state: ReduxState) =>
    manifestProductGroupsSelector(state)
  );

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts, invalidated, locale]);

  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [editingProductGroup, setEditingProductGroup] =
    useState<ManifestProductGroup | null>(null);
  const [productGroupToDelete, setProductGroupToDelete] =
    useState<ManifestProductGroup | null>(null);

  const getColumns = () => {
    return [
      {
        Header: t('Title'),
        accessor: 'key',
        width: '320px',
      },
      {
        Header: t('Applies To'),
        id: 'view_type_key',
        accessor: (row: any) => {
          return row.view_type_key || t('ALL_TYPES');
        },
        width: '160px',
      },
      {
        Header: t('Products'),
        accessor: 'product_ids',
        Cell: (cellInfo: any) => (
          <>
            {(cellInfo.value || [])
              .filter((productId: string) => {
                return products.find((p: Product) => p.id === productId);
              })
              .map((productId: string) => {
                const product = products.find(
                  (p: Product) => p.id === productId
                );
                return (
                  <React.Fragment key={productId}>
                    {getDisplayProductName(product)}
                    <br />
                  </React.Fragment>
                );
              })}
          </>
        ),
      },
      {
        Header: t('Grouping'),
        id: 'used_for_grouping',
        accessor: (row: any) => {
          return row.used_for_grouping ? t('used') : t('not used');
        },
        width: '160px',
      },
      {
        Header: t('Action'),
        accessor: 'key',
        Cell: (cellInfo: any) => (
          <div className={styles['p-manifests__customize__flex']}>
            <Button
              size="icon"
              color="white"
              iconBeforeText={
                <i className="c-icon-outline-general-edit-05"></i>
              }
              onClick={() => {
                setEditingProductGroup(cellInfo.original);
                setShowEditModal(true);
              }}
            />
            <Button
              size="icon"
              color="white"
              onClick={() => {
                setProductGroupToDelete(cellInfo.original);
                setShowDeleteModal(true);
              }}
              iconBeforeText={
                <i className="c-icon-outline-general-trash-03"></i>
              }
            />
          </div>
        ),
        sub: true,
        width: '120px',
      },
    ];
  };

  const columns = getColumns();

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      {loading && <Loading />}
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Edit Manifest Groups')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          <Button
            text={t('Create New Manifest Group')}
            onClick={() => {
              setEditingProductGroup(null);
              setShowEditModal(true);
            }}
          />
        </div>
      </div>
      <>
        <EditProductGroupModal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          existingProductGroup={editingProductGroup}
        />

        <DeleteProductGroupModal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          productGroupToDelete={productGroupToDelete}
        />

        <ManifestCustomizeCustomTable
          itemName={t('Manifest group')}
          items={_.sortBy(productGroups, (p) => p.key)}
          columns={columns as any}
        />
      </>
    </div>
  );
};
