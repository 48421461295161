import moment from 'moment-timezone';

import {
  getWeekdayFromDate,
  getWeekdayFromIndex,
} from 'client/libraries/util/weekdays';
import type {
  Account,
  ReservationColumn,
  GuideNotificationSettings,
  OperatingHoursRule,
  GuideSettings,
  Organization,
  GuideSingleDaySchedule,
} from 'shared/models/swagger';

export interface GuideAccountShape {
  id: string;
  name: string;
  email: string;
  role: Account['role'];
  status: string;
  organization_id: string;
  organization_type: 'AGENT' | 'SUPPLIER' | 'GUEST';
  organization_name: string;
  organization_parent_id: string;
  locale: string;
  created_at_date_time_utc: string;
  enabled: boolean;
  columns: ReservationColumn[];
  notification_settings: GuideNotificationSettings | null;
  product_ids: string[];
  operating_hours_rule: OperatingHoursRule | null;
  description: string;
  start_date_local: string;
  end_date_local: string;
  timezone: string;
}
export const toGuideAccountShape = (
  account: Account,
  guideSettings: GuideSettings | null,
  timezone: string
): GuideAccountShape => {
  return {
    id: account.id,
    name: account.name || '',
    email: account.email,
    role: account.role,
    status: account.status || '',
    organization_id: account.organization_id || '',
    organization_type: account.organization_type || 'SUPPLIER',
    organization_name: account.organization_name || '',
    organization_parent_id: account.organization_parent_id || '',
    locale: account.locale || '',
    created_at_date_time_utc: account.created_at_date_time_utc || '',
    enabled: !!account.enabled,
    columns: guideSettings?.columns || [],
    notification_settings: guideSettings?.notification_settings || null,
    product_ids: guideSettings?.product_ids || [],
    operating_hours_rule: guideSettings?.operating_hours_rule || null,
    description: guideSettings?.description || '',
    start_date_local: guideSettings?.start_date_local || '',
    end_date_local: guideSettings?.end_date_local || '',
    timezone: timezone,
  };
};
export const getGuideAccountShapes = (
  accounts: Account[],
  organization: Organization | null
): GuideAccountShape[] => {
  const guides = organization?.guides || [];
  return (accounts || [])
    .filter((account) => account.role === 'GUIDE')
    .filter(
      (account) =>
        organization === null || account.organization_id === organization.id
    )
    .map((account) => {
      const guideSetting = guides.find((guide) => guide.id === account.id);
      return toGuideAccountShape(
        account,
        guideSetting || null,
        organization?.default_timezone || ''
      );
    });
};
export const isActiveAccount = (guide: GuideAccountShape): boolean => {
  if (guide.enabled) {
    return true;
  }

  return false;
};
export const isAvailableForDate = (
  guide: GuideAccountShape,
  participationDate: string
): boolean => {
  const participationWeekday = getWeekdayFromDate(participationDate);

  if (
    (guide.operating_hours_rule?.days_of_week || []).includes(
      participationWeekday
    )
  ) {
    return true;
  }

  return false;
};
export const isOutOfOffice = (
  date: string,
  guideAccountShape: GuideAccountShape | null,
  guideSingleDaySchedules: GuideSingleDaySchedule[]
): boolean => {
  const momentDate = moment.tz(date, guideAccountShape?.timezone || 'UTC');

  if (guideAccountShape?.start_date_local) {
    const momentStartDate = moment.tz(
      guideAccountShape.start_date_local,
      guideAccountShape.timezone
    );

    if (momentDate.isBefore(momentStartDate)) {
      return true;
    }
  }

  if (guideAccountShape?.end_date_local) {
    const momentEndDate = moment.tz(
      guideAccountShape.end_date_local,
      guideAccountShape.timezone
    );

    if (momentDate.isAfter(momentEndDate)) {
      return true;
    }
  }

  const guideSingleDaySchedule = guideSingleDaySchedules.find((schedule) => {
    if (
      schedule.guide_account_id === guideAccountShape?.id &&
      schedule.date_local === date
    ) {
      return true;
    }

    return false;
  });

  if (guideSingleDaySchedule) {
    if (guideSingleDaySchedule.out_of_office?.value) {
      return true;
    }

    return false;
  }

  const operatingHoursRule = guideAccountShape?.operating_hours_rule;
  const weekday = getWeekdayFromIndex(momentDate.weekday());

  if (!operatingHoursRule) {
    return false;
  }

  if (!(operatingHoursRule?.days_of_week || []).includes(weekday)) {
    return true;
  }

  return false;
};
export const getOperationHourText = (
  date: string,
  guideAccountShape: GuideAccountShape | null,
  guideSingleDaySchedules: GuideSingleDaySchedule[]
) => {
  const operatingHoursRule = guideAccountShape?.operating_hours_rule;
  let startTime = operatingHoursRule?.start_time_local || '';
  let endTime = operatingHoursRule?.end_time_local || '';
  const guideSingleDaySchedule = guideSingleDaySchedules.find((schedule) => {
    if (
      schedule.guide_account_id === guideAccountShape?.id &&
      schedule.date_local === date
    ) {
      return true;
    }

    return false;
  });

  if (guideSingleDaySchedule) {
    startTime = guideSingleDaySchedule.start_time_local || startTime;
    endTime = guideSingleDaySchedule.end_time_local || endTime;
  }

  return `${startTime} ~ ${endTime}`;
};
export const isOverwroteBySingleDayOperatingHour = (
  date: string,
  guideAccountShape: GuideAccountShape | null,
  guideSingleDaySchedules: GuideSingleDaySchedule[]
): boolean => {
  const guideSingleDaySchedule = guideSingleDaySchedules.find((schedule) => {
    if (
      schedule.guide_account_id === guideAccountShape?.id &&
      schedule.date_local === date
    ) {
      return true;
    }

    return false;
  });
  return guideSingleDaySchedule ? true : false;
};
export const hasNote = (
  date: string,
  guideAccountShape: GuideAccountShape | null,
  guideSingleDaySchedules: GuideSingleDaySchedule[]
): boolean => {
  const guideSingleDaySchedule = guideSingleDaySchedules.find((schedule) => {
    if (
      schedule.guide_account_id === guideAccountShape?.id &&
      schedule.date_local === date
    ) {
      return true;
    }

    return false;
  });

  if (
    guideSingleDaySchedule?.supplier_note ||
    guideSingleDaySchedule?.guide_note
  ) {
    return true;
  }

  return false;
};
