import * as React from 'react';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleImageInput } from 'client/components/SingleImageInput/SingleImageInput';

type Props = {
  imageUrl: string;
  onChange: (newValue: string) => void;
};
export const SingleProductImageInput = ({ imageUrl, onChange }: Props) => {
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  return (
    <SingleImageInput
      acceptedFileTypes={['image/jpeg', 'image/png']}
      onUploadFinished={(filename, objectUrl) => onChange(objectUrl)}
      initialValue={imageUrl}
      getFileSavePath={(filename) =>
        activeUserOrganization
          ? `${activeUserOrganization?.id}/${filename}`
          : ''
      }
    />
  );
};
