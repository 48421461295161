import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Badge } from 'client/components/v3/Common/Badge';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { getValidators } from 'shared/libraries/validate/validator';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { CopyToClipboardButton } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/CopyToClipboardButton';
import {
  LanguageISO,
  LanguageUppercaseISO,
  uppercaseIsoToLowercaseIso,
} from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import baseStyles from 'client/v3-base.module.css';
import { operationAllowed } from 'shared/models/access';
import { LocationSearchInput } from 'client/components/v3/LocationSearchInput/LocationSearchInput';

export const BasicInfoEditor = () => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const formState = useFormState();
  const salesStatus = formState.values?.salesStatus;
  const [copiedText, setCopiedText] = useState('');
  const org = useSelector(activeUserOrganizationSelector);
  const editingProduct = useContext(EditingProductContext);
  const activeUser = useSelector(activeUserSelector);

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Product Code')}</div>
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <p>
            <Field name="productCode" validate={required}>
              {({ input }) => input.value}
            </Field>
          </p>
        </div>
      </li>
      {operationAllowed(activeUser, 'write', 'productContents') && (
        <>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Product Status')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <EnumRadioButtonGroup
                name="salesStatus"
                liStyle={{ width: '195px', marginRight: '7px' }}
                options={[
                  {
                    value: 'ON_SALE',
                    label: t('On Sale'),
                    tooltipText: t(
                      'Used for product to be listed and available on booking website.'
                    ),
                  },
                  {
                    value: 'LIST_ONLY',
                    label: t('Not On Sale'),
                    tooltipText: t(
                      'Used for product to be listed on booking website, but not available for new reservations.'
                    ),
                  },
                  {
                    value: 'NOT_LISTED',
                    label: t('Not Listed'),
                    tooltipText: t(
                      'Used for product when editing contents or before sales. Product is not listed on the booking website.'
                    ),
                  },
                ]}
              />
              {salesStatus !== 'NOT_LISTED' && (
                <div className={baseStyles['u-mt-3']}>
                  <Field name="isHiddenFromProductListAndSearch">
                    {({ input }) => (
                      <Toggle
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                        label={
                          <div>
                            <div>
                              {t(
                                'Hide this product from product list on booking website and from search results in Google and other search engines.'
                              )}
                            </div>
                            <div>
                              {t(
                                '* Product page will be accessible when the URL is accessed directly'
                              )}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
          </li>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Per-language Visibility')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <div className={styles['p-bookingWidget__language']}>
                <FieldArray name="languages">
                  {({ fields }) =>
                    fields.map((name, idx) => {
                      const productUrl = `${getBookingWebsiteUrl(
                        org,
                        fields.value[idx].language
                      )}/products/${editingProduct?.id}?lng=${
                        uppercaseIsoToLowercaseIso[
                          fields.value[idx].language as keyof Record<
                            LanguageUppercaseISO,
                            LanguageISO
                          >
                        ]
                      }`;

                      return (
                        <Field
                          type="checkbox"
                          name={`${name}.isVisible`}
                          key={name}
                        >
                          {({ input }) => (
                            <>
                              <Toggle
                                {...input}
                                label={getLanguageName(
                                  uppercaseIsoToLowercaseIso[
                                    fields.value[idx].language as keyof Record<
                                      LanguageUppercaseISO,
                                      LanguageISO
                                    >
                                  ],
                                  t
                                )}
                              />
                              <a
                                className={baseStyles['base-link']}
                                href={productUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t('Open booking site')}
                              </a>
                              <CopyToClipboardButton
                                text={productUrl}
                                onClick={() => {
                                  setCopiedText(productUrl);
                                }}
                              />
                              {copiedText === productUrl && (
                                <Snackbar
                                  text={t('Copied!')}
                                  color="success"
                                  shouldShow={copiedText === productUrl}
                                  onClose={() => setCopiedText('')}
                                />
                              )}
                            </>
                          )}
                        </Field>
                      );
                    })
                  }
                </FieldArray>
              </div>
            </div>
          </li>
        </>
      )}
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Internal Product Name')}</div>
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name="internalProductName" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <>
                <TextField {...input} error={touched && error} />
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Product Name')}</div>
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name="productName" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <>
                <TextField {...input} error={touched && error} />
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Product Description')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <TranslatedField name="productDescription">
            {({ input }) => (
              <>
                <div className={styles['p-products__section__body__textarea2']}>
                  <TextArea
                    {...input}
                    maxWidth={800}
                    height={80}
                    showCharacterCount
                  />
                </div>
              </>
            )}
          </TranslatedField>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Images/Videos')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <div className={styles['p-products__section__body__item']}>
            <Field name="mediaUrls">
              {({ input }) => (
                <ImageVideoAudioInput
                  fileUrls={input.value || []}
                  onChange={(newValue) => input.onChange(newValue)}
                />
              )}
            </Field>
          </div>
        </div>
      </li>
      {operationAllowed(activeUser, 'write', 'productContents') && (
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <div className={styles['p-list__item__ttl__txt']}>
              <div>{t('Location Name')}</div>
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <div className={styles['p-products__section__body__item']}>
              <Field name="area">
                {({ input, meta: { touched, error } }) => (
                  <LocationSearchInput
                    location={input.value.name}
                    onSearchChange={(name) =>
                      input.onChange({
                        name,
                        googlePlaceId: '',
                      })
                    }
                    onLocationSelect={({
                      detail: name,
                      key: googlePlaceId,
                    }) => {
                      input.onChange({
                        name,
                        googlePlaceId,
                      });
                    }}
                    error={touched && error}
                    maxWidth={800}
                  />
                )}
              </Field>
            </div>
          </div>
        </li>
      )}
    </ul>
  );
};
