// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Loading } from 'client/pages/Loading/Loading';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import styles from 'client/pages/BookingSitePreview/BookingSitePreview.module.css';

export const BookingSitePreview = () => {
  const { t } = useTranslation();
  const [iframeLoading, setIframeLoading] = React.useState<boolean>(true);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const bookingWidgetSubdomain =
    activeUserOrganization?.booking_widget_subdomain ?? '';
  const tutorialProductId = activeUserOrganization?.tutorial_product_id || '';

  const url = `${getBookingWidgetUrl(
    bookingWidgetSubdomain
  )}/products/${tutorialProductId}`;

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {iframeLoading && <Loading />}
      <iframe
        src={url}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        width="100%"
        height="100%"
        scrolling="auto"
        onLoad={() => {
          setIframeLoading(false);
        }}
      ></iframe>
      <div className={styles['tutorialFixed']}>
        <Link className={styles['tutorialFixed__btn']} to="/reservations/demo">
          {t('Test Booking')}
        </Link>
        <p className={styles['tutorialFixed__balloon']}>CLICK</p>
      </div>
    </div>
  );
};
