import { createSelector } from 'reselect';
import { Action, combineReducers } from 'redux';

import type { SearchInstantWinEventsRequest } from 'client/libraries/util/searchInstantWinEvents';
import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS,
  SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION,
  SET_INSTANT_WIN_EVENT_CURRENT_PAGE,
  SET_INSTANT_WIN_EVENT_ROW_COUNT,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

// Selectors
export const instantWinEventColumnCandidatesSelector = (): string[] => [
  'drawnDate',
  'title',
  'status',
  'tierTitle',
];

const visibleColumnsSelector = (state: ReduxState) =>
  state.instantWinEventTableControls?.visibleColumns ?? [];

export const instantWinEventVisibleColumnsSelector: (
  state: ReduxState
) => string[] = createSelector(
  visibleColumnsSelector,
  instantWinEventColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);

// Reducers
const defaultVisibleColumns = ['drawnDate', 'tierTitle', 'title', 'status'];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const defaultSearchFilters: SearchInstantWinEventsRequest = {
  instantWinIds: [],
  drawnDateFrom: '',
  drawnDateTo: '',
  dateFilterPreset: 'DRAWN_7_DAYS',
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION:
    case SET_INSTANT_WIN_EVENT_ROW_COUNT:
      // Reset current page when the search query changes or when the instantWinEvent row count changes
      return 1;

    case SET_INSTANT_WIN_EVENT_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_INSTANT_WIN_EVENT_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  currentPage: number;
  rowCount: number;
  lastExecutedSearchCondition: SearchInstantWinEventsRequest;
};

const reducer = combineReducers<State>({
  visibleColumns,
  currentPage,
  rowCount,
  lastExecutedSearchCondition,
});
export const instantWinEventTableControls = (state: State, action: Action) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
