import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_CHECKIN_RECORDS_REQUEST,
  FETCH_CHECKIN_RECORDS_SUCCESS,
  FETCH_CHECKIN_RECORDS_FAILURE,
  DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE,
  DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST,
  DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  ListCheckinRecordsResponse,
  ListCheckinRecordsRequest,
  DownloadCheckinRecordCSVRequest,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchCheckinRecordsRequest = () => ({
  type: FETCH_CHECKIN_RECORDS_REQUEST,
});

const fetchCheckinRecordsSuccess = (response: ListCheckinRecordsResponse) => ({
  type: FETCH_CHECKIN_RECORDS_SUCCESS,
  response,
});

const fetchCheckinRecordsFailure = (error: any) => ({
  type: FETCH_CHECKIN_RECORDS_FAILURE,
  error,
});

export const fetchCheckinRecords =
  (queryParams?: ListCheckinRecordsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchCheckinRecordsRequest());
    return axios
      .get('/api/checkinrecords', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchCheckinRecordsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCheckinRecordsFailure(err.message));
      });
  };

const downloadCheckinRecordCSVRequest = () => ({
  type: DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST,
});

const downloadCheckinRecordCSVSuccess = () => ({
  type: DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS,
});

const downloadCheckinRecordCSVFailure = (error: string) => ({
  type: DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE,
  error,
});

export const downloadCheckinRecordCSV =
  (queryParams?: DownloadCheckinRecordCSVRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadCheckinRecordCSVRequest());
    axios
      .get(`/api/checkinrecordcsv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'checkin_records.csv');
        dispatch(downloadCheckinRecordCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          downloadCheckinRecordCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
