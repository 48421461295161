import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MEMOS_SUCCESS,
  FETCH_MEMOS_FAILURE,
  FETCH_MEMOS_REQUEST,
  DELETE_MEMO_FAILURE,
  DELETE_MEMO_REQUEST,
  DELETE_MEMO_SUCCESS,
  CREATE_MEMO_REQUEST,
  UPDATE_MEMO_FAILURE,
  UPDATE_MEMO_SUCCESS,
  UPDATE_MEMO_REQUEST,
  CREATE_MEMO_FAILURE,
  CREATE_MEMO_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { NewMemo, MemoPatch } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMemosRequest = () => ({
  type: FETCH_MEMOS_REQUEST,
});

const fetchMemosSuccess = (response: any) => ({
  type: FETCH_MEMOS_SUCCESS,
  response,
});

const fetchMemosFailure = (error: any) => ({
  type: FETCH_MEMOS_FAILURE,
  error,
});

export const fetchMemos =
  (key: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMemosRequest());
    axios
      .get('/api/memos', {
        params: {
          key,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMemosSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error)) dispatch(fetchMemosFailure('canceled'));
        else dispatch(fetchMemosFailure(error));
      });
  };

const deleteMemoRequest = (id: string) => ({
  type: DELETE_MEMO_REQUEST,
  id,
});

const deleteMemoSuccess = (response: any, id: string) => ({
  type: DELETE_MEMO_SUCCESS,
  response,
  id,
});

const deleteMemoFailure = (error: any) => ({
  type: DELETE_MEMO_FAILURE,
  error,
});

export const deleteMemo =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMemoRequest(id));
    fetch(`/api/memos/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteMemoSuccess(result, id)))
      .catch((error) => dispatch(deleteMemoFailure(error)));
  };

const createMemoRequest = (newMemo: NewMemo) => ({
  type: CREATE_MEMO_REQUEST,
  newMemo,
});

const createMemoSuccess = (response: any) => ({
  type: CREATE_MEMO_SUCCESS,
  response,
});

const createMemoFailure = (error: any) => ({
  type: CREATE_MEMO_FAILURE,
  error,
});

export const createMemo =
  (newMemo: NewMemo) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createMemoRequest(newMemo));
    return axios
      .post('/api/memos', newMemo, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createMemoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createMemoFailure(err.message));
      });
  };

const updateMemoRequest = (id: string, patch: MemoPatch) => ({
  type: UPDATE_MEMO_REQUEST,
  id,
  patch,
});

const updateMemoSuccess = (response: any) => ({
  type: UPDATE_MEMO_SUCCESS,
  response,
});

const updateMemoFailure = (error: any) => ({
  type: UPDATE_MEMO_FAILURE,
  error,
});

export const updateMemo =
  (id: string, patch: MemoPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMemoRequest(id, patch));
    return axios
      .patch(`/api/memos/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateMemoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateMemoFailure(err.message));
      });
  };
