import * as Swagger from 'shared/models/swagger';

export interface TranslationFormItem {
  source: string;
  target: string;
}

export interface FormValues {
  title: string;
  description: string;
  gifts: GiftFormValues[];
  backgroundImageUrl: string;
  stampImageUrl: string;
  stampCount: number;

  showTranslationMode: boolean;
  targetTranslationLanguage?: 'EN_US' | 'JA_JP';
  translations: TranslationFormItem[];
}

export const getInitialValues = (
  guidanceStampRally?: Swagger.GuidanceStampRally
): FormValues => {
  return {
    title: guidanceStampRally?.title ?? '',
    description: guidanceStampRally?.description ?? '',
    backgroundImageUrl: guidanceStampRally?.background_image_url ?? '',
    stampImageUrl: guidanceStampRally?.stamp_image_url ?? '',
    stampCount: guidanceStampRally?.stamp_count ?? 0,
    gifts: (guidanceStampRally?.gifts ?? []).map((gift) =>
      getGiftInitialValues(gift)
    ),

    showTranslationMode: false,
    translations: [],
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.GuidanceStampRallyParams => {
  return {
    title: values.title,
    description: values.description,
    background_image_url: values.backgroundImageUrl,
    stamp_image_url: values.stampImageUrl,
    stamp_count: Number(values.stampCount ?? 0),
    gifts: (values.gifts ?? []).map((gift) =>
      convertGiftFormValuesToSwagger(gift)
    ),
  };
};

// Gift
export interface GiftFormValues {
  key: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  stampCountToRedeem: number;

  showTranslationMode: boolean;
  targetTranslationLanguage?: 'EN_US' | 'JA_JP';
  translations: TranslationFormItem[];
}

export const getGiftInitialValues = (
  guidanceStampRally?: Swagger.GuidanceStampRallyGift
): GiftFormValues => {
  return {
    key: guidanceStampRally?.key ?? '',
    title: guidanceStampRally?.title ?? '',
    description: guidanceStampRally?.description ?? '',
    thumbnailUrl: guidanceStampRally?.thumbnail_url ?? '',
    stampCountToRedeem: guidanceStampRally?.stamp_count_to_redeem ?? 0,

    showTranslationMode: false,
    translations: [],
  };
};

export const convertGiftFormValuesToSwagger = (
  values: GiftFormValues
): Swagger.GuidanceStampRallyGift => {
  return {
    key: values.key,
    title: values.title,
    description: values.description,
    thumbnail_url: values.thumbnailUrl,
    stamp_count_to_redeem: Number(values.stampCountToRedeem ?? 0),
  };
};
