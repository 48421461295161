import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { Input, Select, ToggleButton, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import { getCommonFormFields } from 'client/libraries/util/coreutil';
import { AgentReferencesEditor } from 'client/components/NewProductEditor/AgentReferencesEditor/AgentReferencesEditor';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';

import styles from '../DetailsStep.module.css';

import type { FormField } from './FormValues';

/*
  NOTE(goro):
    preferred_language_iso2 : Used for displaying the field at Extranet. no need to store it to DynamoDB
    representative_name     : Used for keep the representative name char set (alphabet/kana/alphabet&kana). need to store it to DynamoDB. not used at Falconet
    hotel_information       : Used for the flag to show hotel form at Falconet. need to store it DynamoDB
    family_name             : Used for showing forms at Falconet. no neeed at Falconet
    given_name              : Same with the above item
    kana_family_name        : Same with the above item
    kana_given_name         : Same with the above item
*/
const unRemovableFields = [
  'email',
  'preferred_language_iso2',
  'representative_name',
];
const disabledFields = [
  'email',
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'hotel_tbd_form',
];
const disabledExceptRequiredFormFields = ['hotel_information'];
const disabledExceptFormatFormFields = ['representative_name'];
const disabledExceptQuestionFormFields: string[] = [];
const disableConstraintFormFields = ['consent_form'];
const uniqueFields = [
  'preferred_language_iso2',
  'hotel_information',
  'representative_name',
  'international_phone',
  'consent_form',
  'hotel_tbd_form',
];

const isRemovableField = (
  fieldName: string,
  product: Product | null
): boolean => {
  if (unRemovableFields.includes(fieldName)) {
    return false;
  }

  // NOTE(goro) : can remove the form to input hotel information at BookingWidget&PMP when no transportations
  if (
    (fieldName === 'hotel_information' || fieldName === 'hotel_tbd_form') &&
    product?.transportations?.length !== 0
  ) {
    return false;
  }

  return true;
};

const isFormatFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptFormatFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isRequiredFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptRequiredFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isQuestionFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) &&
    !disabledExceptQuestionFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isConstraintFormDisabled = (fieldName: string): boolean => {
  if (
    disabledFields.includes(fieldName) ||
    disableConstraintFormFields.includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const isFormDisabled = (fieldName: string): boolean => {
  if (disabledFields.includes(fieldName)) {
    return true;
  }

  return false;
};

const isKanaAvailableField = (fieldName: string): boolean => {
  if (fieldName === 'representative_name') {
    return true;
  }

  return false;
};

const getFormatOptions = (t: TranslateFuncType, fieldName: string) => {
  return [
    ...[
      {
        value: 'alpha-name',
        text: t('Alphabet characters or spaces'),
      },
    ],
    ...(isKanaAvailableField(fieldName)
      ? [
          {
            value: 'kana-name',
            text: t('Kana or Alphabet characters'),
          },
          {
            value: 'alpha-kana-name',
            text: t('Alphabet characters or spaces form & Kana form'),
          },
        ]
      : [
          {
            value: 'email',
            text: t('Email'),
          },
          {
            value: 'phone',
            text: t('Phone Number'),
          },
          {
            value: 'friendly_phone',
            text: t('Phone Number (Friendly)'),
          },
          {
            value: 'international_phone',
            text: t('International Phone Number'),
          },
          {
            value: 'float',
            text: t('Numeric'),
          },
          {
            value: 'non-negative-integer',
            text: t('Positive Numeric'),
          },
          {
            value: 'yyyy-mm-dd',
            text: t('YYYY-MM-DD'),
          },
          {
            value: 'datetime-local',
            text: t('Date/Time'),
          },
          {
            text: t('File Upload'),
            value: 'file-upload',
          },
          ...(config.enableFieldResponseImageUpload
            ? [
                {
                  text: t('Image Upload'),
                  value: 'image-upload',
                },
              ]
            : []),
        ]),
  ];
};

const getDefaultFormField = (): FormField => {
  return {
    key: 'custom',
    fieldType: 'PER_PARTICIPANT',
    prompt: '',
    required: 'WHEN_BOOKING',
    choices: [],
    responseConstraint: 'NO_RESTRICTION',
    format: '',
    inputElement: 'TEXT_BOX',
  };
};

export const ReservationFormEditor = () => {
  const { t } = useTranslation();
  // NOTE(goro) : override CSS definition
  const transparentStyle = {
    backgroundColor: 'transparent',
  };
  const editingProduct = React.useContext(EditingProductContext);
  const sourceLanguageUppercaseIso = editingProduct?.source_language ?? 'JA_JP';
  const sourceLanguageLowercaseIso =
    uppercaseIsoToLowercaseIso[sourceLanguageUppercaseIso];
  const form = useForm();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const { required } = getValidators(t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  return (
    <>
      <FieldArray name="formFields">
        {({ fields }) => (
          <div className={styles['c-table-list']}>
            <table>
              {fields.length === 0 ? (
                <tr>
                  <th>
                    <AddIcon
                      onClick={() =>
                        (fields as any).insertAt(0, getDefaultFormField())
                      }
                    />
                  </th>
                  <td />
                </tr>
              ) : (
                fields.map((name, idx) => (
                  <tr key={name}>
                    <th>
                      {t('Form {{index}}', {
                        index: idx + 1,
                      })}
                    </th>
                    <td colSpan={2}>
                      <ul className={styles['scheds']}>
                        <li>
                          <div className={styles['detailInfo__ttl']}>
                            <p>{t('Form Input Name')}</p>
                          </div>
                          <div className={styles['scheds__body']}>
                            <Field name={`${name}.key`}>
                              {({ input }) => (
                                <Select
                                  disabled={isFormDisabled(
                                    fields.value[idx].key
                                  )}
                                  maxWidth={320}
                                  options={getCommonFormFields(
                                    t,
                                    sourceLanguageLowercaseIso
                                  ).filter((formField) => {
                                    return !(
                                      uniqueFields.includes(formField.value) &&
                                      formField.value !== input.value &&
                                      fields.value.find((field) => {
                                        return field.key === formField.value;
                                      })
                                    );
                                  })}
                                  value={input.value}
                                  onChange={(e, { value }) => {
                                    const formField = getCommonFormFields(
                                      t,
                                      sourceLanguageLowercaseIso
                                    ).find((field) => field.value === value);

                                    if (value !== 'custom') {
                                      form?.change(
                                        `${name}.prompt`,
                                        formField?.text || ''
                                      );
                                      form?.change(
                                        `${name}.fieldType`,
                                        formField?.defaultType ||
                                          'PER_PARTICIPANT'
                                      );
                                      form?.change(
                                        `${name}.responseConstraint`,
                                        formField?.responseConstraint || 'NONE'
                                      );
                                      form?.change(
                                        `${name}.format`,
                                        formField?.format || ''
                                      );
                                      form?.change(
                                        `${name}.choices`,
                                        formField?.choices || []
                                      );
                                      form?.change(
                                        `${name}.required`,
                                        formField?.required || 'WHEN_BOOKING'
                                      );
                                      form.change(
                                        `${name}.inputElement`,
                                        formField?.responseConstraint ===
                                          'NO_RESTRICTION'
                                          ? 'TEXT_BOX'
                                          : undefined
                                      );
                                    }

                                    input.onChange(value);
                                  }}
                                  style={transparentStyle}
                                />
                              )}
                            </Field>
                          </div>
                        </li>
                        <li>
                          <div className={styles['detailInfo__ttl']}>
                            <p>{t('Form Input Type')}</p>
                          </div>
                          <div className={styles['scheds__body']}>
                            <EnumRadioButtonGroup
                              disabled={isFormDisabled(fields.value[idx].key)}
                              name={`${name}.fieldType`}
                              options={[
                                {
                                  value: 'PER_PARTICIPANT',
                                  label: t('Per-participant'),
                                  tooltipText: t(
                                    'Specify the question you want to ask for each participant (answers required for the number of people)'
                                  ),
                                },
                                {
                                  value: 'PER_BOOKING',
                                  label: t('Per-booking'),
                                  tooltipText: t(
                                    'Specify the question you want to ask for each reservation (one answer is enough for each reservation)'
                                  ),
                                },
                              ]}
                              style={transparentStyle}
                            />
                          </div>
                        </li>
                        <li>
                          <div className={styles['detailInfo__ttl']}>
                            <p>{t('Required / Optional')}</p>
                          </div>
                          <div className={styles['scheds__body']}>
                            <EnumRadioButtonGroup
                              disabled={isRequiredFormDisabled(
                                fields.value[idx].key
                              )}
                              name={`${name}.required`}
                              options={[
                                {
                                  value: 'WHEN_BOOKING',
                                  label: t('Required when Booking'),
                                  tooltipText: t(
                                    'Select if you want to require an answer when making a reservation (reservation is not possible without an answer)'
                                  ),
                                },
                                {
                                  value: 'BEFORE_PARTICIPATING',
                                  label: t('Required before participation'),
                                  tooltipText: t(
                                    'Select if you want to require an answer by the time you participate (you can make a reservation without an answer)'
                                  ),
                                },
                                {
                                  value: 'OPTIONAL',
                                  label: t('Optional'),
                                  tooltipText: t(
                                    'Select if you want the answer to be optional (reservation is possible without answering)'
                                  ),
                                },
                              ]}
                              style={transparentStyle}
                            />
                          </div>
                        </li>
                        {fields.value[idx].key === 'hotel_information' && (
                          <Box mt={2} mb={2}>
                            <Field name="showHotelInformationInputForCheckinCheckout">
                              {({ input }) => (
                                <ToggleButton
                                  label={t(
                                    'Ask for hotel information for checkin/checkout reservations'
                                  )}
                                  checked={input.value}
                                  onChange={() => {
                                    input.onChange(!input.value);
                                  }}
                                />
                              )}
                            </Field>
                          </Box>
                        )}
                        <li className={styles['scheds__cell']}>
                          <TranslatedField
                            name={`${name}.prompt`}
                            validate={required}
                          >
                            {({
                              input,
                              translationInput,
                              meta: { error, touched },
                            }) => (
                              <>
                                <div>
                                  <div className={styles['detailInfo__ttl']}>
                                    <p>{t('Question Text')}</p>
                                    <Tooltip
                                      text={t(
                                        'Enter the question text to be displayed on the reservation screen'
                                      )}
                                    />
                                  </div>
                                  <p className={styles['scheds__body']}>
                                    <TextArea
                                      {...(input as any)}
                                      disabled={isQuestionFormDisabled(
                                        fields.value[idx].key
                                      )}
                                      maxWidth={800}
                                      height={80}
                                      error={touched && error}
                                    />
                                  </p>
                                </div>
                                {translationTargetLanguage && (
                                  <div>
                                    <div className={styles['detailInfo__ttl']}>
                                      <p>{`${t(
                                        'Question Text'
                                      )} (${translationTargetLanguageName})`}</p>
                                      <Tooltip
                                        text={t(
                                          'Enter the question text to be displayed on the reservation screen'
                                        )}
                                      />
                                    </div>
                                    <p className={styles['scheds__body']}>
                                      <TextArea
                                        {...(translationInput as any)}
                                        maxWidth={800}
                                        height={80}
                                        error={touched && error}
                                      />
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </TranslatedField>
                        </li>
                        <li className={styles['scheds__cell']}>
                          <Field name={`${name}.responseConstraint`}>
                            {({ input: { value } }) => (
                              <div>
                                <div className={styles['detailInfo__ttl']}>
                                  <p>{t('Response Constraint')}</p>
                                </div>
                                <div className={styles['scheds__body']}>
                                  <EnumRadioButtonGroup
                                    disabled={isConstraintFormDisabled(
                                      fields.value[idx].key
                                    )}
                                    name={`${name}.responseConstraint`}
                                    options={[
                                      {
                                        value: 'CHOICES',
                                        label: t('Choices'),
                                        tooltipText: t(
                                          'Choose from provided options'
                                        ),
                                      },
                                      {
                                        value: 'FORMAT',
                                        label: t('Text Format'),
                                        tooltipText: t(
                                          'Input required to be in specified format'
                                        ),
                                      },
                                      {
                                        value: 'NO_RESTRICTION',
                                        label: t('None'),
                                        tooltipText: t('Allow free text entry'),
                                      },
                                    ]}
                                    onChange={(newValue) => {
                                      switch (newValue) {
                                        case 'CHOICES':
                                          form?.change(`${name}.format`, '');
                                          form?.change(`${name}.choices`, ['']);
                                          form.change(
                                            `${name}.inputElement`,
                                            undefined
                                          );
                                          break;

                                        case 'FORMAT':
                                          form.change(`${name}.choices`, []);
                                          form.change(
                                            `${name}.format`,
                                            'alpha-name'
                                          );
                                          form.change(
                                            `${name}.inputElement`,
                                            undefined
                                          );
                                          break;

                                        default:
                                          form.change(`${name}.format`, '');
                                          form.change(`${name}.choices`, []);
                                          form.change(
                                            `${name}.inputElement`,
                                            'TEXT_BOX'
                                          );
                                          break;
                                      }
                                    }}
                                    style={transparentStyle}
                                  />
                                </div>
                                {value === 'NO_RESTRICTION' &&
                                  !isConstraintFormDisabled(
                                    fields.value[idx].key
                                  ) && (
                                    <Field name={`${name}.inputElement`}>
                                      {({ input }) => (
                                        <Select
                                          label={t('Input Element')}
                                          options={[
                                            {
                                              value: 'TEXT_BOX',
                                              text: t('Text box'),
                                            },
                                            {
                                              value: 'TEXT_AREA',
                                              text: t('Text area'),
                                            },
                                          ]}
                                          maxWidth={320}
                                          value={input.value}
                                          onChange={(e, { value }) =>
                                            input.onChange(value)
                                          }
                                        />
                                      )}
                                    </Field>
                                  )}
                                {value === 'FORMAT' && (
                                  <>
                                    <p
                                      className={clsx(
                                        styles['scheds__ttl'],
                                        baseStyles['blue']
                                      )}
                                    >
                                      {t('Text Format')}
                                    </p>
                                    <div className={styles['scheds__body']}>
                                      <Field name={`${name}.format`}>
                                        {({ input }) => (
                                          <Select
                                            disabled={isFormatFormDisabled(
                                              fields.value[idx].key
                                            )}
                                            maxWidth={320}
                                            options={getFormatOptions(
                                              t,
                                              fields.value[idx].key
                                            )}
                                            value={input.value}
                                            onChange={(
                                              e,
                                              { value: newValue }
                                            ) => {
                                              input.onChange(newValue);
                                            }}
                                            style={transparentStyle}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </>
                                )}
                                {value === 'CHOICES' && (
                                  <>
                                    <p
                                      className={clsx(
                                        styles['scheds__ttl'],
                                        styles['blue']
                                      )}
                                    >
                                      {t('Choices')}
                                    </p>
                                    <ListEditor
                                      fieldDisabled={isFormDisabled(
                                        fields.value[idx].key
                                      )}
                                      buttonDisabled={isFormDisabled(
                                        fields.value[idx].key
                                      )}
                                      name={`${name}.choices`}
                                      style={transparentStyle}
                                    />
                                  </>
                                )}
                              </div>
                            )}
                          </Field>
                        </li>
                        {isNutmegAdmin && (
                          <>
                            <li className={styles['scheds__cell']}>
                              <div>
                                <div className={styles['detailInfo__ttl']}>
                                  <p>{t('Agent References')}</p>
                                </div>
                                <div className={styles['scheds__body']}>
                                  <AgentReferencesEditor
                                    name={`${name}.referencesInAgentSystems`}
                                  />
                                </div>
                              </div>
                            </li>
                            {editingProduct?.gateway_name &&
                              editingProduct?.gateway_name !== 'nutmeg' && (
                                <Field
                                  name={`${name}.referenceInSupplierSystem`}
                                >
                                  {({ input }) => (
                                    <Input
                                      label={t('Supplier System Reference')}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              )}
                          </>
                        )}
                      </ul>
                    </td>
                    <td className={styles['c-table-list__btns']}>
                      <div className={baseStyles['base-flex']}>
                        <AddIcon
                          onClick={() =>
                            (fields as any).insertAt(
                              idx + 1,
                              getDefaultFormField()
                            )
                          }
                        />
                        {isRemovableField(
                          fields.value[idx].key,
                          editingProduct
                        ) && <DeleteIcon onClick={() => fields.remove(idx)} />}
                      </div>
                    </td>
                  </tr>
                ))
              )}
              <tr>
                <th>{t('Special Requests')}</th>
                <td colSpan={3}>
                  <Field name="showAgentNotesInputDuringGuestCheckout">
                    {({ input }) => (
                      <ToggleButton
                        label={t(
                          'Show input form for "Special Requests" on the checkout page.'
                        )}
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                      />
                    )}
                  </Field>
                </td>
              </tr>
            </table>
          </div>
        )}
      </FieldArray>
    </>
  );
};
