import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TableSmall } from 'client/components/v3/Table/TableSmall';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type { CustomTableColumn } from 'client/components/v3/Table/TableSmall';
import type { ReduxState } from 'client/reducers';
import type { MediaDownloadOrder } from 'shared/models/swagger';

type Props = {
  orders: MediaDownloadOrder[];
};

const useColumns = (): CustomTableColumn<MediaDownloadOrder>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  return [
    {
      Header: t('Purchase Date'),
      accessor: (row: MediaDownloadOrder) =>
        moment(row.created_date_time_utc).locale(locale).format('lll'),
      width: '256',
    },
    {
      Header: t('Items'),
      width: '160',
      accessor: (row: MediaDownloadOrder) => {
        const itemDescriptions: string[] = [];
        if ((row.media_items ?? []).length > 0) {
          itemDescriptions.push(
            t('Photo/Video x {{itemCount}}', {
              itemCount: (row.media_items ?? []).length,
            })
          );
        }
        if ((row.media_sets ?? []).length > 0) {
          itemDescriptions.push(
            t('Photo/Video Package x {{itemCount}}', {
              itemCount: (row.media_sets ?? []).length,
            })
          );
        }

        return (
          <ul>
            {itemDescriptions.map((description) => (
              <li key={description}>{description}</li>
            ))}
          </ul>
        );
      },
    },
    {
      Header: t('Gross'),
      width: '160',
      accessor: (row: MediaDownloadOrder) =>
        formattedCurrencyAmount(row.billing_info?.amount_gross ?? ''),
    },
  ];
};

export const ReservationMediaDownloadOrderList = ({ orders }: Props) => {
  const columns = useColumns();
  return (
    <TableSmall
      items={orders}
      columns={columns}
      shouldOmitMobileFirstChildStyles={true}
    />
  );
};
