import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';

import styles from './MediaItems.module.css';

export const MediaItems: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.profilePictures}>
      <h2 className={styles.sectionTitle}>{t('Photos')}</h2>
      <Field name="mediaUrls">
        {({ input }) => (
          <ImageVideoAudioInput
            fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
            fileUrls={input.value || []}
            onChange={(newValue) => input.onChange(newValue)}
          />
        )}
      </Field>
    </section>
  );
};
