import { combineReducers } from 'redux';

import {
  FETCH_REDEEMED_RECORDS_REQUEST,
  FETCH_REDEEMED_RECORDS_SUCCESS,
  FETCH_REDEEMED_RECORDS_FAILURE,
  DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE,
  DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST,
  DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { RedeemedRecord } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_REDEEMED_RECORDS_REQUEST:
      return true;

    case FETCH_REDEEMED_RECORDS_SUCCESS:
    case FETCH_REDEEMED_RECORDS_FAILURE:
      return false;

    default:
      return state;
  }
};

const downloading = (state = false, action: any): boolean => {
  switch (action.type) {
    case DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST:
      return true;

    case DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS:
    case DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_REDEEMED_RECORDS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (state: RedeemedRecord[] = [], action: any): RedeemedRecord[] => {
  switch (action.type) {
    case FETCH_REDEEMED_RECORDS_SUCCESS:
      return action.response.redeemed_records;

    default:
      return state;
  }
};

interface State {
  all: RedeemedRecord[];
  loading: boolean;
  error: string;
  downloading: boolean;
}

export const redeemedRecords = combineReducers<State>({
  all,
  loading,
  error,
  downloading,
});
