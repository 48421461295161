import { ReservationReportHeader } from 'client/pages/v3/Reservation/ReservationReport/ReservationReportHeader';
import { ReservationReportSettingsBlock } from 'client/pages/v3/Reservation/ReservationReport/ReservationReportSettingsBlock/ReservationReportSettingsBlock';
import { ReservationReportGraphBlock } from 'client/pages/v3/Reservation/ReservationReport/ReservationReportGraphBlock/ReservationReportGraphBlock';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ReservationReport = () => {
  return (
    <V3Page>
      <ReservationReportHeader />
      <ReservationReportSettingsBlock />
      <ReservationReportGraphBlock />
    </V3Page>
  );
};
