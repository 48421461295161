import * as React from 'react';
import { Field } from 'react-final-form';

import {
  truncated,
  withQueryParams,
  ProductCardFields,
  ProductList,
  groupedProducts,
} from '../util';

import { HighlightedBookingDeadlineSection } from './section/HighlightedBookingDeadlineSection';
import { BaseTemplate } from './BaseTemplate';
import { KeyVisualSection } from './section/KeyVisualSection';
import { CustomerGreetingsSection } from './section/CustomerGreetingsSection';
import { OtherRecommendedProductsSection } from './section/OtherRecommendedProductsSection';

const HighlightedBookingDeadlineSectionEditButton = React.lazy(
  () =>
    import(
      'client/components/TemplateEditButtons/HighlightedBookingDeadlineSectionEditButton'
    )
);
const MainProductEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/MainProductEditButton')
);
const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  keyVisualUrl: string;
  headline: string;
  headlineDescription: string;
  highlightedBookingDeadlineSection: {
    title: string;
    date: string;
    style: string;
  };
  mainProduct: ProductCardFields;
  productList: ProductList;
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const LimitedTimeSale = ({
  editMode,
  language,
  logoUrl,
  newsletterCampaignId,
  unsubscribeUrl,
  topPageUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerName,
  customerIntroduction,
  introParagraph,
  keyVisualUrl,
  headline,
  headlineDescription,
  highlightedBookingDeadlineSection,
  mainProduct,
  productList,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* Key visual */}
      <KeyVisualSection
        data={{
          editMode: editMode ?? false,
          keyVisualUrl: keyVisualUrl,
        }}
      />
      {/* Customer greetings お客様向けメッセージ */}
      <CustomerGreetingsSection
        data={{
          editMode: editMode ?? false,
          customerName: customerName ?? '',
          customerIntroduction: customerIntroduction,
          introParagraph: introParagraph,
        }}
      />
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* 当店一番人気のプランは残席わずか！ */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td>
          <div
            style={{
              margin: 0,
              padding: '1.5em',
              backgroundColor: '#ffffff',
            }}
          >
            {editMode && (
              <Field name="templateValues.headline">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                fontSize: '1.5em',
                whiteSpace: 'pre-line',
                borderBottom: '1px solid #E4E4E7',
                paddingBottom: '1em',
              }}
            >
              {headline}
            </p>
            {editMode && (
              <Field name="templateValues.headlineDescription">
                {({ input }) => (
                  <TextAreaEditButton
                    initialValue={input.value}
                    onSave={(text) => input.onChange(text)}
                  />
                )}
              </Field>
            )}
            <p
              style={{
                margin: '1em 0 0 0',
                padding: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {headlineDescription}
            </p>
            {editMode && (
              <Field name="templateValues.highlightedBookingDeadlineSection">
                {({ input }) => (
                  <HighlightedBookingDeadlineSectionEditButton
                    onSave={(newValue) => input.onChange(newValue)}
                    initialValue={input.value}
                  />
                )}
              </Field>
            )}
            {highlightedBookingDeadlineSection && (
              <HighlightedBookingDeadlineSection
                data={highlightedBookingDeadlineSection}
              />
            )}
            {editMode && (
              <Field name="templateValues.mainProduct">
                {({ input }) => (
                  <MainProductEditButton
                    onSave={(newValue) => input.onChange(newValue)}
                    initialValue={input.value}
                    language={language}
                  />
                )}
              </Field>
            )}
            <div
              style={{
                marginTop: '1em',
                border: '1px solid #E4E4E7',
                borderRadius: '16px',
              }}
            >
              <a
                href={withQueryParams(mainProduct.productUrl, {
                  utm_source: 'ntmg.newsletter',
                  utm_medium: 'email',
                  utm_campaign: newsletterCampaignId ?? 'todo',
                  utm_content: 'mainProduct',
                  lng: language,
                })}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: '100%',
                  margin: '0',
                  padding: 0,
                  display: 'table',
                  textDecoration: 'none',
                }}
              >
                <div
                  style={{
                    margin: '1.5em',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={mainProduct.productThumbnailUrl}
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: '0 1.5em 1.5em 1.5em',
                    color: '#333333',
                    verticalAlign: 'middle',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: '16px',
                      fontWeight: 'bold',
                      lineHeight: '1.5em',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {mainProduct.productName}
                  </p>
                  <p
                    style={{
                      margin: '.5em 0 0 0',
                      padding: 0,
                      fontSize: '12px',
                      color: '#4A6C7A',
                      lineHeight: '1.5em',
                    }}
                  >
                    {truncated(mainProduct.productDescription, 200)}
                  </p>
                </div>
                <div style={{ padding: '0 1.5em 1.5em 1.5em' }}>
                  <a
                    href={withQueryParams(mainProduct.productUrl, {
                      utm_source: 'ntmg.newsletter',
                      utm_medium: 'email',
                      utm_campaign: newsletterCampaignId ?? 'todo',
                      utm_content: 'mainProduct',
                      lng: language,
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      maxWidth: '240px',
                      borderRadius: '9999px',
                      textDecoration: 'none',
                      padding: '2px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                  >
                    <span
                      style={{
                        margin: 0,
                        padding: 0,
                        display: 'block',
                        fontWeight: 'bold',
                        width: '100%',
                        height: '48px',
                        lineHeight: '48px',
                        fontSize: '16px',
                        backgroundColor: '#4DA1EF',
                        color: '#ffffff',
                        textDecoration: 'none',
                        textAlign: 'center',
                        borderRadius: '40px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {mainProduct.buttonText}
                    </span>
                  </a>
                </div>
              </a>
            </div>
          </div>
        </td>
      </tr>
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* Other recommended products その他のおすすめプラン */}
      <OtherRecommendedProductsSection
        data={{
          editMode: editMode ?? false,
          productList: productList,
          language: language,
          groupedProducts: groupedProducts(productList.products),
          newsletterCampaignId: newsletterCampaignId ?? '',
        }}
      />
    </BaseTemplate>
  );
};
