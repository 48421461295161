import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState } from 'react';

import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { getWeekdayText } from 'client/libraries/util/weekdays';
import { printPriceTitle } from 'client/libraries/util/productShape';
import type { ProductShape } from 'client/libraries/util/productShape';
import { getScheduleText } from 'client/libraries/util/getScheduleText';
import { PromotionDetails } from 'client/pages/ProductDetails/ProductContentsPane/PromotionDetails/PromotionDetails';
import baseStyles from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductDetailsSection.module.css';
import v3BaseStyles from 'client/v3-base.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import styles from './OperationInfo.module.css';

interface OwnProps {
  product: ProductShape;
  contentLanguage?: string;
}
type Props = OwnProps;
export const OperationInfo = ({ product, contentLanguage }: Props) => {
  const [selectedPriceScheduleIdx, setSelectedPriceScheduleIdx] =
    useState<number>(0);
  const { t, i18n } = useTranslation();

  const priceScheduleOptions = product.priceSchedules.map(
    (priceSchedule, idx) => ({
      text: getScheduleText(priceSchedule, t),
      value: idx.toString(),
    })
  );

  return (
    <section
      className={clsx(
        baseStyles['p-productsDetail__section'],
        styles['p-productsDetail__operationInfo']
      )}
    >
      <p>{product.description}</p>
      <ul
        className={clsx(styles['c-table1'], baseStyles['base-margin-top-32'])}
      >
        <li className={styles['c-table1__list']}>
          <p className={styles['c-table1__list__title']}>{t('Days of Week')}</p>
          <p className={styles['c-table1__list__body']}>
            {product.operatingWeekdays
              .map((weekday) => getWeekdayText(weekday, t))
              .join(',')}
          </p>
        </li>
        <li className={styles['c-table1__list']}>
          <p className={styles['c-table1__list__title']}>{t('Duration')}</p>
          <p className={styles['c-table1__list__body']}>
            {t('{{durationInHours}} hours', {
              durationInHours: product.durationInHours,
            })}
          </p>
        </li>
      </ul>
      {(product.priceSchedules ?? []).length > 0 && (
        <div>
          <p className={styles['p-productsDetail__operationInfo__ttl']}>
            {t('Price')}
          </p>
          <div className={styles['p-productsDetail__operationInfo__body']}>
            <SingleDropdown
              options={priceScheduleOptions}
              selectedOption={selectedPriceScheduleIdx.toString()}
              onChange={(val) => setSelectedPriceScheduleIdx(Number(val))}
            />
            {product.priceSchedules[selectedPriceScheduleIdx].prices.length >
              0 && (
              <table className={tableStyles['c-tableSmall']}>
                <tbody>
                  {product.priceSchedules[selectedPriceScheduleIdx].prices.map(
                    (price) => (
                      <tr key={price.unitTitle}>
                        <th className={v3BaseStyles['u-width-176']}>
                          {printPriceTitle(price, t)}
                        </th>
                        <td>{formattedCurrencyAmount(price.amount)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
      <div>
        <PromotionDetails
          product={product}
          contentLanguage={contentLanguage || i18n.language}
        />
      </div>
    </section>
  );
};
