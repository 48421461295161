import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import type { TFunction } from 'react-i18next';

import type { ReduxState } from 'client/reducers/index';
import {
  generateAgentReference,
  getGuestTypesUsedInProductInstance,
  isHiddenFromReservationFormFields,
} from 'client/libraries/util/util';
import {
  getFieldResponseErrors,
  printGuestType,
} from 'client/libraries/util/coreutil';
import {
  Button,
  FieldWrapper,
  MultiSelect,
  Select,
} from 'client/components/Form';
import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { createReservation } from 'client/actions/reservations';
import componentStyles from 'client/components/components.module.css';
import {
  BookingSourceType,
  Equipment,
  EquipmentInstance,
  Field,
  NewReservation,
  ProductInstance,
  Reservation,
  ReservationEquipmentAssignment,
  Service,
} from 'shared/models/swagger';
import { printETicketByEpsonThermalPrinter } from 'client/libraries/util/printETicketByEpsonThermalPrinter';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import printerIcon from 'client/images/ic_printer.svg';
import { PlusMinusNumberInputForm } from 'client/components/PlusMinusNumberInputForm/PlusMinusNumberInputForm';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { FieldsFormV2 as FieldsForm } from 'client/components/FieldsFormV2';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { fetchProductByID } from 'client/actions/products';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getInitialAddOnResources } from 'client/libraries/util/resourceManager';
import { FareSummaryV2 as FareSummary } from 'client/pages/BookingForm/FareSummaryV2';
import { useTicketNetworkPrinterIpAddress } from 'client/hooks/useTicketNetworkPrinterIpAddress';
import { getLogoBase64 } from 'client/libraries/util/getLogoBase64';
import { SeatContext } from 'client/contexts/SeatContext';
import { CREATE_RESERVATION_SUCCESS } from 'client/constants/ActionTypes';

import { SeatSelectModal } from './SeatSelectModal';
import {
  getEquipmentBlockReference,
  getEquipmentBlockInstanceProperty,
} from './utils';
import styles from './SeatAssignmentNewReservationModal.module.css';

const getPerParticipantAddOns = (addOns: Service[]): Service[] => {
  return addOns.filter(
    (addOn) =>
      addOn.pricing && addOn.pricing.find((p) => p.method === 'PER_PARTICIPANT')
  );
};
const getPerBookingAddOns = (addOns: Service[]): Service[] => {
  return addOns.filter(
    (addOn) =>
      addOn.service_type === 'FREE' ||
      (addOn.pricing && addOn.pricing.find((p) => p.method === 'PER_BOOKING'))
  );
};

export const getDisplayReservationFormFields = (
  reservation_form_fields: Field[],
  t: TFunction
): Field[] => {
  return [
    {
      // NOTE(goro) : Email is always displayed
      key: 'phone',
      prompt: t('Phone'),
      format: 'phone',
      required: 'OPTIONAL',
      type: 'PER_BOOKING',
    },
    ...(reservation_form_fields || []).filter((field) => {
      return !isHiddenFromReservationFormFields(field.key || '');
    }),
  ];
};

interface Props {
  onReset: () => void;
  open: boolean;
  onClose: () => void;
  selectedProductInstances: ProductInstance[];
  selectedEquipmentInstanceId: string;
  fetchEquipmentAssignments?: () => void;
}

export const SeatAssignmentNewReservationModal = ({
  onReset,
  open,
  onClose,
  selectedProductInstances,
  selectedEquipmentInstanceId,
  fetchEquipmentAssignments,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [newReservation, setNewReservation] = React.useState<NewReservation>({
    product_instance_id: selectedProductInstances[0]?.id || '',
    guests: [],
    payment_type: 'PAY_ON_BOARD',
    agent_reference: generateAgentReference(),
    booking_source: {
      source_type: 'DIRECT_WALK_IN',
    },
  });
  const [showSeatSelectModal, setShowSeatSelectModal] =
    React.useState<boolean>(false);
  const { selectedEquipmentBlockInstanceKeys } = React.useContext(SeatContext);
  const [
    initialAvailablePerBookingAddOns,
    setInitialAvailablePerBookingAddOns,
  ] = React.useState<Record<string, number>>({});
  const [availablePerParticipantAddOns, setAvailablePerParticipantAddOns] =
    React.useState<Record<string, number>>({});
  const [showParticipantDeletionErrorAlert, setShowParticipantDeletionError] =
    React.useState<boolean>(false);
  const [
    showParticipantDeletionErrorGuestTypeKey,
    setShowParticipantDeletionErrorGuestTypeKey,
  ] = React.useState<string>('');

  const { networkPrinterIpAddress } = useTicketNetworkPrinterIpAddress();

  const products = useSelector((state: ReduxState) => state.products.summaries);
  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const productById = useSelector((state: ReduxState) => state.products.byID);

  React.useEffect(() => {
    const productInstance = selectedProductInstances.find(
      (productInstance) =>
        productInstance.id === newReservation.product_instance_id
    );

    if (
      productInstance &&
      productById &&
      !productById[productInstance.product_id]
    ) {
      dispatch(fetchProductByID(productInstance.product_id, i18n.language));
    }
  }, [
    selectedProductInstances,
    newReservation.product_instance_id,
    productById,
  ]);

  React.useEffect(() => {
    const productInstance = selectedProductInstances.find(
      (productInstance) =>
        productInstance.id === newReservation.product_instance_id
    );

    if (!productInstance) {
      return;
    }

    const [
      initialAvailablePerBookingAddOns,
      initialAvailablePerParticipantAddOns,
    ] = getInitialAddOnResources(productInstance);

    setInitialAvailablePerBookingAddOns(initialAvailablePerBookingAddOns);
    setAvailablePerParticipantAddOns(initialAvailablePerParticipantAddOns);
  }, [newReservation.product_instance_id]);

  const product = React.useMemo(() => {
    const productInstance = selectedProductInstances.find(
      (productInstance) =>
        productInstance.id === newReservation.product_instance_id
    );
    return productById[productInstance?.product_id || ''];
  }, [
    selectedProductInstances,
    newReservation.product_instance_id,
    productById,
  ]);

  const productInstanceOptions = React.useMemo(() => {
    return selectedProductInstances.map((productInstance) => ({
      text:
        products.find(
          (product) => product.id === productInstance.product_id ?? ''
        )?.product_name || '',
      value: productInstance.id || '',
    }));
  }, [products, selectedProductInstances]);

  const productInstance = React.useMemo(() => {
    return selectedProductInstances.find(
      (productInstance) =>
        productInstance.id === newReservation.product_instance_id
    );
  }, [newReservation, selectedProductInstances]);

  const productSummary = React.useMemo(() => {
    if (!productInstance) {
      return null;
    }
    return products.find(
      (product) => product.id === productInstance.product_id
    );
  }, [products, productInstance]);

  const guestTypes = React.useMemo(() => {
    if (!productInstance || !productSummary) {
      return [];
    }
    return getGuestTypesUsedInProductInstance(
      productInstance,
      productSummary,
      t
    );
  }, [productInstance, productSummary, t]);

  const totalCapacity = React.useMemo(() => {
    return selectedEquipmentBlockInstanceKeys.reduce((acc, key) => {
      const equipmentInstance = equipmentInstances.find(
        (equipmentInstance) => equipmentInstance.id === key.equipmentInstanceId
      );
      const equipment = equipments.find(
        (equipment) => equipment.id === equipmentInstance?.equipment_id
      );
      const capacity =
        equipment?.original_equipment_block_instance_properties?.find(
          (capacity) =>
            capacity.equipment_block_instance_key ===
            key.equipmentBlockInstanceKey
        )?.capacity;

      return acc + (capacity ?? 0);
    }, 0);
  }, [equipments, equipmentInstances, selectedEquipmentBlockInstanceKeys]);

  const consumedCapacity = React.useMemo(() => {
    return (newReservation.guests || []).length;
  }, [newReservation.guests]);

  const getNewReservationWithEquipmentAssignments = (
    newReservation: NewReservation,
    selectedEquipmentBlockInstanceKeys: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    }[],
    equipmentTicketPrinted?: boolean
  ): NewReservation => {
    const newEquipmentAssignments: ReservationEquipmentAssignment[] = [];

    let selectedEquipmentBlockInstanceKeysIndex = 0;

    newReservation.guests.forEach((guest) => {
      let selectedEquipmentBlockInstanceKey: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
        equipmentBlockReference?: string;
      } | null = null;
      let selectedEquipment: Equipment | undefined;
      let selectedEquipmentInstance: EquipmentInstance | undefined;

      while (
        selectedEquipmentBlockInstanceKeysIndex <
        selectedEquipmentBlockInstanceKeys.length
      ) {
        const key =
          selectedEquipmentBlockInstanceKeys[
            selectedEquipmentBlockInstanceKeysIndex
          ];
        selectedEquipmentBlockInstanceKeysIndex++;

        selectedEquipmentInstance = equipmentInstances.find(
          (equipmentInstance) =>
            equipmentInstance.id === key.equipmentInstanceId
        );
        selectedEquipment = equipments.find(
          (equipment) =>
            equipment.id === selectedEquipmentInstance?.equipment_id
        );

        const property = getEquipmentBlockInstanceProperty(
          key.equipmentBlockInstanceKey,
          selectedEquipment,
          selectedEquipmentInstance,
          activeUserOrganization
        );

        if ((property?.capacity ?? 0) > 0 && !property?.is_closed) {
          selectedEquipmentBlockInstanceKey = {
            ...key,
            equipmentBlockReference:
              property?.reference ||
              getEquipmentBlockReference(
                key.equipmentBlockInstanceKey,
                selectedEquipment
              ),
          };
          break;
        }
      }

      if (selectedEquipmentBlockInstanceKey) {
        newEquipmentAssignments.push({
          equipment_id: selectedEquipment?.id,
          start_time_key: selectedEquipmentInstance?.start_time_key,
          equipment_block_instance_key:
            selectedEquipmentBlockInstanceKey.equipmentBlockInstanceKey,
          equipment_instance_id:
            selectedEquipmentBlockInstanceKey.equipmentInstanceId,
          occupation: {
            guest_type_counts: [
              {
                guest_type_key: guest.guest_type_key,
                count: 1,
              },
            ],
          },
          equipment_block_reference:
            selectedEquipmentBlockInstanceKey.equipmentBlockReference,
        });
      }
    });

    const updatedNewReservation = {
      ...newReservation,
      equipment_assignments: newEquipmentAssignments,
      equipment_ticket_printed: equipmentTicketPrinted,
    };

    return updatedNewReservation;
  };

  const participationReservationFormFields = getDisplayReservationFormFields(
    product?.reservation_form_fields ?? [],
    t
  )
    .filter((f) =>
      [
        'given_name',
        'family_name',
        'kana_given_name',
        'kana_family_name',
        'email',
        'phone',
      ].includes(f.key ?? '')
    )
    .sort((_, b) => {
      // Order email at the end
      if (b.key === 'phone') {
        return -1;
      }
      return 0;
    });

  const requiredWhenBookingPerBookingFields = getDisplayReservationFormFields(
    product?.reservation_form_fields ?? [],
    t
  ).filter(
    (f) =>
      f.required === 'WHEN_BOOKING' &&
      f.type === 'PER_BOOKING' &&
      ![
        'given_name',
        'family_name',
        'kana_given_name',
        'kana_family_name',
        'email',
        'phone',
      ].includes(f.key ?? '')
  );

  const perParticipantFields = (product?.reservation_form_fields || [])
    .filter((f) => f.type === 'PER_PARTICIPANT')
    .map((f) => {
      // remove format validation when the organization type is supplier
      if (activeUser?.organization_type === 'SUPPLIER') {
        return { ...f, format: undefined };
      }

      return f;
    });

  const optionalPerBookingFields: Field[] = getDisplayReservationFormFields(
    product?.reservation_form_fields ?? [],
    t
  )
    .filter(
      (f) =>
        (f.required === 'BEFORE_PARTICIPATING' || f.required === 'OPTIONAL') &&
        f.type === 'PER_BOOKING' &&
        ![
          'given_name',
          'family_name',
          'kana_given_name',
          'kana_family_name',
          'email',
          'phone',
        ].includes(f.key ?? '')
    )
    .map((field) => ({
      ...field,
      required: 'OPTIONAL',
    }));

  const perParticipantAddOns = getPerParticipantAddOns(product?.add_ons ?? []);
  const perBookingAddOns = getPerBookingAddOns(product?.add_ons ?? []);

  const errorMap: Record<string, string> = {};

  return (
    <>
      <Modal title={t('Create Reservation')} open={open} onClose={onClose}>
        <Modal.Content>
          <Box mb={4}>
            <FieldWrapper label={t('Selected seats')}>
              <div className={clsx(componentStyles['c-table-nowrap'])}>
                <table>
                  <thead>
                    <tr>
                      <th>{t('Number of seat')}</th>
                      <th>{t('Assigned')}</th>
                      <th>{t('Remaining')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{totalCapacity}</td>
                      <td>{consumedCapacity}</td>
                      <td>{totalCapacity - consumedCapacity}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </FieldWrapper>
          </Box>

          <Box mb={4}>
            <Select
              label={t('Product')}
              options={productInstanceOptions}
              value={newReservation.product_instance_id || ''}
              onChange={(_, { value }) => {
                setNewReservation({
                  ...newReservation,
                  guests: [],
                  product_instance_id: value as string,
                });
              }}
            />
          </Box>

          <>
            <div className={clsx(styles['field'])}>
              <FieldsForm
                fields={participationReservationFormFields}
                getFieldValue={(key) => {
                  const r = (newReservation.field_responses || []).find(
                    (r) => r.key === key
                  );

                  if (!r) {
                    return '';
                  }

                  return r.response || '';
                }}
                errorMap={errorMap}
                onFieldChange={(key, value) =>
                  setNewReservation({
                    ...newReservation,
                    field_responses: [
                      ...(newReservation.field_responses || []).filter(
                        (r) => r.key !== key
                      ),
                      {
                        key,
                        response: value,
                      },
                    ],
                  })
                }
                mode="INPUT"
              />
              <FieldsForm
                fields={requiredWhenBookingPerBookingFields}
                getFieldValue={(key) => {
                  const r = (newReservation.field_responses || []).find(
                    (r) => r.key === key
                  );

                  if (!r) {
                    return '';
                  }

                  return r.response || '';
                }}
                errorMap={errorMap}
                onFieldChange={(key, value) =>
                  setNewReservation({
                    ...newReservation,
                    field_responses: [
                      ...(newReservation.field_responses || []).filter(
                        (r) => r.key !== key
                      ),
                      {
                        key,
                        response: value,
                      },
                    ],
                  })
                }
                showLeaveBlankBox={false}
                requiredFieldOptionalEntry={
                  activeUser?.organization_type === 'SUPPLIER'
                }
                getFieldLeaveBlankValue={(key) => {
                  const r = (newReservation.field_responses || []).find(
                    (r) => r.key === key
                  );

                  if (!r) {
                    return false;
                  }

                  return (r as any).leaveBlank || false;
                }}
                mode="INPUT"
              />
            </div>
          </>

          <Box mb={4}>
            <Select
              label={t('Booking Source')}
              options={[
                'DIRECT_WALK_IN',
                'DIRECT_TELEPHONE',
                'DIRECT_EMAIL',
                'AGENT',
                'OTHER',
              ].map((a, index) => ({
                text: t(a),
                value: a,
                key: index.toString(),
              }))}
              value={
                newReservation.booking_source?.source_type || 'DIRECT_WALK_IN'
              }
              onChange={(e, { value }) => {
                setNewReservation({
                  ...newReservation,
                  booking_source: {
                    source_type: value as BookingSourceType,
                  },
                });
              }}
            />
          </Box>

          {newReservation?.booking_source?.source_type === 'AGENT' && (
            <Box mb={4}>
              <Select
                label={t('Agent')}
                options={[
                  {
                    text: t('No selected'),
                    value: '',
                  },
                  ...(product?.agents?.map((a) => ({
                    text: a.name || '',
                    value: a.id || '',
                  })) ?? []),
                ]}
                value={newReservation.booking_source?.agent_id ?? ''}
                onChange={(e, { value }) => {
                  setNewReservation({
                    ...newReservation,
                    booking_source: {
                      ...newReservation.booking_source,
                      agent_id: value as string,
                    },
                  });
                }}
              />
            </Box>
          )}

          {guestTypes.length > 0 && (
            <Box mb={4}>
              <div>
                <FieldWrapper label={t('Guests')}>
                  {guestTypes.map((guestType: any, idx: number) => {
                    const cnt =
                      (newReservation.guests &&
                        newReservation.guests.filter(
                          (g) => g.guest_type_key === guestType.key
                        ).length) ||
                      0;

                    let availableCapacity = totalCapacity - consumedCapacity;
                    if (availableCapacity < 0) {
                      availableCapacity = 0;
                    }

                    return (
                      <Box display="flex" flexDirection="column" key={idx}>
                        {printGuestType(guestType, t)}
                        <PlusMinusNumberInputForm
                          value={cnt}
                          onChange={(value) => {
                            if (value < 0) {
                              return;
                            }
                            if (availableCapacity < value - cnt) {
                              return;
                            }
                            const existingGuests = newReservation.guests.filter(
                              (g) => g.guest_type_key !== guestType.key
                            );
                            const newGuests = [...Array(value)].map(() => {
                              return {
                                guest_type_key: guestType.key,
                              };
                            });

                            const updatedNewReservation = {
                              ...newReservation,
                              guests: [...existingGuests, ...newGuests],
                            };

                            setNewReservation(updatedNewReservation);
                          }}
                        />
                      </Box>
                    );
                  })}
                </FieldWrapper>
              </div>
            </Box>
          )}

          <FormTableBox>
            <table>
              <colgroup>
                <col />
                <col />
                {(perParticipantFields.length > 0 ||
                  perParticipantAddOns.length > 0) &&
                  newReservation.guests.length > 0 && (
                    <col className={styles['delete-cell']} />
                  )}
              </colgroup>
              <tbody>
                {(perBookingAddOns.length > 0 ||
                  optionalPerBookingFields.length > 0) && (
                  <tr>
                    <th>{t('Per-Booking')}</th>
                    <td
                      colSpan={
                        (perParticipantFields.length > 0 ||
                          perParticipantAddOns.length > 0) &&
                        newReservation.guests.length > 0
                          ? 2
                          : 1
                      }
                    >
                      {perBookingAddOns.length > 0 && (
                        <div className={clsx(styles['field'])}>
                          <MultiSelect
                            label={t('Add-ons')}
                            placeholder={t('Select add-ons...')}
                            options={perBookingAddOns.map((addOn) => {
                              const p =
                                addOn.pricing &&
                                addOn.pricing.find(
                                  (pr) => pr.method === 'PER_BOOKING'
                                );
                              const gross = (p && p.gross) || '';

                              const remaining =
                                initialAvailablePerBookingAddOns[
                                  addOn.key ?? ''
                                ];

                              const optionText =
                                remaining !== undefined
                                  ? t('[{{remaining}} left]', {
                                      remaining,
                                    })
                                  : '';

                              return {
                                value: addOn.key ?? '',
                                text:
                                  addOn.service_type === 'FREE'
                                    ? `${addOn.title} (${t('Free')})`
                                    : `${addOn.title} (${gross})`,
                                addition: optionText,
                              };
                            })}
                            selectedValues={newReservation.add_ons || []}
                            onChange={({
                              value: add_ons,
                            }: {
                              value: string[];
                            }) => {
                              setNewReservation({
                                ...newReservation,
                                add_ons,
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className={clsx(styles['field'])}>
                        <FieldsForm
                          fields={optionalPerBookingFields}
                          getFieldValue={(key) => {
                            const r = (
                              newReservation.field_responses || []
                            ).find((r) => r.key === key);

                            if (!r) {
                              return '';
                            }

                            return r.response || '';
                          }}
                          errorMap={errorMap}
                          onFieldChange={(key, value) =>
                            setNewReservation({
                              ...newReservation,
                              field_responses: [
                                ...(
                                  newReservation.field_responses || []
                                ).filter((r) => r.key !== key),
                                {
                                  key,
                                  response: value,
                                },
                              ],
                            })
                          }
                          mode="INPUT"
                        />
                      </div>
                    </td>
                  </tr>
                )}

                {(perParticipantFields.length > 0 ||
                  perParticipantAddOns.length > 0) &&
                  newReservation.guests.length > 0 &&
                  newReservation.guests.map((g, idx) => {
                    const addOnOptions = perParticipantAddOns
                      .filter(
                        (addOn) =>
                          addOn.pricing &&
                          addOn.pricing.find(
                            (p) =>
                              p.guest_type &&
                              p.guest_type.key === g.guest_type_key
                          )
                      )
                      .map((addOn) => {
                        const p =
                          addOn.pricing &&
                          addOn.pricing.find(
                            (p) =>
                              p.guest_type &&
                              p.guest_type.key === g.guest_type_key
                          );
                        const gross = (p && p.gross) || '';

                        const remaining =
                          availablePerParticipantAddOns[addOn.key ?? ''];

                        const optionText =
                          remaining !== undefined
                            ? t('[{{remaining}} left]', {
                                remaining,
                              })
                            : '';

                        return {
                          text: `${addOn.title} (${gross})`,
                          value: addOn.key ?? '',
                          addition: optionText,
                        };
                      });
                    // Initialize guest error map if entire form is showing 1 or more errors.
                    const guestErrorMap =
                      Object.keys(errorMap).length > 0
                        ? getFieldResponseErrors(
                            g.field_responses || [],
                            perParticipantFields,
                            t,

                            activeUser?.organization_type === 'SUPPLIER'
                          )
                        : {};
                    return (
                      <>
                        <tr>
                          <th>
                            {
                              (
                                guestTypes.find(
                                  (guestType) =>
                                    guestType &&
                                    guestType.key === g.guest_type_key
                                ) as any
                              )?.title
                            }
                          </th>
                          <td
                            className={clsx(
                              showParticipantDeletionErrorAlert &&
                                showParticipantDeletionErrorGuestTypeKey ===
                                  g.guest_type_key &&
                                styles['table-list-td__error']
                            )}
                          >
                            <div className={styles['table-list-td']}>
                              {addOnOptions.length > 0 && (
                                <div className={clsx(styles['field'])}>
                                  <MultiSelect
                                    label={t('Add-ons')}
                                    placeholder={t('Select add-ons...')}
                                    options={addOnOptions}
                                    selectedValues={g.add_ons || []}
                                    onChange={({
                                      value: add_ons,
                                    }: {
                                      value: string[];
                                    }) => {
                                      setNewReservation(() => ({
                                        ...newReservation,
                                        guests: newReservation.guests.map(
                                          (g2, idx2) =>
                                            idx === idx2
                                              ? {
                                                  ...g2,
                                                  add_ons,
                                                }
                                              : g2
                                        ),
                                      }));
                                    }}
                                  />
                                </div>
                              )}

                              <FieldsForm
                                fields={perParticipantFields}
                                errorMap={guestErrorMap}
                                getFieldValue={(key) => {
                                  const r = (g.field_responses || []).find(
                                    (r) => r.key === key
                                  );

                                  if (!r) {
                                    return '';
                                  }

                                  return r.response || '';
                                }}
                                onFieldChange={(key, value, leaveBlank) =>
                                  setNewReservation(() => ({
                                    ...newReservation,
                                    guests: newReservation.guests.map(
                                      (g2, idx2) =>
                                        idx === idx2
                                          ? {
                                              ...g2,
                                              field_responses: [
                                                ...(
                                                  g2.field_responses || []
                                                ).filter((r) => r.key !== key),
                                                {
                                                  key,
                                                  response: value,
                                                  leaveBlank,
                                                },
                                              ],
                                            }
                                          : g2
                                    ),
                                  }))
                                }
                                getFieldLeaveBlankValue={(key) => {
                                  const r = (g.field_responses || []).find(
                                    (r) => r.key === key
                                  );

                                  if (!r) {
                                    return '';
                                  }

                                  return (r as any).leaveBlank || false;
                                }}
                                showLeaveBlankBox={false}
                                requiredFieldOptionalEntry={
                                  activeUser?.organization_type === 'SUPPLIER'
                                }
                                mode="INPUT"
                              />
                            </div>
                          </td>
                          <td
                            className={clsx(
                              showParticipantDeletionErrorAlert &&
                                showParticipantDeletionErrorGuestTypeKey ===
                                  g.guest_type_key &&
                                styles['table-list-td__error']
                            )}
                          >
                            <div>
                              <DeleteIcon
                                onClick={() => {
                                  const guests = newReservation.guests.filter(
                                    (g, guestIdx) => idx !== guestIdx
                                  );
                                  setShowParticipantDeletionError(false);
                                  setShowParticipantDeletionErrorGuestTypeKey(
                                    ''
                                  );
                                  setNewReservation({
                                    ...newReservation,
                                    guests,
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </FormTableBox>

          <Box mt={4}>
            {newReservation.guests.length > 0 && (
              <FareSummary
                reservationParams={newReservation}
                isChangeReservation={false}
              />
            )}
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style="gray"
            size="middle"
            onClick={() => {
              onReset();
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            loading={reservationLoading}
            style="blue"
            size="middle"
            disabled={
              !newReservation ||
              newReservation.guests.length !==
                selectedEquipmentBlockInstanceKeys.length
            }
            onClick={async () => {
              try {
                const action: any = await dispatch(
                  createReservation(
                    getNewReservationWithEquipmentAssignments(
                      newReservation,
                      selectedEquipmentBlockInstanceKeys,
                      false
                    )
                  )
                );
                if (action.type === CREATE_RESERVATION_SUCCESS) {
                  onReset();
                  onClose();
                  return;
                }
                fetchEquipmentAssignments?.();
                setShowSeatSelectModal(true);
              } catch (e) {
                console.error(e);
                fetchEquipmentAssignments?.();
                setShowSeatSelectModal(true);
              }
            }}
          >
            {t('Create new reservation')}
          </Button>
          <Button
            loading={reservationLoading}
            style="blue"
            size="middle"
            disabled={
              !newReservation ||
              newReservation.guests.length !==
                selectedEquipmentBlockInstanceKeys.length
            }
            onClick={async () => {
              try {
                const action: any = await dispatch(
                  createReservation(
                    getNewReservationWithEquipmentAssignments(
                      newReservation,
                      selectedEquipmentBlockInstanceKeys,
                      true
                    )
                  )
                );

                if (action.type === CREATE_RESERVATION_SUCCESS) {
                  const reservation = action.response as Reservation;
                  printETicketByEpsonThermalPrinter(
                    toReservationSummaryShape(reservation, i18n.language, t),
                    networkPrinterIpAddress,
                    activeUserOrganization?.booking_widget_api_key || '',
                    getLogoBase64(activeUserOrganization?.id ?? ''),
                    (reservation.equipment_block_references ?? [])
                      .sort()
                      .join('/'),
                    reservation.equipment_instance_memo ?? ''
                  );
                  onReset();
                  onClose();
                  return;
                }

                fetchEquipmentAssignments?.();
                setShowSeatSelectModal(true);
              } catch (e) {
                console.error(e);
                fetchEquipmentAssignments?.();
                setShowSeatSelectModal(true);
              }
            }}
          >
            <img
              src={printerIcon}
              style={{ width: '30px', height: '30px' }}
              alt="printer"
            />
            {t('Create new reservation with ticket')}
          </Button>
        </Modal.Actions>
      </Modal>
      {showSeatSelectModal && (
        <SeatSelectModal
          open={showSeatSelectModal}
          onClose={() => setShowSeatSelectModal(false)}
          keepShowingEquipmentInstanceSelector={true}
          selectedEquipmentInstanceId={selectedEquipmentInstanceId}
        />
      )}
    </>
  );
};
