import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { setProductVisibleColumns } from 'client/actions/productTableControls';
import { Modal } from 'client/components/v3/Form/Modal';

type Props = {
  trigger?: React.ReactElement<any>;
  columnsToShow: any[];
  allCandidateColumns: any[];
  onClose: () => void;
};

export type ColumnType = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
};

export const ColumnSettingsModal = ({
  columnsToShow,
  allCandidateColumns,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal
      title={t('Edit Columns')}
      subtitle={t(
        'You can customize which columns to show and the display order.'
      )}
      open={true}
      onClose={onClose}
      useCloseButton={true}
      style={{ width: '600px', minHeight: '600px' }}
    >
      <ToggleableDragAndDropList
        selectedItems={columnsToShow.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        candidateItems={allCandidateColumns.map((col) => ({
          key: col.id,
          text: col.translatedColumnName || col.Header,
        }))}
        setSelectedItems={(items) =>
          dispatch(setProductVisibleColumns(items.map((i) => i.key)))
        }
        doNotUseOuterBox={true}
      />
    </Modal>
  );
};
