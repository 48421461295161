import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type Props = {
  name: string;
  options: {
    key: string;
    value: string;
    text: string;
  }[];
  label: string;
  values: string[];
};
export const ResourceList = ({ name, options, label, values }: Props) => {
  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return (
          <div className={styles['p-manifestsModalResources__item']}>
            <p className={styles['p-manifestsModalResources__item__ttl']}>
              {label}
            </p>
            <div className={styles['p-manifestsModalResources__item__body']}>
              {fields.map((name, idx) => {
                return (
                  <div key={`${name}-${idx}`}>
                    <Field name={`${name}`}>
                      {({ input }) => {
                        return (
                          <SingleDropdown
                            options={options.filter((option) => {
                              // Remove selected option
                              return !values.includes(option.value);
                            })}
                            selectedOption={
                              options.find((p) => p.value === input.value)
                                ?.text || ''
                            }
                            onChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Button
                      size="icon"
                      color="tertiarygray"
                      onClick={() => fields.remove(idx)}
                      iconBeforeText={
                        <i className="c-icon-outline-general-trash-03"></i>
                      }
                    />
                  </div>
                );
              })}
            </div>

            <a
              className={styles['p-manifestsModalResources__item__add']}
              onClick={() => {
                fields.push('');
              }}
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add')}
            </a>
          </div>
        );
      }}
    </FieldArray>
  );
};
