// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import type { Dispatch } from 'redux';

import { updateDispatchVehicles } from 'client/actions/dispatchSettings';
import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import type { DispatchResource } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type OwnProps = {
  vehicleToDelete: {
    ...DispatchResource,
  },
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};

/* eslint-enable no-use-before-define */
const DeleteVehicleModalComponent = ({
  allVehicles,
  vehicleToDelete,
  updateVehicles,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return vehicleToDelete ? (
    <Modal
      title={t('Delete Vehicle')}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: vehicleToDelete.key,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button.Delete
          onClick={() => {
            const newVehicles = allVehicles.filter(
              (vehicle) => vehicle.key !== vehicleToDelete.key
            );
            updateVehicles(newVehicles);
            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  allVehicles: allDispatchVehiclesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateVehicles: (vehicles: DispatchResource[]) =>
    dispatch(updateDispatchVehicles(vehicles)),
});

export const DeleteVehicleModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteVehicleModalComponent);
