import { TranslateFuncType } from 'client/components/Translate';

export const getSourceLanguageOptions = (
  t: TranslateFuncType
): {
  value: string;
  text: string;
}[] => {
  const options = [
    {
      value: 'EN_US',
      text: t('English'),
    },
    {
      value: 'JA_JP',
      text: t('Japanese'),
    },
    {
      value: 'KO_KR',
      text: t('Korean'),
    },
    {
      value: 'ZH_CN',
      text: t('Chinese (Simplified)'),
    },
    {
      value: 'ZH_TW',
      text: t('Chinese (Traditional)'),
    },
  ];

  return options;
};
