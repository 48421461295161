// @flow

import * as React from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  List,
  Message,
  Modal,
  Popup,
  Progress,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash/fp/compose';
import type { TranslatorProps } from 'react-i18next';

import {
  countFilteredFields,
  countUnansweredFields,
  getGuestTypesUsedInProductInstance,
} from 'client/libraries/util/util';
import { activeUserSelector } from 'client/reducers/user';
import { updateReservation } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { FieldsForm } from 'client/components/FieldsForm';
import { getFieldResponseErrors } from 'client/libraries/util/coreutil';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import type { ReduxState } from 'client/reducers';
import type {
  Guest,
  Reservation,
  ReservationPatch,
} from 'shared/models/swagger';

type OwnProps = {
  onUpdate?: (reservation: ReservationPatch) => void,
  reservationID: string,
  size?: string,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  newPerBookingResponses: {| key?: string, response?: string |}[],
  newGuests: Guest[],
  lastSubmissionValidationErrorMap: { [key: string]: string },
  agentNotes: string,
  supplierNotes: string,
};

class EditFieldResponseButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      newPerBookingResponses: [...(props.reservation.field_responses || [])],
      newGuests: [...props.reservation.guests],
      lastSubmissionValidationErrorMap: {},
      agentNotes: this.props.reservation.agent_notes || '',
      supplierNotes: this.props.reservation.supplier_notes || '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.reservation !== this.props.reservation) {
      this.setState({
        newPerBookingResponses: [
          ...(this.props.reservation.field_responses || []),
        ],
        newGuests: [...this.props.reservation.guests],
      });
    }
  }

  reservationFromState = (): Reservation => {
    return ({
      product: this.props.product,
      guests: this.state.newGuests,
      field_responses: this.state.newPerBookingResponses,
    }: any);
  };

  fetchProductAndProductInstance = () => {
    const { reservation, product, productInstance } = this.props;
    const productID = reservation && reservation.product_id;
    const productInstanceID = reservation && reservation.product_instance_id;

    if (productID && (!product || product.id !== productID)) {
      this.props.fetchProductByID(productID);
    }
    if (
      productInstanceID &&
      (!productInstance || productInstance.id !== productInstanceID)
    ) {
      this.props.fetchProductInstanceByID(productInstanceID);
    }
  };

  getFormInputErrors = () => {
    const { product, t } = this.props;
    const { newPerBookingResponses, newGuests } = this.state;

    let errorMap = {};

    const perBookingFields = (product.reservation_form_fields || []).filter(
      (f) => f.type === 'PER_BOOKING'
    );
    errorMap = {
      ...errorMap,
      ...getFieldResponseErrors(
        newPerBookingResponses || [],
        perBookingFields,
        t
      ),
    };

    const perParticipantFields = (product.reservation_form_fields || []).filter(
      (f) => f.type === 'PER_PARTICIPANT'
    );
    (newGuests || []).forEach((g) => {
      errorMap = {
        ...errorMap,
        ...getFieldResponseErrors(
          g.field_responses || [],
          perParticipantFields,
          t
        ),
      };
    });

    return errorMap;
  };

  getNotesInput = () => {
    const { activeUser, t } = this.props;
    if (activeUser.organization_type === 'AGENT') {
      return (
        <Form.Input
          control={TextArea}
          label={t('Remarks')}
          value={this.state.agentNotes}
          onChange={(e) => {
            this.setState({ agentNotes: e.target.value });
          }}
        />
      );
    } else if (activeUser.organization_type === 'SUPPLIER') {
      return (
        <Form.Input
          control={TextArea}
          label={t('Replies')}
          value={this.state.supplierNotes}
          onChange={(e) => {
            this.setState({ supplierNotes: e.target.value });
          }}
        />
      );
    }
  };

  render() {
    const {
      loading,
      onUpdate,
      product,
      productInstance,
      reservation,
      size,
      t,
    } = this.props;
    const {
      newGuests,
      newPerBookingResponses,
      lastSubmissionValidationErrorMap,
      agentNotes,
      supplierNotes,
    } = this.state;

    const currentValidationErrorMap = this.getFormInputErrors();
    const errorMap = {};

    // Set keys for all fields that were invalid in the last submission.
    Object.keys(lastSubmissionValidationErrorMap).forEach((errKey) => {
      errorMap[errKey] = currentValidationErrorMap[errKey];
    });

    const errors = Object.values(lastSubmissionValidationErrorMap);

    const perParticipantFields = (product.reservation_form_fields || []).filter(
      (f) => f.type === 'PER_PARTICIPANT'
    );

    const allGuestTypes = getGuestTypesUsedInProductInstance(
      productInstance,
      product,
      t
    );

    return (
      <Modal
        trigger={
          <Icon.Group size={size}>
            <Icon
              link
              color="orange"
              name="edit"
              onClick={() => {
                this.setState({
                  showModal: true,
                });
              }}
            />
            {countUnansweredFields(
              reservation,
              product,
              (f) => f.required !== 'OPTIONAL'
            ) > 0 && (
              <Popup
                trigger={<Icon name="warning circle" corner color="red" />}
                content={t(
                  '{{count}} fields need responses before participating',
                  {
                    count: countUnansweredFields(
                      reservation,
                      product,
                      (f) => f.required !== 'OPTIONAL'
                    ),
                  }
                )}
                position="right center"
              />
            )}
          </Icon.Group>
        }
        open={this.state.showModal}
        onClose={() => {
          this.setState({
            showModal: false,
          });
        }}
        onOpen={this.fetchProductAndProductInstance}
        style={{
          marginTop: 0,
        }}
      >
        <Header icon="edit" />
        <Modal.Content>
          <Modal.Description>
            {loading || !product || !productInstance ? (
              <ModalLoader />
            ) : (
              <>
                <Progress
                  value={
                    countFilteredFields(
                      this.reservationFromState(),
                      product,
                      (f) => f.required !== 'OPTIONAL'
                    ) -
                    countUnansweredFields(
                      this.reservationFromState(),
                      product,
                      (f) => f.required !== 'OPTIONAL'
                    )
                  }
                  total={countFilteredFields(
                    this.reservationFromState(),
                    product,
                    (f) => f.required !== 'OPTIONAL'
                  )}
                  progress="ratio"
                  indicating
                />
                <Form error={errors.length > 0}>
                  <Message
                    error
                    header={t('Error')}
                    content={
                      <List as="ul">
                        {errors.map((err) => (
                          <List.Item as="li">{err}</List.Item>
                        ))}
                      </List>
                    }
                  />
                  {countFilteredFields(
                    this.reservationFromState(),
                    product,
                    (f) => f.type === 'PER_BOOKING'
                  ) > 0 && (
                    <FieldsForm
                      fields={(product.reservation_form_fields || []).filter(
                        (f) => f.type === 'PER_BOOKING'
                      )}
                      errorMap={errorMap}
                      getFieldValue={(key) => {
                        const r = newPerBookingResponses.find(
                          (r) => r.key === key
                        );

                        return (r && r.response) || '';
                      }}
                      onFieldChange={(key, value) =>
                        this.setState((prevState) => ({
                          newPerBookingResponses: [
                            ...prevState.newPerBookingResponses.filter(
                              (r) => r.key !== key
                            ),
                            {
                              key,
                              response: value,
                            },
                          ],
                        }))
                      }
                      mode="INPUT"
                      type="EDIT"
                    />
                  )}
                  {countFilteredFields(
                    this.reservationFromState(),
                    product,
                    (f) => f.type === 'PER_PARTICIPANT'
                  ) > 0 &&
                    newGuests.map((g, idx) => {
                      // Initialize guest error map if entire form is showing 1 or more errors.
                      const guestErrorMap =
                        errors.length > 0
                          ? getFieldResponseErrors(
                              g.field_responses || [],
                              perParticipantFields,
                              t
                            )
                          : {};

                      let guestTypeTitle = g.guest_type_title;
                      if (!guestTypeTitle) {
                        const matchingGuestType = allGuestTypes.find(
                          (guestType) => guestType.key === g.guest_type_key
                        );
                        if (matchingGuestType) {
                          guestTypeTitle = matchingGuestType.title;
                        } else {
                          guestTypeTitle = g.guest_type_key;
                        }
                      }

                      return (
                        <Segment key={idx}>
                          <Header as="h3">{guestTypeTitle}</Header>
                          <FieldsForm
                            errorMap={guestErrorMap}
                            fields={(
                              product.reservation_form_fields || []
                            ).filter((f) => f.type === 'PER_PARTICIPANT')}
                            getFieldValue={(key) => {
                              const r = (g.field_responses || []).find(
                                (r) => r.key === key
                              );
                              if (!r) {
                                return '';
                              }

                              return r.response || '';
                            }}
                            onFieldChange={(key, value) =>
                              this.setState((prevState) => ({
                                newGuests: prevState.newGuests.map((g2, idx2) =>
                                  idx === idx2
                                    ? {
                                        ...g2,
                                        field_responses: [
                                          ...(g2.field_responses || []).filter(
                                            (r) => r.key !== key
                                          ),
                                          {
                                            key,
                                            response: value,
                                          },
                                        ],
                                      }
                                    : g2
                                ),
                              }))
                            }
                            mode="INPUT"
                          />
                        </Segment>
                      );
                    })}
                </Form>
              </>
            )}
            <Divider section />
            <ReservationActorInputForm />
            <br />
            <Form>{this.getNotesInput()}</Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                newPerBookingResponses: [
                  ...(reservation.field_responses || []),
                ],
                newGuests: [...reservation.guests],
              });
            }}
          >
            {t('Clear Changes')}
          </Button>
          <Button
            primary
            onClick={() => {
              if (Object.keys(currentValidationErrorMap).length > 0) {
                window.scrollTo(0, 0);
                this.setState({
                  lastSubmissionValidationErrorMap: currentValidationErrorMap,
                });
              } else {
                this.setState(() => {
                  // Why do we need to put this inside the callback?
                  // Calling setState() and updateReservation() consecutively
                  // prevented the setState() from happening.
                  this.props
                    .updateReservation(reservation.id, {
                      field_responses: [...newPerBookingResponses],
                      guests: [...(newGuests: any)],
                      agent_notes: agentNotes,
                      supplier_notes: supplierNotes,
                    })
                    .then((res) => onUpdate && onUpdate(res));

                  return {
                    showModal: false,
                  };
                });
              }
            }}
          >
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];

  return {
    loading: state.products.loading || state.productInstances.loading,
    reservation,
    product: state.products.byID[reservation?.product_id || ''],
    productInstance:
      state.productInstances.byID[
        reservation && reservation.product_instance_id
      ],
    activeUser: activeUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateReservation: (id, r) => dispatch(updateReservation(id, r)),
  fetchProductByID: (id) => dispatch(fetchProductByID(id)),
  fetchProductInstanceByID: (id) => dispatch(fetchProductInstanceByID(id)),
});

export const EditFieldResponseButton = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(EditFieldResponseButtonComponent);
