import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

import { InstantWinDrawTriggerFormValues } from './formValues';
import { EditInstantWinDrawTriggerModal } from './EditInstantWinDrawTriggerModal';
import { getDrawTriggerTypeOptions } from './getDrawTriggerTypeOptions';

export const InstantWinDrawTriggerListEditor = () => {
  const { t } = useTranslation();

  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);

  const getTitle = (drawTrigger: InstantWinDrawTriggerFormValues) => {
    return (
      getDrawTriggerTypeOptions(t).find(
        (o) => o.value === drawTrigger.triggerType
      )?.text ?? ''
    );
  };

  return (
    <>
      <Box mt={2}>
        <FieldWrapper label={t('Trigger Type')}>
          <Field name="drawTriggers">
            {({ input }) => (
              <>
                <Button
                  size="middle"
                  style="green"
                  onClick={() => setEditingIdx(input.value.length)}
                >
                  {t('Create New Instant Win Draw Trigger')}
                </Button>
                <EditDeleteButtonDndList
                  items={input.value?.map(
                    (drawTrigger: InstantWinDrawTriggerFormValues) => ({
                      key: drawTrigger.key,
                      text: getTitle(drawTrigger),
                    })
                  )}
                  setItems={(drawTriggers) => {
                    input.onChange(
                      drawTriggers.map((drawTrigger) =>
                        input.value?.find(
                          (trigger: InstantWinDrawTriggerFormValues) =>
                            trigger.key === drawTrigger.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setEditingIdx(idx);
                  }}
                />

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete guidance item')}
                    content={t(
                      'Are you sure you want to delete guidance item?'
                    )}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {editingIdx != null && (
                  <EditInstantWinDrawTriggerModal
                    existingInstantWinDrawTrigger={
                      editingIdx >= (input.value ?? []).length
                        ? undefined
                        : input.value[editingIdx]
                    }
                    onSave={(drawTrigger: InstantWinDrawTriggerFormValues) => {
                      if (editingIdx >= (input.value ?? []).length) {
                        input.onChange([...input.value, drawTrigger]);
                      } else {
                        input.onChange(
                          input.value.map(
                            (existingInstantWinDrawTrigger: any, idx: number) =>
                              idx === editingIdx
                                ? drawTrigger
                                : existingInstantWinDrawTrigger
                          )
                        );
                      }
                    }}
                    open={editingIdx != null}
                    onClose={() => setEditingIdx(null)}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>
      </Box>
    </>
  );
};
