import * as React from 'react';

import styles from 'client/base.module.css';

type Props = {
  onChange: (
    arg0: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  checked: boolean;
};

export const Checkbox = ({ onChange, checked }: Props) => {
  return (
    <div className={styles['base-form-checkbox']}>
      <label style={{ minHeight: 0, padding: 0 }}>
        <input
          onChange={(e) =>
            onChange(e, {
              value: e.target.dataset.value ?? '',
            })
          }
          type="checkbox"
          checked={checked}
        />
        <p style={{ margin: 0 }}></p>
      </label>
    </div>
  );
};
