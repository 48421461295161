import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { PromotionShape } from 'client/libraries/util/promotionShape';
import { Button } from 'client/components/v3/Common/Button';
import { PromotionSettingsModal } from 'client/pages/v3/Product/ProductPromotions/PromotionSettingsModal';

import styles from './OperateButton.module.css';

interface Props {
  inputPromotion: PromotionShape | null;
  tableRef: RefObject<HTMLDivElement>;
}

export const OperateButton = ({ inputPromotion, tableRef }: Props) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [isMenuBottomPartlyHidden, setIsMenuBottomPartlyHidden] =
    useState<boolean>(false);
  const [promo, setPromo] = useState<PromotionShape | null>(inputPromotion);

  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);

  // Check if menu is hidden by table overflow or not
  useEffect(() => {
    if (menuRef.current && tableRef.current) {
      const dropdownRect = menuRef.current.getBoundingClientRect();
      const tableRect = tableRef.current.getBoundingClientRect();

      // Check if the dropdown's bottom edge is below the table's bottom edge
      if (dropdownRect.bottom > tableRect.bottom) {
        setIsMenuBottomPartlyHidden(true);
      } else {
        setIsMenuBottomPartlyHidden(false);
      }
    }
  }, [showMenu]);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
      <div className={styles['operate-button']}>
        <Button
          uiType="bg"
          size="icon"
          color="white"
          iconBeforeText={
            <i className="c-icon-solid-general-dots-vertical"></i>
          }
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      <ul
        className={clsx(
          styles['p-primary__select__menu'],
          showMenu && styles['is-active'],
          showMenu && isMenuBottomPartlyHidden && styles['open-above']
        )}
        ref={menuRef}
      >
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <div>
            <i className="c-icon-outline-general-edit-05"></i>
            <div>{t('Edit Promotion')}</div>
          </div>
        </li>
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={() => {
            setPromo({ ...promo, id: '' } as PromotionShape);
            setShowModal(true);
          }}
        >
          <div>
            <i className="c-icon-outline-general-copy-01"></i>
            <div>{t('Copy Promotion')}</div>
          </div>
        </li>
      </ul>
      {showModal && (
        <PromotionSettingsModal
          open={showModal}
          setShowModal={(show: boolean) => {
            setShowModal(show);
            setShowMenu(false);
          }}
          inputPromotion={promo}
        />
      )}
    </fieldset>
  );
};
