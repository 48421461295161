import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from 'client/hooks/useWindowDimensions';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './GenericTable.module.css';

export interface ColumnType<T> {
  Header: string;
  id: string;
  accessor: (arg: T) => any;
  th?: boolean;
  sub?: boolean;
  width?: number;
  icon?: string;
  loading?: boolean;
}

interface Props<T> {
  items: T[];
  columns: ColumnType<T>[];
  rowCount: number;
  currentPage: number;
  onRowCountChange: (rowCount: number) => void;
  onCurrentPageChange: (page: number) => void;
  totalCount: number;
  hideScrollButtons?: boolean;
  hidePagination?: boolean;
  headerBar?: React.ReactNode;
  loading?: boolean;
}

export const GenericTable = <T,>({
  items,
  columns,
  rowCount,
  currentPage,
  onRowCountChange,
  onCurrentPageChange,
  totalCount,
  hideScrollButtons,
  hidePagination,
  headerBar,
  loading,
}: Props<T>) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);

  const isMobileView = width < 786;

  const pageCount =
    Math.floor(totalCount / rowCount) + (totalCount % rowCount ? 1 : 0);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      onCurrentPageChange(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      onCurrentPageChange(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <section className={styles['g-section']}>
        <div className={styles['p-reservations']}>
          <div className={styles['p-reservations__header']}>{headerBar}</div>
          <Loading size="md" />
        </div>
      </section>
    );
  }

  if (!totalCount) {
    return (
      <section className={styles['g-section']}>
        <div className={styles['p-reservations']}>
          <div className={styles['p-reservations__header']}>{headerBar}</div>
          <div className={styles['p-reservations__body']}>
            <p className={styles['p-reservations__empty']}>
              {t(
                'There are no items that match the specified search conditions'
              )}
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={styles['g-section']}>
      <div className={clsx(styles['p-reservations'])}>
        {headerBar && (
          <div className={styles['p-reservations__header']}>{headerBar}</div>
        )}
        <div className={styles['p-reservations__body']}>
          <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
            {/* Table header */}
            <div
              className={clsx(
                styles['c-table'],
                styles['thead'],
                styles['sticky-top']
              )}
              style={{
                borderTopLeftRadius: headerBar ? '0' : '16px',
                borderTopRightRadius: headerBar ? '0' : '16px',
              }}
              ref={tableHeaderRef}
            >
              <table className={styles['c-table__body']}>
                <thead>
                  <tr>
                    {columns.map((column: ColumnType<T>, idx) => {
                      const width = column.width || '';
                      return (
                        <th
                          className={clsx(
                            styles['thead__th'],
                            hideScrollButtons && styles['c-noScrollButtons']
                          )}
                          style={
                            isMobileView || !width
                              ? { borderTop: headerBar ? undefined : 'none' }
                              : {
                                  width: `${width}px`,
                                  borderTop: headerBar ? undefined : 'none',
                                }
                          }
                          key={idx}
                        >
                          {column.Header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table body */}
            <div
              className={clsx(styles['c-table'], styles['tbody'])}
              onScroll={scrollHandler}
              ref={tableBodyRef}
            >
              <table className={styles['c-table__body']}>
                <tbody>
                  {items.map((item, ridx) => {
                    return (
                      <tr key={ridx}>
                        {columns.map((column, idx) => {
                          const width = column.width || '';
                          const displayValue = column.accessor(item);

                          if (column.th) {
                            return (
                              <th
                                key={idx}
                                style={
                                  isMobileView ? {} : { width: `${width}px` }
                                }
                                className={clsx(
                                  styles['tbody__td'],
                                  styles['sticky-left'],
                                  styles['c-table__spHeader'],
                                  hideScrollButtons &&
                                    styles['c-noScrollButtons']
                                )}
                              >
                                {displayValue}
                              </th>
                            );
                          } else {
                            return (
                              <td
                                key={idx}
                                style={
                                  isMobileView ? {} : { width: `${width}px` }
                                }
                                className={clsx(
                                  styles['tbody__td'],
                                  hideScrollButtons &&
                                    styles['c-noScrollButtons']
                                )}
                                data-text={column.Header}
                              >
                                {displayValue}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {!hideScrollButtons && (
              <>
                <div
                  className={clsx(styles['c-tableBtn'], styles['left'])}
                  onClick={() => {
                    scrollButtonClickHandler('prev');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
                <div
                  className={clsx(styles['c-tableBtn'], styles['right'])}
                  onClick={() => {
                    scrollButtonClickHandler('next');
                  }}
                >
                  <a>
                    <i className="c-icon-solid-arrows-chevron-left"></i>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>

        {!hidePagination && (
          <div className={styles['p-reservations__bottom']}>
            <Pagination
              totalItems={totalCount}
              currentPage={currentPage}
              pageCount={pageCount}
              selectedNumberOfLinesToDisplay={rowCount}
              onChangeNumberOfLinesToDisplay={onRowCountChange}
              onClickPrevButton={pageBackClickHandler}
              onClickNextButton={pageForwardClickHandler}
            />
          </div>
        )}
      </div>
    </section>
  );
};
