export const formattedCurrencyAmount = (currencyAmount: string) => {
  if (!currencyAmount) {
    return '';
  }

  let flipSign = false;

  if (currencyAmount[0] === '-') {
    currencyAmount = currencyAmount.slice(1);
    flipSign = true;
  }

  const currencySymbol = currencyAmount.slice(0, 3);
  const amount = currencyAmount.slice(3);
  const amountFloat = flipSign ? -parseFloat(amount) : parseFloat(amount);

  return amountFloat.toLocaleString(undefined, {
    style: 'currency',
    currency: currencySymbol,
    currencyDisplay: 'code',
  });
};
