import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';

import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from './ProductGuestMyPageSettingsFormValues';
import { ProductGuestMyPageSettingsForm } from './ProductGuestMyPageSettingsForm';

type Props = {
  onClose: () => void;
};
export const ProductGuestMyPageSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('My Page for Guest')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <ProductGuestMyPageSettingsForm />
    </ProductEditorModal>
  );
};
