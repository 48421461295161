import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Button } from 'client/components/v3/Common/Button';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { hasSubscription } from 'client/libraries/util/subscriptions';

export const ManifestDailyHeader = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const checkinUrl = `${getBookingWidgetApiKeyUrl(
    activeUserOrganization?.booking_widget_api_key || ''
  )}/qrreader`;

  return (
    <PageHeader title={t('Daily manifest')}>
      <>
        {hasSubscription(activeUserOrganization, 'feature-qr-checkin') && (
          <>
            <Link to="/qrreader" target="_blank">
              <Button
                text={t('Go To QR Reader')}
                uiType="bg"
                size="md"
                color="white"
                iconAfterText={
                  <i className="c-icon-outline-general-link-external-02"></i>
                }
              />
            </Link>
            <a href={checkinUrl} target="_blank" rel="noreferrer">
              <Button
                text={t('Go To Self Checkin Page')}
                uiType="bg"
                size="md"
                color="white"
                iconAfterText={
                  <i className="c-icon-outline-general-link-external-02"></i>
                }
              />
            </a>
          </>
        )}
      </>
    </PageHeader>
  );
};
