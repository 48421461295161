import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { FieldWrapper } from 'client/components/Form';
import { DraggableList } from 'client/pages/v3/Settings/component/DraggableList/DraggableList';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Button } from 'client/components/v3/Common/Button';
import { AccountReservationSearchPreset } from 'shared/models/swagger';
import styles from 'client/pages/v3/Settings/Settings.module.css';
import { ReservationColumnSettingModal } from 'client/pages/v3/Settings/component/ReservationColumnSettingModal';
import { ReservationSearchPresetEditModal } from 'client/pages/v3/Settings/component/ReservationSearchPresetEditModal/ReservationSearchPresetEditModal';

import { Preset } from './ReservationSearchSettingsFormValues';

interface Props {
  submitting: boolean;
  onSubmit: () => any;
  submitSucceeded: boolean;
}

export const ReservationSearchSettingsForm = ({
  submitting,
  onSubmit,
  submitSucceeded,
}: Props) => {
  const { t } = useTranslation();
  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);
  const [showDefaultColumnModal, setShowDefaultColumnModal] =
    React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const allColumns = getReservationTableColumns(t, 'en').filter((col) => {
    return col.id !== 'edit';
  });

  const getColumns = (columnMask: string[]): any[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c));
  };

  const defaultColumns =
    activeUserOrganization?.account_reservation_search_settings
      ?.default_columns || [];

  const form = useForm();
  const { modified } = form.getState();

  React.useEffect(() => {
    if (submitSucceeded) {
      setShowDefaultColumnModal(false);
      setEditingIdx(null);
    }
  }, [submitSucceeded]);

  return (
    <>
      <Box mt={2}>
        <FieldWrapper
          label={
            <Box display="flex" alignItems="center">
              {t('Preset search condition')}
              <div style={{ marginLeft: '5px' }}>
                <Tooltip
                  text={t(
                    'You will be able to select preset search conditions on the reservation list to provide quick access for frequently used search conditions.'
                  )}
                  width={200}
                >
                  <i className="c-icon-outline-general-info-circle"></i>
                </Tooltip>
              </div>
            </Box>
          }
        >
          <Field name="presets">
            {({ input }) => (
              <>
                <DraggableList
                  options={input.value?.map(
                    (item: AccountReservationSearchPreset) => ({
                      key: item.key,
                      text: item.title,
                    })
                  )}
                  moveItem={(items) => {
                    input.onChange(
                      items.map((item) =>
                        input.value?.find(
                          (preset: AccountReservationSearchPreset) =>
                            preset.key === item.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setEditingIdx(idx);
                  }}
                />

                <Box display="flex" mt={2}>
                  <Box mr={2}>
                    <a
                      className={styles['add__button']}
                      onClick={() => setEditingIdx(input.value.length)}
                    >
                      <i className="c-icon-outline-general-plus-circle"></i>
                      {t('Add new search condition')}
                    </a>
                  </Box>

                  <Button
                    disabled={!modified?.presets || submitting}
                    size="lg"
                    color="primary"
                    type="submit"
                    loading={submitting}
                    text={t('Save Order')}
                  />
                </Box>

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete search condition')}
                    content={t('Are you sure you want to delete "{{name}}"?', {
                      name: input.value[deletingIdx]?.title,
                    })}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                      onSubmit();
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {editingIdx != null && (
                  <ReservationSearchPresetEditModal
                    existingItem={
                      editingIdx >= (input.value ?? []).length
                        ? undefined
                        : input.value[editingIdx]
                    }
                    onSave={(item: Preset) => {
                      if (editingIdx >= (input.value ?? []).length) {
                        input.onChange([...input.value, item]);
                      } else {
                        input.onChange(
                          input.value.map((existingItem: any, idx: number) =>
                            idx === editingIdx ? item : existingItem
                          )
                        );
                      }
                      onSubmit();
                    }}
                    open={editingIdx != null}
                    onClose={() => setEditingIdx(null)}
                    submitting={submitting}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>

        <Box mt={2} mb={2}>
          <FieldWrapper
            label={
              <Box display="flex" alignItems="center">
                {t('preset.Default_columns')}
                <div style={{ marginLeft: '5px' }}>
                  <Tooltip
                    text={t(
                      'Select columns to display on reservation list after login.'
                    )}
                    width={200}
                  >
                    <i className="c-icon-outline-general-info-circle"></i>
                  </Tooltip>
                </div>
              </Box>
            }
          >
            <div className={styles['p-topPage-box']}>
              <div className={styles['p-topPage-sortList__item']}>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getColumns(defaultColumns.map((d) => d.toLowerCase()))
                    .map((col) => col?.Header)
                    .join(', ')}
                </div>
                <Button
                  color="white"
                  size="sm"
                  iconAfterText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                  onClick={() => setShowDefaultColumnModal(true)}
                />
              </div>
            </div>
            <ReservationColumnSettingModal
              submitting={submitting}
              open={showDefaultColumnModal}
              setShowModal={setShowDefaultColumnModal}
              onSubmit={() => {
                onSubmit();
              }}
            />
          </FieldWrapper>
        </Box>
      </Box>
    </>
  );
};
