import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

type Props = {
  message: string;
  title: string;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  insertRoot?: boolean;
  loading?: boolean;
};
export const MessageModal = ({
  message,
  title,
  open,
  onOpen,
  onClose,
  onSubmit,
  insertRoot,
  loading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      onOpen={() => {
        onOpen && onOpen();
      }}
      insertRoot={insertRoot}
    >
      <Modal.Content>
        <Modal.Box>{message}</Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button
          size="middle"
          style="blue"
          onClick={() => {
            onSubmit && onSubmit();
          }}
          loading={loading}
        >
          {t('Yes')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
