import type { ReservationPatch } from 'shared/models/swagger';

export type ResourceType = '' | 'vehicle' | 'crew' | 'other' | 'guide';
export const getAssignedResources = <
  T extends
    | {
        dispatch_crew: string[];
        dispatch_vehicles: string[];
        dispatch_misc_resources: string[];
        dispatch_guides: string[];
      }
    | {
        dispatch_crew?: string[];
        dispatch_vehicles?: string[];
        dispatch_misc_resources?: string[];
        dispatch_guides?: string[];
      }
>(
  reservation: T,
  resourceType: ResourceType
): string[] => {
  switch (resourceType) {
    case 'crew':
      return reservation.dispatch_crew || [];

    case 'vehicle':
      return reservation.dispatch_vehicles || [];

    case 'other':
      return reservation.dispatch_misc_resources || [];

    case 'guide':
      return reservation.dispatch_guides || [];

    default:
      return [];
  }
};
export const buildPatch = (
  resourceType: ResourceType,
  resources: string[]
): ReservationPatch => {
  switch (resourceType) {
    case 'crew':
      return {
        dispatch_crew: resources,
      };

    case 'vehicle':
      return {
        dispatch_vehicles: resources,
      };

    case 'other':
      return {
        dispatch_misc_resources: resources,
      };

    case 'guide':
      return {
        dispatch_guides: resources,
      };

    default:
      // Dummy patch
      return {
        agent_notes: '',
      };
  }
};
