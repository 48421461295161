// @flow

import * as React from 'react';
import { Button, Divider, Modal, Form, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { cancelReservation } from 'client/actions/reservations';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';

type OwnProps = {
  text: string,
  onClose?: () => void,
  open: boolean,
  reservationID: string,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  open: boolean,
  agent_notes: string,
};

class CancelModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: this.props.open,
      agent_notes: '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  handleAgentNotesChange = (e) => {
    this.setState({ agent_notes: e.target.value });
  };

  render() {
    const { onClose, open, reservationID, t, text } = this.props;

    return (
      <Modal
        open={open}
        onClose={() => onClose && onClose()}
        style={{
          marginTop: 0,
        }}
      >
        <Modal.Header>{t('Cancel')}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {text}
            <Divider section />
            <ReservationActorInputForm />
            <Form>
              <Form.Input
                control={TextArea}
                label={t('Remarks')}
                value={this.state.agent_notes}
                onChange={this.handleAgentNotesChange}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({
                open: false,
              });
            }}
          >
            {t('No')}
          </Button>
          <Button
            primary
            onClick={() => {
              this.props.cancelReservation(
                reservationID,
                this.state.agent_notes
              );
              this.setState({
                open: false,
              });
            }}
          >
            {t('Yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  cancelReservation: (id, agent_notes) =>
    dispatch(cancelReservation(id, agent_notes)),
});

export const CancelModal = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(CancelModalComponent);
