import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/v3/Form/Modal';
import { ReservationGuestTypeRedemptionTable } from 'client/pages/v3/Manifest/ManifestDaily/Modal/Checkin/ReservationGuestTypeRedemptionTable';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { Product, ProductSummary, Reservation } from 'shared/models/swagger';

interface Props {
  trigger: React.ReactElement<any>;
  reservation: Reservation | ManifestReservationShape | undefined;
  product: Product | ProductSummary | undefined;
}

export const ReservationGuestTypeRedemptionModal = ({
  trigger,
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setShowModal(true),
      })}
      <Modal
        title={t('Checkin')}
        open={showModal}
        onClose={() => setShowModal(false)}
        style={{ width: '800px' }}
        insertAtRoot={true}
      >
        <>
          <ReservationGuestTypeRedemptionTable
            reservation={reservation}
            product={product}
          />
        </>
      </Modal>
    </>
  );
};
