// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateReservation } from 'client/actions/reservations';
import type { Reservation, Account } from 'shared/models/swagger';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { Modal } from 'client/components/Modal/Modal';
import { Button, TextArea } from 'client/components/Form';

type Props = {
  reservation: Reservation,
  activeUser: Account | null,
  readOnly?: boolean,
  trigger: React.Element<'a'>,
};

export const ReservationSupplementaryInformationUpdateModal = ({
  reservation,
  activeUser,
  readOnly,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [patch, setPatch] = React.useState({
    agent_notes: reservation.agent_notes,
    supplier_notes: reservation.supplier_notes,
    supplier_internal_notes: reservation.supplier_internal_notes,
    supplier_internal_notes_for_dispatch:
      reservation.supplier_internal_notes_for_dispatch,
  });

  React.useEffect(() => {
    setPatch({
      agent_notes: reservation.agent_notes,
      supplier_notes: reservation.supplier_notes,
      supplier_internal_notes: reservation.supplier_internal_notes,
      supplier_internal_notes_for_dispatch:
        reservation.supplier_internal_notes_for_dispatch,
    });
  }, [reservation]);

  const currentStatus = getCurrentStatus(reservation);

  const userCanEditAgentNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditSupplierNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationSupplierNotes');
  return (
    <Modal
      title={t('Update')}
      trigger={trigger}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
    >
      <Modal.Content>
        {userCanEditAgentNotes && (
          <Modal.Box>
            <TextArea
              label={t('Remarks')}
              value={patch['agent_notes']}
              onChange={(event) => {
                setPatch({
                  ...patch,
                  agent_notes: event.target.value,
                });
              }}
            />
          </Modal.Box>
        )}

        {userCanEditSupplierNotes && (
          <>
            <Modal.Box>
              <TextArea
                label={t('Replies')}
                value={patch['supplier_notes']}
                onChange={(event) => {
                  setPatch({
                    ...patch,
                    supplier_notes: event.target.value,
                  });
                }}
              />
            </Modal.Box>

            <Modal.Box>
              <TextArea
                label={t('Memo')}
                value={patch['supplier_internal_notes']}
                onChange={(event) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes: event.target.value,
                  });
                }}
              />
            </Modal.Box>

            <Modal.Box>
              <TextArea
                label={t('Internal Note')}
                value={patch['supplier_internal_notes_for_dispatch']}
                onChange={(event) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes_for_dispatch: event.target.value,
                  });
                }}
              />
            </Modal.Box>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          primary
          onClick={() => {
            dispatch(updateReservation(reservation.id, patch));
            setShowModal(false);
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
