import * as React from 'react';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, TextArea } from 'client/components/Form';
import { updateCustomer } from 'client/actions/customers';
import { getArrayMutators } from 'client/libraries/util/form';

import { CustomerContext } from './CustomerContext';

interface Props {
  index: number;
  staffMemo: {
    dateTime: Moment;
    body: string;
  } | null;
  onClose: () => void;
}

interface FormValues {
  body: string;
}

export const EditStaffMemoModal = ({ index, staffMemo, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = React.useContext(CustomerContext);
  return (
    <Modal
      insertRoot
      title={t('Edit Staff Memo')}
      open={true}
      onClose={onClose}
    >
      <Form
        initialValues={{ body: staffMemo?.body ?? '' }}
        onSubmit={async (values: FormValues) => {
          const newStaffMemos = [...(customer?.staff_memos ?? [])];

          if (staffMemo) {
            newStaffMemos[index] = {
              ...newStaffMemos[index],
              body: values.body,
            };
          } else {
            newStaffMemos.push({
              date_time_utc: moment().utc().format(),
              body: values.body,
            });
          }

          await dispatch(
            updateCustomer(customer?.id ?? '', {
              ...customer,
              staff_memos: newStaffMemos,
            })
          );
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Field name={'body'}>
                {({ input, meta: { touched, error } }) => (
                  <TextArea
                    value={input.value}
                    onChange={(_: any, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </Modal.Content>
            <Modal.Actions>
              <Button style="gray" size="middle" onClick={() => form.reset()}>
                {t('Discard')}
              </Button>
              <Button
                style="blue"
                size="middle"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
