import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, useFormState } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { customPagesSelector } from 'client/reducers/customPages';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  Button,
  FieldWrapper,
  FormError,
  Input,
  Select,
  TextArea,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';

import { convertFormValuesToSwaggerPopupMessage } from './FormValues';
import type { PopupMessage } from './FormValues';
import { updatePopupMessage } from './util';

const encodeCategoryNameForURI = (categoryName: string): string => {
  // We need to replace '%' with '%25' since next.js does not escape '%'
  return encodeURIComponent(categoryName.replace('%', '%25'));
};
type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
  onSave?: (value: PopupMessage) => void;
};
export const EditPopupMessageModal = ({
  name,
  open,
  onClose,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parentFormState = useFormState();
  const { required } = getValidators(t);
  const customPages = useSelector(customPagesSelector);
  const organization = useSelector(activeUserOrganizationSelector);
  const products = useSelector((state: ReduxState) => state.products.summaries);

  const initialValuesFromForm = _.get(parentFormState.values, name);

  const initialValues = initialValuesFromForm
    ? initialValuesFromForm
    : {
        key: uuidv4(),
        text: '',
        url: '',
        displayType: 'ALL_PAGES',
        displayStartDate: '',
        displayEndDate: '',
        imageUrl: '',
        title: '',
        description: '',
        buttonText: '',
        buttonDestinationType: 'CUSTOM_PAGE',
        buttonDestinationUrl: '',
        buttonDestinationPagePath: '/about',
      };
  const displayTypeOptions = [
    {
      key: 'ALL_PAGES',
      value: 'ALL_PAGES',
      label: t('All pages in booking site'),
    },
    {
      key: 'TOP_PAGE_ONLY',
      value: 'TOP_PAGE_ONLY',
      label: t('TOP page'),
    },
  ];
  const buttonDestinationTypeOptions = [
    {
      key: 'CUSTOM_PAGE',
      value: 'CUSTOM_PAGE',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'PRODUCT_PAGE',
      value: 'PRODUCT_PAGE',
      label: t('Select a product'),
    },
    {
      key: 'FULL_URL',
      value: 'FULL_URL',
      label: t('Enter URL directly'),
    },
  ];
  const pmp = organization?.private_marketplaces?.find(
    (pmp) => pmp.language === parentFormState.values?.popupMessageLanguage
  );

  if (pmp) {
    buttonDestinationTypeOptions.push({
      key: 'PRIVATE_MARKETPLACE',
      value: 'PRIVATE_MARKETPLACE',
      label: t('Select a Private Marketplace page'),
    });
  }

  const customPageOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];
  const productOptions = products.map((product) => ({
    key: pmp ? `/top/products/${product.id}` : `/products/${product.id}`,
    value: pmp ? `/top/products/${product.id}` : `/products/${product.id}`,
    text: product.product_name ?? '',
  }));
  const pmpOptions = [
    {
      key: '/top/popular',
      value: '/top/popular',
      text: t('Ranking'),
    },
    {
      key: '/top/recommended',
      value: '/top/recommended',
      text: t('Recommended'),
    },
  ];
  pmp?.category_pages?.forEach((categoryPage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      text: categoryPage.display_name || categoryPage.name || '',
    })
  );
  pmp?.feature_pages?.forEach((featurePage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      text: featurePage.display_name || featurePage.name || '',
    })
  );
  return (
    <Modal
      title={t('Edit pop-up message')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: PopupMessage) => {
          await dispatch(
            updatePopupMessage(
              convertFormValuesToSwaggerPopupMessage(
                values,
                parentFormState.values?.popupMessageLanguage ?? 'JA_JP'
              )
            )
          );
          onSave?.(values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, values, form }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              {submitting && (
                <Loader active={submitting}>{t('Loading')}</Loader>
              )}
              <Box mb={4}>
                <FieldWrapper label={t('Display Period')}>
                  <div className={baseStyles['base-form-range']}>
                    <label className={baseStyles['base-form-calendar']}>
                      <img src={calendarIcon} />
                      <Field name="displayStartDate" validate={required}>
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <input type="date" {...input} />
                            <FormError error={touched && error} />
                          </>
                        )}
                      </Field>
                    </label>
                    <p>-</p>
                    <label className={baseStyles['base-form-calendar']}>
                      <img src={calendarIcon} />
                      <Field name="displayEndDate" validate={required}>
                        {({ input, meta: { error, touched } }) => (
                          <>
                            <input type="date" {...input} />
                            <FormError error={touched && error} />
                          </>
                        )}
                      </Field>
                    </label>
                  </div>
                </FieldWrapper>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('Target Page')}>
                  <EnumRadioButtonGroup
                    name="displayType"
                    options={displayTypeOptions}
                  />
                </FieldWrapper>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('Photo')}>
                  <Field name="imageUrl">
                    {({ input, meta: { touched, error } }) => (
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue.length > 0
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                        error={touched && error}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </Box>
              <Box mb={2}>
                <Field name="title" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <Input
                      label={t('Title')}
                      error={touched && error}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Field name="description">
                  {({ input, meta: { touched, error } }) => (
                    <TextArea
                      label={t('Message')}
                      error={touched && error}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Field name="buttonText">
                  {({ input, meta: { touched, error } }) => (
                    <Input
                      label={t('Text on Button')}
                      error={touched && error}
                      placeholder={t('View Details')}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('URL on button click')}>
                  <EnumRadioButtonGroup
                    name="buttonDestinationType"
                    options={buttonDestinationTypeOptions}
                    onChange={(value) => {
                      if (value === 'FULL_URL') {
                        form.change('buttonDestinationPagePath', '');
                        form.change('buttonDestinationUrl', '');
                      } else if (value === 'PRIVATE_MARKETPLACE') {
                        form.change(
                          'buttonDestinationPagePath',
                          '/top/popular'
                        );
                        form.change('buttonDestinationUrl', '');
                      } else if (value === 'CUSTOM_PAGE') {
                        form.change('buttonDestinationPagePath', '/about');
                        form.change('buttonDestinationUrl', '');
                      } else if (value === 'PRODUCT_PAGE') {
                        form.change(
                          'buttonDestinationPagePath',
                          productOptions.length > 0
                            ? productOptions[0].value
                            : ''
                        );
                        form.change('buttonDestinationUrl', '');
                      }
                    }}
                  />
                  {values?.buttonDestinationType === 'CUSTOM_PAGE' && (
                    <Field name="buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <Select
                          options={customPageOptions}
                          error={touched && error}
                          value={input.value}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  )}
                  {values?.buttonDestinationType === 'PRIVATE_MARKETPLACE' && (
                    <Field name="buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <Select
                          options={pmpOptions}
                          error={touched && error}
                          value={input.value}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  )}
                  {values?.buttonDestinationType === 'PRODUCT_PAGE' && (
                    <Field name="buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <Select
                          options={productOptions}
                          error={touched && error}
                          value={input.value}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  )}
                  {values?.buttonDestinationType === 'FULL_URL' && (
                    <Field name="buttonDestinationUrl" validate={required}>
                      {({ input, meta: { touched, error } }) => (
                        <Input error={touched && error} {...input} />
                      )}
                    </Field>
                  )}
                </FieldWrapper>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  form.change(name as keyof PopupMessage, initialValues);
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button size="middle" style="blue" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
