import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS,
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE,
  FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { RealtimeNotificationTemplateParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchRealtimeNotificationTemplatesRequest = () => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST,
});

const fetchRealtimeNotificationTemplatesSuccess = (response: any) => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS,
  response,
});

const fetchRealtimeNotificationTemplatesFailure = (error: any) => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE,
  error,
});

let fetchRealtimeNotificationTemplatesCancel: () => void | undefined;
export const fetchRealtimeNotificationTemplates =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchRealtimeNotificationTemplatesCancel?.();
    dispatch(fetchRealtimeNotificationTemplatesRequest());
    axios
      .get('/api/realtimenotificationtemplates', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchRealtimeNotificationTemplatesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchRealtimeNotificationTemplatesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRealtimeNotificationTemplatesFailure('canceled'));
        dispatch(fetchRealtimeNotificationTemplatesFailure(error));
      });
  };

const fetchRealtimeNotificationTemplateRequest = () => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
});

const fetchRealtimeNotificationTemplateSuccess = (response: any) => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  response,
});

const fetchRealtimeNotificationTemplateFailure = (error: any) => ({
  type: FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  error,
});

export const fetchRealtimeNotificationTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchRealtimeNotificationTemplateRequest());
    axios
      .get(`/api/realtimenotificationtemplates/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchRealtimeNotificationTemplateSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchRealtimeNotificationTemplateFailure('canceled'));
        else dispatch(fetchRealtimeNotificationTemplateFailure(error));
      });
  };

const deleteRealtimeNotificationTemplateRequest = (id: string) => ({
  type: DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  id,
});

const deleteRealtimeNotificationTemplateSuccess = (
  response: any,
  id: string
) => ({
  type: DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  response,
  id,
});

const deleteRealtimeNotificationTemplateFailure = (error: any) => ({
  type: DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  error,
});

export const deleteRealtimeNotificationTemplate =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteRealtimeNotificationTemplateRequest(id));
    axios
      .delete(`/api/realtimenotificationtemplates/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteRealtimeNotificationTemplateSuccess(response.data, id));
      })
      .catch((error) =>
        dispatch(deleteRealtimeNotificationTemplateFailure(error))
      );
  };

const createRealtimeNotificationTemplateRequest = (
  newRealtimeNotificationTemplate: RealtimeNotificationTemplateParams
) => ({
  type: CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  newRealtimeNotificationTemplate,
});

const createRealtimeNotificationTemplateSuccess = (response: any) => ({
  type: CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  response,
});

const createRealtimeNotificationTemplateFailure = (error: any) => ({
  type: CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  error,
});

export const createRealtimeNotificationTemplate =
  (newRealtimeNotificationTemplate: RealtimeNotificationTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      createRealtimeNotificationTemplateRequest(newRealtimeNotificationTemplate)
    );
    return axios
      .post(
        '/api/realtimenotificationtemplates',
        newRealtimeNotificationTemplate,
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(createRealtimeNotificationTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createRealtimeNotificationTemplateFailure(err.message));
      });
  };

const updateRealtimeNotificationTemplateRequest = (
  id: string,
  patch: RealtimeNotificationTemplateParams
) => ({
  type: UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST,
  id,
  patch,
});

const updateRealtimeNotificationTemplateSuccess = (response: any) => ({
  type: UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS,
  response,
});

const updateRealtimeNotificationTemplateFailure = (error: any) => ({
  type: UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE,
  error,
});

export const updateRealtimeNotificationTemplate =
  (id: string, patch: RealtimeNotificationTemplateParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateRealtimeNotificationTemplateRequest(id, patch));
    return axios
      .patch(`/api/realtimenotificationtemplates/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateRealtimeNotificationTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateRealtimeNotificationTemplateFailure(err.message));
      });
  };
