import type { Translation } from 'shared/models/swagger';

export const getTranslationSourceLanguageText = (
  translation: Translation
): string => {
  if (!translation.source_language) {
    return '';
  }

  const lang =
    translation?.source_language?.toLowerCase() as keyof typeof translation;

  return translation[lang] ?? '';
};
