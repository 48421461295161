import * as React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { Box } from 'client/components/Box/Box';
import {
  Select,
  FieldWrapper,
  ToggleButton,
  Button,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import type { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

import { ExpireLimitInput } from '../ExpireLimitInput/ExpireLimitInput';
import { ETicketStubModal } from '../ETicketStubModal/ETicketStubModal';
import { DraggableStubInputForm } from '../DraggableStubInputForm/DraggableStubInputForm';

import localStyles from './QRCheckinSettings.module.css';

type Props = {
  languageOptions: {
    value: string;
    text: string;
  }[];
  showTranslationMode: boolean;
  translationTargetLanguage: SourceLanguage | null;
  onTranslationModeChange: (arg0: boolean) => void;
  onTranslationTargetLanguageChange: (arg0: SourceLanguage | null) => void;
};
export const QRCheckinSettingsForm = ({
  languageOptions,
  showTranslationMode,
  translationTargetLanguage,
  onTranslationModeChange,
  onTranslationTargetLanguageChange,
}: Props) => {
  const { t } = useTranslation();
  const values = useFormState().values;
  const form = useForm();
  const [openStub, setOpenStub] = React.useState<{
    name: string;
    key: string;
  } | null>(null);
  return (
    <div className={styles['c-tableChild']}>
      <Field name="shouldUseEasyCheckin" type="checkbox">
        {({ input: shouldUseEasyCheckinInput }) => (
          <>
            <Box mb={2}>
              <ToggleButton
                label={t('Simple Checkin')}
                checked={shouldUseEasyCheckinInput.checked}
                onChange={() => {
                  shouldUseEasyCheckinInput.onChange(
                    !shouldUseEasyCheckinInput.checked
                  );
                  form.change('shouldCountGuests', false);
                  form.change('shouldUseETicket', false);
                  form.change('showStubSettings', false);
                }}
              />
            </Box>
            <Box mb={2}>
              <Field name="shouldCountGuests" type="checkbox">
                {({ input }) => (
                  <ToggleButton
                    label={t('Count number of guests to checkin')}
                    checked={input.checked}
                    onChange={() => {
                      input.onChange(!input.checked);
                      form.change('shouldUseEasyCheckin', false);
                      form.change('shouldUseETicket', false);
                    }}
                  />
                )}
              </Field>
            </Box>
            <Box mb={2}>
              <Field name="showStubSettings" type="checkbox">
                {({ input }) => (
                  <ToggleButton
                    label={t('Register multiple ticket usages')}
                    disabled={values?.shouldUseEasyCheckin}
                    checked={input.checked}
                    onChange={() => {
                      input.onChange(!input.checked);
                    }}
                  />
                )}
              </Field>
            </Box>
          </>
        )}
      </Field>

      {!values?.shouldUseEasyCheckin && values?.showStubSettings && (
        <>
          <Box mt={4}>
            <FieldWrapper label={t('Ticket Usage')}>
              <Modal.Box column={'two'}>
                <div className={baseStyles['base-form-toggle']}>
                  <label>
                    <input
                      type="checkbox"
                      checked={showTranslationMode}
                      onChange={() =>
                        onTranslationModeChange(!showTranslationMode)
                      }
                    />
                    <p></p>
                  </label>
                  {t('Translation mode')}
                </div>
                {showTranslationMode && (
                  <Select
                    width={176}
                    options={languageOptions}
                    onChange={(e, { value }: any) => {
                      onTranslationTargetLanguageChange(value);
                    }}
                    value={translationTargetLanguage as any}
                  />
                )}
              </Modal.Box>
            </FieldWrapper>
          </Box>

          <Box mt={4}>
            <FieldArray name="stubs">
              {({ fields }) => (
                <>
                  <div className={localStyles['c-table-list']}>
                    <Box mb={2}>
                      <Button
                        type="button"
                        style="green"
                        size="middle"
                        onClick={() => {
                          fields.push({
                            key: '',
                            options: [],
                          });
                          setOpenStub({
                            name: `stubs[${fields.length}]`,
                            key: '',
                          });
                        }}
                      >
                        {t('Add New Ticket Usage')}
                      </Button>
                    </Box>
                    {openStub !== null && (
                      <ETicketStubModal
                        name={openStub.name}
                        stubKey={openStub.key}
                        translationTargetLanguage={translationTargetLanguage}
                        open={openStub !== null}
                        onClose={() => setOpenStub(null)}
                        hideStubOptions={true}
                      />
                    )}
                    <table>
                      <tbody
                        style={{
                          width: '100%',
                        }}
                      >
                        {translationTargetLanguage && (
                          <TranslationTableHeader hideThTag={true} />
                        )}
                        {fields.map((name, idx) => (
                          <DraggableStubInputForm
                            key={idx}
                            idx={idx}
                            name={name}
                            translationTargetLanguage={
                              translationTargetLanguage
                            }
                            onRemoveClick={() => {
                              fields.remove(idx);
                            }}
                            onEditClick={() => {
                              setOpenStub({
                                name: name,
                                key: fields.value[idx].key,
                              });
                            }}
                            onMoveItem={(
                              dragIndex: number,
                              hoverIndex: number
                            ) => {
                              const dragItem = fields.value[dragIndex];
                              const newItems = [...fields.value];
                              newItems.splice(dragIndex, 1);
                              newItems.splice(hoverIndex, 0, dragItem);
                              newItems.forEach((item, index) => {
                                fields.update(index, item);
                              });
                            }}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <span className={localStyles['c-table-list__comment']}>
                    {t('Drag-and-drop to reorder')}
                  </span>
                </>
              )}
            </FieldArray>
          </Box>

          <Box mt={4}>
            <Field name={`availableStubCount`}>
              {({ input }) => (
                <FieldWrapper label={t('Number of checkin facilities')}>
                  <Select
                    disabled={(values?.stubs ?? []).length === 0}
                    options={[...Array((values?.stubs ?? []).length ?? 10)].map(
                      (_, i) => {
                        return {
                          text: i === 0 ? t('All facilities') : `${i}`,
                          value: `${i}`,
                        };
                      }
                    )}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                </FieldWrapper>
              )}
            </Field>
          </Box>

          <Box mt={4}>
            <FieldWrapper label={t('Expiry from the first ticket redemption')}>
              <ExpireLimitInput name="expirationDate" />
            </FieldWrapper>
          </Box>
        </>
      )}
    </div>
  );
};
