import clsx from 'clsx';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { TranslateFuncType } from 'client/components/Translate';
import baseStyles from 'client/base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

import { getFieldPresetOptions } from './formValues';
import styles from './CustomerFormField.module.css';

export interface Props {
  idx: number;
  name: string;
  onDeleteClick: (idx: number) => void;
}

export const CustomerFormField = ({ idx, name, onDeleteClick }: Props) => {
  const { t } = useTranslation();
  const transparentStyle = {
    backgroundColor: 'transparent',
  };

  const form = useForm();

  const formState = useFormState();

  const formValues = _.get(formState.values, `${name}`);

  const fieldPresetOptions = getFieldPresetOptions(t);

  return (
    <table className={tableStyles['c-tableSmall']}>
      <thead>
        <tr>
          <th style={{ border: 'none' }}>
            <div className={styles['c-customerFormField__title']}>
              <p>
                {t('Form {{index}}', {
                  index: idx + 1,
                })}
              </p>
            </div>
          </th>
          <th style={{ border: 'none' }}></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className={styles['c-customerFormField__body']}>
              <Field name={`${name}.type`}>
                {({ input }) => (
                  <div className={styles['c-customerFormField__item']}>
                    <div
                      className={styles['c-customerFormField__item__header']}
                    >
                      <p style={{ marginRight: '4px' }}>{t('Type')}</p>
                      <Tooltip
                        text={t(
                          'Enter the question text to be displayed on the registration screen'
                        )}
                      >
                        <i className="c-icon-outline-general-info-circle"></i>
                      </Tooltip>
                    </div>
                    <SingleDropdown
                      selectedOption={input.value}
                      onChange={(value) => {
                        const selectedPresetFieldValue =
                          fieldPresetOptions.find(
                            (option) => option.type === value
                          );

                        if (selectedPresetFieldValue) {
                          form.change(name, {
                            ...selectedPresetFieldValue,
                            text: undefined,
                          });
                        }
                      }}
                      options={[
                        ...fieldPresetOptions.map((option) => ({
                          value: option.type,
                          text: option.text,
                        })),
                      ]}
                    />
                  </div>
                )}
              </Field>
              <Field name={`${name}.fieldName`}>
                {({ input, meta: { error, touched } }) => (
                  <>
                    <div className={styles['c-customerFormField__item']}>
                      <div
                        className={styles['c-customerFormField__item__header']}
                      >
                        <p style={{ marginRight: '4px' }}>
                          {t('Form Input Name')}
                        </p>
                        <Tooltip
                          text={t(
                            'Enter the question text to be displayed on the registration screen'
                          )}
                        >
                          <i className="c-icon-outline-general-info-circle"></i>
                        </Tooltip>
                      </div>
                      <TextField
                        value={input.value}
                        error={touched && error}
                        onChange={(value) => input.onChange(value)}
                      />
                    </div>
                  </>
                )}
              </Field>
              <div className={styles['c-customerFormField__item']}>
                <div>
                  <p>{t('Required / Optional')}</p>
                </div>
                <div>
                  <EnumRadioButtonGroup
                    name={`${name}.required`}
                    options={[
                      {
                        value: 'WHEN_SIGN_UP',
                        label: t('Required when sign-up'),
                      },
                      {
                        value: 'OPTIONAL',
                        label: t('Optional'),
                      },
                    ]}
                    style={transparentStyle}
                  />
                </div>
              </div>
              {formValues?.type !== 'ADDRESS' && (
                <Field name={`${name}.responseType`}>
                  {({ input: { value } }) => (
                    <div className={styles['c-customerFormField__item']}>
                      <div className={styles['detailInfo__ttl']}>
                        <p>{t('Response Constraint')}</p>
                      </div>
                      <div className={styles['scheds__body']}>
                        <EnumRadioButtonGroup
                          name={`${name}.responseType`}
                          options={[
                            {
                              value: 'SELECT_ONE',
                              label: t('Choices'),
                              tooltipText: t('Choose from provided options'),
                            },
                            {
                              value: 'FORMATTED_TEXT',
                              label: t('Text Format'),
                              tooltipText: t(
                                'Input required to be in specified format'
                              ),
                            },
                            {
                              value: 'TEXT',
                              label: t('None'),
                              tooltipText: t('Allow free text entry'),
                            },
                          ]}
                          onChange={() => {
                            form?.change(`${name}.format`, '');
                            form?.change(`${name}.options`, ['']);
                          }}
                          style={transparentStyle}
                        />
                      </div>
                      {value === 'FORMATTED_TEXT' && (
                        <>
                          <p
                            className={clsx(
                              styles['scheds__ttl'],
                              baseStyles['blue']
                            )}
                          >
                            {t('Text Format')}
                          </p>
                          <div className={styles['scheds__body']}>
                            <Field name={`${name}.format`}>
                              {({ input }) => (
                                <SingleDropdown
                                  options={getFormatOptions(t)}
                                  selectedOption={input.value}
                                  onChange={(value) => {
                                    input.onChange(value);
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      )}
                      {value === 'SELECT_ONE' && (
                        <>
                          <p
                            className={clsx(
                              styles['scheds__ttl'],
                              styles['blue']
                            )}
                          >
                            {t('Choices')}
                          </p>
                          <ListEditor
                            name={`${name}.options`}
                            style={transparentStyle}
                          />
                        </>
                      )}
                    </div>
                  )}
                </Field>
              )}
            </div>
          </td>
          <td>
            <div>
              <Button
                size="icon"
                color="tertiarygray"
                onClick={() => {
                  onDeleteClick(idx);
                }}
                iconBeforeText={
                  <i className="c-icon-outline-general-trash-03"></i>
                }
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const getFormatOptions = (t: TranslateFuncType) => {
  return [
    {
      value: 'float',
      text: t('Numeric'),
    },
    {
      value: 'yyyy-mm-dd',
      text: t('YYYY-MM-DD'),
    },
  ];
};
