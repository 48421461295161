import * as React from 'react';
import moment from 'moment-timezone';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import type { Weekday } from 'client/libraries/util/weekdays';
import {
  Button,
  FieldWrapper,
  TextArea,
  Input,
  DateRangeInput,
  Select,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';
import {
  disableAccount,
  enableAccount,
  updateAccount,
} from 'client/actions/accounts';
import { getArrayMutators } from 'client/libraries/util/form';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { updateGuideSettings } from 'client/actions/guideSettings';
import { ProductListInput } from 'client/components/ProductListInput';
import type { GuideSettings$Patch } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  guideAccount: GuideAccountShape | null;
};
type FormValues = {
  name: string;
  startTimeLocal: string;
  endTimeLocal: string;
  daysOfWeek: Weekday[];
  description: string;
  productIds: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
  enabled: boolean;
};
export const EditGuideSettingsModal = ({
  open,
  onClose,
  guideAccount,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(summariesSortedByBookmarkedSelector);

  const convertToSwaggerParam = (values: FormValues): GuideSettings$Patch => {
    return {
      operating_hours_rule: {
        start_time_local: values.startTimeLocal,
        end_time_local: values.endTimeLocal,
        days_of_week: values.daysOfWeek,
      },
      description: values.description,
      product_ids: values.productIds,
      start_date_local: values.dateRange.startDate,
      end_date_local: values.dateRange.endDate,
      // duplicate with cognito
      guide_name: values.name,
      // duplicate with cognito
      email: guideAccount?.email || '',
      // TBD (to send email to banda)
      // duplicate with cognito
      enabled: values.enabled,
    };
  };

  const createInitialValues = (): FormValues => {
    const operatingHoursRule = guideAccount?.operating_hours_rule || null;
    return {
      name: guideAccount?.name || '',
      startTimeLocal: operatingHoursRule?.start_time_local || '09:00',
      endTimeLocal: operatingHoursRule?.end_time_local || '17:00',
      daysOfWeek: operatingHoursRule?.days_of_week || [],
      description: guideAccount?.description || '',
      productIds: guideAccount?.product_ids || [],
      dateRange: {
        startDate: guideAccount?.start_date_local || '',
        endDate: guideAccount?.end_date_local || '',
      },
      enabled: guideAccount?.enabled || false,
    };
  };

  const statusOptions = [
    {
      text: t('Enabled'),
      value: 'enabled',
    },
    {
      text: t('Disabled'),
      value: 'disabled',
    },
  ];
  return (
    <Modal
      title={t('Edit Staff')}
      open={open}
      onClose={onClose}
      insertRoot={true}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            const patch = convertToSwaggerParam(values);
            await Promise.all([
              dispatch(updateGuideSettings(guideAccount?.id || '', patch)),
              dispatch(
                updateAccount(guideAccount?.id || '', {
                  name: values.name,
                })
              ),
            ]);

            if (guideAccount?.enabled !== values.enabled) {
              if (values.enabled) {
                await dispatch(enableAccount(guideAccount?.id || ''));
              } else {
                await dispatch(disableAccount(guideAccount?.id || ''));
              }
            }

            onClose();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={createInitialValues()}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field name="name">
                    {({ input }) => <Input label={t('Name')} {...input} />}
                  </Field>
                </Modal.Box>

                <Modal.Box>
                  <Field name="description">
                    {({ input }) => (
                      <TextArea label={t('Description')} {...(input as any)} />
                    )}
                  </Field>
                </Modal.Box>

                <Modal.Box>
                  <Field name="enabled">
                    {({ input }) => (
                      <Select
                        label={t('Status')}
                        options={statusOptions}
                        value={input.value ? 'enabled' : 'disabled'}
                        onChange={(e, { value }) =>
                          input.onChange(value === 'enabled')
                        }
                      />
                    )}
                  </Field>
                </Modal.Box>

                <Modal.Box>
                  <Field name="dateRange">
                    {({ input }) => (
                      <DateRangeInput
                        label={t('Schedule Date Range (optional)')}
                        fromDate={input.value.startDate}
                        toDate={input.value.endDate}
                        onChangeFromDate={(date: string) => {
                          input.onChange({ ...input.value, startDate: date });
                        }}
                        onChangeToDate={(date: string) => {
                          input.onChange({ ...input.value, endDate: date });
                        }}
                      />
                    )}
                  </Field>
                </Modal.Box>

                <Modal.Box>
                  <FieldWrapper label={t('Operating Days of the Week')}>
                    <Field name="daysOfWeek">
                      {({ input }) => (
                        <WeekdaysInput
                          value={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Modal.Box>

                <Modal.Box>
                  <FieldWrapper label={t('Operating hour')}>
                    <Field name="startTimeLocal">
                      {({ input }) => (
                        <TimePicker
                          allowEmpty={false}
                          value={moment(input.value, 'HH:mm')}
                          onChange={(newMoment) => {
                            if (!newMoment) {
                              return;
                            }

                            input.onChange(newMoment.format('HH:mm'));
                          }}
                        />
                      )}
                    </Field>
                    <p>-</p>
                    <Field name="endTimeLocal">
                      {({ input }) => (
                        <TimePicker
                          allowEmpty={false}
                          value={moment(input.value, 'HH:mm')}
                          onChange={(newMoment) => {
                            if (!newMoment) {
                              return;
                            }

                            input.onChange(newMoment.format('HH:mm'));
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Modal.Box>

                <Modal.Box>
                  <Field name="productIds">
                    {({ input }) => (
                      <ProductListInput
                        label={t('Supported products')}
                        candidateProducts={products}
                        products={(input.value || []).map((id: string) =>
                          (products || []).find((p) => p.id === id)
                        )}
                        onChange={(newSelectedProducts) =>
                          input.onChange(
                            (newSelectedProducts || []).map((p) => p?.id || '')
                          )
                        }
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button.Cancel onClick={onClose}>{t('Discard')}</Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
