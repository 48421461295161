import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { updateOrganization } from 'client/actions/organizations';
import { ListInput } from 'client/components/ListInput/ListInput';
import { Button, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { reservationFormInEmailForAccount } from 'shared/models/access';

import styles from './Settings.module.css';

export const OrganizationEmailSettings = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const originalContactEmails = organization?.contact_emails || [];
  const [needReservationEmail, setNeedReservationEmail] =
    React.useState<boolean>(originalContactEmails.length > 0);
  const [shouldShowReservationFormInfo, setShouldShowReservationFormInfo] =
    React.useState(
      organization?.supplier_email_settings
        ?.should_show_reservation_form_info ?? false
    );
  const [contactEmails, setContactEmails] = React.useState<string[]>(
    originalContactEmails
  );
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const reset = React.useCallback(() => {
    setNeedReservationEmail(originalContactEmails.length > 0);
    setContactEmails(organization?.contact_emails || []);
    setShouldShowReservationFormInfo(
      organization?.supplier_email_settings
        ?.should_show_reservation_form_info ?? false
    );
  }, [organization]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    reset();
  }, [reset]);
  return (
    <div>
      {organization ? (
        <>
          <div className={styles['input-box']}>
            <ToggleButton
              label={t('Send notification emails')}
              checked={needReservationEmail}
              onChange={() => setNeedReservationEmail(!needReservationEmail)}
            />
          </div>
          {needReservationEmail && (
            <div className={styles['input-box']}>
              <ListInput
                label={t('Email addresses')}
                value={contactEmails}
                onChange={setContactEmails}
              />
            </div>
          )}
          {reservationFormInEmailForAccount(activeUser) && (
            <Box mt={3}>
              <ToggleButton
                label={t(
                  'Show reservation form question texts and answers in emails'
                )}
                checked={shouldShowReservationFormInfo}
                onChange={() =>
                  setShouldShowReservationFormInfo(
                    !shouldShowReservationFormInfo
                  )
                }
              />
              <Box mt={1}>
                {t(
                  'Reservation form answers may include personal information. By turning this setting ON, you are agreeing to our policy of handling personal information.'
                )}
              </Box>
            </Box>
          )}
          {error && (
            <Message error header={t('Update failed')} content={error} />
          )}
          {success && <Message success header={t('Update succeeded')} />}
          <div className={styles['buttons']}>
            <div className={styles['button-box']}>
              <Button style="gray" size="middle" onClick={reset}>
                {t('Discard')}
              </Button>
            </div>
            <div className={styles['button-box']}>
              <Button
                style="blue"
                size="middle"
                onClick={async () => {
                  try {
                    await dispatch(
                      updateOrganization(organization.id, organization.type, {
                        need_reservation_confirmation_email: true,
                        contact_emails: !needReservationEmail
                          ? []
                          : contactEmails,
                        supplier_email_settings: {
                          should_show_reservation_form_info:
                            shouldShowReservationFormInfo,
                        },
                      })
                    );
                    setSuccess(true);
                    setError('');
                  } catch (err) {
                    setSuccess(false);
                    setError(err as string);
                  }
                }}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>{t('No organization found')}</div>
      )}
    </div>
  );
};
