import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { deleteWaitTime, fetchWaitTimes } from 'client/actions/waitTimes';
import {
  PagedGenericTable,
  ColumnType,
} from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { DigitalMap, Restaurant, WaitTime } from 'shared/models/swagger';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Button } from 'client/components/v3/Common/Button';
import { fetchRestaurants } from 'client/actions/restaurants';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/v3/DeleteConfirmModal/DeleteConfirmModal';

import styles from './WaitTimeList.module.css';
import { EditWaitTimeModal } from './EditWaitTimeModal';

type Pin = Exclude<DigitalMap['pins'], undefined>[number];

const Actions = ({
  waitTime,
  onClickEditButton,
}: {
  waitTime: WaitTime;
  onClickEditButton: (waitTime: WaitTime) => void;
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Box display="flex" gap={2}>
        <i
          className="c-icon-outline-general-edit-05"
          onClick={() => {
            onClickEditButton(waitTime);
          }}
        ></i>
        <i
          className="c-icon-outline-general-trash-03"
          onClick={() => {
            setShowDeleteModal(true);
          }}
        ></i>
      </Box>
      {showDeleteModal && (
        <DeleteConfirmModal
          insertAtRoot
          header={t('Delete Wait Time')}
          content={t('Are you sure you want to delete this wait time?')}
          onConfirm={async () => {
            await dispatch(deleteWaitTime(waitTime.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export const WaitTimeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditWaitTimeModal, setShowEditWaitTimeModal] =
    React.useState(false);
  const [editingWaitTime, setEditingWaitTime] = React.useState<WaitTime | null>(
    null
  );
  const waitTimes = useSelector((state: any) => state.waitTimes.all);
  const restaurants = useSelector((state: any) => state.restaurants.all);
  const digitalMaps = useSelector((state: any) => state.digitalMaps.all);

  const columns: ColumnType<WaitTime>[] = [
    {
      Header: t('Location'),
      accessor: (waitTime: WaitTime) => {
        if (waitTime.restaurant_id) {
          return restaurants.find(
            (restaurant: Restaurant) => restaurant.id === waitTime.restaurant_id
          )?.name;
        }
        return digitalMaps
          .find((map: DigitalMap) => map.id === waitTime.map_id)
          ?.pins?.find((pin: Pin) => pin.key === waitTime.pin_key)?.title;
      },
      id: 'location',
    },
    {
      Header: t('Location Type'),
      accessor: (waitTime: WaitTime) => {
        if (waitTime.restaurant_id) {
          return t('Restaurant');
        }
        return t('Map Pin');
      },
      id: 'locationType',
    },
    {
      Header: t('Wait Time'),
      accessor: (waitTime: WaitTime) =>
        t('{{waitTime}} minutes', {
          waitTime: waitTime.wait_time_minutes || 0,
        }),
      id: 'waitTime',
    },
    {
      Header: t('Enabled'),
      accessor: (waitTime: WaitTime) => (waitTime.enabled ? t('Yes') : t('No')),
      id: 'enabled',
    },
    {
      Header: '',
      accessor: (waitTime: WaitTime) => (
        <Actions
          waitTime={waitTime}
          onClickEditButton={(waitTime: WaitTime) => {
            setShowEditWaitTimeModal(true);
            setEditingWaitTime(waitTime);
          }}
        />
      ),
      id: 'edit',
    },
  ];

  React.useEffect(() => {
    dispatch(fetchWaitTimes());
    dispatch(fetchRestaurants());
    dispatch(fetchDigitalMaps());
  }, [dispatch]);

  return (
    <V3Page>
      <PageHeader title={t('Wait Times')} />
      <PageContent>
        <div className={styles.actions}>
          <Button
            text={t('Create Wait Time')}
            onClick={() => setShowEditWaitTimeModal(true)}
          />
        </div>
        <PagedGenericTable allItems={waitTimes} columns={columns} />
      </PageContent>
      {showEditWaitTimeModal && (
        <EditWaitTimeModal
          existingWaitTime={editingWaitTime}
          onClose={() => {
            setEditingWaitTime(null);
            setShowEditWaitTimeModal(false);
          }}
        />
      )}
    </V3Page>
  );
};
