import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { Button } from 'client/components/v3/Common/Button';
import { SearchConditionsModal } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/SearchConditionsModal';
import { ReservationSearchQueryDisplayBox } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

export const SearchConditions = () => {
  const { t } = useTranslation();
  const appliedSearchCondition = useSelector(
    (state: ReduxState) => (state as any).reservationSearch.lastQuery
  );

  const [showSearchConditionsModal, setShowSearchConditionsModal] =
    React.useState<boolean>(false);

  return (
    <div className={styles['p-download__body__item']}>
      <div className={styles['p-download__body__item__num']}>
        <p className={styles['is-active']}>1</p>
      </div>
      <p className={styles['p-download__body__item__ttl']}>
        {t('Search Conditions')}
      </p>
      <div className={styles['p-download__body__item__edit']}>
        <ReservationSearchQueryDisplayBox
          inline
          searchFilters={appliedSearchCondition}
        />
        <Button
          color="white"
          size="sm"
          iconAfterText={<i className="c-icon-outline-general-edit-05"></i>}
          onClick={() => setShowSearchConditionsModal(true)}
        />
      </div>
      {showSearchConditionsModal && (
        <SearchConditionsModal setShowModal={setShowSearchConditionsModal} />
      )}
    </div>
  );
};
