import { combineReducers } from 'redux';

import {
  SET_ACTIVE_RESERVATION_TUTORIAL_POPUP,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST,
  FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED,
  COPY_SITE_TEMPLATE_SUCCESS,
  COPY_SITE_TEMPLATE_FAILURE,
  COPY_SITE_TEMPLATE_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { GetTemplateSuppliersSettingsResponse } from 'shared/models/swagger';

export type ReservationTutorialPopup =
  | 'main'
  | 'detail'
  | 'related'
  | 'supplementary'
  | 'complete';
export const activeReservationTutorialPopupSelector = (state: ReduxState) =>
  state.tutorial.activeReservationPopup;

const activeReservationPopup = (state = 'main', action: any) => {
  switch (action.type) {
    case SET_ACTIVE_RESERVATION_TUTORIAL_POPUP:
      return action.value;

    default:
      return state;
  }
};

type TemplateSuppliersSettings =
  GetTemplateSuppliersSettingsResponse['template_supplier_booking_widget_settings'];

const templateSuppliersSettings = (
  state: TemplateSuppliersSettings = {
    MARIN: {},
    TOUR: {},
    ACTIVITY: {},
    THEME_PARK: {},
  },
  action: any
) => {
  switch (action.type) {
    case FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS: {
      const resp = action.response as GetTemplateSuppliersSettingsResponse;
      return resp.template_supplier_booking_widget_settings || {};
    }

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case COPY_SITE_TEMPLATE_REQUEST:
    case FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST:
      return true;

    case COPY_SITE_TEMPLATE_SUCCESS:
    case COPY_SITE_TEMPLATE_FAILURE:
    case FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS:
    case FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE:
    case FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED:
      return false;

    default:
      return state;
  }
};

const userReducers = {
  activeReservationPopup,
  templateSuppliersSettings,
  loading,
};
export const tutorial = combineReducers(userReducers);
