import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { DigitalMap, Restaurant, WaitTime } from 'shared/models/swagger';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { ReduxState } from 'client/reducers';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { composeValidators } from 'client/libraries/util/composeValidators';
import { createWaitTime, updateWaitTime } from 'client/actions/waitTimes';
import { Toggle } from 'client/components/v3/Form/Toggle';

import styles from './EditWaitTimeModal.module.css';
import {
  WaitTimeFormValues,
  convertFormValuesToSwaggerWaitTime,
  convertSwaggerWaitTimeToFormValues,
  getInitialWaitTimeFormValues,
} from './formValues';

interface Props {
  onClose: () => void;
  existingWaitTime: WaitTime | null;
}

export const EditWaitTimeModal = ({ existingWaitTime, onClose }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);
  const dispatch = useDispatch();

  const positiveNumeric = (value: string) => {
    if (!value) {
      return undefined;
    }
    if (!/^\d+$/.test(value)) {
      return t('Wait time must be a number');
    }
    return undefined;
  };

  const initialWaitTimeFormValues = existingWaitTime
    ? convertSwaggerWaitTimeToFormValues(existingWaitTime)
    : getInitialWaitTimeFormValues();
  const restaurants = useSelector((state: ReduxState) => state.restaurants.all);
  const restaurantOptions = restaurants.map((restaurant: Restaurant) => ({
    text: restaurant.name ?? '',
    value: restaurant.id ?? '',
  }));
  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);
  const digitalMapOptions = digitalMaps.map((digitalMap: DigitalMap) => ({
    text: digitalMap.name ?? '',
    value: digitalMap.id ?? '',
  }));

  return (
    <Modal
      open={true}
      title={t('Edit Wait Time')}
      insertAtRoot
      onClose={onClose}
      rightActionChildren={
        <Button form="edit-wait-time-form" text={t('Save')} type="submit" />
      }
      allowContentOverflow
    >
      <Form<WaitTimeFormValues>
        onSubmit={(values) => {
          const waitTime = convertFormValuesToSwaggerWaitTime(values);
          if (existingWaitTime) {
            waitTime.id = existingWaitTime.id;
            dispatch(updateWaitTime(waitTime.id ?? '', waitTime));
          } else {
            dispatch(createWaitTime(waitTime));
          }
          onClose();
        }}
        debug={console.log}
        initialValues={initialWaitTimeFormValues}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} id="edit-wait-time-form">
            <EnumRadioButtonGroup
              name="locationType"
              options={[
                { label: t('Attraction'), value: 'ATTRACTION' },
                { label: t('Restaurant'), value: 'RESTAURANT' },
              ]}
            />
            {values.locationType === 'RESTAURANT' && (
              <div className={styles['input-container']}>
                <Field name="restaurantId" validate={required}>
                  {({ input, meta: { error, touched } }) => (
                    <SingleDropdown
                      label={t('Restaurant')}
                      selectedOption={input.value}
                      options={restaurantOptions}
                      onChange={input.onChange}
                      error={touched && error}
                    />
                  )}
                </Field>
              </div>
            )}
            {values.locationType === 'ATTRACTION' && (
              <div className={styles['input-container']}>
                <Field name="mapId" validate={required}>
                  {({ input, meta: { error, touched } }) => (
                    <SingleDropdown
                      label={t('Map')}
                      selectedOption={input.value}
                      options={digitalMapOptions}
                      onChange={input.onChange}
                      error={touched && error}
                    />
                  )}
                </Field>
              </div>
            )}
            {values.locationType === 'ATTRACTION' && values.mapId && (
              <div className={styles['input-container']}>
                <Field name="pinKey">
                  {({ input, meta: { error, touched } }) => (
                    <SingleDropdown
                      label={t('Pin')}
                      selectedOption={input.value}
                      options={
                        digitalMaps
                          ?.find((map) => map.id === values.mapId)
                          ?.pins?.map((pin) => ({
                            text: pin.title ?? '',
                            value: pin.key ?? '',
                          })) ?? []
                      }
                      onChange={input.onChange}
                      error={touched && error}
                    />
                  )}
                </Field>
              </div>
            )}
            <div className={styles['input-container']}>
              <EnumRadioButtonGroup
                isRequired
                name="dataUpdateType"
                options={[
                  { label: t('Manual'), value: 'MANUAL' },
                  { label: t('Automatic'), value: 'AUTO' },
                ]}
              />
            </div>
            {values.dataUpdateType === 'AUTO' && (
              <div className={styles['input-container']}>
                <Field name="automaticDataUpdateConfig">
                  {({ input }) => (
                    <TextArea
                      label={t('Automatic Data Update Config')}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>
            )}
            {values.dataUpdateType === 'MANUAL' && (
              <div className={styles['input-container']}>
                <Field
                  name="waitTimeInMinutes"
                  validate={composeValidators(required, positiveNumeric)}
                >
                  {({ input, meta: { error, touched } }) => (
                    <TextField
                      label={t('Wait Time (in minutes)')}
                      value={input.value}
                      onChange={input.onChange}
                      error={touched && error}
                    />
                  )}
                </Field>
              </div>
            )}
            <div className={styles['input-container']}>
              <Field name="enabled">
                {({ input }) => (
                  <Toggle
                    label={t('Enabled')}
                    checked={input.value}
                    onChange={() => input.onChange(!input.value)}
                  />
                )}
              </Field>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};
