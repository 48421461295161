import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import type { GraphDataItem } from 'client/libraries/util/reservationReport';
import { v3Colors } from 'client/libraries/util/colors';
import { formattedAmount } from 'client/libraries/util/reservationReport';

type Props = {
  data: GraphDataItem[];
  keys: string[];
  names: Record<string, string>;
  currencyCode: string | null;
};
export const BarChartPane = ({ data, keys, names, currencyCode }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) => {
            return formattedAmount(value, currencyCode);
          }}
        />
        <Tooltip
          formatter={(value: any) => {
            return formattedAmount(value, currencyCode);
          }}
        />
        <Legend />
        {keys.map((key, idx) => {
          return (
            <Bar
              key={idx}
              dataKey={key}
              stackId="a"
              fill={chartColors[idx % v3Colors.length]}
              name={names[key]}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

const chartColors = [
  '#3B82F6',
  '#EAB308',
  '#10B981',
  '#84CC16',
  '#8B5CF6',
  '#F97316',
  '#EC4899',
  '#84CC16',
  '#6366F1',
  '#F43F5E',
  '#0EA5E9',
  '#84CC16',
  '#A855F7',
];
