import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';

import { useAnalyticsCustomer } from '../useAnalyticsCustomer';

import styles from './ConversionEvent.module.css';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const ConversionEvent = ({ event, durationInSeconds }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const customer = useAnalyticsCustomer();
  const reservation = customer?.reservations?.find(
    (r) => r.id === event.reservation_id
  );

  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const productId = reservation?.product_id;
  const product = productId
    ? allProducts.find((p) => p.id === productId)
    : null;
  const productThumbnailUrl = product?.media?.find(
    (m) => m.type === 'IMAGE'
  )?.url;

  const guestCounts: { guestType: string; count: number }[] =
    reservation?.guests?.reduce((acc, guest) => {
      const guestType = guest.guest_type_key;
      const existingGuestType = acc.find((g) => g.guestType === guestType);
      if (existingGuestType) {
        existingGuestType.count += 1;
      } else {
        acc.push({ guestType, count: 1 });
      }
      return acc;
    }, [] as { guestType: string; count: number }[]) ?? [];

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['timeline']}>
          <div className={styles['icon']}>
            <i className="c-icon-outline-general-check-done-01"></i>
          </div>

          <div className={styles['duration']}>
            {formattedDuration(durationInSeconds, t)}
          </div>
        </div>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>{t('Booked')}</div>
            <div className={styles['timestamp']}>
              {moment(event.event_date_time_utc).format('H:mm:ss')}
            </div>
          </div>
          <Box display="flex" gap={8}>
            <div className={styles['attr']}>
              <div className={styles['attr-name']}>{t('Start Time')}</div>
              <div className={styles['attr-value']}>
                {moment
                  .tz(
                    reservation?.start_date_time_utc,
                    reservation?.start_timezone ?? ''
                  )
                  .locale(locale)
                  .format('lll')}
              </div>
            </div>
            <div className={styles['attr']}>
              <div className={styles['attr-name']}>{t('Pax')}</div>
              <div className={styles['attr-value']}>
                {guestCounts
                  .map((g) => `${g.guestType} (x${g.count})`)
                  .join(',')}
              </div>
            </div>
            <div className={styles['attr']}>
              <div className={styles['attr-name']}>{t('Lead Time')}</div>
              <div className={styles['attr-value']}>
                {t('{{count}} days', {
                  count: moment(reservation?.start_date_time_utc).diff(
                    moment(
                      reservation?.status_history?.[0].status_date_time_utc
                    ),
                    'days'
                  ),
                })}
              </div>
            </div>
          </Box>
          <div className={styles['body']}>
            <div className={styles['body-inner']}>
              {productThumbnailUrl && (
                <img
                  loading="lazy"
                  src={productThumbnailUrl}
                  className={styles['thumbnail']}
                />
              )}
              <div className={styles['description']}>
                <div className={styles['page-title']}>
                  {reservation?.product_name}
                </div>
                <a
                  className={styles['link']}
                  href={event.page_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className=""></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
