import {
  Equipment,
  EquipmentInstance,
  EquipmentSchedule,
  EquipmentStartTimeMapping,
} from 'shared/models/swagger';
import {
  getAssignableEquipmentInstances,
  getAssignableEquipmentInstancesTotalCapacity,
  getAssignableEquipmentInstancesConsumedCapacity,
} from 'client/components/Seat/utils';

export type EquipmentAvailability = {
  equipmentId: string;
  equipmentTitle: string;
  startTimesAvailabilities: StartTimeAvailabilities[];
};

export type StartTimeAvailabilities = {
  startTimeKey: string;
  startTime: string;
  dateAvailabilities: DateAvailability[];
};

export type DateAvailability = {
  equipmentInstanceId: string;
  date: string;
  total: number;
  occupied: number;
  isClosed?: boolean;
  assignableEquipmentInstances?: {
    total: number;
    title: string;
  }[];
};

export const getEquipmentAvailabilities = (
  startDate: string,
  endDate: string,
  equipments: Equipment[],
  equipmentInstances: EquipmentInstance[]
): EquipmentAvailability[] => {
  return equipments.map((equipment) => {
    const schedule = getEquipmentSchedule(startDate, equipment);

    const EquipmentAvailability: EquipmentAvailability = {
      equipmentId: equipment.id || '',
      equipmentTitle: equipment.title || '',
      startTimesAvailabilities:
        schedule?.start_time_mappings?.map(
          (startTimeMapping: EquipmentStartTimeMapping) => {
            const dateAvailability =
              equipmentInstances
                .filter((equipmentInstance) => {
                  return (
                    equipmentInstance.start_time_key === startTimeMapping.key
                  );
                })
                .filter((equipmentInstance) => {
                  return (
                    equipmentInstance.date &&
                    equipmentInstance.date >= startDate &&
                    equipmentInstance.date <= endDate
                  );
                })
                .map((equipmentInstance) => {
                  const totalCapacity =
                    getAssignableEquipmentInstancesTotalCapacity(
                      equipmentInstance.id || '',
                      equipmentInstances
                    );
                  const consumedCapacity =
                    getAssignableEquipmentInstancesConsumedCapacity(
                      equipmentInstance.id || '',
                      equipmentInstances
                    );
                  const assignableEquipmentInstances =
                    getAssignableEquipmentInstances(
                      equipmentInstance.id || '',
                      equipmentInstances
                    );
                  const totalNumberAndTitle = assignableEquipmentInstances.map(
                    (equipmentInstance) => {
                      const equipment = equipments.find((equipment) => {
                        return equipment.id === equipmentInstance.equipment_id;
                      });

                      let totalOfThisInstance = 0;
                      if (
                        !equipmentInstance?.additional_equipment_settings
                          ?.close_original_equipment
                      ) {
                        totalOfThisInstance =
                          equipmentInstance
                            ?.total_equipment_block_instance_count?.value || 0;
                      }

                      return {
                        total: totalOfThisInstance,
                        title: equipment?.title || '',
                      };
                    }
                  );
                  return {
                    equipmentInstanceId: equipmentInstance.id || '',
                    date: equipmentInstance.date || '',
                    total: totalCapacity,
                    occupied: consumedCapacity,
                    isClosed:
                      equipmentInstance.per_channel_info
                        ?.all_channels_are_closed || false,
                    assignableEquipmentInstances: totalNumberAndTitle,
                  };
                }) || [];

            return {
              startTimeKey: startTimeMapping.key || '',
              startTime: startTimeMapping.start_time_local || '',
              dateAvailabilities: dateAvailability,
            };
          }
        ) || [],
    };

    return EquipmentAvailability;
  });
};

export const getEquipmentSchedule = (
  startDate: string,
  equipment: Equipment
): EquipmentSchedule | null => {
  for (const schedule of equipment.equipment_schedules || []) {
    if (
      schedule.start_date_local &&
      schedule.start_date_local <= startDate &&
      schedule.end_date_local &&
      schedule.end_date_local >= startDate
    ) {
      return schedule;
    }
  }
  return null;
};

export const getDateTotalPax = (
  equipmentAvailabilities: EquipmentAvailability[]
): { [key: string]: number } => {
  const dateTotalPax: { [key: string]: number } = {};
  for (const equipmentAvailability of equipmentAvailabilities) {
    for (const startTimeAvailability of equipmentAvailability.startTimesAvailabilities ||
      []) {
      for (const dateAvailability of startTimeAvailability.dateAvailabilities ||
        []) {
        if (dateAvailability.date) {
          if (dateTotalPax[dateAvailability.date]) {
            dateTotalPax[dateAvailability.date] =
              dateTotalPax[dateAvailability.date] + dateAvailability.occupied;
          } else {
            dateTotalPax[dateAvailability.date] = dateAvailability.occupied;
          }
        }
      }
    }
  }

  return dateTotalPax;
};
