import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { useReviews } from 'client/hooks/useReviews';
import { sendReviewRequestEmail } from 'client/actions/reservations';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import type { Reservation } from 'shared/models/swagger';
import { SendReviewRequestEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review/SendReviewRequestEmailModal';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { ReviewList } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Review/ReviewList';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation?: Reservation;
};

export const Review = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailSendingStatus, setEmailSendingStatus] = useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const [showModal, setShowModal] = useState(false);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const { reviews } = useReviews({
    reservationId: reservation?.id ?? '',
  });
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;
  return (
    <section
      id="review"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>{t('Reviews')}</p>
          <div className={styles['p-reservationsDetail__actions']}></div>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          {reviews.length === 0 && (
            <>
              {!isSubscriptionCancelled(
                activeOrganization,
                'feature-review'
              ) ? (
                <>
                  <Button
                    text={t('Send Review Request Email')}
                    uiType="bg"
                    size="md"
                    color="primary"
                    disabled={emailSendingStatus === 'SUCCEEDED'}
                    loading={emailSendingStatus === 'REQUESTED'}
                    onClick={() => setShowModal(true)}
                  />
                  {showModal && (
                    <SendReviewRequestEmailModal
                      initialToAddress={email}
                      onSubmit={(email?: string) => {
                        try {
                          setEmailSendingStatus('REQUESTED');
                          dispatch(
                            sendReviewRequestEmail(reservation?.id ?? '', email)
                          );
                          setEmailSendingStatus('SUCCEEDED');
                        } catch (e) {
                          setEmailSendingStatus('FAILED');
                        }
                      }}
                      onClose={() => setShowModal(false)}
                    />
                  )}
                </>
              ) : (
                <>
                  {t(
                    'Your subscription to the reviews feature has been deactivated so sending review request emails is disabled. Resubscribe here: '
                  )}{' '}
                  <a
                    className={styles['link']}
                    href={`${window.origin}/systemfee/settings`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Settings')}
                  </a>
                </>
              )}
              <Snackbar
                text={t('Send Successful')}
                color="success"
                shouldShow={emailSendingStatus == 'SUCCEEDED'}
              />
              <Snackbar
                text={t('Send Failed')}
                color="error"
                shouldShow={emailSendingStatus == 'FAILED'}
              />
            </>
          )}

          {reviews.length > 0 && (
            <ReviewList
              reviews={reviews}
              showEditButton={hasCustomUserRoleWritePermissions(
                activeUser,
                'REVIEW.REVIEWS'
              )}
              showStatus
            />
          )}
        </div>
      </div>
    </section>
  );
};
