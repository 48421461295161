import moment, { Moment } from 'moment-timezone';

import {
  filteredStatuses,
  histogram,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { dateTimesSpanMultipleDays } from 'client/libraries/util/dateTimesSpanMultipleDays';
import { getAddOnsSummary } from 'client/libraries/util/getAddOnsSummary';
import { getFormattedBookingSource } from 'client/libraries/util/getFormattedBookingSource';
import { getFormattedPackageComponentStartTime } from 'client/libraries/util/getFormattedPackageComponentStartTime';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  Guest,
  Reservation,
  WaiverInfo,
  CheckinInfo,
  ReservationTransportRouteItem,
  ResourceAssignment,
} from 'shared/models/swagger';

export type ReservationSummaryShape = {
  product_id: string;
  reservation_id: string;
  product_name: string;
  participates_at: Moment;
  formatted_start_time: string;
};
export type ManifestReservationShape = {
  id: string;
  agent_reference: string;
  agent_notes: string;
  booking_source: string;
  supplier_notes: string;
  participates_at: Moment;
  add_ons: string;
  transportation: string;
  status: string;
  field_responses: {
    key?: string;
    response?: string;
  }[];
  amount_gross: string;
  guests: Guest[];
  guest_description: string;
  guest_display_name: string;
  given_name: string;
  family_name: string;
  product_name: string;
  hotel: string;
  pickup_checkin_location: string;
  pickup_checkin_time: Moment | null;
  dropoff_checkout_location: string;
  dropoff_checkout_time: Moment | null;
  product_id: string;
  product_instance_id: string;
  amount_pob: string;
  dropoff_location: string;
  supplier_reference: string;
  supplier_name: string;
  supplier_internal_notes: string;
  supplier_internal_notes_for_dispatch: string;
  supplier_product_reference: string;
  payment_type: string;
  payment_method: string;
  booked_at: Moment;
  guest_count: number;
  updated_at: Moment;
  dispatch_crew: string[];
  dispatch_vehicles: string[];
  dispatch_misc_resources: string[];
  dispatch_guides: string[];
  package_component_reservations: ReservationSummaryShape[];
  internal_product_tags: string[];
  waiver_info?: WaiverInfo;
  checkin_info?: CheckinInfo;
  transport_route?: ReservationTransportRouteItem[];
  start_timezone?: string;
  promo_code?: string;
  resource_assignments?: ResourceAssignment[];
  is_free_start_date_time?: boolean;
  is_free_format_reservation?: boolean;
};
export const toManifestReservationShape = (
  reservation: Reservation,
  t: TranslateFuncType
): ManifestReservationShape => {
  const {
    id,
    agent_notes,
    agent_reference,
    dispatch_crew,
    dispatch_misc_resources,
    dispatch_vehicles,
    dispatch_guides,
    status_history,
    supplier_reference,
    start_date_time_utc,
    payment_type,
    payment_method,
    field_responses,
    guests,
    guest_hotel,
    start_timezone,
    product_id,
    product_instance_id,
    supplier_internal_notes,
    supplier_internal_notes_for_dispatch,
    supplier_notes,
    supplier_product_reference,
    requested_pickup_location,
    billing_info,
    pickup,
    checkin,
    checkout,
    dropoff,
    last_updated_date_time_utc,
    package_component_reservation_summaries,
    transportation_title,
    promo_code,
    internal_product_tags,
    waiver_info,
    checkin_info,
    transport_route,
    resource_assignments,
    free_start_date_time_from,
    free_start_date_time_to,
    is_free_format_reservation,
  } = reservation;
  const { product_name, supplier_name, alternate_product_name } = reservation;
  const booked_at = moment(status_history[0].status_date_time_utc);
  const statuses = filteredStatuses(status_history);
  const status =
    (statuses.length > 0 && statuses[statuses.length - 1].status) || '';
  const participates_at = moment.tz(start_date_time_utc, start_timezone ?? '');
  const updated_at = moment(last_updated_date_time_utc);
  const guest_display_name = getGuestName(reservation);
  const given_name =
    field_responses?.find((f) => f.key === 'given_name')?.response ||
    field_responses?.find((f) => f.key === 'kana_given_name')?.response ||
    '';
  const family_name =
    field_responses?.find((f) => f.key === 'family_name')?.response ||
    field_responses?.find((f) => f.key === 'kana_family_name')?.response ||
    '';
  const guest_count = guests.length;
  const reservationGuestCounts = histogram(
    reservation.guests,
    (g) => g.guest_type_title || g.guest_type_key
  );
  const guest_description = Object.keys(reservationGuestCounts)
    .map((guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`)
    .join(', ');
  let hotel =
    (guest_hotel &&
      (guest_hotel.alternate_location_name || guest_hotel.location_name)) ||
    (requested_pickup_location && requested_pickup_location.location_name);

  if (hotel === 'TBD') {
    hotel = t('TBD');
  }

  const pickupCheckinLocation = !reservationIsCheckinCheckoutOnly(reservation)
    ? pickup
    : checkin;
  const pickup_checkin_location =
    (pickupCheckinLocation &&
      (pickupCheckinLocation.alternate_location_name ||
        pickupCheckinLocation.location_name)) ||
    '';
  const pickupCheckinLocationDateTime =
    pickupCheckinLocation && pickupCheckinLocation.date_time_utc;
  const pickup_checkin_time = pickupCheckinLocationDateTime
    ? moment.tz(pickupCheckinLocationDateTime, reservation.start_timezone ?? '')
    : null;
  const dropoffCheckoutLocation = !reservationIsCheckinCheckoutOnly(reservation)
    ? dropoff
    : checkout;
  const dropoff_checkout_location =
    (dropoffCheckoutLocation &&
      (dropoffCheckoutLocation.alternate_location_name ||
        dropoffCheckoutLocation.location_name)) ||
    '';
  const dropoffCheckoutLocationDateTime =
    dropoffCheckoutLocation && dropoffCheckoutLocation.date_time_utc;
  const dropoff_checkout_time = dropoffCheckoutLocationDateTime
    ? moment.tz(
        dropoffCheckoutLocationDateTime,
        reservation.start_timezone ?? ''
      )
    : null;
  const dropoff_location =
    (dropoff && (dropoff.alternate_location_name || dropoff.location_name)) ||
    '';
  const add_ons = getAddOnsSummary(reservation);
  const amount_gross = (billing_info && billing_info.amount_gross) || '-';
  const amount_pob = (billing_info && billing_info.amount_pay_on_board) || '-';
  const bookingSource = getFormattedBookingSource(reservation, t);
  const componentReservations = package_component_reservation_summaries || [];
  const componentsSpanMultipleDays = dateTimesSpanMultipleDays(
    [
      start_date_time_utc || '',
      ...componentReservations.map(
        (component) => component.start_date_time_utc || ''
      ),
    ],
    start_timezone || ''
  );
  const package_component_reservations = componentReservations.map(
    (componentReservation) => ({
      product_id: componentReservation.product_id || '',
      product_name: componentReservation.product_name || '',
      reservation_id: componentReservation.reservation_id || '',
      participates_at: moment.tz(
        componentReservation.start_date_time_utc,
        start_timezone ?? ''
      ),
      formatted_start_time: getFormattedPackageComponentStartTime(
        componentReservation.start_date_time_utc || '',
        start_timezone || '',
        componentsSpanMultipleDays
      ),
    })
  );
  return {
    id,
    agent_reference: agent_reference || '',
    agent_notes: agent_notes || '',
    booking_source: bookingSource,
    supplier_notes: supplier_notes || '',
    participates_at,
    add_ons: add_ons || '',
    transportation:
      transportation_title ||
      (!reservationIsCheckinCheckoutOnly(reservation)
        ? t('Pickup/Dropoff Included')
        : t('Checkin/Checkout Only')),
    status,
    field_responses: field_responses || [],
    amount_gross,
    guests,
    guest_description,
    guest_display_name,
    given_name,
    family_name,
    product_name: alternate_product_name || product_name || '',
    hotel: hotel || '',
    pickup_checkin_location,
    pickup_checkin_time,
    dropoff_checkout_location,
    dropoff_checkout_time,
    amount_pob,
    dropoff_location,
    product_id: product_id || '',
    product_instance_id: product_instance_id || '',
    supplier_reference: supplier_reference || '',
    supplier_product_reference: supplier_product_reference || '',
    supplier_name: supplier_name || '',
    supplier_internal_notes: supplier_internal_notes || '',
    supplier_internal_notes_for_dispatch:
      supplier_internal_notes_for_dispatch || '',
    payment_type: payment_type || '',
    payment_method: payment_method || '',
    booked_at,
    guest_count,
    updated_at,
    dispatch_crew: dispatch_crew || [],
    dispatch_vehicles: dispatch_vehicles || [],
    dispatch_misc_resources: dispatch_misc_resources || [],
    dispatch_guides: dispatch_guides || [],
    package_component_reservations,
    promo_code,
    internal_product_tags: internal_product_tags ?? [],
    waiver_info,
    checkin_info,
    transport_route,
    start_timezone,
    resource_assignments,
    is_free_start_date_time: Boolean(
      free_start_date_time_from && free_start_date_time_to
    ),
    is_free_format_reservation: is_free_format_reservation || false,
  };
};
