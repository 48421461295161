import { useTranslation } from 'react-i18next';

import { Message } from 'client/components/Message/Message';
import { Button } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';

interface MessageModalProps {
  onClose: () => void;
  open: boolean;

  title?: string;
  message: string;
  error?: boolean;
}

export const MessageModal = ({
  open,
  onClose,
  title,
  message,
  error,
}: MessageModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onClose} title={title ?? ''}>
      <Modal.Content>
        {error && <Message error header={message} />}
        {!error && <Message success header={message} />}
      </Modal.Content>
      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
