import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from 'client/components/v3/Common/Button';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
import baseStyles from 'client/v3-base.module.css';

import styles from './DateListInput.module.css';

type Props = {
  addButtonLabel?: string;
  dates: string[];
  setDates: (arg0: string[]) => void;
};

export const DateListInput = ({ addButtonLabel, dates, setDates }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles['c-dateListInput']}>
      <div className={styles['c-dateListInput__list']}>
        {dates.map((date, idx) => (
          <div key={idx} className={styles['c-dateListInput__list__item']}>
            <DateTimeTZInput
              value={date ? moment(date) : null}
              onChange={(newDate) =>
                setDates(
                  dates.map((d, idx2) =>
                    idx2 === idx ? newDate.format('YYYY-MM-DD') : d
                  )
                )
              }
            />
            <Button
              size="icon"
              color="tertiarygray"
              onClick={() => {
                const newDates = [...(dates || [])];
                newDates.splice(idx, 1);
                setDates(newDates);
              }}
              iconBeforeText={
                <i className="c-icon-outline-general-trash-03"></i>
              }
            />
          </div>
        ))}
      </div>
      <a
        className={clsx(
          styles['c-dateListInput__add'],
          dates.length > 0 && baseStyles['u-mt-3']
        )}
        onClick={() => {
          const newDates = [...(dates || [])];
          newDates.splice(dates.length + 1, 0, '');
          setDates(newDates);
        }}
      >
        <i className="c-icon-outline-general-plus-circle"></i>
        {addButtonLabel ? addButtonLabel : t('Add')}
      </a>
    </div>
  );
};
