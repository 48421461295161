import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';

type Props = {
  message: string;
  title: string;
  onClose: () => void;
  onSubmit?: () => void;
  insertRoot?: boolean;
  loading?: boolean;
};
export const MessageModal = ({
  message,
  title,
  onClose,
  onSubmit,
  insertRoot,
  loading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      open={true}
      onClose={onClose}
      insertAtRoot={insertRoot}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            color="white"
            onClick={() => {
              onClose && onClose();
            }}
          />
          <Button
            text={t('Yes')}
            onClick={() => {
              onSubmit && onSubmit();
            }}
            loading={loading}
          />
        </>
      }
      style={{ maxWidth: '600px' }}
    >
      {message}
    </Modal>
  );
};
