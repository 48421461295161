import * as React from 'react';
import clsx from 'clsx';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import styles from 'client/base.module.css';

type Props = {
  error?: string;
  label?: string | React.ReactElement;
  minLength?: string;
  maxLength?: string;
  type?: 'text' | 'password';
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  tooltipText?: string;
};

export const FieldWrapper = ({
  error,
  label,
  required,
  children,
  tooltipText,
}: Props) => {
  return (
    <div className={styles['base-form-box']}>
      <div
        className={clsx(
          styles['base-form-box__header'],
          required ? styles['required'] : '',
          error ? styles['error'] : ''
        )}
      >
        {label}

        {tooltipText && (
          <Tooltip text={tooltipText} style={{ fontWeight: 'normal' }} />
        )}
      </div>
      <div className={styles['base-form-box__body']}>
        {children}
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
