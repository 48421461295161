import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_GUIDANCE_STAMP_RALLY_FAILURE,
  CREATE_GUIDANCE_STAMP_RALLY_REQUEST,
  CREATE_GUIDANCE_STAMP_RALLY_SUCCESS,
  DELETE_GUIDANCE_STAMP_RALLY_FAILURE,
  DELETE_GUIDANCE_STAMP_RALLY_REQUEST,
  DELETE_GUIDANCE_STAMP_RALLY_SUCCESS,
  FETCH_GUIDANCE_STAMP_RALLIES_FAILURE,
  FETCH_GUIDANCE_STAMP_RALLIES_REQUEST,
  FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS,
  UPDATE_GUIDANCE_STAMP_RALLY_FAILURE,
  UPDATE_GUIDANCE_STAMP_RALLY_REQUEST,
  UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { GuidanceStampRallyParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGuidanceStampRalliesRequest = () => ({
  type: FETCH_GUIDANCE_STAMP_RALLIES_REQUEST,
});

const fetchGuidanceStampRalliesSuccess = (response: any) => ({
  type: FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS,
  response,
});

const fetchGuidanceStampRalliesFailure = (error: string) => ({
  type: FETCH_GUIDANCE_STAMP_RALLIES_FAILURE,
  error,
});

let fetchGuidanceStampRalliesCancel: () => void | null;
export const fetchGuidanceStampRallies =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGuidanceStampRalliesCancel && fetchGuidanceStampRalliesCancel();
    dispatch(fetchGuidanceStampRalliesRequest());
    axios
      .get('/api/guidancestamprallies', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGuidanceStampRalliesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchGuidanceStampRalliesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGuidanceStampRalliesFailure('canceled'));
        else dispatch(fetchGuidanceStampRalliesFailure(error));
      });
  };

const deleteGuidanceStampRallyRequest = (id: string) => ({
  type: DELETE_GUIDANCE_STAMP_RALLY_REQUEST,
  id,
});
const deleteGuidanceStampRallySuccess = (response: any, id: string) => ({
  type: DELETE_GUIDANCE_STAMP_RALLY_SUCCESS,
  response,
  id,
});
const deleteGuidanceStampRallyFailure = (error: string) => ({
  type: DELETE_GUIDANCE_STAMP_RALLY_FAILURE,
  error,
});
export const deleteGuidanceStampRally =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGuidanceStampRallyRequest(id));
    axios
      .delete(`/api/guidancestamprallies/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteGuidanceStampRallySuccess(result, id)))
      .catch((error) => dispatch(deleteGuidanceStampRallyFailure(error)));
  };

const createGuidanceStampRallyRequest = (
  newGuidanceStampRally: GuidanceStampRallyParams
) => ({
  type: CREATE_GUIDANCE_STAMP_RALLY_REQUEST,
  newGuidanceStampRally,
});
const createGuidanceStampRallySuccess = (response: any) => ({
  type: CREATE_GUIDANCE_STAMP_RALLY_SUCCESS,
  response,
});
const createGuidanceStampRallyFailure = (error: string) => ({
  type: CREATE_GUIDANCE_STAMP_RALLY_FAILURE,
  error,
});

export const createGuidanceStampRally =
  (newGuidanceStampRally: GuidanceStampRallyParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGuidanceStampRallyRequest(newGuidanceStampRally));
    return axios
      .post('/api/guidancestamprallies', newGuidanceStampRally, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createGuidanceStampRallySuccess(response.data));
      })
      .catch((err) => {
        dispatch(createGuidanceStampRallyFailure(err.message));
      });
  };

const updateGuidanceStampRallyRequest = (
  id: string,
  patch: GuidanceStampRallyParams
) => ({
  type: UPDATE_GUIDANCE_STAMP_RALLY_REQUEST,
  id,
  patch,
});
const updateGuidanceStampRallySuccess = (response: any) => ({
  type: UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS,
  response,
});
const updateGuidanceStampRallyFailure = (error: string) => ({
  type: UPDATE_GUIDANCE_STAMP_RALLY_FAILURE,
  error,
});

export const updateGuidanceStampRally =
  (id: string, patch: GuidanceStampRallyParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGuidanceStampRallyRequest(id, patch));
    return axios
      .patch(`/api/guidancestamprallies/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateGuidanceStampRallySuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateGuidanceStampRallyFailure(err.message));
      });
  };
