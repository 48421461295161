import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import { Section } from '@nutmeglabs/falcon-ui';
import { useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import { Editor } from 'client/components/Editor/Editor';
import { Button, FieldWrapper } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './EditEditorJsSectionModal.module.css';
import { SectionTitleEditor } from './SectionTitleEditor';
import { SectionBackgroundEditor } from './SectionBackgroundEditor';

type FormValues = Omit<Section, 'editorJsContent' | 'type'> & {
  editorJsJSONContent: string;
};

interface Props {
  name: string;
}

export const EditEditorJsSectionModal = ({ name }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [editorJsLoading, setEditorJsLoading] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const { t } = useTranslation();
  const form = useForm();

  const {
    title,
    titleStyle,
    editorJsContent,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
    themeContentBackgroundColor,
  } = _.get(form.getState().values, name) as Section;

  const initialValues = React.useMemo<FormValues>(() => {
    return {
      title,
      titleStyle,
      editorJsJSONContent: JSON.parse(editorJsContent || '{}'),
      useBackgroundImage,
      backgroundColor,
      backgroundImageUrl,
      themeContentBackgroundColor,
    };
  }, [
    title,
    titleStyle,
    editorJsContent,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
    themeContentBackgroundColor,
  ]);

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Edit Editor.JS Content')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
          width="wide"
        >
          <Form
            initialValues={initialValues}
            onSubmit={(values: FormValues) => {
              form.change(name, {
                type: 'EDITORJS',
                title: values.title,
                titleStyle: values.titleStyle,
                editorJsContent: JSON.stringify(values.editorJsJSONContent),
                useBackgroundImage: values.useBackgroundImage,
                backgroundColor: values.backgroundColor,
                backgroundImageUrl: values.backgroundImageUrl,
                themeContentBackgroundColor: values.themeContentBackgroundColor,
              });
              setShowModal(false);
            }}
            debug={console.log}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <SectionTitleEditor />
                  <SectionBackgroundEditor />
                  <Field name="themeContentBackgroundColor">
                    {({ input }: FieldRenderProps<string>) => (
                      <FieldWrapper
                        label={t('Theme: Content background color')}
                      >
                        <ColorPicker
                          color={input.value}
                          onChange={({ color }: any) => input.onChange(color)}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                  <Modal.Box>
                    <FieldWrapper label={t('Body')} />
                  </Modal.Box>
                  <div className={styles['body-editor']}>
                    <Field name="editorJsJSONContent">
                      {({ input }) => (
                        <Editor
                          imageS3Prefix={`editor/supplier/${
                            activeUserOrganization?.id || ''
                          }`}
                          data={input.value}
                          onChange={(data: any, loading) => {
                            setEditorJsLoading(loading);
                            input.onChange(data);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {t(
                    '* Copy and paste URL of YouTube videos to show YouTube videos in the massage.'
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update loading={editorJsLoading} type="submit">
                    {t('Update')}
                  </Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
