import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/v3-base.module.css';

export const PaymentTypeEditor = () => {
  const { t } = useTranslation();
  const [onlineError, setOnlineError] = useState<string>('');
  const [localError, setLocalError] = useState<string>('');
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const formState = useFormState();

  const organizationPaymentTypes = [
    ...(activeUserOrganization?.supplier_guest_payment_settings
      ?.accepted_guest_payment_types ?? []),
  ];

  return (
    <div className={styles['p-list__item']}>
      <div className={styles['p-list__item__ttl']}>
        <div className={styles['p-list__item__ttl__txt']}>
          <div>{t('Payment Type')}</div>
        </div>
      </div>
      <div className={styles['p-list__item__body']}>
        <Field type="checkbox" name="isProductPaymentTypeEnabled">
          {({ input: isProductPaymentTypeEnabled }) => {
            return (
              <Toggle
                {...isProductPaymentTypeEnabled}
                label={t("Specify product's payment type")}
              />
            );
          }}
        </Field>
        <div className={styles['p-bookingWidget__paymentType']}>
          <Field type="checkbox" name="isOnlinePaymentAvailable">
            {({ input }) => {
              if (!formState.values?.isProductPaymentTypeEnabled) {
                return null;
              }

              return (
                <Checkbox
                  checked={Boolean(input.checked)}
                  onChange={(e, v) => {
                    if (
                      !formState.values?.isLocalPaymentAvailable &&
                      input.checked
                    ) {
                      setOnlineError(t('Select a payment type'));
                    } else {
                      setOnlineError('');
                      setLocalError('');
                    }

                    (input as any).onChange(e, v);
                  }}
                  disabled={
                    !organizationPaymentTypes.includes('PAID_IN_FULL') ||
                    !formState.values?.isProductPaymentTypeEnabled
                  }
                  label={t('Online Payment')}
                  error={Boolean(onlineError)}
                  size="sm"
                />
              );
            }}
          </Field>
          <Field type="checkbox" name="isLocalPaymentAvailable">
            {({ input }) => {
              if (!formState.values?.isProductPaymentTypeEnabled) {
                return null;
              }

              return (
                <Checkbox
                  checked={Boolean(input.checked)}
                  onChange={(e, v) => {
                    if (
                      !formState.values?.isOnlinePaymentAvailable &&
                      input.checked
                    ) {
                      setLocalError(t('Select a payment type'));
                    } else {
                      setOnlineError('');
                      setLocalError('');
                    }

                    (input as any).onChange(e, v);
                  }}
                  disabled={
                    !organizationPaymentTypes.includes('PAY_ON_BOARD') ||
                    !formState.values?.isProductPaymentTypeEnabled
                  }
                  label={t('Local Payment')}
                  error={Boolean(localError)}
                  size="sm"
                />
              );
            }}
          </Field>
        </div>
        {Boolean(localError) && (
          <p className={baseStyles['u-error-msg']}>{localError}</p>
        )}
        {Boolean(onlineError) && (
          <p className={baseStyles['u-error-msg']}>{onlineError}</p>
        )}
      </div>
    </div>
  );
};
