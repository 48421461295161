import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getStartTimesFromRecurrence } from 'client/libraries/util/getStartTimesFromRecurrence';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';

type ItineraryItemInputProps = {
  name: string;
  startTimes: string[];
};

const ItineraryItemInput = ({ name }: ItineraryItemInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguage } = useTranslationTargetLanguage(t);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <>
      <CollapsibleBox title={t('Title')}>
        <TranslatedField name={`${name}.title`}>
          {({ input, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextField {...input} />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextField {...translationInput} />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>
      <CollapsibleBox title={t('Description')}>
        <TranslatedField name={`${name}.description`}>
          {({ input, translationInput }) => (
            <ul className={styles['p-list']}>
              <li className={styles['p-list__item']}>
                <div className={styles['p-list__item__ttl']}>
                  <p>{getLanguageName(lowerCaseIsoSourceLanguage, t)}</p>
                </div>
                <div className={styles['p-list__item__body']}>
                  <TextArea {...(input as any)} height={80} />
                </div>
              </li>
              {translationTargetLanguage && (
                <li className={styles['p-list__item']}>
                  <div className={styles['p-list__item__ttl']}>
                    <p>
                      {getLanguageName(
                        uppercaseIsoToLowercaseIso[translationTargetLanguage],
                        t
                      )}
                    </p>
                  </div>
                  <div className={styles['p-list__item__body']}>
                    <TextArea {...(translationInput as any)} height={80} />
                  </div>
                </li>
              )}
            </ul>
          )}
        </TranslatedField>
      </CollapsibleBox>
    </>
  );
};

export const ItineraryEditor = () => {
  const { t } = useTranslation();
  const editingProduct = useContext(EditingProductContext);
  const startTimes = getStartTimesFromRecurrence(
    editingProduct?.recurrence || []
  ).map((startTime) => startTime.hhMm || '');

  return (
    <FieldArray name="itinerary">
      {({ fields }) => (
        <>
          {fields.length !== 0 &&
            fields.map((name, idx) => (
              <CollapsibleBox
                key={name}
                title={t('Itinerary Item #{{itemNumber}}', {
                  itemNumber: idx + 1,
                })}
              >
                <ItineraryItemInput name={name} startTimes={startTimes} />
              </CollapsibleBox>
            ))}
        </>
      )}
    </FieldArray>
  );
};
