import { combineReducers } from 'redux';

import {
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
  SET_DASHBOARD_TAB,
  SET_DASHBOARD_ROW_COUNT,
  SET_DASHBOARD_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { TabType } from 'client/libraries/util/dashboard';

const tab = (state = 'REQUESTED', action: any) => {
  switch (action.type) {
    case SET_DASHBOARD_TAB:
      return action.tab;

    default:
      return state;
  }
};

const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_DASHBOARD_TAB:
      return 1;

    case SET_DASHBOARD_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const rowCount = (state = 10, action: any) => {
  switch (action.type) {
    case SET_DASHBOARD_ROW_COUNT:
      return action.rowCount;

    default:
      return state;
  }
};

type State = {
  tab: TabType;
  currentPage: number;
  rowCount: number;
};

const reducer = combineReducers<State>({
  tab,
  currentPage,
  rowCount,
});
export const dashboardControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
