import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import baseStyles from 'client/v3-base.module.css';
import styles from 'client/components/v3/Form/TextField.module.css';

type Props = {
  value: number;
  onChange: (arg0: number) => void;
  label: string;
  error?: string | boolean;
};
export const FloatInput = ({ value, onChange, label, error }: Props) => {
  const { t } = useTranslation();
  const [rawValue, setRawValue] = React.useState<string>(`${value}`);
  React.useEffect(() => {
    if (parseFloat(rawValue) !== value) {
      setRawValue(`${value}`);
    }
  }, [rawValue, value]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      // Check if the target element is outside of the wrapper element
      if (target instanceof Node && !inputRef?.current?.contains(target)) {
        setIsClicked(false);
      }
    };

    // Add event listeners to document for click outside
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Focus cursor to input whenever fieldset is clicked
  useEffect(() => {
    if (isClicked && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isClicked]);

  return (
    <div className={styles['c-fieldWrapper']}>
      {label}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <fieldset
          className={clsx(
            styles['c-field'],
            error && styles['is-error'],
            isHovered && styles['is-hover'],
            isClicked && styles['is-active'],
            (isClicked || value) && styles['is-input']
          )}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsClicked(true)}
        >
          <div className={styles['c-field__body']}>
            <label>
              <div className={styles['c-field__body__selected']}>
                <input
                  ref={inputRef}
                  className={styles['c-field__body__selected__input']}
                  value={rawValue}
                  onChange={(e) => {
                    const v = e.target.value;
                    if (typeof v === 'undefined' || v === '') {
                      setRawValue('0');
                      onChange(0);
                    }

                    const newValue = parseFloat(v);

                    if (isNaN(newValue)) {
                      return;
                    }

                    setRawValue(eatLeadingZeros(v));
                    onChange(newValue);
                  }}
                />
              </div>
            </label>
          </div>
        </fieldset>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            flexShrink: '10',
            whiteSpace: 'nowrap',
          }}
        >
          {t('% Discount')}
        </div>
      </div>
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </div>
  );
};

const eatLeadingZeros = (s: string): string => {
  let result = s;

  while (result.length > 1 && result[0] === '0') {
    result = result.substring(1);
  }

  return result;
};
