// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import compose from 'lodash/fp/compose';

import { replaceFullWidthCharactersWithHalfWidth } from 'client/libraries/util/replaceFullWidthCharactersWithHalfWidth';
import { EditBranchModal } from 'client/pages/Manage/BranchesTab/EditBranchModal';
import { DeleteBranchModal } from 'client/pages/Manage/BranchesTab/DeleteBranchModal';
import type { ReduxState } from 'client/reducers/index';
import { translatedReactTableProps } from 'client/libraries/util/coreutil';
import baseStyles from 'client/base.module.css';

import styles from './BranchesTab.module.css';

type OwnProps = {};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  columns: Object[],
};

class branchesTab extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: this.columns(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.t !== this.props.t) {
      this.setState({
        columns: this.columns(),
      });
    }
  }

  columns = () => {
    const { t } = this.props;
    let cols = [
      {
        Header: t('ID'),
        id: 'edit',
        accessor: 'id',
        Cell: (cellInfo) => (
          <React.Fragment key={cellInfo.value}>
            <EditBranchModal
              header={t('Edit branch information')}
              trigger={(props) => (
                <Icon link color="orange" name="edit" {...props} />
              )}
              branch={cellInfo.original}
              onSaveClick={() => {}}
            />
            <DeleteBranchModal
              trigger={(props) => (
                <Icon link color="red" name="delete" {...props} />
              )}
              branch={cellInfo.original}
            />
            {cellInfo.value}
          </React.Fragment>
        ),
      },
    ];
    cols = [
      ...cols,
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: t('Type'),
        accessor: 'type',
      },
    ];

    return cols;
  };

  getOrganizationTypeMap(organizationType: string) {
    const newMap = {};
    this.props.organizations.forEach((org) => {
      if (org.id_seed_email && org.type === organizationType) {
        newMap[org.id_seed_email] = [org.id, org.name];
      }
    });
    return newMap;
  }

  render() {
    const { loading, t } = this.props;
    const agentIdSeedsMap = this.getOrganizationTypeMap('AGENT');
    const supplierIdSeedsMap = this.getOrganizationTypeMap('SUPPLIER');

    return (
      <React.Fragment>
        <div className={styles['actions']}>
          <EditBranchModal
            header={t('Create new branch')}
            trigger={(props) => {
              return (
                <Button positive style={{ marginBottom: '10px' }} {...props}>
                  {t('Create new branch')}
                </Button>
              );
            }}
            onSaveClick={() => {}}
            agentIdSeedsMap={agentIdSeedsMap}
            supplierIdSeedsMap={supplierIdSeedsMap}
          />
          <a
            className={baseStyles['link']}
            href="/signup"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Sign up')}
          </a>
        </div>
        <ReactTable
          defaultFilterMethod={(filter, row) =>
            replaceFullWidthCharactersWithHalfWidth(row[filter.id] || '')
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          }
          filterable
          data={this.props.organizations}
          columns={this.state.columns}
          minRows={10}
          // Text
          previousText={t('Previous')}
          nextText={t('Next')}
          loadingText={t('Loading...')}
          noDataText={t('No rows found')}
          pageText={t('Page')}
          ofText={t('of')}
          rowsText={t('rows')}
          loading={loading}
          {...translatedReactTableProps(t)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  organizations: state.organizations.all,
  loading: state.organizations.loading,
});

export const BranchesTab = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, null),
  withTranslation()
)(branchesTab);
