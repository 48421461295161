import { createSelector } from 'reselect';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import type {
  DispatchCrewMember,
  DispatchResource,
} from 'shared/models/swagger';

export const dispatchSettingsSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return (organization && organization.dispatch_settings) || {};
  }
);
export const allDispatchCrewMembersSelector = createSelector(
  dispatchSettingsSelector,
  (dispatchSettings): DispatchCrewMember[] => {
    return dispatchSettings.dispatch_crew_members || [];
  }
);
export const allDispatchMiscResourcesSelector = createSelector(
  dispatchSettingsSelector,
  (dispatchSettings): DispatchResource[] => {
    return dispatchSettings.dispatch_misc_resources || [];
  }
);
export const allDispatchVehiclesSelector = createSelector(
  dispatchSettingsSelector,
  (dispatchSettings): DispatchResource[] => {
    return dispatchSettings.dispatch_vehicles || [];
  }
);
