import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers/index';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import {
  setETicketDailyUsesVisibleColumns,
  setETicketDailyUsesDisplaySettings,
} from 'client/actions/eTicketDailyUsesSettings';
import {
  getCheckinRecordColumns,
  getGuestCounts,
  sortAllUnitColumn,
  getGuestCountByGuestTypeCounts,
} from 'client/pages/ETicket/ETicketDailyUses/util';
import { Loading } from 'client/pages/Loading';
import { ETicketDailyUsesProductGroupTableList } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesProductGroupTableList';
import { ETicketDailyUsesTableSettingsModal } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesTableSettingsModal';
import { ETicketDailyUsesCSVDownloadModal } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesCSVDownloadModal';
import { eTicketDailyUsesVisibleColumnsSelector } from 'client/reducers/eTicketDailyUsesSettings';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import { defaultVisibleColumns } from 'client/libraries/util/eTicketDailyUsesSettings';
import { ToggleButton } from 'client/components/Form';
import 'react-table/react-table.css';
import baseStyles from 'client/base.module.css';
import type {
  ETicketDailyUse,
  ETicketDailyUsesExportParams,
} from 'shared/models/swagger';

import styles from './ETicketDailyUsesDetails.module.css';

type Props = {
  downloadParams: ETicketDailyUsesExportParams;
  eTicketDailyUsesList: ETicketDailyUse[];
};

export const ETicketDailyUsesDetails = ({
  downloadParams,
  eTicketDailyUsesList,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.eTicketDailyUses.loading
  );
  const displaySettings = useSelector(
    (state: ReduxState) => state.eTicketDailyUsesSettings.displaySettings
  );
  const visibleColumns = useSelector(eTicketDailyUsesVisibleColumnsSelector);

  const showByProductGroup = displaySettings.shownPerProductGroup;

  const allUnitsColumn: CustomTableColumn[] = [];
  const checkUnit: string[] = [];
  eTicketDailyUsesList
    .map((p) => {
      const guestSet = getGuestCounts(p.guests ?? []);
      return guestSet;
    })
    .forEach((g) => {
      Object.keys(g).forEach((key) => {
        if (checkUnit.indexOf(key) === -1) {
          checkUnit.push(key);
          allUnitsColumn.push({
            Header: key,
            id: key,
            width: 'short',
            accessor: (row: any) => row[`${key}`] ?? 0,
          });
        }
      });
    });
  sortAllUnitColumn(t, allUnitsColumn);

  const allCheckinRecordColumns = getCheckinRecordColumns(allUnitsColumn);
  React.useEffect(() => {
    dispatch(
      setETicketDailyUsesVisibleColumns(
        allCheckinRecordColumns.map((i) => i.id ?? '')
      )
    );
  }, [eTicketDailyUsesList]);

  const getCheckinRecords = () => {
    const shapingETicketDailyUses: Record<string, any>[] = [];
    eTicketDailyUsesList.forEach((dailyUseItem) => {
      dailyUseItem?.checkin_info?.checkin_records?.forEach((record) => {
        const guestSet =
          (record.guest_type_counts || []).length > 0
            ? getGuestCountByGuestTypeCounts(record.guest_type_counts)
            : getGuestCounts(dailyUseItem.guests ?? []);
        const pushData = {
          productId: dailyUseItem.product_id ?? '',
          productName:
            dailyUseItem.internal_product_name ??
            dailyUseItem.product_name ??
            '',
          reservationId: dailyUseItem.reservation_id ?? '',
          dateTimeUtc: record.date_time_utc ?? '',
          reservationStartTimeZone:
            dailyUseItem.reservation_start_time_zone ?? '',
          reservationSupplierReference:
            dailyUseItem.reservation_supplier_reference ?? '',
          reservationStartDateTimeUtc:
            dailyUseItem.reservation_start_date_time_utc ?? '',
          reservationAgentReference:
            dailyUseItem.reservation_agent_reference ?? '',
          reservationDisplayName: dailyUseItem.reservation_display_name ?? '',
          stubKey: record.stub_key ?? '',
          stubOptionText: record.stub_option_key ?? '',
          guests: dailyUseItem.guests ?? [],
        };
        Object.keys(guestSet).forEach((key) => {
          (pushData as any)[key] = guestSet[key];
        });
        shapingETicketDailyUses.push(pushData);
      });
    });
    shapingETicketDailyUses.sort((a, b) => {
      const moment1 = moment(a.dateTimeUtc);
      const moment2 = moment(b.dateTimeUtc);

      if (moment1.diff(moment2) > 0) {
        return 1;
      }
      if (moment1.diff(moment2) < 0) {
        return -1;
      }
      return 0;
    });
    return shapingETicketDailyUses;
  };

  const getColumns = (columnMask: string[]): CustomTableColumn[] => {
    return ['EDIT', ...(columnMask.filter((c) => c !== 'EDIT') as any)]
      .map((c) => allCheckinRecordColumns.find((col) => col.id === c) as any)
      .filter((c): c is Exclude<typeof c, undefined> => {
        return c !== undefined;
      });
  };

  const getCandidateColumns = (columnMask: string[]): CustomTableColumn[] => {
    return [...(columnMask.filter((c) => c !== 'EDIT') as any)]
      .map((c) => allCheckinRecordColumns.find((col) => col.id === c) as any)
      .filter((c): c is Exclude<typeof c, undefined> => {
        return c !== undefined;
      });
  };

  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1'],
            styles['manifest_detail__header__left']
          )}
        >
          <ToggleButton
            label={t('Show by product/group')}
            checked={showByProductGroup}
            onChange={() =>
              dispatch(
                setETicketDailyUsesDisplaySettings({
                  ...displaySettings,
                  shownPerProductGroup: !showByProductGroup,
                })
              )
            }
          />
        </div>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <ETicketDailyUsesCSVDownloadModal
            downloadParams={{
              ...downloadParams,
              columns: visibleColumns.filter(
                (c) => defaultVisibleColumns.indexOf(c) !== -1
              ) as any,
              unit_columns: visibleColumns.filter(
                (c) => checkUnit.indexOf(c) !== -1
              ),
              sort_order_csv_header_column: visibleColumns.filter(
                (c) => c !== 'EDIT'
              ),
            }}
          />
          <ETicketDailyUsesTableSettingsModal
            columnsToShow={getCandidateColumns(visibleColumns)}
            allCandidateColumns={allCheckinRecordColumns.filter(
              (c) => c.id !== 'EDIT'
            )}
          />
        </div>
      </div>
      {showByProductGroup ? (
        <ETicketDailyUsesProductGroupTableList
          items={getCheckinRecords()}
          columns={getColumns(visibleColumns)}
        />
      ) : (
        <CustomTable
          items={getCheckinRecords()}
          columns={getColumns(visibleColumns)}
          usePaging={true}
        />
      )}
      {loading && <Loading />}
    </>
  );
};
