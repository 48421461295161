import * as React from 'react';

export interface SeatCalendarEditParams {
  selectedEquipmentInstanceIds: string[];
  selectEquipmentInstanceIds: (...ids: string[]) => void;
  deselectEquipmentInstanceIds: (...ids: string[]) => void;
  calendarEditModeIsActive: boolean;
  setCalendarEditModeIsActive: (b: boolean) => void;
}

// CalendarEditContext can be used to pass a the calendar edit information down the component tree.
export const SeatCalendarEditContext =
  React.createContext<SeatCalendarEditParams>({
    selectedEquipmentInstanceIds: [],
    calendarEditModeIsActive: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCalendarEditModeIsActive: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    selectEquipmentInstanceIds: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    deselectEquipmentInstanceIds: () => {},
  });
