import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'semantic-ui-react';
import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';

import { activeUserSelector } from 'client/reducers/user';
import { s3MediaStorage } from 'client/libraries/s3';
import baseStyles from 'client/v3-base.module.css';
import { DraggableInquiryAttachment } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/DraggableInquiryAttachment';

import styles from './InquiryAttachmentEditor.module.css';

export interface InquiryAttachment {
  url: string;
  filename: string;
}

interface Props {
  attachments: InquiryAttachment[];
  onChange: (value: InquiryAttachment[]) => void;
}

export const InquiryAttachmentsEditor = ({ attachments, onChange }: Props) => {
  const { t } = useTranslation();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>('');
  const imageFileInputRef = useRef<HTMLInputElement | null>(null);
  const activeUser = useSelector(activeUserSelector);
  const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      uploadFiles(acceptedFiles);
    },
    maxSize: 10 * 1024 * 1024,
    // 10MB
    accept: acceptedFileTypes,
  });

  const handleAddImageClick = () => {
    if (imageFileInputRef?.current) {
      imageFileInputRef?.current.click();
    }
  };

  const handleFileInputChange = async (e: any) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));
      // Reset file input so that 'onChange' is called every time that the user selects something in the file dialog
      e.target.value = '';
    }
  };

  const handleMoveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = attachments[dragIndex];
      const newAttachments = [...attachments];
      newAttachments.splice(dragIndex, 1);
      newAttachments.splice(hoverIndex, 0, dragImage);
      onChange(newAttachments);
    },
    [attachments]
  );

  const uploadFiles = async (files: File[]) => {
    const newAttachments: InquiryAttachment[] = [];
    const organizationId = activeUser?.organization_id;

    if (!organizationId) {
      return;
    }

    setUploadError('');
    setUploadInProgress(true);
    const uploadError = '';

    for (const file of files) {
      try {
        const filename = `${uuidv4()}/${file.name}`;
        const objName = `${organizationId}/${filename}`;
        await s3MediaStorage.put(objName, file);
        const url = await s3MediaStorage.get(objName);
        // Remove pre-signed suffix
        const uploadedFileURL = url.split('?')[0];
        newAttachments.push({
          url: uploadedFileURL,
          filename: file.name,
        });
        onChange([...attachments, ...newAttachments]);
      } catch (err) {
        /* TODO */
      }
    }

    if (uploadError) {
      setUploadError(uploadError);
    }

    setUploadInProgress(false);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <ul className={styles['c-attachmentEditor__input']}>
        {attachments.map((attachment, idx) => (
          <li key={`${attachment.url}-idx`} className={styles['file']}>
            <DraggableInquiryAttachment
              attachment={attachment}
              index={idx}
              onRemove={() =>
                onChange(attachments.filter((i) => i.url !== attachment.url))
              }
              onMoveImage={handleMoveImage}
            />
          </li>
        ))}

        <li className={styles['add']} onClick={handleAddImageClick}>
          <a
            {...getRootProps()}
            className={clsx(isDragActive && styles['dragActive'])}
            style={{
              display: 'none',
            }}
          ></a>
          <i className="c-icon-outline-general-plus-circle"></i>
          <p style={{ margin: 0, marginTop: '8px' }}>{t('Add attachment')}</p>
          <input
            {...getInputProps()}
            multiple
            ref={imageFileInputRef}
            id="image-file-input"
            type="file"
            name="name"
            accept={acceptedFileTypes.join(',')}
            style={{
              display: 'none',
            }}
            onChange={handleFileInputChange}
          />
        </li>
      </ul>
      {uploadError && (
        <p className={baseStyles['u-error-msg']}>{uploadError}</p>
      )}

      <div className={styles['c-attachmentEditor__helperText']}>
        <p>{t('* jpg/png/pdf format')}</p>
        <p>{t('* drag-and-drop to reorder')}</p>
      </div>
    </>
  );
};
