import { TranslateFuncType } from 'client/components/Translate';
import { RelativeDeadline } from 'client/libraries/util/productShape';

export const getDeadlineDescription = (
  relativeDeadline: RelativeDeadline,
  t: TranslateFuncType
): string => {
  if (relativeDeadline.deadlineType === 'DAY') {
    return t('{{count}} days before, {{timeOfDay}}', {
      count: relativeDeadline?.daysBefore,
      timeOfDay: relativeDeadline?.timeOfDay,
    });
  } else if (relativeDeadline.deadlineType === 'HOUR') {
    return t('{{count}} hours before', {
      count: relativeDeadline?.hoursBefore,
    });
  }

  return '';
};
