import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import type { TranslateFuncType } from 'client/components/Translate';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { summariesSelector } from 'client/reducers/products';
import type { DispatchResource } from 'shared/models/swagger';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';

const getScheduleSelectOptions = (
  resource: DispatchResource | null,
  t: TranslateFuncType
) => {
  const options: { value: string; text: string }[] = [];
  (resource?.availability?.schedules ?? []).forEach((schedule, idx) => {
    options.push({
      value: String(idx),
      text: getOptionText(
        schedule?.start_date_local ?? null,
        schedule?.end_date_local ?? null,
        t
      ),
    });
  });

  return options;
};

const getOptionText = (
  startDateLocal: string | null,
  endDateLocal: string | null,
  t: TranslateFuncType
) => {
  if (startDateLocal && endDateLocal) {
    return `${startDateLocal} ~ ${endDateLocal}`;
  }
  if (startDateLocal) {
    return `${startDateLocal} ~ ${t('Unknown')}`;
  }
  if (endDateLocal) {
    return `${t('Unknown')} ~ ${endDateLocal}`;
  }

  return '~';
};

export const VehicleAvailabilityDisplayBox = ({
  resourceKey,
}: {
  resourceKey: string;
}) => {
  const { t } = useTranslation();
  const resources = useSelector(allDispatchVehiclesSelector);
  const products = useSelector(summariesSelector);

  const [scheduleIdx, setActiveScheduleIndex] = useState<string>('0');
  const [scheduleSettings, setScheduleSettings] = useState<
    { key: string; value: string }[]
  >([]);

  const resource = resources.find((r) => r.key === resourceKey);

  useEffect(() => {
    setActiveScheduleIndex('0');
  }, [resources]);

  useEffect(() => {
    const schedule = resource?.availability?.schedules?.[Number(scheduleIdx)];
    if (schedule) {
      const settings: {
        key: string;
        value: string;
      }[] = [];

      if ((schedule?.departure_time_capacities || []).length > 0) {
        schedule?.departure_time_capacities?.forEach((dtc) => {
          settings.push({
            key: `[${t('Departure time')}]`,
            value: dtc?.time_local ?? '',
          });
          settings.push({
            key: `[${t('Capacity')}]`,
            value: String(dtc?.capacity ?? 0),
          });
          settings.push({
            key: `[${t('Product')}]`,
            value: (dtc.product_start_times ?? [])
              .map(
                (product_start_time) =>
                  getDisplayProductName(
                    products.find((p) => p.id === product_start_time.product_id)
                  ) +
                  ' ' +
                  product_start_time.start_time_local
              )
              .join(', '),
          });
        });
      }

      setScheduleSettings(settings);
    }
  }, [scheduleIdx, products, resource, t]);

  const options = getScheduleSelectOptions(resource ?? null, t);

  return (
    <>
      {resource?.availability && (
        <div className={styles['p-manifests__vehicleResourceColumn']}>
          <SingleDropdown
            options={options}
            selectedOption={scheduleIdx}
            onChange={(value) => setActiveScheduleIndex(value)}
          />
          <ul>
            {scheduleSettings.map((f) => (
              <li key={f.key}>{`${f.key}: ${f.value}`}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
