import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { AccessReportDataSet } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import styles from './AccessReport.module.css';

type Props = {
  dataSets: AccessReportDataSet[];
};
export const CompareTablePane = ({ dataSets }: Props) => {
  const { t } = useTranslation();

  if (dataSets?.length !== 2) {
    return (
      <div className={clsx(componentStyles['c-table-nowrap'])}>
        Invalid data
      </div>
    );
  }

  const datas = dataSets.map((dataSet) => {
    let totalVisitCount = 0;
    let totalUserCount = 0;
    let totalReservationCount = 0;
    (dataSet?.items || []).forEach((item) => {
      totalVisitCount += item?.visit_count || 0;
      totalUserCount += item?.user_count || 0;
      totalReservationCount += item?.reservation_count || 0;
    });
    const ratio = totalUserCount ? totalReservationCount / totalUserCount : 0;
    return {
      start: dataSet.range?.start,
      end: dataSet.range?.end,
      totalVisitCount,
      totalUserCount,
      totalReservationCount,
      ratio,
    };
  });

  const calcRatio = (compare: number, base: number): string => {
    return `${compare - base > 0 ? '+' : ''}${(
      ((compare - base) / base) *
      100
    ).toFixed(2)} %`;
  };

  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Date')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('visitCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('userCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('reservationCount')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Conversion Rate')}
            </th>
          </tr>
          {datas.map((data, i) => {
            return (
              <tr key={i}>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['center-align']
                  )}
                >
                  {data.start} ~ {data.end}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['center-align']
                  )}
                >
                  {data.totalVisitCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {data.totalUserCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {data.totalReservationCount}
                </td>
                <td
                  className={clsx(
                    baseStyles['base-t-min-32'],
                    styles['right-align']
                  )}
                >
                  {(data.ratio * 100).toFixed(1)} %
                </td>
              </tr>
            );
          })}
          <tr>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            ></td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {calcRatio(datas[1].totalVisitCount, datas[0].totalVisitCount)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {calcRatio(datas[1].totalUserCount, datas[0].totalUserCount)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {calcRatio(
                datas[1].totalReservationCount,
                datas[0].totalReservationCount
              )}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
