import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import {
  FalconUIRenderingContext,
  ImageTile,
  Section,
} from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { Add } from 'client/components/Icons/Add';

import { SectionTitleEditor } from '../SectionTitleEditor';
import { SectionBackgroundEditor } from '../SectionBackgroundEditor';
import { getDefaultImageTile } from '../../useTemplateOptions';

import { DraggableImageTile } from './DraggableImageTile';

interface Props {
  name: string;
}

export const EditImageTilesButton = ({ name }: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const form = useForm();

  const {
    imageTiles,
    title,
    titleStyle,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
  } = _.get(form.getState().values, name) as Section;

  const topPageCtx = React.useContext(FalconUIRenderingContext);

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Edit Image Tiles')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              imageTiles,
              title,
              titleStyle,
              useBackgroundImage,
              backgroundColor,
              backgroundImageUrl,
            }}
            onSubmit={(values: Section) => {
              form.change(name, {
                type: 'IMAGE_TILES',
                imageTiles: values.imageTiles,
                title: values.title,
                titleStyle: values.titleStyle,
                useBackgroundImage: values.useBackgroundImage,
                backgroundColor: values.backgroundColor,
                backgroundImageUrl: values.backgroundImageUrl,
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <SectionTitleEditor />
                  <SectionBackgroundEditor />
                  <Field name="imageTiles">
                    {({ input }: FieldRenderProps<ImageTile[]>) => (
                      <>
                        {input.value?.map((imageItle, idx) => (
                          <DraggableImageTile
                            key={imageItle.key}
                            items={input.value}
                            onItemsChange={input.onChange}
                            index={idx}
                          />
                        ))}
                        <Add
                          onClick={() => {
                            input.onChange([
                              ...input.value,
                              getDefaultImageTile(topPageCtx.t),
                            ]);
                          }}
                        />
                      </>
                    )}
                  </Field>
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit">{t('Update')}</Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
