import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  title: string;
  description: string;
  qrCodeLinks: {
    productId: string;
    stubKey: string;
    stubOptionKey: string;
  }[];
}

export const getInitialValues = (
  eTicketQrCode: Swagger.ETicketQrCode | null | undefined
): FormValues => {
  return {
    title: eTicketQrCode?.title ?? '',
    description: eTicketQrCode?.description ?? '',
    qrCodeLinks: (eTicketQrCode?.qr_code_links ?? []).map((link) => ({
      productId: link.product_id ?? '',
      stubKey: link.stub_key ?? '',
      stubOptionKey: link.stub_option_key ?? '',
    })),
  };
};

export const convertFormValuesToSwagger = (
  formValues: FormValues
): Swagger.ETicketQrCode => {
  return {
    title: formValues.title,
    description: formValues.description,
    qr_code_links: formValues.qrCodeLinks.map((link) => ({
      product_id: link.productId,
      stub_key: link.stubKey,
      stub_option_key: link.stubOptionKey,
    })),
  };
};
