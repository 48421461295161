import moment from 'moment-timezone';

export const dateTimesSpanMultipleDays = (
  dateTimes: string[],
  timezone: string
): boolean => {
  return (
    new Set(
      dateTimes.map((dateTime) =>
        moment.tz(dateTime, timezone).format('YYYY-MM-DD')
      )
    ).size > 1
  );
};
