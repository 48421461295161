import * as React from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';
import _ from 'lodash';

import { Select } from 'client/components/Form';
import {
  productOptionsSelector,
  summariesSelector,
} from 'client/reducers/products';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import baseStyles from 'client/base.module.css';

interface Props {
  name: string;
  onRemoveClick: () => void;
  onEditClick: () => void;
}

export const StubItem = ({ name, onRemoveClick, onEditClick }: Props) => {
  const productSummaries = useSelector(summariesSelector);

  const productOptions = useSelector(productOptionsSelector);

  const formState = useFormState();

  const selectedProductId = _.get(formState.values, `${name}.productId`);
  const selectedStubKey = _.get(formState.values, `${name}.stubKey`);

  const stubSelectorOptions = React.useMemo(() => {
    if (!selectedProductId) {
      return [];
    }

    const product = productSummaries.find((p) => p.id === selectedProductId);
    if (!product) {
      return [];
    }

    return (product?.qr_checkin_settings?.stubs ?? []).map((s) => ({
      value: s.key ?? '',
      text: s.text ?? '',
    }));
  }, [selectedProductId, productSummaries]);

  const stubOptionSelectorOptions = React.useMemo(() => {
    if (!selectedProductId) {
      return [];
    }

    if (!selectedStubKey) {
      return [];
    }

    const product = productSummaries.find((p) => p.id === selectedProductId);
    if (!product) {
      return [];
    }

    const stub = (product?.qr_checkin_settings?.stubs ?? []).find(
      (s) => s.key === selectedStubKey
    );
    if (!stub) {
      return [];
    }

    return (stub?.options ?? []).map((o) => ({
      value: o.key ?? '',
      text: o.text ?? '',
    }));
  }, [selectedProductId, selectedStubKey, productSummaries]);

  return (
    <>
      <th>
        <div className={baseStyles['base-btn']}>
          <DeleteIcon onClick={() => onRemoveClick()} />
          <EditIcon
            onClick={() => {
              onEditClick();
            }}
          />
        </div>
      </th>
      <td>
        <Field name={`${name}.productId`}>
          {({ input }) => (
            <Select
              options={productOptions}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </td>
      <td>
        {stubSelectorOptions.length > 0 && (
          <Field name={`${name}.stubKey`}>
            {({ input }) => (
              <Select
                options={stubSelectorOptions}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        )}
      </td>
      <td>
        {stubOptionSelectorOptions.length > 0 && (
          <Field name={`${name}.stubOptionKey`}>
            {({ input }) => (
              <Select
                options={stubOptionSelectorOptions}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        )}
      </td>
    </>
  );
};
