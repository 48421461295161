import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

import { EditDigitalGuidanceStampRallyGiftModal } from './EditDigitalGuidanceStampRallyGiftModal';

export const DigitalGuidanceStampRallyGiftListEditor = () => {
  const { t } = useTranslation();

  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [openGift, setOpenGift] = React.useState<string | null>(null);

  return (
    <>
      <Box mt={2}>
        <FieldWrapper label={t('Gifts')}>
          <Field name="gifts">
            {({ input }) => (
              <>
                <Button
                  size="middle"
                  style="green"
                  onClick={() => {
                    const previousLength = input.value.length;
                    input.onChange([...input.value, { key: uuidv4() }]);
                    setOpenGift(`gifts.${previousLength}`);
                  }}
                >
                  {t('Create New Guidance Stamp Rally Gift')}
                </Button>
                <EditDeleteButtonDndList
                  items={input.value?.map((item: any) => ({
                    key: item?.key ?? '',
                    text: item?.title ?? '',
                  }))}
                  setItems={(items) => {
                    input.onChange(
                      items.map((item) =>
                        input.value?.find(
                          (guidancePageItem: any) =>
                            guidancePageItem.key === item.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setOpenGift(`gifts.${idx}`);
                  }}
                />

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete gift')}
                    content={t('Are you sure you want to delete gift?')}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {openGift && (
                  <EditDigitalGuidanceStampRallyGiftModal
                    name={openGift}
                    open={openGift != null}
                    onClose={() => setOpenGift(null)}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>
      </Box>
    </>
  );
};
