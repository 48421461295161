import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { ReduxState } from 'client/reducers';

import styles from './PageViewEvent.module.css';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const PageViewEvent = ({ event, durationInSeconds }: Props) => {
  const { t } = useTranslation();

  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const match = event.page_path?.match(
    /products\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/
  );
  const product =
    match && match.length > 1
      ? allProducts.find((p) => p.id === match[1])
      : null;
  const productThumbnailUrl = product?.media?.find(
    (m) => m.type === 'IMAGE'
  )?.url;

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['timeline']}>
          <div className={styles['icon']}>
            <i className="c-icon-solid-general-eye"></i>
          </div>

          <div className={styles['duration']}>
            {formattedDuration(durationInSeconds, t)}
          </div>
        </div>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>{t('Page View')}</div>
            <div className={styles['timestamp']}>
              {moment(event.event_date_time_utc).format('H:mm:ss')}
            </div>
          </div>
          <div className={styles['body']}>
            <div className={styles['body-inner']}>
              {productThumbnailUrl && (
                <img
                  loading="lazy"
                  src={productThumbnailUrl}
                  className={styles['thumbnail']}
                />
              )}
              <div className={styles['description']}>
                <div className={styles['page-title']}>
                  {event.page_title || event.page_path}
                </div>
                <a
                  className={styles['link']}
                  href={event.page_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="c-icon-outline-general-link-external-02"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
