import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_DIGITAL_MAP_FAILURE,
  CREATE_DIGITAL_MAP_REQUEST,
  CREATE_DIGITAL_MAP_SUCCESS,
  DELETE_DIGITAL_MAP_FAILURE,
  DELETE_DIGITAL_MAP_REQUEST,
  DELETE_DIGITAL_MAP_SUCCESS,
  FETCH_DIGITAL_MAPS_FAILURE,
  FETCH_DIGITAL_MAPS_REQUEST,
  FETCH_DIGITAL_MAPS_SUCCESS,
  FETCH_DIGITAL_MAP_FAILURE,
  FETCH_DIGITAL_MAP_REQUEST,
  FETCH_DIGITAL_MAP_SUCCESS,
  UPDATE_DIGITAL_MAP_FAILURE,
  UPDATE_DIGITAL_MAP_REQUEST,
  UPDATE_DIGITAL_MAP_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  DigitalMap,
  CreateDigitalMapResponse,
  ListDigitalMapsResponse,
  UpdateDigitalMapResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_DIGITAL_MAPS_FAILURE:
    case FETCH_DIGITAL_MAP_FAILURE:
    case CREATE_DIGITAL_MAP_FAILURE:
    case UPDATE_DIGITAL_MAP_FAILURE:
    case DELETE_DIGITAL_MAP_FAILURE:
      return action.error;

    case FETCH_DIGITAL_MAPS_SUCCESS:
    case FETCH_DIGITAL_MAP_SUCCESS:
    case CREATE_DIGITAL_MAP_SUCCESS:
    case UPDATE_DIGITAL_MAP_SUCCESS:
    case DELETE_DIGITAL_MAP_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_DIGITAL_MAPS_REQUEST:
    case FETCH_DIGITAL_MAP_REQUEST:
    case CREATE_DIGITAL_MAP_REQUEST:
    case UPDATE_DIGITAL_MAP_REQUEST:
    case DELETE_DIGITAL_MAP_REQUEST:
      return true;

    case FETCH_DIGITAL_MAPS_FAILURE:
    case FETCH_DIGITAL_MAP_FAILURE:
    case CREATE_DIGITAL_MAP_FAILURE:
    case UPDATE_DIGITAL_MAP_FAILURE:
    case DELETE_DIGITAL_MAP_FAILURE:
    case FETCH_DIGITAL_MAPS_SUCCESS:
    case FETCH_DIGITAL_MAP_SUCCESS:
    case CREATE_DIGITAL_MAP_SUCCESS:
    case UPDATE_DIGITAL_MAP_SUCCESS:
    case DELETE_DIGITAL_MAP_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: DigitalMap[] = [], action: any): DigitalMap[] => {
  switch (action.type) {
    case FETCH_DIGITAL_MAPS_SUCCESS: {
      const response = action.response as ListDigitalMapsResponse;
      return response.digital_maps;
    }

    case FETCH_DIGITAL_MAP_SUCCESS: {
      const response = action.response as DigitalMap;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_DIGITAL_MAP_SUCCESS: {
      const response = action.response as CreateDigitalMapResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_DIGITAL_MAP_SUCCESS: {
      const response = action.response as UpdateDigitalMapResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_DIGITAL_MAP_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedDigitalMap = (
  state: DigitalMap | null = null,
  action: any
) => {
  switch (action.type) {
    case CREATE_DIGITAL_MAP_SUCCESS: {
      const response = action.response as CreateDigitalMapResponse;
      return response;
    }

    default:
      return state;
  }
};

export const digitalMaps = combineReducers({
  error,
  loading,
  all,
  lastCreatedDigitalMap,
});
