import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_SITE_CONTROL_WIDGET_FAILURE,
  CREATE_SITE_CONTROL_WIDGET_REQUEST,
  CREATE_SITE_CONTROL_WIDGET_SUCCESS,
  DELETE_SITE_CONTROL_WIDGET_FAILURE,
  DELETE_SITE_CONTROL_WIDGET_REQUEST,
  DELETE_SITE_CONTROL_WIDGET_SUCCESS,
  FETCH_SITE_CONTROL_WIDGETS_FAILURE,
  FETCH_SITE_CONTROL_WIDGETS_REQUEST,
  FETCH_SITE_CONTROL_WIDGETS_SUCCESS,
  FETCH_SITE_CONTROL_WIDGET_FAILURE,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS,
  FETCH_SITE_CONTROL_WIDGET_REQUEST,
  FETCH_SITE_CONTROL_WIDGET_SUCCESS,
  UPDATE_SITE_CONTROL_WIDGET_FAILURE,
  UPDATE_SITE_CONTROL_WIDGET_REQUEST,
  UPDATE_SITE_CONTROL_WIDGET_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  SiteControlWidget,
  CreateSiteControlWidgetResponse,
  ListSiteControlWidgetsResponse,
  UpdateSiteControlWidgetResponse,
  SiteControlWidgetReportDataSet,
  GetSiteControlWidgetReportDataResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_SITE_CONTROL_WIDGETS_FAILURE:
    case FETCH_SITE_CONTROL_WIDGET_FAILURE:
    case CREATE_SITE_CONTROL_WIDGET_FAILURE:
    case UPDATE_SITE_CONTROL_WIDGET_FAILURE:
    case DELETE_SITE_CONTROL_WIDGET_FAILURE:
      return action.error;

    case FETCH_SITE_CONTROL_WIDGETS_SUCCESS:
    case FETCH_SITE_CONTROL_WIDGET_SUCCESS:
    case CREATE_SITE_CONTROL_WIDGET_SUCCESS:
    case UPDATE_SITE_CONTROL_WIDGET_SUCCESS:
    case DELETE_SITE_CONTROL_WIDGET_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_SITE_CONTROL_WIDGETS_REQUEST:
    case FETCH_SITE_CONTROL_WIDGET_REQUEST:
    case CREATE_SITE_CONTROL_WIDGET_REQUEST:
    case UPDATE_SITE_CONTROL_WIDGET_REQUEST:
    case DELETE_SITE_CONTROL_WIDGET_REQUEST:
      return true;

    case FETCH_SITE_CONTROL_WIDGETS_FAILURE:
    case FETCH_SITE_CONTROL_WIDGET_FAILURE:
    case CREATE_SITE_CONTROL_WIDGET_FAILURE:
    case UPDATE_SITE_CONTROL_WIDGET_FAILURE:
    case DELETE_SITE_CONTROL_WIDGET_FAILURE:
    case FETCH_SITE_CONTROL_WIDGETS_SUCCESS:
    case FETCH_SITE_CONTROL_WIDGET_SUCCESS:
    case CREATE_SITE_CONTROL_WIDGET_SUCCESS:
    case UPDATE_SITE_CONTROL_WIDGET_SUCCESS:
    case DELETE_SITE_CONTROL_WIDGET_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: SiteControlWidget[] = [],
  action: any
): SiteControlWidget[] => {
  switch (action.type) {
    case FETCH_SITE_CONTROL_WIDGETS_SUCCESS: {
      const response = action.response as ListSiteControlWidgetsResponse;
      return response.site_control_widgets;
    }

    case FETCH_SITE_CONTROL_WIDGET_SUCCESS: {
      const response = action.response as SiteControlWidget;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_SITE_CONTROL_WIDGET_SUCCESS: {
      const response = action.response as CreateSiteControlWidgetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_SITE_CONTROL_WIDGET_SUCCESS: {
      const response = action.response as UpdateSiteControlWidgetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_SITE_CONTROL_WIDGET_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedSiteControlWidget = (
  state: SiteControlWidget | null = null,
  action: any
): SiteControlWidget | null => {
  switch (action.type) {
    case CREATE_SITE_CONTROL_WIDGET_SUCCESS: {
      const response = action.response as CreateSiteControlWidgetResponse;
      return response;
    }
    default:
      return state;
  }
};

const reportData = (
  state: SiteControlWidgetReportDataSet[] = [],
  action: any
) => {
  switch (action.type) {
    case FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetSiteControlWidgetReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const reportDataLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST:
      return true;
    case FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS:
    case FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export const siteControlWidgets = combineReducers({
  error,
  lastCreatedSiteControlWidget,
  loading,
  all,
  reportData,
  reportDataLoading,
});
