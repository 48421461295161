import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { fetchAnalyticsCustomers } from 'client/actions/analyticsCustomers';
import { ReduxState } from 'client/reducers';
import { AnalyticsCustomer } from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';
import { Button } from 'client/components/v3/Common/Button';
import { journeyAnalyticsSearchPresetsSelector } from 'client/reducers/supplierSettings';
import { fetchMarketingAutomationContents } from 'client/actions/marketingAutomationContents';
import { fetchMarketingAutomationCampaigns } from 'client/actions/marketingAutomationCampaigns';

import { formatLandingSource } from '../JourneyAnalyticsCustomerDetails/util';

import styles from './JourneyAnalyticsCustomerList.module.css';
import { ColumnSettingsModal } from './ColumnSettingsModal';
import { SearchForm } from './SearchForm/SearchForm';

export type ColumnId =
  | 'name'
  | 'customerCategory'
  | 'tags'
  | 'sessionCount'
  | 'pageViewCount'
  | 'totalTimeSeconds'
  | 'region';

export const useColumns = (): ColumnType<AnalyticsCustomer>[] => {
  const { t } = useTranslation();
  const settings = useSelector(
    (state: ReduxState) => state.supplierSettings.journeyAnalytics
  );
  const autoTags = settings?.autotags ?? [];

  return [
    {
      Header: t('Name/Cookie ID'),
      id: 'name',
      width: 300,
      accessor: (customer) => (
        <Link to={`/onsitejourneyanalytics/customers/${customer.id}`}>
          {customer.customer_display_name || customer.id?.substring(0, 8)}
        </Link>
      ),
    },
    {
      Header: t('Classification'),
      id: 'customerCategory',
      width: 150,
      accessor: (customer) =>
        customer.classification === 'BOOKED' ? (
          <Badge label={t('Booked')} color="success" />
        ) : (
          <Badge label={t('Not Booked')} color="orange" />
        ),
    },
    {
      Header: t('Tags'),
      id: 'tags',
      accessor: (customer) => (
        <div className={styles['tags']}>
          {customer.tags?.map((tag) => {
            const color = autoTags.find((t) => t.tag_name === tag)?.tag_color;

            if (!color) {
              return null;
            }

            return <Badge key={tag} label={tag} color={color as BadgeColor} />;
          })}
        </div>
      ),
    },
    {
      Header: t('Landing Source'),
      id: 'landingSource',
      width: 250,
      accessor: (customer) =>
        formatLandingSource(customer.landing_source || ''),
    },
    {
      Header: t('Sessions'),
      id: 'sessionCount',
      accessor: (customer) => customer?.session_count || 0,
    },
    {
      Header: t('Page views'),
      id: 'pageViewCount',
      accessor: (customer) => customer?.page_view_count || 0,
    },
    {
      Header: t('Total time'),
      id: 'totalTimeSeconds',
      accessor: (customer) =>
        formattedDuration(customer?.total_time_seconds ?? 0, t),
    },
    ...(config.enableJourneyRegion
      ? [
          {
            Header: t('Geo Location'),
            id: 'region',
            accessor: (customer: AnalyticsCustomer) => customer?.region,
          },
        ]
      : []),
  ];
};

export const OnSiteJourneyAnalyticsCustomerList = () => {
  const presets = useSelector(journeyAnalyticsSearchPresetsSelector);
  const allColumns = useColumns();
  const [showColumnEditModal, setShowColumnEditModal] = React.useState(false);
  const [visibleColumns, setVisibleColumns] = React.useState<ColumnId[]>([
    'name',
    'customerCategory',
    'tags',
    'sessionCount',
    'pageViewCount',
    'totalTimeSeconds',
  ]);

  const [selectedPresetName, setSelectedPresetName] =
    React.useState<string>('');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = React.useState<
    'ALL' | 'BOOKED' | 'NOT_BOOKED'
  >('ALL');

  const tabOptions: { value: 'ALL' | 'BOOKED' | 'NOT_BOOKED'; text: string }[] =
    [
      {
        value: 'ALL',
        text: t('All'),
      },
      { value: 'BOOKED', text: t('Booked') },
      { value: 'NOT_BOOKED', text: t('Not Booked') },
    ];

  const selectedPreset = presets.find(
    (preset) => preset.preset_name === selectedPresetName
  );

  React.useEffect(() => {
    if (selectedPreset) {
      dispatch(fetchAnalyticsCustomers(selectedPreset.condition_groups ?? []));
    } else {
      dispatch(fetchAnalyticsCustomers([]));
    }
  }, [selectedPreset]);

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationContents());
    dispatch(fetchMarketingAutomationCampaigns());
  }, []);

  const customers = useSelector(
    (state: ReduxState) => state.analyticsCustomers.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.analyticsCustomers.loading
  );

  const filteredCustomers = _.orderBy(
    customers.filter((customer) => {
      if (activeTab === 'ALL') {
        return true;
      }
      return customer.classification === activeTab;
    }),
    ['last_event_date_time_utc'],
    'desc'
  );

  const columns = visibleColumns.map(
    (col) =>
      allColumns.find((c) => c.id === col) as ColumnType<AnalyticsCustomer>
  );

  return (
    <Box ml={-4} mr={-4} mt={-4}>
      <PageHeader title={t('Customer List')} />
      <Box pl={8} pr={8}>
        <SearchForm
          selectedPresetName={selectedPresetName}
          setSelectedPresetName={setSelectedPresetName}
        />
        <Box mt={4}>
          <PagedGenericTable
            columns={columns}
            allItems={filteredCustomers}
            hideScrollButtons
            headerBar={
              <div className={styles['header-bar']}>
                <ul className={styles['tab']}>
                  {tabOptions.map((option) => (
                    <li
                      key={option.value}
                      className={clsx(
                        activeTab === option.value && styles['is-active']
                      )}
                      onClick={() => setActiveTab(option.value)}
                    >
                      {option.text}
                    </li>
                  ))}
                </ul>
                <div className={styles['buttons']}>
                  <Button
                    onClick={() => setShowColumnEditModal(true)}
                    text={t('Edit columns')}
                    uiType="bg"
                    size="md"
                    color="white"
                    iconBeforeText={
                      <i className="c-icon-outline-layout-columns-03"></i>
                    }
                  />
                </div>
              </div>
            }
            loading={loading}
          />
        </Box>
      </Box>
      {showColumnEditModal && (
        <ColumnSettingsModal
          onClose={() => setShowColumnEditModal(false)}
          columns={visibleColumns}
          onColumnsChange={(newColumns) => setVisibleColumns(newColumns)}
        />
      )}
    </Box>
  );
};
