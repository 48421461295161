import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { deleteProduct } from 'client/actions/products';
import type { Product } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  product: Product;
  onClose: () => void;
};

export const DeleteProductModal = ({ product, onClose }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return product ? (
    <Modal
      title={t('Delete product')}
      open={true}
      onClose={onClose}
      insertAtRoot={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
            color="white"
          />
          <Button
            text={t('OK')}
            onClick={() => {
              dispatch(deleteProduct(product.id));
              onClose();
            }}
            color="red"
          />
        </>
      }
    >
      {t('Are you sure you want to delete "{{productName}}"?', {
        productName: product.product_name,
      })}
    </Modal>
  ) : null;
};
