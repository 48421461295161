import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { essentialPagesSelector } from 'client/reducers/essentialPages';
import type { ReduxState } from 'client/reducers';
import type {
  EssentialPages,
  AboutUsSettings,
  FaqSettings,
  Covid19Settings,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

export const updateEssentialPages =
  (patch: EssentialPages) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    const existingEssentialPages = essentialPagesSelector(getState());
    return dispatch(
      updateActiveUserOrganization({
        essential_pages: { ...existingEssentialPages, ...patch },
      })
    );
  };
export const updateAboutUsSettings = (aboutUsSettings: AboutUsSettings) =>
  updateEssentialPages({
    about_us_settings: aboutUsSettings,
  });
export const updateFaqSettings = (faqSettings: FaqSettings) =>
  updateEssentialPages({
    faq_settings: faqSettings,
  });
export const updateCovid19Settings = (covid19Settings: Covid19Settings) =>
  updateEssentialPages({
    covid19_settings: covid19Settings,
  });
