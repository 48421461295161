import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ListEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ListEditor';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';

export const DetailInfoEditor = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles['p-list']}>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Experience Content')}</div>
          </div>
        </div>
        <div
          className={clsx(
            styles['p-list__item__body'],
            styles['p-list__item__body__detail']
          )}
        >
          <ul>
            <ListEditor
              name="inclusions"
              title={t('Inclusions')}
              tooltipText={t(
                'Describe what is included in the price of the experience. Example: Lunch, guide, facility admission, etc.'
              )}
              addButtonText={t('Add Inclusion')}
            />
            <ListEditor
              name="exclusions"
              title={t('Exclusions')}
              tooltipText={t(
                'Describe what is not included in the price of the experience. Examples: drinks, photography, seat upgrades, etc.'
              )}
              addButtonText={t('Add Exclusion')}
            />
          </ul>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Participation Conditions')}</div>
          </div>
        </div>
        <div
          className={clsx(
            styles['p-list__item__body'],
            styles['p-list__item__body__detail']
          )}
        >
          <ul>
            <ListEditor
              name="requirements"
              title={t('Requirements')}
              tooltipText={t(
                'Describe the conditions for participating in the experience. Examples: Participation from 8 years old and over, license holder, etc.'
              )}
              addButtonText={t('Add Requirement')}
            />
            <ListEditor
              name="restrictions"
              title={t('Restrictions')}
              tooltipText={t(
                'Describe the conditions for not being able to participate in the experience. Examples: Pregnant women, people with heart disease, etc.'
              )}
              addButtonText={t('Add Restriction')}
            />
          </ul>
        </div>
      </li>
      <li className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Participation Info')}</div>
          </div>
        </div>
        <div
          className={clsx(
            styles['p-list__item__body'],
            styles['p-list__item__body__detail']
          )}
        >
          <ul>
            <ListEditor
              name="whatToBring"
              title={t('What to Bring')}
              tooltipText={t(
                'List recommended clothes and things to bring. Examples: Walking shoes, dress code, clothing, etc.'
              )}
              addButtonText={t('Add What to Bring')}
            />
            <ListEditor
              name="otherNotes"
              title={t('Other Info')}
              tooltipText={t(
                'Describe other supplemental information about the experience. Example: Schedule may change due to weather and traffic conditions, etc.'
              )}
              addButtonText={t('Add Additional Info')}
            />
          </ul>
        </div>
      </li>
    </ul>
  );
};
