import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import type { SourceLanguage } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { LineMessageContentFormTab } from './LineMessageContentFormTab';

type Props = {
  languageOptions: { value: string; text: string }[];
  showTranslationMode: boolean;
  translationTargetLanguage: SourceLanguage | null;
  onTranslationModeChange: (arg: boolean) => void;
  onTranslationTargetLanguageChange: (arg0: SourceLanguage | null) => void;
};

export const LineMessageContentForm = ({
  languageOptions,
  showTranslationMode,
  translationTargetLanguage,
  onTranslationModeChange,
  onTranslationTargetLanguageChange,
}: Props) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<'confirm' | 'checkin'>(
    'confirm'
  );
  return (
    <>
      <Modal.Box column={'two'}>
        <div className={baseStyles['base-form-toggle']}>
          <label>
            <input
              type="checkbox"
              checked={showTranslationMode}
              onChange={() => onTranslationModeChange(!showTranslationMode)}
            />
            <p></p>
          </label>
          {t('Translation mode')}
        </div>
        {showTranslationMode && (
          <Select
            width={176}
            options={languageOptions}
            onChange={(_, { value }) => {
              onTranslationTargetLanguageChange(value as SourceLanguage);
            }}
            value={translationTargetLanguage as string}
          />
        )}
      </Modal.Box>
      <Modal.Box>
        <div
          className={clsx(
            componentStyles['c-tab-box'],
            baseStyles['scroll-target-pane']
          )}
          id="detail"
        >
          <ul className={clsx(componentStyles['c-tab-box__tab'])}>
            <li
              className={clsx(
                activeTab === 'confirm' ? componentStyles['is-active'] : ''
              )}
              onClick={() => {
                setActiveTab('confirm');
              }}
            >
              <a>{t('Confirm Message')}</a>
            </li>
            <li
              className={clsx(
                activeTab === 'checkin' ? componentStyles['is-active'] : ''
              )}
              onClick={() => {
                setActiveTab('checkin');
              }}
            >
              <a>{t('Redeem Message')}</a>
            </li>
          </ul>

          {activeTab === 'confirm' && (
            <LineMessageContentFormTab
              name={'confirm'}
              translationTargetLanguage={translationTargetLanguage}
            />
          )}
          {activeTab === 'checkin' && (
            <LineMessageContentFormTab
              name={'checkin'}
              translationTargetLanguage={translationTargetLanguage}
            />
          )}
        </div>
      </Modal.Box>
    </>
  );
};
