import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import type { TFunction } from 'react-i18next';

import {
  FETCH_DISPATCH_RESERVATIONS_FAILURE,
  FETCH_DISPATCH_RESERVATIONS_REQUEST,
  FETCH_DISPATCH_RESERVATIONS_SUCCESS,
  UPDATE_RESERVATION_SUCCESS,
  CHECKIN_RESERVATION_SUCCESS,
  UNDO_CHECKIN_RESERVATION_SUCCESS,
} from 'client/constants/ActionTypes';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import { Reservation } from 'shared/models/swagger';

type I18nProps = {
  t: TFunction;
};

// Selectors
export const allDispatchReservationsSelector = createSelector(
  (state: ReduxState) => state.dispatch.reservations,
  (state: ReduxState, props: I18nProps) => props.t,
  (reservations, t) =>
    reservations.map((r: Reservation) => toManifestReservationShape(r, t))
);

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_DISPATCH_RESERVATIONS_FAILURE:
      return action.error;

    case FETCH_DISPATCH_RESERVATIONS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_DISPATCH_RESERVATIONS_REQUEST:
      return true;

    case FETCH_DISPATCH_RESERVATIONS_FAILURE:
    case FETCH_DISPATCH_RESERVATIONS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const reservations = (state: Reservation[] = [], action: any) => {
  switch (action.type) {
    case FETCH_DISPATCH_RESERVATIONS_SUCCESS:
      return action.response.reservations;

    case FETCH_DISPATCH_RESERVATIONS_FAILURE:
      return [];

    case UPDATE_RESERVATION_SUCCESS:
    case CHECKIN_RESERVATION_SUCCESS:
    case UNDO_CHECKIN_RESERVATION_SUCCESS: {
      const updated = action.response;
      return state.map((reservation: Reservation) =>
        reservation.id === updated.id ? updated : reservation
      );
    }
    default:
      return state;
  }
};

interface DispatchState {
  error: string;
  loading: boolean;
  reservations: Reservation[];
}

export const dispatch = combineReducers<DispatchState>({
  error,
  loading,
  reservations,
});
