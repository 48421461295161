import * as React from 'react';
import moment from 'moment-timezone';

import { Calendar } from './Calendar';
import { SessionDetails } from './SessionDetails';
import { SessionSummary } from './SessionSummary';
import styles from './DetailsPane.module.css';
import { DateDetails } from './DateDetails';
import { SessionSelectionContext } from './SessionSelectionContext';
import { useSessions } from './useSessions';

export const DetailsPane = () => {
  const sessions = useSessions();
  const firstSessionDate = moment(sessions?.[0]?.start_date_time_utc);

  const [selectedDate, setSelectedDate] = React.useState(
    firstSessionDate.format('YYYY-MM-DD')
  );
  const [selectedSessionTimestamp, setSelectedSessionTimestamp] =
    React.useState(sessions?.[0]?.start_date_time_utc ?? '');

  return (
    <SessionSelectionContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        selectedSessionTimestamp,
        setSelectedSessionTimestamp,
      }}
    >
      <div className={styles['container']}>
        <div className={styles['left-pane']}>
          <Calendar />
          <DateDetails />
        </div>
        <div className={styles['right-pane']}>
          <SessionSummary />
          <SessionDetails />
        </div>
      </div>
    </SessionSelectionContext.Provider>
  );
};
