import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';
import { ListInstantWinEventsRequest } from 'shared/models/swagger';

export type DateFilterPreset = 'DRAWN_1_DAY' | 'DRAWN_3_DAYS' | 'DRAWN_7_DAYS';
export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return (
    ['DRAWN_1_DAY', 'DRAWN_3_DAYS', 'DRAWN_7_DAYS'] as DateFilterPreset[]
  ).map((preset) => ({
    value: preset,
    text: getDateFilterPresetText(preset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  switch (preset) {
    case 'DRAWN_1_DAY':
      return t('drawn, next 1 day', {
        count: 1,
      });

    case 'DRAWN_3_DAYS':
      return t('drawn, next {{count}} days', {
        count: 3,
      });

    case 'DRAWN_7_DAYS':
      return t('drawn, next {{count}} days', {
        count: 7,
      });
  }
};
export type SearchInstantWinEventsRequest = {
  instantWinIds: string[];
  drawnDateFrom: string;
  drawnDateTo: string;
  dateFilterPreset: DateFilterPreset | null;
};
export const convertSearchInstantWinEventsRequestToQueryParams = (
  req: SearchInstantWinEventsRequest
): ListInstantWinEventsRequest => {
  let startDateLocalFrom = '';
  let startDateLocalTo = '';

  if (req.dateFilterPreset) {
    const now = moment();

    switch (req.dateFilterPreset) {
      case 'DRAWN_1_DAY':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(1, 'days').format('YYYY-MM-DD');
        break;

      case 'DRAWN_3_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(3, 'days').format('YYYY-MM-DD');
        break;

      case 'DRAWN_7_DAYS':
        startDateLocalFrom = now.format('YYYY-MM-DD');
        startDateLocalTo = now.add(7, 'days').format('YYYY-MM-DD');
        break;
    }
  } else {
    startDateLocalFrom = req.drawnDateFrom;
    startDateLocalTo = req.drawnDateTo
      ? moment(req.drawnDateTo).add(1, 'day').format('YYYY-MM-DD')
      : '';
  }

  return {
    drawn_date_local_from: startDateLocalFrom,
    drawn_date_local_to: startDateLocalTo,
  };
};
