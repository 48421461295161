import type { Account } from 'shared/models/swagger';

type Operation = 'read' | 'write';
type AccessMap = {
  availabilityAllotmentRules: Operation[];
  bookingWidgetSettings: Operation[];
  // 'write' access on 'products' allows creating and deleting products as well as editing them.
  products: Operation[];
  // 'write' access on 'productContents' will only allow updates to existing products.
  productContents: Operation[];
  productInstances: Operation[];
  promotions: Operation[];
  reservations: Operation[];
  newReservations: Operation[];
  organizations: Operation[];
  // TODO: fold 'organizationSettings' in with 'organizations'.
  organizationSettings: Operation[];
  accounts: Operation[];
  tasks: Operation[];
  transactions: Operation[];
  netPrices: Operation[];
  reservationPickupDropoff: Operation[];
  reservationBookingSource: Operation[];
  // Payment type options: "paid in full", "pay on board". TODO: do we need more?
  reservationPaymentType: Operation[];
  // Supplier booking payment type options: "invoice", "credit card", "cash", "pay on board", "other"
  reservationPaymentMethod: Operation[];
  reservationSupplierNotes: Operation[];
  reservationSupplierReference: Operation[];
  reservationAgentNotes: Operation[];
  reservationConfirmation: Operation[];
  reservationCancellationFee: Operation[];
  impersonatedAccount: Operation[];
  postIssuanceInvoiceFareAdjustment: Operation[];
  fareAdjustmentChangeDirection: Operation[];
  billingPeriod: Operation[];
  // Note: we're repurposing the access map for feature flags. We'll probably need
  // a separate mechanism in the future.
  reservationVoucher: Operation[];
  // SwaggerUI, basically
  apiSpec: Operation[];
  manifests: Operation[];
  guideAccounts: Operation[];
  schedules: Operation[];
  reverseMappings?: Operation[];
};
type ResourceName = keyof AccessMap;
const accessMaps: {
  AGENT: {
    ADMIN: AccessMap;
    REVERSE_MAPPING_ADMIN: AccessMap;
    RESERVATIONIST: AccessMap;
    ACCOUNTANT: AccessMap;
    GUIDE: AccessMap;
    // Same as ADMIN for agent. Needed for passthrough where supplier can act as agent.
    PRODUCT_EDITOR: AccessMap;
  };
  SUPPLIER: {
    ADMIN: AccessMap;
    RESERVATIONIST?: AccessMap;
    ACCOUNTANT?: AccessMap;
    GUIDE?: AccessMap;
    // product editors have all the same permissions as 'ADMIN' as well as being able to create and delete products.
    PRODUCT_EDITOR: AccessMap;
  };
  GUEST: {
    ADMIN?: AccessMap;
    RESERVATIONIST?: AccessMap;
    ACCOUNTANT?: AccessMap;
    GUIDE?: AccessMap;
    PRODUCT_EDITOR?: AccessMap;
  };
} = {
  AGENT: {
    ADMIN: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: ['read'],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for agents.
      organizations: [],
      organizationSettings: ['read', 'write'],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      impersonatedAccount: [],
      reservationVoucher: [],
      apiSpec: ['read'],
      manifests: [],
      guideAccounts: [],
      schedules: [],
    },
    REVERSE_MAPPING_ADMIN: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: ['read'],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for agents.
      organizations: [],
      organizationSettings: ['read', 'write'],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      impersonatedAccount: [],
      reservationVoucher: [],
      apiSpec: ['read'],
      manifests: [],
      guideAccounts: [],
      schedules: [],
      reverseMappings: ['read', 'write'],
    },
    RESERVATIONIST: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: ['read'],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      organizations: [],
      organizationSettings: [],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: [],
      guideAccounts: [],
      schedules: [],
    },
    ACCOUNTANT: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: [],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read'],
      newReservations: ['read'],
      organizations: [],
      organizationSettings: [],
      accounts: [],
      tasks: [],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: [],
      guideAccounts: [],
      schedules: [],
    },
    GUIDE: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: ['read'],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      organizations: [],
      organizationSettings: [],
      accounts: [],
      tasks: [],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: [],
      guideAccounts: [],
      schedules: [],
    },
    PRODUCT_EDITOR: {
      availabilityAllotmentRules: [],
      bookingWidgetSettings: [],
      products: ['read'],
      productContents: ['read'],
      productInstances: ['read'],
      promotions: [],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for agents.
      organizations: [],
      organizationSettings: ['read', 'write'],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read'],
      reservationBookingSource: [],
      reservationPaymentType: ['read', 'write'],
      reservationPaymentMethod: [],
      reservationSupplierNotes: ['read'],
      reservationSupplierReference: ['read'],
      reservationAgentNotes: ['read', 'write'],
      reservationConfirmation: ['read'],
      reservationCancellationFee: ['read'],
      postIssuanceInvoiceFareAdjustment: ['write'],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      impersonatedAccount: [],
      reservationVoucher: [],
      apiSpec: ['read'],
      manifests: [],
      guideAccounts: [],
      schedules: [],
    },
  },
  SUPPLIER: {
    ADMIN: {
      availabilityAllotmentRules: ['read', 'write'],
      bookingWidgetSettings: ['read', 'write'],
      // products can not be created or deleted
      products: ['read'],
      // products can be edited.
      productContents: ['read', 'write'],
      productInstances: ['read', 'write'],
      promotions: ['read', 'write'],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for suppliers.
      organizations: [],
      organizationSettings: ['read', 'write'],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: ['read', 'write'],
      netPrices: ['read'],
      reservationPickupDropoff: ['read', 'write'],
      reservationBookingSource: ['read', 'write'],
      reservationPaymentType: ['read'],
      reservationPaymentMethod: ['read', 'write'],
      reservationSupplierNotes: ['read', 'write'],
      reservationSupplierReference: ['read', 'write'],
      reservationAgentNotes: ['read'],
      reservationConfirmation: ['read', 'write'],
      reservationCancellationFee: ['read', 'write'],
      postIssuanceInvoiceFareAdjustment: [],
      fareAdjustmentChangeDirection: ['write'],
      billingPeriod: ['read', 'write'],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: ['read'],
      guideAccounts: ['read', 'write'],
      schedules: [],
    },
    PRODUCT_EDITOR: {
      availabilityAllotmentRules: ['read', 'write'],
      bookingWidgetSettings: ['read', 'write'],
      // products can be created, deleted, or updated.
      products: ['read', 'write'],
      productContents: ['read', 'write'],
      productInstances: ['read', 'write'],
      promotions: ['read', 'write'],
      reservations: ['read', 'write'],
      newReservations: ['read', 'write'],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for suppliers.
      organizations: [],
      organizationSettings: ['read', 'write'],
      accounts: [],
      tasks: ['read', 'write'],
      transactions: ['read', 'write'],
      netPrices: ['read'],
      reservationPickupDropoff: ['read', 'write'],
      reservationBookingSource: ['read', 'write'],
      reservationPaymentType: ['read'],
      reservationPaymentMethod: ['read', 'write'],
      reservationSupplierNotes: ['read', 'write'],
      reservationSupplierReference: ['read', 'write'],
      reservationAgentNotes: ['read'],
      reservationConfirmation: ['read', 'write'],
      reservationCancellationFee: ['read', 'write'],
      postIssuanceInvoiceFareAdjustment: [],
      fareAdjustmentChangeDirection: ['write'],
      billingPeriod: ['read', 'write'],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: ['read'],
      guideAccounts: ['read', 'write'],
      schedules: [],
    },
    GUIDE: {
      availabilityAllotmentRules: [],
      // products can be created, deleted, or updated.
      bookingWidgetSettings: [],
      products: [],
      productContents: [],
      productInstances: [],
      promotions: [],
      reservations: [],
      newReservations: [],
      // organizations: ['read', 'write'],
      // accounts: ['read', 'write'],
      // Account and branch management disabled for suppliers.
      organizations: [],
      organizationSettings: [],
      accounts: [],
      tasks: [],
      transactions: [],
      netPrices: [],
      reservationPickupDropoff: ['read', 'write'],
      reservationBookingSource: [],
      reservationPaymentType: [],
      reservationPaymentMethod: [],
      reservationSupplierNotes: [],
      reservationSupplierReference: ['read', 'write'],
      reservationAgentNotes: ['read'],
      reservationConfirmation: [],
      reservationCancellationFee: [],
      postIssuanceInvoiceFareAdjustment: [],
      fareAdjustmentChangeDirection: [],
      billingPeriod: [],
      reservationVoucher: [],
      impersonatedAccount: [],
      apiSpec: [],
      manifests: [],
      guideAccounts: [],
      schedules: ['read', 'write'],
    },
  },
  // 'GUEST' entity type doesn't access anything in the extranet so this access map is just a placeholder.
  GUEST: {},
};
export const operationAllowed = (
  account: Account | null,
  operation: Operation,
  resource: ResourceName
): boolean => {
  if (!account) return false;
  const role = account.role;
  if (role === 'nutmeg_admin') return true;
  if (!account.organization_type) return false;
  const type = account.organization_type;
  const orgAccessMap = accessMaps[type];
  let m = orgAccessMap[role as keyof typeof orgAccessMap];

  if (!m) {
    if (type === 'SUPPLIER') {
      m = accessMaps['SUPPLIER']['PRODUCT_EDITOR'];
    } else {
      m = accessMaps['AGENT']['ADMIN'];
    }
  }

  return m[resource]?.includes(operation) ?? false;
};

export const dashEnabledForAccount = (account?: Account | null): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'tasks');
};
export const manifestEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'manifests');
};
export const promotionsEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'promotions');
};
export const commissionsTableEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'netPrices');
};
export const availabilityTableEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'write', 'reservationConfirmation');
};

export const bookingWidgetSettingsViewEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'bookingWidgetSettings');
};
export const homeEnabledForAccount = (account?: Account | null): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'read', 'netPrices');
};
export const waiverEnabledForAccount = (account?: Account | null): boolean => {
  if (!account) return false;
  if (account.role === 'GUIDE') return false;
  return true;
};
export const crmEnabledForAccount = (account?: Account | null): boolean => {
  if (!account) return false;
  if (account.role === 'GUIDE') return false;
  return true;
};
export const newReservationButtonEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.role === 'nutmeg_admin') return false;
  return operationAllowed(account, 'write', 'newReservations');
};
export const reservationFormInEmailForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.organization_type === 'SUPPLIER') return true;
  return false;
};
export const impersonationEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;

  if (
    account.manageable_organization_ids &&
    account.manageable_organization_ids.length > 0
  ) {
    return true;
  }

  return operationAllowed(account, 'write', 'impersonatedAccount');
};
export const presetSearchConditionsEnabledForAccount = (
  account?: Account | null
): boolean => {
  if (!account) return false;
  if (account.organization_type === 'SUPPLIER') return true;
  return false;
};
