import { combineReducers } from 'redux';

import {
  FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_FAILURE,
  FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_REQUEST,
  FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';
import type { GetDigitalMapAccessReportDataResponse } from 'shared/models/swagger';

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_FAILURE:
      return action.error;

    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_REQUEST:
      return '';

    default:
      return state;
  }
};

const reportData = (state = [], action: any): any => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_SUCCESS: {
      const resp = action.response as GetDigitalMapAccessReportDataResponse;
      return resp.sets || [];
    }
    default:
      return state;
  }
};

const fetchDigitalMapAccessReportDataStatus = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_REQUEST:
      return 'REQUESTED';

    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_SUCCESS:
      return 'SUCCEEDED';

    case FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

export const digitalMapAccessReportData = combineReducers({
  error,
  reportData,
  fetchDigitalMapAccessReportDataStatus,
});
