import * as React from 'react';
import clsx from 'clsx';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { DurationInput } from 'client/components/NewProductEditor/DurationInput/DurationInput';
import { DedicatedAllotmentEditor } from 'client/components/NewProductEditor/ReservationParamsSteps/DedicatedAllotmentEditor';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Input } from 'client/components/Form';
import { isInteger } from 'client/components/NewProductEditor/isInteger';
import type { StartTime } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import deleteIcon from 'client/images/ic_delete.svg';
import addIcon from 'client/images/ic_add.svg';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';

const freesaleSlotLimit = 10000000;

const getDefaultStartTime = (): StartTime => {
  return {
    time: '9:00',
    duration: '3:00',
    allotmentSlots: 10,
    description: '',
    perChannelAllotments: [],
    packageComponentTimeSlots: [],
  };
};

type Props = {
  name?: string;
  showAllotment: boolean;
};
export const StartTimesInput = ({ name, showAllotment }: Props) => {
  const { t } = useTranslation();
  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );
  const translationTargetLanguageName =
    translationTargetLanguage &&
    getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t);
  const namePrefix = name ? `${name}.` : '';
  const isProductEdit = location.pathname.includes('edit');
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  return (
    <div>
      <div>
        <div className={styles['page-productsRegist__timeStock']}>
          <FieldArray name={`${namePrefix}startTimes`}>
            {({ fields }) => {
              return (
                <>
                  {fields.map((name, idx) => {
                    return (
                      <div
                        key={name}
                        className={styles['page-productsRegist__date']}
                      >
                        <div className={styles['c-tableChild']}>
                          <ul>
                            <li className={baseStyles['base-t-128']}>
                              {t('Start Time')}
                              <Tooltip
                                text={t(
                                  'Enter the estimated start time of the experience. You may register multiple times.'
                                )}
                              />
                            </li>
                            <li className={baseStyles['base-t-240']}>
                              {t('Duration')}
                              <Tooltip
                                text={t(
                                  'Enter the approximate length of the experience. You may set different lengths for each start time.'
                                )}
                              />
                            </li>
                            {showAllotment && (
                              <li className={baseStyles['base-t-256']}>
                                {t('Inventory Count')}
                                <Tooltip
                                  text={t(
                                    'The number of people for which you can accept reservations automatically. Please enter the capacity.'
                                  )}
                                />
                              </li>
                            )}
                            <li>
                              {t('Supplemental Note')}
                              <Tooltip
                                text={t(
                                  'Used when there is extra context for the start time. Example: range of business hours (~ 18: 00), final reception, etc.'
                                )}
                              />
                            </li>
                          </ul>
                          <ul>
                            <li data-title={t('Start Time')}>
                              <Box display="flex">
                                <Field name={`${name}.time`}>
                                  {({ input }) => (
                                    <TimePicker
                                      allowEmpty={false}
                                      value={moment(input.value, 'HH:mm')}
                                      onChange={(newMoment) => {
                                        if (!newMoment) {
                                          return;
                                        }

                                        input.onChange(
                                          newMoment.format('HH:mm')
                                        );
                                      }}
                                    />
                                  )}
                                </Field>

                                <Box ml={1} display="flex" alignItems="center">
                                  <a
                                    onClick={() =>
                                      (fields as any).insertAt(
                                        idx + 1,
                                        getDefaultStartTime()
                                      )
                                    }
                                  >
                                    <img src={addIcon} />
                                  </a>
                                  {idx !== 0 && (
                                    <Box ml={1}>
                                      <a>
                                        <img
                                          src={deleteIcon}
                                          onClick={() => fields.remove(idx)}
                                        />
                                      </a>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </li>
                            <li data-title={t('Duration')}>
                              <label className={baseStyles['base-form-select']}>
                                <Field name={`${name}.duration`}>
                                  {({ input }) => (
                                    <DurationInput required {...input} />
                                  )}
                                </Field>
                              </label>
                            </li>
                            {showAllotment && (
                              <li data-title={t('Capacity')}>
                                <div
                                  className={clsx(
                                    baseStyles['base-flex'],
                                    styles['base-flex'],
                                    styles[
                                      'page-productsRegist__timeStock__stock'
                                    ]
                                  )}
                                >
                                  <Field name={`${name}.allotmentSlots`}>
                                    {({ input }) => (
                                      <Input
                                        disabled={fields.value[idx].isFreesale}
                                        minWidth={35}
                                        value={
                                          input.value >= freesaleSlotLimit
                                            ? '-'
                                            : input.value
                                        }
                                        onChange={(e, { value }) => {
                                          if (!isInteger(value)) {
                                            return;
                                          }

                                          input.onChange(value);
                                        }}
                                        type="text"
                                      />
                                    )}
                                  </Field>
                                  <div
                                    className={
                                      styles['request-booking-toggle-container']
                                    }
                                  >
                                    <div
                                      className={clsx(
                                        baseStyles['base-form-toggle'],
                                        styles['base-form-toggle']
                                      )}
                                    >
                                      <label>
                                        <input
                                          onChange={() => {
                                            if (fields.value[idx].isFreesale) {
                                              fields.update(idx, {
                                                ...fields.value[idx],
                                                isFreesale: false,
                                                allotmentSlots: 10,
                                              });
                                            } else {
                                              fields.update(idx, {
                                                ...fields.value[idx],
                                                isFreesale: true,
                                                allotmentSlots:
                                                  freesaleSlotLimit,
                                                perChannelAllotments: [],
                                              });
                                            }
                                          }}
                                          checked={fields.value[idx].isFreesale}
                                          type="checkbox"
                                          name="sample3"
                                        />
                                        <p></p>
                                      </label>
                                      {t('Unlimited Inventory')}
                                    </div>
                                    <div
                                      className={clsx(
                                        baseStyles['base-form-toggle'],
                                        styles['base-form-toggle'],
                                        styles['request-booking-toggle'],
                                        fields.value[idx].isFreesale &&
                                          baseStyles['disabled']
                                      )}
                                    >
                                      <label>
                                        <input
                                          disabled={
                                            fields.value[idx].isFreesale
                                          }
                                          onChange={() => {
                                            if (
                                              fields.value[idx].allotmentSlots
                                            ) {
                                              fields.update(idx, {
                                                ...fields.value[idx],
                                                allotmentSlots: 0,
                                              });
                                            } else {
                                              fields.update(idx, {
                                                ...fields.value[idx],
                                                allotmentSlots: 10,
                                              });
                                            }
                                          }}
                                          checked={
                                            !fields.value[idx].allotmentSlots
                                          }
                                          type="checkbox"
                                          name="sample3"
                                        />
                                        <p></p>
                                      </label>
                                      {t('Request Booking')}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                            <li data-title={t('Supplemental Note')}>
                              <TranslatedField name={`${name}.description`}>
                                {({ input, translationInput }) => (
                                  <>
                                    <div
                                      className={
                                        styles[
                                          'page-productsRegist__timeStock__note'
                                        ]
                                      }
                                    >
                                      <Input {...input} type="text" />
                                    </div>
                                    {translationTargetLanguage != null && (
                                      <div
                                        className={
                                          styles[
                                            'page-productsRegist__timeStock__note'
                                          ]
                                        }
                                      >
                                        <input
                                          {...translationInput}
                                          placeholder={
                                            translationTargetLanguageName ??
                                            undefined
                                          }
                                          type="text"
                                          className={
                                            baseStyles['base-form-text']
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </TranslatedField>
                            </li>
                          </ul>
                        </div>
                        {isNutmegAdmin && (
                          <div className={baseStyles['base-margin-bottom-8']}>
                            <div>{t('Third Party Reference')}</div>
                            <Field name={`${name}.referenceInSupplierSystem`}>
                              {({ input }) => <Input width={200} {...input} />}
                            </Field>
                          </div>
                        )}
                        {isProductEdit &&
                          showAllotment &&
                          !fields.value[idx].isFreesale && (
                            <DedicatedAllotmentEditor
                              name={`${name}.perChannelAllotments`}
                            />
                          )}
                      </div>
                    );
                  })}
                </>
              );
            }}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};
