import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { Button, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import baseStyles from 'client/base.module.css';
import { updateNotificationSettings } from 'client/actions/notificationSettings';

import {
  convertToSwaggerNotificationSettings,
  FormValues,
  getInitialValues,
} from './formValues';

export const NotificationSettings = () => {
  const { t } = useTranslation();

  const scrollTo = React.useContext(ScrollToContext);
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);

  const initialValues = React.useMemo(() => {
    return getInitialValues(organization);
  }, [organization]);

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Form
          initialValues={initialValues}
          onSubmit={async (values: FormValues) => {
            await dispatch(
              updateNotificationSettings(
                convertToSwaggerNotificationSettings(values)
              )
            );

            scrollTo(0, 0);
          }}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitSucceeded, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              {submitError && (
                <Message
                  error
                  header={t('Update failed')}
                  content={submitError}
                />
              )}
              {submitSucceeded && (
                <Message success header={t('Update succeeded')} />
              )}
              <FormTableBox>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Enable Web Push Notifications')}</th>
                      <td>
                        <Field name="enableWebPush" type="checkbox">
                          {({ input }) => {
                            return (
                              <ToggleButton
                                checked={input.checked ?? false}
                                onChange={() => input.onChange(!input.checked)}
                                label={t('Enable Web Push Notifications')}
                              />
                            );
                          }}
                        </Field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormTableBox>
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button style="gray" size="middle">
                  {t('Discard')}
                </Button>
                <Button
                  style="blue"
                  size="middle"
                  type="submit"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
