import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { useReservationData } from 'client/pages/v3/FlexibleDashboard/useReservationData';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReservationSummaryTable } from 'client/pages/v3/FlexibleDashboard/ReservationSummaryGadget/ReservationSummaryTable';
import {
  ReservationSummaryColumn,
  getReservationSummaryTableColumns,
} from 'client/libraries/util/getReservationSummaryTableColumns';
import { ReduxState } from 'client/reducers';
import { ModalLoader } from 'client/components/ModalLoader';

interface Props {
  gadget: DashboardGadget;
  disableDatePicker?: boolean;
}

export const ReservationSummaryGadget = ({ gadget }: Props) => {
  const { t } = useTranslation();
  const [visibleColumns, setVisibleColumns] = useState<
    ReservationSummaryColumn[]
  >(getReservationSummaryTableColumns(t));

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const { data, isLoading } = useReservationData(gadget);
  const reservations = (data ?? []).map((r) =>
    toManifestReservationShape(r, t)
  );
  const dashboardSettings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );

  // Update visible columns based on dashboard settings
  useEffect(() => {
    const gadget = dashboardSettings?.gadgets?.find(
      (g) => g.gadgetType === 'reservation-summary'
    );
    if (gadget && gadget.gadgetType === 'reservation-summary') {
      const defaultColumns = gadget.params.defaultColumns ?? [];
      if (defaultColumns.length > 0) {
        const newVisibleColumns = getReservationSummaryTableColumns(t)
          .filter((c) => defaultColumns.includes(c.id.toUpperCase()))
          .sort((a, b) => {
            const indexA = defaultColumns.indexOf(a.id.toUpperCase());
            const indexB = defaultColumns.indexOf(b.id.toUpperCase());

            return indexA - indexB;
          });
        setVisibleColumns(newVisibleColumns);
      }
    }
  }, [dashboardSettings]);

  let dataToDisplay = null;
  if (isLoading) {
    dataToDisplay = <ModalLoader />;
  } else {
    if (reservations.length === 0) {
      dataToDisplay = (
        <div>{t('No data to display for the selected data set')}</div>
      );
    } else {
      dataToDisplay = (
        <ReservationSummaryTable
          reservations={reservations}
          supplierCurrency={activeUserOrganization?.default_currency || ''}
          visibleColumns={visibleColumns}
        />
      );
    }
  }

  return <>{dataToDisplay}</>;
};
