import * as React from 'react';
import { useField } from 'react-final-form';
import type { FieldRenderProps } from 'react-final-form';

const usePrevious = (value: any) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export type Translation = {
  source: string;
  target: string;
};

type Props = {
  name: string;
  children: (
    arg0: FieldRenderProps<string> & {
      translationInput: FieldRenderProps<string>['input'];
    }
  ) => React.ReactNode;
  validate?: (value: any | null | undefined) => string;
};
export const TranslatedField = ({ children, name, validate }: Props) => {
  // if source text changes, and we have an existing translation, create new translation with source text
  // if translation changes, either:
  // - if translation exists, change that item
  // - if translation doesn't exist, create new translation
  const sourceField = useField(name, {
    validate,
  });
  const translatedField = useField<string>(`t_${name}`);
  const translationsListField = useField<Translation[]>('translations');
  const sourceFieldPreviousValue = usePrevious(sourceField.input.value);
  const translatedFieldPreviousValue = usePrevious(translatedField.input.value);
  // Sync the translations list when source field changes
  React.useEffect(() => {
    // Only handle translations if translations field has been initialized
    if (!translationsListField.input.value) {
      return;
    }

    if (
      sourceField.meta.modified &&
      sourceField.input.value !== sourceFieldPreviousValue &&
      translatedField.input.value
    ) {
      translationsListField.input.onChange([
        ...(translationsListField?.input?.value ?? []).filter(
          (translation) => translation.source !== sourceFieldPreviousValue
        ),
        {
          source: sourceField.input.value,
          target: translatedField.input.value,
        },
      ]);
    } else if (
      translatedField.meta.modified &&
      translatedField.input.value !== translatedFieldPreviousValue &&
      sourceField.input.value
    ) {
      translationsListField.input.onChange([
        ...(translationsListField.input.value ?? []).filter(
          (translation) => translation.source !== sourceField.input.value
        ),
        {
          source: sourceField.input.value,
          target: translatedField.input.value,
        },
      ]);
    } else {
      const trans = (translationsListField.input.value ?? []).find(
        (trans) => trans.source === sourceField.input.value
      );

      if (trans && translatedField.input.value !== trans.target) {
        translatedField.input.onChange(trans.target);
      }
    }
  }, [
    sourceField.input.value,
    sourceFieldPreviousValue,
    translatedField.input.value,
    translatedField.input.onChange,
    translatedFieldPreviousValue,
    translationsListField.input.onChange,
    translationsListField.input.value,
  ]);
  return (
    <>{children({ ...sourceField, translationInput: translatedField.input })}</>
  );
};
