import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import {
  deleteGuidanceCoupon,
  fetchGuidanceCoupons,
} from 'client/actions/guidanceCoupon';
import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { GuidanceCoupon } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { EditGuidanceCouponModal } from './EditGuidanceCouponModal';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const EditDeleteCell = ({ coupon }: { coupon: GuidanceCoupon }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  return (
    <Box display="flex" alignItems="center">
      {showEditModal && (
        <EditGuidanceCouponModal
          existingGuidanceCoupon={coupon}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <EditIcon onClick={() => setShowEditModal(true)} />

      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete coupon')}
          content={t('Are you sure you want to delete coupon?')}
          onConfirm={async () => {
            await dispatch(deleteGuidanceCoupon(coupon.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot={true}
        />
      )}

      <Box ml={2}>
        <DeleteIcon onClick={() => setShowDeleteModal(true)} />
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<GuidanceCoupon>[] => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  return [
    ...(hasCustomUserRoleWritePermissions(
      activeUser,
      'DIGITAL_GUIDANCE.COUPONS'
    )
      ? ([
          {
            Header: '',
            th: true,
            width: 'middle',
            Cell: (cellInfo) => <EditDeleteCell coupon={cellInfo.original} />,
          },
        ] as ColumnType<GuidanceCoupon>[])
      : []),
    {
      Header: t('Title'),
      accessor: 'title',
    },
    {
      Header: t('Description'),
      accessor: 'description',
    },
    {
      Header: t('Redemptions'),
      accessor: (row) => `${row.redemption_count ?? 0}`,
    },
  ];
};

export const DigitalGuidanceCouponList = () => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);

  const dispatch = useDispatch();
  const columns = useColumns();
  const activeUser = useSelector(activeUserSelector);

  const coupons = useSelector((state: ReduxState) => state.guidanceCoupons.all);

  React.useEffect(() => {
    dispatch(fetchGuidanceCoupons());
  }, []);

  return (
    <div>
      {hasCustomUserRoleWritePermissions(
        activeUser,
        'DIGITAL_GUIDANCE.COUPONS'
      ) && (
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Create New Coupon')}
        </Button>
      )}
      {showEditModal && (
        <EditGuidanceCouponModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {coupons.length === 0 ? (
        <div className={baseStyles['base-form-box__err']}>
          {t('No Coupons Found')}
        </div>
      ) : (
        <CustomTable items={coupons} columns={columns} />
      )}
    </div>
  );
};
