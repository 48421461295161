import { BookingWidgetTopPageHeader } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPageHeader';
import { BookingWidgetTopPageBody } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPageBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const BookingWidgetTopPage = () => {
  return (
    <V3Page>
      <BookingWidgetTopPageHeader />
      <BookingWidgetTopPageBody />
    </V3Page>
  );
};
