/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';

interface RatingDistributionProps {
  distributions: number[];
}

const RatingDistribution: React.FC<RatingDistributionProps> = ({
  distributions,
}) => (
  <div className={styles.ratingDistributionCard}>
    {distributions.map((percentage, index) => (
      <div key={index} className={styles.distributionRow}>
        <span className={styles.starLabel}>{5 - index} star</span>
        <div className={styles.distributionBarWrapper}>
          <div
            className={styles.distributionBar}
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
    ))}
  </div>
);

export default RatingDistribution;
