import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { useContext } from 'react';
import clsx from 'clsx';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';

interface Props {
  language: string;
  name: string;
}

export const PerBookingUnitsEditor = ({ language, name }: Props) => {
  const { t } = useTranslation();

  const form = useForm();
  const formState = useFormState();
  const showUnits = (_.get(formState.values, name) ?? []).length > 0;

  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const builtInUnits = [
    t('Adult', { lng: language }),
    t('Child', { lng: language }),
    t('Adult/Child', { lng: language }),
    t('Infant', { lng: language }),
    t('Spectator', { lng: language }),
  ];

  const unitOptions = [
    ...builtInUnits.map((unit) => ({ value: unit, text: unit })),
    { value: 'custom', text: t('Custom') },
  ];

  const translationTargetLanguageName =
    translationTargetLanguage &&
    getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t);

  return (
    <div className={styles['p-box__table']}>
      <div className={clsx(styles['p-box__table__perBooking'])}>
        <Toggle
          label={t('Set Units')}
          checked={showUnits}
          onChange={() => {
            if (!showUnits) {
              form.change(name, [
                {
                  unit: t('Adult', { lng: language }),
                  ageBandMinimum: 13,
                  ageBandMaximum: 0,
                },
                {
                  unit: t('Child', { lng: language }),
                  ageBandMinimum: 4,
                  ageBandMaximum: 12,
                },
                {
                  unit: t('Infant', { lng: language }),
                  ageBandMinimum: 0,
                  ageBandMaximum: 3,
                },
              ]);
            } else {
              form.change(name, []);
            }
          }}
        />
        <Tooltip
          text={t(
            'Please set units if you need the number of guests for each unit (ex. "adult", "child") with per-booking pricing products.'
          )}
        >
          <i className="c-icon-outline-general-info-circle"></i>
        </Tooltip>
      </div>

      {showUnits && (
        <div className={styles['p-box__table']}>
          <ul
            className={clsx(
              styles['p-box__table__header'],
              baseStyles['u-spHidden']
            )}
          >
            <li
              className={clsx(
                styles['p-box__table__item'],
                baseStyles['u-width-128']
              )}
            >
              <p className={styles['p-box__table__item__ttl']}>{t('Unit')}</p>
            </li>
            <li
              className={clsx(
                styles['p-box__table__item'],
                baseStyles['u-width-128']
              )}
            >
              <p className={styles['p-box__table__item__ttl']}>
                {t('Displayed As')}
              </p>
            </li>
            <li
              className={clsx(
                styles['p-box__table__item'],
                baseStyles['u-width-208']
              )}
            >
              <p className={styles['p-box__table__item__ttl']}>
                {t('Age (optional)')}
              </p>
            </li>
            {/* Column for delete icon */}
            <li
              className={clsx(
                styles['p-box__table__item'],
                baseStyles['u-width-48']
              )}
            ></li>
          </ul>
          <FieldArray name={name}>
            {({ fields }) => (
              <>
                {fields.map((fieldName, idx) => (
                  <ul
                    key={fieldName}
                    className={clsx(styles['p-box__table__body'])}
                  >
                    <li
                      className={clsx(
                        styles['p-box__table__item'],
                        styles['p-box__table__item__alignTop']
                      )}
                      data-title={t('Unit')}
                    >
                      <Field name={`${fieldName}.unit`}>
                        {({ input }) => (
                          <SingleDropdown
                            options={unitOptions.map((option) => ({
                              value: option.value,
                              text: option.text,
                            }))}
                            selectedOption={
                              builtInUnits.includes(input.value)
                                ? input.value
                                : 'custom'
                            }
                            onChange={(value) => {
                              if (value === 'custom') {
                                input.onChange('');
                              } else {
                                input.onChange(value);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </li>
                    <li
                      className={clsx(
                        styles['p-box__table__item'],
                        styles['p-box__table__item__alignTop']
                      )}
                      data-title={t('Displayed As')}
                    >
                      <TranslatedField name={`${fieldName}.unit`}>
                        {({ input, translationInput }) => (
                          <>
                            <TextField {...input} />
                            {translationTargetLanguage != null &&
                              !builtInUnits.includes(input.value) && (
                                <TextField
                                  {...translationInput}
                                  placeholder={
                                    translationTargetLanguageName ?? undefined
                                  }
                                />
                              )}
                          </>
                        )}
                      </TranslatedField>
                    </li>
                    <li
                      className={clsx(
                        styles['p-box__table__item'],
                        styles['p-box__table__item__alignTop']
                      )}
                      data-title={t('Age (optional)')}
                    >
                      <p
                        className={clsx(
                          styles['p-box__table__item__ttl'],
                          baseStyles['u-pcHidden']
                        )}
                      >
                        {t('Age (optional)')}
                      </p>
                      <div className={styles['p-box__table__item__flex']}>
                        <Field
                          parse={(value) => parseInt(value, 10)}
                          format={(value) => `${value}`}
                          name={`${fieldName}.ageBandMinimum`}
                        >
                          {({ input }) => (
                            <SingleDropdown
                              options={_.times(100).map((opt) => ({
                                value: opt.toString(),
                                text: opt.toString(),
                              }))}
                              selectedOption={input.value}
                              onChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                        <span>-</span>
                        <Field
                          parse={(value) => parseInt(value, 10)}
                          format={(value) => `${value}`}
                          name={`${fieldName}.ageBandMaximum`}
                        >
                          {({ input }) => (
                            <SingleDropdown
                              options={_.times(100)
                                .map((opt) => ({
                                  value: (opt + 1).toString(),
                                  text: (opt + 1).toString(),
                                }))
                                .concat({
                                  value: '0',
                                  text: t('No Limit'),
                                })}
                              selectedOption={input.value}
                              onChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </li>
                    <li
                      className={clsx(
                        styles['p-box__table__item'],
                        styles['p-box__table__item__alignTop']
                      )}
                    >
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                        shouldHide={idx == 0}
                      />
                    </li>
                  </ul>
                ))}
                <a
                  className={styles['p-box__table__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(fields.length + 1, {
                        unit: t('Adult', {
                          lng: language,
                        }),
                        ageBandMinimum: 13,
                        ageBandMaximum: 0,
                      });
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {t('Add Unit')}
                </a>
              </>
            )}
          </FieldArray>
        </div>
      )}
    </div>
  );
};
