import { TextField } from 'client/components/v3/Form/TextField';
import { currency } from 'shared/libraries/currency';
import { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';

type Props = {
  currencyCode: string;
  moneyAmount: string;
  onChange: (arg0: string) => void;
  useLabel?: boolean;
  error?: boolean;
};
export const MoneyInput = ({
  currencyCode,
  moneyAmount,
  onChange,
  useLabel = true,
  error,
}: Props) => {
  const currencyInputHelper = new CurrencyAmountInputHelper(currencyCode);
  const amount = moneyAmount.length > 3 ? moneyAmount.substring(3) : '';

  return (
    <TextField
      label={useLabel ? currencyCode : ''}
      value={amount}
      onChange={(value) => {
        if (!currencyInputHelper.inputAllowed(value)) {
          return;
        }

        const newMoneyAmount = `${currencyCode}${value}`;

        // Reject input if it has too many decimal places.
        if (
          value &&
          currency(newMoneyAmount).format().length <
            value.length + currencyCode.length
        ) {
          return;
        }

        if (value) {
          onChange(newMoneyAmount);
        } else {
          onChange('');
        }
      }}
      error={error}
    />
  );
};
