import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { fetchNewsletter } from 'client/actions/newsletters';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Input, MultiSelect } from 'client/components/Form';
import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { TranslateFuncType } from 'client/components/Translate';
import { ReduxState } from 'client/reducers';
import { formattedPercent } from 'client/libraries/util/formattedPercent';
import { NewsletterCustomerRecord } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

const getActionText = (
  action: NewsletterCustomerRecord['email_status'],
  t: TranslateFuncType
) => {
  switch (action) {
    case 'DELIVERED':
      return t('Delivered');
    case 'BOUNCED':
      return t('Bounced');
    case 'OPENED':
      return t('Opened');
    case 'CLICKED':
      return t('Clicked');
    case 'BOOKED':
      return t('Booked');
  }

  return '-';
};

export const NewsletterReport = () => {
  const { id } = useParams<{ id: string }>();
  const [actionFilters, setActionFilters] = React.useState<string[]>([]);
  const [nameEmailFilter, setNameEmailFilter] = React.useState<string>('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchNewsletter(id));
  }, [id]);

  const newsletter = useSelector((state: ReduxState) =>
    state.newsletters.all.find((n) => n.id === id)
  );
  const customerRecords = newsletter?.customer_records ?? [];

  const actionFilterOptions = [
    'DELIVERED',
    'BOUNCED',
    'OPENED',
    'CLICKED',
    'BOOKED',
  ].map((action) => ({
    value: action,
    text: getActionText(action as NewsletterCustomerRecord['email_status'], t),
  }));
  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const columns: ColumnType<NewsletterCustomerRecord>[] = React.useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: (customer) => customer.customer_name,
        id: 'name',
      },
      {
        Header: t('Email'),
        accessor: (customer) => customer.customer_email,
        id: 'email',
      },
      {
        Header: t('Action'),
        accessor: (customer) => getActionText(customer.email_status, t),
        id: 'email_status',
      },
    ],
    [t]
  );

  const filteredCustomers = customerRecords.filter((customer) => {
    return (
      (actionFilters.length === 0 ||
        actionFilters.includes(customer.email_status ?? '')) &&
      (!nameEmailFilter ||
        customer.customer_name
          ?.toLowerCase()
          ?.includes(nameEmailFilter.toLowerCase()) ||
        customer.customer_email
          ?.toLowerCase()
          ?.includes(nameEmailFilter.toLowerCase()))
    );
  });

  const targetCount = newsletter?.customer_ids?.length ?? 0;
  const deliveredCount = newsletter?.delivered_count ?? 0;
  const openedCount = newsletter?.opened_count ?? 0;
  const clickedCount = newsletter?.clicked_count ?? 0;
  const bookedCount = newsletter?.booked_count ?? 0;
  const bouncedCount = newsletter?.bounced_count ?? 0;

  return (
    <>
      <Link to="/newsletters">
        <BackArrow />
      </Link>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <FormTableBox header={t('Summary')}>
            <table>
              <tbody>
                <tr>
                  <th>{t('Send Date')}</th>
                  <td>
                    {newsletter?.sent_date_time_utc
                      ? moment(newsletter.sent_date_time_utc)
                          .locale(locale)
                          .format('lll')
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <th>{t('Subject')}</th>
                  <td>{newsletter?.email_subject}</td>
                </tr>
                <tr>
                  <th>{t('Segments')}</th>
                  <td>
                    <ul>
                      {newsletter?.segment_ids?.map((segmentId) => (
                        <li key={segmentId}>
                          {segments.find((s) => s.id === segmentId)?.name}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>{t('Targets')}</th>
                  <td>{targetCount ?? '-'}</td>
                </tr>
                <tr>
                  <th>{t('Deliveries')}</th>
                  <td>{`${deliveredCount} (${formattedPercent(
                    deliveredCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Opens')}</th>
                  <td>{`${openedCount} (${formattedPercent(
                    openedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Clicks')}</th>
                  <td>{`${clickedCount} (${formattedPercent(
                    clickedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Bookings')}</th>
                  <td>{`${bookedCount} (${formattedPercent(
                    bookedCount,
                    targetCount
                  )})`}</td>
                </tr>
                <tr>
                  <th>{t('Bounces')}</th>
                  <td>{`${bouncedCount} (${formattedPercent(
                    bouncedCount,
                    targetCount
                  )})`}</td>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
        </div>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__body']}>
          <div className={baseStyles['base-subttl']}>{t('Targets')}</div>
          <Box ml={-1} mb={2} display="flex" flexWrap="wrap">
            <Box ml={2} minWidth="300px">
              <MultiSelect
                label={t('Filter by actions')}
                options={actionFilterOptions}
                selectedValues={actionFilters}
                onChange={({ value }) => setActionFilters(value)}
              />
            </Box>
            <Box ml={2} minWidth="300px">
              <Input
                label={t('Filter by name/email')}
                value={nameEmailFilter}
                onChange={(e, { value }) => setNameEmailFilter(value)}
              />
            </Box>
          </Box>
          <PagedGenericTable allItems={filteredCustomers} columns={columns} />
        </div>
      </div>
    </>
  );
};
