import _ from 'lodash';

import { TranslateFuncType } from 'client/components/Translate';
import {
  CustomUserResource,
  CustomUserResourceKey,
  ResourcePermission,
  convertResourcePermissionsToPermissionsString,
  convertPermissionsStringToResourcePermissions,
  topLevelResources,
  allResources,
} from 'client/libraries/util/customUserPermissions';
import { Account } from 'shared/models/swagger';

export interface FormValues {
  name: string;
  email: string;
  locale: string;
  topLevelPermissions: ResourcePermission[];
  subMenuPermissions: {
    [r in CustomUserResourceKey]?: ResourcePermission[];
  };
  accountType: 'PRODUCT_EDITOR' | 'CUSTOM_USER' | 'GUIDE';
  manageableOrganizationIds?: string[];
}

export const getInitialValues = (
  defaultLocale?: string,
  account?: Account
): FormValues => {
  if (!account) {
    return {
      name: '',
      email: '',
      topLevelPermissions: getDefaultTopLevelPermissions(),
      subMenuPermissions: getDefaultSubMenuPermissions(),
      accountType: 'CUSTOM_USER',
      locale: defaultLocale ?? 'en',
    };
  }

  const resourcePermissions = account.permissions
    ? convertPermissionsStringToResourcePermissions(account.permissions)
    : [];

  const topLevelPermissions: ResourcePermission[] =
    account.role === 'CUSTOM_USER'
      ? account.permissions
        ? topLevelResources.map(
            (resource) =>
              resourcePermissions.find(
                (permission) => permission.resource === resource
              ) ?? { resource, permission: 'READ_WRITE' }
          )
        : getDefaultTopLevelPermissions()
      : [];

  const subMenuPermissions: {
    [r in CustomUserResourceKey]?: ResourcePermission[];
  } =
    account.role === 'CUSTOM_USER'
      ? account.permissions
        ? getSubMenuPermissions(resourcePermissions)
        : getDefaultSubMenuPermissions()
      : {};

  let manageableOrganizationIds: string[] = [];
  if (account.manageable_organization_ids) {
    manageableOrganizationIds = account.manageable_organization_ids.split(',');
  }

  return {
    name: account.name ?? '',
    email: account.email,
    topLevelPermissions,
    subMenuPermissions,
    locale: account.locale ?? defaultLocale ?? 'en',
    accountType:
      account.role === 'ADMIN' || account.role === 'PRODUCT_EDITOR'
        ? 'PRODUCT_EDITOR'
        : account.role === 'GUIDE'
        ? 'GUIDE'
        : 'CUSTOM_USER',
    manageableOrganizationIds: manageableOrganizationIds,
  };
};

export const convertFormValuesToAccount = (
  values: FormValues,
  existingAccount?: Account
): Partial<Account> => {
  const permissions = [
    ...values.topLevelPermissions,
    ..._.flatten(Object.values(values.subMenuPermissions)),
  ];

  const permissionsString =
    values.accountType === 'CUSTOM_USER'
      ? convertResourcePermissionsToPermissionsString(permissions)
      : '';

  const account: Partial<Account> = {
    ...existingAccount,
    name: values.name,
    email: values.email,
    role: values.accountType,
    permissions: permissionsString,
    locale: values.locale,
    manageable_organization_ids:
      (values.manageableOrganizationIds || []).length > 0
        ? (values.manageableOrganizationIds || []).join(',')
        : undefined,
  };

  return account;
};

export const getDefaultTopLevelPermissions = (): ResourcePermission[] => {
  return topLevelResources.map((resource) => ({
    resource,
    permission: 'READ_WRITE',
  }));
};

export const getDefaultSubMenuPermissions = (): {
  [r in CustomUserResourceKey]?: ResourcePermission[];
} => {
  const subMenuPermissions: {
    [r in CustomUserResourceKey]?: ResourcePermission[];
  } = {};

  for (const topLevelResource of topLevelResources) {
    const subMenuResources = allResources.filter(
      (resource) =>
        resource !== topLevelResource &&
        CustomUserResource[resource].startsWith(
          CustomUserResource[topLevelResource]
        )
    );

    subMenuPermissions[
      CustomUserResource[topLevelResource] as CustomUserResourceKey
    ] = subMenuResources.map((resource) => ({
      resource,
      permission: 'READ_WRITE',
    }));
  }

  return subMenuPermissions;
};

export const getSubMenuPermissions = (
  permissions: ResourcePermission[]
): {
  [r in CustomUserResourceKey]?: ResourcePermission[];
} => {
  const subMenuPermissions: {
    [r in CustomUserResourceKey]?: ResourcePermission[];
  } = {};

  for (const topLevelResource of topLevelResources) {
    const subMenuResources = allResources.filter(
      (resource) =>
        resource !== topLevelResource &&
        CustomUserResource[resource].startsWith(
          CustomUserResource[topLevelResource]
        )
    );

    subMenuPermissions[
      CustomUserResource[topLevelResource] as CustomUserResourceKey
    ] = subMenuResources.map((resource) => {
      const existingPermission = permissions.find(
        (p) => p.resource === resource
      );
      if (existingPermission) {
        return {
          resource,
          permission: existingPermission.permission,
        };
      }

      return {
        resource,
        permission: 'READ_WRITE',
      };
    });
  }

  return subMenuPermissions;
};

export const getResourceText = (
  resource: CustomUserResource,
  t: TranslateFuncType
): string => {
  const texts: Record<CustomUserResourceKey, string> = {
    HOME: t('Home'),
    RESERVATION: t('Reservations'),
    'RESERVATION.LIST': t('List, Details'),
    'RESERVATION.DASHBOARD': t('Dash'),
    'RESERVATION.REPORTS': t('Reports'),
    'RESERVATION.DATA_DOWNLOAD': t('Data Download'),
    'RESERVATION.ACTIM_RESERVATIONS': t('ActimReservations'),
    MANIFEST: t('Manifest'),
    'MANIFEST.DAILY': t('Daily manifest'),
    'MANIFEST.RESOURCE_ASSIGNMENT': t('Resource Assignments'),
    'MANIFEST.CUSTOMIZE': t('Customize'),
    'MANIFEST.EDIT_RESOURCES': t('Edit Resources'),
    'MANIFEST.RESOURCE_AVAILABILITY': t('Resource Availability'),
    PRODUCT: t('Products'),
    'PRODUCT.LIST': t('List, Details'),
    'PRODUCT.PROMOTIONS': t('Promotions'),
    'PRODUCT.COMMISSIONS': t('Commissions'),
    AVAILABILITY: t('Availability'),
    'AVAILABILITY.WEEKLY': t('Weekly'),
    'AVAILABILITY.MONTHLY': t('Monthly'),
    'AVAILABILITY.CUSTOM': t('Custom'),
    BILLING: t('Billing'),
    'BILLING.INVOICES': t('Invoices'),
    'BILLING.PERIODS': t('Billing Period'),
    REVIEW: t('Reviews'),
    'REVIEW.REVIEWS': t('Reviews'),
    'REVIEW.EMBED_CODE': t('Review Embed Code'),
    'REVIEW.SETTINGS': t('Review Settings'),
    WAIVER: t('Waiver'),
    'WAIVER.SETTINGS': t('Waiver Settings'),
    'WAIVER.TEMPLATES': t('Waiver Templates'),
    'WAIVER.WAIVERS': t('Submitted Waivers'),
    MEDIA_DOWNLOAD: t('Media Download'),
    'MEDIA_DOWNLOAD.ORDERS': t('Orders'),
    'MEDIA_DOWNLOAD.PRICES': t('Prices'),
    'MEDIA_DOWNLOAD.UPLOAD': t('Upload'),
    'MEDIA_DOWNLOAD.SETTINGS': t('Settings'),
    DIGITAL_GUIDANCE: t('Digital Guidance'),
    'DIGITAL_GUIDANCE.PAGES': t('Pages'),
    'DIGITAL_GUIDANCE.COUPONS': t('Coupons'),
    'DIGITAL_GUIDANCE.STAMP_RALLIES': t('Stamp Rallies'),
    'DIGITAL_GUIDANCE.SETTINGS': t('Settings'),
    'DIGITAL_GUIDANCE.REDEEMED_RECORDS': t('Redeemed Records'),
    BOOKING_SITE: t('Booking Site'),
    'BOOKING_SITE.ACCESS_REPORTS': t('Access Reports'),
    'BOOKING_SITE.SETTINGS': t('Settings'),
    'BOOKING_SITE.CUSTOMIZE': t('Customize'),
    'BOOKING_SITE.TOP_PAGE': t('TOP page'),
    'BOOKING_SITE.HOMEPAGES': t('Homepages'),
    'BOOKING_SITE.EMAILS': t('Emails'),
    'BOOKING_SITE.EMBED': t('Embed'),
    'BOOKING_SITE.WEBSITEINTEGRATION': t('Website Integration'),
    'BOOKING_SITE.GOOGLE_MY_BUSINESS': t('Google My Business'),
    SYSTEM_FEE: t('System Fee'),
    'SYSTEM_FEE.WEB_BOOKINGS': t('Web Bookings'),
    'SYSTEM_FEE.SUBSCRIPTIONS': t('Subscriptions'),
    'SYSTEM_FEE.SETTINGS': t('Settings'),
    SHIFT_MANAGEMENT: t('Shift Management'),
    'SHIFT_MANAGEMENT.STAFF': t('Staff'),
    'SHIFT_MANAGEMENT.SCHEDULE': t('Schedule'),
    E_TICKET: t('E-ticket'),
    'E_TICKET.REDEMPTIONS': t('E-ticket redemptions'),
    'E_TICKET.LOCATIONS': t('E-ticket location'),
    'E_TICKET.VIEW_HISTORY': t('E-ticket View history'),
    'E_TICKET.QR_CODES': t('E-ticket QR Code'),
    'E_TICKET.DAILY_USES': t('Daily Uses'),
    INQUIRY: t('Inquiry'),
    'INQUIRY.LIST': t('List'),
    'INQUIRY.BULK_EMAIL': t('Bulk Email Notifications'),
    'INQUIRY.BULK_SMS': t('Bulk SMS Notifications'),
    'INQUIRY.SMS_USAGE': t('SMS Usage'),
    'INQUIRY.SETTINGS': t('Settings'),
    CUSTOMER_LEDGER: t('Members'),
    'CUSTOMER_LEDGER.LIST': t('List'),
    'CUSTOMER_LEDGER.SETTINGS': t('Settings'),
    SURVEY: t('Survey'),
    'SURVEY.SUBMISSIONS': t('Submitted Surveys'),
    'SURVEY.SURVEYS': t('Surveys'),
    'SURVEY.SETTINGS': t('Settings'),
    NEWSLETTER: t('Newsletter'),
    'NEWSLETTER.LIST': t('Newsletters'),
    'NEWSLETTER.SEGMENTS': t('Segments'),
    ACCOUNT: t('Accounts'),
    'ACCOUNT.LIST': t('Accounts'),
    CHANNEL_MANAGER: t('Channel Manager'),
    'CHANNEL_MANAGER.COMMISSIONS': t('Commissions'),
    'CHANNEL_MANAGER.INVOICES': t('Invoices'),
    'CHANNEL_MANAGER.PERIODS': t('Billing Period'),
    'CHANNEL_MANAGER.JARAN_IMPORT': t('Jalan Import'),
    UNUSED_LAST_VALUE: 'UNUSED',
  };

  return texts[CustomUserResource[resource] as CustomUserResourceKey] ?? '';
};
