import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  maxThreshold: number;
};

export const ReservationBulkCancelMaxThresholdModal = ({
  setShowModal,
  maxThreshold,
}: Props) => {
  const { t } = useTranslation();

  const text = t(
    'Bulk cancellation/decline is limited to maximum of {{n}} reservations.',
    {
      n: maxThreshold,
    }
  );

  return (
    <Modal
      title={t('Cancel/Decline Reservations')}
      open={true}
      onClose={() => {
        setShowModal(false);
      }}
      rightActionChildren={
        <>
          <Button
            text={t('OK')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </>
      }
      useCloseButton={true}
      style={{ width: '600px' }}
    >
      <div>{text}</div>
    </Modal>
  );
};
