import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useForm, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { BookingDeadlineTimeline } from 'client/components/BookingDeadlineTimeline/BookingDeadlineTimeline';
import { DeadlineInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DeadlineInput';
import { Select, ToggleButton, DateTimeInput } from 'client/components/Form';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';
import deleteIcon from 'client/images/ic_delete.svg';
import addIcon from 'client/images/ic_add.svg';
import { Tooltip } from 'client/components/Tooltip/Tooltip';

export const BookingDeadlinesInput = () => {
  const form = useForm();
  const values = form.getState().values;
  const { t, i18n } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const startDateText = t(
    'Accept reservations from {{days}} days before participation',
    {
      days: '<DAYS>',
    }
  );
  const startDateTextParts = startDateText.split('<DAYS>');

  const startDateTimeText = t('Accept reservations from {{date}}.', {
    date: '<DATE>',
  });
  const startDateTimeTextParts = startDateTimeText.split('<DATE>');

  return (
    <div>
      <div className={styles['page-productsRegist__priceMain__ttl']}>
        {t('Booking Start Date & Time')}
      </div>
      <div>
        <Box mt={2}>
          <Field name="shouldUseBookingStartDateTime">
            {({ input }) => (
              <Box display="flex" alignItems="center">
                <div
                  style={{
                    width: '50px',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                  }}
                >
                  <ToggleButton
                    checked={input.value}
                    onChange={() => {
                      input.onChange(!input.value);
                    }}
                  />
                </div>
                <span>{startDateTimeTextParts[0]}</span>
                <Box display="flex" mr={1} ml={1}>
                  <div style={{ width: '150px' }}>
                    <Field name="bookingStartDateTimeUtc">
                      {({ input: startDateTimeInput }) => (
                        <DateTimeInput
                          value={moment.tz(
                            startDateTimeInput.value,
                            activeUserOrganization?.default_timezone || 'UTC'
                          )}
                          onChange={(date) => {
                            const newDate = moment
                              .tz(
                                startDateTimeInput.value,
                                activeUserOrganization?.default_timezone ||
                                  'UTC'
                              )
                              .clone()
                              .set('year', date.year())
                              .set('month', date.month())
                              .set('date', date.date());
                            startDateTimeInput.onChange(newDate.utc().format());
                          }}
                          locale={i18n.language}
                          disabled={!input.value}
                        />
                      )}
                    </Field>
                  </div>

                  <Field name="bookingStartDateTimeUtc">
                    {({ input: startDateTimeInput }) => (
                      <TimePicker
                        allowEmpty={false}
                        value={moment.tz(
                          startDateTimeInput.value,
                          activeUserOrganization?.default_timezone || 'UTC'
                        )}
                        onChange={(time) => {
                          if (!time) {
                            return;
                          }
                          const newDate = moment
                            .tz(
                              startDateTimeInput.value,
                              activeUserOrganization?.default_timezone || 'UTC'
                            )
                            .clone()
                            .set('hour', time.hour())
                            .set('minute', time.minute());
                          startDateTimeInput.onChange(newDate.utc().format());
                        }}
                        disabled={!input.value}
                      />
                    )}
                  </Field>
                </Box>
                <span>{startDateTimeTextParts[1]}</span>
                <Tooltip
                  text={t(
                    'If date & time is not set, all dates with participation rules and prices will be always available for bookings.'
                  )}
                />
              </Box>
            )}
          </Field>
        </Box>

        <Box mt={2}>
          <Field name="agentGuestBookingAllowedDaysBefore">
            {({ input }) => (
              <Box display="flex" alignItems="center">
                <div
                  style={{
                    width: '50px',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                  }}
                >
                  <ToggleButton
                    checked={Boolean(input.value)}
                    onChange={() => {
                      if (input.value) {
                        input.onChange(null);
                      } else {
                        input.onChange(20);
                      }
                    }}
                  />
                </div>
                <span>{startDateTextParts[0]}</span>
                <Box display="flex" mr={1} ml={1}>
                  <Select
                    style={{
                      display: 'inline-block',
                    }}
                    width={40}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(parseInt(value))}
                    options={_.times(365, (count) => ({
                      value: `${count + 1}`,
                      text: `${count + 1}`,
                    }))}
                    disabled={!input.value}
                  />
                </Box>
                <span>{startDateTextParts[1]}</span>
                <Tooltip
                  text={t(
                    'If date & time is not set, all dates with participation rules and prices will be always available for bookings.'
                  )}
                />
              </Box>
            )}
          </Field>
        </Box>
      </div>
      <Box mt={1}>
        <div className={styles['page-productsRegist__priceMain__ttl']}>
          {t('Deadlines')}
        </div>
        <div>
          <BookingDeadlineTimeline
            requestBookingDeadline={values?.requestBookingDeadline}
            instantBookingDeadline={values?.instantBookingDeadline}
          />
          <div className={styles['page-productsRegist__reserve']}>
            <div className={styles['page-productsRegist__reserve__item']}>
              <p>{t('Instant Booking')}</p>
              {values?.instantBookingDeadline && (
                <div>
                  <DeadlineInput name="instantBookingDeadline" />
                  <div className={baseStyles['base-flex']}>
                    {values?.requestBookingDeadline && (
                      <a
                        onClick={() =>
                          form.change('instantBookingDeadline', null)
                        }
                      >
                        <img src={deleteIcon} />
                      </a>
                    )}
                  </div>
                </div>
              )}
              {!values?.instantBookingDeadline && (
                <div className={baseStyles['base-flex']}>
                  {
                    <a
                      onClick={() =>
                        form.change('instantBookingDeadline', {
                          deadlineType: 'DAY',
                          daysBefore: 1,
                          timeOfDay: '17:00',
                        })
                      }
                    >
                      <img src={addIcon} />
                    </a>
                  }
                </div>
              )}
            </div>
            <div className={styles['page-productsRegist__reserve__item']}>
              <p>{t('Request Booking')}</p>
              {values?.requestBookingDeadline && (
                <div>
                  <DeadlineInput name="requestBookingDeadline" />
                  <div className={baseStyles['base-flex']}>
                    {values?.instantBookingDeadline && (
                      <a
                        onClick={() =>
                          form.change('requestBookingDeadline', null)
                        }
                      >
                        <img src={deleteIcon} />
                      </a>
                    )}
                  </div>
                </div>
              )}
              {!values?.requestBookingDeadline && (
                <a
                  onClick={() =>
                    form.change('requestBookingDeadline', {
                      deadlineType: 'DAY',
                      daysBefore: 2,
                      timeOfDay: '17:00',
                    })
                  }
                >
                  <img src={addIcon} />
                </a>
              )}
            </div>
          </div>
          <p className={styles['c-table-list__note']}>
            {t(
              '* Times and settings for accepting online reservations. All local time.'
            )}
          </p>
        </div>
      </Box>
    </div>
  );
};
