import axios from 'axios';
import type { ThunkDispatch } from 'redux-thunk';

import {
  GET_FARES_CANCELED,
  GET_FARES_REQUEST,
  GET_FARES_SUCCESS,
  GET_FARES_FAILURE,
  GET_REBOOK_FROM_RESERVATION_FARES_REQUEST,
  GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS,
  GET_REBOOK_FROM_RESERVATION_FARES_FAILURE,
  GET_REBOOK_FROM_RESERVATION_FARES_CANCELED,
} from 'client/constants/ActionTypes';
import { getHTTPRequestHeaders } from 'client/actions';
import type { ReduxState } from 'client/reducers';
import type { NewReservation } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

// Action creators
const getFaresRequest = () => ({
  type: GET_FARES_REQUEST,
});

const getFaresSuccess = (response: any) => ({
  type: GET_FARES_SUCCESS,
  response,
});

const getFaresFailure = (error: any) => ({
  type: GET_FARES_FAILURE,
  error,
});

const getFaresCanceled = () => ({
  type: GET_FARES_CANCELED,
});

let calculateReservationFaresCancel: () => void | typeof undefined;
export const calculateReservationFares =
  (req: NewReservation) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    calculateReservationFaresCancel?.();
    dispatch(getFaresRequest());
    return axios
      .post('/api/reservations/calculatefares', req, {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          calculateReservationFaresCancel = c;
        }),
      })
      .then((response) => dispatch(getFaresSuccess(response.data)))
      .catch((err) => {
        if (axios.isCancel(err)) {
          dispatch(getFaresCanceled());
        } else {
          dispatch(getFaresFailure(err));
          throw err;
        }
      });
  };

// Action creators
const getRebookFromReservationFaresRequest = () => ({
  type: GET_REBOOK_FROM_RESERVATION_FARES_REQUEST,
});

const getRebookFromReservationFaresSuccess = (response: any) => ({
  type: GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS,
  response,
});

const getRebookFromReservationFaresFailure = (error: any) => ({
  type: GET_REBOOK_FROM_RESERVATION_FARES_FAILURE,
  error,
});

const getRebookFromReservationFaresCanceled = () => ({
  type: GET_REBOOK_FROM_RESERVATION_FARES_CANCELED,
});

let getRebookFromReservationFaresCancel: () => void | typeof undefined;
export const calculateRebookFromReservationFares =
  (req: NewReservation) => (dispatch: Dispatch, getState: () => ReduxState) => {
    getRebookFromReservationFaresCancel?.();
    dispatch(getRebookFromReservationFaresRequest());
    return axios
      .post('/api/reservations/calculatefares', req, {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          getRebookFromReservationFaresCancel = c;
        }),
      })
      .then((response) =>
        dispatch(getRebookFromReservationFaresSuccess(response.data))
      )
      .catch((err) => {
        if (axios.isCancel(err)) {
          dispatch(getRebookFromReservationFaresCanceled());
        } else {
          dispatch(getRebookFromReservationFaresFailure(err.message));
          throw err;
        }
      });
  };
