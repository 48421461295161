import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import { MenuItemAddOn } from '../formValues';

import styles from './MenuItemEditor.module.css';
import { AddOnModal } from './AddOnModal';
import { TagModal } from './TagModal';

const formatAddOnOption = (addOn: MenuItemAddOn, currencyCode: string) => {
  if (!addOn.price) return addOn.name;

  return `${addOn.name} (${currencyCode}${addOn.price})`;
};

interface Props {
  name: string;
}

export const MenuItemEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const [editingAddOnIdx, setEditingAddOnIdx] = React.useState<number | null>(
    null
  );
  const [editingOptionIdx, setEditingOptionIdx] = React.useState<number | null>(
    null
  );
  const [editingTagIdx, setEditingTagIdx] = React.useState<number | null>(null);
  const { required } = getValidators(t);
  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? '';
  const form = useForm();

  const allergyOptions = [
    {
      value: 'gluten',
      text: t('Gluten'),
    },
    {
      value: 'dairy',
      text: t('Dairy'),
    },
    {
      value: 'nuts',
      text: t('Nuts'),
    },
    {
      value: 'soy',
      text: t('Soy'),
    },
    {
      value: 'shellfish',
      text: t('Shellfish'),
    },
    {
      value: 'egg',
      text: t('Egg'),
    },
    {
      value: 'fish',
      text: t('Fish'),
    },
  ];

  return (
    <div>
      <section className={styles.details}>
        <h2 className={styles.sectionTitle}>{t('Details')}</h2>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.name`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Name')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.category`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Category')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.price`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Price ({{currency}})', {
                    currency: currencyCode,
                  })}
                  onChange={(newValue) => {
                    if (!currencyInputAllowed(currencyCode, newValue)) return;

                    input.onChange(newValue);
                  }}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.description`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextArea
                  label={t('Description')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.isRecommended`}>
              {({ input }) => (
                <Toggle
                  label={t('Recommended')}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field<string[]> name={`${name}.tags`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Tags')}
                  options={[
                    ...((input.value || []).map((tag) => ({
                      value: tag,
                      text: tag,
                    })) ?? []),
                    { value: 'ADD_NEW', text: t('Create New') },
                  ]}
                  onChange={(options) => {
                    if (options.some((option) => option === 'ADD_NEW')) {
                      setEditingTagIdx(input.value?.length || 0);
                    } else {
                      input.onChange(options);
                    }
                  }}
                  renderOption={(option) => {
                    if (option.value === 'ADD_NEW') {
                      return (
                        <div className={styles['add-new']}>
                          <i className="c-icon-outline-general-plus-circle"></i>
                          <p>{option.text}</p>
                        </div>
                      );
                    } else {
                      return option.text;
                    }
                  }}
                  selectedOptions={input.value || []}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.allergens`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Allergens')}
                  options={allergyOptions}
                  onChange={input.onChange}
                  selectedOptions={input.value}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field<MenuItemAddOn[]> name={`${name}.options`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Options')}
                  options={[
                    ...((input.value || [])?.map((addOn) => ({
                      value: addOn.name,
                      text: formatAddOnOption(addOn, currencyCode),
                    })) ?? []),
                    { value: 'ADD_NEW', text: t('Create New') },
                  ]}
                  onChange={(options) => {
                    if (options.some((option) => option === 'ADD_NEW')) {
                      setEditingOptionIdx(input.value?.length || 0);
                    } else {
                      input.onChange(options);
                    }
                  }}
                  renderOption={(option) => {
                    if (option.value === 'ADD_NEW') {
                      return (
                        <div className={styles['add-new']}>
                          <i className="c-icon-outline-general-plus-circle"></i>
                          <p>{option.text}</p>
                        </div>
                      );
                    } else {
                      return option.text;
                    }
                  }}
                  selectedOptions={(input.value || [])?.map(
                    (addOn) => addOn.name
                  )}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field<MenuItemAddOn[]> name={`${name}.addOns`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Add-Ons')}
                  options={[
                    ...((input.value || [])?.map((addOn) => ({
                      value: addOn.name,
                      text: formatAddOnOption(addOn, currencyCode),
                    })) ?? []),
                    { value: 'ADD_NEW', text: t('Create New') },
                  ]}
                  onChange={(options) => {
                    if (options.some((option) => option === 'ADD_NEW')) {
                      setEditingAddOnIdx(input.value?.length || 0);
                    } else {
                      input.onChange(options);
                    }
                  }}
                  renderOption={(option) => {
                    if (option.value === 'ADD_NEW') {
                      return (
                        <div className={styles['add-new']}>
                          <i className="c-icon-outline-general-plus-circle"></i>
                          <p>{option.text}</p>
                        </div>
                      );
                    } else {
                      return option.text;
                    }
                  }}
                  selectedOptions={(input.value || [])?.map(
                    (addOn) => addOn.name
                  )}
                />
              )}
            </Field>
          </div>

          <div className={styles.inputWrapper}>
            <Field name={`${name}.addOnsLimit`}>
              {({ input }) => (
                <SingleDropdown
                  label={t('Total Add-Ons Limit per Order')}
                  onChange={input.onChange}
                  options={[
                    {
                      text: t('None'),
                      value: '0',
                    },
                    ..._.times(10, (i) => ({
                      text: `${i + 1}`,
                      value: `${i + 1}`,
                    })),
                  ]}
                  selectedOption={input.value || '0'}
                />
              )}
            </Field>
          </div>
        </div>
        {editingTagIdx != null && (
          <TagModal
            name={`${name}.tags.${editingTagIdx}`}
            onClose={() => {
              const tags: any[] = form.getState().values.tags || [];
              if (tags.length > 0) {
                form.change(
                  `${name}.tags`,
                  tags.filter((_, idx) => idx !== editingTagIdx)
                );
              }
              setEditingTagIdx(null);
            }}
            onSave={() => setEditingTagIdx(null)}
          />
        )}
        {editingAddOnIdx != null && (
          <AddOnModal
            name={`${name}.addOns.${editingAddOnIdx}`}
            onClose={() => {
              const addOns: any[] = form.getState().values.addOns || [];
              if (addOns.length > 0) {
                form.change(
                  `${name}.addOns`,
                  addOns.filter((_, idx) => idx !== editingAddOnIdx)
                );
              }
              setEditingAddOnIdx(null);
            }}
            onSave={() => setEditingAddOnIdx(null)}
          />
        )}
        {editingOptionIdx != null && (
          <AddOnModal
            name={`${name}.options.${editingOptionIdx}`}
            onClose={() => {
              const options: any[] = form.getState().values.options || [];
              if (options.length > 0) {
                form.change(
                  `${name}.options`,
                  options.filter((_, idx) => idx !== editingOptionIdx)
                );
              }
              setEditingOptionIdx(null);
            }}
            onSave={() => setEditingOptionIdx(null)}
          />
        )}
      </section>

      <section className={styles.profilePictures}>
        <h2 className={styles.sectionTitle}>{t('Photos')}</h2>
        <Field name={`${name}.mediaUrls`}>
          {({ input }) => (
            <ImageVideoAudioInput
              fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
              fileUrls={input.value || []}
              onChange={(newValue) => input.onChange(newValue)}
            />
          )}
        </Field>
      </section>
    </div>
  );
};
