import type { SubscriptionKey } from 'shared/models/swagger';

export const subscriptionPrices: Record<
  SubscriptionKey,
  {
    month: {
      JPY: string;
      USD: string;
    };
  }
> = {
  'feature-review': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-email-customization': {
    month: {
      JPY: '2,500',
      USD: '25.00',
    },
  },
  'feature-digital-waiver': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-qr-checkin': {
    month: {
      JPY: '2,500',
      USD: '25.00',
    },
  },
  'feature-guest-my-page': {
    month: {
      JPY: '2,500',
      USD: '25.00',
    },
  },
  'feature-guide-account': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-image-download': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-digital-guidance': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-inquiry': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-e-ticket': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-resource-management': {
    month: {
      JPY: '2,500',
      USD: '25.00',
    },
  },
  'feature-vehicle-crew-resource-management': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-survey': {
    month: {
      JPY: '5,000',
      USD: '50.00',
    },
  },
  'feature-customer-ledger': {
    month: {
      JPY: '30,000',
      USD: '300.00',
    },
  },
  // deprecated; use 'feature-customer-ledger' instead
  'feature-newsletter': {
    month: {
      JPY: '-',
      USD: '-',
    },
  },
  'standard-plan': {
    month: {
      JPY: '10,000',
      USD: '100.00',
    },
  },
  'feature-resource-management-calendar': {
    month: {
      JPY: '2,500',
      USD: '25.00',
    },
  },
  'feature-seat-management': {
    month: {
      JPY: '50,000',
      USD: '500.00',
    },
  },
  'feature-custom-top-page': {
    month: {
      JPY: '20,000',
      USD: '200.00',
    },
  },
  'feature-channel-manager': {
    month: {
      JPY: '20,000',
      USD: '200.00',
    },
  },
  'feature-annual-pass': {
    month: {
      JPY: '10,000',
      USD: '100.00',
    },
  },
  'feature-journey-analytics': {
    month: {
      JPY: '50,000',
      USD: '500.00',
    },
  },
  'feature-marketing-automation': {
    month: {
      JPY: '50,000',
      USD: '500.00',
    },
  },
  'feature-digital-map': {
    month: {
      JPY: '50,000',
      USD: '500.00',
    },
  },
  'feature-group-ticketing': {
    month: {
      JPY: '50,000',
      USD: '500.00',
    },
  },
  'feature-site-hacker': {
    month: {
      JPY: '30,000',
      USD: '300.00',
    },
  },
};

export const getSubscriptionPriceDescription = (
  key: SubscriptionKey,
  currency: 'JPY' | 'USD'
): string => {
  return currency === 'JPY'
    ? `${subscriptionPrices[key].month[currency]}円 （税抜）/月`
    : `$${subscriptionPrices[key].month[currency]} + TAX / month`;
};
