import React from 'react';
import { Field } from 'react-final-form';

import { ProductCardFields, truncated, withQueryParams } from '../../util';

import { HighlightedDateSection } from './HighlightedDateSection';
import { PlanSummarySection } from './PlanSummarySection';

const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);
const HighlightedDateSectionEditButton = React.lazy(
  () =>
    import(
      'client/components/TemplateEditButtons/HighlightedDateSectionEditButton'
    )
);
const PlanSummarySectionEditButton = React.lazy(
  () =>
    import('client/components/TemplateEditButtons/PlanSummarySectionEditButton')
);
const MainProductEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/MainProductEditButton')
);

type Props = {
  data: {
    editMode: boolean;
    headline: string;
    headlineDescription: string;
    highlightedDateSection?: {
      title: string;
      dates: string;
      availableSlots: string;
      style: string;
    };
    highlightedPlanSummarySection?: {
      title: string;
      items: string[];
      style: string;
    };
    mainProduct: ProductCardFields;
    newsletterCampaignId: string;
    language: string;
  };
};

export const MainSection = ({ data }: Props) => {
  return (
    <tr style={{ margin: 0, padding: 0 }}>
      <td>
        <div
          style={{
            margin: 0,
            padding: '1.5em',
            backgroundColor: '#ffffff',
          }}
        >
          {data.editMode && (
            <Field name="templateValues.headline">
              {({ input }) => (
                <TextAreaEditButton
                  initialValue={input.value}
                  onSave={(text) => input.onChange(text)}
                />
              )}
            </Field>
          )}
          <p
            style={{
              margin: 0,
              fontWeight: 'bold',
              fontSize: '1.5em',
              whiteSpace: 'pre-line',
              borderBottom: '1px solid #E4E4E7',
              paddingBottom: '1em',
            }}
          >
            {data.headline}
          </p>
          {data.editMode && (
            <Field name="templateValues.headlineDescription">
              {({ input }) => (
                <TextAreaEditButton
                  initialValue={input.value}
                  onSave={(text) => input.onChange(text)}
                />
              )}
            </Field>
          )}
          <p
            style={{
              margin: '1em 0 0 0',
              padding: 0,
              whiteSpace: 'pre-line',
            }}
          >
            {data.headlineDescription}
          </p>

          {/* From here on we list different sections of the template depending on the content */}

          {/* Limited availability もうすぐ満席になる日程 1 */}
          {data.highlightedDateSection && (
            <>
              {data.editMode && (
                <Field name="templateValues.highlightedDateSection">
                  {({ input }) => (
                    <HighlightedDateSectionEditButton
                      onSave={(newValue) => input.onChange(newValue)}
                      initialValue={input.value}
                    />
                  )}
                </Field>
              )}
              <HighlightedDateSection
                data={data.highlightedDateSection}
                language={data.language}
              />
            </>
          )}
          {/* Soaring popularity - plan summary */}
          {data.highlightedPlanSummarySection && (
            <>
              {data.editMode && (
                <Field name="templateValues.highlightedPlanSummarySection">
                  {({ input }) => (
                    <PlanSummarySectionEditButton
                      onSave={(newValue) => input.onChange(newValue)}
                      initialValue={input.value}
                    />
                  )}
                </Field>
              )}
              <PlanSummarySection data={data.highlightedPlanSummarySection} />
            </>
          )}

          {data.editMode && (
            <Field name="templateValues.mainProduct">
              {({ input }) => (
                <MainProductEditButton
                  onSave={(newValue) => input.onChange(newValue)}
                  initialValue={input.value}
                  language={data.language}
                />
              )}
            </Field>
          )}
          <div
            style={{
              marginTop: '1em',
              border: '1px solid #E4E4E7',
              borderRadius: '16px',
            }}
          >
            <a
              href={withQueryParams(data.mainProduct.productUrl, {
                utm_source: 'ntmg.newsletter',
                utm_medium: 'email',
                utm_campaign: data.newsletterCampaignId ?? 'todo',
                utm_content: 'mainProduct',
                lng: data.language,
              })}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '100%',
                margin: '1em 0 0 0',
                padding: 0,
                background: '#F9F9F9',
                textDecoration: 'none',
              }}
            >
              <div
                style={{
                  margin: '1.5em',
                  position: 'relative',
                  verticalAlign: 'middle',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={data.mainProduct.productThumbnailUrl}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <div
                style={{
                  padding: '0 1.5em 1.5em 1.5em',
                  color: '#333333',
                  verticalAlign: 'middle',
                }}
              >
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '1.5em',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {data.mainProduct.productName}
                </p>
                <p
                  style={{
                    margin: '.5em 0 0 0',
                    padding: 0,
                    fontSize: '12px',
                    color: '#4A6C7A',
                    lineHeight: '1.5em',
                  }}
                >
                  {truncated(data.mainProduct.productDescription, 200)}
                </p>
              </div>
              <div style={{ padding: '0 1.5em 1.5em 1.5em' }}>
                <a
                  href={withQueryParams(data.mainProduct.productUrl, {
                    utm_source: 'ntmg.newsletter',
                    utm_medium: 'email',
                    utm_campaign: data.newsletterCampaignId ?? 'todo',
                    utm_content: 'mainProduct',
                    lng: data.language,
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    maxWidth: '240px',
                    borderRadius: '9999px',
                    textDecoration: 'none',
                    padding: '2px',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      padding: 0,
                      display: 'block',
                      fontWeight: 'bold',
                      width: '100%',
                      height: '48px',
                      lineHeight: '48px',
                      fontSize: '16px',
                      backgroundColor: '#4DA1EF',
                      color: '#ffffff',
                      textDecoration: 'none',
                      textAlign: 'center',
                      borderRadius: '40px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {data.mainProduct.buttonText}
                  </span>
                </a>
              </div>
            </a>
          </div>
        </div>
      </td>
    </tr>
  );
};
