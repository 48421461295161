import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { useReviews } from 'client/hooks/useReviews';
import { sendReviewRequestEmail } from 'client/actions/reservations';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { ReviewList } from 'client/pages/ProductDetails/ProductReviewsPane/ReviewList';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ReservationReviewsTab.module.css';
import { SendReviewRequestEmailModal } from './SendReviewRequestEmailModal';

type Props = {
  reservation?: Reservation;
};
export const ReservationReviewsTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailSendingStatus, setEmailSendingStatus] = React.useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const { reviews } = useReviews({
    reservationId: reservation?.id ?? '',
  });
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;
  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Reviews')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {reviews.length === 0 && (
          <>
            {!isSubscriptionCancelled(activeOrganization, 'feature-review') ? (
              <SendReviewRequestEmailModal
                initialToAddress={email}
                trigger={
                  <Button
                    style="green"
                    size="middle"
                    disabled={emailSendingStatus === 'SUCCEEDED'}
                    loading={emailSendingStatus === 'REQUESTED'}
                  >
                    {t('Send Review Request Email')}
                  </Button>
                }
                onSubmit={(email?: string) => {
                  try {
                    setEmailSendingStatus('REQUESTED');
                    dispatch(
                      sendReviewRequestEmail(reservation?.id ?? '', email)
                    );
                    setEmailSendingStatus('SUCCEEDED');
                  } catch (e) {
                    setEmailSendingStatus('FAILED');
                  }
                }}
              />
            ) : (
              <>
                {t(
                  'Your subscription to the reviews feature has been deactivated so sending review request emails is disabled. Resubscribe here: '
                )}{' '}
                <a
                  className={styles['link']}
                  href={`${window.origin}/systemfee/settings`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Settings')}
                </a>
              </>
            )}
            {emailSendingStatus == 'SUCCEEDED' && (
              <Message success header={t('Send Successful')} />
            )}
            {emailSendingStatus == 'FAILED' && (
              <Message error header={t('Send Failed')} />
            )}
          </>
        )}

        {reviews.length > 0 && (
          <ReviewList
            reviews={reviews}
            showEditButton={hasCustomUserRoleWritePermissions(
              activeUser,
              'REVIEW.REVIEWS'
            )}
            showStatus
          />
        )}
      </div>
    </div>
  );
};
