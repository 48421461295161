import { useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from 'axios';

import * as Swagger from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { getHTTPRequestHeaders } from 'client/actions/index';
import { config } from 'client/config';

const fetcher = (
  url: string,
  supplierId: string,
  month: string,
  headersString: string
) =>
  axios
    .get(url, {
      params: {
        supplier_id: supplierId,
        month,
      },
      headers: JSON.parse(headersString),
    })
    .then((res) => res.data ?? []);

export const useAgentSystemFeeLineItems = (
  supplierId: string,
  month: string
): {
  data: Swagger.SystemFeeLineItem[] | null;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector((state: ReduxState) => {
    return getHTTPRequestHeaders(state, { useAlternateOrganization: true });
  });

  const url = config.enablePartnershipSystemFeeInvoiceTable
    ? `/api/partnershipagentsystemfeelineitems`
    : `/api/agentsystemfeelineitems`;

  const { data, error } = useSWR<Swagger.SystemFeeLineItem[]>(
    [url, supplierId, month, JSON.stringify(headers)],
    fetcher
  );

  return {
    data: data ?? null,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
