import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import { ReduxState } from 'client/reducers';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { ModalLoader } from 'client/components/ModalLoader';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { useReservationReportData } from 'client/pages/v3/FlexibleDashboard/useReservationReportData';
import { getReservationReportDataSum } from 'client/pages/v3/FlexibleDashboard/util';
import { ReservationReportLineChart } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportLineChart';
import { ReservationReportSourcesBarChart } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportSourcesBarChart';
import { ReservationReportProductsTable } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportProductsTable';
import { ReservationReportSourcesTable } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportSourcesTable';
import { ReservationReportProductsBarChart } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportProductsBarChart';

interface DateRange {
  start: string;
  end: string;
}

interface Props {
  params: ReservationReportGadgetParams;
  disableDatePicker?: boolean;
  positionInRow?: 'left' | 'right' | 'middle';
}

export const ReservationReportGadget = ({ params, positionInRow }: Props) => {
  const { t } = useTranslation();
  const [baseDateRange, setBaseDateRange] = React.useState<DateRange>(
    getDateRangeForPreset(params.dateRange)
  );
  const [comparisonDateRange, setComparisonDateRange] =
    React.useState<DateRange | null>(null);
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const products: Swagger.ProductSummary[] =
    (params.productIds &&
      (params.productIds
        .map((id) => allProducts.find((p) => p.id === id))
        .filter((p) => p) as Swagger.ProductSummary[])) ??
    [];

  const { data, isLoading } = useReservationReportData(
    params,
    baseDateRange,
    comparisonDateRange
  );

  // Update when params changed
  React.useEffect(() => {
    setBaseDateRange(getDateRangeForPreset(params.dateRange));
  }, [params]);

  const total = getReservationReportDataSum(
    data?.[0].items ?? [],
    params.dataType
  );
  const comparisonTotal = data?.[1]
    ? getReservationReportDataSum(data?.[1].items ?? [], params.dataType)
    : 0;

  let dataToDisplay = null;
  if (isLoading) {
    dataToDisplay = (
      <div style={{ marginTop: '16px' }}>
        <ModalLoader />
      </div>
    );
  } else {
    if (data && data[0].items && data[0].items.length === 0) {
      dataToDisplay = (
        <div style={{ marginTop: '16px' }}>
          {t('No data to display for the selected data set')}
        </div>
      );
    } else {
      if (params.displayType === 'LINE_CHART' && data) {
        dataToDisplay = (
          <ReservationReportLineChart params={params} reportDataSets={data} />
        );
      }
      if (params.displayType === 'SOURCES_TABLE' && data) {
        dataToDisplay = (
          <ReservationReportSourcesTable
            params={params}
            reportDataSets={data}
          />
        );
      }
      if (params.displayType === 'SOURCES_BAR_CHART' && data) {
        dataToDisplay = (
          <ReservationReportSourcesBarChart
            params={params}
            reportDataSets={data}
          />
        );
      }
      if (params.displayType === 'PRODUCTS_TABLE' && data) {
        dataToDisplay = (
          <ReservationReportProductsTable
            params={params}
            reportDataSets={data}
          />
        );
      }
      if (params.displayType === 'PRODUCTS_BAR_CHART' && data) {
        dataToDisplay = (
          <ReservationReportProductsBarChart
            params={params}
            reportDataSets={data}
          />
        );
      }
    }
  }

  let dateInputMenuStyle;
  if (positionInRow === 'right') {
    dateInputMenuStyle = { right: 0, left: 'auto' };
  } else {
    dateInputMenuStyle = { right: 'auto', left: 0 };
  }

  return (
    <div className={styles['p-transition']}>
      {/* Only show when we have products */}
      {products.length > 0 && (
        <p className={styles['p-transition__products']}>
          {products.map((p) => p?.internal_product_name).join(', ')}
        </p>
      )}
      <ComparisonDateInput
        baseDateRange={baseDateRange}
        setBaseDateRange={setBaseDateRange}
        comparisonDateRange={comparisonDateRange}
        setComparisonDateRange={setComparisonDateRange}
        menuStyle={dateInputMenuStyle}
      />
      <div className={styles['p-transition__body']}>
        <div className={styles['p-transition__body__summary']}>
          <p>
            {t('Total: ')}
            <span>{total.toLocaleString()}</span>
          </p>
          {comparisonDateRange && (
            <p>
              {t('Comparison Total: {{total}}', {
                total: comparisonTotal.toLocaleString(),
              })}
            </p>
          )}
        </div>
        {dataToDisplay}
      </div>
    </div>
  );
};
