// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react';
import moment from 'moment-timezone';
import ReactTable from 'react-table';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { activeUserSelector } from 'client/reducers/user';
import {
  fetchTasks,
  fetchTaskCounts,
  setTasksFilters,
  setTasksPageSize,
  updateTasksPageTokens,
} from 'client/actions/tasks';
import { setDashVisibleColumns } from 'client/actions/dashTableControls';
import {
  dashColumnCandidatesSelector,
  dashVisibleColumnsSelector,
} from 'client/reducers/dashTableControls';
import {
  actionItemDescription,
  getActionItemCategoryOptions,
  taskShapesSelector,
  getColorForTask,
} from 'client/pages/Dash/util';
import { ActionItemCell } from 'client/pages/Dash/ActionItemCell';
import { translatedReactTableProps } from 'client/libraries/util/coreutil';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers/index';
import type {
  ActionItemCategoryOption,
  TaskShape,
} from 'client/pages/Dash/util';

type ColumnType = {
  Header: string,
  translatedColumnName?: string,
  id: string,
  accessor?: string | ((any) => string),
};

type OwnProps = {};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  allColumns: ColumnType[],
  page: number,
  filter?: Object,
};

class DashComponent extends React.PureComponent<Props, State> {
  table: ?typeof ReactTable;

  constructor(props: Props) {
    super(props);

    this.state = {
      allColumns: this.getAllColumns(),
      page: 0,
    };
  }

  componentDidMount() {
    this.fetchTasksAndCounts();
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.invalidated) {
      this.fetchTasksAndCounts();
    }

    if (this.props !== prevProps) {
      this.setState({
        allColumns: this.getAllColumns(),
      });
    }

    if (prevProps.invalidated) {
      this.resetPages();
      if (this.state.filter) {
        this.handleFetchData();
      }
    }
  };

  fetchTasksAndCounts = () => {
    this.props.fetchTasks();
    this.props.fetchTaskCounts();
  };

  handleFetchData = () => {
    this.props.fetchTasks();
  };

  handleOnClickPrevious = () => {
    const pageTokens = this.props.pageTokens;
    this.props.updateTasksPageTokens({
      allTokens: pageTokens.allTokens,
      currToken: this.props.previousPageToken,
      indexToToken: this.props.pageTokens.indexToToken,
    });

    this.props.fetchTasks();

    this.setState({
      page: this.state.page - 1,
    });
  };

  handleOnClickNext = () => {
    let allTokens = this.props.pageTokens.allTokens;
    allTokens[this.props.nextPageToken] = {
      previous: this.props.pageTokens.currToken,
      next: null,
    };
    this.props.updateTasksPageTokens({
      allTokens: allTokens,
      currToken: this.props.nextPageToken,
      indexToToken: this.props.pageTokens.indexToToken,
    });
    this.setState({
      page: this.state.page + 1,
    });

    this.props.fetchTasks();
  };

  resetPages = () => {
    this.setState({
      page: 0,
    });
  };

  resetAndQuery = () => {
    this.resetPages();
    this.props.updateTasksPageTokens({
      allTokens: {
        '': {
          previous: null,
          next: null,
        },
      },
      currToken: '',
      indexToToken: [''],
    });
    this.props.fetchTasks();
  };

  handlePageSizeChange = (pageSize) => {
    this.props.onPageSizeChange(pageSize);
    // Go back to first page, since our query has changed
    this.resetAndQuery();
  };

  getActionItemOptions = (): ActionItemCategoryOption[] => {
    const { activeUser, t } = this.props;

    return getActionItemCategoryOptions(
      t,
      operationAllowed(activeUser, 'write', 'reservationConfirmation')
    );
  };

  getAllColumns = (): ColumnType[] => {
    const { locale, t } = this.props;

    const actionItemOptions = this.getActionItemOptions().map(
      ({ key, text, value }) => ({
        key,
        text,
        value,
      })
    );

    return [
      {
        Header: t('Action item'),
        id: 'action_item',
        accessor: (row) => actionItemDescription(row, t),
        sortable: false,
        Cell: (cellInfo) => {
          return (
            <ActionItemCell
              width={cellInfo.width}
              task={cellInfo.original}
              color={getColorForTask(cellInfo.original)}
              onTaskChange={this.fetchTasksAndCounts}
            />
          );
        },
        Filter: ({ filter, onChange }) => {
          const f = (filter && filter.value) || 'ALL';
          return (
            <Input size="small">
              <Dropdown
                search
                selection
                onChange={(e, { value }) => {
                  onChange(value);
                }}
                value={f}
                options={actionItemOptions}
              />
            </Input>
          );
        },
        minWidth: 200,
        width: 415,
      },
      {
        Header: t('Booking Source'),
        id: 'bookingSource',
        accessor: (row) => row.bookingSourceAgent || t(row.bookingSourceType),
        filterable: false,
        sortable: false,
      },
      {
        Header: t('(Agent/Guest) Last Updated By'),
        id: 'agentGuestLastUpdatedByFullText',
        accessor: (row: TaskShape) => row.agentGuestLastUpdatedByFullText,
        filterable: false,
        sortable: false,
      },
      {
        Header: t('(Supplier) Last Updated By'),
        id: 'supplierLastUpdatedByFullText',
        accessor: (row: TaskShape) => row.supplierLastUpdatedByFullText,
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Participation'),
        id: 'participationDateTime',
        accessor: (row) =>
          row.participationDateTime &&
          `${row.participationDateTime
            .locale(locale)
            .format('lll')} (GMT${row.participationDateTime
            .locale(locale)
            .format('Z')})`,
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Product Name'),
        id: 'productName',
        accessor: 'productName',
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Customer'),
        id: 'customerName',
        accessor: 'customerFullName',
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Pickup/Checkin Time'),
        id: 'pickupCheckinTime',
        accessor: (row) =>
          row.pickupCheckinTime &&
          moment(row.pickupCheckinTime).locale(locale).format('LT'),
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Pickup/Checkin Location'),
        id: 'pickupCheckinLocation',
        accessor: 'pickupCheckinLocation',
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Application Number'),
        id: 'agentReference',
        accessor: 'agentReference',
        Cell: (cellInfo) => (
          <Link to={`/reservations/${cellInfo.original.reservationId}`}>
            {cellInfo.original.agentReference}
          </Link>
        ),
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Confirmation Number'),
        id: 'supplierReference',
        accessor: 'supplierReference',
        Cell: (cellInfo) => (
          <Link to={`/reservations/${cellInfo.original.reservationId}`}>
            {cellInfo.original.supplierReference}
          </Link>
        ),
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Supplier Name'),
        id: 'supplierName',
        accessor: 'supplierName',
        filterable: false,
        sortable: false,
      },
      {
        Header: t('(Internal) Last Updated By'),
        id: 'agentGuestLastUpdatedByActorNameOnly',
        accessor: (row: TaskShape) => row.agentGuestLastUpdatedByActorNameOnly,
        filterable: false,
        sortable: false,
      },
      {
        Header: t('(Internal) Last Updated By'),
        id: 'supplierLastUpdatedByActorNameOnly',
        accessor: (row: TaskShape) => row.supplierLastUpdatedByActorNameOnly,
        filterable: false,
        sortable: false,
      },
      {
        Header: t('Supplier Reservation Short ID'),
        id: 'supplierShortReference',
        accessor: 'supplierShortReference',
        filterable: false,
        sortable: false,
      },
    ];
  };

  getColumns = (columnMask: string[]): ColumnType[] => {
    const { allColumns } = this.state;
    return columnMask.map((c) => (allColumns.find((col) => col.id === c): any));
  };

  render() {
    const {
      columnCandidates,
      onVisibleColumnsChange,
      pageSize,
      t,
      taskCounts,
      taskFilters,
      visibleColumns,
    } = this.props;

    const nextButtonDisabledProp = {
      disabled: this.props.nextPageToken === null,
    };

    const columnsToShow = this.getColumns(visibleColumns);
    const allCandidateColumns = this.getColumns(columnCandidates);
    const actionItemOptions = this.getActionItemOptions();

    return (
      <Segment attached="bottom">
        <Grid>
          <Grid.Row>
            <Grid.Column width={1}>
              <Button.Group size="tiny" basic style={{ marginBottom: '5px' }}>
                <Modal
                  trigger={
                    <Button basic icon>
                      <Icon name="setting" />
                    </Button>
                  }
                  style={{
                    marginTop: 0,
                  }}
                >
                  <Modal.Header>{t('Show columns')}</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <ToggleableDndList
                        selectedItems={columnsToShow.map((col) => ({
                          key: col.id,
                          text: col.Header,
                        }))}
                        candidateItems={allCandidateColumns.map((col) => ({
                          key: col.id,
                          text: col.Header,
                        }))}
                        setSelectedItems={(items) =>
                          onVisibleColumnsChange(items.map((i) => i.key))
                        }
                      />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Button.Group>
            </Grid.Column>
            <Grid.Column width={15}>
              {actionItemOptions.map((option) => (
                <Label
                  key={option.key}
                  as="a"
                  color={option.color}
                  onClick={() => {
                    const { onFiltersChange, taskFilters } = this.props;
                    const newFilters = [
                      ...taskFilters.filter((f) => f.id !== 'action_item'),
                      {
                        id: 'action_item',
                        value: option.value,
                      },
                    ];
                    onFiltersChange(newFilters);
                    this.handleFetchData();
                  }}
                >
                  {option.text}
                  <Label.Detail>
                    {option.countAccessor(taskCounts)}
                  </Label.Detail>
                </Label>
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ReactTable
          data={this.props.tasks}
          columns={columnsToShow}
          loading={this.props.loading}
          filterable
          manual
          minRows={7}
          pageSizeOptions={[14, 25, 50, 100]}
          defaultPageSize={pageSize}
          onPageSizeChange={this.handlePageSizeChange}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPageJump={false}
          NextComponent={() => (
            <Button
              fluid
              onClick={this.handleOnClickNext}
              {...nextButtonDisabledProp}
              className="-btn"
            >
              {t('Next Page')}
            </Button>
          )}
          PreviousComponent={() => (
            <Button
              fluid
              onClick={this.handleOnClickPrevious}
              disabled={this.props.previousPageToken === null}
              className="-btn"
            >
              {t('Previous Page')}
            </Button>
          )}
          getPaginationProps={() => ({
            ofText: '',
            renderTotalPagesCount: () => null,
          })}
          page={this.state.page}
          onFilteredChange={(filtered) => {
            this.props.onFiltersChange(filtered);
            this.handleFetchData();
          }}
          filtered={taskFilters}
          {...translatedReactTableProps(t)}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: { ...OwnProps, ...TranslatorProps }
) => ({
  activeUser: activeUserSelector(state),
  columnCandidates: dashColumnCandidatesSelector(state),
  visibleColumns: dashVisibleColumnsSelector(state),
  tasks: taskShapesSelector(state, ownProps),
  taskCounts: state.tasks.taskCounts,
  loading: state.tasks.loading,
  locale: state.language.selected.iso,
  monthYearFormat: state.language.selected.monthYearFormat,
  invalidated: state.userDataInvalidated,
  pageTokens: state.tasks.pageTokens,
  previousPageToken:
    state.tasks.pageTokens.allTokens[state.tasks.pageTokens.currToken].previous,
  nextPageToken:
    state.tasks.pageTokens.allTokens[state.tasks.pageTokens.currToken].next,
  pageSize: state.tasks.pageSize,
  taskFilters: state.tasks.filters,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchTasks: () => dispatch(fetchTasks()),
  fetchTaskCounts: () => dispatch(fetchTaskCounts()),
  onFiltersChange: (filters: Object[]) => dispatch(setTasksFilters(filters)),
  onPageSizeChange: (pageSize: number) => dispatch(setTasksPageSize(pageSize)),
  onVisibleColumnsChange: (visibleColumns: string[]) =>
    dispatch(setDashVisibleColumns(visibleColumns)),
  updateTasksPageTokens: (pageTokens: Object) =>
    dispatch(updateTasksPageTokens(pageTokens)),
});

export const Dash = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(DashComponent);
