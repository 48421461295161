import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Input, TextArea } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getValidators } from 'shared/libraries/validate/validator';
import type { Product, Product$Patch } from 'shared/models/swagger';
import styles from 'client/pages/ProductDetails/ProductContentsHeader/HTMLTextsEditorModal/HTMLTextsEditorModal.module.css';

type FormValues = {
  htmlTexts: {
    key: string;
    text: string;
  }[];
};

const getInitialValues = (product: Product | null): FormValues => {
  return {
    htmlTexts: (product?.html_texts || []).map((htmlText) => ({
      key: htmlText.key ?? '',
      text: htmlText.text ?? '',
    })),
  };
};

const convertFormValuesToProductPatch = (values: FormValues): Product$Patch => {
  return {
    html_texts: values.htmlTexts.map((htmlText) => ({
      key: htmlText.key,
      text: htmlText.text,
    })),
  };
};

const getDefaultHTMLText = () => {
  return {
    key: '',
    title: '',
  };
};

type Props = {
  onClose: () => void;
};
export const HTMLTextsEditorModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('HTML Texts')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <FieldArray name="htmlTexts">
        {({ fields }) =>
          fields.length === 0 ? (
            <AddIcon
              onClick={() => (fields as any).insertAt(0, getDefaultHTMLText())}
            />
          ) : (
            <div className={styles['edit-container']}>
              {fields.map((name, idx) => (
                <div key={name} className={styles['edit-row']}>
                  <div className={styles['input-cell']}>
                    <Field name={`${name}.key`} validate={required}>
                      {({ input, meta: { touched, error } }) => (
                        <Input
                          {...input}
                          label={t('Key')}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={styles['input-cell']}>
                    <Field name={`${name}.text`} validate={required}>
                      {({ input, meta: { touched, error } }) => (
                        <TextArea
                          {...(input as any)}
                          maxHeight="200px"
                          label={t('HTML Text')}
                          error={touched && error}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={styles['button-cell']}>
                    <AddIcon
                      onClick={() =>
                        (fields as any).insertAt(idx + 1, getDefaultHTMLText())
                      }
                    />
                    <DeleteIcon onClick={() => fields.remove(idx)} />
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </FieldArray>
    </ProductEditorModal>
  );
};
