import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { formatTextWithLineBreaks } from 'client/libraries/util/formatTextWithLineBreaks';

import styles from './ETicketRedemptionButtonPreview.module.css';

interface Props {
  buttonText: string | null | undefined;
  buttonColor: string | null | undefined;
  buttonTextColor: string | null | undefined;
}

export const ETicketRedemptionButtonPreview = ({
  buttonText,
  buttonColor,
  buttonTextColor,
}: Props) => {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(styles['btn'], styles['btn--submit'])}
      style={{
        backgroundColor: buttonColor ?? '#000000',
        color: buttonTextColor ?? '#ffffff',
      }}
    >
      {buttonText ? formatTextWithLineBreaks(buttonText) : t('Redeem')}
    </button>
  );
};
