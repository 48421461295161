import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ReduxState } from 'client/reducers';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './BookingsGadget.module.css';
import { ChangeLabel } from './ChangeLabel';

const colors = ['#3B82F6', '#60A5FA', '#93C5FD', '#BFDBFE', '#DBEAFE'];

export const BookingsGadget = () => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.reportData
  );
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const eventDataLoading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const loading = eventDataLoading || productsLoading;

  const baseDataSet = reportDataSets[0];
  const baseTotals = baseDataSet?.totals;

  const baseConversions = baseTotals?.conversion_count ?? 0;

  const baseReservationsByProduct =
    baseTotals?.reservation_count_by_product ?? [];

  const sortedReservationsByProduct = _.orderBy(
    baseReservationsByProduct,
    'reservation_count',
    'desc'
  );

  const pieData = [
    ...sortedReservationsByProduct.slice(0, 4).map((reservationCount, idx) => ({
      name:
        allProducts?.find((p) => p.id === reservationCount.product_id)
          ?.internal_product_name ?? '',
      value: reservationCount.reservation_count,
      color: colors[idx],
    })),
  ];

  if (sortedReservationsByProduct.length > 4) {
    const otherReservations = sortedReservationsByProduct
      .slice(4)
      .reduce((acc, cur) => acc + (cur.reservation_count ?? 0), 0);

    pieData.push({
      name: t('Other'),
      value: otherReservations,
      color: colors[4],
    });
  }

  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;
  const comparisonTotals = comparisonDataSet?.totals;

  const comparisonConversions = comparisonTotals?.conversion_count ?? 0;

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Bookings')}</div>
          </div>
        </div>
        <div className={styles['body']} style={{ minHeight: '200px' }}>
          {loading ? (
            <Loading size="md" />
          ) : (
            <>
              <div className={styles['chart']}>
                <div className={styles['amount']}>
                  <div className={styles['amount-inner']}>
                    <div className={styles['amount-text']}>
                      {baseConversions.toLocaleString()}
                    </div>
                  </div>
                  {comparisonDataSet != null && (
                    <div className={styles['amount-inner']}>
                      <div className={styles['amount-unit']}>
                        {comparisonConversions.toLocaleString()}
                      </div>
                    </div>
                  )}
                </div>
                <ResponsiveContainer width="100%" height="100%" debounce={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      outerRadius="90%"
                      innerRadius="70%"
                      paddingAngle={0}
                      startAngle={225}
                      endAngle={-45}
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={index} fill={entry.color} />
                      ))}
                    </Pie>
                    <RechartsTooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className={styles['text-section']}>
                {comparisonDataSet != null && (
                  <ChangeLabel
                    amountChange={baseConversions - comparisonConversions}
                    unit={locale === 'ja' ? '件' : ''}
                  />
                )}
                {pieData.map((entry, index) => (
                  <div key={index} className={styles['legend-item']}>
                    <div
                      className={styles['legend-dot']}
                      style={{ backgroundColor: entry.color }}
                    />
                    <div>{entry.name}</div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
