import { connect } from 'react-redux';
import moment from 'moment-timezone';
import compose from 'lodash/fp/compose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

import { Loading } from 'client/pages/Loading';
import { ConfirmReservationButtonV2 as ConfirmReservationButton } from 'client/components/ConfirmReservationButton';
import { StandbyReservationButtonV2 as StandbyReservationButton } from 'client/components/StandbyReservationButton';
import { DeclineReservationButtonV2 as DeclineReservationButton } from 'client/components/DeclineReservationButton';
import { WithdrawReservationButtonV2 as WithdrawReservationButton } from 'client/components/WithdrawReservationButton';
import { CancelWithFeeModalV2 as CancelWithFeeModal } from 'client/pages/ReservationDetails/CancelWithFeeModalV2';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { CancelModalV2 as CancelModal } from 'client/pages/ReservationDetails/CancelModalV2';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import {
  filteredStatuses,
  getCancellationDeadline,
  getCancellationText,
  getReservationWeightedParticipantCount,
} from 'client/libraries/util/util';
import type {
  Reservation,
  Product,
  ProductInstance,
} from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';
import { Button } from 'client/components/v3/Common/Button';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

type TranslatorProps = WithTranslation;

type OwnProps = {
  readOnly?: boolean;
  reservation: Reservation;
  productInstance?: ProductInstance;
  product?: Product;
};

type Props = OwnProps & TranslatorProps & ReturnType<typeof mapStateToProps>;

const isResubmittableStatus = (status: string) => {
  switch (status) {
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
      return true;
    default:
      return false;
  }
};

const StatusStepsComponent: React.FC<Props> = (props) => {
  const {
    activeUser,
    locale,
    readOnly,
    reservation,
    product,
    productInstance,
    t,
  } = props;

  const { partnershipMode } = React.useContext(PartnershipModeContext);

  const cancelDeadline = getCancellationDeadline(reservation, product);

  const statuses = filteredStatuses(reservation.status_history || []);

  const currentParticipantCount =
    productInstance?.weighted_participant_count || 0;
  const resParticipantCount = getReservationWeightedParticipantCount(
    reservation,
    productInstance
  );
  const participantCountAfterCancel =
    currentParticipantCount - resParticipantCount;

  let cancelWarning = '';
  const minParticipantCount =
    (product?.minimum_participant_count &&
      product?.minimum_participant_count.value) ||
    0;
  if (
    minParticipantCount > 0 &&
    participantCountAfterCancel < minParticipantCount
  ) {
    cancelWarning = t(
      'After cancelling this booking, the participant count for the date and time will be below minimum participant requirements.'
    );
  }

  const userIsPassthroughAgent =
    reservation.agent_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'AGENT';
  const userIsPassthroughSupplier =
    reservation.supplier_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'SUPPLIER';
  const userIsPassthroughOrg =
    userIsPassthroughAgent || userIsPassthroughSupplier;

  const userCanConfirmReservation =
    !readOnly &&
    !userIsPassthroughOrg &&
    operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;
  const userCanSetCancellationFee =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationCancellationFee') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;
  const userCanCancelReservation =
    !readOnly &&
    !userIsPassthroughAgent &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST');
  const userCanWithdrawReservation =
    !readOnly &&
    !userIsPassthroughAgent &&
    !operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;
  const userCanResubmitReservation =
    !readOnly &&
    !userIsPassthroughOrg &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  let packagePendingThirdPartyConfirmation = false;
  const packageComponentReservations =
    reservation.package_component_reservation_summaries || [];
  if (
    packageComponentReservations.length > 0 &&
    reservation.status === 'REQUESTED'
  ) {
    packagePendingThirdPartyConfirmation = true;
    for (const packageComponentReservation of packageComponentReservations) {
      if (
        !packageComponentReservation.supplier_side_passthrough_reservation_id &&
        packageComponentReservation.status !== 'CONFIRMED'
      ) {
        packagePendingThirdPartyConfirmation = false;
      }
    }
  }

  if (!product || !productInstance) {
    return <Loading />;
  }

  return (
    <>
      {statuses.map((s, idx) =>
        (() => {
          const isActive = statuses.length - 1 === idx;

          return (
            <React.Fragment key={idx}>
              <>
                {!isActive ? (
                  // TODO: this was statusChangedAt
                  <></>
                ) : (
                  <>
                    {s.status === 'REQUESTED' ? (
                      userCanConfirmReservation ? (
                        <>
                          {packagePendingThirdPartyConfirmation ? (
                            <div>{t('Pending third-party confirmation')}</div>
                          ) : (
                            <>
                              <ConfirmReservationButton
                                reservationID={reservation.id}
                                trigger={
                                  <Button
                                    text={t('Confirm')}
                                    uiType="bg"
                                    size="md"
                                    color="primary"
                                  />
                                }
                              />
                              {!partnershipMode && (
                                <StandbyReservationButton
                                  reservationID={reservation.id}
                                  trigger={
                                    <Button
                                      text={t('Standby')}
                                      uiType="bg"
                                      size="md"
                                      color="white"
                                    />
                                  }
                                />
                              )}
                              <DeclineReservationButton
                                reservationID={reservation.id}
                                trigger={
                                  <Button
                                    text={t('Decline')}
                                    uiType="bg"
                                    size="md"
                                    color="tertiaryred"
                                  />
                                }
                              />
                            </>
                          )}
                        </>
                      ) : userCanWithdrawReservation ? (
                        <>
                          <WithdrawReservationButton
                            reservationID={reservation.id}
                            trigger={
                              <Button
                                text={t('Withdraw')}
                                uiType="bg"
                                size="md"
                                color="tertiaryred"
                              />
                            }
                          />
                        </>
                      ) : null
                    ) : userCanConfirmReservation && s.status === 'STANDBY' ? (
                      <>
                        <ConfirmReservationButton
                          reservationID={reservation.id}
                          trigger={
                            <Button
                              text={t('Confirm')}
                              uiType="bg"
                              size="md"
                              color="primary"
                            />
                          }
                        />
                        <DeclineReservationButton
                          reservationID={reservation.id}
                          trigger={
                            <Button
                              text={t('Decline')}
                              uiType="bg"
                              size="md"
                              color="tertiaryred"
                            />
                          }
                        />
                      </>
                    ) : s.status === 'CONFIRMED' ||
                      s.status === 'CANCEL_DECLINED_BY_SUPPLIER' ? (
                      userCanSetCancellationFee ? (
                        <CancelWithFeeModal
                          reservation={reservation}
                          warning={cancelWarning}
                          trigger={
                            <Button
                              text={t('Cancel')}
                              uiType="bg"
                              size="md"
                              color="tertiaryred"
                            />
                          }
                        />
                      ) : (
                        <>
                          {moment(cancelDeadline).isBefore(moment()) ? (
                            // TODO: this was Cancel deadline expired date
                            <></>
                          ) : userCanCancelReservation ? (
                            <CancelModal
                              reservationID={reservation.id}
                              text={t(
                                '{{cancelFeeDescription}} Are you sure you wish to cancel?',
                                {
                                  cancelFeeDescription: getCancellationText(
                                    reservation,
                                    product,
                                    productInstance,
                                    t,
                                    locale
                                  ),
                                }
                              )}
                              trigger={
                                <Button
                                  text={t('Cancel')}
                                  uiType="bg"
                                  size="md"
                                  color="tertiaryred"
                                />
                              }
                            />
                          ) : null}
                        </>
                      )
                    ) : (
                      <>
                        {isResubmittableStatus(s.status) &&
                          userCanResubmitReservation && (
                            <Link
                              // TODO: remove v3 from url later
                              to={{
                                pathname: `/products/${
                                  product?.id || ''
                                }/instances/${
                                  productInstance?.id || ''
                                }/book-v3`,
                                state: {
                                  reservation,
                                  isResubmitReservation: true,
                                },
                              }}
                            >
                              <Button
                                text={t('Resubmit booking')}
                                uiType="bg"
                                size="md"
                                color="primary"
                              />
                            </Link>
                          )}
                      </>
                    )}
                  </>
                )}
              </>
            </React.Fragment>
          );
        })()
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    locale: state.language.selected.iso,
    activeUser: activeUserSelector(state),
  };
};

export const StatusRelatedButtons = compose(
  connect(mapStateToProps, null),
  withTranslation()
)(StatusStepsComponent);

export default StatusRelatedButtons;
