import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomTable } from 'client/components/CustomTable/CustomTable';
import type { ReduxState } from 'client/reducers';
import {
  currentActorSelector,
  loggedInAccountSelector,
  activeUserOrganizationSelector,
  impersonatedAccountSelector,
  alternateOrganizationIsActiveSelector,
} from 'client/reducers/user';
import { fetchReservationDataCsvExportOrders } from 'client/actions/reservationDataCsvExportOrders';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';
import { Loading } from 'client/pages/Loading';
import { InputActionOwnerNameModal } from 'client/components/InputActionOwnerNameModal/InputActionOwnerNameModal';

import { useDownloadListColumns } from './utils';

export const DownloadListTab = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const actor = useSelector(currentActorSelector);
  const alternateOrganizationIsActive = useSelector(
    alternateOrganizationIsActiveSelector
  );
  const reservationDataCsvExportOrders = useSelector(
    (state: ReduxState) => state.reservationDataCsvExportOrders.all
  );
  const [downloading, setDownloading] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [openInputDownloaderNameModal, setOpenInputDownloaderNameModal] =
    React.useState(false);
  const [
    downloadReservationDataCsvExportOrderId,
    setDownloadReservationDataCsvExportOrderId,
  ] = React.useState<string | null>(null);

  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const impersonatedAccount = useSelector(impersonatedAccountSelector);

  const loggedInAccount = useSelector(loggedInAccountSelector);

  React.useEffect(() => {
    if (!downloading) {
      dispatch(fetchReservationDataCsvExportOrders());
    }
  }, [downloading]);

  React.useEffect(() => {
    dispatch(fetchReservationDataCsvExportOrders());
  }, [activeUserOrganization]);

  React.useEffect(() => {
    setInitialLoad(true);
  }, [activeUserOrganization]);

  React.useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [reservationDataCsvExportOrders]);

  const [items, setItems] = React.useState<any>([]);
  React.useEffect(() => {
    const newItems = reservationDataCsvExportOrders.map((item, index) => {
      return {
        ...item,
        onDownload: () => {
          if (item.id) {
            setDownloadReservationDataCsvExportOrderId(item.id);
            setOpenInputDownloaderNameModal(true);
          }
        },
        index,
        timezone: activeUserOrganization?.default_timezone ?? 'UTC',
      };
    });
    setItems(newItems);
  }, [reservationDataCsvExportOrders]);

  const download = async (name: string) => {
    try {
      setDownloading(true);
      const response = await fetch(
        `/api/reservationdataexportorders/${
          downloadReservationDataCsvExportOrderId ?? ''
        }/downloadurl?name=${name}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            'accept-language': i18n.language,
            impersonate: impersonatedAccount
              ? buildImpersonateToken(impersonatedAccount)
              : '',
            'x-actor-name': encodeURIComponent(actor?.name ?? ''),
            'x-alt-org': alternateOrganizationIsActive ? 'true' : 'false',
          },
        }
      );
      const responseJson = await response.json();
      const a = document.createElement('a');
      a.href = responseJson.url;
      a.download = 'reservation-data.csv';
      a.click();
    } finally {
      setDownloading(false);
    }
  };

  const columns = useDownloadListColumns();
  return (
    <>
      {initialLoad ? (
        <Loading />
      ) : (
        <CustomTable
          items={items}
          columns={columns}
          usePaging={true}
          hideSeeMoreButton={true}
        />
      )}

      <InputActionOwnerNameModal
        initialName={loggedInAccount?.name ?? ''}
        open={openInputDownloaderNameModal}
        onClose={() => {
          setOpenInputDownloaderNameModal(false);
        }}
        onActionStart={(name: string) => {
          download(name);
        }}
        title={t('Download Reservation Data')}
        buttonText={t('Download')}
      />
    </>
  );
};
