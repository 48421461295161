// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import type { Dispatch } from 'redux';

import { updateManifestProductGroups } from 'client/actions/manifestSettings';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import type { ManifestProductGroup } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';

type OwnProps = {
  productGroupToDelete: {
    ...ManifestProductGroup,
  },
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};

/* eslint-enable no-use-before-define */
const DeleteProductGroupModalComponent = ({
  allProductGroups,
  productGroupToDelete,
  updateManifestProductGroups,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return productGroupToDelete ? (
    <Modal
      title={t('Delete Manifest Group')}
      open={open}
      onOpen={() => {}}
      onClose={onClose}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{name}}"?', {
          name: productGroupToDelete.key,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancel')}
        </Button.Cancel>

        <Button.Delete
          onClick={() => {
            const newProductGroups = allProductGroups.filter(
              (group) => group.key !== productGroupToDelete.key
            );
            updateManifestProductGroups(newProductGroups);
            onClose();
          }}
        >
          {t('Delete')}
        </Button.Delete>
      </Modal.Actions>
    </Modal>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  allProductGroups: manifestProductGroupsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateManifestProductGroups: (productGroups: ManifestProductGroup[]) =>
    dispatch(updateManifestProductGroups(productGroups)),
});

export const DeleteProductGroupModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteProductGroupModalComponent);
