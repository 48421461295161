import {
  SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION,
  SET_INQUIRY_ROW_COUNT,
  SET_INQUIRY_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchInquiriesRequest } from 'client/libraries/util/searchInquiries';

export const setLastExecutedInquirySearchCondition = (
  searchFilters: SearchInquiriesRequest
) => ({
  type: SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION,
  searchFilters,
});
export const setInquiryRowCount = (rowCount: number) => ({
  type: SET_INQUIRY_ROW_COUNT,
  rowCount,
});
export const setInquiryCurrentPage = (currentPage: number) => ({
  type: SET_INQUIRY_CURRENT_PAGE,
  currentPage,
});
