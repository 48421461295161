import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  BATCH_UPDATE_PRODUCT_PRICES_REQUEST,
  BATCH_UPDATE_PRODUCT_PRICES_SUCCESS,
  BATCH_UPDATE_PRODUCT_PRICES_FAILURE,
  DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST,
  DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS,
  DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE,
  DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST,
  DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS,
  DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE,
  FETCH_PRODUCT_PRICES_REQUEST,
  FETCH_PRODUCT_PRICES_FAILURE,
  FETCH_PRODUCT_PRICES_SUCCESS,
} from 'client/constants/ActionTypes';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { bookmarksSetSelector } from 'client/reducers/products';
import type { ReduxState } from 'client/reducers';
import type { ProductSummaryWithBookmark } from 'client/reducers/products';
import { sortedByBookmark } from 'client/libraries/util/sortedByBookmark';
import type { ProductSummary } from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_PRODUCT_PRICES_FAILURE:
    case BATCH_UPDATE_PRODUCT_PRICES_FAILURE:
      return action.error;

    case FETCH_PRODUCT_PRICES_SUCCESS:
    case BATCH_UPDATE_PRODUCT_PRICES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_PRODUCT_PRICES_REQUEST:
    case BATCH_UPDATE_PRODUCT_PRICES_REQUEST:
      return true;

    case FETCH_PRODUCT_PRICES_FAILURE:
    case FETCH_PRODUCT_PRICES_SUCCESS:
    case BATCH_UPDATE_PRODUCT_PRICES_SUCCESS:
    case BATCH_UPDATE_PRODUCT_PRICES_FAILURE:
      return false;

    default:
      return state;
  }
};

const summaries = (
  state: ProductSummary[] = [],
  action: any
): ProductSummary[] => {
  switch (action.type) {
    case FETCH_PRODUCT_PRICES_SUCCESS:
      return action.response.products;

    case BATCH_UPDATE_PRODUCT_PRICES_SUCCESS: {
      const updatedProductsById: Record<string, ProductSummary> = {};

      for (const product of action.response) {
        updatedProductsById[product.id] = product;
      }

      return state.map((product) =>
        product.id in updatedProductsById
          ? updatedProductsById[product.id]
          : product
      );
    }
    default:
      return state;
  }
};

export const productPricesSummariesSortedByBookmarkedSelector = createSelector(
  (state: ReduxState) => state.productPrices.summaries,
  bookmarksSetSelector,
  activeUserOrganizationSelector,
  (summaries, bookmarks, org): ProductSummary[] => {
    const porductSummariesWithBookmark = sortedByBookmark(
      summaries,
      bookmarks,
      org
    );
    return porductSummariesWithBookmark.map(
      (product: ProductSummaryWithBookmark) => {
        const { bookmarked: _removed, ...summaries } = product;
        return summaries;
      }
    );
  }
);

const lastUpdateBatch = (
  state: ProductSummary[] = [],
  action: any
): ProductSummary[] => {
  switch (action.type) {
    case BATCH_UPDATE_PRODUCT_PRICES_SUCCESS:
      return action.response;

    case FETCH_PRODUCT_PRICES_SUCCESS:
    case FETCH_PRODUCT_PRICES_FAILURE:
    case BATCH_UPDATE_PRODUCT_PRICES_FAILURE:
      // Any fetch of product prices clears the update batch
      return [];

    default:
      return state;
  }
};

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const csvStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

export const productPrices = combineReducers({
  error,
  lastUpdateBatch,
  loading,
  pdfStatus,
  csvStatus,
  summaries,
});
