import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import styles from './ResponseOptionsEditor.module.css';

interface Props {
  name: string;
}
export const ResponseOptionsEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName, translationTargetLanguage } =
    useTranslationTargetLanguage(t);

  return (
    <div className={styles['detailInfoEdit']}>
      <FieldArray name={name}>
        {({ fields }) =>
          fields.length === 0 ? (
            <AddIcon
              onClick={() =>
                (fields as any).insertAt(0, {
                  key: uuidv4(),
                  text: '',
                })
              }
            />
          ) : (
            fields.map((name, idx) => (
              <ul key={name} className={styles['detailInfoEditBody']}>
                <TranslatedField name={`${name}.text`}>
                  {({ input, translationInput }) => (
                    <>
                      <li>
                        <div className={baseStyles['base-flex']}>
                          <input
                            {...input}
                            className={baseStyles['base-form-text']}
                          />
                          <div className={baseStyles['base-flex']}>
                            <AddIcon
                              onClick={() =>
                                (fields as any).insertAt(idx + 1, {
                                  key: uuidv4(),
                                  text: '',
                                })
                              }
                            />
                            <DeleteIcon onClick={() => fields.remove(idx)} />
                          </div>
                        </div>
                      </li>
                      {translationTargetLanguage && (
                        <li>
                          <div className={baseStyles['base-flex']}>
                            <input
                              {...translationInput}
                              placeholder={translationTargetLanguageName}
                              className={baseStyles['base-form-text']}
                            />
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </TranslatedField>
              </ul>
            ))
          )
        }
      </FieldArray>
    </div>
  );
};
