import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  CREATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  DELETE_GROUP_BOOKING_TEMPLATE_FAILURE,
  DELETE_GROUP_BOOKING_TEMPLATE_REQUEST,
  DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS,
  FETCH_GROUP_BOOKING_TEMPLATES_FAILURE,
  FETCH_GROUP_BOOKING_TEMPLATES_REQUEST,
  FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS,
  FETCH_GROUP_BOOKING_TEMPLATE_FAILURE,
  FETCH_GROUP_BOOKING_TEMPLATE_REQUEST,
  FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS,
  UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE,
  UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST,
  UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GroupBookingTemplate,
  CreateGroupBookingTemplateResponse,
  ListGroupBookingTemplatesResponse,
  UpdateGroupBookingTemplateResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_GROUP_BOOKING_TEMPLATES_FAILURE:
    case FETCH_GROUP_BOOKING_TEMPLATE_FAILURE:
    case CREATE_GROUP_BOOKING_TEMPLATE_FAILURE:
    case UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE:
    case DELETE_GROUP_BOOKING_TEMPLATE_FAILURE:
      return action.error;

    case FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS:
    case FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_GROUP_BOOKING_TEMPLATES_REQUEST:
    case FETCH_GROUP_BOOKING_TEMPLATE_REQUEST:
    case CREATE_GROUP_BOOKING_TEMPLATE_REQUEST:
    case UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST:
    case DELETE_GROUP_BOOKING_TEMPLATE_REQUEST:
      return true;

    case FETCH_GROUP_BOOKING_TEMPLATES_FAILURE:
    case FETCH_GROUP_BOOKING_TEMPLATE_FAILURE:
    case CREATE_GROUP_BOOKING_TEMPLATE_FAILURE:
    case UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE:
    case DELETE_GROUP_BOOKING_TEMPLATE_FAILURE:
    case FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS:
    case FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS:
    case DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: GroupBookingTemplate[] = [],
  action: any
): GroupBookingTemplate[] => {
  switch (action.type) {
    case FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS: {
      const response = action.response as ListGroupBookingTemplatesResponse;
      return response.group_booking_templates;
    }

    case FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS: {
      const response = action.response as GroupBookingTemplate;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS: {
      const response = action.response as CreateGroupBookingTemplateResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS: {
      const response = action.response as UpdateGroupBookingTemplateResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedGroupBookingTemplate = (
  state: GroupBookingTemplate | null = null,
  action: any
) => {
  switch (action.type) {
    case CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS: {
      const response = action.response as CreateGroupBookingTemplateResponse;
      return response;
    }

    default:
      return state;
  }
};

export const groupBookingTemplates = combineReducers({
  error,
  loading,
  all,
  lastCreatedGroupBookingTemplate,
});
