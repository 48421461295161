import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { Button, FieldWrapper } from 'client/components/Form';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import editIcon from 'client/images/ic_edit.svg';
import { AccountReservationSearchPreset } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { Preset } from './ReservationSearchSettingsFormValues';
import { ReservationSearchSettingsDefaultColumnModal } from './ReservationSearchSettingsDefaultColumnModal';
import { EditAccountReservationSearchPresetModal } from './EditAccountReservationSearchPresetModal';

interface Props {
  submitting: boolean;
  onSubmit: () => any;
  submitSucceeded: boolean;
}

export const ReservationSearchSettingsForm = ({
  submitting,
  onSubmit,
  submitSucceeded,
}: Props) => {
  const { t } = useTranslation();
  const [deletingIdx, setDeletingIdx] = React.useState<number | null>(null);
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);
  const [showDefaultColumnModal, setShowDefaultColumnModal] =
    React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const allColumns = getReservationTableColumns(t, 'en').filter((col) => {
    return col.id !== 'edit';
  });

  const getColumns = (columnMask: string[]): any[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c));
  };

  const defaultColumns =
    activeUserOrganization?.account_reservation_search_settings
      ?.default_columns || [];

  const form = useForm();
  const { modified } = form.getState();

  React.useEffect(() => {
    if (submitSucceeded) {
      setShowDefaultColumnModal(false);
      setEditingIdx(null);
    }
  }, [submitSucceeded]);

  return (
    <>
      <Box mt={2}>
        <FieldWrapper
          label={
            <Box display="flex" alignItems="center">
              {t('Preset search condition')}
              <Tooltip
                text={t(
                  'You will be able to select preset search conditions on the reservation list to provide quick access for frequently used search conditions.'
                )}
              />
            </Box>
          }
        >
          <Field name="presets">
            {({ input }) => (
              <>
                <EditDeleteButtonDndList
                  items={input.value?.map(
                    (item: AccountReservationSearchPreset) => ({
                      key: item.key,
                      text: item.title,
                    })
                  )}
                  setItems={(items) => {
                    input.onChange(
                      items.map((item) =>
                        input.value?.find(
                          (preset: AccountReservationSearchPreset) =>
                            preset.key === item.key
                        )
                      )
                    );
                  }}
                  onRemove={(idx: number) => {
                    setDeletingIdx(idx);
                  }}
                  onEdit={(idx: number) => {
                    setEditingIdx(idx);
                  }}
                />

                <Box display="flex" mt={2}>
                  <Box mr={2}>
                    <Button
                      size="middle"
                      style="green"
                      onClick={() => setEditingIdx(input.value.length)}
                    >
                      {t('Add new search condition')}
                    </Button>
                  </Box>

                  <Button
                    disabled={!modified?.presets || submitting}
                    size="middle"
                    style="blue"
                    type="submit"
                    loading={submitting}
                  >
                    {t('Save Order')}
                  </Button>
                </Box>

                {deletingIdx != null && (
                  <DeleteConfirmModal
                    header={t('Delete search condition')}
                    content={t('Are you sure you want to delete "{{name}}"?', {
                      name: input.value[deletingIdx]?.title,
                    })}
                    onConfirm={async () => {
                      input.onChange(
                        input.value?.filter(
                          (_: any, idx: number) => idx !== deletingIdx
                        )
                      );
                      onSubmit();
                    }}
                    onClose={() => setDeletingIdx(null)}
                    open={deletingIdx != null}
                    insertRoot
                  />
                )}
                {editingIdx != null && (
                  <EditAccountReservationSearchPresetModal
                    existingItem={
                      editingIdx >= (input.value ?? []).length
                        ? undefined
                        : input.value[editingIdx]
                    }
                    onSave={(item: Preset) => {
                      if (editingIdx >= (input.value ?? []).length) {
                        input.onChange([...input.value, item]);
                      } else {
                        input.onChange(
                          input.value.map((existingItem: any, idx: number) =>
                            idx === editingIdx ? item : existingItem
                          )
                        );
                      }
                      onSubmit();
                    }}
                    open={editingIdx != null}
                    onClose={() => setEditingIdx(null)}
                    submitting={submitting}
                  />
                )}
              </>
            )}
          </Field>
        </FieldWrapper>

        <Box mt={2} mb={2}>
          <FieldWrapper
            label={
              <Box display="flex" alignItems="center">
                {t('preset.Default_columns')}
                <Tooltip
                  text={t(
                    'Select columns to display on reservation list after login.'
                  )}
                />
              </Box>
            }
          >
            <Box display="flex" alignItems="center">
              <button
                type="button"
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['icon'],
                  baseStyles['narrow']
                )}
                onClick={() => setShowDefaultColumnModal(true)}
              >
                <img src={editIcon} />
              </button>
              <div className={baseStyles['text']}>
                {getColumns(defaultColumns.map((d) => d.toLowerCase()))
                  .map((col) => col?.Header)
                  .join(', ')}
              </div>
            </Box>
            <ReservationSearchSettingsDefaultColumnModal
              submitting={submitting}
              open={showDefaultColumnModal}
              onClose={() => setShowDefaultColumnModal(false)}
              onSubmit={() => {
                onSubmit();
              }}
            />
          </FieldWrapper>
        </Box>
      </Box>
    </>
  );
};
