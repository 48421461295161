import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_E_TICKET_QR_CODES_SUCCESS,
  FETCH_E_TICKET_QR_CODES_FAILURE,
  FETCH_E_TICKET_QR_CODES_REQUEST,
  DELETE_E_TICKET_QR_CODE_FAILURE,
  DELETE_E_TICKET_QR_CODE_REQUEST,
  DELETE_E_TICKET_QR_CODE_SUCCESS,
  CREATE_E_TICKET_QR_CODE_REQUEST,
  UPDATE_E_TICKET_QR_CODE_FAILURE,
  UPDATE_E_TICKET_QR_CODE_SUCCESS,
  UPDATE_E_TICKET_QR_CODE_REQUEST,
  CREATE_E_TICKET_QR_CODE_FAILURE,
  CREATE_E_TICKET_QR_CODE_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { ETicketQrCodeParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchETicketQrCodesRequest = () => ({
  type: FETCH_E_TICKET_QR_CODES_REQUEST,
});

const fetchETicketQrCodesSuccess = (response: any) => ({
  type: FETCH_E_TICKET_QR_CODES_SUCCESS,
  response,
});

const fetchETicketQrCodesFailure = (error: string) => ({
  type: FETCH_E_TICKET_QR_CODES_FAILURE,
  error,
});

let fetchETicketQrCodesCancel: () => void | null;
export const fetchETicketQrCodes =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchETicketQrCodesCancel && fetchETicketQrCodesCancel();
    dispatch(fetchETicketQrCodesRequest());
    axios
      .get('/api/eticketqrcodes', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchETicketQrCodesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchETicketQrCodesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchETicketQrCodesFailure('canceled'));
        else dispatch(fetchETicketQrCodesFailure(error));
      });
  };

const deleteETicketQrCodeRequest = (id: string) => ({
  type: DELETE_E_TICKET_QR_CODE_REQUEST,
  id,
});
const deleteETicketQrCodeSuccess = (response: any, id: string) => ({
  type: DELETE_E_TICKET_QR_CODE_SUCCESS,
  response,
  id,
});
const deleteETicketQrCodeFailure = (error: string) => ({
  type: DELETE_E_TICKET_QR_CODE_FAILURE,
  error,
});
export const deleteETicketQrCode =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteETicketQrCodeRequest(id));
    axios
      .delete(`/api/eticketqrcodes/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteETicketQrCodeSuccess(result, id)))
      .catch((error) => dispatch(deleteETicketQrCodeFailure(error)));
  };

const createETicketQrCodeRequest = (newETicketQrCode: ETicketQrCodeParams) => ({
  type: CREATE_E_TICKET_QR_CODE_REQUEST,
  newETicketQrCode,
});
const createETicketQrCodeSuccess = (response: any) => ({
  type: CREATE_E_TICKET_QR_CODE_SUCCESS,
  response,
});
const createETicketQrCodeFailure = (error: string) => ({
  type: CREATE_E_TICKET_QR_CODE_FAILURE,
  error,
});

export const createETicketQrCode =
  (newETicketQrCode: ETicketQrCodeParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createETicketQrCodeRequest(newETicketQrCode));
    axios
      .post('/api/eticketqrcodes', newETicketQrCode, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createETicketQrCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createETicketQrCodeFailure(err.message));
      });
  };

const updateETicketQrCodeRequest = (
  id: string,
  patch: ETicketQrCodeParams
) => ({
  type: UPDATE_E_TICKET_QR_CODE_REQUEST,
  id,
  patch,
});
const updateETicketQrCodeSuccess = (response: any) => ({
  type: UPDATE_E_TICKET_QR_CODE_SUCCESS,
  response,
});
const updateETicketQrCodeFailure = (error: string) => ({
  type: UPDATE_E_TICKET_QR_CODE_FAILURE,
  error,
});

export const updateETicketQrCode =
  (id: string, patch: ETicketQrCodeParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateETicketQrCodeRequest(id, patch));
    axios
      .patch(`/api/eticketqrcodes/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateETicketQrCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateETicketQrCodeFailure(err.message));
      });
  };
