// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { AvailabilityDatesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/AvailabilityDatesInput';
import { BookingDeadlinesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/BookingDeadlinesInput';
import { CancellationPoliciesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/CancellationPoliciesInput';
import { StartTimesInput } from 'client/components/NewProductEditor/ReservationParamsSteps/StartTimesInput';
import { updateProduct } from 'client/actions/products';
import {
  convertReservationParamsFormValuesToProductPatch,
  getMinStartDate,
  getMaxEndDate,
} from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import { getArrayMutators } from 'client/libraries/util/form';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { ProductEditReservationPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditReservationPopUp';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import {
  convertToBookingDeadline,
  convertToCancellationPolicy,
  isDefaultCancellationPolicy,
} from 'client/libraries/util/productShape';
import type { BookingDeadline } from 'client/libraries/util/productShape';
import type { ReservationParamsFormValues } from 'client/components/NewProductEditor/ReservationParamsSteps/ReservationParamsFormValues';
import type { Product } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';

const focusOnError = createDecorator();

const getInitialValues = (
  product: Product | null
): ReservationParamsFormValues => {
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  const recurrenceRule =
    product?.recurrence && product.recurrence.length > 0
      ? product.recurrence.slice(-1)[0]
      : null;
  const allotmentRule =
    product?.allotment_rules && product.allotment_rules.length > 0
      ? product.allotment_rules.slice(-1)[0]
      : null;
  const startDate = recurrenceRule?.start_date_local ?? defaultStartDate;
  const endDate = recurrenceRule?.end_date_local ?? defaultEndDate;
  const weekdays = recurrenceRule?.days_of_week ?? [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ];
  const closedDates = _.sortBy(
    [
      ...(recurrenceRule?.closed_dates ?? []).map((closedDate) => ({
        date: closedDate,
        repeatsAnnually: false,
      })),
      ...(recurrenceRule?.annually_repeating_closed_dates ?? []).map(
        (closedDate) => ({
          date: closedDate,
          repeatsAnnually: true,
        })
      ),
    ],
    (closedDate) => closedDate.date
  );
  const freesaleSlotLimit = 10000000;

  const startTimes = recurrenceRule?.start_times
    ? recurrenceRule.start_times.map((startTime) => ({
        time: startTime.start_time_local,
        duration: startTime.duration,
        perChannelAllotments: [],
        allotmentSlots: allotmentRule?.start_time_allotments
          ? allotmentRule.start_time_allotments.find(
              (rule) => rule.time_slot_key === startTime.time_slot_key
            )?.common_allotment_slots ?? 0
          : 10,
        isFreesale:
          (allotmentRule?.start_time_allotments.find(
            (rule) => rule.time_slot_key === startTime.time_slot_key
          )?.common_allotment_slots ?? 0) >= freesaleSlotLimit,
        description: startTime.description ?? '',
        packageComponentTimeSlots: [],
      }))
    : [
        {
          time: '9:00',
          duration: '3:00',
          perChannelAllotments: [],
          allotmentSlots: 10,
          description: '',
          packageComponentTimeSlots: [],
        },
      ];

  let instantBookingDeadline: BookingDeadline;
  let requestBookingDeadline: BookingDeadline;

  const productHasBookingDeadlines =
    product?.booking_deadlines != null && product.booking_deadlines.length > 0;
  const productInstantBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'INSTANT'
  );
  const productRequestBookingDeadline = product?.booking_deadlines?.find(
    (bookingDeadline) => bookingDeadline.confirmation_type === 'REQUEST'
  );
  if (productHasBookingDeadlines) {
    if (productInstantBookingDeadline) {
      instantBookingDeadline = convertToBookingDeadline(
        productInstantBookingDeadline
      );
    }
    if (productRequestBookingDeadline) {
      requestBookingDeadline = convertToBookingDeadline(
        productRequestBookingDeadline
      );
    }
  } else {
    instantBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 2,
      timeOfDay: '17:00',
    };
    requestBookingDeadline = {
      deadlineType: 'DAY',
      daysBefore: 1,
      timeOfDay: '17:00',
    };
  }

  const cancellationPolicies =
    product?.cancellation_policies != null &&
    product.cancellation_policies.length > 0
      ? product.cancellation_policies
          .filter(
            (cancellationPolicy) =>
              !isDefaultCancellationPolicy(cancellationPolicy)
          )
          .map((cancellationPolicy) =>
            convertToCancellationPolicy(cancellationPolicy)
          )
      : [
          {
            deadlineType: 'DAY',
            daysBefore: 2,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '0',
          },
          {
            deadlineType: 'DAY',
            daysBefore: 1,
            timeOfDay: '17:00',
            feeType: 'PERCENT',
            feePercent: '50',
          },
        ];

  return {
    dateRanges: [
      {
        startDate,
        endDate,
      },
    ],
    weekdays,
    startTimes,
    closedDates,
    instantBookingDeadline,
    requestBookingDeadline,
    cancellationPolicies,
    agentGuestBookingAllowedDaysBefore:
      product?.agent_guest_booking_period_settings
        ?.booking_allowed_days_before_participation ?? null,
  };
};

type Props = {
  onNextClick: () => void,
  onBackClick: () => void,
};

export const ReservationParamsStep = ({ onNextClick, onBackClick }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  const editingProduct = React.useContext(EditingProductContext);

  const [showPopUp, setShowPopUp] = React.useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );

  const initialValues = React.useMemo(
    () => getInitialValues(editingProduct),
    []
  );

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__header']}>
        <div className={baseStyles['base-main__body__box__header__ttl']}>
          {t('STEP 2 - Reservation Parameters')}
        </div>
      </div>
      <div className={baseStyles['base-main__body__box__body']}>
        <Form
          onSubmit={async (values: ReservationParamsFormValues) => {
            try {
              await dispatch(
                updateProduct(editingProduct?.id || '', {
                  ...convertReservationParamsFormValuesToProductPatch(
                    values,
                    isPackage,
                    defaultCurrency
                  ),
                  pricing: (editingProduct?.pricing ?? []).map(
                    (priceRule) =>
                      ({
                        ...priceRule,
                        start_date_local: getMinStartDate(values.dateRanges),
                        end_date_local: getMaxEndDate(values.dateRanges),
                      }: any)
                  ),
                })
              );

              await onNextClick();
              window.scrollTo(0, 0);
            } catch (err) {
              return { [FORM_ERROR]: t('Save Failed') };
            }
          }}
          decorators={[focusOnError]}
          initialValues={initialValues}
          mutators={getArrayMutators()}
        >
          {({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              <p className={styles['page-tutorial__ttl']}>
                {t('Register information required to accept reservations')}
              </p>

              <div className={styles['c-table-list']}>
                <table>
                  <tr>
                    <th>{t('Availability Settings')}</th>
                    <td>
                      <AvailabilityDatesInput />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Times and Inventory')}</th>
                    <td>
                      <StartTimesInput
                        showAllotment={!isPackage && !isSharedAllotment}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Booking Deadlines')}</th>
                    <td>
                      <BookingDeadlinesInput />
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Cancellation Policies')}</th>
                    <td>
                      <CancellationPoliciesInput />
                    </td>
                  </tr>
                </table>
              </div>

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button
                  type="button"
                  size="small"
                  style="gray"
                  onClick={onBackClick}
                >
                  {t('Back')}
                </Button>
                <Button
                  type="submit"
                  size="small"
                  style="blue"
                  loading={submitting}
                >
                  {t('Next')}
                </Button>
              </div>
              {isTutorialActive && showHelpPopUp && (
                <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
              )}
            </form>
          )}
        </Form>
      </div>
      {isTutorialActive && showPopUp && (
        <ProductEditReservationPopUp popUpDisable={() => setShowPopUp(false)} />
      )}
    </div>
  );
};
