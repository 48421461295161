import moment from 'moment-timezone';

import { parseDuration } from 'client/libraries/util/util';
import type { TimeSlot } from 'client/libraries/util/getTimeSlots';

export const formattedTimeSlot = (timeSlot: TimeSlot): string => {
  const formattedStartTimes: string[] = [];

  for (const startTime of timeSlot.startTimes) {
    formattedStartTimes.push(
      `${formattedStartTime(startTime)}${
        startTime.startDate && moment(startTime.startDate).isAfter(moment())
          ? `(${startTime.startDate} ~ )`
          : ''
      }`
    );
  }

  return formattedStartTimes.join(' / ');
};

const formattedStartTime = (startTime: {
  startTimeLocal: string;
  duration: string;
}): string => {
  const { hours, minutes } = parseDuration(startTime.duration);
  let durationString = '';

  if (hours) {
    durationString += `${hours}h`;
  }

  if (minutes) {
    durationString += `${minutes}m`;
  }

  if (durationString) {
    durationString = `[${durationString}]`;
  }

  return `${startTime.startTimeLocal}${durationString}`;
};
