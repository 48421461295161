import * as React from 'react';
import { Field, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { Box } from 'client/components/Box/Box';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { getStartTimesFromRecurrence } from 'client/libraries/util/getStartTimesFromRecurrence';
import { timePlusOffset } from 'client/libraries/util/util';
import { DurationInput } from 'client/components/NewProductEditor/DurationInput/DurationInput';
import {
  FieldWrapper,
  Input,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import styles from '../DetailsStep.module.css';

import { LocationListEditor } from './LocationListEditor';
import { isDropoffType, isPickupType, PerStartTimeOffset } from './FormValues';
import type { LocationType, LocationWithTime } from './FormValues';

const isPickupCheckinType = (type: LocationType): boolean => {
  return (
    type === 'PICKUP_DROPOFF' ||
    type === 'PICKUP_ONLY' ||
    type === 'CHECKIN_CHECKOUT' ||
    type === 'CHECKIN_ONLY'
  );
};

const isDropoffCheckoutType = (type: LocationType): boolean => {
  return (
    type === 'PICKUP_DROPOFF' ||
    type === 'DROPOFF_ONLY' ||
    type === 'CHECKIN_CHECKOUT' ||
    type === 'CHECKOUT_ONLY'
  );
};

const getDefaultPickupLocation = (): LocationWithTime => {
  return {
    type: 'PICKUP_DROPOFF',
    timeOffsetCheckinPickup: '0:00',
    timeOffsetCheckoutDropoff: '0:00',
    location: {
      locationName: '',
      googlePlaceId: '',
    },
    description: '',
    imageUrls: [],
    perStartTimeOffsets: [],
    premappedLocations: [],
  };
};

type PerStartTimeOffsetsEditorProps = {
  name: string;
  type: LocationType;
};

const PerStartTimeOffsetsEditor = ({
  name,
  type,
}: PerStartTimeOffsetsEditorProps) => {
  const { t } = useTranslation();
  const [selectedTimeSlotKey, setSelectedTimeSlotKey] = React.useState<
    string | null
  >(null);
  const editingProduct = React.useContext(EditingProductContext);
  const startTimes = React.useMemo(
    () => getStartTimesFromRecurrence(editingProduct?.recurrence || []),
    [editingProduct]
  );
  const { input: perStartTimeOffsets } = useField<PerStartTimeOffset[]>(name);
  const unusedStartTimes = React.useMemo(() => {
    return startTimes.filter(
      (startTime) =>
        !perStartTimeOffsets.value?.some(
          (perStartTimeOffset) =>
            perStartTimeOffset.timeSlotKey === startTime.timeSlotKey
        )
    );
  }, [perStartTimeOffsets.value, startTimes]);
  React.useEffect(() => {
    setSelectedTimeSlotKey(
      unusedStartTimes.length > 0 ? unusedStartTimes[0].timeSlotKey : null
    );
  }, [unusedStartTimes]);
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        return (
          <>
            {' '}
            <p className={styles['scheds__ttl']}>
              {t('Register different settings per start time (optional)')}
            </p>
            <div className={styles['scheds__body']}>
              {unusedStartTimes.length > 0 && (
                <div className={styles['select']}>
                  <div className={styles['select__box']}>
                    <label className={baseStyles['base-form-select']}>
                      <select
                        value={selectedTimeSlotKey ?? undefined}
                        onChange={(e) => {
                          setSelectedTimeSlotKey(e.target.value);
                        }}
                      >
                        {unusedStartTimes.map((startTime, idx) => (
                          <option key={idx} value={startTime.timeSlotKey}>
                            {startTime.hhMm}
                          </option>
                        ))}
                      </select>
                    </label>
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['green'],
                        baseStyles['small'],
                        selectedTimeSlotKey == null && baseStyles['is-disabled']
                      )}
                      onClick={() => {
                        if (selectedTimeSlotKey != null) {
                          fields.push({
                            timeSlotKey: selectedTimeSlotKey,
                            timeOffsetCheckinPickup: '0:00',
                            timeOffsetCheckoutDropoff: '0:00',
                          });
                        }
                      }}
                    >
                      {t('Add rule for start time')}
                    </a>
                  </div>
                </div>
              )}
              {fields.map((name, idx) => (
                <Field key={name} name={name}>
                  {({ input }) => {
                    const startTime = startTimes.find(
                      (startTime) =>
                        startTime.timeSlotKey === input.value.timeSlotKey
                    );
                    return (
                      <div className={styles['selectFrame']}>
                        <div className={styles['selectFrame__header']}>
                          <p className={styles['selectFrame__header__ttl']}>
                            {startTime?.hhMm ?? ''}
                          </p>
                        </div>
                        <div
                          className={clsx(
                            styles['selectFrame__body'],
                            styles['start-time-pickup-container'],
                            baseStyles['base-flex']
                          )}
                        >
                          <ul className={styles['scheds']}>
                            <li>
                              <p className={styles['scheds__ttl']}>
                                {type === 'PICKUP_DROPOFF'
                                  ? t('Pickup/Dropoff Times')
                                  : type === 'PICKUP_ONLY'
                                  ? t('Pickup Time')
                                  : type === 'DROPOFF_ONLY'
                                  ? t('Dropoff Time')
                                  : type === 'CHECKIN_CHECKOUT'
                                  ? t('Checkin/Checkout Times')
                                  : type === 'CHECKIN_ONLY'
                                  ? t('Checkin Time')
                                  : t('Checkout Time')}
                              </p>

                              {isPickupCheckinType(type) && (
                                <Field name={`${name}.timeOffsetCheckinPickup`}>
                                  {({ input }) => (
                                    <>
                                      <div
                                        className={styles['scheds__body__item']}
                                      >
                                        <div>
                                          {isPickupType(type)
                                            ? t('Pickup Time Offset: ')
                                            : t('Checkin Time Offset: ')}
                                        </div>
                                        <div
                                          className={
                                            styles['duration-input-box']
                                          }
                                        >
                                          <DurationInput
                                            showSignSelect
                                            required
                                            {...input}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={styles['scheds__body__item']}
                                      >
                                        <p>{t('Displayed Time: ')}</p>
                                        <span className={styles['blue']}>
                                          {timePlusOffset(
                                            moment(
                                              startTime?.hhMm || '',
                                              'H:mm'
                                            ),
                                            input.value
                                          ).format('H:mm')}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </Field>
                              )}
                              {isDropoffCheckoutType(type) && (
                                <Field
                                  name={`${name}.timeOffsetCheckoutDropoff`}
                                >
                                  {({ input }) => (
                                    <>
                                      <div
                                        className={styles['scheds__body__item']}
                                      >
                                        <div>
                                          {isDropoffType(type)
                                            ? t('Dropoff Time Offset: ')
                                            : t('Checkout Time Offset: ')}
                                        </div>
                                        <div
                                          className={
                                            styles['duration-input-box']
                                          }
                                        >
                                          <DurationInput
                                            showSignSelect
                                            required
                                            {...input}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={styles['scheds__body__item']}
                                      >
                                        <p>{t('Displayed Time: ')}</p>
                                        <span className={styles['blue']}>
                                          {timePlusOffset(
                                            moment(
                                              startTime?.hhMm || '',
                                              'H:mm'
                                            ),
                                            input.value
                                          ).format('H:mm')}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </Field>
                              )}
                            </li>
                          </ul>

                          <DeleteIcon onClick={() => fields.remove(idx)} />
                        </div>
                      </div>
                    );
                  }}
                </Field>
              ))}
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};

type CheckinPickupLocationInputProps = {
  name: string;
  startTimes: string[];
  type: LocationType;
};

const CheckinPickupLocationInput = ({
  name,
  startTimes,
  type,
}: CheckinPickupLocationInputProps) => {
  const { t } = useTranslation();
  const { translationTargetLanguageName } = useTranslationTargetLanguage(t);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  return (
    <ul className={styles['scheds']}>
      <li className={styles['borderBottom']}>
        <p className={styles['scheds__ttl']}>
          {type === 'PICKUP_DROPOFF'
            ? t('Pickup/Dropoff Times')
            : type === 'PICKUP_ONLY'
            ? t('Pickup Time')
            : type === 'DROPOFF_ONLY'
            ? t('Dropoff Time')
            : type === 'CHECKIN_CHECKOUT'
            ? t('Checkin/Checkout Times')
            : type === 'CHECKIN_ONLY'
            ? t('Checkin Time')
            : t('Checkout Time')}
        </p>
        <div className={styles['scheds__body']}>
          <div
            className={styles['scheds__body__item']}
            style={{
              width: '100%',
            }}
          >
            <p>{t('Type:')}</p>
            <Field name={`${name}.type`}>
              {({ input }) => (
                <Select
                  width={190}
                  options={[
                    {
                      text: t('Pickup/Dropoff'),
                      value: 'PICKUP_DROPOFF',
                    },
                    {
                      text: t('Pickup Only'),
                      value: 'PICKUP_ONLY',
                    },
                    {
                      text: t('Dropoff Only'),
                      value: 'DROPOFF_ONLY',
                    },
                    {
                      text: t('Checkin/Checkout'),
                      value: 'CHECKIN_CHECKOUT',
                    },
                    {
                      text: t('Checkin Only'),
                      value: 'CHECKIN_ONLY',
                    },
                    {
                      text: t('Checkout Only'),
                      value: 'CHECKOUT_ONLY',
                    },
                  ]}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </div>
        </div>
        {isPickupCheckinType(type) && (
          <Field name={`${name}.timeOffsetCheckinPickup`}>
            {({ input }) => (
              <>
                <Box mt={2}>
                  <ToggleButton
                    label={
                      isPickupType(type)
                        ? t('Leave Pickup Time Offset Blank')
                        : t('Leave Checkin Time Offset Blank')
                    }
                    onChange={() => {
                      input.onChange(input.value ? '' : '0:00');
                    }}
                    checked={!input.value}
                  />
                </Box>
                {input.value && (
                  <div className={styles['scheds__body']}>
                    <div className={styles['scheds__body__item']}>
                      <div>
                        {isPickupType(type)
                          ? t('Pickup Time Offset: ')
                          : t('Checkin Time Offset: ')}
                      </div>
                      <div className={styles['duration-input-box']}>
                        <DurationInput required showSignSelect {...input} />
                      </div>
                    </div>
                    <div className={styles['scheds__body__item']}>
                      <p>{t('Displayed Time: ')}</p>
                      <span className={styles['blue']}>
                        {startTimes
                          .map((startTime) => {
                            const st = moment(startTime, 'H:mm');
                            return timePlusOffset(st, input.value).format(
                              'H:mm'
                            );
                          })
                          .join(',')}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </Field>
        )}
        {isDropoffCheckoutType(type) && (
          <Field name={`${name}.timeOffsetCheckoutDropoff`}>
            {({ input }) => (
              <>
                <Box mt={2}>
                  <ToggleButton
                    label={
                      isPickupType(type)
                        ? t('Leave Dropoff Time Offset Blank')
                        : t('Leave Checkout Time Offset Blank')
                    }
                    onChange={() => {
                      input.onChange(input.value ? '' : '0:00');
                    }}
                    checked={!input.value}
                  />
                </Box>
                {input.value && (
                  <div className={styles['scheds__body']}>
                    <div className={styles['scheds__body__item']}>
                      <div>
                        {isDropoffType(type)
                          ? t('Dropoff Time Offset: ')
                          : t('Checkout Time Offset: ')}
                      </div>
                      <div className={styles['duration-input-box']}>
                        <DurationInput showSignSelect {...input} />
                      </div>
                    </div>
                    <div className={styles['scheds__body__item']}>
                      <p>{t('Displayed Time: ')}</p>
                      <span className={styles['blue']}>
                        {startTimes
                          .map((startTime) => {
                            const st = moment(startTime, 'H:mm');
                            return timePlusOffset(st, input.value).format(
                              'H:mm'
                            );
                          })
                          .join(',')}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </Field>
        )}
      </li>
      <li className={styles['scheds__cell']}>
        <div>
          <p className={styles['scheds__ttl']}>{t('Location')}</p>
          <div className={styles['scheds__body']}>
            <Field
              name={`${name}.location`}
              validate={(value: any | null | undefined): string => {
                if (!value?.locationName) return t('Required');
                return '';
              }}
            >
              {({ input, meta: { touched, error } }) => (
                <LocationSearchInput
                  location={input.value.locationName}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onSearchChange={() => {}}
                  onLocationSelect={({ title, key }) =>
                    input.onChange({
                      locationName: title,
                      googlePlaceId: key,
                    })
                  }
                  error={touched && error}
                  maxWidth={800}
                />
              )}
            </Field>
          </div>
        </div>
        {translationTargetLanguageName && (
          <div>
            <p className={styles['scheds__ttl']}>{`${t(
              'Location'
            )} (${translationTargetLanguageName})`}</p>
            <div className={styles['scheds__body']}>
              <TranslatedField name={`${name}.location.locationName`}>
                {({ translationInput }) => (
                  <Input {...translationInput} maxWidth={800} />
                )}
              </TranslatedField>
            </div>
          </div>
        )}
      </li>
      <li className={styles['scheds__cell']}>
        <TranslatedField name={`${name}.description`}>
          {({ input, translationInput }) => (
            <>
              <div>
                <p className={styles['scheds__ttl']}>{t('Description')}</p>
                <div className={styles['scheds__body']}>
                  <TextArea
                    value={input.value}
                    onChange={input.onChange}
                    maxWidth={800}
                    height={80}
                  />
                </div>
              </div>
              {translationTargetLanguageName && (
                <div>
                  <p className={styles['scheds__ttl']}>{`${t(
                    'Description'
                  )} (${translationTargetLanguageName})`}</p>
                  <div className={styles['scheds__body']}>
                    <TextArea
                      value={translationInput.value}
                      onChange={translationInput.onChange}
                      maxWidth={800}
                      height={80}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </TranslatedField>
      </li>

      <li>
        <Field name={`${name}.imageUrls`}>
          {({ input }) => (
            <>
              <FieldWrapper
                label={t('Images')}
                tooltipText={t('Images will be shown only on guest emails')}
              />
              <ImageVideoAudioInput
                fileUrls={input.value ?? []}
                onChange={(newValue) => input.onChange(newValue)}
                disableYoutubeVideos
              />
            </>
          )}
        </Field>
      </li>

      {isPickupType(type) && (
        <li className={styles['borderTop']}>
          <div>
            <p className={styles['scheds__ttl']}>
              {t('Pre-mapped Hotels')}
              <Tooltip
                text={t(
                  "By default, Nutmeg will assign the closest pickup location for a customer's hotel. To override this default behavior, a hotel may be added to the pre-mapped hotel list so that it is always mapped to a certain pickup location rather than the closest."
                )}
              />
            </p>
            <div className={styles['scheds__body']}>
              <LocationListEditor name={`${name}.premappedLocations`} />
            </div>
          </div>
        </li>
      )}
      <li className={styles['borderTop']}>
        <PerStartTimeOffsetsEditor
          name={`${name}.perStartTimeOffsets`}
          type={type}
        />
      </li>
      {isNutmegAdmin && (
        <li>
          <div>
            <p className={styles['scheds__ttl']}>
              {t('Third Party Reference')}
            </p>
            <div className={styles['scheds__body']}>
              <Field name={`${name}.referenceInSupplierSystem`}>
                {({ input }) => <Input {...input} width={400} />}
              </Field>
            </div>
          </div>
        </li>
      )}
    </ul>
  );
};

export const CheckinPickupLocationsEditor = () => {
  const { t } = useTranslation();
  const { translationTargetLanguageName } = useTranslationTargetLanguage(t);

  const editingProduct = React.useContext(EditingProductContext);
  const startTimes = getStartTimesFromRecurrence(
    editingProduct?.recurrence || []
  ).map((startTime) => startTime.hhMm || '');

  return (
    <div className={styles['acBody']}>
      <TranslatedField name="guestEmailPickupInstructions">
        {({ input, translationInput }) => (
          <Box mb={2} display="flex">
            <TextArea
              label={t('Guest Email Pickup Instructions')}
              tooltipText={t(
                'Instructions will be shown only on guest emails. The same instructions will be used for all pickup/checkin locations.'
              )}
              value={input.value}
              onChange={input.onChange}
              height={80}
            />
            {translationTargetLanguageName && (
              <Box ml={2} width="100%">
                <TextArea
                  label={`${t(
                    'Guest Email Pickup Instructions'
                  )} (${translationTargetLanguageName})`}
                  value={translationInput.value}
                  onChange={translationInput.onChange}
                  height={80}
                />
              </Box>
            )}
          </Box>
        )}
      </TranslatedField>
      <TranslatedField name="guestEmailCheckinInstructions">
        {({ input, translationInput }) => (
          <Box mb={2} display="flex">
            <TextArea
              label={t('Guest Email Checkin Instructions')}
              tooltipText={t(
                'Instructions will be shown only on guest emails. The same instructions will be used for all pickup/checkin locations.'
              )}
              value={input.value}
              onChange={input.onChange}
              height={80}
            />
            {translationTargetLanguageName && (
              <Box ml={2} width="100%">
                <TextArea
                  label={`${t(
                    'Guest Email Checkin Instructions'
                  )} (${translationTargetLanguageName})`}
                  value={translationInput.value}
                  onChange={translationInput.onChange}
                  height={80}
                />
              </Box>
            )}
          </Box>
        )}
      </TranslatedField>

      <FieldArray name="checkinPickupLocations">
        {({ fields }) =>
          fields.length === 0 ? (
            <AddIcon
              onClick={() =>
                (fields as any).insertAt(0, getDefaultPickupLocation())
              }
            />
          ) : (
            <div className={styles['c-table-list']}>
              <table>
                <tbody>
                  {fields.map((name, idx) => (
                    <Field key={name} name={name}>
                      {({ input }) => (
                        <tr>
                          <th>
                            {input.value.type === 'PICKUP_DROPOFF'
                              ? t('Pickup/Dropoff')
                              : input.value.type === 'PICKUP_ONLY'
                              ? t('Pickup')
                              : input.value.type === 'DROPOFF_ONLY'
                              ? t('Dropoff')
                              : input.value.type === 'CHECKIN_CHECKOUT'
                              ? t('Checkin/Checkout')
                              : input.value.type === 'CHECKIN_ONLY'
                              ? t('Checkin')
                              : t('Checkout')}
                          </th>
                          <td colSpan={2}>
                            <CheckinPickupLocationInput
                              name={name}
                              type={input.value.type}
                              startTimes={startTimes}
                            />
                          </td>
                          <td className={styles['c-table-list__btns']}>
                            <div className={baseStyles['base-flex']}>
                              <AddIcon
                                onClick={() =>
                                  (fields as any).insertAt(
                                    idx + 1,
                                    getDefaultPickupLocation()
                                  )
                                }
                              />
                              <DeleteIcon onClick={() => fields.remove(idx)} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </Field>
                  ))}
                </tbody>
              </table>
            </div>
          )
        }
      </FieldArray>
    </div>
  );
};
