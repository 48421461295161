import * as React from 'react';
import clsx from 'clsx';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import styles from 'client/base.module.css';

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'minLength' | 'maxLength'
> & {
  error?: string;
  label?: string | React.ReactElement;
  tooltipText?: string;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: string;
    }
  ) => void;
  value: string;
  fluid?: boolean;
  step?: string;

  /* TODO (goro) used? */
  minLength?: string;
  maxLength?: string;
  type?: string;
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  colorStyle?: 'warning' | 'success' | 'error' | 'info';
  name?: string;
  className?: string;
};
export const Input = ({
  error,
  label,
  onChange,
  value,
  width,
  minWidth,
  maxWidth,
  minLength,
  maxLength,
  disabled,
  required,
  type,
  placeholder,
  colorStyle,
  tooltipText,
  ...inputProps
}: Props) => {
  // TODO : move to css definition after removing semantic ui css
  const setColorStyle = () => {
    switch (colorStyle) {
      case 'warning':
        return {
          backgroundColor: '#fffaf3',
          color: '#573a08',
        };

      case 'success':
        return {
          backgroundColor: '#fcfff5',
          color: '#2c662d',
        };

      case 'error':
        return {
          backgroundColor: '#fff6f6',
          color: '#9f3a38',
        };

      case 'info':
        return {
          backgroundColor: '#f8ffff',
          color: '#276f86',
        };

      default:
        return {};
    }
  };

  const style = {
    width,
    minWidth,
    maxWidth,
  };
  return (
    <div className={styles['base-form-box']}>
      {label && (
        <div
          className={clsx(
            styles['base-form-box__header'],
            required ? styles['required'] : '',
            error ? styles['error'] : ''
          )}
        >
          {label}

          {tooltipText && <Tooltip text={tooltipText} />}
        </div>
      )}
      <div className={styles['base-form-box__body']}>
        <input
          disabled={disabled}
          value={value}
          onChange={(e) => onChange(e, e.target)}
          type={type || 'text'}
          className={styles['base-form-text']}
          style={{ ...style, ...setColorStyle() }}
          placeholder={placeholder}
          minLength={minLength as any}
          maxLength={maxLength as any}
          {...inputProps}
        />
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
