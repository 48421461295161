import moment from 'moment-timezone';
import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import type { TFunction } from 'react-i18next';

import { currency } from 'shared/libraries/currency';
import { generateRandomString } from 'shared/models/util';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  ActionSource,
  AvailabilityChannelCategory,
  CancellationPolicy,
  Field,
  GuestType,
  Product,
  ProductInstance,
  RelativeDateTime,
  Reservation,
  ReservationStatus,
  UnitPricing,
  Task,
} from 'shared/models/swagger';

import { formattedCurrencyAmount } from './formattedCurrencyAmount';

export const countFilteredFields = (
  reservation: Reservation,
  product: Product,
  fieldFilter: (arg0: Field) => boolean
) => {
  const fields = (product.reservation_form_fields || []).filter(fieldFilter);
  const per_booking_fields = fields.filter((f) => f.type === 'PER_BOOKING');
  const per_participant_fields = fields.filter(
    (f) => f.type === 'PER_PARTICIPANT'
  );
  return (
    per_booking_fields &&
    per_booking_fields.length +
      (reservation.guests && reservation.guests.length) *
        (per_participant_fields && per_participant_fields.length)
  );
};
export const countUnansweredFields = (
  reservation: Reservation,
  product: Product,
  fieldFilter: (arg0: Field) => boolean
) => {
  const fields = (product.reservation_form_fields || []).filter(fieldFilter);
  let count = fields.filter(
    (f) =>
      f.type === 'PER_BOOKING' &&
      !(reservation.field_responses || []).find(
        (r) => r.key === f.key && r.response
      )
  ).length;
  reservation.guests.forEach((g) => {
    count += fields.filter(
      (f) =>
        f.type === 'PER_PARTICIPANT' &&
        !(g.field_responses || []).find((r) => r.key === f.key && r.response)
    ).length;
  });
  return count;
};
type FieldName = 'given_name' | 'family_name' | 'name';
export const getFieldKey = (f: FieldName) => {
  return f; // return uuid(f, uuid.DNS);
};
export const getParentID = (p?: {
  shared_allotment_references?: {
    parent_product_id?: string;
  };
}): string => {
  return (
    (p &&
      p.shared_allotment_references &&
      p.shared_allotment_references.parent_product_id) ||
    ''
  );
};
export const getChildIDs = (p?: {
  shared_allotment_references?: {
    child_product_ids?: string[];
  };
}): string[] => {
  return (
    (p &&
      p.shared_allotment_references &&
      p.shared_allotment_references.child_product_ids) ||
    []
  );
};
const defaultDuration = {
  hours: 0,
  minutes: 0,
};
export const parseDuration = (
  d: string
): {
  hours: number;
  minutes: number;
} => {
  const match = /(-?[0-9]+):([0-9]+)/.exec(d);
  if (!match) return defaultDuration;
  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);
  const isNegative = Math.sign(hours) === -1 || Object.is(hours, -0);
  return {
    hours,
    minutes: isNegative ? -minutes : minutes,
  };
};
export const isTerminalReservationStatus = (
  status: ReservationStatus
): boolean => {
  switch (status) {
    case 'WITHDRAWN_BY_AGENT':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'PARTICIPATED':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
      return true;

    default:
      return false;
  }
};
export const timePlusOffset = (time: Moment, duration: string) => {
  const { hours, minutes } = parseDuration(duration);
  return moment(time).add(hours, 'hours').add(minutes, 'minutes');
};
export const getNextStatus = (status: string) => {
  switch (status) {
    case 'PENDING':
    case 'REQUESTED':
    case 'AWAITING_RESERVATION_CONFIRMATION':
    case 'AWAITING_UPDATE_CONFIRMATION':
    case 'STANDBY':
    case 'WAITLISTED':
      return 'CONFIRMED';

    case 'CANCEL_REQUESTED_BY_AGENT':
      return 'CANCEL_CONFIRMED_BY_SUPPLIER';

    case 'CONFIRMED':
    case 'REJECTED':
    case 'WITHDRAWN_BY_AGENT':
    case 'DECLINED_BY_SUPPLIER':
    case 'CANCELED_BY_SUPPLIER':
    case 'CANCELED_BY_AGENT':
    case 'CANCELED_BY_GUEST':
    case 'CANCEL_CONFIRMED_BY_SUPPLIER':
    case 'CANCEL_DECLINED_BY_SUPPLIER':
    case 'PARTICIPATED':
    default:
      return '';
  }
};
export const getCurrentStatus = (reservation: Reservation) => {
  return ((st) => st[st.length - 1].status)(reservation.status_history);
};
export const getRelativeDateDescription = (
  relativeDate: RelativeDateTime,
  t: TranslateFuncType,
  locale: string
): string => {
  if (relativeDate.type === 'DAY') {
    if (!relativeDate.count) {
      return t('{{time}} on day of departure', {
        time: moment(relativeDate.time_local, 'H:mm')
          .locale(locale)
          .format('LT'),
      });
    }

    return t('{{count}} days before departure at {{time}}', {
      count: relativeDate.count,
      time: moment(relativeDate.time_local, 'H:mm').locale(locale).format('LT'),
    });
  }

  return t('{{count}} hours before departure', {
    count: relativeDate.count,
  });
};
export const getCancellationFeePolicyDescription = (
  cancelPolicy: CancellationPolicy,
  t: TranslateFuncType
): string => {
  const { charge_fixed: chargeFixed, charge_percent } = cancelPolicy;
  const chargePercent = charge_percent || 0;
  return !chargeFixed && chargePercent === 0
    ? t('{{percent}}% cancellation fee', {
        percent: 0,
      })
    : chargeFixed
    ? t('{{fee}} cancellation fee', {
        fee: chargeFixed,
      })
    : t('{{percent}}% cancellation fee', {
        percent: chargePercent,
      });
};
export const getCancellationPoliciesDescription = (
  cancellationPolicies: CancellationPolicy[],
  t: TranslateFuncType,
  locale: string
): string[] => {
  if (cancellationPolicies.length === 0) {
    return [];
  }

  return _.zip(
    [null, ...cancellationPolicies.slice(0, cancellationPolicies.length - 1)],
    cancellationPolicies
  ).map((d) => {
    const prevCancelPolicy = d[0];
    const cancelPolicy = d[1];

    const feeDescription = cancelPolicy
      ? getCancellationFeePolicyDescription(cancelPolicy, t)
      : '';

    if (prevCancelPolicy && cancelPolicy) {
      if (
        cancelPolicy &&
        cancelPolicy.relative_date.type === 'HOUR' &&
        !cancelPolicy.relative_date.count
      ) {
        return prevCancelPolicy.relative_date.type === 'HOUR'
          ? t('Within {{count}} hours of departure: {{feeDescription}}', {
              count: prevCancelPolicy.relative_date.count,
              feeDescription,
            })
          : t('After {{relativeDateDescription}}: {{feeDescription}}', {
              relativeDateDescription: getRelativeDateDescription(
                prevCancelPolicy.relative_date,
                t,
                locale
              ),
              feeDescription,
            });
      }

      return t(
        'From {{relativeDateDescription1}} to {{relativeDateDescription2}}: {{feeDescription}}',
        {
          relativeDateDescription1: getRelativeDateDescription(
            prevCancelPolicy.relative_date,
            t,
            locale
          ),
          relativeDateDescription2: getRelativeDateDescription(
            cancelPolicy.relative_date,
            t,
            locale
          ),
          feeDescription,
        }
      );
    }

    if (
      cancelPolicy &&
      cancelPolicy.relative_date.type === 'HOUR' &&
      !cancelPolicy.relative_date.count
    ) {
      return t('{{feeDescription}} for any cancellation', {
        feeDescription:
          feeDescription.charAt(0).toUpperCase() + feeDescription.slice(1),
      });
    }

    const cp = cancelPolicy
      ? t('Until {{relativeDateDescription}}: {{feeDescription}}', {
          relativeDateDescription: getRelativeDateDescription(
            cancelPolicy.relative_date,
            t,
            locale
          ),
          feeDescription,
        })
      : '';
    return cp;
  });
};
export const subtractRelativeDate = (
  t: Moment,
  relativeDate: RelativeDateTime
): Moment => {
  if (relativeDate.type === 'DAY') {
    const { hours, minutes } = parseDuration(relativeDate.time_local as any);
    return moment(t)
      .subtract(relativeDate.count, 'days')
      .startOf('day')
      .add(hours, 'hours')
      .add(minutes, 'minutes');
  } else if (relativeDate.type === 'HOUR') {
    return moment(t).subtract(relativeDate.count, 'hours');
  }

  return t;
};
export const getCancellationFeeDescription = (
  cancellationFeeAmount: string,
  t: TranslateFuncType
): string => {
  if (!cancellationFeeAmount || !currency(cancellationFeeAmount).intValue) {
    return t('{{percent}}% cancellation fee', {
      percent: 0,
    });
  }

  return t('{{fee}} cancellation fee', {
    fee: formattedCurrencyAmount(cancellationFeeAmount),
  });
};
export const getCancellationText = (
  reservation: Reservation,
  product: Product,
  productInstance: ProductInstance,
  t: TranslateFuncType,
  locale: string
): string => {
  const startTime = moment.tz(
    reservation.start_date_time_utc,
    product.start_timezone ?? ''
  );
  const cancelPolicies = product.cancellation_policies || [];
  let applicableCancelPolicy, effectiveUntil;

  for (let i = 0; i < cancelPolicies.length; i++) {
    applicableCancelPolicy = cancelPolicies[i];
    effectiveUntil = subtractRelativeDate(
      startTime,
      applicableCancelPolicy.relative_date
    );

    if (moment().isBefore(effectiveUntil)) {
      break;
    }
  }

  if (!applicableCancelPolicy || !effectiveUntil) {
    return t('0% cancellation charge from now until participation date.');
  }

  const feeDescription = getCancellationFeeDescription(
    reservation.billing_info?.amount_calculated_cancellation_fee ?? '',
    t
  );

  if (applicableCancelPolicy.relative_date?.type === 'ETERNAL') {
    return t('{{feeDescription}} will apply.', {
      feeDescription,
    });
  }
  return t('{{feeDescription}} from now until {{date}}.', {
    date: effectiveUntil.locale(locale).format('lll'),
    feeDescription,
  });
};
export const getCancellationDeadline = (
  reservation: Reservation,
  product?: Product
): Moment => {
  const startTime = moment.tz(
    reservation.start_date_time_utc,
    product?.start_timezone ?? ''
  );

  if (
    !product?.cancellation_policies ||
    product?.cancellation_policies.length === 0
  ) {
    return startTime;
  }

  const latestPolicy =
    product.cancellation_policies[product.cancellation_policies.length - 1];

  if (latestPolicy.relative_date.type === 'DAY') {
    const { hours, minutes } = parseDuration(
      latestPolicy.relative_date.time_local as any
    );
    return startTime
      .subtract(latestPolicy.relative_date.count, 'days')
      .startOf('day')
      .add(hours, 'hours')
      .add(minutes, 'minutes');
  } else if (latestPolicy.relative_date.type === 'HOUR') {
    return startTime.subtract(latestPolicy.relative_date.count, 'hours');
  } else if (latestPolicy.relative_date.type === 'ETERNAL') {
    // Start time is agent cancellation deadline for "eternal" cancellation policies
    return startTime;
  }

  return startTime;
};
export const histogram = <T>(
  array: Array<T>,
  fn: (elem: T) => string
): Record<string, number> => {
  const grouped = _.groupBy(array, fn);

  const hist: Record<string, number> = {};
  Object.keys(grouped).forEach((key) => {
    hist[key] = grouped[key].length;
  });
  return hist;
};
type LineItemType = {
  name: string;
  count: number;
  gross: string;
  net: string;
  unit?: UnitPricing;
};

const getLineItems = (
  guestKeyToCountMap: Record<string, number>,
  pricingUnits: UnitPricing[],
  product: Product,
  productInstance: ProductInstance,
  t: TranslateFuncType
): LineItemType[] => {
  const guestCountMap = { ...guestKeyToCountMap };
  const perBookingPricing = pricingUnits.find(
    (pr) => pr.method === 'PER_BOOKING'
  );

  if (perBookingPricing) {
    return [
      {
        name: 'PER_BOOKING',
        count: 1,
        gross: perBookingPricing.gross,
        net: perBookingPricing.net,
        unit: perBookingPricing,
      },
    ];
  }

  const productGuestTypes = getGuestTypesUsedInProductInstance(
    productInstance,
    product,
    t
  );
  const lineItems = pricingUnits
    .filter((unit: UnitPricing) => unit.method === 'PER_GROUP')
    .map<LineItemType>((unit) => {
      const unitGuestCounts = histogram(
        unit.group_guest_types || [],
        (g) => g.key
      );
      const maxGroupUnitsPerGuestType = Object.keys(unitGuestCounts).map(
        (guestKey) =>
          unitGuestCounts[guestKey]
            ? Math.floor(
                (guestCountMap[guestKey] || 0) / unitGuestCounts[guestKey]
              )
            : 0
      );
      const unitCount = _.min(maxGroupUnitsPerGuestType) || 0;
      Object.keys(unitGuestCounts).forEach((guestKey) => {
        guestCountMap[guestKey] =
          guestCountMap[guestKey] - unitGuestCounts[guestKey] * unitCount;
      });
      // Get translation for per group unit.title from product.pricing.unit_prices.title
      // find correct product.pricing slice
      const filtered_pricing_units =
        product.pricing &&
        product.pricing.find(
          (pricing_unit) =>
            pricing_unit.start_date_local &&
            pricing_unit.start_date_local <=
              productInstance.start_date_time_utc &&
            pricing_unit.end_date_local &&
            pricing_unit.end_date_local >= productInstance.start_date_time_utc
        );
      const per_group_pricing_unit =
        filtered_pricing_units &&
        filtered_pricing_units.units &&
        filtered_pricing_units.units.find(
          (unit) => unit.method === 'PER_GROUP'
        );
      return {
        name:
          (per_group_pricing_unit && per_group_pricing_unit.title) ||
          (unit && unit.title) ||
          '',
        count: unitCount,
        gross: unit.gross,
        net: unit.net,
        unit,
      };
    });

  // Dedup pricing units by guest type key since 3rd party gateways (uh, Respax) return redundant units in product APIs.
  const uniquePricingUnits = _.uniqBy(
    pricingUnits.filter((unit) => unit.method === 'PER_PARTICIPANT'),
    (unit) => unit.guest_type && unit.guest_type.key
  );

  // User per-participant pricing for remaining participants.
  lineItems.push(
    ...uniquePricingUnits.map((unit) => {
      const guestType = unit.guest_type as any;
      return {
        name: (productGuestTypes.find(
          (productGuestType) => productGuestType.key === guestType.key
        ) as any)
          ? (
              productGuestTypes.find(
                (productGuestType) => productGuestType.key === guestType.key
              ) as any
            ).title
          : guestType.key,
        count: guestCountMap[guestType.key] || 0,
        gross: unit.gross,
        net: unit.net,
        unit,
      };
    })
  );
  return lineItems;
};

export const reservationIsCheckinCheckoutOnly = (
  reservation: Reservation | Task
) => {
  return (
    !reservation.transportation &&
    !reservation.requested_pickup_location &&
    !reservation.requested_dropoff_location
  );
};

export const getHotelDisplay = (
  reservation: Reservation | Task,
  t: TranslateFuncType
) => {
  let hotel =
    reservation?.guest_hotel?.location_name ||
    reservation?.requested_pickup_location?.location_name ||
    '';

  if (hotel === 'TBD') {
    hotel = t('TBD');
  }

  return hotel;
};

export const getGuestDescriptionDisplay = (reservation: Reservation | Task) => {
  const reservationGuestCounts = histogram(
    reservation?.guests ?? [],
    (g) => g.guest_type_title || g.guest_type_key
  );
  return Object.keys(reservationGuestCounts)
    .map((guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`)
    .join(', ');
};

export const getTransportationDisplay = (
  reservation: Reservation | Task,
  t: TranslateFuncType
) => {
  return (
    reservation?.transportation_title ||
    (!reservationIsCheckinCheckoutOnly(reservation)
      ? t('Pickup/Dropoff Included')
      : t('Checkin/Checkout Only'))
  );
};

export const getDateTimeDisplay = (dateTime: Moment, locale: string) => {
  if (!dateTime) {
    return '';
  }
  return `${dateTime.locale(locale).format('lll')} (GMT${dateTime
    .locale(locale)
    .format('Z')})`;
};

export const getReservationLineItems = (
  reservation: Reservation,
  product: Product,
  productInstance: ProductInstance,
  t: TranslateFuncType
) => {
  if (!product || !productInstance || !reservation) {
    return {
      line_items: [],
      total: {},
    };
  }

  const reservationGuestCounts = histogram(
    reservation.guests,
    (g) => g.guest_type_key
  );
  let line_items = [];
  line_items = getLineItems(
    reservationGuestCounts,
    productInstance.units || [],
    product,
    productInstance,
    t
  );
  const transKey = reservation.transportation;

  if (transKey) {
    const trans =
      product.transportations &&
      product.transportations.find((t) => t.key === transKey);

    if (trans) {
      line_items.push(
        ...getLineItems(
          reservationGuestCounts,
          trans.pricing || [],
          product,
          productInstance,
          t
        )
          .filter((i) => i.count > 0)
          .map((i) => ({
            ...i,
            name: i.name ? `${trans.title} - ${i.name}` : trans.title,
          }))
      );
    }
  }

  (reservation.add_ons || []).forEach((addOnKey) => {
    const addOn =
      product.add_ons && product.add_ons.find((a) => a.key === addOnKey);
    const pr =
      addOn &&
      addOn.pricing &&
      addOn.pricing.find((pr) => pr.method === 'PER_BOOKING');

    if (addOn && pr) {
      line_items.push({
        name: addOn.title,
        count: 1,
        gross: pr.gross,
        net: pr.net,
      });
    }
  });
  const addOnGuestCounts: Record<string, Record<string, number>> = {};
  reservation.guests.forEach((g) => {
    (g.add_ons || []).forEach((addOn) => {
      if (!addOnGuestCounts[addOn]) {
        addOnGuestCounts[addOn] = {};
      }

      const count = addOnGuestCounts[addOn][g.guest_type_key] || 0;
      addOnGuestCounts[addOn][g.guest_type_key] = count + 1;
    });
  });
  (product.add_ons || []).forEach((addOn) => {
    if ((addOn.pricing || []).some((p) => p.method === 'PER_PARTICIPANT')) {
      line_items.push(
        ...getLineItems(
          addOnGuestCounts[addOn.key || ''] || {},
          addOn.pricing || [],
          product,
          productInstance,
          t
        )
          .filter((i) => i.count > 0)
          .map((i) => ({
            ...i,
            name: i.name ? `${addOn.title} - ${i.name}` : addOn.title,
          }))
      );
    }
  });
  const { code } = parseMoney(productInstance.units[0].gross);
  return {
    line_items,
    total: {
      gross: line_items
        .reduce(
          (acc, i) => acc.add(currency(i.gross).multiply(i.count)),
          currency(0, code)
        )
        .format(),
      net: line_items
        .reduce(
          (acc, i) => acc.add(currency(i.net).multiply(i.count)),
          currency(0, code)
        )
        .format(),
    },
  };
};
export const parseMoney = (
  s: string
): {
  value: string;
  code: string;
} => {
  const groups = s.match(/^([A-Z]{3})([0-9]+\.?[0-9]*)?$/);

  if (!groups || groups.length < 3) {
    return {
      value: '',
      code: '',
    };
  }

  return {
    value: groups[2],
    code: groups[1],
  };
};
// Returns a shallow-merged object where "" and [] will not overwrite non-empty values
// in the destination object. This can be useful when merging two API response values
// since the API fills in "" and [] as default values.
// Since _.mergeWith() mutates its first argument, deep copy of the parameter obj.
export const mergeNonEmptyProps = (
  obj: Record<string, any>,
  source: Record<string, any>
): Record<string, any> =>
  _.mergeWith(_.cloneDeep(obj), source, (objValue, srcValue) => {
    if (_.isNil(srcValue) || (_.isArrayLike(srcValue) && _.isEmpty(srcValue))) {
      return objValue;
    }

    return srcValue;
  });
type Status = {
  status: ReservationStatus;
  status_date_time_utc: string;
  until_date_time_utc?: string;
  action_source?: ActionSource;
};
export const filteredStatuses = (statuses: Status[]): Status[] => {
  // Truncate at highest-index 'PENDING' state for 'cancel-rebook' case
  const pendingIdx = _.findLastIndex(statuses, (s) => s.status === 'PENDING');

  const filtered = pendingIdx !== -1 ? statuses.slice(pendingIdx) : statuses;
  return _.uniqBy(
    filtered.filter(
      (s) =>
        [
          'NO_SHOW',
          'PENDING', // 'CANCELLATION_PENDING',
          // 'AWAITING_RESERVATION_CONFIRMATION',
          // 'AWAITING_UPDATE_CONFIRMATION':
          'REQUESTED',
          'CANCEL_REQUESTED_BY_AGENT',
          'STANDBY',
          'CONFIRMED',
          'PARTICIPATED',
          'WITHDRAWN_BY_AGENT',
          'DECLINED_BY_SUPPLIER',
          'CANCELED_BY_SUPPLIER',
          'CANCELED_BY_AGENT',
          'CANCELED_BY_GUEST',
          'CANCEL_CONFIRMED_BY_SUPPLIER',
          'CANCEL_DECLINED_BY_SUPPLIER',
        ].indexOf(s.status) !== -1
    ),
    (s) => s.status
  );
};
export const getLastSupplierActionSource = (
  statuses: Status[]
): ActionSource | null => {
  return getLastActionSoruce(statuses, ['SUPPLIER_ENTITY_TYPE']);
};
export const getLastAgentOrGuestActionSource = (
  statuses: Status[]
): ActionSource | null => {
  return getLastActionSoruce(statuses, [
    'AGENT_ENTITY_TYPE',
    'GUEST_ENTITY_TYPE',
  ]);
};

const getLastActionSoruce = (
  statuses: Status[],
  entityTypes: string[]
): ActionSource | null => {
  const status = statuses.reverse().find((s) => {
    return (
      s.action_source &&
      s.action_source.entity_type &&
      entityTypes.includes(s.action_source.entity_type)
    );
  });
  return status && status.action_source ? status.action_source : null;
};

export const getCompanyAndPersonNameFromActionSrouce = (
  actionSource: ActionSource
): string => {
  return (
    (actionSource.entity_name || '') +
    ':' +
    (actionSource.entity_description || '')
  );
};
export const hasBookingDeadline = (
  instance: ProductInstance,
  confirmationType: 'INSTANT' | 'REQUEST'
): boolean => {
  return (instance.booking_deadlines || []).some(
    (deadline) => deadline.confirmation_type === confirmationType
  );
};
export const bookingDeadlineExpired = (
  instance: ProductInstance,
  confirmationType: 'INSTANT' | 'REQUEST'
): boolean => {
  const deadline = (instance.booking_deadlines || []).find(
    (deadline) => deadline.confirmation_type === confirmationType
  );

  if (deadline) {
    return moment(deadline.date_time_utc).isBefore(moment());
  }

  return false;
};
export const getInstanceDedicatedAllotments = (instance: ProductInstance) =>
  (instance.per_channel_info &&
    instance.per_channel_info.dedicated_allotments) ||
  [];
export const getInstanceClosedChannels = (instance: ProductInstance) =>
  (instance.per_channel_info && instance.per_channel_info.closed_channels) ||
  [];
export const getInstancePerChannelInfo = (instance: ProductInstance) =>
  instance.per_channel_info || {};
export const getInstanceError = (
  instance: ProductInstance,
  t: TranslateFuncType
): string => {
  if (!instance.units || instance.units.length === 0) {
    return t('Error: no prices for time slot');
  }

  return '';
};
export const productInstanceAllChannelsAreClosed = (
  instance: ProductInstance
): boolean => {
  return (
    (instance.per_channel_info &&
      instance.per_channel_info.all_channels_are_closed) ||
    false
  );
};
export const productInstanceChannelsAreClosed = (
  instance: ProductInstance,
  channels: {
    channel_category?: AvailabilityChannelCategory;
    agent_id?: string;
    agent_name?: string;
  }[]
) => {
  if (productInstanceAllChannelsAreClosed(instance)) {
    return true;
  }

  const closedChannels =
    (instance.per_channel_info && instance.per_channel_info.closed_channels) ||
    [];

  for (let i = 0; i < channels.length; i++) {
    if (
      !closedChannels.find((c) =>
        c.channel_category === 'AGENT'
          ? c.agent_id === channels[i].agent_id
          : c.channel_category === channels[i].channel_category
      )
    ) {
      return false;
    }
  }

  return true;
};
export const productInstanceCloseAllAgentChannels = (
  productInstance: ProductInstance,
  agentChannels: {
    channel_category?: AvailabilityChannelCategory;
    agent_id?: string;
    agent_name?: string;
  }[]
): ProductInstance => {
  const closedChannels =
    (productInstance.per_channel_info &&
      productInstance.per_channel_info.closed_channels) ||
    [];
  const nonAgentClosedChannels = closedChannels.filter(
    (c) => c.channel_category !== 'AGENT' && c.channel_category !== 'RESELLER'
  );
  return {
    ...productInstance,
    per_channel_info: {
      ...productInstance.per_channel_info,
      closed_channels: [...nonAgentClosedChannels, ...agentChannels],
    },
  };
};
export const productInstanceOpenAllAgentChannels = (
  productInstance: ProductInstance
): ProductInstance => {
  const closedChannels =
    (productInstance.per_channel_info &&
      productInstance.per_channel_info.closed_channels) ||
    [];
  const nonAgentClosedChannels = closedChannels.filter(
    (c) => c.channel_category !== 'AGENT' && c.channel_category !== 'RESELLER'
  );
  return {
    ...productInstance,
    per_channel_info: {
      ...productInstance.per_channel_info,
      closed_channels: [...nonAgentClosedChannels],
    },
  };
};
export const productInstanceAllChannelSlotsAreFull = (
  instance: ProductInstance
) => {
  const totalCommonSlots = instance.total_slots || 0;
  const occupiedCommonSlots = instance.occupied_slots || 0;

  if (totalCommonSlots > occupiedCommonSlots) {
    return false;
  }

  const dedicatedAllotments =
    (instance.per_channel_info &&
      instance.per_channel_info.dedicated_allotments) ||
    [];

  for (let i = 0; i < dedicatedAllotments.length; i++) {
    const totalSlots = dedicatedAllotments[i].total_slots || 0;
    const occupiedSlots = dedicatedAllotments[i].occupied_slots || 0;

    if (totalSlots > occupiedSlots) {
      return false;
    }
  }

  return true;
};
export const productInstanceFillAllChannelSlots = (
  instance: ProductInstance
): ProductInstance => {
  const dedicatedAllotments =
    (instance.per_channel_info &&
      instance.per_channel_info.dedicated_allotments) ||
    [];
  return {
    ...instance,
    total_slots: instance.occupied_slots,
    per_channel_info: {
      ...instance.per_channel_info,
      dedicated_allotments: dedicatedAllotments.map((a) => ({
        ...a,
        total_slots: a.occupied_slots,
      })),
    },
  };
};
export const getReservationWeightedParticipantCount = (
  reservation: Reservation,
  productInstance?: ProductInstance
): number => {
  let participantCount = 0;
  (reservation.guests || []).forEach((g) => {
    const unit = (productInstance?.units || []).find(
      (u) => (u.guest_type && u.guest_type.key) === g.guest_type_key
    );

    if (
      unit &&
      unit.guest_type &&
      unit.guest_type.minimum_participant_parameters
    ) {
      participantCount +=
        unit.guest_type.minimum_participant_parameters.weight || 0;
    } else {
      participantCount++;
    }
  });
  return participantCount;
};
export const getStartTimes = (product: Product) =>
  (product &&
    product.recurrence &&
    product.recurrence.length > 0 &&
    product.recurrence[0].start_times) ||
  [];
// getGuestTypesUsedInProductInstance returns the guest types used in pricing for the base fare, add-ons, or transportations.
export const getGuestTypesUsedInProductInstance = (
  productInstance: ProductInstance | null,
  product: Product | null,
  t: TranslateFuncType
): GuestType[] => {
  const guestTypes =
    productInstance?.units
      .filter((u) => u.method === 'PER_PARTICIPANT')
      .map((u) => u.guest_type) ?? [];

  if (guestTypes.length > 0) {
    return _.uniqBy(guestTypes as any, (g) => g.key);
  }

  const perBookingGuestTypes = productInstance?.units.find(
    (u) =>
      u.method === 'PER_BOOKING' && (u.per_booking_guest_types ?? []).length > 0
  )?.per_booking_guest_types;
  if ((perBookingGuestTypes ?? []).length > 0) {
    return _.uniqBy(perBookingGuestTypes as any, (g) => g.key);
  }

  if (product?.add_ons) {
    for (let i = 0; i < product.add_ons.length; i++) {
      const addOn = product.add_ons[i];

      if (addOn.pricing) {
        const guestTypes = addOn.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type);

        if (guestTypes.length > 0) {
          return guestTypes as any;
        }
      }
    }
  }

  if (product?.transportations) {
    for (let i = 0; i < product.transportations.length; i++) {
      const transportation = product.transportations[i];

      if (transportation.pricing) {
        const guestTypes = transportation.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type);

        if (guestTypes.length > 0) {
          return guestTypes as any;
        }
      }
    }
  }

  return [
    {
      key: 'guest',
      title: t('Guest'),
    },
  ];
};
// getGuestTypeKeysUsedInProduct returns the guest type keys from product data.
// ** Adapted from nutmeg **
export const getGuestTypeKeysUsedInProduct = (product: Product): string[] => {
  {
    const units = (product.pricing?.[0]?.units ?? [])
      .filter((unit) => unit.method === 'PER_PARTICIPANT')
      .map((u) => u.guest_type?.key ?? '');

    if (units.length > 0) {
      return [...new Set(units)];
    }
  }

  {
    const perBookingGuestTypes = (product.pricing?.[0]?.units ?? []).find(
      (unit) =>
        unit.method === 'PER_BOOKING' &&
        (unit.per_booking_guest_types ?? []).length > 0
    )?.per_booking_guest_types;

    if (perBookingGuestTypes && perBookingGuestTypes?.length > 0) {
      return [
        ...new Set(
          perBookingGuestTypes.map((guestType) => guestType.key ?? '')
        ),
      ];
    }
  }

  if (product.add_ons) {
    for (const addOn of product.add_ons) {
      if (addOn.pricing) {
        const units = addOn.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type?.key ?? '');

        if (units.length > 0) {
          return [...new Set(units)];
        }
      }
    }
  }

  if (product.transportations) {
    for (const transportation of product.transportations) {
      if (transportation.pricing) {
        const units = transportation.pricing
          .filter((u) => u.method === 'PER_PARTICIPANT')
          .map((u) => u.guest_type?.key ?? '');

        if (units.length > 0) {
          return [...new Set(units)];
        }
      }
    }
  }

  return ['guest'];
};
// Plagiarized from https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const generateAgentReference = () => {
  const uniqueChars = 8;
  return `${generateRandomString(
    uniqueChars,
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  )}`;
};
export const rewindToMidnight = (m: Moment): Moment =>
  moment(m).hours(0).minutes(0);
export const fastForwardToMidnight = (m: Moment): Moment =>
  moment(m).add(1, 'days').hours(0).minutes(0).subtract(1, 'milliseconds');
export const getActionSourceEntityName = (
  actionSource: ActionSource,
  t: TFunction
): string => {
  switch (actionSource.entity_type) {
    case 'AGENT_ENTITY_TYPE':
    case 'SUPPLIER_ENTITY_TYPE':
      return (actionSource && actionSource.entity_name) || '';

    case 'GUEST_ENTITY_TYPE':
      return t('Guest');

    default:
      return 'Nutmeg';
  }
};

// TODO(goro) : include this info to product.reservation_form_fields fields
export const isHiddenFromReservationFormFields = (
  fieldName: string
): boolean => {
  return [
    'preferred_language_iso2',
    'hotel_information',
    'hotel_information_checkin_checkout',
    'representative_name',
    'consent_form',
    'hotel_tbd_form',
    'email',
  ].includes(fieldName);
};

export const getDisplayReservationFormFields = (
  reservation_form_fields: Field[],
  t: TFunction
): Field[] => {
  return [
    {
      // NOTE(goro) : Email is always displayed
      key: 'email',
      prompt: t('Email'),
      format: 'email',
      required: 'OPTIONAL',
      type: 'PER_BOOKING',
    },
    ...(reservation_form_fields || []).filter((field) => {
      return !isHiddenFromReservationFormFields(field.key || '');
    }),
  ];
};
export const getFormattedTime = (hhMm: string, locale: string): string => {
  return moment(hhMm, 'HH:mm').locale(locale).format('LT');
};
export const getFormattedDateTime = (
  hhMm: string,
  yyyyMmDd: string,
  locale: string
): string => {
  return `${moment(hhMm, 'HH:mm').locale(locale).format('LT')} (${moment(
    yyyyMmDd
  )
    .locale(locale)
    .format('LL')})`;
};
