import * as React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

type Props = {
  text: string;
  helpText: string | React.ReactNode;
};
export const LabelWithHelpText = ({ text, helpText }: Props) => {
  return (
    <label>
      {text}
      {helpText && (
        <>
          {' '}
          <Popup
            flowing
            content={helpText}
            hoverable
            trigger={
              <Icon color="blue" inverted size="small" name="help" circular />
            }
          />
        </>
      )}
    </label>
  );
};
