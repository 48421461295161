import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { ModalLoader } from 'client/components/ModalLoader';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import baseStyles from 'client/base.module.css';
import stopIcon from 'client/images/ic_stop.svg';

import { Checkbox } from '../Availability/Checkbox';

import { SeatCalendarEditContext } from './SeatCalendarEditContext';
import {
  EquipmentAvailability,
  StartTimeAvailabilities,
  DateAvailability,
  getDateTotalPax,
} from './util';
import styles from './SeatAvailabilityCustomTable.module.css';
import componentStyles from './components.module.css';

const getDateHeader = (
  column: CustomTableColumn,
  locale: string,
  dayMonthFormat: string
) => {
  const dateMoment = moment(column.accessor).locale(locale);
  return (
    <span className={clsx(baseStyles['product-calendar-table-date-header'])}>
      <div>{`${dateMoment.format(dayMonthFormat)} (${dateMoment.format(
        'ddd'
      )})`}</div>
    </span>
  );
};

export type CustomTableColumn = {
  accessor: string;
};

type Props = {
  items: EquipmentAvailability[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  onEditEquipmentInstanceClick: (arg: string | null) => void;
};
export const SeatAvailabilityCustomTable = ({
  items,
  columns,
  usePaging,
  onEditEquipmentInstanceClick,
}: Props) => {
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedEquipmentInstanceIds,
    selectEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
  } = React.useContext(SeatCalendarEditContext);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const totalPaxByDate = React.useMemo(() => {
    return getDateTotalPax(items);
  }, [items]);

  const shouldHideScrollButtons = columns.length < 8;

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    setRowCount(count);
    setCurrentPage(1);
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (items.length < rowCount * page) {
      page = Math.floor(items.length / rowCount);
    }

    setCurrentPage(page);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [rowCount]);
  React.useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);

  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const visibleItems = items.filter((_, index) => {
    if (index < rowHead) {
      return false;
    }

    if (rowHead + rowCount <= index) {
      return false;
    }

    return true;
  });

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getTotalPaxHeader = (column: CustomTableColumn) => {
    return (
      <>
        {typeof totalPaxByDate[column.accessor] === 'number' ? (
          <a className={clsx(componentStyles['total'])}>
            {t('{{count}} seat', {
              count: totalPaxByDate[column.accessor],
            })}
          </a>
        ) : (
          <ModalLoader />
        )}
      </>
    );
  };

  return (
    <>
      <div className={clsx(componentStyles['c-table-availability'])}>
        <div
          className={clsx(componentStyles['c-table-availability__thead'])}
          ref={tableHeaderRef}
        >
          <div
            className={clsx(
              componentStyles['c-table-availability__thead__left']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-t-48'],
                styles['product-icon-header']
              )}
            ></div>
            <div
              className={clsx(
                baseStyles['base-t-320'],
                styles['product-name-header']
              )}
            >
              <p>{t('Resource')}</p>
            </div>
          </div>
          <div
            className={clsx(
              componentStyles['c-table-availability__thead__right']
            )}
          >
            <table>
              <tbody>
                <tr>
                  <th
                    className={clsx(
                      baseStyles['base-t-80'],
                      componentStyles['left'],
                      componentStyles['spFixed'],
                      styles['start-time-header']
                    )}
                    key={0}
                  >
                    {t('Start Times')}
                  </th>
                  {columns.map((column, idx) => {
                    const columnEquipmentInstanceIds: string[] = [];

                    for (const item of visibleItems) {
                      for (const availability of item.startTimesAvailabilities) {
                        const equipmentInstanceId =
                          availability.dateAvailabilities?.find(
                            (dateAvailability) =>
                              dateAvailability.date === column.accessor
                          )?.equipmentInstanceId;

                        if (equipmentInstanceId) {
                          columnEquipmentInstanceIds.push(equipmentInstanceId);
                        }
                      }
                    }

                    const columnIsChecked = columnEquipmentInstanceIds.every(
                      (id) => selectedEquipmentInstanceIds.includes(id)
                    );

                    return (
                      <th key={idx + 1} className={baseStyles['base-t-80']}>
                        <div>
                          {getDateHeader(column, locale, dayMonthFormat)}
                        </div>
                        {calendarEditModeIsActive && (
                          <Box display="flex" justifyContent="center" mt={1}>
                            <Box>
                              <Checkbox
                                checked={columnIsChecked}
                                onChange={() => {
                                  if (columnIsChecked) {
                                    deselectEquipmentInstanceIds(
                                      ...columnEquipmentInstanceIds
                                    );
                                  } else {
                                    selectEquipmentInstanceIds(
                                      ...columnEquipmentInstanceIds
                                    );
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  <th
                    className={clsx(
                      baseStyles['base-t-80'],
                      componentStyles['left'],
                      componentStyles['spFixed'],
                      styles['pax-count-header']
                    )}
                    key={0}
                  >
                    {t('Total Pax')}
                  </th>
                  {columns.map((column, idx) => {
                    return (
                      <th key={idx + 1} className={baseStyles['base-t-80']}>
                        {getTotalPaxHeader(column)}
                      </th>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={clsx(componentStyles['c-table-availability__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <div className={styles['scroll-wrapper']}>
            {visibleItems.map((item, idx) => {
              return (
                <EquipmentBlock
                  item={item}
                  key={idx}
                  columns={columns}
                  visibleItems={visibleItems}
                  onEditEquipmentInstanceClick={onEditEquipmentInstanceClick}
                />
              );
            })}
          </div>
        </div>

        {!shouldHideScrollButtons && (
          <>
            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['prev']
              )}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>

            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['next']
              )}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </>
        )}
      </div>

      {usePaging && (
        <>
          <div className={clsx(componentStyles['c-pagination'])}>
            <div className={clsx(componentStyles['c-pagination__size'])}>
              <p>{t('Number of lines')}:</p>
              <label>
                <select value={rowCount} onChange={rowCountChangeHandler}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="50">50</option>
                </select>
              </label>
            </div>
            <div className={clsx(componentStyles['c-pagination__jump'])}>
              <p>{t('Page')}:</p>
              <input
                type="number"
                value={currentPage}
                onChange={currentPageHandler}
              />
              <p>of {pageCount}</p>
            </div>
            <div className={clsx(componentStyles['c-pagination__btns'])}>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  1 < currentPage ? '' : componentStyles['disable']
                )}
                onClick={pageBackClickHandler}
              >
                {t('Previous')}
              </a>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  currentPage < pageCount ? '' : componentStyles['disable']
                )}
                onClick={pageForwardClickHandler}
              >
                {t('Next')}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const EquipmentBlock = ({
  item,
  visibleItems,
  columns,
  onEditEquipmentInstanceClick,
}: {
  item: EquipmentAvailability;
  visibleItems: EquipmentAvailability[];
  columns: CustomTableColumn[];
  onEditEquipmentInstanceClick: (args: string | null) => void;
}) => {
  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );
  const { t } = useTranslation();

  const {
    calendarEditModeIsActive,
    selectedEquipmentInstanceIds,
    selectEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
  } = React.useContext(SeatCalendarEditContext);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div className={clsx(componentStyles['c-table-availability__tbody__box'])}>
      <div
        className={clsx(
          componentStyles['c-table-availability__tbody__box__left']
        )}
      >
        <div
          className={clsx(baseStyles['base-t-48'], styles['product-icon-cell'])}
        ></div>
        <div
          className={clsx(
            baseStyles['base-t-320'],
            styles['product-name-cell']
          )}
        >
          <div>
            <div data-text={t('Detail')}>
              {item.equipmentTitle}
              {process.env.NODE_ENV === 'development' && (
                <div>{item.equipmentId}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          componentStyles['c-table-availability__tbody__box__right']
        )}
      >
        <table className={clsx(componentStyles['availability__table'])}>
          <tbody>
            <tr className={clsx(componentStyles['availability__table'])}>
              <th
                className={clsx(
                  baseStyles['base-t-80'],
                  componentStyles['left'],
                  componentStyles['spFixed'],
                  componentStyles['availability__table'],
                  styles['mobile-start-time-header']
                )}
                key={0}
              >
                {t('Start Times')}
              </th>
              {columns.map((column, idx) => {
                const columnEquipmentInstanceIds: string[] = [];

                for (const item of visibleItems) {
                  for (const availability of item.startTimesAvailabilities) {
                    const equipmentInstanceId =
                      availability.dateAvailabilities?.find(
                        (dateAvailability) =>
                          dateAvailability.date === column.accessor
                      )?.equipmentInstanceId;

                    if (equipmentInstanceId) {
                      columnEquipmentInstanceIds.push(equipmentInstanceId);
                    }
                  }
                }

                const columnIsChecked = columnEquipmentInstanceIds.every((id) =>
                  selectedEquipmentInstanceIds.includes(id)
                );

                return (
                  <th key={idx + 1} className={baseStyles['base-t-80']}>
                    {getDateHeader(column, locale, dayMonthFormat)}
                    {calendarEditModeIsActive && (
                      <Box display="flex" justifyContent="center">
                        <Box width="32px">
                          <Checkbox
                            checked={columnIsChecked}
                            onChange={() => {
                              if (columnIsChecked) {
                                deselectEquipmentInstanceIds(
                                  ...columnEquipmentInstanceIds
                                );
                              } else {
                                selectEquipmentInstanceIds(
                                  ...columnEquipmentInstanceIds
                                );
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </th>
                );
              })}
            </tr>
            {availabilityLoading ? (
              <tr className={clsx(componentStyles['availability__table'])}>
                <td className={clsx(componentStyles['availability__table'])}>
                  <ModalLoader />
                </td>
              </tr>
            ) : item.startTimesAvailabilities.length === 0 ? (
              <StartTimeBlankBlock columns={columns} />
            ) : (
              item.startTimesAvailabilities.map((s, idx) => {
                return (
                  <StartTimeBlock
                    s={s}
                    key={idx}
                    columns={columns}
                    onEditEquipmentInstanceClick={onEditEquipmentInstanceClick}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StartTimeBlock = ({
  s,
  columns,
  onEditEquipmentInstanceClick,
}: {
  s: StartTimeAvailabilities;
  columns: CustomTableColumn[];
  onEditEquipmentInstanceClick: (args: string | null) => void;
}) => {
  const {
    calendarEditModeIsActive,
    selectedEquipmentInstanceIds,
    selectEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
  } = React.useContext(SeatCalendarEditContext);

  return (
    <>
      <tr className={clsx(componentStyles['availability__table'])}>
        <td
          className={clsx(
            baseStyles['base-t-80'],
            componentStyles['left'],
            componentStyles['spFixed'],
            componentStyles['availability__table'],
            styles['start-time-cell']
          )}
        >
          <Box display="flex" alignItems="center">
            <div>{s.startTime}</div>
            {calendarEditModeIsActive && (
              <Box ml="auto">
                <Checkbox
                  checked={
                    s.dateAvailabilities?.every((availability) =>
                      selectedEquipmentInstanceIds.includes(
                        availability.equipmentInstanceId ?? ''
                      )
                    ) ?? false
                  }
                  onChange={() => {
                    const checked = s.dateAvailabilities?.every(
                      (availability) =>
                        selectedEquipmentInstanceIds.includes(
                          availability.equipmentInstanceId ?? ''
                        )
                    );
                    if (checked) {
                      deselectEquipmentInstanceIds(
                        ...(s.dateAvailabilities?.map(
                          (availability) =>
                            availability.equipmentInstanceId ?? ''
                        ) ?? [])
                      );
                    } else {
                      selectEquipmentInstanceIds(
                        ...(s.dateAvailabilities?.map(
                          (availability) =>
                            availability.equipmentInstanceId ?? ''
                        ) ?? [])
                      );
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        </td>

        {columns.map((column, idx) => {
          return (
            <StartTimeCell
              column={column}
              s={s}
              key={idx}
              onEditEquipmentInstanceClick={onEditEquipmentInstanceClick}
            />
          );
        })}
      </tr>
    </>
  );
};

const StartTimeCell = ({
  s,
  column,
  onEditEquipmentInstanceClick,
}: {
  s: StartTimeAvailabilities;
  column: CustomTableColumn;
  onEditEquipmentInstanceClick: (args: string | null) => void;
}) => {
  const {
    calendarEditModeIsActive,
    selectedEquipmentInstanceIds,
    selectEquipmentInstanceIds,
    deselectEquipmentInstanceIds,
  } = React.useContext(SeatCalendarEditContext);

  const availability = s.dateAvailabilities?.find(
    (availability) => availability.date === column.accessor
  );
  return (
    <>
      <td
        className={clsx(
          componentStyles['availability__table'],
          baseStyles['base-t-80']
        )}
      >
        {availability ? (
          <StartTimeCellAnchorTag
            dateAvailability={availability}
            onClick={() => {
              if (calendarEditModeIsActive) {
                if (
                  selectedEquipmentInstanceIds.includes(
                    availability?.equipmentInstanceId ?? ''
                  )
                ) {
                  deselectEquipmentInstanceIds(
                    availability?.equipmentInstanceId ?? ''
                  );
                } else {
                  selectEquipmentInstanceIds(
                    availability?.equipmentInstanceId ?? ''
                  );
                }
              } else {
                onEditEquipmentInstanceClick(availability?.equipmentInstanceId);
              }
            }}
          />
        ) : (
          <p className={clsx(componentStyles['no-allotment'])}>-</p>
        )}
      </td>
    </>
  );
};

const StartTimeCellAnchorTag = ({
  dateAvailability,
  onClick,
}: {
  dateAvailability: DateAvailability;
  onClick: () => void;
}) => {
  const { calendarEditModeIsActive, selectedEquipmentInstanceIds } =
    React.useContext(SeatCalendarEditContext);

  const formattedOccupancy = `${
    dateAvailability.occupied ?? 0
  } / ${formattedTotalAllotmentSlots(dateAvailability.total ?? 0)}`;

  const backgroundColorClassName = getAvailabilityBackgroundColorClass(
    dateAvailability.occupied ?? 0,
    dateAvailability.total ?? 0,
    dateAvailability.isClosed
  );

  const showAssignableEquipmentInstances =
    (dateAvailability.assignableEquipmentInstances?.length ?? 0) > 1;

  return (
    <a
      className={clsx(
        baseStyles['base-t-80'],
        backgroundColorClassName &&
          componentStyles[
            backgroundColorClassName as keyof typeof componentStyles
          ],
        calendarEditModeIsActive &&
          (selectedEquipmentInstanceIds.includes(
            dateAvailability.equipmentInstanceId ?? ''
          )
            ? styles['selected-cell']
            : styles['deselected-cell'])
      )}
      style={{
        flexDirection: 'column',
      }}
      onClick={onClick}
    >
      {dateAvailability.isClosed && (
        <img
          className={componentStyles['availability__table']}
          src={stopIcon}
        />
      )}
      {formattedOccupancy}
      {showAssignableEquipmentInstances && (
        <>
          {dateAvailability.assignableEquipmentInstances?.map(
            (assignableEquipmentInstance, idx) => {
              return (
                <span key={idx}>
                  ({assignableEquipmentInstance.title}{' '}
                  {assignableEquipmentInstance.total})
                </span>
              );
            }
          )}
        </>
      )}
    </a>
  );
};

const StartTimeBlankBlock = ({ columns }: { columns: CustomTableColumn[] }) => {
  return (
    <>
      <tr className={clsx(componentStyles['availability__table'])}>
        <td
          className={clsx(
            baseStyles['base-t-80'],
            componentStyles['left'],
            componentStyles['spFixed'],
            componentStyles['availability__table'],
            styles['start-time-cell']
          )}
        ></td>

        {columns.map((_, idx) => {
          return (
            <>
              <td
                className={clsx(
                  componentStyles['availability__table'],
                  baseStyles['base-t-80']
                )}
                key={idx}
              >
                <a className={clsx(baseStyles['base-t-80'])}></a>
              </td>
            </>
          );
        })}
      </tr>
    </>
  );
};

type ColorClassName =
  | 'gray'
  | 'yellow'
  | 'red100'
  | 'red75'
  | 'red50'
  | 'red25'
  | '';

const getAvailabilityBackgroundColorClass = (
  bookedSlots: number,
  totalSlots: number,
  isClosed?: boolean
): ColorClassName => {
  if (isClosed) {
    return 'gray';
  }
  if (totalSlots === 0) {
    if (bookedSlots !== 0) {
      return 'yellow';
    }
  } else {
    if (bookedSlots >= totalSlots) {
      return 'red100';
    }

    const ratio = bookedSlots / totalSlots;

    if (ratio >= 0.75) {
      return 'red75';
    }

    if (ratio >= 0.5) {
      return 'red50';
    }

    if (ratio > 0) {
      return 'red25';
    }
  }

  return '';
};
