import * as React from 'react';

type Props = {
  src: string;
  onClick?: () => void;
  className?: string;
};
export const Icon = ({ className, src, onClick }: Props) => {
  return (
    <a className={className} onClick={() => onClick?.()}>
      <img src={src} />
    </a>
  );
};
