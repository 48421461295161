import type { MediaItem } from 'shared/models/swagger';

export const isYouTubeUrl = (url: string): boolean => {
  return /^https:\/\/(www\.)?you/.test(url);
};
export const convertMediaUrlsToProductMediaItems = (
  mediaUrls: string[]
): MediaItem[] => {
  return mediaUrls.map((mediaUrl) =>
    isYouTubeUrl(mediaUrl) || mediaUrl.toLowerCase().endsWith('.mp4')
      ? {
          url: mediaUrl,
          type: 'VIDEO',
        }
      : {
          url: mediaUrl,
          type: 'IMAGE',
        }
  );
};
