import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import type { FamilyDiscount } from 'shared/models/swagger';

type Props = {
  showErrors: boolean;
  familyDiscount: FamilyDiscount;
  onFamilyDiscountChange: (newFamilyDiscount: FamilyDiscount) => void;
  guestTypeKeys: string[];
};
export const FamilyDiscountInput = ({
  familyDiscount,
  guestTypeKeys,
  onFamilyDiscountChange,
  showErrors,
}: Props) => {
  const { t } = useTranslation();
  const baseGuestTypeKey =
    (familyDiscount && familyDiscount.base_guest_type_key) || '';
  const qualifyingBaseGuestCount =
    (familyDiscount && familyDiscount.qualifying_base_guest_count) || 1;
  const discountGuestTypeKey =
    (familyDiscount && familyDiscount.discount_guest_type_key) || '';
  const guestTypeKeyOptions = guestTypeKeys.map((key) => ({
    key,
    value: key,
    text: key,
  }));
  return (
    <div style={{ display: 'flex', marginTop: '20px' }}>
      <div style={{ width: 'calc((100% - 20px) / 3)', marginRight: '10px' }}>
        <div style={{ display: 'flex', fontWeight: 'var(--text-semibold)' }}>
          <div style={{ marginRight: '5px' }}>{t('Base unit')}</div>
          <Tooltip text={t('Select the units required to apply the discount')}>
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        </div>
        <SingleDropdown
          error={(showErrors && !baseGuestTypeKey) as any}
          selectedOption={baseGuestTypeKey}
          options={guestTypeKeyOptions}
          onChange={(value) => {
            onFamilyDiscountChange({
              ...familyDiscount,
              base_guest_type_key: value,
            });
          }}
        />
      </div>
      <div style={{ width: 'calc((100% - 20px) / 3)', marginRight: '10px' }}>
        <div style={{ display: 'flex', fontWeight: 'var(--text-semibold)' }}>
          <div style={{ marginRight: '5px' }}>{t('Base unit quantity')}</div>
          <Tooltip
            text={t(
              'Select the number of participants required to apply the discount'
            )}
          >
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        </div>
        <SingleDropdown
          selectedOption={qualifyingBaseGuestCount as any}
          options={
            [1, 2, 3, 4].map((v) => ({
              key: v,
              value: v,
              text: v,
            })) as any
          }
          onChange={(value) => {
            onFamilyDiscountChange({
              ...familyDiscount,
              qualifying_base_guest_count: parseInt(value, 10),
            });
          }}
        />
      </div>
      <div style={{ width: 'calc((100% - 20px) / 3)' }}>
        <div style={{ display: 'flex', fontWeight: 'var(--text-semibold)' }}>
          <div style={{ marginRight: '5px' }}>{t('Discounted unit')}</div>
          <Tooltip text={t('Select the unit to discount')}>
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        </div>
        <SingleDropdown
          error={(showErrors && !discountGuestTypeKey) as any}
          selectedOption={discountGuestTypeKey}
          options={guestTypeKeyOptions}
          onChange={(value) => {
            onFamilyDiscountChange({
              ...familyDiscount,
              discount_guest_type_key: value,
            });
          }}
        />
      </div>
    </div>
  );
};
