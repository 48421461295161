import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_E_TICKET_FOOTPRINTS_REQUEST,
  FETCH_E_TICKET_FOOTPRINTS_SUCCESS,
  FETCH_E_TICKET_FOOTPRINTS_FAILURE,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST,
  DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  ListETicketFootprintsResponse,
  ListETicketFootprintsRequest,
  DownloadETicketFootprintCSVRequest,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchETicketFootprintsRequest = () => ({
  type: FETCH_E_TICKET_FOOTPRINTS_REQUEST,
});

const fetchETicketFootprintsSuccess = (
  response: ListETicketFootprintsResponse
) => ({
  type: FETCH_E_TICKET_FOOTPRINTS_SUCCESS,
  response,
});

const fetchETicketFootprintsFailure = (error: any) => ({
  type: FETCH_E_TICKET_FOOTPRINTS_FAILURE,
  error,
});

export const fetchETicketFootprints =
  (queryParams?: ListETicketFootprintsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchETicketFootprintsRequest());
    return axios
      .get('/api/eticketfootprints', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchETicketFootprintsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchETicketFootprintsFailure(err.message));
      });
  };

const downloadETicketFootprintCSVRequest = () => ({
  type: DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST,
});

const downloadETicketFootprintCSVSuccess = () => ({
  type: DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS,
});

const downloadETicketFootprintCSVFailure = (error: string) => ({
  type: DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE,
  error,
});

export const downloadETicketFootprintCSV =
  (queryParams?: DownloadETicketFootprintCSVRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadETicketFootprintCSVRequest());
    axios
      .get(`/api/eticketfootprintcsv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'e_ticket_view_history.csv');
        dispatch(downloadETicketFootprintCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          downloadETicketFootprintCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
