import type { CheckinInfo } from 'shared/models/swagger';

export const getStubText = (
  stubKey: string,
  checkinInfo: CheckinInfo | undefined
) => {
  const stub = checkinInfo?.stubs?.find((stub) => stub.key === stubKey);
  if (stub == null) {
    return '';
  }
  return stub.text ?? '';
};

export const isCheckinFully = (
  stubKey: string,
  checkinInfo: CheckinInfo | undefined,
  guestCount: number
) => {
  const checkinRecords = checkinInfo?.checkin_records ?? [];
  let checkinGuestCount = 0;
  for (const checkinRecord of checkinRecords) {
    if (stubKey === '' || checkinRecord.stub_key === stubKey) {
      checkinGuestCount += checkinRecord.guest_count ?? 0;
    }
  }
  if (guestCount <= checkinGuestCount) {
    return true;
  }
  return false;
};

export const noMoreAvailable = (
  stubKey: string,
  checkinInfo: CheckinInfo | undefined
): boolean => {
  const availableStubCount = checkinInfo?.available_stub_count ?? 0;
  const checkinRecords = checkinInfo?.checkin_records ?? [];

  // availableStubCount is 0 means the guest available to checkin is unlimited
  if (availableStubCount === 0) {
    return false;
  }

  const usedStubs: { [key: string]: number } = {};
  for (const checkinRecord of checkinRecords) {
    if (checkinRecord.stub_key) {
      if (usedStubs[checkinRecord.stub_key]) {
        usedStubs[checkinRecord.stub_key] += 1;
      } else {
        usedStubs[checkinRecord.stub_key] = 1;
      }
    }
  }

  return (
    !Object.keys(usedStubs).includes(stubKey) &&
    availableStubCount <= Object.keys(usedStubs).length
  );
};

export const getRemainingGuestsToCheckin = (
  stubKey: string,
  checkinInfo: CheckinInfo | undefined,
  guestCount: number
): number => {
  if (!stubKey) {
    return guestCount;
  }

  const checkinRecords = checkinInfo?.checkin_records ?? [];
  const usedStubs: { [key: string]: number } = {};
  for (const checkinRecord of checkinRecords) {
    if (checkinRecord.stub_key) {
      if (usedStubs[checkinRecord.stub_key]) {
        usedStubs[checkinRecord.stub_key] += checkinRecord.guest_count ?? 0;
      } else {
        usedStubs[checkinRecord.stub_key] = checkinRecord.guest_count ?? 0;
      }
    }
  }

  if (usedStubs[stubKey]) {
    return guestCount - (usedStubs[stubKey] ?? 0);
  }

  return guestCount;
};
