import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { FieldWrapper, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

export const CouponEditor = () => {
  const { t } = useTranslation();
  const coupons = useSelector((state: ReduxState) => state.guidanceCoupons.all);

  const couponOptions = coupons.map((coupon) => ({
    text: coupon.title ?? '',
    value: coupon.id ?? '',
  }));

  return (
    <Box mt={2}>
      <FieldWrapper label={t('Coupons')}>
        <FieldArray name="couponIds">
          {({ fields }) =>
            fields.length === 0 ? (
              <AddIcon onClick={() => (fields as any).insertAt(0, '')} />
            ) : (
              fields.map((name, idx) => (
                <Field key={name} name={name}>
                  {({ input }) => (
                    <Box display="flex">
                      <Select
                        width={250}
                        search
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                        options={couponOptions}
                      />
                      <Box display="flex" ml={1}>
                        <AddIcon
                          onClick={() => (fields as any).insertAt(idx + 1, '')}
                        />
                        <DeleteIcon onClick={() => fields.remove(idx)} />
                      </Box>
                    </Box>
                  )}
                </Field>
              ))
            )
          }
        </FieldArray>
      </FieldWrapper>
    </Box>
  );
};
