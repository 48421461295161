import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useState } from 'react';

import { sequentialBatchUpdateReservations } from 'client/actions/reservations';
import { visibleColumnsForDispatchSelector } from 'client/reducers/dispatchTableControls';
import {
  allDispatchVehiclesSelector,
  allDispatchMiscResourcesSelector,
} from 'client/reducers/dispatchSettings';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers/index';
import { Modal } from 'client/components/v3/Form/Modal';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { ResourceAssignmentReservationsTable } from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignmentContents/ResourceAssignmentReservationsTable';
import { getAssignedResources } from 'client/components/ResourceAssignmentModal/resources';
import { groupReservationsByStartTime } from 'client/libraries/util/groupReservationsByStartTime';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import type {
  ManifestCustomizedColumnName,
  ReservationColumn,
} from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignment.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import dailyStyles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

const reservationIncludesPickup = (
  reservation: ManifestReservationShape,
  pickupLocationFilter: string
) =>
  reservation.pickup_checkin_location === pickupLocationFilter ||
  reservation.transport_route?.some(
    (routeItem) =>
      routeItem.location_from?.location_name === pickupLocationFilter
  );

const reservationIncludesDropoff = (
  reservation: ManifestReservationShape,
  dropoffLocationFilter: string
) =>
  reservation.dropoff_checkout_location === dropoffLocationFilter ||
  reservation.transport_route?.some(
    (routeItem) =>
      routeItem.location_to?.location_name === dropoffLocationFilter
  );

const filteredReservations = (
  reservations: ManifestReservationShape[],
  pickupLocationFilter: string,
  dropoffLocationFilter: string
): ManifestReservationShape[] => {
  return reservations.filter(
    (reservation) =>
      (!pickupLocationFilter ||
        reservationIncludesPickup(reservation, pickupLocationFilter)) &&
      (!dropoffLocationFilter ||
        reservationIncludesDropoff(reservation, dropoffLocationFilter))
  );
};

type Props = {
  resourceType: ResourceType;
  resourceKey: string;
  title: string;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  visibleColumns?: ReservationColumn[];
  open: boolean;
  onClose: () => void;
};
export const ResourceAssignmentModal = ({
  resourceType,
  resourceKey,
  title,
  customizedColumnNames,
  visibleColumns,
  open,
  onClose,
}: Props) => {
  const [updateRequests, setUpdateRequests] = useState<any[]>([]);
  const [pickupLocationFilter, setPickupLocationFilter] = useState('');
  const [dropoffLocationFilter, setDropoffLocationFilter] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const allReservations = useSelector((state: ReduxState) =>
    state.dispatch.reservations.map((r) => toManifestReservationShape(r, t))
  );
  const allDispatchMiscResources = useSelector(
    allDispatchMiscResourcesSelector
  );
  const resourceViewVisibleColumns = useSelector(
    visibleColumnsForDispatchSelector
  );
  const allDispatchVehicles = useSelector(allDispatchVehiclesSelector);

  const reservationWillBeAssignedSelectedResource = (
    reservation: ManifestReservationShape
  ) => {
    const updateRequest = updateRequests.find(
      (req) => req.id === reservation.id
    );

    if (updateRequest) {
      const updateRequestResourceList = getAssignedResources(
        updateRequest.patch,
        resourceType
      );
      return updateRequestResourceList.indexOf(resourceKey) !== -1;
    }

    return (
      getAssignedResources(reservation, resourceType).indexOf(resourceKey) !==
      -1
    );
  };

  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);
  const productsById = useSelector((state: ReduxState) => state.products.byID);
  const products = [
    ...new Set(allReservations.map((reservation) => reservation.product_id)),
  ]
    .map((productId) => productsById[productId])
    .filter((p) => Boolean(p));
  let resourceTypeText = '';

  switch (resourceType) {
    case 'vehicle':
      resourceTypeText = t('Vehicle');
      break;

    case 'crew':
      resourceTypeText = t('Crew Member');
      break;

    case 'other':
      resourceTypeText = t('Other');
      break;

    case 'guide':
      resourceTypeText = t('Staff');
      break;

    default:
      break;
  }

  const reservationsByStartTime = groupReservationsByStartTime(
    allReservations,
    locale
  );
  const capacityDescriptions = reservationsByStartTime.map(
    ({ startTime, reservations }) => {
      let capacityDescription = '';

      if (resourceType === 'vehicle' || resourceType === 'other') {
        const resourceList =
          resourceType === 'vehicle'
            ? allDispatchVehicles
            : allDispatchMiscResources;
        const resource = resourceList.find(
          (resource) => resource.key === resourceKey
        );
        let totalCapacity = 0;

        if (resource && resource.capacity) {
          totalCapacity = resource.capacity;
        }

        let currentOccupiedCapacity = 0;
        let newOccupiedCapacity = 0;
        reservations.forEach((reservation) => {
          if (
            getAssignedResources(reservation, resourceType).indexOf(
              resourceKey
            ) !== -1
          ) {
            currentOccupiedCapacity += reservation.guests.length;
          }

          if (reservationWillBeAssignedSelectedResource(reservation)) {
            newOccupiedCapacity += reservation.guests.length;
          }
        });

        if (currentOccupiedCapacity === newOccupiedCapacity) {
          capacityDescription = `(${currentOccupiedCapacity}/${totalCapacity})`;
        } else {
          capacityDescription = `(${currentOccupiedCapacity}/${totalCapacity}) -> (${newOccupiedCapacity}/${totalCapacity})`;
        }
      }

      return `${startTime} : ${capacityDescription}`;
    }
  );
  const pickupFilterOptions = [
    {
      value: '',
      key: '',
      text: t('not selected'),
    },
    ...[
      ...new Set(
        _.flatten(
          allReservations.map((reservation) =>
            [
              reservation.pickup_checkin_location,
              ...(reservation.transport_route ?? []).map(
                (routeItem) => routeItem.location_from?.location_name
              ),
            ].filter((loc) => Boolean(loc))
          )
        )
      ),
    ].map((option) => ({
      value: option ?? '',
      key: option ?? '',
      text: option ?? '',
    })),
  ];
  const dropoffFilterOptions = [
    {
      value: '',
      key: '',
      text: t('not selected'),
    },
    ...[
      ...new Set(
        _.flatten(
          allReservations.map((reservation) =>
            [
              reservation.dropoff_checkout_location,
              ...(reservation.transport_route ?? []).map(
                (routeItem) => routeItem.location_to?.location_name
              ),
            ].filter((loc) => Boolean(loc))
          )
        )
      ),
    ].map((option) => ({
      value: option ?? '',
      key: option ?? '',
      text: option ?? '',
    })),
  ];

  return (
    <Modal
      title={t('Assign Resource')}
      open={open}
      onClose={onClose}
      style={{ width: '80%', height: '600px' }}
      useCloseButton={true}
      rightActionChildren={
        <Button
          text={t('Save')}
          loading={reservationLoading}
          disabled={updateRequests.length === 0}
          onClick={async () => {
            await dispatch(sequentialBatchUpdateReservations(updateRequests));
            await onClose();
          }}
        />
      }
      insertAtRoot={true}
    >
      <>
        <div className={styles['p-assignmentsModal']}>
          <div className={styles['p-assignmentsModal__search']}>
            <div className={styles['p-assignmentsModal__search__item']}>
              <p className={styles['p-assignmentsModal__search__item__ttl']}>
                {t('Resource Type')}
              </p>
              <div className={styles['p-assignmentsModal__search__item__body']}>
                {' '}
                {resourceTypeText}
              </div>
            </div>
            <div className={styles['p-assignmentsModal__search__item']}>
              <p className={styles['p-assignmentsModal__search__item__ttl']}>
                {t('Resource')}
              </p>
              <div className={styles['p-assignmentsModal__search__item__body']}>
                {title || resourceKey}
              </div>
            </div>
            <div className={styles['p-assignmentsModal__search__item']}>
              <p className={styles['p-assignmentsModal__search__item__ttl']}>
                {t('Filter by pickup location')}
              </p>
              <div className={styles['p-assignmentsModal__search__item__body']}>
                <SingleDropdown
                  options={pickupFilterOptions}
                  selectedOption={pickupLocationFilter}
                  onChange={(value) => setPickupLocationFilter(value)}
                />
              </div>
            </div>
            <div className={styles['p-assignmentsModal__search__item']}>
              <p className={styles['p-assignmentsModal__search__item__ttl']}>
                {t('Filter by dropoff location')}
              </p>
              <div className={styles['p-assignmentsModal__search__item__body']}>
                <SingleDropdown
                  options={dropoffFilterOptions}
                  selectedOption={dropoffLocationFilter}
                  onChange={(value) => setDropoffLocationFilter(value)}
                />
              </div>
            </div>
            {resourceType !== 'crew' && resourceType !== 'guide' && (
              <div className={styles['p-assignmentsModal__search__item']}>
                <p className={styles['p-assignmentsModal__search__item__ttl']}>
                  {t('Capacity')}
                </p>
                <div
                  className={styles['p-assignmentsModal__search__item__body']}
                >
                  <ul>
                    {capacityDescriptions.map((capacityDescription, idx) => (
                      <li key={idx}>{capacityDescription}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={dailyStyles['p-manifestsModalAssignments']}>
          {reservationsByStartTime.map(({ startTime, reservations }, idx) => (
            <div
              key={idx}
              className={dailyStyles['p-manifestsModalAssignments']}
            >
              <div className={dailyStyles['p-manifests__normal__header']}>
                <div>
                  <p>
                    {t('Start time: {{startTime}}', {
                      startTime,
                    })}
                  </p>
                </div>
              </div>
              <div className={dailyStyles['p-manifestsTable2']}>
                <ResourceAssignmentReservationsTable
                  excludedFormFieldKeys={excludedFormFieldKeys}
                  reservations={filteredReservations(
                    reservations,
                    pickupLocationFilter,
                    dropoffLocationFilter
                  )}
                  visibleColumns={visibleColumns ?? resourceViewVisibleColumns}
                  products={products}
                  editingResourceType={resourceType}
                  editingResourceKey={resourceKey}
                  updateRequests={updateRequests}
                  onUpdateRequestsChanged={(newUpdateRequests) =>
                    setUpdateRequests(newUpdateRequests)
                  }
                  customizedColumnNames={customizedColumnNames || []}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    </Modal>
  );
};
