import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { calculateRebookFromReservationFares } from 'client/actions/fares';
import { currency } from 'shared/libraries/currency';
import { MoneyInput } from 'client/components/v3/MoneyInput/MoneyInput';
import { ReduxState } from 'client/reducers';
import type { NewReservation, Reservation } from 'shared/models/swagger';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  rebookFromReservation: Reservation;
  amountToPayOnBoard?: string;
  onChangeAmountToPayOnBoard?: (arg0: string) => void;
};
export const PaymentForChangeReservation = ({
  rebookFromReservation,
  amountToPayOnBoard,
  onChangeAmountToPayOnBoard,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );
  const rebookFromReservationBillingInfo = useSelector(
    (state: ReduxState) => state.fares.rebookFromReservationBillingInfo
  );

  useEffect(() => {
    if (!rebookFromReservation?.billing_info?.amount_gross) {
      dispatch(
        calculateRebookFromReservationFares({
          ...(rebookFromReservation as any as NewReservation),
          agent_reference: 'dummy-reference-payment',
        })
      );
    }
  }, [rebookFromReservation]);

  if (
    !billingInfo ||
    !(
      rebookFromReservation.billing_info?.amount_gross ||
      rebookFromReservationBillingInfo
    )
  ) {
    return null;
  }

  const currencyCode = (billingInfo.amount_gross || '').substring(0, 3);
  const amountGrossOfNewReservation = billingInfo?.amount_gross;
  const amountPaidOfRebookedFromReservation =
    rebookFromReservation?.billing_info?.amount_pay_in_advance ||
    currencyCode + '0';

  return (
    <table className={tableStyles['c-tableSmall']}>
      <tbody>
        <tr>
          <th>{t('Paid in advance')}</th>
          <td>{amountPaidOfRebookedFromReservation}</td>
        </tr>
        <tr>
          <th>{t('Paid on board')}</th>
          <td>
            <MoneyInput
              currencyCode={currencyCode}
              moneyAmount={currencyCode + amountToPayOnBoard || ''}
              onChange={(value) => {
                if (onChangeAmountToPayOnBoard) {
                  onChangeAmountToPayOnBoard(value.substring(3));
                }
              }}
            />
          </td>
        </tr>
        <tr>
          <th>{t('Additional payment')}</th>
          <td>
            {currency(amountGrossOfNewReservation ?? '')
              .subtract(amountPaidOfRebookedFromReservation)
              .subtract(currencyCode + amountToPayOnBoard)
              .format()}
          </td>
        </tr>
        <tr>
          <th>{t('Total')}</th>
          <td>{currency(amountGrossOfNewReservation ?? '').format()}</td>
        </tr>
      </tbody>
    </table>
  );
};
