import { ThunkDispatch } from 'redux-thunk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { setReservationVisibleColumns } from 'client/actions/reservationTableControls';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import {
  reservationColumnCandidatesSelector,
  reservationVisibleColumnsSelector,
} from 'client/reducers/reservationTableControls';
import { Modal } from 'client/components/Modal/Modal';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  trigger: React.ReactElement<any>;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
const ReservationSearchTableSettingsModalComponent = ({
  columnCandidates,
  onVisibleColumnsChange,
  trigger,
  visibleColumns,
}: Props) => {
  const { t } = useTranslation();
  const allColumns = getReservationTableColumns(t, 'en');

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c) as any);
  };

  const columnsToShow = getColumns(visibleColumns);
  const allCandidateColumns = getColumns(columnCandidates);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Show columns')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={columnsToShow.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                candidateItems={allCandidateColumns.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                setSelectedItems={(items) =>
                  onVisibleColumnsChange(items.map((i) => i.key))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  columnCandidates: reservationColumnCandidatesSelector(state),
  visibleColumns: reservationVisibleColumnsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onVisibleColumnsChange: (visibleColumns: string[]) =>
    dispatch(setReservationVisibleColumns(visibleColumns)),
});

export const ReservationSearchTableSettingsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationSearchTableSettingsModalComponent);
