import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Button } from 'client/components/Form';
import { fetchRedeemedRecords } from 'client/actions/redeemedRecords';
import { ReduxState } from 'client/reducers';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import {
  undoRedeemReservationCoupon,
  undoRedeemReservationStampRally,
  undoRedeemReservationStampRallyGift,
} from 'client/actions/reservations';
import { getRedeemedRecordTypeText } from 'client/libraries/util/getRedeemedRecordTypeText';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ReservationCouponTab.module.css';

type Props = {
  reservation?: Reservation;
};

export const ReservationCouponTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUser = useSelector(activeUserSelector);

  const redeemedRecords = useSelector(
    (state: ReduxState) => state.redeemedRecords.all
  );

  const [undoRedemptionId, setUndoRedemptionId] = React.useState<string | null>(
    null
  );

  React.useEffect(() => {
    if (!undoRedemptionId) {
      dispatch(
        fetchRedeemedRecords({
          reservation_id: reservation?.id ?? '',
        })
      );
    }
  }, [undoRedemptionId]);

  const redeemedRecordShapes = React.useMemo(() => {
    return redeemedRecords
      .map((record) => {
        return {
          id: record.id,
          dateTime: record?.date_time_utc
            ? moment.tz(
                record.date_time_utc,
                reservation?.start_timezone ?? 'UTC'
              )
            : null,
          type: getRedeemedRecordTypeText(record.type ?? '', t),
          pageTitle: record.page_title,
          title: record.title,
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  }, [redeemedRecords, reservation]);

  const undo = async (id: string) => {
    try {
      setUndoRedemptionId(id);

      const record = redeemedRecords.find((record) => record.id === id);

      if (record?.type === 'COUPON') {
        await dispatch(
          undoRedeemReservationCoupon(reservation?.id ?? '', { id })
        );
      } else if (record?.type === 'STAMP_RALLY') {
        await dispatch(
          undoRedeemReservationStampRally(reservation?.id ?? '', { id })
        );
      } else if (record?.type === 'STAMP_RALLY_GIFT') {
        await dispatch(
          undoRedeemReservationStampRallyGift(reservation?.id ?? '', { id })
        );
      }
    } finally {
      setUndoRedemptionId(null);
    }
  };
  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Redeemed Records')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(styles['c-table-nowrap'])}>
          <table>
            <tbody>
              <tr>
                {hasCustomUserRoleWritePermissions(
                  activeUser,
                  'RESERVATION.LIST'
                ) && <th className={clsx(baseStyles['base-t-192'])}></th>}
                <th
                  className={clsx(
                    baseStyles['base-t-192'],
                    styles['center-align']
                  )}
                >
                  {t('Date/Time')}
                </th>
                <th
                  className={clsx(
                    baseStyles['base-t-192'],
                    styles['center-align']
                  )}
                >
                  {t('Type')}
                </th>
                <th>{t('Page title')}</th>
                <th>{t('Title')}</th>
              </tr>
              {redeemedRecordShapes.map((record, idx) => {
                return (
                  <tr key={idx}>
                    {hasCustomUserRoleWritePermissions(
                      activeUser,
                      'RESERVATION.LIST'
                    ) && (
                      <td className={clsx(styles['center-align'])}>
                        <Button
                          size="middle"
                          style="gray"
                          onClick={() => {
                            undo(record.id ?? '');
                          }}
                          loading={undoRedemptionId === record.id}
                        >
                          {t('Undo')}
                        </Button>
                      </td>
                    )}
                    <td className={clsx(styles['center-align'])}>
                      {record.dateTime?.format('lll') ?? ''}
                    </td>
                    <td
                      className={clsx(
                        baseStyles['base-t-192'],
                        styles['center-align']
                      )}
                    >
                      {record.type}
                    </td>
                    <td>{record.pageTitle}</td>
                    <td>{record.title}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
