import * as React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TranslateFuncType } from 'client/components/Translate';
import { Product } from 'shared/models/swagger';
import { EditingProductContext } from 'client/contexts/EditingProductContext';

import styles from './AdjustmentScheduleTable.module.css';
import {
  AvailabilityAdjustmentSchedule,
  AvailabilityAdjustmentCondition,
  AvailabilityAdjustmentAction,
  Channel,
} from './formValues';
import { getDeadlineDescription } from './util';

const getConditionText = (
  condition: AvailabilityAdjustmentCondition,
  product: Product | null,
  t: TranslateFuncType
): string => {
  const { valueType, operator, value, appliesTo, channel } = condition;

  const occupancyCategory =
    appliesTo === 'TOTAL'
      ? t('Total occupancy')
      : channel?.channelCategory === 'AGENT'
      ? t('{{occupancyType}} occupancy', {
          occupancyType: product?.agents?.find(
            (agent) => agent.id === channel?.agentId
          )?.name,
        })
      : channel?.channelCategory === 'COMMON'
      ? t('Common')
      : t('Direct');
  const occupancyValue = valueType === 'OCCUPANCY_COUNT' ? value : `${value}%`;
  const comparator =
    operator === 'LTE' ? '<=' : operator === 'GTE' ? '>=' : '=';

  return `${occupancyCategory} ${comparator} ${occupancyValue}`;
};

const getChannelText = (
  channel: Channel,
  product: Product | null,
  t: TranslateFuncType
) => {
  if (channel.channelCategory === 'AGENT') {
    return product?.agents?.find((agent) => agent.id === channel.agentId)?.name;
  }

  if (channel.channelCategory === 'DIRECT_ALL') {
    return t('Direct');
  }

  if (channel.channelCategory === 'COMMON') {
    return t('Common');
  }

  return t('<unknown>');
};

const getActionText = (
  action: AvailabilityAdjustmentAction,
  product: Product | null,
  t: TranslateFuncType
): string => {
  if (action.actionType === 'TRANSFER_REMAINING_SLOTS') {
    const fromChannel = action.fromChannel;
    const toChannel = action.toChannel;

    if (!fromChannel || !toChannel) {
      return t('Transfer remaining slots');
    }

    return t('Transfer remaining slots from {{fromChannel}} to {{toChannel}}', {
      fromChannel: getChannelText(fromChannel, product, t),
      toChannel: getChannelText(toChannel, product, t),
    });
  }

  const channels =
    action.channels
      ?.map((channel) => getChannelText(channel, product, t))
      .join(', ') ?? '';

  let actionText = '';
  switch (action.actionType) {
    case 'CLOSE_CHANNEL':
      actionText = t('Close channel', { channels });
      break;
    case 'INCREASE_TOTAL':
      actionText = t('Increase allotment total by {{amount}}', {
        amount: action.amount,
      });
      break;
    case 'DECREASE_TOTAL':
      actionText = t('Decrease allotment total by {{amount}}', {
        amount: action.amount,
      });
      break;
    case 'SET_TOTAL':
      actionText = t('Set allotment total to {{amount}}', {
        amount: action.amount,
      });
      break;
    case 'REMOVE_REMAINING_SLOTS':
      actionText = t('Remove all remaining slots');
      break;
  }

  return `${actionText}: ${channels}`;
};

interface Props {
  activeScheduleIndex: number;
  setActiveScheduleIndex: (index: number) => void;
}

export const AdjustmentScheduleTable = ({
  activeScheduleIndex,
  setActiveScheduleIndex,
}: Props) => {
  const product = React.useContext(EditingProductContext);
  const { schedules } = useForm().getState().values;
  const { t } = useTranslation();

  if ((schedules ?? [])?.length === 0) {
    return null;
  }

  return (
    <table className={styles['table']}>
      <thead>
        <tr>
          <th>{t('Participation Date Range')}</th>
          <th>{t('Active Period')}</th>
          <th>{t('Condition')}</th>
          <th>{t('Action')}</th>
        </tr>
      </thead>
      <tbody>
        {schedules.map(
          (schedule: AvailabilityAdjustmentSchedule, index: number) => (
            <tr
              key={index}
              className={clsx(
                styles['table__row'],
                activeScheduleIndex === index && styles['table__row--active']
              )}
              onClick={() => setActiveScheduleIndex(index)}
            >
              <td>{`${schedule.startDate} ~ ${schedule.endDate}`}</td>
              <td>{`${
                schedule.appliesFromParticipationRelativeDate
                  ? getDeadlineDescription(
                      schedule.appliesFromParticipationRelativeDate,
                      t
                    )
                  : ''
              } ~ ${
                schedule.appliesUntilParticipationRelativeDate
                  ? getDeadlineDescription(
                      schedule.appliesUntilParticipationRelativeDate,
                      t
                    )
                  : ''
              }`}</td>
              <td>
                {(schedule.rules ?? []).length > 0 &&
                (schedule.rules?.[0].conditions ?? [])?.length > 0
                  ? getConditionText(
                      schedule.rules[0].conditions[0],
                      product,
                      t
                    )
                  : ''}
              </td>
              <td>
                {(schedule.rules ?? []).length > 0 &&
                (schedule.rules?.[0].actions ?? [])?.length > 0
                  ? getActionText(schedule.rules[0].actions[0], product, t)
                  : ''}
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};
