import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/v3-base.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { getArrayMutators } from 'client/libraries/util/form';
import { ManifestExportParams } from 'shared/models/swagger';
import { sendManifestEmail } from 'client/actions/manifests';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';

type Props = {
  defaultSubject: string;
  open?: boolean;
  onClose: () => void;
  manifestExportParams?: ManifestExportParams;
};

type FormValues = {
  subject: string;
  toAddresses: string[];
};

export const SendEmailModal = ({
  defaultSubject,
  open,
  onClose,
  manifestExportParams,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailError = useSelector(
    (state: ReduxState) => state.manifests.emailError
  );
  const emailStatus = useSelector(
    (state: ReduxState) => state.manifests.emailStatus
  );

  const [status, setStatus] = useState<
    'IDLE' | 'IN_FLIGHT' | 'SUCCESS' | 'FAILURE' | null
  >('IDLE');
  const [showModal, setShowModal] = useState<boolean>(false);

  // Use useMemo to prevent changes when initial value is inputted manually
  const initialValues = useMemo((): FormValues => {
    return {
      subject: defaultSubject ?? '',
      toAddresses: [],
    };
  }, []);

  useEffect(() => {
    setStatus(emailStatus as any);
  }, [emailStatus]);

  const { required } = getValidators(t);
  const validateEmail = useCallback(
    (email?: any) => {
      if (!email) return t('Required');

      if (!matchesFormat(email, 'email')) return t('Invalid Email');

      return undefined;
    },
    [t]
  );

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        await dispatch(
          sendManifestEmail({
            ...manifestExportParams,
            subject: values.subject,
            to_addresses: values.toAddresses,
          })
        );
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, valid, values }) => (
        <form onSubmit={handleSubmit} id="sendEmailModalForm">
          <Modal
            title={t('Send Manifest Emails')}
            open={open || showModal}
            onClose={() => {
              onClose();
              setShowModal(false);
              setStatus(null);
            }}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  size="md"
                  color="white"
                  onClick={() => {
                    onClose();
                    setStatus(null);
                  }}
                />
                <Button
                  text={t('Send')}
                  onClick={() => {
                    setStatus('IN_FLIGHT');
                    // For some reason setting loading will not fire submission using form="" property
                    // So we dispatch submit event programatically
                    const form =
                      window.document.getElementById('sendEmailModalForm');
                    if (form) {
                      form.dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                      );
                    }
                  }}
                  type="submit"
                  disabled={
                    !valid ||
                    values.toAddresses.length === 0 ||
                    status === 'IN_FLIGHT'
                  }
                  loading={Boolean(status && status === 'IN_FLIGHT')}
                />
              </>
            }
          >
            <>
              <div className={baseStyles['u-mb-4']}>
                <p style={{ marginBottom: '12px' }}>{t('Subject')}</p>
                <Field name="subject" validate={required}>
                  {({ input, meta: { error } }) => (
                    <TextField
                      value={input.value}
                      onChange={(value) => input.onChange(value)}
                      error={error}
                      helperText={error}
                    />
                  )}
                </Field>
              </div>
              <div className={baseStyles['u-mb-4']}>
                <p style={{ marginBottom: '12px' }}>{t('Email addresses')}</p>
                <ListEditor
                  name="toAddresses"
                  addButtonText={t('Add')}
                  validator={validateEmail}
                />
              </div>
              {status && status === 'SUCCESS' && (
                <Snackbar
                  text={t('Email sent successfully')}
                  color="success"
                  shouldShow={status === 'SUCCESS'}
                />
              )}
              {status && status === 'FAILURE' && (
                <Snackbar
                  text={t('Email failed to send')}
                  color="error"
                  shouldShow={status === 'FAILURE'}
                  content={emailError}
                />
              )}
            </>
          </Modal>
        </form>
      )}
    </Form>
  );
};
