import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { currency } from 'shared/libraries/currency';
import { histogram } from 'client/libraries/util/util';
import { formattedAmount } from 'client/libraries/util/formattedAmount';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type {
  Guest,
  ManifestProductGroup,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ManifestSummary.module.css';

type Props = {
  reservations: ManifestReservationShape[];
  supplierCurrency: string;
};
type StartTimeLine = {
  reservationCount: number;
  pax: number;
  guests: Guest[];
  amountGross: number;
  amountPOB: number;
  amountPIF: number;
};
type ProductLine = {
  productId: string;
  productName: string;
  startTimeLines: Record<string, StartTimeLine>;
  reservationId: string;
};
export const ManifestSummary = ({ reservations, supplierCurrency }: Props) => {
  const { t } = useTranslation();
  const [isActives, setIsActives] = React.useState<Record<string, boolean>>({});
  const productGroups = useSelector(manifestProductGroupsSelector);
  const summaries = useSelector(summariesSortedByBookmarkedSelector);
  const productGroupsForDisplay = productGroups.filter((productGroup) => {
    return productGroup.used_for_grouping;
  });
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const rootResourceProductIds = productGroupsForDisplay.find(
    (group: ManifestProductGroup) =>
      group.key == lastManifestDisplaySettings.rootResource.id
  )?.product_ids;

  const productLines: ProductLine[] = [];

  for (let i = 0; i < reservations.length; i++) {
    const amountGross = reservations[i].amount_gross;

    if (
      amountGross &&
      amountGross.length > 3 &&
      !amountGross.startsWith(supplierCurrency)
    ) {
      continue;
    }

    let productLine = productLines.find(
      (productLine) =>
        productLine.productId === reservations[i].product_id &&
        !reservations[i].is_free_format_reservation
    );

    if (!productLine) {
      productLine = {
        productId: reservations[i].product_id,
        productName:
          summaries.find((p) => p.id == reservations[i].product_id)
            ?.internal_product_name ?? reservations[i].product_name,
        startTimeLines: {},
        reservationId: reservations[i].id,
      };
      productLines.push(productLine);
    }

    const startTime = reservations[i].participates_at.format('HH:mm');
    let startTimeLine = productLine.startTimeLines[startTime];

    if (!startTimeLine) {
      startTimeLine = {
        reservationCount: 0,
        pax: 0,
        guests: [],
        amountGross: 0,
        amountPOB: 0,
        amountPIF: 0,
      };
      productLine.startTimeLines[startTime] = startTimeLine;
    }

    const grossValue = currency(reservations[i].amount_gross).value;
    const pobValue = currency(reservations[i].amount_pob).value;
    const pifValue = grossValue - pobValue;
    startTimeLine.reservationCount += 1;
    startTimeLine.pax += reservations[i].guests.length;
    startTimeLine.guests = [...startTimeLine.guests, ...reservations[i].guests];
    startTimeLine.amountGross += grossValue;
    startTimeLine.amountPOB += pobValue;
    startTimeLine.amountPIF += pifValue;
  }

  const getUnits = (guests: Guest[]): string => {
    const reservationGuestCounts = histogram(
      guests,
      (g) => g.guest_type_title || g.guest_type_key
    );
    return Object.keys(reservationGuestCounts)
      .map(
        (guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`
      )
      .join(', ');
  };

  const sortSummaries = rootResourceProductIds
    ? rootResourceProductIds.map((p) => summaries.find((elm) => elm.id == p))
    : summaries;
  const sortedProductLines = productLines.sort((a, b) => {
    const tmp_a = sortSummaries.find((p) => p?.id == a.productId);
    const tmp_b = sortSummaries.find((p) => p?.id == b.productId);

    if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) <
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return -1;
    } else if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) >
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return 1;
    }

    return 0;
  });
  let totalReservationCount = 0;
  let totalPax = 0;
  let totalGuests: Guest[] = [];
  let totalAmountGross = 0;
  let totalAmountPOB = 0;
  let totalAmountPIF = 0;
  return (
    <div className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}>
      <table>
        <tbody>
          <tr>
            <th className={clsx(baseStyles['base-t-368'])}>{t('Product')}</th>
            <th className={clsx(baseStyles['base-t-112'])}>
              {t('Reservations')}
            </th>
            <th className={clsx(baseStyles['base-t-112'])}>{t('Total Pax')}</th>
            <th className={clsx(baseStyles['base-t-208'])}>{t('Units')}</th>
            <th className={clsx(baseStyles['base-t-112'])}>{t('Gross')}</th>
            <th className={clsx(baseStyles['base-t-112'])}>
              {t('PIF Amount')}
            </th>
            <th className={clsx(baseStyles['base-t-112'])}>
              {t('POB Amount')}
            </th>
          </tr>
          {sortedProductLines.map((productLine, idx) => {
            let reservationCount = 0;
            let pax = 0;
            let guests: Guest[] = [];
            let amountGross = 0;
            let amountPOB = 0;
            let amountPIF = 0;

            const keys = _.sortBy(
              Object.keys(productLine.startTimeLines),
              (key) => key
            );

            for (let i = 0; i < keys.length; i++) {
              const startTimeLine = productLine.startTimeLines[keys[i]];
              reservationCount += startTimeLine.reservationCount;
              pax += startTimeLine.pax;
              guests = [...guests, ...startTimeLine.guests];
              amountGross += startTimeLine.amountGross;
              amountPOB += startTimeLine.amountPOB;
              amountPIF += startTimeLine.amountPIF;
            }

            totalReservationCount += reservationCount;
            totalPax += pax;
            totalGuests = [...totalGuests, ...guests];
            totalAmountGross += amountGross;
            totalAmountPOB += amountPOB;
            totalAmountPIF += amountPIF;

            const lineKey = productLine.productId
              ? productLine.productId
              : productLine.reservationId;
            return (
              <>
                <tr
                  key={idx}
                  className={clsx(
                    isActives[lineKey] ? styles['is-active'] : ''
                  )}
                >
                  <td className={styles['parent-line']}>
                    <div className={baseStyles['inline-block']}>
                      <div
                        className={styles['ic']}
                        onClick={() => {
                          const newIsActives = { ...isActives };
                          newIsActives[lineKey] = !newIsActives[lineKey];
                          setIsActives(newIsActives);
                        }}
                      >
                        <span></span>
                      </div>
                      {productLine.productName}
                    </div>
                  </td>
                  <td className={clsx(styles['right-align'])}>
                    {reservationCount}
                  </td>
                  <td className={clsx(styles['right-align'])}>{pax}</td>
                  <td>{getUnits(guests)}</td>
                  <td className={clsx(styles['right-align'])}>
                    {formattedAmount(amountGross, supplierCurrency)}
                  </td>
                  <td className={clsx(styles['right-align'])}>
                    {formattedAmount(amountPIF, supplierCurrency)}
                  </td>
                  <td className={clsx(styles['right-align'])}>
                    {formattedAmount(amountPOB, supplierCurrency)}
                  </td>
                </tr>
                {isActives[lineKey] &&
                  keys.map((key, idx) => {
                    const startTimeLine = productLine.startTimeLines[key];
                    return (
                      <tr key={idx} className={clsx(styles['child-line'])}>
                        <td
                          className={clsx(
                            styles['left-align'],
                            styles['left-padding-50']
                          )}
                        >
                          {key}
                        </td>
                        <td className={clsx(styles['right-align'])}>
                          {startTimeLine.reservationCount}
                        </td>
                        <td className={clsx(styles['right-align'])}>
                          {startTimeLine.pax}
                        </td>
                        <td>{getUnits(startTimeLine.guests)}</td>
                        <td className={clsx(styles['right-align'])}>
                          {formattedAmount(
                            startTimeLine.amountGross,
                            supplierCurrency
                          )}
                        </td>
                        <td className={clsx(styles['right-align'])}>
                          {formattedAmount(
                            startTimeLine.amountPIF,
                            supplierCurrency
                          )}
                        </td>
                        <td className={clsx(styles['right-align'])}>
                          {formattedAmount(
                            startTimeLine.amountPOB,
                            supplierCurrency
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </>
            );
          })}
          <tr>
            <td className={clsx(styles['right-align'])}>{t('Total')}</td>
            <td className={clsx(styles['right-align'])}>
              {totalReservationCount}
            </td>
            <td className={clsx(styles['right-align'])}>{totalPax}</td>
            <td>{getUnits(totalGuests)}</td>
            <td className={clsx(styles['right-align'])}>
              {formattedAmount(totalAmountGross, supplierCurrency)}
            </td>
            <td className={clsx(styles['right-align'])}>
              {formattedAmount(totalAmountPIF, supplierCurrency)}
            </td>
            <td className={clsx(styles['right-align'])}>
              {formattedAmount(totalAmountPOB, supplierCurrency)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={clsx(styles['footer-note'])}>
        {t('Currency Code') + ' : ' + supplierCurrency}
      </div>
    </div>
  );
};
