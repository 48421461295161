import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Checkbox, FieldWrapper, Input, Select } from 'client/components/Form';

import styles from './WaiverTemplateList.module.css';

type Props = {
  name: string;
};
export const FieldEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const inputTypeOptions = [
    {
      value: 'SHORT_TEXT_INPUT',
      label: t('Text input'),
    },
    {
      value: 'TEXT_AREA',
      label: t('Text Area'),
    },
    {
      value: 'CHECKBOX',
      label: t('Checkbox'),
    },
  ];
  const formatOptions = [
    {
      value: 'alpha-name',
      text: t('Alphabet characters or spaces'),
    },
    {
      value: 'kana-name',
      text: t('Kana characters or full-width spaces'),
    },
    {
      value: 'email',
      text: t('Email'),
    },
    {
      value: 'phone',
      text: t('Phone Number'),
    },
    {
      value: 'international_phone',
      text: t('International Phone Number'),
    },
    {
      value: 'float',
      text: t('Numeric'),
    },
    {
      value: 'non-negative-integer',
      text: t('Positive Numeric'),
    },
    {
      value: 'yyyy-mm-dd',
      text: t('YYYY-MM-DD'),
    },
  ];
  return (
    <div>
      <ul className={styles['scheds']}>
        <li className={styles['scheds__cell']}>
          <TranslatedField name={`${name}.prompt`}>
            {({ input, translationInput }) => (
              <>
                <div>
                  <p className={styles['scheds__body']}>
                    <Input {...input} label={t('Question')} />
                  </p>
                </div>
                {translationTargetLanguage && (
                  <div>
                    <p className={styles['scheds__body']}>
                      <Input
                        {...translationInput}
                        label={t('Question ({{language}})', {
                          language: translationTargetLanguageName,
                        })}
                      />
                    </p>
                  </div>
                )}
              </>
            )}
          </TranslatedField>
        </li>
      </ul>
      <Field name={`${name}.required`} type="checkbox">
        {({ input }) => <Checkbox label={t('Required')} {...(input as any)} />}
      </Field>
      <FieldWrapper label={t('Input type')}>
        <EnumRadioButtonGroup
          name={`${name}.inputType`}
          options={inputTypeOptions}
          onChange={() => {
            form.change(`${name}.format`, '');
          }}
        />
      </FieldWrapper>
      <Field name={name}>
        {({ input: { value: fieldValue } }) =>
          fieldValue.inputType === 'CHECKBOX' ||
          fieldValue.inputType === 'TEXT_AREA' ? null : (
            <>
              <FieldWrapper label={t('Response Constraint')}>
                <EnumRadioButtonGroup
                  name={`${name}.responseConstraint`}
                  options={[
                    {
                      value: 'FORMAT',
                      label: t('Text Format'),
                      tooltipText: t(
                        'Input required to be in specified format'
                      ),
                    },
                    {
                      value: 'NO_RESTRICTION',
                      label: t('None'),
                      tooltipText: t('Allow free text entry'),
                    },
                  ]}
                  onChange={(newValue) => {
                    switch (newValue) {
                      case 'FORMAT':
                        form.change(`${name}.format`, 'alpha-name');
                        break;

                      default:
                        form.change(`${name}.format`, '');
                        break;
                    }
                  }}
                />
              </FieldWrapper>
              {fieldValue.responseConstraint === 'FORMAT' && (
                <Field name={`${name}.format`}>
                  {({ input }) => (
                    <Select
                      maxWidth={320}
                      options={formatOptions}
                      value={input.value}
                      onChange={(e, { value: newValue }) => {
                        input.onChange(newValue);
                      }}
                    />
                  )}
                </Field>
              )}
            </>
          )
        }
      </Field>
    </div>
  );
};
