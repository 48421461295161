import * as React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { Button, Select } from 'client/components/Form';
import { ProductSummaryCardView } from 'client/pages/Home/Tutorial/SiteTemplateTutorial/ProductSummaryCardView';
import { SiteTemplateSampleView } from 'client/pages/Home/Tutorial/SiteTemplateTutorial/SiteTemplateSampleView';
import {
  copySiteTemplate,
  fetchTemplateSuppliersSettings,
} from 'client/actions/tutorial';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchOrganizations } from 'client/actions/organizations';
import type {
  CopySiteTemplateRequest,
  TemplateSupplierSettings,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/Home/Tutorial/SiteTemplateTutorial/SiteTemplateTutorial.module.css';

type TemplateType = CopySiteTemplateRequest['params']['site_template_type'];

export const SiteTemplateTutorial = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const templateSuppliers = useSelector(
    (state: ReduxState) => state.tutorial.templateSuppliersSettings
  );
  const loading = useSelector((state: ReduxState) => state.tutorial.loading);

  const [showSampleModal, setShowSampleModal] = React.useState<boolean>(false);
  const [sampleSiteType, setSampleSiteType] =
    React.useState<TemplateSupplierSettings>({});
  const [siteTemplateType, setSiteTemplateType] =
    React.useState<TemplateType>('MARIN');

  React.useEffect(() => {
    dispatch(fetchTemplateSuppliersSettings());
  }, [i18n.language]);

  React.useEffect(() => {
    if (
      activeUserOrganization?.tutorial_stage === 'COPY_SITE_TEMPLATE_COMPLETED'
    ) {
      history.push('/home');
    }
  }, [activeUserOrganization]);
  React.useEffect(() => {
    dispatch(fetchOrganizations());
  }, [loading]);

  const siteTemplateOption = [
    {
      text: t('Diving, Marine sports'),
      key: 'MARIN',
      value: 'MARIN',
    },
    {
      text: t('Tours'),
      key: 'TOUR',
      value: 'TOUR',
    },
    {
      text: t('Activities'),
      key: 'ACTIVITY',
      value: 'ACTIVITY',
    },
    {
      text: t('Theme parks, tourist spots, etc.'),
      key: 'THEME_PARK',
      value: 'THEME_PARK',
    },
  ];

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__header']}>
        <div className={baseStyles['base-main__body__box__header__ttl']}>
          {t('Create Website')}
        </div>
      </div>
      <div className={baseStyles['base-main__body__box__body']}>
        <p className={styles['page-tutorial__ttl']}>
          {t(
            'Select a sample site that is close to your products and create your website.'
          )}
        </p>
        <div className={styles['page-tutorial__samplesite']}>
          <div className={styles['page-tutorial__samplesite__mobile']}>
            <div className={styles['page-tutorial__samplesite__block']}>
              <Box display={'block'}>
                <div style={{ height: '70px' }}>
                  <p style={{ textAlign: 'start' }}>
                    {t('Diving, Marine sports')}
                  </p>
                  <p style={{ fontWeight: 'normal', textAlign: 'start' }}>
                    {t('Website for marine sports, snorkeling and diving')}
                  </p>
                </div>
                <ProductSummaryCardView
                  template={templateSuppliers['MARIN'] ?? {}}
                  onClick={() => {
                    setShowSampleModal(true);
                    setSampleSiteType(templateSuppliers['MARIN']);
                    setSiteTemplateType('MARIN');
                  }}
                />
                <div style={{ margin: '8px' }}>
                  <button
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['blue'],
                      baseStyles['middle']
                    )}
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      setShowSampleModal(true);
                      setSampleSiteType(templateSuppliers['MARIN']);
                      setSiteTemplateType('MARIN');
                    }}
                  >
                    {t('View Sample Website')}
                  </button>
                </div>
              </Box>
            </div>
            <div className={styles['page-tutorial__samplesite__block']}>
              <Box display={'block'}>
                <div style={{ height: '70px' }}>
                  <p style={{ textAlign: 'start' }}>{t('Tours')}</p>
                  <p style={{ fontWeight: 'normal', textAlign: 'start' }}>
                    {t('Websites for sightseeing bus tours, tourist boats')}
                  </p>
                </div>
                <ProductSummaryCardView
                  template={templateSuppliers['TOUR'] ?? {}}
                  onClick={() => {
                    setShowSampleModal(true);
                    setSampleSiteType(templateSuppliers['TOUR']);
                    setSiteTemplateType('TOUR');
                  }}
                />
                <div style={{ margin: '8px' }}>
                  <button
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['blue'],
                      baseStyles['middle']
                    )}
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      setShowSampleModal(true);
                      setSampleSiteType(templateSuppliers['TOUR']);
                      setSiteTemplateType('TOUR');
                    }}
                  >
                    {t('View Sample Website')}
                  </button>
                </div>
              </Box>
            </div>
            <div className={styles['page-tutorial__samplesite__block']}>
              <Box display={'block'}>
                <div style={{ height: '70px' }}>
                  <p style={{ textAlign: 'start' }}>{t('Activities')}</p>
                  <p style={{ fontWeight: 'normal', textAlign: 'start' }}>
                    {t('Websites for activities like SUP, kayak, horse riding')}
                  </p>
                </div>
                <ProductSummaryCardView
                  template={templateSuppliers['ACTIVITY'] ?? {}}
                  onClick={() => {
                    setShowSampleModal(true);
                    setSampleSiteType(templateSuppliers['ACTIVITY']);
                    setSiteTemplateType('ACTIVITY');
                  }}
                />
                <div style={{ margin: '8px' }}>
                  <button
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['blue'],
                      baseStyles['middle']
                    )}
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      setShowSampleModal(true);
                      setSampleSiteType(templateSuppliers['ACTIVITY']);
                      setSiteTemplateType('ACTIVITY');
                    }}
                  >
                    {t('View Sample Website')}
                  </button>
                </div>
              </Box>
            </div>
            <div className={styles['page-tutorial__samplesite__block']}>
              <Box display={'block'}>
                <div style={{ height: '70px' }}>
                  <p style={{ textAlign: 'start' }}>
                    {t('Theme parks, tourist spots, etc.')}
                  </p>
                  <p style={{ fontWeight: 'normal', textAlign: 'start' }}>
                    {t(
                      'Websites to sell admission ticket for theme parks and other facilities.'
                    )}
                  </p>
                </div>
                <ProductSummaryCardView
                  template={templateSuppliers['THEME_PARK'] ?? {}}
                  onClick={() => {
                    setShowSampleModal(true);
                    setSampleSiteType(templateSuppliers['THEME_PARK']);
                    setSiteTemplateType('THEME_PARK');
                  }}
                />
                <div style={{ margin: '8px' }}>
                  <button
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['blue'],
                      baseStyles['middle']
                    )}
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      setShowSampleModal(true);
                      setSampleSiteType(templateSuppliers['THEME_PARK']);
                      setSiteTemplateType('THEME_PARK');
                    }}
                  >
                    {t('View Sample Website')}
                  </button>
                </div>
              </Box>
            </div>
          </div>
        </div>
        <Select
          label={t('Select sample site')}
          placeholder={t('Site Template')}
          value={siteTemplateType}
          options={siteTemplateOption}
          onChange={(e, { value }) => {
            setSiteTemplateType(value as TemplateType);
          }}
        />
        <span>
          {t(
            '* You can change the design and color of the website at any time.'
          )}
        </span>
        <div style={{ marginTop: '17px' }}>
          <Button
            type="submit"
            style="blue"
            size="middle"
            loading={loading}
            onClick={async () => {
              try {
                await dispatch(
                  copySiteTemplate({
                    site_template_type: siteTemplateType,
                  })
                );
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {t('Create website')}
          </Button>
        </div>
      </div>
      {showSampleModal && (
        <SiteTemplateSampleView
          template={sampleSiteType}
          lang={i18n.language}
          setShowModal={setShowSampleModal}
          onCreate={async () => {
            try {
              await dispatch(
                copySiteTemplate({
                  site_template_type: siteTemplateType,
                })
              );
            } catch (error) {
              console.error(error);
            }
          }}
        />
      )}
    </div>
  );
};
