import moment from 'moment-timezone';

import {
  DownloadSurveySubmissionCSVRequest,
  GetSurveySubmissionSummaryRequest,
} from 'shared/models/swagger';

export interface FilterSurveySubmissionsRequest {
  participationDateTo: string;
  participationDateFrom: string;
  submissionDateFrom: string;
  submissionDateTo: string;
}

export const convertSearchConditionToApiRequest = (
  id: string,
  req: FilterSurveySubmissionsRequest
): GetSurveySubmissionSummaryRequest => {
  return {
    survey_template_id: id,
    start_date_local_from: req.participationDateFrom,
    start_date_local_to: req.participationDateTo,
    submission_date_from: req.submissionDateFrom,
    submission_date_to: req.submissionDateTo,
    timezone: moment.tz.guess(),
  };
};

export const convertSearchConditionToCSVDownloadApiRequest = (
  id: string,
  idx: number,
  req: FilterSurveySubmissionsRequest
): DownloadSurveySubmissionCSVRequest => {
  return {
    survey_template_id: id,
    page_index: idx,
    start_date_local_from: req.participationDateFrom,
    start_date_local_to: req.participationDateTo,
    submission_date_from: req.submissionDateFrom,
    submission_date_to: req.submissionDateTo,
    timezone: moment.tz.guess(),
  };
};
