import {
  SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION,
  SET_E_TICKET_FOOTPRINT_ROW_COUNT,
  SET_E_TICKET_FOOTPRINT_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchETicketFootprintsRequest } from 'client/libraries/util/searchETicketFootprints';

export const setLastExecutedETicketFootprintSearchCondition = (
  searchFilters: SearchETicketFootprintsRequest
) => ({
  type: SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION,
  searchFilters,
});

export const setETicketFootprintRowCount = (rowCount: number) => ({
  type: SET_E_TICKET_FOOTPRINT_ROW_COUNT,
  rowCount,
});

export const setETicketFootprintCurrentPage = (currentPage: number) => ({
  type: SET_E_TICKET_FOOTPRINT_CURRENT_PAGE,
  currentPage,
});
