import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';

import styles from '../DetailsStep.module.css';

export const DetailInfoEditor = () => {
  const { t } = useTranslation();
  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );
  return (
    <div className={styles['c-table-list']}>
      <table>
        <tbody>
          {translationTargetLanguage && <TranslationTableHeader />}
          <tr>
            <th>
              {
                t('Experience Content')
                /* 体験内容 */
              }
            </th>
            <td colSpan={2}>
              <ListEditor
                name="inclusions"
                title={t('Inclusions')}
                tooltipText={t(
                  'Describe what is included in the price of the experience. Example: Lunch, guide, facility admission, etc.'
                )}
              />
              <ListEditor
                name="exclusions"
                title={t('Exclusions')}
                tooltipText={t(
                  'Describe what is not included in the price of the experience. Examples: drinks, photography, seat upgrades, etc.'
                )}
              />
            </td>
          </tr>
          <tr>
            <th>
              {
                t('Participation Conditions')
                /* 参加条件 */
              }
            </th>
            <td colSpan={2}>
              <ListEditor
                name="requirements"
                title={t('Requirements')}
                tooltipText={t(
                  'Describe the conditions for participating in the experience. Examples: Participation from 8 years old and over, license holder, etc.'
                )}
              />
              <ListEditor
                name="restrictions"
                title={t('Restrictions')}
                tooltipText={t(
                  'Describe the conditions for not being able to participate in the experience. Examples: Pregnant women, people with heart disease, etc.'
                )}
              />
            </td>
          </tr>
          <tr>
            <th>
              {
                t('Participation Info')
                /* */
              }
            </th>
            <td colSpan={2}>
              <ListEditor
                name="whatToBring"
                title={t('What to Bring')}
                tooltipText={t(
                  'List recommended clothes and things to bring. Examples: Walking shoes, dress code, clothing, etc.'
                )}
              />
              <ListEditor
                name="otherNotes"
                title={t('Other Info')}
                tooltipText={t(
                  'Describe other supplemental information about the experience. Example: Schedule may change due to weather and traffic conditions, etc.'
                )}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
