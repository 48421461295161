import moment from 'moment-timezone';
import { useTranslation, TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers';
import type { InstantWinEvent } from 'shared/models/swagger';
import { ColumnType } from 'client/components/GenericTable/GenericTable';

const getRelatedEntityDetailUrl = (instatnWinEvent: InstantWinEvent) => {
  switch (instatnWinEvent.trigger_type) {
    case 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED':
      return `/reservations/${instatnWinEvent.reservation_id}`;
    case 'INSTANT_WIN_TRIGGER_TYPE_SURVEY_ANSWERED':
      return `/reservations/${instatnWinEvent.reservation_id}`;
    case 'INSTANT_WIN_TRIGGER_TYPE_REVIEW_POSTED':
      return `/reservations/${instatnWinEvent.reservation_id}`;
    case 'INSTANT_WIN_TRIGGER_TYPE_CUSTOMER_REGISTERED':
      return `/customers/${instatnWinEvent.entity_id}`;
    case 'INSTANT_WIN_TRIGGER_TYPE_STAMP_RALLY_COMPLETED':
      return `/reservations/${instatnWinEvent.reservation_id}`;
    default:
      return '';
  }
};

const getTriggerTypeText = (instatnWinEvent: InstantWinEvent, t: TFunction) => {
  switch (instatnWinEvent.trigger_type) {
    case 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED':
      return t('Reservation Created');
    case 'INSTANT_WIN_TRIGGER_TYPE_SURVEY_ANSWERED':
      return t('Survey Answered');
    case 'INSTANT_WIN_TRIGGER_TYPE_REVIEW_POSTED':
      return t('Review Posted');
    case 'INSTANT_WIN_TRIGGER_TYPE_CUSTOMER_REGISTERED':
      return t('Customer Registered');
    case 'INSTANT_WIN_TRIGGER_TYPE_STAMP_RALLY_COMPLETED':
      return t('Stamp Rally Completed');
    default:
      return '';
  }
};

const getStatusText = (instatnWinEvent: InstantWinEvent, t: TFunction) => {
  switch (instatnWinEvent.status) {
    case 'INSTANT_WIN_EVENT_PENDING':
      return t('Pending');
    case 'INSTANT_WIN_EVENT_OPENED':
      return t('Opened');
    case 'INSTANT_WIN_EVENT_DRAWN':
      return t('Drawn');
    case 'INSTANT_WIN_EVENT_EXPIRED':
      return t('Expired');
    default:
      return '';
  }
};

export const useInstantWinEventColumns = (): ColumnType<InstantWinEvent>[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return [
    {
      Header: t('Related Details'),
      width: 60, // 'middle',
      accessor: (row) => (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <a
              href={getRelatedEntityDetailUrl(row)}
              target="_blank"
              rel="noreferrer"
            >
              {t('Detail')}
            </a>
          </Box>
        </Box>
      ),
      id: 'link',
    },
    {
      Header: t('Triggered Date Time'),
      width: 140, // 'middle',
      accessor: (row) =>
        moment(row.triggered_date_time_utc).locale(locale).format('lll'),
      id: 'triggeredDate',
    },
    {
      Header: t('Drawn Date Time'),
      width: 140, // 'middle',
      accessor: (row) =>
        moment(row.drawn_date_time_utc).locale(locale).format('lll'),
      id: 'drawnDate',
    },
    {
      Header: t('Instant Win Title'),
      width: 140, // 'middle',
      accessor: (row) => row.instant_win_title ?? '',
      id: 'title',
    },
    {
      Header: t('Tier Title'),
      width: 140, // 'middle',
      accessor: (row) => row.tier_title ?? '',
      id: 'tierTitle',
    },
    {
      Header: t('Triggered By'),
      width: 100, // 'middle',
      accessor: (row) => getTriggerTypeText(row, t),
      id: 'tierTitle',
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (row) => getStatusText(row, t),
    },
  ];
};
