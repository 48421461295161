import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import jpPhoneLocalization from 'react-phone-input-2/lang/jp.json';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import type { TranslateFuncType } from 'client/components/Translate';
import { ReduxState } from 'client/reducers';
import 'react-phone-input-2/lib/style.css';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { FileUploadInput } from 'client/components/FileUploadInput/FileUploadInput';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
import { Badge } from 'client/components/v3/Common/Badge';

import styles from './FormField.module.css';

const getFormFieldHelpText = (format: string, t: TranslateFuncType): string => {
  if (format === 'alpha-name') {
    return t('Alphabet characters or spaces');
  } else if (format === 'non-negative-integer') {
    return t('Positive numeric');
  } else if (format === 'float') {
    return t('Numeric');
  } else if (format === 'yyyy-mm-dd') {
    return 'YYYY-MM-DD';
  }

  return '';
};

export const FormField = (props: {
  prompt: string;
  onChange: (arg0: string) => void;
  value: string;
  options?: {
    text: string;
    value: string;
    key: string;
  }[];
  error?: string;
  format?: string;
  required?: boolean;
  disabled?: boolean;
  search?: boolean;
  allowFreeInput?: boolean;
  autoComplete?: 'on' | 'off';
  placeholder?: string;
  onBlur?: () => void;
  onKeyUp?: (e: any) => void;
  textArea?: boolean;
  height?: number;
}) => {
  const {
    prompt,
    onChange,
    value,
    options,
    error,
    format,
    required,
    disabled,
    search,
    allowFreeInput,
    placeholder,
    textArea,
    ...rest
  } = props;
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  if (format === 'yyyy-mm-dd') {
    return (
      <div className={styles['c-formField']}>
        <div className={styles['c-formField__ttl']}>
          <p>{prompt}</p>
        </div>
        <DateTimeTZInput
          value={props.value ? moment(props.value) : null}
          onChange={(value) =>
            value ? onChange(value.format('YYYY-MM-DD')) : onChange('')
          }
        />
      </div>
    );
  }

  if (format === 'datetime-local') {
    return (
      <div className={styles['c-formField']}>
        <div className={styles['c-formField__ttl']}>
          <p>{prompt}</p>
        </div>
        <div className={styles['c-formField__flex']}>
          <DateTimeTZInput
            value={props.value ? moment(props.value) : null}
            onChange={(value) =>
              value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
            }
          />
          <TimePicker
            value={props.value ? moment(props.value) : undefined}
            onChange={(value) =>
              value ? onChange(value.format('YYYY-MM-DDTHH:mm')) : onChange('')
            }
          />
        </div>
      </div>
    );
  }

  if (format === 'friendly_phone') {
    return (
      <div className={styles['c-formField']}>
        <div className={styles['c-formField__ttl']}>
          <p>{prompt}</p>
          {required && (
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          )}
        </div>
        <PhoneInput
          placeholder={locale === 'ja' ? '81 9012345678' : '1 800 123 4567'}
          masks={{ jp: '...........' }}
          preferredCountries={locale === 'ja' ? ['jp', 'us'] : ['us', 'jp']}
          localization={locale === 'ja' ? jpPhoneLocalization : undefined}
          value={props.value?.replace('+', '')}
          onChange={(value: string, data: { dialCode: string }) => {
            if (data && !value?.startsWith(data.dialCode)) {
              onChange(`+${data.dialCode}${value}`);
            } else if (value && !value?.startsWith('+')) {
              onChange(`+${value}`);
            } else {
              onChange(value);
            }
          }}
          searchPlaceholder={t('Search')}
          searchNotFound={t('No Results')}
          enableSearch
        />
      </div>
    );
  }

  if (format === 'file-upload') {
    return (
      <div className={styles['c-formField']}>
        <div className={styles['c-formField__ttl']}>
          <p>{prompt}</p>
          {required && (
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          )}
        </div>
        <FileUploadInput
          value={props.value ? JSON.parse(props.value) : []}
          onChange={(newValue) => props.onChange(JSON.stringify(newValue))}
          error={error}
        />
      </div>
    );
  }

  return options && options.length > 0 ? (
    <div className={styles['c-formField']}>
      <div className={styles['c-formField__ttl']}>
        <p>{prompt}</p>
        {required && (
          <Badge size="sm" label={t('Required')} color="warning-contained" />
        )}
      </div>
      <SingleDropdown
        selectedOption={value}
        error={error}
        onChange={(value) => onChange(value)}
        options={options}
        searchable={search}
        placeholder={placeholder}
        disabled={!!disabled}
      />
    </div>
  ) : textArea ? (
    <div className={styles['c-formField']}>
      <div className={styles['c-formField__ttl']}>
        <p>{prompt}</p>
        {required && (
          <Badge size="sm" label={t('Required')} color="warning-contained" />
        )}
      </div>
      <TextArea
        {...rest}
        value={value}
        error={error}
        onChange={(e, { value }) => onChange(value)}
        disabled={!!disabled}
        maxHeight={180}
        tooltipText={getFormFieldHelpText(format ?? '', t)}
      />
    </div>
  ) : (
    <div className={styles['c-formField']}>
      <div className={styles['c-formField__ttl']}>
        <p>{prompt}</p>
        {required && (
          <Badge size="sm" label={t('Required')} color="warning-contained" />
        )}
      </div>
      <TextField
        {...rest}
        error={error}
        name={prompt}
        value={value}
        onChange={(value) => onChange(value)}
        placeholder={prompt}
        disabled={!!disabled}
        tooltipText={getFormFieldHelpText(format ?? '', t)}
      />
    </div>
  );
};
