import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import baseStyles from 'client/v3-base.module.css';

import styles from './Dropdown.module.css';

type Props = {
  error?: string;
  label?: string;
  value: string;
  onSearchChange: (arg0: any, arg1: any) => void;
  onResultSelect: (arg0: any, arg1: any) => void;
  results: Record<string, any>[];
  maxWidth?: number;
  minWidth?: number;
  minLength?: string;
  maxLength?: string;
  spellCheck?: 'true' | 'false';
  autoComplete?: 'on' | 'off';
  disabled?: boolean;
  required?: boolean;
};

export const SearchDropdown = ({
  error,
  label,
  value,
  onSearchChange,
  onResultSelect,
  results,
  maxWidth,
  minWidth,
  maxLength,
  minLength,
  disabled,
  ...inputProps
}: Props) => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        showOptions &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowOptions(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('click', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('click', handleOutsideClick, {
        capture: true,
      });
    };
  }, [showOptions]);

  const style = {
    maxWidth,
    minWidth,
  };

  return (
    <div className={styles['c-dropdownWrapper']}>
      {label && <div className={styles['c-dropdown__label']}>{label}</div>}
      <fieldset
        ref={fieldSetRef}
        className={clsx(
          styles['c-dropdown'],
          isHovered && styles['is-hover'],
          error && styles['is-error'],
          disabled && styles['is-disabled']
        )}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          setShowOptions(true);
        }}
      >
        <div
          className={clsx(
            styles['c-dropdown__body'],
            styles['c-dropdown__body__noIcon'],
            styles['c-dropdown__body__inputFullWidth']
          )}
        >
          <label
            className={clsx(
              showOptions ? (results.length > 0 ? styles['is-active'] : '') : ''
            )}
            style={style}
          >
            <input
              value={value}
              onChange={(e) => onSearchChange(e, e.target)}
              type="text"
              maxLength={maxLength as any}
              minLength={minLength as any}
              {...inputProps}
            />
          </label>
        </div>
        {results.length > 0 && (
          <ul
            className={clsx(
              styles['c-dropdown__menu'],
              showOptions && styles['is-active']
            )}
          >
            {results.map((result, idx) => {
              return (
                <li
                  key={idx}
                  className={styles['c-dropdown__menu__item']}
                  data-value={result.value}
                  data-idx={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    onResultSelect(e, {
                      result: results[e.currentTarget.dataset.idx as any],
                    });
                    setShowOptions(false);
                  }}
                >
                  {result.detail}
                </li>
              );
            })}
          </ul>
        )}
      </fieldset>
      {error && <p className={baseStyles['u-error-msg']}>{error}</p>}
    </div>
  );
};
