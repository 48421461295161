import _ from 'lodash';

import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import type { Weekday } from 'client/libraries/util/weekdays';
import * as Swagger from 'shared/models/swagger';

export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};

export interface OpenHourSchedule {
  openFrom: string;
  openTo: string;
  schedule: Schedule;
}

export interface Schedule {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
}

export interface FormValues {
  name: string;
  description: string;
  mediaUrls: string[];
  openHourSchedules: OpenHourSchedule[];
}

export const getInitialValues = (
  existingRestaurant: Swagger.Restaurant | null
): FormValues => {
  if (existingRestaurant) {
    return {
      name: existingRestaurant.name ?? '',
      description: existingRestaurant.description ?? '',
      mediaUrls:
        existingRestaurant.media_items?.map((mediaItem) => mediaItem.url) ?? [],
      openHourSchedules:
        existingRestaurant?.open_hour_schedules?.map((sched) => ({
          openFrom: sched.start_time_local ?? '0:00',
          openTo: sched.end_time_local ?? '0:00',
          schedule: {
            dateRanges:
              sched.schedule?.date_ranges?.map((dateRange) => ({
                startDate: dateRange.start_date_local ?? '',
                endDate: dateRange.end_date_local ?? '',
              })) ?? [],
            weekdays: sched.schedule?.days_of_week ?? [],
            closedDates: _.sortBy(
              [
                ...(sched.schedule?.closed_dates?.map((closedDate) => ({
                  date: closedDate,
                  repeatsAnnually: false,
                })) ?? []),
                ...(sched.schedule?.annually_repeating_closed_dates?.map(
                  (closedDate) => ({
                    date: closedDate,
                    repeatsAnnually: true,
                  })
                ) ?? []),
              ],
              'date'
            ),
          },
        })) ?? [],
    };
  }

  return {
    name: '',
    description: '',
    mediaUrls: [],
    openHourSchedules: [],
  };
};

export const convertFormValuesToSwagger = (
  formValues: FormValues
): Swagger.RestaurantParams => {
  return {
    name: formValues.name,
    description: formValues.description,
    media_items: convertMediaUrlsToProductMediaItems(formValues.mediaUrls),
    open_hour_schedules: formValues.openHourSchedules?.map((sched) => ({
      start_time_local: sched.openFrom,
      end_time_local: sched.openTo,
      schedule: {
        date_ranges: sched.schedule.dateRanges.map((dateRange) => ({
          start_date_local: dateRange.startDate,
          end_date_local: dateRange.endDate,
        })),
        days_of_week: sched.schedule.weekdays,
        closed_dates: sched.schedule.closedDates
          .filter((closedDate) => !closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
        annually_repeating_closed_dates: sched.schedule.closedDates
          .filter((closedDate) => closedDate.repeatsAnnually)
          .map((closedDate) => closedDate.date),
      },
    })),
  };
};
