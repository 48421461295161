import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Highlight } from '@nutmeglabs/falcon-ui';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Input, TextArea } from 'client/components/Form';

interface Props {
  value: Highlight;
  onChange: (newValue: Highlight) => void;
  onClose: () => void;
}

export const EditHighlightModal = ({ value, onChange, onClose }: Props) => {
  const { t } = useTranslation();
  const [title, setTitle] = React.useState(value.title);
  const [description, setDescription] = React.useState(value.description);
  const [imageUrl, setImageUrl] = React.useState(value.imageUrl);

  return (
    <Modal insertRoot title={t('Edit Highlight')} open={true} onClose={onClose}>
      <Modal.Content>
        <Input
          label={t('Title')}
          value={title}
          onChange={(e, { value }) => setTitle(value)}
        />
        <TextArea
          label={t('Description')}
          value={description}
          onChange={(e, { value }) => setDescription(value)}
        />
        <ImageVideoAudioInput
          fileUrls={imageUrl ? [imageUrl] : []}
          onChange={(newValue) =>
            newValue.length > 0 ? setImageUrl(newValue[0]) : setImageUrl('')
          }
          maxFileCount={1}
          disableYoutubeVideos
        />
      </Modal.Content>
      <Modal.Actions>
        <Button.Update
          onClick={() => {
            onChange({
              ...value,
              title,
              description,
              imageUrl,
            });
            onClose();
          }}
        >
          {t('Update')}
        </Button.Update>
      </Modal.Actions>
    </Modal>
  );
};
