import type { ThunkDispatch } from 'redux-thunk';

import {
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  ENABLE_ACCOUNT_REQUEST,
  ENABLE_ACCOUNT_SUCCESS,
  ENABLE_ACCOUNT_FAILURE,
  DISABLE_ACCOUNT_REQUEST,
  DISABLE_ACCOUNT_SUCCESS,
  DISABLE_ACCOUNT_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { Account$Input, Account$Patch } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchAccountsRequest = () => ({
  type: FETCH_ACCOUNTS_REQUEST,
});

const fetchAccountsSuccess = (response: any) => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  response,
});

const fetchAccountsFailure = (error: any) => ({
  type: FETCH_ACCOUNTS_FAILURE,
  error,
});

export const fetchAccounts =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchAccountsRequest());
    fetch('/api/accounts', {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchAccountsSuccess(result)))
      .catch((error) => dispatch(fetchAccountsFailure(error)));
  };

const deleteAccountRequest = (id: string) => ({
  type: DELETE_ACCOUNT_REQUEST,
  id,
});

const deleteAccountSuccess = (response: any, id: string) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  response,
  id,
});

const deleteAccountFailure = (error: any) => ({
  type: DELETE_ACCOUNT_FAILURE,
  error,
});

export const deleteAccount =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteAccountRequest(id));
    return fetch(`/api/accounts/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteAccountSuccess(result, id)))
      .catch((error) => dispatch(deleteAccountFailure(error)));
  };

const createAccountRequest = (newAccount: Account$Input) => ({
  type: CREATE_ACCOUNT_REQUEST,
  newAccount,
});

const createAccountSuccess = (response: any) => ({
  type: CREATE_ACCOUNT_SUCCESS,
  response,
});

const createAccountFailure = (error: any) => ({
  type: CREATE_ACCOUNT_FAILURE,
  error,
});

export const createAccount =
  (newAccount: Account$Input) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    // email address is all lower case
    newAccount.email = newAccount.email.toLowerCase();
    dispatch(createAccountRequest(newAccount));
    return fetch('/api/accounts', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newAccount),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(createAccountSuccess(result)))
      .catch((error) => dispatch(createAccountFailure(error)));
  };

const updateAccountRequest = (id: string, patch: Account$Patch) => ({
  type: UPDATE_ACCOUNT_REQUEST,
  id,
  patch,
});

const updateAccountSuccess = (response: any) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  response,
});

const updateAccountFailure = (error: any) => ({
  type: UPDATE_ACCOUNT_FAILURE,
  error,
});

export const updateAccount =
  (id: string, patch: Account$Patch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateAccountRequest(id, patch));
    return fetch(`/api/accounts/${id}`, {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(patch),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(updateAccountSuccess(result)))
      .catch((error) => dispatch(updateAccountFailure(error)));
  };

const enableAccountRequest = (id: string) => ({
  type: ENABLE_ACCOUNT_REQUEST,
  id,
});

const enableAccountSuccess = (response: any) => ({
  type: ENABLE_ACCOUNT_SUCCESS,
  response,
});

const enableAccountFailure = (error: any) => ({
  type: ENABLE_ACCOUNT_FAILURE,
  error,
});

export const enableAccount =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(enableAccountRequest(id));
    fetch(`/api/accounts/${id}/enable`, {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(enableAccountSuccess(result)))
      .catch((error) => dispatch(enableAccountFailure(error)));
  };

const disableAccountRequest = (id: string) => ({
  type: DISABLE_ACCOUNT_REQUEST,
  id,
});

const disableAccountSuccess = (response: any) => ({
  type: DISABLE_ACCOUNT_SUCCESS,
  response,
});

const disableAccountFailure = (error: any) => ({
  type: DISABLE_ACCOUNT_FAILURE,
  error,
});

export const disableAccount =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(disableAccountRequest(id));
    fetch(`/api/accounts/${id}/disable`, {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(disableAccountSuccess(result)))
      .catch((error) => dispatch(disableAccountFailure(error)));
  };
