import useSWR from 'swr';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { buildImpersonateToken } from 'shared/libraries/cognito';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type {
  Account,
  Review,
  ReviewAttribution,
  ReviewRating,
} from 'shared/models/swagger';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());

export const useReviews = ({
  reservationId,
  attributions,
  ratings,
  writtenDateFrom,
  writtenDateTo,
  participationDateFrom,
  participationDateTo,
}: {
  reservationId?: string;
  attributions?: ReviewAttribution[];
  ratings?: ReviewRating[];
  writtenDateFrom?: string;
  writtenDateTo?: string;
  participationDateFrom?: string;
  participationDateTo?: string;
}): {
  reviews: Review[];
  isLoading: boolean;
  error: string;
  refetchReviews: () => void;
} => {
  const { i18n } = useTranslation();
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const params = new URLSearchParams();

  if (reservationId) {
    params.append('reservation_id', reservationId);
  }

  if (attributions) {
    attributions.forEach((attribution) =>
      params.append('attributions', attribution)
    );
  }

  if (ratings) {
    ratings.forEach((rating) => params.append('ratings', rating));
  }

  if (writtenDateFrom) {
    params.append(
      'written_date_time_utc_from',
      moment(writtenDateFrom).utc().format()
    );
  }

  if (writtenDateTo) {
    params.append(
      'written_date_time_utc_to',
      moment(writtenDateTo).utc().add(1, 'days').format()
    );
  }

  if (participationDateFrom) {
    params.append('participation_date_local_from', participationDateFrom);
  }

  if (participationDateTo) {
    params.append(
      'participation_date_local_to',
      moment(participationDateTo).add(1, 'days').format('YYYY-MM-DD')
    );
  }

  const { data, mutate, error } = useSWR(
    [
      `/api/reviews?${params.toString()}`,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );
  return {
    refetchReviews: mutate,
    reviews: data?.reviews ?? [],
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
