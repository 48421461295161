import { getRelativeDateDescription } from 'client/libraries/util/util';
import type {
  ProductPromotionShape,
  PromotionShape,
} from 'client/libraries/util/promotionShape';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  PromotionType,
  PromotionStatus,
  UnitPriceDelta,
} from 'shared/models/swagger';

export const getPromotionTypeText = (
  promotionType: PromotionType,
  t: TranslateFuncType
): string => {
  switch (promotionType) {
    case 'SIMPLE_DISCOUNT':
      return t('Simple Discount');

    case 'EARLY_BIRD_DISCOUNT':
      return t('Early Bird Discount');

    case 'LAST_MINUTE_DISCOUNT':
      return t('Last Minute Discount');

    case 'GROUP_DISCOUNT':
      return t('Group Discount');

    case 'FAMILY_DISCOUNT':
      return t('Family Discount');

    case 'ADDITIONAL_CHARGE':
      return t('Additional Charge');

    default:
      return '';
  }
};
export const getPromotionStatusText = (
  promotionStatus: PromotionStatus,
  t: TranslateFuncType
): string => {
  switch (promotionStatus) {
    case 'ON':
      return t('On');

    case 'OFF':
      return t('Off');

    case 'DISCONTINUED':
      return t('Discontinued');

    default:
      return '';
  }
};
export const getPromotionConditionDescription = <
  T extends PromotionShape | ProductPromotionShape
>(
  promotion: T,
  t: TranslateFuncType,
  locale: string
): string => {
  switch (promotion.type) {
    case 'SIMPLE_DISCOUNT':
    case 'ADDITIONAL_CHARGE':
      return '';

    case 'EARLY_BIRD_DISCOUNT':
      return promotion.earlyBirdDeadline
        ? t('Until {{relativeDateDescription}}', {
            relativeDateDescription: getRelativeDateDescription(
              promotion.earlyBirdDeadline,
              t,
              locale
            ),
          })
        : '';

    case 'LAST_MINUTE_DISCOUNT':
      return promotion.lastMinuteStart
        ? t('Starting {{relativeDateDescription}}', {
            relativeDateDescription: getRelativeDateDescription(
              promotion.lastMinuteStart,
              t,
              locale
            ),
          })
        : '';

    case 'GROUP_DISCOUNT':
      if (!promotion.groupDiscount) {
        return '';
      }

      return t('{{groupSize}} or more guests', {
        groupSize: promotion.groupDiscount.min_guest_count,
      });

    case 'FAMILY_DISCOUNT': {
      const familyDiscount = promotion.familyDiscount;

      if (!familyDiscount) {
        return '';
      }

      let discountDescription = '';

      if ((promotion as PromotionShape).unitPriceDeltas) {
        const promo: PromotionShape = promotion as any;
        const unitPriceDelta = promo.unitPriceDeltas.find(
          (delta) =>
            delta.guest_type_key === familyDiscount.discount_guest_type_key
        );

        if (!unitPriceDelta) {
          discountDescription = '0%';
        } else {
          discountDescription = unitPriceDelta.delta_percent
            ? `${unitPriceDelta.delta_percent}%`
            : `${unitPriceDelta.delta_fixed_gross || ''}`;
        }
      } else if ((promotion as ProductPromotionShape).adjustedUnitPrices) {
        const promo: ProductPromotionShape = promotion as any;
        const unitPriceDelta = promo.adjustedUnitPrices.find(
          (delta) =>
            delta.guest_type_key === familyDiscount.discount_guest_type_key
        );

        if (!unitPriceDelta) {
          discountDescription = '0%';
        } else {
          discountDescription = unitPriceDelta.delta_percent
            ? `${unitPriceDelta.delta_percent}%`
            : `${unitPriceDelta.delta_fixed_gross || ''}`;
        }
      }

      return t(
        'For every {{baseUnitCount}} x {{baseUnit}} booked, receive {{discountDescription}} off 1 x {{discountUnit}}',
        {
          baseUnitCount: familyDiscount.qualifying_base_guest_count,
          baseUnit:
            familyDiscount.base_guest_type_title ||
            familyDiscount.base_guest_type_key,
          discountUnit:
            familyDiscount.discount_guest_type_title ||
            familyDiscount.discount_guest_type_key,
          discountDescription,
        }
      );
    }
    default:
      return '';
  }
};
export const getUnitPriceDeltaUnitTitle = (
  unitPriceDelta: UnitPriceDelta,
  t: TranslateFuncType
): string => {
  if (unitPriceDelta.unit_price_method === 'PER_BOOKING') {
    return t('Per-Booking');
  } else if (unitPriceDelta.unit_price_method === 'ANY') {
    return t('All units');
  }

  return unitPriceDelta.guest_type_key || '';
};
export const getPromotionDiscountDescription = (
  promotion: PromotionShape,
  t: TranslateFuncType
): string => {
  return promotion.unitPriceDeltas
    .map((delta) => {
      const unitTitle = getUnitPriceDeltaUnitTitle(delta, t);
      const discount = delta.delta_percent
        ? `${delta.delta_percent}%`
        : `${delta.delta_fixed_gross || ''}/${delta.delta_fixed_net || ''}`;
      return `${unitTitle}: ${discount}`;
    })
    .join(', ');
};
