import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { formatStartTime } from 'client/pages/ProductDetails/formatStartTime';
import { LocationList } from 'client/pages/ProductDetails/ProductContentsPane/ProductOutline/LocationList/LocationList';
import type { ProductShape } from 'client/libraries/util/productShape';
import styles from 'client/pages/v3/Product/ProductDetails/ProductDetailsSection/ProductOutline/ProductOutline.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type Props = {
  product: ProductShape;
};
export const DropoffCheckoutTab = ({ product }: Props) => {
  const { t } = useTranslation();
  const [selectedTimeSlotKey, setSelectedTimeSlotKey] = useState<string>(
    product.startTimes.length > 0 ? product.startTimes[0].timeSlotKey : ''
  );

  const handleStartTimeChange = (val: any) => {
    setSelectedTimeSlotKey(val);
  };

  const showDropoffSection = product.dropoffLocations.length > 0;
  const showCheckoutSection = product.checkoutLocations.length > 0;
  const selectedStartTime = product.startTimes.find(
    (startTime) => startTime.timeSlotKey === selectedTimeSlotKey
  );

  const options = product.startTimes.map((startTime) => ({
    text: formatStartTime(startTime),
    value: startTime.timeSlotKey,
  }));

  return (
    <div className={styles['c-schedule']}>
      <div className={styles['singleDropdown__container']}>
        <SingleDropdown
          options={options}
          selectedOption={selectedTimeSlotKey}
          onChange={handleStartTimeChange}
        />
      </div>
      {showDropoffSection && (
        <>
          <p className={styles['c-schedule__headline']}>
            {t('Dropoff Locations / Times')}
          </p>
          <LocationList
            startTime={selectedStartTime}
            locations={product.dropoffLocations}
          />
        </>
      )}
      {showCheckoutSection && (
        <>
          <p className={styles['c-schedule__headline']}>
            {t('Checkout Times / Locations')}
          </p>
          <LocationList
            startTime={selectedStartTime}
            locations={product.checkoutLocations}
          />
        </>
      )}
    </div>
  );
};
