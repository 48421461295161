import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { MarketingAutomationTriggerCondition } from 'shared/models/swagger';
import {
  createMarketingAutomationTriggerCondition,
  deleteMarketingAutomationTriggerCondition,
  fetchMarketingAutomationTriggerConditions,
} from 'client/actions/marketingAutomationTriggerConditions';

const EditCopyDeleteCell = ({
  trigger,
}: {
  trigger: MarketingAutomationTriggerCondition;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationTriggerConditions.loading
  );
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();

  return (
    <Box display="flex">
      <Link to={`/marketingautomation/triggers/${trigger.id}/edit`}>
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createMarketingAutomationTriggerCondition({
              title: `${trigger.title} [COPY]`,
              trigger: trigger.trigger,
              condition_rules: trigger.condition_rules,
            })
          );

          const lastCreatedTrigger =
            store.getState().marketingAutomationTriggerConditions
              .lastCreatedTriggerCondition;
          if (lastCreatedTrigger) {
            history.push(
              `/marketingautomation/triggers/${lastCreatedTrigger.id}/edit`
            );
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Trigger Condition')}
            content={t('Are you sure you want to delete trigger condition?')}
            onConfirm={async () => {
              await dispatch(
                deleteMarketingAutomationTriggerCondition(trigger.id as string)
              );
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (): ColumnType<MarketingAutomationTriggerCondition>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (trigger) => <EditCopyDeleteCell trigger={trigger} />,
      width: 150,
    } as ColumnType<MarketingAutomationTriggerCondition>,

    {
      Header: t('Title'),
      id: 'title',
      accessor: (salesOffer) => salesOffer.title,
    },
    {
      Header: t('Participation Date Range'),
      id: 'participation_date_range',
      accessor: (trigger) =>
        trigger.condition_rules
          ?.filter(
            (rule) =>
              rule.participation_start_date || rule.participation_end_date
          )
          ?.map((rule) => {
            return `${rule.participation_start_date} ~ ${rule.participation_end_date}`;
          })
          ?.join('; '),
    },
  ];
};

export const MarketingAutomationTriggerConditionList = () => {
  const dispatch = useDispatch();

  const columns = useColumns();

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchMarketingAutomationTriggerConditions());
  }, []);

  const triggers = useSelector(
    (state: ReduxState) => state.marketingAutomationTriggerConditions.all
  );

  const sortedTriggers = _.orderBy(triggers, 'title', 'asc');

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to="/marketingautomation/triggers/new">
            <Button style="green" size="middle">
              {t('Create New Trigger')}
            </Button>
          </Link>
        </Box>
      </Box>
      <PagedGenericTable allItems={sortedTriggers} columns={columns} />
    </div>
  );
};
