import {
  Organization,
  Organization$Patch,
  ReservationColumn,
  BookingSourceType,
  SourceLanguage,
} from 'shared/models/swagger';

export interface Preset {
  key: string;
  title: string;
  orderBy: string;
  bookingStatuses: string[];
  dateType: string;
  targetDateType: string;
  previousDateRange: number;
  nextDateRange: number;
  bookingSources: string[];
  agentIds: string[];
  supplierIds: string[];
  contentLanguages: string[];
  dateRangeType?: string;
  dateRange?: string;
  reservationLanguages: SourceLanguage[];
}

export interface FormValues {
  presets: Preset[];
  defaultColumns: string[];
}

export const getInitialValues = (
  organization: Organization | null
): FormValues => {
  const searchPreset = organization?.account_reservation_search_settings;
  return {
    presets: (searchPreset?.presets ?? []).map((preset) => {
      let dateRangeType = 'NEXT';
      let dateRange = '1';
      let targetDateType = preset.target_date_type ?? '';
      if (preset.target_date_type === 'PREVIOUS_DATE_RANGE') {
        dateRangeType = 'PREVIOUS';
        dateRange = preset.previous_date_range?.toString() ?? '';
        targetDateType = 'RANGE';
      } else if (preset.target_date_type === 'NEXT_DATE_RANGE') {
        dateRangeType = 'NEXT';
        dateRange = preset.next_date_range?.toString() ?? '';
        targetDateType = 'RANGE';
      }

      return {
        key: preset.key ?? '',
        title: preset.title ?? '',
        orderBy: preset.order_by ?? '',
        bookingStatuses: preset.statuses ?? [],
        dateType: preset.date_type ?? '',
        targetDateType: targetDateType,
        previousDateRange: preset.previous_date_range ?? 0,
        nextDateRange: preset.next_date_range ?? 0,
        bookingSources: preset.booking_sources ?? [],
        agentIds: preset.agent_ids ?? [],
        supplierIds: preset.supplier_ids ?? [],
        contentLanguages: preset.content_languages ?? [],
        dateRangeType: dateRangeType,
        dateRange: dateRange,
        reservationLanguages: preset.reservation_languages ?? [],
      };
    }),
    defaultColumns: searchPreset?.default_columns ?? [],
  };
};

export const convertToOrganizationPatch = (
  values: FormValues
): Organization$Patch => {
  return {
    account_reservation_search_settings: {
      presets: values.presets.map((preset) => {
        let targetDateType = preset.targetDateType;
        let previousDateRange = 0;
        let nextDateRange = 0;
        if (preset.targetDateType === 'RANGE') {
          if (preset.dateRangeType === 'PREVIOUS') {
            targetDateType = 'PREVIOUS_DATE_RANGE';
            previousDateRange = Number(preset.dateRange ?? 0);
          } else if (preset.dateRangeType === 'NEXT') {
            targetDateType = 'NEXT_DATE_RANGE';
            nextDateRange = Number(preset.dateRange ?? 0);
          }
        }

        return {
          key: preset.key,
          title: preset.title,
          order_by: preset.orderBy,
          statuses: preset.bookingStatuses,
          date_type: preset.dateType as any,
          target_date_type: targetDateType as any,
          previous_date_range: previousDateRange,
          next_date_range: nextDateRange,
          booking_sources: preset.bookingSources as BookingSourceType[],
          agent_ids: preset.agentIds,
          supplier_ids: preset.supplierIds,
          content_languages: preset.contentLanguages,
          reservation_languages: preset.reservationLanguages,
          is_ascending: false,
        };
      }),
      default_columns: values.defaultColumns as ReservationColumn[],
    },
  };
};
