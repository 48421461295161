import clsx from 'clsx';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Input, Select } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';
import type { SourceLanguage } from 'shared/models/swagger';

import { getItemFieldOptions } from './formValues';
import styles from './DraggableItemInputForm.module.css';

interface Props {
  name: string;
  translationTargetLanguage: SourceLanguage | null;
  onRemoveClick: () => void;
}
export const ItemInputForm = ({
  name,
  translationTargetLanguage,
  onRemoveClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <TranslatedField name={`${name}.title`}>
      {({ input, translationInput }) => (
        <>
          <td>
            <div
              className={clsx(
                baseStyles['base-flex'],
                styles['input'],
                translationTargetLanguage
                  ? styles['input__source_language']
                  : null
              )}
            >
              <Input {...input} />
            </div>
          </td>
          {translationTargetLanguage && (
            <td>
              <div
                className={clsx(
                  styles['input'],
                  translationTargetLanguage
                    ? styles['input__translation_language']
                    : null
                )}
              >
                <Input {...translationInput} />
              </div>
            </td>
          )}
          <td>
            <Field name={`${name}.field`}>
              {({ input }) => (
                <Select
                  value={input.value}
                  options={getItemFieldOptions(t)}
                  onChange={(_, { value }) => {
                    input.onChange(value);
                  }}
                />
              )}
            </Field>
          </td>
          <td style={{ width: '45px' }}>
            <div className={baseStyles['base-flex']}>
              <DeleteIcon onClick={() => onRemoveClick()} />
            </div>
          </td>
        </>
      )}
    </TranslatedField>
  );
};
