import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { currency } from 'shared/libraries/currency';
import type { Reservation } from 'shared/models/swagger';
import { calculateReservationFares } from 'client/actions/fares';

type Props = {
  reservation: Reservation;
};

export const PaymentMethod = ({ reservation }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [settlementAmount, setSettlementAmount] = useState<string>('');

  useEffect(() => {
    const f = async () => {
      try {
        const resp: any = await dispatch(
          calculateReservationFares({
            ...reservation,
            product_instance_id: reservation?.product_instance_id || '',
            reservation_id: reservation.id,
          })
        );
        setSettlementAmount(resp.response?.settlement_currency_amount_gross);
      } catch (err) {
        setSettlementAmount('');
      }
    };
    f();
  }, [reservation]);

  const getText = () => {
    let paymentMethodText = reservation.payment_method
      ? t(reservation.payment_method)
      : t('Unspecified');

    if (
      reservation.payment_method === 'OTHER' &&
      reservation.other_payment_method
    ) {
      paymentMethodText += ` (${t(reservation.other_payment_method) || ''})`;
    }

    if (reservation.payment_deferred) {
      const invoiceStatusHistory =
        reservation.billing_info?.invoice_status_history || [];
      const historyLength = invoiceStatusHistory.length;

      let paidStatus = '';
      if (
        historyLength === 0 ||
        (historyLength > 1 &&
          invoiceStatusHistory[historyLength - 1] === 'INVOICE_IN_PROGRESS')
      ) {
        // not settled
        paidStatus = t('Not Settled');
      } else if (
        (historyLength >= 1 &&
          invoiceStatusHistory[historyLength - 1] ===
            'INVOICE_CHARGE_PENDING') ||
        (historyLength >= 2 &&
          invoiceStatusHistory[historyLength - 2] ===
            'INVOICE_CHARGE_PENDING' &&
          ['INVOICE_REFUNDED', 'INVOICE_PARTIALLY_REFUNDED'].includes(
            invoiceStatusHistory[historyLength - 1]
          ))
      ) {
        // partially not settled
        paidStatus = t('Partially Not Settled {{currencyAmount}}', {
          currencyAmount: currency(settlementAmount).format(),
        });
      } else if (
        (historyLength >= 1 &&
          invoiceStatusHistory[historyLength - 1] === 'INVOICE_PAID') ||
        (historyLength >= 2 &&
          invoiceStatusHistory[historyLength - 2] === 'INVOICE_PAID' &&
          ['INVOICE_REFUNDED', 'INVOICE_PARTIALLY_REFUNDED'].includes(
            invoiceStatusHistory[historyLength - 1]
          ))
      ) {
        // settled
        paidStatus = t('Settled');
      }
      paymentMethodText = `${paymentMethodText}/${t(
        'Email Payment'
      )}[${paidStatus}]`;
    }

    return paymentMethodText;
  };

  return <p> {getText()} </p>;
};
