import isEqual from 'lodash/isEqual';
import * as React from 'react';

export type Bounds = {
  left: number;
  right: number;
  top: number;
  bottom: number;
  height: number;
  width: number;
};
type RefType<T> = {
  current: null | T;
};
// useBounds is a hook that provides the bounding rectangle for an element. Inspired by
// this: https://github.com/Swizec/useDimensions
export const useBounds = <T extends HTMLElement>(): [RefType<T>, Bounds] => {
  const ref = React.createRef<T>();
  const [bounds, setBounds] = React.useState<Bounds>({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: 0,
    width: 0,
  });
  React.useLayoutEffect(() => {
    if (ref.current) {
      const { left, right, top, bottom, width, height } =
        ref.current.getBoundingClientRect();
      const newBounds = {
        left,
        right,
        top,
        bottom,
        height,
        width,
      };

      if (!isEqual(newBounds, bounds)) {
        setBounds({
          left,
          right,
          top,
          bottom,
          height,
          width,
        });
      }
    }
  }, [ref, bounds]);
  return [ref, bounds];
};
