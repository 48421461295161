import { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './Snackbar.module.css';

type Props = {
  id?: string;
  text: string;
  color: 'success' | 'error' | 'warning' | 'neutral';
  shouldShow: boolean;
  content?: string;
  onClose?: () => void;
};

export const Snackbar = ({
  id,
  text,
  color,
  shouldShow,
  content,
  onClose,
}: Props) => {
  const [show, setShow] = useState(shouldShow);

  useEffect(() => {
    if (shouldShow) {
      setShow(shouldShow);
    }
  }, [shouldShow]);

  return (
    <div
      className={clsx(styles['c-snackbar'], show ? styles['is-active'] : '')}
      id={id}
    >
      <div className={clsx(styles['c-snackbar__content'], styles[color])}>
        <div className={styles['c-snackbar__content__txt']}>
          <i className="c-icon-outline-general-check-circle"></i>
          <div>
            <p className={styles['c-snackbar__content__txt__top']}>{text}</p>
            <p>{content}</p>
          </div>
        </div>
        <a
          className={styles['c-snackbar__content__close']}
          onClick={() => {
            setShow(false);
            onClose?.();
          }}
        >
          <i className="c-icon-solid-general-x-close"></i>
        </a>
      </div>
    </div>
  );
};
