import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { FocusableInput, Select, ToggleButton } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

import styles from '../DetailsStep.module.css';

export const MinimumParticipantsEditor = () => {
  const { t } = useTranslation();

  const form = useForm();

  const atLeastOneConsumeInventoryRequired = (
    value: any[] | null | typeof undefined
  ): string => {
    if (!value?.some((item) => item.shouldConsumeInventory)) {
      return t(
        'At least one unit must consume inventory. Units that consume inventory will contribute to guest counts in the product calendar.'
      );
    }

    return '';
  };

  return (
    <div className={styles['c-table-list']}>
      <table>
        <tbody>
          <Field name="usePerBookingInventoryCounting">
            {({ input: inventoryCountingInput }) => (
              <>
                <tr>
                  <th>{t('Inventory Counting')}</th>
                  <td>
                    <ToggleButton
                      label={t(
                        'Count inventory per booking instead of per participant'
                      )}
                      checked={inventoryCountingInput.value}
                      onChange={() => {
                        if (!inventoryCountingInput.value) {
                          form.change('minimumParticipantCount', 0);
                        }
                        inventoryCountingInput.onChange(
                          !inventoryCountingInput.value
                        );
                      }}
                    />
                  </td>
                </tr>
                {!inventoryCountingInput.value && (
                  <tr>
                    <th>{t('Minimum Participants')}</th>
                    <td>
                      <Field name="minimumParticipantCount">
                        {({ input }) => (
                          <Select
                            width={128}
                            value={`${input.value}`}
                            onChange={(e, { value }) =>
                              input.onChange(parseInt(value))
                            }
                            options={_.times(51, (count) => ({
                              value: `${count}`,
                              text: t('{{count}} people', {
                                count,
                              }) as string,
                            }))}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                )}
                {!inventoryCountingInput.value && (
                  <tr>
                    <th>{t('Per-unit Settings')}</th>
                    <td>
                      <FieldArray
                        name="unitWeights"
                        validate={atLeastOneConsumeInventoryRequired}
                      >
                        {({ fields, meta: { touched, error } }) => (
                          <>
                            <div className={styles['c-tableChild']}>
                              <ul>
                                <li className={baseStyles['base-t-240']}>
                                  {t('Unit')}
                                </li>
                                <li className={baseStyles['base-t-160']}>
                                  {t('Unit Weight')}
                                </li>
                                <li className={baseStyles['base-t-160']}>
                                  {t('Consume Inventory')}
                                </li>
                                <li />
                              </ul>
                              {fields.map((name) => (
                                <Field key={name} name={name}>
                                  {({ input }) => (
                                    <ul>
                                      <li data-title={t('Unit')}>
                                        {input.value.unitKey}
                                      </li>
                                      <li data-title={t('Unit Weight')}>
                                        <Field name={`${name}.weight`}>
                                          {({ input }) => (
                                            <Select
                                              options={[
                                                {
                                                  value: '0',
                                                  text: t('0 people'),
                                                },
                                                {
                                                  value: '0.25',
                                                  text: t('0.25 people'),
                                                },
                                                {
                                                  value: '0.5',
                                                  text: t('0.5 people'),
                                                },
                                                {
                                                  value: '1',
                                                  text: t('1.0 people'),
                                                },
                                              ]}
                                              value={`${input.value}`}
                                              onChange={(e, { value }) => {
                                                input.onChange(
                                                  parseFloat(value)
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </li>
                                      <li>
                                        <Field
                                          name={`${name}.shouldConsumeInventory`}
                                        >
                                          {({ input }) => (
                                            <ToggleButton
                                              label=""
                                              checked={input.value}
                                              onChange={() =>
                                                input.onChange(!input.value)
                                              }
                                            />
                                          )}
                                        </Field>
                                      </li>
                                      <li />
                                    </ul>
                                  )}
                                </Field>
                              ))}
                              {error && typeof error === 'string' && (
                                <FocusableInput name="unitWeights" />
                              )}
                            </div>

                            {touched && error && (
                              <Box mb={2} width="100%">
                                <p className={baseStyles['base-form-box__err']}>
                                  {error}
                                </p>
                              </Box>
                            )}
                          </>
                        )}
                      </FieldArray>
                      <p>
                        {t('* Unit Weight')}
                        <br />
                        {t(
                          '・1 = for every person that applies, one is counted'
                        )}
                        <br />
                        {t(
                          '・0.5 = for every two people that apply, one is counted'
                        )}
                        <br />
                        {t(
                          '・0.25 = for every four people that apply, one is counted'
                        )}
                        <br />
                        {t('・0 = not counted')}
                      </p>
                      <p>
                        {t('* Consume Inventory')}
                        <br />
                        {t(
                          'When it is turned off, inventory will not be consumed for the unit.'
                        )}
                      </p>
                    </td>
                  </tr>
                )}
              </>
            )}
          </Field>
        </tbody>
      </table>
    </div>
  );
};
