import * as React from 'react';

import { getBookingWidgetMultiProductCalendarUrl } from 'client/libraries/util/getBookingWidgetMultiProductCalendarUrl';

import { BookNowButtonEditor } from './BookNowButtonEditor';

type Props = {
  bookingWidgetApiKey: string;
  widgetLanguage: string;
};

export const BookingWidgetMultiProductBookNowButton = ({
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const calendarUrl = `${getBookingWidgetMultiProductCalendarUrl(
    bookingWidgetApiKey,
    widgetLanguage
  )}&isModal=true`;

  return <BookNowButtonEditor calendarUrl={calendarUrl} calendarType="MULTI" />;
};
