import clsx from 'clsx';

import { EditCustomPageHeader } from 'client/pages/v3/BookingWidget/EssentialPages/EditCustomPage/EditCustomPageHeader';
import { EditCustomPageBody } from 'client/pages/v3/BookingWidget/EssentialPages/EditCustomPage/EditCustomPageBody';
import { V3Page } from 'client/components/v3/Page/V3Page';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
import baseStyles from 'client/v3-base.module.css';

export const EditCustomPage = () => {
  return (
    <V3Page>
      <EditCustomPageHeader />
      <div className={baseStyles['l-main__body']}>
        <section className={styles['g-section']}>
          <div
            className={clsx(styles['p-inquiry-tabs'], styles['js-tab-scope'])}
            style={{ marginBottom: '70px' }}
          >
            <div className={styles['p-inquiry-tabs__body']}>
              <EditCustomPageBody />
            </div>
          </div>
        </section>
      </div>
    </V3Page>
  );
};
