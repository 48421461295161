import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

import { setAlternateOrganizationIsActive } from 'client/actions/user';
import {
  activeAccountSelector,
  alternateOrganizationIsActiveSelector,
} from 'client/reducers/user';
import { ReduxState } from 'client/reducers';

import styles from '../../base.module.css';

export const AlternateOrganizationSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alternateOrganizationIsActive = useSelector(
    alternateOrganizationIsActiveSelector
  );
  const activeAccount = useSelector(activeAccountSelector);
  const activeOrg: 'ALTERNATE' | 'PRIMARY' = alternateOrganizationIsActive
    ? 'ALTERNATE'
    : 'PRIMARY';
  const options = [
    {
      value: 'PRIMARY',
      text: `${activeAccount?.organization_name ?? '<MISSING>'} (${
        activeAccount?.organization_type ?? '<MISSING>'
      })`,
    },
    {
      value: 'ALTERNATE',
      text: `${activeAccount?.alternate_organization_name ?? '<MISSING>'} (${
        activeAccount?.alternate_organization_type ?? '<MISSING>'
      })`,
    },
  ];
  const bookingFormIsActive = useRouteMatch(
    '/products/:productID/instances/:instanceID/book'
  );
  const params = useParams();
  const bookingFormProduct = useSelector((state: ReduxState) =>
    params['productID' as keyof typeof params]
      ? state.products.byID[params['productID' as keyof typeof params]]
      : null
  );
  // Special case
  // If:
  // - active organization is passthrough supplier
  // - we are on the booking form
  // - product supplier does not match the active organization supplier
  // Then:
  // - automatically switch to the passthrough agent
  React.useEffect(() => {
    const activeOrganizationType = alternateOrganizationIsActive
      ? activeAccount?.alternate_organization_type
      : activeAccount?.organization_type;
    const activeOrganizationId = alternateOrganizationIsActive
      ? activeAccount?.alternate_organization_id
      : activeAccount?.organization_id;

    if (
      bookingFormIsActive &&
      bookingFormProduct &&
      activeOrganizationType === 'SUPPLIER' &&
      activeOrganizationId !== bookingFormProduct?.supplier_id
    ) {
      if (alternateOrganizationIsActive) {
        dispatch(setAlternateOrganizationIsActive(false));
      } else {
        dispatch(setAlternateOrganizationIsActive(true));
      }
    }
  }, [
    bookingFormIsActive,
    bookingFormProduct,
    alternateOrganizationIsActive,
    activeAccount?.organization_type,
  ]);
  return (
    <label className={styles['base-header__info__lang']}>
      <select
        name="alternateAccount"
        value={activeOrg}
        onChange={(e) => {
          if (e.target.value === 'PRIMARY') {
            dispatch(setAlternateOrganizationIsActive(false));
          } else {
            dispatch(setAlternateOrganizationIsActive(true));
          }

          history.push('/reservations');
        }}
      >
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </select>
    </label>
  );
};
