import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { ModalLoader } from 'client/components/ModalLoader';
import { ProductInstanceModal } from 'client/components/ProductInstanceModal/ProductInstanceModal';
import { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import { Box } from 'client/components/Box/Box';
import {
  fetchProductInstanceByID,
  fetchProductInstancesForProducts,
} from 'client/actions/productInstances';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import {
  ProductInstanceShape,
  toProductInstanceShape,
} from 'client/libraries/util/productInstanceShape';
import {
  ProductInstance,
  ProductDateAvailability,
  StartTimeAvailability,
} from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import stopIcon from 'client/images/ic_stop.svg';
import humanIcon from 'client/images/ic_human.svg';
import rqIcon from 'client/images/ic_rq.svg';
import productDetailIcon from 'client/images/ic_productsDetail.svg';
import linkIcon from 'client/images/ic_link.svg';

import { Checkbox } from './Checkbox';
import { CalendarEditContext } from './CalendarEditContext';
import {
  getAvailabilityBackgroundColorClass,
  getAvailabilityIconType,
  ProductWithOccupancy,
} from './util';
import styles from './AvailabilityCustomTable.module.css';
import componentStyles from './components.module.css';

const getDateHeader = (
  column: CustomTableColumn,
  locale: string,
  dayMonthFormat: string
) => {
  const dateMoment = moment(column.accessor).locale(locale);
  return (
    <span className={clsx(baseStyles['product-calendar-table-date-header'])}>
      <div>{`${dateMoment.format(dayMonthFormat)} (${dateMoment.format(
        'ddd'
      )})`}</div>
    </span>
  );
};

const ProductBlock = ({
  item,
  visibleItems,
  columns,
  onEditProductInstanceClick,
}: {
  item: ProductWithOccupancy;
  visibleItems: ProductWithOccupancy[];
  columns: CustomTableColumn[];
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
}) => {
  const allProducts = useSelector(summariesSelector);
  const availabilityLoading = useSelector(
    (state: ReduxState) => state.productAvailability.loading
  );
  const { t } = useTranslation();

  const dynamicPackageComponentProductIdToPackageProductNameMap =
    allProducts.reduce((acc, product) => {
      if (product.is_dynamic_package_product?.value) {
        product.shared_allotment_references?.package_component_product_ids?.forEach(
          (id) => {
            acc[id] = [
              ...(acc[id] ?? []),
              { id: product.id, names: product.product_name ?? '' },
            ];
          }
        );
      }
      return acc;
    }, {} as Record<string, { id: string; names: string }[]>);

  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = React.useContext(CalendarEditContext);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div className={clsx(componentStyles['c-table-availability__tbody__box'])}>
      <div
        className={clsx(
          componentStyles['c-table-availability__tbody__box__left']
        )}
      >
        <div
          className={clsx(baseStyles['base-t-48'], styles['product-icon-cell'])}
        >
          <Link
            to={`/products/${item.id}`}
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Detail')}
          >
            <img src={productDetailIcon} />
          </Link>
        </div>
        <div
          className={clsx(
            baseStyles['base-t-320'],
            styles['product-name-cell']
          )}
        >
          <div>
            <Link to={`/products/${item.id}`} data-text={t('Detail')}>
              {getDisplayProductName(allProducts.find((p) => p.id == item.id))}
            </Link>
            {item.childProducts.length > 0 && (
              <div>
                <ul>
                  {item.childProducts.map((childProduct) => (
                    <Link
                      key={childProduct.id}
                      to={`/products/${childProduct.id}`}
                      data-text={t('Detail')}
                    >
                      <li>
                        <span
                          className={clsx(
                            baseStyles['product-calendar-child-name']
                          )}
                        >
                          - <img src={linkIcon} width="10" />{' '}
                          {childProduct.name}
                        </span>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            )}
            <div>
              <ul>
                {dynamicPackageComponentProductIdToPackageProductNameMap[
                  item.id
                ]?.map(({ id, names }) => (
                  <Link to={`/products/${id}`} key={id} data-text={t('Detail')}>
                    <li>
                      <span
                        className={clsx(
                          baseStyles['product-calendar-child-name']
                        )}
                      >
                        - <img src={linkIcon} width="10" /> {names}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          componentStyles['c-table-availability__tbody__box__right']
        )}
      >
        <table className={clsx(componentStyles['availability__table'])}>
          <tbody>
            <tr className={clsx(componentStyles['availability__table'])}>
              <th
                className={clsx(
                  baseStyles['base-t-80'],
                  componentStyles['left'],
                  componentStyles['spFixed'],
                  componentStyles['availability__table'],
                  styles['mobile-start-time-header']
                )}
                key={0}
              >
                {t('Start Times')}
              </th>
              {columns.map((column, idx) => {
                const columnProductInstanceIds: string[] = [];

                for (const item of visibleItems) {
                  for (const availability of item.startTimeAvailabilities) {
                    const productInstanceId =
                      availability.date_availabilities?.find(
                        (dateAvailability) =>
                          dateAvailability.date === column.accessor
                      )?.product_instance_id;

                    if (productInstanceId) {
                      columnProductInstanceIds.push(productInstanceId);
                    }
                  }
                }

                const columnIsChecked = columnProductInstanceIds.every((id) =>
                  selectedProductInstanceIds.includes(id)
                );

                return (
                  <th key={idx + 1} className={baseStyles['base-t-80']}>
                    {getDateHeader(column, locale, dayMonthFormat)}
                    {calendarEditModeIsActive && (
                      <Box display="flex" justifyContent="center">
                        <Box width="32px">
                          <Checkbox
                            checked={columnIsChecked}
                            onChange={() => {
                              if (columnIsChecked) {
                                deselectProductInstanceIds(
                                  ...columnProductInstanceIds
                                );
                              } else {
                                selectProductInstanceIds(
                                  ...columnProductInstanceIds
                                );
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </th>
                );
              })}
            </tr>
            {availabilityLoading ? (
              <tr className={clsx(componentStyles['availability__table'])}>
                <td className={clsx(componentStyles['availability__table'])}>
                  <ModalLoader />
                </td>
              </tr>
            ) : item.startTimeAvailabilities.length === 0 ? (
              <StartTimeBlankBlock columns={columns} />
            ) : (
              item.startTimeAvailabilities.map((s, idx) => {
                return (
                  <StartTimeBlock
                    item={item}
                    s={s}
                    key={idx}
                    columns={columns}
                    onEditProductInstanceClick={onEditProductInstanceClick}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StartTimeBlankBlock = ({ columns }: { columns: CustomTableColumn[] }) => {
  return (
    <>
      <tr className={clsx(componentStyles['availability__table'])}>
        <td
          className={clsx(
            baseStyles['base-t-80'],
            componentStyles['left'],
            componentStyles['spFixed'],
            componentStyles['availability__table'],
            styles['start-time-cell']
          )}
        ></td>

        {columns.map((column, idx) => {
          return (
            <>
              <td
                className={clsx(
                  componentStyles['availability__table'],
                  baseStyles['base-t-80']
                )}
                key={idx}
              >
                <a className={clsx(baseStyles['base-t-80'])}></a>
              </td>
            </>
          );
        })}
      </tr>
    </>
  );
};

const StartTimeBlock = ({
  item,
  s,
  columns,
  onEditProductInstanceClick,
}: {
  item: ProductWithOccupancy;
  s: StartTimeAvailability;
  columns: CustomTableColumn[];
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
}) => {
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = React.useContext(CalendarEditContext);

  return (
    <>
      <tr className={clsx(componentStyles['availability__table'])}>
        <td
          className={clsx(
            baseStyles['base-t-80'],
            componentStyles['left'],
            componentStyles['spFixed'],
            componentStyles['availability__table'],
            styles['start-time-cell']
          )}
        >
          <Box display="flex" alignItems="center">
            <div>{s.start_time_local}</div>
            {calendarEditModeIsActive && (
              <Box ml="auto">
                <Checkbox
                  checked={
                    s.date_availabilities?.every((availability) =>
                      selectedProductInstanceIds.includes(
                        availability.product_instance_id ?? ''
                      )
                    ) ?? false
                  }
                  onChange={() => {
                    const checked = s.date_availabilities?.every(
                      (availability) =>
                        selectedProductInstanceIds.includes(
                          availability.product_instance_id ?? ''
                        )
                    );
                    if (checked) {
                      deselectProductInstanceIds(
                        ...(s.date_availabilities?.map(
                          (availability) =>
                            availability.product_instance_id ?? ''
                        ) ?? [])
                      );
                    } else {
                      selectProductInstanceIds(
                        ...(s.date_availabilities?.map(
                          (availability) =>
                            availability.product_instance_id ?? ''
                        ) ?? [])
                      );
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        </td>

        {columns.map((column, idx) => {
          return (
            <StartTimeCell
              item={item}
              column={column}
              s={s}
              key={idx}
              onEditProductInstanceClick={onEditProductInstanceClick}
            />
          );
        })}
      </tr>
    </>
  );
};

const StartTimeCell = ({
  item,
  s,
  column,
  onEditProductInstanceClick,
}: {
  item: ProductWithOccupancy;
  s: StartTimeAvailability;
  column: CustomTableColumn;
  onEditProductInstanceClick: (params: ProductInstanceModalParams) => void;
}) => {
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = React.useContext(CalendarEditContext);
  const availability = s.date_availabilities?.find(
    (availability) => availability.date === column.accessor
  );
  return (
    <>
      <td
        className={clsx(
          componentStyles['availability__table'],
          baseStyles['base-t-80']
        )}
      >
        {availability ? (
          <StartTimeCellAnchorTag
            item={item}
            availability={availability}
            onClick={() => {
              if (calendarEditModeIsActive) {
                if (
                  selectedProductInstanceIds.includes(
                    availability?.product_instance_id ?? ''
                  )
                ) {
                  deselectProductInstanceIds(
                    availability?.product_instance_id ?? ''
                  );
                } else {
                  selectProductInstanceIds(
                    availability?.product_instance_id ?? ''
                  );
                }
              } else {
                onEditProductInstanceClick({
                  product: item,
                  startTime: s,
                  column: column,
                  availability: availability,
                });
              }
            }}
          />
        ) : (
          <p className={clsx(componentStyles['no-allotment'])}>-</p>
        )}
      </td>
    </>
  );
};

const StartTimeCellAnchorTag = ({
  item,
  availability,
  onClick,
}: {
  item: ProductWithOccupancy;
  availability: ProductDateAvailability;
  onClick: () => void;
}) => {
  const { calendarEditModeIsActive, selectedProductInstanceIds } =
    React.useContext(CalendarEditContext);

  const formattedOccupancy = `${
    availability.occupied_slots ?? 0
  } / ${formattedTotalAllotmentSlots(availability.total_slots ?? 0)}`;

  const Icon = () => {
    const iconType = getAvailabilityIconType(
      availability.occupied_slots ?? 0,
      availability.total_slots ?? 0,
      availability.all_channels_are_closed ?? false,
      item.shouldRejectRequestBookingsBeyondCapacity ||
        availability.rejects_request_booking_beyond_capacity,
      availability?.booking_deadlines ?? []
    );

    if (iconType === 'STOP') {
      return (
        <img
          className={componentStyles['availability__table']}
          src={stopIcon}
        />
      );
    }

    if (iconType === 'REQUEST') {
      return (
        <img className={componentStyles['availability__table']} src={rqIcon} />
      );
    }

    return null;
  };

  const backgroundColorClassName = getAvailabilityBackgroundColorClass(
    availability.occupied_slots ?? 0,
    availability.total_slots ?? 0,
    availability.all_channels_are_closed ?? false
  );

  return (
    <a
      className={clsx(
        baseStyles['base-t-80'],
        backgroundColorClassName &&
          componentStyles[
            backgroundColorClassName as keyof typeof componentStyles
          ],
        calendarEditModeIsActive &&
          (selectedProductInstanceIds.includes(
            availability.product_instance_id ?? ''
          )
            ? styles['selected-cell']
            : styles['deselected-cell'])
      )}
      onClick={onClick}
    >
      <Icon />
      {formattedOccupancy}
    </a>
  );
};

type Props = {
  items: ProductWithOccupancy[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  setEditingProductInstance: (arg0: any) => any;
  setReservationListContext: (arg0: any) => any;
  onPageSizeChange: (arg0: number) => any;
  onCurrentPageChange: (arg0: number) => any;
  totalPaxByDate: Record<string, number>;
};
export type CustomTableColumn = {
  accessor: string;
};
type ProductInstanceModalParams = {
  product: ProductWithOccupancy;
  startTime: StartTimeAvailability;
  column: CustomTableColumn;
  availability: ProductDateAvailability;
};
export const AvailabilityCustomTable = ({
  items,
  columns,
  usePaging,
  setEditingProductInstance,
  setReservationListContext,
  onPageSizeChange,
  onCurrentPageChange,
  totalPaxByDate,
}: Props) => {
  const tableHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const {
    calendarEditModeIsActive,
    selectedProductInstanceIds,
    selectProductInstanceIds,
    deselectProductInstanceIds,
  } = React.useContext(CalendarEditContext);
  const [productInstanceModalParams, setProductInstanceModalParams] =
    React.useState<ProductInstanceModalParams | null>(null);
  const [openProductInstanceModal, setOpenProductInstanceModal] =
    React.useState<boolean>(false);

  const allProducts = useSelector(summariesSelector);
  const dispatch = useDispatch();

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const shouldHideScrollButtons = columns.length < 8;

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );
  const clickedProductInstance: ProductInstance | null = React.useMemo(
    () =>
      productInstancesById[
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      ] ?? null,
    [
      productInstancesById,
      productInstanceModalParams?.availability?.product_instance_id,
    ]
  );
  const clickedProductInstanceShape: ProductInstanceShape | null =
    React.useMemo(
      () =>
        clickedProductInstance
          ? toProductInstanceShape(clickedProductInstance)
          : null,
      [clickedProductInstance]
    );

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const defaultRowCount = usePaging ? 10 : 10000;
  const [rowCount, setRowCount] = React.useState(defaultRowCount);
  const [currentPage, setCurrentPage] = React.useState(1);
  React.useEffect(() => {
    onPageSizeChange(rowCount);
  }, [rowCount]);
  React.useEffect(() => {
    onCurrentPageChange(currentPage - 1);
  }, [currentPage]);

  const rowCountChangeHandler = (event: any) => {
    const count = parseInt(event.target.value, 10);
    setRowCount(count);
    setCurrentPage(1);
  };

  const currentPageHandler = (event: any) => {
    let page = parseInt(event.target.value, 10);

    if (page < 1) {
      page = 1;
    }

    if (items.length < rowCount * page) {
      page = Math.floor(items.length / rowCount);
    }

    setCurrentPage(page);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [rowCount]);
  React.useEffect(() => {
    window.location.href = '#root';
  }, [currentPage]);
  React.useEffect(() => {
    dispatch(
      fetchProductInstanceByID(
        productInstanceModalParams?.availability?.product_instance_id ?? ''
      )
    );
  }, [productInstanceModalParams?.availability?.product_instance_id]);

  // ProductInstanceModal relies on child product instances existing in redux state in order to show these
  // reservations in the reservation list modal.
  React.useEffect(() => {
    if (clickedProductInstance) {
      const product = productSummaries.find(
        (product) => product.id === clickedProductInstance.product_id
      );

      const childProductIds =
        product?.shared_allotment_references?.child_product_ids ?? [];
      if (childProductIds.length > 0) {
        const startDate = moment.parseZone(
          clickedProductInstance.start_date_time_local
        );
        const endDate = moment
          .parseZone(clickedProductInstance.start_date_time_local)
          .add(1, 'days');
        dispatch(
          fetchProductInstancesForProducts(childProductIds, startDate, endDate)
        );
      }
    }
  }, [clickedProductInstance, productSummaries]);

  const pageCount =
    Math.floor(items.length / rowCount) + (items.length % rowCount ? 1 : 0);
  const rowHead = rowCount * (currentPage - 1);

  const visibleItems = items.filter((item, index) => {
    if (index < rowHead) {
      return false;
    }

    if (rowHead + rowCount <= index) {
      return false;
    }

    return true;
  });

  const pageForwardClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pageBackClickHandler = () => {
    // NOTE(goro) show the top of table when the next/prv button is clicked
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getTotalPaxHeader = (column: CustomTableColumn) => {
    return (
      <>
        {typeof totalPaxByDate[column.accessor] === 'number' ? (
          <a className={clsx(componentStyles['total'])}>
            <div>
              <img src={humanIcon} />
            </div>
            {t('{{count}} pax', {
              count: totalPaxByDate[column.accessor],
            })}
          </a>
        ) : (
          <ModalLoader />
        )}
      </>
    );
  };

  return (
    <>
      <div className={clsx(componentStyles['c-table-availability'])}>
        <div
          className={clsx(componentStyles['c-table-availability__thead'])}
          ref={tableHeaderRef}
        >
          <div
            className={clsx(
              componentStyles['c-table-availability__thead__left']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-t-48'],
                styles['product-icon-header']
              )}
            ></div>
            <div
              className={clsx(
                baseStyles['base-t-320'],
                styles['product-name-header']
              )}
            >
              <p>{t('Product name')}</p>
            </div>
          </div>
          <div
            className={clsx(
              componentStyles['c-table-availability__thead__right']
            )}
          >
            <table>
              <tbody>
                <tr>
                  <th
                    className={clsx(
                      baseStyles['base-t-80'],
                      componentStyles['left'],
                      componentStyles['spFixed'],
                      styles['start-time-header']
                    )}
                    key={0}
                  >
                    {t('Start Times')}
                  </th>
                  {columns.map((column, idx) => {
                    const columnProductInstanceIds: string[] = [];

                    for (const item of visibleItems) {
                      for (const availability of item.startTimeAvailabilities) {
                        const productInstanceId =
                          availability.date_availabilities?.find(
                            (dateAvailability) =>
                              dateAvailability.date === column.accessor
                          )?.product_instance_id;

                        if (productInstanceId) {
                          columnProductInstanceIds.push(productInstanceId);
                        }
                      }
                    }

                    const columnIsChecked = columnProductInstanceIds.every(
                      (id) => selectedProductInstanceIds.includes(id)
                    );

                    return (
                      <th key={idx + 1} className={baseStyles['base-t-80']}>
                        <div>
                          {getDateHeader(column, locale, dayMonthFormat)}
                        </div>
                        {calendarEditModeIsActive && (
                          <Box display="flex" justifyContent="center" mt={1}>
                            <Box>
                              <Checkbox
                                checked={columnIsChecked}
                                onChange={() => {
                                  if (columnIsChecked) {
                                    deselectProductInstanceIds(
                                      ...columnProductInstanceIds
                                    );
                                  } else {
                                    selectProductInstanceIds(
                                      ...columnProductInstanceIds
                                    );
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </th>
                    );
                  })}
                </tr>
                <tr>
                  <th
                    className={clsx(
                      baseStyles['base-t-80'],
                      componentStyles['left'],
                      componentStyles['spFixed'],
                      styles['pax-count-header']
                    )}
                    key={0}
                  >
                    {t('Total Pax')}
                  </th>
                  {columns.map((column, idx) => {
                    return (
                      <th key={idx + 1} className={baseStyles['base-t-80']}>
                        {getTotalPaxHeader(column)}
                      </th>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={clsx(componentStyles['c-table-availability__tbody'])}
          onScroll={scrollHandler}
          ref={tableBodyRef}
        >
          <div className={styles['scroll-wrapper']}>
            {visibleItems.map((item, idx) => {
              return (
                <ProductBlock
                  item={item}
                  key={idx}
                  columns={columns}
                  visibleItems={visibleItems}
                  onEditProductInstanceClick={(
                    params: ProductInstanceModalParams
                  ) => {
                    setProductInstanceModalParams(params);
                    setOpenProductInstanceModal(true);
                  }}
                />
              );
            })}
          </div>
        </div>

        {!shouldHideScrollButtons && (
          <>
            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['prev']
              )}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <p></p>
            </div>

            <div
              className={clsx(
                componentStyles['c-table-main__btn'],
                componentStyles['next']
              )}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <p></p>
            </div>
          </>
        )}
      </div>

      {productInstanceModalParams &&
        clickedProductInstance &&
        clickedProductInstanceShape && (
          <ProductInstanceModal
            insertRoot
            productName={getDisplayProductName(
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )
            )}
            allotmentSettings={
              productInstanceModalParams.product.allotmentSettings
            }
            timezone={productInstanceModalParams.product.timezone}
            onEditClick={setEditingProductInstance}
            onListClick={() =>
              setReservationListContext({
                productIds: [
                  productInstanceModalParams.product.id,
                  ...productInstanceModalParams.product.childProducts.map(
                    (p) => p.id
                  ),
                ],
                rootProductInstanceId:
                  productInstanceModalParams.availability.product_instance_id ??
                  0,
                date: productInstanceModalParams.column.accessor,
              })
            }
            productInstance={clickedProductInstance}
            instance={clickedProductInstanceShape}
            open={openProductInstanceModal}
            onClose={() => {
              setOpenProductInstanceModal(false);
            }}
            shouldRejectBookingsBeyondCapacity={
              allProducts.find(
                (p) => p.id == productInstanceModalParams.product.id
              )?.request_booking_settings
                ?.should_reject_bookings_beyond_capacity ?? false
            }
          />
        )}

      {usePaging && (
        <>
          <div className={clsx(componentStyles['c-pagination'])}>
            <div className={clsx(componentStyles['c-pagination__size'])}>
              <p>{t('Number of lines')}:</p>
              <label>
                <select value={rowCount} onChange={rowCountChangeHandler}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="50">50</option>
                </select>
              </label>
            </div>
            <div className={clsx(componentStyles['c-pagination__jump'])}>
              <p>{t('Page')}:</p>
              <input
                type="number"
                value={currentPage}
                onChange={currentPageHandler}
              />
              <p>of {pageCount}</p>
            </div>
            <div className={clsx(componentStyles['c-pagination__btns'])}>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  1 < currentPage ? '' : componentStyles['disable']
                )}
                onClick={pageBackClickHandler}
              >
                {t('Previous')}
              </a>
              <a
                className={clsx(
                  componentStyles['c-pagination__btn'],
                  currentPage < pageCount ? '' : componentStyles['disable']
                )}
                onClick={pageForwardClickHandler}
              >
                {t('Next')}
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};
