// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { getValidators } from 'shared/libraries/validate/validator';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { PricesEditor } from 'client/pages/ProductEditor/BasicEditor/PricesEditor';
import { Button, TextArea, Input } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import {
  getInitialValues,
  convertFormValuesToProductPatch,
} from 'client/pages/ProductEditor/BasicEditor/FormValues';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

type Props = {
  active: boolean,
};

export const BasicEditor = ({ active }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  const editingProduct = React.useContext(EditingProductContext);

  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );

  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);

  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const [success, setSuccess] = React.useState<boolean>(false);

  React.useEffect(() => setSuccess(false), [active]);

  const initialValues = React.useMemo(
    () => getInitialValues(editingProduct),
    [editingProduct]
  );

  return (
    <ProductEditorForm
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={(values) =>
        convertFormValuesToProductPatch(values, defaultCurrency)
      }
    >
      {({ submitError, submitting }) => (
        <>
          <FormTableBox>
            <table>
              <tbody>
                {translationTargetLanguage && <TranslationTableHeader />}
                <tr>
                  <th>{t('Product Code')}</th>
                  <td className={styles['colspan']} colspan="2">
                    <Field name="productCode" validate={required}>
                      {({ input }) => input.value}
                    </Field>
                  </td>
                </tr>
                <TranslatedField name="internalProductName" validate={required}>
                  {({ input, meta: { touched, error }, translationInput }) => (
                    <tr>
                      <th>{t('Internal Product Name')}</th>
                      <td colspan={translationTargetLanguage ? 1 : 2}>
                        <Input
                          {...input}
                          type="text"
                          maxWidth={800}
                          maxLength={50}
                          error={touched && error}
                        />
                      </td>
                      {translationTargetLanguage && (
                        <td className={clsx(baseStyles['base-translate-cell'])}>
                          <Input
                            {...translationInput}
                            maxWidth={800}
                            height={80}
                          />
                        </td>
                      )}
                    </tr>
                  )}
                </TranslatedField>
                <TranslatedField name="productName" validate={required}>
                  {({ input, meta: { touched, error }, translationInput }) => (
                    <tr>
                      <th>{t('Product Name')}</th>
                      <td colspan={translationTargetLanguage ? 1 : 2}>
                        <Input
                          {...input}
                          type="text"
                          maxWidth={800}
                          error={touched && error}
                        />
                      </td>
                      {translationTargetLanguage && (
                        <td className={clsx(baseStyles['base-translate-cell'])}>
                          <Input
                            {...translationInput}
                            maxWidth={800}
                            height={80}
                          />
                        </td>
                      )}
                    </tr>
                  )}
                </TranslatedField>
                <TranslatedField name="productDescription">
                  {({ input, translationInput }) => (
                    <tr>
                      <th>{t('Product Description')}</th>
                      <td colspan={translationTargetLanguage ? 1 : 2}>
                        <TextArea
                          {...input}
                          maxWidth={800}
                          showCharacterCount
                          // TODO: remove later
                          label="label"
                          tooltipText="tooltipText"
                        />
                      </td>
                      {translationTargetLanguage && (
                        <td
                          className={clsx(
                            baseStyles['base-translate-cell'],
                            translationTargetLanguage &&
                              baseStyles['is-display']
                          )}
                        >
                          <TextArea
                            {...translationInput}
                            maxWidth={800}
                            showCharacterCount
                          />
                        </td>
                      )}
                    </tr>
                  )}
                </TranslatedField>
                <tr>
                  <th>{t('Images/Videos')}</th>
                  <td colspan={2}>
                    <div>
                      <Field name="mediaUrls">
                        {({ input }) => (
                          <ImageVideoAudioInput
                            fileUrls={input.value || []}
                            onChange={(newValue) => input.onChange(newValue)}
                          />
                        )}
                      </Field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t('Prices')}</th>
                  <td colspan={2}>
                    <PricesEditor />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={baseStyles['base-main__box__body__bottomBtns']}>
              <Button
                loading={submitting}
                type="submit"
                style="blue"
                size="small"
              >
                {t('Save')}
              </Button>
            </div>
            {success && <Message success header={t('Save Successful')} />}
            {submitError && <Message error header={t('Save Failed')} />}
          </FormTableBox>
        </>
      )}
    </ProductEditorForm>
  );
};
