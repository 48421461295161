import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { Modal } from 'client/components/Modal/Modal';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import { operationAllowed } from 'shared/models/access';
import { activeUserSelector } from 'client/reducers/user';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import { ToggleableDndList } from '../ToggleableDndList/ToggleableDndList';

import {
  getReservationCSVColumns,
  csvColumnCandidatesSelector,
  piiItems,
} from './util';

type Props = {
  trigger: React.ReactElement<any>;
  downloadItems: string[];
  setDownloadItems: (arg0: string[]) => void;
  onApply: (arg0: string[]) => void;
};
export const DownloadReservationsCSVModal = ({
  trigger,
  downloadItems,
  setDownloadItems,
  onApply,
}: Props) => {
  const { t } = useTranslation();
  const allColumns = getReservationCSVColumns(t);
  const activeUser = useSelector(activeUserSelector);

  const userCanViewInternalSupplierNotes = operationAllowed(
    activeUser,
    'write',
    'reservationConfirmation'
  );

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask
      .filter((c) => {
        if (userCanViewInternalSupplierNotes) {
          return true;
        }
        if (
          [
            'SUPPLIER_INTERNAL_NOTES',
            'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
          ].includes(c)
        ) {
          return false;
        }
        return true;
      })
      .map((c) => allColumns.find((col) => col.id === c) as any);
  };

  const columnCandidates = useSelector((state: ReduxState) =>
    csvColumnCandidatesSelector(state)
  );
  const columnsToShow = getColumns(downloadItems);
  const allCandidateColumns = getColumns(columnCandidates);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Download Items')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <a
          style={{ color: '#008ec9', fontSize: '1.1em', fontWeight: 'bold' }}
          onClick={() => {
            setDownloadItems(allCandidateColumns.map((c) => c.id));
          }}
        >
          {t('Select all')}
        </a>{' '}
        /{' '}
        <a
          style={{ color: '#008ec9', fontSize: '1.1em', fontWeight: 'bold' }}
          onClick={() => {
            setDownloadItems([]);
          }}
        >
          {t('Clear')}
        </a>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={columnsToShow.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                setSelectedItems={(items) =>
                  setDownloadItems(items.map((item) => item.key))
                }
                candidateItems={allCandidateColumns.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                piiItems={piiItems}
              />
            </div>
          </div>
        </div>
        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['middle'],
              baseStyles['blue']
            )}
            onClick={() => {
              onApply(downloadItems);
              setShowModal(false);
            }}
          >
            {t('Apply')}
          </a>
        </div>
      </div>
    </Modal>
  );
};
