import * as React from 'react';

import { ProductList } from '../util';

import { BaseTemplate } from './BaseTemplate';
import { CustomerGreetingsSection } from './section/CustomerGreetingsSection';
import { PromoSection } from './section/PromoSection';

export interface Props {
  editMode?: boolean;
  customerName?: string;
  newsletterCampaignId?: string;
  topPageUrl: string;
  unsubscribeUrl: string;
  language: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  customerIntroduction: string;
  introParagraph: string;
  eyeCatchText: string;
  keyVisualUrl: string;
  promoLeadText: string;
  promoDiscountText: string;
  promoDescription: string;
  promoPeriodText: string;
  periodText: string;
  productList: ProductList;
  inquiryHtml: string;
  unsubscribeText: string;
  poweredByText: string;
  twitterId: string;
  instagramId: string;
  youtubeId: string;
  facebookId: string;
  lineId: string;
}

export const AutoPromo = ({
  editMode,
  customerName,
  newsletterCampaignId,
  topPageUrl,
  unsubscribeUrl,
  language,
  logoUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  customerIntroduction,
  introParagraph,
  eyeCatchText,
  keyVisualUrl,
  promoLeadText,
  promoDiscountText,
  promoDescription,
  promoPeriodText,
  periodText,
  productList,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl,
        newsletterCampaignId,
        unsubscribeUrl,
        topPageUrl,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        inquiryHtml,
        unsubscribeText,
        poweredByText,
        twitterId,
        instagramId,
        youtubeId,
        facebookId,
        lineId,
        language,
      }}
    >
      {/* Customer greetings お客様向けメッセージ */}
      <CustomerGreetingsSection
        data={{
          editMode: editMode ?? false,
          customerName: customerName ?? '',
          customerIntroduction: customerIntroduction,
          introParagraph: introParagraph,
        }}
      />
      {/* Space */}
      <tr style={{ margin: 0, padding: 0 }}>
        <td style={{ height: '1em' }} />
      </tr>
      {/* Promo */}
      <PromoSection
        data={{
          editMode: editMode ?? false,
          keyVisualUrl,
          eyeCatchText,
          promoLeadText,
          promoDiscountText,
          promoDescription,
          promoPeriodText,
          periodText,
          productList,
          newsletterCampaignId: newsletterCampaignId ?? '',
          language,
        }}
        isPromoCode={false}
      />
    </BaseTemplate>
  );
};
