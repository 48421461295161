import type { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';

import {
  UPDATE_TRANSLATIONS_FAILURE,
  UPDATE_TRANSLATIONS_REQUEST,
  UPDATE_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FOR_TEXTS_FAILURE,
  GET_TRANSLATIONS_FOR_TEXTS_REQUEST,
  GET_TRANSLATIONS_FOR_TEXTS_SUCCESS,
  FETCH_PRODUCT_TRANSLATIONS_REQUEST,
  FETCH_PRODUCT_TRANSLATIONS_SUCCESS,
  FETCH_PRODUCT_TRANSLATIONS_FAILURE,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE,
  BATCH_GET_TRANSLATIONS_FAILURE,
  BATCH_GET_TRANSLATIONS_REQUEST,
  BATCH_GET_TRANSLATIONS_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const updateTranslationsRequest = (translations: Swagger.Translation[]) => ({
  type: UPDATE_TRANSLATIONS_REQUEST,
  payload: translations,
});

const updateTranslationsSuccess = (response: any) => ({
  type: UPDATE_TRANSLATIONS_SUCCESS,
  response,
});

const updateTranslationsFailure = (error: string) => ({
  type: UPDATE_TRANSLATIONS_FAILURE,
  error,
});

export const updateTranslations =
  (translations: Swagger.Translation[]) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateTranslationsRequest(translations));
    await fetch('/api/translations', {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(translations),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(updateTranslationsSuccess(result)))
      .catch((error) => dispatch(updateTranslationsFailure(error)));
  };

const batchGetTranslationsRequest = () => ({
  type: BATCH_GET_TRANSLATIONS_REQUEST,
});

const batchGetTranslationsSuccess = (response: any) => ({
  type: BATCH_GET_TRANSLATIONS_SUCCESS,
  response,
});

const batchGetTranslationsFailure = (error: string) => ({
  type: BATCH_GET_TRANSLATIONS_FAILURE,
  error,
});

export const batchGetTranslations =
  (keys: string[]) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchGetTranslationsRequest());
    axios
      .post('/api/translations/batchGet', keys, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(batchGetTranslationsSuccess(result.data)))
      .catch((error) => dispatch(batchGetTranslationsFailure(error)));
  };

const getTranslationsForTextsRequest = () => ({
  type: GET_TRANSLATIONS_FOR_TEXTS_REQUEST,
});

const getTranslationsForTextsSuccess = (response: any) => ({
  type: GET_TRANSLATIONS_FOR_TEXTS_SUCCESS,
  response,
});

const getTranslationsForTextsFailure = (error: string) => ({
  type: GET_TRANSLATIONS_FOR_TEXTS_FAILURE,
  error,
});

export const getTranslationsForTexts =
  (texts: string[], target: 'en' | 'ja' | 'zh-CN' | 'zh-TW' | 'ko') =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(getTranslationsForTextsRequest());
    fetch('/api/translations/translate', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({
        texts,
        target,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(getTranslationsForTextsSuccess(result)))
      .catch((error) => dispatch(getTranslationsForTextsFailure(error)));
  };

const fetchProductTranslationsRequest = () => ({
  type: FETCH_PRODUCT_TRANSLATIONS_REQUEST,
});

const fetchProductTranslationsSuccess = (response: any) => ({
  type: FETCH_PRODUCT_TRANSLATIONS_SUCCESS,
  response,
});

const fetchProductTranslationsFailure = (error: string) => ({
  type: FETCH_PRODUCT_TRANSLATIONS_FAILURE,
  error,
});

let fetchProductTranslationsCancel: () => void | typeof undefined;
export const fetchProductTranslations =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchProductTranslationsCancel?.();
    dispatch(fetchProductTranslationsRequest());
    axios
      .get(`/api/products/${id}/translations`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchProductTranslationsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchProductTranslationsFailure('canceled'));
        else dispatch(fetchProductTranslationsFailure(error));
      });
  };

const fetchWaiverTemplateTranslationsRequest = () => ({
  type: FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST,
});

const fetchWaiverTemplateTranslationsSuccess = (response: any) => ({
  type: FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS,
  response,
});

const fetchWaiverTemplateTranslationsFailure = (error: string) => ({
  type: FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE,
  error,
});

let fetchWaiverTemplateTranslationsCancel: () => void | typeof undefined;
export const fetchWaiverTemplateTranslations =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchWaiverTemplateTranslationsCancel?.();
    dispatch(fetchWaiverTemplateTranslationsRequest());
    axios
      .get(`/api/waivertemplates/${id}/translations`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(fetchWaiverTemplateTranslationsSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchWaiverTemplateTranslationsFailure('canceled'));
        else dispatch(fetchWaiverTemplateTranslationsFailure(error));
      });
  };

const fetchSurveyTemplateTranslationsRequest = () => ({
  type: FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST,
});

const fetchSurveyTemplateTranslationsSuccess = (response: any) => ({
  type: FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS,
  response,
});

const fetchSurveyTemplateTranslationsFailure = (error: string) => ({
  type: FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE,
  error,
});

let fetchSurveyTemplateTranslationsCancel: () => void | typeof undefined;
export const fetchSurveyTemplateTranslations =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchSurveyTemplateTranslationsCancel?.();
    dispatch(fetchSurveyTemplateTranslationsRequest());
    axios
      .get(`/api/surveytemplates/${id}/translations`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(fetchSurveyTemplateTranslationsSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSurveyTemplateTranslationsFailure('canceled'));
        else dispatch(fetchSurveyTemplateTranslationsFailure(error));
      });
  };
