import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fetchProductByID } from 'client/actions/products';
import { Loading } from 'client/pages/Loading';
import { EditProductAgentReferenceForm } from 'client/pages/EditProductContent/EditProductAgentReferenceForm';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

export const EditProductAgentReferences = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const product = useSelector(
    (state: ReduxState) => state.products.byID[id ?? '']
  );
  React.useEffect(() => {
    dispatch(fetchProductByID(id ?? ''));
  }, [id]);

  return !product ? (
    <Loading />
  ) : (
    <div>
      <div
        className={clsx(
          baseStyles['base-flex'],
          baseStyles['base-margin-bottom-8']
        )}
      >
        <Link to={`/products/${product.id}`}>
          <BackArrow />
        </Link>
        <div className={baseStyles['base-main__headline__ttl']}>
          <h1>{`${product.product_name ?? ''} [${
            product.supplier_reference ?? ''
          }]`}</h1>
        </div>
      </div>
      <EditProductAgentReferenceForm product={product} />
    </div>
  );
};
