import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';

import { config } from 'client/config';
import {
  Button,
  OptionalIntegerInput,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDigitalMap } from 'client/actions/digitalMaps';
import { Delete } from 'client/components/Icons/Delete';
import { Add } from 'client/components/Icons/Add';
import { Edit } from 'client/components/Icons/Edit';
import { CopyToClipboard } from 'client/components/Icons/CopyToClipboard';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
  getDefaultPin,
} from './formValues';
import { DigitalMapPinEditModal } from './DigitalMapPinEditModal';

export const DigitalMapPinsEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [editingIdx, setEditingIdx] = React.useState<number | null>(null);

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateDigitalMap(id, convertFormValuesToSwagger(values))
        );
      }}
      initialValues={initialValues}
      debug={console.log}
      mutators={getArrayMutators()}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
      }) => (
        <div className={baseStyles['base-main__body__box']}>
          <div className={baseStyles['base-main__body__box__body']}>
            <form onSubmit={handleSubmit}>
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <FieldArray name="pins">
                {({ fields }) => (
                  <>
                    <Add
                      onClick={() =>
                        (fields as any).insertAt(0, getDefaultPin())
                      }
                    />
                    {(fields?.length ?? 0) > 0 && (
                      <table className={baseStyles['base-table']}>
                        <thead>
                          <th style={{ width: '240px' }}>{t('Title')}</th>
                          <th style={{ width: '144px' }}>{t('Category')}</th>
                          {!config.enableWaitTimePages && (
                            <th style={{ width: '144px' }}>{t('Wait Time')}</th>
                          )}
                          <th style={{ width: '144px' }} />
                        </thead>
                        <tbody>
                          {fields.map((pin, index) => (
                            <tr key={index}>
                              <td style={{ border: '1px solid #cfdae1' }}>
                                <Field name={`${pin}.title`}>
                                  {({ input }) => (
                                    <Box ml={4} mr={4} mt={2}>
                                      {input.value}
                                    </Box>
                                  )}
                                </Field>
                              </td>
                              <td>
                                <Field name={`${pin}.category`}>
                                  {({ input }) => (
                                    <Box ml={4} mr={4} mt={2}>
                                      {input.value}
                                    </Box>
                                  )}
                                </Field>
                              </td>
                              {!config.enableWaitTimePages && (
                                <td>
                                  <Field name={`${pin}.showWaitTime`}>
                                    {({ input }) => (
                                      <ToggleButton
                                        label={t('Show Wait Time')}
                                        checked={input.value}
                                        onChange={() =>
                                          input.onChange(!input.value)
                                        }
                                      />
                                    )}
                                  </Field>
                                  {fields.value[index].showWaitTime && (
                                    <Field name={`${pin}.waitTime`}>
                                      {({ input }) => (
                                        <OptionalIntegerInput
                                          label={t('Wait Time (minutes)')}
                                          value={input.value}
                                          onChange={(value) =>
                                            input.onChange(value)
                                          }
                                        />
                                      )}
                                    </Field>
                                  )}
                                </td>
                              )}
                              <td style={{ border: '1px solid #cfdae1' }}>
                                <Box ml={2} mr={2} display={'flex'}>
                                  <Edit
                                    onClick={() => {
                                      setEditingIdx(index);
                                    }}
                                  />
                                  <Delete
                                    onClick={() => {
                                      fields.remove(index);
                                    }}
                                  />
                                  <Add
                                    onClick={() => {
                                      (fields as any).insertAt(
                                        index + 1,
                                        getDefaultPin()
                                      );
                                    }}
                                  />
                                  <CopyToClipboard
                                    text={`${getBookingWidgetApiKeyUrl(
                                      activeUserOrganization?.booking_widget_api_key ??
                                        ''
                                    )}/maps/${existingDigitalMap?.id}/pins/${
                                      fields.value[index].key
                                    }
                                    `}
                                    tooltipText={t('Copy pin URL to clipboard')}
                                  />
                                </Box>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </FieldArray>

              {editingIdx != null && (
                <DigitalMapPinEditModal
                  editingIdx={editingIdx}
                  onClose={() => setEditingIdx(null)}
                />
              )}
              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Form>
  );
};
