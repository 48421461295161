export const composeValidators =
  <T>(...validators: ((val: T) => string | undefined)[]) =>
  (value: T): string | undefined => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }

    return undefined;
  };
