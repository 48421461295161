import * as React from 'react';

export interface CalendarEditParams {
  selectedProductInstanceIds: string[];
  selectProductInstanceIds: (...ids: string[]) => void;
  deselectProductInstanceIds: (...ids: string[]) => void;
  calendarEditModeIsActive: boolean;
  setCalendarEditModeIsActive: (b: boolean) => void;
}

// CalendarEditContext can be used to pass a the calendar edit information down the component tree.
export const CalendarEditContext = React.createContext<CalendarEditParams>({
  selectedProductInstanceIds: [],
  calendarEditModeIsActive: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCalendarEditModeIsActive: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectProductInstanceIds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deselectProductInstanceIds: () => {},
});
