import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import { getDateFilterPresetText } from 'client/libraries/util/searchWaivers';
import type { SearchWaiversRequest } from 'client/libraries/util/searchWaivers';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';

import styles from './WaiverSearchFiltersDisplayBox.module.css';

const formattedTotalHits = (totalHits: number): string => {
  if (totalHits === 10000) {
    return `${totalHits.toLocaleString()}+`;
  }

  return totalHits.toLocaleString();
};

type Props = {
  searchFilters: SearchWaiversRequest;
};
export const WaiverSearchFiltersDisplayBox = ({ searchFilters }: Props) => {
  const { t } = useTranslation();
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const totalHits = useSelector((state: ReduxState) => state.waivers.totalHits);
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (searchFilters.productIds?.length > 0) {
    filters.push({
      typeText: t('Products'),
      filterValue: searchFilters.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.agentReference) {
    filters.push({
      typeText: t('Application Number'),
      filterValue: searchFilters.agentReference,
    });
  }

  if (searchFilters.supplierReference) {
    filters.push({
      typeText: t('Confirmation Number'),
      filterValue: searchFilters.supplierReference,
    });
  }

  if (searchFilters.dateFilterPreset) {
    const preset = searchFilters.dateFilterPreset;
    filters.push({
      typeText: t('Date Preset'),
      filterValue: getDateFilterPresetText(preset, t),
    });
  }

  if (
    searchFilters.participationDateFrom ||
    searchFilters.participationDateTo
  ) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${searchFilters.participationDateFrom ?? ''} ~ ${
        searchFilters.participationDateTo
      }`,
    });
  }

  if (searchFilters.submittedDateFrom || searchFilters.submittedDateTo) {
    filters.push({
      typeText: t('Waiver Submission Date'),
      filterValue: `${searchFilters.submittedDateFrom ?? ''} ~ ${
        searchFilters.submittedDateTo
      }`,
    });
  }

  if (searchFilters.customerGivenName) {
    filters.push({
      typeText: t('Given Name'),
      filterValue: searchFilters.customerGivenName,
    });
  }

  if (searchFilters.customerFamilyName) {
    filters.push({
      typeText: t('Family Name'),
      filterValue: searchFilters.customerFamilyName,
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      <b>{t('Search Conditions')}</b>

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
          <li className={styles['total']}>
            <b>{t('Total: ')}</b>

            {t('{{count}} records', {
              count: formattedTotalHits(totalHits),
            })}
          </li>
        </ul>
      )}
    </div>
  );
};
