// @flow

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment-timezone';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { Dispatch as ReduxDispatch } from 'redux';
import type { Location } from 'react-router-dom';
import type { TranslatorProps } from 'react-i18next';
import clsx from 'clsx';

import { operationAllowed } from 'shared/models/access';
import { fetchProducts } from 'client/actions/products';
import { ReservationDatePicker } from 'client/components/ReservationDatePicker';
import { Loading } from 'client/pages/Loading';
import type { ReduxState } from 'client/reducers/index';
import { addBookmark, removeBookmark } from 'client/actions/user';
import { DeleteProductModal } from 'client/pages/ProductList/DeleteProductModal';
import {
  activeUserSelector,
  activeUserIsNutmegAdminSelector,
  activeUserAllOrganizationsSelector,
  impersonatedAccountIDSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { UpdateResponsibleAgentModal } from 'client/pages/ProductList/UpdateResponsibleAgentModal';
import {
  productColumnCandidatesSelector,
  productVisibleColumnsSelector,
} from 'client/reducers/productTableControls';
import { ProductCustomTable } from 'client/pages/ProductList/ProductCustomTable';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import calenderDetailIcon from 'client/images/ic_calendarDetail.svg';
import productDetailIcon from 'client/images/ic_productsDetail.svg';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import type { ColumnType as ModalColumnType } from 'client/pages/ProductList/ProductListSettingModal';
import { ProductListSettingModal } from 'client/pages/ProductList/ProductListSettingModal';
import deleteIcon from 'client/images/ic_delete.svg';
import copyIcon from 'client/images/ic_copy.svg';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import bookMarkOnIcon from 'client/images/bookmark_on.svg';
import bookMarkOffIcon from 'client/images/bookmark_off.svg';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { config } from 'client/config';

import 'client/pages/ProductList/styles.css';

type ColumnType = CustomTableColumn & ModalColumnType;

type OwnProps = {
  location: Location,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {|
  allColumns: ColumnType[],
  availableDateRangeFocused: START_DATE | END_DATE,
  inputText: string,
  activeTab: SalesStatus,
|};

class ProductListComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      allColumns: this.getAllColumns(),
      availableDateRangeFocused: null,
      inputText: '',
      activeTab: 'ON_SALE',
    };
  }

  componentDidMount() {
    this.fetchProducts();

    const { location } = this.props;
    const filtered = location && location.state && location.state.filtered;
    if (filtered) {
      this.props.onFilteredChange(filtered);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.invalidated || this.props.locale !== prevProps.locale) {
      this.fetchProducts();
      this.setState({
        allColumns: this.getAllColumns(),
      });
    }
  }

  fetchProducts = () => {
    // only fetch products if user is not nutmeg admin to prevent unnecessary product table scans.
    // Nutmeg admins can impersonate an agent/supplier to view products.
    if (!this.props.isNutmegAdmin) {
      this.props.fetchProducts();
    }
  };

  getAllColumns = (): ColumnType[] => {
    const {
      activeUser,
      impersonating,
      addBookmark,
      organizations,
      removeBookmark,
      t,
    } = this.props;

    return [
      {
        Header: '',
        filterable: false,
        id: 'edit',
        width: 'short',
        Cell: (cellInfo) => (
          <>
            <div className={`product-list-edit-column`}>
              <Link
                to={`/products/${cellInfo.original.id}`}
                className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                data-text={t('Detail')}
                style={{ borderLeft: '0', borderRight: '0' }}
              >
                <img src={productDetailIcon} />
              </Link>
              {!Boolean(impersonating) && (
                <img
                  src={
                    cellInfo.original.bookmarked
                      ? bookMarkOnIcon
                      : bookMarkOffIcon
                  }
                  onClick={() => {
                    if (cellInfo.original.bookmarked) {
                      removeBookmark(cellInfo.original.id);
                    } else {
                      addBookmark(cellInfo.original.id);
                    }
                  }}
                />
              )}
            </div>
          </>
        ),
        th: true,
      },
      {
        Header: t('Delete/Copy'),
        id: 'delete/copy',
        accessor: (data) => data.product_name || '',
        Cell: (cellInfo) => (
          <div className={clsx(baseStyles['inline-block'])}>
            {operationAllowed(activeUser, 'write', 'products') ? (
              <>
                <DeleteProductModal
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        baseStyles['icon'],
                        baseStyles['narrow']
                      )}
                    >
                      <img src={deleteIcon} />
                    </a>
                  }
                  product={cellInfo.original}
                />
                <Link
                  to={`/products/${cellInfo.original.id}/copy`}
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['icon'],
                    baseStyles['narrow']
                  )}
                >
                  <img src={copyIcon} />
                </Link>
              </>
            ) : null}
          </div>
        ),
        width: 'short',
      },
      {
        Header: t('Name'),
        id: 'product_name',
        accessor: (data) => data.product_name || '',
        Cell: (cellInfo) => (
          <Link to={`/products/${cellInfo.original.id}`}>{cellInfo.value}</Link>
        ),
        width: 'longx2',
      },
      {
        Header: t('Internal Product Name'),
        id: 'internal_product_name',
        accessor: (data) => data.internal_product_name || '',
        Cell: (cellInfo) => (
          <Link to={`/products/${cellInfo.original.id}`}>{cellInfo.value}</Link>
        ),
        width: 'long',
      },

      {
        Header: t('Availability Calendar'),
        id: 'availability',
        Cell: (cellInfo) => {
          const { locale } = this.props;

          moment.locale(locale);

          if (
            !cellInfo.original.booking_widget_settings?.is_view_only ||
            (cellInfo.original.booking_widget_settings?.is_view_only &&
              operationAllowed(activeUser, 'write', 'productContents'))
          ) {
            return (
              <ReservationDatePicker
                key={cellInfo.original.id}
                productID={cellInfo.original.id}
                trigger={(props) => (
                  <a
                    className={clsx(
                      baseStyles['base-btn icon'],
                      baseStyles['icon']
                    )}
                    {...props}
                  >
                    <img src={calenderDetailIcon} />
                  </a>
                )}
              />
            );
          } else {
            return null;
          }
        },
        width: 'minimal',
      },
      {
        Header: t('Location'),
        id: 'area_name',
        width: 'middle',
        accessor: 'area_name',
      },
      {
        Header: t('Supplier'),
        id: 'supplier_name',
        width: 'middle',
        accessor: 'supplier_name',
      },
      {
        Header: t('Product Code'),
        id: 'supplier_reference',
        width: 'middle',
        accessor: 'supplier_reference',
      },
      {
        Header: '#',
        id: 'id',
        width: 'long',
        accessor: 'id',
      },
      {
        Header: t('Tags'),
        id: 'product_tags',
        width: 'long',
        accessor: (data) => (data.product_tags || []).join(','),
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: t('Internal Tags'),
        id: 'internal_product_tags',
        width: 'long',
        accessor: (data) => (data.internal_product_tags || []).join(','),
        Cell: (row) => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        ),
      },
      {
        Header: t('Responsible Agent'),
        id: 'responsible_agent',
        width: 'short',
        accessor: (data) => {
          const org =
            data.responsible_agent_id &&
            organizations.find((org) => org.id === data.responsible_agent_id);

          return (org && org.name) || '';
        },
        Cell: (cellInfo) => (
          <>
            <UpdateResponsibleAgentModal
              key={cellInfo.original.id}
              trigger={(props) => (
                <Icon color="orange" name="edit" link {...props} />
              )}
              product={cellInfo.original}
            />
            <span>{cellInfo.value}</span>
          </>
        ),
        filterable: false,
      },
    ];
  };

  getColumns = (columnMask: string[]): ColumnType[] => {
    const { allColumns } = this.state;
    return columnMask.map((col) => (allColumns.find((c) => c.id === col): any));
  };

  getColumnsWithEdit = (columnMask: string[]): ColumnType[] => {
    const { allColumns } = this.state;
    return ['edit', ...columnMask].map(
      (col) => (allColumns.find((c) => c.id === col): any)
    );
  };

  render() {
    const { columnCandidates, t, visibleColumns, activeUser, attrLoading } =
      this.props;
    const { inputText, activeTab } = this.state;

    const columnsToShow = this.getColumns(visibleColumns);
    const allCandidateColumns = this.getColumns(columnCandidates);

    const customTableColumns = this.getColumnsWithEdit(visibleColumns).map(
      (c) => {
        return (c: CustomTableColumn);
      }
    );

    return (
      <>
        {(config.enableUIRevamp ||
          config.enableUIRevampForDemo ||
          config.enableUIRevampForRelease) && (
          <ToggleNewUI origin="PRODUCT_LIST" />
        )}
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__middle'],
              baseStyles['base-f-320'],
              baseStyles['spOrder-2']
            )}
          >
            <input
              value={inputText}
              placeholder={'Filter'}
              onChange={(e) => {
                this.setState({
                  inputText: e.target.value,
                });
              }}
              type={'text'}
              className={baseStyles['base-form-text']}
            />
          </div>

          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spSpacebetween'],
              baseStyles['spOrder-1']
            )}
          >
            {operationAllowed(activeUser, 'write', 'products') &&
            hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
              <Link to="/products/new">
                <button
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['middle'],
                    baseStyles['green']
                  )}
                >
                  {t('Create new product')}
                </button>
              </Link>
            ) : (
              <>
                {/* NOTE(goro) need this element to avoid design collapse at mobile view */}
                <span></span>
              </>
            )}
            <ProductListSettingModal
              columnsToShow={columnsToShow}
              allCandidateColumns={allCandidateColumns}
            />
          </div>
        </div>

        <div
          className={clsx(
            componentStyles['c-tab-box'],
            baseStyles['scroll-target-pane']
          )}
        >
          {operationAllowed(activeUser, 'write', 'productContents') && (
            <ul className={clsx(componentStyles['c-tab-box__tab'])}>
              <li
                className={clsx(
                  activeTab === 'ON_SALE' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  this.setState({ activeTab: 'ON_SALE' });
                }}
              >
                <a>{t('On Sale')}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'LIST_ONLY' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  this.setState({ activeTab: 'LIST_ONLY' });
                }}
              >
                <a>{t('Not On Sale')}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'NOT_LISTED' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  this.setState({ activeTab: 'NOT_LISTED' });
                }}
              >
                <a>{t('Not Listed')}</a>
              </li>
            </ul>
          )}
          <ProductCustomTable
            columns={customTableColumns}
            filterText={inputText}
            activeTab={
              operationAllowed(activeUser, 'write', 'productContents')
                ? activeTab
                : undefined
            }
          />
        </div>
        {attrLoading && <Loading />}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const activeUser = activeUserSelector(state);
  const impersonating = impersonatedAccountIDSelector(state);

  return {
    activeUser,
    impersonating,
    attrLoading: state.user.changingAttr,
    columnCandidates: productColumnCandidatesSelector(state),
    loading: state.products.loading,
    locale: state.language.selected.iso,
    monthYearFormat: state.language.selected.monthYearFormat,
    invalidated: state.userDataInvalidated,
    isNutmegAdmin: activeUserIsNutmegAdminSelector(state),
    organizations: activeUserAllOrganizationsSelector(state),
    visibleColumns: productVisibleColumnsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  fetchProducts: () => dispatch(fetchProducts()),
  addBookmark: (id) => dispatch(addBookmark(id)),
  removeBookmark: (id) => dispatch(removeBookmark(id)),
  history: useHistory(),
});

export const ProductList = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductListComponent);
