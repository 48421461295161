import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { Select, Button } from 'client/components/Form';

import { InstantWinDrawTriggerFormValues } from './formValues';
import { EditInstantWinDrawTriggerForm } from './EditInstantWinDrawTriggerForm';
import { EditInstantWinDrawTriggerReservationCreatedForm } from './EditInstantWinDrawTriggerReservationCreatedForm';
import { getDrawTriggerTypeOptions } from './getDrawTriggerTypeOptions';

interface EditInstantWinDrawTriggerModalProps {
  existingInstantWinDrawTrigger?: InstantWinDrawTriggerFormValues;
  open: boolean;
  onClose: () => void;
  onSave: (instantWinDrawTrigger: InstantWinDrawTriggerFormValues) => void;
}

export const EditInstantWinDrawTriggerModal = ({
  existingInstantWinDrawTrigger,
  open,
  onClose,
  onSave,
}: EditInstantWinDrawTriggerModalProps) => {
  const { t } = useTranslation();

  const instantWinTriggerTypes: {
    value: InstantWinDrawTriggerFormValues['triggerType'];
    component?: React.ReactNode;
  }[] = [
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED',
      component: <EditInstantWinDrawTriggerReservationCreatedForm />,
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_SURVEY_ANSWERED',
      component: <EditInstantWinDrawTriggerForm />,
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_REVIEW_POSTED',
      component: <EditInstantWinDrawTriggerForm />,
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_STAMP_RALLY_COMPLETED',
      component: <EditInstantWinDrawTriggerForm />,
    },
    {
      value: 'INSTANT_WIN_TRIGGER_TYPE_CUSTOMER_REGISTERED',
      component: <EditInstantWinDrawTriggerForm />,
    },
  ];

  const getTriggerTypeComponent = (
    triggerType: InstantWinDrawTriggerFormValues['triggerType']
  ) => {
    return instantWinTriggerTypes.find(
      (trigger) => trigger.value === triggerType
    )?.component;
  };

  return (
    <Modal
      title={t('Edit Instant Win Draw Trigger')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        onSubmit={(values: InstantWinDrawTriggerFormValues) => {
          onSave(values);
          onClose();
        }}
        initialValues={
          existingInstantWinDrawTrigger ?? {
            key: uuidv4(),
            drawTriggerType: 'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED',
            productIds: [],
            surveyIds: [],
          }
        }
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Modal.Box>
                <Field name="triggerType">
                  {({ input }) => (
                    <Select
                      label={t('Trigger Type')}
                      options={getDrawTriggerTypeOptions(t)}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
              {getTriggerTypeComponent(values.triggerType)}
            </Modal.Content>
            <Modal.Actions>
              <Button size="middle" style="blue" type="submit">
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
