import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { fetchInquiries } from 'client/actions/inquiries';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { ReduxState } from 'client/reducers';
import { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { SendSMSModal } from './SendSMSModal';
import { SendInquiryEmailModal } from './SendInquiryEmailModal';
import { InquiryAccordionItem } from './InquiryAccordionItem';

type Props = {
  reservation?: Reservation;
};

export const ReservationInquiryTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const inquiriesLastFetchedDateTimeUtc = React.useRef<string | null>(null);

  const [showSMSModal, setShowSMSModal] = React.useState(false);
  const [showSendEmailModal, setShowSendEmailModal] = React.useState(false);

  const dispatch = useDispatch();
  const inquiries = useSelector((state: ReduxState) => state.inquiries.all);
  const activeUser = useSelector(activeUserSelector);

  React.useEffect(() => {
    if (reservation) {
      inquiriesLastFetchedDateTimeUtc.current = moment().format();

      const reservationIds = [
        reservation?.id,
        ...(reservation?.rebooked_from_reservation_id_history ?? []),
      ];

      dispatch(
        fetchInquiries({
          reservation_ids: reservationIds,
          include_email_opened_at: true,
        })
      );
    }
  }, [reservation]);

  // Refresh inquiries data if the most recent inquiry was updated recently to make sure any async status changes for SMS
  // message operations are reflected.
  React.useEffect(() => {
    const lastUpdatedTime =
      inquiries && inquiries.length > 0
        ? inquiries?.[0]?.last_updated_date_time_utc
        : null;

    if (
      lastUpdatedTime &&
      inquiriesLastFetchedDateTimeUtc.current &&
      moment(lastUpdatedTime)
        .add(3, 'seconds')
        .isAfter(moment(inquiriesLastFetchedDateTimeUtc.current))
    ) {
      inquiriesLastFetchedDateTimeUtc.current = moment().format();
      const reservationIds: string[] = [];
      if (reservation?.id) {
        reservationIds.push(reservation?.id);
      }
      reservationIds.push(
        ...(reservation?.rebooked_from_reservation_id_history ?? [])
      );
      dispatch(
        fetchInquiries({
          reservation_ids: reservationIds,
          include_email_opened_at: true,
        })
      );
    }
  }, [reservation, inquiries]);

  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Inquiries')}
        </div>
      </div>
      <Box ml={4} mt={2} display="flex">
        {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
          <Button
            style="green"
            size="middle"
            onClick={() => setShowSMSModal(true)}
          >
            {t('Send SMS')}
          </Button>
        )}
        {showSMSModal && (
          <SendSMSModal
            reservation={reservation}
            open={showSMSModal}
            onClose={() => setShowSMSModal(false)}
          />
        )}
        <Box ml={2}>
          {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
            <Button
              style="green"
              size="middle"
              onClick={() => setShowSendEmailModal(true)}
            >
              {t('Send Email')}
            </Button>
          )}
        </Box>
        {showSendEmailModal && (
          <SendInquiryEmailModal
            reservation={reservation}
            open={showSendEmailModal}
            onClose={() => setShowSendEmailModal(false)}
          />
        )}
      </Box>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {inquiries.map((item, idx) => (
          <InquiryAccordionItem key={idx} inquiry={item} />
        ))}
      </div>
    </div>
  );
};
