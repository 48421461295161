import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { getValidators } from 'shared/libraries/validate/validator';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';

import styles from './ComposeMessage.module.css';

interface Props {
  onBackClick: () => void;
}

export const PushNotificationEditor = ({ onBackClick }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.loading
  );
  const { required } = getValidators(t);

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
      </div>

      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Web Push Notification Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Box mb={2}>
            <Field name="title" validate={required}>
              {({ input, meta: { touched, error } }) => (
                <Input
                  label={t('Web Push Notification Name')}
                  value={input.value}
                  onChange={input.onChange}
                  error={touched && error}
                />
              )}
            </Field>
          </Box>
          <Box>
            <Field name="pushNotificationTitle">
              {({ input }) => <Input label={t('Subject')} {...input} />}
            </Field>
          </Box>
          <Field name="pushNotificationBody">
            {({ input }) => <TextArea label={t('Body')} {...input} />}
          </Field>
          <Field name="pushNotificationLinkUrl">
            {({ input }) => <Input label={t('Link URL')} {...input} />}
          </Field>
          <Field name="pushNotificationImageUrl">
            {({ input }) => (
              <Box mt={2}>
                <FieldWrapper label={t('Image')}>
                  <ImageVideoAudioInput
                    fileUrls={input.value ? [input.value] : []}
                    onChange={(newValue) =>
                      newValue.length > 0
                        ? input.onChange(newValue[0])
                        : input.onChange('')
                    }
                    maxFileCount={1}
                    disableYoutubeVideos
                  />
                </FieldWrapper>
              </Box>
            )}
          </Field>
          <Field name="pushNotificationIconUrl">
            {({ input }) => (
              <Box mt={2}>
                <FieldWrapper label={t('Icon')}>
                  <ImageVideoAudioInput
                    fileUrls={input.value ? [input.value] : []}
                    onChange={(newValue) =>
                      newValue.length > 0
                        ? input.onChange(newValue[0])
                        : input.onChange('')
                    }
                    maxFileCount={1}
                    disableYoutubeVideos
                  />
                </FieldWrapper>
              </Box>
            )}
          </Field>
          <Field name="pushNotificationBadgeUrl">
            {({ input }) => (
              <Box mt={2}>
                <FieldWrapper label={t('Badge')}>
                  <ImageVideoAudioInput
                    fileUrls={input.value ? [input.value] : []}
                    onChange={(newValue) =>
                      newValue.length > 0
                        ? input.onChange(newValue[0])
                        : input.onChange('')
                    }
                    maxFileCount={1}
                    disableYoutubeVideos
                  />
                </FieldWrapper>
              </Box>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};
