// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch as ReduxDispatch } from 'redux';
import { updateManifestProductGroups } from 'client/actions/manifestSettings';
import {
  manifestProductGroupsSelector,
  manifestCustomViewsSelector,
} from 'client/reducers/manifestSettings';
import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import { defaultManifestViewKeys } from 'client/reducers/manifestDefaults';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ReduxState } from 'client/reducers';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type {
  ManifestProductGroup,
  ProductSummary,
} from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import {
  Input,
  Select,
  Button,
  FieldWrapper,
  ToggleButton,
} from 'client/components/Form';

import clsx from 'clsx';
import baseStyles from 'client/base.module.css';

type OwnProps = {
  existingProductGroup?: ManifestProductGroup,
  open: boolean,
  onClose: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

const EditProductGroupModalComponent = ({
  allProducts,
  customViews,
  existingProductGroup,
  productGroups,
  updateManifestProductGroups,
  open,
  onClose,
}: Props) => {
  const [productGroupKey, setProductGroupKey] = React.useState<string>('');
  const [manifestViewType, setManifestViewType] = React.useState<string>('');
  const [productsInGroup, setProductsInGroup] = React.useState<
    ProductSummary[]
  >([]);
  const [usedForGrouping, setUsedForGrouping] = React.useState<boolean>(false);

  const moveItem = (dragIndex, hoverIndex) => {
    setProductsInGroup((productsInGroup) => {
      const newProducts = [...productsInGroup];
      const insertItem = productsInGroup[dragIndex];
      newProducts.splice(dragIndex, 1);
      newProducts.splice(hoverIndex, 0, insertItem);
      return newProducts;
    });
  };

  React.useEffect(() => {
    if (existingProductGroup && existingProductGroup.product_ids) {
      const productIds = existingProductGroup.product_ids;
      setProductsInGroup(
        productIds.map((p): any => allProducts.find((elm) => elm.id == p))
      );
    } else {
      setProductsInGroup([]);
    }
    setProductGroupKey(existingProductGroup?.key || '');
    setManifestViewType(existingProductGroup?.view_type_key || 'ALL_TYPES');
    setUsedForGrouping(existingProductGroup?.used_for_grouping || false);
  }, [allProducts, existingProductGroup]);

  const { t } = useTranslation();

  const manifestViewTypeOptions = [
    {
      key: 'ALL_TYPES',
      value: 'ALL_TYPES',
      text: t('All types'),
    },
    ...customViews.map((view) => ({
      key: view.key,
      value: view.key,
      text: view.key,
    })),
    ...defaultManifestViewKeys.map((viewKey) => ({
      key: viewKey,
      value: viewKey,
      text: t(viewKey),
    })),
  ];

  const headerText = existingProductGroup
    ? t('Edit Manifest Group')
    : t('Create New Manifest Group');
  return (
    <Modal title={headerText} open={open} onClose={onClose} onOpen={() => {}}>
      <Modal.Content>
        <Modal.Box>
          <Input
            disabled={Boolean(existingProductGroup)}
            label={t('Group Title')}
            value={productGroupKey}
            onChange={(e, { value }) => setProductGroupKey(value)}
          />
        </Modal.Box>
        <Modal.Box>
          <Select
            label={t('Applies To')}
            value={manifestViewType}
            onChange={(e, { value }) => setManifestViewType(value)}
            options={manifestViewTypeOptions}
          />
        </Modal.Box>
        <Modal.Box>
          <div className={baseStyles['base-form-box']}>
            <div className={baseStyles['base-form-box__header']}>
              {t('Products')}
            </div>
            {productsInGroup &&
              productsInGroup.map((product, idx) => (
                <>
                  <DraggableSelect
                    key={product?.id ?? ''}
                    value={product?.id ?? ''}
                    options={allProducts
                      .filter(
                        (p) =>
                          (p && p.id === product?.id) ||
                          !productsInGroup.includes(p)
                      )
                      .map((p) => ({
                        value: p?.id,
                        key: p?.id,
                        text: getVerboseDisplayProductName(p),
                      }))}
                    index={idx}
                    onValueChange={(value) => {
                      const newItem = allProducts.find(
                        (p: ProductSummary) => p.id === value
                      );
                      const pList: any = productsInGroup.map(
                        (p: ProductSummary, pidx) =>
                          pidx === idx ? newItem : p
                      );

                      setProductsInGroup(pList);
                    }}
                    moveItem={(dragIndex: number, hoverIndex: number) => {
                      moveItem(dragIndex, hoverIndex);
                    }}
                    deleteItem={() => {
                      const newProducts = [...productsInGroup];
                      newProducts.splice(idx, 1);
                      setProductsInGroup(newProducts);
                    }}
                  />
                </>
              ))}
          </div>
          {productsInGroup && (
            <div className={clsx(baseStyles['list-add-btn'])}>
              <Button
                style="green"
                size="middle"
                onClick={() => {
                  const newProducts: any = [...productsInGroup];
                  newProducts.splice(productsInGroup.length, 0, null);
                  setProductsInGroup(newProducts);
                }}
              >
                {t('Add')}
              </Button>
            </div>
          )}
        </Modal.Box>
        <Modal.Box>
          <FieldWrapper label={t('Manifest Grouping')}>
            <ToggleButton
              label={t('Use it to group reservations on Manifest')}
              checked={usedForGrouping}
              onChange={() => {
                setUsedForGrouping(!usedForGrouping);
              }}
            />
          </FieldWrapper>
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          positive
          onClick={() => {
            const newManifestGroup = {
              key: productGroupKey,
              view_type_key:
                manifestViewType !== 'ALL_TYPES' ? manifestViewType : '',
              product_ids: productsInGroup.filter((p) => !!p).map((p) => p.id),
              used_for_grouping: usedForGrouping,
            };
            updateManifestProductGroups([
              ...productGroups.filter((group) => group.key !== productGroupKey),
              newManifestGroup,
            ]);
            onClose();
          }}
          disabled={!productGroupKey || (productsInGroup || []).length === 0}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  allProducts: summariesSortedByBookmarkedSelector(state),
  productGroups: manifestProductGroupsSelector(state),
  customViews: manifestCustomViewsSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch<Object>) => ({
  updateManifestProductGroups: (productGroups: ManifestProductGroup[]) =>
    dispatch(updateManifestProductGroups(productGroups)),
});

export const EditProductGroupModal = connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(EditProductGroupModalComponent);
