import FileSaver from 'file-saver';
import axios from 'axios';
import moment from 'moment-timezone';
import { ThunkDispatch } from 'redux-thunk';

import {
  BATCH_FETCH_RESERVATION_STATS_BY_DATE_FAILURE,
  BATCH_FETCH_RESERVATION_STATS_BY_DATE_REQUEST,
  BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  CANCEL_RESERVATION_FAILURE,
  CANCEL_RESERVATION_REQUEST,
  CANCEL_RESERVATION_SUCCESS,
  BULK_CANCEL_RESERVATION_FAILURE,
  BULK_CANCEL_RESERVATION_REQUEST,
  BULK_CANCEL_RESERVATION_SUCCESS,
  BULK_CANCEL_RESERVATION_TIME_OUT,
  CANCEL_RESERVATION_WITH_FEE_FAILURE,
  CANCEL_RESERVATION_WITH_FEE_REQUEST,
  CANCEL_RESERVATION_WITH_FEE_SUCCESS,
  CHECKIN_RESERVATION_FAILURE,
  CHECKIN_RESERVATION_REQUEST,
  CHECKIN_RESERVATION_SUCCESS,
  UNDO_CHECKIN_RESERVATION_FAILURE,
  UNDO_CHECKIN_RESERVATION_REQUEST,
  UNDO_CHECKIN_RESERVATION_SUCCESS,
  CONFIRM_RESERVATION_FAILURE,
  CONFIRM_RESERVATION_REQUEST,
  CONFIRM_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAILURE,
  CREATE_RESERVATION_REQUEST,
  CREATE_RESERVATION_SUCCESS,
  DECLINE_RESERVATION_FAILURE,
  DECLINE_RESERVATION_REQUEST,
  DECLINE_RESERVATION_SUCCESS,
  FETCH_RESERVATION_BY_ID_FAILURE,
  FETCH_RESERVATION_BY_ID_REQUEST,
  FETCH_RESERVATION_BY_ID_SUCCESS,
  FETCH_RESERVATIONS_BY_ID_FAILURE,
  FETCH_RESERVATIONS_BY_ID_REQUEST,
  FETCH_RESERVATIONS_BY_ID_SUCCESS,
  FETCH_RESERVATIONS_FAILURE,
  FETCH_RESERVATIONS_REQUEST,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATION_STATS_FAILURE,
  FETCH_RESERVATION_STATS_REQUEST,
  FETCH_RESERVATION_STATS_SUCCESS,
  FETCH_RESERVATION_STATS_BY_DATE_FAILURE,
  FETCH_RESERVATION_STATS_BY_DATE_REQUEST,
  FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  FETCH_RESERVATIONS_CSV_FAILURE,
  FETCH_RESERVATIONS_CSV_REQUEST,
  FETCH_RESERVATIONS_CSV_SUCCESS,
  STANDBY_RESERVATION_FAILURE,
  STANDBY_RESERVATION_REQUEST,
  STANDBY_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAILURE,
  UPDATE_RESERVATION_REQUEST,
  UPDATE_RESERVATION_SUCCESS,
  FARE_ADJUST_RESERVATION_FAILURE,
  FARE_ADJUST_RESERVATION_REQUEST,
  FARE_ADJUST_RESERVATION_SUCCESS,
  SEND_PAYMENT_EMAIL_FAILURE,
  SEND_PAYMENT_EMAIL_REQUEST,
  SEND_PAYMENT_EMAIL_SUCCESS,
  SEND_REVIEW_REQUEST_EMAIL_FAILURE,
  SEND_REVIEW_REQUEST_EMAIL_REQUEST,
  SEND_REVIEW_REQUEST_EMAIL_SUCCESS,
  SEND_SURVEY_REQUEST_EMAIL_FAILURE,
  SEND_SURVEY_REQUEST_EMAIL_REQUEST,
  SEND_SURVEY_REQUEST_EMAIL_SUCCESS,
  SEND_WAIVER_REQUEST_EMAIL_FAILURE,
  SEND_WAIVER_REQUEST_EMAIL_REQUEST,
  SEND_WAIVER_REQUEST_EMAIL_SUCCESS,
  SEND_RESERVATION_EMAIL_FAILURE,
  SEND_RESERVATION_EMAIL_REQUEST,
  SEND_RESERVATION_EMAIL_SUCCESS,
  SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_FAILURE,
  SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_REQUEST,
  SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_SUCCESS,
  CALCULATE_RESERVATION_FARE_FAILURE,
  CALCULATE_RESERVATION_FARE_REQUEST,
  CALCULATE_RESERVATION_FARE_SUCCESS,
  FETCH_RESERVATION_REPORT_DATA_FAILURE,
  FETCH_RESERVATION_REPORT_DATA_REQUEST,
  FETCH_RESERVATION_REPORT_DATA_SUCCESS,
  UNDO_REDEEM_RESERVATION_COUPON_REQUEST,
  UNDO_REDEEM_RESERVATION_COUPON_SUCCESS,
  UNDO_REDEEM_RESERVATION_COUPON_FAILURE,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS,
  UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST,
  MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS,
  CLEAR_CREATE_RESERVATION_ERROR,
  CLEAR_LAST_CREATED_RESERVATION_ID,
  UNDO_EQUIPMENT_TICKET_PRINT_REQUEST,
  UNDO_EQUIPMENT_TICKET_PRINT_FAILURE,
  UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  getHTTPRequestHeadersWithoutImpersonation,
  URLWithQueryParams,
  getHTTPRequestHeaders,
} from 'client/actions/index';
import { getDemoReservation } from 'client/libraries/util/demoReservation';
import type {
  ConfirmReservationPatch,
  NewReservation,
  ReservationPatch,
  FareAdjustmentForReservationPatch,
  Product,
  ReservationCheckinParams,
  ReservationUndoCheckinParams,
} from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchReservationsRequest = () => ({
  type: FETCH_RESERVATIONS_REQUEST,
});

const fetchReservationsSuccess = (response: any) => ({
  type: FETCH_RESERVATIONS_SUCCESS,
  response,
});

const fetchReservationsFailure = (error: string) => ({
  type: FETCH_RESERVATIONS_FAILURE,
  error,
});

let fetchReservationsCancel: () => void | typeof undefined;
export const fetchReservations =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchReservationsCancel && fetchReservationsCancel();
    dispatch(fetchReservationsRequest());
    axios
      .get('/api/reservations', {
        params: {
          ...queryParams,
          // Limit total reservations to 1000 to prevent costly calls
          page_size: 1000,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchReservationsCancel = c;
        }),
      })
      .then((result) => dispatch(fetchReservationsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchReservationsFailure('canceled'));
        } else {
          dispatch(
            fetchReservationsFailure(
              (error?.response?.data?.message || error?.response?.statusText) ??
                'error'
            )
          );
        }
      });
  };

const fetchReservationByIDRequest = (id: string) => ({
  type: FETCH_RESERVATION_BY_ID_REQUEST,
  id,
});

const fetchReservationByIDSuccess = (response: any) => ({
  type: FETCH_RESERVATION_BY_ID_SUCCESS,
  response,
});

const fetchReservationByIDFailure = (error: string) => ({
  type: FETCH_RESERVATION_BY_ID_FAILURE,
  error,
});

export const fetchReservationByID =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationByIDRequest(id));
    return fetch(`/api/reservations/${id}`, {
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(fetchReservationByIDSuccess(result)))
      .catch((error) => dispatch(fetchReservationByIDFailure(error)));
  };

const fetchReservationsByIDRequest = (ids: string[]) => ({
  type: FETCH_RESERVATIONS_BY_ID_REQUEST,
  ids,
});

const fetchReservationsByIDSuccess = (responses: any) => ({
  type: FETCH_RESERVATIONS_BY_ID_SUCCESS,
  responses,
});

const fetchReservationsByIDFailure = (error: any) => ({
  type: FETCH_RESERVATIONS_BY_ID_FAILURE,
  error,
});

export const fetchReservationsByID =
  (ids: string[]) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationsByIDRequest(ids));
    const promises = ids.map((id) =>
      fetch(`/api/reservations/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      }).then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
    );
    return Promise.all(promises)
      .then((responses) => dispatch(fetchReservationsByIDSuccess(responses)))
      .catch((error) => dispatch(fetchReservationsByIDFailure(error)));
  };
export const fetchDemoReservation =
  (demoProduct: Product) => (dispatch: Dispatch) => {
    dispatch(fetchReservationByIDSuccess(getDemoReservation(demoProduct)));
  };
export const fetchReservationVoucherPdf =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetch(`/api/reservations/${id}/pdf`, {
      headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }

        throw new Error(res.statusText);
      })
      .then((blob) => {
        FileSaver.saveAs(blob, id);
      })
      .catch((error) => {
        console.log(error); // TODO(yijun): better error handling -> show error message in UI
      });
  };

const fetchReservationsCSVRequest = () => ({
  type: FETCH_RESERVATIONS_CSV_REQUEST,
});

const fetchReservationsCSVSuccess = () => ({
  type: FETCH_RESERVATIONS_CSV_SUCCESS,
});

const fetchReservationsCSVFailure = (error: string) => ({
  type: FETCH_RESERVATIONS_CSV_FAILURE,
  error,
});

export const fetchReservationsCSV =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationsCSVRequest());
    axios
      .get(`/api/reservations/csv`, {
        params: {
          ...queryParams,

          // Limit total reservations to 1000 to prevent costly calls
          page_size: 1000,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'reservations.csv');
        dispatch(fetchReservationsCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchReservationsCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };

const createReservationRequest = (newReservation: NewReservation) => ({
  type: CREATE_RESERVATION_REQUEST,
  newReservation,
});

const createReservationSuccess = (response: any) => ({
  type: CREATE_RESERVATION_SUCCESS,
  response,
});

const createReservationFailure = (error: string) => ({
  type: CREATE_RESERVATION_FAILURE,
  error,
});

export const createReservation =
  (newReservation: NewReservation) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    newReservation.agent_reference &&
      (newReservation.agent_reference = newReservation.agent_reference.trim());
    dispatch(createReservationRequest(newReservation));
    return fetch('/api/reservations', {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(newReservation),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(createReservationSuccess(result)))
      .catch((error) => {
        dispatch(createReservationFailure(error));
      });
  };

const updateReservationRequest = (id: string, patch: ReservationPatch) => ({
  type: UPDATE_RESERVATION_REQUEST,
  patch,
});

const updateReservationSuccess = (response: any) => ({
  type: UPDATE_RESERVATION_SUCCESS,
  response,
});

const updateReservationFailure = (error: any) => ({
  type: UPDATE_RESERVATION_FAILURE,
  error,
});

export const updateReservation =
  (id: string, patch: ReservationPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateReservationRequest(id, patch));
    return fetch(`/api/reservations/${id}`, {
      method: 'PATCH',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify(patch),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(updateReservationSuccess(result)))
      .catch((error) => dispatch(updateReservationFailure(error)));
  };

const cancelReservationRequest = (id: string, agent_notes?: string) => ({
  type: CANCEL_RESERVATION_REQUEST,
  id,
  agent_notes,
});

const cancelReservationSuccess = (response: any) => ({
  type: CANCEL_RESERVATION_SUCCESS,
  response,
});

const cancelReservationFailure = (error: string) => ({
  type: CANCEL_RESERVATION_FAILURE,
  error,
});

export const cancelReservation =
  (id: string, agent_notes?: string, useAlternateOrganization?: boolean) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(cancelReservationRequest(id, agent_notes));
    return fetch(`/api/reservations/${id}/cancel`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState(), { useAlternateOrganization }),
      body: JSON.stringify({
        agent_notes: agent_notes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(cancelReservationSuccess(result)))
      .catch((error) => dispatch(cancelReservationFailure(error)));
  };

const bulkCancelReservationRequest = (
  reservation_ids: string[],
  agent_notes?: string
) => ({
  type: BULK_CANCEL_RESERVATION_REQUEST,
  reservation_ids,
  agent_notes,
});

const bulkCancelReservationSuccess = (response: any) => ({
  type: BULK_CANCEL_RESERVATION_SUCCESS,
  response,
});

const bulkCancelReservationFailure = (error: string) => ({
  type: BULK_CANCEL_RESERVATION_FAILURE,
  error,
});

const bulkCancelReservationTimeOut = () => ({
  type: BULK_CANCEL_RESERVATION_TIME_OUT,
});

export const bulkCancelReservation =
  (reservation_ids: string[], agent_notes?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(bulkCancelReservationRequest(reservation_ids, agent_notes));
    return fetch(`/api/reservations/bulkcancel`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({
        reservation_ids: reservation_ids,
        agent_notes: agent_notes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(bulkCancelReservationSuccess(result)))
      .catch((error) => {
        if (error === '') {
          //The error text is coming back empty during 504.
          dispatch(bulkCancelReservationFailure('Gateway Timeout'));
        } else {
          dispatch(bulkCancelReservationFailure(error));
        }
        // This is no longer necessary if the banda side of the bulk cancellation process is changed.
        // Currently, 504, 408, etc. occur, but when an error occurs, a common modal is issued.
        dispatch(bulkCancelReservationTimeOut());
      });
  };

const checkinReservationRequest = (
  id: string,
  params: ReservationCheckinParams
) => ({
  type: CHECKIN_RESERVATION_REQUEST,
  id,
  params,
});

const checkinReservationSuccess = (response: any) => ({
  type: CHECKIN_RESERVATION_SUCCESS,
  response,
});

const checkinReservationFailure = (error: string) => ({
  type: CHECKIN_RESERVATION_FAILURE,
  error,
});

export const checkinReservation =
  (id: string, params: ReservationCheckinParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(checkinReservationRequest(id, params));
    return axios
      .post(`/api/reservations/${id}/checkin`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(checkinReservationSuccess(result.data)))
      .catch((error) => dispatch(checkinReservationFailure(error)));
  };

const undoCheckinReservationRequest = (id: string) => ({
  type: UNDO_CHECKIN_RESERVATION_REQUEST,
  id,
});

const undoCheckinReservationSuccess = (response: any) => ({
  type: UNDO_CHECKIN_RESERVATION_SUCCESS,
  response,
});

const undoCheckinReservationFailure = (error: string) => ({
  type: UNDO_CHECKIN_RESERVATION_FAILURE,
  error,
});

export const undoCheckinReservation =
  (id: string, params: ReservationUndoCheckinParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(undoCheckinReservationRequest(id));
    return axios
      .post(`/api/reservations/${id}/undo-checkin`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(undoCheckinReservationSuccess(result.data)))
      .catch((error) => dispatch(undoCheckinReservationFailure(error)));
  };

const cancelReservationWithFeeRequest = (
  id: string,
  feeFixed?: string,
  feePercent?: number,
  supplier_notes?: string
) => ({
  type: CANCEL_RESERVATION_WITH_FEE_REQUEST,
  id,
  feeFixed,
  feePercent,
  supplier_notes,
});

const cancelReservationWithFeeSuccess = (response: any) => ({
  type: CANCEL_RESERVATION_WITH_FEE_SUCCESS,
  response,
});

const cancelReservationWithFeeFailure = (error: string) => ({
  type: CANCEL_RESERVATION_WITH_FEE_FAILURE,
  error,
});

export const cancelReservationWithFee =
  (
    id: string,
    feeFixed?: string,
    feePercent?: number,
    supplier_notes?: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      cancelReservationWithFeeRequest(id, feeFixed, feePercent, supplier_notes)
    );
    const params: Record<string, string> = {};

    if (feeFixed) {
      params.fee_fixed = feeFixed;
    }

    if (feePercent) {
      params.fee_percent = `${feePercent}`;
    }

    return fetch(
      URLWithQueryParams(`/api/reservations/${id}/cancel-with-fee`, params),
      {
        method: 'POST',
        headers: getHTTPRequestHeaders(getState()),
        body: JSON.stringify({
          supplier_notes: supplier_notes,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(cancelReservationWithFeeSuccess(result)))
      .catch((error) => dispatch(cancelReservationWithFeeFailure(error)));
  };

const confirmReservationRequest = (
  id: string,
  supplier_notes?: string,
  supplier_reference?: string
) => ({
  type: CONFIRM_RESERVATION_REQUEST,
  id,
  supplier_notes,
  supplier_reference,
});

const confirmReservationSuccess = (response: any) => ({
  type: CONFIRM_RESERVATION_SUCCESS,
  response,
});

const confirmReservationFailure = (error: string) => ({
  type: CONFIRM_RESERVATION_FAILURE,
  error,
});

export const confirmReservation =
  (
    id: string,
    req: ConfirmReservationPatch,
    supplier_notes?: string,
    supplier_reference?: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(confirmReservationRequest(id, supplier_notes));
    req.supplier_notes = supplier_notes;
    req.supplier_reference = supplier_reference;
    return fetch(`/api/reservations/${id}/confirm`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({ ...req }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(confirmReservationSuccess(result)))
      .catch((error) => dispatch(confirmReservationFailure(error)));
  };

const declineReservationRequest = (id: string, supplier_notes?: string) => ({
  type: DECLINE_RESERVATION_REQUEST,
  id,
  supplier_notes,
});

const declineReservationSuccess = (response: any) => ({
  type: DECLINE_RESERVATION_SUCCESS,
  response,
});

const declineReservationFailure = (error: string) => ({
  type: DECLINE_RESERVATION_FAILURE,
  error,
});

export const declineReservation =
  (id: string, supplier_notes?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(declineReservationRequest(id, supplier_notes));
    return fetch(`/api/reservations/${id}/decline`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({
        supplier_notes: supplier_notes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(declineReservationSuccess(result)))
      .catch((error) => dispatch(declineReservationFailure(error)));
  };

const bulkDeclineReservationRequest = (
  reservation_ids: string[],
  supplier_notes?: string
) => ({
  type: BULK_CANCEL_RESERVATION_REQUEST,
  reservation_ids,
  supplier_notes,
});

const bulkDeclineReservationSuccess = (response: any) => ({
  type: BULK_CANCEL_RESERVATION_SUCCESS,
  response,
});

const bulkDeclineReservationFailure = (error: string) => ({
  type: BULK_CANCEL_RESERVATION_FAILURE,
  error,
});

const bulkDeclineReservationTimeOut = () => ({
  type: BULK_CANCEL_RESERVATION_TIME_OUT,
});

export const bulkDeclineReservation =
  (reservation_ids: string[], supplier_notes?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(bulkDeclineReservationRequest(reservation_ids, supplier_notes));
    return fetch(`/api/reservations/bulkdecline`, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({
        reservation_ids: reservation_ids,
        supplier_notes: supplier_notes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(bulkDeclineReservationSuccess(result)))
      .catch((error) => {
        if (error === '') {
          //The error text is coming back empty during 504.
          dispatch(bulkDeclineReservationFailure('Gateway Timeout'));
        } else {
          dispatch(bulkDeclineReservationFailure(error));
        }
        // This is no longer necessary if the banda side of the bulk declinelation process is changed.
        // Currently, 504, 408, etc. occur, but when an error occurs, a common modal is issued.
        dispatch(bulkDeclineReservationTimeOut());
      });
  };

const standbyReservationRequest = (
  id: string,
  supplier_notes?: string,
  supplier_reference?: string
) => ({
  type: STANDBY_RESERVATION_REQUEST,
  id,
  supplier_notes,
  supplier_reference,
});

const standbyReservationSuccess = (response: any) => ({
  type: STANDBY_RESERVATION_SUCCESS,
  response,
});

const standbyReservationFailure = (error: string) => ({
  type: STANDBY_RESERVATION_FAILURE,
  error,
});

export const standbyReservation =
  (
    id: string,
    untilTime?: string,
    supplier_notes?: string,
    supplier_reference?: string
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(standbyReservationRequest(id, supplier_notes, supplier_reference));
    const url = untilTime
      ? `/api/reservations/${id}/standby?until_date_time_utc=${encodeURIComponent(
          untilTime
        )}`
      : `/api/reservations/${id}/standby`;
    return fetch(url, {
      method: 'POST',
      headers: getHTTPRequestHeaders(getState()),
      body: JSON.stringify({
        supplier_notes: supplier_notes,
        supplier_reference: supplier_reference,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(standbyReservationSuccess(result)))
      .catch((error) => dispatch(standbyReservationFailure(error)));
  };

const fareAdjustReservationRequest = () => ({
  type: FARE_ADJUST_RESERVATION_REQUEST,
});

const fareAdjustReservationSuccess = (response: any) => ({
  type: FARE_ADJUST_RESERVATION_SUCCESS,
  response,
});

const fareAdjustReservationFailure = (error: string) => ({
  type: FARE_ADJUST_RESERVATION_FAILURE,
  error,
});

export const createFareAdjustmentForReservation =
  (id: string, fare_adjust_params: FareAdjustmentForReservationPatch) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fareAdjustReservationRequest());
    return axios
      .post(`/api/reservations/${id}/fare_adjust`, fare_adjust_params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fareAdjustReservationSuccess(result.data)))
      .catch((error) => {
        dispatch(fareAdjustReservationFailure(error));
        throw error;
      });
  };

const fetchReservationStatsRequest = () => ({
  type: FETCH_RESERVATION_STATS_REQUEST,
});

const fetchReservationStatsSuccess = (response: any) => ({
  type: FETCH_RESERVATION_STATS_SUCCESS,
  response,
});

const fetchReservationStatsFailure = (error: any) => ({
  type: FETCH_RESERVATION_STATS_FAILURE,
  error,
});

let fetchReservationStatsCancel: () => void | typeof undefined;
export const fetchReservationStats =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchReservationStatsCancel && fetchReservationStatsCancel();
    dispatch(fetchReservationStatsRequest());
    axios
      .get('/api/reservations/stats', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchReservationStatsCancel = c;
        }),
      })
      .then((result) => dispatch(fetchReservationStatsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchReservationStatsFailure('canceled'));
        } else {
          dispatch(
            fetchReservationStatsFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
let sequentialBatchUpdateReservationsCancel: () => void | typeof undefined;

const setSequentialBatchUpdateReservationsCancelFunc = (c: () => void) => {
  sequentialBatchUpdateReservationsCancel = c;
};

export const sequentialBatchUpdateReservations =
  (
    updateRequests: {
      id: string;
      patch: ReservationPatch;
    }[]
  ) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    if (sequentialBatchUpdateReservationsCancel) {
      sequentialBatchUpdateReservationsCancel();
    }

    for (const { id, patch } of updateRequests) {
      dispatch(updateReservationRequest(id, patch));

      try {
        const res = await axios.patch(`/api/reservations/${id}`, patch, {
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(
            setSequentialBatchUpdateReservationsCancelFunc
          ),
        });
        dispatch(updateReservationSuccess(res.data));
      } catch (err) {
        dispatch(updateReservationFailure(err));

        if (axios.isCancel(err)) {
          return;
        }
      }
    }
  };

const batchFetchReservationStatsByDateRequest = () => ({
  type: BATCH_FETCH_RESERVATION_STATS_BY_DATE_REQUEST,
});

const batchFetchReservationStatsByDateSuccess = (
  date: string,
  response: any
) => ({
  type: BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  date,
  response,
});

const batchFetchReservationStatsByDateFailure = (error: any) => ({
  type: BATCH_FETCH_RESERVATION_STATS_BY_DATE_FAILURE,
  error,
});

let batchFetchReservationStatsByDateCancel: () => void | typeof undefined;

const setBatchFetchReservationStatsByDateCancelFunc = (c: () => void) => {
  batchFetchReservationStatsByDateCancel = c;
};

export const batchFetchReservationStatsByDate =
  (dates: string[]) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    batchFetchReservationStatsByDateCancel &&
      batchFetchReservationStatsByDateCancel();

    for (const date of dates) {
      dispatch(batchFetchReservationStatsByDateRequest());
      const queryParams = {
        start_date_local_from: date,
        start_date_local_to: moment(date).add(1, 'day').format('YYYY-MM-DD'),
      };

      try {
        const res = await axios.get('/api/reservations/stats', {
          params: queryParams,
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(
            setBatchFetchReservationStatsByDateCancelFunc
          ),
        });
        dispatch(batchFetchReservationStatsByDateSuccess(date, res.data));
      } catch (err) {
        dispatch(batchFetchReservationStatsByDateFailure(err));

        if (axios.isCancel(err)) {
          return;
        }
      }
    }
  };

const fetchReservationStatsByDateRequest = () => ({
  type: FETCH_RESERVATION_STATS_BY_DATE_REQUEST,
});

const fetchReservationStatsByDateSuccess = (response: any) => ({
  type: FETCH_RESERVATION_STATS_BY_DATE_SUCCESS,
  response,
});

const fetchReservationStatsByDateFailure = (error: any) => ({
  type: FETCH_RESERVATION_STATS_BY_DATE_FAILURE,
  error,
});

let fetchReservationStatsByDateCancel: () => void | typeof undefined;

const setFetchReservationStatsByDateCancelFunc = (c: () => void) => {
  fetchReservationStatsByDateCancel = c;
};

export const fetchReservationStatsByDate =
  (startDate: string, endDate: string) =>
  async (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchReservationStatsByDateCancel && fetchReservationStatsByDateCancel();

    dispatch(fetchReservationStatsByDateRequest());
    const queryParams = {
      start_date_local_from: startDate,
      start_date_local_to: endDate,
    };

    try {
      const res = await axios.get('/api/reservations/statsbydate', {
        params: queryParams,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(
          setFetchReservationStatsByDateCancelFunc
        ),
      });
      dispatch(fetchReservationStatsByDateSuccess(res.data));
    } catch (err) {
      dispatch(fetchReservationStatsByDateFailure(err));

      if (axios.isCancel(err)) {
        return;
      }
    }
  };

const sendPaymentEmailRequest = () => ({
  type: SEND_PAYMENT_EMAIL_REQUEST,
});

const sendPaymentEmailSuccess = () => ({
  type: SEND_PAYMENT_EMAIL_SUCCESS,
});

const sendPaymentEmailFailure = (error: any) => ({
  type: SEND_PAYMENT_EMAIL_FAILURE,
  error,
});

export const sendPaymentEmail =
  (id: string, toAddress: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendPaymentEmailRequest());
    return axios
      .post(
        `/api/reservations/${id}/send-payment-email`,
        {
          to_address: toAddress,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendPaymentEmailSuccess()))
      .catch((error) => dispatch(sendPaymentEmailFailure(error)));
  };

const sendReviewRequestEmailRequest = () => ({
  type: SEND_REVIEW_REQUEST_EMAIL_REQUEST,
});

const sendReviewRequestEmailSuccess = () => ({
  type: SEND_REVIEW_REQUEST_EMAIL_SUCCESS,
});

const sendReviewRequestEmailFailure = (error: any) => ({
  type: SEND_REVIEW_REQUEST_EMAIL_FAILURE,
  error,
});

export const sendReviewRequestEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendReviewRequestEmailRequest());
    return axios
      .post(
        `/api/reservations/${reservationId}/send-review-request-email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendReviewRequestEmailSuccess()))
      .catch((error) => dispatch(sendReviewRequestEmailFailure(error)));
  };

const sendSurveyRequestEmailRequest = () => ({
  type: SEND_SURVEY_REQUEST_EMAIL_REQUEST,
});

const sendSurveyRequestEmailSuccess = () => ({
  type: SEND_SURVEY_REQUEST_EMAIL_SUCCESS,
});

const sendSurveyRequestEmailFailure = (error: any) => ({
  type: SEND_SURVEY_REQUEST_EMAIL_FAILURE,
  error,
});

export const sendSurveyRequestEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendSurveyRequestEmailRequest());
    return axios
      .post(
        `/api/reservations/${reservationId}/send-survey-request-email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendSurveyRequestEmailSuccess()))
      .catch((error) => dispatch(sendSurveyRequestEmailFailure(error)));
  };

const sendWaiverRequestEmailRequest = () => ({
  type: SEND_WAIVER_REQUEST_EMAIL_REQUEST,
});

const sendWaiverRequestEmailSuccess = () => ({
  type: SEND_WAIVER_REQUEST_EMAIL_SUCCESS,
});

const sendWaiverRequestEmailFailure = (error: any) => ({
  type: SEND_WAIVER_REQUEST_EMAIL_FAILURE,
  error,
});

export const sendWaiverRequestEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendWaiverRequestEmailRequest());
    return axios
      .post(
        `/api/reservations/${reservationId}/send-waiver-request-email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendWaiverRequestEmailSuccess()))
      .catch((error) => dispatch(sendWaiverRequestEmailFailure(error)));
  };

const sendMediaDownloadOfferEmailRequest = () => ({
  type: SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_REQUEST,
});

const sendMediaDownloadOfferEmailSuccess = () => ({
  type: SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_SUCCESS,
});

const sendMediaDownloadOfferEmailFailure = (error: any) => ({
  type: SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_FAILURE,
  error,
});

export const sendMediaDownloadOfferEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendMediaDownloadOfferEmailRequest());
    return axios
      .post(
        `/api/reservations/${reservationId}/send-media-download-offer-email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendMediaDownloadOfferEmailSuccess()))
      .catch((error) => dispatch(sendMediaDownloadOfferEmailFailure(error)));
  };

const sendReservationEmailRequest = () => ({
  type: SEND_RESERVATION_EMAIL_REQUEST,
});

const sendReservationEmailSuccess = () => ({
  type: SEND_RESERVATION_EMAIL_SUCCESS,
});

const sendReservationEmailFailure = (error: any) => ({
  type: SEND_RESERVATION_EMAIL_FAILURE,
  error,
});

export const sendReservationStatusEmail =
  (reservationId: string, email?: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(sendReservationEmailRequest());
    return axios
      .post(
        `/api/reservations/${reservationId}/send-status-email`,
        {
          to_address: email,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then(() => dispatch(sendReservationEmailSuccess()))
      .catch((error) => dispatch(sendReservationEmailFailure(error)));
  };

const calculateReservationFareRequest = () => ({
  type: CALCULATE_RESERVATION_FARE_REQUEST,
});

const calculateReservationFareSuccess = (response: any) => ({
  type: CALCULATE_RESERVATION_FARE_SUCCESS,
  response,
});

const calculateReservationFareFailure = (error: any) => ({
  type: CALCULATE_RESERVATION_FARE_FAILURE,
  error,
});

export const calculateReservationFares =
  (newReservation: NewReservation) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(calculateReservationFareRequest());
    return axios
      .post(`/api/reservations/calculatefares`, newReservation, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(calculateReservationFareSuccess(result.data)))
      .catch((error) => {
        dispatch(calculateReservationFareFailure(error));
        throw error;
      });
  };

const fetchReservationReportDataRequest = () => ({
  type: FETCH_RESERVATION_REPORT_DATA_REQUEST,
});

const fetchReservationReportDataSuccess = (response: any) => ({
  type: FETCH_RESERVATION_REPORT_DATA_SUCCESS,
  response,
});

const fetchReservationReportDataFailure = (error: any) => ({
  type: FETCH_RESERVATION_REPORT_DATA_FAILURE,
  error,
});

let fetchReservationReportDataCancel: () => void | typeof undefined;
export const fetchReservationReportData =
  (queryParams?: Record<string, string>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchReservationReportDataCancel && fetchReservationReportDataCancel();
    dispatch(fetchReservationReportDataRequest());
    axios
      .get('/api/reservations/reportdata', {
        params: {
          ...queryParams,
          // Limit total reservations to 1000 to prevent costly calls
          page_size: 1000,
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchReservationReportDataCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchReservationReportDataSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchReservationReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchReservationReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };

const undoRedeemReservationCouponRequest = (id: string) => ({
  type: UNDO_REDEEM_RESERVATION_COUPON_REQUEST,
  id,
});

const undoRedeemReservationCouponSuccess = (response: any) => ({
  type: UNDO_REDEEM_RESERVATION_COUPON_SUCCESS,
  response,
});

const undoRedeemReservationCouponFailure = (error: string) => ({
  type: UNDO_REDEEM_RESERVATION_COUPON_FAILURE,
  error,
});

export const undoRedeemReservationCoupon =
  (id: string, params: { id: string }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(undoRedeemReservationCouponRequest(id));
    return axios
      .post(`/api/reservations/${id}/undo-redeemcoupon`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(undoRedeemReservationCouponSuccess(result.data))
      )
      .catch((error) => dispatch(undoRedeemReservationCouponFailure(error)));
  };

const undoRedeemReservationStampRallyRequest = (id: string) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST,
  id,
});

const undoRedeemReservationStampRallySuccess = (response: any) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS,
  response,
});

const undoRedeemReservationStampRallyFailure = (error: string) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE,
  error,
});

export const undoRedeemReservationStampRally =
  (id: string, params: { id: string }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(undoRedeemReservationStampRallyRequest(id));
    return axios
      .post(`/api/reservations/${id}/undo-redeemstamprally`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(undoRedeemReservationStampRallySuccess(result.data))
      )
      .catch((error) =>
        dispatch(undoRedeemReservationStampRallyFailure(error))
      );
  };

const undoRedeemReservationStampRallyGiftRequest = (id: string) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST,
  id,
});

const undoRedeemReservationStampRallyGiftSuccess = (response: any) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS,
  response,
});

const undoRedeemReservationStampRallyGiftFailure = (error: string) => ({
  type: UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE,
  error,
});

export const undoRedeemReservationStampRallyGift =
  (id: string, params: { id: string }) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(undoRedeemReservationStampRallyGiftRequest(id));
    return axios
      .post(`/api/reservations/${id}/undo-redeemstamprallygift`, params, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) =>
        dispatch(undoRedeemReservationStampRallyGiftSuccess(result.data))
      )
      .catch((error) =>
        dispatch(undoRedeemReservationStampRallyGiftFailure(error))
      );
  };

const markReservationEquipmentTicketPrintedRequest = (id: string) => ({
  type: MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST,
  id,
});
const markReservationEquipmentTicketPrintedSuccess = (response: any) => ({
  type: MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS,
  response,
});
const markReservationEquipmentTicketPrintedFailure = (error: string) => ({
  type: MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE,
  error,
});
export const markReservationEquipmentTicketPrinted =
  (reservationId: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(markReservationEquipmentTicketPrintedRequest(reservationId));
    return axios
      .post(
        `/api/reservations/${reservationId}/mark-equipment-ticket-printed`,
        {},
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) =>
        dispatch(markReservationEquipmentTicketPrintedSuccess(result.data))
      )
      .catch((error) =>
        dispatch(markReservationEquipmentTicketPrintedFailure(error))
      );
  };

const undoEquipmentTicketPrintRequest = (id: string) => ({
  type: UNDO_EQUIPMENT_TICKET_PRINT_REQUEST,
  id,
});
const undoEquipmentTicketPrintSuccess = (response: any) => ({
  type: UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS,
  response,
});
const undoEquipmentTicketPrintFailure = (error: string) => ({
  type: UNDO_EQUIPMENT_TICKET_PRINT_FAILURE,
  error,
});
export const undoEquipmentTicketPrint =
  (reservationId: string) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(undoEquipmentTicketPrintRequest(reservationId));
    return axios
      .post(
        `/api/reservations/${reservationId}/undoequipmentticketprint`,
        undefined,
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((result) => dispatch(undoEquipmentTicketPrintSuccess(result.data)))
      .catch((error) => dispatch(undoEquipmentTicketPrintFailure(error)));
  };

export const clearCreateReservationError = () => ({
  type: CLEAR_CREATE_RESERVATION_ERROR,
});

export const clearLastCreatedReservationId = () => ({
  type: CLEAR_LAST_CREATED_RESERVATION_ID,
});
