import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { fetchProductInstances } from 'client/actions/productInstances';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { makeProductInstancesSelector } from 'client/reducers/productInstances';
import { activeUserSelector } from 'client/reducers/user';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers';
import { getCustomCalendarEvents } from 'client/pages/v3/Reservation/ReservationCreate/util';
import {
  SalesStatus,
  getProductSalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import { CustomCalendar } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/CustomCalendar';
import { ReservationListModal } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationListModal';
import type { ProductInstance, Reservation } from 'shared/models/swagger';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import baseStyles from 'client/v3-base.module.css';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { toProductInstanceShape } from 'client/libraries/util/productInstanceShape';
import { ProductInstancePanel } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ProductInstancePanel';
import { ProductInstanceEditModal } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ProductInstanceEditModal';
import { V3Page } from 'client/components/v3/Page/V3Page';

type ReservationListContext = {
  productIds: string[];
  date: string;
  rootProductInstanceId: string;
};
type DateRange = {
  startDate: Moment;
  endDate: Moment;
};

type LocationState = {
  defaultDate?: string;
  productId?: string;
  customerId?: string;
  reservation?: Reservation;
  isFromNewReservationModal?: boolean;
  isChangeReservation?: boolean;
  isReservationWithSameGuests?: boolean;
  productInstance?: ProductInstance;
};

export const ReservationCreate = () => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const {
    defaultDate,
    reservation,
    productId,
    customerId,
    isFromNewReservationModal = false,
    isChangeReservation = false,
    isReservationWithSameGuests = false,
    productInstance,
  }: LocationState = location.state || {};

  const defaultDateMoment = defaultDate ? moment(defaultDate) : moment();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openInstanceEditModal, setOpenInstanceEditModal] =
    useState<boolean>(false);
  const [productStatus, setProductStatus] = useState<
    SalesStatus | 'FREE_FORMAT'
  >('ON_SALE');
  const [errors, setErrors] = useState<string[]>([]);

  // Calendar date range
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment(defaultDateMoment.format())
      .startOf('month')
      .subtract(7, 'days'),
    endDate: moment(defaultDateMoment.format()).endOf('month').add(7, 'days'),
  });
  const [newProductId, setNewProductId] = useState<string>(productId ?? '');
  // State to keep track the selected Product Instance that user has clicked at calendar or passed via location
  // Show product instance panel if this state is not null.
  const [editingProductInstance, setEditingProductInstance] =
    useState<null | ProductInstance>(null);
  // State to show reservation list modal
  const [reservationListContext, setReservationListContext] =
    useState<null | ReservationListContext>(null);
  const locale = useSelector((state: ReduxState) => {
    return state.language.selected.iso;
  });
  const productInstancesLoading = useSelector((state: ReduxState) => {
    return state.productInstances.loading;
  });
  let products = useSelector(summariesSortedByBookmarkedSelector);
  const productInstances = useSelector(
    makeProductInstancesSelector(newProductId)
  );
  const product = useSelector((state: ReduxState) => {
    if (newProductId) {
      return state.products.byID[newProductId];
    }

    return null;
  });
  const activeUser = useSelector(activeUserSelector);
  const timezone = (product && product.start_timezone) || 'UTC';

  if (!operationAllowed(activeUser, 'write', 'productContents')) {
    products = products.filter(
      (product) => 'ON_SALE' === getProductSalesStatus(product)
    );
  }

  // Callback for product instance update via modal
  const [productInstanceIsUpdated, setProductInstanceIsUpdated] =
    useState(false);
  const handleProductInstanceUpdate = async (isUpdated: boolean) => {
    setProductInstanceIsUpdated(isUpdated);
  };
  useEffect(() => {
    if (!productInstancesLoading && productInstanceIsUpdated) {
      const productInstance = productInstances.find(
        (instance) => instance.id === editingProductInstance?.id ?? ''
      );
      if (productInstance) {
        setEditingProductInstance(productInstance);
      }
    }
  }, [productInstancesLoading, productInstanceIsUpdated]);

  // Fetch products
  useEffect(() => {
    dispatch(fetchProducts());
  }, [locale]);
  // Fetch product instance
  useEffect(() => {
    if (newProductId) {
      dispatch(
        fetchProductInstances(
          newProductId,
          dateRange.startDate,
          dateRange.endDate
        )
      );
    }
  }, [newProductId, dateRange]);

  // Fetch product
  useEffect(() => {
    if (newProductId) {
      dispatch(fetchProductByID(newProductId));
    }
  }, [newProductId]);

  // When product selection has changed
  const onChangeProduct = (productId: string) => {
    if (productId) {
      if (productId === 'FREE_FORMAT') {
        setNewProductId('');
      } else {
        setNewProductId(productId);
      }
    }
    setEditingProductInstance(null);
  };

  // Set product instance if it's passed from location
  useEffect(() => {
    if (productInstance) {
      setEditingProductInstance(productInstance);
    }
  }, [productInstance]);

  // When date range changed
  const onRangeChange = (range: any) => {
    let startDate: Moment;
    let endDate: Moment;

    if (range.start && range.end) {
      // 'month' view returns a {start: Date, end: Date} object
      startDate = moment(range.start).subtract(1, 'days');
      endDate = moment(range.end).add(1, 'days');
    } else {
      // 'day' and 'week' views return an array
      startDate = moment(range[0]).subtract(1, 'days');
      endDate = moment(range[range.length - 1]).add(1, 'days');
    }

    if (
      !operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      endDate.isSameOrBefore(moment())
    ) {
      // We won't show past product instances unless the user can confirm reservations so prevent fetch
      return;
    }

    setDateRange({
      startDate,
      endDate,
    });
  };

  // When Product instance is clicked at calendar, show Product Instance panel (with 3 buttons: Edit, Book, Reservation List)
  const onEventClick = (productInstance: ProductInstance | null) => {
    setEditingProductInstance(productInstance);
  };

  // When Reservation list button is clicked at the product instance modal
  const onReservationListClick = (
    reservationListContext: ReservationListContext | null
  ) => {
    setReservationListContext(reservationListContext);
  };

  const handleScrollToTop = () => {
    pageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <V3Page>
      <PageHeader title={t('Create New Reservation')}></PageHeader>
      <div className={baseStyles['l-main__body']} ref={pageRef}>
        <div className={baseStyles['l-main__body__flex']}>
          <div className={baseStyles['l-main__body__flex__left2']}>
            {/* Error display */}
            {errors.length > 0 && (
              <div className={styles['p-errorBox']}>
                <div className={styles['p-errorBox__ttl']}>{t('Error')}</div>
                <ul className={styles['p-errorBox__body']}>
                  {errors.map((err, index) => (
                    <li key={index}>{err as any as string}</li>
                  ))}
                </ul>
              </div>
            )}

            <CustomCalendar
              defaultDate={defaultDateMoment}
              productSelectorIsSearchable={true}
              productSelectorWithDisabled={true}
              title={t('Create new reservation')}
              events={
                productInstancesLoading
                  ? null
                  : getCustomCalendarEvents(
                      activeUser,
                      timezone,
                      productInstances,
                      locale,
                      t,
                      product?.request_booking_settings
                        ?.should_reject_bookings_beyond_capacity ?? false
                    )
              }
              timezone={timezone}
              locale={locale}
              product={product}
              onRangeChange={onRangeChange}
              onEventClick={onEventClick}
              loading={productInstancesLoading}
              showProductSelector={true}
              products={products}
              onChangeProduct={onChangeProduct}
              productStatus={productStatus}
              onChangeProductStatus={setProductStatus}
              initialProductId={productId}
            />

            {editingProductInstance && openInstanceEditModal && (
              <ProductInstanceEditModal
                oldProductInstance={editingProductInstance}
                onClose={() => setOpenInstanceEditModal(false)}
                open={openInstanceEditModal}
                onUpdate={handleProductInstanceUpdate}
              />
            )}

            {reservationListContext && (
              <ReservationListModal
                productIds={reservationListContext?.productIds || []}
                rootProductInstanceId={
                  reservationListContext?.rootProductInstanceId
                }
                date={reservationListContext?.date || ''}
                onClose={() => onReservationListClick(null)}
                open={reservationListContext !== null}
              />
            )}
          </div>
          <div
            className={clsx(
              baseStyles['l-main__body__flex__right2'],
              baseStyles['sticky']
            )}
          >
            {!editingProductInstance && productStatus !== 'FREE_FORMAT' && (
              <div className={styles['p-reservationsCreate__info']}>
                {t('Please select product and reservation slot')}
              </div>
            )}
            {editingProductInstance && (
              <ProductInstancePanel
                productName={getDisplayProductName(product)}
                allotmentSettings={product?.allotment_settings ?? null}
                productInstance={editingProductInstance}
                timezone={timezone}
                onEditClick={() => {
                  setEditingProductInstance;
                  setOpenInstanceEditModal(true);
                }}
                instance={toProductInstanceShape(editingProductInstance)}
                open={editingProductInstance !== null}
                onClose={() => setEditingProductInstance(null)}
                onListClick={() => {
                  onReservationListClick({
                    productIds: [product?.id || ''],
                    rootProductInstanceId: editingProductInstance?.id || '',
                    date: moment(editingProductInstance?.start_date_time_utc)
                      .tz(timezone)
                      .format('YYYY-MM-DD'),
                  });
                }}
                reservation={reservation}
                customerId={customerId}
                shouldRejectBookingsBeyondCapacity={
                  product?.request_booking_settings
                    ?.should_reject_bookings_beyond_capacity ?? false
                }
                onSelectProductInstanceError={(message) => {
                  setErrors([message]);
                  handleScrollToTop();
                }}
                // Set flags for new reservation origins
                isFromNewReservationModal={isFromNewReservationModal}
                isChangeReservation={isChangeReservation}
                isReservationWithSameGuests={isReservationWithSameGuests}
              />
            )}
          </div>
        </div>
      </div>
    </V3Page>
  );
};
