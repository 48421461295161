import * as React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from 'client/components/Message/Message';
import { Box } from 'client/components/Box/Box';
import { fetchProducts } from 'client/actions/products';
import { Select } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { getEmbeddedReviewUrl } from 'client/libraries/util/getEmbeddedReviewUrl';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import type { ReduxState } from 'client/reducers';

import { EmbeddedReview } from './EmbeddedReview/EmbeddedReview';
import { getEmbedTypePath, getEmbedTypeText } from './util';
import type { EmbedType } from './util';
import styles from './ReviewEmbed.module.css';

export const ReviewEmbed = () => {
  const [selectedProductId, setSelectedProductId] = React.useState<string>('');
  const [language, setLanguage] = React.useState<string>('ja-JP');
  const [embedType, setEmbedType] = React.useState<EmbedType>('STARS');
  const productOptions = useSelector(productOptionsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organization = useSelector(activeUserOrganizationSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);
  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';
  const embedReviewsUrl = getEmbeddedReviewUrl(
    bookingWidgetApiKey,
    selectedProductId,
    getEmbedTypePath(embedType),
    language
  );
  const embedTypeOptions = [
    'STARS',
    'REVIEW_SUMMARY',
    'REVIEW_SUMMARY_RECENT',
  ].map((embedType) => ({
    key: embedType,
    value: embedType,
    text: getEmbedTypeText(embedType as EmbedType, t),
  }));
  const languageOptions = (
    (organization && organization.supported_languages) ||
    []
  ).map((language) => ({
    text: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
    key: uppercaseIsoToLowercaseIso[language],
  }));

  if (!hasSubscription(organization, 'feature-review')) {
    return (
      <Message warning>
        {t(
          'Reviews not currently subscribed. Please contact sales@nutmeglabs.com if interested.'
        )}
      </Message>
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['input-box']}>
        <Select
          label={t('Select a product')}
          search
          maxWidth={400}
          value={selectedProductId}
          options={productOptions}
          onChange={(e, { value }) => {
            setSelectedProductId(value);
          }}
        />
      </div>
      {languageOptions.length > 0 ? (
        <div className={styles['input-box']}>
          <Select
            label={t('Select language')}
            maxWidth={400}
            value={language}
            options={languageOptions}
            onChange={(e, { value }) => {
              setLanguage(value);
            }}
          />
        </div>
      ) : (
        <Message warning>
          {t(
            'No supported languages are configured for your organization. Please contact sales@nutmeglabs.com.'
          )}
        </Message>
      )}
      <div className={styles['input-box']}>
        <Select
          label={t('Select embed Type')}
          maxWidth={400}
          value={embedType}
          options={embedTypeOptions}
          onChange={(e, { value }) => {
            setEmbedType(value as EmbedType);
          }}
        />
      </div>
      {selectedProductId && (
        <>
          <h3>{getEmbedTypeText(embedType, t)}</h3>
          <List relaxed>
            <List.Item>
              <List.Content>
                <Box mt={2} mb={2}>
                  <EmbeddedReview
                    key={embedType}
                    apiKey={bookingWidgetApiKey}
                    productId={selectedProductId}
                    language={language}
                    embedType={embedType}
                  />
                </Box>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Header>{t('Code')}</List.Header>
              <List.Content>
                <pre
                  className={styles['code-block']}
                >{`<div id="nutmeg-${embedType.toLowerCase()}-${selectedProductId}">
</div>
<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        var scriptUrl = 'https://assets.ntmg.com/scripts/falcon-embed-review/v1/index.js';
        if (window.NutmegReview) {
            NutmegReviewInit();
        } else {
            loadScriptAsync();
        }
        function loadScriptAsync() {
            var script = document.createElement('script');
            script.src = scriptUrl;
            script.async = true;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = NutmegReviewInit;
        }
        function NutmegReviewInit() {
            window.NutmegReview.buildEmbed('${selectedProductId}', '${embedReviewsUrl}', '${embedType}')
        }
    })();
    /*]]>*/
</script>`}</pre>
              </List.Content>
            </List.Item>
          </List>
        </>
      )}
    </div>
  );
};
