import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import type { ReduxState } from 'client/reducers';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { equipmentCalendarListDatesSelector } from 'client/reducers/equipmentCalendarListControls';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentsSelector } from 'client/reducers/equipments';
import { fetchEquipmentInstances } from 'client/actions/equipmentInstances';
import { fetchEquipments } from 'client/actions/equipments';
import {
  SeatAssignmentContext,
  SeatAssignmentModeType,
} from 'client/components/Seat/SeatAssignmentContext';
import { fetchReservations } from 'client/actions/reservations';

import { getEquipmentAvailabilities } from './util';
import { SeatAvailabilityCustomTable } from './SeatAvailabilityCustomTable';
import { EditSeatMapModal } from './EditSeatMapModal';

type CustomTableColumn = {
  accessor: string;
};
type Column = CustomTableColumn;

export const SeatAvailabilityTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const totalPaxByDate = useSelector(
    (state: ReduxState) => state.reservations.totalConfirmedPaxByDate
  );
  const dates = useSelector(equipmentCalendarListDatesSelector);
  const startDate = useSelector(
    (state: ReduxState) => state.equipmentCalendarListControls.startDate
  );
  const endDate = useSelector(
    (state: ReduxState) => state.equipmentCalendarListControls.endDate
  );
  const reservationCreating = useSelector(
    (state: ReduxState) => state.reservations.creating
  );

  const batchEditLoading = useSelector(
    (state: ReduxState) => state.equipmentInstances.batchEditLoading
  );
  const batchCloseLoading = useSelector(
    (state: ReduxState) => state.equipmentInstances.batchCloseLoading
  );

  const equipments = useSelector(equipmentsSelector);

  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipmentInstanceUpdateLoading = useSelector(
    (state: ReduxState) => state.equipmentInstances.updateLoading
  );
  const [columns, setColumns] = React.useState<Column[]>([]);

  const [editEquipmentInstanceId, setEditEquipmentInstanceId] = React.useState<
    string | null
  >(null);

  const [editMode, setEditMode] = React.useState<SeatAssignmentModeType>(null);

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(fetchEquipments());
    }
  }, [invalidated, isNutmegAdmin]);

  React.useEffect(() => {
    if (isNutmegAdmin) {
      return;
    }
    dispatch(
      fetchReservations({
        start_date_local_from: startDate,
        start_date_local_to: endDate,
        should_return_equipment_block_references: 'false',
      })
    );
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (equipments.length === 0) {
      return;
    }

    if (batchEditLoading || batchCloseLoading) {
      return;
    }

    equipments.forEach((equipment) => {
      dispatch(
        fetchEquipmentInstances(
          '',
          equipment.id,
          startDate,
          moment(endDate).add(-1, 'day').format('YYYY-MM-DD')
        )
      );
    });
  }, [startDate, endDate, equipments, batchEditLoading, batchCloseLoading]);

  React.useEffect(() => {
    if (reservationCreating) {
      return;
    }
    if (equipments.length === 0) {
      return;
    }

    equipments.forEach((equipment) => {
      dispatch(
        fetchEquipmentInstances(
          '',
          equipment.id,
          startDate,
          moment(endDate).add(-1, 'day').format('YYYY-MM-DD')
        )
      );
    });
  }, [reservationCreating]);

  React.useEffect(() => {
    setColumns([
      ...dates.map((date) => {
        return {
          id: date,
          accessor: date,
        };
      }),
    ]);
  }, [dates, dayMonthFormat, locale, setColumns, t, totalPaxByDate]);

  React.useEffect(() => {
    if (equipmentInstanceUpdateLoading) {
      return;
    }
    if (equipments.length === 0) {
      return;
    }
    equipments.forEach((equipment) => {
      dispatch(fetchEquipmentInstances('', equipment.id, startDate, endDate));
    });
  }, [equipmentInstanceUpdateLoading]);

  const equipmentAvailabilities = getEquipmentAvailabilities(
    startDate,
    endDate,
    equipments,
    equipmentInstances
  );

  return (
    <>
      <SeatAvailabilityCustomTable
        columns={columns}
        items={equipmentAvailabilities}
        usePaging={true}
        onEditEquipmentInstanceClick={setEditEquipmentInstanceId}
      />
      {editEquipmentInstanceId && (
        <SeatAssignmentContext.Provider value={{ editMode, setEditMode }}>
          <EditSeatMapModal
            open={!!editEquipmentInstanceId}
            onClose={() => setEditEquipmentInstanceId(null)}
            selectedEquipmentInstanceId={editEquipmentInstanceId}
          />
        </SeatAssignmentContext.Provider>
      )}
      <Loader active={equipmentInstanceUpdateLoading} />
    </>
  );
};
