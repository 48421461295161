import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';

import { Box } from 'client/components/Box/Box';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import baseStyles from 'client/base.module.css';

export const ReviewSettings = () => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);

  const kioskUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key ?? ''
  )}/review/verify`;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <FormTableBox>
          <table>
            <tr>
              <th>{t('Review Page Link')}</th>
              <td>
                <a
                  className={baseStyles['base-link']}
                  href={kioskUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {kioskUrl}
                </a>
                <Box mt={2} mb={2}>
                  <QRCode value={kioskUrl} />
                </Box>
              </td>
            </tr>
          </table>
        </FormTableBox>
      </div>
    </div>
  );
};
