import Amplify from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

import { config } from 'client/config';
import { loginSuccess } from 'client/actions/user';

Amplify.configure(config.cognitoConfig);

export const getIDToken = (cognito: any) =>
  cognito &&
  cognito.signInUserSession &&
  cognito.signInUserSession.idToken &&
  cognito.signInUserSession.idToken.jwtToken;
export const isSignedIn = (cognito: any) => !!getIDToken(cognito);
// cognitoTokenRefreshMiddleware refreshes Cognito tokens if they are expired for
// the current logged-in user.
export const cognitoTokenRefreshMiddleware =
  (store: any) => (next: any) => (action: any) => {
    if (typeof action !== 'function') {
      return next(action);
    }

    const { cognito } = store.getState().user;
    const sess = cognito && cognito.signInUserSession;

    if (sess && !sess.isValid()) {
      return (
        Auth.currentAuthenticatedUser()
          .then((user) => {
            const currentUser = Auth.currentUserInfo();
            currentUser.then((userInfo) => {
              if (userInfo && userInfo.attributes) {
                store.dispatch(loginSuccess(user, userInfo.attributes));
              }

              return next(action);
            });
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
      );
    }

    return next(action);
  };
