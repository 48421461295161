import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { getCommonFormFieldsByKey } from 'client/libraries/util/coreutil';
import { fetchProducts, fetchProductByID } from 'client/actions/products';
import { updateManifestExcludedFormFields } from 'client/actions/manifestSettings';
import { manifestExcludedFormFieldsSelector } from 'client/reducers/manifestSettings';
import { productOptionsSelector } from 'client/reducers/products';
import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

const formFieldKeysExcludedByDefault = [
  'given_name',
  'family_name',
  'representative_name',
  'hotel_information_checkin_checkout',
  'hotel_information',
  'hotel_tbd_form',
];

type Props = {
  open: boolean;
  onClose: () => void;
};

export const EditExcludedFormFieldsModal = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const [newExcludedFormFields, setNewExcludedFormFields] = useState<string[]>(
    []
  );
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const productOptions = useSelector((state: ReduxState) =>
    productOptionsSelector(state)
  );
  const productsById = useSelector((state: ReduxState) => state.products.byID);
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const existingExcludedFormFields = useSelector((state: ReduxState) =>
    manifestExcludedFormFieldsSelector(state)
  );

  const selectedProduct = productsById[selectedProductId];
  const { t } = useTranslation();
  const commonFormFieldsByKey = getCommonFormFieldsByKey(t);

  useEffect(() => {
    setNewExcludedFormFields(existingExcludedFormFields);
  }, [existingExcludedFormFields]);

  useEffect(() => {
    if (productOptions.length === 0) {
      dispatch(fetchProducts());
    }
  }, [productOptions.length, fetchProducts]);

  useEffect(() => {
    if (!selectedProduct) {
      dispatch(fetchProductByID(selectedProductId));
    }
  }, [selectedProductId, selectedProduct, fetchProductByID]);

  return (
    <Modal
      title={t('Edit Filtered Required Info Fields')}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            color="white"
            onClick={() => {
              setNewExcludedFormFields(existingExcludedFormFields);
              onClose();
            }}
          />
          <Button
            text={t('Save')}
            disabled={newExcludedFormFields === existingExcludedFormFields}
            onClick={async () => {
              await setIsSaving(true);
              await dispatch(
                updateManifestExcludedFormFields(newExcludedFormFields)
              );
              await setIsSaving(false);
              await onClose();
            }}
            loading={isSaving}
          />
        </>
      }
      style={{ width: '600px', height: '600px' }}
    >
      <div className={styles['p-manifests__filterRequiredField']}>
        {existingExcludedFormFields.length > 0 && (
          <table
            className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          >
            <tbody>
              {existingExcludedFormFields.map((fieldKey) => {
                const fieldText =
                  commonFormFieldsByKey[fieldKey ?? '']?.text ?? fieldKey;

                return newExcludedFormFields.indexOf(fieldKey) !== -1 ? (
                  <tr key={fieldKey} style={{ display: 'flex' }}>
                    <td style={{ width: '50%', display: 'block' }}>
                      <Button
                        text={t('Include')}
                        color="white"
                        onClick={() =>
                          setNewExcludedFormFields(
                            newExcludedFormFields.filter(
                              (field) => field !== fieldKey
                            )
                          )
                        }
                      />
                    </td>
                    <td
                      style={{
                        width: '50%',
                        marginTop: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ opacity: 0.4 }}>{fieldText}</p>
                    </td>
                  </tr>
                ) : (
                  <tr key={fieldKey} style={{ display: 'flex' }}>
                    <td style={{ width: '50%', display: 'block' }}>
                      <Button
                        text={t('Exclude')}
                        color="primary"
                        onClick={() =>
                          setNewExcludedFormFields([
                            ...new Set([...newExcludedFormFields, fieldKey]),
                          ])
                        }
                      />
                    </td>
                    <td
                      style={{
                        width: '50%',
                        marginTop: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p>{fieldText}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <p className={styles['p-manifests__filterRequiredField__msg']}>
          {t(
            'Please select product and exclude unnecessary items in the manifest. Excluded items will not be displayed in the manifest.'
          )}
        </p>
        <SingleDropdown
          label={t('Select product')}
          options={productOptions}
          selectedOption={selectedProductId}
          onChange={(value) => setSelectedProductId(value)}
          disabled={productOptions.length === 0}
        />
        {selectedProduct &&
        selectedProduct.reservation_form_fields &&
        selectedProduct.reservation_form_fields?.filter(
          (f: any) => formFieldKeysExcludedByDefault.indexOf(f.key) === -1
        ).length > 0 ? (
          <table
            className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
          >
            <tbody>
              {selectedProduct.reservation_form_fields
                ?.filter(
                  (f: any) =>
                    formFieldKeysExcludedByDefault.indexOf(f.key) === -1
                )
                .map((f: any) => {
                  const fieldText =
                    commonFormFieldsByKey[f.key ?? '']?.text ?? f.key;

                  return newExcludedFormFields.indexOf(f.key) !== -1 ? (
                    <tr key={f.key} style={{ display: 'flex' }}>
                      <td style={{ width: '50%', display: 'block' }}>
                        <Button
                          text={t('Include')}
                          color="white"
                          onClick={() =>
                            setNewExcludedFormFields(
                              newExcludedFormFields.filter(
                                (field) => field !== f.key
                              )
                            )
                          }
                        />
                      </td>
                      <td
                        style={{
                          width: '50%',
                          marginTop: 0,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ opacity: 0.4 }}>{fieldText}</p>
                      </td>
                    </tr>
                  ) : (
                    <tr key={f.key} style={{ display: 'flex' }}>
                      <td style={{ width: '50%', display: 'block' }}>
                        <Button
                          text={t('Exclude')}
                          color="primary"
                          onClick={() =>
                            setNewExcludedFormFields([
                              ...new Set([
                                ...newExcludedFormFields,
                                f.key ?? '',
                              ]),
                            ])
                          }
                        />
                      </td>
                      <td
                        style={{
                          width: '50%',
                          marginTop: 0,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <p>{fieldText}</p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : loading ? (
          <ModalLoader />
        ) : null}
      </div>
    </Modal>
  );
};
