import { useForm } from 'react-final-form';

import { ComposeMessage } from './ComposeMessage';
import { WebsitePopupEditor } from './WebsitePopupEditor';
import { SmsEditor } from './SmsEditor';
import { PushNotificationEditor } from './PushNotificationEditor';

interface Props {
  onBackClick: () => void;
}

export const ContentEditor = ({ onBackClick }: Props) => {
  const form = useForm();

  const contentType = form.getState().values.type;

  if (contentType === 'EMAIL') {
    return <ComposeMessage onBackClick={onBackClick} />;
  } else if (contentType === 'WEBSITE_POPUP') {
    return <WebsitePopupEditor onBackClick={onBackClick} />;
  } else if (contentType === 'SMS') {
    return <SmsEditor onBackClick={onBackClick} />;
  }

  return <PushNotificationEditor onBackClick={onBackClick} />;
};
