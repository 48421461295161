import {
  SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION,
  SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS,
  SET_INSTANT_WIN_EVENT_ROW_COUNT,
  SET_INSTANT_WIN_EVENT_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { SearchInstantWinEventsRequest } from 'client/libraries/util/searchInstantWinEvents';

export const setInstantWinEventVisibleColumns = (visibleColumns: string[]) => ({
  type: SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS,
  visibleColumns,
});
export const setLastExecutedInstantWinEventSearchCondition = (
  searchFilters: SearchInstantWinEventsRequest
) => ({
  type: SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION,
  searchFilters,
});
export const setInstantWinEventRowCount = (rowCount: number) => ({
  type: SET_INSTANT_WIN_EVENT_ROW_COUNT,
  rowCount,
});
export const setInstantWinEventCurrentPage = (currentPage: number) => ({
  type: SET_INSTANT_WIN_EVENT_CURRENT_PAGE,
  currentPage,
});
