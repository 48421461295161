import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { fetchProducts } from 'client/actions/products';
import { productOptionsSelector } from 'client/reducers/products';
import { ReduxState } from 'client/reducers';

interface Props {
  onImport: (photoUrls: string[]) => void;
  onClose: () => void;
}

export const ImportPhotosModal = ({ onClose, onImport }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productId, setProductId] = React.useState('');
  const [photoUrls, setPhotoUrls] = React.useState<string[]>([]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  const productOptions = useSelector(productOptionsSelector);
  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  return (
    <Modal title={t('Import Photos')} open={true} onClose={onClose}>
      <Modal.Content>
        <Select
          label={t('Product')}
          value={productId}
          onChange={(e, { value }) => {
            setProductId(value);

            const product = productSummaries.find((p) => p.id === value);

            console.log('product', product);

            setPhotoUrls(
              product?.media
                ?.filter((item) => item.type === 'IMAGE')
                .map((item) => item.url) ?? []
            );
          }}
          options={productOptions}
        />
        {productId && (
          <ImageVideoAudioInput
            fileUrls={photoUrls}
            onChange={(newValue) => setPhotoUrls(newValue)}
            disableYoutubeVideos
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onImport(photoUrls);
            onClose();
          }}
          size="middle"
          style="blue"
        >
          {t('Import')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
