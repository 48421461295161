import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm } from 'react-final-form';

import { MultiSelect, Select } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import styles from './ResponseOptionsEditor.module.css';
import { SurveyPage, SurveyQuestion } from './formValues';

interface Props {
  name: string;
}

export const CustomConditionsEditor = ({ name }: Props) => {
  const form = useForm();
  const values = form.getState().values;

  const questionCandidates: SurveyQuestion[] =
    values.pages?.flatMap((page: SurveyPage) => {
      return (
        page.surveyQuestions?.filter(
          (question) =>
            (question.preset !== 'gender' &&
              (question.responseType === 'SURVEY_QUESTION_SELECT_ONE' ||
                question.responseType === 'SURVEY_QUESTION_SELECT_MULTIPLE')) ??
            []
        ) ?? []
      );
    }) ?? [];
  const questionOptions = questionCandidates.map((question) => ({
    text: question.text,
    value: question.key,
  }));

  return (
    <div className={styles['detailInfoEdit']}>
      <FieldArray name={name}>
        {({ fields }) =>
          fields.length === 0 ? (
            <AddIcon
              onClick={() =>
                (fields as any).insertAt(0, {
                  customQuestionKey: '',
                  customQuestionResponse: '',
                })
              }
            />
          ) : (
            fields.map((name, idx) => (
              <ul key={name} className={styles['detailInfoEditBody']}>
                <li>
                  <Field name={`${name}.customQuestionKey`}>
                    {({ input }) => (
                      <>
                        <div>
                          <Select
                            value={input.value}
                            onChange={(e, { value }) => {
                              input.onChange(value);
                              form.change(
                                `${name}.customQuestionResponses`,
                                []
                              );
                            }}
                            options={questionOptions}
                          />
                          <Field name={`${name}.customQuestionResponses`}>
                            {({ input: responsesInput }) => (
                              <MultiSelect
                                options={
                                  questionCandidates
                                    ?.find(
                                      (question) => question.key === input.value
                                    )
                                    ?.options?.map((opt) => ({
                                      text: opt.text,
                                      value: opt.key,
                                    })) ?? []
                                }
                                selectedValues={
                                  /* On page initialization, value may be string. Check for array type. */
                                  Array.isArray(responsesInput.value)
                                    ? responsesInput.value
                                    : []
                                }
                                onChange={({ value }) =>
                                  responsesInput.onChange(value)
                                }
                              />
                            )}
                          </Field>
                        </div>
                        <div className={baseStyles['base-flex']}>
                          <AddIcon
                            onClick={() =>
                              (fields as any).insertAt(idx + 1, {
                                customQuestionKey: '',
                                customQuestionResponse: '',
                              })
                            }
                          />
                          <DeleteIcon onClick={() => fields.remove(idx)} />
                        </div>
                      </>
                    )}
                  </Field>
                </li>
              </ul>
            ))
          )
        }
      </FieldArray>
    </div>
  );
};
