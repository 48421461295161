import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { GroupByStartTimeTableList } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/GroupByStartTimeTableList';
import { useReservationData } from 'client/pages/v3/FlexibleDashboard/useReservationData';
import { ModalLoader } from 'client/components/ModalLoader';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { ReduxState } from 'client/reducers';
import { ReservationListTable } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListTable';
import { toFlexibleDashboardReservationListShape } from 'client/libraries/util/flexibleDashboardReservationListShape';
import { GroupByProductTableList } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/GroupByProductTableList';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import { ReservationColumn } from 'shared/models/swagger';

interface Props {
  gadget: DashboardGadget;
  disableDatePicker?: boolean;
}

export const ReservationListGadget = ({ gadget }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useReservationData(gadget);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = (data ?? []).map((r) =>
    toFlexibleDashboardReservationListShape(r, locale, t)
  );

  const [groupByProduct, setGroupByProduct] = useState(false);
  const [groupByStartTime, setGroupByStartTime] = useState(false);
  const dashboardSettings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );

  useEffect(() => {
    const gadget = dashboardSettings?.gadgets?.find(
      (g) => g.gadgetType === 'reservation-list'
    );
    if (gadget && gadget.gadgetType === 'reservation-list') {
      setGroupByStartTime(gadget.params.shouldGroupByStartTime ?? false);
      setGroupByProduct(gadget.params.shouldGroupByProduct ?? false);
    }
  }, [dashboardSettings]);

  const visibleColumns =
    gadget.gadgetType === 'reservation-list'
      ? gadget.params.defaultColumns ?? ([] as ReservationColumn[])
      : ([] as ReservationColumn[]);

  const manifestExcludedFormFieldKeys: string[] = [];
  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);

  if (isLoading) {
    return <ModalLoader />;
  } else {
    if (reservations.length === 0) {
      return <div>{t('No data to display for the selected data set')}</div>;
    }
  }

  return (
    <>
      {groupByProduct ? (
        <GroupByProductTableList
          excludedFormFieldKeys={manifestExcludedFormFieldKeys}
          products={productSummaries}
          reservations={reservations}
          visibleColumns={visibleColumns}
          showDispatchColumn={true}
          splitStartTimes={groupByStartTime}
          rootResource={{
            id: 'ALL_PRODUCTS',
            type: 'ALL_PRODUCTS',
          }}
          gadgetKey={gadget.key}
        />
      ) : (
        <>
          {groupByStartTime ? (
            <GroupByStartTimeTableList
              excludedFormFieldKeys={manifestExcludedFormFieldKeys}
              products={productSummaries}
              reservations={reservations}
              visibleColumns={visibleColumns}
              showDispatchColumn={true}
              gadgetKey={gadget.key}
            />
          ) : (
            <ReservationListTable
              excludedFormFieldKeys={manifestExcludedFormFieldKeys}
              products={productSummaries}
              reservations={reservations}
              visibleColumns={visibleColumns}
              gadgetKey={gadget.key}
            />
          )}
        </>
      )}
    </>
  );
};
