import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { Checkbox } from 'client/components/Form';

export const GuestMyPageSettings = () => {
  const { t } = useTranslation();
  const checkboxLabelStyle = {
    background: '#fff',
    fontSize: '14px',
  };
  return (
    <div>
      <div>{t('Allowed operations on My Page for Guest')}</div>
      <Field name="allowViewingReservationDetails">
        {({ input }) => (
          <Checkbox
            labelStyle={checkboxLabelStyle}
            label={t('View reservation details')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
      <Field name="allowUpdatingReservationInfo">
        {({ input }) => (
          <Checkbox
            labelStyle={checkboxLabelStyle}
            label={t('Update hotel, form answers')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
      <Field name="allowChangingReservationParameters">
        {({ input }) => (
          <Checkbox
            labelStyle={checkboxLabelStyle}
            label={t(
              'Change reservations (Participation date, time, participants, add-on, transportation)'
            )}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
      <Field name="allowCancellingReservation">
        {({ input }) => (
          <Checkbox
            labelStyle={checkboxLabelStyle}
            label={t('Cancel')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
      <Field name="allowIssuingDownloadingReceiptPDF">
        {({ input }) => (
          <Checkbox
            labelStyle={checkboxLabelStyle}
            label={t('Issue receipt/download')}
            checked={input.value}
            onChange={() => input.onChange(!input.value)}
          />
        )}
      </Field>
    </div>
  );
};
