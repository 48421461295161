import { createSelector } from 'reselect';
import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_DASH_VISIBLE_COLUMNS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import type { ReduxState } from 'client/reducers';

// Selectors
export const dashColumnCandidatesSelector = createSelector(
  activeUserSelector,
  (activeUser) => {
    const isSupplier = activeUser?.organization_type === 'SUPPLIER';
    const isAgent = activeUser?.organization_type !== 'SUPPLIER';
    return [
      'action_item',
      ...(operationAllowed(activeUser, 'read', 'reservationBookingSource')
        ? ['bookingSource']
        : []),
      ...(isAgent ? ['agentGuestLastUpdatedByActorNameOnly'] : []),
      ...(isSupplier ? ['supplierLastUpdatedByActorNameOnly'] : []),
      'participationDateTime',
      'productName',
      'customerName',
      'pickupCheckinTime',
      'pickupCheckinLocation',
      'agentReference',
      'supplierReference',
      'supplierShortReference',
      ...(isAgent ? ['supplierName'] : []),
      ...(isSupplier ? ['agentGuestLastUpdatedByFullText'] : []),
      ...(isAgent ? ['supplierLastUpdatedByFullText'] : []),
    ];
  }
);

const visibleColumnsSelector = (state: ReduxState) =>
  state.dashTableControls.visibleColumns;

export const dashVisibleColumnsSelector = createSelector(
  visibleColumnsSelector,
  dashColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);
// Reducers
const defaultVisibleColumns = [
  'action_item',
  'bookingSource',
  'agentGuestLastUpdatedByActorNameOnly',
  'supplierLastUpdatedByActorNameOnly',
  'participationDateTime',
  'productName',
  'customerName',
  'pickupCheckinTime',
  'pickupCheckinLocation',
  'agentReference',
  'supplierReference',
  'supplierShortReference',
  'supplierName',
  'agentGuestLastUpdatedByFullText',
  'supplierLastUpdatedByFullText',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_DASH_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

type State = { visibleColumns: string[] };
const reducer = combineReducers<State>({
  visibleColumns,
});
export const dashTableControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
