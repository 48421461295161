import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextArea } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { fetchContracts, updateContract } from 'client/actions/contract';
import { ReduxState } from 'client/reducers';

interface Props {
  initialValue: string[];
  onClose: () => void;
  onSave: (address: string[]) => void;
  agentId: string;
}

export const EditAgentAddressModal = ({
  initialValue,
  onClose,
  onSave,
  agentId,
}: Props) => {
  const [address, setAddress] = React.useState(initialValue.join('\n'));
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contracts = useSelector((state: ReduxState) => state.contracts.all);

  React.useEffect(() => {
    dispatch(fetchContracts());
  }, []);

  const contract = contracts.find((contract) => contract.agent_id === agentId);

  return (
    <Modal insertRoot title={t('Edit address')} open={true} onClose={onClose}>
      <Modal.Content>
        <TextArea
          label={t('Address')}
          value={address}
          onChange={(e, { value }) => setAddress(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        <Button.Submit
          loading={loading}
          disabled={!contract}
          onClick={async () => {
            setLoading(true);

            const formattedAddress = address.split('\n');
            await dispatch(
              updateContract(contract?.id ?? '', {
                agent_invoice_formatted_mailing_address: formattedAddress,
              })
            );
            await onSave(formattedAddress);
            onClose();
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
