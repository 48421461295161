import clsx from 'clsx';

import copyIcon from 'client/images/ic_copy.svg';
import baseStyles from 'client/base.module.css';

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
};
export const Copy = ({ onClick, type }: Props) => {
  return (
    <button
      className={clsx(
        baseStyles['base-btn'],
        baseStyles['icon'],
        baseStyles['narrow']
      )}
      onClick={onClick}
      type={type}
    >
      <img src={copyIcon} />
    </button>
  );
};
