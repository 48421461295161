import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  UPLOAD_JARAN_RESERVATION_CSV_FAILURE,
  UPLOAD_JARAN_RESERVATION_CSV_REQUEST,
  UPLOAD_JARAN_RESERVATION_CSV_SUCCESS,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST,
  FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
} from 'client/constants/ActionTypes';
import type {
  GetJaranReservationImportOrdersResponse,
  JaranReservationImportOrder,
} from 'shared/models/swagger';

const uploadLoading = (state = false, action: any) => {
  switch (action.type) {
    case UPLOAD_JARAN_RESERVATION_CSV_REQUEST:
      return true;

    case UPLOAD_JARAN_RESERVATION_CSV_SUCCESS:
    case UPLOAD_JARAN_RESERVATION_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST:
      return true;

    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS:
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED:
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case UPLOAD_JARAN_RESERVATION_CSV_FAILURE:
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE:
      return action.error;

    case UPLOAD_JARAN_RESERVATION_CSV_SUCCESS:
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const all = (
  state: JaranReservationImportOrder[] = [],
  action: any
): Array<JaranReservationImportOrder> => {
  switch (action.type) {
    case FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS: {
      const resp =
        action.response as any as GetJaranReservationImportOrdersResponse;
      return resp.jaran_reservation_import_orders || [];
    }

    default:
      return state;
  }
};

const reducer = combineReducers({
  error,
  loading,
  uploadLoading,
  all,
});

export const jaranReservationImportOrders = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
