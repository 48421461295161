import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { removeAllReservationNotifications } from 'client/actions/notifications';

import { ReservationNotificationListElement } from './ReservationNotificationListElement';
import styles from './ReservationNotificationList.module.css';

export const ReservationNotificationList = () => {
  const reservationNotifications = useSelector(
    (state: ReduxState) => state.notifications.reservationNotifications
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles['snackbar-container']}>
      <div className={styles['snackbar']}>
        <a
          className={styles['clear-btn']}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(removeAllReservationNotifications());
          }}
        >
          {t('Clear all notifications')}
        </a>
      </div>
      {reservationNotifications.map((notification, idx) => (
        <ReservationNotificationListElement
          key={idx}
          notification={notification}
        />
      ))}
    </div>
  );
};
