import React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { StartTimeManifestReservationsTableList } from 'client/components/StartTimeManifestReservationsTableList/StartTimeManifestReservationsTableList';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchDispatchReservations } from 'client/actions/dispatch';
import { fetchGuideSingleDaySchedules } from 'client/actions/guides';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { sendManifestEmail } from 'client/actions/manifests';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import {
  manifestCustomizedColumnNamesSelector,
  manifestExcludedFormFieldsSelector,
} from 'client/reducers/manifestSettings';
import { SendEmailModal } from 'client/components/SendEmailModal/SendEmailModal';
import { ParticipationDate } from 'client/components/ParticipationDate/ParticipationDate';
import anotherIcon from 'client/images/ic_another.svg';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import styles from './Schedule.module.css';

type Props = {
  isActive?: boolean;
};
export const ReservationList = ({ isActive }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [participationDate, setParticipationDate] = React.useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [showAnotherButtons, setShowAnotherButtons] =
    React.useState<boolean>(false);
  const [showSendEmailModal, setShowSendEmailModal] =
    React.useState<boolean>(false);
  const activeUser = useSelector(activeUserSelector);
  const emailStatus = useSelector(
    (state: ReduxState) => state.manifests.emailStatus
  );
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const loading = useSelector((state: ReduxState) => state.dispatch.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organization = useSelector(activeUserOrganizationSelector);
  const excludedFormFieldKeys = useSelector(manifestExcludedFormFieldsSelector);
  const reservations = useSelector(
    (state: ReduxState) => state.dispatch.reservations
  ).map((reservation) => toManifestReservationShape(reservation, t));
  const manifestCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  React.useEffect(() => {
    dispatch(
      fetchDispatchReservations({
        date: participationDate,
      })
    );
  }, [participationDate]);
  React.useEffect(() => {
    if (isActive) {
      dispatch(
        fetchGuideSingleDaySchedules({
          date_local_from: participationDate,
          date_local_to: participationDate,
        })
      );
    }
  }, [participationDate, isActive]);
  const guideSettings = (organization?.guides || []).find(
    (guide) => guide.id === activeUser?.id
  );
  const filteredReservations = (reservations || []).filter((reservation) =>
    (reservation.dispatch_guides || []).includes(activeUser?.id ?? '')
  );
  const guideSingleDaySchedule = guideSingleDaySchedules.find(
    (schedule) => schedule.date_local === participationDate
  );
  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <div className={clsx(styles['schedule__spacer'])}>
              <ParticipationDate
                value={participationDate}
                onChange={setParticipationDate}
                locale={locale}
              />
            </div>
          </div>

          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-main__body__header__right__another'],
                showAnotherButtons
                  ? baseStyles['is-open']
                  : baseStyles['is-close']
              )}
            >
              <ul>
                {hasSubscription(organization, 'feature-qr-checkin') && (
                  <li>
                    <Box mr={2}>
                      <Link
                        className={clsx(
                          baseStyles['base-btn'],
                          baseStyles['middle'],
                          baseStyles['blue']
                        )}
                        to="/qrreader"
                        target="_blank"
                      >
                        <p>{t('Go To QR Reader')}</p>
                        <img src={newWindowIcon} />
                      </Link>
                    </Box>
                  </li>
                )}

                <li>
                  <a
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['gray'],
                      baseStyles['middle']
                    )}
                    onClick={() => {
                      setShowSendEmailModal(true);
                    }}
                  >
                    {t('Send Email')}
                  </a>
                </li>
              </ul>
              <a
                onClick={() => {
                  setShowAnotherButtons(!showAnotherButtons);
                }}
              >
                <img src={anotherIcon} />
              </a>
            </div>
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          {guideSingleDaySchedule && guideSingleDaySchedule.supplier_note && (
            <Message success header={guideSingleDaySchedule.supplier_note} />
          )}
          {filteredReservations.length > 0 && (
            <>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <StartTimeManifestReservationsTableList
                  products={[]}
                  excludedFormFieldKeys={excludedFormFieldKeys}
                  reservations={filteredReservations}
                  visibleColumns={guideSettings?.columns ?? ([] as any)}
                  showTimeOnly={true}
                  resourceType={'guide'}
                  resourceKey={activeUser?.id || ''}
                  customizedColumnNames={manifestCustomizedColumnNames}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <SendEmailModal
        title={t('Send Email')}
        value={{
          subject: participationDate
            ? t('Schedule - {{formattedDate}}', {
                formattedDate: participationDate,
              })
            : t('Schedule'),
          toAddresses: [],
        }}
        open={showSendEmailModal}
        onClose={() => {
          setShowSendEmailModal(false);
        }}
        emailStatus={emailStatus}
        onSendClick={(value) => {
          return dispatch(
            sendManifestEmail({
              date: participationDate,
              columns: guideSettings?.columns,
              timezone: organization?.default_timezone || '',
              subject: value.subject,
              to_addresses: value.toAddresses,
              dispatch_guides: guideSettings ? [guideSettings?.id || ''] : [],
            })
          );
        }}
      />

      {loading && <Loading />}
    </>
  );
};
