import { fetchSystemFeeInvoiceCSV } from 'client/actions/systemFeeInvoices';
import { fetchPartnershipSystemFeeInvoiceCSV } from 'client/actions/partnershipSystemFeeInvoices';
import { TranslateFuncType } from 'client/components/Translate';
import {
  ReservationStatus,
  SystemFeeInvoiceColumn,
} from 'shared/models/swagger';

export interface ColumnType {
  Header: string;
  id: SystemFeeInvoiceColumn;
}

export const getSystemFeeInvoiceCSVColumns = (
  t: TranslateFuncType,
  partnershipMode?: boolean
): ColumnType[] => {
  return [
    {
      Header: t('Application Number'),
      id: 'AGENT_REFERENCE',
    },
    {
      Header: t('Participation'),
      id: 'PARTICIPATES_AT',
    },
    {
      Header: t('Customer'),
      id: 'GUEST_DISPLAY_NAME',
    },
    {
      Header: t('Status'),
      id: 'STATUS',
    },
    {
      Header: t('Product'),
      id: 'PRODUCT_NAME',
    },
    {
      Header: t('Units'),
      id: 'GUEST_DESCRIPTION',
    },
    {
      Header: t('Total Pax'),
      id: 'GUEST_COUNT',
    },
    {
      Header: t('Payment Type'),
      id: 'PAYMENT_TYPE',
    },
    {
      Header: t('Credit Card Brand'),
      id: 'CARD_BRAND',
    },
    {
      Header: t('Gross'),
      id: 'GROSS',
    },
    {
      Header: t('Cancel Fee'),
      id: 'CANCELLATION_FEE',
    },
    ...(partnershipMode
      ? ([
          {
            Header: t('Commission'),
            id: 'COMMISSION',
          },
          {
            Header: t('Booked Date'),
            id: 'BOOKED_AT',
          },
          {
            Header: t('Agent'),
            id: 'AGENT_NAME',
          },
        ] as ColumnType[])
      : ([
          {
            Header: t('Email Payment'),
            id: 'USES_EMAIL_PAYMENT',
          },
          {
            Header: t('Booking Fee'),
            id: 'BOOKING_FEE',
          },
          {
            Header: t('Credit Card Fee'),
            id: 'CREDIT_CARD_FEE',
          },
        ] as ColumnType[])),
  ];
};

export type DownloadSystemFeeInvoiceCSVRequest = {
  timezone: string;
  columns: SystemFeeInvoiceColumn[];
  productIds: string[];
  paymentTypes: string[];
  reservationStatuses: ReservationStatus[];
  transferDateFrom: string;
  transferDateTo: string;
  settlementType?: 'NUTMEG' | 'DIRECT';
};

export const downloadSystemFeeInvoiceCSV = (
  id: string,
  req: DownloadSystemFeeInvoiceCSVRequest
) =>
  fetchSystemFeeInvoiceCSV(id, {
    timezone: req.timezone,
    columns: req.columns.join(','),
    product_ids: req.productIds,
    payment_types: req.paymentTypes,
    reservation_statuses: req.reservationStatuses,
    payout_date_from: req.transferDateFrom,
    payout_date_to: req.transferDateTo,
    settlement_type: req.settlementType,
  });

export const downloadPartnershipSystemFeeInvoiceCSV = (
  id: string,
  req: DownloadSystemFeeInvoiceCSVRequest
) =>
  fetchPartnershipSystemFeeInvoiceCSV(id, {
    timezone: req.timezone,
    columns: req.columns.join(','),
    product_ids: req.productIds,
    payment_types: req.paymentTypes,
    reservation_statuses: req.reservationStatuses,
    payout_date_from: req.transferDateFrom,
    payout_date_to: req.transferDateTo,
    settlement_type: req.settlementType,
  });
