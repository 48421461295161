import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';

import { fetchProductTags } from 'client/actions/productTags';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import {
  defaultProductCurrencySelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { ProductEditDetailPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditDetailPopUp';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import { ProductEditorForm } from 'client/pages/v3/Product/ProductCreate/ProductCreateContents/ProductEditorForm';
import { HighlightsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/HighlightsEditor';
import { TagsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/TagsEditor';
import { InternalTagsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/InternalTagsEditor';
import { DetailInfoEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/DetailInfoEditor';
import { ItineraryEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ItineraryEditor';
import { ServicesEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ServicesEditor';
import { ReservationFormEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/ReservationFormEditor';
import { CheckinPickupLocationsEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/CheckinPickupLocationsEditor';
import { TransportRouteEditor } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/SectionEditor/TransportRouteEditor';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { CollapsibleSection } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleSection';
import {
  ProductFormValues,
  convertFormValuesToProductPatch,
  getInitialValues,
} from 'client/pages/v3/Product/ProductEdit/ProductEditContents/FormValues';

type Props = {
  onSubmitSuccess: () => void;
  onSubmitFailed: () => void;
  setCurrentSectionId: Dispatch<SetStateAction<string>>;
};

export const DetailInformationEditor = ({
  onSubmitSuccess,
  onSubmitFailed,
  setCurrentSectionId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductTags());
  }, []);

  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = useState<boolean>(false);
  useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const editingProduct = useContext(EditingProductContext);
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;

  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const initialValues = useMemo(
    () =>
      getInitialValues(
        editingProduct,
        defaultCurrency,
        defaultTimezone,
        t,
        false
      ),
    [editingProduct, defaultCurrency, defaultTimezone, t]
  );
  const convertToProductPatch = useCallback(
    (values: ProductFormValues) =>
      convertFormValuesToProductPatch(
        values,
        defaultCurrency,
        isPackage,
        editingProduct,
        t,
        false
      ),
    [editingProduct, t, defaultCurrency, false]
  );

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  return (
    <>
      <ProductEditorForm
        id="createProductDetailInformationForm"
        onSubmitSuccess={onSubmitSuccess}
        onSubmitFailed={onSubmitFailed}
        initialValues={initialValues}
        convertFormValuesToProductPatch={convertToProductPatch}
        subscription={{
          submitError: true,
          submitting: true,
        }}
      >
        {({ submitError }) => (
          <>
            <Snackbar
              text={t('Save Failed')}
              color="error"
              shouldShow={submitError}
            />
            <section
              id="detail"
              className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
            >
              <p className={styles['p-products__ttl']}>
                {t('Detailed Information')}
              </p>
              <p className={baseStyles['u-mt-2']}>
                {t(
                  'Optionally register remaining info (editing can also be done later)'
                )}
              </p>

              <CollapsibleSection
                title={t('Highlights')}
                subtitle={t(
                  'Enter the highlights and summary of the experience'
                )}
                initialOpen={Boolean(
                  editingProduct?.highlights &&
                    editingProduct?.highlights.length > 0
                )}
                id="highlights"
                setCurrentSectionId={setCurrentSectionId}
              >
                <HighlightsEditor />
              </CollapsibleSection>
              <CollapsibleSection
                id="detailedInfoVoucher"
                title={t('Detailed Info (shown on voucher)')}
                subtitle={t(
                  'Enter detailed info for the experience (info is shown automatically on reservation confirmation email)'
                )}
                initialOpen={Boolean(
                  (editingProduct?.inclusions &&
                    editingProduct?.inclusions.length > 0) ||
                    (editingProduct?.exclusions &&
                      editingProduct?.exclusions.length > 0)
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <DetailInfoEditor />
              </CollapsibleSection>
              <CollapsibleSection
                id="experienceItinerary"
                title={t('Experience Itinerary')}
                subtitle={t('Explain the flow of the experience')}
                initialOpen={Boolean(
                  editingProduct?.itinerary &&
                    editingProduct?.itinerary.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <ItineraryEditor />
              </CollapsibleSection>
              <CollapsibleSection
                id="addOns"
                title={t('Add-Ons')}
                subtitle={t(
                  'Describe additional options that can be selected on the booking form'
                )}
                initialOpen={Boolean(
                  editingProduct?.add_ons && editingProduct?.add_ons.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <ServicesEditor
                  name="addOns"
                  addButtonLabel={t('Add Add-on')}
                />
              </CollapsibleSection>
              <CollapsibleSection
                id="transportation"
                title={t('Transportation')}
                subtitle={t(
                  'Register if there is pickup (not required for checkin-only)'
                )}
                initialOpen={Boolean(
                  editingProduct?.transportations &&
                    editingProduct?.transportations.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <ServicesEditor
                  name="transportations"
                  addButtonLabel={t('Add Transportation')}
                />
              </CollapsibleSection>
              <CollapsibleSection
                id="checkinPickupLocations"
                title={t('Checkin/Pickup Locations')}
                subtitle={t(
                  'Enter location and time details in advance to make it easy for customers to understand'
                )}
                initialOpen={Boolean(
                  (editingProduct?.pickup &&
                    editingProduct.pickup.length > 0) ||
                    (editingProduct?.dropoff &&
                      editingProduct.dropoff.length > 0)
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <CheckinPickupLocationsEditor />
              </CollapsibleSection>
              <CollapsibleSection
                id="reservationForm"
                title={t('Reservation Form')}
                subtitle={t(
                  'Customize information required from customers at the time of booking'
                )}
                initialOpen={Boolean(
                  editingProduct?.reservation_form_fields &&
                    editingProduct.reservation_form_fields.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <ReservationFormEditor />
              </CollapsibleSection>
              <CollapsibleSection
                id="productTags"
                title={t('Product Tags')}
                subtitle={t(
                  'Use tags for displaying detailed conditions of the experience and help customers narrow down their selection'
                )}
                initialOpen={Boolean(
                  editingProduct?.product_tags &&
                    editingProduct.product_tags.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <TagsEditor />
              </CollapsibleSection>
            </section>

            <section
              id="internalManagement"
              className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
            >
              <p className={styles['p-products__ttl']}>
                {t('Internal Management')}
              </p>
              <CollapsibleSection
                id="transportRoutes"
                title={t('Transport Routes')}
                initialOpen={Boolean(
                  editingProduct?.transport_route &&
                    editingProduct.transport_route.length > 0
                )}
                setCurrentSectionId={setCurrentSectionId}
              >
                <TransportRouteEditor />
              </CollapsibleSection>
              <CollapsibleSection
                title={t('Internal Use Product Tags')}
                subtitle={t('Set tags for internal use on product list page')}
                initialOpen={Boolean(
                  editingProduct?.internal_product_tags &&
                    editingProduct.internal_product_tags.length > 0
                )}
                id="internalProductTags"
                setCurrentSectionId={setCurrentSectionId}
              >
                <InternalTagsEditor />
              </CollapsibleSection>
            </section>
            {isTutorialActive && showHelpPopUp && (
              <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
            )}
          </>
        )}
      </ProductEditorForm>
      {isTutorialActive && showPopUp && (
        <ProductEditDetailPopUp popUpDisable={() => setShowPopUp(false)} />
      )}
    </>
  );
};
