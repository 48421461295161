import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { Input } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import baseStyles from 'client/base.module.css';

import styles from '../DetailsStep.module.css';

export const HighlightsEditor = () => {
  const { t } = useTranslation();
  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );
  return (
    <FieldArray name="highlights">
      {({ fields }) => (
        <>
          {fields.length === 0 && (
            <AddIcon onClick={() => (fields as any).insertAt(0, '')} />
          )}
          <div className={styles['c-table-list']}>
            <table>
              <tbody>
                {translationTargetLanguage && <TranslationTableHeader />}
                {fields.map((name, idx) => (
                  <TranslatedField key={name} name={name}>
                    {({ input, translationInput }) => (
                      <tr>
                        <th>
                          {t('POINT.{{pointNumber}}', {
                            pointNumber: idx + 1,
                          })}
                        </th>
                        <td>
                          <div className={baseStyles['base-flex']}>
                            <Input {...input} maxWidth={800} />
                            <div className={baseStyles['base-flex']}>
                              <AddIcon
                                onClick={() =>
                                  (fields as any).insertAt(idx + 1, '')
                                }
                              />
                              <DeleteIcon onClick={() => fields.remove(idx)} />
                            </div>
                          </div>
                        </td>
                        {translationTargetLanguage && (
                          <td>
                            <Input {...translationInput} maxWidth={800} />
                          </td>
                        )}
                      </tr>
                    )}
                  </TranslatedField>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </FieldArray>
  );
};
