import arrayMutators from 'final-form-arrays';

export const getArrayMutators = () => {
  return {
    ...arrayMutators,
    insertAt: ([name, index, value]: any, state: any, { changeValue }: any) => {
      changeValue(state, name, (array: any) => {
        const copy = [...(array || [])];
        copy.splice(index, 0, value);
        return copy;
      });
    },
  };
};
