import clsx from 'clsx';

import styles from './ViewSelector.module.css';

export interface View {
  key: 'flow' | 'stock';
  label: string;
}

interface ViewSelectorProps {
  views: View[];
  value: View['key'];
  onChange: (viewKey: View['key']) => void;
}

export const ViewSelector = ({ views, value, onChange }: ViewSelectorProps) => {
  return (
    <ul className={styles['tab-container']}>
      {views.map((view) => (
        <li
          key={view.key}
          className={clsx(
            styles['tab'],
            view.key === value ? styles['active'] : null
          )}
          onClick={() => onChange(view.key)}
        >
          {view.label}
        </li>
      ))}
    </ul>
  );
};
