import React from 'react';
import clsx from 'clsx';
import querystring from 'query-string';
import type { Moment } from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import type { GuideScheduleCalendarEvent } from 'client/components/GuideScheduleCalendar/GuideScheduleCalendar';
import type { ReduxState } from 'client/reducers';
import { EditSingleDayOperatingHourModal } from 'client/components/EditSingleDayOperatingHourModal/EditSingleDayOperatingHourModal';
import { GuideScheduleCalendarModal } from 'client/components/GuideScheduleCalendar/GuideScheduleCalendar';
import {
  ResourceReservationListModal,
  defaultVisibleColumns,
} from 'client/components/ResourceReservationListModal/ResourceReservationListModal';
import {
  activeUserOrganizationSelector,
  accountsSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchGuideSingleDaySchedules } from 'client/actions/guides';
import { fetchProducts } from 'client/actions/products';
import { fetchReservations } from 'client/actions/reservations';
import { getWeekdayListText } from 'client/libraries/util/weekdays';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { groupReservationsByNormalizedStartTime } from 'client/libraries/util/groupReservationsByNormalizedStartTime';
import { summariesSelector } from 'client/reducers/products';
import { toManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { OperatingHoursRule } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';
import componentStyles from 'client/components/components.module.css';
import editIcon from 'client/images/ic_edit.svg';

import type { GuideCustomTableColumn } from './GuideCustomTable';
import { GuideCustomTable } from './GuideCustomTable';
import { EditGuideSettingsModal } from './EditGuideSettingsModal';
import { GuideAvailabilityModal } from './GuideAvailabilityModal/GuideAvailabilityModal';

type Props = {
  guideAccountShapes: GuideAccountShape[];
};
export const GuideListTab = ({ guideAccountShapes }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [accountId, setAccountId] = React.useState<string | null>(null);
  const [editAccountId, setEditAccountId] = React.useState<string | null>(null);
  const [date, setDate] = React.useState<string | null>(null);
  const [eventDate, setEventDate] = React.useState<string | null>(null);
  const [range, setRange] = React.useState<{
    start: Moment;
    end: Moment;
  } | null>(null);
  const [editAvailabilityGuideAccountId, setEditAvailabilityGuideAccountId] =
    React.useState<string | null>(null);

  const accounts = useSelector(accountsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const products = useSelector(summariesSelector);
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  React.useEffect(() => {
    if (range) {
      dispatch(
        fetchReservations({
          start_date_local_from: range.start.format('YYYY-MM-DD'),
          start_date_local_to: range.end.format('YYYY-MM-DD'),
          filter: querystring.stringify({
            status: ['CONFIRMED'],
          }),
        })
      );
    }
  }, [range]);
  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
      dispatch(fetchGuideSingleDaySchedules());
    }
  }, [activeUserOrganization]);
  const timezone =
    activeUserOrganization?.default_timezone || 'America/Los_Angeles';
  const manifestReservationShape = (reservations || [])
    .filter((reservation) =>
      (reservation.dispatch_guides || []).includes(accountId ?? '')
    )
    .map((reservation) => toManifestReservationShape(reservation, t));
  const reservationsByNormalizedStartTime =
    groupReservationsByNormalizedStartTime(manifestReservationShape);
  const guideSettings = (activeUserOrganization?.guides || []).find(
    (guide) => guide.id === accountId
  );
  const events = (reservationsByNormalizedStartTime || []).map(
    (reservationStartTime) => {
      const startDateTime = reservationStartTime.startDateTime;
      const ret: GuideScheduleCalendarEvent = {
        id: startDateTime.valueOf() as any,
        time: startDateTime.tz(timezone).locale(locale).format('HH:mm'),
        startDateTimeUtc: startDateTime.utc().format(),
        title: String(
          reservationStartTime.reservations.reduce((acc, reservation) => {
            return reservation.guests.length + acc;
          }, 0)
        ),
      };
      return ret;
    }
  );

  const getOperatingHourText = (
    operatingHoursRule: OperatingHoursRule | null
  ) => {
    if (!operatingHoursRule) {
      return '';
    }

    return `${operatingHoursRule.start_time_local || 'N/A'} - ${
      operatingHoursRule.end_time_local || 'N/A'
    }`;
  };

  const getScheduleDateRangeText = (
    guideAccountShape: GuideAccountShape | null
  ) => {
    if (!guideAccountShape) {
      return '';
    }

    if (
      !guideAccountShape.start_date_local &&
      !guideAccountShape.end_date_local
    ) {
      return '';
    }

    return `${guideAccountShape.start_date_local || 'N/A'} - ${
      guideAccountShape.end_date_local || 'N/A'
    }`;
  };

  const getColumns = (): GuideCustomTableColumn[] => {
    return [
      ...(hasCustomUserRoleWritePermissions(
        activeUser,
        'SHIFT_MANAGEMENT.STAFF'
      )
        ? [
            {
              Header: '',
              accessor: 'key',
              Cell: (cellInfo) => (
                <a
                  className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
                  data-text={t('Edit')}
                  onClick={() => {
                    setEditAccountId(cellInfo.original.id);
                  }}
                >
                  <img src={editIcon} />
                </a>
              ),
              sub: true,
              width: 'minimal',
            } as GuideCustomTableColumn,
          ]
        : []),
      {
        Header: '',
        accessor: 'key',
        Cell: (cellInfo) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Calendar')}
            onClick={() => {
              setAccountId(cellInfo.original.id);
            }}
          >
            <img src={calendarIcon} />
          </a>
        ),
        sub: true,
        width: 'minimal',
      },
      {
        Header: t('Name'),
        accessor: 'name',
        width: 'middle',
      },
      {
        Header: t('Basic schedule'),
        accessor: 'schedule',
        Cell: (cellInfo) => {
          const scheduleDateRange = getScheduleDateRangeText(cellInfo.original);
          return (
            <ul>
              {cellInfo.original.operating_hours_rule && (
                <>
                  {scheduleDateRange && <li>{scheduleDateRange}</li>}
                  <li>
                    {getWeekdayListText(
                      cellInfo.original.operating_hours_rule?.days_of_week ||
                        [],
                      t
                    )}
                  </li>
                  <li>
                    {getOperatingHourText(
                      cellInfo.original.operating_hours_rule
                    )}
                  </li>
                </>
              )}
            </ul>
          );
        },
        width: 'long',
      },
      {
        Header: t('Description'),
        accessor: 'description',
        Cell: (cellInfo) => (
          <div className={clsx(baseStyles['base-flex'])}>
            {cellInfo.original.description && (
              <span>{cellInfo.original.description}</span>
            )}
          </div>
        ),
        width: 'long',
      },
      {
        Header: t('Products'),
        accessor: 'product_ids',
        Cell: (cellInfo) => (
          <ul>
            {(cellInfo.original.product_ids || []).map((productId: string) => {
              return (
                <li key={productId}>
                  <Link
                    to={`/products/${productId}`}
                    style={{
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      color: '#4183c4',
                    }}
                  >
                    {getDisplayProductName(
                      products.find((p) => p.id === productId)
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
        ),
        width: 'long',
      },
    ];
  };

  const columns = getColumns();

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          componentStyles['is-active']
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}></div>
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <GuideCustomTable items={guideAccountShapes} columns={columns} />
          {accountId && (
            <GuideScheduleCalendarModal
              onClose={() => {
                setAccountId(null);
              }}
              events={events}
              timezone={timezone}
              locale={locale}
              title={`${t('Schedule')} - ${
                guideAccountShapes.find((account) => account.id === accountId)
                  ?.name || ''
              }`}
              onRangeChange={setRange}
              onEventDayClick={(id) => {
                setEventDate(id);
              }}
              onDayClick={(date) => {
                if (
                  hasCustomUserRoleWritePermissions(
                    activeUser,
                    'SHIFT_MANAGEMENT.STAFF'
                  )
                ) {
                  setDate(date);
                }
              }}
              loading={loading}
              guideAccountShape={
                guideAccountShapes.find(
                  (account) => account.id === accountId
                ) || null
              }
              guideSingleDaySchedules={guideSingleDaySchedules || []}
            />
          )}

          <EditSingleDayOperatingHourModal
            open={date !== null}
            onClose={() => {
              setDate(null);
            }}
            date={date || ''}
            accountId={accountId || ''}
            operatingHoursRule={guideSettings?.operating_hours_rule || null}
            guideSingleDaySchedule={
              guideSingleDaySchedules.find((schedule) => {
                if (
                  schedule.guide_account_id === accountId &&
                  schedule.date_local === date
                ) {
                  return true;
                }

                return false;
              }) || null
            }
            onSubmit={() => {
              dispatch(fetchGuideSingleDaySchedules());
            }}
          />

          <ResourceReservationListModal
            open={eventDate !== null}
            resourceType={'guide'}
            resourceKey={accountId || ''}
            visibleColumns={defaultVisibleColumns}
            onClose={() => {
              setEventDate(null);
            }}
            reservations={manifestReservationShape.filter((reservation) => {
              return (
                reservation.participates_at
                  .tz(timezone)
                  .format('YYYY-MM-DD') === eventDate
              );
            })}
            title={
              (accounts || []).find((account) => account.id === accountId)
                ?.name || ''
            }
            timezone={timezone}
          />

          <EditGuideSettingsModal
            open={editAccountId !== null}
            onClose={() => {
              setEditAccountId(null);
            }}
            guideAccount={
              guideAccountShapes.find((guide) => guide.id === editAccountId) ||
              null
            }
          />

          <GuideAvailabilityModal
            open={editAvailabilityGuideAccountId !== null}
            onClose={() => {
              setEditAvailabilityGuideAccountId(null);
            }}
            guideAccountId={editAvailabilityGuideAccountId || ''}
          />
        </div>
      </div>
    </>
  );
};
