import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_EQUIPMENT_ASSET_FAILURE,
  CREATE_EQUIPMENT_ASSET_REQUEST,
  CREATE_EQUIPMENT_ASSET_SUCCESS,
  DELETE_EQUIPMENT_ASSET_FAILURE,
  DELETE_EQUIPMENT_ASSET_REQUEST,
  DELETE_EQUIPMENT_ASSET_SUCCESS,
  FETCH_EQUIPMENT_ASSETS_FAILURE,
  FETCH_EQUIPMENT_ASSETS_REQUEST,
  FETCH_EQUIPMENT_ASSETS_SUCCESS,
  UPDATE_EQUIPMENT_ASSET_FAILURE,
  UPDATE_EQUIPMENT_ASSET_REQUEST,
  UPDATE_EQUIPMENT_ASSET_SUCCESS,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE,
  CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
  CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  EquipmentAsset,
  CreateEquipmentAssetResponse,
  ListEquipmentAssetsResponse,
  UpdateEquipmentAssetResponse,
  CalculateEquipmentAssetCellBlockMappingsResponse,
} from 'shared/models/swagger';

export const equipmentAssetsSelector = (state: ReduxState) =>
  state.equipmentAssets.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSETS_FAILURE:
    case CREATE_EQUIPMENT_ASSET_FAILURE:
    case UPDATE_EQUIPMENT_ASSET_FAILURE:
    case DELETE_EQUIPMENT_ASSET_FAILURE:
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE:
      return action.error;

    case FETCH_EQUIPMENT_ASSETS_SUCCESS:
    case CREATE_EQUIPMENT_ASSET_SUCCESS:
    case UPDATE_EQUIPMENT_ASSET_SUCCESS:
    case DELETE_EQUIPMENT_ASSET_SUCCESS:
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSETS_REQUEST:
    case CREATE_EQUIPMENT_ASSET_REQUEST:
    case UPDATE_EQUIPMENT_ASSET_REQUEST:
    case DELETE_EQUIPMENT_ASSET_REQUEST:
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST:
      return true;

    case FETCH_EQUIPMENT_ASSETS_FAILURE:
    case CREATE_EQUIPMENT_ASSET_FAILURE:
    case UPDATE_EQUIPMENT_ASSET_FAILURE:
    case DELETE_EQUIPMENT_ASSET_FAILURE:
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE:
    case FETCH_EQUIPMENT_ASSETS_SUCCESS:
    case CREATE_EQUIPMENT_ASSET_SUCCESS:
    case UPDATE_EQUIPMENT_ASSET_SUCCESS:
    case DELETE_EQUIPMENT_ASSET_SUCCESS:
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: EquipmentAsset[] = [], action: any): EquipmentAsset[] => {
  switch (action.type) {
    case FETCH_EQUIPMENT_ASSETS_SUCCESS: {
      const response = action.response as ListEquipmentAssetsResponse;
      return [...response.equipment_assets];
    }

    case CREATE_EQUIPMENT_ASSET_SUCCESS: {
      const response = action.response as CreateEquipmentAssetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_EQUIPMENT_ASSET_SUCCESS: {
      const response = action.response as UpdateEquipmentAssetResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_EQUIPMENT_ASSET_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCalculatedEquipmentAsset = (
  state: EquipmentAsset | null = null,
  action: any
): EquipmentAsset | null => {
  switch (action.type) {
    case CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS: {
      const response =
        action.response as CalculateEquipmentAssetCellBlockMappingsResponse;
      return response;
    }
    case CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST:
      return null;
    default:
      return state;
  }
};

export const equipmentAssets = combineReducers({
  error,
  loading,
  all,
  lastCalculatedEquipmentAsset,
});
