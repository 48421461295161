import { forwardRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Account, Product, Reservation } from 'shared/models/swagger';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { SupplementaryInformationUpdateModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/SupplementaryInformation/SupplementaryInformationUpdateModal';
import { Button } from 'client/components/v3/Common/Button';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  product: Product;
  activeUser: Account | null;
  readOnly?: boolean;
};

export const SupplementaryInformation = forwardRef<any, Props>(
  (props: Props) => {
    const { reservation, product, activeUser, readOnly } = props;

    const { partnershipMode } = useContext(PartnershipModeContext);
    if (!reservation || !product) {
      return null;
    }

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const currentStatus = getCurrentStatus(reservation);

    const userIsPassthroughOrg =
      (reservation.agent_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'AGENT') ||
      (reservation.supplier_side_passthrough_reservation_id &&
        activeUser?.organization_type === 'SUPPLIER');

    const userCanEditSupplierReference =
      !userIsPassthroughOrg &&
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationSupplierReference') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
      !isTerminalReservationStatus(currentStatus) &&
      !partnershipMode;
    const userCanEditAgentNotes =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
      !isTerminalReservationStatus(currentStatus);
    const userCanEditSupplierNotes =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationSupplierNotes') &&
      hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
      !partnershipMode;
    const userCanViewInternalSupplierNotes =
      operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      !partnershipMode;

    return (
      <section
        id="supplementary"
        className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
      >
        <TutorialPopup
          name="supplementary"
          content={
            <TutorialPopupContent>
              <TutorialPopupHeader text={t('Supplementary Information')} />
              <TutorialPopupDescription
                text={t(
                  'You can check customer requests and replies from your company from here. Please use internal memo for communications not intended for customers to see.'
                )}
              />
            </TutorialPopupContent>
          }
        />

        <div className={styles['p-reservationsDetail']}>
          {/* Header */}
          <div className={styles['p-reservationsDetail__header']}>
            <p className={styles['p-reservationsDetail__ttl']}>
              {t('Supplementary Information')}
            </p>
            <div className={styles['p-reservationsDetail__actions']}>
              {(userCanEditSupplierReference ||
                userCanEditAgentNotes ||
                userCanEditSupplierNotes) && (
                <Button
                  text={t('Edit')}
                  uiType="bg"
                  size="sm"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                  onClick={() => setShowModal(true)}
                />
              )}
            </div>
          </div>
          {showModal && (
            <SupplementaryInformationUpdateModal
              reservation={reservation}
              activeUser={activeUser}
              onClose={() => setShowModal(false)}
            />
          )}

          {/* Content */}
          <div className={styles['p-reservationsDetail__body']}>
            <div className="p-supplements">
              <table
                className={tableStyles['c-tableSmall']}
                style={{ overflow: 'hidden' }}
              >
                <tbody>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Remarks')}
                    </th>
                    <td>
                      <TruncatedTextWithSeeMoreButton
                        text={reservation.agent_notes ?? ''}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className={baseStyles['u-width-176']}>
                      {t('Replies')}
                    </th>
                    <td>
                      <TruncatedTextWithSeeMoreButton
                        text={reservation.supplier_notes ?? ''}
                      />
                    </td>
                  </tr>
                  {userCanViewInternalSupplierNotes && (
                    <tr>
                      <th className={baseStyles['u-width-176']}>{t('Memo')}</th>
                      <td>
                        <TruncatedTextWithSeeMoreButton
                          text={reservation.supplier_internal_notes ?? ''}
                        />
                      </td>
                    </tr>
                  )}
                  {userCanViewInternalSupplierNotes && (
                    <tr>
                      <th className={baseStyles['u-width-176']}>
                        {t('Internal Note')}
                      </th>
                      <td>
                        <TruncatedTextWithSeeMoreButton
                          text={
                            reservation.supplier_internal_notes_for_dispatch ??
                            ''
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
