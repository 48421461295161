import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
  FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE,
  FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST,
  FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS,
  UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE,
  UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST,
  UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  MediaDownloadPrice,
  CreateMediaDownloadPriceResponse,
  ListMediaDownloadPricesResponse,
  UpdateMediaDownloadPriceResponse,
} from 'shared/models/swagger';

export const mediaDownloadPricesSelector = (state: ReduxState) =>
  state.mediaDownloadPrices.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE:
    case CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE:
    case UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE:
    case DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE:
      return action.error;

    case FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS:
    case CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
    case UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
    case DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST:
    case CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST:
    case UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST:
    case DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST:
      return true;

    case FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE:
    case CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE:
    case UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE:
    case DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE:
    case FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS:
    case CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
    case UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
    case DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: MediaDownloadPrice[] = [],
  action: any
): MediaDownloadPrice[] => {
  switch (action.type) {
    case FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS: {
      const response = action.response as ListMediaDownloadPricesResponse;
      const newIds = response.media_download_prices?.map((m) => m.id);
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...response.media_download_prices,
      ];
    }

    case CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS: {
      const response = action.response as CreateMediaDownloadPriceResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS: {
      const response = action.response as UpdateMediaDownloadPriceResponse;
      return state.map((i) => (i.id !== response.id ? i : response));
    }

    case DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const mediaDownloadPrices = combineReducers({
  error,
  loading,
  all,
});
