import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { fetchProducts } from 'client/actions/products';
import {
  convertFormValuesToRecurrence,
  getInitialAvailabilityAllotmentSchedules,
} from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { Select, ToggleButton } from 'client/components/Form';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { AvailabilityAllotmentSchedule } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import type { Product, Product$Patch } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { useSourceLanguageProduct } from '../useSourceLanguageProduct';

import { ParentProductStartTimeMappingEditor } from './ParentProductStartTimeMappingEditor';

type FormValues = {
  useSharedAllotment: boolean;
  sharedAllotmentParentProductId: string | null;
  availabilitySchedules: AvailabilityAllotmentSchedule[];
};

const getInitialValues = (product: Product | null): FormValues => {
  const sharedAllotmentParentProductId =
    product?.shared_allotment_references?.parent_product_id ?? null;
  return {
    useSharedAllotment: Boolean(sharedAllotmentParentProductId),
    sharedAllotmentParentProductId,
    availabilitySchedules: getInitialAvailabilityAllotmentSchedules(
      product,
      []
    ),
  };
};

type Props = {
  onClose: () => void;
};
export const SharedAvailabilityAllotmentEditorModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const allProducts = useSelector(summariesSortedByBookmarkedSelector);
  const contentLanguageProduct = React.useContext(EditingProductContext);
  const { data: product, isLoading: sourceLanguageProductIsLoading } =
    useSourceLanguageProduct(contentLanguageProduct?.id ?? '');
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  const convertFormValuesToProductPatch = React.useCallback(
    (values: FormValues): Product$Patch => {
      return {
        shared_allotment_references:
          values.useSharedAllotment &&
          values.sharedAllotmentParentProductId != null
            ? {
                parent_product_id: values.sharedAllotmentParentProductId,
              }
            : {
                passthrough_base_product_id:
                  product?.shared_allotment_references
                    ?.passthrough_base_product_id,
                package_component_product_ids:
                  product?.shared_allotment_references
                    ?.package_component_product_ids,
              },
        recurrence: convertFormValuesToRecurrence(values.availabilitySchedules),
      };
    },
    [product]
  );
  const childIds =
    product?.shared_allotment_references?.child_product_ids ?? [];
  const childProducts = (allProducts || []).filter((p) =>
    childIds.includes(p.id)
  );
  const isPackage =
    (product?.shared_allotment_references?.package_component_product_ids ?? [])
      .length > 0;
  const productOptions = allProducts
    .filter(
      (p) =>
        p.id !== product?.id &&
        (p?.shared_allotment_references?.package_component_product_ids ?? [])
          .length === 0 &&
        !p?.shared_allotment_references?.parent_product_id
    )
    .map((p) => ({
      key: p.id,
      value: p.id,
      text: getVerboseDisplayProductName(p),
    }));
  return (
    <ProductEditorModal
      loading={sourceLanguageProductIsLoading}
      open={true}
      onClose={onClose}
      title={t('Shared Availability/Allotment')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
      disabled={childIds.length > 0 || isPackage}
    >
      {childIds.length > 0 ? (
        <div>
          <div>
            {t(
              'The following products are sharing availability and allotment with this product'
            )}
            :
          </div>
          <ul>
            {childProducts.map((p) => (
              <li key={p.id}>
                <Link to={`/products/${p.id}`}>{p.product_name || ''}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : isPackage ? (
        <div>
          {t(
            'This is a package product. Allotment is determined by the component product allotments'
          )}
        </div>
      ) : (
        <Field type="checkbox" name="useSharedAllotment">
          {({ input }) => {
            return (
              <>
                <ToggleButton
                  {...input}
                  label={t(
                    'Use availability and allotment from another product'
                  )}
                />
                {input.checked && (
                  <Field name="sharedAllotmentParentProductId">
                    {({ input: productInput }) => (
                      <>
                        <div className={baseStyles['base-margin-top-8']}>
                          <Select
                            label={t('Parent Product')}
                            search
                            value={productInput.value}
                            options={productOptions}
                            onChange={(e, { value }) =>
                              productInput.onChange(value)
                            }
                          />
                        </div>
                        {productInput.value && (
                          <ParentProductStartTimeMappingEditor
                            parentProductId={productInput.value}
                          />
                        )}
                      </>
                    )}
                  </Field>
                )}
              </>
            );
          }}
        </Field>
      )}
    </ProductEditorModal>
  );
};
