// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Feed, Grid, Header, Icon, List, Segment } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';

import { DownloadVoucherButton } from 'client/components/DownloadVoucherButton';
import { StatusSteps } from 'client/pages/ReservationDetails/StatusSteps';
import { activeUserSelector } from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import { NotesEditButton } from 'client/pages/ReservationDetails/NotesEditButton';
import { CheckinEditButton } from 'client/pages/ReservationDetails/CheckinEditButton';
import { RequestedPickupDropoffEditButton } from 'client/pages/ReservationDetails/RequestedPickupDropoffEditButton';
import { GuestHotelEditButton } from 'client/pages/ReservationDetails/GuestHotelEditButton';
import { PickupDropoffEditButton } from 'client/pages/ReservationDetails/PickupDropoffEditButton';
import { EmailPaymentButton } from 'client/pages/ReservationDetails/EmailPaymentButton';
import { Loading } from 'client/pages/Loading';
import {
  getActionSourceEntityName,
  getCurrentStatus,
  getGuestTypesUsedInProductInstance,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { dateTimesSpanMultipleDays } from 'client/libraries/util/dateTimesSpanMultipleDays';
import { formattedLocationName } from 'client/libraries/util/coreutil';
import { EditFieldResponseButton } from 'client/components/EditFieldResponseButton';
import { FieldsFormGroup } from 'client/pages/ReservationDetails/FieldsFormGroup';
import { getFormattedPackageComponentStartTime } from 'client/libraries/util/getFormattedPackageComponentStartTime';
import type { ActionSource } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers/index';

type OwnProps = {
  reservationId: string,
  readOnly?: boolean,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  editKey: string,
  editValue: string,
};

type Action = {
  actionDescription: string,
  actionSource?: ActionSource,
  time: string,
};

class ReservationTabComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editKey: '',
      editValue: '',
    };
  }

  getActions = (): Action[] => {
    const { reservation, t } = this.props;

    const activities: Action[] = [];

    const statusHistory = reservation.status_history.slice(1);

    let textForNextConfirmedState = t('booked with instant confirmation');
    let textForNextRejectedState = t(
      'booking attempted but rejected by supplier'
    );

    for (let i = 0; i < statusHistory.length; i++) {
      const s = statusHistory[i];
      switch (s.status) {
        case 'REQUESTED':
          activities.push({
            actionDescription: t('requested'),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          textForNextConfirmedState = t('confirmed');
          textForNextRejectedState = t('rejected');
          break;
        case 'CONFIRMED':
          activities.push({
            actionDescription: textForNextConfirmedState,
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          textForNextConfirmedState = '';
          textForNextRejectedState = '';
          break;
        case 'DECLINED_BY_SUPPLIER':
          activities.push({
            actionDescription: textForNextRejectedState,
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          textForNextConfirmedState = '';
          textForNextRejectedState = '';
          break;
        case 'WITHDRAWN':
          activities.push({
            actionDescription: t('withdrawn'),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          break;
        case 'STANDBY':
          activities.push({
            actionDescription: t('put on standby'),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          break;
        case 'AWAITING_UPDATE_CONFIRMATION':
          textForNextConfirmedState = t('updated');
          break;
        case 'CANCELED_BY_AGENT':
        case 'CANCELED_BY_SUPPLIER':
        case 'CANCELED_BY_GUEST':
          activities.push({
            actionDescription: t('canceled'),
            actionSource: s.action_source,
            time: s.status_date_time_utc,
          });
          break;
        default:
          continue;
      }
    }

    return activities;
  };

  printAction = (action: Action): string => {
    const { actionDescription, actionSource } = action;
    if (actionSource) {
      const { t } = this.props;

      const entityName = getActionSourceEntityName(actionSource, t);
      const entityDescription = actionSource.entity_description;

      if (entityName && entityDescription)
        return t('{{entityName}} ({{personName}}): {{actionDescription}}', {
          personName: entityDescription,
          entityName,
          actionDescription,
        });
      else if (entityName)
        return t('{{entityName}}: {{actionDescription}}', {
          entityName,
          actionDescription,
        });
      else if (entityDescription)
        return t('{{personName}}: {{actionDescription}}', {
          personName: entityDescription,
          actionDescription,
        });
    }

    return actionDescription;
  };

  render() {
    const {
      activeUser,
      locale,
      loading,
      product,
      productInstance,
      readOnly,
      reservation,
      t,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!reservation) {
      return <Header as="h1">{t('Reservation not found')}</Header>;
    }

    if (!product) {
      return <Header as="h1">{t("Reservation's product not found")}</Header>;
    }

    if (!productInstance) {
      return (
        <Header as="h1">{t("Reservation's product instance not found")}</Header>
      );
    }

    const currentStatus = getCurrentStatus(reservation);

    const guestCounts = [];
    const guestCountMap = {};
    const productGuestTypes = getGuestTypesUsedInProductInstance(
      productInstance,
      product,
      this.props.t
    );
    reservation.guests.forEach((g: any) => {
      const guestTitle =
        g.guest_type_title ||
        ((productGuestTypes.find(
          (guest_type) => guest_type.key === g.guest_type_key
        ): any)
          ? (productGuestTypes.find(
              (guest_type) => guest_type.key === g.guest_type_key
            ): any).title
          : g.guest_type_key);

      if (guestTitle in guestCountMap) {
        guestCountMap[guestTitle]++;
      } else {
        guestCountMap[guestTitle] = 1;
      }
    });

    for (var guestTitle in guestCountMap) {
      guestCounts.push({
        guestTitle: guestTitle,
        guestCount: guestCountMap[guestTitle],
      });
    }

    const guestSummary = guestCounts
      .map((g) => `${g.guestTitle} (x${g.guestCount})`)
      .join(', ');

    let transportationSummary = '';
    const transKey = reservation.transportation;
    if (transKey) {
      const trans = (product.transportations || []).find(
        (trans) => trans.key === transKey
      );
      if (trans) {
        if (trans.service_type === 'FREE') {
          transportationSummary = trans.title;
        } else {
          const perBookingPricing =
            trans.pricing &&
            trans.pricing.find((pr) => pr.method === 'PER_BOOKING');
          if (perBookingPricing) {
            transportationSummary = trans.title;
          } else {
            const transItems = [];
            trans.pricing &&
              trans.pricing.forEach((pr) => {
                const guestType = pr.guest_type;
                const guestCount = (reservation.guests || []).filter(
                  (g) => guestType && g.guest_type_key === guestType.key
                ).length;
                if (guestType && guestCount > 0) {
                  transItems.push(
                    `${trans.title} - ${guestType.title} (x${guestCount})`
                  );
                }
              });

            transportationSummary = transItems.join(', ');
          }
        }
      } else {
        transportationSummary = transKey;
      }
    }

    const isCheckinCheckoutOnly = reservationIsCheckinCheckoutOnly(reservation);
    if (!transportationSummary) {
      transportationSummary = isCheckinCheckoutOnly
        ? t('Checkin/Checkout Only')
        : t('Pickup/Dropoff Included');
    }

    const addOnItems = [];
    (reservation.add_ons || []).forEach((addOnKey) => {
      const addOn =
        product.add_ons && product.add_ons.find((a) => a.key === addOnKey);
      if (addOn) {
        // mapped per-reservation addon
        addOnItems.push(addOn.title);
      } else {
        // unmapped per-reservation addon
        addOnItems.push(addOnKey);
      }
    });

    // mapped per-guest addons
    (product.add_ons || []).forEach((addOn) => {
      addOn.pricing &&
        addOn.pricing.forEach((pr) => {
          const guestType = pr.guest_type;
          if (pr.method === 'PER_PARTICIPANT' && guestType) {
            const count = (reservation.guests || []).filter(
              (g) =>
                (guestType.key === g.guest_type_key ||
                  // Match on guest type title because agent reverse mapping may cause
                  // the reservation guest type key to not match the corresponding product
                  // guest type key.
                  guestType.title === g.guest_type_title) &&
                (g.add_ons || []).indexOf(addOn.key) !== -1
            ).length;

            if (count > 0) {
              addOnItems.push(
                `${addOn.title} - ${guestType.title} (x${count})`
              );
            }
          }
        });
    });

    // unmapped per-guest addons
    (reservation.guests || []).forEach((guest) => {
      let unmapped_addons = guest.add_ons.filter(
        (per_guest_addon_key) =>
          !(product.add_ons || [])
            .filter(
              (product_addon) =>
                product_addon.pricing &&
                product_addon.pricing
                  .map((pr) => pr.method)
                  .includes('PER_PARTICIPANT')
            )
            .map((product_addon) => product_addon.key)
            .includes(per_guest_addon_key)
      );
      unmapped_addons.forEach((unmapped_addon) => {
        addOnItems.push(unmapped_addon);
      });
    });

    const addOnSummary = addOnItems.join(', ');

    const startDateTime = moment
      .tz(reservation.start_date_time_utc, reservation.start_timezone)
      .locale(locale);

    const endDateTime = moment
      .tz(productInstance.end_date_time_utc, product.start_timezone)
      .locale(locale);

    const duration = endDateTime.diff(startDateTime, 'hours', true);

    const startTimeSummary = productInstance.start_time_description
      ? startDateTime.format('lll') +
        ' ' +
        productInstance.start_time_description
      : startDateTime.format('lll');

    const {
      checkin,
      dropoff,
      guest_hotel,
      pickup,
      requested_dropoff_location,
      requested_pickup_location,
    } = reservation;

    const userIsPassthroughOrg =
      (reservation.agent_side_passthrough_reservation_id &&
        activeUser.organization_type === 'AGENT') ||
      (reservation.supplier_side_passthrough_reservation_id &&
        activeUser.organization_type === 'SUPPLIER');

    const userCanEditPickupDropoff =
      !userIsPassthroughOrg &&
      !readOnly &&
      !isTerminalReservationStatus(currentStatus) &&
      operationAllowed(activeUser, 'write', 'reservationPickupDropoff');
    const userCanEditRequestedPickupDropoff =
      !userIsPassthroughOrg &&
      !readOnly &&
      !isTerminalReservationStatus(currentStatus) &&
      !operationAllowed(activeUser, 'write', 'reservationPickupDropoff');
    const userCanEditSupplierReference =
      !userIsPassthroughOrg &&
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationSupplierReference') &&
      !isTerminalReservationStatus(currentStatus);
    const userCanEditFormFieldResponses =
      !userIsPassthroughOrg &&
      !readOnly &&
      !isTerminalReservationStatus(currentStatus);
    const userCanEditAgentNotes =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
      !isTerminalReservationStatus(currentStatus);
    const userCanEditSupplierNotes =
      !readOnly &&
      operationAllowed(activeUser, 'write', 'reservationSupplierNotes') &&
      !isTerminalReservationStatus(currentStatus);

    const actions = this.getActions();

    const usePDFVoucher = currentStatus === 'CONFIRMED' && reservation.qr_code;
    const useHTMLVoucher =
      currentStatus === 'CONFIRMED' && !reservation.qr_code;

    const componentReservations =
      reservation.package_component_reservation_summaries || [];
    const componentsSpanMultipleDays = dateTimesSpanMultipleDays(
      [
        reservation.start_date_time_utc || '',
        ...componentReservations.map(
          (component) => component.start_date_time_utc || ''
        ),
      ],
      product.start_timezone || ''
    );

    const paymentDeferredAvailable =
      reservation.billing_info &&
      reservation.billing_info.payment_deferred_available;

    return (
      <div>
        <Segment>
          <Header as="h1">
            {t('Reservation #{{id}}', {
              id: reservation.id,
            })}
            {usePDFVoucher && (
              <DownloadVoucherButton size="tiny" reservation={reservation} />
            )}
          </Header>
          <List size="large" relaxed>
            {useHTMLVoucher && (
              <List.Item>
                <Link
                  to={`/reservations/${reservation.id}/voucher`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Voucher')}
                  <Icon name="external" />
                </Link>
              </List.Item>
            )}
            <List.Item>
              <List.Header>
                {t('Application Number: {{agent_reference}}', {
                  agent_reference: reservation.agent_reference,
                })}
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header>
                {t('Confirmation Number: {{supplier_reference}}', {
                  supplier_reference: reservation.supplier_reference,
                })}
                {userCanEditSupplierReference && (
                  <NotesEditButton
                    reservation={reservation}
                    headerName={t('Edit Confirmation Number')}
                    fieldName="supplier_reference"
                  />
                )}
              </List.Header>
            </List.Item>
            {reservation.supplier_short_reference && (
              <List.Item
                header={t(
                  'Supplier Reservation Short ID: {{supplier_short_reference}}',
                  {
                    supplier_short_reference:
                      reservation.supplier_short_reference,
                  }
                )}
              />
            )}
            <List.Item
              icon="flag outline"
              header={
                <Link to={`/products/${product.id}`}>
                  {`${reservation.product_name} [${product.supplier_reference}]`}
                </Link>
              }
            />
            <List.Item icon="point" header={product.area_name} />
            {startTimeSummary && (
              <List.Item icon="calendar" header={startTimeSummary} />
            )}
            {guestSummary && <List.Item icon="male" header={guestSummary} />}
            {transportationSummary && (
              <List.Item icon="bus" header={transportationSummary} />
            )}
            {addOnSummary && (
              <List.Item icon="shopping bag" header={addOnSummary} />
            )}
          </List>
          {componentReservations.length > 0 && (
            <>
              <List>
                <List.Item>
                  <List.Header>{t('Package Breakdown')}</List.Header>
                </List.Item>
              </List>
              <List bulleted>
                {componentReservations.map((componentReservation) => {
                  const formattedComponentStartTime =
                    getFormattedPackageComponentStartTime(
                      componentReservation.start_date_time_utc,
                      product.start_timezone,
                      componentsSpanMultipleDays
                    );

                  return (
                    <List.Item>
                      {`[${formattedComponentStartTime}] ${
                        componentReservation.product_name
                      }${
                        componentReservation.supplier_side_passthrough_reservation_id &&
                        (componentReservation.status === 'REQUESTED' ||
                          componentReservation.status === 'STANDBY')
                          ? t(' ** awaiting 3rd-party confirmation **')
                          : ''
                      }`}
                      {(componentReservation.pickup ||
                        componentReservation.dropoff) && (
                        <List.List bulleted>
                          {componentReservation.pickup && (
                            <List.Item>
                              {`${t('Pickup')}: [${
                                componentReservation.pickup.date_time_utc
                                  ? moment
                                      .tz(
                                        componentReservation.pickup
                                          .date_time_utc,
                                        product.start_timezone
                                      )
                                      .locale(locale)
                                      .format('LT')
                                  : t('TBD')
                              }] ${componentReservation.pickup.location_name}`}
                            </List.Item>
                          )}
                          {componentReservation.dropoff && (
                            <List.Item>
                              {`${t('Dropoff')}: [${
                                componentReservation.dropoff.date_time_utc
                                  ? moment
                                      .tz(
                                        componentReservation.dropoff
                                          .date_time_utc,
                                        product.start_timezone
                                      )
                                      .locale(locale)
                                      .format('LT')
                                  : t('TBD')
                              }] ${componentReservation.dropoff.location_name}`}
                            </List.Item>
                          )}
                        </List.List>
                      )}
                    </List.Item>
                  );
                })}
              </List>
            </>
          )}
          <List>
            <List.Item>
              <List.Header>{t('Customer Hotel')}</List.Header>
              <List.List>
                <List.Item>
                  {(guest_hotel && guest_hotel.location_name) || t('(none)')}
                  {userCanEditPickupDropoff ? (
                    isCheckinCheckoutOnly ? (
                      <CheckinEditButton reservationID={reservation.id} />
                    ) : (
                      <PickupDropoffEditButton reservationID={reservation.id} />
                    )
                  ) : userCanEditRequestedPickupDropoff ? (
                    isCheckinCheckoutOnly ? (
                      <GuestHotelEditButton reservation={reservation} />
                    ) : (
                      <RequestedPickupDropoffEditButton
                        reservation={reservation}
                      />
                    )
                  ) : null}
                </List.Item>
              </List.List>
            </List.Item>
            {!isCheckinCheckoutOnly && (
              <>
                <List.Item>
                  <List.Header>{t('Desired Dropoff Location')}</List.Header>
                  <List.List>
                    <List.Item>
                      {(requested_dropoff_location &&
                        requested_dropoff_location.location_name) ||
                        t('(none)')}
                      {userCanEditRequestedPickupDropoff &&
                        !isTerminalReservationStatus(currentStatus) && (
                          // RequestedPickupDropoffEditButton
                          <RequestedPickupDropoffEditButton
                            reservation={reservation}
                          />
                        )}
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  <List.Header>{t('Desired Pickup Location')}</List.Header>
                  <List.List>
                    <List.Item>
                      {(requested_pickup_location &&
                        requested_pickup_location.location_name) ||
                        t('(none)')}
                      {userCanEditRequestedPickupDropoff && (
                        // RequestedPickupDropoffEditButton
                        <RequestedPickupDropoffEditButton
                          reservation={reservation}
                        />
                      )}
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Segment>
                            <Header>
                              {t('Pickup')}
                              {userCanEditPickupDropoff && (
                                <PickupDropoffEditButton
                                  reservationID={reservation.id}
                                />
                              )}
                            </Header>
                            <List>
                              <List.Item>
                                <Icon name="clock" />
                                {pickup && pickup.date_time_utc
                                  ? moment
                                      .tz(
                                        pickup.date_time_utc,
                                        product.start_timezone
                                      )
                                      .locale(locale)
                                      .format('lll')
                                  : t('TBD')}
                              </List.Item>
                              <List.Item>
                                <Icon name="location arrow" />
                                {pickup && pickup.location_name
                                  ? formattedLocationName(pickup)
                                  : t('TBD')}
                              </List.Item>
                            </List>
                          </Segment>
                        </td>
                        <td>
                          <Icon name="angle double right" size="large" />
                        </td>
                        <td>
                          <Segment>
                            <Header
                              style={
                                userCanEditPickupDropoff
                                  ? {
                                      paddingTop: '6px',
                                      paddingBottom: '7px',
                                    }
                                  : null
                              }
                            >
                              {t('Product Operation')}
                            </Header>
                            <List>
                              <List.Item>
                                {t('Starts: {{startTime}}', {
                                  startTime: startDateTime.format('LT'),
                                })}
                              </List.Item>
                              <List.Item>
                                {t('Duration: {{duration}}h', {
                                  duration: Number.isInteger(duration)
                                    ? duration
                                    : duration.toFixed(1),
                                })}
                              </List.Item>
                            </List>
                          </Segment>
                        </td>
                        <td>
                          <Icon name="angle double right" size="large" />
                        </td>
                        <td>
                          <Segment>
                            <Header>
                              {t('Dropoff')}
                              {userCanEditPickupDropoff && (
                                <PickupDropoffEditButton
                                  reservationID={reservation.id}
                                />
                              )}
                            </Header>
                            <List>
                              <List.Item>
                                <Icon name="clock" />
                                {dropoff && dropoff.date_time_utc
                                  ? moment
                                      .tz(
                                        dropoff.date_time_utc,
                                        product.start_timezone
                                      )
                                      .locale(locale)
                                      .format('lll')
                                  : t('TBD')}
                              </List.Item>
                              <List.Item>
                                <Icon name="location arrow" />
                                {dropoff && dropoff.location_name
                                  ? formattedLocationName(dropoff)
                                  : t('TBD')}
                              </List.Item>
                            </List>
                          </Segment>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </List.Item>
              </>
            )}
            {isCheckinCheckoutOnly && (
              <table>
                <tr>
                  <td>
                    <Segment>
                      <Header>
                        {t('Checkin')}
                        {userCanEditPickupDropoff && (
                          <CheckinEditButton reservationID={reservation.id} />
                        )}
                      </Header>
                      <List>
                        <List.Item>
                          <Icon name="clock" />
                          {checkin && checkin.date_time_utc
                            ? moment
                                .tz(
                                  checkin.date_time_utc,
                                  product.start_timezone
                                )
                                .locale(locale)
                                .format('lll')
                            : t('TBD')}
                        </List.Item>
                        <List.Item>
                          <Icon name="location arrow" />
                          {checkin && checkin.location_name
                            ? formattedLocationName(checkin)
                            : t('TBD')}
                        </List.Item>
                      </List>
                    </Segment>
                  </td>
                  <td>
                    <Icon name="angle double right" size="large" />
                  </td>
                  <td>
                    <Segment>
                      <Header
                        style={
                          userCanEditPickupDropoff
                            ? {
                                paddingTop: '6px',
                                paddingBottom: '7px',
                              }
                            : {}
                        }
                      >
                        {t('Product Operation')}
                      </Header>
                      <List>
                        <List.Item>
                          {t('Starts: {{startTime}}', {
                            startTime: startDateTime.format('LT'),
                          })}
                        </List.Item>
                        <List.Item>
                          {t('Duration: {{duration}}h', {
                            duration: Number.isInteger(duration)
                              ? duration
                              : duration.toFixed(1),
                          })}
                        </List.Item>
                      </List>
                    </Segment>
                  </td>
                </tr>
              </table>
            )}
          </List>
          <StatusSteps
            readOnly={readOnly}
            reservation={reservation}
            product={product}
            productInstance={productInstance}
          />
          {operationAllowed(activeUser, 'write', 'reservationPaymentMethod') &&
            reservation.payment_deferred && (
              <EmailPaymentButton
                reservationID={reservation.id}
                emailPaymentToAddress={reservation.email_payment_to_address}
                paymentLink={reservation.payment_link}
                reservationStatus={reservation.status}
                paymentDeferredAvailable={paymentDeferredAvailable}
              />
            )}
          {operationAllowed(activeUser, 'write', 'reservations') && (
            <>
              <Header as="h3">{t('Customer Form Input')}</Header>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={15}>
                      <FieldsFormGroup
                        productInstance={productInstance}
                        product={product}
                        reservation={reservation}
                        fieldFilter={() => true}
                        mode="READ_ONLY"
                      />
                    </Grid.Column>
                    <Grid.Column width={1}>
                      {userCanEditFormFieldResponses && (
                        <EditFieldResponseButton
                          reservationID={reservation.id}
                          size="big"
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </>
          )}
          <Header as="h3">{t('Details')}</Header>
          <List>
            {operationAllowed(activeUser, 'read', 'reservationBookingSource') &&
              reservation.booking_source &&
              (() => {
                const bookingSource = reservation.booking_source;
                const bookingSourceType = bookingSource.source_type;
                if (!bookingSourceType) {
                  return null;
                }

                const isResell = reservation.contract_type === 'RESELL';
                let bookingAgent = '';
                if (bookingSource.agent_name) {
                  if (
                    reservation.agent_name &&
                    reservation.agent_name !== bookingSource.agent_name
                  ) {
                    // Prefix booking agent with contract agent name
                    // Ex: "H.I.S. Australia (HOPS)"
                    bookingAgent = `${reservation.agent_name} (${bookingSource.agent_name})`;
                  } else {
                    bookingAgent = bookingSource.agent_name;
                  }
                }

                if (bookingSourceType === 'AGENT') {
                  return (
                    <List.Item>
                      <List.Header>{t('Booking source')}</List.Header>
                      <List.Description>
                        {(bookingAgent || '') +
                          ` (${isResell ? t('resell') : t('agent')})`}
                      </List.Description>
                    </List.Item>
                  );
                }

                return (
                  <List.Item>
                    <List.Header>{t('Booking Source')}</List.Header>
                    <List.Description>{t(bookingSourceType)}</List.Description>
                  </List.Item>
                );
              })()}
            {operationAllowed(activeUser, 'read', 'reservationPaymentType') &&
              reservation.payment_type && (
                <List.Item>
                  <List.Header>{t('Payment Type')}</List.Header>
                  <List.Description>
                    {reservation.payment_type
                      ? t(reservation.payment_type)
                      : t('Unspecified')}
                    {reservation.payment_deferred
                      ? '/' + t('Email Payment')
                      : ''}
                    {reservation.payment_deferred &&
                      reservation.billing_info &&
                      (paymentDeferredAvailable
                        ? '[' + t('Not Settled') + ']'
                        : '[' + t('Settled') + ']')}
                  </List.Description>
                </List.Item>
              )}
            {operationAllowed(activeUser, 'read', 'reservationPaymentMethod') &&
              reservation.payment_method && (
                <List.Item>
                  <List.Header>{t('Payment Method')}</List.Header>
                  <List.Description>
                    {reservation.payment_method
                      ? t(reservation.payment_method)
                      : t('Unspecified')}
                  </List.Description>
                </List.Item>
              )}
            {reservation.promo_code && (
              <List.Item>
                <List.Header>{t('Promo Code')}</List.Header>
                <List.Description>{reservation.promo_code}</List.Description>
              </List.Item>
            )}
            {operationAllowed(activeUser, 'read', 'reservationAgentNotes') && (
              <List.Item>
                <List.Header>
                  {t('Remarks')}{' '}
                  {userCanEditAgentNotes && (
                    <NotesEditButton
                      reservation={reservation}
                      fieldName="agent_notes"
                    />
                  )}
                </List.Header>
                <List.Content style={{ whiteSpace: 'pre-wrap' }}>
                  {reservation.agent_notes}
                </List.Content>
              </List.Item>
            )}
            {operationAllowed(
              activeUser,
              'read',
              'reservationSupplierNotes'
            ) && (
              <List.Item>
                <List.Header>
                  {t('Replies')}{' '}
                  {userCanEditSupplierNotes && (
                    <NotesEditButton
                      reservation={reservation}
                      fieldName="supplier_notes"
                    />
                  )}
                </List.Header>
                <List.Content style={{ whiteSpace: 'pre-wrap' }}>
                  {reservation.supplier_notes}
                </List.Content>
              </List.Item>
            )}
            {reservation.guest_exempt_from_marketing && (
              <List.Item>
                <List.Header>
                  {t('* Guest has unsubscribed from marketing emails')}
                </List.Header>
              </List.Item>
            )}
          </List>
          <Header as="h3">{t('Activity Log')}</Header>
          <Feed>
            {actions.map((action, idx) => {
              const actionText = this.printAction(action);

              return (
                actionText && (
                  <Feed.Event key={idx}>
                    <Feed.Content
                      summary={actionText}
                      date={moment(action.time).locale(locale).format('lll')}
                    />
                  </Feed.Event>
                )
              );
            })}
          </Feed>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationId];
  const product =
    reservation && state.products.byID[reservation.product_id || ''];
  const productInstance =
    reservation && state.productInstances.byID[reservation.product_instance_id];
  return {
    locale: state.language.selected.iso,
    activeUser: activeUserSelector(state),
    loading:
      state.reservations.loading ||
      state.products.loading ||
      state.productInstances.loading,
    reservation,
    product,
    productInstance,
  };
};

export const ReservationTab = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, null),
  withTranslation()
)(ReservationTabComponent);
