import { createSelector } from 'reselect';

import { activeUserOrganizationSelector } from 'client/reducers/user';

export const essentialPagesSelector = createSelector(
  activeUserOrganizationSelector,
  (organization) => {
    return organization?.essential_pages || null;
  }
);
