import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'client/components/v3/Common/Button';

import styles from './ActionsFooter.module.css';

interface Props {
  loading: boolean;
}

export const ActionsFooter: React.FC<Props> = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <div className={styles.actions}>
        <button className={styles.resetButton}>{t('Reset')}</button>
        <Button
          loading={loading}
          color="primary"
          text={t('Save')}
          type="submit"
        />
      </div>
    </footer>
  );
};
