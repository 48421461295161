import * as React from 'react';

import styles from './TranslatedInputContainer.module.css';

interface Props {
  children: React.ReactNode;
}

export const TranslatedInputContainer = ({ children }: Props) => {
  return <div className={styles['container']}>{children}</div>;
};
