import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React, { useState } from 'react';

import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { getFieldOptions } from 'client/libraries/util/coreutil';
import { getKnownFieldPrompt } from 'client/libraries/util/getKnownFieldPrompt';
import { FormField } from 'client/pages/v3/Reservation/ReservationCreate/Booking/Form/FormField';
import { Box } from 'client/components/Box/Box';
import type { Field } from 'shared/models/swagger';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';

type Props = {
  fields: Field[];
  unmappedFieldResponses?: Record<string, any>[];
  getFieldValue: (arg0: string) => string;
  getFieldLeaveBlankValue?: (arg0: string) => boolean;
  onFieldChange: (id: string, value: string, leaveBlank?: boolean) => void;
  errorMap: Record<string, string>;
  mode?: 'READ_ONLY' | 'TOGGLE' | 'INPUT';
  showLeaveBlankBox?: boolean;
  requiredFieldOptionalEntry?: boolean;
  type?: 'BOOK' | 'EDIT';
  wrapperClassName?: string;
};

export const FieldsForm = ({
  errorMap = {},
  fields,
  unmappedFieldResponses,
  getFieldValue,
  getFieldLeaveBlankValue,
  mode = 'TOGGLE',
  showLeaveBlankBox,
  requiredFieldOptionalEntry,
  onFieldChange,
  wrapperClassName = '',
}: Props) => {
  const [editKey, setEditKey] = useState<string>('');
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {fields.map((f, idx) =>
        (_.isEqual(editKey, f.key) && mode === 'TOGGLE') || mode === 'INPUT' ? (
          <>
            <React.Fragment key={idx}>
              <FieldWrapper
                label={f.prompt}
                wrapperClassName={wrapperClassName}
                required={
                  f.required !== 'OPTIONAL' && !requiredFieldOptionalEntry
                }
              >
                <FormField
                  required={
                    f.required !== 'OPTIONAL' && !requiredFieldOptionalEntry
                  }
                  error={errorMap[f.key || '']}
                  key={f.key}
                  options={getFieldOptions(f)}
                  format={f.format}
                  prompt={f.prompt}
                  textArea={f.input_element === 'TEXT_AREA'}
                  value={getFieldValue(f.key || '') || ''}
                  onChange={(value) => onFieldChange(f.key || '', value)}
                  onBlur={() => setEditKey('')}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      // ENTER
                      e.preventDefault();
                      setEditKey('');
                    }
                  }}
                />
              </FieldWrapper>
            </React.Fragment>
            {showLeaveBlankBox && f.required !== 'OPTIONAL' && (
              <Box mt={1} mb={2}>
                <Checkbox
                  label={t('Leave Blank')}
                  onChange={() => {
                    const key = f.key || '';
                    const currentLeaveBlank = getFieldLeaveBlankValue?.(key);

                    onFieldChange(key, '', !currentLeaveBlank);
                  }}
                  checked={getFieldLeaveBlankValue?.(f.key || '') || false}
                />
              </Box>
            )}
          </>
        ) : (
          <React.Fragment key={idx}>
            <FieldWrapper label={f.prompt}>
              <span>
                <span className={clsx(baseStyles['inline-title'])}>
                  {getFieldValue(f.key || '') || '---'}
                </span>
                {mode !== 'READ_ONLY' && (
                  <a
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['icon'],
                      baseStyles['inline-icon']
                    )}
                    onClick={() => setEditKey(f.key || '')}
                  >
                    <img src={editIcon} />
                  </a>
                )}
              </span>
            </FieldWrapper>
          </React.Fragment>
        )
      )}
      {mode !== 'INPUT' &&
        mode !== 'TOGGLE' &&
        unmappedFieldResponses &&
        unmappedFieldResponses.map((field_response, idx) => (
          <React.Fragment key={idx}>
            <FieldWrapper
              label={
                getKnownFieldPrompt(field_response.key, t) || field_response.key
              }
            >
              <span>{field_response.response}</span>
            </FieldWrapper>
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};
