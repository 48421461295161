import * as React from 'react';
import { Field } from 'react-final-form';

import { BaseTemplate } from './BaseTemplate';

const TextAreaEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/TextAreaEditButton')
);

export interface Props {
  editMode?: boolean;
  logoPath: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  title: string;
  message: string;
  poweredByText: string;
}

export const Plain = ({
  editMode,
  logoPath,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  title,
  message,
  poweredByText,
}: Props) => {
  return (
    <BaseTemplate
      {...{
        logoUrl: logoPath,
        supplierName,
        supplierEmail,
        supplierOfficeAddress,
        supplierOfficePhone,
        supplierOfficeHours,
        poweredByText,
      }}
    >
      <div style={{ backgroundColor: '#EEF3F6', color: '#333333' }}>
        <div
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#FFFFFF',
          }}
        >
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ width: '100%', paddingBottom: '32px' }}
          >
            <tr>
              <td style={{ padding: '32px 16px 16px 16px' }}>
                {editMode && (
                  <Field name="templateValues.title">
                    {({ input }) => (
                      <TextAreaEditButton
                        initialValue={input.value}
                        onSave={(text) => input.onChange(text)}
                      />
                    )}
                  </Field>
                )}
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: 0,
                    whiteSpace: 'pre-line',
                  }}
                >
                  {title}
                </p>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '16px' }}>
                {editMode && (
                  <Field name="templateValues.message">
                    {({ input }) => (
                      <TextAreaEditButton
                        initialValue={input.value}
                        onSave={(text) => input.onChange(text)}
                      />
                    )}
                  </Field>
                )}
                <p
                  style={{
                    fontSize: '16px',
                    margin: '0',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {message}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </BaseTemplate>
  );
};
