// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PercentInput } from 'client/pages/ProductPriceList/PercentInput';
import { Button } from 'client/components/Form';

import './CommissionPercentInput.css';

type Props = {
  onChange: (number) => void,
};

export const CommissionPercentInput = ({ onChange }: Props) => {
  const { t } = useTranslation();

  const [percentValue, setPercentValue] = React.useState<number>(0);

  return (
    <>
      <div className={'commission-percent-label'}>
        <div>
          <label>{t('Commission Rate to Apply for All Rows')}</label>
        </div>
        <div className={'commission-percent'}>
          <PercentInput
            value={percentValue}
            onChange={(value) => {
              const newPercent = Math.round(value * 100) / 100;
              setPercentValue(newPercent);
            }}
          />
          <Button
            positive
            size="middle"
            style="gray"
            flex
            onClick={() => onChange(percentValue)}
          >
            {t('Recalculate Net Prices')}
          </Button>
        </div>
      </div>
    </>
  );
};
