import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { buildImpersonateToken } from 'shared/libraries/cognito';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { getIDToken } from 'client/libraries/cognito';
import { ReduxState } from 'client/reducers';
import { Account, MediaDownloadSalesPage } from 'shared/models/swagger';

const fetcher = (
  url: string,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) =>
  fetch(url, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());

export const useMediaDownloadSalesPages = ({
  reservationId,
}: {
  reservationId?: string;
}): {
  salesPages: MediaDownloadSalesPage[];
  isLoading: boolean;
  error: string;
  refetchMediaDownloadSalesPages: () => void;
} => {
  const { i18n } = useTranslation();
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });

  const impersonatedAccount = useSelector(impersonatedAccountSelector);

  const params = new URLSearchParams();

  if (reservationId) {
    params.append('reservation_id', reservationId);
  }
  params.append('statuses', 'MEDIA_SALES_PAGE_PUBLISHED');

  const { data, mutate, error } = useSWR(
    [
      `/api/mediadownloadsalespages?${params.toString()}`,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );

  return {
    refetchMediaDownloadSalesPages: mutate,
    salesPages: data?.media_download_sales_pages ?? [],
    isLoading: Boolean(!error && !data),
    error: error,
  };
};
