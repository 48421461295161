import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import styles from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/TagSettings.module.css';
import { TextField } from 'client/components/v3/Form/TextField';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { v3CustomerTagColors } from 'client/libraries/util/colors';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import {
  CustomerAutoTag,
  CustomerAutoTagCondition,
  convertFormValuesToOrganizationPatch,
  convertSwaggerTagToFormValues,
  getInitialValues,
} from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/FormValues';
import { getArrayMutators } from 'client/libraries/util/form';
import { getValidators } from 'shared/libraries/validate/validator';
import { composeValidators } from 'client/libraries/util/composeValidators';
import { Badge } from 'client/components/v3/Common/Badge';

import { ConditionEditor } from './ConditionEditor';

type Props = {
  tagIndex: number;
  onClose: () => void;
  onSubmitSuccess: (arg0: boolean) => void;
};

type FormValues = CustomerAutoTag;
const focusOnError: any = createDecorator<FormValues, Partial<FormValues>>();

const getDefaultCondition = (): CustomerAutoTagCondition => {
  return {
    gender: 'ANY',
    productIds: [],
    manualTagIds: [],
    registrationDateRangePreset: 'REGISTRATION_DATE_CUSTOM',
    lastParticipationDateRangePreset: 'LAST_30_DAYS',
  };
};

export const EditTagModal = ({ tagIndex, onClose, onSubmitSuccess }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { required } = getValidators(t);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const tags =
    activeUserOrganization?.customer_ledger_settings?.customer_auto_tags ?? [];

  const headerText = tagIndex ? t('Edit Tag') : t('Create New Tag');

  const initialValues = useMemo((): CustomerAutoTag => {
    if (tagIndex >= tags.length) {
      return getInitialValues();
    } else {
      return {
        ...convertSwaggerTagToFormValues(tags[tagIndex]),
      };
    }
  }, [tags, tagIndex]);

  const tagNameUnique = useCallback(
    (name: string): string | undefined => {
      if (tags.some((tag, idx) => tag.name === name && idx !== tagIndex)) {
        return t('Tag name must be unique');
      }

      return undefined;
    },
    [tags]
  );

  if (!activeUserOrganization) {
    return null;
  }

  return (
    <Form<FormValues>
      initialValues={initialValues}
      onSubmit={async (values: CustomerAutoTag) => {
        const orgId = activeUserOrganization?.id || '';
        const patch = convertFormValuesToOrganizationPatch(
          activeUserOrganization,
          tagIndex,
          values
        );

        try {
          await dispatch(updateOrganization(orgId, 'SUPPLIER', patch));
          await onSubmitSuccess(true);
          await onClose();
        } catch (e) {
          console.error(e);
        }
      }}
      decorators={[focusOnError]}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} id="editTagModalForm">
          <Modal
            title={headerText}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button text={t('Cancel')} color="white" onClick={onClose} />
                <Button
                  text={t('Save')}
                  loading={submitting}
                  type="submit"
                  form="editTagModalForm"
                />
              </>
            }
            style={{ width: '600px', height: '800px' }}
          >
            <div className={styles['p-tagSettingsModal']}>
              <div className={styles['p-tagSettingsModal__item']}>
                <p className={styles['p-tagSettingsModal__item__ttl']}>
                  <p>{t('Name')}</p>
                  <Badge
                    size="sm"
                    label={t('Required')}
                    color="warning-contained"
                  />
                </p>
                <div className={styles['p-tagSettingsModal__item__body']}>
                  <Field
                    name="name"
                    validate={composeValidators(required, tagNameUnique)}
                  >
                    {({ input, meta: { error, touched } }) => {
                      return (
                        <TextField
                          value={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                          required={true}
                          error={touched && error ? error : undefined}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles['p-tagSettingsModal__item']}>
                <p className={styles['p-tagSettingsModal__item__ttl']}>
                  {t('Tag Color')}
                </p>
                <div className={styles['p-tagSettingsModal__item__body']}>
                  <Field name="color" validate={required}>
                    {({ input }) => {
                      return (
                        <SingleDropdown
                          options={v3CustomerTagColors}
                          selectedOption={t(
                            v3CustomerTagColors.find(
                              (tagColor) => tagColor.value === input.value
                            )?.text ?? ''
                          )}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                          renderOption={(option: any) => (
                            <div
                              className={
                                styles['p-tagSettingsModal__tagColor__item']
                              }
                            >
                              <i
                                className={
                                  styles['p-tagSettingsModal__tagColor__icon']
                                }
                                style={{
                                  backgroundColor: option.backgroundColor,
                                  borderColor: option.borderColor,
                                }}
                              ></i>
                              <div>{t(option.text)}</div>
                            </div>
                          )}
                        />
                      );
                    }}
                  </Field>
                </div>
                <FieldArray name={'conditions'}>
                  {({ fields }) => {
                    return (
                      <>
                        <div className={styles['p-tagSettingsModal__item']}>
                          <div
                            className={styles['p-tagSettingsModal__condition']}
                          >
                            <ul
                              className={
                                styles['p-tagSettingsModal__condition__list']
                              }
                            >
                              {fields.map((name, index) => {
                                return (
                                  <ConditionEditor
                                    key={index}
                                    fields={fields}
                                    name={name}
                                    index={index}
                                  />
                                );
                              })}
                            </ul>
                            <a
                              className={
                                styles['p-tagSettingsModal__condition__add']
                              }
                              onClick={() => {
                                if (fields.length && fields.length > 0) {
                                  (fields as any).insertAt(
                                    fields.length + 1,
                                    getDefaultCondition()
                                  );
                                } else {
                                  (fields as any).insertAt(
                                    0,
                                    getDefaultCondition()
                                  );
                                }
                              }}
                            >
                              <i className="c-icon-outline-general-plus-circle"></i>
                              {t('Add Condition')}
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </FieldArray>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
