import * as React from 'react';
import { Field } from 'react-final-form';

const ImageEditButton = React.lazy(
  () => import('client/components/TemplateEditButtons/ImageEditButton')
);

type Props = {
  data: {
    editMode: boolean;
    keyVisualUrl: string;
  };
};

export const KeyVisualSection = ({ data }: Props) => {
  return (
    <tr style={{ margin: 0, padding: 0 }}>
      <td>
        <div
          style={{
            padding: '1.5em 1.5em 0 1.5em',
            backgroundColor: '#fff',
          }}
        >
          {data.editMode && (
            <Field name="templateValues.keyVisualUrl">
              {({ input }) => (
                <ImageEditButton
                  initialValue={input.value}
                  onSave={(text) => input.onChange(text)}
                />
              )}
            </Field>
          )}
          <div
            style={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <img
              src={data.keyVisualUrl}
              style={{ width: '100%', borderRadius: '8px' }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};
