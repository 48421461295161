// @flow

import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { useQuery } from 'client/hooks/useQuery';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { EmailLog } from 'client/pages/ReservationDetails/EmailLog';
import { ReservationMainInformation } from 'client/pages/ReservationDetails/ReservationMainInformation';
import { ReservationDetailTab } from 'client/pages/ReservationDetails/ReservationDetailTab';
import { ReservationGuestTab } from 'client/pages/ReservationDetails/ReservationGuestTab';
import { ReservationBillingTab } from 'client/pages/ReservationDetails/ReservationBillingTab/ReservationBillingTab';
import { ReservationInquiryTab } from 'client/pages/ReservationDetails/ReservationInquiryTab/ReservationInquiryTab';
import { ReservationReviewsTab } from 'client/pages/ReservationDetails/ReservationReviewsTab/ReservationReviewsTab';
import { ReservationWaiversTab } from 'client/pages/ReservationDetails/ReservationWaiversTab/ReservationWaiversTab';
import { ReservationCheckinTab } from 'client/pages/ReservationDetails/ReservationCheckinTab/ReservationCheckinTab';
import { ReservationGuestTypeCountCheckinTab } from 'client/pages/ReservationDetails/ReservationGuestTypeCountCheckinTab/ReservationGuestTypeCountCheckinTab';
import { ReservationRedemptionCountCheckinTab } from 'client/pages/ReservationDetails/ReservationRedemptionCountCheckinTab/ReservationRedemptionCountCheckinTab';
import { ReservationCouponTab } from 'client/pages/ReservationDetails/ReservationCouponTab/ReservationCouponTab';
import { ReservationFootprintTab } from 'client/pages/ReservationDetails/ReservationFootprintTab/ReservationFootprintTab';
import { ReservationSurveysTab } from 'client/pages/ReservationDetails/ReservationSurveysTab/ReservationSurveysTab';
import { ReservationMediaDownloadsTab } from 'client/pages/ReservationDetails/ReservationMediaDownloadsTab/ReservationMediaDownloadsTab';
import { ReservationRelatedInformation } from 'client/pages/ReservationDetails/ReservationRelatedInformation';
import { ReservationSupplementaryInformation } from 'client/pages/ReservationDetails/ReservationSupplementaryInformation';
import { ReservationActivityLog } from 'client/pages/ReservationDetails/ReservationActivityLog';
import { Loading } from 'client/pages/Loading';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import type {
  Reservation,
  ReservationReceipt,
  Product,
  ProductInstance,
} from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import { ReservationContext } from './ReservationContext';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

type Props = {
  reservation?: Reservation,
  reservationReceipt?: ReservationReceipt,
  product?: Product,
  productInstance?: ProductInstance,
  loading?: boolean,
};

export const ReservationDetails = ({
  reservation,
  reservationReceipt,
  product,
  productInstance,
  loading,
}: Props) => {
  const { t, i18n } = useTranslation();

  const query = useQuery();
  const initialActiveTab = query.get('t') ?? 'detail';

  const activeUser = useSelector(activeUserSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const [activeTab, setActiveTab] = React.useState<
    | 'detail'
    | 'guest'
    | 'billing'
    | 'reviews'
    | 'waivers'
    | 'mediaDownload'
    | 'checkin'
    | 'inquiry'
    | 'digitalGuidance'
    | 'footprint'
    | 'survey'
    | 'map'
  >(initialActiveTab);

  const mainDivRef = React.useRef<HTMLDivElement | null>(null);
  const detailDivRef = React.useRef<HTMLDivElement | null>(null);
  const relatedDivRef = React.useRef<HTMLDivElement | null>(null);
  const supplementaryDivRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const sTop = supplementaryDivRef?.current?.getBoundingClientRect().top;
      const rTop = relatedDivRef?.current?.getBoundingClientRect().top;
      const dTop = detailDivRef?.current?.getBoundingClientRect().top;

      // NOTE(goro) need to check (!null && !undefined) by != (not !==)
      if (sTop != null && rTop != null && dTop != null) {
        if (sTop <= 100) {
          // NOTE(goro) The exact height of header is 96. add 4 px for buffer
          setFocusedPane('supplementary');
        } else if (rTop <= 100) {
          setFocusedPane('related');
        } else if (dTop <= 100) {
          setFocusedPane('detail');
        } else {
          setFocusedPane('main');
        }
      }
    };
    window.document.addEventListener('scroll', handleScroll, { capture: true });
    return () => {
      window.document.removeEventListener('scroll', handleScroll, {
        capture: true,
      });
    };
  }, [mainDivRef, detailDivRef, relatedDivRef, supplementaryDivRef]);

  const [focusedPane, setFocusedPane] = React.useState<
    'main' | 'detail' | 'related' | 'supplementary'
  >('main');

  if (!reservation || !product || !productInstance) {
    return <Loading />;
  }

  return (
    <ReservationContext.Provider value={reservation}>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="RESERVATION_DETAILS" />
      )}
      {loading && <Loading />}
      <nav className={clsx(pageStyles['page-reservations__spNav'])}>
        <ul>
          <li
            className={clsx(
              focusedPane === 'main' ? pageStyles['is-active'] : ''
            )}
          >
            <a href="#main">{t('Main')}</a>
          </li>
          <li
            className={clsx(
              focusedPane === 'detail' ? pageStyles['is-active'] : ''
            )}
          >
            <a href="#detail">{t('Details')}</a>
          </li>
          <li
            className={clsx(
              focusedPane === 'related' ? pageStyles['is-active'] : ''
            )}
          >
            <a href="#related">{t('Related')}</a>
          </li>
          <li
            className={clsx(
              focusedPane === 'supplementary' ? pageStyles['is-active'] : ''
            )}
          >
            <a href="#supplementary">{t('Sup.')}</a>
          </li>
        </ul>
      </nav>

      <ReservationMainInformation
        reservation={reservation}
        reservationReceipt={reservationReceipt}
        product={product}
        productInstance={productInstance}
        locale={i18n.language}
        activeUser={activeUser}
        ref={mainDivRef}
      />

      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
        ref={detailDivRef}
        id="detail"
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'detail' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('detail');
            }}
          >
            <a>{t('Detail Information')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'guest' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('guest');
            }}
          >
            <a>{t('Guest Information')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'billing' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('billing');
            }}
          >
            <a>{t('Billing Info')}</a>
          </li>
          {hasSubscription(activeOrganization, 'feature-review') &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'reviews' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('reviews');
                }}
              >
                <a>{t('Reviews')}</a>
              </li>
            )}
          {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'waivers' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('waivers');
                }}
              >
                <a>{t('Waiver')}</a>
              </li>
            )}
          {hasSubscription(activeOrganization, 'feature-image-download') &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'mediaDownload'
                    ? componentStyles['is-active']
                    : ''
                )}
                onClick={() => {
                  setActiveTab('mediaDownload');
                }}
              >
                <a>{t('Image Download')}</a>
              </li>
            )}
          {hasSubscription(activeOrganization, 'feature-e-ticket') &&
            product.qr_checkin_settings?.should_use_e_ticket &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'checkin' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('checkin');
                }}
              >
                <a>{t('Ticket Redemptions')}</a>
              </li>
            )}
          {hasSubscription(activeOrganization, 'feature-inquiry') && (
            <li
              className={clsx(
                activeTab === 'inquiry' ? componentStyles['is-active'] : ''
              )}
              onClick={() => {
                setActiveTab('inquiry');
              }}
            >
              <a>{t('Inquiries')}</a>
            </li>
          )}
          {hasSubscription(activeOrganization, 'feature-digital-guidance') && (
            <li
              className={clsx(
                activeTab === 'digitalGuidance'
                  ? componentStyles['is-active']
                  : ''
              )}
              onClick={() => {
                setActiveTab('digitalGuidance');
              }}
            >
              <a>{t('Digital Guidance')}</a>
            </li>
          )}
          {hasSubscription(activeOrganization, 'feature-e-ticket') &&
            product.qr_checkin_settings?.should_use_e_ticket &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'footprint' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('footprint');
                }}
              >
                <a>{t('E-ticket View history')}</a>
              </li>
            )}
          {hasSubscription(activeOrganization, 'feature-survey') &&
            reservation.status === 'CONFIRMED' && (
              <li
                className={clsx(
                  activeTab === 'survey' ? componentStyles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('survey');
                }}
              >
                <a>{t('Survey')}</a>
              </li>
            )}
        </ul>

        <ReservationDetailTab
          reservation={reservation}
          product={product}
          locale={i18n.language}
          activeUser={activeUser}
          readOnly={false}
          active={activeTab === 'detail'}
          productInstance={productInstance}
        />

        <ReservationGuestTab
          reservation={reservation}
          product={product}
          locale={i18n.language}
          activeUser={activeUser}
          readOnly={false}
          active={activeTab === 'guest'}
        />

        <ReservationBillingTab
          reservation={reservation}
          product={product}
          locale={i18n.language}
          activeUser={activeUser}
          readOnly={false}
          active={activeTab === 'billing'}
        />

        {hasSubscription(activeOrganization, 'feature-review') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'reviews' && (
            <ReservationReviewsTab reservation={reservation} />
          )}

        {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'waivers' && (
            <ReservationWaiversTab reservation={reservation} />
          )}

        {hasSubscription(activeOrganization, 'feature-image-download') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'mediaDownload' && (
            <ReservationMediaDownloadsTab reservation={reservation} />
          )}

        {hasSubscription(activeOrganization, 'feature-e-ticket') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'checkin' && (
            <>
              {product?.qr_checkin_settings
                ?.should_count_guests_for_checkin_with_guest_type ? (
                <ReservationGuestTypeCountCheckinTab
                  reservation={reservation}
                  product={product}
                />
              ) : product?.qr_checkin_settings?.should_use_redemption_count ? (
                <ReservationRedemptionCountCheckinTab
                  reservation={reservation}
                  product={product}
                />
              ) : (
                <ReservationCheckinTab
                  reservation={reservation}
                  product={product}
                />
              )}
            </>
          )}

        {hasSubscription(activeOrganization, 'feature-inquiry') &&
          activeTab === 'inquiry' && (
            <ReservationInquiryTab reservation={reservation} />
          )}

        {hasSubscription(activeOrganization, 'feature-digital-guidance') &&
          activeTab === 'digitalGuidance' && (
            <ReservationCouponTab reservation={reservation} />
          )}

        {hasSubscription(activeOrganization, 'feature-e-ticket') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'footprint' && (
            <ReservationFootprintTab
              reservation={reservation}
              product={product}
            />
          )}

        {hasSubscription(activeOrganization, 'feature-survey') &&
          reservation.status === 'CONFIRMED' &&
          activeTab === 'survey' && (
            <ReservationSurveysTab reservation={reservation} />
          )}
      </div>

      <div className={clsx(pageStyles['base-main__body__half'])}>
        <ReservationRelatedInformation
          reservation={reservation}
          product={product}
          locale={i18n.language}
          activeUser={activeUser}
          ref={relatedDivRef}
        />

        <ReservationSupplementaryInformation
          reservation={reservation}
          product={product}
          locale={i18n.language}
          activeUser={activeUser}
          ref={supplementaryDivRef}
        />
      </div>

      <ReservationActivityLog
        reservation={reservation}
        product={product}
        locale={i18n.language}
        activeUser={activeUser}
      />

      {operationAllowed(activeUser, 'write', 'reservationConfirmation') && (
        <EmailLog reservation={reservation} />
      )}
    </ReservationContext.Provider>
  );
};
