import lodashTimes from 'lodash/times';

import starOnIcon from 'client/images/ic_star_on.svg';
import starOffIcon from 'client/images/ic_star_off.svg';
import starHalfIcon from 'client/images/ic_star_half.svg';

interface Props {
  rating: number;
}

export const Stars = ({ rating }: Props) => {
  const decimalPart = rating - Math.floor(rating);

  return (
    <>
      {lodashTimes(Math.floor(rating), (i) => (
        <img key={i} src={starOnIcon} />
      ))}
      {decimalPart > 0 &&
        (decimalPart >= 0.75 ? (
          <img src={starOnIcon} />
        ) : decimalPart >= 0.25 ? (
          <img src={starHalfIcon} />
        ) : (
          <img src={starOffIcon} />
        ))}
      {lodashTimes(Math.floor(5 - rating), (i) => (
        <img key={i} src={starOffIcon} />
      ))}
    </>
  );
};
