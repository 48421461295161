import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDateFilterPresetOptions,
  DateFilterPreset,
} from 'client/libraries/util/searchInstantWinEvents';
import { Modal } from 'client/components/Modal/Modal';
import type { SearchInstantWinEventsRequest } from 'client/libraries/util/searchInstantWinEvents';
import { DateRangeInput, Button, Select } from 'client/components/Form';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  searchCondition: SearchInstantWinEventsRequest;
  setSearchCondition: (arg0: SearchInstantWinEventsRequest) => void;
  trigger: React.ReactElement<any>;
};
export const InstantWinEventSearchSettingsModal = ({
  onReset,
  onSearch,
  searchCondition,
  setSearchCondition,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];

  const drawnDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      drawnDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const drawnDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      drawnDateTo: date,
      dateFilterPreset: null,
    });
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Search InstantWinEvents')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Modal.Box>
          <Select
            label={t('Date Preset')}
            options={dateFilterPresetOptions}
            value={searchCondition.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setSearchCondition({
                ...searchCondition,
                dateFilterPreset:
                  value === 'NONE' ? null : (value as DateFilterPreset),
                drawnDateFrom: '',
                drawnDateTo: '',
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <DateRangeInput
            label={t('Participation Date')}
            fromDate={searchCondition.drawnDateFrom}
            onChangeFromDate={drawnDateDateFromChangeHandler}
            toDate={searchCondition.drawnDateTo}
            onChangeToDate={drawnDateDateToChangeHandler}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
          }}
        >
          {t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
