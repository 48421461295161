import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { Box } from 'client/components/Box/Box';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import baseStyles from 'client/base.module.css';
import { SystemFeeInvoice } from 'shared/models/swagger';
import {
  AgentGrossColumn,
  AgentCancelFeeColumn,
  AgentsCommitionCell,
} from 'client/components/PartnershipSystemFee/PartnershipSystemFee';

import styles from './PartnershipSystemFeeInvoiceSummary.module.css';

interface Props {
  invoice: SystemFeeInvoice;
}

export const PartnershipSystemFeeInvoiceSummary = ({ invoice }: Props) => {
  const { t } = useTranslation();
  const currency = useSelector(defaultProductCurrencySelector);

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <div className={styles.summaryTable}>
        <table>
          <tbody>
            <tr>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross')}
                </Box>
              </th>
              <th>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('PIF')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Gross by agent')}
                  </Box>
                </Box>
              </th>
              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee')}
                </Box>
              </th>
              <th>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('PIF')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Cancel Fee by agent')}
                  </Box>
                </Box>
              </th>
              <th>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('PIF')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Commission')}
                  </Box>
                </Box>
              </th>
              <th>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('PIF')}
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    {t('Commission by agent')}
                  </Box>
                </Box>
              </th>

              <th>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Billing Amount')}
                </Box>
              </th>
            </tr>
            <tr>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_gross_amount)}
              </td>
              <td>
                <Box>
                  <AgentGrossColumn invoice={invoice} />
                </Box>
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_cancellation_fee_amount)}
              </td>
              <td>
                <Box>
                  <AgentCancelFeeColumn invoice={invoice} />
                </Box>
              </td>

              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_commission_amount)}
              </td>
              <td>
                <Box>
                  <AgentsCommitionCell invoice={invoice} />
                </Box>
              </td>

              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {invoice?.amount_due_by_nutmeg_final ? (
                  getFormattedAmount(invoice?.amount_due_by_nutmeg_final)
                ) : (
                  <div className={clsx(styles['red'], styles['right-align'])}>
                    {getFormattedAmount(invoice?.amount_due_by_supplier_final)}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
