import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';

import { FormValues } from './formValues';

export const Complete = () => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return (
    <div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Bulk Email Notification Has Been Sent')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <FieldWrapper label={t('Subject')}>{values.subject}</FieldWrapper>
          <Box mt={2}>
            <FieldWrapper label={t('Description')}>
              <div className="newline">{values.description}</div>
            </FieldWrapper>
          </Box>
          <Box mt={2}>
            <FieldWrapper
              label={t('Reservations to Notify (Total: {{count}})', {
                count: values.reservations?.length ?? 0,
              })}
            >
              <FormTableBox>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '48px' }}></th>
                      <th>{t('Product Name')}</th>
                      <th>{t('Participation')}</th>
                      <th>{t('Customer')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.reservations.map((reservation, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href={`/reservations/${reservation.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={clsx(
                              baseStyles['base-btn'],
                              baseStyles['icon']
                            )}
                            data-text={t('Detail')}
                          >
                            <img src={reservationDetailIcon} />
                          </a>
                        </td>
                        <td>
                          {reservation.internal_product_name ??
                            reservation.product_name}
                        </td>
                        <td>
                          {reservation.participates_at
                            ?.locale(locale)
                            .format('lll')}
                        </td>
                        <td>{reservation.guest_display_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FormTableBox>
            </FieldWrapper>
          </Box>
        </div>
      </div>
    </div>
  );
};
