import * as React from 'react';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getValidators } from 'shared/libraries/validate/validator';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import baseStyles from 'client/base.module.css';

import styles from './EnumRadioButtonGroup.module.css';

type EnumRadioButtonsProps = {
  disabled?: boolean;
  name: string;
  options: {
    label: string;
    value: string;
    tooltipText?: string;
    disabled?: boolean;
  }[];
  onChange?: (newValue: string) => void;
  style?: Record<string, any>;
  liStyle?: Record<string, any>;
  isRequired?: boolean;
};
export const EnumRadioButtonGroup = ({
  disabled,
  name,
  options,
  onChange,
  style,
  liStyle,
  isRequired,
}: EnumRadioButtonsProps) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  return (
    <Field name={name} validate={isRequired ? required : undefined}>
      {({ input, meta: { touched, error } }) => (
        <>
          <ul className={clsx(baseStyles['base-flex'], styles['priceSelect'])}>
            {options.map((option) => (
              <li key={option.value} style={liStyle}>
                <div className={baseStyles['base-form-radio']}>
                  <label
                    className={
                      disabled || option.disabled
                        ? baseStyles['disabled']
                        : undefined
                    }
                  >
                    <input
                      disabled={disabled || option.disabled}
                      name={name}
                      type="radio"
                      checked={option.value === input.value}
                      onChange={() => {
                        onChange?.(option.value);
                        input.onChange(option.value);
                      }}
                      style={style}
                    />
                    <p></p>
                    {option.label}
                  </label>
                </div>
                {option.tooltipText && (
                  <div className={styles['tooltip-box']}>
                    <Tooltip text={option.tooltipText} />
                  </div>
                )}
              </li>
            ))}
          </ul>
          {touched && error && (
            <p className={baseStyles['base-form-box__err']}>{error}</p>
          )}
        </>
      )}
    </Field>
  );
};
