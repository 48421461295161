import _ from 'lodash';

import {
  Section,
  HighlightItem,
  ImageGalleryItem,
  NavigationItem,
  TileItem,
} from './util';

type TFunc = (key: string, args?: Record<string, string>) => string;

export const getDefaultProductSection = (t: TFunc): Section => ({
  type: 'PRODUCT',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  product: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    productId: '',
    style: 'SQUARE',
    buttonText: t('See More'),
    buttonUrl: 'https://ntmg.jp',
  },
});

export const getDefaultTileItem = (t: TFunc): TileItem => ({
  text: t('Description Text Goes Here. '),
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
});

export const getDefaultTilesSection = (t: TFunc): Section => ({
  type: 'TILES',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  tile: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    buttonText: t('See More'),
    buttonUrl: 'https://ntmg.jp',
    style: 'SINGLE_PANE_CHECKBOARD',
    imageStyle: 'CIRCLE',
    items: _.times(4, () => getDefaultTileItem(t)),
  },
});

export const getDefaultNavigationItem = (t: TFunc): NavigationItem => ({
  text: t('Text link'),
  linkUrl: 'https://ntmg.jp',
});

export const getDefaultNavigationSection = (t: TFunc): Section => ({
  type: 'NAVIGATION',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  navigation: {
    style: 'HORIZONTAL_WITH_LINE',
    useIcon: false,
    items: _.times(4, () => getDefaultNavigationItem(t)),
  },
});

export const getDefaultImageGalleryItem = (): ImageGalleryItem => ({
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/3b905e91-dfe4-5bcf-9667-58b0e3817e83/tut/a3d0cd60-133d-415e-a8eb-6cf5053f4059.jpg',
});

export const getDefaultImageGallerySection = (t: TFunc): Section => ({
  type: 'IMAGE_GALLERY',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  imageGallery: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    buttonText: t('See More'),
    buttonUrl: 'https://ntmg.jp',
    style: 'ONE_PANE',
    items: _.times(4, () => getDefaultImageGalleryItem()),
  },
});

export const getDefaultHighlightItem = (t: TFunc): HighlightItem => ({
  text: t('Text Goes Here'),
  imageUrl:
    'https://ntmg-media.s3.us-west-1.amazonaws.com/e95717ff-913d-5e05-8b95-bddfb11a3673/sea-turtle-547162_1920.jpg',
});

export const getDefaultHighlightsSection = (t: TFunc): Section => ({
  type: 'HIGHLIGHTS',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  highlight: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    imageUrl: 'https://assets.ntmg.com/newsletter/header-1.png',
    style: 'NUMBER_SMALL',
    headingText: t('Highlights'),
    headingBackgroundColor: '#2563EB',
    headingTextColor: '#FFF',
    items: _.times(4, () => getDefaultHighlightItem(t)),
    itemBackgroundColor: '#EFF6FF',
    itemTextColor: '#3F3F46',
  },
});

export const getDefaultPromotionSection = (t: TFunc): Section => ({
  type: 'PROMOTION',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  promotion: {
    title: t('Title Goes Here'),
    description: t('Description Text Goes Here. '),
    type: 'PROMOTION_CODE',
    style: 'SIMPLE',
    promotionBackgroundStyle: 'SINGLE_COLOR',
    promotionBackgroundColor: '#0EA5E9',
    promotionCodeTitle: t('Promotion Code'),
    promotionCodeDescription: t('20% OFF'),
    promotionCode: t('ABCD - EFGH - IJKL'),
    promotionTitle: t('Title Goes Here'),
    promotionDescription: t('Description Text Goes Here. '),
    couponButtonText: t('See More'),
    couponButtonUrl: 'https://ntmg.jp',
    couponImageUrl: 'https://assets.ntmg.com/newsletter/default-coupon.png',
  },
});

export const getDefaultSocialMediaSection = (): Section => ({
  type: 'SOCIAL_MEDIA',
  backgroundStyle: 'SINGLE_COLOR',
  backgroundColor: '#FFFFFF',
  socialMedia: {
    color: 'BLACK',
    style: 'NO_BACKGROUND',
    facebookUrl: 'Sample URL',
    facebookImageUrl:
      'https://assets.ntmg.com/newsletter/snsicons/fb-black-nobg.png',
    instagramUrl: 'Sample URL',
    instagramImageUrl:
      'https://assets.ntmg.com/newsletter/snsicons/insta-black-nobg.png',
    tiktokUrl: 'Sample URL',
    tiktokImageUrl:
      'https://assets.ntmg.com/newsletter/snsicons/tiktok-black-nobg.png',
    websiteurl: 'Sample URL',
    websiteImageUrl:
      'https://assets.ntmg.com/newsletter/snsicons/web-black-nobg.png',
    xUrl: 'Sample URL',
    xImageUrl: 'https://assets.ntmg.com/newsletter/snsicons/x-black-nobg.png',
  },
});

export const getDefaultContentsForSection = (
  type:
    | 'PRODUCT'
    | 'TILES'
    | 'NAVIGATION'
    | 'IMAGE_GALLERY'
    | 'HIGHLIGHTS'
    | 'PROMOTION'
    | 'SOCIAL_MEDIA',
  t: TFunc
): Section => {
  switch (type) {
    case 'PRODUCT':
      return getDefaultProductSection(t);
    case 'TILES':
      return getDefaultTilesSection(t);
    case 'NAVIGATION':
      return getDefaultNavigationSection(t);
    case 'IMAGE_GALLERY':
      return getDefaultImageGallerySection(t);
    case 'HIGHLIGHTS':
      return getDefaultHighlightsSection(t);
    case 'PROMOTION':
      return getDefaultPromotionSection(t);
    case 'SOCIAL_MEDIA':
      return getDefaultSocialMediaSection();
    default:
      return getDefaultProductSection(t);
  }
};

export const useDefaultContents = (t: TFunc) => {
  return [
    getDefaultContentsForSection('HIGHLIGHTS', t),
    getDefaultContentsForSection('IMAGE_GALLERY', t),
    getDefaultContentsForSection('NAVIGATION', t),
  ];
};
