import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchSurveySubmissions } from 'client/actions/surveySubmissions';
import { SubmittedSurveyAnswers } from 'client/pages/Survey/SubmittedSurveyList/SubmittedSurveyAnswers';
import { ReduxState } from 'client/reducers';
import { sendSurveyRequestEmail } from 'client/actions/reservations';
import type { Reservation, ReviewRating } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import v3BaseStyles from 'client/v3-base.module.css';
import reservationStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import { SendSurveyRequestEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationSurveys/SendSurveyRequestEmailModal';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

export function convertReviewRatingToNumber(
  reviewRating: ReviewRating
): number {
  return parseInt(reviewRating.replace('REVIEW_RATING_', ''));
}

type Props = {
  reservation?: Reservation;
};
export const ReservationSurveys = ({ reservation }: Props) => {
  const { t } = useTranslation();

  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [emailSendingStatus, setEmailSendingStatus] = useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const surveySubmissions = useSelector(
    (state: ReduxState) => state.surveySubmissions.summaries
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  useEffect(() => {
    if (reservation?.id) {
      dispatch(
        fetchSurveySubmissions({
          reservation_id: reservation.id,
          include_survey_template: true,
        })
      );
    }
  }, []);

  const surveySubmission =
    surveySubmissions.length > 0 ? surveySubmissions[0] : null;

  return (
    <section
      id="survey"
      className={clsx(reservationStyles['g-section'], v3BaseStyles['u-mt-6'])}
    >
      <div className={reservationStyles['p-reservationsDetail']}>
        <div className={reservationStyles['p-reservationsDetail__header']}>
          <p className={reservationStyles['p-reservationsDetail__ttl']}>
            {t('Survey')}
          </p>
        </div>
        <div className={reservationStyles['p-reservationsDetail__body']}>
          {!surveySubmission && (
            <>
              {!isSubscriptionCancelled(
                activeOrganization,
                'feature-survey'
              ) ? (
                <>
                  <Button
                    text={t('Send Survey Request Email')}
                    disabled={emailSendingStatus === 'SUCCEEDED'}
                    loading={emailSendingStatus === 'REQUESTED'}
                    onClick={() => setShowModal(true)}
                  />
                </>
              ) : (
                <>
                  {t(
                    'Your subscription to the surveys feature has been deactivated so sending survey request emails is disabled. Resubscribe here: '
                  )}{' '}
                  <a
                    className={baseStyles['base-link']}
                    href={`${window.origin}/systemfee/settings`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Settings')}
                  </a>
                </>
              )}
            </>
          )}

          {showModal && (
            <SendSurveyRequestEmailModal
              initialToAddress={email}
              onSubmit={(email?: string) => {
                try {
                  setEmailSendingStatus('REQUESTED');
                  dispatch(
                    sendSurveyRequestEmail(reservation?.id ?? '', email)
                  );
                  setEmailSendingStatus('SUCCEEDED');
                } catch (e) {
                  setEmailSendingStatus('FAILED');
                }
              }}
              onClose={() => setShowModal(false)}
            />
          )}

          {emailSendingStatus === 'SUCCEEDED' && (
            <Snackbar
              text={t('Send Successful')}
              color="success"
              shouldShow={emailSendingStatus === 'SUCCEEDED'}
            />
          )}
          {emailSendingStatus === 'FAILED' && (
            <Snackbar
              text={t('Send Failed')}
              color="error"
              shouldShow={emailSendingStatus === 'FAILED'}
            />
          )}

          {surveySubmission && (
            <table
              className={clsx(
                tableSmallStyles['c-tableSmall'],
                tableSmallStyles['row']
              )}
            >
              <thead>
                <tr>
                  <th className={v3BaseStyles['u-width-96']}>
                    {t('Submitted')}
                  </th>
                  <th className={v3BaseStyles['u-width-176']}>
                    {t('Responses')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {moment(surveySubmission.submitted_date_time_utc)
                      .locale(locale)
                      .format('lll')}
                  </td>
                  <td>
                    <SubmittedSurveyAnswers
                      surveySubmission={surveySubmission}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};
