import { combineReducers } from 'redux';

import {
  FETCH_SUBSCRIPTION_INVOICES_REQUEST,
  FETCH_SUBSCRIPTION_INVOICES_FAILURE,
  FETCH_SUBSCRIPTION_INVOICES_SUCCESS,
} from 'client/constants/ActionTypes';
import { SubscriptionInvoice } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_INVOICES_REQUEST:
      return true;

    case FETCH_SUBSCRIPTION_INVOICES_FAILURE:
    case FETCH_SUBSCRIPTION_INVOICES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: SubscriptionInvoice[] = [],
  action: any
): SubscriptionInvoice[] => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_INVOICES_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

export const subscriptionInvoices = combineReducers({
  loading,
  all,
});
