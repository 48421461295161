import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers';

import styles from './FiltersDisplayBox.module.css';
import { FilterSurveySubmissionsRequest } from './util';

type Props = {
  filterCondition: FilterSurveySubmissionsRequest;
};
export const FiltersDisplayBox = ({ filterCondition }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (
    filterCondition.participationDateFrom ||
    filterCondition.participationDateTo
  ) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${filterCondition.participationDateFrom ?? ''} ~ ${
        filterCondition.participationDateTo
      }`,
    });
  }

  if (filterCondition.submissionDateFrom || filterCondition.submissionDateTo) {
    filters.push({
      typeText: t('Submission Date'),
      filterValue: `${filterCondition.submissionDateFrom ?? ''} ~ ${
        filterCondition.submissionDateTo
      }`,
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      {filters.length > 0 ? (
        <div className={styles['filter-condition-container']}>
          <b>{t('Filter Conditions')}</b>
          {loading ? (
            <ModalLoader />
          ) : (
            <ul>
              {filters.map((f) => (
                <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className={styles['filter-condition-container']}>
          {t('No filter conditions')}
        </div>
      )}
    </div>
  );
};
