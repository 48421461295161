import * as React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'client/components/Divider/Divider';
import { getBookingWidgetMultiProductCalendarUrl } from 'client/libraries/util/getBookingWidgetMultiProductCalendarUrl';
import { EmbeddedMultiProductCalendar } from 'client/pages/BookingWidget/EmbeddedMultiProductCalendar/EmbeddedMultiProductCalendar';

import styles from './EmbedCalendarTab.module.css';

type Props = {
  bookingWidgetApiKey: string;
  widgetLanguage: string;
};

export const BookingWidgetEmbeddedMultiProductCalendar = ({
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const { t } = useTranslation();

  const calendarUrl = getBookingWidgetMultiProductCalendarUrl(
    bookingWidgetApiKey,
    widgetLanguage
  );

  return (
    <>
      <h3>{t('All Products Calendar')}</h3>
      <Divider />
      <List relaxed>
        <List.Item>
          <List.Content>
            <EmbeddedMultiProductCalendar
              apiKey={bookingWidgetApiKey}
              language={widgetLanguage}
            />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>{t('Code')}</List.Header>
          <List.Content>
            <pre
              className={styles['code-block']}
            >{`<script type="text/javascript">
    /*<![CDATA[*/
    (function () {
        loadScriptsAsync();
        function loadScriptsAsync() {
            if (window.buildMultiCalendarEmbed) {
                NutmegMultiCalendarInit();
            } else {
                var script = document.createElement('script');
                script.src = 'https://assets.ntmg.com/scripts/falcon-embed-multi-product/v1/index.js';
                script.async = true;
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                script.onload = NutmegMultiCalendarInit
            }
        
            if (!window.NutmegCalendar) {
              script = document.createElement('script');
              script.src = 'https://assets.ntmg.com/scripts/falcon-embed/v1/index.js';
              script.async = true;
              (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            }
        }
        function NutmegMultiCalendarInit() {
            window.buildMultiCalendarEmbed('${calendarUrl}');
        }
    })();
    /*]]>*/
</script>
<div id="nutmeg-multi-calendar"></div>`}</pre>
          </List.Content>
        </List.Item>
      </List>
    </>
  );
};
