import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { ReduxState } from 'client/reducers';
import { TruncatedTextWithSeeMoreButton } from 'client/components/v3/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { BulkInquiryReservationList } from 'client/components/v3/InquiryItem/BulkInquiryReservationList';
import { InquiryResponseSection } from 'client/components/v3/InquiryItem/InquiryResponseSection';
import { EditTagsModal } from 'client/components/v3/InquiryItem/EditTagsModal';
import { MessageModal } from 'client/components/v3/MessageModal/MessageModal';
import { updateInquiry } from 'client/actions/inquiries';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import { ActionSource, Inquiry } from 'shared/models/swagger';
import newStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Badge } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';

const getGuestName = (item: Inquiry): string => {
  if (item.customer_given_name || item.customer_family_name) {
    if (
      isKanaWord(item.customer_given_name ?? '') &&
      isKanaWord(item.customer_family_name ?? '')
    ) {
      return `${item.customer_family_name} ${item.customer_given_name}`;
    }
    return `${item.customer_given_name} ${item.customer_family_name}`;
  }

  return '';
};

interface Props {
  item: Inquiry;
  printMode?: boolean;
}

export const InquiryItem = ({ item, printMode }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //const loading = useSelector((state: ReduxState) => state.inquiries.loading);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [showMenu, setShowMenu] = useState(false);
  const [showResponseSection, setShowResponseSection] = useState(false);
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);
  const [showStatusChangeConfirmModal, setShowStatusChangeConfirmModal] =
    useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);

  useEffect(() => {
    if (setShowMenu) {
      const handleClickOutside = ({ target }: Event) => {
        if (
          showMenu &&
          target instanceof Node &&
          !fieldSetRef?.current?.contains(target)
        ) {
          setShowMenu(false);
        }
      };

      window.document.addEventListener('mousedown', handleClickOutside);
      window.document.addEventListener('touchstart', handleClickOutside);

      return () => {
        window.document.removeEventListener('mousedown', handleClickOutside);
        window.document.removeEventListener('touchstart', handleClickOutside);
      };
    }
  }, [showMenu]);

  const formattedActionSource = (
    actionSource: ActionSource | undefined,
    guestName: string
  ): string => {
    if (!actionSource) {
      return '';
    }
    if (actionSource.entity_type === 'GUEST_ENTITY_TYPE') {
      if (guestName) {
        return `${guestName} (${t('Guest')})`;
      } else {
        return t('Guest');
      }
    }

    return `${actionSource.entity_name} - ${actionSource.entity_description}`;
  };

  return (
    <>
      <div className={newStyles['p-contactCard__body__inner']}>
        {item.messages?.map((message, index) => (
          <>
            {/* First message should have different layout */}
            {index === 0 ? (
              <>
                <div className={newStyles['p-contactCard__body__info']}>
                  <div className={newStyles['p-contactCard__body__info__ttl']}>
                    {message.email_opened_at_date_time_utc && (
                      <div>
                        <Badge label={t('Opened')} color="gray" size="sm" />
                      </div>
                    )}
                    <span>
                      {formattedActionSource(
                        message.action_source,
                        getGuestName(item)
                      )}
                    </span>
                  </div>
                  <p className={newStyles['p-contactCard__body__info__date']}>
                    {moment(message.received_date_time_utc)
                      .locale(locale)
                      .format('lll')}
                  </p>
                </div>
                <div className={newStyles['p-contactCard__body__desc']}>
                  {printMode ? (
                    message.body
                  ) : (
                    <TruncatedTextWithSeeMoreButton text={message.body ?? ''} />
                  )}
                </div>
                {(message.attachments ?? []).length > 0 && (
                  <ul>
                    {message.attachments?.map((a, idx) => (
                      <li key={idx}>
                        <a
                          className={newStyles['p-contactCard__body__link']}
                          rel="noreferrer"
                          target="_blank"
                          href={a.url}
                          download
                        >
                          {a.filename}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <div className={newStyles['p-contactCard__body__reply']}>
                <div className={newStyles['p-contactCard__body__reply__info']}>
                  <p
                    className={
                      newStyles['p-contactCard__body__reply__info__ttl']
                    }
                  >
                    {message.email_opened_at_date_time_utc && (
                      <div>
                        <Badge label={t('Opened')} color="gray" size="sm" />
                      </div>
                    )}
                    <span>
                      {formattedActionSource(
                        message.action_source,
                        getGuestName(item)
                      )}
                    </span>
                  </p>
                  <p
                    className={
                      newStyles['p-contactCard__body__reply__info__date']
                    }
                  >
                    {moment(message.received_date_time_utc)
                      .locale(locale)
                      .format('lll')}
                  </p>
                </div>
                <div className={newStyles['p-contactCard__body__reply__desc']}>
                  {printMode ? (
                    message.body
                  ) : (
                    <TruncatedTextWithSeeMoreButton text={message.body ?? ''} />
                  )}
                </div>
                {(message.attachments ?? []).length > 0 && (
                  <ul>
                    {message.attachments?.map((a, idx) => (
                      <li key={idx}>
                        <a
                          className={newStyles['p-contactCard__body__link']}
                          rel="noreferrer"
                          target="_blank"
                          href={a.url}
                          download
                        >
                          {a.filename}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </>
        ))}
        {!printMode &&
          !showResponseSection &&
          item.initial_communication_type !== 'BULK_EMAIL_BY_SUPPLIER' &&
          item.initial_communication_type !== 'BULK_SMS_BY_SUPPLIER' &&
          hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
            <div className={newStyles['p-contactCard__body__reply__btn']}>
              <Button
                color="white"
                text={t('Reply')}
                onClick={() => setShowResponseSection(true)}
              />
            </div>
          )}
        {showResponseSection && (
          <InquiryResponseSection
            inquiry={item}
            onClose={() => setShowResponseSection(false)}
          />
        )}
        {(item.initial_communication_type === 'BULK_EMAIL_BY_SUPPLIER' ||
          item.initial_communication_type === 'BULK_SMS_BY_SUPPLIER') && (
          <BulkInquiryReservationList inquiry={item} />
        )}
      </div>
      <div className={newStyles['p-contactCard__body__bottom']}>
        {!printMode && (
          <div className={newStyles['p-contactCard__body__bottom__tags']}>
            {(item?.tags ?? []).length > 0 && (
              <>
                {item?.tags?.map((tag, idx) => (
                  <p
                    style={{ marginRight: '12px', marginBottom: '12px' }}
                    key={idx}
                  >
                    {tag}
                  </p>
                ))}
              </>
            )}
          </div>
        )}
        <fieldset
          ref={fieldSetRef}
          className={newStyles['p-contactCard__select']}
        >
          <div>
            <a
              className="c-button-bg-md-white"
              onClick={() => setShowMenu(!showMenu)}
            >
              <i className="c-icon-solid-general-dots-vertical"></i>
            </a>
          </div>
          <ul
            className={clsx(
              newStyles['p-contactCard__select__menu'],
              showMenu && newStyles['is-active']
            )}
          >
            {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
              <>
                <li
                  className={newStyles['p-contactCard__select__menu__item']}
                  onClick={() => setShowEditTagsModal(true)}
                >
                  <p>{t('Edit Tags')}</p>
                </li>
                {showEditTagsModal && (
                  <EditTagsModal
                    onClose={async () => {
                      await setShowMenu(false);
                      await setShowEditTagsModal(false);
                    }}
                    inquiry={item}
                  />
                )}
              </>
            )}
            <li
              className={newStyles['p-contactCard__select__menu__item']}
              onClick={() => {
                setShowStatusChangeConfirmModal(true);
              }}
            >
              {item.status === 'ARCHIVED' ? (
                <>
                  {hasCustomUserRoleWritePermissions(
                    activeUser,
                    'INQUIRY.LIST'
                  ) && <p>{t('Re-activate')}</p>}
                  {showStatusChangeConfirmModal && (
                    <MessageModal
                      onClose={async () => {
                        await setShowMenu(false);
                        await setShowStatusChangeConfirmModal(false);
                      }}
                      title={t('Re-activate Inquiry')}
                      message={t(
                        'Are you sure you wish to re-activate this inquiry?'
                      )}
                      onSubmit={async () => {
                        await setShowMenu(false);
                        await dispatch(
                          updateInquiry(item.id ?? '', {
                            status: item.messages?.some(
                              (message) =>
                                message.action_source?.entity_type ===
                                'SUPPLIER_ENTITY_TYPE'
                            )
                              ? 'RESPONDED'
                              : 'RECEIVED',
                          })
                        );
                        await setShowStatusChangeConfirmModal(false);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {hasCustomUserRoleWritePermissions(
                    activeUser,
                    'INQUIRY.LIST'
                  ) && <p>{t('Archive')}</p>}
                  {showStatusChangeConfirmModal && (
                    <MessageModal
                      onClose={async () => {
                        await setShowMenu(false);
                        await setShowStatusChangeConfirmModal(false);
                      }}
                      title={t('Archive Inquiry')}
                      message={t(
                        'Are you sure you wish to archive this inquiry?'
                      )}
                      onSubmit={async () => {
                        await setShowMenu(false);
                        await dispatch(
                          updateInquiry(item.id ?? '', {
                            status: 'ARCHIVED',
                          })
                        );
                        await setShowStatusChangeConfirmModal(false);
                      }}
                    />
                  )}
                </>
              )}
            </li>
            <Link
              to={{
                pathname: `/inquiries/${item.id}/print`,
              }}
              target="_blank"
            >
              <li className={newStyles['p-contactCard__select__menu__item']}>
                {t('Printable Page')}
              </li>
            </Link>
          </ul>
        </fieldset>
      </div>
    </>
  );
};
