import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { InquiryItem } from 'client/components/InquiryItem/InquiryItem';
import { fetchInquiry } from 'client/actions/inquiries';
import { ReduxState } from 'client/reducers';
import { ModalLoader } from 'client/components/ModalLoader';

interface Props {
  inquiryId: string;
  onClose: () => void;
}

export const InquiryDetailsModal = ({ inquiryId, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inquiry = useSelector((state: ReduxState) => state.inquiries.details);

  const loading = useSelector((state: ReduxState) => state.inquiries.loading);

  React.useEffect(() => {
    dispatch(fetchInquiry(inquiryId));
  }, []);

  const showArchiveReactivateButtons =
    inquiry?.initial_communication_type !== 'BULK_EMAIL_BY_SUPPLIER' &&
    inquiry?.initial_communication_type !== 'BULK_SMS_BY_SUPPLIER';

  return (
    <Modal
      insertRoot
      title={
        inquiry?.subject
          ? t('Inquiry: {{subject}}', {
              subject: inquiry?.subject,
            })
          : t('Inquiry')
      }
      open={true}
      onClose={onClose}
    >
      <Modal.Content>
        {loading ? (
          <ModalLoader />
        ) : !inquiry ? (
          <div>{t('Inquiry not found')}</div>
        ) : (
          <InquiryItem
            item={inquiry}
            showArchiveReactivateButtons={showArchiveReactivateButtons}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
