import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE,
  FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS,
  FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { MarketingAutomationCampaignParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMarketingAutomationCampaignsRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST,
});

const fetchMarketingAutomationCampaignsSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS,
  response,
});

const fetchMarketingAutomationCampaignsFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE,
  error,
});

let fetchMarketingAutomationCampaignsCancel: () => void | undefined;
export const fetchMarketingAutomationCampaigns =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchMarketingAutomationCampaignsCancel?.();
    dispatch(fetchMarketingAutomationCampaignsRequest());
    axios
      .get('/api/marketingautomationcampaigns', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchMarketingAutomationCampaignsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationCampaignsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMarketingAutomationCampaignsFailure('canceled'));
        dispatch(fetchMarketingAutomationCampaignsFailure(error));
      });
  };

const fetchMarketingAutomationCampaignRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
});

const fetchMarketingAutomationCampaignSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  response,
});

const fetchMarketingAutomationCampaignFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  error,
});

export const fetchMarketingAutomationCampaign =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMarketingAutomationCampaignRequest());
    axios
      .get(`/api/marketingautomationcampaigns/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationCampaignSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMarketingAutomationCampaignFailure('canceled'));
        else dispatch(fetchMarketingAutomationCampaignFailure(error));
      });
  };

const deleteMarketingAutomationCampaignRequest = (id: string) => ({
  type: DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  id,
});

const deleteMarketingAutomationCampaignSuccess = (
  response: any,
  id: string
) => ({
  type: DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  response,
  id,
});

const deleteMarketingAutomationCampaignFailure = (error: any) => ({
  type: DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  error,
});

export const deleteMarketingAutomationCampaign =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMarketingAutomationCampaignRequest(id));
    axios
      .delete(`/api/marketingautomationcampaigns/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(deleteMarketingAutomationCampaignSuccess(response.data, id));
      })
      .catch((error) =>
        dispatch(deleteMarketingAutomationCampaignFailure(error))
      );
  };

const createMarketingAutomationCampaignRequest = (
  newMarketingAutomationCampaign: MarketingAutomationCampaignParams
) => ({
  type: CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  newMarketingAutomationCampaign,
});

const createMarketingAutomationCampaignSuccess = (response: any) => ({
  type: CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  response,
});

const createMarketingAutomationCampaignFailure = (error: any) => ({
  type: CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  error,
});

export const createMarketingAutomationCampaign =
  (newMarketingAutomationCampaign: MarketingAutomationCampaignParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      createMarketingAutomationCampaignRequest(newMarketingAutomationCampaign)
    );
    return axios
      .post(
        '/api/marketingautomationcampaigns',
        newMarketingAutomationCampaign,
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(createMarketingAutomationCampaignSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createMarketingAutomationCampaignFailure(err.message));
      });
  };

const updateMarketingAutomationCampaignRequest = (
  id: string,
  patch: MarketingAutomationCampaignParams
) => ({
  type: UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST,
  id,
  patch,
});

const updateMarketingAutomationCampaignSuccess = (response: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS,
  response,
});

const updateMarketingAutomationCampaignFailure = (error: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE,
  error,
});

export const updateMarketingAutomationCampaign =
  (id: string, patch: MarketingAutomationCampaignParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMarketingAutomationCampaignRequest(id, patch));
    return axios
      .patch(`/api/marketingautomationcampaigns/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateMarketingAutomationCampaignSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateMarketingAutomationCampaignFailure(err.message));
      });
  };

const fetchMarketingAutomationCampaignReportDataRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST,
});

const fetchMarketingAutomationCampaignReportDataSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS,
  response,
});

const fetchMarketingAutomationCampaignReportDataFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE,
  error,
});

let fetchMarketingAutomationCampaignReportDataCancel: () =>
  | void
  | typeof undefined;
export const fetchMarketingAutomationCampaignReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchMarketingAutomationCampaignReportDataCancel &&
      fetchMarketingAutomationCampaignReportDataCancel();
    dispatch(fetchMarketingAutomationCampaignReportDataRequest());

    axios
      .get('/api/marketingautomationcampaigns/reportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchMarketingAutomationCampaignReportDataCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchMarketingAutomationCampaignReportDataSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(
            fetchMarketingAutomationCampaignReportDataFailure('canceled')
          );
        } else {
          dispatch(
            fetchMarketingAutomationCampaignReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
