import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'client/components/Form/Button';
import { fetchProductTags } from 'client/actions/productTags';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import {
  defaultProductCurrencySelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { ProductEditDetailPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductEditDetailPopUp';
import { ProductTutorialHelpPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/ProductTutorialHelpPopUp';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import { HighlightsEditor } from 'client/components/NewProductEditor/DetailsStep/HighlightsEditor/HighlightsEditor';
import { DetailInfoEditor } from 'client/components/NewProductEditor/DetailsStep/DetailInfoEditor/DetailInfoEditor';
import { ItineraryEditor } from 'client/components/NewProductEditor/DetailsStep/ItineraryEditor/ItineraryEditor';
import { ServicesEditor } from 'client/components/NewProductEditor/DetailsStep/ServicesEditor/ServicesEditor';
import { ReservationFormEditor } from 'client/components/NewProductEditor/DetailsStep/ReservationFormEditor/ReservationFormEditor';
import { CheckinPickupLocationsEditor } from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/CheckinPickupLocationsEditor';
import { MinimumParticipantsEditor } from 'client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/MinimumParticipantsEditor';
import { TagsEditor } from 'client/components/NewProductEditor/DetailsStep/TagsEditor/TagsEditor';
import { TransportRouteEditor } from 'client/components/NewProductEditor/DetailsStep/TransportRouteEditor/TransportRouteEditor';
import { InternalTagsEditor } from 'client/components/NewProductEditor/DetailsStep/InternalTagsEditor/InternalTagsEditor';
import {
  Accordion,
  AccordionItem,
} from 'client/components/NewProductEditor/Accordion/Accordion';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';

import {
  getInitialValues,
  convertFormValuesToProductPatch,
} from './FormValues';
import type { FormValues } from './FormValues';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
};
export const DetailsStep = ({ onNextClick, onBackClick }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProductTags());
  }, []);

  const [showPopUp, setShowPopUp] = React.useState<boolean>(true);
  const [showHelpPopUp, setShowHelpPopUp] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!showPopUp) {
      setShowHelpPopUp(true);
    }
  }, [showPopUp]);

  const editingProduct = React.useContext(EditingProductContext);
  const hasPerParticipantPricing =
    editingProduct?.pricing == null ||
    editingProduct?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );
  const isPassthrough = Boolean(
    editingProduct?.shared_allotment_references?.passthrough_base_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const initialValues = React.useMemo(
    () =>
      getInitialValues(
        editingProduct,
        defaultCurrency,
        defaultTimezone,
        t,
        false
      ),
    [editingProduct, defaultCurrency, defaultTimezone, t]
  );
  const convertToProductPatch = React.useCallback(
    (values: FormValues) =>
      convertFormValuesToProductPatch(
        values,
        editingProduct,
        t,
        defaultCurrency,
        false
      ),
    [editingProduct, t, defaultCurrency, false]
  );

  const isTutorialActive = location.pathname.includes('/home/tutorial');

  return (
    <>
      <ProductEditorForm
        onSubmitSuccess={onNextClick}
        initialValues={initialValues}
        convertFormValuesToProductPatch={convertToProductPatch}
        subscription={{
          submitError: true,
          submitting: true,
        }}
      >
        {({ submitError, submitting }) => (
          <>
            <div className={baseStyles['base-main__body__box']}>
              <div className={baseStyles['base-main__body__box__header']}>
                <div
                  className={baseStyles['base-main__body__box__header__ttl']}
                >
                  {t('STEP 3 - Detailed Info')}
                </div>
              </div>
              <div className={baseStyles['base-main__body__box__body']}>
                <p className={styles['page-tutorial__ttl']}>
                  {t(
                    'Optionally register remaining info (editing can also be done later)'
                  )}
                </p>

                <Accordion>
                  <AccordionItem
                    header={t('Highlights')}
                    subtitle={t(
                      'Enter the highlights and summary of the experience'
                    )}
                    initialOpen={
                      editingProduct?.highlights &&
                      editingProduct?.highlights.length > 0
                    }
                  >
                    {() => <HighlightsEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Detailed Info (shown on voucher)')}
                    subtitle={t(
                      'Enter detailed info for the experience (info is shown automatically on reservation confirmation email)'
                    )}
                    initialOpen={
                      (editingProduct?.inclusions &&
                        editingProduct?.inclusions.length > 0) ||
                      (editingProduct?.exclusions &&
                        editingProduct?.exclusions.length > 0)
                    }
                  >
                    {() => <DetailInfoEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Experience Itinerary')}
                    subtitle={t('Explain the flow of the experience')}
                    initialOpen={
                      editingProduct?.itinerary &&
                      editingProduct?.itinerary.length > 0
                    }
                  >
                    {() => <ItineraryEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Add-Ons')}
                    subtitle={t(
                      'Describe additional options that can be selected on the booking form'
                    )}
                    initialOpen={
                      editingProduct?.add_ons &&
                      editingProduct?.add_ons.length > 0
                    }
                  >
                    {() => (
                      <ServicesEditor
                        name="addOns"
                        getItemLabel={(idx: number) =>
                          t('Add-On #{{itemNumber}}', {
                            itemNumber: idx,
                          })
                        }
                      />
                    )}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Transportation')}
                    subtitle={t(
                      'Register if there is pickup (not required for checkin-only)'
                    )}
                    initialOpen={
                      editingProduct?.transportations &&
                      editingProduct?.transportations.length > 0
                    }
                  >
                    {() => (
                      <ServicesEditor
                        name="transportations"
                        getItemLabel={(idx: number) =>
                          t('Transportation #{{itemNumber}}', {
                            itemNumber: idx,
                          })
                        }
                      />
                    )}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Checkin/Pickup Locations')}
                    subtitle={t(
                      'Enter location and time details in advance to make it easy for customers to understand'
                    )}
                    initialOpen={
                      (editingProduct?.pickup &&
                        editingProduct.pickup.length > 0) ||
                      (editingProduct?.dropoff &&
                        editingProduct.dropoff.length > 0)
                    }
                  >
                    {() => <CheckinPickupLocationsEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Reservation Form')}
                    subtitle={t(
                      'Customize information required from customers at the time of booking'
                    )}
                    initialOpen={
                      editingProduct?.reservation_form_fields &&
                      editingProduct.reservation_form_fields.length > 0
                    }
                  >
                    {() => <ReservationFormEditor />}
                  </AccordionItem>
                  {showMinimumParticipants && (
                    <AccordionItem
                      header={t('Minimum Participants & Per-Unit Settings')}
                      subtitle={t(
                        'If minimum participants are set, reservations will be received as request-only until this number of people is reached'
                      )}
                      initialOpen={
                        !!editingProduct?.minimum_participant_count?.value ||
                        editingProduct?.pricing?.some((priceRule) => {
                          priceRule.units?.some((unit) => {
                            return !!unit?.guest_type
                              ?.minimum_participant_parameters?.weight;
                          });
                        }) ||
                        editingProduct?.allotment_settings?.inventory_consumption_rules?.some(
                          (rule) => rule.should_not_count_inventory
                        )
                      }
                    >
                      {() => <MinimumParticipantsEditor />}
                    </AccordionItem>
                  )}
                  <AccordionItem
                    header={t('Product Tags')}
                    subtitle={t(
                      'Use tags for displaying detailed conditions of the experience and help customers narrow down their selection'
                    )}
                    initialOpen={
                      editingProduct?.product_tags &&
                      editingProduct.product_tags.length > 0
                    }
                  >
                    {() => <TagsEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Transport Routes')}
                    initialOpen={
                      editingProduct?.transport_route &&
                      editingProduct.transport_route.length > 0
                    }
                  >
                    {() => <TransportRouteEditor />}
                  </AccordionItem>
                  <AccordionItem
                    header={t('Internal Use Product Tags')}
                    subtitle={t(
                      'Set tags for internal use on product list page'
                    )}
                    initialOpen={
                      editingProduct?.internal_product_tags &&
                      editingProduct.internal_product_tags.length > 0
                    }
                  >
                    {() => <InternalTagsEditor />}
                  </AccordionItem>
                </Accordion>

                <div className={baseStyles['base-main__box__body__bottomBtns']}>
                  {submitError && (
                    <p className={baseStyles['base-form-box__err']}>
                      {submitError}
                    </p>
                  )}
                  <Button size="small" style="gray" onClick={onBackClick}>
                    {t('Back')}
                  </Button>
                  <Button
                    loading={submitting}
                    size="small"
                    style="blue"
                    type="submit"
                  >
                    {t('Next')}
                  </Button>
                </div>
              </div>
            </div>
            {isTutorialActive && showHelpPopUp && (
              <ProductTutorialHelpPopUp onClose={setShowHelpPopUp} />
            )}
          </>
        )}
      </ProductEditorForm>
      {isTutorialActive && showPopUp && (
        <ProductEditDetailPopUp popUpDisable={() => setShowPopUp(false)} />
      )}
    </>
  );
};
