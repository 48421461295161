import * as React from 'react';

import { UnselectedItem } from './UnselectedItem';
import { SelectedItem } from './SelectedItem';

type Item<T> = {
  key: T;
  text: string;
};
type Props<T> = {
  dragItemType?: string;
  selectedItems: Item<T>[];
  candidateItems: Item<T>[];
  setSelectedItems: (items: Item<T>[]) => void;
  toggleDisabledKeys?: string[];
  piiItems: string[];
};
export const ToggleableDndList = ({
  selectedItems,
  candidateItems,
  setSelectedItems,
  dragItemType,
  toggleDisabledKeys,
  piiItems,
}: Props<any>) => {
  const moveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = selectedItems[dragIndex];
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(dragIndex, 1);
      newSelectedItems.splice(hoverIndex, 0, dragItem);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, setSelectedItems]
  );

  const unselect = (itemToUnselect: Item<any>) => {
    setSelectedItems(
      selectedItems.filter((item) => item.key !== itemToUnselect.key)
    );
  };

  const select = (itemToSelect: Item<any>) => {
    setSelectedItems([...selectedItems, itemToSelect]);
  };

  const unselectedItems = candidateItems.filter(
    (item) => !selectedItems.find((i) => i.key === item.key)
  );
  return (
    <>
      {selectedItems.map((item, idx) => (
        <SelectedItem
          dragItemType={dragItemType}
          text={item.text}
          index={idx}
          id={item}
          moveItem={moveItem}
          toggle={() => unselect(item)}
          disabled={Boolean(toggleDisabledKeys?.includes(item.key))}
          pii={piiItems.includes(item.key)}
          key={item.key}
        />
      ))}
      {unselectedItems.map((item) => (
        <UnselectedItem
          text={item.text}
          toggle={() => select(item)}
          pii={piiItems.includes(item.key)}
          key={item.key}
        />
      ))}
    </>
  );
};
