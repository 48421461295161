import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import clsx from 'clsx';
import _ from 'lodash';

import type { ReduxState } from 'client/reducers';
import type { SearchETicketFootprintsRequest } from 'client/libraries/util/searchETicketFootprints';
import {
  convertSearchETicketFootprintsRequestToQueryParams,
  convertSearchETicketFootprintsRequestToDownloadQueryParams,
} from 'client/libraries/util/searchETicketFootprints';
import { Button } from 'client/components/Form';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import {
  fetchETicketFootprints,
  downloadETicketFootprintCSV,
} from 'client/actions/eTicketFootprints';
import { fetchProducts } from 'client/actions/products';
import { setLastExecutedETicketFootprintSearchCondition } from 'client/actions/eTicketFootprintTableControls';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';
import fileDLIcon from 'client/images/ic_file_download.svg';

import { FootprintSearchFiltersDisplayBox } from './FootprintSearchFiltersDisplayBox/FootprintSearchFiltersDisplayBox';
import { useFootprintColumns } from './util';
import { FootprintSearchSettingsModal } from './FootprintSearchSettingsModal';

export const FootprintList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allColumns = useFootprintColumns();

  const loading = useSelector(
    (state: ReduxState) => state.eTicketFootprints.loading
  );
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) =>
      state.eTicketFootprintTableControls.lastExecutedSearchCondition
  );

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const eTicketFootprints = useSelector(
    (state: ReduxState) => state.eTicketFootprints.all
  );

  const downloading = useSelector(
    (state: ReduxState) => state.eTicketFootprints.downloading
  );

  const filteredFootprints = eTicketFootprints.filter((record) => {
    if (!lastExecutedSearchCondition.nearestLocationNameText) {
      return true;
    }
    if (
      (record?.nearest_location_name ?? '').indexOf(
        lastExecutedSearchCondition.nearestLocationNameText
      ) !== -1
    ) {
      return true;
    }
    return false;
  });

  const [searchCondition, setSearchCondition] =
    React.useState<SearchETicketFootprintsRequest>(lastExecutedSearchCondition);

  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);

  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      supplierReference: '',
      customerGivenName: '',
      customerFamilyName: '',
      productIds: [],
      participationDateFrom: '',
      participationDateTo: '',
      checkinDateFrom: '',
      checkinDateTo: '',
      dateFilterPreset: 'PARTICIPATION_1_DAY',
      nearestLocationNameText: '',
    });
  };

  const search = () => {
    if (!isNutmegAdmin) {
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(
          setLastExecutedETicketFootprintSearchCondition(searchCondition)
        );
      }

      dispatch(
        fetchETicketFootprints(
          convertSearchETicketFootprintsRequestToQueryParams(searchCondition)
        )
      );
    }
  };

  const downloadCSV = () => {
    if (!isNutmegAdmin) {
      dispatch(
        downloadETicketFootprintCSV(
          convertSearchETicketFootprintsRequestToDownloadQueryParams(
            searchCondition
          )
        )
      );
    }
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <FootprintSearchSettingsModal
              onReset={reset}
              onSearch={search}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>

          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <div
              className={clsx(
                baseStyles['base-main__body__header__right__another'],
                baseStyles['is-close']
              )}
            >
              <a
                className={clsx(baseStyles['base-btn'])}
                onClick={() => {
                  if (!downloading) {
                    downloadCSV();
                  }
                }}
              >
                <img src={fileDLIcon} />
              </a>

              <ul>
                <li>
                  <Button.Sub
                    content={<>{t('Download CSV')}</>}
                    onClick={() => {
                      if (!downloading) {
                        downloadCSV();
                      }
                    }}
                    loading={downloading}
                    disabled={downloading}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <Box mb={2}>
        <FootprintSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
          totalHits={filteredFootprints.length ?? 0}
        />
      </Box>
      <CustomTable
        items={filteredFootprints}
        columns={allColumns}
        usePaging={true}
      />
    </div>
  );
};
