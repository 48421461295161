import { SET_LANGUAGE } from 'client/constants/ActionTypes';
import {
  currentActorSelector,
  impersonatedAccountSelector,
  alternateOrganizationIsActiveSelector,
  alternateOrganizationIsActiveSelectorInSecret,
} from 'client/reducers/user';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import {
  ActorHeaderName,
  ActivateAlternateOrganizationHeaderName,
  ImpersonateHeaderName,
} from 'shared/libraries/customHeaders/customHeaders';
import type { ReduxState } from 'client/reducers';
import { getIDToken } from 'client/libraries/cognito';

export const setLanguage = (language: string) => ({
  type: SET_LANGUAGE,
  language,
});
export const getAuthHeaders = (
  state: ReduxState,
  useAlternateOrganization?: boolean
) => {
  const jwtToken = getIDToken(state.user.cognito);

  if (!jwtToken) {
    throw new Error('could not get JWT token');
  }

  const headers: Record<string, string> = {};
  const impersonatedAccount = impersonatedAccountSelector(state);

  if (impersonatedAccount) {
    headers[ImpersonateHeaderName] = buildImpersonateToken(impersonatedAccount);
  }

  const actor = currentActorSelector(state);

  if (actor && actor.name) {
    headers[ActorHeaderName] = encodeURIComponent(actor.name);
  }

  if (
    useAlternateOrganization ||
    alternateOrganizationIsActiveSelector(state) ||
    alternateOrganizationIsActiveSelectorInSecret(state)
  ) {
    headers[ActivateAlternateOrganizationHeaderName] = 'true';
  }

  headers['authorization'] = `Bearer ${jwtToken}`;
  return headers;
};
export const getHTTPRequestHeaders = (
  state: ReduxState,
  options?: {
    language?: string;
    useAlternateOrganization?: boolean;
  }
) => {
  const { language, useAlternateOrganization } = options || {};
  const headers = getAuthHeaders(state, useAlternateOrganization);
  headers['Accept-Language'] = language || state.language.selected.iso;
  headers['content-type'] = 'application/json';
  return headers;
};
export const getHTTPRequestHeadersWithoutImpersonation = (
  state: ReduxState
) => {
  // eslint-disable-next-line no-unused-vars
  const { impersonate, ...headers } = getHTTPRequestHeaders(state);
  return headers;
};
export const URLWithQueryParams = (
  url: string,
  params: Record<string, string>
) => {
  const paramNames = Object.keys(params);

  if (paramNames.length === 0) {
    return url;
  }

  return `${url}?${paramNames
    .map((name) => `${name}=${encodeURIComponent(params[name])}`)
    .join('&')}`;
};
