import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button, DateRangeInput, MultiSelect } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { setNewslettersSearchFilters } from 'client/actions/newslettersSearch';

interface Props {
  onClose: () => void;
}

export const NewsletterSearchModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const savedFilters = useSelector(
    (state: ReduxState) => state.newslettersSearch.filters
  );
  const [filters, setFilters] = React.useState(savedFilters);
  const segments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const segmentOptions = segments.map((segment) => ({
    value: segment.id ?? '',
    text: segment.name ?? '',
  }));

  const onChangeSendFrom = (date: string) => {
    setFilters({ ...filters, sendDateFrom: date });
  };
  const onChangeSendTo = (date: string) => {
    setFilters({ ...filters, sendDateTo: date });
  };
  const onChangeSegments = (segments: string[]) => {
    setFilters({ ...filters, segments });
  };

  return (
    <Modal open={true} title={t('Search Newsletters')} onClose={onClose}>
      <Modal.Content>
        <DateRangeInput
          label={t('Send Date')}
          fromDate={filters.sendDateFrom}
          onChangeFromDate={onChangeSendFrom}
          toDate={filters.sendDateTo}
          onChangeToDate={onChangeSendTo}
        />
        <MultiSelect
          label={t('Segments')}
          options={segmentOptions}
          selectedValues={filters.segments}
          onChange={({ value }) => onChangeSegments(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="blue"
          size="middle"
          onClick={() => {
            dispatch(setNewslettersSearchFilters(filters));
            onClose();
          }}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
