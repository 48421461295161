import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { DurationInput } from 'client/components/NewProductEditor/DurationInput/DurationInput';

import styles from './TransportRouteEditor.module.css';

type Props = {
  name: string;
};
export const TransportRouteLocationEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <div className={styles['bold']}>{t('Time Offset:')}</div>
        <Box ml={2}>
          <Field name={`${name}.relativeTime`}>
            {({ input }) => (
              <DurationInput showSignSelect required {...input} />
            )}
          </Field>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <div className={styles['bold']}>{t('Location:')}</div>
        <Box mt={2} ml={2}>
          <Field name={`${name}.locationName`}>
            {({ input }) => (
              <LocationSearchInput
                location={input.value}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSearchChange={() => {}}
                onLocationSelect={({ title, key }) => {
                  input.onChange(title);
                  form.change(`${name}.googlePlaceId`, key);
                }}
                maxWidth={800}
              />
            )}
          </Field>
        </Box>
      </Box>
    </Box>
  );
};
