import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ResourceType } from 'client/libraries/util/resourceType';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
  ManifestProductGroup,
} from 'shared/models/swagger';
import { ManifestProductTable } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ManifestProductTable';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import { CollapsibleSection } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/CollapsibleSection';

import { ManifestProductTableForGroup } from './ManifestProductTableForGroup';

type Props = {
  excludedFormFieldKeys: string[];
  loading?: boolean;
  products: ProductSummary[];
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  startTime?: string;
  open?: boolean;
  customizedColumnNames?: ManifestCustomizedColumnName[];
  onEditResourceButtonClick?: (arg0: string) => void;
  onResourceTextClick?: (
    arg0: string,
    arg1: ResourceType,
    arg2: string
  ) => void;
  showDispatchColumn?: boolean;
  capacity?: number;
  splitStartTimes?: boolean;
  manifestDate?: string;
  rootResource?: {
    id: string;
    type: 'PRODUCT' | 'GROUP' | 'ALL_PRODUCTS';
  };
};

export const ManifestProductGroupTableList = ({
  excludedFormFieldKeys,
  products,
  reservations,
  visibleColumns,
  open,
  customizedColumnNames,
  onEditResourceButtonClick,
  onResourceTextClick,
  showDispatchColumn,
  capacity,
  splitStartTimes,
  manifestDate,
  rootResource,
}: Props) => {
  const { t } = useTranslation();
  const productGroups = useSelector(manifestProductGroupsSelector);
  const productGroupsForDisplay = productGroups.filter((productGroup) => {
    return productGroup.used_for_grouping;
  });
  const productsInGroup = productGroupsForDisplay.reduce(
    (productIds: string[], productGroup: ManifestProductGroup) => {
      if (productGroup.used_for_grouping) {
        return [...productIds, ...(productGroup?.product_ids || [])];
      }

      return productIds;
    },
    []
  );
  const [openGroup, setOpenGroup] = useState<boolean[]>(
    productGroupsForDisplay.map(() => true)
  );

  return (
    <>
      {reservations.length === 0 && (
        <div className={styles['p-manifestDaily__noData']}>
          <div>
            {t(
              'There is no reservation that matches the conditions. Please change participation date or Product/Group.'
            )}
          </div>
        </div>
      )}
      {reservations.length !== 0 && (
        <>
          {/* If grouping is specfied */}
          {productGroupsForDisplay
            .filter((productGroup) => {
              if (
                rootResource?.type === 'ALL_PRODUCTS' ||
                (rootResource?.type === 'GROUP' &&
                  rootResource?.id === productGroup.key &&
                  productGroup.used_for_grouping)
              ) {
                return true;
              }

              return false;
            })
            .map((productGroup, idx) => {
              const filteredReservations = reservations.filter((reservation) =>
                (productGroup.product_ids || []).includes(
                  reservation.product_id
                )
              );

              if (filteredReservations.length === 0) {
                return <></>;
              }

              return (
                <CollapsibleSection
                  key={idx}
                  title={productGroup?.key || ''}
                  open={openGroup[idx]}
                  onClick={() => {
                    const newOpenGroup = [...openGroup];
                    newOpenGroup[idx] = !openGroup[idx];
                    setOpenGroup(newOpenGroup);
                  }}
                >
                  <>
                    {(productGroup.product_ids || []).map((product_id, idx) => {
                      const product = products.find(
                        (product) => product.id === product_id
                      );

                      if (!product) {
                        return <></>;
                      }

                      if (
                        reservations.filter(
                          (reservation) => reservation.product_id === product_id
                        ).length === 0
                      ) {
                        return <></>;
                      }

                      return (
                        <ManifestProductTableForGroup
                          key={idx}
                          product={product}
                          excludedFormFieldKeys={excludedFormFieldKeys}
                          reservations={reservations.filter(
                            (reservation) =>
                              reservation.product_id === product.id
                          )}
                          visibleColumns={visibleColumns}
                          open={open}
                          customizedColumnNames={customizedColumnNames}
                          onEditResourceButtonClick={onEditResourceButtonClick}
                          onResourceTextClick={onResourceTextClick}
                          showDispatchColumn={showDispatchColumn}
                          capacity={capacity}
                          splitStartTimes={splitStartTimes}
                          manifestDate={manifestDate}
                        />
                      );
                    })}
                  </>
                </CollapsibleSection>
              );
            })}

          {/* If grouping is not specified */}
          {products
            .filter((product) => {
              if (
                rootResource?.type === 'ALL_PRODUCTS' &&
                !productsInGroup.includes(product.id)
              ) {
                return true;
              }

              if (
                rootResource?.type === 'PRODUCT' &&
                product.id === rootResource?.id
              ) {
                return true;
              }

              return false;
            })
            .filter((product) => {
              if (
                reservations.filter(
                  (reservation) => reservation.product_id === product.id
                ).length > 0
              ) {
                return true;
              }
              return false;
            })
            .map((product, idx) => {
              return (
                <div key={idx} className={styles['p-manifests__group']}>
                  <ManifestProductTable
                    product={product}
                    excludedFormFieldKeys={excludedFormFieldKeys}
                    reservations={reservations.filter(
                      (reservation) => reservation.product_id === product.id
                    )}
                    visibleColumns={visibleColumns}
                    open={open}
                    customizedColumnNames={customizedColumnNames}
                    onEditResourceButtonClick={onEditResourceButtonClick}
                    onResourceTextClick={onResourceTextClick}
                    showDispatchColumn={showDispatchColumn}
                    capacity={capacity}
                    splitStartTimes={splitStartTimes}
                    manifestDate={manifestDate}
                  />
                </div>
              );
            })}
          {
            //for FreeFormatReservation
            reservations
              .filter((reservation) => {
                if (rootResource?.type === 'ALL_PRODUCTS') {
                  if (reservation.is_free_format_reservation) {
                    return true;
                  }
                  return false;
                }

                return false;
              })
              .map((reservation, idx) => {
                return (
                  <div key={idx} className={styles['p-manifests__group']}>
                    <ManifestProductTable
                      key={idx}
                      excludedFormFieldKeys={excludedFormFieldKeys}
                      reservations={[reservation]}
                      visibleColumns={visibleColumns}
                      open={open}
                      customizedColumnNames={customizedColumnNames}
                      onEditResourceButtonClick={onEditResourceButtonClick}
                      onResourceTextClick={onResourceTextClick}
                      showDispatchColumn={showDispatchColumn}
                      capacity={capacity}
                      splitStartTimes={splitStartTimes}
                      manifestDate={manifestDate}
                    />
                  </div>
                );
              })
          }
        </>
      )}
    </>
  );
};
