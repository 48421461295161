import React from 'react';

import styles from './Hours.module.css';
import { OpenHourSchedulesEditor } from './OpenHourSchedulesEditor';

export const Hours: React.FC = () => {
  return (
    <section className={styles.hours}>
      <h2 className={styles.sectionTitle}>Hours</h2>
      <OpenHourSchedulesEditor name="openHourSchedules" />
    </section>
  );
};
