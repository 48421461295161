import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Modal } from 'client/components/v3/Form/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { fetchProducts } from 'client/actions/products';
import { allDispatchVehiclesSelector } from 'client/reducers/dispatchSettings';
import { updateDispatchVehicles } from 'client/actions/dispatchSettings';
import {
  FormValues,
  getDefaultDepartureTimeCapacity,
  validateRange,
  getScheduleText,
} from 'client/libraries/util/resourceManager';
import baseStyles from 'client/v3-base.module.css';
import {
  getInitialValues,
  convertFormValuesToDispatchResources,
} from 'client/pages/DispatchCustomize/VehicleList/VehicleAvailabilityModal/formValues';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { VehicleAvailabilityDepartureTimeForm } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/VehicleAvailabilityDepartureTimeForm';
import { Button } from 'client/components/v3/Common/Button';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  open: boolean;
  onClose?: () => void;
  resourceKey: string | null;
};

export const VehicleAvailabilityModal = ({
  open,
  onClose,
  resourceKey,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('Edit availability for {{resourceName}}', {
    resourceName: resourceKey,
  });

  const resources = useSelector(allDispatchVehiclesSelector);

  const resource = resources.find((r) => r.key === resourceKey);

  const [activeScheduleIndex, setActiveScheduleIndex] = useState<number>(0);
  const defaultStartDate = moment().format('YYYY-MM-DD');
  const defaultEndDate = moment().add(1, 'years').format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        const err = validateRange(values, t);
        if (err) {
          return err;
        }

        try {
          await dispatch(
            updateDispatchVehicles(
              convertFormValuesToDispatchResources(
                values,
                resourceKey,
                resources
              )
            )
          );
          onClose && onClose();
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={getInitialValues(resource ?? null)}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              title={title}
              open={open}
              onClose={() => {
                onClose && onClose();
              }}
              rightActionChildren={
                <>
                  <Button
                    color="white"
                    text={t('Discard')}
                    onClick={() => {
                      onClose && onClose();
                    }}
                  />
                  <Button
                    text={t('Save')}
                    loading={submitting}
                    color="primary"
                    type="submit"
                  />
                </>
              }
              style={{ width: '600px', height: '600px' }}
            >
              <div className={styles['p-manifestsModalCustomize']}>
                <FieldArray name="availabilitySchedules">
                  {({ fields }) => (
                    <>
                      <div
                        className={styles['p-manifestsModalCustomize__item']}
                      >
                        <p
                          className={
                            styles['p-manifestsModalCustomize__item__ttl']
                          }
                        >
                          {t('Resource Availability')}
                        </p>
                        <div
                          className={
                            styles['p-manifestsModalCustomize__item__body']
                          }
                        >
                          <div
                            className={
                              styles[
                                'p-manifestsModalCustomize__item__body__flex'
                              ]
                            }
                          >
                            <SingleDropdown
                              options={
                                fields.value.length > 0
                                  ? fields.value.map((priceRule, idx) => ({
                                      text: getScheduleText(priceRule, t),
                                      value: idx.toString(),
                                    }))
                                  : []
                              }
                              selectedOption={
                                fields.value.length > 0
                                  ? activeScheduleIndex.toString()
                                  : ''
                              }
                              onChange={(e) => {
                                setActiveScheduleIndex(parseInt(e));
                              }}
                            />
                            <Button
                              text={t('Add resource availability')}
                              color="primary"
                              onClick={() => {
                                fields.push({
                                  startDateLocal: defaultStartDate,
                                  endDateLocal: defaultEndDate,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles['p-frame']}>
                        <div className={styles['p-frame__header']}>
                          <p className={styles['p-frame__header__ttl']}>
                            {t('Selected Resource Availability')}
                          </p>
                          <div className={styles['p-frame__header__actions']}>
                            {fields.value.length > 1 && (
                              <Button
                                text={t('Remove this price schedule')}
                                size="sm"
                                color="tertiarygray"
                                onClick={() => {
                                  fields.remove(activeScheduleIndex);
                                  setActiveScheduleIndex(0);
                                }}
                                iconBeforeText={
                                  <i className="c-icon-outline-general-trash-03"></i>
                                }
                                style={{ color: 'var(--error600)' }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          key={activeScheduleIndex}
                          className={styles['p-frame__body']}
                        >
                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <p
                              className={
                                styles['p-manifestsModalCustomize__item__ttl']
                              }
                            >
                              {t('Date Range')}
                            </p>
                            <div
                              className={
                                styles['p-manifestsModalCustomize__item__body']
                              }
                            >
                              <div
                                className={
                                  styles[
                                    'p-manifestsModalCustomize__item__body__flex'
                                  ]
                                }
                              >
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.startDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                                <p style={{ marginBottom: 0 }}>-</p>
                                <Field
                                  name={`${fields.name}.${activeScheduleIndex}.endDateLocal`}
                                >
                                  {({ input }) => (
                                    <SimpleDateInput
                                      {...input}
                                      onChange={input.onChange}
                                      dateFrom={input.value}
                                      name={input.name}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              styles['p-manifestsModalCustomize__item']
                            }
                          >
                            <FieldArray
                              name={`availabilitySchedules.${activeScheduleIndex}.departureTimeCapacities`}
                            >
                              {({ fields }) => {
                                return (
                                  <table
                                    className={clsx(
                                      tableStyles['c-tableSmall']
                                    )}
                                  >
                                    <tbody>
                                      <>
                                        {fields.map((name, index) => (
                                          <tr key={index}>
                                            <td style={{ width: '80%' }}>
                                              <VehicleAvailabilityDepartureTimeForm
                                                name={name}
                                                key={index}
                                              />
                                            </td>
                                            <td>
                                              <div
                                                style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <Button
                                                  size="icon"
                                                  color="tertiarygray"
                                                  onClick={() =>
                                                    fields.remove(index)
                                                  }
                                                  iconBeforeText={
                                                    <i className="c-icon-outline-general-trash-03"></i>
                                                  }
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                        <tr>
                                          <td>
                                            <a
                                              className={styles['p-frame__add']}
                                              onClick={() => {
                                                if (
                                                  fields.length &&
                                                  fields.length > 0
                                                ) {
                                                  (fields as any).insertAt(
                                                    fields.length + 1,
                                                    getDefaultDepartureTimeCapacity()
                                                  );
                                                } else {
                                                  (fields as any).insertAt(
                                                    0,
                                                    getDefaultDepartureTimeCapacity()
                                                  );
                                                }
                                              }}
                                            >
                                              <i className="c-icon-outline-general-plus-circle"></i>
                                              {t('Add Start Time')}
                                            </a>
                                          </td>
                                          <td
                                            style={{ borderLeft: 'none' }}
                                          ></td>
                                        </tr>
                                      </>
                                    </tbody>
                                  </table>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>
                {submitError && (
                  <p className={baseStyles['u-error-msg']}>{submitError}</p>
                )}
              </div>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};
