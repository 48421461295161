/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';
import FeedbackHeader from './FeedbackHeader';
import SearchFilter from './SearchFilter';
import AverageRating from './AverageRating';
import RatingDistribution from './RatingDistribution';
import FeedbackCard from './FeedbackCard';

export const MobileOrderReviewList: React.FC = () => {
  const ratingDistributions = [90, 5, 3, 1, 1];

  const feedbackData = [
    {
      orderId: 'Order 836849',
      restaurant: 'restaurant A',
      dish: 'fish tacos',
      rating: 4.1,
      comment:
        'Horrible. The tortilla was soggy and the vegetables were not fresh at all. Really disappointed with the quality of the food. The overall experience was far below my expectations and I will not be returning.',
      author: 'John K',
      time: '8 hours ago',
    },
    {
      orderId: 'Order 836849',
      restaurant: 'restaurant A',
      dish: 'fish tacos',
      rating: 2.0,
      comment:
        'Horrible. The tortilla was soggy and the vegetables were not fresh at all. Really disappointed with the quality of the food. The overall experience was far below my expectations and I will not be returning.',
      author: 'John K',
      time: '8 hours ago',
      images: [
        'https://cdn.builder.io/api/v1/image/assets/TEMP/6bebdc22441049748f2dfe14ca5b1a1f16094b813339fa6d77a6e8ca9e838b5f?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818',
        'https://cdn.builder.io/api/v1/image/assets/TEMP/0617f1faf5a2e0768b338dd73d768943c135ab482b41e03c3db5c938061ddadf?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818',
      ],
    },
  ];

  return (
    <main className={styles.feedbackModule}>
      <FeedbackHeader
        title="feedback"
        description="add, edit or delete all your mobile order settings from here."
      />
      <SearchFilter />
      <section className={styles.feedbackContent}>
        <div className={styles.feedbackStats}>
          <AverageRating rating={4.2} count={3} />
          <RatingDistribution distributions={ratingDistributions} />
        </div>
        <div className={styles.feedbackList}>
          {feedbackData.map((feedback, index) => (
            <FeedbackCard key={index} {...feedback} />
          ))}
        </div>
      </section>
    </main>
  );
};
