// @flow

import * as React from 'react';
import { Button } from 'semantic-ui-react';
import 'client/components/ButtonLink/style.css';

type Props = {|
  className?: string,
  style?: Object,
  onClick?: (event: SyntheticEvent<HTMLButtonElement>, data: Object) => void,
  children?: React.Node,
  textAlign?: string,
  content?: string,
  fluid?: boolean,
|};

export const ButtonLink = ({ className = '', children, ...props }: Props) => (
  <Button
    basic
    color="blue"
    className={['link', className].join(' ')}
    {...props}
  >
    {children}
  </Button>
);
