import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  ENABLE_ACCOUNT_REQUEST,
  ENABLE_ACCOUNT_SUCCESS,
  ENABLE_ACCOUNT_FAILURE,
  DISABLE_ACCOUNT_REQUEST,
  DISABLE_ACCOUNT_SUCCESS,
  DISABLE_ACCOUNT_FAILURE,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers/index';
import type {
  Account,
  CreateAccountResponse,
  UpdateAccountResponse,
  ListAccountsResponse,
  EnableAccountResponse,
  DisableAccountResponse,
} from 'shared/models/swagger';
import {
  ActivateAlternateOrganizationHeaderName,
  ActorHeaderName,
  ImpersonateHeaderName,
} from 'shared/libraries/customHeaders/customHeaders';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';

import {
  alternateOrganizationIsActiveSelector,
  currentActorSelector,
  impersonatedAccountSelector,
} from './user';

export const httpRequestHeadersSelector = createSelector(
  (state: ReduxState) => state.language.selected.iso,
  (state: ReduxState) => state.user.cognito,
  impersonatedAccountSelector,
  currentActorSelector,
  alternateOrganizationIsActiveSelector,
  (locale, cognito, impersonatedAccount, actor, shouldUseAlternateOrg) => {
    const headers: Record<string, string> = {};
    headers['Accept-Language'] = locale;
    headers['content-type'] = 'application/json';

    if (impersonatedAccount) {
      headers[ImpersonateHeaderName] =
        buildImpersonateToken(impersonatedAccount);
    }
    if (actor && actor.name) {
      headers[ActorHeaderName] = encodeURIComponent(actor.name);
    }
    if (shouldUseAlternateOrg) {
      headers[ActivateAlternateOrganizationHeaderName] = 'true';
    }

    const jwtToken = getIDToken(cognito);
    headers['authorization'] = `Bearer ${jwtToken}`;

    return headers;
  }
);

export const accountsSelector = (state: ReduxState): Account[] =>
  state.accounts.all.filter((acc) => !!acc.organization_id);

const error = (state = '', action: Record<string, any>): string => {
  switch (action.type) {
    case CREATE_ACCOUNT_FAILURE:
    case UPDATE_ACCOUNT_FAILURE:
    case FETCH_ACCOUNTS_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
    case ENABLE_ACCOUNT_FAILURE:
    case DISABLE_ACCOUNT_FAILURE:
      return action.error;

    case CREATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
    case FETCH_ACCOUNTS_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
    case ENABLE_ACCOUNT_SUCCESS:
    case DISABLE_ACCOUNT_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Record<string, any>): boolean => {
  switch (action.type) {
    case CREATE_ACCOUNT_REQUEST:
    case UPDATE_ACCOUNT_REQUEST:
    case FETCH_ACCOUNTS_REQUEST:
    case DELETE_ACCOUNT_REQUEST:
    case ENABLE_ACCOUNT_REQUEST:
    case DISABLE_ACCOUNT_REQUEST:
      return true;

    case CREATE_ACCOUNT_FAILURE:
    case UPDATE_ACCOUNT_FAILURE:
    case FETCH_ACCOUNTS_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
    case ENABLE_ACCOUNT_FAILURE:
    case DISABLE_ACCOUNT_FAILURE:
    case CREATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
    case FETCH_ACCOUNTS_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
    case ENABLE_ACCOUNT_SUCCESS:
    case DISABLE_ACCOUNT_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: Account[] = [], action: Record<string, any>): Account[] => {
  switch (action.type) {
    case CREATE_ACCOUNT_SUCCESS: {
      const response = action.response as any as CreateAccountResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_ACCOUNT_SUCCESS: {
      const response = action.response as any as UpdateAccountResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case ENABLE_ACCOUNT_SUCCESS: {
      const response = action.response as any as EnableAccountResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DISABLE_ACCOUNT_SUCCESS: {
      const response = action.response as any as DisableAccountResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case FETCH_ACCOUNTS_SUCCESS: {
      const response = action.response as any as ListAccountsResponse;
      return response.accounts;
    }

    case DELETE_ACCOUNT_SUCCESS:
      return state.filter((i) => i.id !== action.id);

    default:
      return state;
  }
};

type State = {
  error: string;
  loading: boolean;
  all: Account[];
};

export const accounts = combineReducers<State>({
  error,
  loading,
  all,
});
