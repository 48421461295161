import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';

import styles from './Tab.module.css';

type Tabs = {
  id: string;
  title: string;
}[];

type Props = {
  children: JSX.Element | JSX.Element[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<any>>;
  tabs: Tabs;
};

export const Tab = ({ children, tabs, activeTab, setActiveTab }: Props) => {
  return (
    <div className={styles['c-pageTab']}>
      <div className={styles['c-pageTab__header']}>
        <ul className={styles['c-pageTab__header__tab']}>
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={clsx(activeTab === tab.id ? styles['is-active'] : '')}
              onClick={() => {
                setActiveTab(tab.id);
              }}
            >
              <a>{tab.title}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['c-pageTab__body']}>{children}</div>
    </div>
  );
};
