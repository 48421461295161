import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import queryString from 'query-string';
import clsx from 'clsx';

import { config } from 'client/config';
import { operationAllowed } from 'shared/models/access';
import { activeUserSelector } from 'client/reducers/user';
import {
  getInstanceDedicatedAllotments,
  productInstanceAllChannelsAreClosed,
  getInstanceError,
} from 'client/libraries/util/util';
import { fetchReservations } from 'client/actions/reservations';
import { getPromotionTypeText } from 'client/libraries/util/promotionTextHelpers';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import { ModalLoader } from 'client/components/ModalLoader';
import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers/index';
import { Modal } from 'client/components/Modal/Modal';
import type { ProductInstanceShape } from 'client/libraries/util/productInstanceShape';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Button, TextArea } from 'client/components/Form';
import type {
  ProductInstance,
  Reservation,
  AllotmentSettings,
} from 'shared/models/swagger';
import pageStyles from 'client/pages/pages.module.css';
import humanIcon from 'client/images/ic_human.svg';
import sbIcon from 'client/images/ic_sb.svg';
import rqIcon from 'client/images/ic_rq.svg';
import dcIcon from 'client/images/ic_dc.svg';
import { getAvailabilityIconType } from 'client/pages/Availability/util';

const getUncountedUnitsText = (
  allotmentSettings: AllotmentSettings | null,
  reservations: Reservation[]
): string => {
  const confirmedReservations = reservations.filter(
    (reservation) => reservation.status === 'CONFIRMED'
  );
  const uncountedUnitCounts: Record<string, number> = {};

  for (const reservation of confirmedReservations) {
    for (const guest of reservation.guests) {
      if (
        allotmentSettings?.inventory_consumption_rules?.find(
          (rule) => rule.unit === guest.guest_type_key
        )?.should_not_count_inventory
      ) {
        uncountedUnitCounts[guest.guest_type_title ?? ''] =
          (uncountedUnitCounts[guest.guest_type_title ?? ''] ?? 0) + 1;
      }
    }
  }

  const unitCounts = Object.entries(uncountedUnitCounts).map(
    ([unit, count]) => {
      const unitCount: any = count;
      return `${unit} x ${unitCount}`;
    }
  );

  if (unitCounts.length > 0) {
    return `(+ ${unitCounts.join(', ')})`;
  }

  return '';
};

type Props = {
  timezone: string;
  productInstance: ProductInstance;
  onEditClick?: (instance: ProductInstance) => void;
  onListClick?: (instance: ProductInstance) => void;
  productName: string;
  allotmentSettings: AllotmentSettings | null;
  instance: ProductInstanceShape;
  open: boolean;
  onClose: () => void;
  onSameProductInstanceIsSelected?: () => void;
  insertRoot?: boolean;
  reservation?: Reservation;
  isChangeReservation?: boolean;
  isFromNewReservationModal?: boolean;
  onSelectProductInstanceError?: (arg0: string) => void;
  customerId?: string;
  shouldRejectBookingsBeyondCapacity?: boolean;
};
export const ProductInstanceModal = ({
  timezone,
  productInstance,
  onEditClick,
  onListClick,
  productName,
  allotmentSettings,
  instance,
  open,
  onClose,
  onSameProductInstanceIsSelected,
  insertRoot,
  reservation,
  isChangeReservation,
  isFromNewReservationModal,
  onSelectProductInstanceError,
  customerId,
  shouldRejectBookingsBeyondCapacity,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const reservationsLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const startMoment = moment
    .tz(productInstance.start_date_time_utc, timezone)
    .locale(locale);
  const start =
    startMoment.format('lll') +
    ' ' +
    (productInstance.start_time_description ?? '');
  const instantDeadline = (productInstance.booking_deadlines || []).find(
    (d) => d.confirmation_type === 'INSTANT'
  );
  const instantDeadlineMoment =
    instantDeadline && moment.tz(instantDeadline.date_time_utc, timezone);
  const instantDeadlinePassed =
    instantDeadline && moment().isAfter(instantDeadlineMoment);
  const formattedInstantBookingDeadline =
    instantDeadlineMoment && instantDeadlineMoment.locale(locale).format('lll');
  const dedicatedAllotments = getInstanceDedicatedAllotments(productInstance);

  const allChannelsClosed =
    productInstanceAllChannelsAreClosed(productInstance);
  const instanceIsClosed = productInstance.is_closed || allChannelsClosed;
  const instanceError = getInstanceError(productInstance, t);

  const userIsPassthroughSupplier =
    activeUser?.organization_type === 'SUPPLIER' &&
    productInstance.shared_allotment_references &&
    productInstance.shared_allotment_references
      .passthrough_base_product_instance_id;
  const componentInstances =
    productInstance.package_component_product_instances || [];
  // Load reservations
  React.useEffect(() => {
    dispatch(
      fetchReservations({
        filter: queryString.stringify({
          product_instance_id: productInstance.id,
        }),
        start_date_local_from: startMoment.format('YYYY-MM-DD'),
        start_date_local_to: startMoment.add(1, 'days').format('YYYY-MM-DD'),
      })
    );
  }, [productInstance.id]);

  const onMoveRebookForm = async () => {
    try {
      const now = moment().tz(reservation?.start_timezone ?? '');
      const changeFromStartTime = moment.tz(
        reservation?.start_date_time_utc,
        reservation?.start_timezone ?? ''
      );
      const changeToStartTime = moment.tz(
        productInstance?.start_date_time_utc,
        reservation?.start_timezone ?? ''
      );

      if (
        !isFromNewReservationModal &&
        changeFromStartTime.isBefore(now) &&
        !changeToStartTime.isSame(changeFromStartTime, 'month')
      ) {
        if (onSelectProductInstanceError) {
          onSelectProductInstanceError(
            t(
              'If the participation date is already passed, it cannot be changed to next month or later. We are sorry for the inconveniences but please cancel and rebook with a new date.'
            )
          );
        }

        return;
      }

      if (
        !isFromNewReservationModal &&
        moment
          .tz(
            changeToStartTime.format('YYYY-MM-DD'),
            reservation?.start_timezone ?? 'UTC'
          )
          .isBefore(
            moment.tz(
              now.format('YYYY-MM-DD'),
              reservation?.start_timezone ?? 'UTC'
            )
          )
      ) {
        if (onSelectProductInstanceError) {
          onSelectProductInstanceError(
            t('Cannot change the reservation to past date.')
          );
        }

        return;
      }

      if (
        reservation &&
        typeof onSameProductInstanceIsSelected !== 'undefined' &&
        productInstance.product_id === reservation.product_id &&
        productInstance.id === reservation.product_instance_id
      ) {
        onSameProductInstanceIsSelected();
        return;
      }

      onSelectProductInstanceError?.('');
      history.push({
        pathname: `/products/${productInstance.product_id}/instances/${productInstance.id}/book`,
        state: {
          reservation,
          // Always set isChangeReservation = false, if this trigger point is from ReservationCreateModal.
          isChangeReservation: isFromNewReservationModal
            ? false
            : isChangeReservation,
          isFromNewReservationModal: isFromNewReservationModal,
          customerId,
        },
      });
    } catch (err) {
      if (onSelectProductInstanceError) {
        onSelectProductInstanceError(t('System error'));
      }

      return;
    }
  };

  const requestedReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'REQUESTED'
  ).length;
  const standbyReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'STANDBY'
  ).length;
  const declinedReservationsCount = reservations.filter(
    (reservation) => reservation.status === 'DECLINED_BY_SUPPLIER'
  ).length;
  const uncountedUnitsText = !reservationsLoading
    ? getUncountedUnitsText(allotmentSettings, reservations)
    : '';

  const getSearchString = () => {
    const params: string[] = [];
    if (isFromNewReservationModal) {
      params.push('new_reservation_modal=1');
    }
    if (customerId) {
      params.push(`customer_id=${customerId}`);
    }

    if (params.length === 0) {
      return '';
    }
    return '?' + params.join('&');
  };

  return (
    <Modal
      title={productName}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      onClose={onClose}
      open={open}
      width="narrow"
      insertRoot={insertRoot}
    >
      <div>
        <div className={clsx(pageStyles['page-availability__modal'])}>
          <table
            className={clsx(pageStyles['page-availability__modal__table'])}
          >
            <tbody>
              {(productInstance.promotions || []).length > 0 && (
                <tr>
                  <th> {t('Discounts')} </th>
                  <td>
                    <span className={clsx(pageStyles['red'])}>
                      {[
                        ...new Set(
                          (productInstance.promotions || []).map(
                            (promo) => promo.type
                          )
                        ),
                      ]
                        .map((promoType) =>
                          promoType ? getPromotionTypeText(promoType, t) : ''
                        )
                        .join(', ')}
                    </span>
                  </td>
                </tr>
              )}
              {formattedInstantBookingDeadline && (
                <>
                  <tr>
                    <th> {t('Date & Start time')} </th>
                    <td>
                      <span>{start}</span>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <span>{t('Instant confirmation deadline:')}</span>
          {instantDeadlinePassed ? (
            <span style={{ color: '#dc3e15' }}>
              {t('Instant booking deadline passed')}
            </span>
          ) : (
            <span>{formattedInstantBookingDeadline}</span>
          )}
          {process.env.NODE_ENV === 'development' && (
            <div className={clsx(pageStyles['page-availability__modal__ttl'])}>
              {productInstance.id}
            </div>
          )}
          <div className={clsx(pageStyles['page-availability__modal__num'])}>
            <div
              className={clsx(
                pageStyles['page-availability__modal__num__header']
              )}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <div
                  className={clsx(
                    pageStyles['page-availability__modal__num__header__img']
                  )}
                >
                  <p
                    style={{
                      marginRight: '4px',
                    }}
                  >
                    <img src={humanIcon} />
                  </p>
                  {operationAllowed(
                    activeUser,
                    'write',
                    'reservationConfirmation'
                  ) ? (
                    <>
                      {`${
                        instance.bookedSlots
                      } / ${formattedTotalAllotmentSlots(instance.totalSlots)}`}
                      {uncountedUnitsText && (
                        <div
                          className={
                            pageStyles[
                              'page-availability__modal__num__header__uncounted__units'
                            ]
                          }
                        >
                          {uncountedUnitsText}
                        </div>
                      )}
                    </>
                  ) : getAvailabilityIconType(
                      instance.bookedSlots,
                      instance.totalSlots,
                      instance.allChannelsClosed,
                      shouldRejectBookingsBeyondCapacity,
                      productInstance.booking_deadlines
                    ) === 'REQUEST' ? (
                    <img src={rqIcon} />
                  ) : (
                    t('{{count}} spot', {
                      count: formattedTotalAllotmentSlots(
                        productInstance.available_slots || 0
                      ),
                    })
                  )}
                </div>
                {instance.bookedSlots === instance.totalSlots &&
                  instance.totalSlots !== 0 && (
                    <div
                      className={clsx(
                        pageStyles[
                          'page-availability__modal__num__header__label'
                        ]
                      )}
                    >
                      <span className={clsx(pageStyles['red'])}>
                        {t('Sold out')}
                      </span>
                    </div>
                  )}
              </Box>
              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) &&
                (reservationsLoading ? (
                  <ModalLoader />
                ) : (
                  requestedReservationsCount +
                    standbyReservationsCount +
                    declinedReservationsCount >
                    0 && (
                    <div
                      className={
                        pageStyles['page-availability__modal__non__confirmed']
                      }
                    >
                      {t('Non-confirmed reservations: ')}
                      {requestedReservationsCount > 0 && (
                        <>
                          <img height={20} src={rqIcon} />
                          {requestedReservationsCount}
                        </>
                      )}
                      {standbyReservationsCount > 0 && (
                        <>
                          <img src={sbIcon} />
                          {standbyReservationsCount}
                        </>
                      )}
                      {declinedReservationsCount > 0 && (
                        <>
                          <img src={dcIcon} />
                          {declinedReservationsCount}
                        </>
                      )}
                    </div>
                  )
                ))}
            </div>
            {operationAllowed(
              activeUser,
              'write',
              'reservationConfirmation'
            ) && (
              <ul
                className={clsx(
                  pageStyles['page-availability__modal__num__body']
                )}
              >
                <li key="COMMON">
                  <p>{t('COMMON')}</p>
                  <p>{`${
                    productInstance.occupied_slots || 0
                  }/${formattedTotalAllotmentSlots(
                    productInstance.total_slots || 0
                  )}`}</p>
                </li>
                {dedicatedAllotments.map((a) => {
                  if (a.agent_id) {
                    return (
                      <li key={a.agent_id}>
                        <p>{a.agent_name || ''}</p>
                        <p>{`${a.occupied_slots || 0}/${
                          a.total_slots || 0
                        }`}</p>
                      </li>
                    );
                  }

                  return (
                    <li key={a.channel_category}>
                      <p>{t(a.channel_category as any)}</p>
                      <p>{`${a.occupied_slots || 0}/${a.total_slots || 0}`}</p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <TextArea
          label={t('Memo')}
          value={productInstance?.memo?.value ?? ''}
          height={100}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          disabled={true}
        />

        <Modal.Actions vertical={true}>
          {!isChangeReservation &&
            onEditClick &&
            !userIsPassthroughSupplier &&
            hasCustomUserRoleWritePermissions(activeUser, 'AVAILABILITY') &&
            componentInstances.length === 0 && (
              <Button
                style="yellow"
                size="middle"
                onClick={() => {
                  onClose();
                  onEditClick && onEditClick(productInstance);
                }}
              >
                {t('Edit allotment')}
              </Button>
            )}

          {((instanceIsClosed &&
            operationAllowed(activeUser, 'write', 'productInstances')) ||
            !instanceIsClosed) &&
          !instanceError &&
          hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') ? (
            <>
              {reservation ? (
                <>
                  <Button
                    style="green"
                    size="middle"
                    onClick={onMoveRebookForm}
                  >
                    {isChangeReservation
                      ? t('Change to this date & time')
                      : t('Create new reservation')}
                  </Button>
                </>
              ) : (
                (config.enableCreateReservationButtonForPassthroughSupplier ||
                  !userIsPassthroughSupplier) && (
                  <Link
                    to={{
                      pathname: `/products/${productInstance.product_id}/instances/${productInstance.id}/book`,
                      search: getSearchString(),
                    }}
                    target={'_blank'}
                  >
                    <Button style="green" size="middle">
                      {t('Create new reservation')}
                    </Button>
                  </Link>
                )
              )}
            </>
          ) : null}

          {onListClick && operationAllowed(activeUser, 'read', 'manifests') && (
            <Button
              style="blue"
              size="middle"
              onClick={() => {
                onClose();
                onListClick(productInstance);
              }}
            >
              {t('See reservations')}
            </Button>
          )}
        </Modal.Actions>
      </div>
    </Modal>
  );
};
