import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';
import { useState } from 'react';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from './TargetListEditor.module.css';
import { EditTargetModal } from './EditTargetModal';
import { constructUrl } from './util';
import { FormValues } from './formValues';

type TargetListEditorProps = {
  title?: string;
  tooltipText?: string;
  name: string;
  style?: Record<string, any>;
  fieldDisabled?: boolean;
  buttonDisabled?: boolean;
  addButtonText?: string;
  validator?: FieldValidator<any> | undefined;
};

export const TargetListEditor = ({
  title,
  tooltipText,
  name,
  style,
  fieldDisabled,
  buttonDisabled,
  addButtonText,
  validator,
}: TargetListEditorProps) => {
  const combinedStyle = {
    maxWidth: 800,
    ...style,
  };
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState('');
  const formState = useFormState<FormValues>();
  const values = formState.values;

  return (
    <div className={styles['c-listEditor']}>
      {title && (
        <div className={styles['c-listEditor__ttl']}>
          <p>{title}</p>
          {tooltipText && (
            <Tooltip text={tooltipText}>
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          )}
        </div>
      )}
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={styles['c-listEditor__body']}>
            <div className={styles['c-listEditor__body__list']}>
              {fields.length !== 0 &&
                fields.map((fieldName, idx) => (
                  <div
                    key={fieldName}
                    className={styles['c-listEditor__body__list__item']}
                  >
                    <Field name={fieldName} validate={validator}>
                      {({ input }) => {
                        return (
                          <>
                            <div style={{ width: '100%' }}>
                              <TextField
                                style={combinedStyle}
                                disabled={fieldDisabled}
                                value={constructUrl(
                                  values.baseUrl,
                                  input.value
                                )}
                                onChange={() => {
                                  // Empty since we edit using edit button anyways
                                }}
                              />
                            </div>
                            {!buttonDisabled && (
                              <div
                                className={
                                  styles['c-listEditor__body__actions']
                                }
                              >
                                <Button
                                  size="icon"
                                  color="tertiarygray"
                                  onClick={() => {
                                    setSelectedTarget(fieldName);
                                    setShowModal(true);
                                  }}
                                  iconBeforeText={
                                    <i className="c-icon-outline-general-edit-05"></i>
                                  }
                                />
                                <Button
                                  size="icon"
                                  color="tertiarygray"
                                  onClick={() => fields.remove(idx)}
                                  iconBeforeText={
                                    <i className="c-icon-outline-general-trash-03"></i>
                                  }
                                />
                              </div>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </div>
                ))}
            </div>
            {!buttonDisabled && (
              <div
                className={clsx(
                  fields.length && fields.length > 0 && baseStyles['u-mt-2']
                )}
              >
                <a
                  className={styles['c-listEditor__body__add']}
                  onClick={async () => {
                    // Init empty path for target upon addition
                    let index = 0;
                    if (fields.length && fields.length > 0) {
                      index = fields.length;
                    }
                    (fields as any).insertAt(index, { path: '' });

                    // Also open modal upon adding
                    await setSelectedTarget(`${name}[${index}]`);
                    await setShowModal(true);
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {addButtonText ?? t('Add')}
                </a>
              </div>
            )}
          </div>
        )}
      </FieldArray>
      {showModal && (
        <EditTargetModal
          name={selectedTarget}
          onClose={async () => {
            await setShowModal(false);
            await setSelectedTarget('');
          }}
        />
      )}
    </div>
  );
};
