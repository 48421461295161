import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import {
  printPriceTitle,
  ServiceShape,
} from 'client/libraries/util/productShape';

import styles from './ServiceList.module.css';

interface Props {
  services: ServiceShape[];
}
export const ServiceList = ({ services }: Props) => {
  const { t } = useTranslation();
  return (
    <ul className={styles['c-schedule2']}>
      {services.map((service) => {
        const showDescription = !!service.description;
        return (
          <li key={service.name} className={styles['c-schedule2__list']}>
            <p className={styles['c-schedule2__list__item__title']}>
              {service.name}
            </p>
            <div className={styles['c-schedule2__list__item__body']}>
              {showDescription && (
                <div className={styles['c-schedule2__list__item__body__text']}>
                  <div
                    className={
                      styles['c-schedule2__list__item__body__text__th']
                    }
                  >
                    {t('[Description]')}
                  </div>
                  <div
                    className={clsx(
                      styles['c-schedule2__list__item__body__text__td'],
                      'newline'
                    )}
                  >
                    {service.description}
                  </div>
                </div>
              )}
              <div className={styles['c-schedule2__list__item__body__price']}>
                <div
                  className={styles['c-schedule2__list__item__body__price__th']}
                >
                  {t('[Price]')}
                </div>
                {service.type === 'FREE' ? (
                  <div
                    className={
                      styles['c-schedule2__list__item__body__price__td']
                    }
                  >
                    {t('Free')}
                  </div>
                ) : (
                  <div
                    className={
                      styles['c-schedule2__list__item__body__price__td']
                    }
                  >
                    {service.pricesSummary.map((price) => (
                      <p key={price.unitTitle}>
                        <span>{printPriceTitle(price, t)}</span>
                        {formattedCurrencyAmount(price.amount)}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
