import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  name: string;
  description: string;
  latitude: number;
  longitude: number;
}

export interface ETicketPageItem {
  key: string;
  title: string;
  description: string;
  body: any;
}

export const getInitialValues = (
  eTicketLocation?: Swagger.ETicketLocation | null
): FormValues => {
  return {
    name: eTicketLocation?.location_name ?? '',
    description: eTicketLocation?.location_description ?? '',
    latitude: eTicketLocation?.latitude ?? 0,
    longitude: eTicketLocation?.longitude ?? 0,
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues,
  eTicketLocationSetId: string
): Swagger.ETicketLocationParams => {
  return {
    location_name: values.name,
    location_description: values.description,
    latitude: values?.latitude ?? 0,
    longitude: values?.longitude ?? 0,
    e_ticket_location_set_id: eTicketLocationSetId,
  };
};
