import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';
import baseStyles from 'client/base.module.css';

import styles from './ReservationReport.module.css';

type Props = {
  data: GraphDataItem[];
  currencyCode: string | null;
};
export const TotalTablePane = ({ data, currencyCode }: Props) => {
  const { t } = useTranslation();
  let totalAmountGross = 0;
  let totalAmountNet = 0;
  let totalNumOfReservations = 0;
  let totalPax = 0;
  data.forEach((dataItem) => {
    totalAmountGross += dataItem.amountGross as number;
    totalAmountNet += dataItem.amountNet as number;
    totalNumOfReservations += dataItem.numOfReservations as number;
    totalPax += dataItem.pax as number;
  });
  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Gross')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Net')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Number of Reservations')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Pax')}
            </th>
          </tr>
          <tr>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(totalAmountGross, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(totalAmountNet, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {totalNumOfReservations}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {totalPax}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
