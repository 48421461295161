import { getGuestTypeKeysUsedInProduct } from 'client/libraries/util/util';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import {
  getProductSalesStatus,
  convertSalesStatusToModelValue,
} from 'client/libraries/util/getProductSalesStatus';
import type {
  Organization,
  Product,
  Product$Patch,
  SourceLanguage,
} from 'shared/models/swagger';

export type MinimumMaximumParticipantRule = {
  unit: string;
  minimumParticipants: number;
  maximumParticipants: number;
};
export type SelectableParticipantRule = {
  requiredUnits: string[];
  restrictedUnits: string[];
  maximumParticipants: number;
};
export type LanguageVisibility = {
  language: SourceLanguage;
  isVisible: boolean;
};
export type PerBookingParticipantRule = {
  minimumParticipants?: number;
  maximumParticipants?: number | string;
};
type CalendarUnitOrder = {
  unit: string;
  default_display_in_calendar?: boolean;
};
export type FormValues = {
  isVisible: boolean;
  salesStatus: SalesStatus;
  isHiddenFromProductListAndSearch: boolean;
  languages: LanguageVisibility[];
  minimumMaximumParticipantRules: MinimumMaximumParticipantRule[];
  isSelectableParticipantRuleActive: boolean;
  selectableParticipantRules: SelectableParticipantRule[];
  isProductPaymentTypeEnabled: boolean;
  isLocalPaymentAvailable: boolean;
  isOnlinePaymentAvailable: boolean;
  isFreeStartDateTime: boolean;
  isPerBookingParticipantRuleActive: boolean;
  perBookingParticipantRule: PerBookingParticipantRule;
  calendarUnitOrder?: CalendarUnitOrder[];
};

const isAdultUnit = (unit: string): boolean => {
  return unit === 'Adult' || unit === '大人';
};

const getInitialMinimumMaximumParticipantRules = (
  product: Product | null
): MinimumMaximumParticipantRule[] => {
  if (!product) {
    return [];
  }

  const units = getGuestTypeKeysUsedInProduct(product);
  return units.map((unit) => {
    const maxPaxRule =
      product?.booking_widget_settings?.maximum_participant_rules?.find(
        (rule) => rule.unit === unit
      );
    const minPaxRule =
      product?.booking_widget_settings?.minimum_participant_rules?.find(
        (rule) => rule.unit === unit
      );
    const maximumParticipants = maxPaxRule
      ? maxPaxRule.maximum_participants ?? 11
      : 11;
    const minimumParticipants = minPaxRule
      ? minPaxRule.minimum_participants ?? 0
      : isAdultUnit(unit)
      ? 1
      : 0;
    return {
      unit,
      maximumParticipants,
      minimumParticipants,
    };
  });
};

const getInitialSelectableParticipantRules = (
  product: Product | null
): SelectableParticipantRule[] => {
  if (!product) {
    return [];
  }

  const minimumMaximumParticipantRules =
    getInitialMinimumMaximumParticipantRules(product);
  const defaultUnit =
    minimumMaximumParticipantRules.length > 0
      ? minimumMaximumParticipantRules[0].unit
      : '';
  let selectableParticipantRules = (
    product?.booking_widget_settings?.selectable_participant_rules || []
  ).map((rule) => ({
    requiredUnits: rule.required_units || [],
    restrictedUnits: rule.restricted_units || [],
    maximumParticipants: rule.maximum_participants || 1,
  }));

  if (selectableParticipantRules.length === 0) {
    selectableParticipantRules = [
      {
        requiredUnits: [defaultUnit],
        restrictedUnits: [defaultUnit],
        maximumParticipants: 1,
      },
    ];
  }

  return selectableParticipantRules;
};

const getInitialPerBookingParticipantRule = (
  product: Product | null
): PerBookingParticipantRule => {
  if (!product) {
    return {};
  }

  const minimumParticipants =
    product?.booking_widget_settings?.per_booking_participant_rule
      ?.minimum_participants ?? 1;
  const maximumParticipants =
    product?.booking_widget_settings?.per_booking_participant_rule
      ?.maximum_participants ?? 0;

  return {
    minimumParticipants,
    maximumParticipants,
  };
};

export const getInitialValues = (
  product: Product | null,
  organization: Organization | null
): FormValues => {
  const languages = getBookingWidgetPmpSupportLanguages(organization);
  const minimumMaximumParticipantRules =
    getInitialMinimumMaximumParticipantRules(product);
  const selectableParticipantRules =
    getInitialSelectableParticipantRules(product);
  const isProductPaymentTypeEnabled =
    product?.booking_widget_settings?.guest_payment_settings
      ?.is_product_payment_type_enabled ?? false;
  let isOnlinePaymentAvailable = (
    organization?.supplier_guest_payment_settings
      ?.accepted_guest_payment_types || []
  ).includes('PAID_IN_FULL');
  let isLocalPaymentAvailable = (
    organization?.supplier_guest_payment_settings
      ?.accepted_guest_payment_types || []
  ).includes('PAY_ON_BOARD');

  if (isProductPaymentTypeEnabled) {
    isOnlinePaymentAvailable = (
      product?.booking_widget_settings?.guest_payment_settings
        ?.accepted_guest_payment_types || []
    ).includes('PAID_IN_FULL');
    isLocalPaymentAvailable = (
      product?.booking_widget_settings?.guest_payment_settings
        ?.accepted_guest_payment_types || []
    ).includes('PAY_ON_BOARD');
  }

  const isFreeStartDateTime =
    product?.booking_widget_settings?.is_free_start_date_time ?? false;
  const is_visible = product?.booking_widget_settings?.is_visible ?? false;

  const isPerBookingParticipantRuleActive =
    product?.booking_widget_settings?.is_per_booking_participant_rule_active ??
    false;
  const perBookingParticipantRule =
    getInitialPerBookingParticipantRule(product);

  return {
    isVisible: is_visible,
    isHiddenFromProductListAndSearch:
      product?.booking_widget_settings
        ?.is_hidden_from_product_list_and_search ?? false,
    salesStatus: getProductSalesStatus(product as Product),
    languages: languages.map((lang) => ({
      language: lang,
      isVisible:
        !product?.booking_widget_settings?.blacklisted_languages?.includes(
          lang
        ),
    })),
    minimumMaximumParticipantRules,
    isSelectableParticipantRuleActive:
      product?.booking_widget_settings?.is_selectable_participant_rule_active ??
      false,
    selectableParticipantRules,
    isProductPaymentTypeEnabled,
    isOnlinePaymentAvailable,
    isLocalPaymentAvailable,
    isFreeStartDateTime,
    isPerBookingParticipantRuleActive,
    perBookingParticipantRule,
  };
};
export const convertFormValuesToProductPatch = (
  values: FormValues
): Product$Patch => {
  const { is_visible, is_view_only } = convertSalesStatusToModelValue(
    values.salesStatus
  );
  return {
    booking_widget_settings: {
      is_visible,
      is_view_only: is_view_only,
      is_hidden_from_product_list_and_search:
        values.isHiddenFromProductListAndSearch,
      blacklisted_languages: values.languages
        .filter((lang) => !lang.isVisible)
        .map((lang) => lang.language),
      maximum_participant_rules: values.minimumMaximumParticipantRules.map(
        (rule) => ({
          unit: rule.unit,
          maximum_participants: rule.maximumParticipants,
        })
      ),
      minimum_participant_rules: values.minimumMaximumParticipantRules.map(
        (rule) => ({
          unit: rule.unit,
          minimum_participants: rule.minimumParticipants,
        })
      ),
      is_selectable_participant_rule_active:
        values.isSelectableParticipantRuleActive,
      selectable_participant_rules: values.selectableParticipantRules.map(
        (rule) => ({
          required_units: rule.requiredUnits,
          restricted_units: rule.restrictedUnits,
          maximum_participants: rule.maximumParticipants,
        })
      ),
      guest_payment_settings: {
        is_product_payment_type_enabled: values.isProductPaymentTypeEnabled,
        accepted_guest_payment_types: [
          ...(values.isLocalPaymentAvailable ? ['PAY_ON_BOARD'] : []),
          ...(values.isOnlinePaymentAvailable ? ['PAID_IN_FULL'] : []),
        ] as any,
      },
      is_free_start_date_time: values.isFreeStartDateTime,
      is_per_booking_participant_rule_active:
        values.isPerBookingParticipantRuleActive,
      per_booking_participant_rule: values.isPerBookingParticipantRuleActive
        ? {
            minimum_participants:
              values.perBookingParticipantRule.minimumParticipants,
            maximum_participants:
              typeof values.perBookingParticipantRule.maximumParticipants ===
              'string'
                ? 0
                : values.perBookingParticipantRule.maximumParticipants,
          }
        : {},
      calendar_unit_order: (values?.calendarUnitOrder ?? []).map((o) => ({
        unit: o.unit,
        default_display_in_calendar: o.default_display_in_calendar,
      })),
    },
  };
};
