// @flow

import * as React from 'react';
import { Grid, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { TranslatorProps } from 'react-i18next';

import { cancelReservation } from 'client/actions/reservations';
import { deleteTask } from 'client/actions/tasks';
import { actionItemDescription } from 'client/pages/Dash/util';
import { ActionButtons } from 'client/pages/Dash/ActionButtons';
import { TaskDetailsModal } from 'client/pages/Dash/TaskDetailsModal';
import type { TaskShape } from 'client/pages/Dash/util';
import type { ReduxState } from 'client/reducers/index';

type OwnProps = {
  width: number,
  task: TaskShape,
  color: string,
  onTaskChange: (taskID: string) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  modalID: string,
};

class ActionItemCellComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalID: '',
    };
  }

  render() {
    const { color, onTaskChange, task, t, width } = this.props;

    const narrow = width < 200;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={narrow ? 16 : 5}>
            <Label color={color} style={{ whiteSpace: 'normal' }}>
              {actionItemDescription(task, t)}
            </Label>
          </Grid.Column>
          {!narrow && (
            <Grid.Column width={11} textAlign="right">
              <ActionButtons
                stack={width < 375}
                task={task}
                onTaskChange={() => onTaskChange(task.id)}
              />
              <span style={{ paddingLeft: '5px' }}>
                <TaskDetailsModal
                  taskColor={color}
                  task={task}
                  onTaskChange={() => onTaskChange(task.id)}
                />
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  locale: state.language.selected.iso,
  monthYearFormat: state.language.selected.monthYearFormat,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  cancelReservation: (id: string) => dispatch(cancelReservation(id)),
  onDismissTask: (id: string) => dispatch(deleteTask(id)),
});

export const ActionItemCell = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ActionItemCellComponent);
