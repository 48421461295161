import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateInquiry } from 'client/actions/inquiries';
import { Inquiry } from 'shared/models/swagger';

interface FormValues {
  tags: string[];
}

interface Props {
  inquiry: Inquiry;
  onClose: () => void;
}

export const EditTagsModal = ({ inquiry, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      tags: inquiry.tags ?? [],
    }),
    [inquiry]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateInquiry(inquiry.id ?? '', {
            // If setting tags, pass tags
            // If clearing tags, pass sentinel value
            tags: values.tags.length > 0 ? values.tags : [''],
          })
        );
        onClose();
      }}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            useCloseButton={true}
            title={t('Edit Tags')}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Discard')}
                  color="white"
                  onClick={() => form.reset()}
                />
                <Button text={t('Save')} type="submit" loading={submitting} />
              </>
            }
          >
            <ListEditor name="tags" addButtonText={t('Add Tag')} />
          </Modal>
        </form>
      )}
    </Form>
  );
};
