import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';

import { Button, Input, ToggleButton } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import { setProductAgentUnitMappings } from 'client/actions/products';
import { Product, AgentUnitMapping } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';

interface UnitMapping {
  guestTypeKey: string;
  agentReference: string;
  shouldUseCustomMapping: boolean;
}

interface FormValues {
  unitMappings: UnitMapping[];
}

const getInitialValues = (product: Product): FormValues => {
  const units = product?.raw_guest_type_keys ?? [];

  return {
    unitMappings: units.map((unit) => {
      const mapping = product.unit_mappings?.find(
        (m) => m.guest_type_key === unit
      );
      if (mapping?.agent_reference) {
        return {
          guestTypeKey: unit,
          agentReference: mapping?.agent_reference,
          shouldUseCustomMapping: true,
        };
      }

      return {
        guestTypeKey: unit,
        agentReference: '',
        shouldUseCustomMapping: false,
      };
    }),
  };
};

const convertFormValuesToSwagger = (values: FormValues): AgentUnitMapping[] => {
  const unitMappings: AgentUnitMapping[] = [];

  values.unitMappings?.forEach((mapping) => {
    if (mapping.shouldUseCustomMapping && mapping.agentReference) {
      unitMappings.push({
        guest_type_key: mapping.guestTypeKey,
        agent_reference: mapping.agentReference,
      });
    }
  });

  return unitMappings;
};

interface Props {
  onClose: () => void;
  product: Product;
}

export const EditProductAgentUnitMappingsModal = ({
  onClose,
  product,
}: Props) => {
  const { t } = useTranslation();

  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );

  const dispatch = useDispatch();

  return (
    <Modal open={true} onClose={onClose} title={t('Edit Unit Mappings')}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            setProductAgentUnitMappings(
              product.id,
              convertFormValuesToSwagger(values)
            )
          );
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <div className={styles['c-tableChild']}>
                <ul>
                  <li className={baseStyles['base-t-240']}>{t('Unit')}</li>
                  <li className={baseStyles['base-t-160']}>
                    {t('Use Custom Mapping')}
                  </li>
                  <li className={baseStyles['base-t-160']}>{t('Mapping')}</li>
                </ul>
                <FieldArray name="unitMappings">
                  {({ fields }) =>
                    fields.map((name, idx) => (
                      <ul key={idx}>
                        <li data-title={t('Unit')}>
                          {fields.value?.[idx]?.guestTypeKey}
                        </li>
                        <li data-title={t('Use Custom Mapping')}>
                          <Field name={`${name}.shouldUseCustomMapping`}>
                            {({ input }) => (
                              <ToggleButton
                                label=""
                                checked={input.value}
                                onChange={() => {
                                  input.onChange(!input.value);
                                }}
                              />
                            )}
                          </Field>
                        </li>
                        {fields.value?.[idx]?.shouldUseCustomMapping && (
                          <li data-title={t('Custom Mapping')}>
                            <Field name={`${name}.agentReference`}>
                              {({ input }) => (
                                <Input
                                  value={input.value}
                                  onChange={(e, { value }) => {
                                    input.onChange(value);
                                  }}
                                />
                              )}
                            </Field>
                          </li>
                        )}
                      </ul>
                    ))
                  }
                </FieldArray>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                style="blue"
                size="middle"
                loading={submitting}
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
