// @flow

import * as React from 'react';
import * as _ from 'lodash';
import { Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { FormField } from 'client/components/FormField';
import { getFieldOptions } from 'client/libraries/util/coreutil';
import { getKnownFieldPrompt } from 'client/libraries/util/getKnownFieldPrompt';
import type { Field } from 'shared/models/swagger';

type OwnProps = {
  fields: Field[],
  unmappedFieldResponses?: Object[],
  getFieldValue: (string) => string,
  onFieldChange: (id: string, value: string) => void,
  errorMap: { [key: string]: string },
  mode?: 'READ_ONLY' | 'TOGGLE' | 'INPUT',
  type?: 'BOOK' | 'EDIT',
};

type Props = {
  ...OwnProps,
};

export const FieldsForm = ({
  errorMap = {},
  fields,
  unmappedFieldResponses,
  getFieldValue,
  mode = 'TOGGLE',
  onFieldChange,
}: Props) => {
  const [editKey, setEditKey] = React.useState<string>('');

  const { t } = useTranslation();

  return (
    <React.Fragment>
      {fields.map((f) =>
        (_.isEqual(editKey, f.key) && mode === 'TOGGLE') || mode === 'INPUT' ? (
          <FormField
            required={f.required !== 'OPTIONAL'}
            error={!!errorMap[f.key || '']}
            key={f.key}
            options={getFieldOptions(f)}
            format={f.format}
            prompt={f.prompt}
            value={getFieldValue(f.key || '') || ''}
            onChange={(value) => onFieldChange(f.key || '', value)}
            onBlur={() => setEditKey('')}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                // ENTER
                e.preventDefault();
                setEditKey('');
              }
            }}
          />
        ) : (
          <Form>
            <Form.Field key={f.key}>
              <label>{f.prompt}</label>
              <span>
                {getFieldValue(f.key || '') || '---'}
                {mode !== 'READ_ONLY' && (
                  <Icon
                    name="write"
                    color="blue"
                    link
                    disabled
                    onClick={() => setEditKey(f.key || '')}
                  />
                )}
              </span>
            </Form.Field>
          </Form>
        )
      )}
      {mode !== 'INPUT' &&
        mode !== 'TOGGLE' &&
        unmappedFieldResponses &&
        unmappedFieldResponses.map((field_response) => (
          <Form>
            <Form.Field key={field_response.key}>
              <label>
                {getKnownFieldPrompt(field_response.key, t) ||
                  field_response.key}
              </label>
              <span>{field_response.response}</span>
            </Form.Field>
          </Form>
        ))}
    </React.Fragment>
  );
};
