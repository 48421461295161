import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useMemo, useCallback } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';

type FormValues = {
  toAddress?: string;
};
type Props = {
  initialToAddress?: string;
  onSubmit: (toAddress?: string) => void;
  onClose: () => void;
};

export const SendReviewRequestEmailModal = ({
  initialToAddress,
  onSubmit,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    (): FormValues => ({
      toAddress: initialToAddress,
    }),
    [initialToAddress]
  );
  const validateEmailAddress = useCallback(
    (email?: any) => {
      if (!email) return t('Required');
      if (!matchesFormat(email, 'email')) return t('Invalid Email');
      return undefined;
    },
    [t]
  );
  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values: FormValues) => {
        onSubmit(values.toAddress);
        onClose();
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={t('Send Review Request Email')}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button
                  text={t('Cancel')}
                  color="white"
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                />
                <Button text={t('Send')} type="submit" />
              </>
            }
          >
            <p style={{ marginBottom: '6px' }}>{t('Email Address')}</p>
            <Field name="toAddress" validate={validateEmailAddress}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  disabled={Boolean(initialToAddress)}
                  value={input.value}
                  onChange={(value) => input.onChange(value)}
                  error={touched && error}
                />
              )}
            </Field>
          </Modal>
        </form>
      )}
    </Form>
  );
};
