import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { ReduxState } from 'client/reducers';
import { QuantityGadget } from 'client/components/v3/DashboardGadgets/QuantityGadget';
import { NpsSurveyReportDataSet } from 'shared/models/swagger';
import { consolidateCustomerData } from 'client/pages/v3/AnnualPass/Dashboard';
import { useReservationData } from 'client/pages/v3/AnnualPass/useReservationData';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './Gadgets.module.css';
import { NPSRatioChart } from './Charts/NPSRatioChart';
import { PromotionAverageConversionCostCorrelationChart } from './Charts/PromotionAverageConversionCostCorrelationChart';
import { PromotionTotalReservationsCorrelationChart } from './Charts/PromotionTotalReservationsCorrelationChart';
import { NPSRatingsFactorChart } from './Charts/NPSRatingsFactorChart';
import { PromotionVisitFrequencyCorrelationChart } from './Charts/PromotionVisitFrequencyCorrelationChart';
import { PromotionMemberLifetimeValueCorrelationChart } from './Charts/PromotionMemberLifetimeValueCorrelationChart';
import { useCustomerData } from './useCustomerData';

// Only promoters and detractors are important in NPS calculation formula
// https://customergauge.com/blog/how-to-calculate-the-net-promoter-score
const calculateNpsScore = (dataSet: NpsSurveyReportDataSet) => {
  if (
    !dataSet ||
    !dataSet.items_by_date?.length ||
    dataSet.items_by_date?.length === 0
  ) {
    return 0;
  }

  let promoters = 0;
  let detractors = 0;
  let totalResponses = 0;

  for (const item of dataSet.items_by_date) {
    if (!item.results) {
      continue;
    }
    for (const result of item.results) {
      if (!result.type) {
        continue;
      }
      if (result.type === 'NPS_SURVEY_RESULT_TYPE_PROMOTER') {
        promoters++;
      } else if (result.type === 'NPS_SURVEY_RESULT_TYPE_DETRACTOR') {
        detractors++;
      }
    }
    totalResponses += item.total_responses ?? 0;
  }

  const promoterPercentage = (promoters / totalResponses) * 100;
  const detractorPercentage = (detractors / totalResponses) * 100;
  const npsScore = promoterPercentage - detractorPercentage;

  return npsScore;
};

const calculateRecommendationAverage = (dataSet: NpsSurveyReportDataSet) => {
  if (
    !dataSet ||
    !dataSet.items_by_date?.length ||
    dataSet.items_by_date?.length === 0
  ) {
    return 0;
  }

  let totalNpsScore = 0;
  let totalResponses = 0;
  for (const item of dataSet.items_by_date) {
    if (!item.results) {
      continue;
    }
    for (const result of item.results) {
      if (!result.nps_score) {
        continue;
      }
      totalNpsScore = totalNpsScore + parseInt(result.nps_score);
    }
    totalResponses += item.total_responses ?? 0;
  }

  return Math.round((totalNpsScore / totalResponses) * 10) / 10;
};

export const Gadgets = () => {
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const reportDataSets = useSelector(
    (state: ReduxState) => state.surveySubmissionResults.reportData
  );

  const loading = useSelector(
    (state: ReduxState) => state.surveySubmissionResults.reportDataLoading
  );

  // Get all reservations for supplier
  const { data: annualPassesData } = useReservationData(true);
  const { data: allOtherReservationsData } = useReservationData();
  const { data: customerData } = useCustomerData({
    supplierId: activeOrganization?.id ?? '',
    includeDeletedRecords: true,
  });

  // Create mapping of customers to all of their reservations
  const customerReservationData = useMemo(
    () =>
      consolidateCustomerData(
        customerData,
        annualPassesData,
        allOtherReservationsData
      ),
    [customerData, annualPassesData, allOtherReservationsData]
  );

  const baseDataSet = reportDataSets[0];
  const comparisonDataSet =
    reportDataSets?.length > 1 ? reportDataSets[1] : null;

  const baseNpsScore = calculateNpsScore(baseDataSet);
  let comparisonNpsScore = 0;
  if (comparisonDataSet) {
    comparisonNpsScore = calculateNpsScore(comparisonDataSet);
  }
  const baseRecommendationAverage = calculateRecommendationAverage(baseDataSet);
  let comparisonRecommendationAverage = 0;
  if (comparisonDataSet) {
    comparisonRecommendationAverage =
      calculateRecommendationAverage(comparisonDataSet);
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-row']}>
        <QuantityGadget
          header={t('NPS Score')}
          unit={t('%')}
          amount={baseNpsScore}
          changeAmount={
            comparisonDataSet ? baseNpsScore - comparisonNpsScore : null
          }
          loading={loading}
        />
        <QuantityGadget
          header={t('Promotion Average')}
          unit={t('pt')}
          amount={baseRecommendationAverage}
          changeAmount={
            comparisonDataSet
              ? baseRecommendationAverage - comparisonRecommendationAverage
              : null
          }
          loading={loading}
        />
        <NPSRatioChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
        />
      </div>
      <div className={styles['container-row']}>
        <PromotionAverageConversionCostCorrelationChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
        />
        <PromotionMemberLifetimeValueCorrelationChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
        />
      </div>
      <div className={styles['container-row']}>
        <PromotionTotalReservationsCorrelationChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
        />
        <PromotionVisitFrequencyCorrelationChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
          customerReservationData={customerReservationData}
        />
      </div>
      <div className={styles['container-row']}>
        <NPSRatingsFactorChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
          title={t('Experience Factors that Influence NPS Ratings (Promoters)')}
          type="NPS_SURVEY_RESULT_TYPE_PROMOTER"
          baseColor="#3B82F6"
          comparisonColor="#93C5FD"
        />
        <NPSRatingsFactorChart
          baseDataSet={baseDataSet}
          comparisonDataSet={comparisonDataSet}
          loading={loading}
          title={t(
            'Experience Factors that Influence NPS Ratings (Detractors)'
          )}
          type="NPS_SURVEY_RESULT_TYPE_DETRACTOR"
          baseColor="#EC4899"
          comparisonColor="#FBCFE8"
        />
      </div>
    </div>
  );
};
