import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import {
  CODE_MISMATCH_EXCEPTION,
  EMPTY_EMAIL_EXCEPTION,
  EMPTY_NEW_PASSWORD_EXCEPTION,
  EMPTY_PASSWORD_EXCEPTION,
  EMPTY_RECOVERY_CODE_EXCEPTION,
  INVALID_EMAIL_FORMAT_EXCEPTION,
  INVALID_PARAMETER_EXCEPTION,
  INVALID_PASSWORD_EXCEPTION,
  LIMIT_EXCEEDED_EXCEPTION,
  NOT_AUTHORIZED_EXCEPTION,
  USER_NOT_FOUND_EXCEPTION,
  INVALID_ONETIME_CODE_EXCEPTION,
  EMPTY_ONETIME_CODE_EXCEPTION,
} from 'client/constants/ErrorMessages';
import {
  clearError,
  forgotPasswordCancel,
  forgotPasswordRequest,
  forgotPasswordReset,
  login,
  sendOnetimeCode,
  logout,
} from 'client/actions/user';
import { isSignedIn } from 'client/libraries/cognito';
import { Input } from 'client/components/Form/Input';
import { Button } from 'client/components/Form/Button';
import { LogoHeader } from 'client/components/LogoHeader/LogoHeader';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import 'client/loggedOutPages/Login/Login.css';

const getNotificationMessage = (err: string, t: TranslateFuncType) => {
  return {
    [CODE_MISMATCH_EXCEPTION]: t(
      'Invalid verification code provided, please try again.'
    ),
    [EMPTY_EMAIL_EXCEPTION]: t('Email is empty O_O'),
    [EMPTY_NEW_PASSWORD_EXCEPTION]: t('Please set your new password.'),
    [EMPTY_PASSWORD_EXCEPTION]: t('Password is empty O_O'),
    [EMPTY_RECOVERY_CODE_EXCEPTION]: t('Recovery code is empty.'),
    [INVALID_EMAIL_FORMAT_EXCEPTION]: t('Email format is invalid.'),
    [INVALID_PARAMETER_EXCEPTION]: t(
      'New password needs to contain numbers, special character, uppercase letters, lowercase letters, and needs to be 8-20 characters in length.'
    ),
    [INVALID_PASSWORD_EXCEPTION]: t(
      'New password needs to contain numbers, special character, uppercase letters, lowercase letters, and needs to be 8-20 characters in length.'
    ),
    [LIMIT_EXCEEDED_EXCEPTION]: t(
      'Attempt limit exceeded, please try after some time.'
    ),
    [NOT_AUTHORIZED_EXCEPTION]: t(
      "Email or password doesn't match with our system >_<"
    ),
    [USER_NOT_FOUND_EXCEPTION]: t('User is not found in our system.'),
    [INVALID_ONETIME_CODE_EXCEPTION]: t(
      'Invalid passcode or passcode has expired. Please login again to reissue a new passcode.'
    ),
    [EMPTY_ONETIME_CODE_EXCEPTION]: t('Passcode is empty 0_0'),
  }[err];
};

export const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [recoveryCode, setRecoveryCode] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [onetimeCode, setOnetimeCode] = React.useState<string>('');
  const location = useLocation<{ from?: string }>();
  const dispatch = useDispatch();
  const cognito = useSelector((state: ReduxState) => state.user.cognito);
  const forgotPassword = useSelector(
    (state: ReduxState) => state.user.forgotPassword
  );
  const loading = useSelector((state: ReduxState) => state.user.loading);
  const error = useSelector((state: ReduxState) => state.error);
  React.useEffect(() => {
    dispatch(clearError());
  }, [username, password, recoveryCode, newPassword]);

  const handleMouseClickCancel = () => {
    dispatch(forgotPasswordCancel());
  };

  const handleLogin = (e: any) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  const handleSendOnetimeCode = (e: any) => {
    e.preventDefault();
    dispatch(sendOnetimeCode(cognito, onetimeCode));
  };

  const handleForgotPasswordRequest = () => {
    setRecoveryCode('');
    setNewPassword('');

    if (
      error !== '' &&
      error !== EMPTY_PASSWORD_EXCEPTION &&
      error !== NOT_AUTHORIZED_EXCEPTION
    ) {
      return;
    }

    dispatch(forgotPasswordRequest(username));
  };

  const handleSendOnetimeCodeCancel = () => {
    dispatch(logout());
  };

  const handleForgotPasswordReset = (e: any) => {
    e.preventDefault();

    if (forgotPassword === true) {
      dispatch(forgotPasswordReset(username, recoveryCode, newPassword));
    }
  };

  const redirectPath =
    location.state && location.state.from
      ? location.state.from
      : '/reservations';

  if (cognito && cognito.challengeName === 'NEW_PASSWORD_REQUIRED') {
    return <Redirect push to="/new-user" />;
  }

  if (isSignedIn(cognito)) {
    return <Redirect push to={redirectPath} />;
  }

  const errorMessageBox =
    error !== '' ? (
      <div className="error-message">{getNotificationMessage(error, t)}</div>
    ) : null;
  return (
    <div className="loginPage">
      <LogoHeader />
      <div className="main-content">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        <div className="main-text">
          {forgotPassword ? (
            <form noValidate onSubmit={handleForgotPasswordReset}>
              <div className="input-box">
                <Input
                  label={t('Login ID (email)')}
                  value={username}
                  onChange={(e, { value }) => {
                    setUsername(value);
                  }}
                  minLength="6"
                  maxLength="100"
                  type="text"
                  spellCheck="false"
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="input-box">
                <Input
                  label={t('Password')}
                  value={password}
                  onChange={(e, { value }) => {
                    setPassword(value);
                  }}
                  minLength="8"
                  maxLength="20"
                  type="password"
                  spellCheck="false"
                  autoComplete="off"
                  disabled
                />
              </div>

              <div className="input-box">
                <Input
                  label={t('Recovery code')}
                  value={recoveryCode}
                  onChange={(e, { value }) => {
                    setRecoveryCode(value);
                  }}
                  minLength="6"
                  maxLength="40"
                  type="text"
                  spellCheck="false"
                  autoComplete="off"
                  required
                />
              </div>

              <div className="input-box">
                <Input
                  label={t('New password')}
                  value={newPassword}
                  onChange={(e, { value }) => {
                    setNewPassword(value);
                  }}
                  minLength="8"
                  maxLength="20"
                  type="password"
                  spellCheck="false"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="button-area">
                <div className="button-box">
                  <Button size="small" style="green" type="submit">
                    {t('Confirm')}
                  </Button>
                </div>
                <div className="button-box">
                  <Button
                    size="small"
                    style="link"
                    onClick={handleMouseClickCancel}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              </div>

              <div className="notify-box">
                {t(
                  "We've sent password recovery code to your email. Please type it in and set a new password."
                )}
              </div>
            </form>
          ) : (
            <>
              {cognito?.challengeName === 'CUSTOM_CHALLENGE' ? (
                <form noValidate onSubmit={handleSendOnetimeCode}>
                  <div className="input-box">
                    <b>{t('Two factor authentication required')}</b>
                    <Box mt={4} mb={4}>
                      {t("We've sent a passcode to your email address.")}
                      <br />
                      {t('Email')}: <b>{username}</b>
                      <br />
                      {t(
                        'Please login with your passcode. Passcode will expire in 10 minutes.'
                      )}
                    </Box>
                    <Input
                      label={t('Passcode')}
                      minLength="6"
                      maxLength="40"
                      type="text"
                      spellCheck="false"
                      autoComplete="off"
                      value={onetimeCode}
                      onChange={(e, { value }) => {
                        setOnetimeCode(value);
                      }}
                      required
                    />
                  </div>
                  <div className="button-area">
                    <div className="button-box">
                      <Button size="small" style="green" type="submit">
                        {t('Login')}
                      </Button>
                    </div>
                    <div className="button-box">
                      <Button
                        size="small"
                        style="link"
                        onClick={handleSendOnetimeCodeCancel}
                      >
                        {t('Cancel')}
                      </Button>
                    </div>
                  </div>
                </form>
              ) : (
                <form noValidate onSubmit={handleLogin}>
                  <div className="input-box">
                    <Input
                      label={t('Login ID (email)')}
                      minLength="6"
                      maxLength="100"
                      type="text"
                      spellCheck="false"
                      autoComplete="off"
                      value={username}
                      onChange={(e, { value }) => {
                        setUsername(value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-box">
                    <Input
                      label={t('Password')}
                      minLength="6"
                      maxLength="20"
                      type="password"
                      spellCheck="false"
                      autoComplete="off"
                      value={password}
                      onChange={(e, { value }) => {
                        setPassword(value);
                      }}
                      required
                    />
                  </div>
                  <div className="button-area">
                    <div className="button-box">
                      <Button size="small" style="green" type="submit">
                        {t('Login')}
                      </Button>
                    </div>
                    <div className="button-box">
                      <Button
                        size="small"
                        style="link"
                        onClick={handleForgotPasswordRequest}
                      >
                        {t('Forgot Password')}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </>
          )}

          {errorMessageBox}
        </div>
      </div>
    </div>
  );
};
