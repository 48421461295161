// @flow

import * as React from 'react';
import { Grid, Icon, Message, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { Dispatch } from 'redux';
import type { TranslatorProps } from 'react-i18next';

import { fetchReservationByID } from 'client/actions/reservations';
import { fetchProductByID } from 'client/actions/products';
import { fetchProductInstanceByID } from 'client/actions/productInstances';
import { ReservationTab } from 'client/pages/ReservationDetails/ReservationTab';
import { ModalLoader } from 'client/components/ModalLoader';
import { ActionButtons } from 'client/pages/Dash/ActionButtons';
import {
  actionItemDescription,
  actionItemHelpText,
} from 'client/pages/Dash/util';
import type { TaskShape } from 'client/pages/Dash/util';
import type { ReduxState } from 'client/reducers/index';

type OwnProps = {
  task: TaskShape,
  taskColor: string,
  onTaskChange: () => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  open: boolean,
};

class TaskDetailsModalComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.reservation !== prevProps.reservation) {
      if (this.props.reservation && this.props.reservation.product_id) {
        this.props.fetchProductByID(this.props.reservation.product_id);
      }
      if (
        this.props.reservation &&
        this.props.reservation.product_instance_id
      ) {
        this.props.fetchProductInstanceByID(
          this.props.reservation.product_instance_id
        );
      }
    }
  }

  handleOpen = () => {
    const { task, fetchReservationByID } = this.props;
    fetchReservationByID(task.reservationId);
    this.setState({ open: true });
  };
  handleClose = () => this.setState({ open: false });

  render() {
    const { loading, onTaskChange, t, task } = this.props;
    const { open } = this.state;

    return (
      <>
        <Icon link name="external" onClick={this.handleOpen} />
        <Modal
          size="fullscreen"
          eventPool="task-details"
          className="fix-semantic-modal"
          open={open}
          closeIcon={true}
          onClose={this.handleClose}
          style={{ left: 'auto!important' }}
        >
          <Modal.Header>{actionItemDescription(task, t)}</Modal.Header>
          <Modal.Content>
            {loading ? (
              <ModalLoader />
            ) : (
              <>
                <Grid verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <ActionButtons
                        size="large"
                        task={task}
                        onTaskChange={() => {
                          this.handleClose();
                          onTaskChange();
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} textAlign="right">
                      <Link to={`/reservations/${task.reservationId}`}>
                        {t('Edit reservation details')}
                        <Icon name="arrow right" />
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Message info size="large">
                  <Message.Content>
                    {actionItemHelpText(task, t)}
                  </Message.Content>
                </Message>
                <ReservationTab
                  reservationId={task.reservationId}
                  readOnly={true}
                />
              </>
            )}
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  reservation: state.reservations.byID[ownProps.task.reservationId],
  loading:
    state.reservations.loading ||
    state.products.loading ||
    state.productInstances.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
  fetchProductInstanceByID: (id: string) =>
    dispatch(fetchProductInstanceByID(id)),
});

export const TaskDetailsModal = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(TaskDetailsModalComponent);
