import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { productOptionsSelector } from 'client/reducers/products';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  Button,
  DateInput,
  FieldWrapper,
  MultiSelect,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import { mediaDownloadPricesSelector } from 'client/reducers/mediaDownloadPrices';
import { defaultProductTimezoneSelector } from 'client/reducers/organizations';
import {
  createMediaDownloadSalesPage,
  updateMediaDownloadSalesPage,
} from 'client/actions/mediaDownloadSalesPages';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import * as Swagger from 'shared/models/swagger';

import { PublishedSalesPageForm } from './PublishedSalesPageForm/PublishedSalesPageForm';
import { MediaPackagesEditor } from './MediaPackagesEditor';
import { EditSelectedProductInstancesForm } from './EditSelectedProductInstancesForm';
import { MediaItemInput } from './MediaItemInput/MediaItemInput';
import { FormValues, getInitialValues } from './FormValues';

type Props = {
  existingSalesPage?: Swagger.MediaDownloadSalesPage;
  open: boolean;
  onClose: () => void;
};
export const EditMediaSalesPageModal = ({
  open,
  onClose,
  existingSalesPage,
}: Props) => {
  const [showPublishConfirmModal, setShowPublishConfirmModal] =
    React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timezone = useSelector(defaultProductTimezoneSelector);
  const initialValues = React.useMemo(
    () => getInitialValues(timezone, existingSalesPage),
    [existingSalesPage, timezone]
  );
  const productOptions = useSelector(productOptionsSelector);

  const prices = useSelector(mediaDownloadPricesSelector);
  const hasPackagePrices = prices.some((price) => price.type === 'MEDIA_SET');

  return (
    <Modal
      title={t('Edit Sales Page')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      {existingSalesPage?.status === 'MEDIA_SALES_PAGE_PUBLISHED' ? (
        <PublishedSalesPageForm
          salesPage={existingSalesPage}
          onUnpublish={async () => {
            await dispatch(
              updateMediaDownloadSalesPage(existingSalesPage?.id ?? '', {
                ...existingSalesPage,
                status: 'MEDIA_SALES_PAGE_PENDING',
              })
            );

            onClose();
          }}
        />
      ) : (
        <Form
          initialValues={initialValues}
          debug={console.log}
          onSubmit={async (values: FormValues) => {
            if (existingSalesPage) {
              await dispatch(
                updateMediaDownloadSalesPage(existingSalesPage?.id ?? '', {
                  participation_date: values.date,
                  product_ids: values.productIds,
                  media_items: values.mediaItems.map((mediaItem) => ({
                    key: mediaItem.key,
                    content: {
                      type: mediaItem.url?.endsWith('mp4') ? 'VIDEO' : 'IMAGE',
                      url: mediaItem.url,
                      video_duration_seconds: mediaItem.videoDurationSeconds,
                      video_filesize_bytes: mediaItem.videoFilesizeBytes,
                    },
                    price_id: mediaItem.priceId,
                  })),
                  media_sets: values.mediaSets.map((mediaSet) => ({
                    key: mediaSet.key,
                    media_items: mediaSet.mediaItems.map((mediaItem) => ({
                      key: mediaItem.key,
                      content: {
                        type: mediaItem.url?.endsWith('mp4')
                          ? 'VIDEO'
                          : 'IMAGE',
                        url: mediaItem.url,
                        video_duration_seconds: mediaItem.videoDurationSeconds,
                        video_filesize_bytes: mediaItem.videoFilesizeBytes,
                      },
                    })),
                    price_id: mediaSet.priceId,
                  })),
                  status: values.status,
                  product_instance_ids: values.productInstanceIds,
                })
              );
            } else {
              await dispatch(
                createMediaDownloadSalesPage({
                  participation_date: values.date,
                  product_ids: values.productIds,
                  media_items: values.mediaItems.map((mediaItem) => ({
                    key: mediaItem.key,
                    content: {
                      type: mediaItem.url?.endsWith('mp4') ? 'VIDEO' : 'IMAGE',
                      url: mediaItem.url,
                      video_duration_seconds: mediaItem.videoDurationSeconds,
                      video_filesize_bytes: mediaItem.videoFilesizeBytes,
                    },
                    price_id: mediaItem.priceId,
                  })),
                  media_sets: values.mediaSets.map((mediaSet) => ({
                    key: mediaSet.key,
                    media_items: mediaSet.mediaItems.map((mediaItem) => ({
                      key: mediaItem.key,
                      content: {
                        type: mediaItem.url?.endsWith('mp4')
                          ? 'VIDEO'
                          : 'IMAGE',
                        url: mediaItem.url,
                        video_duration_seconds: mediaItem.videoDurationSeconds,
                        video_filesize_bytes: mediaItem.videoFilesizeBytes,
                      },
                    })),
                    price_id: mediaSet.priceId,
                  })),
                  status: values.status,
                  product_instance_ids: values.productInstanceIds,
                })
              );
            }

            onClose();
          }}
          mutators={getArrayMutators()}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                {submitting && (
                  <Loader active={submitting}>{t('Loading')}</Loader>
                )}
                {showPublishConfirmModal && (
                  <MessageModal
                    open={true}
                    onClose={() => setShowPublishConfirmModal(false)}
                    title={t('Publish Sales Page')}
                    message={t(
                      'Are you sure you wish to publish this sales page?'
                    )}
                    onSubmit={() => {
                      form.change('status', 'MEDIA_SALES_PAGE_PUBLISHED');
                      handleSubmit();
                    }}
                  />
                )}
                <Box mb={2} display="flex">
                  <FieldWrapper label={t('Status')}>
                    {t('Pending')}
                  </FieldWrapper>
                  <Box>
                    <Button.Create
                      onClick={() => setShowPublishConfirmModal(true)}
                    >
                      {t('Save and Publish')}
                    </Button.Create>
                  </Box>
                </Box>
                <Box mb={2}>
                  <Field name="date">
                    {({ input }) => (
                      <DateInput
                        label={t('Select Participation Date')}
                        {...input}
                      />
                    )}
                  </Field>
                </Box>
                <Box mb={2}>
                  <FieldWrapper label={t('Select Products')}>
                    <Field name="productIds">
                      {({ input }) => (
                        <MultiSelect
                          search
                          options={productOptions}
                          selectedValues={input.value}
                          onChange={({ value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </Box>
                <Box mb={2}>
                  <Field<
                    FormValues['shouldApplyToAllStartTimes']
                  > name="shouldApplyToAllStartTimes">
                    {({ input }) => (
                      <ToggleButton
                        label={t(
                          'Apply page to all product start times for the date'
                        )}
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                      />
                    )}
                  </Field>
                </Box>
                {!values?.shouldApplyToAllStartTimes && (
                  <EditSelectedProductInstancesForm />
                )}
                <FieldWrapper label={t('Individual Photos and Videos')}>
                  <Field name="mediaItems">
                    {({ input }) => (
                      <MediaItemInput
                        mediaItems={input.value || []}
                        onChange={(newValue) => input.onChange(newValue)}
                      />
                    )}
                  </Field>
                </FieldWrapper>
                {hasPackagePrices ? (
                  <FieldWrapper label={t('Photo/Video Packages')}>
                    <MediaPackagesEditor />
                  </FieldWrapper>
                ) : (
                  <Box>
                    {t(
                      'Note: Please register a package price to add photo/video packages'
                    )}
                  </Box>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  size="middle"
                  style="blue"
                  type="submit"
                  disabled={
                    values?.mediaItems?.some(
                      (item) =>
                        item.mediaType === 'VIDEO' && !item.videoDurationSeconds
                    ) ||
                    values?.mediaSets?.some((mediaSet) =>
                      mediaSet.mediaItems?.some(
                        (item) =>
                          item.mediaType === 'VIDEO' &&
                          !item.videoDurationSeconds
                      )
                    )
                  }
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          )}
        </Form>
      )}
    </Modal>
  );
};
