import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { ModalLoader } from 'client/components/ModalLoader';
import { getDateFilterPresetText } from 'client/libraries/util/searchMediaDownloadOrders';
import type { SearchMediaDownloadOrdersRequest } from 'client/libraries/util/searchMediaDownloadOrders';
import type { ReduxState } from 'client/reducers';
import { summariesWithBookmarksSelector } from 'client/reducers/products';

import styles from './MediaDownloadOrderSearchFiltersDisplayBox.module.css';

type Props = {
  searchFilters: SearchMediaDownloadOrdersRequest;
};
export const MediaDownloadOrderSearchFiltersDisplayBox = ({
  searchFilters,
}: Props) => {
  const { t } = useTranslation();
  const productOptions = useSelector(summariesWithBookmarksSelector).map(
    (p) => {
      return {
        value: p.id,
        text: getDisplayProductName(p),
      };
    }
  );
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (searchFilters.productIds?.length > 0) {
    filters.push({
      typeText: t('Products'),
      filterValue: searchFilters.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (searchFilters.agentReference) {
    filters.push({
      typeText: t('Application Number'),
      filterValue: searchFilters.agentReference,
    });
  }

  if (searchFilters.supplierReference) {
    filters.push({
      typeText: t('Confirmation Number'),
      filterValue: searchFilters.supplierReference,
    });
  }

  if (searchFilters.dateFilterPreset) {
    const preset = searchFilters.dateFilterPreset;
    filters.push({
      typeText: t('Date Preset'),
      filterValue: getDateFilterPresetText(preset, t),
    });
  }

  if (
    searchFilters.participationDateFrom ||
    searchFilters.participationDateTo
  ) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${searchFilters.participationDateFrom ?? ''} ~ ${
        searchFilters.participationDateTo
      }`,
    });
  }

  if (searchFilters.createdDateFrom || searchFilters.createdDateTo) {
    filters.push({
      typeText: t('Purchase Date'),
      filterValue: `${searchFilters.createdDateFrom ?? ''} ~ ${
        searchFilters.createdDateTo
      }`,
    });
  }

  if (searchFilters.customerGivenName) {
    filters.push({
      typeText: t('Given Name'),
      filterValue: searchFilters.customerGivenName,
    });
  }

  if (searchFilters.customerFamilyName) {
    filters.push({
      typeText: t('Family Name'),
      filterValue: searchFilters.customerFamilyName,
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      <b>{t('Search Conditions')}</b>

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
