import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, FieldWrapper, Input, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { InsertMessageTemplateModal } from 'client/pages/Inquiry/InsertMessageTemplateModal/InsertMessageTemplateModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { sendInquiryEmail } from 'client/actions/inquiries';
import {
  InquiryAttachmentsEditor,
  InquiryAttachment,
} from 'client/components/InquiryAttachmentsEditor/InquiryAttachmentsEditor';
import { Reservation } from 'shared/models/swagger';

import styles from './SendInquiryEmailModal.module.css';

type FormValues = {
  subject: string;
  cc: string[];
  bcc: string[];
  message: string;
  email: string;
  attachments: InquiryAttachment[];
};
type Props = {
  reservation: Reservation | undefined;
  onClose: () => void;
  open: boolean;
};
export const SendInquiryEmailModal = ({
  onClose,
  open,
  reservation,
}: Props) => {
  const { t } = useTranslation();
  const [showInsertMessageTemplateModal, setShowInsertMessageTemplateModal] =
    React.useState(false);

  const [step, setStep] = React.useState<'EDIT' | 'CONFIRM'>('EDIT');
  const organization = useSelector(activeUserOrganizationSelector);
  const initialValues = React.useMemo((): FormValues => {
    const email =
      reservation?.field_responses?.find((response) => response.key === 'email')
        ?.response ?? '';
    return {
      subject: '',
      cc: [],
      bcc: [],
      message: '',
      email,
      attachments: [],
    };
  }, [reservation]);
  const validateEmail = React.useCallback(
    (email?: any) => {
      if (!email) return t('Required');

      if (!matchesFormat(email, 'email')) return t('Invalid Email');

      return undefined;
    },
    [t]
  );

  const { required } = getValidators(t);

  const dispatch = useDispatch();

  const org = useSelector(activeUserOrganizationSelector);
  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;

  return (
    <Modal title={t('Send Email')} open={open} onClose={onClose}>
      <Form
        onSubmit={async (values: FormValues) => {
          switch (step) {
            case 'EDIT':
              setStep('CONFIRM');
              break;
            case 'CONFIRM':
              await dispatch(
                sendInquiryEmail({
                  reservation_id: reservation?.id,
                  email: values.email,
                  subject: values.subject,
                  body: values.message,
                  cc: values.cc,
                  bcc: values.bcc,
                  attachments: values.attachments?.map((attachment) => ({
                    url: attachment.url,
                    filename: attachment.filename,
                  })),
                })
              );

              onClose();
              break;
          }
        }}
        initialValues={initialValues}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <Field name="email" validate={validateEmail}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    label={t('Email')}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('From')}>
                <div className={styles['from-input']}>
                  <Box mb={3}>{organization?.name ?? ''}</Box>
                  <div className={styles['from-input-actor-name']}>
                    <ReservationActorInputForm />
                  </div>
                  <Box mb={3}>
                    <Tooltip
                      text={t(
                        'Please enter your name here. Your name will be shown in emails to guests.'
                      )}
                    />
                  </Box>
                </div>
              </FieldWrapper>
            </Box>
            <Box mt={2}>
              <ListEditor name="cc" title={t('CC')} />
            </Box>
            <Box mt={2}>
              <ListEditor name="bcc" title={t('BCC')} />
            </Box>

            {hasMessageTemplates && (
              <Box mt={2}>
                <Button.Create
                  onClick={() => setShowInsertMessageTemplateModal(true)}
                >
                  {t('Insert Message Template')}
                </Button.Create>
              </Box>
            )}
            {showInsertMessageTemplateModal && (
              <InsertMessageTemplateModal
                onClose={() => setShowInsertMessageTemplateModal(false)}
                onInsert={(messageTemplate) => {
                  if (messageTemplate.subject) {
                    form.change('subject', messageTemplate.subject);
                  }
                  form.change('message', messageTemplate.message);
                }}
              />
            )}
            <Box mt={2}>
              <Field name="subject" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    label={t('Subject')}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </Box>

            <Box mt={2}>
              <Field name="message" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <TextArea
                    label={t('Message')}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                    error={touched && error}
                  />
                )}
              </Field>
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('Attachments')}>
                <Field name="attachments">
                  {({ input }) => (
                    <InquiryAttachmentsEditor
                      attachments={input.value}
                      onChange={(newAttachments: InquiryAttachment[]) =>
                        input.onChange(newAttachments)
                      }
                    />
                  )}
                </Field>
              </FieldWrapper>
            </Box>

            <div className={styles['buttons']}>
              <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
              <Button size="middle" style="blue" type="submit">
                {t('Send')}
              </Button>
              {step === 'CONFIRM' && (
                <MessageModal
                  open={true}
                  onClose={() => setStep('EDIT')}
                  title={t('Send Email')}
                  message={t('Are you sure you wish to send this email?')}
                  onSubmit={handleSubmit}
                  insertRoot
                  loading={submitting}
                />
              )}
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};
