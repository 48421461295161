import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ProductGroupList } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ProductGroupList';
import { ManifestViewList } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestViewList';
import { ExcludedFormFieldList } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ExcludedFormFieldList';
import { CustomizedColumnName } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/CustomizedColumnName';
import { OutputAbbreviation } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/OutputAbbreviation';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import { ManifestCustomizeHeader } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeHeader/ManifestCustomizeHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';

type TabType =
  | 'EDIT_GROUP'
  | 'EDIT_VIEWS'
  | 'FILTER'
  | 'CUSTOMIZED_COLUMN_NAME'
  | 'ALTERNATE_NAME';

export const ManifestCustomize = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabType>('EDIT_GROUP');

  return (
    <V3Page>
      <ManifestCustomizeHeader />
      <div className={baseStyles['l-main__body']}>
        <section
          className={clsx(baseStyles['g-section'], baseStyles['u-mb-4'])}
        >
          <div className={styles['p-manifests__customize']}>
            <div className={styles['p-manifests__customize__header']}>
              <ul className={styles['p-manifests__customize__header__tab']}>
                <li
                  className={clsx(
                    activeTab === 'EDIT_GROUP' && styles['is-active']
                  )}
                  onClick={() => {
                    setActiveTab('EDIT_GROUP');
                  }}
                >
                  {t('Edit Manifest Groups')}
                </li>
                <li
                  className={clsx(
                    activeTab === 'EDIT_VIEWS' && styles['is-active']
                  )}
                  onClick={() => {
                    setActiveTab('EDIT_VIEWS');
                  }}
                >
                  {t('Edit Manifest Views')}
                </li>
                <li
                  className={clsx(
                    activeTab === 'FILTER' && styles['is-active']
                  )}
                  onClick={() => {
                    setActiveTab('FILTER');
                  }}
                >
                  {t('Filter Required Info')}
                </li>
                <li
                  className={clsx(
                    activeTab === 'CUSTOMIZED_COLUMN_NAME' &&
                      styles['is-active']
                  )}
                  onClick={() => {
                    setActiveTab('CUSTOMIZED_COLUMN_NAME');
                  }}
                >
                  {t('Column names')}
                </li>
                <li
                  className={clsx(
                    activeTab === 'ALTERNATE_NAME' && styles['is-active']
                  )}
                  onClick={() => {
                    setActiveTab('ALTERNATE_NAME');
                  }}
                >
                  {t('Output Abbreviations')}
                </li>
              </ul>
            </div>
            <div className={styles['p-manifests__customize__body']}>
              <ProductGroupList isActive={activeTab === 'EDIT_GROUP'} />
              <ManifestViewList isActive={activeTab === 'EDIT_VIEWS'} />
              <ExcludedFormFieldList isActive={activeTab === 'FILTER'} />
              <CustomizedColumnName
                isActive={activeTab === 'CUSTOMIZED_COLUMN_NAME'}
              />
              <OutputAbbreviation isActive={activeTab === 'ALTERNATE_NAME'} />
            </div>
          </div>
        </section>
      </div>
    </V3Page>
  );
};
