import { MarketingAutomationCampaignParams } from 'shared/models/swagger';

export interface FormValues {
  title: string;
  status: 'DRAFT' | 'ACTIVE';
  segmentIds: string[];
  triggerId: string;
  contentId: string;
  sendOnce: boolean;
}

export const convertFormValuesToSwagger = (
  values: FormValues
): MarketingAutomationCampaignParams => {
  return {
    title: values.title,
    status: values.status,
    segment_ids: values.segmentIds,
    trigger_id: values.triggerId,
    content_id: values.contentId,
    is_single_send: values.sendOnce,
  };
};

export const convertSwaggerToFormValues = (
  content: MarketingAutomationCampaignParams
): FormValues => {
  return {
    title: content.title ?? '',
    status: content.status ?? 'DRAFT',
    segmentIds: content.segment_ids ?? [],
    triggerId: content.trigger_id ?? '',
    contentId: content.content_id ?? '',
    sendOnce: content.is_single_send ?? false,
  };
};
