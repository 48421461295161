import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getColumns } from 'client/components/ManifestReservationsTable/util';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import { Checkbox } from 'client/components/Form';
import { ManifestTableHeaderCell } from 'client/pages/Manifest/ManifestTableHeaderCell';
import {
  activeUserOrganizationSelector,
  accountsSelector,
} from 'client/reducers/user';
import { getGuideAccountShapes } from 'client/libraries/util/accountShape';
import type { ProductSummary, ReservationColumn } from 'shared/models/swagger';

type Props = {
  excludedFormFieldKeys: string[];
  products: ProductSummary[];
  reservations: ManifestReservationShape[];
  visibleColumns: ReservationColumn[];
  selectedReservationIds: string[];
  onSelectedReservationChange: (arg0: string[]) => void;
};
const defaultProducts: ProductSummary[] = [];
export const ResourceBulkUpdateReservationsTable = ({
  excludedFormFieldKeys,
  products = defaultProducts,
  reservations,
  visibleColumns,
  selectedReservationIds,
  onSelectedReservationChange,
}: Props) => {
  const [columns, setColumns] = React.useState<CustomTableColumn[]>([]);
  const { t } = useTranslation();
  const accounts = useSelector(accountsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector((state: ReduxState) => state.manifests.loading);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const guideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  );

  const handleRowCheckboxClicked = (reservation: ManifestReservationShape) => {
    if (selectedReservationIds.includes(reservation.id)) {
      onSelectedReservationChange(
        selectedReservationIds.filter((id) => id !== reservation.id)
      );
    } else {
      onSelectedReservationChange([...selectedReservationIds, reservation.id]);
    }
  };

  React.useEffect(() => {
    setColumns(
      [
        {
          Header: '',
          HeaderElement: (
            <>
              <Checkbox
                checked={selectedReservationIds.length === reservations.length}
                onChange={() => {
                  if (selectedReservationIds.length === reservations.length) {
                    onSelectedReservationChange([]);
                  } else {
                    onSelectedReservationChange([
                      ...reservations.map((r) => r.id),
                    ]);
                  }
                }}
              />
            </>
          ),
          id: 'assign',
          width: 'short',
          Cell: (cellInfo: { original: ManifestReservationShape }) => (
            <Checkbox
              checked={selectedReservationIds.includes(cellInfo.original.id)}
              onChange={() => handleRowCheckboxClicked(cellInfo.original)}
            />
          ),
          th: true,
        },
        ...getColumns(
          locale,
          t,
          excludedFormFieldKeys,
          products,
          reservations,
          [],
          visibleColumns,
          [],
          guideAccountShapes || [],
          null,
          null,
          null
        ).map((col) => ({
          ...col,
          Header: '',
          HeaderElement: <ManifestTableHeaderCell text={col.Header} />,
        })),
      ].map((c) => {
        return c as CustomTableColumn;
      })
    );
  }, [
    locale,
    t,
    products,
    visibleColumns,
    selectedReservationIds,
    reservations,
  ]);
  return (
    <>
      {loading ? (
        <>{t('Loading')}</>
      ) : (
        <CustomTable
          usePaging={false}
          useScrollButton={true}
          items={reservations}
          columns={columns}
        />
      )}
    </>
  );
};
