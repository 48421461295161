import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { ReduxState } from 'client/reducers';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { DateRangeInput, Select } from 'client/components/Form';
import { fetchSalesOfferStats } from 'client/actions/salesOfferStats';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

type DatePreset = '7_DAY' | '14_DAYS' | '28_DAYS' | 'CUSTOM';

export const SalesOfferStats = () => {
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [datePreset, setDatePreset] = React.useState<string>('7_DAY');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (datePreset === 'CUSTOM') {
      dispatch(fetchSalesOfferStats(startDate, endDate, moment.tz.guess()));
    } else {
      switch (datePreset) {
        case '7_DAY':
          dispatch(
            fetchSalesOfferStats(
              moment().subtract(7, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              moment.tz.guess()
            )
          );
          break;
        case '14_DAYS':
          dispatch(
            fetchSalesOfferStats(
              moment().subtract(14, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              moment.tz.guess()
            )
          );
          break;
        case '28_DAYS':
          dispatch(
            fetchSalesOfferStats(
              moment().subtract(28, 'days').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              moment.tz.guess()
            )
          );
          break;
      }
    }
  }, [datePreset, startDate, endDate]);

  const salesOfferStats = useSelector(
    (state: ReduxState) => state.salesOfferStats.all
  );

  const presetOptions: { value: DatePreset; text: string }[] = [
    {
      value: '7_DAY',
      text: t('Last {{count}} days', {
        count: 7,
      }),
    },
    {
      value: '14_DAYS',
      text: t('Last {{count}} days', {
        count: 14,
      }),
    },
    {
      value: '28_DAYS',
      text: t('Last {{count}} days', {
        count: 28,
      }),
    },
    {
      value: 'CUSTOM',
      text: t('Custom'),
    },
  ];

  return (
    <div>
      <Box mb={2} display="flex" flexWrap="wrap" alignItems="flex-end">
        <Select
          label={t('Date Range')}
          options={presetOptions}
          value={datePreset ?? 'CUSTOM'}
          onChange={(event, { value }) => {
            setDatePreset(value);
          }}
          width={300}
        />
        {datePreset === 'CUSTOM' && (
          <Box ml={2}>
            <DateRangeInput
              label={t('Dates')}
              fromDate={startDate}
              onChangeFromDate={(fromDate) => {
                setStartDate(fromDate);
              }}
              toDate={endDate}
              onChangeToDate={(toDate) => {
                setEndDate(toDate);
              }}
            />
          </Box>
        )}
      </Box>
      <div className={baseStyles['base-main__body__box']}>
        <div style={{ margin: '16px' }}>
          <FormTableBox>
            <table>
              <tr>
                <th>{t('Offer')}</th>
                <th>{t('Reach')}</th>
                <th>{t('Conversions')}</th>
                <th>{t('Amount')}</th>
              </tr>
              {salesOfferStats.map((statRow, idx) => {
                const conversionCount = statRow.conversion_count || 0;

                return (
                  <tr key={idx}>
                    <td>{statRow.sales_offer_title}</td>
                    <td>
                      {statRow.impression_count
                        ? statRow.impression_count
                        : '-'}
                    </td>
                    <td>
                      {statRow.impression_count
                        ? `${conversionCount} (${(
                            100 *
                            (conversionCount / statRow.impression_count)
                          ).toFixed(2)}%)`
                        : '-'}
                    </td>
                    <td>
                      {statRow.conversion_total_amount
                        ? formattedCurrencyAmount(
                            statRow.conversion_total_amount
                          )
                        : '-'}
                    </td>
                  </tr>
                );
              })}
            </table>
          </FormTableBox>
        </div>
      </div>
    </div>
  );
};
