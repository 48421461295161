import * as React from 'react';
import clsx from 'clsx';
import { Field, useFormState } from 'react-final-form';
import _ from 'lodash';

import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { Input } from 'client/components/Form';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { QRCode as QRCodeIcon } from 'client/components/Icons/QRCode';
import type { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { PresetRedemptionCountQrCodeModal } from './PresetRedemptionCountQrCodeModal';
import styles from './DraggablePresetRedemptionCountInputForm.module.css';

type Props = {
  productId: string;
  name: string;
  idx: number;
  translationTargetLanguage: SourceLanguage | null;
  onRemoveClick: () => void;
};
export const PresetRedemptionCountInputForm = ({
  productId,
  name,
  translationTargetLanguage,
  onRemoveClick,
}: Props) => {
  const formState = useFormState();
  const formValue = _.get(formState.values, name);
  const [showQrCodeModal, setShowQrCodeModal] = React.useState(false);

  return (
    <>
      <TranslatedField name={`${name}.text`}>
        {({ input, translationInput }) => (
          <>
            <td>
              <div
                className={clsx(
                  baseStyles['base-flex'],
                  styles['input'],
                  translationTargetLanguage
                    ? styles['input__source_language']
                    : null
                )}
              >
                <Input {...input} />
                <Field name={`${name}.redemptionCount`}>
                  {({ input: redemptionCountInput }) => (
                    <Input {...redemptionCountInput} type="number" />
                  )}
                </Field>
                <div className={baseStyles['base-flex']}>
                  <DeleteIcon onClick={() => onRemoveClick()} />
                  <QRCodeIcon onClick={() => setShowQrCodeModal(true)} />
                </div>
              </div>
            </td>
            {translationTargetLanguage && (
              <td>
                <div
                  className={clsx(
                    styles['input'],
                    translationTargetLanguage
                      ? styles['input__translation_language']
                      : null
                  )}
                >
                  <Input {...translationInput} />
                </div>
              </td>
            )}
          </>
        )}
      </TranslatedField>
      <PresetRedemptionCountQrCodeModal
        productId={productId}
        open={showQrCodeModal}
        onClose={() => setShowQrCodeModal(false)}
        presetRedemptionCountKey={formValue?.key}
      />
    </>
  );
};
