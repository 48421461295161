import moment from 'moment-timezone';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Button, Select } from 'client/components/Form';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import type { ProductSummaryWithBookmark } from 'client/reducers/products';
import baseStyles from 'client/base.module.css';
import deleteIcon from 'client/images/ic_delete.svg';

interface ProductStartTimeProps {
  productId?: string;
  startTimeLocal?: string;
}

type Props = {
  productStartTimes: ProductStartTimeProps[];
  onChange: (arg0: ProductStartTimeProps[]) => void;
  error?: string | typeof undefined;
  filter?: (arg0: ProductSummaryWithBookmark) => boolean;
};
export const ProductIdStartTimeListInput = ({
  productStartTimes,
  onChange,
  error,
  filter,
}: Props) => {
  const { t } = useTranslation();
  const productsToChoose = useSelector(summariesWithBookmarksSelector);

  const filteredProductsToChoose = productsToChoose.filter((p) => {
    if (filter) {
      return filter(p);
    }
    return true;
  });

  const getStartTimeLocals = (productId: string) => {
    const product = filteredProductsToChoose.find((p) => p.id === productId);
    if (!product) {
      return [];
    }

    const recurrence = product.recurrence;
    if (!recurrence || !recurrence.length) {
      return [];
    }

    const firstRecurrence = recurrence.find((r) => {
      if (
        moment
          .tz(r.end_date_local, product.start_timezone ?? 'UTC')
          .isAfter(moment())
      ) {
        return true;
      }
      return false;
    });

    if (!firstRecurrence) {
      return [];
    }

    return firstRecurrence.start_times.map((startTime) => ({
      value: startTime.start_time_local,
      text: startTime.start_time_local,
    }));
  };

  return (
    <div className={baseStyles['base-form-box']}>
      {(productStartTimes || []).map((currentValue, currentValueIdx) => (
        <div key={currentValueIdx} className={clsx(baseStyles['inline-block'])}>
          <Select
            search
            options={filteredProductsToChoose
              .filter(
                // Does not allow choosing duplicate
                (p) =>
                  p.id === currentValue.productId ||
                  !productStartTimes
                    .map((productStartTime) => productStartTime.productId)
                    .includes(p.id)
              )
              .map((p) => ({
                value: p.id ?? '',
                text: getVerboseDisplayProductName(p),
              }))}
            value={currentValue.productId ?? ''}
            onChange={(_, { value: newProductId }) => {
              onChange(
                productStartTimes.map((productStartTime, idx) =>
                  idx === currentValueIdx
                    ? { productId: newProductId }
                    : productStartTime
                )
              );
            }}
          />

          <Select
            options={getStartTimeLocals(currentValue.productId ?? '')}
            value={currentValue.startTimeLocal ?? ''}
            onChange={(_, { value: newStartTimeLocal }) => {
              const newProductStartTimes = [...productStartTimes];
              newProductStartTimes[currentValueIdx] = {
                ...newProductStartTimes[currentValueIdx],
                startTimeLocal: newStartTimeLocal,
              };
              onChange(newProductStartTimes);
            }}
          />

          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['icon'],
              baseStyles['inline']
            )}
            onClick={() => {
              const newProductStartTimes = [...productStartTimes];
              newProductStartTimes.splice(currentValueIdx, 1);
              onChange(newProductStartTimes);
            }}
          >
            <img src={deleteIcon} />
          </a>
        </div>
      ))}

      <div className={clsx(baseStyles['list-add-btn'])}>
        <Button
          style="green"
          size="middle"
          onClick={() => {
            const newProductStartTimes = [...productStartTimes];
            newProductStartTimes.splice(productStartTimes.length + 1, 0, {});
            onChange(newProductStartTimes);
          }}
        >
          {t('Add')}
        </Button>
      </div>
      {error && <p className={baseStyles['base-form-box__err']}>{error}</p>}
    </div>
  );
};
