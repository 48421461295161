import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import { ToggleButton, Select } from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { Modal } from 'client/components/Modal/Modal';
import * as Swagger from 'shared/models/swagger';
import { productOptionsSelector } from 'client/reducers/products';
import { ReduxState } from 'client/reducers';
import { DraggableProductList } from 'client/pages/ProductDetails/ProductContentsHeader/DraggableProductList/DraggableProductList';

type FormValues = {
  isEnable: boolean;
  continuingProductId: string;
  recommendedProductIds: string[];
};

const getInitialValues = (product: Swagger.Product | null): FormValues => {
  return {
    isEnable: product?.annual_pass_mode_settings?.is_enabled || false,
    continuingProductId:
      product?.annual_pass_mode_settings?.continuing_product_id || '',
    recommendedProductIds:
      product?.annual_pass_mode_settings?.recommended_product_ids || [],
  };
};
const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    annual_pass_mode_settings: {
      is_enabled: values.isEnable,
      continuing_product_id: values.continuingProductId,
      recommended_product_ids: values.recommendedProductIds,
    },
  };
};

type Props = {
  onClose: () => void;
};
export const AnnualPassModeSettingsModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const product = React.useContext(EditingProductContext);

  const productOptions = useSelector(productOptionsSelector);

  const productSummaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const filteredProductOptions = React.useMemo(() => {
    const annualPassProductIds =
      (productSummaries || [])
        .filter(
          (summary) => summary?.annual_pass_mode_settings?.is_enabled === true
        )
        .map((summary) => summary.id) || [];

    return productOptions.filter((option) =>
      annualPassProductIds.includes(option.value)
    );
  }, [productOptions, productSummaries]);

  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Annual Pass Mode Settings')}
      initialValues={getInitialValues(product)}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <Field type="checkbox" name="isEnable">
        {({ input }) => {
          return (
            <>
              <Modal.Box>
                <ToggleButton
                  {...input}
                  label={t('Use annual pass mode for this product')}
                />
              </Modal.Box>
              <Modal.Box>
                <Field name="continuingProductId">
                  {({ input }) => (
                    <Select
                      label={t('Continuing product')}
                      search
                      options={[
                        {
                          text: t('No selected'),
                          value: '',
                        },
                        ...filteredProductOptions,
                      ]}
                      value={input.value}
                      onChange={(_, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              </Modal.Box>
              <Modal.Box>
                <Field name="recommendedProductIds">
                  {({ input }) => (
                    <DraggableProductList
                      productIds={input.value}
                      setProductIds={input.onChange}
                      options={filteredProductOptions}
                      label={t(
                        'The annual passport product offered as an upgrade to this item on product detail page.(drag-and-drop to reorder)'
                      )}
                    />
                  )}
                </Field>
              </Modal.Box>
            </>
          );
        }}
      </Field>
    </ProductEditorModal>
  );
};
