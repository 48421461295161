import * as React from 'react';
import _ from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Message } from 'client/components/Message/Message';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Box } from 'client/components/Box/Box';
import { fetchContracts } from 'client/actions/contract';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { agentNameByIdSelector } from 'client/pages/BillingPeriodRevamped/util';
import { ColumnType } from 'client/libraries/util/ColumnType';
import { EditBillingPeriodModal } from 'client/pages/BillingPeriodRevamped/EditBillingPeriodModal';
import type { ReduxState } from 'client/reducers';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { TranslateFuncType } from 'client/components/Translate';
import { Contract } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

const describeBillingSchedule = (
  daysOfMonth: number[] | undefined,
  t: TranslateFuncType
): string => {
  if (!daysOfMonth) {
    return t('Not set yet');
  }

  let stringifiedDaysOfMonth = '';

  for (const dayOfMonth of daysOfMonth) {
    if (stringifiedDaysOfMonth === '') {
      stringifiedDaysOfMonth =
        dayOfMonth > 28
          ? String(dayOfMonth) + t(' (or last day of the month)')
          : String(dayOfMonth);
      continue;
    }

    if (dayOfMonth > 28) {
      stringifiedDaysOfMonth +=
        ', ' + dayOfMonth + t(' (or last day of the month)');
    } else {
      stringifiedDaysOfMonth += ', ' + dayOfMonth;
    }
  }

  return t('Invoicing dates: {{stringifiedDaysOfMonth}}', {
    stringifiedDaysOfMonth: stringifiedDaysOfMonth,
  });
};

interface TableRowData {
  id: string;
  agent_name: string;
  contract: Contract;
}

export const BillingPeriod = () => {
  const activeUser = useSelector(activeUserSelector);
  const contracts = useSelector((state: ReduxState) => state.contracts.all);
  const agentsMap = useSelector(agentNameByIdSelector);
  const loading = useSelector(
    (state: ReduxState) =>
      state.contracts.loading || state.organizations.loading
  );
  const currentOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  React.useEffect(() => {
    if (activeUser?.organization_type === 'SUPPLIER') {
      dispatch(fetchContracts());
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser, currentOrganization?.id]);

  //Preparing or Mapping data for displaying in tables
  const data = React.useMemo((): TableRowData[] => {
    const rowData = (contracts || []).map((contract) => {
      return {
        id: contract.id,
        agent_name: agentsMap[contract.agent_id ?? ''],
        contract: contract,
      };
    });
    return _.orderBy(rowData, ['agent_name'], ['asc']);
  }, [contracts, agentsMap]);

  const columns = React.useMemo((): ColumnType<TableRowData>[] => {
    return [
      {
        Header: t('Agent'),
        accessor: 'agent_name',
        id: 'agentName',
      },
      {
        Header: t('Billing Period'),
        Cell: (cellInfo) => {
          const daysOfMonth =
            cellInfo?.original?.contract?.billing_schedule_supplier
              ?.days_of_month;
          return (
            <Box display="flex" alignItems="center">
              <EditBillingPeriodModal
                trigger={() => <EditIcon />}
                contract={cellInfo.original.contract}
              />
              <Box ml={2}>{describeBillingSchedule(daysOfMonth, t)}</Box>
            </Box>
          );
        },
        id: 'billingPeriod',
      },
    ];
  }, [t]);

  let billing_period_header_title = t(
    'Agent Supplier specific billing periods'
  );

  if (activeUser && activeUser.organization_type === 'SUPPLIER') {
    billing_period_header_title = t('Per Agent Billing Periods');
  } else if (activeUser && activeUser.organization_type === 'AGENT') {
    billing_period_header_title = t('Per Supplier Billing Periods');
  }

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        <div>
          <div className={baseStyles['base-subttl']}>
            {t('Default Billing Period')}
          </div>
          <Message>
            <Box display="flex" alignItems="center">
              <EditBillingPeriodModal
                trigger={() => <EditIcon />}
                isEditingDefaultBillingSchedule={true}
                currentOrganization={currentOrganization}
              />

              <Box ml={2}>
                {currentOrganization &&
                currentOrganization.default_billing_schedule &&
                currentOrganization.default_billing_schedule.days_of_month
                  ? describeBillingSchedule(
                      currentOrganization.default_billing_schedule
                        .days_of_month,
                      t
                    )
                  : t('Click here to set your default billing period')}
              </Box>
            </Box>
          </Message>
        </div>

        <div className={baseStyles['base-subttl']}>
          {billing_period_header_title}
        </div>

        <CustomTable items={data} columns={columns} usePaging />
      </div>
    </div>
  );
};
