import { useTranslation } from 'react-i18next';

import { SearchConditions } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/SearchConditions';
import { DownloadItems } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/DownloadItems';
import { DownloadCsv } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/DownloadCsv';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

export const ReservationDataDownloadInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* loading && <Loading size={'lg'} /> */}
      <section className={styles['g-section']}>
        <div className={styles['p-download']}>
          <div className={styles['p-download__header']}>
            <h2 className={styles['p-download__header__title']}>
              {t('Create CSV File')}
            </h2>
          </div>
          <div className={styles['p-download__body']}>
            <SearchConditions />
            <DownloadItems />
            <DownloadCsv />
          </div>
        </div>
      </section>
    </>
  );
};
