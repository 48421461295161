// Environment variables to turn on debugging.
//
// Usage:
//  > REACT_APP_XXXXX=1 REACT_APP_YYYYY=1 yarn start
const dev = process.env.NODE_ENV !== 'production';
// Enable a post-processor to make it obvious which strings have been wrapped in i18n tags.
export const usePseudoLocalization =
  dev && !!process.env.REACT_APP_USE_PSEUDO_LOCALIZATION;
// Turn on logging for i18next
export const debugi18n = dev && !!process.env.REACT_APP_DEBUG_I18N;
export const disableOrganizationPolling =
  dev && !!process.env.REACT_APP_DISABLE_ORGANIZATION_POLLING;
export const disableRedirectAfterCreatingReservation =
  dev && !!process.env.REACT_APP_DISABLE_REDIRECT_AFTER_CREATING_RESERVATION;
