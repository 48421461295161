import { combineReducers } from 'redux';

import {
  FETCH_INVOICES_FAILURE,
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  ISSUE_INVOICE_FAILURE,
  ISSUE_INVOICE_REQUEST,
  ISSUE_INVOICE_SUCCESS,
  FETCH_INVOICE_BY_ID_FAILURE,
  FETCH_INVOICE_BY_ID_REQUEST,
  FETCH_INVOICE_BY_ID_SUCCESS,
  CONFIRM_INVOICE_FAILURE,
  CONFIRM_INVOICE_REQUEST,
  CONFIRM_INVOICE_SUCCESS,
  MARK_INVOICE_AS_PAID_FAILURE,
  MARK_INVOICE_AS_PAID_REQUEST,
  MARK_INVOICE_AS_PAID_SUCCESS,
  FARE_ADJUST_INVOICE_FAILURE,
  FARE_ADJUST_INVOICE_REQUEST,
  FARE_ADJUST_INVOICE_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  DOWNLOAD_INVOICE_PDF_REQUEST,
  DOWNLOAD_INVOICE_PDF_SUCCESS,
  DOWNLOAD_INVOICE_PDF_FAILURE,
  DOWNLOAD_INVOICE_CSV_REQUEST,
  DOWNLOAD_INVOICE_CSV_SUCCESS,
  DOWNLOAD_INVOICE_CSV_FAILURE,
} from 'client/constants/ActionTypes';
import type {
  ListInvoicesResponse,
  IssueInvoiceResponse,
  ConfirmInvoiceResponse,
  MarkInvoiceAsPaidResponse,
  CreateFareAdjustmentForInvoiceResponse,
  Invoice,
} from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_INVOICES_REQUEST:
    case ISSUE_INVOICE_REQUEST:
    case FETCH_INVOICE_BY_ID_REQUEST:
    case CONFIRM_INVOICE_REQUEST:
    case MARK_INVOICE_AS_PAID_REQUEST:
    case FARE_ADJUST_INVOICE_REQUEST:
      return true;

    case FETCH_INVOICES_SUCCESS:
    case FETCH_INVOICES_FAILURE:
    case ISSUE_INVOICE_SUCCESS:
    case ISSUE_INVOICE_FAILURE:
    case FETCH_INVOICE_BY_ID_SUCCESS:
    case FETCH_INVOICE_BY_ID_FAILURE:
    case CONFIRM_INVOICE_SUCCESS:
    case CONFIRM_INVOICE_FAILURE:
    case MARK_INVOICE_AS_PAID_SUCCESS:
    case MARK_INVOICE_AS_PAID_FAILURE:
    case FARE_ADJUST_INVOICE_SUCCESS:
    case FARE_ADJUST_INVOICE_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_INVOICES_FAILURE:
    case ISSUE_INVOICE_FAILURE:
    case FETCH_INVOICE_BY_ID_FAILURE:
    case CONFIRM_INVOICE_FAILURE:
    case MARK_INVOICE_AS_PAID_FAILURE:
    case FARE_ADJUST_INVOICE_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const byID = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_INVOICES_SUCCESS:
    case ISSUE_INVOICE_SUCCESS:
    case FETCH_INVOICE_BY_ID_SUCCESS:
    case CONFIRM_INVOICE_SUCCESS:
    case MARK_INVOICE_AS_PAID_SUCCESS:
    case FARE_ADJUST_INVOICE_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    default:
      return state;
  }
};

const allInvoices = (state: Invoice[] = [], action: any) => {
  const new_state = [...state];

  switch (action.type) {
    case FETCH_INVOICES_SUCCESS: {
      const resp = action.response as any as ListInvoicesResponse;
      return resp.invoices || [];
    }
    case ISSUE_INVOICE_SUCCESS: {
      const resp_issue_invoice = action.response as any as IssueInvoiceResponse;

      for (let i = 0; i < new_state.length; i++) {
        if (new_state[i].id === resp_issue_invoice.id) {
          new_state[i] = resp_issue_invoice;
          break;
        }
      }

      return new_state;
    }
    case CONFIRM_INVOICE_SUCCESS: {
      const resp_confirm_invoice =
        action.response as any as ConfirmInvoiceResponse;

      for (let i = 0; i < new_state.length; i++) {
        if (new_state[i].id === resp_confirm_invoice.id) {
          new_state[i] = resp_confirm_invoice;
          break;
        }
      }

      return new_state;
    }
    case MARK_INVOICE_AS_PAID_SUCCESS: {
      const resp_mark_invoice_as_paid =
        action.response as any as MarkInvoiceAsPaidResponse;

      for (let i = 0; i < new_state.length; i++) {
        if (new_state[i].id === resp_mark_invoice_as_paid.id) {
          new_state[i] = resp_mark_invoice_as_paid;
          break;
        }
      }

      return new_state;
    }
    case FARE_ADJUST_INVOICE_SUCCESS:
      for (let i = 0; i < new_state.length; i++) {
        const resp_fare_adjust_invoice =
          action.response as any as CreateFareAdjustmentForInvoiceResponse;

        if (new_state[i].id === resp_fare_adjust_invoice.id) {
          new_state[i] = resp_fare_adjust_invoice;
          break;
        }
      }

      return new_state;

    default:
      return state;
  }
};

const pdfLoading = (state = false, action: any) => {
  switch (action.type) {
    case DOWNLOAD_INVOICE_PDF_REQUEST:
      return true;
    case DOWNLOAD_INVOICE_PDF_SUCCESS:
    case DOWNLOAD_INVOICE_PDF_FAILURE:
      return false;
    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case DOWNLOAD_INVOICE_CSV_REQUEST:
      return true;
    case DOWNLOAD_INVOICE_CSV_SUCCESS:
    case DOWNLOAD_INVOICE_CSV_FAILURE:
      return false;
    default:
      return state;
  }
};

type State = {
  loading: boolean;
  error: string;
  allInvoices: Invoice[];
  byID: Record<string, Invoice>;
  pdfLoading: boolean;
  csvLoading: boolean;
};

const reducer = combineReducers<State>({
  loading,
  error,
  allInvoices,
  byID,
  pdfLoading,
  csvLoading,
});

export const invoices = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
