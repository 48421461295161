import * as React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import {
  LocationSearchInput,
  SearchResult,
} from 'client/components/LocationSearchInput';
import { FieldWrapper } from 'client/components/Form';

import styles from './GoogleMapView.module.css';
import { Background } from './formValues';
import { IllustrationOverlay } from './IlustrationOverlay/IllustrationOverlay';

const defaultCenter = { lat: -3.745, lng: -38.523 };
const defaultZoom = 18;

interface Props {
  onClick?: (lat: number, lng: number) => void;
  background?: Background;
  pins?: google.maps.LatLngLiteral[];
  showOverlay?: boolean;
  heading?: number;
  disableAutoBounds?: boolean;
  zoom?: number;
  initialCenter?: { latitude: number; longitude: number };
  onZoomChange?: (zoom: number) => void;
}

export const GoogleMapView = ({
  onClick,
  background,
  pins,
  showOverlay,
  heading,
  zoom,
  initialCenter,
  onZoomChange,
}: Props) => {
  const { t } = useTranslation();
  const { isLoaded } = useGoogleMapsApi();

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [locationSearchResult, setLocationSearchResult] =
    React.useState<SearchResult | null>(null);

  const onLoad = React.useCallback((map) => {
    setMap(map);

    if (initialCenter) {
      map.setCenter({
        lat: initialCenter.latitude,
        lng: initialCenter.longitude,
      });
    } else {
      map.setCenter(defaultCenter);
    }
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (!map) {
      return;
    }
    if (zoom != null) {
      map.setZoom(zoom);
    } else {
      map.setZoom(defaultZoom);
    }
  }, [map, zoom]);

  React.useEffect(() => {
    if (!map) {
      return;
    }
    if (heading != null) {
      map.setHeading(heading);
    } else {
      map.setHeading(0);
    }
  }, [map, heading]);

  const shouldShowOverlay =
    showOverlay && background?.useOverlayImage && background?.imageUrl;

  return (
    <>
      {isLoaded ? (
        <>
          <GoogleMap
            mapContainerClassName={styles['map-container']}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(e) => {
              onClick?.(e?.latLng?.lat() ?? 0, e?.latLng?.lng() ?? 0);
            }}
            onZoomChanged={() => {
              const zoom = map?.getZoom();
              if (zoom != null && zoom >= 0) {
                onZoomChange?.(zoom);
              }
            }}
            options={{
              mapId: '2082d3d4952bd020',
              disableDoubleClickZoom: true,
            }}
          >
            {pins?.map((pin, idx) => (
              <Marker key={idx} position={{ ...pin }} />
            ))}
            {shouldShowOverlay && map && (
              <>
                <IllustrationOverlay
                  zIndex={102}
                  map={map}
                  url={background.imageUrl}
                  aspectRatio={background.aspectRatio}
                  topLeft={background.topLeft}
                  widthInMeters={background.widthInMeters}
                  heading={heading}
                />
              </>
            )}
          </GoogleMap>
          <Box mt={2} mb={2}>
            <FieldWrapper
              label={t(
                'Location Search (map will center around selected location)'
              )}
            />
            <LocationSearchInput
              location={locationSearchResult?.title ?? ''}
              onLocationSelect={async (locationSearchResult) => {
                if (locationSearchResult) {
                  const geocoder = new google.maps.Geocoder();

                  try {
                    const result = await geocoder.geocode({
                      placeId: locationSearchResult?.key ?? '',
                    });

                    map?.setCenter({
                      lat: result.results[0].geometry.location.lat(),
                      lng: result.results[0].geometry.location.lng(),
                    });

                    setLocationSearchResult(locationSearchResult);
                  } catch (e) {
                    console.log('error: ', e);
                  }
                }
              }}
            />
          </Box>
        </>
      ) : null}
    </>
  );
};
