import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { PagedGenericTable } from 'client/components/PagedGenericTable/PagedGenericTable';
import {
  createSalesOffer,
  deleteSalesOffer,
  fetchSalesOffers,
} from 'client/actions/salesOffers';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/GenericTable/GenericTable';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Edit } from 'client/components/Icons/Edit';
import { Copy } from 'client/components/Icons/Copy';
import { Delete } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { SalesOffer } from 'shared/models/swagger';

const EditCopyDeleteCell = ({
  salesOffer,
  annualPassMode,
}: {
  salesOffer: SalesOffer;
  annualPassMode: boolean;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const loading = useSelector((state: ReduxState) => state.salesOffers.loading);
  const dispatch = useDispatch();
  const store = useStore<ReduxState>();
  const history = useHistory();

  return (
    <Box display="flex">
      <Link
        to={`${annualPassMode ? '/annualpasses' : ''}/offers/${
          salesOffer.id
        }/edit`}
      >
        <Edit />
      </Link>
      <Copy
        onClick={async () => {
          await dispatch(
            createSalesOffer({
              title: `${salesOffer.title} [COPY]`,
              status: 'DRAFT',
              content: salesOffer.content,
              trigger: salesOffer.trigger,
              condition_rules: salesOffer.condition_rules,
              medium: salesOffer.medium,
            })
          );

          const lastCreatedSalesOffer =
            store.getState().salesOffers.lastCreatedSalesOffer;
          if (lastCreatedSalesOffer) {
            history.push(
              `${annualPassMode ? '/annualpasses' : ''}/offers/${
                lastCreatedSalesOffer.id
              }/edit`
            );
          }
        }}
      />
      <Box ml={1}>
        <Delete onClick={() => setShowDeleteModal(true)} />
        {showDeleteModal && (
          <DeleteConfirmModal
            loading={loading}
            header={t('Delete Sales Offer')}
            content={t('Are you sure you want to delete sales offer?')}
            onConfirm={async () => {
              await dispatch(deleteSalesOffer(salesOffer.id as string));
            }}
            onClose={() => setShowDeleteModal(false)}
            open={true}
            insertRoot
          />
        )}
      </Box>
    </Box>
  );
};

const useColumns = (annualPassMode: boolean): ColumnType<SalesOffer>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: '',
      id: 'edit',
      accessor: (salesOffer) => (
        <EditCopyDeleteCell
          salesOffer={salesOffer}
          annualPassMode={annualPassMode}
        />
      ),
      width: 150,
    } as ColumnType<SalesOffer>,
    {
      Header: t('Status'),
      id: 'status',
      accessor: (salesOffer) =>
        salesOffer.status === 'ACTIVE' ? t('Active') : t('Draft'),
      width: 80,
    },
    {
      Header: t('Medium'),
      id: 'medium',
      accessor: (salesOffer) =>
        salesOffer.medium === 'EMAIL' ? t('Email') : t('Web'),
      width: 80,
    },
    {
      Header: t('Title'),
      id: 'title',
      accessor: (salesOffer) => salesOffer.title,
    },
  ];
};

interface Props {
  annualPassMode?: boolean;
}

export const SalesOfferList = (
  { annualPassMode = false }: Props = { annualPassMode: false }
) => {
  const dispatch = useDispatch();

  const columns = useColumns(annualPassMode ?? false);

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchSalesOffers());
  }, []);

  const salesOffers = useSelector((state: ReduxState) => state.salesOffers.all);

  const filteredSalesOffers = salesOffers.filter((salesOffer) => {
    if (annualPassMode) {
      return salesOffer.content?.content_type === 'ANNUAL_PASS';
    } else {
      return salesOffer.content?.content_type !== 'ANNUAL_PASS';
    }
  });

  const sortedSalesOffers = _.orderBy(filteredSalesOffers, ['title'], ['asc']);

  return (
    <div>
      <Box display="flex" mb={2}>
        <Box ml="auto">
          <Link to={`${annualPassMode ? '/annualpasses' : ''}/offers/new`}>
            <Button style="green" size="middle">
              {t('Create New Offer')}
            </Button>
          </Link>
        </Box>
      </Box>
      <PagedGenericTable allItems={sortedSalesOffers} columns={columns} />
    </div>
  );
};
