import { createSelector } from 'reselect';
import { Action, combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS,
  SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION,
} from 'client/constants/ActionTypes';
import { SearchMediaDownloadOrdersRequest } from 'client/libraries/util/searchMediaDownloadOrders';
import { ReduxState } from 'client/reducers';

// Selectors
export const mediaDownloadOrderColumnCandidatesSelector = (): string[] => [
  'submissionDate',
  'title',
  'language',
  'agentReference',
  'supplierReference',
  'participation',
  'customer',
  'productName',
  'status',
];

const visibleColumnsSelector = (state: ReduxState) =>
  state.mediaDownloadOrderTableControls?.visibleColumns ?? [];

export const mediaDownloadOrderVisibleColumnsSelector: (
  state: ReduxState
) => string[] = createSelector(
  visibleColumnsSelector,
  mediaDownloadOrderColumnCandidatesSelector,
  (visibleColumns, candidateColumns) =>
    visibleColumns.filter((c: string) => candidateColumns.indexOf(c) !== -1)
);

// Reducers
const defaultVisibleColumns = [
  'submissionDate',
  'title',
  'language',
  'agentReference',
  'supplierReference',
  'participation',
  'customer',
  'productName',
  'status',
];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

const defaultSearchFilters: SearchMediaDownloadOrdersRequest = {
  agentReference: '',
  supplierReference: '',
  customerGivenName: '',
  customerFamilyName: '',
  productIds: [],
  participationDateFrom: '',
  participationDateTo: '',
  createdDateFrom: '',
  createdDateTo: '',
  dateFilterPreset: 'CREATED_7_DAYS',
};

const lastExecutedSearchCondition = (
  state = defaultSearchFilters,
  action: any
) => {
  switch (action.type) {
    case SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION:
      return action.searchFilters;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  lastExecutedSearchCondition: SearchMediaDownloadOrdersRequest;
};

const reducer = combineReducers<State>({
  visibleColumns,
  lastExecutedSearchCondition,
});
export const mediaDownloadOrderTableControls = (
  state: State,
  action: Action
) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
