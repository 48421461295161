import * as React from 'react';
import clsx from 'clsx';

import styles from './Tabs.module.css';

export type TabPane = {
  header: string;
  content: React.ComponentType<any>;
};
interface Props {
  panes: TabPane[];
}
export const Tabs = ({ panes }: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <div className={styles['p-dashboardData']}>
      <ul className={styles['p-dashboardData__tab']}>
        {panes.map((pane, idx) => {
          return (
            <li
              key={pane.header}
              className={clsx(idx === activeIndex && styles['is-active'])}
              onClick={() => setActiveIndex(idx)}
            >
              {pane.header}
            </li>
          );
        })}
      </ul>
      {panes.map((pane, idx) => {
        const Content = pane.content;
        return (
          <div
            key={pane.header}
            className={clsx(styles['p-dashboardData__tabContent'])}
          >
            <div
              className={clsx(
                styles['p-dashboardData__body'],
                idx === activeIndex && styles['is-active']
              )}
            >
              <Content />
            </div>
          </div>
        );
      })}
    </div>
  );
};
