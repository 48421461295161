import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'client/components/Form';

import styles from './ConsentForm.module.css';

type Props = {
  visible: boolean;
  checked: boolean;
  onChange: (e: React.SyntheticEvent<Record<string, any>>) => void;
};
export const ConsentForm = ({ visible, checked, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.warn}
      style={{
        display: visible ? 'block' : 'none',
      }}
    >
      <Checkbox
        label={t(
          'Personally Identifiable Information is included in the CSV file.\nPlease be careful with the downloaded file.'
        )}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};
