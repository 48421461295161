import { EssentialPagesHeader } from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPagesHeader';
import { EssentialPagesBody } from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPagesBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const EssentialPages = () => {
  return (
    <V3Page>
      <EssentialPagesHeader />
      <EssentialPagesBody />
    </V3Page>
  );
};
