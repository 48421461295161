import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Button } from 'client/components/v3/Common/Button';
import baseStyles from 'client/v3-base.module.css';
import { TextField } from 'client/components/v3/Form/TextField';

import styles from './TargetListEditor.module.css';

type QueryListEditorProps = {
  title?: string;
  tooltipText?: string;
  name: string;
  buttonDisabled?: boolean;
  addButtonText?: string;
};

export const QueryListEditor = ({
  title,
  tooltipText,
  name,
  buttonDisabled,
  addButtonText,
}: QueryListEditorProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['c-listEditor']}>
      {title && (
        <div className={styles['c-listEditor__ttl']}>
          <p>{title}</p>
          {tooltipText && (
            <Tooltip text={tooltipText}>
              <i className="c-icon-outline-general-info-circle"></i>
            </Tooltip>
          )}
        </div>
      )}
      <FieldArray name={name}>
        {({ fields }) => (
          <div className={styles['c-listEditor__body']}>
            <div className={styles['c-listEditor__body__list']}>
              {fields.length !== 0 &&
                fields.map((fieldName, idx) => (
                  <div
                    key={fieldName}
                    className={styles['c-listEditor__body__list__item']}
                  >
                    <Field name={`${fieldName}.parameter`}>
                      {({ input }) => {
                        return (
                          <TextField
                            value={input.value}
                            onChange={input.onChange}
                          />
                        );
                      }}
                    </Field>
                    <div style={{ padding: '0 12px' }}>—</div>
                    <Field name={`${fieldName}.value`}>
                      {({ input }) => {
                        return (
                          <TextField
                            value={input.value}
                            onChange={input.onChange}
                          />
                        );
                      }}
                    </Field>
                    <div className={styles['c-listEditor__body__actions']}>
                      <Button
                        size="icon"
                        color="tertiarygray"
                        onClick={() => fields.remove(idx)}
                        iconBeforeText={
                          <i className="c-icon-outline-general-trash-03"></i>
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
            {!buttonDisabled && (
              <div
                className={clsx(
                  fields.length && fields.length > 0 && baseStyles['u-mt-2']
                )}
              >
                <a
                  className={styles['c-listEditor__body__add']}
                  onClick={() => {
                    if (fields.length && fields.length > 0) {
                      (fields as any).insertAt(fields.length + 1, '');
                    } else {
                      (fields as any).insertAt(0, '');
                    }
                  }}
                >
                  <i className="c-icon-outline-general-plus-circle"></i>
                  {addButtonText ?? t('Add')}
                </a>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};
