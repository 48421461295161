import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { fetchCustomerReportData } from 'client/actions/customers';
import { fetchProducts } from 'client/actions/products';
import { Loading } from 'client/components/v3/Common/Loading';
import { ReduxState } from 'client/reducers';

import styles from './CrmDashboard.module.css';
import { Gadgets } from './Gadgets';
import { ViewSelector, View } from './ViewSelector';

interface DateRange {
  start: string;
  end: string;
}

export const CrmDashboard = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = React.useState<DateRange>(
    getDateRangeForPreset('PREV_7_DAYS')
  );
  const [comparisonDateRange, setComparisonDateRange] =
    React.useState<DateRange | null>(null);

  const loading = useSelector((state: ReduxState) => state.customers.loading);

  const dispatch = useDispatch();

  const views: View[] = [
    { key: 'flow', label: t('CRM Flow') },
    { key: 'stock', label: t('CRM Stock') },
  ];

  const [selectedViewKey, setSelectedViewKey] =
    React.useState<View['key']>('flow');

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  React.useEffect(() => {
    const dateRanges = [{ start: dateRange.start, end: dateRange.end }];
    if (comparisonDateRange?.start && comparisonDateRange?.end) {
      dateRanges.push({
        start: comparisonDateRange.start,
        end: comparisonDateRange.end,
      });
    }

    dispatch(fetchCustomerReportData(dateRanges));
  }, [
    dateRange.start,
    dateRange.end,
    comparisonDateRange?.start,
    comparisonDateRange?.end,
  ]);

  return (
    <V3Page>
      <PageHeader title={t('CRM Dashboard')}>
        <div className={styles['header-actions-container']}>
          <ComparisonDateInput
            baseDateRange={dateRange}
            setBaseDateRange={setDateRange}
            comparisonDateRange={comparisonDateRange}
            setComparisonDateRange={setComparisonDateRange}
            menuStyle={{ right: 0, left: 'auto' }}
          />
        </div>
      </PageHeader>
      {loading ? (
        <Loading size="lg" />
      ) : (
        <PageContent>
          <ViewSelector
            views={views}
            value={selectedViewKey}
            onChange={(viewKey) => setSelectedViewKey(viewKey)}
          />
          <Gadgets viewKey={selectedViewKey} />
        </PageContent>
      )}
    </V3Page>
  );
};
