export const getYouTubePreviewUrl = (url: string): string => {
  const urlParts = url.split('/');

  if (urlParts.length === 0) {
    return '';
  }

  const videoId = urlParts[urlParts.length - 1];
  return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
};
