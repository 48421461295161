import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { Button, TextArea } from 'client/components/Form';
import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { InsertMessageTemplateModal } from 'client/pages/Inquiry/InsertMessageTemplateModal/InsertMessageTemplateModal';
import baseStyles from 'client/base.module.css';

import styles from './ComposeMessage.module.css';

interface Props {
  onBackClick: () => void;
}
export const ComposeMessage = ({ onBackClick }: Props) => {
  const [showInsertMessageTemplateModal, setShowInsertMessageTemplateModal] =
    React.useState(false);
  const { t } = useTranslation();

  const { required } = getValidators(t);
  const form = useForm();

  const org = useSelector(activeUserOrganizationSelector);
  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button type="submit" style="green" size="middle">
            {t('Continue to Step 3')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('SMS Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          {hasMessageTemplates && (
            <Box mb={2}>
              <Button.Create
                onClick={() => setShowInsertMessageTemplateModal(true)}
              >
                {t('Insert Message Template')}
              </Button.Create>
            </Box>
          )}
          {showInsertMessageTemplateModal && (
            <InsertMessageTemplateModal
              onClose={() => setShowInsertMessageTemplateModal(false)}
              onInsert={(messageTemplate) => {
                form.change('message', messageTemplate.message);
              }}
            />
          )}
          <Box mt={2}>
            <Field name="message" validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextArea
                  value={input.value}
                  onChange={input.onChange}
                  label={t('Message')}
                  error={touched && error}
                  showCharacterCount={true}
                />
              )}
            </Field>
          </Box>
          <Box>
            <div>
              {t('* 1 message cost will be for approximately 60 characters.')}
            </div>
            <div>
              {t(
                '* Long messages may be split into multiple messages and charged for multiple messages'
              )}
            </div>
            <div>
              {t(
                '* When including URLs in messages, we recommend shortening the URLs with a service like '
              )}
              <a
                className={baseStyles['base-link']}
                href="https://bitly.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                bitly.com
              </a>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
