import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { Modal } from 'client/components/Modal/Modal';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  columnCandidates: string[];
  visibleColumns: string[];
  onVisibleColumnsChange: (columns: string[]) => void;
}

export const CommonReservationSearchTableSettingsModal = ({
  open,
  onClose,
  columnCandidates,
  onVisibleColumnsChange,
  visibleColumns,
}: Props) => {
  const { t } = useTranslation();
  const allColumns = getReservationTableColumns(t, 'en');

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c) as any);
  };

  const columnsToShow = getColumns(visibleColumns);
  const allCandidateColumns = getColumns(columnCandidates);

  return (
    <Modal title={t('Show columns')} open={open} onClose={onClose}>
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={columnsToShow.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                candidateItems={allCandidateColumns.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                setSelectedItems={(items) =>
                  onVisibleColumnsChange(items.map((i) => i.key))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
