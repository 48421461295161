import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'client/config';
import { Modal } from 'client/components/Modal/Modal';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Button, Input, Select, TextArea } from 'client/components/Form';
import { getArrayMutators } from 'client/libraries/util/form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import {
  createGuidanceCoupon,
  updateGuidanceCoupon,
} from 'client/actions/guidanceCoupon';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { bookingWidgetPMPSupportedLanguagesSelector } from 'client/reducers/user';
import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';
import { getLanguageName } from 'client/libraries/i18n';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import * as Swagger from 'shared/models/swagger';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';

import styles from './EditGuidanceCouponModal.module.css';
import {
  getInitialValues,
  convertFormValuesToSwagger,
  FormValues,
  TranslationFormItem,
} from './formValues';

type Props = {
  existingGuidanceCoupon?: Swagger.GuidanceCoupon;
  open: boolean;
  onClose: () => void;
};

export const EditGuidanceCouponModal = ({
  open,
  onClose,
  existingGuidanceCoupon,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );

  React.useEffect(() => {
    const sourceTexts = [
      existingGuidanceCoupon?.title,
      existingGuidanceCoupon?.description,
    ].filter((text) => Boolean(text));

    if (sourceTexts.length > 0) {
      dispatch(batchGetTranslations(sourceTexts as string[]));
    }
  }, [existingGuidanceCoupon]);

  const languageOptions = supportedLanguages
    .filter((lang) => lang !== defaultLanguage)
    .map((lang) => ({
      value: lang,
      text: getLanguageName(lang, t),
    }));

  const initialValues = React.useMemo(
    () => getInitialValues(existingGuidanceCoupon),
    [existingGuidanceCoupon]
  );

  return (
    <Modal
      title={t('Edit Coupon')}
      open={open}
      onClose={onClose}
      insertRoot
      width="wide"
    >
      <Form
        initialValues={initialValues}
        debug={console.log}
        onSubmit={async (values: FormValues) => {
          if (existingGuidanceCoupon) {
            await dispatch(
              updateGuidanceCoupon(
                existingGuidanceCoupon.id ?? '',
                convertFormValuesToSwagger(values)
              )
            );
          } else {
            await dispatch(
              createGuidanceCoupon(convertFormValuesToSwagger(values))
            );
          }

          if (values.targetTranslationLanguage) {
            await dispatch(
              updateTranslations(
                values?.translations.map((translation) => ({
                  source_language: defaultLanguage,
                  [defaultLanguage.toLowerCase()]: translation.source,
                  [values.targetTranslationLanguage?.toLowerCase() as any]:
                    translation.target,
                }))
              )
            );
          }

          onClose();
        }}
        keepDirtyOnReinitialize
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, values, form }) => {
          React.useEffect(() => {
            let translations: TranslationFormItem[] = [];
            if (defaultLanguage && values.targetTranslationLanguage) {
              const sourceFieldName: string = defaultLanguage.toLowerCase();
              const translationFieldName: string =
                values.targetTranslationLanguage.toLowerCase();
              translations = apiTranslations.map((trans) => ({
                source: (trans as any)[sourceFieldName],
                target: (trans as any)[translationFieldName],
              }));
            }

            form.change('translations', translations);
          }, [
            defaultLanguage,
            apiTranslations,
            values.targetTranslationLanguage,
          ]);

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                {submitting && (
                  <Loader active={submitting}>{t('Loading')}</Loader>
                )}
                <div className={baseStyles['base-main__body__header']}>
                  <div
                    className={clsx(
                      baseStyles['base-main__body__header__left'],
                      baseStyles['spSpacebetween'],
                      baseStyles['spOrder-1']
                    )}
                  >
                    <div className={baseStyles['base-form-toggle']}>
                      <label>
                        <Field name="showTranslationMode" type="checkbox">
                          {({ input }) => <input type="checkbox" {...input} />}
                        </Field>
                        <p></p>
                      </label>
                      {t('Translation mode')}
                    </div>
                    {values.showTranslationMode && (
                      <Field name="targetTranslationLanguage">
                        {({ input }) => (
                          <Select
                            width={176}
                            options={languageOptions.filter(
                              (lang) => lang.value !== defaultLanguage
                            )}
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <FormTableBox>
                  <table>
                    {values.targetTranslationLanguage ? (
                      <thead>
                        <tr className={clsx(styles['table-header'])}>
                          <th></th>
                          <td>{getLanguageName(defaultLanguage, t)}</td>
                          <td>
                            {getLanguageName(
                              values.targetTranslationLanguage,
                              t
                            )}
                          </td>
                        </tr>
                      </thead>
                    ) : null}
                    <tbody>
                      <tr>
                        <th>{t('Coupon Title')}</th>
                        <TranslatedField name="title">
                          {({ input, translationInput }) => (
                            <>
                              <td>
                                <Input
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                />
                              </td>
                              {values.targetTranslationLanguage ? (
                                <td>
                                  <Input
                                    value={translationInput.value}
                                    onChange={(e, { value }) =>
                                      translationInput.onChange(value)
                                    }
                                    placeholder={getLanguageName(
                                      values.targetTranslationLanguage,
                                      t
                                    )}
                                  />
                                </td>
                              ) : null}
                            </>
                          )}
                        </TranslatedField>
                      </tr>
                      {config.enableMaRevamp && (
                        <tr>
                          <th>{t('Thumbnail Image (jpg, png)')}</th>
                          <td>
                            <Field name="thumbnailUrl">
                              {({ input, meta: { touched, error } }) => (
                                <ImageVideoAudioInput
                                  fileUrls={input.value ? [input.value] : []}
                                  onChange={(newValue) =>
                                    newValue.length > 0
                                      ? input.onChange(newValue[0])
                                      : input.onChange('')
                                  }
                                  maxFileCount={1}
                                  disableYoutubeVideos
                                  error={touched && error}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>{t('Description')}</th>
                        <TranslatedField name="description">
                          {({ input, translationInput }) => (
                            <>
                              <td>
                                <TextArea
                                  value={input.value}
                                  onChange={(e, { value }) =>
                                    input.onChange(value)
                                  }
                                />
                              </td>
                              {values.targetTranslationLanguage ? (
                                <td>
                                  <TextArea
                                    value={translationInput.value}
                                    onChange={(e, { value }) =>
                                      translationInput.onChange(value)
                                    }
                                    placeholder={getLanguageName(
                                      values.targetTranslationLanguage,
                                      t
                                    )}
                                  />
                                </td>
                              ) : null}
                            </>
                          )}
                        </TranslatedField>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
