import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import { Button, Select, ToggleButton } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateWaiverSettings } from 'client/actions/waiverSettings';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import styles from './WaiverSettings.module.css';

export const WaiverSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const initialEmailDaysBeforeParticipation =
    organization?.waiver_settings?.waiver_request_days ?? 1;
  const initialEmailsDisabled =
    organization?.waiver_settings?.waiver_emails_disabled ?? false;
  const [emailDaysBeforeParticipation, setEmailDaysBeforeParticipation] =
    React.useState<number>(initialEmailDaysBeforeParticipation);
  const [emailsDisabled, setEmailsDisabled] = React.useState<boolean>(
    initialEmailsDisabled
  );
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  const kioskUrl = `${getBookingWebsiteUrl(organization)}/kiosk/waiver`;
  const reset = React.useCallback(() => {
    setEmailDaysBeforeParticipation(initialEmailDaysBeforeParticipation);
    setEmailsDisabled(initialEmailsDisabled);
  }, [initialEmailDaysBeforeParticipation, initialEmailsDisabled]);
  React.useEffect(() => {
    reset();
  }, [reset]);
  const pristine =
    initialEmailDaysBeforeParticipation === emailDaysBeforeParticipation &&
    initialEmailsDisabled === emailsDisabled;
  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tr>
              <th>{t('Waiver Page Link')}</th>
              <td>
                <a
                  className={styles['link']}
                  href={kioskUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {kioskUrl}
                </a>
                <Box mt={2} mb={2}>
                  <QRCode value={kioskUrl} />
                </Box>
              </td>
            </tr>
            <tr>
              <th>{t('Waiver Request Emails')}</th>
              <td>
                <Box mt={2} mb={2}>
                  <ToggleButton
                    label={t('Enable Automatic Waiver Emails')}
                    checked={!emailsDisabled}
                    onChange={() => setEmailsDisabled(!emailsDisabled)}
                  />
                </Box>
                {!emailsDisabled && (
                  <Select
                    label={t('Automatic Waiver Request Email Send Time')}
                    search
                    value={`${emailDaysBeforeParticipation}`}
                    onChange={(e, { value }) => {
                      setEmailDaysBeforeParticipation(parseInt(value));
                    }}
                    options={_.times(45, (i) => i + 1).map((day) => ({
                      key: day,
                      text: t('{{count}} days before participation', {
                        count: day,
                      }),
                      value: `${day}`,
                    }))}
                  />
                )}
              </td>
            </tr>
          </table>
        </FormTableBox>
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button
            style="gray"
            size="middle"
            disabled={pristine}
            onClick={reset}
          >
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            disabled={pristine}
            onClick={async () => {
              await dispatch(
                updateWaiverSettings({
                  waiver_request_days: emailDaysBeforeParticipation,
                  waiver_emails_disabled: emailsDisabled,
                })
              );
              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
