import { useTranslation } from 'react-i18next';
import ColorPicker from 'rc-color-picker';

import { Box } from 'client/components/Box/Box';

import styles from './ColorSelectCheckbox.module.css';

interface ColorSelectCheckboxProps {
  value: string;
  onChange: (value: string) => void;
  defaultColors: string[];
  vertical?: boolean;
}

export const ColorSelectCheckbox = (props: ColorSelectCheckboxProps) => {
  const { t } = useTranslation();

  const customColor = props.defaultColors.includes(props.value)
    ? '#FF0000'
    : props.value;

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={props.vertical ? 'column' : 'row'}
    >
      {props.defaultColors.map((color) => (
        <div
          key={color}
          className={styles['color-box']}
          onClick={() => {
            props.onChange(color);
          }}
        >
          <input type="checkbox" checked={color === props.value} readOnly />
          <label>
            <div
              style={{ backgroundColor: color }}
              className={styles['color-pane']}
            ></div>
          </label>
        </div>
      ))}
      <div
        className={styles['color-box']}
        onClick={() => {
          props.onChange(customColor);
        }}
      >
        <input
          type="checkbox"
          checked={!props.defaultColors.includes(props.value)}
          readOnly
        />
        <label>
          <Box display="flex">
            <div className={styles['color-custom-pane']}>
              <p>{t('Custom')}</p>
              <ColorPicker
                color={customColor}
                onChange={({ color }: any) => props.onChange(color)}
              />
            </div>
          </Box>
        </label>
      </div>
    </Box>
  );
};
