import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { ResourceAvailabilityModal } from 'client/pages/v3/Manifest/ManifestEditResource/Modal/ResourceAvailabilityModal';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import 'react-table/react-table.css';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ManifestCustomizeCustomTable } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomizeContents/ManifestCustomizeCustomTable';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';
import type { DispatchResource } from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { ResourceAvailabilityDisplayBox } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/ResourceAvailabilityDisplayBox';
import { ResourceEditTable } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/ResourceEditTable';
import { updateDispatchMiscResources } from 'client/actions/dispatchSettings';

type Props = {
  isActive?: boolean;
};

export const ResourceList = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const currentResources = useSelector(allDispatchMiscResourcesSelector);
  const [resources, setResources] = useState<DispatchResource[]>([]);
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const [showResourceAvailabilityModal, setShowResourceAvailabilityModal] =
    useState<boolean>(false);
  const [existingResource, setExistingResource] =
    useState<DispatchResource | null>(null);

  useEffect(() => {
    setResources(_.sortBy(currentResources, (r) => r.key));
  }, [currentResources]);

  const [editMode, setEditMode] = useState(false);
  // Close edit mode if active tab is changed
  useEffect(() => {
    if (!isActive) {
      setEditMode(false);
    }
  }, [isActive]);

  // Refetch data when edit mode is active
  useEffect(() => {
    if (editMode) {
      setResources(_.sortBy(currentResources, (r) => r.key));
    }
  }, [editMode]);

  const getColumns = () => {
    return [
      {
        Header: t('Name'),
        accessor: 'key',
        Cell: (cellInfo: any) => <>{cellInfo.value}</>,
        width: '240px',
      },
      {
        Header: t('Capacity'),
        accessor: 'capacity',
        width: '150px',
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },
    ];
  };

  const columns = getColumns();

  if (
    hasSubscription(activeOrganization, 'feature-resource-management') ||
    hasSubscription(
      activeOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    columns.push({
      Header: t('Resource & Products'),
      accessor: 'availability',
      Cell: (cellInfo: any) => (
        <div className={styles['p-manifests__vehicleResourceColumn']}>
          <ResourceAvailabilityDisplayBox resourceKey={cellInfo.original.key} />
          <Button
            text={t('Edit')}
            onClick={() => {
              setExistingResource(cellInfo.original);
              setShowResourceAvailabilityModal(true);
            }}
            color="white"
            iconBeforeText={<i className="c-icon-outline-general-edit-05"></i>}
          />
        </div>
      ),
    } as any);
  }

  return (
    <div
      className={clsx(
        styles['p-manifests__customize__body__content'],
        isActive && styles['is-active']
      )}
    >
      <div className={styles['p-manifests__customize__body__header']}>
        <p className={styles['p-manifests__customize__body__header__ttl']}>
          {t('Resources')}
        </p>
        <div
          className={styles['p-manifests__customize__body__header__actions']}
        >
          {editMode && (
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
          )}
          <Button
            text={editMode ? t('Save') : t('Edit')}
            onClick={() => {
              setEditMode(!editMode);
              if (editMode) {
                dispatch(updateDispatchMiscResources([...resources]));
              }
            }}
            color={editMode ? 'primary' : 'white'}
            iconBeforeText={
              editMode ? undefined : (
                <i className="c-icon-outline-general-edit-05"></i>
              )
            }
            // Disable button if crew key is empty in edit mode
            disabled={
              editMode &&
              resources.filter((r) => r.key === undefined).length > 0
            }
          />
        </div>
      </div>

      {showResourceAvailabilityModal && (
        <ResourceAvailabilityModal
          open={showResourceAvailabilityModal}
          onClose={() => {
            setShowResourceAvailabilityModal(false);
          }}
          resourceKey={existingResource?.key ?? ''}
        />
      )}
      {editMode && (
        <ResourceEditTable
          initialResources={resources}
          setResources={setResources}
        />
      )}

      {!editMode && (
        <ManifestCustomizeCustomTable
          itemName={t('Resource')}
          items={_.sortBy(currentResources, (r) => r.key)}
          columns={columns as any}
        />
      )}

      {editMode && (
        <div
          className={clsx(styles['p-manifests__fixed'], styles['is-active'])}
        >
          <div className={styles['p-manifests__fixed__main']}></div>
          <div className={styles['p-manifests__fixed__actions']}>
            <Button
              text={t('Cancel')}
              onClick={() => {
                setEditMode(false);
              }}
              color={'white'}
            />
            <Button
              text={t('Save')}
              onClick={async () => {
                await setLoading(true);
                if (editMode) {
                  await dispatch(updateDispatchMiscResources([...resources]));
                }
                await setLoading(false);
                await setEditMode(!editMode);
              }}
              color={'primary'}
              disabled={
                editMode &&
                resources.filter((r) => r.key === undefined).length > 0
              }
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};
