import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import _ from 'lodash';

import {
  getInstanceDedicatedAllotments,
  productInstanceAllChannelsAreClosed,
} from 'client/libraries/util/util';
import type { ProductInstance } from 'shared/models/swagger';

type ProductInstanceSupplierStatus =
  | 'closed'
  | 'partly closed'
  | 'fully booked'
  | 'available';
export type AgentAvailability = {
  agentId: string;
  agentName: string;
  isClosed: boolean;
  totalSlots: number;
  occupiedSlots: number;
};
export type AgentChannel = {
  type: 'AGENT';
  agentId: string;
  agentName: string;
};
export type GeneralChannel = {
  type: 'COMMON' | 'DIRECT_ALL' | 'RESELLER';
};
export type Channel = AgentChannel | GeneralChannel;
export type ProductInstanceShape = {
  rawInstance: ProductInstance;
  bookedSlots: number;
  totalSlots: number;
  weightedParticipantCount: number;
  closedChannels: Channel[];
  allChannelsClosed: boolean;
  requestDeadline: Moment | null;
  instantDeadline: Moment | null;
};
export const toProductInstanceShape = (
  instance: ProductInstance
): ProductInstanceShape => {
  const bookedSlots =
    (instance.occupied_slots || 0) +
    _.sumBy(
      getInstanceDedicatedAllotments(instance),
      (a) => a.occupied_slots || 0
    );

  const totalSlots =
    (instance.total_slots || 0) +
    _.sumBy(
      getInstanceDedicatedAllotments(instance),
      (a) => a.total_slots || 0
    );

  const weightedParticipantCount = instance.weighted_participant_count || 0;
  const closedChannels = getClosedChannels(instance);
  const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);
  const requestDeadline = getBookingDeadline(instance, 'REQUEST');
  const instantDeadline = getBookingDeadline(instance, 'INSTANT');
  return {
    rawInstance: instance,
    bookedSlots,
    totalSlots,
    weightedParticipantCount,
    closedChannels,
    allChannelsClosed,
    requestDeadline,
    instantDeadline,
  };
};

const getBookingDeadline = (
  instance: ProductInstance,
  confirmationType: 'INSTANT' | 'REQUEST'
): Moment | null => {
  const deadline = (instance.booking_deadlines || []).find(
    (deadline) => deadline.confirmation_type === confirmationType
  );

  if (deadline) {
    return moment(deadline.date_time_utc);
  }

  return null;
};

const getClosedChannels = (instance: ProductInstance): Channel[] => {
  const channels =
    (instance.per_channel_info && instance.per_channel_info.closed_channels) ||
    [];
  return channels.map((channel) => {
    switch (channel.channel_category) {
      case 'COMMON':
      case 'DIRECT_ALL':
      case 'RESELLER':
        return {
          type: channel.channel_category,
        };

      case 'AGENT':
        return {
          type: 'AGENT',
          agentId: channel.agent_id || '',
          agentName: channel.agent_name || '',
        };

      default:
        return {
          type: 'COMMON',
        };
    }
  });
};

const getProductInstanceSupplierStatus = (
  productInstance: ProductInstanceShape
): ProductInstanceSupplierStatus => {
  if (productInstance.allChannelsClosed) {
    return 'closed';
  }

  if (productInstance.closedChannels.length > 0) {
    return 'partly closed';
  }

  if (productInstance.totalSlots === productInstance.bookedSlots) {
    return 'fully booked';
  }

  return 'available';
};

// Linearly interpolate between color1 and color2 using ratio.
//  if ratio = 1, returns color1
//  if ratio = 0, returns color2
const interpolateColor = (
  color1: string,
  color2: string,
  ratio: number
): string => {
  const color1RGB = {
    r: parseInt(color1.substring(1, 3), 16),
    g: parseInt(color1.substring(3, 5), 16),
    b: parseInt(color1.substring(5, 7), 16),
  };
  const color2RGB = {
    r: parseInt(color2.substring(1, 3), 16),
    g: parseInt(color2.substring(3, 5), 16),
    b: parseInt(color2.substring(5, 7), 16),
  };
  const r = Math.round(color1RGB.r * ratio + color2RGB.r * (1 - ratio));
  const g = Math.round(color1RGB.g * ratio + color2RGB.g * (1 - ratio));
  const b = Math.round(color1RGB.b * ratio + color2RGB.b * (1 - ratio));
  const interpolated = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  return interpolated;
};

export const getProductInstanceColor = (
  productInstance: ProductInstanceShape
): string => {
  switch (getProductInstanceSupplierStatus(productInstance)) {
    case 'closed':
      return '#8e4404';

    case 'partly closed':
      return '#fbb437';

    case 'fully booked':
      return '#916bfb';

    default:
      return interpolateColor(
        '#916bfb',
        '#46d6db',
        productInstance.bookedSlots / productInstance.totalSlots
      );
  }
};
export const productInstanceIsRequestOnly = (
  instance: ProductInstance
): boolean => {
  //const { bookedSlots, instantDeadline, requestDeadline, totalSlots } =
  const { instantDeadline, requestDeadline } = toProductInstanceShape(instance);
  return (
    //bookedSlots >= totalSlots ||
    (instantDeadline === null || instantDeadline.isBefore(moment())) &&
    requestDeadline !== null &&
    requestDeadline.isAfter(moment())
  );
};
export const getProductInstanceDate = (instance: ProductInstance): string => {
  const startDateTimeLocal = instance.start_date_time_local;

  if (!startDateTimeLocal) {
    return '';
  }

  return startDateTimeLocal.split('T')[0];
};

type ColorClassName =
  | 'gray'
  | 'yellow'
  | 'red100'
  | 'red75'
  | 'red50'
  | 'red25'
  | '';

export const getAvailabilityBackgroundColorClass = (
  instance: ProductInstance,
  bookedSlots: number,
  totalSlots: number
): ColorClassName => {
  const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);

  if (instance.is_closed || allChannelsClosed) {
    return 'gray';
  }

  if (totalSlots === 0) {
    if (bookedSlots !== 0) {
      return 'yellow';
    }
  } else {
    if (bookedSlots >= totalSlots) {
      return 'red100';
    }

    const ratio = bookedSlots / totalSlots;

    if (ratio >= 0.75) {
      return 'red75';
    }

    if (ratio >= 0.5) {
      return 'red50';
    }

    if (ratio > 0) {
      return 'red25';
    }
  }

  return '';
};
export const getAvailabilityIconType = (
  instance: ProductInstance,
  bookedSlots: number,
  totalSlots: number,
  shouldRejectRequestBookingsBeyondCapacity: boolean
): 'STOP' | 'REQUEST' | 'NO_ICON' => {
  const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);

  if (instance.is_closed || allChannelsClosed) {
    return 'STOP';
  }

  if (
    (totalSlots === 0 || bookedSlots >= totalSlots) &&
    !shouldRejectRequestBookingsBeyondCapacity
  ) {
    return 'REQUEST';
  } else if (productInstanceIsRequestOnly(instance)) {
    return 'REQUEST';
  }

  return 'NO_ICON';
};
