import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import type { Review } from 'shared/models/swagger';

import styles from './ProductReviewsPane.module.css';

interface Props {
  review: Review;
}
export const ProductReviewSupplierComments = ({ review }: Props) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] =
    React.useState<boolean>(false);
  const commentsElemRef = React.useRef<HTMLParagraphElement | null>(null);
  const { t } = useTranslation();
  const supplierName =
    useSelector((state: ReduxState) => state.organizations.all).find(
      (org) => org.id === review.supplier_id
    )?.name ?? '';
  React.useEffect(() => {
    // Set this state inside useEffect() because refs are not guaranteed to be set on first render but they
    // will be set by the time this useEffect() callback runs.
    setShouldShowSeeMoreButton(
      commentsElemRef.current != null
        ? commentsElemRef.current.scrollHeight > 70
        : false
    );
  });

  if (!review.supplier_comments) {
    return null;
  }

  return (
    <div className={styles['c-review__list__comment__item__reply']}>
      <p className={styles['c-review__list__comment__item__reply__ttl']}>
        {t('Reply from {{supplierName}}', {
          supplierName,
        })}
      </p>
      <div className={styles['c-review__list__comment__item__reply__box']}>
        <p
          className={clsx(
            styles['c-review__list__comment__item__reply__message'],
            'newline',
            !showMore && styles['is-close']
          )}
          ref={commentsElemRef}
        >
          {review.supplier_comments}
        </p>
        {shouldShowSeeMoreButton && (
          <div className={styles['c-review__list__comment__item__reply__more']}>
            <a onClick={() => setShowMore(!showMore)}>
              {showMore ? t('Show Less') : t('See More')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
