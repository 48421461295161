import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { MessageModal } from 'client/components/v3/MessageModal/MessageModal';
import { sendInquiryEmail } from 'client/actions/inquiries';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Reservation } from 'shared/models/swagger';
import { TextField } from 'client/components/v3/Form/TextField';
import { ReservationActorInputForm } from 'client/components/v3/ReservationActorInputForm';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import { TextArea } from 'client/components/v3/Form/TextArea';
import {
  InquiryAttachment,
  InquiryAttachmentsEditor,
} from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/Inquiry/InquiryAttachmentsEditor';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

type FormValues = {
  subject: string;
  cc: string[];
  bcc: string[];
  message: string;
  email: string;
  attachments: InquiryAttachment[];
};
type Props = {
  reservation: Reservation | undefined;
  onClose: () => void;
  open: boolean;
};
export const SendInquiryEmailModal = ({
  onClose,
  open,
  reservation,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const org = useSelector(activeUserOrganizationSelector);
  const [showInsertMessageTemplate, setShowInsertMessageTemplate] =
    useState(false);
  const [step, setStep] = useState<'EDIT' | 'CONFIRM'>('EDIT');
  const [selectedMessageTemplateName, setSelectedMessageTemplateName] =
    useState('');
  const initialValues = useMemo((): FormValues => {
    const email =
      reservation?.field_responses?.find((response) => response.key === 'email')
        ?.response ?? '';
    return {
      subject: '',
      cc: [],
      bcc: [],
      message: '',
      email,
      attachments: [],
    };
  }, [reservation]);
  const validateEmail = useCallback(
    (email?: any) => {
      if (!email) return t('Required');

      if (!matchesFormat(email, 'email')) return t('Invalid Email');

      return undefined;
    },
    [t]
  );

  const { required } = getValidators(t);

  const hasMessageTemplates =
    (org?.inquiry_settings?.inquiry_message_templates ?? []).length > 0;
  const messageTemplateOptions = [
    {
      value: 'none',
      text: '',
    },
    ...(org?.inquiry_settings?.inquiry_message_templates ?? []).map(
      (template) => ({
        value: template.name ?? '',
        text: template.name ?? '',
      })
    ),
  ];
  const selectedMessageTemplate =
    org?.inquiry_settings?.inquiry_message_templates?.find(
      (template) => template.name === selectedMessageTemplateName
    );

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        switch (step) {
          case 'EDIT':
            setStep('CONFIRM');
            break;
          case 'CONFIRM':
            await dispatch(
              sendInquiryEmail({
                reservation_id: reservation?.id,
                email: values.email,
                subject: values.subject,
                body: values.message,
                cc: values.cc,
                bcc: values.bcc,
                attachments: values.attachments?.map((attachment) => ({
                  url: attachment.url,
                  filename: attachment.filename,
                })),
              })
            );

            onClose();
            break;
        }
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, submitting, form }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={t('Send Email')}
            style={{ width: '568px', height: '600px' }}
            open={open}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button color="white" text={t('Cancel')} onClick={onClose} />
                <Button type="submit" text={t('Send')} />
              </>
            }
          >
            <div className={styles['c-modal__content__body']}>
              {step === 'CONFIRM' && (
                <MessageModal
                  onClose={() => setStep('EDIT')}
                  title={t('Send Email')}
                  message={t('Are you sure you wish to send this email?')}
                  onSubmit={handleSubmit}
                  insertRoot
                  loading={submitting}
                />
              )}
              <div className={styles['p-contactEmail']}>
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('Email')}
                  </p>
                  <Field name="email" validate={validateEmail}>
                    {({ input, meta: { touched, error } }) => (
                      <TextField
                        type="text"
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        error={touched && error}
                        helperText={error}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['p-contactEmail__item']}>
                  <ReservationActorInputForm label={t('From')} />
                  <p className={styles['p-contactEmail__item__note']}>
                    {t(
                      'Please enter your name here. Your name will be shown in emails to guests.'
                    )}
                  </p>
                </div>
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('CC')}
                  </p>
                  <ListEditor name="cc" addButtonText={t('add CC')} />
                </div>
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('BCC')}
                  </p>
                  <ListEditor name="bcc" addButtonText={t('add BCC')} />
                </div>
                {hasMessageTemplates && (
                  <div className={styles['p-contactEmail__item']}>
                    <div
                      className={styles['p-contactEmail__item__templateToggle']}
                    >
                      <Toggle
                        label={t('Insert Message Template')}
                        checked={showInsertMessageTemplate}
                        onChange={() =>
                          setShowInsertMessageTemplate(
                            !showInsertMessageTemplate
                          )
                        }
                      />
                    </div>
                    {showInsertMessageTemplate && (
                      <div
                        className={
                          styles['p-contactEmail__item__templateToggle']
                        }
                      >
                        <SingleDropdown
                          options={messageTemplateOptions}
                          selectedOption={selectedMessageTemplateName}
                          onChange={async (e: any) => {
                            await setSelectedMessageTemplateName(
                              e.target.value
                            );
                            if (selectedMessageTemplate?.subject) {
                              form.change(
                                'subject',
                                selectedMessageTemplate.subject
                              );
                            }
                            form.change(
                              'message',
                              selectedMessageTemplate?.message
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('Subject')}
                  </p>
                  <Field name="subject" validate={required}>
                    {({ input, meta: { touched, error } }) => (
                      <TextField
                        value={input.value}
                        onChange={(value) => input.onChange(value)}
                        type="text"
                        error={touched && error}
                        helperText={error}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('Message')}
                  </p>
                  <Field name="message" validate={required}>
                    {({ input, meta: { touched, error } }) => (
                      <TextArea
                        value={input.value}
                        onChange={(_, { value }) => input.onChange(value)}
                        error={touched && error}
                        helperText={error}
                        height={120}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['p-contactEmail__item']}>
                  <p className={styles['p-contactEmail__item__ttl']}>
                    {t('Attachments')}
                  </p>
                  <Field name="attachments">
                    {({ input }) => {
                      return (
                        <InquiryAttachmentsEditor
                          attachments={input.value}
                          onChange={(newAttachments: InquiryAttachment[]) =>
                            input.onChange(newAttachments)
                          }
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
