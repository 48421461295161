import type { Organization, SourceLanguage } from 'shared/models/swagger';

import {
  getBookingWidgetApiKeyUrl,
  getBookingWidgetUrl,
} from './getBookingWidgetUrl';

// getBookingWebsiteUrl returns the booking website URL for the organization based on the following
// ordering:
// 1) PMP with original domain
// 2) PMP with NML domain
// 3) Booking widget with original domain
// 4) Booking widget with NML domain
// 5) Booking widget with API key
export const getBookingWebsiteUrl = (
  organization: Organization | null,
  language?: SourceLanguage
): string => {
  if (organization == null) {
    return '';
  }

  const privateMarketplaces =
    organization.private_marketplaces?.filter(
      (pmp) => !pmp.should_not_redirect_to_pmp_from_booking_widget
    ) ?? [];

  if (privateMarketplaces?.length > 0) {
    const pmp = language
      ? privateMarketplaces.find((pmp) => pmp.language === language)
      : privateMarketplaces[0];
    if (pmp) {
      if (pmp.custom_domain) {
        return `https://${pmp.custom_domain}/top`;
      }

      if (pmp.domain) {
        return `https://${pmp.domain}/top`;
      }
    }
  }

  if (organization.booking_widget_custom_domain) {
    return `https://${organization.booking_widget_custom_domain}`;
  }

  if (organization.booking_widget_subdomain) {
    return getBookingWidgetUrl(organization.booking_widget_subdomain);
  }

  if (organization.booking_widget_api_key) {
    return getBookingWidgetApiKeyUrl(organization.booking_widget_api_key);
  }

  return '';
};
export const getBookingWebsiteUrlWithParams = (
  organization: Organization | null,
  query?: {
    bg?: string;
    sh?: string;
    thm?: string;
    primary?: string;
    secondary?: string;
    hbg?: string;
    fbg?: string;
    cbg?: string;
    defCol?: string;
  }
) => {
  const bookingWebsiteUrl = getBookingWebsiteUrl(organization);
  const params = new URLSearchParams();

  if (query?.bg) {
    params.set('bg', query?.bg);
  }

  if (query?.sh) {
    params.set('sh', query?.sh);
  }

  if (query?.thm) {
    params.set('thm', query?.thm);
  }

  if (query?.primary) {
    params.set('primary', query?.primary);
  }

  if (query?.secondary) {
    params.set('secondary', query?.secondary);
  }

  if (query?.hbg) {
    params.set('hbg', query?.hbg);
  }

  if (query?.fbg) {
    params.set('fbg', query?.fbg);
  }

  if (query?.cbg) {
    params.set('cbg', query?.cbg);
  }

  if (query?.defCol) {
    params.set('defCol', query?.defCol);
  }

  return `${bookingWebsiteUrl}?${params.toString()}`;
};
