import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import type { ReduxState } from 'client/reducers';
import type { SiteControlWidgetParams } from 'shared/models/swagger';
import {
  FETCH_SITE_CONTROL_WIDGETS_REQUEST,
  FETCH_SITE_CONTROL_WIDGETS_SUCCESS,
  FETCH_SITE_CONTROL_WIDGETS_FAILURE,
  FETCH_SITE_CONTROL_WIDGET_REQUEST,
  FETCH_SITE_CONTROL_WIDGET_SUCCESS,
  FETCH_SITE_CONTROL_WIDGET_FAILURE,
  DELETE_SITE_CONTROL_WIDGET_REQUEST,
  DELETE_SITE_CONTROL_WIDGET_SUCCESS,
  DELETE_SITE_CONTROL_WIDGET_FAILURE,
  CREATE_SITE_CONTROL_WIDGET_REQUEST,
  CREATE_SITE_CONTROL_WIDGET_SUCCESS,
  CREATE_SITE_CONTROL_WIDGET_FAILURE,
  UPDATE_SITE_CONTROL_WIDGET_REQUEST,
  UPDATE_SITE_CONTROL_WIDGET_SUCCESS,
  UPDATE_SITE_CONTROL_WIDGET_FAILURE,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST,
  FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS,
} from 'client/constants/ActionTypes';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSiteControlWidgetsRequest = () => ({
  type: FETCH_SITE_CONTROL_WIDGETS_REQUEST,
});

const fetchSiteControlWidgetsSuccess = (response: any) => ({
  type: FETCH_SITE_CONTROL_WIDGETS_SUCCESS,
  response,
});

const fetchSiteControlWidgetsFailure = (error: any) => ({
  type: FETCH_SITE_CONTROL_WIDGETS_FAILURE,
  error,
});

// Note: in extranet we don't filter by statuses
// If we need to do so, follow fetchReviews and add params
export const fetchSiteControlWidgets =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSiteControlWidgetsRequest());
    axios
      .get('/api/sitecontrolwidgets', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchSiteControlWidgetsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSiteControlWidgetsFailure('canceled'));
        else dispatch(fetchSiteControlWidgetsFailure(error));
      });
  };

const fetchSiteControlWidgetRequest = () => ({
  type: FETCH_SITE_CONTROL_WIDGET_REQUEST,
});

const fetchSiteControlWidgetSuccess = (response: any) => ({
  type: FETCH_SITE_CONTROL_WIDGET_SUCCESS,
  response,
});

const fetchSiteControlWidgetFailure = (error: any) => ({
  type: FETCH_SITE_CONTROL_WIDGET_FAILURE,
  error,
});

export const fetchSiteControlWidget =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSiteControlWidgetRequest());
    axios
      .get(`/api/sitecontrolwidgets/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchSiteControlWidgetSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchSiteControlWidgetFailure('canceled'));
        else dispatch(fetchSiteControlWidgetFailure(error));
      });
  };

const createSiteControlWidgetRequest = (
  newSiteControlWidget: SiteControlWidgetParams
) => ({
  type: CREATE_SITE_CONTROL_WIDGET_REQUEST,
  newSiteControlWidget,
});

const createSiteControlWidgetSuccess = (response: any) => ({
  type: CREATE_SITE_CONTROL_WIDGET_SUCCESS,
  response,
});

const createSiteControlWidgetFailure = (error: any) => ({
  type: CREATE_SITE_CONTROL_WIDGET_FAILURE,
  error,
});

export const createSiteControlWidget =
  (newSiteControlWidget: SiteControlWidgetParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createSiteControlWidgetRequest(newSiteControlWidget));
    return axios
      .post('/api/sitecontrolwidgets', newSiteControlWidget, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createSiteControlWidgetSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createSiteControlWidgetFailure(err.message));
      });
  };

const updateSiteControlWidgetRequest = (
  id: string,
  patch: SiteControlWidgetParams
) => ({
  type: UPDATE_SITE_CONTROL_WIDGET_REQUEST,
  id,
  patch,
});

const updateSiteControlWidgetSuccess = (response: any) => ({
  type: UPDATE_SITE_CONTROL_WIDGET_SUCCESS,
  response,
});

const updateSiteControlWidgetFailure = (error: any) => ({
  type: UPDATE_SITE_CONTROL_WIDGET_FAILURE,
  error,
});

export const updateSiteControlWidget =
  (id: string, patch: SiteControlWidgetParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateSiteControlWidgetRequest(id, patch));
    return axios
      .patch(`/api/sitecontrolwidgets/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateSiteControlWidgetSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateSiteControlWidgetFailure(err.message));
      });
  };

const deleteSiteControlWidgetRequest = (id: string) => ({
  type: DELETE_SITE_CONTROL_WIDGET_REQUEST,
  id,
});

const deleteSiteControlWidgetSuccess = (response: any, id: string) => ({
  type: DELETE_SITE_CONTROL_WIDGET_SUCCESS,
  response,
  id,
});

const deleteSiteControlWidgetFailure = (error: any) => ({
  type: DELETE_SITE_CONTROL_WIDGET_FAILURE,
  error,
});

export const deleteSiteControlWidget =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteSiteControlWidgetRequest(id));
    fetch(`/api/sitecontrolwidgets/${id}`, {
      method: 'DELETE',
      headers: getHTTPRequestHeaders(getState()),
    })
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }

        return res.json();
      })
      .then((result) => dispatch(deleteSiteControlWidgetSuccess(result, id)))
      .catch((error) => dispatch(deleteSiteControlWidgetFailure(error)));
  };

const fetchSiteControlWidgetReportDataRequest = () => ({
  type: FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST,
});

const fetchSiteControlWidgetReportDataSuccess = (response: any) => ({
  type: FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS,
  response,
});

const fetchSiteControlWidgetReportDataFailure = (error: any) => ({
  type: FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE,
  error,
});

let fetchSiteControlWidgetReportDataCancel: () => void | typeof undefined;
export const fetchSiteControlWidgetReportData =
  (dateRanges: { start: string; end: string }[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchSiteControlWidgetReportDataCancel &&
      fetchSiteControlWidgetReportDataCancel();
    dispatch(fetchSiteControlWidgetReportDataRequest());

    axios
      .get('/api/sitecontrolwidgets/reportdata', {
        params: {
          date_ranges: dateRanges.map(
            (dateRange) => `${dateRange.start},${dateRange.end}`
          ),
        },
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchSiteControlWidgetReportDataCancel = c;
        }),
      })
      .then((result) =>
        dispatch(fetchSiteControlWidgetReportDataSuccess(result.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(fetchSiteControlWidgetReportDataFailure('canceled'));
        } else {
          dispatch(
            fetchSiteControlWidgetReportDataFailure(
              error.response.data.message || error.response.statusText
            )
          );
        }
      });
  };
