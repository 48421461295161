import * as React from 'react';

import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { MarketingAutomationCampaign } from 'shared/models/swagger';

import { CustomersModal } from './CustomersModal';

interface Props {
  campaign: MarketingAutomationCampaign;
}

export const TargetsButton = ({ campaign }: Props) => {
  const [showContactsModal, setShowContactsModal] = React.useState(false);

  return (
    <Box display="flex">
      <a
        className={baseStyles['base-link']}
        onClick={() => setShowContactsModal(true)}
      >
        {campaign.targeted_count}
      </a>
      {showContactsModal && (
        <CustomersModal
          campaign={campaign}
          onClose={() => setShowContactsModal(false)}
        />
      )}
    </Box>
  );
};
