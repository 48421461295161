import * as Swagger from 'shared/models/swagger';

export type TagColor =
  | 'GRAY'
  | 'ORANGE'
  | 'GREEN'
  | 'YELLOW'
  | 'BLUE'
  | 'PURPLE'
  | 'PINK'
  | 'RED';

export type RegistrationDateRangePreset = '' | 'REGISTRATION_DATE_CUSTOM';
export type LastParticipationDateRangePreset =
  | ''
  | 'LAST_30_DAYS'
  | 'LAST_90_DAYS'
  | 'LAST_180_DAYS'
  | 'LAST_PARTICIPATION_DATE_CUSTOM';

export type CustomerAutoTagCondition = {
  registrationDateRangePreset: RegistrationDateRangePreset;
  registrationDateRange?: {
    startDate: string;
    endDate: string;
  };
  lastParticipationDateRangePreset: LastParticipationDateRangePreset;
  lastParticipationDateRange?: {
    startDate: string;
    endDate: string;
  };
  productIds?: string[];
  manualTagIds?: string[];
  // TODO: area group
  gender: 'ANY' | 'MALE' | 'FEMALE' | 'UNSPECIFIED';
  ageRange?: {
    lowerBound: string;
    upperBound: string;
  };
  shouldReceiveSpecialEmailOffers?: boolean;
  alertedCustomer?: boolean;
  reservationCountRange?: {
    lowerBound: string;
    upperBound: string;
  };
  averageReservationAmountGrossRange?: {
    lowerBound: string;
    upperBound: string;
  };
  customerLtvRange?: {
    lowerBound: string;
    upperBound: string;
  };
};

export type CustomerAutoTag = {
  name: string;
  color: TagColor;
  conditions?: CustomerAutoTagCondition[];
};

export const getInitialValues = (): CustomerAutoTag => {
  return {
    name: '',
    color: 'GRAY',
    conditions: [
      {
        registrationDateRangePreset: 'REGISTRATION_DATE_CUSTOM',
        lastParticipationDateRangePreset: 'LAST_PARTICIPATION_DATE_CUSTOM',
        gender: 'ANY',
        productIds: [],
        manualTagIds: [],
      },
    ],
  };
};

export const convertFormValuesToOrganizationPatch = (
  organization: Swagger.Organization,
  tagIndex: number,
  tag: CustomerAutoTag
): Swagger.Organization$Patch => {
  const existingCustomerAutoTags =
    organization?.customer_ledger_settings?.customer_auto_tags ?? [];
  const newCustomerAutoTag = {
    name: tag.name,
    color: tag.color,
    conditions: tag.conditions?.map((condition) => {
      let lastParticipationDateRange;
      if (
        condition.lastParticipationDateRangePreset ===
        'LAST_PARTICIPATION_DATE_CUSTOM'
      ) {
        lastParticipationDateRange = condition.lastParticipationDateRange
          ? condition.lastParticipationDateRange.endDate === null ||
            condition.lastParticipationDateRange.endDate === null
            ? undefined // Set as undefined if we do not want to set date range
            : {
                start_date: condition.lastParticipationDateRange.startDate,
                end_date: condition.lastParticipationDateRange.endDate,
              }
          : undefined;
      }

      return {
        // TODO: for now set preset only to custom
        registration_date_range_preset:
          'REGISTRATION_DATE_CUSTOM' as RegistrationDateRangePreset,
        registration_date_range: condition.registrationDateRange
          ? condition.registrationDateRange.endDate === null ||
            condition.registrationDateRange.endDate === null
            ? undefined // Set as undefined if we do not want to set date range
            : {
                start_date: condition.registrationDateRange.startDate,
                end_date: condition.registrationDateRange.endDate,
              }
          : undefined,
        last_participation_date_range_preset:
          condition.lastParticipationDateRangePreset,
        last_participation_date_range: lastParticipationDateRange,
        product_ids: condition.productIds,
        manual_tag_ids: condition.manualTagIds,
        gender: condition.gender,
        age_range: condition.ageRange
          ? {
              lower_bound:
                condition.ageRange.lowerBound != null
                  ? parseInt(condition.ageRange.lowerBound)
                  : undefined,
              upper_bound:
                condition.ageRange.upperBound != null
                  ? parseInt(condition.ageRange.upperBound)
                  : undefined,
            }
          : undefined,
        should_receive_special_email_offers:
          condition.shouldReceiveSpecialEmailOffers,
        alerted_customer: condition.alertedCustomer,
        reservation_count_range: condition.reservationCountRange
          ? {
              lower_bound:
                condition.reservationCountRange.lowerBound != null
                  ? parseInt(condition.reservationCountRange.lowerBound)
                  : undefined,
              upper_bound:
                condition.reservationCountRange.upperBound != null
                  ? parseInt(condition.reservationCountRange.upperBound)
                  : undefined,
            }
          : undefined,
        average_reservation_amount_gross_range:
          condition.averageReservationAmountGrossRange
            ? {
                lower_bound:
                  condition.averageReservationAmountGrossRange.lowerBound,
                upper_bound:
                  condition.averageReservationAmountGrossRange.upperBound,
              }
            : undefined,
        customer_ltv_range: condition.customerLtvRange
          ? {
              lower_bound: condition.customerLtvRange.lowerBound,
              upper_bound: condition.customerLtvRange.upperBound,
            }
          : undefined,
      };
    }),
  };
  if (tagIndex < existingCustomerAutoTags.length) {
    existingCustomerAutoTags[tagIndex] = newCustomerAutoTag;
  } else {
    existingCustomerAutoTags.push(newCustomerAutoTag);
  }

  return {
    customer_ledger_settings: {
      ...organization.customer_ledger_settings,
      customer_auto_tags: [...existingCustomerAutoTags],
    },
  };
};

type CustomerLedgerSettings = Exclude<
  Swagger.Organization['customer_ledger_settings'],
  undefined
>;
export type SwaggerAutoTag = Exclude<
  CustomerLedgerSettings['customer_auto_tags'],
  undefined
>[0];

export const convertSwaggerTagToFormValues = (
  tag: SwaggerAutoTag
): CustomerAutoTag => {
  return {
    name: tag.name ?? '',
    color: tag.color as TagColor,
    conditions:
      tag.conditions?.map((condition) => ({
        registrationDateRangePreset:
          condition.registration_date_range_preset ?? '',
        registrationDateRange: condition.registration_date_range
          ? {
              startDate: condition.registration_date_range.start_date ?? '',
              endDate: condition.registration_date_range.end_date ?? '',
            }
          : undefined,
        lastParticipationDateRangePreset:
          condition.last_participation_date_range_preset ?? '',
        lastParticipationDateRange: condition.last_participation_date_range
          ? {
              startDate:
                condition.last_participation_date_range.start_date ?? '',
              endDate: condition.last_participation_date_range.end_date ?? '',
            }
          : undefined,
        productIds: condition.product_ids,
        manualTagIds: condition.manual_tag_ids,
        gender: condition.gender ?? 'ANY',
        ageRange: condition.age_range
          ? {
              lowerBound: condition.age_range.lower_bound
                ? `${condition.age_range.lower_bound}`
                : '',
              upperBound: condition.age_range.upper_bound
                ? `${condition.age_range.upper_bound}`
                : '',
            }
          : undefined,
        shouldReceiveSpecialEmailOffers:
          condition.should_receive_special_email_offers ?? false,
        alertedCustomer: condition.alerted_customer ?? false,
        reservationCountRange: condition.reservation_count_range
          ? {
              lowerBound: condition.reservation_count_range.lower_bound
                ? `${condition.reservation_count_range.lower_bound}`
                : '',
              upperBound: condition.reservation_count_range.upper_bound
                ? `${condition.reservation_count_range.upper_bound}`
                : '',
            }
          : undefined,
        averageReservationAmountGrossRange:
          condition.average_reservation_amount_gross_range
            ? {
                lowerBound: condition.average_reservation_amount_gross_range
                  .lower_bound
                  ? `${condition.average_reservation_amount_gross_range.lower_bound}`
                  : '',
                upperBound: condition.average_reservation_amount_gross_range
                  .upper_bound
                  ? `${condition.average_reservation_amount_gross_range.upper_bound}`
                  : '',
              }
            : undefined,
        customerLtvRange: condition.customer_ltv_range
          ? {
              lowerBound: condition.customer_ltv_range.lower_bound
                ? `${condition.customer_ltv_range.lower_bound}`
                : '',
              upperBound: condition.customer_ltv_range.upper_bound
                ? `${condition.customer_ltv_range.upper_bound}`
                : '',
            }
          : undefined,
      })) ?? [],
  };
};
