import { Field, useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Input, OptionalIntegerInput, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { getValidators } from 'shared/libraries/validate/validator';
import { ReduxState } from 'client/reducers';

import { DateFilter } from './formValues';

const OperatorInput = ({ name }: { name: string }) => {
  const { t } = useTranslation();

  const {
    input: { value: dateFilterValue },
  } = useField<DateFilter>(name);

  const { required } = getValidators(t);

  return (
    <Field name={`${name}.filterTypeOperator`} validate={required}>
      {({ input, meta: { touched, error } }) => (
        <Box ml={1}>
          <Select
            options={
              dateFilterValue?.filterType === 'DATE'
                ? [
                    {
                      text: t('Before'),
                      value: 'BEFORE',
                    },
                    {
                      text: t('Only On'),
                      value: 'ON',
                    },
                    {
                      text: t('After'),
                      value: 'AFTER',
                    },
                  ]
                : [
                    {
                      text: t('Before'),
                      value: 'BEFORE',
                    },
                    {
                      text: t('After'),
                      value: 'AFTER',
                    },
                  ]
            }
            value={input.value}
            onChange={(e, { value }) => {
              input.onChange(value);
            }}
            error={touched && error}
          />
        </Box>
      )}
    </Field>
  );
};

interface Props {
  name: string;
}

export const DateFilterEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const {
    input: { value: dateFilterValue },
  } = useField<DateFilter>(name);
  const form = useForm();

  const required = (value: any | null | undefined): string => {
    if (!value && value !== 0) return t('Required');
    return '';
  };

  return (
    <Box display="flex" mt={2} alignItems="flex-end">
      <Field name={`${name}.filterType`} validate={required}>
        {({ input, meta: { touched, error } }) => (
          <div>
            <div>{t('Condition')}</div>
            <Select
              options={[
                {
                  text: t('By Date'),
                  value: 'DATE',
                },
                {
                  text: t('By Number of Days'),
                  value: 'DAYS',
                },
              ]}
              value={input.value}
              onChange={(e, { value }) => {
                if (value === 'DATE') {
                  form.change(`${name}.value`, '');
                  form.change(`${name}.filterTypeOperator`, 'BEFORE');
                  form.change(`${name}.filterTypeQualifier`, '');
                } else {
                  form.change(`${name}.value`, '10');
                  form.change(`${name}.filterTypeOperator`, 'BEFORE');
                  form.change(`${name}.filterTypeQualifier`, 'DAYS_AGO');
                }

                input.onChange(value);
              }}
              error={touched && error}
            />
          </div>
        )}
      </Field>
      {locale !== 'ja' && <OperatorInput name={name} />}
      {dateFilterValue?.filterType === 'DATE' && (
        <Field name={`${name}.value`} validate={required}>
          {({ input, meta: { touched, error } }) => (
            <Box ml={1}>
              <div>{t('Value')}</div>
              <Input
                value={input.value}
                onChange={input.onChange}
                type="date"
                error={touched && error}
              />
            </Box>
          )}
        </Field>
      )}
      {dateFilterValue?.filterType === 'DAYS' && (
        <>
          <Field name={`${name}.value`} validate={required}>
            {({ input, meta: { error, touched } }) => (
              <Box ml={1}>
                <OptionalIntegerInput
                  label=""
                  value={input.value}
                  onChange={(newValue) => input.onChange(newValue)}
                  error={touched && error}
                  disallowNegative
                />
              </Box>
            )}
          </Field>
          <Field name={`${name}.filterTypeQualifier`} validate={required}>
            {({ input, meta: { error, touched } }) => (
              <Box ml={1}>
                <Select
                  value={input.value}
                  options={[
                    {
                      value: 'DAYS_AGO',
                      text: t('days ago'),
                    },
                    {
                      value: 'DAYS_FROM_NOW',
                      text: t('days from now'),
                    },
                  ]}
                  onChange={(e, { value }) => {
                    input.onChange(value);
                  }}
                  error={touched && error}
                />
              </Box>
            )}
          </Field>
        </>
      )}
      {locale === 'ja' && <OperatorInput name={name} />}
    </Box>
  );
};
