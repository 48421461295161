import * as React from 'react';

import { Highlights } from './Highlights';
import { Section } from './util';
import { ImageGallery } from './ImageGallery';
import { Navigation } from './Navigation';
import { Tiles } from './Tiles';
import { Product } from './Product';
import { Promotion } from './Promotion';
import { SocialMedia } from './SocialMedia';

type Props = {
  data: Section;
  language?: string;
  newsletterCampaignId?: string;
};

export const SectionView = ({
  data,
  language,
  newsletterCampaignId,
}: Props) => {
  const Content = () => {
    switch (data.type) {
      case 'PRODUCT':
        return (
          <Product
            data={data}
            language={language ?? ''}
            newsletterCampaignId={newsletterCampaignId ?? 'todo'}
          />
        );
      case 'TILES':
        return <Tiles data={data} />;
      case 'NAVIGATION':
        return <Navigation data={data} />;
      case 'IMAGE_GALLERY':
        return <ImageGallery data={data} />;
      case 'HIGHLIGHTS':
        return <Highlights data={data} />;
      case 'PROMOTION':
        return <Promotion data={data} />;
      case 'SOCIAL_MEDIA':
        return <SocialMedia data={data} />;
      default:
        return <Tiles data={data} />;
    }
  };

  return <Content />;
};
