import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  BATCH_UPDATE_GUIDANCE_PAGE_FAILURE,
  BATCH_UPDATE_GUIDANCE_PAGE_REQUEST,
  BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS,
  FETCH_GUIDANCE_PAGES_SUCCESS,
  FETCH_GUIDANCE_PAGES_FAILURE,
  FETCH_GUIDANCE_PAGES_REQUEST,
  DELETE_GUIDANCE_PAGE_FAILURE,
  DELETE_GUIDANCE_PAGE_REQUEST,
  DELETE_GUIDANCE_PAGE_SUCCESS,
  CREATE_GUIDANCE_PAGE_REQUEST,
  UPDATE_GUIDANCE_PAGE_FAILURE,
  UPDATE_GUIDANCE_PAGE_SUCCESS,
  UPDATE_GUIDANCE_PAGE_REQUEST,
  CREATE_GUIDANCE_PAGE_FAILURE,
  CREATE_GUIDANCE_PAGE_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import { GuidancePage, GuidancePageParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchGuidancePagesRequest = () => ({
  type: FETCH_GUIDANCE_PAGES_REQUEST,
});

const fetchGuidancePagesSuccess = (response: any) => ({
  type: FETCH_GUIDANCE_PAGES_SUCCESS,
  response,
});

const fetchGuidancePagesFailure = (error: string) => ({
  type: FETCH_GUIDANCE_PAGES_FAILURE,
  error,
});

let fetchGuidancePagesCancel: () => void | null;
export const fetchGuidancePages =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchGuidancePagesCancel && fetchGuidancePagesCancel();
    dispatch(fetchGuidancePagesRequest());
    axios
      .get('/api/guidancepages', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchGuidancePagesCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchGuidancePagesSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchGuidancePagesFailure('canceled'));
        else dispatch(fetchGuidancePagesFailure(error));
      });
  };

const deleteGuidancePageRequest = (id: string) => ({
  type: DELETE_GUIDANCE_PAGE_REQUEST,
  id,
});
const deleteGuidancePageSuccess = (response: any, id: string) => ({
  type: DELETE_GUIDANCE_PAGE_SUCCESS,
  response,
  id,
});
const deleteGuidancePageFailure = (error: string) => ({
  type: DELETE_GUIDANCE_PAGE_FAILURE,
  error,
});
export const deleteGuidancePage =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteGuidancePageRequest(id));
    axios
      .delete(`/api/guidancepages/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteGuidancePageSuccess(result, id)))
      .catch((error) => dispatch(deleteGuidancePageFailure(error)));
  };

const createGuidancePageRequest = (newGuidancePage: GuidancePageParams) => ({
  type: CREATE_GUIDANCE_PAGE_REQUEST,
  newGuidancePage,
});
const createGuidancePageSuccess = (response: any) => ({
  type: CREATE_GUIDANCE_PAGE_SUCCESS,
  response,
});
const createGuidancePageFailure = (error: string) => ({
  type: CREATE_GUIDANCE_PAGE_FAILURE,
  error,
});

export const createGuidancePage =
  (newGuidancePage: GuidancePageParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(createGuidancePageRequest(newGuidancePage));
    return axios
      .post('/api/guidancepages', newGuidancePage, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(createGuidancePageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createGuidancePageFailure(err.message));
      });
  };

const updateGuidancePageRequest = (id: string, patch: GuidancePageParams) => ({
  type: UPDATE_GUIDANCE_PAGE_REQUEST,
  id,
  patch,
});
const updateGuidancePageSuccess = (response: any) => ({
  type: UPDATE_GUIDANCE_PAGE_SUCCESS,
  response,
});
const updateGuidancePageFailure = (error: string) => ({
  type: UPDATE_GUIDANCE_PAGE_FAILURE,
  error,
});

export const updateGuidancePage =
  (id: string, patch: GuidancePageParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateGuidancePageRequest(id, patch));
    return axios
      .patch(`/api/guidancepages/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(updateGuidancePageSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateGuidancePageFailure(err.message));
      });
  };

const batchUpdateGuidancePagesRequest = () => ({
  type: BATCH_UPDATE_GUIDANCE_PAGE_REQUEST,
});

const batchUpdateGuidancePagesFailure = (error: string) => ({
  type: BATCH_UPDATE_GUIDANCE_PAGE_FAILURE,
  error,
});

const batchUpdateGuidancePagesSuccess = (responses: any[]) => ({
  type: BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS,
  responses,
});

export const batchUpdateGuidancePages =
  (guidancePages: GuidancePage[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchUpdateGuidancePagesRequest());
    const promises = guidancePages.map((guidancePage) =>
      axios
        .patch(`/api/guidancepages/${guidancePage.id}`, guidancePage, {
          headers: getHTTPRequestHeaders(getState()),
        })
        .then((result) => result.data)
    );
    return Promise.all(promises)
      .then((responses) => dispatch(batchUpdateGuidancePagesSuccess(responses)))
      .catch((error) =>
        dispatch(batchUpdateGuidancePagesFailure(error.response.data.message))
      );
  };
