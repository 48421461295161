import * as React from 'react';
import { Link, Redirect, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { config } from 'client/config';
import { NotificationList } from 'client/components/NotificationList/NotificationList';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { CategoryHeader } from 'client/components/CategoryHeader/CategoryHeader';
import { NavigationMenu } from 'client/components/NavigationMenu/NavigationMenu';
import { isSignedIn } from 'client/libraries/cognito';
import { LanguageSelect } from 'client/components/LanguageSelect/LanguageSelect';
import { ImpersonationSelect } from 'client/components/ImpersonationSelect/ImpersonationSelect';
import { AlternateOrganizationSelect } from 'client/components/AlternateOrganizationSelect/AlternateOrganizationSelect';
import { AccountDropdown } from 'client/components/AccountDropdown/AccountDropdown';
import {
  impersonatedAccountSelector,
  loggedInAccountSelector,
  activeAccountSelector,
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { fetchAccounts } from 'client/actions/accounts';
import {
  fetchOrganizations,
  refreshOrganizations,
} from 'client/actions/organizations';
import { impersonationEnabledForAccount } from 'shared/models/access';
import { Button } from 'client/components/Form';
import { disableOrganizationPolling } from 'client/debugging';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import type { ReduxState } from 'client/reducers';
import newWindowIcon from 'client/images/ic_newwindow.svg';
import logo from 'client/images/logo.svg';
import humanIcon from 'client/images/ic_human.svg';
import styles from 'client/base.module.css';

import { ReservationNotificationBell } from './ReservationNotificationBell/ReservationNotificationBell';

type Props = {
  children: React.ReactNode;
  hideMenu?: boolean;
  hideFrame?: boolean;
  path?: string;
  exact?: boolean;
  strict?: boolean;
};
export const PrivateRoute = ({
  children,
  hideMenu,
  hideFrame,
  path,
  exact,
  strict,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const accountMenuRef = React.useRef<HTMLDivElement | null>(null);
  const navMenuRef = React.useRef<any>();
  const contentPaneRef = React.useRef<HTMLElement | null>(null);
  const isAuthenticated = useSelector((state: ReduxState) =>
    isSignedIn(state.user.cognito)
  );
  const loggedInAccount = useSelector(loggedInAccountSelector);
  const activeAccount = useSelector(activeAccountSelector);
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const impersonatedUserAccount = useSelector(impersonatedAccountSelector);
  const [navBarIsExpanded, setNavBarIsExpanded] = React.useState<boolean>(true);
  const [mobileAccountMenuIsOpen, setMobileAccountMenuIsOpen] =
    React.useState<boolean>(false);
  const [mobileNavMenuIsOpen, setMobileNavMenuIsOpen] =
    React.useState<boolean>(false);
  const [headerOverride, setHeaderOverride] = React.useState<string>('');
  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAccounts());
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrganizations());

      if (activeUser?.role !== 'nutmeg_admin' && !disableOrganizationPolling) {
        // Refresh organizations every 15 seconds to keep subscription info up to date
        const intervalId = setInterval(
          () => dispatch(refreshOrganizations()),
          config.settingsPollingInterval
        );

        if (intervalId) {
          return () => clearInterval(intervalId);
        }
      }
    }
  }, [isAuthenticated, activeUser, impersonatedUserAccount]);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (contentPaneRef.current) {
      contentPaneRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (
        mobileAccountMenuIsOpen &&
        target instanceof Node &&
        !accountMenuRef?.current?.contains(target)
      ) {
        setMobileAccountMenuIsOpen(false);
      }

      if (
        mobileNavMenuIsOpen &&
        target instanceof Node &&
        !navMenuRef?.current?.contains(target)
      ) {
        setMobileNavMenuIsOpen(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [mobileAccountMenuIsOpen, mobileNavMenuIsOpen]);
  React.useEffect(() => {
    // Close mobile menu if we clicked a link
    setMobileAccountMenuIsOpen(false);
    setMobileNavMenuIsOpen(false);
  }, [location.pathname]);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    );
  }

  const bookingWebsiteUrl = getBookingWebsiteUrl(activeUserOrganization);
  const tutorialCompleted =
    !activeUserOrganization?.tutorial_stage ||
    activeUserOrganization?.tutorial_stage === 'COMPLETED';
  const showToHelpTopButton = !location.pathname.startsWith('/helps');
  return (
    <Route path={path} exact={exact} strict={strict}>
      <ScrollToContext.Provider
        value={(x: number, y: number) => {
          if (contentPaneRef.current) {
            contentPaneRef.current.scrollTo(x, y);
          }
        }}
      >
        <PageHeaderOverrideContext.Provider
          value={{
            headerOverride,
            setHeaderOverride,
          }}
        >
          {hideFrame ? (
            <>{children}</>
          ) : (
            <div className={styles['base-wrap']}>
              <header className={styles['base-header']}>
                <div
                  className={clsx(
                    styles['base-header__spMenu'],
                    mobileNavMenuIsOpen ? styles['is-open'] : styles['is-close']
                  )}
                  onClick={() => {
                    setMobileAccountMenuIsOpen(false);
                    setMobileNavMenuIsOpen(!mobileNavMenuIsOpen);
                  }}
                >
                  <span></span>
                </div>
                <Link className={styles['base-header__logo']} to="/">
                  <img src={logo} />
                </Link>
                <ReservationNotificationBell />
                <div
                  className={clsx(
                    styles['base-header__spInfo'],
                    mobileAccountMenuIsOpen
                      ? styles['is-open']
                      : styles['is-close']
                  )}
                >
                  <div
                    className={styles['base-header__spAccount']}
                    onClick={() => {
                      setMobileNavMenuIsOpen(false);
                      setMobileAccountMenuIsOpen(!mobileAccountMenuIsOpen);
                    }}
                  >
                    <p>
                      <img src={humanIcon} />
                    </p>
                  </div>
                  <div
                    className={styles['base-header__info']}
                    ref={accountMenuRef}
                  >
                    {bookingWebsiteUrl && tutorialCompleted && (
                      <div className={styles['base-header__info__btn']}>
                        <a
                          className={clsx(
                            styles['base-btn'],
                            styles['middle'],
                            styles['blue']
                          )}
                          href={bookingWebsiteUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p>{t('Go To Booking Site')}</p>
                          <img src={newWindowIcon} />
                        </a>
                      </div>
                    )}
                    {showToHelpTopButton && (
                      <div className={styles['base-header__info__btn']}>
                        <Link to="/helps/home" target="_blank">
                          <Button size="middle" style="blue">
                            <p>{t('Go To Help Page')}</p>
                            <img src={newWindowIcon} />
                          </Button>
                        </Link>
                      </div>
                    )}
                    <LanguageSelect />
                    {
                      // Impersonated user needs to use 'activeAccount' so we can switch the 'activeUser'
                      impersonationEnabledForAccount(loggedInAccount) && (
                        <ImpersonationSelect />
                      )
                    }
                    {activeAccount?.alternate_organization_id && (
                      <AlternateOrganizationSelect />
                    )}
                    <AccountDropdown />
                  </div>
                </div>
              </header>
              {!hideMenu && (
                <NavigationMenu
                  ref={navMenuRef}
                  isExpanded={navBarIsExpanded}
                  onIsExpandedChange={setNavBarIsExpanded}
                  mobileNavIsOpen={mobileNavMenuIsOpen}
                />
              )}
              <main
                className={clsx(
                  styles['base-main'],
                  navBarIsExpanded ? styles['is-open'] : styles['is-close']
                )}
                ref={contentPaneRef}
              >
                <div id="reservation">
                  <CategoryHeader />
                  <div className={styles['base-main__body']}>{children}</div>
                </div>
              </main>
              <NotificationList />
            </div>
          )}
        </PageHeaderOverrideContext.Provider>
      </ScrollToContext.Provider>
    </Route>
  );
};
