import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';

import styles from './ProductTutorialHelpPopUp.module.css';

type Props = {
  onClose: (arg0: boolean) => void;
};

export const ProductTutorialHelpPopUp = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.products.loading);

  return (
    <div className={styles['snackbar-container']}>
      <div className={clsx(styles['snackbar'])}>
        <a
          onClick={() => {
            onClose(false);
          }}
          className={clsx(styles['snackbar-close'])}
        />
        <Box
          display="flex"
          justifyContent="center"
          ml={1}
          mt={2}
          mb={2}
          className={styles['snackbar-title']}
        >
          {t('See Help Pages')}
        </Box>
        <Box display="flex" justifyContent="center" ml={1} mb={2}>
          {t(
            'Please see the articles on Help pages for creating new products.'
          )}
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/helps/products/create-product"
          >
            <Button size="middle" style="blue">
              {t('See help page articles')}
            </Button>
          </a>
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <Button size="middle" style="blue" type="submit" loading={loading}>
            {t('Go to next step')}
          </Button>
        </Box>
      </div>
    </div>
  );
};
