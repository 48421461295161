import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { FocusableInput } from 'client/components/Form';
import { ProductIdRecurrenceKeyTimeSlotKeyListInput } from 'client/components/ProductIdRecurrenceKeyTimeSlotKeyListInput/ProductIdRecurrenceKeyTimeSlotKeyListInput';
import { Box } from 'client/components/Box/Box';

interface Props {
  name: string;
}

export const StartTimeForm = ({ name }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex">
        <Box mr={4}>
          <div>{t('Start Time')}</div>
          <Field name={`${name}.startTimeLocal`}>
            {({ input }) => (
              <TimePicker
                allowEmpty={false}
                value={moment(input.value, 'HH:mm')}
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            )}
          </Field>
        </Box>
      </Box>

      <Box mt={4}>
        <div>{t('Products')}</div>
        <Field name={`${name}.productStartTimes`}>
          {({ input, meta: { error, touched } }) => (
            <>
              <ProductIdRecurrenceKeyTimeSlotKeyListInput
                productIdRecurrenceKeyTimeSlotKeys={input.value}
                onChange={(newProductStartTimes) =>
                  input.onChange(newProductStartTimes)
                }
                error={touched ? error : undefined}
                filter={(product) =>
                  !product.booking_widget_settings?.is_free_start_date_time
                }
              />
              <FocusableInput name="productIds" />
            </>
          )}
        </Field>
      </Box>
    </>
  );
};
