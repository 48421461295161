import { TranslateFuncType } from 'client/components/Translate';

export const getBookingStatusOptions = (
  t: TranslateFuncType
): {
  key: string;
  text: string;
  value: string;
}[] => {
  return [
    {
      key: 'REQUESTED',
      text: t('Requested'),
      value: 'REQUESTED',
    },
    {
      key: 'STANDBY',
      text: t('Standby'),
      value: 'STANDBY',
    },
    {
      key: 'CONFIRMED',
      text: t('Confirmed'),
      value: 'CONFIRMED',
    },
    {
      key: 'WITHDRAWN_BY_AGENT',
      text: t('Withdrawn by agent'),
      value: 'WITHDRAWN_BY_AGENT',
    },
    {
      key: 'DECLINED_BY_SUPPLIER',
      text: t('Declined by supplier'),
      value: 'DECLINED_BY_SUPPLIER',
    },
    {
      key: 'CANCELED_BY_AGENT',
      text: t('Canceled by agent'),
      value: 'CANCELED_BY_AGENT',
    },
    {
      key: 'CANCELED_BY_GUEST',
      text: t('Canceled by guest'),
      value: 'CANCELED_BY_GUEST',
    },
    {
      key: 'CANCELED_BY_SUPPLIER',
      text: t('Canceled by supplier'),
      value: 'CANCELED_BY_SUPPLIER',
    },
  ];
};
