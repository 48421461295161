import { combineReducers } from 'redux';

import {
  BATCH_GET_TRANSLATIONS_FAILURE,
  BATCH_GET_TRANSLATIONS_REQUEST,
  BATCH_GET_TRANSLATIONS_SUCCESS,
  FETCH_PRODUCT_TRANSLATIONS_FAILURE,
  FETCH_PRODUCT_TRANSLATIONS_REQUEST,
  FETCH_PRODUCT_TRANSLATIONS_SUCCESS,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST,
  FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST,
  FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS,
  UPDATE_TRANSLATIONS_FAILURE,
  UPDATE_TRANSLATIONS_REQUEST,
  UPDATE_TRANSLATIONS_SUCCESS,
  GET_TRANSLATIONS_FOR_TEXTS_FAILURE,
  GET_TRANSLATIONS_FOR_TEXTS_REQUEST,
  GET_TRANSLATIONS_FOR_TEXTS_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  GetTranslationsForTextsResponse,
  Translation,
} from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    case UPDATE_TRANSLATIONS_REQUEST:
    case GET_TRANSLATIONS_FOR_TEXTS_REQUEST:
    case BATCH_GET_TRANSLATIONS_REQUEST:
    case FETCH_PRODUCT_TRANSLATIONS_REQUEST:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST:
      return true;

    case UPDATE_TRANSLATIONS_FAILURE:
    case UPDATE_TRANSLATIONS_SUCCESS:
    case GET_TRANSLATIONS_FOR_TEXTS_FAILURE:
    case GET_TRANSLATIONS_FOR_TEXTS_SUCCESS:
    case BATCH_GET_TRANSLATIONS_FAILURE:
    case BATCH_GET_TRANSLATIONS_SUCCESS:
    case FETCH_PRODUCT_TRANSLATIONS_FAILURE:
    case FETCH_PRODUCT_TRANSLATIONS_SUCCESS:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case UPDATE_TRANSLATIONS_FAILURE:
    case GET_TRANSLATIONS_FOR_TEXTS_FAILURE:
    case BATCH_GET_TRANSLATIONS_FAILURE:
    case FETCH_PRODUCT_TRANSLATIONS_FAILURE:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE:
      return action.error;

    case UPDATE_TRANSLATIONS_SUCCESS:
    case GET_TRANSLATIONS_FOR_TEXTS_SUCCESS:
    case BATCH_GET_TRANSLATIONS_SUCCESS:
    case FETCH_PRODUCT_TRANSLATIONS_SUCCESS:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const all = (state: Translation[] = [], action: any): Translation[] => {
  switch (action.type) {
    case BATCH_GET_TRANSLATIONS_SUCCESS:
    case FETCH_PRODUCT_TRANSLATIONS_SUCCESS:
    case FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS:
    case FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS:
      return action.response;

    case UPDATE_TRANSLATIONS_SUCCESS:
      return [
        ...state.filter(
          (trans: Translation) =>
            !action.response.find(
              (responseTrans: Translation) =>
                responseTrans.source_language === trans.source_language &&
                responseTrans[
                  responseTrans.source_language?.toLowerCase() as keyof Translation
                ] ===
                  trans[
                    responseTrans.source_language?.toLowerCase() as keyof Translation
                  ]
            )
        ),
        ...action.response,
      ];

    default:
      return state;
  }
};

const lastUpdateStatus = (state = '', action: any) => {
  switch (action.type) {
    case UPDATE_TRANSLATIONS_REQUEST:
      return 'REQUESTED';

    case UPDATE_TRANSLATIONS_SUCCESS:
      return 'SUCCEEDED';

    case UPDATE_TRANSLATIONS_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

const forTexts = (state = [], action: any) => {
  switch (action.type) {
    case GET_TRANSLATIONS_FOR_TEXTS_SUCCESS: {
      const resp = action.response as any as GetTranslationsForTextsResponse;
      return resp.translations;
    }
    default:
      return state;
  }
};

export const translations = combineReducers({
  all,
  error,
  forTexts,
  lastUpdateStatus,
  loading,
});
