import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslationTableHeader } from 'client/components/TranslationTableHeader/TranslationTableHeader';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import type { ReduxState } from 'client/reducers';

import styles from '../DetailsStep.module.css';

export const InternalTagsEditor = () => {
  const { t } = useTranslation();
  const targetTranslationLanguage = React.useContext(
    TranslationLanguageContext
  );
  const internalTagSuggestions = useSelector(
    (state: ReduxState) => state.productTags.internalTags
  );
  return (
    <div className={styles['c-table-list']}>
      <table>
        <tbody>
          {targetTranslationLanguage && <TranslationTableHeader />}
          <tr>
            <th>{t('Tags')}</th>
            <td colSpan={2}>
              <ListEditor
                name="internalTags"
                suggestions={internalTagSuggestions}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
