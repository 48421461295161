import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Input, Select, TextArea } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { issueInvoice } from 'client/actions/invoices';

type Props = {
  invoiceId: string;
  includeDetails: boolean;
  buttonSize?: 'small' | 'middle' | 'tiny';
};

export const BillingInfoFormModal = ({
  invoiceId,
  includeDetails,
  buttonSize,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);
  const [payeeReference, setPayeeReference] = React.useState('');
  const [paymentMethodDescription, setPaymentMethodDescription] =
    React.useState('');
  const [paymentMethod, setPaymentMethod] = React.useState('');
  const [remarks, setRemarks] = React.useState('');

  const setDefaultFields = () => {
    setShowModal(false);
    setPayeeReference('');
    setPaymentMethodDescription('');
    setPaymentMethod('');
    setRemarks('');
  };
  const getPaymentMethodTypes = () => {
    return [
      {
        key: 'CREDIT_CARD',
        text: t('Credit Card'),
        value: 'CREDIT_CARD',
      },
      {
        key: 'DEBIT_CARD',
        text: t('Debit Card'),
        value: 'DEBIT_CARD',
      },
      {
        key: 'CASH',
        text: t('Cash'),
        value: 'CASH',
      },
      {
        key: 'BANK',
        text: t('Bank'),
        value: 'BANK',
      },
      {
        key: 'CHECK',
        text: t('Check'),
        value: 'CHECK',
      },
      {
        key: 'OTHER',
        text: t('Other'),
        value: 'OTHER',
      },
    ];
  };
  const getPaymentMethodPlaceholder = () => {
    switch (paymentMethod) {
      case 'CREDIT_CARD':
      case 'DEBIT_CARD':
        return t("e.g. 'Mastercard ending in 1234'");

      case 'BANK':
        return t("e.g. 'Account number 0123456789'");

      case 'CHECK':
        return t("e.g. 'Pay to the order of John Doe'");

      case 'CASH':
      case 'OTHER':
        return t('');

      default:
        return t('Enter Here...');
    }
  };

  const paymentMethodTypeOptions = getPaymentMethodTypes();
  return (
    <>
      <Button
        size={buttonSize ?? 'middle'}
        style="blue"
        onClick={() => setShowModal(true)}
      >
        {t('Issue')}
      </Button>
      <Modal
        insertRoot
        open={showModal}
        title={t('Issue an Invoice')}
        onClose={setDefaultFields}
      >
        <Modal.Content>
          <Box mb={2}>
            <Input
              value={payeeReference}
              label={t('Invoice Reference #')}
              placeholder={t('Please enter your invoice reference # here.')}
              required
              onChange={(e, { value }) => {
                setPayeeReference(value);
              }}
            />
          </Box>
          <Box mb={2}>
            <Select
              required
              label={t('Choose Payment Method')}
              placeholder={t('Select Type...')}
              value={paymentMethod}
              onChange={(e, { value }) => {
                setPaymentMethod(value);
              }}
              options={paymentMethodTypeOptions}
            />
          </Box>
          <Box mb={2}>
            <Input
              value={paymentMethodDescription}
              label={t('Payment Method Description')}
              placeholder={getPaymentMethodPlaceholder()}
              required={
                !(paymentMethod === 'CASH' || paymentMethod === 'OTHER')
              }
              onChange={(e, { value }) => {
                setPaymentMethodDescription(value);
              }}
            />
          </Box>
          <Box mb={2}>
            <TextArea
              value={remarks}
              label={t('Additional Notes')}
              placeholder={t('Enter Here...')}
              onChange={(e, { value }) => {
                setRemarks(value);
              }}
            />
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Button.Cancel onClick={setDefaultFields}>{t('Back')}</Button.Cancel>
          <Button.Submit
            disabled={
              (!paymentMethodDescription &&
                !(paymentMethod === 'CASH' || paymentMethod === 'OTHER')) ||
              !paymentMethod
            }
            onClick={() =>
              dispatch(
                issueInvoice(
                  invoiceId,
                  payeeReference,
                  includeDetails,
                  paymentMethodDescription,
                  paymentMethod,
                  remarks
                )
              )
            }
          >
            {t('Issue')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    </>
  );
};
