import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import type { ReservationReportSettings } from 'client/components/ReservationReportSettingsModal/ReservationReportSettingsModal';
import { ModalLoader } from 'client/components/ModalLoader';
import {
  agentOptionsSelector,
  getBookingSourceOptions,
} from 'client/components/ReservationSearchSettingsModal/util';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { productOptionsSelector } from 'client/reducers/products';
import { getDateFilterPresetText } from 'client/components/ReservationReportSettingsModal/ReservationReportSettingsModal';

import styles from './ReservationReport.module.css';

type Props = {
  reservationReportSettings: ReservationReportSettings;
  fetchReservationReportDataStatus?: string;
};
export const ReservationReportSettingsDisplayBox = ({
  reservationReportSettings,
  fetchReservationReportDataStatus,
}: Props) => {
  const { t } = useTranslation();
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const contractedLoading = useSelector(
    (state: ReduxState) => state.organizations.contractedLoading
  );
  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );
  const loading = contractedLoading || productsLoading;

  const statusOptions = getBookingStatusOptions(t);
  const bookingSourceOptions = getBookingSourceOptions(t);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];
  filters.push({
    typeText: t('Basis'),
    filterValue:
      reservationReportSettings.basisDateType === 'PARTICIPATION_DATE'
        ? t('Participation Date')
        : t('Booked Date'),
  });

  if (reservationReportSettings.productIds?.length > 0) {
    filters.push({
      typeText: t('Products'),
      filterValue: reservationReportSettings.productIds
        .map(
          (productId) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (reservationReportSettings.statuses?.length > 0) {
    filters.push({
      typeText: t('Booking Status'),
      filterValue: reservationReportSettings.statuses
        .map(
          (status) =>
            statusOptions.find((option) => option.value === status)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (reservationReportSettings.dateFilterPreset) {
    const preset = reservationReportSettings.dateFilterPreset;
    let dateRange = '';

    if (preset === 'CUSTOM') {
      dateRange = ` (${reservationReportSettings.startDate} ~ ${reservationReportSettings.endDate})`;
    }

    filters.push({
      typeText: t('Date Range'),
      filterValue: `${getDateFilterPresetText(preset, t)} ${dateRange}`,
    });
  }

  if (reservationReportSettings.compare) {
    const dateRange = `${reservationReportSettings.compareStartDate} ~ ${reservationReportSettings.compareEndDate}`;
    filters.push({
      typeText: t('Compare'),
      filterValue: `${dateRange}`,
    });
  }

  if (reservationReportSettings.bookingSourceTypes?.length > 0) {
    filters.push({
      typeText: t('Booking Source'),
      filterValue: reservationReportSettings.bookingSourceTypes
        .map(
          (sourceType) =>
            bookingSourceOptions.find((option) => option.value === sourceType)
              ?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  if (reservationReportSettings.agentIds?.length > 0) {
    filters.push({
      typeText: t('Agent'),
      filterValue: reservationReportSettings.agentIds
        .map(
          (agentId) =>
            agentOptions.find((option) => option.value === agentId)?.text
        )
        .filter((t) => Boolean(t))
        .join(','),
    });
  }

  return (
    <div className={styles['condition-display-box']}>
      <b>{t('Report Setting')}</b>

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
          {fetchReservationReportDataStatus === 'FAILED' && (
            <li
              style={{
                color: 'red',
              }}
            >
              {t(
                "Sorry, there's an error while getting the data. Please retry with shorter date range."
              )}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
