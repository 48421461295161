import styles from './PlusMinusNumberInputForm.module.css';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export const PlusMinusNumberInputForm = (props: Props) => {
  return (
    <label className={styles['form-number']}>
      <input
        type="number"
        step="1"
        value={props.value}
        min="1"
        max="2"
        onChange={(e) => props.onChange(Number(e.target.value))}
      />
      <span
        className={styles['down']}
        onClick={() => props.onChange(props.value - 1)}
      >
        -
      </span>
      <span
        className={styles['up']}
        onClick={() => props.onChange(props.value + 1)}
      >
        +
      </span>
    </label>
  );
};
