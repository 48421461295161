import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { convertAccessReportDataSetsToTableData } from 'client/libraries/util/accessReportSettings';
import { AccessReportDataSet } from 'shared/models/swagger';

interface Props {
  reportDataSets: AccessReportDataSet[];
  shouldShowComparison: boolean;
}

export const AccessReportSummaryTable = ({
  reportDataSets,
  shouldShowComparison,
}: Props) => {
  const { t } = useTranslation();

  const graphData = convertAccessReportDataSetsToTableData(reportDataSets);

  let totalVisitCount = 0;
  let totalUserCount = 0;
  let totalReservationCount = 0;
  let compareTotalVisitCount = 0;
  let compareTotalUserCount = 0;
  let compareTotalReservationCount = 0;
  graphData.forEach((dataItem) => {
    totalVisitCount += dataItem.visitCount;
    totalUserCount += dataItem.userCount;
    totalReservationCount += dataItem.reservationCount;
    if (
      dataItem.compareVisitCount &&
      dataItem.compareUserCount &&
      dataItem.compareReservationCount
    ) {
      compareTotalVisitCount += dataItem.compareVisitCount;
      compareTotalUserCount += dataItem.compareUserCount;
      compareTotalReservationCount += dataItem.compareReservationCount;
    }
  });

  return (
    <div
      className={clsx(
        styles['p-dashboard__body__table'],
        styles['p-dashboardTable']
      )}
    >
      <table
        className={clsx(
          tableSmallStyles['c-tableSmall'],
          tableSmallStyles['row']
        )}
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            <th style={{ width: '200px' }}>{t('visitCount')}</th>
            <th style={{ width: '200px' }}>{t('userCount')}</th>
            <th style={{ width: '200px' }}>{t('reservationCount')}</th>
            <th style={{ width: '200px' }}> {t('Conversion Rate')}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles['p-accessReport__row']}>
            <td data-text={t('visitCount')}>
              <div className={styles['p-accessReport__cell']}>
                <p>{totalVisitCount}</p>
                {shouldShowComparison && <p>{compareTotalVisitCount}</p>}
              </div>
            </td>
            <td data-text={t('userCount')}>
              <div className={styles['p-accessReport__cell']}>
                <p>{totalUserCount}</p>
                {shouldShowComparison && <p>{compareTotalUserCount}</p>}
              </div>
            </td>
            <td data-text={t('reservationCount')}>
              <div className={styles['p-accessReport__cell']}>
                <p>{totalReservationCount}</p>
                {shouldShowComparison && <p>{compareTotalReservationCount}</p>}
              </div>
            </td>
            <td data-text={t('Conversion Rate')}>
              <div className={styles['p-accessReport__cell']}>
                <p>
                  {(totalUserCount
                    ? (totalReservationCount / totalUserCount) * 100
                    : 0
                  ).toFixed(1)}{' '}
                  %
                </p>
                {shouldShowComparison && (
                  <p>
                    {(compareTotalUserCount
                      ? (compareTotalReservationCount / compareTotalUserCount) *
                        100
                      : 0
                    ).toFixed(1)}{' '}
                    %
                  </p>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
