import * as React from 'react';

import { Input } from 'client/components/Form';

type Props = {
  value: number;
  onChange: (arg0: number) => void;
  label?: string | React.ReactElement;
  rest?: any;
};
export const FloatInput = ({ value, onChange, label, ...rest }: Props) => {
  const [rawValue, setRawValue] = React.useState<string>(`${value}`);
  React.useEffect(() => {
    if (parseFloat(rawValue) !== value) {
      setRawValue(`${value}`);
    }
  }, [rawValue, value]);
  return (
    <Input
      {...rest}
      label={label}
      value={rawValue}
      onChange={(e, { value: v }) => {
        if (typeof v === 'undefined' || v === '') {
          setRawValue('0');
          onChange(0);
        }

        const newValue = parseFloat(v);

        if (isNaN(newValue)) {
          return;
        }

        setRawValue(eatLeadingZeros(v));
        onChange(newValue);
      }}
    />
  );
};

const eatLeadingZeros = (s: string): string => {
  let result = s;

  while (result.length > 1 && result[0] === '0') {
    result = result.substring(1);
  }

  return result;
};
