import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import {
  setInvoiceReservationVisibleColumns,
  setInvoiceSummaryVisibleColumns,
  setInvoiceSummaryVisibleRows,
} from 'client/actions/invoiceTableControls';
import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/Form';
import {
  invoiceReservationColumnCandidates,
  invoiceSummaryColumnCandidates,
  invoiceSummaryRowCandidates,
} from 'client/reducers/invoiceTableControls';
import { Modal } from 'client/components/Modal/Modal';
import { ReduxState } from 'client/reducers';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import {
  getSummaryColumnHeader,
  getSummaryRowHeader,
  getReservationColumnHeader,
} from './util';

export const InvoiceTableSettingsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visibleSummaryColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryColumns
  );
  const visibleSummaryRows = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleSummaryRows
  );

  const visibleReservationColumns = useSelector(
    (state: ReduxState) => state.invoiceTableControls.visibleReservationColumns
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      <a
        onClick={() => setShowModal(true)}
        className={clsx(
          baseStyles['base-btn'],
          baseStyles['square'],
          baseStyles['gray']
        )}
      >
        <img src={thIcon} />
      </a>
      <Modal
        title={t('Invoice Settings')}
        open={showModal}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
      >
        <div className={clsx(pageStyles['page-reservations__modal'])}>
          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <div className={clsx(baseStyles['base-form-box'])}>
              <div className={clsx(baseStyles['base-form-box__body'])}>
                <FieldWrapper label={t('Summary Columns')} />
                <ToggleableDndList
                  dragItemType="invoiceSummaryColumn"
                  selectedItems={visibleSummaryColumns.map((col) => ({
                    key: col,
                    text: getSummaryColumnHeader(col, t),
                  }))}
                  candidateItems={invoiceSummaryColumnCandidates.map((col) => ({
                    key: col,
                    text: getSummaryColumnHeader(col, t),
                  }))}
                  setSelectedItems={(items) =>
                    dispatch(
                      setInvoiceSummaryVisibleColumns(items.map((i) => i.key))
                    )
                  }
                />
                <Box mt={2} width="100%">
                  <FieldWrapper label={t('Summary Rows')} />
                  <ToggleableDndList
                    dragItemType="invoiceSummaryRow"
                    selectedItems={visibleSummaryRows.map((row) => ({
                      key: row,
                      text: getSummaryRowHeader(row, t),
                    }))}
                    candidateItems={invoiceSummaryRowCandidates.map((row) => ({
                      key: row,
                      text: getSummaryRowHeader(row, t),
                    }))}
                    setSelectedItems={(items) =>
                      dispatch(
                        setInvoiceSummaryVisibleRows(items.map((i) => i.key))
                      )
                    }
                  />
                </Box>
                <Box mt={2} width="100%">
                  <FieldWrapper label={t('Reservation List Columns')} />
                  <ToggleableDndList
                    dragItemType="invoiceReservationColumn"
                    selectedItems={visibleReservationColumns.map((col) => ({
                      key: col,
                      text: getReservationColumnHeader(col, t),
                    }))}
                    candidateItems={invoiceReservationColumnCandidates.map(
                      (col) => ({
                        key: col,
                        text: getReservationColumnHeader(col, t),
                      })
                    )}
                    setSelectedItems={(items) =>
                      dispatch(
                        setInvoiceReservationVisibleColumns(
                          items.map((i) => i.key)
                        )
                      )
                    }
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
