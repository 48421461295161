// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';

import { Loading } from 'client/pages/Loading';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getArrayMutators } from 'client/libraries/util/form';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import type { ReduxState } from 'client/reducers';
import type { Organization, SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import {
  convertFormValuesToOrganizationPatch,
  getInitialValues,
} from '../FormValues';

import { PerProductMessageEditor } from './PerProductMessageEditor';

const focusOnError = createDecorator();

const getTranslationKeysForPerProductMessages = (
  org: Organization | null
): string[] => {
  if (!org) {
    return [];
  }

  const keys: string[] = [];

  org?.email_customization_settings?.per_product_custom_messages?.forEach(
    (msg) => {
      if (msg.title) {
        keys.push(msg.title);
      }
      if (msg.description) {
        keys.push(msg.description);
      }
      keys.push(...(msg.list_items?.filter((item) => Boolean(item)) ?? []));
    }
  );

  return keys;
};

export const PerProductMessagesTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const scrollTo = React.useContext(ScrollToContext);

  const translationKeys = React.useMemo(
    () => getTranslationKeysForPerProductMessages(activeUserOrganization),
    [activeUserOrganization]
  );

  React.useEffect(() => {
    if (translationKeys.length > 0) {
      dispatch(batchGetTranslations(translationKeys));
    }
  }, [activeUserOrganization, translationKeys]);

  React.useEffect(() => {
    if (
      apiTranslations.some((trans) =>
        translationKeys.includes(trans[defaultLanguage.toLowerCase()])
      )
    ) {
      setShowTranslationMode(true);
    }
  }, [apiTranslations]);

  const initialValues = React.useMemo(() => {
    return {
      ...getInitialValues(activeUserOrganization),
    };
  }, [activeUserOrganization]);

  return (
    <div className={baseStyles['base-main__body__box']}>
      {loading && <Loading />}
      <div className={baseStyles['base-main__body__box__body']}>
        <SettingTranslationLanguageSelector
          showTranslationMode={showTranslationMode}
          onShowTranslationModeChange={setShowTranslationMode}
          translationTargetLanguage={translationTargetLanguage}
          onTranslationTargetLanguageChange={setTranslationTargetLanguage}
        />

        <TranslationLanguageContext.Provider
          value={showTranslationMode ? translationTargetLanguage : null}
        >
          {saveSucceeded && <Message success header={t('Settings Saved')} />}
          <Form
            onSubmit={async (values) => {
              setSaveSucceeded(false);

              scrollTo(0, 0);

              if (
                translationTargetLanguage &&
                showTranslationMode &&
                (values?.translations ?? []).length > 0
              ) {
                await Promise.all([
                  dispatch(
                    updateOrganization(
                      activeUserOrganization?.id ?? '',
                      'SUPPLIER',
                      convertFormValuesToOrganizationPatch(
                        values,
                        activeUserOrganization
                      )
                    )
                  ),
                  dispatch(
                    updateTranslations(
                      values.translations.map((translation) => ({
                        source_language: defaultLanguage,
                        [defaultLanguage.toLowerCase()]: translation.source,
                        [translationTargetLanguage.toLowerCase()]:
                          translation.target,
                      }))
                    )
                  ),
                ]);
              } else {
                await dispatch(
                  updateOrganization(
                    activeUserOrganization?.id ?? '',
                    'SUPPLIER',
                    convertFormValuesToOrganizationPatch(
                      values,
                      activeUserOrganization
                    )
                  )
                );
              }

              setSaveSucceeded(true);
            }}
            keepDirtyOnReinitialize
            initialValues={initialValues}
            mutators={getArrayMutators()}
            decorators={[focusOnError]}
          >
            {({ handleSubmit, form }) => {
              const sourceLanguage =
                activeUserOrganization?.source_language ?? 'JA_JP';
              React.useEffect(() => {
                let translations = [];
                if (sourceLanguage && translationTargetLanguage) {
                  const sourceFieldName = sourceLanguage.toLowerCase();
                  const translationFieldName =
                    translationTargetLanguage.toLowerCase();
                  translations = apiTranslations.map((trans) => ({
                    source: trans[sourceFieldName],
                    target: trans[translationFieldName],
                  }));
                }

                form.change('translations', translations);
              }, [sourceLanguage, apiTranslations, translationTargetLanguage]);

              return (
                <form onSubmit={handleSubmit}>
                  <FormTableBox>
                    <FieldArray name="perProductMessages">
                      {({ fields }) => (
                        <div>
                          {fields.length === 0 && (
                            <AddIcon
                              onClick={() =>
                                fields.insertAt(0, {
                                  productId: '',
                                  title: '',
                                  description: '',
                                  listItems: [],
                                })
                              }
                            />
                          )}
                          <table>
                            {fields.map((name, idx) => (
                              <tr key={name}>
                                <th>
                                  {t('Per Product Message {{idx}}', {
                                    idx: idx + 1,
                                  })}
                                </th>
                                <td>
                                  <PerProductMessageEditor name={name} />
                                </td>
                                <td style={{ width: '88px' }}>
                                  <Box display="flex">
                                    <AddIcon
                                      onClick={() =>
                                        fields.insertAt(idx + 1, {
                                          productId: '',
                                          title: '',
                                          description: '',
                                          listItems: [],
                                        })
                                      }
                                    />
                                    <DeleteIcon
                                      onClick={() => fields.remove(idx)}
                                    />
                                  </Box>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      )}
                    </FieldArray>
                  </FormTableBox>
                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    <Button type="submit" style="blue" size="small">
                      {t('Save Settings')}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </TranslationLanguageContext.Provider>
      </div>
    </div>
  );
};
