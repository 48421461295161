import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Modal } from 'client/components/v3/Form/Modal';
import { getValidators } from 'shared/libraries/validate/validator';
import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { Button } from 'client/components/v3/Common/Button';

import styles from './MenuItemEditor.module.css';

interface Props {
  onClose: () => void;
  onSave: () => void;
  name: string;
}

export const AddOnModal = ({ onClose, onSave, name }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);
  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? '';

  return (
    <Modal
      title={t('New Add-On')}
      onClose={onClose}
      open={true}
      rightActionChildren={
        <>
          <Button
            color="white"
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
          />
          <Button text={t('Save')} type="button" onClick={() => onSave()} />
        </>
      }
    >
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name={`${name}.name`} validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Name')}
                onChange={input.onChange}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name={`${name}.description`} validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextArea
                label={t('Description')}
                onChange={input.onChange}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.inputWrapper}>
          <Field name={`${name}.price`} validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Price ({{currency}})', {
                  currency: currencyCode,
                })}
                onChange={(newValue) => {
                  if (!currencyInputAllowed(currencyCode, newValue)) return;

                  input.onChange(newValue);
                }}
                value={input.value}
                error={Boolean(touched && error)}
                helperText={touched ? error : undefined}
              />
            )}
          </Field>
        </div>
      </div>
      <section className={styles.profilePictures}>
        <h2 className={styles.sectionTitle}>{t('Photos')}</h2>
        <Field name={`${name}.mediaUrls`}>
          {({ input }) => (
            <ImageVideoAudioInput
              fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
              fileUrls={input.value || []}
              onChange={(newValue) => input.onChange(newValue)}
            />
          )}
        </Field>
      </section>
    </Modal>
  );
};
