import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import {
  DashboardGadget,
  ReservationReportGadgetDataType,
  GadgetType,
  AccessReportGadgetDataType,
} from 'client/reducers/dashboardSettings';
import { TextField } from 'client/components/v3/Form/TextField';
import { ReduxState } from 'client/reducers';
import { putDashboardSettings } from 'client/actions/dashboardSettings';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';
import { ReservationReportGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationReportGadget/ReservationReportGadgetEditor';
import { AccessSummaryGadgetEditor } from 'client/pages/v3/FlexibleDashboard/AccessSummaryGadget/AccessSummaryGadgetEditor';
import { AccessReportGadgetEditor } from 'client/pages/v3/FlexibleDashboard/AccessReportGadget/AccessReportGadgetEditor';
import { ReservationSummaryGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationSummaryGadget/ReservationSummaryGadgetEditor';
import {
  getCustomGadgetKey,
  getReservationListGadgetInitialParams,
  getReservationSummaryGadgetInitialParams,
  getParamsForReservationListSearchPreset,
  getParamsForReservationSummarySearchPreset,
} from 'client/pages/v3/FlexibleDashboard/util';
import { ReservationListGadgetEditor } from 'client/pages/v3/FlexibleDashboard/ReservationListGadget/ReservationListGadgetEditor';

type Props = {
  gadgetType: GadgetType;
  gadgetDataType?: ReservationReportGadgetDataType | AccessReportGadgetDataType;
  onClose: () => void;
  onCancel: () => void;
};

const setNewDashboardGadget = (
  gadget: DashboardGadget,
  gadgetType: GadgetType,
  gadgetDataType:
    | ReservationReportGadgetDataType
    | AccessReportGadgetDataType
    | undefined
) => {
  gadget.gadgetType = gadgetType;
  if (gadget.gadgetType === 'reservation-report') {
    return {
      ...gadget,
      key: getCustomGadgetKey(
        gadget.gadgetType,
        gadgetDataType,
        gadget.params.displayType
      ),
      gadgetType: gadgetType,
      params: {
        ...gadget.params,
        dataType: gadgetDataType,
      },
    };
  } else if (gadget.gadgetType === 'access-report') {
    return {
      ...gadget,
      key: getCustomGadgetKey(
        gadget.gadgetType,
        gadgetDataType,
        gadget.params.displayType
      ),
      gadgetType: gadgetType,
      params: {
        ...gadget.params,
        dataType: gadgetDataType,
      },
    };
  } else if (gadget.gadgetType === 'access-summary') {
    return {
      ...gadget,
      key: getCustomGadgetKey(gadget.gadgetType),
    };
  } else if (gadget.gadgetType === 'reservation-summary') {
    const searchPresetIsCustom = gadget.params.searchPreset === 'CUSTOM';
    let params;
    if (searchPresetIsCustom) {
      params = gadget.params;
    } else {
      params = {
        ...getParamsForReservationSummarySearchPreset(
          gadget.params.searchPreset
        ),
        defaultColumns: gadget.params.defaultColumns,
      };
    }

    return {
      ...gadget,
      key: getCustomGadgetKey(gadget.gadgetType),
      params: params,
    };
  }
  // Reservation list
  else {
    const searchPresetIsCustom = gadget.params.searchPreset === 'CUSTOM';
    let params;
    if (searchPresetIsCustom) {
      params = gadget.params;
    } else {
      params = {
        ...getParamsForReservationListSearchPreset(gadget.params.searchPreset),
        defaultColumns: gadget.params.defaultColumns,
      };
    }

    return {
      ...gadget,
      key: getCustomGadgetKey(gadget.gadgetType),
      params: params,
    };
  }
};

export const AddCustomGadgetModal = ({
  gadgetType,
  gadgetDataType,
  onClose,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const existingSettings = useSelector(
    (state: ReduxState) => state.dashboardSettings.data
  );

  const getInitialValues = (gadgetType: GadgetType) => {
    // Need initial values for reservation summary & list to set preset conditions
    if (gadgetType === 'reservation-summary') {
      return {
        gadgetType: 'reservation-summary',
        params: getReservationSummaryGadgetInitialParams(t),
      };
    } else if (gadgetType === 'reservation-list') {
      return {
        gadgetType: 'reservation-list',
        params: getReservationListGadgetInitialParams(),
      };
    }
  };

  return (
    <Form
      initialValues={getInitialValues(gadgetType)}
      onSubmit={async (values: DashboardGadget) => {
        const newGadget = setNewDashboardGadget(
          values,
          gadgetType,
          gadgetDataType
        );

        const newSettings = {
          gadgets: existingSettings?.gadgets
            ? [...existingSettings.gadgets, newGadget]
            : [newGadget],
        };

        await dispatch(putDashboardSettings(JSON.stringify(newSettings)));

        onClose();
      }}
    >
      {({ handleSubmit, values, submitting }) => {
        let shouldDisable = false;
        values.gadgetType = gadgetType;
        if (values.gadgetType === 'access-report') {
          if (values.title === undefined) {
            shouldDisable = true;
          }
          if (values.params) {
            if (values.params.displayType === undefined) {
              shouldDisable = true;
            }
            if (values.params.dateRange === undefined) {
              shouldDisable = true;
            }
          } else {
            shouldDisable = true;
          }
        } else if (values.gadgetType === 'access-summary') {
          if (values.title === undefined) {
            shouldDisable = true;
          }
          if (values.params) {
            if (values.params.dateRange === undefined) {
              shouldDisable = true;
            }
          } else {
            shouldDisable = true;
          }
        } else if (values.gadgetType === 'reservation-report') {
          if (values.title === undefined) {
            shouldDisable = true;
          }
          if (values.params) {
            if (values.params.displayType === undefined) {
              shouldDisable = true;
            }
            if (values.params.dateType === undefined) {
              shouldDisable = true;
            }
            if (values.params.dateRange === undefined) {
              shouldDisable = true;
            }
          } else {
            shouldDisable = true;
          }
        } else if (
          values.gadgetType === 'reservation-summary' ||
          values.gadgetType === 'reservation-list'
        ) {
          if (values.title === undefined) {
            shouldDisable = true;
          }
          if (values.params) {
            if (values.params.orderBy === undefined) {
              shouldDisable = true;
            }
            if (values.params.dateType === undefined) {
              shouldDisable = true;
            }
            if (values.params.targetDateType === undefined) {
              shouldDisable = true;
            }
          } else {
            shouldDisable = true;
          }
        }

        return (
          <form id="addCustomGadgetForm" onSubmit={handleSubmit}>
            <Modal
              insertAtRoot
              title={t('Add Custom Gadget')}
              open={true}
              onClose={onCancel}
              rightActionChildren={
                <>
                  <Button
                    text={t('Cancel')}
                    size="md"
                    color="white"
                    onClick={onCancel}
                  />
                  {/* ref: https://github.com/final-form/react-final-form/blob/master/docs/faq.md#how-can-i-trigger-a-submit-from-outside-my-form
                    Portal causes the button to render outside the form tree. Use form's id to connect this submit button to the form. */}
                  <Button
                    loading={submitting}
                    form="addCustomGadgetForm"
                    text={t('Save')}
                    type="submit"
                    disabled={shouldDisable}
                  />
                </>
              }
              // Limit width of modal because multi-select may make the modal width change
              style={{
                maxWidth: '600px',
                height:
                  gadgetType === 'reservation-report' ||
                  gadgetType === 'reservation-list' ||
                  gadgetType === 'reservation-summary'
                    ? '600px'
                    : undefined,
              }}
              useCloseButton={true}
            >
              <ul className={styles['p-dashboardModal']}>
                <li className={styles['p-dashboardModal__item']}>
                  <p className={styles['p-dashboardModal__item__ttl']}>
                    {t('Title')}
                  </p>
                  <div className={styles['p-dashboardModal__item__body']}>
                    <Field name="title">
                      {({ input }) => (
                        <TextField
                          value={input.value}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </li>
                {gadgetType === 'reservation-report' && (
                  <ReservationReportGadgetEditor />
                )}
                {gadgetType === 'access-report' && <AccessReportGadgetEditor />}
                {gadgetType === 'access-summary' && (
                  <AccessSummaryGadgetEditor />
                )}
                {gadgetType === 'reservation-summary' && (
                  <ReservationSummaryGadgetEditor />
                )}
                {gadgetType === 'reservation-list' && (
                  <ReservationListGadgetEditor />
                )}
              </ul>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};
