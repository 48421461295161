import * as React from 'react';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TFunction } from 'react-i18next';
import type { ThunkDispatch } from 'redux-thunk';

import { ProductInstanceEditInput } from 'client/pages/EditProductAvailabilityAllotment/ProductInstanceEditCalendar/ProductInstanceEditInput';
import { getParentID } from 'client/libraries/util/util';
import { fetchProductByID } from 'client/actions/products';
import { updateProductInstance } from 'client/actions/productInstances';
import { ModalLoader } from 'client/components/ModalLoader';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';
import { Button } from 'client/components/Form';
import type { ProductInstance } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  oldProductInstance: ProductInstance;
  onClose: () => void;
  open: boolean;
  insertRoot?: boolean;
};

type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  I18nProps;
/* eslint-enable no-use-before-define */
type State = {
  editingProductInstance: ProductInstance;
};

class ProductInstanceEditModalComponent extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editingProductInstance: this.getInitialProductInstance(),
    };
  }

  componentDidMount() {
    this.fetchProduct();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.oldProductInstance !== this.props.oldProductInstance) {
      this.fetchProduct();
      this.setState({
        editingProductInstance: this.getInitialProductInstance(),
      });
    }
  }

  fetchProduct = () => {
    const { product, oldProductInstance } = this.props;

    if (!product || product.id !== oldProductInstance.product_id) {
      this.props.fetchProductByID(oldProductInstance.product_id);
    }
  };
  getInitialProductInstance = () => {
    return {
      ...this.props.oldProductInstance,
      prevent_allotment_changes_when_reprinting: true,
    };
  };
  editingProductInstanceHasChanged = () => {
    const { oldProductInstance } = this.props;
    const { editingProductInstance } = this.state;
    return (
      oldProductInstance.total_slots !== editingProductInstance.total_slots ||
      oldProductInstance.per_channel_info !==
        editingProductInstance.per_channel_info ||
      !editingProductInstance.prevent_allotment_changes_when_reprinting
    );
  };

  render() {
    const {
      productLoading,
      locale,
      oldProductInstance,
      onClose,
      product,
      updateProductInstance,
      t,
      open,
    } = this.props;
    const { editingProductInstance } = this.state;
    const loading = productLoading || !product;
    const timezone = (product && product.start_timezone) || 'UTC';
    const startMoment = moment
      .tz(editingProductInstance.start_date_time_utc, timezone)
      .locale(locale);
    const start = startMoment.format('lll');
    const parentID = product && getParentID({ ...product });
    return (
      <Modal
        title={`${t('Edit Instance')}: ${start}`}
        open={open}
        onClose={onClose}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
        insertRoot={true}
      >
        {parentID ? (
          <Modal.Content>
            <Message
              content={t(
                'This product shares allotment with another product so calendar edit is disabled.'
              )}
            />
            <Message>
              <Link to={`/products/${parentID}`}>
                {t('Go to parent product')}
              </Link>
            </Message>
          </Modal.Content>
        ) : (
          <>
            <Modal.Content>
              {loading ? (
                <ModalLoader />
              ) : (
                <ProductInstanceEditInput
                  product={product || null}
                  oldProductInstance={oldProductInstance}
                  newProductInstance={editingProductInstance}
                  handleProductInstanceChange={(
                    editingProductInstance: ProductInstance
                  ) =>
                    this.setState({
                      editingProductInstance,
                    })
                  }
                />
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() =>
                  this.setState({
                    editingProductInstance: this.getInitialProductInstance(),
                  })
                }
              >
                {t('Discard')}
              </Button.Cancel>
              <Button.Submit
                onClick={() => {
                  const {
                    total_slots,
                    per_channel_info,
                    prevent_allotment_changes_when_reprinting,
                    memo,
                  } = editingProductInstance;
                  (
                    updateProductInstance(editingProductInstance.id, {
                      total_slots,
                      per_channel_info,
                      prevent_allotment_changes_when_reprinting,
                      memo,
                    }) as any
                  ).then(() => onClose());
                }}
                disabled={!this.editingProductInstanceHasChanged()}
              >
                {t('Save')}
              </Button.Submit>
            </Modal.Actions>
          </>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => ({
  product: state.products.byID[ownProps.oldProductInstance.product_id],
  productLoading: state.products.loading,
  locale: state.language.selected.iso,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProductInstance: (id: string, patch: any) =>
    dispatch(updateProductInstance(id, patch)),
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
});

export const ProductInstanceEditModal = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ProductInstanceEditModalComponent);
