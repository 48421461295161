import moment from 'moment-timezone';

import type { AvailabilityAllotmentSchedule, ClosedDate } from './FormValues';

export const findOverlappingDateRange = (
  schedule: AvailabilityAllotmentSchedule,
  comparisonSchedules: AvailabilityAllotmentSchedule[]
): {
  startDate: string;
  endDate: string;
} | null => {
  for (const comparisonSchedule of comparisonSchedules) {
    const overlappingDateRange = findOverlappingDateRangeForSchedule(
      schedule,
      comparisonSchedule
    );

    if (overlappingDateRange) {
      return overlappingDateRange;
    }
  }

  return null;
};

const findOverlappingDateRangeForSchedule = (
  schedule: AvailabilityAllotmentSchedule,
  comparisonSchedule: AvailabilityAllotmentSchedule
): {
  startDate: string;
  endDate: string;
} | null => {
  if (
    !schedule.weekdays.some((weekday) =>
      comparisonSchedule.weekdays.includes(weekday)
    )
  ) {
    return null;
  }

  for (const dateRange of schedule.dateRanges) {
    for (const comparisonDateRange of comparisonSchedule.dateRanges) {
      if (
        (dateRange.startDate >= comparisonDateRange.startDate &&
          dateRange.startDate <= comparisonDateRange.endDate) ||
        (comparisonDateRange.startDate >= dateRange.startDate &&
          comparisonDateRange.startDate <= dateRange.endDate)
      ) {
        let startDate = dateRange.startDate;

        if (comparisonDateRange.startDate > startDate) {
          startDate = comparisonDateRange.startDate;
        }

        let endDate = dateRange.endDate;

        if (comparisonDateRange.endDate < endDate) {
          endDate = comparisonDateRange.endDate;
        }

        // Is there a date in the overlapping range that is not excluded by closed dates?
        for (
          let date = moment(startDate);
          date.format('YYYY-MM-DD') <= endDate;
          date = date.add(1, 'day')
        ) {
          const formattedDate = date.format('YYYY-MM-DD');

          if (
            !schedule.closedDates.some((closedDate) =>
              closedDateMatchesDate(closedDate, formattedDate)
            ) &&
            !comparisonSchedule.closedDates.some((closedDate) =>
              closedDateMatchesDate(closedDate, formattedDate)
            )
          ) {
            return {
              startDate: comparisonDateRange.startDate,
              endDate: comparisonDateRange.endDate,
            };
          }
        }
      }
    }
  }

  return null;
};

const closedDateMatchesDate = (
  closedDate: ClosedDate,
  date: string
): boolean => {
  if (closedDate.repeatsAnnually) {
    return (
      closedDate.date.length === 10 &&
      date.length === 10 &&
      closedDate.date.slice(5) === date.slice(5)
    );
  }

  return closedDate.date === date;
};
