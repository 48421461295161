import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE,
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST,
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS,
} from 'client/constants/ActionTypes';
import type { AnalyticsCustomer } from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE:
      return action.error;

    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST:
      return true;

    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE:
    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: AnalyticsCustomer[] = [],
  action: any
): AnalyticsCustomer[] => {
  switch (action.type) {
    case FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS: {
      const response = action.response as AnalyticsCustomer;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    default:
      return state;
  }
};

export const onSiteAnalyticsCustomers = combineReducers({
  error,
  loading,
  all,
});
