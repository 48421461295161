import clsx from 'clsx';
import _ from 'lodash';
import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import baseStyles from 'client/v3-base.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
  usePaging?: boolean;
  useScrollButton?: boolean;
};
export type CustomTableColumn = {
  Header: string;
  HeaderElement?: ReactElement<any>;
  accessor?: string | ((arg0: any) => string);
  width?: string;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
  id?: string;
};

export const CustomTable = ({ items, columns, useScrollButton }: Props) => {
  const { t } = useTranslation();
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);

  const scrollHandler = () => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      tableHeaderRef.current.scrollLeft = tableBodyRef.current.scrollLeft;
    }
  };

  const scrollButtonClickHandler = (direction: any) => {
    if (
      tableHeaderRef?.current?.scrollLeft != null &&
      tableBodyRef?.current?.scrollLeft != null
    ) {
      if (direction === 'next') {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft += 100;
      } else {
        tableHeaderRef.current.scrollLeft =
          tableBodyRef.current.scrollLeft -= 100;
      }
    }
  };

  return (
    <>
      <div className={clsx(styles['c-tableFrame'], styles['arrow'])}>
        {items.length === 0 && <div>{t('No reservations found')}</div>}

        {items.length > 0 && (
          <>
            <div
              className={clsx(
                styles['c-table'],
                styles['thead'],
                styles['sticky-top']
              )}
              ref={tableHeaderRef}
            >
              <table className={styles['c-table__body']}>
                <thead>
                  <tr>
                    {columns.map((column, idx) => {
                      return (
                        <th
                          className={clsx(
                            baseStyles['u-width-176'],
                            idx === 0 && styles['sticky-top'],
                            idx === 0 && styles['sticky-left']
                          )}
                          key={idx}
                        >
                          {column.Header}
                          {column.HeaderElement}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className={clsx(styles['c-table'], styles['tbody'])}
              onScroll={scrollHandler}
              ref={tableBodyRef}
            >
              <table className={styles['c-table__body']}>
                <tbody>
                  {items.map((item, ridx) => {
                    const rows = [];

                    const subRowCount =
                      _.max(
                        columns.map(
                          (col) =>
                            (col.CellArray && col.CellArray(item)?.length) || 1
                        )
                      ) ?? 1;

                    for (
                      let subRowIdx = 0;
                      subRowIdx < subRowCount;
                      subRowIdx++
                    ) {
                      const row = (
                        <tr key={`${ridx}-${subRowIdx}`}>
                          {columns.map((column, idx) => {
                            if (subRowIdx > 0 && !column.CellArray) {
                              return null;
                            }

                            let value: any;
                            let displayValue: any;

                            if (typeof column.accessor === 'string') {
                              value = item[column.accessor];
                            } else if (typeof column.accessor === 'function') {
                              value = column.accessor(item);
                            }

                            if (column.CellArray) {
                              const cellArray = column.CellArray(item);
                              displayValue =
                                subRowIdx < cellArray.length
                                  ? cellArray[subRowIdx]
                                  : '';
                            } else if (column.Cell) {
                              const cell = {
                                value: value,
                                original: item,
                                rowIndex: ridx,
                              };
                              displayValue = column.Cell(cell);
                            } else {
                              displayValue = value;
                            }

                            if (column.th) {
                              return (
                                <td
                                  className={clsx(
                                    baseStyles['u-width-176'],
                                    styles['c-table__spHeader'],
                                    idx === 0 && styles['sticky-left']
                                  )}
                                  key={`${ridx}-${subRowIdx}-${idx}`}
                                >
                                  {displayValue}
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  className={baseStyles['u-width-176']}
                                  key={`${ridx}-${subRowIdx}-${idx}`}
                                  data-text={column.Header}
                                >
                                  {displayValue}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                      rows.push(row);
                    }

                    return rows;
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

        {items.length > 0 && useScrollButton && (
          <>
            <div
              className={clsx(styles['c-tableBtn'], styles['left'])}
              onClick={() => {
                scrollButtonClickHandler('prev');
              }}
            >
              <a>
                <i className="c-icon-solid-arrows-chevron-left"></i>
              </a>
            </div>

            <div
              className={clsx(styles['c-tableBtn'], styles['right'])}
              onClick={() => {
                scrollButtonClickHandler('next');
              }}
            >
              <a>
                <i className="c-icon-solid-arrows-chevron-right"></i>
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
};
