import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';

import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { TabType } from 'client/libraries/util/dashboard';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDashBoard/ReservationDashBoard.module.css';
import {
  fetchTasks,
  setTasksFilters,
  fetchTaskCounts,
  updateTasksPageTokens,
  setTasksDueDateFrom,
} from 'client/actions/tasks';
import { fetchReservations } from 'client/actions/reservations';
import { setDashboardTab } from 'client/actions/dashboardControls';
import { DashboardCustomTable } from 'client/pages/v3/Reservation/ReservationDashBoard/DashboardCustomTable';
import type { CustomTableColumn } from 'client/pages/v3/Reservation/ReservationDashBoard/DashboardCustomTable';
import { Loading } from 'client/components/v3/Common/Loading';

import {
  taskShapesSelector,
  getColumnIds,
  getAllColumns,
  convertReservationToTaskShape,
  getNumOfTasksLabel,
  getNumOfTasks,
} from './utils';

export const ReservationDashBoardBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeTab = useSelector(
    (state: ReduxState) => state.dashboardControls.tab
  );
  const taskCounts = useSelector((state: ReduxState) => state.tasks.taskCounts);
  const pageTokens = useSelector((state: ReduxState) => state.tasks.pageTokens);
  const loading = useSelector((state: ReduxState) => state.tasks.loading);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );

  const [previousTab, setPreviousTab] = React.useState<TabType>(activeTab);
  const [numOfPinnedItems, setNumOfPinnedItems] = React.useState<number>(0);

  const taskShapes = useSelector((state: ReduxState) =>
    taskShapesSelector(state, { t })
  );

  const pinnedTaskShape = React.useMemo(
    () => allReservations.map((r) => convertReservationToTaskShape(r, t)),
    [allReservations, t]
  );

  const sortedPinnedTaskShape = pinnedTaskShape.sort((a, b) => {
    if (a.deadline.isBefore(b.deadline)) {
      return -1;
    }
    return 1;
  });

  React.useEffect(() => {
    setNumOfPinnedItems(pinnedTaskShape?.length ?? 0);
  }, [pinnedTaskShape]);

  React.useEffect(() => {
    dispatch(
      setTasksDueDateFrom(
        moment()
          .tz(activeUserOrganization?.default_timezone ?? 'UTC')
          .utc()
          .format()
      )
    );
    dispatch(
      fetchReservations({
        pinned_only: 'true',
      })
    );
    dispatch(
      updateTasksPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.currToken,
        indexToToken: pageTokens.indexToToken,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(fetchTasks());
    dispatch(fetchTaskCounts());
  }, [pageTokens]);

  // this value is used in fetchTasks
  const handleOnClickPrevious = async () => {
    await dispatch(
      updateTasksPageTokens({
        allTokens: pageTokens.allTokens,
        currToken: pageTokens.allTokens[pageTokens.currToken].previous,
        indexToToken: pageTokens.indexToToken,
      })
    );

    await dispatch(fetchTasks());
  };

  // this value is used in fetchTasks
  const handleOnClickNext = async () => {
    const allTokens = pageTokens.allTokens;
    const nextToken = pageTokens.allTokens[pageTokens.currToken].next;
    if (nextToken != null) {
      allTokens[nextToken] = {
        previous: pageTokens.currToken,
        next: null,
      };
      await dispatch(
        updateTasksPageTokens({
          allTokens: allTokens,
          currToken: nextToken,
          indexToToken: pageTokens.indexToToken,
        })
      );
      await dispatch(fetchTasks());
    }
  };

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const visibleColumns = React.useMemo(() => {
    const columns = getColumnIds(activeTab);
    const allColumnTypes = getAllColumns(locale, activeTab, t);

    const visibleColumns: CustomTableColumn[] = [];

    columns.forEach((column: string) => {
      const c = allColumnTypes.find((columnType: CustomTableColumn) => {
        return columnType.id === column;
      });
      if (c) {
        visibleColumns.push(c);
      }
    });

    return visibleColumns;
  }, [activeTab, locale, t]);

  React.useEffect(() => {
    let value;
    let isTask;
    if (activeTab === 'REQUESTED') {
      value = 'REQUESTED';
      isTask = true;
    } else if (activeTab === 'STANDBY') {
      value = 'STANDBY';
      isTask = true;
    } else if (activeTab === 'PICKUP_CHECKIN_INFO_REQURED') {
      value = 'PICKUP_DROPOFF_TBD';
      isTask = true;
    } else if (activeTab === 'PINNED') {
      value = 'PINNED';
      isTask = false;
    }
    if (isTask) {
      dispatch(
        setTasksFilters([
          {
            id: 'action_item',
            value,
          },
        ])
      );
      if (previousTab === activeTab) {
        dispatch(
          updateTasksPageTokens({
            allTokens: pageTokens.allTokens,
            currToken: pageTokens.currToken,
            indexToToken: pageTokens.indexToToken,
          })
        );
      }
      dispatch(fetchTasks());
    } else {
      dispatch(
        fetchReservations({
          pinned_only: 'true',
        })
      );
    }
    dispatch(fetchTaskCounts());
    setPreviousTab(activeTab);
  }, [activeTab]);

  let pinStyle = styles['num-of-task-zero'];
  if (numOfPinnedItems && activeTab === 'PINNED') {
    pinStyle = styles['num-of-task-non-zero'];
  } else if (numOfPinnedItems && activeTab !== 'PINNED') {
    pinStyle = styles['num-of-task-non-active-non-zero'];
  }

  const getTabName = () => {
    if (activeTab === 'REQUESTED') {
      return t('Requested');
    } else if (activeTab === 'STANDBY') {
      return t('Standby');
    } else if (activeTab === 'PICKUP_CHECKIN_INFO_REQURED') {
      return t('Pickup/Checkin Info Required');
    }
    return t('Pinned');
  };

  return (
    <div className={clsx(baseStyles['l-main__body'])}>
      <section className={styles['g-section']}>
        <div className={clsx(styles['p-reservations'])}>
          <div className={styles['p-reservations__header']}>
            <ul className={styles['p-reservations__tab']}>
              <li
                className={clsx(
                  activeTab === 'REQUESTED' ? styles['is-active'] : ''
                )}
                onClick={() => {
                  dispatch(setDashboardTab('REQUESTED'));
                }}
              >
                <a className={styles['table--link']}>
                  <span className={styles['table--tab']}>{t('Requested')}</span>
                  <span>
                    {getNumOfTasksLabel(
                      taskCounts,
                      'REQUESTED',
                      activeTab === 'REQUESTED'
                    )}
                  </span>
                </a>
              </li>
              <li
                className={clsx(
                  activeTab === 'STANDBY' ? styles['is-active'] : ''
                )}
                onClick={() => {
                  dispatch(setDashboardTab('STANDBY'));
                }}
              >
                <a className={styles['table--link']}>
                  <span className={styles['table--tab']}>{t('Standby')}</span>
                  <span>
                    {getNumOfTasksLabel(
                      taskCounts,
                      'STANDBY',
                      activeTab === 'STANDBY'
                    )}
                  </span>
                </a>
              </li>
              <li
                className={clsx(
                  activeTab === 'PICKUP_CHECKIN_INFO_REQURED'
                    ? styles['is-active']
                    : ''
                )}
                onClick={() => {
                  dispatch(setDashboardTab('PICKUP_CHECKIN_INFO_REQURED'));
                }}
              >
                <a className={styles['table--link']}>
                  <span className={styles['table--tab']}>
                    {t('Pickup/Checkin Info Required')}
                  </span>
                  <span>
                    {getNumOfTasksLabel(
                      taskCounts,
                      'PICKUP_CHECKIN_INFO_REQURED',
                      activeTab === 'PICKUP_CHECKIN_INFO_REQURED'
                    )}
                  </span>
                </a>
              </li>
              <li
                className={clsx(
                  activeTab === 'PINNED' ? styles['is-active'] : ''
                )}
                onClick={() => {
                  dispatch(setDashboardTab('PINNED'));
                }}
              >
                <a className={styles['table--link']}>
                  <span className={styles['table--tab']}>{t('Pinned')} </span>
                  {numOfPinnedItems !== 0 && (
                    <span
                      className={clsx(styles['num-of-task-base'], pinStyle)}
                    >
                      {numOfPinnedItems}
                    </span>
                  )}
                </a>
              </li>
            </ul>
          </div>

          {loading ? (
            <Loading size="lg" />
          ) : (
            <>
              {activeTab === 'PINNED' ? (
                <>
                  {numOfPinnedItems === 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '100px',
                        marginBottom: '100px',
                      }}
                    >
                      {t('No reservations in "{{tabName}}" status.', {
                        tabName: getTabName(),
                      })}
                    </div>
                  ) : (
                    <DashboardCustomTable
                      items={sortedPinnedTaskShape}
                      columns={visibleColumns}
                      handleOnClickPrevious={handleOnClickPrevious}
                      handleOnClickNext={handleOnClickNext}
                      usePaging={true}
                    />
                  )}
                </>
              ) : (
                <>
                  {getNumOfTasks(taskCounts, activeTab) == 0 ? (
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '100px',
                        marginBottom: '100px',
                      }}
                    >
                      {t('No reservations in "{{tabName}}" status.', {
                        tabName: getTabName(),
                      })}
                    </div>
                  ) : (
                    <DashboardCustomTable
                      items={taskShapes}
                      numOfTasks={getNumOfTasks(taskCounts, activeTab)}
                      columns={visibleColumns}
                      handleOnClickPrevious={handleOnClickPrevious}
                      handleOnClickNext={handleOnClickNext}
                      usePaging={true}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};
