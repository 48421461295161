import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  SystemFeeInvoice,
  SystemFeeInvoiceSummary,
  AgentSystemFeeInvoiceSummary,
} from 'shared/models/swagger';

export const inquiriesSelector = (state: ReduxState) => state.inquiries.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE:
      return action.error;

    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST:
      return true;

    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS:
      return false;

    default:
      return state;
  }
};

const summaries = (
  state: SystemFeeInvoiceSummary[] = [],
  action: any
): SystemFeeInvoiceSummary[] => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS: {
      return [...action.response];
    }

    default:
      return state;
  }
};

const agentSummaries = (
  state: AgentSystemFeeInvoiceSummary[] = [],
  action: any
): AgentSystemFeeInvoiceSummary[] => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS: {
      return [...action.response];
    }

    default:
      return state;
  }
};

const byId = (
  state: Record<string, SystemFeeInvoice> = {},
  action: any
): Record<string, SystemFeeInvoice> => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS: {
      const newState = { ...state };

      newState[action.response?.id] = action.response;

      return newState;
    }

    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST:
      return true;

    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS:
    case FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS:
    case FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

export const partnershipSystemFeeInvoices = combineReducers({
  error,
  loading,
  byId,
  summaries,
  agentSummaries,
  csvLoading,
});
