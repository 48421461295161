import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
  FETCH_PRODUCT_AVAILABILITY_FAILURE,
  FETCH_PRODUCT_AVAILABILITY_REQUEST,
  FETCH_PRODUCT_AVAILABILITY_SUCCESS,
  UPDATE_PRODUCT_INSTANCE_SUCCESS,
  BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS,
  BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ProductAvailability } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    // These actions always trigger the loading screen
    case FETCH_PRODUCT_AVAILABILITY_REQUEST:
      return true;

    // These actions always remove the loading screen
    case FETCH_PRODUCT_AVAILABILITY_FAILURE:
    case FETCH_PRODUCT_AVAILABILITY_SUCCESS:
      return false;

    default:
      return state;
  }
};

const availabilities = (
  state: ProductAvailability[] = [],
  action: any
): ProductAvailability[] => {
  switch (action.type) {
    case FETCH_PRODUCT_AVAILABILITY_SUCCESS:
      return action.response?.product_availabilities ?? [];
    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_AVAILABILITY_FAILURE:
      return action.error;

    case FETCH_PRODUCT_AVAILABILITY_SUCCESS:
      return '';

    default:
      return state;
  }
};

const invalidated = (_: boolean, action: any) => {
  switch (action.type) {
    // Availability data becomes invalidated when product instances are edited manually.
    case UPDATE_PRODUCT_INSTANCE_SUCCESS:
    case BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS:
    case BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS:
      return true;
    default:
      return false;
  }
};

const reducer = combineReducers({
  error,
  loading,
  availabilities,
  invalidated,
});

export const productAvailability = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  // Reset data to initial values when impersonating or logging out
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
