import * as Swagger from 'shared/models/swagger';

type CognitoUserToken = {
  sub: string;
  'custom:bookmarks': string;
  'custom:organization_id': string;
  'custom:org_parent_id'?: string;
  'custom:organization_name'?: string;
  'custom:alt_org_id'?: string;
  'custom:alt_org_type'?: 'AGENT' | 'SUPPLIER';
  'custom:alt_org_name'?: string;
  'custom:api_access_type':
    | 'AGENT'
    | 'AGENT_EXTERNAL_API'
    | 'SUPPLIER'
    | 'DIRECT'
    | 'GATEWAY';
  'custom:user_role':
    | 'ADMIN'
    | 'RESERVATIONIST'
    | 'ACCOUNTANT'
    | 'GUIDE'
    | 'nutmeg_admin';
  'custom:organization_type': 'AGENT' | 'SUPPLIER';
  'custom:permissions'?: string;
  'custom:manageable_org_ids'?: string;
  name?: string;
  email: string;
};
export const buildImpersonateToken = (account: Swagger.Account) =>
  encodeURIComponent(JSON.stringify(account));
export const convertCognitoTokenToAccount = (
  user: CognitoUserToken
): Swagger.Account => {
  const role = user['custom:user_role'];
  const api_access_type = getAccessType(user);
  return {
    id: user.sub,
    email: user.email,
    name: user.name,
    role,
    organization_id: user['custom:organization_id'],
    organization_type: user['custom:organization_type'],
    organization_name: user['custom:organization_name'],
    organization_parent_id: user['custom:org_parent_id'],
    alternate_organization_id: user['custom:alt_org_id'],
    alternate_organization_type: user['custom:alt_org_type'],
    alternate_organization_name: user['custom:alt_org_name'],
    bookmarks: user['custom:bookmarks'],
    permissions: user['custom:permissions'],
    manageable_organization_ids: user['custom:manageable_org_ids'],
    api_access_type,
  };
};

const getAccessType = (user: CognitoUserToken): any => {
  if (user['custom:user_role'] === 'nutmeg_admin') {
    return 'nutmeg_admin';
  }

  return user['custom:api_access_type'] || user['custom:organization_type'];
};
