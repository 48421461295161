import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorModal } from 'client/pages/ProductDetails/ProductEditorModal/ProductEditorModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from './BookingWidgetSettingsFormValues';
import { BookingWidgetSelectableParticipantRulesEditor } from './BookingWidgetSelectableParticipantRulesEditor';

type Props = {
  onClose: () => void;
};
export const BookingWidgetSelectableParticipantRulesEditorModal = ({
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product, activeUserOrganization),
    [activeUserOrganization, product]
  );
  return (
    <ProductEditorModal
      open={true}
      onClose={onClose}
      title={t('Booking Website Selectable Participant Rules')}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertFormValuesToProductPatch}
    >
      <BookingWidgetSelectableParticipantRulesEditor />
    </ProductEditorModal>
  );
};
