import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { changeUserAttributes } from 'client/actions/user';
import { loggedInAccountSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers/index';
import styles from 'client/pages/v3/Settings/Settings.module.css';

export const AccountSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInAccount = useSelector(loggedInAccountSelector);
  const attrMapError = useSelector(
    (state: ReduxState) => state.user.changingAttrError
  );
  const attrMapSuccess = useSelector(
    (state: ReduxState) => state.user.changingAttrSuccess
  );

  const [name, setName] = React.useState<string>(loggedInAccount?.name || '');
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    setName(loggedInAccount?.name || '');
  }, [loggedInAccount]);

  React.useEffect(() => {
    if (attrMapSuccess) {
      setSuccess(true);
    }
  }, [attrMapSuccess]);

  React.useEffect(() => {
    if (attrMapError != '') {
      setError(true);
    }
  }, [attrMapError]);

  const onChangeAttributes = (newAttr: Record<string, string>) =>
    dispatch(changeUserAttributes(newAttr));

  const buildAttrMap = () => {
    return {
      name: name,
    };
  };

  return (
    <div>
      <div className={styles['input-box']}>
        <div
          style={{
            fontWeight: 'var(--text-semibold)',
            fontSize: 'var(--text-sm-size)',
            lineHeight: 'var(--text-sm-height)',
            marginBottom: '5px',
          }}
        >
          {t('Email')}
        </div>
        <div>{loggedInAccount?.email}</div>
      </div>
      <div className={styles['input-box']}>
        <div
          style={{
            fontWeight: 'var(--text-semibold)',
            fontSize: 'var(--text-sm-size)',
            lineHeight: 'var(--text-sm-height)',
            marginBottom: '10px',
          }}
        >
          {t('Full Name')}
        </div>
        <div
          style={{
            marginBottom: '20px',
          }}
        >
          <TextField
            name="name"
            value={name}
            onChange={(value) => setName(value)}
          />
        </div>
      </div>
      {error && (
        <Snackbar
          color="error"
          text={t('Update failed')}
          shouldShow={error}
          onClose={() => setError(false)}
        />
      )}
      {success && (
        <Snackbar
          color="success"
          text={t('Update succeeded')}
          shouldShow={success}
          onClose={() => setSuccess(false)}
        />
      )}
      <Button
        color="primary"
        size="lg"
        text={t('Save')}
        onClick={() => onChangeAttributes(buildAttrMap())}
      />
    </div>
  );
};
