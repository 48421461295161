import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { Box } from 'client/components/Box/Box';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { updateInquiry } from 'client/actions/inquiries';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import emailOpenedIcon from 'client/images/ic_email_opened.svg';
import { ActionSource, Inquiry } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import { BulkInquiryReservationList } from './BulkInquiryReservationList';
import { InquiryResponseSection } from './InquiryResponseSection';
import styles from './InquiryItem.module.css';
import { EditTagsModal } from './EditTagsModal';

const getGuestName = (item: Inquiry): string => {
  if (item.customer_given_name || item.customer_family_name) {
    if (
      isKanaWord(item.customer_given_name ?? '') &&
      isKanaWord(item.customer_family_name ?? '')
    ) {
      return `${item.customer_family_name} ${item.customer_given_name}`;
    }
    return `${item.customer_given_name} ${item.customer_family_name}`;
  }

  return '';
};

interface Props {
  item: Inquiry;
  showArchiveReactivateButtons?: boolean;
  printMode?: boolean;
}

export const InquiryItem = ({
  item,
  showArchiveReactivateButtons,
  printMode,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showResponseSection, setShowResponseSection] = React.useState(false);
  const [showEditTagsModal, setShowEditTagsModal] = React.useState(false);
  const [showStatusChangeConfirmModal, setShowStatusChangeConfirmModal] =
    React.useState(false);
  const loading = useSelector((state: ReduxState) => state.inquiries.loading);
  const activeUser = useSelector(activeUserSelector);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const formattedActionSource = (
    actionSource: ActionSource | undefined,
    guestName: string
  ): string => {
    if (!actionSource) {
      return '';
    }
    if (actionSource.entity_type === 'GUEST_ENTITY_TYPE') {
      if (guestName) {
        return `${guestName} (${t('Guest')})`;
      } else {
        return t('Guest');
      }
    }

    return `${actionSource.entity_name} - ${actionSource.entity_description}`;
  };

  return (
    <div>
      {!printMode && (
        <div className={styles['tags-container']}>
          {(item?.tags ?? []).length > 0 && (
            <ul className={styles['tag-list']}>
              {item?.tags?.map((tag, idx) => (
                <li key={idx}>{tag}</li>
              ))}
            </ul>
          )}

          {hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
            <Box ml="auto">
              <Button
                style="yellow"
                size="small"
                onClick={() => setShowEditTagsModal(true)}
              >
                {t('Edit Tags')}
              </Button>
            </Box>
          )}
          {showEditTagsModal && (
            <EditTagsModal
              onClose={() => setShowEditTagsModal(false)}
              inquiry={item}
            />
          )}

          {showArchiveReactivateButtons && (
            <Box ml={2}>
              {item.status === 'ARCHIVED' ? (
                <>
                  {hasCustomUserRoleWritePermissions(
                    activeUser,
                    'INQUIRY.LIST'
                  ) && (
                    <Button
                      loading={loading}
                      style="gray"
                      size="small"
                      onClick={() => {
                        setShowStatusChangeConfirmModal(true);
                      }}
                    >
                      {t('Re-activate')}
                    </Button>
                  )}
                  {showStatusChangeConfirmModal && (
                    <MessageModal
                      insertRoot
                      open={true}
                      onClose={() => setShowStatusChangeConfirmModal(false)}
                      title={t('Re-activate Inquiry')}
                      message={t(
                        'Are you sure you wish to re-activate this inquiry?'
                      )}
                      onSubmit={async () => {
                        await dispatch(
                          updateInquiry(item.id ?? '', {
                            status: item.messages?.some(
                              (message) =>
                                message.action_source?.entity_type ===
                                'SUPPLIER_ENTITY_TYPE'
                            )
                              ? 'RESPONDED'
                              : 'RECEIVED',
                          })
                        );
                        setShowStatusChangeConfirmModal(false);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {hasCustomUserRoleWritePermissions(
                    activeUser,
                    'INQUIRY.LIST'
                  ) && (
                    <Button
                      loading={loading}
                      style="blue"
                      size="small"
                      onClick={() => {
                        setShowStatusChangeConfirmModal(true);
                      }}
                    >
                      {t('Archive')}
                    </Button>
                  )}
                  {showStatusChangeConfirmModal && (
                    <MessageModal
                      insertRoot
                      open={true}
                      onClose={() => setShowStatusChangeConfirmModal(false)}
                      title={t('Archive Inquiry')}
                      message={t(
                        'Are you sure you wish to archive this inquiry?'
                      )}
                      onSubmit={async () => {
                        await dispatch(
                          updateInquiry(item.id ?? '', {
                            status: 'ARCHIVED',
                          })
                        );
                        setShowStatusChangeConfirmModal(false);
                      }}
                    />
                  )}
                </>
              )}
            </Box>
          )}
          <Box ml={2}>
            <a
              href={`/inquiries/${item.id}/print`}
              target="_blank"
              rel="noreferrer"
            >
              <Button.Sub size="small">{t('Printable Page')}</Button.Sub>
            </a>
          </Box>
        </div>
      )}
      {item.messages?.map((message, messageIdx) => (
        <div
          className={clsx(
            styles['message'],
            item.initial_communication_type === 'BULK_EMAIL_BY_SUPPLIER' &&
              styles['bulk'],
            message.action_source?.entity_type === 'SUPPLIER_ENTITY_TYPE' &&
              styles['response']
          )}
          key={messageIdx}
        >
          <div className={styles['message-info']}>
            <div className={styles['email-opened-icon']}>
              {message.email_opened_at_date_time_utc && (
                <img src={emailOpenedIcon} />
              )}
            </div>
            <div className={styles['message-from']}>
              {formattedActionSource(message.action_source, getGuestName(item))}
            </div>
            <div className={styles['message-date']}>
              {moment(message.received_date_time_utc)
                .locale(locale)
                .format('lll')}
            </div>
            {message.sms_info?.segment_count && (
              <Box ml={1}>({message.sms_info?.segment_count})</Box>
            )}
          </div>
          <div className={styles['message-body']}>
            {printMode ? (
              message.body
            ) : (
              <TruncatedTextWithSeeMoreButton text={message.body ?? ''} />
            )}
            {(message.attachments ?? []).length > 0 && (
              <Box mt={2}>
                <ul>
                  {message.attachments?.map((a, idx) => (
                    <li key={idx}>
                      <a
                        className={baseStyles['base-link']}
                        href={a.url}
                        download
                      >
                        {a.filename}
                      </a>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </div>
        </div>
      ))}
      {!printMode &&
        !showResponseSection &&
        item.initial_communication_type !== 'BULK_EMAIL_BY_SUPPLIER' &&
        item.initial_communication_type !== 'BULK_SMS_BY_SUPPLIER' &&
        hasCustomUserRoleWritePermissions(activeUser, 'INQUIRY.LIST') && (
          <div className={styles['message-respond-button-container']}>
            <Button
              onClick={() => setShowResponseSection(true)}
              style="green"
              size="small"
            >
              {t('Reply')}
            </Button>
          </div>
        )}
      {showResponseSection && (
        <InquiryResponseSection
          inquiry={item}
          onClose={() => setShowResponseSection(false)}
        />
      )}
      {(item.initial_communication_type === 'BULK_EMAIL_BY_SUPPLIER' ||
        item.initial_communication_type === 'BULK_SMS_BY_SUPPLIER') && (
        <Box mt={4}>
          <BulkInquiryReservationList inquiry={item} />
        </Box>
      )}
    </div>
  );
};
