import { TranslateFuncType } from 'client/components/Translate';

export const formattedDuration = (
  durationInSeconds: number,
  t: TranslateFuncType
): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  if (hours) {
    return t('{{hours}}h{{minutes}}m{{seconds}}s', {
      hours,
      minutes,
      seconds,
    });
  }
  if (minutes) {
    return t('{{minutes}}m{{seconds}}s', {
      minutes,
      seconds,
    });
  }

  return t('{{seconds}}s', {
    seconds,
  });
};
