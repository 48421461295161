import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type { MarketingAutomationTriggerConditionParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchMarketingAutomationTriggerConditionsRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST,
});

const fetchMarketingAutomationTriggerConditionsSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS,
  response,
});

const fetchMarketingAutomationTriggerConditionsFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE,
  error,
});

let fetchMarketingAutomationTriggerConditionsCancel: () => void | undefined;
export const fetchMarketingAutomationTriggerConditions =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchMarketingAutomationTriggerConditionsCancel?.();
    dispatch(fetchMarketingAutomationTriggerConditionsRequest());
    axios
      .get('/api/marketingautomationtriggerconditions', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchMarketingAutomationTriggerConditionsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationTriggerConditionsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(
            fetchMarketingAutomationTriggerConditionsFailure('canceled')
          );
        dispatch(fetchMarketingAutomationTriggerConditionsFailure(error));
      });
  };

const fetchMarketingAutomationTriggerConditionRequest = () => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
});

const fetchMarketingAutomationTriggerConditionSuccess = (response: any) => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  response,
});

const fetchMarketingAutomationTriggerConditionFailure = (error: any) => ({
  type: FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  error,
});

export const fetchMarketingAutomationTriggerCondition =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchMarketingAutomationTriggerConditionRequest());
    axios
      .get(`/api/marketingautomationtriggerconditions/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchMarketingAutomationTriggerConditionSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchMarketingAutomationTriggerConditionFailure('canceled'));
        else dispatch(fetchMarketingAutomationTriggerConditionFailure(error));
      });
  };

const deleteMarketingAutomationTriggerConditionRequest = (id: string) => ({
  type: DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  id,
});

const deleteMarketingAutomationTriggerConditionSuccess = (
  response: any,
  id: string
) => ({
  type: DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  response,
  id,
});

const deleteMarketingAutomationTriggerConditionFailure = (error: any) => ({
  type: DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  error,
});

export const deleteMarketingAutomationTriggerCondition =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteMarketingAutomationTriggerConditionRequest(id));
    axios
      .delete(`/api/marketingautomationtriggerconditions/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(
          deleteMarketingAutomationTriggerConditionSuccess(response.data, id)
        );
      })
      .catch((error) =>
        dispatch(deleteMarketingAutomationTriggerConditionFailure(error))
      );
  };

const createMarketingAutomationTriggerConditionRequest = (
  newMarketingAutomationTriggerCondition: MarketingAutomationTriggerConditionParams
) => ({
  type: CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  newMarketingAutomationTriggerCondition,
});

const createMarketingAutomationTriggerConditionSuccess = (response: any) => ({
  type: CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  response,
});

const createMarketingAutomationTriggerConditionFailure = (error: any) => ({
  type: CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  error,
});

export const createMarketingAutomationTriggerCondition =
  (
    newMarketingAutomationTriggerCondition: MarketingAutomationTriggerConditionParams
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(
      createMarketingAutomationTriggerConditionRequest(
        newMarketingAutomationTriggerCondition
      )
    );
    return axios
      .post(
        '/api/marketingautomationtriggerconditions',
        newMarketingAutomationTriggerCondition,
        {
          headers: getHTTPRequestHeaders(getState()),
        }
      )
      .then((response) => {
        dispatch(
          createMarketingAutomationTriggerConditionSuccess(response.data)
        );
      })
      .catch((err) => {
        dispatch(createMarketingAutomationTriggerConditionFailure(err.message));
      });
  };

const updateMarketingAutomationTriggerConditionRequest = (
  id: string,
  patch: MarketingAutomationTriggerConditionParams
) => ({
  type: UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST,
  id,
  patch,
});

const updateMarketingAutomationTriggerConditionSuccess = (response: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS,
  response,
});

const updateMarketingAutomationTriggerConditionFailure = (error: any) => ({
  type: UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE,
  error,
});

export const updateMarketingAutomationTriggerCondition =
  (id: string, patch: MarketingAutomationTriggerConditionParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateMarketingAutomationTriggerConditionRequest(id, patch));
    return axios
      .patch(`/api/marketingautomationtriggerconditions/${id}`, patch, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(
          updateMarketingAutomationTriggerConditionSuccess(response.data)
        );
      })
      .catch((err) => {
        dispatch(updateMarketingAutomationTriggerConditionFailure(err.message));
      });
  };
