import moment from 'moment-timezone';
import querystring from 'query-string';

import type { ReservationReportSettings } from 'client/components/ReservationReportSettingsModal/ReservationReportSettingsModal';

export const convertReservationReportSettingsToQueryParams = (
  req: ReservationReportSettings
): Record<string, string> => {
  const starts = [];
  const ends = [];
  let start = '';
  let end = '';

  if (req.dateFilterPreset !== 'CUSTOM') {
    let count = 0;
    const match = /^(\d+)_DAY.*$/.exec(req.dateFilterPreset || '');

    if (match && match.length > 0) {
      count = Number(match[1]);
    }

    start = moment().subtract(count, 'days').format('YYYY-MM-DD');
    end = moment().subtract(1, 'days').format('YYYY-MM-DD');
  } else {
    start = req.startDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    end = req.endDate || moment().format('YYYY-MM-DD');
  }

  starts.push(start);
  ends.push(end);

  if (req.compare) {
    start =
      req.compareStartDate || moment().subtract(7, 'days').format('YYYY-MM-DD');
    end = req.compareEndDate || moment().format('YYYY-MM-DD');
    starts.push(start);
    ends.push(end);
  }

  // to create array as the value of starts/end. When the length is 1, it is changed to normal string valuek (not array)
  starts.push('END');
  ends.push('END');
  const ranges = {
    starts: starts,
    ends: ends,
  };
  return {
    basis_date_type: req.basisDateType || 'BOOKING_DATE',
    ranges: querystring.stringify(ranges),
    filter: querystring.stringify({
      'booking_source.source_type':
        req.bookingSourceTypes.length > 0 ? req.bookingSourceTypes : '',
      status: req.statuses.length > 0 ? req.statuses : '',
      product_id: req.productIds.length > 0 ? req.productIds : '',
      agent_id: req.agentIds.length > 0 ? req.agentIds : '',
    }),
  };
};
export const getRangeText = (req: ReservationReportSettings): string => {
  let start = 'N/A';
  let end = 'N/A';

  if (req.dateFilterPreset) {
    const now = moment();
    let count = 0;
    const match = /^(\d+)_DAY.*$/.exec(req.dateFilterPreset || '');

    if (match && match.length > 0) {
      count = Number(match[1]);
    }

    start = now.subtract(count, 'days').format('YYYY-MM-DD');
    end = now.format('YYYY-MM-DD');
  }

  return `${start} - ${end}`;
};
