import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import { getHTTPRequestHeaders } from 'client/actions';
import {
  BATCH_UPDATE_PRODUCT_PRICES_REQUEST,
  BATCH_UPDATE_PRODUCT_PRICES_FAILURE,
  BATCH_UPDATE_PRODUCT_PRICES_SUCCESS,
  DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST,
  DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE,
  DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS,
  DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST,
  DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE,
  DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS,
  FETCH_PRODUCT_PRICES_REQUEST,
  FETCH_PRODUCT_PRICES_FAILURE,
  FETCH_PRODUCT_PRICES_SUCCESS,
} from 'client/constants/ActionTypes';
import type { Product, ProductPricesExportParams } from 'shared/models/swagger';
import type { ReduxState } from 'client/reducers';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchProductPricesRequest = () => ({
  type: FETCH_PRODUCT_PRICES_REQUEST,
});
const fetchProductPricesSuccess = (response: any) => ({
  type: FETCH_PRODUCT_PRICES_SUCCESS,
  response,
});
const fetchProductPricesFailure = (error: any) => ({
  type: FETCH_PRODUCT_PRICES_FAILURE,
  error,
});

let fetchProductPricesCancel: () => void | typeof undefined;
export const fetchProductPrices =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchProductPricesCancel && fetchProductPricesCancel();
    dispatch(fetchProductPricesRequest());

    const params = {
      include_prices: true,
    };
    axios
      .get('/api/products', {
        params,
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchProductPricesCancel = c;
        }),
      })
      .then((result) => dispatch(fetchProductPricesSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchProductPricesFailure('canceled'));
        else dispatch(fetchProductPricesFailure(error));
      });
  };

const batchUpdateProductPricesRequest = () => ({
  type: BATCH_UPDATE_PRODUCT_PRICES_REQUEST,
});

const batchUpdateProductPricesFailure = (error: any) => ({
  type: BATCH_UPDATE_PRODUCT_PRICES_FAILURE,
  error,
});

const batchUpdateProductPricesSuccess = (response: any) => ({
  type: BATCH_UPDATE_PRODUCT_PRICES_SUCCESS,
  response,
});

export const batchUpdateProductPrices =
  (products: Product[]) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(batchUpdateProductPricesRequest());
    const promises = products.map((product) =>
      axios
        .patch(`/api/products/${product.id}`, product, {
          headers: getHTTPRequestHeaders(getState()),
        })
        .then((result) => result.data)
    );
    return Promise.all(promises)
      .then((responses) => dispatch(batchUpdateProductPricesSuccess(responses)))
      .catch((error) =>
        dispatch(batchUpdateProductPricesFailure(error.response.data.message))
      );
  };

const downloadProductPricesPDFRequest = () => ({
  type: DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST,
});

const downloadProductPricesPDFSuccess = () => ({
  type: DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS,
});

const downloadProductPricesPDFFailure = (error: string) => ({
  type: DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE,
  error,
});

export const downloadProductPricesPDF =
  (req: ProductPricesExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadProductPricesPDFRequest());
    axios
      .post('/api/products/prices/pdf', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(downloadProductPricesPDFSuccess());
        FileSaver.saveAs(response.data, 'product-prices.pdf');
      })
      .catch((error) => dispatch(downloadProductPricesPDFFailure(error)));
  };

const downloadProductPricesCSVRequest = () => ({
  type: DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST,
});

const downloadProductPricesCSVSuccess = () => ({
  type: DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS,
});

const downloadProductPricesCSVFailure = (error: string) => ({
  type: DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE,
  error,
});

export const downloadProductPricesCSV =
  (req: ProductPricesExportParams) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(downloadProductPricesCSVRequest());
    axios
      .post('/api/products/prices/csv', req, {
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(downloadProductPricesCSVSuccess());
        FileSaver.saveAs(response.data, 'product-prices.csv');
      })
      .catch((error) => dispatch(downloadProductPricesCSVFailure(error)));
  };
