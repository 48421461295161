import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { SystemFeeReservations } from 'client/pages/SystemFee/SystemFeeReservations/SystemFeeReservations';

export const PartnershipSystemFeeReservations = () => {
  return (
    <PartnershipModeContext.Provider value={{ partnershipMode: true }}>
      <SystemFeeReservations />
    </PartnershipModeContext.Provider>
  );
};
