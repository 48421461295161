import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import { FormValues } from './formValues';
import styles from './ComposeMessage.module.css';
import { MessageEditor } from './MessageEditor';
import { NewsletterTargetsButton } from './NewsletterTargetsButton';

interface Props {
  onBackClick: () => void;
  onSave: () => void;
}

export const ConfirmAndSend = ({ onBackClick, onSave }: Props) => {
  const { t } = useTranslation();

  const form = useForm();

  const values = form.getState().values as FormValues;
  const allSegments = useSelector(
    (state: ReduxState) => state.newsletterSegments.all
  );
  const segments = values?.segments.map((segmentId) =>
    allSegments.find((s) => s.id === segmentId)
  );

  const { id } = useParams<{ id: string }>();

  const existingNewsletter = useSelector((state: ReduxState) =>
    state.newsletters.all.find((n) => n.id === id)
  );

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button style="gray" size="middle" onClick={onBackClick}>
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button onClick={onSave} style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
        <Box ml={2}>
          <Button type="submit" style="green" size="middle">
            {t('Send Email')}
          </Button>
        </Box>
      </div>

      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Confirm Email Details')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <FieldWrapper label={t('Target Segments')}>
            <ul>
              {segments.map((segment, idx) => (
                <li key={idx}>{segment?.name}</li>
              ))}
            </ul>
          </FieldWrapper>
          <FieldWrapper
            label={
              <Box display="flex">
                <Box mr={1}>{t('Total Targets: ')}</Box>
                {existingNewsletter && (
                  <NewsletterTargetsButton newsletter={existingNewsletter} />
                )}
              </Box>
            }
          />
          <FieldWrapper label={t('Subject')}>{values?.subject}</FieldWrapper>
          <FieldWrapper label={t('Message')}>
            <MessageEditor readOnly />
          </FieldWrapper>
        </div>
      </div>
    </div>
  );
};
