import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  SEARCH_RESERVATIONS_REQUEST,
  SEARCH_RESERVATIONS_FAILURE,
  SEARCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATION_SEARCH_CSV_REQUEST,
  FETCH_RESERVATION_SEARCH_CSV_FAILURE,
  FETCH_RESERVATION_SEARCH_CSV_SUCCESS,
  SET_LAST_RESERVATION_SEARCH_QUERY,
  SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY,
  SET_SHOULD_SHOW_DETAILED_CONDITIONS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const searchReservationsRequest = () => ({
  type: SEARCH_RESERVATIONS_REQUEST,
});

const searchReservationsSuccess = (response: any) => ({
  type: SEARCH_RESERVATIONS_SUCCESS,
  response,
});

const searchReservationsFailure = (error: string) => ({
  type: SEARCH_RESERVATIONS_FAILURE,
  error,
});

let searchReservationsCancel: () => void | typeof undefined;
export const searchReservations =
  (
    query: Swagger.SearchReservationsRequest,
    pageSize: number,
    offset: number,
    useAlternateOrganization?: boolean
  ) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    searchReservationsCancel && searchReservationsCancel();
    dispatch(searchReservationsRequest());
    axios
      .get('/api/reservations/search', {
        params: {
          ...query,
          page_size: pageSize,
          offset,
        },
        headers: getHTTPRequestHeaders(getState(), {
          useAlternateOrganization,
        }),
        cancelToken: new axios.CancelToken(function executor(c) {
          searchReservationsCancel = c;
        }),
      })
      .then((result) => dispatch(searchReservationsSuccess(result.data)))
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(searchReservationsFailure('canceled'));
        } else {
          dispatch(
            searchReservationsFailure(
              error?.response?.data?.message || error?.response?.statusText
            )
          );
        }
      });
  };

export const setLastReservationSearchQuery = (
  searchFilters: SearchReservationsRequest
) => ({
  type: SET_LAST_RESERVATION_SEARCH_QUERY,
  searchFilters,
});

export const setLastAnnualPassReservationSearchQuery = (
  searchFilters: SearchReservationsRequest
) => ({
  type: SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY,
  searchFilters,
});

const fetchReservationSearchCSVRequest = () => ({
  type: FETCH_RESERVATION_SEARCH_CSV_REQUEST,
});

const fetchReservationSearchCSVSuccess = () => ({
  type: FETCH_RESERVATION_SEARCH_CSV_SUCCESS,
});

const fetchReservationSearchCSVFailure = (error: string) => ({
  type: FETCH_RESERVATION_SEARCH_CSV_FAILURE,
  error,
});

export const fetchReservationSearchCSV =
  (queryParams?: Record<string, any>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchReservationSearchCSVRequest());
    axios
      .get(`/api/reservations/searchcsv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'reservations.csv');
        dispatch(fetchReservationSearchCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchReservationSearchCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };

export const setShouldShowDetailedConditions = (flag: boolean) => ({
  type: SET_SHOULD_SHOW_DETAILED_CONDITIONS,
  flag,
});
