import _ from 'lodash';
import moment from 'moment-timezone';

import type { TranslateFuncType } from 'client/components/Translate';
import { operationAllowed } from 'shared/models/access';
import {
  bookingDeadlineExpired,
  productInstanceAllChannelsAreClosed,
  getInstanceDedicatedAllotments,
  getInstanceError,
  hasBookingDeadline,
} from 'client/libraries/util/util';
import { formattedTotalAllotmentSlots } from 'client/libraries/util/formattedTotalAllotmentSlots';
import {
  getAvailabilityBackgroundColorClass,
  getAvailabilityIconType,
} from 'client/libraries/util/productInstanceShape';
import type { CustomCalendarEvent } from 'client/components/CustomCalendar/CustomCalendar';
import type { Account, ProductInstance } from 'shared/models/swagger';

const isMultidayProductInstanceId = (id: string): boolean => {
  const parts = id.split(':');
  return parts.length === 3;
};

export const getCustomCalendarEvents = (
  activeUser: Account | null,
  timezone: string,
  productInstances: ProductInstance[],
  locale: string,
  t: TranslateFuncType,
  shouldRejectRequestBookingsBeyondCapacity: boolean
): CustomCalendarEvent[] => {
  const sortedProductInstances = _.orderBy(productInstances, [
    'start_date_time_utc',
    'end_date_time_utc',
  ]);

  const result: CustomCalendarEvent[] = [];
  sortedProductInstances.forEach((instance) => {
    // Remove MultidayProductInstance
    if (isMultidayProductInstanceId(instance.id)) {
      return;
    }

    const tzStartMoment = moment
      .tz(instance.start_date_time_utc, timezone)
      .locale(locale);
    let time = tzStartMoment.format('LT');

    if (instance.start_time_description) {
      time = `${time} ${instance.start_time_description}`;
    }

    const userIsPassthroughSupplier =
      activeUser?.organization_type === 'SUPPLIER' &&
      instance.shared_allotment_references &&
      instance.shared_allotment_references.passthrough_base_product_instance_id;
    const allChannelsClosed = productInstanceAllChannelsAreClosed(instance);

    if (
      operationAllowed(activeUser, 'write', 'reservationConfirmation') &&
      !userIsPassthroughSupplier
    ) {
      const bookedSlots =
        (instance.occupied_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(instance),
          (a) => a.occupied_slots || 0
        );

      const totalSlots =
        (instance.total_slots || 0) +
        _.sumBy(
          getInstanceDedicatedAllotments(instance),
          (a) => a.total_slots || 0
        );

      const instanceError = getInstanceError(instance, t);
      const { title, style } = instanceError
        ? {
            title: t('Error'),
            style: {
              frontColor: 'red100',
            },
          }
        : {
            title: `${bookedSlots} / ${formattedTotalAllotmentSlots(
              totalSlots
            )}`,
            style: {
              backgroundColor: getAvailabilityBackgroundColorClass(
                instance,
                bookedSlots,
                totalSlots
              ),
              iconType: getAvailabilityIconType(
                instance,
                bookedSlots,
                totalSlots,
                shouldRejectRequestBookingsBeyondCapacity
              ),
            },
          };
      result.push({
        id: instance.id,
        title,
        resource: instance,
        style,
        time,
      });
    } else {
      const hasRequestDeadline = hasBookingDeadline(instance, 'REQUEST');
      const requestDeadlineExpired = bookingDeadlineExpired(
        instance,
        'REQUEST'
      );
      const hasInstantDeadline = hasBookingDeadline(instance, 'INSTANT');
      const instantDeadlineExpired = bookingDeadlineExpired(
        instance,
        'INSTANT'
      );

      if (
        (!hasRequestDeadline || requestDeadlineExpired) &&
        (!hasInstantDeadline || instantDeadlineExpired)
      ) {
        return;
      }

      const availableSlots = instance.available_slots || 0;
      const { title, style } =
        instance.is_closed || allChannelsClosed
          ? {
              title: t('Closed'),
              style: {
                backgroundColor: 'gray',
              },
            }
          : (availableSlots <= 0 ||
              !hasInstantDeadline ||
              instantDeadlineExpired) &&
            hasRequestDeadline &&
            !requestDeadlineExpired
          ? {
              title: '',
              style: {
                backgroundColor: 'yellow',
                iconType: 'REQUEST',
              },
            }
          : {
              title: t('{{count}} spot', {
                count: formattedTotalAllotmentSlots(availableSlots),
              }),
              style: {
                backgroundColor: '#46d6db',
              },
            };
      result.push({
        id: instance.id,
        title,
        resource: instance,
        style,
        time,
      });
    }
  });
  return result;
};
