import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { ReduxState } from 'client/reducers';
import { Button, FieldWrapper, Input } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { fetchReservationsByID } from 'client/actions/reservations';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import { FormValues } from './formValues';
import styles from './SystemFeeFareAdjustmentEditor.module.css';

export const SystemFeeFareAdjustmentEditor = () => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const currency = useSelector(defaultProductCurrencySelector);
  const reservationsById = useSelector(
    (state: ReduxState) => state.reservations.byID
  );
  const dispatch = useDispatch();

  const form = useForm<FormValues>();
  const formState = useFormState<FormValues>();

  const { exemptedReservationIds, fareAdjustments } = formState.values;

  React.useEffect(() => {
    dispatch(fetchReservationsByID(exemptedReservationIds));
  }, [exemptedReservationIds]);

  const [editingReservationId, setEditingReservationId] = React.useState('');

  const [editingFareAdjustmentAmount, setEditingFareAdjustmentAmount] =
    React.useState('');
  const [editingFareAdjustmentNote, setEditingFareAdjustmentNote] =
    React.useState('');

  const exemptedReservations =
    exemptedReservationIds
      ?.map((reservationId) => reservationsById[reservationId])
      .filter((r) => !!r) ?? [];

  return (
    <div>
      <Box display="flex" alignItems="flex-end" mb={2}>
        <Box width="60%">
          <Input
            label={t('Reservation #')}
            value={editingReservationId}
            onChange={(e, { value }) => setEditingReservationId(value)}
          />
        </Box>
        <Box ml="auto" width="30%">
          <Button
            style="blue"
            size="middle"
            onClick={() => {
              form.change('exemptedReservationIds', [
                ...new Set([
                  ...(exemptedReservationIds ?? []),
                  editingReservationId,
                ]),
              ]);
              setEditingReservationId('');
            }}
          >
            {t('Exempt Booking')}
          </Button>
        </Box>
      </Box>
      {exemptedReservations.length > 0 && (
        <div className={componentStyles['c-table-nowrap']}>
          <table>
            <tbody>
              <tr>
                <th className={baseStyles['base-t-32']} />
                <th className={baseStyles['base-t-112']}>{t('#')}</th>
                <th className={baseStyles['base-t-112']}>
                  {t('Participation')}
                </th>
                <th className={baseStyles['base-t-112']}>{t('Gross')}</th>
              </tr>
              {exemptedReservations.map((reservation) => (
                <tr key={reservation.id}>
                  <td className={baseStyles['base-t-32']}>
                    <DeleteIcon
                      onClick={() =>
                        form.change(
                          'exemptedReservationIds',
                          exemptedReservationIds.filter(
                            (id) => id !== reservation.id
                          )
                        )
                      }
                    />
                  </td>
                  <td className={baseStyles['base-t-112']}>{reservation.id}</td>
                  <td className={baseStyles['base-t-112']}>
                    {moment(reservation.start_date_time_utc)
                      .locale(locale)
                      .format('lll')}
                  </td>
                  <td className={baseStyles['base-t-112']}>
                    {getFormattedAmount(
                      reservation?.billing_info?.amount_gross
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Box mt={2} display="flex">
        <Box width="50%">
          <FieldWrapper label={t('Fare Adjustment Amount')}>
            <div className={styles['page-productsRegist__priceMain__price']}>
              <p>{currency}</p>
              <Input
                value={editingFareAdjustmentAmount}
                onChange={(e, { value }) => {
                  if (
                    !currencyInputAllowed(currency, value) &&
                    (value[0] !== '-' ||
                      !currencyInputAllowed(currency, value.substring(1)))
                  ) {
                    return;
                  }

                  setEditingFareAdjustmentAmount(value);
                }}
              />
            </div>
          </FieldWrapper>
        </Box>
        <Box width="50%">
          <Input
            label={t('Remarks')}
            value={editingFareAdjustmentNote}
            onChange={(e, { value }) => setEditingFareAdjustmentNote(value)}
          />
        </Box>
      </Box>
      <Box mt={2} mb={2} display="flex">
        <Box ml="auto">
          <Button
            style="blue"
            size="middle"
            onClick={() => {
              form.change('fareAdjustments', [
                ...new Set([
                  ...(fareAdjustments ?? []),
                  {
                    amount: editingFareAdjustmentAmount,
                    note: editingFareAdjustmentNote,
                  },
                ]),
              ]);
              setEditingFareAdjustmentAmount('');
              setEditingFareAdjustmentNote('');
            }}
          >
            {t('Add Adjustment')}
          </Button>
        </Box>
      </Box>
      {fareAdjustments && fareAdjustments.length > 0 && (
        <div className={componentStyles['c-table-nowrap']}>
          <table>
            <tbody>
              <tr>
                <th className={baseStyles['base-t-32']} />
                <th className={baseStyles['base-t-112']}>
                  {t('Adjusted Amount')}
                </th>
                <th className={baseStyles['base-t-112']}>{t('Remarks')}</th>
              </tr>
              {fareAdjustments.map((adjustment, idx) => (
                <tr key={idx}>
                  <td className={baseStyles['base-t-32']}>
                    <DeleteIcon
                      onClick={() =>
                        form.change(
                          'fareAdjustments',
                          fareAdjustments.filter(
                            (adjustment, adjustmentIdx) => idx !== adjustmentIdx
                          )
                        )
                      }
                    />
                  </td>
                  <td className={baseStyles['base-t-112']}>
                    {adjustment.amount}
                  </td>
                  <td className={baseStyles['base-t-112']}>
                    {adjustment.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
