import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { fetchRestaurantOrders } from 'client/actions/restaurantOrders';
import { ReduxState } from 'client/reducers';

import { OrderDetails } from './OrderDetails/OrderDetails';
import { OrderList } from './OrderList';
import { ActiveOrderContext } from './ActiveOrderContext';

export const MobileOrderList = () => {
  const { t } = useTranslation();
  const [activeOrderId, setActiveOrderId] = React.useState<string>('');
  const orders = useSelector((state: ReduxState) => state.restaurantOrders.all);

  const activeOrder = orders.find((order) => order.id === activeOrderId);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchRestaurantOrders());
  }, []);

  return (
    <ActiveOrderContext.Provider value={{ activeOrderId, setActiveOrderId }}>
      <V3Page>
        <PageHeader title={t('Orders')} />
        <PageContent>
          <OrderList />
        </PageContent>
      </V3Page>
      {activeOrder && <OrderDetails order={activeOrder} />}
    </ActiveOrderContext.Provider>
  );
};
