import clsx from 'clsx';

import styles from './Tabs.module.css';

type TabType<T extends string> = {
  id: T;
  title: string;
};

type Props<T extends string> = {
  activeTabId: T;
  onChange: (tabId: T) => void;
  tabs: TabType<T>[];
};

export function Tabs<T extends string>({
  tabs,
  activeTabId,
  onChange,
}: Props<T>) {
  return (
    <div className={styles['tabs-container']}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={clsx(activeTabId === tab.id && styles['active'])}
          onClick={() => onChange(tab.id)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
}
