import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_RESOURCE_QUANTITY_FAILURE,
  CREATE_RESOURCE_QUANTITY_REQUEST,
  CREATE_RESOURCE_QUANTITY_SUCCESS,
  DELETE_RESOURCE_QUANTITY_FAILURE,
  DELETE_RESOURCE_QUANTITY_REQUEST,
  DELETE_RESOURCE_QUANTITY_SUCCESS,
  FETCH_RESOURCE_QUANTITIES_FAILURE,
  FETCH_RESOURCE_QUANTITIES_REQUEST,
  FETCH_RESOURCE_QUANTITIES_SUCCESS,
  UPDATE_RESOURCE_QUANTITY_FAILURE,
  UPDATE_RESOURCE_QUANTITY_REQUEST,
  UPDATE_RESOURCE_QUANTITY_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  ResourceQuantity,
  CreateResourceQuantityResponse,
  ListResourceQuantitiesResponse,
  UpdateResourceQuantityResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_RESOURCE_QUANTITIES_FAILURE:
    case CREATE_RESOURCE_QUANTITY_FAILURE:
    case UPDATE_RESOURCE_QUANTITY_FAILURE:
    case DELETE_RESOURCE_QUANTITY_FAILURE:
      return action.error;

    case FETCH_RESOURCE_QUANTITIES_SUCCESS:
    case CREATE_RESOURCE_QUANTITY_SUCCESS:
    case UPDATE_RESOURCE_QUANTITY_SUCCESS:
    case DELETE_RESOURCE_QUANTITY_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_RESOURCE_QUANTITIES_REQUEST:
    case CREATE_RESOURCE_QUANTITY_REQUEST:
    case UPDATE_RESOURCE_QUANTITY_REQUEST:
    case DELETE_RESOURCE_QUANTITY_REQUEST:
      return true;

    case FETCH_RESOURCE_QUANTITIES_FAILURE:
    case CREATE_RESOURCE_QUANTITY_FAILURE:
    case UPDATE_RESOURCE_QUANTITY_FAILURE:
    case DELETE_RESOURCE_QUANTITY_FAILURE:
    case FETCH_RESOURCE_QUANTITIES_SUCCESS:
    case CREATE_RESOURCE_QUANTITY_SUCCESS:
    case UPDATE_RESOURCE_QUANTITY_SUCCESS:
    case DELETE_RESOURCE_QUANTITY_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (
  state: ResourceQuantity[] = [],
  action: any
): ResourceQuantity[] => {
  switch (action.type) {
    case FETCH_RESOURCE_QUANTITIES_SUCCESS: {
      const response = action.response as ListResourceQuantitiesResponse;
      const newIds = (response.resource_quantities ?? []).map(
        (resource_quantity) => resource_quantity.id
      );
      return [
        ...state.filter((i) => !newIds.includes(i.id)),
        ...(response.resource_quantities ?? []),
      ];
    }

    case CREATE_RESOURCE_QUANTITY_SUCCESS: {
      const response = action.response as CreateResourceQuantityResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_RESOURCE_QUANTITY_SUCCESS: {
      const response = action.response as UpdateResourceQuantityResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_RESOURCE_QUANTITY_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const latestQueryResults = (
  state: ResourceQuantity[] = [],
  action: any
): ResourceQuantity[] => {
  switch (action.type) {
    case FETCH_RESOURCE_QUANTITIES_SUCCESS: {
      const response = action.response as ListResourceQuantitiesResponse;
      return [...(response.resource_quantities ?? [])];
    }

    default:
      return state;
  }
};

export const resourceQuantities = combineReducers({
  error,
  loading,
  all,
  latestQueryResults,
});
