import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { fetchProductTags } from 'client/actions/productTags';
import { Button } from 'client/components/Form/Button';
import { Message } from 'client/components/Message/Message';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import {
  defaultProductCurrencySelector,
  defaultProductTimezoneSelector,
} from 'client/reducers/organizations';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { HighlightsEditor } from 'client/components/NewProductEditor/DetailsStep/HighlightsEditor/HighlightsEditor';
import { DetailInfoEditor } from 'client/components/NewProductEditor/DetailsStep/DetailInfoEditor/DetailInfoEditor';
import { ItineraryEditor } from 'client/components/NewProductEditor/DetailsStep/ItineraryEditor/ItineraryEditor';
import { ServicesEditor } from 'client/components/NewProductEditor/DetailsStep/ServicesEditor/ServicesEditor';
import { ReservationFormEditor } from 'client/components/NewProductEditor/DetailsStep/ReservationFormEditor/ReservationFormEditor';
import { CheckinPickupLocationsEditor } from 'client/components/NewProductEditor/DetailsStep/CheckinPickupLocationsEditor/CheckinPickupLocationsEditor';
import { MinimumParticipantsEditor } from 'client/components/NewProductEditor/DetailsStep/MinimumParticipantsEditor/MinimumParticipantsEditor';
import { TagsEditor } from 'client/components/NewProductEditor/DetailsStep/TagsEditor/TagsEditor';
import { TransportRouteEditor } from 'client/components/NewProductEditor/DetailsStep/TransportRouteEditor/TransportRouteEditor';
import { InternalTagsEditor } from 'client/components/NewProductEditor/DetailsStep/InternalTagsEditor/InternalTagsEditor';
import {
  Accordion,
  AccordionItem,
} from 'client/components/NewProductEditor/Accordion/Accordion';
import {
  convertFormValuesToProductPatch,
  getInitialValues,
} from 'client/components/NewProductEditor/DetailsStep/FormValues';
import type { FormValues } from 'client/components/NewProductEditor/DetailsStep/FormValues';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { isNta } from 'client/components/ESaas/Nta/utils';
import { NtaReservationFormMessage } from 'client/components/ESaas/Nta/NtaReservationFormMessage/NtaReservationFormMessage';
import baseStyles from 'client/base.module.css';

export const DetailsEditor = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProductTags());
  }, []);
  const [success, setSuccess] = React.useState<boolean>(false);
  const editingProduct = React.useContext(EditingProductContext);
  const hasPerParticipantPricing =
    editingProduct?.pricing == null ||
    editingProduct?.pricing.some((priceRule) => {
      if (priceRule.units == null) {
        return false;
      }

      return priceRule.units.some((unit) => unit.method === 'PER_PARTICIPANT');
    });
  const isPackage =
    (
      editingProduct?.shared_allotment_references
        ?.package_component_product_ids ?? []
    ).length > 0;
  const isSharedAllotment = Boolean(
    editingProduct?.shared_allotment_references?.parent_product_id
  );
  const isPassthrough = Boolean(
    editingProduct?.shared_allotment_references?.passthrough_base_product_id
  );
  const showMinimumParticipants =
    hasPerParticipantPricing &&
    !isPackage &&
    !isSharedAllotment &&
    !isPassthrough;
  const hasHighlights = (editingProduct?.highlights || []).length > 0;
  const hasDetailInfo =
    (editingProduct?.inclusions ?? []).length > 0 ||
    (editingProduct?.exclusions ?? []).length > 0 ||
    (editingProduct?.requirements ?? []).length > 0 ||
    (editingProduct?.restrictions ?? []).length > 0 ||
    (editingProduct?.what_to_bring ?? []).length > 0 ||
    (editingProduct?.other_notes ?? []).length > 0;
  const hasItinerary = (editingProduct?.itinerary ?? []).length > 0;
  const hasAddOns = (editingProduct?.add_ons ?? []).length > 0;
  const hasTransportation = (editingProduct?.transportations ?? []).length > 0;
  const hasCheckinPickup =
    (editingProduct?.checkin ?? []).length > 0 ||
    (editingProduct?.checkout ?? []).length > 0 ||
    (editingProduct?.pickup ?? []).length > 0 ||
    (editingProduct?.dropoff ?? []).length > 0;
  const hasForm =
    (
      editingProduct?.reservation_form_fields?.filter(
        (formField) =>
          formField.key !== 'given_name' && formField.key !== 'family_name'
      ) ?? []
    ).length > 0;
  const hasMinPaxOrCountInventorySettings =
    (editingProduct?.minimum_participant_count?.value ?? 0) > 0 ||
    editingProduct?.allotment_settings?.inventory_consumption_rules?.some(
      (rule) => rule.should_not_count_inventory
    );
  const hasProductTags = (editingProduct?.product_tags ?? []).length > 0;
  const hasTransportRoute = (editingProduct?.transport_route ?? []).length > 0;
  const hasInternalProductTags =
    (editingProduct?.internal_product_tags ?? []).length > 0;
  const defaultTimezone = useSelector(defaultProductTimezoneSelector);
  const defaultProductCurrency = editingProduct
    ? getProductCurrency(editingProduct)
    : undefined;
  const defaultSupplierCurrency = useSelector(defaultProductCurrencySelector);
  const defaultCurrency = defaultProductCurrency ?? defaultSupplierCurrency;
  const initialValues = React.useMemo(
    () =>
      getInitialValues(
        editingProduct,
        defaultCurrency,
        defaultTimezone,
        t,
        true
      ),
    [editingProduct, defaultCurrency, defaultTimezone, t]
  );
  const convertToProductPatch = React.useCallback(
    (values: FormValues) =>
      convertFormValuesToProductPatch(
        values,
        editingProduct,
        t,
        defaultCurrency,
        true
      ),
    [editingProduct, t, defaultCurrency, false]
  );
  const isNtaHotel =
    isNta(activeUserOrganization) &&
    (editingProduct?.html_texts || []).find((htmlText) => {
      return htmlText.key === 'product-type' && htmlText.text === 'hotel';
    });
  return (
    <ProductEditorForm
      onSubmitStart={() => setSuccess(false)}
      onSubmitSuccess={() => setSuccess(true)}
      initialValues={initialValues}
      convertFormValuesToProductPatch={convertToProductPatch}
      subscription={{
        submitError: true,
        submitting: true,
      }}
    >
      {({ submitError, submitting }) => (
        <div>
          <Accordion>
            <AccordionItem
              header={t('Highlights')}
              subtitle={t('Enter the highlights and summary of the experience')}
              initialOpen={hasHighlights}
            >
              {() => <HighlightsEditor />}
            </AccordionItem>
            <AccordionItem
              header={t('Detailed Info (shown on voucher)')}
              subtitle={t(
                'Enter detailed info for the experience (info is shown automatically on reservation confirmation email)'
              )}
              initialOpen={hasDetailInfo}
            >
              {() => <DetailInfoEditor />}
            </AccordionItem>
            <AccordionItem
              header={t('Experience Itinerary')}
              subtitle={t('Explain the flow of the experience')}
              initialOpen={hasItinerary}
            >
              {() => <ItineraryEditor />}
            </AccordionItem>
            <AccordionItem
              header={t('Add-Ons')}
              subtitle={t(
                'Describe additional options that can be selected on the booking form'
              )}
              initialOpen={hasAddOns}
            >
              {() => (
                <ServicesEditor
                  name="addOns"
                  getItemLabel={(idx: number) =>
                    t('Add-On #{{itemNumber}}', {
                      itemNumber: idx,
                    })
                  }
                />
              )}
            </AccordionItem>
            <AccordionItem
              header={t('Transportation')}
              subtitle={t(
                'Register if there is pickup (not required for checkin-only)'
              )}
              initialOpen={hasTransportation}
            >
              {() => (
                <ServicesEditor
                  name="transportations"
                  getItemLabel={(idx: number) =>
                    t('Transportation #{{itemNumber}}', {
                      itemNumber: idx,
                    })
                  }
                />
              )}
            </AccordionItem>
            <AccordionItem
              header={t('Checkin/Pickup Locations')}
              subtitle={t(
                'Enter location and time details in advance to make it easy for customers to understand'
              )}
              initialOpen={hasCheckinPickup}
            >
              {() => <CheckinPickupLocationsEditor />}
            </AccordionItem>
            {!isNtaHotel && (
              <AccordionItem
                header={t('Reservation Form')}
                subtitle={t(
                  'Customize information required from customers at the time of booking'
                )}
                initialOpen={hasForm}
              >
                {() => <ReservationFormEditor />}
              </AccordionItem>
            )}
            {isNtaHotel && (
              <AccordionItem
                header={t('Reservation Form')}
                subtitle={''}
                initialOpen={hasForm}
              >
                {() => <NtaReservationFormMessage />}
              </AccordionItem>
            )}
            {showMinimumParticipants && (
              <AccordionItem
                header={t('Minimum Participants & Per-Unit Settings')}
                subtitle={t(
                  'If minimum participants are set, reservations will be received as request-only until this number of people is reached'
                )}
                initialOpen={hasMinPaxOrCountInventorySettings}
              >
                {() => <MinimumParticipantsEditor />}
              </AccordionItem>
            )}
            <AccordionItem
              header={t('Product Tags')}
              subtitle={t(
                'Use tags for displaying detailed conditions of the experience and help customers narrow down their selection'
              )}
              initialOpen={hasProductTags}
            >
              {() => <TagsEditor />}
            </AccordionItem>
            <AccordionItem
              header={t('Transport Routes')}
              initialOpen={hasTransportRoute}
            >
              {() => <TransportRouteEditor />}
            </AccordionItem>
            <AccordionItem
              header={t('Internal Use Product Tags')}
              subtitle={t('Set tags for internal use on product list page')}
              initialOpen={hasInternalProductTags}
            >
              {() => <InternalTagsEditor />}
            </AccordionItem>
          </Accordion>
          <div className={baseStyles['base-main__box__body__bottomBtns']}>
            <Button
              loading={submitting}
              type="submit"
              style="blue"
              size="small"
            >
              {t('Save')}
            </Button>
          </div>
          {success && <Message success header={t('Save Successful')} />}
          {submitError && <Message error header={t('Save Failed')} />}
        </div>
      )}
    </ProductEditorForm>
  );
};
