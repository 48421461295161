import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { Input } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

import { PerBookingUnitsEditor } from './PerBookingUnitsEditor';
import styles from './PerBookingPriceInput.module.css';

type Props = {
  name: string;
  currency: string;
  language?: string;
  showNet?: boolean;
};
export const PerBookingPriceInput = ({
  currency,
  name,
  language,
  showNet,
}: Props) => {
  const { t } = useTranslation();
  const isProductEditOrProductCopy =
    location.pathname.includes('edit') || location.pathname.includes('copy');
  return (
    <div className={styles['page-productsRegist__priceMain']}>
      <p className={styles['page-productsRegist__priceMain__ttl']}>
        {t('Set Up Per-Booking Prices')}
      </p>
      <div className={styles['c-tableChild']}>
        <ul>
          <li className={baseStyles['base-t-240']}>
            {t('Amount (tax included)')}
          </li>
          {showNet && (
            <li className={baseStyles['base-t-240']}>{t('Net Amount')}</li>
          )}
          <li></li>
        </ul>
        <FieldArray name={name}>
          {({ fields }) =>
            fields.map((name) => (
              <ul key={name}>
                <li data-title={t('Amount (tax included)')}>
                  <div
                    className={styles['page-productsRegist__priceMain__price']}
                  >
                    <p>{currency}</p>
                    <Field name={`${name}.price`}>
                      {({ input }) => (
                        <Input
                          value={input.value}
                          onChange={(e: any) => {
                            if (
                              !currencyInputAllowed(currency, e.target.value)
                            ) {
                              return;
                            }

                            input.onChange(e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </li>
                {showNet && (
                  <li data-title={t('Net Amount')}>
                    <div
                      className={
                        styles['page-productsRegist__priceMain__price']
                      }
                    >
                      <p>{currency}</p>
                      <Field name={`${name}.netPrice`}>
                        {({ input }) => (
                          <Input
                            value={input.value}
                            onChange={(e: any) => {
                              if (
                                !currencyInputAllowed(currency, e.target.value)
                              ) {
                                return;
                              }

                              input.onChange(e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </li>
                )}
                <li></li>
              </ul>
            ))
          }
        </FieldArray>
      </div>
      {isProductEditOrProductCopy && (
        <PerBookingUnitsEditor
          name={`${name}[0].perBookingUnits`}
          language={language ?? ''}
        />
      )}
    </div>
  );
};
