import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TFunction, withTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';
import { ThunkDispatch } from 'redux-thunk';

import {
  fetchReservationByID,
  updateReservation,
} from 'client/actions/reservations';
import {
  convertToLocationWithMoment,
  locationWithMomentsAreEqual,
  getStartTime,
  convertLocationWithMomentToReservationLocationWithTimeInput,
} from 'client/libraries/util/coreutil';
import { findClosestPickupLocation } from 'client/libraries/util/findClosestPickupLocation';
import { fetchProductByID } from 'client/actions/products';
import { LocationWithTimeEditFormFields } from 'client/components/LocationWithTimeEditFormFields';
import { activeUserSelector } from 'client/reducers/user';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { ModalLoader } from 'client/components/ModalLoader';
import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Button,
  Checkbox,
  TextArea,
} from 'client/components/Form';
import type { LocationWithMoment } from 'client/libraries/util/coreutil';
import type { ReduxState } from 'client/reducers/index';
import { Divider } from 'client/components/Divider/Divider';
import type { LocationWithTime } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

type OwnProps = {
  onUpdateCompleted?: (arg0: void) => void;
  reservationID: string;
  trigger?: React.ReactElement<'a'>;
};

type I18nProps = {
  t: TFunction;
};

/* eslint-disable no-use-before-define */
type Props = OwnProps &
  I18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

/* eslint-enable no-use-before-define */
type State = {
  showModal: boolean;
  guestHotel?: {
    location_name?: string;
    google_place_id?: string;
  };
  pickup?: LocationWithMoment;
  dropoff?: LocationWithMoment;
  pickupDropoffUseSameLocation: boolean;
  supplierNotes: string;
};

class PickupDropoffEditButtonComponent extends React.Component<Props, State> {
  placesService: Record<string, any> | null = null;
  hotelInputRef: React.RefObject<HTMLInputElement> | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      ...this.getStateFromReservation(),
    };
    this.hotelInputRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.reservation &&
      prevProps.reservation !== this.props.reservation
    ) {
      this.setState({ ...this.getStateFromReservation() });
      this.fetchProduct();
    }

    const prevGuestHotelPlaceId = prevState.guestHotel?.google_place_id;
    const newGuestHotelPlaceId = this.state.guestHotel?.google_place_id;

    if (
      prevGuestHotelPlaceId !== newGuestHotelPlaceId &&
      newGuestHotelPlaceId
    ) {
      const exactMatchPickup = this.props.product?.pickup?.find(
        (loc) => loc.google_place_id === newGuestHotelPlaceId
      );
      const premappedPickup =
        this.getPremappedPickupLocation(newGuestHotelPlaceId);

      if (exactMatchPickup) {
        const pickupTime = this.getPickupTime(exactMatchPickup);
        this.setState({
          pickup: {
            locationID: exactMatchPickup.id ?? '',
            locationName: exactMatchPickup.location_name ?? '',
            locationDescription: exactMatchPickup.location_description ?? '',
            googlePlaceID: exactMatchPickup.google_place_id ?? '',
            locationDateTime: pickupTime,
            imageUrls: exactMatchPickup.image_urls ?? [],
          },
        });
      } else if (premappedPickup) {
        const pickupTime = this.getPickupTime(premappedPickup);
        this.setState({
          pickup: {
            locationID: premappedPickup.id ?? '',
            locationName: premappedPickup.location_name ?? '',
            locationDescription: premappedPickup.location_description ?? '',
            googlePlaceID: premappedPickup.google_place_id ?? '',
            locationDateTime: pickupTime,
            imageUrls: premappedPickup.image_urls ?? [],
          },
        });
      } else {
        if (!this.placesService) {
          this.placesService = new window.google.maps.places.PlacesService(
            this.hotelInputRef?.current
          );
        }

        this.placesService?.getDetails(
          {
            placeId: newGuestHotelPlaceId,
            fields: ['geometry'],
          },
          (place: any, status: any) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              const closestPickup = findClosestPickupLocation(
                lat,
                lng,
                this.props.product?.pickup ?? []
              );

              if (closestPickup) {
                const pickupTime = this.getPickupTime(closestPickup);
                this.setState({
                  pickup: {
                    locationID: closestPickup.id ?? '',
                    locationName: closestPickup.location_name ?? '',
                    locationDescription:
                      closestPickup.location_description ?? '',
                    googlePlaceID: closestPickup.google_place_id ?? '',
                    locationDateTime: pickupTime,
                    imageUrls: closestPickup.image_urls ?? [],
                  },
                });
              }
            } else {
              throw new Error('getDetails() returned status ' + status);
            }
          }
        );
      }
    }
  }

  getPremappedPickupLocation = (
    googlePlaceId: string
  ): LocationWithTime | null => {
    return (
      this.props.product?.pickup?.find((loc) =>
        loc.premapped_locations?.some(
          (premappedLocation) =>
            premappedLocation.google_place_id === googlePlaceId
        )
      ) ?? null
    );
  };
  getPickupTime = (pickupLocation: LocationWithTime): Moment => {
    // Apply pickup time to start time
    let timeRelative = pickupLocation.time_relative;

    if ((pickupLocation.dedicated_start_time_info ?? []).length > 0) {
      const dedicatedStartTimeItem =
        pickupLocation.dedicated_start_time_info?.find(
          (item) =>
            item.time_slot_key === this.props?.productInstance?.time_slot_key ??
            ''
        );

      if (dedicatedStartTimeItem?.time_relative) {
        timeRelative = dedicatedStartTimeItem?.time_relative;
      }
    }

    const startDate = moment.tz(
      this.props.productInstance?.start_date_time_utc,
      this.props.product?.start_timezone ?? ''
    );
    return moment(startDate).add(moment.duration(timeRelative));
  };
  getStateFromReservation = () => {
    const { locale, reservation } = this.props;

    if (!reservation) {
      return {
        guestHotel: undefined,
        pickup: undefined,
        dropoff: undefined,
        pickupDropoffUseSameLocation: true,
        supplierNotes: '',
      };
    }

    let pickup = convertToLocationWithMoment(
      reservation.pickup,
      reservation.start_timezone || '',
      locale
    );

    if (!pickup?.locationDateTime) {
      pickup = {
        ...pickup,
        locationDateTime:
          (reservation.start_date_time_utc &&
            reservation.start_timezone &&
            moment.tz(
              reservation.start_date_time_utc,
              reservation.start_timezone
            )) ||
          null,
      };
    }

    const dropoff = convertToLocationWithMoment(
      reservation.dropoff,
      reservation.start_timezone || '',
      locale
    );
    const pickupDropoffUseSameLocation =
      pickup.locationName === dropoff.locationName &&
      pickup.locationDescription === dropoff.locationDescription &&
      pickup.googlePlaceID === dropoff.googlePlaceID;
    return {
      guestHotel: reservation.guest_hotel,
      pickup,
      dropoff,
      pickupDropoffUseSameLocation,
      supplierNotes: reservation.supplier_notes || '',
    };
  };
  fetchReservation = () => {
    const { reservation } = this.props;

    if (!reservation) {
      this.props.fetchReservationByID(this.props.reservationID);
    }
  };
  fetchProduct = () => {
    const { fetchProductByID, reservation, product } = this.props;

    if (
      reservation &&
      reservation.product_id &&
      (!product || product.id !== reservation.product_id)
    ) {
      fetchProductByID(reservation.product_id);
    }
  };
  handleClickAutofillPickupDropoff = () => {
    const { reservation } = this.props;
    const requestedPickup = reservation.requested_pickup_location;
    const requestedPickupLocationName =
      (requestedPickup && requestedPickup.location_name) || '';
    const requestedDropoff = reservation.requested_dropoff_location;
    const requestedDropoffLocationName =
      (requestedDropoff && requestedDropoff.location_name) || '';

    if (
      (requestedPickupLocationName && requestedPickupLocationName !== 'TBD') ||
      (requestedDropoffLocationName && requestedDropoffLocationName !== 'TBD')
    ) {
      // If either requested pickup or requested dropoff are populated, autofill from those.
      const requestedPickupGooglePlaceId =
        (requestedPickup && requestedPickup.google_place_id) || '';
      const requestedDropoffGooglePlaceId =
        (requestedDropoff && requestedDropoff.google_place_id) || '';
      this.setState((prevState: State) => ({
        pickup: {
          ...(prevState.pickup as any),
          locationName: requestedPickupLocationName,
          googlePlaceID: requestedPickupGooglePlaceId,
        },
        dropoff: {
          ...(prevState.dropoff as any),
          locationName: requestedDropoffLocationName,
          googlePlaceID: requestedDropoffGooglePlaceId,
        },
      }));
    } else {
      // Otherwise, try to fill from customer hotel.
      const { guestHotel } = this.state;
      const locationName = (guestHotel && guestHotel.location_name) || '';
      const googlePlaceID = (guestHotel && guestHotel.google_place_id) || '';

      if (locationName) {
        this.setState((prevState) => ({
          pickup: { ...(prevState.pickup as any), locationName, googlePlaceID },
          dropoff: {
            ...(prevState.dropoff as any),
            locationName,
            googlePlaceID,
          },
        }));
      }
    }
  };
  handleTogglePickupDropoffUseSameLocation = () => {
    this.setState((prevState) => {
      if (!prevState.pickupDropoffUseSameLocation) {
        const pickup = prevState.pickup;
        const dropoff = {
          locationID: (pickup && pickup.locationID) || '',
          locationName: (pickup && pickup.locationName) || '',
          locationDescription: (pickup && pickup.locationDescription) || '',
          googlePlaceID: pickup && pickup.googlePlaceID,
          locationDateTime: null,
          imageUrls: [],
        };
        return {
          pickupDropoffUseSameLocation: true,
          dropoff,
        };
      } else {
        return {
          pickupDropoffUseSameLocation: false,
        };
      }
    });
  };
  handleSupplierNotesChange = (e: any) => {
    this.setState({
      supplierNotes: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };
  handleOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  render() {
    const {
      loading,
      locale,
      onUpdateCompleted,
      product,
      reservation,
      t,
      trigger,
    } = this.props;
    const {
      guestHotel,
      pickup,
      dropoff,
      pickupDropoffUseSameLocation,
      supplierNotes,
    } = this.state;
    const startTime = reservation && getStartTime(reservation).locale(locale);
    const timezone = (reservation && reservation.start_timezone) || '';
    const originalGuestHotel = reservation && reservation.guest_hotel;
    const originalPickup =
      reservation &&
      convertToLocationWithMoment(reservation.pickup, timezone, locale);
    const originalDropoff =
      reservation &&
      convertToLocationWithMoment(reservation.dropoff, timezone, locale);
    const pristine =
      !pickup ||
      !dropoff ||
      (guestHotel === originalGuestHotel &&
        originalPickup &&
        originalDropoff &&
        locationWithMomentsAreEqual(pickup, originalPickup) &&
        locationWithMomentsAreEqual(dropoff, originalDropoff) &&
        supplierNotes === reservation.supplier_notes);
    const requested_dropoff_location =
      reservation && reservation.requested_dropoff_location;
    const requested_pickup_location =
      reservation && reservation.requested_pickup_location;
    return (
      <Modal
        title={t('Edit Pickup/Dropoff')}
        trigger={trigger}
        open={this.state.showModal}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      >
        {loading ? (
          <Modal.Content>
            <Modal.Box>
              <ModalLoader />
            </Modal.Box>
          </Modal.Content>
        ) : (
          <Modal.Content>
            <Modal.Box>
              <input
                style={{
                  display: 'none',
                }}
                ref={this.hotelInputRef}
              />
              <LocationSearchInput
                prompt={t('Customer Hotel')}
                location={(guestHotel && guestHotel.location_name) || ''}
                onSearchChange={(location_name) =>
                  this.setState({
                    guestHotel: {
                      location_name,
                    },
                  })
                }
                onLocationSelect={({
                  title: location_name,
                  key: google_place_id,
                }) =>
                  this.setState({
                    guestHotel: {
                      location_name,
                      google_place_id,
                    },
                  })
                }
              />
            </Modal.Box>

            <Modal.Box>
              <FieldWrapper label={t('Desired Pickup Location')}>
                {(requested_pickup_location &&
                  requested_pickup_location.location_name) ||
                  t('(none)')}
              </FieldWrapper>
            </Modal.Box>

            <Modal.Box>
              <FieldWrapper label={t('Desired Dropoff Location')}>
                {(requested_dropoff_location &&
                  requested_dropoff_location.location_name) ||
                  t('(none)')}
              </FieldWrapper>
            </Modal.Box>

            <Modal.Box>
              <Button
                size="middle"
                style={'gray'}
                onClick={this.handleClickAutofillPickupDropoff}
              >
                {t('Autofill pickup/dropoff')}
              </Button>
            </Modal.Box>

            {pickup && (
              <LocationWithTimeEditFormFields
                locationNameLabel={t('Pickup Location Name')}
                locationDescriptionLabel={t(
                  'Pickup Location Description (ex: "Main lobby", "Car park", "Main wing")'
                )}
                locationTimeLabel={t('Pickup Time')}
                locationDateLabel={t('Pickup Date')}
                location={pickup}
                productCandidateLocations={(product && product.pickup) || []}
                startTime={startTime}
                onLocationChange={(pickup) => {
                  this.setState({
                    pickup,
                  });
                }}
                timeSlotKey={this.props.productInstance?.time_slot_key ?? ''}
              />
            )}

            <Modal.Box>
              <Checkbox
                label={t('Use same location for pickup and dropoff')}
                checked={pickupDropoffUseSameLocation}
                onChange={this.handleTogglePickupDropoffUseSameLocation}
              />
            </Modal.Box>

            {!pickupDropoffUseSameLocation && dropoff && (
              <LocationWithTimeEditFormFields
                locationNameLabel={t('Dropoff Location Name')}
                locationDescriptionLabel={t(
                  'Dropoff Location Description (ex: "Main lobby", "Car park", "Main wing")'
                )}
                locationTimeLabel={t('Dropoff Time')}
                locationDateLabel={t('Dropoff Date')}
                location={dropoff}
                productCandidateLocations={(product && product.dropoff) || []}
                startTime={startTime}
                onLocationChange={(dropoff) => {
                  this.setState({
                    dropoff,
                  });
                }}
                timeSlotKey={this.props.productInstance?.time_slot_key ?? ''}
              />
            )}

            <hr />

            <Divider />

            <ReservationActorInputForm />
            <br />
            <TextArea
              label={t('Replies')}
              value={supplierNotes}
              onChange={this.handleSupplierNotesChange}
            />
          </Modal.Content>
        )}

        <Modal.Actions>
          <Button.Cancel
            disabled={pristine}
            onClick={() =>
              this.setState({
                guestHotel: originalGuestHotel,
                pickup: originalPickup,
                dropoff: originalDropoff,
                supplierNotes: reservation.supplier_notes ?? '',
              })
            }
          >
            {t('Discard')}
          </Button.Cancel>
          <Button.Submit
            disabled={pristine}
            onClick={() => {
              (
                this.props.updateReservation(reservation.id, {
                  guest_hotel: guestHotel,
                  pickup:
                    pickup &&
                    convertLocationWithMomentToReservationLocationWithTimeInput(
                      pickup
                    ),
                  dropoff:
                    dropoff &&
                    convertLocationWithMomentToReservationLocationWithTimeInput(
                      dropoff
                    ),
                  supplier_notes: supplierNotes,
                } as any) as any
              ).then(() => onUpdateCompleted && onUpdateCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button.Submit>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const reservation = state.reservations.byID[ownProps.reservationID];
  return {
    activeUser: activeUserSelector(state),
    loading: state.reservations.loading || state.products.loading,
    locale: state.language.selected.iso,
    monthYearFormat: state.language.selected.monthYearFormat,
    product: reservation && state.products.byID[reservation.product_id || ''],
    reservation,
    productInstance:
      reservation &&
      state.productInstances.byID[reservation.product_instance_id || ''],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchReservationByID: (id: string) => dispatch(fetchReservationByID(id)),
  updateReservation: (id: string, patch: any) =>
    dispatch(updateReservation(id, patch)),
  fetchProductByID: (id: string) => dispatch(fetchProductByID(id)),
});

export const PickupDropoffEditButton = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(PickupDropoffEditButtonComponent);
