import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Radio } from 'client/components/v3/Form/Radio';
import { getArrayMutators } from 'client/libraries/util/form';
import { operationAllowed } from 'shared/models/access';
import { supplierOptionsSelector } from 'client/reducers/products';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import {
  activeUserSelector,
  agentOptionsSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import { Select } from 'client/pages/v3/Reservation/ReservationDataDownload/Select/Select';
import { Modal } from 'client/components/v3/Form/Modal';
import { Preset } from 'client/pages/v3/Settings/component/ReservationSearchSettingsFormValues';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { useReservationSearchConditionDateType } from 'client/hooks/useReservationSearchConditionDateType';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import {
  getValidators,
  matchesFormat,
} from 'shared/libraries/validate/validator';
import { SourceLanguage } from 'shared/models/swagger';

import styles from './ReservationSearchPresetEditModal.module.css';

type Props = {
  existingItem?: Preset;
  open: boolean;
  onClose: () => void;
  onSave: (item: Preset) => void;
  submitting: boolean;
};

export const ReservationSearchPresetEditModal = ({
  existingItem,
  open,
  onClose,
  onSave,
  submitting,
}: Props) => {
  const { t, i18n } = useTranslation();

  const { required } = getValidators(t);

  const activeUser = useSelector(activeUserSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const languageOptions = React.useMemo(() => {
    const options = getSourceLanguageOptions(t);

    return options.filter((option) => {
      return supportedLanguages.includes(option.value as SourceLanguage);
    });
  }, [supportedLanguages, t]);

  const orderByOptions = useOrderByOptions();
  const dateTypeOptions = useReservationSearchConditionDateType();

  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  return (
    <>
      <Form
        onSubmit={(values: Preset) => {
          onSave(values);
        }}
        initialValues={
          existingItem ?? {
            key: uuidv4(),
            title: '',
            orderBy: 'last_updated_desc',
            dateType: 'PARTICIPATION_DATE',
            targetDateType: 'CURRENT_DATE',
            dateRangeType: 'NEXT',
            dateRange: '1',
          }
        }
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, values }) => (
          <form>
            <Modal
              title={t('Preset search condition')}
              open={open}
              onClose={onClose}
              rightActionChildren={
                <>
                  <Button
                    text={t('Cancel')}
                    uiType="bg"
                    size="md"
                    color="white"
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <Button
                    type="submit"
                    text={t('Save')}
                    uiType="bg"
                    size="md"
                    color="primary"
                    loading={submitting}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                    disabled={values.title ? false : true}
                  />
                </>
              }
              style={{
                width: '600px',
                height: 'fit-content',
                maxHeight: '85%',
              }}
            >
              <div className={styles['p-downloadModal']}>
                <div className={styles['p-downloadModal__item']}>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Title')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <Field name="title" validate={required}>
                      {({ input }) => <TextField {...input} />}
                    </Field>
                  </div>
                </div>
                <div className={styles['p-downloadModal__item']}>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Order By')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <Field name="orderBy">
                      {({ input }) => (
                        <Select
                          options={orderByOptions}
                          selectedOption={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={styles['p-downloadModal__item']}>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Booking Status')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <Field name="bookingStatuses">
                      {({ input }) => (
                        <MultiDropdown
                          options={statusOptions}
                          onChange={(value) => input.onChange(value)}
                          selectedOptions={
                            typeof input.value === 'object' ? input.value : []
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className={styles['p-downloadModal__item']}>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Date')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <Field name="dateType">
                      {({ input }) => (
                        <SingleDropdown
                          options={dateTypeOptions}
                          onChange={(value) => input.onChange(value)}
                          selectedOption={input.value}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Field name="targetDateType">
                  {({ input: targetDateTypeInput }) => (
                    <>
                      <div className={styles['p-downloadModal__item']}>
                        <p className={styles['p-downloadModal__item__ttl']}>
                          {t('Target Dates')}
                        </p>
                        <div className={styles['p-downloadModal__item__body']}>
                          <div
                            className={
                              styles['p-downloadModal__item__body__check']
                            }
                          >
                            <p>{t('Product Status')}</p>
                            <div>
                              <Radio
                                label={t('Current date')}
                                checked={
                                  targetDateTypeInput.value === 'CURRENT_DATE'
                                }
                                onChange={() => {
                                  targetDateTypeInput.onChange('CURRENT_DATE');
                                }}
                              />
                              <Radio
                                label={t('Previous date')}
                                checked={
                                  targetDateTypeInput.value === 'PREVIOUS_DATE'
                                }
                                onChange={() => {
                                  targetDateTypeInput.onChange('PREVIOUS_DATE');
                                }}
                              />
                            </div>
                            <Radio
                              label={t('Next date')}
                              checked={
                                targetDateTypeInput.value === 'NEXT_DATE'
                              }
                              onChange={() => {
                                targetDateTypeInput.onChange('NEXT_DATE');
                              }}
                            />
                            <Radio
                              label={t('Specific number of days')}
                              checked={targetDateTypeInput.value === 'RANGE'}
                              onChange={() => {
                                targetDateTypeInput.onChange('RANGE');
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {targetDateTypeInput.value === 'RANGE' && (
                        <>
                          <div className={styles['p-downloadModal__item']}>
                            <p className={styles['p-downloadModal__item__ttl']}>
                              {t('Number of days')}
                            </p>
                            <div
                              className={styles['p-downloadModal__item__body']}
                            >
                              <div
                                className={
                                  styles['p-downloadModal__item__body__check']
                                }
                              >
                                {i18n.language === 'ja' ? (
                                  <>
                                    <p>今日から</p>
                                    <Field name="dateRange">
                                      {({ input }) => (
                                        <TextField
                                          type="number"
                                          value={input.value}
                                          onChange={(value) => {
                                            if (
                                              value !== '' &&
                                              !matchesFormat(
                                                value,
                                                'non-negative-integer'
                                              )
                                            ) {
                                              return;
                                            }
                                            input.onChange(value);
                                          }}
                                          {...{ min: 1 }}
                                        />
                                      )}
                                    </Field>
                                    <Field name="dateRangeType">
                                      {({ input }) => (
                                        <SingleDropdown
                                          menuStyle={{ width: '120px' }}
                                          options={[
                                            {
                                              value: 'NEXT',
                                              text: t('dateRangeType.next'),
                                            },
                                            {
                                              value: 'PREVIOUS',
                                              text: t('dateRangeType.previous'),
                                            },
                                          ]}
                                          selectedOption={input.value}
                                          onChange={(value) => {
                                            input.onChange(value);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </>
                                ) : (
                                  <>
                                    <Field name="dateRangeType">
                                      {({ input }) => (
                                        <SingleDropdown
                                          menuStyle={{ width: '120px' }}
                                          options={[
                                            {
                                              value: 'NEXT',
                                              text: t('dateRangeType.next'),
                                            },
                                            {
                                              value: 'PREVIOUS',
                                              text: t('dateRangeType.previous'),
                                            },
                                          ]}
                                          selectedOption={input.value}
                                          onChange={(value) => {
                                            input.onChange(value);
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <Field name="dateRange">
                                      {({ input }) => (
                                        <TextField
                                          type="number"
                                          value={input.value}
                                          onChange={(value) => {
                                            if (
                                              value !== '' &&
                                              !matchesFormat(
                                                value,
                                                'non-negative-integer'
                                              )
                                            ) {
                                              return;
                                            }
                                            input.onChange(value);
                                          }}
                                          {...{ min: 1 }}
                                        />
                                      )}
                                    </Field>
                                    <div
                                      style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                      }}
                                    >
                                      days from today
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Field>
                {operationAllowed(
                  activeUser,
                  'write',
                  'reservationConfirmation'
                ) ? (
                  <>
                    <div className={styles['p-downloadModal__item']}>
                      <p className={styles['p-downloadModal__item__ttl']}>
                        {t('Booking Source')}
                      </p>
                      <div className={styles['p-downloadModal__item__body']}>
                        <Field name="bookingSources">
                          {({ input }) => (
                            <MultiDropdown
                              options={bookingSourceOptions}
                              onChange={(value) => input.onChange(value)}
                              selectedOptions={
                                typeof input.value === 'object'
                                  ? input.value
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles['p-downloadModal__item']}>
                      <p className={styles['p-downloadModal__item__ttl']}>
                        {t('Agent')}
                      </p>
                      <div className={styles['p-downloadModal__item__body']}>
                        <Field name="agentIds">
                          {({ input }) => (
                            <MultiDropdown
                              options={agentOptions}
                              onChange={(value) => input.onChange(value)}
                              selectedOptions={
                                typeof input.value === 'object'
                                  ? input.value
                                  : []
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles['p-downloadModal__item']}>
                    <p className={styles['p-downloadModal__item__ttl']}>
                      {t('Supplier')}
                    </p>
                    <div className={styles['p-downloadModal__item__body']}>
                      <Field name="supplierIds">
                        {({ input }) => (
                          <MultiDropdown
                            options={supplierOptions}
                            onChange={(value) => input.onChange(value)}
                            selectedOptions={
                              typeof input.value === 'object' ? input.value : []
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                )}
                <div className={styles['p-downloadModal__item']}>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Language')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <Field name="reservationLanguages">
                      {({ input }) => (
                        <MultiDropdown
                          options={languageOptions}
                          onChange={(value) => input.onChange(value)}
                          selectedOptions={
                            typeof input.value === 'object' ? input.value : []
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </Modal>
          </form>
        )}
      </Form>
    </>
  );
};
