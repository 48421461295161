import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { ReservationList } from './ReservationList';
import { Calendar } from './Calendar';
import { OperatingHours } from './OperatingHours';

type TabType = 'RESERVATION_LIST' | 'CALENDAR' | 'OPERATION_HOURS';
export const Schedule = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<TabType>('RESERVATION_LIST');
  return (
    <>
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'RESERVATION_LIST'
                ? componentStyles['is-active']
                : ''
            )}
            onClick={() => {
              setActiveTab('RESERVATION_LIST');
            }}
          >
            <a>{t('Assigned Reservations')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'CALENDAR' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CALENDAR');
            }}
          >
            <a>{t('Schedule')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'OPERATION_HOURS'
                ? componentStyles['is-active']
                : ''
            )}
            onClick={() => {
              setActiveTab('OPERATION_HOURS');
            }}
          >
            <a>{t('Basic work hours')}</a>
          </li>
        </ul>
        <ReservationList isActive={activeTab === 'RESERVATION_LIST'} />
        <Calendar isActive={activeTab === 'CALENDAR'} />
        <OperatingHours isActive={activeTab === 'OPERATION_HOURS'} />
      </div>
    </>
  );
};
