import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { Button, Input, TextArea } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';

import localStyles from './ETicketQrCode.module.css';
import { DraggableStubItem } from './DraggableStubItem';

export const EditETicketQrCodeForm = () => {
  const { t } = useTranslation();

  return (
    <Modal.Content>
      <Modal.Box>
        <Field name="title">
          {({ input }) => (
            <Input
              label={t('Title')}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </Modal.Box>
      <Modal.Box>
        <Field name="description">
          {({ input }) => (
            <TextArea
              label={t('Description')}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </Modal.Box>
      <Modal.Box>
        <FieldArray name="qrCodeLinks">
          {({ fields }) => (
            <>
              <div>
                <Box mb={2}>
                  <Button
                    type="button"
                    style="green"
                    size="middle"
                    onClick={() => {
                      fields.push('');
                    }}
                  >
                    {t('Add E-ticket Usage')}
                  </Button>
                </Box>
                <div className={localStyles['c-table-list']}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '60px' }}></th>
                        <th>{t('Product')}</th>
                        <th>{t('Usage')}</th>
                        <th>{t('Usage Option')}</th>
                      </tr>
                    </thead>
                    <tbody style={{ width: '100%' }}>
                      {(fields ?? []).map((name, idx) => (
                        <DraggableStubItem
                          key={idx}
                          index={idx}
                          name={name}
                          onRemoveClick={() => {
                            fields.remove(idx);
                          }}
                          onEditClick={() => undefined}
                          onMoveItem={(
                            dragIndex: number,
                            hoverIndex: number
                          ) => {
                            const dragItem = fields.value[dragIndex];
                            const newItems = [...fields.value];
                            newItems.splice(dragIndex, 1);
                            newItems.splice(hoverIndex, 0, dragItem);
                            newItems.forEach((item, index) => {
                              fields.update(index, item);
                            });
                          }}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </FieldArray>
      </Modal.Box>
    </Modal.Content>
  );
};
