import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import { Modal } from 'client/components/Modal/Modal';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

import { getReservationCSVColumns, downloadReservationSearchCSV } from './util';

interface Props {
  trigger: React.ReactElement<any>;
  searchRequest: SearchReservationsRequest;
  initialSelectedColumns: string[];
  columnCandidates: string[];
}

export const ReservationSearchDownloadCSVModal = ({
  trigger,
  searchRequest,
  initialSelectedColumns,
  columnCandidates,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedColumns, setSelectedColumns] = React.useState(
    initialSelectedColumns
  );

  const allColumns = getReservationCSVColumns(t);

  const getColumns = (columnMask: string[]): ColumnType[] => {
    return columnMask.map((c) => allColumns.find((col) => col.id === c) as any);
  };

  const columnsToShow = getColumns(selectedColumns);
  const allCandidateColumns = getColumns(columnCandidates);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Modal
      title={t('Download CSV')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <div className={clsx(pageStyles['page-reservations__modal'])}>
        <div className={clsx(pageStyles['page-reservations__modal__box'])}>
          <div className={clsx(baseStyles['base-form-box'])}>
            <div className={clsx(baseStyles['base-form-box__body'])}>
              <ToggleableDndList
                selectedItems={columnsToShow.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                candidateItems={allCandidateColumns.map((col) => ({
                  key: col.id,
                  text: col.translatedColumnName || col.Header,
                }))}
                setSelectedItems={(items) =>
                  setSelectedColumns(items.map((item) => item.key))
                }
              />
            </div>
          </div>
        </div>

        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['middle'],
              baseStyles['blue']
            )}
            onClick={() => {
              dispatch(
                downloadReservationSearchCSV({
                  ...searchRequest,
                  timezone: moment.tz.guess(),
                  columns: selectedColumns.map((col) =>
                    col.toUpperCase()
                  ) as any,
                })
              );
              setShowModal(false);
            }}
          >
            {t('Download')}
          </a>
        </div>
      </div>
    </Modal>
  );
};
