import { useState } from 'react';
import RCTimePicker from 'rc-time-picker';
import { Moment } from 'moment-timezone';

import { isTouchBackend } from 'client/libraries/util/isTouchBackend';

import 'rc-time-picker/assets/index.css';
import 'client/components/v3/Form/TimePicker/timepicker-override.css';

type Props = {
  defaultOpenValue?: Moment;
  allowEmpty?: boolean;
  value: Moment | undefined;
  onChange: (arg0: Moment | null) => void;
  disabled?: boolean;
};

export const TimePicker = ({
  allowEmpty,
  defaultOpenValue,
  value,
  onChange,
  disabled,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <fieldset className="react-timepicker-override">
      {
        // Hack: Need overlay layer to close TimePicker in a modal.
        // Cannot catch close event (click at out of TimePicker) without this element
      }
      {open && (
        <div
          className={'rc-time-picker-overlay'}
          onClick={() => {
            setOpen(false);
          }}
        ></div>
      )}
      <RCTimePicker
        allowEmpty={allowEmpty}
        showSecond={false}
        defaultOpenValue={defaultOpenValue}
        value={value}
        onChange={onChange}
        inputReadOnly={isTouchBackend() ? true : false}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disabled={disabled}
      />
    </fieldset>
  );
};
