import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { Input } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { getValidators } from 'shared/libraries/validate/validator';
import baseStyles from 'client/base.module.css';

import styles from './PerGroupPriceInput.module.css';

type Props = {
  name: string;
  currency: string;
  showNet?: boolean;
};
export const PerGroupPriceInput = ({ currency, name, showNet }: Props) => {
  const { t } = useTranslation();
  const editingProduct = React.useContext(EditingProductContext);
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const { required } = getValidators(t);
  let units: string[] = [];

  for (const priceRule of editingProduct?.pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit?.guest_type?.key) {
        units.push(unit.guest_type.key);
      } else if (unit.method === 'PER_GROUP') {
        units.push(
          ...new Set(
            (unit.group_guest_types ?? []).map((guestType) => guestType.key)
          )
        );
      }
    }
  }

  units = [...new Set(units)];

  if (units.length === 0) {
    return (
      <p className={baseStyles['base-form-box__err']}>
        {t(
          'Per-group prices require at least one separate per-participant price schedule'
        )}
      </p>
    );
  }

  return (
    <div className={styles['page-productsRegist__priceMain']}>
      <p className={styles['page-productsRegist__priceMain__ttl']}>
        {t('Set Up Per-Group Prices')}
      </p>
      <FieldArray name={name}>
        {({ fields: priceFields }) => {
          return (
            <>
              {priceFields.length === 0 && (
                <AddIcon
                  onClick={() => {
                    (priceFields as any).insertAt(0, {
                      title: '',
                      method: 'PER_GROUP',
                      groupUnits: [],
                      price: '100',
                      netPrice: '100',
                    });
                  }}
                />
              )}
              <table>
                <tbody>
                  {priceFields.map((priceFieldName, idx) => (
                    <>
                      <tr>
                        <td>
                          <div
                            key={priceFieldName}
                            className={styles['c-tableChild']}
                          >
                            <div>
                              <p>{t('Title')}</p>
                              <TranslatedField
                                name={`${priceFieldName}.title`}
                                validate={required}
                              >
                                {({
                                  input,
                                  translationInput,
                                  meta: { error, touched },
                                }) => (
                                  <>
                                    <div>
                                      <Input
                                        {...input}
                                        maxWidth={600}
                                        type="text"
                                        error={touched && error}
                                      />
                                    </div>
                                    {translationTargetLanguage != null && (
                                      <div>
                                        <Input
                                          {...translationInput}
                                          maxWidth={600}
                                          placeholder={
                                            translationTargetLanguageName
                                          }
                                          type="text"
                                          className={
                                            baseStyles['base-form-text']
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </TranslatedField>
                            </div>
                            <div className={baseStyles['base-margin-top-8']}>
                              <p>{t('Units')}</p>
                              <FieldArray name={`${priceFieldName}.groupUnits`}>
                                {({ fields: unitFields }) => (
                                  <>
                                    {unitFields.length === 0 && (
                                      <AddIcon
                                        onClick={() =>
                                          (unitFields as any).insertAt(
                                            0,
                                            units[0]
                                          )
                                        }
                                      />
                                    )}
                                    <div>
                                      {unitFields.map((unitFieldName, idx) => (
                                        <div
                                          key={unitFieldName}
                                          className={clsx(
                                            baseStyles['base-flex'],
                                            styles[
                                              'page-productsRegist__priceMain__unitRow'
                                            ]
                                          )}
                                        >
                                          <Field name={unitFieldName}>
                                            {({ input }) => (
                                              <label
                                                className={clsx(
                                                  baseStyles['base-form-select']
                                                )}
                                                style={{
                                                  maxWidth: 300,
                                                }}
                                              >
                                                <select
                                                  value={input.value}
                                                  onChange={(e) => {
                                                    input.onChange(
                                                      e.target.value
                                                    );
                                                  }}
                                                >
                                                  {units.map((unit, idx) => (
                                                    <option
                                                      key={idx}
                                                      value={unit}
                                                    >
                                                      {unit}
                                                    </option>
                                                  ))}
                                                </select>
                                              </label>
                                            )}
                                          </Field>
                                          <AddIcon
                                            onClick={() =>
                                              (unitFields as any).insertAt(
                                                idx + 1,
                                                units[0]
                                              )
                                            }
                                          />
                                          <DeleteIcon
                                            onClick={() =>
                                              unitFields.remove(idx)
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                          <div className={styles['c-tableChild']}>
                            <ul>
                              <li className={baseStyles['base-t-240']}>
                                {t('Amount (tax included)')}
                              </li>
                              {showNet && (
                                <li className={baseStyles['base-t-240']}>
                                  {t('Net Amount')}
                                </li>
                              )}
                            </ul>
                            <ul>
                              <li data-title={t('Amount (tax included)')}>
                                <div
                                  className={
                                    styles[
                                      'page-productsRegist__priceMain__price'
                                    ]
                                  }
                                >
                                  <p>{currency}</p>
                                  <Field name={`${priceFieldName}.price`}>
                                    {({ input }) => (
                                      <Input
                                        value={input.value}
                                        onChange={(e: any) => {
                                          if (
                                            !currencyInputAllowed(
                                              currency,
                                              e.target.value
                                            )
                                          ) {
                                            return;
                                          }

                                          input.onChange(e.target.value);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </li>
                              {showNet && (
                                <li data-title={t('Net Amount')}>
                                  <div
                                    className={
                                      styles[
                                        'page-productsRegist__priceMain__price'
                                      ]
                                    }
                                  >
                                    <p>{currency}</p>
                                    <Field name={`${priceFieldName}.netPrice`}>
                                      {({ input }) => (
                                        <Input
                                          value={input.value}
                                          onChange={(e: any) => {
                                            if (
                                              !currencyInputAllowed(
                                                currency,
                                                e.target.value
                                              )
                                            ) {
                                              return;
                                            }

                                            input.onChange(e.target.value);
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </td>
                        <td className={styles['per-group-add-delete']}>
                          <li className={baseStyles['base-flex']}>
                            <DeleteIcon
                              onClick={() => {
                                priceFields.remove(idx);
                              }}
                            />
                          </li>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
