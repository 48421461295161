// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { manifestCustomizedColumnNamesSelector } from 'client/reducers/manifestSettings';
import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import 'react-table/react-table.css';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { ManifestCustomizeCustomTable } from 'client/pages/ManifestCustomize/ManifestCustomizeCustomTable';
import { EditCustomizedColumnNameModal } from 'client/pages/ManifestCustomize/CustomizedColumnName/EditCustomizedColumnNameModal';

type Props = {
  isActive?: boolean,
};

export const CustomizedColumnName = ({ isActive }: Props) => {
  const { t } = useTranslation();

  const customizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );

  const columns = [
    {
      Header: t('Default name'),
      accessor: 'column',
      Cell: (cellInfo) => getColumnHeaderText(cellInfo.original.column, t),
      width: 'long',
    },
    {
      Header: t('Customized name'),
      accessor: 'name',
    },
  ];

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__header'])}>
          <div
            className={clsx(baseStyles['base-main__body__box__header__ttl'])}
          >
            {t('Column names')}
          </div>
          <div
            className={clsx(baseStyles['base-main__body__box__header__btn'])}
          >
            <EditCustomizedColumnNameModal
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['small'],
                    baseStyles['flex'],
                    baseStyles['yellow']
                  )}
                >
                  {t('Edit')}
                </a>
              }
            />
          </div>
        </div>

        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <ManifestCustomizeCustomTable
            items={_.sortBy(customizedColumnNames, (p) => p.column)}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};
