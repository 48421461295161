import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_E_TICKET_QR_CODE_FAILURE,
  CREATE_E_TICKET_QR_CODE_REQUEST,
  CREATE_E_TICKET_QR_CODE_SUCCESS,
  DELETE_E_TICKET_QR_CODE_FAILURE,
  DELETE_E_TICKET_QR_CODE_REQUEST,
  DELETE_E_TICKET_QR_CODE_SUCCESS,
  FETCH_E_TICKET_QR_CODES_FAILURE,
  FETCH_E_TICKET_QR_CODES_REQUEST,
  FETCH_E_TICKET_QR_CODES_SUCCESS,
  UPDATE_E_TICKET_QR_CODE_FAILURE,
  UPDATE_E_TICKET_QR_CODE_REQUEST,
  UPDATE_E_TICKET_QR_CODE_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  ETicketQrCode,
  CreateETicketQrCodeResponse,
  ListETicketQrCodesResponse,
  UpdateETicketQrCodeResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_E_TICKET_QR_CODES_FAILURE:
    case CREATE_E_TICKET_QR_CODE_FAILURE:
    case UPDATE_E_TICKET_QR_CODE_FAILURE:
    case DELETE_E_TICKET_QR_CODE_FAILURE:
      return action.error;

    case FETCH_E_TICKET_QR_CODES_SUCCESS:
    case CREATE_E_TICKET_QR_CODE_SUCCESS:
    case UPDATE_E_TICKET_QR_CODE_SUCCESS:
    case DELETE_E_TICKET_QR_CODE_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_E_TICKET_QR_CODES_REQUEST:
    case CREATE_E_TICKET_QR_CODE_REQUEST:
    case UPDATE_E_TICKET_QR_CODE_REQUEST:
    case DELETE_E_TICKET_QR_CODE_REQUEST:
      return true;

    case FETCH_E_TICKET_QR_CODES_FAILURE:
    case CREATE_E_TICKET_QR_CODE_FAILURE:
    case UPDATE_E_TICKET_QR_CODE_FAILURE:
    case DELETE_E_TICKET_QR_CODE_FAILURE:
    case FETCH_E_TICKET_QR_CODES_SUCCESS:
    case CREATE_E_TICKET_QR_CODE_SUCCESS:
    case UPDATE_E_TICKET_QR_CODE_SUCCESS:
    case DELETE_E_TICKET_QR_CODE_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: ETicketQrCode[] = [], action: any): ETicketQrCode[] => {
  switch (action.type) {
    case FETCH_E_TICKET_QR_CODES_SUCCESS: {
      const response = action.response as ListETicketQrCodesResponse;
      return response.e_ticket_qr_codes ?? [];
    }

    case CREATE_E_TICKET_QR_CODE_SUCCESS: {
      const response = action.response as CreateETicketQrCodeResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_E_TICKET_QR_CODE_SUCCESS: {
      const response = action.response as UpdateETicketQrCodeResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_E_TICKET_QR_CODE_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const eTicketQrCodes = combineReducers({
  error,
  loading,
  all,
});
