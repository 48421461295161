export const formattedPercent = (
  numerator?: number,
  denominator?: number
): string => {
  if (!denominator) {
    return '-';
  }

  if (!numerator) {
    return '0%';
  }

  return ((numerator / denominator) * 100).toFixed(2) + '%';
};
