// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import type { TranslateFuncType } from 'client/components/Translate';

const sectionCandidates = [
  'HEADER',
  'OVERVIEW',
  'GUEST_MY_PAGE',
  'CHANGES_SECTION',
  'INQUIRY',
  'DETAILS',
  'MISC_INFO',
];

const getSectionName = (section: string, t: TranslateFuncType): string => {
  switch (section) {
    case 'HEADER':
      return t('Header');
    case 'OVERVIEW':
      return t('Overview');
    case 'GUEST_MY_PAGE':
      return t('My Page for Guest');
    case 'CHANGES_SECTION':
      return t('Changes');
    case 'INQUIRY':
      return t('Inquiry');
    case 'DETAILS':
      return t('Details');
    case 'MISC_INFO':
      return t('Please read through the following');
  }

  throw new Error(`unrecognized section: ${section}`);
};

export const SectionOrderEditor = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box mb={1}>{t('Drag-and-drop to reorder')}</Box>
      <Field name="sections">
        {({ input }) => (
          <ToggleableDndList
            dragItemType="section"
            selectedItems={input.value.map((item) => ({
              key: item,
              text: getSectionName(item, t),
            }))}
            candidateItems={sectionCandidates.map((item) => ({
              key: item,
              text: getSectionName(item, t),
            }))}
            setSelectedItems={(items) => {
              input.onChange(items.map((item) => item.key));
            }}
            toggleDisabledKeys={['HEADER', 'OVERVIEW', 'DETAILS', 'MISC_INFO']}
          />
        )}
      </Field>
    </div>
  );
};
