// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button, Form, Image, Message, Segment } from 'semantic-ui-react';
import type { TranslatorProps } from 'react-i18next';

import { s3MediaStorage } from 'client/libraries/s3';
import { activeUserSelector } from 'client/reducers/user';
import { config } from 'client/config';

type OwnProps = {
  filename: string,
  organizationID: string,
  organizationType: 'agent' | 'supplier',
  onChange: (newFilename: string) => void,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  file: ?File,
  error: ?Error,
  uploadMessage: string,
  uploadedFileURL: string,
};

// t('Upload')
const UPLOAD_AVAILABLE = 'Upload';
// t('Please Add File')
const UPLOAD_EMPTY = 'Please Add File';
// t('Invalid File')
const UPLOAD_INVALID = 'Invalid File';

class LogoInputComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { filename, organizationID, organizationType } = props;

    const uploadedFileURL = `${config.s3Config.mediaPathRoot}/logos/${organizationType}/${organizationID}/${filename}`;

    this.state = {
      file: null,
      error: null,
      uploadMessage: UPLOAD_AVAILABLE,
      uploadedFileURL,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      const { filename, organizationID, organizationType } = this.props;

      const uploadedFileURL = `${config.s3Config.mediaPathRoot}/logos/${organizationType}/${organizationID}/${filename}`;

      this.setState({
        uploadedFileURL,
      });
    }
  }

  getFileType = () => {
    const { file } = this.state;

    if (!file) return '';

    return file.type;
  };

  validateFile = () => {
    const fileType = this.getFileType().toLowerCase();
    const isValid =
      fileType.indexOf('image/jpeg') !== -1 ||
      fileType.indexOf('image/png') !== -1;
    this.setState({
      uploadMessage: !isValid
        ? this.state.file
          ? UPLOAD_INVALID
          : UPLOAD_EMPTY
        : UPLOAD_AVAILABLE,
    });
    return isValid;
  };

  uploadFile = () => {
    const { organizationID, organizationType, onChange } = this.props;
    const { file } = this.state;

    if (!file) return;

    const objName = `logos/${organizationType}/${organizationID}/${file.name}`;
    s3MediaStorage
      .put(objName, file)
      .then(() =>
        s3MediaStorage
          .get(objName)
          .then(() => {
            // Remove pre-signed suffix
            this.setState({
              error: null,
            });
            onChange(file.name);
            this.setState({
              file: null,
            });
          })
          .catch((err) => {
            throw err;
          })
      )
      .catch((error) => {
        this.setState({
          error,
        });
        onChange('');
      });
  };

  render() {
    const { t } = this.props;

    const { error, uploadMessage, uploadedFileURL } = this.state;

    return (
      <Segment>
        <label>
          {t('Select image file (jpg, png)')}
          <Form.Group inline>
            <Form.Input
              type="file"
              onChange={(e) =>
                this.setState({
                  file: e.target.files[0],
                })
              }
            />
            <Button onClick={this.uploadFile}>{t(uploadMessage)}</Button>
          </Form.Group>
          {error && (
            <Message
              negative
              header={t('Upload failed')}
              content={error.message}
            />
          )}
        </label>
        {uploadedFileURL && (
          <Form.Field>
            <label>{t('Preview')}</label>
            <Image src={uploadedFileURL} size="small" />
          </Form.Field>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeUser: activeUserSelector(state),
});

export const LogoInput = compose(
  withTranslation(),
  connect(mapStateToProps, {})
)(LogoInputComponent);
