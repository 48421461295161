import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Loading.module.css';

interface Props {
  size: 'lg' | 'md';
}

export const Loading = ({ size }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        size === 'md' && styles['c-loading-md'],
        size === 'lg' && styles['c-loading-lg']
      )}
    >
      <div>
        <svg viewBox="22 22 44 44">
          <circle
            cx="44"
            cy="44"
            r="18"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
          ></circle>
        </svg>
      </div>
      <p>{t('Loading...')}</p>
    </div>
  );
};
