import * as React from 'react';
import moment from 'moment-timezone';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { impersonatedAccountSelector } from 'client/reducers/user';
import { buildImpersonateToken } from 'shared/libraries/cognito';
import { getIDToken } from 'client/libraries/cognito';
import type { ReduxState } from 'client/reducers';
import type { Account } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import pageStyles from 'client/pages/pages.module.css';

import styles from './ETicketFootprintDateCount.module.css';

const fetcher = async (
  url: string,
  dateFrom: string,
  dateTo: string,
  annualPassOnly: boolean,
  token: string,
  lang: string,
  impersonatedAccount?: Account
) => {
  const params = new URLSearchParams({
    date_from: dateFrom,
    date_to: dateTo,
  });
  if (annualPassOnly) {
    params.append('annual_pass_only', 'true');
  }

  return fetch(`${url}?${params}`, {
    headers: {
      authorization: `Bearer ${token}`,
      'accept-language': lang,
      impersonate: impersonatedAccount
        ? buildImpersonateToken(impersonatedAccount)
        : '',
    },
  }).then((res) => res.json());
};

export type DateCount = {
  date: string;
  count: number;
};

export type ColumnType<T> = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
  textAlign?: 'right' | 'center' | 'left';
};

const useColumns = (): ColumnType<DateCount>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('Date'),
      th: true,
      accessor: (row) => row.date,
      textAlign: 'center',
    },
    {
      Header: t('Display Count'),
      accessor: (row) => String(row.count || 0),
      textAlign: 'right',
    },
    {
      Header: t('Reservations'),
      Cell: (cellInfo) => {
        return (
          <Link to={`/annualpasses/reservations/${cellInfo.original.date}`}>
            {t('List')}
          </Link>
        );
      },
      textAlign: 'center',
    },
  ];
};

interface Props {
  annualPassOnly?: boolean;
}

export const ETicketFootprintDateCount = ({ annualPassOnly }: Props) => {
  const { i18n } = useTranslation();
  const impersonatedAccount = useSelector(impersonatedAccountSelector);
  const token = useSelector((state: ReduxState) => {
    return getIDToken(state.user.cognito);
  });
  const [selectedMonth, setSelectedMonth] = React.useState<string>(
    moment().format('YYYY-MM')
  );

  const columns = useColumns();

  const dateFrom = moment(selectedMonth).startOf('month').format('YYYY-MM-DD');
  const dateTo = moment(selectedMonth).endOf('month').format('YYYY-MM-DD');

  const { data } = useSWR(
    [
      `/api/eticketfootprints/datecounts`,
      dateFrom,
      dateTo,
      annualPassOnly || false,
      token,
      i18n.language,
      impersonatedAccount,
    ],
    fetcher
  );

  return (
    <div>
      <div className={styles['header']}>
        <Box mt={2}>
          <div className={clsx(pageStyles['page-availability__datepick'])}>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['prev']
              )}
              onClick={() => {
                setSelectedMonth(
                  moment(selectedMonth).add(-1, 'months').format('YYYY-MM')
                );
              }}
            />
            <Box display="flex" alignItems="center" justifyContent="center">
              {moment(selectedMonth).format('YYYY/MM')}
            </Box>
            <a
              className={clsx(
                pageStyles['page-availability__datepick__btn'],
                pageStyles['next']
              )}
              onClick={() => {
                setSelectedMonth(
                  moment(selectedMonth).add(1, 'months').format('YYYY-MM')
                );
              }}
            />
          </div>
        </Box>
      </div>
      {data?.e_ticket_footprint_date_counts && (
        <Box mt={2}>
          <div>
            <CustomTable
              items={data.e_ticket_footprint_date_counts ?? []}
              columns={columns}
            />
          </div>
        </Box>
      )}
    </div>
  );
};
