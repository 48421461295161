import * as React from 'react';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { FORM_ERROR } from 'final-form';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { Button, Select } from 'client/components/Form';
import { updateAccount } from 'client/actions/accounts';
import { getArrayMutators } from 'client/libraries/util/form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import type { ReduxState } from 'client/reducers';
import type { Account } from 'shared/models/swagger';

import styles from './EditMultipleUserAdminModal.module.css';
import {
  getInitialValues,
  convertFormValuesToAccount,
  FormValues,
} from './formValues';

interface Props {
  account?: Partial<Account>;
  trigger?: React.ReactElement<any>;
}

const focusOnError: any = createDecorator();

export const EditMultipleUserAdminModal = ({
  account: existingAccount,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxStore = useStore();

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const initialValues = React.useMemo(
    () => getInitialValues(existingAccount),
    [existingAccount]
  );

  const childAccounts = useSelector((state: ReduxState) => state.accounts.all);

  const accountOptions = [
    ..._.sortBy(
      _.uniqBy(
        childAccounts.filter(
          (a) =>
            a.api_access_type !== 'DIRECT' &&
            a.organization_type === 'SUPPLIER' &&
            a.role === 'PRODUCT_EDITOR' &&
            a.organization_id !== existingAccount?.organization_id
        ),
        (a) =>
          `${a?.organization_id ?? '<MISSING>'}:${
            a.organization_type ?? '<MISSING>'
          }:${a.role === 'CUSTOM_USER' ? a.email : a.role}`
      ),
      (a) => a.organization_name
    ),
  ];
  return (
    <Modal
      title={'管理者権限を付与する組織'}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
      trigger={trigger}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          if (existingAccount) {
            await dispatch(
              updateAccount(
                existingAccount.id ?? '',
                convertFormValuesToAccount(values, existingAccount)
              )
            );

            if (reduxStore.getState().accounts.error) {
              return { [FORM_ERROR]: t('Save Failed') };
            }

            setShowModal(false);
          }
        }}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        decorators={[focusOnError]}
        debug={console.log}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <FieldArray name={'manageableOrganizationIds'}>
                  {({ fields }) => (
                    <>
                      {fields.length === 0 ? (
                        <AddIcon
                          onClick={() => (fields as any).insertAt(0, '')}
                        />
                      ) : (
                        <div className={styles['edit-container']}>
                          {fields.map((name, idx) => (
                            <div key={idx} className={styles['edit-row']}>
                              <div className={styles['input-cell']}>
                                <Field name={name}>
                                  {({ input, meta: { touched, error } }) => (
                                    <Select
                                      search
                                      value={input.value}
                                      onChange={(e, { value }) =>
                                        input.onChange(value)
                                      }
                                      error={touched && error}
                                      options={accountOptions
                                        .map((a) => {
                                          return {
                                            value: a.organization_id || '',
                                            text: a.organization_name || '',
                                          };
                                        })
                                        .filter(
                                          (option) =>
                                            !fields.value.includes(
                                              option.value
                                            ) || option.value === input.value
                                        )}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={styles['button-cell']}>
                                <AddIcon
                                  onClick={() =>
                                    (fields as any).insertAt(idx + 1, '')
                                  }
                                />
                                <DeleteIcon
                                  onClick={() => fields.remove(idx)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
              </Modal.Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                disabled={submitting}
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
