import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { Button, Input, TextArea } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { getValidators } from 'shared/libraries/validate/validator';

import styles from './ComposeMessage.module.css';

interface Props {
  onBackClick: () => void;
}

export const SmsEditor = ({ onBackClick }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.loading
  );
  const { required } = getValidators(t);

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
      </div>

      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('SMS Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Box mb={2}>
            <Field name="title" validate={required}>
              {({ input, meta: { touched, error } }) => (
                <Input
                  label={t('SMS Message Name')}
                  value={input.value}
                  onChange={input.onChange}
                  error={touched && error}
                />
              )}
            </Field>
          </Box>
          <Field name="smsContent" validate={required}>
            {({ input, meta: { touched, error } }) => (
              <TextArea
                label={t('Message Text Content')}
                value={input.value}
                onChange={input.onChange}
                error={touched && error}
              />
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};
