import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import {
  FieldWrapper,
  MultiSelect,
  OptionalIntegerInput,
  ToggleButton,
} from 'client/components/Form';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { DateInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DateInput';
import baseStyles from 'client/base.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';

interface Props {
  name: string;
}

export const ConditionRuleEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const productOptions = allProducts.map((product) => ({
    value: product.id ?? '',
    text: product.internal_product_name ?? '',
  }));

  const limitTypeOptions = [
    {
      value: 'NO_LIMIT',
      label: t('No Limit'),
    },
    {
      value: 'EXPIRATION_TIME_OF_DAY',
      label: t('Expiration'),
      tooltipText: t('Offer expires at a specific time of day'),
    },
    // LIMITED_CLAIMS_PER_DAY is not supported yet
  ];

  const form = useForm();
  const { values } = form.getState();

  const rule = _.get(values, name);

  return (
    <>
      <Box maxWidth="350px">
        <FieldWrapper label={t('Participation Date Range')}>
          <div className={baseStyles['base-form-range']}>
            <label className={baseStyles['base-form-calendar']}>
              <img src={calendarIcon} />
              <Field name={`${name}.participationStartDate`}>
                {({ input }) => (
                  <DateInput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </label>
            <p>-</p>
            <label className={baseStyles['base-form-calendar']}>
              <img src={calendarIcon} />
              <Field name={`${name}.participationEndDate`}>
                {({ input }) => (
                  <DateInput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </label>
          </div>
        </FieldWrapper>
      </Box>

      <Box mt={2}>
        <Field name={`${name}.shouldFilterByBookedProductIds`}>
          {({ input }) => (
            <ToggleButton
              label={t('Show offer for certain booked products')}
              checked={input.value}
              onChange={() => {
                input.onChange(!input.value);
              }}
            />
          )}
        </Field>
      </Box>
      {rule?.shouldFilterByBookedProductIds && (
        <Field name={`${name}.bookedProductIds`}>
          {({ input }) => (
            <MultiSelect
              label={t('Products')}
              options={productOptions}
              selectedValues={input.value}
              onChange={({ value }) => input.onChange(value)}
            />
          )}
        </Field>
      )}

      <Box mt={2}>
        <Field name={`${name}.shouldFilterByTimeOfDay`}>
          {({ input }) => (
            <ToggleButton
              label={t('Show offer based on time of day')}
              checked={input.value}
              onChange={() => {
                input.onChange(!input.value);
              }}
            />
          )}
        </Field>
        {rule?.shouldFilterByTimeOfDay && (
          <>
            <Box mt={2}>
              <FieldWrapper label={t('Display Start Time')}>
                <Field name={`${name}.timeOfDayStart`}>
                  {({ input }) => (
                    <TimePicker
                      allowEmpty={false}
                      value={moment(input.value, 'HH:mm')}
                      onChange={(newMoment) => {
                        if (!newMoment) {
                          return;
                        }
                        input.onChange(newMoment.format('HH:mm'));
                      }}
                    />
                  )}
                </Field>
              </FieldWrapper>
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('Display End Time')}>
                <Field name={`${name}.timeOfDayEnd`}>
                  {({ input }) => (
                    <TimePicker
                      allowEmpty={false}
                      value={moment(input.value, 'HH:mm')}
                      onChange={(newMoment) => {
                        if (!newMoment) {
                          return;
                        }
                        input.onChange(newMoment.format('HH:mm'));
                      }}
                    />
                  )}
                </Field>
              </FieldWrapper>
            </Box>
          </>
        )}
      </Box>
      <Box mt={2}>
        <FieldWrapper label={t('Limit Type')} />
        <EnumRadioButtonGroup
          name={`${name}.limitType`}
          options={limitTypeOptions}
        />
      </Box>
      {rule?.limitType === 'EXPIRATION_TIME_OF_DAY' && (
        <Field name={`${name}.expirationTimeOfDay`}>
          {({ input }) => (
            <FieldWrapper label={t('Expiration Time of Day')}>
              <TimePicker
                allowEmpty={false}
                value={
                  input.value
                    ? moment(input.value, 'HH:mm')
                    : moment('23:59', 'HH:mm')
                }
                onChange={(newMoment) => {
                  if (!newMoment) {
                    return;
                  }

                  input.onChange(newMoment.format('HH:mm'));
                }}
              />
            </FieldWrapper>
          )}
        </Field>
      )}
      {rule?.limitType === 'LIMITED_CLAIMS_PER_DAY' && (
        <Field name={`${name}.dailyClaimLimit`}>
          {({ input }) => (
            <OptionalIntegerInput
              label={t('Daily Claim Limit')}
              value={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
      )}
    </>
  );
};
