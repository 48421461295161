import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_ACCOUNT_SETTINGS_REQUEST,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_SETTINGS_FAILURE,
  PUT_ACCOUNT_SETTINGS_FAILURE,
  PUT_ACCOUNT_SETTINGS_REQUEST,
  PUT_ACCOUNT_SETTINGS_SUCCESS,
} from 'client/constants/ActionTypes';
import * as Swagger from 'shared/models/swagger';

export type AccountSettingsKey = 'dashboard';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_REQUEST:
    case PUT_ACCOUNT_SETTINGS_REQUEST:
      return '';

    case FETCH_ACCOUNT_SETTINGS_FAILURE:
    case PUT_ACCOUNT_SETTINGS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_REQUEST:
      return true;

    case FETCH_ACCOUNT_SETTINGS_FAILURE:
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const journeyAnalytics = (
  state: Swagger.JourneyAnalyticsSettings | null = null,
  action: any
): Swagger.JourneyAnalyticsSettings | null => {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
    case PUT_ACCOUNT_SETTINGS_SUCCESS: {
      if (action.response.key === 'JOURNEY_ANALYTICS') {
        return JSON.parse(action.response.data);
      }
      return state;
    }

    case FETCH_ACCOUNT_SETTINGS_FAILURE:
      return null;

    default:
      return state;
  }
};

export const accountSettings = combineReducers({
  error,
  loading,
  journeyAnalytics,
});
