import { getWeekdayText } from 'client/libraries/util/weekdays';
import type { TranslateFuncType } from 'client/components/Translate';

import type { Schedule } from './types';

export const getScheduleText = (schedule: Schedule, t: TranslateFuncType) => {
  const weekdayText =
    schedule.weekdays.length >= 7
      ? t('Everyday')
      : schedule.weekdays
          .map((weekday) => getWeekdayText(weekday, t))
          .join(',');

  if (schedule.dateRange.startDate && schedule.dateRange.endDate) {
    return `${schedule.dateRange.startDate} ~ ${schedule.dateRange.endDate} - ${weekdayText}`;
  }

  if (schedule.dateRange.startDate) {
    return `${schedule.dateRange.startDate} ~ ${t('Unknown')} - ${weekdayText}`;
  }

  if (schedule.dateRange.endDate) {
    return `${t('Unknown')} ~ ${schedule.dateRange.endDate} - ${weekdayText}`;
  }

  return '~';
};
