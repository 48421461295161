import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';

import styles from './ReservationReport.module.css';

type Props = {
  data: GraphDataItem[];
  currencyCode: string | null;
};
export const CompareTablePane = ({ data, currencyCode }: Props) => {
  const { t } = useTranslation();

  const calcRatio = (base: number, compare: number): string => {
    return `${compare - base > 0 ? '+' : ''}${(
      ((compare - base) / base) *
      100
    ).toFixed(2)} %`;
  };

  if (data.length !== 2) {
    return (
      <div className={clsx(componentStyles['c-table-nowrap'])}>
        Invalid data
      </div>
    );
  }

  let ratioAmountGross: string | number = 0;
  let ratioAmountNet: string | number = 0;
  let ratioNumOfReservations: string | number = 0;
  let ratioPax: string | number = 0;

  if (data[0].amountGross && data[1].amountGross) {
    ratioAmountGross = calcRatio(
      Number(data[1].amountGross),
      Number(data[0].amountGross)
    );
  } else {
    ratioAmountNet = '-';
  }

  if (data[0].amountNet && data[1].amountNet) {
    ratioAmountNet = calcRatio(
      Number(data[1].amountNet),
      Number(data[0].amountNet)
    );
  } else {
    ratioAmountNet = '-';
  }

  if (data[0].pax && data[1].pax) {
    ratioNumOfReservations = calcRatio(
      Number(data[1].numOfReservations),
      Number(data[0].numOfReservations)
    );
  } else {
    ratioNumOfReservations = '-';
  }

  if (data[0].pax && data[1].pax) {
    ratioPax = calcRatio(Number(data[1].pax), Number(data[0].pax));
  } else {
    ratioPax = '-';
  }

  return (
    <div className={clsx(styles['c-table-nowrap'])}>
      <table>
        <tbody>
          <tr>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Date')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Gross')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Net')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Number of Reservations')}
            </th>
            <th
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {t('Pax')}
            </th>
          </tr>
          <tr>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {data[0].name}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(data[0].amountGross, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(data[0].amountNet, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {data[0].numOfReservations}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {data[0].pax}
            </td>
          </tr>
          <tr>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['center-align']
              )}
            >
              {data[1].name}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(data[1].amountGross, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {formattedAmount(data[1].amountNet, currencyCode)}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {data[1].numOfReservations}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {data[1].pax}
            </td>
          </tr>
          <tr>
            <td className={clsx(baseStyles['base-t-min-32'])}></td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {ratioAmountGross}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {ratioAmountNet}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {ratioNumOfReservations}
            </td>
            <td
              className={clsx(
                baseStyles['base-t-min-32'],
                styles['right-align']
              )}
            >
              {ratioPax}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
