import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};
const itemType = 'selectable-highlight';
export interface Props<ItemType> {
  value: ItemType;
  index: number;
  listValue: ItemType[];
  onListValueChange: (arg0: ItemType[]) => void;
  children: React.ReactNode;
}
interface DragItem {
  index: number;
  id: string;
  type: string;
}
export function DraggableItem<ItemType>({
  value,
  index,
  listValue,
  onListValueChange,
  children,
}: Props<ItemType>) {
  const moveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = listValue[dragIndex];
      const newSelectedItems = [...listValue];
      newSelectedItems.splice(dragIndex, 1);
      newSelectedItems.splice(hoverIndex, 0, dragItem);
      onListValueChange(newSelectedItems);
    },
    [listValue, onListValueChange]
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, DragItem, { handlerId: any }>(
    {
      accept: itemType,

      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },

      hover(item: DragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          ref.current && ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }
  );
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: value,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <>
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        {children}
      </div>
    </>
  );
}
