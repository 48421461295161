import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import type { Account, GuideSettings } from 'shared/models/swagger';
import editIcon from 'client/images/ic_edit.svg';

import { EditGuideReservationInfoModal } from './EditGuideReservationInfoModal';

type Props = {
  activeUser: Account | null;
  guideSettings: GuideSettings | null;
};
export const GuideReservationInfo = ({ activeUser, guideSettings }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <strong>
          {t(
            'Register reservation details to be displayed on schedule page and the notification emails.'
          )}
        </strong>
      </div>
      {(guideSettings?.columns || [])
        .map((column) => getColumnHeaderText(column, t))
        .join(',')}
      <EditGuideReservationInfoModal
        activeUser={activeUser}
        guideSettings={guideSettings}
        trigger={
          <a>
            <img src={editIcon} />
          </a>
        }
      />
    </>
  );
};
