import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { getDateFilterPresetOptions } from 'client/libraries/util/searchRedeemedRecords';
import { Modal } from 'client/components/Modal/Modal';
import type {
  SearchRedeemedRecordsRequest,
  DateFilterPreset,
} from 'client/libraries/util/searchRedeemedRecords';
import { productOptionsSelector } from 'client/reducers/products';
import {
  Input,
  DateRangeInput,
  Button,
  MultiSelect,
  Select,
} from 'client/components/Form';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  searchCondition: SearchRedeemedRecordsRequest;
  setSearchCondition: (arg0: SearchRedeemedRecordsRequest) => void;
  trigger: React.ReactElement<any>;
};

export const RedeemedRecordSearchSettingsModal = ({
  onReset,
  onSearch,
  searchCondition,
  setSearchCondition,
  trigger,
}: Props) => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return { value: option.value, text: option.text };
  });

  const dateFilterPresetOptions = [
    { value: 'NONE', text: t('None') },
    ...getDateFilterPresetOptions(t),
  ];

  const changeProductId = ({ value }: { value: any }) => {
    setSearchCondition({
      ...searchCondition,
      productIds: value,
    });
  };

  const participationDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const participationDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateTo: date,
      dateFilterPreset: null,
    });
  };

  const redeemedDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      redeemedDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const redeemedDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      redeemedDateTo: date ? moment(date).format('YYYY-MM-DD') : '',
      dateFilterPreset: null,
    });
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <Modal
      title={t('Search Redeemed Record')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        <Modal.Box>
          <MultiSelect
            search
            label={t('Product')}
            options={productOptions}
            selectedValues={searchCondition.productIds}
            onChange={changeProductId}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Application Number')}
            value={searchCondition.agentReference}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                agentReference: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Confirmation Number')}
            value={searchCondition.supplierReference}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                supplierReference: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Select
            label={t('Date Preset')}
            options={dateFilterPresetOptions}
            value={searchCondition.dateFilterPreset ?? 'NONE'}
            onChange={(event, { value }) => {
              setSearchCondition({
                ...searchCondition,
                dateFilterPreset:
                  value === 'NONE' ? null : (value as DateFilterPreset),
                redeemedDateFrom: '',
                redeemedDateTo: '',
                participationDateFrom: '',
                participationDateTo: '',
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <DateRangeInput
            label={t('Participation Date')}
            fromDate={searchCondition.participationDateFrom}
            onChangeFromDate={participationDateDateFromChangeHandler}
            toDate={searchCondition.participationDateTo}
            onChangeToDate={participationDateDateToChangeHandler}
          />
          <DateRangeInput
            label={t('Redemption Date')}
            fromDate={searchCondition.redeemedDateFrom}
            onChangeFromDate={redeemedDateFromChangeHandler}
            toDate={searchCondition.redeemedDateTo}
            onChangeToDate={redeemedDateToChangeHandler}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Given Name')}
            value={searchCondition.customerGivenName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerGivenName: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Family Name')}
            value={searchCondition.customerFamilyName}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerFamilyName: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Usage')}
            value={searchCondition.titleText}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                titleText: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Page title')}
            value={searchCondition.pageTitleText}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                pageTitleText: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
          }}
        >
          {t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
