import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { sendWaiverRequestEmail } from 'client/actions/reservations';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { getWaiverStatus } from 'client/libraries/util/getWaiverStatusText';
import type { Reservation } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';

import styles from './ReservationWaiversTab.module.css';
import { SendWaiverRequestEmailModal } from './SendWaiverRequestEmailModal';
import { WaiverDisplay } from './WaiverDisplay';

type Props = {
  reservation: Reservation;
};
export const ReservationWaiversTab = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const [emailSendingStatus, setEmailSendingStatus] = React.useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const isFutureReservation = moment(reservation.start_date_time_utc).isAfter(
    moment()
  );
  const waiverIsComplete = getWaiverStatus(reservation) === 'COMPLETE';
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;
  return (
    <div className={clsx(baseStyles['base-main__body__box'])}>
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Waiver')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {!waiverIsComplete &&
          isFutureReservation &&
          (!isSubscriptionCancelled(
            activeOrganization,
            'feature-digital-waiver'
          ) ? (
            <>
              <Box mb={2}>
                <SendWaiverRequestEmailModal
                  initialToAddress={email}
                  trigger={
                    <Button
                      style="green"
                      size="middle"
                      disabled={emailSendingStatus === 'SUCCEEDED'}
                      loading={emailSendingStatus === 'REQUESTED'}
                    >
                      {t('Send Waiver Request Email')}
                    </Button>
                  }
                  onSubmit={(email?: string) => {
                    try {
                      setEmailSendingStatus('REQUESTED');
                      dispatch(
                        sendWaiverRequestEmail(reservation?.id ?? '', email)
                      );
                      setEmailSendingStatus('SUCCEEDED');
                    } catch (e) {
                      setEmailSendingStatus('FAILED');
                    }
                  }}
                />
              </Box>

              {emailSendingStatus == 'SUCCEEDED' && (
                <Message success header={t('Send Successful')} />
              )}
              {emailSendingStatus == 'FAILED' && (
                <Message error header={t('Send Failed')} />
              )}
            </>
          ) : (
            <>
              {t(
                'Your subscription to the waivers feature has been deactivated so sending waiver request emails is disabled. Resubscribe here: '
              )}{' '}
              <a
                className={styles['link']}
                href={`${window.origin}/systemfee/settings`}
                target="_blank"
                rel="noreferrer"
              >
                {t('Settings')}
              </a>
            </>
          ))}

        <WaiverDisplay reservation={reservation} />
      </div>
    </div>
  );
};
