import * as React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import createDecorator from 'final-form-focus';

import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserSelector } from 'client/reducers/user';
import { createAccount, updateAccount } from 'client/actions/accounts';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { Account } from 'shared/models/swagger';

import {
  getInitialValues,
  convertFormValuesToAccount,
  FormValues,
} from './formValues';
import { EditAccountForm } from './EditAccountForm';

const focusOnError: any = createDecorator();

interface Props {
  onClose: () => void;
  existingAccount?: Account;
}

export const EditAccountModal = ({ onClose, existingAccount }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduxStore = useStore();

  const activeUser = useSelector(activeUserSelector);

  const initialValues = React.useMemo(
    () => getInitialValues(activeUser?.locale, existingAccount),
    [existingAccount, activeUser?.locale]
  );

  return (
    <Modal
      insertRoot
      title={existingAccount ? t('Update Account') : t('Create New Account')}
      onClose={onClose}
      open={true}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          if (existingAccount) {
            await dispatch(
              updateAccount(
                existingAccount.id,
                convertFormValuesToAccount(values)
              )
            );

            if (reduxStore.getState().accounts.error) {
              return { [FORM_ERROR]: t('Save Failed') };
            }

            onClose();
          } else {
            await dispatch(
              createAccount(convertFormValuesToAccount(values) as Account)
            );

            if (reduxStore.getState().accounts.error) {
              return { [FORM_ERROR]: t('Save Failed') };
            }

            onClose();
          }
        }}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        decorators={[focusOnError]}
        debug={console.log}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <EditAccountForm existingAccount={existingAccount} />

              {submitError && (
                <Box mt={2}>
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                </Box>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
              <Button.Submit loading={submitting} type="submit">
                {t('Save')}
              </Button.Submit>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
