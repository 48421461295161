import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SYSTEM_FEE_INVOICES_REQUEST,
  FETCH_SYSTEM_FEE_INVOICES_FAILURE,
  FETCH_SYSTEM_FEE_INVOICES_SUCCESS,
  FETCH_SYSTEM_FEE_INVOICE_REQUEST,
  FETCH_SYSTEM_FEE_INVOICE_FAILURE,
  FETCH_SYSTEM_FEE_INVOICE_SUCCESS,
  FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST,
  FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
  UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_REQUEST,
  UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_SUCCESS,
  UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_FAILURE,
  CONFIRM_SYSTEM_FEE_INVOICE_REQUEST,
  CONFIRM_SYSTEM_FEE_INVOICE_SUCCESS,
  CONFIRM_SYSTEM_FEE_INVOICE_FAILURE,
  MARK_SYSTEM_FEE_INVOICE_PAID_REQUEST,
  MARK_SYSTEM_FEE_INVOICE_PAID_SUCCESS,
  MARK_SYSTEM_FEE_INVOICE_PAID_FAILURE,
} from 'client/constants/ActionTypes';
import {
  getHTTPRequestHeaders,
  getHTTPRequestHeadersWithoutImpersonation,
} from 'client/actions';
import { ReduxState } from 'client/reducers';
import { SystemFeeInvoiceFareAdjustment } from 'shared/models/swagger';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSystemFeeInvoicesRequest = () => ({
  type: FETCH_SYSTEM_FEE_INVOICES_REQUEST,
});

const fetchSystemFeeInvoicesSuccess = (response: any) => ({
  type: FETCH_SYSTEM_FEE_INVOICES_SUCCESS,
  response,
});

const fetchSystemFeeInvoicesFailure = (error: any) => ({
  type: FETCH_SYSTEM_FEE_INVOICES_FAILURE,
  error,
});

export const fetchSystemFeeInvoices =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSystemFeeInvoicesRequest());
    return axios
      .get('/api/systemfeeinvoices', {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchSystemFeeInvoicesSuccess(result.data)))
      .catch((error) => dispatch(fetchSystemFeeInvoicesFailure(error)));
  };

const fetchSystemFeeInvoiceRequest = () => ({
  type: FETCH_SYSTEM_FEE_INVOICE_REQUEST,
});

const fetchSystemFeeInvoiceSuccess = (response: any) => ({
  type: FETCH_SYSTEM_FEE_INVOICE_SUCCESS,
  response,
});

const fetchSystemFeeInvoiceFailure = (error: any) => ({
  type: FETCH_SYSTEM_FEE_INVOICE_FAILURE,
  error,
});

export const fetchSystemFeeInvoice =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSystemFeeInvoiceRequest());
    return axios
      .get(`/api/systemfeeinvoices/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(fetchSystemFeeInvoiceSuccess(result.data)))
      .catch((error) => dispatch(fetchSystemFeeInvoiceFailure(error)));
  };

const fetchSystemFeeInvoiceCSVRequest = () => ({
  type: FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST,
});

const fetchSystemFeeInvoiceCSVSuccess = () => ({
  type: FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS,
});

const fetchSystemFeeInvoiceCSVFailure = (error: string) => ({
  type: FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE,
  error,
});

export const fetchSystemFeeInvoiceCSV =
  (id: string, queryParams?: Record<string, any>) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSystemFeeInvoiceCSVRequest());
    axios
      .get(`/api/systemfeeinvoices/${id}/csv`, {
        params: {
          ...queryParams,
        },
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'reservations.csv');
        dispatch(fetchSystemFeeInvoiceCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchSystemFeeInvoiceCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };

const updateSystemFeeInvoiceFareAdjustmentsRequest = () => ({
  type: UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_REQUEST,
});

const updateSystemFeeInvoiceFareAdjustmentsSuccess = (response: any) => ({
  type: UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_SUCCESS,
  response,
});

const updateSystemFeeInvoiceFareAdjustmentsFailure = (error: any) => ({
  type: UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_FAILURE,
  error,
});

export const updateSystemFeeInvoiceFareAdjustments =
  (id: string, fareAdjustment: SystemFeeInvoiceFareAdjustment) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(updateSystemFeeInvoiceFareAdjustmentsRequest());
    return axios
      .post(`/api/systemfeeinvoices/${id}/fareadjust`, fareAdjustment, {
        headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
      })
      .then((result) =>
        dispatch(updateSystemFeeInvoiceFareAdjustmentsSuccess(result.data))
      )
      .catch((error) =>
        dispatch(updateSystemFeeInvoiceFareAdjustmentsFailure(error))
      );
  };

const confirmSystemFeeInvoiceRequest = () => ({
  type: CONFIRM_SYSTEM_FEE_INVOICE_REQUEST,
});

const confirmSystemFeeInvoiceSuccess = (response: any) => ({
  type: CONFIRM_SYSTEM_FEE_INVOICE_SUCCESS,
  response,
});

const confirmSystemFeeInvoiceFailure = (error: any) => ({
  type: CONFIRM_SYSTEM_FEE_INVOICE_FAILURE,
  error,
});

export const confirmSystemFeeInvoice =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(confirmSystemFeeInvoiceRequest());
    return axios
      .post(
        `/api/systemfeeinvoices/${id}/confirm`,
        {},
        {
          headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
        }
      )
      .then((result) => dispatch(confirmSystemFeeInvoiceSuccess(result.data)))
      .catch((error) => dispatch(confirmSystemFeeInvoiceFailure(error)));
  };

const markSystemFeeInvoicePaidRequest = () => ({
  type: MARK_SYSTEM_FEE_INVOICE_PAID_REQUEST,
});

const markSystemFeeInvoicePaidSuccess = (response: any) => ({
  type: MARK_SYSTEM_FEE_INVOICE_PAID_SUCCESS,
  response,
});

const markSystemFeeInvoicePaidFailure = (error: any) => ({
  type: MARK_SYSTEM_FEE_INVOICE_PAID_FAILURE,
  error,
});

export const markSystemFeeInvoicePaid =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(markSystemFeeInvoicePaidRequest());
    return axios
      .post(
        `/api/systemfeeinvoices/${id}/markpaid`,
        {},
        {
          headers: getHTTPRequestHeadersWithoutImpersonation(getState()),
        }
      )
      .then((result) => dispatch(markSystemFeeInvoicePaidSuccess(result.data)))
      .catch((error) => dispatch(markSystemFeeInvoicePaidFailure(error)));
  };
