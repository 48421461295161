import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import { Weekday } from 'client/libraries/util/weekdays';
import * as Swagger from 'shared/models/swagger';
import { generateRandomString } from 'shared/models/util';

export interface FormValues {
  restaurantId: string;
  name: string;
  mediaUrls: string[];
  category: string;
  activeSchedules: OpenHourSchedule[];
  menuItems: MenuItem[];
}

export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};

export interface OpenHourSchedule {
  openFrom: string;
  openTo: string;
  schedule: Schedule;
}

export interface Schedule {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
}

export interface MenuItemOption {
  name: string;
  price: string;
  key: string;
  description: string;
  mediaUrls?: string[];
}

export interface MenuItemAddOn {
  name: string;
  price: string;
  key: string;
  description: string;
  mediaUrls?: string[];
}

export interface MenuItem {
  name: string;
  description: string;
  price: string;
  mediaUrls?: string[];
  category: string;
  tags?: string[];
  isRecommended: boolean;
  key: string;
  options?: MenuItemOption[];
  addOns?: MenuItemAddOn[];
  addOnsLimit?: string;
  allergens?: string[];
}

export const getInitialValues = (
  existingRestaurant: Swagger.Restaurant | null,
  restaurantId: string
): FormValues => {
  const existingMenu = existingRestaurant?.menu;

  return {
    restaurantId: existingRestaurant?.id || restaurantId,
    name: existingMenu?.name ?? '',
    mediaUrls: existingMenu?.media_items?.map((m) => m.url) ?? [],
    category: existingMenu?.category ?? '',
    activeSchedules:
      existingMenu?.active_schedules?.map((sched) => ({
        openFrom: sched.start_time_local ?? '0:00',
        openTo: sched.end_time_local ?? '0:00',
        schedule: {
          dateRanges:
            sched.schedule?.date_ranges?.map((dateRange) => ({
              startDate: dateRange.start_date_local ?? '',
              endDate: dateRange.end_date_local ?? '',
            })) ?? [],
          weekdays: sched.schedule?.days_of_week ?? [],
          closedDates:
            sched.schedule?.closed_dates?.map((closedDate) => ({
              date: closedDate,
              repeatsAnnually: false,
            })) ?? [],
        },
      })) ?? [],
    menuItems:
      existingMenu?.menu_items?.map((menuItem) => ({
        key: menuItem.key || '',
        name: menuItem.name ?? '',
        description: menuItem.description ?? '',
        price:
          menuItem.price && menuItem.price.length > 3
            ? menuItem.price.substring(3)
            : '',
        mediaUrls:
          menuItem.media_items?.map((mediaItem) => mediaItem.url) ?? [],
        category: menuItem.category ?? '',
        tags: menuItem.tags ?? [],
        isRecommended: menuItem.is_recommended ?? false,
        allergens: menuItem.allergens ?? [],
        options:
          menuItem.options?.map((option) => ({
            key: option.key || '',
            name: option.name || '',
            price:
              option.price && option.price.length > 3
                ? option.price.substring(3)
                : '',
            description: option.description || '',
            mediaUrls:
              option.media_items?.map((mediaItem) => mediaItem.url) ?? [],
          })) ?? [],
        addOns:
          menuItem.add_ons?.map((addOn) => ({
            key: addOn.key || '',
            name: addOn.name || '',
            price:
              addOn.price && addOn.price.length > 3
                ? addOn.price.substring(3)
                : '',
            description: addOn.description || '',
            mediaUrls:
              addOn.media_items?.map((mediaItem) => mediaItem.url) ?? [],
          })) ?? [],
        addOnsLimit: (menuItem.add_on_selection_limit || 0)?.toString() ?? '',
      })) ?? [],
  };
};

export const convertFormValuesToSwaggerRestaurantPatch = (
  values: FormValues,
  currencyCode: string
): Swagger.Restaurant => {
  return {
    menu: {
      name: values.name,
      category: values.category,
      media_items: convertMediaUrlsToProductMediaItems(values.mediaUrls ?? []),
      active_schedules: values.activeSchedules.map((sched) => ({
        start_time_local: sched.openFrom,
        end_time_local: sched.openTo,
        schedule: {
          date_ranges: sched.schedule.dateRanges.map((dateRange) => ({
            start_date_local: dateRange.startDate,
            end_date_local: dateRange.endDate,
          })),
          days_of_week: sched.schedule.weekdays,
          closed_dates: sched.schedule.closedDates.map(
            (closedDate) => closedDate.date
          ),
        },
      })),
      menu_items: values.menuItems.map((menuItem) => ({
        name: menuItem.name,
        description: menuItem.description,
        price: menuItem.price ? `${currencyCode}${menuItem.price}` : '',
        category: menuItem.category,
        tags: menuItem.tags,
        media_items: convertMediaUrlsToProductMediaItems(
          menuItem.mediaUrls ?? []
        ),
        is_recommended: menuItem.isRecommended,
        key: menuItem.key || generateMenuItemKey(menuItem.name),
        allergens: menuItem.allergens ?? [],
        options: (menuItem.options || []).map((option) => ({
          key: option.key || generateMenuItemKey(option.name),
          name: option.name,
          price: option.price ? `${currencyCode}${option.price}` : '',
          description: option.description,
          media_items: convertMediaUrlsToProductMediaItems(
            option.mediaUrls ?? []
          ),
        })),
        add_ons: (menuItem.addOns || []).map((addOn) => ({
          key: addOn.key || generateMenuItemKey(addOn.name),
          name: addOn.name,
          price: addOn.price ? `${currencyCode}${addOn.price}` : '',
          description: addOn.description,
          media_items: convertMediaUrlsToProductMediaItems(
            addOn.mediaUrls ?? []
          ),
        })),
        add_on_selection_limit: parseInt(menuItem.addOnsLimit || '0', 10),
      })),
    },
  };
};

export const generateMenuItemKey = (name: string): string => {
  return (
    name
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
      .substring(0, 9) + generateRandomString(3)
  );
};
