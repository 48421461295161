// @flow

import * as React from 'react';
import { Button, Form, Header, List, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { createContract } from 'client/actions/contract';
import type { ReduxState } from 'client/reducers/index';
import type { Contract$Input } from 'shared/models/swagger';

type OwnProps = {
  trigger: React.ComponentType<{}>,
  header?: string,
};
/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *>,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */
type State = {
  showModal: boolean,
  contract: any,
  agentOrgOptions: any,
  supplierOrgOptions: any,
};
const options = [
  { key: 'DIRECT', text: 'DIRECT', value: 'DIRECT' },
  { key: 'RESELL', text: 'RESELL', value: 'RESELL' },
];

class CreateContractModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      contract: {},
      agentOrgOptions: [],
      supplierOrgOptions: [],
    };
  }
  componentWillMount() {
    let { organizations } = this.props;
    this.setState({
      agentOrgOptions: organizations
        .filter((organization) => organization.type === 'AGENT')
        .map((organization) => ({
          key: organization.id,
          text: organization.name,
          value: organization.id,
        }))
        .sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          }
          if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
      supplierOrgOptions: organizations
        .filter((organization) => organization.type === 'SUPPLIER')
        .map((organization) => ({
          key: organization.id,
          text: organization.name,
          value: organization.id,
        }))
        .sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
          }
          if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
    });
  }

  createContract = () => {
    this.props.createContract((this.state.contract: any));
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { trigger: Trigger, header, t } = this.props;

    return (
      <React.Fragment>
        <Modal
          trigger={
            <Trigger
              onClick={() =>
                this.setState({
                  showModal: true,
                })
              }
            />
          }
          open={this.state.showModal}
          onClose={() =>
            this.setState({
              showModal: false,
            })
          }
        >
          <Header icon="edit" content={header} />
          <Modal.Content>
            <Modal.Description>
              <List />
              <Form>
                <Form.Select
                  fluid
                  search
                  label={t('Agent')}
                  value={`${this.state.contract.agent_id || ''}`}
                  options={this.state.agentOrgOptions}
                  onChange={(e, { value }) =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        agent_id: value,
                      },
                    })
                  }
                  placeholder="Agent Name"
                />
                <Form.Select
                  fluid
                  search
                  label={t('Supplier')}
                  value={`${this.state.contract.supplier_id || ''}`}
                  options={this.state.supplierOrgOptions}
                  onChange={(e, { value }) =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        supplier_id: value,
                      },
                    })
                  }
                  placeholder="Supplier"
                />
                <Form.Select
                  fluid
                  label={t('Contract Type')}
                  options={options}
                  onChange={(e, { value }) =>
                    this.setState({
                      contract: {
                        ...this.state.contract,
                        type: value,
                      },
                    })
                  }
                  placeholder="Contract Type"
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  contract: {},
                  showModal: false,
                });
              }}
            >
              {t('Close')}
            </Button>
            <Button primary onClick={this.createContract}>
              {t('Add Contract')}
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  organizations: state.organizations.all,
});

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  createContract: (contract: Contract$Input) =>
    dispatch(createContract(contract)),
});

export const CreateContractModal = compose(
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(CreateContractModalComponent);
