import * as React from 'react';
import moment from 'moment-timezone';

import { CalendarMonthSelect } from './CalendarMonthSelect';
import { CalendarDates } from './CalendarDates';
import { CalendarLegend } from './CalendarLegend';
import styles from './Calendar.module.css';
import { useSessions } from './useSessions';

export const Calendar = () => {
  const sessions = useSessions();
  const firstSessionDate = moment(sessions?.[0]?.start_date_time_utc);

  const [month, setMonth] = React.useState<string>(
    firstSessionDate.format('YYYY-MM')
  );

  return (
    <div className={styles['calendar-container']}>
      <CalendarMonthSelect
        month={month}
        onChangeMonth={(m: string) => setMonth(m)}
      />
      <CalendarDates month={month} />
      <CalendarLegend />
    </div>
  );
};
