import * as React from 'react';
import clsx from 'clsx';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { operationAllowed } from 'shared/models/access';
import { activeUserSelector } from 'client/reducers/user';
import { calculateReservationFares } from 'client/actions/fares';
import { separatedCurrency as currency } from 'shared/libraries/currency';
import { ReduxState } from 'client/reducers';
import type { NewReservation } from 'shared/models/swagger';
import styles from 'client/pages/BookingForm/Book.module.css';

type Props = {
  reservationParams: NewReservation;
  isChangeReservation?: boolean;
};
export const FareSummaryV2 = ({
  reservationParams,
  isChangeReservation,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const loading = useSelector((state: ReduxState) => state.fares.loading);
  const billingInfo = useSelector(
    (state: ReduxState) => state.fares.billingInfo
  );
  const {
    add_ons,
    booking_source,
    guests,
    product_instance_id,
    transportation,
    promo_code,
    rebooked_from_reservation_id,
    adjustment_amount,
  } = reservationParams;
  React.useEffect(() => {
    dispatch(
      calculateReservationFares({
        agent_reference: 'dummy-reference-1',
        product_instance_id,
        guests,
        add_ons,
        transportation,
        booking_source,
        promo_code,
        payment_type: 'PAID_IN_FULL',
        rebooked_from_reservation_id: isChangeReservation
          ? rebooked_from_reservation_id
          : '',
        adjustment_amount: adjustment_amount,
      })
    );
  }, [
    product_instance_id,
    guests,
    add_ons,
    transportation,
    booking_source,
    isChangeReservation,
    rebooked_from_reservation_id,
    adjustment_amount,
  ]);

  if (!billingInfo) {
    return <div>{t('-')}</div>;
  }

  const lineItems = billingInfo.line_items || [];
  const totalGross = billingInfo.amount_gross;
  const totalNet = billingInfo.amount_net;
  const variableMarkupAmount = billingInfo.variable_markup_amount;
  return operationAllowed(activeUser, 'read', 'netPrices') ? (
    <div className={clsx(styles['fare-summary-table'])}>
      <Loader active={loading} />
      <table>
        <tbody>
          <tr key={0}>
            <th style={{ width: '30%' }}>{t('Title')}</th>
            <th style={{ width: '35%' }}>{t('Gross')}</th>
            <th style={{ width: '35%' }}>{t('Net')}</th>
          </tr>
          {lineItems.map((i) => {
            const title = i.title || '';
            const count = i.count || '';
            const amountGross = i.amount_gross || '';
            const amountNet = i.amount_net || '';
            return (
              <tr key={i.title}>
                <td>{title}</td>
                <td>
                  <p className={clsx(styles['fare-summary-table__bold'])}>
                    {currency(amountGross).multiply(count).format()}
                  </p>
                  <p className={clsx(styles['fare-summary-table__small'])}>
                    ({currency(amountGross).format()} x {count})
                  </p>
                </td>
                <td>
                  <p className={clsx(styles['fare-summary-table__bold'])}>
                    {currency(amountNet).multiply(count).format()}
                  </p>
                  <p className={clsx(styles['fare-summary-table__small'])}>
                    ({currency(amountNet).format()} x {count})
                  </p>
                </td>
              </tr>
            );
          })}

          {variableMarkupAmount && (
            <tr key={1}>
              <td>{t('Taxes and Fees')}</td>
              <td>
                <p className={clsx(styles['fare-summary-table__bold'])}>
                  {currency(variableMarkupAmount).format()}
                </p>
              </td>
              <td>
                <p>-</p>
              </td>
            </tr>
          )}
          <tr key={2}>
            <td>{t('Total')}</td>
            <td>
              <p className={clsx(styles['fare-summary-table__bold'])}>
                {currency(totalGross ?? '').format()}
              </p>
            </td>
            <td>
              <p className={clsx(styles['fare-summary-table__bold'])}>
                {currency(totalNet ?? '').format()}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className={clsx(styles['fare-summary-table'])}>
      <table>
        <tbody>
          <tr key={0}>
            <th style={{ width: '30%' }}>{t('Title')}</th>
            <th style={{ width: '70%' }}>{t('Gross')}</th>
          </tr>
          {lineItems.map((i) => {
            const title = i.title || '';
            const count = i.count || '';
            const amountGross = i.amount_gross || '';
            return (
              <tr key={i.title}>
                <td>{title}</td>
                <td>
                  <p className={clsx(styles['fare-summary-table__bold'])}>
                    {currency(amountGross).multiply(count).format()}
                  </p>
                  <p className={clsx(styles['fare-summary-table__small'])}>
                    ({currency(amountGross).format()} x {count})
                  </p>
                </td>
              </tr>
            );
          })}

          {variableMarkupAmount && (
            <tr key={1}>
              <td>{t('Taxes and Fees')}</td>
              <td>
                <p className={clsx(styles['fare-summary-table__bold'])}>
                  {currency(variableMarkupAmount).format()}
                </p>
              </td>
            </tr>
          )}
          <tr key={2}>
            <td>{t('Total')}</td>
            <td>
              <p className={clsx(styles['fare-summary-table__bold'])}>
                {currency(totalGross ?? '').format()}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
