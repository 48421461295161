/**
 * This code was generated by Builder.io.
 */
import React from 'react';

import styles from './FeedbackModule.module.css';

interface FeedbackHeaderProps {
  title: string;
  description: string;
}

const FeedbackHeader: React.FC<FeedbackHeaderProps> = ({
  title,
  description,
}) => (
  <header className={styles.feedbackHeader}>
    <h1 className={styles.feedbackTitle}>{title}</h1>
    <p className={styles.feedbackDescription}>{description}</p>
  </header>
);

export default FeedbackHeader;
