import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EditOrganizationNameModal = ({ setShowModal }: Props) => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const [name, setName] = useState<string>(organization?.name || '');
  useEffect(() => {
    setName(organization?.name || '');
  }, [organization]);
  return (
    <Modal
      title={t('Change Organization Name')}
      open={true}
      onClose={() => setShowModal(false)}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            uiType="bg"
            size="md"
            color="white"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <Button
            type="submit"
            text={t('Save')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={async () => {
              organization &&
                dispatch(
                  updateOrganization(organization.id, organization.type, {
                    name,
                  })
                );
              setShowModal(false);
            }}
          />
        </>
      }
      style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
    >
      <Message
        info
        content={t(
          'Note: after changing organization name, it can take up to 24 hours for the name change to be reflected in the entire system. Existing reservations will continue to use the old organization name.'
        )}
      />
      <TextField
        label={t('Organization Name')}
        value={name}
        onChange={(value) => setName(value)}
      />
    </Modal>
  );
};
