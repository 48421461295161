import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { getArrayMutators } from 'client/libraries/util/form';
import {
  allDispatchCrewMembersSelector,
  allDispatchVehiclesSelector,
  allDispatchMiscResourcesSelector,
} from 'client/reducers/dispatchSettings';
import {
  getResourceFilterOptions,
  crewMemberIsActiveForDate,
} from 'client/pages/Dispatch/util';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { sequentialBatchUpdateReservations } from 'client/actions/reservations';
import { buildPatch } from 'client/components/ResourceAssignmentModal/resources';
import {
  activeUserOrganizationSelector,
  accountsSelector,
} from 'client/reducers/user';
import {
  getGuideAccountShapes,
  isActiveAccount,
  isOutOfOffice,
} from 'client/libraries/util/accountShape';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import type { GuideSingleDaySchedule } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { ResourceList } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDailyContents/ResourceList';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';

type Props = {
  open: boolean;
  reservations: ManifestReservationShape[];
  participationDate: string;
  onClose: () => void;
  guideSingleDaySchedules: GuideSingleDaySchedule[];
  onSubmitSuccess: () => void;
};
export const EditReservationResourceAssignmentModal = ({
  open,
  reservations,
  participationDate,
  onClose,
  guideSingleDaySchedules,
  onSubmitSuccess,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allCrewMembers = useSelector(allDispatchCrewMembersSelector);
  const allVehicles = useSelector(allDispatchVehiclesSelector);
  const allOtherResources = useSelector(allDispatchMiscResourcesSelector);
  const accounts = useSelector(accountsSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const availableGuideAccountShapes = getGuideAccountShapes(
    accounts || [],
    activeUserOrganization
  )
    .filter((guide) => isActiveAccount(guide))
    .filter(
      (guide) =>
        !isOutOfOffice(participationDate, guide, guideSingleDaySchedules)
    );
  const isGuideAccountActive = hasSubscription(
    activeUserOrganization,
    'feature-guide-account'
  );
  const activeCrewMembers = allCrewMembers.filter((crewMember) =>
    crewMemberIsActiveForDate(crewMember, participationDate)
  );
  const crewFilterOptions = [
    ...getResourceFilterOptions(activeCrewMembers),
    ...(isGuideAccountActive
      ? availableGuideAccountShapes.map((guide) => {
          return {
            key: guide.id,
            value: guide.id,
            text: guide.name + '(' + t('staff') + ')',
          };
        })
      : []),
  ];
  const crews =
    reservations?.length === 1
      ? [
          ...(reservations[0].dispatch_crew || []),
          ...(reservations[0].dispatch_guides || []),
        ]
      : [];
  const vehicleFilterOptions = getResourceFilterOptions(allVehicles);
  const vehicles =
    reservations?.length === 1 ? reservations[0].dispatch_vehicles : [];
  const otherFilterOptions = getResourceFilterOptions(allOtherResources);
  const others =
    reservations?.length === 1 ? reservations[0].dispatch_misc_resources : [];

  const isGuide = (key: string) => {
    return (availableGuideAccountShapes || [])
      .map((guide) => guide.id)
      .includes(key);
  };

  return (
    <Form
      onSubmit={async (values: {
        vehicles: string[];
        crews: string[];
        others: string[];
      }) => {
        try {
          if (reservations.length > 0) {
            const updateRequests = reservations.map((reservation) => {
              return {
                id: reservation.id,
                patch: {
                  ...buildPatch(
                    'vehicle',
                    values.vehicles.filter((r) => r !== '')
                  ),
                  ...buildPatch(
                    'crew',
                    values.crews.filter((r) => r !== '' && !isGuide(r))
                  ),
                  ...buildPatch(
                    'guide',
                    values.crews.filter((r) => r !== '' && isGuide(r))
                  ),
                  ...buildPatch(
                    'other',
                    values.others.filter((r) => r !== '')
                  ),
                },
              };
            });
            await dispatch(sequentialBatchUpdateReservations(updateRequests));
            await onSubmitSuccess();
          }

          await onClose();
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={{
        crews,
        vehicles,
        others,
      }}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitError, submitting, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            id="editReservationResourceAssignmentModalForm"
          >
            <Modal
              title={t('Assign Resource')}
              style={{ height: '600px' }}
              open={open}
              onClose={onClose}
              rightActionChildren={
                <>
                  <Button
                    text={t('Cancel')}
                    onClick={() => {
                      onClose();
                    }}
                    color="white"
                  />
                  <Button
                    text={t('Save')}
                    loading={submitting}
                    color="primary"
                    type="submit"
                    form="editReservationResourceAssignmentModalForm"
                  />
                </>
              }
              insertAtRoot={true}
            >
              <div className={styles['p-manifestsModalResources']}>
                <ResourceList
                  name={'vehicles'}
                  options={vehicleFilterOptions}
                  label={t('Vehicle')}
                  values={(values as any).vehicles}
                />
                <ResourceList
                  name={'crews'}
                  options={crewFilterOptions}
                  label={t('Crew')}
                  values={(values as any).crews}
                />
                <ResourceList
                  name={'others'}
                  options={otherFilterOptions}
                  label={t('Other Resources')}
                  values={(values as any).others}
                />
                {submitError && (
                  <p className={baseStyles['u-error-msg']}>{submitError}</p>
                )}
              </div>
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};
