/* eslint-disable no-irregular-whitespace */
import * as React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import './terms.css';

export const Terms = () => {
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1" align="center">
            特定商取引法に基づく表記
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Container>
            <table>
              <tbody>
                <tr>
                  <th className="term-row">会社名</th>
                  <td className="term-row">
                    NutmegLabs Japan株式会社
                    <br />
                    NutmegLabs Japan Corporation
                    <br />
                  </td>
                </tr>

                <tr>
                  <th className="term-row">サービス名</th>
                  <td className="term-row">Nutmeg</td>
                </tr>

                <tr>
                  <th className="term-row">運営責任者</th>
                  <td className="term-row">代表取締役社長　中口　貴志</td>
                </tr>

                <tr>
                  <th className="term-row">本社所在地</th>
                  <td className="term-row">
                    〒103-0023 東京都中央区日本橋本町3-3-3 Clipニホンバシビル1階
                  </td>
                </tr>

                <tr>
                  <th className="term-row">電話番号</th>
                  <td className="term-row">
                    050-5328-8026（営業時間：9：00～18：00）
                    <br />
                    基本的に電話対応はしておりません。当社サービスに関するお問い合わせは下記E-mail宛でご連絡をお願いします。
                  </td>
                </tr>

                <tr>
                  <th className="term-row">webサイト</th>
                  <td className="term-row">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ntmg.com"
                    >
                      https://ntmg.com
                    </a>
                  </td>
                </tr>

                <tr>
                  <th className="term-row">Eメール</th>
                  <td className="term-row">
                    <a
                      href="mailto:info@nutmeglabs.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      info@nutmeglabs.com
                    </a>
                  </td>
                </tr>

                <tr>
                  <th className="term-row">事業内容</th>
                  <td className="term-row">予約管理システムNutmegの運営</td>
                </tr>

                <tr>
                  <th className="term-row">販売価格</th>
                  <td className="term-row">
                    当社webサイト上の各種ツアーページでご確認ください。
                    <br />
                    ※ツアーはすべて現地通貨建てのため、日々の実勢為替レートにより実際の円価での支払（決済）額が確定します。
                    <br />
                    ※為替レートは、「申込時」における当社設定為替レートを適用します。詳細は当社webサイト上、または予約確定時に送信する予約受付確認メールでもご確認いただけます。
                    <br />
                  </td>
                </tr>

                <tr>
                  <th className="term-row">申込み方法</th>
                  <td className="term-row">
                    弊社webサイトからお申込みください（電話等では受け付けておりません）。
                  </td>
                </tr>

                <tr>
                  <th className="term-row">支払方法</th>
                  <td className="term-row">
                    ＜支払手段＞
                    <br />
                    クレジットカード（24時間支払可能）。
                    <br />
                    {'<支払期限>'}
                    <br />
                    クレジットカード：　予約確定時
                    <br />
                    *
                    予約確定時：　即時予約確定では「申込時」、リクエスト予約では「予約受付確認メール到達時」となります。
                    <br />
                    {'<支払場所>'}
                    <br />
                    当社webサイト上　(オンライン）
                    <br />
                  </td>
                </tr>
                <tr>
                  <th className="term-row">返金方法</th>
                  <td className="term-row">
                    決済頂いたクレジットカードへ自動返金いたします。
                    <br />
                  </td>
                </tr>

                <tr>
                  <th className="term-row">商品（サービス）以外の必要代金</th>
                  <td className="term-row">
                    ご利用にあたっての通信費　（PCからのインターネット接続費用、携帯電話でのインターネット接続費用など）
                  </td>
                </tr>

                <tr>
                  <th className="term-row">商品（サービス）の引き渡し時期</th>
                  <td className="term-row">
                    現地でのツアー参加日。
                    <br />
                  </td>
                </tr>

                <tr>
                  <th className="term-row">webサイト 不具合について</th>
                  <td className="term-row">
                    コンテンツやサービスの不具合が発見された場合は対処方法をメール又はサイトにてご案内いたします。
                  </td>
                </tr>

                <tr>
                  <th className="term-row">返品の可否</th>
                  <td className="term-row">
                    サービスの性質上、返品はできませんのでご了承下さい。
                  </td>
                </tr>
              </tbody>
            </table>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
