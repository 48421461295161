import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formattedPhone } from 'client/libraries/util/formattedPhone';
import { ReduxState } from 'client/reducers';
import { activeUserSelector } from 'client/reducers/user';
import { fetchInquiry } from 'client/actions/inquiries';
import { InquiryItem } from 'client/components/InquiryItem/InquiryItem';
import { TranslateFuncType } from 'client/components/Translate';
import baseStyles from 'client/base.module.css';
import { Inquiry, InquiryStatus } from 'shared/models/swagger';

import styles from './InquiryDetails.module.css';

const getSmsInquiryStatus = (
  inquiry: Inquiry,
  t: TranslateFuncType
): string => {
  if (inquiry.status === 'ARCHIVED') {
    return t('Archived');
  }

  return (
    inquiry.messages?.[inquiry.messages?.length - 1]?.sms_info?.sms_status ??
    'unknown'
  );
};

interface Props {
  printMode?: boolean;
}

export const InquiryDetails = ({ printMode }: Props) => {
  const { t } = useTranslation();

  const activeUser = useSelector(activeUserSelector);

  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    dispatch(fetchInquiry(id ?? ''));
  }, [id, activeUser]);

  const inquiry = useSelector((state: ReduxState) => state.inquiries.details);

  const getStatusText = (status?: InquiryStatus): string => {
    switch (status) {
      case 'RECEIVED':
        return t('Received');
      case 'RESPONDED':
        return t('Responded');
      case 'ARCHIVED':
        return t('Archived');
    }

    return t('Completed');
  };

  if (!inquiry) {
    return <div>{t('Inquiry not found')}</div>;
  }

  const header =
    inquiry.initial_communication_type === 'SMS_BY_SUPPLIER'
      ? `SMS ${formattedPhone(
          inquiry.customer_phone || ''
        )} [${getSmsInquiryStatus(inquiry, t)}]`
      : `${inquiry.subject} [${getStatusText(inquiry.status)}]`;

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__header']}>
        <div className={baseStyles['base-main__body__box__header__ttl']}>
          {header}
        </div>
      </div>
      <div className={baseStyles['base-main__body__box__body']}>
        <div className={styles['title']}></div>
        <InquiryItem
          item={inquiry}
          showArchiveReactivateButtons
          printMode={printMode}
        />
      </div>
    </div>
  );
};
