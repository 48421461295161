import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_E_TICKET_LOCATION_SETS_SUCCESS,
  FETCH_E_TICKET_LOCATION_SETS_FAILURE,
  FETCH_E_TICKET_LOCATION_SETS_REQUEST,
  DELETE_E_TICKET_LOCATION_SET_FAILURE,
  DELETE_E_TICKET_LOCATION_SET_REQUEST,
  DELETE_E_TICKET_LOCATION_SET_SUCCESS,
  CREATE_E_TICKET_LOCATION_SET_REQUEST,
  UPDATE_E_TICKET_LOCATION_SET_FAILURE,
  UPDATE_E_TICKET_LOCATION_SET_SUCCESS,
  UPDATE_E_TICKET_LOCATION_SET_REQUEST,
  CREATE_E_TICKET_LOCATION_SET_FAILURE,
  CREATE_E_TICKET_LOCATION_SET_SUCCESS,
} from 'client/constants/ActionTypes';
import { ReduxState } from 'client/reducers';
import type { ETicketLocationSetParams } from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchETicketLocationSetsRequest = () => ({
  type: FETCH_E_TICKET_LOCATION_SETS_REQUEST,
});

const fetchETicketLocationSetsSuccess = (response: any) => ({
  type: FETCH_E_TICKET_LOCATION_SETS_SUCCESS,
  response,
});

const fetchETicketLocationSetsFailure = (error: string) => ({
  type: FETCH_E_TICKET_LOCATION_SETS_FAILURE,
  error,
});

let fetchETicketLocationSetsCancel: () => void | null;
export const fetchETicketLocationSets =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchETicketLocationSetsCancel && fetchETicketLocationSetsCancel();
    dispatch(fetchETicketLocationSetsRequest());
    axios
      .get('/api/eticketlocationsets', {
        headers: getHTTPRequestHeaders(getState()),
        cancelToken: new axios.CancelToken(function executor(c) {
          fetchETicketLocationSetsCancel = c;
        }),
      })
      .then((result) => {
        dispatch(fetchETicketLocationSetsSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchETicketLocationSetsFailure('canceled'));
        else dispatch(fetchETicketLocationSetsFailure(error));
      });
  };

const deleteETicketLocationSetRequest = (id: string) => ({
  type: DELETE_E_TICKET_LOCATION_SET_REQUEST,
  id,
});
const deleteETicketLocationSetSuccess = (response: any, id: string) => ({
  type: DELETE_E_TICKET_LOCATION_SET_SUCCESS,
  response,
  id,
});
const deleteETicketLocationSetFailure = (error: string) => ({
  type: DELETE_E_TICKET_LOCATION_SET_FAILURE,
  error,
});
export const deleteETicketLocationSet =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(deleteETicketLocationSetRequest(id));
    axios
      .delete(`/api/eticketlocationsets/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => dispatch(deleteETicketLocationSetSuccess(result, id)))
      .catch((error) => dispatch(deleteETicketLocationSetFailure(error)));
  };

const createETicketLocationSetRequest = (
  newETicketLocationSet: ETicketLocationSetParams
) => ({
  type: CREATE_E_TICKET_LOCATION_SET_REQUEST,
  newETicketLocationSet,
});
const createETicketLocationSetSuccess = (response: any) => ({
  type: CREATE_E_TICKET_LOCATION_SET_SUCCESS,
  response,
});
const createETicketLocationSetFailure = (error: string) => ({
  type: CREATE_E_TICKET_LOCATION_SET_FAILURE,
  error,
});

export const createETicketLocationSet =
  (newETicketLocationSet: ETicketLocationSetParams) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(createETicketLocationSetRequest(newETicketLocationSet));
    return new Promise((resolve, reject) => {
      axios
        .post('/api/eticketlocationsets', newETicketLocationSet, {
          headers: getHTTPRequestHeaders(getState()),
        })
        .then((response) => {
          dispatch(createETicketLocationSetSuccess(response.data));
          resolve(response.data);
        })
        .catch((err) => {
          dispatch(createETicketLocationSetFailure(err.message));
          reject(err);
        });
    });
  };

const updateETicketLocationSetRequest = (
  id: string,
  patch: ETicketLocationSetParams
) => ({
  type: UPDATE_E_TICKET_LOCATION_SET_REQUEST,
  id,
  patch,
});
const updateETicketLocationSetSuccess = (response: any) => ({
  type: UPDATE_E_TICKET_LOCATION_SET_SUCCESS,
  response,
});
const updateETicketLocationSetFailure = (error: string) => ({
  type: UPDATE_E_TICKET_LOCATION_SET_FAILURE,
  error,
});

export const updateETicketLocationSet =
  (id: string, patch: ETicketLocationSetParams) =>
  (dispatch: Dispatch, getState: () => ReduxState): Promise<any> => {
    dispatch(updateETicketLocationSetRequest(id, patch));
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/eticketlocationsets/${id}`, patch, {
          headers: getHTTPRequestHeaders(getState()),
        })
        .then((response) => {
          dispatch(updateETicketLocationSetSuccess(response.data));
          resolve(response.data);
        })
        .catch((err) => {
          dispatch(updateETicketLocationSetFailure(err.message));
          reject(err);
        });
    });
  };
