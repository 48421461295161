import { fetchPartnershipAgentSystemFeeInvoiceCSV } from 'client/actions/partnershipSystemFeeInvoices';
import { TranslateFuncType } from 'client/components/Translate';
import { SystemFeeInvoiceColumn } from 'shared/models/swagger';

export interface ColumnType {
  Header: string;
  id: SystemFeeInvoiceColumn;
}

export const getPartnershipAgentSystemFeeInvoiceCSVColumns = (
  t: TranslateFuncType
): ColumnType[] => {
  return [
    {
      Header: t('Application Number'),
      id: 'AGENT_REFERENCE',
    },
    {
      Header: t('Supplier'),
      id: 'SUPPLIER_NAME',
    },
    {
      Header: t('Booked Date'),
      id: 'BOOKED_AT',
    },
    {
      Header: t('Participation'),
      id: 'PARTICIPATES_AT',
    },
    {
      Header: t('Customer'),
      id: 'GUEST_DISPLAY_NAME',
    },
    {
      Header: t('Status'),
      id: 'STATUS',
    },
    {
      Header: t('Product'),
      id: 'PRODUCT_NAME',
    },
    {
      Header: t('Units'),
      id: 'GUEST_DESCRIPTION',
    },
    {
      Header: t('Total Pax'),
      id: 'GUEST_COUNT',
    },
    {
      Header: t('Payment Type'),
      id: 'PAYMENT_TYPE',
    },
    {
      Header: t('Credit Card Brand'),
      id: 'CARD_BRAND',
    },
    {
      Header: t('Gross'),
      id: 'GROSS',
    },
    {
      Header: t('Cancel Fee'),
      id: 'CANCELLATION_FEE',
    },
    {
      Header: t('Booking Fee'),
      id: 'AGENT_BOOKING_FEE',
    },
    {
      Header: t('Credit Card Fee'),
      id: 'AGENT_CREDIT_CARD_FEE',
    },
    {
      Header: t('Commission'),
      id: 'COMMISSION',
    },
  ];
};

export type DownloadSystemFeeInvoiceCSVRequest = {
  supplierId: string;
  month: string;
  timezone: string;
  columns: SystemFeeInvoiceColumn[];
};

export const downloadPartnershipAgentSystemFeeInvoiceCSV = (
  req: DownloadSystemFeeInvoiceCSVRequest
) =>
  fetchPartnershipAgentSystemFeeInvoiceCSV(
    {
      supplier_id: req.supplierId,
      month: req.month,
      timezone: req.timezone,
      columns: req.columns.join(','),
    },
    true
  );
