import * as React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TopPage } from '@nutmeglabs/falcon-ui';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  fetchCustomTopPage,
  putCustomTopPage,
} from 'client/actions/customTopPage';
import { Select } from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import { getBookingWidgetPmpSupportedLanguageOptions } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { SourceLanguage } from 'shared/models/swagger';

import { PageEditor } from './PageEditor';
import { usePageDefaults } from './useTemplateOptions';

export const BookingWidgetCustomTopPageEditor = () => {
  const org = useSelector(activeUserOrganizationSelector);
  const { t } = useTranslation();

  const languageOptions = getBookingWidgetPmpSupportedLanguageOptions(org);

  const [selectedLanguage, setSelectedLanguage] = React.useState(
    languageOptions?.length ? languageOptions[0].value : 'JA_JP'
  );

  const initialValues = usePageDefaults(selectedLanguage);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCustomTopPage(selectedLanguage));
  }, [org?.id, selectedLanguage]);

  const loading = useSelector(
    (state: ReduxState) => state.customTopPage.loading
  );

  const existingTopPageData = useSelector(
    (state: ReduxState) => state.customTopPage.data
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {languageOptions?.length && (
        <div style={{ position: 'relative', zIndex: 10000 }}>
          <Select
            style={{ zIndex: 1000 }}
            maxWidth={200}
            label={t('Language')}
            value={selectedLanguage}
            options={languageOptions}
            onChange={(e, { value }) =>
              setSelectedLanguage(value as SourceLanguage)
            }
          />
        </div>
      )}
      <Form<TopPage>
        key={selectedLanguage}
        onSubmit={async (values: TopPage) => {
          await Promise.all([
            dispatch(
              updateActiveUserOrganization({
                booking_widget_custom_top_page_settings: [
                  ...(org?.booking_widget_custom_top_page_settings?.filter(
                    (s) => s.language !== selectedLanguage
                  ) ?? []),
                  {
                    language: selectedLanguage,
                    is_custom_top_page_enabled: values.shouldUseCustomTopPage,
                  },
                ],
              })
            ),
            dispatch(
              putCustomTopPage(JSON.stringify(values), selectedLanguage)
            ),
          ]);
        }}
        debug={console.log}
        initialValues={{
          ...(existingTopPageData
            ? JSON.parse(existingTopPageData)
            : initialValues),
          shouldUseCustomTopPage:
            org?.booking_widget_custom_top_page_settings?.find(
              (s) => s.language === selectedLanguage
            )?.is_custom_top_page_enabled ?? false,
        }}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <PageEditor language={selectedLanguage} />
            </form>
          );
        }}
      </Form>
    </>
  );
};
