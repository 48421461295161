import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Modal } from 'client/components/v3/Form/Modal';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { Button } from 'client/components/v3/Common/Button';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onSave: (memo: string) => void;
}

export const CalendarEditModal = ({
  open,
  onClose,
  title,
  message,
  onSave,
}: Props) => {
  const { t } = useTranslation();
  const [memo, setMemo] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title={title}
      subtitle={message}
      open={open}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={onClose}
          />
          <Button
            text={t('Save')}
            onClick={async () => {
              await setLoading(true);
              await onSave(memo);
              await setLoading(false);
              await onClose();
            }}
            loading={loading}
          />
        </>
      }
    >
      <div className={commonStyles['p-availabilityModal']}>
        <div className={commonStyles['p-availabilityModal__item']}>
          <p className={commonStyles['p-availabilityModal__item__ttl']}>
            {t('Memo')}
          </p>
          <div className={commonStyles['p-availabilityModal__item__body']}>
            <TextArea
              value={memo}
              height={120}
              onChange={(_, { value }) => setMemo(value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
