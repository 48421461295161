import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Button, Input } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { getArrayMutators } from 'client/libraries/util/form';
import baseStyles from 'client/base.module.css';

export type FormValues = {
  name: string;
};

type Props = {
  initialName?: string;
  open: boolean;
  onClose?: () => void;
  onActionStart: (name: string) => void;
  title: string;
  buttonText: string;
};
export const InputActionOwnerNameModal = ({
  initialName,
  open,
  onClose,
  onActionStart,
  title,
  buttonText,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          try {
            onActionStart(values.name);
            onClose?.();
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('error'),
            };
          }
        }}
        initialValues={{ name: initialName }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field
                    name={'name'}
                    validate={(name) => (name ? undefined : t('Required'))}
                  >
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Your Name')}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button.Cancel
                  onClick={() => {
                    onClose?.();
                  }}
                >
                  {t('Cancel')}
                </Button.Cancel>
                <Button size="middle" style="blue" type="submit">
                  {buttonText}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
