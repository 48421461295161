import { createSelector } from 'reselect';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { activeUserAllOrganizationsSelector } from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import { BookingSourceType } from 'client/libraries/util/getBookingSourceOptions';
import type { TranslateFuncType } from 'client/components/Translate';

export const agentOptionsSelector = createSelector(
  (state: ReduxState) => state.organizations.contracted,
  activeUserAllOrganizationsSelector,
  (contractedOrganizations, allOrganizations) => {
    return _.orderBy(
      _.uniqBy(
        [
          ...contractedOrganizations,
          ...allOrganizations
            .filter((org) => org.type === 'AGENT')
            .map((org) => ({
              id: org.id,
              name: org.name,
            })),
        ],
        (agent) => agent.id
      ),
      [(agent) => agent.name?.toLowerCase()]
    ).map((agent) => ({
      key: agent.id,
      text: agent.name,
      value: agent.id,
    }));
  }
);
export const supplierOptionsSelector = createSelector(
  (state: ReduxState) => state.products.summaries,
  (
    products
  ): {
    key: string;
    text: string;
    value: string;
  }[] => {
    const supplierNamesById: Record<string, string> = {};

    for (const product of products) {
      supplierNamesById[product.supplier_id || ''] =
        product.supplier_name || '';
    }

    return Object.keys(supplierNamesById).map((supplierId) => ({
      key: supplierId,
      text: supplierNamesById[supplierId],
      value: supplierId,
    }));
  }
);
export const getBookingSourceOptions = (
  t: TranslateFuncType
): {
  key: BookingSourceType;
  text: string;
  value: BookingSourceType;
}[] => {
  return [
    {
      key: 'AGENT',
      text: t('agent'),
      value: 'AGENT',
    },
    {
      key: 'DIRECT_WALK_IN',
      text: t('walk-in (direct)'),
      value: 'DIRECT_WALK_IN',
    },
    {
      key: 'DIRECT_TELEPHONE',
      text: t('phone (direct)'),
      value: 'DIRECT_TELEPHONE',
    },
    {
      key: 'DIRECT_EMAIL',
      text: t('email (direct)'),
      value: 'DIRECT_EMAIL',
    },
    {
      key: 'DIRECT_WEB',
      text: t('Online (direct)'),
      value: 'DIRECT_WEB',
    },
    {
      key: 'OTHER',
      text: t('other'),
      value: 'OTHER',
    },
  ];
};

export const useOrderByOptions = () => {
  const { t } = useTranslation();

  return [
    {
      value: 'last_updated_desc',
      text: t('Updated (most recent first)'),
    },
    {
      value: 'last_updated_asc',
      text: t('Updated (least recent first)'),
    },
    {
      value: 'booked_desc',
      text: t('Booked (most recent first)'),
    },
    {
      value: 'booked_asc',
      text: t('Booked (least recent first)'),
    },
    {
      value: 'participation_asc',
      text: t('Participation (earliest first)'),
    },
    {
      value: 'participation_desc',
      text: t('Participation (latest first)'),
    },
    {
      value: 'family_name',
      text: t('Family Name (A-Z)'),
    },
  ];
};
