import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_REVIEW_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';
import type { ReviewSummary } from 'client/libraries/util/reviewSummary';
import type { ReduxState } from 'client/reducers';

// Selectors
export const reviewVisibleColumnsSelector = (state: ReduxState) =>
  state.reviewTableControls.visibleColumns;
// Reducers
const defaultVisibleColumns: (keyof ReviewSummary)[] = [
  'rating',
  'status',
  'productName',
  'writtenDate',
  'participationDate',
  'title',
  'body',
  'attribution',
  'language',
  'guestNickname',
  'supplierComments',
];

const visibleColumns = (
  state = defaultVisibleColumns,
  action: any
): (keyof ReviewSummary)[] => {
  switch (action.type) {
    case SET_REVIEW_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};

type State = {
  visibleColumns: (keyof ReviewSummary)[];
};

const reducer = combineReducers<State>({
  visibleColumns,
});
export const reviewTableControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
