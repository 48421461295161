import * as React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { ToggleButton } from 'client/components/Form';
import { Add as AddIcon } from 'client/components/Icons/Add';
import baseStyles from 'client/base.module.css';
import styles from 'client/components/NewProductEditor/NewProductEditor.module.css';
import calendarIcon from 'client/images/ic_calendar.svg';
import deleteIcon from 'client/images/ic_delete.svg';
import addIcon from 'client/images/ic_add.svg';

import { DateInput } from './DateInput';

type Props = {
  name?: string;
};
export const AvailabilityDatesInput = ({ name }: Props) => {
  const { t } = useTranslation();
  const namePrefix = name ? `${name}.` : '';
  return (
    <div>
      <div className={styles['page-productsRegist__date']}>
        <p className={styles['page-productsRegist__date__ttl']}>
          {t('Date Range')}
        </p>
        <FieldArray name={`${namePrefix}dateRanges`}>
          {({ fields }) => (
            <>
              {fields.length === 0 && (
                <AddIcon
                  onClick={() =>
                    (fields as any).insertAt(0, {
                      startDate: moment().format('YYYY-MM-DD'),
                      endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
                    })
                  }
                />
              )}
              {fields.map((name, idx) => (
                <Field key={name} name={name}>
                  {({ input }) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      mb={2}
                    >
                      <Box flexShrink={0} width="280px">
                        {input.value.startDate === input.value.endDate ? (
                          <label className={baseStyles['base-form-calendar']}>
                            <img src={calendarIcon} />
                            <DateInput
                              name={`${name}.startDate`}
                              value={input.value.startDate}
                              onChange={(e) => {
                                input.onChange({
                                  startDate: e.target.value,
                                  endDate: e.target.value,
                                });
                              }}
                            />
                          </label>
                        ) : (
                          <div className={baseStyles['base-form-range']}>
                            <label className={baseStyles['base-form-calendar']}>
                              <img src={calendarIcon} />
                              <DateInput
                                name={input.name}
                                value={input.value.startDate}
                                onChange={(e) =>
                                  input.onChange({
                                    ...input.value,
                                    startDate: e.target.value,
                                  })
                                }
                              />
                            </label>
                            <p>-</p>
                            <label className={baseStyles['base-form-calendar']}>
                              <img src={calendarIcon} />

                              <DateInput
                                name={input.name}
                                value={input.value.endDate}
                                onChange={(e) =>
                                  input.onChange({
                                    ...input.value,
                                    endDate: e.target.value,
                                  })
                                }
                              />
                            </label>
                          </div>
                        )}
                      </Box>
                      <Box ml={2} flexShrink={0}>
                        <ToggleButton
                          label={t('1 day only')}
                          checked={
                            input.value.startDate &&
                            input.value.startDate === input.value.endDate
                          }
                          onChange={() => {
                            if (!input.value.startDate) return;

                            if (input.value.startDate === input.value.endDate) {
                              input.onChange({
                                ...input.value,
                                endDate: moment(input.value.startDate)
                                  .add('years', 1)
                                  .format('YYYY-MM-DD'),
                              });
                            } else {
                              input.onChange({
                                ...input.value,
                                endDate: input.value.startDate,
                              });
                            }
                          }}
                        />
                      </Box>
                      <Box display="flex" alignItems="center" ml={2}>
                        <a>
                          <img
                            src={addIcon}
                            onClick={() => {
                              (fields as any).insertAt(idx + 1, {
                                startDate: moment().format('YYYY-MM-DD'),
                                endDate: moment()
                                  .add(1, 'years')
                                  .format('YYYY-MM-DD'),
                              });
                            }}
                          />
                        </a>
                        <Box ml={1}>
                          <a>
                            <img
                              src={deleteIcon}
                              onClick={() => {
                                fields.remove(idx);
                              }}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Field>
              ))}
            </>
          )}
        </FieldArray>
        {name && (
          <p>
            {t(
              '* Both Participation Rules and "Prices" in Basic Information tab are required to become available on the calendars. Please make sure price and participation rules date ranges are covered.'
            )}
          </p>
        )}
      </div>

      <div className={styles['page-productsRegist__date']}>
        <p className={styles['page-productsRegist__date__ttl']}>
          {t('Days of Week')}
        </p>
        <Field name={`${namePrefix}weekdays`}>
          {({ input }) => (
            <WeekdaysInput value={input.value} onChange={input.onChange} />
          )}
        </Field>
      </div>

      <div className={styles['page-productsRegist__date']}>
        <div className={styles['page-productsRegist__date__ttl']}>
          {t('Closed Dates')}
          <Tooltip
            text={t(
              'Set the days that will not be offered to hide them on the calendar. Specify holidays and company holidays on a daily basis.'
            )}
            mobileStyle={{
              left: '-70px',
            }}
          />
        </div>
        <FieldArray name={`${namePrefix}closedDates`}>
          {({ fields }) => (
            <>
              <div className={styles['page-productsRegist__date__exclusion']}>
                <div className={baseStyles['base-flex']}>
                  <a>
                    <img
                      src={addIcon}
                      onClick={() => {
                        (fields as any).insertAt(0, {
                          date: '',
                          repeatsAnnually: false,
                        });
                      }}
                    />
                  </a>
                </div>
              </div>
              {fields.map((name, idx) => (
                <Box
                  key={idx}
                  mt={2}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Box width="150px">
                    <label className={baseStyles['base-form-calendar']}>
                      <img src={calendarIcon} />
                      <Field name={`${name}.date`}>
                        {({ input }) => <input {...input} type="date" />}
                      </Field>
                    </label>
                  </Box>
                  <Box ml={2} flexShrink={0}>
                    <Field name={`${name}.repeatsAnnually`} type="checkbox">
                      {({ input }) => (
                        <ToggleButton
                          label={t('Repeat Annually')}
                          checked={input.checked}
                          onChange={() => {
                            input.onChange(!input.checked);
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box display="flex" alignItems="center" ml={2}>
                    <a>
                      <img
                        src={addIcon}
                        onClick={() => {
                          (fields as any).insertAt(idx + 1, {
                            date: '',
                            repeatsAnnually: false,
                          });
                        }}
                      />
                    </a>
                    <Box ml={1}>
                      <a>
                        <img
                          src={deleteIcon}
                          onClick={() => {
                            fields.remove(idx);
                          }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              ))}{' '}
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};
