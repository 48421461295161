import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Button } from 'client/components/v3/Common/Button';
import { sendMediaDownloadOfferEmail } from 'client/actions/reservations';
import { isSubscriptionCancelled } from 'client/libraries/util/subscriptions';
import { useMediaDownloadOrders } from 'client/hooks/useMediaDownloadOrders';
import { useMediaDownloadSalesPages } from 'client/hooks/useMediaDownloadSalesPages';
import { SendMediaDownloadOfferEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationMediaDownloads/SendMediaDownloadOfferEmailModal';
import { ReservationMediaDownloadOrderList } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationMediaDownloads/ReservationMediaDownloadOrderList';
import baseStyles from 'client/v3-base.module.css';
import reservationStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationMediaDownloads/ReservationMediaDownloads.module.css';
import type { Reservation } from 'shared/models/swagger';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

type Props = {
  reservation?: Reservation;
};
export const ReservationMediaDownloads = ({ reservation }: Props) => {
  const { t } = useTranslation();
  const [emailSendingStatus, setEmailSendingStatus] = React.useState<
    'REQUESTED' | 'SUCCEEDED' | 'FAILED' | null
  >(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();
  const { orders } = useMediaDownloadOrders({
    reservationId: reservation?.id ?? '',
  });
  const { salesPages } = useMediaDownloadSalesPages({
    reservationId: reservation?.id ?? '',
  });
  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  // Do not show section if there are no records found
  if (orders.length === 0 && salesPages.length === 0) {
    return <></>;
  }

  return (
    <section
      id="mediaDownload"
      className={clsx(reservationStyles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={reservationStyles['p-reservationsDetail']}>
        <div className={reservationStyles['p-reservationsDetail__header']}>
          <p className={reservationStyles['p-reservationsDetail__ttl']}>
            {t('Image/Video Downloads')}
          </p>
        </div>
        <div className={reservationStyles['p-reservationsDetail__body']}>
          {orders.length === 0 && salesPages.length === 0 && (
            <div style={{ marginBottom: '2px' }}>
              {t(
                "No images have been published for this reservation's product and start time"
              )}
            </div>
          )}
          {orders.length === 0 &&
            salesPages.length > 0 &&
            !isSubscriptionCancelled(
              activeOrganization,
              'feature-image-download'
            ) && (
              <>
                <div style={{ marginBottom: '4px' }}>
                  {t(
                    'Images/videos downloads are available for this reservation but the customer has not made any purchases'
                  )}
                </div>
                <Button
                  color="primary"
                  size="md"
                  disabled={emailSendingStatus === 'SUCCEEDED'}
                  loading={emailSendingStatus === 'REQUESTED'}
                  text={t('Send Photo Download Offer Email')}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
                {showModal && (
                  <SendMediaDownloadOfferEmailModal
                    initialToAddress={email}
                    open={showModal}
                    onClose={() => {
                      setShowModal(false);
                    }}
                    onSubmit={async (email?: string) => {
                      try {
                        await setEmailSendingStatus('REQUESTED');
                        await dispatch(
                          sendMediaDownloadOfferEmail(
                            reservation?.id ?? '',
                            email
                          )
                        );
                        await setEmailSendingStatus('SUCCEEDED');
                      } catch (e) {
                        setEmailSendingStatus('FAILED');
                      }
                    }}
                  />
                )}
                {emailSendingStatus == 'SUCCEEDED' && (
                  <Snackbar
                    text={t('Send Successful')}
                    color="success"
                    shouldShow={emailSendingStatus == 'SUCCEEDED'}
                  />
                )}
                {emailSendingStatus == 'FAILED' && (
                  <Snackbar
                    text={t('Send Failed')}
                    color="error"
                    shouldShow={emailSendingStatus == 'FAILED'}
                  />
                )}
              </>
            )}
          {orders.length === 0 &&
            salesPages.length > 0 &&
            isSubscriptionCancelled(
              activeOrganization,
              'feature-image-download'
            ) && (
              <>
                {t(
                  'Your subscription to the image download feature has been deactivated so sending image download offer emails is disabled. Resubscribe here: '
                )}
                <a
                  className={styles['link']}
                  href={`${window.origin}/systemfee/settings`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Settings')}
                </a>
              </>
            )}
          <div className={reservationStyles['p-photos']}>
            {orders.length > 0 && (
              <ReservationMediaDownloadOrderList orders={orders} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
