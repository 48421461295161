import type { NewReservation } from 'shared/models/swagger';

export const needToOpenNoEmailWithPaymentLinkMessageModal = (
  reservation: NewReservation,
  isChangeReservation: boolean
): boolean => {
  if (
    reservation?.payment_type === 'PAID_IN_FULL' &&
    reservation?.payment_method === 'CREDIT_CARD' &&
    !reservation?.payment_deferred &&
    !isChangeReservation
  ) {
    return true;
  }

  return false;
};
