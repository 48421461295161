import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { config } from 'client/config';

import { getCorrectedPopupWindowPosition } from './utils';
import styles from './SeatAssignment.module.css';

interface Props {
  replacedEquipmentBlockInstanceKeyMap: {
    from: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
    to: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
  }[];
  position: { left: number; top: number } | null | undefined;
  onBulkOperationClick: () => void;
  onReset: () => void;
  open: boolean;
  onSeatMap?: boolean;
  onClose: () => void;
  onPrintTicketClick?: () => void;
  onClearSeatClick?: () => void;
  onClearRedemptionRecordClick?: () => void;
}

export const AssignedSeatSelectPopupWindow = ({
  replacedEquipmentBlockInstanceKeyMap,
  position,
  onBulkOperationClick,
  open,
  onSeatMap,
  onClose,
  onPrintTicketClick,
  onClearSeatClick,
  onClearRedemptionRecordClick,
}: Props) => {
  const { t } = useTranslation();
  const hoverRef = React.useRef<HTMLDivElement>(null);

  const [popupWindowOpen, setPopupWindowOpen] = React.useState<{
    top: string;
    left: string;
  } | null>(null);

  React.useEffect(() => {
    if (hoverRef?.current) {
      const correctedPosition = getCorrectedPopupWindowPosition(
        hoverRef?.current?.clientHeight ?? 150,
        position
      );

      setPopupWindowOpen({
        left: `${correctedPosition.left}px`,
        top: `${correctedPosition.top}px`,
      });
    }
  }, [hoverRef?.current, position]);

  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !hoverRef?.current?.contains(target)) {
        onClose();
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, []);

  if (!position) {
    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <div
      className={clsx(styles['popup'], styles['edit'])}
      ref={hoverRef}
      style={{
        ...(onSeatMap
          ? {
              right: '43px',
              position: 'absolute',
            }
          : {
              top: popupWindowOpen?.top,
              left: popupWindowOpen?.left,
            }),
      }}
    >
      <ul className={styles['popup__info']}>
        <li>
          <p>{t('Selected')}</p>
          <p></p>
        </li>
        <li>
          <p>{t('Total')}</p>
          <p>{replacedEquipmentBlockInstanceKeyMap.length}</p>
        </li>
      </ul>
      <ul className={styles['popup__list']}>
        <li
          onClick={() => {
            onBulkOperationClick();
          }}
        >
          {t('Move Seats')}
        </li>
      </ul>
      <p className={styles['popup__listTite']}>{t('Edit All Seats')}</p>

      <ul className={clsx(styles['popup__list'])}>
        <li
          onClick={() => {
            onPrintTicketClick?.();
          }}
        >
          {t('Print Ticket')}
        </li>
        <li
          onClick={() => {
            onClearSeatClick?.();
          }}
        >
          {t('Clear Seats')}
        </li>
        <li
          onClick={() => {
            onClearRedemptionRecordClick?.();
          }}
        >
          {config.enableUndoEqauipmentTicketPrint
            ? t('Undo Redemption & Ticketing')
            : t('Delete Redemption Records')}
        </li>
      </ul>
    </div>
  );
};
