import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { fetchProductInstancesForProducts } from 'client/actions/productInstances';
import { ModalLoader } from 'client/components/ModalLoader';
import { Box } from 'client/components/Box/Box';
import { Checkbox, FieldWrapper } from 'client/components/Form';
import { productInstancesByProductIdSelector } from 'client/reducers/productInstances';
import { getProductInstanceDate } from 'client/libraries/util/productInstanceShape';
import baseStyles from 'client/base.module.css';
import { ProductSummary } from 'shared/models/swagger';

import { FormValues } from './FormValues';

export const EditSelectedProductInstancesForm = () => {
  const { t } = useTranslation();
  const { productIds, date } = useFormState<FormValues>().values;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (productIds.length > 0) {
      const startDate = moment(date);
      const endDate = moment(date).add(1, 'days');
      dispatch(
        fetchProductInstancesForProducts(productIds, startDate, endDate)
      );
    }
  }, [productIds, date]);

  const loading = useSelector(
    (state: ReduxState) => state.productInstances.loading
  );

  const productInstancesByProductId = useSelector(
    productInstancesByProductIdSelector
  );

  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const products = productIds.map(
    (id) => allProducts.find((p) => p.id === id) as ProductSummary
  );

  return loading ? (
    <ModalLoader />
  ) : (
    <FieldWrapper label={t('Select Product Start Times')}>
      <div>
        <Field<FormValues['productInstanceIds']> name="productInstanceIds">
          {({ input }) =>
            products.map((product) => {
              const instances = (
                productInstancesByProductId[product.id] ?? []
              ).filter((instance) => getProductInstanceDate(instance) === date);

              return (
                <Box mb={2} key={product.id}>
                  {product.product_name}
                  {instances.length === 0 ? (
                    <div className={baseStyles['error']}>
                      {t('No start times found for date')}
                    </div>
                  ) : (
                    instances.map((instance) => (
                      <Checkbox
                        checked={input.value?.includes(instance.id)}
                        onChange={() => {
                          input.value?.includes(instance.id)
                            ? input.onChange(
                                input.value?.filter((id) => id !== instance.id)
                              )
                            : input.onChange([...input.value, instance.id]);
                        }}
                        label={instance.start_time_local ?? ''}
                        key={instance.id}
                      />
                    ))
                  )}
                </Box>
              );
            })
          }
        </Field>
      </div>
    </FieldWrapper>
  );
};
