import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FieldWrapper, Input, Select } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { CustomUserResource } from 'client/libraries/util/customUserPermissions';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  matchesFormat,
  getValidators,
} from 'shared/libraries/validate/validator';
import { Box } from 'client/components/Box/Box';
import { Account } from 'shared/models/swagger';

import { getDefaultTopLevelPermissions, getResourceText } from './formValues';

interface Props {
  existingAccount?: Account;
}

export const EditAccountForm = ({ existingAccount }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;
  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const validateEmailAddress = React.useCallback((email?: any) => {
    if (!email) return t('Required');

    if (!matchesFormat(email, 'email')) return t('Invalid Email');

    return undefined;
  }, []);

  const shouldShowResource = (resource: CustomUserResource) => {
    switch (resource) {
      case CustomUserResource['REVIEW']:
        return hasSubscription(activeOrganization, 'feature-review');
      case CustomUserResource['WAIVER']:
        return hasSubscription(activeOrganization, 'feature-digital-waiver');
      case CustomUserResource['MEDIA_DOWNLOAD']:
        return hasSubscription(activeOrganization, 'feature-image-download');
      case CustomUserResource['INQUIRY']:
        return hasSubscription(activeOrganization, 'feature-inquiry');
      case CustomUserResource['E_TICKET']:
        return hasSubscription(activeOrganization, 'feature-e-ticket');
      case CustomUserResource['SURVEY']:
        return hasSubscription(activeOrganization, 'feature-survey');
      case CustomUserResource['CUSTOMER_LEDGER']:
        return hasSubscription(activeOrganization, 'feature-customer-ledger');
      case CustomUserResource['SHIFT_MANAGEMENT']:
        return hasSubscription(activeOrganization, 'feature-guide-account');
      case CustomUserResource['DIGITAL_GUIDANCE']:
        return hasSubscription(activeOrganization, 'feature-digital-guidance');
      case CustomUserResource['RESERVATION.ACTIM_RESERVATIONS']:
        return Boolean(activeOrganization?.actim_api_key);
      case CustomUserResource['NEWSLETTER']:
        return hasSubscription(activeOrganization, 'feature-customer-ledger');
      case CustomUserResource['BOOKING_SITE.EMBED']:
        return false;
      default:
        return true;
    }
  };

  const shouldShowCustomOption = (resource: CustomUserResource) => {
    switch (resource) {
      case CustomUserResource['HOME']:
        return false;
      default:
        return true;
    }
  };

  const { required } = getValidators(t);

  const accountTypeOptions = [
    {
      value: 'PRODUCT_EDITOR',
      text: t('Admin'),
    },
    {
      value: 'CUSTOM_USER',
      text: t('User'),
    },
  ];

  if (hasSubscription(activeOrganization, 'feature-guide-account')) {
    accountTypeOptions.push({
      value: 'GUIDE',
      text: t('Shift Management Staff'),
    });
  }

  const localeOptions = [
    { text: t('Japanese'), value: 'ja' },
    { text: t('English'), value: 'en' },
  ];

  return (
    <>
      <Field name="email" validate={validateEmailAddress}>
        {({ input, meta: { touched, error } }) =>
          existingAccount ? (
            <FieldWrapper label={t('Email')}>{input.value}</FieldWrapper>
          ) : (
            <Input label={t('Email')} {...input} error={touched && error} />
          )
        }
      </Field>
      <Field name="name" validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box mt={2}>
            <Input label={t('Name')} {...input} error={touched && error} />
          </Box>
        )}
      </Field>
      <Field name="locale" validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box mt={2}>
            <Select
              label={t('Language')}
              value={input.value}
              options={localeOptions}
              onChange={(e, { value }) => {
                input.onChange(value);
              }}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
      <Field name="accountType" validate={required}>
        {({ input, meta: { touched, error } }) => (
          <Box mt={2}>
            <Select
              label={t('User Account Type')}
              value={input.value}
              options={accountTypeOptions}
              onChange={(e, { value }) => {
                input.onChange(value);

                switch (value) {
                  case 'CUSTOM_USER':
                    form.change(
                      'topLevelPermissions',
                      getDefaultTopLevelPermissions()
                    );
                    break;
                  default:
                    form.change('topLevelPermissions', []);
                    break;
                }
              }}
              error={touched && error}
            />
          </Box>
        )}
      </Field>
      {values?.accountType === 'CUSTOM_USER' && (
        <Box mt={2}>
          <FieldWrapper label={t('Custom Permissions')}>
            <FormTableBox>
              <table>
                <tbody>
                  <FieldArray name="topLevelPermissions">
                    {({ fields }) =>
                      fields.map(
                        (name, idx) =>
                          shouldShowResource(fields.value[idx].resource) && (
                            <>
                              <tr key={name}>
                                <Field name={`${name}.resource`}>
                                  {({ input }) => (
                                    <th>{getResourceText(input.value, t)}</th>
                                  )}
                                </Field>
                                <td>
                                  <EnumRadioButtonGroup
                                    liStyle={{ width: '100px' }}
                                    name={`${name}.permission`}
                                    options={[
                                      {
                                        value: 'NONE',
                                        label: t('Hidden'),
                                      },
                                      {
                                        value: 'READ_ONLY',
                                        label: t('View Only'),
                                      },
                                      {
                                        value: 'READ_WRITE',
                                        label: t('View & Edit'),
                                      },
                                      ...(shouldShowCustomOption(
                                        fields.value[idx].resource
                                      )
                                        ? [
                                            {
                                              value: 'CUSTOM',
                                              label: t('Custom'),
                                            },
                                          ]
                                        : []),
                                    ]}
                                  />
                                </td>
                              </tr>
                              {fields.value[idx].permission === 'CUSTOM' && (
                                <FieldArray
                                  name={`subMenuPermissions.${
                                    CustomUserResource[
                                      fields.value[idx].resource
                                    ]
                                  }`}
                                >
                                  {({ fields: subMenuPermissionFields }) =>
                                    subMenuPermissionFields.map(
                                      (
                                        subMenuPermissionName,
                                        subMenuPermissionIdx
                                      ) => {
                                        return (
                                          shouldShowResource(
                                            subMenuPermissionFields.value[
                                              subMenuPermissionIdx
                                            ].resource
                                          ) && (
                                            <tr key={subMenuPermissionName}>
                                              <Field
                                                name={`${subMenuPermissionName}.resource`}
                                              >
                                                {({ input }) => (
                                                  <th>
                                                    {`${getResourceText(
                                                      fields.value[idx]
                                                        .resource,
                                                      t
                                                    )} / ${getResourceText(
                                                      input.value,
                                                      t
                                                    )}`}
                                                  </th>
                                                )}
                                              </Field>
                                              <td>
                                                <EnumRadioButtonGroup
                                                  liStyle={{ width: '100px' }}
                                                  name={`${subMenuPermissionName}.permission`}
                                                  options={[
                                                    {
                                                      value: 'NONE',
                                                      label: t('Hidden'),
                                                    },
                                                    {
                                                      value: 'READ_WRITE',
                                                      label: t('View & Edit'),
                                                    },
                                                  ]}
                                                />
                                              </td>
                                            </tr>
                                          )
                                        );
                                      }
                                    )
                                  }
                                </FieldArray>
                              )}
                            </>
                          )
                      )
                    }
                  </FieldArray>
                </tbody>
              </table>
            </FormTableBox>
          </FieldWrapper>
        </Box>
      )}
    </>
  );
};
