import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';

import { Toggle } from 'client/components/v3/Form/Toggle';
import { Loading } from 'client/components/v3/Common/Loading';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateCovid19Settings } from 'client/actions/essentialPages';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';
import type { SourceLanguage, Covid19Settings } from 'shared/models/swagger';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import styles from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages.module.css';
import baseStyles from 'client/v3-base.module.css';

export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

const defaultPrecautions = [
  'Guides & drivers wear masks',
  'Staff required to wash hands regularly',
  'Staff required to check temperature regularly',
  'Regularly sanitize high-traffic area',
  'Regularly sanitize equipments, vehicles',
  'Hand sanitizer available to travelers and staff',
  'Reduced group sizes',
  'Keep social distance throughout the activity',
];
const defaultToGuests = [
  'Guests are required to check temperature upon arrival',
  'Guests are required to bring and wear masks',
  'Hand sanitizer available to travelers',
];

export const Covid19Tab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  console.log(saveSucceeded);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');
  console.log(setTranslationTargetLanguage);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      ...activeUserOrganization?.essential_pages?.covid19_settings,
    }),
    [apiTranslations, translationTargetLanguage, activeUserOrganization]
  );

  if (!activeUserOrganization) {
    return <Loading size="lg" />;
  }

  //const changeShowTranslationMode = (show: boolean) => {
  //  setShowTranslationMode(show);
  //};

  //const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
  //  setTranslationTargetLanguage(lang);
  //};

  return (
    <Form
      onSubmit={async (values: Covid19Settings & TranslationsFormValues) => {
        const newCovid19Settings = {
          visibility: values.visibility,
          introduction: values.introduction,
          precautions: defaultPrecautions.filter((precaution) =>
            (values.precautions || []).includes(precaution)
          ),
          custom_precautions: values.custom_precautions,
          to_guests: defaultToGuests.filter((toGuest) =>
            (values.to_guests || []).includes(toGuest)
          ),
          custom_to_guests: values.custom_to_guests,
          other_message: values.other_message,
        };

        try {
          await dispatch(updateCovid19Settings(newCovid19Settings));
          setSaveSucceeded(true);
        } catch (err) {
          return {
            [FORM_ERROR]: t('Save Failed'),
          };
        }
      }}
      initialValues={initialValuesWithTranslations}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div
            className={clsx(
              styles['p-inquiry-tabs__tab-panel'],
              styles['components_is-active__B15DT']
            )}
            style={{ display: 'block' }}
          >
            {submitSucceeded && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'success'}
                text={t('Save Successful')}
                shouldShow={submitSucceeded}
              />
            )}
            {submitError && !modifiedSinceLastSubmit && (
              <Snackbar
                color={'error'}
                text={t('Save Failed')}
                shouldShow={submitError}
              />
            )}
            <div className={styles['p-inquiry-tabs__content']}>
              <ul className={styles['p-inquiry-list']}>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Visibility')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="visibility" type="checkbox">
                        {({ input }) => (
                          <div>
                            <Toggle
                              label={t(
                                'Show COVID-19 Safety Measures page on booking website'
                              )}
                              {...input}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </li>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Introduction')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="introduction">
                        {({ input, meta: { touched, error } }) => (
                          <TextField
                            label={t('Title')}
                            value={input.value}
                            onChange={input.onChange}
                            error={touched && error}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </li>
                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Precautions')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      {defaultPrecautions.map((precaution, idx) => {
                        return (
                          <Field
                            key={idx}
                            name="precautions"
                            type="checkbox"
                            value={t(precaution, {
                              lng: defaultLanguage,
                            })}
                          >
                            {({ input }) => (
                              <div key={idx} style={{ marginBottom: '7px' }}>
                                <Toggle label={t(precaution)} {...input} />
                              </div>
                            )}
                          </Field>
                        );
                      })}
                    </div>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <FieldArray name="custom_precautions">
                        {({ fields }) => {
                          const count = fields.length ?? 0;
                          return (
                            <>
                              {fields.map((name, idx) => (
                                <Field key={name} name={name}>
                                  {({ input }) => (
                                    <div
                                      className={styles['company__item']}
                                      style={{ display: 'flex' }}
                                      key={idx}
                                    >
                                      <TextField {...input} />
                                      <div className={baseStyles['base-flex']}>
                                        <Button
                                          size="icon"
                                          color="tertiarygray"
                                          iconBeforeText={
                                            <i className="c-icon-outline-general-trash-03"></i>
                                          }
                                          onClick={() => fields.remove(idx)}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Field>
                              ))}
                              <a
                                className={styles['add__button']}
                                onClick={() => {
                                  if (count === 0) {
                                    (fields as any).insertAt(0, '');
                                  } else {
                                    (fields as any).insertAt(count + 1, '');
                                  }
                                }}
                              >
                                <i className="c-icon-outline-general-plus-circle"></i>
                                {t('Add')}
                              </a>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('To Guests')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      {defaultToGuests.map((toGuest, idx) => {
                        return (
                          <Field
                            key={idx}
                            name="to_guests"
                            value={t(toGuest, {
                              lng: defaultLanguage,
                            })}
                          >
                            {({ input }) => (
                              <div key={idx} style={{ marginBottom: '7px' }}>
                                <Toggle label={t(toGuest)} {...input} />
                              </div>
                            )}
                          </Field>
                        );
                      })}
                    </div>
                  </div>
                </li>

                <li className={styles['p-inquiry-list__item']}>
                  <div className={styles['p-inquiry-list__item__ttl']}>
                    <div className={styles['p-inquiry-list__item__ttl__txt']}>
                      <div>{t('Other Message')}</div>
                    </div>
                  </div>
                  <div className={styles['p-inquiry-list__item__body']}>
                    <div className={styles['p-inquiry-list__item__body__item']}>
                      <Field name="other_message">
                        {({ input }) => (
                          <TextField
                            style={{ height: '150px' }}
                            {...(input as any)}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles['p-products__fixed'], styles['is-active'])}
          >
            <div className={styles['p-products__fixed__actions']}>
              <Button
                disabled={submitting}
                loading={submitting}
                text={t('Save')}
                type="submit"
                color="primary"
                size="md"
                form="submit"
                style={{
                  width: '60px',
                  fontWeight: 'var(--text-semibold)',
                }}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
