import * as React from 'react';
import { GalleryImage } from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Delete } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

import { DraggableItem } from '../DraggableItem';

import { EditGalleryImageModal } from './EditGalleryImageModal';

interface Props {
  items: GalleryImage[];
  onItemsChange: (arg0: GalleryImage[]) => void;
  index: number;
}

export const DraggableGalleryImage = ({
  items,
  onItemsChange,
  index,
}: Props) => {
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  return (
    <>
      {showEditModal && (
        <EditGalleryImageModal
          value={items[index]}
          onChange={(newValue) =>
            onItemsChange(
              items.map((item, i) => (i === index ? newValue : item))
            )
          }
          onClose={() => setShowEditModal(false)}
        />
      )}
      <DraggableItem
        value={items[index]}
        index={index}
        listValue={items}
        onListValueChange={onItemsChange}
      >
        <Edit
          className={baseStyles['icon-box']}
          onClick={() => setShowEditModal(true)}
        />
        <Delete
          className={baseStyles['icon-box']}
          onClick={() => onItemsChange(items.filter((i, idx) => idx !== index))}
        />
        <img height={100} src={items[index].imageUrl} />
      </DraggableItem>
    </>
  );
};
