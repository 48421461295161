import { useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from 'axios';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { buildSearchReservationsRequest } from 'client/pages/ReservationSearch/util';

const fetcher = (url: string, paramsString: string, headerString: string) =>
  axios
    .get(url, {
      params: {
        ...JSON.parse(paramsString),
        page_size: 10000,
      },
      headers: JSON.parse(headerString),
    })
    .then((res) => res.data ?? []);

export const useReservationData = (
  annualPassOnly = false
): {
  data: Swagger.Reservation[] | null;
  total: number;
  isLoading: boolean;
  error: string;
} => {
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = getSearchReservationRequest(annualPassOnly);

  const { data, error } = useSWR<Swagger.SearchReservationsResponse>(
    [
      `/api/reservations/search`,
      JSON.stringify(queryParams),
      JSON.stringify(headers),
    ],
    fetcher
  );
  return {
    data: data?.reservations ?? null,
    total: data?.total ?? 0,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const getSearchReservationRequest = (
  annualPassOnly: boolean
): Swagger.SearchReservationsRequest => {
  return buildSearchReservationsRequest({
    agentReference: '',
    agentIds: [],
    presetKey: '',
    supplierReference: '',
    id: '',
    statuses: [],
    customerGivenName: '',
    customerFamilyName: '',
    customerPhone: '',
    customerEmail: '',
    bookingSourceTypes: [],
    supplierIds: [],
    productIds: [],
    bookedDateFrom: '',
    bookedDateTo: '',
    participationDateFrom: '',
    participationDateTo: '',
    lastUpdatedDateFrom: '',
    lastUpdatedDateTo: '',
    dateFilterPreset: null,
    orderBy: 'last_updated_desc',
    supplierOrAgentReference: '',
    reservationLanguages: [],
    mostRecentEmailBounced: false,
    pickupCheckinLocationName: '',
    waiverCompletionStatuses: [],
    checkinStatuses: [],
    annualPassOnly: annualPassOnly,
    expirationPresetKey: '',
    expirationDateFrom: '',
    expirationDateTo: '',
    expirationDateFilterPreset: null,
    groupIds: [],
    automaticContinuingStatus: null,
  });
};
