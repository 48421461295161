import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
// import moment from 'moment';

import { ProductInstanceEditModal } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ProductInstanceEditModal';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { fetchReservationStatsByDate } from 'client/actions/reservations';
import { ReservationListModal } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationListModal';
import type { ReduxState } from 'client/reducers';
import {
  setProductCalendarListCurrentPage,
  setProductCalendarListPageSize,
} from 'client/actions/productCalendarListControls';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { fetchProductAvailability } from 'client/actions/productAvailability';
import type { ProductInstance } from 'shared/models/swagger';
import {
  productsWithOccupancySelector,
  tagFilterSetSelector,
  salesStatusFilterSetSelector,
  visibleParentProductIdsSelector,
} from 'client/pages/Availability/util';
import { AvailabilityCustomCalendar as AvailabilityWeeklyCalendar } from 'client/pages/v3/Availability/AvailabilityWeekly/AvailabilityWeeklyBody/AvailabilityCustomCalendar';
import { AvailabilityCustomCalendar as AvailabilityMonthlyCalendar } from 'client/pages/v3/Availability/AvailabilityMonthly/AvailabilityMonthlyBody/AvailabilityCustomCalendar';
import { AvailabilityCustomTable as AvailabilityTable } from 'client/pages/v3/Availability/Shared/AvailabilityCustomTable';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { searchReservations } from 'client/actions/reservationSearch';
import { buildSearchReservationsRequest } from 'client/pages/ReservationSearch/util';

type CustomTableColumn = {
  accessor: string;
};
type Column = CustomTableColumn;

type Props = {
  type: 'weekly' | 'monthly';
  view: 'calendar' | 'table';
};

const DEFAULT_ROW_COUNT = 10000;

export const AvailabilityWrapper = ({ type, view }: Props) => {
  const { t } = useTranslation();

  const [editingProductInstance, setEditingProductInstance] =
    useState<ProductInstance | null>(null);
  const [reservationListContext, setReservationListContext] = useState<{
    productIds: string[];
    rootProductInstanceId: string;
    date: string;
  } | null>(null);
  const [shouldRefreshAvailabilityData, setShouldRefreshAvailabilityData] =
    useState(false);
  const [columns, setColumns] = useState<Column[]>([]);

  const dayMonthFormat = useSelector(
    (state: ReduxState) => state.language.selected.dayMonthFormat
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const availabilityInvalidated = useSelector(
    (state: ReduxState) => state.productAvailability.invalidated
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const totalPaxByDate = useSelector(
    (state: ReduxState) => state.reservations.totalConfirmedPaxByDate
  );
  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);
  const visibleParentProductIds = useSelector(visibleParentProductIdsSelector);
  const productsWithOccupancy = useSelector(productsWithOccupancySelector);
  const dates = useSelector(productCalendarListDatesSelector);
  const startDate = useSelector(
    (state: ReduxState) => state.productCalendarListControls.startDate
  );
  const endDate = useSelector(
    (state: ReduxState) => state.productCalendarListControls.endDate
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNutmegAdmin && visibleParentProductIds.length === 0) {
      dispatch(fetchProducts());
    }
  }, [invalidated, isNutmegAdmin, t, visibleParentProductIds.length]);
  useEffect(() => {
    if (visibleParentProductIds.length > 0 && startDate && endDate) {
      dispatch(
        fetchProductAvailability(visibleParentProductIds, startDate, endDate)
      );
    }
  }, [startDate, endDate, visibleParentProductIds]);
  useEffect(() => {
    // Reset current page if filters change.
    dispatch(setProductCalendarListCurrentPage(0));
  }, [tagFilterSet, salesStatusFilterSet]);
  useEffect(() => {
    dispatch(fetchReservationStatsByDate(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (availabilityInvalidated) {
      setShouldRefreshAvailabilityData(true);
    }
  }, [availabilityInvalidated]);

  // Reload availability if 'shouldRefreshAvailabilityData' flag becomes true;
  useEffect(() => {
    if (
      shouldRefreshAvailabilityData &&
      visibleParentProductIds.length > 0 &&
      startDate &&
      endDate
    ) {
      dispatch(
        fetchProductAvailability(visibleParentProductIds, startDate, endDate)
      );
    }

    setShouldRefreshAvailabilityData(false);
  }, [
    startDate,
    endDate,
    visibleParentProductIds,
    shouldRefreshAvailabilityData,
  ]);

  useEffect(() => {
    setColumns([
      ...dates.map((date) => {
        return {
          id: date,
          accessor: date,
        };
      }),
    ]);
  }, [dates, dayMonthFormat, locale, setColumns, t, totalPaxByDate]);

  // Fetch product instances for each product in list of products in span of a week
  // TODO: what is this used for? for now disable this
  // useEffect(() => {
  //   if (columns.length > 0 && productsWithOccupancy.length > 0) {
  //     const productIds = productsWithOccupancy.map((item) => {
  //       return item.id;
  //     });

  //     // Note: the current method is to loop through each product id to fetch instances by product id
  //     dispatch(
  //       fetchProductInstancesForProducts(
  //         productIds,
  //         moment(columns[0].accessor),
  //         moment(columns[columns.length - 1].accessor)
  //       )
  //     );
  //   }
  // }, [columns, productsWithOccupancy]);

  // Fetch reservations for the week
  useEffect(() => {
    const defaultSearchConditions = {
      agentReference: '',
      agentIds: [],
      presetKey: '',
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
    };

    if (startDate && endDate) {
      const productIds = productsWithOccupancy.map((item) => {
        return item.id;
      });

      dispatch(
        searchReservations(
          buildSearchReservationsRequest({
            ...defaultSearchConditions,
            participationDateFrom: startDate,
            participationDateTo: endDate,
            productIds: productIds,
            statuses: ['CONFIRMED'],
          }),
          DEFAULT_ROW_COUNT,
          0
        )
      );
    }
  }, [startDate, endDate]);

  return (
    <div
      className={clsx(
        commonStyles['p-availability__body'],
        commonStyles['table']
      )}
    >
      <>
        {editingProductInstance && (
          <ProductInstanceEditModal
            oldProductInstance={editingProductInstance}
            onClose={() => setEditingProductInstance(null)}
            open={editingProductInstance !== null}
          />
        )}
        <ReservationListModal
          productIds={reservationListContext?.productIds || []}
          rootProductInstanceId={
            reservationListContext?.rootProductInstanceId || ''
          }
          date={reservationListContext?.date || ''}
          onClose={() => setReservationListContext(null)}
          open={reservationListContext !== null}
        />

        {type === 'weekly' && view === 'calendar' && (
          <AvailabilityWeeklyCalendar
            items={productsWithOccupancy}
            columns={columns}
            setEditingProductInstance={setEditingProductInstance}
            setReservationListContext={setReservationListContext}
            totalPaxByDate={totalPaxByDate}
            onCurrentPageChange={(page: number) =>
              dispatch(setProductCalendarListCurrentPage(page))
            }
            onPageSizeChange={(size: number) =>
              dispatch(setProductCalendarListPageSize(size))
            }
          />
        )}
        {type === 'weekly' && view === 'table' && (
          <AvailabilityTable
            items={productsWithOccupancy}
            columns={columns}
            setEditingProductInstance={setEditingProductInstance}
            setReservationListContext={setReservationListContext}
            totalPaxByDate={totalPaxByDate}
            onCurrentPageChange={(page: number) =>
              dispatch(setProductCalendarListCurrentPage(page))
            }
            onPageSizeChange={(size: number) =>
              dispatch(setProductCalendarListPageSize(size))
            }
            usePaging={true}
          />
        )}
        {type === 'monthly' && view === 'calendar' && (
          <AvailabilityMonthlyCalendar
            items={productsWithOccupancy}
            setEditingProductInstance={setEditingProductInstance}
            setReservationListContext={setReservationListContext}
            totalPaxByDate={totalPaxByDate}
            startDate={startDate}
            onCurrentPageChange={(page: number) =>
              dispatch(setProductCalendarListCurrentPage(page))
            }
            onPageSizeChange={(size: number) =>
              dispatch(setProductCalendarListPageSize(size))
            }
          />
        )}
        {type === 'monthly' && view === 'table' && (
          <AvailabilityTable
            items={productsWithOccupancy}
            columns={columns}
            setEditingProductInstance={setEditingProductInstance}
            setReservationListContext={setReservationListContext}
            totalPaxByDate={totalPaxByDate}
            onCurrentPageChange={(page: number) =>
              dispatch(setProductCalendarListCurrentPage(page))
            }
            onPageSizeChange={(size: number) =>
              dispatch(setProductCalendarListPageSize(size))
            }
            usePaging={true}
          />
        )}
      </>
    </div>
  );
};
