import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { updateOrganization } from 'client/actions/organizations';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { Button } from 'client/components/Form';
import { Loading } from 'client/pages/Loading';
import 'react-table/react-table.css';
import type { ReduxState } from 'client/reducers/index';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import editIcon from 'client/images/ic_edit.svg';
import deleteIcon from 'client/images/ic_delete.svg';

import { ProductGroupCalendarEditModal } from './ProductGroupCalendarEditModal';
import { ProductGroupCalendarEditAlertModal } from './ProductGroupCalendarEditAlertModal';

export const ProductGroupCalendarList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector((state: ReduxState) => state.user.loading);
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const productGroup =
    activeOrganization?.booking_widget_calendar_product_groups ?? [];

  const isActive = Boolean(productGroup.length);

  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showAlertModal, setShowAlertModal] = React.useState<boolean>(false);

  const [editingProductGroupName, setEditingProductGroupName] =
    React.useState<string>('');

  const deleteProductGroupCalendar = async () => {
    const productGroup =
      activeOrganization?.booking_widget_calendar_product_groups?.filter(
        (p) => p.product_group_name !== editingProductGroupName
      );
    await dispatch(
      updateOrganization(activeOrganization?.id || '', 'SUPPLIER', {
        booking_widget_calendar_product_groups: productGroup?.length
          ? productGroup
          : [{}],
      })
    );
  };

  const getColumns = (): CustomTableColumn[] => {
    return [
      {
        Header: '',
        Cell: (cellInfo: any) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Edit')}
            onClick={() => {
              setEditingProductGroupName(cellInfo.original.product_group_name);
              setShowEditModal(true);
            }}
          >
            <img src={editIcon} />
          </a>
        ),
        width: 'icon',
      },
      {
        Header: '',
        Cell: (cellInfo: any) => (
          <a
            className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
            data-text={t('Delete')}
            onClick={() => {
              setEditingProductGroupName(cellInfo.original.product_group_name);
              setShowAlertModal(true);
            }}
          >
            <img src={deleteIcon} />
          </a>
        ),
        width: 'icon',
      },
      {
        Header: t('Product Group Name'),
        accessor: 'product_group_name',
        width: 'short',
      },
      {
        Header: t('Products'),
        accessor: 'product_ids',
        Cell: (cellInfo: any) => (
          <>
            {(cellInfo.value || [])
              .filter((productId: string) => {
                return allProducts.find((p) => p.id === productId);
              })
              .map((productId: string) => {
                const product = allProducts.find((p) => p.id === productId);
                return (
                  <>
                    {getDisplayProductName(product)}
                    <br />
                  </>
                );
              })}
          </>
        ),
        width: 'short',
      },
    ];
  };

  const columns = getColumns();

  return (
    <>
      {loading && <Loading />}
      <div>
        <Button
          style="green"
          size="middle"
          type="button"
          onClick={() => {
            setEditingProductGroupName('');
            setShowEditModal(true);
          }}
        >
          {t('Create new product group')}
        </Button>
      </div>
      {showEditModal && (
        <ProductGroupCalendarEditModal
          open={true}
          onClose={() => {
            setShowEditModal(false);
          }}
          productGroupName={editingProductGroupName}
          existingProductGroup={Boolean(editingProductGroupName)}
        />
      )}
      {showAlertModal && (
        <ProductGroupCalendarEditAlertModal
          header={t('Delete Product Group')}
          productGroupName={editingProductGroupName}
          open={true}
          onOk={async () => {
            await deleteProductGroupCalendar();
            //setShowAlertModal(false);
          }}
          onCancel={() => {
            setEditingProductGroupName('');
            //setShowAlertModal(false);
          }}
          onClose={() => {
            setShowAlertModal(false);
          }}
        />
      )}
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <CustomTable items={productGroup} columns={columns} />
        </div>
      </div>
    </>
  );
};
