import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { DashboardGadget } from 'client/reducers/dashboardSettings';
import styles from 'client/pages/v3/FlexibleDashboard/FlexibleDashboard.module.css';

export const AccessReportGadgetEditor = () => {
  const { t } = useTranslation();

  const form = useForm<DashboardGadget>();
  const values = form.getState().values;

  return (
    <>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Display Data')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.displayType">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  { value: 'BAR_CHART', text: t('Bar Chart') },
                  // TODO: Hide products and landing sources for now, to be implemented later
                  // {
                  //   value: 'PRODUCTS_TABLE',
                  //   text: t('Top 5 products'),
                  // },
                  // {
                  //   value: 'LANDING_SOURCES_TABLE',
                  //   text: t('Top 5 landing sources'),
                  // },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
      <li className={styles['p-dashboardModal__item']}>
        <p className={styles['p-dashboardModal__item__ttl']}>
          {t('Default Date Range')}
        </p>
        <div className={styles['p-dashboardModal__item__body']}>
          <Field name="params.dateRange">
            {({ input }) => (
              <SingleDropdown
                selectedOption={input.value}
                onChange={input.onChange}
                options={[
                  {
                    value: 'PREV_7_DAYS',
                    text: t('Last {{count}} days', { count: 7 }),
                  },
                  {
                    value: 'PREV_28_DAYS',
                    text: t('Last {{count}} days', { count: 28 }),
                  },
                  {
                    value: 'PREV_3_MONTHS',
                    text: t('Last {{count}} months', { count: 3 }),
                  },
                ]}
              />
            )}
          </Field>
        </div>
      </li>
      {values.gadgetType === 'access-report' &&
        values.params &&
        values.params.displayType === 'BAR_CHART' && (
          <li className={styles['p-dashboardModal__item']}>
            <p className={styles['p-dashboardModal__item__ttl']}>
              {t('Aggregation Type')}
            </p>
            <div className={styles['p-dashboardModal__item__body']}>
              <Field name="params.aggregationType">
                {({ input }) => (
                  <SingleDropdown
                    selectedOption={input.value}
                    onChange={input.onChange}
                    options={[
                      { value: 'DAILY', text: t('Daily') },
                      { value: 'WEEKLY', text: t('Weekly') },
                      { value: 'MONTHLY', text: t('Monthly') },
                    ]}
                  />
                )}
              </Field>
            </div>
          </li>
        )}
    </>
  );
};
