import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { fetchDigitalMapAccessReportData } from 'client/actions/digitalMapAccessReportData';
import type { ReduxState } from 'client/reducers';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { setLastDigitalMapAccessReportSettings } from 'client/actions/digitalMapAccessReportSettings';
import { activeUserSelector } from 'client/reducers/user';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  DateFilterPreset,
  DigitalMapAccessReportSettings,
} from 'client/libraries/util/digitalMapAccessReportSettings';
import { initialSettings } from 'client/libraries/util/accessReportSettings';
import { convertAccessReportSettingsToQueryParams } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/utils';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import styles from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReport.module.css';
import { operationAllowed } from 'shared/models/access';

export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return ['7_DAY', '14_DAYS', '28_DAYS', '30_DAYS', 'CUSTOM'].map((preset) => ({
    value: preset as DateFilterPreset,
    text: getDateFilterPresetText(preset as DateFilterPreset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  if (preset === 'CUSTOM') {
    return t('Custom');
  }

  const match = /^(\d+)_DAY.*$/.exec(preset);

  if (match && match.length > 0) {
    return t('Last {{count}} days', {
      count: match[1],
    });
  }

  return '';
};

export const DigitalMapAccessReportSettingsBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUser = useSelector(activeUserSelector);

  const lastDigitalMapAccessReportSettings = useSelector(
    (state: ReduxState) =>
      state.digitalMapAccessReportSettings.lastDigitalMapAccessReportSettings
  );
  //const fetchAccessReportDataStatus = useSelector(
  //  (state: ReduxState) => state.accessReportData.fetchAccessReportDataStatus
  //);
  const [digitalMapAccessReportSettings, setDigitalMapAccessReportSettings] =
    React.useState<DigitalMapAccessReportSettings>(
      lastDigitalMapAccessReportSettings
    );

  const dateFilterPresetOptions = [...getDateFilterPresetOptions(t)];

  const dateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    let startDateParam = '';
    let endDateParam = '';
    if (
      digitalMapAccessReportSettings.endDate &&
      moment(digitalMapAccessReportSettings.endDate).isBefore(moment(startDate))
    ) {
      endDateParam = startDate || '';
    } else {
      startDateParam = startDate || '';
    }

    if (
      digitalMapAccessReportSettings.startDate &&
      moment(digitalMapAccessReportSettings.startDate).isAfter(moment(endDate))
    ) {
      startDateParam = endDate || '';
    } else {
      endDateParam = endDate || '';
    }
    setDigitalMapAccessReportSettings({
      ...digitalMapAccessReportSettings,
      endDate: endDateParam,
      startDate: startDateParam,
    });
  };

  const compareDateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    let startDateParam = '';
    let endDateParam = '';
    if (
      digitalMapAccessReportSettings.compareEndDate &&
      moment(digitalMapAccessReportSettings.compareEndDate).isBefore(
        moment(startDate)
      )
    ) {
      endDateParam = startDate || '';
    } else {
      startDateParam = startDate || '';
    }
    if (
      digitalMapAccessReportSettings.compareStartDate &&
      moment(digitalMapAccessReportSettings.compareStartDate).isAfter(
        moment(endDate)
      )
    ) {
      startDateParam = endDate || '';
    } else {
      endDateParam = endDate || '';
    }
    setDigitalMapAccessReportSettings({
      ...digitalMapAccessReportSettings,
      compareStartDate: startDateParam,
      compareEndDate: endDateParam,
    });
  };

  const comparisonHandler = () => {
    setDigitalMapAccessReportSettings({
      ...digitalMapAccessReportSettings,
      compare: !digitalMapAccessReportSettings.compare,
    });
  };

  React.useEffect(() => {
    dispatch(
      fetchDigitalMapAccessReportData(
        convertAccessReportSettingsToQueryParams(digitalMapAccessReportSettings)
      )
    );
  }, []);

  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  const reset = () => {
    setDigitalMapAccessReportSettings(initialSettings);
  };
  const search = () => {
    dispatch(
      fetchDigitalMapAccessReportData(
        convertAccessReportSettingsToQueryParams(digitalMapAccessReportSettings)
      )
    );
    dispatch(
      setLastDigitalMapAccessReportSettings(digitalMapAccessReportSettings)
    );
  };

  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  return (
    <section className={styles['g-section']}>
      <div className={styles['p-reports']}>
        <div className={styles['p-reports__header']}>
          <h2 className={styles['p-reports__header__title']}>
            {t('Report Setting')}
          </h2>
        </div>
        <div className={styles['p-reports__body']}>
          <div className={styles['p-reports__body__display']}>
            <div
              className={clsx(
                styles['p-reports__body__item'],
                styles['p-reports__body__item--custom']
              )}
              style={{ alignItems: 'center' }}
            >
              <div style={{ width: '264px' }}>
                <SingleDropdown
                  label={t('Date Range')}
                  selectedOption={
                    digitalMapAccessReportSettings.dateFilterPreset ?? 'NONE'
                  }
                  options={dateFilterPresetOptions}
                  onChange={(value) => {
                    setDigitalMapAccessReportSettings({
                      ...digitalMapAccessReportSettings,
                      dateFilterPreset: value as DateFilterPreset,
                    });
                  }}
                />
              </div>
              {digitalMapAccessReportSettings.dateFilterPreset === 'CUSTOM' && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '1',
                    })}
                    dateFrom={digitalMapAccessReportSettings.startDate}
                    dateTo={digitalMapAccessReportSettings.endDate}
                    onChange={dateRangeInputChangeHandler}
                  />
                </div>
              )}
              <Checkbox
                label={t('Compare')}
                checked={digitalMapAccessReportSettings.compare}
                onChange={() => {
                  comparisonHandler();
                }}
                size="md"
              />

              {digitalMapAccessReportSettings.compare && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '2',
                    })}
                    dateFrom={digitalMapAccessReportSettings.compareStartDate}
                    dateTo={digitalMapAccessReportSettings.compareEndDate}
                    onChange={compareDateRangeInputChangeHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={clsx(styles['p-reports__bottom'], styles['center'])}>
          <Button
            text={t('Clear conditions')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            onClick={() => {
              reset();
            }}
          />
          <Button
            type="submit"
            text={t('Search reservations')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={() => {
              search();
            }}
          />
        </div>
      </div>
    </section>
  );
};
