import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import {
  FieldWrapper,
  Radio,
  Input,
  Checkbox,
  Select,
  Button,
} from 'client/components/Form';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { ProductEditorForm } from 'client/components/ProductEditorForm/ProductEditorForm';
import { Message } from 'client/components/Message/Message';
import type {
  Product,
  Product$Patch,
  Field$Patch,
} from 'shared/models/swagger';

import { ntaReservationFormFields } from '../utils';
import { cityCodes } from '../cityCodes';

type FormValues = {
  productType: string;
  cityCode: string;
  operatorName: string;
  eanDataId: string;
  extraBedConfirmed: boolean;
  noBathtub: boolean;
  maxBed: string;
  maxCapacity: string;
  minStay: string;
  multipleNightDiscount: string;
  ageLimitMinorsOnlyProhibited: boolean;
  ageLimitNoMinors: boolean;
  hotelCode: string;
  arrangement: string;
  inventoryManagement: string;
  eanDataIdList: string;
  mealCondition: string;
};

const getInitialValues = (product: Product | null): FormValues => {
  const htmlTexts = product?.html_texts || [];
  return {
    productType:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'product-type';
      })?.text || 'activity',
    cityCode:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'city-code';
      })?.text || '',
    operatorName:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'operator-name';
      })?.text || '',
    eanDataId:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'ean-data-id';
      })?.text || '',
    extraBedConfirmed:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'extra-bed-confirmed';
      })?.text === 'yes',
    noBathtub:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'no-bathtub';
      })?.text === 'yes',
    maxBed:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'max-bed';
      })?.text || '1',
    maxCapacity:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'max-capacity';
      })?.text || '1',
    minStay:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'min-stay';
      })?.text || '1',
    multipleNightDiscount:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'multiple-night-discount';
      })?.text || '',
    ageLimitMinorsOnlyProhibited:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'age-limit-minors-only-prohibited';
      })?.text === 'yes',
    ageLimitNoMinors:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'age-limit-no-minors';
      })?.text === 'yes',
    hotelCode:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'hotel-code';
      })?.text || '',
    arrangement:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'arrangement';
      })?.text || '',
    inventoryManagement:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'inventory-management';
      })?.text || '',
    eanDataIdList:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'ean-data-id-list';
      })?.text || '',
    mealCondition:
      htmlTexts.find((htmlText) => {
        return htmlText.key === 'meal-condition';
      })?.text || '',
  };
};

const convertFormValuesToProductPatch = (
  values: FormValues,
  product: Product | null
): Product$Patch => {
  const ntaReservationFormKeys = ntaReservationFormFields.map((field) => {
    return field.key;
  });
  const newReservationFields: Field$Patch[] = (
    product?.reservation_form_fields || []
  )
    .filter((field) => {
      return !ntaReservationFormKeys.includes(field.key);
    })
    .map((field) => {
      return { ...field };
    });

  if (values.productType === 'hotel') {
    ntaReservationFormFields.forEach((field) => {
      newReservationFields.push(field);
    });
  }

  const htmlTexts = [];
  htmlTexts.push({
    key: 'product-type',
    text: values.productType,
  });

  if (['activity', 'hotel'].includes(values.productType)) {
    if (values.cityCode) {
      htmlTexts.push({
        key: 'city-code',
        text: values.cityCode,
      });
    }
  }

  if (values.productType === 'activity') {
    if (values.operatorName) {
      htmlTexts.push({
        key: 'operator-name',
        text: values.operatorName,
      });
    }
  }

  if (values.productType === 'hotel') {
    if (values.eanDataId) {
      htmlTexts.push({
        key: 'ean-data-id',
        text: values.eanDataId,
      });
    }

    htmlTexts.push({
      key: 'extra-bed-confirmed',
      text: values.extraBedConfirmed ? 'yes' : 'no',
    });
    htmlTexts.push({
      key: 'no-bathtub',
      text: values.noBathtub ? 'yes' : 'no',
    });
    htmlTexts.push({
      key: 'max-bed',
      text: values.maxBed,
    });
    htmlTexts.push({
      key: 'max-capacity',
      text: values.maxCapacity,
    });
    htmlTexts.push({
      key: 'max-stay',
      text: values.minStay,
    });

    if (values.multipleNightDiscount) {
      htmlTexts.push({
        key: 'multiple-night-discount',
        text: values.multipleNightDiscount,
      });
    }

    htmlTexts.push({
      key: 'age-limit-minors-only-prohibited',
      text: values.ageLimitMinorsOnlyProhibited ? 'yes' : 'no',
    });
    htmlTexts.push({
      key: 'age-limit-no-minors',
      text: values.ageLimitNoMinors ? 'yes' : 'no',
    });

    if (values.hotelCode) {
      htmlTexts.push({
        key: 'hotel-code',
        text: values.hotelCode,
      });
    }

    if (values.arrangement) {
      htmlTexts.push({
        key: 'arrangement',
        text: values.arrangement,
      });
    }

    if (values.inventoryManagement) {
      htmlTexts.push({
        key: 'inventory-management',
        text: values.inventoryManagement,
      });
    }

    if (values.mealCondition) {
      htmlTexts.push({
        key: 'meal-condition',
        text: values.mealCondition,
      });
    }
  }

  if (values.productType === 'land_package') {
    if (values.eanDataIdList) {
      htmlTexts.push({
        key: 'ean-date-id-list',
        text: values.eanDataIdList,
      });
    }
  }

  return {
    reservation_form_fields: newReservationFields,
    html_texts: htmlTexts,
  };
};

type Props = {
  onClose: () => void;
};
export const NtaProductSettingsModal = ({ onClose }: Props) => {
  const product = React.useContext(EditingProductContext);
  const initialValues = React.useMemo(
    () => getInitialValues(product),
    [product]
  );
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState<boolean>(false);
  return (
    <Modal title={'NTA商品設定'} open={true} onClose={onClose}>
      <ProductEditorForm
        onSubmitStart={() => setSuccess(false)}
        onSubmitSuccess={() => setSuccess(true)}
        initialValues={initialValues}
        convertFormValuesToProductPatch={(
          values: FormValues
        ): Product$Patch => {
          return convertFormValuesToProductPatch(values, product);
        }}
      >
        {({ submitError, submitting, values }) => (
          <>
            <Modal.Content>
              {false && <pre>{JSON.stringify(values, undefined, 2)}</pre>}
              <Modal.Box>
                <Field name="productType">
                  {({ input }) => (
                    <FieldWrapper label={'商品種別'}>
                      <Radio
                        label={'アクティビティ'}
                        checked={input.value === 'activity'}
                        onChange={() => {
                          input.onChange('activity');
                        }}
                      />
                      <Radio
                        label={'ホテル'}
                        checked={input.value === 'hotel'}
                        onChange={() => {
                          input.onChange('hotel');
                        }}
                      />
                      <Radio
                        label={'ランドパッケージ'}
                        checked={input.value === 'land_package'}
                        onChange={() => {
                          input.onChange('land_package');
                        }}
                      />
                    </FieldWrapper>
                  )}
                </Field>
              </Modal.Box>

              {['activity', 'hotel'].includes(
                (values as any)['productType']
              ) && (
                <Modal.Box>
                  <Field name="cityCode">
                    {({ input }) => (
                      <Select
                        label={'都市コード'}
                        search
                        options={cityCodes}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Modal.Box>
              )}
              {(values as any)['productType'] === 'activity' && (
                <Modal.Box>
                  <Field name="operatorName">
                    {({ input, meta }) => (
                      <Input
                        label={'催行会社名'}
                        error={meta.error && meta.touched && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                </Modal.Box>
              )}

              {(values as any)['productType'] === 'hotel' && (
                <>
                  <Modal.Box>
                    <Field name="eanDataId">
                      {({ input, meta }) => (
                        <Input
                          label={'EAN情報ID'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper label={'エキストラベッドトリプル確約'}>
                      <Field name="extraBedConfirmed" type="checkbox">
                        {({ input }) => (
                          <Checkbox label={'確約する'} {...(input as any)} />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper label={'バスタブなし'}>
                      <Field name="noBathtub" type="checkbox">
                        {({ input }) => (
                          <Checkbox
                            label={'バスタブなし'}
                            {...(input as any)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper label={'最大ベッド人数＝ベッド数'}>
                      <Field name="maxBed">
                        {({ input }) => (
                          <Select
                            options={[...Array(10)].map((_, i) => {
                              return {
                                text: `${i + 1}`,
                                value: `${i + 1}`,
                              };
                            })}
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper label={'最大収容人数'}>
                      <Field name="maxCapacity">
                        {({ input }) => (
                          <Select
                            options={[...Array(20)].map((_, i) => {
                              return {
                                text: `${i + 1}`,
                                value: `${i + 1}`,
                              };
                            })}
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper label={'最低泊数（ミニマムステイ）'}>
                      <Field name="minStay">
                        {({ input }) => (
                          <Select
                            options={[...Array(20)].map((_, i) => {
                              return {
                                text: `${i + 1}`,
                                value: `${i + 1}`,
                              };
                            })}
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <Field name="multipleNightDiscount">
                      {({ input, meta }) => (
                        <Input
                          label={'連泊割引'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper
                      label={'年齢制限チェック（未成年だけでの宿泊を禁止）'}
                    >
                      <Field
                        name="ageLimitMinorsOnlyProhibited"
                        type="checkbox"
                      >
                        {({ input }) => (
                          <Checkbox
                            label={'未成年だけでの宿泊を禁止する'}
                            {...(input as any)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <FieldWrapper
                      label={'年齢制限チェック（未成年は宿泊禁止）'}
                    >
                      <Field name="ageLimitNoMinors" type="checkbox">
                        {({ input }) => (
                          <Checkbox
                            label={'未成年の宿泊を禁止する'}
                            {...(input as any)}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </Modal.Box>
                  <Modal.Box>
                    <Field name="hotelCode">
                      {({ input, meta }) => (
                        <Input
                          label={'ホテル識別コード（ウィング・部屋タイプの別）'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                  <Modal.Box>
                    <Field name="arrangement">
                      {({ input, meta }) => (
                        <Input
                          label={'手配箇所'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                  <Modal.Box>
                    <Field name="inventoryManagement">
                      {({ input, meta }) => (
                        <Input
                          label={'在庫管理箇所'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                  <Modal.Box>
                    <Field name="mealCondition">
                      {({ input, meta }) => (
                        <Input
                          label={'食事条件'}
                          error={meta.error && meta.touched && meta.error}
                          {...input}
                        />
                      )}
                    </Field>
                  </Modal.Box>
                </>
              )}
              {(values as any)['productType'] === 'land_package' && (
                <Modal.Box>
                  <Field name="eanDataIdList">
                    {({ input, meta }) => (
                      <Input
                        label={'EAN情報IDリスト（カンマ区切り）'}
                        error={meta.error && meta.touched && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                </Modal.Box>
              )}
              <div>
                {success && <Message success header={t('Save Successful')} />}
                {submitError && <Message error header={t('Save Failed')} />}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                style="green"
                size="middle"
                type="submit"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </>
        )}
      </ProductEditorForm>
    </Modal>
  );
};
