// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { deleteProduct } from 'client/actions/products';
import type { Product } from 'shared/models/swagger';

type Props = {
  product: {
    ...Product,
  },
  trigger: React.Element<'a' | 'button'>,
};

export const DeleteProductModal = ({ product, trigger }: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return product ? (
    <Modal
      title={t('Delete product')}
      trigger={trigger}
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        {t('Are you sure you want to delete "{{productName}}"?', {
          productName: product.product_name,
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setShowModal(false);
          }}
        >
          {t('Cancel')}
        </Button.Cancel>
        <Button.Caution
          onClick={() => {
            dispatch(deleteProduct(product.id));
            setShowModal(false);
          }}
        >
          {t('OK')}
        </Button.Caution>
      </Modal.Actions>
    </Modal>
  ) : null;
};
