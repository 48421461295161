import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PromotionSettingsModal } from 'client/pages/v3/Product/ProductPromotions/PromotionSettingsModal';
import { Button } from 'client/components/v3/Common/Button';

export const ProductPromotionsHeader = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      <PageHeader title={t('Promotions')}>
        <Button
          type="button"
          text={t('Create new promotion')}
          size="md"
          color="primary"
          onClick={() => setShowModal(true)}
        />
      </PageHeader>
      {showModal && (
        <PromotionSettingsModal
          open={showModal}
          setShowModal={setShowModal}
          inputPromotion={null}
        />
      )}
    </>
  );
};
