import { ThunkDispatch } from 'redux-thunk';
import queryString from 'query-string';
import axios from 'axios';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_RESERVATION_NOTIFICATIONS_REQUEST,
  FETCH_RESERVATION_NOTIFICATIONS_SUCCESS,
  FETCH_RESERVATION_NOTIFICATIONS_FAILURE,
  DELETE_RESERVATION_NOTIFICATION_REQUEST,
  DELETE_RESERVATION_NOTIFICATION_SUCCESS,
  DELETE_RESERVATION_NOTIFICATION_FAILURE,
  DELETE_ALL_RESERVATION_NOTIFICATIONS_REQUEST,
  DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS,
  DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE,
} from 'client/constants/ActionTypes';
import type { Notification } from 'client/reducers/notifications';
import { ReduxState } from 'client/reducers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchReservationNotificationsRequest = () => ({
  type: FETCH_RESERVATION_NOTIFICATIONS_REQUEST,
});

const fetchReservationNotificationsSuccess = (response: any) => ({
  type: FETCH_RESERVATION_NOTIFICATIONS_SUCCESS,
  response,
});

const fetchReservationNotificationsFailure = (error: any) => ({
  type: FETCH_RESERVATION_NOTIFICATIONS_FAILURE,
  error,
});

const maxNotificationCount = 100;

export const fetchReservationNotifications =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchReservationNotificationsRequest();
    return axios
      .get('/api/tasks', {
        params: {
          page_size: maxNotificationCount,
          filter: queryString.stringify({
            category: 'RESERVATION_NOTIFICATION',
          }),
          ignore_request_language: true,
        },
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        const data = result.data;

        dispatch(fetchReservationNotificationsSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchReservationNotificationsFailure(error));
      });
  };

export const addNotification = (notification: Notification) => ({
  type: ADD_NOTIFICATION,
  notification,
});
export const removeCheckinNotification = (id: string) => ({
  type: REMOVE_NOTIFICATION,
  id,
});
export const removeRestaurantOrderNotification = (id: string) => ({
  type: REMOVE_NOTIFICATION,
  id,
});

const deleteReservationNotificationsRequest = () => ({
  type: DELETE_RESERVATION_NOTIFICATION_REQUEST,
});

const deleteReservationNotificationsSuccess = (id: string) => ({
  type: DELETE_RESERVATION_NOTIFICATION_SUCCESS,
  id,
});

const deleteReservationNotificationsFailure = (id: string, error: any) => ({
  type: DELETE_RESERVATION_NOTIFICATION_FAILURE,
  id,
  error,
});
export const removeReservationNotification =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    deleteReservationNotificationsRequest();
    return axios
      .delete(`/api/tasks/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then(() => {
        dispatch(deleteReservationNotificationsSuccess(id));
      })
      .catch((error) => {
        dispatch(deleteReservationNotificationsFailure(id, error));
      });
  };

const deleteAllReservationNotificationsRequest = () => ({
  type: DELETE_ALL_RESERVATION_NOTIFICATIONS_REQUEST,
});

const deleteAllReservationNotificationsSuccess = (ids: string[]) => ({
  type: DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS,
  ids,
});

const deleteAllReservationNotificationsFailure = (
  ids: string[],
  error: any
) => ({
  type: DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE,
  ids,
  error,
});

export const removeAllReservationNotifications =
  () => (dispatch: Dispatch, getState: () => ReduxState) => {
    deleteAllReservationNotificationsRequest();

    const ids = getState().notifications.reservationNotifications.map(
      (notification) => notification.id
    );
    return Promise.all(
      ids.map((id) =>
        axios.delete(`/api/tasks/${id}`, {
          headers: getHTTPRequestHeaders(getState()),
        })
      )
    )
      .then(() => {
        dispatch(deleteAllReservationNotificationsSuccess(ids));
      })
      .catch((error) => {
        dispatch(deleteAllReservationNotificationsFailure(ids, error));
      });
  };
