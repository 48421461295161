import * as Swagger from 'shared/models/swagger';

export type InstantWinSettingsFormValues = {
  buttonPosition: Swagger.InstantWinSettings['button_position'];
  buttonStyle: Swagger.InstantWinSettings['button_style'];
  buttonText: string;
  buttonColor: string;
  buttonTextColor: string;
  buttonWidth: number;
  buttonHeight: number;
};

type TranslationsFormValues = {
  translations: Translation[];
};

export type Translation = {
  source: string;
  target: string;
};

export type FormValues = InstantWinSettingsFormValues & TranslationsFormValues;

export const getSettingsInitialValues = (
  organization: Swagger.Organization | null
): InstantWinSettingsFormValues => {
  return {
    buttonPosition:
      organization?.instant_win_settings?.button_position ?? 'LEFT_BOTTOM',
    buttonStyle:
      organization?.instant_win_settings?.button_style ?? 'SQUARE_CORNERS',
    buttonText: organization?.instant_win_settings?.button_text ?? '',
    buttonColor: organization?.instant_win_settings?.button_color ?? '#008ec9',
    buttonTextColor:
      organization?.instant_win_settings?.button_text_color ?? '#FFFFFF',
    buttonWidth: organization?.instant_win_settings?.button_width ?? 160,
    buttonHeight: organization?.instant_win_settings?.button_height ?? 40,
  };
};

export const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};
export const convertSettingsFormValuesToOrganizationPatch = (
  values: InstantWinSettingsFormValues
): Swagger.Organization$Patch => {
  return {
    instant_win_settings: {
      button_position: values.buttonPosition,
      button_style: values.buttonStyle,
      button_text: values.buttonText,
      button_color: values.buttonColor,
      button_text_color: values.buttonTextColor,
      button_width: values.buttonWidth,
      button_height: values.buttonHeight,
    },
  };
};
