import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { ReduxState } from 'client/reducers';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

import styles from './ActionButton.module.css';
import { DeleteTagModal } from './DeleteTagModal';
import { SwaggerAutoTag } from './FormValues';

type Props = {
  tag: SwaggerAutoTag;
  onEdit: () => void;
  lastItemOnList: boolean;
};

export const ActionButton = ({ tag, onEdit, lastItemOnList }: Props) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [failedCopyTagName, setFailedCopyTagName] = useState<string | null>(
    null
  );
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const tags =
    activeUserOrganization?.customer_ledger_settings?.customer_auto_tags ?? [];

  const tagNameUnique = useCallback(
    (name: string): string | undefined => {
      if (tags.some((tag) => tag.name === name)) {
        return t('Tag name must be unique');
      }

      return undefined;
    },
    [tags]
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
        setFailedCopyTagName(null);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
      <div>
        <Button
          uiType="bg"
          size="icon"
          color="white"
          iconBeforeText={
            <i className="c-icon-solid-general-dots-vertical"></i>
          }
          onClick={() => setShowMenu(!showMenu)}
          loading={loading}
        />
      </div>
      <ul
        className={clsx(
          styles['p-primary__select__menu'],
          showMenu && styles['is-active'],
          lastItemOnList && styles['open-above']
        )}
        ref={menuRef}
      >
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={() => {
            onEdit();
            setShowMenu(false);
          }}
        >
          <div>
            <i className="c-icon-outline-general-edit-05"></i>
            <div>{t('Edit Tag')}</div>
          </div>
        </li>
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={async () => {
            const orgId = activeUserOrganization?.id || '';
            try {
              const newTagName = `${tag.name} [COPY]`;
              if (tagNameUnique(newTagName)) {
                setFailedCopyTagName(newTagName);
                return;
              }
              const newCustomerAutoTag = {
                ...tag,
                name: newTagName,
              };
              tags.push(newCustomerAutoTag);
              await dispatch(
                updateOrganization(orgId, 'SUPPLIER', {
                  customer_ledger_settings: {
                    ...activeUserOrganization?.customer_ledger_settings,
                    customer_auto_tags: tags,
                  },
                })
              );
              await setShowMenu(false);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <div>
            <i className="c-icon-outline-general-copy-01"></i>
            <div>{t('Copy Tag')}</div>
          </div>
        </li>
        <li
          className={clsx(
            styles['p-primary__select__menu__item'],
            styles['red']
          )}
          onClick={() => setShowModal(true)}
        >
          <div>
            <i className="c-icon-outline-general-trash-03"></i>
            <div>{t('Delete Tag')}</div>
          </div>
        </li>
      </ul>
      {showModal && (
        <DeleteTagModal
          tagName={tag.name ?? ''}
          onClose={() => {
            setShowModal(false);
            setShowMenu(false);
          }}
        />
      )}
      {failedCopyTagName && (
        <Snackbar
          color={'error'}
          text={t(
            'Tag name is duplicated. Please edit the name of "{{tag_name}}" before copying.',
            { tag_name: failedCopyTagName }
          )}
          shouldShow={Boolean(failedCopyTagName)}
          onClose={() => setFailedCopyTagName(null)}
        />
      )}
    </fieldset>
  );
};
