import { SystemFeeSettingsHeader } from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettingsHeader';
import { SystemFeeSettingsBody } from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettingsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const SystemFeeSettings = () => {
  return (
    <V3Page>
      <SystemFeeSettingsHeader />
      <SystemFeeSettingsBody />
    </V3Page>
  );
};
