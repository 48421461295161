import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { Inquiry } from 'shared/models/swagger';

import styles from './InquirySummary.module.css';

const getLeadTimeInDays = (inquiry: Inquiry): number | null => {
  if (!inquiry.reservation_start_date_time_local) {
    return null;
  }

  const today = moment();
  const participationDate = moment(inquiry.reservation_start_date_time_local);

  return participationDate.diff(today, 'days');
};

export const InquirySummary = () => {
  const { t } = useTranslation();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const inquiryCategories =
    activeUserOrganization?.inquiry_settings?.inquiry_categories ?? [];

  const inquiries = useSelector((state: ReduxState) => state.inquiries.all);

  const receivedInquiries = inquiries.filter((p) => p.status === 'RECEIVED');
  const respondedInquiries = inquiries.filter((p) => p.status === 'RESPONDED');

  return (
    <div>
      <div className={baseStyles['base-form-box__header']}>
        {t('Inquiries by category')}
      </div>
      <div
        className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}
      >
        <table>
          <tbody>
            <tr>
              <th className={clsx(baseStyles['base-t-112'])}></th>
              <th className={clsx(baseStyles['base-t-112'])}>
                {t('Received')}
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                {t('Responded')}
              </th>
            </tr>
            {inquiryCategories.map((category, idx) => (
              <tr key={idx}>
                <td>
                  {t('Category: {{categoryName}}', {
                    categoryName: category.title,
                  })}
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter(
                      (inquiry) => inquiry.inquiry_category === category.title
                    ).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter(
                      (inquiry) => inquiry.inquiry_category === category.title
                    ).length
                  }
                </td>
              </tr>
            ))}
            <tr>
              <td>
                {t('Category: {{categoryName}}', {
                  categoryName: '-',
                })}
              </td>
              <td className={clsx(styles['right-align'])}>
                {
                  receivedInquiries.filter(
                    (inquiry) => !inquiry.inquiry_category
                  ).length
                }
              </td>
              <td className={clsx(styles['right-align'])}>
                {
                  respondedInquiries.filter(
                    (inquiry) => !inquiry.inquiry_category
                  ).length
                }
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>{t('Total')}</td>
              <td className={clsx(styles['right-align'])}>
                {receivedInquiries.length}
              </td>
              <td className={clsx(styles['right-align'])}>
                {respondedInquiries.length}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Box mt={4}>
        <div className={baseStyles['base-form-box__header']}>
          {t('Inquiries by lead time')}
        </div>
        <div
          className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}
        >
          <table>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-112'])}></th>
                <th className={clsx(baseStyles['base-t-112'])}>
                  {t('Received')}
                </th>
                <th className={clsx(baseStyles['base-t-112'])}>
                  {t('Responded')}
                </th>
              </tr>
              <tr>
                <td>{t('Lead Time: within 1 day')}</td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime <= 1 && leadTime >= 0;
                    }).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime <= 1 && leadTime >= 0;
                    }).length
                  }
                </td>
              </tr>
              <tr>
                <td>{t('Lead Time: 2-3 days')}</td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime >= 2 && leadTime <= 3;
                    }).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime >= 2 && leadTime <= 3;
                    }).length
                  }
                </td>
              </tr>
              <tr>
                <td>{t('Lead Time: 4-7 days')}</td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime >= 4 && leadTime <= 7;
                    }).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime >= 4 && leadTime <= 7;
                    }).length
                  }
                </td>
              </tr>
              <tr>
                <td>{t('Lead Time: 7+ days')}</td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime > 7;
                    }).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime != null && leadTime > 7;
                    }).length
                  }
                </td>
              </tr>
              <tr>
                <td>{t('Lead Time: -')}</td>
                <td className={clsx(styles['right-align'])}>
                  {
                    receivedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime == null || leadTime < 0;
                    }).length
                  }
                </td>
                <td className={clsx(styles['right-align'])}>
                  {
                    respondedInquiries.filter((inquiry) => {
                      const leadTime = getLeadTimeInDays(inquiry);

                      return leadTime == null || leadTime < 0;
                    }).length
                  }
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>{t('Total')}</td>
                <td className={clsx(styles['right-align'])}>
                  {receivedInquiries.length}
                </td>
                <td className={clsx(styles['right-align'])}>
                  {respondedInquiries.length}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </div>
  );
};
