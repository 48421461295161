import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';

import { ReservationSearchTable } from '../ReservationSearch/ReservationSearchTable/ReservationSearchTable';

export const PartnershipReservationSearch = () => {
  return (
    <PartnershipModeContext.Provider value={{ partnershipMode: true }}>
      <ReservationSearchTable />
    </PartnershipModeContext.Provider>
  );
};
