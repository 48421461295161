// @flow

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

type Day = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
const days: Day[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const WeekdayInputComponent = ({
  disabled,
  label,
  value,
  setValue,
}: {
  disabled?: boolean,
  label: string,
  value: ?(Day[]),
  setValue: (Day[]) => void,
}) => (
  <div className="field" key={label}>
    <label>{label}</label>
    <Form.Group inline>
      {days.map((d) => (
        <Form.Checkbox
          disabled={disabled}
          key={d}
          label={d}
          value={d}
          checked={value && value.indexOf(d) !== -1}
          onChange={(e, { value: day }) => {
            const s = new Set(value || []);
            if (s.has(day)) {
              s.delete(day);
            } else {
              s.add(day);
            }

            setValue(days.filter((d) => s.has(d)));
          }}
        />
      ))}
    </Form.Group>
  </div>
);

export const WeekdayInput = withTranslation()(WeekdayInputComponent);
