import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import type { ReduxState } from 'client/reducers';

type Props = {
  header: string;
  productGroupName?: string;
  onOk?: () => any;
  onCancel?: () => any;
  onClose?: () => any;
  open?: boolean;
};
export const ProductGroupCalendarEditAlertModal = ({
  header,
  productGroupName,
  onOk,
  onCancel,
  onClose,
  open: openFromProps,
}: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const [myOpen, setMyOpen] = React.useState<boolean>(false);

  const onOpen = () => setMyOpen(true);

  const open = openFromProps ?? myOpen;
  return (
    <Modal open={open} onOpen={onOpen} onClose={onCancel} title={header}>
      <Modal.Content>
        <Box mt={2}>
          {t('Are you sure you want to delete "{{productGroupName}}"?', {
            productGroupName,
          })}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button
          style="gray"
          size="middle"
          onClick={() => {
            onCancel?.();
            onClose?.();
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          loading={loading}
          style="blue"
          size="middle"
          onClick={async () => {
            await onOk?.();
            onClose?.();
          }}
        >
          {t('OK')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
