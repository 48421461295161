import * as Swagger from 'shared/models/swagger';

export type FormValues = {
  shouldUseCustomSettings: boolean;
  allowUpdatingReservationInfo: boolean;
  allowChangingReservationParameters: boolean;
  allowCancellingReservation: boolean;
};
export const getInitialValues = (
  product: Swagger.Product | null
): FormValues => {
  return {
    shouldUseCustomSettings:
      product?.guest_my_page_settings?.should_use_custom_settings ?? false,
    allowUpdatingReservationInfo:
      product?.guest_my_page_settings?.allow_updating_reservation_info ?? false,
    allowChangingReservationParameters:
      product?.guest_my_page_settings?.allow_changing_reservation_parameters ??
      false,
    allowCancellingReservation:
      product?.guest_my_page_settings?.allow_cancelling_reservation ?? false,
  };
};
export const convertFormValuesToProductPatch = (
  values: FormValues
): Swagger.Product$Patch => {
  return {
    guest_my_page_settings: values.shouldUseCustomSettings
      ? {
          should_use_custom_settings: values.shouldUseCustomSettings,
          allow_updating_reservation_info: values.allowUpdatingReservationInfo,
          allow_changing_reservation_parameters:
            values.allowChangingReservationParameters,
          allow_cancelling_reservation: values.allowCancellingReservation,
        }
      : {
          should_use_custom_settings: values.shouldUseCustomSettings,
        },
  };
};
