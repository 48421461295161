// @flow

import * as React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import type { TranslateFuncType } from 'client/components/Translate';

const ThirdPartyReferenceInputComponent = ({
  label,
  value,
  update,
  type,
  idToNameMap,
  t,
}: {
  label: string,
  value: ?({ id: string, type: 'AGENT' | 'SUPPLIER', reference: string }[]),
  update: (
    updater: (
      { id: string, type: 'AGENT' | 'SUPPLIER', reference: string }[]
    ) => { id: string, type: 'AGENT' | 'SUPPLIER', reference: string }[]
  ) => void,
  type: 'AGENT' | 'SUPPLIER' | 'GUEST',
  idToNameMap: { AGENT: { [string]: string }, SUPPLIER: { [string]: string } },
  t: TranslateFuncType,
}) => {
  return (
    <div className="field" key={label}>
      <label>
        {label}
        <Icon
          link
          name="plus circle"
          color="blue"
          size="large"
          onClick={() => {
            update((prevValue) => {
              const newValue = [...(prevValue || [])];
              newValue.splice(0, 0, {
                id: type === 'AGENT' ? 'SUPPLIER' : 'AGENT',
                type: type === 'AGENT' ? 'SUPPLIER' : 'AGENT',
                reference: '',
              });
              return newValue;
            });
          }}
        />
      </label>
      {value &&
        value.map((h, idx) => (
          <Form.Group key={idx} inline>
            <Form.Select
              label={t('Organization Name')}
              value={h.id}
              options={Object.keys(idToNameMap[h.type]).map((id) => ({
                key: id,
                text:
                  id !== h.type
                    ? idToNameMap[h.type][id]
                    : 'Choose an organization',
                value: id,
              }))}
              onChange={(e, { value: id }) => {
                update((prevValue) =>
                  prevValue.map((h, idx2) =>
                    idx2 === idx
                      ? {
                          ...h,
                          id,
                        }
                      : h
                  )
                );
              }}
            />

            <Form.Input
              label={t('Reference')}
              value={h.reference}
              onChange={(e, { value: reference }) =>
                update((prevValue) =>
                  prevValue.map((h, idx2) =>
                    idx2 === idx
                      ? {
                          ...h,
                          reference,
                        }
                      : h
                  )
                )
              }
            />

            <React.Fragment>
              <Icon
                link
                name="minus circle"
                color="red"
                size="large"
                onClick={() => {
                  update(() => {
                    const newValue = [...value];
                    newValue.splice(idx, 1);
                    return newValue;
                  });
                }}
              />
              <Icon
                link
                name="plus circle"
                color="blue"
                size="large"
                onClick={() => {
                  update((prevValue) => {
                    const newValue = [...prevValue];
                    newValue.splice(idx + 1, 0, {
                      id: type === 'AGENT' ? 'SUPPLIER' : 'AGENT',
                      type: type === 'AGENT' ? 'SUPPLIER' : 'AGENT',
                      reference: '',
                    });
                    return newValue;
                  });
                }}
              />
            </React.Fragment>
          </Form.Group>
        ))}
    </div>
  );
};

export const ThirdPartyReferenceInput = withTranslation()(
  ThirdPartyReferenceInputComponent
);
