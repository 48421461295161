import { SystemFeeReservationsHeader } from 'client/pages/v3/SystemFee/SystemFeeReservations/SystemFeeReservationsHeader';
import { SystemFeeReservationsBody } from 'client/pages/v3/SystemFee/SystemFeeReservations/SystemFeeReservationsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const SystemFeeReservations = () => {
  return (
    <V3Page>
      <SystemFeeReservationsHeader />
      <SystemFeeReservationsBody />
    </V3Page>
  );
};
