import { combineReducers } from 'redux';

import {
  FETCH_SURVEY_TEMPLATES_REQUEST,
  FETCH_SURVEY_TEMPLATES_SUCCESS,
  FETCH_SURVEY_TEMPLATES_FAILURE,
  FETCH_SURVEY_TEMPLATE_REQUEST,
  FETCH_SURVEY_TEMPLATE_SUCCESS,
  FETCH_SURVEY_TEMPLATE_FAILURE,
  CREATE_SURVEY_TEMPLATE_REQUEST,
  CREATE_SURVEY_TEMPLATE_SUCCESS,
  CREATE_SURVEY_TEMPLATE_FAILURE,
  UPDATE_SURVEY_TEMPLATE_REQUEST,
  UPDATE_SURVEY_TEMPLATE_SUCCESS,
  UPDATE_SURVEY_TEMPLATE_FAILURE,
  DELETE_SURVEY_TEMPLATE_REQUEST,
  DELETE_SURVEY_TEMPLATE_SUCCESS,
  DELETE_SURVEY_TEMPLATE_FAILURE,
} from 'client/constants/ActionTypes';
import type { SurveyTemplate } from 'shared/models/swagger';

// Reducers
const loading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_SURVEY_TEMPLATES_REQUEST:
    case FETCH_SURVEY_TEMPLATE_REQUEST:
    case CREATE_SURVEY_TEMPLATE_REQUEST:
    case UPDATE_SURVEY_TEMPLATE_REQUEST:
    case DELETE_SURVEY_TEMPLATE_REQUEST:
      return true;

    case FETCH_SURVEY_TEMPLATES_SUCCESS:
    case FETCH_SURVEY_TEMPLATES_FAILURE:
    case FETCH_SURVEY_TEMPLATE_SUCCESS:
    case FETCH_SURVEY_TEMPLATE_FAILURE:
    case CREATE_SURVEY_TEMPLATE_SUCCESS:
    case CREATE_SURVEY_TEMPLATE_FAILURE:
    case UPDATE_SURVEY_TEMPLATE_SUCCESS:
    case UPDATE_SURVEY_TEMPLATE_FAILURE:
    case DELETE_SURVEY_TEMPLATE_SUCCESS:
    case DELETE_SURVEY_TEMPLATE_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_SURVEY_TEMPLATES_FAILURE:
    case FETCH_SURVEY_TEMPLATE_FAILURE:
    case CREATE_SURVEY_TEMPLATE_FAILURE:
    case UPDATE_SURVEY_TEMPLATE_FAILURE:
    case DELETE_SURVEY_TEMPLATE_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const all = (state: SurveyTemplate[] = [], action: any): SurveyTemplate[] => {
  switch (action.type) {
    case FETCH_SURVEY_TEMPLATES_SUCCESS:
      return action.response.survey_templates;

    case CREATE_SURVEY_TEMPLATE_SUCCESS:
      return [...state, action.response];

    case UPDATE_SURVEY_TEMPLATE_SUCCESS:
      return state?.map((item: SurveyTemplate) =>
        item.id === action.response.id ? action.response : item
      );

    case FETCH_SURVEY_TEMPLATE_SUCCESS:
      if (state?.find((w: SurveyTemplate) => w?.id === action.response.id)) {
        return state?.map((item: SurveyTemplate) =>
          item.id === action.response.id ? action.response : item
        );
      } else {
        return [...state, action.response];
      }

    case DELETE_SURVEY_TEMPLATE_SUCCESS:
      return state?.filter((item: SurveyTemplate) => item.id !== action.id);

    default:
      return state;
  }
};

const lastCreated = (state = null, action: any): SurveyTemplate | null => {
  switch (action.type) {
    case CREATE_SURVEY_TEMPLATE_SUCCESS:
      return action.response;

    default:
      return state;
  }
};

export const surveyTemplates = combineReducers({
  all,
  loading,
  error,
  lastCreated,
});
