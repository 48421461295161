import useSWR from 'swr';
import Papa from 'papaparse';

import type { Customer } from 'shared/models/swagger';

const fetcher = async (url: string) => {
  if (!url) {
    return Promise.resolve('');
  }
  return fetch(url).then((res) => res.text());
};

export const useImportedCustomers = ({
  s3PresignedUrl,
}: {
  s3PresignedUrl?: string;
}): {
  importedCustomers: Customer[] | null;
  isImportedLoading: boolean;
  error: string;
} => {
  const { data, error } = useSWR([s3PresignedUrl], fetcher);

  let customers = null;
  if (data) {
    customers = Papa.parse<string>(data).data.map((row) => {
      if (row.length < 1) {
        return {};
      }
      if (row.length === 1) {
        return {
          email: row[0],
        };
      }
      const name = row[1].split(/\s+/);
      if (name.length < 2) {
        return {
          email: row[0],
          given_name: name[0],
        };
      }
      return {
        email: row[0],
        given_name: name[0],
        family_name: name.slice(1).join(' '),
      };
    });
  }

  return {
    importedCustomers: customers,
    isImportedLoading: Boolean(!error && !data && s3PresignedUrl),
    error: error,
  };
};
