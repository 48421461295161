import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  FETCH_DASHBOARD_SETTINGS_REQUEST,
  FETCH_DASHBOARD_SETTINGS_SUCCESS,
  FETCH_DASHBOARD_SETTINGS_FAILURE,
  PUT_DASHBOARD_SETTINGS_FAILURE,
  PUT_DASHBOARD_SETTINGS_REQUEST,
  PUT_DASHBOARD_SETTINGS_SUCCESS,
} from 'client/constants/ActionTypes';
import { DateRangePreset } from 'client/libraries/util/dateRangePresets';
import {
  BookingSourceType,
  ReservationColumn,
  ReservationStatus,
  SourceLanguage,
} from 'shared/models/swagger';

export interface DashboardSettings {
  gadgets: DashboardGadget[];
}

export interface DashboardSettings {
  gadgets: DashboardGadget[];
}

export type GadgetType =
  | 'access-report'
  | 'access-summary'
  | 'reservation-report'
  | 'reservation-summary'
  | 'reservation-list';

export type DashboardGadget =
  | {
      gadgetType: Extract<GadgetType, 'access-report'>;
      key: string;
      title: string;
      params: AccessReportGadgetParams;
    }
  | {
      gadgetType: Extract<GadgetType, 'access-summary'>;
      key: string;
      title: string;
      params: AccessSummaryGadgetParams;
    }
  | {
      gadgetType: Extract<GadgetType, 'reservation-report'>;
      key: string;
      title: string;
      params: ReservationReportGadgetParams;
    }
  | {
      gadgetType: Extract<GadgetType, 'reservation-summary'>;
      key: string;
      title: string;
      params: ReservationSummaryGadgetParams;
    }
  | {
      gadgetType: Extract<GadgetType, 'reservation-list'>;
      key: string;
      title: string;
      params: ReservationListGadgetParams;
    };

export type ReservationReportGadgetDataType =
  | 'RESERVATION_COUNT'
  | 'PARTICIPANT_COUNT'
  | 'SALES';
export type ReservationReportGadgetDisplayType =
  | 'LINE_CHART'
  | 'SOURCES_TABLE'
  | 'SOURCES_BAR_CHART'
  | 'PRODUCTS_TABLE'
  | 'PRODUCTS_BAR_CHART';

export interface ReservationReportGadgetParams {
  dataType: ReservationReportGadgetDataType;
  displayType: ReservationReportGadgetDisplayType;
  dateType: 'BOOKING_DATE' | 'PARTICIPATION_DATE';
  dateRange: DateRangePreset;
  productIds?: string[];
  aggregationType?: 'DAILY' | 'WEEKLY' | 'MONTHLY'; // Only used when displayType is LINE_CHART
}

export type AccessReportGadgetDataType =
  | 'PAGE_VIEWS'
  | 'USERS'
  | 'RESERVATIONS';

export type AccessReportGadgetDisplayType =
  | 'BAR_CHART'
  | 'PRODUCTS_TABLE'
  | 'LANDING_SOURCES_TABLE';

export interface AccessReportGadgetParams {
  dataType: AccessReportGadgetDataType;
  displayType: AccessReportGadgetDisplayType;
  dateRange: Extract<
    DateRangePreset,
    'PREV_7_DAYS' | 'PREV_28_DAYS' | 'PREV_3_MONTHS'
  >;
  aggregationType?: 'DAILY' | 'WEEKLY' | 'MONTHLY'; // Only used when displayType is BAR_CHART
}

export interface AccessSummaryGadgetParams {
  dateRange: Extract<
    DateRangePreset,
    'PREV_7_DAYS' | 'PREV_28_DAYS' | 'PREV_3_MONTHS'
  >;
}

export type ReservationSearchPreset =
  | 'BOOKED_YESTERDAY'
  | 'PARTICIPATION_TODAY'
  | 'PARTICIPATION_TOMORROW'
  | 'CUSTOM';

export interface ReservationSummaryGadgetParams {
  searchPreset: ReservationSearchPreset;
  orderBy: string;
  statuses: ReservationStatus[];
  dateType: 'BOOKED_DATE' | 'PARTICIPATION_DATE';
  targetDateType: 'CURRENT_DATE' | 'PREVIOUS_DATE' | 'NEXT_DATE' | 'RANGE';
  // dateRangeType & dateRange are only used when targetDateType is 'RANGE'
  dateRangeType?: 'NEXT' | 'PREV';
  dateRange?: number;
  productIds?: string[];
  bookingSources?: BookingSourceType[];
  agents?: string[];
  languages?: SourceLanguage[];
  defaultColumns?: string[];
}

export type ReservationListSearchPreset =
  | ReservationSearchPreset
  | 'CONFIRMATION_REQUIRED';
// | 'UNSETTLED_EMAIL_PAYMENT' TODO: to be implemented later

export interface ReservationListGadgetParams {
  searchPreset: ReservationListSearchPreset;
  orderBy: string;
  statuses: ReservationStatus[];
  dateType: 'BOOKED_DATE' | 'PARTICIPATION_DATE';
  targetDateType: 'CURRENT_DATE' | 'PREVIOUS_DATE' | 'NEXT_DATE' | 'RANGE';
  // dateRangeType, dateRange & shouldSetAsIndefinite are only used when targetDateType is 'RANGE'
  dateRangeType?: 'NEXT' | 'PREV';
  dateRange?: number;
  shouldSetAsIndefinite?: boolean; // Use this when we wish to set indefinite 'from date' or 'to date'
  productIds?: string[];
  bookingSources?: BookingSourceType[];
  agents?: string[];
  languages?: SourceLanguage[];
  defaultColumns?: ReservationColumn[];
  shouldGroupByStartTime?: boolean;
  shouldGroupByProduct?: boolean;
}

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_DASHBOARD_SETTINGS_REQUEST:
    case PUT_DASHBOARD_SETTINGS_REQUEST:
      return '';

    case FETCH_DASHBOARD_SETTINGS_FAILURE:
    case PUT_DASHBOARD_SETTINGS_FAILURE:
      return action.error;

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_DASHBOARD_SETTINGS_REQUEST:
      return true;

    case FETCH_DASHBOARD_SETTINGS_FAILURE:
    case FETCH_DASHBOARD_SETTINGS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const rawData = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_DASHBOARD_SETTINGS_SUCCESS:
    case PUT_DASHBOARD_SETTINGS_SUCCESS: {
      return action.response?.data ?? '';
    }

    case FETCH_DASHBOARD_SETTINGS_FAILURE:
      return '';

    default:
      return state;
  }
};

const data = (
  state: DashboardSettings | null = null,
  action: any
): DashboardSettings | null => {
  switch (action.type) {
    case FETCH_DASHBOARD_SETTINGS_SUCCESS:
    case PUT_DASHBOARD_SETTINGS_SUCCESS: {
      return JSON.parse(action.response.data);
    }

    case FETCH_DASHBOARD_SETTINGS_FAILURE:
      return null;

    default:
      return state;
  }
};

export const dashboardSettings = combineReducers({
  error,
  loading,
  rawData,
  data,
});
