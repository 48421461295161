import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import type { Moment } from 'moment-timezone';

import {
  filteredStatuses,
  reservationIsCheckinCheckoutOnly,
  getLastSupplierActionSource,
  getLastAgentOrGuestActionSource,
  getCompanyAndPersonNameFromActionSrouce,
  getHotelDisplay,
  getTransportationDisplay,
  getGuestDescriptionDisplay,
} from 'client/libraries/util/util';
import { getGuestName } from 'client/libraries/util/getGuestName';
import { getAddOnsSummary } from 'client/libraries/util/getAddOnsSummary';
import { getFormattedBookingSource } from 'client/libraries/util/getFormattedBookingSource';
import type { TranslateFuncType } from 'client/components/Translate';
import type { ReduxState } from 'client/reducers';
import type {
  Reservation,
  ReservationStatus,
  PackageComponentReservationSummary,
} from 'shared/models/swagger';

export type ReservationSummaryShape = {
  id: string;
  agent_notes: string;
  booking_source: string;
  supplier_notes: string;
  participates_at: Moment;
  add_ons: string;
  transportation: string;
  status: ReservationStatus;
  guest_description: string;
  guest_display_name: string;
  product_name: string;
  product_id: string;
  product_instance_id: string;
  hotel: string;
  pickup_checkin_location: string;
  pickup_checkin_time: string;
  agent_reference: string;
  supplier_reference: string;
  supplier_short_reference: string;
  supplier_product_reference: string;
  supplier_name: string;
  payment_type: string;
  payment_method: string;
  booked_at: Moment;
  guest_count: number;
  updated_at: Moment;
  supplier_last_updated_by_actor_name_only: string;
  agent_guest_last_updated_by_actor_name_only: string;
  package_component_reservation_summaries: PackageComponentReservationSummary[];
  uses_email_payment: 'yes' | 'no';
  promo_code: string;
  phone: string;
  email: string;
  internal_product_name?: string;
  supplier_internal_notes?: string;
  supplier_internal_notes_for_dispatch?: string;
  reservation_language: string;
  equipment_block_references?: string[];
  equipment_ticket_printed?: boolean;
  equipment_instance_memo?: string;
  eticket_status?: string;
  eticket_first_footprinted_at?: Moment;
  eticket_last_footprinted_at?: Moment;
  eticket_footprint_day_count?: number;
  eticket_expire_at?: Moment;
  automatic_continuing_status?: boolean;
  landing_source?: string;
};

const allReservationsSelector = (state: ReduxState) =>
  state.reservations.summaries;

const languageSelector = (state: ReduxState) => state.language.selected.iso;

const tSelector = (_: any, props: any) => props.t;

export const reservationSummariesSelector = createSelector(
  allReservationsSelector,
  languageSelector,
  tSelector,
  (reservations, language, t) =>
    reservations.map((r) => toReservationSummaryShape(r, language, t))
);
export const manifestReservationSummariesSelector = createSelector(
  (state: ReduxState) => state.manifests.reservations,
  languageSelector,
  tSelector,
  (reservations, language, t) =>
    reservations.map((r: any) => toReservationSummaryShape(r, language, t))
);
export const toReservationSummaryShape = (
  reservation: Reservation,
  language: string,
  t: TranslateFuncType
): ReservationSummaryShape => {
  const {
    id,
    status_history,
    agent_reference,
    supplier_reference,
    supplier_short_reference,
    supplier_product_reference,
    start_date_time_utc,
    payment_type,
    payment_method,
    guests,
    start_timezone,
    agent_notes,
    supplier_notes,
    pickup,
    checkin,
    last_updated_date_time_utc,
    product_name,
    supplier_name,
    payment_deferred,
    product_id,
    product_instance_id,
    promo_code,
    package_component_reservation_summaries,
    internal_product_name,
    supplier_internal_notes,
    supplier_internal_notes_for_dispatch,
    equipment_block_references,
    equipment_ticket_printed,
    equipment_instance_memo,
    checkin_info,
    footprint_info,
    should_auto_renew_annual_pass,
    landing_sid,
    landing_referrer,
  } = reservation;
  const booked_at = moment(status_history[0].status_date_time_utc);
  const statuses = filteredStatuses(status_history);
  const status =
    (statuses.length > 0 && statuses[statuses.length - 1].status) || 'UNKNOWN';
  const participates_at = moment.tz(start_date_time_utc, start_timezone ?? '');
  const updated_at = moment(last_updated_date_time_utc);
  const guest_display_name = getGuestName(reservation);
  const guest_count = guests.length;
  const guest_description = getGuestDescriptionDisplay(reservation);

  const hotel = getHotelDisplay(reservation, t);
  const transportation = getTransportationDisplay(reservation, t) || '';

  const location = !reservationIsCheckinCheckoutOnly(reservation)
    ? pickup
    : checkin;
  const pickup_checkin_location = (location && location.location_name) || '';
  const locationDateTime = location && location.date_time_utc;
  const pickup_checkin_time = locationDateTime
    ? moment
        .tz(locationDateTime, reservation.start_timezone ?? '')
        .locale(language)
        .format('LT')
    : '';
  const add_ons = getAddOnsSummary(reservation);
  const bookingSource = getFormattedBookingSource(reservation, t);
  const lastSupplierActionSrouce = getLastSupplierActionSource(status_history);
  const lastSupplierCompanyAndPersonName = lastSupplierActionSrouce
    ? getCompanyAndPersonNameFromActionSrouce(lastSupplierActionSrouce)
    : '';
  const lastAgentOrGuestActionSrouce =
    getLastAgentOrGuestActionSource(status_history);
  const lastAgentOrGuestCompanyAndPersonName = lastAgentOrGuestActionSrouce
    ? getCompanyAndPersonNameFromActionSrouce(lastAgentOrGuestActionSrouce)
    : '';
  const reservationEmail = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;
  const phone =
    reservation?.field_responses?.find(
      (fieldResponse) => fieldResponse.key === 'international_phone'
    )?.response ||
    reservation?.field_responses?.find(
      (fieldResponse) => fieldResponse.key === 'phone'
    )?.response ||
    '';
  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;

  const eticket_status = checkin_info?.checkin_status || undefined;
  const eticket_first_footprinted_at =
    footprint_info?.first_footprint_date_time_utc
      ? moment(footprint_info.first_footprint_date_time_utc)
      : undefined;
  const eticket_last_footprinted_at =
    footprint_info?.last_footprint_date_time_utc
      ? moment(footprint_info.last_footprint_date_time_utc)
      : undefined;
  const eticket_footprint_day_count =
    footprint_info?.footprint_day_count || undefined;
  const eticket_expire_at = checkin_info?.expiration_date_time_utc
    ? moment(checkin_info?.expiration_date_time_utc)
    : undefined;

  return {
    id,
    agent_notes: agent_notes || '',
    booking_source: bookingSource,
    supplier_notes: supplier_notes || '',
    participates_at,
    add_ons,
    transportation,
    status,
    guest_description,
    guest_display_name,
    product_name: product_name || '',
    product_id: product_id || '',
    product_instance_id: product_instance_id || '',
    hotel,
    pickup_checkin_location,
    pickup_checkin_time,
    agent_reference: agent_reference || '',
    supplier_reference: supplier_reference || '',
    supplier_short_reference: supplier_short_reference || '',
    supplier_product_reference: supplier_product_reference || '',
    supplier_name: supplier_name || '',
    payment_type: payment_type || '',
    payment_method: payment_method || '',
    booked_at,
    guest_count,
    updated_at,
    supplier_last_updated_by_actor_name_only: lastSupplierCompanyAndPersonName,
    agent_guest_last_updated_by_actor_name_only:
      lastAgentOrGuestCompanyAndPersonName,
    uses_email_payment: payment_deferred ? 'yes' : 'no',
    promo_code: promo_code ?? '',
    package_component_reservation_summaries:
      package_component_reservation_summaries ?? [],
    email: reservationEmail ?? '',
    phone,
    internal_product_name,
    supplier_internal_notes,
    supplier_internal_notes_for_dispatch,
    reservation_language: reservationLanguage ?? '',
    equipment_block_references,
    equipment_ticket_printed,
    equipment_instance_memo,
    eticket_status,
    eticket_first_footprinted_at,
    eticket_last_footprinted_at,
    eticket_footprint_day_count,
    eticket_expire_at,
    automatic_continuing_status: should_auto_renew_annual_pass?.value ?? false,
    landing_source: landing_sid || landing_referrer || undefined,
  };
};
