import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_NEWSLETTER_FAILURE,
  CREATE_NEWSLETTER_REQUEST,
  CREATE_NEWSLETTER_SUCCESS,
  DELETE_NEWSLETTER_FAILURE,
  DELETE_NEWSLETTER_REQUEST,
  DELETE_NEWSLETTER_SUCCESS,
  FETCH_NEWSLETTERS_FAILURE,
  FETCH_NEWSLETTERS_REQUEST,
  FETCH_NEWSLETTERS_SUCCESS,
  FETCH_NEWSLETTER_FAILURE,
  FETCH_NEWSLETTER_REQUEST,
  FETCH_NEWSLETTER_SUCCESS,
  UPDATE_NEWSLETTER_FAILURE,
  UPDATE_NEWSLETTER_REQUEST,
  UPDATE_NEWSLETTER_SUCCESS,
  FETCH_NEWSLETTERS_CSV_FAILURE,
  FETCH_NEWSLETTERS_CSV_REQUEST,
  FETCH_NEWSLETTERS_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type {
  Newsletter,
  CreateNewsletterResponse,
  ListNewslettersResponse,
  UpdateNewsletterResponse,
} from 'shared/models/swagger';

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_NEWSLETTERS_FAILURE:
    case FETCH_NEWSLETTER_FAILURE:
    case CREATE_NEWSLETTER_FAILURE:
    case UPDATE_NEWSLETTER_FAILURE:
    case DELETE_NEWSLETTER_FAILURE:
      return action.error;

    case FETCH_NEWSLETTERS_SUCCESS:
    case FETCH_NEWSLETTER_SUCCESS:
    case CREATE_NEWSLETTER_SUCCESS:
    case UPDATE_NEWSLETTER_SUCCESS:
    case DELETE_NEWSLETTER_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_NEWSLETTERS_REQUEST:
    case FETCH_NEWSLETTER_REQUEST:
    case CREATE_NEWSLETTER_REQUEST:
    case UPDATE_NEWSLETTER_REQUEST:
    case DELETE_NEWSLETTER_REQUEST:
      return true;

    case FETCH_NEWSLETTERS_FAILURE:
    case FETCH_NEWSLETTER_FAILURE:
    case CREATE_NEWSLETTER_FAILURE:
    case UPDATE_NEWSLETTER_FAILURE:
    case DELETE_NEWSLETTER_FAILURE:
    case FETCH_NEWSLETTERS_SUCCESS:
    case FETCH_NEWSLETTER_SUCCESS:
    case CREATE_NEWSLETTER_SUCCESS:
    case UPDATE_NEWSLETTER_SUCCESS:
    case DELETE_NEWSLETTER_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: Newsletter[] = [], action: any): Newsletter[] => {
  switch (action.type) {
    case FETCH_NEWSLETTERS_SUCCESS: {
      const response = action.response as ListNewslettersResponse;
      return response.newsletters;
    }

    case FETCH_NEWSLETTER_SUCCESS: {
      const response = action.response as Newsletter;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case CREATE_NEWSLETTER_SUCCESS: {
      const response = action.response as CreateNewsletterResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_NEWSLETTER_SUCCESS: {
      const response = action.response as UpdateNewsletterResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_NEWSLETTER_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

const lastCreatedNewsletter = (
  state: Newsletter | null = null,
  action: any
): Newsletter | null => {
  switch (action.type) {
    case CREATE_NEWSLETTER_SUCCESS: {
      const response = action.response as CreateNewsletterResponse;
      return response;
    }
    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_NEWSLETTERS_CSV_REQUEST:
      return true;

    case FETCH_NEWSLETTERS_CSV_SUCCESS:
    case FETCH_NEWSLETTERS_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

export const newsletters = combineReducers({
  error,
  csvLoading,
  lastCreatedNewsletter,
  loading,
  all,
});
