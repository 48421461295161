import clsx from 'clsx';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import styles from 'client/base.module.css';

interface Props {
  error?: string;
  label?: string | React.ReactElement;
  tooltipText?: string;
  onChange: (
    e: React.SyntheticEvent<Record<string, any>>,
    arg1: {
      value: number;
    }
  ) => void;
  value: number;
  disabled?: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  min?: number;
  max?: number;
}

export const NumberInput = ({
  error,
  label,
  tooltipText,
  onChange,
  value,
  disabled,
  width,
  minWidth,
  maxWidth,
  min,
  max,
  ...inputProps
}: Props) => {
  const style = {
    width,
    minWidth,
    maxWidth,
  };

  return (
    <div className={styles['base-form-box']}>
      {label && (
        <div
          className={clsx(
            styles['base-form-box__header'],
            error ? styles['error'] : ''
          )}
        >
          {label}

          {tooltipText && <Tooltip text={tooltipText} />}
        </div>
      )}
      <div className={styles['base-form-box__body']}>
        <label className={clsx(styles['base-form-number'])}>
          <input
            type="number"
            step="1"
            value={value}
            min={min}
            max={max}
            disabled={disabled}
            style={style}
            onChange={(e) => {
              onChange(e, {
                value: parseInt(e.target.value, 10),
              });
            }}
            {...inputProps}
          />
          <span
            className={clsx(styles['down'])}
            onClick={(e) => {
              if (min !== null && min !== undefined && value <= min) {
                return;
              }
              onChange(e, {
                value: value - 1,
              });
            }}
          >
            -
          </span>
          <span
            className={clsx(styles['up'])}
            onClick={(e) => {
              if (max !== null && max !== undefined && value >= max) {
                return;
              }
              onChange(e, {
                value: value + 1,
              });
            }}
          >
            +
          </span>
        </label>
        {error && <p className={styles['base-form-box__err']}>{error}</p>}
      </div>
    </div>
  );
};
