import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';

import { config } from 'client/config';
import { EditDeleteButtonDndList } from 'client/components/EditDeleteButtonDndList/EditDeleteButtonDndList';
import { Divider } from 'client/components/Divider/Divider';
import { EditCustomPageModal } from 'client/pages/EssentialPages/CustomPages/EditCustomPageModal';
import { DeleteCustomPageModal } from 'client/pages/EssentialPages/CustomPages/DeleteCustomPageModal';
import { customPagesSelector } from 'client/reducers/customPages';
import {
  batchUpdateCustomPages,
  fetchCustomPages,
} from 'client/actions/customPage';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Message } from 'client/components/Message/Message';
import { Button } from 'client/components/Form';
import type { ReduxState } from 'client/reducers';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import type { CustomPage, SourceLanguage } from 'shared/models/swagger';

type Props = {
  isActive?: boolean;
  type: 'NORMAL' | 'NEWS';
  contentLanguage: SourceLanguage | null;
};
const style = {
  backgroundColor: '#F9F9F9',
  cursor: 'move',
};
export const CustomPageList = ({ isActive, type, contentLanguage }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customPages = useSelector(customPagesSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<
    {
      key: string;
      text: string;
    }[]
  >([]);
  const [editCustomPage, setEditCustomPage] = React.useState<CustomPage | null>(
    null
  );
  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);
  const [orderUpdated, setOrderUpdated] = React.useState<boolean>(false);
  const [orderUpdateSucceeded, setOrderUpdateSucceeded] =
    React.useState<boolean>(false);
  const submitError = useSelector(
    (state: ReduxState) => state.customPages.error
  );
  const [deleteCustomPage, setDeleteCustomPage] =
    React.useState<CustomPage | null>(null);
  React.useEffect(() => {
    dispatch(fetchCustomPages());
  }, [activeUserOrganization]);
  React.useEffect(() => {
    const items = (customPages || [])
      .filter((customPage) => {
        return customPage.content_language === contentLanguage;
      })
      .filter((customPage) => {
        return customPage.type === type;
      })
      .sort((a, b) => {
        return a.sort_order - b.sort_order;
      })
      .map((customPage) => {
        return {
          key: customPage.id,
          text: customPage.title,
        };
      });
    setItems(items);
  }, [customPages, contentLanguage, type]);
  React.useEffect(() => {
    if (orderUpdated) {
      setOrderChanged(false);
      setOrderUpdated(false);
      setOrderUpdateSucceeded(true);
    }
  }, [customPages]);
  React.useEffect(() => {
    if (submitError) {
      setOrderUpdated(false);
    }
  }, [submitError]);

  // Invoke custom page remove modal
  const onRemove = (index: number) => {
    const target = items[index];
    const customPage = customPages.find(
      (customPage) => customPage.id === target.key
    );

    if (customPage) {
      setDeleteCustomPage(customPage);
      setShowDeleteModal(true);
    }
  };

  // Invoke custom page editor
  const onEdit = (index: number) => {
    const target = items[index];
    const customPage = customPages.find(
      (customPage) => customPage.id === target.key
    );

    if (customPage) {
      setEditCustomPage(customPage);
      setShowEditModal(true);
    }
  };

  // Update sort_order only
  const onOrderChange = (
    items: {
      key: string;
      text: string;
    }[]
  ) => {
    setItems(items);
    setOrderChanged(true);
    setOrderUpdated(false);
    setOrderUpdateSucceeded(false);
  };

  const onOrderSave = () => {
    const newCustomPages = items.map((item, idx) => {
      return {
        id: item.key,
        sort_order: idx + 1,
      };
    });

    if (newCustomPages) {
      setOrderUpdated(true);
      dispatch(batchUpdateCustomPages(newCustomPages));
    }
  };

  if (
    config.enableHPFeatureImprovements &&
    contentLanguage !== null &&
    showEditModal
  ) {
    return (
      <Redirect
        push
        to={{
          pathname: `/bookingWidget/homepages/editcustompage`,
          state: {
            contentLanguage: contentLanguage,
            type: type,
            id: editCustomPage?.id || '',
          },
        }}
      />
    );
  }

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        isActive ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Custom Page')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <a
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['small'],
              baseStyles['flex'],
              baseStyles['green']
            )}
            onClick={() => {
              setEditCustomPage(null);
              setShowEditModal(true);
            }}
          >
            {t('Add New Custom Page')}
          </a>
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div>
          {!config.enableHPFeatureImprovements &&
            contentLanguage !== null &&
            showEditModal && (
              <EditCustomPageModal
                title={
                  editCustomPage
                    ? t('Edit Custom Page')
                    : t('Create New Custom Page')
                }
                open={showEditModal}
                onClose={() => {
                  setShowEditModal(false);
                }}
                editCustomPage={editCustomPage}
                type={type}
                contentLanguage={contentLanguage}
                customPages={customPages}
              />
            )}
          {showDeleteModal && (
            <DeleteCustomPageModal
              open={showDeleteModal}
              onClose={() => {
                setShowDeleteModal(false);
              }}
              customPage={deleteCustomPage}
            />
          )}
          {type === 'NORMAL' && (
            <>
              <h4>{t('Fixed Pages')}</h4>
              <div
                className={clsx(baseStyles['base-form-checkbox'])}
                style={{ ...style }}
              >
                <label>{t('About Us')}</label>
              </div>
              <div
                className={clsx(baseStyles['base-form-checkbox'])}
                style={{ ...style }}
              >
                <label>{t('FAQ')}</label>
              </div>
              <div
                className={clsx(baseStyles['base-form-checkbox'])}
                style={{ ...style }}
              >
                <label>{t('COVID-19 Guidelines')}</label>
              </div>
              <div
                className={clsx(baseStyles['base-form-checkbox'])}
                style={{ ...style }}
              >
                <label>{t('NEWS')}</label>
              </div>
              <Divider />
              <h4>{t('Additional Pages')}</h4>
            </>
          )}

          <EditDeleteButtonDndList
            items={items}
            setItems={(items) => {
              onOrderChange(items);
            }}
            onRemove={onRemove}
            onEdit={onEdit}
          />
        </div>

        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          {submitError && (
            <p className={baseStyles['base-form-box__err']}>{submitError}</p>
          )}
          <Button
            type="submit"
            style="blue"
            size="small"
            disabled={!orderChanged}
            loading={orderUpdated}
            onClick={() => {
              onOrderSave();
            }}
          >
            {t('Save Order')}
          </Button>
        </div>

        {orderUpdateSucceeded && <Message success header={t('Order Saved')} />}
      </div>
    </div>
  );
};
