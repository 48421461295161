import * as Swagger from 'shared/models/swagger';

export interface TranslationFormItem {
  source: string;
  target: string;
}

export interface FormValues {
  title: string;
  description: string;
  showTranslationMode: boolean;
  targetTranslationLanguage?: 'EN_US' | 'JA_JP';
  translations: TranslationFormItem[];
  thumbnailUrl: string;
}

export const getInitialValues = (
  guidanceCoupon?: Swagger.GuidanceCoupon
): FormValues => {
  return {
    title: guidanceCoupon?.title ?? '',
    description: guidanceCoupon?.description ?? '',
    showTranslationMode: false,
    translations: [],
    thumbnailUrl: guidanceCoupon?.thumbnail_url ?? '',
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.GuidanceCouponParams => {
  return {
    title: values.title,
    description: values.description,
    thumbnail_url: values.thumbnailUrl,
  };
};
