import type { LocationWithTime } from 'shared/models/swagger';

export const findClosestPickupLocation = (
  lat: number,
  lng: number,
  pickupLocations: LocationWithTime[]
): LocationWithTime | null => {
  if (!window.google.maps) {
    return null;
  }

  let minDistance = Number.MAX_VALUE;
  let closestLocation: LocationWithTime | null = null;
  const origin = new window.google.maps.LatLng(lat, lng);
  pickupLocations.forEach((loc) => {
    if (!loc.latitude || !loc.longitude) {
      return;
    }

    const distanceToLocation =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        origin,
        new window.google.maps.LatLng(loc.latitude, loc.longitude)
      );

    if (distanceToLocation < minDistance && distanceToLocation < 1000) {
      closestLocation = loc;
      minDistance = distanceToLocation;
    }
  });
  return closestLocation;
};
