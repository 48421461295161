import { BookingWidgetCustomizeHeader } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomizeHeader';
import { BookingWidgetCustomizeSettings } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomizeSettings';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const BookingWidgetCustomize = () => {
  return (
    <V3Page>
      <BookingWidgetCustomizeHeader />
      <BookingWidgetCustomizeSettings />
    </V3Page>
  );
};
