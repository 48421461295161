import * as React from 'react';

import { currency } from 'shared/libraries/currency';
import { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';

import { Input } from './Input';
import styles from './Form.module.css';

type Props = {
  currencyCode: string;
  moneyAmount: string;
  onChange: (arg0: string) => void;
};
export const MoneyInput = ({ currencyCode, moneyAmount, onChange }: Props) => {
  const currencyInputHelper = new CurrencyAmountInputHelper(currencyCode);
  const amount = moneyAmount.length > 3 ? moneyAmount.substring(3) : '';
  return (
    <div className={styles['money-input']}>
      <p>{currencyCode}</p>
      <Input
        value={amount}
        onChange={(e, { value }) => {
          if (!currencyInputHelper.inputAllowed(value)) {
            return;
          }

          const newMoneyAmount = `${currencyCode}${value}`;

          // Reject input if it has too many decimal places.
          if (
            value &&
            currency(newMoneyAmount).format().length <
              value.length + currencyCode.length
          ) {
            return;
          }

          if (value) {
            onChange(newMoneyAmount);
          } else {
            onChange('');
          }
        }}
      />
    </div>
  );
};
