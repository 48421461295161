import { useCallback } from 'react';
import clsx from 'clsx';

import styles from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList.module.css';

import { UnselectedItem } from './UnselectedItem';
import { SelectedItem } from './SelectedItem';
import { FixedItem } from './FixedItem';

type Item<T> = {
  key: T;
  text: string;
};
type Props<T> = {
  dragItemType?: string;
  fixedItems?: Item<T>[];
  selectedItems: Item<T>[];
  candidateItems: Item<T>[];
  setSelectedItems: (items: Item<T>[]) => void;
  toggleDisabledKeys?: string[];
  doNotUseOuterBox?: boolean;
};
export const ToggleableDragAndDropList = ({
  fixedItems,
  selectedItems,
  candidateItems,
  setSelectedItems,
  dragItemType,
  toggleDisabledKeys,
  doNotUseOuterBox = false,
}: Props<any>) => {
  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = selectedItems[dragIndex];
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(dragIndex, 1);
      newSelectedItems.splice(hoverIndex, 0, dragItem);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, setSelectedItems]
  );

  const unselect = (itemToUnselect: Item<any>) => {
    setSelectedItems(
      selectedItems.filter((item) => item.key !== itemToUnselect.key)
    );
  };

  const select = (itemToSelect: Item<any>) => {
    setSelectedItems([...selectedItems, itemToSelect]);
  };

  const unselectedItems = candidateItems.filter(
    (item) =>
      !selectedItems.find((i) => i.key === item.key) &&
      !fixedItems?.find((i) => i.key === item.key)
  );
  return (
    <ul
      className={clsx(
        styles['c-toggleableList'],
        doNotUseOuterBox && styles['noOuterBox']
      )}
    >
      {fixedItems?.map((item) => (
        <FixedItem key={item.key} text={item.text} />
      ))}
      {selectedItems.map((item, idx) => (
        <SelectedItem
          dragItemType={dragItemType}
          key={item.key}
          text={item.text}
          index={idx}
          id={item}
          moveItem={moveItem}
          toggle={() => unselect(item)}
          disabled={Boolean(toggleDisabledKeys?.includes(item.key))}
        />
      ))}
      {unselectedItems.map((item) => (
        <UnselectedItem
          key={item.key}
          text={item.text}
          toggle={() => select(item)}
        />
      ))}
    </ul>
  );
};
