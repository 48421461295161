import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { getArrayMutators } from 'client/libraries/util/form';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Modal } from 'client/components/v3/Form/Modal';
import baseStyles from 'client/v3-base.module.css';

export type FormValues = {
  name: string;
};
type Props = {
  initialName?: string;
  open: boolean;
  onClose?: () => void;
  onActionStart: (name: string) => void;
  title: string;
  buttonText: string;
};

export const InputActionOwnerNameModal = ({
  initialName,
  open,
  onClose,
  onActionStart,
  title,
  buttonText,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        try {
          onActionStart(values.name);
          onClose?.();
        } catch (err) {
          console.log(err);
          return {
            [FORM_ERROR]: t('error'),
          };
        }
      }}
      initialValues={{ name: initialName }}
      mutators={getArrayMutators()}
    >
      {({ handleSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              title={title}
              open={open}
              onClose={() => {
                onClose && onClose();
              }}
              useCloseButton={true}
              rightActionChildren={
                <>
                  <Button
                    text={t('Cancel')}
                    uiType="bg"
                    size="md"
                    color="white"
                    onClick={() => {
                      onClose?.();
                    }}
                  />
                  <Button
                    type="submit"
                    text={buttonText}
                    uiType="bg"
                    size="md"
                    color="primary"
                  />
                </>
              }
              style={{ width: '600px', height: '280px' }}
            >
              <Field
                name={'name'}
                validate={(name) => (name ? undefined : t('Required'))}
              >
                {({ input, meta: { touched, error } }) => (
                  <TextField
                    {...input}
                    label={t('Your Name')}
                    error={touched && error}
                  />
                )}
              </Field>
              {submitError && (
                <p className={baseStyles['u-error-msg']}>{submitError}</p>
              )}
            </Modal>
          </form>
        );
      }}
    </Form>
  );
};
