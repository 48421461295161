import * as React from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Select,
  TextArea,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import * as Swagger from 'shared/models/swagger';

import { TranslatedInputContainer } from './TranslatedInputContainer';
import { ResponseOptionsEditor } from './ResponseOptionsEditor';
import { FormValues, getQuestionPresetOptions, SurveyPage } from './formValues';

interface Props {
  initialName: string;
  onClose: () => void;
}

export const EditSurveyQuestionModal = ({ initialName, onClose }: Props) => {
  const { t } = useTranslation();
  const [name] = React.useState(initialName);
  const form = useForm();
  const responseType = _.get(form.getState().values, name)?.responseType;
  const questionKey = _.get(form.getState().values, name)?.key;
  const { translationTargetLanguage, translationTargetLanguageName } =
    useTranslationTargetLanguage(t);
  const { values } = useFormState<FormValues>();

  const sourceLanguage: Swagger.SourceLanguage =
    form.getState().values?.sourceLanguage ?? 'EN_US';
  const sourceLanguageLowercaseIso = uppercaseIsoToLowercaseIso[sourceLanguage];

  const questionPresetOptions = getQuestionPresetOptions((s: string) =>
    t(s, { lng: sourceLanguageLowercaseIso })
  );

  const otherQuestionKeys =
    _.get(form.getState().values, 'pages')
      ?.flatMap(
        (page: SurveyPage) =>
          page.surveyQuestions?.map((question) => question.key) ?? []
      )
      ?.filter((key: string) => key !== questionKey) ?? [];

  React.useEffect(() => {
    const initialValue = _.get(form.getState().values, name);

    // Set key for the question if it doesn't exist
    if (!initialValue?.key) {
      form.change(`${name}.key`, uuid());
    }
    // Set preset for the question if it doesn't exist
    if (!initialValue?.preset) {
      form.change(`${name}.preset`, 'custom');
    }
    if (!initialValue?.responseType) {
      form.change(`${name}.responseType`, 'SURVEY_QUESTION_TEXT');
    }
  }, [name]);

  let responseTypeOptions: {
    value: Swagger.SurveyQuestionResponseType;
    label: string;
  }[] = [
    {
      value: 'SURVEY_QUESTION_STAR_RATING',
      label: t('Star Rating'),
    },
    {
      value: 'SURVEY_QUESTION_TEXT',
      label: t('Text (single line)'),
    },
    {
      value: 'SURVEY_QUESTION_TEXT_AREA',
      label: t('Text (paragraph)'),
    },
    { value: 'SURVEY_QUESTION_SELECT_ONE', label: t('Select One') },
    { value: 'SURVEY_QUESTION_SELECT_MULTIPLE', label: t('Select Multiple') },
  ];
  const isNpsSurvey =
    values.surveyType === 'NPS' &&
    values.pages &&
    values.pages[0].surveyQuestions?.length === 3;
  if (isNpsSurvey) {
    if (name === 'pages[0].surveyQuestions[0]') {
      responseTypeOptions = [
        {
          value: 'SURVEY_QUESTION_STAR_RATING_NPS',
          label: t('Star Rating NPS'),
        },
      ];
    }
    if (name === 'pages[0].surveyQuestions[1]') {
      responseTypeOptions = [
        {
          value: 'SURVEY_QUESTION_SELECT_MULTIPLE',
          label: t('Select Multiple'),
        },
      ];
    }
    if (name === 'pages[0].surveyQuestions[2]') {
      responseTypeOptions = [
        {
          value: 'SURVEY_QUESTION_TEXT_AREA',
          label: t('Text (paragraph)'),
        },
      ];
    }
  }

  const formatOptions = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: 'alpha-name',
      text: t('Alphabet characters or spaces'),
    },
    {
      value: 'kana-name',
      text: t('Kana characters or full-width spaces'),
    },
    {
      value: 'alpha-kana-name',
      text: t('Alphabet characters or spaces form & Kana form'),
    },
    {
      value: 'email',
      text: t('Email'),
    },
    {
      value: 'phone',
      text: t('Phone'),
    },
    {
      value: 'international_phone',
      text: t('International Phone Number'),
    },
    {
      value: 'float',
      text: t('Numeric'),
    },
    {
      value: 'non-negative-integer',
      text: t('Positive Numeric'),
    },
    {
      value: 'yyyy-mm-dd',
      text: t('YYYY-MM-DD'),
    },
  ];

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t('Edit Survey Question')}
      insertRoot
    >
      <Modal.Content>
        <Field name={`${name}.preset`}>
          {({ input }) => (
            <Select
              label={t('Question')}
              value={input.value}
              onChange={(e, { value }) => {
                e.preventDefault();
                e.stopPropagation();

                if (value === 'custom') {
                  form.change(name, {
                    preset: 'custom',
                    key: uuid(),
                    text: '',
                    responseType: 'SURVEY_QUESTION_TEXT',
                    format: '',
                    options: [],
                  });
                } else {
                  const presetOption = questionPresetOptions.find(
                    (option) => option.value === value
                  );

                  form.change(name, {
                    preset: value,
                    key: value,
                    text: presetOption?.text ?? '',
                    responseType:
                      presetOption?.responseType ?? 'SURVEY_QUESTION_TEXT',
                    format: presetOption?.format ?? '',
                    options: presetOption?.choices ?? [],
                  });
                }
              }}
              options={[
                ...questionPresetOptions
                  .filter((option) => !otherQuestionKeys.includes(option.value))
                  .map((option) => ({
                    value: option.value,
                    text: option.text,
                  })),
                {
                  value: 'custom',
                  text: t('Custom'),
                },
              ]}
              disabled={values.surveyType === 'NPS'}
            />
          )}
        </Field>
        <TranslatedField name={`${name}.text`}>
          {({ input, translationInput }) => (
            <TranslatedInputContainer>
              <TextArea
                value={input.value}
                onChange={input.onChange}
                label={t('Question Text')}
                height={80}
              />
              {translationTargetLanguage && (
                <TextArea
                  value={translationInput.value}
                  onChange={translationInput.onChange}
                  height={80}
                  label={t('Question Text ({{language}})', {
                    language: translationTargetLanguageName,
                  })}
                />
              )}
            </TranslatedInputContainer>
          )}
        </TranslatedField>
        <Box mt={2}>
          <FieldWrapper label={t('Response Type')}>
            <EnumRadioButtonGroup
              name={`${name}.responseType`}
              options={responseTypeOptions}
            />
          </FieldWrapper>
        </Box>
        <Box mt={2} mb={2}>
          <Field name={`${name}.required`} type="checkbox">
            {({ input }) => (
              <ToggleButton
                label={t('Required')}
                // Disable for NPS survey question 1 & 2
                disabled={
                  isNpsSurvey &&
                  (name === 'pages[0].surveyQuestions[0]' ||
                    name === 'pages[0].surveyQuestions[1]')
                }
                {...input}
              />
            )}
          </Field>
        </Box>
        {(responseType === 'SURVEY_QUESTION_SELECT_ONE' ||
          responseType === 'SURVEY_QUESTION_SELECT_MULTIPLE') && (
          <Box mt={2}>
            <FieldWrapper label={t('Response Options')}>
              <ResponseOptionsEditor name={`${name}.options`} />
            </FieldWrapper>
          </Box>
        )}
        {responseType === 'SURVEY_QUESTION_TEXT' && (
          <Field name={`${name}.format`}>
            {({ input }) => (
              <Select
                label={t('Format')}
                value={input.value}
                onChange={(e, { value }) => {
                  e.preventDefault();
                  e.stopPropagation();

                  input.onChange(value);
                }}
                options={formatOptions}
              />
            )}
          </Field>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button style="green" size="middle" onClick={onClose}>
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
