import * as React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Form, Field, useForm } from 'react-final-form';

import { ZoomablePane } from 'client/components/ZoomablePane/ZoomablePane';
import { getArrayMutators } from 'client/libraries/util/form';
import { Button, FieldWrapper } from 'client/components/Form';
import { convertToAlphabet } from 'client/libraries/util/convertToAlphabet';
import {
  updateEquipmentBlock,
  deleteEquipmentBlock,
} from 'client/actions/equipmentBlocks';
import { EquipmentBlock } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { ColorSelectCheckbox } from 'client/components/ColorSelectCheckbox/ColorSelectCheckbox';
import { Box } from 'client/components/Box/Box';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';

import {
  TitleInputForm,
  ColumnRowCountInputForm,
  CapacityInputForm,
} from './parts';
import styles from './SeatEditor.module.css';

interface FormValues {
  title: string;
  description: string;
  columnCount: number;
  rowCount: number;
  color: string;
  capacity: number;
}

const getInitialValues = (
  equipmentBlock: EquipmentBlock | undefined
): FormValues => {
  return {
    title: equipmentBlock?.title ?? '',
    description: equipmentBlock?.description ?? '',
    columnCount: equipmentBlock?.column_count ?? 1,
    rowCount: equipmentBlock?.row_count ?? 1,
    color: equipmentBlock?.color?.value ?? '#000000',
    capacity: equipmentBlock?.capacity?.value ?? 0,
  };
};

interface Props {
  equipmentBlock: EquipmentBlock | undefined;
}

export const BlockPane = ({ equipmentBlock }: Props) => {
  const dispatch = useDispatch();

  if (!equipmentBlock) {
    return <div className={clsx(styles['seats__main'])}></div>;
  }

  return (
    <div className={clsx(styles['seats__main'])}>
      <Form<FormValues>
        onSubmit={(values: FormValues) => {
          dispatch(
            updateEquipmentBlock(equipmentBlock?.id ?? '', {
              title: values.title,
              color: {
                value: values.color,
              },
              capacity: {
                value: Number(values.capacity),
              },
              column_count: Number(values.columnCount),
              row_count: Number(values.rowCount),
            })
          );
        }}
        initialValues={getInitialValues(equipmentBlock)}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <BlockPaneForm
                submitting={submitting}
                equipmentBlock={equipmentBlock}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

const BlockPaneForm = ({
  submitting,
  equipmentBlock,
}: {
  submitting: boolean;
  equipmentBlock: EquipmentBlock;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useForm();
  const values = form.getState().values;

  const cellWidth = 60;
  const cellHeight = 60;

  const layoutWidth = values.columnCount * cellWidth;
  const layoutHeight = values.rowCount * cellHeight;

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  const renderLayout = () => {
    const cells = [];
    for (let i = 0; i < values.rowCount; i++) {
      for (let j = 0; j < values.columnCount; j++) {
        cells.push(
          <li
            key={`${i}-${j}`}
            className={clsx(
              styles['equipment-editor__panel-main__layout__cell']
            )}
            style={{
              width: cellWidth,
              height: cellHeight,
              backgroundColor: values.color,
            }}
          >
            {convertToAlphabet(j + 1)}
            {i + 1}
          </li>
        );
      }
    }
    return cells;
  };

  React.useEffect(() => {
    form.setConfig('keepDirtyOnReinitialize', false);
    form.reset(getInitialValues(equipmentBlock));
    form.setConfig('keepDirtyOnReinitialize', true);
  }, [equipmentBlock]);

  return (
    <div className={clsx(styles['equipment-editor__panel-main__content'])}>
      <div className={clsx(styles['equipment-editor__panel-main__prop'])}>
        <div>
          <TitleInputForm />

          <ColumnRowCountInputForm />

          <Field name="color">
            {({ input }) => (
              <Box mt={2} mr={2}>
                <FieldWrapper label={t('Colors')}>
                  <ColorSelectCheckbox
                    value={input.value}
                    onChange={(color) => input.onChange(color)}
                    defaultColors={['#E6F8FF', '#FEFFE6', '#FFECE6', '#E7FFE6']}
                    vertical={true}
                  />
                </FieldWrapper>
              </Box>
            )}
          </Field>

          <CapacityInputForm />
        </div>

        <Box mt={2} mr={2}>
          <Button
            loading={submitting}
            size="middle"
            style="blue"
            type="submit"
            disabled={values.title ? false : true}
          >
            {t('Save')}
          </Button>
        </Box>

        <Box mt={8} mr={2}>
          <Button
            size="middle"
            style="red"
            onClick={() => {
              setShowDeleteConfirm(true);
            }}
          >
            {t('Delete')}
          </Button>

          <DeleteConfirmModal
            header={t('Delete Block')}
            content={t('Are you sure you want to delete "{{blockTitle}}"??', {
              blockTitle: equipmentBlock.title,
            })}
            onConfirm={async () => {
              await dispatch(deleteEquipmentBlock(equipmentBlock.id ?? ''));
              setShowDeleteConfirm(false);
            }}
            onClose={() => setShowDeleteConfirm(false)}
            open={showDeleteConfirm}
            insertRoot={true}
          />
        </Box>
      </div>

      <div className={clsx(styles['equipment-editor__panel-main__body'])}>
        <div className={clsx(baseStyles['base-form-box'])}>
          <div className={clsx(baseStyles['base-form-box__header'])}>
            {t('Preview')}
          </div>
          <ZoomablePane>
            {({
              targetRef,
              wrapperRef,
              onZoomInClick,
              onZoomOutClick,
              onReseatScale,
            }) => {
              React.useEffect(() => {
                onReseatScale?.();
              }, [equipmentBlock]);

              return (
                <div className={styles['seatsAction']}>
                  <div className={clsx(styles['seatsAction__info'])}>
                    <div
                      className={clsx(
                        styles['seatsAction__btns'],
                        styles['reload']
                      )}
                    >
                      <a
                        onClick={() => {
                          onZoomInClick?.();
                        }}
                      >
                        +
                      </a>
                      <a
                        onClick={() => {
                          onZoomOutClick?.();
                        }}
                      >
                        -
                      </a>
                    </div>
                  </div>
                  <div className={styles['seatsAction__cell']}>
                    <div className={styles['content']} ref={wrapperRef}>
                      <ul
                        style={{
                          gridTemplateColumns: `repeat(${values.columnCount}, ${cellWidth}px)`,
                          gridTemplateRows: `repeat(${values.rowCount}, ${cellHeight}px)`,
                          width: layoutWidth,
                          height: layoutHeight,
                        }}
                        ref={targetRef}
                      >
                        {renderLayout()}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            }}
          </ZoomablePane>
        </div>
      </div>
    </div>
  );
};
