import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useFormState, useForm } from 'react-final-form';
import _ from 'lodash';

import { Tooltip } from 'client/components/Tooltip/Tooltip';
import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { getLanguageName } from 'client/libraries/i18n';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Box } from 'client/components/Box/Box';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { ToggleButton } from 'client/components/Form';
import baseStyles from 'client/base.module.css';

import styles from './PerBookingPriceInput.module.css';

interface Props {
  language: string;
  name: string;
}

export const PerBookingUnitsEditor = ({ language, name }: Props) => {
  const { t } = useTranslation();

  const form = useForm();
  const formState = useFormState();
  const showUnits = (_.get(formState.values, name) ?? []).length > 0;

  const translationTargetLanguage = React.useContext(
    TranslationLanguageContext
  );

  const builtInUnits = [
    t('Adult', { lng: language }),
    t('Child', { lng: language }),
    t('Adult/Child', { lng: language }),
    t('Infant', { lng: language }),
    t('Spectator', { lng: language }),
  ];

  const unitOptions = [
    ...builtInUnits.map((unit) => ({ value: unit, text: unit })),
    { value: 'custom', text: t('Custom') },
  ];

  const translationTargetLanguageName =
    translationTargetLanguage &&
    getLanguageName(uppercaseIsoToLowercaseIso[translationTargetLanguage], t);

  return (
    <div className={styles['page-productsRegist__priceMain']}>
      <Box mb={1} display="flex">
        <Box maxWidth="250px">
          <ToggleButton
            label={t('Set Units')}
            checked={showUnits}
            onChange={() => {
              if (!showUnits) {
                form.change(name, [
                  {
                    unit: t('Adult', { lng: language }),
                    ageBandMinimum: 13,
                    ageBandMaximum: 0,
                  },
                  {
                    unit: t('Child', { lng: language }),
                    ageBandMinimum: 4,
                    ageBandMaximum: 12,
                  },
                  {
                    unit: t('Infant', { lng: language }),
                    ageBandMinimum: 0,
                    ageBandMaximum: 3,
                  },
                ]);
              } else {
                form.change(name, []);
              }
            }}
          />
        </Box>
        <Box ml={2}>
          <Tooltip
            text={t(
              'Please set units if you need the number of guests for each unit (ex. "adult", "child") with per-booking pricing products.'
            )}
            mobileStyle={{
              left: '-190px',
            }}
            style={{
              top: '-80px',
            }}
          />
        </Box>
      </Box>
      {showUnits && (
        <div className={styles['c-tableChild']}>
          <ul>
            <li className={baseStyles['base-t-160']}>{t('Unit')}</li>
            <li className={baseStyles['base-t-160']}>{t('Displayed As')}</li>
            <li className={baseStyles['base-t-224']}>{t('Age (optional)')}</li>
            <li className={baseStyles['base-t-144']}></li>
            <li></li>
          </ul>
          <FieldArray name={name}>
            {({ fields }) =>
              fields.map((fieldName, idx) => (
                <ul key={fieldName}>
                  <li data-title={t('Unit')}>
                    <label className={baseStyles['base-form-select']}>
                      <Field name={`${fieldName}.unit`}>
                        {({ input }) => (
                          <select
                            value={
                              builtInUnits.includes(input.value)
                                ? input.value
                                : 'custom'
                            }
                            onChange={(e) => {
                              if (e.target.value === 'custom') {
                                input.onChange('');
                              } else {
                                input.onChange(e.target.value);
                              }
                            }}
                          >
                            {unitOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.text}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </label>
                  </li>
                  <li data-title={t('Displayed As')}>
                    <TranslatedField name={`${fieldName}.unit`}>
                      {({ input, translationInput }) => (
                        <>
                          <div>
                            <input
                              {...input}
                              type="text"
                              className={baseStyles['base-form-text']}
                            />
                          </div>
                          {translationTargetLanguage != null &&
                            !builtInUnits.includes(input.value) && (
                              <div>
                                <input
                                  {...translationInput}
                                  placeholder={
                                    translationTargetLanguageName ?? undefined
                                  }
                                  type="text"
                                  className={baseStyles['base-form-text']}
                                />
                              </div>
                            )}
                        </>
                      )}
                    </TranslatedField>
                  </li>
                  <li data-title={t('Age (optional)')}>
                    <div
                      className={styles['page-productsRegist__priceMain__age']}
                    >
                      <label className={baseStyles['base-form-select']}>
                        <Field
                          parse={(value) => parseInt(value, 10)}
                          format={(value) => `${value}`}
                          name={`${fieldName}.ageBandMinimum`}
                        >
                          {({ input }) => (
                            <select {...input}>
                              {_.times(100).map((opt) => (
                                <option key={opt}>{opt}</option>
                              ))}
                            </select>
                          )}
                        </Field>
                      </label>
                      <p>-</p>
                      <label className={baseStyles['base-form-select']}>
                        <Field
                          parse={(value) => parseInt(value, 10)}
                          format={(value) => `${value}`}
                          name={`${fieldName}.ageBandMaximum`}
                        >
                          {({ input }) => (
                            <select {...input}>
                              {_.times(100).map((opt) => (
                                <option key={opt} value={opt + 1}>
                                  {opt + 1}
                                </option>
                              ))}
                              <option value={0}>{t('No Limit')}</option>
                            </select>
                          )}
                        </Field>
                      </label>
                    </div>
                  </li>
                  <li className={baseStyles['base-flex']}>
                    <AddIcon
                      onClick={() => {
                        (fields as any).insertAt(idx + 1, {
                          unit: t('Adult', {
                            lng: language,
                          }),
                          ageBandMinimum: 13,
                          ageBandMaximum: 0,
                        });
                      }}
                    />
                    {idx > 0 && (
                      <DeleteIcon
                        onClick={() => {
                          fields.remove(idx);
                        }}
                      />
                    )}
                  </li>
                </ul>
              ))
            }
          </FieldArray>
        </div>
      )}
    </div>
  );
};
