import type {
  BookingSourceType,
  ReservationStatus,
  BasisDateType,
} from 'shared/models/swagger';

export type DateFilterPreset =
  | '7_DAY'
  | '14_DAYS'
  | '28_DAYS'
  | '30_DAYS'
  | 'CUSTOM';
export type ReservationReportSettings = {
  agentIds: string[];
  statuses: ReservationStatus[];
  bookingSourceTypes: BookingSourceType[];
  productIds: string[];
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  dateFilterPreset: DateFilterPreset;
  basisDateType: BasisDateType;
  compare: boolean;
};
export const initialSettings: ReservationReportSettings = {
  agentIds: [],
  statuses: [],
  bookingSourceTypes: [],
  productIds: [],
  startDate: '',
  endDate: '',
  compareStartDate: '',
  compareEndDate: '',
  dateFilterPreset: '7_DAY',
  basisDateType: 'BOOKING_DATE',
  compare: false,
};
