import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { fetchProducts } from 'client/actions/products';
import { fetchWaivers } from 'client/actions/waivers';
import {
  setLastExecutedWaiverSearchCondition,
  setWaiverCurrentPage,
  setWaiverRowCount,
} from 'client/actions/waiverTableControls';
import { waiverVisibleColumnsSelector } from 'client/reducers/waiverTableControls';
import { convertSearchWaiversRequestToQueryParams } from 'client/libraries/util/searchWaivers';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import {
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import type { SearchWaiversRequest } from 'client/libraries/util/searchWaivers';
import baseStyles from 'client/base.module.css';
import searchIcon from 'client/images/ic_search.svg';
import thIcon from 'client/images/ic_th.svg';
import { Waiver } from 'shared/models/swagger';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import {
  useCustomWaiverColumns,
  useWaiverColumns,
} from 'client/components/WaiverTable/util';
import { WaiverSearchFiltersDisplayBox } from 'client/components/WaiverTable/WaiverSearchFiltersDisplayBox/WaiverSearchFiltersDisplayBox';
import { WaiverSearchSettingsModal } from 'client/components/WaiverTable/WaiverSearchSettingsModal/WaiverSearchSettingsModal';
import { WaiverTableSettingsModal } from 'client/components/WaiverTable/WaiverTableSettingsModal/WaiverTableSettingsModal';
import { config } from 'client/config';
import { CustomTable } from 'client/components/CustomTable/CustomTable';

export const WaiverTable = () => {
  const rowCount = useSelector(
    (state: ReduxState) => state.waiverTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.waiverTableControls.currentPage
  );
  const totalHits = useSelector((state: ReduxState) => state.waivers.totalHits);
  const allColumns = useWaiverColumns();

  // TODO: customAllColumns is only used for feature flag config.enableWaiverListEnhancement
  // Delete customAllColumns for release
  const customAllColumns = useCustomWaiverColumns();

  const visibleColumns = useSelector(waiverVisibleColumnsSelector);
  const columns = React.useMemo(() => {
    const getColumns = (columnMask: string[]) => {
      return ['view', ...columnMask.filter((c) => c !== 'view')].map(
        (c) => allColumns.find((col) => col.id === c) as ColumnType<Waiver>
      );
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, allColumns]);

  // TODO: customColumns is only used for feature flag config.enableWaiverListEnhancement
  // Delete customColumns for release
  const customColumns = React.useMemo(() => {
    const getColumns = (columnMask: string[]) => {
      return ['view', ...(columnMask.filter((c) => c !== 'view') as any)].map(
        (c) => customAllColumns.find((col) => col.id === c) as any
      );
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, customAllColumns]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state: ReduxState) => state.waivers.loading);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const lastExecutedSearchCondition = useSelector(
    (state: ReduxState) => state.waiverTableControls.lastExecutedSearchCondition
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchWaiversRequest>(lastExecutedSearchCondition);
  const waivers = useSelector((state: ReduxState) => state.waivers.all);

  // TODO: sortedWaivers is only used for feature flag config.enableWaiverListEnhancement
  // Delete sortedWaivers for release
  const sortedWaivers = React.useMemo(() => {
    return _.sortBy(
      waivers,
      (waiver) => waiver.submitted_date_time_utc
    ).reverse();
  }, [waivers]);

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  React.useEffect(() => {
    search();
  }, [activeUserOrganization]);
  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUserOrganization]);

  // Re-fetch data whenever currentPage or rowCount changes
  React.useEffect(() => {
    search();
  }, [currentPage, rowCount]);

  const search = () => {
    if (!isNutmegAdmin) {
      const pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastExecutedSearchCondition)) {
        dispatch(setLastExecutedWaiverSearchCondition(searchCondition));
      }

      dispatch(
        fetchWaivers(
          convertSearchWaiversRequestToQueryParams(searchCondition),
          rowCount,
          rowCount * (pageToFetch - 1)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      supplierReference: '',
      customerGivenName: '',
      customerFamilyName: '',
      productIds: [],
      participationDateFrom: '',
      participationDateTo: '',
      submittedDateFrom: '',
      submittedDateTo: '',
      dateFilterPreset: 'PARTICIPATION_1_DAY',
    });
  };

  return (
    <div>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <WaiverSearchSettingsModal
              onReset={reset}
              onSearch={search}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
            />
          </div>
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2']
            )}
          >
            <WaiverTableSettingsModal
              trigger={
                <a
                  className={clsx(
                    baseStyles['base-btn'],
                    baseStyles['square'],
                    baseStyles['gray']
                  )}
                >
                  <img src={thIcon} />
                </a>
              }
            />
          </div>
        </div>
      )}
      <Box mb={2}>
        <WaiverSearchFiltersDisplayBox
          searchFilters={lastExecutedSearchCondition}
        />
      </Box>
      {/* TODO: Keep GenericTable and delete CustomTable for release */}
      {config.enableWaiverListEnhancement && (
        <GenericTable<Waiver>
          items={waivers}
          totalCount={totalHits}
          columns={columns}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            dispatch(setWaiverRowCount(rowCount));
          }}
          onCurrentPageChange={(page: number) => {
            dispatch(setWaiverCurrentPage(page));
          }}
        />
      )}
      {!config.enableWaiverListEnhancement && (
        <CustomTable
          items={sortedWaivers}
          columns={customColumns}
          usePaging={true}
        />
      )}
    </div>
  );
};
