import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment-timezone';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { getColumnHeaderText } from 'client/reducers/manifestDefaults';
import { Box } from 'client/components/Box/Box';
import { Tooltip } from 'client/components/Tooltip/Tooltip';
import type { TranslateFuncType } from 'client/components/Translate';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type { ResourceType } from 'client/libraries/util/resourceType';
import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import { operationAllowed } from 'shared/models/access';
import {
  getWaiverStatusText,
  getWaiverStatus,
} from 'client/libraries/util/getWaiverStatusText';
import { EditSupplierInternalNotesModalForDispatchModal } from 'client/pages/Manifest/EditSupplierInternalNotesForDispatchModal';
import type {
  ProductSummary,
  ReservationColumn,
  ManifestCustomizedColumnName,
  Account,
} from 'shared/models/swagger';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkUnfinishedIcon from 'client/images/ic_check_unfinished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';
import checkMarkIcon from 'client/images/ic_check.svg';
import baseStyles from 'client/base.module.css';
import { ReservationFormCell } from 'client/pages/v3/Manifest/ManifestDaily/Util/ReservationFormCell';
import { Button } from 'client/components/v3/Common/Button';
import { CustomTableColumn } from 'client/components/v3/Table/CustomTable';
import { FlexibleDashboardReservationListShape } from 'client/libraries/util/flexibleDashboardReservationListShape';
import {
  getCheckinTipsText,
  getCheckinStatusText,
  getInProgressCheckinTipsText,
  getInProgressCheckinStatusBox,
  getCheckinModal,
  formatPOBamount,
  formFieldColumns,
  reservationFormCustomFieldColumns,
  filterExcludedFormFieldColumns,
} from 'client/pages/v3/Manifest/ManifestDaily/Util/util';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { Badge } from 'client/components/v3/Common/Badge';
import { getBadgeColorForReservationStatus } from 'client/libraries/util/getBadgeColorForReservationStatus';

export type ManifestReservationsTableColumn = ReservationColumn | string;
export type Column = {
  Header: string;
  HeaderElement?: React.ReactElement<any>;
  id: ManifestReservationsTableColumn;
  accessor?: string | ((arg0: any) => string);
  columnGroupKey?: ManifestReservationsTableColumn;
  Cell?: (arg0: any) => any;
  CellArray?: (arg0: any) => any[];
  th?: boolean;
  width?: string;
};

export const calcWidthToPx = (width: string | typeof undefined): number => {
  if (width === 'short') return 110;
  if (width === 'middle') return 140;
  if (width === 'long') return 220;
  if (width === 'longx2') return 320;

  return 60;
};

const tooltipStyle = {
  top: '-20px',
  left: '20px',
};

export const getStatusColumn = (t: TranslateFuncType) => {
  return {
    Header: t('Status'),
    id: 'status',
    width: 'long',
    Cell: (cellInfo: any) => (
      <Badge
        label={t(cellInfo.original.status)}
        color={getBadgeColorForReservationStatus(cellInfo.original.status)}
      />
    ),
  };
};

export const getColumns = (
  locale: string,
  t: TranslateFuncType,
  excludedFormFieldKeys: string[],
  products: ProductSummary[],
  reservations: FlexibleDashboardReservationListShape[],
  visibleColumns: ReservationColumn[],
  customizedColumnNames: ManifestCustomizedColumnName[],
  guideAccountShapes: GuideAccountShape[],
  editingResourceType: ResourceType | null,
  editingResourceKey: string | null,
  activeUser: Account | null
): CustomTableColumn[] => {
  const bookedProducts = products.filter((p) =>
    reservations.some((r) => r.product_id === p.id)
  );

  const allReservationColumns = (
    getReservationTableColumns(t, locale, false, false) as Column[]
  )
    .filter(
      (c) =>
        // Filter unnecessary columns here
        c.id !== 'edit'
    )
    .map((c) => {
      // Replace 'status' column with the new column
      if (c.id === 'status') {
        return getStatusColumn(t);
      }

      return c;
    });

  const allManifestColumns: Column[] = [
    {
      Header: getColumnHeaderText('CHECKIN_STATUS', t, customizedColumnNames),
      id: 'CHECKIN_STATUS',
      width: 'long',
      Cell: (cellInfo: { original: ManifestReservationShape }) => {
        const product = products.find(
          (product) => product.id === cellInfo.original?.product_id
        );

        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {['CHECKED_IN', 'NOT_CHECKED_IN'].includes(
              cellInfo.original?.checkin_info?.checkin_status || ''
            ) && (
              <>
                <Tooltip
                  style={tooltipStyle}
                  icon={getCheckinModal(product, cellInfo.original)}
                  text={getCheckinTipsText(cellInfo.original, products, t)}
                />
                <Box ml={1}>
                  {getCheckinStatusText(cellInfo.original, products)}
                </Box>
              </>
            )}
            {cellInfo.original?.checkin_info?.checkin_status ===
              'IN_PROGRESS' && (
              <>
                <Tooltip
                  style={tooltipStyle}
                  icon={getCheckinModal(product, cellInfo.original)}
                  text={getInProgressCheckinTipsText(
                    cellInfo.original,
                    products,
                    t
                  )}
                />
                <Box ml={1}>
                  {getInProgressCheckinStatusBox(cellInfo.original, products)}
                </Box>
              </>
            )}
          </Box>
        );
      },
    },
    {
      Header: t('Dispatch resources'),
      id: 'DISPATCH',
      width: 'long',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {cellInfo.original.dispatch_vehicles.map((s, idx) => (
              <div key={idx}>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '2px',
                  }}
                  key={idx}
                >
                  {s}
                </span>
              </div>
            ))}
            {cellInfo.original.dispatch_crew.map((s, idx) => (
              <div key={idx}>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '2px',
                  }}
                  key={idx}
                >
                  {s}
                </span>
              </div>
            ))}
            {cellInfo.original.dispatch_misc_resources.map((s, idx) => (
              <div key={idx}>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '2px',
                  }}
                  key={idx}
                >
                  {s}
                </span>
              </div>
            ))}
            {cellInfo.original.dispatch_guides.map((s, idx) => (
              <div key={idx}>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '2px',
                  }}
                  key={idx}
                >
                  {guideAccountShapes.find((guide) => guide.id === s)?.name ||
                    'NO NAME'}
                </span>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_REFERENCE',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_REFERENCE',
      accessor: (row: ManifestReservationShape) => row.supplier_reference || '',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) =>
        activeUser === null ||
        operationAllowed(activeUser, 'read', 'reservations') ? (
          <Link to={`/reservations/${cellInfo.original.id}`}>
            {cellInfo.original.supplier_reference}
          </Link>
        ) : (
          <>{cellInfo.original.supplier_reference}</>
        ),
    },
    {
      Header: getColumnHeaderText(
        'GUEST_DISPLAY_NAME',
        t,
        customizedColumnNames
      ),
      id: 'GUEST_DISPLAY_NAME',
      width: 'middle',
      accessor: 'guest_display_name',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <div>
          {cellInfo.original.guest_display_name.split(' ').map((s, idx) => (
            <span
              style={{
                display: 'inline-block',
                marginRight: '2px',
              }}
              key={idx}
            >
              {s}{' '}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: getColumnHeaderText('GIVEN_NAME', t, customizedColumnNames),
      id: 'GIVEN_NAME',
      width: 'middle',
      accessor: 'given_name',
    },
    {
      Header: getColumnHeaderText('FAMILY_NAME', t, customizedColumnNames),
      id: 'FAMILY_NAME',
      width: 'middle',
      accessor: 'family_name',
    },
    {
      Header: getColumnHeaderText('PRODUCT_NAME', t, customizedColumnNames),
      id: 'PRODUCT_NAME',
      width: 'long',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <>
          <div className={clsx(baseStyles['base-flex'])}>
            <ul>
              <li>{cellInfo.original.product_name}</li>
              {cellInfo.original.package_component_reservations.map(
                (componentReservation, idx) => (
                  <li key={idx}>
                    {`[${componentReservation.formatted_start_time}] ${componentReservation.product_name}`}
                  </li>
                )
              )}
            </ul>
            {editingResourceType === 'guide' &&
              (
                guideAccountShapes.find(
                  (guide) => guide.id === editingResourceKey
                )?.product_ids || []
              ).includes(cellInfo.original.product_id) && (
                <img src={checkMarkIcon} />
              )}
          </div>
        </>
      ),
    },
    {
      Header: getColumnHeaderText(
        'INTERNAL_PRODUCT_NAME',
        t,
        customizedColumnNames
      ),
      id: 'INTERNAL_PRODUCT_NAME',
      width: 'long',
      accessor: (row) => {
        return (
          getDisplayProductName(products.find((p) => p.id == row.product_id)) ||
          ''
        );
      },
    },
    {
      Header: getColumnHeaderText(
        'GUEST_DESCRIPTION',
        t,
        customizedColumnNames
      ),
      id: 'GUEST_DESCRIPTION',
      width: 'long',
      accessor: 'guest_description',
    },
    {
      Header: getColumnHeaderText('GUEST_COUNT', t, customizedColumnNames),
      id: 'GUEST_COUNT',
      width: 'short',
      accessor: 'guest_count',
    },
    {
      Header: getColumnHeaderText('GROSS', t, customizedColumnNames),
      id: 'GROSS',
      width: 'short',
      accessor: 'amount_gross',
    },
    {
      Header: getColumnHeaderText('POB_AMOUNT', t, customizedColumnNames),
      id: 'POB_AMOUNT',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        formatPOBamount(row.amount_pob) || '',
    },
    {
      Header: getColumnHeaderText(
        'PICKUP_CHECKIN_TIME',
        t,
        customizedColumnNames
      ),
      id: 'PICKUP_CHECKIN_TIME',
      width: 'middle',
      accessor: (row: ManifestReservationShape) => {
        return (
          (row &&
            row.pickup_checkin_time &&
            row.pickup_checkin_time.locale &&
            row.pickup_checkin_time.locale(locale).format('LT')) ||
          ''
        );
      },
    },
    {
      Header: getColumnHeaderText(
        'PICKUP_CHECKIN_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'PICKUP_CHECKIN_LOCATION',
      width: 'long',
      accessor: 'pickup_checkin_location',
    },
    {
      Header: getColumnHeaderText('DROPOFF_LOCATION', t, customizedColumnNames),
      id: 'DROPOFF_LOCATION',
      width: 'long',
      accessor: 'dropoff_location',
    },
    {
      Header: getColumnHeaderText('PAYMENT_TYPE', t, customizedColumnNames),
      id: 'PAYMENT_TYPE',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        (row.payment_type && t(row.payment_type)) || '',
    },
    {
      Header: getColumnHeaderText('PAYMENT_METHOD', t, customizedColumnNames),
      id: 'PAYMENT_METHOD',
      width: 'short',
      accessor: (row: ManifestReservationShape) =>
        (row.payment_method && t(row.payment_method)) || '',
    },
    {
      Header: getColumnHeaderText('TRANSPORTATION', t, customizedColumnNames),
      id: 'TRANSPORTATION',
      width: 'middle',
      accessor: 'transportation',
    },
    {
      Header: getColumnHeaderText('ADD_ONS', t, customizedColumnNames),
      id: 'ADD_ONS',
      width: 'middle',
      accessor: 'add_ons',
    },
    {
      Header: getColumnHeaderText('BOOKING_SOURCE', t, customizedColumnNames),
      id: 'BOOKING_SOURCE',
      width: 'middle',
      accessor: 'booking_source',
    },
    {
      Header: getColumnHeaderText('SUPPLIER_NOTES', t, customizedColumnNames),
      id: 'SUPPLIER_NOTES',
      width: 'long',
      accessor: 'supplier_notes',
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_INTERNAL_NOTES',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_INTERNAL_NOTES',
      accessor: 'supplier_internal_notes',
      width: 'long',
      Cell: (cellInfo: {
        original: ManifestReservationShape;
        value: string;
      }) => (
        <div>
          <p style={{ marginTop: '4px' }}>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_INTERNAL_NOTES_FOR_DISPATCH',
      accessor: 'supplier_internal_notes_for_dispatch',
      width: 'long',
      Cell: (cellInfo: {
        original: ManifestReservationShape;
        value: string;
      }) => (
        <>
          <EditSupplierInternalNotesModalForDispatchModal
            key={cellInfo.original?.id}
            header={t('Edit internal note')}
            trigger={
              <Button
                text={t('Edit')}
                size="sm"
                color="white"
                iconBeforeText={
                  <i className="c-icon-outline-general-edit-05"></i>
                }
              />
            }
            reservation={cellInfo.original}
          />
          {cellInfo.value}
        </>
      ),
    },
    {
      Header: getColumnHeaderText(
        'SUPPLIER_PRODUCT_REFERENCE',
        t,
        customizedColumnNames
      ),
      id: 'SUPPLIER_PRODUCT_REFERENCE',
      width: 'middle',
      accessor: 'supplier_product_reference',
    },
    {
      Header: getColumnHeaderText('AGENT_NOTES', t, customizedColumnNames),
      id: 'AGENT_NOTES',
      width: 'long',
      accessor: 'agent_notes',
    },
    {
      Header: getColumnHeaderText('AGENT_REFERENCE', t, customizedColumnNames),
      id: 'AGENT_REFERENCE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) => row.agent_reference || '',
      Cell: (cellInfo: { original: ManifestReservationShape }) =>
        activeUser === null ||
        operationAllowed(activeUser, 'read', 'reservations') ? (
          <Link to={`/reservations/${cellInfo.original.id}`}>
            {cellInfo.original.agent_reference}
          </Link>
        ) : (
          <>{cellInfo.original.agent_reference}</>
        ),
    },
    {
      Header: getColumnHeaderText('HOTEL', t, customizedColumnNames),
      id: 'HOTEL',
      width: 'long',
      accessor: 'hotel',
    },
    {
      Header: getColumnHeaderText('PROMO_CODE', t, customizedColumnNames),
      id: 'PROMO_CODE',
      width: 'middle',
      accessor: 'promo_code',
    },
    {
      Header: getColumnHeaderText('DISPATCH_VEHICLE', t, customizedColumnNames),
      id: 'DISPATCH_VEHICLE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        row.dispatch_vehicles && row.dispatch_vehicles.join(','),
    },
    {
      Header: getColumnHeaderText('DISPATCH_CREW', t, customizedColumnNames),
      id: 'DISPATCH_CREW',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        [
          ...(row.dispatch_crew || []),
          ...(row.dispatch_guides || []).map((id) => {
            return (
              guideAccountShapes.find((guide) => guide.id === id)?.name ||
              'no name'
            );
          }),
        ].join(',') || '',
    },
    {
      Header: getColumnHeaderText(
        'DISPATCH_MISC_RESOURCE',
        t,
        customizedColumnNames
      ),
      id: 'DISPATCH_MISC_RESOURCE',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        (row.dispatch_misc_resources &&
          row.dispatch_misc_resources.join(',')) ||
        '',
    },
    {
      Header: getColumnHeaderText(
        'INTERNAL_PRODUCT_TAGS',
        t,
        customizedColumnNames
      ),
      id: 'INTERNAL_PRODUCT_TAGS',
      width: 'middle',
      accessor: (row: ManifestReservationShape) =>
        (row.internal_product_tags && row.internal_product_tags.join(',')) ||
        '',
    },
    {
      Header: getColumnHeaderText('WAIVER_STATUS', t, customizedColumnNames),
      id: 'WAIVER_STATUS',
      width: 'middle',
      accessor: 'waiver_status_text',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {getWaiverStatus(cellInfo.original) === 'COMPLETE' && (
              <img src={checkFinishedIcon} />
            )}
            {getWaiverStatus(cellInfo.original) === 'UNSIGNED' && (
              <img src={checkUnfinishedIcon} />
            )}
            {getWaiverStatus(cellInfo.original) === 'IN_PROGRESS' && (
              <img src={checkInProgressIcon} />
            )}
          </Box>
          <Link to={`/reservations/${cellInfo.original.id}?t=waivers#detail`}>
            {getWaiverStatusText(
              cellInfo.original.waiver_info,
              cellInfo.original.guest_count,
              t
            )}
          </Link>
        </Box>
      ),
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_DEPARTURE_TIME',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_DEPARTURE_TIME',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) => {
          const dateTimeUtc = routeItem.location_from?.date_time_utc;

          if (!dateTimeUtc) {
            return '';
          }

          return moment
            .tz(dateTimeUtc, item.start_timezone ?? '')
            .format('HH:mm');
        }) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_DEPARTURE_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_DEPARTURE_LOCATION',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map(
          (routeItem) => routeItem.location_from?.location_name || ''
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_ARRIVAL_TIME',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_ARRIVAL_TIME',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) => {
          const dateTimeUtc = routeItem.location_to?.date_time_utc;

          if (!dateTimeUtc) {
            return '';
          }

          return moment
            .tz(dateTimeUtc, item.start_timezone ?? '')
            .format('HH:mm');
        }) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_ARRIVAL_LOCATION',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_ARRIVAL_LOCATION',
      columnGroupKey: 'TRANSPORT_ROUTE',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map(
          (routeItem) => routeItem.location_to?.location_name || ''
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'TRANSPORT_VEHICLE',
        t,
        customizedColumnNames
      ),
      id: 'TRANSPORT_VEHICLE',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          (routeItem.dispatch_vehicles ?? []).join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText('TRANSPORT_CREW', t, customizedColumnNames),
      id: 'TRANSPORT_CREW',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          [
            ...(routeItem.dispatch_crew || []),
            ...(routeItem.dispatch_guides || []).map((id) => {
              return (
                guideAccountShapes.find((guide) => guide.id === id)?.name ||
                'no name'
              );
            }),
          ].join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText('TRANSPORT_OTHER', t, customizedColumnNames),
      id: 'TRANSPORT_OTHER',
      width: 'middle',
      CellArray: (item: ManifestReservationShape) =>
        item.transport_route?.map((routeItem) =>
          (routeItem.dispatch_misc_resources ?? []).join(',')
        ) ?? [],
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMAIL',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMAIL',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="email" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FULL_NAME',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FULL_NAME',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="full_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FULL_NAME_KANJI',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FULL_NAME_KANJI',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kanji_full_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_TITLE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_TITLE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="title" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="phone" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_INTERNATIONAL_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_INTERNATIONAL_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="international_phone"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_NAME',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="emergency_contact_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_EMERGENCY_CONTACT_PHONE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="emergency_contact_phone"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_HEIGHT',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_HEIGHT',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="height"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_WEIGHT',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_WEIGHT',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="weight"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_DATE_OF_BIRTH',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_DATE_OF_BIRTH',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="date_of_birth"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_AGE',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_AGE',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell reservation={cellInfo.original} fieldKey="age" />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_GENDER',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_GENDER',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="gender"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_HOME_ADDRESS',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_HOME_ADDRESS',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="home_address"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_CONSENT_FORM',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_CONSENT_FORM',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="consent_form"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_FAMILY_NAME_KANA',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_FAMILY_NAME_KANA',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kana_family_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'RESERVATION_FORM_GIVEN_NAME_KANA',
        t,
        customizedColumnNames
      ),
      id: 'RESERVATION_FORM_GIVEN_NAME_KANA',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => (
        <ReservationFormCell
          reservation={cellInfo.original}
          fieldKey="kana_given_name"
        />
      ),
    },
    {
      Header: getColumnHeaderText(
        'REQUIRED_RESOURCES',
        t,
        customizedColumnNames
      ),
      id: 'REQUIRED_RESOURCES',
      width: 'middle',
      Cell: (cellInfo: { original: ManifestReservationShape }) => {
        return (cellInfo.original?.resource_assignments || []).map(
          (resourceAssignment, idx) => {
            return (
              <p key={idx} style={{ margin: '1px' }}>
                {`${resourceAssignment.resource_key ?? ''} x ${
                  resourceAssignment.quantity ?? ''
                }`}
              </p>
            );
          }
        );
      },
    },
    ...formFieldColumns(excludedFormFieldKeys, bookedProducts),
    ...reservationFormCustomFieldColumns(excludedFormFieldKeys, bookedProducts),
  ];

  const columns: Column[] = [];
  [
    ...filterExcludedFormFieldColumns(visibleColumns, excludedFormFieldKeys),
  ].forEach((vc) => {
    columns.push(
      ...allManifestColumns.filter((c) => {
        if (c.id === vc) {
          return true;
        }

        if (c.columnGroupKey === vc) {
          return true;
        }

        return false;
      })
    );
  });

  const allManifestColumnsWithLowercaseId = allManifestColumns.map(
    (column) => ({
      ...column,
      id: column.id.toLowerCase(),
    })
  );
  const columnsWithLowercaseId = columns.map((column) => ({
    ...column,
    id: column.id.toLowerCase(),
  }));

  const uniqueColumns = allReservationColumns.filter((column) => {
    return !allManifestColumnsWithLowercaseId.some(
      (col) => col.id === column.id
    );
  });

  return [
    ...uniqueColumns,
    ...allManifestColumnsWithLowercaseId,
    ...columnsWithLowercaseId,
  ].filter(
    (column, index, self) => index === self.findIndex((c) => c.id === column.id)
  );
};
