import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalLoader } from 'client/components/ModalLoader';
import { getDateFilterPresetText } from 'client/libraries/util/searchInstantWinEvents';
import type { SearchInstantWinEventsRequest } from 'client/libraries/util/searchInstantWinEvents';
import type { ReduxState } from 'client/reducers';

import styles from './InstantWinEventSearchFiltersDisplayBox.module.css';

const formattedTotalHits = (totalHits: number): string => {
  if (totalHits === 10000) {
    return `${totalHits.toLocaleString()}+`;
  }

  return totalHits.toLocaleString();
};

type Props = {
  searchFilters: SearchInstantWinEventsRequest;
};
export const InstantWinEventSearchFiltersDisplayBox = ({
  searchFilters,
}: Props) => {
  const { t } = useTranslation();
  const totalHits = useSelector(
    (state: ReduxState) => state.instantWinEvents.totalSize
  );
  const loading = useSelector((state: ReduxState) => state.products.loading);
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (searchFilters.dateFilterPreset) {
    const preset = searchFilters.dateFilterPreset;
    filters.push({
      typeText: t('Date Preset'),
      filterValue: getDateFilterPresetText(preset, t),
    });
  }

  if (searchFilters.drawnDateFrom || searchFilters.drawnDateTo) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${searchFilters.drawnDateFrom ?? ''} ~ ${
        searchFilters.drawnDateTo
      }`,
    });
  }

  return (
    <div className={styles['search-filter-display-box']}>
      <b>{t('Search Conditions')}</b>

      {loading ? (
        <ModalLoader />
      ) : (
        <ul>
          {filters.map((f) => (
            <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
          ))}
          <li className={styles['total']}>
            <b>{t('Total: ')}</b>

            {t('{{count}} records', {
              count: formattedTotalHits(totalHits),
            })}
          </li>
        </ul>
      )}
    </div>
  );
};
