import { parsePhoneNumber, ParseError } from 'libphonenumber-js';

import { TranslateFuncType } from 'client/components/Translate';

export const validateInternationalPhoneNumber = (
  phone: string,
  t: TranslateFuncType
): string => {
  const formattedPhone = phone.startsWith('+') ? phone : `+${phone}`;
  try {
    if (formattedPhone) {
      const phoneNumber = parsePhoneNumber(formattedPhone);

      if (
        phoneNumber.countryCallingCode !== '1' &&
        phoneNumber.countryCallingCode !== '81'
      ) {
        return t('Invalid country calling code: {{countryCode}}', {
          countryCode: phoneNumber.countryCallingCode,
        });
      }
    }
  } catch (e) {
    if (e instanceof ParseError && e.message === 'INVALID_COUNTRY') {
      return t('Phone number does not have a country code.');
    } else {
      return t('Invalid phone number format.');
    }
  }

  return '';
};
