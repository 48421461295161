import * as React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { ReduxState } from 'client/reducers';
import { fetchReviewsByReservationIDs } from 'client/actions/reviews';
import type { TranslateFuncType } from 'client/components/Translate';
import {
  ColumnType,
  GenericTable,
} from 'client/components/GenericTable/GenericTable';
import {
  convertReviewRatingToNumber,
  getReviewStatusText,
} from 'client/libraries/util/review';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import { Stars } from 'client/components/Stars/Stars';
import type { Review } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import styles from './CustomerDetails.module.css';
import { CustomerReservationsContext } from './CustomerReservationsContext';

interface Props {
  active: boolean;
}

export const CustomerReviews = ({ active }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(10);

  const reservations = React.useContext(CustomerReservationsContext);

  const reviews = useSelector((state: ReduxState) => state.reviews.all);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  React.useEffect(() => {
    if (reservations) {
      dispatch(fetchReviewsByReservationIDs(reservations.map((r) => r.id)));
    }
  }, [reservations]);

  const getAgentReference = (reservationId: string): string => {
    const reservation = (reservations ?? []).find(
      (r) => r.id === reservationId
    );
    return reservation?.agent_reference ?? '';
  };

  const getColumns = (
    local: string,
    t: TranslateFuncType
  ): ColumnType<Review>[] => {
    return [
      {
        Header: t('Application Number'),
        id: 'agent_reference',
        accessor: (item: Review) => {
          return (
            <Link to={`/reservations/${item.reservation_id}`}>
              {getAgentReference(item.reservation_id)}
            </Link>
          );
        },
        width: 120,
      },
      {
        Header: t('Review Status'),
        id: 'review_status',
        accessor: (item: Review) => {
          return getReviewStatusText(item.status, t);
        },
        width: 120,
      },
      {
        Header: t('Posted Date'),
        id: 'posted_date',
        accessor: (item: Review) => {
          return getDateTimeDisplay(moment(item.written_date_time_utc), local);
        },
        width: 120,
      },

      {
        Header: t('Review Title'),
        id: 'posted_date',
        accessor: (item: Review) => {
          return item.title;
        },
        width: 120,
      },
      {
        Header: t('Rating'),
        id: 'rating',
        accessor: (item: Review) => (
          <div
            className={
              styles['c-review__list__comment__item__top__review__stars']
            }
          >
            <Stars rating={convertReviewRatingToNumber(item.rating)} />
          </div>
        ),
        width: 120,
      },

      {
        Header: t('Participation'),
        id: 'participates_at',
        accessor: (item: Review) => {
          return getDateTimeDisplay(
            moment.parseZone(item.participation_date_time_local),
            local
          );
        },
        width: 120,
      },
      {
        Header: t('Product'),
        id: 'product_name',
        accessor: (item: Review) => (
          <Link to={`/products/${item.product_id}`}>{item.product_name}</Link>
        ),
      },
    ];
  };

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        active ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Reviews')}
        </div>
      </div>
      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <GenericTable<Review>
          items={reviews ?? []}
          totalCount={(reviews ?? []).length}
          columns={getColumns(locale, t)}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount: number) => {
            setRowCount(rowCount);
          }}
          onCurrentPageChange={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
        />
      </div>
    </div>
  );
};
