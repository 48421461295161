import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  name: string;
  description: string;
  productIds: string[];
}

export const getInitialValues = (
  eTicketLocationSet?: Swagger.ETicketLocationSet | null
): FormValues => {
  return {
    name: eTicketLocationSet?.location_set_name ?? '',
    description: eTicketLocationSet?.location_set_description ?? '',
    productIds: eTicketLocationSet?.product_ids ?? [],
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.ETicketLocationSetParams => {
  return {
    location_set_name: values.name,
    location_set_description: values.description,
    product_ids: values.productIds,
  };
};
