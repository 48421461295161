import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from 'client/components/Message/Message';
import { fetchProducts } from 'client/actions/products';
import { Select } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import type { ReduxState } from 'client/reducers';
import { BookingWidgetSingleProductBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetSingleProductBookNowButton';
import { BookingWidgetMultiProductBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetMultiProductBookNowButton';
import { BookingWidgetProductGroupBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetProductGroupBookNowButton';

import { BookingWidgetEmbeddedSingleProductCalendar } from './BookingWidgetEmbeddedSingleProductCalendar';
import { BookingWidgetEmbeddedMultiProductCalendar } from './BookingWidgetEmbeddedMultiProductCalendar';
import { BookingWidgetEmbeddedProductGroupCalendar } from './BookingWidgetEmbeddedProductGroupCalendar';
import { ProductGroupCalendarList } from './ProductGroupCalendarList';
import styles from './EmbedCalendarTab.module.css';

export const EmbedCalendarTab = () => {
  const [selectedProductId, setSelectedProductId] = React.useState<string>('');
  const [widgetLanguage, setWidgetLanguage] = React.useState<string>('ja-JP');
  const [calendarType, setCalendarType] = React.useState<
    | 'SINGLE_PRODUCT_CALENDAR'
    | 'MULTI_PRODUCT_CALENDAR'
    | 'PRODUCT_GROUP_CALENDAR'
  >('SINGLE_PRODUCT_CALENDAR');
  const [embedType, setEmbedType] = React.useState<
    'CALENDAR' | 'BOOK_NOW_BUTTON'
  >('CALENDAR');
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);
  //const productsLoading = useSelector(
  //  (state: ReduxState) => state.products.loading
  //);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);

  const languageOptions = (
    (organization && organization.supported_languages) ||
    []
  ).map((language) => ({
    text: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
    key: uppercaseIsoToLowercaseIso[language],
  }));

  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';

  if (!bookingWidgetApiKey) {
    return (
      <Message warning>
        {t(
          'Booking widget not currently configured. Please contact sales@nutmeglabs.com if interested.'
        )}
      </Message>
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['input-box']}>
        <Select
          label={t('Calendar Type')}
          maxWidth={400}
          value={calendarType}
          options={[
            {
              value: 'SINGLE_PRODUCT_CALENDAR',
              text: t('Single Product Calendar'),
            },
            {
              value: 'MULTI_PRODUCT_CALENDAR',
              text: t('All Products Calendar'),
            },
            {
              value: 'PRODUCT_GROUP_CALENDAR',
              text: t('Product Group'),
            },
          ]}
          onChange={(e, { value }) => {
            setCalendarType(value as any);
          }}
        />
      </div>
      {calendarType === 'PRODUCT_GROUP_CALENDAR' && (
        <>
          <ProductGroupCalendarList />
        </>
      )}
      <div className={styles['input-box']}>
        <Select
          label={t('Embed Type')}
          maxWidth={400}
          value={embedType}
          options={[
            {
              value: 'CALENDAR',
              text: t('Calendar'),
            },
            {
              value: 'BOOK_NOW_BUTTON',
              text: t('"Book Now" Button'),
            },
          ]}
          onChange={(e, { value }) => {
            setEmbedType(value as any);
          }}
        />
      </div>
      {calendarType === 'SINGLE_PRODUCT_CALENDAR' && (
        <div className={styles['input-box']}>
          <Select
            label={t('Select a product')}
            search
            maxWidth={400}
            value={selectedProductId}
            options={productOptions}
            onChange={(e, { value }) => {
              setSelectedProductId(value);
            }}
          />
        </div>
      )}
      {languageOptions.length > 0 ? (
        <div className={styles['input-box']}>
          <Select
            label={t('Select language')}
            maxWidth={400}
            value={widgetLanguage}
            options={languageOptions}
            onChange={(e, { value }) => {
              setWidgetLanguage(value);
            }}
          />
        </div>
      ) : (
        <Message warning>
          {t(
            'No supported languages are configured for your organization. Please contact sales@nutmeglabs.com.'
          )}
        </Message>
      )}
      {calendarType === 'SINGLE_PRODUCT_CALENDAR' &&
        embedType === 'CALENDAR' &&
        selectedProductId && (
          <BookingWidgetEmbeddedSingleProductCalendar
            bookingWidgetApiKey={bookingWidgetApiKey}
            selectedProductId={selectedProductId}
            widgetLanguage={widgetLanguage}
          />
        )}
      {calendarType === 'SINGLE_PRODUCT_CALENDAR' &&
        embedType === 'BOOK_NOW_BUTTON' &&
        selectedProductId && (
          <BookingWidgetSingleProductBookNowButton
            bookingWidgetApiKey={bookingWidgetApiKey}
            selectedProductId={selectedProductId}
            widgetLanguage={widgetLanguage}
          />
        )}
      {calendarType === 'MULTI_PRODUCT_CALENDAR' &&
        embedType === 'CALENDAR' && (
          <BookingWidgetEmbeddedMultiProductCalendar
            bookingWidgetApiKey={bookingWidgetApiKey}
            widgetLanguage={widgetLanguage}
          />
        )}
      {calendarType === 'MULTI_PRODUCT_CALENDAR' &&
        embedType === 'BOOK_NOW_BUTTON' && (
          <BookingWidgetMultiProductBookNowButton
            bookingWidgetApiKey={bookingWidgetApiKey}
            widgetLanguage={widgetLanguage}
          />
        )}
      {calendarType === 'PRODUCT_GROUP_CALENDAR' &&
        embedType === 'CALENDAR' && (
          <BookingWidgetEmbeddedProductGroupCalendar
            bookingWidgetApiKey={bookingWidgetApiKey}
            widgetLanguage={widgetLanguage}
          />
        )}
      {calendarType === 'PRODUCT_GROUP_CALENDAR' &&
        embedType === 'BOOK_NOW_BUTTON' && (
          <BookingWidgetProductGroupBookNowButton
            bookingWidgetApiKey={bookingWidgetApiKey}
            widgetLanguage={widgetLanguage}
          />
        )}
    </div>
  );
};
