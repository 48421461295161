import { combineReducers } from 'redux';
import type { Action } from 'redux';

import {
  CREATE_EQUIPMENT_BLOCK_FAILURE,
  CREATE_EQUIPMENT_BLOCK_REQUEST,
  CREATE_EQUIPMENT_BLOCK_SUCCESS,
  DELETE_EQUIPMENT_BLOCK_FAILURE,
  DELETE_EQUIPMENT_BLOCK_REQUEST,
  DELETE_EQUIPMENT_BLOCK_SUCCESS,
  FETCH_EQUIPMENT_BLOCKS_FAILURE,
  FETCH_EQUIPMENT_BLOCKS_REQUEST,
  FETCH_EQUIPMENT_BLOCKS_SUCCESS,
  UPDATE_EQUIPMENT_BLOCK_FAILURE,
  UPDATE_EQUIPMENT_BLOCK_REQUEST,
  UPDATE_EQUIPMENT_BLOCK_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import type {
  EquipmentBlock,
  CreateEquipmentBlockResponse,
  ListEquipmentBlocksResponse,
  UpdateEquipmentBlockResponse,
} from 'shared/models/swagger';

export const equipmentBlocksSelector = (state: ReduxState) =>
  state.equipmentBlocks.all;

const error = (state = '', action: any): string => {
  switch (action.type) {
    case FETCH_EQUIPMENT_BLOCKS_FAILURE:
    case CREATE_EQUIPMENT_BLOCK_FAILURE:
    case UPDATE_EQUIPMENT_BLOCK_FAILURE:
    case DELETE_EQUIPMENT_BLOCK_FAILURE:
      return action.error;

    case FETCH_EQUIPMENT_BLOCKS_SUCCESS:
    case CREATE_EQUIPMENT_BLOCK_SUCCESS:
    case UPDATE_EQUIPMENT_BLOCK_SUCCESS:
    case DELETE_EQUIPMENT_BLOCK_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: Action): boolean => {
  switch (action.type) {
    case FETCH_EQUIPMENT_BLOCKS_REQUEST:
    case CREATE_EQUIPMENT_BLOCK_REQUEST:
    case UPDATE_EQUIPMENT_BLOCK_REQUEST:
    case DELETE_EQUIPMENT_BLOCK_REQUEST:
      return true;

    case FETCH_EQUIPMENT_BLOCKS_FAILURE:
    case CREATE_EQUIPMENT_BLOCK_FAILURE:
    case UPDATE_EQUIPMENT_BLOCK_FAILURE:
    case DELETE_EQUIPMENT_BLOCK_FAILURE:
    case FETCH_EQUIPMENT_BLOCKS_SUCCESS:
    case CREATE_EQUIPMENT_BLOCK_SUCCESS:
    case UPDATE_EQUIPMENT_BLOCK_SUCCESS:
    case DELETE_EQUIPMENT_BLOCK_SUCCESS:
      return false;

    default:
      return state;
  }
};

const all = (state: EquipmentBlock[] = [], action: any): EquipmentBlock[] => {
  switch (action.type) {
    case FETCH_EQUIPMENT_BLOCKS_SUCCESS: {
      const response = action.response as ListEquipmentBlocksResponse;
      return [...response.equipment_blocks];
    }

    case CREATE_EQUIPMENT_BLOCK_SUCCESS: {
      const response = action.response as CreateEquipmentBlockResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case UPDATE_EQUIPMENT_BLOCK_SUCCESS: {
      const response = action.response as UpdateEquipmentBlockResponse;
      return [...state.filter((i) => i.id !== response.id), response];
    }

    case DELETE_EQUIPMENT_BLOCK_SUCCESS: {
      return state.filter((i) => i.id !== action.id);
    }

    default:
      return state;
  }
};

export const equipmentBlocks = combineReducers({
  error,
  loading,
  all,
});
