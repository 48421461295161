import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Pseudo from 'i18next-pseudo';

import type { LanguageISO } from 'shared/libraries/i18n';
import type { TranslateFuncType } from 'client/components/Translate';
import { usePseudoLocalization, debugi18n } from 'client/debugging';
import type { SourceLanguage } from 'shared/models/swagger';
import enTranslations from 'client/libraries/i18n/resources/en.json';
import jaTranslations from 'client/libraries/i18n/resources/ja.json';
import koTranslations from 'client/libraries/i18n/resources/ko.json';
import zhCnTranslations from 'client/libraries/i18n/resources/zh-CN.json';
import zhTwTranslations from 'client/libraries/i18n/resources/zh-TW.json';

// When adding a language option, the following imports should also be updated.
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

type LanguageOption = {
  iso: LanguageISO;
  text: string;
  short: string;
  monthYearFormat: string;
  dayMonthFormat: string;
};
const languageOptions: LanguageOption[] = [
  {
    iso: 'en',
    text: 'English',
    short: 'en',
    monthYearFormat: 'MMM YYYY',
    dayMonthFormat: 'D MMM',
  },
  {
    iso: 'ja',
    text: '日本語',
    short: '日本語',
    monthYearFormat: 'YYYY年M月',
    dayMonthFormat: 'M月D日',
  },
];
const contentLanguageOptions: LanguageOption[] = [
  {
    iso: 'en',
    text: 'English',
    short: 'en',
    monthYearFormat: 'MMM YYYY',
    dayMonthFormat: 'D MMM',
  },
  {
    iso: 'ja',
    text: '日本語',
    short: '日本語',
    monthYearFormat: 'YYYY年M月',
    dayMonthFormat: 'M月D日',
  },
  {
    iso: 'ko',
    text: '한국어',
    short: '한국어',
    monthYearFormat: 'YYYY년 M월',
    dayMonthFormat: 'M월 D일',
  },
  {
    iso: 'zh-CN',
    text: '简体中文',
    short: '简',
    monthYearFormat: 'YYYY年M月',
    dayMonthFormat: 'M月D日',
  },
  {
    iso: 'zh-TW',
    text: '繁體中文',
    short: '繁',
    monthYearFormat: 'YYYY年M月',
    dayMonthFormat: 'M月D日',
  },
];
i18n
  .use(new Pseudo())
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: enTranslations,
      },
      ja: {
        translations: jaTranslations,
      },
      ko: {
        translations: koTranslations,
      },
      'zh-CN': {
        translations: zhCnTranslations,
      },
      'zh-TW': {
        translations: zhTwTranslations,
      },
    },
    fallbackLng: 'en',
    returnEmptyString: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    nsSeparator: ':::',
    keySeparator: false,
    // we use content as keys
    interpolation: {
      escapeValue: false,
      // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'translations',
    },
    // Debugging attributes
    debug: debugi18n,
    postProcess: usePseudoLocalization ? ['pseudo'] : undefined,
  });
let current_language =
  languageOptions.filter(
    (lang) => lang.iso === i18n.language // exact match first
  )[0] ||
  languageOptions.filter(
    (lang) => lang.iso === i18n.language.substring(0, 2) // blur match second
  )[0] ||
  languageOptions[0];

// fallback to English
i18n.setLanguage = function (iso: string) {
  current_language = languageOptions.filter((lang) => lang.iso === iso)[0];
  i18n.changeLanguage(iso);
};

// i18nTranslationKey is a special marker function indicating that a string should be added
// to translation files. This is useful when the string is not wrapped by i18n.t() directly.
const i18nTranslationKey = (s: string) => s;

// returns the translation function for the specified source language code
const to_language = (s: string) => i18n.getFixedT(s);

export const getLanguageName = (
  lang: LanguageISO | SourceLanguage,
  t: TranslateFuncType
): string => {
  switch (lang) {
    case 'ja':
    case 'JA_JP':
      return t('Japanese');

    case 'en':
    case 'EN_US':
      return t('English');

    case 'ko':
    case 'KO_KR':
      return t('Korean');

    case 'zh-CN':
    case 'ZH_CN':
      return t('Chinese (Simplified)');

    case 'zh-TW':
    case 'ZH_TW':
      return t('Chinese (Traditional)');

    default:
      return t('Unknown (this is a bug!!)');
  }
};
export {
  i18n,
  i18nTranslationKey,
  to_language,
  current_language,
  contentLanguageOptions,
  languageOptions,
};
