import * as React from 'react';

import * as Swagger from 'shared/models/swagger';
import { JourneyAnalyticsSiteContext } from 'client/contexts/JourneyAnalyticsSiteContext';

import { useAnalyticsCustomer } from './useAnalyticsCustomer';
import { useOnSiteAnalyticsCustomer } from './useOnSiteAnalyticsCustomer';

export const useSessions = ():
  | Swagger.AnalyticsCustomerSession[]
  | undefined => {
  const { site } = React.useContext(JourneyAnalyticsSiteContext);

  const websiteCustomer = useAnalyticsCustomer();
  const onSiteCustomer = useOnSiteAnalyticsCustomer();

  if (site === 'ON_SITE') {
    return onSiteCustomer?.sessions;
  }

  return websiteCustomer?.sessions;
};
