// @flow

import * as React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import { FieldsForm } from 'client/components/FieldsForm';
import { getFieldResponseErrors } from 'client/libraries/util/coreutil';
import {
  getGuestTypesUsedInProductInstance,
  getDisplayReservationFormFields,
} from 'client/libraries/util/util';
import type {
  Field,
  Product,
  ProductInstance,
  Reservation,
} from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';

type Props = {
  t: TranslateFuncType,
  showErrors: boolean,
  reservation: Reservation,
  product: Product,
  productInstance: ProductInstance,
  fieldFilter: (Field) => boolean,
  onChange?: (Object) => void,
  mode?: 'READ_ONLY' | 'TOGGLE' | 'INPUT',
  type?: 'BOOK' | 'EDIT',
};

const requiredSortValues = {
  WHEN_BOOKING: 0,
  BEFORE_PARTICIPATING: 1,
  OPTIONAL: 2,
};

// Sorting a list of fields using fieldCompare groups fields by their 'required' value,
// ordering them as follows:
// 1) required when booking
// 2) required before participating
// 3) optional
const fieldCompare = (f1: Field, f2: Field) => {
  const f1Value = f1.required && requiredSortValues[f1.required];
  const f2Value = f2.required && requiredSortValues[f2.required];

  if (f1Value === undefined || f2Value === undefined) {
    return 0;
  }

  return f1Value - f2Value;
};

class FieldsFormGroupComponent extends React.PureComponent<Props> {
  static defaultProps = {
    mode: 'INPUT',
    showErrors: false,
  };

  render() {
    const {
      showErrors,
      reservation,
      product,
      productInstance,
      fieldFilter,
      mode,
      onChange,
      type,
      t,
    } = this.props;

    const perBookingFields = getDisplayReservationFormFields(
      product.reservation_form_fields || [],
      t
    )
      .filter((f) => fieldFilter(f) && f.type === 'PER_BOOKING')
      .sort(fieldCompare);
    const perParticipantFields = getDisplayReservationFormFields(
      product.reservation_form_fields || [],
      t
    )
      .filter((f) => fieldFilter(f) && f.type === 'PER_PARTICIPANT')
      .sort(fieldCompare);

    const errorMap = showErrors
      ? getFieldResponseErrors(
          reservation.field_responses || [],
          perBookingFields,
          t
        )
      : {};

    return (
      <React.Fragment>
        {perBookingFields.length > 0 && (
          <FieldsForm
            errorMap={errorMap}
            fields={perBookingFields}
            unmappedFieldResponses={
              reservation.field_responses &&
              reservation.field_responses.filter(
                (field_response) =>
                  !perBookingFields
                    .map((field) => field.key)
                    .includes(field_response.key)
              )
            }
            getFieldValue={(key) => {
              const r = (reservation.field_responses || []).find(
                (r) => r.key === key
              );
              return (r && r.response) || '';
            }}
            onFieldChange={(key, value) =>
              onChange &&
              onChange({
                field_responses: [
                  ...(reservation.field_responses || []).filter(
                    (r) => r.key !== key
                  ),
                  {
                    key,
                    response: value,
                  },
                ],
              })
            }
            mode={mode}
          />
        )}
        {reservation.guests.map((g, idx) => {
          const guestErrorMap = showErrors
            ? getFieldResponseErrors(
                g.field_responses || [],
                perParticipantFields,
                t
              )
            : {};

          const unmappedFieldResponses =
            (g.field_responses &&
              g.field_responses.filter((field_response) => {
                // hacky. if it's per guest field_response,
                // for a field response to be considered "unmapped field",
                // it has to be not in either per guest form or per booking form,
                // as the first guest has per booking info too, from backend.
                return (
                  !perParticipantFields
                    .map((field) => field.key)
                    .includes(field_response.key) &&
                  !perBookingFields
                    .map((field) => field.key)
                    .includes(field_response.key)
                );
              })) ||
            [];

          if (
            perParticipantFields.length <= 0 &&
            unmappedFieldResponses.length <= 0
          ) {
            return <div />;
          }

          const productGuestTypes = getGuestTypesUsedInProductInstance(
            productInstance,
            product,
            this.props.t
          );

          return (
            <Segment key={idx}>
              <Header as="h3">
                {g.guest_type_title ||
                  ((productGuestTypes.find(
                    (guest_type) => guest_type.key === g.guest_type_key
                  ): any)
                    ? (productGuestTypes.find(
                        (guest_type) => guest_type.key === g.guest_type_key
                      ): any).title
                    : g.guest_type_key)}
              </Header>
              <FieldsForm
                errorMap={guestErrorMap}
                fields={perParticipantFields}
                unmappedFieldResponses={unmappedFieldResponses}
                getFieldValue={(key) => {
                  const r = (g.field_responses || []).find(
                    (r) => r.key === key
                  );
                  return (r && r.response) || '';
                }}
                onFieldChange={(key, value) =>
                  onChange &&
                  onChange({
                    guests: reservation.guests.map((g2, idx2) =>
                      idx === idx2
                        ? {
                            ...g2,
                            field_responses: [
                              ...(g2.field_responses || []).filter(
                                (r) => r.key !== key
                              ),
                              { key, response: value },
                            ],
                          }
                        : g2
                    ),
                  })
                }
                mode={mode}
                type={type}
              />
            </Segment>
          );
        })}
      </React.Fragment>
    );
  }
}

export const FieldsFormGroup = withTranslation()(FieldsFormGroupComponent);
