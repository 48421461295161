import _ from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { ReduxState } from 'client/reducers';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { currency } from 'shared/libraries/currency';
import { histogram } from 'client/libraries/util/util';
import { formattedAmount } from 'client/libraries/util/formattedAmount';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type {
  Guest,
  ManifestProductGroup,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  reservations: ManifestReservationShape[];
  supplierCurrency: string;
};
type StartTimeLine = {
  reservationCount: number;
  pax: number;
  guests: Guest[];
  amountGross: number;
  amountPOB: number;
  amountPIF: number;
};
type ProductLine = {
  productId: string;
  productName: string;
  startTimeLines: Record<string, StartTimeLine>;
  reservationId: string;
};

export const ManifestSummary = ({ reservations, supplierCurrency }: Props) => {
  const { t } = useTranslation();
  const [isActives, setIsActives] = useState<Record<string, boolean>>({});
  const productGroups = useSelector(manifestProductGroupsSelector);
  const summaries = useSelector(summariesSortedByBookmarkedSelector);
  const productGroupsForDisplay = productGroups.filter((productGroup) => {
    return productGroup.used_for_grouping;
  });
  const lastManifestDisplaySettings = useSelector(
    (state: ReduxState) =>
      state.manifestDisplaySettings.lastManifestDisplaySettings
  );
  const rootResourceProductIds = productGroupsForDisplay.find(
    (group: ManifestProductGroup) =>
      group.key == lastManifestDisplaySettings.rootResource.id
  )?.product_ids;

  const productLines: ProductLine[] = [];

  for (let i = 0; i < reservations.length; i++) {
    const amountGross = reservations[i].amount_gross;

    if (
      amountGross &&
      amountGross.length > 3 &&
      !amountGross.startsWith(supplierCurrency)
    ) {
      continue;
    }

    let productLine = productLines.find(
      (productLine) =>
        productLine.productId === reservations[i].product_id &&
        !reservations[i].is_free_format_reservation
    );

    if (!productLine) {
      productLine = {
        productId: reservations[i].product_id,
        productName:
          summaries.find((p) => p.id == reservations[i].product_id)
            ?.internal_product_name ?? reservations[i].product_name,
        startTimeLines: {},
        reservationId: reservations[i].id,
      };
      productLines.push(productLine);
    }

    const startTime = reservations[i].participates_at.format('HH:mm');
    let startTimeLine = productLine.startTimeLines[startTime];

    if (!startTimeLine) {
      startTimeLine = {
        reservationCount: 0,
        pax: 0,
        guests: [],
        amountGross: 0,
        amountPOB: 0,
        amountPIF: 0,
      };
      productLine.startTimeLines[startTime] = startTimeLine;
    }

    const grossValue = currency(reservations[i].amount_gross).value;
    const pobValue = currency(reservations[i].amount_pob).value;
    const pifValue = grossValue - pobValue;
    startTimeLine.reservationCount += 1;
    startTimeLine.pax += reservations[i].guests.length;
    startTimeLine.guests = [...startTimeLine.guests, ...reservations[i].guests];
    startTimeLine.amountGross += grossValue;
    startTimeLine.amountPOB += pobValue;
    startTimeLine.amountPIF += pifValue;
  }

  const getUnits = (guests: Guest[]): string => {
    const reservationGuestCounts = histogram(
      guests,
      (g) => g.guest_type_title || g.guest_type_key
    );
    return Object.keys(reservationGuestCounts)
      .map(
        (guestTitle) => `${guestTitle}: ${reservationGuestCounts[guestTitle]}`
      )
      .join(', ');
  };

  const sortSummaries = rootResourceProductIds
    ? rootResourceProductIds.map((p) => summaries.find((elm) => elm.id == p))
    : summaries;
  const sortedProductLines = productLines.sort((a, b) => {
    const tmp_a = sortSummaries.find((p) => p?.id == a.productId);
    const tmp_b = sortSummaries.find((p) => p?.id == b.productId);

    if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) <
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return -1;
    } else if (
      sortSummaries.indexOf(tmp_a ?? ({} as ProductSummary)) >
      sortSummaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return 1;
    }

    return 0;
  });
  let totalReservationCount = 0;
  let totalPax = 0;
  let totalGuests: Guest[] = [];
  let totalAmountGross = 0;
  let totalAmountPOB = 0;
  let totalAmountPIF = 0;

  return (
    <div>
      {reservations.length === 0 && (
        <div
          style={{
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t(
            'There is no reservation that matches the conditions. Please change participation date or Product/Group.'
          )}
        </div>
      )}
      {reservations.length !== 0 && (
        <>
          <div className={styles['p-manifestsTable']}>
            <table
              className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr>
                  <th style={{ width: '20%', borderRight: 'none' }}>
                    {t('Product')}
                  </th>
                  <th style={{ width: '15%' }}>{t('Reservations')}</th>
                  <th style={{ width: '15%' }}>{t('Total Pax')}</th>
                  <th style={{ width: '20%' }}>{t('Units')}</th>
                  <th style={{ width: '15%' }}>{t('Gross')}</th>
                  <th style={{ width: '15%' }}>{t('PIF Amount')}</th>
                  <th style={{ width: '15%' }}>{t('POB Amount')}</th>
                </tr>
              </thead>
              <>
                {sortedProductLines.map((productLine, idx) => {
                  let reservationCount = 0;
                  let pax = 0;
                  let guests: Guest[] = [];
                  let amountGross = 0;
                  let amountPOB = 0;
                  let amountPIF = 0;

                  const keys = _.sortBy(
                    Object.keys(productLine.startTimeLines),
                    (key) => key
                  );

                  for (let i = 0; i < keys.length; i++) {
                    const startTimeLine = productLine.startTimeLines[keys[i]];
                    reservationCount += startTimeLine.reservationCount;
                    pax += startTimeLine.pax;
                    guests = [...guests, ...startTimeLine.guests];
                    amountGross += startTimeLine.amountGross;
                    amountPOB += startTimeLine.amountPOB;
                    amountPIF += startTimeLine.amountPIF;
                  }

                  totalReservationCount += reservationCount;
                  totalPax += pax;
                  totalGuests = [...totalGuests, ...guests];
                  totalAmountGross += amountGross;
                  totalAmountPOB += amountPOB;
                  totalAmountPIF += amountPIF;

                  const lineKey = productLine.productId
                    ? productLine.productId
                    : productLine.reservationId;
                  return (
                    <tbody key={idx}>
                      <tr
                        className={clsx(
                          isActives[lineKey] ? styles['is-active'] : ''
                        )}
                      >
                        <td className={tableStyles['c-tableSmall__spTtl']}>
                          <div
                            className={styles['acBtn']}
                            style={{ marginLeft: 0 }}
                          >
                            <div className={baseStyles['u-spHidden']}>
                              <Button
                                color="secondary"
                                size="icon"
                                iconAfterText={
                                  isActives[lineKey] ? (
                                    <i className="c-icon-outline-general-minus"></i>
                                  ) : (
                                    <i className="c-icon-outline-general-plus"></i>
                                  )
                                }
                                onClick={() => {
                                  const newIsActives = { ...isActives };
                                  newIsActives[lineKey] =
                                    !newIsActives[lineKey];
                                  setIsActives(newIsActives);
                                }}
                              />
                            </div>
                            {productLine.productName}
                          </div>
                        </td>
                        <td
                          style={{ textAlign: 'right' }}
                          data-text={t('Reservations')}
                        >
                          {reservationCount}
                        </td>
                        <td
                          style={{ textAlign: 'right' }}
                          data-text={t('Total Pax')}
                        >
                          {pax}
                        </td>
                        <td data-text={t('Units')}>{getUnits(guests)}</td>
                        <td
                          data-text={t('Gross')}
                          style={{ textAlign: 'right' }}
                        >
                          {formattedAmount(amountGross, supplierCurrency)}
                        </td>
                        <td
                          data-text={t('PIF Amount')}
                          style={{ textAlign: 'right' }}
                        >
                          {formattedAmount(amountPIF, supplierCurrency)}
                        </td>
                        <td
                          data-text={t('POB Amount')}
                          style={{ textAlign: 'right' }}
                        >
                          {formattedAmount(amountPOB, supplierCurrency)}
                        </td>
                        <td
                          className={clsx(
                            baseStyles['u-pcHidden'],
                            styles['spAcBtn']
                          )}
                        >
                          <Button
                            color="secondary"
                            size="icon"
                            text={
                              isActives[lineKey]
                                ? t('Hide')
                                : t('Show start time summary')
                            }
                            iconAfterText={
                              isActives[lineKey] ? (
                                <i className="c-icon-solid-arrows-chevron-up"></i>
                              ) : (
                                <i className="c-icon-solid-arrows-chevron-down"></i>
                              )
                            }
                            onClick={() => {
                              const newIsActives = { ...isActives };
                              newIsActives[lineKey] = !newIsActives[lineKey];
                              setIsActives(newIsActives);
                            }}
                            useFullWidth={true}
                          />
                        </td>
                      </tr>
                      {isActives[lineKey] &&
                        keys.map((key, idx) => {
                          const startTimeLine = productLine.startTimeLines[key];
                          return (
                            <tr key={idx}>
                              <td
                                className={
                                  styles['p-manifestsTable__summary__firstCell']
                                }
                                data-text={t('Start time')}
                              >
                                {key}
                              </td>
                              <td
                                style={{ textAlign: 'right' }}
                                data-text={t('Reservations')}
                              >
                                {startTimeLine.reservationCount}
                              </td>
                              <td
                                style={{ textAlign: 'right' }}
                                data-text={t('Total Pax')}
                              >
                                {startTimeLine.pax}
                              </td>
                              <td data-text={t('Units')}>
                                {getUnits(startTimeLine.guests)}
                              </td>
                              <td
                                style={{ textAlign: 'right' }}
                                data-text={t('Gross')}
                              >
                                {formattedAmount(
                                  startTimeLine.amountGross,
                                  supplierCurrency
                                )}
                              </td>
                              <td
                                style={{ textAlign: 'right' }}
                                data-text={t('PIF Amount')}
                              >
                                {formattedAmount(
                                  startTimeLine.amountPIF,
                                  supplierCurrency
                                )}
                              </td>
                              <td
                                style={{ textAlign: 'right' }}
                                data-text={t('POB Amount')}
                              >
                                {formattedAmount(
                                  startTimeLine.amountPOB,
                                  supplierCurrency
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  );
                })}
              </>
              <tfoot>
                <tr
                  style={{
                    marginTop: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  <td className={tableStyles['c-tableSmall__spTtl']}>
                    {t('Total')}
                  </td>
                  <td
                    data-text={t('Reservations')}
                    style={{ textAlign: 'right' }}
                  >
                    {totalReservationCount}
                  </td>
                  <td data-text={t('Total Pax')} style={{ textAlign: 'right' }}>
                    {totalPax}
                  </td>
                  <td data-text={t('Units')}>{getUnits(totalGuests)}</td>
                  <td data-text={t('Gross')} style={{ textAlign: 'right' }}>
                    {formattedAmount(totalAmountGross, supplierCurrency)}
                  </td>
                  <td
                    data-text={t('PIF Amount')}
                    style={{ textAlign: 'right' }}
                  >
                    {formattedAmount(totalAmountPIF, supplierCurrency)}
                  </td>
                  <td
                    data-text={t('POB Amount')}
                    style={{ textAlign: 'right' }}
                  >
                    {formattedAmount(totalAmountPOB, supplierCurrency)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className={baseStyles['u-mt-4']}>
            <p style={{ textAlign: 'right' }}>
              {t('Currency Code') + ' : ' + supplierCurrency}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
