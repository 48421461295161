import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';

import { Input, Button, TextArea, ToggleButton } from 'client/components/Form';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
} from 'client/libraries/i18n';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getValidators } from 'shared/libraries/validate/validator';
import { getArrayMutators } from 'client/libraries/util/form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { Loading } from 'client/pages/Loading';
import { updateFaqSettings } from 'client/actions/essentialPages';
import { Message } from 'client/components/Message/Message';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import * as Swagger from 'shared/models/swagger';
import type { SourceLanguage, FaqSettings } from 'shared/models/swagger';

import styles from './FaqEditor.module.css';

export type Translation = {
  source: string;
  target: string;
};
type TranslationsFormValues = {
  translations: Translation[];
};

const getInitialTranslationValues = (
  defaultLanguage: Swagger.SourceLanguage | null,
  apiTranslations: Swagger.Translation[],
  translationTargetLanguage: Swagger.SourceLanguage | null
): TranslationsFormValues => {
  let translations: { source: string; target: string }[] = [];

  if (defaultLanguage && translationTargetLanguage) {
    const sourceFieldName = defaultLanguage.toLowerCase();
    const translationFieldName = translationTargetLanguage.toLowerCase();
    translations = apiTranslations.map((trans: any) => ({
      source: trans[sourceFieldName],
      target: trans[translationFieldName],
    }));
  }

  return {
    translations,
  };
};

export const FaqEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>('EN_US');
  const { required } = getValidators(t);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  React.useEffect(() => {
    if (showTranslationMode) {
      const texts: string[] = [];
      (
        activeUserOrganization?.essential_pages?.faq_settings?.faqs || []
      ).forEach((faq) => {
        if (faq?.category_title) {
          texts.push(faq.category_title);
        }

        (faq.question_and_answer || []).forEach((qAndA) => {
          if (qAndA?.question) {
            texts.push(qAndA.question);
          }

          if (qAndA?.answer) {
            texts.push(qAndA.answer);
          }
        });
      });
      dispatch(batchGetTranslations(texts));
    }
  }, [showTranslationMode, activeUserOrganization]);
  const initialValuesWithTranslations = React.useMemo(
    () => ({
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
      faqs: activeUserOrganization?.essential_pages?.faq_settings?.faqs || [],
      visibility:
        activeUserOrganization?.essential_pages?.faq_settings?.visibility ||
        false,
    }),
    [apiTranslations, translationTargetLanguage, activeUserOrganization]
  );

  if (!activeUserOrganization) {
    return <Loading />;
  }

  const changeShowTranslationMode = (show: boolean) => {
    setShowTranslationMode(show);
  };

  const changeTranslationTargetLanguage = (lang: SourceLanguage) => {
    setTranslationTargetLanguage(lang);
  };

  return (
    <div className={baseStyles['base-main__body']}>
      <SettingTranslationLanguageSelector
        showTranslationMode={showTranslationMode}
        onShowTranslationModeChange={changeShowTranslationMode}
        translationTargetLanguage={translationTargetLanguage}
        onTranslationTargetLanguageChange={changeTranslationTargetLanguage}
      />

      <Form
        onSubmit={async (values: FaqSettings & TranslationsFormValues) => {
          try {
            if (showTranslationMode) {
              await Promise.all([
                dispatch(updateFaqSettings(values)),
                dispatch(
                  updateTranslations(
                    values.translations.map((translation) => ({
                      source_language: defaultLanguage,
                      [defaultLanguage.toLowerCase()]: translation.source,
                      [translationTargetLanguage.toLowerCase()]:
                        translation.target,
                    }))
                  )
                ),
              ]);
            } else {
              await dispatch(updateFaqSettings(values));
            }

            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={initialValuesWithTranslations}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting, form, values }) => {
          React.useEffect(() => {
            let translations: { source: string; target: string }[] = [];

            if (defaultLanguage && translationTargetLanguage) {
              const defaultFieldName = defaultLanguage.toLowerCase();
              const translationFieldName =
                translationTargetLanguage.toLowerCase();
              translations = apiTranslations.map((trans: any) => ({
                source: trans[defaultFieldName],
                target: trans[translationFieldName],
              }));
            }

            form.change('translations', translations);
          }, [
            defaultLanguage,
            apiTranslations,
            translationTargetLanguage,
            showTranslationMode,
          ]);
          return (
            <form onSubmit={handleSubmit}>
              {false && <pre>{JSON.stringify(values, undefined, 2)}</pre>}
              <div className={baseStyles['base-main__body__box']}>
                <div className={baseStyles['base-main__body__box__body']}>
                  <div className={styles['acBody']}>
                    <FieldArray name="faqs">
                      {({ fields }) => {
                        return fields.length === 0 ? (
                          <AddIcon
                            onClick={() => (fields as any).insertAt(0, '')}
                          />
                        ) : (
                          <div className={styles['c-table-list']}>
                            <table>
                              <tbody>
                                <tr>
                                  <th>{t('Visibility')}</th>
                                  <td colSpan={2}>
                                    <Field name="visibility" type="checkbox">
                                      {({ input }) => (
                                        <div className={styles['select']}>
                                          <ToggleButton
                                            label={t(
                                              'Show FAQ page on booking website'
                                            )}
                                            {...input}
                                          />
                                        </div>
                                      )}
                                    </Field>
                                  </td>
                                  <td
                                    className={clsx(
                                      styles['c-table-list__btns'],
                                      styles['hidden']
                                    )}
                                  >
                                    {' '}
                                  </td>
                                </tr>
                                {fields.map((name, idx) => (
                                  <tr key={name}>
                                    <th>
                                      {' '}
                                      {t('Category #{{idx}}', {
                                        idx: idx + 1,
                                      })}{' '}
                                    </th>

                                    <td colSpan={2}>
                                      <ul className={styles['scheds']}>
                                        <li className={styles['scheds__cell']}>
                                          <TranslatedField
                                            name={`${name}.category_title`}
                                            validate={required}
                                          >
                                            {({
                                              input,
                                              translationInput,
                                              meta: { error, touched },
                                            }) => (
                                              <>
                                                <div>
                                                  <p
                                                    className={
                                                      styles['scheds__ttl']
                                                    }
                                                  >
                                                    {' '}
                                                    {t('Category Title')}{' '}
                                                  </p>
                                                  <p
                                                    className={
                                                      styles['scheds__body']
                                                    }
                                                  >
                                                    <Input
                                                      {...input}
                                                      maxWidth={800}
                                                      error={touched && error}
                                                    />
                                                  </p>
                                                </div>
                                                {showTranslationMode && (
                                                  <div>
                                                    <p
                                                      className={
                                                        styles['scheds__ttl']
                                                      }
                                                    >{`${t(
                                                      'Category Title'
                                                    )} (${getLanguageName(
                                                      uppercaseIsoToLowercaseIso[
                                                        translationTargetLanguage
                                                      ],
                                                      t
                                                    )})`}</p>
                                                    <p
                                                      className={
                                                        styles['scheds__body']
                                                      }
                                                    >
                                                      <Input
                                                        {...translationInput}
                                                        maxWidth={800}
                                                        error={touched && error}
                                                      />
                                                    </p>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </TranslatedField>
                                        </li>

                                        <li className={styles['scheds__cell']}>
                                          <div
                                            className={styles['scheds__body']}
                                          >
                                            <FieldArray
                                              name={`${name}.question_and_answer`}
                                            >
                                              {({ fields }) => {
                                                return fields.length === 0 ? (
                                                  <AddIcon
                                                    onClick={() =>
                                                      (fields as any).insertAt(
                                                        0,
                                                        ''
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <table
                                                    className={
                                                      styles[
                                                        'scheds__body__content'
                                                      ]
                                                    }
                                                  >
                                                    {fields.map(
                                                      (name, formIdx) => (
                                                        <tr key={name}>
                                                          <td>
                                                            <div
                                                              className={
                                                                styles[
                                                                  'scheds__body__content__body'
                                                                ]
                                                              }
                                                            >
                                                              <TranslatedField
                                                                name={`${name}.question`}
                                                                validate={
                                                                  required
                                                                }
                                                              >
                                                                {({
                                                                  input,
                                                                  translationInput,
                                                                  meta: {
                                                                    error,
                                                                    touched,
                                                                  },
                                                                }) => (
                                                                  <>
                                                                    <div
                                                                      className={
                                                                        styles[
                                                                          'scheds__body__form'
                                                                        ]
                                                                      }
                                                                    >
                                                                      <p
                                                                        className={
                                                                          styles[
                                                                            'scheds__ttl'
                                                                          ]
                                                                        }
                                                                      >
                                                                        {' '}
                                                                        {t(
                                                                          'Question'
                                                                        )}{' '}
                                                                      </p>
                                                                      <p
                                                                        className={
                                                                          styles[
                                                                            'scheds__body'
                                                                          ]
                                                                        }
                                                                      >
                                                                        {' '}
                                                                        <Input
                                                                          {...input}
                                                                          error={
                                                                            touched &&
                                                                            error
                                                                          }
                                                                        />{' '}
                                                                      </p>
                                                                    </div>
                                                                    {showTranslationMode && (
                                                                      <div
                                                                        className={
                                                                          styles[
                                                                            'scheds__body__form'
                                                                          ]
                                                                        }
                                                                      >
                                                                        <p
                                                                          className={
                                                                            styles[
                                                                              'scheds__ttl'
                                                                            ]
                                                                          }
                                                                        >{`${t(
                                                                          'Question'
                                                                        )} (${getLanguageName(
                                                                          uppercaseIsoToLowercaseIso[
                                                                            translationTargetLanguage
                                                                          ],
                                                                          t
                                                                        )})`}</p>
                                                                        <p
                                                                          className={
                                                                            styles[
                                                                              'scheds__body'
                                                                            ]
                                                                          }
                                                                        >
                                                                          {' '}
                                                                          <Input
                                                                            {...translationInput}
                                                                            {...{
                                                                              height: 50,
                                                                            }}
                                                                            error={
                                                                              touched &&
                                                                              error
                                                                            }
                                                                          />{' '}
                                                                        </p>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </TranslatedField>
                                                            </div>

                                                            <div
                                                              className={
                                                                styles[
                                                                  'scheds__body__content__body'
                                                                ]
                                                              }
                                                            >
                                                              <TranslatedField
                                                                name={`${name}.answer`}
                                                                validate={
                                                                  required
                                                                }
                                                              >
                                                                {({
                                                                  input,
                                                                  translationInput,
                                                                  meta: {
                                                                    error,
                                                                    touched,
                                                                  },
                                                                }) => (
                                                                  <>
                                                                    <div
                                                                      className={
                                                                        styles[
                                                                          'scheds__body__form'
                                                                        ]
                                                                      }
                                                                    >
                                                                      <p
                                                                        className={
                                                                          styles[
                                                                            'scheds__ttl'
                                                                          ]
                                                                        }
                                                                      >
                                                                        {' '}
                                                                        {t(
                                                                          'Answer'
                                                                        )}{' '}
                                                                      </p>
                                                                      <p
                                                                        className={
                                                                          styles[
                                                                            'scheds__body'
                                                                          ]
                                                                        }
                                                                      >
                                                                        {' '}
                                                                        <TextArea
                                                                          {...(input as any)}
                                                                          error={
                                                                            touched &&
                                                                            error
                                                                          }
                                                                          height={
                                                                            100
                                                                          }
                                                                        />{' '}
                                                                      </p>
                                                                    </div>
                                                                    {showTranslationMode && (
                                                                      <div
                                                                        className={
                                                                          styles[
                                                                            'scheds__body__form'
                                                                          ]
                                                                        }
                                                                      >
                                                                        <p
                                                                          className={
                                                                            styles[
                                                                              'scheds__ttl'
                                                                            ]
                                                                          }
                                                                        >{`${t(
                                                                          'Answer'
                                                                        )} (${getLanguageName(
                                                                          uppercaseIsoToLowercaseIso[
                                                                            translationTargetLanguage
                                                                          ],
                                                                          t
                                                                        )})`}</p>
                                                                        <p
                                                                          className={
                                                                            styles[
                                                                              'scheds__body'
                                                                            ]
                                                                          }
                                                                        >
                                                                          {' '}
                                                                          <TextArea
                                                                            {...(translationInput as any)}
                                                                            error={
                                                                              touched &&
                                                                              error
                                                                            }
                                                                            height={
                                                                              100
                                                                            }
                                                                          />{' '}
                                                                        </p>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </TranslatedField>
                                                            </div>
                                                          </td>
                                                          <td
                                                            className={
                                                              styles[
                                                                'c-table-list__btns'
                                                              ]
                                                            }
                                                          >
                                                            <AddIcon
                                                              onClick={() =>
                                                                (
                                                                  fields as any
                                                                ).insertAt(
                                                                  formIdx + 1,
                                                                  ''
                                                                )
                                                              }
                                                            />
                                                            <DeleteIcon
                                                              onClick={() =>
                                                                fields.remove(
                                                                  formIdx
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </table>
                                                );
                                              }}
                                            </FieldArray>
                                          </div>
                                        </li>
                                      </ul>
                                    </td>

                                    <td
                                      className={styles['c-table-list__btns']}
                                    >
                                      <AddIcon
                                        onClick={() =>
                                          (fields as any).insertAt(idx + 1, '')
                                        }
                                      />
                                      <DeleteIcon
                                        onClick={() => fields.remove(idx)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      loading={submitting}
                      size="small"
                      style="blue"
                      type="submit"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
