import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import type { GraphDataItem } from 'client/libraries/util/reservationReport';
//import { v3Colors } from 'client/libraries/util/colors';
import { formattedAmount } from 'client/libraries/util/reservationReport';

type Props = {
  data: GraphDataItem[];
  keys: string[];
  names: Record<string, string>;
  currencyCode: string | null;
};
export const LineChartPane = ({ data, keys, names, currencyCode }: Props) => {
  return (
    <ResponsiveContainer width="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) => {
            return formattedAmount(value, currencyCode);
          }}
        />
        <Tooltip
          formatter={(value: any) => {
            return formattedAmount(value, currencyCode);
          }}
        />
        <Legend />
        {keys.map((key, idx) => {
          let color = '#3B82F6';
          if (idx === 1) {
            color = '#EC4899';
          }
          return (
            <Line
              legendType={'circle'}
              key={idx}
              dataKey={key}
              stroke={color}
              strokeWidth={3}
              {...{ connectNull: true }}
              activeDot={{
                r: 8,
              }}
              name={names[key]}
              type="monotone"
              dot={{
                fill: color,
                r: 3,
              }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
