import { v3AvailabilityColors } from 'client/libraries/util/colors';
import { ProductWithOccupancy } from 'client/pages/Availability/util';

export type ProductWithOccupancyPlusColor = ProductWithOccupancy & {
  cardColor?: {
    color: string;
    backgroundColor: string;
    borderColor: string;
  };
};

export const assignColorsToProducts = (
  products: ProductWithOccupancy[]
): ProductWithOccupancyPlusColor[] => {
  return products.map((product, index) => ({
    ...product,
    cardColor: v3AvailabilityColors[index % v3AvailabilityColors.length],
  }));
};

type ColorClassName = 'gray' | 'red1' | 'red2' | 'red3' | 'red4' | 'red5' | '';

export const getAvailabilityBackgroundColorClass = (
  bookedSlots: number,
  totalSlots: number,
  isClosed: boolean
): ColorClassName => {
  if (isClosed) {
    return 'gray';
  }

  if (totalSlots === 0) {
    if (bookedSlots !== 0) {
      return 'red5';
    }
  } else {
    if (bookedSlots >= totalSlots) {
      return 'red4';
    }

    // Free sale
    if (totalSlots === 10000000 && bookedSlots > 0) {
      return 'red1';
    }

    const ratio = bookedSlots / totalSlots;

    if (ratio >= 0.8) {
      return 'red3';
    }

    if (ratio >= 0.5) {
      return 'red2';
    }

    if (ratio > 0) {
      return 'red1';
    }
  }

  return '';
};
