import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import baseStyles from 'client/base.module.css';

type Props = {
  name: string;
};
export const LocationListEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  return (
    <FieldArray name={name}>
      {({ fields, meta: { error, touched } }) => (
        <>
          {fields.length === 0 && (
            <AddIcon
              onClick={() =>
                (fields as any).insertAt(0, {
                  googlePlaceId: '',
                  locationName: '',
                })
              }
            />
          )}
          {fields.map((name, idx) => (
            <Field
              key={name}
              name={name}
              validate={(value: any | null | undefined): string => {
                if (!value?.locationName) return t('Required');
                return '';
              }}
            >
              {({ input, meta: { touched, error } }) => (
                <Box
                  mb={2}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <LocationSearchInput
                      location={input.value.locationName}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onSearchChange={() => {}}
                      onLocationSelect={({ title, key }) =>
                        input.onChange({
                          locationName: title,
                          googlePlaceId: key,
                        })
                      }
                      error={touched && error}
                      maxWidth={800}
                    />
                  </div>
                  <Box display="flex">
                    <Box ml={2}>
                      <AddIcon
                        onClick={() =>
                          (fields as any).insertAt(idx + 1, {
                            googlePlaceId: '',
                            locationName: '',
                          })
                        }
                      />
                    </Box>
                    <Box ml={2}>
                      <DeleteIcon onClick={() => fields.remove(idx)} />
                    </Box>
                  </Box>
                </Box>
              )}
            </Field>
          ))}
          {error && touched && (
            <p className={baseStyles['base-form-box__err']}>{error}</p>
          )}
        </>
      )}
    </FieldArray>
  );
};
