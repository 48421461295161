// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { ListEditor } from 'client/components/NewProductEditor/DetailsStep/ListEditor';
import { getValidators } from 'shared/libraries/validate/validator';
import { Box } from 'client/components/Box/Box';
import { Select } from 'client/components/Form';
import { summariesWithBookmarksSelector } from 'client/reducers/products';

import { TranslatedInput } from '../TranslatedInput';
import { TranslatedTextArea } from '../TranslatedTextArea';

type Props = {
  name: string,
};

export const PerProductMessageEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const { required } = getValidators(t);

  const products = useSelector(summariesWithBookmarksSelector);

  const productOptions = React.useMemo(
    () =>
      products.map((product) => ({
        value: product.id,
        text: getVerboseDisplayProductName(product),
      })),
    [products]
  );

  return (
    <div>
      <Box>
        <Field name={`${name}.productId`} validate={required}>
          {({ input, meta: { touched, error } }) => (
            <Select
              label={t('Product')}
              search
              value={input.value}
              options={productOptions}
              onChange={(e, { value }) => input.onChange(value)}
              error={touched && error}
            />
          )}
        </Field>
      </Box>
      <TranslatedInput name={`${name}.title`} label={t('Title')} />
      <TranslatedTextArea
        name={`${name}.description`}
        label={t('Description')}
      />
      <ListEditor name={`${name}.listItems`} title={t('List Items')} />
    </div>
  );
};
