import * as Swagger from 'shared/models/swagger';

type ItineraryItem = {
  timeOffsetStart: string;
  timeOffsetEnd: string;
  title: string;
  description: string;
};
export type ItineraryFormValues = {
  itinerary: ItineraryItem[];
};
export const getDefaultItineraryItem = () => {
  return {
    timeOffsetStart: '',
    timeOffsetEnd: '',
    title: '',
    description: '',
  };
};
export const getInitialItineraryFormValues = (
  product: Swagger.Product | null
): ItineraryFormValues => {
  return {
    itinerary:
      product?.itinerary && product.itinerary.length > 0
        ? product.itinerary.map((itineraryItem) => ({
            timeOffsetStart: itineraryItem.start_time_relative ?? '',
            timeOffsetEnd: itineraryItem.end_time_relative ?? '',
            title: itineraryItem.title ?? '',
            description: itineraryItem.description ?? '',
          }))
        : [],
  };
};
export const convertItineraryFormValuesToProductPatch = (
  values: ItineraryFormValues
): Swagger.Product$Patch => ({
  itinerary: values.itinerary.map((item) => ({
    start_time_relative: item.timeOffsetStart,
    end_time_relative: item.timeOffsetEnd,
    title: item.title,
    description: item.description,
  })),
});
