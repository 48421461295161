// @flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import compose from 'lodash/fp/compose';
import ReactTable from 'react-table';
import { Button, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';

import { fetchContracts } from 'client/actions/contract';
import {
  agentMapSelector,
  supplierMapSelector,
} from 'client/reducers/organizations';
import { EditContractModal } from 'client/pages/Manage/ContractsTab/EditContractModal';
import { DeleteContractModal } from 'client/pages/Manage/ContractsTab/DeleteContractModal';
import { CreateContractModal } from 'client/pages/Manage/ContractsTab/CreateContractModal';
import type { ReduxState } from 'client/reducers';

type State = {};
type OwnProps = {};

/* eslint-disable no-use-before-define */
type Props = {|
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapStateToProps, *, *>,
  ...$Call<typeof mapDispatchToProps, *>,
|};
/* eslint-enable no-use-before-define */

class ContractsTabComponent extends React.Component<Props, State> {
  componentDidMount() {
    this.props.fetchContracts();
  }

  //Preparing or Mapping data for displaying in tables
  data = () => {
    let { contracts, agentsMap, suppliersMap } = this.props;
    let data = [];
    if (contracts) {
      data = contracts.map((contract) => {
        return {
          id: contract.id,
          agent_name: agentsMap[contract.agent_id],
          supplier_name: suppliersMap[contract.supplier_id],
          contract_type: contract.type,
          contract: contract,
        };
      });
    }
    return data;
  };

  columns = () => {
    const { t } = this.props;
    return [
      {
        Header: t('Contract Id'),
        accessor: 'id',
        id: 'edit',
        Cell: (cellInfo) => (
          <React.Fragment key={cellInfo.value}>
            <EditContractModal
              header={t('Edit Contract information')}
              trigger={(props) => (
                <Icon link color="orange" name="edit" {...props} />
              )}
              contract={cellInfo.original.contract}
              info={cellInfo.original}
              onSaveClick={() => {}}
            />
            <DeleteContractModal
              trigger={(props) => (
                <Icon link color="red" name="delete" {...props} />
              )}
              contract={cellInfo.original}
            />
            {cellInfo.value}
          </React.Fragment>
        ),
      },
      {
        Header: t('Agent'),
        accessor: 'agent_name',
      },
      {
        Header: t('Supplier'),
        accessor: 'supplier_name',
      },
      {
        Header: t('Contract Type'),
        accessor: 'contract_type',
        filterable: false,
      },
    ];
  };

  render() {
    const { t } = this.props;
    return (
      <div className="ContractTab">
        <CreateContractModal
          header={t('Create new Contract')}
          trigger={(props) => (
            <Button positive style={{ marginBottom: '10px' }} {...props}>
              {t('Create new contract')}
            </Button>
          )}
          onSaveClick={() => {}}
        />
        <ReactTable
          defaultFilterMethod={(filter, row) =>
            row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !==
            -1
          }
          filterable
          data={this.data()}
          columns={this.columns()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => {
  return {
    contracts: state.contracts.all,
    agentsMap: agentMapSelector(state),
    suppliersMap: supplierMapSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  fetchContracts: () => dispatch(fetchContracts()),
});

export const ContractsTab = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ContractsTabComponent);
