// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Input } from 'client/components/Form';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { getValidators } from 'shared/libraries/validate/validator';
import { useTranslationTargetLanguage } from 'client/contexts/TranslationLanguageContext';

type Props = {
  name: string,
  label: string,
};

export const TranslatedInput = ({ name, label }: Props) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const { translationTargetLanguageName } = useTranslationTargetLanguage(t);

  return (
    <Box mb={1} ml={-1} display="flex" flexWrap="wrap">
      <TranslatedField name={name} validate={required}>
        {({ input, translationInput, meta: { touched, error } }) => (
          <>
            <Box mt={1} ml={1} mb={1} flexGrow={1} minWidth="200px">
              <Input {...input} label={label} error={touched && error} />
            </Box>
            {translationTargetLanguageName && (
              <Box mt={1} ml={1} mb={1} flexGrow={1} minWidth="200px">
                <Input
                  {...translationInput}
                  label={`${label} (${translationTargetLanguageName})`}
                />
              </Box>
            )}
          </>
        )}
      </TranslatedField>
    </Box>
  );
};
