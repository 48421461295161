// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CrewMemberList } from 'client/pages/DispatchCustomize/CrewMemberList/CrewMemberList';
import { ResourceList } from 'client/pages/DispatchCustomize/ResourceList/ResourceList';
import { VehicleList } from 'client/pages/DispatchCustomize/VehicleList/VehicleList';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

type TabType = 'VEHICLES' | 'CREW' | 'RESOURCES';

export const DispatchCustomize = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = React.useState<TabType>('VEHICLES');

  return (
    <>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="MANIFEST_EDIT_RESOURCE" />
      )}
      <div
        className={clsx(
          componentStyles['c-tab-box'],
          baseStyles['scroll-target-pane']
        )}
      >
        <ul className={clsx(componentStyles['c-tab-box__tab'])}>
          <li
            className={clsx(
              activeTab === 'VEHICLES' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('VEHICLES');
            }}
          >
            <a>{t('Vehicles')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'CREW' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('CREW');
            }}
          >
            <a>{t('Crew')}</a>
          </li>
          <li
            className={clsx(
              activeTab === 'RESOURCES' ? componentStyles['is-active'] : ''
            )}
            onClick={() => {
              setActiveTab('RESOURCES');
            }}
          >
            <a>{t('Resources')}</a>
          </li>
        </ul>

        <VehicleList isActive={activeTab === 'VEHICLES'} />

        <CrewMemberList isActive={activeTab === 'CREW'} />

        <ResourceList isActive={activeTab === 'RESOURCES'} />
      </div>
    </>
  );
};
