import { useSelector } from 'react-redux';

import {
  AgentSystemFeeInvoiceSummary,
  SystemFeeInvoiceSummary,
} from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';

export const SuppliersGrossCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const gross = (invoice.pif_supplier_gross_amounts || []).find(
          (gross) => gross.supplier_id === org.id
        )?.gross_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(gross)}
          </li>
        );
      })}
    </ul>
  );
};

export const SuppliersCancellationCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const cancellation = (
          invoice.pif_supplier_cancellation_fee_amounts || []
        ).find(
          (cancellation) => cancellation.supplier_id === org.id
        )?.cancellation_fee_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(cancellation)}
          </li>
        );
      })}
    </ul>
  );
};

export const SuppliersCommissionCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const commission = (invoice.pif_supplier_commission_amounts || []).find(
          (commission) => commission.supplier_id === org.id
        )?.commission_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(commission)}
          </li>
        );
      })}
    </ul>
  );
};

export const SuppliersBookingFeeCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const bookingFee = (
          invoice.pif_supplier_booking_fee_amounts || []
        ).find(
          (bookingFee) => bookingFee.supplier_id === org.id
        )?.booking_fee_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(bookingFee)}
          </li>
        );
      })}
    </ul>
  );
};

export const SuppliersCreditCardFeeCell = ({
  invoice,
}: {
  invoice: AgentSystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const creditCardFee = (
          invoice.pif_supplier_credit_card_fee_amounts || []
        ).find(
          (bookingFee) => bookingFee.supplier_id === org.id
        )?.credit_card_fee_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(creditCardFee)}
          </li>
        );
      })}
    </ul>
  );
};

export const AgentsCommitionCell = ({
  invoice,
}: {
  invoice: SystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  return (
    <ul>
      {contracted.map((org) => {
        const commission = (invoice.pif_agent_commission_amounts || []).find(
          (commission) => commission.agent_id === org.id
        )?.commission_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(commission)}
          </li>
        );
      })}
    </ul>
  );
};

export const AgentGrossColumn = ({
  invoice,
}: {
  invoice: SystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );
  return (
    <ul>
      {contracted.map((org) => {
        const commission = (invoice.pif_agent_gross_amounts || []).find(
          (commission) => commission.agent_id === org.id
        )?.gross_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(commission)}
          </li>
        );
      })}
    </ul>
  );
};

export const AgentCancelFeeColumn = ({
  invoice,
}: {
  invoice: SystemFeeInvoiceSummary;
}) => {
  const contracted = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );
  return (
    <ul>
      {contracted.map((org) => {
        const commission = (
          invoice.pif_agent_cancellation_fee_amounts || []
        ).find(
          (commission) => commission.agent_id === org.id
        )?.cancellation_fee_amount;

        return (
          <li key={org.id}>
            {org.name} : {getFormattedAmount(commission)}
          </li>
        );
      })}
    </ul>
  );
};
