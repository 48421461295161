import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Select } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { activeUserSelector } from 'client/reducers/user';
import { createAccount } from 'client/actions/accounts';
import { getArrayMutators } from 'client/libraries/util/form';
import { matchesFormat } from 'shared/libraries/validate/validator';
import { updateGuideSettings } from 'client/actions/guideSettings';
import baseStyles from 'client/base.module.css';

import { convertFromValuesToAccount } from './formValues';
import type { FormValues } from './formValues';

type Props = {
  trigger: React.ReactElement<'a' | 'button'>;
};
export const NewGuideModal = ({ trigger }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const validateEmailAddress = React.useCallback(
    (email?: any) => {
      if (!email) return t('Required');
      if (!matchesFormat(email, 'email')) return t('Invalid Email');
      return undefined;
    },
    [t]
  );
  const validateName = React.useCallback(
    (name?: any) => {
      if (!name) return t('Required');
      return undefined;
    },
    [t]
  );
  const localeOptions = [
    {
      text: t('Japanese'),
      value: 'ja',
    },
    {
      text: t('English'),
      value: 'en',
    },
  ];
  return (
    <Modal
      title={t('Create new staff account')}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      onOpen={() => {
        setShowModal(true);
      }}
      trigger={trigger}
    >
      <Form
        onSubmit={async (values: FormValues) => {
          const account = convertFromValuesToAccount(values, activeUser);

          try {
            const resp: any = await dispatch(createAccount(account));
            await dispatch(
              updateGuideSettings(resp.response.id, {
                id: resp.response.id,
                columns: [
                  'AGENT_REFERENCE',
                  'PRODUCT_NAME',
                  'GUEST_DISPLAY_NAME',
                  'GUEST_COUNT',
                  'GUEST_DESCRIPTION',
                  'PICKUP_CHECKIN_TIME',
                  'PICKUP_CHECKIN_LOCATION',
                  'DROPOFF_LOCATION',
                ],
                // duplicate to cognito
                guide_name: resp.response.name,
                // duplicate to cognito
                email: resp.response.email,
                // duplicate to cognito
                locale: values.locale,
                // duplicate to cognito
                enabled: true,
              })
            );
            setShowModal(false);
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        initialValues={{
          locale: 'ja',
        }}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Modal.Box>
                  <Field name={'email'} validate={validateEmailAddress}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Email')}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
                <Modal.Box>
                  <Field name={'name'} validate={validateName}>
                    {({ input, meta: { touched, error } }) => (
                      <Input
                        {...input}
                        label={t('Name')}
                        error={touched && error}
                      />
                    )}
                  </Field>
                </Modal.Box>
                <Modal.Box>
                  <Field name="locale">
                    {({ input }) => (
                      <Select
                        label={t('Language')}
                        options={localeOptions}
                        value={input.value}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Modal.Box>
              </Modal.Content>
              <Modal.Actions>
                {submitError && (
                  <p className={baseStyles['base-form-box__err']}>
                    {submitError}
                  </p>
                )}
                <Button.Cancel
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {t('Discard')}
                </Button.Cancel>
                <Button
                  loading={submitting}
                  size="middle"
                  style="blue"
                  type="submit"
                >
                  {t('Save')}
                </Button>
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
