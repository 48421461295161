import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import {
  FieldWrapper,
  Input,
  Select,
  ToggleButton,
} from 'client/components/Form';
import {
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { Box } from 'client/components/Box/Box';
import { getLanguageName } from 'client/libraries/i18n';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { ListInput } from 'client/components/ListInput/ListInput';
import { SourceLanguage } from 'shared/models/swagger';

import { getFormCategoriesForLanguage } from './formValues';
import styles from './InquiryCategoriesEditor.module.css';

export const InquiryCategoryEditor = () => {
  const { t } = useTranslation();
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    text: getLanguageName(lang, t),
  }));
  const organization = useSelector(activeUserOrganizationSelector);
  const form = useForm();

  return (
    <div>
      <Box mb={2}>
        <Field name="categoriesLanguage">
          {({ input }) => (
            <Select
              label={t('Categories Content Language')}
              maxWidth={200}
              options={languageOptions}
              value={input.value}
              onChange={(e, { value }) => {
                input.onChange(value);
                form.change(
                  'categories',
                  getFormCategoriesForLanguage(
                    organization?.inquiry_settings?.inquiry_categories ?? [],
                    value as SourceLanguage
                  )
                );
              }}
            />
          )}
        </Field>
      </Box>
      <FieldWrapper label={t('Categories')} />
      <FieldArray name="categories">
        {({ fields }) => (
          <>
            {fields.length === 0 && (
              <AddIcon
                onClick={() =>
                  (fields as any).insertAt(0, {
                    title: '',
                    questions: [],
                    reservationInquiriesOnly: false,
                  })
                }
              />
            )}
            <table>
              <tbody>
                {fields.map((name, idx) => (
                  <tr key={name}>
                    <td>
                      <Box mb={2}>
                        {t('Category #{{idx}}', {
                          idx: idx + 1,
                        })}
                      </Box>
                      <Box width="100%">
                        <Field name={`${name}.title`}>
                          {({ input }) => (
                            <Input label={t('Title')} {...input} />
                          )}
                        </Field>
                        <Field name={`${name}.questions`}>
                          {({ input }) => (
                            <Box mt={2} mb={2}>
                              <ListInput
                                label={t('Questions')}
                                value={input.value}
                                onChange={input.onChange}
                                tooltipText={t(
                                  'Enter question text to be displayed in the inquiry form as an initial prompt when the category is selected.'
                                )}
                              />
                            </Box>
                          )}
                        </Field>
                        <Field name={`${name}.reservationInquiriesOnly`}>
                          {({ input }) => (
                            <Box mt={2} mb={2}>
                              <ToggleButton
                                label={t(
                                  'Category only applies to inquiries for existing reservations'
                                )}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            </Box>
                          )}
                        </Field>
                      </Box>
                    </td>
                    <td className={styles['add-delete-buttons-container']}>
                      <Box display="flex" ml={1}>
                        <AddIcon
                          onClick={() => (fields as any).insertAt(idx + 1, '')}
                        />
                        <DeleteIcon onClick={() => fields.remove(idx)} />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </FieldArray>
    </div>
  );
};
