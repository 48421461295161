import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { fetchAccessReportData } from 'client/actions/accessReportData';
import { setLastAccessReportSettings } from 'client/actions/accessReportSettings';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  DateFilterPreset,
  AccessReportSettings,
} from 'client/libraries/util/accessReportSettings';
import { initialSettings } from 'client/libraries/util/accessReportSettings';
import { convertAccessReportSettingsToQueryParams } from 'client/pages/v3/BookingWidget/AccessReport/utils';
import { Button } from 'client/components/v3/Common/Button';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import styles from 'client/pages/v3/BookingWidget/AccessReport/AccessReport.module.css';
import { operationAllowed } from 'shared/models/access';

export const getDateFilterPresetOptions = (
  t: TranslateFuncType
): {
  value: DateFilterPreset;
  text: string;
}[] => {
  return ['7_DAY', '14_DAYS', '28_DAYS', '30_DAYS', 'CUSTOM'].map((preset) => ({
    value: preset as DateFilterPreset,
    text: getDateFilterPresetText(preset as DateFilterPreset, t),
  }));
};
export const getDateFilterPresetText = (
  preset: DateFilterPreset,
  t: TranslateFuncType
): string => {
  if (preset === 'CUSTOM') {
    return t('Custom');
  }

  const match = /^(\d+)_DAY.*$/.exec(preset);

  if (match && match.length > 0) {
    return t('Last {{count}} days', {
      count: match[1],
    });
  }

  return '';
};

export const AccessReportSettingsBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUser = useSelector(activeUserSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const lastAccessReportSettings = useSelector(
    (state: ReduxState) => state.accessReportSettings.lastAccessReportSettings
  );
  const [accessReportSettings, setAccessReportSettings] =
    React.useState<AccessReportSettings>(lastAccessReportSettings);

  React.useEffect(() => {
    setAccessReportSettings(lastAccessReportSettings);
  }, [lastAccessReportSettings]);

  const dateFilterPresetOptions = [...getDateFilterPresetOptions(t)];

  const dateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    let startDateParam = '';
    let endDateParam = '';
    if (
      accessReportSettings.endDate &&
      moment(accessReportSettings.endDate).isBefore(moment(startDate))
    ) {
      endDateParam = startDate || '';
    } else {
      startDateParam = startDate || '';
    }

    if (
      accessReportSettings.startDate &&
      moment(accessReportSettings.startDate).isAfter(moment(endDate))
    ) {
      startDateParam = endDate || '';
    } else {
      endDateParam = endDate || '';
    }
    setAccessReportSettings({
      ...accessReportSettings,
      endDate: endDateParam,
      startDate: startDateParam,
    });
  };

  const compareDateRangeInputChangeHandler = (
    dates: [string | null, string | null]
  ) => {
    const [startDate, endDate] = dates;

    let startDateParam = '';
    let endDateParam = '';
    if (
      accessReportSettings.compareEndDate &&
      moment(accessReportSettings.compareEndDate).isBefore(moment(startDate))
    ) {
      endDateParam = startDate || '';
    } else {
      startDateParam = startDate || '';
    }
    if (
      accessReportSettings.compareStartDate &&
      moment(accessReportSettings.compareStartDate).isAfter(moment(endDate))
    ) {
      startDateParam = endDate || '';
    } else {
      endDateParam = endDate || '';
    }
    setAccessReportSettings({
      ...accessReportSettings,
      compareStartDate: startDateParam,
      compareEndDate: endDateParam,
    });
  };

  const comparisonHandler = () => {
    setAccessReportSettings({
      ...accessReportSettings,
      compare: !accessReportSettings.compare,
    });
  };

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
    }
  }, [isNutmegAdmin]);
  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  const reset = () => {
    setAccessReportSettings(initialSettings);
  };
  const search = () => {
    if (!isNutmegAdmin) {
      dispatch(
        fetchAccessReportData(
          convertAccessReportSettingsToQueryParams(accessReportSettings)
        )
      );
      dispatch(setLastAccessReportSettings(accessReportSettings));
    }
  };

  React.useEffect(() => {
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  return (
    <section className={styles['g-section']}>
      <div className={styles['p-reports']}>
        <div className={styles['p-reports__header']}>
          <h2 className={styles['p-reports__header__title']}>
            {t('Report Setting')}
          </h2>
        </div>
        <div className={styles['p-reports__body']}>
          <div className={styles['p-reports__body__display']}>
            <div
              className={clsx(
                styles['p-reports__body__item'],
                styles['p-reports__body__item--custom']
              )}
              style={{ alignItems: 'center' }}
            >
              <div style={{ width: '264px' }}>
                <SingleDropdown
                  label={t('Date Range')}
                  selectedOption={
                    accessReportSettings.dateFilterPreset ?? 'NONE'
                  }
                  options={dateFilterPresetOptions}
                  onChange={(value) => {
                    setAccessReportSettings({
                      ...accessReportSettings,
                      dateFilterPreset: value as DateFilterPreset,
                    });
                  }}
                />
              </div>
              {accessReportSettings.dateFilterPreset === 'CUSTOM' && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '1',
                    })}
                    dateFrom={accessReportSettings.startDate}
                    dateTo={accessReportSettings.endDate}
                    onChange={dateRangeInputChangeHandler}
                  />
                </div>
              )}
              <Checkbox
                label={t('Compare')}
                checked={accessReportSettings.compare}
                onChange={() => {
                  comparisonHandler();
                }}
                size="md"
              />

              {accessReportSettings.compare && (
                <div style={{ width: '264px' }}>
                  <DateRangeInput
                    label={t('Duration {{duration}}', {
                      duration: '2',
                    })}
                    dateFrom={accessReportSettings.compareStartDate}
                    dateTo={accessReportSettings.compareEndDate}
                    onChange={compareDateRangeInputChangeHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={clsx(styles['p-reports__bottom'], styles['center'])}>
          <Button
            text={t('Clear', { context: 'AcccessReportSettingsModal' })}
            uiType="bg"
            size="md"
            color="tertiarygray"
            onClick={() => {
              reset();
            }}
            style={{ marginRight: '12px' }}
          />
          <Button
            type="submit"
            text={t('Update')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={() => {
              search();
            }}
          />
        </div>
      </div>
    </section>
  );
};
