import _ from 'lodash';

import * as Swagger from 'shared/models/swagger';

export type MinimumParticipantFormValues = {
  minimumParticipantCount: number;
  usePerBookingInventoryCounting: boolean;
  unitWeights: {
    unitKey: string;
    ageBandMinimum: number | null;
    ageBandMaximum: number | null;
    weight: number;
    shouldConsumeInventory: boolean;
  }[];
  calendarUnitOrder: CalendarUnitOrder[];
};

type CalendarUnitOrder = {
  unit: string;
  title: string;
  default_display_in_calendar?: boolean;
};

export const getInitialMinimumParticipantFormValues = (
  product: Swagger.Product | null
): MinimumParticipantFormValues => {
  let units: Swagger.GuestType[] = [];

  for (const priceRule of product?.pricing ?? []) {
    for (const unit of priceRule?.units ?? []) {
      if (unit.method === 'PER_PARTICIPANT' && unit.guest_type) {
        units.push({ ...unit.guest_type });
      }
    }
  }

  units = _.uniqBy(units, (unit) => unit.key);

  const copyUnits = [...units];
  let sortUnits = [];
  if (
    !copyUnits.some(
      (u) =>
        (product?.booking_widget_settings?.calendar_unit_order ?? []).find(
          (p) => p.unit === u.key
        )?.default_display_in_calendar
    )
  ) {
    sortUnits = copyUnits.map((u, idx) => {
      return {
        unit: u.key,
        title: u.title,
        default_display_in_calendar: idx < 3 ? true : false,
      };
    });
  } else {
    sortUnits = copyUnits
      .sort((a, b) => {
        const orderA = (
          product?.booking_widget_settings?.calendar_unit_order ?? []
        )?.findIndex(({ unit }) => unit === a.key);
        const orderB = (
          product?.booking_widget_settings?.calendar_unit_order ?? []
        )?.findIndex(({ unit }) => unit === b.key);
        if (orderA !== -1 && orderB === -1) {
          return -1;
        }
        if (orderA === -1 && orderB !== -1) {
          return 1;
        }
        if (orderA === -1 && orderB === -1) {
          return 0;
        }
        return orderA - orderB;
      })
      .map((u) => ({
        unit: u.key,
        title: u.title,
        default_display_in_calendar:
          (product?.booking_widget_settings?.calendar_unit_order ?? [])?.find(
            (p) => p.unit === u.key
          )?.default_display_in_calendar ?? false,
      }));
  }

  return {
    minimumParticipantCount: product?.minimum_participant_count?.value ?? 0,
    usePerBookingInventoryCounting:
      product?.allotment_settings?.allotment_counting_type === 'PER_BOOKING',
    unitWeights: units.map((unit) => ({
      unitKey: unit.key,
      ageBandMinimum: unit.minimum_age ?? null,
      ageBandMaximum: unit.maximum_age ?? null,
      weight: unit?.minimum_participant_parameters
        ? unit?.minimum_participant_parameters.weight || 0
        : 1,
      shouldConsumeInventory:
        !product?.allotment_settings?.inventory_consumption_rules?.find(
          (rule) => rule.unit === unit.key
        )?.should_not_count_inventory,
    })),
    calendarUnitOrder: sortUnits,
  };
};
export const convertMinimumParticipantFormValuesToProductPatch = (
  values: MinimumParticipantFormValues,
  previous: Swagger.Product | null
): Swagger.Product$Patch => {
  return {
    minimum_participant_count: {
      value: values.minimumParticipantCount,
    },
    pricing: previous?.pricing?.map((priceRule) => {
      const newPriceRule: any = {
        ...priceRule,
        units: priceRule.units?.map((unit) => {
          if (unit.method === 'PER_PARTICIPANT' && unit.guest_type) {
            return {
              ...unit,
              guest_type: {
                ...unit.guest_type,
                minimum_participant_parameters: {
                  weight:
                    values.unitWeights.find(
                      (unitWeight) =>
                        unitWeight.unitKey === unit.guest_type?.key
                    )?.weight ?? 1,
                },
              },
            };
          }

          return { ...unit };
        }),
      };
      return newPriceRule;
    }),
    allotment_settings: {
      allotment_counting_type: values.usePerBookingInventoryCounting
        ? 'PER_BOOKING'
        : 'DEFAULT',
      inventory_consumption_rules: values.unitWeights?.map((unitWeight) => ({
        unit: unitWeight.unitKey,
        should_not_count_inventory: !unitWeight.shouldConsumeInventory,
      })),
    },
  };
};
