import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { TranslationLanguageContext } from 'client/contexts/TranslationLanguageContext';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import styles from 'client/pages/v3/Product/ProductTranslation/ProductTranslation.module.css';
import { CollapsibleBox } from 'client/pages/v3/Product/ProductTranslation/ProductTranslationContents/CollapsibleBox';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { uppercaseIsoToLowercaseIso } from 'shared/libraries/i18n';
import { getLanguageName } from 'client/libraries/i18n';
import { TextField } from 'client/components/v3/Form/TextField';

export const HighlightsEditor = () => {
  const { t } = useTranslation();
  const translationTargetLanguage = useContext(TranslationLanguageContext);

  const editingProduct = useContext(EditingProductContext);
  const sourceLanguage = editingProduct?.source_language ?? '';
  const lowerCaseIsoSourceLanguage = sourceLanguage
    ? uppercaseIsoToLowercaseIso[sourceLanguage]
    : 'EN_US';

  return (
    <FieldArray name="highlights">
      {({ fields }) => (
        <>
          {fields.map((name, idx) => (
            <>
              <CollapsibleBox
                key={name}
                title={t('POINT.{{pointNumber}}', {
                  pointNumber: idx + 1,
                })}
              >
                <TranslatedField key={name} name={name}>
                  {({ input, translationInput }) => (
                    <ul className={styles['p-list']}>
                      <li className={styles['p-list__item']}>
                        <div className={styles['p-list__item__ttl']}>
                          <p>
                            {getLanguageName(lowerCaseIsoSourceLanguage, t)}
                          </p>
                        </div>
                        <div className={styles['p-list__item__body']}>
                          <TextField {...input} />
                        </div>
                      </li>
                      {translationTargetLanguage && (
                        <li className={styles['p-list__item']}>
                          <div className={styles['p-list__item__ttl']}>
                            <p>
                              {getLanguageName(
                                uppercaseIsoToLowercaseIso[
                                  translationTargetLanguage
                                ],
                                t
                              )}
                            </p>
                          </div>
                          <div className={styles['p-list__item__body']}>
                            \
                            <TextField {...translationInput} />
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </TranslatedField>
              </CollapsibleBox>
            </>
          ))}
        </>
      )}
    </FieldArray>
  );
};
