import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { Button } from 'client/components/v3/Common/Button';

export const ProductListHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader title={t('Product List')}>
      <Link
        // TODO: remove v3 from url later
        to={{
          pathname: `/products/new-v3`,
        }}
      >
        <Button
          type="button"
          text={t('Create new product')}
          size="md"
          color="primary"
        />
      </Link>
    </PageHeader>
  );
};
