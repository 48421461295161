import _ from 'lodash';
import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FieldWrapper,
  MultiSelect,
  Select,
} from 'client/components/Form';

import { FormValues } from './formValues';
import { StructuredInfoValueModal } from './StructuredInfoValueModal';

interface Props {
  name: string;
}

export const StructuredInfoItemEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const [showStructuredInfoValueModal, setShowStructuredInfoValueModal] =
    React.useState(false);

  const form = useForm();
  const values = form.getState().values as FormValues;
  const structuredInfoCategoryOptions = (
    values?.structuredInfoCategories || []
  ).map((c) => ({
    value: c.label,
    text: c.label,
  }));

  const infoCategoryKey = _.get(values, `${name}.infoCategoryKey`);

  console.log('infoCategoryKey', infoCategoryKey);
  console.log('pins', values.pins);

  const valueOptions = _.uniq(
    _.flatten(
      values.pins.map((pin) => {
        return (
          pin.structuredInfoItems.find(
            (item) => item.infoCategoryKey === infoCategoryKey
          )?.values || []
        );
      })
    )
  ).map((v) => ({ value: v, text: v }));

  console.log('valueOptions', valueOptions);

  return (
    <>
      <Field name={`${name}.infoCategoryKey`}>
        {({ input }) => (
          <Select
            label={t('Information Category')}
            value={input.value}
            onChange={(e, { value }) => input.onChange(value)}
            options={structuredInfoCategoryOptions}
          />
        )}
      </Field>
      <Field name={`${name}.values`}>
        {({ input }) => (
          <>
            {valueOptions.length > 0 && (
              <FieldWrapper label={t('Values')}>
                <MultiSelect
                  selectedValues={input.value}
                  onChange={({ value }) => input.onChange(value)}
                  options={valueOptions}
                />
              </FieldWrapper>
            )}
            <Button
              size="middle"
              style="green"
              onClick={() => setShowStructuredInfoValueModal(true)}
            >
              {t('Add New Value')}
            </Button>
            {showStructuredInfoValueModal && (
              <StructuredInfoValueModal
                onAddValue={(value) => {
                  input.onChange([...input.value, value]);
                }}
                onClose={() => setShowStructuredInfoValueModal(false)}
              />
            )}
          </>
        )}
      </Field>
    </>
  );
};
