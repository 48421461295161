// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { withTranslation } from 'react-i18next';
import type { TranslatorProps } from 'react-i18next';
import type { Dispatch } from 'redux';
import clsx from 'clsx';

import { updateReservation } from 'client/actions/reservations';
import { LocationSearchInput } from 'client/components/LocationSearchInput';
import { ReservationActorInputForm } from 'client/components/ReservationActorInputForm';
import { Modal } from 'client/components/Modal/Modal';
import { Button } from 'client/components/Form/';
import type { Reservation } from 'shared/models/swagger';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';

type OwnProps = {
  reservation: Reservation,
  onUpdateCompleted?: (void) => void,
  trigger: React.Element<'a'>,
};

/* eslint-disable no-use-before-define */
type Props = {
  ...OwnProps,
  ...TranslatorProps,
  ...$Call<typeof mapDispatchToProps, *>,
};
/* eslint-enable no-use-before-define */

type State = {
  showModal: boolean,
  guestHotel?: {
    location_name?: string,
    google_place_id?: string,
  },
};

class GuestHotelEditButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { reservation } = props;

    this.state = {
      showModal: false,
      guestHotel: reservation.guest_hotel,
    };
  }

  render() {
    const { onUpdateCompleted, reservation, t, trigger } = this.props;
    const { guestHotel, showModal } = this.state;
    const pristine = guestHotel === reservation.guest_hotel;

    return (
      <Modal
        title={t('Edit Customer Hotel')}
        trigger={trigger}
        open={showModal}
        onClose={() =>
          this.setState({
            showModal: false,
          })
        }
        onOpen={() =>
          this.setState({
            showModal: true,
          })
        }
      >
        <div className={clsx(pageStyles['page-reservations__modal'])}>
          <div className={clsx(pageStyles['page-reservations__modal__box'])}>
            <LocationSearchInput
              prompt={t('Customer Hotel')}
              location={(guestHotel && guestHotel.location_name) || ''}
              onSearchChange={(location_name) =>
                this.setState({
                  guestHotel: {
                    location_name,
                  },
                })
              }
              onLocationSelect={({
                title: location_name,
                key: google_place_id,
              }) =>
                this.setState({
                  guestHotel: {
                    location_name,
                    google_place_id,
                  },
                })
              }
            />
          </div>
        </div>

        <div className={clsx(baseStyles['base-text-divider'])} />
        <ReservationActorInputForm />

        <div className={clsx(componentStyles['c-modal__frame__body__btns'])}>
          <Button
            disabled={pristine}
            style="gray"
            size="middle"
            onClick={() =>
              this.setState({
                guestHotel: reservation.guest_hotel,
              })
            }
          >
            {t('Discard')}
          </Button>
          <Button
            disabled={pristine}
            style="green"
            size="middle"
            inverted
            disabled={pristine}
            onClick={() => {
              this.props
                .updateReservation(
                  reservation.id,
                  ({
                    guest_hotel: guestHotel,
                  }: any)
                )
                .then(() => onUpdateCompleted && onUpdateCompleted());
              this.setState({
                showModal: false,
              });
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Object>) => ({
  updateReservation: (id, patch) => dispatch(updateReservation(id, patch)),
});

export const GuestHotelEditButton = compose(
  connect<*, *, *, *, *, *>(null, mapDispatchToProps),
  withTranslation()
)(GuestHotelEditButtonComponent);
