import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment-timezone';

import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  DateTimeInput,
  FieldWrapper,
  MultiSelect,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import baseStyles from 'client/base.module.css';
import { equipmentsSelector } from 'client/reducers/equipments';
import { batchCloseEquipmentInstances } from 'client/actions/equipmentInstances';

interface FormValues {
  startDate: string;
  endDate: string;
  equipmentSelectedType: 'ALL_EQUIPMENTS' | 'SELECT_EQUIPMENTS';
  equipmentIds: string[];
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export const EquipmentInstanceCloseCalendarModal: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const equipments = useSelector(equipmentsSelector);

  const dispatch = useDispatch();
  const reduxStore = useStore<ReduxState>();

  const initialValues: FormValues = React.useMemo(
    () => ({
      startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      equipmentSelectedType: 'ALL_EQUIPMENTS',
      equipmentIds: [],
    }),
    []
  );

  const equipmentOptions = equipments.map((equipment) => ({
    value: equipment.id ?? '',
    text: equipment.title ?? '',
  }));

  return (
    <Modal title={t('Close Calendars')} open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            batchCloseEquipmentInstances(
              values.startDate,
              moment(values.endDate).add(1, 'days').format('YYYY-MM-DD'),
              values.equipmentIds
            )
          );

          const error = reduxStore.getState().productInstances.batchCloseError;
          if (error) {
            return { [FORM_ERROR]: error };
          }

          onClose();
        }}
        validate={(values: FormValues) => {
          const errors: Partial<Record<keyof FormValues, string>> = {};
          if (values.startDate > values.endDate) {
            errors.startDate = t('Invalid date range');
          }
          if (
            moment(values.endDate).diff(moment(values.startDate), 'days') + 1 >=
            90
          ) {
            errors.startDate = t(
              'Sorry, the duration must be {{maxDuration}} days or less.',
              {
                maxDuration: 90,
              }
            );
          }
          if (
            values.equipmentSelectedType === 'SELECT_EQUIPMENTS' &&
            values.equipmentIds.length === 0
          ) {
            errors.equipmentIds = t('At least one product required');
          }
          return errors;
        }}
      >
        {({ handleSubmit, values, submitting, submitError, form }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <FieldWrapper label={t('Select Date')}>
                <Box display="flex" alignItems="center">
                  <Field name="startDate">
                    {({ input }) => (
                      <DateTimeInput
                        value={moment(input.value)}
                        locale={locale}
                        onChange={(date) => {
                          input.onChange(date.format('YYYY-MM-DD'));
                        }}
                      />
                    )}
                  </Field>
                  -
                  <Field name="endDate">
                    {({ input }) => (
                      <DateTimeInput
                        value={moment(input.value)}
                        locale={locale}
                        onChange={(date) => {
                          input.onChange(date.format('YYYY-MM-DD'));
                        }}
                      />
                    )}
                  </Field>
                </Box>
                <Field name="startDate">
                  {({ meta: { touched, error } }) =>
                    touched && error ? (
                      <div className={baseStyles['base-form-box__err']}>
                        {error}
                      </div>
                    ) : null
                  }
                </Field>
              </FieldWrapper>
              <Box mt={2}>
                <FieldWrapper label={t('Select Resources')}>
                  <EnumRadioButtonGroup
                    name="equipmentSelectedType"
                    options={[
                      {
                        label: t('All Resources'),
                        value: 'ALL_EQUIPMENTS',
                      },
                      {
                        label: t('Select Resources'),
                        value: 'SELECT_EQUIPMENTS',
                      },
                    ]}
                    onChange={(newValue: string) => {
                      if (newValue === 'ALL_EQUIPMENTS') {
                        form.change('equipmentIds', []);
                      }
                    }}
                  />
                </FieldWrapper>
              </Box>
              {values?.equipmentSelectedType === 'SELECT_EQUIPMENTS' && (
                <Field name="equipmentIds">
                  {({ input, meta: { touched, error } }) => (
                    <MultiSelect
                      search
                      selectedValues={input.value}
                      onChange={({ value }) => input.onChange(value)}
                      options={equipmentOptions}
                      error={touched && error}
                    />
                  )}
                </Field>
              )}

              <Box mt={4}>
                {t(
                  '* Calendar will be closed for all channels on the selected date. Please edit availability separately if you need to re-open on a particular resource.'
                )}
              </Box>

              {submitError && (
                <p className={baseStyles['base-form-box__err']}>
                  {t('Error closing calendars. Please try again.')}
                </p>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submitting}
                type="submit"
                style="blue"
                size="middle"
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
