import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper, Select, Input } from 'client/components/Form';
import { CustomerFormField } from 'shared/models/swagger';
import { useCountries } from 'client/hooks/useCountries';

interface Props {
  formField: CustomerFormField;
}

export const CustomerFormFieldResponseInput = ({ formField }: Props) => {
  if (formField.format === 'YYYY_MM_DD') {
    return <DateInput formField={formField} />;
  }

  if (formField.response_type === 'SELECT_ONE') {
    return <SelectOne formField={formField} />;
  }

  if (formField.response_type === 'ADDRESS_FORM') {
    return <AddressInput formField={formField} />;
  }

  return <TextInput formField={formField} />;
};

const DateInput = ({ formField }: Props) => {
  const { t } = useTranslation();
  const thisYear = new Date().getFullYear();
  return (
    <Modal.Box>
      <FieldWrapper label={formField.field_name ?? ''}>
        <Modal.Box column="three">
          <Field name={`formFieldResponses.${formField.key}.year`}>
            {({ input }) => (
              <Select
                options={[
                  {
                    text: t('Year'),
                    value: '',
                  },
                  ...[...Array(100)].map((_, i) => {
                    return {
                      text: `${thisYear - 100 + i}`,
                      value: `${thisYear - 100 + i}`,
                    };
                  }),
                ]}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
          <Field name={`formFieldResponses.${formField.key}.month`}>
            {({ input }) => (
              <Select
                options={[
                  {
                    text: t('Month'),
                    value: '',
                  },
                  ...[...Array(12)].map((_, i) => {
                    return {
                      text: `${i + 1}`,
                      value: `${i + 1}`.padStart(2, '0'),
                    };
                  }),
                ]}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
          <Field name={`formFieldResponses.${formField.key}.day`}>
            {({ input }) => (
              <Select
                options={[
                  {
                    text: t('Day'),
                    value: '',
                  },
                  ...[...Array(31)].map((_, i) => {
                    return {
                      text: `${i + 1}`,
                      value: `${i + 1}`.padStart(2, '0'),
                    };
                  }),
                ]}
                value={input.value}
                onChange={(_, { value }) => input.onChange(value)}
              />
            )}
          </Field>
        </Modal.Box>
      </FieldWrapper>
    </Modal.Box>
  );
};

const SelectOne = ({ formField }: Props) => {
  return (
    <Modal.Box>
      <Field name={`formFieldResponses.${formField.key}.value`}>
        {({ input }) => (
          <Select
            label={formField.field_name ?? ''}
            options={(formField.options ?? []).map((option) => ({
              text: option.text ?? '',
              value: option.key ?? '',
            }))}
            value={input.value}
            onChange={(_, { value }) => input.onChange(value)}
          />
        )}
      </Field>
    </Modal.Box>
  );
};

const TextInput = ({ formField }: Props) => {
  return (
    <Modal.Box>
      <Field name={`formFieldResponses.${formField.key}.value`}>
        {({ input, meta }) => (
          <Input
            label={formField.field_name ?? ''}
            error={meta.error && meta.touched && meta.error}
            {...input}
          />
        )}
      </Field>
    </Modal.Box>
  );
};

const AddressInput = ({ formField }: Props) => {
  const { t } = useTranslation();

  const countries = useCountries();

  const countryOptions = Object.entries(countries).map(([key, value]) => ({
    text: value,
    value: key,
  }));

  return (
    <>
      <Modal.Box>
        <Field name={`formFieldResponses.${formField.key}.country`}>
          {({ input }) => (
            <Select
              label={t('Country')}
              search
              options={countryOptions}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </Modal.Box>

      <Modal.Box>
        <Field name={`formFieldResponses.${formField.key}.postalCode`}>
          {({ input, meta }) => (
            <Input
              label={t('Zip / Postal code')}
              error={meta.error && meta.touched && meta.error}
              {...input}
            />
          )}
        </Field>
      </Modal.Box>

      <Modal.Box>
        <Field name={`formFieldResponses.${formField.key}.province`}>
          {({ input, meta }) => (
            <Input
              label={t('State / Province / Region')}
              error={meta.error && meta.touched && meta.error}
              {...input}
            />
          )}
        </Field>
      </Modal.Box>

      <Modal.Box>
        <Field type="text" name={`formFieldResponses.${formField.key}.city`}>
          {({ input, meta }) => (
            <Input
              label={t('City / Ward / Town')}
              error={meta.error && meta.touched && meta.error}
              {...input}
            />
          )}
        </Field>
      </Modal.Box>

      <Modal.Box>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.addressLine`}
        >
          {({ input, meta }) => (
            <Input
              label={t('Street / House Number')}
              error={meta.error && meta.touched && meta.error}
              {...input}
            />
          )}
        </Field>
      </Modal.Box>
    </>
  );
};
