import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { Add as AddIcon } from 'client/components/Icons/Add';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Box } from 'client/components/Box/Box';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';

import styles from './TransportRouteEditor.module.css';
import { TransportRouteLocationEditor } from './TransportRouteLocationEditor';

export const TransportRouteEditor = () => {
  const { t } = useTranslation();
  return (
    <FormTableBox>
      <table>
        <tbody>
          <FieldArray name="transportRoute">
            {({ fields }) =>
              fields.map((name, idx) => {
                return (
                  <tr key={name}>
                    <th>
                      {t('Route #{{routeIdx}}', {
                        routeIdx: idx + 1,
                      })}
                    </th>
                    <td>
                      <Box display="flex" flexWrap="wrap" alignItems="center">
                        {idx === 0 ? (
                          <div className={styles['bold']}>
                            {t('Pickup / Checkin')}
                          </div>
                        ) : (
                          <TransportRouteLocationEditor
                            key={`${name}.locationFrom`}
                            name={`${name}.locationFrom`}
                          />
                        )}
                        <Box ml={2} mr={2} className={styles['arrow']}>
                          →
                        </Box>
                        {idx === fields.value?.length - 1 ? (
                          <div className={styles['bold']}>
                            {t('Dropoff / Checkout')}
                          </div>
                        ) : (
                          <TransportRouteLocationEditor
                            key={`${name}.locationTo`}
                            name={`${name}.locationTo`}
                          />
                        )}
                        <Box display="flex" ml="auto">
                          <Box width="40px">
                            <AddIcon
                              onClick={() => {
                                const initialLocationFrom = fields.value[idx]
                                  ?.locationTo
                                  ? {
                                      googlePlaceId:
                                        fields.value[idx]?.locationTo
                                          .googlePlaceId,
                                      locationName:
                                        fields.value[idx]?.locationTo
                                          .locationName,
                                      relativeTime:
                                        fields.value[idx]?.locationTo
                                          .relativeTime,
                                    }
                                  : {
                                      googlePlaceId: '',
                                      locationName: '',
                                      relativeTime: '',
                                    };
                                (fields as any).insertAt(idx + 1, {
                                  locationFrom: initialLocationFrom,
                                  locationTo: {
                                    googlePlaceId: '',
                                    locationName: '',
                                    relativeTime: '',
                                  },
                                });
                              }}
                            />
                          </Box>
                          <Box width="40px">
                            {idx !== 0 && (
                              <DeleteIcon onClick={() => fields.remove(idx)} />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                );
              })
            }
          </FieldArray>
        </tbody>
      </table>
    </FormTableBox>
  );
};
