import * as React from 'react';
import ReactDOM from 'react-dom';
import _uniqueId from 'lodash/uniqueId';

import { LanguageISO } from 'shared/libraries/i18n';

type Props = {
  url: string;
  locale: string;
  size: 'small' | 'large';
};
const facebookWidgetPathLangMap = {
  en: 'en_US',
  ja: 'ja_JP',
  ko: 'ko_KR',
  'zh-CN': 'zh_CN',
  'zh-TW': 'zh_TW',
};
export const FacebookShareButton = ({ url, locale, size }: Props) => {
  const [id] = React.useState<string>(_uniqueId('fb-'));
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const encodedUrl = encodeURI(url);
  const wrapper = React.useRef(null);

  const appendSdkScript = () => {
    const script = document.createElement('script');
    script.id = `widget-${id}`;
    script.src = `https://connect.facebook.net/${
      facebookWidgetPathLangMap[locale as LanguageISO] || 'ja_JP'
    }/sdk.js#xfbml=1&version=v11.0`;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    script.onload = () => setHasLoaded(true);

    (wrapper.current as any).appendChild(script);
  };

  const scriptAlreadyExists = () =>
    document.querySelector(`script#widget-${id}`) !== null;

  const removeExistScript = () => {
    const dom = document.getElementById(`widget-${id}`);

    if (dom?.parentNode) {
      dom.parentNode.removeChild(dom);
      delete (window as any).FB; // Need to remove loaded SDK to switch display language
    }
  };

  React.useEffect(() => {
    // Need to reload SDK when the display language is changed at Facebook Share Button
    if (scriptAlreadyExists()) {
      setHasLoaded(false);
      removeExistScript();
    }

    appendSdkScript();
  }, [locale]);

  const genKey = () => {
    return encodedUrl + locale + size + id;
  };

  React.useEffect(() => {
    if (hasLoaded) {
      const node = document.getElementById(id);

      if (node) {
        // Need to wrrap div.fb-share-button with another div to manage the element via React because div.fb-share-button is replaced by Facebook SDK.
        ReactDOM.render(
          <div key={genKey()}>
            <div
              className="fb-share-button"
              data-href={encodedUrl}
              data-layout="button"
              data-size={size}
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore"
                rel="noreferrer"
              />
            </div>
          </div>,
          node,
          () => {
            if ((window as any).FB) {
              (window as any).FB.XFBML.parse();
            }
          }
        );
      }
    }
  }, [hasLoaded, encodedUrl, size]);
  return (
    <div ref={wrapper} className="c-sns__wrapper">
      <div id={id} />
    </div>
  );
};
