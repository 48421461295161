import * as React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getBookingWebsiteUrl } from 'client/libraries/util/getBookingWebsiteUrl';
import baseStyles from 'client/base.module.css';

interface Props {
  productId: string;
  open: boolean;
  onClose: () => void;
  presetRedemptionCountKey: string;
}

export const PresetRedemptionCountQrCodeModal = ({
  productId,
  open,
  onClose,
  presetRedemptionCountKey,
}: Props) => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);

  const kioskUrl = `${getBookingWebsiteUrl(
    organization
  )}/me/eticket?presetRedemptionCountKey=${presetRedemptionCountKey}&productId=${productId}`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('Show QR Code')}
      insertRoot={true}
    >
      <Modal.Content>
        <a
          className={baseStyles['base-link']}
          href={kioskUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {kioskUrl}
        </a>
        <Box mt={2} mb={2}>
          <QRCode value={kioskUrl} />
        </Box>
      </Modal.Content>

      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
