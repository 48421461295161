import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { activeUserSelector } from 'client/reducers/user';
import { fetchProductByID } from 'client/actions/products';
import { ProductContentsHeader } from 'client/pages/ProductDetails/ProductContentsHeader/ProductContentsHeader';
import { ProductContentsPane } from 'client/pages/ProductDetails/ProductContentsPane/ProductContentsPane';
import { ProductReviewsPane } from 'client/pages/ProductDetails/ProductReviewsPane/ProductReviewsPane';
import type { ReduxState } from 'client/reducers';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';
import { config } from 'client/config';

import styles from './ProductDetails.module.css';

export const ProductDetails = () => {
  const { t, i18n } = useTranslation();
  const [contentLanguage, setContentLanguage] = React.useState<string>(
    i18n.language
  );
  const activeUser = useSelector(activeUserSelector);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    dispatch(fetchProductByID(id ?? '', contentLanguage));
  }, [id, contentLanguage, activeUser]);
  const product = useSelector(
    (state: ReduxState) => state.products.byID[id ?? '']
  );

  if (!product) {
    return <div>{t('Product not found')}</div>;
  }

  return (
    <article className={styles['base-single']}>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <div style={{ marginBottom: '12px' }}>
          <ToggleNewUI origin="PRODUCT_DETAILS" />
        </div>
      )}
      <ProductContentsHeader
        product={product}
        contentLanguage={contentLanguage}
        onContentLanguageChange={setContentLanguage}
      />
      <ProductContentsPane
        product={product}
        contentLanguage={contentLanguage}
      />
      <ProductReviewsPane product={product} contentLanguage={contentLanguage} />
    </article>
  );
};
