import * as React from 'react';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProducts } from 'client/actions/products';
import { MultiSelect } from 'client/components/Form';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { Stars } from 'client/components/Stars/Stars';
import type { SearchFormValues } from 'client/libraries/util/review';
import { getReviewAttributionText } from 'client/libraries/util/getReviewAttributionText';
import { ReduxState } from 'client/reducers';
import type { ReviewAttribution } from 'shared/models/swagger';
import searchIcon from 'client/images/ic_search_black.svg';

import styles from './ProductReviewsPane.module.css';

interface Props {
  onSearchFormValuesChange: (searchFormValues: SearchFormValues) => void;
  searchFormValues: SearchFormValues;
  showProductFilter?: boolean;
  initialIsExpanded?: boolean;
  limit?: number;
}
export const ReviewSearchForm = ({
  onSearchFormValuesChange,
  searchFormValues,
  showProductFilter,
  initialIsExpanded,
}: Props) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    initialIsExpanded ?? true
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mql = window.matchMedia('(max-width: 600px)');
  const isSmallScreen = mql.matches;
  React.useEffect(() => {
    if (isSmallScreen) {
      setIsExpanded(false);
    }
  }, [isSmallScreen]);
  React.useEffect(() => {
    if (showProductFilter) {
      dispatch(fetchProducts());
    }
  }, [showProductFilter]);
  const products = useSelector(summariesWithBookmarksSelector);
  const loading = useSelector(
    (state: ReduxState) => state.reviews.fetchReviewsLoading
  );
  const productOptions = React.useMemo(
    () =>
      products.map((product) => ({
        value: product.id,
        text: getVerboseDisplayProductName(product),
      })),
    [products]
  );
  const attributions: ReviewAttribution[] = [
    'FAMILY',
    'COUPLE',
    'FRIEND',
    'SOLO',
    'BUSINESS',
  ];
  const monthOptions: {
    value: number;
    text: string;
  }[] = [
    {
      value: 1,
      text: t('Jan'),
    },
    {
      value: 2,
      text: t('Feb'),
    },
    {
      value: 3,
      text: t('Mar'),
    },
    {
      value: 4,
      text: t('Apr'),
    },
    {
      value: 5,
      text: t('May'),
    },
    {
      value: 6,
      text: t('Jun'),
    },
    {
      value: 7,
      text: t('Jul'),
    },
    {
      value: 8,
      text: t('Aug'),
    },
    {
      value: 9,
      text: t('Sep'),
    },
    {
      value: 10,
      text: t('Oct'),
    },
    {
      value: 11,
      text: t('Nov'),
    },
    {
      value: 12,
      text: t('Dec'),
    },
  ];
  const languageOptions = [
    {
      value: 'EN_US',
      text: t('English'),
    },
    {
      value: 'JA_JP',
      text: t('Japanese'),
    },
  ];
  return (
    <Form
      initialValues={searchFormValues}
      onSubmit={async (values: SearchFormValues) => {
        onSearchFormValuesChange(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles['c-review__box']}>
            <a
              className={clsx(
                styles['c-review__search__ttl'],
                styles['ac'],
                isExpanded ? styles['is-open'] : styles['is-close']
              )}
            >
              <img src={searchIcon} />
              {t('Search')}
              <p
                className={styles['c-review__close']}
                onClick={() => setIsExpanded(!isExpanded)}
              ></p>
            </a>
            <div
              className={clsx(
                styles['c-review__search__main'],
                styles['ac'],
                isExpanded ? styles['is-open'] : styles['is-close']
              )}
            >
              <div className={styles['c-review__search__main__inner']}>
                <div className={styles['c-review__search__main__item']}>
                  <p className={styles['c-review__search__main__item__ttl']}>
                    {t('Star Rating')}
                  </p>
                  <div
                    className={styles['c-review__search__main__item__checkbox']}
                  >
                    {[5, 4, 3, 2, 1].map((rating) => (
                      <Field
                        name="ratings"
                        type="checkbox"
                        key={`REVIEW_RATING_${rating}`}
                        value={`REVIEW_RATING_${rating}`}
                      >
                        {({ input }) => (
                          <label>
                            <input {...input} />
                            <p></p>
                            <div
                              className={
                                styles[
                                  'c-review__search__main__item__checkbox__stars'
                                ]
                              }
                            >
                              <Stars rating={rating} />
                            </div>
                          </label>
                        )}
                      </Field>
                    ))}
                  </div>
                </div>
                <div className={styles['c-review__search__main__item']}>
                  <p className={styles['c-review__search__main__item__ttl']}>
                    {t('Participation Type')}
                  </p>
                  <div
                    className={styles['c-review__search__main__item__checkbox']}
                  >
                    {attributions.map((attribution) => (
                      <Field
                        key={attribution}
                        name="attributions"
                        type="checkbox"
                        value={attribution}
                      >
                        {({ input }) => (
                          <label>
                            <input {...input} />
                            <p></p>
                            <span>
                              {getReviewAttributionText(attribution, t)}
                            </span>
                          </label>
                        )}
                      </Field>
                    ))}
                  </div>
                </div>
                {showProductFilter && (
                  <div className={styles['c-review__search__main__item']}>
                    <p className={styles['c-review__search__main__item__ttl']}>
                      {t('Product')}
                    </p>
                    <Field name="productIds">
                      {({ input }) => (
                        <MultiSelect
                          search
                          options={productOptions}
                          selectedValues={input.value}
                          onChange={({ value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </div>
                )}
                <div className={styles['c-review__search__main__item']}>
                  {/*
                <div className={styles['c-review__search__main__item__col']}>
                <p className={styles['c-review__search__main__item__ttl']}>キーワード</p>
                <input
                type="text"
                className={styles['c-review__search__main__item__inputText']}
                placeholder="キーワードを入力"
                name="keyword"
                value=""
                />
                </div>
                */}
                  <div className={styles['c-review__search__main__item__col']}>
                    <p className={styles['c-review__search__main__item__ttl']}>
                      {t('Participation Period')}
                    </p>
                    <div
                      className={styles['c-review__search__main__item__flex']}
                    >
                      <Field name="monthStart">
                        {({ input }) => (
                          <label
                            className={
                              styles['c-review__search__main__item__select']
                            }
                          >
                            <select
                              value={`${input.value}`}
                              onChange={(e) => {
                                input.onChange(parseInt(e.target.value));
                              }}
                            >
                              {monthOptions.map((monthOption) => (
                                <option
                                  key={monthOption.value}
                                  value={`${monthOption.value}`}
                                >
                                  {monthOption.text}
                                </option>
                              ))}
                            </select>
                          </label>
                        )}
                      </Field>
                      <p>～</p>
                      <Field name="monthEnd">
                        {({ input }) => (
                          <label
                            className={
                              styles['c-review__search__main__item__select']
                            }
                          >
                            <select
                              value={`${input.value}`}
                              onChange={(e) => {
                                input.onChange(parseInt(e.target.value));
                              }}
                            >
                              {monthOptions.map((monthOption) => (
                                <option
                                  key={monthOption.value}
                                  value={`${monthOption.value}`}
                                >
                                  {monthOption.text}
                                </option>
                              ))}
                            </select>
                          </label>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles['c-review__search__main__item__col']}>
                    <p className={styles['c-review__search__main__item__ttl']}>
                      {t('Language')}
                    </p>
                    <Field name="language">
                      {({ input }) => (
                        <label
                          className={
                            styles['c-review__search__main__item__select']
                          }
                        >
                          <select {...input}>
                            <option value="">{t('All Languages')}</option>
                            {languageOptions.map((languageOption) => (
                              <option
                                key={languageOption.value}
                                value={languageOption.value}
                              >
                                {languageOption.text}
                              </option>
                            ))}
                          </select>
                        </label>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <button
                className={clsx(
                  styles['c-review__search__main__submit'],
                  loading && styles['loading']
                )}
                disabled={loading}
                type="submit"
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
