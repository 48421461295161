import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Select } from 'client/components/Form';
import { InvoicesTab } from 'client/pages/InvoiceRevamped/InvoicesTab';
import { ReduxState } from 'client/reducers';

export const Invoice = () => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const [monthYear, setMonthYear] = React.useState(
    moment().locale(locale).format('YYYY-MM')
  );

  const monthYearFormat = useSelector(
    (state: ReduxState) => state.language.selected.monthYearFormat
  );

  const now = moment().locale(locale);

  const options = _.times(6, (i) => {
    const m = moment(now).subtract(i, 'months');
    const monthYear = m.format(monthYearFormat);
    return {
      key: monthYear,
      text: monthYear,
      value: m.format('YYYY-MM'),
    };
  });

  return (
    <div>
      <Box mb={2}>
        <Select
          maxWidth={200}
          label={t('Month')}
          value={monthYear}
          options={options}
          placeholder={t('Month')}
          onChange={(e, { value }) => setMonthYear(value as string)}
        />
      </Box>
      <InvoicesTab monthYear={monthYear} />
    </div>
  );
};
