import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import baseStyles from 'client/base.module.css';
import editIcon from 'client/images/ic_edit.svg';

import { DownloadReservationsCSVModal } from '../components/DownloadReservationsCSVModal/DownloadReservationsCSVModal';

import { DownloadItemsDisplayBox } from './DownloadItemsDisplayBox';

type Props = {
  appliedDownloadItems: string[];
  onApply: (arg0: string[]) => void;
};
export const DownloadItems = ({ appliedDownloadItems, onApply }: Props) => {
  const { t } = useTranslation();
  const [downloadItems, setDownloadItems] =
    React.useState(appliedDownloadItems);
  return (
    <div>
      <b>{t('Download Items')}</b>
      <DownloadReservationsCSVModal
        trigger={
          <a className={clsx(baseStyles['base-margin-left-8'])}>
            <img src={editIcon} />
          </a>
        }
        downloadItems={downloadItems}
        setDownloadItems={setDownloadItems}
        onApply={onApply}
      />
      <DownloadItemsDisplayBox appliedDownloadItems={appliedDownloadItems} />
      {(appliedDownloadItems ?? []).length === 0 && (
        <p style={{ color: 'red', marginTop: '8px' }}>
          {t('Please select download items.')}
        </p>
      )}
    </div>
  );
};
