import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// ModalLoader is a loader component that can be used inside modals.
export const ModalLoader = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Icon name="circle notched" loading />
      {t('Loading...')}
    </div>
  );
};
