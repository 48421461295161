/* eslint-disable react/no-unescaped-entities */
import styles from './USAPrivacyPolicy.module.css';

export const USAPrivacyPolicy = () => {
  return (
    <div className={styles['p-regist__terms']}>
      <div className={styles['p-regist__terms__header']}>Privacy Policy</div>
      <div className={styles['p-regist__terms__body']}>
        <div className={styles['c-inner--middle']}>
          <div className={styles['terms']}>
            <div className={styles['terms-block']}>
              <p>
                We at NutmegLabs, Inc. (“Nutmeg,” “we,” “us,” “our”) know that
                our users (“you,” “your”) care about how your personal
                information is used and shared, and we take your privacy
                seriously. Please read the following to learn more about our
                Privacy Policy. By visiting or using the Website or Services in
                any manner, you acknowledge that you accept the practices and
                policies outlined in this Privacy Policy, and you hereby consent
                that we will collect, use, and share your information in the
                following ways. Any capitalized terms used herein without
                definition shall have the meaning given to them in the Nutmeg
                Terms and conditions. For all data inquiries or requests, please
                contact info@nutmeglabs.com
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>1. WHAT DOES THIS PRIVACY POLICY COVER?</h3>
              <p>
                This Privacy Policy covers our treatment of personally
                identifiable information ("Personal Information") that we gather
                when you are accessing or using our Website. This policy does
                not apply to the practices of companies that we do not own or
                control, or to individuals that we do not employ or manage. We
                do not knowingly collect or solicit personal information from
                anyone under the age of 13 or knowingly allow such persons to
                register for the Services (as that term is defined in our Terms
                and conditions). If you are under 13, please do not attempt to
                register for the Services or send any information about yourself
                to us, including your name, address, telephone number, or email
                address. No one under age 13 may provide any personal
                information to us or on the Services. In the event that we learn
                that we have collected personal information from a child under
                age 13 without verification of parental consent, we will delete
                that information as quickly as possible. If you believe that we
                might have any information from or about a child under 13,
                please contact us at info@nutmeglabs.com.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>2. WHAT INFORMATION DOES NUTMEG COLLECT?</h3>
              <p>
                We gather various types of Personal Information from our users,
                as explained more fully below. We may use this Personal
                Information to personalize and improve our services, to allow
                our users to set up a user account and profile, to contact
                users, to fulfill your requests for certain products and
                services, to analyze how users utilize the Website, and as
                otherwise set forth in this Privacy Policy. We may share certain
                types of Personal Information with third parties (described in
                this Section and in Section III below). We collect the following
                types of information:
              </p>
              <br />
              <h4>2-1. Information You Provide to Us:</h4>
              <p>
                We receive and store any information you knowingly provide to
                us. For example, we collect Personal Information such as your
                name, email address, browser information, travel preferences,
                and third-party account credentials (for example, your log-in
                credentials for Facebook or other third party sites). If you
                provide your third-party account credentials to us, you
                understand some content and/or information in those accounts
                (“Third Party Account Information”) may be transmitted into your
                account with us if you authorize such transmissions, and that
                Third Party Account Information transmitted to our Website is
                covered by this Privacy Policy. You can choose not to provide us
                with certain information, but then you may not be able to
                register with us or to take advantage of some of our features.
                We may anonymize your Personal Information so that you cannot be
                individually identified, and provide that information to our
                partners.
              </p>
              <br />
              <h4>2-2. Information Collected Automatically:</h4>
              <p>
                Whenever you interact with our Website, we automatically receive
                and record information on our server logs from your browser
                including your IP address, “cookie” information, and the page
                you requested. “Cookies” are identifiers we transfer to your
                computer or mobile device that allow us to recognize your
                browser or mobile device and tell us how and when pages in our
                Website are visited and by how many people. You may be able to
                change the preferences on your browser or mobile device to
                prevent or limit your computer or device’s acceptance of
                cookies, but this may prevent you from taking advantage of some
                of our Website’s features. If you click on a link to a third
                party website, such third party may also transmit cookies to
                you. This Privacy Policy does not cover the use of cookies by
                any third parties. When we collect usage information (such as
                the numbers and frequency of visitors to the Website), we only
                use this data in aggregate form, and not in a manner that would
                identify you personally. For example, this aggregate data tells
                us how often users use parts of the Website, so that we can make
                the Website appealing to as many users as possible. We may also
                provide this aggregate information to our partners; our partners
                may use such information to understand how often and in what
                ways people use our Website, so that they, too, can provide you
                with an optimal experience. We never disclose aggregate
                information to a partner in a manner that would identify you
                personally.
              </p>
              <br />
              <h4>2-3. E-mail and Other Communications:</h4>
              <p>
                We may contact you, by email or other means; for example, we may
                send you promotional offers on behalf of other businesses, or
                communicate with you about your use of the Website. Also, we may
                receive a confirmation when you open an email from us. This
                confirmation helps us make emails more interesting and improve
                our service. If you do not want to receive email or other mail
                from us, please indicate your preference by emailing us at
                info@nutmeglabs.com. Please note that if you do not want to
                receive legal notices from us, those legal notices will still
                govern your use of the Website, and you are responsible for
                reviewing such legal notices for changes.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>3. HOW DOES NUTMEG USE YOUR INFORMATION?</h3>
              <p>
                We may use, or disclose the personal information we collect for
                one or more of the following business purposes:
              </p>
              <ul className={styles['privacy']}>
                <li>
                  To fulfill or meet the reason you provided the information.
                  For example, if you share your name and contact information to
                  request a demo or ask a question about our products or
                  services through our Website, we will use that personal
                  information to respond to your inquiry. If you provide your
                  personal information to purchase a product or service, we will
                  use that information to process your payment and submit your
                  booking. We may also save your information to enable Providers
                  to facilitate new product orders or process returns.
                </li>
                <li>
                  To provide, support, personalize, and develop our Website,
                  products, and services.
                </li>
                <li>
                  To create, maintain, customize, and secure your account with
                  us.
                </li>
                <li>
                  To process your requests, purchases, transactions, and
                  payments and prevent transactional fraud.
                </li>
                <li>
                  To provide you with support and to respond to your inquiries,
                  including to investigate and address your concerns and monitor
                  and improve our responses.
                </li>
                <li>
                  To personalize your Website experience and to deliver content
                  and product and service offerings relevant to your interests,
                  including targeted offers and ads through our Website, the
                  Service, and via email or text message (with your consent,
                  where required by law).
                </li>
                <li>
                  To help maintain the safety, security, and integrity of our
                  Website, products and services, databases and other technology
                  assets, and business.
                </li>
                <li>
                  For testing, research, analysis, and product development,
                  including to develop and improve our Website, products, and
                  services.
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  As described to you when collecting your personal information,
                  as otherwise described in this Privacy Policy, or as otherwise
                  set forth in applicable law.
                </li>
                <li>
                  To evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Nutmeg’s assets, whether as a going concern or as
                  part of bankruptcy, liquidation, or similar proceeding, in
                  which personal information held by Nutmeg about our Website
                  users is among the assets transferred.
                </li>
              </ul>
            </div>
            <div className={styles['terms-block']}>
              <h3>
                4. WILL NUTMEG SHARE ANY OF THE PERSONAL INFORMATION IT
                RECEIVES?
              </h3>
              <p>
                We neither rent nor sell your Personal Information in personally
                identifiable form to anyone. We share your Personal Information
                in personally identifiable form with third parties as described
                below.
              </p>
              <br />
              <h4>
                4-1. Affiliated Businesses and Third Party Websites We Do Not
                Control:
              </h4>
              <p>
                In certain situations, businesses or third party websites we’re
                affiliated with may sell items or provide services to you
                through the Website (either alone or jointly with us). You can
                recognize when an affiliated business is associated with such a
                transaction or service, and we will share your Personal
                Information with that affiliated business only to the extent
                that it is related to such transaction or service. For example,
                we may need to share certain information about you with a
                Merchant, so that Merchant can allow you to use your Product at
                its place of business. Another such transaction or service may
                include the ability for you to automatically transmit Third
                Party Account Information to your Website profile or to
                automatically transmit information in your Website profile to
                your third party account. We have no control over the policies
                and practices of third party websites or businesses as to
                privacy or anything else, so if you choose to take part in any
                transaction or service relating to an affiliated website or
                business, please review all such business’ or websites’
                policies.
              </p>
              <br />
              <h4>4-2. Agents:</h4>
              <p>
                We employ other companies and people to perform tasks on our
                behalf and need to share your information with them to provide
                products or services to you. Unless we tell you differently, our
                agents do not have any right to use the Personal Information we
                share with them beyond what is necessary to assist us.
              </p>
              <br />
              <h4>4-3. User Profiles and Submissions:</h4>
              <p>
                Certain user profile information, including without limitation a
                user’s name, location, and any video or image content that such
                user has uploaded to the Website, may be displayed to other
                users to facilitate user interaction within the Website or
                address your request for Nutmeg’s services. Your account privacy
                settings may allow you to limit the other users who can see the
                Personal Information in your user profile and/or what
                information in your user profile is visible to others. Any
                content you upload to your public user profile, along with any
                Personal Information or content that you voluntarily disclose
                online in a manner other users can view (on discussion boards,
                in messages and chat areas, etc.) becomes publicly available,
                and can be collected and used by others. Your user name may also
                be displayed to other users if and when you send messages or
                comments or upload images or videos through the Website and
                other users can contact you through messages and comments.
                Additionally, if you sign into the Website through a third party
                social networking site or service, your list of “friends” from
                that site or service may be automatically imported to the
                Website, and such “friends,” if they are also registered users
                of the Website, may be able to access certain non-public
                information you have entered in your Website user profile.
                Again, we do not control the policies and practices of any other
                third party site or service.
              </p>
              <br />
              <h4>4-4. Business Transfers:</h4>
              <p>
                We may choose to buy or sell assets. In these types of
                transactions, customer information is typically one of the
                business assets that would be transferred. Also, if we (or
                substantially all of our assets) are acquired, or if we go out
                of business, enter bankruptcy, or go through some other change
                of control, Personal Information would be one of the assets
                transferred to or acquired by a third party.
              </p>
              <br />
              <h4>4-5. Protection of Nutmeg and Others:</h4>
              <p>
                We reserve the right to access, read, preserve, and disclose any
                information that we reasonably believe is necessary to comply
                with law or court order; enforce or apply our conditions of use
                and other agreements; or protect the rights, property, or safety
                of Nutmeg, our employees, our users, or others. This includes
                exchanging information with other companies and organizations
                for fraud protection and credit risk reduction.
              </p>
              <br />
              <h4>4-6. Protection of Children:</h4>
              <p>
                Nutmeg’s sites are general audience websites and do not offer
                services directed to children. You must be 16 years of age or
                older to book using Nutmeg. Should a child whom we know to be
                under 13 send personal information to us, we will use that
                information only to respond directly to that child to inform him
                or her that we must have parental consent before receiving his
                or her personal information and will delete the information. If
                you believe that Nutmeg has been provided with the personal
                information of a child under 13 without parental consent, please
                notify us immediately at info@nutmeglabs.com.
              </p>
              <br />
              <h4>4-7. With Your Consent:</h4>
              <p>
                Except as set forth above, you will be notified when your
                Personal Information may be shared with third parties in
                personally identifiable form, and will be able to prevent the
                sharing of this information.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>5. IS PERSONAL INFORMATION ABOUT ME SECURE?</h3>
              <p>
                Your account is protected by a password for your privacy and
                security. If you access your account via a third party site or
                service, you may have additional or different sign-on
                protections via that third party site or service. You must
                prevent unauthorized access to your account and Personal
                Information by selecting and protecting your password and/or
                other sign-on mechanism appropriately and limiting access to
                your computer or device and browser by signing off after you
                have finished accessing your account. We endeavor to protect the
                privacy of your account and other Personal Information we hold
                in our records, but we cannot guarantee complete security.
                Unauthorized entry or use, hardware or software failure, and
                other factors, may compromise the security of user information
                at any time. The Website may contain links to other sites. We
                are not responsible for the privacy policies and/or practices on
                other sites. When following a link to another site you should
                read that site’s privacy policy.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>6. WHAT PERSONAL INFORMATION CAN I ACCESS?</h3>
              <p>
                Through your account settings, you may access, and, in some
                cases, edit or delete the following information you’ve provided
                to us:
              </p>
              <ul className={styles['privacy']}>
                <li>name and password</li>
                <li>email address</li>
                <li>location</li>
                <li>
                  user profile information, including images and videos you have
                  uploaded to the site.
                </li>
              </ul>
              <br />
              <p>
                The information you can view, update, and delete may change as
                the Website changes. If you have any questions about viewing or
                updating information we have on file about you, please contact
                us at info@nutmeglabs.com. Under California Civil Code Sections
                1798.83-1798.84, California residents are entitled to ask us for
                a notice identifying the categories of Personal Information
                which we share with our affiliates and/or third parties for
                marketing purposes, and providing contact information for such
                affiliates and/or third parties. If you are a California
                resident and would like a copy of this notice, please submit a
                written request to: info@nutmeglabs.com.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>7. WHAT CHOICES DO I HAVE?</h3>
              <p>
                You can always opt not to disclose information to use, but keep
                in mind some information may be needed to register with us or to
                take advantage of some of our special features. You may be able
                to add, update, or delete information as explained in Section V
                above. When you update information, however, we may maintain a
                copy of the unrevised information in our records. Please note
                that some information may remain in our records after your
                deletion of such information from your account. We may use any
                aggregated data derived from or incorporating your Personal
                Information after you update or delete it, but not in a manner
                that would identify you personally. If you are visiting our site
                or using our services from outside the United States, please be
                aware that your information may be transferred to, stored, and
                processed in the United States. The data protection and other
                laws of the United States might be different from those in your
                country, but please be assured that we take steps to ensure that
                your privacy is protected in accords with this policy. By using
                our services and voluntarily submitting your information to us,
                you consent to the general use of such information as provided
                in this Privacy Policy and to the transfer of that information
                to and storage of that information in the United States. For
                individuals in the European Economic Area or Switzerland, please
                also review the corresponding section in our Terms and
                conditions As a customer, you may also request a copy of your
                stored data as well as appropriate data deletion. Please submit
                a request online or email our team at info@nutmeglabs.com
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>8. CHANGES TO THIS PRIVACY POLICY</h3>
              <p>
                We may amend this Privacy Policy from time to time. Use of
                information we collect now is subject to the Privacy Policy in
                effect at the time such information is used. If we make changes
                in the way we use Personal Information, we will notify you by
                posting an announcement on our Website or sending you an email.
                You are bound by any changes to the Privacy Policy when you use
                the Website after such changes have been first posted.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <h3>9. QUESTIONS OR CONCERNS</h3>
              <p>
                If you have any questions or concerns regarding our privacy
                policies, please send us a detailed message to
                info@nutmeglabs.com. We will make every effort to resolve your
                concerns.
              </p>
            </div>
            <div className={styles['terms-block']}>
              <p>Effective Date: </p>
              <p>January 1st, 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
