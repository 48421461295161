import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';

export const ManifestCustomizeHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader title={t('Customize')}>
      <></>
    </PageHeader>
  );
};
