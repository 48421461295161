import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './StepsNavigation.module.css';

type Props = {
  currentStep: string;
  steps: { name: string; text: string }[];
};

// Render max 3 steps navigation
export const StepsNavigation = ({ currentStep, steps }: Props) => {
  const { t } = useTranslation();

  if (steps.length > 3) {
    return null;
  }

  return (
    <ul className={styles['steps-nav']}>
      {steps.map((step, index) => (
        <li
          key={index}
          className={clsx(currentStep === step.name && styles['is-active'])}
          style={{
            borderTopLeftRadius: index === 0 ? '8px' : undefined,
            borderBottomLeftRadius: index === 0 ? '8px' : undefined,
          }}
        >
          {`STEP${index + 1}`}
          <span> - {t(step.text)}</span>
        </li>
      ))}
    </ul>
  );
};
