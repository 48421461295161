import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Box } from 'client/components/Box/Box';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { reservationIsCheckinCheckoutOnly } from 'client/libraries/util/util';
import { ReservationContext } from 'client/pages/ReservationDetails/ReservationContext';

import { EditTransportRouteModal } from './EditTransportRouteModal';
import styles from './TransportRoutePanel.module.css';

const formattedTransportLocation = (
  locationName: string,
  dateTimeUtc: string,
  timezone: string,
  t: (s: string) => string
): string => {
  if (!locationName) {
    return t('TBD');
  }

  return `${
    dateTimeUtc && moment.tz(dateTimeUtc, timezone).format('HH:mm')
  } ${locationName}`;
};

export const TransportRoutePanel = () => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const reservation = React.useContext(ReservationContext);

  if ((reservation?.transport_route ?? []).length === 0) {
    return null;
  }

  const pickupCheckin =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkin
      : reservation?.pickup;
  const dropoffCheckout =
    reservation && reservationIsCheckinCheckoutOnly(reservation)
      ? reservation?.checkout
      : reservation?.dropoff;
  return (
    <>
      {showEditModal && (
        <EditTransportRouteModal
          open={true}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <Box mt={4}>
        <FormTableBox>
          <Box display="flex" alignItems="flex-end">
            <div className={styles['header']}>{t('Transport Routes')}</div>
            <Box ml="auto" mb={2}>
              <EditIcon onClick={() => setShowEditModal(true)} />
            </Box>
          </Box>
          <table>
            <tbody>
              {reservation?.transport_route?.map((transportRouteItem, idx) => {
                const locationFrom = transportRouteItem?.location_from;
                const locationTo = transportRouteItem?.location_to;
                return (
                  <tr key={idx}>
                    <th>
                      {t('Route #{{routeIdx}}', {
                        routeIdx: idx + 1,
                      })}
                    </th>
                    <td>
                      {locationFrom
                        ? formattedTransportLocation(
                            locationFrom?.location_name ?? '',
                            locationFrom?.date_time_utc ?? '',
                            reservation?.start_timezone ?? '',
                            t
                          )
                        : formattedTransportLocation(
                            pickupCheckin?.location_name ?? '',
                            pickupCheckin?.date_time_utc ?? '',
                            reservation?.start_timezone ?? '',
                            t
                          )}{' '}
                      -{' '}
                      {locationTo
                        ? formattedTransportLocation(
                            locationTo?.location_name ?? '',
                            locationTo?.date_time_utc ?? '',
                            reservation?.start_timezone ?? '',
                            t
                          )
                        : formattedTransportLocation(
                            dropoffCheckout?.location_name ?? '',
                            dropoffCheckout?.date_time_utc ?? '',
                            reservation?.start_timezone ?? '',
                            t
                          )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormTableBox>
      </Box>
    </>
  );
};
