import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';

import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import {
  Button,
  FocusableInput,
  Input,
  MultiSelect,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { AlertModal } from 'client/components/AlertModal/AlertModal';
import { MessageModal } from 'client/components/MessageModal/MessageModal';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateTranslations } from 'client/actions/translations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { ModalLoader } from 'client/components/ModalLoader';
import { ProductIdsListInput } from 'client/components/ProductIdsListInput/ProductIdsListInput';
import {
  createSurveyTemplate,
  updateSurveyTemplate,
  fetchSourceLanguageSurveyTemplateByID,
  fetchSurveyTemplates,
} from 'client/actions/surveyTemplates';
import type { ReduxState } from 'client/reducers';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { SurveyTemplate } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { config } from 'client/config';

import styles from './SurveyTemplateEditor.module.css';
import {
  convertFormValuesToSwaggerSurveyTemplate,
  convertSwaggerSurveyPageToFormValuePage,
  createSurveyPageForNpsSurvey,
  FormValues,
  getInitialValues,
  getSurveyStatusText,
  SurveyType,
} from './formValues';
import { SurveyContentEditor } from './SurveyContentEditor';

interface LocationState {
  existingSurveyTemplate?: SurveyTemplate;
}

const focusOnError: any = createDecorator<FormValues, Partial<FormValues>>();
export const SurveyTemplateEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();
  const surveyTemplates = useSelector(
    (state: ReduxState) => state.surveyTemplates.all
  );
  const products = useSelector((state: ReduxState) => state.products.summaries);
  const location = useLocation<LocationState>();

  const productsLoading = useSelector(
    (state: ReduxState) => state.products.loading
  );

  const [
    showProductIdsAlreadyUsedAlertModal,
    setShowProductIdsAlreadyUsedAlertModal,
  ] = React.useState(false);

  const [showPublishConfirmModal, setShowPublishConfirmModal] =
    React.useState(false);
  const [showUnpublishConfirmModal, setShowUnpublishConfirmModal] =
    React.useState(false);

  React.useEffect(() => {
    dispatch(fetchSurveyTemplates());
  }, []);
  React.useEffect(() => {
    if (id) {
      dispatch(fetchSourceLanguageSurveyTemplateByID(id));
    }
  }, [id]);
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const existingSurveyTemplate = id
    ? surveyTemplates.find((surveyTemplate) => surveyTemplate.id === id) ??
      undefined
    : location?.state?.existingSurveyTemplate;

  const isCopy = Boolean(location?.state?.existingSurveyTemplate);

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const initialValues = React.useMemo(() => {
    const initialVals = getInitialValues(existingSurveyTemplate);

    if (isCopy) {
      initialVals.title = `[COPY] ${initialVals.title}`;
      initialVals.templateName = `[COPY] ${initialVals.templateName}`;
      initialVals.status = 'SURVEY_TEMPLATE_UNPUBLISHED';
    }

    return initialVals;
  }, [existingSurveyTemplate, isCopy]);

  const productIdsUnusedInOtherTemplates = React.useCallback(
    (productIds: any) => {
      if (!productIds) return '';

      for (const surveyTemplate of surveyTemplates) {
        if (surveyTemplate.id === id) {
          continue;
        }
        if (surveyTemplate.status === 'SURVEY_TEMPLATE_UNPUBLISHED') {
          continue;
        }
        for (const productId of surveyTemplate.product_ids ?? []) {
          if (productIds.includes(productId)) {
            const productName =
              products.find((product) => product.id === productId)
                ?.product_name ?? productId;
            return t(
              'Survey "{{templateName}}" is already assigned to product "{{productName}}"',
              {
                templateName: surveyTemplate.title,
                productName,
              }
            );
          }
        }
      }

      return '';
    },
    [surveyTemplates, products, id]
  );

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['base-main__body']}>
      <div className={styles['back-arrow']}>
        <Link to="/surveys/templates">
          <BackArrow />
        </Link>
      </div>
      <Form<FormValues>
        onSubmit={async (values: FormValues) => {
          try {
            const surveyTemplate =
              convertFormValuesToSwaggerSurveyTemplate(values);
            const surveySaveAction = id
              ? updateSurveyTemplate(id, { ...surveyTemplate })
              : createSurveyTemplate(surveyTemplate);

            if (
              values?.showTranslationMode &&
              values?.translationTargetLanguage
            ) {
              const sourceLanguage = values?.sourceLanguage;
              const translationTargetLanguage =
                values?.translationTargetLanguage;

              await Promise.all([
                dispatch(surveySaveAction),
                dispatch(
                  updateTranslations(
                    values?.translations.map((translation) => ({
                      source_language: values?.sourceLanguage,
                      [sourceLanguage.toLowerCase()]: translation.source,
                      [translationTargetLanguage?.toLowerCase()]:
                        translation.target,
                    }))
                  )
                ),
              ]);
            } else {
              await dispatch(surveySaveAction);
            }

            setSaveSucceeded(true);

            if (!id) {
              const url = `/surveys/templates/${
                store.getState().surveyTemplates.lastCreated?.id ?? ''
              }/edit`;
              history.push(url);
            }
          } catch (err) {
            console.log(err);
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={baseStyles['base-main__body__box']}
                style={{ maxWidth: '1000px' }}
              >
                <div className={baseStyles['base-main__body__box__body']}>
                  <p className={styles['table__section__header']}>
                    {t('Settings')}
                  </p>
                  <FormTableBox>
                    <table>
                      <tbody>
                        {config.enableNpsSurvey && (
                          <tr>
                            <th>{t('Survey Format')}</th>
                            <td>
                              <EnumRadioButtonGroup
                                isRequired
                                name="surveyType"
                                options={[
                                  {
                                    value: 'NORMAL',
                                    label: t('Normal'),
                                  },
                                  {
                                    value: 'NPS',
                                    label: t('NPS'),
                                  },
                                ]}
                                onChange={(newValue: string) => {
                                  form.change(
                                    'surveyType',
                                    newValue as SurveyType
                                  );
                                  if (newValue === 'NPS') {
                                    form.change(
                                      'pages',
                                      createSurveyPageForNpsSurvey(t)
                                    );
                                  } else {
                                    form.change(
                                      'pages',
                                      existingSurveyTemplate?.pages?.map(
                                        (page) =>
                                          convertSwaggerSurveyPageToFormValuePage(
                                            page
                                          )
                                      ) ?? []
                                    );
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <th>{t('Template Name')}</th>
                          <td>
                            <Field name="templateName">
                              {({ input }) => <Input {...input} />}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Status')}</th>
                          <td>
                            <Box display="flex" alignItems="center">
                              {getSurveyStatusText(values?.status, t)}
                              {showPublishConfirmModal && (
                                <MessageModal
                                  open={true}
                                  onClose={() =>
                                    setShowPublishConfirmModal(false)
                                  }
                                  title={t('Publish Survey')}
                                  message={t(
                                    'Are you sure you wish to publish this survey?'
                                  )}
                                  onSubmit={() => {
                                    form.change(
                                      'status',
                                      'SURVEY_TEMPLATE_PUBLISHED'
                                    );
                                    handleSubmit();
                                    setShowPublishConfirmModal(false);
                                  }}
                                />
                              )}
                              {values?.status ===
                                'SURVEY_TEMPLATE_UNPUBLISHED' && (
                                <Box ml="auto">
                                  <Button
                                    style="green"
                                    size="middle"
                                    onClick={() => {
                                      if (
                                        productIdsUnusedInOtherTemplates(
                                          values?.productIds ?? []
                                        )
                                      ) {
                                        setShowProductIdsAlreadyUsedAlertModal(
                                          true
                                        );
                                      } else {
                                        setShowPublishConfirmModal(true);
                                      }
                                    }}
                                  >
                                    {t('Save and Publish')}
                                  </Button>
                                </Box>
                              )}
                              {showProductIdsAlreadyUsedAlertModal && (
                                <AlertModal
                                  header={t(
                                    'Product Already Used in Published Survey'
                                  )}
                                  content={
                                    <>
                                      <div>
                                        {productIdsUnusedInOtherTemplates(
                                          values.productIds ?? []
                                        )}
                                      </div>
                                      <Box mt={2}>
                                        {t(
                                          'Please un-assign the product or unpublish the existing published survey before publishing this survey.'
                                        )}
                                      </Box>
                                    </>
                                  }
                                  open={true}
                                  onClose={() =>
                                    setShowProductIdsAlreadyUsedAlertModal(
                                      false
                                    )
                                  }
                                />
                              )}
                              {showUnpublishConfirmModal && (
                                <MessageModal
                                  open={true}
                                  onClose={() =>
                                    setShowUnpublishConfirmModal(false)
                                  }
                                  title={t('Unpublish Survey')}
                                  message={t(
                                    'Are you sure you wish to unpublish this survey?'
                                  )}
                                  onSubmit={() => {
                                    form.change(
                                      'status',
                                      'SURVEY_TEMPLATE_UNPUBLISHED'
                                    );
                                    handleSubmit();
                                    setShowUnpublishConfirmModal(false);
                                  }}
                                />
                              )}
                              {values?.status ===
                                'SURVEY_TEMPLATE_PUBLISHED' && (
                                <Box ml="auto">
                                  <Button
                                    style="green"
                                    size="middle"
                                    onClick={() =>
                                      setShowUnpublishConfirmModal(true)
                                    }
                                  >
                                    {t('Save and Unpublish')}
                                  </Button>
                                </Box>
                              )}
                            </Box>
                          </td>
                        </tr>

                        <tr>
                          <th>{t('Products')}</th>
                          <td>
                            {productsLoading ? (
                              <ModalLoader />
                            ) : (
                              <Field
                                name="productIds"
                                validate={
                                  values?.status === 'SURVEY_TEMPLATE_PUBLISHED'
                                    ? productIdsUnusedInOtherTemplates
                                    : undefined
                                }
                              >
                                {({ input, meta: { error, touched } }) => (
                                  <>
                                    <ProductIdsListInput
                                      productIds={input.value}
                                      onChange={(newProductIds) =>
                                        input.onChange(newProductIds)
                                      }
                                      error={touched ? error : undefined}
                                    />
                                    <FocusableInput name="productIds" />
                                  </>
                                )}
                              </Field>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Language')}</th>
                          <td>
                            {id != null ? (
                              values?.sourceLanguage === 'EN_US' ? (
                                t('English')
                              ) : (
                                t('Japanese')
                              )
                            ) : (
                              <EnumRadioButtonGroup
                                isRequired
                                name="sourceLanguage"
                                options={[
                                  {
                                    value: 'JA_JP',
                                    label: t('Japanese'),
                                  },
                                  {
                                    value: 'EN_US',
                                    label: t('English'),
                                  },
                                ]}
                              />
                            )}
                          </td>
                        </tr>
                        {values?.sourceLanguage && (
                          <tr>
                            <th>{t('Translated Languages')}</th>
                            <td>
                              <Field name="translatedLanguages">
                                {({ input }) => (
                                  <MultiSelect
                                    options={[
                                      { value: 'EN_US', text: t('English') },
                                      { value: 'JA_JP', text: t('Japanese') },
                                      { value: 'KO_KR', text: t('Korean') },
                                      {
                                        value: 'ZH_CN',
                                        text: t('Chinese (Simplified)'),
                                      },
                                      {
                                        value: 'ZH_TW',
                                        text: t('Chinese (Traditional)'),
                                      },
                                    ].filter(
                                      (option) =>
                                        option.value !== values?.sourceLanguage
                                    )}
                                    selectedValues={values?.translatedLanguages}
                                    onChange={({ value }) =>
                                      input.onChange(value)
                                    }
                                  />
                                )}
                              </Field>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormTableBox>

                  <Box mt={2}>
                    <SurveyContentEditor surveyTemplateId={id} />
                  </Box>

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      loading={submitting}
                      size="small"
                      style="blue"
                      type="submit"
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
