import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import type { CardPaymentLogItem } from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import baseStyles from 'client/v3-base.module.css';

type Props = {
  paymentLog: CardPaymentLogItem[];
};

const getPaymentTypeText = (
  paymentType: 'CHARGE' | 'REFUND',
  t: TranslateFuncType
): string => {
  switch (paymentType) {
    case 'CHARGE':
      return t('Charge');
    case 'REFUND':
    default:
      return t('Refund');
  }
};

export const ReservationCardPaymentLog = ({ paymentLog }: Props) => {
  const { t } = useTranslation();

  if ((paymentLog?.length ?? 0) === 0) {
    return null;
  }

  return (
    <>
      <p className={styles['p-claim__item__ttl']}>
        {t('Credit Card Payment History')}
      </p>
      <table className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}>
        <thead>
          <tr>
            <th className={baseStyles['u-width-128']}>{t('Date/Time')}</th>
            <th className={baseStyles['u-width-128']}>{t('Charge/Refund')}</th>
            <th className={baseStyles['u-width-128']}>{t('Amount')}</th>
            <th className={baseStyles['u-width-128']}>{t('Card Info')}</th>
            <th className={baseStyles['u-width-128']}>{t('Processing ID')}</th>
          </tr>
        </thead>
        <tbody>
          {paymentLog?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td className={tableStyles['c-tableSmall__spTtl']}>
                  {moment(item.date_time_utc).format('YYYY/MM/DD HH:mm')}
                </td>
                <td data-text={t('Charge/Refund')}>
                  {item.type ? getPaymentTypeText(item.type, t) : ''}
                </td>
                <td data-text={t('Amount')}>
                  {formattedCurrencyAmount(item.amount ?? '')}
                </td>
                <td data-text={t('Card Info')}>
                  <div className={styles['p-claim__cardinfo']}>
                    <div>{item.card_info?.card_brand ?? ''}</div>
                    <div>xxxx{item.card_info?.last_four_digits}</div>
                    <div>
                      {t('Exp: ')}
                      {item.card_info?.exp_month} /{' '}
                      {(item.card_info?.exp_year ?? 0) % 100}
                    </div>
                  </div>
                </td>
                <td
                  data-text={t('Processing ID')}
                  style={{ wordBreak: 'break-all' }}
                >
                  {item.card_info?.payment_profile_gateway_reference ?? ''}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
