import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { FocusableInput } from 'client/components/Form';
import { ProductIdStartTimeListInput } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResourceContents/ProductIdStartTimeListInput';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import styles from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCostumize.module.css';

interface Props {
  name: string;
}

export const VehicleAvailabilityDepartureTimeForm = ({ name }: Props) => {
  const { t } = useTranslation();
  const nums = [...Array(100)].map((_, i) => {
    return {
      value: String(i + 1),
      text: String(i + 1),
    };
  });
  return (
    <>
      <div className={styles['p-manifestsModalCustomize__item__body__flex']}>
        <div className={styles['p-manifestsModalCustomize__item']}>
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Start Time')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <Field name={`${name}.timeLocal`}>
              {({ input }) => (
                <TimePicker
                  allowEmpty={false}
                  value={moment(input.value, 'HH:mm')}
                  onChange={(newMoment) => {
                    if (!newMoment) {
                      return;
                    }

                    input.onChange(newMoment.format('HH:mm'));
                  }}
                />
              )}
            </Field>
          </div>
        </div>
        <div
          className={styles['p-manifestsModalCustomize__item']}
          style={{ marginTop: 0 }}
        >
          <p className={styles['p-manifestsModalCustomize__item__ttl']}>
            {t('Capacity')}
          </p>
          <div className={styles['p-manifestsModalCustomize__item__body']}>
            <Field name={`${name}.capacity`}>
              {({ input }) => (
                <SingleDropdown
                  options={[...nums]}
                  selectedOption={input.value}
                  onChange={(value) => input.onChange(value)}
                  width={100}
                />
              )}
            </Field>
          </div>
        </div>
      </div>

      <div className={styles['p-manifestsModalCustomize__item']}>
        <p className={styles['p-manifestsModalCustomize__item__ttl']}>
          {t('Products')}
        </p>
        <div className={styles['p-manifestsModalCustomize__item__body']}>
          <Field name={`${name}.productStartTimes`}>
            {({ input, meta: { error, touched } }) => (
              <>
                <ProductIdStartTimeListInput
                  productStartTimes={input.value}
                  onChange={(newProductStartTimes) =>
                    input.onChange(newProductStartTimes)
                  }
                  error={touched ? error : undefined}
                  filter={(product) =>
                    !product.booking_widget_settings?.is_free_start_date_time
                  }
                />
                <FocusableInput name="productIds" />
              </>
            )}
          </Field>
        </div>
      </div>
    </>
  );
};
