import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  updateEquipmentInstance,
  fetchEquipmentInstances,
} from 'client/actions/equipmentInstances';
import { Modal } from 'client/components/Modal/Modal';
import { Input, Button } from 'client/components/Form';
import { EquipmentInstance } from 'shared/models/swagger';

interface FormValues {
  memo: string;
}

const getInitialValues = (
  equipmentInstance: EquipmentInstance | undefined
): FormValues => {
  return {
    memo: equipmentInstance?.memo?.value ?? '',
  };
};

const convertToEquipmentInstancePatch = (
  values: FormValues
): EquipmentInstance => {
  return {
    memo: {
      value: values.memo,
    },
  };
};

type Props = {
  equipmentInstance: EquipmentInstance | undefined;
  open: boolean;
  onClose: () => void;
};

export const EquipmentInstanceMemoModal = ({
  equipmentInstance,
  open,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  return (
    <Modal
      title={t('Edit memo displayed on ticket')}
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={async (value: FormValues) => {
          const startDate = equipmentInstance?.date;
          const endDate = moment
            .tz(startDate, activeUserOrganization?.default_timezone ?? 'UTC')
            .add(1, 'day')
            .format('YYYY-MM-DD');

          try {
            await dispatch(
              updateEquipmentInstance({
                ...equipmentInstance,
                ...convertToEquipmentInstancePatch(value),
              })
            );
            await dispatch(
              fetchEquipmentInstances(
                '',
                equipmentInstance?.equipment_id ?? '',
                startDate,
                endDate
              )
            );
          } catch (e) {
            console.log(e);
          }
          onClose();
        }}
        initialValues={getInitialValues(equipmentInstance)}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Content>
              <Modal.Box>
                <Field name="memo">
                  {({ input }) => <Input label={t('Memo')} {...input} />}
                </Field>
              </Modal.Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel onClick={onClose}>{t('Discard')}</Button.Cancel>
              <Button.Submit
                loading={submitting}
                size="middle"
                style="blue"
                type="submit"
              >
                {t('Save')}
              </Button.Submit>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
