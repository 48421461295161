import * as React from 'react';

import { BaseSection } from './BaseSection';
import { Section } from './util';

type Props = {
  data: Section;
};

export const Navigation = ({ data }: Props) => {
  const navigation = data.navigation;

  if (!navigation) {
    return <></>;
  }

  const itemsPerRow = 3;
  const rows = [];
  for (let i = 0; i < navigation.items.length; i += itemsPerRow) {
    const rowItems = navigation.items.slice(i, i + itemsPerRow);
    rows.push(rowItems);
  }

  return (
    <BaseSection data={data}>
      <>
        {navigation.style !== 'VERTICAL' && (
          <table
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '0 auto',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td style={{ textAlign: 'center' }}>
                    {row.map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        {navigation.style === 'HORIZONTAL_WITH_LINE' &&
                          itemIndex > 0 &&
                          row.length > 1 && (
                            <div
                              style={{
                                display: 'inline-block',
                                width: '1px',
                                height: '24px',
                                backgroundColor:
                                  navigation.textStyle?.color ??
                                  'rgba(0,0,0,0.2)',
                                margin: '8px 24px',
                                verticalAlign: 'middle',
                              }}
                            ></div>
                          )}
                        <div
                          style={{
                            display: 'inline-block',
                            margin: '8px 24px',
                          }}
                        >
                          <a
                            href={item.linkUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: navigation.textStyle?.color ?? 'inherit',
                            }}
                          >
                            {navigation.useIcon && (
                              <img
                                src={item.iconUrl ?? ''}
                                style={{
                                  marginRight: '6px',
                                  height: '16px',
                                  width: '16px',
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                }}
                              />
                            )}
                            <p
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: 0,
                              }}
                            >
                              {item.text}
                            </p>
                          </a>
                        </div>
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {navigation.style === 'VERTICAL' && (
          <table
            style={{
              width: '528px',
              textAlign: 'center',
              margin: '0 16px',
              borderCollapse: 'collapse',
            }}
          >
            <tbody>
              {navigation.items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        textAlign: 'left',
                        borderBottom:
                          index === navigation.items.length - 1
                            ? undefined
                            : navigation.textStyle?.color
                            ? `1px solid ${navigation.textStyle?.color}`
                            : '1px solid rgba(0,0,0,0.2)',
                        paddingTop: index === 0 ? undefined : '16px',
                        paddingBottom:
                          index === navigation.items.length - 1
                            ? undefined
                            : '16px',
                      }}
                    >
                      <a
                        href={item.linkUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: navigation.textStyle?.color ?? 'inherit',
                        }}
                      >
                        {navigation.useIcon && (
                          <img
                            src={item.iconUrl ?? ''}
                            style={{
                              marginRight: '6px',
                              height: '16px',
                              width: '16px',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                          />
                        )}
                        <p
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            margin: 0,
                          }}
                        >
                          {item.text}
                        </p>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </>
    </BaseSection>
  );
};
