import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, useFormState, useForm } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { getArrayMutators } from 'client/libraries/util/form';
import { customPagesSelector } from 'client/reducers/customPages';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { getValidators } from 'shared/libraries/validate/validator';
import { Button, FieldWrapper, Input, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';

import { convertFormValuesToSwaggerFooterLink } from './FormValues';
import type { FooterLink } from './FormValues';
import { updateFooterLink } from './util';

type Props = {
  name: string;
  open: boolean;
  onClose: () => void;
  onSave?: (value: FooterLink) => void;
};
export const EditFooterLinkModal = ({ name, open, onClose, onSave }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm();
  const formState = useFormState();
  const { required } = getValidators(t);
  const customPages = useSelector(customPagesSelector);

  const initialValuesFromForm = _.get(formState.values, name);

  const initialValues = initialValuesFromForm
    ? initialValuesFromForm
    : {
        text: '',
        url: '',
        customPagePath: '/about',
        key: uuidv4(),
        linkType: 'CUSTOM_PAGE_PATH',
      };
  const linkTypeOptions = [
    {
      key: 'CUSTOM_PAGE_PATH',
      value: 'CUSTOM_PAGE_PATH',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'URL',
      value: 'URL',
      label: t('Enter URL directly'),
    },
  ];
  const customPagePathOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];
  return (
    <Modal
      title={t('Edit footer link')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: FooterLink) => {
          await dispatch(
            updateFooterLink(
              convertFormValuesToSwaggerFooterLink(values),
              formState.values?.footerLinkLanguage ?? 'JA_JP'
            )
          );
          onSave?.(values);
          onClose();
        }}
        mutators={getArrayMutators()}
      >
        {({ handleSubmit, submitting, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              e.stopPropagation();
            }}
          >
            <Modal.Content>
              <Box mb={2}>
                <Field name="text" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <Input
                      label={t('Title')}
                      error={touched && error}
                      {...input}
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <FieldWrapper label={t('Page to open')}>
                  <EnumRadioButtonGroup
                    name="linkType"
                    options={linkTypeOptions}
                    onChange={(value: string) => {
                      if (value === 'CUSTOM_PAGE_PATH') {
                        form.change('customPagePath', '/about');
                        form.change('url', '');
                      } else {
                        form.change('customPagePath', '');
                        form.change('url', '');
                      }
                    }}
                  />
                  {values?.linkType === 'CUSTOM_PAGE_PATH' && (
                    <Field name="customPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <Select
                          options={customPagePathOptions}
                          error={touched && error}
                          value={input.value}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  )}
                  {values?.linkType === 'URL' && (
                    <Field name="url">
                      {({ input, meta: { touched, error } }) => (
                        <Input error={touched && error} {...input} />
                      )}
                    </Field>
                  )}
                </FieldWrapper>
              </Box>
            </Modal.Content>
            <Modal.Actions>
              <Button.Cancel
                onClick={() => {
                  form.change(name, initialValues);
                  onClose();
                }}
              >
                {t('Discard')}
              </Button.Cancel>
              <Button
                size="middle"
                style="blue"
                type="submit"
                loading={submitting}
              >
                {t('Save')}
              </Button>
            </Modal.Actions>
          </form>
        )}
      </Form>
    </Modal>
  );
};
