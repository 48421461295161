import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.css';

type Props = {
  paths: {
    text: string;
    url: string;
  }[];
};

export const Breadcrumbs = ({ paths }: Props) => {
  return (
    <div className={styles['c-breadcrumbs']}>
      <ul>
        {paths.map((map, index) => {
          const isLastPath = index === paths.length - 1;
          if (isLastPath) {
            return (
              <li key={index}>
                <p>{map.text}</p>
              </li>
            );
          }

          return (
            <li key={index}>
              <Link to={map.url}>{map.text}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
