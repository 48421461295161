import type { TranslateFuncType } from 'client/components/Translate';
import type { Reservation } from 'shared/models/swagger';

export const getFormattedBookingSource = (
  reservation: Reservation,
  t: TranslateFuncType
): string => {
  let bookingSource = '';
  const {
    agent_name: reservationAgentName,
    booking_source,
    alternate_agent_name,
  } = reservation;
  const isResell = reservation.contract_type === 'RESELL';

  const abbreviatedAgentName =
    alternate_agent_name ||
    reservationAgentName ||
    booking_source?.agent_name ||
    '';

  if (booking_source) {
    if (booking_source.agent_name) {
      if (
        reservationAgentName &&
        booking_source.agent_name !== reservationAgentName
      ) {
        // Prefix booking agent with contract agent name if the two are different.
        // Ex: "H.I.S. Australia (HOPS)"
        bookingSource = `${abbreviatedAgentName} (${booking_source.agent_name})`;
      } else {
        bookingSource = abbreviatedAgentName;
      }

      if (isResell) {
        bookingSource = `${bookingSource} (${t('Resell')})`;
      }
    } else if (booking_source.group_name) {
      bookingSource = booking_source.group_name;
    } else if (booking_source.source_type) {
      bookingSource = t(booking_source.source_type);
    }
  }

  return bookingSource;
};
