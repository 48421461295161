import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { AccordionItem } from 'client/components/Accordion/Accordion';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ManifestProductGroup } from 'shared/models/swagger';

import { ETicketDailyUsesProductTable } from './ETicketDailyUsesProductTable';
import styles from './ETicketDailyUsesProductGroupTableList.module.css';

type Props = {
  items: Record<string, any>[];
  columns: CustomTableColumn[];
};
export const ETicketDailyUsesProductGroupTableList = ({
  items,
  columns,
}: Props) => {
  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);
  const productGroupsForDisplay = useSelector(manifestProductGroupsSelector);
  const productsInGroup = productGroupsForDisplay.reduce(
    (productIds: string[], productGroup: ManifestProductGroup) => {
      if (productGroup.used_for_grouping) {
        return [...productIds, ...(productGroup?.product_ids || [])];
      }

      return productIds;
    },
    []
  );
  const [openGroup, setOpenGroup] = React.useState<boolean[]>(
    productGroupsForDisplay.map(() => true)
  );
  const groupByProductIdItems = _.groupBy(items, (item) => item.productId);

  return (
    <>
      {productGroupsForDisplay.map((productGroup, idx) => {
        const filteredItems = items.filter((item) =>
          (productGroup.product_ids || []).includes(item.productId)
        );

        if (filteredItems.length === 0) {
          return <></>;
        }

        return (
          <>
            <AccordionItem
              key={idx}
              header={productGroup?.key || ''}
              open={openGroup[idx]}
              onButtonClick={() => {
                const newOpenGroup = [...openGroup];
                newOpenGroup[idx] = !openGroup[idx];
                setOpenGroup(newOpenGroup);
              }}
            >
              {() => {
                return (productGroup.product_ids || []).map(
                  (productId, idx) => {
                    const product = productSummaries.find(
                      (product) => product.id === productId
                    );

                    if (!product) {
                      return <></>;
                    }

                    const filterdItems = items.filter(
                      (item) => item.productId === productId
                    );
                    if (filterdItems.length === 0) {
                      return <></>;
                    }

                    return (
                      <ETicketDailyUsesProductTable
                        key={idx}
                        items={filterdItems}
                        columns={columns}
                        productName={
                          product.internal_product_name ??
                          product.product_name ??
                          ''
                        }
                        productId={product.id}
                      />
                    );
                  }
                );
              }}
            </AccordionItem>
          </>
        );
      })}
      {productSummaries
        .filter((product) => {
          if (!productsInGroup.includes(product.id)) {
            return true;
          }

          return false;
        })
        .filter((product) => {
          if (
            items.filter((item) => item.productId === product.id).length > 0
          ) {
            return true;
          }
          return false;
        })
        .map((product, idx) => {
          return (
            <div key={idx} className={clsx(styles['boarder'])}>
              <ETicketDailyUsesProductTable
                key={idx}
                items={groupByProductIdItems[product.id] as any}
                columns={columns}
                productName={
                  product.internal_product_name ?? product.product_name ?? ''
                }
                productId={product.id}
              />
            </div>
          );
        })}
    </>
  );
};
