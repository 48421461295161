import axios from 'axios';
import FileSaver from 'file-saver';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_SURVEY_SUBMISSIONS_REQUEST,
  FETCH_SURVEY_SUBMISSIONS_SUCCESS,
  FETCH_SURVEY_SUBMISSIONS_FAILURE,
  FETCH_SURVEY_SUBMISSION_REQUEST,
  FETCH_SURVEY_SUBMISSION_SUCCESS,
  FETCH_SURVEY_SUBMISSION_FAILURE,
  FETCH_SURVEY_SUBMISSIONS_CSV_REQUEST,
  FETCH_SURVEY_SUBMISSIONS_CSV_FAILURE,
  FETCH_SURVEY_SUBMISSIONS_CSV_SUCCESS,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import {
  DownloadSurveySubmissionCSVRequest,
  ListSurveySubmissionsRequest,
} from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchSurveySubmissionsRequest = () => ({
  type: FETCH_SURVEY_SUBMISSIONS_REQUEST,
});

const fetchSurveySubmissionsSuccess = (response: any) => ({
  type: FETCH_SURVEY_SUBMISSIONS_SUCCESS,
  response,
});

const fetchSurveySubmissionsFailure = (error: string) => ({
  type: FETCH_SURVEY_SUBMISSIONS_FAILURE,
  error,
});

export const fetchSurveySubmissions =
  (req: ListSurveySubmissionsRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveySubmissionsRequest());
    return axios
      .get('/api/surveysubmissions', {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchSurveySubmissionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSurveySubmissionsFailure(err.message));
      });
  };

const fetchSurveySubmissionRequest = () => ({
  type: FETCH_SURVEY_SUBMISSION_REQUEST,
});

const fetchSurveySubmissionSuccess = (response: any) => ({
  type: FETCH_SURVEY_SUBMISSION_SUCCESS,
  response,
});

const fetchSurveySubmissionFailure = (error: string) => ({
  type: FETCH_SURVEY_SUBMISSION_FAILURE,
  error,
});

export const fetchSurveySubmission =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveySubmissionRequest());
    return axios
      .get(`/api/surveysubmissions/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((response) => {
        dispatch(fetchSurveySubmissionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSurveySubmissionFailure(err.message));
      });
  };

const fetchSurveySubmissionsCSVRequest = () => ({
  type: FETCH_SURVEY_SUBMISSIONS_CSV_REQUEST,
});

const fetchSurveySubmissionsCSVSuccess = () => ({
  type: FETCH_SURVEY_SUBMISSIONS_CSV_SUCCESS,
});

const fetchSurveySubmissionsCSVFailure = (error: string) => ({
  type: FETCH_SURVEY_SUBMISSIONS_CSV_FAILURE,
  error,
});

export const fetchSurveySubmissionsCSV =
  (req: DownloadSurveySubmissionCSVRequest) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchSurveySubmissionsCSVRequest());
    axios
      .get(`/api/surveysubmissions/csv`, {
        params: req,
        headers: getHTTPRequestHeaders(getState()),
        responseType: 'blob',
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'survey_results.csv');
        dispatch(fetchSurveySubmissionsCSVSuccess());
      })
      .catch((error) => {
        dispatch(
          fetchSurveySubmissionsCSVFailure(
            error.response.data.message || error.response.statusText
          )
        );
      });
  };
