import * as Swagger from 'shared/models/swagger';

export interface AnalyticsCustomerAutoTag {
  tagName: string;
  tagColor: string;
  conditionGroups: AnalyticsCustomerAutoTagConditionGroup[];
}

export interface AnalyticsCustomerAutoTagConditionGroup {
  classification: 'ANY' | 'BOOKED' | 'NOT_BOOKED';
  gender: 'ANY' | 'MALE' | 'FEMALE' | 'NO_RESPONSE';
  ageGroup:
    | 'ANY'
    | 'UNDER_18'
    | '18_TO_24'
    | '25_TO_34'
    | '35_TO_44'
    | '45_TO_54'
    | '55_TO_64'
    | '65_PLUS'
    | 'CUSTOM';
  ageRange?: {
    lowerBound: string;
    upperBound: string;
  };
  device: 'ANY' | 'DESKTOP' | 'MOBILE' | 'TABLET';
  referralPaths: string[];
  dateRangePreset:
    | ''
    | 'LAST_24_HOURS'
    | 'LAST_1_WEEK'
    | 'LAST_4_WEEKS'
    | 'CUSTOM';
  dateRange?: {
    startDate: string;
    endDate: string;
  };
  dimensionType:
    | 'NONE'
    | 'PAGE_VIEW_COUNT_GTE'
    | 'PAGE_VIEW_COUNT_LT'
    | 'PAGE_VIEW_COUNT_BETWEEN'
    | 'SESSION_COUNT_GTE'
    | 'SESSION_COUNT_LT'
    | 'SESSION_COUNT_BETWEEN'
    | 'TOTAL_TIME_SECONDS_GTE'
    | 'TOTAL_TIME_SECONDS_LT'
    | 'TOTAL_TIME_SECONDS_BETWEEN';
  dimensionComparisonValue?: {
    lowerBound: string;
    upperBound: string;
  };
}

type SwaggerAutoTag = Exclude<
  Swagger.JourneyAnalyticsSettings['autotags'],
  undefined
>[0];

export const convertSwaggerTagToFormValues = (
  tag: SwaggerAutoTag
): AnalyticsCustomerAutoTag => {
  return {
    tagName: tag.tag_name ?? '',
    tagColor: tag.tag_color ?? '',
    conditionGroups:
      tag.condition_groups?.map((conditionGroup) => ({
        classification: conditionGroup.classification ?? 'ANY',
        gender: conditionGroup.gender ?? 'ANY',
        ageGroup: conditionGroup.age_group ?? 'ANY',
        ageRange: conditionGroup.age_range
          ? {
              lowerBound: conditionGroup.age_range.lower_bound
                ? `${conditionGroup.age_range.lower_bound}`
                : '',
              upperBound: conditionGroup.age_range.upper_bound
                ? `${conditionGroup.age_range.upper_bound}`
                : '',
            }
          : undefined,
        device: conditionGroup.device ?? 'ANY',
        referralPaths: conditionGroup.referral_paths ?? [],
        dateRangePreset: conditionGroup.date_range_preset ?? '',
        dateRange:
          conditionGroup.date_range_preset === 'CUSTOM' &&
          conditionGroup.date_range
            ? {
                startDate: conditionGroup.date_range.start_date ?? '',
                endDate: conditionGroup.date_range.end_date ?? '',
              }
            : undefined,
        dimensionType: conditionGroup.dimension_type || 'NONE',
        dimensionComparisonValue: conditionGroup.dimension_comparison_value
          ? {
              lowerBound: conditionGroup.dimension_comparison_value.lower_bound
                ? `${conditionGroup.dimension_comparison_value.lower_bound}`
                : '',

              upperBound: conditionGroup.dimension_comparison_value.upper_bound
                ? `${conditionGroup.dimension_comparison_value.upper_bound}`
                : '',
            }
          : undefined,
      })) ?? [],
  };
};

export const convertFormValuesToSwaggerTag = (
  tag: AnalyticsCustomerAutoTag
): SwaggerAutoTag => {
  return {
    tag_name: tag.tagName,
    tag_color: tag.tagColor,
    condition_groups: tag.conditionGroups.map((conditionGroup) => ({
      classification: conditionGroup.classification,
      gender: conditionGroup.gender,
      age_group: conditionGroup.ageGroup,
      age_range: conditionGroup.ageRange
        ? {
            lower_bound: conditionGroup.ageRange.lowerBound
              ? parseInt(conditionGroup.ageRange.lowerBound)
              : undefined,
            upper_bound: conditionGroup.ageRange.upperBound
              ? parseInt(conditionGroup.ageRange.upperBound)
              : undefined,
          }
        : undefined,
      device: conditionGroup.device,
      referral_paths: conditionGroup.referralPaths,
      date_range_preset: conditionGroup.dateRangePreset,
      date_range: conditionGroup.dateRange
        ? {
            start_date: conditionGroup.dateRange.startDate,
            end_date: conditionGroup.dateRange.endDate,
          }
        : undefined,
      dimension_type:
        conditionGroup.dimensionType === 'NONE'
          ? ''
          : conditionGroup.dimensionType,
      dimension_comparison_value: conditionGroup.dimensionComparisonValue
        ? {
            lower_bound: conditionGroup.dimensionComparisonValue.lowerBound
              ? parseInt(conditionGroup.dimensionComparisonValue.lowerBound)
              : undefined,
            upper_bound: conditionGroup.dimensionComparisonValue.upperBound
              ? parseInt(conditionGroup.dimensionComparisonValue.upperBound)
              : undefined,
          }
        : undefined,
    })),
  };
};
