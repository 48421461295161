import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getInstantWinStats,
  downloadInstantWinCsv,
} from 'client/actions/instantWins';
import type { ReduxState } from 'client/reducers';

interface Props {
  instantWinId: string;
}

export const TotalStats = ({ instantWinId }: Props) => {
  const dispatch = useDispatch();

  const instantWinAllStats = useSelector(
    (state: ReduxState) => state.instantWins.allStats
  );

  const instantWinStats = instantWinAllStats[instantWinId] || null;

  React.useEffect(() => {
    if (instantWinStats) {
      return;
    }
    dispatch(getInstantWinStats(instantWinId));
  }, []);

  if (!instantWinStats) {
    return null;
  }

  return (
    <div>
      <a
        onClick={() => {
          dispatch(downloadInstantWinCsv(instantWinId));
        }}
      >
        {instantWinStats?.total?.drawn || '0'} /{' '}
        {instantWinStats?.total?.opend || '0'} /{' '}
        {instantWinStats?.total?.pending || '0'} /{' '}
        {instantWinStats?.total?.total || '0'}
      </a>
    </div>
  );
};
