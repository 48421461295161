import moment from 'moment-timezone';
import { FORM_ERROR } from 'final-form';
import { v4 as uuidv4 } from 'uuid';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import type { TranslateFuncType } from 'client/components/Translate';
import { getFromStartDateToEndDateText } from 'client/libraries/util/getFromStartDateToEndDateText';
import type {
  Organization,
  Guest,
  Product,
  ProductInstance,
} from 'shared/models/swagger';

export type FormValues = {
  unitType?: 'PER_BOOKING' | 'PER_PARTICIPANT';
  capacityPerUnit?: number;
  availabilitySchedules?: AvailabilitySchedule[];
};
export type AvailabilitySchedule = {
  startDateLocal?: string;
  endDateLocal?: string;
  quantity?: number;
  addOns?: {
    productId: string;
    addOnKey: string;
  }[];
  productIds?: string[];
  departureTimeCapacities?: DepartureTimeCapacity[];
  productCapacities?: ProductCapacity[];
};
export type DepartureTimeCapacity = {
  key: string;
  timeLocal: string;
  capacity: number;
  productStartTimes: { productId?: string; startTimeLocal?: string }[];
};
export type ProductCapacity = {
  productId?: string;
  capacity?: number;
};

// Returns capacity when the resource supports ResourceManager
export const getResourceManagerCapacity = (
  activeUserOrganization: Organization | null,
  participationDate: string,
  resourceType: string,
  resourceKey: string
): number | null => {
  if (
    !hasSubscription(activeUserOrganization, 'feature-resource-management') &&
    !hasSubscription(
      activeUserOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    return null;
  }

  if (resourceType !== 'other') {
    return null;
  }

  const resourceSetting = (
    activeUserOrganization?.dispatch_settings?.dispatch_misc_resources || []
  ).find((resource) => {
    if (resource.key === resourceKey) {
      return true;
    }
    return false;
  });

  if (!resourceSetting) {
    return null;
  }

  const participationDateMomemnt = moment(participationDate);

  let quantity: number | null = null;
  (resourceSetting.availability?.schedules || []).forEach((schedule) => {
    if (
      participationDateMomemnt.isSameOrAfter(
        moment.tz(
          schedule.start_date_local,
          activeUserOrganization?.default_timezone ?? 'UTC'
        )
      ) &&
      participationDateMomemnt.isBefore(
        moment
          .tz(
            schedule.end_date_local,
            activeUserOrganization?.default_timezone ?? 'UTC'
          )
          .add(1, 'd')
      )
    )
      if (quantity === null) {
        quantity = schedule.quantity ?? null;
      }
  });

  return quantity;
};

export const getResourceManagerAssignedResources = (
  activeUserOrganization: Organization | null,
  reservations: ManifestReservationShape[]
): { resourceKey: string; resourceType: string; quantity: number }[] | null => {
  if (
    !hasSubscription(activeUserOrganization, 'feature-resource-management') &&
    !hasSubscription(
      activeUserOrganization,
      'feature-vehicle-crew-resource-management'
    )
  ) {
    return null;
  }

  const pairs: {
    resourceKey: string;
    resourceType: string;
    quantity: number;
  }[] = [];

  reservations.forEach((reservation) => {
    (reservation.resource_assignments ?? []).forEach((resourceAssignment) => {
      let resource = pairs.find((pair) => {
        return (
          pair.resourceKey === resourceAssignment.resource_key &&
          pair.resourceType === resourceAssignment.resource_type
        );
      });
      if (!resource) {
        resource = {
          resourceType: resourceAssignment.resource_type ?? '',
          resourceKey: resourceAssignment.resource_key ?? '',
          quantity: resourceAssignment.quantity ?? 0,
        };
        pairs.push(resource);
      } else {
        resource.quantity += resourceAssignment.quantity ?? 0;
      }
    });
  });

  return pairs;
};

export const getNotEnoughAvailableResources = (
  guests: Guest[],
  product: Product,
  productInstance: ProductInstance | null
): string[] => {
  const countableUnits: { [index: string]: boolean } = {};
  (product?.allotment_settings?.inventory_consumption_rules ?? []).forEach(
    (rule: any) => {
      if (!rule.should_not_count_inventory && rule.unit) {
        countableUnits[rule.unit] = true;
      }
    }
  );

  let guestCount = 0;
  guests.forEach((guest) => {
    if (countableUnits[guest.guest_type_key]) {
      guestCount += 1;
    }
  });

  const notEnoughAvailableResources: string[] = [];
  productInstance?.resource_availability?.product_resources?.forEach(
    (resource: any) => {
      const availableQuantity = resource.available_quantity ?? 0;
      const capacityPerUnit = resource.capacity_per_unit ?? 1;
      if (resource.unit_type === 'PER_BOOKING') {
        if (availableQuantity < 1) {
          notEnoughAvailableResources.push(resource.resource_key);
        }
      } else {
        if (availableQuantity * capacityPerUnit < guestCount) {
          notEnoughAvailableResources.push(resource.resource_key);
        }
      }
    }
  );

  return notEnoughAvailableResources;
};

export const getInitialAddOnResources = (
  productInstance: ProductInstance | null
): [{ [key: string]: number }, { [key: string]: number }] => {
  const newAvailablePerParticipantAddOns: { [key: string]: number } = {};
  const newAvailablePerBookingAddOns: { [key: string]: number } = {};
  const availableAddOns = productInstance?.resource_availability?.add_ons ?? [];
  availableAddOns.forEach((addOn: any) => {
    (addOn.resources ?? []).forEach((resource: any) => {
      const availableQuantity = resource.available_quantity ?? 0;
      if (resource.unit_type === 'PER_PARTICIPANT') {
        if (
          newAvailablePerParticipantAddOns[addOn.add_on_key] === undefined ||
          newAvailablePerParticipantAddOns[addOn.add_on_key] > availableQuantity
        ) {
          newAvailablePerParticipantAddOns[addOn.add_on_key] =
            availableQuantity;
        }
      } else {
        if (
          newAvailablePerBookingAddOns[addOn.add_on_key] === undefined ||
          newAvailablePerBookingAddOns[addOn.add_on_key] > availableQuantity
        ) {
          newAvailablePerBookingAddOns[addOn.add_on_key] = availableQuantity;
        }
      }
    });
  });

  return [newAvailablePerBookingAddOns, newAvailablePerParticipantAddOns];
};

export const getResourceTypeText = (
  type: string,
  t: TranslateFuncType
): string => {
  switch (type) {
    case 'RESOURCE_TYPE_OTHER':
      return t('Other');
    case 'RESOURCE_TYPE_VEHICLE':
      return t('Vehicle');
    case 'RESOURCE_TYPE_CREW':
      return t('Crew');
    case 'RESOURCE_TYPE_GUIDE':
      return t('Guide');
    default:
      return '';
  }
};

export const getDefaultDepartureTimeCapacity = (): DepartureTimeCapacity => {
  return {
    key: uuidv4(),
    timeLocal: '00:00',
    capacity: 0,
    productStartTimes: [],
  };
};

export const validateRange = (values: FormValues, t: TranslateFuncType) => {
  for (let i = 0; i < (values.availabilitySchedules || []).length; i++) {
    const availabilitySchedule = values.availabilitySchedules?.[i];

    if (
      availabilitySchedule?.startDateLocal &&
      availabilitySchedule?.endDateLocal &&
      moment(availabilitySchedule.startDateLocal).isAfter(
        availabilitySchedule.endDateLocal
      )
    ) {
      return {
        [FORM_ERROR]: t(
          'Start date must be before end date for each availability schedule'
        ),
      };
    }

    for (let j = i + 1; j < (values.availabilitySchedules || []).length; j++) {
      const availabilitySchedule2 = values.availabilitySchedules?.[j];

      if (
        availabilitySchedule?.startDateLocal &&
        availabilitySchedule?.endDateLocal &&
        availabilitySchedule2?.startDateLocal &&
        availabilitySchedule2?.endDateLocal &&
        !(
          moment(availabilitySchedule.startDateLocal).isAfter(
            moment(availabilitySchedule2.endDateLocal)
          ) ||
          moment(availabilitySchedule.endDateLocal).isBefore(
            moment(availabilitySchedule2.startDateLocal)
          )
        )
      ) {
        return {
          [FORM_ERROR]: t(
            'Date ranges are overlapping. Please edit date range to avoid overlaps.'
          ),
        };
      }
    }
  }

  return null;
};

export const getScheduleText = (
  availabilitySchedule: AvailabilitySchedule,
  t: TranslateFuncType
) => {
  return getFromStartDateToEndDateText(
    availabilitySchedule.startDateLocal ?? '',
    availabilitySchedule.endDateLocal ?? '',
    t
  );
};
