import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Loading } from 'client/components/v3/Common/Loading';

import { ChangeLabel } from '../../../pages/JourneyAnalyticsDashboard/ChangeLabel';

import styles from './QuantityGadget.module.css';

interface Props {
  header: string;
  unit: string;
  amount: number;
  changeAmount?: number | null;
  tooltipText?: string;
  loading?: boolean;
  numberFormatter?: (amount: number) => string;
}

export const QuantityGadget = ({
  header,
  unit,
  amount,
  changeAmount,
  tooltipText,
  loading,
  numberFormatter,
}: Props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{header}</div>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles['body']}>
          <div className={styles['body-inner']}>
            {loading ? (
              <Loading size="md" />
            ) : (
              <>
                <div className={styles['amount-wrapper']}>
                  <div className={styles['amount']}>
                    <div className={styles['amount-text']}>
                      {numberFormatter
                        ? numberFormatter(amount)
                        : amount.toLocaleString()}
                    </div>
                    {unit && (
                      <div className={styles['amount-unit']}>{unit}</div>
                    )}
                  </div>
                  {changeAmount != null && (
                    <div className={styles['previous']}>
                      <div className={styles['previous-text']}>
                        {numberFormatter
                          ? numberFormatter(amount - changeAmount)
                          : (amount - changeAmount).toLocaleString()}
                      </div>
                      {unit && (
                        <div className={styles['previous-unit']}>{unit}</div>
                      )}
                    </div>
                  )}
                </div>
                {changeAmount != null && (
                  <ChangeLabel amountChange={changeAmount} unit={unit} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
