import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BaseGadget } from 'client/components/v3/DashboardGadgets/BaseGadget';
import { NpsSurveyReportDataSet } from 'shared/models/swagger';

import styles from './Chart.module.css';
import { Props, TooltipProps } from './type';

const CustomTooltip = ({ active, payload, label, t }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <>
        <div className={styles['c-tooltip']}>
          <div className={styles['c-tooltip__header']}>
            <p className={styles['c-tooltip__header__ttl']}>{label}</p>
          </div>
          <div className={styles['c-tooltip__body']}>
            {payload.map((payload, index) => (
              <p className={styles['c-tooltip__body__item']} key={index}>{`${t(
                payload.name
              )}: ${payload.value.toFixed(2)}%`}</p>
            ))}
          </div>
        </div>
      </>
    );
  }

  return null;
};

export const NPSRatioChart = ({
  baseDataSet,
  comparisonDataSet,
  loading = false,
}: Props) => {
  const { t } = useTranslation();

  const renderLegendText = (value: string) => {
    return <span style={{ color: '#71717A' }}>{t(value)}</span>;
  };

  const calculateNPSRatio = (dataSet: NpsSurveyReportDataSet) => {
    if (
      !dataSet ||
      !dataSet.items_by_date ||
      dataSet.items_by_date.length === 0
    ) {
      return { Detractor: 0, Passive: 0, Promoter: 0 };
    }

    const totals = {
      Detractor: 0,
      Passive: 0,
      Promoter: 0,
      Total: 0,
    };

    dataSet.items_by_date.forEach((item) => {
      item.results?.forEach((result) => {
        switch (result.type) {
          case 'NPS_SURVEY_RESULT_TYPE_DETRACTOR':
            totals.Detractor++;
            break;
          case 'NPS_SURVEY_RESULT_TYPE_PASSIVE':
            totals.Passive++;
            break;
          case 'NPS_SURVEY_RESULT_TYPE_PROMOTER':
            totals.Promoter++;
            break;
        }
        totals.Total++;
      });
    });

    return {
      Detractor: (totals.Detractor / totals.Total) * 100,
      Passive: (totals.Passive / totals.Total) * 100,
      Promoter: (totals.Promoter / totals.Total) * 100,
    };
  };

  const chartData = [
    {
      name: t('Base Period', { context: 'nps' }),
      ...calculateNPSRatio(baseDataSet),
    },
  ];
  if (comparisonDataSet) {
    chartData.push({
      name: t('Comparison Period'),
      ...calculateNPSRatio(comparisonDataSet),
    });
  }

  return (
    <BaseGadget header={t('NPS Ratio')} loading={loading}>
      <ResponsiveContainer width="100%" height={comparisonDataSet ? 200 : 150}>
        <BarChart
          layout="vertical"
          data={chartData}
          margin={{
            top: 20,
            right: 10,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            domain={[0, 100]}
            unit="%"
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => {
              return value.toFixed(0);
            }}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            width={80}
          />
          <Tooltip
            content={<CustomTooltip t={t} />}
            cursor={{ fill: 'transparent' }}
          />
          <Legend
            align="center"
            iconType="square"
            formatter={renderLegendText}
          />
          <Bar dataKey="Detractor" stackId="a" fill="#EC4899" barSize={30} />
          <Bar dataKey="Passive" stackId="a" fill="#93C5FD" barSize={30} />
          <Bar dataKey="Promoter" stackId="a" fill="#3B82F6" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </BaseGadget>
  );
};
