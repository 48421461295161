import React from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import type { GraphDataItem } from 'client/libraries/util/accessReportSettings';
import { colors } from 'client/libraries/util/colors';

type Props = {
  data: GraphDataItem[];
  barDataKeys: string[];
  names: Record<string, string>;
};
export const ChartPane = ({ data, barDataKeys, names }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          label={{
            value: names[barDataKeys[0]],
            position: 'left',
            angle: -90,
          }}
          allowDecimals={false}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickFormatter={(value) => {
            return value;
          }}
          stroke={'red'}
          label={{
            value: names['reservationCount'],
            position: 'right',
            angle: 90,
          }}
          allowDecimals={false}
        />
        <Tooltip
          formatter={(value: any) => {
            return value;
          }}
        />
        <Legend />
        {barDataKeys.map((barDataKey, idx) => {
          return (
            <Bar
              key={idx}
              dataKey={barDataKey}
              fill={colors[idx]}
              name={names[barDataKey]}
            />
          );
        })}
        <Line
          dataKey={'reservationCount'}
          yAxisId="right"
          stroke={'red'}
          name={names['reservationCount']}
          {...{ connectNull: true }}
          activeDot={{
            r: 8,
          }}
          type="monotone"
        />
        {barDataKeys.length === 2 && (
          <Line
            dataKey={'compareReservationCount'}
            yAxisId="right"
            stroke={'red'}
            strokeDasharray={'5 5'}
            name={names['compareReservationCount']}
            {...{ connectNull: true }}
            activeDot={{
              r: 8,
            }}
            type="monotone"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
