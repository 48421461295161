import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { allDispatchMiscResourcesSelector } from 'client/reducers/dispatchSettings';
import type { TranslateFuncType } from 'client/components/Translate';
import { Select } from 'client/components/Form';
import { summariesSelector } from 'client/reducers/products';
import type { DispatchResource } from 'shared/models/swagger';

const getScheduleSelectOptions = (
  resource: DispatchResource | null,
  t: TranslateFuncType
) => {
  const options: { value: string; text: string }[] = [];
  (resource?.availability?.schedules ?? []).forEach((schedule, idx) => {
    options.push({
      value: String(idx),
      text: getOptionText(
        schedule?.start_date_local ?? null,
        schedule?.end_date_local ?? null,
        t
      ),
    });
  });

  return options;
};

const getOptionText = (
  startDateLocal: string | null,
  endDateLocal: string | null,
  t: TranslateFuncType
) => {
  if (startDateLocal && endDateLocal) {
    return `${startDateLocal} ~ ${endDateLocal}`;
  }
  if (startDateLocal) {
    return `${startDateLocal} ~ ${t('Unknown')}`;
  }
  if (endDateLocal) {
    return `${t('Unknown')} ~ ${endDateLocal}`;
  }

  return '~';
};

export const MiscResourceAvailabilityDisplayBox = ({
  resourceKey,
}: {
  resourceKey: string;
}) => {
  const { t } = useTranslation();
  const resources = useSelector(allDispatchMiscResourcesSelector);
  const products = useSelector(summariesSelector);

  const [scheduleIdx, setActiveScheduleIndex] = React.useState<string>('0');
  const [scheduleSettings, setScheduleSettings] = React.useState<
    { key: string; value: string }[]
  >([]);

  const resource = resources.find((r) => r.key === resourceKey);

  React.useEffect(() => {
    const schedule = resource?.availability?.schedules?.[Number(scheduleIdx)];
    if (schedule) {
      const settings: {
        key: string;
        value: string;
      }[] = [];

      settings.push({
        key: `[${t('Quantity')}]`,
        value: String(schedule.quantity),
      });

      if (schedule?.product_ids?.length) {
        const productIds = schedule.product_ids.map((id) => {
          const product = products.find((p) => p.id === id);
          return getDisplayProductName(product);
        });
        settings.push({
          key: `[${t('Products')}]`,
          value: productIds.join(', '),
        });
      }

      if (schedule?.add_ons?.length) {
        const productAddOns = schedule.add_ons.map((addOn) => {
          const product = products.find((p) => p.id === addOn.product_id);
          return `${getDisplayProductName(product)} - (${addOn.add_on_key})`;
        });
        settings.push({
          key: `[${t('Add-ons')}]`,
          value: productAddOns.join(', '),
        });
      }

      setScheduleSettings(settings);
    }
  }, [scheduleIdx, products, resource, t]);

  const commonSettings: {
    key: string;
    value: string;
  }[] = [];

  if (resource?.availability?.unit_type) {
    commonSettings.push({
      key: `[${t('Type')}]`,
      value:
        resource?.availability?.unit_type === 'PER_BOOKING'
          ? t('Per-booking')
          : t('Per-participant'),
    });
  }

  if (resource?.availability?.unit_type === 'PER_PARTICIPANT') {
    commonSettings.push({
      key: `[${t('Capacity per unit')}]`,
      value: resource?.availability?.capacity_per_unit?.toString() || '0',
    });
  }

  const options = getScheduleSelectOptions(resource ?? null, t);

  return (
    <>
      {resource?.availability && (
        <div>
          <ul>
            {commonSettings.map((f) => (
              <li key={f.key}>{`${f.key}: ${f.value}`}</li>
            ))}
          </ul>
          <Select
            options={options}
            value={scheduleIdx}
            onChange={(_, { value }) => setActiveScheduleIndex(value)}
          />
          <ul>
            {scheduleSettings.map((f) => (
              <li key={f.key}>{`${f.key}: ${f.value}`}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
