import { SurveyTemplate } from 'shared/models/swagger';

import { Answer } from './Charts/type';

export const replaceJPYWithSymbol = (s: string): string => {
  if (s.includes('JPY')) {
    s = `${s.replace(/JPY\s*/, '')}円`;
    if (s === '0円') {
      return '無料';
    }
  }

  return s;
};

export const numberWithCommas = (x: number, useFixedDecimal = false) => {
  if (useFixedDecimal) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getAnswersFromNPSSurveySecondQuestion = (
  surveyTemplate: SurveyTemplate
): Answer[] => {
  if (
    surveyTemplate.pages &&
    surveyTemplate.pages[0] &&
    surveyTemplate.pages[0].questions &&
    surveyTemplate.pages[0].questions[1] &&
    surveyTemplate.pages[0].questions[1].response_options
  ) {
    return surveyTemplate.pages[0].questions[1].response_options;
  }
  return [];
};
