import { combineReducers } from 'redux';

import { SET_NEWSLETTERS_SEARCH_FILTERS } from 'client/constants/ActionTypes';

export interface NewslettersSearchFilters {
  sendDateFrom: string;
  sendDateTo: string;
  segments: string[];
}

const defaultFilters: NewslettersSearchFilters = {
  sendDateFrom: '',
  sendDateTo: '',
  segments: [],
};

const filters = (
  state = defaultFilters,
  action: any
): NewslettersSearchFilters => {
  switch (action.type) {
    case SET_NEWSLETTERS_SEARCH_FILTERS:
      return action.filters;

    default:
      return state;
  }
};

export const newslettersSearch = combineReducers({
  filters,
});
