import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { fetchManifest } from 'client/actions/manifests';
import { getReactTableColumns } from 'client/pages/v3/Reservation/ReservationCreate/util';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import type { Column } from 'client/components/ReservationListModal/util';
import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';
import { CustomTable } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/CustomTable';
import type { CustomTableColumn } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/CustomTable';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  productIds: string[];
  rootProductInstanceId: string;
  date: string;
  onClose: () => void;
  open: boolean;
};

export const ReservationListModal = ({
  productIds,
  rootProductInstanceId,
  date,
  onClose,
  open,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservations = useSelector((state: ReduxState) =>
    state.manifests.reservations.map((r) =>
      toReservationSummaryShape(r, locale, t)
    )
  );
  const productInstancesById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );

  const toCustomTableColumns = (columns: Column[]): CustomTableColumn[] => {
    return columns.map((c) => {
      const newColumn: CustomTableColumn = {
        Header: c.Header,
        accessor: c.accessor,
        Cell: c.Cell,
        th: c.th,
        width: c.width,
      };
      return newColumn;
    });
  };

  const [columns, setColumns] = useState<CustomTableColumn[]>(
    toCustomTableColumns(getReactTableColumns(t, locale, true))
  );
  useEffect(() => {
    setColumns(toCustomTableColumns(getReactTableColumns(t, locale, true)));
  }, [t, locale]);
  useEffect(() => {
    if (date) {
      dispatch(
        fetchManifest({
          date: date,
          product_ids: productIds,
        })
      );
    }
  }, [date, productIds]);
  let tableRows = [];

  if (rootProductInstanceId) {
    // If rootProductInstanceId prop is passed, include:
    // 1) all reservations that have exactly that product instance ID
    // 2) all reservations for any product instance that is a child instance of that product instance ID
    // 3) all reservations for any package that has a component product instance the root product instance ID
    tableRows = reservations.filter((reservation) => {
      return (
        // 1) all reservations that have exactly that product instance ID
        rootProductInstanceId === reservation.product_instance_id || // 2) all reservations for any product instance that is a child instance of that product instance ID
        (productInstancesById &&
          rootProductInstanceId ===
            productInstancesById[reservation.product_instance_id]
              ?.shared_allotment_references?.parent_product_instance_id) || // 3) all reservations for any package that has a component product instance the root product instance ID
        reservation.package_component_reservation_summaries?.some(
          (packageComponent) =>
            rootProductInstanceId === packageComponent.product_instance_id
        )
      );
    });
  } else {
    tableRows = reservations;
  }

  let totalPaxCount = 0;

  for (const reservation of tableRows) {
    totalPaxCount += reservation.guest_count;
  }

  const rootProductInstance = productInstancesById?.[rootProductInstanceId];
  const formattedDateTime = rootProductInstance
    ? moment
        .parseZone(rootProductInstance.start_date_time_local)
        .locale(locale)
        .format('lll')
    : moment(date, 'YYYY-MM-DD').locale(locale).format('ll');
  return (
    <Modal
      title={t('Reservation List')}
      subtitle={`${t('Date & Start time')}: ${formattedDateTime}`}
      open={open}
      onClose={onClose}
      style={{ width: '90vw' }}
      rightActionChildren={
        <Button text={t('Cancel')} size="md" color="white" onClick={onClose} />
      }
      insertAtRoot={true}
    >
      <div className={styles['p-reservationsCreateList']}>
        {totalPaxCount ? (
          <div className={styles['p-reservationsCreateList__total']}>
            <i className="c-icon-outline-users-users-02"></i>
            <p>
              {t('Total Pax: {{paxCount}}', {
                paxCount: totalPaxCount,
              })}
            </p>
          </div>
        ) : null}
        <div className={styles['p-reservationsCreateList__table']}>
          <CustomTable
            items={tableRows}
            columns={columns}
            usePaging={false}
            useScrollButton={true}
          />
        </div>
      </div>
    </Modal>
  );
};
