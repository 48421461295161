import moment from 'moment-timezone';

export const applyRelativeTimeOffset = (
  startTime: string,
  relativeTime: string
): string => {
  return moment
    .utc(startTime, 'H:mm')
    .add(moment.duration(relativeTime))
    .format('H:mm');
};
