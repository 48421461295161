import { Tooltip } from 'client/components/v3/Common/Tooltip';

import styles from './Gadget.module.css';

type Props = {
  title: string;
  tooltipText?: string;
  positionTooltipToTheLeft?: boolean;
  children: JSX.Element;
};

export const Gadget = ({
  title,
  tooltipText,
  positionTooltipToTheLeft,
  children,
}: Props) => {
  return (
    <div className={styles['c-gadget']}>
      <div className={styles['c-gadget__header']}>
        <div className={styles['c-gadget__header__title']}>
          <p>{title}</p>
        </div>
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            positionLeftOfTriggeringElement={positionTooltipToTheLeft}
          >
            <i className="c-icon-outline-general-info-circle"></i>
          </Tooltip>
        )}
      </div>
      <div className={styles['c-gadget__body']}>{children}</div>
    </div>
  );
};
