import { ThunkDispatch } from 'redux-thunk';

import { updateActiveUserOrganization } from 'client/actions/organizations';
import type { DigitalGuidanceSettings$Patch } from 'shared/models/swagger';

export const updateDigitalGuidanceSettings =
  (patch: DigitalGuidanceSettings$Patch) =>
  (dispatch: ThunkDispatch<any, any, any>) => {
    return dispatch(
      updateActiveUserOrganization({
        digital_guidance_settings: patch,
      })
    );
  };
