import * as React from 'react';
import { useSelector } from 'react-redux';

import 'swagger-ui/dist/swagger-ui.css';
import type { ReduxState } from 'client/reducers';
import { getIDToken } from 'client/libraries/cognito';

export const SwaggerUI = () => {
  const bearerToken = useSelector((state: ReduxState) => getIDToken(state));

  React.useEffect(() => {
    // Swagger UI's uncompressed package+dependencies size is huge: ~700KB
    // Before dynamic import:
    //  File sizes after gzip:
    //
    //    1.2 MB (+387.32 KB)   build/static/js/main.43e3004e.js
    //    32.21 KB (+21.51 KB)  build/static/css/main.989639ba.css
    // After dynamic import => Webpack code splitting:
    //  File sizes after gzip:
    //
    //    828.91 KB (-397.39 KB)  build/static/js/main.9317acb7.js
    //    396.23 KB               build/static/js/0.773efd0a.chunk.js
    //    32.21 KB                build/static/css/main.989639ba.css
    import('swagger-ui').then(({ default: swaggerUI }) => {
      const ui = swaggerUI({
        dom_id: '#swaggerContainer',
        url: '/docs/api',
        presets: [(swaggerUI as any).presets.apis],
        requestInterceptor: (req) => {
          if (req.loadSpec) {
            req.headers.Authorization = `Bearer ${bearerToken}`;
          }

          return req;
        },
      });
      // Set default auth by issuing an action on the swagger UI redux store.
      (ui as any).getStore().dispatch({
        type: 'authorize',
        payload: {
          BearerAuth: {
            schema: {
              type: 'apiKey',
              name: 'Authorization',
              in: 'header',
            },
            value: `Bearer ${bearerToken}`,
          },
        },
      });
    });
  }, []);

  return <div id="swaggerContainer" />;
};
