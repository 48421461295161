import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import querystring from 'query-string';

import type { LanguageISO } from 'shared/libraries/i18n';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ReduxState } from 'client/reducers';
import { productOptionsSelector } from 'client/reducers/products';
import { TruncatedTextWithSeeMoreButton } from 'client/components/TruncatedTextWithSeeMoreButton/TruncatedTextWithSeeMoreButton';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import { ReservationDataExportOrder } from 'shared/models/swagger';

import {
  getReservationCSVColumns,
  piiItems,
} from '../components/DownloadReservationsCSVModal/util';

import styles from './DownloadListTab.module.css';

const getExportOrderBy = (
  order: ReservationDataExportOrder
): SearchReservationsRequest['orderBy'] => {
  switch (order.order_by) {
    case 'last_updated_date_time_utc':
      return order.is_ascending ? 'last_updated_asc' : 'last_updated_desc';
    case 'booked_date_time_utc':
      return order.is_ascending ? 'booked_asc' : 'booked_desc';
    case 'start_date_time_utc':
      return order.is_ascending ? 'participation_asc' : 'participation_desc';
    case 'family_name':
      return 'family_name';
  }

  return 'last_updated_desc';
};

const convertReservationDataExportOrderToReservationSearchRequest = (
  order: ReservationDataExportOrder,
  timezone: string,
  locale: LanguageISO
): SearchReservationsRequest => {
  const getDateTime = (utcTime: string) =>
    moment.tz(utcTime, timezone).locale(locale).format('ll');
  const getDateTimeSubtract1Day = (utcTime: string) =>
    moment.tz(utcTime, timezone).locale(locale).subtract(1).format('ll');
  return {
    agentReference: order.agent_reference ?? '',
    agentIds: order.agent_ids ?? [],
    supplierReference: order.supplier_reference ?? '',
    id: order.reservation_id ?? '',
    statuses: order.statuses ?? [],
    customerGivenName: order.given_name ?? '',
    customerFamilyName: order.family_name ?? '',
    customerPhone: order.phone ?? '',
    customerEmail: order.email ?? '',
    bookingSourceTypes: order.booking_sources ?? [],
    groupIds: order.group_ids ?? [],
    supplierIds: order.supplier_ids ?? [],
    productIds: order.product_ids ?? [],
    bookedDateFrom: order.booked_date_time_utc_from
      ? getDateTime(order.booked_date_time_utc_from)
      : '',
    bookedDateTo: order.booked_date_time_utc_to
      ? getDateTimeSubtract1Day(order.booked_date_time_utc_to)
      : '',
    participationDateFrom: order.start_date_local_from
      ? getDateTime(order.start_date_local_from)
      : '',
    participationDateTo: order.start_date_local_to
      ? getDateTimeSubtract1Day(order.start_date_local_to)
      : '',
    lastUpdatedDateFrom: order.last_updated_date_time_utc_from
      ? getDateTime(order.last_updated_date_time_utc_from)
      : '',
    lastUpdatedDateTo: order.last_updated_date_time_utc_to
      ? getDateTimeSubtract1Day(order.last_updated_date_time_utc_to)
      : '',
    orderBy: getExportOrderBy(order),
    supplierOrAgentReference: order.supplier_or_agent_reference ?? '',
    reservationLanguages: order.reservation_languages ?? [],
    mostRecentEmailBounced: order.most_recent_email_bounced?.value ?? false,
    pickupCheckinLocationName: order.pickup_checkin_location_name ?? '',
    waiverCompletionStatuses: order.waiver_completion_statuses ?? [],
    checkinStatuses: order.checkin_statuses ?? [],
    presetKey: '',
    dateFilterPreset: null,
    annualPassOnly: false,
    expirationPresetKey: '',
    expirationDateFrom: '',
    expirationDateTo: '',
    expirationDateFilterPreset: null,
    automaticContinuingStatus: null,
  };
};

export const useDownloadListColumns = (): CustomTableColumn[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const allColumns = getReservationCSVColumns(t);
  return [
    {
      Header: t('Downloads'),
      width: 'short',
      th: true,
      Cell: (cellInfo) => (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_EXPORTED' && (
            <a
              onClick={() => {
                cellInfo?.original?.onDownload?.();
              }}
            >
              {t('Download')}
            </a>
          )}
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_PENDING' && <>{t('In progress')}</>}
          {cellInfo.original?.data_export_status ===
            'DATA_EXPORT_STATUS_DELETED' && <>{t('Deleted')}</>}
        </div>
      ),
    },
    {
      Header: t('Created Date'),
      width: 'middle',
      accessor: (row) =>
        moment
          .tz(row.created_date_time_utc, row.timezone ?? 'UTC')
          .locale(locale)
          .format('lll'),
    },
    {
      Header: t('Account Name'),
      width: 'middle',
      accessor: (row) => {
        return getExportRequestUserName(row);
      },
    },
    {
      Header: t('Search Condition'),
      width: 'long',
      Cell: (cellInfo) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          <ReservationSearchQueryDisplayBox
            inline
            searchFilters={convertReservationDataExportOrderToReservationSearchRequest(
              cellInfo.original,
              cellInfo.original.timezone,
              locale
            )}
          />
        </TruncatedTextWithSeeMoreButton>
      ),
    },
    {
      Header: t('Download Items'),
      width: 'long',
      Cell: (cellInfo) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          {(cellInfo?.original?.columns || []).map(
            (column: any, idx: number) => {
              const columnInfo = allColumns.find(
                (c) => c.id.toUpperCase() === column.toUpperCase()
              );

              if (!columnInfo) {
                return null;
              }

              if (
                piiItems
                  .map((item) => item.toUpperCase())
                  .includes(columnInfo.id.toUpperCase())
              ) {
                return (
                  <span
                    style={{
                      color: 'red',
                    }}
                    key={idx}
                    className={clsx(styles['download-item'])}
                  >
                    {columnInfo.Header}{' '}
                  </span>
                );
              }

              return (
                <span key={idx} className={clsx(styles['download-item'])}>
                  {columnInfo.Header}{' '}
                </span>
              );
            }
          )}
        </TruncatedTextWithSeeMoreButton>
      ),
    },
    {
      Header: t('Log'),
      width: 'middle',
      Cell: (cellInfo: any) => (
        <TruncatedTextWithSeeMoreButton key={cellInfo.original.index}>
          {(cellInfo?.original?.data_download_logs || []).map((log: any) => (
            <>
              <div>
                {moment
                  .tz(log.date_time_utc, cellInfo.original?.timezone ?? 'UTC')
                  .locale(locale)
                  .format('lll')}
              </div>
              <div>{getDownloadUserName(log)}</div>
            </>
          ))}
        </TruncatedTextWithSeeMoreButton>
      ),
    },
  ];
};

export const getDownloadUserName = (log: any) => {
  if (log?.name) {
    return log?.name;
  }

  if (log?.user_info?.login_account?.role === 'nutmeg_admin') {
    return log?.user_info?.login_account?.name;
  }

  return log?.user_info?.account?.name;
};

export const getExportRequestUserName = (reservationDataExportOrder: any) => {
  if (reservationDataExportOrder?.name) {
    return reservationDataExportOrder?.name;
  }

  if (
    reservationDataExportOrder?.action_source?.user_info?.login_account
      ?.role === 'nutmeg_admin'
  ) {
    return reservationDataExportOrder?.action_source?.user_info?.login_account
      ?.name;
  }

  return reservationDataExportOrder?.action_source?.user_info?.account?.name;
};

export const convertFilterToSearchCondition = (
  condition: Record<string, string>
) => {
  const { t } = useTranslation();
  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const parsed = querystring.parse(condition?.filter ?? '');
  const filters: {
    typeText: string;
    filterValue: string;
  }[] = [];

  if (parsed.product_id) {
    let productIds = [];

    if (typeof parsed.product_id === 'string') {
      productIds = [parsed.product_id];
    } else if (Array.isArray(parsed.product_id)) {
      productIds = parsed.product_id;
    }

    filters.push({
      typeText: t('Products'),
      filterValue: productIds
        .map(
          (productId: any) =>
            productOptions.find((option) => option.value === productId)?.text
        )
        .filter((t: any) => Boolean(t))
        .join(','),
    });
  }

  if (parsed.agent_reference) {
    filters.push({
      typeText: t('Application Number'),
      filterValue: String(parsed?.agent_reference) ?? '',
    });
  }

  if (parsed.supplier_reference) {
    filters.push({
      typeText: t('Confirmation Number'),
      filterValue: String(parsed?.supplier_reference) ?? '',
    });
  }

  if (parsed.id) {
    filters.push({
      typeText: t('#'),
      filterValue: String(parsed?.id) ?? '',
    });
  }

  if (condition.start_date_local_from || condition.start_date_local_to) {
    filters.push({
      typeText: t('Participation Date'),
      filterValue: `${condition.start_date_local_from ?? ''} ~ ${
        condition.start_date_local_to ?? ''
      }`,
    });
  }

  if (
    condition.booked_date_time_utc_from ||
    condition.booked_date_time_utc_to
  ) {
    let start = '';

    if (condition.booked_date_time_utc_from) {
      start = moment
        .tz(condition.booked_date_time_utc_from, condition.timezone ?? 'UTC')
        .format('YYYY-MM-DD');
    }

    let end = '';

    if (condition.booked_date_time_utc_to) {
      end = moment
        .tz(condition.booked_date_time_utc_to, condition.timezone ?? 'UTC')
        .format('YYYY-MM-DD');
    }

    filters.push({
      typeText: t('Booked Date'),
      filterValue: `${start} ~ ${end}`,
    });
  }

  if (
    condition.last_updated_date_time_utc_from ||
    condition.last_updated_date_time_utc_to
  ) {
    let start = '';

    if (condition.last_updated_date_time_utc_from) {
      start = moment
        .tz(
          condition.last_updated_date_time_utc_from,
          condition.timezone ?? 'UTC'
        )
        .format('YYYY-MM-DD');
    }

    let end = '';

    if (condition.last_updated_date_time_utc_to) {
      end = moment
        .tz(
          condition.last_updated_date_time_utc_to,
          condition.timezone ?? 'UTC'
        )
        .format('YYYY-MM-DD');
    }

    filters.push({
      typeText: t('Last Updated Date'),
      filterValue: `${start} ~ ${end}`,
    });
  }

  if (
    parsed['field_responses.given_name.response'] &&
    Array.isArray(parsed['field_responses.given_name.response']) &&
    parsed['field_responses.given_name.response'].length > 0
  ) {
    const name = (parsed['field_responses.given_name.response'] || []).shift();
    filters.push({
      typeText: t('Given Name'),
      filterValue: name as any as string,
    });
  } else {
    if (parsed['field_responses.kana_given_name.response']) {
      const name = parsed['field_responses.kana_given_name.response'] || '';
      filters.push({
        typeText: t('Given Name'),
        filterValue: name as any as string,
      });
    }
  }

  if (
    parsed['field_responses.family_name.response'] &&
    Array.isArray(parsed['field_responses.family_name.response']) &&
    parsed['field_responses.family_name.response'].length > 0
  ) {
    const name = (parsed['field_responses.family_name.response'] || []).shift();
    filters.push({
      typeText: t('Family Name'),
      filterValue: name as any as string,
    });
  } else {
    if (parsed['field_responses.kana_family_name.response']) {
      const name = parsed['field_responses.kana_family_name.response'] || '';
      filters.push({
        typeText: t('Family Name'),
        filterValue: name as any as string,
      });
    }
  }

  return (
    <>
      {filters.map((f) => (
        <li key={f.typeText}>{`${f.typeText}: ${f.filterValue}`}</li>
      ))}
    </>
  );
};
