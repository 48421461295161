import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'react-final-form';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper, Select } from 'client/components/Form';
import { fetchPromotions } from 'client/actions/promotions';
import type { ReduxState } from 'client/reducers';

export const EditInstantWinTierPromoCodeForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const promotions = useSelector((state: ReduxState) => state.promotions.all);

  React.useEffect(() => {
    dispatch(fetchPromotions());
  }, []);

  const promotionOptions = promotions
    .filter((p) => p.use_one_time_promo_code)
    .map((p) => ({
      value: p.id ?? '',
      text: p.reference_label ?? '',
    }));

  return (
    <Box mb={2}>
      <FieldWrapper label={t('Select Promotion')}>
        <Field name="promotionId">
          {({ input }) => (
            <Select
              search
              options={promotionOptions}
              value={input.value}
              onChange={(_, { value }) => input.onChange(value)}
            />
          )}
        </Field>
      </FieldWrapper>
    </Box>
  );
};
