import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Tabs } from 'client/components/Tabs/Tabs';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { fetchProducts } from 'client/actions/products';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { mediaDownloadSalesPagesSelector } from 'client/reducers/mediaDownloadSalesPages';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { fetchMediaDownloadSalesPages } from 'client/actions/mediaDownloadSalesPages';
import { fetchMediaDownloadPrices } from 'client/actions/mediaDownloadPrices';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import baseStyles from 'client/base.module.css';
import { MediaDownloadSalesPage } from 'shared/models/swagger';

import { EditMediaSalesPageModal } from './EditMediaSalesPageModal';

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id?: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const useColumns = (): ColumnType<MediaDownloadSalesPage>[] => {
  const { t } = useTranslation();
  const productSummaries = useSelector(summariesWithBookmarksSelector);
  const activeUser = useSelector(activeUserSelector);
  return [
    ...(hasCustomUserRoleWritePermissions(activeUser, 'MEDIA_DOWNLOAD.UPLOAD')
      ? ([
          {
            Header: '',
            th: true,
            Cell: (cellInfo) => {
              const [showEditModal, setShowEditModal] = React.useState(false);
              return (
                <Box display="flex">
                  {showEditModal && (
                    <EditMediaSalesPageModal
                      existingSalesPage={cellInfo.original}
                      open={showEditModal}
                      onClose={() => setShowEditModal(false)}
                    />
                  )}
                  <EditIcon onClick={() => setShowEditModal(true)} />
                </Box>
              );
            },
          },
        ] as ColumnType<MediaDownloadSalesPage>[])
      : []),
    {
      Header: t('Participation Date'),
      accessor: 'participation_date',
    },
    {
      Header: t('Products and Start Times'),
      Cell: (cellInfo) => (
        <ul>
          {cellInfo.original.product_summaries?.map((productSummary) => (
            <li key={productSummary.product_id}>
              {`${getDisplayProductName(
                productSummaries.find((p) => p.id == productSummary.product_id)
              )}${
                (productSummary.start_times_local ?? []).length > 0
                  ? ` [${productSummary.start_times_local?.join(', ')}]`
                  : ''
              }`}
            </li>
          ))}
        </ul>
      ),
    },
  ];
};

export const MediaDownloadSalesPageList = () => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  React.useEffect(() => {
    dispatch(fetchMediaDownloadSalesPages());
    dispatch(fetchMediaDownloadPrices());
  }, []);
  const salesPages = useSelector(mediaDownloadSalesPagesSelector);
  const columns = useColumns();
  const activeUser = useSelector(activeUserSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const { t } = useTranslation();
  const tabPanes = React.useMemo(() => {
    const pendingSalesPages = salesPages.filter(
      (p) => p.status === 'MEDIA_SALES_PAGE_PENDING'
    );
    const publishedSalesPages = salesPages.filter(
      (p) => p.status === 'MEDIA_SALES_PAGE_PUBLISHED' && !p.is_expired
    );
    const expiredSalesPages = salesPages.filter(
      (p) => p.status === 'MEDIA_SALES_PAGE_PUBLISHED' && p.is_expired
    );
    return [
      {
        header: t('Pending'),
        content: () =>
          pendingSalesPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Pending Sales Pages Found')}
            </div>
          ) : (
            <CustomTable items={pendingSalesPages} columns={columns} />
          ),
      },
      {
        header: t('Published'),
        content: () =>
          publishedSalesPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Published Sales Pages Found')}
            </div>
          ) : (
            <CustomTable items={publishedSalesPages} columns={columns} />
          ),
      },
      {
        header: t('Expired'),
        content: () =>
          expiredSalesPages.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Expired Sales Pages Found')}
            </div>
          ) : (
            <CustomTable items={expiredSalesPages} columns={columns} />
          ),
      },
    ];
  }, [t, salesPages]);
  return (
    <div>
      {hasCustomUserRoleWritePermissions(
        activeUser,
        'MEDIA_DOWNLOAD.UPLOAD'
      ) && (
        <Button
          size="middle"
          style="green"
          onClick={() => setShowEditModal(true)}
        >
          {t('Upload New Images and Videos')}
        </Button>
      )}
      {showEditModal && (
        <EditMediaSalesPageModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}

      <Box mt={2}>
        <Tabs panes={tabPanes} />
      </Box>
    </div>
  );
};
