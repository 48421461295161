import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { GraphDataItem } from 'client/libraries/util/digitalMapAccessReportSettings';
import styles from 'client/components/v3/Table/TableSmall.module.css';

type Props = {
  data: GraphDataItem[];
};
export const DetailTablePane = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <table className={clsx(styles['c-tableSmall'], styles['row'])}>
      <thead>
        <tr>
          <th style={{ width: 'calc(100% / 5)' }}>{t('Date')}</th>
          <th style={{ width: 'calc(100% / 5)' }}>{t('visitCount')}</th>
          <th style={{ width: 'calc(100% / 5)' }}>{t('userCount')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataItem, idx) => {
          return (
            <tr key={idx}>
              <td>{dataItem.name}</td>
              <td>{dataItem.visitCount}</td>
              <td>{dataItem.userCount}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
