import * as React from 'react';
import clsx from 'clsx';

import type { GuideAccountShape } from 'client/libraries/util/accountShape';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

export type GuideCustomTableColumn = {
  Header: string;
  accessor?: string | ((arg0: any) => string);
  width?: 'minimal' | 'short' | 'middle' | 'long' | 'longx2';
  Cell?: (arg0: any) => any;
  th?: boolean;
  sub?: boolean;
};
type Props = {
  items: GuideAccountShape[];
  columns: GuideCustomTableColumn[];
};
export const GuideCustomTable = ({ items, columns }: Props) => {
  return (
    <>
      <div className={clsx(componentStyles['c-table-sub'])}>
        <table>
          <tbody>
            <tr>
              {columns.map((column, idx) => {
                const width = column.width || '';
                return (
                  <th
                    className={clsx(
                      width
                        ? baseStyles[
                            ('base-r-t-' + width) as keyof typeof baseStyles
                          ]
                        : ''
                    )}
                    key={idx}
                  >
                    {column.Header}
                  </th>
                );
              })}
            </tr>

            {items.map((item, ridx) => {
              return (
                <tr key={ridx}>
                  {columns.map((column, idx) => {
                    const width = column.width || '';
                    let value: any;
                    let displayValue: any;

                    if (typeof column.accessor === 'string') {
                      value = (item as any)[column.accessor];
                    } else if (typeof column.accessor === 'function') {
                      value = column.accessor(item);
                    }

                    if (column.Cell) {
                      const cell = {
                        value: value,
                        original: item,
                      };
                      displayValue = column.Cell(cell);
                    } else {
                      displayValue = value;
                    }

                    if (column.th) {
                      return (
                        <th
                          className={clsx(
                            baseStyles[
                              ('base-r-t-' + width) as keyof typeof baseStyles
                            ]
                          )}
                          key={idx}
                        >
                          {displayValue}
                        </th>
                      );
                    } else {
                      return (
                        <td
                          key={idx}
                          className={clsx(
                            baseStyles[
                              ('base-r-t-' + width) as keyof typeof baseStyles
                            ],
                            column.sub
                              ? componentStyles['c-table-sub__btn']
                              : ''
                          )}
                          data-text={column.Header}
                        >
                          {displayValue}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
