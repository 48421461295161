import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import clsx from 'clsx';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';

import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';
import { Button } from 'client/components/v3/Common/Button';

const itemType = 'selectable-item';
type Props = {
  title: string;
  index: number;
  id?: string;
  children: JSX.Element | JSX.Element[];
  onDelete?: () => void;
  isDeleteable?: boolean;
  isCollapsible?: boolean;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
};
interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const CollapsibleBox = ({
  title,
  index,
  id,
  children,
  onDelete,
  isDeleteable = true,
  isCollapsible = true,
  moveItem,
}: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop<DragItem>({
    accept: itemType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect =
        ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem?.(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      type: itemType,
      id: id,
      index,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      id={id}
      style={{ opacity }}
      className={styles['p-topPage-acBox']}
    >
      <div className={styles['p-topPage-acBox__header']}>
        <div className={styles['p-topPage-acBox__header__ttl']}>
          {/* TODO: hide icon now until we implement drag&drop functionality */}
          <div className={styles['p-topPage-acBox__header__ttl__ic']}>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <p className={styles['p-topPage-acBox__header__ttl__txt']}>{title}</p>
        </div>
        <div className={styles['p-topPage-acBox__header__actions']}>
          {isDeleteable && (
            <Button
              size="icon"
              color="tertiarygray"
              iconBeforeText={
                <i className="c-icon-outline-general-trash-03"></i>
              }
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
            />
          )}
          {isCollapsible && (
            <Button
              text={isActive ? t('Hide') : t('Show')}
              uiType="bg"
              size="md"
              color="tertiarygray"
              iconBeforeText={
                isActive ? (
                  <i className="c-icon-outline-general-minus"></i>
                ) : (
                  <i className="c-icon-solid-general-plus"></i>
                )
              }
              onClick={() => {
                setIsActive(!isActive);
              }}
            />
          )}
        </div>
      </div>

      <div
        className={clsx(
          styles['p-topPage-acBox__body'],
          isActive && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
