import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import moment from 'moment-timezone';

import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { Button } from 'client/components/Form';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { getArrayMutators } from 'client/libraries/util/form';
import { Message } from 'client/components/Message/Message';
import { updateGuideSettings } from 'client/actions/guideSettings';
import type { Weekday } from 'client/libraries/util/weekdays';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import styles from './Schedule.module.css';

type Props = {
  isActive?: boolean;
};
type FormValues = {
  operatingDaysOfWeek: Weekday[];
  operatingTimeOfDayStart: string;
  operatingTimeOfDayEnd: string;
};
const focusOnError: any = createDecorator();
export const OperatingHours = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const guideSettings = (organization?.guides || []).find(
    (guide) => guide.id === activeUser?.id
  );

  const getInitialValues = (): FormValues => {
    return {
      operatingDaysOfWeek:
        guideSettings?.operating_hours_rule?.days_of_week || [],
      operatingTimeOfDayStart:
        guideSettings?.operating_hours_rule?.start_time_local || '09:00',
      operatingTimeOfDayEnd:
        guideSettings?.operating_hours_rule?.end_time_local || '17:00',
    };
  };

  return (
    <>
      <div
        className={clsx(
          baseStyles['base-main__body__box'],
          componentStyles['c-tab-box__box'],
          isActive ? componentStyles['is-active'] : ''
        )}
      >
        <div className={clsx(baseStyles['base-main__body__box__body'])}>
          <Form
            onSubmit={async (values: FormValues) => {
              try {
                await dispatch(
                  updateGuideSettings(activeUser?.id ?? '', {
                    operating_hours_rule: {
                      days_of_week: values.operatingDaysOfWeek,
                      start_time_local: values.operatingTimeOfDayStart,
                      end_time_local: values.operatingTimeOfDayEnd,
                    },
                  })
                );
                setSaveSucceeded(true);
              } catch (err) {
                return {
                  [FORM_ERROR]: t('Save Failed'),
                };
              }
            }}
            decorators={[focusOnError]}
            initialValues={getInitialValues()}
            mutators={getArrayMutators()}
            keepDirtyOnReinitialize={true}
          >
            {({ handleSubmit, submitError }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className={clsx(componentStyles['c-table-nowrap'])}>
                    <table>
                      <tbody>
                        <tr>
                          <th className={clsx(baseStyles['base-t-128'])}>
                            {t('Operating Days of the Week')}
                          </th>
                          <td>
                            <Field name="operatingDaysOfWeek">
                              {({ input }) => (
                                <WeekdaysInput
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                              )}
                            </Field>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Operating Hours')}</th>
                          <td>
                            <div className={styles['schedule__businessHours']}>
                              <Field name="operatingTimeOfDayStart">
                                {({ input }) => (
                                  <TimePicker
                                    allowEmpty={false}
                                    value={moment(input.value, 'HH:mm')}
                                    onChange={(newMoment) => {
                                      if (!newMoment) {
                                        return;
                                      }

                                      input.onChange(newMoment.format('HH:mm'));
                                    }}
                                  />
                                )}
                              </Field>
                              <p>-</p>
                              <Field name="operatingTimeOfDayEnd">
                                {({ input }) => (
                                  <TimePicker
                                    allowEmpty={false}
                                    value={moment(input.value, 'HH:mm')}
                                    onChange={(newMoment) => {
                                      if (!newMoment) {
                                        return;
                                      }

                                      input.onChange(newMoment.format('HH:mm'));
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {saveSucceeded && (
                      <Message success header={t('Settings Saved')} />
                    )}
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button type="submit" style="blue" size="small">
                      {t('Save Settings')}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </>
  );
};
