import StorageClass, { StorageAccessLevel } from '@aws-amplify/storage';
import { Auth } from '@aws-amplify/auth';

import { config } from 'client/config';

Auth.configure(config.cognitoConfig);
StorageClass.configure({
  // This "provider" initialization is required in the latest versions of aws-amplify.
  AWSS3: {
    bucket: '',
    region: '',
  },
});
const s3MediaConfig = {
  ...config.cognitoConfig,
  bucket: config.s3Config.mediaBucketName,
  region: config.s3Config.mediaBucketRegion,
  identityPoolId: config.cognitoConfig.identityPoolId,
  level: 'public' as StorageAccessLevel,
  customPrefix: {
    public: '',
  },
};
type s3MediaStoragePutConfig = {
  contentType?: string;
};
const s3MediaStorage = {
  put: (key: string, object: any, config?: s3MediaStoragePutConfig) =>
    StorageClass.put(key, object, {
      ...s3MediaConfig,
      ...config,
    }),
  get: (key: string) => StorageClass.get(key, s3MediaConfig),
};
const s3MediaDownloadConfig = {
  ...config.cognitoConfig,
  bucket: config.s3Config.mediaDownloadBucketName,
  region: config.s3Config.mediaDownloadBucketRegion,
  identityPoolId: config.cognitoConfig.identityPoolId,
  level: 'public' as StorageAccessLevel,
  customPrefix: {
    public: '',
  },
};
const s3MediaDownloadStorage = {
  put: (key: string, object: any) =>
    StorageClass.put(key, object, s3MediaDownloadConfig),
  get: (key: string) => StorageClass.get(key, s3MediaDownloadConfig),
};
export { s3MediaStorage, s3MediaDownloadStorage };
