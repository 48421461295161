import type { Reservation } from 'shared/models/swagger';

export const getGuestName = (r: Reservation) => {
  const lastName =
    r.field_responses?.find((f) => f.key === 'family_name')?.response || '';
  const givenName =
    r.field_responses?.find((f) => f.key === 'given_name')?.response || '';
  const kanaLastName =
    r.field_responses?.find((f) => f.key === 'kana_family_name')?.response ||
    '';
  const kanaGivenName =
    r.field_responses?.find((f) => f.key === 'kana_given_name')?.response || '';
  let customerName = '';

  if (givenName !== '' || lastName !== '') {
    customerName = `${givenName} ${lastName}`;

    if (kanaGivenName !== '' || kanaLastName !== '') {
      customerName =
        customerName + ' ' + `（${kanaLastName} ${kanaGivenName}）`;
    }
  } else {
    customerName = `${kanaLastName} ${kanaGivenName}`;
  }

  return customerName;
};
