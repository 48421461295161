import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { withTranslation } from 'react-i18next';

import type { TranslateFuncType } from 'client/components/Translate';
import { config } from 'client/config';

Sentry.init({
  dsn: 'https://411c74a0417b4f689727827466930cc1@sentry.io/1299510',
  environment: config.sentryEnvironment,
  ignoreErrors: [
    // Ignore 'Illegal invocation' because it happens often for GA and does not seem to impact users.
    'Illegal invocation',
  ],
});
type Props = {
  t: TranslateFuncType;
  children: any;
};
type State = {
  error: Error | null | undefined;
};

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    }
  ) {
    this.setState({
      error,
    });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, (errorInfo as any)[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const { children, t } = this.props;

    if (this.state.error) {
      //render fallback UI
      return (
        <>
          <h2>{t('Oops, something went wrong.')}</h2>
          <a href="#dummy" onClick={() => Sentry.showReportDialog()}>
            {t('Report feedback')}
          </a>
        </>
      );
    } else {
      //when there's not an error, render children untouched
      return children;
    }
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent as any);
