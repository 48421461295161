import * as React from 'react';
import clsx from 'clsx';

import styles from './AccordionList.module.css';

export type AccordionItemContent = {
  header: string;
  content: React.ComponentType<any>;
  isActiveDefault?: boolean;
};
interface Props {
  isActiveDefault?: boolean;
  item: AccordionItemContent;
}
export const AccordionItem = ({ item }: Props) => {
  const [isActive, setIsActive] = React.useState<boolean>(
    item.isActiveDefault || false
  );
  const Content = item.content;
  return (
    <li className={styles['c-accordion__list']}>
      <div
        className={clsx(
          styles['c-accordion__list__header'],
          isActive && styles['is-active']
        )}
        onClick={() => setIsActive(!isActive)}
      >
        <h3 className={styles['c-accordion__list__header__text']}>
          {item.header}
        </h3>
        <p className={styles['c-accordion__list__header__ic']} />
      </div>
      <div
        className={clsx(
          styles['c-accordion__list__body'],
          isActive && styles['is-active']
        )}
      >
        <Content />
      </div>
    </li>
  );
};
