import clsx from 'clsx';
import { useState } from 'react';

import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  headerText: string;
  children: JSX.Element | JSX.Element[];
};

export const Accordion = ({ headerText, children }: Props) => {
  const [shouldShow, setShouldShow] = useState(false);

  return (
    <div className={styles['p-priceChange__item__ac']}>
      <div className={styles['p-priceChange__item__ac__header']}>
        <p className={styles['p-priceChange__item__ac__header__ttl']}>
          {headerText}
        </p>
        <div className={styles['p-priceChange__item__ac__header__ic']}>
          <Button
            color="white"
            size="icon"
            iconAfterText={
              shouldShow ? (
                <i className="c-icon-outline-general-minus"></i>
              ) : (
                <i className="c-icon-outline-general-plus"></i>
              )
            }
            onClick={() => setShouldShow(!shouldShow)}
          />
        </div>
      </div>
      <div
        className={clsx(
          styles['p-priceChange__item__ac__body'],
          shouldShow && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
