export const colors = [
  '#203f52',
  '#78b4c6',
  '#6edb9f',
  '#218841',
  '#98da1d',
  '#7c8a4f',
  '#bfcd8e',
  '#743502',
  '#de9c76',
  '#cb2c09',
  '#fe8f06',
  '#3a3f09',
  '#2ee52d',
  '#0494fb',
  '#d3cb04',
];

export const v3Colors = [
  '#0CB0EB',
  '#EF4444',
  '#F59E0B',
  '#F97316',
  '#EAB308',
  '#84CC16',
  '#14B8A6',
  '#10B981',
  '#0EA5E9',
  '#3B82F6',
  '#6366F1',
  '#8B5CF6',
  '#A855F7',
  '#D946EF',
  '#EC4899',
  '#F43F5E',
];

export const v3AvailabilityColors = [
  {
    // Red
    color: '#7F1D1D',
    backgroundColor: '#FEE2E2',
    borderColor: '#FECACA',
  },
  {
    // Blue
    color: '#1E3A8A',
    backgroundColor: '#DBEAFE',
    borderColor: '#BFDBFE',
  },
  {
    // Green
    color: '#064E3B',
    backgroundColor: '#D1FAE5',
    borderColor: '#A7F3D0',
  },
  {
    // Yellow
    color: '#713F12',
    backgroundColor: '#FEF9C3',
    borderColor: '#FEF08A',
  },
  {
    // Gray
    color: '#18181B',
    backgroundColor: '#F4F4F5',
    borderColor: '#E4E4E7',
  },
  {
    // Orange
    color: '#7C2D12',
    backgroundColor: '#FFEDD5',
    borderColor: '#FED7AA',
  },
  {
    // Purple
    color: '#581C87',
    backgroundColor: '#F3E8FF',
    borderColor: '#E9D5FF',
  },
  {
    // Pink
    color: '#831843',
    backgroundColor: '#FCE7F3',
    borderColor: '#FBCFE8',
  },
  {
    // Lime
    color: '#365314',
    backgroundColor: '#ECFCCB',
    borderColor: '#D9F99D',
  },
  {
    // Teal
    color: '#134E4A',
    backgroundColor: '#CCFBF1',
    borderColor: '#99F6E4',
  },
];

export const v3CustomerTagColors = [
  {
    text: 'Gray',
    value: 'GRAY',
    color: '#18181B',
    backgroundColor: '#F4F4F5',
    borderColor: '#E4E4E7',
  },
  {
    text: 'Orange',
    value: 'ORANGE',
    color: '#7C2D12',
    backgroundColor: '#FFEDD5',
    borderColor: '#FED7AA',
  },
  {
    text: 'Green',
    value: 'GREEN',
    color: '#064E3B',
    backgroundColor: '#D1FAE5',
    borderColor: '#A7F3D0',
  },
  {
    text: 'Yellow',
    value: 'YELLOW',
    color: '#713F12',
    backgroundColor: '#FEF9C3',
    borderColor: '#FEF08A',
  },
  {
    text: 'Blue',
    value: 'BLUE',
    color: '#1E3A8A',
    backgroundColor: '#DBEAFE',
    borderColor: '#BFDBFE',
  },
  {
    text: 'Purple',
    value: 'PURPLE',
    color: '#581C87',
    backgroundColor: '#F3E8FF',
    borderColor: '#E9D5FF',
  },
  {
    text: 'Pink',
    value: 'PINK',
    color: '#831843',
    backgroundColor: '#FCE7F3',
    borderColor: '#FBCFE8',
  },
  {
    text: 'Red',
    value: 'RED',
    color: '#7F1D1D',
    backgroundColor: '#FEE2E2',
    borderColor: '#FECACA',
  },
];
