import * as React from 'react';
import { useSelector } from 'react-redux';

import { activeAccountSelector } from 'client/reducers/user';

declare global {
  interface Window {
    _hsq: any;
  }
}

export const HubspotChatWidget = () => {
  const activeUser = useSelector(activeAccountSelector);

  React.useEffect(() => {
    // Create script component.
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/40203475.js`;
    script.async = true;

    const _hsq = (window._hsq = window._hsq || []);

    _hsq.push([
      'identify',
      {
        email: activeUser?.email,
      },
    ]);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [activeUser]);

  return null;
};
