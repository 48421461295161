import {
  SET_DASHBOARD_TAB,
  SET_DASHBOARD_ROW_COUNT,
  SET_DASHBOARD_CURRENT_PAGE,
} from 'client/constants/ActionTypes';
import type { TabType } from 'client/libraries/util/dashboard';

export const setDashboardTab = (tab: TabType) => ({
  type: SET_DASHBOARD_TAB,
  tab,
});

export const setDashboardRowCount = (rowCount: number) => ({
  type: SET_DASHBOARD_ROW_COUNT,
  rowCount,
});

export const setDashboardCurrentPage = (currentPage: number) => ({
  type: SET_DASHBOARD_CURRENT_PAGE,
  currentPage,
});
