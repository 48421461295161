import { parsePhoneNumber } from 'libphonenumber-js';

export const formattedPhone = (phone: string): string => {
  try {
    const phoneNumber = parsePhoneNumber(phone?.replace(/^\+0-9/g, ''));

    if (!phoneNumber.isValid) {
      return phone;
    }

    return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
  } catch (e) {
    return phone;
  }
};
