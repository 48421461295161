import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { updateReservation } from 'client/actions/reservations';
import type { Reservation, Account } from 'shared/models/swagger';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextArea } from 'client/components/v3/Form/TextArea';
import styles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  activeUser: Account | null;
  readOnly?: boolean;
  onClose: () => void;
};

export const SupplementaryInformationUpdateModal = ({
  reservation,
  activeUser,
  readOnly,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [patch, setPatch] = useState({
    agent_notes: reservation.agent_notes,
    supplier_notes: reservation.supplier_notes,
    supplier_internal_notes: reservation.supplier_internal_notes,
    supplier_internal_notes_for_dispatch:
      reservation.supplier_internal_notes_for_dispatch,
  });

  useEffect(() => {
    setPatch({
      agent_notes: reservation.agent_notes,
      supplier_notes: reservation.supplier_notes,
      supplier_internal_notes: reservation.supplier_internal_notes,
      supplier_internal_notes_for_dispatch:
        reservation.supplier_internal_notes_for_dispatch,
    });
  }, [reservation]);

  const currentStatus = getCurrentStatus(reservation);

  const userCanEditAgentNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditSupplierNotes =
    !readOnly &&
    operationAllowed(activeUser, 'write', 'reservationSupplierNotes');
  return (
    <Modal
      title={t('Update')}
      open={true}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button text={t('Discard')} color="white" onClick={onClose} />
          <Button
            text={t('Save')}
            onClick={() => {
              dispatch(updateReservation(reservation.id, patch));
              onClose();
            }}
          />
        </>
      }
    >
      <div className={styles['p-contactEmail']}>
        {userCanEditAgentNotes && (
          <div className={styles['p-contactEmail__item']}>
            <TextArea
              label={t('Remarks')}
              value={patch['agent_notes'] ?? ''}
              onChange={(_, { value }) => {
                setPatch({
                  ...patch,
                  agent_notes: value,
                });
              }}
              height={120}
            />
          </div>
        )}

        {userCanEditSupplierNotes && (
          <>
            <div className={styles['p-contactEmail__item']}>
              <TextArea
                label={t('Replies')}
                value={patch['supplier_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_notes: value,
                  });
                }}
                height={120}
              />
            </div>

            <div className={styles['p-contactEmail__item']}>
              <TextArea
                label={t('Memo')}
                value={patch['supplier_internal_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes: value,
                  });
                }}
                height={120}
              />
            </div>

            <div className={styles['p-contactEmail__item']}>
              <TextArea
                label={t('Internal Note')}
                value={patch['supplier_internal_notes_for_dispatch'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes_for_dispatch: value,
                  });
                }}
                height={120}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
