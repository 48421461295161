import { useTranslation } from 'react-i18next';

import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import baseStyles from 'client/base.module.css';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { config } from 'client/config';

import styles from './ContentTypeSelect.module.css';

export const ContentTypeSelect = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Box ml={2}>
          <Button type="submit" style="green" size="middle">
            {t('Continue')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Select Content Type')}
          </div>
        </div>
        <Box mt={2} ml={2}>
          <EnumRadioButtonGroup
            isRequired
            name="type"
            options={[
              {
                label: t('Email'),
                value: 'EMAIL',
              },
              {
                label: t('Website Popup'),
                value: 'WEBSITE_POPUP',
              },
              ...(config.enableConsolidatedMarketingAutomationContent
                ? [
                    {
                      label: t('SMS'),
                      value: 'SMS',
                    },
                    {
                      label: t('Push Notification'),
                      value: 'PUSH_NOTIFICATION',
                    },
                  ]
                : []),
            ]}
          />
        </Box>
      </div>
    </div>
  );
};
