import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { Button, FieldWrapper, Input } from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import styles from './ComposeMessage.module.css';
import { MessageEditor } from './MessageEditor';

interface Props {
  onBackClick: () => void;
  onSave: () => void;
}
export const ComposeMessage = ({ onBackClick, onSave }: Props) => {
  const { t } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);

  const org = useSelector(activeUserOrganizationSelector);
  const loading = useSelector((state: ReduxState) => state.newsletters.loading);

  const fromEmailAddress = `${
    organization?.booking_widget_subdomain ?? ''
  }@${getEmailDomain()}`;

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} onClick={onSave} style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="green" size="middle">
            {t('Continue to Step 3')}
          </Button>
        </Box>
      </div>
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Email Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <FieldWrapper label={t('From Name')}>{org?.name}</FieldWrapper>
          <FieldWrapper label={t('From Address')}>
            {fromEmailAddress}
          </FieldWrapper>
          <Box mt={2}>
            <Field name="subject">
              {({ input, meta: { touched, error } }) => (
                <Input
                  value={input.value}
                  onChange={input.onChange}
                  label={t('Subject')}
                  error={touched && error}
                />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <MessageEditor />
          </Box>
        </div>
      </div>
    </div>
  );
};

const getEmailDomain = (): string => {
  return window.location.origin
    .toString()
    .replace('localhost:3000', 'dev.ntmg.com')
    .replace(/https?:\/\/(app\.)?/, 'book.');
};
