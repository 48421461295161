import * as React from 'react';

import { Heading } from './Heading';
import { Section } from './util';
import { BaseSection } from './BaseSection';

type Props = {
  data: Section;
};

export const Highlights = ({ data }: Props) => {
  const highlight = data.highlight;

  if (!highlight) {
    return <></>;
  }

  return (
    <BaseSection data={data}>
      <>
        <Heading
          title={highlight?.title}
          description={highlight?.description}
          titleFontFamily={highlight?.titleStyle?.fontFamily}
          titleColor={highlight?.titleStyle?.color}
          descriptionFontFamily={highlight?.descriptionStyle?.fontFamily}
          descriptionColor={highlight?.descriptionStyle?.color}
        />
        {highlight.style !== 'CHECKER_WITH_IMAGE' && highlight.imageUrl && (
          <tr>
            <td>
              <img
                src={highlight.imageUrl}
                style={{
                  width: '100%',
                  borderRadius: '1em',
                  marginBottom: '1.5em',
                }}
              />
            </td>
          </tr>
        )}
        {highlight.style !== 'CHECKER_WITH_IMAGE' && (
          <tr>
            <td>
              <table
                cellPadding="0"
                cellSpacing="0"
                style={{
                  width: '100%',
                  padding: '16px',
                  backgroundColor: highlight.headingBackgroundColor,
                  color: highlight.headingTextColor,
                  borderTopLeftRadius: '1em',
                  borderTopRightRadius: '1em',
                  fontWeight: 'bold',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      {/* TODO: use image here */}
                      {/* <span></span> */}
                      <p style={{ margin: 0 }}>{highlight.headingText}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              {(highlight.style === 'NUMBER_SMALL' ||
                highlight.style === 'CHECK' ||
                highlight.style === 'DOT') && (
                <table
                  style={{
                    width: '100%',
                    padding: '16px',
                    textAlign: 'left',
                    backgroundColor: highlight.itemBackgroundColor,
                    color: highlight.itemTextColor,
                    borderBottomLeftRadius: '1em',
                    borderBottomRightRadius: '1em',
                  }}
                >
                  <tbody>
                    {highlight.items.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          marginTop: index === 0 ? undefined : '12px',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingBottom:
                            index === highlight.items.length - 1
                              ? undefined
                              : '8px',
                          borderBottom:
                            index === highlight.items.length - 1
                              ? undefined
                              : '1px solid rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        {highlight.style === 'NUMBER_SMALL' && (
                          <td
                            style={{
                              padding: '4px',
                              width: '20px',
                              height: '20px',
                              fontWeight: 'bold',
                              lineHeight: '1.25em',
                              fontSize: '14px',
                              color: highlight.headingTextColor,
                              backgroundColor: highlight.headingBackgroundColor,
                              borderRadius: '9999px',
                              verticalAlign: 'middle',
                              textAlign: 'center',
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                position: 'absolute',
                                top: 0,
                                left: 6,
                              }}
                            >
                              {index + 1}
                            </p>
                          </td>
                        )}
                        {(highlight.style === 'CHECK' ||
                          highlight.style === 'DOT') && (
                          <td
                            style={{
                              fontWeight: 'bold',
                              lineHeight: '1.25em',
                              fontSize: '1.2em',
                              color: highlight.headingBackgroundColor,
                            }}
                          >
                            {highlight.style === 'DOT' ? '●' : '✓'}
                          </td>
                        )}
                        <td style={{ marginLeft: '12px' }}>{item.text}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {highlight.style === 'NUMBER_LARGE' && (
                <table
                  style={{
                    width: '100%',
                    padding: '8px',
                    backgroundColor: highlight.itemBackgroundColor,
                    color: highlight.itemTextColor,
                    borderBottomLeftRadius: '1em',
                    borderBottomRightRadius: '1em',
                  }}
                >
                  <tbody>
                    {Array.from(
                      { length: Math.ceil(highlight.items.length / 4) },
                      (_, rowIndex) => (
                        <tr key={rowIndex} style={{ textAlign: 'center' }}>
                          {highlight.items
                            .slice(rowIndex * 4, rowIndex * 4 + 4)
                            .map((item, itemIndex) => (
                              <td
                                key={itemIndex}
                                style={{
                                  width: '25%',
                                  padding: '1em',
                                  verticalAlign: 'top',
                                  wordBreak: 'break-word',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: '2em',
                                    color: 'rgba(0, 0, 0, 0.3)',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                  }}
                                >
                                  {itemIndex + 1}
                                </p>
                                <p>{item.text}</p>
                              </td>
                            ))}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </td>
          </tr>
        )}
        {highlight.style === 'CHECKER_WITH_IMAGE' && (
          <tr>
            <td>
              <table
                style={{
                  width: '100%',
                }}
              >
                <tbody>
                  {highlight.items.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        width: '100%',
                      }}
                    >
                      {index % 2 === 0 ? (
                        <>
                          <td
                            style={{
                              width: '260px',
                            }}
                          >
                            <img
                              src={item.imageUrl ?? ''}
                              alt={item.text}
                              style={{
                                width: '100%',
                                height: '260px',
                                borderRadius: '1em',
                                marginBottom: '1.5em',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </td>
                          <td style={{ width: '20px' }}>
                            <div></div>
                          </td>
                          <td style={{ width: '260px' }}>
                            <table
                              style={{
                                width: '100%',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <p
                                      style={{
                                        fontSize: '2em',
                                        color: 'rgba(0, 0, 0, 0.3)',
                                        fontWeight: 'bold',
                                        marginBottom: '8px',
                                      }}
                                    >
                                      {index + 1}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <p
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {item.text}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ width: '260px' }}>
                            <table
                              style={{
                                width: '100%',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ textAlign: 'right' }}>
                                    <p
                                      style={{
                                        fontSize: '2em',
                                        color: 'rgba(0, 0, 0, 0.3)',
                                        fontWeight: 'bold',
                                        marginBottom: '8px',
                                      }}
                                    >
                                      {index + 1}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ textAlign: 'left' }}>
                                    <p
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {item.text}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ width: '20px' }}>
                            <div></div>
                          </td>
                          <td style={{ width: '260px' }}>
                            <img
                              src={item.imageUrl ?? ''}
                              alt={item.text}
                              style={{
                                width: '100%',
                                height: '260px',
                                borderRadius: '1em',
                                marginBottom: '1.5em',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </>
    </BaseSection>
  );
};
