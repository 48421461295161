import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { Modal } from 'client/components/Modal/Modal';
import {
  DateRangeInput,
  Button,
  MultiSelect,
  Checkbox,
} from 'client/components/Form';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { ReservationStatus } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import { FilterReservationsRequest, getPaymentTypeOptions } from './util';

type Props = {
  showModal: boolean;
  onClose: () => void;
  onReset: () => void;
  onSearch: () => void;
  filterCondition: FilterReservationsRequest;
  setFilterCondition: (arg0: FilterReservationsRequest) => void;
};
export const FilterModal = ({
  showModal,
  onClose,
  onReset,
  onSearch,
  filterCondition,
  setFilterCondition,
}: Props) => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);

  const contractOrganizations = useSelector(
    (state: ReduxState) => state.organizations.contracted
  );

  const showDepositDate = org?.stripe_accounts?.some(
    (acct) => acct.account_type === 'JAPAN_EXPRESS'
  );

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = contractOrganizations.map((option) => {
    return {
      value: option.id,
      text: option.name,
    };
  });
  const paymentTypeOptions = getPaymentTypeOptions(t);

  const changeProductId = ({ value }: { value: string[] }) => {
    setFilterCondition({ ...filterCondition, productIds: value });
  };

  const changeStatus = ({ value }: { value: string[] }) => {
    setFilterCondition({
      ...filterCondition,
      reservationStatuses: value as ReservationStatus[],
    });
  };
  const changeAgent = ({ value }: { value: string[] }) => {
    setFilterCondition({
      ...filterCondition,
      agentIds: value,
    });
  };

  const changePaymentTypes = (value: string[]) => {
    setFilterCondition({
      ...filterCondition,
      paymentTypes: value as string[],
    });
  };

  const transferDateDateFromChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      transferDateFrom: date,
    });
  };

  const transferDateDateToChangeHandler = (date: string) => {
    setFilterCondition({
      ...filterCondition,
      transferDateTo: date,
    });
  };

  return (
    <Modal title={t('Filter Reservations')} open={showModal} onClose={onClose}>
      <Modal.Content>
        <Modal.Box>
          <MultiSelect
            search
            label={t('Product')}
            options={productOptions}
            selectedValues={filterCondition.productIds}
            onChange={changeProductId}
          />
        </Modal.Box>

        <Modal.Box>
          <MultiSelect
            search
            label={t('Booking Status')}
            options={statusOptions}
            selectedValues={filterCondition.reservationStatuses}
            onChange={changeStatus}
          />
        </Modal.Box>

        {config.enablePartnership && (agentOptions || []).length > 0 && (
          <MultiSelect
            search
            label={t('Agent')}
            options={agentOptions}
            selectedValues={filterCondition.agentIds}
            onChange={changeAgent}
          />
        )}

        <Modal.Box>
          {paymentTypeOptions.map((option) => (
            <Checkbox
              key={option.value}
              label={option.text}
              checked={filterCondition.paymentTypes.includes(option.value)}
              onChange={() => {
                filterCondition.paymentTypes.includes(option.value)
                  ? changePaymentTypes(
                      filterCondition.paymentTypes.filter(
                        (p) => p !== option.value
                      )
                    )
                  : changePaymentTypes([
                      ...filterCondition.paymentTypes,
                      option.value,
                    ]);
              }}
            />
          ))}
        </Modal.Box>

        {showDepositDate && (
          <Modal.Box>
            <DateRangeInput
              label={t('Deposit Date')}
              fromDate={filterCondition.transferDateFrom}
              onChangeFromDate={transferDateDateFromChangeHandler}
              toDate={filterCondition.transferDateTo}
              onChangeToDate={transferDateDateToChangeHandler}
            />
          </Modal.Box>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
          }}
        >
          {t('Filter')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
