import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import styles from 'client/pages/v3/FlexibleDashboard/Chart/CustomTooltip.module.css';
import type { GraphDataItem } from 'client/libraries/util/reservationReport';
import { formattedAmount } from 'client/libraries/util/reservationReport';

type Props = {
  data: GraphDataItem[][];
  keys: Key[];
  names: Record<string, string>;
  currencyCode: string | null;
  dataTypeName: string;
};

type CustomData = {
  [x: string]: string | number | undefined;
  baseRangeName: string | number;
  compareRangeName: string | number | undefined;
}[];

export type Key = {
  data: string;
  color: string;
};

const CustomTooltip = ({ payload, dataTypeName, currencyCode }: any) => {
  return (
    <div className={styles['c-tooltip']}>
      <div className={styles['c-tooltip__header']}>
        <div className={styles['c-tooltip__header__ttl']}>{dataTypeName}</div>
      </div>
      <div className={styles['c-tooltip__body']}>
        {payload.length > 0 && (
          <>
            <div
              className={styles['c-tooltip__body__item']}
              style={{ color: payload[0].color }}
            >
              {payload[0].payload['baseRangeName']}:{' '}
              {formattedAmount(payload[0].value, currencyCode)}
            </div>

            {payload[1] && (
              <div
                className={styles['c-tooltip__body__item']}
                style={{ color: payload[1].color }}
              >
                {payload[1].payload['compareRangeName']}:{' '}
                {formattedAmount(payload[1].value, currencyCode)}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const ReservationReportLineChartPane = ({
  data,
  keys,
  names,
  currencyCode,
  dataTypeName,
}: Props) => {
  const customData: CustomData = data[0].map((item, index) => {
    return {
      baseRangeName: item.name,
      compareRangeName: data.length > 1 ? data[1][index].name : undefined,
      [keys[0]?.data]: item.total,
      [keys[1]?.data]: data.length > 1 ? data[1][index]?.total : undefined,
    };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={customData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tickFormatter={(value) => {
            return moment(value).format('MM/DD');
          }}
          dataKey="baseRangeName"
          tick={{ fill: '#A1A1AA', fontSize: '12px' }}
          tickLine={{ stroke: '#A1A1AA' }}
          axisLine={{ stroke: '#A1A1AA' }}
        />
        <YAxis
          tickFormatter={(value) => {
            return formattedAmount(value, currencyCode);
          }}
          tick={{ fill: '#A1A1AA', fontSize: '12px' }}
          tickLine={{ stroke: '#A1A1AA' }}
          axisLine={{ stroke: '#A1A1AA' }}
          allowDecimals={false}
        />
        <Tooltip
          content={
            <CustomTooltip
              dataTypeName={dataTypeName}
              currencyCode={currencyCode}
            />
          }
        />
        <Legend
          wrapperStyle={{
            fontSize: '12px',
          }}
        />
        {keys.map((key, idx) => {
          return (
            <Line
              legendType={'circle'}
              key={idx}
              dataKey={key.data}
              stroke={key.color}
              {...{ connectNull: true }}
              activeDot={{
                r: 8,
              }}
              strokeWidth={3}
              name={names[key.color]}
              type="monotone"
              strokeDasharray={idx === 0 ? undefined : '3 1'}
              dot={idx === 0 ? { fill: key.color, r: 4 } : false}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
