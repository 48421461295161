import { v4 as uuidv4 } from 'uuid';

import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  language: Swagger.SourceLanguage;
  title: string;
  description: string;
  status: Swagger.GuidancePageStatus;
  items: GuidancePageItem[];
  thumbnailUrl: string;
  tags: string[];
  productIds: string[];
  stampRallyId: string;
}

export interface AutoplayMedia {
  mediaUrl: string;
  originalFilename: string;
}

export interface GuidancePageItem {
  key: string;
  title: string;
  description: string;
  body: any;
  location: GuidancePageLocation | null;
  couponIds: string[];
  thumbnailUrl: string;
  shouldUseStampRally: boolean;
  stampRallyLabel: string;
  productId: string;
  itemType: 'NORMAL' | 'PRODUCT';
  stampImageUrl: string;
  editorJsIsLoading?: boolean;
  shouldUseAutoplayMediaItem: boolean;
  autoplayMedia?: {
    mediaUrl: string;
    originalFilename: string;
  };
  autoplayMediaItemActivationDistance: number;
}

export interface GuidancePageLocation {
  name: string;
  latitude: number;
  longitude: number;
  activationDistance: number;
}

export const getInitialValues = (page?: Swagger.GuidancePage): FormValues => {
  return {
    language: page?.language || 'EN_US',
    title: page?.title ?? '',
    description: page?.description ?? '',
    status: page?.status || 'GUIDANCE_PAGE_INACTIVE',
    items: page ? getInitialItems(page) : [],
    productIds: page?.product_ids ?? [],
    thumbnailUrl: page?.thumbnail_url ?? '',
    tags: page?.tags ?? [],
    stampRallyId: page?.stamp_rally_id ?? '',
  };
};

const getInitialItems = (page: Swagger.GuidancePage): GuidancePageItem[] => {
  return (page.items ?? []).map((item) => ({
    key: item.key ?? uuidv4(),
    title: item.title ?? '',
    description: item.description ?? '',
    body: JSON.parse(item?.body || '{}'),
    location: getInitialLocation(item),
    couponIds: item.coupon_ids ?? [],
    thumbnailUrl: item.thumbnail_url ?? '',
    shouldUseStampRally: item.should_use_stamp_rally ?? false,
    stampRallyLabel: item.stamp_rally_label ?? '',
    productId: item.product_id ?? '',
    itemType: item.type ?? 'NORMAL',
    stampImageUrl: item.stamp_image_url ?? '',
    shouldUseAutoplayMediaItem: Boolean(item.autoplay_media_item) ?? false,
    autoplayMedia: item.autoplay_media_item?.media_url
      ? {
          mediaUrl: item.autoplay_media_item.media_url,
          originalFilename: item.autoplay_media_item.original_filename ?? '',
        }
      : undefined,
    autoplayMediaItemActivationDistance:
      item.autoplay_media_item?.activation_distance ?? 100,
  }));
};

const getInitialLocation = (
  page: Swagger.GuidancePageItem
): GuidancePageLocation | null => {
  if (!page.location) {
    return null;
  }

  return {
    name: page.location?.location_name ?? '',
    latitude: page.location?.latitude ?? 0,
    longitude: page.location?.longitude ?? 0,
    activationDistance: page.location?.page_activation_range ?? 0,
  };
};

const getMediaType = (filename: string): 'AUDIO' | 'VIDEO' => {
  if (filename.toLowerCase().endsWith('mp3')) {
    return 'AUDIO';
  }

  return 'VIDEO';
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.GuidancePageParams => {
  return {
    language: values.language,
    title: values.title,
    description: values.description,
    status: values.status,
    items: values.items.map((item) => ({
      key: item.key,
      title: item.title,
      description: item.description,
      body: JSON.stringify(item.body),
      location: item.location
        ? {
            location_name: item.location.name,
            latitude: item.location.latitude,
            longitude: item.location.longitude,
            page_activation_range: item.location.activationDistance,
          }
        : undefined,
      coupon_ids: item.couponIds,
      thumbnail_url: item.thumbnailUrl,
      should_use_stamp_rally: item.shouldUseStampRally,
      stamp_rally_label: item.stampRallyLabel,
      product_id: item.productId,
      type: item.itemType,
      stamp_image_url: item.stampImageUrl,
      autoplay_media_item: item.shouldUseAutoplayMediaItem
        ? {
            media_type: getMediaType(item.autoplayMedia?.mediaUrl || ''),
            media_url: item.autoplayMedia?.mediaUrl || '',
            original_filename: item.autoplayMedia?.originalFilename || '',
            activation_distance: item.autoplayMediaItemActivationDistance,
          }
        : undefined,
    })),
    thumbnail_url: values.thumbnailUrl,
    tags: values.tags,
    product_ids: values.productIds,
    stamp_rally_id: values.stampRallyId,
  };
};
