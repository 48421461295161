import * as React from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { Reservation } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import {
  Button,
  TextArea,
  DateTimeInput,
  FieldWrapper,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import { operationAllowed } from 'shared/models/access';
import { updateReservation } from 'client/actions/reservations';

type Props = {
  open: boolean;
  onClose: () => void;
  reservation: Reservation;
};

export const EditFixedExpirationDateTimeModal = ({
  open,
  onClose,
  reservation,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);

  const currentExpirationDateTime = moment.tz(
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
      reservation?.checkin_info?.expiration_date_time_utc,
    activeUserOrganization?.default_timezone || 'UTC'
  );

  const [patch, setPatch] = React.useState({
    agent_notes: reservation.agent_notes,
    supplier_notes: reservation.supplier_notes,
    supplier_internal_notes: reservation.supplier_internal_notes,
    supplier_internal_notes_for_dispatch:
      reservation.supplier_internal_notes_for_dispatch,
    checkin_fixed_expiration_date_time_utc: {
      value:
        reservation?.checkin_info?.fixed_expiration_date_time_utc ||
        reservation?.checkin_info?.expiration_date_time_utc ||
        moment().utc().format(),
    },
  });

  React.useEffect(() => {
    setPatch({
      agent_notes: reservation.agent_notes,
      supplier_notes: reservation.supplier_notes,
      supplier_internal_notes: reservation.supplier_internal_notes,
      supplier_internal_notes_for_dispatch:
        reservation.supplier_internal_notes_for_dispatch,
      checkin_fixed_expiration_date_time_utc: {
        value:
          reservation?.checkin_info?.fixed_expiration_date_time_utc ||
          reservation?.checkin_info?.expiration_date_time_utc ||
          moment().utc().format(),
      },
    });
  }, [reservation]);

  const currentStatus = getCurrentStatus(reservation);

  const userCanEditAgentNotes =
    operationAllowed(activeUser, 'write', 'reservationAgentNotes') &&
    !isTerminalReservationStatus(currentStatus);
  const userCanEditSupplierNotes = operationAllowed(
    activeUser,
    'write',
    'reservationSupplierNotes'
  );
  return (
    <Modal
      open={open}
      title={t('Update')}
      onClose={() => {
        onClose();
      }}
    >
      <Modal.Content>
        <Modal.Box>
          <FieldWrapper label={t('Current expiration date')}>
            {currentExpirationDateTime.format('YYYY-MM-DD HH:mm')}
          </FieldWrapper>
        </Modal.Box>
        <Modal.Box>
          <FieldWrapper label={t('Expiration date after the change')}>
            <Box display="flex" mr={1} ml={1}>
              <div style={{ width: '150px' }}>
                <DateTimeInput
                  value={moment.tz(
                    patch['checkin_fixed_expiration_date_time_utc'].value,
                    activeUserOrganization?.default_timezone || 'UTC'
                  )}
                  onChange={(date) => {
                    const newDate = moment
                      .tz(
                        patch['checkin_fixed_expiration_date_time_utc'].value,
                        activeUserOrganization?.default_timezone || 'UTC'
                      )
                      .clone()
                      .set('year', date.year())
                      .set('month', date.month())
                      .set('date', date.date());

                    setPatch({
                      ...patch,
                      checkin_fixed_expiration_date_time_utc: {
                        value: newDate.utc().format(),
                      },
                    });
                  }}
                  locale={i18n.language}
                />
              </div>

              <TimePicker
                allowEmpty={false}
                value={moment.tz(
                  patch['checkin_fixed_expiration_date_time_utc'].value,
                  activeUserOrganization?.default_timezone || 'UTC'
                )}
                onChange={(time) => {
                  if (!time) {
                    return;
                  }
                  const newDate = moment
                    .tz(
                      patch['checkin_fixed_expiration_date_time_utc'].value,
                      activeUserOrganization?.default_timezone || 'UTC'
                    )
                    .clone()
                    .set('hour', time.hour())
                    .set('minute', time.minute());
                  setPatch({
                    ...patch,
                    checkin_fixed_expiration_date_time_utc: {
                      value: newDate.utc().format(),
                    },
                  });
                }}
              />
            </Box>
          </FieldWrapper>
        </Modal.Box>

        {userCanEditAgentNotes && (
          <Modal.Box>
            <TextArea
              label={t('Remarks')}
              value={patch['agent_notes'] ?? ''}
              onChange={(_, { value }) => {
                setPatch({
                  ...patch,
                  agent_notes: value,
                });
              }}
            />
          </Modal.Box>
        )}

        {userCanEditSupplierNotes && (
          <>
            <Modal.Box>
              <TextArea
                label={t('Replies')}
                value={patch['supplier_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_notes: value,
                  });
                }}
              />
            </Modal.Box>

            <Modal.Box>
              <TextArea
                label={t('Memo')}
                value={patch['supplier_internal_notes'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes: value,
                  });
                }}
              />
            </Modal.Box>

            <Modal.Box>
              <TextArea
                label={t('Internal Note')}
                value={patch['supplier_internal_notes_for_dispatch'] ?? ''}
                onChange={(_, { value }) => {
                  setPatch({
                    ...patch,
                    supplier_internal_notes_for_dispatch: value,
                  });
                }}
              />
            </Modal.Box>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            dispatch(updateReservation(reservation.id, patch));
            onClose();
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
