import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from 'rc-color-picker';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';

import baseStyles from 'client/base.module.css';
import type { ReduxState } from 'client/reducers';
import type { SourceLanguage } from 'shared/models/swagger';
import { Box } from 'client/components/Box/Box';
import { Button, Input, Radio, FieldWrapper } from 'client/components/Form';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Loading } from 'client/pages/Loading';
import { Message } from 'client/components/Message/Message';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { SettingTranslationLanguageSelector } from 'client/components/SettingTranslationLanguageSelector/SettingTranslationLanguageSelector';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { updateOrganization } from 'client/actions/organizations';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import {
  updateTranslations,
  batchGetTranslations,
} from 'client/actions/translations';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';

import styles from './InstantWinSettings.module.css';
import {
  FormValues,
  getSettingsInitialValues,
  getInitialTranslationValues,
  convertSettingsFormValuesToOrganizationPatch,
} from './FormValues';

import 'rc-color-picker/assets/index.css';

const focusOnError: any = createDecorator();

export const InstantWinSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollTo = React.useContext(ScrollToContext);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const apiTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const [showTranslationMode, setShowTranslationMode] =
    React.useState<boolean>(false);
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    React.useState<SourceLanguage>(
      getBookingWidgetPmpSupportLanguages(activeUserOrganization).find(
        (supportedLanguage) => supportedLanguage !== defaultLanguage
      ) || 'EN_US'
    );

  const getBorderRadiusValue = (buttonStyle: string) => {
    if (buttonStyle === 'ROUND') {
      return '50%';
    } else if (buttonStyle === 'ROUNDED_CORNERS_LARGE') {
      return '24px';
    } else if (buttonStyle === 'ROUNDED_CORNERS_SMALL') {
      return '12px';
    }
    return '';
  };

  React.useEffect(() => {
    if (!showTranslationMode) {
      return;
    }

    if (!activeUserOrganization?.instant_win_settings?.button_text) {
      return;
    }

    const texts = [activeUserOrganization?.instant_win_settings?.button_text];

    dispatch(batchGetTranslations(texts));
  }, [showTranslationMode, activeUserOrganization]);

  const initialValues = React.useMemo(() => {
    return {
      ...getSettingsInitialValues(activeUserOrganization),
      ...getInitialTranslationValues(
        defaultLanguage,
        apiTranslations,
        translationTargetLanguage
      ),
    };
  }, [activeUserOrganization]);

  return (
    <>
      <SettingTranslationLanguageSelector
        showTranslationMode={showTranslationMode}
        onShowTranslationModeChange={setShowTranslationMode}
        translationTargetLanguage={translationTargetLanguage}
        onTranslationTargetLanguageChange={setTranslationTargetLanguage}
      />
      <Form
        onSubmit={async (values: FormValues) => {
          const orgId = activeUserOrganization?.id || '';

          const promises = [
            dispatch(
              updateOrganization(
                orgId,
                'SUPPLIER',
                convertSettingsFormValuesToOrganizationPatch(values)
              )
            ),
          ];

          if (showTranslationMode) {
            promises.push(
              dispatch(
                updateTranslations(
                  values.translations.map((translation: any) => ({
                    source_language: defaultLanguage,
                    [defaultLanguage.toLowerCase()]: translation.source,
                    [translationTargetLanguage.toLowerCase()]:
                      translation.target,
                  }))
                )
              ) as any
            );
          }

          try {
            await Promise.all(promises);
            scrollTo(0, 0);
            setSaveSucceeded(true);
          } catch (err) {
            return {
              [FORM_ERROR]: t('Save Failed'),
            };
          }
        }}
        decorators={[focusOnError]}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values, form }) => {
          React.useEffect(() => {
            let translations: { source: string; target: string }[] = [];

            if (defaultLanguage && translationTargetLanguage) {
              const defaultFieldName = defaultLanguage.toLowerCase();
              const translationFieldName =
                translationTargetLanguage.toLowerCase();
              translations = apiTranslations.map((trans: any) => ({
                source: trans[defaultFieldName],
                target: trans[translationFieldName],
              }));
            }

            form.change('translations', translations);
          }, [
            defaultLanguage,
            apiTranslations,
            translationTargetLanguage,
            showTranslationMode,
          ]);
          return (
            <form onSubmit={handleSubmit}>
              <div className={baseStyles['base-main__body__box']}>
                <div className={baseStyles['base-main__body__box__body']}>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                  <FormTableBox>
                    <table>
                      <tbody>
                        <tr>
                          <th>{t('Display')}</th>
                          <td>
                            <Box mt={2}>
                              <FieldWrapper label={t('Position')}>
                                <Field name="buttonPosition">
                                  {({ input }) => (
                                    <>
                                      <Box display="flex">
                                        <div className={styles['form-body']}>
                                          <Radio
                                            label={t('Left Bottom')}
                                            checked={
                                              input.value === 'LEFT_BOTTOM'
                                            }
                                            onChange={() =>
                                              input.onChange('LEFT_BOTTOM')
                                            }
                                          />
                                        </div>
                                        <div className={styles['form-body']}>
                                          <Radio
                                            label={t('Right Bottom')}
                                            checked={
                                              input.value === 'RIGHT_BOTTOM'
                                            }
                                            onChange={() =>
                                              input.onChange('RIGHT_BOTTOM')
                                            }
                                          />
                                        </div>
                                      </Box>
                                    </>
                                  )}
                                </Field>
                              </FieldWrapper>
                            </Box>

                            <Box mt={2}>
                              <FieldWrapper label={t('Button Text')}>
                                <TranslatedField name="buttonText">
                                  {({
                                    input,
                                    translationInput,
                                    meta: { error, touched },
                                  }) => (
                                    <Box display="flex" width="100%">
                                      <div
                                        style={{
                                          width: '50%',
                                        }}
                                      >
                                        <p>
                                          {' '}
                                          <Input
                                            {...input}
                                            {...{
                                              height: 100,
                                            }}
                                            error={touched && error}
                                          />{' '}
                                        </p>
                                      </div>
                                      {showTranslationMode && (
                                        <div
                                          style={{
                                            width: '50%',
                                          }}
                                        >
                                          <p>
                                            {' '}
                                            <Input
                                              {...translationInput}
                                              {...{
                                                height: 100,
                                              }}
                                              placeholder={getLanguageName(
                                                uppercaseIsoToLowercaseIso[
                                                  translationTargetLanguage
                                                ],
                                                t
                                              )}
                                              error={touched && error}
                                            />{' '}
                                          </p>
                                        </div>
                                      )}
                                    </Box>
                                  )}
                                </TranslatedField>
                              </FieldWrapper>
                            </Box>
                            <Box mt={2}>
                              <FieldWrapper label={t('Text Color')}>
                                <Field name="buttonTextColor">
                                  {({ input }) => (
                                    <ColorPicker
                                      color={input.value}
                                      onChange={({ color }: any) =>
                                        input.onChange(color)
                                      }
                                    />
                                  )}
                                </Field>
                              </FieldWrapper>
                            </Box>
                            <Box mt={2}>
                              <FieldWrapper label={t('Button Color')}>
                                <Field name="buttonColor">
                                  {({ input }) => (
                                    <ColorPicker
                                      color={input.value}
                                      onChange={({ color }: any) =>
                                        input.onChange(color)
                                      }
                                    />
                                  )}
                                </Field>
                              </FieldWrapper>
                            </Box>
                            <Box mt={2}>
                              <FieldWrapper label={t('Button Style')}>
                                <Field name="buttonStyle">
                                  {({ input }) => (
                                    <Box display="flex">
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t('Square Corners')}
                                          checked={
                                            input.value === 'SQUARE_CORNERS'
                                          }
                                          onChange={() => {
                                            input.onChange('SQUARE_CORNERS');
                                            form.change('buttonWidth', 160);
                                            form.change('buttonHeight', 40);
                                          }}
                                        />
                                      </div>
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t('Rounded Corners (small)')}
                                          checked={
                                            input.value ===
                                            'ROUNDED_CORNERS_SMALL'
                                          }
                                          onChange={() => {
                                            input.onChange(
                                              'ROUNDED_CORNERS_SMALL'
                                            );
                                            form.change('buttonWidth', 160);
                                            form.change('buttonHeight', 40);
                                          }}
                                        />
                                      </div>
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t('Rounded Corners (large)')}
                                          checked={
                                            input.value ===
                                            'ROUNDED_CORNERS_LARGE'
                                          }
                                          onChange={() => {
                                            input.onChange(
                                              'ROUNDED_CORNERS_LARGE'
                                            );
                                            form.change('buttonWidth', 160);
                                            form.change('buttonHeight', 40);
                                          }}
                                        />
                                      </div>
                                      <div className={styles['form-body']}>
                                        <Radio
                                          label={t('Round')}
                                          checked={input.value === 'ROUND'}
                                          onChange={() => {
                                            input.onChange('ROUND');
                                            form.change('buttonWidth', 50);
                                            form.change('buttonHeight', 50);
                                          }}
                                        />
                                      </div>
                                    </Box>
                                  )}
                                </Field>
                              </FieldWrapper>
                            </Box>
                            <Box mt={2}>
                              <FieldWrapper label={t('Button Size')}>
                                <Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mt={2}
                                    mb={2}
                                  >
                                    <span style={{ width: '50px' }}>
                                      {t('width')}
                                    </span>
                                    <Field name="buttonWidth">
                                      {({ input }) => (
                                        <Input
                                          value={input.value}
                                          onChange={input.onChange}
                                        />
                                      )}
                                    </Field>
                                    <span style={{ marginLeft: '5px' }}>
                                      {t('px')}
                                    </span>
                                  </Box>
                                  {values.buttonStyle !== 'ROUND' && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mt={2}
                                      mb={2}
                                    >
                                      <span style={{ width: '50px' }}>
                                        {t('height')}
                                      </span>
                                      <Field name="buttonHeight">
                                        {({ input }) => (
                                          <Input
                                            value={input.value}
                                            onChange={input.onChange}
                                          />
                                        )}
                                      </Field>
                                      <span style={{ marginLeft: '5px' }}>
                                        {t('px')}
                                      </span>
                                    </Box>
                                  )}
                                </Box>
                              </FieldWrapper>
                            </Box>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Sample')}</th>
                          <td>
                            <div className={styles['form-title']}>
                              {t('Button Display')}
                            </div>
                            <Box display="flex" alignItems="center">
                              <button
                                type="button"
                                style={{
                                  height: `${values.buttonHeight}px`,
                                  width: `${values.buttonWidth}px`,
                                  fontWeight: 'bold',
                                  color: `${values.buttonTextColor}`,
                                  fontSize: '18px',
                                  backgroundColor: `${values.buttonColor}`,
                                  borderRadius: `${getBorderRadiusValue(
                                    values.buttonStyle as string
                                  )}`,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  console.log('clicked');
                                }}
                              >
                                {values.buttonText || t('Button Text')}
                              </button>
                            </Box>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormTableBox>
                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    <Button
                      type="submit"
                      style="blue"
                      size="small"
                      loading={submitting}
                    >
                      {t('Save Settings')}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
      {loading && <Loading />}
    </>
  );
};
