import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';

import { CustomerEmailHistory } from './CustomerEmailHistory';
import { CustomerReviews } from './CustomerReviews';
import { CustomerWaivers } from './CustomerWaivers';

export const CustomerTab = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<
    'EMAIL' | 'REVIEW' | 'WAIVER'
  >('EMAIL');

  return (
    <div
      className={clsx(
        componentStyles['c-tab-box'],
        baseStyles['scroll-target-pane']
      )}
    >
      <ul className={clsx(componentStyles['c-tab-box__tab'])}>
        <li
          className={clsx(
            activeTab === 'EMAIL' ? componentStyles['is-active'] : ''
          )}
          onClick={() => {
            setActiveTab('EMAIL');
          }}
        >
          <a>{t('Email History')}</a>
        </li>

        <li
          className={clsx(
            activeTab === 'REVIEW' ? componentStyles['is-active'] : ''
          )}
          onClick={() => {
            setActiveTab('REVIEW');
          }}
        >
          <a>{t('Reviews')}</a>
        </li>
        <li
          className={clsx(
            activeTab === 'WAIVER' ? componentStyles['is-active'] : ''
          )}
          onClick={() => {
            setActiveTab('WAIVER');
          }}
        >
          <a>{t('Waivers')}</a>
        </li>
      </ul>

      <CustomerEmailHistory active={activeTab === 'EMAIL'} />
      <CustomerReviews active={activeTab === 'REVIEW'} />
      <CustomerWaivers active={activeTab === 'WAIVER'} />
    </div>
  );
};
