import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ToggleButton, Button } from 'client/components/Form';
import { changeUserAttributes } from 'client/actions/user';
import type { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import { Box } from 'client/components/Box/Box';

import styles from './Settings.module.css';

export const MultiFactorAuthSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const attrMap = useSelector((state: ReduxState) => state.user.attrMap);
  const error = useSelector(
    (state: ReduxState) => state.user.changingAttrError
  );
  const changingAttr = useSelector(
    (state: ReduxState) => state.user.changingAttr
  );

  const [isMfaEnabled, setIsMfaEnabled] = React.useState(
    attrMap['custom:email_mfa'] === 'true'
  );
  const [loading, setLoading] = React.useState(false);

  const [changeResult, setChangeResult] = React.useState<
    'SUCCEEDED' | 'FAILED' | null
  >(null);

  React.useEffect(() => {
    if (loading && !changingAttr) {
      setChangeResult(error ? 'FAILED' : 'SUCCEEDED');
      setLoading(false);
    }
  }, [changingAttr, error]);

  return (
    <div>
      <ToggleButton
        label={t('Enable two factor authentication')}
        checked={isMfaEnabled}
        onChange={() => {
          setIsMfaEnabled(!isMfaEnabled);
        }}
      />
      <Box mt={2}>
        {t(
          'When two factor authentication is enabled, we will send you an email with a passcode after successfully login with your ID and password. You will be required to enter passcode to complete login.'
        )}
      </Box>
      {changeResult === 'FAILED' && (
        <Message error header={t('Update failed')} content={error} />
      )}
      {changeResult === 'SUCCEEDED' && (
        <Message success header={t('Update succeeded')} />
      )}
      <div className={styles['button-box']}>
        <Button
          style="blue"
          size="middle"
          loading={changingAttr}
          disabled={changingAttr}
          onClick={async () => {
            try {
              setLoading(true);
              await dispatch(
                changeUserAttributes({
                  'custom:email_mfa': isMfaEnabled ? 'true' : 'false',
                })
              );
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
