import * as React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'client/components/Divider/Divider';
import { ToggleButton } from 'client/components/Form';
import { to_language } from 'client/libraries/i18n';
import { EmbeddedCalendar } from 'client/pages/BookingWidget/EmbeddedCalendar/EmbeddedCalendar';
import { getBookingWidgetEmbeddedCalendarUrl } from 'client/libraries/util/getBookingWidgetEmbeddedCalendarUrl';
import { getBookingWidgetSingleProductStandaloneUrl } from 'client/libraries/util/getBookingWidgetSingleProductStandaloneUrl';
import baseStyles from 'client/base.module.css';

import styles from './EmbedCalendarTab.module.css';

type Props = {
  bookingWidgetApiKey: string;
  selectedProductId: string;
  widgetLanguage: string;
};

export const BookingWidgetEmbeddedSingleProductCalendar = ({
  selectedProductId,
  widgetLanguage,
  bookingWidgetApiKey,
}: Props) => {
  const { t } = useTranslation();

  const [
    showCalendarCustomizationOptions,
    setShowCalendarCustomizationOptions,
  ] = React.useState<boolean>(false);
  const [calendarDesignType, setCalendarDesignType] = React.useState<
    'BASIC' | 'BASIC_WITH_PRICES'
  >('BASIC');

  const embedCalendarUrl = getBookingWidgetEmbeddedCalendarUrl(
    bookingWidgetApiKey,
    selectedProductId,
    calendarDesignType,
    widgetLanguage
  );
  const singleProductStandaloneUrl = getBookingWidgetSingleProductStandaloneUrl(
    bookingWidgetApiKey,
    selectedProductId,
    widgetLanguage
  );

  const widgetTextT = widgetLanguage ? to_language(widgetLanguage) : t;

  if (!selectedProductId) {
    return null;
  }

  return (
    <>
      <h3>{t('Standalone Link')}</h3>
      <Divider />
      <List relaxed>
        <List.Item>
          <List.Content>
            <div className={styles['booking-link']}>
              <a
                href={singleProductStandaloneUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {widgetTextT('Book')}
              </a>
            </div>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>{t('HTML')}</List.Header>
          <List.Content>
            <pre
              className={styles['code-block']}
            >{`<a href="${singleProductStandaloneUrl}" target="_blank" rel="noopener noreferrer">
${widgetTextT('Book')}
</a>`}</pre>
          </List.Content>
        </List.Item>
      </List>
      <h3>{t('Embedded Calendar')}</h3>
      <div className={styles['input-box']}>
        <ToggleButton
          label={t('Customize')}
          checked={showCalendarCustomizationOptions}
          onChange={() => {
            setShowCalendarCustomizationOptions(
              !showCalendarCustomizationOptions
            );
          }}
        />
      </div>
      {showCalendarCustomizationOptions && (
        <div className={baseStyles['base-form-box']}>
          <div className={baseStyles['base-form-box__header']}>
            {t('Calendar Design')}
          </div>
          <div className={baseStyles['base-form-box__body']}>
            <div className={baseStyles['base-form-radio']}>
              <label>
                <input
                  type="radio"
                  name="BASIC"
                  checked={calendarDesignType === 'BASIC'}
                  onChange={() => setCalendarDesignType('BASIC')}
                />
                <p></p>
                {t('Basic')}
              </label>
            </div>
            <div className={baseStyles['base-form-radio']}>
              <label>
                <input
                  type="radio"
                  name="BASIC_WITH_PRICES"
                  checked={calendarDesignType === 'BASIC_WITH_PRICES'}
                  onChange={() => setCalendarDesignType('BASIC_WITH_PRICES')}
                />
                <p></p>
                {t('Basic + Show Prices for Selected Date')}
              </label>
            </div>
          </div>
        </div>
      )}
      <List relaxed>
        <List.Item>
          <List.Content>
            <EmbeddedCalendar
              apiKey={bookingWidgetApiKey}
              productId={selectedProductId}
              designType={calendarDesignType}
              language={widgetLanguage}
            />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Header>{t('Code')}</List.Header>
          <List.Content>
            <pre
              className={styles['code-block']}
            >{`<div id="nutmeg-calendar-${selectedProductId}">
</div>
<script type="text/javascript">
  /*<![CDATA[*/
  (function () {
      var scriptUrl = 'https://assets.ntmg.com/scripts/falcon-embed/v1/index.js';
      if (window.NutmegCalendar) {
          NutmegCalendarInit();
      } else {
          loadScriptAsync();
      }
      function loadScriptAsync() {
          var script = document.createElement('script');
          script.src = scriptUrl;
          script.async = true;
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
          script.onload = NutmegCalendarInit;
      }
      function NutmegCalendarInit() {
          window.NutmegCalendar.buildEmbed('${selectedProductId}', '${embedCalendarUrl}')
      }
  })();
  /*]]>*/
</script>`}</pre>
          </List.Content>
        </List.Item>
      </List>
    </>
  );
};
