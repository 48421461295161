import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateManifestCustomViews } from 'client/actions/manifestSettings';
import { manifestCustomViewsSelector } from 'client/reducers/manifestSettings';
import { ToggleableDndList } from 'client/components/ToggleableDndList/ToggleableDndList';
import {
  useAllManifestColumns,
  getColumnHeaderText,
} from 'client/reducers/manifestDefaults';
import { Modal } from 'client/components/Modal/Modal';
import { Input, FieldWrapper, Button, Select } from 'client/components/Form';
import type { ReservationColumn } from 'shared/models/swagger';

type Props = {
  existingView?: {
    key?: string;
    order_by?: ReservationColumn[];
    columns?: ReservationColumn[];
  } | null;
  open: boolean;
  onClose: () => void;
};
const sortOptions: {
  value: ReservationColumn;
  text: string;
}[] = [
  {
    value: 'SUPPLIER_REFERENCE',
    text: 'Confirmation Number',
  },
  {
    value: 'AGENT_REFERENCE',
    text: 'Application Number',
  },
  {
    value: 'PICKUP_CHECKIN_TIME',
    text: 'Pickup/Checkin time',
  },
  {
    value: 'SUPPLIER_PRODUCT_REFERENCE',
    text: 'Product Code',
  },
  {
    value: 'GUEST_DISPLAY_NAME',
    text: 'Customer',
  },
  {
    value: 'GIVEN_NAME',
    text: 'Given Name',
  },
  {
    value: 'FAMILY_NAME',
    text: 'Family Name',
  },
];
export const EditCustomViewModal = ({ existingView, open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [key, setKey] = React.useState<string>('');
  const [columns, setColumns] = React.useState<ReservationColumn[]>([]);
  const [orderBy, setOrderBy] = React.useState<ReservationColumn[]>([]);
  const existingCustomViews = useSelector(manifestCustomViewsSelector);
  const allManifestColumns = useAllManifestColumns();
  React.useEffect(() => {
    if (existingView) {
      setKey(existingView?.key || '');
      setOrderBy(existingView?.order_by || []);
      setColumns(existingView?.columns || []);
    } else {
      setKey('');
      setColumns([]);
    }
  }, [existingView]);
  const headerText = existingView
    ? t('Edit Manifest View')
    : t('Create New Manifest View');
  return (
    <Modal
      title={headerText}
      open={open}
      onClose={onClose}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
    >
      <Modal.Content>
        <Modal.Box>
          <Input
            disabled={Boolean(existingView)}
            label={t('Name')}
            value={key}
            onChange={(_, { value }) => setKey(value)}
          />
        </Modal.Box>

        <Modal.Box>
          <Select
            label={t('Sort')}
            options={sortOptions.map((option) => {
              return { ...option, text: t(option.text) };
            })}
            value={orderBy.length > 0 ? orderBy[0] : ''}
            onChange={(_, { value }) => {
              if (value) {
                setOrderBy([value as ReservationColumn]);
              }
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <FieldWrapper label={t('Columns')}>
            <ToggleableDndList
              selectedItems={columns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
              setSelectedItems={(selectedItems) =>
                setColumns(selectedItems.map((i) => i.key))
              }
              candidateItems={allManifestColumns.map((col) => ({
                key: col,
                text: getColumnHeaderText(col, t),
              }))}
            />
          </FieldWrapper>
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Submit
          disabled={!key}
          onClick={() => {
            const newCustomViews = [
              ...existingCustomViews.filter((view) => view.key !== key),
              {
                key,
                columns,
                order_by: orderBy,
              },
            ];
            dispatch(updateManifestCustomViews(newCustomViews));
            onClose();
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
