import { SettingsHeader } from 'client/pages/v3/Settings/SettingsHeader';
import { SettingsBody } from 'client/pages/v3/Settings/SettingsBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const Settings = () => {
  return (
    <V3Page>
      <SettingsHeader />
      <SettingsBody />
    </V3Page>
  );
};
