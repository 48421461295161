import clsx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { s3MediaStorage } from 'client/libraries/s3';
import { getRandomFilenameForUpload } from 'client/libraries/util/getRandomFilenameForUpload';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { config } from 'client/config';
import styles from 'client/components/SingleImageInput/SingleImageInput.module.css';
import baseStyles from 'client/base.module.css';

type Props = {
  onUploadFinished?: (localFilename: string, objectUrl: string) => void;
  acceptedFileTypes: string[];
  getFileSavePath: (localFilename: string) => string;
  uploadWithOriginalFilename?: boolean;
  initialValue?: string;
};
export const SingleImageInput = ({
  onUploadFinished,
  acceptedFileTypes,
  getFileSavePath,
  initialValue,
  uploadWithOriginalFilename,
}: Props) => {
  const { t } = useTranslation();
  const [selectedLocalFile, setSelectedLocalFile] = React.useState<File | null>(
    null
  );
  const [showPreview, setShowPreview] = React.useState<boolean>(
    Boolean(initialValue)
  );
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const organizationId = activeUserOrganization?.id || '';
  const fileSavePath = React.useMemo(
    () =>
      selectedLocalFile
        ? getFileSavePath(
            uploadWithOriginalFilename
              ? selectedLocalFile?.name
              : getRandomFilenameForUpload(selectedLocalFile)
          )
        : '',
    [selectedLocalFile]
  );

  const getFileUrl = () => {
    if (!organizationId) {
      return '';
    }

    if (fileSavePath) {
      return `${config.s3Config.mediaPathRoot}/${fileSavePath}`;
    }

    return initialValue;
  };

  const uploadFile = async () => {
    if (!selectedLocalFile || !organizationId) return;
    setUploadInProgress(true);
    await s3MediaStorage.put(fileSavePath, selectedLocalFile);
    const url = await s3MediaStorage.get(fileSavePath);
    const newUploadedFileUrl = url.split('?')[0];
    setShowPreview(true);
    setUploadInProgress(false);

    if (onUploadFinished) {
      onUploadFinished(selectedLocalFile.name, newUploadedFileUrl);
    }
  };

  const fileUrl = getFileUrl();
  return (
    <div>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Loader active={uploadInProgress}>{t('Uploading')}</Loader>
      </div>
      <div className={styles['page-productsRegist__file']}>
        <p
          className={clsx(
            baseStyles['base-btn'],
            baseStyles['blue'],
            baseStyles['small']
          )}
          onClick={uploadFile}
        >
          {t('Upload')}
        </p>
        <div>
          <label>
            <input
              type="file"
              accept={acceptedFileTypes.join(',')}
              name="image-input"
              onChange={(e) => {
                if (e.target.files) {
                  setSelectedLocalFile(e.target.files[0]);
                }

                setShowPreview(false);
              }}
            />
            <p
              className={clsx(
                baseStyles['base-btn'],
                baseStyles['gray'],
                baseStyles['small']
              )}
            >
              {t('Choose File')}
            </p>
          </label>
          {selectedLocalFile && <span>{selectedLocalFile.name}</span>}
        </div>
      </div>
      <div className={styles['page-productsRegist__preview']}>
        {showPreview && fileUrl ? <img src={fileUrl} /> : <p>{t('Preview')}</p>}
      </div>
    </div>
  );
};
