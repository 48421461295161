import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import clsx from 'clsx';

import { fetchProducts } from 'client/actions/products';
import { ReduxState } from 'client/reducers';
import { Loading } from 'client/pages/Loading';
import {
  createMarketingAutomationContent,
  fetchMarketingAutomationContent,
  updateMarketingAutomationContent,
} from 'client/actions/marketingAutomationContents';

import {
  convertFormValuesToSwagger,
  convertSwaggerToFormValues,
  FormValues,
} from './formValues';
import { TemplateSelect } from './TemplateSelect';
import { ContentTypeSelect } from './ContentTypeSelect';
import { ContentEditor } from './ContentEditor';
import styles from './MarketingAutomationContentEditor.module.css';

type Step = 'CONTENT_TYPE_SELECT' | 'TEMPLATE_SELECT' | 'MESSAGE';

export const MarketingAutomationContentEditor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t]);
  const [step, setStep] = React.useState<Step>(
    id ? 'MESSAGE' : 'CONTENT_TYPE_SELECT'
  );

  const existingContent = useSelector((state: ReduxState) =>
    state.marketingAutomationContents.all.find((n) => n.id === id)
  );

  React.useEffect(() => {
    if (id && !existingContent) {
      dispatch(fetchMarketingAutomationContent(id));
    }
  }, [id, existingContent]);

  const initialValues = React.useMemo(() => {
    if (existingContent) {
      return convertSwaggerToFormValues(existingContent);
    }

    return {
      productIds: [],
    };
  }, [existingContent]);

  if (id && !existingContent) {
    return <Loading />;
  }

  if (
    step === 'CONTENT_TYPE_SELECT' ||
    step === 'TEMPLATE_SELECT' ||
    step === 'MESSAGE'
  ) {
    return (
      <Form
        onSubmit={async (values: FormValues) => {
          if (step === 'CONTENT_TYPE_SELECT') {
            if (values.type === 'EMAIL') {
              setStep('TEMPLATE_SELECT');
              return;
            } else {
              setStep('MESSAGE');
              return;
            }
          }
          if (step === 'TEMPLATE_SELECT') {
            if (values.type === 'EMAIL') {
              setStep('MESSAGE');
              return;
            }
          }

          if (id) {
            await dispatch(
              updateMarketingAutomationContent(
                id,
                convertFormValuesToSwagger(values)
              )
            );
          } else {
            await dispatch(
              createMarketingAutomationContent(
                convertFormValuesToSwagger(values)
              )
            );
          }

          history.push('/marketingautomation/contents');
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, values }) => {
          return (
            <>
              {values.type === 'EMAIL' &&
                (step === 'TEMPLATE_SELECT' || step === 'MESSAGE') && (
                  <ul className={styles['steps-nav']}>
                    <li
                      className={clsx(
                        step === 'TEMPLATE_SELECT' && styles['is-active']
                      )}
                    >
                      STEP1<span> - {t('Select Template')}</span>
                    </li>
                    <li
                      className={clsx(
                        step === 'MESSAGE' && styles['is-active']
                      )}
                    >
                      STEP2<span> - {t('Compose Message')}</span>
                    </li>
                  </ul>
                )}
              <form onSubmit={handleSubmit}>
                {step === 'CONTENT_TYPE_SELECT' && <ContentTypeSelect />}
                {step === 'TEMPLATE_SELECT' && (
                  <TemplateSelect
                    onBackClick={() => setStep('CONTENT_TYPE_SELECT')}
                  />
                )}
                {step === 'MESSAGE' && (
                  <ContentEditor
                    onBackClick={() => {
                      if (values.type === 'EMAIL') {
                        setStep('TEMPLATE_SELECT');
                      } else {
                        setStep('CONTENT_TYPE_SELECT');
                      }
                    }}
                  />
                )}
              </form>
            </>
          );
        }}
      </Form>
    );
  }

  // We shouldn't reach here
  return null;
};
