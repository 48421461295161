import * as React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/Modal/Modal';
import {
  Select,
  DateRangeInput,
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  Checkbox,
} from 'client/components/Form';
import { updateCustomerSearchSettings } from 'client/actions/customers';
import { activeUserIsNutmegAdminSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { defaultSearchSettings } from 'client/reducers/customers';

import {
  getOrderByOptions,
  getOrderByValue,
  getNumOfReservationsOptions,
} from './utils';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CustomerSearchSettingsModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return { value: option.value, text: option.text };
  });

  const searchSettings = useSelector((state: ReduxState) => {
    return state.customers.searchSettings;
  });

  const [newSearchSettings, setNewSearchSettings] =
    React.useState(searchSettings);

  React.useEffect(() => {
    setNewSearchSettings(searchSettings);
  }, [searchSettings]);

  return (
    <Modal title={t('Search Members')} open={open} onClose={onClose}>
      <Modal.Content>
        <Modal.Box>
          <Select
            label={t('Order By')}
            options={getOrderByOptions(t)}
            value={getOrderByValue(newSearchSettings)}
            onChange={(_, { value }) => {
              const parts = (value as string).split('#');
              const orderBy = parts[0];
              const isAscending = parts[1] === 'ASC';

              setNewSearchSettings({
                ...newSearchSettings,
                orderBy,
                isAscending,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <DateRangeInput
            label={t('Registration Date')}
            fromDate={newSearchSettings.registrationDateFrom}
            onChangeFromDate={(date) => {
              setNewSearchSettings({
                ...newSearchSettings,
                registrationDateFrom: date,
              });
            }}
            toDate={newSearchSettings.registrationDateTo}
            onChangeToDate={(date) => {
              setNewSearchSettings({
                ...newSearchSettings,
                registrationDateTo: date,
              });
            }}
          />
          <DateRangeInput
            label={t('Last Participation Date')}
            fromDate={newSearchSettings.lastParticipationDateFrom}
            onChangeFromDate={(date) => {
              setNewSearchSettings({
                ...newSearchSettings,
                lastParticipationDateFrom: date,
              });
            }}
            toDate={newSearchSettings.lastParticipationDateTo}
            onChangeToDate={(date) => {
              setNewSearchSettings({
                ...newSearchSettings,
                lastParticipationDateTo: date,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <FieldWrapper label={t('Number of Reservations')}>
            <Modal.Box column="two">
              <Select
                options={getNumOfReservationsOptions(t)}
                value={newSearchSettings.reservationCountOperator}
                onChange={(_, { value }) => {
                  setNewSearchSettings({
                    ...newSearchSettings,
                    reservationCountOperator: value as string,
                  });
                }}
              />
              <Select
                options={[...Array(20).keys()].map((i) => ({
                  text: i.toString(),
                  value: i.toString(),
                }))}
                value={String(newSearchSettings.reservationCount)}
                onChange={(_, { value }) => {
                  setNewSearchSettings({
                    ...newSearchSettings,
                    reservationCount: Number(value as string),
                  });
                }}
              />
            </Modal.Box>
          </FieldWrapper>
        </Modal.Box>

        <Modal.Box>
          <MultiSelect
            search
            label={t('Product')}
            options={productOptions}
            selectedValues={newSearchSettings.productIds}
            onChange={({ value }: { value: any }) => {
              setNewSearchSettings({
                ...newSearchSettings,
                productIds: value as string[],
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Email')}
            value={newSearchSettings.email}
            onChange={(e) => {
              setNewSearchSettings({
                ...newSearchSettings,
                email: e.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Input
            label={t('Tags')}
            value={newSearchSettings.tags.join(' ')}
            onChange={(e) => {
              const trimmed = e.currentTarget.value.trim();
              if (!trimmed) {
                setNewSearchSettings({
                  ...newSearchSettings,
                  tags: [],
                });
                return;
              }
              setNewSearchSettings({
                ...newSearchSettings,
                tags: trimmed.split(' ').filter((tag: any) => tag.trim()),
              });
            }}
          />
        </Modal.Box>

        <Modal.Box column="two">
          <Input
            label={t('Given Name')}
            value={newSearchSettings.givenName}
            onChange={(event) => {
              setNewSearchSettings({
                ...newSearchSettings,
                givenName: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Family Name')}
            value={newSearchSettings.familyName}
            onChange={(event) => {
              setNewSearchSettings({
                ...newSearchSettings,
                familyName: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        <Modal.Box>
          <Checkbox
            label={t('Receive offer emails')}
            checked={newSearchSettings.onlyShouldReceiveSpecialEmailOffers}
            onChange={() => {
              setNewSearchSettings({
                ...newSearchSettings,
                onlyShouldReceiveSpecialEmailOffers:
                  !newSearchSettings.onlyShouldReceiveSpecialEmailOffers,
              });
            }}
          />
        </Modal.Box>
        <Modal.Box>
          <Checkbox
            label={t('Alerted Customer')}
            checked={newSearchSettings.onlyAlertedCustomer}
            onChange={() => {
              setNewSearchSettings({
                ...newSearchSettings,
                onlyAlertedCustomer: !newSearchSettings.onlyAlertedCustomer,
              });
            }}
          />
        </Modal.Box>
      </Modal.Content>
      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            setNewSearchSettings(defaultSearchSettings);
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            if (
              !isNutmegAdmin &&
              !_.isEqual(searchSettings, newSearchSettings)
            ) {
              dispatch(updateCustomerSearchSettings(newSearchSettings));
            }
            onClose();
          }}
        >
          {t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
