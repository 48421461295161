import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import * as Swagger from 'shared/models/swagger';
import { ReservationReportGadgetParams } from 'client/reducers/dashboardSettings';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/FlexibleDashboard/GadgetBody.module.css';
import { getReservationReportDataSum } from 'client/pages/v3/FlexibleDashboard/util';

interface Props {
  params: ReservationReportGadgetParams;
  reportDataSets: Swagger.ReservationReportDataSet[];
}

export const ReservationReportSourcesTable = ({
  reportDataSets,
  params,
}: Props) => {
  const { t } = useTranslation();

  const total = getReservationReportDataSum(
    reportDataSets?.[0].items ?? [],
    params.dataType
  );

  const grouped = _.groupBy(reportDataSets[0].items ?? [], (item) => {
    return (
      item.booking_source?.agent_name ||
      t(item.booking_source?.source_type || 'NO BOOKING SOURCE')
    );
  });

  const bookingSourceCounts: {
    source: string;
    count: number;
    comparisonCount?: number;
  }[] = [];
  Object.keys(grouped).forEach((key) => {
    bookingSourceCounts.push({
      source: key,
      count: getReservationReportDataSum(grouped[key], params.dataType),
    });
  });

  // Create comparison data
  let comparisonTotal = 0;
  if (reportDataSets.length > 1) {
    comparisonTotal = getReservationReportDataSum(
      reportDataSets?.[1].items ?? [],
      params.dataType
    );

    const grouped = _.groupBy(reportDataSets[1].items ?? [], (item) => {
      return (
        item.booking_source?.agent_name ||
        t(item.booking_source?.source_type || 'NO BOOKING SOURCE')
      );
    });

    bookingSourceCounts.forEach((bookingSourceCount) => {
      const comparisonCount = getReservationReportDataSum(
        grouped[bookingSourceCount.source],
        params.dataType
      );
      bookingSourceCount.comparisonCount = comparisonCount;
    });
  }

  const sorted = _.sortBy(bookingSourceCounts, (item) => -item.count);

  return (
    <div className={styles['p-transition__body__table']}>
      <table className={tableSmallStyles['c-tableSmall']}>
        <tbody>
          {sorted.slice(0, 5).map((item, index) => (
            <tr key={index}>
              <th className={baseStyles['u-width-144']}>{item.source}</th>
              <td>
                {item.count.toLocaleString()} (
                {((item.count / total) * 100).toFixed(0)}%)
                {reportDataSets.length > 1 &&
                  item.comparisonCount !== undefined && (
                    <span>
                      {item.comparisonCount.toLocaleString()} ( (
                      {((item.comparisonCount / comparisonTotal) * 100).toFixed(
                        0
                      )}
                      %)
                    </span>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
