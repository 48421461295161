import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { FieldWrapper } from 'client/components/Form';
import { Add } from 'client/components/Icons/Add';
import { Box } from 'client/components/Box/Box';
import { Delete } from 'client/components/Icons/Delete';

import { StructuredInfoItemEditor } from './StructuredInfoItemEditor';

interface Props {
  name: string;
}

export const StructuredInfoEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          <FieldWrapper label={t('Structured Information')} />
          <div>
            <Add
              onClick={() =>
                (fields as any).insertAt(0, {
                  infoCategoryKey: '',
                  values: [],
                })
              }
            />
          </div>
          {fields.map((name, idx) => (
            <Box key={name} alignItems="flex-end" display="flex" mt={1} gap={1}>
              <StructuredInfoItemEditor name={name} />
              <Add
                onClick={() =>
                  (fields as any).insertAt(idx + 1, {
                    infoCategoryKey: '',
                    values: [],
                  })
                }
              />
              <Delete onClick={() => fields.remove(idx)} />
            </Box>
          ))}
        </>
      )}
    </FieldArray>
  );
};
